import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'
import styled from 'styled-components'
import moment from 'moment-business-days'
import ordinal from 'ordinal'
import { Modal } from 'antd'

// Components
import { Text } from '../../Reusable/Text'
import { Image } from '../../Reusable/Image'
import { FlexColumn, Flex, CardContainer } from '../../Reusable/Container'
import { LabeledInput } from '../../Reusable/Input'
import { ErrorAlert } from '../../Reusable/Alert'
import FlowFooter from '../../Reusable/FlowFooter'
import ConnectionModal from '../../Reusable/ConnectionModal'
import SettingsModal from './SettingsModal'
import BoldBanner from '../../Reusable/BoldBanner'
import SVGIcon from '../../Reusable/SVGIcon'
import { ImageButton, TextButton } from '../../Reusable/Button'
import environment from '../../../environment'
import { apiPOSTReq, apiGET } from '../../../Utils/api'
import { addressObjectToStr, dateFormatList, getDateInLocalTZ, toAbsCurrency, toNumberStr } from '../../../Utils/util'
import { ApprovalGraph } from '../../Reusable/Approval'
import { toCurrency, isBeforeCutOff, getFeatureLimit, getUserRoles, getBPAccountsWithAccess, showCodatConnectionBanner } from '../../../Utils/util'
import NewInboxVendor from '../Vendors/Create Vendor/NewInboxVendor'
import { FromAccountOption } from '../../Reusable/Option'
import { addDataToStore, CODAT_DATA, SAVE_DATA } from '../../../Actions/actions';
import { Option } from 'antd/lib/mentions'
import AionIcon from '../../../Images/aion-bank-icon.png'
import Lightning from '../../../Images/lightning-dark.svg';
import ModalClose from '../../../Images/modal-close.png'
import AlertModal from '../../Reusable/AlertModal'
import SettingsIcon from '../../../Images/integration/setting.png';
import RightArrow from '../../../Images/right-arrow-dark.svg'
import Info from '../../../Images/info.png';
import Edit from "../../../Images/edit.svg"
import BillPaymentTable from './BillPaymentTable'
import BillPaymentScheduledTable from './BillPaymentScheduledTable'

class PayBill extends Component {

    state = {
        payOnOption: "Select Date",
        messageLoading: true,
        paymentMethods: [],
        accounts: [],
        newPM: {},
        bill: this.props.bill,
        vendor: {},
        accountCOA: {},
        pairPagination: {
            current: 1,
            pageSize: 1000,
        },
        allAccountsPaired: true,
        paymentAmount: toNumberStr((this.props.bill || {}).balance, 2),
        scheduledPayments: [],
        scheduledAmt: 0
    }

    componentDidMount() {
        const { store, location, bill } = this.props
        var { publicHolidays } = store
        // var { bill } = location.state

        moment.updateLocale('us', {
            holidays: publicHolidays,
            holidayFormat: 'YYYY-MM-DD'
        })
        var accounts = getBPAccountsWithAccess(null, { resourceStr: "Payables.Bills", permType: "manage", getActiveAccounts: true, ignoreARAcct: true }) || []
        var fromAccount = {}
        if (accounts.length > 0) {
            fromAccount = accounts[0]
        }
        this.setState({
            paymentDate: this.getInitialPaymentDate(bill.dueDate),
            accounts: accounts,
            fromAccount: fromAccount
        }, this.evaluatePaymentApproval())
        this.getVendor(bill.vendorId)
        this.fetchCOAPair({ pagination: this.state.pairPagination });

        this.getConnections()
        this.getSchldPmts()
    }

    getVendor = (id) => {
        apiPOSTReq(`${environment.payBaseUrl}/payables/vendors/getVendor`, {}, { vendorId: id }, (err, resp) => {
            try {
                const data = resp || {}
                if (data.result && data.vendor) {
                    this.setState({ vendor: data.vendor })
                    this.getPaymentMethods()
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /payables/vendors/getVendor", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    getInitialPaymentDate = (dueDate) => {
        if (moment(dueDate).isBefore(moment())) {
            return moment().isBusinessDay ? moment().businessAdd(0) : moment().businessAdd(1)
        } else {
            return moment(dueDate)
        }
    }

    handleDateChange = (value) => {
        if (value === "Today") {
            var paymentDate = moment()

            // if (!isBeforeCutOff()) paymentDate = paymentDate.businessAdd(1)

            this.setState({ payOnOption: value, paymentDate })
        } else {
            const { bill } = this.props
            this.setState({ payOnOption: value, paymentDate: this.getInitialPaymentDate(bill.dueDate) })
        }
    }

    disabledDate = (current) => {
        // Can not select days before today and today and weekends / holidays
        return !current.isBusinessDay() || current < moment().add(0, 'd').startOf('day')
        // return current < moment().add(1, 'd').startOf('day')
    }

    saveInput = (id, dataToSave) => {
        this.setState({ [id]: dataToSave }, () => { if(id == 'paymentAmount') this.evaluatePaymentApproval() })
    }

    evaluatePaymentApproval = (amt) => {
        var { paymentAmount } = this.state
        apiPOSTReq(`${environment.payBaseUrl}/payables/evaluatePaymentApproval`, { "BankProvider": environment.bankProvider.crb }, { paymentAmount: amt || paymentAmount }, (err, resp) => {
            this.setState({ messageLoading: false, payBillLoading: false })
            try {
                const data = resp
                if (data.result) {
                    this.setState({ approvalRequired: data.approvalRequired, approvalRule: data.approvalRule })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /payables/evaluatePaymentApproval", error, err, resp)
                /* ErrorAlert({ description: error.message }) */
            }
        })
    }

    getApprovalText = () => {
        const { approvalRule } = this.state
        const { levels } = approvalRule

        var approvalText

        if (levels.length > 1) {
            approvalRule.levels.forEach((level, i) => {
                approvalText = approvalText + `${(i > 0) && ', followed by'} <b>${ordinal(i + 1)}</b> level approval from ${this.getApproversText(level.performers, level.type)}`
            })
        } else {
            approvalText = `This payment requires approval from ${this.getApproversText(levels[0].performers, levels[0].type)}`
        }

        return <Text>{approvalText}</Text>
    }

    getApproversText = (approvers, approvalBy) => {
        if (approvers.length == 1) {
            return `${approvers[0].firstName} ${approvers[0].lastName}`
        } else {
            return <>
                {approvers.map(a => `${a.firstName} ${a.lastName}`).slice(0, approvers.length - 1).join(", ")}<b>{approvalBy === "ANY" ? " or " : " and "}</b>{`${approvers[approvers.length - 1].firstName} ${approvers[approvers.length - 1].lastName}`}
            </>
        }
    }

    getSchldPmts = () => {
        var { bill, paymentAmount } = this.state
        // This includes both pending approval and scheduled statuses
        var body = {
            "billId": bill.id,
            "viewBy": "APPROVAL_SCHEDULED"
        }

               
        apiPOSTReq(`${environment.payBaseUrl}/payables/getPayments`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            this.setState({ submitLoading: false, payBillLoading: false })
            try {
                const data = resp || {}
                if (data.result) {
                    var scheduledPayments = data.achTransfersList || []
                    var scheduledAmt = 0;
                    scheduledPayments.forEach(pmnt => {
                        scheduledAmt += parseFloat(pmnt.amount)
                    })
                    var pAmt = toNumberStr(paymentAmount - scheduledAmt, 2)
                    if(pAmt == 0) pAmt = null
                    this.setState({ scheduledPayments: scheduledPayments, paymentAmount: pAmt, scheduledAmt: scheduledAmt })
                    this.evaluatePaymentApproval(pAmt)

                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /payables/pay", error, err, resp)
            }
        })
    }

    pay = async () => {
        const { bankingStore, store } = this.props
        var { payOnOption, paymentDate, approvalRequired, note, fromAccount, bill, vendor, paymentMethods, selectedPM, paymentAmount } = this.state

        const { UserInfo } = store

        // TODO - Check Thoroughly 
        let businessDailyLimits = bankingStore.BusinessDailyLimits || {}

        if (!paymentAmount || (paymentAmount == 0) || (paymentAmount > bill.balance)) {
            this.setState({ errorField: "paymentAmount", payBillLoading: false })
            return
        }
        if (!fromAccount) {
            this.setState({ errorField: "fromAccount", payBillLoading: false })
            return
        }

        this.setState({ submitLoading: true, payBillLoading: true })
        if (paymentMethods.length == 0) {
            var pmResp = await this.savePaymentMethod()
            if (pmResp) {
                selectedPM = pmResp.paymentMethod
                vendor = pmResp.vendor
            } else {
                return // savePaymentMethod will throw error
            }
        }

        var body = {
            "billId": bill.id,
            "vendorId": vendor.id,
            "paymentMethodId": selectedPM.id,
            "initiatedBy": `${UserInfo.FirstName} ${UserInfo.LastName}`,
            "ruleType": approvalRequired ? "Payable" : null,
            "contextIdentifier": `Bill Payment - ${vendor.vendorName}: ${bill.invoiceNumber}`,
            "accountNumber": fromAccount.accountNumber,
            "note": note,
            "paymentAmount": paymentAmount,
            "scheduleDate": moment(paymentDate).format("YYYY-MM-DD")
        }

        var payEndpoint = this.isPaymentDateToday() ? "pay" : "schedule"
        if (payEndpoint === "schedule") {
            body.contextIdentifier = `Bill Payment - ${vendor.vendorName}: ${bill.invoiceNumber}`
        }        
        apiPOSTReq(`${environment.payBaseUrl}/payables/${payEndpoint}`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            this.setState({ submitLoading: false, payBillLoading: false })
            try {
                const data = resp || {}
                if (data.result) {
                    // this.props.history.push('/payables/bills')
                    this.props.onPay()
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /payables/pay", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    savePaymentMethod = (options) => {
        var { newPM, vendor, fromAccount } = this.state
        const { pmId, accountNumber, routingNumber } = newPM
        return new Promise((resolve) => {
            if ((!accountNumber || isNaN(accountNumber))) {
                this.setState({ errorField: "accountNumber" })
                resolve(null)
                return
            }

            if ((!routingNumber || isNaN(routingNumber) || routingNumber.length != 9)) {
                this.setState({ errorField: "routingNumber" })
                resolve(null)
                return
            }

            var body = {
                vendorDeliveryMode: 'ACH',
                vendorId: vendor.id,
                accountId: fromAccount.accountId,
                nickName: vendor.vendorName,
                vendorType: vendor.type,
                bankDetail: {
                    accountNumber: accountNumber || "",
                    routingNumber: routingNumber || "",
                    accountType: "checking",
                },
            }

            apiPOSTReq(`${environment.payBaseUrl}/payables/vendors/addTransferDelivery`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
                this.setState({ createVendorLoading: false })
                try {
                    if (err) throw Error(err)
                    const data = resp || {}
                    if (data.result) {
                        resolve(data)
                    } else {
                        throw Error(data.responseMessage || data.error)
                    }
                } catch (error) {
                    console.log("/createCounterparty err", error, resp, body)
                    ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
                    resolve(null)
                }
                this.setState({ submitLoading: false })
            })
        })


    }

    getPaymentMethods = () => {
        const { vendor } = this.state
        const body = {
            "vendorId": vendor.associatedCPObjectId
        }
        this.setState({ loading: true })
        apiPOSTReq(`${environment.payBaseUrl}/payables/vendors/getPaymentMethods`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            try {
                this.setState({ loading: false })
                const data = resp
                if (data.result) {
                    var selectedPM = (data.paymentMethods || []).find(account => account.default === true)
                    console.log("/payables/vendors/getPaymentMethods selectedPM", JSON.stringify(selectedPM))
                    this.setState({ paymentMethods: data.paymentMethods, selectedPM })
                } else {
                    // throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getPaymentMethods", error, resp)
                // ErrorAlert({ description: error.message })
            }
        })
    }

    handleAccountSelect = (value, option) => {
        var fromAccount = this.state.accounts.find(account => account.accountId === value)
        this.setState({ fromAccount })
    }

    isPaymentDateToday = () => {
        var { paymentDate } = this.state
        return (moment(paymentDate).format(environment.defaultDateFormat) === moment().format(environment.defaultDateFormat))
    }

    getDeliveryDate(paymentDate) {
        var { paymentDate } = this.state
        if (this.isPaymentDateToday()) {
            return moment(paymentDate).businessAdd(isBeforeCutOff() ? 1 : 2).format(environment.defaultDateFormat)
        } else {
            return moment(paymentDate).businessAdd(1).format(environment.defaultDateFormat)
        }
    }

    fetchCOAPair = ({ pagination }) => {
        const body = {
            page: pagination.current - 1,
            size: pagination.pageSize,
        }
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/codat/getAcctCOAPair`, {}, body, (err, resp) => {
            try {
                const data = resp;
                if (data.result) {
                    const accounts = getBPAccountsWithAccess()
                    const bbaccounts = (accounts || []).filter(acc => acc.accountType !== "ACCOUNTS_RECEIVABLE")
                    const accountCOA = this.state.accountCOA;
                    if (data.accountPairList) {
                        data.accountPairList.forEach(x => {
                            accountCOA[x.accountId] = x.coaId
                        });
                        this.setState({ allAccountsPaired: bbaccounts.every(account => account.accountId in accountCOA) });
                    }

                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getAcctCOAPair", error.stack, resp);
                // ErrorAlert({ description: error.message })
            }
        })
    }

    getConnections = () => {
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/codat/getConnections`, {}, {}, (err, resp) => {
            try {
                const data = resp || {};
                if (data.result) {
                    var connections = data.connections || []
                    this.props.dispatch(addDataToStore(CODAT_DATA, { connections: connections, connection: connections.length > 0 && connections[0], company: data.company }))
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("PayBill getConnections error", { description: error.message })
            }
        })
    }

    saveToBill = (id, dataToSave) => {
        var { bill } = this.state

        bill[id] = dataToSave
        this.setState({ bill })
    }

    handleBillTextChange = (event) => {
        this.saveToBill(event.target.id, event.target.value)
    }

    saveBill = (callback) => {
        var { bill, vendor } = this.state
        if(bill.paymentMemo || bill.paymentDesc) {
            this.setState({ payBillLoading: true })
            this.props.saveBill(bill, vendor, callback)
        }
    }

    render() {
        const { theme, store, bankingStore } = this.props
        var { billsSyncSettingsBannerDismissed } = store
        var { bill, vendor, payOnOption, paymentDate, showConnectAS, approvalRequired, approvalRule, payBillLoading, selectedPM, 
            paymentMethods, fromAccount, errorField, accounts, newPM, showSettingsModal, showDismissSetupWarning, allAccountsPaired, 
            showSyncSetupComplete, billSettingsSaved, showDismissConnectWarning, paymentAmount, scheduledPayments, scheduledAmt } = this.state

        var { invoiceNumber, amount, balance, dueDate, notes, category, billDate, paymentMemo, paymentDesc, scheduledDateStr, payments, status, autoPayAfterApproval } = bill
        const { id, vendorName, primaryEmail, primaryPhone, taxId, address, type } = vendor

        const unpaid = ['Unpaid', 'Canceled'].includes(status) || balance > 0
        const partialPayment = ((payments || []).length > 0 && balance > 0)
        var deliveryMode = "ACH"

        if (!fromAccount && selectedPM?.accountId) fromAccount = (accounts || []).find(account => account.accountId === selectedPM?.accountId)

        var accountOptions = [];
        accounts.forEach(account => {
            accountOptions.push(
                <Option key={account.accountId} id={id} value={account.accountId} style={{ backgroundColor: "transparent" }}>
                    <FromAccountOption account={account} />
                </Option>
            )
        })

        const showConnectionBanner = showCodatConnectionBanner(store)
        const showCompleteBillPaymentSetupBanner = !showConnectionBanner && !billSettingsSaved && !billsSyncSettingsBannerDismissed && !allAccountsPaired

        var paymentsTotal = 0
        payments.forEach(pmnt => { paymentsTotal += parseFloat(pmnt.amount) })

        return (
            <>
                <FlexColumn start fullWidth grow>
                    {
                        showConnectionBanner &&
                        <div style={{ padding: '0 15px' }}>
                            <BoldBanner
                                icon={<Image src={Lightning} />}
                                style={{ marginTop: "0", marginBottom: 24, width: "100%" }}
                                message="Sync your bills, vendors and more to your accounting system. Skip all the manual work"
                                cta={<TextButton underline color={theme.colors.primary2} text={'Get started now'} onClick={() => {
                                    this.setState({ showConnectAS: true })
                                    // this.props.history.push("/settings/sync")
                                }} />}
                                onClose={() => {
                                    this.setState({ showDismissConnectWarning: true })
                                    this.props.dispatch(addDataToStore(SAVE_DATA, { connectASBannerDismissed: true }))
                                }}
                            />
                        </div>
                    }

                    {
                        showCompleteBillPaymentSetupBanner &&
                        <BoldBanner
                            icon={<Image src={Info} />}
                            style={{ marginTop: "0", marginBottom: 24, width: "100%" }}
                            message="Complete your bill payment setup in under a few minutes. Bill payments will not be synced to your accounting system until you complete this setup"
                            cta={<TextButton underline color={theme.colors.primary2} text={'Complete setup'} onClick={() => {
                                this.setState({ showSettingsModal: true })
                                // this.props.history.push("/settings/sync")
                            }} />}
                            onClose={() => {
                                this.setState({ showSettingsModal: false, showDismissSetupWarning: true })
                                this.props.dispatch(addDataToStore(SAVE_DATA, { billsSyncSettingsBannerDismissed: true }))
                            }}
                        />
                    }

                    {/* <CardContainer padding='0' style={{ marginBottom: 24 }} grow fullWidth> */}
                        <FlexColumn between fullHeight>
                            <FlexColumn start style={{ padding: '24px' }} gap="24px" fullWidth>                                
                                { unpaid ? <Text size='30px' weight='600' height='40px'>{partialPayment ? "Partially settled. Review and submit an additional payment." : "Verify and complete payment"}</Text> : <Text heading>Bill {invoiceNumber}</Text> }
                                {/* <Text size='20px' margin="10px 0 0 0">You're about to pay <span style={{ color: theme.colors.creditGreen }}>${toCurrency(bill.amount)}</span></Text> */}
                                <FlexColumn start style={{ width: 500 }} gap="24px">
                                    <Flex start centerHorizontally gap='2px'>
                                        <Text caption spaced weight='500'>BILL DETAILS</Text>
                                        {
                                            unpaid &&
                                            <ImageButton  
                                                svg 
                                                src={Edit} 
                                                height='20px' 
                                                width='20px'
                                                onClick={() => this.props.editBill()}
                                                fill={theme.colors.primary2}
                                                strokeWidth={'0.2px'}
                                            />
                                        }
                                    </Flex>

                                    <Flex start wrap fullWidth gap='24px'>
                                        <LabeledInput nomargin type='read-only' label="Vendor" value={vendorName} width={144} />
                                        {partialPayment && <LabeledInput nomargin type='read-only' label="Bill amount" value={`$${toCurrency(amount)}`} width={144} />}
                                        <LabeledInput nomargin type='read-only' label="Bill balance" value={`$${toCurrency(balance)}`} width={144} />    
                                        <LabeledInput nomargin type='read-only' label="Bill number" value={invoiceNumber} width={144} />
                                        <LabeledInput nomargin type='read-only' label="Bill date" value={billDate && moment.utc(billDate).utcOffset(0).format("ll")} width={144} />
                                        <LabeledInput nomargin type='read-only' label="Due date" value={moment(dueDate).format("ll")} width={144} />
                                    </Flex>

                                    <div style={{ height: 1, width: '100%', background: '#F2F2F2' }}></div>

                                    <Text caption spaced weight='500'>PAYMENT DETAILS</Text>
                                    {
                                        (payments || []).length > 0 &&
                                        <BillPaymentTable
                                            payments={payments}
                                            borderView
                                            descText={ `A total of $${toAbsCurrency(paymentsTotal, 2)} has been paid towards this bill`}
                                        />                               
                                    }
                                    {
                                        (scheduledPayments || []).length > 0 &&
                                        <BillPaymentScheduledTable
                                            payments={scheduledPayments.map(pmnt => {
                                                var status = pmnt.status
                                                if(status == "Scheduled" && pmnt.scheduledDate) {
                                                    status = `Scheduled for ${moment(pmnt.scheduledDate).format(environment.defaultDateFormat)}`
                                                }
                                                return {
                                                    paymentId: pmnt.objectId,
                                                    date: getDateInLocalTZ(pmnt.createdAt),
                                                    amount: pmnt.amount,
                                                    status: status
                                                }
                                            })}
                                            descText={ `Payments totaling $${toAbsCurrency(scheduledAmt, 2)} are scheduled or pending approval`}
                                            borderView
                                        />                               
                                    }
                                    {
                                        unpaid ?
                                        <>
                                            <LabeledInput
                                                label={partialPayment ? "New payment amount" : "Payment amount"}
                                                nomargin
                                                id="paymentAmount"
                                                placeholder="Enter an amount"
                                                prefixText="$"
                                                value={paymentAmount}
                                                onChange={e => this.saveInput(e.target.id, e.target.value)}
                                                error={errorField === "paymentAmount"}
                                                errorMessage={"Should be greater than 0 and less than or equal to the balance"}
                                            />
                                            <LabeledInput
                                                label="Initiate payment on"
                                                tooltip="For payments submitted prior to 6:00 PM ET, expect delivery on the following business day. Payments made after this cutoff will arrive within 2 business days."
                                                key="paymentDate"
                                                id="paymentDate"
                                                type="date-picker"
                                                nomargin
                                                format={dateFormatList}
                                                value={paymentDate && moment(paymentDate)}
                                                disabledDate={this.disabledDate}
                                                placeholder={moment(dueDate).format("MM-DD-YYYY")}
                                                onChange={(date, dateStr) => this.saveInput("paymentDate", date)}
                                                // onKeyDown={this.handleOnKeyDown}
                                                // instruction={paymentDate && `Approx. delivery date will be on ${moment(paymentDate).businessAdd(1).format(environment.defaultDateFormat)}`}
                                            />

                                            <Flex start wrap fullWidth gap='24px'>
                                                {
                                                    deliveryMode === "ACH" &&
                                                    <LabeledInput
                                                        nomargin
                                                        type='read-only'
                                                        key="3"
                                                        label="Approx. delivery date"
                                                        value={paymentDate && this.getDeliveryDate(paymentDate)}
                                                        width={144} />
                                                }
                                                {
                                                    (selectedPM && selectedPM.type === "ACH" && selectedPM.bankDetail && selectedPM.bankDetail.accountNumber) &&
                                                    <LabeledInput nomargin type='read-only' key="4" label="Vendor payment account" value={<>{(selectedPM.type).toUpperCase()} - <span className="MICR-font">****{selectedPM.bankDetail.accountNumber.substring(selectedPM.bankDetail.accountNumber.length - 4)}</span></>} width={164} />
                                                }
                                                <LabeledInput nomargin type='read-only' key="5" label="Transaction fee" value={"$0"} width={144} />
                                                {/*
                                                userPaymentAccount &&
                                                <LabeledInput nomargin type='read-only' key="1" label="From account" value={<>{(userPaymentAccount.nickName || `Business ${userPaymentAccount.accountSubType}`).toUpperCase()} - <span className="MICR-font">****{userPaymentAccount.mask}</span></>} width={408} />
                                            */}
                                            </Flex>
                                            <FlexColumn gap='24px'>
                                                {
                                                    (accounts.length > 1) &&
                                                    <LabeledInput
                                                        label="Account to pay from"
                                                        showAction={'focus'}
                                                        type="select"
                                                        nomargin
                                                        key="fromAccount"
                                                        value={fromAccount && <><img width='16px' height='16px' src={AionIcon} style={{ marginRight: 8, marginBottom: 2 }} /><Text>{`${(fromAccount.nickName || `Business ${fromAccount.accountSubType}`)} • ${fromAccount.mask}`}</Text></>}
                                                        instruction="We will use this account for your payments to this vendor"
                                                        placeholder="From account"
                                                        onChange={this.handleAccountSelect}
                                                        errorField={errorField}
                                                        error={errorField === "fromAccount"}
                                                        errorMessage="Select a from account"
                                                    >
                                                        {accountOptions}
                                                    </LabeledInput>
                                                }
                                                {
                                                    // Add a new payment method
                                                    paymentMethods.length == 0 &&
                                                    <NewInboxVendor
                                                        payMode
                                                        key='pm'
                                                        pm={newPM}
                                                        handlePMChange={(pm) => this.setState({ newPM: pm })}
                                                        errorField={errorField}
                                                    />
                                                }
                                                {
                                                    <LabeledInput
                                                        id="paymentMemo"
                                                        label="Payment memo"
                                                        optional
                                                        nomargin
                                                        key="paymentMemo"
                                                        value={paymentMemo}
                                                        maxLength={80}
                                                        instruction={`Add additional info to help identify this payment's purpose ${paymentMemo ? `(${paymentMemo.length}/80 characters)` : ''}`}
                                                        placeholder="Add up to 80 characters"
                                                        onChange={this.handleBillTextChange}
                                                        errorField={errorField}
                                                        error={errorField === "paymentMemo"}
                                                        errorMessage="Add a valid memo"
                                                        // onBlur={() => this.saveBill()}
                                                    />
                                                }
                                                {
                                                    <LabeledInput
                                                        id="paymentDesc"
                                                        label="Payment identifier"
                                                        optional
                                                        nomargin
                                                        key="paymentDesc"
                                                        value={paymentDesc}
                                                        maxLength={10}
                                                        instruction={`Add a short identifier that helps your vendor identify this payment ${paymentDesc ? `(${paymentDesc.length}/10 characters)` : ''}`}
                                                        placeholder="Add up to 10 characters"
                                                        onChange={this.handleBillTextChange}
                                                        errorField={errorField}
                                                        error={errorField === "paymentDesc"}
                                                        errorMessage="Add a description"
                                                        // onBlur={() => this.saveBill()}
                                                    />
                                                }
                                            </FlexColumn>

                                            {
                                                approvalRequired === true && <>
                                                    <div style={{ height: 1, width: '100%', background: '#F2F2F2' }}></div>
                                                    <FlexColumn start>
                                                        <Text caption spaced weight='500'>APPROVALS</Text>
                                                        <ApprovalGraph rule={approvalRule} />
                                                    </FlexColumn>
                                                </>
                                            }
                                        </>
                                        :
                                        <>
                                            <Flex start wrap fullWidth gap='24px'>
                                                {scheduledDateStr && <LabeledInput nomargin type='read-only' label="Scheduled" value={moment(scheduledDateStr).format(environment.defaultDateFormat)} width={144} />}
                                                {!scheduledDateStr && (status == 'Pending Approval') && <LabeledInput nomargin type='read-only' label="Payment date" value={"Initiated on Approval"} width={144} />}
                                                {payments && payments.length > 0 && <LabeledInput nomargin type='read-only' label="Payment date" value={payments[payments.length-1].paymentDate ? (getDateInLocalTZ(payments[payments.length-1].paymentDate)) : "--"} width={144} />}
                                                {status && <LabeledInput nomargin type='read-only' label="Status" value={status} width={144} />}
                                            </Flex>
                                        </>
                                    }
                                </FlexColumn>
                                
                            </FlexColumn>

                            {
                                unpaid &&
                                <div style={{ marginLeft: 24 }}>
                                    <FlowFooter
                                        disabled={false}
                                        loading={payBillLoading}
                                        back={false}
                                        buttonTitle={approvalRequired ? "SEND FOR APPROVAL" : "PAY"}
                                        onClick={() => {
                                            this.setState({ submitLoading: true, payBillLoading: true })
                                            this.saveBill((err, resp) => {
                                                this.pay()
                                            })
                                        }}
                                        solid
                                    /> 
                                </div>
                                
                            }
                        </FlexColumn>
                    {/* </CardContainer> */}
                </FlexColumn>

                <Modal
                    visible={showConnectAS}
                    footer={null}
                    closable={true}
                    maskClosable={true}
                    width={697}
                    destroyOnClose={true}
                    onCancel={() => this.setState({ showConnectAS: false })}
                    closeIcon={<Image src={ModalClose} />}
                >
                    <ConnectionModal onCancel={() => this.setState({ showConnectAS: false })} />
                </Modal>

                <Modal
                    visible={showSettingsModal}
                    footer={null}
                    closable={true}
                    maskClosable={true}
                    width={697}
                    destroyOnClose={true}
                    onCancel={() => this.setState({ showSettingsModal: false, showDismissSetupWarning: true })}
                    closeIcon={<Image src={ModalClose} />}
                >
                    <SettingsModal
                        onCancel={() => {
                            this.fetchCOAPair({ pagination: this.state.pairPagination });
                            this.setState({ showSettingsModal: false, showSyncSetupComplete: true, billSettingsSaved: true })
                        }}
                    />
                </Modal>

                <AlertModal
                    success
                    visible={showSyncSetupComplete}
                    title='Sync setup complete'
                    description={`You're all set! Bill payments settings saved successfully.`}
                    buttonTitle='OKAY'
                    onConfirm={() => this.setState({ showSyncSetupComplete: false })}
                />

                <AlertModal
                    imgSrc={Info}
                    visible={showDismissConnectWarning}
                    title='Accessing your Connections'
                    description={<Text>You can go to Business Profile {'>'} Accounting Systems anytime to connect Aion to your accounting system.</Text>}
                    buttonTitle='OKAY'
                    onConfirm={() => this.setState({ showDismissConnectWarning: false })}
                />

                <AlertModal
                    imgSrc={Info}
                    visible={showDismissSetupWarning}
                    title='Bill payments settings incomplete'
                    description={<Text>You can go to Business Profile {'>'} Accounting Systems {'>'} Settings (<Image width='14px' height='14px' src={SettingsIcon} />) anytime to complete your setup.</Text>}
                    buttonTitle='OKAY'
                    onConfirm={() => this.setState({ showDismissSetupWarning: false })}
                />
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer,
        bankingStore: state.bankingAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(PayBill))