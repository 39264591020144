import React, { useState } from 'react';
import { Text, Tag } from '../../Reusable/Text';
import { LabeledInput } from '../../Reusable/Input';
import { TextButton } from '../../Reusable/Button'
import { Image } from '../../Reusable/Image'
import moment from 'moment'
import { toCurrency } from '../../../Utils/util'
import { Flex, FlexColumn, Container, Card, CardContainer } from '../../Reusable/Container';
import Arrow from '../../../Images/arrow-right.png'
import Profile from '../../../Images/merchant-profile.svg'
import Phone from '../../../Images/merchant-phone.svg'
import Email from '../../../Images/merchant-email.svg'


const PaymentInformation = ({ invoice, width }) => {

    return (
        <CardContainer width={width} fullWidth={!width} padding='12px 24px 24px'>
            <FlexColumn>
                <Text heading>About Aion</Text>
                <Text margin='10px 0 0'>Highly secure and business banking platform built for the busy business owner</Text>

                <TextButton weight='400' margin='30px 0 0' text={<a target="_blank" href="https://www.aionfi.com">Learn more</a>} rightIcon={<Image src={Arrow} />} />
            </FlexColumn>
        </CardContainer>
    );
};

export default PaymentInformation;
