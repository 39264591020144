import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import { saveAs } from 'file-saver'
import { Table, Modal, message, Skeleton } from 'antd';
import { PaperClipOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';

// Components
import PageHeader from '../../Reusable/PageHeader';
import { Flex, FlexColumn, Container } from '../../Reusable/Container';
import environment from '../../../environment';
import { apiPOSTReq, getPreSignedS3Url } from '../../../Utils/api';
import moment from 'moment';
import { ErrorAlert } from '../../Reusable/Alert';
import { Tag } from '../../Reusable/Text';
import { Button } from '../../Reusable/Button';
import { LabeledInput } from '../../Reusable/Input';
import UploadSummary from './UploadSummary'

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
})

const downloadAttachment = (url, name) => {
    getPreSignedS3Url({ url: url }, (err, preSignedUrl) => {
        if (!err) {
            saveAs(preSignedUrl, name)
        } else {
            ErrorAlert({ description: JSON.stringify(err) })
        }
    })
}

class Index extends Component {

    state = {
        loading: true,
        pagination: {
            current: 1,
            pageSize: 100
        },
        pos: [],
        showUploadSummary: false,
    };

    componentDidMount() {
        this.fetchAgingReportHistory()
    }

    fetchAgingReportHistory = () => {
        this.setState({ loading: true, selectedRows: [] })

        apiPOSTReq(`${environment.iveBaseUrl}/ar/aging/getHistory`, {}, { "viewBy": "FILTER_STATUS", "Status": "InReview|Approved|Rejected|Voided" }, (err, resp) => {
            try {
                const data = resp;
                console.log("/ar/aging/getHistory", JSON.stringify(data))
                console.log("/ar/aging/getHistory err", JSON.stringify(err))
                if (data.result) {
                    this.setState({ loading: false, uploadARAgingInfos: data.uploadARAgingInfos })
                } else {
                    this.setState({ loading: false, deleteLoading: false })
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /ar/aging/getHistory", error, err, resp);
                ErrorAlert({ description: error.message })
            }
        })
    }

    getColumns = () => {
        const { theme } = this.props;

        return [
            {
                title: 'Upload Date',
                dataIndex: 'uploadDate',
                render: date => (moment(date).format("MMM D, h:mm a z")),
            },
            {
                title: 'Uploaded Gross AR',
                dataIndex: 'grossAR',
                render: amount => formatter.format(amount),
            },
            {
                title: 'Uploaded and Certified  by',
                dataIndex: 'certifiedBy',
            },
            {
                title: 'Status',
                dataIndex: 'status',
                render: (status, info) => {
                    if (status === "PendingUpload") {
                        return <Tag tagpreset={"#EC3F25"}>{"Pending Upload"}</Tag>
                    } else if (status === "InReview") {
                        return <Tag tagpreset={theme.colors.secondary4}>{"In Review"}</Tag>
                    } else {
                        return <Tag tagpreset={theme.colors.secondary4}>{status}</Tag>
                    }
                },
            },
            {
                title: '',
                dataIndex: 'action',
                align: 'center',
                render: (text, upload) => {
                    return <>
                        <a onClick={() => this.showUploadSummary(upload)} permtype="Override">Upload Summary</a>
                        <a onClick={() => downloadAttachment(upload.uploadFileURL.uri, upload.uploadFileURL.fileName)}><PaperClipOutlined style={{ marginLeft: 40, fontSize: 18 }} /></a>
                    </>
                },
            },
        ]
    }

    showUploadSummary = (upload) => {
        this.setState({ showUploadSummary: true, upload })
    }

    handleTableChange = (pagination, filters, sorter) => {
        this.fetchData({ pagination: pagination });
    }

    handleRowSelection = (selectedRowKeys, selectedRows) => {
        console.log("handleRowSelection", selectedRows, selectedRowKeys)
        this.setState({ selectedRows: selectedRows, selectedRowKeys: selectedRowKeys })
    }
    getApprovalStatus = (status) => {
        let approvalStatus = "";
        if (status === 'Approved') {
            approvalStatus = "Approved";
        } else if (status === 'Rejected') {
            approvalStatus = "Rejected";
        } else if (status === 'InReview') {
            approvalStatus = "Pending";
        } else if (status === 'Voided') {
            approvalStatus = 'Voided';
        }
        return approvalStatus;
    }
    getRowDetails = (props) => {
        var { status, reviewDate, reviewerNotes, reviewer } = props;
        const { theme } = this.props;
        const dateFormat = "MM/DD/YY h:mm a z";
        const approvalStatus = this.getApprovalStatus(status);

        return (
            (approvalStatus != 'Pending') ? <Skeleton loading={this.state.loadingTrackLog} active title={false} paragraph={{ rows: 3 }}>
                <Flex style={{ padding: "20px", background: theme.body, justifyContent: "space-around", alignItems: 'flex-start' }}>
                    <FlexColumn style={{ width: '40%' }}>
                        <div style={{ fontWeight: 'bold', fontSize: '16px', marginBottom: '10px' }}>AR Aging Report approvals</div>
                        <div>
                            <div style={{ color: theme.colors.secondary4 }}>{approvalStatus} by:</div>
                            <div>{reviewer}</div>
                        </div>
                        <div style={{ marginTop: '10px' }}>
                            <div style={{ color: theme.colors.secondary4 }}>{approvalStatus} on:</div>
                            <div>{moment(reviewDate).format(dateFormat)}</div>
                        </div>
                    </FlexColumn>
                    <FlexColumn>
                        <div style={{ fontWeight: 'bold', fontSize: '16px', marginBottom: '10px' }}>Reviewer note</div>
                        <div>{reviewerNotes}</div>
                    </FlexColumn>
                </Flex></Skeleton> : <></>
        )
    }

    handleApproveUpload = (approvalStatus) => {
        this.setState({approveLoading: true});
        if (!this.state.reviewerNote) {
            ErrorAlert({ description: "Please update reviewer notes before proceeding." });
            return;
        }
        const { UserInfo } = this.props.aionStore;

        const body = {
            "uploadIds": this.state.selectedRows.map(row => row.uploadJobId),
            "approvalStatus": approvalStatus,
            "reviewerNotes": this.state.reviewerNote,
            "reviewer": `${UserInfo.FirstName} ${UserInfo.LastName} (${UserInfo.Email})`
        }

        console.log('/ar/aging/approveUpload', body)
        apiPOSTReq(`${environment.iveBaseUrl}/ar/aging/approveUpload`, null, body, (err, resp) => {
            try {
                const data = resp || {};
                if (data.result) {
                    message.success(`Report${this.state.selectedRows.length > 1 ? "s" : ""} ${approvalStatus}`);
                    this.setState({ selectedRowKeys: [] })
                    this.setState({approveLoading: false});
                    this.fetchAgingReportHistory()
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getInvoices", error, err, resp);
                ErrorAlert({ description: error.message })
            }
        })
    }

    render() {
        const { theme, hidetitle, showRowSelection } = this.props;
        const { loading, pagination, selectedRows, selectedRowKeys, showUpdateModal, inventoryInfos, uploadARAgingInfos, showUploadSummary, upload, approveLoading } = this.state;

        return (
            <>
                <FlexColumn style={{ margin: hidetitle ? 0 : `0 40px` }}>
                    {!hidetitle &&
                        <PageHeader
                            titleText="AR Aging Reports"
                            desc=""
                        />}

                    <FlexColumn>
                        {((selectedRows || []).length > 0) &&
                            <div>
                                <LabeledInput
                                    label="Reviewer Notes *"
                                    id="reviewerNote"
                                    type="text-area"
                                    placeholder="Enter a reason for the approval / rejection"
                                    onChange={(e) => this.setState({ reviewerNote: e.target.value })}
                                />
                                <div style={{ display: 'flex', marginTop: '20px' }}>
                                    <Button
                                        permtype="Override"
                                        loading={approveLoading}
                                        onClick={() => this.handleApproveUpload("Approved")}
                                        style={{ height: 45, width: "250px", margin: "0 10px 10px 0" }}
                                        solid
                                        text='Approve Reports' />

                                    <Button
                                        permtype="Override"
                                        loading={approveLoading}
                                        onClick={() => this.handleApproveUpload("Rejected")}
                                        style={{ height: 45, width: "250px", margin: "0 0 10px 0" }}
                                        text='Reject Reports' />
                                </div>
                            </div>
                        }
                    </FlexColumn>
                    <Container shadow style={{ margin: '24px 0' }}>
                        <Table
                            id="inbox-table"
                            tableLayout='auto'
                            columns={this.getColumns()}
                            dataSource={uploadARAgingInfos}
                            rowKey='id'
                            pagination={pagination}
                            onChange={this.handleTableChange}
                            style={{ minHeight: 500, marginTop: 20 }}
                            scroll={{ y: '700px', x: '100%' }}
                            loading={loading}
                            rowSelection={{
                                type: "radio",
                                onChange: this.handleRowSelection,
                                //selectedRowKeys: selectedRowKeys,
                                selectedRowKeys: this.props.selectedRowKeys,
                                renderCell: (checked, record, index, originNode) => {
                                    if (record.status === "InReview") {
                                        return originNode
                                    }
                                },
                                hideSelectAll: true,
                            }}
                            expandable={!this.props.disableExpandable && {
                                expandedRowRender: record => this.getRowDetails(record),
                                expandRowByClick: true,
                                expandIconColumnIndex: -1,
                                columnWidth: "2px",
                                indentSize: "20px",
                                expandIcon: ({ expanded, onExpand, record }) =>
                                    !expanded ? (
                                        <DownOutlined onClick={e => onExpand(record, e)} />
                                    ) : (
                                        <UpOutlined onClick={e => onExpand(record, e)} />
                                    )
                            }}
                        />
                    </Container>
                </FlexColumn>

                <Modal
                    visible={showUploadSummary}
                    footer={null}
                    closable={true}
                    maskClosable={true}
                    width={1200}
                    style={{ top: 80 }}
                    destroyOnClose={true}
                    onCancel={() => this.setState({ showUploadSummary: false })}
                >
                    <UploadSummary upload={upload} />
                </Modal>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        bkStore: state.bookkeepingAppReducer,
        aionStore: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Index));