import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import moment from 'moment'
import styled from 'styled-components'
import { Modal, Tabs, Table, Dropdown, Menu } from 'antd'

import { Flex, FlexColumn, CardContainer } from '../../Reusable/Container'
import PageHeader from '../../Reusable/PageHeader'
import { Button, TextButton, ImageButton } from '../../Reusable/Button'
import { ErrorAlert } from '../../Reusable/Alert'
import { Text, Tag } from '../../Reusable/Text'
import { Image } from '../../Reusable/Image'
import AlertModal from '../../Reusable/AlertModal'
import { apiPOSTReq } from '../../../Utils/api'
import { uaApiPOST } from '../../../Utils/userAccess'
import { getApplicationDisplayName } from '../../../Utils/util'
import environment from '../../../environment'
import ordinal from 'ordinal'
import SaveApprovalRule from './SaveApprovalRule'
import ModalClose from '../../../Images/modal-close.png'
import More from "../../../Images/more.png"
import Edit from "../../../Images/edit.png"
import Add from '../../../Images/add-white.svg'
import TableView from '../../Reusable/TableView'

const { TabPane } = Tabs


const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
})

class Index extends Component {

    constructor(props) {
        super(props)

        this.state = {
            approvers: {},
            selectedApproverNames: {},
            approvalSettings: [],
            stepCount: 1,
            showEditSettingModal: false,
            levelTypes: {
                0: "ANY",
                1: "ANY",
                2: "ANY",
            },
            recipientApprovers: [],
            paymentApprovers: [],
            tab: 'active',
        }
    }

    componentDidMount() {
        this.getRules()
        this.getInactiveRules()
        this.getRecipientApprovers()
        this.getPaymentApprovers()
        this.fetchUsers()
        this.fetchRoles()
    }

    getRecipientApprovers = () => {
        const recipientsBody = {
            "applicationName": "BusinessBanking",
            "resourceName": "Recipients"
        }

        apiPOSTReq(`${environment.uamBaseUrl}/uam/getApprovers`, {}, recipientsBody, (err, resp) => {
            try {
                var data = resp

                console.log("Approvals /uam/getApprovers", data)

                this.setState({ recipientApprovers: data.registeredUsers })

                if (err) throw Error(err)
                if (!resp.result) throw Error(resp.responseMessage || resp.error)
            } catch (error) {
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    getPaymentApprovers = () => {
        const recipientsBody = {
            "applicationName": "BusinessBanking",
            "resourceName": "Payments"
        }

        apiPOSTReq(`${environment.uamBaseUrl}/uam/getApprovers`, {}, recipientsBody, (err, resp) => {
            try {
                var data = resp

                console.log("Approvals /uam/getApprovers", data)

                this.setState({ paymentApprovers: data.registeredUsers })

                if (err) throw Error(err)
                if (!resp.result) throw Error(resp.responseMessage || resp.error)
            } catch (error) {
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    fetchUsers = () => {
        var userData = {}

        uaApiPOST("/user/admin/getUsersForBusinessEx", null, (err, resp) => {
            try {
                if (err) throw Error(err)
                if (resp.error) throw Error(resp.error)
                userData = resp
                console.log("getUsersForBusiness userData", userData)
                loadUsers()
            } catch (error) {
                console.log("getUsersForBusiness error", error)
                ErrorAlert({ description: error.message })
            }
        })

        const loadUsers = () => {
            var registeredUsers = userData.registeredUsers || []
            var allApprovers = []

            registeredUsers.forEach(user => {
                const userInfo = user.userInfo
                var userRoleNames = []
                const userAccessInfoList = user.userAccessInfoList || []
                const assignedRoleNamesMap = userData.assignedRoleNamesMap || {}
                var roles = assignedRoleNamesMap[userInfo.email] || {}
                Object.keys(roles).forEach(applicationName => {
                    (roles[applicationName] || []).forEach(appRole => {
                        if (!userRoleNames.includes(appRole)) userRoleNames.push(appRole)
                    })
                })

                user.roleTags = userRoleNames
                user.userAccessInfoList = userAccessInfoList
                user.name = `${userInfo.firstName} ${userInfo.lastName}`

                if (userInfo.enabled) allApprovers.push(user)
            })

            console.log("Approvals /uam/getApprovers allApprovers", allApprovers)

            this.setState({ loading: false, allApprovers })
        }
    }

    getRules = () => {
        this.setState({ loading: true })
        apiPOSTReq(`${environment.bbBaseUrl}/bb/approvals/getActiveApprovalRules`, null, { "RuleTypes": ["ACH", "Wire", "Recipient", "Payment"] }, (err, resp) => {
            try {
                const data = resp

                console.log("Approvals /bb/getActiveApprovalRules", data)

                if (data.result) {
                    if (err) throw new Error(err)

                    this.setState({ rules: data.approvalRuleList, loading: false })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getActiveApprovalRules", error, err, resp)
            }
        })
    }

    getInactiveRules = () => {
        this.setState({ loadingInactive: true })
        apiPOSTReq(`${environment.bbBaseUrl}/bb/approvals/getInActiveApprovalRules`, null, { "RuleTypes": ["ACH", "Wire", "Recipient", "Payment"] }, (err, resp) => {
            try {
                const data = resp

                console.log("Approvals /bb/getInActiveApprovalRules", data)

                if (data.result) {
                    if (err) throw new Error(err)

                    this.setState({ inactiveRules: data.approvalRuleList, loadingInactive: false })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getInActiveApprovalRules", error, err, resp)
            }
        })
    }

    getApproversText = (approvers, approvalBy) => {
        if (!approvers || approvers.length < 1) return
        if (approvers.length == 1) {
            return `${approvers[0].firstName} ${approvers[0].lastName}`
        } else {
            return <>
                {approvers.map(a => `${a.firstName} ${a.lastName}`).slice(0, approvers.length - 1).join(", ")}<b>{approvalBy === "ANY" ? " or " : " and "}</b>{`${approvers[approvers.length - 1].firstName} ${approvers[approvers.length - 1].lastName}`}
            </>
        }
    }

    getPaymentTypeText = (paymentType) => {
        console.log("getPaymentTypeText ", paymentType)
        // switch (paymentType) {
        //     case 'ACH':
        //         return <><b>{paymentType}</b> transfers</>
        //     case 'Wire':
        //         return <><b>{paymentType}</b> transfers</>
        //     case 'Payment':
        //         return <><b>Instant</b> transfers</>
        //     default:
        //         return <><b>Any</b> transfer</>
        // }
        return <>Transfers</>
    }

    getApproverNames = (i) => {
        var approverNames = []

        this.state.approvers[i].map(a => {
            approverNames.push(`${a.userInfo.firstName} ${a.userInfo.lastName}`)
        })

        return approverNames
    }

    deleteRule = (ruleId) => {
        console.log("ruleId", ruleId)

        apiPOSTReq(`${environment.bbBaseUrl}/bb/approvals/deactivate`, null, { "ruleId": ruleId }, (err, resp) => {
            try {
                const data = resp

                console.log("Approvals /bb/approvals/deactivate", data)

                if (data.result) {
                    if (err) throw new Error(err)
                    this.getRules()
                    this.getInactiveRules()
                    this.setState({ showDeactivate: false, tab: 'inactive' })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR approvals/deactivate", error, err, resp)
            } finally {
                this.setState({ deactivateLoading: false })
            }
        })
    }

    activateRule = (rule) => {
        console.log("rule", rule)

        this.setState({ [`${rule.name}ActivateLoading`]: true });
        apiPOSTReq(`${environment.bbBaseUrl}/bb/approvals/activate`, null, { "ruleId": rule.approvalRuleId }, (err, resp) => {
            try {
                const data = resp

                console.log("Approvals /bb/approvals/activate", data)

                if (data.result) {
                    if (err) throw new Error(err)
                    this.getRules()
                    this.getInactiveRules()
                    this.setState({ showDeactivate: false, tab: 'active' })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR approvals/activate", error, err, resp)
            }
        })
    }

    handleSaveApprovalSetting = () => {
        apiPOSTReq(`${environment.bbBaseUrl}/bb/approvals/getActiveApprovalRules`, null, { "RuleTypes": ["ACH", "Wire", "Recipient", "Payment"] }, (err, resp) => {
            try {
                const data = resp

                if (data.result) {
                    if (err) throw new Error(err)

                    this.setState({ rules: data.approvalRuleList, loading: false, loadingSaveApprovalSetting: false, showEditSettingModal: false, approvalType: null, name: null, paymentType: null, approvers: {}, selectedApproverNames: {}, amount: null })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getActiveApprovalRules", error, err, resp)
            }
        })
    }

    fetchRoles() {
        const { store } = this.props
        var { businessSubscriptionPlan } = store
        businessSubscriptionPlan = businessSubscriptionPlan || {}

        console.log('fetchRoles businessSubscriptionPlan', businessSubscriptionPlan)

        apiPOSTReq(`${environment.uamBaseUrl}/uam/getRolesForBusiness`, {}, { PlanName: (businessSubscriptionPlan || {}).currentPlan }, (err, resp) => {
            try {
                var roles = resp.roles || []
                roles.sort(function (x, y) { return x.roleType == "Custom__Sys" ? 1 : y.roleType == "Custom__Sys" ? -1 : 0 })

                console.log("getRolesForBusiness", resp)

                // Revenue and Expenses Admin handling
                var revenueAndAdminRoles = []
                var indexesToRemove = []

                roles.forEach((role, i) => {
                    // Add App names to system roles
                    role.resourcePermissions.forEach(permission => {
                        if (!permission.nameSpace) {
                            permission.nameSpace = getApplicationDisplayName(role.applicationName) // getAppDisplayNameBySystemRole(role.roleName)
                        }
                    })

                    // Revenue and Expenses Admin handling
                    if (role.roleName === "Revenue and Expenses Admin") {
                        revenueAndAdminRoles.push(role)
                        indexesToRemove.push(i)
                    }
                })

                // Revenue and Expenses Admin handling
                if (revenueAndAdminRoles.length > 1) {
                    var revenueAndAdminRole = revenueAndAdminRoles[0]

                    for (var i = 1; i < revenueAndAdminRoles.length; i++) {
                        revenueAndAdminRole.resourcePermissions = revenueAndAdminRole.resourcePermissions.concat(revenueAndAdminRoles[i].resourcePermissions)
                    }

                    while (indexesToRemove.length) {
                        roles.splice(indexesToRemove.pop(), 1)
                    }

                    roles.push(revenueAndAdminRole)
                }

                var systemRoles = roles.filter(function (role) {
                    return role.roleType != "Custom__Sys" && role.roleName != "Staff"
                })

                var customRoles = roles.filter(function (role) {
                    return role.roleType === "Custom__Sys" && role.roleName != "Staff"
                })

                console.log('UserAccess allRoles: ', roles)

                this.setState({ allRoles: roles, rolesLoading: false, systemRoles, customRoles })

                if (err) throw Error(err)
                if (!resp.result) throw Error(resp.responseMessage || resp.error)
            } catch (error) {
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    render() {
        const { editSetting, approvalRule, approvalSetting, showEditSettingModal, loadingNew, allApprovers, rules, loading, loadingInactive, allRoles, inactiveRules, tab, showDeactivate, deactivateLoading, ruleToDeactivate } = this.state
        const { theme } = this.props

        const columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                // width: 300,
                render: (name, record) => {
                    var rail
                    if (record.type === 'Payment') rail = 'Instant'
                    if (record.type === 'Wire') rail = 'Wire'
                    if (record.type === 'ACH') rail = 'ACH'

                    return <Flex gap='8px' start centerHorizontally>
                        <Text>{name}</Text>
                        {rail && <Tag>{rail}</Tag>}
                    </Flex>
                },
            },
            {
                title: 'Type',
                dataIndex: 'type',
                key: 'type',
                align: "center",
                render: (type, record) => {
                    var displayType = type
                    if (type === 'Payment') displayType = 'Transfer'
                    if (type === 'Wire') displayType = 'Transfer'
                    if (type === 'ACH') displayType = 'Transfer'

                    return <Tag>{displayType}</Tag>
                },
                // width: 120,
            },
            {
                title: 'Description',
                dataIndex: 'setting',
                key: 'setting',
                render: (setting, rule) => (
                    <>
                        {
                            rule.type === "Recipient" ?
                                <>Require</>
                                :
                                <>{this.getPaymentTypeText(rule.type)} of <b>{formatter.format(rule.condition.expressions[0].rhsValue)}</b> or more require</>
                        }
                        {
                            rule.levels.length > 1 ?
                                rule.levels.map((l, i) => {
                                    return <>{(i > 0) && ', followed by'} <b>{ordinal(i + 1)}</b> level approval from {this.getApproversText(l.performers, l.type)}</>
                                })
                                :
                                <> approval from {this.getApproversText(rule.levels[0].performers, rule.levels[0].type)}</>
                        }
                    </>
                ),
            },
            {
                key: 'action',
                align: 'right',
                dataIndex: 'name',
                onHover: true,
                render: (name, record) => (
                    <Flex fullWidth end centerHorizontally>
                        {
                            tab === 'active' ?
                                <Dropdown
                                    placement='bottomRight'
                                    trigger={['click']}
                                    key={`${record.docNumber}-Dropdown`}
                                    overlay={<Menu style={{ padding: 24, borderRadius: 8, zIndex: 1001 }} key={`${record.docNumber}-Menu`} onClick={() => { }}>
                                        <FlexColumn gap='24px'>
                                            <TextButton text='EDIT' onClick={() => this.setState({ showEditSettingModal: true, editSetting: true, approvalRule: record })} rightIcon={<Image src={Edit} />} />
                                            <TextButton permtype="Receivables.Invoices" text='DEACTIVATE' onClick={() => this.setState({ ruleToDeactivate: record, showDeactivate: true })} />
                                        </FlexColumn>
                                    </Menu>}
                                >
                                    <ImageButton src={More} />
                                </Dropdown>
                                :
                                <TextButton loading={this.state[`${name}ActivateLoading`]} onClick={() => this.activateRule(record)} permtype="Receivables.Invoices" text='ACTIVATE' />
                        }
                    </Flex>
                ),
            }
        ]

        return (
            <>
                <FlexColumn start className='main-padding' grow>
                    <PageHeader
                        titleText="Banking"
                        subtitle='Banking'
                    />

                    {/* <CardContainer grow style={{ marginBottom: 24 }} padding='8px 24px 24px'> */}
                        <Tabs size="large" activeKey={tab} onChange={(tab) => this.setState({ tab })} style={{ marginBottom: 16 }}>
                            <TabPane tab="Active" key="active" />
                            <TabPane tab="Inactive" key="inactive" />
                        </Tabs>

                        <TableView
                            id="setting-table"
                            titleText='Controls'
                            // tableLayout='auto'
                            columns={columns}
                            dataSource={tab === 'active' ? rules : inactiveRules}
                            rowKey='approvalRuleId'
                            pagination={false}
                            style={{ minHeight: "600px", height: '100%' }}
                            loading={tab === 'active' ? loading : loadingInactive}
                            ctaContent={
                                <Button permtype="BusinessBanking.Approvals" loading={loadingNew} solid onClick={() => this.setState({ showEditSettingModal: true })} icon={<Image src={Add} />} text="ADD CONTROL" />
                            }
                        />
                    {/* </CardContainer> */}

                </FlexColumn>
                <Modal
                    visible={showEditSettingModal}
                    footer={null}
                    closable={true}
                    width={700}
                    destroyOnClose={true}
                    onCancel={() => {
                        this.setState({ showEditSettingModal: false, editSetting: false, approvalRule: null })
                    }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <SaveApprovalRule
                        registeredUsers={allApprovers}
                        handleSaveApprovalSetting={this.handleSaveApprovalSetting}
                        editSetting={editSetting}
                        approvalRule={approvalRule}
                        approvalSetting={approvalSetting}
                        allRoles={allRoles}
                    />
                </Modal>

                <AlertModal
                    deactivate
                    visible={showDeactivate}
                    loading={deactivateLoading}
                    title='Deactivate this control?'
                    description={`Are you sure you want to deactivate ${ruleToDeactivate?.name}?`}
                    buttonTitle='DEACTIVATE'
                    cancelButtonText='CANCEL'
                    onConfirm={() => {
                        this.setState({ deactivateLoading: true }, () => this.deleteRule(ruleToDeactivate.approvalRuleId))
                    }}
                    onCancel={() => this.setState({ showDeactivate: false, ruleToDeactivate: null })}
                />
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer,
        bankingStore: state.bankingAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)))