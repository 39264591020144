import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import SignatureCanvas from 'react-signature-canvas'

import { CardContainer, Flex, FlexColumn } from '../../../Reusable/Container';
import { Text } from '../../../Reusable/Text';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Divider, Select, Modal } from 'antd';
import { isSmallScreen } from '../../../../Utils/util';
import { ImageButton, Button } from '../../../Reusable/Button'
import ModalClose from '../../../../Images/modal-close.png'
import EditIcon from '../../../../Images/edit.png'
import DownIcon from '../../../../Images/chevron-down-icon.png'
import BusinessInformation from './BusinessInformation';
import OwnershipInformation from './OwnershipInformation';
import Chat from '../../../OnboardingFlowCoordinator/CreditOnboarding/Chat';
import { apiGET, apiPOST, apiPOSTReq, getPreSignedS3Url } from '../../../../Utils/api';
import { ErrorAlert } from '../../../Reusable/Alert';
import environment from '../../../../environment';

var businessDocs = [
    { name: "2022 Tax Returns" },
    { name: "2021 Tax Returns" },
    // { name: "Company Debt Schedule" }
];

class DueDiligenceReview extends Component {

    state = {
        submitLoading: false,
        beneficialOwners: [],
        gnplLow: 0,
        gnplHigh: 100001
    }

    componentDidMount() {
        this.setState({ beneficialOwners: this.fetchOwners() });
        this.fetchBusinessAttributes();
    }

    validateFlow = () => {
        this.fetchDocuments();
    }

    fetchBusinessAttributes = () => {
        var body = {
            "clientBusinessId": ''
        };

        this.setState({ attributesLoading: true });
        // apiPOSTReq(`${environment.opsBaseUrl}/ops/fetchBusinessAttributes`, {}, body, (err, resp) => {
        apiPOSTReq(`${environment.opsBaseUrl}/ops/fetchBusinessAttributes`, null, body, (err, resp) => {
            try {
                if (err) throw Error(err);
                const data = resp || {};
                if (data.result) {
                    var lineSetup = data.businessAttributes.filter(x => x.name == "GNPLAutomatedLineSetupFlow");
                    if (lineSetup.length > 0) {
                        var attribute = lineSetup[0];
                        var additionalParameterReference = attribute.additionalParameterReference;
                        var parameters = additionalParameterReference.parameters;
                        var low = parseInt(parameters[0].value);
                        var high = parseInt(parameters[1].value);
                        this.setState({ gnplLow: low, gnplHigh: high })
                    }
                } else {
                    throw Error(data.responseMessage || data.error);
                }
            } catch (error) {
                console.log("/generateDebtSchedule err", error, resp);
            }
        });
    }

    fetchDocuments = () => {
        if (!this.state.submitLoading) {
            const { loanApplication, proposalTerms } = this.props;
            var body = {
                "sortDirection": "DESC"
            };
            this.setState({ submitLoading: true });

            if (loanApplication) {
                body["clientBusinessId"] = loanApplication.businessId;
                body["applicationId"] = loanApplication.applicationId;
                body["viewBy"] = "FILTER_APPLICATION"
            }
            console.log("/docs/getActiveDocuments body", body, loanApplication);
            apiPOSTReq(`${environment.uamBaseUrl}/docs/getActiveDocuments`, {}, body, (err, resp) => {
                console.log("/docs/getActiveDocuments resp", resp);
                this.setState({ submitLoading: false });
                try {
                    const data = resp || {};
                    if (data.result) {
                        this.setState({
                            businessDocuments: data.businessDocuments
                        });
                        /*  if (proposalTerms.creditLimit < this.state.gnplHigh) {
                              businessDocs = businessDocs.filter(doc => doc.name != "Company Debt Schedule");
                          } */
                        if (businessDocs.find(item => {
                            if (!this.getDocument(item.name).fileName) {
                                return true;
                            }
                        })
                        ) {
                            ErrorAlert({ description: "Please upload all documents" });
                            return;
                        }

                        this.props.loadNext();
                    } else {
                        throw Error(data.responseMessage || data.error)
                    }
                } catch (error) {
                    //console.log("/getPersonCards err", error, resp);
                    ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
                }
            });
        }
    }

    getDocument = (name, options) => {
        options = options || {};
        var { businessDocuments } = this.state;
        var savedDoc = this.state[name] || {};
        var filteredDoc = (businessDocuments || []).find(item => (item.name == name));
        if (filteredDoc != undefined) {
            // Replace with remotely stored data
            if (options.businessDocument) savedDoc = filteredDoc;
            else savedDoc = filteredDoc.documentUrl || {};
        }
        return savedDoc;
    }

    fetchOwners = () => {
        var { store, isCreditPlus, onboardingStore } = this.props;
        var { business } = store;
        var businessProfile = isCreditPlus ? onboardingStore.businessProfile : (business || {}).businessProfile || {};
        var coAppInfo = businessProfile.coOwnerApplicantInfo || [];
        var beneficialOwners = [];
        if (businessProfile.primaryApplicantInfo != undefined) {
            if (Number(businessProfile.primaryApplicantInfo.ownershipPercentage) >= 25) {
                beneficialOwners.push(businessProfile.primaryApplicantInfo)
            }
        }
        if (isCreditPlus) {
            coAppInfo = this.props.onboardingStore.coOwnersInfo
        }
        if (coAppInfo.length > 0) {
            var filteredcoOwners = coAppInfo.filter(owner => Number(owner.ownershipPercentage) >= 25)

            if (filteredcoOwners.length > 0) {
                filteredcoOwners.map(owner => beneficialOwners.push(owner));
            }
        }
        return beneficialOwners;
    }
    render() {
        var { theme, flowView, currentViewIndex, loadPrevView, applicationStep, loanApplication, store } = this.props
        var { submitLoading, isUserProfileActive, editUserProfile, isBusinessInformationActive, editBusinessInformation, isOwnershipInformationActive, editOwnershipInformation, isOwnershipActive, editOwnership, isCPActive, editCP, isASActive, editAS } = this.state
        var sigStyle = { borderRadius: 5, marginBottom: 6 }
        if (isSmallScreen) sigStyle["zoom"] = "1.2"
        var { beneficialOwners } = this.state;
        var childViews = (
            <FlexColumn style={{ gap: "24px" }}>
                <FlexColumn style={{ width: "20%" }}>
                    <span style={{ justifyContent: "flex-end", position: "absolute", top: "10px", right: "110px" }}> {<Chat loanApp={loanApplication || {}} applicationStep={applicationStep} />} </span>
                </FlexColumn>
                {<Button noClick shadow padding='0 16px' height='40px' text="Review" weight='600' radius='4px' style={{ width: '84px' }} />}
                <FlexColumn>
                    <Text weight={400} size='20px' height='40px' >All done!</Text>
                    <Text>Please review all the information you’ve provided before submitting your application.</Text>
                </FlexColumn>

                {/* BUSINESS INFORMATION */}
                <CardContainer onClick={() => this.setState({ isBusinessInformationActive: !isBusinessInformationActive })} style={{ cursor: 'pointer' }}>
                    <FlexColumn start>
                        <Flex between centerHorizontally fullWidth style={{ height: '64px' }}>
                            <Flex center gap='8px'>
                                <CheckCircleOutlined style={{ color: theme.colors.greenDeep, fontSize: '20px' }} />
                                <Text weight='500' size='20px'>Business Information</Text>
                            </Flex>
                            <Flex center gap='24px'>
                                <ImageButton src={EditIcon} onClick={() => this.setState({ editBusinessInformation: !editBusinessInformation, showEditInfoModal: true })} />
                                <ImageButton src={DownIcon} onClick={() => this.setState({ isBusinessInformationActive: !isBusinessInformationActive })} transform={`rotate(${isBusinessInformationActive ? 180 : 0}deg)`} />
                            </Flex>
                        </Flex>
                        {
                            isBusinessInformationActive &&
                            <>
                                <Divider margin='24px 0 24px' />
                                <BusinessInformation reviewView proposalTerms={this.props.proposalTerms} />
                            </>
                        }
                    </FlexColumn>
                </CardContainer>

                {/* OWNERSHIP INFORMATION */}
                {(beneficialOwners.length > 0 && (this.props.proposalTerms || {}).creditLimit >= this.state.gnplHigh) ?

                    <CardContainer minHeight="300" onClick={() => this.setState({ isOwnershipInformationActive: !isOwnershipInformationActive })} style={{ cursor: 'pointer' }}>
                        <FlexColumn start>
                            <Flex between centerHorizontally fullWidth style={{ height: '64px' }}>
                                <Flex center gap='8px'>
                                    <CheckCircleOutlined style={{ color: theme.colors.greenDeep, fontSize: '20px' }} />
                                    <Text weight='500' size='20px'>Ownership Information</Text>
                                </Flex>
                                <Flex center gap='24px'>
                                    <ImageButton src={EditIcon} onClick={() => this.setState({ editOwnershipInformation: !editOwnershipInformation, showEditInfoModal: true })} />
                                    <ImageButton src={DownIcon} onClick={() => this.setState({ isOwnershipInformationActive: !isOwnershipInformationActive })} transform={`rotate(${isOwnershipInformationActive ? 180 : 0}deg)`} />
                                </Flex>
                            </Flex>
                            {
                                isOwnershipInformationActive &&
                                <>
                                    <Divider />
                                    <OwnershipInformation reviewView proposalTerms={this.props.proposalTerms} />
                                </>
                            }
                        </FlexColumn>
                    </CardContainer>

                    : null}

            </FlexColumn>
        )
        return (
            <FlexColumn left fullWidth style={{ margin: '24px 0', paddingBottom: '48px' }}>

                <FlexColumn fullWidth style={{ padding: '0 64px' }} gap='24px'>
                    <Text weight='500' color='#444444' size='28px' height='40px'>{flowView.AttrTitle || (flowView.TitleToDisplay || flowView.Title)}</Text>
                    {childViews}
                    <FlexColumn left gap='24px'>
                        <Text>By clicking submit, you acknowledge that the information being submitted is accurate and correct. You agree to the <a target='blank' href='https://aionfi.com/legal'>Aion Legal Terms and Conditions</a>.</Text>
                        <Button solid loading={submitLoading} text='SUBMIT' style={{ textTransform: 'uppercase' }} onClick={() => this.validateFlow()} />
                    </FlexColumn>
                </FlexColumn>

                <Modal
                    visible={this.state.showSignatureModal}
                    footer={null}
                    closable={true}
                    width={625}
                    destroyOnClose={true}
                    onCancel={() => {
                        this.setState({ showSignatureModal: false })
                    }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                    style={{ top: '20px' }}
                >
                    <FlexColumn left >
                        <Text size="20px" weight={500} height="40px">Acknowledgement</Text>
                        <Text>Acknowledge your application by signing below with your cursor or finger </Text>
                        <div style={{ border: "1px solid #C7CEDD", borderRadius: '4px', marginTop: '24px', width: '100%' }}>
                            <SignatureCanvas
                                // backgroundColor='#F7F7FA'
                                penColor='black'
                                ref={(ref) => { this.sigBOCert = ref }}
                                canvasProps={{ width: isSmallScreen ? 480 : 575, height: 240, className: 'sigCanvas', style: sigStyle }}
                            />
                        </div>
                        <Text style={{ fontSize: 14, color: theme.colors.secondary5, cursor: 'pointer', marginBottom: 24 }} onClick={() => { this.sigBOCert.clear() }}>Clear Signature</Text>
                        <Button solid text='Submit' style={{ marginTop: '24px', textTransform: 'uppercase' }} onClick={() => this.handleSignatureSubmit()} />
                    </FlexColumn>
                </Modal>
                <Modal
                    visible={this.state.showEditInfoModal}
                    footer={null}
                    closable={false}
                    width={"90%"}
                    destroyOnClose={true}
                    onCancel={() => {
                    }}
                    // closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                    style={{ top: '20px' }}
                >
                    <FlexColumn style={{ padding: "0 0 24px 0" }}>
                        {
                            editBusinessInformation &&
                            <BusinessInformation
                                reviewEditMode
                                flowView={{ FlowStep: "Apply.BusinessInfo", Title: "Business Information", ButtonTitle: "Save Changes" }} // FlowStep: set to step before review
                                hideSteps={true}
                                back={false}
                                loadNext={() => this.setState({ editBusinessInformation: false, showEditInfoModal: false })}
                                showFooter={false}
                                proposalTerms={this.props.proposalTerms}
                            />
                        }
                        {
                            editOwnershipInformation &&
                            <OwnershipInformation
                                reviewEditMode
                                flowView={{ FlowStep: "Apply.OwnershipInfo", Title: "Ownership Information", ButtonTitle: "Save Changes" }} // FlowStep: set to step before review
                                hideSteps={true}
                                back={false}
                                loadNext={() => this.setState({ editOwnershipInformation: false, showEditInfoModal: false })}
                                showFooter={false}
                                proposalTerms={this.props.proposalTerms}
                            />
                        }
                    </FlexColumn>
                </Modal>
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer,
        creditStore: state.creditAppReducer,
        onboardingStore: state.onboardingAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(DueDiligenceReview));