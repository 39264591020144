import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';
import moment from 'moment';
import _ from 'lodash';

import { Skeleton, Table } from 'antd';

// Util
import { Flex, FlexColumn } from '../../Reusable/Container';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

class InvoiceTable extends Component {
    state = {
    };

    selectRow = (record) => {
        this.props.showSelectedInvoice(record);
    }

    getRowDetails = (invoice) => {
        var { creditStatus, verificationStatus, reviewer, reviewedDate, reviewerNote } = invoice;
        const { theme } = this.props;
        const dateFormat = "MM/DD/YY";
        const approvalStatus = this.getApprovalStatus(creditStatus, verificationStatus);

        return (
            (approvalStatus != 'Pending') ? <Skeleton loading={this.state.loadingTrackLog} active title={false} paragraph={{ rows: 3 }}>
                <Flex style={{ padding: "20px", background: theme.body, justifyContent: "space-around", alignItems: 'flex-start' }}>
                    <FlexColumn style={{ width: '40%' }}>
                        <div style={{ fontWeight: 'bold', fontSize: '16px', marginBottom: '10px' }}>Invoice approvals</div>
                        <div>
                            <div style={{ color: theme.colors.secondary4 }}>{approvalStatus} by:</div>
                            <div>{reviewer}</div>
                        </div>
                        <div style={{ marginTop: '10px' }}>
                            <div style={{ color: theme.colors.secondary4 }}>{approvalStatus} on:</div>
                            <div>{moment(reviewedDate).format(dateFormat)}</div>
                        </div>
                    </FlexColumn>
                    <FlexColumn>
                        <div style={{ fontWeight: 'bold', fontSize: '16px', marginBottom: '10px' }}>Reviewer note</div>
                        <div>{reviewerNote}</div>
                    </FlexColumn>
                </Flex>
            </Skeleton> : <></>
        )
    }

    handleRowSelection = (selectedRowKeys, selectedRows) => {
        this.props.handleRowSelection(selectedRowKeys, selectedRows);
    }

    getApprovalStatus = (creditStatus, verificationStatus) => {
        let approvalStatus = "";
        if (verificationStatus === 'VALIDATED') {
            approvalStatus = "Approved";
        } else if (verificationStatus === 'REJECTED') {
            approvalStatus = "Rejected";
        } else if (creditStatus === 'INREVIEW') {
            approvalStatus = "Pending";
        }
        return approvalStatus;
    }

    render() {
        const { loading, invoices, type } = this.props

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });

        const columns = [
            {
                title: 'No.',
                dataIndex: 'docNumber',
                key: 'docNumber',
            },
            {
                title: 'Customer',
                dataIndex: 'customerName',
                key: 'Customer',
            },
            {
                title: 'Invoice Date',
                dataIndex: 'txnDate',
                key: 'txnDate',
                render: (date, invoice) => {
                    if (date) {
                        date = new Date(date).toISOString();
                        date = date.split("T")[0];
                    }
                    return (moment(date).format('MM/DD/YY'))
                },
            },
            {
                title: 'Due Date',
                dataIndex: 'dueDate',
                key: 'dueDate',
                render: (date, invoice) => {
                    if (date) {
                        date = new Date(date).toISOString();
                        date = date.split("T")[0];
                    }
                    return (moment(date).format('MM/DD/YY'))
                },
            },
        ];

        if (type === 'PartiallyPaid') {
            columns.push({
                title: 'Amount',
                dataIndex: 'amount',
                key: 'amount',
                render: amount => (formatter.format(amount)),
                align: 'right',
            });

            columns.push({
                title: 'Begining Balance',
                render: item => (formatter.format(item.totalAmt + item.paidAmount)),
                align: 'right',
            });

            columns.push({
                title: 'Payments Received',
                dataIndex: 'paidAmount',
                key: 'paidAmount',
                render: amount => (amount ? formatter.format(amount) : '--'),
                align: 'right',
            });

            columns.push({
                title: 'Ending Balance',
                dataIndex: 'totalAmt',
                key: 'totalAmt',
                render: amount => (formatter.format(amount)),
                align: 'right',
            });
        }

        if (type === 'Paid') {
            columns.push({
                title: 'Amount',
                dataIndex: 'paidAmount',
                key: 'paidAmount',
                render: amount => (formatter.format(amount)),
                align: 'right',
            });
        }

        if (type === 'New') {
            columns.push({
                title: 'Amount',
                dataIndex: 'amount',
                key: 'amount',
                render: amount => (formatter.format(amount)),
                align: 'right',
            });
        }

        return (
            <>
                <Table
                    id="invoice-table"
                    className='row-pointer'
                    tableLayout='auto'
                    columns={columns}
                    dataSource={invoices}
                    rowKey='invoiceId'
                    loading={loading}
                    expandable={!this.props.disableExpandable && {
                        expandedRowRender: record => this.getRowDetails(record),
                        expandRowByClick: true,
                        expandIconColumnIndex: -1,
                        columnWidth: "2px",
                        indentSize: "20px",
                        expandIcon: ({ expanded, onExpand, record }) =>
                            !expanded ? (
                                <DownOutlined onClick={e => onExpand(record, e)} />
                            ) : (
                                <UpOutlined onClick={e => onExpand(record, e)} />
                            )
                    }}
                />
            </>
        )
    }

}

function mapStateToProps(state) {
    return {
        store: state.creditAppReducer,
        aionStore: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(InvoiceTable)));