import React, { useState } from 'react'

import { Text, Tag } from '../../Reusable/Text'
import { Image } from '../../Reusable/Image'
import { TextButton, ImageButton } from '../../Reusable/Button'
import { Flex, FlexColumn, LightContainer } from '../../Reusable/Container'
import { Tooltip, Modal } from 'antd'
import UpdateUser from './UpdateUser'

// Images
import ModalClose from '../../../Images/modal-close.png'
import Edit from '../../../Images/edit.png'

const UserCard = (props) => {
    const [showUpdateModal, setShowUpdateModal] = useState(false)

    const { user, allRoles, fetchData, onUpdateRoles } = props
    const { Name, Email, roleTags } = user

    const nameArray = Name.split(' ')

    return (
        <>
            <div style={{ width: '48%', padding: 5 }}>
                <LightContainer>
                    <Flex fullWidth between centerHorizontally gap='16px'>
                        <Flex start bottom centerHorizontally wrap>
                            <Text className='initial-back' light center>{`${nameArray[0].charAt(0)}${nameArray[nameArray.length - 1].charAt(0)}`.toUpperCase()}</Text>
                            <FlexColumn >
                                <Flex start>
                                    <TextButton text={Name} onClick={() => setShowUpdateModal(true)} />
                                </Flex>
                                <Text size='14px' style={{ overflowWrap: 'break-word' }}>{Email}</Text>
                            </FlexColumn>
                        </Flex>

                        <Flex centerHorizontally end gap='8px' width='30%'>
                            {
                                roleTags && roleTags.length > 0 &&
                                <Tag noCap key={`${Name}-${roleTags[0]}`} background='#F2F2F2'>{roleTags[0]}</Tag>
                            }
                            {
                                roleTags && roleTags.length > 1 &&
                                <Tooltip overlayClassName='roles-tooltip' placement="bottom" arrowPointAtCenter={false} title={<Text color='white' size='14px'>{roleTags.slice(1, roleTags.length).join(', ')}</Text>}>
                                    <Tag noCap key={`${Name}-${roleTags[1]}`} background='#FBFBFB'>+{roleTags.length - 1}</Tag>
                                </Tooltip>
                            }
                        </Flex>
                    </Flex>
                </LightContainer>
            </div>
            <Modal
                visible={showUpdateModal}
                footer={null}
                onCancel={() => setShowUpdateModal(false)}
                width={616}
                destroyOnClose={true}
                closeIcon={<img width='24px' height='24px' src={ModalClose} />}
            >
                <UpdateUser
                    user={user}
                    allRoles={allRoles}
                    modal={true}
                    onUpdateRoles={onUpdateRoles} />
            </Modal>
        </>
    )
}

export default UserCard