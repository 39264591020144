import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import moment from 'moment'
import { Modal, Select, message } from 'antd'

import _ from 'lodash'
import { Text, Tag } from '../../Reusable/Text'
import { Image } from '../../Reusable/Image';
import { Flex, FlexColumn } from '../../Reusable/Container'
import { Button, ImageButton, TextButton } from '../../Reusable/Button'
import { LabeledInput } from '../../Reusable/Input'
import AlertModal from '../../Reusable/AlertModal'
import { getUserApps, toCurrency, toNumberStr } from '../../../Utils/util'
import { apiPOSTReq } from '../../../Utils/api'
import environment from '../../../environment'
import { ExpenseCategory } from '../../Reusable/ExpenseCategory';

const { Option } = Select

const priorityMap = {
    "Accounts Payable": 2,
    "Accounts Receivable": 3,
    "Bank": 4,
    "Cost of Goods Sold": 5,
    "Credit Card": 6,
    "Equity": 7,
    "Expense": 8,
    "Fixed Asset": 9,
    "Income": 0,
    "Long Term Liability": 10,
    "Other Asset": 11,
    "Other Current Asset": 12,
    "Other Current Liability": 13,
    "Other Expense": 14,
    "Other Income": 1,
}

class ProductDrawer extends Component {

    constructor(props) {
        super(props)

        var ABLUser = false

        const userApps = getUserApps(this.props.aionStore)

        if (userApps && userApps.includes("ABLCredit")) {
            ABLUser = true
        }

        this.state = {
            documentsLoading: true,
            msasLoading: true,
            showMSAModal: false,
            pagination: {
                current: 1,
                pageSize: 100,
                showSizeChanger: false,
            },
            sorter: {},
            ABLUser: ABLUser,
            dashboardLoading: true,
            loading: false,
            selectedTab: 'details',
            transferMethods: [],
            fileList: [],
            name: props.msa ? props.msa.name : null,
            contractValue: props.msa ? props.msa.contractValue : null,
            product: this.props.product || { type: 'Product' },
            view: props.view,
        }

        this.customer = this.props.location.state && this.props.location.state.customer

        this.nameRef = React.createRef()
        this.typeRef = React.createRef()
        this.incomeAccountRef = React.createRef()
        this.unitPriceRef = React.createRef()
    }

    componentDidMount() {
        this.fetchCOA({});
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.product) !== JSON.stringify(this.props.product)) {
            this.setState({ product: this.props.product || {}, errorField: null });
        }
    }

    fetchCOA = ({ searchStr }) => {
        const headers = {
            AionCurrentBiz: this.props.aionStore.BusinessUniqueKey,
            AionAuth: this.props.aionStore.UAM.encryptedAuthHeader
        }

        const body = {
            "BusinessId": this.props.aionStore.BusinessUniqueKey,
            "size": 1000,
            "page": 0,
            "ActiveRecords": true,
            "sortFieldName": "Code",
            "sortDirection": "ASC",
            "viewBy": "INCOME"
        }
        if (searchStr) {
            body.searchStr = searchStr;
        }

        apiPOSTReq(`${environment.bbBaseUrl}/bk/getCategories`, headers, body, (err, resp) => {
            try {
                const data = resp;
                console.log("/bk/getCategories", data)
                if (data.result) {
                    const customCOAList = data.customCOAList.filter(x => x.coatype !== 'Accounts Payable' && x.coatype !== 'Accounts Receivable')
                    let categories = (data.systemCOAList || []).concat(customCOAList);
                    this.setState({
                        categories: categories ? categories.sort((a, b) => {
                            if (priorityMap[a.coatype] < priorityMap[b.coatype]) return -1;
                            else return 1;
                        }).map(x => {
                            return {
                                ...x, key: x.id,
                            }
                        }) : [],
                    })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getActiveCustomers", error.stack, resp);
                this.props.showError(error.message)
            }
        })
    }

    handleSearch = value => {
        this.fetchCOA({ searchStr: value })
    }

    handleTextChange = (event) => {
        var { product } = this.state;
        product[event.target.id === 'productName' ? 'name' : event.target.id] = event.target.value;
        this.setState({ product });
    }

    handleSelect = (value, option) => {
        console.log("handleSelect", value, option)
        var { product } = this.state;
        product["type"] = value;
        this.setState({ product });
    }

    handleSelectCategory = (value, option) => {
        var category = this.state.categories[value]
        var { product } = this.state;
        product["accountCategoryName"] = category ? category.categoryName : null;
        product["accountCategoryId"] = category ? category.id : null;
        product["accountCategoryType"] = category ? category.coatype : null;
        this.setState({ product, category: category ? category.categoryName : null });
    }


    handleSubmit = async (event) => {
        var { product, category } = this.state;
        const { name, type, accountCategoryName, unitPrice } = (product || {});
        if (!name) {
            this.setState({ errorField: 'productName' })
            this.nameRef.current.focus()
            return;
        }
        if (!type) {
            this.setState({ errorField: 'type' })
            this.nameRef.current.focus()
            return;
        }
        if (!category && !accountCategoryName) {
            this.setState({ errorField: 'incomeAccount' })
            this.incomeAccountRef.current.focus()
            return;
        }
        // if (!unitPrice) {
        //     this.setState({ errorField: 'unitPrice' })
        //     this.unitPriceRef.current.focus()
        //     return;
        // }

        if (unitPrice) {
            const unitPriceAmt = toNumberStr(unitPrice)
            product.unitPrice = unitPriceAmt
        }

        product.productName = name

        const body = {
            "product": product,
        }

        this.setState({ loading: true })

        apiPOSTReq(`${environment.iveBaseUrl}/ive/product/save`, {}, body, (err, resp) => {
            try {
                const data = resp;
                console.log("ProductDrawer /ive/product/save", data)
                if (data.result) {
                    message.success(`${name} successfully created`);
                    if (data.responseMessage != null && data.responseMessage != "Success") {
                        this.props.showError(data.responseMessage)
                    }
                    this.props.submitComplete(data.product.id);
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                this.setState({ loading: false })
                console.log("ERRR /ive/product/save", error, err, resp);
                this.props.showError(error.message)
            } finally {
                this.setState({ loading: false })
            }
        })
    }

    deactivateProduct = () => {
        const { product } = this.state;
        // deactivates a Custom COA when there are no references to BKTransactions and is not a parent
        const body = {
            "productId": product.id
        }
        this.setState({ deleteLoading: true });
        apiPOSTReq(`${environment.iveBaseUrl}/ive/product/deactivate`, {}, body, (err, resp) => {
            try {
                this.setState({ deleteLoading: false });
                const data = resp;
                console.log("/ive/product/deactivate", data)
                if (data.result) {
                    message.success(`Product deactivated!`);
                    this.props.submitComplete();
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR deactivateProduct", error, resp);
                this.props.showError(error.message)
            }
        })
    }

    activateProduct = () => {
        const { product } = this.state;

        const body = {
            "productId": product.id
        }
        this.setState({ activateLoading: true });
        apiPOSTReq(`${environment.iveBaseUrl}/ive/product/activate`, {}, body, (err, resp) => {
            try {
                const data = resp;
                console.log("/ive/product/activate", data)
                if (data.result) {
                    message.success(`Product activated!`);
                    this.props.submitComplete();
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR activateProduct", error, resp);
                this.props.showError(error.message)
            } finally {
                this.setState({ activateLoading: false });
            }
        })
    }

    render() {
        const { visible, onClose, theme, showDelete, onShowDelete } = this.props;
        var { loading, addLoading, deleteLoading, errorField, product, view, activateLoading, showDeactivateModal, deactivateLoading } = this.state
        const { productId, name, type, sku, unitPrice, description, accountCategoryName, active } = (product || {});

        return (
            <>
                <FlexColumn start gap="24px" fullHeight style={{ marginBottom: 24 }}>
                    <Text heading>{view ? 'View Product/Service' : (this.props.product) ? 'Edit Product/Service' : 'New Product/Service'}</Text>

                    <LabeledInput
                        inputRef={this.nameRef}
                        nomargin
                        autoFocus
                        type={view && 'read-only'}
                        label="Name"
                        id="productName"
                        key="productName"
                        placeholder="Enter your product or service name"
                        value={name}
                        onChange={this.handleTextChange}
                        error={errorField === "productName"}
                        errorMessage={"This is a mandatory field"}
                    />

                    <LabeledInput
                        label="Type"
                        nomargin
                        type={view ? 'read-only' : "switch"}
                        switchNames={["Product", "Service"]}
                        onChange={(value) => {
                            product["type"] = value;
                            this.setState({ product });
                        }}
                        value={type}
                    />

                    <LabeledInput
                        inputRef={this.incomeAccountRef}
                        nomargin
                        showSearch
                        label="Income Account"
                        id="category"
                        key="category"
                        type={view ? 'read-only' : "remote-search"}
                        className="no-left-padding"
                        allowClear
                        placeholder="Search to select an account"
                        value={accountCategoryName}
                        onChange={this.handleSelectCategory}
                        onSearch={this.handleSearch}
                        error={errorField === "incomeAccount"}
                        errorMessage={"This is a mandatory field"}
                    >
                        {(this.state.categories || []).map((category, i) => (
                            <Option key={category.id} id="category" name="category" value={i}>
                                <ExpenseCategory category={category} theme={theme} />
                            </Option>
                        ))}
                    </LabeledInput>

                    {
                        (!view || (view && (unitPrice === 0 || unitPrice))) &&
                        <LabeledInput
                            inputRef={this.unitPriceRef}
                            nomargin
                            label="Unit Price"
                            type={view && 'read-only'}
                            id="unitPrice"
                            key="unitPrice"
                            placeholder="Enter a price"
                            prefix="$"
                            value={view ? `$${toCurrency(unitPrice)}` : unitPrice}
                            onChange={this.handleTextChange}
                            error={errorField === "unitPrice"}
                            errorMessage={"This is a mandatory field"}
                            optional
                        />
                    }

                    {
                        (!view || (view && sku)) &&
                        <LabeledInput
                            nomargin
                            optional
                            label="SKU"
                            type={view && 'read-only'}
                            id="sku"
                            key="sku"
                            placeholder="Enter your product/service sku"
                            value={sku}
                            onChange={this.handleTextChange}
                        />
                    }

                    {
                        (!view || (view && description)) &&
                        <LabeledInput
                            nomargin
                            optional
                            label="Description"
                            id="description"
                            key="description"
                            type={view && 'read-only'}
                            placeholder="Enter any description"
                            value={description}
                            onChange={this.handleTextChange}
                        />
                    }

                    <FlexColumn end grow>
                        {
                            active === false ?
                                <TextButton color='#318F2F' text="ACTIVATE" onClick={() => this.activateProduct()} loading={activateLoading} />
                                :
                                <Flex start gap='24px' centerHorizontally>
                                    {
                                        view ?
                                            <Button onClick={() => this.setState({ view: false })} solid text={'EDIT'} loading={addLoading} />
                                            :
                                            <Button onClick={this.handleSubmit} solid text={(this.props.product) ? 'SAVE' : 'ADD'} loading={loading} />
                                    }
                                    {/* <Button onClick={onClose} text='DISCARD' loading={addLoading} /> */}
                                    {
                                        (this.props.product) &&
                                        <TextButton onClick={() => onShowDelete(true)} text='DEACTIVATE' />
                                    }
                                </Flex>
                        }
                    </FlexColumn>
                </FlexColumn>

                {/* <Modal
                    visible={showDelete}
                    footer={null}
                    closable={false}
                    width={510}
                    destroyOnClose={true}
                    closeIcon={<Image src={ModalClose} />}
                    className='drawer-modal'
                    wrapClassName="drawer-modal"
                >
                    <DeleteModal
                        title='Deactivate product / service?'
                        description={<FlexColumn start centerHorizontally>
                            <Text>Are you sure you want to deactivate {name}?</Text>
                        </FlexColumn>}
                        descWidth={'470px'}
                        buttonTitle='OKAY'
                        onConfirm={() => {
                            this.setState({ deleteLoading: true }, () => {
                                this.deactivateProduct()
                            })
                        }}
                        onClose={() => onShowDelete(false)}
                        deleteLoading={deleteLoading}
                    />
                </Modal> */}

                <AlertModal
                    deactivate
                    visible={showDelete}
                    loading={deactivateLoading}
                    title='Deactivate product / service?'
                    description={`Are you sure you want to deactivate ${name}?`}
                    buttonTitle='DEACTIVATE'
                    cancelButtonText='CANCEL'
                    onConfirm={() => {
                        this.setState({ deleteLoading: true }, () => {
                            this.deactivateProduct()
                        })
                    }}
                    onCancel={() => onShowDelete(false)}
                />
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(ProductDrawer)))