import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'
import { Tabs, Modal, Dropdown, Menu, Popconfirm } from 'antd'

// Components
import { Tag, Text } from '../../../Reusable/Text'
import { Image } from '../../../Reusable/Image'
import { ImageButton, TextButton } from '../../../Reusable/Button'
import { FlexColumn, Flex } from '../../../Reusable/Container'
import { LabeledInput } from '../../../Reusable/Input'
import { ErrorAlert } from '../../../Reusable/Alert'
import PageHeader from '../../../Reusable/PageHeader'
import ConnectionModal from '../../../Reusable/ConnectionModal'
import SettingsModal from '../SettingsModal'
import BoldBanner from '../../../Reusable/BoldBanner'
import SVGIcon from '../../../Reusable/SVGIcon'
import ViewBill from '../ViewBill'
import { addDataToStore, CODAT_DATA, SAVE_DATA } from '../../../../Actions/actions';

import environment from '../../../../environment'
import SetupMailbox from './SetupMailbox'
import { apiPOSTReq, getPreSignedS3Url } from '../../../../Utils/api'
import { toCurrency, getUserRoles, showCodatConnectionBanner } from '../../../../Utils/util'
import { SignedLink, StyledExtLink } from '../../../Reusable/Link'
import moment from 'moment'
import Search from '../../../../Images/search.png'
import ModalClose from '../../../../Images/modal-close.png'
import TableView from '../../../Reusable/TableView'
import AlertModal from '../../../Reusable/AlertModal'
import Archive from '../../../../Images/archive-minus.svg'
import Lightning from '../../../../Images/lightning-dark.svg';
import SettingsIcon from '../../../../Images/integration/setting.png';
import Info from '../../../../Images/info.png';
import { DeleteOutlined } from '@ant-design/icons'
import More from "../../../../Images/more.png"


const defaultPagination = {
    current: 1,
    pageSize: 50,
    hideOnSinglePage: true
}

class Index extends Component {

    state = {
        loading: false,
        selectedTab: this.props.selectedTab || "New",
        pagination: defaultPagination,
        modalWidth: 700,
        inboxItems: [],
        copyStatus: "Copy email",
        showViewBillModal: false,
        categories: [],
        coaPagination: {
            current: 1,
            pageSize: 200
        },
        vendor: {},
        showConnectAS: false,
        showSettingsModal: false,
    }

    componentDidMount() {
        const urlStr = this.props.location.search.replace('?', '')
        const queryParams = new URLSearchParams(urlStr)
        const statusCode = queryParams.get('statuscode')
        const errorMessage = queryParams.get('errormessage')
        const statusText = queryParams.get('statustext')
        // http://{website}/{path}/?statuscode={statusCode}&errormessage={errorMessage}&statusText={statusText}
        // Check if redirected from codat
        if (statusCode) {
            console.log("BillPayments Inbox statusCode", statusCode)
            if (statusCode == 200) {
                this.fetchCOA({ coaPagination: this.state.coaPagination })
                this.getConnections(true)
            } else {
                ErrorAlert({ description: errorMessage || statusText || "Sorry we had trouble processing your request. Please try connecting again" })
            }
        } else {
            console.log("BillPayments Inbox NO statusCode")
        }

        this.getConnections()
        this.fetchMailboxEmail()
        this.fetchInbox({ selectedTab: this.state.selectedTab, pagination: this.state.pagination })
        this.fetchCOA({ coaPagination: this.state.coaPagination })
    }

    onSearch = (value) => {
        this.setState({ searchLoading: true })
        this.fetchInbox({ searchStr: value, selectedTab: this.state.selectedTab, pagination: defaultPagination })
    }

    handleSearchTextChange = (event) => {
        const target = event.target
        console.log("handleSearchTextChange", target.value)
        this.onSearch(target.value)
    }

    fetchMailboxEmail = () => {
        apiPOSTReq(`${environment.payBaseUrl}/payables/bills/getBillSetup`, { "BankProvider": environment.bankProvider.crb }, {}, (err, resp) => {
            try {
                const data = resp || {}
                console.log("/payables/bills/getBillSetup", err, data)
                if (data.result) {
                    const billInboxSetups = data.billInboxSetups || []
                    this.setState({ billInboxSetups: billInboxSetups, showMailboxSetup: !(billInboxSetups.length > 0) })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /payables/bills/getBillSetup", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    fetchCOA = (options) => {
        const { coaPagination } = options
        const headers = {
            AionCurrentBiz: this.props.aionStore.BusinessUniqueKey,
            AionAuth: this.props.aionStore.UAM.encryptedAuthHeader
        }
        const body = {
            "BusinessId": this.props.aionStore.BusinessUniqueKey,
            "size": coaPagination.pageSize,
            "page": 0,
            "ActiveRecords": true,
            "sortFieldName": "Code",
            "sortDirection": "ASC",
        }
        this.setState({ newProductLoading: true })
        apiPOSTReq(`${environment.bbBaseUrl}/bk/getCategories`, headers, body, (err, resp) => {
            try {
                const data = resp
                console.log("/bk/getCategories", data)
                if (data.result) {
                    // this.props.dispatch(addDataToStore(BOOKKEEPING_SAVE_DATA, { COA: (data.systemCOAList || []).concat(data.customCOAList) }))
                    this.setState({
                        coaPagination: {
                            ...coaPagination,
                            total: data.count
                        },
                        categories: (data.systemCOAList || []).concat(data.customCOAList),
                    })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getActiveCustomers", error.stack, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    fetchInbox = (options) => {
        const { searchStr, selectedTab, pagination } = options || {}
        var body = {
            size: pagination.pageSize,
            page: pagination.current - 1,
            sortDirection: "DESC",
            viewBy: "FILTER_STATUS",
            processingError: false,
        }

        if (selectedTab == "Archive") {
            body["converted"] = false
            body["archived"] = true
        } else if (selectedTab == "Inbox") {
            body["archived"] = false
        } else if (selectedTab == "New") {
            body["converted"] = false
            body["archived"] = false
        } else if (selectedTab == "Processed") {
            body["converted"] = true
            body["archived"] = false
        }

        if (searchStr) body["searchStr"] = searchStr
        this.setState({ loading: true })
        console.log("getBillInboxItems body", JSON.stringify(body), selectedTab)
        apiPOSTReq(`${environment.payBaseUrl}/payables/bills/getBillInboxItems`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            try {
                const data = resp || {}
                console.log("/payables/bills/getBillInboxItems", (data))
                if (data.result) {
                    this.setState({
                        inboxItems: data.billInboxItems || [],
                        pagination: {
                            ...pagination,
                            total: data.count
                        }
                    })
                    this.checkForProcessingBills()
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /payables/bills/getBillInboxItems", error, err, resp)
                ErrorAlert({ description: error.message })
            } finally {
                this.setState({ loading: false, searchLoading: false })
            }
        })
    }

    delay = ms => new Promise(res => setTimeout(res, ms))

    checkForProcessingBills = async () => {
        var { inboxItems, selectedTab, pagination } = this.state
        var processingInboxItems = inboxItems.filter(inboxItem => (!inboxItem.parsedInvoiceId && !inboxItem.processingError))
        console.log("checkForProcessingBills", selectedTab)
        if(processingInboxItems.length > 0 && (selectedTab == "New" || selectedTab == "Inbox")) {
            // Wait for 5s before fetching the inbox again
            await this.delay(5000)
            this.fetchInbox({ selectedTab, pagination })
        }
    }

    archiveInboxItem = (event, inboxItem) => {
        this.setState({ archiveLoading: true })
        apiPOSTReq(`${environment.payBaseUrl}/payables/bills/archiveBillInboxItems`, {}, { idList: [inboxItem.id] }, (err, resp) => {
            try {
                this.setState({ archiveLoading: false })
                const data = resp || {}
                console.log("/payables/bills/archiveBillInboxItems", err, data, { billInboxItemId: inboxItem.id })
                if (data.result) {
                    this.fetchInbox({ selectedTab: this.state.selectedTab, pagination: this.state.pagination })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /payables/bills/archiveBillInboxItems", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
        // stops the click event from bubbling up to the parent component 
        event.stopPropagation()
    }

    unarchiveInboxItem = (event, inboxItem) => {
        this.setState({ archiveLoading: true })
        apiPOSTReq(`${environment.payBaseUrl}/payables/bills/unarchiveBillInboxItems`, {}, { idList: [inboxItem.id] }, (err, resp) => {
            try {
                this.setState({ archiveLoading: false })
                const data = resp || {}
                console.log("/payables/bills/unarchiveBillInboxItems", err, data, { billInboxItemId: inboxItem.id })
                if (data.result) {
                    this.fetchInbox({ selectedTab: this.state.selectedTab, pagination: this.state.pagination })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /payables/bills/archiveBillInboxItems", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
        // stops the click event from bubbling up to the parent component 
        event.stopPropagation()
    }

    onChangeTab = (selectedTab) => {
        this.setState({ selectedTab: selectedTab })
        this.fetchInbox({ selectedTab: selectedTab, pagination: defaultPagination })
    }

    handleTableChange = (pagination, filters, sorter) => {
        this.fetchInbox({ pagination: pagination, selectedTab: this.state.selectedTab })
    }

    handleBillDetail = (inboxItem) => {
        this.props.history.push({
            pathname: '/payables/inbox/detail',
            state: { inboxItem: inboxItem }
        })
    }

    getColumns = (tab) => {
        const { theme } = this.props
        var columns = [
            {
                title: 'From',
                dataIndex: 'from',
                key: 'from',
                // width: '20%',
                render: (from, inboxItem) => {
                    var split = (from || "").split("<")
                    return (
                        <Flex start centerHorizontally gap='8px'>
                            <Text color={theme.colors.primary2}>{inboxItem.vendorName || split[0]}</Text>
                            {/* <span style={{ color: hexToRgba(theme.colors.primary2, inboxItem.vendorName ? 0.5 : 1) }}>{split[0]}</span> */}
                            {
                                !inboxItem.parsedInvoiceId && !inboxItem.processingError &&
                                <Tag title='Please wait for a few minutes while we process your bill'>Processing</Tag>
                            }
                        </Flex>
                    )
                }
            },
            {
                title: 'Subject',
                dataIndex: 'subject',
                key: 'Subject',
                // width: '20%'
            },
            // {
            //     title: 'Attachment',
            //     dataIndex: 'attachmentUrl',
            //     key: 'attachmentUrl',
            //     width: 80,
            //     render: attachmentUrl => <SignedLink usePin={true} tooltip={attachmentUrl.fileName} uri={attachmentUrl.uri} fileName={attachmentUrl.fileName} />
            // }
        ]

        if (tab == "Inbox" || tab == "New") {
            columns.push(
                {
                    title: 'Amount',
                    dataIndex: 'invoiceAmount',
                    key: 'amount',
                    // width: '12%',
                    render: invoiceAmount => "$" + toCurrency(invoiceAmount)
                }
            )
        }

        columns.push(
            {
                title: 'Date',
                dataIndex: 'createdDate',
                key: 'date',
                // width: '18%',
                render: createdDate => {
                    const localTZ = moment.tz.guess()
                    return moment.utc(createdDate).tz(localTZ).format("MMM D, h:mm A")
                },
                // width: '170px'
            }
        )
        console.log("tab", tab)
        if (tab == "Inbox" || tab == "New" || tab == "Processed" || tab == "Archive") {
            return columns = columns.concat([
                {
                    title: '',
                    key: 'action',
                    width: "40px",
                    onHover: true,
                    render: (inboxItem) => {
                        return (
                            // <TextButton text='DELETE' onClick={() => this.archiveInboxItem(inboxItem)} />
                            // <StyledExtLink title='Archive' onClick={(e) => this.archiveInboxItem(e, inboxItem)}><SVGIcon src={Archive} /> </StyledExtLink>
                            <Dropdown
                                placement='bottomRight'
                                trigger={['click']}
                                key={`${inboxItem.id}-Dropdown`}
                                overlay={
                                    <Menu style={{ padding: 24, borderRadius: 8, zIndex: 1001 }} key={`${inboxItem.id}-Menu`} onClick={() => { }}>
                                        <FlexColumn gap='24px'>
                                            {
                                               (tab === "Inbox" || tab === "New" || tab === "Processed") && !(inboxItem.converted || inboxItem.archived) &&
                                                <Popconfirm
                                                    title={<>
                                                        <div>Are you sure you want to archive this bill?</div>
                                                    </>}
                                                    onConfirm={(e) => {
                                                        e.stopPropagation()
                                                        this.archiveInboxItem(e, inboxItem)
                                                    }}
                                                    okText="Confirm"
                                                    placement="left"
                                                    okButtonProps={{ loading: this.state.archiveLoading }}
                                                    cancelText="Cancel"
                                                    onCancel={(e) => e.stopPropagation()}
                                                >
                                                    <TextButton text='ARCHIVE' onClick={(e) => e.stopPropagation()} />
                                                </Popconfirm>
                                            }
                                            {
                                                (tab === "Archive") &&
                                                <Popconfirm
                                                    title={<>
                                                        <div>Are you sure you want to unarchive? This bill will be moved to the Inbox tab</div>
                                                    </>}
                                                    onConfirm={(e) => {
                                                        e.stopPropagation()
                                                        this.unarchiveInboxItem(e, inboxItem)
                                                    }}
                                                    okText="Confirm"
                                                    placement="left"
                                                    okButtonProps={{ loading: this.state.archiveLoading }}
                                                    cancelText="Cancel"
                                                    onCancel={(e) => e.stopPropagation()}
                                                >
                                                    <TextButton text='UNARCHIVE' onClick={(e) => e.stopPropagation()} />
                                                </Popconfirm>
                                            }
                                            <TextButton text='VIEW ATTACHMENT' 
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    getPreSignedS3Url({ url: (inboxItem.attachmentUrl || {}).uri }, (err, preSignedUrl) => {
                                                        window.open(preSignedUrl, '_blank')
                                                    })
                                                }}
                                            />
                                        </FlexColumn>
                                    </Menu>
                                }
                            >
                                <ImageButton src={More} onClick={(e) => e.stopPropagation()} />
                            </Dropdown>
                        )
                    }
                }])
        } else {
            return columns
        }
    }

    copyEmail = (email) => {
        var copyStatus = "Copied!"
        navigator.clipboard.writeText(email)
        this.setState({ copyStatus })
        var self = this
        setTimeout(() => { self.setState({ copyStatus: "Copy email" }) }, 1000)
    }

    getBill = (id, callback) => {
        apiPOSTReq(`${environment.payBaseUrl}/payables/bills/getBill`, { "BankProvider": environment.bankProvider.crb }, { billId: id }, (err, resp) => {
            try {
                const data = resp
                console.log("/payables/bills/getBill", JSON.stringify(data))
                if (data.result && data.bill) {
                    callback(data.bill)
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /payables/bills/getBill", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    getConnections = (showSuccess) => {
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/codat/getConnections`, {}, {}, (err, resp) => {
            try {
                const data = resp || {};
                if (data.result) {
                    var connections = data.connections || []
                    console.log("BillPayments Inbox /integration/codat/getConnections data", data)
                    this.props.dispatch(addDataToStore(CODAT_DATA, { connections: connections, connection: connections.length > 0 && connections[0], company: data.company }))
                    if (showSuccess) {
                        if(connections.length > 0) this.updateConnection(connections[0])
                        this.setState({ showSettingsModal: true })
                    }
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("BillPayments Inbox getConnections error", { description: error.message })
            }
        })
    }

    updateConnection = (connection) => {
        var body = {
            companyId: connection.companyId,
            connectionId: connection.connectionId
        }
        console.log("BillPayments Inbox updateConnection body", body)
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/codat/updateConnection`, {}, body, (err, resp) => {
            try {
                const data = resp || {};
                if (data.result) {
                    console.log("BillPayments Inbox updateConnection data", data)
                    this.props.history.replace('/payables/inbox')
                    this.props.dispatch(addDataToStore(CODAT_DATA, { connection: data.connection }))
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                ErrorAlert({ description: error.message })
            }
        })
    }

    render() {
        const { theme, aionStore } = this.props
        const { selectedTab, inboxItems, pagination, loading, billInboxSetups, showMailboxSetup, modalWidth, showConnectAS, showDismissConnectWarning,
            showViewBillModal, billToView, categories, vendor, showSettingsModal, showSyncSetupComplete, showDismissSetupWarning } = this.state
        const lastBillInboxSetup = (billInboxSetups || []).length > 0 ? billInboxSetups[0].emailAddress : null

        const showConnectionBanner = showCodatConnectionBanner(aionStore)
        var tableTitle = "Inbox"
        var descText = <Flex start><span>Bills sent to your AP email at <a href={`mailto:${lastBillInboxSetup}`}>{lastBillInboxSetup}</a> will show up right here.</span></Flex>
        if(selectedTab == "Archive") {
            tableTitle = "Bills"
            descText = "Archived bills from your Inbox. Attachments other than bills are archived automatically."
        }
        return (
            <>
                <FlexColumn start grow>
                    <PageHeader
                        titleText="Bill Payments"
                        subtitle="Bill Payments"
                    />

                    {
                        showConnectionBanner &&
                        <BoldBanner
                            icon={<Image src={Lightning} />}
                            style={{ marginTop: "0", marginBottom: 24, width: "100%" }}
                            message="Sync your bills, vendors and more to your accounting system. Skip all the manual work"
                            cta={<TextButton underline color={theme.colors.primary2} text={'Get started now'} onClick={() => {
                                this.setState({ showConnectAS: true })
                                // this.props.history.push("/settings/sync")
                            }} />}
                            onClose={() => {
                                this.setState({ showDismissConnectWarning: true })
                                this.props.dispatch(addDataToStore(SAVE_DATA, { connectASBannerDismissed: true }))
                            }}
                        />
                    }

                    {/* <Tabs
                        defaultActiveKey={selectedTab}
                        onChange={this.onChangeTab}
                        style={{ marginBottom: 12 }}
                    >
                        <Tabs.TabPane tab="New" key="New" />
                        <Tabs.TabPane tab="Processed" key="Processed" />
                        <Tabs.TabPane tab="Archived" key="Archive" />
                    </Tabs> */}

                    {/* <div style={{ marginBottom: 24 }}>
                        <LabeledInput
                            nomargin
                            id="searchTerm"
                            key="searchTerm"
                            placeholder="Search"
                            prefixIcon={<Image width='12px' height='12px' src={Search} />}
                            onChange={this.handleSearchTextChange}
                            width='400px'
                        />
                    </div> */}

                    <TableView
                        id="inbox-table"
                        // className="borderless-table"
                        titleText={tableTitle}
                        descText={descText}
                        tableLayout='auto'
                        columns={this.getColumns(selectedTab)}
                        dataSource={inboxItems}
                        rowKey='id'
                        pagination={pagination}
                        loading={loading}
                        onChange={this.handleTableChange}
                        ctaContent={
                            <LabeledInput
                                nomargin
                                id="searchTerm"
                                key="searchTerm"
                                placeholder="Search"
                                prefixIcon={<Image width='12px' height='12px' src={Search} />}
                                onChange={this.handleSearchTextChange}
                                width='250px'
                            />
                        }
                        onRow={selectedTab == "New" ? (record, rowIndex) => {
                            return {
                                onClick: event => {
                                    record.parsedInvoiceId ?
                                        this.handleBillDetail(record)
                                        :
                                        ErrorAlert({ title: "Processing", description: "This bill is being processed by our AI Engine, check back shortly." })
                                }, // click row
                            }
                        }
                            :
                            null
                        }
                    />
                </FlexColumn>
                <Modal
                    visible={!(showConnectAS && showSettingsModal) && showMailboxSetup}
                    footer={null}
                    closable={true}
                    maskClosable={false}
                    width={modalWidth}
                    // style={{ top: 50 }}
                    destroyOnClose={true}
                    onCancel={() => this.setState({ showMailboxSetup: false })}
                    closeIcon={<Image src={ModalClose} />}
                >
                    <SetupMailbox
                        setupComplete={
                            (billInboxSetups) => {
                                this.setState({ billInboxSetups: billInboxSetups, showMailboxSetup: false })
                                this.fetchMailboxEmail()
                            }
                        }
                    />
                </Modal>
                <Modal
                    visible={showViewBillModal}
                    footer={null}
                    closable={true}
                    maskClosable={true}
                    width={(billToView || {}).previewDocumentUrls && billToView.previewDocumentUrls.length > 0 ? 1150 : 700}
                    style={{ top: 20 }}
                    destroyOnClose={true}
                    onCancel={() => this.setState({ showViewBillModal: false, billToView: null })}
                    closeIcon={<Image src={ModalClose} />}
                >
                    <ViewBill
                        bill={billToView}
                        vendor={vendor}
                        categories={categories}
                    />
                </Modal>
                <Modal
                    visible={showConnectAS}
                    footer={null}
                    closable={true}
                    maskClosable={true}
                    width={697}
                    destroyOnClose={true}
                    onCancel={() => this.setState({ showConnectAS: false })}
                    closeIcon={<Image src={ModalClose} />}
                >
                    <ConnectionModal onCancel={() => this.setState({ showConnectAS: false })} />
                </Modal>
                <Modal
                    visible={showSettingsModal}
                    footer={null}
                    closable={true}
                    maskClosable={true}
                    width={697}
                    destroyOnClose={true}
                    onCancel={() => this.setState({ showSettingsModal: false, showDismissSetupWarning: true })}
                    closeIcon={<Image src={ModalClose} />}
                >
                    <SettingsModal
                        learn
                        onCancel={() => this.setState({ showSettingsModal: false, showSyncSetupComplete: true })}
                    />
                </Modal>

                <AlertModal
                    success
                    visible={showSyncSetupComplete}
                    title='Sync setup complete'
                    description={`You're all set! The connection was successful and your sync is in progress.`}
                    buttonTitle='OKAY'
                    onConfirm={() => this.setState({ showSyncSetupComplete: false })}
                />

                <AlertModal
                    imgSrc={Info}
                    visible={showDismissConnectWarning}
                    title='Accessing your Connections'
                    description={<Text>You can go to Business Profile {'>'} Accounting Systems anytime to connect Aion to your accounting system.</Text>}
                    buttonTitle='OKAY'
                    onConfirm={() => this.setState({ showDismissConnectWarning: false })}
                />

                <AlertModal
                    imgSrc={Info}
                    visible={showDismissSetupWarning}
                    title='Bill settings incomplete'
                    description={<Text>You can go to Business Profile {'>'} Accounting Systems {'>'} Settings <Image width='14px' height='14px' src={SettingsIcon} /> anytime to complete your setup.</Text>}
                    buttonTitle='OKAY'
                    onConfirm={() => this.setState({ showDismissSetupWarning: false })}
                />
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        bkStore: state.bookkeepingAppReducer,
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Index))