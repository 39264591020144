import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import { Select, Table, Switch } from 'antd'

import { Text, Tag } from '../../Reusable/Text'
import { Flex, FlexColumn, Container, GradientContainer } from '../../Reusable/Container'
import { FromAccountOption, RecipientOption } from '../../Reusable/Option'
import PageHeader from '../../Reusable/PageHeader'
import { LabeledInput, GradientDivider } from '../../Reusable/Input'
import ActionMenu from '../../Reusable/ActionMenu'
import { ErrorAlert } from '../../Reusable/Alert'
import { Button, TextButton } from '../../Reusable/Button'
import AionIcon from '../../../Images/aion-bank-icon.png'
import ChaseIcon from '../../../Images/chase-icon.png'
import TransferSent from '../../../Images/transfer-sent-for-approval.png'
import MagnifyingGlass from '../../../Images/magnifying-glass.png'
import Individual from '../../../Images/individual-recipient.png'
import Business from '../../../Images/business-recipient.png'

import { PlusOutlined, ArrowLeftOutlined, CheckOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { addDataToStore, SAVE_DATA } from '../../../Actions/actions'
import { toCurrency, getBPAccountsWithAccess, toNumberStr, getFeatureLimit } from '../../../Utils/util'
import { apiPOSTReq } from '../../../Utils/api'
import environment from '../../../environment'
import TableView from '../../Reusable/TableView'

const { Option } = Select

class Index extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            pagination: {
                current: 1,
                pageSize: 1000,
                hideOnSinglePage: true
            },
            recipients: [],
            showTransferModal: false,
            transferMethods: {},
            showResult: false,
            step: 0,
            steps: 3,
            confirmDisabled: true,
            sendEmail: false,
            referenceDocuments: [],
            // transferAmount: 2000,
            // fromAccount: { mask: '1234', nickName: 'Operating' },
            // recipient: { nameOnAccount: 'Ben Holland' },
            // transferPurpose: 'Transfer for April 2022\'s orders',
        }

        this.nameRef = React.createRef()
        this.bankRef = React.createRef()
        this.accountNumberRef = React.createRef()
        this.routingNumberRef = React.createRef()
    }

    componentDidMount() {
        const { theme, bankingStore } = this.props
        const { AllRecipients } = bankingStore
        console.log("fetchCounterparties: AllRecipients", AllRecipients)
        this.setState({ loading: false, recipients: AllRecipients })
    }

    viewRecipient = (recipient) => {
        this.props.history.push({
            pathname: '/banking/recipients/details',
            state: { recipient }
        })
    }

    createRecipient = () => {
        const { recipientName, recipientEmail, type, transferMethods } = this.state
        const { store } = this.props
        const { UserInfo } = store || {}

        var body = {
            counterparty: {
                "type": type,
                "nameOnAccount": recipientName || "",
                "email": recipientEmail[0] || "",
                "notificationEmails": [],
                "contextIdentifier": recipientEmail[0] || "",
                "initiatedBy": `${UserInfo.FirstName} ${UserInfo.LastName}`,
            },
            "BankProvider": environment.bankProvider.crb,
            "ruleType": "Recipient"
        }

        if (transferMethods.ACH) {
            body.counterparty.ach = {
                "accountNumber": transferMethods.ACH.accountNumber || "",
                "routingNumber": transferMethods.ACH.routingNumber || "",
                "accountType": (transferMethods.ACH.accountType || "").toLowerCase(),
            }
        }

        if (transferMethods.Wire) {
            body.counterparty.wire = {
                "accountNumber": transferMethods.Wire.accountNumber || "",
                "routingNumber": transferMethods.Wire.routingNumber || "",
                "addressOnAccount": this.formatAddr(transferMethods.Wire.addressOnAccount),
                "bankName": transferMethods.Wire.bankName,
                "bankAddress": this.formatAddr(transferMethods.Wire.bankAddress),
                "accountType": (transferMethods.Wire.accountType || "").toLowerCase(),
            }
        }

        console.log("createRecipient: ", body)

        this.setState({ submitLoading: true })

        apiPOSTReq(`${environment.bbBaseUrl}/bb/createCounterparty`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            this.setState({ submitLoading: false })
            try {
                // if (err) throw Error(err)
                const data = resp || {}
                if (data.result) {
                    console.log("bb/createCounterparty", data)
                    // window.history.back()
                    this.setState({ showResult: true })
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/createCounterparty err", error, resp, body)
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    formatAddr = (address) => {
        return {
            line1: address.line1,
            line2: address.line2 || null,
            city: address.city,
            countrySubDivisionCode: address.countrySubDivisionCode,
            state: address.countrySubDivisionCode,
            postalCode: address.postalCode
        }
    }

    handleSelectAccount = (value, option) => {
        var Accounts = getBPAccountsWithAccess("Payments", { permType: "manage", getActiveAccounts: true })
        console.log("handleSelectAccount: ", value)
        console.log("handleSelectAccount Accounts: ", Accounts)

        var account = Accounts.find(a => a.accountId === value)

        this.setState({ fromAccount: account }, this.isConfirmEnabled)
    }

    saveUserInput = (id, dataToSave) => {
        this.setState({ [id]: dataToSave }, this.isConfirmEnabled)
    }

    selectRecipient = (value, option) => {
        const { recipients } = this.state
        const recipient = recipients.find(r => r.objectId === value)
        console.log("selectRecipient: ", recipient)
        this.setState({ recipient }, this.isConfirmEnabled)
    }

    getTransferMethods = () => {
        const { recipient } = this.state
        const transferMethods = []

        if (recipient && recipient.ach) {
            recipient.ach.type = "ACH"
            transferMethods.push(recipient.ach)
        }

        if (recipient && recipient.wire) {
            recipient.wire.type = "Wire"
            transferMethods.push(recipient.wire)
        }

        if (recipient && recipient.check) {
            recipient.check.type = "Check"
            transferMethods.push(recipient.check)
        }

        console.log("selectRecipient: getTransferMethods", transferMethods)

        return transferMethods
    }

    isConfirmEnabled = () => {
        const { step, fromAccount, recipient, transferMethod, transferAmount, description, note } = this.state

        switch (step) {
            case 0:
                this.setState({ confirmDisabled: !fromAccount || !recipient || !transferMethod || !transferAmount || !description })
                break
            case 1:
                this.setState({ confirmDisabled: !note })
                break
            default:
                // this.setState({ confirmDisabled: true })
                break
        }
    }

    next = () => {
        const { step, fromAccount, recipient, selectedRows } = this.state

        switch (step) {
            case 0:
                this.setState({ step: 1, confirmDisabled: true })
                break
            case 1:
                this.setState({ step: 2 })
                break
            case 2:
                this.confirmPayment()
                break
            default:
                break
        }
    }

    confirmPayment = () => {
        const { step, fromAccount, recipient, transferMethod, transferAmount, sendEmail, achDesc, note, description } = this.state
        const { store } = this.props
        const { UserInfo } = store || {}

        const paymentAmt = toNumberStr(transferAmount)

        var secCode = ""
        if (recipient.type === "Business") {
            secCode = "ccd"
        } else if (recipient.type === "Individual") {
            secCode = "ppd"
        }

        switch (transferMethod.type) {
            case 'ACH':
                var body = {
                    achTransferObj: {
                        "accountId": fromAccount.accountId || "",
                        "accountNumber": fromAccount.accountNumber || "",
                        "amount": paymentAmt,
                        "counterpartyName": recipient.nameOnAccount || "",
                        "counterpartyId": recipient.id || "",
                        "counterpartyType": recipient.type || "",
                        "direction": "credit",
                        "secCode": secCode,
                        "email": recipient.email,
                        "sendEmail": sendEmail,
                        "userNote": note || null,
                        "description": description || null,
                        "contextIdentifier": `Transfer $${toCurrency(paymentAmt)} to ${recipient.nameOnAccount || ""}`,
                        "initiatedBy": `${UserInfo.FirstName} ${UserInfo.LastName}`,
                        "initiatedByUserName": UserInfo.Email,
                        "status": "pending",
                        "statusMessage": "Transfer has been queued for processing."
                    },
                    "ruleType": "ACH"
                }

                console.log('createACH body: ', body)

                this.setState({ submitLoading: true })

                apiPOSTReq(`${environment.bbBaseUrl}/bb/createACH`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
                    this.setState({ submitLoading: false })
                    try {
                        if (err) throw Error(err)
                        const data = resp || {}
                        if (data.result) {
                            console.log("/createACH data ", data)
                            this.setState({ showResult: true })
                        } else {
                            throw Error(data.responseMessage || data.error)
                        }
                    } catch (error) {
                        console.log("/createACH err", error.stack)
                        ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
                    }
                })
                break
            case 'Wire':
                var body = {
                    "wireTransferObj": {
                        "accountId": fromAccount.accountId || "",
                        "accountNumber": fromAccount.accountNumber || "",
                        "amount": paymentAmt,
                        "counterpartyName": recipient.nameOnAccount || "",
                        "counterpartyId": recipient.id || "",
                        "counterpartyType": recipient.type || "",
                        "email": recipient.email,
                        "sendEmail": sendEmail,
                        "userNote": note || "",
                        "instructions": note || "",
                        "contextIdentifier": `Transfer $${toCurrency(paymentAmt)} to ${recipient.nameOnAccount || ""}`,
                        "initiatedBy": `${UserInfo.FirstName} ${UserInfo.LastName}`,
                        "initiatedByUserName": UserInfo.Email,
                        "status": "pending",
                        "statusMessage": "Transfer has been queued for processing."
                    },
                    "ruleType": "Wire"
                }
                this.setState({ submitLoading: true })
                console.log(`${environment.bbBaseUrl}/bb/createWire`, body)
                apiPOSTReq(`${environment.bbBaseUrl}/bb/createWire`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
                    this.setState({ submitLoading: false })
                    try {
                        if (err) throw Error(err)
                        const data = resp || {}
                        if (data.result) {
                            console.log("/createWire data ", data)
                            this.setState({ showResult: true })
                        } else {
                            throw Error(data.responseMessage || data.error)
                        }
                    } catch (error) {
                        console.log("/createWire debit err", error.stack)
                        ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
                    }
                })
                break
        }
    }

    render() {
        const { loading, step, steps, fromAccount, transferAmount, transferMethod, recipients, recipient, recipientEmail, type, selectedRowKeys,
            selectedRows, showResult, submitLoading, confirmDisabled, transferPurpose, description, referenceDocuments, note } = this.state
        const { theme, bankingStore } = this.props
        var Accounts = getBPAccountsWithAccess("Payments", { permType: "manage", getActiveAccounts: true })
        const { ACHRecipients, WireRecipients, ACHDailyUsage, WireDailyUsage, BusinessDailyLimits, ACHFees, WireFees } = bankingStore
        var achRecipients = ACHRecipients || []
        var businessDailyLimits = BusinessDailyLimits || {}
        var achDailyLimit = businessDailyLimits.ACH || parseFloat(getFeatureLimit("BusinessBanking.Payments.Daily ACH Limit")) || 5000
        var wireDailyLimit = businessDailyLimits.Wire || parseFloat(getFeatureLimit("BusinessBanking.Payments.Daily Wire Limit")) || 5000
        var achFees = ACHFees || {}
        var wireFees = WireFees || {}
        achDailyLimit = `$${toCurrency(achDailyLimit)}`
        var achDailyUsage = ACHDailyUsage
        var wireDailyUsage = WireDailyUsage



        var { invoiceNumberError, emailError } = this.state

        // var confirmDisabled = true //(!transferMethods || Object.keys(transferMethods).length === 0) || !recipientName || !recipientEmail || !type

        var columns = [
            {
                dataIndex: 'nameOnAccount',
                key: 'nameOnAccount',
                render: (name, record) => <Text>{name || `Test ${record.bankAddress ? 'Wire' : 'ACH'}`}</Text>,
            },
            {
                dataIndex: 'type',
                key: 'type',
                align: 'right',
                render: (type, record) => <Flex end>
                    <Tag>{type}</Tag>
                    <Tag style={{ marginLeft: 8 }}>{record.bankIcon && <img style={{ marginRight: 8 }} width={16} height={16} src={record.bankIcon} />} {record.accountNumber.substring(record.accountNumber.length - 4, record.accountNumber.length)}</Tag>
                </Flex>
            }
        ]

        var options = []
        var filteredAccounts = Accounts.filter(item => { return (!['ACCOUNTS_RECEIVABLE', 'Transitional'].includes(item.accountType)) }) || []
        filteredAccounts.forEach(account => {
            options.push(
                <Option key={account.accountId} value={account.accountId} style={{ backgroundColor: "transparent" }}>
                    <FromAccountOption account={account} />
                </Option>)
        })

        var recipientOptions = []
        achRecipients.concat(WireRecipients || []).forEach((recipient, index) => {
            if (!recipient.invalid) {
                recipientOptions.push(
                    <Option key={recipient.objectId} value={recipient.objectId} style={{ backgroundColor: "transparent" }}>
                        <RecipientOption recipient={recipient} />
                    </Option>)
            }
        })

        var transferMethods = this.getTransferMethods()
        var transferType = (transferMethod || {}).type

        return (
            <>
                <Flex between>
                    <Flex start style={{ width: '100%', paddingLeft: 66, paddingRight: 80 }}>
                        <ArrowLeftOutlined onClick={() => window.history.back()} style={{ color: '#1199FF', fontSize: 22, marginTop: 92, marginRight: 26 }} />
                        <FlexColumn style={{ width: '100%' }} start>
                            <PageHeader
                                titleText="Banking"
                            />

                            {
                                step === 0 &&
                                <>
                                    <Container style={{ width: '100%' }} shadow>
                                        <Flex between>
                                            <div style={{ width: '50%', paddingRight: 12 }}>
                                                <LabeledInput
                                                    inputRef={this.invoiceNumberInput}
                                                    margin="10px 0"
                                                    label="From Account"
                                                    type="select"
                                                    id="fromAccount"
                                                    key="fromAccount"
                                                    placeholder="Select account"
                                                    value={fromAccount && <><img width='16px' height='16px' src={AionIcon} style={{ marginRight: 8, marginBottom: 2 }} /><Text color='#7384AA'>{`${(fromAccount.nickName || `Business ${fromAccount.accountSubType}`)} • ${fromAccount.mask}`}</Text></>}
                                                    autoFocus
                                                    onChange={this.handleSelectAccount}
                                                    error={invoiceNumberError}
                                                    errorMessage="From account is required"
                                                >
                                                    {options}
                                                </LabeledInput>
                                            </div>

                                            <div style={{ width: '50%', paddingLeft: 12 }}>
                                                <LabeledInput
                                                    inputRef={this.invoiceNumberInput}
                                                    margin="10px 0"
                                                    label="Recipient"
                                                    type="select"
                                                    id="recipient"
                                                    key="recipient"
                                                    placeholder="Select recipient"
                                                    value={recipient && <><img width='16px' height='16px' src={recipient.type === "Business" ? Business : Individual} style={{ marginRight: 8, marginBottom: 2 }} /><Text color='#7384AA'>{recipient.nameOnAccount}</Text></>}
                                                    onChange={this.selectRecipient}
                                                    error={invoiceNumberError}
                                                    errorMessage="Recipient email is required"
                                                >
                                                    {recipientOptions}
                                                </LabeledInput>
                                            </div>

                                        </Flex>
                                    </Container>

                                    {
                                        (recipient) ?
                                            // <Container shadow style={{ marginTop: 24 }}>
                                                <FlexColumn start>
                                                    <Flex between>
                                                        <Text heading weight={500} style={{ marginBottom: 8 }} color='#000000'>Transfer Methods</Text>
                                                        <Flex start>
                                                            {/* <TextButton uppercase onClick={() => this.setState({ showTransferModal: true, transferType: "ACH" })} icon={<PlusOutlined style={{ color: '#1199FF' }} />} text='ACH Transfer' />
                                                            <TextButton uppercase onClick={() => this.setState({ showTransferModal: true, transferType: "Wire" })} icon={<PlusOutlined style={{ marginLeft: 25, color: '#1199FF' }} />} text='Wire Transfer' /> */}
                                                            {/* <TextButton icon={<PlusOutlined style={{ marginLeft: 25, color: '#1199FF' }} />} text='Check Transfer' /> */}
                                                        </Flex>
                                                    </Flex>
                                                    <GradientDivider reverse marginTop='24px' marginBottom='30px' />
                                                    <TableView
                                                        id="transfers-table"
                                                        showHeader={false}
                                                        pagination={false}
                                                        tableLayout='auto'
                                                        titleText='One Time Transfer'
                                                        columns={columns}
                                                        dataSource={transferMethods}
                                                        rowKey='type'
                                                        rowSelection={{
                                                            type: "radio",
                                                            onChange: (selectedRowKeys, selectedRows) => {
                                                                console.log("onSelectRows: ", selectedRows)
                                                                this.setState({ selectedRowKeys, selectedRows, transferMethod: selectedRows[0] }, this.isConfirmEnabled)
                                                            },
                                                            selectedRowKeys: this.state.selectedRowKeys
                                                        }}
                                                        expandable={{
                                                            expandedRowRender: invoice => {
                                                                return <FlexColumn start style={{ width: '100%', borderLeft: '1px solid #1199FF', marginLeft: 20, padding: '12px 0 18px' }}>
                                                                    <div style={{ width: 400, paddingLeft: 24 }}>
                                                                        <LabeledInput
                                                                            inputRef={this.accountNumberInput}
                                                                            // margin="10px 0"
                                                                            type="number"
                                                                            label="Transfer Amount"
                                                                            id="matchedAmount"
                                                                            key="matchedAmount"
                                                                            className="no-left-padding"
                                                                            placeholder="Enter transfer amount"
                                                                            // instruction={`Unpaid Amount after Matching: $${toCurrency((invoice.paymentDate ? (invoice.amount ? invoice.amount : 0) : (invoice.balance ? invoice.balance : 0)) - invoice.matchAmount)}`}
                                                                            min={0}
                                                                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                            value={transferAmount}
                                                                            onChange={(value) => {
                                                                                this.setState({ transferAmount: value })
                                                                            }}
                                                                        // error={accountNumberError}
                                                                        // errorMessage="Account number is required"
                                                                        // value={accountNumber}
                                                                        />
                                                                    </div>
                                                                    <div style={{ width: 400, paddingLeft: 24 }}>
                                                                        <LabeledInput
                                                                            inputRef={this.accountNumberInput}
                                                                            // margin="10px 0"
                                                                            label="Short Description (maximum of 10 characters)"
                                                                            id="description"
                                                                            key="description"
                                                                            maxLength={10}
                                                                            className="no-left-padding"
                                                                            placeholder="Enter a description"
                                                                            value={description}
                                                                            onChange={this.handleOnChange}
                                                                        // error={accountNumberError}
                                                                        // errorMessage="Account number is required"
                                                                        // value={accountNumber}
                                                                        />
                                                                    </div>
                                                                    <Flex between style={{ width: 400, paddingLeft: 24, marginTop: 12 }}>
                                                                        <Text size='14px' color='#7384AA'>Send Email To Recipient</Text>
                                                                        <Switch size='small' key="sendEmail" checked={this.state.sendEmail} onChange={(checked, event) => { this.saveUserInput("sendEmail", checked) }} />
                                                                    </Flex>
                                                                </FlexColumn>
                                                                // </Flex>
                                                            },
                                                            expandedRowKeys: selectedRowKeys,
                                                            expandIconColumnIndex: -1, //columns.length: to show icon at the last column -1: to hide column
                                                            columnWidth: "2px",
                                                            indentSize: "20px",
                                                        }}
                                                    />
                                                </FlexColumn>
                                            // </Container>
                                            :
                                            <GradientContainer style={{ width: '100%', margin: '24pt 48pt 24pt 0' }}>
                                                <Flex start centerHorizontally style={{ width: '100%' }}>
                                                    <img src={MagnifyingGlass} width='70px' height='70px' />
                                                    <FlexColumn start style={{ padding: 10, marginLeft: 14 }}>
                                                        <Text color='#444444'>Can't find the recipient you're looking for? Maybe you could trying adding them or just do a one time transfer instead.</Text>
                                                        {/* <Paragraph size="16px" noMargin color='#1199FF'>Show me how <ArrowRightOutlined style={{ marginLeft: 4, fontSize: 12 }} /></Paragraph> */}
                                                        <Flex start style={{ marginTop: 16, zIndex: 2 }}>
                                                            {/* <TextButton uppercase onClick={() => this.props.history.push('/banking/recipients/new')} rightIcon={<ArrowRightOutlined style={{ color: '#1199FF', marginRight: 25 }} />} text='One Time Transfer' /> */}
                                                            <TextButton uppercase onClick={() => this.props.history.push('/banking/recipients/new')} icon={<PlusOutlined style={{ color: '#1199FF' }} />} text='Add New Recipient' />
                                                        </Flex>
                                                    </FlexColumn>
                                                </Flex>
                                            </GradientContainer>
                                    }
                                </>
                            }

                            {
                                step === 1 &&
                                <>
                                    <Container shadow style={{ marginTop: 24 }}>
                                        <FlexColumn start>
                                            <Text size='20px' weight={400} style={{ marginBottom: 8 }}>Additional Details</Text>
                                            <LabeledInput
                                                label="What is the purpose of the transfer? (maximum of 80 characters)"
                                                id="note"
                                                key="note"
                                                className="no-left-padding"
                                                placeholder="Eg: Transfer for April 2022's orders"
                                                maxLength={80}
                                                value={note}
                                                onChange={this.handleOnChange}
                                            />

                                            <LabeledInput
                                                optional
                                                type='dragger'
                                                label="Reference Documents"
                                                id="transferPurpose"
                                                key="transferPurpose"
                                                className="no-left-padding"
                                                placeholder="Eg: Transfer for April 2022's orders"
                                                value={transferPurpose}
                                            />
                                        </FlexColumn>
                                    </Container>
                                </>
                            }

                            {
                                step === 2 &&
                                <FlexColumn>
                                    <Container shadow style={{ marginTop: 24 }}>
                                        <FlexColumn start>
                                            <Flex between>
                                                <Container shadow padding='24px' style={{ width: 280, height: 112 }}>
                                                    <Flex start>
                                                        <div style={{ width: 8, height: 64, background: '#F4F5F7', borderRadius: 8, marginRight: 40 }} />
                                                        <FlexColumn left>
                                                            <Text uppercase color='#737387' weight={500}>{fromAccount.nickName}</Text>
                                                            <Tag style={{ marginTop: 8 }}>{<img style={{ marginRight: 8 }} width={16} height={16} src={AionIcon} />} {fromAccount.mask}</Tag>
                                                        </FlexColumn>
                                                    </Flex>
                                                </Container>
                                                <FlexColumn center>
                                                    <Text size='20px' color='#1199FF'>{`$ ${toCurrency(transferAmount)}`}</Text>
                                                    <ArrowRightOutlined style={{ color: '#1199FF', marginTop: 12, fontSize: 16 }} />
                                                </FlexColumn>
                                                <Container shadow padding='24px' style={{ width: 280, height: 112 }}>
                                                    <Flex end>
                                                        <FlexColumn right>
                                                            <Text uppercase color='#737387' weight={500}>{recipient.nameOnAccount}</Text>
                                                            <Tag style={{ marginTop: 8 }}>{<img style={{ marginRight: 8 }} width={16} height={16} src={ChaseIcon} />} {fromAccount.mask}</Tag>
                                                        </FlexColumn>
                                                        <div style={{ width: 8, height: 64, background: '#F4F5F7', borderRadius: 8, marginLeft: 40 }} />
                                                    </Flex>
                                                </Container>
                                            </Flex>
                                        </FlexColumn>
                                    </Container>

                                    <Container shadow style={{ marginTop: 24 }}>
                                        <FlexColumn start>
                                            <Text size='20px' weight={400} style={{ marginBottom: 8 }}>Additional Details</Text>

                                            {
                                                note &&
                                                <LabeledInput
                                                    type='read-only'
                                                    label="What is the purpose of the transfer?"
                                                    id="transferPurpose"
                                                    key="transferPurpose"
                                                    className="no-left-padding"
                                                    placeholder="Eg: Transfer for April 2022's orders"
                                                    value={note}
                                                />
                                            }

                                            {
                                                referenceDocuments && referenceDocuments.length > 0 &&
                                                <LabeledInput
                                                    type='read-only'
                                                    label="Reference Documents"
                                                    id="referenceDocuments"
                                                    key="referenceDocuments"
                                                    className="no-left-padding"
                                                    placeholder="Eg: Transfer for April 2022's orders"
                                                />
                                            }
                                        </FlexColumn>
                                    </Container>
                                </FlexColumn>
                            }
                        </FlexColumn>
                    </Flex>
                    <ActionMenu
                        steps={steps}
                        step={step}
                        noBackground
                        content={<FlexColumn center>
                            {
                                step > 0 &&
                                <TextButton onClick={() => this.setState({ step: step - 1 })} margin='0 0 18px' uppercase text='Previous' icon={<ArrowLeftOutlined style={{ color: '#1199FF' }} />} />
                            }
                            <Button
                                solid
                                loading={submitLoading}
                                disabled={confirmDisabled}
                                width="210px"
                                margin='20px 0 5px 0'
                                onClick={() => { !confirmDisabled && this.next() }}
                                icon={step === steps - 1 && <CheckOutlined style={{ color: 'white' }} />}
                                rightIcon={step < 2 && <ArrowRightOutlined style={{ color: confirmDisabled ? '#737387' : 'white' }} />}
                                text={step < 2 ? "NEXT" : "CONFIRM"}
                            />
                        </FlexColumn>}
                        summary={transferMethod && {
                            title: "Things To Know",
                            data: [
                                {
                                    description: `Daily ${transferType} Limit`,
                                    value: `$ ${toCurrency(transferType === 'ACH' ? achDailyLimit : wireDailyLimit)}`,
                                }, {
                                    description: `Pending ${transferType} Transfers`,
                                    value: `$ ${toCurrency(transferType === 'ACH' ? achDailyUsage : wireDailyUsage)}`,
                                }, {
                                    description: `Fees`,
                                    value: `$ ${toCurrency(transferType === 'ACH' ? achFees.Outgoing : wireFees.Outgoing)}`,
                                }, {
                                    description: "Additional Information",
                                    value: transferType === 'ACH' ?
                                        `The daily cut-off time for ACH payments is 3:00 pm ET. Payments created after this time are processed the next business day.`
                                        :
                                        `The daily cut-off time for Wire payments is 12:00 pm PST. Payments created after this time are processed the next business day.`,
                                },
                            ]
                        }}
                    />
                </Flex>
            </>
        )
    }

    handleOnChange = (event) => {
        const target = event.target
        this.saveUserInput(target.id || target.name, target.value)
    }

    twoFAValidated = () => {
        this.props.dispatch(addDataToStore(SAVE_DATA, { "TwoFAForTransfersValidated": true }))
        this.loadNext()
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer,
        bankingStore: state.bankingAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)))