import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { Table, Popconfirm, message, Tabs, Dropdown, Menu } from 'antd'

// Util
import environment from '../../../environment'
import { apiPOSTReq } from '../../../Utils/api'
import { getResourcePerm, getUserApps, renderBankIcon } from '../../../Utils/util'
import PageHeader from "../../Reusable/PageHeader"
import { Flex, FlexColumn, CardContainer } from '../../Reusable/Container'
import { LabeledInput } from '../../Reusable/Input'
import Import from './Import'

// Images
import { ErrorAlert } from '../../Reusable/Alert'
import { Tag } from '../../Reusable/Text'
import { Image } from '../../Reusable/Image'
import { Button, TextButton, ImageButton } from '../../Reusable/Button'
import { withTheme } from 'styled-components'
import Modal from 'antd/lib/modal/Modal'
import { addDataToStore, SAVE_DATA } from '../../../Actions/actions'
import ModalClose from '../../../Images/modal-close.png'
import Search from '../../../Images/search.png'
import More from "../../../Images/more.png"
import Edit from "../../../Images/edit.png"
import Upload from "../../../Images/upload.svg"
import Add from "../../../Images/add-white.svg"
import TableView from '../../Reusable/TableView'

const { TabPane } = Tabs


class Index extends Component {
    ref = null
    loadingAnimation = null
    state = {
        loading: false,
        pagination: {
            current: 1,
            pageSize: 50,
        },
        inactivePagination: {
            current: 1,
            pageSize: 50,
        },
        searchPagination: {
            current: 1,
            pageSize: 50,
        },
        accountingSW: '',
        tab: 'active',
        inactiveVendors: [],
    }

    componentDidMount() {
        this.fetchData({ pagination: this.state.pagination })
        this.fetchRecipientPM()
    }

    componentDidUpdate(prevProps) {
        let { location } = this.props
        var state = location.state ? location.state : {}
        if (state.reloadData) {
            this.props.location.state = {}
            this.fetchData({ pagination: this.state.pagination })
        }
    }

    fetchData(options) {
        const { pagination } = options

        const body = {
            "size": pagination.pageSize,
            "page": pagination.current - 1,
            "viewBy": "FILTER_ACTIVE",
        }
        this.setState({ loading: true })
        apiPOSTReq(`${environment.payBaseUrl}/payables/vendors/getVendors`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            this.setState({ loading: false })
            try {
                const data = resp
                console.log("/getVendors", data)
                if (data.result) {
                    this.setState({
                        ...data,
                        pagination: {
                            ...pagination,
                            total: data.count
                        }
                    })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getActiveCustomers", error, err, resp)
                this.setState({ loading: false })
                ErrorAlert({ description: error.message })
            }
        })
    }

    deactivateVendor = (vendor) => {
        const body = {
            "vendorId": vendor.id
        }

        this.setState({ deactivateLoading: true })
        console.log("/payables/vendors/deactivate", JSON.stringify(body))

        apiPOSTReq(`${environment.payBaseUrl}/payables/vendors/deactivate`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            try {
                this.setState({ deactivateLoading: false })
                const data = resp
                console.log("/payables/vendors/deactivate", data)
                if (data.result) {
                    message.success(`Successfully deactivated!`)
                    this.fetchData({ pagination: this.state.pagination })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR deactivatevendor", error, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    handleTableChange = (pagination) => {
        this.fetchData({ pagination })
    }

    selectRow = (record) => {
        this.props.history.push({
            pathname: '/payables/vendors/edit-vendor',
            state: { vendor: record }
        })
    }

    handleTableChange = (pagination, filters, sorter) => {
        this.fetchData({ pagination })
    }

    setRowClassName = (record) => {
        var { selectedVendor } = this.state
        selectedVendor = selectedVendor || {}
        console.log("setRowClassName", record, selectedVendor, record.id === selectedVendor.id ? 'selected-row' : '')
        return record.id === selectedVendor.id ? 'selected-row' : ''
    }

    handleNewVendor = () => {
        this.props.history.push({
            pathname: '/payables/vendors/create',
            state: { newVendor: true }
        })
    }

    handleImportVendor = () => {
        this.setState({ bill: null, vendor: null, category: null, showImportModal: true, modalWidth: 700 })
    }

    onSearch = ({ vendorSearch }) => {
        if (!vendorSearch) {
            return this.fetchData({ pagination: this.state.pagination })
        }
        let pagination = this.state.searchPagination
        const body = {
            "size": pagination.pageSize,
            "page": pagination.current - 1,
            "viewBy": "FILTER_BY_ACTIVE",
        }

        if (vendorSearch && vendorSearch.includes("@")) {
            body.searchEmail = vendorSearch
        } else {
            body.searchStr = vendorSearch
        }

        this.setState({ loading: true })
        apiPOSTReq(`${environment.payBaseUrl}/payables/vendors/lookupVendors`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            this.setState({ loading: false })
            try {
                const data = resp
                console.log("/lookupVendors", data)
                if (data.result) {
                    this.setState({
                        ...data,
                        pagination: {
                            ...pagination,
                            total: data.count
                        }
                    })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getContractors", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    onSearchChange = (e) => {
        this.setState({ vendorSearch: e.target.value })
        this.setState({
            pagination: {
                current: 1,
                pageSize: 50
            }
        })
        this.onSearch({ vendorSearch: e.target.value })
    }

    fetchRecipientPM = () => {
        this.setState({ loading: true })

        const body = { railType: null}

        apiPOSTReq(`${environment.bbBaseUrl}/bb/getPaymentMethodsByRecipients`, null, body, (err, resp) => {
            try {
                const data = resp
                if (data.result) {
                    if (err) throw new Error(err)

                    if (resp && resp.result) {
                        var sortedArray = Object.values(resp.recipientMap || []).sort((a, b) => {
                            // Handle null values by setting them to a default date in the distant past
                            const dateA = a.lastTransferDate || "1900-01-01";
                            const dateB = b.lastTransferDate || "1900-01-01";
                            return new Date(dateB) - new Date(dateA);  // Sort in descending order
                        });

                        this.setState({
                            loading: false,
                            // recipients: sortedArray,
                            noData: (!sortedArray || sortedArray.length === 0),
                            paymentMethodRecipientMap: resp.paymentMethodRecipientMap,
                            recipientMap: resp.recipientMap,
                        })
                    }
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getPaymentMethodsByRecipients", error, err, resp)
            } finally {
                this.setState({
                    loading: false
                })
            }
        })
    }

    render() {
        var { theme } = this.props
        var { pagination, vendors, tab, inactiveVendors, paymentMethodRecipientMap } = this.state
        var vendors = vendors || []

        const userApps = getUserApps(this.props.aionStore)
        const { CurrentSync } = this.props.aionStore
        let qbo = false
        if (CurrentSync && CurrentSync.platformName === 'QUICKBOOKS' && CurrentSync.syncSetupCompleted && userApps && userApps.includes("Payables")) {
            qbo = true
        }

        var columns = [
            {
                title: 'Name',
                dataIndex: 'vendorName',
                key: 'vendorName',
                render: (item, record) => (<div>
                    <span>{item}</span>
                </div>)
            },
            {
                title: 'Email',
                dataIndex: 'primaryEmail',
                key: 'primaryEmail',
                render: primaryEmail => primaryEmail ? primaryEmail : "--"
            },
            {
                title: 'Bank detail',
                dataIndex: 'deliveryMode',
                key: 'deliveryMode',
                // render: deliveryMode => deliveryMode ? <Tag style={{ margin: "5px 0" }} primary>{deliveryMode}</Tag> : <Tag style={{ margin: "5px 0" }}>None</Tag>
                render: (item, vendor) => {
                            return (
                                <FlexColumn start gap='8px'>
                                    {
                                        paymentMethodRecipientMap &&
                                        <Flex start wrap gap='8px'>
                                            {
                                                (paymentMethodRecipientMap[vendor.associatedCPObjectId] || []).map(paymentMethod => {
                                                    return <Tag onClick={() => {/*onViewTransfer(paymentMethod, recipient)*/}}>{renderBankIcon(paymentMethod.bankDetail)}{paymentMethod.nickName} • {paymentMethod.bankDetail.accountNumber ? paymentMethod.bankDetail.accountNumber.slice(-4) : ""}</Tag>
                                                })
                                            }
                                        </Flex>
                                    }
                                </FlexColumn>
                            )
                        }
            },
        ]

        const vendorPerm = getResourcePerm("Payables.Vendors")

        if (vendorPerm.manage) {
            columns.push(
                {
                    title: '',
                    key: 'action',
                    align: 'center',
                    width: "40px",
                    onHover: true,
                    render: (text, record) => (
                        <div>
                            <Dropdown
                                placement='bottomRight'
                                trigger={['click']}
                                key={`${record.id}-Dropdown`}
                                overlay={<Menu style={{ padding: 24, borderRadius: 8, zIndex: 1001 }} key={`${record.id}-Menu`} onClick={() => { }}>
                                    <FlexColumn gap='24px'>
                                        <TextButton text='EDIT' rightIcon={<Image src={Edit} />} onClick={() => this.selectRow(record)} />
                                        <Popconfirm
                                            title={<div>
                                                <div>Are you sure you want to deactivate this vendor?</div>
                                                {qbo && record.source === 'QUICKBOOKS' && <div>This will also deactivate the vendor on QuickBooks Online</div>}
                                            </div>
                                            }
                                            onConfirm={() => this.deactivateVendor(record)}
                                            okText="Confirm"
                                            placement="left"
                                            okButtonProps={{ loading: this.state.deactivateLoading }}
                                            cancelText="Cancel"
                                        >
                                            <TextButton text='DEACTIVATE' />
                                        </Popconfirm>
                                    </FlexColumn>
                                </Menu>}
                            >
                                <ImageButton src={More} />
                            </Dropdown>
                        </div>
                        
                    ),
                }
            )
        }
        const { vendorUploadJobId } = this.props.aionStore

        return (
            <FlexColumn className='main-padding'>
                <PageHeader
                    titleText="Bill Payments"
                    subtitle="Bill Payments"
                    
                />

                {/* <CardContainer style={{ marginBottom: 24 }} grow padding='8px 24px 24px'> */}
                    {/* <Tabs activeKey={tab} onClick={() => { }} onChange={(key) => this.setState({ tab: key })} style={{ marginBottom: 12 }}>
                        <TabPane tab="Active" key="active" />
                        <TabPane tab="Inactive" key="inactive" />
                    </Tabs> */}

                    {/* <div style={{ marginBottom: 24 }}>
                        <LabeledInput
                            id="searchTerm"
                            key="searchTerm"
                            placeholder="Search vendors"
                            prefixIcon={<Image width='12px' height='12px' src={Search} />}
                            onChange={this.onSearchChange}
                            width='400px'
                            nomargin
                        />
                    </div> */}

                    <TableView
                        id="vendor-table"
                        titleText="Vendors"
                        tableLayout='auto'
                        columns={columns}
                        dataSource={tab === 'active' ? vendors : inactiveVendors}
                        rowKey='id'
                        pagination={pagination}
                        loading={this.state.loading}
                        // scroll={{ y: '700px', x: '100%' }}
                        // onRow={(record) => ({
                        //     onClick: () => {
                        //         this.selectRow(record)
                        //     }
                        // })}
                        // rowClassName={this.setRowClassName}
                        style={{ flex: 1 }}
                        onChange={this.handleTableChange}
                        ctaContent={
                            <Flex start centerHorizontally gap='24px'>
                                <TextButton onClick={this.handleImportVendor} disabled={vendorUploadJobId} rightIcon={<Image src={Upload} />} text='BULK IMPORT' />
                                <Button solid permtype="Payables.Vendors" onClick={this.handleNewVendor} icon={<Image src={Add} />} text='ADD VENDOR' />
                            </Flex>
                        }
                        ctaSecondary={
                            <LabeledInput
                                id="searchTerm"
                                key="searchTerm"
                                placeholder="Search vendors"
                                prefixIcon={<Image width='12px' height='12px' src={Search} />}
                                onChange={this.onSearchChange}
                                width='250px'
                                nomargin
                            />
                        }
                    />
                {/* </CardContainer> */}
                <Modal
                    visible={this.state.showImportModal}
                    footer={null}
                    closable={true}
                    maskClosable={true}
                    width={this.state.modalWidth}
                    destroyOnClose={true}
                    onCancel={() => this.setState({ showImportModal: false })}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <Import
                        downloadLink="/Vendor_template.csv"
                        downloadName="Vendor_template.csv"
                        actionUrl={`${environment.payBaseUrl}/payables/vendors/uploadVendors`}
                        uploadTypeTitle="Vendor"
                        uploadType="vendor"
                        submitComplete={
                            (vendorUploadJobId) => {
                                if (vendorUploadJobId) {
                                    this.props.dispatch(addDataToStore(SAVE_DATA, { vendorUploadJobId: vendorUploadJobId }))
                                }

                                this.setState({ showImportModal: false })
                            }
                        }
                    />
                </Modal>
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)))