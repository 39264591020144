import styled from 'styled-components'

// This button will have all the functionality as the antd Button
export const Paragraph = styled.p`
    font-size: ${props => {
        switch (props.level) {
            case 1:
                return '0.9rem'
            case 2:
                return '1.1rem'
            case 3:
                return '1.2rem'
            case 4:
                return '1.3rem'
            case 5:
                return '1.4rem'
            default:
                return props.size || '14px'
        }
    }
    };
    font-weight: ${props => props.bold ? 500 : props.fontWeight};
    color: ${props => props.color || props.theme.text};
    margin: ${props => props.noMargin ? 0 : (props.margin || null)};
`

export const Title = styled.h1`
    opacity: 0.95;
    font-size: ${props => {
        switch (props.level) {
            case 2:
                return '4.2rem'
            case 3:
                return '3.4rem'
            case 4:
                return '2.6rem'
            case 5:
                return '2.0rem'
            case 6:
                return '1.6rem'
            default:
                return '5rem'
        }
    }
    };
    line-height: ${props => {
        switch (props.level) {
            case 2:
                return '4.7rem'
            case 3:
                return '3.9rem'
            case 4:
                return '3.1rem'
            case 5:
                return '2.5rem'
            case 6:
                return '2.3rem'
            default:
                return '5rem'
        }
    }
    };
    font-weight: ${props => props.bold ? 600 : 400};
    color: ${props => props.color || props.theme.text};
    margin: ${props => props.noMargin ? 0 : (props.margin || null)};
`

/* --- Refresh --- */

export const Text = styled.span`
    font-family: 'InterDisplay';
    font-size: ${props => props.size ? props.size : (props.heading || props.large) ? '18px' : props.light ? '12px' : props.theme.text.fontSize};
    font-weight: ${props => props.weight ? props.weight : ((props.heading || props.caption) ? '500' : '400')};
    line-height: ${props => props.height ? props.height : ((props.heading || props.large) ? '36px' : '20px')};
    text-align: ${props => props.right ? 'right' : (props.center ? 'center' : 'left')};
    vertical-align: ${props => props.center && 'middle'};
    color: ${props => {
        var color = ''
        if (props.primary) {
            color = props.theme.colors.primary
        } else if (props.color) {
            color = props.color
        } else if (props.heading) {
            color = props.theme.colors.heading
        } else if (props.light || props.lightText) {
            color = props.theme.colors.defaultLightText
        } else if (props.caption) {
            color = props.theme.colors.caption
        } else if (props.credit) {
            color = props.theme.colors.creditGreen
        } else if (props.error) {
            color = props.theme.colors.primary6
        } else {
            color = props.theme.colors.defaultText
        }
        return `${color} !important`
    }};
    text-transform: ${props => props.uppercase && 'uppercase'};
    margin: ${props => props.margin && props.margin};
    width: ${props => props.width && props.width};
    white-space: ${props => props.noWrap ? 'nowrap' : props.break && 'pre-wrap'};
    text-transform: ${props => props.capitalize ? 'capitalize' : props.uppercase && 'uppercase'};
    text-decoration: ${props => props.underline && 'underline'};
    letter-spacing: ${props => props.spacing ? props.spacing : props.spaced && '0.08em'};
    cursor: ${props => props.onClick && 'pointer'};
    word-wrap: ${props => props.break && 'break-word'};
    overflow-wrap: ${props => props.break && 'break-word'};
    word-break: ${props => props.break && 'break-all'};
    user-select: ${props => props.noSelect && 'none'};
    -webkit-user-select: ${props => props.noSelect && 'none'};
    > span {
        font-family: 'InterDisplay';
        font-size: ${props => props.size ? props.size : (props.heading || props.large) ? '18px' : props.light ? '12px' : props.theme.text.fontSize};
        font-weight: ${props => props.weight ? props.weight : ((props.heading || props.caption) ? '500' : '400')};
    }
`

export const Tag = styled.span`
    display: inline-flex;
    align-items: center;
    color: ${props => props.alert ? '#E52D2D' : props.primary ? props.theme.colors.primary : (props.color ? props.color : props.theme.colors.defaultLightText)};
    background: ${props => props.alert ? '#FEF5F5' : props.primary ? '#F3FAFF' : (props.background ? props.background : props.theme.colors.backgroundDefault)};
    font-size: ${props => props.size || '11px'};
    font-weight: ${props => props.weight || '400'};
    border-radius: ${props => props.radius || '4px'};
    margin: ${props => props.margin || '0'};
    padding: ${props => props.padding || '4px 8px'};
    width: ${props => props.width && props.width};
    height: ${props => props.height && props.height};
    text-transform: ${props => !props.noCap && 'capitalize'};
    border: ${props => props.bordered && `0.5px solid ${props.primary ? props.theme.colors.primary : props.borderColor ? props.borderColor : (props.color ? props.color : props.theme.colors.secondary)}`};
    cursor: ${props => props.onClick ? 'pointer' : 'default'};
    white-space: ${props => props.noWrap && 'nowrap'};
`