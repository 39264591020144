import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import { withTheme } from 'styled-components';

// Components
import { Button } from '../Reusable/Button';
import { Paragraph, Title } from '../Reusable/Text';
import { FlexColumn } from '../Reusable/Container';
import { LabeledInput } from '../Reusable/Input';
import { ErrorAlert } from '../Reusable/Alert';
import { apiGET } from '../../Utils/api';


class ReferralCode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            code: ''
        }
    }

    handleChange = (event) => {
        if(event.target.id === "code") {
            this.setState({code: event.target.value});
        }        
    }
    
    handleSubmit = async (event) => {
        if(this.state.code === '') {
            ErrorAlert({description: "Please enter a code."});
            return;
        }
        this.setState({loading: true});
        apiGET(`/validate/referralcode?code=${this.state.code}`, {}, (error, resp) => {
            this.setState({loading: false});
            try {
                
                const data = resp.data;
                if(error || !data.Active) throw Error("Invalid code");
                this.props.showSignup(this.state.code);
            } catch (error) {
                ErrorAlert({description: "We could not recognize this code, please enter a valid code. Reach out to contact@aionfi.com if you have any questions."});
                console.log("Error", error);
            }
        })
    }

    render() {
        return (
            <FlexColumn center style={{height: "80%"}}>
                <div style={{ width: "100%" }}>
                    <Title level={4}>Referral Code</Title>
                    <Paragraph>Enter your code below.</Paragraph>
                    <FlexColumn className="container" onKeyDown={(event) => { if (event.key === "Enter") this.handleSubmit() }}>
                        <LabeledInput
                            autoFocus
                            label="Referral Code"
                            id="code" 
                            placeholder="Enter Code" 
                            onChange={this.handleChange} 
                        />
                        <Button permtype="Override" style={{alignSelf: 'center', width: '100%', margin: '20px 0'}} solid loading={this.state.loading} onClick={this.handleSubmit} text='Submit' />
                    </FlexColumn>
                </div>
            </FlexColumn>
        );
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(withTheme(ReferralCode));