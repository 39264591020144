import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { Divider } from 'antd';
import AccountingSysSelect from './AccountingSysSelect';
import { FlexColumn, Flex } from './Container';
import { ErrorAlert } from './Alert'
import { Text } from './Text';
import { Image } from './Image';
import { Button, TextButton } from './Button';
import { capitalize } from 'lodash';
import environment from '../../environment'
import { apiPOSTReq } from '../../Utils/api'
import { ONB_SAVE_DATA, REMOTE_SAVE_DATA, addDataToStore } from '../../Actions/actions'
import Lightning from '../../Images/lightning.svg';
import ThumbsUp from '../../Images/thumbs-up.svg';
import Shield from '../../Images/shield.svg';
import Banner from './Banner'


class ConnectionModal extends Component {
    constructor(props) {
        super(props)
        const { loanApplication } = props.creditStore;
        const { referralCode } = props.aionStore;
        this.state = {
            submitLoading: false,
            submitPlaidLoading: false,
            loading: false,
            valid: false,
            uploadList: [],
            loadingBanks: false,
            showBankDetails: false,
            reconnectBank: false,
            yodleeLink: false,
            showManualDocUploadReason: true,
            showManualBankUploadReason: true,
            loanApplication: loanApplication,
            referralCode: referralCode,
            step: 'sync',
        }
    }

    componentDidMount() {
    }

    onClickManualUploadModal = () => {
        this.setState({ showManualUploadModal: true, uploadList: [], bankName: "" });
    }

    // Accounting Software Code Start
    onClickPreScreenManualUploadModal = () => {
        var { onboardingStore } = this.props;
        var businessInfo = onboardingStore.businessInfo || {};

        if (!businessInfo.closeBooksDate) {
            this.setState({ errorField: "closeBooksDate", errorMessage: 'Please select a closing date' })
            return
        } else if (!businessInfo.fiscalYearEnd) {
            this.setState({ errorField: "fiscalYearEnd", errorMessage: 'Please select fiscal year end' })
            return
        }

        this.setState({ showPreScreenManualUploadModal: true, showManualUpload: false, errorField: '', errorMessage: '' });
    }

    beginAuth = () => {
        var { connection, selectedIntegration } = this.state
        var { location } = this.props
        var { linkUrl } = connection || {}
        var { pathname } = location
        this.setState({ loading: true })


        console.log("ConnectionModal connection, selectedIntegration", connection, selectedIntegration)

        if (connection) {
            var redirectUri = `${linkUrl}?website=${environment.uiBaseUrl}&path=${pathname}`
            window.location.href = redirectUri
        } else {
            this.createConnection({ platformKey: selectedIntegration.key, logoUrl: selectedIntegration.logoUrl }, (result) => {
                if (result) {
                    linkUrl = result.linkUrl
                    var redirectUri = `${linkUrl}?website=${environment.uiBaseUrl}&path=${pathname}`
                    window.location.href = redirectUri
                }
            })
        }
    }

    createConnection = (options, callback) => {
        var body = {
            platformKey: options.platformKey,
            logoUrl: options.logoUrl
        }

        apiPOSTReq(`${environment.integrationBaseUrl}/integration/codat/createConnection`, {}, body, (err, resp) => {
            try {
                const data = resp || {}
                if (data.result) {
                    this.setState({ connection: data.connection })
                    this.props.dispatch(addDataToStore(ONB_SAVE_DATA, { codatData: { connection: data.connection, company: this.state.company } }))
                    callback(data.connection)
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                this.setState({ loading: false })
                callback(null)
                ErrorAlert({ description: error.message })
            }
        })
    }

    render() {
        var { step, selectedIntegration, loading } = this.state;
        var { onCancel, isCreditPlus } = this.props;


        return (
            <FlexColumn between left gap='8px' style={{ height: 740 }}>
                {(() => {
                    switch (step) {
                        case 'sync':
                            return <>
                                <FlexColumn start>
                                    <Text heading>Connect Aion to your accounting system </Text>
                                    <Text margin='0px 0 24px'>Reduce manual entry with Aion's accounting system integrations. Setup in minutes and save time on repetitive accounting tasks.</Text>

                                    <Flex start gap='16px' centerHorizontally style={{ padding: '12px 24px', marginTop: 64 }}>
                                        <Image width='40px' height='40px' src={Lightning} />
                                        <FlexColumn start left gap='8px'>
                                            <Text weight='500' height='24px'>REAL TIME UPDATES</Text>
                                            <Text>Sync transactions in real-time with a single click. Updates in Aion will be synced in real time with your accounting system.</Text>
                                        </FlexColumn>
                                    </Flex>
                                    <Divider style={{ margin: '18px 0' }} />
                                    <Flex start gap='16px' centerHorizontally style={{ padding: '12px 24px' }}>
                                        <Image width='40px' height='40px' src={Shield} />
                                        <FlexColumn start left gap='8px'>
                                            <Text weight='500' height='24px'>SECURITY</Text>
                                            <Text>We do not store or have access to your accounting system credentials. Aion undergoes audits to ensure your data is always secure, confidential, and private according to SOC 2 Type II protocols.</Text>
                                        </FlexColumn>
                                    </Flex>
                                    <Divider style={{ margin: '18px 0' }} />
                                    <Flex start gap='16px' centerHorizontally style={{ padding: '12px 24px' }}>
                                        <Image width='40px' height='40px' src={ThumbsUp} />
                                        <FlexColumn start left gap='8px'>
                                            <Text weight='500' height='24px' spacing='1.28px'>SEAMLESS</Text>
                                            <Text>The first sync begins once you authenticate and link your accounting system. Your sync will pull the following transactions from your accounting system: chart of accounts, invoices, products, customers, vendors, bills and bill payments.</Text>
                                        </FlexColumn>
                                    </Flex>
                                </FlexColumn>

                                <Button solid text='GET STARTED' margin='24px 0 0' onClick={() => this.setState({ step: 'connect' })} />
                            </>
                        case 'connect':
                            return <>
                                <FlexColumn start>
                                    <Text heading>Connect Aion to your accounting system </Text>
                                    <Text margin='0px 0 24px'>Reduce manual entry with Aion's accounting system integrations. Setup in minutes and save time on repetitive accounting tasks.</Text>
                                    <AccountingSysSelect
                                        location={this.props.location}
                                        onAccountingSysConnected={(options) => {
                                            console.log("onAccountingSysConnected", options)
                                            this.setState({ accountingSysConnected: options.accountingSysConnected })
                                        }}
                                        onClickManualUploadModal={() => this.onClickPreScreenManualUploadModal()}
                                        isCreditPlus={isCreditPlus}
                                        onSelect={(selectedIntegration) => this.setState({ selectedIntegration })}
                                    />

                                    <Banner
                                        style={{ marginTop: "24px", width: "100%" }}
                                        message="Make sure you have the correct permissions. Most accounting systems require admin privileges such as Primary admin or Company admin to connect."
                                    />

                                    {!selectedIntegration && <Text margin='24px 0 16px'>Select an accounting system to continue</Text>}
                                </FlexColumn>

                                <Button loading={loading} solid text='CONNECT' margin='12px 0 0' onClick={() => this.beginAuth()} disabled={!selectedIntegration} />
                            </>
                        default:
                            return null
                    }
                })()}
            </FlexColumn>
        );
    }
}

function mapStateToProps(state) {
    return {
        onboardingStore: state.onboardingAppReducer,
        aionStore: state.aionAppReducer,
        creditStore: state.creditAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(ConnectionModal)));