import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { Table } from 'antd';

// Components
import moment from 'moment';
import { apiPOSTReq, getPreSignedS3Url } from '../../../../Utils/api';
import environment from '../../../../environment';
import { Paragraph } from '../../../Reusable/Text';
import { ErrorAlert } from '../../../Reusable/Alert';
import { saveAs } from 'file-saver'
import { Flex, FlexColumn, CardContainer } from '../../../Reusable/Container'

const downloadAttachment = (attachment) => {
    if (attachment) {
        getPreSignedS3Url({ url: attachment.uri }, (err, preSignedUrl) => {
            saveAs(preSignedUrl, attachment.fileName)
        });
    } else {
        ErrorAlert({ description: "No document found for this attachment" });
    }
}
class Index extends Component {

    state = {
        pagination: {
            current: 1,
            pageSize: 100
        }
    };

    componentDidMount() {
        this.fetchLoanApplication({ pagination: this.state.pagination });
    }

    handleTableChange = (pagination, filters, sorter) => {
        this.fetchLoanApplication({ pagination: pagination });
    }

    fetchLoanApplication = () => {
        const { loanApp } = this.props.location.state;
        var body = {
            clientBusinessId: loanApp.businessId,
            applicationId: loanApp.applicationId
        }
        console.log("loanApplication body", body);
        this.setState({ validateLoading: true });
        apiPOSTReq(`${environment.opsBaseUrl}/ops/loans/application/getApplication`, null, body, (err, resp) => {
            this.setState({ validateLoading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    console.log(data.loanApplication);
                    if (data.loanApplication) {
                        this.setState({ reviewerLogs: data.loanApplication.reviewLog, applicationStatus: data.loanApplication.underwritingStatus });
                    }
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    getColumns = () => {
        return [
            {
                title: 'Date',
                width: 100,
                render: item => moment(item.createTime).format('MM/DD/YY')
            },
            {
                title: 'Name',
                render: (item) => <Paragraph size="0.95rem" bold noMargin>{item.username}</Paragraph>
            },
            {
                title: 'Email',
                render: (item) => <Paragraph size="0.95rem" bold noMargin>{item.email}</Paragraph>
            },
            {
                title: 'Outcome',
                dataIndex: 'outcome'
            },
            {
                title: 'Notes',
                width: 300,
                dataIndex: 'notes'
            },
            {
                title: 'Attachments',
                width: 300,
                render: (text, item) => {
                    var attachments = item.documentUrls;
                    return <>
                        {attachments && attachments.length > 0 ?
                            <>
                                {
                                    attachments.map(file => {
                                        return <a onClick={() => downloadAttachment(file)}> {file.fileName} </a>
                                    })
                                }
                            </> : null}
                    </>
                },
            }
        ]
    }

    render() {
        const { reviewerLogs, pagination, loading } = this.state;
        return (
            <CardContainer fullWidth>
                <Table
                    id="inbox-table"
                    tableLayout='auto'
                    columns={this.getColumns()}
                    dataSource={reviewerLogs}
                    rowKey='id'
                    pagination={pagination}
                    onChange={this.handleTableChange}
                    style={{ minHeight: "500px" }}
                    loading={loading}
                    scroll={{ x: '100%' }}
                />
            </CardContainer>
        );
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)));