import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';

import {
    Table,
    Space,
    Tooltip
} from 'antd';
import { Tag } from '../../Reusable/Text';
import TableView from '../../Reusable/TableView';

class AccountsTable extends Component {
    state = {
    };

    selectRow = (record) => {
        this.props.showSelectedAccount(record);
    }

    handleTableChange = (pagination, filters, sorter) => {
        this.props.fetchBBAccounts({ pagination: pagination, sorter: sorter });
    }

    showAccountRestrictions = (selectedAccount) => {
        this.props.history.push({
            pathname: '/support/bb-acc/restrictions',
            state: { account: selectedAccount }
        })
    }

    render() {
        const { theme, aionStore, loading, pagination, bbAccounts } = this.props;
        const StatusTag = (options) => {
            let { account } = options;
            const { status} = account;
            return (
                <Space wrap>
                    {status && <Tooltip title="Status"><Tag tagpreset={theme.colors.secondary7}>{status.toUpperCase()}</Tag></Tooltip>}
                    
                </Space>
            )
        }
        const columns = [
            {
                title: 'Nickname',
                dataIndex: 'nickName',
                key: 'nickName',

            },
            {
                title: 'Account Number',
                dataIndex: 'accountNumber',
                key: 'accountNumber',
            },
            {
                title: 'Account Type',
                dataIndex: 'accountType',
                key: 'accountType',
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'accountNickName',
                render: (status, account) => <StatusTag status={status} account={account} />

            },
            {
                title: 'Restrictions',
                key: 'restrictions',
                render: (account) => <a  onClick={() => this.showAccountRestrictions(account)}>View</a>

            }
        ];

        return (
            <>
                <TableView
                    id="accounts-table"
                    className="borderless-table row-pointer"
                    titleText='Accounts'
                    ctaContent={this.props.ctaContent}
                    columns={columns}
                    dataSource={bbAccounts}
                    rowKey='accountNumber'
                    pagination={pagination}
                    onChange={this.handleTableChange}
                    loading={loading}
                />
            </>
        )
    }

}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer,
        bankingStore: state.bankingAppReducer,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(AccountsTable)));