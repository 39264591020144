import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'
import update from 'immutability-helper'

import {
    message, Select, Popconfirm, Space, Upload, Modal, List, Table, Tooltip
} from 'antd'

import {
    InboxOutlined, DeleteOutlined, CheckCircleFilled, EditOutlined, InfoCircleOutlined
} from '@ant-design/icons'

// Components
import { TextButton } from '../../../Reusable/Button'
import { Button } from '../../../Reusable/Refresh/Button'
import { Image } from '../../../Reusable/Image'
import { Text, Tag } from '../../../Reusable/Text'
import { FlexColumn, Flex, CardContainer } from '../../../Reusable/Container'
import { LabeledInput } from '../../../Reusable/Input'
import { ErrorAlert } from '../../../Reusable/Alert'
import environment from '../../../../environment'
import { SignedLink } from '../../../Reusable/Link'
import { apiPOSTReq } from '../../../../Utils/api'
import { addressObjectToStr, toCurrency, isEmailValid, getCodatPlatformName, isCodatUser } from '../../../../Utils/util'
import AddPaymentMethod from '../AddPaymentMethod'
import ModalClose from '../../../../Images/modal-close.png'
import TableView from '../../../Reusable/TableView'
import Unsyncable from '../../../../Images/unsyncable.svg'

const docTypes = [
    {
        name: "Purchase Order",
        type: "PO",
    },
    {
        name: "Work Order",
        type: "WO",
    },
    {
        name: "Master Service Agreement",
        type: "MSA",
    },
    {
        name: "Other",
        type: "OTHER",
    }
]

const { Option } = Select
const { Dragger } = Upload

class NewVendor extends Component {

    constructor(props) {
        super(props)
        const vendor = props.vendor || { type: "Business" }
        vendor.billRecipientEmail = vendor.billRecipientEmail || [];

        this.state = {
            paymentMethod: "ACH",
            accountType: "Checking",
            createVendorLoading: false,
            vendor: vendor,
        }
    }

    componentDidMount() {
        console.log("NewVendorsaveVendorInput", this.props.vendor)
        this.getPaymentMethods()
    }

    saveInput = (id, dataToSave) => {
        this.setState({ [id]: dataToSave })
    }

    saveVendorInput = (key, value) => {
        console.log("saveVendorInput", key, value)
        var { vendor } = this.state
        vendor[key] = value
        this.setState({ vendor })
    }

    handleVendorTextChange = (event) => {
        var { vendor } = this.state

        vendor[event.target.id] = event.target.value

        this.setState({ vendor })
    }

    getVendorLocation = (location, formattedAddress) => {
        if (!location)
            this.setState({
                vendor: update(this.state.vendor, {
                    address: {
                        $set: {
                            line1: null,
                            line2: null,
                            city: null,
                            countrySubDivisionCode: null,
                            state: null,
                            postalCode: null
                        }
                    }
                })
            })
        else
            this.setState({
                vendor: update(this.state.vendor, {
                    address: {
                        $set: {
                            line1: location.line1,
                            line2: location.line2,
                            city: location.city,
                            countrySubDivisionCode: location.countrySubDivisionCode,
                            state: location.state,
                            postalCode: location.postalCode
                        }
                    }
                })
            })
    }

    getPaymentMethods = (options) => {
        options = options || {}
        var { vendor } = this.state
        if (options.vendor) vendor = options.vendor
        if (!vendor.associatedCPObjectId) {
            return
        }
        const body = {
            "vendorId": vendor.associatedCPObjectId
        }
        this.setState({ loading: true })
        apiPOSTReq(`${environment.payBaseUrl}/payables/vendors/getPaymentMethods`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            try {
                this.setState({ loading: false })
                const data = resp
                console.log("/payables/vendors/getPaymentMethods", data)
                if (data.result) {
                    var paymentMethods = (data.paymentMethods || []).filter(pm => pm.type == "ACH")
                    this.setState({ paymentMethods: paymentMethods })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getPaymentMethods", error, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    addPaymentMethod = (type) => {
        var { vendor } = this.state
        var { id } = vendor || {}
        if (!id) {
            this.handleSubmit(type)
            return
        }
        if ((type) == "ACH") this.setState({ showAddACHMethod: true })
        if ((type) == "Check") this.setState({ showAddCheckMethod: true })
    }

    makeDefaultPaymentMethod = (paymentMethod) => {
        const { vendor } = this.state
        const body = {
            "vendorId": vendor.id,
            "paymentMethodId": paymentMethod.id
        }
        this.setState({ loading: true })
        apiPOSTReq(`${environment.payBaseUrl}/payables/vendors/makeDefaultPaymentMethod`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            this.setState({ loading: false })
            try {
                const data = resp || {}
                console.log("/payables/vendors/makeDefaultPaymentMethod resp", data)
                if (data.result) {
                    this.getPaymentMethods()
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /ive/templates/makeDefault", error, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    deletePaymentMethod = (paymentMethod) => {
        const { vendor } = this.state
        const body = {
            "vendorId": vendor.id,
            "paymentMethodId": paymentMethod.id
        }
        this.setState({ loading: true })
        apiPOSTReq(`${environment.payBaseUrl}/payables/vendors/deletePaymentMethod`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            this.setState({ loading: false })
            try {
                const data = resp || {}
                console.log("/payables/vendors/deletePaymentMethod", data)
                if (data.result) {
                    this.getPaymentMethods()
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /payables/vendors/deletePaymentMethod", error, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    getModalForDocUpload = (doc) => {
        const { theme } = this.props
        var { uploadedFile } = this.state
        uploadedFile = uploadedFile || { ...doc }
        const uploadProps = () => {
            return {
                name: `payables-docs`,
                multiple: false,
                action: `${environment.apiBaseUrl}/file/upload`,
                headers: {
                    businesskey: this.props.aionStore.BusinessUniqueKey,
                    jwt: this.props.aionStore.jwt
                },
                onChange: (info) => {
                    var { file } = info
                    console.log("uploadProps info file", file)
                    const { status, response, name } = file
                    if (status === "done") {
                        this.setState({
                            uploadedFile: {
                                ...uploadedFile,
                                fileName: (doc.name == "W-9") ? doc.name : name,
                                uri: (response.UploadedUrls || [])[0]
                            }
                        })
                    }
                }
            }
        }

        return (
            <FlexColumn center>
                <div style={{ width: "95%" }}>
                    <Text size='20px'>Upload {doc.name === "W-9" ? "W-9" : "document"}</Text>
                    <Text>Upload your document and add an optional note.</Text>
                    <FlexColumn>
                        <Dragger {...uploadProps(doc)}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        </Dragger>
                        {(doc.name !== "W-9") &&
                            <LabeledInput
                                label="Document type"
                                labelcolor={theme.colors.secondary3}
                                showAction={'focus'}
                                type="select"
                                size="type"
                                key="type"
                                defaultValue={doc.type}
                                placeholder="Purchase Order"
                                optionFilterProp="children"
                                onChange={(value, option) => this.setState({ uploadedFile: { ...uploadedFile, "type": value } })}
                                className="no-left-padding"
                            >
                                {docTypes.map(item =>
                                    <Option key={item.type} id="type" name={item.name} value={item.type} style={{ backgroundColor: "transparent" }}>
                                        {item.name}
                                    </Option>
                                )}
                            </LabeledInput>}
                        <LabeledInput
                            label="Note"
                            labelcolor={theme.colors.secondary3}
                            placeholder="Note"
                            key="note"
                            id="note"
                            onChange={e => { this.setState({ uploadedFile: { ...uploadedFile, "notes": e.target.value } }) }}
                        />
                        <Button permtype="Override" onClick={() => {
                            if (!uploadedFile.fileName) {
                                ErrorAlert({ description: "Please upload a file before saving" })
                                return
                            }
                            this.setState({
                                vendor: update(this.state.vendor, { attachments: { $set: [...(this.state.vendor.attachments || []), uploadedFile] } }),
                                showUploadModal: false
                            })
                        }
                        } text='Save' />
                    </FlexColumn>
                </div>
            </FlexColumn>
        )
    }

    getDocumentSection = () => {
        var { vendor } = this.state
        var { attachments } = vendor || {}
        attachments = attachments || []
        const { theme } = this.props
        var documentsToUpload = [
            {
                name: `W-9`,
                type: "W-9"
            },
            {
                name: `Other`,
                type: "OTHER"
            }
        ]
        documentsToUpload.forEach(item => {
            if (attachments.find(document => document.fileName == item.name)) item.uploaded = true
        })

        const handleDeleteFile = (uri) => {
            var vendor = this.state.vendor || {}
            var attachments = (vendor.attachments || []).filter(item => item.uri !== uri)
            this.setState({
                vendor: update(this.state.vendor, { attachments: { $set: attachments } })
            })
        }

        return (
            <>
                <FlexColumn>
                    <Text color='#444444' style={{ margin: '15px 0' }}>Documents</Text>
                    {/* <Divider style={{ margin: "0 0 0 5" }} /> */}
                    <List
                        bordered
                        loading={this.state.loading}
                        dataSource={documentsToUpload}
                        renderItem={item =>
                            <Flex centerHorizontally between style={{ padding: "6px 15px", borderBottom: `1px solid ${theme.colors.systemGray6}` }}>
                                <FlexColumn>
                                    <Text weight='500'>{item.name}</Text>
                                    {
                                        item.type === "W-9" &&
                                        <Text>IRS W-9: <a href="https://www.irs.gov/pub/irs-pdf/fw9.pdf" target="_blank">https://www.irs.gov/pub/irs-pdf/fw9.pdf</a></Text>
                                    }
                                </FlexColumn>
                                {(!item.uploaded || (item.type === "OTHER")) ?
                                    <div style={{ textAlign: "right" }}>
                                        <TextButton onClick={() => { this.setState({ showUploadModal: true, uploadDoc: item, uploadedFile: null }) }} text='Upload' />
                                    </div>

                                    :
                                    <Tag style={{ margin: "5px 0" }} tagpreset={theme.colors.secondary4}>Uploaded <CheckCircleFilled /></Tag>
                                }
                            </Flex>
                        }
                    />
                    {(attachments.length > 0) &&
                        <>
                            <Text weight='500' size='20px' style={{ marginTop: "40px" }}>Uploaded Documents</Text>
                            <Table
                                className='borderless-table'
                                loading={this.state.loading}
                                dataSource={attachments}
                                scroll={{ y: '700px', x: '100%' }}
                                pagination={false}
                                rowKey='uri'
                                columns={[{
                                    title: 'Name',
                                    dataIndex: 'fileName',
                                    render: (name, item) => <SignedLink {...item} />
                                },
                                {
                                    title: 'Type',
                                    dataIndex: 'type',
                                    render: item => <Tag tagpreset={theme.colors.secondary8}>{item}</Tag>
                                },
                                {
                                    title: 'Note',
                                    dataIndex: 'notes'
                                },
                                {
                                    title: 'Action',
                                    width: "100px",
                                    render: (text, item) => (
                                        <Space size="middle">
                                            <Popconfirm title="Are you sure you want to delete this file?" onConfirm={() => handleDeleteFile(item.uri)}>
                                                <a><DeleteOutlined style={{ cursor: 'pointer', fontSize: 18 }} /></a>
                                            </Popconfirm>
                                        </Space>
                                    ),
                                    align: "right"
                                }]}
                            />
                        </>
                    }
                </FlexColumn>
            </>
        )
    }

    getDeliveryMethods = () => {
        const { theme, bankingStore } = this.props
        var { vendor, paymentMethods } = this.state
        var { bankDetail } = vendor || {}
        var { accountNumber } = bankDetail || {}
        var accountMask = accountNumber ? accountNumber.slice(-4) : ""
        paymentMethods = paymentMethods || []
        return (
            <FlexColumn style={{ width: "100%" }}>
                {/* {(paymentMethods.length > 0) && */}
                <TableView
                    className='borderless-table'
                    titleText='Payment Methods'
                    style={{ width: "100%", marginBottom: 10 }}
                    loading={this.state.loading}
                    dataSource={paymentMethods}
                    pagination={false}
                    rowKey='id'
                    ctaContent={
                        <Flex center>
                            <a style={{ fontSize: 15 }} permtype="Payables.Vendors" onClick={() => this.setState({ showPaymentModal: true, editPm: null })}>Add Payment Method</a>
                        </Flex>
                    }
                    columns={[
                        {
                            title: 'Nickname',
                            dataIndex: 'nickName',
                            width: "200px",
                        },
                        {
                            title: 'Type',
                            dataIndex: 'type',
                            render: item => <Tag tagpreset={theme.colors.secondary8}>{item}</Tag>,
                            width: "80px",
                        },
                        {
                            title: 'Account',
                            dataIndex: 'bankDetail',
                            render: bankDetail =>
                                <>
                                    {bankDetail.accountNumber && <Space style={{ color: theme.colors.secondary7 }}><span>{`•••••${bankDetail.accountNumber.substring(bankDetail.accountNumber.length - 4, bankDetail.accountNumber.length)}`}</span></Space>}
                                    {bankDetail.bankAddress && <span style={{ color: theme.colors.secondary7 }}><b>Mailing address: </b>{addressObjectToStr(bankDetail.bankAddress)}</span>}
                                </>,
                            width: "35%",
                        },
                        // {
                        //     title: 'From Account',
                        //     dataIndex: 'bankDetail',
                        //     render: (bankDetail, paymentMethod) => {
                        //         console.log("bankDetail", JSON.stringify(paymentMethod))
                        //         var Accounts = getBPAccountsWithAccess(null, { resourceStr: "Payables.Vendors", permType: "manage", getActiveAccounts: true })
                        //         const userPaymentAccount = (Accounts || []).find(account => account.accountId === paymentMethod.accountId)

                        //         return <>
                        //             {
                        //                 userPaymentAccount && <><span style={{ color: theme.colors.secondary7 }}><b>{(userPaymentAccount.nickName || `Business ${userPaymentAccount.accountSubType}`)}</b></span>: <span style={{ color: theme.colors.secondary7 }}>••••{userPaymentAccount.mask}</span></>
                        //             }
                        //         </>
                        //     },
                        //     width: "35%",
                        // },
                        {
                            title: '',
                            dataIndex: 'default',
                            render: (isdefault, record) => (
                                <Flex start centerHorizontally style={{ gap: 8 }}>
                                    {isdefault && <Tag tagpreset={theme.colors.primary}>{"Default".toUpperCase()}</Tag>}
                                    {!isdefault && <a permtype="Payables.Vendors" style={{ whiteSpace: 'nowrap', marginBottom: 4 }} onClick={() => this.makeDefaultPaymentMethod(record)}>Make default</a>}
                                    {!isdefault && <a permtype="Payables.Vendors" onClick={() => this.deletePaymentMethod(record)}><DeleteOutlined style={{ fontSize: 18 }} /></a>}
                                    <a permtype="Receivables.Invoices" onClick={() => this.setState({ showPaymentModal: true, editPm: record })}><EditOutlined style={{ fontSize: 18 }} /></a>
                                </Flex>
                            ),
                            align: "center",
                            width: "20%",
                        }]}
                />
            </FlexColumn>
        )
    }

    handleEmailChange = (event) => {
        this.setState({
            vendor: update(this.state.vendor, { billRecipientEmail: { [event.target.getAttribute('index')]: { $set: event.target.value } } })
        })
    }

    handleAddEmail = () => {
        this.setState({
            vendor: update(this.state.vendor, this.state.vendor.billRecipientEmail ? { billRecipientEmail: { $push: [null] } } : { billRecipientEmail: { $set: [null] } })
        })
    }

    handleDeleteEmail = (event) => {
        this.setState({
            vendor: update(this.state.vendor, { billRecipientEmail: { $splice: [[[event.currentTarget.getAttribute('index')], 1]] } })
        })
    }

    saveVendor = () => {
        var { vendor, fromAccount, paymentMethod, accountNumber, routingNumber, checkAddress } = this.state
        const { vendorName, primaryEmail, type } = vendor

        var successMessage = vendor.id ? "Vendor updated successfully!" : "Vendor created successfully!"

        if (!type) {
            ErrorAlert({ description: "Please select a type for this vendor." })
            return
        }

        if (!vendorName) {
            ErrorAlert({ description: "Please enter a vendor name." })
            return
        }

        if (vendorName.includes('|')) {
            ErrorAlert({ description: "Please enter a valid vendor name." })
            return
        }

        if (primaryEmail && !isEmailValid(primaryEmail)) {
            ErrorAlert({ description: "Please enter a valid email." })
            return
        }

        if (vendor.primaryEmail === "") {
            vendor.primaryEmail = null
        }

        this.setState({ createVendorLoading: true })

        const body = {
            "vendor": vendor,
            "saveToQBO": this.state.saveToQBO,
        }

        console.log("/payables/vendors/save", JSON.stringify(body))
        apiPOSTReq(`${environment.payBaseUrl}/payables/vendors/save`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            try {
                this.setState({ loading: false })
                const data = resp
                console.log("/payables/vendors/save", JSON.stringify(data))
                if (data.result) {
                    this.setState({ vendor: data.vendor, editvendor: data.editvendor, createVendorLoading: false })
                    message.success(successMessage)

                    // if (paymentMethod === "ACH") {
                    //     this.saveACHPayment(successMessage)
                    // } else if (paymentMethod === "Check") {
                    //     this.saveCheckPayment(successMessage)
                    // }
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                this.setState({ createVendorLoading: false })
                console.log("ERRR /payables/vendors/save", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    saveACHPayment = (successMessage) => {
        const { vendor, flowSteps } = this.state

        var body = {
            "vendorId": vendor.id,
            "accountId": this.state.fromAccount,
            "type": vendor.type,
            "bankDetail": {
                "accountName": vendor.vendorName || "",
                "accountNumber": this.state.accountNumber || "",
                "routingNumber": this.state.routingNumber || "",
                "accountType": (this.state.accountType || "").toLowerCase()
            },
        }

        this.setState({ submitLoading: true })
        apiPOSTReq(`${environment.payBaseUrl}/payables/vendors/addTransferDelivery`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            this.setState({ createVendorLoading: false })
            try {
                if (err) throw Error(err)
                const data = resp || {}
                if (data.result) {
                    message.success(successMessage)
                    body.type = "ACH"
                    vendor.deliveryMode = "ACH"
                    this.props.onSave(vendor, body)
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/createCounterparty err", error, resp, body)
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    saveCheckPayment = (successMessage) => {
        var { vendor, checkAddress, flowSteps, vendor } = this.state
        var { line1, line2, city, state, postalCode } = checkAddress

        var body = {
            "vendorId": vendor.id,
            "accountId": this.state.fromAccount,
            "bankDetail": {
                "bankAddress": {
                    "line1": line1,
                    "line2": line2,
                    "city": city,
                    "state": state,
                    "postalCode": postalCode
                }
            },
        }

        console.log("/addCheckDelivery body", JSON.stringify(body))
        this.setState({ submitLoading: true })
        apiPOSTReq(`${environment.payBaseUrl}/payables/vendors/addCheckDelivery`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            console.log("/addCheckDelivery err, resp", err, resp)
            this.setState({ createVendorLoading: false })
            try {
                const data = resp || {}
                if (data.result) {
                    message.success(successMessage)
                    body.type = "Check"
                    vendor.deliveryMode = "Check"
                    this.props.onSave(vendor, body)
                } else {
                    throw Error(data.error || data.responseMessage)
                }
            } catch (error) {
                console.log("/addCheckDelivery err", error)
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    render() {
        var { theme, aionStore } = this.props
        const { vendor, createVendorLoading } = this.state

        var { vendorName, primaryEmail, primaryPhone, taxId, address, type, customerId, billRecipientEmail } = vendor

        const unsyncable = isCodatUser(aionStore)

        return (
            <FlexColumn start>
                <FlexColumn>
                    <Flex start style={{ width: "100%" }} gap='24px'>
                        <CardContainer shadow style={{ width: "50%" }} padding='12px 24px 24px'>
                            <FlexColumn start style={{ marginBottom: 12, marginRight: 12 }} gap='16px'>
                                {/* <Text color='#444444' margin="10px 0 0 0">Vendor Details</Text> */}

                                <LabeledInput
                                    label="Type"
                                    type='switch'
                                    switchNames={['Business', 'Individual']}
                                    onChange={(value) => this.saveVendorInput("type", value)}
                                    value={vendor.type}
                                    nomargin
                                />

                                <LabeledInput
                                    autoFocus
                                    label="Name"
                                    labelcolor={theme.colors.secondary3}
                                    id="vendorName"
                                    key="vendorName"
                                    placeholder="Enter vendor name"
                                    value={vendorName}
                                    onChange={this.handleVendorTextChange}
                                    nomargin
                                />

                                <LabeledInput
                                    optional
                                    label="Email"
                                    labelcolor={theme.colors.secondary3}
                                    id="primaryEmail"
                                    key="primaryEmail"
                                    placeholder="Enter email"
                                    value={primaryEmail}
                                    onChange={this.handleVendorTextChange}
                                    nomargin
                                />

                                <LabeledInput
                                    optional
                                    label="Phone"
                                    labelcolor={theme.colors.secondary3}
                                    id="primaryPhone"
                                    key="primaryPhone"
                                    placeholder="Enter phone number"
                                    value={primaryPhone}
                                    onChange={this.handleVendorTextChange}
                                    maxLength={10}
                                    nomargin
                                />

                                <LabeledInput
                                    optional
                                    label={(type !== "Individual") ? "EIN" : "SSN"}
                                    labelcolor={theme.colors.secondary3}
                                    id="taxId"
                                    key="taxId"
                                    placeholder="373888567"
                                    value={taxId}
                                    onChange={this.handleVendorTextChange}
                                    maxLength={9}
                                    nomargin
                                    unsyncable={isCodatUser(aionStore)}
                                    accountingSoftware={getCodatPlatformName(aionStore)}
                                />

                                <LabeledInput
                                    optional
                                    label={"Customer ID"}
                                    id="customerId"
                                    key="customerId"
                                    placeholder="Add an ID"
                                    value={customerId}
                                    onChange={this.handleVendorTextChange}
                                    nomargin
                                    maxLength='10'
                                    instruction="This ID will be automatically added when making a bill payment to help your vendors easily identify it"
                                    unsyncable={isCodatUser(aionStore)}
                                    accountingSoftware={getCodatPlatformName(aionStore)}
                                />

                                <LabeledInput
                                    label='Bill payment notification email'
                                    id="emails"
                                    key="emails"
                                    type="multi-email"
                                    nomargin
                                    onChange={(billRecipientEmail) => {
                                        vendor.billRecipientEmail = billRecipientEmail
                                        console.log("EditVendor nextStep customer:", billRecipientEmail)
                                        this.setState({ vendor })
                                    }}
                                    onDelete={(index) => {
                                        var emails = []

                                        for (var i = 0; i < billRecipientEmail.length; i++) {
                                            if (i != index) emails.push(billRecipientEmail[i])
                                        }

                                        vendor.billRecipientEmail = emails
                                        this.setState({ vendor })
                                    }}
                                    emails={billRecipientEmail && billRecipientEmail}
                                    instruction="Email of the recipient(s) that will receive bill payment notifications"
                                    unsyncable={isCodatUser(aionStore)}
                                    accountingSoftware={getCodatPlatformName(aionStore)}
                                    optional
                                />

                                <LabeledInput
                                    optional
                                    id="address"
                                    key="address"
                                    label="Address"
                                    labelcolor={theme.colors.secondary3}
                                    type="location"
                                    getLocation={this.getVendorLocation}
                                    Line2
                                    address={address}
                                    value={address && address.line1 ?
                                        { line1: addressObjectToStr(address), line2: address.line2 } : null
                                    }
                                    placeholder="Address"
                                    nomargin
                                />

                                <Flex start style={{ width: "100%", marginTop: 48 }}>
                                    <Button permtype="Payables.Vendors" solid loading={createVendorLoading} onClick={() => this.saveVendor()} text='Save' />
                                </Flex>
                            </FlexColumn>
                        </CardContainer>
                        {
                            vendor.id &&
                            <FlexColumn style={{ width: "48%", justifyContent: "flex-start" }} gap='24px'>
                                {/* <Text color='#444444' weight='500' margin="10px 0 20px 0">Payment Methods</Text> */}
                                {this.getDeliveryMethods()}
                                <CardContainer shadow padding='12px 24px 24px' >
                                    {this.getDocumentSection()}
                                </CardContainer>
                            </FlexColumn>
                        }
                    </Flex>

                </FlexColumn>

                <Modal
                    visible={this.state.showUploadModal}
                    footer={null}
                    closable={true}
                    width={700}
                    destroyOnClose={true}
                    onCancel={() => { this.setState({ showUploadModal: false }) }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    {this.getModalForDocUpload(this.state.uploadDoc || {})}
                </Modal>
                <Modal
                    visible={this.state.showPaymentModal}
                    footer={null}
                    closable={true}
                    width={700}
                    destroyOnClose={true}
                    onCancel={() => this.setState({ showPaymentModal: false, editPm: null })}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <AddPaymentMethod
                        pm={this.state.editPm}
                        vendor={vendor}
                        onSave={(updatedVendor) => {
                            this.setState({ showPaymentModal: false, vendor: updatedVendor }, this.getPaymentMethods({ vendor: updatedVendor }))
                        }} />
                </Modal>
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        bkStore: state.bookkeepingAppReducer,
        aionStore: state.aionAppReducer,
        bankingStore: state.bankingAppReducer,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(NewVendor))