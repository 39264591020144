import React, { Component, lazy } from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import moment from 'moment'
import styled, { withTheme } from 'styled-components'
import { v4 } from 'uuid'
import axios from 'axios'
import { saveAs } from 'file-saver'
import { withRouter } from 'react-router-dom'
import { Image } from './../Reusable/Image'
import Percentage from '../../Images/Percentage.png'

import {
    Alert,
    Layout,
    Input,
    DatePicker,
    Radio,
    Switch,
    List,
    Select,
    Button,
    Steps,
    InputNumber,
    Table,
    Empty,
    ConfigProvider,
    Modal,
    Form,
    Divider,
    Upload,
    message,
    Popover,
    Space,
} from 'antd'

// Content
import { flowViewsData, coOwnerFlowViewsData, submitSingleOwnerApplicationFlowViewsData, submitMultiOwnerApplicationFlowViewsData, incorpTypeShortArr, pppApplication, naicsIndustries, naicsIndustriesMap, invoiceFinancingApplication, signersFlowViewsData } from './viewData'
import OnboardingFlowViewComponent from '../Reusable/OnboardingFlowViewComponent'
import FlowViewComponent from '../Reusable/FlowViewComponent'
import UploadDoc from './UploadDoc'
import { FlexColumn, SideBar, AbstractSideBar, Flex } from '../Reusable/Container'
import { LabeledInput } from '../Reusable/Input'
import { ErrorAlert } from '../Reusable/Alert'
import TwoFactor from '../TwoFactor/Index'
import SearchBusiness from './SearchBusiness'
import PatriotAct from '../../Images/PatriotAct.jpeg'
import SignatureCanvas from 'react-signature-canvas'

import { SAVE_DATA, APP_STATE, PERSONAL_INFO, BUSINESS_INFO, NEW_CO_OWNER, DELETE_CO_OWNER, CO_OWNER_INFO, SIGNERS_INFO, SET_CONTROL_PERSON, PPP_INFO, DEBT_SCHEDULE, RESET, USERDEFAULTS_SAVE_FLAG, BNK_SAVE_DATA, ONB_SAVE_DATA, NEW_SIGNER, DELETE_SIGNER, SIGNER_INFO, addDataToStore, completeSignin, ADD_CONTROL_PERSON } from '../../Actions/actions'
import { getNameObj2, isEmailValid, getAddressObj, addressObjectToStr, capitalizeWords, FLOW_VIEW_TYPE, formatPhoneText, formatSSN, formatEIN, isDOB18, isValidUrl, toCurrency, STATES_MAP, toNumberStr, dateFormatList, getDateString, countryCodeToNameMap, countryNameToCodeMap, countryCodeMapUSFirst, STATES_ABBR_MAP, isSmallScreen, formatOwnershipPercentage } from '../../Utils/util'
import { ExclamationCircleOutlined, DownloadOutlined, DeleteOutlined, UploadOutlined, CheckCircleFilled } from '@ant-design/icons'
import { containerDimensions } from '../../Styles/theme'

// Util
import { apiGET, apiPOST, apiPOSTReq, getPreSignedS3Url } from '../../Utils/api'
import { FlowViewContainer } from '../Reusable/FlowViewContainer'
import { Text, Tag } from '../Reusable/Text'
import environment from '../../environment'
import UploadFiles from '../Reusable/UploadFiles'
import _, { capitalize } from 'lodash'
import { OnboardingMenu, } from './OnboardingMenu'
import Vouched from './Vouched/Index'
import NaicsField from '../Reusable/NaicsField'
import { random } from 'underscore'
import ModalClose from '../../Images/modal-close.png'

const StyledUl = styled.ul`
    li::before: {
        color: red;
    }
`

const STATES = ["Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"]

const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
]

const { Dragger } = Upload
const { confirm } = Modal

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
})

const { Header, Content, Footer } = Layout
const { Option } = Select
const { Step } = Steps
const { TextArea } = Input

const uuid = v4

// Global variable
var flowView = {}
const requiredOwnershipPercentage = 76

const cardStyle = {
    // maxWidth: "400px",
    margin: "20px 0 40px 0",
    padding: "20px 20px",
    borderRadius: "15px",
    // backgroundColor: "rgba(242, 242, 247, 0.6)"
}

const cardStyle2 = {
    // maxWidth: "400px",
    margin: "20px 0 0 0",
    padding: "20px 20px",
    borderRadius: "15px",
    // backgroundColor: "rgba(242, 242, 247, 0.6)",
}

const tableStyle = {
    width: "100%",
    margin: "20px 0 40px 0"
}

const hintStyle = {
    fontSize: "12px",
    margin: "0px 0px 5px 0",
    color: "rgb(0, 122, 255)"
}

const countStyle = {
    fontSize: "12px",
    margin: "5px 0px 0px 0"
}

const inputStyle = {
    textTransform: "capitalize",
    marginBottom: "15px"
    // backgroundColor: "transparent"
}

const docUploadHintStyle = {
    fontSize: "12px",
    margin: "0px 0px 5px 0",
    color: "rgb(0, 122, 255)",
    marginTop: "12pt"
}

const flexRowStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
}

const preFilledStyle = {
    padding: '12px 20px',
    borderRadius: '5px',
    backgroundColor: '#F7F7FA',
    marginBottom: 24,
}

const dateFormat = 'MMM DD, YYYY'

var validURLPattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator

const stepName = {
    0: "Personal Info",
    1: "Business Info",
    2: "Beneficial Owner Info",
    3: "Authorized Signers",
    4: "Submission",
}

class Index extends Component {
    formRef = React.createRef()

    constructor(props) {
        super(props)

        const { appStore } = this.props
        var { Attributes } = appStore || {}
        Attributes = Attributes || {}
        const Users = Attributes.Users || {}

        const countryItems = []
        Object.values(countryCodeMapUSFirst).forEach(item => {
            countryItems.push(
                <Option key={item.code} value={item.code}>
                    {item.name}
                </Option>
            )
        })

        this.state = {
            currentViewIndex: 0,
            flowViews: flowViewsData(),
            flowView: flowViewsData()[0],
            flowViewType: FLOW_VIEW_TYPE.MAIN_FLOW,
            stepProgress: 0,
            showAddLoanModal: false,
            // flowViews: pppApplication(),
            // flowView: pppApplication()[0],
            // flowViewType: FLOW_VIEW_TYPE.PPP_FLOW
            // stepProgress: 3,
            // flowViews: invoiceFinancingApplication(),
            // flowView: invoiceFinancingApplication()[0],
            // flowViewType: FLOW_VIEW_TYPE.INVOICE_FINANCING_FLOW
            debtSchedule: this.props.store.debtSchedule ? this.props.store.debtSchedule : [],
            submitLoading: false,
            continueVisible: true,
            application: 'Application',
            twoFactorTokenValidated: Users.TwoFactorTokenValidated || false,
            isMobile: window.matchMedia("only screen and (max-width: 760px)").matches,
            isTablet: window.matchMedia("only screen and (max-width: 1100px)").matches,
            businessDocuments: [],
            incorpTypeDocumentMap: {
                "LLC": [
                    { name: "Filed Certificate of Formation/Articles of Organization, issued by Secretary of State" },
                    { name: "Business Certificate/Business License (if professional LLC)" },
                    { name: "Certificate of Authority files with state(s) the company is operating in (if incorporated outside of state in which operating)" },
                    { name: "Operating Agreement, signed by members" },
                ],
                "C-Corp": [
                    { name: "Certificate of Incorporation, issued by Secretary of State and filing receipt" },
                    { name: "Trade/Assumed Name Certificate (if existing DBA), must be filed with the Secretary of State" },
                    { name: "Certificate of Good Standing" },
                ],
                "S-Corp": [
                    { name: "Certificate of Incorporation, issued by Secretary of State and filing receipt" },
                    { name: "Trade/Assumed Name Certificate (if existing DBA), must be filed with the Secretary of State" },
                    { name: "Certificate of Good Standing" },
                ],
                "Non-Profit": [
                    { name: "Filed Certificate of Incorporation/Articles of Incorporation, if incorporated this must be issued be the Secretary of State" },
                    { name: "Filed Trade Name Certificate, issued by Secretary of State" },
                    { name: "Tax ID Number/501(c)(3) notification" },
                ],
                "Investment/Hedge/Private Equity Fund": [
                    { name: "Filed business documents (e.g., LLC, Corporation, Limited Partnership) and respective agreements (e.g. Operating Agreement or Partnership Agreement)" },
                    { name: "Offering Documents" },
                ],
                "Trust": [
                    { name: "Trust Agreement, this should include the appointed trustee and must contain completed notary section" },
                ],
                // "Special Purpose Vehicle/Special Purpose Entity": [], 
                // "Private Investment Company/Private Holding Company": [], 
                "Partnership": [
                    { name: "Filed Business/Trade Name Certificate, this must be issued and certified by the applicable county" },
                    { name: "Filed Limited Partnership Certificate, issued by Secretary of State" },
                    { name: "Certified Copy of Partnership Agreement" },
                ],
                "Joint Venture": [
                    { name: "Filed business documents (e.g., LLC, Corporation, Limited Partnership) and respective agreements (e.g. Operating Agreement or Partnership Agreement)" },
                    { name: "Assumed Name Certificate, which must include filing stamp/receipt" },
                    { name: "Joint Venture Agreement" },
                ],
            },
            countryItems,
        }

        //this.props.dispatch(addDataToStore(RESET, {}))
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize)
        const step = this.props.step//new URLSearchParams(this.props.location.search).get("step")
        const applicationType = this.props.applicationType//new URLSearchParams(this.props.location.search).get("application_type")
        const primary_application_completed = this.props.primary_application_completed//new URLSearchParams(this.props.location.search).get("primary_application_completed") || false

        this.setState({
            primaryApplicationCompleted: primary_application_completed,
            applicationType: applicationType,
            //jwt: new URLSearchParams(this.props.location.search).get("jwt"),
            //businessUniqueKey: new URLSearchParams(this.props.location.search).get("business_key")
            businessUniqueKey: this.props.appStore.BusinessUniqueKey
        })
        // Initialize Vouched
        // loadVouched();
        // this.runVouched();
        if(!environment.isSandbox) { this.locationCheck(); }
        this.getIovationBBString();
        //this.props.history.replace('/')
        const { appState } = this.props.store

        switch (step) {
            case "1":
                this.setState({
                    flowViews: flowViewsData(),
                    flowView: flowViewsData()[13],
                    flowViewType: FLOW_VIEW_TYPE.MAIN_FLOW,
                    stepProgress: 1,
                })
                break
            case "2":
                this.setState({
                    flowViews: coOwnerFlowViewsData(),
                    flowView: coOwnerFlowViewsData()[9],
                    flowViewType: FLOW_VIEW_TYPE.CO_OWNER_FLOW,
                    stepProgress: 2,
                })
                break
            case "3":
                this.setState({
                    flowViews: signersFlowViewsData(),
                    flowView: signersFlowViewsData()[0],
                    flowViewType: FLOW_VIEW_TYPE.SIGNER_FLOW,
                    stepProgress: 3,
                })
                break
            default:
                let flowViews = flowViewsData()
                switch (appState.flowViewType) {
                    case FLOW_VIEW_TYPE.CO_OWNER_FLOW:
                        flowViews = coOwnerFlowViewsData()
                        break
                    case FLOW_VIEW_TYPE.SIGNER_FLOW:
                        flowViews = signersFlowViewsData()
                        break
                    case FLOW_VIEW_TYPE.INVOICE_FINANCING_FLOW:
                        flowViews = invoiceFinancingApplication()
                        break
                    case FLOW_VIEW_TYPE.SUBMIT_APPLICATION_FLOW:
                        flowViews = submitSingleOwnerApplicationFlowViewsData()
                        break
                    case FLOW_VIEW_TYPE.SUBMIT_MULTI_OWNER_APPLICATION_FLOW:
                        flowViews = submitMultiOwnerApplicationFlowViewsData()
                        break
                }
                // TODO
                this.setState({
                    currentViewIndex: appState.currentViewIndex || 0,
                    flowViews: flowViews,
                    flowView: flowViews[appState.currentViewIndex || 0],
                    flowViewType: appState.flowViewType || FLOW_VIEW_TYPE.MAIN_FLOW,
                    stepProgress: appState.stepProgress || 0,
                })
                //
                /*
                this.setState({
                    currentViewIndex: 0,
                    flowViews: coOwnerFlowViewsData(),
                    flowView: coOwnerFlowViewsData()[0],
                    flowViewType: FLOW_VIEW_TYPE.CO_OWNER_FLOW,
                    stepProgress: 2
                })
                */
                break
        }
        this.fetchDocuments()
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize)
    }

    handleResize = () => {
        if (typeof (window)) {
            this.setState({
                isMobile: window.matchMedia("only screen and (max-width: 760px)").matches,
                isTablet: window.matchMedia("only screen and (max-width: 1100px)").matches,
            })
        }
    }

    getIovationBBString = () => {
        try {
            var bb3 = window.ioGetBlackbox()
            console.log("Third party bb: " + bb3.blackbox)
            return bb3.blackbox
        } catch (error) {
            console.log("getIovationBBString", error.message)
            return error.message || "Unable to get device info"
        }
    }

    fetchDocuments = () => {
        const { loanApplication } = this.state
        var body = {
            "sortDirection": "DESC"
        }
        if (loanApplication) {
            body["clientBusinessId"] = loanApplication.businessId
            body["applicationId"] = loanApplication.applicationId
            body["viewBy"] = "FILTER_APPLICATION"
        }
        console.log("/docs/getActiveDocuments body", body, loanApplication)
        this.setState({ loading: true })
        apiPOSTReq(`${environment.uamBaseUrl}/docs/getActiveDocuments`, {}, body, (err, resp) => {
            console.log("/docs/getActiveDocuments resp", resp)
            this.setState({ loading: false })
            try {
                const data = resp || {}
                if (data.result) {
                    this.setState({
                        businessDocuments: data.businessDocuments
                    })
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp)
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    handleDocSubmit = (uploadItem) => {
        var { loadingItem } = uploadItem || {}
        var { loanApplication } = this.state

        var businessDocument = {
            "category": "BBOnboarding",
            "name": uploadItem.name,
            "documentUrl": uploadItem.savedFile || {},
        }
        if (loanApplication) businessDocument.applicationId = loanApplication.applicationId
        var body = {
            "businessDocument": businessDocument
        }
        console.log("handleSubmit DOC/SAVE uploadItem body", uploadItem, body)
        this.setState({ submitLoading: true, loadingItem: loadingItem })
        apiPOSTReq(`${environment.uamBaseUrl}/docs/save`, {}, body, (err, resp) => {
            this.setState({ submitLoading: false, loadingItem: null })
            try {
                const data = resp || {}
                if (data.result) {
                    // message.success(`File saved successfully.`, 0.75)
                    this.fetchDocuments()
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/docs/save err", error, resp)
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    handlePreview = async file => {
        if (file.uri) {
            getPreSignedS3Url({ url: file.uri }, (err, preSignedUrl) => {
                saveAs(preSignedUrl, file.fileName)
            })
        }
    }

    modifyFileList = fileList => {
        fileList.forEach((file, index) => {
            file.uid = index
            file.name = file.fileName
            file.url = file.uri
        })
    }

    uploadProps = (doc) => {
        return {
            name: `business-docs`,
            multiple: false,
            action: `${environment.apiBaseUrl}/file/upload`,
            headers: {
                businesskey: this.props.appStore.BusinessUniqueKey,
                jwt: this.props.appStore.jwt
            },
            onChange: (info) => {
                var { fileList } = info
                var savedFile = {}
                fileList.forEach(file => {
                    const { status, response, name, url } = file
                    if (status === "done") {
                        savedFile = {
                            fileName: name,
                            uri: (response.UploadedUrls || [])[0],
                        }
                        if (doc.type) savedFile.type = doc.type
                        message.success(`${info.file.name} file uploaded successfully.`, 0.75)
                        doc.savedFile = savedFile
                        this.handleDocSubmit(doc)
                    } else if (status === 'error') {
                        console.log("BusinessInfo.IncorpType", response)
                        message.error(`${info.file.name} file upload failed.`)
                    }
                })
                doc.savedFile = savedFile
                this.setState({ [doc.name]: doc })
            }
        }
    }

    getAttributes = () => {
        // Check session and fetch attributes
        apiGET("/attributes", null, (err, resp) => {
            this.setState({ show2FAModal: false })

            try {
                const data = resp.data
                if (data.success) {
                    console.log("/attributes", JSON.stringify(data))

                    this.setState({ twoFactorTokenValidated: data.Attributes.Users.TwoFactorTokenValidated })
                } else {
                    throw Error("Could not fetch attributes.")
                }
            } catch (error) {
                console.log("/attr ERR", error, err, resp)
                // ErrorAlert({description: "Sorry, we had trouble logging you in, please try again."})
                this.setState({ loading: false })
            }
        })
    }

    render() {
        flowView = this.state.flowView

        var step4Title

        switch (this.state.applicationType) {
            case "ppp":
                step4Title = "PPP Questions"
                break
            case "invoice_financing":
                step4Title = "Debt Schedule"
                break
            default:
                step4Title = "Submit"
                break
        }

        return (
            <>
                {
                    !this.props.modal ?
                        <FlexColumn center style={{ minHeight: "100%" }}>
                            {this.getContentViews()}
                        </FlexColumn>
                        :
                        <FlowViewContainer width="600" modal={this.props.modal} title="Financials" contentViews={this.getContentViews()} />
                }

                <Modal
                    visible={this.state.show2FAModal}
                    footer={null}
                    closable={true}
                    width={500}
                    style={{ top: 20 }}
                    destroyOnClose={true}
                    onCancel={() => { this.setState({ show2FAModal: false }) }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <TwoFactor title={"Email confirmation"} delivery={"Email"} validationComplete={this.twoFAValidated} />
                </Modal>
            </>

        )
    }

    twoFAValidated = () => {
        this.getAttributes()
    }

    locationCheck = () => {
        var t = this
        axios.get('https://ipinfo.io?token=28dbfb85263943')
            .then(function (response) {
                // handle success
                console.log("locationCheck", response)
                var data = response.data || {}
                var country = data.country
                if (country !== "US") {
                    t.setManualReviewRequired(`This application was submitted outside the US (${country})`)
                }
            })
            .catch(function (error) {
                // handle error
                console.log("locationCheck", error)
            })
    }

    handleOnChange = (event) => {
        const target = event.target
        this.saveUserInput(target.id || target.name, target.value)
    }

    handleOnChangeToState = (event) => {
        const target = event.target
        this.setState({ [target.id]: target.value })
    }

    handlePhoneChange = (event) => {
        const target = event.target
        var phone = formatPhoneText(target.value)
        this.saveUserInput("Phone", phone)
        this.setState({ [target.id]: phone })
    }

    handleSSNChange = (event) => {
        const target = event.target
        var ssn = formatSSN(target.value)
        this.saveUserInput("ssn", ssn)
        this.setState({ [target.id]: ssn })
    }

    handleEINChange = (event) => {
        const target = event.target
        var ein = formatEIN(target.value)
        this.saveUserInput("ein", ein)
        this.setState({ [target.id]: ein })
    }

    handleDateChange = (date, dateString) => {
        if (date) {
            this.saveUserInput("Date", date.toDate())
        } else {
            this.saveUserInput("Date", null)
        }
    }

    handleSearchText = (searchText) => {
        //this.props.dispatch(addDataToStore(BUSINESS_INFO, { businessSearchText: searchText }))
        this.props.dispatch(addDataToStore(APP_STATE, { businessSearchText: searchText }))
    }

    handleSearchResults = (searchResults) => {
        //this.props.dispatch(addDataToStore(BUSINESS_INFO, { businessSearchResults: searchResults }))
        this.props.dispatch(addDataToStore(APP_STATE, { businessSearchResults: searchResults }))
    }

    handleSelectBusiness = (company) => {
        // set business name
        this.props.dispatch(addDataToStore(BUSINESS_INFO, { name: company.name, oc: company }))

        // set incorporation date
        if (company.incorporation_date)
            this.props.dispatch(addDataToStore(BUSINESS_INFO, { incorporationDate: moment(company.incorporation_date).format('YYYY-MM-DD') }))
        else
            this.props.dispatch(addDataToStore(BUSINESS_INFO, { incorporationDate: null }))

        // set incorporation state
        if (company.jurisdiction_code) {
            let state = company.jurisdiction_code.substr(company.jurisdiction_code.lastIndexOf('_') + 1)
            state = state.toUpperCase()
            this.props.dispatch(addDataToStore(BUSINESS_INFO, { incorporationState: state }))
        } else
            this.props.dispatch(addDataToStore(BUSINESS_INFO, { incorporationState: null }))

        // set business address
        let addressObj = null
        const address = company.registered_address
        if (address && address.street_address && address.locality && address.region && address.postal_code) {
            addressObj = {
                "line1": address.street_address,
                "city": address.locality,
                "countrySubDivisionCode": ((address.region || "").length == 2) ? address.region : STATES_ABBR_MAP[address.region],
                "postalCode": address.postal_code,
                "country": address.country,
                "countryCode": countryNameToCodeMap[address.country],
            }
        }
        this.props.dispatch(addDataToStore(BUSINESS_INFO, { address: addressObj, mailingAddress: addressObj }))

        // go to next screen
        this.loadNext()
    }

    // clear any old business info, if any
    handleCantFindBusiness = () => {
        this.handleSelectBusiness({ name: null, incorporationDate: null, incorporationState: null, registered_address: null })
        this.setManualReviewRequired("Could not find business in open corporates.")
    }

    handleOnKeyDown = (event) => { if (event.key === "Enter") this.loadNext() }

    disabledDOBDate = (current) => {
        // Can not select days after today and today and weekends
        return current > moment().subtract('18', "years")
    }

    disabledIncorporationDate = (current) => {
        // Can not select days after today and today and weekends
        return current > moment()
    }

    getLocation = (location, formattedAddress) => {
        let addrObj = getAddressObj(location)
        addrObj.city = addrObj.city ? addrObj.city.substring(0, 18) : ''
        this.saveUserInput("Address", addrObj)
    }

    setManualReviewRequired = (msg) => {
        this.props.dispatch(addDataToStore(SAVE_DATA, { manualReviewRequired: true, manualReviewRequiredMsg: msg }))
    }

    renderEmptyDebtSchedule = () => (
        <Empty
            imageStyle={{
                height: 60,
            }}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
                <span>
                    No loans added
                </span>
            }>
            <Button type="primary" onClick={() => this.setState({ showAddLoanModal: true })}>Add Debt</Button>
        </Empty>
    )

    getCurrentCoOwner = () => {
        var coOwnerInfo
        if (this.state.editCoOwnerId) {
            console.log("getCurrentCoOwner", 0, this.state.editCoOwnerId)
            coOwnerInfo = this.props.store.coOwnersInfo[this.state.editCoOwnerId]
        } else if (this.props.store.coOwnerId === null) {
            console.log("getCurrentCoOwner", 1)
            coOwnerInfo = this.props.store.coOwnersInfo.slice(-1)[0] || {}  // get last co owner
        } else {
            console.log("getCurrentCoOwner", 2, this.props.store.coOwnerId)
            coOwnerInfo = this.props.store.coOwnersInfo[this.props.store.coOwnerId]
        }

        // console.log("getCurrentCoOwner", coOwnerInfo)

        return coOwnerInfo
    }

    getCurrentSigner = () => {
        var signer
        if (this.state.editSigner) {
            signer = this.props.store.signersInfo[this.state.editSignerId]
        } else if (this.props.store.signersInfo === null) {
            signer = this.props.store.signersInfo.slice(-1)[0] || {}  // get last signer
        } else {
            signer = this.props.store.signersInfo[this.props.store.signerId]
        }

        return signer
    }

    editCoOwner = (id) => {
        // this.props.dispatch(addDataToStore(ONB_SAVE_DATA, { coOwnerId: id }))

        this.setState({
            currentViewIndex: 1,
            flowView: coOwnerFlowViewsData()[2],
            flowViews: coOwnerFlowViewsData(),
            flowViewType: FLOW_VIEW_TYPE.CO_OWNER_FLOW,
            editCoOwnerId: id,
        })
    }

    saveStatus = () => {
        if (this.state.stepProgress === 1 && this.state.flowViewType === FLOW_VIEW_TYPE.MAIN_FLOW && this.state.currentViewIndex >= 8) {
            return true
        }
        if (this.state.stepProgress === 2 && this.state.flowViewType === FLOW_VIEW_TYPE.CO_OWNER_FLOW) {
            return true
        }
        if (this.state.stepProgress === 3 && this.state.flowViewType === FLOW_VIEW_TYPE.SIGNER_FLOW) {
            return true
        }
        if (this.state.stepProgress === 4 && (this.state.flowViewType === FLOW_VIEW_TYPE.SUBMIT_MULTI_OWNER_APPLICATION_FLOW || this.state.flowViewType === FLOW_VIEW_TYPE.SUBMIT_APPLICATION_FLOW)) {
            return true
        }
        return false
    }

    // This will provide the view for each flowView
    getContentViews = () => {
        const StyledParagraph = styled(Text)`
            color: ${props => this.props.theme.colors.secondary3}
            margin: 0 
            font-size: ${props => props.fontSize || "0.9rem"}
            font-weight: ${props => props.fontWeight || "400"}
        `

        var continueLink, altButtonTitle, altButtonClick

        // Columns for debt schedule table
        const debtScheduleColumns = [
            {
                title: 'Loan Company',
                dataIndex: 'loanCompany',
                key: 'loanCompany',
                width: '15%'
            },
            {
                title: 'Start Date',
                dataIndex: 'startDate',
                key: 'startDate',
                render: date => (moment(date).format('MMM Do, YYYY')),
                width: '10%'
            },
            {
                title: 'Original Balance',
                dataIndex: 'originalBalance',
                key: 'originalBalance',
                render: amount => (formatter.format(amount)),
                width: '10%'
            },
            {
                title: 'Current Balance',
                dataIndex: 'currentBalance',
                key: 'currentBalance',
                render: amount => (formatter.format(amount)),
                width: '10%'
            },
            {
                title: 'Term (months)',
                dataIndex: 'originalTerm',
                key: 'originalTerm',
                width: '5%'
            },
            {
                title: 'Monthly Payment',
                dataIndex: 'monthlyPayment',
                key: 'monthlyPayment',
                render: amount => (formatter.format(amount)),
                width: '10%'
            },
            {
                title: 'Payment Due On',
                dataIndex: 'paymentDueDate',
                key: 'paymentDueDate',
                width: '10%'
            },
            {
                title: 'Maturity Date',
                dataIndex: 'maturityDate',
                key: 'maturityDate',
                render: date => (moment(date).format('MMM Do, YYYY')),
                width: '10%'
            },
            {
                title: 'Interest Rate',
                dataIndex: 'interestRate',
                key: 'interestRate',
                render: rate => `${rate}%`,
                width: '5%'
            },
            {
                title: 'Notes',
                dataIndex: 'note',
                key: 'note',
                width: '15%'
            }
        ]

        // Reset user input
        if (flowView.DefaultUserInput) {
            flowView.UserInput = flowView.DefaultUserInput
        }

        var childViews = []

        const { countryItems } = this.state;
        const { store, theme, appStore } = this.props;
        var { appState, personalInfo, businessInfo } = store;
        businessInfo = businessInfo || {};
        const coOwners = this.props.store.coOwnersInfo || []
        const signers = this.props.store.signersInfo || []

        var coOwnerInfo = this.getCurrentCoOwner()
        var signer = this.getCurrentSigner()

        var { BBConfiguration } = this.props.appStore.AppConfig || {}
        var { Attributes } = appStore || {}
        Attributes = Attributes || {}

        const StyledLi = styled.li`
            color: ${props => props.theme.colors.systemGray}
            font-size: 1.1rem
            ::before {
                content: '☑'
                color: ${props => props.theme.colors.secondary1}
                margin-right: 10px
                margin-left: -30px
                font-size: 1.2em!important
            }
        `

        // Make any customizations here
        switch (flowView.OnboardingStep) {
            case "Intro.Welcome":
                break
            case "Intro.Subscribe":
                const starterSubscriptionAmount = BBConfiguration["StarterSubscriptionAmount"] || 0.0
                const oldSubscriptionAmount = BBConfiguration["StarterSubscriptionOldAmount"] || 0.0
                const features = BBConfiguration["StarterSubscriptionFeatures"] || []
                if (oldSubscriptionAmount <= starterSubscriptionAmount) {
                    childViews.push(
                        <Text key="1" color={theme.colors.secondary4} level={4} bold>${toCurrency(starterSubscriptionAmount)}/month</Text>
                    )
                } else {
                    childViews.push(
                        <Text key="2" color={theme.colors.secondary4} level={4} bold noMargin><strike style={{ color: theme.colors.systemGray }}>${toCurrency(oldSubscriptionAmount)}</strike> ${toCurrency(starterSubscriptionAmount)}/month</Text>
                    )
                }
                childViews.push(
                    <Text key="3" color={theme.colors.secondary6} level={1} bold>Launch Edition</Text>
                )
                childViews.push(
                    <ul key="4" style={{ listStyle: 'none' }}>
                        {features.map((item, i) => <StyledLi key={i}>{item}</StyledLi>)}
                    </ul>
                )
                break
            case "SubmitInfo.InitalDeposit":
                const achSupported = BBConfiguration["ACHMinimumDepositEnabled"] || false
                flowView.DescriptionToDisplay = `After an application is approved, the payment info will be executed and the proceeds will be deposited into the new account.`
                var items = []
                flowView.ContentItems.forEach(item => {
                    if (!achSupported && (item === "Bank transfer")) return
                    items.push(<Radio key={item} value={item}>{item}</Radio>)
                })
                const initialDepositType = businessInfo.initialDepositType || null
                childViews = (
                    <FlexColumn>
                        <LabeledInput
                            autoFocus
                            labelcolor={this.props.theme.colors.secondary3}
                            label={`Deposit amount (Minimum of $${toCurrency(BBConfiguration.MinimumDeposit)})`}
                            key={flowView.OnboardingStep + ".DepositAmt"}
                            id="Amt"
                            onChange={this.handleOnChange}
                            value={businessInfo.initialDepositAmt}
                            placeholder="100"
                            prefix="$"
                            onKeyDown={this.handleOnKeyDown}
                        />
                        <FlexColumn>
                            <Text size='14px' color={this.props.theme.colors.secondary3}>Deposit type</Text>
                            <Radio.Group style={{ marginTop: 5 }} key={flowView.OnboardingStep} id="initialDepositType" name="initialDepositType" onChange={this.handleOnChange} value={initialDepositType}
                                buttonStyle="solid" size="large">
                                {items}
                            </Radio.Group>
                            <Divider style={{ margin: "10px 0 0 0" }} />
                        </FlexColumn>
                        {
                            (() => {
                                if (initialDepositType == "Bank transfer") {
                                    return (
                                        <>
                                            <LabeledInput
                                                labelcolor={this.props.theme.colors.secondary3}
                                                label="Name on account*"
                                                key={flowView.OnboardingStep + ".NameOnAccount"}
                                                id="NameOnAccount"
                                                onChange={this.handleOnChange}
                                                value={businessInfo.initialDepositNameOnAccount}
                                                placeholder="Richard Hendricks"
                                                onKeyDown={this.handleOnKeyDown}
                                            />
                                            <LabeledInput
                                                labelcolor={this.props.theme.colors.secondary3}
                                                label="Checking account number*"
                                                key={flowView.OnboardingStep + ".AccountNumber"}
                                                id="AccountNumber"
                                                onChange={this.handleOnChange}
                                                value={businessInfo.initialDepositAccountNumber}
                                                placeholder="776288901123"
                                                onKeyDown={this.handleOnKeyDown}
                                            />
                                            <LabeledInput
                                                labelcolor={this.props.theme.colors.secondary3}
                                                label="Routing number*"
                                                key={flowView.OnboardingStep + ".RoutingNumber"}
                                                id="RoutingNumber"
                                                onChange={this.handleOnChange}
                                                value={businessInfo.initialDepositRoutingNumber}
                                                placeholder="052001633"
                                                onKeyDown={this.handleOnKeyDown}
                                            />
                                        </>
                                    )
                                } if (initialDepositType == "Debit card") {
                                    return (
                                        <>
                                            <LabeledInput
                                                labelcolor={this.props.theme.colors.secondary3}
                                                label="Name on account*"
                                                key={flowView.OnboardingStep + ".NameOnAccount"}
                                                id="NameOnAccount"
                                                onChange={this.handleOnChange}
                                                value={businessInfo.initialDepositNameOnAccount}
                                                placeholder="Richard Hendricks"
                                                onKeyDown={this.handleOnKeyDown}
                                            />
                                            <LabeledInput
                                                labelcolor={this.props.theme.colors.secondary3}
                                                label="Card number*"
                                                key={flowView.OnboardingStep + ".CardNumber"}
                                                id="CardNumber"
                                                onChange={this.handleOnChange}
                                                value={businessInfo.initialDepositCardNumber}
                                                placeholder="4242424242425555"
                                                onKeyDown={this.handleOnKeyDown}
                                            />
                                            <LabeledInput
                                                labelcolor={this.props.theme.colors.secondary3}
                                                label="CVC* (Three or four digit card verification code)"
                                                key={flowView.OnboardingStep + ".CVC"}
                                                id="CVC"
                                                onChange={this.handleOnChange}
                                                value={businessInfo.initialDepositCVC}
                                                placeholder="567"
                                                maxLength={4}
                                                onKeyDown={this.handleOnKeyDown}
                                            />
                                            <LabeledInput
                                                labelcolor={this.props.theme.colors.secondary3}
                                                label="Card expiration date*"
                                                type="date-picker"
                                                picker="month"
                                                format="MM/YYYY"
                                                key={flowView.OnboardingStep + ".ExpMonthYear"}
                                                id="ExpMonthYear"
                                                onChange={(date, dateStr) => this.saveUserInput("ExpMonthYear", dateStr)}
                                                placeholder="01/2031"
                                                onKeyDown={this.handleOnKeyDown}
                                            />
                                            <LabeledInput
                                                labelcolor={this.props.theme.colors.secondary3}
                                                label="Zip code*"
                                                key={flowView.OnboardingStep + ".Zip"}
                                                id="Zip"
                                                onChange={this.handleOnChange}
                                                value={businessInfo.initialDepositZip}
                                                placeholder="94131"
                                                onKeyDown={this.handleOnKeyDown}
                                            />
                                        </>
                                    )
                                } else {
                                    return null
                                }
                            })()
                        }
                    </FlexColumn>
                )
                break

            case "SubmitInfo.OwnershipCertification":
                var sigStyle = { borderRadius: 5, marginBottom: 6 }
                if(isSmallScreen) sigStyle["zoom"] = "1.2"
                flowView.descComponent = (
                    <FlexColumn start>
                        <Text level={1} style={{ color: theme.colors.systemGray }}>{flowView.Subtitle}</Text>
                        {/* <Text level={3} style={{ color: 'black' }}>{flowView.Description}</Text> */}
                        <br />
                        {/* <div style={preFilledStyle}>
                            <Text noMargin level={3} style={{ color: 'black' }}>{`${personalInfo.firstName || ''}${personalInfo.spacer || ''}${personalInfo.lastName || ''}`}</Text>
                        </div> */}
                        <Text style={{ fontSize: 16 }}>{flowView.Description}</Text>
                        <div style={{ backgroundColor: "rgba(242, 242, 247, 0.6)" }}>
                            <SignatureCanvas
                                // backgroundColor='#F7F7FA'
                                penColor='black'
                                ref={(ref) => { this.sigBOCert = ref }}
                                canvasProps={{ width: 450, height: 120, className: 'sigCanvas', style: sigStyle }}
                            />
                        </div>
                        <Text style={{ fontSize: 16, color: '#5A8FF4', cursor: 'pointer', marginBottom: 24 }} onClick={() => { this.sigBOCert.clear() }}>Clear Signature</Text>
                        <div style={preFilledStyle}>
                            <Text noMargin level={3} style={{ color: 'black' }}>{moment().format('ll')}</Text>
                        </div>
                    </FlexColumn>
                )
                break
            case "PersonalInfo.IDV":
                flowView.hideButton = true
                var idvKey = flowView.OnboardingStep
                if (this.state.reloadIDV) idvKey = idvKey + random(500)
                console.log("Loading vouched with idvKey", idvKey)
                childViews = (
                    <Vouched
                        key={idvKey}
                        onDone={(job) => {
                            this.saveUserInput("vouched", job)
                            console.log("PersonalInfo.IDV onDone", job)
                            this.loadNext()
                        }}
                    />
                )
                break
            case "PersonalInfo.Edit":
                childViews = (
                    <div>
                        <div style={cardStyle}>
                            <LabeledInput
                                autoFocus
                                labelcolor={this.props.theme.colors.secondary3}
                                label="Full Legal Name"
                                key={flowView.OnboardingStep + ".Name"}
                                id="Name"
                                onChange={this.handleOnChange}
                                value={`${personalInfo.firstName || ''}${personalInfo.spacer || ''}${personalInfo.lastName || ''}`}
                                placeholder="Larry Lin"
                            />
                            <LabeledInput
                                labelcolor={this.props.theme.colors.secondary3}
                                label="Date of Birth"
                                type="date-picker"
                                defaultPickerValue={moment().subtract(18, "years")}
                                disabledDate={this.disabledDOBDate}
                                key={flowView.OnboardingStep + ".DOB"}
                                id="Date"
                                onChange={this.handleDateChange}
                                format={dateFormatList}
                                value={personalInfo.dob && moment(personalInfo.dob)}
                                placeholder="Pick a date"
                            />
                            <LabeledInput
                                labelcolor={this.props.theme.colors.secondary3}
                                label="Home address"
                                type="location"
                                key={flowView.OnboardingStep + ".HomeAddr"}
                                getLocation={this.getLocation}
                                value={(personalInfo.address)}
                                placeholder="Enter address"
                                country
                            />
                            {/* <LabeledInput
                                labelcolor={this.props.theme.colors.secondary3}
                                label="Apartment/Suite #"
                                key={flowView.OnboardingStep+".Apartment"}
                                id="Line2"
                                onChange={this.handleOnChange}
                                value={personalInfo.address && personalInfo.address.line2}
                                placeholder="Apt 200"
                            /> */}
                        </div>
                    </div>
                )
                break
            case "PersonalInfo.Citizenship":
                // flowView.TitleToDisplay = `${flowView.Title} ${this.props.store.personalInfo.firstName || ""}?`
                var items = []
                flowView.ContentItems.forEach(item => {
                    items.push(<Radio.Button style={{ width: "150px", textAlign: "center" }} key={item} value={item}>{item}</Radio.Button>)
                })
                childViews = (
                    <div style={cardStyle}>
                        <LabeledInput
                            labelcolor={this.props.theme.colors.secondary3}
                            label="Citizenship"
                            type="select"
                            key={flowView.OnboardingStep}
                            id="Citizenship"
                            placeholder="Select country of citizenship"
                            className="no-left-padding"
                            optionFilterProp="children"
                            onChange={value => { this.saveUserInput("countryOfCitizenship", value) }}
                            value={personalInfo.countryOfCitizenship}
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().startsWith(input.toLowerCase())
                            }
                        >
                            {countryItems}
                        </LabeledInput>
                    </div>
                )
                break
            case "PersonalInfo.Resident":
                // flowView.TitleToDisplay = `${flowView.Title} ${this.props.store.personalInfo.firstName || ""}?`
                var items = []
                flowView.ContentItems.forEach(item => {
                    items.push(<Radio.Button style={{ width: "150px", textAlign: "center" }} key={item} value={item}>{item}</Radio.Button>)
                })
                childViews = (
                    <FlexColumn center>
                        <Radio.Group key={flowView.OnboardingStep} id="resident" name="resident" onChange={this.handleOnChange} defaultValue={flowView.DefaultUserInput}
                            value={personalInfo.resident === false ? "No" : "Yes"} buttonStyle="solid" size="large">
                            {items}
                        </Radio.Group>
                    </FlexColumn>
                )
                break
            case "PersonalInfo.SSN":
                childViews = (
                    <div style={cardStyle}>
                        <LabeledInput
                            autoFocus
                            onKeyDown={this.handleOnKeyDown}
                            labelcolor={this.props.theme.colors.secondary3}
                            label="Social Security Number"
                            id="personalSSN"
                            placeholder="123-44-5555"
                            onChange={this.handleSSNChange}
                            value={personalInfo.ssn}
                            maxLength={11}
                        />
                    </div>
                )
                break
            case "PersonalInfo.BusinessTitle":
            case "OwnerInfo.BusinessTitle":
                let titleValue
                let otherValue
                if (flowView.OnboardingStep == "PersonalInfo.BusinessTitle") {
                    flowView.TitleToDisplay = `${this.props.store.personalInfo.firstName || ""}${flowView.Title}`
                    titleValue = personalInfo.businessTitle
                    otherValue = personalInfo.businessTitleOther
                } else {
                    flowView.TitleToDisplay = `${flowView.Title}${coOwnerInfo.firstName || ""}'s role`
                    titleValue = coOwnerInfo.businessTitle
                    otherValue = coOwnerInfo.businessTitleOther
                }
                var items = []
                flowView.ContentItems.forEach(item => {
                    items.push(<Radio.Button key={item} value={item}>{item}</Radio.Button>)
                })
                childViews = (
                    <FlexColumn center>
                        <Radio.Group key={flowView.OnboardingStep} id="businessTitle" name="businessTitle" onChange={this.handleOnChange} defaultValue={flowView.DefaultUserInput} value={titleValue} buttonStyle="solid" size="large">
                            {items}
                        </Radio.Group>
                        {
                            flowView.OtherInput && titleValue === "Other" &&
                            <div style={{ width: 340, ...cardStyle }}>
                                <LabeledInput
                                    labelcolor={this.props.theme.colors.secondary3}
                                    label="Title *"
                                    key={flowView.OnboardingStep + ".BusinessTitleOther"}
                                    id="businessTitleOther"
                                    name="businessTitleOther"
                                    onChange={this.handleOnChange}
                                    value={otherValue}
                                    placeholder="Add title"
                                    onKeyDown={this.handleOnKeyDown}
                                />
                            </div>
                        }
                    </FlexColumn>
                )
                break
            case "PersonalInfo.BusinessRole":
            case "OwnerInfo.BusinessRole":
                let roleValue = personalInfo.businessRole
                if (flowView.OnboardingStep == "OwnerInfo.BusinessRole") {
                    roleValue = coOwnerInfo.businessRole
                }
                var items = []
                flowView.ContentItems.forEach(item => {
                    items.push(<Radio.Button key={item} value={item}>{item}</Radio.Button>)
                })
                childViews = (
                    <FlexColumn center>
                        <Radio.Group key={flowView.OnboardingStep} id="BusinessRole" name="businessRole" onChange={this.handleOnChange} defaultValue={flowView.DefaultUserInput} value={roleValue} buttonStyle="solid" size="large">
                            {items}
                        </Radio.Group>
                    </FlexColumn>
                )
                break
            case "PersonalInfo.Id":
                var items = []
                flowView.ContentItems.forEach(item => {
                    items.push(
                        <Option key={item} value={item}>
                            {item}
                        </Option>
                    )
                })

                childViews = (
                    <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 80 }}>
                        <div style={cardStyle}>
                            {
                                personalInfo.coOwnerInfo.countryOfCitizenship === 'US' &&
                                <LabeledInput
                                    labelcolor={this.props.theme.colors.secondary3}
                                    label="Document Type"
                                    type="select"
                                    key={flowView.OnboardingStep + ".DocumentType"}
                                    id="idType"
                                    placeholder="Select document type"
                                    className="no-left-padding"
                                    onChange={value => { this.saveUserInput("idType", value) }}
                                    value={personalInfo.idType}
                                >
                                    {items}
                                </LabeledInput>
                            }
                            <LabeledInput
                                labelcolor={this.props.theme.colors.secondary3}
                                label={personalInfo.coOwnerInfo.countryOfCitizenship === 'US' ? "Document ID #" : "Passport Number"}
                                key={flowView.OnboardingStep + ".Number"}
                                id="idNumber"
                                onChange={this.handleOnChange}
                                value={personalInfo.idNumber}
                                placeholder={personalInfo.coOwnerInfo.countryOfCitizenship === 'US' ? "Enter document id number" : "Enter passport number"}
                            />
                            <LabeledInput
                                labelcolor={this.props.theme.colors.secondary3}
                                label="Expiry Date"
                                type="date-picker"
                                key={flowView.OnboardingStep + ".ExpDate"}
                                id="idExpiryDate"
                                onChange={this.handleDateChange}
                                format={dateFormatList}
                                value={personalInfo.idExpiryDate && moment(personalInfo.idExpiryDate)}
                                placeholder="Pick a date"
                                disabledDate={(current) => current < moment().add(1, 'd').startOf('day')}
                            />
                            <LabeledInput
                                labelcolor={this.props.theme.colors.secondary3}
                                label={personalInfo.coOwnerInfo.countryOfCitizenship === 'US' ? "Country or State of Issuance" : "Country of Issuance"}
                                key={flowView.OnboardingStep + ".PlaceOfIssuance"}
                                id="idPlaceOfIssuance"
                                onChange={this.handleOnChange}
                                value={(personalInfo.idPlaceOfIssuance)}
                                placeholder={personalInfo.resident ? "Two letter code" : "US"}
                                maxLength={2}
                                instruction={!personalInfo.resident ? "Enter 2 letter country code" : null}
                            />
                        </div>
                        <UploadFiles
                            key={flowView.OnboardingStep}
                            customUploadURI={`${environment.apiBaseUrl}/user/file/upload`}
                            onUpload={(fileList) => {
                                this.modifyFileList(fileList)
                                this.props.dispatch(addDataToStore(PERSONAL_INFO, { idDocumentUrl: fileList[0], idFileList: fileList }))
                            }}
                            maxCount={1}
                            showDragger={true}
                            fileList={personalInfo.idFileList}
                            onPreview={this.handlePreview}
                        />
                    </div>
                )
                break
            case "PersonalInfo.PatriotAct":
                var desc = flowView.ChildDescription
                var descArr = null
                if ((desc || "").includes("\n")) {
                    const arr = desc.split("\n")
                    descArr = []
                    arr.forEach((item, i) => {
                        descArr.push(<br key={i} />)
                        descArr.push(item)
                    })
                }
                childViews = (
                    <FlexColumn>
                        <img
                            src={PatriotAct}
                            width='100%'
                            // height={animationWidth}
                            style={{ marginBottom: 20 }}
                        />
                        <Text level={3} style={{ color: theme.colors.systemGray }}>{descArr}</Text>
                    </FlexColumn>
                )
                break
            // case "PersonalInfo.AuthorizedSigner":
            case "PersonalInfo.ControlPerson":
                let checked = personalInfo.authorizedSigner
                if (flowView.OnboardingStep == 'PersonalInfo.ControlPerson')
                    checked = personalInfo.controlPerson
                else if (flowView.OnboardingStep == 'BusinessInfo.IndustryConsent')
                    checked = businessInfo.industryConsent
                childViews = (
                    <List
                        itemLayout="horizontal"
                        dataSource={flowView.ContentItems}
                        renderItem={item => (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={<Switch size='small' key={flowView.OnboardingStep} defaultChecked={flowView.DefaultUserInput || false} checked={checked} onChange={(checked, event) => { this.saveUserInput(flowView.FieldName, checked) }} />}
                                    title={item}
                                />
                            </List.Item>
                        )}
                    />
                )
                break
            case "BusinessInfo.IndustryConsent":
                let industryConsentChecked = businessInfo.industryConsentChecked

                childViews = (
                    <FlexColumn between>
                        <a
                            style={{ marginTop: 24, marginBottom: 48, textDecoration: 'underline' }}
                            onClick={() => {
                                // this.setState({ isModalVisible: true })
                                Modal.warning({
                                    width: 700,
                                    title: "Prohibited Customers",
                                    content: (
                                        <div style={{ height: 700, overflowY: 'auto', paddingTop: 12, paddingRight: 40 }}>
                                            <p>Aion maintains a list of customers and activities that are deemed to pose a high risk for money laundering and terrorist financing, and may prohibit customers engaged in such activities from using Aion’s products or services.</p>
                                            <br />
                                            <p>Aion’s list of prohibited business and activities includes the following:</p>
                                            <StyledUl>
                                                <li>Any potential customer whose identity cannot be verified</li>
                                                <li>Individuals prohibited under applicable sanctions programs</li>
                                                <li>Individuals included on Aion internal watch list (or equivalent)</li>
                                                <li>Marijuana, Hemp, CBD, Private ATM Owners</li>
                                                <li>Unlicensed or Unregistered Money Service Business</li>
                                                <li>Online/Internet Gambling</li>
                                                <li>Countries, governments, entities and individuals subject to sanctions or included in any applicable internal lists</li>
                                                <li>Marijuana-related businesses (MRB) (such as manufacturers, dispensers, and those engaged in medical marijuana), including companies whose main source of revenue is derived from this type of activity are prohibited</li>
                                                <li>Customers engaged in the defense sector or the manufacture or production of arms, military equipment or weapons of mass destruction. This includes, but is not limited to: anti-personnel mines, chemical weapons, cluster munitions, military equipment or technology, nuclear weapons, military and dual-use equipment, internal repression equipment and security and police equipment</li>
                                                <ul>
                                                    <li>There may be instances dependent on the Client Type or nature of business that may require an analysis of the customer’s activity/operations in the defense sector and annual revenue and percentage of sales/revenue attributed to the above referenced defense sector activities. These should be escalated to Cross River Financial Crime Compliance.</li>
                                                </ul>
                                                <li>Anonymous or numbered accounts or Customers seeking to maintain an account in an obviously fictitious name</li>
                                                <li>New bearer share issuance or issued bearer shares that have not been immobilized or are not with an approved custodian</li>
                                                <li>Customers whose identities are not known or cannot be verified</li>
                                                <li>Customers exited for financial crime concerns. In addition, customers or related parties where there is a strong suspicion or direct evidence that criminal activity has taken place, where a criminal offence has been committed and charges have been brought or where there is a suspicion of terrorist financing</li>
                                                <li>Shell banks – i.e. an entity that has no physical existence in the country in which it is incorporated and licensed, and which is unaffiliated with a regulated financial group that is subject to effect consolidated supervision (Section 313 of the USA PATRIOT Act)</li>
                                                <li>Unlicensed or unregistered Money Services Businesses – i.e., companies offering services involving money/currency exchange, money transfer, cheque cashing, and issuing or selling travelers cheques, money orders or stored value cards that meet the regulatory definition.</li>
                                                <li>Unlawful Internet Gaming Companies, their principals, and their payment processors and companies whose main source of revenue is derived from either the development of gambling software or hosting environments as defined in the Unlawful Internet Gambling Act of 2006 (UIGEA) and Prohibition of Funding of Unlawful Internet Gambling (“Regulation GG”)</li>
                                                <li>Adult entertainment businesses, escort services and sexually oriented or pornographic products and services</li>
                                                <li>Payday lenders, their owners, and principals and</li>
                                                <li>Missions, embassies, and consulates</li>
                                                <li>Verified Hackers</li>
                                            </StyledUl>
                                        </div>
                                    ),
                                    okText: "Okay",
                                    // ...props
                                    // PASS BELOW PROPS TO CONTROL MODAL ACTION
                                    // onOk: props.onOk
                                    // onCancel: props.onCancel
                                })
                            }
                            }>See full list of unsupported businesses</a>
                        <List
                            itemLayout="horizontal"
                            dataSource={flowView.ContentItems}
                            renderItem={item => (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={<Switch size='small' key={flowView.OnboardingStep} defaultChecked={flowView.DefaultUserInput || false} checked={industryConsentChecked} onChange={(checked, event) => { this.saveUserInput(flowView.FieldName, checked) }} />}
                                        title={item}
                                    />
                                </List.Item>
                            )}
                        />
                    </FlexColumn >
                )
                break
            case "PersonalInfo.Complete":
                flowView.TitleToDisplay = `${this.props.store.personalInfo.firstName || ""}${flowView.Title}`
                break

            // BusinessInfo
            case "BusinessInfo.IncorpType":
                var { businessDocuments, incorpTypeDocumentMap } = this.state

                var items = []
                incorpTypeShortArr().forEach(item => {
                    items.push(
                        <Option key={item} value={item}>
                            {item}
                        </Option>
                    )
                })

                if (businessInfo.incorporationType) {
                    incorpTypeDocumentMap[businessInfo.incorporationType].forEach(doc => {
                        if ((businessDocuments || []).find(item => item.name == doc.name)) doc.uploaded = true
                    })
                    // this.setState({ incorpTypeDocumentMap })
                }

                childViews = (
                    <FlexColumn>
                        <div style={cardStyle}>
                            <LabeledInput
                                // autoFocus
                                showAction={'focus'}
                                labelcolor={this.props.theme.colors.secondary3}
                                label="Incorporation Type"
                                type="select"
                                key={flowView.OnboardingStep}
                                size="large"
                                placeholder="Select an option"
                                optionFilterProp="children"
                                onChange={value => { this.saveUserInput("incorporationType", value) }}
                                value={businessInfo.incorporationType}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {items}
                            </LabeledInput>
                        </div>
                        {
                            businessInfo.incorporationType &&
                            <Text size='14px' style={{ marginBottom: 12 }} labelcolor={this.props.theme.colors.secondary3}>Please upload <b>ONE</b> of the following documents *</Text>
                        }
                        {
                            businessInfo.incorporationType &&
                            incorpTypeDocumentMap[businessInfo.incorporationType].map(item =>
                                <Flex style={{ marginBottom: 12 }} centerHorizontally between>
                                    <Text level={1} margin="0 8px 0 0">{item.name}</Text>
                                    {!item.uploaded ?
                                        <Upload {...this.uploadProps(item)}>
                                            <Button size="small" icon={<UploadOutlined />}>Upload</Button>
                                        </Upload> : <Tag tagpreset={theme.colors.secondary1}>Uploaded <CheckCircleFilled style={{ fontSize: `13px`, color: theme.colors.secondary1, marginLeft: "5px" }} /></Tag>}
                                </Flex>
                            )

                        }
                    </FlexColumn>
                )
                break

            case "BusinessInfo.OC":
                childViews = (
                    <FlexColumn>
                        <SearchBusiness
                            searchText={appState.businessSearchText}
                            searchResults={appState.businessSearchResults}
                            onSearchText={searchText => this.handleSearchText(searchText)}
                            onSearchResults={searchResults => this.handleSearchResults(searchResults)}
                            onSelectBusiness={company => this.handleSelectBusiness(company)}
                        />
                        <a style={{ alignSelf: "center", marginTop: "30px", fontSize: 16 }} onClick={() => this.handleCantFindBusiness()}>I can't find my business</a>
                    </FlexColumn>
                )
                break

            case "BusinessInfo.Edit":
                if (businessInfo.name) {
                    flowView.TitleToDisplay = `${businessInfo.name} ${flowView.Title}`
                    flowView.DescriptionToDisplay = `${personalInfo.firstName}${flowView.Description}${businessInfo.name}.`
                } else {
                    flowView.TitleToDisplay = "Add your business details."
                    flowView.DescriptionToDisplay = "Enter as it appears on your incorporation document. PO Boxes are not accepted."
                }
                var items = []
                Object.keys(STATES_MAP).forEach(item => {
                    items.push(
                        <Option key={item} value={item}>
                            {item}
                        </Option>
                    )
                })

                childViews = (
                    <div>
                        <div style={cardStyle}>
                            <LabeledInput
                                labelcolor={this.props.theme.colors.secondary3}
                                label="Business Name"
                                key={flowView.OnboardingStep + ".Name"}
                                // id="Name"
                                onChange={(e) => this.saveUserInput("Name", e.target.value)}
                                value={businessInfo.name}
                                placeholder="Apple Inc."
                                autocomplete="do-not-autofill"
                            />
                            <LabeledInput
                                labelcolor={this.props.theme.colors.secondary3}
                                label="Incorporation Date"
                                type="date-picker"
                                key={flowView.OnboardingStep + ".Date"}
                                id="Date"
                                onChange={this.handleDateChange}
                                format={dateFormatList}
                                value={businessInfo.incorporationDate && moment(businessInfo.incorporationDate)}
                                placeholder="Pick a date"
                                disabledDate={this.disabledIncorporationDate}
                            />
                            <LabeledInput
                                labelcolor={this.props.theme.colors.secondary3}
                                label="Incorporation State"
                                type="select"
                                key={flowView.OnboardingStep + ".State"}
                                id="State"
                                placeholder="Select a state"
                                optionFilterProp="children"
                                className="no-left-padding"
                                onChange={value => { this.saveUserInput("State", value) }}
                                value={businessInfo.incorporationState}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {items}
                            </LabeledInput>
                            <LabeledInput
                                labelcolor={this.props.theme.colors.secondary3}
                                label="Physical Business Address"
                                type="location"
                                key={flowView.OnboardingStep + ".BusinessAddr"}
                                getLocation={this.getLocation}
                                value={(businessInfo.physicalAddress)}
                                placeholder="Enter address"
                                country
                            />
                        </div>
                    </div>
                )
                break

            case "BusinessInfo.MailingAddress":
                flowView.TitleToDisplay = `${flowView.Title}${this.props.store.businessInfo.name || ""}`
                const mailingAddress = this.props.store.businessInfo.mailingAddress || {}
                childViews = (
                    <div style={cardStyle}>
                        <LabeledInput
                            labelcolor={this.props.theme.colors.secondary3}
                            label="Mailing Address"
                            type="location"
                            key={flowView.OnboardingStep + ".MailingAddr"}
                            value={`${mailingAddress.line1}, ${mailingAddress.city}, ${mailingAddress.countrySubDivisionCode} ${mailingAddress.postalCode}`}
                            address={mailingAddress}
                            getLocation={this.getLocation}
                            placeholder="Enter address"
                            country
                        />
                        {/* <LabeledInput
                            autoFocus
                            onKeyDown={this.handleOnKeyDown}
                            labelcolor={this.props.theme.colors.secondary3}
                            label="Suite/Floor #"
                            key={flowView.OnboardingStep+".MailingAddrLine2"}
                            id="Line2"
                            onChange={this.handleOnChange}
                            defaultValue={mailingAddress.line2 || ""}
                            placeholder="Suite 600"
                        /> */}
                    </div>
                )
                break
            case "BusinessInfo.Industry":
                flowView.TitleToDisplay = `${flowView.Title}${this.props.store.businessInfo.name || ""}`
                childViews = (
                    <div style={cardStyle}>
                        <NaicsField
                            onChange={value => { this.saveUserInput("industry", value) }}
                            value={businessInfo.industry || ""}
                        />
                    </div>
                )
                break
            case "BusinessInfo.AnnualRevenue":
                flowView.TitleToDisplay = `What is ${this.props.store.businessInfo.name || ""}'s${flowView.Title}`
                childViews = (
                    <div style={cardStyle}>
                        <LabeledInput
                            autoFocus
                            onKeyDown={this.handleOnKeyDown}
                            labelcolor={this.props.theme.colors.secondary3}
                            label="Annual Revenue"
                            symbolprefix="$"
                            key={flowView.OnboardingStep}
                            id="revenue"
                            placeholder="500,000.00"
                            onChange={e => { this.saveUserInput("revenue", toCurrency(e.target.value, 0, 0)) }}
                            value={parseFloat(businessInfo.revenue) > 0 ? businessInfo.revenue : ""}
                        />
                    </div>
                )
                break
            case "BusinessInfo.EmployeeCount": // not saved???
                flowView.TitleToDisplay = `${flowView.Title}${this.props.store.businessInfo.name || ""} have?`
                childViews = (
                    <div style={cardStyle}>
                        <LabeledInput
                            autoFocus
                            onKeyDown={this.handleOnKeyDown}
                            labelcolor={this.props.theme.colors.secondary3}
                            label="Employee Count"
                            key={flowView.OnboardingStep}
                            id="employeeCount"
                            onChange={this.handleOnChange}
                            value={businessInfo.employeeCount}
                            placeholder="10"
                        />
                    </div>
                )
                break
            case "BusinessInfo.Description":
                childViews = (
                    <FlexColumn start>
                        <div style={cardStyle2}>
                            <LabeledInput
                                autoFocus
                                onKeyDown={this.handleOnKeyDown}
                                type="text-area"
                                labelcolor={this.props.theme.colors.secondary3}
                                label="Brief description of business (minimum 60 characters)"
                                key={`${flowView.OnboardingStep}-Description`}
                                id="description"
                                onChange={this.handleOnChange}
                                value={businessInfo.description}
                                placeholder="We build marketing webites for tech companies." />
                            <p style={countStyle}>{businessInfo.description ? Math.max(0, 60 - businessInfo.description.length) : 60} characters remaining</p>
                        </div>
                        <div style={cardStyle2}>
                            <LabeledInput
                                autoFocus
                                onKeyDown={this.handleOnKeyDown}
                                labelcolor={this.props.theme.colors.secondary3}
                                label="Annual Revenue"
                                symbolprefix="$"
                                key={flowView.OnboardingStep}
                                id="revenue"
                                placeholder="500,000.00"
                                onChange={e => { this.saveUserInput("revenue", toCurrency(e.target.value, 0, 0)) }}
                                value={businessInfo.revenue && businessInfo.revenue}
                            />
                        </div>
                        <div style={cardStyle2}>
                            <LabeledInput
                                autoFocus
                                onKeyDown={this.handleOnKeyDown}
                                labelcolor={this.props.theme.colors.secondary3}
                                label="Employee Count"
                                key={flowView.OnboardingStep}
                                id="employeeCount"
                                onChange={this.handleOnChange}
                                value={businessInfo.employeeCount}
                                placeholder="10"
                            />
                        </div>
                    </FlexColumn>
                )
                break
            case "BusinessInfo.AccountActivity":
                childViews = (
                    <FlexColumn start>
                        <div style={cardStyle2}>
                            <Text size='14px' color={this.props.theme.colors.secondary3}>
                                Monthly Money In (Credits)
                            </Text>
                            <LabeledInput
                                autoFocus
                                onKeyDown={this.handleOnKeyDown}
                                type="text-area"
                                label="Provide a description of your incoming activity"
                                labelcolor={'#444444'}
                                key="moneyInDescription"
                                id="moneyInDescription"
                                onChange={this.handleOnChange}
                                value={businessInfo.moneyInDescription}
                                placeholder="E.g. Revenue from sales, retained earnings, credit etc." />
                            <Flex center>
                                <LabeledInput
                                    key="moneyInLow"
                                    id="moneyInLow"
                                    placeholder="Low"
                                    prefix="$"
                                    value={businessInfo.moneyInLow}
                                    onChange={e => { this.saveUserInput("moneyInLow", toCurrency(e.target.value, 0, 0)) }}
                                />
                                <Text size='14px' margin='0 10px'>to</Text>
                                <LabeledInput
                                    key="moneyInHigh"
                                    id="moneyInHigh"
                                    placeholder="High"
                                    prefix="$"
                                    value={businessInfo.moneyInHigh}
                                    onChange={e => { this.saveUserInput("moneyInHigh", toCurrency(e.target.value, 0, 0)) }}
                                />
                            </Flex>
                        </div>
                        <div style={cardStyle2}>
                            <Text size='14px' color={this.props.theme.colors.secondary3}>
                                Monthly Money Out (Debits)
                            </Text>
                            <LabeledInput
                                autoFocus
                                onKeyDown={this.handleOnKeyDown}
                                type="text-area"
                                label="Provide a description of your outgoing activity"
                                labelcolor={'#444444'}
                                key="moneyOutDescription"
                                id="moneyOutDescription"
                                onChange={this.handleOnChange}
                                value={businessInfo.moneyOutDescription}
                                placeholder="E.g. Business expenses, Bill payments, etc." />
                            <Flex center>
                                <LabeledInput
                                    key="moneyOutLow"
                                    id="moneyOutLow"
                                    placeholder="Low"
                                    prefix="$"
                                    value={businessInfo.moneyOutLow}
                                    onChange={e => { this.saveUserInput("moneyOutLow", toCurrency(e.target.value, 0, 0)) }}
                                />
                                <Text size='14px' margin='0 10px'>to</Text>
                                <LabeledInput
                                    key="moneyOutHigh"
                                    id="moneyOutHigh"
                                    placeholder="High"
                                    prefix="$"
                                    value={businessInfo.moneyOutHigh}
                                    onChange={e => { this.saveUserInput("moneyOutHigh", toCurrency(e.target.value, 0, 0)) }}
                                />
                            </Flex>
                        </div>
                    </FlexColumn>
                )
                break
            case "BusinessInfo.DBA":
                childViews = (
                    <div style={cardStyle}>
                        <LabeledInput
                            autoFocus
                            onKeyDown={this.handleOnKeyDown}
                            labelcolor={this.props.theme.colors.secondary3}
                            label="Doing Business As"
                            key={flowView.OnboardingStep}
                            id="DBA"
                            onChange={this.handleOnChange}
                            value={businessInfo.dba}
                            placeholder={businessInfo.name}
                        />
                        <List
                            itemLayout="horizontal"
                            dataSource={flowView.ContentItems}
                            renderItem={item => (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={<Switch size='small' key={flowView.OnboardingStep} checked={businessInfo.dbaChecked} onChange={(checked, event) => { this.saveUserInput("Toggle", checked) }} />}
                                        title={item}
                                    />
                                </List.Item>
                            )}
                        />
                    </div>
                )
                break
            case "BusinessInfo.EIN":
                // flowView.DescriptionToDisplay = `Enter the EIN for ${this.props.store.businessInfo.name}`
                childViews = (
                    <div>
                        <div style={{ margin: "80px 0" }}>
                            <UploadFiles
                                key={flowView.OnboardingStep}
                                customUploadURI={`${environment.apiBaseUrl}/user/file/upload`}
                                onUpload={(fileList) => {
                                    this.modifyFileList(fileList)
                                    this.props.dispatch(addDataToStore(BUSINESS_INFO, { einDocument: fileList[0], einFileList: fileList }))
                                }}
                                maxCount={1}
                                showDragger={true}
                                onPreview={this.handlePreview}
                                fileList={businessInfo.einFileList}
                            />
                        </div>

                        <div style={cardStyle}>
                            <LabeledInput
                                autoFocus
                                onKeyDown={this.handleOnKeyDown}
                                labelcolor={this.props.theme.colors.secondary3}
                                label="EIN Number"
                                key={flowView.OnboardingStep}
                                id="businessInfoEIN"
                                onChange={this.handleEINChange}
                                value={businessInfo.ein}
                                maxLength={10}
                                placeholder="22-0000000"
                            />
                        </div>
                    </div>
                )
                break
            case "BusinessInfo.OnlinePresence":
                childViews = (
                    <div style={cardStyle}>
                        <LabeledInput
                            autoFocus
                            onKeyDown={this.handleOnKeyDown}
                            labelcolor={this.props.theme.colors.secondary3}
                            label="Website / Social"
                            key={flowView.OnboardingStep}
                            id="Website"
                            symbolprefix="https://"
                            onChange={e => { this.saveUserInput("Website", "https://" + e.target.value) }}
                            value={((businessInfo.onlinePresence || [])[0] || "").replace("https://", "")}
                            placeholder="acme.com"
                        />
                    </div>
                )
                break
            case "BusinessInfo.Phone":
                flowView.TitleToDisplay = `What is ${this.props.store.businessInfo.name || ""}'s${flowView.Title}`
                childViews = (
                    <div style={cardStyle}>
                        <LabeledInput
                            autoFocus
                            onKeyDown={this.handleOnKeyDown}
                            labelcolor={this.props.theme.colors.secondary3}
                            label="Business Phone"
                            key={flowView.OnboardingStep}
                            id="businessPhone"
                            onChange={this.handlePhoneChange}
                            maxLength={12}
                            placeholder="634-555-7773"
                            value={businessInfo.phone}
                        />
                    </div>
                )
                break
            case "BusinessInfo.Status":
                flowView.TitleToDisplay = `${flowView.Title}${this.props.store.personalInfo.firstName || ""}`
                break
            case "BusinessInfo.OwnershipPercentage":
                childViews = (
                    <div style={cardStyle}>
                        <LabeledInput
                            autoFocus
                            onKeyDown={this.handleOnKeyDown}
                            labelcolor={this.props.theme.colors.secondary3}
                            label="Ownership Percentage"
                            key={flowView.OnboardingStep}
                            id="OwnershipPercentage"
                            symbolsuffix="%"
                            onChange={this.handleOnChange}
                            value={formatOwnershipPercentage(personalInfo.ownershipPercentage)}
                            maxLength={6}
                            placeholder="Enter Percentage"
                            suffixFontSzie="20px"
                        />
                    </div>
                )
                break
            case "OwnerInfo.Id":
                var items = []
                flowView.ContentItems.forEach(item => {
                    items.push(
                        <Option key={item} value={item}>
                            {item}
                        </Option>
                    )
                })

                childViews = (
                    <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 80 }}>
                        <div style={cardStyle}>
                            {
                                coOwnerInfo.countryOfCitizenship === 'US' &&
                                <LabeledInput
                                    labelcolor={this.props.theme.colors.secondary3}
                                    label="Document Type"
                                    type="select"
                                    key={flowView.OnboardingStep + ".DocumentType"}
                                    id="idType"
                                    placeholder="Select document type"
                                    className="no-left-padding"
                                    onChange={value => { this.saveUserInput("idType", value) }}
                                    value={coOwnerInfo.idType}
                                >
                                    {items}
                                </LabeledInput>
                            }
                            <LabeledInput
                                labelcolor={this.props.theme.colors.secondary3}
                                label={coOwnerInfo.countryOfCitizenship === 'US' ? "Document ID #" : "Passport Number"}
                                key={flowView.OnboardingStep + ".Number"}
                                id="idNumber"
                                onChange={this.handleOnChange}
                                value={coOwnerInfo.idNumber}
                                placeholder={coOwnerInfo.countryOfCitizenship === 'US' ? "Enter document id number" : "Enter passport number"}
                            />
                            <LabeledInput
                                labelcolor={this.props.theme.colors.secondary3}
                                label="Expiry Date"
                                type="date-picker"
                                key={flowView.OnboardingStep + ".ExpDate"}
                                id="idExpiryDate"
                                onChange={this.handleDateChange}
                                format={dateFormatList}
                                value={coOwnerInfo.idExpiryDate && moment(coOwnerInfo.idExpiryDate)}
                                placeholder="Pick a date"
                                disabledDate={(current) => current < moment().add(1, 'd').startOf('day')}
                            />
                            <LabeledInput
                                labelcolor={this.props.theme.colors.secondary3}
                                label={coOwnerInfo.countryOfCitizenship === 'US' ? "Country or State of Issuance" : "Country of Issuance"}
                                key={flowView.OnboardingStep + ".PlaceOfIssuance"}
                                id="idPlaceOfIssuance"
                                onChange={this.handleOnChange}
                                value={(coOwnerInfo.idPlaceOfIssuance)}
                                placeholder={coOwnerInfo.resident ? "Two letter code" : "US"}
                                maxLength={2}
                                instruction={!coOwnerInfo.resident ? "Enter 2 letter country code" : null}
                            />
                        </div>
                        <UploadFiles
                            key={flowView.OnboardingStep}
                            customUploadURI={`${environment.apiBaseUrl}/user/file/upload`}
                            onUpload={(fileList) => {
                                this.modifyFileList(fileList)
                                this.props.dispatch(addDataToStore(CO_OWNER_INFO, { idDocumentUrl: fileList[0], idFileList: fileList, coOwnerId: coOwnerInfo.id }))
                            }}
                            maxCount={1}
                            showDragger={true}
                            fileList={coOwnerInfo.idFileList}
                            onPreview={this.handlePreview}
                        />
                    </div>
                )
                break
            case "SubmitInfo.Submit":
                let acknowledgementChecked = personalInfo.acknowledgementChecked

                flowView.TitleToDisplay = `${flowView.Title}${this.props.store.personalInfo.firstName || ""}!`
                flowView.descComponent = (
                    <span>
                        Before submitting your application, please review the information below. This information is also available at <a target="_blank" href="https://aionfi.com/legal">aionfi.com/legal</a>.<br /><br />
                        <ul>
                            <li><a target="_blank" href="https://aionfi.com/legal">Banking Terms of Use</a></li>
                            <li><a target="_blank" href="https://www.aionfi.com/privacy">Privacy Policy</a></li>
                            <li><a target="_blank" href="http://www.aionfi.com/business-deposit-account-agreement">Business Deposit Account Agreement</a></li>
                        </ul>
                    </span>
                )
                // childViews = (
                //     <div style={{ margin: "40px 0 20px" }}>
                //         <Text>Acknowledgement and Certification</Text>
                //         <Checkbox checked={acknowledgementChecked} onChange={(e) => { this.saveUserInput('acknowledgement', e.target.checked) }}>I certify to the best of my knowledge, that the information provided regarding the business, ownership and controlling parties is complete and correct.</Checkbox>
                //     </div>
                // )
                break
            case "SubmitInfo.Submitted":
                flowView.TitleToDisplay = `${flowView.Title}${this.props.store.personalInfo.firstName || ""}.`
                break
            // OwnerInfo
            case "BusinessInfo.OwnerChecklist":
                flowView.TitleToDisplay = `${this.props.store.personalInfo.firstName || ""}${flowView.Title}`
                childViews = (
                    <div style={cardStyle}>
                        <List
                            size="small"
                            itemLayout="horizontal"
                            dataSource={flowView.ContentItems}
                            renderItem={item => (
                                <List.Item>
                                    <List.Item.Meta
                                        title={item}
                                    />
                                </List.Item>
                            )}
                        />
                    </div>
                )
                break
            case "OwnerInfo.OwnershipPercentage":
                childViews = (
                    <div style={cardStyle}>
                        <LabeledInput
                            autoFocus
                            onKeyDown={this.handleOnKeyDown}
                            labelcolor={this.props.theme.colors.secondary3}
                            label="Ownership Percentage"
                            key={flowView.OnboardingStep}
                            id="OwnershipPercentage"
                            symbolsuffix="%"
                            onChange={this.handleOnChange}
                            value={personalInfo.ownershipPercentage || ''}
                            maxLength={5}
                            placeholder="80"
                            suffixFontSzie="20px"
                        />
                    </div>
                )
                break
            case "OwnerInfo.AllOwners":
                childViews = (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {
                            coOwners.map((coOwner, i) => {
                                if (!coOwner.controllingParty) {
                                    return <div style={cardStyle2}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={{ width: '60%', paddingRight: 12 }}>
                                                <LabeledInput
                                                    autoFocus
                                                    onKeyDown={this.handleOnKeyDown}
                                                    labelcolor={this.props.theme.colors.secondary3}
                                                    label="Full Legal Name"
                                                    key={`coOwner${coOwner.id}Name`}
                                                    id="Name"
                                                    onChange={(e) => this.saveOwnerInfo(coOwner.id, e.target.id, e.target.value)}
                                                    value={`${coOwner.firstName || ''}${coOwner.spacer || ''}${coOwner.lastName || ''}`}
                                                    placeholder="Tony Stark"
                                                />
                                            </div>
                                            <div style={{ width: '30%', paddingLeft: 12 }}>
                                                <LabeledInput
                                                    autoFocus
                                                    onKeyDown={this.handleOnKeyDown}
                                                    labelcolor={this.props.theme.colors.secondary3}
                                                    label="% Ownership"
                                                    key={`coOwner${coOwner.id}OwnershipPercentage`}
                                                    id="ownershipPercentage"
                                                    //symbolsuffix="%"
                                                    onChange={(e) => this.saveOwnerInfo(coOwner.id, e.target.id, e.target.value)}
                                                    value={formatOwnershipPercentage(coOwner.ownershipPercentage)}
                                                    maxLength={6}
                                                    placeholder="Enter Percentage"
                                                    suffixFontSzie="20px"
                                                />
                                            </div>
                                            <div style={{ width: '10%', paddingLeft: 12 }}>
                                                {/* {
                                                (i > 0 && i === coOwners.length - 1) && */}
                                                <a style={{ alignSelf: "center", marginTop: 24 }} onClick={() => this.props.dispatch(addDataToStore(DELETE_CO_OWNER))}><DeleteOutlined style={{ fontSize: 16, color: this.props.theme.colors.secondary3 }} /></a>
                                                {/* } */}
                                            </div>
                                        </div>
                                    </div>
                                }
                            })
                        }

                        {
                            coOwners.length < 4 &&
                            <a style={{ fontSize: 18, alignSelf: "center", marginTop: 24 }} onClick={() => this.props.dispatch(addDataToStore(NEW_CO_OWNER))}>Add owner</a>
                        }
                    </div>
                )
                break
            case "OwnerInfo.Name":
                flowView.TitleToDisplay = `${this.props.store.personalInfo.firstName || ""}${flowView.Title}`
                childViews = (
                    <div style={cardStyle}>
                        <LabeledInput
                            autoFocus
                            onKeyDown={this.handleOnKeyDown}
                            labelcolor={this.props.theme.colors.secondary3}
                            label="Full Legal Name"
                            key={flowView.OnboardingStep + ".Name"}
                            id="Name"
                            onChange={this.handleOnChange}
                            value={`${coOwnerInfo.firstName || ''}${coOwnerInfo.spacer || ''}${coOwnerInfo.lastName || ''}`}
                            placeholder="Tony Stark"
                        />
                    </div>
                )
                break
            case "OwnerInfo.Edit":
                flowView.TitleToDisplay = `Fill in ${coOwnerInfo.controllingParty === true ? "your controlling party" : (coOwnerInfo.firstName || "")}'s details.`
                childViews = (
                    <div style={cardStyle}>
                        {
                            coOwnerInfo.controllingParty === true &&
                            <LabeledInput
                                autoFocus
                                labelcolor={this.props.theme.colors.secondary3}
                                label="Full Legal Name"
                                key={flowView.OnboardingStep + ".Name"}
                                id="Name"
                                onChange={this.handleOnChange}
                                value={`${coOwnerInfo.firstName || ''}${coOwnerInfo.spacer || ''}${coOwnerInfo.lastName || ''}`}
                                placeholder="Larry Lin"
                            />
                        }
                        <LabeledInput
                            labelcolor={this.props.theme.colors.secondary3}
                            label="Date of Birth"
                            defaultPickerValue={moment().subtract(18, "years")}
                            disabledDate={this.disabledDOBDate}
                            type="date-picker"
                            key={flowView.OnboardingStep + (coOwnerInfo.firstName || "") + ".DOB"}
                            id="Date"
                            onChange={this.handleDateChange}
                            format={dateFormatList}
                            value={coOwnerInfo.dob && moment(coOwnerInfo.dob)}
                            placeholder="Pick a date"
                        />
                        <LabeledInput
                            labelcolor={this.props.theme.colors.secondary3}
                            label="Home address"
                            type="location"
                            key={flowView.OnboardingStep + (coOwnerInfo.firstName || "") + ".HomeAddr"}
                            getLocation={this.getLocation}
                            value={addressObjectToStr(coOwnerInfo.address)}
                            placeholder="Enter address"
                            country
                        />
                        {/* <LabeledInput
                                labelcolor={this.props.theme.colors.secondary3}
                                label="Apartment/Suite #"
                                key={flowView.OnboardingStep+(coOwnerInfo.firstName || "")+".Apartment"}
                                id="Line2"
                                onChange={this.handleOnChange}
                                value={coOwnerInfo.address && coOwnerInfo.address.line2}
                                placeholder="Apt 200"
                            /> */}
                    </div>
                )
                break
            case "OwnerInfo.Email":
                flowView.TitleToDisplay = `What is ${coOwnerInfo.firstName || ""}'s${flowView.Title}`
                childViews = (
                    <div style={cardStyle}>
                        <LabeledInput
                            autoFocus
                            onKeyDown={this.handleOnKeyDown}
                            labelcolor={this.props.theme.colors.secondary3}
                            label="Business Email"
                            type="email"
                            key={flowView.OnboardingStep + (coOwnerInfo.firstName || "") + ".Name"}
                            id="Email"
                            onChange={this.handleOnChange}
                            value={coOwnerInfo.email}
                            placeholder="tim@apple.com"
                        />
                    </div>
                )
                break
            case "OwnerInfo.Phone":
                flowView.TitleToDisplay = `What is ${coOwnerInfo.firstName || ""}'s${flowView.Title}`
                childViews = (
                    <div style={cardStyle}>
                        <LabeledInput
                            autoFocus
                            onKeyDown={this.handleOnKeyDown}
                            labelcolor={this.props.theme.colors.secondary3}
                            label="Phone Number"
                            key={flowView.OnboardingStep + (coOwnerInfo.firstName || "")}
                            id="ownerPhone"
                            onChange={this.handlePhoneChange}
                            maxLength={12}
                            placeholder="444-555-6666"
                            value={coOwnerInfo.phone}
                        />
                    </div>
                )
                break
            case "OwnerInfo.Citizenship":
                flowView.TitleToDisplay = `What is ${coOwnerInfo.firstName || ""}'s ${flowView.Title}`
                var items = []
                flowView.ContentItems.forEach(item => {
                    items.push(<Radio.Button style={{ width: "150px", textAlign: "center" }} key={item} value={item}>{item}</Radio.Button>)
                })
                childViews = (
                    <div style={cardStyle}>
                        <LabeledInput
                            labelcolor={this.props.theme.colors.secondary3}
                            label="Citizenship"
                            type="select"
                            key={flowView.OnboardingStep}
                            id="Citizenship"
                            placeholder="Select country of citizenship"
                            className="no-left-padding"
                            optionFilterProp="children"
                            onChange={value => { this.saveUserInput("countryOfCitizenship", value) }}
                            value={coOwnerInfo.countryOfCitizenship}                            
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().startsWith(input.toLowerCase())
                            }
                        >
                            {countryItems}
                        </LabeledInput>
                    </div>
                )
                break
            case "OwnerInfo.Resident":
                flowView.TitleToDisplay = `Does ${coOwnerInfo.firstName || ""}${flowView.Title}`
                var items = []
                flowView.ContentItems.forEach(item => {
                    items.push(<Radio.Button style={{ width: "150px", textAlign: "center" }} key={item} value={item}>{item}</Radio.Button>)
                })
                childViews = (
                    <FlexColumn center>
                        <Radio.Group key={flowView.OnboardingStep} id="resident" onChange={this.handleOnChange} defaultValue={flowView.DefaultUserInput}
                            value={coOwnerInfo.resident === false ? "No" : "Yes"} buttonStyle="solid" size="large">
                            {items}
                        </Radio.Group>
                    </FlexColumn>
                )
                break
            case "OwnerInfo.SSN":
                flowView.TitleToDisplay = `What is ${coOwnerInfo.firstName || ""}'s ${flowView.Title}`
                childViews = (
                    <div style={cardStyle}>
                        <LabeledInput
                            autoFocus
                            onKeyDown={this.handleOnKeyDown}
                            labelcolor={this.props.theme.colors.secondary3}
                            label="Social Security Number"
                            id="ownerSSN"
                            onChange={this.handleSSNChange}
                            maxLength={11}
                            placeholder="123-44-5555"
                            value={coOwnerInfo.ssn}
                            optional={this.getCurrentCoOwner().resident == false}
                        />
                    </div>
                )
                break
            case "OwnerInfo.OwnershipPercentage":
                flowView.TitleToDisplay = `${flowView.Title}${coOwnerInfo.firstName || ""} own?`
                childViews = (
                    <div style={cardStyle}>
                        <LabeledInput
                            autoFocus
                            onKeyDown={this.handleOnKeyDown}
                            labelcolor={this.props.theme.colors.secondary3}
                            label="Ownership Percentage"
                            key={flowView.OnboardingStep}
                            id="OwnershipPercentage"
                            symbolsuffix="%"
                            onChange={this.handleOnChange}
                            value={formatOwnershipPercentage(coOwnerInfo.ownershipPercentage)}
                            maxLength={6}
                            placeholder="Enter Percentage"
                            suffixFontSzie="20px"
                        />
                    </div>
                )
                break

            // for submitMultiOwnerApplicationFlowViewsData() (non invoice_financing flow)
            case "OwnerInfo.ControlPerson":
                // const coOwners = this.props.store.coOwnersInfo || []
                var persons = []

                persons.push({
                    name: `${this.props.store.personalInfo.firstName} ${this.props.store.personalInfo.lastName}`,
                    businessTitle: this.props.store.personalInfo.title,
                })

                coOwners.forEach(owner => {
                    persons.push({
                        name: `${owner.firstName} ${owner.lastName}`,
                        businessTitle: owner.businessTitle === 'Other' ? owner.businessTitleOther : owner.businessTitle,
                    })
                })

                var personItems = []
                persons.forEach((item, index) => {
                    personItems.push(
                        <Option key={index} value={index}>
                            {item.name}
                        </Option>
                    )
                })

                const setControlPerson = (value) => {
                    this.props.dispatch(addDataToStore(SET_CONTROL_PERSON, { key: value }))
                }

                const getControlPersonIndex = () => {
                    let cpIndex = 0
                    if (!personalInfo.controlPerson) {
                        coOwners.forEach((owner, index) => {
                            if (owner.controlPerson)
                                cpIndex = index + 1
                        })
                    }
                    return cpIndex
                }

                childViews = (
                    // <FlexColumn>
                    <Radio.Group onChange={(e) => setControlPerson(e.target.value)} value={getControlPersonIndex()}>
                        <FlexColumn style={{ width: '100%', marginBottom: 40 }}>
                            {
                                persons.map((item, index) => {
                                    return <div style={cardStyle2}>
                                        <Flex centerHorizontally between>
                                            <FlexColumn start>
                                                <p style={hintStyle}>{item.businessTitle === 'Other' ? item.businessTitleOther : item.businessTitle}</p>
                                                <Text bold noMargin level={2}>{item.name}</Text>
                                            </FlexColumn>
                                            <Radio value={index} />
                                        </Flex>
                                    </div>
                                })
                            }
                            {
                                (!this.props.store.coOwnersInfo.find(o => o.controllingParty === true)) &&
                                <div style={cardStyle2}>
                                    <p style={hintStyle}>Add controlling party (if they are not listed above)</p>
                                    <Button onClick={() => {
                                        this.props.dispatch(addDataToStore(ADD_CONTROL_PERSON))
                                        this.addControlPerson()
                                    }}>Add Controlling Party</Button>
                                </div>
                            }
                        </FlexColumn>
                    </Radio.Group>
                    // </FlexColumn>
                )
                break
            case "SubmitInfo.AddOwner":
                // flowView.TitleToDisplay = `Alright, ${coOwnerInfo.firstName || ""}'s${flowView.Title}`
                var items = []
                const coOwnersInfo = this.props.store.coOwnersInfo || []
                var cardStyleCopy = Object.assign({}, cardStyle)
                cardStyleCopy.padding = "20px 20px"
                cardStyleCopy.margin = "10px 0"
                coOwnersInfo.forEach(owner => {
                    items.push(
                        <div style={cardStyleCopy}>
                            <p style={hintStyle}>{owner.businessTitle}</p>
                            <h3 style={{ margin: "0" }}>{owner.firstName} {owner.lastName} <Button size='small' style={{ marginLeft: '5px' }} onClick={() => this.editCoOwner(owner.id)}>Edit</Button></h3>
                        </div>
                    )
                })
                items.push(
                    <div style={cardStyleCopy}>
                        <p style={hintStyle}>Add More</p>
                        <Button onClick={this.addOwner}>Add Owner</Button>
                    </div>
                )
                childViews = (
                    <div>
                        {items}
                    </div>
                )
                break
            case "OwnerInfo.AddOwner":
                // flowView.TitleToDisplay = `Alright, ${coOwnerInfo.firstName || ""}'s${flowView.Title}`
                var items = []
                const ownersInfo = this.props.store.coOwnersInfo || []
                var cardStyleCopy = Object.assign({}, cardStyle)
                cardStyleCopy.padding = "20px 20px"
                cardStyleCopy.margin = "10px 0"
                ownersInfo.forEach(owner => {
                    items.push(
                        <div style={cardStyleCopy}>
                            <p style={hintStyle}>{owner.businessTitle}</p>
                            <h3 style={{ margin: "0" }}>{owner.firstName} {owner.lastName} <Button size='small' style={{ marginLeft: '5px' }} onClick={() => this.editCoOwner(owner.id)}>Edit</Button></h3>
                        </div>
                    )
                })
                items.push(
                    <div style={cardStyleCopy}>
                        <p style={hintStyle}>Add More</p>
                        <Button onClick={this.addOwner}>Add Owner</Button>
                    </div>
                )
                childViews = (
                    <div>
                        {items}
                    </div>
                )
                break
            case "Signers.AddSigner":
                var items = []
                flowView.ContentItems.forEach(item => {
                    items.push(<Radio.Button style={{ width: "150px", textAlign: "center" }} id="additionalSigners" key={item} value={item}>{item}</Radio.Button>)
                })
                childViews = (
                    <FlexColumn center>
                        <Radio.Group key={flowView.OnboardingStep} id="additionalSigners" onChange={this.handleOnChange} defaultValue={flowView.DefaultUserInput}
                            value={this.props.store.additionalSigners} buttonStyle="solid" size="large">
                            {items}
                        </Radio.Group>
                    </FlexColumn>
                )
                break
            case "Signers.AuthorizedSigner":
                var items = []
                flowView.ContentItems.forEach(item => {
                    items.push(<Radio.Button style={{ width: "150px", textAlign: "center" }} id="authorizedSigner" key={item} value={item}>{item}</Radio.Button>)
                })
                childViews = (
                    <FlexColumn center>
                        <Radio.Group key={flowView.OnboardingStep} id="authorizedSigner" onChange={this.handleOnChange} defaultValue={flowView.DefaultUserInput}
                            value={this.props.store.personalInfo.authorizedSigner ? "Yes" : "No"} buttonStyle="solid" size="large">
                            {items}
                        </Radio.Group>
                    </FlexColumn>
                )
                break
            case "Signers.AllSigners":
                var items = []
                flowView.ContentItems.forEach(item => {
                    items.push(<Radio.Button key={item} value={item}>{item}</Radio.Button>)
                })

                childViews = (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {
                            signers.map((signer, i) => {
                                return <div style={cardStyle2}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                        <div style={{ display: 'flex', alignItems: 'start', flexDirection: 'column', width: '100%' }}>
                                            <div style={{ width: '100%' }}>
                                                <LabeledInput
                                                    autoFocus
                                                    onKeyDown={this.handleOnKeyDown}
                                                    labelcolor={this.props.theme.colors.secondary3}
                                                    label="Full Legal Name"
                                                    key={`signer${signer.id}Name`}
                                                    id="Name"
                                                    onChange={(e) => this.saveSignerInfo(signer.id, e.target.id, e.target.value)}
                                                    value={`${signer.firstName || ''}${signer.spacer || ''}${signer.lastName || ''}`}
                                                    placeholder="Tony Stark"
                                                />
                                            </div>

                                            <div style={{ width: '100%', marginBottom: 12, marginTop: 8 }}>
                                                <Text size='14px' color={this.props.theme.colors.secondary3}>Title</Text>
                                            </div>

                                            <Radio.Group key={flowView.OnboardingStep} id="businessTitle" name="businessTitle" onChange={(e) => this.saveSignerInfo(signer.id, 'businessTitle', e.target.value)} defaultValue={flowView.DefaultUserInput} value={signer.businessTitle} buttonStyle="solid" size="large">
                                                {items}
                                            </Radio.Group>

                                            {
                                                flowView.OtherInput && signer.businessTitle === "Other" &&
                                                <div style={{ width: 320 }}>
                                                    <LabeledInput
                                                        labelcolor={this.props.theme.colors.secondary3}
                                                        key={flowView.OnboardingStep + ".BusinessTitleOther"}
                                                        id="businessTitleOther"
                                                        name="businessTitleOther"
                                                        onChange={(e) => this.saveSignerInfo(signer.id, 'businessTitleOther', e.target.value)}
                                                        value={signer.businessTitleOther}
                                                        placeholder="Add title"
                                                        onKeyDown={this.handleOnKeyDown}
                                                    />
                                                </div>
                                            }
                                        </div>

                                        <div>
                                            <a style={{ alignSelf: "center", marginTop: 24, marginLeft: 12 }} onClick={() => this.props.dispatch(addDataToStore(DELETE_SIGNER))}>
                                                <DeleteOutlined style={{ fontSize: 16, color: this.props.theme.colors.secondary3 }} />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            })
                        }

                        <a style={{ alignSelf: "center", marginTop: 24 }} onClick={() => this.props.dispatch(addDataToStore(NEW_SIGNER))}>Add another signer</a>
                    </div>
                )
                break
            case "Signers.Edit":
                flowView.TitleToDisplay = `Fill in ${signer.firstName || ""}'s details.`
                childViews = (
                    <div style={cardStyle}>
                        <LabeledInput
                            labelcolor={this.props.theme.colors.secondary3}
                            label="Date of Birth"
                            defaultPickerValue={moment().subtract(18, "years")}
                            disabledDate={this.disabledDOBDate}
                            type="date-picker"
                            key={flowView.OnboardingStep + (signer.firstName || "") + ".DOB"}
                            id="Date"
                            onChange={this.handleDateChange}
                            format={dateFormatList}
                            value={signer.dob && moment(signer.dob)}
                            placeholder="Pick a date"
                        />
                        <LabeledInput
                            labelcolor={this.props.theme.colors.secondary3}
                            label="Home address"
                            type="location"
                            key={flowView.OnboardingStep + (signer.firstName || "") + ".HomeAddr"}
                            getLocation={this.getLocation}
                            value={addressObjectToStr(signer.address)}
                            placeholder="Enter address"
                            country
                        />
                    </div>
                )
                break
            case "Signers.Email":
                flowView.TitleToDisplay = `What is ${signer.firstName || ""}'s${flowView.Title}`
                childViews = (
                    <div style={cardStyle}>
                        <LabeledInput
                            autoFocus
                            onKeyDown={this.handleOnKeyDown}
                            labelcolor={this.props.theme.colors.secondary3}
                            label="Business Email"
                            type="email"
                            key={flowView.OnboardingStep + (signer.firstName || "") + ".Name"}
                            id="Email"
                            onChange={this.handleOnChange}
                            value={signer.email}
                            placeholder="tim@apple.com"
                        />
                    </div>
                )
                break
            case "Signers.Citizenship":
                flowView.TitleToDisplay = `What is ${signer.firstName || ""}'s ${flowView.Title}`
                var items = []
                flowView.ContentItems.forEach(item => {
                    items.push(<Radio.Button style={{ width: "150px", textAlign: "center" }} key={item} value={item}>{item}</Radio.Button>)
                })
                childViews = (
                    <div style={cardStyle}>
                        <LabeledInput
                            labelcolor={this.props.theme.colors.secondary3}
                            label="Citizenship"
                            type="select"
                            key={flowView.OnboardingStep}
                            id="Citizenship"
                            placeholder="Select country of citizenship"
                            className="no-left-padding"
                            optionFilterProp="children"
                            onChange={value => { this.saveUserInput("countryOfCitizenship", value) }}
                            value={signer.countryOfCitizenship}
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().startsWith(input.toLowerCase())
                            }
                        >
                            {countryItems}
                        </LabeledInput>
                    </div>
                )
                break
            case "Signers.Resident":
                flowView.TitleToDisplay = `Does ${signer.firstName || ""}${flowView.Title}`
                var items = []
                flowView.ContentItems.forEach(item => {
                    items.push(<Radio.Button style={{ width: "150px", textAlign: "center" }} key={item} value={item}>{item}</Radio.Button>)
                })
                childViews = (
                    <FlexColumn center>
                        <Radio.Group key={flowView.OnboardingStep} id="resident" onChange={this.handleOnChange} defaultValue={flowView.DefaultUserInput}
                            value={signer.resident === false ? "No" : "Yes"} buttonStyle="solid" size="large">
                            {items}
                        </Radio.Group>
                    </FlexColumn>
                )
                break
            case "Signers.SSN":
                flowView.TitleToDisplay = `What is ${signer.firstName || ""}'s ${flowView.Title}`
                childViews = (
                    <div style={cardStyle}>
                        <LabeledInput
                            autoFocus
                            onKeyDown={this.handleOnKeyDown}
                            labelcolor={this.props.theme.colors.secondary3}
                            label="Social Security Number"
                            id="ownerSSN"
                            onChange={this.handleSSNChange}
                            maxLength={11}
                            placeholder="123-44-5555"
                            value={signer.ssn}
                            /* optional={this.getCurrentSigner().resident == false} */
                        />
                    </div>
                )
                break
            case "Signers.Phone":
                flowView.TitleToDisplay = `What is ${signer.firstName || ""}'s${flowView.Title}`
                childViews = (
                    <div style={cardStyle}>
                        <LabeledInput
                            autoFocus
                            onKeyDown={this.handleOnKeyDown}
                            labelcolor={this.props.theme.colors.secondary3}
                            label="Phone Number"
                            key={flowView.OnboardingStep + (signer.firstName || "")}
                            id="ownerPhone"
                            onChange={this.handlePhoneChange}
                            maxLength={12}
                            placeholder="444-555-6666"
                            value={signer.phone}
                        />
                    </div>
                )
                break
            case "Signers.Id":
                flowView.TitleToDisplay = `${flowView.Title} for ${signer.firstName || ""}`
                var items = []
                flowView.ContentItems.forEach(item => {
                    items.push(
                        <Option key={item} value={item}>
                            {item}
                        </Option>
                    )
                })

                childViews = (
                    <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 80 }}>
                        <div style={cardStyle}>
                            {
                                signer.countryOfCitizenship === 'US' &&
                                <LabeledInput
                                    labelcolor={this.props.theme.colors.secondary3}
                                    label="Document Type"
                                    type="select"
                                    key={flowView.OnboardingStep + ".DocumentType"}
                                    id="idType"
                                    placeholder="Select document type"
                                    className="no-left-padding"
                                    onChange={value => { this.saveUserInput("idType", value) }}
                                    value={signer.idType}
                                >
                                    {items}
                                </LabeledInput>
                            }
                            <LabeledInput
                                labelcolor={this.props.theme.colors.secondary3}
                                label={signer.countryOfCitizenship === 'US' ? "Document ID #" : "Passport Number"}
                                key={flowView.OnboardingStep + ".Number"}
                                id="idNumber"
                                onChange={this.handleOnChange}
                                value={signer.idNumber}
                                placeholder={signer.countryOfCitizenship === 'US' ? "Enter document id number" : "Enter passport number"}
                            />
                            <LabeledInput
                                labelcolor={this.props.theme.colors.secondary3}
                                label="Expiry Date"
                                type="date-picker"
                                key={flowView.OnboardingStep + ".ExpDate"}
                                id="idExpiryDate"
                                onChange={this.handleDateChange}
                                format={dateFormatList}
                                value={signer.idExpiryDate && moment(signer.idExpiryDate)}
                                placeholder="Pick a date"
                                disabledDate={(current) => current < moment().add(1, 'd').startOf('day')}
                            />
                            <LabeledInput
                                labelcolor={this.props.theme.colors.secondary3}
                                label={signer.countryOfCitizenship === 'US' ? "Country or State of Issuance" : "Country of Issuance"}
                                key={flowView.OnboardingStep + ".PlaceOfIssuance"}
                                id="idPlaceOfIssuance"
                                onChange={this.handleOnChange}
                                value={(signer.idPlaceOfIssuance)}
                                placeholder={signer.resident ? "Two letter code" : "US"}
                                maxLength={2}
                                instruction={!signer.resident ? "Enter 2 letter country code" : null}
                            />
                        </div>
                        <UploadFiles
                            key={flowView.OnboardingStep}
                            customUploadURI={`${environment.apiBaseUrl}/user/file/upload`}
                            onUpload={(fileList) => {
                                this.modifyFileList(fileList)
                                this.props.dispatch(addDataToStore(SIGNER_INFO, { idDocumentUrl: fileList[0], idFileList: fileList, signerId: this.props.store.signerId }))
                            }}
                            maxCount={1}
                            showDragger={true}
                            onPreview={this.handlePreview}
                            fileList={signer.idFileList}
                        />
                    </div>
                )
                break
            // PPP
            case "PPP.MonthlyPayroll":
                childViews = (
                    <div style={cardStyle}>
                        <p style={hintStyle}>Average Monthly Payroll</p>
                        <InputNumber
                            key={flowView.OnboardingStep}
                            id="MonthlyPayroll"
                            step="10000"
                            style={{ width: '50%' }}
                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            onChange={val => { this.saveUserInput("Currency", val) }}
                            placeholder="$100,000" />
                    </div>
                )
                break
            case "PPP.Employees":
                childViews = (
                    <div style={cardStyle}>
                        <p style={hintStyle}>Employee #</p>
                        <Input
                            key={flowView.OnboardingStep}
                            id="Employees"
                            onChange={this.handleOnChange}
                            placeholder="10" />
                    </div>
                )
                break
            case "PPP.LoanRequest":
                childViews = (
                    <div style={cardStyle}>
                        <p style={hintStyle}>Loan Amount</p>
                        <InputNumber
                            key={flowView.OnboardingStep}
                            id="LoanRequest"
                            step="10000"
                            style={{ width: '50%' }}
                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            onChange={val => { this.saveUserInput("Currency", val) }}
                            placeholder="$50,000" />
                    </div>
                )
                break
            case "PPP.LoanPurpose":
                var items = []
                flowView.ContentItems.forEach(item => {
                    items.push(
                        <Option key={item} value={item}>
                            {item}
                        </Option>
                    )
                })
                childViews = (
                    <div>
                        <Select
                            key={flowView.OnboardingStep}
                            showSearch
                            style={{ width: "100%" }}
                            mode="multiple"
                            placeholder="Select all that apply"
                            optionFilterProp="children"
                            onChange={value => {
                                if (value.includes("Other")) {
                                    this.setState({ explainOther: true })
                                } else {
                                    this.setState({ explainOther: false })
                                }
                                this.saveUserInput("Dropdown", value)
                            }}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {items}
                        </Select>
                        {this.state.explainOther ?
                            <div style={cardStyle}>
                                <p style={hintStyle}>Please explain "Other"</p>
                                <TextArea
                                    key={flowView.OnboardingStep}
                                    id="Other"
                                    style={{ width: '80%', textTransform: 'capitalize' }}
                                    onChange={event => { this.saveUserInput("Other", event.target.value) }}
                                    placeholder="Enter here" />
                            </div>
                            : null}
                    </div>
                )
                break
            case "PPP.Questions":
                var items = []
                flowView.ContentItems.forEach(item => {
                    items.push(
                        <div key={item.Id} style={cardStyle}>
                            <p>{item.Question}</p>
                            <Radio.Group onChange={event => {
                                var components = event.target.value.split('-')
                                if (components.slice(-1)[0] == "yes") {
                                    this.setState({ [`${event.target.value}`]: true, [`${components[0]}-no`]: false })
                                    item.Response = true
                                } else {
                                    this.setState({ [`${event.target.value}`]: true, [`${components[0]}-yes`]: false })
                                    item.Response = false
                                }
                                this.saveUserInput(item.Id, item)
                            }}>
                                <Radio value={`${item.Id}-yes`}>
                                    <b>Yes</b>
                                </Radio>
                                <Radio value={`${item.Id}-no`}>
                                    <b>No</b>
                                </Radio>
                            </Radio.Group>
                            {(this.state[`${item.Id}-yes`] && !this.state[`${item.Id}-no`] && item.YesExplanation)
                                ?
                                <TextArea key={item.Question} style={{ marginTop: '10px', width: '80%' }} placeholder={item.Placeholder} onChange={event => {
                                    item.Explanation = event.target.value
                                    this.saveUserInput(item.Id, item)
                                }} />
                                : null}
                        </div>
                    )
                })
                childViews = (
                    items
                )
                break

            // InvoiceFinancing
            case "InvoiceFinancing.DebtSchedule":
                continueLink = this.state.debtSchedule.length > 0 ? undefined : 'I do not have any debt'
                altButtonTitle = this.state.debtSchedule.length > 0 ? 'Continue' : 'Add Debt'

                altButtonClick = () => {
                    const onOK = () => {
                        this.loadNextView()
                    }

                    confirm({
                        title: 'Are you sure you do not have any outstanding debt?',
                        icon: <ExclamationCircleOutlined />,
                        okText: 'Yes',
                        onOk() {
                            onOK()
                        },
                        onCancel() { },
                    })
                }

                const layout = {
                    labelCol: {
                        span: 9,
                    },
                    wrapperCol: {
                        span: 14,
                    }
                }

                const validateMessages = {
                    required: '${label} is a required field',
                    types: {
                        number: '${label} is not a valid number',
                    },
                    number: {
                        range: '${label} must be between ${min} and ${max}',
                    }
                }

                const onFinish = (values) => {
                    altButtonTitle = "Continue"

                    console.log('debtSchedule: ', values)

                    const newData = [...this.state.debtSchedule]

                    newData.push(values)

                    this.setState({ showAddLoanModal: false, debtSchedule: newData })

                    this.props.dispatch(addDataToStore(DEBT_SCHEDULE, { debtSchedule: newData }))

                    this.formRef.current.resetFields()
                }

                childViews = (
                    <div style={tableStyle}>
                        <ConfigProvider renderEmpty={this.renderEmptyDebtSchedule}>
                            {
                                (this.state.debtSchedule.length > 0) &&
                                <div>
                                    <Button
                                        onClick={() => this.setState({ showAddLoanModal: true })}
                                        type="primary"
                                        style={{
                                            marginBottom: 16,
                                        }}>
                                        Add Debt
                                    </Button>
                                    <Table
                                        bordered={true}
                                        tableLayout='auto'
                                        columns={debtScheduleColumns}
                                        dataSource={this.state.debtSchedule}
                                        size="small"
                                        scroll={{ y: '700px', x: '100%' }}
                                    />
                                </div>
                            }
                            <Modal
                                visible={this.state.showAddLoanModal}
                                title="Add Debt"
                                okText="Submit"
                                width={850}
                                style={{ top: 20 }}
                                onCancel={() => this.setState({ showAddLoanModal: false })}
                                confirmLoading={this.state.confirmLoading}
                                footer={null}
                                closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                            >
                                <Form ref={this.formRef} {...layout} name="nest-messages" onFinish={onFinish} validateMessages={validateMessages}>
                                    <Form.Item
                                        name="loanCompany"
                                        label="Loan Company"
                                        rules={[{ required: true }]}
                                    >
                                        <Input placeholder="American Express" />
                                    </Form.Item>
                                    <Form.Item
                                        name="startDate"
                                        label="Start Date"
                                        rules={[{ required: true }]}
                                    >
                                        <DatePicker format={dateFormat} />
                                    </Form.Item>
                                    <Form.Item
                                        name="originalBalance"
                                        label="Original Balance"
                                        rules={[{ required: true }]}
                                    >
                                        <InputNumber
                                            style={{ width: '50%' }}
                                            addonBefore="$"
                                            step="1000"
                                            placeholder="$50,000"
                                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                                    </Form.Item>
                                    <Form.Item name="currentBalance" label="Current Balance" rules={[{ required: true }]}>
                                        <InputNumber
                                            style={{ width: '50%' }}
                                            step="1000"
                                            placeholder="$50,000"
                                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                                    </Form.Item>
                                    <Form.Item name="originalTerm" label="Original Term (months)" rules={[{ required: true }]}>
                                        <InputNumber
                                            style={{ width: '50%' }}
                                            step="1"
                                            placeholder="36"
                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                                    </Form.Item>
                                    <Form.Item name="monthlyPayment" label="Monthly Payment" rules={[{ required: true }]}>
                                        <InputNumber
                                            style={{ width: '50%' }}
                                            step="1000"
                                            placeholder="$5,000"
                                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                                    </Form.Item>
                                    <Form.Item name="paymentDueDate" label="Payment Due On" rules={[{ required: true }]}>
                                        <Input
                                            style={{ width: '50%' }}
                                            placeholder="2nd day of every month" />
                                    </Form.Item>
                                    <Form.Item name="maturityDate" label="Maturity Date" rules={[{ required: true }]}>
                                        <DatePicker format={dateFormat} />
                                    </Form.Item>
                                    <Form.Item name="interestRate" label="Interest Rate" rules={[{ required: true }]}>
                                        <InputNumber
                                            placeholder="4.5" />
                                    </Form.Item>
                                    <Form.Item name="note" label="Note">
                                        <Input.TextArea />
                                    </Form.Item>
                                    <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 9 }}>
                                        <Button style={{ marginTop: '10pt' }} type="primary" htmlType="submit">
                                            Submit
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Modal>
                        </ConfigProvider>
                    </div>
                )
                break

            case "InvoiceFinancing.TaxReturns":
                var year = new Date().getFullYear()
                childViews = (
                    <div>
                        <div style={cardStyle}>
                            <UploadDoc name={year} key="taxReturn1" docType="taxReturns" checkNoDoc businessUniqueKey={this.state.businessUniqueKey} />
                            <Divider />
                            <UploadDoc name={year - 1} key="taxReturn2" docType="taxReturns" checkNoDoc businessUniqueKey={this.state.businessUniqueKey} />
                            <Divider />
                            <UploadDoc name={year - 2} key="taxReturn3" docType="taxReturns" checkNoDoc businessUniqueKey={this.state.businessUniqueKey} />
                        </div>
                        <div className="flex-container justify-content-center">
                            {this.state.showError ? <Alert
                                style={{ width: '100%', marginTop: '-10pt', marginBottom: '20pt' }}
                                message="Required documents"
                                description={this.state.errorMsg}
                                type="error"
                                showIcon
                            /> : null}
                        </div>
                    </div>
                )
                break

            case "InvoiceFinancing.ProfitAndLoss":
                var year = new Date().getFullYear()
                childViews = (
                    <div>
                        <div style={cardStyle}>
                            <UploadDoc name={year} key="pnl1" docType="pnl" checkNoDoc businessUniqueKey={this.state.businessUniqueKey} />
                            <Divider />
                            <UploadDoc name={year - 1} key="pnl2" docType="pnl" checkNoDoc businessUniqueKey={this.state.businessUniqueKey} />
                            {/* <Divider />
                            <UploadDoc name={year - 2} key="pnl3" docType="pnl" checkNoDoc businessUniqueKey={this.state.businessUniqueKey} /> */}
                        </div>
                        <div className="flex-container justify-content-center">
                            {this.state.showError ? <Alert
                                style={{ width: '100%', marginTop: '-10pt', marginBottom: '20pt' }}
                                message="Required documents"
                                description={this.state.errorMsg}
                                type="error"
                                showIcon
                            /> : null}
                        </div>
                    </div>
                )
                break

            case "InvoiceFinancing.BankStatements":
                var thisMonth = moment().add('month', -1)
                var lastMonth = moment().add('month', -2)
                var monthBeforeLast = moment().add('month', -3)

                var thisMonthText = `${monthNames[thisMonth.month()]} ${thisMonth.year()}`
                var lastMonthText = `${monthNames[lastMonth.month()]} ${lastMonth.year()}`
                var monthBeforeLastText = `${monthNames[monthBeforeLast.month()]} ${monthBeforeLast.year()}`

                childViews = (
                    <div>
                        <div style={cardStyle}>
                            <UploadDoc name={thisMonthText} key="bankStatements1" docType="bankStatements" accept=".pdf" businessUniqueKey={this.state.businessUniqueKey} />
                            <Divider />
                            <UploadDoc name={lastMonthText} key="bankStatements2" docType="bankStatements" accept=".pdf" businessUniqueKey={this.state.businessUniqueKey} />
                            <Divider />
                            <UploadDoc name={monthBeforeLastText} key="bankStatements3" docType="bankStatements" accept=".pdf" businessUniqueKey={this.state.businessUniqueKey} />
                        </div>
                        <div className="flex-container justify-content-center">
                            {this.state.showError ? <Alert
                                style={{ width: '100%', marginTop: '-10pt', marginBottom: '20pt' }}
                                message="Required documents"
                                description={this.state.errorMsg}
                                type="error"
                                showIcon
                            /> : null}
                        </div>
                    </div>
                )
                break

            case "InvoiceFinancing.PFStatement":
                childViews = (
                    <div>
                        <div style={cardStyle}>
                            <h4>First download the template</h4>
                            <Button id="downloadTemplate" icon={<DownloadOutlined />}>
                                <a href="/Aion-Personal-Financial-Statement-Template.xlsx" download="Aion-Personal-Financial-Statement-Template.xlsx">&nbsp&nbspDownload Template</a>
                            </Button>
                            <div style={{ marginBottom: '30px' }} />
                            <UploadDoc title='Then upload the completed document' name="personalFinancialStatement" key="personalFinancialStatement" docType="personalFinancialStatement" businessUniqueKey={this.state.businessUniqueKey} />
                        </div>
                        <div className="flex-container justify-content-center">
                            {this.state.showError ? <Alert
                                style={{ width: '100%', marginTop: '-10pt', marginBottom: '20pt' }}
                                message="Required documents"
                                description={this.state.errorMsg}
                                type="error"
                                showIcon
                            /> : null}
                        </div>
                    </div>
                )
                break

            case "InvoiceFinancing.OtherDocuments":
                childViews = (
                    <div>
                        <div style={cardStyle}>
                            <UploadDoc title="Balance sheet as of today" name="balanceSheet" key="balanceSheet" docType="balanceSheet" businessUniqueKey={this.state.businessUniqueKey} />
                            <Divider />
                            <UploadDoc title="AR aging summary" name="arAgingSummary" key="arAgingSummary" docType="arAgingSummary" businessUniqueKey={this.state.businessUniqueKey} />
                            <Divider />
                            <UploadDoc title="AP aging summary" name="apAgingReport" key="apAgingReport" docType="apAgingReport" businessUniqueKey={this.state.businessUniqueKey} />
                        </div>
                        <div className="flex-container justify-content-center">
                            {this.state.showError ? <Alert
                                style={{ width: '100%', marginTop: '-10pt', marginBottom: '20pt' }}
                                message="Required documents"
                                description={this.state.errorMsg}
                                type="error"
                                showIcon
                            /> : null}
                        </div>
                    </div>
                )
                break

            case "InvoiceFinancing.Confirmation":
                childViews = (
                    <div style={cardStyle}>
                        By submitting your application, you agree to the<br /><a href='https://fundpnb.com/legal/e-signature' target="_blank">e-Signature Agreement</a>, <a href='https://fundpnb.com/legal/termshttps://fundpnb.com/legal/terms-of-use' target="_blank">fundpnb terms of use</a> and <a href='https://fundpnb.com/legal/privacy' target="_blank">privacy policy</a>
                    </div>
                )
                break
            default:
                break
        }

        if (flowView.OnboardingStep.includes("InvoiceFinancing.")) {
            return <>
                <FlowViewComponent
                    width={this.state.isTablet ? "100%" : (this.props.width || null)}
                    padding={this.state.isTablet && "0 20px 20px 20px"}
                    flowView={flowView}
                    loadPrev={this.loadPrevView}
                    loadNext={this.loadNext}
                    saveUserInput={this.saveUserInput}
                    childViews={childViews}
                    submitLoading={this.state.submitLoading}
                    continueLink={continueLink}
                    altButtonTitle={altButtonTitle}
                    altButtonClick={altButtonClick}
                    currentViewIndex={this.state.currentViewIndex}
                />
            </>
        } else {
            let Menu = (
                <OnboardingMenu style={{ background: 'transparent', paddingTop: '5px', padding: 0, margin: 0, marginTop: '20px', minWidth: '200px', }}>
                    <Steps direction="vertical" current={this.getCurrentStep()} onChange={this.onStepChange} style={{ height: this.state.isTablet ? '300px' : '400px' }}>
                        <Step status={this.getStatus(0)} disabled={this.disableAllSteps()} className={this.getNextStatus(1)} title="Personal Info" />
                        <Step status={this.getStatus(1)} disabled={this.disableAllSteps() || this.getStatus(1) === 'wait'} className={this.getNextStatus(1)} title="Business Info" />
                        <Step status={this.getStatus(2)} disabled={this.disableAllSteps() || this.getStatus(2) === 'wait'} className={this.getNextStatus(2)} title="Beneficial Owner Info" />
                        <Step status={this.getStatus(3)} disabled={this.disableAllSteps() || this.getStatus(3) === 'wait'} className={this.getNextStatus(3)} title="Authorized Signers" />
                        <Step status={this.getStatus(4)} disabled={this.disableAllSteps() || this.getStatus(4) === 'wait'} className={this.getNextStatus(4)} title="Submission" />
                    </Steps>
                </OnboardingMenu>
            )

            if (this.state.isTablet || this.state.isMobile) {
                const customDot = (dot, { index }) => {
                    return <Popover content={stepName[index]} arrowPointAtCenter>
                        {dot}
                    </Popover>
                }
                Menu = (
                    <OnboardingMenu style={{ background: 'transparent', paddingTop: '5px', marginTop: '20px', minWidth: '10px', width: '20px', height: '20px', marginLeft: this.state.isMobile ? 0 : '20px' }}>
                        <Steps direction={"horizontal"} current={this.getCurrentStep()} onChange={this.onStepChange} style={{ height: '400px', width: '10px' }} progressDot={customDot}>
                            <Step status={this.getStatus(0)} disabled={this.disableAllSteps()} className={this.getNextStatus(1)} title="" />
                            <Step status={this.getStatus(1)} disabled={this.disableAllSteps() || this.getStatus(1) === 'wait'} className={this.getNextStatus(1)} title="" />
                            <Step status={this.getStatus(2)} disabled={this.disableAllSteps() || this.getStatus(2) === 'wait'} className={this.getNextStatus(2)} title="" />
                            <Step status={this.getStatus(3)} disabled={this.disableAllSteps() || this.getStatus(3) === 'wait'} className={this.getNextStatus(3)} title="" />
                            <Step status={this.getStatus(4)} disabled={this.disableAllSteps() || this.getStatus(4) === 'wait'} className={this.getNextStatus(4)} title="" />
                        </Steps>
                    </OnboardingMenu>
                )
            }

            const banner = this.state.twoFactorTokenValidated === false &&
                { title: "Your email is not verified", message: <><a onClick={() => { this.setState({ show2FAModal: true }) }}>Click here</a> to send a confirmation email.</> }

            return <>
                {(this.state.isTablet || this.state.isMobile) ? <>
                    <div style={{ display: 'block', width: this.state.isMobile ? '95%' : '85%' }}>
                        {Menu}
                        <OnboardingFlowViewComponent
                            width={this.state.isTablet ? "100%" : (this.props.width || null)}
                            padding={this.state.isTablet && "0"}
                            flowView={flowView}
                            loadPrev={this.loadPrevView}
                            loadNext={this.loadNext}
                            saveUserInput={this.saveUserInput}
                            childViews={childViews}
                            submitLoading={this.state.submitLoading}
                            continueLink={continueLink}
                            altButtonTitle={altButtonTitle}
                            altButtonClick={altButtonClick}
                            currentViewIndex={this.state.currentViewIndex}
                            banner={banner}
                        />
                    </div>
                </>
                    :
                    <Flex>
                        {Menu}
                        <OnboardingFlowViewComponent
                            width={this.state.isTablet ? "100%" : (this.props.width || 700)}
                            padding={this.state.isTablet && "0 20px 20px 20px"}
                            flowView={flowView}
                            loadPrev={this.loadPrevView}
                            loadNext={this.loadNext}
                            saveUserInput={this.saveUserInput}
                            childViews={childViews}
                            submitLoading={this.state.submitLoading}
                            continueLink={continueLink}
                            altButtonTitle={altButtonTitle}
                            altButtonClick={altButtonClick}
                            currentViewIndex={this.state.currentViewIndex}
                            banner={banner}
                        />
                    </Flex>
                }
                {/* <a onClick={() => this.loadBusinessView()}>Business Menu</a> */}

            </>
        }
    }

    disableAllSteps = () => {
        return (this.state.stepProgress === 3 &&
            this.state.currentViewIndex >= 4
            && (this.state.flowViewType === FLOW_VIEW_TYPE.SUBMIT_MULTI_OWNER_APPLICATION_FLOW || this.state.flowViewType === FLOW_VIEW_TYPE.SUBMIT_APPLICATION_FLOW))
    }

    onStepChange = (current) => {
        if (current > this.state.stepProgress) return
        if (this.disableAllSteps()) return
        this.setState({ currentStep: current })
        switch (current) {
            case 0:
                if (this.state.stepProgress > 0) {
                    this.setState({
                        currentViewIndex: 2,
                        flowView: flowViewsData()[2],
                        flowViews: flowViewsData(),
                        flowViewType: FLOW_VIEW_TYPE.MAIN_FLOW,
                    })
                }
                if (this.saveStatus()) {
                    this.setState({
                        savedViewIndex: this.state.currentViewIndex,
                        savedFlowView: this.state.flowView,
                        savedFlowViews: this.state.flowViews,
                        savedFlowViewType: this.state.flowViewType,
                    })
                }
                return
            case 1:
                if (this.state.stepProgress > 1) {
                    this.setState({
                        currentViewIndex: 10,
                        flowView: flowViewsData()[10],
                        flowViews: flowViewsData(),
                        flowViewType: FLOW_VIEW_TYPE.MAIN_FLOW,

                    })
                } else if (this.state.stepProgress === 1) {
                    this.setState({
                        currentViewIndex: this.state.savedViewIndex,
                        flowView: this.state.savedFlowView,
                        flowViews: this.state.savedFlowViews,
                        flowViewType: this.state.savedFlowViewType,
                    })
                }
                if (this.saveStatus()) {
                    this.setState({
                        savedViewIndex: this.state.currentViewIndex,
                        savedFlowView: this.state.flowView,
                        savedFlowViews: this.state.flowViews,
                        savedFlowViewType: this.state.flowViewType,
                    })
                }
                return

            case 2:
                if (this.state.stepProgress > 2) {
                    this.setState({
                        currentViewIndex: 0,
                        flowView: coOwnerFlowViewsData()[0],
                        flowViews: coOwnerFlowViewsData(),
                        flowViewType: FLOW_VIEW_TYPE.CO_OWNER_FLOW,
                    })
                } else if (this.state.stepProgress === 2 && this.state.flowViewType !== FLOW_VIEW_TYPE.CO_OWNER_FLOW) {
                    this.setState({
                        currentViewIndex: this.state.savedViewIndex,
                        flowView: this.state.savedFlowView,
                        flowViews: this.state.savedFlowViews,
                        flowViewType: this.state.savedFlowViewType,
                    })
                }
                if (this.saveStatus()) {
                    this.setState({
                        savedViewIndex: this.state.currentViewIndex,
                        savedFlowView: this.state.flowView,
                        savedFlowViews: this.state.flowViews,
                        savedFlowViewType: this.state.flowViewType,
                    })
                }
                return
            case 3:
                this.setState({
                    currentViewIndex: 0,
                    flowView: signersFlowViewsData()[0],
                    flowViews: signersFlowViewsData(),
                    flowViewType: FLOW_VIEW_TYPE.SIGNER_FLOW,
                })

                if (this.saveStatus()) {
                    this.setState({
                        savedViewIndex: this.state.currentViewIndex,
                        savedFlowView: this.state.flowView,
                        savedFlowViews: this.state.flowViews,
                        savedFlowViewType: this.state.flowViewType,
                    })
                }
                return
            case 4:
                if (this.state.stepProgress === 3 && (this.state.flowViewType !== FLOW_VIEW_TYPE.SUBMIT_MULTI_OWNER_APPLICATION_FLOW && this.state.flowViewType !== FLOW_VIEW_TYPE.SUBMIT_APPLICATION_FLOW)) {
                    this.setState({
                        currentViewIndex: this.state.savedViewIndex,
                        flowView: this.state.savedFlowView,
                        flowViews: this.state.savedFlowViews,
                        flowViewType: this.state.savedFlowViewType,
                    })
                }
                return
            default:
                break
        }
    }

    getCurrentStep = () => {
        if (this.state.flowViewType === FLOW_VIEW_TYPE.MAIN_FLOW && this.state.currentViewIndex < 8) {
            return 0
        }
        if (this.state.flowViewType === FLOW_VIEW_TYPE.MAIN_FLOW && this.state.currentViewIndex >= 8) {
            return 1
        }
        if (this.state.flowViewType === FLOW_VIEW_TYPE.CO_OWNER_FLOW) {
            return 2
        }
        if (this.state.flowViewType === FLOW_VIEW_TYPE.SIGNER_FLOW) {
            return 3
        }
        if (this.state.flowViewType === FLOW_VIEW_TYPE.SUBMIT_MULTI_OWNER_APPLICATION_FLOW || this.state.flowViewType === FLOW_VIEW_TYPE.SUBMIT_APPLICATION_FLOW) {
            return 4
        }
    }

    getStatus = (step) => {
        if (step === 4 && this.getCurrentStep() === 4 && this.state.currentViewIndex >= 4) {
            return "finish"
        }
        if (step === this.getCurrentStep()) {
            return "process"
        }
        if (step < this.state.stepProgress) {
            return "finish"
        } else if (step > this.state.stepProgress) {
            return "wait"
        }
    }

    getNextStatus = (step) => {
        if (step <= this.state.stepProgress) {
            return "finish"
        } else if (step > this.state.stepProgress) {
            return "wait"
        }
    }

    // Validate input and go to next view if it's good
    loadNext = () => {
        const personalInfo = this.props.store.personalInfo || {}
        const validation = this.validateFlow()
        // const validation = { validated: true }
        var signer = this.getCurrentSigner()
        console.log("loadNext", validation, flowView.OnboardingStep)
        if (validation.validated) {
            var { supportingDocuments, vouched } = this.props.store
            var Attributes = this.props.appStore.Attributes || {}
            const bbAttributes = Attributes.BankingAttributes || {}
            // Save the data and load next
            switch (flowView.OnboardingStep) {
                case "BusinessInfo.OwnerChecklist":
                    this.props.dispatch(addDataToStore(NEW_CO_OWNER))
                    break
                case "Intro.Welcome":
                    var { UserInfo } = this.props.appStore || {}
                    if (!bbAttributes.ForceCRBAccountOpening) {
                        // Pass api key for aionAuth
                        apiPOSTReq(`${environment.uamBaseUrl}/initialSignUp`, {}, { userId: UserInfo.Email }, (err, resp) => {
                            console.log("/initialSignUp body", { userId: UserInfo.Email })
                            this.setState({ submitLoading: false })
                            try {
                                var data = resp || {}
                                console.log("/initialSignUp", err, resp)
                                if (data.result) {
                                    this.setState({ initialBusiness: data })
                                    this.props.dispatch(addDataToStore(SAVE_DATA, { BusinessUniqueKey: (data.business || {}).id }))
                                    this.loadNextView()
                                } else {
                                    throw Error(data.error || data.responseMessage)
                                }
                            } catch (error) {
                                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
                            }
                        })
                    } else {
                        console.log("Intro.Welcome", this.props.appStore.BusinessUniqueKey)
                        this.loadNextView()
                    }

                    return
                case "PersonalInfo.Edit":
                    var { UserInfo } = this.props.appStore || {}
                    this.props.dispatch(addDataToStore(PERSONAL_INFO, {
                        email: UserInfo.Email,
                        phone: UserInfo.Phone
                    }))
                    break
                case "PersonalInfo.IDV":
                    console.log("load PersonalInfo.IDV", personalInfo)
                    const { countryOfCitizenship } = personalInfo || {}
                    var { result } = personalInfo.vouched || {}
                    var idType = result.type
                    switch (result.type) {
                        case "drivers-license":
                            idType = "DriversLicense"
                            break
                        case "passport":
                            idType = "Passport"
                            break
                        default:
                            idType = "Other"
                            break
                    }

                    if (!countryOfCitizenship === 'US' && (idType.toLowerCase() !== "passport")) {
                        // Non-citizen user has to upload a passport
                        ErrorAlert({ description: "We only accept passport for non-US citizens. Please upload a passport" })
                        this.setState({ reloadIDV: true })
                        return
                    }
                    var identification = {
                        "isPrimary": true,
                        "firstName": result.firstName,
                        "lastName": result.lastName,
                        "type": idType,
                        "idNumber": result.id,
                        "issuedDate": moment(result.issueDate, "MM/DD/YYYY").format("YYYY-MM-DD"),
                        "expDate": moment(result.expireDate, "MM/DD/YYYY").format("YYYY-MM-DD"),
                        // "verifiedDate": "2022-05-27",
                        "issuingAuthority": result.state,
                        "issuingStateOrProvince": result.state,
                        "issuingCountryCode": result.country
                        // "documentUrl": personalInfo.idDocumentUrl,
                    }
                    var body = {
                        "identification": identification
                    }

                    console.log("/identifications/save body", body)
                    this.setState({ submitLoading: true })
                    apiPOSTReq(`${environment.uamBaseUrl}/identifications/save`, {}, body, (err, resp) => {
                        this.setState({ submitLoading: false })
                        try {
                            console.log("/identifications/save resp", resp)
                            const data = resp || {}
                            if (data.result && data.identification) {
                                this.props.dispatch(addDataToStore(PERSONAL_INFO, { identificationId: data.identification.id }))
                                this.loadNextView()
                            } else {
                                throw Error(data.responseMessage || data.error)
                            }
                        } catch (error) {
                            console.log("/identifications/save err", error, resp)
                            ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
                        }
                    })
                    return
                case "PersonalInfo.Citizenship":
                    this.loadNextView(personalInfo.countryOfCitizenship === 'US' ? 2 : 1)
                    return
                case "OwnerInfo.Citizenship":
                    this.loadNextView(this.getCurrentCoOwner().countryOfCitizenship === 'US' ? 2 : 1)
                    return
                case "PersonalInfo.DriversLicenseFront":
                    var businessDocument = {
                        "category": "BBOnboarding",
                        "name": "Drivers License Front",
                        "documentUrl": personalInfo.driversLicenseFront
                    }
                    var body = {
                        "businessDocument": businessDocument
                    }

                    this.setState({ submitLoading: true })
                    apiPOSTReq(`${environment.uamBaseUrl}/docs/save`, {}, body, (err, resp) => {
                        this.setState({ submitLoading: false })
                        try {
                            const data = resp || {}
                            if (data.result) {
                                this.loadNextView()
                            } else {
                                throw Error(data.responseMessage || data.error)
                            }
                        } catch (error) {
                            console.log("/docs/save err", error, resp)
                            ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
                        }
                    })
                    return
                case "PersonalInfo.DriversLicenseBack":
                    var businessDocument = {
                        "category": "BBOnboarding",
                        "name": "Drivers License Back",
                        "documentUrl": personalInfo.driversLicenseBack
                    }
                    var body = {
                        "businessDocument": businessDocument
                    }
                    this.setState({ submitLoading: true })
                    apiPOSTReq(`${environment.uamBaseUrl}/docs/save`, {}, body, (err, resp) => {
                        this.setState({ submitLoading: false })
                        try {
                            const data = resp || {}
                            if (data.result) {
                                this.loadNextView()
                            } else {
                                throw Error(data.responseMessage || data.error)
                            }
                        } catch (error) {
                            console.log("/docs/save err", error, resp)
                            ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
                        }
                    })
                    return
                case "BusinessInfo.IncorpLetter":
                    var { businessInfo } = this.props.store
                    var businessDocument = {
                        "category": "BBOnboarding",
                        "name": "Incorporation Letter",
                        "documentUrl": businessInfo.incorporationLetter
                    }
                    var body = {
                        "businessDocument": businessDocument
                    }
                    console.log("BusinessInfo.IncorpLetter", body)
                    this.setState({ submitLoading: true })
                    apiPOSTReq(`${environment.uamBaseUrl}/docs/save`, {}, body, (err, resp) => {
                        this.setState({ submitLoading: false })
                        try {
                            const data = resp || {}
                            if (data.result) {
                                this.loadNextView()
                            } else {
                                throw Error(data.responseMessage || data.error)
                            }
                        } catch (error) {
                            console.log("/docs/save err", error, resp)
                            ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
                        }
                    })
                    return
                case "BusinessInfo.EIN":
                    var { businessInfo } = this.props.store
                    var businessDocument = {
                        "category": "BBOnboarding",
                        "name": "EIN Document",
                        "documentUrl": businessInfo.einDocument
                    }
                    var body = {
                        "businessDocument": businessDocument
                    }
                    this.setState({ submitLoading: true })
                    console.log("BusinessInfo.EIN", body)
                    apiPOSTReq(`${environment.uamBaseUrl}/docs/save`, {}, body, (err, resp) => {
                        this.setState({ submitLoading: false })
                        console.log("BusinessInfo.EIN RESP", err, resp)
                        try {
                            const data = resp || {}
                            if (data.result) {
                                this.loadNextView()
                            } else {
                                throw Error(data.responseMessage || data.error)
                            }
                        } catch (error) {
                            console.log("/docs/save err", error, resp)
                            ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
                        }
                    })
                    return
                case "BusinessInfo.Edit":
                    console.log("BusinessInfo.Edit loadNext: ", this.props.store.businessInfo.mailingAddress)
                    if (!this.props.store.businessInfo.mailingAddress) {
                        this.props.dispatch(addDataToStore(BUSINESS_INFO, { mailingAddress: this.props.store.businessInfo.physicalAddress }))
                    }
                    break
                case "OwnerInfo.OwnershipPercentage":
                    this.props.dispatch(addDataToStore(ONB_SAVE_DATA, { coOwnerId: 0 }))
                    this.loadNextView()
                    return
                case "OwnerInfo.AllOwners":
                    if ((this.props.store.coOwnersInfo || []).length === 0) {
                        this.loadNextView(10)    // 10 to skip to final step when there are no co owners
                    } else {
                        if ((this.getCurrentCoOwner() || {}).email) {
                            const coOwners = this.props.store.coOwnersInfo || []
                            // var nextCoOwnerId
                            // coOwners.forEach((coOwner, index) => {
                            //     if (!coOwner.email) {
                            //         nextCoOwnerId = index
                            //     }
                            // })

                            var nextCoOwner = coOwners.find((coOwner) => !coOwner.email)

                            if (!nextCoOwner) {
                                this.loadNextView(9)    // 9
                            } else {
                                this.props.dispatch(addDataToStore(ONB_SAVE_DATA, { coOwnerId: nextCoOwner.id }))
                                this.loadNextView()
                            }
                        } else {
                            this.props.dispatch(addDataToStore(ONB_SAVE_DATA, { coOwnerId: 0 }))
                            this.loadNextView()
                        }
                    }
                    return
                case "Signers.Id":
                    var identification = {
                        "isPrimary": true,
                        "firstName": signer.firstName,
                        "lastName": signer.lastName,
                        "type": signer.idType.replace(/\s/g, ''),
                        "idNumber": signer.idNumber,
                        // "issuedDate": "2022-05-27",
                        "expDate": signer.idExpiryDate,
                        // "verifiedDate": "2022-05-27",
                        "issuingAuthority": signer.idType === 'Drivers License' ? 'DMV' : 'US Department of State',
                        "issuingStateOrProvince": signer.idPlaceOfIssuance,
                        "issuingCountryCode": signer.idPlaceOfIssuance,
                        "documentUrl": signer.idDocumentUrl,
                    }
                    var body = {
                        "identification": identification
                    }

                    console.log("/identifications/save body", body)
                    this.setState({ submitLoading: true })
                    apiPOSTReq(`${environment.uamBaseUrl}/identifications/save`, {}, body, (err, resp) => {
                        this.setState({ submitLoading: false })
                        try {
                            console.log("/identifications/save resp", resp)
                            const data = resp || {}
                            if (data.result && data.identification) {
                                this.props.dispatch(addDataToStore(SIGNER_INFO, { identificationId: data.identification.id, signerId: signer.id }))
                                this.loadNextView()
                            } else {
                                throw Error(data.responseMessage || data.error)
                            }
                        } catch (error) {
                            console.log("/identifications/save err", error, resp)
                            ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
                        }
                    })
                    return
                case "OwnerInfo.Id":
                    var coOwnerInfo = this.getCurrentCoOwner()

                    var identification = {
                        "isPrimary": true,
                        "firstName": coOwnerInfo.firstName,
                        "lastName": coOwnerInfo.lastName,
                        "type": coOwnerInfo.idType.replace(/\s/g, ''),
                        "idNumber": coOwnerInfo.idNumber,
                        // "issuedDate": "2022-05-27",
                        "expDate": coOwnerInfo.idExpiryDate,
                        // "verifiedDate": "2022-05-27",
                        "issuingAuthority": coOwnerInfo.idType === 'Drivers License' ? 'DMV' : 'Passport Authority',
                        "issuingStateOrProvince": coOwnerInfo.idPlaceOfIssuance,
                        "issuingCountryCode": coOwnerInfo.idPlaceOfIssuance,
                        "documentUrl": coOwnerInfo.idDocumentUrl,
                    }
                    var body = {
                        "identification": identification
                    }

                    console.log("/identifications/save body", body)
                    this.setState({ submitLoading: true })
                    apiPOSTReq(`${environment.uamBaseUrl}/identifications/save`, {}, body, (err, resp) => {
                        this.setState({ submitLoading: false })
                        try {
                            console.log("/identifications/save resp", resp)
                            const data = resp || {}
                            if (data.result && data.identification) {
                                this.props.dispatch(addDataToStore(CO_OWNER_INFO, { identificationId: data.identification.id, coOwnerId: coOwnerInfo.id }))
                                this.loadNextView()
                            } else {
                                throw Error(data.responseMessage || data.error)
                            }
                        } catch (error) {
                            console.log("/identifications/save err", error, resp)
                            ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
                        }
                    })
                    return
                case "Signers.DriversLicenseFront":
                    var businessDocument = {
                        "category": "BBOnboarding",
                        "name": "Drivers License Front",
                        "documentUrl": signer.driversLicenseFront
                    }
                    var body = {
                        "businessDocument": businessDocument
                    }

                    this.setState({ submitLoading: true })
                    apiPOSTReq(`${environment.uamBaseUrl}/docs/save`, {}, body, (err, resp) => {
                        this.setState({ submitLoading: false })
                        try {
                            const data = resp || {}
                            if (data.result) {
                                this.loadNextView()
                            } else {
                                throw Error(data.responseMessage || data.error)
                            }
                        } catch (error) {
                            console.log("/docs/save err", error, resp)
                            ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
                        }
                    })
                    return
                case "Signers.DriversLicenseBack":
                    var businessDocument = {
                        "category": "BBOnboarding",
                        "name": "Drivers License Back",
                        "documentUrl": signer.driversLicenseBack
                    }
                    var body = {
                        "businessDocument": businessDocument
                    }

                    this.setState({ submitLoading: true })
                    apiPOSTReq(`${environment.uamBaseUrl}/docs/save`, {}, body, (err, resp) => {
                        this.setState({ submitLoading: false })
                        try {
                            const data = resp || {}
                            if (data.result) {
                                this.loadNextView()
                            } else {
                                throw Error(data.responseMessage || data.error)
                            }
                        } catch (error) {
                            console.log("/docs/save err", error, resp)
                            ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
                        }
                    })
                    return
                case "SubmitInfo.InitalDeposit":
                    var { businessInfo } = this.props.store
                    var { UserInfo } = this.props.appStore || {}
                    var { initialDepositType, initialDepositAmt, initialDepositAccountNumber, initialDepositRoutingNumber, initialDepositNameOnAccount, initialDepositCardNumber, initialDepositCVC, initialDepositExpMonthYear, initialDepositZip } = businessInfo
                    var deposit = {
                        "amount": toNumberStr(initialDepositAmt),
                        "name_on_account": initialDepositNameOnAccount
                    }
                    if (initialDepositType == "Bank transfer") {
                        deposit.ach = {
                            "account_number": initialDepositAccountNumber,
                            "account_type": "checking",
                            "routing_number": initialDepositRoutingNumber
                        }
                    } else {
                        var expArr = initialDepositExpMonthYear.split("/")
                        deposit.card = {
                            "card_number": initialDepositCardNumber,
                            "cvc": initialDepositCVC,
                            "expiration_month": expArr[0],
                            "expiration_year": expArr[1],
                            "zip_code": initialDepositZip
                        }
                    }
                    var body = { tpDeposit: deposit, deposit: deposit, personEmail: UserInfo.Email }
                    console.log("deposit body", body)
                    this.setState({ submitLoading: true })
                    var depositHeaders = {
                        "BankProvider": environment.bankProvider.crb
                    }

                    console.log("/bb/deposit", depositHeaders, body)

                    apiPOSTReq(`${environment.bbBaseUrl}/bb/deposit`, depositHeaders, body, (err, resp) => {
                        this.setState({ submitLoading: false })
                        try {
                            var data = resp || {}
                            console.log("/bb/deposit", err, resp)
                            const tpDepositResult = data.tpDeposit || {}
                            const depositResult = data.deposit || {}
                            const initialDepositId = depositResult.id || tpDepositResult.id
                            if (data.result) {
                                this.setState({ initialDepositId: initialDepositId })
                                console.log("/bb/deposit initialDepositId", depositResult.id, tpDepositResult.id)
                                this.props.dispatch(addDataToStore(ONB_SAVE_DATA, { initialDepositId: initialDepositId }))
                                this.loadNextView()
                            } else {
                                throw Error(data.error || data.responseMessage)
                            }
                        } catch (error) {
                            ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
                        }
                    })

                    return
                case "SubmitInfo.OwnershipCertification":
                    this.saveUserInput()
                    break
                case "SubmitInfo.Submit":
                    var primaryApplicantInfo = this.props.store.personalInfo
                    primaryApplicantInfo["iovationBlackbox"] = this.getIovationBBString()
                    primaryApplicantInfo["vouchedJobId"] = (primaryApplicantInfo.vouched || {}).id
                    var body = {
                        primaryApplicantInfo: primaryApplicantInfo,
                        coOwnerInfo: this.props.store.coOwnersInfo,
                        businessInfo: this.props.store.businessInfo,
                        initialDepositId: this.props.store.initialDepositId,
                        manualReviewRequired: this.props.appStore.manualReviewRequired,
                        reviewComments: [this.props.appStore.manualReviewRequiredMsg || "NA"],
                        subscriptionPlanName: capitalize(this.props.appStore.subscriptionPlanName),
                        subscriptionBilling: capitalize(this.props.appStore.subscriptionBilling),
                        accountApplication: {
                            "accountNickName": "Business Checking"
                        }
                    }

                    if (this.props.store.additionalSigners === "Yes") body.signerInfo = this.props.store.signersInfo

                    this.setState({ submitLoading: true })
                    const signupEndpoint = `${environment.uamBaseUrl}/bp/${bbAttributes.ForceCRBAccountOpening ? 'existingCustomerSignUp' : 'signUp'}`
                    console.log("SubmitInfo.Submit", signupEndpoint, body)
                    apiPOSTReq(signupEndpoint, {}, body, (err, resp) => {
                        this.setState({ submitLoading: false })
                        try {
                            var data = resp || {}
                            console.log(signupEndpoint, "resp", err, resp)
                            if (data.result) {
                                message.success('Application submitted successfully!')
                                this.loadNextView()

                                // Generate BO Certificate
                                apiPOSTReq(`${environment.uamBaseUrl}/uam/generateBoCertificate`, {}, body, (err, resp) => {
                                    try {
                                        var data = resp || {}
                                        console.log("/bb/generateBoCertificate", err, resp)
                                        if (!data.result) throw Error(data.error || data.responseMessage)
                                    } catch (error) {
                                        console.log("/application err", error, err, resp)
                                    }
                                })
                            } else {
                                throw Error(data.error || data.responseMessage)
                            }
                        } catch (error) {
                            console.log("/application err", error, err, resp)
                            ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
                        }
                    })

                    return
                case "SubmitInfo.Submitted":
                    this.setState({ submitLoading: true })
                    apiGET("/attributes", null, (err, resp) => {
                        try {
                            const data = resp.data
                            if (data.success) {
                                this.props.dispatch(completeSignin(data))
                                // Fetch Profile
                                apiGET("/profile", null, (err, resp) => {
                                    try {
                                        const pData = resp.data
                                        this.setState({ loading: false })
                                        if (pData.success) {
                                            this.props.dispatch(addDataToStore(SAVE_DATA, { Profile: pData.profileInfo }))
                                            // Set Getting Started flag
                                            this.props.dispatch(addDataToStore(USERDEFAULTS_SAVE_FLAG, { BBShowGettingStarted: true }))
                                            apiGET("/banking/radius/dashboard", null, (err, resp) => {
                                                try {
                                                    console.log("/banking/radius/dashboard", err, resp)
                                                    if (err) throw new Error(err)
                                                    this.props.dispatch(addDataToStore(BNK_SAVE_DATA, resp.data))
                                                    window.location.reload()
                                                } catch (error) {
                                                    console.log("/banking/radius/dashboard error", error)
                                                }
                                            })
                                        } else {
                                            throw Error("Could not fetch profile.")
                                        }
                                    } catch (error) {
                                        console.log("ERRR", error.stack)
                                        alert("Sorry, we had trouble processing your request. Please try again.")
                                    }
                                })
                            } else {
                                throw Error("Could not fetch attributes.")
                            }
                        } catch (error) {
                            console.log("ERRR", error.stack)
                            alert("Sorry, we had trouble processing your request. Please try again.")
                        }
                    })
                    return
                // InvoiceFinancing
                case "InvoiceFinancing.OtherDocuments":
                    // Submit data to server
                    this.setState({ submitLoading: true })

                    const headers = {
                        //jwt: this.state.jwt,
                        businesskey: this.state.businessUniqueKey
                    }

                    var data = {
                        primaryApplicantInfo: this.props.store.personalInfo,
                        businessInfo: this.props.store.businessInfo,
                        debtSchedule: this.props.store.debtSchedule,
                        supportingDocuments: this.props.store.supportingDocuments,
                        primaryApplicationCompleted: this.state.primaryApplicationCompleted
                    }

                    if (this.props.store.coOwnersInfo) {
                        data.coOwnersInfo = this.props.store.coOwnersInfo
                    }

                    //console.log("InvoiceFinancing.Confirmation: ", JSON.stringify(data))

                    apiPOST('/financing/application', headers, data, (err, resp) => {
                        this.setState({ submitLoading: false })
                        if (!err) {
                            try {
                                const data = resp.data
                                if (data.success) {
                                    message.success('Application submitted successfully!')
                                    this.loadNextView()
                                    // Re-direct to invoicing web app
                                    this.props.closeModal()
                                } else {
                                    throw Error("Could not complete submission.")
                                }
                            } catch (error) {
                                alert("Sorry, we had trouble processing your request. Please try again.")
                            }
                        } else {
                            alert("Sorry, we had trouble processing your request. Please try again.")
                        }
                    })
                    return

                default:
                    break
            }

            if (flowView.OnboardingStep === "BusinessInfo.IncorpType") {
                if (this.props.store.businessInfo.incorporationType == "Sole Proprietorship") {
                    this.loadNextView(2)    // skip business search and move forward to manual entry view
                } else {
                    this.loadNextView()
                }
                // For debt schedule, first check if any loans were added and ask to confirm if not
            } else if (flowView.OnboardingStep === "InvoiceFinancing.DebtSchedule") {
                if (this.state.debtSchedule.length > 0) {
                    this.loadNextView()
                } else {
                    this.setState({ showAddLoanModal: true })
                }
            } else if (flowView.OnboardingStep === "InvoiceFinancing.TaxReturns") {
                var year = new Date().getFullYear()
                const newData = supportingDocuments.taxReturns

                if (newData.find(x => x.Name === year.toString() && (x.noDocumentChecked && x.Note || x.DocumentURL)) &&
                    newData.find(x => x.Name === '' + (year - 1).toString() && (x.noDocumentChecked && x.Note || x.DocumentURL)) &&
                    newData.find(x => x.Name === '' + (year - 2).toString() && (x.noDocumentChecked && x.Note || x.DocumentURL))) {
                    this.setState({
                        showError: false, errorMsg: ""
                    })
                    this.loadNextView()
                } else {
                    this.setState({
                        showError: true, errorMsg: "Please provide your tax returns for the last 3 years"
                    })
                }
            } else if (flowView.OnboardingStep === "InvoiceFinancing.ProfitAndLoss") {
                var year = new Date().getFullYear()

                const newData = supportingDocuments.pnl

                if (newData.find(x => x.Name === year.toString() && (x.noDocumentChecked && x.Note || x.DocumentURL)) &&
                    newData.find(x => x.Name === '' + (year - 1).toString() && (x.noDocumentChecked && x.Note || x.DocumentURL)) /*&&
                    newData.find(x => x.Name === '' + (year - 2).toString() && (x.noDocumentChecked && x.Note || x.DocumentURL))*/) {
                    this.setState({
                        showError: false, errorMsg: "",
                    })
                    this.loadNextView()
                } else {
                    this.setState({
                        showError: true, errorMsg: "Please provide your profit and loss/income statements for the last 2 years"
                    })
                }
            } else if (flowView.OnboardingStep === "InvoiceFinancing.BankStatements") {
                var thisMonth = moment().add('month', -1)
                var lastMonth = moment().add('month', -2)
                var monthBeforeLast = moment().add('month', -3)

                var thisMonthText = `${monthNames[thisMonth.month()]} ${thisMonth.year()}`
                var lastMonthText = `${monthNames[lastMonth.month()]} ${lastMonth.year()}`
                var monthBeforeLastText = `${monthNames[monthBeforeLast.month()]} ${monthBeforeLast.year()}`

                const bankStatements = supportingDocuments.bankStatements

                if (bankStatements.find(x => x.Name === thisMonthText) &&
                    bankStatements.find(x => x.Name === lastMonthText) &&
                    bankStatements.find(x => x.Name === monthBeforeLastText)) {
                    this.setState({
                        showError: false, errorMsg: ""
                    })
                    this.loadNextView()
                } else {
                    this.setState({
                        showError: true, errorMsg: "Please provide your bank statements for the previous 3 months"
                    })
                }
            } else if (flowView.OnboardingStep === "InvoiceFinancing.PFStatement") {
                if (supportingDocuments.personalFinancialStatement.length == 0) {
                    this.setState({ showError: true, errorMsg: "You must provide the required document to continue." })
                } else {
                    this.setState({ showError: false, errorMsg: "" })
                    this.loadNextView()
                }
            } else if (flowView.OnboardingStep === "InvoiceFinancing.OtherDocuments") {
                if (supportingDocuments.balanceSheet.length == 0 ||
                    supportingDocuments.arAgingSummary.length == 0 || supportingDocuments.apAgingReport.length == 0) {
                    this.setState({ showError: true, errorMsg: "You must provide the required documents to continue." })
                } else {
                    this.setState({ showError: false, errorMsg: "" })
                    this.loadNextView()
                }
            } else {
                this.loadNextView()
            }
        } else {
            // alert(validation.message)
            ErrorAlert({ description: validation.message })
        }
    }

    saveUserInput = (id, dataToSave) => {
        // console.log("flowView", flowView, dataToSave)
        switch (flowView.OnboardingStep) {
            // PersonalInfo
            case "PersonalInfo.Edit":
                if (id === "Name") {
                    const nameObj = getNameObj2(dataToSave)
                    this.props.dispatch(addDataToStore(PERSONAL_INFO, nameObj))
                }
                if (id === "Date") {
                    this.props.dispatch(addDataToStore(PERSONAL_INFO, { dob: dataToSave }))
                }
                if (id === "Address") {
                    this.props.dispatch(addDataToStore(PERSONAL_INFO, { address: dataToSave }))
                }
                if (id === "Line2") {
                    var address = Object.assign({}, this.props.store.personalInfo.address)
                    address.line2 = dataToSave
                    this.props.dispatch(addDataToStore(PERSONAL_INFO, { address: address }))
                }
                break
            case "PersonalInfo.Citizenship":
                this.props.dispatch(addDataToStore(PERSONAL_INFO, { countryOfCitizenship: dataToSave }))
                break
            case "PersonalInfo.Resident":
                this.props.dispatch(addDataToStore(PERSONAL_INFO, { resident: dataToSave === "Yes", citizenOrResident: dataToSave === "Yes", idType: "Passport" }))
                break
            case "PersonalInfo.IDV":
                var { result } = dataToSave
                this.props.dispatch(addDataToStore(PERSONAL_INFO, {
                    firstName: result.firstName,
                    lastName: result.lastName,
                    dob: moment(result.birthDate, "MM/DD/YYYY").toDate(),
                    [id]: dataToSave
                }))
                break
            case "PersonalInfo.Id":
                if (id === "Date") {
                    this.props.dispatch(addDataToStore(PERSONAL_INFO, { idExpiryDate: getDateString(dataToSave) }))
                } else {
                    this.props.dispatch(addDataToStore(PERSONAL_INFO, { [id]: dataToSave }))
                }
                break
            case "PersonalInfo.SSN":
            case "PersonalInfo.BusinessTitle":
            case "PersonalInfo.BusinessRole":
            // case "PersonalInfo.AuthorizedSigner":
            case "PersonalInfo.ControlPerson":
                this.props.dispatch(addDataToStore(PERSONAL_INFO, { [id]: dataToSave }))
                break

            // BusinessInfo
            case "BusinessInfo.IndustryConsent":
                this.props.dispatch(addDataToStore(BUSINESS_INFO, { [id]: new Date(), [`${id}Checked`]: dataToSave }))
                break
            case "BusinessInfo.IncorpType":
            case "BusinessInfo.AnnualRevenue":
            case "BusinessInfo.EmployeeCount":
                this.props.dispatch(addDataToStore(BUSINESS_INFO, { [id]: dataToSave }))
                break
            case "BusinessInfo.Industry":
                dataToSave = dataToSave || {}
                this.props.dispatch(addDataToStore(BUSINESS_INFO, { industry: dataToSave.naicsTitle, naics: dataToSave.code, naics6: dataToSave.code }))
                break
            case "BusinessInfo.Edit":
                if (id === "Name") {
                    this.props.dispatch(addDataToStore(BUSINESS_INFO, { name: (dataToSave) }))
                }
                if (id === "State") {
                    this.props.dispatch(addDataToStore(BUSINESS_INFO, { incorporationState: dataToSave }))
                }
                if (id === "Date") {
                    this.props.dispatch(addDataToStore(BUSINESS_INFO, { incorporationDate: moment(dataToSave).format('YYYY-MM-DD') }))
                }
                // Make mailing address the same as well
                if (id === "Address") {
                    // if (!this.props.store.businessInfo.mailingAddress) {
                    //     this.props.dispatch(addDataToStore(BUSINESS_INFO, { physicalAddress: dataToSave, mailingAddress: dataToSave }))
                    // } else {
                    this.props.dispatch(addDataToStore(BUSINESS_INFO, { physicalAddress: dataToSave }))
                    // }
                }
                if (id === "Line2") {
                    var address = Object.assign(this.props.store.businessInfo.physicalAddress || {}, {})
                    address.line2 = dataToSave
                    // if (!this.props.store.businessInfo.mailingAddress) {
                    //     this.props.dispatch(addDataToStore(BUSINESS_INFO, { physicalAddress: address, mailingAddress: address }))
                    // } else {
                    this.props.dispatch(addDataToStore(BUSINESS_INFO, { physicalAddress: address }))
                    // }
                }
                break
            case "BusinessInfo.MailingAddress":
                if (id === "Address") {
                    var address = this.props.store.businessInfo.mailingAddress || {}
                    address = Object.assign(address, dataToSave || {})
                    this.props.dispatch(addDataToStore(BUSINESS_INFO, { mailingAddress: address }))
                }
                if (id === "Line2") {
                    var address = Object.assign({}, this.props.store.businessInfo.mailingAddress)
                    address.line2 = dataToSave
                    this.props.dispatch(addDataToStore(BUSINESS_INFO, { mailingAddress: address }))
                }
                break
            case "BusinessInfo.Description":
                this.props.dispatch(addDataToStore(BUSINESS_INFO, { [id]: dataToSave }))
                break
            case "BusinessInfo.AccountActivity":
                this.props.dispatch(addDataToStore(BUSINESS_INFO, { [id]: dataToSave }))
                break
            case "BusinessInfo.DBA":
                if (id === "Toggle") {
                    if (dataToSave) {
                        this.props.dispatch(addDataToStore(BUSINESS_INFO, { dbaChecked: true, dba: this.props.store.businessInfo.name }))
                    } else {
                        this.props.dispatch(addDataToStore(BUSINESS_INFO, { dbaChecked: false, dba: null }))
                    }
                } else {
                    this.props.dispatch(addDataToStore(BUSINESS_INFO, { dba: dataToSave }))
                }
                break
            case "BusinessInfo.EIN":
                this.props.dispatch(addDataToStore(BUSINESS_INFO, { ein: dataToSave }))
                break
            case "BusinessInfo.OnlinePresence":
                this.props.dispatch(addDataToStore(BUSINESS_INFO, { onlinePresence: [dataToSave] }))
                this.props.dispatch(addDataToStore(BUSINESS_INFO, { Urls: [dataToSave] }))
                break
            case "BusinessInfo.Phone":
                this.props.dispatch(addDataToStore(BUSINESS_INFO, { phone: dataToSave }))
                break
            case "BusinessInfo.OwnershipPercentage":
            case "OwnerInfo.OwnershipPercentage":
                this.props.dispatch(addDataToStore(PERSONAL_INFO, { ownershipPercentage: dataToSave }))
                break

            // OwnerInfo
            case "OwnerInfo.Name":
                const nameObj = getNameObj2(dataToSave)
                this.props.dispatch(addDataToStore(CO_OWNER_INFO, { ...nameObj, coOwnerId: this.props.store.coOwnerId }))
                break
            case "OwnerInfo.Edit":
                if (id === "Name") {
                    const nameObj2 = getNameObj2(dataToSave)
                    this.props.dispatch(addDataToStore(CO_OWNER_INFO, { ...nameObj2, coOwnerId: this.props.store.coOwnerId }))
                }
                if (id === "Date") {
                    this.props.dispatch(addDataToStore(CO_OWNER_INFO, { dob: dataToSave, coOwnerId: this.props.store.coOwnerId }))
                }
                if (id === "Address") {
                    this.props.dispatch(addDataToStore(CO_OWNER_INFO, { address: dataToSave, coOwnerId: this.props.store.coOwnerId }))
                }
                if (id === "Line2") {
                    var coOwnerInfo = this.getCurrentCoOwner()
                    var address = Object.assign({}, coOwnerInfo.address)
                    address.line2 = dataToSave
                    this.props.dispatch(addDataToStore(CO_OWNER_INFO, { address: address, coOwnerId: this.props.store.coOwnerId }))
                }
                break
            case "OwnerInfo.BusinessTitle":
                this.props.dispatch(addDataToStore(CO_OWNER_INFO, { [id]: dataToSave, coOwnerId: this.props.store.coOwnerId }))
                break
            case "OwnerInfo.BusinessRole":
                this.props.dispatch(addDataToStore(CO_OWNER_INFO, { businessRole: dataToSave, coOwnerId: this.props.store.coOwnerId }))
                break
            case "OwnerInfo.Email":
                this.props.dispatch(addDataToStore(CO_OWNER_INFO, { email: dataToSave, coOwnerId: this.props.store.coOwnerId }))
                break
            case "OwnerInfo.Phone":
                this.props.dispatch(addDataToStore(CO_OWNER_INFO, { phone: dataToSave, coOwnerId: this.props.store.coOwnerId }))
                break
            case "OwnerInfo.Citizenship":
                // var resident = false
                // if (dataToSave === "Yes") {
                //     resident = true
                // }
                this.props.dispatch(addDataToStore(CO_OWNER_INFO, { countryOfCitizenship: dataToSave, coOwnerId: this.props.store.coOwnerId }))
                break
            case "OwnerInfo.Resident":
                var resident = false
                if (dataToSave === "Yes") {
                    resident = true
                }
                this.props.dispatch(addDataToStore(CO_OWNER_INFO, { resident: resident, citizenOrResident: resident, idType: "Passport", coOwnerId: this.props.store.coOwnerId }))
                break
            case "OwnerInfo.SSN":
                this.props.dispatch(addDataToStore(CO_OWNER_INFO, { ssn: dataToSave, coOwnerId: this.props.store.coOwnerId }))
                break
            case "OwnerInfo.OwnershipPercentage":
                this.props.dispatch(addDataToStore(CO_OWNER_INFO, { ownershipPercentage: parseFloat(dataToSave), coOwnerId: this.props.store.coOwnerId }))
                break
            case "Signers.AddSigner":
                this.props.dispatch(addDataToStore(ONB_SAVE_DATA, { additionalSigners: dataToSave }))
                break
            case "Signers.AuthorizedSigner":
                this.props.dispatch(addDataToStore(PERSONAL_INFO, { authorizedSigner: (dataToSave === "Yes") }))
                return
            // case "Signers.AddSigner":
            //     this.props.dispatch(addDataToStore(SIGNER_INFO, { additionalSigners: dataToSave }))
            //     break
            case "Signers.Edit":
                if (id === "Date") {
                    this.props.dispatch(addDataToStore(SIGNER_INFO, { dob: dataToSave, signerId: this.props.store.signerId }))
                }
                if (id === "Address") {
                    this.props.dispatch(addDataToStore(SIGNER_INFO, { address: dataToSave, signerId: this.props.store.signerId }))
                }
                if (id === "Line2") {
                    var signer = this.getCurrentSigner()
                    var address = Object.assign({}, signer.address)
                    address.line2 = dataToSave
                    this.props.dispatch(addDataToStore(SIGNER_INFO, { address: address, signerId: this.props.store.signerId }))
                }
                break
            case "Signers.Email":
                this.props.dispatch(addDataToStore(SIGNER_INFO, { email: dataToSave, signerId: this.props.store.signerId }))
                break
            case "OwnerInfo.Id":
                if (id === "Date") {
                    this.props.dispatch(addDataToStore(CO_OWNER_INFO, { idExpiryDate: getDateString(dataToSave), coOwnerId: this.props.store.coOwnerId }))
                } else {
                    this.props.dispatch(addDataToStore(CO_OWNER_INFO, { [id]: dataToSave, coOwnerId: this.props.store.coOwnerId }))
                }
                break
            case "Signers.Id":
                if (id === "Date") {
                    this.props.dispatch(addDataToStore(SIGNER_INFO, { idExpiryDate: getDateString(dataToSave), signerId: this.props.store.signerId }))
                } else {
                    this.props.dispatch(addDataToStore(SIGNER_INFO, { [id]: dataToSave, signerId: this.props.store.signerId }))
                }
                break
            case "Signers.Citizenship":
                // var resident = false
                // if (dataToSave === "Yes") {
                //     resident = true
                // }
                this.props.dispatch(addDataToStore(SIGNER_INFO, { countryOfCitizenship: dataToSave, signerId: this.props.store.signerId }))
                break
            case "Signers.Resident":
                var resident = false
                if (dataToSave === "Yes") {
                    resident = true
                }
                this.props.dispatch(addDataToStore(SIGNER_INFO, { resident: resident, citizenOrResident: resident, idType: "Passport", signerId: this.props.store.signerId }))
                break
            case "Signers.SSN":
                this.props.dispatch(addDataToStore(SIGNER_INFO, { ssn: dataToSave, signerId: this.props.store.signerId }))
                break
            case "Signers.Phone":
                this.props.dispatch(addDataToStore(SIGNER_INFO, { phone: dataToSave, signerId: this.props.store.signerId }))
                break
            case "SubmitInfo.OwnershipCertification":
                this.props.dispatch(addDataToStore(PERSONAL_INFO, { certifiedOn: moment() }))
                this.props.dispatch(addDataToStore(PERSONAL_INFO, { digitalSignatureStr: this.sigBOCert.getTrimmedCanvas().toDataURL("image/png") }))
                break
            case "SubmitInfo.InitalDeposit":
                console.log("initialDepositType", id, dataToSave)
                if (id === "initialDepositType") {
                    this.props.dispatch(addDataToStore(BUSINESS_INFO, { initialDepositType: dataToSave }))
                } else if (id === "AccountNumber") {
                    this.props.dispatch(addDataToStore(BUSINESS_INFO, { initialDepositAccountNumber: dataToSave }))
                } else if (id === "RoutingNumber") {
                    this.props.dispatch(addDataToStore(BUSINESS_INFO, { initialDepositRoutingNumber: dataToSave }))
                } else {
                    this.props.dispatch(addDataToStore(BUSINESS_INFO, { [`initialDeposit${id}`]: dataToSave }))
                }
                this.setState({})
                break
            case "SubmitInfo.Submit":
                this.props.dispatch(addDataToStore(PERSONAL_INFO, { [id]: new Date(), [`${id}Checked`]: dataToSave }))
                break
            // PPP
            case "PPP.LoanPurpose":
                if (Array.isArray(dataToSave)) {
                    if (!flowView.UserInput) {
                        flowView.UserInput = {}
                    }
                    flowView.UserInput.SelectedOptions = dataToSave
                } else if (id === "Other") {
                    if (!flowView.UserInput) {
                        flowView.UserInput = {}
                    }
                    flowView.UserInput.Other = dataToSave
                }
                return
            case "PPP.Questions":
                flowView.UserInput = flowView.UserInput || {}
                flowView.UserInput[id] = dataToSave
                return
            default:
                break
        }
        flowView.UserInput = dataToSave
    }

    saveOwnerInfo = (coOwnerId, id, value) => {
        if (id === "Name") {
            const nameObj = getNameObj2(value)
            this.props.dispatch(addDataToStore(CO_OWNER_INFO, { ...nameObj, coOwnerId: coOwnerId }))
        } else {
            this.props.dispatch(addDataToStore(CO_OWNER_INFO, { [id]: value, coOwnerId: coOwnerId }))
        }
    }

    saveSignerInfo = (signerId, id, value) => {
        if (id === "Name") {
            const nameObj = getNameObj2(value)
            this.props.dispatch(addDataToStore(SIGNER_INFO, { ...nameObj, signerId: signerId }))
        } else {
            this.props.dispatch(addDataToStore(SIGNER_INFO, { [id]: value, signerId: signerId }))
        }
    }

    validateFlow = () => {
        var validation = {
            validated: false,
            message: flowView.ValidationErrorMessage ?? "Please provide an input to continue"
        }

        const personalInfo = this.props.store.personalInfo || {}
        const businessInfo = this.props.store.businessInfo || {}

        var coOwnerInfo = this.getCurrentCoOwner()
        var signer = this.getCurrentSigner()

        const coOwners = this.props.store.coOwnersInfo || []
        const signers = this.props.store.signersInfo || []

        switch (flowView.OnboardingStep) {
            case "PersonalInfo.Edit":
                const address = personalInfo.address || {}
                console.log("personal address", address)
                if (!personalInfo.firstName || !personalInfo.lastName) {
                    validation.message = "Please provide a first and last name"
                } else if (!personalInfo.dob) {
                    validation.message = "Please provide your date of birth"
                } else if (!address.line1 || address.line1.includes("undefined") || !address.city || !address.countrySubDivisionCode || !address.postalCode || !address.country) {
                    validation.message = "Please provide your full address"
                } else {
                    validation.validated = true
                }
                break
            case "PersonalInfo.Resident":
                validation.validated = personalInfo.resident
                break
            case "PersonalInfo.SSN":
                const ssn = this.props.store.personalInfo.ssn
                //if (ssn && ssn.match(/^[0-9]+(-[0-9]+)+$/)) {
                if (ssn && ssn.match(/^\d{3}-\d{2}-\d{4}$/)) {
                    validation.validated = true
                }
                break
            case "OwnerInfo.SSN": // TODO
                //if (ssn && ssn.match(/^[0-9]+(-[0-9]+)+$/)) {
                if (coOwnerInfo.ssn && coOwnerInfo.ssn.match(/^\d{3}-\d{2}-\d{4}$/)) {
                    validation.validated = true
                } else if(this.getCurrentCoOwner().resident == false) {
                    // Optional for non-residents. Add default value
                    var defaultValue = ""
                    _.times(9, () => defaultValue += this.getCurrentCoOwner().coOwnerId)
                    console.log("SETTING SSN", defaultValue, this.getCurrentCoOwner().coOwnerId, this.getCurrentCoOwner(), this.props.store.coOwnersInfo)
                    if((this.getCurrentCoOwner().ssn || "").length != 9) {
                        this.props.dispatch(addDataToStore(CO_OWNER_INFO, { ssn: defaultValue, coOwnerId: this.getCurrentCoOwner().coOwnerId }))
                    }
                    validation.validated = true
                }
                break
            case "PersonalInfo.PatriotAct":
                validation.validated = true
                break
            case "PersonalInfo.AuthorizedSigner":
                validation.validated = true
                break
            case "PersonalInfo.ControlPerson":
                validation.validated = personalInfo.controlPerson
                break
            case "OwnerInfo.ControlPerson":
                var selectedControlPerson = false

                if (this.props.store.personalInfo.controlPerson) {
                    selectedControlPerson = true
                }

                coOwners.forEach(owner => {
                    if (owner.controlPerson) {
                        selectedControlPerson = true
                    }
                })

                if (selectedControlPerson) {
                    validation.validated = true
                } else {
                    validation.message = "Please select the control person for your company"
                }
                break
            case "OwnerInfo.AllOwners":
                var allOwnersError = false
                var combinedOwnership = 0

                if (personalInfo.ownershipPercentage) combinedOwnership += Number(personalInfo.ownershipPercentage)

                for (var i = 0; i < coOwners.length; i++) {
                    if (!coOwners[i].controllingParty) {
                        var owner = coOwners[i]
                        if (!owner.firstName || !owner.lastName) {
                            allOwnersError = true
                            validation.message = "Please provide a first and last name for each owner"
                            break
                        } else if (!owner.ownershipPercentage) {
                            allOwnersError = true
                            validation.message = "Please provide ownership for each owner"
                            break
                        } else if (owner.ownershipPercentage.isNaN) {
                            allOwnersError = true
                            validation.message = "Ownership must be a valid number"
                            break
                        } else if (Number(owner.ownershipPercentage) < 25) {
                            allOwnersError = true
                            validation.message = "Ownership must be 25% or more"
                            break
                        } else if (Number(owner.ownershipPercentage) > 100) {
                            allOwnersError = true
                            validation.message = "Ownership cannot exceed 100%"
                            break
                        }

                        combinedOwnership += Number(owner.ownershipPercentage)
                    }
                }

                if (!allOwnersError) {
                    if (combinedOwnership > 100) {
                        validation.message = "Combined % Ownership cannot exceed 100"
                    } else {
                        validation.validated = true
                    }
                }

                break
            case "PersonalInfo.Id":
                if (!personalInfo.idType) {
                    validation.message = "Please select a document type"
                } else if (!personalInfo.idNumber) {
                    validation.message = "Please enter your document ID number"
                } else if (!personalInfo.idExpiryDate) {
                    validation.message = "Please select an expiry date"
                } else if (!personalInfo.idPlaceOfIssuance) {
                    validation.message = "Please enter a place of issuance"
                } else if (personalInfo.idDocumentUrl) {
                    validation.validated = true
                }
                break
            case "PersonalInfo.DriversLicenseFront":
                if (personalInfo.driversLicenseFront) {
                    validation.validated = true
                }
                break
            case "PersonalInfo.DriversLicenseBack":
                if (personalInfo.driversLicenseBack) {
                    validation.validated = true
                }
                break
            case "PersonalInfo.BusinessTitle":
                if (personalInfo.businessTitle === "Other") {
                    if (!personalInfo.businessTitleOther || personalInfo.businessTitleOther === '') {
                        validation.message = "Please provide your title"
                    } else {
                        validation.validated = true
                    }
                } else {
                    validation.validated = true
                }
                break
            case "OwnerInfo.BusinessTitle":
                if (coOwnerInfo.businessTitle === "Other") {
                    if (!coOwnerInfo.businessTitleOther || coOwnerInfo.businessTitleOther === '') {
                        validation.message = "Please provide a title for each owner"
                    } else {
                        validation.validated = true
                    }
                } else {
                    validation.validated = true
                }
                break
            case "BusinessInfo.IncorpType":
                if (!businessInfo.incorporationType) {
                    validation.validated = false
                } else if (!this.state.incorpTypeDocumentMap[businessInfo.incorporationType].find(doc => doc.uploaded === true)) {
                    validation.message = "Please upload one of the required documents"
                } else {
                    validation.validated = true
                }
                break
            case "BusinessInfo.OC":
                validation.validated = true
                break
            case "BusinessInfo.Edit":
                console.log("BusinessInfo.OC", businessInfo.oc)
                if (!businessInfo.oc) {
                    this.setManualReviewRequired("Could not find business in open corporates.")
                }
                if ((businessInfo.oc || {}).inactive) {
                    this.setManualReviewRequired("Business is inactive.")
                }
                const businessAddress = businessInfo.physicalAddress || {}
                if (!businessInfo.name) {
                    validation.message = "Please provide your business name"
                } else if (!businessInfo.incorporationDate) {
                    validation.message = "Please provide a incorporation date"
                } else if (!businessInfo.incorporationState) {
                    validation.message = "Please provide a incorporation state"
                } else if (!businessAddress.line1 || !businessAddress.city || !businessAddress.countrySubDivisionCode || !businessAddress.postalCode || !businessAddress.country) {
                    validation.message = "Please provide your business address"
                } else {
                    validation.validated = true
                }
                break
            case "BusinessInfo.MailingAddress":
                const bInfo = this.props.store.businessInfo || {}
                const mailingAddress = bInfo.mailingAddress || {}
                if (!mailingAddress.line1 || !mailingAddress.city || !mailingAddress.countrySubDivisionCode || !mailingAddress.postalCode || !mailingAddress.country) {
                    validation.message = "Please provide a valid mailing address"
                } else {
                    validation.validated = true
                }
                break
            case "BusinessInfo.Industry":
                var naics6 = (businessInfo || {}).naics6 || ""
                validation.validated = (naics6.length == 6)
                break
            case "BusinessInfo.IndustryConsent":
                validation.validated = !!businessInfo.industryConsentChecked
                break
            case "BusinessInfo.AnnualRevenue":
                if (!parseFloat(businessInfo.revenue)) {
                    validation.message = "Please provide a valid amount"
                } else {
                    validation.validated = true
                }
                break
            case "BusinessInfo.EmployeeCount":
                if (!parseInt(businessInfo.employeeCount)) {
                    validation.message = "Please provide a valid number"
                } else {
                    validation.validated = true
                }
                break
            case "BusinessInfo.Description":
                if (!businessInfo.description || businessInfo.description.length < 60) {
                    validation.message = "Business description must be at least 60 characters"
                } else if (!(businessInfo.revenue)) {
                    validation.message = "Please provide a valid revenue amount"
                } else if (!parseInt(businessInfo.employeeCount)) {
                    validation.message = "Please provide a valid number for employee count"
                }
                // else if (!businessInfo.sourceDescription || businessInfo.sourceDescription.length < 50) {
                //     validation.message = "Source description must be at least 50 characters"
                // } else if (!businessInfo.paymentDescription || businessInfo.paymentDescription.length < 50) {
                //     validation.message = "Payment description must be at least 50 characters"
                // } 
                else {
                    validation.validated = true
                }
                break
            case "BusinessInfo.AccountActivity":
                if (!businessInfo.moneyInDescription) {
                    validation.message = "Please provide a description of your incoming activity"
                } else if (!parseFloat(businessInfo.moneyInLow) || parseFloat(businessInfo.moneyInLow) < 0.0) {
                    validation.message = "Please provide a valid low amount for money in"
                } else if (!parseFloat(businessInfo.moneyInHigh) || parseFloat(businessInfo.moneyInHigh) < 0.0) {
                    validation.message = "Please provide a valid high amount for money in"
                } else if (parseFloat(businessInfo.moneyInLow) > parseFloat(businessInfo.moneyInHigh)) {
                    validation.message = "The value for high money in cannot be lower than the low amount"
                } else if (!businessInfo.moneyOutDescription) {
                    validation.message = "Please provide a description of your outgoing activity"
                } else if (!parseFloat(businessInfo.moneyOutLow) || parseFloat(businessInfo.moneyOutLow) < 0.0) {
                    validation.message = "Please provide a valid low amount for money out"
                } else if (!parseFloat(businessInfo.moneyOutHigh) || parseFloat(businessInfo.moneyOutHigh) < 0.0) {
                    validation.message = "Please provide a valid high amount for money out"
                } else if (parseFloat(businessInfo.moneyOutLow) > parseFloat(businessInfo.moneyOutHigh)) {
                    validation.message = "The value for high money out cannot be lower than the low amount"
                } else {
                    validation.validated = true
                }
                break
            case "BusinessInfo.DBA":
                validation.validated = !!businessInfo.dba
                break
            case "BusinessInfo.IncorpLetter":
                if (businessInfo.incorporationLetter) {
                    validation.validated = true
                }
                break
            case "BusinessInfo.EIN":
                if (!businessInfo.einDocument) {
                    validation.message = "Please upload your EIN document"
                } else if (!(businessInfo.ein && businessInfo.ein.length === 10)) {
                    validation.message = "Please enter a valid EIN number"
                } else {
                    validation.validated = true
                }
                break
            case "BusinessInfo.OnlinePresence":
                validation.validated = isValidUrl(businessInfo.onlinePresence)
                break
            case "BusinessInfo.Phone":
                if (businessInfo.phone && businessInfo.phone.match(/^[0-9]+(-[0-9]+)+$/)) {
                    validation.validated = true
                }
                break
            case "BusinessInfo.OwnershipPercentage":
            case "OwnerInfo.OwnershipPercentage":
                if (parseFloat(personalInfo.ownershipPercentage) <= 100) {
                    validation.validated = true
                }
                break
            case "OwnerInfo.OwnershipPercentage":
                if (parseFloat(coOwnerInfo.ownershipPercentage) <= 100) {
                    validation.validated = true
                }
                break
            case "OwnerInfo.Name":
                if (!coOwnerInfo.firstName || !coOwnerInfo.lastName) {
                    validation.message = "Please provide a first and last name"
                } else {
                    validation.validated = true
                }
                break
            case "OwnerInfo.Edit":
                const coOwnerAddress = coOwnerInfo.address || {}
                // if (coOwnerInfo.controlPerson && (!coOwnerInfo.firstName || !coOwnerInfo.lastName)) {
                //     validation.message = "Please provide a full name"
                // } else 
                if (!coOwnerInfo.dob) {
                    validation.message = "Please provide a date of birth"
                } else if (!coOwnerAddress.line1 || !coOwnerAddress.city || !coOwnerAddress.countrySubDivisionCode || !coOwnerAddress.postalCode || !coOwnerAddress.country) {
                    validation.message = "Please provide a full address"
                } else {
                    validation.validated = true
                }
                break
            case "OwnerInfo.Email":
                if (isEmailValid(coOwnerInfo.email)) {
                    validation.validated = true
                }
                break
            case "OwnerInfo.Phone":
                if (coOwnerInfo.phone && coOwnerInfo.phone.match(/^[0-9]+(-[0-9]+)+$/)) {
                    validation.validated = true
                }
                break
            case "OwnerInfo.Resident":
                validation.validated = this.getCurrentCoOwner().resident // To allow non-resident's to continue: (typeof(this.getCurrentCoOwner().resident) == 'boolean')
                break
            case "Signers.AllSigners":
                var allSignersError = false

                for (var i = 0; i < signers.length; i++) {
                    var signer = signers[i]
                    if (!signer.firstName || !signer.lastName) {
                        allSignersError = true
                        validation.message = "Please provide a first and last name for each signer"
                        break
                    }
                    if (signer.businessTitle === "Other") {
                        if (!signer.businessTitleOther || signer.businessTitleOther === '') {
                            allSignersError = true
                            validation.message = "Please provide a title for each signer"
                            break
                        }
                    }
                }

                if (!allSignersError) validation.validated = true

                break
            case "Signers.SSN":
                //if (ssn && ssn.match(/^[0-9]+(-[0-9]+)+$/)) {
                if (signer.ssn && signer.ssn.match(/^\d{3}-\d{2}-\d{4}$/)) {
                    validation.validated = true
                } else if(this.getCurrentSigner().resident == false) {
                    // Optional for non-residents. Add default value
                    var defaultValue = ""
                    var uInt = this.props.store.coOwnersInfo.length + this.getCurrentSigner().signerId
                    _.times(9, () => defaultValue += uInt)
                    console.log("SETTING SSN", uInt, defaultValue, this.getCurrentSigner().signerId, this.getCurrentSigner(), this.props.store.coOwnersInfo, this.props.store.signersInfo)
                    if((this.getCurrentSigner().ssn || "").length != 9) {
                        this.props.dispatch(addDataToStore(SIGNER_INFO, { ssn: defaultValue, signerId: this.getCurrentSigner().signerId }))
                    }
                    validation.validated = true
                }
                break
            case "Signers.Phone":
                if (signer.phone && signer.phone.match(/^[0-9]+(-[0-9]+)+$/)) {
                    validation.validated = true
                }
                break
            case "Signers.Citizenship":
                validation.validated = signer.countryOfCitizenship
                break
            case "Signers.Resident":
                validation.validated = signer.resident // PREV: (typeof(signer.resident) == 'boolean') // To allow non-resident's to continue
                break
            case "Signers.Edit":
                const signerAddress = signer.address || {}
                if (!signer.dob) {
                    validation.message = "Please provide a date of birth"
                } else if (!signerAddress.line1 || !signerAddress.city || !signerAddress.countrySubDivisionCode || !signerAddress.postalCode) {
                    validation.message = "Please provide a full address"
                } else {
                    validation.validated = true
                }
                break
            case "Signers.Email":
                if (isEmailValid(signer.email)) {
                    validation.validated = true
                }
                break
            case "Signers.Id":
                if (!signer.idType) {
                    validation.message = "Please select a document type"
                } else if (!signer.idNumber) {
                    validation.message = "Please enter a document ID number"
                } else if (!signer.idExpiryDate) {
                    validation.message = "Please select an expiry date"
                } else if (!signer.idPlaceOfIssuance) {
                    validation.message = "Please enter a place of issuance"
                } else if (signer.idDocumentUrl) {
                    validation.validated = true
                }
                break
            case "OwnerInfo.Id":
                if (!coOwnerInfo.idNumber) {
                    validation.message = "Please enter a document ID number"
                } else if (!coOwnerInfo.idExpiryDate) {
                    validation.message = "Please select an expiry date"
                } else if (!coOwnerInfo.idPlaceOfIssuance) {
                    validation.message = "Please enter a place of issuance"
                } else if (coOwnerInfo.idDocumentUrl) {
                    validation.validated = true
                }
                break
            case "Signers.DriversLicenseFront":
                if (signer.driversLicenseFront) {
                    validation.validated = true
                }
                break
            case "Signers.DriversLicenseBack":
                if (signer.driversLicenseBack) {
                    validation.validated = true
                }
                break
            case "SubmitInfo.InitalDeposit":
                const { initialDepositAmt, initialDepositAccountNumber, initialDepositRoutingNumber, initialDepositCardNumber, initialDepositNameOnAccount, initialDepositCVC, initialDepositExpMonthYear, initialDepositZip } = businessInfo
                var { BBConfiguration } = this.props.appStore.AppConfig || {}
                console.log("Validation InitalDeposit", initialDepositAmt, BBConfiguration.MinimumDeposit)
                if (!initialDepositAmt || (initialDepositAmt < BBConfiguration.MinimumDeposit)) {
                    validation.message = `We require a minimum opening deposit of $${toCurrency(BBConfiguration.MinimumDeposit)} to open an account`
                } else if (!initialDepositNameOnAccount) {
                    validation.message = "Account name is required"
                } else if (businessInfo.initialDepositType == "Bank transfer") {
                    if (!initialDepositAccountNumber || !initialDepositRoutingNumber) {
                        validation.message = "Account and routing numbers are required"
                    } else if (!initialDepositNameOnAccount) {
                        validation.message = "Account name is required"
                    } else {
                        validation.validated = true
                    }
                } else {
                    if (!initialDepositCardNumber || !initialDepositNameOnAccount || !initialDepositCVC || !initialDepositExpMonthYear || !initialDepositZip) {
                        validation.message = "All card info fields are required"
                    } else {
                        validation.validated = true
                    }
                }
                break
            case "SubmitInfo.OwnershipCertification":
                if (this.sigBOCert.isEmpty()) {
                    validation.message = `Please sign to continue.`
                } else {
                    validation.validated = true
                }
                break
            case "SubmitInfo.Submit":
                if (this.state.twoFactorTokenValidated === false) {
                    validation.message = "Please confirm your email address to continue."
                    // } else if (personalInfo.acknowledgementChecked !== true) {
                    //     validation.message = "Please check the acknowledgement to continue."
                } else {
                    validation.validated = true
                }
                break
            // PPP
            case "PPP.MonthlyPayroll":
            case "PPP.LoanRequest":
                if (!parseFloat(flowView.UserInput)) {
                    validation.message = "Please provide a valid number"
                } else {
                    validation.validated = true
                }
                break
            case "PPP.Employees":
                if (!parseInt(flowView.UserInput)) {
                    validation.message = "Please provide a valid number"
                } else {
                    validation.validated = true
                }
                break
            case "PPP.LoanPurpose":
                if (flowView.UserInput) {
                    if (flowView.UserInput.SelectedOptions.includes("Other") && !flowView.UserInput.Other) {
                        validation.message = "Please provide an explanantion for \"Other\""
                    } else {
                        validation.validated = true
                    }
                }
                break
            case "PPP.Questions":
                var contentItems = flowView.ContentItems
                var allQuestionsAnswered = true
                var message = "Please answer all questions"
                contentItems.forEach(item => {
                    flowView.UserInput = flowView.UserInput || {}
                    var respObj = flowView.UserInput[item.Id] || {}
                    console.log("respObj", respObj.Response)
                    if (typeof (respObj.Response) !== "boolean") {
                        allQuestionsAnswered = false
                        message = `Response missing for \"${item.Question}\"`
                    } else if (respObj.Response && item.YesExplanation && !respObj.Explanation) {
                        allQuestionsAnswered = false
                        message = `Explanation missing for \"${item.Question}\"`
                    }
                })
                validation.validated = allQuestionsAnswered
                validation.message = message
                break

            // InvoiceFinancing
            case "InvoiceFinancing.TaxReturns":
            case "InvoiceFinancing.ProfitAndLoss":
            case "InvoiceFinancing.BankStatements":
            case "InvoiceFinancing.PFStatement":
            case "InvoiceFinancing.OtherDocuments":
            case "InvoiceFinancing.Confirmation":
                validation.validated = true
                break
            default:
                if (flowView.UserInput) {
                    validation.validated = true
                }
                break
        }
        return validation
    }

    updateStoreAppState = (currentViewIndex, stepProgress, flowViewType) => {
        this.props.dispatch(addDataToStore(APP_STATE, { currentViewIndex, stepProgress, flowViewType }))
    }

    loadPrevView = () => {
        let flowViews = this.state.flowViews
        let currentViewIndex = this.state.currentViewIndex
        let stepProgress = this.state.stepProgress
        let flowView = {}
        const personalInfo = this.props.store.personalInfo || {}
        if (currentViewIndex == 0) {
            flowView = this.getPrevFlow()
        } else {
            if (flowViews[currentViewIndex].OnboardingStep.includes("BusinessInfo.IncorpType")) {
                stepProgress = 0
            }
            // skip business search step if necessary
            let steps = 1
            if (flowViews[currentViewIndex].OnboardingStep.includes("BusinessInfo.Edit") &&
                this.props.store.businessInfo.incorporationType == "Sole Proprietorship") {
                steps = 2
            }
            if (flowViews[currentViewIndex].OnboardingStep.includes("PersonalInfo.SSN") &&
                personalInfo.countryOfCitizenship === 'US') {
                steps = 2
            }
            if (flowViews[currentViewIndex].OnboardingStep.includes("OwnerInfo.Id") &&
                this.getCurrentCoOwner().countryOfCitizenship === 'US') {
                steps = 2
            }
            flowView = {
                currentViewIndex: currentViewIndex - steps,
                flowViews: this.state.flowViews,
                flowView: this.state.flowViews[currentViewIndex - steps],
                flowViewType: this.state.flowViewType,
                stepProgress,
            }
        }
        this.setState({
            ...flowView,
            showError: false, errorMsg: ""      // dismiss any validatin error, if any, for the current step
        })
        this.updateStoreAppState(flowView.currentViewIndex, flowView.stepProgress, flowView.flowViewType)
    }

    loadNextView = (steps = 1) => {
        // console.log("loadNextView STORE", this.props.store)
        var flowViews = this.state.flowViews
        var currentViewIndex = this.state.currentViewIndex + steps
        const personalInfo = this.props.store.personalInfo || {}
        const coOwnersInfo = this.props.store.coOwnersInfo || []
        var coOwnerInfo = this.getCurrentCoOwner()
        if (currentViewIndex < this.state.flowViews.length) {
            var stepProgress = this.state.stepProgress
            if (flowViews[currentViewIndex].OnboardingStep.includes("BusinessInfo.IncorpType")) {
                stepProgress = 1
            } else if (flowViews[currentViewIndex].OnboardingStep.includes("InvoiceFinancing.TaxReturns")) {
                stepProgress = this.state.primaryApplicationCompleted ? 1 : 4
            } else if (flowViews[this.state.currentViewIndex].OnboardingStep.includes("OwnerInfo.OwnershipPercentage")) {
                if (personalInfo.ownershipPercentage > 75) {
                    // Show next flow
                    const nextFlowView = this.getNextFlow()
                    this.setState(nextFlowView)
                    this.updateStoreAppState(nextFlowView.currentViewIndex, nextFlowView.stepProgress, nextFlowView.flowViewType)
                    return
                }
                // } else if (flowViews[this.state.currentViewIndex].OnboardingStep.includes("OwnerInfo.AllOwners")) {
                //     if (coOwnersInfo.length === 0) {
                //         // Show next flow
                //         const nextFlowView = this.getNextFlow()
                //         this.setState(nextFlowView)
                //         this.updateStoreAppState(nextFlowView.currentViewIndex, nextFlowView.stepProgress, nextFlowView.flowViewType)
                //         return
                //     }
            } else if (flowViews[currentViewIndex].OnboardingStep.includes("OwnerInfo.AddOwner")) {
                if (this.state.editCoOwnerId) {
                    this.setState({ editCoOwnerId: false })
                } else if (coOwnerInfo.id < this.props.store.coOwnersInfo.length - 1) {
                    // Ask for next co-owner information
                    this.props.dispatch(addDataToStore(ONB_SAVE_DATA, { coOwnerId: coOwnerInfo.id + 1 }))

                    this.setState({
                        currentViewIndex: 2,
                        flowViews: coOwnerFlowViewsData(),
                        flowView: coOwnerFlowViewsData()[2],
                        flowViewType: FLOW_VIEW_TYPE.CO_OWNER_FLOW,
                        stepProgress: 2,
                    })
                    this.updateStoreAppState(0, 2, FLOW_VIEW_TYPE.CO_OWNER_FLOW)
                    return
                }
            } else if (flowViews[this.state.currentViewIndex].OnboardingStep.includes("Signers.AddSigner")) {
                if (this.props.store.additionalSigners === "No") {
                    // Show next flow
                    const nextFlowView = this.getNextFlow()
                    this.setState(nextFlowView)
                    this.updateStoreAppState(nextFlowView.currentViewIndex, nextFlowView.stepProgress, nextFlowView.flowViewType)
                    return
                }
            }

            this.setState({
                currentViewIndex: currentViewIndex,
                flowView: flowViews[currentViewIndex],
                stepProgress: stepProgress,
            })
            this.updateStoreAppState(currentViewIndex, stepProgress, this.state.flowViewType)
        } else {
            switch (this.state.flowViewType) {
                case FLOW_VIEW_TYPE.MAIN_FLOW:
                    if (this.getTotalOwnershipPercent() < requiredOwnershipPercentage) {
                        // Ask for other co-owner information
                        this.setState({
                            currentViewIndex: 0,
                            flowViews: coOwnerFlowViewsData(),
                            flowView: coOwnerFlowViewsData()[0],
                            flowViewType: FLOW_VIEW_TYPE.CO_OWNER_FLOW,
                            stepProgress: 2,
                        })
                        this.updateStoreAppState(0, 2, FLOW_VIEW_TYPE.CO_OWNER_FLOW)
                    } else {
                        // Show next flow
                        const nextFlowView = this.getNextFlow()
                        this.setState(nextFlowView)
                        this.updateStoreAppState(nextFlowView.currentViewIndex, nextFlowView.stepProgress, nextFlowView.flowViewType)
                    }
                    break
                case FLOW_VIEW_TYPE.CO_OWNER_FLOW:
                    // Show next flow
                    const nextFlowView = this.getNextFlow()
                    this.setState(nextFlowView)
                    this.updateStoreAppState(nextFlowView.currentViewIndex, nextFlowView.stepProgress, nextFlowView.flowViewType)

                    break
                case FLOW_VIEW_TYPE.SIGNER_FLOW:
                    var signer = this.getCurrentSigner()

                    console.log('getCurrentSigner', signer)

                    if (signer.id < this.props.store.signersInfo.length - 1) {
                        // Ask for next signer information
                        this.props.dispatch(addDataToStore(ONB_SAVE_DATA, { signerId: signer.id + 1 }))

                        this.setState({
                            currentViewIndex: 2,
                            flowViews: signersFlowViewsData(),
                            flowView: signersFlowViewsData()[3],
                            flowViewType: FLOW_VIEW_TYPE.SIGNER_FLOW,
                            stepProgress: 3,
                        })
                        this.updateStoreAppState(0, 2, FLOW_VIEW_TYPE.SIGNER_FLOW)
                    } else {
                        // Show next flow
                        const nextFlowView = this.getNextFlow()
                        this.setState(nextFlowView)
                        this.updateStoreAppState(nextFlowView.currentViewIndex, nextFlowView.stepProgress, nextFlowView.flowViewType)
                    }
                    break
                default:
                    break
            }

        }
    }

    loadBusinessView() {
        const nextFlowView = {
            currentViewIndex: 0,
            flowViews: flowViewsData(),
            flowView: flowViewsData()[0],
            flowViewType: FLOW_VIEW_TYPE.MAIN_FLOW,
            stepProgress: 2
        }
        this.setState(nextFlowView)
        this.updateStoreAppState(nextFlowView.currentViewIndex, nextFlowView.stepProgress, nextFlowView.flowViewType)
    }

    // Only bank account onboarding comes here
    getPrevFlow = () => {
        const { flowViewType } = this.state
        if (flowViewType === FLOW_VIEW_TYPE.CO_OWNER_FLOW || flowViewType === FLOW_VIEW_TYPE.SUBMIT_APPLICATION_FLOW) {
            return {
                currentViewIndex: flowViewsData().length - 1,
                flowViews: flowViewsData(),
                flowView: flowViewsData()[flowViewsData().length - 1],
                flowViewType: FLOW_VIEW_TYPE.MAIN_FLOW,
                stepProgress: 1
            }
        } else {
            // FLOW_VIEW_TYPE.SUBMIT_MULTI_OWNER_APPLICATION_FLOW
            return {
                currentViewIndex: coOwnerFlowViewsData().length - 1,
                flowViews: coOwnerFlowViewsData(),
                flowView: coOwnerFlowViewsData()[coOwnerFlowViewsData().length - 1],
                flowViewType: FLOW_VIEW_TYPE.CO_OWNER_FLOW,
                stepProgress: 2
            }
        }
    }

    getNextFlow = () => {
        // Get the type of application from the url params

        switch (this.state.applicationType) {
            case "ppp":
                return {
                    currentViewIndex: 0,
                    flowViews: pppApplication(),
                    flowView: pppApplication()[0],
                    flowViewType: FLOW_VIEW_TYPE.PPP_FLOW,
                    stepProgress: 3,
                }
                break
            case "invoice_financing":
                return {
                    currentViewIndex: 0,
                    flowViews: invoiceFinancingApplication(),
                    flowView: invoiceFinancingApplication()[0],
                    flowViewType: FLOW_VIEW_TYPE.INVOICE_FINANCING_FLOW,
                    stepProgress: 3,
                }
                break
            default:
                // The default is for bank account onboarding
                if (this.state.flowViewType === FLOW_VIEW_TYPE.MAIN_FLOW) {
                    return {
                        currentViewIndex: 0,
                        flowViews: submitSingleOwnerApplicationFlowViewsData(),
                        flowView: submitSingleOwnerApplicationFlowViewsData()[0],
                        flowViewType: FLOW_VIEW_TYPE.SUBMIT_APPLICATION_FLOW,
                        stepProgress: 3,
                    }
                } else if (this.state.flowViewType === FLOW_VIEW_TYPE.CO_OWNER_FLOW) {
                    return {
                        currentViewIndex: 0,
                        flowViews: signersFlowViewsData(),
                        flowView: signersFlowViewsData()[0],
                        flowViewType: FLOW_VIEW_TYPE.SIGNER_FLOW,
                        stepProgress: 3,
                    }
                } else if (this.state.flowViewType === FLOW_VIEW_TYPE.SIGNER_FLOW) {
                    return {
                        currentViewIndex: 0,
                        flowViews: submitMultiOwnerApplicationFlowViewsData(),
                        flowView: submitMultiOwnerApplicationFlowViewsData()[0],
                        flowViewType: FLOW_VIEW_TYPE.SUBMIT_MULTI_OWNER_APPLICATION_FLOW,
                        stepProgress: 4,
                    }
                }
                break
        }
    }

    addOwner = () => {
        // Ask for other co-owner information
        this.setState({
            currentViewIndex: 1,
            flowViews: coOwnerFlowViewsData(),
            flowView: coOwnerFlowViewsData()[1],
            flowViewType: FLOW_VIEW_TYPE.CO_OWNER_FLOW,
            coOwnerId: '',
        })
    }

    addControlPerson = () => {
        // Ask for other co-owner information
        this.setState({
            currentViewIndex: 2,
            flowViews: coOwnerFlowViewsData(),
            flowView: coOwnerFlowViewsData()[2],
            flowViewType: FLOW_VIEW_TYPE.CO_OWNER_FLOW,
            coOwnerId: '',
        })
        this.updateStoreAppState(2, 2, FLOW_VIEW_TYPE.CO_OWNER_FLOW)
    }

    getTotalOwnershipPercent = () => {
        var total = this.props.store.personalInfo.ownershipPercentage || 0
        const coOwnersInfo = this.props.store.coOwnersInfo || []
        coOwnersInfo.forEach(owner => {
            total += owner.ownershipPercentage || 0
        })
        return total
    }
}

function mapStateToProps(state) {
    return {
        store: state.onboardingAppReducer,
        appStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return { dispatch }
}

export default  withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)))