import React, { useState } from 'react'
import { withTheme } from 'styled-components'

import { Text } from '../../Reusable/Text'
import { Image } from '../../Reusable/Image'
import { Flex, FlexColumn } from '../../Reusable/Container'

// Images
// import Lock from '../../../Images/lock-selected.png'
// import LockLight from '../../../Images/LockLight.png'
import hexToRgba from 'hex-to-rgba'


const CardSelector = ({ debitCard, ...props }) => {
    var { selected, status } = debitCard

    return (
        <FlexColumn key={debitCard.cardId} {...props} between centerVertically className={selected ? 'cards-selector-selected-back' : 'debit-card'} onClick={() => props.onClick(debitCard)} style={{ height: 85,  minWidth: 266, padding: 24 }} fullHeight gap='8px'>
            <Flex between fullWidth>
                <Text size='16px' capitalize primary={false} weight={selected ? 600 : 400} color={selected ? hexToRgba(props.theme.colors.primary2, 0.8) : hexToRgba(props.theme.colors.primary2, 0.7)}>{["VirualPan", "VirtualPan"].includes(debitCard.paymentInstrument) ? 'Virtual' : 'Physical'}</Text>
                {
                    (status === 'Closed') &&
                    <Flex start centerHorizontally gap='8px'>
                        <Text size='14px'
                            capitalize primary={false} weight={selected ? 500 : 400}
                            color={selected ? hexToRgba(props.theme.colors.primary2, 0.9) : hexToRgba(props.theme.colors.primary2, 0.5)}
                        >
                            Closed
                        </Text>
                    </Flex>
                }
                {
                    (status === 'Suspended') &&
                    <Flex start centerHorizontally gap='8px'>
                        {/* <Image src={selected ? Lock : LockLight} /> */}
                        <Text size='14px'
                            capitalize primary={false} weight={selected ? 500 : 400}
                            color={selected ? hexToRgba(props.theme.colors.primary2, 0.9) : hexToRgba(props.theme.colors.primary2, 0.5)}
                        >
                            Locked
                        </Text>
                    </Flex>
                }
                <Text size='14px'
                    capitalize primary={false} weight={selected ? 500 : 400}
                    color={selected ? hexToRgba(props.theme.colors.primary2, 0.9) : hexToRgba(props.theme.colors.primary2, 0.5)}
                >
                    •••• {(debitCard.status !== 'Unactivated') ? debitCard.last4 : '••••'}
                </Text>
            </Flex>
            
        </FlexColumn>
    )
}

export default withTheme(CardSelector)