import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled, { withTheme } from 'styled-components'
import { CardContainer } from '../Reusable/CardContainer'
import { Flex, FlexColumn } from '../../Reusable/Container'
import BusinessLogo from '../../Reusable/BusinessLogo'
import { Text } from '../../Reusable/Text'
import { getDateInLocalTZ, getGreeting } from '../../../Utils/util'
import moment from 'moment'
import hexToRgba from 'hex-to-rgba'
import { Badge, Dropdown, Menu } from 'antd'
import { ImageButton, TextButton } from '../../Reusable/Button'
import AlertIcon from "../../../Images/alert.png"
import ArrowRight from "../../../Images/arrow-right.png"
import Logout from "../../../Images/logout-blue.png"
import AionLogo, { Image } from '../../Reusable/Image'
import ProfileImage from '../../Settings/Profile/UserProfile/ProfileImage'
import { doSignout } from '../../../Utils/api'
import TopNav from '../../Reusable/TopNav'

class UserWelcome extends Component {
    state = {}

    signout = () => {
        doSignout(this.props.history, this.props.dispatch)
    }

    render() {
        const { store, theme } = this.props
        const { notification, notificationText } = this.state

        const { UserInfo, business, businessSubscriptionPlan } = store
        const { businessProfile } = business || {}
        const { businessInfo } = businessProfile || {}

        return (
            <CardContainer small>
                <FlexColumn gap='16px' style={{ padding: '20px 40px 20px' }}>
                    <TopNav
                        history={this.props.history} 
                        signout={() => this.signout()} 
                        notification={notification}
                        notificationText={notificationText} 
                        location={this.props.location}
                        miniNav={true}
                    />
                    {/* <Flex start gap='16px' > */}
                        <Text weight='600' heading size='28px' style={{ marginTop: '25px' }}>
                            {getGreeting()}<br/>{UserInfo.FirstName}
                        </Text>
                    {/* </Flex> */}
                    <FlexColumn gap='16px' style={{ marginTop: '50px' }}>
                        <Flex start centerHorizontally gap='16px' style={{ marginTop: '25px' }}>
                            <BusinessLogo hideupload size={80} borderRadius='20px' border={`2px solid ${hexToRgba(theme.colors.primary2, 0.15)}`} />
                            <Text weight='500' size='20px'>{businessInfo.name}</Text>
                        </Flex>
                        
                        <Flex start gap='8px'>
                            <Text lightText size='16px'>{getDateInLocalTZ(moment())}</Text>
                            <Text lightText size='16px'>•</Text>
                            <Text lightText size='16px'>Subscription: {businessSubscriptionPlan?.currentPlan}</Text>
                        </Flex>
                    </FlexColumn>
                </FlexColumn>
            </CardContainer>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(UserWelcome)))