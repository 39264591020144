import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { Switch, Select, Tooltip, Steps, Space } from 'antd'
import { withTheme } from 'styled-components'
import { WarningFilled, InfoCircleOutlined, DeleteOutlined } from '@ant-design/icons'
import update from 'immutability-helper'
import { Image } from '../../Reusable/Image'


// Components
import { Flex, Container } from '../../Reusable/Container'
import { Button } from '../../Reusable/Refresh/Button'
import { TextButton } from '../../Reusable/Button'
import { Text } from '../../Reusable/Text'
import { LabeledInput } from '../../Reusable/Input'
import { ErrorAlert } from '../../Reusable/Alert'

// Util
import {
    isEmailValid,
    defaultPaymentTerms,
    addressObjectToStr,
    getResourcePerm,
    capitalizeWords,
    getNameObj,
    getValidFullName,
    formatPhoneText
} from '../../../Utils/util'

const { Step } = Steps
const { Option } = Select

class AddNewCustomer extends Component {

    constructor(props) {
        super(props)

        var customer = { companyName: this.props.customerName, displayName: this.props.customerName, website: "" }
        customer.primaryEmailAddress = customer.primaryEmailAddress || { address: null }
        customer.invoiceRecipientEmail = customer.invoiceRecipientEmail || []

        this.state = {
            customer: customer,
            paymentTermsItems: defaultPaymentTerms,
            saveLoading: false,
        }
    }

    handleTextChange = (event) => {
        var val
        switch (event.target.id) {
            case "name":
                var name = capitalizeWords(event.target.value)
                var nameObj = getNameObj(getValidFullName(name))
                val = { firstName: { $set: nameObj.FirstName }, lastName: { $set: nameObj.LastName } }
                break
            case "companyName":
                var captitalizedName = { $set: (event.target.value) }
                val = { [event.target.id]: captitalizedName, displayName: captitalizedName, printOnCheckName: captitalizedName }
                break
            case "primaryEmailAddress":
                val = { [event.target.id]: { address: { $set: event.target.value } } }
                break
            case "phone":
                val = { phone: { $set: formatPhoneText(event.target.value) } }
                break
            case "webSite":
                val = { website: { $set: (event.target.value) } }
                break
            default:
                val = { [event.target.id]: { $set: event.target.value } }
                break
        }
        console.log("handleTextChange update", this.state.customer, val)
        this.setState({
            customer: update(this.state.customer, val)
        })
    }

    handlePaymentTermsSelect = (value, option) => {
        var { customer } = this.state

        const term = this.state.paymentTermsItems.find(x => x.id === value)
        if (term) {
            customer.paymentTerms = term.name
            customer.termsId = term.id
            value = term.dueDays
        } else {
            customer.paymentTerms = value
        }

        this.setState({ customer })
    }

    handlePhoneChange = (event) => {
        this.setState({
            customer: update(this.state.customer, { phone: { $set: formatPhoneText(event.target.value) } })
        })
    }

    handleEmailChange = (event) => {
        this.setState({
            customer: update(this.state.customer, { invoiceRecipientEmail: { [event.target.getAttribute('index')]: { $set: event.target.value } } })
        })
    }

    handleAddEmail = () => {
        this.setState({
            customer: update(this.state.customer, { invoiceRecipientEmail: { $push: [null] } })
        })
    }

    handleDeleteEmail = (event) => {
        this.setState({
            customer: update(this.state.customer, { invoiceRecipientEmail: { $splice: [[[event.currentTarget.getAttribute('index')], 1]] } })
        })
    }

    handleShipAddrSwitch = (checked) => {
        this.setState({ makeShipAddrAsBillAddr: checked })
        var stateToUpdate = {
            makeShipAddrAsBillAddr: checked
        }
        if (checked) stateToUpdate.customer = update(this.state.customer, { "shippingAddress": { $set: this.state.customer.billingAddress } })
        this.setState(stateToUpdate)
    }

    handleParentCustomerSelect = (value, option) => {
        this.setState({
            customer: update(this.state.customer, { parentCustomerId: { $set: value }, parentCustomerName: { $set: option.name } })
        })
    }

    getLocation = (location, addressField) => {
        if (!location) {
            if (addressField === 'billingAddress') {
                this.setState({
                    customer: update(this.state.customer, {
                        billingAddress: {
                            $set: {
                                line1: null,
                                line2: null,
                                city: null,
                                countrySubDivisionCode: null,
                                postalCode: null
                            }
                        }
                    })
                })
            } else {
                this.setState({
                    customer: update(this.state.customer, {
                        shippingAddress: {
                            $set: {
                                line1: null,
                                line2: null,
                                city: null,
                                countrySubDivisionCode: null,
                                postalCode: null
                            }
                        }
                    })
                })
            }
        }
        else {
            if (addressField === 'billingAddress') {
                this.setState({
                    customer: update(this.state.customer, {
                        billingAddress: {
                            $set: {
                                line1: location.line1,
                                line2: location.line2,
                                city: location.city,
                                countrySubDivisionCode: location.countrySubDivisionCode,
                                postalCode: location.postalCode
                            }
                        }
                    })
                })
            } else {
                this.setState({
                    customer: update(this.state.customer, {
                        shippingAddress: {
                            $set: {
                                line1: location.line1,
                                line2: location.line2,
                                city: location.city,
                                countrySubDivisionCode: location.countrySubDivisionCode,
                                postalCode: location.postalCode
                            }
                        }
                    })
                })
            }
        }
    }

    onNext = () => {
        const { customer, customerName } = this.state
        const phone = customer.phone || ""
        const primaryEmailAddress = customer.primaryEmailAddress || {}
        const otherEmails = customer.invoiceRecipientEmail || []

        if (primaryEmailAddress.address != null && !isEmailValid(primaryEmailAddress.address)) {
            ErrorAlert({ description: "Please enter a valid contact email" })
            return
        }

        if (phone !== "" && phone.length !== 12) {
            ErrorAlert({ description: "Please enter a valid 10 digit US phone number" })
            return
        }

        var otherEmailsValidated = true
        var invalidEmail = ""
        otherEmails.map(item => {
            if (!isEmailValid(item || "")) {
                otherEmailsValidated = false
                invalidEmail = item
            }
        })
        if (!otherEmailsValidated) {
            ErrorAlert({ description: `${invalidEmail} is an invalid email, please update it.` })
            return
        }

        this.setState({ saveLoading: true })

        this.props.onNext(customer, () => this.setState({ saveLoading: false }))
    }

    render() {
        const { theme, done } = this.props
        const { currentStep, newCustomers, customer, saveLoading, customerName } = this.state
        var { companyName, displayName, webSite, firstName, lastName, notes, fullyQualifiedName, billingAddress, shippingAddress, phone, paymentTerms, parentCustomerName, billWithParent, invoiceRecipientEmail, primaryEmailAddress } = customer

        var { ActiveCustomers } = this.props.receivablesStore
        ActiveCustomers = ActiveCustomers || []

        return (
            <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                <Container shadow style={{ width: "100%" }}>
                    <Flex between>
                        <div style={{ width: "50%", marginRight: 12 }}>
                            <LabeledInput
                                autoFocus
                                label='Name'
                                id="companyName"
                                key="companyName"
                                placeholder="Acme Inc."
                                value={this.props.customerName}
                                disabled
                            // onChange={this.handleTextChange}
                            />
                        </div>
                        <div style={{ width: "50%", marginLeft: 12 }}>
                            <LabeledInput
                                customlabelcomponent={
                                    <Flex between centerHorizontally style={{ width: '100%', marginBottom: 8 }}>
                                        <Text color='#7384AA' size='14px' weight={400}>Parent Company</Text>
                                        <Flex start centerHorizontally gap='8px'>
                                            <Text color='#7384AA' size='14px' weight={400}>Bill with parent?</Text>
                                            <Switch size="small" defaultChecked={this.state.customer.billWithParent} onChange={(checked) => this.setState({ customer: update(customer, { billWithParent: { $set: checked } }) })} />
                                        </Flex>
                                    </Flex>
                                }
                                type="select"
                                className="no-left-padding"
                                showSearch
                                disabled={!this.state.customer.billWithParent}
                                key="parentCustomer"
                                placeholder="Select parent company"
                                optionFilterProp="children"
                                value={billWithParent ? parentCustomerName : ''}
                                onChange={this.handleParentCustomerSelect}
                                filterOption={(input, option) => {
                                    return option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }}
                            >
                                {ActiveCustomers.map(item => <Option key={item.customerId} id="selectedParentCustomer" name={item.displayName || item.companyName} value={item.customerId} style={{ backgroundColor: "transparent" }}>{item.displayName || item.companyName}</Option>)}
                            </LabeledInput>
                        </div>
                    </Flex>
                    <Flex between>
                        <div style={{ width: `${100 / 3}%`, marginRight: 12 }}>
                            <LabeledInput
                                label="Contact Name"
                                labelcolor={theme.colors.secondary3}
                                id="fullyQualifiedName"
                                key="fullyQualifiedName"
                                placeholder="Tony Adams"
                                defaultValue={fullyQualifiedName}
                                onChange={this.handleTextChange}
                                noAsterisk
                                optional
                            />
                        </div>

                        <div style={{ width: `${100 / 3}%`, marginLeft: 12, marginRight: 12 }}>
                            <LabeledInput
                                label="Contact Email"
                                labelcolor={theme.colors.secondary3}
                                type="email"
                                id="primaryEmailAddress"
                                key="primaryEmailAddress"
                                value={primaryEmailAddress && primaryEmailAddress.address}
                                onChange={this.handleTextChange}
                                placeholder="Email"
                                noAsterisk
                                optional
                            />
                        </div>
                        <div style={{ width: `${100 / 3}%`, marginLeft: 12 }}>
                            <LabeledInput
                                type="phone"
                                label="Phone"
                                labelcolor={theme.colors.secondary3}
                                value={phone}
                                onChange={this.handlePhoneChange}
                                placeholder="Phone"
                                noAsterisk
                                optional
                            />
                        </div>
                    </Flex>
                    <Flex between>
                        <div style={{ margin: "15px 0 0", width: "50%", marginRight: 12 }}>
                            <Tooltip title="Email of the recipient(s) that will receive invoices submitted for this customer">
                                <Flex start style={{ marginBottom: 8 }}>
                                    <Text color={'#7384AA'} size='14px' weight={400} margin='0 8px 0 0'>
                                        Invoice Recipient Email
                                    </Text>
                                    <InfoCircleOutlined style={{ color: '#7384AA' }} />
                                </Flex>
                            </Tooltip>
                            <div>
                                {
                                    invoiceRecipientEmail && invoiceRecipientEmail.map((email, index) => {
                                        return (
                                            <div key={index} style={{ margin: "-15px 0 0", width: '100%' }}>
                                                <Flex start centerHorizontally style={{ width: '100%' }}>
                                                    <LabeledInput
                                                        index={index}
                                                        type="email"
                                                        value={email}
                                                        onChange={this.handleEmailChange}
                                                        placeholder="Email"
                                                        id="invoiceEmailAddress"
                                                        key={"invoiceEmailAddress" + index}
                                                        noAsterisk
                                                        optional
                                                    />

                                                    <span index={index} onClick={this.handleDeleteEmail} style={{ marginLeft: 12 }}>
                                                        <DeleteOutlined style={{ cursor: 'pointer', color: theme.colors.primary }} />
                                                    </span>
                                                </Flex>
                                            </div>
                                        )
                                    })
                                }
                                <TextButton onClick={this.handleAddEmail} tagpreset={theme.colors.primary} text='Add email' />
                            </div>
                        </div>
                        <div style={{ width: "50%", marginLeft: 12 }}>
                            <LabeledInput
                                label="Payment Terms"
                                labelcolor={theme.colors.secondary3}
                                id="paymentTerms"
                                key="paymentTerms"
                                type="select"
                                className="no-left-padding"
                                placeholder="Select"
                                noAsterisk
                                optional
                                value={paymentTerms}
                                onChange={this.handlePaymentTermsSelect}
                                dropdownRender={menu => (
                                    <>
                                        {menu}
                                    </>
                                )}
                            >
                                {this.state.paymentTermsItems.map(item => <Option key={item.id || item} value={item.id || item}>{item.name || item}</Option>)}
                            </LabeledInput>
                        </div>
                    </Flex>

                    <Flex between>
                        <div style={{ width: "50%", marginRight: 12 }}>
                            <LabeledInput
                                id="billingAddress"
                                key="billingAddress"
                                label="Billing Address"
                                labelcolor={theme.colors.secondary3}
                                type="location"
                                getLocation={(loc) => this.getLocation(loc, 'billingAddress')}
                                Line2
                                address={billingAddress}
                                noAsterisk
                                optional
                                value={billingAddress && billingAddress.line1 ?
                                    { line1: addressObjectToStr(billingAddress), line2: billingAddress.line2 } : null
                                }
                                placeholder="Address"
                            />
                        </div>
                        <div style={{ width: "50%", margin: '14px 0 0 12px' }}>
                            <Flex between centerHorizontally style={{ width: '100%', marginBottom: 8 }}>
                                <Text color='#7384AA' size='14px' weight={400}>Shipping Address</Text>
                                <Flex start centerHorizontally gap='8px'>
                                    <Text color='#7384AA' size='14px' weight={400}>Same as billing?</Text>
                                    <Switch size="small" defaultChecked={this.state.makeShipAddrAsBillAddr} onChange={this.handleShipAddrSwitch} />
                                </Flex>
                            </Flex>
                            <LabeledInput
                                id="shippingAddress"
                                key={"shippingAddress" + this.state.makeShipAddrAsBillAddr}
                                nomargin="nomargin"
                                type="location"
                                getLocation={(loc) => this.getLocation(loc, 'shippingAddress')}
                                Line2
                                address={shippingAddress}
                                value={shippingAddress && shippingAddress.line1 ?
                                    { line1: addressObjectToStr(shippingAddress), line2: shippingAddress.line2 } : null
                                }
                                placeholder="Address"
                            />
                        </div>
                    </Flex>

                    <Flex start style={{ marginTop: 36 }}>
                        {
                            !getResourcePerm("Support.Console").aionSystemUser &&
                            <Button onClick={() => this.onNext()} loading={saveLoading} solid text={done ? "Save & Finish" : "Save"} />
                        }
                    </Flex>
                </Container>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.creditAppReducer,
        aionStore: state.aionAppReducer,
        receivablesStore: state.receivablesAppReducer,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(AddNewCustomer)))