import React from 'react';
import { Flex } from '../../Reusable/Container';
import { toAbsCurrency } from '../../../Utils/util';
import hexToRgba from 'hex-to-rgba';

const AgingBarChart = ({ arAging, totalARBalance }) => {
    const opacity = 1;
    const agingCategories = [
        { name: 'Current', color: hexToRgba('#52B788', opacity) },
        { name: '0-30', color: hexToRgba('#4299E1', opacity) },
        { name: '31-60', color: hexToRgba('#8740F6', opacity) },
        { name: '61-90', color: hexToRgba('#1EC3FF', opacity) },
        { name: '90+', color: hexToRgba('#9370DA', opacity) }
    ];

    const getWidth = (amount) => {
        return (amount / totalARBalance) * 100;
    };

    return (
        <Flex style={{ width: '100%' }}>
            {agingCategories.map((category) => {
                const agingData = arAging.find(item => item.name === category.name);
                const width = agingData ? getWidth(agingData.total) : 0;

                return (
                    <div
                        key={category.name}
                        style={{
                            width: `${width}%`,
                            height: `8px`,
                            backgroundColor: category.color,
                            borderRadius: '4px'
                        }}
                        className="h-full"
                        title={`${category.name}: $${agingData ? toAbsCurrency(agingData.total) : 0}`}
                    />
                );
            })}
        </Flex>
    );
};

export default AgingBarChart;