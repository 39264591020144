const SYSTEM_COLORS = {
    primary: '#1199FF',
    secondary1: '#2CD1D6',
    secondary2: '#32C2F5',
    secondary3: '#1199FF',
    secondary4: '#5663E9',
    secondary5: '#DEE4EF',
    secondary6: '#7384AA',
    secondary7: '#3C508A',
    secondary8: '#041858',
    systemGray: 'rgb(142, 142, 147)',
    systemGray2: 'rgb(174, 174, 178)',
    systemGray3: 'rgb(199, 199, 204)',
    systemGray4: 'rgb(209, 209, 214)',
    systemGray5: 'rgb(229, 229, 234)',
    systemGray6: 'rgb(242, 242, 247)'
}

module.exports.SYSTEM_COLORS = SYSTEM_COLORS;

const SYSTEM_STYLES = {
    cardStyle: { borderRadius: '10px', backgroundColor: SYSTEM_COLORS.systemGray6, padding: '20px' }
}

module.exports.SYSTEM_STYLES = SYSTEM_STYLES;