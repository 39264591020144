import React, { useState } from 'react';
import { Text, Tag } from '../../Reusable/Text';
import { LabeledInput } from '../../Reusable/Input';
import { TextButton } from '../../Reusable/Button'
import { Image } from '../../Reusable/Image'
import moment from 'moment'
import { saveAs } from 'file-saver'
import { toCurrency, getDateByTZ } from '../../../Utils/util'
import { apiPOSTReq, getPreSignedS3Url } from '../../../Utils/api'
import { Flex, FlexColumn, Container, Card, CardContainer } from '../../Reusable/Container';
import Download from '../../../Images/download.png'


const PaymentInformation = ({ invoice, width, attachmentUrl, referralInfo }) => {
    var { amount } = referralInfo || {}
    var { docNumber, dueDate, attachments, balance, payments } = invoice || {}

    if (dueDate) {
        dueDate = new Date(dueDate).toISOString()
        dueDate = dueDate.split("T")[0]
    }
    dueDate = dueDate && moment(dueDate)

    const invoiceDoc = (invoice?.attachments || []).find(item => item.type == "InvoiceDoc") || {}

    return (
        <CardContainer width={width} fullWidth={!width} padding='12px 24px 24px'>
            <FlexColumn gap='24px'>
                <Text heading>Payment history</Text>
                <FlexColumn start gap='12px'>
                    <Flex fullWidth between>
                        <Text weight='700'>Date</Text>
                        <Text weight='700'>Amount</Text>
                    </Flex>

                    {
                        payments?.map((payment, index) => {
                            return <Flex fullWidth between style={{ margin: '8px 0' }}>
                                <Text>{getDateByTZ(payment?.paymentDate, null, "America/New_York")}</Text>
                                <Text weight='700'>${toCurrency(payment?.amount)}</Text>
                            </Flex>
                        })
                    }
                </FlexColumn>
            </FlexColumn>
        </CardContainer>
    );
};

export default PaymentInformation;
