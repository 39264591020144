import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import styled from 'styled-components'
import _ from 'underscore'

import { Input, Collapse } from 'antd'
import { Text, Paragraph } from '../../Reusable/Text'
// Util
import { apiGET, apiPOST } from '../../../Utils/api'
import { toCurrency, getMultiples, toNumber, getUserApps } from '../../../Utils/util'

// Components
import PageHeader from "../../Reusable/PageHeader"
import { FlexColumn, Flex, Capsule, Container } from '../../Reusable/Container'
import { Button } from '../../Reusable/Refresh/Button'
import { ErrorAlert } from '../../Reusable/Alert'
import { LabeledInput } from '../../Reusable/Input'
import ExpressFunds from './ExpressFunds'
import LimitsBalances from '../LimitsBalances'
import Clock from '../../../Images/clock.png';
import BoldBanner from '../../Reusable/BoldBanner'
import { Image } from '../../Reusable/Image'

// Actions
import { CREDIT_SAVE_DATA, addDataToStore } from '../../../Actions/actions'

const { Panel } = Collapse
const { TextArea } = Input

export const DescItem = styled.div`
    display: flex;
    justify-content: flex-end;
`

export const DescLabel = styled.span`
    fontWeight: 500;
    fontSize: 16;
`

class RequestFunds extends Component {
    constructor(props) {
        super(props)

        const userApps = getUserApps(this.props.aionStore)

        this.state = {
            isLoading: true,
            loading: false,
            grossAdvanceableExpandable: userApps.includes("ABLCredit"),
            grossAdvanceableExpanded: false,
            showBanner: true
        }
    }

    componentDidMount() {
        this.fetchFundsData()
    }

    fetchFundsData = () => {
        const userApps = getUserApps(this.props.aionStore)

        const headers = {
            businesskey: this.props.aionStore.BusinessUniqueKey
        }

        var endpoint = '/inventoryfinancing/funds/view'

        if (userApps.includes("ABLCredit")) endpoint = '/assetbasedloan/funds/view'

        apiGET(endpoint, headers, (err, resp) => {
            try {
                if (err) throw Error(err)
                var data = resp.data
                console.log(endpoint, (data))

                if (data.success) {
                    this.props.dispatch(addDataToStore(CREDIT_SAVE_DATA, { AvailableFunds: data }))
                    this.setState({ ...data })
                    this.setState({ fundsLoaded: true, isLoading: false })
                } else {
                    throw Error("We had some trouble. Please try again later.")
                }
            } catch (error) {
                alert(error.message)
            }
        })
    }

    getFundingType = (fundsrequested) => {
        const headers = {
            businesskey: this.props.aionStore.BusinessUniqueKey
        }
        apiPOST(`/financing/funds/fundingtype?fundsrequested=${fundsrequested}`, headers, { fundsrequested: fundsrequested }, (err, resp) => {
            try {
                if (err) throw Error(err)
                var data = resp.data
                console.log("financing/funds/fundingtype", (data))

                if (data.success && (data.ExpressFunding === true || data.StandardFunding === true)) {
                    this.setState({ isLoading: false, showExpressFundsModal: true, fundingData: data })
                } else {
                    throw Error("Sorry we had trouble processing your request. Please try again.")
                }
            } catch (error) {
                alert(error.message)
            }
            this.setState({ loading: false })
        })
    }

    handleAmtSelection = (amt) => {
        this.setState({ requestedAmt: amt })
    }

    handleSubmit = () => {
        const { store } = this.props
        const { AvailableFunds, ARAdvanceInfo } = store
        const { ExpressFundingBorrower } = this.state
        const requestedAmt = toNumber(this.state.requestedAmt)

        try {
            if (!AvailableFunds.AvailableFunds || AvailableFunds.AvailableFunds <= 0) throw Error("You don't have any funds available.")
            if (requestedAmt > AvailableFunds.AvailableFunds) throw Error(`Please enter an amount less than $${toCurrency(AvailableFunds.AvailableFunds)}.`)
            if (requestedAmt <= 0) throw Error("Please enter a valid amount.")
            if (isNaN(requestedAmt)) throw Error("Please enter a valid amount.")
            if (!this.state.notes) throw Error("Please enter a note.")
        } catch (error) {
            ErrorAlert({ description: error.message })
            return
        }

        if (ExpressFundingBorrower === true) {
            this.setState({ loading: true })
            this.getFundingType(requestedAmt)
        } else {
            this.setState({ showExpressFundsModal: true })
        }
    }

    render() {
        const { store, theme } = this.props
        const { requestedAmt, ExpressFundingBorrower, fundingData, loading, notes, fundsLoaded, grossAdvanceableExpanded, grossAdvanceableExpandable, FeeRecipient, DrawFeesToBorrower, showBanner } = this.state
        const userApps = getUserApps(this.props.aionStore)
        const { AvailableFunds, ARAdvanceInfo } = store
        const availableFunds = AvailableFunds || {}
        const availableAR = availableFunds.AvailableFunds
        const maxTags = 4
        var multiples = getMultiples(availableAR, maxTags)
        multiples = _.uniq(multiples)
        var amtTags = []
        amtTags.push(<Capsule id={availableAR} key={availableAR} margin='0 10px 0 0' onClick={() => this.handleAmtSelection(availableAR)} text='Full' />)
        multiples.forEach(val => {
            if (val < availableAR) {
                amtTags.push(<Capsule id={val} key={val} margin='0 10px 0 0' onClick={() => this.handleAmtSelection(val)} text={`${toCurrency(val, 0)}`} />)
            }
        })

        return (
            <FlexColumn className='main-padding'>
                <PageHeader
                    titleText="Request Funds"
                    backText='Back'
                    back
                />

                <FlexColumn style={{ width: "100%", marginBottom: 24 }}>
                    {
                        showBanner && <BoldBanner
                            icon={<Image src={Clock} />}
                            style={{ marginTop: "0", marginBottom: 24, width: "100%" }}
                            message="Funds will be available in 1 to 2 business days after the funding request is processed. Requests submitted after 6pm ET are processed the next business day."
                            onClose={() => {
                                this.setState({ showBanner: false })
                            }}
                        />
                    }
                    {this.props.store.ARAdvanceInfo ? <LimitsBalances abl advancesLoaded={fundsLoaded} data={this.props.store.ARAdvanceInfo} availableFunds={AvailableFunds || {}} type="Advances" /> : null}
                </FlexColumn>

                <Container shadow style={{ marginBottom: 48 }}>
                    <FlexColumn style={{ alignItems: "flex-start" }}>
                        <div style={{ width: "600px" }}>
                            <LabeledInput nomargin prefix='$' label="How much would you like to advance?" onChange={(e) => { this.setState({ requestedAmt: e.target.value }) }} size="large" placeholder="Enter amount" value={this.state.requestedAmt || null} />
                            <Flex start wrap style={{ margin: '20px 0' }}>
                                {amtTags}
                            </Flex>
                            <LabeledInput type="text-area" rows={3} onChange={(e) => { this.setState({ notes: e.target.value }) }} size="large" placeholder="Enter purpose (E.g. Payroll, Operating Expenses, etc.)" value={this.state.notes || null} label="Briefly describe purpose of your funding request" />
                            <FlexColumn start gap='12px' style={{ margin: "12px 0", backgroundColor: theme.colors.backgroundBanner, padding: "12px 24px", borderRadius: "8px" }}>
                                <Text lightText>The daily cut-off time for funding requests is 6:00 PM ET. Requests submitted after this time are processed the next business day.</Text>
                                {
                                    FeeRecipient && FeeRecipient.FeeType == "Percentage" && <Text lightText>A {FeeRecipient.FeePercentage}% draw fee will be deducted from your draw amount and the net amount will be deposited in your Aion account.</Text>
                                }
                                {
                                    FeeRecipient && FeeRecipient.FeeType == "Flat Fee" && <Text lightText>A ${toCurrency(FeeRecipient.FlatFee)} draw fee will be deducted from your draw amount and the net amount will be deposited in your Aion account.</Text>
                                }
                            </FlexColumn>
                            <Flex start style={{ width: '100%', marginTop: 30 }}>
                                <Button loading={loading} onClick={this.handleSubmit} noMargin permtype={userApps.includes("ABLCredit") ? "ABLCredit.ABL Advances" : "ARCredit.Inventory Advances"} solid text='Submit' />
                            </Flex>
                        </div>

                    </FlexColumn>
                </Container>

                <ExpressFunds
                    visible={this.state.showExpressFundsModal}
                    onSubmit={() => {
                        this.setState({ showExpressFundsModal: false })
                        this.fetchFundsData()
                        window.history.back()
                    }}
                    advanceAmount={requestedAmt}
                    fundingData={fundingData}
                    availableFunds={availableFunds}
                    notes={notes}
                    drawFeesToBorrower={DrawFeesToBorrower}
                    feeRecipient={FeeRecipient}
                    onCancel={(loading) => {
                        if (!loading) this.setState({ showExpressFundsModal: false })
                    }}
                />
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.creditAppReducer,
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(RequestFunds)))