import React from 'react';
import styled from 'styled-components';
import { Empty, Layout } from 'antd';
import lottie from 'lottie-web';
import Lottie from 'react-lottie';
import moment from 'moment';

import { Paragraph, Title } from './Text';
import { TextButton } from './Button';
import AbstImg from '../../../Images/rocket-abstract.png';
import { containerDimensions } from '../../../Styles/theme';
import success from '../../Lotties/success.json';

const { Sider, Footer } = Layout;

export const Flex = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: ${props => {
        if (props.centerVertically || props.center) {
            return 'center';
        } else if (props.start) {
            return 'flex-start';
        } else if (props.between) {
            return 'space-between';
        } else if (props.start) {
            return 'flex-start';
        } else if (props.end) {
            return 'flex-end';
        } else if (props.centerVertically || props.center) {
            return 'center';
        } else  {
            return 'space-around';
        }
    }
    };
    align-items: ${props => {
        if (props.centerHorizontally || props.center) {
            return 'center';
        } else {
            return null;
        }
    }
    };
    flex-wrap: ${props => props.wrap && 'wrap'}
`;

export const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: ${props => {
        if (props.centerVertically || props.center) {
            return 'center';
        } else if (props.start) {
            return 'flex-start';
        } else if (props.end) {
            return 'flex-end';
        } else if (props.between) {
            return 'space-between';
        } else {
            return 'space-around';
        }
    }
    };
    align-items: ${props => {
        if (props.centerHorizontally || props.center) {
            return 'center';
        } else if (props.right) {
            return 'flex-end';
        } else if (props.left) {
            return 'flex-start';
        } else {
            return null;
        }
    }
    };
`;

export const SideBar = () => {
    const StyledBar = styled(Sider)`
        background: ${props => props.theme.colors.secondary2};
        background: linear-gradient(to bottom, #4C33D2, #763CDA);
    `;
    return (<StyledBar width={containerDimensions.authPage.sideBar} />);
}

export const GradientSideBar = styled(Sider)`
    background: ${props => props.theme.colors.secondary2};
    background: linear-gradient(to bottom, #4C33D2, #763CDA);
`;

export const AbstractSideBar = (props) => {
    const StyledBar = styled(Sider)`
        background: ${props => props.theme.colors.secondary2};
        background: linear-gradient(to bottom, #4C33D2, #763CDA);
    `;

    return (
        <StyledBar {...props}>
            <img src={AbstImg} style={{ width: "600px", margin: '50% 0 0 -20%', opacity: 0.8 }} alt="Aion Logo" />
        </StyledBar>
    );
}

export const AppFooter = () => {
    return (
        <Footer style={{ textAlign: "center", background: "transparent" }}>©{moment().format('YYYY')} Aion Financial Technologies, Inc.</Footer>
    );
}

// CARDS
export const CardHeader = (props) => {
    const Header = styled(Flex)`
        background-color: ${props => props.theme.colors.secondary5};
        height: 45px;
        padding: 0 15px;
        border-radius: ${containerDimensions.cards.borderRadius};
        justify-content: space-between;
    `;
    return (
        <Header center>
            <Flex center>
                {props.img ? <img height="35" width="35" src={props.img} style={{ marginRight: "10px" }} /> : null}
                <Paragraph level={3} style={{ fontWeight: 500 }} noMargin>{props.title}</Paragraph>
            </Flex>
            {props.buttonTitle ? <TextButton onClick={props.onClick}>{props.buttonTitle}</TextButton> : null}
            {/* {props.subtitle ? <span style={{ fontWeight: 400, fontSize: 16 }} noMargin>{props.subtitle}</span> : null} */}
        </Header>
    );
}

export const Card = styled.div`
    margin: 5px 0px;
    padding: 5px 15px;
    border-radius: ${containerDimensions.cards.borderRadius};
    border: 0px solid ${props => props.theme.colors.systemGray5};
`;

export const InputContainer = styled.div`
    margin: 20px 0 40px 0;
    padding: 20px 20px;
    border-radius: 15px;
    background-color: ${props => props.theme.colors.systemGray6};
`;

export const EmptyContainer = (props) => (
    <Empty
        style={props.style || {}}
        imageStyle={{
            height: 60,
        }}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={
            <span>
                {props.desc || `Nothing found`}
            </span>
        }
    >
    </Empty>
);

export const GradientContainer = styled.div`
    position:relative;
    padding: ${props => props.padding ? props.padding : '24px'};
    ::before {
        content:"";
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        border-radius:16px;
        border: 1px solid transparent;
        background: linear-gradient(90deg, #CBCED6 0%, rgba(203, 206, 214, 0) 124.48%) border-box;
        -webkit-mask:
            linear-gradient(#fff 0 0) padding-box, 
            linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
            mask-composite: exclude;
    }
`

export const SearchContainer = styled.div`
    background: #F4F5F7;
    border-radius: 8px;
`;

export const Container = styled.div`
    padding: ${props => props.padding ? props.padding : '48px'};
    background: ${props => props.color ? props.color : '#FFFFFF'};
    border: ${props => props.border && props.border};
    border-radius: ${props => props.radius ? props.radius : '8px'};
    box-shadow: ${props => props.shadow && '0px 4px 32px rgba(111, 111, 111, 0.08)'};
`

export const RefreshContainer = styled.div`
    padding: ${props => props.padding ? props.padding : '24px'};
    border: 1px solid #F2F2F2;
    border-radius: ${props => props.radius ? props.radius : '8px'};
    margin: ${props => props.margin && props.margin};
`;

export const CardContainer = styled.div`
    padding: ${props => props.padding ? props.padding : '24px'};
    box-shadow: 0px 4px 8px rgba(102, 102, 102, 0.08);
    border-radius: ${props => props.radius ? props.radius : '8px'};
`;

export const BrandedContainer = styled.div`
    width: 516px;
    height: 784px;
    /* Gradient/Blue */
    background: conic-gradient(from 99.91deg at 16.25% 86.88%, #1199FF 0deg, #1A8AFA 360deg);
    /* Elevation/Medium */
    box-shadow: 0px 4px 8px rgba(102, 102, 102, 0.16);
    border-radius: 8px;
`;