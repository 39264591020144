import React, { Component } from 'react'
import styled, { withTheme } from 'styled-components'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { Flex, FlexColumn } from '../../Reusable/Container'
import { Tag, Text } from '../../Reusable/Text'
import { getDateInLocalTZ, getUserApps, toAbsCurrency } from '../../../Utils/util'
import More from "../../../Images/more.png"
import TableView from '../../Reusable/TableView'
import moment from 'moment'
import { Dropdown, Menu, Popconfirm } from 'antd'
import { ImageButton, TextButton } from '../../Reusable/Button'
import { DeleteOutlined } from '@ant-design/icons'
import hexToRgba from 'hex-to-rgba'

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
})

class BillTable extends Component {
    
    getColumns = () => {
        const { activeTab, aionStore, hideAction, hideColumns, theme } = this.props

        const userApps = getUserApps(aionStore)
        const { CurrentSync } = aionStore
        let qbo = false
        if (CurrentSync && CurrentSync.platformName === 'QUICKBOOKS' && CurrentSync.syncSetupCompleted && userApps && userApps.includes("Payables")) {
            qbo = true
        }

        var columns = [
            {
                title: <Text weight='500' noWrap>Vendor</Text>,
                dataIndex: 'vendorName',
                key: 'vendorName'
            },
            {
                title: <Text weight='500' noWrap>No.</Text>,
                dataIndex: 'invoiceNumber',
                key: 'invoiceNumber',
                width: "280px",
            },
            {
                title: <Text weight='500' noWrap>Due Date</Text>,
                dataIndex: 'dueDate',
                key: 'dueDate',
                render: (date) => {
                    if (date) {
                        date = new Date(date).toISOString()
                        date = date.split("T")[0]
                    }
                    return (moment(date).format('MM/DD/YY'))
                },
            }
        ]

        // if (activeTab === "All" || activeTab === "Scheduled") {
        //     columns.push({
        //         title: <Text weight='500' noWrap>Scheduled Date</Text>,
        //         dataIndex: 'scheduledDateStr',
        //         key: 'scheduledDateStr',
        //         render: date => date ? (moment(date).format('MM/DD/YY')) : "--",
        //     })
        // }

        if (activeTab === "All" || activeTab === "Completed") {
            columns.push({
                title: <Text weight='500' noWrap>Paid Date</Text>,
                dataIndex: 'payments',
                key: 'payments',
                width: "100px",
                render: (payments, bill) => (payments && payments.length > 0 && payments[payments.length-1].paymentDate) ? <Flex start centerHorizontally gap='8px'>
                    {
                        getDateInLocalTZ(payments[payments.length-1].paymentDate, 'MM/DD/YY')
                    }
                </Flex> 
                : "--",
            })
        }

        columns = columns.concat([
            {
                title: <Text weight='500' noWrap>Balance</Text>,
                dataIndex: 'balance',
                key: 'balance',
                render: (balance, bill) => { 
                    return (
                        <Flex start className='cell-content'>
                            <Text weight='500'>{formatter.format(balance)}<span className='hover-content' style={{ color: hexToRgba(theme.colors.primary, 0.7) }}> / <b title='Total'>{formatter.format(bill.amount)}</b></span></Text>
                        </Flex>
                    )
                },
                align: 'left',
                width: "220px",
            },
            {
                title: <Text weight='500' noWrap>Status</Text>,
                dataIndex: 'status',
                key: 'status',
                // width: '200px',
                render: (status, bill) => <Flex start gap='8px'>
                    {
                        status &&
                        <Tag title={bill.statusMessage}>{status}</Tag>
                    }
                    {
                        (bill.balance > 0 && bill.balance != bill.amount && bill.status != "Partially Paid") && <Tag>Partially Paid</Tag>
                    }
                </Flex>,
            },
            {
                title: <Text>Last Updated</Text>,
                dataIndex: 'auditData',
                key: 'auditData',
                // onHover: true,
                render: auditData => moment(auditData.lastUpdatedTime).format('MM/DD/YY'),
                align: 'right',
            },
        ])

        columns.push(
            {
                title: '',
                key: 'action',
                align: 'center',
                width: "40px",
                onHover: true,
                render: (text, bill) => {
                    return !["Paid", "Processing", "Initiated", "Pending Approval"].includes(bill.status) && !hideAction ? 
                        <Dropdown
                            placement='bottomRight'
                            trigger={['click']}
                            key={`${bill.invoiceNumber}-Dropdown`}
                            overlay={
                                <Menu style={{ padding: 24, borderRadius: 8, zIndex: 1001 }} key={`${bill.invoiceNumber}-Menu`} onClick={() => { }}>
                                    <FlexColumn gap='24px'>
                                        {
                                            (bill.status === "New" || bill.status === "Unpaid" || bill.status === "Canceled") &&
                                            <Popconfirm
                                                title={<>
                                                    <div>Are you sure you want to delete this bill?</div>
                                                    {qbo && bill.source === 'QUICKBOOKS' && <div>This will also delete the bill on QuickBooks Online</div>}
                                                </>}
                                                onConfirm={(e) => {
                                                    e.stopPropagation()
                                                    this.props.deleteBill(bill.id, bill.invoiceNumber)
                                                }}
                                                okText="Confirm"
                                                placement="left"
                                                okButtonProps={{ loading: this.props.deleteLoading }}
                                                cancelText="Cancel"
                                                onCancel={(e) => e.stopPropagation()}
                                            >
                                                <TextButton text='DELETE' rightIcon={<DeleteOutlined style={{ fontSize: 18 }} />} onClick={(e) => e.stopPropagation()} />
                                            </Popconfirm>
                                        }
                                        {
                                            // bill.status === "Scheduled" &&
                                            // <TextButton text='RESCHEDULE' onClick={(e) => {
                                            //     e.stopPropagation()
                                            //     this.props.handleRescheduleBill(bill)
                                            // }} />
                                        }
                                        {
                                            bill.status === "Scheduled" &&
                                            <Popconfirm
                                                title="This will cancel all scheduled payments. Are you sure you want to proceed?"
                                                onConfirm={(e) => {
                                                    e.stopPropagation()
                                                    this.props.cancelBillPayment(bill.id)
                                                }}
                                                okText="Confirm"
                                                placement="left"
                                                okButtonProps={{ loading: this.props.cancelLoading }}
                                                cancelText="Cancel"
                                            >
                                                <TextButton text='CANCEL' onClick={(e) => e.stopPropagation()} />
                                            </Popconfirm>
                                        }
                                    </FlexColumn>
                                </Menu>
                            }
                        >
                            <ImageButton src={More} onClick={(e) => e.stopPropagation()} />
                        </Dropdown>
                        :
                        null
                }
            }
        )

        if(hideColumns) {
            columns = columns.filter(column => !hideColumns.includes(column.key))
        }

        return columns
    }

    render() {
        
        return (
            <TableView
                id="bills-table"
                tableLayout='auto'
                columns={this.getColumns()}
                dataSource={this.props.bills}
                rowKey='id'
                pagination={this.props.pagination}
                loading={this.props.loading}
                onChange={this.props.handleTableChange}
                titleText={this.props.titleText || "Bills"}
                descText={this.props.descText}
                onRow={
                    this.props.handleOnRow ?
                    (record, rowIndex) => {
                        return {
                            onClick: event => {
                                this.props.handleOnRow(record)
                            }, // click row
                        }
                    }
                    : null
                }
                borderView={this.props.borderView}
                ctaContent={this.props.ctaContent}
            />
        )
    }
}


function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer,
        userDefaultsStore: state.userDefaultsReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(BillTable)))