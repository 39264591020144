import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'
import update from 'immutability-helper'

import {
    message, Select, Popconfirm, Space, Upload, Modal, Tooltip, List, Table
} from 'antd'

import {
    InboxOutlined, DeleteOutlined, CheckCircleFilled, InfoCircleOutlined
} from '@ant-design/icons'

// Components
import { TextButton } from '../../../Reusable/Button'
import { Button } from '../../../Reusable/Refresh/Button'
import { Text, Tag } from '../../../Reusable/Text'
import { FlexColumn, Flex, Container } from '../../../Reusable/Container'
import { LabeledInput } from '../../../Reusable/Input'
import { ErrorAlert } from '../../../Reusable/Alert'
import environment from '../../../../environment'
import { FromAccountOption } from '../../../Reusable/Option'
import { SignedLink } from '../../../Reusable/Link'
import { apiPOSTReq } from '../../../../Utils/api'
import { addressObjectToStr, toCurrency, isEmailValid, getBPAccountsWithAccess } from '../../../../Utils/util'
import ModalClose from '../../../../Images/modal-close.png'

const docTypes = [
    {
        name: "Purchase Order",
        type: "PO",
    },
    {
        name: "Work Order",
        type: "WO",
    },
    {
        name: "Master Service Agreement",
        type: "MSA",
    },
    {
        name: "Other",
        type: "OTHER",
    }
]

const { Option } = Select
const { Dragger } = Upload

class NewVendor extends Component {

    constructor(props) {
        super(props)

        const { vendorPaymentAccount, userPaymentAccount } = props

        this.state = {
            paymentMethod: (vendorPaymentAccount && vendorPaymentAccount.type) ? vendorPaymentAccount.type : "ACH",
            accountNumber: (vendorPaymentAccount && vendorPaymentAccount.bankDetail) && vendorPaymentAccount.bankDetail.accountNumber,
            routingNumber: (vendorPaymentAccount && vendorPaymentAccount.bankDetail) && vendorPaymentAccount.bankDetail.routingNumber,
            accountType: (vendorPaymentAccount && vendorPaymentAccount.bankDetail) ? vendorPaymentAccount.bankDetail.accountType : "Checking",
            checkAddress: (vendorPaymentAccount && vendorPaymentAccount.bankDetail) && vendorPaymentAccount.bankDetail.bankAddress,
            createVendorLoading: false,
            vendor: props.vendor || { type: "Individual", billRecipientEmail: [] },
            fromAccount: userPaymentAccount && userPaymentAccount,
            vendorType: "Individual",
        }
    }

    saveInput = (id, dataToSave) => {
        this.setState({ [id]: dataToSave })
    }

    saveVendorInput = (key, value) => {
        var { vendor } = this.state
        vendor[key] = value
        this.setState({ vendor })
    }

    handleTextChange = (event) => {
        const target = event.target
        this.saveInput(target.id || target.name, target.value)
    }

    handleVendorTextChange = (event) => {
        var { vendor } = this.state
        vendor[event.target.id] = event.target.value
        this.setState({ vendor })
    }

    handleAccountSelect = (value, option) => {
        const Accounts = getBPAccountsWithAccess(null, { resourceStr: "Payables.Vendors", permType: "manage", getActiveAccounts: true })
        var fromAccount = Accounts.find(account => account.accountId === value)
        this.setState({ fromAccount })
    }

    getVendorLocation = (location, formattedAddress) => {
        if (!location)
            this.setState({
                vendor: update(this.state.vendor, {
                    address: {
                        $set: {
                            line1: null,
                            line2: null,
                            city: null,
                            countrySubDivisionCode: null,
                            state: null,
                            postalCode: null
                        }
                    }
                })
            })
        else
            this.setState({
                vendor: update(this.state.vendor, {
                    address: {
                        $set: {
                            line1: location.line1,
                            line2: location.line2,
                            city: location.city,
                            countrySubDivisionCode: location.countrySubDivisionCode,
                            state: location.state,
                            postalCode: location.postalCode
                        }
                    }
                })
            })
    }

    getCheckLocation = (location, formattedAddress) => {
        console.log("getCheckLocation", location)

        if (!location)
            this.setState({
                checkAddress: {
                    // $set: {
                    line1: null,
                    line2: null,
                    city: null,
                    countrySubDivisionCode: null,
                    state: null,
                    postalCode: null
                    // }
                }
            })
        else
            this.setState({
                checkAddress: {
                    // $set: {
                    line1: location.line1,
                    line2: location.line2,
                    city: location.city,
                    countrySubDivisionCode: location.countrySubDivisionCode,
                    state: location.state,
                    postalCode: location.postalCode
                    // }
                }
            })
    }

    getModalForDocUpload = (doc) => {
        const { theme } = this.props
        var { uploadedFile } = this.state
        uploadedFile = uploadedFile || { ...doc }
        const uploadProps = () => {
            return {
                name: `payables-docs`,
                multiple: false,
                action: `${environment.apiBaseUrl}/file/upload`,
                headers: {
                    businesskey: this.props.aionStore.BusinessUniqueKey,
                    jwt: this.props.aionStore.jwt
                },
                onChange: (info) => {
                    var { file } = info
                    console.log("uploadProps info file", file)
                    const { status, response, name } = file
                    if (status === "done") {
                        this.setState({
                            uploadedFile: {
                                ...uploadedFile,
                                fileName: (doc.name == "W-9") ? doc.name : name,
                                uri: (response.UploadedUrls || [])[0]
                            }
                        })
                    }
                }
            }
        }

        return (
            <FlexColumn center>
                <div style={{ width: "95%" }}>
                    <Text size='20px'>Upload {doc.name === "W-9" ? "W-9" : "document"}</Text>
                    <Text>Upload your document and add an optional note.</Text>
                    <FlexColumn>
                        <Dragger {...uploadProps(doc)}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        </Dragger>
                        {(doc.name !== "W-9") &&
                            <LabeledInput
                                label="Document type"
                                showAction={'focus'}
                                type="select"
                                size="type"
                                key="type"
                                defaultValue={doc.type}
                                placeholder="Purchase Order"
                                optionFilterProp="children"
                                onChange={(value, option) => this.setState({ uploadedFile: { ...uploadedFile, "type": value } })}
                                className="no-left-padding"
                            >
                                {docTypes.map(item =>
                                    <Option key={item.type} id="type" name={item.name} value={item.type} style={{ backgroundColor: "transparent" }}>
                                        {item.name}
                                    </Option>
                                )}
                            </LabeledInput>}
                        <LabeledInput
                            label="Note"
                            placeholder="Note"
                            key="note"
                            id="note"
                            onChange={e => { this.setState({ uploadedFile: { ...uploadedFile, "notes": e.target.value } }) }}
                        />
                        <Button permtype="Override" onClick={() => {
                            if (!uploadedFile.fileName) {
                                ErrorAlert({ description: "Please upload a file before saving" })
                                return
                            }
                            this.setState({
                                vendor: update(this.state.vendor, { attachments: { $set: [...(this.state.vendor.attachments || []), uploadedFile] } }),
                                showUploadModal: false
                            })
                        }
                        } text='Save' />
                    </FlexColumn>
                </div>
            </FlexColumn>
        )
    }

    getDocumentSection = () => {
        var { vendor } = this.state
        var { attachments } = vendor || {}
        attachments = attachments || []
        const { theme } = this.props
        var documentsToUpload = [
            {
                name: `W-9`,
                type: "W-9"
            },
            {
                name: `Other`,
                type: "OTHER"
            }
        ]
        documentsToUpload.forEach(item => {
            if (attachments.find(document => document.fileName == item.name)) item.uploaded = true
        })

        const handleDeleteFile = (uri) => {
            var vendor = this.state.vendor || {}
            var attachments = (vendor.attachments || []).filter(item => item.uri !== uri)
            this.setState({
                vendor: update(this.state.vendor, { attachments: { $set: attachments } })
            })
        }

        return (
            <>
                <FlexColumn>
                    <Text color='#444444' style={{ margin: '15px 0' }}>Documents</Text>
                    {/* <Divider style={{ margin: "0 0 0 5" }} /> */}
                    <List
                        bordered
                        loading={this.state.loading}
                        dataSource={documentsToUpload}
                        renderItem={item =>
                            <Flex centerHorizontally between style={{ padding: "6px 15px", borderBottom: `1px solid ${theme.colors.systemGray6}` }}>
                                <FlexColumn>
                                    <Text weight='500'>{item.name}</Text>
                                    {
                                        item.type === "W-9" &&
                                        <Text>IRS W-9: <a href="https://www.irs.gov/pub/irs-pdf/fw9.pdf" target="_blank">https://www.irs.gov/pub/irs-pdf/fw9.pdf</a></Text>
                                    }
                                </FlexColumn>
                                {(!item.uploaded || (item.type === "OTHER")) ?
                                    <div style={{ textAlign: "right" }}>
                                        <TextButton onClick={() => { this.setState({ showUploadModal: true, uploadDoc: item, uploadedFile: null }) }} text='Upload' />
                                    </div>
                                    :
                                    <Tag style={{ margin: "5px 0" }} tagpreset={theme.colors.secondary4}>Uploaded <CheckCircleFilled /></Tag>
                                }
                            </Flex>
                        }
                    />
                    {(attachments.length > 0) &&
                        <>
                            <Text weight='500' size='20px' style={{ marginTop: "40px" }}>Uploaded Documents</Text>
                            <Table
                                loading={this.state.loading}
                                dataSource={attachments}
                                scroll={{ y: '700px', x: '100%' }}
                                pagination={false}
                                rowKey='uri'
                                columns={[{
                                    title: 'Name',
                                    dataIndex: 'fileName',
                                    render: (name, item) => <SignedLink {...item} />
                                },
                                {
                                    title: 'Type',
                                    dataIndex: 'type',
                                    render: item => <Tag tagpreset={theme.colors.secondary8}>{item}</Tag>
                                },
                                {
                                    title: 'Note',
                                    dataIndex: 'notes'
                                },
                                {
                                    title: 'Action',
                                    width: "100px",
                                    render: (text, item) => (
                                        <Space size="middle">
                                            <Popconfirm title="Are you sure you want to delete this file?" onConfirm={() => handleDeleteFile(item.uri)}>
                                                <a><DeleteOutlined style={{ cursor: 'pointer', fontSize: 18 }} /></a>
                                            </Popconfirm>
                                        </Space>
                                    ),
                                    align: "right"
                                }]}
                            />
                        </>
                    }
                </FlexColumn>
            </>
        )
    }

    createVendor = () => {
        var { vendor, fromAccount, paymentMethod, accountNumber, routingNumber, checkAddress } = this.state
        const { vendorName, primaryEmail, type } = vendor

        var successMessage = vendor.id ? "Vendor updated successfully!" : "Vendor created successfully!"

        if (!type) {
            ErrorAlert({ description: "Please select a type for this vendor." })
            return
        }

        if (!vendorName) {
            ErrorAlert({ description: "Please enter a vendor name." })
            return
        }

        if (vendorName.includes('|')) {
            ErrorAlert({ description: "Please enter a valid vendor name." })
            return
        }

        if (primaryEmail && !isEmailValid(primaryEmail)) {
            ErrorAlert({ description: "Please enter a valid email." })
            return
        }

        if (vendor.primaryEmail === "") {
            vendor.primaryEmail = null
        }

        if (!fromAccount) {
            ErrorAlert({ description: "Please select an account." })
            return
        }

        if (paymentMethod === "ACH") {
            if (!accountNumber) {
                ErrorAlert({ description: "Please enter an account number." })
                return
            }

            if (accountNumber && (isNaN(accountNumber) || accountNumber.length > 17)) {
                ErrorAlert({ description: "Please enter a valid account number. It can have a maximum of 17 characters." })
                return
            }

            if (!routingNumber) {
                ErrorAlert({ description: "Please enter a routing number." })
                return
            }

            if (routingNumber && (isNaN(routingNumber) || routingNumber.length != 9)) {
                ErrorAlert({ description: "Please enter a valid 9-digit routing number." })
                return
            }
        } else if (paymentMethod === "Check") {
            if (!checkAddress) {
                ErrorAlert({ description: "Check address is required. Please enter a valid address." })
                return
            }

            var { line1, city, state, postalCode } = checkAddress
            if (!line1 || !city || !state || !postalCode) {
                ErrorAlert({ description: "Check address is required. Please enter a valid address. 2" })
                return
            }
        }

        this.setState({ createVendorLoading: true })

        const body = {
            "vendor": vendor,
            "saveToQBO": this.state.saveToQBO,
        }

        console.log("/payables/vendors/save", body)
        apiPOSTReq(`${environment.payBaseUrl}/payables/vendors/save`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            try {
                this.setState({ loading: false })
                const data = resp
                console.log("/payables/vendors/save", JSON.stringify(data))
                if (data.result) {
                    this.setState({ vendor: data.vendor, editvendor: data.editvendor })

                    if (paymentMethod === "ACH") {
                        this.saveACHPayment(successMessage)
                    } else if (paymentMethod === "Check") {
                        this.saveCheckPayment(successMessage)
                    }
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                this.setState({ createVendorLoading: false })
                console.log("ERRR /payables/vendors/save", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    saveACHPayment = (successMessage) => {
        const { vendor, vendorType, fromAccount } = this.state

        var body = {
            "vendorId": vendor.id,
            "accountId": fromAccount.accountId,
            "vendorType": vendorType,
            "bankDetail": {
                "accountName": vendor.vendorName || "",
                "accountNumber": this.state.accountNumber || "",
                "routingNumber": this.state.routingNumber || "",
                "accountType": (this.state.accountType || "").toLowerCase()
            },
        }

        this.setState({ submitLoading: true })
        console.log("/payables/vendors/addTransferDelivery", JSON.stringify(body))
        apiPOSTReq(`${environment.payBaseUrl}/payables/vendors/addTransferDelivery`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            this.setState({ createVendorLoading: false })
            try {
                if (err) throw Error(err)
                const data = resp || {}
                if (data.result) {
                    message.success(successMessage)
                    body.type = "ACH"
                    vendor.deliveryMode = "ACH"
                    this.props.onSave(vendor, body)
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/createCounterparty err", error, resp, body)
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    handleEmailChange = (event) => {
        this.setState({
            vendor: update(this.state.vendor, { billRecipientEmail: { [event.target.getAttribute('index')]: { $set: event.target.value } } })
        })
    }

    handleAddEmail = () => {
        this.setState({
            vendor: update(this.state.vendor, this.state.vendor.billRecipientEmail ? { billRecipientEmail: { $push: [null] } } : { billRecipientEmail: { $set: [null] } })
        })
    }

    handleDeleteEmail = (event) => {
        this.setState({
            vendor: update(this.state.vendor, { billRecipientEmail: { $splice: [[[event.currentTarget.getAttribute('index')], 1]] } })
        })
    }

    saveCheckPayment = (successMessage) => {
        var { vendor, checkAddress, vendorType, vendor, fromAccount } = this.state
        var { line1, line2, city, state, postalCode, countrySubDivisionCode } = checkAddress

        var body = {
            "vendorId": vendor.id,
            "accountId": fromAccount.accountId,
            "vendorType": vendorType,
            "bankDetail": {
                "bankAddress": {
                    "line1": line1,
                    "line2": line2,
                    "city": city,
                    "state": countrySubDivisionCode,
                    "postalCode": postalCode
                }
            },
        }

        console.log("/addCheckDelivery body", JSON.stringify(body))
        this.setState({ submitLoading: true })
        apiPOSTReq(`${environment.payBaseUrl}/payables/vendors/addCheckDelivery`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            console.log("/addCheckDelivery err, resp", err, resp)
            this.setState({ createVendorLoading: false })
            try {
                const data = resp || {}
                if (data.result) {
                    message.success(successMessage)
                    body.type = "Check"
                    vendor.deliveryMode = "Check"
                    this.props.onSave(vendor, body)
                } else {
                    throw Error(data.error || data.responseMessage)
                }
            } catch (error) {
                console.log("/addCheckDelivery err", error)
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    render() {
        var { theme, bankingStore, vendorPaymentAccount } = this.props;
        const { vendor, paymentMethod, accountType, checkAddress, createVendorLoading, accountNumber, fromAccount, vendorType } = this.state;

        var { id, vendorName, primaryEmail, primaryPhone, taxId, address, type, deliveryMode } = vendor;

        var Accounts = getBPAccountsWithAccess(null, { resourceStr: "Payables.Vendors", permType: "manage", getActiveAccounts: true }) || []
        var accountOptions = [];
        var filteredAccounts = Accounts.filter(item => { return (item.accountType !== 'ACCOUNTS_RECEIVABLE') }) || []
        filteredAccounts.forEach(account => {
            accountOptions.push(
                <Option key={account.accountId} id={id} value={account.accountId} style={{ backgroundColor: "transparent" }}>
                    <FromAccountOption account={account} />
                </Option>
            )
        })

        return (
            <FlexColumn start>
                <FlexColumn>
                    <Flex style={{ width: "100%", justifyContent: "space-between" }}>
                        <FlexColumn start style={{ width: "50%", marginBottom: 12, marginRight: 12 }}>
                            <Text color='#444444' margin="10px 0 0 0">Vendor Details</Text>

                            <LabeledInput
                                label="Type"
                                type='switch'
                                switchNames={['Individual', 'Business']}
                                onChange={(value) => this.saveVendorInput("type", value)}
                                value={vendor.type} />

                            <LabeledInput
                                autoFocus
                                label="Name"
                                nomargin
                                id="vendorName"
                                key="vendorName"
                                placeholder="Enter vendor name"
                                value={vendorName}
                                onChange={this.handleVendorTextChange}
                            />

                            <LabeledInput
                                optional
                                label="Email"
                                id="primaryEmail"
                                key="primaryEmail"
                                placeholder="Enter email"
                                value={primaryEmail}
                                onChange={this.handleVendorTextChange}
                            />

                            <LabeledInput
                                optional
                                label="Phone"
                                nomargin
                                id="primaryPhone"
                                key="primaryPhone"
                                placeholder="Enter phone number"
                                value={primaryPhone}
                                maxLength={10}
                                onChange={this.handleVendorTextChange}
                            />

                            <LabeledInput
                                optional
                                label={(type !== "Individual") ? "EIN" : "SSN"}
                                id="taxId"
                                key="taxId"
                                placeholder="373888567"
                                value={taxId}
                                onChange={this.handleVendorTextChange}
                                maxLength={9}
                            />
                            <div>
                                <Tooltip title="Email of the recipient(s) that will receive bill payment notifications">
                                    <Flex start centerHorizontally style={{ marginBottom: 8, justifyContent: 'space-between' }}>
                                        <Flex>
                                        <Text color='#7384AA' size='14px'>Bill payment notification email</Text>
                                        <InfoCircleOutlined style={{ color: '#7384AA', marginLeft: 4, marginTop: 4 }} />
                                        </Flex>
                                        <span style={{color: '#7384AA', font: 'InterDisplay',fontSize: '12px',fontStyle: 'normal',fontWeight: 400,lineHeight: '20px',
                                            letterSpacing: '0em',
                                            textAlign: 'left'}}>
                                                optional
                                                </span>
                                    </Flex>
                                </Tooltip>
                                <div>
                                    {
                                        this.state.vendor.billRecipientEmail && this.state.vendor.billRecipientEmail.map((email, index) => {
                                            return (
                                                <div key={index} style={{ margin: "-15px 0 0", width: '100%' }}>
                                                    <Flex start centerHorizontally style={{ width: '100%' }}>
                                                        <LabeledInput
                                                            index={index}
                                                            type="email"
                                                            value={email}
                                                            onChange={this.handleEmailChange}
                                                            placeholder="Email"
                                                            id="invoiceEmailAddress"
                                                            key={"invoiceEmailAddress" + index}
                                                        />

                                                        <span index={index} onClick={this.handleDeleteEmail}>
                                                            <a><DeleteOutlined style={{ cursor: 'pointer', marginLeft: 8 }} /></a>
                                                        </span>
                                                    </Flex>
                                                </div>
                                            )
                                        })
                                    }
                                    <Tag onClick={this.handleAddEmail} tagpreset={theme.colors.primary}>Add email</Tag>
                                </div>
                            </div>

                            <LabeledInput
                                optional
                                id="address"
                                key="address"
                                label="Address"
                                type="location"
                                getLocation={this.getVendorLocation}
                                Line2
                                address={address}
                                value={address && address.line1 ?
                                    { line1: addressObjectToStr(address), line2: address.line2 } : null
                                }
                                placeholder="Address"
                            />
                        </FlexColumn>

                        <FlexColumn start style={{ width: "50%", marginLeft: 12 }}>
                            <Text color='#444444' margin="10px 0 0 0">Your Payment Account</Text>

                            <LabeledInput
                                label="Bank Account"
                                showAction={'focus'}
                                type="select"
                                size="large"
                                key="fromAccount"
                                className="no-left-padding"
                                instruction="We will use this account for your payments to this vendor"
                                placeholder="From account"
                                onChange={this.handleAccountSelect}
                                value={fromAccount && fromAccount.accountSubType && fromAccount.mask &&
                                    <Text capitalize>{(fromAccount.nickName || `Business ${fromAccount.accountSubType}`)} • {fromAccount.mask}</Text>}
                            >
                                {accountOptions}
                            </LabeledInput>

                            <Text color='#444444' margin="10px 0 0 0">Vendor ACH Details</Text>

                            <div style={{ display: "flex", justifyContent: "space-between", width: 400 }}>
                                <LabeledInput
                                    label="Vendor Account Type"
                                    type='switch'
                                    switchNames={['Individual', 'Business']}
                                    onChange={(value) => this.saveInput("vendorType", value)}
                                    value={vendorType} />
                            </div>

                            {
                                paymentMethod === "ACH" &&
                                <>
                                    <LabeledInput
                                        autoFocus
                                        label="Account Number"
                                        name="accountNumber"
                                        key="accountNumber"
                                        value={accountNumber}
                                        placeholder="xxxxxxxx"
                                        onChange={this.handleTextChange}
                                        maxLength={17}
                                        instruction="Maximum of 17 characters"
                                    />
                                    <LabeledInput
                                        autoFocus
                                        label="Routing Number"
                                        name="routingNumber"
                                        key="routingNumber"
                                        placeholder="052001633"
                                        onChange={this.handleTextChange}
                                        maxLength={9}
                                        instruction="9 digit routing number"
                                    />

                                    <LabeledInput
                                        label="Account Type"
                                        type='switch'
                                        switchNames={['Checking', 'Savings']}
                                        onChange={(value) => this.saveInput("accountType", value)}
                                        value={accountType} />
                                </>
                            }

                            {
                                paymentMethod === "Check" &&
                                <LabeledInput
                                    id="checkAddress"
                                    key="checkAddress"
                                    label="Check Address"
                                    type="location"
                                    getLocation={this.getCheckLocation}
                                    Line2
                                    address={checkAddress}
                                    value={checkAddress && checkAddress.line1 ?
                                        { line1: addressObjectToStr(checkAddress), line2: checkAddress.line2 } : null
                                    }
                                    placeholder="Address"
                                    instruction="We'll mail a check to this address free of cost"
                                />
                            }
                        </FlexColumn>
                    </Flex>
                    {this.getDocumentSection()}
                </FlexColumn>
                <Flex start style={{ width: "100%", marginTop: 48 }}>
                    <Button permtype="Payables.Vendors" solid loading={createVendorLoading} onClick={() => this.createVendor()} text={this.props.update === true ? "Update Vendor" : "Create Vendor"} />
                </Flex>
                <Modal
                    visible={this.state.showUploadModal}
                    footer={null}
                    closable={true}
                    width={700}
                    style={{ top: 20 }}
                    destroyOnClose={true}
                    onCancel={() => { this.setState({ showUploadModal: false }) }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    {this.getModalForDocUpload(this.state.uploadDoc || {})}
                </Modal>
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        bkStore: state.bookkeepingAppReducer,
        aionStore: state.aionAppReducer,
        bankingStore: state.bankingAppReducer,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(NewVendor))