import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'
import { saveAs } from 'file-saver'
import { Table, message, Skeleton } from 'antd'

import { PaperClipOutlined, DownOutlined, UpOutlined } from '@ant-design/icons'

// Components
import PageHeader from '../../Reusable/PageHeader'
import { Flex, FlexColumn, Container } from '../../Reusable/Container'
import environment from '../../../environment'
import { apiPOSTReq, getPreSignedS3Url } from '../../../Utils/api'
import { getResourcePerm, getUserApps } from '../../../Utils/util'
import moment from 'moment'
import { ErrorAlert } from '../../Reusable/Alert'
import { Tag } from '../../Reusable/Text'
import { Button } from '../../Reusable/Button'
import { LabeledInput } from '../../Reusable/Input'

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
})

const WOStatusTag = (options) => {
    let statusMsg
    let statusColor = "blue"
    switch (options.status) {
        case "VALIDATED":
            statusColor = "green"
            statusMsg = "Approved"
            break
        case "REJECTED":
            statusColor = "red"
            statusMsg = "Rejected"
            break
        default:
            statusMsg = "Verifying"
            break
    }
    return (<Tag color={statusColor}>{statusMsg}</Tag>)
}

const downloadAttachment = (url, name) => {
    getPreSignedS3Url({ url: url }, (err, preSignedUrl) => {
        if (!err) {
            saveAs(preSignedUrl, name)
        } else {
            ErrorAlert({ description: JSON.stringify(err) })
        }
    })
}

class Index extends Component {

    constructor(props) {
        super(props)

        const ablInvPerm = getResourcePerm("ABLCredit.Inventory Upload")

        this.state = {
            loading: true,
            pagination: {
                current: 1,
                pageSize: 100
            },
            pos: [],
            ablInvPerm,
        }
    }

    componentDidMount() {
        this.fetchInventoryHistory()
    }

    fetchInventoryHistory = () => {
        const { ablInvPerm } = this.state
        this.setState({ loading: true, selectedRows: [] })

        var body = {}

        if (ablInvPerm.view || ablInvPerm.manage) body = { "InventoryLOC": true }

        apiPOSTReq(`${environment.iveBaseUrl}/inventory/getInventoryHistory`, {}, body, (err, resp) => {
            try {
                const data = resp
                console.log("/inventory/getInventoryHistory", JSON.stringify(data))
                console.log("/inventory/getInventoryHistory err", JSON.stringify(err))
                if (data.result) {
                    this.setState({ inventoryInfos: data.inventoryInfos, inventoryLoaded: true, loading: false })
                } else {
                    this.setState({ payLoading: false, deleteLoading: false })
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /inventory/getInventoryHistory", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    handleApproveUpload = (approvalStatus) => {
        if (!this.state.reviewerNote) {
            ErrorAlert({ description: "Please update reviewer notes before proceeding." })
            return
        }
        const { UserInfo } = this.props.aionStore;
        const body = {
            uploadId: this.state.selectedRows[0].uploadJobId,
            status: approvalStatus,
            reviewer: `${UserInfo.FirstName} ${UserInfo.LastName} (${UserInfo.Email})`,
            notes: this.state.reviewerNote,
        }

        console.log('/inventory/approveUpload', body)
        apiPOSTReq(`${environment.iveBaseUrl}/inventory/approveUpload`, null, body, (err, resp) => {
            try {
                const data = resp || {}
                if (data.result) {
                    message.success(`Report${this.state.selectedRows.length > 1 ? "s" : ""} ${approvalStatus}`)
                    this.setState({ selectedRowKeys: [] })
                    this.fetchInventoryHistory()
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR approveUpload", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    getColumns = () => {
        const { theme } = this.props
        const userApps = getUserApps(this.props.aionStore)

        var columns = [
            {
                title: 'Update Date',
                dataIndex: 'uploadDate',
                render: (date) => {
                    if (date) {
                        date = new Date(date).toISOString()
                        date = date.split("T")[0]
                    } else {
                        return '--'
                    }
                    return (moment(date).format('ll'))
                },
            },
        ]

        if (userApps.includes("ABLCredit")) {
            columns = columns.concat(
                {
                    title: 'As of Date',
                    dataIndex: 'inventoryAsOf',
                    render: (date) => {
                        if (date) {
                            date = new Date(date).toISOString()
                            date = date.split("T")[0]
                        } else {
                            return '--'
                        }
                        return (moment(date).format('ll'))
                    },
                },
                {
                    title: 'Gross Inventory',
                    dataIndex: 'grossInventory',
                    render: (item) => (formatter.format(item)),
                },
                {
                    title: 'Ineligible Inventory',
                    dataIndex: 'ineligibleInventory',
                    render: (item) => (formatter.format(item)),
                },
            )
        }

        columns = columns.concat(
            {
                title: 'Updated and Certified  by',
                dataIndex: 'certifiedBy',
            },
        )

        if (userApps.includes("ABLCredit")) {
            columns = columns.concat(
                {
                    title: 'Status',
                    dataIndex: 'status',
                    render: (status, info) => {
                        if (status === "PendingUpload") {
                            return <Tag tagpreset={"#EC3F25"}>{"Pending Upload"}</Tag>
                        } else if (status === "InReview") {
                            return <Tag tagpreset={theme.colors.secondary4}>{"In Review"}</Tag>
                        } else {
                            return <Tag tagpreset={theme.colors.secondary4}>{status}</Tag>
                        }
                    },
                }
            )
        }

        if (userApps.includes("ARCredit")) {
            columns = columns.concat(
                {
                    title: '',
                    dataIndex: 'action',
                    align: 'center',
                    render: (text, inventory) => {
                        return inventory.inventoryFileURL && inventory.inventoryFileURL.uri && <a onClick={() => downloadAttachment(inventory.inventoryFileURL.uri, inventory.inventoryFileURL.fileName)}><PaperClipOutlined style={{ fontSize: 18 }} /></a>
                    },
                }
            )
        }


        return columns
    }

    handleTableChange = (pagination, filters, sorter) => {
        this.fetchData({ pagination: pagination })
    }

    handleRowSelection = (selectedRowKeys, selectedRows) => {
        console.log("handleRowSelection", selectedRows, selectedRowKeys)
        this.setState({ selectedRows: selectedRows, selectedRowKeys: selectedRowKeys })
    }

    getApprovalStatus = (verificationStatus) => {
        let approvalStatus = "";
        if (verificationStatus === 'Approved') {
            approvalStatus = "Approved";
        } else if (verificationStatus === 'Rejected') {
            approvalStatus = "Rejected";
        } else {
            approvalStatus = "Verifying";
        }
        return approvalStatus;
    }
    getRowDetails = (props) => {
        var { status, reviewer, revieweDate, notes } = props;
        const { theme } = this.props;
        const dateFormat = "MM/DD/YY";
        const approvalStatus = this.getApprovalStatus(status);

        return (
            (approvalStatus != 'Verifying') ? <Skeleton loading={this.state.loadingTrackLog} active title={false} paragraph={{ rows: 3 }}>
                <Flex style={{ padding: "20px", background: theme.body, justifyContent: "space-around", alignItems: 'flex-start' }}>
                    <FlexColumn style={{ width: '40%' }}>
                        <div style={{ fontWeight: 'bold', fontSize: '16px', marginBottom: '10px' }}>Inventory approvals</div>
                        <div>
                            <div style={{ color: theme.colors.secondary4 }}>{approvalStatus} by:</div>
                            <div>{reviewer}</div>
                        </div>
                        <div style={{ marginTop: '10px' }}>
                            <div style={{ color: theme.colors.secondary4 }}>{approvalStatus} on:</div>
                            <div>{moment(revieweDate).format(dateFormat)}</div>
                        </div>
                    </FlexColumn>
                    <FlexColumn>
                        <div style={{ fontWeight: 'bold', fontSize: '16px', marginBottom: '10px' }}>Reviewer note</div>
                        <div>{notes}</div>
                    </FlexColumn>
                </Flex>
            </Skeleton> : <></>
        )
    }


    render() {
        const { theme, hidetitle } = this.props
        const { inventoryLoaded, pos, pagination, selectedRows, selectedRowKeys, showUpdateModal, inventoryInfos, uploadARAgingInfos, loading } = this.state

        return (
            <>
                <FlexColumn style={{ margin: hidetitle ? 0 : `0 40px` }}>
                    {!hidetitle &&
                        <PageHeader
                            titleText="Inventory"
                            desc=""
                        />}
                    {/* <FlexColumn>
                        {((selectedRows || []).length > 0) &&
                            <StyledButton
                                permtype="Override"
                                onClick={() => this.setState({ showUpdateModal: true })}
                                style={{ height: 45, width: "250px", margin: "0 10px 10px 0" }}
                            >Approve Aging Reports</StyledButton>
                        }
                    </FlexColumn> */}

                    <FlexColumn>
                        {((selectedRows || []).length > 0) &&
                            <div>
                                <LabeledInput
                                    label="Reviewer Notes *"
                                    id="reviewerNote"
                                    type="text-area"
                                    placeholder="Enter a reason for the approval / rejection"
                                    onChange={(e) => this.setState({ reviewerNote: e.target.value })}
                                />
                                <div style={{ display: 'flex', marginTop: '20px' }}>
                                    <Button
                                        permtype="Override"
                                        onClick={() => this.handleApproveUpload("Approved")}
                                        style={{ height: 45, width: "250px", margin: "0 10px 10px 0" }}
                                        text='Approve' />

                                    <Button
                                        permtype="Override"
                                        onClick={() => this.handleApproveUpload("Rejected")}
                                        style={{ height: 45, width: "250px", margin: "0 0 10px 0" }}
                                        text='Reject' />
                                </div>
                            </div>
                        }
                    </FlexColumn>
                    <Container shadow style={{ margin: '24px 0' }}>
                        <Table
                            id="inbox-table"
                            tableLayout='auto'
                            columns={this.getColumns()}
                            dataSource={inventoryInfos}
                            rowKey='id'
                            pagination={pagination}
                            onChange={this.handleTableChange}
                            style={{ minHeight: 500, marginTop: 20 }}
                            scroll={{ y: '700px', x: '100%' }}
                            loading={loading}
                            rowSelection={{
                                type: "radio",
                                onChange: this.handleRowSelection,
                                selectedRowKeys: selectedRowKeys,
                                renderCell: (checked, record, index, originNode) => {
                                    if (record.status === "InReview") {
                                        return originNode
                                    }
                                },
                                hideSelectAll: true,
                            }}
                            expandable={!this.props.disableExpandable && {
                                expandedRowRender: record => this.getRowDetails(record),
                                expandRowByClick: true,
                                expandIconColumnIndex: -1,
                                columnWidth: "2px",
                                indentSize: "20px",
                                expandIcon: ({ expanded, onExpand, record }) =>
                                    !expanded ? (
                                        <DownOutlined onClick={e => onExpand(record, e)} />
                                    ) : (
                                        <UpOutlined onClick={e => onExpand(record, e)} />
                                    )
                            }}
                        />
                    </Container>
                </FlexColumn>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        bkStore: state.bookkeepingAppReducer,
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Index))