import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter, useLocation } from 'react-router-dom'
import { withTheme } from 'styled-components'

import { Modal } from 'antd'
import { FlexColumn } from '../../Reusable/Container'
import PageHeader from '../../Reusable/PageHeader'
import { fetchBBHistory, normalizeAndSortHistory } from '../../../Utils/bankingDataManager'
import PendingTable from '../Reusable/PendingTable'

// Images
import AccountNameModal from '../Reusable/AccountNameModal'
import ModalClose from '../../../Images/modal-close.png'

const MoreTransactions = (props) => {
    const [account, setAccount] = useState()
    const [accountIndex, setAccountIndex] = useState(0)
    const [type, setType] = useState()
    const [paymentStatus, setPaymentStatus] = useState([])
    const [postedTransfers, setPostedTransfers] = useState([])
    const [showAccountNameModal, setShowAccountNameModal] = useState(false)
    const [loadingPending, setLoadingPending] = useState(true)

    const location = useLocation()

    useEffect(() => {
        if (location.state && location.state.account) {
            console.log('MoreTransactions', location.state.account, location.state.type)
            setAccount(location.state.account)
            setAccountIndex(location.state.accountIndex)
            setType(location.state.type)
            // setPaymentStatus(location.state.type === 'Canceled' ? ['canceled'] : location.state.type === 'Rejected' ? ['Rejected', 'denied_approval'] : location.state.type === 'Errored' ? ['error'] : [])
            setPaymentStatus(['canceled', 'Rejected', 'denied_approval', 'error'])
        }
    }, [])

    useEffect(() => {
        if (account) {
            getPending({ pagination: { pageSize: 100, current: 1 } })
        }
    }, [account])


    const getPending = (options) => {
        console.log('getPending fetchOptions0:', options, paymentStatus, account.accountId)

        const body = {
            viewBy: "PAYMENT_STATUS",
            paymentStatus: paymentStatus,
            accountId: account.accountId,
            pagination: options.pagination,
        }

        setLoadingPending(true)
        fetchBBHistory(body, (err, resp) => {
            if (resp) {
                console.log('getPending fetchOptions0: resp', resp)
                const normalizedHistory = normalizeAndSortHistory(resp)
                setPostedTransfers(normalizedHistory.normalizedData)
            }
            setLoadingPending(false)
        })
    }

    return (
        <FlexColumn className='main-padding' start>
            <PageHeader
                titleText={`Unsuccessful Transfers`}
                back
                onBack={() => {
                    props.history.replace({
                        pathname: '/banking/accounts',
                        state: { selectedAccountIndex: accountIndex }
                    })
                }}
            />

            <FlexColumn between style={{ width: '100%', paddingBottom: 24 }}>
                <PendingTable
                    // more
                    title={`Unsuccessful Transfers`}
                    subtitle='Access detailed information about transfers that were canceled, had errors, or were rejected. Click on a transfer to see the reasons for any errors or rejections.'
                    collapsible={false}
                    loadingPending={loadingPending}
                    pendingTransfers={postedTransfers} />
            </FlexColumn>

            <Modal
                wrapClassName='account-name-modal'
                visible={showAccountNameModal}
                footer={null}
                closable={false}
                maskClosable={true}
                width={776}
                destroyOnClose={true}
                onCancel={() => setShowAccountNameModal(false)}
                closeIcon={<img width='24px' height='24px' src={ModalClose} />}
            >
                <AccountNameModal onCancel={() => setShowAccountNameModal(false)} />
            </Modal>
        </FlexColumn>
    )
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer,
        bankingStore: state.bankingAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(MoreTransactions)))