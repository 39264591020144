import React from 'react';
import styled from 'styled-components';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import { Input, InputNumber, Divider, DatePicker, Select, Radio, Upload, Button, Popover, Cascader } from 'antd';
import { Text, Paragraph } from './Text';
import { Image } from '../Image'
import { TextButton } from './Button';
import { FlexColumn, Flex, InputContainer } from '../Container';
import UploadFiles from '../UploadFiles';
import LocationSearchInput from './LocationSearchInput';
import { InfoCircleOutlined, DownOutlined, CloseOutlined, LoadingOutlined, PlusOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { truncate } from 'lodash';
import UploadIcon from '../../../Images/input-upload-icon.png';
import Asterisk from '../../../Images/asterisk.png'
import { phoneCountryCode } from '../../../Utils/util';

const { RangePicker } = DatePicker;
const { Dragger } = Upload;

// This button will have all the functionality as the antd Button
export const StyledInput = styled(Input)`
    font-family: 'InterDisplay';
    color: ${props => props.color ? props.color : '#444444'} !important;
    font-size: ${props => props.large ? "24px" : "16px"};
    font-weight: ${props => props.large ? "500" : "400"};
    line-height: ${props => props.large ? "32px" : "24px"};
    letter-spacing: 0em;
    text-align: left;
    &::placeholder {
        font-style: italic;
        font-weight: 300;
        color: ${props => props.theme.colors.placeholder};
    }
    padding: 0;
    width: 100%;
`;

export const ErrorLabel = styled.span`
    font-size: 12px;
    color: red;
    margin-top: 4px;
`;

export const StyledReactMultiEmail = styled(ReactMultiEmail)`
    font-family: 'InterDisplay';
    font-size: ${props => props.large ? "24px" : "16px"};
    font-weight: ${props => props.large ? "500" : "400"};
    line-height: ${props => props.large ? "32px" : "24px"};
    letter-spacing: 0em;
    text-align: left;
    &::placeholder {
        font-style: italic;
        font-weight: 300;
        color: ${props => props.theme.colors.placeholder};
    }
    padding: 0;
    background: transparent;
    background-color: transparent;
    border: none;
    &:focus {
        background: transparent;
        border: none;
    }
    &.focused {
        background: transparent;
        border: none;
    }
    > input {
        background: transparent;
        background-color: transparent;
        border: none;
        &::placeholder {
            font-style: italic;
            font-weight: 300;
            color: ${props => props.theme.colors.placeholder};
        }
    }
    > span[data-placeholder] {
        font-style: italic;
        left: 0;
        top: 0;
        font-weight: 300;
        color: ${props => props.theme.colors.placeholder};
    }
`;

export const StyledPlaceholder = styled.span`
    font-family: 'InterDisplay';
    font-size: ${props => props.large ? "24px" : "16px"};
    font-weight: ${props => props.large ? "500" : "400"};
    line-height: ${props => props.large ? "32px" : "24px"};
    font-style: italic;
`;

export const StyledInputPassword = styled(Input.Password)`
    background: transparent;
    font-size: 1.0rem;
    margin: ${props => props.padded ? '1em' : 0};
    padding: ${props => props.padded ? '0.25em 1em' : '0.25em 0'};
    > input {
        font-size: ${props => props.large ? "24px" : "16px"};
        font-weight: ${props => props.large ? "500" : "400"};
        line-height: ${props => props.large ? "32px" : "24px"};
        letter-spacing: 0em;
        text-align: ${props => props.alignRight ? "right" : "left"};
        border: none;
        &::placeholder {
            font-style: italic;
            color: ${props => props.theme.colors.greySecondary};
        }
    }
`;

export const StyledInputSearch = styled(Input.Search)`
    background: transparent;
    font-size: 1.0rem;
    margin: ${props => props.padded ? '1em' : 0};
    padding: 0;
`;

export const StyledInputNumber = styled(InputNumber)`
    background: transparent;
    font-size: 1.0rem;
    width: 100%;
    margin: ${props => props.padded ? '1em' : 0};
    padding: ${props => props.padded ? '0.25em 1em' : '0.25em 0'};
    &::placeholder {
        font-style: italic;
        color: ${props => props.theme.colors.greySecondary};
    }
`;

export const StyledTextArea = styled(Input.TextArea)`
    background: transparent;
    font-size: 1.0rem;
    margin: ${props => props.padded ? '1em' : 0};
    padding: ${props => props.padded ? '0.25em 1em' : '0.25em 0'};
`;

export const StyledDatePicker = styled(DatePicker)`
    background: transparent;
    color: ${props => props.color ? props.color : '#444444'} !important;
    font-size: ${props => props.large ? "24px" : "16px"};
    margin: ${props => props.padded ? '1em' : 0};
    padding: ${props => props.padded ? '0.25em 1em' : '0.25em 0'};
    width: 100%;
    > div > input {
        font-size: ${props => props.large ? "24px" : "16px"};
        font-weight: ${props => props.large ? "500" : "400"};
        line-height: ${props => props.large ? "32px" : "24px"};
        letter-spacing: 0em;
        text-align: ${props => props.alignRight ? "right" : "left"};
        border: none;
        &::placeholder {
            font-style: italic;
            font-weight: 300;
            color: ${props => props.theme.colors.placeholder};
        }
    }
`;

export const StyledRangePicker = styled(RangePicker)`
    background: transparent;
    font-size: 1.0rem;
    margin: ${props => props.padded ? '1em' : 0};
    padding: ${props => props.padded ? '0.25em 1em' : '0.25em 0'};
`;

export const StyledSelect = styled(Select)`
    justify: space-between;
    font-family: 'InterDisplay';
    color: ${props => props.color ? props.color : '#444444'} !important;
    font-size: ${props => props.large ? "24px" : "16px"};
    font-weight: ${props => props.large ? "500" : "400"};
    line-height: ${props => props.large ? "32px" : "24px"};
    width: 100%;
`;

export const StyledLabel = styled(Paragraph)`
    font-family: 'InterDisplay';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    color: ${props => props.labelcolor || props.theme.colors.greySecondary};
    margin-bottom: 4px;
    text-align: ${props => props.alignRight ? "right" : "left"};
    text-transform: uppercase;
`;

export const StyledInputContainer = styled.div`
    background: white;
    display: flex;
    justify-content: flex-start !important;
    align-items: center;
    height: ${props => !props.noHeight && (props.location ? (props.nextLine ? "105px" : "125px") : (props.small ? "40px" : (props.large ? "56px" : "40px")))};
    width: 100%;
    padding: ${props => props.padding ? props.padding : "8px 16px"};
    border: ${props => props.error ? '1px solid red' : '1px solid #C7CEDD'};
    border-radius: 4px;
    &:focus-within {
        border: ${props => `1px solid ${props.theme.colors.primary}`};
    }
`;

export const BlankContainer = styled.div`
    height: 16px;
    border-radius: 16px;
    padding: 16px;
    background: white;
    display: flex;
    align-items: center;
`;

const StyledCascader = styled(Cascader)`
    background: transparent;
    color: ${props => props.color ? props.color : '#444444'} !important;
    font-size: ${props => props.large ? "24px" : "16px"};
    font-weight: ${props => props.large ? "500" : "400"};
    line-height: ${props => props.large ? "32px" : "24px"};
    width: 100%;
    &::placeholder {
        font-style: italic !important;
        font-weight: 300;
        color: ${props => props.theme.colors.placeholder};
    }
`;

export const LabeledInput = (props) => {
    // const StyledParagraph = styled(Paragraph)`
    //     color: ${props => props.labelcolor || props.theme.colors.systemGray};
    //     margin: ${props => props.margin || "0"};
    //     font-size: ${props => props.fontSize || "0.9rem"};
    //     font-weight: ${props => props.fontWeight || "400"};
    //     text-align: ${props => props.alignRight ? "right" : "left"};
    // `;
    const StyledParagraph = styled.span`
        font-family: 'InterDisplay';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        color: ${props => props.labelcolor || props.theme.colors.greySecondary};
        margin-bottom: 4px;
        text-align: ${props => props.alignRight ? "right" : "left"};
        text-transform: uppercase;
    `;
    const StyledText = styled.span`
        font-family: 'InterDisplay';
        font-size: ${props => props.large ? "24px" : "16px"};
        font-style: normal;
        font-weight: ${props => props.large ? "500" : "400"};
        line-height: ${props => props.large ? "32px" : "24px"};
        letter-spacing: 0em;
        text-align: ${props => props.alignRight ? "right" : "left"};
        color: ${props => props.color || "#444444"};
    `;
    const StyledSuffix = styled.span`
        color: ${props => props.theme.colors.systemGray};
        margin-left: 5px;
    `;
    const Optional = styled.span`
        color: ${props => props.color || props.theme.colors.greySecondary};
        font-family: 'InterDisplay';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
    `;
    const Prefix = styled.span`
        color: ${props => props.color || '#1199FF'};
        font-family: 'InterDisplay';
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: right;
        margin-right: 8px;
    `;

    const getInput = () => {
        const { type } = props;
        if (type === "password" || type === "new-password" || type === "current-password") {
            return (<StyledInputPassword
                {...props}
                bordered={false}
            />)
        } else if (type === "text-area") {
            return (<StyledTextArea
                {...props}
                bordered={false}
            />)
        } else if (type === "date-picker") {
            return (<StyledDatePicker
                style={{ color: '#444444' }}
                {...props}
                bordered={false}
                ref={props.inputRef}
            />)
        } else if (type === "range-picker") {
            return (<StyledRangePicker
                {...props}
                bordered={false}
            />)
        } else if (type === "location") {
            return (<LocationSearchInput
                {...props}
                bordered={false}
            // ref={props.inputRef}
            />)
        } else if (type === "select") {
            return (<StyledSelect
                suffixIcon={<DownOutlined style={{ fontSize: '12px', fontWeight: 700, color: '#1199FF' }} />}
                // style={{ fontSize: "1.0rem", paddingLeft: 0, marginLeft: 0 }}
                {...props}
                bordered={false}
                ref={props.inputRef}
                autocomplete={!props.autocomplete && 'off'}
                data-lpignore={!props.autocomplete && "true"}
            />)
        } else if (type === "radio") {
            return (<Radio.Group
                style={{ fontSize: "1.0rem", paddingLeft: 0, marginLeft: 0 }}
                {...props}
                bordered={false}
            />)
        } else if (type === "search") {
            return (<StyledInputSearch
                {...props}
                bordered={false}
                allowClear
            />)
        } else if (type === "remote-search") {
            return (
                <div start centerHorizontally style={{ width: '100%', position: "relative" }}>
                    {props.symbolprefix && <Flex center style={{ marginRight: "5px", position: "absolute", top: 0, left: 12, bottom: 0, height: "100%" }}>{props.symbolprefix}</Flex>}
                    <StyledSelect
                        {...props}
                        bordered={false}
                        showSearch
                        defaultActiveFirstOption={false}
                        filterOption={false}
                        notFoundContent={null}
                        style={{ textIndent: props.symbolprefix ? "35px" : 0 }}

                    />
                    {props.symbolsuffix && <Flex center style={{ position: "absolute", top: 0, right: 12, bottom: 0, height: "100%"  }}>{props.symbolsuffix}</Flex>}
                </div>
            )
        } else if (type === "multi-email") {
            return (<StyledReactMultiEmail
                // ref={props.inputRef}
                {...props}
                getLabel={(
                    email,
                    index
                ) => {
                    return (
                        <EmailLabel key={email} onDelete={props.onDelete} email={email} index={index} />
                    );
                }}
            />)
        } else if (type === "upload") {
            return (<Upload {...props} start>
                <TextButton {...props} weight='500' text='UPLOAD' icon={<img width='24px' height='24px' src={UploadIcon} />} />
            </Upload>)
        } else if (type === "blank") {
            return (<></>)
        } else if (type === "cascader") { 
            return (<StyledCascader
                suffixIcon={<DownOutlined style={{ fontSize: '12px', fontWeight: 700, color: '#1199FF' }} />}
                style={{ fontSize: "16px" }}
                {...props}
                bordered={false}
                ref={props.inputRef}
            />)
        } else if (type === "number") {
            if (props.symbolprefix) {
                return (
                    <Flex start centerHorizontally style={{ width: '100%' }}>
                        <span style={{ marginRight: "5px" }}>{props.symbolprefix}</span>
                        <StyledInputNumber
                                min={props.min && props.min}
                                max={props.max && props.max}
                                value={props.value && props.value}
                                formatter={props.formatter && props.formatter}
                                type="text"
                                onChange={props.onChange}
                                bordered={false}
                                placeholder={props.placeholder && props.placeholder}
                                style={props.style && props.style}
                                id={props.id && props.id}
                                name={props.name && props.name}
                            // {...props}
                            />
                    </Flex>
                )
            }
            return (<StyledInputNumber
                min={props.min && props.min}
                max={props.max && props.max}
                value={props.value && props.value}
                formatter={props.formatter && props.formatter}
                type="text"
                onChange={props.onChange}
                bordered={false}
                placeholder={props.placeholder && props.placeholder}
                style={props.style && props.style}
                id={props.id && props.id}
                name={props.name && props.name}
            // {...props}
            />)
        } else if (type === "dragger") {
            return <Dragger {...props} showUploadList={true}>
                <FlexColumn style={{ padding: '12px 0 12px' }} center>
                    <Text margin='0 0 24px' size='12px' color='#7384AA'>Drag and drop documents here to attach them</Text>
                    <TextButton uppercase icon={<PlusOutlined style={{ fontSize: 16, color: '#1199FF' }} />} text='Upload Documents' />
                </FlexColumn>
            </Dragger>
        } else if (type === "read-only") {
            if(props.symbolprefix) {
                return (
                <Flex start centerHorizontally style={{ width: '100%' }}>
                    <span style={{ marginRight: "5px" }}>{props.symbolprefix}</span>
                    <StyledText {...props} alignRight={props.alignRight} margin="4px 0" fontSize={props.fontSize || "1.0rem"} labelcolor="black">{props.value}</StyledText>
                </Flex>
                )
            } else {
                return (<StyledText {...props} alignRight={props.alignRight} margin="4px 0" fontSize={props.fontSize || "1.0rem"} labelcolor="black">{props.value}</StyledText>)
            }
        } else if(type === "phone-cc") { // Country code selection
            return (
                <Flex start centerHorizontally style={{ width: '100%' }}>
                    <div style={{ width: 50 }}>
                    <Select
                        // showSearch
                        style={{ fontSize: 18, width: 50 }}
                        dropdownStyle={{ width: 300 }}
                        dropdownMatchSelectWidth={false}
                        bordered={false}
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                            console.log("filterOption", option, input)
                            return (option.name || '').includes(input)
                        }}
                        value={props.phoneCode && (phoneCountryCode.find(i => i.name == props.phoneCode.name) || {}).flag}
                        onChange={(dialCode) => props.onCodeChange(phoneCountryCode.find(i => i.dial_code == dialCode))}
                    >
                        {
                            phoneCountryCode.map(item => (
                                    <Select.Option key={item.code} name={item.name} value={item.dial_code} label={item.flag}>
                                        <Flex start gap="8px">
                                            <Text>{item.flag}</Text>
                                            <Text>{item.name}</Text>
                                            <Text color="#666666">{item.dial_code}</Text>
                                        </Flex>
                                    </Select.Option>
                                )
                            )
                        }
                    </Select>
                    </div>
                    
                    <StyledInput
                        {...props}
                        bordered={false}
                    />
                </Flex>
            )
        } else {
            if (props.symbolprefix) {
                return (
                    <Flex start centerHorizontally style={{ width: '100%' }}>
                        <span style={{ marginRight: "5px" }}>{props.symbolprefix}</span>
                        <StyledInput
                            {...props}
                            bordered={false}
                            autocomplete={!props.autocomplete && 'off'}
                            data-lpignore={!props.autocomplete && "true"}
                        />
                    </Flex>
                )
            }
            if (props.symbolsuffix) {
                return (
                    <Flex start centerHorizontally style={{ width: '100%' }}>
                        <StyledInput
                            {...props}
                            bordered={false}
                            autocomplete={!props.autocomplete && 'off'}
                            data-lpignore={!props.autocomplete && "true"}
                        />
                        <StyledSuffix>{props.symbolsuffix}</StyledSuffix>
                    </Flex>
                )
            }
            return (<StyledInput
                {...props}
                bordered={false}
                ref={props.inputRef}
                autocomplete={!props.autocomplete && 'off'}
                data-lpignore={!props.autocomplete && "true"}
            />)
        }
    }

    return (
        <FlexColumn start left style={{ margin: props.margin ? props.margin : '0 0 24px', width: '100%' }}>
            {
                (props.label || props.optional) &&
                <Flex between centerHorizontally style={{ marginBottom: 8, width: '100%' }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {
                            props.label ?
                                <Flex start gap='4px'>
                                    <Text color={props.labelColor || '#7384AA'} size='14px' weight={400}>{props.label}</Text>
                                    {
                                        (!props.optional && !props.noAsterisk && !['read-only', 'read-only-password'].includes(props.type)) &&
                                        <Image width='5px' height='5px' margin='4px 0 0' src={Asterisk} />
                                    }
                                </Flex> : null
                        }
                        {
                            props.tooltip &&
                            <Popover content={props.tooltip}>
                                <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)', marginLeft: 4 }} />
                            </Popover>
                        }
                    </div>
                    {props.optional && <Optional>Optional</Optional>}
                    {props.custLblComp} 
                </Flex>
            }
            {props.customlabelcomponent || null}

            {
                (props.type === "read-only" || props.type === "radio" || props.type === "dragger" || props.type === "upload") ?
                    <div style={{ width: '100%' }}>
                        {getInput()}
                    </div>
                    :
                    (
                        props.type === "blank" ?
                            <BlankContainer>{getInput()}</BlankContainer>
                            :
                            <StyledInputContainer key={`${props.key}Container`} padding={props.padding || (props.type === 'select' && '16px 4px 16px 4px')} error={props.error} style={props.containerStyle} nextLine={props.nextLine} location={props.type === "location"} large={props.large} small={props.small} noHeight={props.type === "text-area"} >
                                <Flex start center style={{ width: '100%' }}>
                                    {
                                        props.prefixText &&
                                        <Prefix>{props.prefixText}</Prefix>
                                    }
                                    {getInput()}
                                </Flex>
                            </StyledInputContainer>
                    )
            }
            {props.instruction && <Text size='12px' height='20px' color='#666666' margin='4px 0 0'>{props.instruction}</Text>}
            {props.uploadText && <Text size='14px' color='#666666' margin='12px 0 0'>{props.uploadText}</Text>}
            {props.error && <ErrorLabel>{props.errorMessage}</ErrorLabel>}
        </FlexColumn>
    );
}

export const InputConfirmation = (props) => {
    const StyledParagraph = styled(Paragraph)`
        color: ${props => props.labelcolor || props.theme.colors.secondary3};
        margin: 0; 
        font-size: ${props => props.labelSize || "0.9rem"};
        font-weight: ${props => props.labelWeight || "400"};
    `;
    if (props.withdivider)
        return (
            <>
                <StyledParagraph labelSize={props.labelSize} labelWeight={props.labelWeight}>{props.label}</StyledParagraph>
                <Paragraph level={props.level || 3} bold noMargin>{props.value}</Paragraph>
                <Divider style={{ margin: "15px 0" }} />
            </>
        )
    else
        return (
            <InputContainer style={{ margin: "10px 0" }}>
                {/* <StyledParagraph labelSize={props.labelSize} labelWeight={props.labelWeight}>{props.label}</StyledParagraph> */}
                <div style={{ display: "flex", alignItems: "center" }}>
                    <StyledParagraph labelSize={props.labelSize} labelWeight={props.labelWeight}>{props.label}</StyledParagraph>
                    {
                        props.tooltip &&
                        <Popover content={props.tooltip}>
                            <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)', marginLeft: 4 }} />
                        </Popover>
                    }
                </div>
                <Paragraph level={props.level || 3} bold noMargin>{props.value}</Paragraph>
            </InputContainer>
        )
}

export const InputConfirmationEdit = (props) => {
    const StyledParagraph = styled(Paragraph)`
        color: ${props => props.labelcolor || props.theme.colors.secondary3};
        margin: 0; 
        font-size: 0.9rem;
    `;

    return (
        <InputContainer style={{ margin: "10px 0" }}>
            <StyledParagraph>{props.label}</StyledParagraph>
            <Paragraph level={3} bold noMargin>{props.value} <Button size="small" onClick={props.onClick}>{props.buttonText}</Button></Paragraph>
        </InputContainer>
    )
}

export const GradientDivider = styled.div`
    background-image: ${props => props.reverse ? 'linear-gradient(90deg, #CBCED6 0%, rgba(203, 206, 214, 0) 124.48%)' : 'linear-gradient(to right, rgba(203, 206, 214, 0.1), rgba(203, 206, 214, 1))'}; 
    height: 1px;
    width: 100%;
    margin-top: ${props => props.marginTop && props.marginTop};
    margin-bottom: ${props => props.marginBottom && props.marginBottom};
`;

export const StyledDivider = styled(Divider)`
    background: #CBCED6;
    height: 1px;
    width: 100%;
    margin: 20px 0;
`;

const EmailLabel = (props) => {
    // const EmailLabel = styled.div`
    //     display: flex;
    //     flex-direction: row;
    //     justify-content: center;
    //     align-items: center;
    //     height: 24px;
    //     background: transparent;
    //     border: 1px solid #1199FF;
    //     border-radius: 40px;
    //     color: #1199FF;
    //     font-family: 'InterDisplay';
    //     font-size: 16px;
    //     font-style: normal;
    //     font-weight: 400;
    //     line-height: 24px;
    //     letter-spacing: 0em;
    //     text-align: left;
    //     padding: 16px 16px;
    //     margin-right: 15px;
    // `;

    const emailDivStyle = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: 24,
        background: 'transparent',
        border: '1px solid #1199FF',
        borderRadius: '4px',
        color: '#1199FF',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '24px',
        textAlign: 'left',
        padding: '16px 16px',
        marginRight: '15px',
    }

    // return
    // <EmailLabel>
    //     <span>{props.email}</span>
    //     <CloseOutlined onClick={() => props.onDelete(props.index)} style={{ color: '#1199FF', fontSize: 14, marginLeft: 13, cursor: 'pointer' }} />
    // </EmailLabel>
    return <div style={emailDivStyle} key={props.index}>
        {props.email}
        <CloseOutlined onClick={() => props.onDelete(props.index)} style={{ color: '#1199FF', fontSize: 14, marginLeft: 10, cursor: 'pointer' }} />
    </div>
}