import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import { Tabs, Select, Dropdown, Menu } from 'antd'

import moment from 'moment'
import _ from 'lodash'
import { saveAs } from 'file-saver'
import { Flex, FlexColumn } from '../../Reusable/Container'
import { Button, TextButton, Chip, ImageButton } from '../../Reusable/Button'
import { Image } from '../../Reusable/Image'
import { apiPOSTReq } from '../../../Utils/api'
import environment from '../../../environment'
import { ErrorAlert } from '../../Reusable/Alert'
import { LabeledInput } from '../../Reusable/Input'
import { Text } from '../../Reusable/Text'
import Search from '../../../Images/search.png'
import FilterIcon from '../../../Images/filter.png'


const { TabPane } = Tabs
const { Option } = Select

var dFormat = "YYYY-MM-DD"

class SearchAndFilter extends Component {

    state = {
        loading: true,
        selectedTab: "Aion",
        pagination: {
            current: 1,
            pageSize: 100,
            showSizeChanger: false,
        },
        sorter: {},
        sortDirection: "DESC",
        showClear: false,
        selectedRowsPageDict: {},
        selectedRowKeyPageDict: {},
        vendors: this.props.vendors,
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.vendors) !== JSON.stringify(this.props.vendors)) {
            this.setState({ vendors: this.props.vendors });
        }
    }

    showClearAll = () => {
        const { fetchInvoices } = this.props

        var { searchTerm, fromDate, toDate, fromAmount, toAmount, filterVendorName } = this.state
        if (searchTerm || fromDate || fromAmount || filterVendorName) {
            this.setState({ clearAll: true }, () => fetchInvoices({ ...this.state }))
        }
        else if (!searchTerm && !fromDate && !fromAmount && !filterVendorName) {
            this.setState({ clearAll: false }, () => fetchInvoices({ ...this.state }))
        }
    }

    clearAll = () => {
        const { fetchInvoices, vendors } = this.props

        this.setState({
            dueFromDate: null,
            dueToDate: null,
            fromDate: null,
            toDate: null,
            searchTerm: null,
            invoiceFromDate: null,
            invoiceToDate: null,
            filterInvoiceFromDate: null,
            filterInvoiceToDate: null,
            fromAmount: null,
            toAmount: null,
            fromAmount: null,
            toAmount: null,
            vendorName: null,
            filterVendorName: null,
            clearAll: null,
            billPagination: {
                current: 1,
                pageSize: 100
            },
            vendors: [],
        }, () => {
            this.setState({ vendors })
            fetchInvoices({ ...this.state })
        })
    }

    onTabChange = tab => {
        this.setState({ tab })
    }

    handleSearchChange = (e) => {
        const { fetchInvoices } = this.props

        const pagination = {
            current: 1,
            pageSize: 100
        }

        this.setState({ [e.target.id]: e.target.value, billPagination: pagination, amountError: false }, () => fetchInvoices({ ...this.state }))
    }

    render() {
        const { filterInvoiceFromDate, filterInvoiceToDate, dueFromDate, dueToDate, fromDate, toDate, filterVendorName,
            vendors, csvDownloading, searchTerm, dropdownVisible, fromAmount, toAmount, vendorName, clearAll, amountError } = this.state
        const { fetchInvoices } = this.props

        return (
            <Flex between centerHorizontally gap='12px'>
                <Flex centerHorizontally between fullWidth gap='16px'>
                    <FlexColumn start>
                        <Flex start>
                            <LabeledInput
                                id="searchTerm"
                                nomargin
                                width='200px'
                                placeholder="Search"
                                prefixIcon={<img width='12px' height='12px' src={Search} />}
                                value={searchTerm}
                                onChange={this.handleSearchChange}
                            />
                        </Flex>
                    </FlexColumn>

                    {
                        ((filterInvoiceFromDate && filterInvoiceToDate) || (fromDate && toDate) || searchTerm || (fromAmount || toAmount) || (filterVendorName)) &&
                        <Flex end fullWidth>
                            <Flex between fullWidth centerHorizontally >
                                <Flex start wrap gap='8px' centerHorizontally>
                                    {
                                        searchTerm && false &&
                                        <Chip closable onDelete={() => {
                                            this.setState({ searchTerm: null, pagination: { current: 1, pageSize: 100 } }, () => this.showClearAll())
                                        }} text={`"${searchTerm}"`} />
                                    }

                                    {
                                        fromDate && toDate &&
                                        <Chip closable onDelete={() => {
                                            this.setState({ dueFromDate: null, dueToDate: null, fromDate: null, toDate: null, pagination: { current: 1, pageSize: 100 } }, () => this.showClearAll())
                                        }} text={`Due Date: ${moment(fromDate).format('ll')} - ${moment(toDate).format('ll')}`} />
                                    }

                                    {
                                        (fromAmount || toAmount) && !amountError &&
                                        <Chip closable onDelete={() => {
                                            this.setState({ fromAmount: null, toAmount: null, fromAmount: null, toAmount: null, pagination: { current: 1, pageSize: 100 } }, () => this.showClearAll())
                                        }} text={`Amount: $${fromAmount} - $${toAmount}`} />
                                    }

                                    {
                                        filterVendorName &&
                                        <Chip closable onDelete={() => {
                                            const { vendors } = this.props
                                            this.setState({ filterVendorName: null, pagination: { current: 1, pageSize: 100 }, vendors: [], vendorName: null, }, () => {
                                                this.setState({ vendors })
                                                this.showClearAll()
                                            })
                                        }} text={`"Customer Name: ${filterVendorName}"`} />
                                    }
                                </Flex>
                            </Flex>
                        </Flex>
                    }

                    <Flex gap='12px' centerHorizontally>
                        {
                            clearAll &&
                            <>
                                <Text primary noWrap underline style={{ cursor: 'pointer' }} onClick={() => this.clearAll()}>Clear all</Text>
                            </>
                        }

                        <Dropdown
                            placement='bottomRight'
                            trigger={['click']}
                            onVisibleChange={flag => this.setState({ dropdownVisible: flag })}
                            visible={dropdownVisible}
                            overlay={(
                                <Menu onClick={() => { }} style={{ width: 600, padding: '12px 24px 24px', borderRadius: 8 }}>
                                    <Tabs onClick={() => { }} onChange={this.onTabChange}>
                                        <TabPane tab="Due Date" key="dueDate">
                                            <FlexColumn left gap='24px'>
                                                <Flex between gap='24px'>
                                                    <LabeledInput
                                                        label="From"
                                                        type="date-picker"
                                                        disabledDate={(date) => dueToDate != null ? date > this.state.dueToDate : false}
                                                        placeholder='MM/DD/YYYY'
                                                        value={dueFromDate}
                                                        format={'MM/DD/YYYY'}
                                                        onChange={(dueFromDate, dateStr) => this.setState({ dueFromDate: dueFromDate })}
                                                    />
                                                    <LabeledInput
                                                        label="To"
                                                        type="date-picker"
                                                        disabledDate={(date) => dueFromDate != null ? date < this.state.dueFromDate : false}
                                                        placeholder='MM/DD/YYYY'
                                                        value={dueToDate}
                                                        format={'MM/DD/YYYY'}
                                                        onChange={(dueToDate, dateStr) => this.setState({ dueToDate: dueToDate })}
                                                    />
                                                </Flex>
                                                <Button solid disabled={!dueFromDate || !dueToDate} text='APPLY' onClick={() => {
                                                    this.setState({ fromDate: dueFromDate.format(dFormat), toDate: dueToDate.format(dFormat) }, () => {
                                                        var updatedState = {
                                                            dateType: "Custom",
                                                            loading: true,
                                                            fromDate: dueFromDate.format(dFormat),
                                                            toDate: dueToDate.format(dFormat),
                                                            billPagination: {
                                                                current: 1,
                                                                pageSize: 100
                                                            },
                                                            clearAll: true,
                                                            amountError: false,
                                                        }
                                                        fetchInvoices({ ...this.state, ...updatedState })
                                                        this.setState({ dropdownVisible: false, clearAll: true, amountError: false, })
                                                    })
                                                }} />
                                            </FlexColumn>
                                        </TabPane>

                                        <TabPane tab="Amount" key="amount">
                                            <FlexColumn left gap='24px'>
                                                <Flex between gap='24px'>
                                                    <LabeledInput
                                                        label="From"
                                                        id="fromAmount"
                                                        key="fromAmount"
                                                        prefix="$"
                                                        placeholder="100"
                                                        min={0}
                                                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        value={fromAmount}
                                                        onChange={(e) => this.setState({ fromAmount: (!isNaN(parseFloat(e.target.value)) ? parseFloat(e.target.value) : null) })}
                                                    />
                                                    <LabeledInput
                                                        label="To"
                                                        id="toAmount"
                                                        key="toAmount"
                                                        prefix="$"
                                                        placeholder="1000"
                                                        min={0}
                                                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        value={toAmount}
                                                        onChange={(e) => this.setState({ toAmount: (!isNaN(parseFloat(e.target.value)) ? parseFloat(e.target.value) : null) })}
                                                        error={amountError}
                                                        errorMessage={amountError}
                                                    />
                                                </Flex>
                                                <Button solid disabled={!(typeof (fromAmount) == 'number' && typeof (toAmount) == 'number')} text='APPLY' onClick={() => {
                                                    this.setState({ fromAmount: fromAmount, toAmount: toAmount }, () => {
                                                        if (this.state.fromAmount > this.state.toAmount) {
                                                            this.setState({ amountError: "To amount should be greater than or equal to From amount" })
                                                            return
                                                        }
                                                        else if (this.state.fromAmount == 0 && this.state.toAmount == 0) {
                                                            this.setState({ amountError: "Please enter an amount greater than $0" })
                                                            return
                                                        }
                                                        var updatedState = {
                                                            dateType: "Custom",
                                                            loading: true,
                                                            fromAmount: fromAmount,
                                                            toAmount: toAmount,
                                                            billPagination: {
                                                                current: 1,
                                                                pageSize: 100
                                                            },
                                                            clearAll: true,
                                                            amountError: false,
                                                        }
                                                        fetchInvoices({ ...this.state, ...updatedState })
                                                        this.setState({ dropdownVisible: false, clearAll: true, amountError: false, })
                                                    })
                                                }} />
                                            </FlexColumn>
                                        </TabPane>

                                        {/* <TabPane tab="Vendors" key="vendor">
                                            <FlexColumn left gap='24px'>
                                                <Flex between gap='24px'>
                                                    <LabeledInput
                                                        label=""
                                                        type="select"
                                                        showSearch
                                                        prefixIcon={<img width='12px' height='12px' src={Search} />}
                                                        placeholder="Search by Vendor Name"
                                                        value={vendorName}
                                                        onChange={(val) => { this.setState({ vendorName: val }) }}
                                                        width={500}
                                                        dropdownRender={option => (
                                                            <div>
                                                                {option}
                                                            </div>
                                                        )}
                                                    >
                                                        {(vendors || []).map(item => <Option key={item.vendorId} id="vendorName" name={item.displayName || item.companyName} value={item.displayName || item.companyName} style={{ backgroundColor: "transparent" }}>{item.displayName || item.companyName}</Option>)}
                                                    </LabeledInput>
                                                </Flex>
                                                <Button solid disabled={!vendorName} text='APPLY' onClick={() => {
                                                    this.setState({ filterVendorName: vendorName }, () => {
                                                        var updatedState = {
                                                            dateType: "Custom",
                                                            loading: true,
                                                            filterVendorName: vendorName,
                                                            billPagination: {
                                                                current: 1,
                                                                pageSize: 10
                                                            },
                                                            clearAll: true,
                                                            amountError: false,
                                                        }
                                                        fetchInvoices({ ...this.state, ...updatedState })
                                                        this.setState({ dropdownVisible: false, clearAll: true, amountError: false, })
                                                    })
                                                }} />
                                            </FlexColumn>
                                        </TabPane> */}
                                    </Tabs>
                                </Menu>
                            )}
                        >
                            <TextButton text='FILTER' rightIcon={<Image src={FilterIcon} />} />
                        </Dropdown>
                    </Flex>
                </Flex>
            </Flex>
        )
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(SearchAndFilter)))