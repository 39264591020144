import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'
import { withRouter, useHistory } from 'react-router-dom'
import { Auth } from 'aws-amplify'

import { Flex, FlexColumn } from '../../Reusable/Container'
import { Text } from '../../Reusable/Refresh/Text'
import ClockIcon from '../../../Images/clock-icon.png'
import RejectedIcon from '../../../Images/rejected.png'
import Vector8 from '../../../Images/vector-8.png'
import Vector7 from '../../../Images/vector-7.png'
import { CardContainer } from '../../Reusable/Container'
import Profile1 from '../../../Images/review-profile-2.png'
import Quote from '../../../Images/quote.png'
import ArrowRight from '../../../Images/arrow-right.png'

import { TextButton } from '../../Reusable/Button'
import { Image } from '../../Reusable/Image'
import { apiPOSTReq } from '../../../Utils/api'
import { addDataToStore, ONB_SAVE_DATA, UAM_SAVE_BUSINESS, UAM_SAVE_USERINFO, UAM_SAVE_PERMISSIONS } from '../../../Actions/actions'
import environment from '../../../environment'
import { getUserApps } from '../../../Utils/util';
import { ErrorAlert } from '../../Reusable/Alert';
import { getUserPermissions } from '../../../Utils/userAccess';

class BankAppStatus extends Component {

    state = {
        isEnableAccessLoading: false,
        gnplHigh: 100000,
        gnplLow: 0
    }

    componentDidMount() {
        // Fetch on refresh page
        this.fetchBusinessData();
        this.fetchLoanApp();
        this.fetchBusinessAttributes();

    }
    fetchBusinessAttributes = () => {
        var body = {
            "clientBusinessId": ''
        };

        this.setState({ attributesLoading: true });
        // apiPOSTReq(`${environment.opsBaseUrl}/ops/fetchBusinessAttributes`, {}, body, (err, resp) => {
        apiPOSTReq(`${environment.opsBaseUrl}/ops/fetchBusinessAttributes`, null, body, (err, resp) => {
            try {
                if (err) throw Error(err);
                const data = resp || {};
                if (data.result) {
                    var lineSetup = data.businessAttributes.filter(x => x.name == "GNPLAutomatedLineSetupFlow");
                    if (lineSetup.length > 0) {
                        var attribute = lineSetup[0];
                        var additionalParameterReference = attribute.additionalParameterReference;
                        var parameters = additionalParameterReference.parameters;
                        var low = parseInt(parameters[0].value);
                        var high = parseInt(parameters[1].value);
                        this.setState({ gnplLow: low, gnplHigh: high })
                    }
                } else {
                    throw Error(data.responseMessage || data.error);
                }
            } catch (error) {
                console.log("/generateDebtSchedule err", error, resp);
            }
        });
    }
    fetchBusinessData = () => {
        apiPOSTReq(`${environment.uamBaseUrl}/getBusinessOwnership`, {}, {}, (err, resp) => {
            try {
                if (err) throw Error(err);
                const data = resp || {};
                if (data.result) {
                    var business = data.business || {}
                    this.props.dispatch(addDataToStore(UAM_SAVE_BUSINESS, data));
                    this.props.dispatch(addDataToStore(ONB_SAVE_DATA, { userStatus: business.userStatus })); // track userStatus
                } else {
                    throw Error(data.responseMessage || data.error);
                }
            } catch (error) {
                console.log("/getBusiness err", error, resp);
            }
        });
    }
    addApplicationAccess = () => {
        var { onboardingStore, aionStore } = this.props
        var loanApplication = onboardingStore.loanApplication ? onboardingStore.loanApplication : aionStore.loanApplication ? aionStore.loanApplication : this.state.loanApplication ? this.state.loanApplication : {};
        var body = {
            businessId: loanApplication.businessId,
            applicationId: loanApplication.applicationId
        }
        this.setState({ isEnableAccessLoading: true });
        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/activateCreditLine`, {}, body, (err, resp) => {

            try {
                if (err) throw Error(err);
                const data = resp || {};
                if (resp.result) {
                    apiPOSTReq(`${environment.lsmBaseUrl}/lsm/enableApplicationAccess`, {}, body, (err, response) => {

                        this.setState({ isEnableAccessLoading: false });
                        try {
                            if (err) throw Error(err);
                            const data = response || {};
                            if (data.result) {
                                window.location.href = '/credit/term-loans/advances'
                            } else {
                                this.setState({ isEnableAccessLoading: false })
                                ErrorAlert({ description: "Sorry, we had trouble processing your request. Please try again." });
                            }
                        } catch (error) {
                            ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
                            this.setState({ isEnableAccessLoading: false })
                            console.log("/getBusiness err", error, resp);
                        }
                    });
                } else {
                    this.setState({ isEnableAccessLoading: false })
                    ErrorAlert({ description: "Sorry, we had trouble processing your request. Please try again." });
                }
            } catch (error) {
                this.setState({ isEnableAccessLoading: false })
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
                console.log("/getBusiness err", error, resp);
            }
        });
    }
    fetchLoanApp = () => {

        // Fetch document list
        var body = {
            "clientBusinessId": this.props.aionStore.BusinessUniqueKey,
            "size": 100,
            "page": 0,
            // "sortDirection": "ASC"
        }
        this.setState({ loanAppLoading: true });
        apiPOSTReq(`${environment.opsBaseUrl}/ops/loans/application/getApplications`, {}, body, (err, resp) => {
            console.log("/getApplications resp body", resp, body);
            // this.setState({ loanAppLoading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    var loanApplications = data.loanApplications || [];
                    var loanApp = loanApplications.length > 0 ? loanApplications[loanApplications.length - 1] : {};
                    this.setState({ loanApplications: loanApplications, loanApplication: loanApp });
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        })
    }
    fetchUserData = (options, callback) => {
        options = options || {};
        Auth.currentUserInfo()
            .then(cognitoUserInfo => {
                var username = cognitoUserInfo.username;
                apiPOSTReq(`${environment.uamBaseUrl}/getUserInfo`, {}, { userId: username }, (err, resp) => {
                    try {
                        if (err) throw Error(err);
                        const data = resp || {};
                        if (data.result) {
                            var registeredUser = data.registeredUser || {};
                            console.log("fetchUserData /getUserInfo", registeredUser);
                            this.props.dispatch(addDataToStore(UAM_SAVE_USERINFO, registeredUser));
                            callback(null, true);
                        } else {
                            throw Error(data.responseMessage || data.error);
                        }
                    } catch (error) {
                        console.log("/getUserInfo err", error, resp);
                        callback(error.message);
                    }
                });
            })
            .catch(err => {
                console.log("fetchUserData Err", err);
            });
    }

    render() {
        var { onboardingStore, aionStore, theme } = this.props
        var { business, userInfo } = aionStore
        const { email } = userInfo
        var { businessBanking } = business || {}
        var apps = (businessBanking || {}).applications || []
        var { isEnableAccessLoading, gnplHigh, gnplLow } = this.state

        var onboardingStatus;
        if (this.state.loanApplication) {
            onboardingStatus = this.state.loanApplication.onboarding ? this.state.loanApplication.onboarding.toLowerCase() : "";
        }
        var status = ((apps[apps.length - 1] || {}).status || "").toLowerCase()
        var icon = ClockIcon
        var title = 'Your Aion banking application is being processed'
        var desc = <Text style={{ maxWidth: '575px', textAlign: 'center' }}>We will do our best to get back to you in less than 72 hours. If we have any questions we’ll reach out to you at <span style={{ color: theme.colors.primary, textDecorationLine: 'underline' }}>{email}</span></Text>
        var cta = null
        var showBgDesign = true
        const userApps = getUserApps(this.props.aionStore);
        if (onboardingStatus == "completed") {
            icon = theme.logo.symbol
            title = 'Congratulations'
            desc = <Text style={{ maxWidth: '320px', textAlign: 'center' }}>Your application has been accepted and your bank account is ready to use</Text>
            cta = <TextButton loading={isEnableAccessLoading} text='GET STARTED' onClick={() => {
                getUserPermissions(null, (err, resp) => {
                    try {
                        console.log("fetchPermissions resp.data", resp.data)
                        // this.setState({ loading: true })
                        if (err) throw Error(err)
                        if (!resp.data.authToken) throw Error(resp.data.responseMessage)
                        this.props.dispatch(addDataToStore(UAM_SAVE_PERMISSIONS, { UAM: resp.data }));
                        this.props.history.push('/credit/term-loans/advances');
                    } catch (error) {
                        ErrorAlert({ description: error.message })
                    }
                });
            }} rightIcon={<Image src={ArrowRight} />} />
            showBgDesign = false
        } else {
            switch (status) {
                case "approved":
                    if (this.props.aionStore.OnboardingType == "credit" && onboardingStatus && onboardingStatus != "completed") {
                        if (this.state.loanApplication) {
                            var loanApplication = this.state.loanApplication;
                            if (loanApplication.productType == "GNPL" && loanApplication.creditLimit > gnplLow && loanApplication.creditLimit < gnplHigh) {
                                break;
                            } else {
                                // GNPL greater than 100k and RLOC applications
                                icon = theme.logo.symbol
                                title = 'Congratulations'
                                desc = <Text style={{ maxWidth: '320px', textAlign: 'center' }}>Your application has been accepted and your bank account is ready to use</Text>
                                cta = <TextButton text='GET STARTED' onClick={() => {
                                    this.fetchBusinessData()
                                    getUserPermissions(null, (err, resp) => {
                                        try {
                                            console.log("fetchPermissions resp.data", resp.data)
                                            // this.setState({ loading: true })
                                            if (err) throw Error(err)
                                            if (!resp.data.authToken) throw Error(resp.data.responseMessage)
                                            this.props.dispatch(addDataToStore(UAM_SAVE_PERMISSIONS, { UAM: resp.data }));
                                            this.props.history.push('/banking/home');
                                        } catch (error) {
                                            ErrorAlert({ description: error.message })
                                        }
                                    });
                                }} rightIcon={<Image src={ArrowRight} />} />
                                showBgDesign = false
                            }
                        }
                        break;
                    } else {
                        icon = theme.logo.symbol
                        title = 'Congratulations'
                        desc = <Text style={{ maxWidth: '320px', textAlign: 'center' }}>Your application has been accepted and your bank account is ready to use</Text>
                        cta = <TextButton text='GET STARTED' onClick={() => {
                            this.fetchBusinessData()
                            getUserPermissions(null, (err, resp) => {
                                try {
                                    console.log("fetchPermissions resp.data", resp.data)
                                    // this.setState({ loading: true })
                                    if (err) throw Error(err)
                                    if (!resp.data.authToken) throw Error(resp.data.responseMessage)
                                    this.props.dispatch(addDataToStore(UAM_SAVE_PERMISSIONS, { UAM: resp.data }));
                                    this.props.history.push('/banking/home');
                                } catch (error) {
                                    ErrorAlert({ description: error.message })
                                }
                            });
                        }} rightIcon={<Image src={ArrowRight} />} />
                        showBgDesign = false
                    }
                    break;
                case "manual_review":
                case "obp_manual_review":
                    title = 'Your Aion banking application is being reviewed'
                    break
                case "rejected":
                    icon = RejectedIcon
                    title = 'Application declined'
                    desc = <Text style={{ maxWidth: '575px', textAlign: 'center' }}>Unfortunately we are unable to approve your business at this time.<br /><br />Thank you for your understanding and we apologize for any inconvenience this may have caused.</Text>
                    showBgDesign = false
                    break
                default:
                    break
            }
        }
        return (
            <>
                <FlexColumn start fullWidth style={{ position: 'relative' }}>
                    {
                        showBgDesign &&
                        <>
                            <img className='vector-8-onb' src={Vector8} />
                            <img className='vector-7-onb' src={Vector7} />
                        </>
                    }

                    <FlexColumn center gap='24px' style={{ marginTop: '10%' }}>
                        <img height='48px' width='48px' src={icon} />
                        <Text bold size='28px' height='40px' style={{ textAlign: 'center', maxWidth: '400px' }}>{title}</Text>
                        <div style={{ width: '80px', height: '1px', background: 'radial-gradient(83.75% 38.54% at 16.25% 86.88%, #1199FF 0%, #1A8AFA 100%)' }} />
                        {desc}
                        {cta}
                    </FlexColumn>
                    {showBgDesign &&
                        <FlexColumn start centerHorizontally>
                            <CardContainer style={{ width: '625px', marginTop: '200px', zIndex: 5 }}>
                                <Text>With Aion, I can finally perform all financial functions for my business in one comprehensive place. Aion’s intuitive, user-friendly  app saves me a bunch of time and as a business owner, that means a bunch of money too</Text>

                                <Flex between style={{ marginTop: 12, width: '100%' }}>
                                    <Flex start>
                                        {/* <img width='48px' height='48px' src={Profile1} /> */}
                                        <FlexColumn between style={{ height: 48, marginLeft: 12 }}>
                                            <Text color='#1199FF'>Christopher V. Basso</Text>
                                            <Text size='12px' height='20px' color='#666666'>President, Quench Design</Text>
                                        </FlexColumn>
                                    </Flex>

                                    <img width='48px' height='48px' src={Quote} />
                                </Flex>
                            </CardContainer>
                        </FlexColumn>
                    }
                </FlexColumn>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        onboardingStore: state.onboardingAppReducer,
        aionStore: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(BankAppStatus)));