import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';

import { Flex, FlexColumn } from '../../Reusable/Container';
import FlowViewComponent from '../../Reusable/Refresh/FlowViewComponent';
import { LabeledInput } from '../../Reusable/Input';
import { Text } from '../../Reusable/Refresh/Text';
import { Image } from '../../Reusable/Image';
import { addDataToStore, REMOTE_SAVE_DATA, UAM_SAVE_BUSINESS } from '../../../Actions/actions';
import { ImageButton } from '../../Reusable/Button';
import { Button } from '../../Reusable/Refresh/Button';
import { ErrorAlert } from '../../Reusable/Alert';
import { DownOutlined } from '@ant-design/icons';
import { Collapse, Divider, Select } from 'antd';
import { addressObjectToStr, STATES_MAP, toCurrency } from '../../../Utils/util';
import { OwnerCard, SubsidirayCard } from '../../Reusable/Refresh/Card';
import FinancialInfo from './FinancialInfo';
import CreditUserProfile from './CreditUserProfile';
import { capitalize } from 'lodash';
import { apiPOSTReq } from '../../../Utils/api';
import CollapseCheck from '../../../Images/collapse-check.png';
import environment from '../../../environment';
import moment from 'moment'
import Edit from '../../../Images/edit.png'
import QB from '../../../Images/quickbooks-icon-sm.jpeg'
import { StyledExtLink } from '../../Reusable/Link';

const { Option } = Select;

class PSReview extends Component {

    state = {
    };

    componentDidMount() {
        this.fetchBusiness();
    }

    fetchBusiness = () => {
        apiPOSTReq(`${environment.uamBaseUrl}/getBusinessOwnership`, {}, {}, (err, resp) => {
            try {
                if (err) throw Error(err);
                const data = resp || {};
                if (data.result) {
                    var business = data.business || {}
                    this.props.dispatch(addDataToStore(UAM_SAVE_BUSINESS, data));
                } else {
                    throw Error(data.responseMessage || data.error);
                }
            } catch (error) {
                console.log("/getBusiness err", error, resp);
            }
        });
    }

    validateFlow = () => {
        if (!this.state.submitLoading) {
            this.setState({ submitLoading: true });
            var { aionStore, onboardingStore, creditStore } = this.props || {};
            var { businessInfo, coOwnersInfo, personalInfo } = onboardingStore || {};
            businessInfo = businessInfo || {};
            var { name, physicalAddress, capitalRequest } = businessInfo;
            var { UserInfo, subscriptionPlanName, subscriptionBilling } = aionStore || {};
            var { loanApplication } = creditStore;
            var body = {
                "primaryApplicantInfo": {
                    "firstName": personalInfo.firstName,
                    "lastName": personalInfo.lastName,
                    "phone": UserInfo.Phone,
                    "email": UserInfo.Email,
                    "ssn": personalInfo.ssn,
                    "dob": personalInfo.dob,
                    "address": personalInfo.address,
                    "ownershipPercentage": personalInfo.ownershipPercentage,
                    "businessTitle": personalInfo.businessTitle,
                    "citizenOrResident": personalInfo.citizenOrResident,
                    "countryOfCitizenship": personalInfo.countryOfCitizenship,
                    "iscontractOwner": personalInfo.isContractOwner
                },
                "coOwnerInfo": coOwnersInfo,
                "businessInfo": {
                    "physicalAddress": physicalAddress,
                    ...businessInfo
                },
                "onboardingType": "CreditApplication",
                "subscriptionPlanName": capitalize(subscriptionPlanName),
                "subscriptionBilling": capitalize(subscriptionBilling),
                "applicationId": loanApplication.applicationId
            }

            // (body.coOwnerInfo || []).forEach(owner => owner.ownershippercentage = Math.ceil(owner.ownershippercentage))

            console.log("/creditSignUp BODY", body);
            // Update the application with the closing month
            apiPOSTReq(`${environment.uamBaseUrl}/creditSignUp`, {}, body, (err, resp) => {
                console.log("/creditSignUp err, resp", err, resp);
                try {
                    const data = resp || {};
                    if (data.result) {
                        loanApplication = loanApplication || {};
                        this.props.updateLoanApp({
                            "applicantFirstName": UserInfo.FirstName,
                            "applicantLastName": UserInfo.LastName,
                            "emailAddress": UserInfo.Email,
                            "userStatus": "PreScreenCompleted",
                            "businessName": name
                        });

                        var notificationBody = {
                            "fromAddr": "Aion <notification@aionfi.com>",
                            "toAddr": process.env.REACT_APP_ENV == "production" ? "kveid@aionfi.com, cpetre@aionfi.com, bsterrett@aionfi.com" : "stagingmoderators@mg.aionfi.com",
                            // "ccAddr" : "", 
                            // "bccAddr" : "" , 
                            "emailSubject": `New pre-screen credit application`,
                            "emailBody": `<p>Dear Credit Team,<p>A new pre-screen credit application was submitted, you can view this application by logging in to https://app.aionfi.com.</p><p>ID: <b>${(loanApplication || {}).applicationId}</b><br/>Business: <b>${name}</b><br/>User: <b>${UserInfo.FirstName} ${UserInfo.LastName}</b></p></p>`
                        }

                        var header = {
                        }

                        // apiPOSTReq(`${environment.opsBaseUrl}/ops/sendEmail`, header, notificationBody, (err, resp) => {
                        //     console.log("sendEmail", err, resp, notificationBody);

                        //Email Triggered to User confirmation
                        var userNotificationBody = {
                            "fromAddr": "Aion <credit@aionfi.com>",
                            "toAddr": UserInfo.Email,
                            "ccAddr": process.env.REACT_APP_ENV != "production" ? "stagingmoderators@mg.aionfi.com" : "",
                            "bccAddr": process.env.REACT_APP_ENV != "production" ? "akumar@aionfi.com" : "",
                            "emailSubject": `Aion Application Submitted ✅`,
                            "emailBody": `<p>Hi ${UserInfo.FirstName},<p>Your application for financing has been received! Thank you for taking the time to apply. Aion is hard at work reviewing the information you provided.</p><p>Stay tuned for an update on the status of your application.</p></p>`
                        }

                        var header = {
                        }

                        // apiPOSTReq(`${environment.opsBaseUrl}/ops/sendEmail`, header, userNotificationBody, (err, resp) => {
                        apiPOSTReq(`${environment.opsBaseUrl}/ops/sendEmail`, null, userNotificationBody, (err, resp) => {
                            console.log("sendEmail", err, resp, userNotificationBody);
                        })
                        // })

                        var orchestratorBody = {
                            "businessId": loanApplication.businessId,
                            "clientBusinessId": loanApplication.businessId,
                            "applicationId": loanApplication.applicationId,
                            "requestedAmount": parseFloat(capitalRequest.replace(/[$,]/g, ''))
                        }

                        apiPOSTReq(`${environment.uamBaseUrl}/orchestrator`, header, orchestratorBody, (err, resp) => {
                            console.log("orchestrator", err, resp, orchestratorBody);
                        })
                        apiPOSTReq(`${environment.uamBaseUrl}/getBusiness`, {}, {}, (err, resp) => {
                            try {
                                if (err) throw Error(err);
                                const data = resp || {};
                                if (data.result) {
                                    var business = data.business || {}
                                    this.props.dispatch(addDataToStore(UAM_SAVE_BUSINESS, data));
                                } else {
                                    throw Error(data.responseMessage || data.error);
                                }
                            } catch (error) {
                                console.log("/getBusiness err", error, resp);
                            }
                        });
                        this.setState({ submitLoading: false });

                        this.props.loadNext();
                    } else {
                        throw Error(data.responseMessage || data.error)
                    }
                } catch (error) {
                    ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
                }
            });
        }

    }

    editYourInfo = () => (
        <ImageButton src={Edit} onClick={(e) => {
            e.stopPropagation();
            this.props.updateCurrentViewIndex(3);
        }} />
    );
    editBusinessProfile = () => (
        <ImageButton src={Edit} onClick={(e) => {
            e.stopPropagation();
            this.props.updateCurrentViewIndex(5);
        }} />
    );
    editOwnershipInfo = () => (
        <ImageButton src={Edit} onClick={(e) => {
            e.stopPropagation();
            this.props.updateCurrentViewIndex(6);
        }} />
    );
    editFinancialInfo = () => (
        <ImageButton margin="10px 0 0 0" src={Edit} onClick={(e) => {
            e.stopPropagation();
            this.props.updateCurrentViewIndex(8);
        }} />
    )

    render() {
        var { theme, flowView, currentViewIndex, loadPrevView, onboardingStore, loanApplication, aionStore } = this.props;
        var { businessInfo, coOwnersInfo, personalInfo } = onboardingStore;
        var { physicalAddress, mailingAddress, subsidiaries } = businessInfo;
        var { firstName, lastName, dob, address, phone, ssn, ownershipPercentage, businessTitle } = personalInfo;
        var mulipleStates = [];
        var usStates = [];
        const { business } = aionStore;
        const businessAttributes = business.attributes || {};
        mulipleStates.push(
            <Option key={"All"} value={"All"}>
                {"All States"}
            </Option>
        )
        Object.keys(STATES_MAP).forEach(item => {
            usStates.push(
                <Option key={item} value={item}>
                    {item}
                </Option>
            )

            mulipleStates.push(
                <Option disabled={this.state.statesOfOperation ? this.state.statesOfOperation.includes('All') : false} key={item} value={item}>
                    {item}
                </Option>
            )
        });
        var childViews = (
            <FlexColumn start style={{ gap: "24px" }}>
                <Text style={{ fontSize: "20px" }} weight='400'>Ready for submission!</Text>
                <Text style={{ fontSize: "16px" }} weight='400'>Please review the information you have provided before submitting your application.</Text>
                <FlexColumn start left style={{ width: '100%', height: 116, borderRadius: '8px', padding: 24, background: 'conic-gradient(from 99.91deg at 16.25% 86.88%, #1199FF 0deg, #1A8AFA 360deg)', marginBottom: 24 }}>
                    <Text margin='0 0 4px' color='white'>Your funding request</Text>
                    <Text margin='0 0 4px' color='white' size='28px' weight='500' height='40px'>{(businessInfo && businessInfo.capitalRequest && `$${toCurrency(businessInfo.capitalRequest)}`)}</Text>
                </FlexColumn>
                <FlexColumn fullWidth gap='24px'>
                    <Collapse
                        onChange={() => this.setState({ "isYourInfoActive": !this.state.isYourInfoActive })}
                        bordered={true}
                        expandIcon={(obj) => <DownOutlined rotate={obj.isActive ? 180 : 0} end style={{
                            color: "#1199FF",
                            fontSize: "20px",
                            marginTop: "2px"
                        }} />}
                        ghost
                        expandIconPosition="end"
                        style={{ boxShadow: "0px 4px 8px rgba(102, 102, 102, 0.08)", padding: "24px", gap: "24px" }}
                    >
                        <Collapse.Panel header={
                            <>
                                <Image margin='0 12px 5px' src={CollapseCheck} />
                                <Text heading>Your Information</Text>
                            </>
                        } extra={this.state.isYourInfoActive ? this.editYourInfo() : null}>
                            <Divider />
                            <CreditUserProfile reviewView />
                        </Collapse.Panel>
                    </Collapse>
                    <Collapse
                        onChange={() => this.setState({ "isBusinessProfileActive": !this.state.isBusinessProfileActive })}
                        bordered={true}
                        expandIcon={(obj) => <DownOutlined rotate={obj.isActive ? 180 : 0} end style={{
                            color: "#1199FF",
                            fontSize: "20px",
                            marginTop: "2px"
                        }} />}
                        ghost
                        expandIconPosition="end"
                        style={{ boxShadow: "0px 4px 8px rgba(102, 102, 102, 0.08)", padding: "24px", gap: "24px" }}
                    >
                        <Collapse.Panel header={
                            <>
                                <Image margin='0 12px 5px' src={CollapseCheck} />
                                <Text heading> Business Information</Text>
                            </>
                        } extra={this.state.isBusinessProfileActive ? this.editBusinessProfile() : null}>
                            <Divider />
                            <Flex between style={{ width: "100%", minHeight: '105px' }}>
                                <div style={{ width: "25%", marginRight: 12 }}>
                                    <LabeledInput
                                        autoFocus
                                        labelcolor={theme.colors.secondary3}
                                        label="Business Name"
                                        id="businessName"
                                        type="read-only"
                                        value={businessInfo.name}
                                    />
                                </div>
                                <div style={{ width: "25%", marginRight: 12, marginLeft: 12 }}>
                                    <LabeledInput
                                        labelcolor={theme.colors.secondary3}
                                        label="Incorporation Type"
                                        type="read-only"
                                        key={flowView.OnboardingStep}
                                        size="large"
                                        placeholder="Select an option" value={businessInfo.incorporationType}
                                    >
                                    </LabeledInput>
                                </div>
                                <div style={{ width: "25%", marginRight: 12, marginLeft: 12 }}>
                                    <LabeledInput
                                        label="Formation Date"
                                        type="read-only"
                                        disabledDate={this.disabledDate}
                                        key={flowView.OnboardingStep + ".Date"}
                                        id="incorporationYear"
                                        value={moment(businessInfo.incorporationDate).format('MMM DD, YYYY')}
                                        placeholder="Pick an Year"
                                        picker="year"
                                    />
                                </div>
                                <div style={{ width: "25%", marginLeft: 12 }}>
                                    <LabeledInput
                                        labelcolor={theme.colors.secondary3}
                                        label="Formation State"
                                        type="read-only"
                                        size="large"
                                        key={flowView.OnboardingStep + ".State"}
                                        id="incorporationState"
                                        placeholder="Select a state"
                                        value={businessInfo.incorporationState}
                                    >
                                    </LabeledInput>
                                </div>
                            </Flex>
                            <Flex between style={{ width: "100%", minHeight: '105px' }}>
                                <div style={{ width: "25%", marginRight: 12, }}>
                                    <LabeledInput
                                        labelcolor={theme.colors.secondary3}
                                        label="DBA or Trade Name"
                                        id="dba"
                                        type="read-only"
                                        value={businessInfo.dba}
                                        placeholder={businessInfo.name}
                                    />
                                </div>
                                <div style={{ width: "25%", marginRight: 12, marginLeft: 12 }}>
                                    <LabeledInput
                                        labelcolor={theme.colors.secondary3}
                                        label="States of Operation"
                                        type="read-only"
                                        size="large"
                                        mode="multiple"
                                        className="no-left-padding"
                                        dropdownClassName="no-left-padding"
                                        key={flowView.OnboardingStep + ".StatesOfOperation"}
                                        id="statesOfOperation"
                                        placeholder="Select all that apply"
                                        optionFilterProp="children"
                                        value={businessInfo.statesOfOperation && businessInfo.statesOfOperation.map((val, i) => {
                                            return <>{i > 0 ? <>, </> : ''}{val}</>
                                        })}
                                        divider={false}
                                    >
                                        {mulipleStates}
                                    </LabeledInput>
                                </div>
                                <div style={{ width: "25%", marginRight: 12, marginLeft: 12 }}>
                                    <LabeledInput
                                        labelcolor={theme.colors.secondary3}
                                        label="Industry"
                                        type="read-only"
                                        size="large"
                                        key={flowView.OnboardingStep + ".Industry"}
                                        id="industry"
                                        placeholder="Select an industry"
                                        optionFilterProp="children"
                                        onChange={value => { this.saveUserInput("industry", value) }}
                                        value={businessInfo.industry}
                                        divider={false}
                                    >
                                    </LabeledInput>
                                </div>
                                <div style={{ width: "25%", marginLeft: 12 }}>
                                    <LabeledInput
                                        onKeyDown={this.handleOnKeyDown}
                                        labelcolor={this.props.theme.colors.secondary3}
                                        label="Number of Employees"
                                        key={flowView.OnboardingStep}
                                        id="employeeCount"
                                        value={businessInfo.employeeCount}
                                        placeholder="10"
                                        type="read-only"
                                    />
                                </div>
                            </Flex>

                            <Flex between style={{ width: "100%", minHeight: '105px' }}>
                                <div style={{ width: "25%", marginRight: 12 }}>
                                    <LabeledInput
                                        autoFocus
                                        labelcolor={theme.colors.secondary3}
                                        label="Business Phone Number"
                                        id="phone"
                                        type="read-only"
                                        value={businessInfo.phone}
                                        placeholder="Enter phone number"
                                    />
                                </div>
                                <div style={{ width: "25%", marginRight: 12, marginLeft: 12 }}>
                                    <LabeledInput
                                        autoFocus
                                        labelcolor={theme.colors.secondary3}
                                        label="Website"
                                        id="websiteUrl"
                                        type="read-only"
                                        value={businessInfo.websiteUrl}
                                        placeholder="Enter website URL"
                                    />
                                </div>
                                <div style={{ width: "25%", marginRight: 12, marginLeft: 12 }}>
                                    <LabeledInput
                                        type="read-only"
                                        id={"Address".toLowerCase()}
                                        key={"Address"}
                                        value={addressObjectToStr(physicalAddress)}
                                        label="Business Address"
                                        placeholder={`Enter ${"Address"}`}
                                        // nextLine={true}
                                        // country
                                        divider={false}
                                        fullWidth
                                    />
                                </div>
                                <div style={{ width: "25%", marginLeft: 12 }}>
                                    <LabeledInput
                                        type="read-only"
                                        id='mailingAddress'
                                        key='mailingAddress'
                                        value={addressObjectToStr(mailingAddress)}
                                        label="Mailing Address"
                                        placeholder={`Enter ${"Address"}`}
                                        // nextLine={true}
                                        // country
                                        divider={false}
                                        fullWidth
                                    />
                                </div>
                            </Flex>
                            <Flex between style={{ width: "100%", minHeight: '105px' }}>
                                <div style={{ width: "55%", marginRight: 12 }}>
                                    <LabeledInput
                                        type="read-only"
                                        label="Brief description of business"
                                        value={businessInfo.description}
                                    />
                                </div>
                            </Flex>
                        </Collapse.Panel>
                    </Collapse>
                    <Collapse
                        onChange={() => this.setState({ "isOwnershipInfoActive": !this.state.isOwnershipInfoActive })}
                        bordered={true}
                        expandIcon={(obj) => <DownOutlined rotate={obj.isActive ? 180 : 0} end style={{
                            color: "#1199FF",
                            fontSize: "20px",
                            marginTop: "2px"
                        }} />}
                        ghost
                        expandIconPosition="end"
                        style={{ boxShadow: "0px 4px 8px rgba(102, 102, 102, 0.08)", padding: "24px", gap: "24px" }}
                    >
                        <Collapse.Panel header={
                            <>
                                <Image margin='0 12px 5px' src={CollapseCheck} />
                                <Text heading> Ownership Information</Text>
                            </>
                        } extra={this.state.isOwnershipInfoActive ? this.editOwnershipInfo() : null}>
                            <Divider />
                            <FlexColumn style={{ width: "100%", marginBottom: 15 }}>
                                <Text weight='500' color='#7384AA'>YOUR OWNERSHIP</Text>
                                <Flex start wrap fullWidth gap='24px'>
                                    <LabeledInput
                                        type="read-only"
                                        label="Name"
                                        value={`${firstName} ${lastName}`}
                                        width='288px'
                                    />
                                    <LabeledInput
                                        type="read-only"
                                        label="Title"
                                        value={businessTitle}
                                        width='288px'
                                    />
                                    <LabeledInput
                                        type="read-only"
                                        label="Ownership Percentage"
                                        value={`${ownershipPercentage}%`}
                                        width='288px'
                                    />
                                </Flex>
                            </FlexColumn>
                            <FlexColumn start>
                                <FlexColumn style={{ width: "100%", marginBottom: 15 }}>
                                    <Text style={{ marginBottom: 8 }} weight='500' color='#7384AA'>OWNERS</Text>
                                    {
                                        coOwnersInfo && coOwnersInfo.length > 0 ?
                                            <Flex wrap style={{ marginTop: 12 }} start >
                                                {(coOwnersInfo || []).map((item, j) => {
                                                    return (
                                                        <OwnerCard hideEdit={true} ownerInfos={item} theme={theme} symbolprefix="%" key={j} />
                                                    )
                                                })}
                                            </Flex>
                                            :
                                            <Text margin='8px 0 0'>None added</Text>
                                    }
                                </FlexColumn>
                                <FlexColumn style={{ width: "100%", margin: "15px 0" }}>
                                    <Text style={{ marginBottom: 8 }} weight='500' color='#7384AA'>PARENT COMPANIES & SUBSIDIARIES</Text>
                                    {
                                        subsidiaries && subsidiaries.length > 0 ?
                                            <Flex wrap style={{ marginTop: 12 }} start >
                                                {(subsidiaries || []).map((item, j) => {
                                                    return (
                                                        <SubsidirayCard subsidirayInfo={item} theme={theme} key={j} />
                                                    );
                                                })}
                                            </Flex>
                                            :
                                            <Text margin='8px 0 0'>None added</Text>
                                    }
                                </FlexColumn>
                            </FlexColumn>
                        </Collapse.Panel>
                    </Collapse>

                    <Collapse
                        onChange={() => this.setState({ "isFinancialInfoActive": !this.state.isFinancialInfoActive })}
                        bordered={true}
                        expandIcon={(obj) => <DownOutlined rotate={obj.isActive ? 180 : 0} end style={{
                            color: "#1199FF",
                            fontSize: "20px",
                            marginTop: "2px"
                        }} />}
                        ghost
                        expandIconPosition="end"
                        style={{ boxShadow: "0px 4px 8px rgba(102, 102, 102, 0.08)", padding: "24px", gap: "24px" }}
                    >
                        <Collapse.Panel header={
                            <>
                                <Image margin='0 12px 5px' src={CollapseCheck} />
                                <Text heading> Financial Information</Text></>
                        } extra={
                            <Flex gap="20px">
                                {businessAttributes.AccountingSWLinked &&
                                    <Button noClick noBorder background="#F5F9F5" margin='0 0 0 0' color="#318F2F" padding='0 0 0 0' width='290px' weight='500' height='40px' radius='4px'
                                        text="Retrieved from Quickbooks Online" icon={<Image src={QB} width='24px' height='24px' />} ></Button>
                                }

                                {this.state.isFinancialInfoActive ? this.editFinancialInfo() : null}</Flex>
                        }>
                            <Divider />
                            <FinancialInfo loanApplication={loanApplication} readonly flowView={flowView} />
                        </Collapse.Panel>
                    </Collapse>
                </FlexColumn>
                <Text>By clicking submit you agree to our <StyledExtLink target="_blank" href="https://aion-assets.s3.us-west-2.amazonaws.com/terms/aion-credit-profile-authorization.html">Credit Profile Authorization</StyledExtLink> agreement.</Text>
            </FlexColumn>
        );
        return (
            <FlowViewComponent
                flowView={flowView}
                currentViewIndex={currentViewIndex}
                loadPrev={loadPrevView}
                childViews={childViews}
                width={'100%'}
                back={currentViewIndex > 0}
                padding='0 64px'
                {...this.props}
                loadNext={this.validateFlow}
                submitLoading={this.state.submitLoading}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        onboardingStore: state.onboardingAppReducer,
        aionStore: state.aionAppReducer,
        creditStore: state.creditAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(PSReview));