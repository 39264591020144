import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import cloneDeep from 'lodash/cloneDeep'
import { Table, Tabs, Dropdown, Skeleton, Menu, Modal } from 'antd'
import moment from 'moment'

import { Text, Tag } from '../../Reusable/Text'
import { Image } from '../../Reusable/Image'
import { Flex, FlexColumn, CardContainer, LightContainer } from '../../Reusable/Container'
import PageHeader from '../../Reusable/PageHeader'
import { LabeledInput } from '../../Reusable/Input'
import { Button, Chip, ImageButton, TextButton } from '../../Reusable/Button'
import RecipientTable from '../Reusable/RecipientTable'
import { ErrorAlert } from '../../Reusable/Alert'
import { ApprovalJourney } from '../../Reusable/Approval'
import ViewTransferModal from './ViewTransferModal'
import Cat from '../../../Images/cat.png'
import ChevronDown from "../../../Images/chevron-down.png"
import ChevronUp from "../../../Images/chevron-up.png"
import Individual from '../../../Images/individual-recipient.png'
import Business from '../../../Images/business-recipient.png'
import FilterIcon from '../../../Images/filter.png'
import ApprovalDivider from '../../../Images/approval-divider.png'
import ModalClose from '../../../Images/modal-close.png'

import Search from '../../../Images/search.png'
import { PlusOutlined } from '@ant-design/icons'
import { addDataToStore, SAVE_DATA } from '../../../Actions/actions'
import { fetchCounterparties } from '../../../Utils/bankingDataManager'
import { apiPOSTReq, getPreSignedS3Url } from '../../../Utils/api'
import environment from '../../../environment'
import TableView from '../../Reusable/TableView'

const { TabPane } = Tabs

class Index extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            loadingPending: true,
            pagination: {
                current: 1,
                pageSize: 1000,
                hideOnSinglePage: true
            },
            recipients: [],
            inactiveRecipients: [],
            pendingRecipients: [],
            selectedTab: "active",
            collapsed: true,
            objectMap: {},
        }

        this.back = this.props.location.state && this.props.location.state.back
    }

    componentDidMount() {
        const { pagination } = this.state
        this.fetchRecipients()
        this.fetchRecipients2()

        fetchCounterparties({ pagination, active: false }, (err, resp) => {
            if (resp && resp.result) this.setState({ loading: false, inactiveRecipients: resp.counterpartiesList })
        })

        fetchCounterparties({ pagination, viewBy: 'CP_PENDING_STATUS' }, (err, resp) => {
            var pendingRecipients = resp?.counterpartiesList
            // if((pendingRecipients || []).length > 0) this.handleTabChange("pending_approval")
            if (resp && resp.result) this.setState({ loadingPending: false, pendingRecipients: pendingRecipients })
        })
    }

    fetchRecipients = () => {
        this.setState({ loading: true })
        const { pagination, achSelected, wireSelected } = this.state
        fetchCounterparties({ viewBy: achSelected ? 'ACH' : wireSelected ? 'WIRE' : null, pagination, active: true }, (err, resp) => {

            console.log("fetchRecipients: ", resp)

            if (resp && resp.result) {
                this.setState({ loading: false, recipients: resp.counterpartiesList })
            }
        })
    }

    fetchRecipients2 = () => {
        this.setState({ loading: true })

        const body = { railType: this.rail ? this.rail.toUpperCase() : null }

        apiPOSTReq(`${environment.bbBaseUrl}/bb/getPaymentMethodsByRecipients`, null, body, (err, resp) => {
            try {
                const data = resp

                console.log("/bb/getPaymentMethodsByRecipients", data)

                if (data.result) {
                    if (err) throw new Error(err)

                    if (resp && resp.result) {
                        var sortedArray = Object.values(resp.recipientMap || []).sort((a, b) => {
                            // Handle null values by setting them to a default date in the distant past
                            const dateA = a.lastTransferDate || "1900-01-01";
                            const dateB = b.lastTransferDate || "1900-01-01";
                            return new Date(dateB) - new Date(dateA);  // Sort in descending order
                        });

                        this.setState({
                            loading: false,
                            // recipients: sortedArray,
                            noData: (!sortedArray || sortedArray.length === 0),
                            paymentMethodRecipientMap: resp.paymentMethodRecipientMap,
                            recipientMap: resp.recipientMap,
                        })
                    }
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getPaymentMethodsByRecipients", error, err, resp)
            } finally {
                this.setState({
                    loading: false
                })
            }
        })
    }

    handleOnChange = (e) => {
        this.setState({ [e.target.id]: e.target.value })

        const pagination = {
            current: 1,
            pageSize: 1000
        }

        fetchCounterparties({ pagination, searchStr: e.target.value }, (err, resp) => {
            if (resp && resp.result) this.setState({ loading: false, recipients: resp.counterpartiesList })
        })
    }

    newRecipient = () => {
        this.props.history.push('/banking/recipients/new')
    }

    handleTabChange = (key) => {
        this.setState({ selectedTab: key })
    }

    viewRecipient = (recipient) => {
        this.props.history.push({
            pathname: '/banking/recipients/details',
            state: { recipient }
        })
    }

    getApprovalInstance = (record, objectDetail) => {
        var { objectMap } = this.state
        var { objectId, approvalInstanceId } = record
        if (objectDetail) approvalInstanceId = objectDetail.approvalInstanceId
        apiPOSTReq(`${environment.bbBaseUrl}/bb/approvals/getApprovalInstance `, { "BankProvider": environment.bankProvider.crb }, { instanceId: approvalInstanceId, }, (err, resp) => {
            try {
                const data = resp
                if (data.result && data.approvalRuleInstance) {
                    const objectMapClone = cloneDeep(objectMap)
                    if (objectMapClone[objectId]) {
                        objectMapClone[objectId].approvalInstance = data.approvalRuleInstance
                    } else {
                        objectMapClone[objectId] = { approvalInstance: data.approvalRuleInstance } || true
                    }

                    if (objectDetail) objectMapClone[objectId].objectDetail = objectDetail

                    this.setState({ objectMap: objectMapClone })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                ErrorAlert({ description: error.message })
            }
        })
    }

    getRowDetail = (record) => {
        this.getApprovalInstance(record)
    }

    getRowDetails = (record, props) => {
        var { objectMap, selectedRowId } = this.state
        var { objectId, status, type, userNote, description, additionalDetails } = record
        var object = objectMap[objectId]
        var { approvalInstance, objectDetail } = (object || {})

        console.log("getRowDetails", object, record, objectMap, selectedRowId)
        return (
            <Skeleton loading={!object} active title={false} paragraph={{ rows: 3 }}>
                <FlexColumn start>
                    <LightContainer fullWidth>
                        <ApprovalJourney instance={approvalInstance} margin='0' />
                    </LightContainer>
                </FlexColumn>
            </Skeleton >
        )
    }

    render() {
        const { loading, pagination, recipients, searchTerm, noData, selectedTab, pendingRecipients, collapsed, transferMethod,
            inactiveRecipients, dropdownVisible, achSelected, wireSelected, paymentMethodRecipientMap, showTransferModal, recipient, selectedRowId } = this.state

        var columns = [
            {
                title: 'Name',
                dataIndex: 'nameOnAccount',
                key: 'nameOnAccount',
                render: (item, record) => <Flex fullWidth between gap='12px'>
                    <FlexColumn left start gap='12px'>
                        <Flex between start gap='12px'>
                            {
                                record.type === 'Individual' ?
                                    <img src={Individual} width='24px' height='24px' />
                                    :
                                    <img src={Business} width='24px' height='24px' />
                            }
                            <a style={{ textDecoration: "underline", color: "#1199FF", marginLeft: 12 }} onClick={() => this.viewRecipient(record)}>
                                <Text primary>{record.nickName || item}</Text>
                            </a>
                        </Flex>
                        {
                            (record.objectId == selectedRowId) &&
                            <>
                                {this.getRowDetails(record)}
                            </>
                        }
                    </FlexColumn>
                    {
                        (record.objectId != selectedRowId) ?
                            <img style={{ cursor: 'pointer' }} width='24px' height='24px' src={ChevronDown} />
                            :
                            <img style={{ cursor: 'pointer' }} width='24px' height='24px' src={ChevronUp} />
                    }
                </Flex>
            }
        ]

        return (
            <FlexColumn className='main-padding' start>
                {/* {
                    !noData && */}
                <>
                    <PageHeader
                        titleText="Banking"
                        back={this.back}
                        subtitle='Banking'
                        ctaContent={
                            <Button solid onClick={this.newRecipient} icon={<PlusOutlined style={{ color: 'white' }} />} text="ADD RECIPIENT" />
                        }
                    />

                    {/* <CardContainer margin='0 0 24px' padding='8px 24px 24px' fullHeight> */}
                        <FlexColumn start fullHeight>
                            <Tabs size="large" defaultActiveKey={selectedTab} onChange={this.handleTabChange} style={{ marginBottom: 12 }}>
                                <TabPane tab="Active" key="active" />
                                { pendingRecipients && pendingRecipients.length > 0 && <TabPane tab="Pending Approval" key="pending_approval" /> }
                                <TabPane tab="Inactive" key="inactive" />
                            </Tabs>
                            

                            {
                                selectedTab === 'active' &&
                                <RecipientTable
                                    view
                                    fetch={fetchCounterparties}
                                    pagination={pagination}
                                    recipients={recipients}
                                    loading={loading}
                                    paymentMethodRecipientMap={paymentMethodRecipientMap}
                                    onViewTransfer={(transferMethod, recipient) => this.setState({ transferMethod, showTransferModal: true, recipient })}
                                    ctaContent={
                                        <FlexColumn start gap='24px'>
                                            <Flex between fullWidth gap='24px'>
                                                <LabeledInput
                                                    id="searchTerm"
                                                    nomargin
                                                    width='250px'
                                                    placeholder="Search by name"
                                                    prefixIcon={<Image width='12px' height='12px' src={Search} />}
                                                    value={searchTerm}
                                                    onChange={this.handleOnChange}
                                                />

                                                <Flex style={{ height: 40 }} start centerHorizontally>
                                                    {
                                                        (achSelected || wireSelected) &&
                                                        <Flex end centerHorizontally>
                                                            <Flex start wrap gap='8px' centerHorizontally>
                                                                {
                                                                    achSelected &&
                                                                    <Chip closable onDelete={() => {
                                                                        this.setState({ achSelected: false }, this.fetchRecipients)
                                                                    }} text='ACH' />
                                                                }

                                                                {
                                                                    wireSelected &&
                                                                    <Chip closable onDelete={() => {
                                                                        this.setState({ wireSelected: false }, this.fetchRecipients)
                                                                    }} text='Wire' />
                                                                }
                                                            </Flex>

                                                            <Image src={ApprovalDivider} />

                                                            {/* <Text primary noWrap underline style={{ cursor: 'pointer' }} onClick={() => this.setState({ achSelected: false, wireSelected: false }, this.fetchRecipients)}>Clear all</Text> */}
                                                        </Flex>
                                                    }

                                                    <Dropdown
                                                        placement='bottomRight'
                                                        trigger={['click']}
                                                        onVisibleChange={flag => this.setState({ dropdownVisible: flag })}
                                                        visible={dropdownVisible}
                                                        overlay={(
                                                            <Menu onClick={() => { }} style={{ width: 230, padding: '12px 24px 24px', borderRadius: 8 }}>
                                                                <Tabs defaultActiveKey="2" onClick={() => { }} onChange={this.onTabChange}>
                                                                    <TabPane tab="Type" key="type">
                                                                        <Flex start wrap gap='8px'>
                                                                            <Chip onAdd={() => this.setState({ achSelected: true, wireSelected: false, dropdownVisible: false }, this.fetchRecipients)} onDelete={() => this.setState({ achSelected: false, dropdownVisible: false }, this.fetchRecipients)} selected={achSelected} add closable text='ACH' background='white' />
                                                                            <Chip onAdd={() => this.setState({ wireSelected: true, achSelected: false, dropdownVisible: false }, this.fetchRecipients)} onDelete={() => this.setState({ wireSelected: false, dropdownVisible: false }, this.fetchRecipients)} selected={wireSelected} add closable text='Wire' background='white' />
                                                                        </Flex>
                                                                    </TabPane>
                                                                </Tabs>
                                                            </Menu>
                                                        )}>
                                                        <TextButton text='FILTER' rightIcon={<Image src={FilterIcon} />} />
                                                    </Dropdown>
                                                </Flex>
                                                <Button solid onClick={this.newRecipient} icon={<PlusOutlined style={{ color: 'white' }} />} text="ADD RECIPIENT" />
                                            </Flex>
                                        </FlexColumn>
                                    }
                                />
                            }

                            {
                                selectedTab === 'inactive' &&
                                <RecipientTable view fetch={fetchCounterparties} pagination={pagination} recipients={inactiveRecipients} loading={loading} />
                            }

                            {
                                selectedTab === 'pending_approval' &&
                                <TableView
                                    id="pending-table"
                                    // className='borderless-table'
                                    titleText='Recipients'
                                    // tableLayout='auto'
                                    columns={columns}
                                    dataSource={pendingRecipients}
                                    rowKey='objectId'
                                    pagination={false}
                                    /*expandable={{
                                        expandedRowRender: record => this.getRowDetails(record, this.props),
                                        expandRowByClick: false,
                                        expandIconColumnIndex: 4,
                                        expandIcon: ({ expanded, onExpand, record }) => {
                                            if (record.dateRow) {
                                                return null
                                            } else {
                                                return !expanded ? (
                                                    <img style={{ cursor: 'pointer' }} width='24px' height='24px' src={ChevronDown} onClick={e => {
                                                        this.getRowDetail(record)
                                                        onExpand(record, e)
                                                    }} />
                                                ) : (
                                                    <img style={{ cursor: 'pointer' }} width='24px' height='24px' src={ChevronUp} onClick={e => onExpand(record, e)} />
                                                )
                                            }
                                        }
                                    }}*/
                                    onRow={ 
                                        (record, rowIndex) => {
                                            return {
                                                onClick: event => {
                                                    if(record.objectId == selectedRowId) {
                                                        this.setState({ selectedRowId: null })
                                                    } else {
                                                        this.getRowDetail(record)
                                                        this.setState({ selectedRowId: record.objectId })
                                                    }
                                                    
                                                }, // click row
                                            }
                                        }
                                    }
                                />
                            }
                        </FlexColumn>
                    {/* </CardContainer> */}
                </>

                <Modal
                    visible={showTransferModal}
                    width={616}
                    closable={true}
                    maskClosable={true}
                    style={{ borderRadius: '8px !important' }}
                    footer={null}
                    destroyOnClose={true}
                    onCancel={() => this.setState({ showTransferModal: false, transferMethod: null, recipient: null })}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <ViewTransferModal
                        recipient={recipient}
                        transferMethod={transferMethod}
                    />
                </Modal>
            </FlexColumn>
        )
    }

    twoFAValidated = () => {
        this.props.dispatch(addDataToStore(SAVE_DATA, { "TwoFAForTransfersValidated": true }))
        this.loadNext()
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer,
        bankingStore: state.bankingAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)))