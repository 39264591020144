import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Auth } from 'aws-amplify'
import styled, { withTheme } from 'styled-components'

import {
    Layout, message
} from 'antd'

// Util
import { isEmailValid } from '../../Utils/util'
import { containerDimensions } from '../../Styles/theme'

// Actions
import { addDataToStore, SAVE_DATA } from '../../Actions/actions'

// Components
import { Button, TextButton } from '../Reusable/Button'
import { Paragraph, Text, Title } from '../Reusable/Text'
import { FlexColumn, SideBar, AbstractSideBar, Flex } from '../Reusable/Container'
import { LabeledInput, Divider } from '../Reusable/Input'
import AionLogo from '../Reusable/Image'
import { BrandedContainer } from '../Reusable/Container'
import AionOverlayImg from '../../Images/aion-wing-overlay.png';
import CoinStackImg from '../../Images/stack-of-coins.png';
import { ErrorAlert } from '../Reusable/Alert'

const { Content, Sider } = Layout

const AionOverLay = styled.div`
    position: absolute;
    mix-blend-mode: overlay;
    opacity: 0.16;
`;

class ForgotPassword extends Component {
    state = {   
        code: '', 
        step: 0  
    }
    
    handleChange = (event) => {
        if(event.target.id === "username") {
            this.setState({username: event.target.value})
        }  
        if(event.target.id === "code") {
            this.setState({code: event.target.value})
        }
        if(event.target.id === "password") {
            this.setState({password: event.target.value})
        }      
    }
    
    handleCodeSubmit = async (event) => {
        if(this.state.code === '') {
            this.setState({ errorField: "code", errorMessage: "Please enter a code." })
            return
        }
        if(this.state.password === '') {
            this.setState({ errorField: "password", errorMessage: "Please enter a password." })
            return
        }
        this.setState({loading: true})
        try {
            const username = this.state.username
            const password = this.state.password
            const code = this.state.code
            await Auth.forgotPasswordSubmit(username, code, password)
            message.success('Password update complete! Sign in with your credentials.')
            this.props.history.push('/')
        } catch (error) {
            this.setState({loading: false})
            console.log('error confirming sign up', error)
            ErrorAlert({description: `Error confirming sign up. ${error.message}`})
        }
    }
    
    handleSubmit = () => {
        if(!isEmailValid(this.state.username)) {
            this.setState({ errorField: "username", errorMessage: "Please enter a valid email." })
            return
        }
        this.setState({loading: true})
        const username = this.state.username
            Auth.forgotPassword(username)
                .then(data => {
                    this.setState({ codeDestination: data.CodeDeliveryDetails.Destination, loading: false })
                    this.next()
                })
                .catch(error => {
                    this.setState({loading: false})
                    console.log('error confirming sign up', error)
                    ErrorAlert({description: error.message})
                })
    }

    getPasswordValidations = () => {
        var { password } = this.state;
        password = password || '';
        var validationArr = [];
        if((password.length >= 8) && (password.length < 25)) validationArr.push(true)
        else validationArr.push(false)
        const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        validationArr.push(specialChars.test(password));
        validationArr.push(/^(?=.*[A-Z])/.test(password));
        validationArr.push(/^(?=.*[a-z])/.test(password));
        validationArr.push(/^(?=.*\d)/.test(password));
        return validationArr;
    }

    pwdOverlay =  () => {
        const StyledOverlay = styled.div`
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 16px;

            position: absolute;
            width: 408px;
            height: 162px;

            background: conic-gradient(from 156.95deg at 92.52% 82.42%, #000000 0deg, #333333 360deg);
            border-radius: 4px;
        `;
        const StyledUL = styled.ul`
            font-weight: 400;
            font-size: 16px;
            color: #FFFFFF;
            padding: 0 16px;
            list-style: none;
        `;
        const StyledSpan = styled.span`
            display: inline-block;
            width: 15px;
        `;
        const bullet = <StyledSpan>•</StyledSpan>
        const checkMark = <StyledSpan>✓</StyledSpan>
        const validations = this.getPasswordValidations();
        return (
            <StyledOverlay>
                <StyledUL className='checked-bullet'>
                    <li>{validations[0] ? checkMark : bullet} 8 to 25 characters</li>
                    <li>{validations[1] ? checkMark : bullet} Atleast 1 special character (@,#,$,%)</li>
                    <li>{validations[2] ? checkMark : bullet} Atleast 1 character from A-Z</li>
                    <li>{validations[3] ? checkMark : bullet} Atleast 1 character from a-z</li>
                    <li>{validations[4] ? checkMark : bullet} Atleast 1 character from 0-9</li>
                </StyledUL>
            </StyledOverlay>   
        )
    }

    next = () =>  this.setState({step: this.state.step + 1})
    back = () => this.setState({step: this.state.step - 1})

    getCTA = () => {
        const { step, loading } = this.state
        return (
            <FlexColumn>
                {
                    (step == 0) &&
                    <FlexColumn>
                        <Button solid permtype="Override" loading={loading} onClick={() => this.handleSubmit()} text={'Confirm'.toUpperCase()} />
                    </FlexColumn>
                }
                {
                    (step == 1) &&
                    <FlexColumn>
                        <Flex center style={{ gap: '24px' }}>
                            <Button permtype="Override" solid loading={loading} onClick={() => this.handleCodeSubmit()} text={'Confirm'.toUpperCase()} />
                            <TextButton permtype="Override" onClick={() => this.back()} text={'Cancel'.toUpperCase()} />
                        </Flex>
                    </FlexColumn>
                }
            </FlexColumn>
        )
    }

    render() {
        const { theme } = this.props
        const { errorField, errorMessage, showPwdOverlay, codeDestination, step } = this.state
        return (
            <>
                <Flex style={{ paddingTop: '80px'  }}>
                    <Flex gap='132px' style={{alignSelf: "stretch"}}>
                        <FlexColumn centerVertically left style={{ width: "408px", gap: '24px', alignSelf: "stretch" }}>
                            <FlexColumn left style={{ gap: '24px', width: 'inherit' }}>
                                <AionLogo symbol margin='0px' />
                                <FlexColumn fullWidth>
                                    {
                                        (step == 0) && 
                                        <FlexColumn gap="24px">
                                            <Text size="28px" weight="500" height="">Reset Password</Text>
                                            <Text>Enter the email address you used to sign up for Aion to reset your password.</Text>
                                            <LabeledInput
                                                autoFocus
                                                label="Email"
                                                id="username" 
                                                type="email" 
                                                placeholder="Enter your email address" 
                                                onChange={this.handleChange} 
                                                onKeyDown={(event) => { if(event.key === "Enter") this.handleSubmit() }}
                                                error={errorField == "username"}
                                                errorMessage={errorMessage}
                                            />
                                        </FlexColumn>
                                    }
                                    {
                                        (step == 1) &&
                                        <FlexColumn gap="24px">
                                            <Text size="28px" weight="500" height="">Confirmation Code</Text>
                                            <Text>A confirmation code has been sent to { codeDestination || "your phone/email" }. This code expires in 5 minutes.</Text>
                                            <FlexColumn >
                                                <LabeledInput
                                                    label="Confirmation Code"
                                                    id="code" 
                                                    type="code" 
                                                    placeholder="Enter Code" 
                                                    onChange={this.handleChange} 
                                                    error={errorField == "code"}
                                                    errorMessage={errorMessage}
                                                />
                                                <div style={{ background: 'transparent' }}>
                                                    <LabeledInput
                                                        label="New Password"
                                                        id="password"
                                                        type="new-password"
                                                        placeholder="Enter password"
                                                        onChange={this.handleChange} 
                                                        error={errorField == "password"}
                                                        errorMessage={errorMessage}
                                                        onKeyDown={(event) => { if (event.key === "Enter") this.handleCodeSubmit() }}
                                                        style={{ width: '100%' }}
                                                        margin="0 0 8px"
                                                        onFocus={() => this.setState({ showPwdOverlay: true })}
                                                        onBlur={() => this.setState({ showPwdOverlay: false })}
                                                    />
                                                    {showPwdOverlay && this.pwdOverlay()}
                                                </div>
                                            </FlexColumn>
                                            
                                        </FlexColumn>
                                    }
                                </FlexColumn>
                            </FlexColumn>
                            {this.getCTA()}
                        </FlexColumn>
                        <BrandedContainer style={{ height: "95vh" }}>
                            <AionOverLay>
                                <img src={AionOverlayImg} />
                            </AionOverLay>
                            <FlexColumn center gap="16px" style={{ padding: '24px 80px', height: '100%' }}>
                                <Text size="32px" height="60px" center color={theme.body}>Welcome back</Text>
                                <Divider style={{ background: theme.body }} />
                                <Text center color={theme.body}>
                                    Manage all your business finances with Aion. Check account balances, pay vendors, send invoices, unlock capital, and more.
                                </Text>
                            </FlexColumn>
                        </BrandedContainer>
                    </Flex>
                </Flex>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(withTheme(ForgotPassword))