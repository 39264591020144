import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import {
    message, Popconfirm, Button, Space, Tooltip, Popover, Table
} from 'antd';
import { DeleteOutlined, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';

// Components
import { Paragraph } from '../../../Reusable/Text';
import { Flex, FlexColumn } from '../../../Reusable/Container';
import AddModalMSA from '../AddModalMSA';

import environment from '../../../../environment';
import { apiPOSTReq } from '../../../../Utils/api';
import { SignedLink } from '../../../Reusable/Link';

class Index extends Component {
    state = {
        customer: this.props.customer || {},
        loading: false,
        msas: []
    }

    componentDidMount() {
        this.getMSAForCustomer();
    }

    getMSAForCustomer = () => {
        var { loanApp, underwriter, reportMSAStatus } = this.props;
        var body = {
            "BusinessId": this.props.aionStore.BusinessUniqueKey,
            "CustomerId": this.state.customer.customerId,
        }
        var uri = `${environment.iveBaseUrl}/ive/msa/getMSAForCustomer`;
        if(loanApp) {
            body.applicationId = loanApp.applicationId;
            uri = `${environment.iveBaseUrl}/ive/msa/getMSAsForApplication`;
            if(underwriter) body.clientBusinessId = loanApp.businessId;
        }
        console.log("getMSAForCustomer body", uri, body);
        apiPOSTReq(uri, null, body, (err, resp) => {
            try {
                console.log("getMSAForCustomer err, resp", err, resp);
                const data = resp;
                if (data.result) {
                    const msas = (data.msas || []);
                    this.setState({
                        msas: msas
                    });
                    // Notify parent component
                    if(reportMSAStatus) this.props.msaUploaded(this.state.customer, msas);
                } else {
                    throw Error("Could not getActiveMSAs.")
                }
            } catch (error) {
                //console.log("ERRR", error.stack)
                //message.error(`Error adding MSA `);
            }
        })
    }

    handleShowMSAModal = () => {
        this.setState({ showAddModalMSA: true });
    }

    handleMSAAdded = () => {
        this.getMSAForCustomer();
        this.setState({ showAddModalMSA: false });
    }

    handleDeleteMSA = (msa) => {
        console.log("handleDeleteMSA", msa.id)
        const body = {
            "BusinessId": this.props.aionStore.BusinessUniqueKey,
            "CustomerId": this.state.customer.customerId,
            "msaId": msa.id
        }
        apiPOSTReq(`${environment.iveBaseUrl}/ive/msa/delete`, this.iveHeaders, body, (err, resp) => {
            try {
                const data = resp;
                if (data.result) {
                    this.getMSAForCustomer();
                } else {
                    throw Error(data.error || data.responseMessage || "Could not delete MSA.")
                }
            } catch (error) {
                //console.log("ERRR", error.stack)
                message.error(error.message);
            }
        })
    }

    handleCancelMSA = () => {
        this.setState({ showAddModalMSA: false });
    }

    render() {
        var { customer, msas } = this.state;
        var { theme, loanApp, underwriter } = this.props;

        return (
            <>
                <div>
                    {((this.state.msas || []).length == 0) ?
                        <Paragraph size="0.9rem" color={theme.colors.systemGray2}>{(this.state.msas.length == 0) ? "No MSA added." : null}</Paragraph>
                    :
                        <Table
                            id="msa-table"
                            bordered={false}
                            size="middle"
                            pagination={{hideOnSinglePage: true}}
                            columns={[
                            {
                                title: 'MSA',
                                render: item => <SignedLink {...item.documentUrl} showfilename={true} />
                            },
                            
                            {
                                title: 'Action',
                                key: 'action',
                                width: 150,
                                render: (item) => (
                                    !underwriter && <Space size="middle">
                                        {/* <a target="_blank" href={item.documentUrl.uri}><FileTextOutlined style={{ fontSize: 18 }} /></a> */}
                                        <Popconfirm title="Are you sure you want to delete this MSA?" onConfirm={() => this.handleDeleteMSA(item)}>
                                            <a><DeleteOutlined style={{ cursor: 'pointer', fontSize: 18 }} /></a>
                                        </Popconfirm>
                                    </Space>
                                ),
                            }]}
                            dataSource={msas || []}
                            rowKey='id'
                        />
                    }
                    {!underwriter && <Button disabled={false/*!this.invInfoCompleted*/} onClick={this.handleShowMSAModal} type="primary" style={{ marginTop: 16 }}>
                        Add MSA
                    </Button>}
                </div>
                {/* Modals */}
                <AddModalMSA
                    visible={this.state.showAddModalMSA}
                    onAdd={this.handleMSAAdded}
                    onCancel={this.handleCancelMSA}
                    aionStore={this.props.aionStore}
                    store={this.props.store}
                    customerId={customer.customerId}
                    loanApp={loanApp}
                />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Index));