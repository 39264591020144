import React, { useState } from 'react';
import { Flex, FlexColumn } from '../../Reusable/Container';
import { Space, Table, Tag, Modal, Popover } from 'antd';
import { ErrorAlert } from '../../Reusable/Alert'
import { withTheme } from 'styled-components';
import moment from 'moment';
import { Document, Page, pdfjs } from 'react-pdf';
import { saveAs } from 'file-saver'

import { containerDimensions } from '../../../Styles/theme';
import { toCurrency, getBBAccount, getBPAccountsWithAccess } from '../../../Utils/util';
import { getPreSignedS3Url, apiPOSTReq } from '../../../Utils/api';
import { fetchBBHistory, fetchBBObject } from '../../../Utils/bankingDataManager';
import { InfoCircleOutlined, DownloadOutlined } from '@ant-design/icons';

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const TxnTable = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [showAttachmentsModal, setShowAttachmentsModal] = useState(false);
    const [attachments, setAttachments] = useState([]);
    const [numPages, setNumPages] = useState([]);
    // const [stmtFile, setStmtFile] = useState();
    const [filterBy, setFilterBy] = useState({});
    const [check, setCheck] = useState();



    const { theme, bankingStore, componentType } = props;
    const lowercaseField = (componentType === "AccountTransactions");
    var items = [];
    var count = 0;
    var Accounts = getBPAccountsWithAccess()
    Accounts = Accounts || [];
    (props.transactions || []).forEach(txn => {
        items.push(Object.assign({ key: count++, txn: txn }, txn));
    });
    const arAccount = Accounts.filter(item => { return (item.accountType === 'ACCOUNTS_RECEIVABLE') });

    const logoWidth = 30;//containerDimensions.logo.txnLogoWidth;

    function getAttachment(uri) {
        return new Promise((resolve, reject) => {
            getPreSignedS3Url({ url: uri }, (err, preSignedUrl) => {
                if (!err) {
                    resolve(preSignedUrl)
                } else {
                    reject(JSON.stringify(err))
                }
            })
        })
    }

    const onViewAttachments = (transaction) => {
        var fetchOptions = {
            viewBy: "CHECK_DEPOSIT",
            filterItemId: transaction.checkId,
        }

        fetchBBObject(fetchOptions, (err, resp) => {
            if (resp) {
                if (resp.checkDepositObj && (resp.checkDepositObj.frontImage || {}).imageBase64Content) {
                    setCheck(resp.checkDepositObj)
                    setShowAttachmentsModal(true)
                } else {
                    ErrorAlert({ description: "Error: No images available for this check" })
                }
            } else {
                ErrorAlert({ description: JSON.stringify(err) })
            }
        })
    }

    var columns = [
        {
            title: '',
            dataIndex: lowercaseField ? 'logo' : 'Logo',
            key: 'Logo',
            render: logoUrl => (
                <img src={logoUrl} height={logoWidth} width={logoWidth} style={{ borderRadius: "5px" }} />
            ),
            width: logoWidth
        },
        {
            title: 'Description',
            dataIndex: 'displayDescription',
            key: 'displayDescription',
            render: (description, transaction) => {
                return (transaction.checkId ?
                    <FlexColumn>
                        <span>{description}</span>
                        {/* <Space>
                            <div style={{ marginTop: "7px" }}> */}
                        <a onClick={() => onViewAttachments(transaction)}>View Check</a>
                        {/* </div>
                        </Space> */}
                    </FlexColumn>
                    :
                    description
                )
            }
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: item => moment(item).format("MMM D, YYYY"),
            width: 120
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'Amount',
            align: "right",
            render: amount => {
                return (amount > 0 ?
                    <b>+{formatter.format(amount)}</b>
                    :
                    <b>{formatter.format(amount)}</b>
                )
            },
            width: 140
        }
    ];
    if (props.type == "pending") {
        columns.push({
            title: "",
            dataIndex: '',
            key: '',
            // align: "right",
            render: "",
            width: 200
        });
    } else {
        columns.push({
            title: (
                <Popover content="Running Balance calculations do not include pending transactions, holds or other restrictions that may have been placed.">
                    <span>Running Balance </span>
                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                </Popover>
            ),
            dataIndex: 'balance',
            key: 'balance',
            // align: "right",
            render: amount => props.type == "pending" ? "--" : formatter.format(amount),
            width: 200
        });
    }
    return (
        <>
            <Table
                id="txn-table"
                style={{ background: "transparent" }}
                dataSource={items}
                columns={columns}
                // showHeader={false}
                pagination={false}
                // size="small"
                tableLayout='auto'
                scroll={{ y: '700px', x: '100%' }}
            />
            <Modal
                visible={showAttachmentsModal}
                footer={null}
                closable={true}
                width={650}
                destroyOnClose={true}
                onCancel={() => { setShowAttachmentsModal(false) }}
            >
                <FlexColumn start>
                    {
                        check && <>
                            <img width='600px' src={`${check.frontImage.imageBase64Content.includes('data:image') ? '' : 'data:image/jpeg;base64,'}${check.frontImage.imageBase64Content}`} />
                            <br />
                            <img width='600px' src={`${check.backImage.imageBase64Content.includes('data:image') ? '' : 'data:image/jpeg;base64,'}${check.backImage.imageBase64Content}`} />
                        </>
                    }
                </FlexColumn>
            </Modal>
        </>
    )
}

export default withTheme(TxnTable);