import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';

import { FlowViewContainer } from '../../Reusable/FlowViewContainer';
import FlowViewComponent from '../../Reusable/FlowViewComponent';

import MapAionCOA from './MapAionCOA';
import AdvancedSettings from './AdvancedSettings';
import COATable from './COATable';
import { ErrorAlert } from '../../Reusable/Alert';

import environment from '../../../environment';
import { apiPOSTReq } from '../../../Utils/api';

const flowViewsData = [
    {
        "ViewType": "WithoutImage",
        "FlowStep": "QB.COA",
        "Title": "",
        "Description": "",
        "Progress": 0.2,
        "ButtonTitle": "Continue",
        "ValidationErrorMessage": "Enter all .",
        "DefaultUserInput": "NOTREQUIRED"
    },
    {
        "ViewType": "WithoutImage",
        "FlowStep": "Aion.COA",
        "Title": "",
        "Description": "",
        "Progress": 0.2,
        "ButtonTitle": "Continue",
        "ValidationErrorMessage": "Enter all .",
        "DefaultUserInput": "NOTREQUIRED"
    },
    {
        "ViewType": "WithoutImage",
        "FlowStep": "AdvancedSettings",
        "Title": "",
        "Progress": 0.2,
        "ButtonTitle": "",
        "HideButton": true,
        "ValidationErrorMessage": "Enter all .",
        "DefaultUserInput": "NOTREQUIRED"
    },
];

class COASetup extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentViewIndex: 0,
            flowViews: flowViewsData,
            submitLoading: false,
            stepProgress: 0,
            coaToSetup: [],
            accountCOA: {},
        }
    }

    componentDidMount() {
        const { CurrentSync } = this.props.aionStore;
        if (CurrentSync && CurrentSync.syncSetupCompleted) {
            this.props.history.push('/settings/sync');
        }
        this.fetchCOAToSetup();
    }

    render() {
        const title = "QuickBooks Online Sync Setup";
        return (
            <>
                <FlowViewContainer padding="0" title={title} contentViews={this.getContentViews()} />
            </>
        );
    }

    // This will provide the view for each flowView
    getContentViews = () => {
        const { theme } = this.props
        const { flowViews, currentViewIndex } = this.state;
        const flowView = flowViews[currentViewIndex];
        var childViews = (<div></div>); // Initialize

        switch (flowView.FlowStep) {
            case "QB.COA":
                childViews = <COATable />
                break;
            case "Aion.COA":
                childViews = <MapAionCOA accountCOA={this.state.accountCOA} coaToSetup={this.state.coaToSetup} saveUserInput={this.saveUserInput} />
                break;
            case "AdvancedSettings":
                childViews = <AdvancedSettings redirect="/settings/sync" actionText="Confirm Preferences" onComplete={this.replaceCOA} />
                break;
            default:
                break;
        }

        return <FlowViewComponent
            width='100%'
            flowView={flowView}
            loadPrev={this.loadPrev}
            loadNext={this.loadNext}
            saveUserInput={this.saveUserInput}
            childViews={childViews}
            submitLoading={this.state.submitLoading}
            currentViewIndex={this.state.currentViewIndex}
        />
    }

    fetchCOAToSetup = () => {
        this.setState({ loading: true, });

        apiPOSTReq(`${environment.integrationBaseUrl}/integration/qb/cleanAionCOA`, {}, {}, (err, resp) => {
            try {
                const data = resp;
                console.log('/qb/cleanAionCOA', data)
                if (data.result) {
                    this.setState({
                        loading: false,
                        coaToSetup: data.customCOAList || [],
                    })
                    return data.customCOAList || [];
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getActiveCustomers", error.stack, resp);
                ErrorAlert({ description: error.message })
            }
        })
    }

    replaceCOA = () => {
        if (!this.state.coaToSetup || !this.state.accountCOA) {
            return;
        }
        const body = {
            coaSetupMap: this.state.accountCOA,
        }
        this.setState({ loading: true, });

        apiPOSTReq(`${environment.integrationBaseUrl}/integration/qb/remapAionCOA`, {}, body, (err, resp) => {
            try {
                const data = resp;
                console.log('/qb/remapAionCOA', data)
                if (data.result) {
                    this.setState({
                        loading: false,
                    })
                    this.fetchCOAToSetup()
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR remapAionCOA", error.stack, resp);
                ErrorAlert({ description: error.message })
            }
        })
    }

    // Validate input and go to next view if it's good
    loadNext = () => {
        const { theme } = this.props
        let { flowViews, currentViewIndex } = this.state;
        const flowView = flowViews[currentViewIndex];
        const validation = this.validateFlow()
        if (validation.validated) {
            switch (flowView.FlowStep) {
                case "QB.COA":
                    if (this.state.coaToSetup && this.state.coaToSetup.length === 0) {
                        window.scrollTo(0, 0);
                        currentViewIndex = this.state.currentViewIndex + 2
                        if (currentViewIndex < this.state.flowViews.length) {
                            this.setState({
                                currentViewIndex: currentViewIndex,
                                flowView: flowViews[currentViewIndex]
                            });
                        }
                        return;
                    }
                    break;

                default:
                    break;
            }
            this.loadNextView();
        } else {
            ErrorAlert({ description: validation.message });
        }
    }

    loadPrev = () => {
        let { flowViews, currentViewIndex } = this.state;
        const flowView = flowViews[currentViewIndex];
        console.log('currentViewIndex', currentViewIndex)
        switch (flowView.FlowStep) {
            case "AdvancedSettings":
                if (currentViewIndex === 2 && this.state.coaToSetup && this.state.coaToSetup.length === 0) {
                    window.scrollTo(0, 0);
                    this.setState({
                        currentViewIndex: currentViewIndex - 2,
                        flowViews: flowViews,
                        flowView: flowViews[currentViewIndex - 2],
                        showError: false, errorMsg: ""      // dismiss any validatin error, if any, for the current step
                    });
                    return;
                }
                break;

            default:
                break;
        }
        this.loadPrevView();

    }

    saveUserInput = (id, dataToSave) => {
        this.setState({ [id]: dataToSave });
    }

    validateFlow = () => {
        const { flowViews, currentViewIndex } = this.state;
        const flowView = flowViews[currentViewIndex];
        var validation = {
            validated: false,
            message: '',
        }
        switch (flowView.FlowStep) {
            case "Aion.COA":
                if (!this.state.coaToSetup) {
                    validation.validated = true;
                    return validation;
                }
                if (this.state.coaToSetup.length > 0 && !this.state.accountCOA) {
                    validation.message = "Please map all accounts";
                    return validation;
                } else if (this.state.coaToSetup.length > 0 && Object.keys(this.state.accountCOA).length !== this.state.coaToSetup.length) {
                    validation.message = "Please map all accounts";
                    return validation;
                }
                break;

            default:
                break;
        }
        validation.validated = true;
        return validation;
    }

    loadPrevView = () => {
        window.scrollTo(0, 0);
        let { flowViews, currentViewIndex } = this.state;
        if (currentViewIndex !== 0) {
            this.setState({
                currentViewIndex: currentViewIndex - 1,
                flowViews: flowViews,
                flowView: flowViews[currentViewIndex - 1],
                showError: false, errorMsg: ""      // dismiss any validatin error, if any, for the current step
            });
        }
    }

    loadNextView = () => {
        window.scrollTo(0, 0);
        var flowViews = this.state.flowViews;
        var currentViewIndex = this.state.currentViewIndex + 1;
        if (currentViewIndex < this.state.flowViews.length) {
            this.setState({
                currentViewIndex: currentViewIndex,
                flowView: flowViews[currentViewIndex]
            });
        }
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(COASetup)));