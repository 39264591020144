import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';

import { Select, Table, message, Divider } from 'antd';

import { Flex, FlexColumn } from '../../Reusable/Container';
import { LabeledInput } from '../../Reusable/Input';
import { Image } from '../../Reusable/Image';
import { ErrorAlert } from '../../Reusable/Alert';
import { Tag, Text } from '../../Reusable/Text';
import { Button, TextButton } from '../../Reusable/Button'
import { ExpenseCategory } from '../../Reusable/ExpenseCategory';
import { addDataToStore, SAVE_DATA } from '../../../Actions/actions';

import environment from '../../../environment';
import { apiPOSTReq } from '../../../Utils/api';
import { capitalizeWords, getUserApps, getBPAccountsWithAccess } from '../../../Utils/util';
import TableView from '../../Reusable/TableView'
import AionIcon from '../../../Images/aion-bank-icon.png'
import RightArrow from '../../../Images/right-arrow.svg'
import { initial } from 'underscore';

const { Option } = Select;

const priorityMap = {
    "Accounts Payable": 1,
    "Accounts Receivable": 2,
    "Bank": 0,
    "Cost of Goods Sold": 3,
    "Credit Card": 4,
    "Equity": 5,
    "Expense": 6,
    "Fixed Asset": 7,
    "Income": 8,
    "Long Term Liability": 9,
    "Other Asset": 10,
    "Other Current Asset": 11,
    "Other Current Liability": 12,
    "Other Expense": 13,
    "Other Income": 14,
}

class SettingsModal extends Component {
    state = {
        accounts: getBPAccountsWithAccess(),
        currentViewIndex: 0,
        historyPagination: {
            current: 1,
            pageSize: 50
        },
        errorPagination: {
            current: 1,
            pageSize: 80
        },
        qbErrors: [],
        integrationHistory: [],
        showAdvancedSetting: false,
        syncQB: false,
        pagination: {
            current: 1,
            pageSize: 1000,
        },
        pairPagination: {
            current: 1,
            pageSize: 1000,
        },
        accountCOA: {},
        customCOA: [],
        loading: true,
        pollInterval: null,
        assetAccounts: [],
        initialAccountPairs: [],
    };

    componentDidMount() {
        const { poll } = this.props
        this.fetchCOAPair({ pagination: this.state.pairPagination });

        if (poll) this.startPolling();
        else this.fetchCOA({ pagination: this.state.pagination });
    }

    componentWillUnmount() {
        this.stopPolling();
    }

    startPolling = () => {
        this.getSyncProgress("CustomCOA")
        const pollInterval = setInterval(() => this.getSyncProgress("CustomCOA"), 1000); // Pass the entity to the polling function
        this.setState({ pollInterval });

        setTimeout(() => {
            this.setState({ pollInterval: null })
        }, 90000)
    };

    stopPolling = () => {
        clearInterval(this.state.pollInterval);
        this.setState({ pollInterval: null });
    };

    getSyncProgress = (entity = null) => {
        if (!this.state.pollInterval) {
            return; // Exit if polling should have stopped
        }

        apiPOSTReq(`${environment.integrationBaseUrl}/integration/codat/getSyncProgress`, {}, {}, (err, resp) => {
            try {
                const data = resp;
                console.log("/integration/codat/refreshAll getSyncProgress POLLING", data);

                // Filter syncJobList based on entity if entity is provided
                const relevantJobs = entity ? data.syncJobList.filter(job => job.progress.entityName === entity) : data.syncJobList;

                // Check for any failed jobs
                // const anyFailed = relevantJobs.some(job => job.status === 'Failure');
                // if (anyFailed) {
                //     const failedJob = relevantJobs.find(job => job.status === 'Failure'); // Get the first failed job to report error
                //     this.stopPolling(); // Stop polling if any job fails
                //     ErrorAlert({ description: `Error syncing COA - Job failed with error: ${failedJob.errorMessage || 'No error message provided.'}` });
                //     this.fetchCOA({ pagination: this.state.pagination }); // Call next function
                //     return; // Exit the function early
                // }

                // Check if all filtered jobs are completed successfully
                const allSuccessful = relevantJobs.every(job => job.status === 'Success');

                if (allSuccessful) {
                    this.stopPolling(); // Stop polling when all jobs are successful
                    this.fetchCOA({ pagination: this.state.pagination }); // Call next function
                }
            } catch (error) {
                this.setState({ loading: false });
                console.log("ERR integration/codat/refreshAll", error.stack, resp);
                ErrorAlert({ description: error.message });
                // this.stopPolling(); // Consider stopping on critical error
            }
        });
    };

    fetchCOAPair = ({ pagination }) => {
        const body = {
            page: pagination.current - 1,
            size: pagination.pageSize,
        }
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/codat/getAcctCOAPair`, {}, body, (err, resp) => {
            try {
                const data = resp;
                console.log('BillSettings updateSyncSetting /integration/codat/getAcctCOAPair', data)
                if (data.result) {
                    const accountCOA = this.state.accountCOA;
                    if (data.accountPairList) {
                        data.accountPairList.forEach(x => {
                            accountCOA[x.accountId] = x.coaId
                        });
                        this.setState({ accountCOA, initialAccountPairs: data.accountPairList });
                    }

                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getAcctCOAPair", error.stack, resp);
                // ErrorAlert({ description: error.message })
            }
        })
    }

    fetchCOA = ({ pagination, searchStr }) => {
        const headers = {
            AionCurrentBiz: this.props.aionStore.BusinessUniqueKey,
            AionAuth: this.props.aionStore.UAM.encryptedAuthHeader
        }

        const body = {
            "BusinessId": this.props.aionStore.BusinessUniqueKey,
            "size": pagination.pageSize,
            "page": pagination.current - 1,
            "ActiveRecords": true,
            "sortFieldName": "Code",
            "sortDirection": "ASC",
            // "sources": ["CODAT"],
        }
        if (searchStr) {
            body.searchStr = searchStr;
        }
        // this.setState({ loading: true });

        apiPOSTReq(`${environment.bbBaseUrl}/bk/getCategories`, headers, body, (err, resp) => {
            try {
                const data = resp;
                console.log('BillSettings updateSyncSetting /bk/getCategories', data)
                if (data.result) {
                    const customCOA = data.customCOAList ? data.customCOAList.sort((a, b) => {
                        if (priorityMap[a.coatype] < priorityMap[b.coatype]) return -1;
                        else return 1;
                    }).map(x => {
                        return {
                            ...x, key: x.id,
                        }
                    }) : [];

                    const assetAccounts = customCOA.filter(x => x.coatype === "Asset");

                    this.setState({ assetAccounts, allAssetAccounts: assetAccounts })
                    if (!searchStr) {
                        this.setState({
                            allCustomCOA: data.customCOAList ? data.customCOAList.sort((a, b) => {
                                if (priorityMap[a.coatype] < priorityMap[b.coatype]) return -1;
                                else return 1;
                            }).map(x => {
                                return {
                                    ...x, key: x.id,
                                }
                            }) : [],
                        })
                    }
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getActiveCustomers", error.stack, resp);
                ErrorAlert({ description: error.message })
            } finally {
                this.setState({ loading: false })
            }
        })
    }

    handleSelect = (accountId, coaId) => {
        let accountCOA = this.state.accountCOA;
        accountCOA[accountId] = coaId;
        this.setState({ accountCOA, customCOA: this.state.allCustomCOA });
    }

    updateSyncSetting = () => {
        var { onCancel } = this.props;
        var { accounts } = this.state;

        const bbaccounts = accounts.filter(acc => acc.accountType !== "ACCOUNTS_RECEIVABLE")

        // Initialize the parts of the request object
        const body = {};

        // Temporary storage for accountCOAPair and CustomCOA
        const accountCOAPair = [];
        const customCOAList = [];

        // Iterate over each account to sort them into the correct categories
        bbaccounts.forEach(account => {
            if (account.addNew) {
                // Add to CustomCOA if addNew is true
                customCOAList.push({
                    accountNumber: account.accountNumber,
                    accountName: account.newAccountName,
                    aionAccountId: account.accountId,
                    // Include other necessary fields as needed
                });
            } else {
                // Add to accountCOAPair if addNew is false
                if (account.coaId) {
                    accountCOAPair.push({
                        accountId: account.accountId,
                        coaId: account.coaId,
                    });
                }
            }
        });

        // Only add accountCOAPair to the request object if it's not empty
        if (accountCOAPair.length > 0) {
            body.accountCOAPair = accountCOAPair;
        }

        // Only add CustomCOA to the request object if it's not empty
        if (customCOAList.length > 0) {
            body.customCOAList = customCOAList;
        }

        console.log("BillSettings updateSyncSetting", body);

        this.setState({ submitLoading: true })

        apiPOSTReq(`${environment.integrationBaseUrl}/integration/codat/updateSyncSetting`, {}, body, (err, resp) => {
            try {
                const data = resp;
                console.log('/integration/codat/updateSyncSetting', data)
                if (data.result) {
                    if (onCancel) onCancel()
                    else message.success("Sync setting saved!");
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getActiveCustomers", error.stack, resp);
                ErrorAlert({ description: error.message })
            } finally {
                this.setState({ submitLoading: false })
            }
        })
    }

    render() {
        const { theme, buttonText } = this.props;
        var { loading, assetAccounts, accountCOA, accounts, submitLoading, filteredAssetAccounts, initialAccountPairs } = this.state;

        const userApps = getUserApps(this.props.aionStore);

        var activeAccounts = accounts.filter(x => x.status === "Active");
        const bbaccounts = accounts.filter(acc => acc.accountType !== "ACCOUNTS_RECEIVABLE")
        // const apAccounts = customCOA.filter(x => x.coatype === "Accounts Payable");
        // const arAccounts = customCOA.filter(x => x.coatype === "Accounts Receivable");
        // const bankAccounts = customCOA.filter(x => x.coatype === 'Bank' && x.coasubType === 'Checking')
        // const assetAccounts = customCOA.filter(x => x.coatype === "Asset");

        const accountColumns = [
            {
                title: 'Aion Bank Account',
                dataIndex: 'connectionStatus',
                key: 'connectionStatus',
                width: '170px',
                render: (status, account) => {
                    return <Tag background='#FBFBFB'><Text><img width='16px' height='16px' src={AionIcon} style={{ marginRight: 8, marginBottom: 2 }} />{`${account.mask}`}</Text></Tag>
                }
            },
            {
                title: 'General Ledger or Chart of Account',
                dataIndex: 'lastSyncedEndDate',
                key: 'lastSyncedEndDate',
                render: (lastSyncedEndDate, account) => {
                    return <FlexColumn gap='8px' fullWidth>
                        {
                            account.addNew ?
                                <>
                                    <LabeledInput
                                        nomargin
                                        label=""
                                        placeholder="Enter new account name"
                                        id={`newAccount${account.accountId}`}
                                        value={account.newAccountName}
                                        onChange={(event) => {
                                            accountCOA[account.accountId] = event.target.value
                                            account.newAccountName = event.target.value
                                            this.setState({ accounts })
                                        }}
                                    />

                                    <Flex gap='8px' start centerHorizontally>
                                        <TextButton underline weight='400' text='Select existing account' onClick={() => {
                                            account.addNew = false
                                            this.setState({ accounts })
                                        }} />
                                    </Flex>
                                </>
                                :
                                <>
                                    <LabeledInput
                                        label=""
                                        labelcolor={theme.colors.secondary3}
                                        id={`category${account.accountId}`}
                                        key={`category${account.accountId}`}
                                        type="remote-search"
                                        className="no-left-padding"
                                        nomargin
                                        placeholder="Select Account"
                                        value={accountCOA && accountCOA[account.accountId] && <ExpenseCategory category={assetAccounts.find(coa => coa.coaId === accountCOA[account.accountId])} theme={theme} />}
                                        onChange={(val) => {
                                            accountCOA[account.accountId] = val;
                                            console.log("BillSettings updateSyncSetting select", val);
                                            account.coaId = val
                                            this.setState({ accounts, accountCOA, filteredAssetAccounts: null })
                                        }}
                                        onSearch={(val) => {
                                            if (val) {
                                                const filtered = assetAccounts.filter(account =>
                                                    account.categoryName.toLowerCase().includes(val.toLowerCase())
                                                );
                                                this.setState({ filteredAssetAccounts: filtered });
                                            } else {
                                                this.setState({ filteredAssetAccounts: null });
                                            }
                                        }}
                                        onDropdownVisibleChange={() => this.setState({ filteredAssetAccounts: null })}
                                    >
                                        {assetAccounts && (filteredAssetAccounts || assetAccounts).map(category => (
                                            <Option key={category.coaId} id="category" name="category" code={category.code} value={category.coaId}> <ExpenseCategory tag category={category} theme={theme} /></Option>)
                                        )}
                                    </LabeledInput>

                                    {
                                        !initialAccountPairs.some(item => item.accountId === account.accountId && item.coaId !== null) &&
                                        <Flex gap='8px' start centerHorizontally>
                                            <Text>Not able to find the chart of account?</Text>
                                            <TextButton underline weight='400' text='Add a new one' rightIcon={<Image src={RightArrow} />} onClick={() => {
                                                account.addNew = true
                                                this.setState({ accounts })
                                            }} />
                                        </Flex>
                                    }
                                </>
                        }
                    </FlexColumn>
                }
            },
        ]

        let statusText = "N/A";
        if (this.state.qbErrors && this.state.lastSyncedEndDate) {
            statusText = this.state.qbErrors.length > 0 ? "Synced with errors" : "Successfully completed";
        }

        return (
            <FlexColumn left start gap='24px' fullWidth>
                <FlexColumn start>
                    <Text weight='500' spacing="0.08em" caption>BANK ACCOUNT MATCHING</Text>
                    <Text margin='8px 0 0'>Match your Aion bank account to a corresponding general ledger (GL) or chart of account in your accounting system so any bill payments made from your Aion bank accounts can be automatically categorized.</Text>
                </FlexColumn>

                <Table
                    id="bank-accounts"
                    className='borderless-table'
                    tableLayout='auto'
                    columns={accountColumns}
                    style={{ width: '100%' }}
                    dataSource={bbaccounts}
                    rowKey='id'
                    pagination={false}
                    loading={loading}
                />

                {/* <FlexColumn start>
                        <Text weight='500' spacing="0.08em" caption>UNACCOUNTED EXPENSE CATEGORIZATION</Text>
                        <Text margin='8px 0 0'>Select an accounts payable account to post unaccounted bills. This is where bills with no GL accounts specified when you create bills will post to during the sync.</Text>
                    </FlexColumn>

                    <LabeledInput
                        autoFocus
                        label="Select a chart of account from your accounting system"
                        labelcolor={theme.colors.secondary3}
                        id="category"
                        key="category"
                        type="sync-select"
                        className="no-left-padding"
                        placeholder="Select Chart of Account"
                        value={(apAccounts && apAccounts.length > 0) && this.state.apAccountId}
                        onChange={(val) => this.setState({ apAccountId: val })}
                    >
                        {apAccounts && apAccounts.map(category => (
                            <Option key={category.coaId} id="category" name="category" value={category.coaId}> <ExpenseCategory category={category} theme={theme} /></Option>)
                        )}
                    </LabeledInput> */}

                {!loading && !bbaccounts.every(account => account.accountId in accountCOA) && <Text margin='24px 0 16px'>Match each account to continue</Text>}

                <Button
                    solid
                    text={buttonText || 'CONFIRM'}
                    onClick={this.updateSyncSetting}
                    style={{ marginTop: 48 }}
                    loading={submitLoading}
                    disabled={loading || !bbaccounts.every(account => account.accountId in accountCOA)}
                />
            </FlexColumn>
        );
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer,
        bankingStore: state.bankingAppReducer,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(SettingsModal));