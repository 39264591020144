import React, { Component, useEffect } from 'react'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'
import moment from 'moment'
import 'draft-js/dist/Draft.css'
import { saveAs } from 'file-saver'

import { message, Select, Input, Table } from 'antd'

import { CheckOutlined } from '@ant-design/icons'

import PageHeader from "../../Reusable/PageHeader"
import { TextButton } from '../../Reusable/Button'
import { Flex, FlexColumn, Container } from '../../Reusable/Container'
import { ErrorAlert } from '../../Reusable/Alert'
import { Text, Tag } from '../../Reusable/Text'
import { LabeledInput } from '../../Reusable/Input'
import environment from '../../../environment'
import { apiPOSTReq, getPreSignedS3Url } from '../../../Utils/api'
import { toCurrency } from '../../../Utils/util'
import ActionMenu from '../../Reusable/ActionMenu'
import QB_ICON from '../../../Images/quickbooks-icon-sm.jpeg'

const { Search } = Input

class MatchInvoice extends Component {

    constructor(props) {
        super(props)

        this.state = {
            filters: [],
            pagination: {
                current: 1,
                pageSize: 100,
                showSizeChanger: false,
            },
            matchedAmount: 0,
            remainingAmount: 0,
            loading: true,
            unpaidInvoices: [],
            loadingCustomers: true
        }
    }

    componentDidMount() {
        if (this.props.location.state && this.props.location.state.payment) {
            // const qbo = this.props.location.state.qbo

            // if (qbo) {
            //     var accounts = getBBAccountsWithAccess("Transactions")
            //     var accountId = (accounts[0] || {}).AccountId
            //     this.setState({ accounts: accounts, accountId: accountId })
            //     this.fetchPayments({ accountId: accountId, pagination: this.state.pagination })
            // } else {
            //     this.fetchInvoices({ pagination: this.state.pagination })
            // }

            const payment = this.props.location.state.payment

            console.log("payment", payment)

            this.fetchCustomers({
                customerId: payment.customerId,
                pagination: {
                    current: 1,
                    pageSize: 500
                }
            })

            this.setState({ payment, remainingAmount: payment.amount - payment.matchedAmount })
            this.getUnpaidInvoices({ customerId: payment.customerId })
            this.getUnpaidQBPayments(payment.customerId)
        } else {
            this.fetchCustomers({
                pagination: {
                    current: 1,
                    pageSize: 500
                }
            })

            this.getUnpaidInvoices({})
            this.getUnpaidQBPayments()
        }
    }

    getUnpaidInvoices(options) {
        const { customerId, searchStr, pagination } = options

        var body = { pagination }

        if (searchStr && searchStr != '') {
            if (isNaN(searchStr)) {
                body.searchStr = searchStr
            } else {
                body.searchAmt = searchStr
            }
        } else {
            if (customerId) body.customerId = customerId
        }


        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/getUnpaidInvoices`, {}, body, (err, resp) => {
            this.setState({ loading: false })
            try {
                const data = resp || {}
                console.log("/ive/invoice/getUnpaidInvoices", data)
                if (data.result) {
                    this.setState({
                        unpaidInvoices: data.upaidInvoices
                    })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getUnpaidInvoices", error, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    getUnpaidQBPayments(customerId) {
        var body = {}
        if (customerId) body.customerId = customerId

        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/getUnpaidQBPayments`, {}, body, (err, resp) => {
            this.setState({ loading: false })
            try {
                const data = resp || {}
                console.log("/ive/invoice/getUnpaidQBPayments", data)
                if (data.result) {
                    this.setState({ qbPayments: data.invoicePayments })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getUnpaidQBPayments", error, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    fetchCustomers(options) {
        const { pagination, customerId } = options
        // Fetch customer list
        const body = {
            "size": pagination.pageSize,
            "page": pagination.current - 1
        }
        this.setState({ loadingCustomers: true })
        apiPOSTReq(`${environment.iveBaseUrl}/ive/bc/getActiveCustomers`, {}, body, (err, resp) => {
            try {
                this.setState({ loadingCustomers: false })
                const data = resp
                console.log("/getActiveCustomers", data)
                if (data.result) {
                    // var selectedCustomer
                    // if (this.state.invoice) {
                    //     selectedCustomer = (data.customers || []).find(customer => (customer.customerId == this.state.invoice.customerId))
                    // }

                    var customer

                    if (customerId) customer = data.customers.find(customer => customer.customerId === customerId)

                    this.setState({ customer, loadingCustomers: false, activeCustomers: data.customers }) //, selectedCustomer })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getActiveCustomers", error.stack, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    filterSelected = (text) => {
        const { filters } = this.state
        return filters.find(filter => filter.text === text)
    }

    handlePaymentTextChange = (event) => {
        var { contractor } = this.state
        contractor[event.target.id] = event.target.value
        this.setState({ contractor })
    }

    matchInvoicePayments() {
        const { selectedRows, payment } = this.state

        var upaidInvoices = []
        var invoicePayments = []

        selectedRows.forEach(row => {
            if (row.paymentDate) {
                invoicePayments.push(row)
            } else {
                upaidInvoices.push(row)
            }
        })

        const body = {
            upaidInvoices,
            transactionId: payment.transactionId
        }

        const qboBody = {
            invoicePayments,
            transactionId: payment.transactionId
        }

        console.log("MatchInvoice matchInvoicePayments: ", body, qboBody)

        if (upaidInvoices.length > 0) {
            apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/matchInvoicePayments`, {}, body, (err, resp) => {
                try {
                    const data = resp || {}
                    console.log("/ive/invoice/matchInvoicePayments", data)
                    if (data.result) {
                        message.success(`Payment matched successfully`)
                        this.props.history.replace('/receivables/invoice-payments')
                    } else {
                        throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                    }
                } catch (error) {
                    console.log("ERRR matchInvoicePayments", error, resp)
                    ErrorAlert({ description: error.message })
                }
            })
        }

        if (invoicePayments.length > 0) {
            apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/matchQBPayments`, {}, qboBody, (err, resp) => {
                try {
                    const data = resp || {}
                    console.log("/ive/invoice/matchQBPayments", data)
                    if (data.result) {
                        message.success(`QBO Payment matched successfully`)
                        this.props.history.replace('/receivables/invoice-payments')
                    } else {
                        throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                    }
                } catch (error) {
                    console.log("ERRR matchQBPayments", error, resp)
                    ErrorAlert({ description: error.message })
                }
            })
        }
    }

    match = () => {
        const { matchedAmount, remainingAmount } = this.state

        if ((remainingAmount - matchedAmount) < 0) {
            ErrorAlert({ description: "Total matched amount cannot exceed the payment amount." })
        } else {
            this.matchInvoicePayments()
        }
    }

    handleTableChange = (pagination) => {
        this.setState({ pagination })
    }

    handleOnChange = (e) => {
        this.setState({ searchStr: e.target.value })

        const pagination = {
            current: 1,
            pageSize: 100
        }

        this.getUnpaidInvoices({
            searchStr: e.target.value,
            pagination
        })
    }

    handleCustomerSelect = (value, option) => {
        const { activeCustomers } = this.state

        this.getUnpaidInvoices({
            customerId: value,
            pagination: {
                current: 1,
                pageSize: 500
            }
        })

        this.getUnpaidQBPayments(value)

        var customer = activeCustomers.find(customer => customer.customerId === value)

        this.setState({ loading: true, customer })
    }

    downloadInvoice = (invoice) => {
        var invoiceDoc = (invoice.attachments || []).find(item => item.type == "InvoiceDoc")
        if (invoiceDoc) {
            getPreSignedS3Url({ url: invoiceDoc.uri }, (err, preSignedUrl) => {
                invoiceDoc.preSignedUrl = preSignedUrl || ""
                saveAs(invoiceDoc.preSignedUrl, `Invoice ${invoice.docNumber}`)
            })
        } else {
            ErrorAlert({ description: "No document found for this invoice" })
        }
    }

    render() {
        const { unpaidInvoices, customer, customerName, loadingCustomers, activeCustomers, qbo, payments, payment, matchedAmount, remainingAmount, qbPayments, includeExternalInvoices } = this.state
        const { selectedTab, loading, pagination, invoices, dateType, showCustomDate, invoiceDashboardInfo, dashboardLoading, filterType, enablePaymentReminder, selectedRows, selectedRowKeys, filters, ccAddr, reminderTeamName, sorter, searchTerm, invoiceRefreshLoading, ABLUser, showPaymentReminderModal } = this.state
        const { theme, aionStore } = this.props

        const StatusTag = (options) => {
            let { invoice } = options
            var { status } = invoice

            return (<Tag height="32px" weight={400} textsize="16px" background="#F5F6FE" tagpreset="#1199FF">{status}</Tag>)
        }

        const columns = [
            {
                title: 'No.',
                dataIndex: 'docNumber',
                key: 'docNumber',
                render: (docNumber, invoice) => {
                    var invoiceNumber

                    if (docNumber) {
                        invoiceNumber = docNumber
                    } else {
                        invoiceNumber = invoice.invoiceId
                    }

                    return <a style={{ fontSize: 16, textDecoration: "underline" }} onClick={() => this.downloadInvoice(invoice)}>{invoiceNumber}</a>
                },
            },
            {
                title: '',
                dataIndex: 'id',
                key: 'id',
                render: (id, invoice) => {
                    if (id) return <img src={QB_ICON} width={24} height={24} style={{ marginRight: '8px' }} />
                },
                width: 30,
            },
            {
                title: 'Invoice Date',
                dataIndex: 'txnDate',
                key: 'txnDate',
                render: (date, invoice) => {
                    if (date) {
                        date = new Date(date).toISOString()
                        date = date.split("T")[0]
                    }
                    return <Text color="#444444">{(moment(date).format('MMM Do, YYYY'))}</Text>
                },
            },
            {
                title: 'Due Date',
                dataIndex: 'dueDate',
                key: 'dueDate',
                render: (date, invoice) => {
                    if (date) {
                        date = new Date(date).toISOString()
                        date = date.split("T")[0]
                    }
                    return <Text color="#444444">{(moment(date).format('MMM Do, YYYY'))}</Text>
                },
            },
            {
                title: 'Available Amount',
                dataIndex: 'balance',
                key: 'balance',
                render: (balance, invoice) => {
                    var amount

                    if (balance) {
                        amount = balance
                    } else {
                        amount = invoice.amount
                    }

                    return <Text color="#444444">$ {toCurrency(amount)}</Text>
                },
                align: 'right',
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (status, invoice) => <StatusTag invoice={invoice} />,
            },
        ]

        return (
            <>
                <Flex between>
                    <FlexColumn start className='main-padding' style={{ width: "100%" }}>
                        <PageHeader
                            titleText="Reconcile"
                            // subtitle="Invoice Payments"
                        // desc="Payments received in your bank account."
                        />

                        <Container shadow>

                            <div style={{ width: 384, marginBottom: 20 }}>
                                <LabeledInput
                                    allowClear
                                    nomargin
                                    label="Customer"
                                    id="customerId"
                                    key="customerId"
                                    type="select"
                                    className="no-left-padding"
                                    placeholder="Select"
                                    value={customer && (customer.displayName || customer.companyName)}
                                    loading={loadingCustomers}
                                    onChange={this.handleCustomerSelect}
                                    filterOption={(input, option) => {
                                        return option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }}
                                    showSearch
                                    optionFilterProp="children"
                                    onClear={() => {
                                    }}
                                >
                                    {(activeCustomers || []).map(item => <Select.Option key={item.customerId} id="customerName" name={item.displayName || item.companyName} value={item.customerId} style={{ backgroundColor: "transparent" }}>{item.displayName || item.companyName}</Select.Option>)}
                                </LabeledInput>
                            </div>

                            {/* <GradientDivider reverse marginBottom='32px' /> */}

                            {/* <Container shadow> */}
                            <FlexColumn>
                                {/* <Flex between centerHorizontally style={{ paddingBottom: 12 }}>
                                    <Text heading>Invoices</Text>
                                    <Flex>
                                        <span style={{ fontSize: "0.9rem", color: theme.colors.secondary7 }}>INCLUDE EXTERNALLY PAID INVOICES</span>
                                        <Switch size='small' checked={this.state.includeExternalInvoices} onChange={(checked) => this.setState({ includeExternalInvoices: checked })} />
                                    </Flex>
                                </Flex> */}
                                <Search
                                    placeholder="Search Invoices"
                                    size="large"
                                    allowClear
                                    // onSearch={() => this.txnSearch({ accountId: this.state.accountId, pagination: this.state.pagination, searchTerm: this.state.txnSearch })}
                                    onChange={this.handleOnChange}
                                    style={{ marginBottom: '20px' }}
                                    enterButton={false}
                                />

                                {
                                    selectedRowKeys && selectedRowKeys.length > 0 &&
                                    <Text style={{ marginBottom: 24 }} color='#737387'>{`${selectedRowKeys.length} Invoice${selectedRowKeys.length > 1 ? 's' : ''} selected`}</Text>
                                }

                                <Table
                                    id="invoice-table"
                                    className="row-pointer"
                                    tableLayout='auto'
                                    columns={columns}
                                    dataSource={includeExternalInvoices ? (qbPayments || []).concat(unpaidInvoices) : unpaidInvoices}
                                    rowKey={(record) => {
                                        if (record.id) {
                                            return record.id
                                        } else {
                                            return record.invoiceId
                                        }
                                    }}
                                    pagination={pagination}
                                    style={{ minHeight: "500px" }}
                                    onChange={this.handleTableChange}
                                    loading={loading}
                                    expandable={{
                                        expandedRowRender: invoice => {
                                            return <Flex between>
                                                <div style={{ width: '40%', paddingLeft: 32, paddingRight: 12 }}>
                                                    <LabeledInput
                                                        inputRef={this.accountNumberInput}
                                                        // margin="10px 0"
                                                        type="number"
                                                        label="PAYMENT AMOUNT"
                                                        id="matchedAmount"
                                                        key="matchedAmount"
                                                        className="no-left-padding"
                                                        placeholder="Enter payment amount"
                                                        instruction={`Unpaid Amount after Matching: $${toCurrency((invoice.paymentDate ? (invoice.amount ? invoice.amount : 0) : (invoice.balance ? invoice.balance : 0)) - invoice.matchAmount)}`}
                                                        min={0}
                                                        max={invoice.paymentDate ? (invoice.amount && toCurrency(invoice.amount)) : (invoice.balance && toCurrency(invoice.balance))}
                                                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        value={invoice.matchAmount}
                                                        onChange={(value) => {
                                                            invoice.matchAmount = value
                                                            var matchedAmount = 0

                                                            selectedRows.forEach(invoice => {
                                                                matchedAmount += invoice.matchAmount
                                                            })

                                                            this.setState({ unpaidInvoices, matchedAmount })
                                                        }}
                                                    // error={accountNumberError}
                                                    // errorMessage="Account number is required"
                                                    // value={accountNumber}
                                                    />
                                                </div>
                                                <div style={{ width: '60%', paddingLeft: 12, paddingRight: 24 }}>
                                                    <LabeledInput
                                                        inputRef={this.accountNumberInput}
                                                        // margin="10px 0"
                                                        label="NOTE"
                                                        id="note"
                                                        key="note"
                                                        className="no-left-padding"
                                                        placeholder="Enter a note"
                                                        value={invoice.paymentNote}
                                                        onChange={(event) => { invoice.paymentNote = event.target.value; this.setState(unpaidInvoices) }}
                                                    // error={accountNumberError}
                                                    // errorMessage="Account number is required"
                                                    // value={accountNumber}
                                                    />
                                                </div>
                                            </Flex>
                                        },
                                        expandedRowKeys: this.state.selectedRowKeys,
                                        expandIconColumnIndex: -1, //columns.length: to show icon at the last column -1: to hide column
                                        columnWidth: "2px",
                                        indentSize: "20px",
                                    }}
                                    rowSelection={true && { //showRowSelection && {
                                        type: "checkbox",
                                        onChange: (selectedRowKeys, selectedRows) => {
                                            console.log("onSelectRows: ", selectedRows)
                                            this.setState({ selectedRowKeys, selectedRows })
                                        },
                                        selectedRowKeys: this.state.selectedRowKeys
                                    }}
                                />

                            </FlexColumn>
                        </Container>
                    </FlexColumn>

                    {
                        payment &&
                        <ActionMenu
                            content={<>
                                <TextButton onClick={() => { this.match() }} icon={<CheckOutlined />} text='Confirm' />
                            </>}
                            summary={{
                                title: "Payment Summary",
                                data: [
                                    {
                                        description: "PAYMENT DATE",
                                        value: moment(payment.date).format('MMM Do, YYYY')
                                    }, {
                                        //     description: "TRANSACTION ID",
                                        //     value: '1234567890'
                                        // }, {
                                        //     description: "BANK DESCRIPTION",
                                        //     value: 'Lorem Ipsum',
                                        // }, {
                                        description: "PAYMENT AMOUNT",
                                        value: `$ ${toCurrency(payment.amount - payment.matchedAmount)}`,
                                    }, {
                                        gradient: true
                                    }, {
                                        description: "MATCHED AMOUNT",
                                        value: `$ ${toCurrency(matchedAmount)}`,
                                        large: true,
                                    }, {
                                        description: "REMAINING AMOUNT",
                                        value: `$ ${toCurrency(remainingAmount - matchedAmount)}`,
                                        color: (remainingAmount - matchedAmount) < 0 && "red",
                                    },
                                ]
                            }}
                        />
                    }
                </Flex>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        bkStore: state.bookkeepingAppReducer,
        aionStore: state.aionAppReducer,
        bankingStore: state.bankingAppReducer,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(MatchInvoice))