import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';

import moment from 'moment';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { apiPOSTReq, getPreSignedS3Url } from '../../../../Utils/api';
import environment from '../../../../environment';
import { ErrorAlert } from '../../../Reusable/Alert';
import { Table, Dropdown, Menu, Popconfirm } from 'antd';
import { Paragraph, Tag } from '../../../Reusable/Text';
import { ImageButton, TextButton } from '../../../Reusable/Button';
import { Flex, FlexColumn } from '../../../Reusable/Container';
import { Image } from '../../../Reusable/Image';
import More from "../../../../Images/more.png"
import Download from "../../../../Images/download.png"
import Check from "../../../../Images/check.png"
import Close from "../../../../Images/chip-close.png"
import { saveAs } from 'file-saver'

class AdditionalDocuments extends Component {
    state = {
        pagination: {
            current: 1,
            pageSize: 100
        }
    };

    componentDidMount() {
        const { allRequests } = this.props
        if (allRequests) {
            this.fetchRequests({ pagination: this.state.pagination });
        }
        else {
            this.fetchActiveRequests({ pagination: this.state.pagination });
        }
    }

    fetchDocuments = () => {
        const { loanApp } = this.props;
        var body = {
            "sortDirection": "DESC"
        };
        if (loanApp) {
            body["clientBusinessId"] = loanApp.businessId;
            body["applicationId"] = loanApp.applicationId;
            body["viewBy"] = "FILTER_APPLICATION_ID"
        }
        console.log("/docs/getActiveDocuments body", body, loanApp);
        this.setState({ qbConnectLoading: true });
        apiPOSTReq(`${environment.uamBaseUrl}/docs/getActiveDocuments`, null, body, (err, resp) => {
            console.log("/docs/getActiveDocuments resp", resp);
            this.setState({ qbConnectLoading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    this.setState({ businessDocuments: data.businessDocuments });
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    fetchRequests = (options) => {
        const { loanApp } = this.props;
        const { pagination } = options || {};
        var body = {
            "size": pagination.pageSize,
            "page": pagination.current - 1,
            "sortDirection": "DESC"
        };
        if (loanApp) {
            body["BusinessId"] = loanApp.businessId;
            body["applicationId"] = loanApp.applicationId;
            body["uploadCategory"] = "PreScreen"
        }

        console.log("/lsm/getLoanRequest body", body);
        this.setState({ loading: true });
        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/getLoanRequest`, null, body, (err, resp) => {
            console.log("/lsm/getLoanRequest resp", resp);
            this.setState({ loading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    this.setState({ requests: data.requests });
                    this.fetchDocuments();
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    fetchActiveRequests = (options) => {
        const { loanApp } = this.props;
        const { pagination } = options || {};
        var body = {
            "size": pagination.pageSize,
            "page": pagination.current - 1,
            "sortDirection": "DESC"
        };
        if (loanApp) {
            body["BusinessId"] = loanApp.businessId;
            body["applicationId"] = loanApp.applicationId;
            body["uploadCategory"] = "PreScreen"
        }

        console.log("/lsm/getActiveLoanRequest body", body);
        this.setState({ loading: true });
        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/getActiveLoanRequest`, null, body, (err, resp) => {
            console.log("/lsm/getActiveLoanRequest resp", resp);
            this.setState({ loading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    this.setState({ requests: data.requests });
                    if(data.requests.length == 0) {
                      //  this.props.refreshInfoRequestedTag(false)
                    } else {
                     //   this.props.refreshInfoRequestedTag(true)
                    }
                    this.fetchDocuments();
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    updateRequest = (request) => {
        const { loanApp } = this.props;
        const { UserInfo } = this.props.store;


        var body = {
            "BusinessId": loanApp.businessId,
            "applicationId": loanApp.applicationId,
            "request": request,
            "loggedInUser": `${UserInfo.Email}`
        };

        console.log("/lsm/updateLoanRequest body", body);
        this.setState({ loading: true });
        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/updateLoanRequest`, null, body, (err, resp) => {
            console.log("/lsm/updateLoanRequest resp", resp);
            this.setState({ loading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    this.fetchActiveRequests({ pagination: this.state.pagination });
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    download = (request) => {
        const { businessDocuments } = this.state;
        let requestId = request.requestId;
        let name = request.documentType;
        var documents = businessDocuments.filter(x =>  x.requestId == requestId);
        if (documents.length == 1) {
            let document = documents[0] || {}; 
            this.setState({ loadingDownload: true })
            this.downloadStatement(document);
        }else if(documents.length > 1) {
            this.downloadAll(documents);
        }
    }

    downloadStatement(item) {
        var filename = item.documentUrl.fileName;
        getPreSignedS3Url({ url: item.documentUrl.uri }, async (err, preSignedUrl) => {
            try {
                // prevents opening file in new tab
                let blob = await fetch(preSignedUrl).then((r) => r.blob());
                saveAs(blob, filename);

            } catch (err) {
                console.log(err);
            }
        })
    }

    // Download All docs
    downloadAll = (docs) => {
        const zip = new JSZip();
        let count = 0;
        const zipFilename = "AdditionalDocuments.zip";
        docs.forEach(async function (doc, index) {
            var filename = doc.documentUrl.fileName;
            try {
                getPreSignedS3Url({ url:  doc.documentUrl.uri }, async (err, preSignedUrl) => {
                    try {
                        const file = await JSZipUtils.getBinaryContent(preSignedUrl)
                        zip.file(filename, file, { binary: true });
                        count++;
                        if (count === docs.length) {
                            zip.generateAsync({ type: 'blob' }).then(function (content) {
                                saveAs(content, zipFilename);

                            });
                        }
                    }
                    catch (err) {
                        console.log(err);
                    }
                })
            } catch (err) {
                console.log(err);
            }
        });
    }

    getColumns = () => {
        const { allRequests, refreshInfoRequestedTag } = this.props
        const { loadingDownload } = this.state
        return [
            {
                title: 'ID',
                width: 100,
                dataIndex: 'parentRequestId'
            },
            {
                title: 'Request Date',
                width: 150,
                dataIndex: 'createdAt',
                render: item => moment(item).format('MM/DD/YY')
            },
            {
                title: 'Upload Date',
                width: 150,
                dataIndex: 'uploadedAt',
                render: item => item ? moment(item).format('MM/DD/YY') : '--'
            },
            {
                title: 'Document Type',
                width: 200,
                dataIndex: 'documentType'
            },
            {
                title: 'Period',
                width: 200,
                render: (item) => item.period == 'Date Range' ?
                    `${moment(item.fromDate).format('MM/DD/YY')} to ${moment(item.toDate).format('MM/DD/YY')}` :
                    item.period == 'As of Date' ? `As of ${moment(item.toDate).format('MM/DD/YY')}` :
                        "--"
            },
            {
                title: 'Multiple Upload',
                width: 120,
                render: (item)=>{
                    var isMultiple = item.allowMultipleUpload;
                    return(
                        <div>{isMultiple ? "true" : "false"}</div>
                    )
                }
            },
            {
                title: 'Status',
                width: 100,
                dataIndex: 'status',
                render: (item) => item == 'InProgress' ? <Tag>In Progress</Tag> :
                    item == 'InReview' ? <Tag primary>In Review</Tag> :
                        item == 'Submitted' ? <Tag primary>Submitted</Tag> :
                            item == 'Approved' ? <Tag color={'green'}>Approved</Tag> :
                                item == 'Rejected' ? <Tag color={'red'}>Rejected</Tag> :
                                    item == 'Cancelled' ? <Tag>Cancelled</Tag> : '--'
            },
            {
                title: '',
                width: 100,
                dataIndex: 'status',
                render: (item, request) =>
                    !allRequests ?
                        item == 'InProgress' ?
                            <div style={{marginLeft: "25px"}}>
                                <Popconfirm
                                placement='leftBottom'
                                title="Are you sure you want to cancel?"
                                onConfirm={() => {
                                    request.status = "Cancelled"
                                    this.updateRequest(request)
                                    
                                }}
                                okText="Confirm"
                                cancelText="Cancel"
                            >
                                <TextButton margin='0' rightIcon={<Image src={Close} />} text="Cancel" />
                            </Popconfirm>
                            
                            </div>
                            :
                            <Dropdown
                                trigger={['click']}
                                placement="bottomLeft"
                                overlay={
                                    <Menu style={{ padding: 24, borderRadius: 8, marginLeft: "25px" }}>
                                        <FlexColumn style={{ marginBottom: item == 'InReview' ? 18 : 0 }}>
                                            <TextButton margin='0' loading={loadingDownload} onClick={() => {
                                                this.download(request);
                                            }} rightIcon={<Image src={Download} />} text={"Download"} />
                                        </FlexColumn>
                                        {
                                            item == 'InReview' && <FlexColumn style={{ marginBottom: 18 }}>
                                                <Popconfirm
                                                    title="Are you sure you want to approve?"
                                                    onConfirm={() => {
                                                        request.status = "Approved"
                                                        this.updateRequest(request)
                                                    }}
                                                    okText="Confirm"
                                                    cancelText="Cancel"
                                                >
                                                    <TextButton margin='0' rightIcon={<Image src={Check} />} text="Approve" />
                                                </Popconfirm>
                                            </FlexColumn>
                                        }
                                        {
                                            item == 'InReview' && <FlexColumn>
                                                <Popconfirm
                                                    title="Are you sure you want to reject?"
                                                    onConfirm={() => {
                                                        request.status = "Rejected"
                                                        this.updateRequest(request)
                                                    }}
                                                    okText="Confirm"
                                                    cancelText="Cancel"
                                                >
                                                    <TextButton margin='0' rightIcon={<Image src={Close} />} text="Reject" />
                                                </Popconfirm>
                                            </FlexColumn>
                                        }
                                    </Menu>
                                }
                            >
                                <ImageButton src={More} />
                            </Dropdown>
                        :
                        (item != 'Cancelled' && item != 'InProgress') &&
                        <>
                            <TextButton margin='0' loading={loadingDownload} onClick={() => {
                                this.download(request);
                            }} rightIcon={<Image src={Download} />} text="Download" />
                        </>
            }
        ]
    }
    
    

    render() {
        const { loanApp } = this.props;
        const { requests, pagination, loading } = this.state;

        return (
            <Table
                id="inbox-table"
                tableLayout='auto'
                columns={this.getColumns()}
                dataSource={requests}
                rowKey='id'
                pagination={pagination}
                // onChange={this.handleTableChange}
                style={{ minHeight: "500px" }}
                loading={loading}
                scroll={{ x: '100%' }}
            />
        );
    }
}
function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(AdditionalDocuments)));