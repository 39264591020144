import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components';

// Components
import Bills from './Bills/Index';
import NewBillFlow from './Bills/New Bill/Index';
import Vendors from './Vendors/Index';
import Payments from './Contractors/Payments/Index';
import Contractors from './Contractors/Index';
import PayGroups from './Contractors/PayGroups/Index';
import ApprovalRules from './Approval Rules/Index';
import Inbox from './Bills/Inbox/Index';
import Pay from './Bills/Pay/Index';
import Reschedule from './Bills/Reschedule/Index';
import EditBill from './Bills/Edit/Index';
import NewVendor from './Vendors/New Vendor/Index';
import CreateVendor from './Vendors/Create Vendor/Index';
import EditVendor from './Vendors/Edit Vendor/Index';
import BillPayments from './Bills/Payments/Index';
import NewContractor from './Contractors/New Contractor/Index';
import NewPayGroup from './Contractors/New Pay Group/Index';
import NewPayment from './Contractors/Payments/NewPayment';
import environment from '../../environment';
import { apiPOSTReq } from '../../Utils/api';
import { addDataToStore, BNK_SAVE_DATA } from '../../Actions/actions';
import { FlexColumn } from '../Reusable/Container';
import PayBill from './Bills/PayBill';

class Index extends Component {

    componentDidMount() {
        this.fetchBankAccounts()
    }

    fetchBankAccounts = () => {
        
        apiPOSTReq(`${environment.payBaseUrl}/payables/getAccounts`, {}, null, (err, resp) => {
            try {
                console.log("/payables/getAccounts", err, resp)
                const data = resp
                if (data.result) {
                    if (err) throw new Error(err)
                    var accounts = data.accounts
                    this.props.dispatch(addDataToStore(BNK_SAVE_DATA, { BBAccounts: accounts }))
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getAccounts", error, err, resp)
            }
        })
    }

    render() {
        let { location, history } = this.props;

        const title = "Payables (Coming Soon)";
        return (
            <FlexColumn start style={{ padding: 0, flexGrow: 1, height: '100%' }}>
                <Switch location={location}>
                    <Route exact path="/payables/inbox" component={Inbox} />
                    <Route exact
                        path="/payables/inbox/detail"
                        render={(props) => (
                            <NewBillFlow {...props} {...this.props} />
                        )}
                    />
                    <Route exact path="/payables/bills" component={Bills} />
                    <Route exact
                        path="/payables/bills/new-bill"
                        render={(props) => (
                            <NewBillFlow newBill {...props} {...this.props} />
                        )}
                    />
                    <Route 
                        exact path="/payables/bills/edit"
                        render={(props) => (
                            <NewBillFlow {...props} {...this.props} />
                        )}
                    />
                    <Route 
                        exact path="/payables/bills/view" key='pay-bill' 
                        render={(props) => (
                            <NewBillFlow {...props} {...this.props} />
                        )} 
                    />
                    <Route exact path="/payables/bills/reschedule" component={Reschedule} />
                    <Route exact path="/payables/vendors" component={Vendors} />
                    <Route exact path="/payables/vendors/new-vendor" component={NewVendor} />
                    <Route exact path="/payables/vendors/create" component={EditVendor} />
                    <Route exact path="/payables/vendors/edit-vendor" component={EditVendor} />
                    <Route exact path="/payables/bill-payments" component={BillPayments} />
                    <Route exact path="/contractor-payroll/contractors" component={Contractors} />
                    <Route exact path="/contractor-payroll/contractors/new-contractor" component={NewContractor} />
                    <Route exact path="/contractor-payroll/contractors/edit-contractor" component={NewContractor} />
                    <Route exact path="/contractor-payroll/paygroups/new-paygroup" component={NewPayGroup} />
                    <Route exact path="/contractor-payroll/paygroups/edit-paygroup" component={NewPayGroup} />
                    <Route exact path="/contractor-payroll/paygroups" component={PayGroups} />
                    <Route exact path="/contractor-payroll/payments" component={Payments} />
                    <Route exact path="/contractor-payroll/payments/pay" component={NewPayment} />
                    <Route exact
                        path="/payables/controls"
                        render={(props) => (
                            <ApprovalRules key="vendor-approval-rules" {...props} type="vendor" />
                        )}
                    />
                    <Route exact
                        path="/payables/contractor-approval-rules"
                        render={(props) => (
                            <ApprovalRules key="contractor-approval-rules" {...props} type="contractor" />
                        )}
                    />
                </Switch>
            </FlexColumn>
        );
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)));