import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled, { withTheme } from 'styled-components'
import moment from 'moment'
import { Modal, Select, Space } from 'antd'
import _, { random } from 'underscore'
import Vouched from '../Vouched/Index'
import { InfoCircleOutlined } from '@ant-design/icons';
import cloneDeep from 'lodash/cloneDeep'

import { CardContainer, Flex, FlexColumn } from '../../Reusable/Container'
import FlowViewComponent from '../../Reusable/Refresh/FlowViewComponent'
import { addressObjectToStr, countryCodeMapUSFirst, countryCodeToNameMap, formatOwnershipPercentage, formatPhoneTextV2, formatSSN, getAddressObj, getTotalOwnershipPercentage, STATES_ABBR_MAP, STATES_MAP, toCurrency } from '../../../Utils/util'
import { LabeledInput, StyledInput } from '../../Reusable/Input'
import { Paragraph, Text } from '../../Reusable/Refresh/Text'
import NaicsField from '../../Reusable/Refresh/NaicsField'
import { addDataToStore, ONB_SAVE_DATA, PERSONAL_INFO, REMOTE_SAVE_DATA, RESET_ONBOARDING_STATE } from '../../../Actions/actions'
import { TextButton } from '../../Reusable/Button'
import SearchIcon from '../../../Images/icon-search.svg'
import Banner from '../../Reusable/Banner'
import Divider from '../../Reusable/Refresh/Divider'
import { ErrorAlert } from '../../Reusable/Alert'
import { Button, Chip } from '../../Reusable/Button'
import ModalClose from '../../../Images/modal-close.png'
import { apiPOSTReq } from '../../../Utils/api'
import environment from '../../../environment'

const { Option } = Select
export const dateFormatList = ['MM/DD/YYYY', 'MM/DD/YY', 'MM-DD-YYYY', 'MM-DD-YY']

class UserProfile extends Component {

    state = {
        submitLoading: false,
        personalInfoCopy: cloneDeep(this.props.onboardingStore.personalInfo)
    }

    componentDidMount() {
        this.fetchIdentification()
    }

    onCloseClick = () => {
        this.props.dispatch(addDataToStore(PERSONAL_INFO, this.state.personalInfoCopy))
        this.props.loadNext();
    }

    saveUserInput = (id, value) => {
        var dataToSave = {}
        var remoteSave = false
        var { allowAnyIdType, inviteUserType } = this.props
        var { citizenOrResident, countryOfCitizenship } = this.props.onboardingStore.personalInfo
        const foreignUser = (countryOfCitizenship != 'US') && (citizenOrResident == false)
        switch(id) {
            case "ssn":
                if(!foreignUser) {
                    value = value.replace(/[^\d]/g, '')
                } else {
                    value = value.replace(/[^0-9A-Z]+/gi, '')
                }
                dataToSave = { [id]: value }
                break
            case "idNumber":
                var identification = this.props.onboardingStore.personalInfo.identification || {}
                identification[id] = value
                dataToSave = { identification: identification }
                break
            case "expDate":
                var identification = this.props.onboardingStore.personalInfo.identification || {}
                identification[id] = value
                dataToSave = { identification: identification }
                break
            case "vouched":
                var vouchedData = value
                const { phone, email } = this.props.aionStore.userInfo
                var { countryOfCitizenship } = this.props.onboardingStore.personalInfo
                var { result, errors } = vouchedData || {}
                var idType = result.type
                switch (result.type) {
                        case "drivers-license":
                            idType = "DriversLicense"
                            break
                        case "passport":
                            idType = "Passport"
                            break
                        default:
                            idType = "DriversLicense" // "Other"
                            break
                }
                console.log("saveUserInput vouched", vouchedData, idType, countryOfCitizenship, citizenOrResident)
                if(allowAnyIdType == true) {
                    if( !((countryOfCitizenship == 'US') || citizenOrResident) && (idType !== "Passport") ) {
                        // Non-citizen user has to upload a passport
                        ErrorAlert({ description: "We only accept passport for non US citizens/residents. Please upload a passport" })
                        this.setState({ reloadIDV: true })
                        return
                    }
                } else {
                    if(((countryOfCitizenship == 'US') || citizenOrResident) && (idType !== "DriversLicense")) {
                        // US residents have to upload a driver's license
                        ErrorAlert({ description: "We only accept driver's license for US citizens/residents. Please upload a driver's license" })
                        this.setState({ reloadIDV: true })
                        return
                    } else if( !((countryOfCitizenship == 'US') || citizenOrResident) && (idType !== "Passport") ) {
                        // Non-citizen user has to upload a passport
                        ErrorAlert({ description: "We only accept passport for non US citizens/residents. Please upload a passport" })
                        this.setState({ reloadIDV: true })
                        return
                    }
                }
                
                /*
                if((errors || []).length > 0) {
                    ErrorAlert({ description: "There were some issues scanning your ID. Please try again." })
                    this.setState({ reloadIDV: true })
                    return
                }
                */
                var identification = {
                    "isPrimary": true,
                    "firstName": result.firstName,
                    "lastName": result.lastName,
                    "type": idType,
                    "idNumber": result.id,
                    "issuedDate": result.issueDate ? moment.utc(result.issueDate, "MM/DD/YYYY").format("YYYY-MM-DD") : null,
                    "expDate": result.expireDate ? moment.utc(result.expireDate, "MM/DD/YYYY").format("YYYY-MM-DD") : null,
                    "issuingAuthority": foreignUser ? (result.country || "Passport Authority") : (result.state || "DMV"),
                    "issuingStateOrProvince": result.state,
                    "issuingCountryCode": (result.country || '').length == 2 ? result.country : (result.country || countryOfCitizenship).substring(0, 2)
                }
                const idAddress = result.idAddress || {}
                var userInfo = {
                    "firstName": result.firstName,
                    "lastName": result.lastName,
                    "dob": result.birthDate ? moment.utc(result.birthDate, "MM/DD/YYYY").startOf('day').toDate() : null,
                    "phone": phone,
                    "authorizedSigner": true
                }
                if(email) userInfo.email = email
                var addrCC = idAddress.country || ''
                if(addrCC.length > 2) addrCC = addrCC.substring(0, 2)

                if(result.idAddress) {
                    userInfo.address = {
                        "line1": idAddress.streetNumber ? `${idAddress.streetNumber} ${(idAddress.street || "")}` : (idAddress.street || ""),
                        "city": idAddress.city,
                        "countrySubDivisionCode": ((idAddress.state || "").length == 2) ? idAddress.state : STATES_ABBR_MAP[idAddress.state],
                        "postalCode": idAddress.postalCode,
                        "country": countryCodeToNameMap[addrCC],
                        "countryCode": addrCC
                    }
                } else if((result.unverifiedIdAddress || []).length > 0) {
                    userInfo.address = {
                        "line1": result.unverifiedIdAddress[0],
                        "city": null,
                        "countrySubDivisionCode": null,
                        "postalCode": null,
                        "country": null,
                        "countryCode": null
                    }
                }
                console.log("vouched saving data", userInfo, identification)
                dataToSave = { vouchedJobId: vouchedData.id, vouchedData: vouchedData, identification: identification, ...userInfo }
                // Default primary applicant as control person
                // if(!inviteUserType) dataToSave.controlPerson = true
                this.props.dispatch(addDataToStore(PERSONAL_INFO, dataToSave))
                this.saveIdentification()
                return
            default:
                dataToSave = { [id]: value }
                break
        }
        this.props.dispatch(addDataToStore(PERSONAL_INFO, dataToSave))
    }

    handleOnChange = (event) => {
        const target = event.target
        if ((target.id || target.name) == 'ownershipPercentage') {
            if (isNaN(target.value)) {
                this.setState({ errorField: 'ownershipPercentage', errorMessage: 'Please enter a valid ownership percentage' })
            }
            this.saveUserInput(target.id || target.name, formatOwnershipPercentage(target.value >= 0 ? target.value : ''))
        } else {
            this.saveUserInput(target.id || target.name, target.value)
        }
        
    }

    setErrorField = (id, msg) => this.setState({ errorField: id, errorMessage: msg })

    fetchIdentification = () => {
        const { identificationId } = this.props.onboardingStore.personalInfo
        //console.log("/identifications/getActiveDocument resp", identificationId)
        if(!identificationId) return
        var body = {
            "id": identificationId
        }
        
        apiPOSTReq(`${environment.uamBaseUrl}/identifications/getActiveDocument`, {}, body, (err, resp) => {
            this.setState({ loading: false })
            try {
                const data = resp || {}
                console.log("/identifications/getActiveDocument resp", resp)
                if (data.result) {
                    var identification = data.identification
                    this.setState({
                        identification: identification
                    })
                    this.props.dispatch(addDataToStore(PERSONAL_INFO, { identification: identification }))
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp)
                // ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again" })
            }
        })
    }

    saveIdentification = () => {
        const { personalInfo } = this.props.onboardingStore || {}
        var idObj = personalInfo.identification;
        if(personalInfo.identificationId) {
            idObj["id"] = personalInfo.identificationId;
        }
        
        var body = {
            "identification": idObj
        }
        
        console.log("/identifications/save body", body)
        this.setState({ submitLoading: true })
        apiPOSTReq(`${environment.uamBaseUrl}/identifications/save`, {}, body, (err, resp) => {
            this.setState({ submitLoading: false })
            try {
                //console.log("/identifications/save resp", resp)
                const data = resp || {}
                if (data.result && data.identification) {
                    this.props.dispatch(addDataToStore(PERSONAL_INFO, { identificationId: data.identification.id }))
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/identifications/save err", error, resp)
                // ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again" })
            }
        })
    }

    validateFlow = () => {
        const { onboardingStore, flowView, inviteUserType, isCP } = this.props
        var { citizenOrResident, countryOfCitizenship, vouchedJobId, identification, firstName, lastName, dob, address, phone, ssn, businessTitle, ownershipPercentage } = onboardingStore.personalInfo
        const { idNumber, expDate } = identification || {}
        const foreignUser = (countryOfCitizenship != 'US') && (citizenOrResident == false)
        
        if(typeof(citizenOrResident) !== 'boolean') {
            this.setErrorField("citizenOrResident", "Select an option")
            return false;
        }
        if(!countryOfCitizenship){
            this.setErrorField("countryOfCitizenship", "Select your country of citizenship")
            return false;
        }
        if(!vouchedJobId) {
            ErrorAlert({description : "Please upload your government identification"})
            return false;
        }
        if(!firstName) {
            this.setErrorField("firstName", "Enter your first name")
            return false;
        }
        if(!lastName) {
            this.setErrorField("lastName", "Enter your last name")
            return false;
        }
        if(!dob) {
            this.setErrorField("dob", "Select a date")
            return false;
        }
        if(inviteUserType == 'CoOwner') {
            if(!businessTitle || _.isEmpty(businessTitle)) {
                this.setErrorField("title", "Title is required")
                return false;
            }
            if (!isCP && ownershipPercentage && isNaN(ownershipPercentage)) {
                this.setErrorField("ownershipPercentage", "Needs to be a valid number")
                return false;
            }
            if(!isCP && ownershipPercentage && ownershipPercentage < 25) {
                this.setErrorField("ownershipPercentage", "Needs to be 25% or more")
                return false;
            }
            const totalOwnership = getTotalOwnershipPercentage() // will include currently entered owner info as its stored as personalInfo in the onboarding store
            if(ownershipPercentage && (totalOwnership > 100)) {
                this.setErrorField("ownershipPercentage", `Total ownership cannot exceed 100%. Combined ownership of other owners is ${totalOwnership}%`)
                return false;
            }
            if(!phone) {
                this.setErrorField("phone", "Please enter a valid phone")
                return false;
            }
        }
        if(foreignUser && (!ssn || (ssn.length < 9) || (ssn.length > 20))) {
            this.setErrorField("ssn", "Enter a valid Tax ID between 9 and 20 characters")
            return false;
        } else if(!foreignUser && (!ssn || ((ssn || "").replace(/[^\d]/g, '').length !== 9))) {
            this.setErrorField("ssn", "Enter your SSN")
            return false;
        }
        if(!idNumber) {
            this.setErrorField("idNumber", "ID number is required")
            return false;
        }
        if(!expDate || !moment(expDate).isAfter(moment())) {
            this.setErrorField("expDate", "Your ID may have expired or been incorrectly filled. Update this field or reupload a valid document.")
            return false;
        }
        // if(!phone || (phone.replace(/[^\d]/g, '').length !== 11)) {
        //     this.setErrorField("phone", "Please enter a valid phone")
        //     return
        // }
        if(!address || !address.line1 || address.line1.includes("undefined") || !address.city || (!address.countrySubDivisionCode && !address.country) || !address.postalCode) {
            this.setErrorField("address", "Please enter a valid address")
            return false;
        }
        // Save to the DB
        console.log("Saving personalInfo", { personalInfo: onboardingStore.personalInfo })
        if(!this.props.disableRemoteSave) {
            this.props.dispatch(addDataToStore(REMOTE_SAVE_DATA, flowView.FlowStep))
        }
        // save any updated ID information
        this.saveIdentification()
        this.setErrorField({"": ""});
        return true;
    }
    
    validateExpiryDate = () => {
        const { onboardingStore } = this.props
        var { identification } = onboardingStore.personalInfo
        const { expDate } = identification || {}
        
        if(!expDate || !moment(expDate).isAfter(moment())) {
            this.setErrorField("expDate", "Their ID may have expired or been incorrectly filled. Update this field or reupload a valid document.")
            return false;
        } else {
            this.setErrorField({"": ""});
        }
    }

    loadNext = () => {
        var isValid = this.validateFlow();
        if(isValid) {        
            this.props.loadNext();
        }
    }

    disabledDOBDate = (current) => {
        // Can not select days after today and today and weekends
        return current > moment().subtract('18', "years")
    }

    handleDateChange = (id, dateStr) => {
        if (dateStr) {
            if(id == "dob") {
                this.saveUserInput(id, moment.utc(dateStr).startOf('day').toDate());
            } else {
                this.saveUserInput(id, moment.utc(dateStr).startOf('day').format("YYYY-MM-DD"))
            }
        } else {
            this.saveUserInput(id, null);
        }
        this.validateExpiryDate();
    }

    getLocation = (location, formattedAddress) => {
        let addrObj = getAddressObj(location)
        addrObj.city = addrObj.city ? addrObj.city.substring(0, 18) : ''
        console.log("getLocation", location, addrObj, formattedAddress)
        this.saveUserInput("address", addrObj)
    }

    render() {
        var { flowView, currentViewIndex, loadPrevView, flowSteps, onboardingStore, aionStore, theme, reviewView, allowAnyIdType, inviteUserType, isCP } = this.props
        var { errorField, errorMessage, showVouched, isWeb, isMobile } =  this.state
        var { citizenOrResident, countryOfCitizenship, vouchedJobId, identification, firstName, lastName, dob, address, phone, ssn, businessTitle, ownershipPercentage, phone } = onboardingStore.personalInfo
        const { idNumber, type, expDate, issuingStateOrProvince, issuingCountryCode } = identification || {}
        flowSteps = flowSteps || []
        flowView = flowView || {}
        const foreignUser = (countryOfCitizenship != 'US') && (citizenOrResident == false)
        // console.log("identification address", address) 
        const countryItems = []
        Object.values(countryCodeMapUSFirst).forEach(item => {
            countryItems.push(
                <Option key={item.code} value={item.code}>
                    {item.name}
                </Option>
            )
        })
        var idvKey = flowView.FlowStep || random(500)
        if (this.state.reloadIDV) idvKey = idvKey + random(500)
        if(typeof(vouchedJobId) == 'undefined') vouchedJobId = null
        var childViews = (
            <FlexColumn between fullHeight style={{ marginTop: "0px", height: '100%' }}>
                <FlexColumn start style={{ width: "100%" }} gap='24px'>
                    {
                        !vouchedJobId &&
                        <FlexColumn style={{ width: "25%", marginRight: 12 }}>
                            <LabeledInput
                                margin='0px'
                                label='Are you a resident of the U.S.?'
                                type='switch'
                                switchNames={['Yes', 'No']}
                                onChange={(value) => this.saveUserInput("citizenOrResident", (value == 'Yes'))}
                                value={(typeof(citizenOrResident) == 'boolean') ? (citizenOrResident ? 'Yes' : 'No') : null}
                                error={errorField=="citizenOrResident"}
                                errorMessage={errorMessage}
                            />
                        </FlexColumn>
                    }
                    {
                        <FlexColumn gap='24px'>
                            {
                                (typeof(citizenOrResident) == "boolean") &&
                                !vouchedJobId &&
                                <FlexColumn style={{ width: "25%", marginRight: 12 }}>
                                    <LabeledInput
                                        margin='0px'
                                        labelcolor={this.props.theme.colors.secondary3}
                                        label="Which country are you a citizen of?"
                                        type="select"
                                        key='Citizenship'
                                        id="Citizenship"
                                        placeholder="Select country of citizenship"
                                        optionFilterProp="children"
                                        onChange={value => { this.saveUserInput("countryOfCitizenship", value) }}
                                        value={countryOfCitizenship}
                                        error={errorField=="countryOfCitizenship"}
                                        errorMessage={errorMessage}
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().startsWith(input.toLowerCase())
                                        }
                                    >
                                        {countryItems}
                                    </LabeledInput>
                                    
                                </FlexColumn>
                            }
                            {
                                (typeof(citizenOrResident) == 'boolean') && countryOfCitizenship && (onboardingStore.allowNRFlow || (countryOfCitizenship == 'US') || citizenOrResident) &&
                                (!vouchedJobId ? 
                                    <CardContainer style={{ position: 'relative', overflow: 'hidden', boxShadow: '0px 0px 32px rgba(102, 102, 102, 0.16)' }}>
                                        <div className='vector-8' />
                                        <div className='vector-7' />
                                        <div className='vector-6' />
                                        <FlexColumn left>
                                            <Text size='20px' height='40px' weight='500' spacing='0.04em'>Verify your government identification</Text>
                                            <Text>Please be ready with a copy of your government-issued identification</Text>
                                            <Text uppercase color={theme.colors.caption} style={{ marginTop: '24px' }}  spacing='0.08em'>Accepted Documents</Text>
                                            <ul style={{ paddingLeft: 20 }}>
                                            {
                                                    ((countryOfCitizenship == 'US') || (citizenOrResident == true)) ? 
                                                    (
                                                        (allowAnyIdType == true) ?
                                                        <>
                                                            <li>Driver's License</li>
                                                            <li>Passport</li>
                                                        </>
                                                        :
                                                        <>
                                                            <li>Driver's License</li>
                                                            {/* <li>Passport</li> */}
                                                        </>
                                                    )
                                                    :
                                                    <li>Passport</li>
                                                }
                                            </ul>
                                            <Flex gap="16px" style={{ marginTop: '16px' }}>
                                                <Button solid text='USE WEBCAM'  onClick={() => this.setState({ showVouched: true, isWeb: true, isMobile: false })} />
                                                <Button text='USE PHONE' onClick={() => this.setState({ showVouched: true, isWeb: false, isMobile: true })} rightIcon={ <InfoCircleOutlined style={{ marginLeft: 4, color: 'rgba(0,0,0,.45)' }} />}/>
                                            </Flex>
                                        </FlexColumn>
                                    </CardContainer>
                                :
                                    <FlexColumn>
                                        <FlexColumn style={{ marginTop: "24px" }}>
                                            <Text size="20px" weight={500} height="40px">{type == "DriversLicense" ? "Drivers License" : (type == "Passport" ? "Passport" : "ID Document")}</Text>
                                            <Flex start >
                                                <Text spacing="0.04em" size="14px" height="24px">{type == "DriversLicense" ? `Issued in ${STATES_MAP[issuingStateOrProvince] || issuingStateOrProvince} • Expires on ${moment(expDate).format('ll')}` : `Issued in ${countryCodeToNameMap[issuingCountryCode] || issuingCountryCode} • Expires on ${moment(expDate).format('ll')}`} •</Text>
                                                <TextButton size='14px' height='24px' padding='0px 5px' text='REUPLOAD' onClick={() => this.setState({ showVouched: true })} />
                                            </Flex>
                                            <Banner
                                                style={{ marginTop: "24px" }}
                                                message="We’ve autofilled the information from your document. You can edit the below details as required."
                                            />
                                            <Divider />
                                        </FlexColumn>
                                        <FlexColumn style={{ width: "75%", marginRight: 16 }}>
                                            <Flex gap="24px">
                                                <LabeledInput
                                                    labelcolor={this.props.theme.colors.secondary3}
                                                    label="First Name"
                                                    key="firstName"
                                                    id="firstName"
                                                    onChange={this.handleOnChange}
                                                    value={firstName}
                                                    placeholder="Robert"
                                                    error={errorField=="firstName"}
                                                    errorMessage={errorMessage}
                                                />
                                                <LabeledInput
                                                    labelcolor={this.props.theme.colors.secondary3}
                                                    label="Last Name"
                                                    key="lastName"
                                                    id="lastName"
                                                    onChange={this.handleOnChange}
                                                    value={lastName}
                                                    placeholder="Glass"
                                                    error={errorField=="lastName"}
                                                    errorMessage={errorMessage}
                                                />
                                                <LabeledInput
                                                    labelcolor={this.props.theme.colors.secondary3}
                                                    label="Date of Birth"
                                                    type="date-picker"
                                                    defaultPickerValue={moment().subtract(18, "years")}
                                                    disabledDate={this.disabledDOBDate}
                                                    key={"DOB"}
                                                    id="dob"
                                                    onChange={(date, dateStr) => this.handleDateChange("dob", dateStr)}
                                                    format={dateFormatList}
                                                    value={dob && moment.utc(dob)}
                                                    placeholder="Select"
                                                    error={errorField=="dob"}
                                                    errorMessage={errorMessage}
                                                    style={{ height: "30px" }}
                                                />
                                            </Flex>
                                            <Flex gap="24px">
                                                <LabeledInput
                                                    autoFocus
                                                    onKeyDown={this.handleOnKeyDown}
                                                    labelcolor={this.props.theme.colors.secondary3}
                                                    label={foreignUser ? `Personal Tax ID Number` : "Social Security Number"}
                                                    id="ssn"
                                                    type="mask"
                                                    placeholder="123-44-5550"
                                                    onChange={this.handleOnChange}
                                                    value={ssn && (foreignUser ? ssn : formatSSN(ssn))}
                                                    error={errorField=="ssn"}
                                                    errorMessage={errorMessage}
                                                    maxLength={foreignUser ? 36 : 11}
                                                />
                                                <LabeledInput
                                                    labelcolor={this.props.theme.colors.secondary3}
                                                    label={type == "DriversLicense" ? "License Number" : (type == "Passport" ? "Passport Number" : "ID Document Number")}
                                                    id="idNumber"
                                                    value={idNumber}
                                                    placeholder="••••••••••"
                                                    onChange={this.handleOnChange}
                                                    error={errorField=="idNumber"}
                                                    errorMessage={errorMessage}
                                                    style={{ height: "30px" }}
                                                />
                                                <LabeledInput
                                                    labelcolor={this.props.theme.colors.secondary3}
                                                    label={type == "DriversLicense" ? "License Expiry" : (type == "Passport" ? "Passport Expiry" : "ID Document Expiry")}
                                                    id="expDate"
                                                    type="date-picker"
                                                    placeholder="Select"
                                                    onChange={(date, dateStr) => this.handleDateChange("expDate", dateStr)}
                                                    style={{ height: "30px" }}
                                                    format={dateFormatList}
                                                    value={expDate && moment.utc(expDate)}
                                                    error={errorField=="expDate"}
                                                    errorMessage={errorMessage}
                                                />
                                                {/* <LabeledInput
                                                    labelcolor={this.props.theme.colors.secondary3}
                                                    label="Phone Number"
                                                    id="phone"
                                                    type="text"
                                                    value={phone && formatPhoneTextV2(phone)}
                                                    placeholder="(000) 000 0000"
                                                    onChange={this.handleOnChange}
                                                    error={errorField=="phone"}
                                                    errorMessage={errorMessage}
                                                /> */}
                                            </Flex>
                                            {
                                                inviteUserType == 'CoOwner' &&
                                                <Flex gap="24px">
                                                    <LabeledInput
                                                        label="Title"
                                                        id="businessTitle"
                                                        type="text"
                                                        placeholder="Enter your title"
                                                        value={businessTitle}
                                                        onChange={this.handleOnChange}
                                                        error={errorField == "title"}
                                                        errorMessage={errorMessage}
                                                        maxLength={50}
                                                    />
                                                    <LabeledInput
                                                        label="Phone Number"
                                                        labelcolor={theme.colors.secondary3}
                                                        id="phone"
                                                        type="text"
                                                        placeholder="(000) 000 0000"
                                                        value={formatPhoneTextV2(phone)}
                                                        onChange={this.handleOnChange}
                                                        error={errorField == "phone"}
                                                        errorMessage={errorMessage}
                                                    />
                                                    {
                                                        !isCP ? 
                                                        <LabeledInput
                                                            label="Ownership Percentage"
                                                            id="ownershipPercentage"
                                                            symbolsuffix="%"
                                                            placeholder="Enter Percentage"
                                                            value={formatOwnershipPercentage(ownershipPercentage? ownershipPercentage.toString() : '')}
                                                            onChange={this.handleOnChange}
                                                            error={errorField == "ownershipPercentage"}
                                                            errorMessage={errorMessage}
                                                            maxLength={6}
                                                            suffixFontSzie="20px"
                                                        />
                                                        :
                                                        <div style={{ width: '100%' }}></div>
                                                    }
                                                </Flex>
                                            }
                                            <LabeledInput
                                                labelcolor={this.props.theme.colors.secondary3}
                                                label="Home Address"
                                                type="location"
                                                key={"Address"}
                                                id="address"
                                                getLocation={this.getLocation}
                                                value={address}
                                                placeholder="Enter address"
                                                country
                                                error={errorField=="address"}
                                                errorMessage={errorMessage}
                                            />
                                        </FlexColumn>
                                    </FlexColumn>
                                )
                            }
                        </FlexColumn>
                    }
                    {
                        (citizenOrResident == false) && countryOfCitizenship && !(onboardingStore.allowNRFlow || (countryOfCitizenship == "US")) &&
                        <Banner style={{ marginTop: "24px", width: "700px" }} message="We currently do not support non US resident, non US citizen applicants" />
                    }
                </FlexColumn>
            </FlexColumn>
        )
        
        if(reviewView) {
            return (
                <FlexColumn start fullWidth left>
                    <Flex start fullWidth gap='24px'>
                        <FlexColumn style={{ width: "25%" }}>
                            <LabeledInput
                                label='Are you a resident of the U.S.?'
                                type='read-only'
                                value={(typeof(citizenOrResident) == 'boolean') ? (citizenOrResident ? 'Yes' : 'No') : null}
                            />
                        </FlexColumn>
                        <FlexColumn style={{ width: "25%" }}>
                            <LabeledInput
                                label="Which country are you a citizen of?"
                                type="read-only"
                                value={countryCodeToNameMap[countryOfCitizenship]}
                            />
                        </FlexColumn>
                    </Flex>
                    <Flex start fullWidth gap='24px'>
                        <FlexColumn style={{ width: "25%" }}>
                            <LabeledInput
                                label="First Name"
                                type='read-only'
                                value={firstName}
                            />
                        </FlexColumn>
                        <FlexColumn style={{ width: "25%" }}>
                            <LabeledInput
                                label="Last Name"
                                type='read-only'
                                value={lastName}
                            />
                        </FlexColumn>
                    </Flex>
                    <Flex start fullWidth gap='24px'>
                        <FlexColumn style={{ width: "25%" }}>
                            <LabeledInput
                                label="Phone Number"
                                type='read-only'
                                value={foreignUser ? phone : (formatPhoneTextV2(phone))}
                            />
                        </FlexColumn>
                        <FlexColumn style={{ width: "50%" }}>
                            <LabeledInput
                                type='read-only'
                                label="Home address"
                                value={addressObjectToStr(address)}
                            />
                        </FlexColumn>
                    </Flex>
                    <Flex start fullWidth gap='24px'>
                        <FlexColumn style={{ width: "25%" }}>
                            <LabeledInput
                                type='read-only'
                                label={type == "DriversLicense" ? "License Number" : (type == "Passport" ? "Passport Number" : "ID Document Number")}
                                value={idNumber}
                            />
                        </FlexColumn>
                        <FlexColumn style={{ width: "25%" }}>
                            <LabeledInput
                                type='read-only'
                                label="Date of Birth"
                                value={dob && moment.utc(dob).format('MMM DD, YYYY')}
                            />
                        </FlexColumn>
                        <FlexColumn style={{ width: "25%" }}>
                            <LabeledInput
                                type='read-only'
                                label={foreignUser ? `Personal Tax ID Number` : "Social Security Number"}
                                value={ssn && (foreignUser ? `XXXX${ssn.substring(ssn.length-4, ssn.length)}` : `XXX-XX-${ssn.substring(ssn.length-4, ssn.length)}`)}
                            />
                        </FlexColumn>
                    </Flex>
                </FlexColumn>
            )
        }
        return (
            <>
                <FlowViewComponent
                    flowView={flowView}
                    currentViewIndex={currentViewIndex}
                    loadPrev={loadPrevView}
                    childViews={childViews}
                    submitLoading={this.state.submitLoading}
                    width={'100%'}
                    back={false}
                    padding='0 64px'
                    {...this.props}
                    loadNext={this.loadNext}
                    onCloseClick={this.onCloseClick}
                />
                <Modal
                    visible={showVouched}
                    footer={null}
                    closable={true}
                    width={700}
                    destroyOnClose={true}
                    onCancel={() => {
                        this.setState({ showVouched: false, isWeb: false, isMobile: false })
                    }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                    style={{ minWidth: '500px', minHeight: '500px', top: 20 }}
                >
                    <Vouched
                        key={idvKey}
                        isWeb={isWeb}
                        isMobile={isMobile}
                        onDone={(job) => {
                            this.saveUserInput("vouched", job)
                            console.log("PersonalInfo.IDV onDone", job)
                            this.setState({ showVouched: false, isMobile: false, isWeb: false })
                            this.validateExpiryDate();
                        }}
                        idType={environment.isSandbox ? 'id' : 'idv'} // idv: document + selfie, id: document only
                    />
                </Modal>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        onboardingStore: state.onboardingAppReducer,
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(UserProfile))