import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter, Route, Switch } from 'react-router-dom'
import styled, { withTheme } from 'styled-components'
import moment from 'moment'
import { Layout, Modal, notification, message, Checkbox, Alert, Badge, Dropdown, Menu } from 'antd'
import queryString from 'query-string'
import IdleTimer from 'react-idle-timer'
import Bowser from "bowser"

// Actions
import { addDataToStore, WINDOW_CLOSE_TIMESTAMP, UAM_SAVE_PERMISSIONS, BNK_SAVE_DATA, USERDEFAULTS_SAVE_FLAG, SAVE_DATA, CREDIT_SAVE_DATA } from '../../Actions/actions'

// Components
import OnboardingFlowCoordinator from '../OnboardingFlowCoordinator'
import BankingOnboarding from '../OnboardingFlowCoordinator/BankingOnboarding/Index'

import CreditOnboarding from '../OnboardingFlowCoordinator/CreditOnboarding/Prescreen/Index'
import PreScreenLoading from '../OnboardingFlowCoordinator/CreditOnboarding/Prescreen/PreScreenLoading'
import Banking from '../Banking/Index'
import Credit from '../Credit/Index'
import Settings from '../Settings/Index'
import Pay from '../Pay/Index'
import Approvals from '../Approvals/Index'
import Support from '../SupportConsole/Index'
import Receivables from '../Receivables/Index'
import Invoices from '../Receivables/Invoices/Index'
import RequestAdvance from '../Credit/Term Loans/Request Advance/Index'
import UnderwritingConsole from '../UnderwritingConsole/Index'
import TermLoans from '../Credit/Term Loans/Index'
import { getUserApps, getUserRoles, removeArrItem, getResourcePerm, getFeaturePerm } from '../../Utils/util'
import { apiGET, doSignout, recordTimeStamp, getJwt, apiPOSTReq } from '../../Utils/api'
import { Flex, FlexColumn } from '../Reusable/Container'
import { Text } from '../Reusable/Text'
import { TextButton, Button, ImageButton } from '../Reusable/Button'
import { Paragraph } from '../Reusable/Text'
import QBIntegration from '../Settings/QuickBooksOnline/Index'
import CreditEligibility from '../Credit/Eligibility/Index'
import CreditEligibilityRefresh from '../Credit/EligibilityRefresh/PreScreen/Index'
import NavMenu from './NavMenu'

// Images
import AlertIcon from "../../Images/alert.png"
import AlertBlue from "../../Images/alert-blue.png"
import SearchIcon from "../../Images/search-icon.png"
import ModalClose from '../../Images/modal-close.png'
import ArrowRight from "../../Images/arrow-right.png"
import Logout from "../../Images/logout-blue.png"
import Missing from "../../Images/missing.png"
import AionText from "../../Images/aion-logo-text.png"

// Actions
import { completeSignin } from '../../Actions/actions'
import { getUserPermissions, uaApiPOST } from '../../Utils/userAccess'
import { ErrorAlert, Notification } from '../Reusable/Alert'
import { LockedAccountModalContent } from '../Reusable/Modal'
import environment from '../../environment'

// Assets
import { ApiOutlined, WarningOutlined, PoweroffOutlined, ArrowRightOutlined } from '@ant-design/icons'
import Result from '../Reusable/Result'
import ProfileImage from '../Settings/Profile/UserProfile/ProfileImage'
import { Image } from '../Reusable/Image'
import Banner from '../Reusable/Banner'
import ErrorModal from '../Reusable/ErrorModal'
import TopNav from '../Reusable/TopNav'


const { Content, Header } = Layout

const entityNameDict = {
    "BillPayment": "Bill Payments",
    "CustomCOA": "GL Accounts",
    "Bill": "Bills",
    "Vendor": "Vendors",
    "Customer": "Customers",
    "Invoice": "Invoices",
    "Product": "Products",
}

const entitySortDict = {
    CustomCOA: 0,
    Vendor: 1,
    Bill: 2,
    BillPayment: 3,
    Customer: 4,
    Product: 5,
    Invoice: 6,
}

export const TopDivider = styled.div`
    background-image: linear-gradient(to right, rgba(203, 206, 214, 1), rgba(203, 206, 214, 0.2)); 
    height: 1px;
    width: 100%;
`;

class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hideSetting: false,
            showARUploadResult: false,
            showInitExternalBankModal: false,
            collapsed: true,
            pinned: true,
            notification: false,
            approvalNotificationCount: 0,
            notificationClosed: false,
            // showLockedAccountModal: true,
        }
        this.intervalTmr = null
    }

    componentDidMount() {
        window.addEventListener("beforeunload", recordTimeStamp)
        window.addEventListener("resize", this.handleResize)

        this.checkBrowser()
        this.getAttributes()
        this.checkForSystemBanner()
        this.checkLockedAccount()
        this.fetchLinkedBusinesses()
        this.fetchBanks()
        this.fetchBusinessAttributes()
        this.maintainAuth()

        this.intervalTmr = setInterval(this.maintainAuth, 1000 * 900) // 15 mins = 9,00,000 ms
        // Scroll to top on any route changes
        this.props.history.listen((location, action) => {
            window.scrollTo(0, 0)
        })

        this.fetchAllInboxItems()
        this.fetchHolidays()

        if (this.props.aionStore.hasIntegration) {
            const { syncJobId } = this.props.aionStore
            if (syncJobId) {
                this.timer = setInterval(() => this.poll(), 5000)
                notification.close('completed')
                this.openNotification({ duration: 0, status: 'inProgress', key: 'inProgress' })
            }
        }

        if (this.props.aionStore.bankNameList) {
            const { bankNameList, UAM } = this.props.aionStore
            if (!this.props.aionStore.initBankNameList && bankNameList && bankNameList.length > 0) {
                this.setState({ showExternalBankModal: true && !UAM.aionUnderWriterUser })
            }
        }

    }

    fetchBanks = () => {
        const headers = {
            businesskey: this.props.aionStore.BusinessUniqueKey,
        }

        const body = {
            "BusinessId": this.props.aionStore.businessId
        }
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/plaid/getInstitutionInfo`, headers, body, (err, resp) => {
            try {
                const data = resp;
                var bankInfo = data.externalBBInstitutions
                for (let index = 0; index < bankInfo.length; index++) {
                    if (bankInfo[index].status != "Connected") {
                        this.setState({ reconnectExternalBank: true, showNotificationModal: false })
                    }
                }
            } catch (error) {
                console.log("ERROR", error.stack)
            } finally {

            }
        })
    }

    fetchBusinessAttributes = () => {
        const showCreditPlus = getFeaturePerm("AionApplications.Onboarding.CreditPlus").manage
        if (showCreditPlus) {
            this.fetchLoanApp();
        }
    }

    fetchLoanApp = () => {
        const { UserInfo } = this.props.store || {};
        // Fetch document list
        var body = {
            "clientBusinessId": this.props.aionStore.BusinessUniqueKey,
            "size": 100,
            "page": 0,
            // "sortDirection": "ASC"
        }
        this.setState({ loanAppLoading: true });
        apiPOSTReq(`${environment.opsBaseUrl}/ops/loans/application/getApplications`, {}, body, (err, resp) => {
            try {
                const data = resp || {};
                if (data.result) {
                    var loanApplications = data.loanApplications || [];
                    var loanApp = loanApplications.length > 0 ? loanApplications[loanApplications.length - 1] : {};
                    if (!loanApp.userStatus) {
                        this.setState({ creditPlusTitle: "Get funded and grow your business!", creditPlusSubTitle: "Apply Now" })
                    }
                    else {
                        this.setState({ creditPlusTitle: "Your financing application is in progress", creditPlusSubTitle: "Continue" })
                    }

                    this.setState({ showCreditPlus: "true" });
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/fetchLoanApp err", error, resp);
                // ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            } finally {
                this.setState({ loanAppLoading: false });
            }
        })
    }

    handleResize = () => {
        if (typeof (window)) {
            this.setState({ isMobile: window.matchMedia("only screen and (max-width: 760px)").matches })
        }
    }

    checkBrowser = () => {
        const browser = Bowser.getParser(window.navigator.userAgent)
        const browserName = browser.getBrowserName()
        if (!(browserName.toLowerCase().includes("chrome") || browserName.toLowerCase().includes("safari") || browserName.toLowerCase().includes("edge"))) {
            this.setState({ unsupported: true })
        }
    }

    componentDidUpdate(prevProps) {
        var { UAM } = this.props.aionStore
        if (this.props.aionStore.vendorUploadJobId !== prevProps.aionStore.vendorUploadJobId) {
            const { vendorUploadJobId } = this.props.aionStore
            if (!vendorUploadJobId) {
                clearInterval(this.uploadVendorTimer)
                this.uploadVendorTimer = null
            } else if (vendorUploadJobId && !this.uploadVendorTimer) {
                this.uploadVendorTimer = setInterval(() => this.pollVendorUpload(), 5000)
            }
        }
        if (this.props.aionStore.contractorUploadJobId !== prevProps.aionStore.contractorUploadJobId) {
            const { contractorUploadJobId } = this.props.aionStore
            if (!contractorUploadJobId) {
                clearInterval(this.uploadContractorTimer)
                this.uploadContractorTimer = null
            } else if (contractorUploadJobId && !this.uploadContractorTimer) {
                this.uploadContractorTimer = setInterval(() => this.pollContractorUpload(), 5000)
            }
        }

        if (!this.props.aionStore.hasIntegration) {
            return
        }
        if (this.props.aionStore.syncJobId !== prevProps.aionStore.syncJobId) {
            const { syncJobId } = this.props.aionStore
            if (!syncJobId) {
                clearInterval(this.timer)
                this.timer = null
            } else if (syncJobId && !this.timer) {
                this.timer = setInterval(() => this.poll(), 5000)
                notification.close('completed')
                this.openNotification({ duration: 0, status: 'inProgress', key: 'inProgress' })
            }
        }
        if (this.props.aionStore.hideNotification !== prevProps.aionStore.hideNotification) {
            const { hideNotification } = this.props.aionStore
            if (hideNotification !== undefined && hideNotification === false) {
                this.props.dispatch(addDataToStore(SAVE_DATA, { hideNotification: true }))
                this.setState({ showNotificationModal: true && !UAM.aionUnderWriterUser && !this.state.reconnectExternalBank })
            }
        }

        if (this.props.aionStore.initBankNameList !== prevProps.aionStore.initBankNameList) {
            const { initBankNameList } = this.props.aionStore
            if (initBankNameList && initBankNameList.length > 0) {
                this.props.dispatch(addDataToStore(SAVE_DATA, { initBankNameList: null, bankNameList: initBankNameList }))
                this.setState({ showInitExternalBankModal: true && !UAM.aionUnderWriterUser })
            }
        }
    }

    fetchInboxItems = (baseUrl) => {
        const { UserInfo } = this.props.aionStore;
        const username = UserInfo.Email;
        const payload = { "BankProvider": environment.bankProvider.crb, "approverUserId": username };

        return new Promise((resolve, reject) => {
            apiPOSTReq(`${baseUrl}/approvals/getInboxItems`, { "BankProvider": environment.bankProvider.crb }, payload, (err, resp) => {
                if (err) return reject(err);
                if (!resp.result) return reject(resp.error || resp.responseMessage || "Error fetching data");

                console.log('fetchInboxItems: ', resp, err)

                resolve(resp.pendingItemList ? resp.pendingItemList.length : 0);
            });
        });
    }

    fetchAllInboxItems = () => {
        Promise.allSettled([
            this.fetchInboxItems(`${environment.bbBaseUrl}/bb`),
            this.fetchInboxItems(`${environment.payBaseUrl}/payables`)
        ]).then(results => {
            let approvalNotificationCount = results.reduce((acc, result) => {
                if (result.status === 'fulfilled') {
                    acc += result.value;
                }
                return acc;
            }, 0);
            this.setState({ approvalNotificationCount });
        });
    }

    fetchHolidays = () => {
        const { aionStore } = this.props
        var { publicHolidays } = aionStore


        if (!publicHolidays) {
            apiPOSTReq(`${environment.payBaseUrl}/payables/getHolidayList`, null, {}, (err, resp) => {
                try {
                    const data = resp

                    console.log("payables/getHolidayList", data)

                    if (data.result) {
                        if (err) throw new Error(err)

                        if (data.holidayCalendarList && data.holidayCalendarList.length > 0) {
                            var publicHolidays = []

                            data.holidayCalendarList.forEach(holidayCalendar => {
                                holidayCalendar.holidays.forEach(holiday => {
                                    publicHolidays.push(holiday.date)
                                })
                            })

                            this.props.dispatch(addDataToStore(SAVE_DATA, { publicHolidays }))
                            this.showHolidayBanner(publicHolidays)
                        }
                    } else {
                        throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                    }
                } catch (error) {
                    console.log("ERRR getAccounts", error, err, resp)
                }
            })
        } else {
            this.showHolidayBanner(publicHolidays)
        }
    }

    showHolidayBanner = (publicHolidays) => {
        publicHolidays.forEach((holiday) => {
            if (moment().isAfter(moment(holiday).subtract(3, 'days')) && moment().isBefore(moment(holiday).add(1, 'days'))) {
                const bannerText = `Aion will be closed on ${moment(holiday).format('ll')} as it is a banking holiday. While we may not be available, you can access your account anytime via Online & Mobile. We hope you enjoy your holiday!`
                // Notification({ title: "Alert", description: bannerText, duration: 20 })
                this.setState({ notification: true, notificationText: bannerText })
            }
        })
    }

    componentWillUnmount() {
        console.log("clearInterval maintainAuth")
        clearInterval(this.intervalTmr)
        clearInterval(this.timer)
        clearInterval(this.uploadVendorTimer)
        clearInterval(this.uploadContractorTimer)
        this.timer = null
        this.uploadContractorTimer = null
        this.uploadVendorTimer = null
        notification.close('inProgress')
        notification.close('completed')
        window.removeEventListener("resize", this.handleResize)
    }

    maintainAuth = () => {

        // This will update permissions on window.reload
        console.log("Running interval maintainAuth")
        // Refresh JWT
        getJwt((err, jwt) => { })
        // Refresh AionAuth
        getUserPermissions(null, (err, resp) => {
            try {
                if (err) throw Error(err)
                if (!resp.data.authToken) throw Error(resp.data.responseMessage)
                console.log("Running interval getUserPermissions")
                this.props.dispatch(addDataToStore(UAM_SAVE_PERMISSIONS, { UAM: resp.data }))
                // Notification({title: "Auth refresh", description: `Refresh time: ${moment().format("lll")} ${resp.data.authToken}`, duration: 0})
            } catch (error) {
                console.log("maintainAuth Error", error.message, err, resp)
            }
        })
    }

    getAttributes() {
        // Fetch attributes
        apiGET("/attributes", null, (err, resp) => {
            try {
                const data = resp.data
                if (data.success) {
                    this.props.dispatch(completeSignin(data))
                    this.setState({})
                    // Need to check here too in case user uses a direct link without signing in first
                    if (this.props.aionStore.WINDOW_CLOSE_TIMESTAMP && (Date.now() - this.props.aionStore.WINDOW_CLOSE_TIMESTAMP > 1000 * 15 * 60)) {
                        this.props.dispatch(addDataToStore(WINDOW_CLOSE_TIMESTAMP, { WINDOW_CLOSE_TIMESTAMP: null }))
                        doSignout(this.props.history, this.props.dispatch, true)
                    }

                    var { reloadTermLoans } = this.props.creditStore
                    if (reloadTermLoans) {
                        this.props.dispatch(addDataToStore(CREDIT_SAVE_DATA, { reloadTermLoans: false }));
                        setTimeout(() => {
                            this.props.history.replace('/credit/term-loans/advances');
                        }, 2000);
                    }
                } else {
                    throw Error("Could not fetch attributes.")
                }
            } catch (error) {
                console.log("Error fetching /attributes")
            }
        })
    }

    checkForSystemBanner() {
        var { aionStore, userDefaults } = this.props
        var { DesktopSystemBanner } = aionStore.AppConfig || {}
        DesktopSystemBanner = DesktopSystemBanner || {}
        const alertDisplayed = userDefaults[DesktopSystemBanner.Message]
        if (DesktopSystemBanner.Display && !alertDisplayed) {
            this.props.dispatch(addDataToStore(USERDEFAULTS_SAVE_FLAG, { [DesktopSystemBanner.Message]: true }))
            Notification({ title: DesktopSystemBanner.title || "Alert", description: DesktopSystemBanner.Message, url: DesktopSystemBanner.URL, duration: 0 })
        }
    }

    checkLockedAccount() {
        let { aionStore } = this.props
        const allowedApps = getUserApps(aionStore)
        console.log("checkLockedAccount", allowedApps)
        if (allowedApps.includes("BusinessBanking")) {
            apiPOSTReq(`${environment.bbBaseUrl}/bb/getAccounts`, { "BankProvider": environment.bankProvider.crb }, null, (err, resp) => {
                try {
                    console.log("/bb/getAccounts", err, resp)
                    const data = resp
                    if (data.result) {
                        if (err) throw new Error(err)
                        var accounts = data.bbaccounts.filter(account => account.bankProvider === 'CrossRiverBank')
                        var accountLocked = accounts.find(account => account.locked)
                        var accountClosed = accounts.find(account => ((account.status || "").toLowerCase() === "closed"))
                        this.props.dispatch(addDataToStore(BNK_SAVE_DATA, { BBAccounts: accounts, AccountLocked: accountLocked, AccountClosed: accountClosed }))
                        if (accountLocked || accountClosed) {
                            // Remove comment to not let 
                            // this.setState({ showLockedAccountModal: true })
                        }
                    } else {
                        throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                    }
                } catch (error) {
                    console.log("ERRR getAccounts", error, err, resp)
                }
            })
        }
    }

    // This fetches all the businesses the user is connected to
    fetchLinkedBusinesses = () => {
        uaApiPOST("/aion/system/getBusinessAccessForUser", { size: 500, page: 1 }, (err, resp) => {
            try {
                var data = resp || {}
                if (err) throw Error(err)
                if (data.result) {
                    // console.log("getBusinessAccessForUser", data)
                    this.props.dispatch(addDataToStore(SAVE_DATA, { linkedBusinessList: data.businessInfoList || [] }))
                }
                else throw Error(data.error || data.responseMessage)
            } catch (error) {
                console.log("getBusinessAccessForUser error", error)
            }
        })
    }

    tabClicked(options) {
        if (!this.props.location.pathname.includes(options.path)) {
            this.setState({ collapsed: true })
            // this.props.history.push(options.path)
        }
    }

    signout = () => {
        doSignout(this.props.history, this.props.dispatch)
    }

    syncIntegration = () => {
        const { CurrentSync } = this.props.aionStore
        const qbo = CurrentSync ? CurrentSync.platformName === 'QUICKBOOKS' : false
        if (qbo) {
            apiPOSTReq(`${environment.integrationBaseUrl}/integration/qb/syncQuickBooks`, {}, {}, (err, resp) => {
                try {
                    this.setState({ loading: false })
                    const data = resp
                    console.log("/syncQuickBooks", data)
                    if (data.result) {
                        message.success(data.responseMessage)
                        if (data.jobId) {
                            this.props.dispatch(addDataToStore(SAVE_DATA, { syncJobId: data.jobId }))
                            if (!this.timer) {
                                this.timer = setInterval(() => this.poll(), 5000)
                            }
                        }
                    } else {
                        throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                    }
                } catch (error) {
                    console.log("ERRR syncQuickBooks", error.stack, resp)
                    ErrorAlert({ description: error.message })
                }
            })
        }
    }

    poll = () => {
        const { syncJobId } = this.props.aionStore
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/getSyncStatus`, {}, { jobId: syncJobId }, (err, resp) => {
            try {
                const data = resp
                console.log("/getSyncStatus", data)
                if (data.result) {
                    this.setState({ syncStatus: data.syncStatus })
                    if (data.syncStatus && data.syncStatus.status === 'Success' || data.syncStatus.status === 'Failure') {
                        this.openNotification({ duration: 0, status: 'Completed', key: 'completed' })
                        notification.close('inProgress')
                        this.props.dispatch(addDataToStore(SAVE_DATA, { syncStatus: 'Completed' }))
                        this.props.dispatch(addDataToStore(SAVE_DATA, { syncJobId: null }))
                        clearInterval(this.timer)
                        this.timer = null
                    } else {
                        this.props.dispatch(addDataToStore(SAVE_DATA, { syncStatus: 'InProgress' }))
                        this.openNotification({ duration: 0, status: 'inProgress', key: 'inProgress' })
                    }
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                this.props.dispatch(addDataToStore(SAVE_DATA, { syncJobId: null }))
                clearInterval(this.timer)
                this.timer = null
                console.log("ERRR syncQuickBooks", error.stack, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    pollVendorUpload = () => {
        const { vendorUploadJobId } = this.props.aionStore
        console.log('vendorUploadJobId', vendorUploadJobId)
        apiPOSTReq(`${environment.payBaseUrl}/payables/vendors/getUploadStatus`, {}, { uploadJobId: vendorUploadJobId }, (err, resp) => {
            try {
                const data = resp
                console.log("/getUploadStatus", data)
                if (data.result) {
                    if (data.uploadStatus === "Success") {
                        this.props.dispatch(addDataToStore(SAVE_DATA, { vendorUploadJobId: null, downloadVendorUploadJobId: vendorUploadJobId }))
                        this.setState({
                            errorMessage: data.responseMessage,
                            uploadVendorInfo: data.uploadVendorInfo
                        })
                        this.openVendorUploadNotification({ duration: 0, key: 'vendorUploadResult' })

                        clearInterval(this.uploadVendorTimer)
                        this.uploadVendorTimer = null
                    }
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getUploadStatus", error.stack, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    pollContractorUpload = () => {
        const { contractorUploadJobId } = this.props.aionStore
        console.log('contractorUploadJobId', contractorUploadJobId)
        apiPOSTReq(`${environment.payBaseUrl}/payables/contractors/getUploadStatus`, {}, { uploadJobId: contractorUploadJobId }, (err, resp) => {
            try {
                const data = resp
                console.log("/getUploadStatus", data)
                if (data.result) {
                    if (data.uploadStatus === "Success") {
                        this.props.dispatch(addDataToStore(SAVE_DATA, { contractorUploadJobId: null, downloadContractorUploadJobId: contractorUploadJobId }))
                        this.setState({
                            errorMessage: data.responseMessage,
                            uploadContractorInfo: data.uploadVendorInfo
                        })
                        this.openContractorUploadNotification({ duration: 0, key: 'contractorUploadResult' })

                        clearInterval(this.uploadContractorTimer)
                        this.uploadContractorTimer = null
                    }
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getUploadStatus", error.stack, resp)
                ErrorAlert({ description: error.message })
            }
        })

    }

    openNotification = ({ duration, status, key }) => {
        let syncStatus = this.state.syncStatus
        let percentage = syncStatus && syncStatus.progress ? Math.ceil(syncStatus.progress.processedCount / syncStatus.progress.totalCount * 100) : 0
        if (syncStatus && syncStatus.progress && syncStatus.progress.processedCount === 0) {
            percentage = 100
        }
        let entityProgress = syncStatus ? Object.values(syncStatus.entityProgress) : []

        /*notification.open({
            placement: 'bottomLeft',
            key: key,
            bottom: 50,
            duration,
            style: { width: '400px' },
            message: <></>,
            description: <>
                {status === 'inProgress' && <Collapse bordered={false} style={{ background: 'white' }}>
                    <Collapse.Panel key="1"
                        style={{ paddingBottom: 0, borderBottomStyle: 'none' }}
                        header={<div style={{ fontSize: '16px', display: 'flex', alignItems: 'center' }}>
                            <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between', }}>
                                <div>Sync Progress: <span style={{ marginLeft: '5px', fontWeight: 400 }}>In progress</span></div>
                                {percentage !== 0 && <Progress width={60} type="circle" percent={percentage} />}
                            </div>
                        </div>}
                    >
                        {entityProgress.sort((a, b) => {
                            if (entitySortDict[a.entityName] > entitySortDict[b.entityName]) return 1
                            else return -1
                        }).map((x, i) => {
                            let percent = x && x.totalCount && x.processedCount ? Math.ceil(x.processedCount / x.totalCount * 100) : 0
                            if (x && x.totalCount === 0) {
                                percent = 100
                            }
                            return <div style={{ display: 'flex', marginBottom: '5px' }} key={i}>
                                <div style={{ width: '150px', marginRight: '10px' }}>{entityNameDict[x.entityName] || x.entityName}</div>
                                <Progress size="small" percent={percent} />
                            </div>
                        })}
                    </Collapse.Panel>
                </Collapse>}

                {status !== 'inProgress' && <>
                    <div style={{ fontSize: '16px', display: 'flex', alignItems: 'center' }}>
                        <div style={{ marginRight: '10px' }}>Sync status: {status}</div>
                    </div>
                    {status === 'Completed' && <TextButton style={{ padding: 0, fontSize: '12px' }} onClick={() => this.props.history.push("/settings/sync")} text='View Sync Summary' />}
                </>}
            </>,
        })*/
    }

    openVendorUploadNotification = ({ duration, key }) => {
        notification.open({
            placement: 'topRight',
            key: key,
            duration,
            style: { width: '530px' },
            message: <></>,
            description: <>
                <span>Bulk import is complete. Please see results of the import.
                    <TextButton style={{ fontSize: '12px' }} onClick={() => {
                        this.setState({ showVendorUploadResult: true })
                        notification.close('vendorUploadResult')
                    }} text='Show results' />
                </span>
            </>,
        })
    }

    openContractorUploadNotification = ({ duration, key }) => {
        notification.open({
            placement: 'topRight',
            key: key,
            duration,
            style: { width: '530px' },
            message: <></>,
            description: <>
                <span>Bulk import is complete. Please see results of the import.
                    <TextButton style={{ fontSize: '12px' }} onClick={() => {
                        this.setState({ showContractorUploadResult: true })
                        notification.close('contractorUploadResult')
                    }} text='Show results' />
                </span>
            </>,
        })
    }

    handleClose = () => {
        this.setState({ showNotificationModal: false })
        if (this.state.hideSetting) {
            apiPOSTReq(`${environment.integrationBaseUrl}/integration/updateNotificationSetting`, {}, { hideIntNotification: this.state.hideSetting }, (err, resp) => {
                try {
                    const data = resp
                    console.log("/getSyncStatus", data)

                } catch (error) {
                }
            })
        }
    }

    handleGoToSetting = () => {
        this.setState({ showNotificationModal: false })
        if (this.state.hideSetting) {
            apiPOSTReq(`${environment.integrationBaseUrl}/integration/updateNotificationSetting`, {}, { hideIntNotification: this.state.hideSetting }, (err, resp) => {
                try {
                    const data = resp
                    console.log("/getSyncStatus", data)

                } catch (error) {
                }
            })
        }
        this.props.history.push('/settings/integration/qb-setup')
    }

    handleCloseBankModal = () => {
        this.setState({ showInitExternalBankModal: false })
        this.props.history.push('/settings/bank-connect')
    }

    signout = () => {
        doSignout(this.props.history, this.props.dispatch)
    }

    setTopNavContent = (content) => {
        this.setState({ topNavContent: content })
    }

    render() {
        let { location, theme, bankingStore, userDefaults, aionStore } = this.props
        const { userInfo } = aionStore
        const { pinned, notification, notificationText, approvalNotificationCount, reconnectExternalBank, topNavContent } = this.state
        var Attributes = aionStore.Attributes || {}
        const Applications = bankingStore.Applications || []
        const bbAttributes = Attributes.BankingAttributes || {}
        const showAppStatus = bbAttributes.ApplicationSubmitted || bbAttributes.ManualReviewRequired
        // console.log("/home showAppStatus", showAppStatus, bbAttributes)
        const businessAttributes = Attributes.Businesses || {}
        const paymentsPerm = getResourcePerm("BusinessBanking.Payments")
        const recipientsPerm = getResourcePerm("BusinessBanking.Recipients")
        const advPerm = getResourcePerm("ARCredit.Advances")
        const invAdvPerm = getResourcePerm("ARCredit.Inventory Advances")
        const params = queryString.parse(this.props.location.search)
        const allowedApps = getUserApps(aionStore)
        const userRoles = getUserRoles(aionStore)
        const { UAM, businessSubscriptionPlan, OnboardingType } = aionStore
        const { currentPlan } = businessSubscriptionPlan || {}
        var showCredit = false
        const { AccountLocked, AccountClosed } = bankingStore
        var tabsToDisplay = ["settings"]
        let { pathname } = location
        let tabName = pathname.substring(0, pathname.indexOf("/", 1))
        if (bbAttributes.ApplicationApproved) {
            if (allowedApps.includes("BusinessBanking")) tabsToDisplay.push("banking")
        }
        if (allowedApps.includes("BusinessCards")) tabsToDisplay.push("cards")
        if (allowedApps.includes("Receivables")) tabsToDisplay.push("receivables")
        if (allowedApps.includes("ARCredit")) tabsToDisplay.push("ar_credit")
        if (allowedApps.includes("ABLCredit")) tabsToDisplay.push("abl_credit")
        if (allowedApps.includes("Payables")) tabsToDisplay.push("payables")
        // if (allowedApps.includes("Bookkeeping")) tabsToDisplay.push("bookkeeping")
        if (allowedApps.includes("TermLoans")) tabsToDisplay.push("term_loans")
        if (paymentsPerm.approve) tabsToDisplay.push("approvals")
        showCredit = ["Essentials", "Enterprise", "Growth"].includes(currentPlan) // Show credit onboarding for only these plans
        if (!bbAttributes.ApplicationApproved || bbAttributes.ForceCRBAccountOpening) tabsToDisplay.splice(0, 0, "apply")
        const creditOnboardingApproved = allowedApps.includes("ARCredit") || allowedApps.includes("ABLCredit") || allowedApps.includes("TermLoans")
        const onboardingApproved = bbAttributes.ApplicationApproved
        const onboardingMode = tabsToDisplay.includes("apply") && !onboardingApproved && !UAM.aionCustomerSupportUser

        if (UAM.aionCustomerSupportUser) tabsToDisplay.push("support")
        if (UAM.aionUnderWriterUser) tabsToDisplay.push("underwriting")
        let callbackComponent
        let openCreditPlus = this.props.onbStore.isCreditPlus == true ? true : false;
        switch (params.state) {
            case "qb-dashboard":
                callbackComponent = Receivables
                break
            case "qb-credit-eligibility":
                callbackComponent = CreditOnboarding
                break
            case "qb-credit-term-loans":
                callbackComponent = TermLoans
                break
            case "qb-integration":
                callbackComponent = QBIntegration
                break
            case "qb-invoices":
                callbackComponent = Invoices
                break
            case "qb-credit-plus":
                openCreditPlus = true;
                break;
            case "qb-advances":
                callbackComponent = RequestAdvance
        }

        const showWidgetDashboard = getFeaturePerm("AionApplications.System.WidgetsDashboard").view
        const isCompanyAdmin = userRoles.includes("Company Admin") || userRoles.includes("Owner") || userRoles.includes("Primary Admin")

        const { CurrentSync, BusinessUniqueKey } = this.props.aionStore

        let vendorErrors = []
        if (this.state.uploadVendorInfo && this.state.uploadVendorInfo.headerErrors && this.state.uploadVendorInfo.headerErrors.length > 0) {
            vendorErrors = this.state.uploadVendorInfo.headerErrors.map((x, i) => {
                return { ...x, key: i }
            })
        } else if (this.state.uploadVendorInfo && this.state.uploadVendorInfo.valueErrors && this.state.uploadVendorInfo.valueErrors.length > 0) {
            vendorErrors = this.state.uploadVendorInfo.valueErrors.map((x, i) => {
                return { ...x, key: i }
            })
        }

        let vendorErrorNum = 0
        if (this.state.uploadVendorInfo && this.state.uploadVendorInfo.recordsErrorCount) {
            vendorErrorNum = this.state.uploadVendorInfo.recordsErrorCount
        }

        let vendorSuccessNum = 0
        if (this.state.uploadVendorInfo && this.state.uploadVendorInfo.recordsTotalCount) {
            vendorSuccessNum = this.state.uploadVendorInfo.recordsTotalCount
                - this.state.uploadVendorInfo.recordsErrorCount
        }

        let contractorErrors = []
        if (this.state.uploadContractorInfo && this.state.uploadContractorInfo.headerErrors && this.state.uploadContractorInfo.headerErrors.length > 0) {
            contractorErrors = this.state.uploadContractorInfo.headerErrors.map((x, i) => {
                return { ...x, key: i }
            })
        } else if (this.state.uploadContractorInfo && this.state.uploadContractorInfo.valueErrors && this.state.uploadContractorInfo.valueErrors.length > 0) {
            contractorErrors = this.state.uploadContractorInfo.valueErrors.map((x, i) => {
                return { ...x, key: i }
            })
        }

        let contractorErrorNum = 0
        if (this.state.uploadContractorInfo && this.state.uploadContractorInfo.recordsErrorCount) {
            contractorErrorNum = this.state.uploadContractorInfo.recordsErrorCount
        }
        let contractorSuccessNum = 0
        if (this.state.uploadContractorInfo && this.state.uploadContractorInfo.recordsTotalCount && this.state.uploadContractorInfo.recordsErrorCount) {
            contractorSuccessNum = this.state.uploadContractorInfo.recordsTotalCount - this.state.uploadContractorInfo.recordsErrorCount
        }

        const fullScreenMode = ['/receivables/invoices/create', '/receivables/invoices/revise', '/payables/inbox/detail', '/credit/payers/send-payment-notification', '/payables/bills/view', '/payables/bills/new-bill'].includes(this.props.location.pathname)
        const pinnedML = 300
        const unpinnedML = 88
        return (
            <>
                {bbAttributes.ApplicationApproved &&
                    <IdleTimer
                        timeout={1000 * 15 * 60}
                        onIdle={this.signout}
                        debounce={250}
                    />
                }
                <Layout style={{ minWidth: '100vw', height: '100%', background: "transparent" }}>
                    {
                        !this.state.isMobile &&
                        !onboardingMode &&
                        !fullScreenMode &&
                        <NavMenu {...this.props} showCreditPlus={this.state.showCreditPlus} creditPlusTitle={this.state.creditPlusTitle} openCreditPlus={openCreditPlus} creditPlusSubTitle={this.state.creditPlusSubTitle} onPinnedChange={(pinned) => this.setState({ pinned })} approvalNotificationCount={approvalNotificationCount} />
                    }
                    <Layout style={{ minHeight: '100vh', background: "transparent", position: 'sticky' }}>
                        {/*
                            !fullScreenMode &&
                            <Header style={{ width: '100%', height: onboardingMode ? 70 : 56, background: onboardingMode ? "white" : "transparent" }}>
                                {this.state.unsupported ? this.state.notificationClosed ? null : <Content style={{ marginLeft: fullScreenMode ? 75 : onboardingMode ? 0 : pinned ? 310 : 75, height: '100%' }}>
                                    <Flex start style={{ width: '100%', height: '100%', paddingTop: 12, paddingRight: 12 }}>
                                        {this.state.unsupported && <Alert style={{ position: 'sticky', zIndex: 2, top: 0, width: "inherit" }} message="For an improved experience of the Aion app, please use a different browser such as Google Chrome or Safari." banner closable={true} onClose={() => { this.setState({ notificationClosed: true }) }} />}
                                    </Flex>
                                </Content>
                                    :
                                    null}

                                <Flex end style={{ width: '100%', height: '100%', paddingTop: this.state.unsupported ? this.state.notificationClosed ? 12 : 0 : 12, paddingRight: 12 }}>
                                    <Flex centerHorizontally gap='20px' style={{ padding: 12 }}>
                                        <Dropdown
                                            placement='bottomRight'
                                            overlay={<Menu style={{ padding: 24, borderRadius: 8 }}>
                                                <Flex center style={{ width: (notificationText) ? 320 : 220 }}>
                                                    <Text>{(notificationText) ? notificationText : 'No Notifications'}</Text>
                                                </Flex>
                                            </Menu>}
                                            onVisibleChange={flag => this.setState({ notification: false })}
                                        >
                                            <Flex style={{ zIndex: 99, cursor: 'pointer' }}>
                                                <ImageButton src={AlertIcon} />
                                                {
                                                    notification &&
                                                    <Badge style={{ marginLeft: -10, marginTop: -4 }} count={1} size="small" />
                                                }
                                            </Flex>
                                        </Dropdown>
                                        <Dropdown
                                            placement='bottomRight'
                                            align={{ overflow: { adjustX: true, adjustY: true } }}
                                            overlay={<Menu style={{ padding: 24, borderRadius: 8 }}>
                                                <FlexColumn start gap='12px'>
                                                    <TextButton onClick={() => this.props.history.push("/settings/user-profile")} rightIcon={<Image src={ArrowRight} />} text={"View Profile".toUpperCase()} />
                                                    <TextButton onClick={this.signout} rightIcon={<Image src={Logout} />} text={"Logout".toUpperCase()} />
                                                </FlexColumn>
                                            </Menu>}
                                        >
                                            <Flex style={{ zIndex: 99, cursor: 'pointer' }}>
                                                <ProfileImage
                                                    size={24}
                                                    hideupload="true"
                                                />
                                            </Flex>
                                        </Dropdown>
                                    </Flex>
                                </Flex>
                            </Header>
                        */}
                        {
                            !fullScreenMode &&
                            <div style={{ marginLeft: fullScreenMode ? 12 : onboardingMode ? 0 : pinned ? pinnedML + 35 : unpinnedML + 35, marginTop: '24px', background: 'transparent', padding: '0', marginRight: 24, marginBottom: onboardingMode ? 0 : 20 }}>
                                <TopNav 
                                    content={topNavContent} 
                                    onboardingMode={onboardingMode} 
                                    history={this.props.history} 
                                    signout={() => this.signout()} 
                                    notification={this.state.notification} 
                                    notificationText={notificationText} 
                                    location={location}
                                    showDashIcon={isCompanyAdmin && showWidgetDashboard}
                                />
                            </div>
                        }

                        <Layout style={{ background: "transparent", height: '100%', marginTop: fullScreenMode ? 24 : this.state.unsupported ? this.state.notificationClosed ? "0" : "4%" : "0" }}>
                            <Content style={{ marginLeft: fullScreenMode ? 12 : onboardingMode ? 0 : pinned ? pinnedML : unpinnedML, height: '100%' }}>
                                {/* <div style={{ minHeight: '100vh', margin: '0 80px 0 168px' }}> */}
                                <Switch location={location}>
                                    <Route exact path="/apply" render={(props) => <BankingOnboarding />} />
                                    <Route exact path="/apply/credit" component={CreditOnboarding} />
                                    <Route exact path="/apply/credit-loading" component={PreScreenLoading} />
                                    <Route exact path="/apply/credit/due-diligence" render={(props) => <CreditEligibility creditEligibilityApplied={false} />} />
                                    <Route exact path="/apply/credit/due-diligence/refresh" render={(props) => <CreditEligibilityRefresh creditEligibilityApplied={false} />} />
                                    <Route
                                        path="/banking"
                                        render={(props) => (
                                            <Banking {...props} updateSideMenu={() => this.setState({ updateSideMenu: true })} onApprovalAction={(newNotificationCount) => {
                                                console.log('onApprovalAction newNotificationCount', newNotificationCount)
                                                this.fetchAllInboxItems()
                                                this.setState({ approvalNotificationCount: newNotificationCount > 0 ? newNotificationCount : undefined })
                                            }} />
                                        )}
                                    />
                                    <Route
                                        path="/cards"
                                        render={(props) => (
                                            <Banking {...props} updateSideMenu={() => this.setState({ updateSideMenu: true })} onApprovalAction={(newNotificationCount) => this.setState({ approvalNotificationCount: newNotificationCount > 0 ? newNotificationCount : undefined })} />
                                        )}
                                    />
                                    <Route path="/credit" component={Credit} />
                                    <Route path="/settings" component={Settings} />
                                    <Route path="/receivables" render={(props) => (<Receivables {...props} setTopNavContent={this.setTopNavContent} />)} />
                                    <Route path="/callback" component={callbackComponent} />
                                    <Route
                                        path="/payables"
                                        render={(props) => (
                                            <Pay {...props} updateSideMenu={() => this.setState({ updateSideMenu: true })} onApprovalAction={(newNotificationCount) => {
                                                this.fetchAllInboxItems()
                                                this.setState({ approvalNotificationCount: newNotificationCount > 0 ? newNotificationCount : undefined })
                                            }} />
                                        )}
                                    />
                                    <Route path="/contractor-payroll" component={Pay} />
                                    {/* <Route path="/bookkeeping" component={Bookkeeping} /> */}
                                    {/* <Route path="/approvals" component={Approvals} /> */}
                                    <Route
                                        path="/approvals"
                                        render={(props) => (
                                            <Approvals {...props} onApprovalAction={(newNotificationCount) => {
                                                this.fetchAllInboxItems()
                                                this.setState({ approvalNotificationCount: approvalNotificationCount - 1 })
                                            }} />
                                        )}
                                    />
                                    <Route path="/support" component={Support} />
                                    <Route path="/underwriting" component={UnderwritingConsole} />
                                </Switch>
                                {/* </div> */}
                            </Content>
                            {/* {location.pathname.includes("/banking") ? <AppFooter /> : null} */}
                        </Layout>
                    </Layout>
                </Layout>
                <Modal
                    visible={this.state.showNotificationModal}
                    title={<div style={{ fontSize: '19px' }}>Sync setup</div>}
                    style={{ top: '25%', right: 0, bottom: 0, left: 0 }}
                    closable={false}
                    destroyOnClose={true}
                    footer={
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Checkbox onChange={(e) => this.setState({ hideSetting: e.target.checked })}>Don't show me again</Checkbox>
                            <Flex>
                                <Button permtype="Override" height="45px" style={{ marginRight: '15px' }} onClick={this.handleClose} text='Close' />
                                <Button permtype="Override" height="45px" style={{ margin: 0 }} solid onClick={this.handleGoToSetting} text='Setup now' />
                            </Flex>
                        </div>}
                >
                    <Paragraph>Syncing with your accounting software will help you reduce double entries for transactions you perform on Aion.<br /><br /> Click on the Setup Now button to get started!</Paragraph>

                </Modal>
                <Modal
                    visible={this.state.showExternalBankModal}
                    title={<div style={{ fontSize: '19px', display: 'flex', alignItems: 'center' }}>
                        <WarningOutlined style={{ marginRight: '15px', fontSize: '28px' }} />
                        <div>You still have external account(s) that are disconnected</div>
                    </div>}
                    style={{ top: '25%', right: 0, bottom: 0, left: 0 }}
                    closable={false}
                    destroyOnClose={true}
                    footer={
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button permtype="Override" style={{ margin: 5 }} onClick={() => this.setState({ showExternalBankModal: false })} text='Close' />
                        </div>}
                >
                    <Paragraph>One or many of your external bank account connections still remain disconnected. We require that all your external accounts connections are active to continue using Aion.</Paragraph>
                    <Paragraph>Please close this dialogue box to reconnect your accounts.</Paragraph>
                </Modal>
                <Modal
                    visible={this.state.showInitExternalBankModal}
                    title={<div style={{ fontSize: '19px' }}><ApiOutlined style={{ marginRight: '5px' }} /> Reconnect your external bank account(s)</div>}
                    style={{ top: '25%', right: 0, bottom: 0, left: 0 }}
                    closable={false}
                    destroyOnClose={true}
                    footer={
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button permtype="Override" style={{ margin: 5 }} solid onClick={this.handleCloseBankModal} text='Reconnect account' />
                        </div>}
                >
                    <Paragraph>One or many of your external bank account connections have been disconnected. We require that all your external accounts connections are active to continue using Aion.</Paragraph>
                    <Paragraph>Please click on the link below to reconnect your accounts.</Paragraph>
                </Modal>
                <Modal
                    visible={this.state.showLockedAccountModal}
                    footer={null}
                    closable={false}
                    width="100vw"
                    style={{ top: 0, right: 0, bottom: 0, left: 0 }}
                    destroyOnClose={true}
                >
                    <LockedAccountModalContent
                        lockType={AccountClosed ? "Closed" : "Frozen"}
                        theme={theme}
                        onConfirm={() => {
                            this.setState({ showLockedAccountModal: false })
                            // Signout banking only users
                            // if ((tabsToDisplay.includes("receivables") || tabsToDisplay.includes("credit")) && !AccountClosed) {
                            //     this.props.history.push("/settings/profile")
                            // } else 
                            if (UAM.aionCustomerSupportUser) {
                                this.props.history.push("/support/search")
                            } else {
                                this.signout()
                            }
                        }}
                    />
                </Modal>

                <Modal
                    visible={this.state.showVendorUploadResult}
                    footer={null}
                    closable={true}
                    maskClosable={true}
                    width={700}
                    style={{ top: 20 }}
                    destroyOnClose={true}
                    onCancel={() => this.setState({ showVendorUploadResult: false })}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <Result
                        uploadTypeTitle="Vendor Import Overview"
                        showHeaderErrorTable={false}
                        errors={vendorErrors}
                        errorNum={vendorErrorNum}
                        successNum={vendorSuccessNum}
                        errorMessage={"The bulk import is complete. Here are the results of your import:"}
                        successMsg="Successful records imported"
                        afterInitialUpload={true}
                        errorMsg="Duplicate records found"
                    />
                </Modal>
                <Modal
                    visible={this.state.showContractorUploadResult}
                    footer={null}
                    closable={true}
                    maskClosable={true}
                    width={700}
                    style={{ top: 20 }}
                    destroyOnClose={true}
                    onCancel={() => this.setState({ showContractorUploadResult: false })}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <Result
                        uploadTypeTitle="Contractor Import Overview"
                        showHeaderErrorTable={false}
                        errors={contractorErrors}
                        errorNum={contractorErrorNum}
                        successNum={contractorSuccessNum}
                        errorMessage={"The bulk import is complete. Here are the results of your import:"}
                        successMsg="Successful records imported"
                        afterInitialUpload={true}
                        errorMsg="Duplicate records found"
                    />
                </Modal>
                {
                    ((allowedApps.includes("ARCredit") || allowedApps.includes("ABLCredit") || allowedApps.includes("TermLoans")) && ((!UAM.aionCustomerSupportUser) || (!UAM.aionUnderWriterUser)) && this.props.aionStore.ReconnectBankModal != true) &&
                    <Modal
                        visible={reconnectExternalBank}
                        footer={null}
                        closable={true}
                        maskClosable={true}
                        width={500}
                        destroyOnClose={true}
                        onCancel={() => {
                            this.props.dispatch(addDataToStore(SAVE_DATA, { ReconnectBankModal: true }))
                            this.setState({ reconnectExternalBank: false })
                        }
                        }

                        closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                    >
                        <FlexColumn center gap='24px' style={{ marginBottom: '5%' }}>
                            {<img height='48px' width='48px' src={Missing} />}
                            <Text bold size='20px' height='40px' style={{ textAlign: 'center', maxWidth: '400px' }}>Reconnect Your Linked Bank Accounts</Text>
                            <div style={{ width: '80px', height: '1px', background: 'radial-gradient(83.75% 38.54% at 16.25% 86.88%, #1199FF 0%, #1A8AFA 100%)' }} />
                            <Text style={{ textAlign: "center" }} background="#F5F6FE" margin="0px" borderColor="2px solid var(--solid-primary-blue-20, #D7DCFB);">One or more of your linked bank account connections have been disconnected. We require that all your external account connections are active to continue using Aion Credit.</Text>
                            <Text background="#F5F6FE" margin="0px" borderColor="2px solid var(--solid-primary-blue-20, #D7DCFB);">Please click below to reconnect to your accounts.</Text>

                            <Button solid style={{ alignItems: "left" }} type="primary" text='RECONNECT'
                                onClick={
                                    () => {
                                        this.setState({ reconnectExternalBank: false })
                                        this.props.dispatch(addDataToStore(SAVE_DATA, { ReconnectBankModal: true }))
                                        this.props.history.push(`/settings/profile`)
                                    }
                                } />

                        </FlexColumn>
                    </Modal>
                }
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        bankingStore: state.bankingAppReducer,
        aionStore: state.aionAppReducer,
        userDefaults: state.userDefaultsReducer,
        bkStore: state.bookeepingAppReducer,
        creditStore: state.creditAppReducer,
        onbStore: state.onboardingAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)))