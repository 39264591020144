import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components';
import update from 'immutability-helper';

import { message, Select, Checkbox } from 'antd';

import environment from '../../../../environment'
import { apiPOSTReq } from '../../../../Utils/api'

import {
    isEmailValid,
    addressObjectToStr,
    formatPhoneText,
    getUserApps,
    defaultCustomerPaymentTerms,
    capitalizeWords,
    getNameObj,
    getValidFullName,
    isCodatUser,
    getCodatPlatformName,
} from '../../../../Utils/util'

import { FlexColumn, Flex } from '../../../Reusable/Container';
import { Text } from '../../../Reusable/Text';
import { LabeledInput } from '../../../Reusable/Input';
import { Button } from '../../../Reusable/Refresh/Button';
import { ErrorAlert } from '../../../Reusable/Alert';
import { addDataToStore, RECEIVABLES_SAVE_DATA } from '../../../../Actions/actions';
import { cloneDeep } from 'lodash';

const { Option } = Select;

class Index extends Component {

    constructor(props) {
        super(props);
        this.customer = this.props.location.state && this.props.location.state.customer
        let customer = this.customer || {};
        let isNew = this.customer === null ? true : false;
        let actionStr = this.customer ? 'Edit' : 'Create';
        customer.primaryEmailAddress = customer.primaryEmailAddress || { address: null };
        customer.invoiceRecipientEmail = customer.invoiceRecipientEmail || [];
        this.state = {
            customize: true,
            customer: customer,
            msas: null,
            workOrders: null,
            paymentTermsItems: defaultCustomerPaymentTerms,
            actionStr,
            isNew,
            loading: false,
            makeShipAddrAsBillAddr: true,
        }
        this.iveHeaders = {
            AionCurrentBiz: this.props.aionStore.BusinessUniqueKey,
            AionAuth: this.props.aionStore.UAM.encryptedAuthHeader
        }

        this.nameRef = React.createRef()
        this.billingAddressRef = React.createRef()
        this.shippingAddressRef = React.createRef()
        this.contactNameRef = React.createRef()
        this.emailRef = React.createRef()
        this.phoneRef = React.createRef()
        this.parentRef = React.createRef()
    }

    componentDidMount() {
        console.log("EditCustomer customer", this.state.customer)
        if (!this.state.isNew) {
            this.fetchTerms();
        }
    }

    fetchTerms() {
        apiPOSTReq(`${environment.iveBaseUrl}/ive/term/getTerms`, {}, {}, (err, resp) => {
            try {
                const data = resp || {};
                console.log("/ive/term/getTerms", data)
                if (data.result) {
                    // const terms = data.termList && data.termList.length > 0 ? data.termList : defaultCustomerPaymentTerms;

                    const combinedTerms = [...data.termList];

                    defaultCustomerPaymentTerms.forEach(newTerm => {
                        const exists = combinedTerms.some(termDetail => termDetail.name === newTerm.name);
                        if (!exists) {
                            combinedTerms.push(newTerm);
                        }
                    });

                    this.setState({ paymentTermsItems: combinedTerms }, () => this.handlePaymentTermsSelect(this.state.customer.paymentTerms))
                } else {
                    this.setState({ paymentTermsItems: defaultCustomerPaymentTerms })
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                this.setState({ paymentTermsItems: defaultCustomerPaymentTerms })
                console.log("ERRR getTerms", error, resp);
                ErrorAlert({ description: error.message })
            }
        })
    }

    handleEmailChange = (event) => {
        this.setState({
            customer: update(this.state.customer, { invoiceRecipientEmail: { [event.target.getAttribute('index')]: { $set: event.target.value } } })
        })
    }

    handlePhoneChange = (event) => {
        this.setState({
            customer: update(this.state.customer, { phone: { $set: formatPhoneText(event.target.value) } })
        })
    }

    handleAddEmail = () => {
        this.setState({
            customer: update(this.state.customer, { invoiceRecipientEmail: { $push: [null] } })
        });
    }

    handleDeleteEmail = (event) => {
        this.setState({
            customer: update(this.state.customer, { invoiceRecipientEmail: { $splice: [[[event.currentTarget.getAttribute('index')], 1]] } })
        });
    }

    handleShipAddrSwitch = (checked) => {
        this.setState({ makeShipAddrAsBillAddr: checked });
        var stateToUpdate = {
            makeShipAddrAsBillAddr: checked
        };
        if (checked) {
            stateToUpdate.customer = update(this.state.customer, { "shippingAddress": { $set: this.state.customer.billingAddress } });
        } else {
            stateToUpdate.customer = update(this.state.customer, {
                "shippingAddress": {
                    $set: {
                        line1: null,
                        line2: null,
                        city: null,
                        countrySubDivisionCode: null,
                        postalCode: null
                    }
                }
            });
        }
        this.setState(stateToUpdate);
    }

    handlePaymentTermsSelect = (value, option) => {
        var { customer } = this.state;

        const term = this.state.paymentTermsItems.find(x => x.name === value);
        if (term) {
            customer.paymentTerms = term.name;
            customer.termId = term.termId;
        } else {
            customer.paymentTerms = value;
            customer.termId = value;
        }

        this.setState({ customer });
    }

    handleTextChange = (event) => {
        var val;
        switch (event.target.id) {
            case "name":
                var name = capitalizeWords(event.target.value);
                var nameObj = getNameObj(getValidFullName(name));
                val = { firstName: { $set: nameObj.FirstName }, lastName: { $set: nameObj.LastName } }
                break;
            case "customerName":
                var captitalizedName = { $set: (event.target.value) };
                val = { companyName: captitalizedName, displayName: captitalizedName, printOnCheckName: captitalizedName };
                break;
            case "primaryEmailAddress":
                val = { [event.target.id]: { address: { $set: event.target.value } } };
                break;
            case "phone":
                val = { phone: { $set: formatPhoneText(event.target.value) } };
                break;
            case "webSite":
                val = { website: { $set: (event.target.value) } };
                break;
            default:
                val = { [event.target.id]: { $set: event.target.value } };
                break;
        }
        console.log("handleTextChange update", this.state.customer, val);
        this.setState({
            customer: update(this.state.customer, val)
        });
    }

    handleParentCustomerSelect = (value, option) => {
        this.setState({
            customer: update(this.state.customer, { parentCustomerId: { $set: value }, parentCustomerName: { $set: value ? option.name : null } }),
        });
    }

    getLocation = (location, addressField) => {
        if (!location) {
            if (addressField === 'billingAddress2') {
                this.setState({
                    customer: update(this.state.customer, {
                        billingAddress: {
                            $set: {
                                line1: null,
                                line2: null,
                                city: null,
                                countrySubDivisionCode: null,
                                postalCode: null
                            }
                        }
                    })
                });
            } else {
                this.setState({
                    customer: update(this.state.customer, {
                        shippingAddress: {
                            $set: {
                                line1: null,
                                line2: null,
                                city: null,
                                countrySubDivisionCode: null,
                                postalCode: null
                            }
                        }
                    })
                });
            }
        }
        else {
            if (addressField === 'billingAddress2') {
                this.setState({
                    customer: update(this.state.customer, {
                        billingAddress: {
                            $set: {
                                line1: location.line1,
                                line2: location.line2,
                                city: location.city,
                                countrySubDivisionCode: location.countrySubDivisionCode,
                                postalCode: location.postalCode
                            }
                        }
                    })
                });
            } else {
                this.setState({
                    customer: update(this.state.customer, {
                        shippingAddress: {
                            $set: {
                                line1: location.line1,
                                line2: location.line2,
                                city: location.city,
                                countrySubDivisionCode: location.countrySubDivisionCode,
                                postalCode: location.postalCode
                            }
                        }
                    })
                });
            }
        }
    }

    handleSave = () => {
        const { customer, makeShipAddrAsBillAddr } = this.state;
        const { billingAddress, shippingAddress, fullyQualifiedName, paymentTerms, billWithParent, parentCustomerId } = customer
        const { onSave } = this.props
        if (customer.website === 'https://') {
            customer.website = "";
        }

        const displayName = customer.displayName;
        if (!displayName || displayName == '') {
            this.setState({ errorField: 'customerName', errorMessage: "This is a mandatory field" })
            this.nameRef.current.focus()
            return;
        };

        // if (!billingAddress) {
        // this.setState({ errorField: 'billingAddress', errorMessage: "Billing Address is a required field" })
        // this.billingAddressRef.current.scrollIntoView({ behavior: 'smooth' });
        // return;
        // } else {
        //     const { line1, city, countrySubDivisionCode, state, postalCode } = billingAddress
        //     if (!line1) {
        //         this.setState({ errorField: 'billingAddress', errorMessage: "Billing address street is a required field" })
        //         this.billingAddressRef.current.scrollIntoView({ behavior: 'smooth' });
        //         return;
        //     }
        //     if (!city) {
        //         this.setState({ errorField: 'billingAddress', errorMessage: "Billing address city is a required field" })
        //         this.billingAddressRef.current.scrollIntoView({ behavior: 'smooth' });
        //         return;
        //     }
        //     if (!countrySubDivisionCode && !state) {
        //         this.setState({ errorField: 'billingAddress', errorMessage: "Billing address state is a required field" })
        //         this.billingAddressRef.current.scrollIntoView({ behavior: 'smooth' });
        //         return;
        //     }
        //     if (!postalCode) {
        //         this.setState({ errorField: 'billingAddress', errorMessage: "Billing address postal code is a required field" })
        //         this.billingAddressRef.current.scrollIntoView({ behavior: 'smooth' });
        //         return;
        //     }
        // }
        // if (!shippingAddress) {
        // this.setState({ errorField: 'shippingAddress', errorMessage: "Shipping Address is a required field" })
        // this.shippingAddressRef.current.scrollIntoView({ behavior: 'smooth' });
        // return;
        // } else {
        // if (!makeShipAddrAsBillAddr) {
        //     const { line1, city, countrySubDivisionCode, state, postalCode } = shippingAddress
        //     if (!line1) {
        //         this.setState({ errorField: 'shippingAddress', errorMessage: "Shipping address street is a required field" })
        //         this.shippingAddressRef.current.scrollIntoView({ behavior: 'smooth' });
        //         return;
        //     }
        //     if (!city) {
        //         this.setState({ errorField: 'shippingAddress', errorMessage: "Shipping address city is a required field" })
        //         this.shippingAddressRef.current.scrollIntoView({ behavior: 'smooth' });
        //         return;
        //     }
        //     if (!countrySubDivisionCode && !state) {
        //         this.setState({ errorField: 'shippingAddress', errorMessage: "Shipping address state is a required field" })
        //         this.shippingAddressRef.current.scrollIntoView({ behavior: 'smooth' });
        //         return;
        //     }
        //     if (!postalCode) {
        //         this.setState({ errorField: 'shippingAddress', errorMessage: "Shipping address postal code is a required field" })
        //         this.shippingAddressRef.current.scrollIntoView({ behavior: 'smooth' });
        //         return;
        //     }
        // }
        // }

        // if (!fullyQualifiedName || fullyQualifiedName === '') {
        //     this.setState({ errorField: 'fullyQualifiedName', errorMessage: "This is a mandatory field" })
        //     this.contactNameRef.current.focus()
        //     return;
        // };

        const phone = customer.phone || "";
        const primaryEmailAddress = customer.primaryEmailAddress || {};
        const otherEmails = customer.invoiceRecipientEmail || [];

        var otherEmailsValidated = true;
        var invalidEmail = "";
        otherEmails.map(item => {
            if (!isEmailValid(item || "")) {
                otherEmailsValidated = false
                invalidEmail = item;
            };
        })
        // if (!isEmailValid(primaryEmailAddress.address)) {
        //     this.setState({ errorField: 'primaryEmailAddress', errorMessage: "Please enter a valid Contact email" })
        //     this.emailRef.current.focus()
        //     return;
        // };
        // if ((phone || '') !== '' && phone.replace(/\D/g, '').length !== 10) {
        //     this.setState({ errorField: 'phone', errorMessage: "Please enter a valid 10 digit US phone number" })
        //     this.phoneRef.current.focus()
        //     return;
        // };

        if (billWithParent && !parentCustomerId) {
            this.setState({ errorField: 'parentCustomer', errorMessage: "Please select a customer" })
            this.parentRef.current.scrollIntoView({ behavior: 'smooth' });
            return;
        }

        if (makeShipAddrAsBillAddr) customer.shippingAddress = customer.billingAddress

        const body = {
            Customer: customer
        }

        console.log("CreateCustomer nextStep customer:", body)

        this.setState({ loading: true })

        apiPOSTReq(`${environment.iveBaseUrl}/ive/bc/save`, this.iveHeaders, body, (err, resp) => {
            try {
                const data = resp;
                if (data.result) {
                    message.success(`Customer saved!`);
                    console.log("CreateCustomer nextStep customer:", data.customer)
                    this.setState({ isNew: false, actionStr: 'Edit', customer: data.customer })
                    this.props.dispatch(addDataToStore(RECEIVABLES_SAVE_DATA, { editedCustomer: cloneDeep(data.customer) }));
                    // if (this.props.location.state?.details === true) this.props.history.replace({
                    //     pathname: '/receivables/customers/details',
                    //     state: { customer: data.customer }
                    // })
                    // else window.history.back()
                    onSave(data.customer)
                } else {
                    message.error("Could not Save. " + data.responseMessage);
                }
            } catch (error) {
                console.log("ERRR", error.stack, err, resp)
                message.error("Could not Save.");
            } finally {
                this.setState({ loading: false })
            }
        })
    }

    render() {
        const { theme, customers, aionStore } = this.props;
        const { customer, loading, isNew, invoiceRecipientEmail, errorField, errorMessage, showParentDropdown, makeShipAddrAsBillAddr } = this.state;

        const userApps = getUserApps(this.props.aionStore);
        const { CurrentSync } = this.props.aionStore;

        let qbo = false;
        if (CurrentSync && CurrentSync.platformName === 'QUICKBOOKS' && CurrentSync.syncSetupCompleted && userApps && userApps.includes("Receivables")) {
            qbo = true;
        }

        var { companyName, displayName, webSite, firstName, lastName, notes, fullyQualifiedName, billingAddress, shippingAddress, phone, paymentTerms, parentCustomerName, billWithParent, termId } = this.state.customer;
        shippingAddress = shippingAddress || {};
        displayName = displayName || companyName;

        if (webSite) {
            if (webSite.substring(0, 8) !== 'https://' && webSite.substring(0, 7) !== 'http://') {
                webSite = "https://" + webSite;
            }
        } else {
            webSite = "https://";
        }

        return (
            <FlexColumn start fullHeight style={{ marginBottom: 0 }}>
                <Text heading margin='0 24px 24px'>New customer</Text>

                <FlexColumn start gap="24px" style={{ overflowY: 'auto', overflowX: 'hidden', padding: showParentDropdown ? '0 24px 24px' : '0 24px' }}>
                    <LabeledInput
                        inputRef={this.nameRef}
                        nomargin
                        style={{ width: "100%" }}
                        autoFocus
                        label='Name'
                        id="customerName"
                        key="customerName"
                        // width="592px"
                        placeholder="Acme Inc."
                        value={this.state.customer.displayName}
                        onChange={this.handleTextChange}
                        error={errorField === "customerName"}
                        errorMessage={errorMessage}
                    />

                    <LabeledInput
                        nomargin
                        label="Payment Terms"
                        id="paymentTerms"
                        key="paymentTerms"
                        // width="284px"
                        type="select"
                        className="no-left-padding"
                        placeholder="Select"
                        value={paymentTerms}
                        onChange={this.handlePaymentTermsSelect}
                        allowClear
                        optional
                        // style={labelStyle}
                        dropdownRender={menu => (
                            <>
                                {menu}
                            </>
                        )}
                        unsyncable={isCodatUser(aionStore)}
                        accountingSoftware={getCodatPlatformName(aionStore)}
                    >
                        {this.state.paymentTermsItems.map(item => <Option key={item.termId} value={item.name}>{item.name}</Option>)}
                    </LabeledInput>

                    <LabeledInput
                        nomargin
                        label="Website"
                        id="webSite"
                        key="webSite"
                        // width="284px"
                        placeholder="acme.com"
                        defaultValue={webSite}
                        onChange={this.handleTextChange}
                        unsyncable={isCodatUser(aionStore)}
                        accountingSoftware={getCodatPlatformName(aionStore)}
                        optional
                    />

                    <div ref={this.billingAddressRef}>
                        <LabeledInput
                            nomargin
                            id="billingAddress2"
                            key="billingAddress2"
                            label="Billing Address"
                            type="location"
                            // width="592px"
                            getLocation={(loc) => this.getLocation(loc, 'billingAddress2')}
                            Line2
                            address={billingAddress}
                            value={billingAddress && billingAddress.line1 ?
                                { line1: addressObjectToStr(billingAddress), line2: billingAddress.line2 } : null
                            }
                            placeholder="Address"
                            error={errorField === "billingAddress"}
                            errorMessage={errorMessage}
                            optional
                        />
                    </div>


                    <div ref={this.shippingAddressRef}>
                        {
                            makeShipAddrAsBillAddr ?
                                <Flex between fullWidth>
                                    <Flex start centerHorizontally style={{ marginBottom: 4, width: '100%', gap: 12 }}>
                                        <Text color={'#7384AA'} size='14px' weight={400}>Shipping Address</Text>
                                        <Checkbox onChange={(e) => { this.setState({ makeShipAddrAsBillAddr: e.target.checked }) }} checked={makeShipAddrAsBillAddr}>
                                            <span style={{ margin: '0', fontSize: "0.9rem", color: theme.colors.secondary7 }}>Same as billing?</span>
                                        </Checkbox>
                                    </Flex>
                                    <Text color={'#7384AA'} size='12px' weight={400}>optional</Text>
                                </Flex>
                                :
                                <LabeledInput
                                    nomargin
                                    customlabelcomponent={<Flex between fullWidth>
                                        <Flex start centerHorizontally style={{ marginBottom: 4, width: '100%', gap: 12 }}>
                                            <Text color={'#7384AA'} size='14px' weight={400}>Shipping Address</Text>
                                            <Checkbox onChange={(e) => { this.setState({ makeShipAddrAsBillAddr: e.target.checked }) }} checked={makeShipAddrAsBillAddr}>
                                                <span style={{ margin: '0', fontSize: "0.9rem", color: theme.colors.secondary7 }}>Same as billing?</span>
                                            </Checkbox>
                                        </Flex>
                                        <Text color={'#7384AA'} size='12px' weight={400}>optional</Text>
                                    </Flex>
                                    }
                                    id="shippingAddress"
                                    key={"shippingAddress" + makeShipAddrAsBillAddr}
                                    type="location"
                                    getLocation={(loc) => this.getLocation(loc, 'shippingAddress')}
                                    Line2
                                    address={shippingAddress}
                                    // width="592px"
                                    value={shippingAddress && shippingAddress.line1 ?
                                        { line1: addressObjectToStr(shippingAddress), line2: shippingAddress.line2 } : null
                                    }
                                    placeholder="Address"
                                    error={errorField === "shippingAddress"}
                                    errorMessage={errorMessage}
                                />
                        }
                    </div>


                    {/* <div style={{ height: 1, width: '100%', background: '#F2F2F2' }}></div> */}

                    <span class="section-heading-text">CONTACT DETAILS</span>

                    <LabeledInput
                        inputRef={this.contactNameRef}
                        nomargin
                        label="Name"
                        id="fullyQualifiedName"
                        key="fullyQualifiedName"
                        // width="284px"
                        placeholder="Tony Adams"
                        defaultValue={fullyQualifiedName}
                        onChange={this.handleTextChange}
                        error={errorField === "fullyQualifiedName"}
                        errorMessage={errorMessage}
                    />

                    <LabeledInput
                        inputRef={this.emailRef}
                        nomargin
                        label="Email"
                        type="email"
                        id="primaryEmailAddress"
                        key="primaryEmailAddress"
                        // width="284px"
                        value={customer.primaryEmailAddress && customer.primaryEmailAddress.address}
                        onChange={this.handleTextChange}
                        placeholder="Email"
                        error={errorField === "primaryEmailAddress"}
                        errorMessage={errorMessage}
                    />

                    <LabeledInput
                        inputRef={this.phoneRef}
                        nomargin
                        type="phone"
                        label="Phone"
                        // width="284px"
                        value={phone}
                        maxLength={15}
                        onChange={this.handlePhoneChange}
                        placeholder="Phone"
                        error={errorField === "phone"}
                        errorMessage={errorMessage}
                        optional
                    />

                    <div style={{ height: 1, width: '100%', background: '#F2F2F2' }}></div>

                    <span class="section-heading-text">INVOICING DETAILS</span>

                    <LabeledInput
                        label="Send invoice to"
                        id="emails"
                        key="emails"
                        type="multi-email"
                        nomargin
                        onChange={(invoiceRecipientEmail) => {
                            console.log("CreateCustomer nextStep customer:", invoiceRecipientEmail)
                            this.setState({ invoiceRecipientEmail: invoiceRecipientEmail })
                            this.setState({
                                customer: update(this.state.customer, { invoiceRecipientEmail: { $set: (invoiceRecipientEmail) } })
                            });
                        }}
                        onDelete={(index) => {
                            var emails = []

                            for (var i = 0; i < invoiceRecipientEmail.length; i++) {
                                if (i != index) emails.push(invoiceRecipientEmail[i])
                            }

                            this.setState({ invoiceRecipientEmail: emails })
                        }}
                        emails={invoiceRecipientEmail && invoiceRecipientEmail}
                        error={errorField === "invoiceRecipientEmail"}
                        errorMessage={errorMessage}
                        instruction="Add email addresses you will be sending invoices to at this customer and we will automatically populate it when you select the invoice"
                        // containerStyle={{ padding: 12, width: '592px' }}
                        unsyncable={isCodatUser(aionStore)}
                        accountingSoftware={getCodatPlatformName(aionStore)}
                        optional
                    />

                    {
                        customers?.length > 0 &&
                        <Checkbox style={{ marginTop: 0 }} checked={showParentDropdown} onChange={(e) => {
                            this.setState({ showParentDropdown: e.target.checked, customer: update(customer, { billWithParent: { $set: e.target.checked } }) }, () => this.parentRef.current.scrollIntoView({ behavior: 'smooth' }))
                        }}><span style={{ margin: '0', fontSize: "0.9rem", color: theme.colors.secondary7 }}>Is this company associated with a parent customer?</span></Checkbox>
                    }

                    <div ref={this.parentRef}>
                        {
                            showParentDropdown &&
                            <LabeledInput
                                nomargin
                                customlabelcomponent={
                                    <Flex between centerHorizontally style={{ marginBottom: 4, width: '100%' }}>
                                        <Flex start centerHorizontally>
                                            <Text color={'#7384AA'} size='14px' weight={400}>Parent Company</Text>
                                        </Flex>
                                    </Flex>
                                }
                                type="select"
                                className="no-left-padding"
                                showSearch
                                // width="592px"
                                key="parentCustomer"
                                placeholder="Select parent company"
                                optionFilterProp="children"
                                value={parentCustomerName}
                                allowClear
                                onChange={this.handleParentCustomerSelect}
                                filterOption={(input, option) => {
                                    return option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }}
                                error={errorField === "parentCustomer"}
                                errorMessage={errorMessage}
                            >
                                {customers.map(item => <Option key={item.customerId} id="selectedParentCustomer" name={item.displayName || item.companyName} value={item.customerId} style={{ backgroundColor: "transparent" }}>{item.displayName || item.companyName}</Option>)}
                            </LabeledInput>
                        }
                    </div>
                </FlexColumn>

                <FlexColumn end grow>
                    <Flex start centerHorizontally gap="24px" style={{ boxShadow: '0px -4px 8px -2px rgba(102, 102, 102, 0.08)', padding: '24px 24px 0' }}>
                        <Button loading={loading} text='CONFIRM' solid onClick={this.handleSave} />
                    </Flex>
                </FlexColumn>
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.receivablesAppReducer,
        aionStore: state.aionAppReducer,
        receivablesStore: state.receivablesAppReducer,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)))