import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';
import 'draft-js/dist/Draft.css';
import { ConfigProvider, Space, Popconfirm, Modal, Spin, Table, Switch, Upload, message, Tooltip, Skeleton } from 'antd';
import { apiPOSTReq, getPreSignedS3Url } from '../../../../Utils/api';
import environment from '../../../../environment';
import { saveAs } from 'file-saver'
import HelloSign from "hellosign-embedded";

import { Image } from '../../../Reusable/Image'
import Percentage from '../../../../Images/Percentage.png'
// Util
import { Button, TextButton } from '../../../Reusable/Button';
import { FlexColumn, Flex } from '../../../Reusable/Container';
import { LabeledInput } from '../../../Reusable/Input';
import { DeleteOutlined, CheckCircleOutlined, MailOutlined, UploadOutlined, FileDoneOutlined, LoadingOutlined } from '@ant-design/icons'
import { getAddressObj } from '../../../../Utils/util';
import TableView from '../../../Reusable/TableView'
// Actions

import 'react-multi-email/style.css';
import moment from 'moment';
import SuccessModal from '../../../Reusable/SuccessModal';
import { ErrorAlert } from '../../../Reusable/Alert';

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetchAndDownload: false,
            isLoading: false,
            savedContract: false,
            reminderModal: false,
            contractSent: false,
            unsavedChanges: false,
            proposalTerms: false,
            openContractPage: false,
            embeddedUrl: '',
            customContractState: false,
            productType: this.props.productType
        }
    }

    componentDidMount() {
        this.setState({ applicationId: this.props.loanApp.applicationId })
        this.fetchContracts();
    }

    handleContractChange = (event) => {
        const { contractData } = this.state
        contractData[event.target.id] = event.target.value
        this.setState({ contractData })
        this.setState({ unsavedChanges: true })

    };

    handleBorrowerChange = (event) => {
        const { contractData } = this.state
        const { promisor } = this.state.contractData
        promisor[event.target.id] = event.target.value
        this.setState({ promisor })
        this.setState({ unsavedChanges: true })
    };

    getLocation(location, key) {
        const { contractData } = this.state
        let addrObj = getAddressObj(location);
        addrObj.city = addrObj.city ? addrObj.city.substring(0, 18) : '';
        contractData[key] = addrObj
    }

    saveUserInput = (id, value) => {
        var dataToSave = this.state.contractData || {};
        switch (id) {
            default:
                dataToSave[id] = value;
                break;
        }

        this.setState({ contractData: dataToSave });
    }

    handleDateChange = (id, dateStr) => {
        if (dateStr) {
            this.saveUserInput(id, moment.utc(dateStr).startOf('day').format("YYYY-MM-DD"))
        } else {
            this.saveUserInput(id, null)
        }
    }

    closePreview = () => {
        this.setState({
            reminderSent: false, reminderModal: false, proposalTerms: false,
            contractSent: false, openSignatureStatus: false, fetchAndDownload: false
        });
    };

    openReminder = () => {
        this.setState({ reminderModal: true })
    }

    closeContractPage() {
        this.setState({ isLoading: true, isClosePageLoading: true, contractSent: false })
        const headers = {

            AionCurrentBiz: this.props.businessId
        }

        const body = {
            BusinessId: this.props.businessId,
            ApplicationId: this.state.applicationId
        }

        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/contract/fetchRLOCContracts`, headers, body, (err, resp) => {
            try {
                const response = resp || {};
                if (response.result) {
                    const { contractList } = response
                    this.setState({ contractsLoading: true, contracts: contractList, openContractPage: false, isLoading: false, isClosePageLoading: false })

                } else {
                    this.setState({ contractsLoading: true, contracts: "", isLoading: false, isClosePageLoading: false })
                    throw Error(response.error || response.responseMessage || "Could not fetch Contracts")
                }
            } catch (error) {
                this.setState({ contractsLoading: true, contracts: "", openContractPage: false, isClosePageLoading: false, isLoading: false, })
                console.log("ERRR getActivePayors", error, err, resp);
                // ErrorAlert({ description: error.message })
            }
        })
    }

    openContractPage() {
        this.setState({ isLoading: true, savedContract: false, customContractState: false })
        const header = {
            AionCurrentBiz: this.props.businessId
        }
        const body = {
            "BusinessId": this.props.businessId,
            "ApplicationId": this.state.applicationId
        }

        if (this.state.productType === "GNPL") {
            apiPOSTReq(`${environment.lsmBaseUrl}/lsm/contract/loadContractValues`, header, body, (err, resp) => {
                try {
                    const response = resp || {};
                    if (response != null) {

                        const { contractTerms } = response
                        if (contractTerms == undefined) {
                            this.setState({ proposalTerms: true, isLoading: false })
                            ErrorAlert({ description: "Error Loading Contract" })
                        }
                        else {
                            this.setState({ contractData: contractTerms, openContractPage: true, isLoading: false, isMinimumCashBalanceWaived: contractTerms.minimumCashBalanceWaived })
                        }

                    } else {
                        throw Error(response.error || response.responseMessage || "Could not retrieve values")
                    }
                } catch (error) {
                    console.log("ERRR retrieving values", error, err, resp);
                    // ErrorAlert({description: error.message})
                }
            })
        }
        else {
            apiPOSTReq(`${environment.lsmBaseUrl}/lsm/contract/loadRLOCContract`, header, body, (err, resp) => {
                try {
                    const response = resp || {};
                    if (response != null) {

                        const { contractTerms } = response

                        if (contractTerms == undefined) {
                            this.setState({ proposalTerms: true, isLoading: false })
                            ErrorAlert({ description: "Error Loading Contract" })
                        }
                        else {
                            this.setState({ contractData: contractTerms, openContractPage: true, isLoading: false, applicationId: contractTerms.applicationId })
                            console.log("ApplicationId:", this.state.applicationId)
                        }

                    } else {
                        throw Error(response.error || response.responseMessage || "Could not retrieve values")
                    }
                } catch (error) {
                    console.log("ERRR retrieving values", error, err, resp);
                    // ErrorAlert({description: error.message})
                }
            })
        }
    }

    editContractPage(contractTerms) {
        this.setState({ contractData: contractTerms, openContractPage: true, isLoading: false, applicationId: contractTerms.applicationId })
        console.log("ApplicationId:", contractTerms, this.state.applicationId)
    }

    previewContract = () => {
        const header = {

            AionCurrentBiz: this.props.businessId
        }
        const { contractData } = this.state
        const body = {
            "contractTerms": contractData,
            "BusinessId": this.props.businessId
        }


        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/contract/requestContractPreview`, header, body, (err, resp) => {
            try {
                const response = resp || {};
                console.log("Response:", response)

                if (response.result) {
                    const embeddedUrl = response.helloSignEmbeddedUrl
                    this.setState({ embeddedUrl: embeddedUrl })
                    this.setState({ unsavedChanges: false, savedContract: true, isSaveLoading: false })

                    const client = new HelloSign({
                        clientId: `${environment.hellosignClientKey}`
                    });

                    client.open(this.state.embeddedUrl, {
                        testMode: true
                    });
                }
            }
            catch (error) {
                console.log("ERRR saving values", error, err, resp);
            }

        })
    }

    handleSave() {
        this.setState({ isSaveLoading: true })
        const header = {

            AionCurrentBiz: this.props.businessId
        }
        const { contractData } = this.state
        contractData.source = "Manual"
        const body = {
            "contractTerms": contractData,
            "BusinessId": this.props.businessId
        }

        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/contract/saveContractValues`, header, body, (err, resp) => {
            try {
                const response = resp || {};
                if (response.result) {
                    this.previewContract()
                }
            }
            catch (error) {
                console.log("ERRR saving values", error, err, resp);
            }
        })

    }

    sendContract = () => {
        this.setState({ isSendLoading: true })
        const header = {

            AionCurrentBiz: this.props.businessId
        }
        const { contractData } = this.state
        contractData.source = "Manual"
        const body = {
            "contractTerms": contractData,
            "BusinessId": this.props.businessId
        }

        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/contract/requestSignature`, header, body, (err, resp) => {
            try {
                const response = resp || {};
                if (response.result) {
                    this.setState({ contractSent: true, isSendLoading: false })
                }

            }
            catch (error) {
                console.log("ERRR saving values", error, err, resp);
            }
        })
    }

    fetchContracts = () => {
        const headers = {
            AionCurrentBiz: this.props.businessId
        }

        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/contract/fetchRLOCContracts`, headers, { "BusinessId": this.props.businessId }, (err, resp) => {
            try {
                const response = resp || {};
                if (response.result) {
                    const { contractList } = response
                    this.setState({ contractsLoading: true, contracts: contractList })
                }
                else {
                    this.setState({ contractsLoading: true, contracts: "", contractsLoading: true })
                    throw Error(response.error || response.responseMessage || "Could not fetch Contracts")
                }
            } catch (error) {
                this.setState({ contractsLoading: true, contracts: "" })
                console.log("ERRR getActivePayors", error, err, resp);
                // ErrorAlert({description: error.message})
            }
        })
    }

    openOptions = (item) => {

        this.setState({ contractIdSS: item.contractId })

        const headers = {

            AionCurrentBiz: this.props.businessId
        }
        const body = {
            "contracts": item,
            "BusinessId": this.props.businessId
        }

        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/contract/signatureStatus`, headers, body, (err, resp) => {
            try {
                const response = resp || {}
                if (response.result) {
                    const signatureList = response.signatureStatus
                    this.setState({ signatureStatus: signatureList })
                    const newList = signatureList.map((item) => {
                        const [name, signatureStatus] = item.split(':');
                        return { name, signatureStatus };
                    });
                    this.setState({ statusData: newList, openSignatureStatus: true })
                    this.fetchContracts()
                    this.setState({ contractIdSS: '' })
                }
            }
            catch (error) {
                console.log("ERRR getting signature status", error, err, resp);
            }
        })
    }

    downloadContract = () => {
        return new Promise((resolve, reject) => {
            getPreSignedS3Url({ url: this.state.contractDocumentUrl }, (err, preSignedUrl) => {
                if (!err) {
                    saveAs(preSignedUrl, "AionContract");
                } else {
                    reject(JSON.stringify(err))
                }
            })
        })
    }

    fetchAndDownload = (item) => {
        const headers = {

            AionCurrentBiz: this.props.businessId
        }
        this.setState({ fetchAndDownload: true, contractId: item.contractId })
        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/contract/downloadContract`, headers, { BusinessId: item.businessId, applicationId: item.applicationId, contractId: item.contractId, contractTermsId: item.contractTermsId }, (err, resp) => {
            try {
                const response = resp || {}
                if (response.result) {
                    const contractDocumentUrl = response.contractDocumentUrl
                    this.setState({ contractDocumentUrl: contractDocumentUrl })
                    this.downloadContract()
                    this.setState({ fetchAndDownload: false, contractId: '' })
                }
                else {
                    this.setState({ fetchAndDownload: false, contractId: '' })
                    if (response.responseMessage === "This resource has been deleted") {
                        ErrorAlert({ description: "The contract cannot be downloaded because it has been cancelled." })
                    } else {
                        ErrorAlert({ description: "Download the contract after all parties have signed. Check signature status for more details." })
                    }

                }
            }
            catch (error) {
                console.log("ERRR downloading document", error, err, resp);
            }
        })
    }

    sendReminderEmail = (item) => {
        this.setState({ reminderModal: false, sendReminder: true })
        const headers = {

            AionCurrentBiz: item.businessId
        }
        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/contract/sendReminder`, headers, { BusinessId: item.businessId, "contracts": item }, (err, resp) => {
            try {
                const response = resp || {}
                if (response.result) {
                    this.setState({ sendReminder: false, reminderSent: true })
                }
            }
            catch (error) {
                console.log("ERRR sending email", error, err, resp);
            }
        })
    }

    cancelSignature = (item) => {

        const headers = {

            AionCurrentBiz: item.businessId
        }

        const body = {
            "contracts": item,
            "BusinessId": this.props.businessId
        }

        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/contract/removeSignatureRequest`, headers, body, (err, resp) => {
            try {
                const response = resp || {}
                if (response.result) {
                    // window.location.reload()

                    this.fetchContracts()
                }
            }
            catch (error) {
                console.log("ERRR sending email", error, err, resp);
            }
        })
    }

    makeContractActive = (item) => {
        const headers = {

            AionCurrentBiz: item.businessId
        }

        const body = {
            "contracts": item,
            "BusinessId": this.props.businessId
        }

        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/contract/makeActiveContract`, headers, body, (err, resp) => {
            try {
                const response = resp || {}
                if (response.result) {
                    // window.location.reload()
                    this.fetchContracts()
                }
            }
            catch (error) {
                console.log("ERRR sending email", error, err, resp);
            }
        })

    }

    handleDocSubmit = (uploadItem) => {
        var { loadingItem } = uploadItem || {}
        var { loanApplication } = this.props
        const { contractData } = this.state
        var businessDocument = {
            "category": "Due Diligence",
            "name": uploadItem.name,
            "documentUrl": uploadItem.savedFile || {},
            "subType": 'Contracts',
            "source": 'User',
        };

        if (loanApplication) businessDocument.applicationId = loanApplication.applicationId;
        var body = {
            businessDocument: businessDocument,
            "businessId": this.props.businessId,
            "contractTerms": contractData
        }
        console.log("handleSubmit DOC/SAVE uploadItem body", body);
        this.setState({ submitLoading: true, loadingItem: loadingItem });

        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/contract/storeCustomRLOCContract`, { AionCurrentBiz: this.props.businessId }, body, (err, resp) => {
            this.setState({ submitLoading: false, loadingItem: null });
            try {
                const data = resp || {};
                if (data.result) {
                    message.success(`File saved successfully.`, 0.75);
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/docs/save err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    uploadProps = {
        name: `business-docs`,
        action: `${environment.apiBaseUrl}/file/upload`,
        headers: {
            businesskey: this.props.aionStore.BusinessUniqueKey,
            jwt: this.props.aionStore.jwt
        },
        beforeUpload: (doc) => {
            const isPDF = doc.type === 'application/pdf';
            if (!isPDF) {
                message.error(`${doc.name} is not a pdf file`);
            }
            return isPDF || Upload.LIST_IGNORE;
        },
        onChange: (info) => {
            var { fileList } = info;
            var savedFile = {};
            var doc = {}
            fileList.forEach(file => {
                const { status, response, name, url } = file;
                if (status === "done") {
                    savedFile = {
                        fileName: name,
                        uri: (response.UploadedUrls || [])[0],
                    }
                    doc = { name: name, savedFile: savedFile }
                    console.log("URLSaved", savedFile.uri)
                    this.handleDocSubmit(doc);
                } else if (status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            });
            if (((Object.keys(savedFile)).length) !== 0) {
                doc.savedFile = savedFile;
            }
        },
        accept: ".pdf",
    };

    completeContract = (item) => {
        const headers = {

            AionCurrentBiz: item.businessId
        }

        const body = {
            "contracts": item,
            "BusinessId": this.props.businessId
        }

        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/contract/completeContract`, headers, body, (err, resp) => {
            try {
                const response = resp || {}
                if (response.result) {
                    // window.location.reload()
                    this.fetchContracts()
                }
            }
            catch (error) {
                console.log("ERRR sending email", error, err, resp);
            }
        })

    }



    render() {
        const { savedContract, isClosePageLoading, reminderSent, customize, customContractState,
            unsavedChanges, openContractPage, contractSent, statusData, openSignatureStatus, contracts, isSendLoading, isSaveLoading, isLoading } = this.state
        var { contractData } = this.state
        contractData = contractData || {}
        const { commitmentFee, contractStartDate, advanceFee, contractEndDate, businessAddress, commitmentAmount, promisor, guarantor, subscriptionFee, customContract } = contractData;
        const isSaveDisabled = !contractStartDate || !advanceFee || !commitmentAmount || !contractEndDate || !guarantor ||
            !promisor.promisorFirstName || !promisor.promisorLastName || !promisor.promisorEmail || !promisor.promisorPosition;
        const { aionStore, theme } = this.props
        var { Profile } = aionStore;
        var { BusinessInfo } = Profile || {};
        BusinessInfo = BusinessInfo || {};
        const antIcon = <LoadingOutlined style={{ fontSize: 12 }} spin />;

        const isCustomContractDisabled = !contractStartDate || !advanceFee || !commitmentAmount || !contractEndDate || !guarantor ||
            !promisor.promisorFirstName || !promisor.promisorLastName || !promisor.promisorEmail || !promisor.promisorPosition;

        const Statuscolumns = [
            {
                title: 'Name',
                dataIndex: 'name',
                align: 'left'
            },
            {
                title: 'Signature Status',
                dataIndex: 'signatureStatus',
                align: 'left'
            }
        ]

        const columns = [
            {
                title: 'Contract ID',
                align: 'center',
                render: item =>
                    <>
                        <a style={{ textDecoration: "underline" }} onClick={(e) => {
                            this.editContractPage(item);
                        }}>{item.contractId}</a>
                    </>,
            },
            {
                title: 'Issued On',
                dataIndex: 'createdAt',
                align: 'center',
                render: (date) => {
                    if (date) {
                        date = new Date(date).toISOString();
                        date = date.split("T")[0];
                    }
                    return (moment(date).format('MM/DD/YY'))
                },
            },
            {
                title: 'Credit Limit',
                dataIndex: 'commitmentAmount',
                align: 'center'
            },
            {
                title: 'Start Date',
                dataIndex: 'contractStartDate',
                align: 'center',
                render: (date) => {
                    if (date) {
                        date = new Date(date).toISOString();
                        date = date.split("T")[0];
                    }
                    return (moment(date).format('MM/DD/YY'))
                },
            },
            {
                title: 'End Date',
                dataIndex: 'contractEndDate',
                align: 'center',
                render: (date) => {
                    if (date) {
                        date = new Date(date).toISOString();
                        date = date.split("T")[0];
                    }
                    return (moment(date).format('MM/DD/YY'))
                },
            },

            {
                title: 'Signature Status',
                dataIndex: 'contractSignatureStatus',
                align: 'center'
            },
            {
                title: 'Contract Status',
                dataIndex: 'contractStatus',
                align: 'center'
            },
            {
                title: 'Actions',
                key: 'signatureStatus',
                align: 'center',
                render: (item) =>

                (
                    !item.customContract &&

                    <Flex center style={{ whiteSpace: 'nowrap' }}>
                        <Tooltip title="Check signature status">
                            <a onClick={(event) => {
                                this.openOptions(item);
                            }} style={{ marginRight: "10px" }} >Status </a>
                            {item.contractId === this.state.contractIdSS
                                ? <Spin indicator={antIcon} style={{ marginRight: "10px" }} />
                                :
                                <></>
                            }
                        </Tooltip>
                        <Popconfirm
                            title="Are you sure you want to make this contract active?"
                            onConfirm={(e) => {
                                this.makeContractActive(item);
                            }}
                            okText="Confirm"
                            cancelText="Cancel"
                        >
                            <Tooltip title="Make the contract active">
                                <TextButton icon={<CheckCircleOutlined style={{ fontSize: 18 }} />} style={{ marginRight: "5px" }} />
                            </Tooltip>
                        </Popconfirm>
                        <Popconfirm
                            title="Do you want to send a reminder?"
                            onConfirm={() => this.sendReminderEmail(item)}
                            okText="Confirm"
                            cancelText="Cancel"
                        >
                            <Tooltip title="Send Reminder Email">
                                <TextButton icon={<MailOutlined style={{ fontSize: 18 }} />} style={{ marginRight: "5px" }} />
                            </Tooltip>
                        </Popconfirm>

                        <Popconfirm
                            title="Are you sure you want to cancel the contract?"
                            onConfirm={() => this.cancelSignature(item)}
                            okText="Confirm"
                            cancelText="Cancel"
                        >
                            <Tooltip title="Cancel Contract">
                                <TextButton icon={<DeleteOutlined style={{ fontSize: 18 }} />} style={{ marginRight: "5px" }} />
                            </Tooltip>
                        </Popconfirm>
                        <Popconfirm
                            title="Are you sure you want to mark the contract as completed?"
                            onConfirm={() => this.completeContract(item)}
                            okText="Confirm"
                            cancelText="Cancel"
                        >
                            <Tooltip title="Mark Contract as completed">
                                <TextButton icon={<FileDoneOutlined style={{ fontSize: 18 }} />} style={{ marginRight: "5px" }} />
                            </Tooltip>

                        </Popconfirm>
                    </Flex>
                )
            },
            {
                title: 'Document',
                key: "documentUrl",
                align: "center",
                render: (item) =>
                (
                    <Flex center style={{ whiteSpace: 'nowrap' }}>
                        <a onClick={(event) => {
                            this.fetchAndDownload(item);
                            event.stopPropagation();
                        }} style={{ marginRight: "5px" }}>Download</a>

                        {item.contractId === this.state.contractId
                            ? <Spin indicator={antIcon} />
                            :
                            <></>
                        }
                    </Flex>
                )
            }
        ]
        return (
            <div>
                {!openContractPage ?
                    <FlexColumn style={{ margin: '0 1px' }}>
                        <ConfigProvider renderEmpty={customize && this.customizeRenderEmpty}>
                            <div className="config-provider" >
                                <TableView
                                    id="contracts-table"
                                    titleText={"Contracts"}
                                    tableLayout='auto'
                                    columns={columns}
                                    dataSource={contracts}
                                    rowKey='displayName'
                                    loading={!this.state.contractsLoading}
                                    pagination={false}
                                    style={{ marginBottom: '40px' }}
                                />
                            </div>
                        </ConfigProvider>

                        <Space size="middle" style={{ justifyContent: "center" }}>
                            <Button
                                solid
                                primary
                                loading={isLoading}
                                onClick={() => { this.openContractPage() }}
                                text='New Contract'
                            />

                            <Modal
                                visible={reminderSent}
                                footer={null}
                                onCancel={this.closePreview}
                            >
                                <SuccessModal
                                    title='Reminder Sent Successfully'
                                    buttonTitle='OKAY'
                                    onConfirm={() => this.setState({ reminderSent: false })}
                                >

                                </SuccessModal>

                            </Modal>

                            <Modal
                                visible={openSignatureStatus}
                                footer={false}
                                onCancel={this.closePreview}
                            >
                                <Skeleton loading={!this.state.openSignatureStatus} active title={false} paragraph={{ rows: 4 }} className="skeleton-padding">
                                    <Table
                                        id="Status Table"
                                        tableLayout='auto'
                                        columns={Statuscolumns}
                                        dataSource={statusData}
                                        pagination={false}
                                        style={{ marginBottom: '20px' }}

                                    />
                                </Skeleton>
                            </Modal>
                        </Space>
                    </FlexColumn> :
                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                        <h3 style={{ color: '#7384AA', textAlign: "left" }}>CONTRACT DETAILS</h3>
                        <Flex between style={{ width: "75%" }}>
                            <div style={{ width: "25%", marginRight: 12 }}>
                                <LabeledInput
                                    label="Effective Date"
                                    type="date-picker"
                                    id="contractStartDate"
                                    placeholder="yyyy-mm-dd"
                                    onChange={(date, dateStr) => {
                                        this.handleDateChange("contractStartDate", dateStr);
                                        this.setState({ startDate: date })
                                    }}
                                    required
                                />
                            </div>

                            <div style={{ width: "25%", marginRight: 12, marginLeft: 12 }}>
                                <LabeledInput
                                    label='Commitment Amount'
                                    id="commitmentAmount"
                                    // type='number'
                                    placeholder="0.00"
                                    prefix="$"
                                    value={commitmentAmount}
                                    onChange={this.handleContractChange}
                                    required

                                />
                            </div>
                            <div style={{ width: "25%", marginLeft: 12 }}>
                                <LabeledInput
                                    label="Commitment Maturity Date"
                                    type="date-picker"
                                    id="contractEndDate"
                                    placeholder="yyyy-mm-dd"
                                    onChange={(date, dateStr) => this.handleDateChange("contractEndDate", dateStr)}
                                    required
                                />
                            </div>
                        </Flex>
                        <Flex between style={{ width: "75%" }}>
                            <div style={{ width: "25%", marginRight: 12 }}>
                                <LabeledInput
                                    label='Advance Fee'
                                    id="advanceFee"
                                    // type='number'
                                    placeholder="0"
                                    symbolsuffix="%"
                                    value={advanceFee}
                                    onChange={this.handleContractChange}
                                    required
                                    suffixFontSzie="20px"
                                />
                            </div>
                            <div style={{ width: "25%", marginRight: 12 }}>
                                <LabeledInput
                                    label='Committment Fee'
                                    id="commitmentFee"
                                    placeholder="0"
                                    prefix="$"
                                    value={commitmentFee}
                                    onChange={this.handleContractChange}
                                    required

                                />
                            </div>
                            <div style={{ width: "25%", marginleft: 12 }}>
                                <LabeledInput
                                    label='Subscription Fee'
                                    id="subscriptionFee"
                                    // type='number'
                                    placeholder="0"
                                    prefix="$"
                                    value={subscriptionFee}
                                    onChange={this.handleContractChange}
                                    required

                                />
                            </div>
                        </Flex>
                        <Flex between style={{ width: "75%", marginBottom: "30px" }}>
                            <div style={{ width: "25%", marginRight: 12 }}>
                                <LabeledInput
                                    label='Guarantor'
                                    id="guarantor"
                                    type='text'
                                    placeholder="Enter Name"
                                    onChange={this.handleContractChange}
                                    value={guarantor}
                                    required

                                />
                            </div>
                        </Flex>
                        <Flex between style={{ width: "75%" }}>
                            <h3 style={{ color: '#7384AA', textAlign: "left" }}>BORROWER DETAILS</h3>
                        </Flex>
                        <Flex between style={{ width: "75%" }}>
                            <div style={{ width: "25%", marginRight: 12 }}>
                                <LabeledInput
                                    label='First Name'
                                    id="promisorFirstName"
                                    type='text'
                                    name="firstName"
                                    placeholder="Enter First Name"
                                    value={promisor.promisorFirstName}
                                    onChange={this.handleBorrowerChange}
                                    required

                                />
                            </div>

                            <div style={{ width: "25%", marginRight: 12, marginLeft: 12 }}>
                                <LabeledInput
                                    label='Middle Name'
                                    id="promisorMiddleName"
                                    type='text'
                                    placeholder="Enter Middle Name"
                                    value={promisor.promisorMiddleName}
                                    onChange={this.handleBorrowerChange}
                                />
                            </div>

                            <div style={{ width: "25%", marginLeft: 12 }}>
                                <LabeledInput
                                    label='Last Name'
                                    id="promisorLastName"
                                    type='text'
                                    placeholder="Enter Last Name"
                                    value={promisor.promisorLastName}
                                    onChange={this.handleBorrowerChange}
                                    required

                                />
                            </div>
                        </Flex>
                        <Flex between style={{ width: "75%" }}>
                            <div style={{ width: "25%", marginRight: 12 }}>
                                <LabeledInput
                                    label='Email'
                                    id="promisorEmail"
                                    type='text'
                                    placeholder="Eg: John@email.com"
                                    value={promisor.promisorEmail}
                                    onChange={this.handleBorrowerChange}
                                    required

                                />
                            </div>
                            <div style={{ width: "25%", marginLeft: 12 }}>
                                <LabeledInput
                                    label='Position'
                                    id="promisorPosition"
                                    type='text'
                                    placeholder="Enter Position"
                                    value={promisor.promisorPosition}
                                    onChange={this.handleBorrowerChange}
                                    required

                                />
                            </div>

                        </Flex>
                        <Flex between style={{ width: "75%", marginBottom: "30px" }}>
                            <FlexColumn style={{ width: "50%" }}>
                                <LabeledInput
                                    label="Business Address"
                                    id="businessAddress"
                                    type="location"
                                    value={businessAddress}
                                    getLocation={(location, formattedAddress) => this.getLocation(location, 'businessAddress')}
                                />
                            </FlexColumn>
                        </Flex>
                        <Flex between style={{ width: "50%", marginBottom: "30px" }}>
                            <FlexColumn style={{ width: "25%", marginRight: 12 }}>
                                <Flex start style={{ marginBottom: 8 }}>
                                    <span style={{ fontSize: "0.9rem", color: theme.colors.systemGray, marginRight: 8 }}>Custom Contract?</span>
                                    <Switch size="small" disabled={isCustomContractDisabled} defaultChecked={this.state.customContractState} onChange={() => this.setState({ customContractState: !this.state.customContractState })} />
                                </Flex>
                            </FlexColumn>
                            {
                                customContractState &&
                                <FlexColumn style={{ width: "25%", marginRight: 12 }}>
                                    <Upload {...this.uploadProps}>
                                        <TextButton size="medium" icon={<UploadOutlined />} text="Upload and Save Contract"></TextButton>
                                    </Upload>
                                </FlexColumn>
                            }

                        </Flex>
                        <Flex between style={{ width: "75%" }}>
                            <div style={{ width: "25%", marginRight: 12 }}>
                                <Space>
                                    <Button loading={isSaveLoading} key="PreviewContract" text="Save and Preview" onClick={() => { this.handleSave() }} disabled={isSaveDisabled || customContractState}></Button>
                                    <Button loading={isSendLoading} solid key="GenContract" text="Send Contract" onClick={() => { this.sendContract() }} disabled={unsavedChanges || isSaveDisabled || !savedContract || customContractState}></Button>
                                    <Button loading={isClosePageLoading} key="ViewContracts" text="View All Contracts" onClick={() => { this.closeContractPage() }}></Button>
                                </Space>
                            </div>
                        </Flex>
                        <FlexColumn between fullHeight>
                            <FlexColumn start style={{ padding: 24, height: '100%' }}>
                                <Modal
                                    visible={contractSent}
                                    footer={null}
                                    onCancel={this.closePreview}
                                >
                                    <SuccessModal
                                        title='Contract Sent Successfully'
                                        buttonTitle='OKAY'
                                        onConfirm={() => this.closeContractPage()}
                                    >

                                    </SuccessModal>
                                </Modal>
                            </FlexColumn>
                        </FlexColumn>


                    </div>
                }
            </div>

        );
    }
}

function mapStateToProps(state) {
    return {
        store: state.creditAppReducer,
        aionStore: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)));