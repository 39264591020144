import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import { Select, Modal, Drawer, Space, Skeleton, message, Affix, Switch, Tooltip, Checkbox } from 'antd'
import { Flex, FlexColumn, CardContainer, LightContainer, LineItemsHeader, LineItemRow } from '../../../Reusable/Container'
import PageHeader from '../../../Reusable/PageHeader'
import { Text, Tag } from '../../../Reusable/Text'
import { Button, TextButton } from '../../../Reusable/Button'
import { Image } from '../../../Reusable/Image'
import { LabeledInput } from '../../../Reusable/Input'
import AlertModal from '../../../Reusable/AlertModal'
import EditTemplate from '../Template/EditTemplate'
import { ErrorAlert } from '../../../Reusable/Alert'
import { Editor, EditorState } from 'draft-js'
import 'draft-js/dist/Draft.css'
import { stateFromHTML } from 'draft-js-import-html'
import moment, { isMoment } from 'moment'
import cloneDeep from 'lodash/cloneDeep'
import Void from './Void'
import ProductDrawer from '../../Products/ProductDrawer'
import DocumentDrawer from '../../Customers/DocumentDrawer'
import CustomerDrawer from '../../Customers/Create Customer/CustomerDrawer'
import SendInvoiceDrawer from '../SendInvoiceDrawer'
import { FromAccountOption } from '../../../Reusable/Option'
import { defaultPaymentTermObjects, toNumber, toCurrency, addressObjectToMultiLineStr, getFilenameFromUrl, isEqual, toNumberStr, isCodatUser, getCodatPlatformName, getFeaturePerm, getBPAccountsWithAccess } from '../../../../Utils/util'
import { apiPOSTReq, getPreSignedS3Url } from '../../../../Utils/api'
import { addDataToStore, CODAT_DATA } from '../../../../Actions/actions'
import environment from '../../../../environment'
import PoweredBy from '../../../../Images/PoweredByAion.png'
import Call from '../../../../Images/invoice-template/Call.png'
import Divider from '../../../../Images/invoice-template/Divider.png'
import Email from '../../../../Images/invoice-template/Email.png'
import Individual from '../../../../Images/invoice-template/Individual.png'
import Info from '../../../../Images/info-gradient.png'
import InfoBlack from '../../../../Images/info.png'
import DeleteIcon from '../../../../Images/delete.svg'
import ModalClose from '../../../../Images/modal-close.png'
import Missing from '../../../../Images/missing.png'
import Asterisk from '../../../../Images/asterisk.png'
import Add from '../../../../Images/add.svg'
import Edit from "../../../../Images/edit.png"
import Brush from "../../../../Images/brush.svg"
import AionIcon from '../../../../Images/aion-bank-icon.png'

const { Option } = Select

const dateFormat = 'MM/DD/YYYY'

const skeletonStyle = { width: 180, height: 15, marginTop: 2 }

const fieldsToCompare = [
    'amount', 'balance', 'billingAddress', 'customerId', 'customerName', 'discount',
    'docNumber', 'dueDate', 'lineItems', 'memo', 'ponumber', 'shippingAddress',
    'shipTrackingNumber', 'shipVia', 'subTotal', 'taxes', 'templateName', 'terms',
    'termsId', 'totalAmt', 'txnDate'
];

class Index extends Component {

    constructor(props) {
        super(props)

        const invoice = {}
        var fileList = []

        var showShippingInfo = false

        if (this.props.location.state && this.props.location.state.invoice) {
            fileList = this.props.location.state.invoice.attachments
            fileList.forEach((item, index) => {
                item.uid = index
                item.name = item.fileName
                item.url = item.uri
            })
            this.props.location.state.invoice.txnDate = moment.utc(moment().format('MM/DD/YYYY')).utcOffset(0)

            const { shipVia, shipDate, shipTrackingNumber } = this.props.location.state.invoice

            showShippingInfo = shipVia || shipDate || shipTrackingNumber

            console.log("/ive/invoice/getInvoiceEmailBodyText", this.props.location.state.invoice)
        }

        this.customer = this.props.location.state && this.props.location.state.customer

        if (this.customer) {
            console.log("CreateInvoice this.customer", this.customer)
            invoice.customerId = this.customer.customerId
            invoice.customerName = this.customer.displayName
            invoice.billingAddress = this.customer.billingAddress
            invoice.shippingAddress = this.customer.shippingAddress
            invoice.ponumber = null
            // this.setState({
            //     invoice, selectedCustomer, loadingPurchaseOrders: true, toEmails: selectedCustomer.invoiceRecipientEmail || [],
            //     templates, selectedTemplate: templates.length > 0 ? templates[0] : null
            // }, () => this.setState({ billingAddressLoading: false, shippingAddressLoading: false }))
            // this.handlePaymentTermsSelect(selectedCustomer.termId)
            // this.fetchCustomerPO(selectedCustomer)
            invoice.lineItems = [...(invoice.lineItems || []), { itemId: 0, lineNum: 0, unsavedItem: true }]
        }

        const pathname = this.props.location.pathname;
        const segments = pathname.split('/');
        const lastSegment = segments.pop() || segments.pop();

        this.state = {
            loading: false,
            invoice: this.props.location.state && this.props.location.state.invoice || invoice,
            lastSavedInvoice: cloneDeep(this.props.location.state && this.props.location.state.invoice || invoice),
            paymentTermsItems: defaultPaymentTermObjects,
            scheduleTypeItems: ["Once", "Recurring"],
            schedulePeriodTypeItems: ["Weekly", "BiWeekly", "Monthly", "Quarterly"],
            showScheduleInfo: (this.props.invoice || {}).scheduleId,
            selectedTab: "email",
            toEmails: this.getToEmails(this.customer),
            viewDetails: false,
            ccEmails: [],
            step: 0,
            steps: ['Invoice Details', 'Send Invoice'],
            fileList: fileList,
            emailTone: 'Formal',
            animate: false,
            selectedCustomer: this.customer && this.customer,
            selectTemplateOpen: false,
            editorState: EditorState.createEmpty(),
            showVoidModal: lastSegment === 'revise',
            revise: lastSegment === 'revise',
            voidedInvoiceNumber: this.props.location.state?.invoice?.docNumber,
            showShippingInfo,
        }

        this.nameRef = React.createRef()
        this.termsRef = React.createRef()
        this.invoiceDateRef = React.createRef()
        this.dueDateRef = React.createRef()
    }

    componentDidMount() {
        const { revise } = this.state
        const businessInfo = this.props.aionStore?.business?.businessProfile?.businessInfo;
        this.setState({ logoUrl: businessInfo.logoUrl, animate: true })
        if (!(this.props.location.state && this.props.location.state.invoice)) this.generateTemporaryDocNumber()
        else this.setState({ subject: `${businessInfo.name}: Invoice # ${this.props.location.state.invoice.docNumber}` })

        if (revise) {
            this.generateTemporaryDocNumber()
        }

        this.fetchTemplates()
        this.fetchTerms()

        if (!this.props.location.state?.customer) {
            this.fetchCustomers({
                pagination: {
                    current: 1,
                    pageSize: 2000,
                }
            })
        }
        this.fetchProducts({
            pagination: {
                current: 1,
                pageSize: 500,
            }
        })

        if (this.props.location.state?.invoice) {
            this.getCustomer(this.state.invoice.customerId)
        }

        if ((this.state.invoice.lineItems || []).length === 0) this.addLineItemRow();

        if (this.customer) {
            this.handlePaymentTermsSelect(this.customer.paymentTerms)
            this.fetchCustomerPO(this.customer)
            this.getMSAForCustomer(this.customer)
        }

        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    generateTemporaryDocNumber() {
        const { revise } = this.state
        const businessInfo = this.props.aionStore?.business?.businessProfile?.businessInfo;
        this.setState({ loadingNewInvoice: true })

        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/generateTemporaryDocNumber`, {}, {}, (err, resp) => {
            try {
                const data = resp
                console.log("POST success /ive/invoice/generateTemporaryDocNumber", data)
                if (data.result) {
                    const { invoice } = this.state
                    invoice.docNumber = data.generatedDocNumber
                    this.setState({ invoice, subject: `${businessInfo.name}: Invoice # ${invoice.docNumber}`, lastSavedInvoice: revise ? null : cloneDeep(invoice) })
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("Err /ive/invoice/generateTemporaryDocNumber", error, err, resp)
                ErrorAlert({ description: error.message })
            } finally {
                this.setState({ loadingNewInvoice: false })
            }
        })
    }

    fetchCustomers(options) {
        const { pagination } = options
        // Fetch customer list
        const body = {
            "size": pagination.pageSize,
            "page": pagination.current - 1
        }
        this.setState({ loadingCustomers: true })
        apiPOSTReq(`${environment.iveBaseUrl}/ive/bc/getActiveCustomers`, {}, body, (err, resp) => {
            try {
                this.setState({ loadingCustomers: false })
                const data = resp
                console.log("/getActiveCustomers", data)
                console.log("CreateCustomer nextStep customer:", data)
                if (data.result) {

                    this.setState({ activeCustomers: data.customers })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getActiveCustomers", error.stack, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    getCustomer(customerId) {
        const body = {
            customerId
        }
        console.log("CreateInvoice invoice /getCustomer body", body)

        this.setState({ loadingCustomers: true })
        apiPOSTReq(`${environment.iveBaseUrl}/ive/bc/getCustomer`, {}, body, (err, resp) => {
            try {
                this.setState({ loadingCustomers: false })
                const data = resp
                console.log("CreateInvoice invoice /getCustomer", data)
                if (data.result) {
                    var selectedCustomer = data.customer

                    if (selectedCustomer) {
                        this.setState({ toEmails: this.getToEmails(selectedCustomer), selectedCustomer })

                        this.fetchCustomerPO(selectedCustomer);
                    }
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getActiveCustomers", error.stack, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    fetchProducts(options, productId) {
        const { pagination } = options
        // Fetch product list
        const body = {
            "size": pagination.pageSize,
            "page": pagination.current - 1
        }
        this.setState({ loadingProducts: true })
        apiPOSTReq(`${environment.iveBaseUrl}/ive/product/getActiveProducts`, {}, body, (err, resp) => {
            try {
                this.setState({ loadingProducts: false })
                const data = resp
                console.log("/getActiveProducts", data)
                if (data.result) {
                    if (productId) {
                        const { addNewProductItemId } = this.state
                        this.setState({ activeProducts: data.products }, () => {
                            this.handleLineItemProductSelection(productId, addNewProductItemId)
                        })
                    } else this.setState({ activeProducts: data.products })
                }
                else throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
            } catch (error) {
                console.log("ERRR activeProducts", error, err, resp)
            }
        })
    }

    fetchCustomerPO = (selectedCustomer) => {
        const body = {
            "BusinessId": this.props.aionStore.BusinessUniqueKey,
            "CustomerId": selectedCustomer.customerId,
        }

        apiPOSTReq(`${environment.iveBaseUrl}/ive/po/getPOsForCustomer`, this.iveHeaders, body, (err, resp) => {
            try {
                const data = resp
                console.log("CreateInvoice handleSelectPurchaseOrder /ive/po/getApprovedPOsForCustomer", (data))
                if (data.result) {
                    this.setState({ loadingPurchaseOrders: false, purchaseOrders: data.pos })
                } else {
                    throw Error("Could not fetch purchase orders.")
                }
            } catch (error) {
                console.log("ERRR", error.stack)
            }
        })
    }

    handleSelectPurchaseOrder = (value, option) => {
        var { invoice, purchaseOrders, fileList } = this.state
        invoice.ponumber = value
        this.setState({ invoice })

        const purchaseOrder = purchaseOrders.find(po => po.ponumber === value)

        console.log("CreateInvoice handleSelectPurchaseOrder: ", purchaseOrder)

        if (purchaseOrder) {
            var { documentUrl } = purchaseOrder

            invoice.attachments = (invoice.attachments || []).filter(attachment => attachment.type !== 'PO');
            fileList = (fileList || []).filter(file => file.type !== 'PO');

            documentUrl.name = getFilenameFromUrl(documentUrl.uri)
            documentUrl.fileName = documentUrl.name
            documentUrl.uid = invoice.attachments.length
            documentUrl.type = "PO"
            documentUrl.url = documentUrl.uri

            invoice.attachments.push(documentUrl)
            fileList.push(documentUrl)

            this.setState({ invoice, fileList })

            // getPreSignedS3Url({ url: documentUrl.uri }, (err, preSignedUrl) => {
            //     documentUrl.url = preSignedUrl || ""
            //     fileList.push(documentUrl)
            //     this.setState({ fileList })
            // })
        }
    }

    fetchTerms() {
        apiPOSTReq(`${environment.iveBaseUrl}/ive/term/getTerms`, {}, {}, (err, resp) => {
            try {
                const data = resp || {};
                console.log("/ive/term/getTerms", data)
                if (data.result) {
                    const combinedTerms = [...data.termList];

                    defaultPaymentTermObjects.forEach(newTerm => {
                        const exists = combinedTerms.some(termDetail => termDetail.name === newTerm.name);
                        if (!exists) {
                            combinedTerms.push(newTerm);
                        }
                    });

                    this.setState({ paymentTermsItems: combinedTerms })
                } else {
                    this.setState({ paymentTermsItems: defaultPaymentTermObjects })
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                this.setState({ paymentTermsItems: defaultPaymentTermObjects })
                console.log("ERRR getTerms", error, resp);
                ErrorAlert({ description: error.message })
            }
        })
    }

    fetchTemplates() {
        this.setState({ loadingTemplates: true })
        apiPOSTReq(`${environment.iveBaseUrl}/ive/templates/getActiveTemplates`, {}, {}, (err, resp) => {
            try {
                const data = resp || {}
                console.log("/ive/templates/getActiveTemplates", data)
                if (data.result) {
                    var { templates } = data
                    templates.sort((a, b) => (a.default ? -1 : b.default ? 1 : 0));

                    var selectedTemplate

                    if (this.props.location.state && this.props.location.state.invoice) {
                        selectedTemplate = templates.find(template => template.templateName === this.props.location.state.invoice.templateName)
                    }

                    if (!selectedTemplate) selectedTemplate = templates.find(template => template.default)

                    if (this.customer) {
                        templates = this.sortTemplates(templates, this.customer.displayName)
                        selectedTemplate = templates[0]
                    }

                    var { sections } = (selectedTemplate || {})
                    sections = sections || []

                    var lineItemHeaderSection = sections.find(item => (item.sectionName === "LineItemHeader")) || {}

                    var Accounts = getBPAccountsWithAccess("Payments", { permType: "manage", getActiveAccounts: true })
                    var account = Accounts.find(a => a.accountNumber === selectedTemplate?.accountNumber) || Accounts[0]

                    this.setState({
                        templates, selectedTemplate, lineItemHeaderSectionFields: lineItemHeaderSection.sectionFields,
                        onlinePaymentEnabled: selectedTemplate?.onlinePaymentEnabled,
                        fromAccount: account,
                    })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getActiveTemplates", error, resp)
                ErrorAlert({ description: error.message })
            } finally {
                this.setState({ loadingTemplates: false })
            }
        })
    }

    getMSAForCustomer = (customer) => {
        const body = {
            "BusinessId": this.props.aionStore.BusinessUniqueKey,
            "CustomerId": customer.customerId,
        };
        console.log("getMSAForCustomer", body);

        apiPOSTReq(`${environment.iveBaseUrl}/ive/msa/getMSAForCustomer`, {}, body, (err, resp) => {
            try {
                const data = resp || {}
                if (data.result) {
                    console.log("CustomerDetails getMSAForCustomer data", data);
                    this.setState({
                        msas: data.msas
                    });
                } else {
                    throw Error("Could not getActiveMSAs.");
                }

            } catch (error) {
                console.log("ERRR getActiveTemplates", error, resp)
                ErrorAlert({ description: error.message })
            } finally {
                this.setState({ loadingTemplates: false })
            }
        })
    };

    handleCustomerSelect = (value, option) => {
        if (value === 'addNewCardholder') return
        this.setState({ errorField: '', errorMessage: '', billingAddressLoading: true, shippingAddressLoading: true })
        var { invoice, activeCustomers, templates } = this.state
        templates = this.sortTemplates(templates, option.name)

        var selectedTemplate = templates.length > 0 ? templates[0] : null
        var { sections } = (selectedTemplate || {})
        sections = sections || []
        var lineItemHeaderSection = sections.find(item => (item.sectionName === "LineItemHeader")) || {}

        var Accounts = getBPAccountsWithAccess("Payments", { permType: "manage", getActiveAccounts: true })
        var account = Accounts.find(a => a.accountNumber === selectedTemplate?.accountNumber) || Accounts[0]

        const selectedCustomer = (activeCustomers || []).find(customer => (customer.customerId == value))
        if (selectedCustomer) {
            console.log("CreateInvoice selectedCustomer", selectedCustomer)
            invoice.customerId = value
            invoice.customerName = option.name
            invoice.billingAddress = selectedCustomer.billingAddress
            invoice.shippingAddress = selectedCustomer.shippingAddress
            invoice.ponumber = null
            this.setState({
                invoice, selectedCustomer, loadingPurchaseOrders: true,
                toEmails: this.getToEmails(selectedCustomer),
                templates, selectedTemplate, lineItemHeaderSectionFields: lineItemHeaderSection.sectionFields,
                onlinePaymentEnabled: selectedTemplate?.onlinePaymentEnabled, fromAccount: account,
            }, () => this.setState({ billingAddressLoading: false, shippingAddressLoading: false }))
            this.handlePaymentTermsSelect(selectedCustomer.paymentTerms)
            this.fetchCustomerPO(selectedCustomer)
            this.getMSAForCustomer(selectedCustomer)
        }
    }

    sortTemplates = (templates, customerName) => {
        return (templates || []).sort((a, b) => {
            if (a.customerName === customerName) return -1;
            if (b.customerName === customerName) return 1;
            if (a.default) return -1;
            if (b.default) return 1;
            return 0;
        })
    }

    handeTemplateSelect = (value) => {
        const { invoice, templates } = this.state
        invoice.templateName = value
        const selectedTemplate = templates.find(template => template.templateName === value)
        var { sections } = (selectedTemplate || {})
        sections = sections || []
        var lineItemHeaderSection = sections.find(item => (item.sectionName === "LineItemHeader")) || {}

        var Accounts = getBPAccountsWithAccess("Payments", { permType: "manage", getActiveAccounts: true })
        var account = Accounts.find(a => a.accountNumber === selectedTemplate?.accountNumber) || Accounts[0]

        this.setState({
            selectedTemplate, invoice, lineItemHeaderSectionFields: lineItemHeaderSection.sectionFields,
            onlinePaymentEnabled: selectedTemplate?.onlinePaymentEnabled,
            fromAccount: account,
        })
    }

    handlePaymentTermsSelect = (value, option) => {
        var { invoice } = this.state

        const term = this.state.paymentTermsItems.find(x => x.name === value)
        if (term) {
            invoice.terms = term.name
            invoice.termsId = term.termId
            value = term.dueDays
        } else {
            invoice.terms = value
            invoice.termsId = value
        }

        this.setState({ errorField: '', errorMessage: "" });

        var netDays = toNumber(value)
        if (!invoice.txnDate) invoice.txnDate = moment.utc(moment().format('MM/DD/YYYY')).utcOffset(0)
        invoice.dueDate = moment.utc(invoice.txnDate).add(netDays, 'days')
        this.setState({ invoice })
    }

    handleTextChange = (event) => {
        var val
        switch (event.target.id) {
            case "paymentTermsCustom":
                val = `Net ${event.target.value}`
                break
            default:
                val = event.target.value
                break
        }
        this.saveUserInput(event.target.id, val)
    }

    handleCurrencyChange = (e) => {
        let input = e.target.value;

        // Remove any non-numeric characters except the decimal point
        input = input.replace(/[^\d.]/g, '');

        // Parse to float and fix to two decimal places, then convert back to string
        // This will remove any extra decimal points and format as a number
        const formattedInput = (parseFloat(input).toFixed(2)).toString();

        // Only update if the input is a number (including empty string for backspace)
        if (!isNaN(formattedInput)) {
            this.saveUserInput(e.target.id, formattedInput)
        }
    };

    saveUserInput = (id, dataToSave) => {
        const businessInfo = this.props.aionStore?.business?.businessProfile?.businessInfo;
        console.log("CreateInvoice saveUserInput", id)
        var { invoice } = this.state
        invoice[id] = dataToSave
        this.setState({ invoice }, () => { if (id === "discount" || id === "taxes") this.updateTotals() })
        if(id === "docNumber") this.setState({ subject: `${businessInfo.name}: Invoice # ${dataToSave}` }) 
    }

    handleLineItemTextChange = (event, key) => {
        const { id, value } = event.target;
        const { invoice } = this.state;
        const lineItems = invoice.lineItems || [];
        const selectedItem = lineItems[key];

        if (selectedItem) {
            selectedItem[id] = value;

            if (["qty", "unitPrice", "discount"].includes(id)) {
                selectedItem.amount = (selectedItem.qty * selectedItem.unitPrice) - (selectedItem.discount || 0);
            }

            if (id === "amount") {
                selectedItem.unitPrice = selectedItem.amount / selectedItem.qty;
                selectedItem.discount = 0;
            }
        } else {
            lineItems.push({
                "itemId": key,
                [id]: value
            });
        }

        this.setState({ invoice });
        this.updateTotals();
    }

    getBillingLocation = (address, formattedAddress) => {
        var { invoice } = this.state
        invoice.billingAddress = address || {}
        this.setState({ invoice })
    }

    getShippingLocation = (address, formattedAddress) => {
        var { invoice } = this.state
        invoice.shippingAddress = address || {}
        this.setState({ invoice })
    }

    getSection = (sectionName) => {
        var { selectedTemplate } = this.state
        var { sections } = (selectedTemplate || {})
        sections = sections || []
        return sections.find(item => (item.sectionName === sectionName)) || {}
    }

    getSectionField = (sectionName, fieldName) => {
        var { selectedTemplate } = this.state
        var { sections } = (selectedTemplate || {})
        sections = sections || []
        const section = sections.find(item => (item.sectionName === sectionName)) || {}
        const sectionFields = section.sectionFields || []
        return sectionFields.find(item => (item.fieldName === fieldName)) || {}
    }

    addLineItemRow = () => {
        var { invoice } = this.state;
        const itemId = invoice.lineItems?.length || 0;
        invoice = {
            ...invoice,
            lineItems: [...(invoice.lineItems || []), { itemId, lineNum: itemId, unsavedItem: true }],
        }
        this.setState({ invoice }, this.updateTotals);
    }

    removeLineItemRow = (id) => {
        const { invoice } = this.state;
        invoice.lineItems = (invoice.lineItems || []).filter((_, index) => index !== id);
        this.setState({ invoice }, this.updateTotals);
    }

    handleLineItemProductSelection = (value, key) => {
        console.log("CreateInvoice handleLineItemProductSelection", value, key)
        const { invoice, activeProducts } = this.state;
        const selectedProduct = activeProducts.find(item => item.id === value);
        console.log("CreateInvoice handleLineItemProductSelection selectedProduct", selectedProduct)
        if (!selectedProduct) return;

        const { id, name, description, unitPrice } = selectedProduct;

        const updatedLineItems = (invoice.lineItems || []).map((item, index) => {
            if (index === key) {
                return {
                    ...item,
                    productId: id,
                    productName: name,
                    description,
                    unitPrice,
                    qty: item.qty || 1,
                    amount: (item.qty || 1) * unitPrice,
                    discount: 0
                };
            }
            return item;
        });

        if (updatedLineItems.length === key) {
            console.log("CreateInvoice handleLineItemProductSelection updatedLineItems.length === key", updatedLineItems.length, key)
            updatedLineItems.push({ itemId: key, productId: id, productName: name, description, unitPrice, qty: 1, amount: unitPrice, discount: 0 });
        } else {
            console.log("CreateInvoice handleLineItemProductSelection updatedLineItems.length !== key", updatedLineItems.length, key)
        }

        this.setState({ invoice: { ...invoice, lineItems: updatedLineItems }, addNewProductItemId: null }, this.updateTotals);
    }

    getLineItemTotals = () => {
        const { lineItems = [], discount = 0, taxes = 0 } = this.state.invoice;

        const subTotal = lineItems.reduce((acc, item) => acc + parseFloat(item.amount || 0), 0);
        const parsedDiscount = parseFloat(discount);
        const parsedTaxes = parseFloat(taxes);

        return {
            subTotal,
            totalAmt: subTotal - parsedDiscount + parsedTaxes,
            discount: parsedDiscount,
        };
    }

    updateTotals = () => {
        var { invoice } = this.state
        var totals = this.getLineItemTotals()
        invoice.totalAmt = toNumberStr(totals.totalAmt || 0)
        invoice.balance = toNumberStr(invoice.totalAmt)
        invoice.subTotal = toNumberStr(totals.subTotal || 0)
        invoice.taxes = invoice.taxes || 0
        this.setState({ invoice })
    }

    handleSave = (saveAndPreview, callback, ignoreError) => {
        let { aionStore } = this.props;
        let { invoice, selectedCustomer, selectedTemplate, revise, voidedInvoiceNumber, saveAndExitLoading, lastSavedInvoice, onlinePaymentEnabled } = this.state;
        const userInfo = aionStore?.userInfo;
        const createdInvoiceCount = aionStore?.createdInvoiceCount;

        if (!isCodatUser(aionStore) && !revise && lastSavedInvoice && saveAndPreview && isEqual(lastSavedInvoice, invoice, fieldsToCompare)) {
            this.handleFilePreview(invoice)
            return
        }

        if (!selectedCustomer) {
            if (!ignoreError) {
                this.setState({ errorField: 'customerId', errorMessage: "Please select a customer.", showExitModal: false, saveAndExitLoading: false });
                this.nameRef.current.focus()
            }
            return;
        }

        // if (!invoice.termsId) {
        //     if (!ignoreError) {
        //         this.setState({ errorField: 'terms', errorMessage: 'Please select a payment term', showExitModal: false, saveAndExitLoading: false });
        //         this.termsRef.current.focus()
        //     }
        //     return;
        // }

        if (!invoice.lineItems || invoice.lineItems.length === 0) {
            if (!ignoreError) {
                this.setState({ showExitModal: false, saveAndExitLoading: false });
                ErrorAlert({ description: "Please add a product / service" })
            }
            return;
        } else {
            invoice.lineItems.forEach(item => {
                if (!ignoreError && isNaN(item.unitPrice)) ErrorAlert({ description: "Unit price must be a valid number" })
            })
        }

        for (let item of invoice.lineItems) {
            if (item.productId == null) {
                ErrorAlert({ description: "Please choose a product" });
                return;
            }
        }
        // if (!invoice.shippingAddress) {
        //     this.setState({ errorField: 'shippingAddress', showExitModal: false, saveAndExitLoading: false });
        //     return;
        // }

        invoice.lineItems = invoice.lineItems.filter(x => x.productId);
        ['txnDate', 'dueDate', 'shipDate'].forEach(dateField => {
            if (invoice[dateField]) invoice[dateField] = moment(invoice[dateField]).toDate();
        });

        const body = {
            Invoice: { ...invoice },
            docTemplateName: selectedTemplate && selectedTemplate.templateName,
            modifiedByUserName: `${userInfo.firstName} ${userInfo.lastName}`,
            onlinePaymentEnabled: onlinePaymentEnabled,
        };

        if (revise) {
            body.Invoice.id = null
            body.Invoice.invoiceId = null
            body.Invoice.invoiceStatus = null
            body.Invoice.emailStatus = null
            body.Invoice.attachments = []
            body.revisedInvoice = true
            body.voidedInvoiceNumber = voidedInvoiceNumber
            body.Invoice.metaData = null
        }

        this.setState({
            loadingSave: !saveAndPreview,
            loadingPreview: saveAndPreview,
            errorField: '',
            errorMessage: '',
        });

        console.log('CreateInvoice ive/invoice/save body', body)

        const saveCallback = () => {
            apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/save`, {}, body, (err, resp) => {
                this.setState({ loadingSave: false, loadingPreview: false });

                try {
                    console.log('CreateInvoice ive/invoice/save', resp)
                    if (resp?.result) {
                        this.props.dispatch(addDataToStore(CODAT_DATA, { createdInvoiceCount: (createdInvoiceCount || 0) + 1 }))
                        this.setState({ invoice: resp.invoice, lastSavedInvoice: cloneDeep(resp.invoice) });
                        if (callback) {
                            callback()
                        } else {
                            message.success(`Invoice saved`);
                            if (resp.responseMessage && resp.responseMessage !== "Success") {
                                ErrorAlert({ description: resp.responseMessage });
                            }
                            if (saveAndPreview) this.handleFilePreview(resp.invoice);
                            if (saveAndExitLoading) window.history.back();
                        }
                    } else {
                        if (callback) {
                            callback()
                        } else {
                            const errorMsg = resp?.responseMessage || resp?.error || "Unknown error";
                            ErrorAlert({ description: errorMsg });
                        }
                    }
                } catch (error) {
                    console.log("ive/invoice/save", error, err, resp)
                    ErrorAlert({ description: error.message })
                } finally {
                    this.setState({ saveAndExitLoading: false })
                }
            });
        }

        // if (revise) {
        //     this.voidInvoice(saveCallback)
        // } else {
        saveCallback()
        // }
    }

    voidInvoice = () => {
        const userInfo = this.props.aionStore?.userInfo;
        let { invoice } = this.state;

        var body = {
            invoiceId: invoice.invoiceId,
            modifiedByUserName: `${userInfo.firstName} ${userInfo.lastName}`,
        }

        console.log("Invoices invoice/voidInvoice body", invoice)

        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/voidInvoice`, null, body, (err, resp) => {
            try {
                const data = resp || {}
                console.log("Invoices invoice/voidInvoice", data)
                // callback()
                message.success(`Invoice voided`);
            } catch (error) {
                console.log("ERRR voidInvoice", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    handleFilePreview = (invoice) => {
        var { selectedTemplate } = this.state

        const body = {
            InvoiceId: invoice.invoiceId,
            docTemplateName: selectedTemplate && selectedTemplate.templateName,
        }

        console.log("/ive/invoice/getInvoiceEmailBodyText", JSON.stringify(body))

        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/getInvoiceEmailBodyText`, {}, body, (err, resp) => {
            try {
                const data = resp
                console.log("POST success /ive/invoice/getInvoiceEmailBodyText", JSON.stringify(data))
                if (data.result) {
                    var invoiceDoc = (invoice.attachments || []).find(item => item.type == "InvoiceDoc") || {}
                    getPreSignedS3Url({ url: invoiceDoc.uri }, (err, preSignedUrl) => {
                        invoiceDoc.preSignedUrl = preSignedUrl || ""
                        this.setState({
                            invoice: invoice,
                            showPreview: true,
                            htmlMailtext: data.htmlMailtext,
                            invoiceEmailCustomBody: data.invoiceEmailCustomBody,
                            editorState: EditorState.createWithContent(stateFromHTML(data.invoiceEmailCustomBody)),
                            previewUrl: preSignedUrl || "",
                        })
                    })
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("ive/invoice/getInvoiceEmailBodyText", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    createInvoiceTemplate = () => {
        this.setState({ loadingNew: true })
        apiPOSTReq(`${environment.iveBaseUrl}/ive/templates/createInvoiceTemplate`, {}, { templateType: "INVOICE", templateName: `Template ${(this.state.templates || []).length + 1} ${moment().format('ll')}` }, (err, resp) => {
            this.setState({ loadingNew: false })
            try {
                const data = resp || {}
                console.log("/ive/templates/createInvoiceTemplate", data)
                if (data.result) {
                    this.setState({
                        newTemplate: true,
                        templateMetadata: data.template,
                        showEditTemplateModal: true
                    })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR createInvoiceTemplate", error, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    getCustomerOptions = () => {
        const { activeCustomers } = this.state

        const customerOptions = (activeCustomers || []).map(item => (
            <Select.Option key={item.customerId} id="customerName" name={item.displayName || item.companyName} value={item.customerId} style={{ backgroundColor: "transparent" }}>
                {item.displayName || item.companyName}
            </Select.Option>
        ))

        return [...customerOptions];
    }

    getTemplateOptions = () => {
        const { templates, selectedCustomer } = this.state

        const templateOptions = [];

        (templates || []).forEach(item => {
            if (!item.customerId || (selectedCustomer && selectedCustomer.customerId === item.customerId)) {
                templateOptions.push(<Select.Option key={item.templateName} id={item.templateName} value={item.templateName} style={{ backgroundColor: "transparent" }}>
                    {item.templateName}
                </Select.Option>)
            }
        })

        return [...templateOptions];
    }

    getProductOptions = () => {
        const { activeProducts } = this.state

        const productOptions = (activeProducts || []).map(item => (
            <Select.Option key={item.id} id="productId" name={item.name} value={item.id} style={{ backgroundColor: "transparent" }}>
                {item.name}
            </Select.Option>
        ))

        return [...productOptions];
    }

    handleScroll = () => {
        // Close the dropdown when scrolling occurs
        this.setState({ selectTemplateOpen: false });
    }

    handleDropdownVisibleChange = (visible) => {
        // Toggle dropdown visibility state
        this.setState({ selectTemplateOpen: visible });
    }

    saveTemporaryTemplate = (template) => {
        var body = {
            "template": template
        }
        this.setState({ loading: true })
        console.log("/ive/templates/save body", body)
        apiPOSTReq(`${environment.iveBaseUrl}/ive/templates/save`, {}, body, (err, resp) => {
            try {
                const data = resp || {}
                console.log("/ive/templates/save", data)
                if (data.result) {
                    this.setState({ showEditTemplateModal: false, newTemplate: false, saveTemporaryTemplate: false })
                    this.fetchTemplates()
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /ive/templates/save", error, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    getToEmails = (customer) => {
        if (!customer) return []

        if (customer.invoiceRecipientEmail && customer.invoiceRecipientEmail.length > 0) {
            return customer.invoiceRecipientEmail;
        } else if (customer.primaryEmailAddress && customer.primaryEmailAddress.address) {
            return [customer.primaryEmailAddress.address];
        } else {
            return [];
        }
    }

    handleSelectAccount = (value, option) => {
        var Accounts = getBPAccountsWithAccess("Payments", { permType: "manage", getActiveAccounts: true })
        var account = Accounts.find(a => a.accountId === value)
        console.log("EditTemplate /ive/templates/save handleSelectAccount", account)
        this.setState({ fromAccount: account })
    }

    render() {
        const { theme, aionStore } = this.props
        const { loading, loadingSave, loadingCustomers, loadingTemplates, loadingProducts, loadingPurchaseOrders, loadingPreview, selectTemplateOpen, showAddCustomer, voidedInvoiceNumber, paymentDetailsOpen,
            billingAddressLoading, shippingAddressLoading, editorState, showNewProductModal, logoUrl, invoice, selectedCustomer, lineItemHeaderSectionFields, toEmails, showSentModal, showExitTemplateModal,
            saveAndExitLoading, selectedTemplate, showScheduleInfo, paymentTermsItems, purchaseOrders, showExitModal, showEditTemplateModal, subject, animate, showVoidModal, msas, showShippingInfo, fromAccount,
            editTemplate, templateMetadata, newTemplate, saveTemporaryTemplate, showPreview, ccEmails, lastSavedInvoice, previewUrl, revise, showAddDocument, activeCustomers, showExitCustomerModal, onlinePaymentEnabled } = this.state
        var { customerName, txnDate, dueDate, terms, shipDate, lineItems, discount, taxes, billingAddress, shippingAddress, memo, docNumber, ponumber, shipVia, shipDate, shipTrackingNumber } = invoice
        var { templates, errorMessage, errorField, fileList } = this.state
        var { connections, createdInvoiceCount } = aionStore

        const businessInfo = this.props.aionStore?.business?.businessProfile?.businessInfo;
        const primaryApplicantInfo = this.props.aionStore?.business?.businessProfile?.primaryApplicantInfo;
        lineItems = lineItems || []

        const lineItemTotals = this.getLineItemTotals()

        if (txnDate && !isMoment(txnDate)) {
            txnDate = new Date(txnDate).toISOString()
            txnDate = txnDate.split("T")[0]
        }
        txnDate = txnDate && moment(txnDate)
        if (dueDate && !isMoment(dueDate)) {
            dueDate = new Date(dueDate).toISOString()
            dueDate = dueDate.split("T")[0]
        }
        dueDate = dueDate && moment(dueDate)

        if (shipDate) shipDate = moment(shipDate)

        var customerOptions = this.getCustomerOptions()
        var productOptions = this.getProductOptions()

        var Accounts = getBPAccountsWithAccess("Payments", { permType: "manage", getActiveAccounts: true })
        var options = []
        var filteredAccounts = Accounts?.filter(item => { return (!['ACCOUNTS_RECEIVABLE', 'Transitional'].includes(item.accountType)) }) || []
        filteredAccounts.forEach(account => {
            options.push(
                <Option key={account.accountId} value={account.accountId} style={{ backgroundColor: "transparent" }}>
                    <FromAccountOption account={account} />
                </Option>)
        })

        const showPayByBank = getFeaturePerm("Receivables.Invoices.PayByBank").view

        return (
            <FlexColumn start className='main-padding' fullWidth fullHeightstyle={{ paddingBottom: 24 }}>
                <PageHeader
                    titleText={`${revise ? 'Revise' : this.props.location.state?.invoice ? 'Edit' : 'Create'} invoice`}
                    marginBottom={24}
                    close
                    onClose={() => {
                        console.log("CreateInvoice lastSavedInvoice:", invoice, lastSavedInvoice)
                        if (!isEqual(lastSavedInvoice, invoice, fieldsToCompare)) {
                            this.setState({ showExitModal: true })
                        } else {
                            window.history.back()
                        }
                    }}
                    fullpage
                />

                <Flex between fullWidth style={{ marginBottom: 48 }} gap='24px'>
                    <FlexColumn className={animate ? 'slide-up-animation create-invoice-container' : 'create-invoice-container'} style={{ marginTop: 24 }}>
                        <Skeleton loading={false} active title={false} paragraph={{ rows: 9 }}>
                            <Flex between fullWidth>
                                {/* <Flex between> */}

                                <FlexColumn>
                                    <Text heading>{this.getSectionField("CompanyInformation", "Name").fieldValue || businessInfo.name}</Text>
                                    <Text color={'#666'}>{addressObjectToMultiLineStr(businessInfo.mailingAddress)}</Text>
                                </FlexColumn>

                                {
                                    logoUrl &&
                                    <FlexColumn center style={{ height: 88, marginRight: 24 }}>
                                        <img src={logoUrl} style={{ height: '48px' }} />
                                    </FlexColumn>
                                }
                            </Flex>

                            {
                                selectedTemplate && (this.getSectionField("CompanyInformation", "Phone").visible || this.getSectionField("CompanyInformation", "Email").visible || this.getSectionField("CompanyInformation", "Website").visible) &&
                                <Flex start centerHorizontally gap='8px' fullWidth>
                                    {
                                        (this.getSectionField("CompanyInformation", "Phone").visible && this.getSectionField("CompanyInformation", "Phone").fieldValue) &&
                                        <>
                                            <Image src={Call} />
                                            <Text>{this.getSectionField("CompanyInformation", "Phone").fieldValue}</Text>
                                        </>
                                    }
                                    {
                                        (this.getSectionField("CompanyInformation", "Email").visible && primaryApplicantInfo?.email) &&
                                        <>
                                            {
                                                (this.getSectionField("CompanyInformation", "Phone").visible && businessInfo.phone) &&
                                                <Image src={Divider} />
                                            }
                                            <Image src={Email} />
                                            <Text>{this.getSectionField("CompanyInformation", "Email").fieldValue}</Text>
                                        </>
                                    }
                                    {
                                        (this.getSectionField("CompanyInformation", "Website").visible && businessInfo.urls && businessInfo.urls.length > 0) &&
                                        <>
                                            {
                                                ((this.getSectionField("CompanyInformation", "Phone").visible && businessInfo.phone) ||
                                                    (this.getSectionField("CompanyInformation", "Email").visible && this.getSectionField("CompanyInformation", "Email").fieldValue)) &&
                                                <Image src={Divider} />
                                            }
                                            <Text underline primary><a href={businessInfo.urls[0]} target="_blank">{this.getSectionField("CompanyInformation", "Website").fieldValue || businessInfo.urls[0]}</a></Text>
                                        </>
                                    }
                                </Flex>
                            }

                            <div style={{ width: '100%', height: 1, background: '#E3E6EE', margin: '4px 0' }}></div>

                            {/* <Flex between gap="24px" fullWidth>
                                <Flex start gap="24px" grow>
                                    <Flex start centerHorizontally gap="4px" style={{ width: '50%' }}>
                                        <Text heading style={{ marginRight: 4 }}>Invoice No.</Text>
                                        <LabeledInput
                                            id="docNumber"
                                            key="docNumber"
                                            className="no-left-padding"
                                            placeholder="Invoice No."
                                            value={docNumber}
                                            onChange={this.handleTextChange}
                                        />
                                    </Flex>
                                </Flex>
                                <div style={{ marginLeft: '24px', width: '300px', minWidth: '264px' }}></div>
                            </Flex> */}
                            {/* 
                            <Flex between gap="0" fullWidth>

                                <div style={{ marginLeft: '24px', width: '300px', minWidth: '264px', padding: '0 24px' }}></div>
                            </Flex> */}

                            {
                                true &&
                                <>
                                    <Flex between fullWidth>
                                        <FlexColumn start gap="24px" grow>

                                            <Flex start gap="24px" grow>
                                                <div style={{ width: '50%' }}>
                                                    <LabeledInput
                                                        label="Invoice No."
                                                        id="docNumber"
                                                        key="docNumber"
                                                        className="no-left-padding"
                                                        placeholder="Invoice No."
                                                        value={docNumber}
                                                        onChange={this.handleTextChange}
                                                        minHeight='42px'
                                                        nomargin
                                                    />
                                                </div>
                                                <div style={{ width: '50%' }}>
                                                    <LabeledInput
                                                        inputRef={this.nameRef}
                                                        label="Customer"
                                                        id="customerId"
                                                        key="customerId"
                                                        type={this.customer ? "read-only" : "select"}
                                                        nomargin
                                                        placeholder="Select Customer"
                                                        value={customerName}
                                                        loading={loadingCustomers}
                                                        onChange={this.handleCustomerSelect}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        error={errorField == "customerId"}
                                                        errorMessage={errorMessage}
                                                        filterOption={(input, option) => {
                                                            return option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }}
                                                        width="100%"
                                                        dropdownRender={menu => (
                                                            <FlexColumn start>
                                                                {menu}
                                                                <Flex start style={{ padding: '4px 12px 6px' }}>
                                                                    <TextButton text='ADD NEW' capitalize primary onClick={() => this.setState({ showAddCustomer: true })} rightIcon={<Image src={Add} />} />
                                                                </Flex>
                                                            </FlexColumn>
                                                        )}
                                                    >
                                                        {customerOptions}
                                                    </LabeledInput>
                                                </div>
                                            </Flex>

                                            <Flex start gap="24px" grow>
                                                <FlexColumn start style={{ width: '50%', marginLeft: 0 }} gap="24px">
                                                    {!showScheduleInfo &&
                                                        <LabeledInput
                                                            inputRef={this.invoiceDateRef}
                                                            label="Invoice Date"
                                                            id="txnDate"
                                                            key="txnDate"
                                                            type="date-picker"
                                                            nomargin
                                                            // disabledDate={this.disabledDate}
                                                            placeholder={moment().format(dateFormat)}
                                                            value={txnDate && txnDate}
                                                            format={dateFormat}
                                                            onChange={(date, dateStr) => this.saveUserInput("txnDate", moment.utc(dateStr).utcOffset(0))}
                                                        />
                                                    }
                                                    {!showScheduleInfo &&
                                                        <LabeledInput
                                                            inputRef={this.dueDateRef}
                                                            label="Due Date"
                                                            id="dueDate"
                                                            key="dueDate"
                                                            type="date-picker"
                                                            nomargin
                                                            // disabledDate={this.disabledDate}
                                                            placeholder={moment().add(30, "days").format(dateFormat)}
                                                            value={dueDate && dueDate}
                                                            format={dateFormat}
                                                            onChange={(date, dateStr) => {
                                                                this.handlePaymentTermsSelect("Custom")
                                                                this.saveUserInput("dueDate", moment.utc(dateStr).utcOffset(0))
                                                            }}
                                                        />
                                                    }
                                                </FlexColumn>

                                                <FlexColumn start style={{ width: '50%', marginLeft: 0 }} gap="24px">
                                                    <LabeledInput
                                                        inputRef={this.termsRef}
                                                        label="Payment Terms"
                                                        id="terms"
                                                        key="terms"
                                                        type="select"
                                                        nomargin
                                                        minHeight="45px"
                                                        placeholder="Select"
                                                        value={terms}
                                                        loading={loadingProducts}
                                                        onChange={this.handlePaymentTermsSelect}
                                                        error={errorField == "terms"}
                                                        errorMessage={errorMessage}
                                                        allowClear
                                                        dropdownRender={menu => (
                                                            <>
                                                                {menu}
                                                            </>
                                                        )}
                                                        unsyncable={isCodatUser(aionStore)}
                                                        accountingSoftware={getCodatPlatformName(aionStore)}
                                                        noAsterisk
                                                        optional
                                                    >
                                                        {paymentTermsItems.map(item => <Option key={item.termId} value={item.name}>{item.name}</Option>)}
                                                    </LabeledInput>

                                                    <LabeledInput
                                                        label="Purchase Order"
                                                        disabled={!purchaseOrders && !msas}
                                                        id="ponumber"
                                                        key="ponumber"
                                                        type="select"
                                                        nomargin
                                                        minHeight="45px"
                                                        placeholder="Select Purchase Order"
                                                        value={ponumber}
                                                        loading={loadingPurchaseOrders}
                                                        onChange={this.handleSelectPurchaseOrder}
                                                        allowClear
                                                        onClear={() => {
                                                            if (invoice?.attachments) {
                                                                invoice.attachments = invoice.attachments.filter(item => item.type !== 'PO');
                                                                fileList = fileList.filter(item => item.type !== 'PO');
                                                                this.setState({ invoice, fileList, ponumber: null })
                                                            }
                                                        }}
                                                        optional
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => {
                                                            return option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }}
                                                        dropdownRender={menu => (
                                                            <FlexColumn start>
                                                                {menu}
                                                                <Flex start style={{ padding: '4px 12px 6px' }}>
                                                                    <TextButton text='ADD NEW' capitalize primary onClick={() => this.setState({ showAddDocument: true })} rightIcon={<Image src={Add} />} />
                                                                </Flex>
                                                            </FlexColumn>
                                                        )}
                                                        unsyncable={isCodatUser(aionStore)}
                                                        accountingSoftware={getCodatPlatformName(aionStore)}
                                                    >
                                                        {(purchaseOrders || []).map(item =>
                                                            <Select.Option key={item.ponumber} id="ponumber" name={item.ponumber} value={item.ponumber} style={{ backgroundColor: "transparent" }}>
                                                                <Flex between fullWidth>
                                                                    {item.name || `#${item.ponumber}` || item.msaid} {item.verificationStatus == "VALIDATED" ? <div style={{ marginRight: "7px" }}> <Tag padding='0 4px' height='28px' style={{ fontSize: "12px" }} primary>APPROVED</Tag> </div> : <></>}
                                                                </Flex>
                                                            </Select.Option>
                                                        )}

                                                    </LabeledInput>
                                                </FlexColumn>
                                            </Flex>

                                            <Flex centerHorizontally gap="24px" fullWidth>
                                                <Skeleton loading={billingAddressLoading} active>
                                                    <LabeledInput
                                                        id="billingAddress"
                                                        key="billingAddress"
                                                        label="Bill to"
                                                        type="location"
                                                        nomargin
                                                        // width="100%"
                                                        getLocation={this.getBillingLocation}
                                                        address={billingAddress}
                                                        optional
                                                        unsyncable={isCodatUser(aionStore)}
                                                        accountingSoftware={getCodatPlatformName(aionStore)}
                                                    />
                                                </Skeleton>


                                                {
                                                    this.getSection("ShipTo").visible &&
                                                    <Skeleton loading={shippingAddressLoading} active>
                                                        <LabeledInput
                                                            id="shippingAddress"
                                                            key="shippingAddress"
                                                            label="Ship to"
                                                            type="location"
                                                            nomargin
                                                            // width="100%"
                                                            getLocation={this.getShippingLocation}
                                                            address={shippingAddress}
                                                            // error={errorField == "shippingAddress"}
                                                            // errorMessage={"This is a mandatory field"}
                                                            optional
                                                            unsyncable={isCodatUser(aionStore)}
                                                            accountingSoftware={getCodatPlatformName(aionStore)}
                                                        />
                                                    </Skeleton>
                                                }
                                            </Flex>

                                            <FlexColumn start>
                                                <Flex start style={{ marginBottom: 8 }}>
                                                    <span style={{ fontSize: "0.9rem", color: theme.colors.systemGray, marginRight: 8 }}>Add tracking information for shipment?</span>
                                                    <Switch size="small" defaultChecked={showShippingInfo} onChange={() => this.setState({ showShippingInfo: !showShippingInfo })} />
                                                </Flex>
                                                {
                                                    showShippingInfo &&
                                                    <Flex style={{ justifyContent: "flex-start" }}>
                                                        <div style={{ width: `${100 / 3}%`, marginRight: 12 }}>
                                                            <LabeledInput
                                                                label="Ship Via"
                                                                labelcolor={theme.colors.secondary3}
                                                                id="shipVia"
                                                                key="shipVia"
                                                                placeholder="E.g. FedEx"
                                                                value={shipVia}
                                                                onChange={this.handleTextChange}
                                                                unsyncable={isCodatUser(aionStore)}
                                                                accountingSoftware={getCodatPlatformName(aionStore)}
                                                                optional
                                                            />
                                                        </div>
                                                        <div style={{ width: `${100 / 3}%`, marginRight: 12, marginLeft: 12 }}>
                                                            <LabeledInput
                                                                label="Ship Date"
                                                                labelcolor={theme.colors.secondary3}
                                                                id="shipDate"
                                                                key="shipDate"
                                                                type="date-picker"
                                                                placeholder={moment().format(dateFormat)}
                                                                value={shipDate && moment(shipDate)}
                                                                format={dateFormat}
                                                                onChange={(date, dateStr) => this.saveUserInput("shipDate", moment.utc(dateStr).utcOffset(0))}
                                                                unsyncable={isCodatUser(aionStore)}
                                                                accountingSoftware={getCodatPlatformName(aionStore)}
                                                                optional
                                                            />
                                                        </div>
                                                        <div style={{ width: `${100 / 3}%`, marginLeft: 12 }}>
                                                            <LabeledInput
                                                                label="Tracking #"
                                                                labelcolor={theme.colors.secondary3}
                                                                id="shipTrackingNumber"
                                                                key="shipTrackingNumber"
                                                                placeholder="Enter tracking number"
                                                                defaultValue={shipTrackingNumber}
                                                                onChange={this.handleTextChange}
                                                                unsyncable={isCodatUser(aionStore)}
                                                                accountingSoftware={getCodatPlatformName(aionStore)}
                                                                optional
                                                            />
                                                        </div>
                                                    </Flex>
                                                }
                                            </FlexColumn>
                                        </FlexColumn>

                                        <FlexColumn start className="contact-container" fullHeight>
                                            <span className="section-heading-text" style={{ marginBottom: 12 }}>CONTACT DETAILS</span>
                                            <Flex start style={{ marginBottom: 12 }} gap='8px' centerHorizontally>
                                                <Image src={Individual} />

                                                {
                                                    selectedCustomer ?
                                                        <Text>{selectedCustomer && selectedCustomer.fullyQualifiedName}</Text>
                                                        :
                                                        <Skeleton.Input style={skeletonStyle} />
                                                }
                                            </Flex>
                                            {
                                                (!selectedCustomer || selectedCustomer?.phone) &&
                                                <Flex start style={{ marginBottom: 12 }} gap='8px' centerHorizontally>
                                                    <Image src={Call} />

                                                    {
                                                        selectedCustomer ?
                                                            <Text>{selectedCustomer && selectedCustomer.phone}</Text>
                                                            :
                                                            <Skeleton.Input style={skeletonStyle} />
                                                    }
                                                </Flex>
                                            }
                                            <Flex start gap='8px' centerHorizontally>
                                                <Image src={Email} />

                                                {
                                                    selectedCustomer ?
                                                        <Text>{selectedCustomer && selectedCustomer.primaryEmailAddress && selectedCustomer.primaryEmailAddress.address}</Text>
                                                        :
                                                        <Skeleton.Input style={skeletonStyle} />
                                                }
                                            </Flex>
                                        </FlexColumn>
                                    </Flex>

                                    <div style={{ width: '100%', height: 1, background: '#E3E6EE', margin: '0' }} />

                                    <span class="section-heading-text">
                                        PRODUCTS / SERVICES
                                        <Image width='5px' height='5px' margin='0 8px 10px 5px' src={Asterisk} />
                                    </span>


                                    <FlexColumn gap="10px" fullWidth>

                                        <LineItemsHeader>
                                            <div style={{ width: 300 }}>
                                                <Text color='#333' weight='600'>{lineItemHeaderSectionFields ? lineItemHeaderSectionFields[0].fieldValue : 'Name'}</Text>
                                            </div>
                                            <div style={{ flexGrow: 1 }}>
                                                <Text color='#333' weight='600'>{lineItemHeaderSectionFields ? lineItemHeaderSectionFields[1].fieldValue : 'Description'}</Text>
                                            </div>
                                            {
                                                this.getSectionField("LineItemHeader", "Unit Price").visible &&
                                                <div style={{ width: 100, display: "flex", justifyContent: "flex-end" }}>
                                                    <Text right color='#333' weight='600'>{lineItemHeaderSectionFields ? lineItemHeaderSectionFields[3].fieldValue : 'Unit Price'}</Text>
                                                </div>
                                            }
                                            {
                                                this.getSectionField("LineItemHeader", "Qty").visible &&
                                                <div style={{ width: 80, display: "flex", justifyContent: "flex-end" }}>
                                                    <Text right color='#333' weight='600'>{lineItemHeaderSectionFields ? lineItemHeaderSectionFields[2].fieldValue : 'Qty'}</Text>
                                                </div>
                                            }
                                            <div style={{ width: 100, display: "flex", justifyContent: "flex-end" }}>
                                                <Text right color='#333' weight='600'>{lineItemHeaderSectionFields ? lineItemHeaderSectionFields[4].fieldValue : 'Amount'}</Text>
                                            </div>
                                            <div style={{ width: 24 }} />
                                        </LineItemsHeader>

                                        {lineItems.map((lineItem, i) => {
                                            var { itemId, productName, description, amount, qty, unitPrice } = lineItem || {}
                                            var lineItemDiscount = (lineItem || {}).discount
                                            // if (amount < 0) amount = amount * -1
                                            var itemId = i;
                                            return (
                                                <LineItemRow key={itemId + "container"}>
                                                    <div style={{ width: 300 }}>
                                                        <LabeledInput
                                                            small
                                                            nomargin
                                                            id="product"
                                                            key={itemId + "product"}
                                                            type="select"
                                                            placeholder="Select a product"
                                                            value={productName}
                                                            onChange={(value) => this.handleLineItemProductSelection(value, itemId)}
                                                            showSearch
                                                            dropdownClassName='product-select'
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => {
                                                                return option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }}
                                                            dropdownRender={menu => (
                                                                <FlexColumn start>
                                                                    {menu}
                                                                    <Flex start style={{ padding: '4px 12px 6px' }}>
                                                                        <TextButton text='ADD NEW' capitalize primary onClick={() => this.setState({ showNewProductModal: true, addNewProductItemId: itemId })} rightIcon={<Image src={Add} />} />
                                                                    </Flex>
                                                                </FlexColumn>
                                                            )}
                                                        >
                                                            {productOptions}
                                                        </LabeledInput>
                                                    </div>
                                                    <div style={{ flexGrow: 1 }}>
                                                        <LabeledInput
                                                            nomargin
                                                            id="description"
                                                            key={itemId + "description"}
                                                            type='text-area'
                                                            autoSize={{ minRows: 1, maxRows: 6 }}
                                                            placeholder="Add text"
                                                            value={(description)}
                                                            onChange={e => this.handleLineItemTextChange(e, itemId)}
                                                            minHeight='42px'
                                                        />
                                                    </div>
                                                    {
                                                        this.getSectionField("LineItemHeader", "Unit Price").visible &&
                                                        <Flex start centerHorizontally gap="8px" style={{ width: 120, paddingTop: 0 }}>
                                                            <Text size='20px'>$</Text>
                                                            <LabeledInput
                                                                small
                                                                nomargin
                                                                id="unitPrice"
                                                                key={itemId + "unitPrice"}
                                                                placeholder="1000"
                                                                value={unitPrice}
                                                                // prefix="$"
                                                                // type="read-only"
                                                                alignRight
                                                                divider={false}
                                                                onChange={e => this.handleLineItemTextChange(e, itemId)}
                                                                minHeight='42px'
                                                            />
                                                        </Flex>
                                                    }
                                                    {
                                                        this.getSectionField("LineItemHeader", "Qty").visible &&
                                                        <div style={{ width: 80, paddingLeft: 4 }}>
                                                            <LabeledInput
                                                                small
                                                                nomargin
                                                                id="qty"
                                                                key={itemId + "qty"}
                                                                placeholder="5"
                                                                value={(qty)}
                                                                onChange={e => this.handleLineItemTextChange(e, itemId)}
                                                                alignRight
                                                                minHeight='42px'
                                                            />
                                                        </div>
                                                    }
                                                    {/* <div style={{ width: "10%" }}>
                                        <LabeledInput
                                            small
                                            nomargin
                                            id="discount"
                                            key={itemId + "discount"}
                                            placeholder="0"
                                            prefix="$"
                                            value={(lineItemDiscount)}
                                            onChange={e => this.handleLineItemTextChange(e, itemId)}
                                        />
                                    </div> */}
                                                    <Flex end style={{ width: 100, paddingTop: 8 }}>
                                                        <LabeledInput
                                                            small
                                                            nomargin
                                                            id="amount"
                                                            key={itemId + "amount"}
                                                            placeholder="5000"
                                                            value={`$ ${toCurrency(amount || 0)}`}
                                                            type="read-only"
                                                            divider={false}
                                                            alignRight
                                                        />
                                                    </Flex>
                                                    <Flex end style={{ width: 24, paddingTop: 8 }}>
                                                        {
                                                            lineItems.length > 1 &&
                                                            <img width='24px' height='24px' style={{ cursor: 'pointer' }} src={DeleteIcon} onClick={() => this.removeLineItemRow(itemId)} />
                                                        }
                                                    </Flex>
                                                </LineItemRow>)
                                        })}
                                    </FlexColumn>

                                    <div style={{ width: 160 }}>
                                        <TextButton onClick={() => this.addLineItemRow()} margin="0" rightIcon={<Image src={Add} />} text="ADD LINE ITEM" />
                                    </div>

                                    <FlexColumn right fullWidth gap='8px' style={{ paddingRight: 48 }}>
                                        <Flex between style={{ width: 264, paddingRight: 14 }} centerHorizontally>
                                            <Text width='80px' right>Subtotal</Text>
                                            <Text right size='20px'>{`$ ${toCurrency(lineItemTotals.subTotal)}`}</Text>
                                        </Flex>
                                        <Flex between style={{ width: 264 }} centerHorizontally>
                                            <Text width='80px' right>Discount</Text>
                                            <Flex start centerHorizontally gap="8px">
                                                <Text size='20px'>$</Text>
                                                <LabeledInput
                                                    id="discount"
                                                    key="discount"
                                                    small
                                                    // prefix="$"
                                                    value={discount}
                                                    defaultValue={'0'}
                                                    onChange={this.handleTextChange}
                                                    alignRight
                                                    nomargin
                                                    width='100px'
                                                />
                                            </Flex>
                                        </Flex>

                                        {
                                            ((taxes && taxes !== '') || !isCodatUser(aionStore)) &&
                                            <Flex between style={{ width: 264 }} centerHorizontally>
                                                <Text width='80px' right>Taxes</Text>
                                                <Flex start centerHorizontally gap="8px">
                                                    <Text size='20px'>$</Text>
                                                    <LabeledInput
                                                        id="taxes"
                                                        key="taxes"
                                                        small
                                                        // style={{ width: "150px", margin: 0 }}
                                                        // prefix="$"
                                                        value={taxes}
                                                        defaultValue={'0'}
                                                        onChange={this.handleTextChange}
                                                        alignRight
                                                        nomargin
                                                        width='100px'
                                                    />
                                                </Flex>
                                            </Flex>
                                        }

                                        <div style={{ width: '100%', height: 1, background: '#F2F2F2', margin: '8px 0' }} />

                                        <Flex between style={{ width: 264, paddingRight: 14 }} centerHorizontally>
                                            <Text width='80px' right weight='600'>Total</Text>
                                            <Text right size='20px'>{`$ ${toCurrency(lineItemTotals.totalAmt)}`}</Text>
                                        </Flex>

                                        <div style={{ width: '100%', height: 1, background: '#F2F2F2', margin: '8px 0' }} />
                                    </FlexColumn>

                                    {
                                        (!onlinePaymentEnabled && this.getSection("PaymentDetails").visible &&
                                            (this.getSectionField("PaymentDetails", "Account Number").visible || this.getSectionField("PaymentDetails", "ACH Routing Number").visible || this.getSectionField("PaymentDetails", "Wire Routing Number").visible ||
                                                this.getSectionField("PaymentDetails", "Check Address").visible || this.getSectionField("PaymentDetails", "Footer").visible)) &&
                                        <Flex between className="payment-detials-container">
                                            <FlexColumn>
                                                <span className="section-heading-text" style={{ marginBottom: 16 }}>PAYMENT DETAILS</span>
                                                {/* {getSectionField("PaymentDetails", "Header").visible && <span style={{ fontSize: "1.1rem", fontWeight: 500 }}>{getSectionField("PaymentDetails", "Header").fieldValue ? getSectionField("PaymentDetails", "Header").fieldValue : "Add payment header here"}<br /></span>}<br /> */}

                                                {
                                                    this.getSectionField("PaymentDetails", "Account Number").visible && this.getSectionField("PaymentDetails", "Account Number").fieldValue &&
                                                    this.getSectionField("PaymentDetails", "Account Number").fieldValue != '' &&
                                                    <Flex between style={{ marginBottom: 8 }}>
                                                        <span className="label-text payment-details-label">Account number:</span><span className="value-text">{this.getSectionField("PaymentDetails", "Account Number").fieldValue}</span>
                                                    </Flex>
                                                }
                                                {
                                                    this.getSectionField("PaymentDetails", "ACH Routing Number").visible && this.getSectionField("PaymentDetails", "ACH Routing Number").fieldValue &&
                                                    this.getSectionField("PaymentDetails", "ACH Routing Number").fieldValue != '' &&
                                                    <Flex between style={{ marginBottom: 8 }}>
                                                        <span className="label-text payment-details-label">ACH routing number:</span><span className="value-text">{this.getSectionField("PaymentDetails", "ACH Routing Number").fieldValue}</span>
                                                    </Flex>
                                                }
                                                {
                                                    this.getSectionField("PaymentDetails", "Wire Routing Number").visible && this.getSectionField("PaymentDetails", "Wire Routing Number").fieldValue &&
                                                    this.getSectionField("PaymentDetails", "Wire Routing Number").fieldValue != '' &&
                                                    <Flex between style={{ marginBottom: 8 }}>
                                                        <span className="label-text payment-details-label">Wire routing number:</span><span className="value-text">{this.getSectionField("PaymentDetails", "Wire Routing Number").fieldValue}</span>
                                                    </Flex>
                                                }
                                                {/* {getSectionField("PaymentDetails", "Name").visible && <span>Bank Name: {getSectionField("PaymentDetails", "Name").fieldValue} <br /></span>} */}
                                                {
                                                    this.getSectionField("PaymentDetails", "Check Address").visible && this.getSectionField("PaymentDetails", "Check Address").fieldValue &&
                                                    this.getSectionField("PaymentDetails", "Check Address").fieldValue != '' &&
                                                    <Flex between style={{ marginBottom: 8 }}>
                                                        <span className="label-text payment-details-label">Check address:</span><span className="value-text">{this.getSectionField("PaymentDetails", "Check Address").fieldValue}</span>
                                                    </Flex>
                                                }
                                                {
                                                    this.getSectionField("PaymentDetails", "Bank Name").visible && this.getSectionField("PaymentDetails", "Bank Name").fieldValue &&
                                                    this.getSectionField("PaymentDetails", "Bank Name").fieldValue != '' &&
                                                    <Flex between style={{ marginBottom: 8 }}>
                                                        <span className="label-text payment-details-label">Bank name:</span><span className="value-text">{this.getSectionField("PaymentDetails", "Bank Name").fieldValue}</span>
                                                    </Flex>
                                                }
                                                {
                                                    this.getSectionField("PaymentDetails", "Bank Address").visible && this.getSectionField("PaymentDetails", "Bank Address").fieldValue &&
                                                    this.getSectionField("PaymentDetails", "Bank Address").fieldValue != '' &&
                                                    <Flex between style={{ marginBottom: 8 }}>
                                                        <span className="label-text payment-details-label">Bank address:</span><span className="value-text">{this.getSectionField("PaymentDetails", "Bank Address").fieldValue}</span>
                                                    </Flex>
                                                }
                                                {/* {getSectionField("PaymentDetails", "Note").visible && <span>Note: {getSectionField("BankDetails", "Note").fieldValue}<br /><br /></span>} */}
                                                {
                                                    this.getSectionField("PaymentDetails", "Footer").visible && this.getSectionField("PaymentDetails", "Footer").fieldValue &&
                                                    this.getSectionField("PaymentDetails", "Footer").fieldValue != '' &&
                                                    <Text size='14px' color='#333' style={{ marginTop: 2 }}>
                                                        {this.getSectionField("PaymentDetails", "Footer").fieldValue}
                                                    </Text>
                                                }
                                            </FlexColumn>

                                            <TextButton text='EDIT' rightIcon={<Image src={Edit} />} onClick={() => this.setState({ templateMetadata: selectedTemplate, showEditTemplateModal: true, editTemplate: selectedTemplate, paymentDetailsOpen: true })} />
                                        </Flex>
                                    }

                                    <Flex start>
                                        <Image src={PoweredBy} width="156px" height="24px" />
                                    </Flex>
                                </>
                            }
                        </Skeleton>
                    </FlexColumn>

                    <Affix offsetTop={0}>
                        <FlexColumn start gap='24px' style={{ marginTop: 24, overflowY: 'auto', height: 1200 }}>
                            {
                                selectedCustomer &&
                                <CardContainer width='330px' padding='16px 24px 14px'>
                                    <FlexColumn start>
                                        <Text heading margin='0 0 4px'>Invoice customization</Text>
                                        <Text>Select an invoice template to customize this invoice</Text>
                                        <LabeledInput
                                            type="select"
                                            placeholder="Select Template"
                                            onChange={this.handeTemplateSelect}
                                            loading={loadingTemplates}
                                            value={selectedTemplate && selectedTemplate.templateName}
                                            open={selectTemplateOpen}
                                            onDropdownVisibleChange={this.handleDropdownVisibleChange}
                                            dropdownRender={menu => (
                                                <FlexColumn start>
                                                    {menu}
                                                    <Flex start style={{ padding: '4px 12px 6px' }}>
                                                        <TextButton text='ADD NEW' capitalize primary onClick={() => this.createInvoiceTemplate()} rightIcon={<Image src={Add} />} />
                                                    </Flex>
                                                </FlexColumn>
                                            )}
                                        >
                                            {this.getTemplateOptions()}
                                        </LabeledInput>



                                        <TextButton text='CUSTOMIZE' rightIcon={<Image src={Brush} />} onClick={() => this.setState({ templateMetadata: selectedTemplate, showEditTemplateModal: true, editTemplate: selectedTemplate })} margin='8px 0 0' />
                                    </FlexColumn>
                                </CardContainer>
                            }

                            <CardContainer width='330px' padding='16px 24px 16px'>
                                <FlexColumn start gap='16px'>
                                    <Flex between fullWidth centerHorizontally>
                                        <Text heading>{docNumber}</Text>
                                        {/* <Tag primary>Unsent</Tag> */}
                                    </Flex>
                                    {
                                        selectedCustomer ?
                                            <>
                                                <LabeledInput
                                                    nomargin
                                                    type="read-only"
                                                    label="Customer"
                                                    value={customerName}
                                                />
                                                <LabeledInput
                                                    nomargin
                                                    type="read-only"
                                                    label="Invoice Amount"
                                                    value={`$ ${toCurrency(lineItemTotals.totalAmt)}`}
                                                />
                                                <LabeledInput
                                                    nomargin
                                                    type="read-only"
                                                    label="Due Date"
                                                    value={dueDate && dueDate.format('ll')}
                                                />

                                                <LabeledInput
                                                    nomargin
                                                    label="Internal note"
                                                    id="memo"
                                                    key="memo"
                                                    placeholder="Enter text"
                                                    optional
                                                    onChange={(event) => this.saveUserInput(event.target.id, event.target.value)}
                                                    value={memo}
                                                />

                                                {
                                                    (isCodatUser(aionStore) && (createdInvoiceCount || 0) < 2) ?
                                                        <Tooltip title={<Flex fullWidth center style={{ margin: '8px' }}><Text color='white' size='14px'>Your invoice will be synced to your accounting system once it’s sent</Text></Flex>} defaultVisible={false} color={'#030342'} placement='top' >
                                                            <Button loading={loadingPreview} solid text='PREVIEW & SEND' margin='8px 0 0' onClick={() => this.handleSave(true)} />
                                                        </Tooltip>
                                                        :
                                                        <Button loading={loadingPreview} solid text='PREVIEW & SEND' margin='8px 0 0' onClick={() => this.handleSave(true)} />
                                                }

                                                <Flex fullWidth centerHorizontally>
                                                    <TextButton onClick={() => this.handleSave(false)} loading={loadingSave} text='SAVE FOR LATER' />
                                                </Flex>
                                            </>
                                            :
                                            <FlexColumn center gap="8px">
                                                <Image src={Info} />
                                                <Text center>Start creating your invoice by choosing the customer</Text>
                                            </FlexColumn>
                                    }
                                </FlexColumn>
                            </CardContainer>

                            {
                                (showPayByBank && selectedCustomer) &&
                                <CardContainer width='330px' padding='16px 24px 24px'>
                                    <FlexColumn start gap='16px' style={{ marginTop: 0 }}>
                                        <Text heading margin='0 0 0'>Payment options</Text>

                                        <FlexColumn start>
                                            <Flex className="radio-table" start gap='8px' style={{ cursor: 'pointer', marginBottom: 12 }} onClick={() => this.setState({ onlinePaymentEnabled: !onlinePaymentEnabled })}>
                                                <Checkbox checked={onlinePaymentEnabled} onChange={this.handleAionChange} />
                                                <Text size="14px" weight={onlinePaymentEnabled ? '700' : '400'}>Accept online payments</Text>
                                                <Tooltip overlayInnerStyle={{ minWidth: 300 }} overlayStyle={{ minWidth: 320 }} overlayClassName='roles-tooltip' placement="top" arrowPointAtCenter={false} title={<FlexColumn start left>
                                                    <Text color='white' size='14px' noWrap>{'Your customers can pay your invoice via'}</Text>
                                                    <Text color='white' size='14px' noWrap>{'bank transfer using Aion’s payment portal'}</Text>
                                                </FlexColumn>}>
                                                    <Image src={InfoBlack} height='20px' width='20px' />
                                                </Tooltip>
                                                {/* <Text lightText>Your customers can pay your invoice via card, or bank transfer using Aion’s payment portal</Text> */}
                                            </Flex>

                                            {
                                                onlinePaymentEnabled &&
                                                <LabeledInput
                                                    nomargin
                                                    label="Where would you like to receive payment?"
                                                    type="select"
                                                    id="fromAccount"
                                                    // width='276px'
                                                    placeholder="Select account"
                                                    value={fromAccount && <Flex between centerHorizontally style={{ height: 30 }}>
                                                        <Text><img width='16px' height='16px' src={AionIcon} style={{ marginRight: 8, marginBottom: 2 }} />{`${(fromAccount.nickName || `Business ${fromAccount.accountSubType}`)} • ${fromAccount.mask}`}</Text>
                                                        <Text size='14px' lightText style={{ marginLeft: 8 }}>${toCurrency(fromAccount.availableBalance)}</Text>
                                                    </Flex>}
                                                    onChange={this.handleSelectAccount}
                                                // noAsterisk
                                                // error={errorField === "fromAccount"}
                                                // errorMessage={errorMessage}
                                                >
                                                    {options}
                                                </LabeledInput>
                                            }
                                        </FlexColumn>

                                        <Flex className="radio-table" start style={{ cursor: 'pointer' }} onClick={() => this.setState({ onlinePaymentEnabled: !onlinePaymentEnabled })}>
                                            <Checkbox checked={!onlinePaymentEnabled} onChange={this.handleBankChange} />
                                            <FlexColumn style={{ margin: '0 0 0 8px' }} gap='8px'>
                                                <Text size="14px" weight={!onlinePaymentEnabled ? '700' : '400'}>Send payment instructions</Text>
                                                {/* <Text lightText>Send specific payment instructions to your customers’ on how to pay</Text> */}
                                            </FlexColumn>
                                        </Flex>
                                    </FlexColumn>
                                </CardContainer>
                            }
                        </FlexColumn>
                    </Affix>
                </Flex >

                <Modal
                    visible={showEditTemplateModal}
                    footer={null}
                    closable={true}
                    width="100vw"
                    style={{ top: 10, minHeight: "100vh" }}
                    destroyOnClose={true}
                    onCancel={() => {
                        this.setState({ showExitTemplateModal: true })
                    }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <EditTemplate
                        template={templateMetadata}
                        onSave={() => {
                            this.handleSave(false, null, true)
                            templates.push(templateMetadata)
                            this.setState({ showEditTemplateModal: false, newTemplate: false, selectedTemplate: templateMetadata, templates, paymentDetailsOpen: false, onlinePaymentEnabled: templateMetadata?.onlinePaymentEnabled })
                        }}
                        paymentDetailsOpen={paymentDetailsOpen}
                    />
                </Modal>

                <Modal
                    visible={saveTemporaryTemplate}
                    footer={null}
                    closable={true}
                    width={500}
                    destroyOnClose={true}
                    onCancel={() => {
                        this.setState({ showEditTemplateModal: false, newTemplate: false, saveTemporaryTemplate: false })
                    }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <FlexColumn center gap='24px' style={{ marginBottom: '5%' }}>
                        {<img height='48px' width='48px' src={Missing} />}
                        <Text bold size='28px' height='40px' style={{ textAlign: 'center', maxWidth: '800px' }}>Do you want to save the template?</Text>
                        <Flex center style={{ width: "75%" }}>
                            <div>
                                <Space>
                                    <Button solid loading={loading} style={{ alignItems: "left" }} type="primary" text='Yes' onClick={() => this.saveTemporaryTemplate(newTemplate ? templateMetadata : editTemplate)} />
                                    <Button style={{ alignItems: "left" }} type="primary" text='No'
                                        onClick={() => {
                                            this.setState({ showEditTemplateModal: false, newTemplate: false, saveTemporaryTemplate: false, paymentDetailsOpen: false })
                                        }}
                                    />
                                </Space>
                            </div>
                        </Flex>
                    </FlexColumn>
                </Modal>

                <AlertModal
                    close
                    visible={showExitTemplateModal}
                    title='Are you sure you want to exit?'
                    description='You will lose your progress if you exit before saving your template.'
                    buttonTitle='EXIT WITHOUT SAVING'
                    cancelButtonText='GO BACK'
                    onConfirm={() => {
                        this.setState({ showExitTemplateModal: false, showEditTemplateModal: false, paymentDetailsOpen: false })
                        // onClose()
                    }}
                    onCancel={() => this.setState({ showExitTemplateModal: false })}
                />

                <SendInvoiceDrawer
                    toEmails={toEmails}
                    ccEmails={ccEmails}
                    subject={subject}
                    editorState={editorState}
                    invoice={invoice}
                    visible={showPreview}
                    previewUrl={previewUrl}
                    onClose={() => this.setState({ showPreview: false })}
                    handleSave={this.handleSave}
                    templateName={selectedTemplate?.templateName}
                    revise={revise}
                    onSuccess={(toEmails, ccEmails) => this.setState({ showSentModal: true, toEmails, ccEmails })}
                    onlinePaymentEnabled={onlinePaymentEnabled}
                    accountNumber={fromAccount?.accountNumber}
                />

                <Modal
                    visible={showVoidModal}
                    footer={null}
                    closable={false}
                    width={456}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => {
                        this.setState({ showVoidModal: false })
                    }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <Void
                        customer={this.customer}
                        onCancel={() => {
                            this.setState({ showVoidModal: false })
                            this.voidInvoice()
                        }}
                        onDeactivated={() => {
                            this.setState({ showVoidModal: false })
                            window.history.back()
                        }}
                        oldDocNumber={voidedInvoiceNumber}
                        newDocNumber={docNumber}
                    />
                </Modal>


                <Drawer
                    visible={showNewProductModal}
                    placement="right"
                    closable={true}
                    mask={true}
                    maskClosable={true}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                    width='444px'
                    destroyOnClose={true}
                    onClose={() => { this.setState({ showNewProductModal: false, selectedProduct: null }) }}
                >
                    <ProductDrawer
                        onClose={() => { this.setState({ showNewProductModal: false, selectedProduct: null }) }}
                        visible={showNewProductModal}
                        product={this.state.selectedProduct}
                        submitComplete={(productId) => {
                            this.setState({ showNewProductModal: false, selectedProduct: null })
                            this.fetchProducts({
                                pagination: {
                                    current: 1,
                                    pageSize: 500
                                }
                            }, productId)
                        }
                        }
                    />
                </Drawer>

                <DocumentDrawer
                    visible={showAddDocument}
                    onClose={() => this.setState({ showAddDocument: false })}
                    onSubmit={() => this.setState({ showAddDocument: false }, this.fetchCustomerPO(selectedCustomer))}
                    msas={msas}
                    customerId={(selectedCustomer || {}).customerId}
                    customer={selectedCustomer}
                    addPO
                />

                <AlertModal
                    close
                    visible={showExitModal}
                    loading={saveAndExitLoading}
                    title='Are you sure you want to exit?'
                    description='You will lose your progress if you exit before saving your work.'
                    buttonTitle='SAVE AND EXIT'
                    secondaryCTAText='EXIT WITHOUT SAVING'
                    cancelButtonText='GO BACK'
                    onConfirm={() => {
                        this.setState({ saveAndExitLoading: true }, () => this.handleSave(false))
                    }}
                    onSecondaryCTAClick={() => window.history.back()}
                    onCancel={() => this.setState({ showExitModal: false })}
                />

                <AlertModal
                    close
                    visible={showExitCustomerModal}
                    title='Are you sure you want to exit?'
                    description='You will lose your progress if you exit before saving your template.'
                    buttonTitle='EXIT WITHOUT SAVING'
                    cancelButtonText='GO BACK'
                    onConfirm={() => {
                        this.setState({ showExitCustomerModal: false, showAddCustomer: false })
                        // onClose()
                    }}
                    onCancel={() => this.setState({ showExitCustomerModal: false })}
                />

                <Drawer
                    visible={showAddCustomer}
                    placement="right"
                    closable={true}
                    mask={!showExitCustomerModal}
                    onClose={() => this.setState({ showExitCustomerModal: true })}
                    maskClosable={false}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                    width='444px'
                    className='sendInvoiceDrawer'
                    destroyOnClose={true}
                >
                    <CustomerDrawer
                        customers={activeCustomers || []}
                        onSave={(customer) => {
                            const { activeCustomers } = this.state
                            activeCustomers.push(customer);

                            // Sort the array by companyName
                            activeCustomers.sort((a, b) => (a.displayName || a.companyName).localeCompare(b.displayName || b.companyName));
                            this.setState({ showAddCustomer: false, activeCustomers, billingAddressLoading: true, shippingAddressLoading: true }, () => {
                                invoice.customerId = customer.customerId
                                invoice.customerName = customer.displayName
                                invoice.billingAddress = customer.billingAddress
                                invoice.shippingAddress = customer.shippingAddress
                                invoice.ponumber = null

                                this.setState({
                                    selectedCustomer: customer, invoice, billingAddressLoading: false,
                                    shippingAddressLoading: false, toEmails: customer.invoiceRecipientEmail || [],
                                })
                                this.handlePaymentTermsSelect(customer.paymentTerms)
                                this.fetchCustomerPO(customer)
                                this.getMSAForCustomer(customer)
                            })
                        }} />
                </Drawer>

                <AlertModal
                    success
                    visible={showSentModal}
                    title='Invoice Sent'
                    description={`Your invoice ${invoice?.docNumber} has been sent to ${invoice.customerName} (${toEmails.join(', ')}) ${(ccEmails && ccEmails.length > 0) ? ` and CC'd to ${ccEmails.join(', ')}` : ''}`}
                    buttonTitle='OKAY'
                    onConfirm={() => this.props.history.replace({
                        pathname: '/receivables/invoices',
                        state: { tab: 'unpaid' }
                    })}
                />
            </FlexColumn >
        )
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer,
        receivablesStore: state.receivablesAppReducer,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)))