import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import moment from 'moment';
import queryString from 'query-string'

import { Skeleton, Divider, Typography, Alert, message, Affix } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

import { FlexColumn, Flex, } from '../../Reusable/Container';
import { TextButton } from '../../Reusable/Button';
import { ErrorAlert } from '../../Reusable/Alert';
// import qbButton from '../../../Images/C2QB_auth.png'
import qbButton from '../../../Images/C2QB_green_btn_tall_hover.svg'
import Banner from '../../Reusable/Banner';

import OAuthClient from 'intuit-oauth';
import environment from '../../../environment';
import { apiGET, apiPOSTReq } from '../../../Utils/api';
import { getUserRoles } from '../../../Utils/util';
import COASetup from './COASetup';
import PageHeader from '../../Reusable/PageHeader';
import { addDataToStore, SAVE_DATA } from '../../../Actions/actions'

const { Text } = Typography;

class Index extends Component {
    state = {
        connectedQB: false,
        loadingSetting: true,
    };

    componentDidMount() {
        this.handleCallback();
    }

    handleCallback = () => {
        const { UserInfo } = this.props.aionStore;
        const value = queryString.parse(this.props.location.search)
        const state = value.state
        const url = window.location.href

        if (state && state === 'qb-integration') {
            this.props.history.replace('/settings/integration/qb-setup')

            if (value.code && value.realmId) {
                const headers = {
                    businesskey: this.props.aionStore.BusinessUniqueKey
                }

                apiGET(`/financing/oauth/token?url=${url}`, headers, (err, resp) => {
                    try {
                        const token = resp.data.Response;
                        if (resp.data.success) {

                            const body = {
                                accessToken: token.access_token,
                                accessTokenExpiresIn: token.expires_in,
                                accessTokenSecret: "",
                                companyId: value.realmId,
                                platformName: "QUICKBOOKS",
                                dateCreated: Date.now(),
                                refreshToken: token.refresh_token,
                                refreshTokenExpiresIn: token.x_refresh_token_expires_in,
                                oAuthVersion: "2.0",
                                refreshTokens: true,
                                connectedBy: `${UserInfo.FirstName} ${UserInfo.LastName} (${UserInfo.Email})`,
                            }

                            apiPOSTReq(`${environment.integrationBaseUrl}/integration/connect`, {}, body, (err, resp) => {
                                try {
                                    const data = resp;
                                    console.log("/integration/connect", data)
                                    if (data.result) {
                                        const body = {
                                            page: 0,
                                            size: 1000,
                                        }
                                        apiPOSTReq(`${environment.integrationBaseUrl}/integration/getIntegrationHistory`, {}, body, (err, resp) => {
                                            try {
                                                const data = resp;
                                                console.log("/integration/getIntegrationHistory", data)
                                                if (data.result) {
                                                    if(data.integrationList && data.integrationList.length > 0){
                                                        var prev = data.integrationList[0];
                                                        if(prev.syncSetupCompleted){
                                                            this.syncNow();
                                                            this.props.history.replace('/settings/profile')
                                                        }
                                                    }

                                                    window.location.reload(false);
                                                } else {
                                                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                                                }
                                            } catch (error) {
                                                console.log("ERRR getIntegrationHistory", error.stack, resp);
                                                ErrorAlert({ description: error.message })
                                            }
                                        })
                                    } else {
                                        throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                                    }
                                } catch (error) {
                                    console.log("ERRR fetchConnectedSW", error.stack, resp);
                                    ErrorAlert({ description: error.message })
                                }
                            })
                        } else {
                            console.error("The error message is :" + JSON.stringify(err))
                            message.error('Sorry, we had trouble reconnecting your QuickBooks. Please try again.')
                            this.setState({ invoiceRefreshLoading: false })
                            throw Error("Could not fetch attributes.")
                        }
                    } catch (error) {
                        console.error("ERROR", error.stack)
                        message.error('Sorry, we had trouble reconnecting your QuickBooks. Please try again.')
                        this.setState({ invoiceRefreshLoading: false })
                    }
                })
            }
        } else {
            this.fetchConnectedSW();
        }
    }

    syncNow = () => {
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/qb/syncQuickBooks`, {}, {}, (err, resp) => {
            try {
                const data = resp;
                console.log("/syncQuickBooks", data);
                if (data.result) {
                    message.success(data.responseMessage);
                    if (data.jobId) {
                        this.props.dispatch(addDataToStore(SAVE_DATA, { syncJobId: data.jobId }));
                    }
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later");
                }
            } catch (error) {
                console.log("ERRR syncQuickBooks", error.stack, resp);
                ErrorAlert({ description: error.message });
            }
        })
    }

    fetchConnectedSW = () => {
        this.setState({ loading: true });
        const body = { platformName: 'QUICKBOOKS' }
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/getConnectedSW`, {}, body, (err, resp) => {
            try {
                this.setState({ loading: false });
                const data = resp;
                console.log("/integration/getConnectedSW", data)
                if (data.result) {
                    if (!data.integration) {
                        return;
                    }
                    this.setState({
                        connectedQB: true,
                        loadingSetting: true,
                    })

                    if (data.integration.coaSyncedDate) {
                        var ms = moment().diff(moment(data.coaSyncedDate));
                        var d = moment.duration(ms);
                        if (d.asHours() < 24) {
                            this.setState({ loadingSetting: false, connectedQB: true })
                            return;
                        }
                    }
                    message.loading("We are retrieving some basic information from QuickBooks to help you effectively setup your sync on Aion. This can take upto a minute to load, we appreciate your patience.", 5)
                    apiPOSTReq(`${environment.integrationBaseUrl}/integration/qb/syncQuickBooksCOA`, {}, {}, (err, resp) => {
                        try {
                            const data = resp;
                            console.log("/integration/syncQuickBooksCOA", data)
                            if (data.result) {
                                this.setState({ loadingSetting: false, connectedQB: true })
                            } else {
                                throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                            }
                        } catch (error) {
                            console.log("ERRR fetchConnectedSW", error.stack, resp);
                            ErrorAlert({ description: error.message })
                        }
                    })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR fetchConnectedSW", error.stack, resp);
                ErrorAlert({ description: error.message })
            }
        })
    }

    connectQuickBooks = () => {
        const userRole = getUserRoles(this.props.aionStore);
        if (userRole.includes("Company Admin") || userRole.includes("Owner") || userRole.includes("Primary Admin")) {
            var oauthClient = new OAuthClient(environment.qboOAuth);
            const authUri = oauthClient.authorizeUri({
                scope: [
                    OAuthClient.scopes.Accounting,
                    OAuthClient.scopes.OpenId,
                    OAuthClient.scopes.Profile,
                    OAuthClient.scopes.Email,
                    OAuthClient.scopes.Phone,
                    OAuthClient.scopes.Address
                ],
                state: 'qb-integration'
            });

            window.location.href = authUri
        }
    }

    render() {
        const { theme } = this.props;
        var { loading } = this.state;

        return (
            <>
                <FlexColumn className='main-padding'>
                    {!this.state.connectedQB && <Skeleton loading={loading} active title={true} paragraph={{ rows: 5 }}>
                        <PageHeader
                            noMargin
                            titleText={'Setup'}
                            desc={"Sync your Aion account with QuickBooks Online to reduce redundant tasks"}
                        />


                        <div className="flex-column-center" style={{ textAlign: 'center', paddingBottom: '20pt' }}>
                            <Banner style={{ marginTop: '20pt' }} title="QuickBooks Master admin or Company admin rights required" message="Make sure you have the right permissions. You will need master admin or company rights to connect to QuickBooks" />

                            <Text style={{ margin: '20pt 0 12pt 0' }}>Tap on the button below to<br />connect you QuickBooks account</Text>
                            <img style={{ height: '40pt', cursor: 'pointer' }} src={qbButton} onClick={() => { this.connectQuickBooks() }} />
                            <br />
                            <Text>You will be redirected to QuickBooks to provide authorization to Aion</Text>
                            <br />

                            <Alert message="We do not store or have access to your QuickBooks credentials" type="info" />
                        </div>
                    </Skeleton>}

                    {this.state.connectedQB && <>
                        <Skeleton loading={this.state.loadingSetting} active title={true} paragraph={{ rows: 5 }}>
                            <COASetup />
                        </Skeleton>
                    </>
                    }
                </FlexColumn>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer,
        bankingStore: state.bankingAppReducer,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Index));