import { Modal, Select } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import { addDataToStore, BUSINESS_INFO, PERSONAL_INFO, REMOTE_SAVE_DATA, SAVE_CO_OWNER } from '../../../Actions/actions';
import { ErrorAlert } from '../../Reusable/Alert';
import { TextButton } from '../../Reusable/Button';
import { Flex } from '../../Reusable/Container';
import { Divider } from '../../Reusable/Divider';
import cloneDeep from 'lodash/cloneDeep'

import { FlexColumn } from '../../Reusable/Container';
import FlowViewComponent from '../../Reusable/Refresh/FlowViewComponent';
import OwnerProfile from './OwnerProfile';
import { LabeledInput } from '../../Reusable/Input';
import { Text } from '../../Reusable/Refresh/Text';
import ModalClose from '../../../Images/modal-close.png'
import { OwnerCard } from '../../Reusable/Refresh/Card';
import { getTotalOwnershipPercentage } from '../../../Utils/util';
import OwnerPendingInvite from './OwnerPendingInvite';
import OwnerInvite from './OwnerInvite';
import { random } from 'lodash';
import Banner from '../../Reusable/Banner';
import { apiPOSTReq } from '../../../Utils/api';
import environment from '../../../environment';

class ControlPersonInfo extends Component {

    state = {
        selectedCP: {}, // type (primary, coOwner) and email fields
        personalInfoCopy: cloneDeep(this.props.onboardingStore.personalInfo),
        businessInfoCopy: cloneDeep(this.props.onboardingStore.businessInfo),
        preboardUsers: []
    }

    componentDidMount() {
        var { onboardingStore } = this.props
        if(onboardingStore.personalInfo.controlPerson) {
            this.setState({ selectedCP: { type: 'primary', email: onboardingStore.personalInfo.email } })
        } else {
            var coOwnerCP = onboardingStore.coOwnersInfo.find(owner => owner.controlPerson)
            if(coOwnerCP) {
                this.setState({ selectedCP: { type: 'coOwner', email: coOwnerCP.email } })
            }
        }
        this.fetchPendingInvites()
    }
    fetchPendingInvites = () => {
        var criteriaList = [
            {
                "fieldName": "Status",
                "value": "Invited"
            },
            {
                "fieldName": "Status",
                "value": "Error"
            },
            {
                "fieldName": "Status",
                "value": "ProcessedPreseed"
            }
        ]

        var body = {
            "searchFilter": {
                "criteriaList": criteriaList
            },
            "sortDirection": "ASC"
        }
        this.setState({ preboardUserLoading: true })
        apiPOSTReq(`${environment.uamBaseUrl}/getPreboardUser`, {}, body, (err, resp) => {
            try {
                this.setState({ preboardUserLoading: false })
                var data = resp || {}
                if (data.result) {
                    this.setState({ preboardUsers: data.preboardUsers })
                } else {
                    throw Error(data.error || data.responseMessage)
                }
            } catch (error) {
                console.log({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }
    onCloseClick = () => {
        this.props.dispatch(addDataToStore(PERSONAL_INFO, this.state.personalInfoCopy))
        this.props.dispatch(addDataToStore(BUSINESS_INFO, this.state.businessInfoCopy))
        this.props.loadNext();
    }
    saveUserInput = (id, value) => {
        var dataToSave = {}
        switch(id) {
            case "controlPerson":
                dataToSave = { [id]: value }
                this.props.dispatch(addDataToStore(PERSONAL_INFO, dataToSave))
                break
            case "isControlPersonBO":
                var { onboardingStore } = this.props
                dataToSave[id] = value
                // Mark all coOwners as not CPs
                if(value == false) {
                    onboardingStore.coOwnersInfo.forEach(owner => {
                        owner.controlPerson = false
                        this.props.dispatch(addDataToStore(SAVE_CO_OWNER, { ownerInfo: owner }))
                    })
                    this.setState({ selectedCP: {} })
                }
                this.props.dispatch(addDataToStore(BUSINESS_INFO, dataToSave))
                break
            default:
                dataToSave[id] = value
                this.props.dispatch(addDataToStore(BUSINESS_INFO, dataToSave))
                break
        }
    }

    handleOnChange = (event) => {
        const target = event.target;
        switch(target.id) {
            default:
                this.saveUserInput(target.id || target.name, target.value)
        }
    }

    setErrorField = (id, msg) => this.setState({ errorField: id, errorMessage: msg })

    validateFlow = () => {
        var { selectedCP, preboardUsers } = this.state
        var { onboardingStore, flowView } = this.props
        var { controlPerson, email } = onboardingStore.personalInfo
        var { isControlPersonBO } = onboardingStore.businessInfo
        var cpOwners = onboardingStore.coOwnersInfo.filter(owner => owner.controlPerson)

        var boInvited = (preboardUsers || []).filter(user => user.beneficialOwner)
        var cpInvited = (preboardUsers || []).filter(user => user.controlPerson)

        if(!(controlPerson == true ||
            cpOwners.length > 0 ||
            cpInvited.length > 0 || 
            selectedCP.email ||
            (isControlPersonBO && boInvited.length > 0))) {
            ErrorAlert({ description: 'A controlling party is required to continue.' })
            return
        }
        console.log("selectedCP", selectedCP)
        // Set the controlPerson attribute on the right owner
        if(selectedCP.email == onboardingStore.personalInfo.email) {
            this.props.dispatch(addDataToStore(PERSONAL_INFO, { controlPerson: true }))
            // update other owners
            onboardingStore.coOwnersInfo.forEach(owner => {
                owner.controlPerson = false
                this.props.dispatch(addDataToStore(SAVE_CO_OWNER, { ownerInfo: owner }))
            })
        } else {
            this.props.dispatch(addDataToStore(PERSONAL_INFO, { controlPerson: false }))
            // update other owners
            onboardingStore.coOwnersInfo.forEach(owner => {
                owner.controlPerson = (owner.email == selectedCP.email) // set true to selectedCP
                this.props.dispatch(addDataToStore(SAVE_CO_OWNER, { ownerInfo: owner }))
            })
        }
        this.props.dispatch(addDataToStore(REMOTE_SAVE_DATA, flowView.FlowStep))
        this.props.loadNext()
    }

    render() {
        var { flowView, currentViewIndex, loadPrevView, onboardingStore, theme, reviewView, reviewEditMode } = this.props
        var { errorField, errorMessage, selectedCP, selectedOwner, showOwnerModal, preboardUsers, showInviteOwnerModal, inviteCompKey } = this.state
        var { controlPerson, email } = onboardingStore.personalInfo
        var { isControlPersonBO } = onboardingStore.businessInfo
        var beneficialOwners = onboardingStore.coOwnersInfo.filter(owner => (Number(owner.ownershipPercentage) >= 25 || owner.controlPerson))
        var cpOwners = onboardingStore.coOwnersInfo.filter(owner => owner.controlPerson)

        // We do not need to show completed ones
        preboardUsers = (preboardUsers || []).filter(user => user.status != 'ProcessedPreseed')

        var boInvited = (preboardUsers || []).filter(user => user.beneficialOwner)
        var cpInvited = (preboardUsers || []).filter(user => user.controlPerson)

        if(cpInvited.length > 0) {
            controlPerson = false
            isControlPersonBO = false
        }
        if(isControlPersonBO == false) preboardUsers = cpInvited

        var boExists = boInvited.length > 0 || beneficialOwners.length > 0
        if(!boExists && !controlPerson) isControlPersonBO = false

        flowView = flowView || []

        console.log("cpInvited onboardingStore.personalInfo", onboardingStore.personalInfo)
        var childViews = (
            <FlexColumn start style={{ marginTop: "0px", height: '100%' }}>
                <Text uppercase spacing='0.08em' weight='500' color={theme.colors.secondary6}>Controlling party</Text>
                <Text style={{ maxWidth: '600px' }}>We need information about an individual with significant responsibility for managing or directing the entity, including, an executive officer or senior manager (e.g. Chief Executive Officer, Chief Financial Officer, Chief Operating Officer, Managing Member, General Partner, President, Treasurer, or any other individuals who regularly perform similar functions)</Text>
                <FlexColumn start left style={{ marginTop: '24px' }} gap='24px'>
                    {
                        // Do not show questionnaire if cp exists or is invited
                        (cpOwners.length == 0) && (cpInvited.length == 0) &&
                        <>
                            <LabeledInput
                                margin='0px'
                                label='Are you a controlling party?'
                                type='switch'
                                switchNames={['Yes', 'No']}
                                onChange={(value) => {
                                    this.saveUserInput("controlPerson", (value == 'Yes'))
                                    if(value == 'Yes') {
                                        this.setState({ selectedCP: { type: 'primary', email: email } })
                                    } else {
                                        this.setState({ selectedCP: {} })
                                    }
                                }}
                                value={(typeof(controlPerson) == 'boolean') ? (controlPerson ? 'Yes' : 'No') : null}
                                error={errorField=="controlPerson"}
                                errorMessage={errorMessage}
                            />
                            {
                                (controlPerson == false) && (beneficialOwners.length > 0 || boInvited.length > 0) &&
                                <LabeledInput
                                    margin='0px'
                                    label={'Are any of the beneficial owners a controlling party?'}
                                    type='switch'
                                    switchNames={['Yes', 'No']}
                                    onChange={(value) => this.saveUserInput("isControlPersonBO", (value == 'Yes'))}
                                    value={(typeof(isControlPersonBO) == 'boolean') ? (isControlPersonBO ? 'Yes' : 'No') : null}
                                    error={errorField=="isControlPersonBO"}
                                    errorMessage={errorMessage}
                                />
                            }
                            {
                                !controlPerson && (isControlPersonBO == true) && (beneficialOwners.length > 0) &&
                                <div style={{ width: "350px" }}>
                                    <LabeledInput
                                        label="Select the owner"
                                        type="select"
                                        key="boControlPerson"
                                        placeholder="Select"
                                        onChange={value => { this.setState({ selectedCP: { type: 'coOwner', email: value } }) }}
                                        value={selectedCP.email}
                                        id="boControlPerson"
                                        error={errorField=="boControlPerson"}
                                        errorMessage={errorMessage}
                                    >
                                        {
                                            beneficialOwners.map(item => {
                                                return (
                                                    <Select.Option key={item.email} value={item.email}>
                                                        {`${item.firstName} ${item.lastName}`}
                                                    </Select.Option>
                                                )
                                            })
                                        }
                                    </LabeledInput>
                                </div>
                            }
                            {
                                (typeof(controlPerson) == 'boolean') && !controlPerson && 
                                (isControlPersonBO == false) &&
                                !(preboardUsers || []).filter(u => u.controlPerson).length > 0 &&
                                // <Flex start>
                                //     <TextButton text={'Add Controlling Party'.toUpperCase()} onClick={() => this.setState({ showOwnerModal: true })} />
                                // </Flex>
                                <FlexColumn start gap='16px'>
                                    <Flex gap='16px' start><Text>Add information on their behalf: </Text><TextButton text={'Add Controlling Party'.toUpperCase()} onClick={() => this.setState({ showOwnerModal: true })} /></Flex>
                                    <Flex gap='16px' start><Text>Invite them to add information: </Text><TextButton text={'Invite Controlling Party'.toUpperCase()} onClick={() => this.setState({ showInviteOwnerModal: true })} /></Flex>
                                    {/* <Text color={theme.colors.divider}>|</Text> */}
                                    
                                </FlexColumn>
                            }
                        </>
                    }
                    
                    {
                        (cpOwners.length > 0) &&
                        <Flex wrap start>
                            {
                                cpOwners.map((item, j) => {
                                    return <OwnerCard padding='12px 0' hidePercentage={true} ownerInfos={item} theme={theme} key={j} onClick={() => this.setState({ showOwnerModal: true, selectedOwner: item })}/>
                                })
                            }
                        </Flex>
                    }

                    {
                        !controlPerson && (typeof(isControlPersonBO) == 'boolean') && ((preboardUsers || []).length > 0) && (cpOwners.length == 0) &&
                        <Banner style={{ maxWidth: '950px', marginTop: '0px' }} message='You can proceed further, however, you will be able to submit your application only once the invited owners have provided their required information.' />
                    }

                    {
                        !controlPerson && (typeof(isControlPersonBO) == 'boolean') && (cpOwners == 0) &&
                        <FlexColumn gap='24px'>
                            {
                                preboardUsers.length > 0 &&
                                <FlexColumn >
                                    <Text uppercase spacing='0.08em' weight='500' color={theme.colors.secondary6}>Pending Invites</Text>
                                </FlexColumn>
                            }
                            <OwnerPendingInvite
                                key={inviteCompKey + preboardUsers.length}
                                displayPendingOnly={true}
                                displayCPOnly={(isControlPersonBO == false)}
                                setPreboardUsers={ (data) => {
                                    this.setState({ preboardUsers: data.preboardUsers })
                                } }
                            />
                        </FlexColumn>
                    }
                </FlexColumn>
            </FlexColumn>
        );
        if(reviewView) {
            return (
                <FlexColumn start fullWidth left>
                    {
                        controlPerson && 
                        <div style={{ width: "25%" }}>
                            <LabeledInput
                                type="read-only"
                                label='Are you a controlling party?'
                                value={(typeof(controlPerson) == 'boolean') ? (controlPerson ? 'Yes' : 'No') : null}
                            />
                        </div>
                        // <OwnerCard padding='12px 0' hidePercentage={true} ownerInfos={onboardingStore.personalInfo} theme={theme} style={{cursor: 'not-allowed'}}/>
                    }
                    {
                        (cpOwners.length > 0) &&
                        <Flex wrap start>
                            {
                                cpOwners.map((item, j) => {
                                    return <OwnerCard hideEdit={true} padding='12px 0' hidePercentage={true} ownerInfos={item} theme={theme} key={j} style={{cursor: 'not-allowed'}}/>
                                })
                            }
                        </Flex>
                    }
                    {
                        !controlPerson && (cpOwners.length == 0) &&
                        <Banner style={{ width: 350 }} message={cpInvited.length == 0 ? 'Controlling party needs to be selected' : 'Controlling party invite is pending'} />
                    }
                </FlexColumn>
            )
        }
        return (
            <>
                <FlowViewComponent
                    flowView={flowView}
                    currentViewIndex={currentViewIndex}
                    loadPrev={loadPrevView}
                    childViews={childViews}
                    submitLoading={this.state.submitLoading}
                    width={'100%'}
                    back={currentViewIndex > 0}
                    padding='0 64px'
                    {...this.props}
                    loadNext={this.validateFlow}
                    onCloseClick={this.onCloseClick}
                />
                <Modal
                    visible={showOwnerModal}
                    footer={null}
                    closable={true}
                    width={700}
                    destroyOnClose={true}
                    onCancel={() => { 
                        this.setState({ showOwnerModal: false, selectedOwner: null })
                    }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                    style={{ top: '20px' }}
                >
                    <OwnerProfile 
                        ownerType="controlPerson" // coOwner, controlPerson, signer
                        hidePII={(this.state.preboardUsers || []).filter(pbUser => pbUser.email == (selectedOwner || {}).email).length > 0}
                        selectedOwner={selectedOwner}
                        flowStep={"Apply.Ownership"} // Set remote save to prev step
                        onSubmit={(data) => {
                            this.setState({ showOwnerModal: false, selectedOwner: null, selectedCP: { type: 'coOwner', email: data ? data.ownerInfo.email : "" } })
                            this.fetchPendingInvites()
                        }}
                    />
                </Modal>
                <Modal
                    visible={showInviteOwnerModal}
                    footer={null}
                    closable={true}
                    width={500}
                    destroyOnClose={true}
                    onCancel={() => { 
                        this.setState({ showInviteOwnerModal: false })
                    }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                    style={{ top: '20px' }}
                >
                    <OwnerInvite
                        ownerType="controlPerson" // coOwner, controlPerson, signer
                        selectedOwner={{}}
                        onSubmit={() => {
                            this.setState({ showInviteOwnerModal: false, inviteCompKey: random(500) })
                            this.fetchPendingInvites()
                        }}
                        preboardUsers={preboardUsers}
                    />
                </Modal>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        onboardingStore: state.onboardingAppReducer,
        aionStore: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(ControlPersonInfo));