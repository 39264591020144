import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'
import styled from 'styled-components'
import { saveAs } from 'file-saver'

import { message, Button, Upload, Modal } from 'antd'

// Components
import { Text } from '../../../Reusable/Text'
import { TextButton } from '../../../Reusable/Button'
import { Image } from '../../../Reusable/Image'
import { FlexColumn, Flex } from '../../../Reusable/Container'
import environment from '../../../../environment'
import Result from './Result'
import ModalClose from '../../../../Images/modal-close.png'
import Download from '../../../../Images/download.png'
import UploadIcon from '../../../../Images/upload.svg'

export const CardButton = styled.div`
    margin-top: 18px;
    background-color: rgb(255, 255, 255);
    border-radius: 0.5rem;
    width: 450px;
    margin-bottom: 1.5rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    opacity: 1;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    padding: 1.8rem;
    transition-property: box-shadow;
    transition-duration: 300ms;
    &:hover {
        cursor: pointer;
        box-shadow: 0 3px 15px rgb(0 0 0 / 0.4);
    };
`

export const CardButtonText = styled.span`
    font-size: 18px;
    font-weight: 400;
`

class Import extends Component {

    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            file: null,
            fileName: '',
            showErrorModal: false,
            fileList: [],
        }
    }

    render() {
        const { theme } = this.props
        const darkMode = (theme.type === "dark")

        var uploadProps = {
            accept: ".csv",
            beforeUpload: file => {
                console.log('beofre upload', file)
                this.setState({ polling: true, })
                return
            },
            multiple: false,
            action: this.props.actionUrl || `${environment.payBaseUrl}/payables/vendors/uploadVendors`,
            data: (file) => {
                console.log(file)
            },
            headers: {
                AionCurrentBiz: this.props.aionStore.BusinessUniqueKey,
                AionAuth: this.props.aionStore.UAM.encryptedAuthHeader,
            },
            multiple: false,
            onChange: (info) => {
                var { file } = info
                console.log('info', info)
                this.setState({ fileList: [file] })
                const { status, response } = file

                console.log('status', status, response)
                if (status === "done") {
                    if (response.result) {
                        console.log('job id', response.uploadJobId)
                        message.loading("The import has begun, we will update you when the import is complete.")
                        this.props.submitComplete(response.uploadJobId)
                    } else {
                        this.setState({
                            showErrorModal: true,
                            fileList: [],
                            errorMessage: response.responseMessage,
                            uploadVendorInfo: response.uploadVendorInfo
                        })
                    }
                } else if (status === "error") {
                    console.log("payables/vendors/uploadVendors file", JSON.stringify(file))
                    this.setState({ showErrorModal: true, fileList: [] })
                }

            }
        }

        let showHeaderErrorTable = this.state.uploadVendorInfo && this.state.uploadVendorInfo.headerErrors && this.state.uploadVendorInfo.headerErrors.length > 0
        let errors = []
        // let errorMessage = this.state.errorMessage
        if (this.state.uploadVendorInfo && this.state.uploadVendorInfo.headerErrors && this.state.uploadVendorInfo.headerErrors.length > 0) {
            errors = this.state.uploadVendorInfo.headerErrors.map((x, i) => {
                return { ...x, key: i }
            })
            // errorMessage = "The file is missing required column headers"
        } else if (this.state.uploadVendorInfo && this.state.uploadVendorInfo.valueErrors && this.state.uploadVendorInfo.valueErrors.length > 0) {
            errors = this.state.uploadVendorInfo.valueErrors.map((x, i) => {
                return { ...x, key: i }
            })
        }
        let errorMessage = "The file you uploaded contains the below error records. Please update these records and retry."

        let errorNum = 0
        if (this.state.uploadVendorInfo && this.state.uploadVendorInfo.recordsErrorCount) {
            errorNum = this.state.uploadVendorInfo.recordsErrorCount
        }
        let successNum = 0
        if (this.state.uploadVendorInfo && this.state.uploadVendorInfo.recordsTotalCount && this.state.uploadVendorInfo.recordsErrorCount) {
            successNum = this.state.uploadVendorInfo.recordsTotalCount - this.state.uploadVendorInfo.recordsErrorCount
        }

        return (
            <>
                <FlexColumn start>
                    <Text heading>Import {this.props.uploadTypeTitle || "Vendors"}</Text>
                    <Text>Import a list of {this.props.uploadType || "vendors"} using our standard template</Text>

                    <TextButton margin='24px 0 0' weight='400' rightIcon={<Image src={Download} />} onClick={() => saveAs(this.props.downloadLink || "/Vendor_template.csv", `Vendor_template.csv`)} text='Download CSV Template' />
                        {/* <a href={this.props.downloadLink || "/Vendor_template.csv"} download={this.props.downloadName || "Vendor_template.csv"} style={{ color: theme.colors.secondary1 }}> Download CSV Template</a>
                    </Button> */}

                    <div style={{ display: "flex", justifyContent: "center", marginTop: '30px' }} >
                        <Upload {...uploadProps} fileList={this.state.fileList}>
                            <CardButton>
                                <img src={UploadIcon} width="25" height="25" style={{ marginRight: "15px", opacity: 0.8 }} />
                                <FlexColumn start>
                                    <CardButtonText>Upload File</CardButtonText>
                                    <Text color={theme.colors.systemGray}>Please upload a file using the CSV template above</Text>
                                </FlexColumn>
                            </CardButton>
                        </Upload>
                    </div>
                </FlexColumn>
                <Modal
                    visible={this.state.showErrorModal}
                    footer={null}
                    closable={true}
                    width={750}
                    style={{ top: 10 }}
                    destroyOnClose={true}
                    onCancel={() => {
                        this.setState({ showErrorModal: false })
                    }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <Result
                        uploadTypeTitle="Error log"
                        showHeaderErrorTable={showHeaderErrorTable}
                        errors={errors}
                        errorNum={errorNum}
                        successNum={successNum}
                        errorMessage={errorMessage}
                    />
                </Modal>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        bkStore: state.bookkeepingAppReducer,
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Import))