import React, { Component } from 'react';
import { message } from 'antd';
import AddModalBase from './AddModalBase';

// Util
import environment from '../../../environment'
import { apiPOSTReq } from '../../../Utils/api'
import { ErrorAlert } from '../../Reusable/Alert';
import ModalClose from '../../../Images/modal-close.png';
import moment from 'moment'

class AddModalMSA extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showError: false,
            EndDate: null,
			StartDate: null,
			contractValue: null,
        };
    }

    onCancel = () => {
        this.setState({ 
            showError: false, 
            EndDate: null,
            StartDate: null,
            contractValue: null,
            FileName: null,
            URI: null
         });
        this.props.onCancel();
    }

	submitMSA = (values) => {
		const { aionStore, store, customerId, loanApp } = this.props;
        this.setState({
            EndDate: values.EndDate,
			StartDate: values.StartDate,
			contractValue: values.contractValue,
            FileName: values.FileName,
            URI: values.URI
        })
        if (!values.StartDate) {
            ErrorAlert({ description: `Please provide Valid From` });
            return;
        }
        if (!values.EndDate) {
            ErrorAlert({ description: `Please provide Valid Through` });
            return;
        }
		if (!values.FileName) {
			ErrorAlert({description: `Please upload a supporting document.`})
			return
		}
        console.log(2, moment(values.EndDate).diff(moment(values.StartDate)));
        if(moment(values.EndDate).diff(moment(values.StartDate)) < 0){
            ErrorAlert({ description: `Please provide Valid Through` });
            return;
        }
		this.setState({ showError: false });
        const headers = {
            AionCurrentBiz: aionStore.BusinessUniqueKey,
            AionAuth: aionStore.UAM.encryptedAuthHeader // This will be replaced with the token received from the access mgmt service
        }
        var body = {
			msa: {
				"BusinessId": aionStore.BusinessUniqueKey,
				"CustomerId": customerId, 
				"EndDate": values.EndDate,
                "validThrough": values.EndDate,
                "validFrom": values.StartDate,
                "contractValue": values.contractValue ? parseFloat(values.contractValue.toString().replaceAll(',', '')) : null,
				"DocumentURL": {
					"FileName": values.FileName,
					"URI": values.URI,
					"ObjectType": "MSA"
				}
			}
        }
        if(loanApp) body.msa.applicationId = loanApp.applicationId;
        console.log("/ive/msa", body)
        apiPOSTReq(`${environment.iveBaseUrl}/ive/msa/validate`, headers, body, (err, resp) => {
            try {
                const data = resp;
                if (data.result) {
                    this.setState({
                        EndDate: null,
                        StartDate: null,
                        contractValue: null,
                        FileName: null,
                        URI: null
                    });
                    message.success('MSA added successfully!');
                    this.props.onAdd(); // Closes modal and refresh
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("ERRR", error, err, resp)
                ErrorAlert({ description: error.message || "Error adding MSA." });
            }
        })
    }

    render() {
        return (
            <AddModalBase
                type="MSA"
                visible={this.props.visible}
                title="Master Service Agreement"
                onAdd={this.props.onAdd}
                onCancel={this.onCancel}
                onSubmit={this.submitMSA}
                showError={this.state.showError}
                errorMsg={this.state.errorMsg}
                aionStore={this.props.aionStore}
                store={this.props.store}
                EndDate={this.state.EndDate}
                StartDate={this.state.StartDate}
                contractValue={this.state.contractValue}
                FileName={this.state.FileName}
                URI={this.state.URI}
            />
        )
    }
}

export default AddModalMSA;
