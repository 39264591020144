import React from 'react'
import { withTheme } from 'styled-components'
import { Modal } from 'antd'

import { Text, Paragraph } from './Text'
import { FlexColumn } from './Container'
import { Button } from './Button'
import fatal_error from '../../Images/pluto-fatal-error.png'

export const FAQModal = (props) => {
    var { type, title, description, theme, subTitle, theme } = props
    var childViews
    if (type === "getting-started") {
        subTitle = "Getting Started"
        title = "Things you need to know"
        description = "Get the best out of your Aion account"
        const contentItems = [
            {
                title: <span>Pay your bills for <span style={{ color: theme.colors.secondary4 }}>free</span> with ACH, Bill Pay or Checks</span>,
            },
            {
                title: <span>Accept digital payments, <span style={{ color: theme.colors.secondary4 }}>free</span> and <span style={{ color: theme.colors.secondary4 }}>unlimited</span></span>,
            },
            {
                description: <span style={{ fontSize: "1.1rem" }}>Please note that during the first 30 days of your new account:</span>
            },
            // {
            //     title: "Opening Deposit",
            //     description: "You’ll see your opening deposit posted to the account within 2-3 business days. The deposit is held for 5 business days for your security."
            // },
            // {
            //     title: "Debit Card",
            //     description: "A debit card is issued on account opening. Please allow 7-10 business days for the card to reach you at your business mailing address."
            // },
            {
                title: "Mobile Deposits",
                description: "Mobile check deposits are held for 5 business days. After 30 days, this will change to 2 business days."
            },
            {
                title: "Incoming ACH Debits",
                description: "Transfers from other financial institutions are held for 5 business days. After 30 days, this will change to 2 business days."
            },
            {
                title: "Outgoing ACH Payments",
                description: "The daily cut-off time for ACH payments is 6:30 pm ET. Payments created after this time are processed the next business day."
            },
            {
                title: "Wire Payments",
                description: "The daily cut-off time for Wire payments is 3:00 pm ET. Payments created after this time are processed the next business day."
            }
        ]
        childViews = []
        contentItems.forEach((item, i) => childViews.push(<FlexColumn key={i} left>
            <Text theme={theme} heading>{item.title}</Text>
            <Text theme={theme} lightText margin='0 0 16px'>{item.description}</Text>
        </FlexColumn>))
    }
    Modal.info({
        title: subTitle,
        content: (
            <FlexColumn start>
                <FlexColumn start style={{ margin: "20px 0 10px 0" }} >
                    <Text theme={theme} heading>{title}</Text>
                    <Text theme={theme} lightText>{description}</Text>
                </FlexColumn>
                {childViews}
            </FlexColumn>
        ),
        okButtonProps: { size: "large" },
        style: { top: 10 },
        okText: "Got It",
        ...props,
        width: 800,
        destroyOnClose: true
    })
}

export const LockedAccountModalContent = (props) => {
    var { theme, lockType } = props
    return (
        <FlexColumn center style={{ minHeight: "100vh" }}>
            <FlexColumn left start style={{ border: `1px solid ${theme.colors.systemGray5}`, width: "1400px", padding: "50px", borderRadius: "5px" }}>
                <img
                    src={fatal_error}
                    width={350}
                    alt="image"
                    style={{ marginBottom: "50px", alignSelf: "center" }}
                />
                <div style={{ textAlign: "justify" }}>
                    <Text heading>Your bank account has been {lockType.toLowerCase()}</Text>
                    <Paragraph level={2}>Please read below on why we block or freeze accounts and what you can do.
                    </Paragraph>
                    <Paragraph level={2}>
                        <b>Blocking an account is a legal requirement</b>
                        <br />
                        Aion bank accounts are FDIC insured we must follow precise rules set by banking regulators that are aimed at preventing fraudulent guys from moving money around and funding criminal activities. These are commonly known as AML (anti-money-laundering) and counter-terrorism financing rules.  These rules mean we must act in certain ways when we detect that Aion banking accounts are used for suspicious activity. And that’s a good thing – it protects us from terrorists and money launderers and protects you from crime.
                    </Paragraph>
                    <Paragraph level={2}>
                        <b>Freezing an account protects your money</b>
                        <br />
                        We do not freeze, block, or close an account randomly. If our fraud systems detect that someone else may have accessed your account, we’ll freeze it as soon as possible. This means that we prevent any money from moving in or out of the account. We’ll unfreeze the account once we know that things are fine.
                    </Paragraph>
                    <Paragraph level={2}>
                        <b>We also freeze accounts when we suspect criminal activity</b>
                        <br />
                        We cannot list out every reason why we might freeze or block an account or explain the ways we detect fraud, otherwise criminals would figure out how to get around them. But we can give a broad description of times we might do it:
                        <br />
                        <br />
                        <ul>
                            <li>
                                <b>We spot unusual activity.</b> There are few common signs that a banking account is being used for criminal activity like money laundering. We’ll freeze accounts when we suspect this is happening.
                            </li>
                            <li>
                                <b>We identify a high-risk customer.</b> Sometimes we’re told by other banks that an Aion banking account has been linked to suspicious activity. When we’re updated with this information, we’ll freeze the account on our side to investigate.
                            </li>
                            <li>
                                <b>The police tell us to block an account.</b> This can happen as part of financial crime investigation, and we’ll co-operate with any requests from law enforcement.
                            </li>
                        </ul>
                    </Paragraph>
                    <Paragraph level={2}>
                        <b>We’re not allowed to say why we’ve blocked an account</b>
                        <br />
                        We know this can be very frustrating. Please note we’re not deliberately ignoring anyone, it’s just the way the law and systems work.
                        <br />
                        <br />
                        It is very common for criminals to try and get into their accounts by putting pressure on us, and sometimes getting other people to join in too. This can be as simple as coming up with a very sad story or posting negative comments about Aion. This is all part of the job and unfortunately just something our team must deal with. Please note that we treat every customer with the same level of integrity, support, and partnership.
                    </Paragraph>
                    <Paragraph level={2}>
                        <b>False-positives do happen</b>
                        <br />
                        It does sometimes (rarely though) happen that we’ll block an account that looks suspicious, but after investigating find there’s a perfectly reasonable (and legal) explanation for the activity. When this happens, we unfreeze the account and do the right thing for our customer.
                        <br />
                        <br />
                        Banking is a highly regulated industry, and for good reason. Following the misconduct that’s led to global financial disaster in the past, and other banking scandals over the years, these laws exist to help keep your money safe.
                    </Paragraph>
                </div>
                <Button solid permtype="Override" onClick={() => props.onConfirm()} text='Got It' margin='12px 0 0' />
            </FlexColumn>
        </FlexColumn>
    )
}