import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import moment from 'moment'
import { Modal, Drawer, Select, message } from 'antd'

import _ from 'lodash'
import { Text, Tag } from '../../Reusable/Text'
import { Image } from '../../Reusable/Image';
import { Flex, FlexColumn } from '../../Reusable/Container'
import { Button, ImageButton, TextButton } from '../../Reusable/Button'
import { LabeledInput } from '../../Reusable/Input'
import { ErrorAlert } from '../../Reusable/Alert'
import DeleteModal from '../../Reusable/DeleteModal'
import { getDateInLocalTZ, getUserApps, toCurrency, getDateByTZ } from '../../../Utils/util'
import { apiPOSTReq } from '../../../Utils/api'
import environment from '../../../environment'
import { ExpenseCategory } from '../../Reusable/ExpenseCategory';

import Dot from '../../../Images/dot-grey.png'
import ModalClose from '../../../Images/modal-close.png'
import ChevronUp from '../../../Images/chevron-up.png'
import ChevronDown from '../../../Images/chevron-down.png'
import MailReceipt from '../../../Images/mail-receipt.png'


const dFormat = "ll; h:mm A z"
const etTimeZone = "America/New_York"

class HistoryDrawer extends Component {

    constructor(props) {
        super(props)

        var ABLUser = false

        const userApps = getUserApps(this.props.aionStore)

        if (userApps && userApps.includes("ABLCredit")) {
            ABLUser = true
        }

        this.state = {
            documentsLoading: true,
            msasLoading: true,
            showMSAModal: false,
            pagination: {
                current: 1,
                pageSize: 100,
                showSizeChanger: false,
            },
            sorter: {},
            ABLUser: ABLUser,
            dashboardLoading: true,
            loading: false,
            selectedTab: 'details',
            transferMethods: [],
            fileList: [],
            name: props.msa ? props.msa.name : null,
            contractValue: props.msa ? props.msa.contractValue : null,
            product: this.props.product || {},
        }

        this.customer = this.props.location.state && this.props.location.state.customer

        this.nameRef = React.createRef()
        this.typeRef = React.createRef()
        this.incomeAccountRef = React.createRef()
        this.unitPriceRef = React.createRef()
    }

    getInvoiceLogs = (emailMessageId, index) => {
        var body = {
            emailMessageId,
        }

        console.log("Invoices invoice/getInvoiceEmailLogs body", body)

        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/getInvoiceEmailLogs`, null, body, (err, resp) => {
            try {
                const data = resp || {}

                console.log("Invoices invoice/getInvoiceLogs Email", data)

                this.setState({
                    [`action${index}EmailLog`]: data.invoiceEmailLog,
                    [`action${index}EmailLoading`]: false,
                    [`action${index}Open`]: true,
                })
            } catch (error) {
                console.log("ERRR getInvoiceEmailLogs", error, err, resp)
                ErrorAlert({ description: error.message })
            } finally {
                // this.setState({ voidLoading: false })
            }
        })
    }

    render() {
        const { invoiceLog, visible, onClose, invoice } = this.props;
        var { loading, showDelete, addLoading, deleteLoading, errorField, product } = this.state
        const { productId, name, type, sku, unitPrice, description, accountCategoryName } = (product || {});

        return (
            <>
                <Drawer
                    visible={visible}
                    placement="right"
                    closable={true}
                    onClose={() => {
                        onClose()
                    }}
                    mask={!showDelete}
                    maskClosable={true}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                    width='444px'
                    destroyOnClose={true}
                >
                    <FlexColumn start gap="0" fullHeight style={{ paddingBottom: 24, marginBottom: 24 }}>
                        <Text heading margin='0 0 24px'>Invoice History: {invoice?.docNumber}</Text>

                        {
                            (!invoiceLog || invoiceLog?.invoiceActions?.length === 0) ?
                                <Flex fullWidth center style={{ height: '100px' }}>
                                    <Text>No history available for this invoice</Text>
                                </Flex>
                                :
                                invoiceLog?.invoiceActions?.map((action, index) => {
                                    const { actionType, actionDate, actionName, modifiedBy, emailMessageId, paymentDate, paymentAmount } = action
                                    const open = this.state[`action${index}Open`];
                                    const emailLoading = this.state[`action${index}EmailLoading`];
                                    const emailLog = this.state[`action${index}EmailLog`];

                                    return <Flex start fullWidth gap='16px'>
                                        <FlexColumn grow fullHeight center>
                                            <Image src={Dot} />
                                            <div style={{ width: 1, background: '#8F9DBB', height: '100%' }} />
                                        </FlexColumn>
                                        <FlexColumn start fullWidth style={{ marginBottom: invoiceLog?.invoiceActions?.length - 1 === index ? 0 : 24 }} fullHeight>
                                            {
                                                actionDate &&
                                                <Text color='#666666' size='14px'>{getDateByTZ(actionDate, null, "America/New_York")}</Text>
                                            }

                                            <Flex between centerHorizontally>
                                                {(() => {
                                                    switch (actionType) {
                                                        case 'revised':
                                                            return <Tag primary>{actionName}</Tag>
                                                        case 'payment':
                                                            return <FlexColumn start gap='8px'>
                                                                <Text>{actionName}</Text>
                                                                <div>
                                                                    <Tag primary>${toCurrency(paymentAmount)} paid{paymentDate ? ` on ${getDateByTZ(paymentDate, null, "America/New_York")}` : ''}</Tag>
                                                                </div>
                                                            </FlexColumn>
                                                        default:
                                                            return <Text>{actionName}</Text>
                                                    }
                                                })()}

                                                <Tag>{modifiedBy}</Tag>
                                            </Flex>

                                            {
                                                emailMessageId &&
                                                <FlexColumn start fullHeight style={{ paddingBottom: 0, marginTop: 16 }}>
                                                    {
                                                        open && <FlexColumn start style={{ margin: '0 0 24px 0' }}>
                                                            {
                                                                emailLog?.events?.map((event, index) => {
                                                                    return <Flex start fullWidth gap='16px' fullHeight>
                                                                        <FlexColumn grow fullHeight center>
                                                                            <Image src={MailReceipt} />
                                                                            <div style={{ width: 1, background: '#8F9DBB', height: '100%' }} />
                                                                        </FlexColumn>
                                                                        <FlexColumn start fullWidth style={{ marginBottom: emailLog?.events?.length - 1 === index ? 0 : 24 }}>
                                                                            <Text color='#666666' size='14px'>{moment(event.eventDate).tz(etTimeZone).format(dFormat)}</Text>

                                                                            <Flex between centerHorizontally>
                                                                                <Text>{event.eventName}</Text>

                                                                                <Tag noCap>{event.recipientEmail}</Tag>
                                                                            </Flex>
                                                                        </FlexColumn>
                                                                    </Flex>
                                                                })
                                                            }
                                                        </FlexColumn>
                                                    }
                                                    <TextButton
                                                        underline
                                                        onClick={() => {
                                                            if (!open) {
                                                                this.setState({ [`action${index}EmailLoading`]: true })
                                                                this.getInvoiceLogs(emailMessageId, index)
                                                            } else {
                                                                this.setState({
                                                                    [`action${index}EmailLoading`]: false,
                                                                    [`action${index}Open`]: false,
                                                                })
                                                            }
                                                        }}
                                                        weight='400'
                                                        text='View email status'
                                                        loading={emailLoading}
                                                        rightIcon={<Image src={open ? ChevronUp : ChevronDown} />}
                                                    />
                                                </FlexColumn>
                                            }
                                        </FlexColumn>
                                    </Flex>
                                })
                        }
                    </FlexColumn>
                </Drawer>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(HistoryDrawer)))