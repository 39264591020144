import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { Text } from '../../../Reusable/Refresh/Text';
import { Image } from '../../../Reusable/Image';
import { FlexColumn, Flex } from '../../../Reusable/Container';
import FlowViewComponent from '../../../Reusable/Refresh/FlowViewComponent';
import { FlowViewContainer } from '../../../Reusable//FlowViewContainer';

import { ErrorAlert } from '../../../Reusable/Alert';
import { creditPlustFlowViewData } from './viewData';
import { apiPOSTReq } from '../../../../Utils/api';
import environment from '../../../../environment';
import { Skeleton } from 'antd';

// Actions
import { addDataToStore, CREDIT_SAVE_DATA, SAVE_DATA, ONB_SAVE_DATA, UAM_SAVE_BUSINESS, BUSINESS_INFO, PERSONAL_INFO, CO_OWNER_APP_INFO } from '../../../../Actions/actions';
import { UAM_SAVE_PERMISSIONS } from '../../../../Actions/actions';
import LockSteps from '../../../Reusable/Refresh/LockSteps';
import { _ } from 'lodash';
import CustomerProposal from '../CustomerProposal';
import CompleteApplication from '../CompleteApplication';
import BusinessLookup from '../../Reusable/BusinessLookup';
import BusinessProfile from '../../Reusable/BusinessProfile';
import YourCreditApplication from '../../Reusable/YourCreditApplication';
import CreditGetStarted from '../../Reusable/CreditGetStarted';
import SplashPage from '../../Reusable/SplashPage';
import CreditUserProfile from '../../Reusable/CreditUserProfile';
import OwnerInfo from '../OwnerInfo';
import FinancialInfo from '../../Reusable/FinancialInfo';
import PSReview from '../../Reusable/PSReview';
import moment from 'moment'
import CheckGreen from '../../../../Images/check-green.png'
import InfoSquare from '../../../../Images/info-square.png';
import DocAdd from '../../../../Images/doc-add.png';
import { GettingStartedCardBlue } from '../../../Reusable/Card'
import ArrowRight from '../../../../Images/arrow-right.png'
import PreScreenLoading from './PreScreenLoading';
import { getUserPermissions } from '../../../../Utils/userAccess';
import ConnectionInfo from '../../Reusable/ConnectionInfo';

const applicationSteps = ["Submit Application", "Get Pre-approved", "Finalize Application", "Sign Agreement"]

var preScreenDocs = [
    { name: "Company-prepared balance sheet — most recent closed month" },
    { name: "Company-prepared balance sheet — most recent fiscal year end" },
    { name: "Company-prepared profit & loss statement — YTD through most recent closed month" },
    { name: "Company-prepared profit & loss statement — full year for most recent fiscal year end" }
];
const etTimeZone = "America/New_York";

class CreditPlus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentViewIndex: 0,
            submitLoading: false,
            loading: false,
            requestFinancials: true,
            loanAppLoading: true,
            businessInfo: {},
            ownerInfo: [],
            subsidiaries: [],
            rowItem: {},
            newOwner: true,
            newSubsidiary: true,
            selectedType: "",
            step: 0,
            flowSteps: [
                // "Your Information",
                // "Business Information",
                // "Ownership Information",
                // "Financial Information",
                // "Review and Submit"
            ],
            applicationStep: 0,
            showQBUploadModal: false,
            hideViewApp: false
        }
        this.flowViews = creditPlustFlowViewData;
    }

    componentDidMount() {
        window.scroll({ top: 0, behavior: 'smooth' })
        const { store, onboardingStore } = this.props;
        const { initialBusinessId, BusinessUniqueKey } = store;
        console.log("componentDidMount", onboardingStore, store)
        if (BusinessUniqueKey) {
            this.fetchBusinessData();
        }
    }

    fetchBusinessData = () => {
        var body = {
            "clientBusinessId": this.props.store.BusinessUniqueKey
        }

        console.log("/business/admin/getBusinessData body", body)
        this.setState({ loading: true })
        apiPOSTReq(`${environment.uamBaseUrl}/business/admin/getBusinessData`, null, body, (err, resp) => {
            this.setState({ loading: false })
            try {
                const data = resp || {}
                if (data.result) {
                    var business = data.business || {};
                    var { businessProfile } = business;
                    var { businessInfo } = businessProfile || {};
                    this.setState({
                        business: data.business, businessInfo: businessInfo
                    });
                    this.props.dispatch(addDataToStore(ONB_SAVE_DATA, data.business));
                    this.props.dispatch(addDataToStore(UAM_SAVE_BUSINESS, data.business));
                    this.props.dispatch(addDataToStore(BUSINESS_INFO, data.business.businessProfile.businessInfo));
                    this.props.dispatch(addDataToStore(PERSONAL_INFO, data.business.businessProfile.primaryApplicantInfo));
                    this.props.dispatch(addDataToStore(CO_OWNER_APP_INFO, data.business.businessProfile.coOwnerApplicantInfo));
                    this.fetchLoanApp();
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp)
                // ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    fetchLoanApp = () => {
        const { UserInfo } = this.props.store || {};
        // Fetch document list
        var body = {
            "clientBusinessId": this.props.store.BusinessUniqueKey,
            "size": 100,
            "page": 0,
            // "sortDirection": "ASC"
        }
        this.setState({ loanAppLoading: true });
        apiPOSTReq(`${environment.opsBaseUrl}/ops/loans/application/getApplications`, {}, body, (err, resp) => {
            console.log("/getApplications resp body", resp, body);
            try {
                const data = resp || {};
                if (data.result) {
                    var loanApplications = data.loanApplications || [];
                    if (loanApplications.length === 0) {
                        this.updateLoanApp({
                            "applicantFirstName": UserInfo.FirstName,
                            "applicantLastName": UserInfo.LastName,
                            "emailAddress": UserInfo.Email,
                            "userStatus": "PreScreenStarted"
                        });
                    }
                    else {
                        var loanApp = loanApplications.length > 0 ? loanApplications[loanApplications.length - 1] : {};
                        this.setState({ loanApplications: loanApplications, loanApplication: loanApp });
                        this.props.dispatch(addDataToStore(CREDIT_SAVE_DATA, { loanApplication: loanApp }));
                        this.fetchDocuments();

                        if (loanApp.underwritingStatus == 'Proposal Issued' || loanApp.underwritingStatus == 'Proposal Accepted' || loanApp.underwritingStatus == "Proposal Declined" || loanApp.underwritingStatus == 'Proposal Expired' || loanApp.userStatus == "Completed")
                            this.fetchProposalTerms();
                        this.fetchLoanApplicationRequest();
                        this.loadAppFromState();
                    }
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/getApplications err", error, resp);
                // ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            } finally {
                this.setState({ loanAppLoading: false });
            }
        })
    }

    updateLoanApp = (updateData) => {
        const { BusinessUniqueKey } = this.props.store;
        const { loanApplication } = this.state;
        var body = {
            clientBusinessId: BusinessUniqueKey,
            loanApplication: Object.assign(loanApplication || {}, updateData)
        }
        console.log("updateLoanApp", body);
        apiPOSTReq(`${environment.opsBaseUrl}/ops/loans/application/save`, {}, body, (err, resp) => {
            console.log("updateLoanApp /ops/loans/application/save err, resp", err, resp);
            try {
                const data = resp || {};
                if (data.result) {
                    this.props.dispatch(addDataToStore(CREDIT_SAVE_DATA, { loanApplication: data.loanApplication }));
                    this.setState({ loanApplication: data.loanApplication });
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp);
                ErrorAlert({ description: `Error updating application. ${error.message}` || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    fetchDocuments = () => {
        const { loanApplication } = this.state;
        var body = {
            "sortDirection": "DESC"
        };
        if (loanApplication) {
            body["clientBusinessId"] = loanApplication.businessId;
            body["applicationId"] = loanApplication.applicationId;
            body["viewBy"] = "FILTER_APPLICATION"
        }
        console.log("/docs/getActiveDocuments body", body, loanApplication);
        this.setState({ loading: true });
        apiPOSTReq(`${environment.uamBaseUrl}/docs/getActiveDocuments`, {}, body, (err, resp) => {
            console.log("/docs/getActiveDocuments resp", resp);
            this.setState({ loading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    this.setState({
                        businessDocuments: data.businessDocuments
                    });
                } else {
                    if (data.responseMessage != 'Application Id is a required field.') {
                        throw Error(data.responseMessage || data.error)
                    }
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    fetchLoanApplicationRequest = () => {
        // Fetch document list
        const { loanApplication } = this.state;
        var body = {
            "businessId": this.props.store.BusinessUniqueKey,
            "applicationId": loanApplication.applicationId,
            "uploadCategory": "PreScreen"
            // "sortDirection": "ASC"
        };
        this.setState({ loanAppLoading: true });
        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/getActiveLoanRequest`, {}, body, (err, resp) => {
            console.log("/getApplications resp body", resp, body);
            try {
                const data = resp || {};
                if (data.result) {
                    var loanApplicationRequests = data.requests || [];
                    this.setState({ loanApplicationRequests: loanApplicationRequests })
                    if (loanApplicationRequests.length > 0) {
                        this.setState({ hasLoanApplicationRequest: true });
                    } else {
                        this.setState({ hideViewApp: true });
                    }
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/getApplications err", error, resp);
                // ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            } finally {
                this.setState({ loanAppLoading: false });
            }
        })
    }

    fetchProposalTerms = () => {
        const { loanApplication } = this.state;
        var body = {
            "clientBusinessId": loanApplication.businessId,
            "applicationId": loanApplication.applicationId
        };

        this.setState({ loanAppLoading: true });
        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/getProposalTerms`, {}, body, (err, resp) => {
            console.log("/getProposalTerms resp body", resp, body);
            this.setState({ loanAppLoading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    this.setState({
                        proposalTerms: data.offers[0], offers: data.offers
                    });
                    this.props.dispatch(addDataToStore(SAVE_DATA, { proposalTerms: data.offers[0] }));

                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/getProposalTerms err", error, resp);
                // ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    loadAppFromState = () => {
        var { loanApplication } = this.props.creditStore;
        var { userStatus } = this.props.onboardingStore;
        var { applicationStep } = this.state;
        loanApplication = loanApplication || {};
        console.log("loadAppFromState userStatus applicationStep loanApplication", loanApplication);
        var currentStep = 0;
        if (loanApplication.preScreenStatus === "Proposal Accepted") {
            //Write code here for dd flow.
            // if (loanApplication.productType === "RLOC") {
            //     if (loanApplication.userStatus === "PreScreenCompleted") {
            //         this.props.history.push("/apply/credit/due-diligence")
            //     } else {
            //         const value = queryString.parse(this.props.location.search)
            //         var searchString = this.props.history.location.search;
            //         const state = value.state
            //         const url = window.location.href
            //         console.log("/credit/eligibility componentDidMount", state, url, this.props.location.search)
            //         if (state && state === 'qb-credit-eligibility') {
            //             var searchString = this.props.history.location.search;
            //             this.props.history.push({
            //                 pathname: '/apply/credit/due-diligence',
            //                 state: {
            //                     data: searchString,
            //                     url: url
            //                 }
            //             })
            //         }
            //     }
            // }
            currentStep = this.flowViews.length - 1;
            if (loanApplication.underwritingStatus !== "Not Started" && loanApplication.underwritingStatus !== "Under Review" && loanApplication.userStatus === "Completed") {
                applicationStep = 3
            }
            else {
                applicationStep = 2
            }
        } else if (loanApplication.userStatus === "PreScreenCompleted" || loanApplication.underwritingStatus === "Proposal Ready" || loanApplication.underwritingStatus === "Proposal Issued" ||
            loanApplication.underwritingStatus === "Processing" ||
            loanApplication.underwritingStatus === "Auto Rejected" || loanApplication.underwritingStatus === "Manually Rejected" ||
            loanApplication.underwritingStatus === "Proposal Declined") {
            console.log("loadAppFromState userStatus applicationStep CASE2");
            currentStep = this.flowViews.length - 1
            if (loanApplication.userStatus === "PreScreenCompleted" && loanApplication.underwritingStatus === "Proposal Issued") {
                applicationStep = 1
            }
            else if (loanApplication.underwritingStatus === "Proposal Declined") {
                applicationStep = 1
            }
            else {
                applicationStep = 0
            }
            console.log("loadAppFromState PreScreenCompleted applicationStep", applicationStep);
        } else {
            console.log("loadAppFromState userStatus applicationStep CASE3");
            console.log(" loadAppFromState else case PreScreenCompleted applicationStep", applicationStep);
            if (userStatus != "Apply.PreScreenSubmitted") {
                console.log("loadAppFromState != Apply.PreScreenSubmitted", userStatus)
                this.flowViews.forEach((item, i) => { if (item.FlowStep == userStatus) currentStep = i + 1 });
            }
            applicationStep = 0
        }
        console.log("loadAppFromState currentStep applicationStep", currentStep, applicationStep)
        this.setState({ currentViewIndex: currentStep, applicationStep })
    }

    saveProposal = (status, reason, callback) => {
        const { creditStore } = this.props;
        var { loanApplication } = creditStore;
        var { store } = this.props;
        var { UserInfo } = store;
        loanApplication = loanApplication || {};

        var body = {
            "clientBusinessId": loanApplication.businessId,
            "applicationId": loanApplication.applicationId,
            "verificationStatus": status
        };

        if (reason && reason != null) {
            const { UserInfo } = this.props.store;
            body.reviewerNote = reason;
            body.reviewer = `${UserInfo.FirstName} ${UserInfo.LastName}`;
            body.reviewerEmail = `${UserInfo.Email}`;
        }

        console.log("/ops/application/sendProposal body", body);
        this.setState({ sendProposalLoading: true });
        apiPOSTReq(`${environment.opsBaseUrl}/ops/loans/application/sendProposal`, {}, body, (err, resp) => {
            this.setState({ sendProposalLoading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    if (status == "Proposal Accepted") {
                        //Email Triggered to User confirmation
                        var userNotificationBody = {
                            "fromAddr": "Aion <notification@aionfi.com>",
                            "toAddr": process.env.REACT_APP_ENV != "production" ? "stagingmoderators@mg.aionfi.com" : "credit@aionfi.com",
                            "ccAddr": process.env.REACT_APP_ENV != "production" ? "stagingmoderators@mg.aionfi.com" : "",
                            "bccAddr": process.env.REACT_APP_ENV != "production" ? "akumar@aionfi.com" : "",
                            "emailSubject": `Proposal accepted by prospect (ID: ${loanApplication.applicationId})`,
                            "emailBody": `<p>Dear Credit Team,<p>The proposal issued for the following application was accepted by the prospect.</p><p>Please feel free to review their application or proposal by logging in to https://app.aionfi.com.</p><p>ID: <b>${(loanApplication || {}).applicationId}</b><br/>Business: <b>${(loanApplication || {}).businessName}</b><br/>User: <b>${UserInfo.FirstName} ${UserInfo.LastName}</b></p></p>`
                        }

                        apiPOSTReq(`${environment.opsBaseUrl}/ops/sendEmail`, null, userNotificationBody, (err, resp) => {
                            console.log("sendEmail", err, resp, userNotificationBody);
                        })
                    }

                    this.fetchLoanApp();
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/sendProposal err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            } finally {
                if (callback) {
                    callback()
                    this.setState({ showGoodChoiceModal: true })
                }
            }
        });
    }

    acceptProposal = (callback) => {
        this.saveProposal("Proposal Accepted", null, callback);
    }

    declineProposal = (reason) => {
        this.saveProposal("Proposal Declined", reason);
    }

    closeCreditPlus = () => {
        setTimeout(() => {
            this.props.closeCreditPlus();
            getUserPermissions(null, (err, resp) => {
                try {
                    console.log("fetchPermissions resp.data", resp.data)
                    // this.setState({ loading: true })
                    if (err) throw Error(err)
                    if (!resp.data.authToken) throw Error(resp.data.responseMessage)
                    this.props.dispatch(addDataToStore(UAM_SAVE_PERMISSIONS, { UAM: resp.data }));
                    this.props.history.push('/credit/term-loans/advances');
                } catch (error) {
                    ErrorAlert({ description: error.message })
                }
            });
        }, 1000);
    }

    getContentViews = () => {
        const { theme, store, creditStore, onboardingStore, isCreditPlus } = this.props;
        var { currentViewIndex, loanAppLoading, businessInfo, businessDocuments, proposalTerms,
            showDeclineProposalModal, step, flowSteps, applicationStep, loanApplicationRequests, hasLoanApplicationRequest, hideViewApp } = this.state;
        var { address, mailingAddress } = businessInfo;
        var { loanApplication } = creditStore;
        loanApplication = loanApplication || {};
        var childViews = (<div></div>); // Initialize
        var { proposalTerms } = store;

        if (loanAppLoading) {
            return <FlexColumn style={{ margin: '80px', width: '80%' }}>
                <Skeleton loading={true} active title={false} paragraph={{ rows: 25 }}></Skeleton>
            </FlexColumn>
        }


        preScreenDocs.forEach(doc => {
            if ((businessDocuments || []).find(item => item.name == doc.name)) doc.uploaded = false;
        })

        const flowView = this.flowViews[currentViewIndex] || {};
        switch (flowView.FlowStep) {
            case "Apply.Splash":
                return (
                    <div style={{ height: "100vh", width: "100vw" }}>
                        <SplashPage
                            delay={3000}
                            loadNext={() => { if (currentViewIndex === 0) this.loadNext() }}
                        />
                    </div>
                );
            case "Apply.ProductInfo":
                return (
                    <YourCreditApplication
                        loading={loanAppLoading}
                        flowView={flowView}
                        currentViewIndex={currentViewIndex}
                        loadPrev={this.loadPrevView}
                        loadNext={this.loadNext}
                        width={'100%'}
                        showFooter={false}
                        isCreditPlus={true}
                    />
                );
            case "Apply.GetStarted":
                return (
                    <CreditGetStarted
                        loading={loanAppLoading}
                        flowView={flowView}
                        currentViewIndex={currentViewIndex}
                        loadPrev={this.loadPrevView}
                        loadNext={this.loadNext}
                        width={'100%'}
                        showFooter={false}
                        isCreditPlus={true}
                        loanApplication={loanApplication}
                    />
                );
            case "Apply.PersonalInfo":
                return (
                    <CreditUserProfile
                        loading={loanAppLoading}
                        flowView={flowView}
                        currentViewIndex={currentViewIndex}
                        loadPrev={this.loadPrevView}
                        loadNext={this.loadNext}
                        width={'100%'}
                        back={false}
                        padding='0 64px'
                        step={0}
                        steps={flowSteps}
                        showFooter={true}
                        headerMargin='24px 0'
                        loanApplication={loanApplication}
                        applicationStep={applicationStep}
                        goToStep={this.goToStep}
                    />
                );
            case "Apply.Lookup":
                return (
                    <BusinessLookup
                        loading={loanAppLoading}
                        flowView={flowView}
                        currentViewIndex={currentViewIndex}
                        loadPrev={this.loadPrevView}
                        loadNext={this.loadNext}
                        width={'100%'}
                        back={currentViewIndex > 0}
                        padding='0 64px'
                        step={1}
                        steps={flowSteps}
                        showFooter={true}
                        headerMargin='24px 0'
                        loanApplication={loanApplication}
                        applicationStep={applicationStep}
                        goToStep={this.goToStep}
                    />
                );
            case "Apply.Basic":
                return (
                    <BusinessProfile
                        loading={loanAppLoading}
                        flowView={flowView}
                        currentViewIndex={currentViewIndex}
                        loadPrev={this.loadPrevView}
                        loadNext={this.loadNext}
                        loadNextView={this.loadNextView}
                        width={'100%'}
                        back={currentViewIndex > 0}
                        padding='0 64px'
                        step={1}
                        steps={flowSteps}
                        showFooter={true}
                        headerMargin='24px 0'
                        loanApplication={loanApplication}
                        applicationStep={applicationStep}
                        goToStep={this.goToStep}
                    />
                );
            case "Apply.Ownership":
                return (
                    <OwnerInfo
                        loading={loanAppLoading}
                        flowView={flowView}
                        flowViews={this.flowViews}
                        currentViewIndex={currentViewIndex}
                        loadPrev={this.loadPrevView}
                        loadNext={this.loadNext}
                        loadNextView={this.loadNextView}
                        width={'100%'}
                        back={currentViewIndex > 0}
                        padding='0 64px'
                        step={2} // So that step shows as Business Profile
                        steps={flowSteps}
                        showFooter={true}
                        headerMargin='24px 0'
                        loanApplication={loanApplication}
                        applicationStep={applicationStep}
                        goToStep={this.goToStep}
                    />
                )
            case "Apply.Connection":
                return (<ConnectionInfo
                    loading={loanAppLoading}
                    flowView={flowView}
                    flowViews={this.flowViews}
                    currentViewIndex={currentViewIndex}
                    loadPrev={this.loadPrevView}
                    loadNext={this.loadNext}
                    loadNextView={this.loadNextView}
                    width={'100%'}
                    back={currentViewIndex > 0}
                    padding='0 64px'
                    step={3} // So that step shows as Business Profile
                    steps={flowSteps}
                    showFooter={true}
                    headerMargin='24px 0'
                    loanApplication={loanApplication}
                    applicationStep={applicationStep}
                    isCreditPlus={true}
                    goToStep={this.goToStep}
                />)
            case "Apply.Documents":
                return (
                    <FinancialInfo
                        loading={loanAppLoading}
                        flowView={flowView}
                        flowViews={this.flowViews}
                        currentViewIndex={currentViewIndex}
                        loadPrev={this.loadPrevView}
                        loadNext={this.loadNext}
                        loadNextView={this.loadNextView}
                        width={'100%'}
                        back={currentViewIndex > 0}
                        padding='0 64px'
                        step={3}
                        steps={flowSteps}
                        showFooter={true}
                        headerMargin='24px 0'
                        loanApplication={loanApplication}
                        applicationStep={applicationStep}
                        goToStep={this.goToStep}
                        onQBUpload={() => this.setState({ showQBUploadModal: true })}
                        isCreditPlus={true}
                        updateLoanApp={this.updateLoanApp}
                    />
                )
            case "Apply.Review":
                return (
                    <PSReview
                        loading={loanAppLoading}
                        flowView={flowView}
                        flowViews={this.flowViews}
                        currentViewIndex={currentViewIndex}
                        loadPrev={this.loadPrevView}
                        loadNext={this.loadNext}
                        loadNextView={this.loadNextView}
                        width={'100%'}
                        back={currentViewIndex > 0}
                        padding='0 64px'
                        step={4}
                        steps={flowSteps}
                        showFooter={true}
                        headerMargin='24px 0'
                        loanApplication={loanApplication}
                        applicationStep={applicationStep}
                        updateCurrentViewIndex={this.updateCurrentViewIndex}
                        updateLoanApp={this.updateLoanApp}
                        goToStep={this.goToStep}
                    />
                )
            case "Apply.PreScreenLoading":
                flowView.descComponent = (<div style={{ width: 624, marginTop: 4 }}><Text>{flowView.Description}</Text></div>)
                childViews = (
                    <div style={{ height: "100vh" }}>
                        <PreScreenLoading isCreditPlus={true}
                            loadNext={this.loadNext}
                            loadNextView={this.loadNextView}></PreScreenLoading>
                    </div>
                )
                return <FlowViewComponent
                    loading={loanAppLoading}
                    flowView={flowView}
                    currentViewIndex={currentViewIndex}
                    loadPrev={this.loadPrevView}
                    loadNext={this.loadNext}
                    childViews={childViews}
                    submitLoading={this.state.submitLoading}
                    width={'100%'}
                    back={currentViewIndex > 0}
                    // padding='0 64px'
                    step={currentViewIndex}
                    steps={flowSteps}
                    showFooter={currentViewIndex < flowSteps.length}
                    // headerMargin='24px 0'
                    loanApplication={loanApplication}
                    applicationStep={applicationStep}
                    hideSteps={true}
                    cvOverFlow='scroll'
                />
            case "Apply.PreScreenSubmitted":
                flowView.descComponent = (<div style={{ width: 624, marginTop: 4 }}><Text>{flowView.Description}</Text></div>)
                childViews = (
                    ((loanApplication.underwritingStatus == "Rejected" || loanApplication.underwritingStatus == "Approved" || loanApplication.underwritingStatus == "Auto Approved")) ?
                        <div style={{ height: "100%", minHeight: "100vh" }}>
                            {applicationStep >= 2 &&
                                <CompleteApplication loanApplication={loanApplication} isCreditPlus={true} proposalTerms={proposalTerms} closeCreditPlus={this.closeCreditPlus} />
                            }
                        </div>
                        :
                        <div style={{ height: "100%", minHeight: "100vh" }}>
                            <Flex center>
                                <FlexColumn style={{ alignItems: 'center' }}>
                                    <Text size='28px' height='40px' weight='500' style={{ marginBottom: 24 }}>Apply for financing with Aion</Text>
                                    <Text center margin='0 12px 24px' width={'624px'}>Welcome to your one-stop shop for Aion Capital. Complete outstanding tasks, keep track of your application status, and chat with the Aion team throughout the process.</Text>
                                </FlexColumn>
                            </Flex>
                            <LockSteps alignContent={true} error={hasLoanApplicationRequest} key={`${flowView.FlowStep}LockSteps`} step={applicationStep} steps={applicationSteps} />

                            {
                                (applicationStep === 0 && loanApplication.underwritingStatus != "Auto Rejected" && loanApplication.underwritingStatus != "Manually Rejected") ||
                                    (applicationStep == 2 && (loanApplication.underwritingStatus == "Under Review" || loanApplication.underwritingStatus == "Not Started")) ?
                                    <>
                                        {hasLoanApplicationRequest &&
                                            <FlexColumn center width="100%" style={{ margin: "100px 0 100px 0" }}>
                                                <Image margin='24px 0 12px' width='48px' height='48px' src={InfoSquare} />
                                                <Text size='28px' height='40px' weight='500' style={{ marginTop: 4 }}>Additional information required</Text>
                                                <Text center margin='0 12px 24px' width={'400px'}>A few things require your attention before we can continue processing your application</Text>

                                                <GettingStartedCardBlue
                                                    tabIcon={DocAdd}
                                                    title='We need a few documents'
                                                    desc='Select upload below to view your request. Have any questions? Just the “Chat with us” feature on the top navigation bar.'
                                                    buttonText='UPLOAD'
                                                    buttonIcon={ArrowRight}
                                                    onClick={this.onAddionalUploadClick}
                                                    width={'420px'}
                                                    minWidth={'600px'}
                                                />

                                            </FlexColumn>
                                        }
                                        {hideViewApp &&
                                            <FlexColumn center width="100%" style={{ margin: "100px 0 100px 0" }}>
                                                <Image margin='24px 0 12px' width='48px' height='48px' src={CheckGreen} />
                                                <Text size='28px' height='40px' weight='500' style={{ marginTop: 4 }}>Application Submitted</Text>
                                                <Text center margin='0 12px 24px' width={'400px'}>Your application has been submitted and is being reviewed by our team</Text>
                                                <Text center light size="12px" margin='0 12px 24px' width={'300px'} > Submitted on:  {moment.utc(loanApplication.auditData.createTime).tz(etTimeZone).format('MMM DD, YYYY')}</Text>
                                                {/* {
                                                ((applicationStep === 2 && proposalTerms != null && proposalTerms.productType == "GNPL") || applicationStep === 0) &&
                                                <Button
                                                    solid
                                                    text={"VIEW APPLICATION DEAILS"}
                                                    onClick={this.onViewApplication}
                                                    margin='0 0 24px'
                                                />
                                            } */}


                                            </FlexColumn>}

                                    </>
                                    : <>
                                        {applicationStep < 2 &&
                                            <CustomerProposal flowView={flowView} acceptProposal={this.acceptProposal} declineProposal={this.declineProposal} loanApplication={loanApplication} proposalTerms={proposalTerms}></CustomerProposal>
                                        }

                                        {applicationStep >= 2 &&
                                            <CompleteApplication loanApplication={loanApplication} isCreditPlus={true} proposalTerms={proposalTerms} closeCreditPlus={this.closeCreditPlus} />
                                        }
                                    </>
                            }
                        </div>
                )

                return <FlowViewComponent
                    loading={loanAppLoading}
                    flowView={flowView}
                    currentViewIndex={currentViewIndex}
                    loadPrev={this.loadPrevView}
                    loadNext={this.loadNext}
                    childViews={childViews}
                    submitLoading={this.state.submitLoading}
                    width={'100%'}
                    back={currentViewIndex > 0}
                    padding='0 64px'
                    step={currentViewIndex}
                    steps={flowSteps}
                    showFooter={currentViewIndex < flowSteps.length}
                    headerMargin='24px 0'
                    loanApplication={loanApplication}
                    applicationStep={applicationStep}
                    hideSteps={true}
                    cvOverFlow='scroll'
                />
                break;
                // case "Apply.ViewApplication":
                //     childViews = (
                //         <>
                //             {applicationStep < 2 &&
                //                 <>
                //                     <Flex between >

                //                         <Text size='24px' height='40px' weight='500' style={{ marginBottom: 24, marginTop: 8 }}>Your Application </Text>
                //                         <img width='24px' height='24px' src={ModalClose} onClick={() => {
                //                             this.props.history.push("/apply/credit");
                //                             this.setState({ currentViewIndex: this.flowViews.length - 3 })
                //                         }} />
                //                     </Flex>
                //                     <CustomerProposal flowView={flowView} acceptProposal={this.acceptProposal} declineProposal={this.declineProposal} loanApplication={loanApplication} proposalTerms={proposalTerms}></CustomerProposal>

                //                 </>

                //             }

                //             {
                //                 applicationStep === 2 &&
                //                 <>
                //                     <Flex between >
                //                         <Text size='24px' height='40px' weight='500' style={{ marginBottom: 24, marginTop: 8 }}>Your Application </Text>
                //                         <img width='24px' height='24px' src={ModalClose} onClick={() => {
                //                             this.props.history.push("/apply/credit");
                //                             this.setState({ currentViewIndex: this.flowViews.length - 3 })
                //                         }} />
                //                     </Flex>
                //                     <CompleteApplication loanApplication={loanApplication} proposalTerms={proposalTerms} />

                //                 </>
                //             }
                //         </>
                //     )
                return <FlowViewComponent
                    loading={loanAppLoading}
                    flowView={flowView}
                    currentViewIndex={currentViewIndex}
                    loadPrev={this.loadPrevView}
                    loadNext={this.loadNext}
                    childViews={childViews}
                    submitLoading={this.state.submitLoading}
                    width={'100%'}
                    back={currentViewIndex > 0}
                    padding='0 64px'
                    step={currentViewIndex}
                    steps={flowSteps}
                    showFooter={currentViewIndex < flowSteps.length}
                    headerMargin='24px 0'
                    loanApplication={loanApplication}
                    applicationStep={applicationStep}
                    hideSteps={true}
                    cvOverFlow='scroll'
                />
                // case "Apply.AdditionalInformation":
                //     childViews = (
                //         <>

                //             <Flex between >
                //                 <Text size='24px' height='40px' weight='500' style={{ marginBottom: 24, marginTop: 8 }}>Additional Information</Text>
                //                 <img width='24px' height='24px' src={ModalClose} onClick={() => {
                //                     this.props.history.push("/apply/credit");
                //                     this.setState({ currentViewIndex: this.flowViews.length - 3 })
                //                 }} />
                //             </Flex>
                //             <AdditionalInformation
                //                 loading={loanAppLoading}
                //                 flowView={flowView}
                //                 flowViews={this.flowViews}
                //                 currentViewIndex={currentViewIndex}
                //                 loadPrev={this.loadPrevView}
                //                 loadNext={this.loadNext}
                //                 loadNextView={this.loadNextView}
                //                 width={'100%'}
                //                 back={currentViewIndex > 0}
                //                 padding='0 64px'
                //                 step={4}
                //                 steps={flowSteps}
                //                 showFooter={true}
                //                 headerMargin='24px 0'
                //                 loanApplication={loanApplication}
                //                 applicationStep={applicationStep}
                //                 updateCurrentViewIndex={this.updateCurrentViewIndex}
                //                 updateLoanApp={this.updateLoanApp}
                //                 goToStep={this.goToStep}
                //                 loanApplicationRequests={loanApplicationRequests}
                //             />

                //         </>
                //     )
                return <FlowViewComponent
                    loading={loanAppLoading}
                    flowView={flowView}
                    currentViewIndex={currentViewIndex}
                    loadPrev={this.loadPrevView}
                    loadNext={this.loadNext}
                    childViews={childViews}
                    submitLoading={this.state.submitLoading}
                    width={'100%'}
                    back={currentViewIndex > 0}
                    padding='0 64px'
                    step={currentViewIndex}
                    steps={flowSteps}
                    showFooter={currentViewIndex < flowSteps.length}
                    headerMargin='24px 0'
                    loanApplication={loanApplication}
                    applicationStep={applicationStep}
                    hideSteps={true}
                    cvOverFlow='scroll'
                />
            default:
                break;
        }
    }

    render() {
        const { store } = this.props;
        const { currentViewIndex, showQBUploadModal, showGoodChoiceModal } = this.state
        const flowView = this.flowViews[currentViewIndex] || {};
        var { proposalTerms } = store;
        return (
            <FlexColumn
                start
                // fullHeight={!['Apply.ProductInfo', 'Apply.GetStarted', 'Apply.Basic', 'Apply.Review', 'Apply.Ownership'].includes(flowView.FlowStep)}
                fullHeight={true}
                style={{ minHeight: '100%', flexGrow: 1 }} >
                <FlowViewContainer padding="0" alignContent={currentViewIndex == 10 ? false : true} width="100%" height='100%' title={null} contentViews={this.getContentViews()} hidesidebar={true} />
            </FlexColumn>
        )
    }

    goToStep = (step) => {
        const stepMap = {
            "Your Information": "Apply.PersonalInfo",
            "Business Information": "Apply.Basic",
            "Ownership Information": "Apply.Ownership",
            "Financial Information": "Apply.Documents",
            "Review and Submit": "Apply.Review"
        }
        const selectedStep = stepMap[this.state.flowSteps[step]]
        var currentStep = 0
        this.flowViews.forEach((item, i) => { if (item.FlowStep == selectedStep) currentStep = i })
        this.setState({ currentViewIndex: currentStep })
    }

    // Validate input and go to next view if it's good
    loadNext = () => {
        const { loanApplication } = this.state;
        var currentView = this.state.currentViewIndex + 1 == this.flowViews.length - 3 ? this.state.currentViewIndex + 1 : this.state.currentViewIndex;
        var flowView = this.flowViews[currentView];
        var validation = { validated: true };
        if (validation.validated) {
            this.loadNextView();
        } else {
            ErrorAlert({ description: validation.message });
        }
    }

    loadPrevView = () => {
        console.log('loadPrevView currentViewIndex', this.state.currentViewIndex)
        if (this.state.currentViewIndex !== 0) {
            this.setState({
                currentViewIndex: this.state.currentViewIndex - 1,
                showError: false, errorMsg: ""      // dismiss any validatin error, if any, for the current step
            });
        }
    }

    loadNextView = () => {
        console.log('currentViewIndex', this.state.currentViewIndex)
        if (this.state.currentViewIndex + 1 < this.flowViews.length) {
            this.setState({
                currentViewIndex: this.state.currentViewIndex + 1
            });
        }
        window.scrollTo(0, 0);
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer,
        creditStore: state.creditAppReducer,
        onboardingStore: state.onboardingAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(CreditPlus)));