import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import moment from 'moment-business-days';
import { saveAs } from 'file-saver'

// Components
import { Text, Tag } from '../../Reusable/Text'
import { FlexColumn, Flex } from '../../Reusable/Container'
import { LabeledInput } from '../../Reusable/Input'
import { ErrorAlert } from '../../Reusable/Alert'
import { ExpenseCategory } from '../../Reusable/ExpenseCategory'
import { ApproversCard, ApproversStatusCard } from '../../Reusable/Card';
import { ApprovalGraph } from '../../Reusable/Approval'
import PDFDocument from '../../Reusable/PDFDocument';
import { apiPOSTReq, getPreSignedS3Url } from '../../../Utils/api'
import { addressObjectToStr, getBPAccountsWithAccess, getSourceDisplayText } from '../../../Utils/util';
import environment from '../../../environment';

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

class ViewBill extends Component {

    constructor(props) {
        super(props);

        this.state = {
            bill: this.props.bill || {},
            vendor: this.props.vendor || {},
            documentLoaded: false,
            pageNumber: 1,
        };
    }

    getRules = () => {
        apiPOSTReq(`${environment.bbBaseUrl}/bb/approvals/getRules`, { "BankProvider": environment.bankProvider.crb }, { "RuleTypes": ["ACH", "Wire", "Recipient"] }, (err, resp) => {
            try {
                const data = resp;

                console.log("Approvals /bb/getRules", JSON.stringify(data))

                if (data.result) {
                    if (err) throw new Error(err);

                    this.setState({ rules: data.approvalRuleList, loading: false })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getAccounts", error, err, resp);
            }
        })
    }

    componentDidMount() {
        const { bill } = this.state

        this.getRules()

        this.getVendor(bill.vendorId, (vendor) => {
            this.getPaymentMethods(vendor)
            this.evaluatePaymentApproval(bill.amount, vendor.vendorName)
        })

        if ((bill || {}).previewDocumentUrls && bill.previewDocumentUrls.length > 0) {
            getPreSignedS3Url({ url: bill.previewDocumentUrls[0].uri }, (err, preSignedUrl) => {
                if (!err) {
                    this.setState({ billUrl: preSignedUrl })
                } else {
                    ErrorAlert({ description: JSON.stringify(err) })
                }
            })
        }

        if (bill.approvalInstanceId) {
            this.getApprovalRuleInstance(bill.approvalInstanceId)
        }
    }

    downloadAttachment = (url, name) => {
        getPreSignedS3Url({ url: url }, (err, preSignedUrl) => {
            if (!err) {
                saveAs(preSignedUrl, name)
            } else {
                ErrorAlert({ description: JSON.stringify(err) })
            }
        })
    }

    getVendor = (id, callback) => {
        console.log("/payables/vendors/getVendor vendorId: ", id)

        apiPOSTReq(`${environment.payBaseUrl}/payables/vendors/getVendor`, { "BankProvider": environment.bankProvider.crb }, { vendorId: id }, (err, resp) => {
            try {
                const data = resp;
                console.log("/payables/vendors/getVendor", JSON.stringify(data))
                if (data.result && data.vendor) {
                    this.setState({ vendor: data.vendor })
                    callback(data.vendor)
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /payables/vendors/getVendor", error, err, resp);
                // ErrorAlert({ description: error.message })
            }
        })
    }

    getPaymentMethods = (vendor, callback) => {
        // const { vendor } = this.state;
        const body = {
            "vendorId": vendor.associatedCPObjectId
        }
        this.setState({ loading: true });
        apiPOSTReq(`${environment.payBaseUrl}/payables/vendors/getPaymentMethods`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            try {
                this.setState({ loading: false });
                const data = resp;
                console.log("/payables/vendors/getPaymentMethods", JSON.stringify(data))
                if (data.result) {
                    const vendorPaymentAccount = (data.paymentMethods || []).find(account => account.default === true)
                    console.log("/payables/vendors/getPaymentMethods vendorPaymentAccount", JSON.stringify(vendorPaymentAccount))
                    this.setState({ paymentMethods: data.paymentMethods, vendorPaymentAccount })
                } else {
                    // throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getPaymentMethods", error, resp);
                // ErrorAlert({ description: error.message })
            }
            // callback()
        })
    }

    onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
        this.setState({ numPages: nextNumPages });
    }

    previousPage = () => {
        const { pageNumber } = this.state
        this.setState({ pageNumber: pageNumber - 1 })
    }

    nextPage = () => {
        const { pageNumber } = this.state
        this.setState({ pageNumber: pageNumber + 1 })
    }

    evaluatePaymentApproval = (amount, vendorName) => {
        console.log(`${environment.payBaseUrl}/payables/evaluatePaymentApproval`, JSON.stringify({ paymentAmount: amount }))
        apiPOSTReq(`${environment.payBaseUrl}/payables/evaluatePaymentApproval`, { "BankProvider": environment.bankProvider.crb }, { paymentAmount: amount, vendorName }, (err, resp) => {
            this.setState({ messageLoading: false });
            try {
                const data = resp;
                console.log(`${environment.payBaseUrl}/payables/evaluatePaymentApproval`, JSON.stringify(data))
                if (data.result) {
                    this.setState({ approvalRequired: data.approvalRequired, evaluatedApprovalRule: data.approvalRule })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
                // this.setState({ payLoading: false })
            } catch (error) {
                console.log("ERRR /payables/evaluatePaymentApproval", error, err, resp);
                ErrorAlert({ description: error.message })
            }
        })
    }

    getApprovalRuleInstance = (approvalInstanceId) => {
        apiPOSTReq(`${environment.payBaseUrl}/payables/approvals/getApprovalInstance `, { "BankProvider": environment.bankProvider.crb }, { instanceId: approvalInstanceId, }, (err, resp) => {
            try {
                const data = resp;
                console.log("/payables/approvals/getApprovalInstance ", JSON.stringify(data))
                if (data.result && data.approvalRuleInstance) {


                    apiPOSTReq(`${environment.payBaseUrl}/payables/approvals/getRules`, { "BankProvider": environment.bankProvider.crb }, { "RuleTypes": ["Payable"] }, (err, resp) => {
                        try {
                            const data2 = resp;

                            console.log("getApprovalInstance getRules:", JSON.stringify(data2))

                            if (data2.result) {
                                if (err) throw new Error(err);

                                var approvalRule = data2.approvalRuleList.find(rule => { return rule.approvalRuleId === data.approvalRuleInstance.firedRuleId })

                                console.log("getApprovalInstance approvalRule: ", JSON.stringify(approvalRule))
                                console.log("getApprovalInstance approvalRuleInstance: ", JSON.stringify(data.approvalRuleInstance))

                                this.setState({ approvalRule, approvalRuleInstance: data.approvalRuleInstance })
                            } else {
                                throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                            }
                        } catch (error) {
                            console.log("ERRR getAccounts", error, err, resp);
                        }
                    })
                } else {
                    // throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /payables/approvals/getApprovalInstance ", error, err, resp);
                // ErrorAlert({ description: error.message })
            }
        })
    }

    render() {
        const { bill, vendorPaymentAccount, type, numPages, polling, billUrl, vendor, vendors, addVendor, payLoading, fileList, flowStep, documentLoaded, approvalRule, approvalRequired, approvalRuleInstance, evaluatedApprovalRule, pageNumber } = this.state;
        const { theme, categories, aionStore } = this.props
        var { amount, dueDate, notes, coaid, billDate, line } = bill

        let coaIds = (line || []).map(x => {
            if (x.accountBasedExpenseLineDetail && x.accountBasedExpenseLineDetail.accountRef) {
                return x.accountBasedExpenseLineDetail.accountRef.value;
            } else {
                return null;
            }
        })
        let coaList = []
        coaIds.forEach(id => {
            if (id) {
                coaList.push((categories || []).find(x => x.id === id))
            }
        })

        if (dueDate) {
            dueDate = new Date(dueDate).toISOString();
            dueDate = dueDate.split('T')[0];
        }
        if (billDate) {
            billDate = new Date(billDate).toISOString();
            billDate = billDate.split('T')[0];
        }

        var darkMode = (theme.type === "dark")

        var attachmentsContent = []

        if (bill.receipts && bill.receipts.length > 0) {
            bill.receipts.forEach(receipt => {
                attachmentsContent.push(
                    <a style={{ fontSize: 14 }} onClick={() => this.downloadAttachment(receipt.uri, receipt.fileName)}>{receipt.fileName}</a>
                )
            });
        }

        var Accounts = getBPAccountsWithAccess(null, { resourceStr: "Payables.Bills", permType: "view", getActiveAccounts: true })
        const userPaymentAccount = vendorPaymentAccount && (Accounts || []).find(account => account.AccountId === vendorPaymentAccount.accountId)

        let paymentMemo = "--";
        if (bill.paymentMemo) {
            paymentMemo = bill.paymentMemo;
        }

        return (
            <FlexColumn style={{ minHeight: "80%" }}>
                <div style={{ width: "100%", display: "flex", justifyContent: (bill || {}).previewDocumentUrls && bill.previewDocumentUrls.length > 0 ? "space-between" : "center", alignItems: "flex-start" }}>
                    {
                        billUrl &&
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <div style={{ height: "auto", border: (documentLoaded === true) ? `5px solid ${theme.colors.systemGray6}` : 'none', marginBottom: 10, marginTop: 15 }}>
                                <div style={{ width: 511 }}>
                                    <PDFDocument url={billUrl} fileName={bill.invoiceNumber} download height={600} width={463} />
                                </div>
                            </div>
                        </div>
                    }
                    <div style={{ width: (bill || {}).previewDocumentUrls && bill.previewDocumentUrls.length > 0 ? 450 : 600 }}>
                        <Flex start gap='12px' style={{ marginBottom: 24 }} centerHorizontally>
                            <Text heading>{bill.invoiceNumber}</Text>
                            <Tag>{getSourceDisplayText(bill?.createdSource, aionStore)}</Tag>
                        </Flex>
                        {
                            (bill.status === "Paid" || bill.status === "Completed" || bill.status === "Scheduled" || bill.status === "Initiated" || bill.status === "Pending Approval" || bill.status === "Denied Approval") ?
                                <FlexColumn gap='24px' style={{ marginBottom: 24 }}>
                                    {/* <LabeledInput nomargin key="-1" label="Bill no." value={bill.invoiceNumber} /> */}
                                    <LabeledInput nomargin key="0" type='read-only' label="Bill Amount" value={formatter.format(amount)} />

                                    <LabeledInput nomargin key="1" type='read-only' label="Payment to" value={vendor.vendorName} />
                                    {
                                        userPaymentAccount &&
                                        <LabeledInput nomargin key="2" type='read-only' label="From account" value={<>{(userPaymentAccount.NickName || `Business ${userPaymentAccount.AccountSubType}`).toUpperCase()} - <span className="MICR-font">****{userPaymentAccount.Mask}</span></>} />
                                    }
                                    {
                                        (vendorPaymentAccount && vendorPaymentAccount.type === "ACH" && vendorPaymentAccount.bankDetail && vendorPaymentAccount.bankDetail.accountNumber) &&
                                        <LabeledInput nomargin key="3" type='read-only' label="Vendor payment details" value={<>{(vendorPaymentAccount.type).toUpperCase()} - <span className="MICR-font">****{vendorPaymentAccount.bankDetail.accountNumber.substring(vendorPaymentAccount.bankDetail.accountNumber.length - 4)}</span></>} />
                                    }
                                    {
                                        (vendorPaymentAccount && vendorPaymentAccount.type === "Check" && vendorPaymentAccount.bankDetail && vendorPaymentAccount.bankDetail.bankAddress) &&
                                        <LabeledInput nomargin key="4" type='read-only' label="Vendor payment details" value={<>Check payment <br />Address: {addressObjectToStr(vendorPaymentAccount.bankDetail.bankAddress)}</>} />
                                    }
                                    {
                                        billDate &&
                                        <LabeledInput nomargin key="12" type='read-only' label="Bill Date" value={billDate && moment(billDate).format("MMM D, YYYY")} />
                                    }

                                    <LabeledInput nomargin key="13" type='read-only' label="Due Date" value={dueDate ? moment(dueDate).format("MMM D, YYYY") : "--"} />
                                    {
                                        bill.scheduledDateStr &&
                                        <LabeledInput nomargin key="5" type='read-only' label="Scheduled date" value={moment(bill.scheduledDateStr).format("ll")} />
                                    }
                                    {
                                        bill.payments && bill.payments.length > 0 &&
                                        <LabeledInput nomargin key="6" type='read-only' label="Paid date" value={moment(bill.payments[0].paymentDate).format("ll")} />
                                    }

                                    <LabeledInput nomargin key="7" type='read-only' label="Transaction fee" value={"$0"} />

                                    {/* {
                                        (bill.payments && bill.payments.length > 0 || bill.paymentMemo) &&
                                        <LabeledInput nomargin key="8" type='read-only' label="Payment memo" value={paymentMemo} />
                                    } */}

                                    {
                                        coaList &&
                                        <LabeledInput nomargin key="14" type='read-only' label="Expense account" value={(coaList ? coaList.map(x => `${x?.categoryName} - ${x?.coatype}`) : "--")} />
                                    }
                                    {
                                        notes &&
                                        <LabeledInput nomargin key="15" type='read-only' label="Note" value={notes} />
                                    }
                                    {
                                        approvalRule &&
                                        <LabeledInput nomargin key="9" type='read-only' label="Approvers" value={(<ApproversStatusCard theme={theme} approvalRule={approvalRule} approvalRuleInstance={approvalRuleInstance} />)} />
                                    }
                                </FlexColumn>
                                :
                                <FlexColumn gap='24px' style={{ marginBottom: 24 }}>
                                    <LabeledInput nomargin key="11" type='read-only' label="Bill Amount" value={formatter.format(amount)} />

                                    <LabeledInput nomargin key="1" type='read-only' label="Payment to" value={vendor.vendorName} />
                                    {
                                        billDate &&
                                        <LabeledInput nomargin key="12" type='read-only' label="Bill Date" value={billDate && moment(billDate).format("MMM D, YYYY")} />
                                    }

                                    <LabeledInput nomargin key="13" type='read-only' label="Due Date" value={dueDate ? moment(dueDate).format("MMM D, YYYY") : "--"} />

                                    <LabeledInput nomargin key="14" type='read-only' label="Expense account" value={(coaList ? coaList.map(x => `${x?.categoryName} - ${x?.coatype}`) : "--")} />
                                    {
                                        notes &&
                                        <LabeledInput nomargin key="15" type='read-only' label="Note" value={notes} />
                                    }
                                    {
                                        attachmentsContent.length > 0 &&
                                        <LabeledInput nomargin key="16" type='read-only' label="Attachments" value={<FlexColumn>{attachmentsContent}</FlexColumn>} />
                                    }
                                    {
                                        evaluatedApprovalRule && <>
                                            <div style={{ height: 1, width: '100%', background: '#F2F2F2' }}></div>
                                            <FlexColumn start>
                                                <Text caption spaced weight='500'>APPROVALS</Text>
                                                <ApprovalGraph rule={evaluatedApprovalRule} />
                                            </FlexColumn>
                                        </>
                                    }
                                    {/* {
                                        approvalRule &&
                                        <LabeledInput nomargin key="19" label="Approvers" value={(<ApproversStatusCard theme={theme} approvalRule={approvalRule} approvalRuleInstance={approvalRuleInstance} />)} />
                                    } */}
                                </FlexColumn>
                        }
                    </div>
                </div>
            </FlexColumn>
        );
    }
}

function mapStateToProps(state) {
    return {
        bkStore: state.bookkeepingAppReducer,
        aionStore: state.aionAppReducer,
        bankingStore: state.bankingAppReducer,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(ViewBill));