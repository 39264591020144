import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import moment from 'moment';

import { Text } from '../../Reusable/Refresh/Text';
import { FlexColumn } from '../../Reusable/Container';
import FlowViewComponent from '../../Reusable/Refresh/FlowViewComponent';
import SearchBusiness from '../SearchBusiness';
import { addDataToStore, APP_STATE, BUSINESS_INFO, REMOTE_SAVE_DATA } from '../../../Actions/actions';
import { countryNameToCodeMap, STATES_ABBR_MAP } from '../../../Utils/util';
import { ErrorAlert } from '../../Reusable/Alert';


class BusinessLookup extends Component {

    state = {
        submitLoading: false,
        searchText: (this.props.onboardingStore.appState || {}).businessSearchText || '',
        showSuccessBanner: false
    };

    handleSearchText = (searchText) => {
        this.props.dispatch(addDataToStore(APP_STATE, { businessSearchText: searchText }))
        this.setState({ searchText: searchText });
    }

    handleSearchResults = (searchResults) => {
        //this.props.dispatch(addDataToStore(BUSINESS_INFO, { businessSearchResults: searchResults }));
        const companies = searchResults || [];
        this.props.dispatch(addDataToStore(APP_STATE, { businessSearchResults: companies }));
        this.setState({ showEmptyOption: (companies.length == 0) })
    }

    handleSelectBusiness = (company) => {
        console.log("handleSelectBusiness", company)
        // set business name
        const name = company.name || "";
        // company_type: Domestic Nonprofit Corporation, Limited Liability Company, CORPORATION FOR PROFIT, Sole Proprietor (Domestic), Stock Corporation - CA - Professional, Assumed Name for LLC, Domestic Corporation, Corporation; potential s-corps: DOMESTIC PROFIT CORPORATION, Domestic For-Profit Corporation, DOMESTIC BUSINESS CORPORATION
        const company_type = (company.company_type || "").toLowerCase();
        var incorporationType = null;
        if(["limited liability company", "llc", "assumed name for llc"].includes(company_type)) incorporationType = "LLC";
        if(["CORPORATION FOR PROFIT".toLowerCase(), "Domestic Corporation".toLowerCase(), "corporation"].includes(company_type) || (name.toLowerCase()).includes("inc.")) incorporationType = "C-Corp";
        else if(["DOMESTIC PROFIT CORPORATION".toLowerCase(), "Domestic For-Profit Corporation".toLowerCase(), "DOMESTIC BUSINESS CORPORATION".toLowerCase()].includes(company_type)) incorporationType = "S-Corp";
        if(["Limited Liability Partnership".toLowerCase()].includes(company_type) || (name.toLowerCase()).includes("partnership")) incorporationType = "Partnership";

        this.props.dispatch(addDataToStore(BUSINESS_INFO, { name: name, incorporationType: incorporationType, oc: company }));

        // set incorporation date
        if (company.incorporation_date)
            this.props.dispatch(addDataToStore(BUSINESS_INFO, { incorporationDate: moment(company.incorporation_date).format('YYYY-MM-DD') }));
        else
            this.props.dispatch(addDataToStore(BUSINESS_INFO, { incorporationDate: null }));

        // set incorporation state
        if (company.jurisdiction_code) {
            let state = company.jurisdiction_code.substr(company.jurisdiction_code.lastIndexOf('_') + 1);
            state = state.toUpperCase();
            this.props.dispatch(addDataToStore(BUSINESS_INFO, { incorporationState: state, statesOfOperation: [state] }));
        } else
            this.props.dispatch(addDataToStore(BUSINESS_INFO, { incorporationState: null }));

        // set business address
        let addressObj = null;
        const address = company.registered_address;
        if (address && address.street_address && address.locality && address.region && address.postal_code) {
            addressObj = {
                "line1": address.street_address,
                "city": address.locality,
                "countrySubDivisionCode": ((address.region || "").length == 2) ? address.region : STATES_ABBR_MAP[address.region],
                "postalCode": (address.postal_code || "").substr(0, 5),
                "country": address.country,
                "countryCode": countryNameToCodeMap[address.country] || 'US',
            }
        }
        this.props.dispatch(addDataToStore(BUSINESS_INFO, { physicalAddress: addressObj }));
        this.props.dispatch(addDataToStore(REMOTE_SAVE_DATA, this.props.flowView.FlowStep));
        // go to next screen
        this.props.loadNext();
    }

    // clear any old business info, if any
    handleCantFindBusiness = () => {
        this.handleSelectBusiness({ name: null, incorporationDate: null, incorporationState: null, registered_address: null });
        this.props.setManualReviewRequired("Could not find business in open corporates.");
    }

    validateSearchText = () => {
        var { searchText } = this.state;
        const { onboardingStore } = this.props;
        if (searchText !== '') {
            this.setState({showSuccessBanner: true})
                    setTimeout(() => {
                        this.setState({showSuccessBanner: false})

                        this.props.loadNext();
                    }, 1000)
        }else {
            this.props.loadNext();
        }
    }

    render() {
        var { flowView, currentViewIndex, loadPrevView, loadNext, flowSteps, onboardingStore, theme, loanApplication, applicationStep } = this.props;
        flowSteps = flowSteps || [];
        const { appState } = onboardingStore;
        var childViews = (
            <FlexColumn start fullHeight>
                <Text heading>Business Lookup</Text>
                <Text margin='0 0 12px'>Search for your business using a public database of US businesses.</Text>
                <FlexColumn>
                    <SearchBusiness
                        searchText={appState.businessSearchText}
                        searchResults={appState.businessSearchResults}
                        onSearchText={searchText => this.handleSearchText(searchText)}
                        onSearchResults={searchResults => this.handleSearchResults(searchResults)}
                        onSelectBusiness={company => this.handleSelectBusiness(company)}
                    />
                </FlexColumn>
            </FlexColumn>
        );
        return (
            <FlowViewComponent
                flowView={flowView}
                currentViewIndex={currentViewIndex}
                loadPrev={loadPrevView}                
                childViews={childViews}
                submitLoading={this.state.submitLoading}
                width={'100%'}
                back={currentViewIndex > 0}
                padding='0 64px'
                {...this.props}
                loadNext={this.validateSearchText}
                showSuccessBanner={this.state.showSuccessBanner}
                
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        onboardingStore: state.onboardingAppReducer,
        aionStore: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(BusinessLookup));