import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';

import { Upload, message, Avatar, Tooltip } from 'antd';
import { CameraOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import environment from '../../../../environment';
import { apiPOSTReq, getJwt } from '../../../../Utils/api';
import { addDataToStore, UPDATE_PROFILE_IMG } from '../../../../Actions/actions';
import { Flex, FlexColumn } from '../../../Reusable/Container';
import { ErrorAlert } from '../../../Reusable/Alert';
import { TextButton } from '../../../Reusable/Button';
import { Image } from '../../../Reusable/Image';
// import Camera from '../../../../Images/email.png';

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

class ProfileImage extends Component {
    state = {
        loading: false,
    };

    componentDidMount() {
        var { userInfo } = this.props.aionStore;
        userInfo = userInfo || {};
        console.log("ProfileImage", userInfo);
        this.setState({ imageUrl: userInfo.profileImage })
    }

    componentDidUpdate(prevProps, prevState) {
        // Update on image change
        const newProfileImage = (this.props.aionStore.userInfo || {}).profileImage
        if ((prevProps.aionStore.userInfo || {}).profileImage !== newProfileImage) {
            this.setState({ imageUrl: newProfileImage });
        }
    }

    // Save logo URL
    saveLogoUrl = () => {
        var { userInfo } = this.props.aionStore;
        apiPOSTReq(`${environment.uamBaseUrl}/updateUserProfileImage`, null, { logoUrl: this.state.imageUrl, userId: userInfo.email }, (err, resp) => {
            try {
                const data = resp || {};
                console.log("/updateUserProfileImage", this.state.imageUrl, data)
                if (data.result) {
                    // Update profile img
                    this.props.dispatch(addDataToStore(UPDATE_PROFILE_IMG, this.state.imageUrl));
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR updateLogo", error, err, resp);
                ErrorAlert({description: error.message})
            }
        })
    }

    render() {
        const { imageUrl } = this.state;
        const { size, hideupload, aionStore } = this.props;
        var { userInfo } = aionStore;
        userInfo = userInfo || {};
        const logoSize = size || 96;
        var uploadProps = {
            action: `${environment.apiBaseUrl}/file/upload`,
            headers: {
                businesskey: this.props.aionStore.BusinessUniqueKey,
                type: "logo",
                jwt: this.props.aionStore.jwt
            },
            onChange: (info) => {
                var { fileList } = info;
                fileList.forEach(file => {
                    const { status, response, name, url } = file;
                    console.log("fileList response", response);
                    if(status === "done") {
                        this.setState({
                            imageUrl: (response.UploadedUrls || [])[0]
                        });
                        this.saveLogoUrl();
                    }
                });
			}
        };
        return (
            <Flex style={{ justifyContent: "flex-start" }}>
                {
                    imageUrl ? 
                    (
                        hideupload ?
                        <div style={{position: "relative", overflow: "hidden"}}>
                            <img style={{ display: "block", margin: "0 auto", height: logoSize, width: logoSize, borderRadius: "50%", objectFit: "cover" }} src={imageUrl} />
                        </div>
                        :
                        <div style={{position: "relative"}}>
                            <img style={{ display: "block", margin: "0 auto", height: logoSize, width: logoSize, borderRadius: "50%", objectFit: "cover" }} src={imageUrl} />
                            <Upload
                                name="avatar"
                                showUploadList={false}
                                beforeUpload={beforeUpload}
                                {...uploadProps}
                            >
                                <CameraOutlined style={{ position: 'relative', cursor: "pointer", bottom: 30, left: 70, fontSize: "20px", background: this.props.theme.body, padding: "5px", borderRadius: "50%" }} className="shadow hover-icon" />
                            </Upload>
                        </div>                            
                    )
                    :
                    (
                        hideupload ?
                        <Avatar size={logoSize} style={{alignSelf: 'center', backgroundColor: this.props.theme.colors.secondary4, fontSize: logoSize/2 }}>{userInfo.firstName ? userInfo.firstName[0] : ""}</Avatar>
                        :
                        <div style={{ alignSelf: 'center', borderRadius: "50%" }}>
                            <Avatar size={logoSize} style={{alignSelf: 'center', backgroundColor: this.props.theme.colors.secondary4, fontSize: logoSize/2 }}>{userInfo.firstName ? userInfo.firstName[0] : ""}</Avatar>
                            <Upload
                                name="avatar"
                                showUploadList={false}
                                beforeUpload={beforeUpload}
                                {...uploadProps}
                            >
                                <CameraOutlined style={{ position: 'relative', cursor: "pointer", bottom: 30, left: 70, fontSize: "20px", background: this.props.theme.body, padding: "5px", borderRadius: "50%" }} className="shadow hover-icon" />
                            </Upload>
                        </div>
                    )
                    
                }
            </Flex>
            
        );
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(ProfileImage));