import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'

import { Skeleton, Tabs, Collapse } from 'antd'
import { FlexColumn, Flex, CardContainer } from '../../Reusable/Container'
import PageHeader from '../../Reusable/PageHeader'
import { OwnerCard } from '../../Reusable/Refresh/Card'
import { Text } from '../../Reusable/Text'

const { TabPane } = Tabs
const { Panel } = Collapse

class Ownership extends Component {
    state = {
        loading: false,
        selectedTab: "beneficialowners"
    }

    handleTabChange = () => {

    }

    render() {
        const { aionStore, theme } = this.props
        var { selectedTab, loading } = this.state
        var { business } = aionStore || {}
        var { businessProfile } = business || {}
        businessProfile = businessProfile || {}
        var coOwnersInfo = []
        coOwnersInfo.push(businessProfile.primaryApplicantInfo || {})
        coOwnersInfo = coOwnersInfo.concat(businessProfile.coOwnerApplicantInfo || [])
        coOwnersInfo = coOwnersInfo.filter(item => (item.ownershipPercentage >= 25) )
        return (
            <>
                <FlexColumn start className='main-padding' fullHeight>
                    <PageHeader
                        titleText={"Ownership"}
                        back
                        marginBottom='12px'
                    />
                    <Flex between gap='24px' fullWidth margin='0 0 24px'>
                        <FlexColumn start fullWidth fullHeight>
                            <Tabs size="large" defaultActiveKey={selectedTab} onChange={this.handleTabChange}>
                                <TabPane tab="Beneficial Owners" key="beneficialowners" />
                            </Tabs>

                            <CardContainer fullWidth margin='8px 0'>
                                <FlexColumn gap='24px'>
                                    <Text weight="500" margin="0 10px">Individuals who own 25% or more</Text>
                                    {
                                        coOwnersInfo.length > 0 ?
                                        <Flex start wrap gap='24px'>
                                            {
                                                selectedTab === 'beneficialowners' && coOwnersInfo && coOwnersInfo.map(item => {
                                                    return <OwnerCard hideEdit={true} ownerInfos={item} theme={theme} symbolprefix="%" key={item.email} onClick={() => {}}/>
                                                })
                                            }
                                        </Flex>
                                        :
                                        <Text margin='0 10px'><br/>None found for your organization</Text>
                                    }
                                </FlexColumn>
                                
                            </CardContainer>
                        </FlexColumn>
                    </Flex>
                </FlexColumn>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        onboardingStore: state.onboardingAppReducer,
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Ownership))