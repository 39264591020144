import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'

import { Tabs, Select, Skeleton } from 'antd'

import { Image } from '../../../Reusable/Image'
import { ErrorAlert } from '../../../Reusable/Alert'
import { apiPOSTReq, getPreSignedS3Url } from '../../../../Utils/api'
import { getBPAccountsWithAccess, getTextWidth, toCurrency } from '../../../../Utils/util'
import environment from '../../../../environment'
import { Flex, FlexColumn, CardContainer, LightContainer } from '../../../Reusable/Container';
import { LabeledInput } from '../../../Reusable/Input'
import { Text } from '../../../Reusable/Text'
import { TextButton, ImageButton } from '../../../Reusable/Button'
import moment from 'moment'
import AionIcon from '../../../../Images/aion-bank-icon.png'
import ModalClose from '../../../../Images/modal-close.png'
import Download from '../../../../Images/download.png'
import ChartImage from "../../../../Images/chart.png"
import { EyeOutlined } from '@ant-design/icons'
import { saveAs } from 'file-saver'

// Actions
import { fetchBBTransactions, normalizeAndSortHistory } from '../../../../Utils/bankingDataManager'
import AccountCardList from '../../../Banking/Reusable/AccountCardList';
import AccountDetailsCard from '../../../Banking/Reusable/AccountDetailsCard'
import PendingTable from '../../../Banking/Reusable/PendingTable'
import ActivityTable from '../../../Banking/Reusable/ActivityTable'
import { FromAccountOption } from '../../../Reusable/Option'

const NUM_POSTED_TRANSFERS = 50

const { TabPane } = Tabs
const { Option } = Select
class AccountTransactions extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            showOpeningDepositModal: false,
            outboundACH: true,
            selectedAccount: 0,
            accounts: [],
            postedTransfers: [],
            loadingPending: false,
            pendingTransfers: []
        }
    }

    componentDidMount() {
        this.fetchData()
        this.fetchScheduledPayments()
    }

    getBalance = () => {
        const Accounts = getBPAccountsWithAccess("Transactions")

        if ((Accounts || []).length > 0) {
            return Accounts[0].availableBalance
        } else {
            return 0
        }
    }

    fetchData = () => {
        this.setState({ loading: true })

        apiPOSTReq(`${environment.bbBaseUrl}/bb/getAccounts`, { "BankProvider": environment.bankProvider.crb, "AionCurrentBiz": this.props.business.id }, { BusinessId: this.props.business.id }, (err, resp) => {
            try {
                console.log("/bb/getAccounts", err, resp)
                const data = resp
                if (data.result) {
                    if (err) throw new Error(err)
                    var accounts = data.bbaccounts

                    console.log("/bb/getAccounts", accounts)

                    var zeroBalance = true
                    var crb = false

                    accounts.forEach(account => {
                        if (account.bankProvider === "CrossRiverBank") {
                            crb = true
                        }
                        if (account.currentBalance != 0) {
                            zeroBalance = false
                        }
                    })

                    if (crb && zeroBalance) {
                        this.setState({ showOpeningDepositModal: true })
                    }

                    this.setState({ accounts: accounts, tab: 'details' })
                    this.fetchTransactions({ account: accounts[this.state.selectedAccount], pagination: { pageSize: 50, current: 1 } });
                    this.fetchSubAccounts(accounts[this.state.selectedAccount].accountNumber);
                    this.getPending(accounts[this.state.selectedAccount]);
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getAccounts", error, err, resp)
                const fd = this.fetchData
                ErrorAlert({ description: error.message, okText: "Retry", onOk() { fd() } })
            }
        })
    }

    fetchSubAccounts = (accountNumber) => {
        console.log("/bb/getSubAccounts", { "BankProvider": environment.bankProvider.crb, "AccountNumber": accountNumber, "BusinessId": this.props.business.id });
        apiPOSTReq(`${environment.bbBaseUrl}/bb/getSubAccounts`, { "BankProvider": environment.bankProvider.crb, AionCurrentBiz: this.props.business.id }, { "BankProvider": environment.bankProvider.crb, "AccountNumber": accountNumber, "BusinessId": this.props.business.id }, (err, resp) => {
            try {
                console.log("/bb/getSubAccounts", err, resp);
                const data = resp;
                if (data.result) {
                    if (err) throw new Error(err);
                    console.log('getSubAccounts', data.subAccounts)

                    if (data.subAccounts && data.subAccounts.length > 0) {
                        this.setState({ subAccounts: data.subAccounts })
                    }
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getSubAccounts", error, err, resp);
            }
        })
    }

    handleSelectSubAccount = (value) => {
        var { subAccounts } = this.state
        var subAccount = subAccounts.find(a => a.accountNumber === value)
        this.setState({ selectedSubAccount: subAccount });
    }

    fetchScheduledPayments = () => {
        var body = {
            viewBy: "ALL",
            sortDirection: "DESC",
            BusinessId: this.props.business.id
        };

        console.log("fetchBBHistory /getHistory body", body)
        apiPOSTReq(`${environment.bbBaseUrl}/bb/getHistory`, { AionCurrentBiz: this.props.business.id }, body, (err, resp) => {
            try {
                const data = resp || {};
                console.log("fetchBBHistory /getHistory resp", data)
                if (data.result) {
                    var achTransfersList = data.achTransfersList || [];
                    var achPayments = achTransfersList.filter(item => item.direction == "credit");
                    var achFundingSource = achTransfersList.filter(item => item.direction == "debit");
                    this.setState({ outboundACH: ((data || {}).achTransfersCount || 0) > 0 })
                    this.setState({
                        ACHPayments: achPayments || [],
                        FSDeposits: achFundingSource || [],
                        WirePayments: data.wireTransfersList || [],
                        BookTransfers: data.bookTransfersList || [],
                        BillPayments: data.bpPaymentList || [],
                        CheckDeposits: data.checkDepositList || [],
                        ...data
                    })
                } else {
                    throw Error("Could not fetch listCounterParty.")
                }
            } catch (error) {
                console.log("ERRR listCounterParty", error, resp);
            }
        })
    }

    fetchTransactions = (options) => {
        var { accounts, selectedSubAccount, selectedAccount, postedTransfers } = this.state;
        var { fromDate, toDate, searchStr, account, fromAmt, toAmt, railList, viewBy } = options

        if (!account) account = selectedSubAccount ? selectedSubAccount : accounts[selectedAccount]

        if (account) {
            var fetchOptions = {
                BusinessId: this.props.business.id,
                accountId: account.accountId,
                pagination: options.pagination // || pagination
            }

            console.log('fetchTransactions fetchOptions1:', fetchOptions)

            if (searchStr && searchStr != '') fetchOptions.searchStr = searchStr
            if (fromDate) fetchOptions.fromDate = fromDate
            if (toDate) fetchOptions.toDate = toDate
            if (fromAmt) fetchOptions.fromAmt = fromAmt
            if (toAmt) fetchOptions.toAmt = toAmt
            if (railList) fetchOptions.railList = railList
            if (viewBy && searchStr && searchStr != '') fetchOptions.viewBy = viewBy

            fetchOptions.bankProvider = account.bankProvider
            fetchOptions.providerStatus = "Posted";

            this.setState({ currentOptions: fetchOptions, loadingPosted: true });
            fetchBBTransactions(fetchOptions, (err, resp) => {
                try {
                    if (err) throw new Error(err)
                    console.log("fetchData fetchBBTransactions fetchOptions", fetchOptions)
                    console.log("fetchData fetchBBTransactions resp", resp)

                    const newData = fetchOptions.pagination.current > 1 ? postedTransfers.concat(resp.Transactions) : resp.Transactions
                    this.setState({ moreToLoad: newData.length !== resp.transactionsCount });
                    this.setState({ postedTransfers: newData, loadingPosted: false });

                } catch (error) {
                    ErrorAlert({ description: error.message })
                }
            })
        }
    }

    onTabChange = tab => {
        var { accounts, selectedAccount } = this.state;
        if (tab === "statements") {
            this.getAccountStatements(accounts[selectedAccount].accountId)
        }
        this.setState({ tab: tab });
    }

    getAccountStatements = (accountId) => {
        const body = {
            "AccountId": accountId,
            "FromDate": moment().subtract(1, 'years').format("YYYY-MM-DD"),
            "ToDate": moment().format('YYYY-MM-DD'),
            BusinessId: this.props.business.id
        }

        console.log('bb/getAccountStatements', body)
        this.setState({ statementsLoading: true });
        apiPOSTReq(`${environment.bbBaseUrl}/bb/getAccountStatements`, { AionCurrentBiz: this.props.business.id }, body, (err, resp) => {
            try {
                const data = resp || {}
                console.log('bb/getAccountStatements', data)
                if (data.result) {
                    var statements = data.accountStmtInfos

                    if (!statements || statements.length === 0) {
                        this.setState({ statementMap: null });
                    } else {
                        var statementMap = {}

                        statements.sort((a, b) => (a.periodStart > b.periodStart) ? 1 : ((b.periodStart > a.periodStart) ? -1 : 0)).reverse().forEach(statement => {
                            const statementYear = statement.periodStart.split('-')[0]

                            if (statementMap[statementYear]) {
                                statementMap[statementYear].push({
                                    month: moment(statement.periodStart, 'YYYY-MM-DD').format('MMMM'),
                                    url: statement.statementUrl.uri,
                                })
                            } else {
                                statementMap[statementYear] = [{
                                    month: moment(statement.periodStart, 'YYYY-MM-DD').format('MMMM'),
                                    url: statement.statementUrl.uri,
                                }]
                            }
                        })

                        this.setState({ statementMap: statementMap });
                    }
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("bb/getAccountStatements err", error, resp)
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            } finally {
                this.setState({ statementsLoading: false });
            }
        })
    }

    handleStatementYear = (value) => {
        this.setState({ statementYear: value })
    }

    downloadStatement = (url, name) => {
        getPreSignedS3Url({ url: url }, (err, preSignedUrl) => {
            saveAs(preSignedUrl, name)
        })
    }

    viewStatement = (url, name) => {
        getPreSignedS3Url({ url: url }, (err, preSignedUrl) => {
            this.setState({ stmtFile: { preSignedUrl, name } })
        })
    }

    getDailyBalances = () => {
        this.setState({ chartLoading: false });
        const body = {
            "FromDate": moment().subtract(30, 'days').format("YYYY-MM-DD"),
            "ToDate": moment().format('YYYY-MM-DD'),
            BusinessId: this.props.business.id
        }

        apiPOSTReq(`${environment.bbBaseUrl}/bb/dailyBalancesChart`, {}, body, (err, resp) => {
            this.setState({ chartLoading: true });
            try {
                const data = resp || {}
                console.log('bb/dailyBalancesChart', data)
                if (data.result) {
                    if (data.dailyBalanceChart) {
                        this.setState({ dailyBalanceChart: data.dailyBalanceChart })
                    }
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("bb/dailyBalancesChart err", error, resp)
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    getPending = (account) => {
        this.setState({ pendingTransfers: [], loadingPending: true });
        var body = {
            viewBy: "PENDING_ACTIVITY",
            sortDirection: "DESC",
            accountId: account.accountId,
            BusinessId: this.props.business.id
        };

        console.log("fetchBBHistory /getHistory body", body)
        apiPOSTReq(`${environment.bbBaseUrl}/bb/getHistory`, { AionCurrentBiz: this.props.business.id }, body, (err, resp) => {
            try {
                const data = resp || {};
                console.log("fetchBBHistory /getHistory resp", data)
                if (data.result) {
                    var achTransfersList = data.achTransfersList || [];
                    var achPayments = achTransfersList.filter(item => item.direction == "credit");
                    var achFundingSource = achTransfersList.filter(item => item.direction == "debit");
                    this.setState({ outboundACH: ((data || {}).achTransfersCount || 0) > 0 })
                    var normalizedHistory = normalizeAndSortHistory({
                        ACHPayments: achPayments || [],
                        FSDeposits: achFundingSource || [],
                        WirePayments: data.wireTransfersList || [],
                        BookTransfers: data.bookTransfersList || [],
                        BillPayments: data.bpPaymentList || [],
                        CheckDeposits: data.checkDepositList || [],
                        ...data
                    });
                    console.log(2, normalizedHistory);
                    this.setState({ pendingTransfers: normalizedHistory.normalizedData, pendingSums: normalizedHistory.pendingSums, loadingPending: false });
                } else {
                    throw Error("Could not fetch listCounterParty.")
                }
            } catch (error) {
                console.log("ERRR listCounterParty", error, resp);
            }
        })
    };

    loadMoreActivity = () => {
        var { currentOptions } = this.state;
        currentOptions.pagination.current = currentOptions.pagination.current + 1
        this.fetchTransactions(currentOptions)
    }

    render() {
        var { accounts, selectedAccount, subAccounts, selectedSubAccount, statementsLoading, statementMap, statementYear, tab,
            currentBalance, loadingPending, pendingTransfers, postedTransfers, searchTerm, moreToLoad, loadingPosted } = this.state
        const editingNickname = false;
        const singleAccount = accounts.length === 1;
        const maxBalanceWidth = 64 + accounts.reduce((max, account) => {
            const textWidth = getTextWidth(`$${toCurrency(account.availableBalance)}`, '500 28px InterDisplay');
            return Math.max(max, textWidth);
        }, 0);

        return (
            <FlexColumn style={{ width: '100%', margin: 0, paddingLeft: 0 }} start>
                <FlexColumn between style={{ width: '100%' }}>
                    {
                        accounts && accounts.length > 1 &&
                        <div>
                            <AccountCardList accounts={accounts} selectedAccount={selectedAccount} onSelectAccount={(i) => {
                                this.setState({ selectedAccount: i });
                                this.fetchTransactions({ account: accounts[i], pagination: { pageSize: 50, current: 1 } });
                                this.fetchSubAccounts(accounts[i].accountNumber);
                                this.getPending(accounts[i]);
                            }} />
                        </div>
                    }
                    {
                        subAccounts && subAccounts.length > 0 &&
                        <div style={{ margin: '0 24px 12px 36px' }}>
                            <LabeledInput
                                label="Subledger"
                                id="selectedSubAccount"
                                type="select"
                                width='400px'
                                placeholder="Select subledger account"
                                value={selectedSubAccount && <Flex start centerHorizontally style={{ height: 30 }}>
                                    <Text><img width='16px' height='16px' src={AionIcon} style={{ marginRight: 8, marginBottom: 2 }} />{`${(selectedSubAccount.nickName || `Business ${selectedSubAccount.accountSubType}`)} • ${selectedSubAccount.mask}`}</Text>
                                </Flex>}
                                onChange={this.handleSelectSubAccount}
                                allowClear
                                onClear={() => this.setState({ subAccounts: null })}
                                clearIcon={<img style={{ marginTop: -7 }} width='24px' height='24px' src={ModalClose} />}
                            >

                                {
                                    subAccounts.map((sAccount) => (
                                        <Option key={sAccount.accountNumber} value={sAccount.accountNumber} style={{ backgroundColor: "transparent" }}>
                                            <FromAccountOption account={sAccount} />
                                        </Option>
                                    ))
                                }
                            </LabeledInput>
                        </div>
                    }
                    <Tabs size="large" defaultActiveKey="details" onChange={this.onTabChange} style={{ margin: '0 24px 12px 36px' }} tabBarExtraContent={<></>}>
                        <TabPane tab="Details" key="details" />
                        {
                            accounts[selectedAccount] && accounts[selectedAccount].accountSubType !== 'Transitional' &&
                            <>
                                {
                                    !selectedSubAccount && <TabPane tab="Statements" key="statements">
                                        <Skeleton loading={statementsLoading} active title={false} paragraph={{ rows: 7 }}>
                                            <Flex fullWidth fullHeight>
                                                {
                                                    statementMap != null ?
                                                        <CardContainer margin='12px 0 24px' fullWidth>
                                                            <FlexColumn start>
                                                                <Text margin='0 0 12px'>Download monthly account statements</Text>
                                                                <LabeledInput
                                                                    type='switch-button'
                                                                    switchNames={['All'].concat(Object.keys(statementMap).reverse())}
                                                                    onChange={this.handleStatementYear}
                                                                    value={statementYear} />

                                                                {
                                                                    Object.keys(statementMap).reverse().map((year) => {
                                                                        if (!statementYear || statementYear === 'All' || statementYear === year) {
                                                                            return <>
                                                                                <Text margin='12px 0 24px' caption>{year}</Text>
                                                                                <Flex start fullWidth wrap>
                                                                                    {
                                                                                        statementMap[year].map(statement => {
                                                                                            return <LightContainer width='284px' padding='12px 24px' margin='0 24px 12px 0'>
                                                                                                <Flex between fullWidth centerHorizontally>
                                                                                                    <Text>{statement.month}</Text>
                                                                                                    <Flex centerHorizontally>
                                                                                                        <TextButton icon={<EyeOutlined />} onClick={() => this.viewStatement(statement.url, `${statement.month} ${year}`)} />
                                                                                                        <ImageButton src={Download} onClick={() => this.downloadStatement(statement.url, `${statement.month} ${year}`)} />
                                                                                                    </Flex>
                                                                                                </Flex>
                                                                                            </LightContainer>
                                                                                        })
                                                                                    }
                                                                                </Flex>
                                                                            </>
                                                                        }
                                                                    })
                                                                }
                                                            </FlexColumn>
                                                        </CardContainer>
                                                        :
                                                        <LightContainer fullWidth padding='96px 24px'>
                                                            <FlexColumn center>
                                                                <Text heading>No statements available.</Text>

                                                                <Text lightText center width='297px'>Your statements have not been generated yet. Please check back at the start of next month.</Text>
                                                            </FlexColumn>
                                                        </LightContainer>
                                                }
                                            </Flex>
                                        </Skeleton>
                                    </TabPane>
                                }
                            </>
                        }
                    </Tabs>
                    {
                        tab === 'details' &&
                        <FlexColumn start gap='24px' style={{ margin: '0 24px 24px 36px' }}>
                            {
                                <Flex fullWidth gap='24px'>
                                    <CardContainer padding='0 24px 5px' fullWidth style={{ minWidth: 696, height: 220 }}>
                                        <Flex start fullWidth>
                                            <FlexColumn style={{ padding: '0 12px 24px 0' }} between>
                                                {
                                                    <Flex start centerHorizontally style={{ marginBottom: editingNickname ? 0 : 8 }}>
                                                        {
                                                            <Flex start centerHorizontally gap='8px' style={{ marginTop: singleAccount ? 18 : 26 }}>
                                                                <Text capitalize noWrap size='20px' height='40px'>{accounts[selectedAccount].nickName || `Business ${accounts[selectedAccount].accountSubType}`}</Text>
                                                            </Flex>
                                                        }
                                                    </Flex>
                                                }
                                                {
                                                    accounts[selectedAccount] && accounts[selectedAccount].accountSubType !== 'Transitional' &&
                                                    <>
                                                        <LightContainer
                                                            noBorder
                                                            background='#FBFBFB'
                                                            padding='12px 24px'
                                                            width={`${maxBalanceWidth}px`}>
                                                            <Flex fullWidth between>
                                                                <FlexColumn start>
                                                                    <Text noWrap size='14px'>{`${currentBalance ? 'Current balance' : 'Available balance'}`}</Text>
                                                                    {
                                                                        selectedSubAccount ?
                                                                            <Text size='28px' height='40px' weight='500'>{`$${toCurrency(selectedSubAccount[currentBalance ? 'currentBalance' : 'availableBalance'])}`}</Text>
                                                                            :
                                                                            <Text size='28px' height='40px' weight='500'>{`$${toCurrency(accounts[selectedAccount][currentBalance ? 'currentBalance' : 'availableBalance'])}`}</Text>
                                                                    }
                                                                </FlexColumn>
                                                            </Flex>
                                                        </LightContainer>

                                                        <TextButton margin='18px 0 0' weight='400' onClick={() => this.setState({ currentBalance: !currentBalance })} text={`Show ${currentBalance ? 'available' : 'current'} balance`} underline />
                                                    </>
                                                }
                                            </FlexColumn>
                                            <FlexColumn fullWidth start style={{ padding: '24px 0 24px 24px' }}>
                                                {
                                                    <div className='chart-card-getting-started' style={{ height: singleAccount ? 170 : 141 }}>
                                                        <Flex between fullWidth>
                                                            <FlexColumn start>
                                                                <Text noWrap heading primary>Working on your chart</Text>
                                                                <Text margin='0 0 24px' size='16px'>We’ll need a few more days of data to help us show you a chart of your daily balances.</Text>
                                                            </FlexColumn>

                                                            <Image margin='0 24px 0 0' src={ChartImage} width='166px' height='80px' />
                                                        </Flex>
                                                    </div>
                                                }
                                            </FlexColumn>
                                        </Flex>
                                    </CardContainer>
                                    {
                                        accounts[selectedAccount] && accounts[selectedAccount].accountSubType !== 'Transitional' &&
                                        <AccountDetailsCard
                                            height={singleAccount ? '220px' : '220px'}
                                            account={selectedSubAccount ? selectedSubAccount : accounts[selectedAccount]}
                                        // onViewAccountLetter={downloadAccountVerificationPDF}
                                        />
                                    }
                                </Flex>
                            }
                            {
                                (loadingPending || pendingTransfers.length >= 0) &&
                                <PendingTable
                                    title='Pending Transactions'
                                    subtitle={<>Incoming and outgoing transactions that we know about but have not yet been fully processed (posted) on your account.</>}
                                    onCancelled={() => this.getPending(accounts[selectedAccount])}
                                    loadingPending={loadingPending}
                                    pendingTransfers={pendingTransfers.length > 20 ? pendingTransfers.slice(0, 20) : pendingTransfers}
                                    // pendingSums={pendingSums}
                                    tooltip={<FlexColumn start left>
                                        <Text color='white' size='14px'>All transactions shown are based on ET regardless of the time zone in which they were initiated.</Text>
                                        <Text color='white' size='14px'>For some debit card transactions, the amount you see listed may not be the final amount deducted<br />from your account, for example, it may initially not include a tip you added to a restaurant bill.</Text>
                                    </FlexColumn>}
                                    canCollapse
                                    isCollapsed={true}
                                    autoExpand={false}
                                    setSearchTerm={(searchTerm) => this.setState({ searchTerm: searchTerm })}
                                    padding='12px 24px'
                                    moreTransactions={pendingTransfers?.length > 20}
                                    // totalTransactions={totalPendingTransactions}
                                    totalTransactions={pendingTransfers?.length}
                                />
                            }
                            <ActivityTable
                                key={selectedAccount}
                                filter
                                more
                                download
                                runningBalance
                                viewReturn
                                tooltip={<FlexColumn start>
                                    <Text color='white' size='14px'>All transactions shown are based on ET regardless of the time zone in which they were initiated.</Text>
                                </FlexColumn>}
                                title='Posted Transactions'
                                account={accounts[selectedAccount]}
                                accountIndex={selectedAccount}
                                fetchTransactions={this.fetchTransactions}
                                data={postedTransfers}
                                subAccount={selectedSubAccount}
                                setSearchTerm={(searchTerm) => this.setState({ searchTerm: searchTerm })}
                                sharedSearchTerm={searchTerm}
                                padding='4px 24px 24px 24px'
                                searchWidth={'200px'}
                                moreToLoad={moreToLoad}
                                onLoadMore={() => this.loadMoreActivity()}
                                loading={loadingPosted}
                                pageSize={NUM_POSTED_TRANSFERS}
                            />
                        </FlexColumn>
                    }
                </FlexColumn>
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.bankingAppReducer,
        aionStore: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(AccountTransactions));