import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'
import styled from 'styled-components'
import moment from 'moment-business-days'
import ordinal from 'ordinal'

import { message, Skeleton, Modal } from 'antd'

import { ExclamationCircleOutlined } from '@ant-design/icons'

// Components
import { Button } from '../../../Reusable/Button'
import { Text } from '../../../Reusable/Text'
import { FlexColumn, Flex, CardContainer } from '../../../Reusable/Container'
import { LabeledInput } from '../../../Reusable/Input'
import { ErrorAlert } from '../../../Reusable/Alert'
import { ApproversCard } from '../../../Reusable/Card'
import { ExpenseCategory } from '../../../Reusable/ExpenseCategory'
import PageHeader from '../../../Reusable/PageHeader'
import PDFDocument from '../../../Reusable/PDFDocument'
import environment from '../../../../environment'
import { apiPOSTReq, getPreSignedS3Url } from '../../../../Utils/api'
import { addressObjectToStr, getBPAccountsWithAccess } from '../../../../Utils/util'
import PayBill from '../PayBill'
import BillDetail from '../BillDetail'

import Resize from '../../../../Images/resize.svg'
import SVGIcon from '../../../Reusable/SVGIcon'
import hexToRgba from 'hex-to-rgba'
import { USERDEFAULTS_SAVE_FLAG, addDataToStore } from '../../../../Actions/actions'

const { confirm } = Modal

export const CardButton = styled.div`
    background-color: rgb(255, 255, 255);
    border-radius: 0.5rem;
    width: 400px;
    height: 100px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    opacity: 1;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    transition-property: box-shadow;
    transition-duration: 300ms;
    &:hover {
        cursor: pointer;
        box-shadow: 0 3px 15px rgb(0 0 0 / 0.4);
    };
`

export const CardButtonText = styled.span`
    font-size: 18px;
    font-weight: 400;
`

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
})

class NewBillFlow extends Component {

    constructor(props) {
        super(props)

        // Update moment to include holidays
        const { store } = this.props
        var { publicHolidays } = store

        moment.updateLocale('us', {
            holidays: publicHolidays,
            holidayFormat: 'YYYY-MM-DD'
        })
        this.pdfContainerRef = React.createRef();
        this.state = {
            title: "New bill",
            loading: false,
            payLoading: false,
            delay: 500,
            pagination: {
                current: 1,
                pageSize: 100
            },
            polling: false,
            // type: "upload",
            bills: [],
            vendors: [],
            bill: this.props.bill || {},
            vendor: {},
            fileList: this.props.bill && this.props.bill.receipts,
            flowStep: "billDetails",
            flowSteps: ["billDetails"],
            documentLoaded: false,
            selectVendorValue: "Yes",
            newVendorValue: "Yes",
            categories: [],
            paymentMethod: "ACH",
            accountType: "Checking",
            saveToQBO: false,
            payOnOption: "dueDate",
            newVendor: false,
            vendorPaymentAccount: {},
            totalScheduled: 0.0,
            createVendorLoading: false,
            inboxItemLoading: false,
            showSaveToQuickBook: false,
            pageNumber: 1,
            upload: false,
            editBill: false,
            pdfWidth: this.props.userDefaultsStore.BillsPdfWidth || 750,
            newBill: this.props.newBill
        }
    }

    componentDidMount() {
        if (this.props.location.state) {
            var { bill, billUrl, vendor, parsedVendor, inboxItem, payFlow  } = this.props.location.state
            if (inboxItem) {
                this.setState({ inboxItem: inboxItem, flowStep: "billDetails", flowSteps: ["billDetails"], upload: true, title: "Bill Details", inboxItemLoading: true })
                this.getParsedInvoice(inboxItem)
            } else if (bill) {
                if ((bill || {}).previewDocumentUrls && bill.previewDocumentUrls.length > 0) {
                    getPreSignedS3Url({ url: bill.previewDocumentUrls[0].uri }, (err, preSignedUrl) => {
                        if (!err) {
                            this.setState({ billUrl: preSignedUrl })
                        } else {
                            console.log({ description: err })
                        }
                    })
                }
                this.setState({ bill, billUrl, vendor, parsedVendor, payFlow, flowStep: payFlow ? "pay" : "billDetails", flowSteps: ["billDetails"], title: "Bill Details" }, () => this.getVendor(bill.vendorId))
            }
        }

        let { Profile } = this.props.store
        this.setState({ showSaveToQuickBook: (Profile || {}).AccountingSW === 'QuickBooks' })

        this.fetchVendors()
        this.fetchCOA({ pagination: this.state.pagination })
        // REVERTED BELOW as it was causing 6398
        // this.props.history.replace('/payables/bills/new-bill')
    }

    getParsedInvoice = (inboxItem) => {
        inboxItem = inboxItem || {}
        this.setState({ loading: true })
        apiPOSTReq(`${environment.payBaseUrl}/payables/bills/getParsedInvoice`, { "BankProvider": environment.bankProvider.crb }, { BillInboxItemId: inboxItem.id }, (err, resp) => {
            try {
                this.setState({ loading: false })
                const data = resp || {}
                console.log("/payables/bills/getParsedInvoice", err, data)
                var parsedInvoice = data.parsedInvoice || {}
                if (data.result) {
                    // Create a bill and vendor obj
                    var bill = {
                        "invoiceNumber": parsedInvoice.docNumber,
                        "billDate": parsedInvoice.transactionDate ? moment.utc(parsedInvoice.transactionDate).format("YYYY-MM-DD") : null,
                        "dueDate": parsedInvoice.dueDate ? moment.utc(parsedInvoice.dueDate).format("YYYY-MM-DD") : null,
                        "amount": parsedInvoice.totalAmt || parsedInvoice.balance,
                        "balance": parsedInvoice.balance,
                        "subTotal": parsedInvoice.subTotal,
                        "tax": parsedInvoice.tax,
                        "discount": parsedInvoice.discount,
                        "line": parsedInvoice.lineItems ? parsedInvoice.lineItems : [],
                        "previewDocumentUrls": [
                            {
                                "uri": parsedInvoice.attachmentUrl
                            }
                        ],
                        "ponumber": parsedInvoice.ponumber
                    }
                    this.setState({ parsedInvoice: parsedInvoice })
                    const body = {
                        "viewBy": "FILTER_ACTIVE"
                    }

                    apiPOSTReq(`${environment.payBaseUrl}/payables/vendors/getVendors`, {}, body, (err, resp) => {
                        try {
                            const data = resp
                            console.log("/payables/vendors/getVendors", data)
                            if (data.result) {
                                var vendor
                                var vendors = data.vendors
                                if(parsedInvoice && parsedInvoice.vendorReference && parsedInvoice.vendorReference.companyName){
                                    var body = {
                                        "vendorName" :  parsedInvoice.vendorReference.companyName
                                    }
                                    apiPOSTReq(`${environment.payBaseUrl}/payables/bills/getExistingVendor`, {}, body, (err, response) => {
                                        try {
                                            const vendorData = response
                                            console.log("/payables/vendors/getExistingVendor", vendorData)
                                            if (vendorData.result) {
                                                vendor = vendorData.vendor
                                                getPreSignedS3Url({ url: bill.previewDocumentUrls[0].uri }, (err, preSignedUrl) => {
                                                    this.setState({ parsedInvoice: parsedInvoice, bill: bill, billUrl: preSignedUrl, vendors: vendors, vendor: vendor, inboxItemLoading: false, vendorType: vendor ? "Existing" : "New"  })
                                                })
                                            }
                                            else{
                                                vendor = vendors.find(item => item.vendorName == parsedInvoice.clientCompanyName)
                                                getPreSignedS3Url({ url: bill.previewDocumentUrls[0].uri }, (err, preSignedUrl) => {
                                                    this.setState({ parsedInvoice: parsedInvoice, bill: bill, billUrl: preSignedUrl, vendors: vendors, vendor: vendor, inboxItemLoading: false, vendorType: vendor ? "Existing" : "New"  })
                                                })
                                            }
                                        }
                                        catch (error) {
                                            console.log("ERRR /payables/vendors/getExistingVendor", error, err, resp)
                                            ErrorAlert({ description: error.message })
                                            this.setState({ inboxItemLoading: false })
                                        }
                                       
                                    })

                                }
                                else{
                                    var vendor = vendors.find(item => item.vendorName == parsedInvoice.clientCompanyName)
                                    getPreSignedS3Url({ url: bill.previewDocumentUrls[0].uri }, (err, preSignedUrl) => {
                                        this.setState({ parsedInvoice: parsedInvoice, bill: bill, billUrl: preSignedUrl, vendors: vendors, vendor: vendor, inboxItemLoading: false, vendorType: vendor ? "Existing" : "New"  })
                                    })
                                }
                                // var vendor = {
                                //     "vendorName": parsedInvoice.clientCompanyName,
                                //     "address": parsedInvoice.clientCompanyAddress
                                // }
                            } else {
                                throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                            }
                        } catch (error) {
                            console.log("ERRR /payables/vendors/getVendors", error, err, resp)
                            ErrorAlert({ description: error.message })
                            this.setState({ inboxItemLoading: false })
                        }
                    })
                } else {
                    var errMsg = data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later"
                    if(data.parseStatus) {
                        errMsg = "This bill is being processed by our AI Engine, please check back in a few minutes."
                    }
                    throw Error(errMsg)
                }
            } catch (error) {
                console.log("ERRR /payables/bills/getParsedInvoice", error, err, resp)
                ErrorAlert({ description: error.message })
                this.setState({ inboxItemLoading: false })
            }
        })
    }

    fetchVendors = () => {
        const body = {
            "viewBy": "FILTER_ACTIVE",
        }

        apiPOSTReq(`${environment.payBaseUrl}/payables/vendors/getVendors`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            try {
                const data = resp
                console.log("/payables/vendors/getVendors New Bill", (data))
                if (data.result) {
                    this.setState({ vendors: data.vendors || [], addVendor: false })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /payables/vendors/getVendors", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    addPaymentInfo = () => {
        var { bill, vendor } = this.state
        vendor = vendor || {}
        var custId = vendor.customerId
        console.log("addPaymentInfo", custId, vendor)
        if(bill && (bill.status != 'Unpaid')) return
        if(custId && bill) {
            // prepopulate the payment desc and memo with customer ID bill no.
            custId = custId.substring(0, 10)
            if(!bill.paymentDesc) bill.paymentDesc = custId
            if(!bill.paymentMemo) {
                var paymentMemo = `ID: ${vendor.customerId} | Invoice: ${bill.invoiceNumber}`
                bill.paymentMemo = paymentMemo.substring(0, 80)
            }
            this.setState({ bill })
        } else if (!bill.paymentMemo) {
            var paymentMemo = `Invoice: ${bill.invoiceNumber}`
            bill.paymentMemo = paymentMemo.substring(0, 80)
            this.setState({ bill })
        }
    }

    getVendor = (id) => {
        apiPOSTReq(`${environment.payBaseUrl}/payables/vendors/getVendor`, {}, { vendorId: id }, (err, resp) => {
            try {
                const data = resp
                console.log("/payables/vendors/getVendor", JSON.stringify(data))
                if (data.result && data.vendor) {
                    this.setState({ vendor: data.vendor }, () => this.addPaymentInfo())
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /payables/vendors/getVendor", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    fetchCOA = (options) => {
        const { pagination } = options
        const headers = {
            AionCurrentBiz: this.props.store.BusinessUniqueKey,
            AionAuth: this.props.store.UAM.encryptedAuthHeader
        }
        const body = {
            "BusinessId": this.props.store.BusinessUniqueKey,
            "size": pagination.pageSize,
            "page": pagination.current - 1,
            "ActiveRecords": true,
            "sortFieldName": "Code",
            "sortDirection": "ASC"
        }
        this.setState({ newProductLoading: true })
        apiPOSTReq(`${environment.bbBaseUrl}/bk/getCategories`, headers, body, (err, resp) => {
            try {
                const data = resp
                console.log("/bk/getCategories", data)
                if (data.result) {
                    this.setState({
                        pagination: {
                            ...pagination,
                            total: data.count
                        },
                        categories: (data.systemCOAList || []).concat(data.customCOAList),
                    })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getActiveCustomers", error.stack, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    confirmBill = (bill, vendor, callback) => {
        const { flowSteps } = this.state
        this.setState({ bill, vendor, saveBillLoading: true }, () => this.handleSubmit(true))
    }

    handleSubmit = (pay, callback) => {
        var { bgBillSave } = this.state
        if (pay === true) {
            this.setState({ payLoading: true })
        } else {
            this.setState({ saveLoading: true })
        }
        const { bill, vendor, fileList, flowSteps, inboxItem, vendorPaymentAccount } = this.state

        if (!vendor.vendorName) {
            this.setState({ saveLoading: false, payLoading: false })
            ErrorAlert({ description: "Please select a vendor for this bill." })
            return
        }
        if (!vendorPaymentAccount) {
            this.setState({ saveLoading: false, payLoading: false })
            ErrorAlert({ description: "Please add a default payment method for the selected vendor." })
            return
        }
        if (!bill.amount) {
            this.setState({ saveLoading: false, payLoading: false })
            ErrorAlert({ description: "Please enter an amount for this bill." })
            return
        }
        if (!bill.invoiceNumber) {
            this.setState({ saveLoading: false, payLoading: false })
            ErrorAlert({ description: "Please enter a bill no. for this bill." })
            return
        }
        if (!bill.dueDate) {
            this.setState({ saveLoading: false, payLoading: false })
            ErrorAlert({ description: "Please select a due date for this bill." })
            return
        }
        if (!vendor.id) {
            this.setState({ saveLoading: false, payLoading: false, addVendor: true })
            return
        }

        bill.vendorId = vendor.id
        bill.vendorName = vendor.vendorName

        const body = {
            Bill: bill,
        }

        if (inboxItem) body.billInboxItemId = inboxItem.id
        console.log("/payables/bills/save body", JSON.stringify(body))

        apiPOSTReq(`${environment.payBaseUrl}/payables/bills/save`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            this.setState({ saveLoading: false, payLoading: false })
            try {
                const data = resp
                if (data.result) {
                    this.setState({ bill: data.bill }, () => this.addPaymentInfo())
                    if(!bgBillSave) {
                        if (pay === true) {
                            this.setState({
                                flowStep: "pay", flowSteps: flowSteps.concat(["pay"]), title: this.getTitle("pay"),
                                paymentDate: moment.utc(bill.dueDate).isBefore(moment.utc()) ? moment.utc().businessAdd(1) : moment.utc(bill.dueDate).subtract(1, 'd')
                            })
                        } else {
                            // message.success("Bill saved successfully!")
                            this.props.history.replace('/payables/bills')
                        }
                    }
                    if(callback) callback(null, true)
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /payables/bills/save", error, err, resp)
                ErrorAlert({ description: error.message })
                if(callback) callback(error.message)
            }
        })
    }

    sendBillForApproval = (billId) => {
        apiPOSTReq(`${environment.payBaseUrl}/payables/sendBillForApproval`, {}, { billId: billId, ruleType: "Payable" }, (err, resp) => {
            this.setState({ saveLoading: false, payLoading: false })
            try {
                const data = resp
                console.log("/payables/sendBillForApproval", JSON.stringify(data))
                if (data.result) {
                    message.success("Bill sent for approval!")
                    this.props.history.push('/payables/bills')
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /payables/sendBillForApproval", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    getPaymentMethods = (vendor, callback) => {
        // const { vendor } = this.state
        const body = {
            "vendorId": vendor.associatedCPObjectId
        }
        this.setState({ loading: true })
        apiPOSTReq(`${environment.payBaseUrl}/payables/vendors/getPaymentMethods`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            try {
                this.setState({ loading: false })
                const data = resp
                console.log("/payables/vendors/getPaymentMethods", JSON.stringify(data))
                if (data.result) {
                    const vendorPaymentAccount = (data.paymentMethods || []).find(account => account.default === true)
                    this.setState({ paymentMethods: data.paymentMethods, vendorPaymentAccount })
                } else {
                    // throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getPaymentMethods", error, resp)
            }
            callback()
        })
    }

    back = () => {
        var { flowSteps } = this.state

        flowSteps = flowSteps.slice(0, flowSteps.length - 1)
        var flowStep = flowSteps[flowSteps.length - 1]

        switch (flowStep) {
            case "selectMethod":
                this.setState({
                    bill: {}, vendor: {}, selectVendorValue: "Yes", newVendorValue: "Yes", paymentMethod: "ACH",
                    accountType: "Checking", flowSteps, flowStep, title: this.getTitle(flowStep)
                })
                break
            case "billDetails":
            case "confirmVendor":
            case "newVendor":
                this.setState({ flowSteps, flowStep, title: this.getTitle(flowStep), userPaymentAccount: null, vendorPaymentAccount: null })
                break
            default:
                this.setState({ flowSteps, flowStep, title: this.getTitle(flowStep) })
                break
        }
    }

    getTitle = (flowStep) => {
        const { theme } = this.props
        const { vendor } = this.state

        switch (flowStep) {
            case "selectMethod":
                return "Add a new bill"
            case "billDetails":
                return "Bill Details"
            case "addVendor":
                return "Create new vendor"
            case "updateVendor":
                return "Update vendor"
            case "confirm":
                return "Confirm bill details"
            case "pay":
                return <>Pay your bill from <span style={{ color: theme.colors.secondary1 }}>{vendor.vendorName}</span></>
        }
    }

    evaluatePaymentApproval = (amount, vendorName) => {
        console.log(`${environment.payBaseUrl}/payables/evaluatePaymentApproval vendorName: `, vendorName)
        var body = { paymentAmount: amount, vendorName: vendorName }
        console.log(`${environment.payBaseUrl}/payables/evaluatePaymentApproval`, JSON.stringify(body))
        apiPOSTReq(`${environment.payBaseUrl}/payables/evaluatePaymentApproval`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            this.setState({ messageLoading: false })
            try {
                const data = resp
                console.log(`${environment.payBaseUrl}/payables/evaluatePaymentApproval`, JSON.stringify(data))
                if (data.result) {
                    this.setState({ approvalRequired: data.approvalRequired, approvalRule: data.approvalRule })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
                // this.setState({ payLoading: false })
            } catch (error) {
                console.log("ERRR /payables/evaluatePaymentApproval", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    getApprovalText = () => {
        const { approvalRule } = this.state
        const { levels } = approvalRule

        var approvalText

        if (levels.length > 1) {
            approvalRule.levels.forEach((level, i) => {
                approvalText = approvalText + `${(i > 0) && ', followed by'} <b>${ordinal(i + 1)}</b> level approval from ${this.getApproversText(level.performers, level.type)}`
            })
        } else {
            approvalText = `This payment requires approval from ${this.getApproversText(levels[0].performers, levels[0].type)}`
        }

        return approvalText
    }

    getApproversText = (approvers, approvalBy) => {
        if (approvers.length == 1) {
            return `${approvers[0].firstName} ${approvers[0].lastName}`
        } else {
            return <>
                {approvers.map(a => `${a.firstName} ${a.lastName}`).slice(0, approvers.length - 1).join(", ")}<b>{approvalBy === "ANY" ? " or " : " and "}</b>{`${approvers[approvers.length - 1].firstName} ${approvers[approvers.length - 1].lastName}`}
            </>
        }
    }

    onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
        this.setState({ numPages: nextNumPages })
    }

    previousPage = () => {
        const { pageNumber } = this.state
        this.setState({ pageNumber: pageNumber - 1 })
    }

    nextPage = () => {
        const { pageNumber } = this.state
        this.setState({ pageNumber: pageNumber + 1 })
    }

    handleMouseDown = (e) => {
        e.preventDefault()
        document.addEventListener('mousemove', this.handleMouseMove)
        document.addEventListener('mouseup', this.handleMouseUp)
    }
        
    handleMouseMove = (e) => {
        const pdfContainerElement = this.pdfContainerRef.current
        if (pdfContainerElement) {
            const newWidth = e.clientX - pdfContainerElement.getBoundingClientRect().left
            console.log("newWidth", newWidth, typeof(newWidth))
            if(newWidth > 500 && newWidth < 1000) {
                this.setState({ pdfWidth: newWidth })
                this.props.dispatch(addDataToStore(USERDEFAULTS_SAVE_FLAG, { BillsPdfWidth: newWidth }))
            }
            
        }
    }
        
    handleMouseUp = () => {
        document.removeEventListener('mousemove', this.handleMouseMove)
        document.removeEventListener('mouseup', this.handleMouseUp)
    }

    render() {
        const { bill, upload, billUrl, vendor, vendors, flowStep, categories, vendorPaymentAccount, inboxItem, inboxItemLoading, parsedInvoice, editBill, newBill } = this.state
        const { theme } = this.props

        var { previewDocumentUrls } = bill

        var content = (<div></div>)
        switch (flowStep) {
            case "billDetails":
            case "confirm":
                content = <Flex between gap='24px' fullWidth stretch={!upload} grow={!upload}>
                    {
                        flowStep == "billDetails" &&
                        <Skeleton loading={inboxItem && inboxItemLoading === true} paragraph={{ rows: 18 }}>
                            <BillDetail
                                key={(vendor || {}).id || "billdetail"}
                                newBill={newBill}
                                buttonText={editBill ? 'SAVE' : "PAY"}
                                bill={bill}
                                vendor={vendor}
                                parsedInvoice={parsedInvoice}
                                vendors={vendors}
                                categories={categories}
                                onSubmit={this.confirmBill}
                                inboxItem={inboxItem && inboxItem}
                                upload={upload}
                                edit={editBill}
                                onSave={(bill, vendor) => {
                                    this.setState({ bill, vendor, saveBillLoading: true }, () => {
                                        this.addPaymentInfo()
                                        this.handleSubmit(false)
                                    })
                                }}
                            />
                        </Skeleton>
                    }
                </Flex>
                break
            case "pay":
                content = (
                    <PayBill
                        bill={bill} vendor={vendor} vendorPaymentAccount={vendorPaymentAccount} 
                        onPay={
                            () => {
                                this.props.onApprovalAction()
                                this.props.history.push('/payables/bills')
                            }
                        }
                        saveBill={(bill, vendor, callback) => {
                            this.setState({ bill, vendor, bgBillSave: true }, () => {
                                this.handleSubmit(false, callback)
                            })
                        }}
                        editBill={() => {
                            this.setState({
                                flowStep: "billDetails",
                                editBill: true
                            })
                        }}
                    />
                )
                break
        }
        const resizeDivMargin = 15
        const resizeDivWidth = 2
        const resizeIconDivWidth = 20
        const resizeDivColor = theme.colors.primary3

        return (
            <FlexColumn className='main-padding' fullWidth start grow>
                <PageHeader
                    close
                    onClose={() => {
                        window.history.back()
                    }}
                    fullpage
                    titleText="Bill details"
                />
                <CardContainer>
                    <Flex between gap='16px' fullWidth style={{ position: 'relative' }}>
                        {
                            (previewDocumentUrls && previewDocumentUrls.length > 0) &&
                            <Flex
                                center
                                ref={this.pdfContainerRef}
                                style={{ width: this.state.pdfWidth}}
                            >
                                <PDFDocument
                                    download
                                    url={billUrl}
                                    fileName={bill.invoiceNumber}
                                    height={600}
                                    width={this.state.pdfWidth-48}
                                />
                            </Flex>
                        }
                        {
                            // Resize Bar
                            billUrl &&
                            <>
                                <div
                                    style={{
                                        width: `${resizeDivWidth}px`,
                                        backgroundColor: hexToRgba(resizeDivColor, 0.2),
                                        position: 'absolute',
                                        top: 10,
                                        bottom: 10,
                                        left: this.state.pdfWidth + resizeDivMargin,
                                        zIndex: 1,
                                        borderRadius: 5
                                    }}
                                />
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '400px',
                                        left: this.state.pdfWidth + resizeDivMargin - resizeIconDivWidth/2 + resizeDivWidth/2, //
                                        transform: 'translateY(-50%)',
                                        cursor: 'col-resize',
                                        zIndex: 2,
                                        backgroundColor: '#fff',
                                        borderRadius: '50%',
                                        width: `${resizeIconDivWidth}px`,
                                        height: `${resizeIconDivWidth}px`,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
                                    }}
                                    onMouseDown={this.handleMouseDown}
                                >
                                    <SVGIcon height={`${resizeIconDivWidth-5}px`} width={`${resizeIconDivWidth-5}px`} src={Resize} style={{ marginBottom: 2 }} color={hexToRgba(resizeDivColor, 0.5)} />
                                </div>
                            </>
                        }
                        {content}
                    </Flex>
                </CardContainer>
                
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        bkStore: state.bookkeepingAppReducer,
        store: state.aionAppReducer,
        bankingStore: state.bankingAppReducer,
        userDefaultsStore: state.userDefaultsReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(NewBillFlow))