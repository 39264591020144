import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Switch, withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components';
import {
    Layout,
    Alert,
    Modal,
    Typography,
    message,
    Button,
    Divider
} from 'antd'
import moment from 'moment'
import queryString from 'query-string'

import OAuthClient from 'intuit-oauth'

// Components
import SideMenu from '../Reusable/SideMenu'
import Dashboard from './Dashboard'
import Customers from './Customers/Index'
import BulkAddCustomer from './Customers/BulkAddCustomer'
import EditCustomer from './Customers/EditCustomer'
import CreateCustomer from './Customers/Create Customer/Index'
import CustomerDetails from './Customers/Customer Details/Index'
import InvoicePayments from './Invoice Payments/Index'
import MatchInvoice from './Invoice Payments/MatchInvoice'
import MatchedPayments from './Invoice Payments/MatchedPayments'
import Invoices from './Invoices/Index'
import EditInvoice from './Invoices/New Invoice/Index'
import CreateInvoice from './Invoices/Create Invoice/Index'
import ViewInvoice from './Invoices/View Invoice/Index'
import AddInvoice from './Invoices/AddInvoice'
import VoidedInvoices from './Invoices/VoidedInvoices'
import Templates from './Invoices/Template/Index'
import Products from './Products/Index'
import GettingStarted from './GettingStarted'

// Util
import { apiGET, apiPOST, apiPOSTReq } from '../../Utils/api'
import environment from '../../environment'

// import qbButton from '../../Images/C2QB_auth.png'
import qbButton from '../../Images/C2QB_green_btn_tall_hover.svg'
import ModalClose from '../../Images/modal-close.png'
import Banner from '../Reusable/Banner';
import { ErrorAlert } from '../Reusable/Alert';
import { addDataToStore, BNK_SAVE_DATA } from '../../Actions/actions';
import { FlexColumn } from '../Reusable/Container';

const { Text, Title } = Typography;
const { Header, Content, Sider, Footer } = Layout;

class Index extends Component {

    state = {
    };

    componentDidMount() {
        const value = queryString.parse(this.props.location.search)
        const state = value.state
        const url = window.location.href

        if (state && state === 'qb-dashboard') {
            this.props.history.replace('/receivables/invoices')

            if (value.code && value.realmId) {
                const headers = {
                    businesskey: this.props.aionStore.BusinessUniqueKey
                }

                apiGET(`/financing/oauth/token?url=${url}`, headers, (err, resp) => {
                    try {
                        const token = resp.data.Response;
                        if (resp.data.success) {

                            const data = {
                                accessToken: token.access_token,
                                accessTokenExpiresIn: token.expires_in,
                                accessTokenSecret: "",
                                companyId: value.realmId,
                                accountingSoftware: "QuickBooksOnline",
                                dateCreated: Date.now(),
                                refreshToken: token.refresh_token,
                                refreshTokenExpiresIn: token.x_refresh_token_expires_in,
                                oauthVersion: "2.0",
                                refreshTokens: true
                            }

                            apiPOST('accountingsoftwareinfo', headers, data, (err, resp) => {
                                // console.log("accountingsoftwareinfo resp: " + JSON.stringify(resp))
                                if (!err) {
                                    try {
                                        const data = resp.data;

                                        if (data.success) {
                                            this.setState({ qbExpired: false })
                                            message.success('QuickBooks reconnected successfully!')
                                        } else {
                                            message.error('Sorry, we had trouble reconnecting your QuickBooks. Please try again.')
                                            throw Error("Could not complete submission.")
                                        }
                                    } catch (error) {
                                        console.error("The error message is :" + JSON.stringify(error))
                                        message.error('Sorry, we had trouble reconnecting your QuickBooks. Please try again.')
                                    }
                                } else {
                                    console.error("The error message is :" + JSON.stringify(err))
                                    message.error('Sorry, we had trouble reconnecting your QuickBooks. Please try again.')
                                }
                                this.setState({ invoiceRefreshLoading: false })
                            })
                        } else {
                            console.error("The error message is :" + JSON.stringify(err))
                            message.error('Sorry, we had trouble reconnecting your QuickBooks. Please try again.')
                            this.setState({ invoiceRefreshLoading: false })
                            throw Error("Could not fetch attributes.")
                        }
                    } catch (error) {
                        console.error("ERROR", error.stack)
                        message.error('Sorry, we had trouble reconnecting your QuickBooks. Please try again.')
                        this.setState({ invoiceRefreshLoading: false })
                    }
                })
            }
        } else {
            if (!url.includes('callback') && !url.includes('reconnect_qb')) {
                apiGET("/gnpl/funding/collateral", {
                    businesskey: this.props.aionStore.BusinessUniqueKey
                }, (err, resp) => {
                    try {
                        const data = resp.data;
                        // console.log("/invoices", data)
                        if (!data.success && data.msg === "Tokens Expired") {
                            this.setState({ qbModalVisible: true, qbExpired: true })
                        }
                    } catch (error) {
                        console.log("ERROR", error.stack)
                    }
                });
                if (this.state.contractSigned) this.props.history.replace('/receivables/customers')
            }
        }
        this.handleGS()
        this.fetchBankAccounts()
    }

    fetchBankAccounts = () => {

        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/getAccounts`, {}, null, (err, resp) => {
            try {
                console.log("/ive/invoice/getAccounts", err, resp)
                const data = resp
                if (data.result) {
                    if (err) throw new Error(err)
                    var accounts = data.accounts
                    this.props.dispatch(addDataToStore(BNK_SAVE_DATA, { BBAccounts: accounts }))
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getAccounts", error, err, resp)
            }
        })
    }

    handleGS() {
        apiPOSTReq(`${environment.iveBaseUrl}/ive/templates/getActiveTemplates`, {}, {}, (err, resp) => {
            try {
                const data = resp || {};
                console.log("/ive/templates/getActiveTemplates", data)
                if (data.result) {
                    if ((data.templates || []).length == 0) {
                        this.setState({
                            showGSModal: true
                        })
                    }
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getActiveTemplates", error, resp);
                ErrorAlert({ description: error.message })
            }
        })
    }

    reconnectQuickBooks() {
        this.setState({ qbModalVisible: false })

        var oauthClient = new OAuthClient(environment.qboOAuth);
        const authUri = oauthClient.authorizeUri({
            scope: [
                OAuthClient.scopes.Accounting,
                OAuthClient.scopes.OpenId,
                OAuthClient.scopes.Profile,
                OAuthClient.scopes.Email,
                OAuthClient.scopes.Phone,
                OAuthClient.scopes.Address
            ],
            state: 'qb-dashboard'
        });

        window.location.href = authUri
    }

    previousLocation = this.props.location
    componentWillUpdate(nextProps) {
        let { location } = this.props

        // set previousLocation if props.location is not modal
        if (
            nextProps.history.action !== "POP" &&
            (!location.state || !location.state.modal)
        ) {
            this.previousLocation = this.props.location
        }
    }

    copySysCOA = () => {
        const headers = {
            AionAuth: this.props.aionStore.UAM.encryptedAuthHeader
        }
        var body = {
            "BusinessId": this.props.aionStore.BusinessUniqueKey
        }

        this.setState({ submitLoading: true });

        apiPOSTReq(`${environment.bbBaseUrl}/bk/copySysCOA`, headers, body, (err, resp) => {
            try {
                const data = resp;
                console.log("/bk/copySysCOA", data)
                if (data.result) {
                    console.log("copySysCOA data", data);
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
                this.setState({ showGSModal: false, submitLoading: false });
                this.props.history.push('/receivables/invoices');
            } catch (error) {
                this.setState({ showGSModal: false, submitLoading: false });
                console.log("ERRR copySysCOA", error.stack, resp);
            }
        })
    }

    render() {
        let { location } = this.props;

        return (
            <FlexColumn start style={{ padding: 0, flexGrow: 1, height: '100%' }}>
                <Switch location={location}>
                    <Route exact path="/receivables/customers" component={Customers} />
                    <Route path="/receivables/customers/add" component={BulkAddCustomer} />
                    <Route path="/receivables/customers/edit" component={EditCustomer} />
                    <Route path="/receivables/customers/create" component={CreateCustomer} />
                    <Route path="/receivables/customers/details" component={CustomerDetails} />
                    <Route exact path="/receivables/invoice-payments" component={InvoicePayments} />
                    <Route exact path="/receivables/invoice-payments/match-invoice" component={MatchInvoice} />
                    <Route exact path="/receivables/invoice-payments/matched-payments" component={MatchedPayments} />
                    <Route
                        exact path="/receivables/invoices"
                        render={(props) => <Invoices {...props} qbExpired={this.state.qbExpired} reconnectQB={() => this.setState({ qbModalVisible: true })} />}
                    />
                    <Route path="/receivables/invoices/add" component={AddInvoice} />
                    <Route path="/receivables/invoices/edit" component={EditInvoice} />
                    <Route path="/receivables/invoices/create" component={CreateInvoice} />
                    <Route path="/receivables/invoices/revise" component={CreateInvoice} />
                    <Route path="/receivables/invoices/view" render={(props) => (<ViewInvoice {...props} setTopNavContent={this.props.setTopNavContent} />)} />
                    <Route path="/receivables/invoices/resend" component={ViewInvoice} />
                    <Route path="/receivables/invoices/voided" component={VoidedInvoices} />
                    <Route path="/receivables/templates" component={Templates} />
                    <Route path="/receivables/products" component={Products} />
                </Switch>

                <Modal
                    visible={this.state.qbModalVisible}
                    title="Reconnect QuickBooks"
                    closable={false}
                    okText="Save"
                    onCancel={() => (this.setState({ qbModalVisible: false }))}
                    onOk={this.saveCustomer}
                    confirmLoading={this.state.confirmLoading}
                    destroyOnClose={true}
                    width={700}
                    footer={null}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <div className="flex-column-center" style={{ textAlign: 'center', paddingBottom: '20pt' }}>
                        <Text strong={true} style={{ fontSize: '12pt', marginBottom: '12pt' }}>We need you to reconnect your QuickBooks account<br />before you can refresh your invoices</Text>
                        <Banner title="QuickBooks Master admin or Company admin rights required" message="Make sure you have the right permissions. You will need master admin or company rights to connect to QuickBooks" />
                        <br />
                        <Text>You will be redirected to QuickBooks to provide authorization to Aion</Text>
                        <Text style={{ margin: '32pt 0 12pt 0' }}>Tap on the button below to<br />reconnect you QuickBooks account</Text>
                        <img style={{ height: '40pt' }} src={qbButton} onClick={() => { this.reconnectQuickBooks() }} />
                        <br />
                        <Alert message="We do not store or have access to your QuickBooks credentials" type="info" />
                    </div>
                </Modal>
                <Modal
                    visible={this.state.showGSModal}
                    footer={null}
                    closable={true}
                    width={700}
                    style={{ top: 20 }}
                    destroyOnClose={true}
                    onCancel={() => window.history.back()}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <GettingStarted
                        submitComplete={
                            () => {
                                this.copySysCOA()
                            }
                        }
                    />
                </Modal>
            </FlexColumn>
        );
    }
}

function mapStateToProps(state) {
    return {
        store: state.creditAppReducer,
        bankingStore: state.bankingAppReducer,
        userDefaults: state.userDefaultsReducer,
        aionStore: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)));