import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'
import { Layout } from 'antd'

// Components
import { FlexColumn, Container } from '../../../Reusable/Container'
import PageHeader from '../../../Reusable/PageHeader'
import NewVendor from './NewVendor'
import EditVendor from '../Edit Vendor/EditVendor'

class Pay extends Component {

    render() {
        return (
            <FlexColumn className='main-padding' style={{ width: '100%' }} start>
                <PageHeader
                    back
                    backText='Vendors'
                    titleText="Create new vendor" />

                <Container shadow style={{ marginBottom: 48 }} padding='12px 24px 24px'>
                    <NewVendor onSave={() => window.history.back()} />
                </Container>
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        bkStore: state.bookkeepingAppReducer,
        store: state.aionAppReducer,
        bankingStore: state.bankingAppReducer,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Pay))