import React, { useEffect, useState } from 'react'
import { Table } from 'antd'

import { Text } from '../../Reusable/Text'
import { CardContainer, Flex, FlexColumn } from '../../Reusable/Container'
import { ImageButton } from '../../Reusable/Button'
import ChevronDown from '../../../Images/chevron-down.png'
import ChevronUp from '../../../Images/chevron-up.png'
import Check from '../../../Images/check.png'
import { Image } from '../../Reusable/Image'
import ApiIcon from "../../../Images/new-key.png"
import BankingActiveIcon from "../../../Images/product-menu/bank-menu-icon-active.png"
import CreditActiveIcon from "../../../Images/product-menu/credit-menu-icon-active.png"
import ContractorsActiveIcon from "../../../Images/product-menu/contractors-menu-icon-active.png"
import PayablesActiveIcon from "../../../Images/product-menu/payables-menu-icon-active.png"
import ReceivablesActiveIcon from "../../../Images/product-menu/receivables-menu-icon-active.png"
import CardsActiveIcon from "../../../Images/product-menu/CardsActive.png"
import BkActiveIcon from "../../../Images/calendar.png"
import { getBPAccountsWithAccess } from '../../../Utils/util'

const ViewRole = (props) => {
    const [loading, setLoading] = useState(false)
    const [collapsed, setCollapsed] = useState({})
    const { selectedRole, customDescComponent, userRoleNames } = props
    const productNameMap = {
        "TermLoans": { name: "Term Loans", icon: CreditActiveIcon, order: 6 },
        "BusinessBanking": { name: "Banking", icon: BankingActiveIcon, order: 0 },
        "Banking": { name: "Banking", icon: BankingActiveIcon, order: 1 },
        "BusinessCards": { name: "Cards", icon: CardsActiveIcon, order: 2 },
        "ARCredit": { name: "AR Credit", icon: CreditActiveIcon, order: 7 },
        "Credit": { name: "Credit", icon: CreditActiveIcon, order: 5 },
        "ABLCredit": { name: "ABL Credit", icon: CreditActiveIcon, order: 8 },
        "Receivables": { name: "Receivables", icon: ReceivablesActiveIcon, order: 3 },
        "Payables": { name: "Bill Payments", icon: PayablesActiveIcon, order: 4 },
        "Bill Payments": { name: "Bill Payments", icon: PayablesActiveIcon, order: 4 },
        "Bookkeeping": { name: "Bookkeeping", icon: BkActiveIcon, order: 9 },
        "AionAPI": { name: "API", icon: ApiIcon, order: 10 },
        "API": { name: "API", icon: ApiIcon, order: 11 },
        "UserMgmt": { name: "Business Profile", icon: ContractorsActiveIcon, order: 12 },
    }

    useEffect(() => {
        console.log('ViewRole selectedRole: ', selectedRole)
    }, [])

    var Accounts = getBPAccountsWithAccess("Transactions", { getActiveAccounts: true })

    console.log("selectedRole resourcePermissions", selectedRole)

    const getColumns = (product) => {
        // console.log('getColumns product', product)

        return [
            {
                title: 'Permissions',
                titleName: 'Permissions',
                render: (type, record) => <FlexColumn>
                    <Text weight={500}>{record.resourceLabel}</Text>
                    {
                        record.resourceDescription &&
                        <Text size='14px'>{record.resourceDescription}</Text>
                    }
                </FlexColumn>,
            },
            {
                title: <Flex style={{ marginLeft: 22 }} start centerHorizontally gap='4px'>
                    <Text>Approve</Text>
                </Flex>,
                titleName: 'Approve',
                render: (type, record) => record.permissionCodes.includes("Approval__Sys") ? <Image src={Check} /> : <></>,
                width: 120,
                align: 'center'
            },
            {
                title: '', // empty column to maintain widths
                render: (type, record) => <></>,
                width: 120,
                align: 'center'
            },
            {
                title: <Flex style={{ marginLeft: 22 }} start centerHorizontally gap='4px'>
                    <Text>Manage</Text>
                </Flex>,
                titleName: 'Manage',
                render: (type, record) => (record.permissionCodes.includes("Approval__Sys") || record.permissionCodes.includes("Manage__Sys")) ? <Image src={Check} /> : <></>,
                width: 120,
                align: 'center'
            },
            {
                title: <Flex style={{ marginLeft: 22 }} start centerHorizontally gap='4px'>
                    <Text>View</Text>
                </Flex>,
                titleName: 'View',
                render: (type, record) => (record.permissionCodes.includes("Approval__Sys")
                    || record.permissionCodes.includes("Manage__Sys")
                    || record.permissionCodes.includes("View__Sys")) ? <FlexColumn center><Image src={Check} /></FlexColumn> : <></>,
                width: 120,
                align: 'center'
            },
        ]
    }

    var { resourcePermissions, accountResourcePermissions } = selectedRole || {}
    resourcePermissions = resourcePermissions || []

    if (accountResourcePermissions && accountResourcePermissions.length > 0 && !selectedRole.addedAccountResourcePermissions) {
        accountResourcePermissions.forEach(accountResourcePermission => {
            accountResourcePermission.resourcePermissions.forEach(arrrp => {
                arrrp.accountId = accountResourcePermission.accountId
                resourcePermissions.push(arrrp)
            })
        })
        selectedRole.addedAccountResourcePermissions = true
    }

    var permByProduct = {}
    resourcePermissions.forEach(item => {
        if (!item.resourceLabel) item.resourceLabel = item.resourceName

        if (item.accountId) {
            const account = Accounts.find(acc => acc.accountId === item.accountId)
            const accountSuffix = `${(account.nickName || `Business ${account.accountSubType}`)} • ${account.mask} - `
            if(!item.resourceLabel.includes(accountSuffix)) item.resourceLabel = `${accountSuffix}${item.resourceLabel}`
        }

        var nameSpace = item.nameSpace
        if (nameSpace == "BusinessBanking") nameSpace = "Banking"
        if (permByProduct[nameSpace]) {
            var prodPerm = permByProduct[nameSpace]
            if (prodPerm[item.resourceLabel]) {
                prodPerm[item.resourceLabel] = prodPerm[item.resourceLabel].concat(item.permissionCodes)
            } else {
                prodPerm[item.resourceLabel] = item.permissionCodes
            }
        } else {
            permByProduct[nameSpace] = { [item.resourceLabel]: item.permissionCodes } // Initialize
        }
    })

    var tableDataObj = {}
    Object.keys(permByProduct).forEach((product) => {
        var resourceMap = permByProduct[product]
        tableDataObj[product] = Object.keys(resourceMap).map(resourceLabel => {
            var resource = resourcePermissions.find(rp => rp.resourceLabel === resourceLabel) || {}
            return ({ resourceLabel: resourceLabel, resourceDescription: resource.resourceDescription, permissionCodes: resourceMap[resourceLabel] })
        })
    })

    return (
        <FlexColumn start left gap="24px">
            <FlexColumn start left gap="8px">
                <Text heading>{props.roleName || selectedRole.roleName}</Text>
                {
                    selectedRole.description &&
                    <Text>{selectedRole.description}</Text>
                }
                {
                    customDescComponent
                }
            </FlexColumn>
            <FlexColumn>
                {
                    Object.keys(permByProduct).sort((a, b) => ((productNameMap[a] || { order: 99 }).order - (productNameMap[b] || { order: 99 }).order)).map(product => {
                        console.log('ViewRole product', product)
                        const productPermData = tableDataObj[product]
                        var columns = ["Permissions", "View", "Manage"]
                        var hasApproval = false
                        productPermData.forEach(resource => {
                            if (resource.permissionCodes.includes("Approval__Sys")) hasApproval = true
                        })
                        if (hasApproval) columns.push("Approve")
                        else columns.push("")
                        const productIcon = (productNameMap[product] || {}).icon
                        const title = (
                            <>
                                <Flex center gap="8px">
                                    {(productNameMap[product] || {}).icon && <Image src={productIcon} />}
                                    <Text weight={600}>
                                        {
                                            (product === "AionApplications") ?
                                                "All Applications"
                                                :
                                                (productNameMap[product] || {}).name || product
                                        }
                                    </Text>
                                </Flex>
                                <ImageButton
                                    onClick={() => {
                                        setCollapsed({ ...collapsed, [product]: !collapsed[product] })
                                    }}
                                    src={collapsed[product] ? ChevronDown : ChevronUp}
                                />
                            </>
                        )
                        return (
                            <CardContainer width={props.width || "852px"} key={product}>
                                <FlexColumn start fullWidth>
                                    {
                                        collapsed[product] ?
                                            <Flex between fullWidth centerHorizontally gap="24px">
                                                {title}
                                            </Flex>
                                            :
                                            <>
                                                <Flex between fullWidth centerHorizontally style={{ marginBottom: 24 }} gap="24px">
                                                    {title}
                                                </Flex>
                                                {

                                                }
                                                <Table
                                                    id="role-table"
                                                    className='borderless-table'
                                                    pagination={false}
                                                    tableLayout='auto'
                                                    columns={getColumns(product).filter(item => columns.includes(item.titleName))}
                                                    dataSource={tableDataObj[product]}
                                                    rowKey="resourceName"
                                                />
                                            </>
                                    }
                                </FlexColumn>
                            </CardContainer>
                        )
                    }

                    )
                }
            </FlexColumn>
        </FlexColumn>
    )
}

export default ViewRole