import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { Steps } from 'antd';
import { Text, Tag } from '../../Reusable/Refresh/Text';
import { Flex, FlexColumn } from './Container';
import { Button, TextButton } from './Button';
import { ArrowLeftOutlined, ArrowRightOutlined, CheckOutlined } from '@ant-design/icons';
import InfoSquare from '../../../Images/info-square.png';

// Images
import LockIcon from '../../../Images/lock-icon.png';

const { Step } = Steps;

const LockSteps = (props) => {
    const { onClick, steps, step, alignContent, error} = props

    console.log("LockSteps: ", step)

    return (
        <Flex  centerHorizontally style={{ height: 48, background: '#FBFBFB', width: '100%' ,  justifyContent: alignContent ? 'center' : 'start', alignItems: alignContent ? 'center' : 'start'}}>
            {
                steps &&
                steps.map((stepTitle, index) => {
                    return <div style={{ marginRight: 24 }}>
                        {
                            ( index === step && !error) &&
                            <Button noClick padding='0 16px' key={`progressButton-${index}`} height='40px' onClick={onClick && onClick} background='#F5F6FE' text={stepTitle} weight='600' icon={<Text style={{ width: 24, height: 24 }} center weight='600' color='#1199FF'>{index + 1}</Text>} radius='4px' />
                        }

                        {
                            ( index === step && error) &&
                            <Button noClick padding='0 16px' key={`progressButton-${index}`} height='40px' onClick={onClick && onClick} background='#F5F6FE' text={stepTitle} weight='600' icon={<img width='24px' height='24px' src={InfoSquare}/> } radius='4px' />
                        }

                        {
                            index < step &&
                            <TextButton noClick key={`progressButton-${index}`} margin='0 24px' height='40px' onClick={onClick && onClick} text={stepTitle} icon={<CheckOutlined color='#1199FF' />} />
                        }

                        {
                            index > step &&
                            <TextButton noClick key={`progressButton-${index}`} margin='0 24px' height='40px' onClick={onClick && onClick} text={stepTitle} color='#666666' icon={<img width='24px' height='24px' src={LockIcon} />} />
                        }
                    </div>
                })
            }
        </Flex>
    )
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(LockSteps)));