import React from 'react'
import { Modal } from 'antd'
import { Text } from './Text'
import { Image } from './Image'
import { FlexColumn } from './Container'
import { Button, TextButton } from './Button'
import Exclamation from '../../Images/exclamation.png'
import ModalClose from '../../Images/modal-close.png'

const SuccessModal = (props) => {
    const { title, description, onConfirm, onCancel, loading, visible } = props

    return (<Modal
        visible={visible}
        footer={null}
        closable={true}
        width={456}
        destroyOnClose={true}
        onCancel={onCancel}
        closeIcon={<img width='24px' height='24px' src={ModalClose} />}
    >
        <FlexColumn center>
            <Image margin='24px 0 12px' width='48px' height='48px' src={Exclamation} />
            <Text size='28px' height='40px' weight='500' style={{ marginBottom: 24, marginTop: 8 }}>{title}</Text>
            <Text center margin='0 12px 24px' width='300px'>{description}</Text>
            <Button
                solid
                text='YES'
                onClick={onConfirm}
                margin='0 0 24px'
                loading={loading}
            />
            <TextButton text='NO' onClick={onCancel} />
        </FlexColumn>
    </Modal>
    )
}

export default SuccessModal