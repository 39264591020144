import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Table, Skeleton, Tabs, Tooltip, Menu, message } from 'antd'
import { withTheme } from 'styled-components'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import { saveAs } from 'file-saver'
import { validate } from 'uuid'

import { Flex, FlexColumn, LightContainer, CardContainer } from '../../Reusable/Container'
import { Text, Tag } from '../../Reusable/Text'
import { Image } from '../../Reusable/Image'
import { Button, TextButton, ImageButton } from '../../Reusable/Button'
import CancelModal from '../../Reusable/CancelModal'
import { apiPOSTReq, getPreSignedS3Url } from '../../../Utils/api'
import { ErrorAlert } from '../../Reusable/Alert'
import { TransferJourney } from '../../Reusable/Approval'
import { ACHDetail, WireDetail, CheckDetail, InternalDetail, CardDetail, InstantDetail } from './AccountDetail'
import { getBPAccountsWithAccess, toCurrency } from '../../../Utils/util'
import environment from '../../../environment'

// Images
import ChevronDown from "../../../Images/chevron-down.png"
import ChevronUp from "../../../Images/chevron-up.png"
import File from '../../../Images/file.png'
import { LoadingOutlined } from '@ant-design/icons'
import { LabeledInput } from '../../Reusable/Input'
import Info from '../../../Images/info.png'
import Return from '../../../Images/return.png'
import TableView from '../../Reusable/TableView'

const { TabPane } = Tabs

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
})

const dFormat = "ll";
const etTimeZone = "America/New_York";

const PendingTable = (props) => {
    const [expandedRowKeys, setExpandedRowKeys] = useState([])
    const [objectMap, setObjectMap] = useState({})
    const [collapsed, setCollapsed] = useState(props.autoExpand === true ? false : true)
    const [accountMap, setAccountMap] = useState({})
    const [showCancel, setShowCancel] = useState(false)
    const [cancelLoading, setCancelLoading] = useState(false)
    const [paymentToCancel, setPaymentToCancel] = useState()
    const [selectedRowId, setSelectedRowId] = useState(null)

    const history = useHistory()

    const { theme, loadingPending, pendingTransfers, pendingSums, title, subtitle, collapsible, cta, onCancelled, more, tooltip,
        noTitle, hideType, hideStatus, showAccount, account, padding, setSearchTerm, hideCardInfo, moreTransactions, totalTransactions } = props

    var colSpan = 4
    if (hideType) colSpan = colSpan - 1
    if (hideStatus) colSpan = colSpan - 1
    if (more) colSpan = colSpan - 1

    useEffect(() => {
        var Accounts = getBPAccountsWithAccess("Transactions", { getActiveAccounts: true })
        Accounts = Accounts || []

        var accountMap = {}

        Accounts.forEach(account => {
            accountMap[account.accountId] = account
        })

        setAccountMap(accountMap)
    }, [])

    useEffect(() => {
        if (paymentToCancel) {
            setShowCancel(true)
        } else {
            setShowCancel(false)
        }
    }, [paymentToCancel])

    const getObjectDetail = (record) => {
        const { objectId, transaction, type, transactionId } = record;

        let body = {
            objectId: (transaction && !objectId) ? transactionId : objectId
        };

        // This validator checks if the id is a UUID or a mongoDB BSON ID
        if (validate(objectId)) body.fetchByPrimaryKey = false
        else body.fetchByPrimaryKey = true

        // console.log('getObjectDetail', validate(objectId), objectId, transaction, type, transactionId, record);
        const typeMapping = {
            'ACH': 'ACH',
            'Wire': 'WIRE_TFER',
            'Check': 'CHECK_DEPOSIT',
            'Internal': 'BOOK_TFER',
            'Card': 'CARD_TXN',
            'CardMemo': 'MEMO_TXN',
            'Instant': 'INSTANT_TFER',
            'Rtp': 'INSTANT_TFER'
        };

        body.viewBy = typeMapping[type];

        console.log('bb/getObjectDetail PendingTable body', body);

        apiPOSTReq(`${environment.bbBaseUrl}/bb/getObjectDetail`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            const objectMapClone = cloneDeep(objectMap);
            try {
                if (err) throw Error(err);
                if (!resp.result) throw Error(resp.responseMessage || resp.error);

                console.log('bb/getObjectDetail PendingTable resp', resp);

                const objectDetailMapping = {
                    'ACH': 'achTransferObj',
                    'Wire': 'wireTransferObj',
                    'Check': 'checkDepositObj',
                    'Internal': 'bookTransferObj',
                    'Card': 'cardManagementTransactionInfo',
                    'CardMemo': 'cardManagementTransactionInfo',
                    'Instant': 'instantTransferObj',
                };

                let object = resp[objectDetailMapping[type]] || record;
                object.type = type;

                if (object && resp.fiToFiInfos) object.fiToFiInfos = resp.fiToFiInfos

                console.log('bb/getObjectDetail PendingTable record', record);
                console.log('bb/getObjectDetail PendingTable objectDetail', object);

                objectMapClone[objectId] = { objectDetail: object } || true;

                if (object.approvalInstanceId) {
                    getApprovalInstance(record, object);
                } else {
                    setObjectMap(objectMapClone);
                }
            } catch (error) {
                objectMapClone[objectId] = { objectDetail: record } || true;
                setObjectMap(objectMapClone);
                console.error('bb/getObjectDetail', error);
            }
        });
    }

    const getApprovalInstance = (record, objectDetail) => {
        var { objectId, approvalInstanceId } = record
        if (objectDetail) approvalInstanceId = objectDetail.approvalInstanceId
        apiPOSTReq(`${environment.bbBaseUrl}/bb/approvals/getApprovalInstance `, { "BankProvider": environment.bankProvider.crb }, { instanceId: approvalInstanceId, }, (err, resp) => {
            try {
                const data = resp
                if (data.result && data.approvalRuleInstance) {
                    const objectMapClone = cloneDeep(objectMap)
                    if (objectMapClone[objectId]) {
                        objectMapClone[objectId].approvalInstance = data.approvalRuleInstance
                    } else {
                        objectMapClone[objectId] = { approvalInstance: data.approvalRuleInstance } || true
                    }

                    if (objectDetail) objectMapClone[objectId].objectDetail = objectDetail

                    setObjectMap(objectMapClone)
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                ErrorAlert({ description: error.message })
            }
        })
    }

    const onTabChange = (tab, object) => {
        object.tab = tab
        const objectMapClone = cloneDeep(objectMap)
        objectMapClone[object.objectId] = object

        setObjectMap(objectMapClone)
    }

    const cancelPayment = (objectDetail, type) => {
        setCancelLoading(true)
        var body = {};
        console.log("cancelTransfer objectDetail", objectDetail);
        body.objectId = objectDetail.id || objectDetail.objectId;
        console.log("cancelPayment crb body", body)
        apiPOSTReq(`${environment.bbBaseUrl}/bb/cancel${type}`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            try {
                const data = resp || {}
                console.log(`/cancel${type} crb YOLO`, err, data)
                if (data.result) {
                    message.success("Successfully canceled")
                    if (onCancelled) onCancelled()
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("ERRR bp cancelPayment", error, resp)
                ErrorAlert({ description: error.message || "We had trouble processing your request. Please try again." })
            } finally {
                setCancelLoading(false)
                setShowCancel(false)
            }
        })
    }

    const getRowDetails = (record, props) => {
        var { objectId, status, type, userNote, description } = record
        var object = objectMap[objectId]
        var { approvalInstance, objectDetail } = (object || {})
        objectDetail = objectDetail || {}

        var { additionalDetails, serviceType } = objectDetail

        if (!objectDetail.userNote) objectDetail.userNote = userNote
        if (!objectDetail.description) objectDetail.description = description

        console.log('getRowDetails additionalDetails record', record)
        console.log('getRowDetails additionalDetails objectDetail', objectDetail)

        return (
            <Skeleton loading={!object} active title={false} paragraph={{ rows: 3 }}>
                <FlexColumn start>
                    {(() => {
                        {/*
                            if (record.subRail && record.subRail.includes('Instant')) return (
                            <InstantDetail
                                detail={objectDetail}
                                traceNumber={record.traceNumber}
                            />)
                        */}

                        switch (type) {
                            case 'ACH':
                                return <ACHDetail viewReturn={false} record={record} detail={objectDetail} onViewOriginal={(searchTerm) => {
                                    setSearchTerm(searchTerm)
                                    setCollapsed(true)
                                }} />
                            case 'Wire':
                                return <WireDetail record={record} detail={objectDetail} />
                            case 'Check':
                                return <CheckDetail detail={objectDetail} />
                            case 'Internal':
                            case 'XPay':
                                return <InternalDetail detail={objectDetail} />
                            case 'Instant':
                                return <InstantDetail detail={objectDetail} instant />
                            case 'CardManagement':
                            case 'Card':
                            case 'CardMemo':
                                return <CardDetail detail={objectDetail} hideCardInfo={hideCardInfo} />
                            default:
                                return null
                        }
                    })()}

                    {
                        !['CardManagement', 'Card', 'CardMemo'].includes(type) && status.toLowerCase() !== 'scheduled' &&
                        <Tabs key={`${objectId}-pendingTabs`} size="large" activeKey={object?.tab && object.tab} defaultActiveKey={'transferJourney'} onChange={(tab) => onTabChange(tab, object)}>
                            <TabPane tab="Transfer Journey" key="transferJourney">
                                <LightContainer fullWidth>
                                    <TransferJourney approvalInstance={approvalInstance} payment={record} objectDetail={objectDetail} />
                                </LightContainer>
                            </TabPane>

                            {
                                additionalDetails && additionalDetails.length > 0 && (additionalDetails.find(detail => detail.context === 'Notifications to') || additionalDetails.find(detail => detail.attachmentList && detail.attachmentList.length > 0)) &&
                                <TabPane tab="Additional Details" key="additionalDetails">
                                    <LightContainer fullWidth padding='8px 24px 12px'>
                                        {
                                            additionalDetails.map(additionalDetail => {
                                                if (additionalDetail.attachmentList && additionalDetail.attachmentList.length > 0) {
                                                    return <FlexColumn style={{ marginBottom: 12 }}>
                                                        <Text size='14px' caption weight='400' margin='0 0 8px'>{additionalDetail.context}</Text>
                                                        <Flex start gap='8px' wrap>
                                                            {
                                                                additionalDetail.attachmentList.map((file, i) => {
                                                                    return <Tag key={`${i}-${file.fileName}`} onClick={() => downloadDocument(file.uri, file.fileName)} primary noCap bordered><Image src={File} margin='0 4px 0 0' />{file.fileName}</Tag>
                                                                })
                                                            }
                                                        </Flex>
                                                    </FlexColumn>
                                                } else {
                                                    if (additionalDetail.context === 'Notifications to') {
                                                        return <LabeledInput type='read-only' label={additionalDetail.context} value={additionalDetail.contextValue} />
                                                    }
                                                }
                                            })
                                        }
                                    </LightContainer>
                                </TabPane>
                            }
                        </Tabs>
                    }

                    {
                        (['ACH'/*, 'Wire'*/].includes(type)) && (['created'].includes(status)) && (serviceType != 'SameDay') &&
                        <TextButton text='CANCEL TRANSFER' color='#E52D2D' margin='24px 0 12px' onClick={() => {
                            console.log('paymentToCancel', objectDetail);
                            setPaymentToCancel(objectDetail)
                            setShowCancel(true)
                        }} />
                    }

                    {
                        paymentToCancel &&
                        <CancelModal
                            key={`${paymentToCancel.objectId}`}
                            visible={showCancel}
                            title='Cancel Payment?'
                            description={`You're about to cancel ${type === 'ACH' ? 'an ACH' : 'a Wire'} transfer for $${toCurrency(paymentToCancel.amount)} ${paymentToCancel.transactionType == "debit" ? "to" : "from"} ${paymentToCancel.counterpartyName || paymentToCancel.recipient}. This cannot be undone.`}
                            buttonTitle='OKAY'
                            onConfirm={() => cancelPayment(paymentToCancel, paymentToCancel.type)}
                            onCancel={() => setPaymentToCancel(null)}
                            loading={cancelLoading} />
                    }
                </FlexColumn>
            </Skeleton >
        )
    }

    const downloadDocument = (url, name) => {
        getPreSignedS3Url({ url: url }, (err, preSignedUrl) => {
            saveAs(preSignedUrl, name)
        })
    }

    var pendingColumns = []

    pendingColumns.push({
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        align: "left",
        render: (date, record) => {
            if (record.sectionHeader === true) {
                return { props: { colSpan: 0 } }
            } else {
                return date
            }
        },
        width: 140,
    })

    if (!hideType) {
        pendingColumns.push({
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            align: "center",
            render: (type, record) => {
                if (record.sectionHeader === true) {
                    return { props: { colSpan: 0 } }
                } else {
                    var displayType = type
                    if (type === 'Ach') displayType = 'ACH'
                    if (type === 'CardManagement' || type === 'CardMemo') displayType = 'Card'
                    if (type === 'Wires') displayType = 'Wire'
                    if (type === 'Checks') displayType = 'Check'
                    if (type === 'Internal') {
                        displayType = record.subRail
                            ? ((record.subRail || '').includes('Instant') ? 'Internal' : record.subRail)
                            : 'Internal';
                    }
                    // if (record.subRail && (record.subRail || '').includes('Instant')) displayType = 'Instant'
                    return <Tag>{displayType}</Tag>
                }
            },
            width: 52,
        })
    }

    if (!hideCardInfo) {
        pendingColumns.push({
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            primaryCol: true,
            render: (desc, record) => {
                if (record.sectionHeader === true) {
                    return {
                        children: <Flex start fullWidth><Text size='14px' color={theme.colors.lightGrey}>{record.date}</Text></Flex>,
                        props: { colSpan },
                    }
                } else {
                    var description = desc

                    switch (record.type) {
                        case 'ACH':
                        case 'Wire':
                            description = record.displayDescription ? record.displayDescription : `$${toCurrency(record.amount)} ${record.transactionType === 'credit' ? 'from' : 'to'} ${record.recipient}`
                            break
                        case 'Check':
                            description = description || `CHECK DEPOSIT REF: ${record.referenceId}`
                            break
                        case 'Internal':
                            if (record.displayDescription) {
                                description = record.displayDescription
                            } else {
                                console.log("Internal", accountMap)
                                var toAccount = accountMap[record.toAccountId]
                                var fromAccount = accountMap[record.fromAccountId]
                                var toAccName = toAccount ? `${(toAccount.nickName ? `${toAccount.nickName} - ${toAccount.mask}` : `Business ${toAccount.accountSubType} - ${toAccount.mask}`)}` : ''
                                var fromAccName = fromAccount ? (fromAccount.mask ? `${(fromAccount.nickName ? `${fromAccount.nickName} - ${fromAccount.mask}` : `Business ${fromAccount.accountSubType} - ${fromAccount.mask}`)}` : null) : ''
                                description = `$${toCurrency(record.amount)} from ${fromAccName} to ${toAccName}`
                            }
                            break
                    }

                    return <Text>{description}</Text>
                }
            },
        })
    }

    if (hideCardInfo) {
        pendingColumns.push({
            title: 'Merchant & Location',
            dataIndex: 'merchantName',
            key: 'merchantName',
            render: (merchantName, record) => {
                if (record.sectionHeader === true) {
                    return {
                        children: <Flex start fullWidth><Text size='14px' color={theme.colors.lightGrey}>{moment.utc(record.date).format(dFormat)}</Text></Flex>,
                        props: { colSpan },
                    }
                } else {
                    return <Text>{merchantName}</Text>
                }
            },
        })

        // pendingColumns.push({
        //     title: 'Category',
        //     dataIndex: 'merchantCategory',
        //     key: 'merchantCategory',
        // })
    }

    pendingColumns.push({
        title: 'Amount',
        dataIndex: 'amount',
        key: 'Amount',
        align: "right",
        render: (amount, record) => {
            const { wasReturned, wasReversed, paymentType } = record
            if (record.sectionHeader === true) {
                return { props: { colSpan: 0 } }
            } else {
                return <Flex end centerHorizontally gap='24px'>
                    {/* {
                        (["Incoming ACH Return", "Reversal"].includes(record.transactionCode)) &&
                        <Image src={Return} />
                    } */}
                    {
                        (wasReturned || wasReversed || paymentType === 'Return') &&
                        <Image src={Return} />
                    }

                    {
                        (() => {
                            switch (record.transactionType) {
                                case 'credit':
                                    return <Text noWrap weight='600' credit>+ {formatter.format(amount)}</Text>
                                case 'debit':
                                    return <Text noWrap weight='600' color={theme.colors.defaultLightText}>- {formatter.format(Math.abs(amount))}</Text>
                                default:
                                    return <Text noWrap weight='600' color={theme.colors.defaultLightText}>{formatter.format(Math.abs(amount))}</Text>
                            }
                        })()
                    }
                </Flex>
            }
        },
        width: 140,
    },)

    if (showAccount) {
        pendingColumns.push({
            title: 'Account',
            dataIndex: 'accountId',
            key: 'Account',
            align: "right",
            render: (accountId, record) => {
                if (record.sectionHeader === true) {
                    return { props: { colSpan: 0 } }
                } else {
                    return <Tag>{`${account.accountSubType} • ${account.mask}`}</Tag>
                }
            },
            width: 240,
        })
    }

    {
        (!more && !hideStatus) &&
            pendingColumns.push({
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                align: "right",
                render: (status, record) => {
                    if (record.sectionHeader === true) {
                        return { props: { colSpan: 0 } }
                    } else if (status) {
                        var displayStatus = status

                        switch ((status || '').toLowerCase()) {
                            case 'pending_approval':
                                displayStatus = 'Pending Approval'
                                break
                            case 'pending':
                                displayStatus = 'Processing'
                                break
                            case 'created':
                                displayStatus = 'Processing'
                                break
                            case 'hold':
                                displayStatus = 'In Review'
                                break
                            case 'complete':
                            case 'completed':
                                displayStatus = 'Sent'
                                break
                            case 'denied_approval':
                                displayStatus = 'Rejected'
                                break
                            default:
                                displayStatus = displayStatus.charAt(0).toUpperCase() + displayStatus.slice(1)
                        }

                        if (record.transactionCode === 'Reversal') displayStatus = "Reversal";

                        return <Tag primary={status === 'pending_approval' || (displayStatus || '').toLowerCase() === 'processing'}>{displayStatus}</Tag>
                    }
                },
                width: 200,
            })
    }

    const getRowDetail = (record) => {
        // if (record.approvalInstanceId) {
        //     getApprovalInstance(record)
        // } else {
        getObjectDetail(record)
        // }
    }

    const getDataWithDates = (data) => {
        var dataWithDates = []
        data.forEach((item, i) => {
            const iDate = i === 0 ? null : (data[i - 1].lastModifiedAt || data[i - 1].updatedAt || data[i - 1].createdAt)
            const itemDate = item.lastModifiedAt || item.updatedAt || item.createdAt

            /*
            ** Uncomment to get sectionHeader
            **
            if (i === 0 || (moment.utc(iDate).tz(etTimeZone).dayOfYear() !== moment.utc(itemDate).tz(etTimeZone).dayOfYear())) {
                dataWithDates.push({
                    objectId: 'sectionHeader',
                    date: moment.utc(itemDate).tz(etTimeZone).format(dFormat)
                })
            }
            */
            item['date'] = moment.utc(itemDate).tz(etTimeZone).format(dFormat)
            dataWithDates.push(item)
        })
        return dataWithDates
    }
    const rowKey = 'objectId'
    return (
        <FlexColumn start>
            <TableView
                id="pending-table"
                titleText={
                    <Flex start fullWidth gap='8px' centerHorizontally>
                        {
                            noTitle !== true &&
                            <Text heading height='20px'>{title ? title : 'Pending'}</Text>
                        }
                        {
                            tooltip &&
                            <Tooltip overlayInnerStyle={{ minWidth: 680 }} overlayStyle={{ minWidth: 700 }} overlayClassName='roles-tooltip' placement="right" arrowPointAtCenter={false} title={<FlexColumn>
                                <Text color='white' size='14px' noWrap>{tooltip}</Text>
                            </FlexColumn>}>
                                <FlexColumn>
                                    <Image src={Info} />
                                </FlexColumn>
                            </Tooltip>
                        }
                    </Flex>
                }
                descText={props.subtitle}
                loading={loadingPending}
                pagination={false}
                tableLayout='auto'
                columns={pendingColumns}
                dataSource={getDataWithDates(pendingTransfers || [])}
                rowKey={rowKey}
                ctaContent={
                    props.ctaContent ||
                    <Flex start gap='24px' centerHorizontally>
                        {
                            !loadingPending && pendingSums &&
                            <Flex gap="8px">
                                <Tag noWrap>${toCurrency(pendingSums.debitSum)} Out</Tag>
                                <Tag noWrap color='#318F2F' background='#F5F9F5'>${toCurrency(pendingSums.creditSum)} In</Tag>
                            </Flex>
                        }
                        {cta && cta}
                        {loadingPending && <LoadingOutlined style={{ color: props.theme.colors.primary }} />}
                    </Flex>
                }
                onRow={
                    (record, rowIndex) => {
                        return {
                            onClick: event => {
                                if (record[rowKey] == selectedRowId) {
                                    setSelectedRowId(null)
                                } else {
                                    getRowDetail(record)
                                    setSelectedRowId(record[rowKey])
                                }
                            }, // click row
                        }
                    }
                }
                expand
                expandedRowKey={selectedRowId}
                getRowDetails={(record) => getRowDetails(record)}
                canCollapse={props.canCollapse}
                isCollapsed={props.isCollapsed}
            />

            {
                moreTransactions &&
                <Flex end style={{ margin: 16 }} centerHorizontally gap='4px'>
                    <Text>{`You have ${totalTransactions} total pending transactions. View them all in your`}</Text>
                    <TextButton underline weight='400' text='Transfers' onClick={() => history.push('/banking/transfers')} />
                    <Text>page</Text>
                </Flex>
            }
        </FlexColumn>
    )
}

export default (withTheme(PendingTable))