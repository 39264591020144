import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { Steps } from 'antd';
import { Text, Tag } from './Text';
import { Flex, FlexColumn } from './Container';
import { Button, TextButton } from './Button';
import { ArrowLeftOutlined, ArrowRightOutlined, CheckOutlined } from '@ant-design/icons';

// Images
import ZeroProgress from '../../Images/zero-progress.png';
import HalfProgress from '../../Images/half-progress.png';
import DoneProgress from '../../Images/done-progress.png';
import hexToRgba from 'hex-to-rgba';

const { Step } = Steps;

const ProgressSteps = (props) => {
    const { onClick, steps, step, edit } = props

    console.log("ProgressSteps: ", step)

    useEffect(() => {

    }, [])

    return (
        <Flex start centerHorizontally style={{ height: 48, background: '#F6F6F6', width: '100%', marginBottom: 0 }}>
            {
                // steps && step < steps.length - 1 ?
                (steps || []).map((stepTitle, index) => {
                    if (edit) {
                        return <>
                            {
                                index > 0 &&
                                <div style={{ width: 48, height: 1, background: '#1199FF', margin: '0 4px' }} />
                            }
                            <Button weight='400' noClick={!onClick} noBorder={index !== step} shadow key={`progressButton-${index}`} onClick={() => onClick(index)} text={stepTitle} icon={<img width='24px' height='24px' src={DoneProgress} />} />
                        </>
                    } else {
                        return <>
                            {
                                index > 0 &&
                                <div style={{ width: 48, height: 1, background: step < index ? '#CCCCCC' : hexToRgba(props.theme.colors.primary2, 0.7), margin: '0 4px' }} />
                            }
                            {
                                index === step &&
                                <Button color={hexToRgba(props.theme.colors.primary2, 0.7)} weight='400' noClick shadow padding='0 16px' key={`progressButton-${index}`} text={stepTitle} icon={<img width='24px' height='24px' src={ZeroProgress} />} />
                            }

                            {
                                index < step &&
                                <Button color={hexToRgba(props.theme.colors.primary2, 0.8)} weight='400' noClick={!onClick} noBorder shadow key={`progressButton-${index}`} onClick={() => onClick(index)} text={stepTitle} icon={<img width='24px' height='24px' style={{opacity: '0.8'}} src={DoneProgress} />} />
                            }

                            {
                                index > step &&
                                <Button color={hexToRgba(props.theme.colors.primary2, 0.5)} weight='400' noClick noBorder shadow key={`progressButton-${index}`} text={stepTitle} />
                            }
                        </>
                    }
                })
                // :
                // <Button weight='600' noClick shadow padding='0 16px' key={`progressButton-${step}`} text={steps[step]} />
            }
        </Flex>
    )
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(ProgressSteps)));