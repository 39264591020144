import React from 'react'
import { Text } from './Text'
import { Image } from './Image'
import { FlexColumn } from './Container'
import { Button, TextButton } from './Button'
import Delete from '../../Images/delete-red-icon.png'

const DeleteModal = (props) => {
    const { title, description, buttonTitle, onConfirm, descWidth, deleteLoading, onClose } = props

    return (
        <FlexColumn center>
            <Image margin='24px 0 12px' width='48px' height='48px' src={Delete} />
            <Text size='28px' height='40px' weight='500' style={{ marginBottom: 24, marginTop: 8 }}>{title}</Text>
            <Text center margin='0 12px 24px' width={descWidth || '300px'}>{description}</Text>
            <Button
                solid
                text={buttonTitle}
                onClick={onConfirm}
                margin='0 0 24px'
                loading={deleteLoading}
            />
            <TextButton text='CANCEL' onClick={onClose} />
        </FlexColumn>
    )
}

export default DeleteModal