import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import { Skeleton, Divider, Space, Button, message, Modal } from 'antd';
import { startCase } from 'lodash';
import { Flex, FlexColumn, Container } from '../../Reusable/Container';
import PageHeader from '../../Reusable/PageHeader';
import BusinessDocuments from '../../Reusable/BusinessDocuments';
import { Title, Paragraph } from '../../Reusable/Text';
import { addressObjectToStr } from "../../../Utils/util";
import { StyledButton } from '../../Reusable/Button';
import { ErrorAlert } from '../../Reusable/Alert';
import { apiGET, apiPOSTReq } from '../../../Utils/api';
import environment from '../../../environment';
import { addDataToStore, BNK_SAVE_DATA } from '../../../Actions/actions';
import { LoadingOutlined } from '@ant-design/icons';

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    componentDidMount = () => {
        this.refreshAppData();
        this.fetchBusinessData();
    }

    approveApp = () => {
        this.setState({ submitLoading: true });
        var body = {};
        apiPOSTReq(`${environment.uamBaseUrl}/bp/approveSignUpReview`, {}, body, (err, resp) => {
            this.setState({ submitLoading: false });
            try {
                var data = resp || {};
                console.log("/bp/approveSignUpReview", err, resp, body);
                this.setState({ submitLoading: false });
                if (data.result) {
                    message.success('Application submitted to the bank successfully!');
                    this.refreshAppData(true);
                } else {
                    throw Error(data.error || data.responseMessage);
                }
            } catch (error) {
                console.log("/application err", error, err, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    rejectApp = () => {
        this.setState({ submitLoading: true });
        apiPOSTReq(`${environment.uamBaseUrl}/bp/rejectSignUpReview`, {}, {}, (err, resp) => {
            this.setState({ submitLoading: false });
            try {
                var data = resp || {};
                console.log("/uam/rejectSignUpReview", err, resp);
                this.setState({ submitLoading: false });
                if (data.result) {
                    message.success('Application rejected successfully!');
                    this.refreshAppData(true);
                } else {
                    throw Error(data.error || data.responseMessage);
                }
            } catch (error) {
                console.log("/application err", error, err, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    refreshAppData = (reload) => {
        this.setState({ submitLoading: true });
        apiGET("/banking/radius/dashboard", null, (err, resp) => {
            try {
                if (err) throw new Error(err);
                this.props.dispatch(addDataToStore(BNK_SAVE_DATA, resp.data));
                if (reload) window.location.reload();
                else this.setState({ submitLoading : false });
            } catch (error) {
                console.log("refreshAppData", error.message);
            }
        });
    }

    fetchBusinessData = (options) => {
        const { aionStore } = this.props;
        var { Profile } = aionStore;
        var { BusinessInfo } = Profile || {};
        var body = {
            "clientBusinessId": aionStore.BusinessUniqueKey
        };
        console.log("/business/admin/getBusinessData body", body);
        this.setState({ loading: true });
        apiPOSTReq(`${environment.uamBaseUrl}/business/admin/getBusinessData`, null, body, (err, resp) => {
            this.setState({ loading: false });
            try {
                const data = resp || {};
                console.log("/business/admin/getBusinessData data", data);
                if (data.result) {
                    this.setState({
                        reviewComments: (data.business || {}).reviewComments,
                        signupStatus: (data.business || {}).signupStatus,
                        signupStatusMsg: (data.business || {}).signupStatusMsg
                    });
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }
    render() {
        const { loading, reviewComments, submitLoading, pagination, sorter, selectedRowKeys } = this.state;
        const BBAccounts = this.props.bankingStore.BBAccounts;
        const { aionStore, bankingStore, theme } = this.props;
        var { BankingAttributes } = aionStore.Attributes || {};
        var { Applications } = bankingStore || {};
        Applications = Applications || [];
        var { ManualReviewRequired, ApplicationApproved } = BankingAttributes || {};
        var appStatus = ""
        var appStatusMsg = "";
        var appObj = {};
        console.log("Applications aionStore", aionStore)
        if (Applications.length > 0) {
            appObj = Applications[Applications.length - 1] || {};
            appStatusMsg = Applications[Applications.length - 1].StatusMsg;
            switch (Applications[Applications.length - 1].Status) {
                case "processing":
                    appStatus = "Bank - In Processing"
                    break;
                case "manual_review":
                    appStatus = "In Manual Review"
                    break;
                case "obp_manual_review":
                    appStatus = "In Alloy Manual Review"
                    break;
                default:
                    appStatus = Applications[Applications.length - 1].Status;
                    break;
            }
        }

        return (
            <>
                <FlexColumn style={{ margin: "10px 70px 50px" }}>
                    <Skeleton loading={loading} active title={true} paragraph={{ rows: 5 }}>
                        <PageHeader
                            titleText={"Banking Application"}
                        />
                        <Container shadow style={{ margin: '24px 0' }}>
                        <BusinessDocuments showUploadedOnly={true} />
                        {
                            (appObj.Status == "manual_review") ?
                                <>
                                    <Paragraph bold size="1.8rem" color={theme.colors.secondary8} style={{ marginTop: "40px" }} noMargin>{"Application Status: Aion - In Manual Review"}</Paragraph>
                                    {
                                        reviewComments &&
                                        <>
                                            <Paragraph noMargin bold level={5} style={{ marginTop: 40, marginBottom: 12 }}>Review comments:</Paragraph>
                                            <ol>
                                                {
                                                    reviewComments.map(comment => <li>{comment}</li>)
                                                }
                                            </ol>
                                        </>
                                    }
                                    {
                                        submitLoading ?
                                            <Flex><LoadingOutlined style={{ fontSize: 35 }} spin /></Flex>
                                            :
                                            <>
                                                <Paragraph bold level={5} style={{ marginTop: "40px" }}>Submit to bank or reject application</Paragraph>
                                                <Space>
                                                    <Button type="primary" onClick={() => this.approveApp()}>Submit to bank</Button>
                                                    <Button onClick={() => this.rejectApp()}>Reject</Button>
                                                </Space>
                                            </>

                                    }
                                </>
                                :
                                <>
                                    <Paragraph bold size="1.8rem" color={theme.colors.secondary8} style={{ marginTop: "40px" }} noMargin>Application Status: {appStatus}</Paragraph>
                                    <Paragraph color={theme.colors.secondary7} noMargin>Application Status: {appStatusMsg}</Paragraph>
                                    <Paragraph bold size="1.3rem" color={theme.colors.secondary6}>Application ID: {Applications.length > 0 ? (Applications[Applications.length - 1] || {}).ApplicationId : "NA"}</Paragraph>
                                </>
                        }
                        </Container>
                    </Skeleton>
                </FlexColumn>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer,
        bankingStore: state.bankingAppReducer,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Index));