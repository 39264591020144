import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { saveAs } from 'file-saver'

import { apiPOST, apiPOSTReq, getPreSignedS3Url } from '../../../Utils/api';
import { addDataToStore, CREDIT_SAVE_DATA, REMOTE_SAVE_DATA, BUSINESS_INFO } from '../../../Actions/actions';
import environment from '../../../environment';
import { PlaidLink } from "react-plaid-link";
import AccountingSysConnect from '../../Reusable/AccountingSysConnect';
import { FlexColumn, Flex } from '../../Reusable/Container';
import FlowViewComponent from '../../Reusable/Refresh/FlowViewComponent';
import { LightContainer } from '../../Reusable/Container';
import { LabeledInput } from '../../Reusable/Input';
import { Text } from '../../Reusable/Text';
import { Button, TextButton, ImageButton } from '../../Reusable/Button';
import { Image } from '../../Reusable/Image';
import { ErrorAlert } from '../../Reusable/Alert';
import AlertModal from '../../Reusable/AlertModal'
import { message, Divider, Modal, Upload, Table, Tooltip, Skeleton, Space, Popconfirm, Badge } from 'antd';
import { FilePdfOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import File from '../../../Images/file.svg'
import Close from '../../../Images/close-primary.svg'
import BankIcon from '../../../Images/bank-generic.png';
import Connection from '../../../Images/connection.png';
import ConnectionGrey from '../../../Images/connect-grey.svg';
import ModalClose from '../../../Images/modal-close.png';
import infoRed from '../../../Images/info-red.png';
import Info from '../../Reusable/Info';
import InfoIcon from '../../../Images/info.png';
import Missing from '../../../Images/missing.png'
import DownloadIcon from '../../../Images/download.png';
import Asterisk from '../../../Images/asterisk.png';
import { StyledExtLink } from '../../Reusable//Link'
import moment from 'moment';
import { capitalize } from 'lodash';
import YodleeIntegration from '../../Settings/External Bank Connect/YodleeIntegration';

var businessDocs = [
    { name: "2022 Tax Returns" },
    { name: "2021 Tax Returns" },
    { name: "BankStatement" }
];

var preScreenDocs = [
    { name: "Company-prepared balance sheet — most recent closed month" },
    { name: "Company-prepared balance sheet — most recent fiscal year end" },
    { name: "Company-prepared profit & loss statement — YTD through most recent closed month" },
    { name: "Company-prepared profit & loss statement — full year for most recent fiscal year end" },
    { name: "AR Aging Report" }
];

var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
});

class ConnectionInfo extends Component {
    constructor(props) {
        super(props)
        const { loanApplication } = props.creditStore;
        const { referralCode } = props.aionStore;
        this.state = {
            submitLoading: false,
            submitPlaidLoading: false,
            loading: false,
            valid: false,
            uploadList: [],
            loadingBanks: false,
            showBankDetails: false,
            reconnectBank: false,
            yodleeLink: false,
            showManualDocUploadReason: true,
            showManualBankUploadReason: true,
            loanApplication: loanApplication,
            referralCode: referralCode,
            linkToken: ''
        }
    }

    componentDidMount() {
        this.fetchBanks();
        this.fetchDocuments();
        this.getPlaidLinkToken();
    }

    fetchBanks = () => {
        const headers = {}

        const body = {
            "BusinessId": this.props.aionStore.businessId
        }
        this.setState({ loadingBanks: true })
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/plaid/getInstitutionInfo`, headers, body, (err, resp) => {
            try {
                const data = resp;
                this.props.dispatch(addDataToStore(CREDIT_SAVE_DATA, data))
                console.log("fetchBankInfo", data.externalBBInstitutions)
                this.setState({ banks: data.externalBBInstitutions })
            } catch (error) {
                console.log("ERROR", error.stack)
            } finally {
                this.setState({ loadingBanks: false })
            }
        })
    }

    getPlaidLinkToken = () => {
        var header = {}
        var body = {
            "redirectUri": window.location.href,
            "BusinessId": this.props.aionStore.businessId,
        }

        apiPOSTReq(`${environment.integrationBaseUrl}/integration/plaid/getLinkToken`, header, body, (err, resp) => {
            try {
                const response = resp || {};
                if (response.result) {
                    this.setState({ linkToken: response.linkToken })
                } else {
                    ErrorAlert({ description: response.responseMessage });
                }
            }
            catch (err) {
            }
        })
    }

    fetchDocuments = () => {
        const { loanApplication } = this.props.creditStore;
        var body = {
            "sortDirection": "DESC"
        };
        if (loanApplication) {
            body["clientBusinessId"] = loanApplication.businessId;
            body["applicationId"] = loanApplication.applicationId;
            body["viewBy"] = "FILTER_APPLICATION"
        }
        console.log("/docs/getActiveDocuments body", body, loanApplication);
        apiPOSTReq(`${environment.uamBaseUrl}/docs/getActiveDocuments`, {}, body, (err, resp) => {
            console.log("/docs/getActiveDocuments resp", resp);
            this.setState({ submitLoading: false });

            try {
                const data = resp || {};
                if (data.result) {
                    this.setState({
                        businessDocuments: data.businessDocuments
                    }, () => {
                        const { referralCode } = this.props.aionStore;
                        if (referralCode) {
                            const manualUpload = preScreenDocs.find(item => {
                                if (!this.getDocument(item.name).fileName && item.name != 'AR Aging Report') {
                                    return true;
                                }
                            })

                            if (!manualUpload) {
                                this.setState({ allDocsUploaded: true });
                                setTimeout(() => {
                                    this.setState({ showUploadBankDocsManually: true });
                                }, 30000)
                            }
                            else this.setState({ showUploadBankDocsManually: false, allDocsUploaded: false });
                        }
                    });

                    if (data.businessDocuments && data.businessDocuments.length > 0) {
                        var prescreenDocs = data.businessDocuments.filter(
                            doc => {
                                if (doc.category == 'PreScreen') return doc;
                            }
                        )

                        if (prescreenDocs && prescreenDocs.length > 0 && !prescreenDocs[0].documentUrl.fileName.includes('QBO_Generated')) {
                            this.saveUserInput('uploadMethod', "Upload Manually")
                        }
                    }
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    setErrorField = (id, msg) => this.setState({ errorField: id, errorMessage: msg });

    handleDeleteDoc = (uploadItem) => {
        var { loanApplication } = this.props;
        var businessDocument = {
            "name": uploadItem.name
        };

        if (loanApplication) businessDocument.applicationId = loanApplication.applicationId;
        var body = {
            "businessDocument": businessDocument,
            "documentId": uploadItem.documentId
        }

        console.log("handleSubmit DOC/delete uploadItem body", body);
        apiPOSTReq(`${environment.uamBaseUrl}/docs/delete`, {}, body, (err, resp) => {
            this.setState({ loadingItem: null });
            try {
                const data = resp || {};
                if (data.result) {
                    message.success(`File Deleted successfully.`, 0.75);
                    this.fetchDocuments();
                    let doc = this.state[uploadItem.name] || {};
                    doc.savedFile = {};
                    this.setState({ [doc.name]: doc });

                    // multiple doc delete and updated in the filelist
                    let removedList = this.state.uploadList.filter(item => {
                        if (item.documentId !== uploadItem.documentId) return item
                    });
                    this.setState({ uploadList: removedList });
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/docs/deleteUpload err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    handleDocSubmit = (uploadItem, uploadCategory) => {
        var { loadingItem } = uploadItem || {};
        var { loanApplication } = this.props.creditStore;
        var businessDocument = {
            "documentUrl": uploadItem.savedFile || {},
            "name": uploadItem.name
        };
        if (uploadItem.name === "BankStatement") {
            businessDocument.category = "DueDiligence"
            businessDocument.type = uploadItem.name
        } else {
            businessDocument.category = "Business Finanicals"
        }
        if (loanApplication) businessDocument.applicationId = loanApplication.applicationId;
        if (uploadItem.effectivePeriod) businessDocument.effectivePeriod = uploadItem.effectivePeriod
        var body = {
            "businessDocument": businessDocument
        }
        console.log("handleSubmit DOC/SAVE uploadItem body", uploadItem, body);
        apiPOSTReq(`${environment.uamBaseUrl}/docs/save`, {}, body, (err, resp) => {
            this.setState({ uploadLoading: false, uploadCategory: uploadCategory });
            try {
                const data = resp || {};
                var uploadList = this.state.uploadList;
                if (data.result) {
                    message.success(`File saved successfully.`, 0.75);
                    uploadList.push(data.businessDocument);
                    this.setState({ uploadList: uploadList })

                    this.fetchDocuments();
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/docs/save err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    onUploadBankStatementSubmit = () => {
        var { uploadList, bankName } = this.state;

        if (!bankName) {
            ErrorAlert({ description: "Please enter bank name." });
            return false;
        }
        if (uploadList.length === 0) {
            ErrorAlert({ description: "Please upload bank statement." });
            return false;
        }
        var valid = this.validateUpload({});

        if (valid) {
            var docIds = [];

            uploadList.map(doc => {
                docIds.push(doc.documentId);
            })
            const { bankName } = this.state;
            var body = {
                "bankName": bankName,
                "documentIds": docIds
            }
            console.log("handleSubmit DOC/SAVE uploadItem body", body);
            apiPOSTReq(`${environment.uamBaseUrl}/docs/uploadBankStatements`, {}, body, (err, resp) => {
                this.setState({ loadingItem: null });
                try {
                    const data = resp || {};
                    if (data.result) {
                        message.success(`Uploaded successfully.`, 0.75);
                        this.setState({
                            showManualUploadModal: false,
                            uploadList: [], bankName: ""
                        })
                        this.fetchDocuments();

                    } else {
                        throw Error(data.responseMessage || data.error)
                    }
                } catch (error) {
                    console.log("/docs/save err", error, resp);
                    ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
                }
            });
        }
    }

    validateUpload = (uploadedDoc) => {

        var { businessDocuments, bankName, uploadList } = this.state;
        var filteredDocs = [];
        var duplicateDocs = [];
        var alreadySavedDocs = [];

        if (!bankName) {
            ErrorAlert({ description: "Please enter bank name." });
            return false;
        }
        if (Object.keys(uploadedDoc).length > 0) {
            duplicateDocs = uploadList.filter(file => file.documentUrl.fileName === uploadedDoc.name)
            alreadySavedDocs = businessDocuments.filter(doc => (doc.bankName === bankName) && (doc.documentUrl.fileName === uploadedDoc.name))
        }
        if (duplicateDocs.length > 0 || alreadySavedDocs.length > 0) {
            ErrorAlert({ description: `A file with this name already exists: ${uploadedDoc.name}. Please review and upload the correct document.` });
            return false;
        }

        filteredDocs = businessDocuments.filter(doc => (doc.bankName || '').toLowerCase() === bankName.toLowerCase())

        if ((filteredDocs.length + uploadList.length) > (Object.keys(uploadedDoc).length > 0 ? 14 : 15)) {
            ErrorAlert({ description: "To add more bank statements, please remove one of your files and upload a zip (compress) of the remaining statements." });
            return false;
        }
        else {
            return true;
        }
    }

    uploadProps = (doc, uploadCategory) => {
        return {
            name: `business-docs`,
            action: `${environment.apiBaseUrl}/file/upload`,
            headers: {
                businesskey: this.props.aionStore.BusinessUniqueKey,
                jwt: this.props.aionStore.jwt
            },
            beforeUpload: (doc) => {
                var { valid } = this.state;
                this.setState({ valid: uploadCategory === "BankStatement" ? this.validateUpload(doc) : valid })
                const isPDF = doc.type === 'application/pdf';
                const isXLSX = doc.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                const isZip = doc.type === 'application/zip';

                if ((uploadCategory === "BankStatement") && !isPDF && !isZip) {
                    message.error("Uploaded document is not a pdf/zip file");
                    return isPDF || Upload.LIST_IGNORE;
                } else if ((uploadCategory != "BankStatement") && !isXLSX && !isPDF) {
                    message.error("Uploaded document is not a pdf/xlsx file");
                    return isPDF || isXLSX || Upload.LIST_IGNORE;
                }

                var maxFileSize = 10240; // 10MB

                const uploadedFileSizeKiloBytes = doc.size / 1024

                if (uploadedFileSizeKiloBytes > maxFileSize) {
                    message.error(`File size is greater than 10MB`);
                    this.setState({ uploadLoading: false, uploadingItem: uploadCategory });

                    return false;
                }
            },
            showUploadList: false,
            onChange: (info) => {
                const { valid } = this.state;
                if ((uploadCategory === "BankStatement" && valid) || uploadCategory != "BankStatement") {
                    {
                        this.setState({ uploadLoading: true, uploadCategory: uploadCategory });
                        var savedFile = {};
                        var file = info.file;
                        var { status, response, name } = file;
                        if (status === "done") {
                            savedFile = {
                                fileName: name,
                                uri: (response.UploadedUrls || [])[0],
                            }
                            if (doc.type) savedFile.type = doc.type;
                            doc.savedFile = savedFile;
                            this.handleDocSubmit(doc);

                        } else if (status === 'error') {
                            this.setState({ uploadLoading: false });
                            message.error(`${info.file.name} file upload failed.`);
                        }


                        if (!status) {
                            this.setState({ uploadLoading: false });
                        }
                        if (((Object.keys(savedFile)).length) !== 0) {
                            doc.savedFile = savedFile;
                        }
                        this.setState({ [doc.name]: doc });
                    }
                }
            },
            accept: ".pdf, .xlsx,.zip",
            onRemove: () => {
                this.handleDeleteDoc(doc);
            }
        }
    };

    onClickManualUploadModal = () => {
        this.setState({ showManualUploadModal: true, uploadList: [], bankName: "" });
    }

    onClickConnectionErrorModal = () => {
        this.setState({ showConnectionErrorModal: true });
    }

    getPlaidAccessToken = (public_token, metadata, includeAuth, accessToken) => {
        const { loanApplication } = this.props.creditStore;
        var header = {}
        var body = {
            "redirectUri": window.location.href,
            "plaidTokenData": {
                publicToken: public_token,
                accountId: metadata.account.id,
                accountName: metadata.account.name,
                institution: metadata.institution.name,
                institutionId: metadata.institution.institution_id,
            },
            "loggedInUserId": this.props.aionStore.UserInfo.Email,
            "BusinessId": this.props.aionStore.businessId,
            "includeAuth": includeAuth,
            "accessToken": accessToken,
            "LoanApplicationId": loanApplication.applicationId

        }

        this.setState({ loadingBanks: true })
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/plaid/getAccessToken`, header, body, (err, resp) => {
            try {
                const response = resp || {};
                if (response.result) {
                    this.fetchBanks()
                    this.setState({ showBankDetails: false, hideError: true })
                } else {
                    ErrorAlert({ description: response.responseMessage });
                    this.setState({ loadingBanks: false, hideError: false })
                }
            }
            catch (err) {

            }

        })

    }

    logPlaidEvent = (eventName, metadata) => {
        apiPOST("/logError", {}, { logType: "PLAID_DESKTOP", eventName: eventName, metadata: metadata }, (err, resp) => {
            console.log("Plaid event logged", err, resp);
        });
    }

    handleBankDetails = (bankInfo) => {
        if (bankInfo.integrationProvider == "Plaid") {
            const headers = {}

            const body = {
                "itemId": bankInfo.itemId,
                "redirectUri": window.location.href,
                "connectionStatus": bankInfo.status

            }

            console.log("Metabody:", body)
            apiPOSTReq(`${environment.integrationBaseUrl}/integration/plaid/getAccountDetails`, headers, body, (err, resp) => {
                try {
                    const response = resp || {};
                    if (response.result) {
                        this.setState({
                            accountDetails: response.accountsList, showBankDetails: true, bankStatus: bankInfo.status,
                            bankUrl: bankInfo.logoUrl, bankName: bankInfo.bankName, bankLastUpdated: bankInfo.lastUpdatedAt, relinkToken: response.linkToken,
                            bankAccessToken: bankInfo.accessToken, reconnectBank: false, itemId: bankInfo.itemId
                        })
                        if (bankInfo.status != "Connected") {
                            this.setState({ reconnectBank: true })
                        }
                    } else {
                        ErrorAlert({ description: response.responseMessage });
                    }
                }
                catch (err) {

                }

            })
        }
        else {
            const headers = {}

            const body = {
                "providerAccountId": bankInfo.providerAccountId,

            }

            console.log("Metabody:", bankInfo)
            apiPOSTReq(`${environment.integrationBaseUrl}/integration/yodlee/getAccountDetails`, headers, body, (err, resp) => {
                try {
                    const response = resp || {};
                    if (response.result) {
                        this.setState({
                            accountDetails: response.accountsList, showBankDetails: true, bankStatus: bankInfo.status,
                            bankUrl: bankInfo.logoUrl, bankName: bankInfo.bankName, bankLastUpdated: bankInfo.lastUpdatedAt, relinkToken: response.linkToken,
                            bankAccessToken: bankInfo.accessToken, reconnectBank: false, itemId: bankInfo.itemId
                        })
                        if (bankInfo.status != "Connected") {
                            this.setState({ reconnectBank: true })
                        }
                    } else {
                        ErrorAlert({ description: response.responseMessage });
                    }
                }
                catch (err) {

                }

            })

        }
    }

    handleChange = (event) => {
        this.setState({ [event.target.id]: event.target.value });
    }

    closeBankDetails = () => {
        this.setState({ showBankDetails: false })
    }



    validateFlow = () => {
        var { onboardingStore, flowView } = this.props;
        var { closeBooksDate, fiscalYearStart, fiscalYearEnd } = onboardingStore.businessInfo;
        const { accountingSysConnected, businessDocuments, banks } = this.state;

        // this.setState({ submitLoading: true })

        if (!closeBooksDate) {
            this.setState({ errorField: "closeBooksDate", errorMessage: 'Please select a closing date', submitLoading: false })
            return
        }

        if (!fiscalYearEnd) {
            this.setState({ errorField: "fiscalYearEnd", errorMessage: 'Please select fiscal year end', submitLoading: false })
            return
        }

        if (moment.utc(closeBooksDate).isBefore(moment.utc(fiscalYearStart).startOf('month'))) {
            this.setState({ errorField: "closeBooksDate", errorMessage: 'Closing date cannot be lesser than start of fiscal year', submitLoading: false })
            return
        }

        const manualUpload = preScreenDocs.find(item => {
            if (!this.getDocument(item.name).fileName && item.name != 'AR Aging Report') {
                return true;
            }
        })

        console.log("accountingSysConnected validation", accountingSysConnected, manualUpload, !(accountingSysConnected || (manualUpload == undefined)))
        if (!(accountingSysConnected || (manualUpload == undefined))) {
            this.setState({ submitLoading: false });
            ErrorAlert({ title: "Incomplete Document Upload", description: "Please connect to an accounting system or upload the requested financial statements to submit your application.", submitLoading: false });
            return;
        }

        var filteredBanks = businessDocuments.filter(item => item.category === "DueDiligence");
        if ((banks && banks.length == 0) && filteredBanks.length == 0) {
            this.setState({ submitLoading: false });
            ErrorAlert({ title: "Banking Error", description: "Please connect to an banking system or upload the requested financial statements to submit your application.", submitLoading: false });
            return;
        }

        this.setState({ showConnectedModal: true })
    }

    // Accounting Software Code Start
    onClickPreScreenManualUploadModal = () => {
        var { onboardingStore } = this.props;
        var businessInfo = onboardingStore.businessInfo || {};

        if (!businessInfo.closeBooksDate) {
            this.setState({ errorField: "closeBooksDate", errorMessage: 'Please select a closing date' })
            return
        } else if (!businessInfo.fiscalYearEnd) {
            this.setState({ errorField: "fiscalYearEnd", errorMessage: 'Please select fiscal year end' })
            return
        }

        this.setState({ showPreScreenManualUploadModal: true, showManualUpload: false, errorField: '', errorMessage: '' });
    }

    uploadAccProps = (doc, index) => {
        return {
            name: `business-docs`,
            action: `${environment.apiBaseUrl}/file/upload`,
            headers: {
                businesskey: this.props.aionStore.BusinessUniqueKey,
                jwt: this.props.aionStore.jwt
            },
            showUploadList: false,
            beforeUpload: (doc) => {
                var { onboardingStore } = this.props
                var businessInfo = onboardingStore.businessInfo || {}

                if (!businessInfo.closeBooksDate) {
                    ErrorAlert({ description: "Please select last closed books date." });
                    return Upload.LIST_IGNORE;
                }

                if (!businessInfo.fiscalYearEnd) {
                    ErrorAlert({ description: "Please select most recent fiscal year end." });
                    return Upload.LIST_IGNORE;
                }

                const isPDF = doc.type === 'application/pdf';

                const isXLSX = doc.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                if (!isXLSX && !isPDF) {
                    message.error(`${doc.name} is not a pdf file`);
                    return isPDF || isXLSX || Upload.LIST_IGNORE;
                }

                var maxFileSize = 10240; // 10MB

                const uploadedFileSizeKiloBytes = doc.size / 1024

                if (uploadedFileSizeKiloBytes > maxFileSize) {
                    message.error(`File size is greater than 10MB`);
                    this.setState({ uploadLoading: false, uploadingItem: "uploading" + index });

                    return false;
                }
            },
            onChange: (info) => {
                var { fileList } = info;
                var savedFile = {};

                fileList.forEach(file => {
                    this.setState({ uploadLoading: true, uploadingItem: "uploading" + index });
                    const { status, response, name } = file;
                    if (status === "done") {
                        savedFile = {
                            fileName: name,
                            uri: (response.UploadedUrls || [])[0],
                        }
                        if (doc.type) savedFile.type = doc.type;
                        // message.success(`${info.file.name} file uploaded successfully.`, 0.75);
                        doc.savedFile = savedFile;
                        this.handleAccDocSubmit(doc, index);
                    } else if (status === 'error') {
                        this.setState({ uploadLoading: false });
                        message.error(`${info.file.name} file upload failed.`);
                    }

                    if (!status) {
                        this.setState({ uploadLoading: false });
                    }
                });
                if (((Object.keys(savedFile)).length) !== 0) {
                    doc.savedFile = savedFile;
                }
                this.setState({ [doc.name]: doc });
            },
            accept: ".pdf",
            onRemove: () => {
                this.handleDeleteDoc(doc);
            }
        }
    };

    handleAccDocSubmit = (uploadItem, index) => {
        var { loadingItem } = uploadItem || {}
        var { loanApplication, onboardingStore } = this.props
        var businessInfo = onboardingStore.businessInfo || {}
        var startDate = moment.utc(businessInfo.fiscalYearStart);
        var endDate = moment.utc(businessInfo.fiscalYearEnd);
        var businessDocument = {
            "name": uploadItem.name,
            "documentUrl": uploadItem.savedFile || {},
            "subType": 'BusinessFinancials',
            "source": 'User'
        };

        if (uploadItem.name != "AR Aging Report") {
            businessDocument.category = "PreScreen"
            businessDocument.closingDate = businessInfo.closeBooksDate
            businessDocument.fiscalFromMonth = startDate.format('MM')
            businessDocument.fiscalFromYear = startDate.format('YYYY')
            businessDocument.fiscalToMonth = endDate.format('MM')
            businessDocument.fiscalToYear = endDate.format('YYYY')
        }

        if (loanApplication) businessDocument.applicationId = loanApplication.applicationId;
        var body = {
            businessDocument: businessDocument
        }

        console.log("handleSubmit DOC/SAVE uploadItem body", body);
        //this.setState({ submitLoading: true, loadingItem: loadingItem });
        apiPOSTReq(`${environment.uamBaseUrl}/docs/save`, {}, body, (err, resp) => {
            this.setState({ uploadLoading: false, uploadingItem: "uploading" + index, loadingItem: null });
            try {
                const data = resp || {};
                if (data.result) {
                    message.success(`File saved successfully.`, 0.75);
                    var { businessDocuments } = this.state;
                    if (businessDocuments.length == 0) {
                        this.setState({ showPreScreenManualUploadModal: true, isDocUploadsActive: true })
                    }
                    this.fetchDocuments();
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/docs/save err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    handleAccDeleteDoc = (uploadItem) => {
        var { loanApplication } = this.props;
        var businessDocument = {
            "category": "PreScreen",
            "name": uploadItem.name
        };

        if (loanApplication) businessDocument.applicationId = loanApplication.applicationId;
        var body = {
            "businessDocument": businessDocument
        }

        console.log("handleSubmit DOC/delete uploadItem body", body);
        this.setState({ submitLoading: true });
        apiPOSTReq(`${environment.uamBaseUrl}/docs/delete`, {}, body, (err, resp) => {
            this.setState({ submitLoading: false, loadingItem: null });
            try {
                const data = resp || {};
                if (data.result) {
                    message.success(`File Deleted successfully.`, 0.75);
                    this.fetchDocuments();
                    let doc = this.state[uploadItem.name] || {};
                    doc.savedFile = {};
                    this.setState({ [doc.name]: doc });
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/docs/deleteUpload err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    handleFiscalYear = (id, dateStr) => {
        if (id == "fiscalYearStart") {
            var startDate = moment.utc(dateStr, "MMM-YYYY").startOf('month');
            this.saveUserInput("fiscalYearStart", startDate.format("YYYY-MM-DD"));
            this.saveUserInput("fiscalYearEnd", startDate.add(11, 'months').endOf('month').format("YYYY-MM-DD"));
        }
        else if (id == "fiscalYearEnd") {
            var endDate = moment.utc(dateStr, "MMM-YYYY").endOf('month');
            this.saveUserInput("fiscalYearEnd", endDate.format("YYYY-MM-DD"));
            this.saveUserInput("fiscalYearStart", endDate.add(-11, 'months').startOf('month').format("YYYY-MM-DD"));
        }
        var { flowView, isCreditPlus } = this.props;
        if (!isCreditPlus)
            this.props.dispatch(addDataToStore(REMOTE_SAVE_DATA, "Apply.Ownership")); // Apply.Ownership so that it does not take user to the next step on reload
    }

    handleDateChange = (id, dateStr) => {
        if (dateStr) {
            if (id == "dob") {
                this.saveUserInput(id, moment.utc(dateStr).startOf('day').toDate())
            } else {
                this.saveUserInput(id, moment.utc(dateStr).startOf('day').format("YYYY-MM-DD"))
            }
        } else {
            this.saveUserInput(id, null)
        }
        var { flowView, isCreditPlus } = this.props;
        if (!isCreditPlus)
            this.props.dispatch(addDataToStore(REMOTE_SAVE_DATA, "Apply.Ownership")); // Apply.Ownership so that it does not take user to the next step on reload
    }

    saveUserInput = (id, value) => {
        var dataToSave = {};
        switch (id) {
            default:
                dataToSave[id] = value;
                break;
        }
        console.log("saveUserInput", dataToSave)
        this.props.dispatch(addDataToStore(BUSINESS_INFO, dataToSave));
    }

    disabledDate = (current) => {
        return moment().subtract('1', "years") > current || current > moment();
    }

    disabledFiscalYearEndDate = (current) => {
        return current > moment();
    }

    getDocument = (name, options) => {
        options = options || {};
        var { businessDocuments } = this.state;
        var savedDoc = this.state[name] || {};
        var filteredDoc = (businessDocuments || []).find(item => (item.name == name));
        if (filteredDoc != undefined) {
            // Replace with remotely stored data
            if (options.businessDocument) savedDoc = filteredDoc;
            else savedDoc = filteredDoc.documentUrl || {};
        }
        return savedDoc;
    }

    downloadStatement = (url, name) => {
        getPreSignedS3Url({ url: url }, (err, preSignedUrl) => {
            saveAs(preSignedUrl, name)
        })
    }

    onUploadPreScreenSubmit = () => {
        if (preScreenDocs.find(item => {
            if (!this.getDocument(item.name).fileName && item.name != 'AR Aging Report') {
                return true;
            }
        })
        ) {
            ErrorAlert({ title: "Incomplete Document Upload", description: "Please make sure to provide the requested financial statements to submit your application." });
        }
        this.setState({ showPreScreenManualUploadModal: false, showManualUpload: true });
    }

    handleYodleeLink = () => {
        if (this.state.yodleeLink == true) {
            this.setState({ yodleeLink: false })
        }
        else {
            this.setState({ yodleeLink: true })
        }
    }

    handleTextChange = (event) => {
        this.setState({ [event.target.id]: event.target.value })
    }

    updateReason = (reasonType) => {
        const { reason, loanApplication } = this.state;
        const { creditStore } = this.props;
        var { aionStore } = this.props;
        var { BusinessUniqueKey } = aionStore;
        var body = {
            clientBusinessId: BusinessUniqueKey,
            loanApplication: Object.assign(loanApplication, { [reasonType]: reason })
        }

        this.setState({ docUploadReasonLoading: true })

        console.log("ConnectionInfo updateReason body: ", body)
        apiPOSTReq(`${environment.opsBaseUrl}/ops/loans/application/save`, {}, body, (err, resp) => {
            try {
                const data = resp || {};
                if (data.result) {
                    console.log("ConnectionInfo updateReason: ", data)
                    this.props.dispatch(addDataToStore(CREDIT_SAVE_DATA, { loanApplication: data.loanApplication }));
                    this.setState({
                        loanApplication: data.loanApplication,
                        showManualDocUploadReason: reasonType === '' && false,
                        showManualBankUploadReason: reasonType === '' && false,
                    })
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/updateUserStatus err", error, resp);
            } finally {
                this.setState({ docUploadReasonLoading: false })
            }
        });
    }

    render() {
        var { flowView, currentViewIndex, loadPrevView, onboardingStore, theme, reviewView, isCreditPlus } = this.props;
        var { businessInfo } = onboardingStore;
        var { submitPlaidLoading, errorField, errorMessage, uploadList, loadingBanks, showBankDetails, bankUrl, bankName, bankLastUpdated, allDocsUploaded,
            reconnectBank, showConnectedModal, loanApplication, referralCode, accountDetails, relinkToken, bankAccessToken, yodleeLink, accountingSysConnected,
            reason, showManualDocUploadReason, showManualBankUploadReason, docUploadReasonLoading, showUploadBankDocsManually, linkToken } = this.state;
        var bankInfos = this.props.creditStore.externalBBInstitutions || [];
        var businessDocuments = this.state.businessDocuments || [];

        const { arUploadReason, bbUploadReason } = loanApplication || {}

        var prescreenDocuments = (businessDocuments || []).filter(x => x.category == "PreScreen");

        console.log("ConnectionInfo updateReason loanApplication", loanApplication, referralCode)

        const AccountDetailColumns = [
            {
                title: 'Title',
                dataIndex: 'title',
                align: 'left'
            },
            {
                title: 'Account Ending',
                dataIndex: 'mask',
                align: 'left'
            },
            {
                title: 'Available Balance',
                dataIndex: 'availableBalance',
                align: 'left',
                render: (availableBalance, record) => {
                    return (formatter.format(availableBalance ? availableBalance : record.currentBalance))
                },
            }
        ]

        let connectedBanks = (bankInfos).map(bankInfo => {
            return (
                <LightContainer key={bankInfo.InstitutionId} padding='5px 0' margin='0 5px 5px 0' height='56px' noBorder='true'>
                    <Flex style={{ height: '100%' }} noMargin row between>
                        <FlexColumn start style={{ width: `75%`, flexDirection: "row" }} gap="10px" >
                            {
                                bankInfo.logoUrl && bankInfo.bankName &&
                                <Button type="primary" icon={<img style={{ marginRight: 8 }} height='24px' src={`${bankInfo.logoUrl}` || BankIcon} />} text={bankInfo.bankName} rightIcon={bankInfo.status == "Connected" ? <></> : <ExclamationCircleFilled style={{ color: 'orange' }} />} onClick={() => this.handleBankDetails(bankInfo)} />
                            }
                        </FlexColumn>
                    </Flex>
                </LightContainer>
            )
        });

        var filteredBanks = [];
        if (businessDocuments) {
            filteredBanks = businessDocuments.map(item => item.bankName)
                .filter((value, index, self) => {
                    return self.indexOf(value) === index;
                }
                )
        }

        let uploadedStatements =
            (filteredBanks).map(bank => {
                var bdocs = businessDocuments.filter(doc => doc.bankName === bank && bank != null);
                return (
                    <FlexColumn style={{ width: "100%", margin: "12px 0 0" }}>
                        <Text weight="400" color="#7384AA" style={{ fontSize: "14px", }} >{bank}</Text>
                        <Flex wrap start style={{ width: '100%', padding: "4px", marginTop: 8, gap: 18 }}>
                            {
                                bdocs.map(doc => {
                                    return (
                                        <TextButton icon={<Image src={File} />}
                                            rightIcon={reviewView ? null : <ImageButton src={Close} onClick={() => this.handleDeleteDoc(doc)} />}
                                            text={doc.displayName} ></TextButton>

                                    )
                                })
                            }
                        </Flex>
                    </FlexColumn>
                )
            });

        const accountingSection = (<>
            <FlexColumn start style={{ marginTop: "0", marginBottom: "12px" }}>
                <Text margin="0px 0px 12px 0px" weight='500' spacing="0.08em" caption>ACCOUNTING INFORMATION</Text>
                <Text width='800px' margin='0px 0px 12px 0px'>Help us understand your accounting cycle and financials</Text>
                <Flex start top gap='12px'>
                    <LabeledInput
                        labelcolor={this.props.theme.colors.secondary3}
                        label={<Flex between gap="35px"><Text color='#7384AA' size='14px' weight={400}>When did you last close your books?</Text>  <Tooltip title="Latest month end date when financial records were finalized and closed for a specific accounting period.">
                            <Image src={InfoIcon} />
                        </Tooltip></Flex>}
                        type="date-picker"
                        disabledDate={this.disabledDate} id="closeBooksDate"
                        format="ll"
                        width='295px'
                        onChange={(date, dateStr) => this.handleDateChange("closeBooksDate", dateStr)}
                        value={businessInfo.closeBooksDate && moment.utc(businessInfo.closeBooksDate)}
                        error={errorField == "closeBooksDate"}
                        errorMessage={errorMessage}
                        style={{ height: "32px" }}
                    />
                    <LabeledInput
                        labelcolor={this.props.theme.colors.secondary3}
                        label={<Flex between gap="38px"><Text color='#7384AA' size='14px' weight={400}>When did your most recent fiscal year end?</Text>  <Tooltip title={`Fiscal year end is the specific date  when your financial accounting period concludes. It signifies the end of a 12-month financial cycle.`}>
                            <Image src={InfoIcon} />
                        </Tooltip></Flex>}
                        type="date-picker"
                        picker="month"
                        id="fiscalYearEnd"
                        disabledDate={this.disabledFiscalYearEndDate}
                        onChange={(date, dateStr) => this.handleFiscalYear("fiscalYearEnd", dateStr)}
                        format="MMM-YYYY"
                        value={businessInfo.fiscalYearEnd && moment.utc(businessInfo.fiscalYearEnd)}
                        placeholder="Pick a Month"
                        width='340px'
                        style={{ height: "32px" }}
                        error={errorField == "fiscalYearEnd"}
                        errorMessage={errorMessage}
                    />
                </Flex>
                {
                    businessInfo.closeBooksDate && businessInfo.fiscalYearEnd &&
                    prescreenDocuments && prescreenDocuments.length == 0 &&
                    <FlexColumn start left style={{ margin: '16px 0' }} gap='8px'>
                        <Text margin='0px' color='#7384AA' weight={500}>{capitalize('CONNECT YOUR ACCOUNTING SYSTEM')}</Text>
                        <Text margin='0px 0 16px'>Connecting your accounting system enables instant offers on various financial products.</Text>
                        <AccountingSysConnect
                            location={this.props.location}
                            onAccountingSysConnected={(options) => {
                                console.log("onAccountingSysConnected", options)
                                this.setState({ accountingSysConnected: options.accountingSysConnected })
                                if (referralCode) {
                                    setTimeout(() => {
                                        this.setState({ showUploadBankDocsManually: true });
                                    }, 30000)
                                }
                            }}
                            showUploadOption
                            onClickManualUploadModal={() => this.onClickPreScreenManualUploadModal()}
                            isCreditPlus={isCreditPlus}
                        />
                    </FlexColumn>
                }
                <Modal
                    visible={this.state.showPreScreenManualUploadModal}
                    footer={null}
                    width={(!arUploadReason && showManualDocUploadReason) ? 620 : 1000}
                    destroyOnClose={true}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                    closable={true}
                    onCancel={() => {
                        this.setState({ showPreScreenManualUploadModal: false })
                    }}
                >
                    <FlexColumn start left>
                        {
                            (!arUploadReason && showManualDocUploadReason) ? <>
                                <Text heading>Tell us about why you weren’t able to connect</Text>

                                <LabeledInput
                                    label="Reason"
                                    id="reason"
                                    type="text-area"
                                    placeholder="Enter a reason (minimum of 50 characters)"
                                    maxLength={2000}
                                    width='576px'
                                    value={reason}
                                    autoSize={{ minRows: 2, maxRows: 4 }}
                                    onChange={this.handleTextChange}
                                    instruction={reason ? `${reason.length}/${50}` : `0/${50}`}
                                />

                                <Button loading={docUploadReasonLoading} disabled={!(reason && reason.length > 49)} text='NEXT' solid onClick={() => this.updateReason('arUploadReason')} margin='48px 0 0' />
                            </>
                                :
                                <>
                                    <Text weight="500" style={{ marginBottom: 5 }} size='20px'>UPLOAD FINANCIAL STATEMENTS</Text>
                                    <Text margin='4px 0 24px'>Download, fill and upload the PDF template provided for each of the requested periods.</Text>

                                    <>
                                        <Flex fullWidth between>
                                            <Text margin="0px 0px 12px 0px" weight='500' spacing="0.08em" caption>COMPANY PREPARED BALANCE SHEET</Text>
                                            <Text primary underline><a href="/Balance_Sheet_Template_v_2023-01.pdf" download="Balance_Sheet_Template_v_2023-01.pdf"><Image src={DownloadIcon} /> Download PDF Template</a></Text>
                                        </Flex>
                                        <Flex style={{ justifyContent: "between", width: "100%", borderRadius: "8px", padding: "8px 0px", marginTop: 4 }} gap='24px'>
                                            <FlexColumn start style={{ width: `${100 / 2}%` }}>
                                                {!this.getDocument(preScreenDocs[0].name).fileName ?
                                                    <LabeledInput
                                                        nomargin
                                                        {...this.uploadAccProps(preScreenDocs[0], 0)}
                                                        loading={this.state.uploadingItem === "uploading0" ? this.state.uploadLoading : false}
                                                        type='upload'
                                                        label='Most recent closed month'
                                                        uploadText='Only PDF file types accepted. File size cannot exceed 10 MB.'
                                                    /> : <>
                                                        <Flex start gap='4px'>
                                                            <Text weight="400" color="#7384AA" style={{ fontSize: "14px", }} margin='0 0 4px'>Most recent closed month</Text>
                                                            <Image width='5px' height='5px' margin='4px 0 0' src={Asterisk} />
                                                        </Flex>
                                                        <TextButton
                                                            preLine
                                                            style={{ textAlign: "left" }}
                                                            icon={<Image src={File} />}
                                                            onClick={() => this.downloadStatement(this.getDocument(preScreenDocs[0].name).uri, this.getDocument(preScreenDocs[0].name).fileName)}
                                                            text={this.getDocument(preScreenDocs[0].name).fileName}
                                                            deleteConfirmMessage={'Are you sure you want to delete this file?'}
                                                            onDelete={() => this.handleAccDeleteDoc(this.getDocument(preScreenDocs[0].name, { businessDocument: true }))} />
                                                    </>}
                                            </FlexColumn>
                                            <FlexColumn start style={{ width: `${100 / 2}%` }}>
                                                {!this.getDocument(preScreenDocs[1].name).fileName ?
                                                    <LabeledInput
                                                        nomargin
                                                        {...this.uploadAccProps(preScreenDocs[1], 1)}
                                                        loading={this.state.uploadingItem === "uploading1" ? this.state.uploadLoading : false}
                                                        type='upload'
                                                        label='Most recent fiscal year end'
                                                        uploadText='Only PDF file types accepted. File size cannot exceed 10 MB.'
                                                    /> : <>
                                                        <Flex start gap='4px'>
                                                            <Text weight="400" color="#7384AA"
                                                                style={{ fontSize: "14px" }}
                                                                margin='0 0 4px'
                                                            >
                                                                Most recent fiscal year end
                                                            </Text>
                                                            <Image width='5px' height='5px' margin='4px 0 0' src={Asterisk} />
                                                        </Flex>
                                                        <TextButton
                                                            preLine
                                                            style={{ textAlign: "left" }}
                                                            icon={<Image src={File} />}
                                                            onClick={() => this.downloadStatement(this.getDocument(preScreenDocs[1].name).uri, this.getDocument(preScreenDocs[1].name).fileName)}
                                                            text={this.getDocument(preScreenDocs[1].name).fileName}
                                                            deleteConfirmMessage={'Are you sure you want to delete this file?'}
                                                            onDelete={() => this.handleAccDeleteDoc(this.getDocument(preScreenDocs[1].name, { businessDocument: true }))} />

                                                    </>}
                                            </FlexColumn>
                                        </Flex>

                                        <div style={{ height: 1, width: '100%', background: '#F2F2F2', margin: '24px 0' }} />

                                        <Flex fullWidth between>
                                            <Text weight='500' color='#7384AA'>COMPANY PREPARED PROFIT & LOSS STATEMENT</Text>
                                            <Text primary underline><a href="/Income Statement Template (Version 2023-01).pdf" download="Income Statement Template (Version 2023-01).pdf"><Image src={DownloadIcon} /> Download PDF Template</a></Text>
                                        </Flex>

                                        <Flex noMargin style={{ justifyContent: "between", width: "100%", borderRadius: "8px", padding: "8px 0px", marginTop: 4 }} gap='24px'>
                                            <FlexColumn start style={{ width: `${100 / 2}%` }}>
                                                {!this.getDocument(preScreenDocs[2].name).fileName ?
                                                    <LabeledInput
                                                        nomargin
                                                        {...this.uploadAccProps(preScreenDocs[2], 2)}
                                                        loading={this.state.uploadingItem === "uploading2" ? this.state.uploadLoading : false}
                                                        type='upload'
                                                        label='YTD through most recent closed month'
                                                        uploadText='Only PDF file types accepted. File size cannot exceed 10 MB.'
                                                    /> : <>
                                                        <Flex start gap='4px'>
                                                            <Text weight="400" color="#7384AA"
                                                                style={{ fontSize: "14px" }}
                                                                margin='0 0 4px'
                                                            >YTD through most recent closed month</Text>
                                                            <Image width='5px' height='5px' margin='4px 0 0' src={Asterisk} />
                                                        </Flex>

                                                        <TextButton
                                                            preLine
                                                            style={{ textAlign: "left" }}
                                                            icon={<Image src={File} />}
                                                            onClick={() => this.downloadStatement(this.getDocument(preScreenDocs[2].name).uri, this.getDocument(preScreenDocs[2].name).fileName)}
                                                            text={this.getDocument(preScreenDocs[2].name).fileName}
                                                            deleteConfirmMessage={'Are you sure you want to delete this file?'}
                                                            onDelete={() => this.handleAccDeleteDoc(this.getDocument(preScreenDocs[2].name, { businessDocument: true }))} />

                                                    </>}
                                            </FlexColumn>
                                            <FlexColumn start style={{ width: `${100 / 2}%` }}>
                                                {!this.getDocument(preScreenDocs[3].name).fileName ?
                                                    <LabeledInput
                                                        nomargin
                                                        {...this.uploadAccProps(preScreenDocs[3], 3)}
                                                        loading={this.state.uploadingItem === "uploading3" ? this.state.uploadLoading : false}
                                                        type='upload'
                                                        label='Full year for most recent fiscal year end'
                                                        uploadText='Only PDF file types accepted. File size cannot exceed 10 MB.'
                                                    /> : <>
                                                        <Flex start gap='4px'>
                                                            <Text weight="400" color="#7384AA"
                                                                style={{ fontSize: "14px", }}
                                                                margin='0 0 4px'
                                                            >Full year for most recent fiscal year end</Text>
                                                            <Image width='5px' height='5px' margin='4px 0 0' src={Asterisk} />
                                                        </Flex>

                                                        <TextButton
                                                            preLine
                                                            style={{ textAlign: "left" }}
                                                            icon={<Image src={File} />}
                                                            onClick={() => this.downloadStatement(this.getDocument(preScreenDocs[3].name).uri, this.getDocument(preScreenDocs[3].name).fileName)}
                                                            text={this.getDocument(preScreenDocs[3].name).fileName}
                                                            deleteConfirmMessage={'Are you sure you want to delete this file?'}
                                                            onDelete={() => this.handleAccDeleteDoc(this.getDocument(preScreenDocs[3].name, { businessDocument: true }))} />
                                                    </>}
                                            </FlexColumn>
                                        </Flex>
                                        <div style={{ height: 1, width: '100%', background: '#F2F2F2', margin: '24px 0' }} />
                                        <Flex fullWidth between>
                                            <FlexColumn>
                                                <Text weight='500' color='#7384AA'>AR AGING SUMMARY</Text>
                                                <Text margin='4px 0 24px' width="600px">Please upload a copy of the AR aging report as of your most recent financial closing month. Feel free to use the template provided as a reference.</Text>

                                            </FlexColumn>
                                            <Text primary underline><a href="/ARAgingSummary_Aion Template_Watermarked.pdf" download="ARAgingSummary Aion Template.pdf"><Image src={DownloadIcon} /> Download Template</a></Text>
                                        </Flex>
                                        <Flex noMargin style={{ justifyContent: "start", width: "100%", borderRadius: "8px", padding: "8px 0px", marginTop: 4 }} gap='24px'>
                                            <FlexColumn start style={{ width: `53%` }}>
                                                {!this.getDocument(preScreenDocs[4].name).fileName ?
                                                    <LabeledInput
                                                        nomargin
                                                        {...this.uploadAccProps(preScreenDocs[4], 4)}
                                                        loading={this.state.uploadingItem === "uploading4" ? this.state.uploadLoading : false}
                                                        type='upload'
                                                        label='AR Aging Report'
                                                        uploadText='Accepted file types include PDF, XLS and XLSX. File size cannot exceed 10 MB.'
                                                        accept=".pdf,.xlsx"
                                                        optional
                                                    /> : <>
                                                        <Text weight="400" color="#7384AA"
                                                            style={{ fontSize: "14px" }}
                                                            margin='0 0 4px'
                                                        >AR Aging Report</Text>
                                                        <TextButton
                                                            preLine
                                                            style={{ textAlign: "left" }}
                                                            icon={<Image src={File} />}
                                                            onClick={() => this.downloadStatement(this.getDocument(preScreenDocs[4].name).uri, this.getDocument(preScreenDocs[4].name).fileName)}
                                                            text={this.getDocument(preScreenDocs[4].name).fileName}
                                                            deleteConfirmMessage={'Are you sure you want to delete this file?'}
                                                            onDelete={() => this.handleAccDeleteDoc(this.getDocument(preScreenDocs[4].name, { businessDocument: true }))} />

                                                    </>}
                                            </FlexColumn>
                                        </Flex>
                                    </>
                                    <Button weight='500' width='119px' margin="24px 0 0 0" onClick={this.onUploadPreScreenSubmit} text="DONE" solid radius='4px' />
                                </>
                        }
                    </FlexColumn>
                </Modal>
            </FlexColumn>
            {
                prescreenDocuments && prescreenDocuments.length >= 1 && businessInfo.uploadMethod === 'Upload Manually' && prescreenDocuments.length > 0 &&
                <FlexColumn start style={{ height: 470 }}>
                    {
                        prescreenDocuments.length < 5 && prescreenDocuments.length > 0 ?
                            <Flex start gap='5px' style={{ margin: '12px 0 24px' }} centerHorizontally>
                                <Text> Make sure to upload all required documents. </Text>
                                <StyledExtLink
                                    key="StyledExtLink"
                                    style={{ textAlign: "center", fontSize: "14px", lineHeight: "24px", textDecoration: "underline" }}
                                    onClick={() => this.onClickPreScreenManualUploadModal()}
                                >
                                    Click to upload
                                </StyledExtLink>
                            </Flex> : null
                    }

                    <Flex fullWidth between>
                        <Text weight='500' spacing="0.08em" caption>COMPANY PREPARED BALANCE SHEET</Text>
                        {/* <Text primary underline><a href="/Balance_Sheet_Template_v_2023-01.pdf" download="Balance_Sheet_Template_v_2023-01.pdf"><Image src={DownloadIcon} /> Download Template</a></Text> */}
                    </Flex>
                    <Flex style={{ justifyContent: "between", width: "100%", borderRadius: "8px", padding: "8px 0px", marginTop: 4 }} gap='24px'>
                        <FlexColumn start style={{ width: `${100 / 2}%` }}>
                            {!this.getDocument(preScreenDocs[0].name).fileName ?
                                <>
                                    <Flex start gap='4px'>
                                        <Text weight="400" color="#7384AA" style={{ fontSize: "14px", }} margin='0 0 4px'>Most recent closed month</Text>
                                        <Image width='5px' height='5px' margin='4px 0 0' src={Asterisk} />
                                    </Flex>

                                    <Text width='600px' margin='4px 0 24px'>None</Text>
                                </>
                                : <>
                                    <Flex start gap='4px'>
                                        <Text weight="400" color="#7384AA" style={{ fontSize: "14px", }} margin='0 0 4px'>Most recent closed month</Text>
                                        <Image width='5px' height='5px' margin='4px 0 0' src={Asterisk} />
                                    </Flex>
                                    <TextButton
                                        preLine
                                        style={{ textAlign: "left" }}
                                        icon={<Image src={File} />}
                                        onClick={() => this.downloadStatement(this.getDocument(preScreenDocs[0].name).uri, this.getDocument(preScreenDocs[0].name).fileName)}
                                        text={this.getDocument(preScreenDocs[0].name).fileName}
                                        deleteConfirmMessage={'Are you sure you want to delete this file?'}
                                        onDelete={() => this.handleAccDeleteDoc(this.getDocument(preScreenDocs[0].name, { businessDocument: true }))}
                                    />
                                </>}
                        </FlexColumn>
                        <FlexColumn start style={{ width: `${100 / 2}%` }}>
                            {
                                !this.getDocument(preScreenDocs[1].name).fileName ?
                                    <>
                                        <Flex start gap='4px'>
                                            <Text weight="400" color="#7384AA"
                                                style={{ fontSize: "14px" }}
                                                margin='0 0 4px'
                                            >
                                                Most recent fiscal year end
                                            </Text>
                                            <Image width='5px' height='5px' margin='4px 0 0' src={Asterisk} />
                                        </Flex>

                                        <Text width='600px' margin='4px 0 24px'>None</Text>
                                    </>
                                    :
                                    <>
                                        <Flex start gap='4px'>
                                            <Text weight="400" color="#7384AA"
                                                style={{ fontSize: "14px" }}
                                                margin='0 0 4px'
                                            >
                                                Most recent fiscal year end
                                            </Text>
                                            <Image width='5px' height='5px' margin='4px 0 0' src={Asterisk} />
                                        </Flex>
                                        <TextButton
                                            preLine
                                            style={{ textAlign: "left" }}
                                            icon={<Image src={File} />}
                                            onClick={() => this.downloadStatement(this.getDocument(preScreenDocs[1].name).uri, this.getDocument(preScreenDocs[1].name).fileName)}
                                            text={this.getDocument(preScreenDocs[1].name).fileName}
                                            deleteConfirmMessage={'Are you sure you want to delete this file?'}
                                            onDelete={() => this.handleAccDeleteDoc(this.getDocument(preScreenDocs[1].name, { businessDocument: true }))}
                                        />
                                    </>
                            }
                        </FlexColumn>
                    </Flex>

                    <div style={{ height: 1, width: '100%', background: '#F2F2F2', margin: '12px 0 24px 0' }} />

                    <Flex fullWidth between>
                        <Text weight='500' spacing="0.08em" caption>COMPANY PREPARED PROFIT & LOSS STATEMENT</Text>
                        {/* <Text primary underline><a href="/Income Statement Template (Version 2023-01).pdf" download="Income Statement Template (Version 2023-01).pdf"><Image src={DownloadIcon} /> Download Template</a></Text> */}
                    </Flex>

                    <Flex noMargin style={{ justifyContent: "between", width: "100%", borderRadius: "8px", padding: "8px 0px", marginTop: 4 }} gap='24px'>
                        <FlexColumn start style={{ width: `${100 / 2}%` }}>
                            {
                                !this.getDocument(preScreenDocs[2].name).fileName ?
                                    <>
                                        <Flex start gap='4px'>
                                            <Text weight="400" color="#7384AA"
                                                style={{ fontSize: "14px" }}
                                                margin='0 0 4px'
                                            >YTD through most recent closed month</Text><Image width='5px' height='5px' margin='4px 0 0' src={Asterisk} />
                                        </Flex>

                                        <Text width='600px' margin='4px 0 24px'>None</Text>
                                    </>
                                    :
                                    <>
                                        <Flex start gap='4px'>
                                            <Text weight="400" color="#7384AA"
                                                style={{ fontSize: "14px" }}
                                                margin='0 0 4px'
                                            >YTD through most recent closed month</Text><Image width='5px' height='5px' margin='4px 0 0' src={Asterisk} />
                                        </Flex>
                                        <TextButton
                                            preLine
                                            style={{ textAlign: "left" }}
                                            icon={<Image src={File} />}
                                            onClick={() => this.downloadStatement(this.getDocument(preScreenDocs[2].name).uri, this.getDocument(preScreenDocs[2].name).fileName)}
                                            text={this.getDocument(preScreenDocs[2].name).fileName}
                                            deleteConfirmMessage={'Are you sure you want to delete this file?'}
                                            onDelete={() => this.handleAccDeleteDoc(this.getDocument(preScreenDocs[2].name, { businessDocument: true }))}
                                        />
                                    </>
                            }
                        </FlexColumn>
                        <FlexColumn start style={{ width: `${100 / 2}%` }}>
                            {!this.getDocument(preScreenDocs[3].name).fileName ?
                                <>
                                    <Flex start gap='4px'>
                                        <Text weight="400" color="#7384AA"
                                            style={{ fontSize: "14px", }}
                                            margin='0 0 4px'
                                        >Full year for most recent fiscal year end</Text>
                                        <Image width='5px' height='5px' margin='4px 0 0' src={Asterisk} />
                                    </Flex>

                                    <Text width='600px' margin='4px 0 24px'>None</Text>
                                </>
                                : <>
                                    <Flex start gap='4px'>
                                        <Text weight="400" color="#7384AA"
                                            style={{ fontSize: "14px", }}
                                            margin='0 0 4px'
                                        >Full year for most recent fiscal year end</Text>
                                        <Image width='5px' height='5px' margin='4px 0 0' src={Asterisk} />
                                    </Flex>
                                    <TextButton
                                        preLine
                                        style={{ textAlign: "left" }}
                                        icon={<Image src={File} />}
                                        onClick={() => this.downloadStatement(this.getDocument(preScreenDocs[3].name).uri, this.getDocument(preScreenDocs[3].name).fileName)}
                                        text={this.getDocument(preScreenDocs[3].name).fileName}
                                        deleteConfirmMessage={'Are you sure you want to delete this file?'}
                                        onDelete={() => this.handleAccDeleteDoc(this.getDocument(preScreenDocs[3].name, { businessDocument: true }))}
                                    />
                                </>
                            }
                        </FlexColumn>
                    </Flex>
                    {
                        (flowView && flowView.FlowStep == "Apply.Documents" && !this.getDocument(preScreenDocs[4].name).fileName) ? null :
                            <>
                                <div style={{ height: 1, width: '100%', background: '#F2F2F2', margin: '24px 0' }} />

                                <Flex fullWidth between>
                                    <Text weight='500' spacing="0.08em" caption>AR AGING SUMMARY</Text>
                                </Flex>

                                <Flex noMargin style={{ justifyContent: "start", width: "100%", borderRadius: "8px", padding: "8px 0px", marginTop: 4 }} gap='24px'>
                                    <FlexColumn start style={{ width: `${100 / 2}%` }}>
                                        {!this.getDocument(preScreenDocs[4].name).fileName ?
                                            <>
                                                <Text weight="400" color="#7384AA"
                                                    style={{ fontSize: "14px" }}
                                                    margin='0 0 4px'
                                                >AR Aging Report</Text>
                                                <Text width='600px' margin='4px 0 24px'>None</Text>
                                            </>
                                            : <>
                                                <Text weight="400" color="#7384AA"
                                                    style={{ fontSize: "14px" }}
                                                    margin='0 0 4px'
                                                >AR Aging Report</Text>
                                                <TextButton
                                                    preLine
                                                    style={{ textAlign: "left" }}
                                                    icon={<Image src={File} />}
                                                    onClick={() => this.downloadStatement(this.getDocument(preScreenDocs[4].name).uri, this.getDocument(preScreenDocs[4].name).fileName)}
                                                    text={this.getDocument(preScreenDocs[4].name).fileName}
                                                    deleteConfirmMessage={'Are you sure you want to delete this file?'}
                                                    onDelete={() => this.handleAccDeleteDoc(this.getDocument(preScreenDocs[4].name, { businessDocument: true }))}
                                                />

                                            </>}
                                    </FlexColumn>
                                </Flex>
                            </>
                    }
                </FlexColumn>
            }
        </>
        );

        const bankingSection = (
            <>
                <FlexColumn style={{ margin: "12px 0 0" }} fullWidth>
                    <Flex between>
                        <FlexColumn style={{ width: `75%`, flexDirection: "column" }} >
                            <Text margin="0px 0px 12px 0px" weight='500' spacing="0.08em" caption>BUSINESS BANK STATEMENTS</Text>
                            <Text width="800px" margin="0px 0px 12px 0px" >Connect your primary bank account and any other accounts associated with your business. This will enable us to better assess your application.</Text>
                        </FlexColumn>
                        <FlexColumn style={{ width: `25%`, flexDirection: "column" }}>
                        </FlexColumn>
                    </Flex>

                    {/* {
                        (accountingSysConnected || allDocsUploaded) ? */}
                    <FlexColumn start gap="24px" left>
                        <PlaidLink
                            {...environment.plaid}
                            token={linkToken}
                            selectAccount="false"
                            clientName="Aion"
                            isWebview="true"
                            apiVersion="v2"
                            onSuccess={(public_token, metadata) => {
                                this.setState({ showConnectionErrorModal: false })
                                this.getPlaidAccessToken(public_token, metadata, true, null)
                            }}
                            onExit={() => this.setState({ yodleeLink: true })}
                            onCancel={() => this.setState({ yodleeLink: true })}
                            style={{ border: "none", background: "none", paddingLeft: 0, paddingBottom: 0, margin: '0px 0px 0px 0px', fontSize: 18, cursor: 'pointer' }}
                            onEvent={this.logPlaidEvent}
                        >
                            <Button loading={submitPlaidLoading} width='350px' solid text={bankInfos.length ? "CONNECT ADDITIONAL ACCOUNTS" : "CONNECT YOUR ACCOUNT"} icon={<Image src={Connection} style={{ cursor: 'pointer' }} />} ></Button>
                        </PlaidLink>
                        {
                            bankInfos.length === 0 && showUploadBankDocsManually &&
                            <Flex start gap='5px' style={{ margin: '0 0 0' }} centerHorizontally>
                                <Text>Unable to connect?</Text>
                                <StyledExtLink
                                    key="StyledExtLink"
                                    style={{ textAlign: "center", fontSize: "14px", lineHeight: "24px", textDecoration: "underline" }}
                                    onClick={() => this.onClickManualUploadModal()}
                                >
                                    Click here
                                </StyledExtLink>
                            </Flex>
                        }
                    </FlexColumn>
                    {/* :
                            <Button disabled={true} width='350px' solid text={bankInfos.length ? "CONNECT ADDITIONAL ACCOUNTS" : "CONNECT YOUR ACCOUNT"} icon={<Image src={ConnectionGrey} style={{ cursor: 'pointer' }} />} />
                    } */}

                    {/* {
                            yodleeLink &&
                            <YodleeIntegration store={this.props.aionStore} businessId={this.props.aionStore.businessId} businessUniqueKey={this.props.aionStore.BusinessUniqueKey} openYodleeLink={yodleeLink} handleYodleeLink={this.handleYodleeLink} fetchBanks={this.fetchBanks} />
                        } */}

                    <Flex wrap start style={{ margin: '12px 0 0' }}>
                        {connectedBanks}
                        {uploadedStatements}
                    </Flex>
                </FlexColumn>

                <Modal
                    visible={this.state.showManualUploadModal}
                    footer={null}
                    closable={true}
                    width={624}
                    destroyOnClose={true}
                    onCancel={() => {
                    }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} onClick={() => this.setState({ showManualUploadModal: false, uploadList: [], bankName: "" })} />}
                >
                    <FlexColumn start left>
                        {
                            (!bbUploadReason && showManualBankUploadReason) ?
                                <>
                                    <Text heading>Tell us about why you weren’t able to connect</Text>

                                    <LabeledInput
                                        label="Reason"
                                        id="reason"
                                        type="text-area"
                                        placeholder="Enter a reason (minimum of 50 characters)"
                                        maxLength={2000}
                                        width='576px'
                                        value={reason}
                                        autoSize={{ minRows: 2, maxRows: 4 }}
                                        onChange={this.handleTextChange}
                                        instruction={reason ? `${reason.length}/${50}` : `0/${50}`}
                                    />

                                    <Button loading={docUploadReasonLoading} disabled={!(reason && reason.length > 49)} text='NEXT' solid onClick={() => this.updateReason('bbUploadReason')} margin='48px 0 0' />
                                </>
                                :
                                <>
                                    <Text weight="500" style={{ marginBottom: 20 }} size='20px'>Upload Bank Statements</Text>
                                    <LabeledInput
                                        autoFocus
                                        label="Bank Name"
                                        labelcolor={theme.colors.secondary3}
                                        id="bankName"
                                        placeholder="Enter the name of the bank"
                                        onChange={this.handleChange}
                                        errorMessage={errorMessage}
                                    />
                                    <LabeledInput
                                        {...this.uploadProps(businessDocs[2], businessDocs[2].name)}
                                        type='upload'
                                        label='Bank Statements'
                                        uploadText='Accepted file types include PDF and ZIP. File size cannot exceed 10 MB'
                                        loading={this.state.uploadCategory === businessDocs[2].name ? this.state.uploadLoading : false}
                                        disabled={!bankName || bankName === ""}
                                    />
                                    <Flex start style={{ flexWrap: "wrap", padding: "4px", marginTop: uploadList && uploadList.length > 0 ? 8 : 0, gap: 16 }}>
                                        {
                                            uploadList && uploadList.map((file, i) => {
                                                return (
                                                    <TextButton
                                                        height='32px'
                                                        key={i}
                                                        icon={<Image src={File} />}
                                                        rightIcon={<ImageButton src={Close} onClick={() => this.handleDeleteDoc(file)} />}
                                                        text={file.displayName} />
                                                );
                                            })
                                        }
                                    </Flex>
                                    <Button weight='500' width='119px' margin="24px 0 0 0" onClick={this.onUploadBankStatementSubmit} text="CONFIRM" solid radius='4px' />
                                </>
                        }
                    </FlexColumn>
                </Modal>
                <Modal
                    visible={this.state.showConnectionErrorModal}
                    footer={null}
                    closable={true}
                    width={456}
                    destroyOnClose={true}
                    onCancel={() => { this.setState({ showConnectionErrorModal: false }) }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <FlexColumn center>
                        <Image margin='24px 0 12px' width='48px' height='48px' src={infoRed} />
                        <Text size='28px' height='40px' weight='500' style={{ marginBottom: 8, marginTop: 8 }}>Connection Unsuccessful</Text>
                        <Text center margin='0 12px 24px' width={'360px'}>Looks like your bank account sync through Plaid was not successful.</Text>

                        <Text center margin='0 12px 24px' width={'360px'}>To continue, please upload your bank statements for the last 12 months.</Text>

                        <Button weight='500' width='214px' margin="12px 0 0 0" onClick={() => {
                            this.setState({ showConnectionErrorModal: false, showManualUploadModal: true, uploadList: [], bankName: "" })
                        }} text="UPLOAD STATEMENTS" solid radius='4px' />

                        <PlaidLink
                            {...environment.plaid}
                            token={linkToken}
                            selectAccount="false"
                            clientName="Aion"
                            isWebview="true"
                            apiVersion="v2"
                            onSuccess={(public_token, metadata) => {
                                this.setState({ showConnectionErrorModal: false })
                                this.getPlaidAccessToken(public_token, metadata, true, null)
                            }
                            } onExit={() => {
                                if (this.state.hideError) {
                                    this.setState({ showConnectionErrorModal: true })
                                }
                            }
                            }
                            style={{ border: "none", background: "none", paddingLeft: 0, paddingBottom: 0, margin: '0px 0px 0px 0px', fontSize: 18, cursor: 'pointer' }}
                            onEvent={this.logPlaidEvent}
                        >

                            <TextButton width="61px" size="16px" margin="12px 0 0 0" style={{ textAlign: "left", padding: "14px 14px" }} type="link"
                                text="RETRY"></TextButton>
                        </PlaidLink>


                    </FlexColumn>
                </Modal>
                <Skeleton loading={loadingBanks} active>
                    <Modal
                        visible={showBankDetails}
                        footer={null}
                        onCancel={() => this.closeBankDetails()}
                        width={600}
                    >
                        <div style={{ textAlign: 'left' }}>
                            <Text heading caption margin='12px 12px 0 12px' >BANK DETAILS</Text>
                            {
                                <FlexColumn style={{ margin: '24px 0' }}>
                                    <div style={{ textAlign: 'left', margin: '0 12px 12px 12px' }}>
                                        <Text>
                                            <img style={{ marginRight: 8 }} height='35px' src={bankUrl} />
                                            {bankName}
                                        </Text>
                                    </div>
                                    <LightContainer start wrap="wrap" style={{ margin: '12px 12px 12px 12px' }}>
                                        <Info label='Last Synced' value={moment(bankLastUpdated).format("MMM DD, YYYY hh:mm A")} />
                                        <Info label='Status' value={reconnectBank ? <Badge status="error" text="DISCONNECTED" /> : <Badge status="success" text="CONNECTED" />} />
                                    </LightContainer>
                                    <Flex start wrap="wrap" style={{ margin: '12px 12px 12px 12px' }}>
                                        <Text heading caption margin='12px 12px 12px 0'>ACCOUNT DETAILS</Text>
                                        {
                                            accountDetails && accountDetails.length > 0 &&
                                            <Table
                                                id="AccountDetailsTable"
                                                tableLayout='fixed'
                                                columns={AccountDetailColumns}
                                                dataSource={accountDetails}
                                                pagination={false}
                                                style={{ margin: '12px 0 12px 0' }}
                                            />
                                        }
                                    </Flex>
                                    <Flex fullWidth centerHorizontally start gap='12px' style={{ margin: '12px 12px 0 12px' }}>
                                        {
                                            reconnectBank &&
                                            <PlaidLink
                                                {...environment.plaid}
                                                token={relinkToken}
                                                className='plaid-link-button'
                                                selectAccount="false"
                                                clientName="Aion"
                                                isWebview="true"
                                                apiVersion="v2"
                                                onSuccess={(public_token, metadata) => {
                                                    this.setState({ showConnectionErrorModal: false })
                                                    this.getPlaidAccessToken(public_token, metadata, true, bankAccessToken)
                                                }
                                                } onEvent={this.logPlaidEvent()}
                                            >
                                                <Button solid type="primary" text='RECONNECT' />
                                            </PlaidLink>
                                        }
                                    </Flex>
                                </FlexColumn>
                            }
                        </div>
                    </Modal>
                </Skeleton>
            </>
        )

        var childViews = (
            <FlexColumn start style={{ width: "100%", margin: "12px 0 24px", overflowY: 'auto' }}>
                {accountingSection}
                <FlexColumn start style={{ minHeight: 280, display: !(accountingSysConnected || allDocsUploaded) && 'none' }}>
                    {
                        true && // (accountingSysConnected || allDocsUploaded) &&
                        <>
                            <Divider />
                            {bankingSection}
                        </>
                    }
                </FlexColumn>
            </FlexColumn>
        )

        return (
            <>
                <FlowViewComponent
                    flowView={flowView}
                    currentViewIndex={currentViewIndex}
                    loadPrev={loadPrevView}
                    childViews={childViews}
                    submitLoading={this.state.submitLoading}
                    width={'100%'}
                    back={currentViewIndex > 0}
                    padding='0 64px'
                    {...this.props}
                    loadNext={this.validateFlow}
                />

                <AlertModal
                    question
                    visible={showConnectedModal}
                    title={<>Have you connected all of<br />your bank accounts?</>}
                    description='Connecting all of your associated bank accounts helps us assess your application faster'
                    buttonTitle='YES'
                    cancelButtonText="NO"
                    onConfirm={() => {
                        this.setState({ showConnectedModal: false, submitLoading: false, showSuccessBanner: false });
                        this.props.dispatch(addDataToStore(REMOTE_SAVE_DATA, "Apply.Connection"));
                        this.props.loadNext();
                    }}
                    onCancel={() => this.setState({ showConnectedModal: false })}
                />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        onboardingStore: state.onboardingAppReducer,
        aionStore: state.aionAppReducer,
        creditStore: state.creditAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(ConnectionInfo)));