import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'
// Components
import { Button } from '../../../Reusable/Button'
import { Flex, FlexColumn } from '../../../Reusable/Container'
import { Text } from '../../../Reusable/Text'
import { LabeledInput } from '../../../Reusable/Input'
import { apiPOSTReq } from '../../../../Utils/api'
import environment from '../../../../environment'
import { ErrorAlert } from '../../../Reusable/Alert'
import { Space, Switch } from 'antd'

class Index extends Component {
    state = {
        customer: this.props.customer,
        loading: false,
        verificationStatus: this.props.customer.verificationStatus,
        paymentConfirmation: this.props.customer.paymentConfirmation,
        areligible: this.props.customer.areligible,
        invoiceEligibility: '',
        concentrationLimit: '',
        crossAgeLimit: '',
        reason: '',
        invoiceEligibilityChanged: false,
        concentrationLimitChanged: false,
        crossAgeLimitChanged: false
    }

    componentDidMount() {
        const { businessId } = this.props
        console.log(this.state.customer)
        this.fetchCustomerAttributes(businessId);
        this.fetchAuditLogs(businessId);
    }

    fetchCustomerAttributes = () => {
        const { customer } = this.props
        var body = {
            "clientBusinessId": customer.businessId,
            customerId: customer.customerId
        }

        this.setState({ loading: true })
        apiPOSTReq(`${environment.opsBaseUrl}/ops/fetchCustomerAttributes`, null, body, (err, resp) => {
            this.setState({ loading: false })
            try {
                const data = resp || {}
                if (data.customerAttributes) {
                    var customerAttributes = data.customerAttributes
                    var invoiceEligibility = customerAttributes.filter(
                        item => {
                            return (item.name == "DaysPastInvoiceDate")
                        }
                    )

                    if (invoiceEligibility.length > 0) {
                        var invoiceEligibility = invoiceEligibility[0].value
                        this.setState({
                            invoiceEligibility: invoiceEligibility
                        })
                    }

                    var concentrationLimit = customerAttributes.filter(
                        item => {
                            return (item.name == "Concentration")
                        }
                    )

                    if (concentrationLimit.length > 0) {
                        var concentrationLimit = concentrationLimit[0].value
                        this.setState({
                            concentrationLimit: concentrationLimit * 100
                        })
                    }

                    var crossAgeLimit = customerAttributes.filter(
                        item => {
                            return (item.name == "CrossAged")
                        }
                    )

                    if (crossAgeLimit.length > 0) {
                        var crossAgeLimit = crossAgeLimit[0].value
                        this.setState({
                            crossAgeLimit: crossAgeLimit * 100
                        })
                    }

                    this.setState({
                        paymentConfirmation: data.paymentConfirmation,
                        customerAttributes: customerAttributes
                    })

                    // this.fetchARData()
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    fetchAuditLogs = () => {
        const { customer } = this.props
        var body = {
            "clientBusinessId": customer.businessId,
            customerId: customer.customerId
        }

        this.setState({ loading: true })
        apiPOSTReq(`${environment.opsBaseUrl}/ops/fetchAuditLogs`, null, body, (err, resp) => {
            this.setState({ loading: false })
            const data = resp || {}
            if (data.auditLogs) {

                var auditlogs = data.auditLogs;
                var latestAuditLog = auditlogs[auditlogs.length - 1];

                this.setState({
                    reason: latestAuditLog?.reason
                })
            } else {
                throw Error(data.responseMessage || data.error)
            }

        })
    }

    handleInvoiceEligibility = (event) => {
        const target = event.target
        this.setState({ invoiceEligibility: target.value, invoiceEligibilityChanged: true })
    }

    handleConcentrationLimit = (event) => {
        const target = event.target
        this.setState({ concentrationLimit: target.value, concentrationLimitChanged: true })
    }

    handleCrossAgeLimit = (event) => {
        const target = event.target
        this.setState({ crossAgeLimit: target.value, crossAgeLimitChanged: true })
    }

    handleReason = (event) => {
        const target = event.target
        this.setState({ reason: target.value })
    }

    handleVerificationStatus = (checked) => {
        if (!checked) {
            this.setState({
                verificationStatus: "REQUESTED",
                paymentConfirmation: false,
                areligible: false
            });
        }
        else {
            this.setState({
                verificationStatus: "VALIDATED",
                paymentConfirmation: true,
                areligible: true
            });
        }
    }

    handlePaymentConfirmation = (checked) => {
        this.setState({
            paymentConfirmation: checked,
        });
    }

    handleAreligible = (checked) => {
        this.setState({
            areligible: checked
        });
    }

    handleSubmit = () => {
        const { invoiceEligibility, concentrationLimit, crossAgeLimit, reason, paymentConfirmation, concentrationLimitChanged, crossAgeLimitChanged, invoiceEligibilityChanged, areligible, verificationStatus } = this.state
        const { customer, email } = this.props

        if (reason != null && reason != '') {
            this.setState({ loading: true })

            var username = email
            var request = {
                "clientBusinessId": customer.businessId,
                "userName": username,
                "reason": reason,
                "paymentConfirmation": paymentConfirmation,
                "verificationStatus": verificationStatus,
                "arEligible": areligible,
                "creditOpsCustomerParams": {
                    "customerId": customer.customerId,
                    "customerAttributes": []
                }
            }

            if (invoiceEligibilityChanged) {
                request["creditOpsCustomerParams"]["customerAttributes"].push(
                    {
                        "name": "DaysPastInvoiceDate",
                        "type": "IneligibilityDaysPastInvoiceDate",
                        "value": invoiceEligibility
                    }
                )
            }
            if (concentrationLimitChanged && concentrationLimit != '') {
                if (isNaN(parseFloat(concentrationLimit))) {
                    this.setState({ loading: false })
                    ErrorAlert({ description: "Please enter a valid Concentration Limit." });
                    return
                }

                request["creditOpsCustomerParams"]["customerAttributes"].push(
                    {
                        "name": "Concentration",
                        "type": "IneligibilityConcentration",
                        "value": parseFloat(concentrationLimit) / 100
                    }
                )
            }
            if (crossAgeLimitChanged) {
                request["creditOpsCustomerParams"]["customerAttributes"].push(
                    {
                        "name": "CrossAged",
                        "type": "IneligibilityCrossAged",
                        "value": crossAgeLimit / 100
                    }
                )
            }

            apiPOSTReq(`${environment.opsBaseUrl}/ops/creditOps/setCustomerParams`, null, request, (err, resp) => {
                this.setState({ loading: false })
                try {
                    const data = resp
                    if (data.result) {
                        if (err) throw new Error(err)

                        this.props.closeEdit()
                    } else {
                        throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                    }
                } catch (error) {
                    console.log("ERRR getAccounts", error, err, resp)
                }
            })
        }
        else {
            if (!reason || reason == "") {
                this.setState({ errorField: "reason", errorMessage: "Please enter a valid reason." })
                return
            }
        }
    }

    render() {
        const { loading, paymentConfirmation, concentrationLimit, reason, verificationStatus, areligible, errorField, errorMessage } = this.state
        const { theme } = this.props
        var verification = verificationStatus == "VALIDATED";
        return (
            <FlexColumn start style={{ width: 616 }}>
                <FlexColumn>
                    <Text margin='0 0 40px' size='24px' weight={500} color='#444444'>Payer Settings</Text>
                    <Flex start style={{ marginBottom: 15 }}>
                        <FlexColumn style={{ width: "30%" }}>
                            <Text color='#7384AA' size='14px' weight={400}>Verification Status</Text>
                        </FlexColumn>
                        <FlexColumn>
                            <Switch size="large" checked={verification} onChange={(checked) => this.handleVerificationStatus(checked)} />
                        </FlexColumn>
                    </Flex>
                    <Flex start style={{ marginBottom: 15 }}>
                        <FlexColumn style={{ width: "30%" }}>
                            <Text color='#7384AA' size='14px' weight={400}>Payment Confirmation</Text>
                        </FlexColumn>
                        <FlexColumn>
                            <Switch size="large" checked={paymentConfirmation} onChange={(checked) => this.handlePaymentConfirmation(checked)} />
                        </FlexColumn>
                    </Flex>
                    <Flex start style={{ marginBottom: 15 }}>
                        <FlexColumn style={{ width: "30%" }}>
                            <Text color='#7384AA' size='14px' weight={400}>AR Eligible</Text>
                        </FlexColumn>
                        <FlexColumn>
                            <Switch size="large" checked={areligible} onChange={(checked) => this.handleAreligible(checked)} />
                        </FlexColumn>
                    </Flex>
                    <Flex start style={{ marginBottom: 15 }}>
                        <LabeledInput
                            autoFocus
                            label="Concentration Limit"
                            labelcolor={theme.colors.secondary3}
                            symbolprefix="%"
                            id="concentrationLimit"
                            key="concentrationLimit"
                            placeholder="80.00"
                            onChange={this.handleConcentrationLimit}
                            optional
                            value={concentrationLimit}
                        />
                    </Flex>
                    <Flex start style={{ marginBottom: 15 }}>
                        <LabeledInput
                            autoFocus
                            label="Reason"
                            type="text-area"
                            labelcolor={theme.colors.secondary3}
                            id="reason"
                            key="reason"
                            placeholder="Add your reason for update"
                            onChange={this.handleReason}
                            value={reason}
                            error={errorField == "reason"}
                            errorMessage={errorMessage}
                        />
                    </Flex>
                    <Flex start style={{ marginBottom: 15 }}>
                        <Button primary solid loading={loading} onClick={this.handleSubmit} text="Save" />
                    </Flex>
                </FlexColumn>
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer,
        receivablesStore: state.receivablesAppReducer,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Index))