import React, { Component } from 'react'
import Dimensions from 'react-dimensions'
import CardSelector from './CardSelector'
import { Flex } from '../../Reusable/Container'

class CardSelectorList extends Component {

    render() {
        const { cards, onUpdateCard, onActivateCard, onClick } = this.props

        return (
            <Flex start gap='24px' className='no-scrollbar' centerHorizontally style={{ width: this.props.containerWidth, overflow: 'scroll', padding: '24px 0', paddingInlineStart: '36px', paddingInlineEnd: '24px' }}>
                {
                    cards.map((card, index) => {
                        // if (card.orderStatus !== "OrderPending") {
                        return <CardSelector
                            id={card.cardId}
                            key={card.cardId}
                            debitCard={card}
                            onUpdateCard={(selectedCard) => onUpdateCard(selectedCard)}
                            onActivateCard={(selectedCard) => onActivateCard(selectedCard)}
                            onClick={(card) => onClick(card)}
                        />
                        // }
                    })
                }
            </Flex>
        )
    }
}

export default Dimensions()(CardSelectorList)