import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { Typography, Divider, Skeleton, Select, Input, Modal, Dropdown, Menu, Button, Space, Popover } from 'antd';
import { Flex, FlexColumn, InputContainer } from '../../Reusable/Container';
import { Text, Tag } from '../../Reusable/Text';
import { ErrorAlert } from '../../Reusable/Alert';
import PageHeader from '../../Reusable/PageHeader';
import TxnTable from './TxnTable';
import AccountStatement from '../AccountStatement/Index';
import { TextButton } from '../../Reusable/Button';
import { RightOutlined, DownOutlined, LoadingOutlined, FilePdfOutlined, DownloadOutlined, InfoCircleOutlined } from '@ant-design/icons';

import { apiGET, apiGETDocUrl } from '../../../Utils/api';
import { formatCurrency, getBBAccountName, getBBAccountsWithAccess, getBPAccountsWithAccess, getResourcePerm, toAbsCurrency } from '../../../Utils/util';
import { fetchBBTransactions } from '../../../Utils/bankingDataManager';

// Actions
import { addDataToStore, BNK_SAVE_DATA } from '../../../Actions/actions';
import { LabeledInput } from '../../Reusable/Input';

import CSV from '../../../Images/csv.png'
import QFX from '../../../Images/qfx.svg'
import environment from '../../../environment';

const { Search } = Input;

const { Option } = Select
const dFormat = "YYYY-MM-DD";

class AccountTransactions extends Component {
    constructor(props) {
        super(props);
        const params = new URLSearchParams(props.location.search);
        this.accountId = params.get("accountId");
        this.state = {
            loadingDateRange: true,
            loadingTransactions: true,
            showAccountNumber: false,
            pagination: {
                pageSize: 500,
                current: 1
            }
        }
    }

    componentDidMount() {
        const { UAM } = this.props.aionStore;
        var isAionCustomerSupportUser = UAM.aionCustomerSupportUser;
        this.setState({ isAionCustomerSupportUser: isAionCustomerSupportUser });
        this.handleStmtTypeSelection("Recent");
        this.fetchData({ fetchPending: true });
        this.fetchScheduledPayments();
    }

    fetchData(options) {
        const { fromDate, toDate, searchStr, fetchPending } = options;
        var account = {};
        var accounts = getBPAccountsWithAccess("Transactions", { getActiveAccounts: this.state.isAionCustomerSupportUser ? false : true }); // Return all accounts for support users

        if (!this.accountId) {
            account = accounts[0] || {};
            this.accountId = account.accountId;
        } else {
            account = (accounts || []).find(account => account.accountId == this.accountId) || {};
        }
        var fetchOptions = {
            accountId: this.accountId,
            pagination: this.state.pagination
        }
        if (searchStr) {
            fetchOptions.searchStr = searchStr;
        } else {
            this.setState({ loadingTransactions: true })
        }
        if (fromDate) {
            fetchOptions.fromDate = fromDate;
            fetchOptions.toDate = toDate;
        }
        fetchOptions.bankProvider = account.bankProvider;
        fetchOptions.providerStatus = "Posted";
        if(fetchPending) {
            fetchOptions.providerStatus = "Pending";
        }
        fetchBBTransactions(fetchOptions, (err, resp) => {
            this.setState({ loadingTransactions: false, loadingDateRange: false, loadingTransactions: false });
            try {
                if (err) throw new Error(err);
                console.log("fetchData fetchBBTransactions", fetchOptions)
                console.log("fetchData resp.Transactions", resp.Transactions)
                if(fetchPending) {
                    this.setState({
                        // ...options,
                        pendingTransactions: resp.Transactions
                    });
                } else {
                    if (!searchStr) {
                        // this.props.dispatch(addDataToStore(BNK_SAVE_DATA, resp || {}));
                    }
                    delete options['pendingTransactions']; // remove pendingTransactions from options
                    this.setState({
                        ...options,
                        Transactions: resp.Transactions
                    });
                }
                
            } catch (error) {
                // const fd = this.fetchData;
                // ErrorAlert({ description: error.message, okText: "Retry", onOk() { fd() } });
                ErrorAlert({ description: error.message });
            }
        });
    }

    handleStmtTypeSelection = (value) => {
        if (value == 'Recent') {
            // Get txns for last 1 month
            this.fetchData({
                fromDate: moment().subtract(1, "month").format(dFormat),
                toDate: moment().format(dFormat),
                stmtType: value
            });
        } else {
            const filteredItem = this.props.store.AvailableStatements.find(item => item.Name == value);
            const itemDate = moment(filteredItem.Value, "YYYY-MM");
            this.fetchData({
                fromDate: itemDate.startOf("month").format(dFormat),
                toDate: itemDate.endOf("month").format(dFormat),
                stmtType: value
            });
        }
    }

    handleDateRange = (dates) => {
        this.fetchData({
            fromDate: dates[0].format(dFormat),
            toDate: dates[1].format(dFormat),
            stmtType: "Custom"
        });
    }

    downloadCSVStmt = () => {
        const { fromDate, toDate } = this.state;
        this.setState({ loadingCSV: true });
        var query = {
            FromDate: fromDate,
            ToDate: toDate,
            AccountId: this.accountId
        }
        apiGETDocUrl(`${environment.bbBaseUrl}/bb/export/csv/statement`, {}, query, (err, resp) => {
            this.setState({ loadingCSV: false });
            const data = (resp || {}).data;
            try {
                if (err) throw Error("We had trouble downloading your statement. Please try again.");
                const blob = new Blob([data], {
                    type: 'application/vnd.ms-excel',
                });
                const fileURL = URL.createObjectURL(blob);
                saveAs(fileURL, `Account transactions ${fromDate} to ${toDate}.csv`);
            } catch (error) {
                ErrorAlert({ description: error.message });
            }
        })
    }

    downloadQFXStmt = () => {
        const { fromDate, toDate } = this.state;
        this.setState({ loadingCSV: true });
        var query = {
            FromDate: fromDate,
            ToDate: toDate,
            AccountId: this.accountId
        }
        console.log("downloadQFXStmt", query)
        apiGETDocUrl(`${environment.bbBaseUrl}/bb/qfx/export/transactions`, {}, query, (err, resp) => {
            this.setState({ loadingCSV: false });
            const data = (resp || {}).data;
            try {
                if (err) throw Error("We had trouble downloading your statement. Please try again.");
                const blob = new Blob([data], {
                    type: 'application/vnd.intu.qfx',
                });
                const fileURL = URL.createObjectURL(blob);
                saveAs(fileURL, `Account transactions ${fromDate} to ${toDate}.qfx`);
            } catch (error) {
                ErrorAlert({ description: error.message });
            }
        })
    }

    downloadAccountVerificationPDF = () => {
        this.setState({ loadingAccountVerificationPDF: true });
        var query = {
            AccountId: this.accountId
        }
        apiGETDocUrl(`${environment.bbBaseUrl}/bb/export/pdf/accountVerificationTemplate`, {}, query, (err, resp) => {
            this.setState({ loadingAccountVerificationPDF: false });
            const data = (resp || {}).data;
            try {
                if (err) throw Error("We had trouble downloading your account verification. Please try again.");
                const blob = new Blob([data], {
                    type: 'application/pdf',
                });
                const fileURL = URL.createObjectURL(blob);
                saveAs(fileURL, `Bank Account Letter ${moment().format('MMDDYY')}`)
            } catch (error) {
                ErrorAlert({ description: error.message });
            }
        })
    }

    switchAccount = (accountId) => {
        this.accountId = accountId;
        // this.handleStmtTypeSelection("Recent");
        this.fetchData(this.state);
        this.fetchData({ fetchPending: true });
    }

    fetchScheduledPayments = () => {
        apiGET("/banking/radius/payments/scheduled", {}, (err, resp) => {
            this.setState({ submitLoading: false });
            try {
                const data = resp.data || {};

                // console.log("/banking/radius/payments/scheduled", JSON.stringify(data))

                if (data.success) {
                    this.setState({
                        totalScheduled: data.totalScheduled,
                        totalWireScheduled: data.totalWireScheduled,
                        totalACHScheduled: data.totalACHScheduled,
                        scheduledACHTransfers: data.scheduledACHTransfers,
                        scheduledWireTransfers: data.scheduledWireTransfers,
                    });
                } else {
                    throw Error(data.msg || "Sorry we had trouble processing your request, please try again.")
                }
            } catch (error) {
                ErrorAlert({ description: error.message });
            }
        })
    }

    onSearchChange = (e) => {
        this.setState({ searchStr: e.target.value });
        this.setState({
            pagination: {
                current: 1,
                pageSize: 100
            }
        })
        this.fetchData({ pagination: this.state.pagination, searchStr: e.target.value });
    }

    render() {
        var { stmtType, fromDate, toDate, loadingCSV, loadingAccountVerificationPDF, totalScheduled, pendingTransactions } = this.state;
        pendingTransactions = pendingTransactions || [];
        const { store, theme } = this.props;
        var Accounts = getBPAccountsWithAccess("Transactions", { permType: "view", getActiveAccounts: this.state.isAionCustomerSupportUser ? false : true });
        const account = (Accounts || []).find(account => account.accountId == this.accountId) || {};

        var accountClosed = (account.Status === "closed") || ((account.status || "").toLowerCase() === "closed");
        var accountLocked = account.locked || account.Locked;
        const AccountSummary = () => {

            const processing = account.currentBalance - account.availableBalance;

            return (
                <FlexColumn style={{ width: "fit-content", justifyContent: "start" }}>
                    <Flex center>
                        <InputContainer style={{ margin: "0px 0" }}>
                            <Flex center>
                                <FlexColumn>
                                    <Text size='14px' color={theme.colors.secondary3}>Available</Text>
                                    <Text>{formatCurrency(account.availableBalance)}</Text>
                                </FlexColumn>
                                <Divider type="vertical" style={{ margin: "0 20px", height: "50px", borderColor: theme.colors.systemGray4 }} />
                                <FlexColumn>
                                    <Text size='14px' color={theme.colors.secondary3}>Current</Text>
                                    <Text>{formatCurrency(account.currentBalance)}</Text>
                                </FlexColumn>
                            </Flex>
                        </InputContainer>
                    </Flex>
                </FlexColumn>
            )
        };

        const DateRangeDropdown = () => {
            var { AvailableStatements } = store;
            // console.log("AvailableStatements", AvailableStatements)
            var items = [];
            items.push(
                <Option key={"Recent"} value={"Recent"}>Recent</Option>
            )
            if (AvailableStatements)
                AvailableStatements.forEach(item => {
                    items.push(
                        <Option key={item.Name} name={item.Name} value={item.Name}>
                            {item.Name}
                        </Option>
                    )
                })
            return (
                <Skeleton loading={this.state.loadingDateRange} active title={false} paragraph={{ rows: 1 }}>
                    <Space size="large">
                        <LabeledInput
                            label="Filter"
                            labelcolor={theme.colors.secondary3}
                            id="statementType"
                            key="statementType"
                            type="select"
                            placeholder="Select"
                            value={stmtType}
                            onChange={this.handleStmtTypeSelection}
                            className="no-left-padding"
                            style={{ width: "200px", fontSize: "1.1rem", fontWeight: 500 }}
                            noAsterisk
                        >
                            {items}
                        </LabeledInput>
                        <LabeledInput
                            label="Date Range"
                            labelcolor={theme.colors.secondary3}
                            id="dateRange"
                            key="dateRange"
                            type="range-picker"
                            value={[moment(fromDate), moment(toDate)]}
                            format="MM/DD/YYYY"
                            onChange={this.handleDateRange}
                            style={{ width: "300px", fontSize: "1.1rem", fontWeight: 500 }}
                            noAsterisk
                        />
                        <FlexColumn >
                            <Text labelcolor={theme.colors.secondary3} style={{ marginBottom: "3px" }}>Download</Text>
                            <Space>
                                <a onClick={this.downloadCSVStmt}><img style={{ marginBottom: "3px", height: 25 }} src={CSV} /></a>
                                <a onClick={this.downloadQFXStmt}><img style={{ marginBottom: "3px", height: 25 }} src={QFX} /></a>
                                {loadingCSV && <LoadingOutlined style={{ color: theme.colors.secondary3, fontSize: 22 }} />}
                            </Space>
                        </FlexColumn>
                        <Divider type="vertical" style={{ margin: "0 10px", height: "50px", borderColor: theme.colors.systemGray6 }} />
                        <FlexColumn >
                            <Text labelcolor={theme.colors.secondary3} style={{ marginBottom: "3px" }}>Monthly Statements</Text>
                            <Space>
                                <Tag tagpreset={theme.colors.secondary8} style={{ marginTop: "7px" }} onClick={e => this.setState({ showAccountStmtModal: true })}>VIEW</Tag>
                            </Space>
                        </FlexColumn>
                        <FlexColumn >
                            <Text labelcolor={theme.colors.secondary3} style={{ marginBottom: "3px" }}>Account Letter</Text>
                            <Space>
                                <a onClick={this.downloadAccountVerificationPDF}><FilePdfOutlined style={{ fontSize: 22 }} /></a>
                                {loadingAccountVerificationPDF && <LoadingOutlined style={{ color: theme.colors.secondary3, fontSize: 22 }} />}
                            </Space>
                        </FlexColumn>
                    </Space>
                </Skeleton>
            )
        }

        const AccountDropdownMenu = () => {
            var Accounts = getBPAccountsWithAccess("Transactions", { getActiveAccounts: this.state.isAionCustomerSupportUser ? false : true });
            var menuItems = [];
            Accounts.forEach(account => {
                menuItems.push(
                    <Menu.Item key={account.accountId}>
                        <a onClick={() => this.switchAccount(account.accountId)}>
                            {getBBAccountName(account)}
                        </a>
                    </Menu.Item>
                )
            })

            const menu = <Menu style={{ maxWidth: "250px" }} selectedKeys={[this.accountId]}>{menuItems}</Menu>;
            return (
                <Flex style={{ justifyContent: "start", alignItems: "center", marginTop: 5 }}>
                    <Dropdown overlay={menu}>
                        <a onClick={e => e.preventDefault()}>
                            <Text color={theme.colors.secondary3} level={1} noMargin>{getBBAccountName(Accounts.find(item => item.accountId == this.accountId))} <DownOutlined /></Text>
                        </a>
                    </Dropdown>
                    <Divider style={{ margin: "0 25px" }} type="vertical" />
                    {/* <Button type="text" size="large" style={{ textAlign: "left", color: theme.colors.secondary3, padding: 0 }} onClick={() => { this.setState({ showAccountStmtModal: true }) }}>Account Statements<RightOutlined /></Button> */}
                    {!(accountClosed || accountLocked) && <Flex center>
                        <Text className="MICR-font" style={{ fontSize: 24, marginRight: 10 }}>abcd{(account || {}).mask}</Text>
                        <Tag tagpreset={theme.colors.secondary8} onClick={e => this.setState({ showAccountNumber: !this.state.showAccountNumber })}>VIEW</Tag>
                    </Flex>}
                </Flex>

            );
        }

        return (
            <>
                <FlexColumn style={{ margin: "10px 70px" }}>
                    <PageHeader
                        titleText="Transactions"
                        descComponent={!this.state.loadingTransactions ? <AccountDropdownMenu /> : null}
                        ctaButton={!this.state.loadingTransactions ? <AccountSummary /> : null}
                    // nodivider
                    />
                    {this.state.showAccountNumber &&
                        <InputContainer>
                            <FlexColumn>
                                <Flex between centerHorizontally>
                                    <Text noMargin level={2}>Account number</Text>
                                    <Text style={{ fontSize: 26 }} className="MICR-font">{account.accountNumber}</Text>
                                </Flex>
                                <Divider style={{ margin: "8px 0" }} />
                                <Flex between centerHorizontally>
                                    <Text noMargin level={2}>Routing number</Text>
                                    <Text style={{ fontSize: 26 }} className="MICR-font">{account.routingNumber}</Text>
                                </Flex>
                            </FlexColumn>
                        </InputContainer>

                    }
                    {(pendingTransactions.length > 0) &&
                        <div>
                            <Space>
                                <Text heading>Pending</Text>
                                <Popover content="These transactions have not yet been posted to this account and will be processed during the next nightly processing.">
                                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)', fontSize: '1.2rem', marginBottom: "10px" }} />
                                </Popover>
                            </Space>
                            <Skeleton loading={this.state.loadingTransactions} active title={false} paragraph={{ rows: 3 }}>
                                {<TxnTable componentType="AccountTransactions" type="pending" transactions={pendingTransactions} bankingStore={store} />}
                            </Skeleton>
                            <Divider />
                        </div>
                    }
                    <Text heading>Posted</Text>
                    {!this.state.loadingTransactions ? <DateRangeDropdown /> : null}
                    <Search
                        placeholder="Search"
                        value={this.state.searchStr}
                        size="large" allowClear
                        onSearch={() => this.fetchData({ pagination: this.state.pagination, searchStr: this.state.searchStr })}
                        onChange={this.onSearchChange}
                        style={{ marginBottom: '20px' }}
                    />
                    <Skeleton loading={this.state.loadingTransactions} active title={false} paragraph={{ rows: 3 }}>
                        {<TxnTable componentType="AccountTransactions" transactions={this.state.Transactions || []} bankingStore={store} />}
                    </Skeleton>
                </FlexColumn>
                <Modal
                    visible={this.state.showAccountStmtModal}
                    footer={null}
                    onCancel={() => { this.setState({ showAccountStmtModal: false }) }}
                    width={500}
                    style={{ top: 20 }}
                    destroyOnClose={true}
                >
                    <AccountStatement accountid={this.accountId} accountname={getBBAccountName(account || {})} account={account} modal />
                </Modal>
            </>

        );
    }
}

function mapStateToProps(state) {
    return {
        store: state.bankingAppReducer,
        aionStore: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(AccountTransactions));