import React from 'react'
import styled, { withTheme } from 'styled-components'
import { GradientDivider } from './Input'
import { Text } from './Text'

// Components
import { LabeledInput } from './Input'

export const Summary = (props) => {
    return <div {...props}>
        <Text color='#737387' weight={500}>{props.title}</Text>
        {
            props.data && props.data.map(
                (data, index) =>
                    data.gradient ?
                        <GradientDivider marginBottom='24px' reverse />
                        :
                        <LabeledInput
                            label={data.description}
                            id={data.description}
                            key={data.description}
                            className="no-left-padding"
                            margin='24px 0 0 0'
                            value={data.value}
                            divider={false}
                            type="read-only"
                            large={data.large}
                            color={data.color}
                        />
            )
        }
    </div>
}