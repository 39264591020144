import React, { useState } from 'react';
import { Text, Tag } from '../../Reusable/Text';
import { LabeledInput } from '../../Reusable/Input';
import { TextButton } from '../../Reusable/Button'
import { Image } from '../../Reusable/Image'
import moment from 'moment'
import { saveAs } from 'file-saver'
import { toCurrency } from '../../../Utils/util'
import { apiPOSTReq, getPreSignedS3Url } from '../../../Utils/api'
import { Flex, FlexColumn, Container, Card, CardContainer } from '../../Reusable/Container';
import Download from '../../../Images/download.png'


const PaymentInformation = ({ invoice, width, attachmentUrl, referralInfo }) => {
    var { amount } = referralInfo || {}
    var { docNumber, dueDate, attachments, balance, amount } = invoice || {}

    if (dueDate) {
        dueDate = new Date(dueDate).toISOString()
        dueDate = dueDate.split("T")[0]
    }
    dueDate = dueDate && moment(dueDate)

    const invoiceDoc = (invoice?.attachments || []).find(item => item.type == "InvoiceDoc") || {}

    return (
        <CardContainer width={width} fullWidth={!width} padding='12px 24px 24px'>
            <FlexColumn gap='24px'>
                <Text heading>Invoice Summary</Text>
                <FlexColumn start gap='12px'>
                    <Flex fullWidth between>
                        <Text>Invoice number</Text>
                        <Text>{docNumber}</Text>
                    </Flex>
                    <Flex fullWidth between>
                        <Text>Due on</Text>
                        <Text>{dueDate?.format('ll')}</Text>
                    </Flex>
                    <Flex fullWidth between>
                        <Text>Invoice amount</Text>
                        <Text>${toCurrency(amount)}</Text>
                    </Flex>
                    <Flex fullWidth between>
                        <Text weight='700'>Invoice balance</Text>
                        <Text weight='700'>${toCurrency(balance)}</Text>
                    </Flex>
                </FlexColumn>

                {
                    attachmentUrl &&
                    <TextButton weight='400' text='Download invoice' rightIcon={<Image src={Download} />} onClick={() => saveAs(attachmentUrl, docNumber)} />
                }
            </FlexColumn>
        </CardContainer>
    );
};

export default PaymentInformation;
