import LHomeIcon from '../Images/home-icon/light.png';
import DHomeIcon from '../Images/home-icon/dark.png';
import LDepositIcon from '../Images/deposit-icon/light.png';
import DDepositIcon from '../Images/deposit-icon/dark.png';
import LPaymentsIcon from '../Images/payments-icon/light.png';
import DPaymentsIcon from '../Images/payments-icon/dark.png';
import LHistoryIcon from '../Images/history-icon/light.png';
import DHistoryIcon from '../Images/history-icon/dark.png';
import LRecipientIcon from '../Images/recipient-icon/light.png';
import DRecipientIcon from '../Images/recipient-icon/dark.png';
import LTxnIcon from '../Images/txn-icon/light.png';
import DTxnIcon from '../Images/txn-icon/dark.png';
import LProfileIcon from '../Images/profile-icon/light.png';
import DProfileIcon from '../Images/profile-icon/dark.png';
import LSupportIcon from '../Images/support-icon/light.png';
import DSupportIcon from '../Images/support-icon/dark.png';
import LPOIcon from '../Images/po-icon/light.png';
import DPOIcon from '../Images/po-icon/dark.png';
import LAdvanceIcon from '../Images/advance-icon/light.png';
import DAdvanceIcon from '../Images/advance-icon/dark.png';
import LInvoiceIcon from '../Images/invoice-icon/light.png';
import DInvoiceIcon from '../Images/invoice-icon/dark.png';
import LPayGroupIcon from '../Images/pay-group-icon/light.png';
import DPayGroupIcon from '../Images/pay-group-icon/dark.png';
import LBookIcon from '../Images/book-icon/light.png';
import DBookIcon from '../Images/book-icon/dark.png';
import LBPIcon from '../Images/bp-icon/light.png';
import DBPIcon from '../Images/bp-icon/dark.png';
import LDCIcon from '../Images/debit-card-icon/light.png';
import DDCIcon from '../Images/debit-card-icon/dark.png';
import LApiIcon from '../Images/api-key-icon/light.png';
import DApiIcon from '../Images/api-key-icon/dark.png';
import LTemplateIcon from '../Images/template-icon/light.png';
import DTemplateIcon from '../Images/template-icon/dark.png';
import LApprSettingsIcon from '../Images/approval-settings-icon/light.png';
import DApprSettingsIcon from '../Images/approval-settings-icon/dark.png';
import LApprovalIcon from '../Images/approval-icon/light.png';
import DApprovalIcon from '../Images/approval-icon/dark.png';
import LInboxIcon from '../Images/inbox-icon/light.png';
import DInboxIcon from '../Images/inbox-icon/dark.png';
import LIntegartionIcon from '../Images/integration/integration.png';
import DIntegartionIcon from '../Images/integration/integration.png';
import LSyncIcon from '../Images/integration/sync-integration.png';
import DSyncIcon from '../Images/integration/sync-integration.png';
import LSwitchIcon from '../Images/switch.png';
import DSwitchIcon from '../Images/switch.png';

import { getBBAccountsWithAccess, getBPAccountsWithAccess, getFeaturePerm, getResourcePerm, getUserApps, getUserRoles } from '../Utils/util';
import environment from '../environment';
import { Flex } from '../Components/Reusable/Container';

export const getNewMenuData = (props) => {
    const { location, theme, approvalNotificationCount } = props;
    var darkMode = (theme.type === "dark");
    var data = {
        colorScheme: theme.menu.colors,
        menuItems: {}
    }

    // console.log('getNewMenuData approvalNotificationCount', approvalNotificationCount)

    const userRole = getUserRoles(props.aionStore);

    const allowedApps = getUserApps(props.aionStore);
    var Attributes = props.aionStore.Attributes || {};
    const bbAttributes = Attributes.BankingAttributes || {};
    const businessAttributes = Attributes.Businesses || {};
    const { ApprovalsEnabled } = businessAttributes;
    var Accounts = getBPAccountsWithAccess()
    Accounts = Accounts || [];
    var linkedBusinessList = props.aionStore.linkedBusinessList || [];

    const paymentsPerm = getResourcePerm("BusinessBanking.Payments", { menuPerm: true });
    const recipientsPerm = getResourcePerm("BusinessBanking.Recipients", { menuPerm: true });
    const billPaymentsPerm = getResourcePerm("Payables.Bill Payments");
    const contractorPaymentsPerm = getResourcePerm("Payables.Contractor Payments");

    const { connections } = props.aionStore

    // Menu item for apply
    const isCompanyAdmin = userRole.includes("Company Admin") || userRole.includes("Owner") || userRole.includes("Primary Admin") || userRole.includes("Aion Support User");
    if (!isCompanyAdmin) {
        // Menu item for apply
        data.menuItems.apply = [{
            key: "0",
            title: "Application",
            icon: darkMode ? DApprSettingsIcon : LApprSettingsIcon,
            path: "/apply"
        }]
    }

    if (bbAttributes.ApplicationApproved && allowedApps.includes("BusinessBanking")) {
        var bankingMenuItems = []
        var transactMenuItems = []

        const filteredAccounts = getBPAccountsWithAccess("Deposits", { permType: "manage", getActiveAccounts: true }); //.filter(item => { return (item.AccountType !== 'ACCOUNTS_RECEIVABLE') });
        const txnPerm = getResourcePerm("BusinessBanking.Transactions", { menuPerm: true });
        const depositsPerm = getResourcePerm("BusinessBanking.Deposits", { menuPerm: true });
        const recipientsPerm = getResourcePerm("BusinessBanking.Recipients", { menuPerm: true });

        // console.log('getNewMenuData recipientsPerm', txnPerm, recipientsPerm)

        // console.log("paymentsPerm", paymentsPerm);
        if (txnPerm.view) {
            bankingMenuItems = bankingMenuItems.concat([
                {
                    key: "0",
                    title: "Overview",
                    icon: darkMode ? DHomeIcon : LHomeIcon,
                    path: "/banking/home"
                }, {
                    key: "1",
                    title: "Accounts",
                    icon: darkMode ? DHomeIcon : LHomeIcon,
                    path: "/banking/accounts"
                }
            ])
        }
        if (txnPerm.manage) {
            bankingMenuItems = bankingMenuItems.concat([
                {
                    key: "2",
                    title: "Transfers",
                    icon: darkMode ? DHomeIcon : LHomeIcon,
                    path: "/banking/transfers"
                }
            ])
        }
        if (recipientsPerm.approve || recipientsPerm.manage || recipientsPerm.view) bankingMenuItems.push({
            key: "7",
            title: "Recipients",
            icon: darkMode ? DApprSettingsIcon : LApprSettingsIcon,
            path: "/banking/recipients"
        })

        // // Show Book Transfer option if no. of accounts is greater than 1
        // if (filteredAccounts.length > 1 && txnPerm.manage) {
        //     bankingMenuItems.push({
        //         key: "4",
        //         title: "Internal Transfer",
        //         icon: darkMode ? DBookIcon : LBookIcon,
        //         path: "/banking/internal-transfer"
        //     })
        // }


        if (txnPerm.isAdmin && getFeaturePerm("BusinessBanking.Approvals.Transfers Workflow").manage) bankingMenuItems.push({
            key: "8",
            title: "Controls",
            icon: darkMode ? DApprSettingsIcon : LApprSettingsIcon,
            path: "/banking/controls"
        })

        if (bankingMenuItems.length > 0) {
            data.menuItems.banking = bankingMenuItems
        }
    }

    if (allowedApps.includes("BusinessCards")) {
        const cardsPerm = getResourcePerm("BusinessCards.Cards", { menuPerm: true });

        if (cardsPerm.manage || cardsPerm.view) {
            data.menuItems.cards = [
                {
                    key: "0",
                    title: "My Cards",
                    icon: darkMode ? DHomeIcon : LHomeIcon,
                    path: "/cards/my-cards"
                }
            ]
        }

        if (cardsPerm.manage && cardsPerm.view) {
            data.menuItems.cards = data.menuItems.cards.concat([
                {
                    key: "1",
                    title: "Company Cards",
                    icon: darkMode ? DHomeIcon : LHomeIcon,
                    path: "/cards/all"
                }
            ])
        }
    }

    if (allowedApps.includes("ARCredit")) {
        var arCreditMenuItems = []
        var arMenuItems = []

        const advPerm = getResourcePerm("ARCredit.Advances");
        const txnPerm = getResourcePerm("ARCredit.Transactions");
        const payersPerm = getResourcePerm("ARCredit.Payers");
        const invAdvPerm = getResourcePerm("ARCredit.Inventory Advances");
        const invTxnPerm = getResourcePerm("ARCredit.Inventory History");
        const invPayersPerm = getResourcePerm("ARCredit.Inventory Upload");


        if (businessAttributes.InventoryLOCEnabled !== true) {
            if (advPerm.view || advPerm.manage) {
                arMenuItems.push({
                    key: '0',
                    title: "Advances",
                    icon: darkMode ? DAdvanceIcon : LAdvanceIcon,
                    path: "/credit/ar-credit/advances"
                });
            }

            if (txnPerm.view || txnPerm.manage) {
                arMenuItems.push({
                    key: '2',
                    title: "Transactions",
                    icon: darkMode ? DTxnIcon : LTxnIcon,
                    path: "/credit/ar-credit/transactions"
                });
            }

            if (payersPerm.view || payersPerm.manage) {
                arMenuItems.push({
                    key: '3',
                    title: "Payers",
                    icon: darkMode ? DRecipientIcon : LRecipientIcon,
                    path: "/credit/ar-credit/payers"
                });
            }
        }

        // if (arMenuItems.length > 0) {
        //     arCreditMenuItems = arCreditMenuItems.concat(
        //         [
        //             {
        //                 subtitle: "AR Credit",
        //                 open: true,
        //                 items: arMenuItems
        //             }
        //         ]
        //     )
        // }

        if (businessAttributes.InventoryLOCEnabled) {
            // var invMenuItems = []

            if (invAdvPerm.view || invAdvPerm.manage) {
                arMenuItems.push({
                    key: '4',
                    title: "Advances",
                    icon: darkMode ? DAdvanceIcon : LAdvanceIcon,
                    path: "/credit/inventory-loc/advances"
                });
            }

            if (invTxnPerm.view || invTxnPerm.manage) {
                arMenuItems.push({
                    key: '5',
                    title: "History",
                    icon: darkMode ? DTxnIcon : LTxnIcon,
                    path: "/credit/inventory-loc/transactions"
                });
            }

            if (invPayersPerm.view || invPayersPerm.manage) {
                arMenuItems.push({
                    key: '6',
                    title: "Inventory Upload",
                    icon: darkMode ? DInvoiceIcon : LInvoiceIcon,
                    path: "/credit/inventory-loc/inventory"
                });
            }

            // if (arMenuItems.length > 0) {
            //     arCreditMenuItems = arCreditMenuItems.concat(
            //         [
            //             {
            //                 subtitle: "Inventory Line of Credit",
            //                 open: true,
            //                 items: invMenuItems
            //             }
            //         ]
            //     )
            // }
        }

        data.menuItems.arCredit = arMenuItems
    }

    if (allowedApps.includes("ABLCredit")) {
        var ablCreditMenuItems = []
        // var creditMenuItems = []

        const ablAdvPerm = getResourcePerm("ABLCredit.ABL Advances");
        const ablHistPerm = getResourcePerm("ABLCredit.ABL History");
        const ablPayersPerm = getResourcePerm("ABLCredit.AR Payers");
        const ablARUploadPerm = getResourcePerm("ABLCredit.AR Upload");
        const ablInvUploadPerm = getResourcePerm("ABLCredit.Inventory Upload");

        if (ablAdvPerm.view || ablAdvPerm.manage) {
            ablCreditMenuItems.push({
                key: '0',
                title: "Advances",
                icon: darkMode ? DAdvanceIcon : LAdvanceIcon,
                path: "/credit/abl-credit/advances"
            });
        }

        if (ablHistPerm.view || ablHistPerm.manage) {
            ablCreditMenuItems.push({
                key: '1',
                title: "Line History",
                icon: darkMode ? DTxnIcon : LTxnIcon,
                path: "/credit/abl-credit/line-history"
            });
        }

        // if (creditMenuItems.length > 0) {
        //     ablCreditMenuItems = ablCreditMenuItems.concat(
        //         [
        //             {
        //                 subtitle: " ",
        //                 open: true,
        //                 items: creditMenuItems
        //             }
        //         ]
        //     )
        // }

        // var arMenuItems = []

        if (ablPayersPerm.view || ablPayersPerm.manage) {
            ablCreditMenuItems.push({
                key: '3',
                title: "Payers",
                icon: darkMode ? DRecipientIcon : LRecipientIcon,
                path: "/credit/abl-credit/payers"
            });
        }

        if (ablARUploadPerm.view || ablARUploadPerm.manage) {
            ablCreditMenuItems.push({
                key: '2',
                title: "AR Upload",
                icon: darkMode ? DTxnIcon : LTxnIcon,
                path: "/credit/abl-credit/ar-aging"
            });
        }

        // if (arMenuItems.length > 0) {
        //     ablCreditMenuItems = ablCreditMenuItems.concat(
        //         [
        //             {
        //                 subtitle: "AR LINE OF CREDIT",
        //                 open: true,
        //                 items: arMenuItems
        //             }
        //         ]
        //     )
        // }

        // var invMenuItems = []

        if (ablInvUploadPerm.view || ablInvUploadPerm.manage) {
            ablCreditMenuItems.push({
                key: '4',
                title: "Inventory Update",
                icon: darkMode ? DAdvanceIcon : LAdvanceIcon,
                path: "/credit/abl-credit/inventory"
            });
        }

        // if (invMenuItems.length > 0) {
        //     ablCreditMenuItems = ablCreditMenuItems.concat(
        //         [
        //             {
        //                 subtitle: "INVENTORY LINE OF CREDIT",
        //                 open: true,
        //                 items: invMenuItems
        //             }
        //         ]
        //     )
        // }

        data.menuItems.ablCredit = ablCreditMenuItems
    }

    if (allowedApps.includes("Receivables")) {
        var receivablesMenuItems = []

        const invPerm = getResourcePerm("Receivables.Invoices");
        const paymentsPerm = getResourcePerm("Receivables.Payments");

        if (invPerm.view || invPerm.manage) receivablesMenuItems = receivablesMenuItems.concat([{
            key: '0',
            title: "Invoices",
            icon: darkMode ? DInvoiceIcon : LInvoiceIcon,
            path: "/receivables/invoices"
        }, {
            key: '1',
            title: "Customers",
            icon: darkMode ? DRecipientIcon : LRecipientIcon,
            path: "/receivables/customers"
        }, {
            key: '2',
            title: "Products & Services",
            icon: darkMode ? DTxnIcon : LTxnIcon,
            path: "/receivables/products"
        }, {
            key: '3',
            title: "Invoice Templates",
            icon: darkMode ? DTemplateIcon : LTemplateIcon,
            path: "/receivables/templates"
        }]);
        // if (paymentsPerm.view || paymentsPerm.manage) receivablesMenuItems = receivablesMenuItems.concat([{
        //     key: '4',
        //     title: "Invoice Payments",
        //     icon: darkMode ? DDepositIcon : LDepositIcon,
        //     path: "/receivables/invoice-payments"
        // }]);

        data.menuItems.receivables = receivablesMenuItems
    }

    if (allowedApps.includes("Payables")) {
        var payablesMenuItems = []
        var billMenuItems = []

        const billsPerm = getResourcePerm("Payables.Bills");
        const vendorPerm = getResourcePerm("Payables.Vendors");
        const contractorsPerm = getResourcePerm("Payables.Contractors");

        if (billsPerm.view || billsPerm.manage) {
            // billMenuItems.push(
            //     {
            //         key: '0',
            //         title: "Inbox",
            //         icon: darkMode ? DInboxIcon : LInboxIcon,
            //         path: "/payables/inbox"
            //     }
            // )
            billMenuItems.push(
                {
                    key: '1',
                    title: "Bills",
                    icon: darkMode ? DInvoiceIcon : LInvoiceIcon,
                    path: "/payables/bills"
                }
            )
        }

        if (vendorPerm.view || vendorPerm.manage) {
            billMenuItems.push(
                {
                    key: '2',
                    title: "Vendors",
                    icon: darkMode ? DRecipientIcon : LRecipientIcon,
                    path: "/payables/vendors"
                }
            )
        }

        if (billPaymentsPerm.view || billPaymentsPerm.manage) {
            billMenuItems.push(
                {
                    key: '3',
                    title: "Payments",
                    icon: darkMode ? DPaymentsIcon : LPaymentsIcon,
                    path: "/payables/bill-payments"
                }
            )
        }

        if (billsPerm.isAdmin && getFeaturePerm("Payables.Approvals.Payment Approval Workflow").manage) {
            billMenuItems.push(
                {
                    key: '5',
                    title: "Controls",
                    icon: darkMode ? DApprSettingsIcon : LApprSettingsIcon,
                    path: "/payables/controls"
                }
            )
        }

        if (billMenuItems.length > 0) {
            payablesMenuItems = payablesMenuItems.concat(
                [
                    {
                        subtitle: "PAYABLES",
                        open: true,
                        items: billMenuItems
                    }
                ]
            )

            data.menuItems.payables = billMenuItems
        }

        var contractorMenuItems = []

        /*
        * Enable for CRB */
        if (contractorsPerm.view || contractorsPerm.manage) {
            contractorMenuItems.push(
                {
                    key: '3',
                    title: "Contractors",
                    icon: darkMode ? DRecipientIcon : LRecipientIcon,
                    path: "/contractor-payroll/contractors"
                }
            )
        }

        if (contractorsPerm.view || contractorsPerm.manage) {
            contractorMenuItems.push(
                {
                    key: 'payGroup',
                    title: "Pay Groups",
                    icon: darkMode ? DPayGroupIcon : LPayGroupIcon,
                    path: "/contractor-payroll/paygroups"
                }
            )
        }

        if (contractorPaymentsPerm.view || contractorPaymentsPerm.manage) {
            contractorMenuItems.push(
                {
                    key: '4',
                    title: "Payments",
                    icon: darkMode ? DPaymentsIcon : LPaymentsIcon,
                    path: "/contractor-payroll/payments"
                }
            )
        }

        // if (contractorsPerm.isAdmin && ApprovalsEnabled) {
        //     contractorMenuItems.push(
        //         {
        //             key: '5',
        //             title: "Approval Policies",
        //             icon: darkMode ? DApprSettingsIcon : LApprSettingsIcon,
        //             path: "/payables/contractor-approval-rules"
        //         }
        //     )
        // }

        if (contractorMenuItems.length > 0) {
            // contractorMenuItems = contractorMenuItems.concat(
            //     [
            //         {
            //             subtitle: "CONTRACTOR PAYROLL",
            //             open: true,
            //             items: contractorMenuItems
            //         }
            //     ]
            // )

            data.menuItems.contractorPayroll = contractorMenuItems
        }

        // data.menuItems.payables = payablesMenuItems
    }

    if (paymentsPerm.approve || recipientsPerm.approve || billPaymentsPerm.approve || contractorPaymentsPerm.approve) {
        var approveMenuItems = [{
            key: "0",
            title: "Pending",
            icon: darkMode ? DApprovalIcon : LApprovalIcon,
            path: "/approvals/pending",
            notificationCount: approvalNotificationCount
        }, {
            key: "0",
            title: "History",
            icon: darkMode ? DApprovalIcon : LApprovalIcon,
            path: "/approvals/history",
            notificationCount: approvalNotificationCount
        }]
        data.menuItems.approve = approveMenuItems
    }

    const perm = getResourcePerm("Support.Console");
    var supportMenuItems = [];
    if (perm.aionSystemUser) supportMenuItems = supportMenuItems.concat(
        [{
            key: "0",
            title: "Business Search",
            icon: darkMode ? DTxnIcon : LTxnIcon,
            path: "/support/search"
        }, {
            key: "1",
            title: "Business Profile",
            icon: darkMode ? DProfileIcon : LProfileIcon,
            path: "/support/business-profile"
        }, {
            key: "2",
            title: "Banking Application",
            icon: darkMode ? DDCIcon : LDCIcon,
            path: "/support/bb-app"
        }, {
            key: "3",
            title: "Bank Accounts",
            icon: darkMode ? DDCIcon : LDCIcon,
            path: "/support/bb-acc"
        }, {
            key: "4",
            title: "Invoices",
            icon: darkMode ? DInvoiceIcon : LInvoiceIcon,
            path: "/support/invoices"
        }, {
            key: "5",
            title: "Purchase Orders",
            icon: darkMode ? DTxnIcon : LTxnIcon,
            path: "/support/pos"
        }, {
            key: "6",
            title: "AR Aging Reports",
            icon: darkMode ? DTemplateIcon : LTemplateIcon,
            path: "/support/ars"
        }, {
            key: "7",
            title: "Inventory",
            icon: darkMode ? DInvoiceIcon : LInvoiceIcon,
            path: "/support/inventory"
        }, {
            key: "8",
            title: "Payment Proof",
            icon: darkMode ? DTxnIcon : LTxnIcon,
            path: "/support/payment-proof"
        }]
    );
    data.menuItems.support = supportMenuItems;

    // if (location.pathname.includes("/underwriting")) {
    if (userRole.includes("Aion Support User") || userRole.includes("Aion Underwriter")) {
        var aionCreditMenuItems = [];
        const perm = getResourcePerm("Underwriting.Console");
        if (perm.aionSystemUser) data.menuItems.aionCreditMenuItems = aionCreditMenuItems.concat(
            [{
                key: "0",
                title: "Control Center",
                icon: darkMode ? DTxnIcon : LTxnIcon,
                path: "/underwriting/clients"
            },
            // {
            //     key: "0",
            //     title: "Pending",
            //     icon: darkMode ? DTxnIcon : LTxnIcon,
            //     path: "/underwriting/applications/pending"
            // }, {
            //     key: "1",
            //     title: "Completed",
            //     icon: darkMode ? DProfileIcon : LProfileIcon,
            //     path: "/underwriting/applications/completed"
            // }
            {
                key: "1",
                title: "Pre-Screen Apps",
                icon: darkMode ? DTxnIcon : LTxnIcon,
                path: "/underwriting/applications/prescreen"
            }, {
                key: "2",
                title: "Due Diligence Apps",
                icon: darkMode ? DProfileIcon : LProfileIcon,
                path: "/underwriting/applications/duediligence"
            }
                // {
                //     key: "3",
                //     title: "Advances",
                //     icon: darkMode ? DAdvanceIcon : LAdvanceIcon,
                //     path: "/underwriting/applications/advances"
                // }
            ]
        );
    }

    if (true) { // location.pathname.includes("/settings")) {
        var settingsMenuItems = []

        const integrationItems = [
            {
                key: 'sync',
                title: "Sync",
                icon: darkMode ? DSyncIcon : LSyncIcon,
                path: "/settings/sync"
            },
        ]
        // Api App users only
        // const apiPerm = getResourcePerm("AionAPI.Banking");
        // if (apiPerm.view || apiPerm.manage) {
        //     integrationItems.push({
        //         key: 'api',
        //         title: "API Keys",
        //         icon: darkMode ? DApiIcon : LApiIcon,
        //         path: "/settings/api-access"
        //     });
        // }

        settingsMenuItems = [{
            key: 'profile',
            title: "Profile",
            icon: darkMode ? DProfileIcon : LProfileIcon,
            path: "/settings/profile"
        }, {
            key: 'support',
            title: "Help",
            icon: darkMode ? DSupportIcon : LSupportIcon,
            path: "/settings/support"
        }, {
            key: 'bank-connect',
            title: "External Bank Connections",
            icon: darkMode ? DBookIcon : LBookIcon,
            path: "/settings/bank-connect"
        }];

        if (allowedApps && allowedApps.length > 0) {
            let showIntegration = allowedApps.filter(x => x === "Receivables" || x === "Bookkeeping" || x === "Payables").length > 0

            if (!props.aionStore.hasIntegration) {
                showIntegration = false;
            }
            let showCOA = allowedApps.filter(x => x === "Bookkeeping").length > 0
            if (showCOA) {
                settingsMenuItems.push(
                    {
                        key: 'coa',
                        title: "Chart of Accounts",
                        icon: darkMode ? DTxnIcon : LTxnIcon,
                        path: "/settings/coa"
                    })
            }
            if (showIntegration) {
                // settingsMenuItems.push(
                //     {
                //         key: 'integration',
                //         title: "Integrations",
                //         icon: darkMode ? DIntegartionIcon : LIntegartionIcon,
                //         path: "/settings/integration"
                //     }, {
                //     subMenu: true,
                //     open: true,
                //     items: integrationItems
                // })

                settingsMenuItems.push({
                    key: 'sync',
                    title: "Sync",
                    icon: darkMode ? DSyncIcon : LSyncIcon,
                    path: "/settings/sync"
                })
            } else {
                // const apiPerm = getResourcePerm("AionAPI.Banking");
                // if (apiPerm.view || apiPerm.manage) {
                //     settingsMenuItems.push({
                //         key: 'api',
                //         title: "API Keys",
                //         icon: darkMode ? DApiIcon : LApiIcon,
                //         path: "/settings/api-access"
                //     });
                // }
            }

            const apiPerm = getResourcePerm("AionAPI.Banking");
            if (apiPerm.view || apiPerm.manage) {
                settingsMenuItems.push({
                    key: 'api',
                    title: "API Keys",
                    icon: darkMode ? DApiIcon : LApiIcon,
                    path: "/settings/api-access"
                });
            }

            // if (showIntegration && integrationItems.length > 0) {
            //     settingsMenuItems = settingsMenuItems.concat(
            //         [
            //             {
            //                 subtitle: "Integrations",
            //                 open: true,
            //                 items: integrationItems
            //             }
            //         ]
            //     )
            // }
        }

        // Only display Manage Users for admins and owners
        if (userRole.includes("Company Admin") || userRole.includes("Owner") || userRole.includes("Primary Admin")) {
            settingsMenuItems.splice(1, 0, {
                key: 'access',
                title: "Users & Roles",
                icon: darkMode ? DRecipientIcon : LRecipientIcon,
                path: "/settings/user-access"
            });
        }
        const userMgmtPerm = getResourcePerm("UserMgmt.UserMgmt")
        var showBusinessProfile = true // Showing for everyone // PREVIOUSLY: allowedApps.includes("AionAPI") || userMgmtPerm.manage 
        var newSettingsMenuItems = []
        if (showBusinessProfile) newSettingsMenuItems.push({
            key: 'companyProfile',
            title: "Business Profile",
            icon: darkMode ? DProfileIcon : LProfileIcon,
            path: "/settings/profile"
        })
        if (allowedApps && allowedApps.length > 0) {
            let showIntegration = allowedApps.filter(x => x === "Receivables" || x === "Bookkeeping" || x === "Payables").length > 0

            if (!props.aionStore.hasIntegration) {
                showIntegration = false;
            }
            if (showIntegration) {
                newSettingsMenuItems.push({
                    key: 'sync',
                    title: "Sync",
                    icon: darkMode ? DSyncIcon : LSyncIcon,
                    path: "/settings/sync"
                });
            }
            // if(connections && connections?.length > 0) {
            //     newSettingsMenuItems.push({
            //         key: 'sync',
            //         title: "Sync",
            //         icon: darkMode ? DSyncIcon : LSyncIcon,
            //         path: "/settings/accounting-system-sync"
            //     });
            // }
        }

        // if (allowedApps && allowedApps.length > 0) {
        //     let showIntegration = allowedApps.filter(x => x === "Receivables" || x === "Bookkeeping" || x === "Payables").length > 0

        //     if (!props.aionStore.hasIntegration) {
        //         showIntegration = false;
        //     }
        //     if (showIntegration) {
        //         newSettingsMenuItems.push(
        //             {
        //                 key: 'integration',
        //                 title: "Integrations",
        //                 icon: darkMode ? DIntegartionIcon : LIntegartionIcon,
        //                 path: "/settings/integration"
        //             }, {
        //             subMenu: true,
        //             open: true,
        //             items: integrationItems
        //         })
        //     }
        // }
        if (linkedBusinessList.length > 1) newSettingsMenuItems.push({
            key: 'switchBusiness',
            title: "Switch Business",
            icon: darkMode ? DSwitchIcon : LSwitchIcon,
            type: "custom"
        })
        data.menuItems.settings = newSettingsMenuItems
    }

    if (allowedApps.includes("TermLoans")) {
        var termLoanMenuItems = []

        const termLoansPerm = getResourcePerm("TermLoans.Advances");
        if (termLoansPerm.view || termLoansPerm.manage) {
            termLoanMenuItems = [{
                key: '0',
                title: "Advances",
                icon: darkMode ? DAdvanceIcon : LAdvanceIcon,
                path: "/credit/term-loans/advances"
            }]
        }

        data.menuItems.termLoans = termLoanMenuItems
    }

    // data.menuItems.sort((a, b) => (a.key - b.key))
    return data;
}