import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Switch, withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import { Layout, Steps } from 'antd'

// Components
import Transactions from './Transactions/Index'
import Dashboard from './Dashboard'
import Advances from './Advances/Index'
import TakeAdvance from './Advances/RequestFunds'
import Payers from './Payers/Index'
import SendPaymentNotification from './Payers/SendPaymentNotification'
import Eligibility from './Eligibility/Index'
import TermLoans from './Term Loans/Index'
import RequestAdvance from './Term Loans/Request Advance/Index'
import InventoryAdvances from './Inventory Advances/Index'
import InventorySummary from './Inventory History/Index'
import InventoryTakeAdvance from './Inventory Advances/RequestFunds'
import Inventory from './Inventory Upload/Index'
import ARAging from './AR Upload/Index'
import NewCustomerSetup from './AR Upload/NewCustomerSetup'
import { FlexColumn } from '../Reusable/Container';

// Util
import { getCurrentOnboardingStep, signout } from '../../Utils/util'
import { GradientSideBar } from '../Reusable/Container'
import { Paragraph, Title } from '../Reusable/Text'

const { Header, Content, Sider, Footer } = Layout
const { Step } = Steps

class Index extends Component {
    constructor(props) {
        super(props)
        const store = this.props.aionStore
        const { Attributes } = store
        const attributes = Attributes || {}
        this.businessAttributes = attributes.Businesses || {}
        var currentStep = getCurrentOnboardingStep(this.businessAttributes.ARCreditApplicationSubmitted, this.props.store)
        this.state = {
            collapsed: false,
            applicationSubmitted: this.businessAttributes.ARCreditApplicationSubmitted,
            contractSigned: this.businessAttributes.ARAgreementSigned,
            creditEligibilityApproved: this.businessAttributes.CreditEligibilityApproved,
            creditEligibilityApplied: this.businessAttributes.CreditEligibilityApplied,
            signoutLoading: false,
            currentStep: currentStep
        }
    }

    previousLocation = this.props.location
    componentWillUpdate(nextProps) {
        let { location } = this.props

        // set previousLocation if props.location is not modal
        if (
            nextProps.history.action !== "POP" &&
            (!location.state || !location.state.modal)
        ) {
            this.previousLocation = this.props.location
        }
    }

    // Used by child component
    updateState = (data) => {
        this.setState(data)
    }

    render() {
        let { location, theme } = this.props

        const updateSideMenu = (applicationSubmitted) => {
            var currentStep = getCurrentOnboardingStep(this.businessAttributes.ARCreditApplicationSubmitted, this.props.store)
            if (applicationSubmitted) currentStep = 1
            this.setState({ applicationSubmitted: applicationSubmitted, currentStep: currentStep })
        }
        const onboardingSider = (
            <GradientSideBar width={500} style={{ padding: '30px' }}>
                <div className="flex-container flex-column space-between" style={{ height: '100%' }}>
                    {/* <img src="https://fundpnb-assets.s3-us-west-2.amazonaws.com/aion_logo_white.png" style={{width: "100px"}} alt="background" /> */}
                    <div></div>
                    <div>
                        <Title level={4} style={{ color: '#FFFFFF' }}>Aion AR Credit</Title>
                        <Paragraph level={2} style={{ color: '#FFFFFF' }}>Fast and easy financing for your unpaid invoices. {!this.state.applicationSubmitted ? "Get going by by following the steps described 👉" : ""}</Paragraph>
                    </div>
                    <div></div>
                </div>
            </GradientSideBar>
        )
        return (
            <FlexColumn start style={{ padding: 0, flexGrow: 1, height: '100%' }}>
                <Switch location={location}>
                    <Route
                        exact path='/credit'
                        render={(props) =>
                            // AR Credit 
                            <Dashboard currentStep={this.state.currentStep} updateState={this.updateState} {...props} updateSideMenu={(applicationSubmitted) => updateSideMenu(applicationSubmitted)} />}
                    />
                    <Route exact path='/credit/apply' render={(props) => <Eligibility creditEligibilityApplied={false} />} />
                    <Route path='/callback' render={(props) => <Eligibility creditEligibilityApplied={false} />} />
                    <Route exact path="/credit/ar-credit/payers" component={Payers} />
                    <Route exact path="/credit/ar-credit/transactions" component={Transactions} />
                    <Route exact path="/credit/ar-credit/advances" component={Advances} />
                    <Route exact path="/credit/ar-credit/advances/request" component={TakeAdvance} />
                    <Route exact path="/credit/abl-credit/payers" component={Payers} />
                    <Route exact path="/credit/payers/send-payment-notification" component={SendPaymentNotification} />
                    <Route exact path="/credit/abl-credit/line-history" component={InventorySummary} />
                    <Route exact path="/credit/abl-credit/advances" component={InventoryAdvances} />
                    <Route exact path="/credit/abl-credit/inventory" component={Inventory} />
                    <Route exact path="/credit/abl-credit/ar-aging" component={ARAging} />
                    <Route exact path="/credit/abl-credit/new-customer-setup" component={NewCustomerSetup} />
                    <Route exact path="/credit/abl-credit/advances/request" component={InventoryTakeAdvance} />
                    <Route exact path="/credit/term-loans/advances" component={TermLoans} />
                    <Route exact path="/credit/term-loans/advances/request" component={RequestAdvance} />
                    <Route exact path="/credit/inventory-loc/transactions" component={InventorySummary} />
                    <Route exact path="/credit/inventory-loc/advances" component={InventoryAdvances} />
                    <Route exact path="/credit/inventory-loc/inventory" component={Inventory} />
                    <Route exact path="/credit/inventory-loc/advances/request" component={InventoryTakeAdvance} />
                </Switch>
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.creditAppReducer,
        bankingStore: state.bankingAppReducer,
        userDefaults: state.userDefaultsReducer,
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)))