import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Descriptions, Modal, Skeleton, Popover } from 'antd'
import styled, { withTheme } from 'styled-components'
import { InfoCircleOutlined } from '@ant-design/icons'

// Components
import InventoryTable from '../../../Credit/Inventory Upload/InventoryTable'
import { Flex, FlexColumn, InputContainer } from '../../../Reusable/Container'
import { TextButton } from '../../../Reusable/Button'
import { Text } from '../../../Reusable/Text'
import { Image } from '../../../Reusable/Image'
import ArrowRight from "../../../../Images/arrow-right.png"
import { ErrorAlert } from '../../../Reusable/Alert'

// Util
import { apiGET, apiPOSTReq } from '../../../../Utils/api'
import { toCurrency, getUserApps } from '../../../../Utils/util'
import environment from '../../../../environment'

// Actions
import ModalClose from '../../../../Images/modal-close.png'

export const DescItem = styled.div`
    display: flex;
    justify-content: flex-end;
`

export const DescLabel = styled.span`
    fontWeight: 500;
    fontSize: 16;
`

class Index extends Component {
    constructor(props) {
        super(props)

        this.state = {
            fundsLoaded: false,
            advancesLoaded: false,
            showUploadModal: false,
            inventoryLoaded: false,
            showUpdateModal: false,
            showAdvanceableInventory: false,
        }
    }

    componentDidMount() {
        this.fetchInventoryHistory()
        this.fetchFundsData()
    }

    fetchInventoryHistory = () => {
        const userApps = getUserApps(this.props.aionStore)

        var body = {}

        if (this.props.creditType == "assetbasedloan") body = { "InventoryLOC": true }

        apiPOSTReq(`${environment.iveBaseUrl}/inventory/getInventoryHistory`, { AionCurrentBiz: this.props.businessId }, body, (err, resp) => {
            try {
                const data = resp
                console.log("/inventory/getInventoryHistory", JSON.stringify(data))
                console.log("/inventory/getInventoryHistory err", JSON.stringify(err))
                if (data.result) {
                    this.setState({ inventoryInfos: data.inventoryInfos, inventoryLoaded: true })
                } else {
                    this.setState({ payLoading: false, deleteLoading: false })
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /inventory/getInventoryHistory", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    fetchFundsData = () => {
        const headers = {
            businesskey: this.props.businessId
        }

        var endpoint = "/inventoryfinancing/funds/view"

        if (this.props.creditType == "assetbasedloan") endpoint = "/assetbasedloan/funds/view"

        apiGET(endpoint, headers, (err, resp) => {
            try {
                if (err) throw Error(err)
                const data = resp.data
                console.log(endpoint, data)
                if (data.success) {
                    this.setState({ fundsLoaded: true, AvailableFunds: data })
                } else {
                    throw Error("We had some trouble. Please try again later.")
                }
            } catch (error) {
                alert(error.message)
            }
        })
    }

    render() {
        const { theme } = this.props
        const { inventoryLoaded, inventoryInfos, AvailableFunds, showUploadModal, showUpdateModal, fundsLoaded, ablInvPerm, showAdvanceableInventory } = this.state
        const userApps = getUserApps(this.props.aionStore)

        const Attributes = this.props.aionStore.Attributes || {}
        const businessAttributes = Attributes.Businesses || {}
        const inventoryLOC = businessAttributes.InventoryLOCEnabled

        return (
            <FlexColumn>
                {/* <PageHeader
                    titleText="Credit"
                    subtitle={inventoryLOC === true ? 'Inventory line of credit' : "ABL Credit"}

                /> */}
                <FlexColumn>
                    <Flex between>
                        <div style={{ width: 250 }}>
                            <Skeleton loading={!fundsLoaded} active title={false} paragraph={{ rows: 4 }}>
                                <InputContainer padding='20px 20px 10px' margin='0'>
                                    <FlexColumn start>
                                        <Text color={theme.colors.caption} style={{ marginBottom: 4 }}>Gross Advanceable Inventory</Text>
                                        <Text size='26px' weight='600'>{AvailableFunds && !isNaN(AvailableFunds.GrossAdvanceableInventory) ? `$${toCurrency(AvailableFunds.GrossAdvanceableInventory)}` : "--"}</Text>
                                        {/* <Flex start fullWidth><a style={{ marginTop: 4 }} onClick={() => this.setState({ showAdvanceableInventory: true })}><EyeOutlined style={{ marginRight: 3 }} />View details</a></Flex> */}
                                        <TextButton style={{ marginTop: 4 }} uppercase rightIcon={<Image src={ArrowRight} />} onClick={() => this.setState({ showAdvanceableInventory: true })} text="VIEW DETAILS" />
                                    </FlexColumn>
                                </InputContainer>
                            </Skeleton>
                        </div>
                    </Flex>
                    <br />
                    <InventoryTable
                        inventoryLoaded={inventoryLoaded} data={inventoryInfos} userApps={userApps}
                        titleText='Inventory History'
                    />
                </FlexColumn>
                <Modal
                    visible={showAdvanceableInventory}
                    footer={false}
                    style={{ top: 20 }}
                    destroyOnClose={true}
                    width={550}
                    closable={true}
                    onCancel={() => this.setState({ showAdvanceableInventory: false })}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <div style={{ display: "flex", flexDirection: "flex-start" }}>
                        <Descriptions
                            bordered
                            column={1}
                            style={{ width: '100%', marginTop: 24 }}
                        >
                            <Descriptions.Item label="Gross Inventory" labelStyle={{ fontWeight: 500, fontSize: 16 }} contentStyle={{ fontSize: 16 }}>
                                <DescItem>{AvailableFunds && !isNaN(AvailableFunds.GrossInventory) ? `$${toCurrency(AvailableFunds.GrossInventory)}` : "--"}</DescItem>
                            </Descriptions.Item>
                            <Descriptions.Item label="- Ineligible Inventory" labelStyle={{ fontSize: 16 }} contentStyle={{ fontSize: 16 }}>
                                <DescItem>{AvailableFunds && !isNaN(AvailableFunds.IneligibleInventory) ? `$${toCurrency(AvailableFunds.IneligibleInventory)}` : "--"}</DescItem>
                            </Descriptions.Item>
                            <Descriptions.Item label="Eligible Inventory" labelStyle={{ fontWeight: 500, fontSize: 16 }} contentStyle={{ fontSize: 16 }}>
                                <DescItem>{AvailableFunds && !isNaN(AvailableFunds.EligibleInventory) ? `$${toCurrency(AvailableFunds.EligibleInventory)}` : "--"}</DescItem>
                            </Descriptions.Item>
                            <Descriptions.Item label="* Inventory Advance Rate" labelStyle={{ fontSize: 16 }} contentStyle={{ fontSize: 16 }}>
                                <DescItem>{AvailableFunds && !isNaN(AvailableFunds.InventoryAdvanceRate) ? `${AvailableFunds.InventoryAdvanceRate * 100}%` : "--"}</DescItem>
                            </Descriptions.Item>
                            <Descriptions.Item label={(
                                <Popover content={(
                                    <FlexColumn>
                                        <span><b>Calculation:</b> The product of eligible inventory and advance</span>
                                        <span>rate or the inventory limit whichever is lesser</span>
                                    </FlexColumn>
                                )}>
                                    <div style={{ display: "flex" }}>
                                        <span>Gross Advanceable Inventory</span>
                                        <InfoCircleOutlined style={{ marginLeft: 4, color: 'rgba(0,0,0,.45)' }} />
                                    </div>
                                </Popover>
                            )} labelStyle={{ fontWeight: 500, fontSize: 16 }} contentStyle={{ fontSize: 16 }}>
                                <DescItem>{AvailableFunds && !isNaN(AvailableFunds.GrossAdvanceableInventory) ? `$${toCurrency(AvailableFunds.GrossAdvanceableInventory)}` : "--"}</DescItem>
                            </Descriptions.Item>
                        </Descriptions>
                    </div>
                </Modal>
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.creditAppReducer,
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)))