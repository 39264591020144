const environment = {
    appVersion: "2024-08_R2_HF1",
    uiBaseUrl: "localhost:3000",
    apiBaseUrl: "http://localhost:5000",
    // iveBaseUrl: "http://localhost:5000",
    iveBaseUrl: "https://ive-stage.aionfi.com",
    uamBaseUrl: "https://uam-stage.aionfi.com",
    bbBaseUrl: "https://bb-stage.aionfi.com",
    bbxBaseUrl: "https://bbx-stage.aionfi.com",
    bpBaseUrl: "https://bp-stage.aionfi.com",
    obpBaseUrl: "https://obp-stage.aionfi.com",
    lsmBaseUrl: "https://lsm-stage.aionfi.com",
    payBaseUrl: "https://payables-stage.aionfi.com",
    opsBaseUrl: "https://ops-stage.aionfi.com",
    creditRiskBaseUrl: "https://credit-risk-stage.aionfi.com",
    integrationBaseUrl: "https://int-stage.aionfi.com",
    qboOAuth: {
        clientId: 'Q0AsO9sHWTn0anCPlnoVxorfqvaOCoVAajAT3ypJparh2QYUSt',
        clientSecret: 'in8g6eKCkTL1AVX7oEh0q3oOxzivbXXOiDvzlnQf',
        environment: 'sandbox',
        redirectUri: 'http://localhost:3000/callback'
    },
    plaid: {
        publicKey: '8190ebb0655fb8f3806b6d558e4bd3',
        product: ["transactions"],
        env: 'sandbox',
        webhook: 'https://node.aionfi.com/plaidwebhook'
    },
    yodleeUrl: 'https://fl4.sandbox.yodlee.com/authenticate/restserver/fastlink',
    yodleeConfigName: 'Verification',
    billsDomain: "staging-bills.aionfi.com",
    bankProvider: { crb: "CrossRiverBank", tp: "TreasuryPrime" },
    defaultBankProvider: "TreasuryPrime",
    hubspotTrackingScript: "//js.hs-scripts.com/21352997.js",
    vouchedKey: "i~rg15tI6mn!Pi1_nrfV0xi3*gzqaq",
    hellosignClientKey:"755f602c078cee9b02b30f4b46f36ffc",
    cognitoConfig: {
        region: 'us-west-2',
        userPoolId: 'us-west-2_QD58SMRbU',
        userPoolWebClientId: '5tjd7i6mc8oda4jn55qhbnme2m',
        authenticationFlowType: 'USER_SRP_AUTH',
    },
    showAutofill: false,
    vgs: {
        vaultID: "tnt8xvoyjyw",
        env: "SANDBOX"
    },
    isSandbox: true,
    defaultDateFormat: 'MMM D, YYYY',
    etTimeZone: "America/New_York"
}

switch (process.env.REACT_APP_ENV) {
    case "development":
        // No changes from default
        break
    case "qa":
        /*environment.apiBaseUrl = "https://dev.aionfi.com"
        environment.iveBaseUrl = "https://ive-qa.aionfi.com"
        environment.uamBaseUrl = "https://uam-qa.aionfi.com"
        environment.bbBaseUrl = "https://bb-qa.aionfi.com"//"http://localhost:5000"
        environment.bpBaseUrl = "https://bp-qa.aionfi.com"//"http://localhost:5010"
        environment.obpBaseUrl = "https://obp-qa.aionfi.com"
        environment.lsmBaseUrl = "https://lsm-qa.aionfi.com"
        environment.payBaseUrl = "https://payables-qa.aionfi.com"
        environment.integrationBaseUrl = "https://int-qa.aionfi.com"
        environment.opsBaseUrl = "https://ops-qa.aionfi.com"
        environment.qboOAuth = {
            clientId: 'Q055ydaSFXR4ayoDvgkpIJM9v3paK6rssa5O5bUy6valB01ioi',
            clientSecret: 'TT5RZAzCX9DB1ihbcLNN4euF4xdWhLE093h5KMNi',
            environment: 'production',
            redirectUri: 'https://qa.aionfi.com/callback'
        }
        environment.plaid = {
            publicKey: '8190ebb0655fb8f3806b6d558e4bd3',
            product: ["transactions"],
            env: 'sandbox',
            webhook: 'https://dev.aionfi.com/plaidwebhook'
        }
        environment.aionBusinessId = ['01bc7a74c28a68ccd69ce7514702489c6a56f014-arcadia-demo', '9df51081-9fc7-44e3-a522-f1d834b2b1dc', 's0u54f14zex6k166e3tsdffdp4zeuhyp', 'e12195f2-1909-4690-8087-399b304c50a1', '03cd831c-f8ab-446a-bd62-655a5712d41a'];
        environment.showAutofill = true;
        break*/
    case "qa-stage":
        environment.uiBaseUrl = "ui-qa.cloud.aionfi.com"
        environment.apiBaseUrl = "https://node-qa.cloud.aionfi.com"
        // environment.iveBaseUrl = "http://localhost:5000"
        environment.iveBaseUrl = "https://ive-qa.cloud.aionfi.com"
        environment.uamBaseUrl = "https://uam-qa.cloud.aionfi.com"
        environment.bbBaseUrl = "https://bb-qa.cloud.aionfi.com"
        environment.bbxBaseUrl = "https://bbx-qa.cloud.aionfi.com"
        environment.bpBaseUrl = "https://bp-qa.cloud.aionfi.com"
        environment.obpBaseUrl = "https://obp-qa.cloud.aionfi.com"
        environment.lsmBaseUrl = "https://lsm-qa.cloud.aionfi.com"
        environment.payBaseUrl = "https://payables-qa.cloud.aionfi.com"
        environment.integrationBaseUrl = "https://integration-qa.cloud.aionfi.com"
        environment.opsBaseUrl = "https://ops-qa.cloud.aionfi.com"
        environment.parserUrl = "https://parser-qa.cloud.aionfi.com/parser/aws/textract"
        environment.qboOAuth = {
            clientId: 'Q055ydaSFXR4ayoDvgkpIJM9v3paK6rssa5O5bUy6valB01ioi',
            clientSecret: 'TT5RZAzCX9DB1ihbcLNN4euF4xdWhLE093h5KMNi',
            environment: 'production',
            redirectUri: 'https://ui-qa.cloud.aionfi.com/callback'
        }
        environment.plaid = {
            publicKey: '8190ebb0655fb8f3806b6d558e4bd3',
            env: 'sandbox',
        }
        environment.yodleeUrl = 'https://fl4.sandbox.yodlee.com/authenticate/restserver/fastlink';
        environment.yodleeConfigName = 'Verification'
        environment.aionBusinessId = ['01bc7a74c28a68ccd69ce7514702489c6a56f014-arcadia-demo', '9df51081-9fc7-44e3-a522-f1d834b2b1dc', 's0u54f14zex6k166e3tsdffdp4zeuhyp', 'e12195f2-1909-4690-8087-399b304c50a1', '03cd831c-f8ab-446a-bd62-655a5712d41a']
        // environment.cognitoConfig.userPoolId = "us-west-2_khuQxxOnK"
        // environment.cognitoConfig.userPoolWebClientId = "48p9qut3jghnt21je201fl7isb"
        environment.showAutofill = true;
        break
    case "staging":
        environment.uiBaseUrl = "staging.aionfi.com"
        environment.apiBaseUrl = "https://node-stage.aionfi.com"
        environment.iveBaseUrl = "https://ive-stage.aionfi.com" // No changes from default
        environment.uamBaseUrl = "https://uam-stage.aionfi.com" // No changes from default
        environment.bbBaseUrl = "https://bb-stage.aionfi.com" // No changes from default
        environment.bbxBaseUrl = "https://bbx-stage.aionfi.com"
        environment.bpBaseUrl = "https://bp-stage.aionfi.com"
        environment.obpBaseUrl = "https://obp-stage.aionfi.com"
        environment.integrationBaseUrl = "https://int-stage.aionfi.com"
        environment.parserUrl = "https://parser-stage.aionfi.com/parser/aws/textract"
        environment.qboOAuth = {
            clientId: 'Q055ydaSFXR4ayoDvgkpIJM9v3paK6rssa5O5bUy6valB01ioi',
            clientSecret: 'TT5RZAzCX9DB1ihbcLNN4euF4xdWhLE093h5KMNi',
            environment: 'production',
            redirectUri: 'https://staging.aionfi.com/callback'
        }
        environment.plaid = {
            publicKey: '8190ebb0655fb8f3806b6d558e4bd3',
            product: ["transactions"],
            env: 'sandbox',
            webhook: 'https://int-stage.aionfi.com/integration/plaid/plaidEvents'
        }
        environment.yodleeUrl = 'https://fl4.sandbox.yodlee.com/authenticate/restserver/fastlink';
        environment.yodleeConfigName = 'Verification';
        environment.showAutofill = false;
        break
    case "production":
        environment.uiBaseUrl = "app.aionfi.com"
        environment.apiBaseUrl = "https://node.aionfi.com"
        environment.iveBaseUrl = "https://ive.aionfi.com"
        environment.uamBaseUrl = "https://uam.aionfi.com"
        environment.bbBaseUrl = "https://bb.aionfi.com"
        environment.bbxBaseUrl = "https://bbx.aionfi.com"
        environment.bpBaseUrl = "https://bp.aionfi.com"
        environment.obpBaseUrl = "https://obp.aionfi.com"
        environment.lsmBaseUrl = "https://lsm.aionfi.com"
        environment.payBaseUrl = "https://payables.aionfi.com"
        environment.opsBaseUrl = "https://ops.aionfi.com"
        environment.creditRiskBaseUrl = "https://credit-risk.aionfi.com"
        environment.integrationBaseUrl = "https://int.aionfi.com"
        environment.parserUrl = "https://parser.aionfi.com/parser/aws/textract"
        environment.qboOAuth = {
            clientId: 'Q055ydaSFXR4ayoDvgkpIJM9v3paK6rssa5O5bUy6valB01ioi',
            clientSecret: 'TT5RZAzCX9DB1ihbcLNN4euF4xdWhLE093h5KMNi',
            environment: 'production',
            redirectUri: 'https://app.aionfi.com/callback'
        }
        environment.plaid = {
            publicKey: '8190ebb0655fb8f3806b6d558e4bd3',
            product: ["transactions"],
            env: 'production',
            webhook: 'https://int.aionfi.com/integration/plaid/plaidEvents'
        }
        environment.yodleeUrl = "https://fl4.prod.yodlee.com/authenticate/USDevexProd4-237/fastlink?channelAppName=usdevexprod4";
        environment.yodleeConfigName = 'Yodlee_Dev'
        environment.hellosignClientKey = "e9b73d3b18558638dac76368fd568ddb";
        environment.billsDomain = "bills.aionfi.com"
        environment.hubspotTrackingScript = "//js.hs-scripts.com/20887819.js"
        environment.vgs.vaultID = "tntirlugtdy";
        environment.vgs.env = "live";
        environment.isSandbox = false;
        environment.showAutofill = false;
        break
    case "prod03":
        environment.apiBaseUrl = "https://node-prod03.prod.aionfi.com"
        environment.iveBaseUrl = "https://ive-prod03.prod.aionfi.com"
        environment.uamBaseUrl = "https://uam-prod03.prod.aionfi.com"
        environment.bbBaseUrl = "https://bb-prod03.prod.aionfi.com"
        environment.bbxBaseUrl = "https://bbx-prod03.prod.aionfi.com"
        environment.bpBaseUrl = "https://bp-prod03.prod.aionfi.com"
        environment.obpBaseUrl = "https://obp-prod03.prod.aionfi.com"
        environment.lsmBaseUrl = "https://lsm-prod03.prod.aionfi.com"
        environment.payBaseUrl = "https://payables-prod03.prod.aionfi.com"
        environment.integrationBaseUrl = "https://integration-prod03.prod.aionfi.com"
        environment.opsBaseUrl = "https://ops-prod03.prod.aionfi.com"

        environment.qboOAuth = {
            clientId: 'Q055ydaSFXR4ayoDvgkpIJM9v3paK6rssa5O5bUy6valB01ioi',
            clientSecret: 'TT5RZAzCX9DB1ihbcLNN4euF4xdWhLE093h5KMNi',
            environment: 'production',
            redirectUri: 'https://ui-prod03.prod.aionfi.com/callback'
        }
        environment.plaid = {
            publicKey: '8190ebb0655fb8f3806b6d558e4bd3',
            product: ["transactions"],
            env: 'production',
            webhook: 'https://int.aionfi.com/integration/plaid/plaidEvents'
        }
        environment.yodleeUrl = "https://fl4.prod.yodlee.com/authenticate/USDevexProd4-237/fastlink?channelAppName=usdevexprod4";
        environment.yodleeConfigName = 'Yodlee_Dev'
        environment.hellosignClientKey = "e9b73d3b18558638dac76368fd568ddb";
        environment.billsDomain = "bills.aionfi.com"
        environment.hubspotTrackingScript = "//js.hs-scripts.com/20887819.js"
        environment.vgs.vaultID = "tntirlugtdy";
        environment.vgs.env = "live";
        environment.isSandbox = false;
        environment.showAutofill = false;
        break
    default:
        break
}

export default environment;