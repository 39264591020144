import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';

import { Select, Skeleton, Tooltip } from 'antd';
import { Text, Tag } from '../../Reusable/Text';
import { FlexColumn, } from '../../Reusable/Container';
import { LabeledInput } from '../../Reusable/Input';
import { ErrorAlert } from '../../Reusable/Alert';

import environment from '../../../environment';
import { apiPOSTReq } from '../../../Utils/api';
import { ExpenseCategory } from '../../Reusable/ExpenseCategory';
import { InfoCircleOutlined } from '@ant-design/icons';

const { Option } = Select;

const priorityMap = {
    "Accounts Payable": 1,
    "Accounts Receivable": 2,
    "Bank": 0,
    "Cost of Goods Sold": 3,
    "Credit Card": 4,
    "Equity": 5,
    "Expense": 6,
    "Fixed Asset": 7,
    "Income": 8,
    "Long Term Liability": 9,
    "Other Asset": 10,
    "Other Current Asset": 11,
    "Other Current Liability": 12,
    "Other Expense": 13,
    "Other Income": 14,
}

class MapAionCOA extends Component {
    state = {
        pagination: {
            current: 1,
            pageSize: 1000,
        },
        pairPagination: {
            current: 1,
            pageSize: 1000,
        },
        customCOA: [],
        accountCOA: this.props.accountCOA || {}
    };

    componentDidMount() {
        this.fetchCOA({ pagination: this.state.pagination });
    }

    fetchCOA = ({ pagination, searchStr }) => {
        const headers = {
            AionCurrentBiz: this.props.aionStore.BusinessUniqueKey,
            AionAuth: this.props.aionStore.UAM.encryptedAuthHeader
        }

        const body = {
            "BusinessId": this.props.aionStore.BusinessUniqueKey,
            "size": pagination.pageSize,
            "page": pagination.current - 1,
            "ActiveRecords": true,
            "sortFieldName": "Code",
            "sortDirection": "ASC",
            "sources": ["QUICKBOOKS"],
        }
        if (searchStr) {
            body.searchStr = searchStr;
        }
        this.setState({ loading: true, });

        apiPOSTReq(`${environment.bbBaseUrl}/bk/getCategories`, headers, body, (err, resp) => {
            try {
                const data = resp;
                console.log('/bk/getCategories', data)
                if (data.result) {
                    this.setState({
                        loading: false,
                        customCOA: data.customCOAList ? data.customCOAList.sort((a, b) => {
                            if (priorityMap[a.coatype] < priorityMap[b.coatype]) return -1;
                            else return 1;
                        }).map(x => {
                            return {
                                ...x, key: x.id,
                            }
                        }) : [],
                    })
                    if (!searchStr) {
                        this.setState({
                            allCustomCOA: data.customCOAList ? data.customCOAList.sort((a, b) => {
                                if (priorityMap[a.coatype] < priorityMap[b.coatype]) return -1;
                                else return 1;
                            }).map(x => {
                                return {
                                    ...x, key: x.id,
                                }
                            }) : [],
                        })
                    }
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getActiveCustomers", error.stack, resp);
                ErrorAlert({ description: error.message })
            }
        })
    }

    handleSearch = value => {
        this.fetchCOA({ pagination: this.state.pagination, searchStr: value })
    }

    handleSelect = (accountId, coaId) => {
        const accountToMap = this.props.coaToSetup.find(x => x.id === accountId);
        const newAccount = this.state.customCOA.find(x => x.id === coaId);


        if (!accountToMap || !newAccount) {
            ErrorAlert({ description: "Error making selection" });
            return;
        }

        if ((accountToMap.coatype !== 'Accounts Payable' && (newAccount.coatype === 'Accounts Payable'))) {
            ErrorAlert({ description: "Can't map a non AP account to an AP account" });
            let accountCOA = this.state.accountCOA;
            delete accountCOA[accountId];
            this.setState({ accountCOA })
            this.props.saveUserInput("accountCOA", accountCOA)
            return;
        }
        if ((accountToMap.coatype !== 'Accounts Receivable' && newAccount.coatype === 'Accounts Receivable')) {
            ErrorAlert({ description: "Can't map a non AR account to an AR account" });
            let accountCOA = this.state.accountCOA;
            delete accountCOA[accountId];
            this.setState({ accountCOA })
            this.props.saveUserInput("accountCOA", accountCOA)
            return;
        }

        let accountCOA = this.state.accountCOA;
        accountCOA[accountId] = coaId;
        this.setState({ accountCOA, customCOA: this.state.allCustomCOA })
        this.props.saveUserInput("accountCOA", accountCOA)
    }

    render() {
        const { theme } = this.props;

        return (
            <>
                <Text weight={600} >Map your Aion account to your QuickBooks Online Account
                    <Tooltip color="#000000d9" title="Mapping your Aion Expense and Income accounts to your QuickBooks Online GL accounts is required to sync your existing bills and products on Aion with QuickBooks Online">
                        <InfoCircleOutlined style={{ marginLeft: '10px' }} />
                    </Tooltip>
                </Text>


                <FlexColumn style={{ marginBottom: '30px', marginTop: '20px' }}>
                    <table>
                        <thead style={{ borderBottomStyle: 'solid', borderBottom: '1px solid rgba(0, 0, 0, 0.06)' }}>
                            <tr>
                                <th style={{ paddingBottom: '5px' }}><Text>Aion accounts</Text></th>
                                <th style={{ paddingBottom: '5px', width: '50%' }}><Text>QuickBooks accounts</Text></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.coaToSetup.map(account => {
                                return <tr>
                                    <td>
                                        <span>
                                            {account.code && <Tag style={{ marginRight: 10 }} tagpreset={theme.colors.secondary4}>{account.code}</Tag>}
                                            <b>{account.categoryName}</b></span>
                                    </td>
                                    <td>
                                        <div style={{ width: '100%' }}>
                                            <LabeledInput
                                                label=""
                                                labelcolor={theme.colors.secondary3}
                                                id="category"
                                                key="category"
                                                type="remote-search"
                                                className="no-left-padding"
                                                placeholder="Select GL Account"
                                                value={this.state.accountCOA[account.id] || null}
                                                onChange={(val) => this.handleSelect(account.id, val)}
                                                onSearch={this.handleSearch}
                                            >
                                                {this.state.customCOA && this.state.customCOA.map(category => (
                                                    <Option key={category.id} id="category" name="category" value={category.id}> <ExpenseCategory category={category} theme={theme} /></Option>)
                                                )}
                                            </LabeledInput>
                                        </div>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </FlexColumn>

            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer,
        bankingStore: state.bankingAppReducer,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(MapAionCOA));