import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'
import moment from 'moment-business-days'

import { Modal, Select } from 'antd'

// Components
import { Button, ImageButton, TextButton } from '../../Reusable/Button'
import { Text } from '../../Reusable/Text'
import { Image } from '../../Reusable/Image'
import { FlexColumn, Flex, LineItemsHeader, LineItemRow, CardContainer, InfoCard } from '../../Reusable/Container'
import { LabeledInput, GradientDivider } from '../../Reusable/Input'
import { ErrorAlert } from '../../Reusable/Alert'
import FlowFooter from '../../Reusable/FlowFooter'
import { ExpenseCategory } from '../../Reusable/ExpenseCategory'
import NewInboxVendor from '../Vendors/Create Vendor/NewInboxVendor'
import environment from '../../../environment'
import { apiPOSTReq } from '../../../Utils/api'
import { dateFormatList, toAbsCurrency, toCurrency } from '../../../Utils/util'
import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons'
import DeleteIcon from '../../../Images/delete.svg'
import AI from '../../../Images/AI.svg'
import Add from '../../../Images/add.svg'
import _, { initial, iteratee, random } from 'underscore'
import TableView from '../../Reusable/TableView'
import hexToRgba from 'hex-to-rgba'
import VendorCard from '../Vendors/VendorCard'

const { Option } = Select

const priorityMap = {
    "Accounts Payable": 2,
    "Accounts Receivable": 3,
    "Bank": 4,
    "Cost of Goods Sold": 5,
    "Credit Card": 6,
    "Equity": 7,
    "Expense": 0,
    "Fixed Asset": 8,
    "Income": 9,
    "Long Term Liability": 10,
    "Other Asset": 11,
    "Other Current Asset": 12,
    "Other Current Liability": 13,
    "Other Expense": 1,
    "Other Income": 14,
}

class BillDetail extends Component {

    state = {
        bill: this.props.bill || { billRecipientEmail: [], line: [] },
        fileList: this.props.bill && this.props.bill.receipts,
        vendor: this.props.vendor,
        vendors: this.props.vendors || [],
        allVendors: this.props.vendors,
        selectedVendor: this.props.vendor || {},
        loading: false,
        pagination: {
            current: 1,
            pageSize: 100
        },
        categories: [],
        reloaded: false,
        showAddVendorMask: true,
        secondaryCTALoading: false,
        editMode: this.props.edit || this.props.newBill || false,
        newBill: this.props.newBill,
        descriptionValues: {},
        vendorCardLoading: true,
        existingVendor: this.props.newBill || false,
        initialInvoiceNo: (this.props.bill || {}).invoiceNumber
    }

    componentDidMount() {
        var { bill } = this.state
        this.fetchCOA({})
        if (!bill.line || bill.line.length === 0) this.addLineItemRow()
        else bill.line.forEach((line, i) => { if (!line.itemId) line.itemId = i })
        this.fetchVendors()

        if (bill.vendorId && this.props.edit) {
            if (bill.vendorId && !this.state.vendor) this.getVendor(bill.vendorId)
        } else {
            var parsedInvoice = this.props.parsedInvoice
            var parsedVendor = { type: "Business", billRecipientEmail: [], vendorName: (parsedInvoice || {}).clientCompanyName }
            if (parsedInvoice && parsedInvoice.vendorReference) {
                parsedVendor = { ...parsedVendor, ...parsedInvoice.vendorReference }
            }
            this.setState({ parsedVendor, newVendor: !this.props.selectedVendor && parsedVendor.vendorName, bill: bill })
            if (bill.vendorId && !this.state.vendor) this.getVendor(bill.vendorId)
            else if (parsedVendor.existingVendor) {
                this.setState({ existingVendor: true, newVendor: false })
                this.getVendor(parsedVendor.id)
            }
        }

    }

    componentDidUpdate() {
        var { bill } = this.props;
        if (!this.state.reloaded) {
            this.setState({ bill: bill, reloaded: true })
        }
    }

    getVendor = (id) => {
        apiPOSTReq(`${environment.payBaseUrl}/payables/vendors/getVendor`, {}, { vendorId: id }, (err, resp) => {
            try {
                const data = resp
                if (data.result && data.vendor) {
                    this.setState({ vendor: data.vendor, selectedVendor: data.vendor })
                    this.getVendorBills(id)
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /payables/vendors/getVendor", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    getVendorBills = (id) => {
        apiPOSTReq(`${environment.payBaseUrl}/payables/vendors/getVendorBills`, {}, { vendorId: id }, (err, resp) => {
            try {
                const data = resp || {}
                if (data.result) {
                    this.setState({ vendorBills: data.bills, unpaidBillsCount: data.count, totalBillsCount: data.totalBillsCount, paidBillsCount: data.paidBillsCount, vendorCardLoading: false })
                } else {
                    this.setState({ vendorCardLoading: false })
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /payables/vendors/getVendorBills", error, err, resp)
            }
        })
    }

    addLineItemRow = () => {
        let bill = this.state.bill
        let billLine = bill.line
        if (!billLine || billLine.length === 0) {
            billLine = []
        }

        bill.line = billLine.concat([{
            itemId: billLine.length,
            lineNum: billLine.length,
            unsavedItem: true
        }])
        this.setState({ bill })
    }

    removeLineItemRow = (id) => {
        var { bill } = this.state
        if (!bill.line || bill.line.length <= 1) {
            bill.line = []
            this.setState({ bill })
            return
        }
        bill.line.splice(id, 1)
        this.setState({ bill })
    }

    saveInput = (id, dataToSave) => {
        var { bill } = this.state

        bill[id] = dataToSave
        this.setState({ bill })
    }

    handleBillTextChange = (event) => {
        this.saveInput(event.target.id, event.target.value)
    }

    handleSelect = (value, option) => {
        var { bill, allVendors, selectedVendor } = this.state

        var { initialUpdate } = option

        if (initialUpdate && selectedVendor) {
            vendor = selectedVendor
        }
        else {
            var vendor = allVendors.find(obj => {
                return obj.id === value
            })
        }

        bill.vendorId = vendor.id

        this.getVendorBills(vendor.id)

        if (vendor.expenseAccountIds) {

            let line = bill.line

            for (let index = 0; index < line.length; index++) {
                const stringValue = vendor.expenseAccountIds[Math.min(index, vendor.expenseAccountIds.length - 1)];
                var category = this.state.categories.find(item => item.id === stringValue)

                if (category && !(line[index].accountBasedExpenseLineDetail || {}).accountRef) {
                    line[index].accountBasedExpenseLineDetail = {
                        accountRef: {
                            value: category.id,
                            name: category.categoryName,
                        }
                    }
                }
                else if (!line[index].accountBasedExpenseLineDetail) {
                    line[index].accountBasedExpenseLineDetail = {}
                }
            }

            bill.line = line

        }
        this.setState({ vendor, selectedVendor: vendor, bill })
    }

    handleSelectCategory = (value, index) => {
        var { bill } = this.state
        const { categories } = this.state
        var category = categories.find(item => item.id === value)

        var line = bill.line || []
        if (line.length <= index) {
            return
        }
        var selectedItem = line[index]
        if (selectedItem) {
            selectedItem.accountBasedExpenseLineDetail = {
                accountRef: {
                    value: category.id,
                    name: category.categoryName,
                }
            }
        } else {
            line.push({
                itemId: index,
                accountBasedExpenseLineDetail: {
                    accountRef: {
                        value: category.id,
                        name: category.categoryName,
                    }
                }
            })
        }
        this.setState({ bill })
        this.updateTotals()
    }

    handleLineItemTextChange = (event, index) => {
        var { bill } = this.state
        var line = bill.line || []
        if (line.length <= index) {
            return
        }
        var selectedItem = line[index]
        if (selectedItem) {
            selectedItem[event.target.id] = event.target.value
        } else {
            line.push({
                "itemId": index,
                [event.target.id]: event.target.value
            })
        }
        this.setState({ bill })
        this.updateTotals()
    }

    getLineItemTotals = () => {
        var { bill } = this.state
        const reducer = (accumulator, item) => accumulator + parseFloat(item.amount || 0)
        var total = (bill.line || []).reduce(reducer, 0)
        return { totalAmt: total }
    }

    updateTotals = () => {
        var { bill } = this.state
        var totals = this.getLineItemTotals()
        bill.totalAmt = totals.totalAmt || 0
        bill.balance = bill.totalAmt

        this.setState({ bill })
    }

    handleFileUpload = (fileList) => {
        var { bill } = this.state

        bill.receipts = (bill.receipts || []).concat(fileList)

        bill.receipts = fileList

        this.setState({ bill })
    }

    onSearch = (value) => {
        var { pagination, vendors, allVendors } = this.state

        if (!value) { //this.state.vendorSearch) {
            return this.fetchData({ pagination: this.state.pagination })
        }

        const body = {
            "size": pagination.pageSize,
            "page": pagination.current - 1
        }

        body.searchStr = value // this.state.vendorSearch

        // this.setState({ loading: true })
        apiPOSTReq(`${environment.payBaseUrl}/payables/vendors/lookupVendors`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            this.setState({ loading: false })
            try {
                const data = resp
                console.log("/lookupVendors", JSON.stringify(data))
                if (data.result) {

                    data.vendors.forEach(vendor => {
                        if (allVendors.filter(v => v.id === vendor.id).length === 0) {
                            allVendors.push(vendor)
                        }
                    })

                    this.setState({
                        vendors: data.vendors,
                        allVendors: allVendors,
                        pagination: {
                            ...pagination,
                            total: data.count
                        },
                    })

                    console.log("lookupVendors", JSON.stringify(this.state.allVendors))
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getContractors", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    handleSearch = value => {
        if (value) {
            this.onSearch(value)
        } else {
            this.setState({ vendors: this.state.allVendors })
        }
    }

    fetchVendors = () => {
        const body = {
            "viewBy": "FILTER_ACTIVE",
        }

        apiPOSTReq(`${environment.payBaseUrl}/payables/vendors/getVendors`, {}, body, (err, resp) => {
            try {
                const data = resp
                console.log("/payables/vendors/getVendors New Bill", (data))
                if (data.result) {
                    this.setState({ vendors: data.vendors || [], allVendors: data.vendors || [], addVendor: false })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /payables/vendors/getVendors", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    fetchCOA = ({ searchStr }) => {
        const headers = {
            AionCurrentBiz: this.props.store.BusinessUniqueKey,
            AionAuth: this.props.store.UAM.encryptedAuthHeader
        }

        const body = {
            "BusinessId": this.props.store.BusinessUniqueKey,
            "size": 1000,
            "page": 0,
            "ActiveRecords": true,
            "sortFieldName": "Code",
            "sortDirection": "ASC"
        }
        if (searchStr) {
            body.searchStr = searchStr
        }

        apiPOSTReq(`${environment.bbBaseUrl}/bk/getCategories`, headers, body, (err, resp) => {
            try {
                const data = resp
                console.log("/bk/getCategories", data)
                if (data.result) {
                    const customCOAList = data.customCOAList.filter(x => x.coatype !== 'Accounts Payable' && x.coatype !== 'Accounts Receivable')
                    let categories = (data.systemCOAList || []).concat(customCOAList)
                    this.setState({
                        categories: categories ? categories.sort((a, b) => {
                            if (priorityMap[a.coatype] < priorityMap[b.coatype]) return -1
                            else return 1
                        }).map(x => {
                            return {
                                ...x, key: x.id,
                            }
                        }) : [],
                    })
                    if (this.state.categories && this.state.selectedVendor) {
                        this.handleSelect((this.state.selectedVendor || {}).id, { initialUpdate: true })
                    }
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getActiveCustomers", error.stack, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    handleSearchCOA = value => {
        this.fetchCOA({ searchStr: value })
    }

    createVendor = (options) => {
        var { parsedVendor } = this.state
        parsedVendor["id"] = null // it should update an existing vendor
        const body = {
            "vendor": parsedVendor
        }
        apiPOSTReq(`${environment.payBaseUrl}/payables/vendors/save`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            try {
                const data = resp || {}
                if (data.result) {
                    // Complete saving the bill
                    this.setState({ vendor: data.vendor, selectedVendor: data.vendor, newVendor: false })
                    this.submit(options)
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                this.setState({ loading: false, secondaryCTALoading: false })
                console.log("ERRR /payables/vendors/save", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    submit = (options) => {
        var { payLater } = options
        const { bill, vendor, parsedVendor, selectedVendor, newVendor, existingVendor } = this.state

        if (!bill.invoiceNumber) {
            this.setState({ errorField: 'billNumber' })
            return
        }

        if (!bill.amount) {
            this.setState({ errorField: 'amount' })
            ErrorAlert({ description: "The bill amount has not been fully allocated to expense items. Please add or update the expense account allocation." })
            return
        }

        if (!bill.billDate) {
            this.setState({ errorField: 'billDate' })
            return
        }

        if (!bill.dueDate) {
            this.setState({ errorField: 'dueDate' })
            return
        }

        if (!bill.line) {
            ErrorAlert({ description: "Please add a line for this bill." })
            return
        }

        if (bill.amount === 0) {
            ErrorAlert({ description: "Please add amount for this bill." })
            return
        }

        let amountSum = this.getLineItemTotals().totalAmt
        if (amountSum) {
            amountSum = parseFloat(amountSum)
            amountSum = amountSum.toFixed(2)
        }

        let billAmt = bill.amount
        if (billAmt) {
            billAmt = parseFloat(billAmt)
            billAmt = billAmt.toFixed(2)
        }

        if (billAmt != amountSum) {
            ErrorAlert({ description: "The bill amount has not been fully allocated to expense items. Please add or update the expense account allocation." })
            return
        }

        for (let i = 0; i < bill.line.length; i++) {
            if (!bill.line[i].accountBasedExpenseLineDetail) {
                ErrorAlert({ description: "Please select an expense account for all lines." })
                return
            }
            bill.line[i].detailType = "AccountBasedExpenseLineDetail"
        }

        if (newVendor && _.isEmpty((parsedVendor || {}).vendorName)) {
            this.setState({ errorField: 'vendor' })
            return
        }

        if (!(selectedVendor || existingVendor) || newVendor) {
            // Create a new vendor
            if (payLater) {
                this.setState({ secondaryCTALoading: true }, () => this.createVendor(options))
            } else {
                this.setState({ loading: true }, () => this.createVendor(options))
            }
            return
        }

        if (!selectedVendor) {
            this.setState({ errorField: 'vendor' })
            return
        }

        if (payLater) {
            this.setState({ secondaryCTALoading: true }, () => this.props.onSave(bill, selectedVendor))
        } else {
            this.setState({ loading: true }, () => this.props.onSubmit(bill, selectedVendor, () => this.setState({ loading: false })))
        }
    }

    checkForIncompleteCOA = () => {
        var filteredCOAs = (this.state.bill.line || []).filter(line => line.accountBasedExpenseLineDetail ? line.accountBasedExpenseLineDetail.accountRef : false)
        // Return true if all line items have not been categorized and atleast one should be categorized
        return ((filteredCOAs.length != this.state.bill.line.length) && filteredCOAs.length > 0)
    }

    applyCOAToAll = (coa) => {
        (this.state.bill.line || []).forEach(line => {
            if (line.accountBasedExpenseLineDetail) line.accountBasedExpenseLineDetail.accountRef = coa
            else line.accountBasedExpenseLineDetail = { accountRef: coa }
        })
        this.setState({ bill: this.state.bill })
    }

    getColumns = () => {
        var { categories, editMode, bill } = this.state
        var { theme } = this.props
        var columns = [
            {
                title: 'Expense Account',
                dataIndex: 'category',
                width: 260,
                render: (category, lineItem, i) => {
                    var { accountBasedExpenseLineDetail } = lineItem
                    const coa = accountBasedExpenseLineDetail ? accountBasedExpenseLineDetail.accountRef || {} : {}
                    return (
                        <div>
                            {
                                (editMode || !coa.value) ?
                                    <LabeledInput
                                        nomargin
                                        id="category"
                                        key="category"
                                        type="select"
                                        className="no-left-padding"
                                        placeholder="Select account"
                                        defaultValue={coa.name}
                                        onChange={e => this.handleSelectCategory(e, i)}
                                        // onSearch={this.handleSearchCOA}
                                        noBorder={!editMode}
                                        showSearch
                                        filterOption={(input, option) => {
                                            return option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }}
                                    >
                                        {
                                            categories && categories.filter(category => !category.coatype.includes('Liability')).map(category => (
                                                <Option key={category.id} id="category" name={category.categoryName} value={category.id}>
                                                    <ExpenseCategory selected={coa.value == category.id} category={category} hideCategory={true} theme={theme} />
                                                </Option>
                                            ))
                                        }
                                    </LabeledInput>
                                    :
                                    <LabeledInput
                                        nomargin
                                        id="category"
                                        key={"category" + coa.name}
                                        type={"read-only"}
                                        className="no-left-padding"
                                        value={coa.name}
                                    />
                            }
                            {
                                // Show this option if there is more than one line
                                (coa.value) && (i == 0 && bill.line.length > 1) && this.checkForIncompleteCOA() &&
                                <Text light><a onClick={() => this.applyCOAToAll(coa)}>Apply this</a> to all line items</Text>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Description',
                dataIndex: 'description',
                render: (description, lineItem, i) => (
                    <div style={{ flexGrow: 1 }}>
                        {
                            editMode ?
                                <LabeledInput
                                    nomargin
                                    type={'text-area'}
                                    id="description"
                                    key={i + "description"}
                                    placeholder="Add text"
                                    autoSize={{ minRows: 1, maxRows: 3 }}
                                    // onChange={e => this.handleLineItemTextChange(e, i)}
                                    defaultValue={description || ''}
                                    onBlur={(e) => this.handleLineItemTextChange(e, i)}
                                    minHeight='42px'
                                />
                                :
                                <LabeledInput
                                    nomargin
                                    type={"read-only"}
                                    key={i + "description"}
                                    value={description || ''}
                                    minHeight='42px'
                                />
                        }
                    </div>
                )
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
                align: 'right',
                width: '185px',
                render: (amount, lineItem, i) => (
                    editMode ?
                        <LabeledInput
                            nomargin
                            id="amount"
                            key={i + "amount"}
                            placeholder="5000"
                            prefixText="$"
                            alignRight
                            defaultValue={amount}
                            onBlur={e => this.handleLineItemTextChange(e, i)}
                        />
                        :
                        <LabeledInput
                            nomargin
                            type={"read-only"}
                            key={i + "amount"}
                            prefixText="$"
                            alignRight
                            value={`$${toCurrency(amount)}`}
                        />
                )
            }
        ]
        if (editMode) columns.push({
            title: '',
            dataIndex: 'action',
            render: (action, lineItem, i) => (
                bill.line.length > 1 &&
                <img width='24px' height='24px' style={{ cursor: 'pointer' }} src={DeleteIcon} onClick={() => this.removeLineItemRow(i)} />
            ),
            width: editMode ? '24px' : '0px'
        })
        return columns
    }

    render() {
        var { theme, upload, buttonText, inboxItem, parsedInvoice, onSave, edit } = this.props
        var { loading, vendors, selectedVendor, categories, bill, showAddVendor, showAddVendorMask, errorField, secondaryCTALoading, existingVendor, parsedVendor, newVendor, editMode, applyCOAToAll, showNote, initialInvoiceNo } = this.state
        var { invoiceNumber, amount, dueDate, notes, billDate, subTotal, discount, tax, line, receipts } = bill
        categories = categories.filter(x => x.coatype !== 'Accounts Payable' && x.coatype !== 'Accounts Receivable')
        if (billDate) {
            billDate = new Date(billDate).toISOString()
            billDate = billDate.split('T')[0]
        }

        if (dueDate) {
            dueDate = new Date(dueDate).toISOString()
            dueDate = dueDate.split('T')[0]
        }
        const dateFormat = environment.defaultDateFormat
        if (_.isEmpty(selectedVendor)) selectedVendor = null // handles selectedVendor = {}
        if (existingVendor == undefined) existingVendor = null
        var vendorName = selectedVendor ? selectedVendor.vendorName : (parsedVendor ? parsedVendor.vendorName : '')
        return (
            <>
                <FlexColumn between fullWidth grow>
                    <div radius='8px' padding='0' style={{ marginBottom: 24 }} grow fullWidth>
                        <FlexColumn between fullHeight>
                            <FlexColumn start style={{ padding: '0px 24px 24px' }} gap="8px" fullWidth>
                                <FlexColumn gap='4px'>
                                    {edit && <Text margin='0 0 20px 0' heading>Edit Bill {invoiceNumber}</Text>}
                                    {
                                        !editMode &&
                                        <Text size='30px' weight='600' height='40px'>You've received a new invoice from <span style={{ color: hexToRgba(theme.colors.primary2, 0.6) }}>{vendorName}</span> totaling <span style={{ color: hexToRgba(theme.colors.primary2, 0.6) }}>${toAbsCurrency(amount)}</span>{dueDate && <>. Payment Due <span style={{ color: hexToRgba(theme.colors.primary2, 0.6) }}>{moment(dueDate).format(environment.defaultDateFormat)}</span></>}</Text>
                                    }
                                    {
                                        parsedInvoice &&
                                        <InfoCard margin='24px 0px 0px'>
                                            <Flex between centerHorizontally gap='8px'>
                                                <Flex start centerHorizontally gap='16px'>
                                                    <Image src={AI} />
                                                    <Text color={theme.colors.defaultLightText}>Our AI Engine automatically captured important details from your bill. Feel free to modify as needed.</Text>
                                                </Flex>
                                                {!editMode && <TextButton color={hexToRgba(theme.colors.primary2, 0.8)} text="MODIFY" onClick={() => this.setState({ editMode: true })} />}
                                            </Flex>
                                        </InfoCard>
                                    }
                                    {
                                        editMode && (selectedVendor || existingVendor) && !newVendor ?
                                            <Flex start gap='24px' style={{ width: !upload && "560px", marginRight: '24px' }}>
                                                <div style={{ width: '100%', paddingRight: '12px' }}>
                                                    <LabeledInput
                                                        nomargin
                                                        label='Vendor'
                                                        id="name"
                                                        type={!editMode ? "read-only" : "remote-search"}
                                                        className="no-left-padding"
                                                        placeholder="Select vendor or type to search"
                                                        value={selectedVendor && selectedVendor.vendorName}
                                                        style={{ fontSize: '14px' }}
                                                        onChange={this.handleSelect}
                                                        onSearch={this.handleSearch}
                                                        width='524px'
                                                        dropdownRender={menu => (
                                                            <FlexColumn start>
                                                                {menu}
                                                                {/* <Flex start style={{ padding: '4px 12px 6px' }}>
                                                                <TextButton text='ADD NEW' capitalize primary onClick={() => this.setState({ showAddVendor: true })} rightIcon={<Image src={Add} />} />
                                                            </Flex> */}
                                                            </FlexColumn>
                                                        )}
                                                        error={errorField === "vendor"}
                                                        errorMessage={"Select a vendor"}
                                                    >
                                                        {vendors.map(item => (
                                                            <Option key={item.id} id={item.id} name={item.vendorName} value={item.id}>
                                                                {item.vendorName}
                                                            </Option>
                                                        ))}
                                                    </LabeledInput>
                                                </div>
                                                <div></div>
                                            </Flex>
                                            :
                                            editMode &&
                                            <Flex start gap='24px' style={{ width: !upload && "560px", marginRight: '24px' }}>
                                                <div style={{ width: '100%', paddingRight: '36px' }}>
                                                    <NewInboxVendor
                                                        key='new-vendor'
                                                        vendor={parsedVendor}
                                                        handleVendorChange={(vendor) => this.setState({ parsedVendor: vendor })}
                                                        errorField={errorField}
                                                    />
                                                </div>
                                            </Flex>


                                        // :
                                        // <LabeledInput
                                        //     nomargin
                                        //     label='Vendor Name'
                                        //     id="name"
                                        //     type="read-only"
                                        //     value={parsedVendor && parsedVendor.vendorName}
                                        // />
                                    }
                                    {
                                        !this.props.edit && editMode &&
                                        <>
                                            {
                                                (selectedVendor || existingVendor) && !newVendor ?
                                                    <Text light>Are they a <a onClick={() => this.setState({ newVendor: true, existingVendor: false })}>new vendor</a>?</Text>
                                                    :
                                                    <Text light>Are they an <a onClick={() => this.setState({ newVendor: false, existingVendor: true })}>existing vendor</a>?</Text>
                                            }
                                        </>
                                    }
                                    {
                                        !(editMode && newVendor) &&
                                        <VendorCard
                                            {...this.state}
                                            vendor={(selectedVendor || existingVendor) && !newVendor ? selectedVendor : parsedVendor}
                                            margin='12px 0 0'
                                        />
                                    }
                                </FlexColumn>

                                <Flex start gap='24px'>
                                    {
                                        editMode &&
                                        <LabeledInput
                                            label="Bill Amount"
                                            type={!editMode && "read-only"}
                                            nomargin
                                            id="amount"
                                            placeholder="Enter an amount"
                                            prefixText="$"
                                            value={editMode ? amount : `$${toAbsCurrency(amount)}`}
                                            onChange={this.handleBillTextChange}
                                            error={errorField === "amount"}
                                            errorMessage={"This is a mandatory field"}
                                            width='250px'
                                        />
                                    }
                                    {
                                        (editMode || !dueDate) &&
                                        <LabeledInput
                                            label="Due Date"
                                            nomargin
                                            type={(!editMode && dueDate) ? "read-only" : "date-picker"}
                                            id="dueDate"
                                            format={dateFormatList}
                                            value={dueDate && (!editMode ? moment(dueDate).format(environment.defaultDateFormat) : moment(dueDate))}
                                            placeholder={moment().add(1, "day").format(dateFormat)}
                                            onChange={(date, dateStr) => this.saveInput("dueDate", moment.utc(dateStr).utcOffset(0))}
                                            onKeyDown={this.handleOnKeyDown}
                                            error={errorField === "dueDate"}
                                            errorMessage={"This is a mandatory field"}
                                            width='250px'
                                        />

                                    }
                                </Flex>

                                <Flex start gap='24px' style={{ width: !upload && "592px", marginBottom: '8px' }}>
                                    <LabeledInput
                                        label="Bill Date"
                                        nomargin
                                        type={(!editMode && billDate) ? "read-only" : "date-picker"}
                                        id="billDate"
                                        format={dateFormatList}
                                        value={billDate && (!editMode ? moment(billDate).format(environment.defaultDateFormat) : moment(billDate))}
                                        placeholder={moment().add(0, "day").format(dateFormat)}
                                        onChange={(date, dateStr) => {
                                            this.saveInput("billDate", moment.utc(dateStr).utcOffset(0))
                                        }}
                                        onKeyDown={this.handleOnKeyDown}
                                        error={errorField === "billDate"}
                                        errorMessage={"This is a mandatory field"}
                                        width='250px'
                                    />
                                    <LabeledInput
                                        label="Bill Number"
                                        type={(!editMode && !_.isEmpty(initialInvoiceNo)) && "read-only"}
                                        nomargin
                                        id="invoiceNumber"
                                        style={{ fontSize: '14px' }}
                                        placeholder="e.g. 194710"
                                        value={invoiceNumber}
                                        onChange={this.handleBillTextChange}
                                        error={errorField === "billNumber"}
                                        errorMessage={"This is a mandatory field"}
                                        width='250px'
                                    />
                                </Flex>

                                <TableView
                                    id="line-item-table"
                                    key={"line-item-table" + (line || []).length}
                                    tableLayout='auto'
                                    columns={this.getColumns()}
                                    dataSource={line}
                                    rowKey='description'
                                    titleText="Expense Categorization"
                                    descText="Categorize your line items"
                                    borderView={!editMode}
                                />

                                {editMode && <TextButton onClick={() => this.addLineItemRow()} margin="0" rightIcon={<Image src={Add} />} text="ADD LINE ITEM" />}
                                <FlexColumn centerVertically right style={{ marginRight: 24, marginTop: 24 }} gap='8px'>
                                    {/* {
                                        (subTotal != null) &&
                                        <Flex centerHorizontally gap='4px'>
                                            <Text right caption>SUBTOTAL</Text>
                                            <Text right style={{ minWidth: 127 }}>{`$ ${toAbsCurrency(subTotal)}`}</Text>
                                        </Flex>
                                    }
                                    {
                                        (discount != null) &&
                                        <Flex centerHorizontally gap='4px'>
                                            <Text right caption>DISCOUNT</Text>
                                            <Text right style={{ minWidth: 127 }}>{`$ ${toAbsCurrency(discount)}`}</Text>
                                        </Flex>
                                    }
                                    {
                                        (tax != null) &&
                                        <Flex centerHorizontally gap='4px'>
                                            <Text right caption>TAX</Text>
                                            <Text right style={{ minWidth: 127 }}>{`$ ${toAbsCurrency(tax)}`}</Text>
                                        </Flex>
                                    } */}
                                    <Flex centerHorizontally gap='4px'>
                                        <Text right caption>TOTAL</Text>
                                        <Text right weight='500' style={{ minWidth: 127 }}>{`$ ${toAbsCurrency(this.getLineItemTotals().totalAmt)}`}</Text>
                                    </Flex>
                                </FlexColumn>
                                {!showNote && <Text style={{ marginTop: '24px' }}><a onClick={() => this.setState({ showNote: true })}>Click here</a> to add an internal note</Text>}
                                {
                                    showNote &&
                                    <Flex start gap='24px' style={{ width: !upload && "592px", marginRight: '24px' }}>
                                        <div style={{ width: '50%' }}>
                                            <LabeledInput
                                                label="Note"
                                                type="text-area"
                                                autoSize={{ minRows: 1, maxRows: 2 }}
                                                optional
                                                width={"250px"}
                                                id="notes"
                                                nomargin
                                                tooltip={<Text color='white'>Use this field to add context to the bill for internal purposes. This detail will not be passed to the vendor during payment of this bill.</Text>}
                                                placeholder="Notes for your internal records"
                                                style={{ fontSize: '14px' }}
                                                value={notes}
                                                onChange={this.handleBillTextChange}
                                            />
                                        </div>
                                        <div></div>
                                    </Flex>
                                }

                            </FlexColumn>

                            <FlowFooter
                                disabled={false}
                                loading={loading}
                                back={false}
                                buttonTitle={buttonText.toUpperCase()}
                                onClick={() => this.submit({ payLater: false })}
                                solid
                                secondaryCTA={!edit}
                                secondaryCTATitle="SAVE FOR LATER"
                                secondaryCTALoading={secondaryCTALoading}
                                onClickSecondaryCTA={() => this.submit({ payLater: true })}
                            />
                        </FlexColumn>
                    </div>
                </FlexColumn>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        bkStore: state.bookkeepingAppReducer,
        store: state.aionAppReducer,
        bankingStore: state.bankingAppReducer,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(BillDetail))