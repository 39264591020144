import hexToRgba from "hex-to-rgba";
import styled from "styled-components";
import { FlexColumn } from "../../Reusable/Container";

export const CardContainer = styled.div`
    background: ${props => hexToRgba(props.theme.colors.container, 0.7)};
    border-radius: 24px;
    border: ${props => props.borderView && `1px solid ${props.theme.colors.border}`};
    box-shadow: ${props => !props.borderView && `0px 0px 2px rgba(0, 0, 0, 0.1)`};
    min-height: 400px;
    max-width: ${props => props.small && '400px'};
    min-width: 400px;
    /* padding: 20px; */
`;

export const AppImgContainer = styled(FlexColumn)`
    padding: 12px;
    border-radius: 15px;
    border: ${props => `1px solid ${props.theme.colors.border}`};
    background: ${props => props.theme.body};
    cursor: pointer;
    &:hover {
        box-shadow: ${props => props.onClick && `5px 10px 10px rgba(0, 0, 0, 0.05)`};
        background: ${props => props.onClick && hexToRgba(props.theme.colors.primary, 0.01)};
    }
`;