import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';

import { Radio, Divider, Select, message, Modal, Tooltip } from 'antd';
import { Paragraph } from '../../Reusable/Text';
import { FlexColumn, Flex, Container } from '../../Reusable/Container';
import { LabeledInput } from '../../Reusable/Input';
import { ErrorAlert } from '../../Reusable/Alert';

import environment from '../../../environment';
import { apiPOSTReq } from '../../../Utils/api';
import { addDataToStore, SAVE_DATA } from '../../../Actions/actions';
import { Button } from '../../Reusable/Button';
import { ExpenseCategory } from '../../Reusable/ExpenseCategory';
import { getUserApps, getBPAccountsWithAccess } from '../../../Utils/util';
import { InfoCircleOutlined } from '@ant-design/icons';

const { Option } = Select;

const RadioSetting = (props) => {
    return <div style={{ marginTop: 15 }}>
        <Flex style={{ justifyContent: 'flex-start' }}>
            <div style={{ width: '50%' }}>
                <div style={{ fontSize: '14px' }}>{props.title}</div>
                {props.desc && <Paragraph level={1} style={{ color: props.theme.colors.systemGray }}>{props.desc}</Paragraph>}
            </div>
            <Radio.Group onChange={props.onChange} value={props.value}>
                <Radio value={'QUICKBOOKS'} style={{ marginRight: '20px' }}>QuickBooks Online</Radio>
                <Radio value={'AION'}>Aion</Radio>
            </Radio.Group>
        </Flex>
    </div>
}

const bankMapTooltip = 'Select a GL account for each bank account listed below. The GL account you associate will be used to post payments made using this bank account. Please note that QuickBooks only permits choosing GL accounts that are "Bank" type and "Checking" detailed type in QuickBooks.'

const apTooltip = "Select an AP account where you would like to post all your Aion bills in QuickBooks Online."

const ConflictTooltip = (props) => (<div>
    <div style={{ marginBottom: '20px' }}>
        The primary source you choose controls which system’s edits you would like to override in case the information related to a particular item (e.g. Vendor) is conflicting across both systems. It is recommended that you select the primary source based on where you will be maintaining the item (e.g. Vendor) most of the time.
        <br /><br />
        The primary source you select will be used during the below two conflict scenarios:
    </div>
    <Flex style={{ marginBottom: '20px' }}>
        <Flex center style={{ height: 30, minWidth: 30, borderRadius: 15, background: props.theme.colors.secondary5, marginRight: '20px' }}><Paragraph noMargin bold>1</Paragraph></Flex>
        <div>
            <div style={{ marginBottom: '15px' }}>Duplicate items exist in both systems during sync setup.</div>
            <div style={{ fontSize: '13px', color: '#8e8e93' }}>
                Example: If you create a vendor in your accounting system, and another vendor with the same name already exists in Aion during sync setup, then the vendor information between the two systems will be in conflict. Now, based on the primary source system you selected, the vendor information in the other system will be overridden.
            </div>
        </div>
    </Flex>
    <Flex>
        <Flex center style={{ height: 30, minWidth: 30, borderRadius: 15, background: props.theme.colors.secondary5, marginRight: '20px' }}><Paragraph noMargin bold>2</Paragraph></Flex>
        <div>
            <div style={{ marginBottom: '15px' }}>Real-time sync of items on Aion with duplicate records that were created on QuickBooks after your last sync on Aion.</div>
            <div style={{ fontSize: '13px', color: '#8e8e93', marginBottom: '15px' }}>
                Example: If you create a vendor in your accounting system, and you also create another vendor with the same name in Aion before sync, then the vendor information between the two systems will be in conflict. Now, based on the primary source system you selected, the vendor information in the other system will be overridden.
            </div>
            <div >Following first time creation of an item, edits to item information in either system will be synced based on the system where the latest update was made.</div>
        </div>
    </Flex>
</div>)

const priorityMap = {
    "Accounts Payable": 1,
    "Accounts Receivable": 2,
    "Bank": 0,
    "Cost of Goods Sold": 3,
    "Credit Card": 4,
    "Equity": 5,
    "Expense": 6,
    "Fixed Asset": 7,
    "Income": 8,
    "Long Term Liability": 9,
    "Other Asset": 10,
    "Other Current Asset": 11,
    "Other Current Liability": 12,
    "Other Expense": 13,
    "Other Income": 14,
}

class AdvancedSettings extends Component {
    state = {
        pagination: {
            current: 1,
            pageSize: 1000,
        },
        pairPagination: {
            current: 1,
            pageSize: 1000,
        },
        accountCOA: {},
        customCOA: [],
        allCustomCOA: [],

        accountMaster: this.props.settings ? this.props.settings.accountMaster : 'QUICKBOOKS',
        vendorMaster: this.props.settings ? this.props.settings.vendorMaster : 'QUICKBOOKS',
        billMaster: this.props.settings ? this.props.settings.billMaster : 'QUICKBOOKS',
        billPaymentMaster: this.props.settings ? this.props.settings.billPaymentMaster : 'QUICKBOOKS',
        customerMaster: this.props.settings ? this.props.settings.customerMaster : 'QUICKBOOKS',
        invoiceMaster: this.props.settings ? this.props.settings.invoiceMaster : 'QUICKBOOKS',
        productMaster: this.props.settings ? this.props.settings.productMaster : 'QUICKBOOKS',

        apAccountId: this.props.apAccountId,
    };

    componentDidMount() {
        this.fetchCOA({ pagination: this.state.pagination });
        this.fetchCOAPair({ pagination: this.state.pairPagination });
    }

    fetchCOAPair = ({ pagination }) => {
        const body = {
            page: pagination.current - 1,
            size: pagination.pageSize
        }
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/qb/getAcctCOAPair`, {}, body, (err, resp) => {
            try {
                const data = resp;
                console.log('/integration/qb/getAcctCOAPair', data)
                if (data.result) {
                    const accountCOA = this.state.accountCOA;
                    if (data.accountPairList) {
                        data.accountPairList.forEach(x => {
                            accountCOA[x.accountId] = x.coaId
                        });
                        this.setState({ accountCOA });
                    }

                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getActiveCustomers", error.stack, resp);
                ErrorAlert({ description: error.message })
            }
        })
    }

    fetchCOA = ({ pagination, searchStr }) => {
        const headers = {
            AionCurrentBiz: this.props.aionStore.BusinessUniqueKey,
            AionAuth: this.props.aionStore.UAM.encryptedAuthHeader
        }

        const body = {
            "BusinessId": this.props.aionStore.BusinessUniqueKey,
            "size": pagination.pageSize,
            "page": pagination.current - 1,
            "ActiveRecords": true,
            "sortFieldName": "Code",
            "sortDirection": "ASC",
            "sources": ["QUICKBOOKS"],
        }
        if (searchStr) {
            body.searchStr = searchStr;
        }
        this.setState({ loading: true, });

        apiPOSTReq(`${environment.bbBaseUrl}/bk/getCategories`, headers, body, (err, resp) => {
            try {
                const data = resp;
                console.log('/bk/getCategories', data)
                if (data.result) {
                    this.setState({
                        loading: false,
                        customCOA: data.customCOAList ? data.customCOAList.sort((a, b) => {
                            if (priorityMap[a.coatype] < priorityMap[b.coatype]) return -1;
                            else return 1;
                        }).map(x => {
                            return {
                                ...x, key: x.id,
                            }
                        }) : [],
                    })
                    if (!searchStr) {
                        this.setState({
                            allCustomCOA: data.customCOAList ? data.customCOAList.sort((a, b) => {
                                if (priorityMap[a.coatype] < priorityMap[b.coatype]) return -1;
                                else return 1;
                            }).map(x => {
                                return {
                                    ...x, key: x.id,
                                }
                            }) : [],
                        })
                    }
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getActiveCustomers", error.stack, resp);
                ErrorAlert({ description: error.message })
            }
        })
    }

    handleSelect = (accountId, coaId) => {
        let accountCOA = this.state.accountCOA;
        accountCOA[accountId] = coaId;
        this.setState({ accountCOA, customCOA: this.state.allCustomCOA });
    }

    updateSetting = () => {
        if (!this.state.apAccountId) {
            ErrorAlert({ description: "Please select AP accounts" });
            return;
        }

        if (this.props.onComplete) {
            this.props.onComplete();
        }
        const body = {
            BusinessId: this.props.aionStore.BusinessUniqueKey,
            setting: {
                accountMaster: this.state.accountMaster,
                vendorMaster: this.state.vendorMaster,
                billMaster: this.state.billMaster,
                billPaymentMaster: this.state.billPaymentMaster,
                customerMaster: this.state.customerMaster,
                invoiceMaster: this.state.invoiceMaster,
                productMaster: this.state.productMaster,
            },
            accountCOAPair: Object.entries(this.state.accountCOA).map(x => {
                return {
                    accountId: x[0],
                    coaId: x[1]
                }
            }),
            apAccountId: this.state.apAccountId,
        }
        console.log('body', body)
        this.setState({ loading: true, });

        apiPOSTReq(`${environment.integrationBaseUrl}/integration/qb/updateSyncSetting`, {}, body, (err, resp) => {
            try {
                const data = resp;
                console.log('/integration/qb/updateSyncSetting', data)
                if (data.result) {
                    message.success("Preferences confirmed");
                    if (data.jobId) {
                        this.props.dispatch(addDataToStore(SAVE_DATA, { syncJobId: data.jobId }));
                    }
                    if (this.props.redirect) {
                        window.location.href = this.props.redirect
                    }
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getActiveCustomers", error.stack, resp);
                ErrorAlert({ description: error.message })
            }
        })
    }

    handleSearch = value => {
        this.fetchCOA({ pagination: this.state.pagination, searchStr: value })
    }

    render() {
        const { theme } = this.props;
        var Accounts = getBPAccountsWithAccess()
        const userApps = getUserApps(this.props.aionStore);

        console.log(this.state.customCOA)
        var activeAccounts = Accounts.filter(x=> x.status === "Active");
        const apAccounts = this.state.customCOA.filter(x => x.coatype === "Accounts Payable");
        const arAccounts = this.state.customCOA.filter(x => x.coatype === "Accounts Receivable");
        const bankAccounts = this.state.customCOA.filter(x => x.coatype === 'Bank' && x.coasubType === 'Checking')

        return (
            <>
            <Container shadow style={{ marginBottom: 48 }}>
                <Paragraph noMargin fontWeight={600} level={4} >Select the QuickBooks Online GL account associated with your bank account
                    <Tooltip title={bankMapTooltip}>
                        <InfoCircleOutlined style={{ marginLeft: '10px' }} />
                    </Tooltip>
                </Paragraph>
                <FlexColumn style={{ marginBottom: '30px', marginTop: '20px' }}>
                    <table>
                        <thead style={{ borderBottomStyle: 'solid', borderBottom: '1px solid rgba(0, 0, 0, 0.06)' }}>
                            <tr>
                                <th style={{ textAlign: 'left', paddingBottom: '5px' }}><Paragraph fontWeight={400} level={4} noMargin>Bank account</Paragraph></th>
                                <th style={{ textAlign: 'left', paddingBottom: '5px', width: '50%' }}><Paragraph fontWeight={400} level={4} noMargin>Chart of account</Paragraph></th>
                            </tr>
                        </thead>
                        <tbody>
                            {bankAccounts && activeAccounts.map(account => {
                                return <tr>
                                    <td>
                                        <div style={{ fontSize: '14px' }}>{`${account.nickName ? account.nickName : 'Aion Business Banking'} - ${account.mask}`}</div>
                                    </td>
                                    <td>
                                        <Flex style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div style={{ width: '100%' }}>
                                                <LabeledInput
                                                    label=""
                                                    labelcolor={theme.colors.secondary3}
                                                    id={`category${account.accountId}`}
                                                    key={`category${account.accountId}`}
                                                    type="remote-search"
                                                    className="no-left-padding"
                                                    placeholder="Select Chart of Account"
                                                    value={(bankAccounts && bankAccounts.length > 0) && this.state.accountCOA[account.accountId]}
                                                    onChange={(val) => this.handleSelect(account.accountId, val)}
                                                    onSearch={this.handleSearch}
                                                >
                                                    {bankAccounts && bankAccounts.map(category => (
                                                        <Option key={category.coaId} id="category" name="category" value={category.coaId}> <ExpenseCategory category={category} theme={theme} /></Option>)
                                                    )}
                                                </LabeledInput>
                                                
                                            </div>
                                        </Flex>
                                    </td>
                                </tr>
                            })}

                        </tbody>
                    </table>
                </FlexColumn>

                <Paragraph style={{ marginTop: '40px' }} fontWeight={600} level={4} >Select the QuickBooks Online Accounts Payable GL account
                    <Tooltip title={apTooltip}>
                        <InfoCircleOutlined style={{ marginLeft: '10px' }} />
                    </Tooltip>
                </Paragraph>
                <FlexColumn style={{ marginBottom: '30px' }}>
                    <Flex style={{ justifyContent: 'flex-start', alignItems: 'center' }}>
                        <div style={{ width: '50%' }}><Paragraph level={1} noMargin>Accounts Payable GL account</Paragraph></div>
                        <div style={{ width: '50%' }}>
                            <Flex style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                                <div style={{ width: '100%' }}>
                                    <LabeledInput
                                        autoFocus
                                        label=""
                                        labelcolor={theme.colors.secondary3}
                                        id="category"
                                        key="category"
                                        type="sync-select"
                                        className="no-left-padding"
                                        placeholder="Select Chart of Account"
                                        value={(apAccounts && apAccounts.length > 0) && this.state.apAccountId}
                                        onChange={(val) => this.setState({ apAccountId: val })}
                                    >
                                        {apAccounts && apAccounts.map(category => (
                                            <Option key={category.coaId} id="category" name="category" value={category.coaId}> <ExpenseCategory category={category} theme={theme} /></Option>)
                                        )}
                                    </LabeledInput>
                                </div>
                            </Flex>
                        </div>
                    </Flex>
                </FlexColumn>

                <Paragraph style={{ marginTop: '40px' }} noMargin fontWeight={600} level={4} >Select primary source in case of conflicts
                    <InfoCircleOutlined style={{ marginLeft: '10px' }} onClick={() => this.setState({ showTooltipModal: true })} />
                </Paragraph>
                <Flex style={{ justifyContent: 'flex-start', marginTop: '20px' }}>
                    <div style={{ width: '50%' }}><Paragraph level={4} noMargin>Items</Paragraph></div>
                    <div style={{ width: '50%' }}><Paragraph level={4} noMargin>Primary Source</Paragraph></div>
                </Flex>
                <Divider style={{ margin: '5px 0' }} />
                {userApps && userApps.includes("Bookkeeping") &&
                    <RadioSetting
                        title="Chart of Accounts"
                        onChange={(e) => this.setState({ accountMaster: e.target.value })}
                        theme={theme}
                        disabled={!this.state.syncQBO}
                        value={this.state.accountMaster}
                        desc=""
                    />
                }

                {userApps && userApps.includes("Payables") && <>
                    <Paragraph bold level={3} margin="20px 0 0px 0">Pay</Paragraph>

                    {/* vendor */}
                    <RadioSetting
                        title="Vendors"
                        onChange={(e) => this.setState({ vendorMaster: e.target.value })}
                        theme={theme}
                        disabled={!this.state.syncQBO}
                        value={this.state.vendorMaster}
                        desc=""
                    />

                    {/* bill */}
                    <RadioSetting
                        title="Bills"
                        onChange={(e) => this.setState({ billMaster: e.target.value })}
                        theme={theme}
                        disabled={!this.state.syncQBO}
                        value={this.state.billMaster}
                        desc=""
                    />

                    {/* bill payment */}
                    <RadioSetting
                        title="Bill Payments"
                        onChange={(e) => this.setState({ billPaymentMaster: e.target.value })}
                        theme={theme}
                        disabled={!this.state.syncQBO}
                        value={this.state.billPaymentMaster}
                        desc=""
                    />
                </>}

                {userApps && userApps.includes("Receivables") && <>
                    <Paragraph bold level={3} margin="20px 0 0px 0">Receivables</Paragraph>

                    {/* customer */}
                    <RadioSetting
                        title="Customers"
                        onChange={(e) => this.setState({ customerMaster: e.target.value })}
                        theme={theme}
                        disabled={!this.state.syncQBO}
                        value={this.state.customerMaster}
                        desc=""
                    />

                    {/* invoice */}
                    <RadioSetting
                        title="Invoices"
                        onChange={(e) => this.setState({ invoiceMaster: e.target.value })}
                        theme={theme}
                        disabled={!this.state.syncQBO}
                        value={this.state.invoiceMaster}
                        desc=""
                    />

                    {/* product */}
                    <RadioSetting
                        title="Products"
                        onChange={(e) => this.setState({ productMaster: e.target.value })}
                        theme={theme}
                        disabled={!this.state.syncQBO}
                        value={this.state.productMaster}
                        desc=""
                    />
                </>}

                <Modal
                    visible={this.state.showTooltipModal}
                    footer={null}
                    closable={true}
                    width={650}
                    style={{ top: '10%' }}
                    destroyOnClose={true}
                    onCancel={() => {
                        this.setState({ showTooltipModal: false })
                    }}
                >
                    <span style={{ fontSize: '18px', fontWeight: 600 }}><InfoCircleOutlined style={{ marginRight: '5px' }} /> Primary source in case of conflict</span>
                    <Divider />
                    <ConflictTooltip theme={theme} />
                </Modal>

                {!this.props.hideAction && <FlexColumn left style={{ marginTop: '30px', justifyContent: 'center' }}>
                    <Button permtype="Override" solid onClick={this.updateSetting} text={this.props.actionText || "Save"} />
                </FlexColumn>}
                </Container>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer,
        bankingStore: state.bankingAppReducer,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(AdvancedSettings));