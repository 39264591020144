import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import { Modal } from 'antd';
import { Image } from '../../Reusable/Image'
import { TextButton, Button } from '../../Reusable/Button'

import { FlexColumn } from '../../Reusable/Container'
import environment from '../../../environment';
import { apiPOSTReq } from '../../../Utils/api';
import { Text } from '../../Reusable/Text'
import ModalClose from '../../../Images/modal-close.png'
import Missing from '../../../Images/missing.png'

class YodleeIntegration extends Component {
    state = {
        openFastlink: false,
        yodleeLink: false,
        businessId: this.props.businessId,
        businessUniqueKey: this.props.businessUniqueKey
    }

    componentDidMount() {
        this.fetchAccessToken()
        this.setState({ yodleeLink: true })
    }

    fetchBanks = () => {
        this.props.fetchBanks()
    }

    closeYodleeLink = () => {
        this.props.handleYodleeLink()
        this.setState({ yodleeLink: false })
    }

    fetchAccessToken = () => {
        const headers = {
            
        }

        const body = {
            "BusinessId": this.props.BusinessUniqueKey,
            "loggedInUserId": this.props.store.userInfo.email
        }

        apiPOSTReq(`${environment.integrationBaseUrl}/integration/yodlee/getAccessToken`, headers, body, (err, resp) => {
            try {
                const data = resp
                const { token } = data
                this.setState({ accessToken: token.accessToken })

            } catch (error) {
                console.log("ERROR", error.stack)
            }
        })
    }

    openYodleeFastLink = (fetchBankCallback, closeYodleeLinkCall) => {
        console.log('Bearer ' + this.state.accessToken)
        this.setState({ openFastlink: true })
        var businessId = this.props.businessId || ''
        var accessToken = this.state.accessToken || ''
        window.fastlink.open({
            // Your FastLink parameters here
            fastLinkURL: environment.yodleeUrl,
            accessToken: 'Bearer ' + this.state.accessToken,
            forceIframe: true,
            params: {
                configName: environment.yodleeConfigName,
                isIFrameMounted: true
            },
            onSuccess: function (data) {
                // will be called on success. For list of possible message, refer to onSuccess(data) Method.
                console.log(data);
                const headers = {
                    
                }

                const body = {
                    "BusinessId": businessId,
                    "yodleeTokenData": data,
                    "accessToken": accessToken
                }
                apiPOSTReq(`${environment.integrationBaseUrl}/integration/yodlee/storeAccessInfo`, headers, body, (err, resp) => {
                    try {
                        fetchBankCallback()
                    } catch (error) {
                        console.log("ERROR", error.stack)
                    }
                })

            },
            onError: function (data) {
                console.log(data)
            },
            onClose: function (data) {
                // window.fastlink.close()
                const headers = {
                    
                }

                const body = {
                    "BusinessId": businessId,
                    "yodleeTokenData": data,
                    "accessToken": accessToken
                }
                apiPOSTReq(`${environment.integrationBaseUrl}/integration/yodlee/storeAccountInfo`, headers, body, (err, resp) => {
                    try {
                    } catch (error) {
                        console.log("ERROR", error.stack)
                    }
                })
                console.log(data)
                closeYodleeLinkCall()
            },
        }, 'container-fastlink');

    }

    render() {
        const { openFastlink, yodleeLink } = this.state
        console.log("YodleeStore", this.props.store)
        return (
            <>
                <Modal
                    visible={yodleeLink}
                    footer={null}
                    closable={false}
                    width={510}
                    destroyOnClose={true}
                    closeIcon={<Image src={ModalClose} />}
                    className='drawer-modal'
                    wrapClassName="drawer-modal"
                >
                    {
                        !openFastlink &&
                        <FlexColumn center gap='24px' style={{ padding: '24px 0' }}>
                            <Image width={'48px'} height={'48px'} src={Missing} />
                            <Text size='28px' height='40px' weight='500' center>Couldn't find your account?</Text>
                            <Text size='16px' center width={'300px'}>Lets try another way</Text>
                            <Button
                                solid
                                text={'CONNECT'}
                                onClick={() => {
                                    this.openYodleeFastLink(this.fetchBanks, this.closeYodleeLink)
                                }}
                                loading={false}
                            />
                            <TextButton text={"I'll do it later"} onClick={() => this.closeYodleeLink()} margin='6px 0' />
                        </FlexColumn>
                    }
                    
                    <div id="container-fastlink"></div>
                </Modal>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer,
        bankingStore: state.bankingAppReducer,
        creditStore: state.creditAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(YodleeIntegration));