import React, { useState } from 'react'

import { message } from 'antd'
import { Text } from './Text'
import { Flex, FlexColumn, Container, LightContainer, CardContainer } from './Container'
import { LabeledInput, GradientDivider } from './Input'
import { Button } from './Button'
import { apiPOSTReq } from '../../Utils/api'
import { isEmailValid } from '../../Utils/util'
import { ErrorAlert } from './Alert'
import moment from 'moment'
import environment from '../../environment'

// Images
import Visibility from "../../Images/visibility.png"
import VisibilityOff from "../../Images/visibility-off.png"

import AccountNameBack from "../../Images/name-account-back.png"

const SendToModal = (props) => {
    const [emails, setEmails] = useState([])
    const [masked, setMasked] = useState(true)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    const { account, onCancel } = props

    const sendReferralEmail = () => {
        if (!isEmailValid(emails)) {
            setError(true)
            return
        }

        setError(false)

        var body = {
            ToAddress: emails
        }

        console.log("aion/system/sendReferralEmail body", body)

        setLoading(true)
        apiPOSTReq(`${environment.uamBaseUrl}/aion/system/sendReferralEmail`, { }, body, (err, resp) => {
            setLoading(false)
            try {
                const data = resp || {}
                if (data.result) {
                    console.log("aion/system/sendReferralEmail", data)
                    message.success('Invite sent!')
                    onCancel()
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/updateAccountNickName err", error, resp, body)
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    return (
        <FlexColumn start fullHeight>
            <FlexColumn start>
                <Text heading>Email invite</Text>
                <Text>Please provide the email address of the business you would like to invite to use Aion</Text>
                <LabeledInput
                    margin="24px 0 48px"
                    label='Send to'
                    type="email"
                    placeholder="Enter a single email ID"
                    onChange={(event) => {
                        setEmails(event.target.value)
                    }}
                    value={emails}
                    error={error}
                    errorMessage={"Please enter a valid email address"}
                />
                {/* <LabeledInput
                    margin="24px 0 48px"
                    label='Send to'
                    type="multi-email"
                    placeholder="Enter a single email ID or multiple email IDs"
                    value={emails}
                    onChange={(emails) => {
                        setEmails(emails)
                    }}
                    onDelete={(index) => {
                        var newEmails = []

                        for (var i = 0; i < emails.length; i++) {
                            if (i != index) newEmails.push(emails[i])
                        }

                        setEmails(newEmails)
                    }}
                    emails={emails}
                /> */}
            </FlexColumn>
            <Flex start>
                <Button loading={loading} onClick={() => sendReferralEmail()} solid disabled={emails.length === 0} text='Send Invite' />
            </Flex>
        </FlexColumn>
    )
}

export default (SendToModal)