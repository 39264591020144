import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { Avatar, Button, Divider, Badge } from 'antd';

import { Flex, FlexColumn } from '../Reusable/Container';
// import { containerDimensions } from '../../Styles/theme';
import { getDomain, capitalizeWords, getGreeting } from '../../Utils/util';
import { Paragraph } from '../Reusable/Text';
import { uaApiPOST } from '../../Utils/userAccess';
import { TextButton } from './Button';
import Modal from 'antd/lib/modal/Modal';
import SwitchBusiness from './SwitchBusiness';
import BusinessLogo from './BusinessLogo';
import LUpIcon from '../../Images/menu-up-icon/light.png';
import DUpIcon from '../../Images/menu-up-icon/dark.png';
import UpIcon from '../../Images/menu-up-icon/blue.png';
import LDownIcon from '../../Images/menu-down-icon/light.png';
import DDownIcon from '../../Images/menu-down-icon/dark.png';
import DownIcon from '../../Images/menu-down-icon/blue.png';

const Menu = styled.div`
    list-style-type: none;
    display: flex;
    flex-direction: column;
`;

const MenuItem = styled.div`
    cursor: pointer;
    margin: 2px 20px 0 0;
    padding: 6px 20px;
    border-radius: 5px;
    font-size: 0.9rem;
    color: ${props => props.colorScheme && props.colorScheme.itemColor || "#9CA6AA"};
    background: ${props => props.colorScheme && props.colorScheme.itemBackground || "white"};
    &:hover {
        color: ${props => props.colorScheme && props.colorScheme.itemColorHovered || "#63857B"};
        background: ${props => props.colorScheme && props.colorScheme.itemBackgroundHovered || "#EFFFEF"};
    }
    &.selected {
        color: ${props => props.colorScheme && props.colorScheme.itemColorSelected || "#63857B"};
        background: ${props => props.colorScheme && props.colorScheme.itemBackgroundSelected || "#E4F4E4"};
    }
`;

const SubMenu = styled.ul`
    list-style-type: none;
    padding-inline-start: 10px;
`;

const SubTitle = styled.span`
    font-weight: 450
`;

const SubMenuItem = styled.li`
    cursor: pointer;
    margin: 2px 20px 0 20px;
    padding: 6px 20px;
    border-radius: 5px;
    font-size: 0.9rem;
    color: ${props => props.colorScheme && props.colorScheme.itemColor || "#9CA6AA"};
    background: ${props => props.colorScheme && props.colorScheme.itemBackground || "white"};
    &:hover {
        color: ${props => props.colorScheme && props.colorScheme.itemColorHovered || "#63857B"};
        background: ${props => props.colorScheme && props.colorScheme.itemBackgroundHovered || "#EFFFEF"};
    }
    &.selected {
        color: ${props => props.colorScheme && props.colorScheme.itemColorSelected || "#63857B"};
        background: ${props => props.colorScheme && props.colorScheme.itemBackgroundSelected || "#E4F4E4"};
    }
`;

const SubMenuTitleItem = styled.li`
    margin: 2px 30px 0 10px;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 0.9rem;
    color: ${props => props.colorScheme && props.colorScheme.itemColor || "#9CA6AA"};
    background: ${props => props.colorScheme && props.colorScheme.itemBackground || "white"};
`;

class SideNav extends React.Component {

    state = {
        menuItems: this.props.data.menuItems
    }

    componentDidMount() {
        // load the menu item with defaultSelectedKey if any
        if (this.props.defaultSelectedKey)
            this.menuItemClicked(this.findMenuItem(this.props.defaultSelectedKey));
    }

    // Find a menu item given its key
    findMenuItem(key) {
        let { data, defaultSelectedKey } = this.props;
        return data.menuItems.find(menuItem => menuItem.key == defaultSelectedKey);
    }

    // A menu item is clicked; save it to state and load the associated link
    menuItemClicked(item) {
        this.props.history.push(item.path);
    }

    isSubMenuSelected(subMenu) {
        return subMenu.some(item => item.path === this.props.location.pathname)
    }

    render() {
        const { data, location, store } = this.props;
        var { Profile, UserInfo } = store;
        var { path, menuItems } = this.state;
        var { BusinessInfo } = Profile || {};
        BusinessInfo = BusinessInfo || {};
        UserInfo = UserInfo || {};
        const website = ((BusinessInfo).Urls || [])[0];
        const domain = getDomain(website);

        const items = data.menuItems.map((item, i) => {
            return (
                item.subtitle || item.subMenu ?
                    <Menu key={`menu-${i}`}>
                        {item.subtitle && <SubMenuTitleItem
                            key={item.subtitle}
                            className={this.isSubMenuSelected(item.items) ? "selected" : ""}
                            colorScheme={data.colorScheme}
                        // style={{ marginTop: i > 0 && "20px" }}
                        >
                            <Flex centerHorizontally style={{ justifyContent: "space-between" }}>
                                <SubTitle>{item.subtitle}</SubTitle>
                                {/* <img src={this.isSubMenuSelected(item.items) ? UpIcon : (item.open ? LUpIcon : LDownIcon)} width="18" style={{ marginLeft: "12px", opacity: 0.8 }} /> */}
                            </Flex>
                        </SubMenuTitleItem>}
                        {
                            item.open === true && // this.isSubMenuOpen(item.items) &&
                            <SubMenu key={`${item.subtitle}-submenu`}>
                                {
                                    item.items.map(subItem => {
                                        return (
                                            <SubMenuItem
                                                key={subItem.key}
                                                onClick={this.menuItemClicked.bind(this, subItem)}
                                                className={location.pathname.includes(subItem.path) ? "selected" : ""}
                                                colorScheme={data.colorScheme}>
                                                <Flex centerHorizontally style={{ justifyContent: "start", alignItems: "center" }}>
                                                    <img src={subItem.icon} width="22" style={{ marginRight: "15px", opacity: 0.8 }} />
                                                    {subItem.title}
                                                    {
                                                        subItem.notificationCount &&
                                                        <Badge style={{ marginLeft: 8 }} count={subItem.notificationCount} size="small" />
                                                    }
                                                </Flex>
                                            </SubMenuItem>
                                        )
                                    })
                                }
                            </SubMenu>
                        }
                    </Menu>
                    :
                    <Menu style={{ paddingInlineStart: 20 }} key={`menu-${i}`}>
                        <MenuItem
                            key={item.key}
                            onClick={this.menuItemClicked.bind(this, item)}
                            className={location.pathname.includes(item.path) ? "selected" : ""}
                            colorScheme={data.colorScheme}>
                            <Flex centerHorizontally style={{ justifyContent: "start", alignItems: "center" }}>
                                <img src={item.icon} width="22" style={{ marginRight: "15px", opacity: 0.8 }} />
                                {item.title}
                                {
                                    item.notificationCount &&
                                    <Badge style={{ marginLeft: 8 }} count={item.notificationCount} size="small" />
                                }
                            </Flex>
                        </MenuItem>
                    </Menu>
            );
        });
        const logoSize = 60;
        const homePage = location.pathname.includes("/home");
        const bbPage = location.pathname.includes("/banking");
        const subpaths = location.pathname.split("/");
        subpaths.shift();
        const tabPath = (subpaths.length > 0) ? subpaths.shift() : null;
        var tabName = bbPage ? "Aion Banking" : capitalizeWords(tabPath);
        var isUnderWritingConsole = location.pathname.includes("/underwriting")
        return (
            <>
                <div style={{ padding: "0px 20px 20px 0px" }}>
                    <FlexColumn style={{ paddingBottom: "10px", marginTop: "0px" }} center>
                        <Paragraph noMargin>{getGreeting()}</Paragraph>
                        <Paragraph level={2} bold noMargin>{UserInfo.FirstName} {UserInfo.LastName}</Paragraph>
                    </FlexColumn>
                    {
                        !isUnderWritingConsole && BusinessInfo.Name ?
                            <FlexColumn>
                                <Flex style={{ fontWeight: 500, margin: "0 10px", justifyContent: "flex-start" }} centerHorizontally>
                                    <BusinessLogo size={logoSize} hideupload />
                                    <Paragraph style={{ fontSize: "0.95rem" }} noMargin>{BusinessInfo.Name}</Paragraph>
                                </Flex>
                            </FlexColumn>
                            : null
                    }
                </div>
                {items}
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(SideNav)));

SideNav.propTypes = {
    data: PropTypes.shape({
        // define colors used for each menu item
        colorScheme: PropTypes.shape({
            itemColor: PropTypes.string,                // normal text color
            itemBackground: PropTypes.string,           // normal background
            itemColorHovered: PropTypes.string,         // text color when hovered
            itemBackgroundHovered: PropTypes.string,    // background when hovered
            itemColorSelected: PropTypes.string,        // text color when selected
            itemBackgroundSelected: PropTypes.string    // background when selected
        }),
        // define content of each menu item
        menuItems: PropTypes.arrayOf(
            PropTypes.shape({
                key: PropTypes.string,      // an unique id
                title: PropTypes.string,    // text to display
                path: PropTypes.string      // link to follow when clicked
            })
        )
    })
}
