import React, { Component } from 'react';
import moment from 'moment';
import { withTheme } from 'styled-components';
import { saveAs } from 'file-saver'

import {
    ConfigProvider,
    Empty,
    Skeleton,
    Table,
    Space,
} from 'antd';

import { PaperClipOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';

import { Flex, FlexColumn } from '../../Reusable/Container';
import { Text, Tag } from '../../Reusable/Text';
import { ErrorAlert } from '../../Reusable/Alert'

import { getPreSignedS3Url } from '../../../Utils/api'
import TableView from '../../Reusable/TableView';

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const customizeRenderEmpty = () => (
    <Empty
        imageStyle={{
            height: 60,
        }}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={
            <span>
                No Advances
            </span>
        }>
    </Empty>
)

const downloadAttachment = (url, name) => {
    getPreSignedS3Url({ url: url }, (err, preSignedUrl) => {
        if (!err) {
            saveAs(preSignedUrl, name)
        } else {
            ErrorAlert({ description: JSON.stringify(err) })
        }
    })
}

const getColumns = (props) => {
    const { theme } = props;

    return [
        {
            title: 'Upload Date',
            dataIndex: 'uploadDate',
            render: date => (moment(date).format("MMM D, h:mm a z")),
        },
        // {
        //     title: 'Total Invoice Amount',
        //     dataIndex: 'grossAR',
        //     render: amount => formatter.format(amount),
        // },
        {
            title: 'Uploaded and Certified  by',
            dataIndex: 'certifiedBy',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (status, info) => {
                if (status === "PendingUpload") {
                    return <Tag tagpreset={"#EC3F25"}>{"Pending Upload"}</Tag>
                } else if (status === "InReview") {
                    return <Tag tagpreset={theme.colors.secondary4}>{"In Review"}</Tag>
                } else {
                    return <Tag tagpreset={theme.colors.secondary4}>{status}</Tag>
                }
            },
        },
        {
            title: '',
            dataIndex: 'action',
            align: 'center',
            render: (text, inventory) => {
                return <Space size="middle" style={{ justifyContent: "flex-end" }}>
                    {
                        inventory.status === "PendingUpload" ?
                            <a onClick={() => props.handleAddCustomers(inventory.newCustomers, inventory.uploadJobId)} permtype="Override">Add Customers</a>
                            :
                            <a onClick={() => downloadAttachment(inventory.uploadFileURL.uri, inventory.uploadFileURL.fileName)}><PaperClipOutlined style={{ fontSize: 18 }} /></a>
                    }
                </Space>
            },
        },
    ]
}

const getRowDetails = (record, props) => {
    const { theme } = props

    return (
        <Flex style={{ padding: "20px", background: theme.body, justifyContent: "space-between", alignItems: 'flex-start' }}>
            {
                record.status === "PendingUpload" ?
                    <FlexColumn>
                        <div style={{ fontWeight: 'bold', fontSize: '16px', marginBottom: '10px' }}>Pending Upload:</div>
                        <div>We have detected {record.newCustomers.length} new customer{record.newCustomers.length > 1 ? "s" : ""} in this AR aging report. We will review this report once you have completed adding these customers.</div>
                    </FlexColumn>
                    :
                    <FlexColumn>
                        <div style={{ fontWeight: 'bold', fontSize: '16px', marginBottom: '10px' }}>Reviewer Notes:</div>
                        <div>{record.reviewerNotes ? record.reviewerNotes : ''}</div>
                    </FlexColumn>
            }
        </Flex>
    )
}

function ARTable(props) {
    var items = props.data || [];
    items.sort(function (a, b) {
        return moment(b.AdvanceDate).toDate() - moment(a.AdvanceDate).toDate();
    });
    return (
        <FlexColumn>
            {/* <Text size='20px' margin='0 0 24px 0'>History</Text> */}

            <ConfigProvider renderEmpty={customizeRenderEmpty}>
                <div className="config-provider">
                    <Skeleton loading={!props.inventoryLoaded} active title={false} paragraph={{ rows: 4 }}>
                        <TableView
                            id='advances-table'
                            titleText={props.titleText}
                            className='row-pointer'
                            rowKey='uploadJobId'
                            tableLayout='auto'
                            columns={getColumns(props)}
                            dataSource={items}
                            onRow={(record) => props.allowRowClick ? ({
                                onClick: () => {
                                    props.selectRow(record);
                                },
                            }) : {}}
                            expandable={!props.disableExpandable && {
                                expandedRowRender: record => getRowDetails(record, props),
                                expandRowByClick: true,
                                expandIconColumnIndex: -1,
                                columnWidth: "2px",
                                indentSize: "20px",
                                expandIcon: ({ expanded, onExpand, record }) =>
                                    !expanded ? (
                                        <DownOutlined onClick={e => onExpand(record, e)} />
                                    ) : (
                                        <UpOutlined onClick={e => onExpand(record, e)} />
                                    ),
                                rowExpandable: record => record.status === "PendingUpload" || record.status === "Approved" || record.status === "Rejected"
                            }}
                            ctaContent={props.ctaContent}
                        />
                    </Skeleton>
                </div>
            </ConfigProvider>
        </FlexColumn>
    )
}

export default withTheme(ARTable);