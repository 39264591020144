import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import { Modal } from 'antd'

import _ from 'lodash'
import { Text } from './Text'
import { Button, TextButton } from './Button'
import { Image } from './Image';
import { FlexColumn } from './Container'
import ModalClose from '../../Images/modal-close.png'
import Close from '../../Images/close-red.png'
import Deactivate from '../../Images/deactivate.png'
import Delete from '../../Images/delete-red-icon.png'
import Success from '../../Images/success-check.png'
import Question from '../../Images/question-primary.svg'
import Error from '../../Images/exclamation-red.svg'

class AlertModal extends Component {

    render() {
        const { onCancel, onConfirm, onSecondaryCTAClick, loading, visible, title, description, descWidth, buttonTitle, imgSrc, 
            secondaryCTAText, titleSize, cancelButtonText, close, deactivate, del, success, imgWidth, imgHeight, question, error } = this.props

        return (
            <Modal
                visible={visible}
                footer={null}
                closable={false}
                width={510}
                destroyOnClose={true}
                closeIcon={<Image src={ModalClose} />}
                className='drawer-modal'
                wrapClassName="drawer-modal"
            >
                <FlexColumn center gap='24px' style={{ padding: '24px 0' }}>
                    <Image width={imgWidth || '48px'} height={imgHeight || '48px'} src={success ? Success : close ? Close : deactivate ? Deactivate : del ? Delete : question ? Question : error ? Error : imgSrc} />
                    <Text size={titleSize || '28px'} height='40px' weight='500' center>{title}</Text>
                    <Text size='16px' center width={descWidth || '300px'}>{description}</Text>
                    <Button
                        solid
                        text={buttonTitle || 'OKAY'}
                        onClick={onConfirm}
                        loading={loading}
                    />
                    {
                        (onSecondaryCTAClick && secondaryCTAText) &&
                        <TextButton text={secondaryCTAText} onClick={onSecondaryCTAClick} margin='6px 0' />
                    }
                    {
                        onCancel &&
                        <TextButton text={cancelButtonText || 'CANCEL'} onClick={onCancel} margin='6px 0' />
                    }
                </FlexColumn>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(AlertModal)))