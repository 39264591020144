import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components';

// Components
import Applications from './Applications/Index';
import ApplicationDetail from './ApplicationDetail/Index';
import Clients from './Clients/Index';
import ClientDetail from './ClientDetail/Index';
import Advances from './Advances/Index';
class Index extends Component {

    render() {
        let { location } = this.props;
        return (
            <>
                <Switch location={location}>
                    <Route key="clients-app" exact path="/underwriting/clients" component={Clients} />
                    <Route key="clients-app" exact path="/underwriting/clients/creditdashboard" component={ClientDetail} />
                    <Route key="pending-app" exact path="/underwriting/applications/pending" component={Applications} />
                    <Route key="completed-app" exact path="/underwriting/applications/completed" component={Applications} />
                    <Route key="pending-app" path="/underwriting/applications/pending/:loanId" component={ApplicationDetail} />
                    <Route key="completed-app" path="/underwriting/applications/completed/:loanId" component={ApplicationDetail} />
                    <Route key="pre-screen-app" exact path="/underwriting/applications/prescreen" component={Applications} />
                    <Route key="due-diligence-app" exact path="/underwriting/applications/duediligence" component={Applications} />
                    <Route key="pre-screen-app" path="/underwriting/applications/prescreen/:loanId" component={ApplicationDetail} />
                    <Route key="due-diligence-app" path="/underwriting/applications/duediligence/:loanId" component={ApplicationDetail} />
                    <Route key="advances-app" exact path="/underwriting/applications/advances" component={Advances} />
                </Switch>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)));