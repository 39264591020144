import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import { Tooltip, Tabs } from 'antd'
import { getUserRoles, getFeaturePerm, getResourcePerm, toCurrency } from '../../../Utils/util'
import { Flex, FlexColumn, CardContainer } from '../../Reusable/Container'
import PageHeader from '../../Reusable/PageHeader'
import { Text, Tag } from '../../Reusable/Text'
import { Image } from '../../Reusable/Image'
import UserCards from './UserCards'
import CompanyCards from './../../Settings/Company Profile/CompanyCards'
import Info from '../../../Images/info.png'

const { TabPane } = Tabs

class Index extends Component {
    state = {
    }

    componentDidMount() {
    }

    render() {
        const { aionStore } = this.props

        const userRole = getUserRoles(aionStore)
        const isAdmin = userRole.includes("Company Admin") || userRole.includes("Owner") || userRole.includes("Primary Admin") || userRole.includes("Card Admin")
        const view = (this.props.location.state && this.props.location.state.view === true)
        const card = (this.props.location.state && this.props.location.state.card)
        const viewAll = (this.props.location.state && this.props.location.state.all === true)
        const cardsPerm = getResourcePerm("BusinessCards.Cards", { menuPerm: true })

        const manage = isAdmin || cardsPerm.manage

        return (
            <FlexColumn className='main-left-padding main-top-padding' style={{ width: '100%', margin: 0 }} start>
                <PageHeader
                    titleText={view ? 'Card Details' : 'Cards'}
                    back={view}
                    onBack={() => this.props.history.replace({
                            pathname: '/cards/all',
                            state: { all: true }
                        })}
                    // subtitle='Banking'
                    marginRight='36px'
                    ctaContent={
                        <Tooltip overlayInnerStyle={{ width: 330, minWidth: 330 }} overlayStyle={{ width: 350, minWidth: 350 }} overlayClassName='roles-tooltip' placement="left" arrowPointAtCenter={false} title={<FlexColumn>
                            <Text color='white' size='16px' weight='500' noWrap>Purchase limits</Text>
                            <Text color='white' size='14px'>{card ? `$${toCurrency(card.perTransactionLimit, 0, 0)}` : "$2,000.00"} / transaction</Text>
                            <Text color='white' size='14px' noWrap>{card ? `$${toCurrency(card.maxDailyLimit, 0, 0)}` : "$5,000.00"} / day</Text>
                            <Text color='white' size='14px' noWrap>{card ? `$${toCurrency(card.maxMonthlyLimit, 0, 0)}` : "$150,000.00"} / month</Text>
                            <br/>
                            <Text color='white' size='16px' weight='500' noWrap>Cash withdrawal limit</Text>
                            <Text color='white' size='14px' noWrap>{card ? `$${toCurrency(card.cashWithdrawalLimit, 0, 0)}` : "$1,000.00"} / day</Text>
                        </FlexColumn>}>
                            <FlexColumn>
                                <Image src={Info} />
                            </FlexColumn>
                        </Tooltip>
                    }
                />

                {
                    !manage || view ?
                        <UserCards view={view} card={card} />
                        :
                        <Tabs size="large" onChange={this.handleTabChange} defaultActiveKey={viewAll && 'CompanyCards'}>
                            <TabPane tab="My Cards" key="UserCards">
                                <UserCards />
                            </TabPane>
                            <TabPane tab="Company Cards" key="CompanyCards">
                                <CompanyCards noHeader={true} />
                            </TabPane>
                        </Tabs>
                }
            </FlexColumn>
        );
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer,
        bankingStore: state.bankingAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)))