import React from 'react';
import { Flex } from './Container';
import { Tag } from './Text'

export const ExpenseCategory = (props) => {
    const fontSize = props.fontSize ? props.fontSize : 13
    
    var categoryName = props.category ? props.category.categoryName : null;
    if (categoryName && props.selected && categoryName.length > 15) {
        categoryName = categoryName.substring(0, 15) + '...';
    }
    return props.category ? <Flex between centerHorizontally style={{alignItems: 'flex-start'}}>
        <Flex start style={{ whiteSpace: 'break-spaces', overflow: 'visible' }}>
            <span style={{ fontSize: fontSize, marginBottom: 0 }} title={props.category.categoryName}>{categoryName} {props.category.code ? (<Tag height='20px'>{props.category.code}</Tag>) : ''}</span>
        </Flex>
        {(!props.tag && !props.hideCategory) && <span style={{ fontSize: fontSize , marginRight: 10, marginBottom: 10}}>{props.category.coatype}</span>}
        {props.tag && <Tag background='#FBFBFB'>{props.category.coatype}</Tag>}
    </Flex>
    :
    null
}