import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { Modal, List, Typography, message, Skeleton, Space, Divider, Switch, Steps } from 'antd';
import { Flex, FlexColumn, Container } from '../../Reusable/Refresh/Container';
import { LightContainer, CardContainer } from '../../Reusable/Container';
import { Text, Tag } from '../../Reusable/Refresh/Text';
import { Button } from '../../Reusable/Refresh/Button';
import { LabeledInput } from '../../Reusable/Refresh/Input';
import Banner from '../../Reusable/Banner'
import _ from 'underscore';

import { CheckOutlined, InfoCircleOutlined, ArrowRightOutlined, CheckCircleOutlined } from '@ant-design/icons';

import { ImageButton } from '../../Reusable/Button'
import ModalClose from '../../../Images/modal-close.png'
import EditIcon from '../../../Images/edit.png'
import DownIcon from '../../../Images/chevron-down-icon.png'
import BusinessInformation from '../../Credit/EligibilityRefresh/Reusable/BusinessInformation';
import OwnershipInformation from '../../Credit/EligibilityRefresh/Reusable/OwnershipInformation';
import { apiPOSTReq } from '../../../Utils/api';
import environment from '../../../environment';
// Actions
import { addDataToStore, USERDEFAULTS_SAVE_FLAG, CREDIT_SAVE_DATA } from '../../../Actions/actions';
import AdditionalDocumentSubmitted from '../Reusable/AdditionalDocumentSubmitted';
import CreditEligibilityRefresh from '../../Credit/EligibilityRefresh/PreScreen/Index'
import CreditEligibility from '../../Credit/Eligibility/Index'

class CompleteApplication extends Component {

    constructor(props) {
        super(props);

        const { userDefaults } = this.props
        const { CreditApplicationStarted, currentViewIndex } = userDefaults

        this.state = {
            currentViewIndex: 0,
            submitLoading: false,
            loading: false,
            requestFinancials: true,
            applicationStarted: false,
            proposalTerms: {},
            beneficialOwners: [],
            hasBeneficialOwners: false,
            requests: [],
            pagination: {
                current: 1,
                pageSize: 100
            },
            isAdditionalDocsAdded: false
        }
    }

    componentDidMount() {
        var { loanApplication, store, isCreditPlus } = this.props;
        var Attributes = store.Attributes || {}
        const bbAttributes = Attributes.BankingAttributes || {}
        if ((loanApplication.underwritingStatus == "Auto Approved" || loanApplication.underwritingStatus == "Approved" || loanApplication.contracting == "Contract Signed" || loanApplication.underwritingStatus == "Rejected") && loanApplication.productType == "GNPL") {
            if (loanApplication.userStatus === "PreScreenCompleted") {
                this.updateUserStatus("DueDiligenceStarted");
                if (!isCreditPlus) {
                    this.props.history.push("/apply/credit/due-diligence/refresh")
                }
            } else if (bbAttributes.ForceCRBAccountOpening) {
                this.props.history.push("/apply");
            }
            else {
                if (!isCreditPlus) {
                    this.props.history.push("/apply/credit/due-diligence/refresh")
                }
            }
        }
        else {
            if (!isCreditPlus && loanApplication.userStatus == "Completed" && loanApplication.productType == "RLOC") {
                this.props.history.push("/credit/apply")
            }
            else {
                if (!isCreditPlus) {
                    this.props.history.push("/apply/credit")
                }
            }
        }
        this.fetchProposalTerms();
        this.setState({ beneficialOwners: this.fetchOwners() })
        this.fetchApprovedRequests({ pagination: this.state.pagination })

    }

    fetchApprovedRequests = (options) => {
        const { loanApplication } = this.props;
        const { pagination } = options || {};
        var body = {
            "size": pagination.pageSize,
            "page": pagination.current - 1,
            "sortDirection": "DESC"
        };
        if (loanApplication) {
            body["BusinessId"] = loanApplication.businessId;
            body["applicationId"] = loanApplication.applicationId;
            body["uploadCategory"] = "Custom Upload"
        }

        console.log("/lsm/getApprovedLoanRequest body", body);
        this.setState({ loading: true });
        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/getApprovedLoanRequest`, null, body, (err, resp) => {
            console.log("/lsm/getApprovedLoanRequest resp", resp);
            this.setState({ loading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    this.setState({ requests: data.requests });
                    if (data.requests.length > 0) {
                        this.setState({ isAdditionalDocsAdded: false })
                    } else {
                        this.setState({ isAdditionalDocsAdded: true })
                    }
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/getApprovedLoanRequest err", error, resp);
                // ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }
    fetchOwners = () => {
        var { store } = this.props;
        var { business } = store;
        var businessProfile = (business || {}).businessProfile || {};
        var coAppInfo = businessProfile.coOwnerApplicantInfo || [];
        var beneficialOwners = [];
        if (businessProfile.primaryApplicantInfo != undefined) {
            if (Number(businessProfile.primaryApplicantInfo.ownershipPercentage) >= 25) {
                beneficialOwners.push(businessProfile.primaryApplicantInfo)
            }
        }
        if (coAppInfo.length > 0) {
            var filteredcoOwners = coAppInfo.filter(owner => Number(owner.ownershipPercentage) >= 25)

            if (filteredcoOwners.length > 0) {
                filteredcoOwners.map(owner => beneficialOwners.push(owner));
            }
        }

        if (beneficialOwners.length > 0) {
            this.setState({ hasBeneficialOwners: true })
        } else {
            this.setState({ hasBeneficialOwners: false })
        }
        return beneficialOwners;
    }
    fetchProposalTerms = () => {
        const { loanApplication } = this.props;
        var body = {
            "clientBusinessId": loanApplication.businessId,
            "applicationId": loanApplication.applicationId
        };

        this.setState({ loanAppLoading: true });
        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/getProposalTerms`, {}, body, (err, resp) => {
            console.log("/getProposalTerms resp body", resp, body);
            this.setState({ loanAppLoading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    this.setState({
                        proposalTerms: data.offers[0]
                    });
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/getProposalTerms err", error, resp);
                // ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    updateUserStatus = (userStatus) => {
        var { loanApplication } = this.props;
        var { store } = this.props;
        var { BusinessUniqueKey } = store;
        var body = {
            clientBusinessId: BusinessUniqueKey,
            loanApplication: Object.assign(loanApplication, { userStatus: userStatus })
        }
        console.log("updated User satatus")
        apiPOSTReq(`${environment.opsBaseUrl}/ops/loans/application/save`, {}, body, (err, resp) => {
            try {
                const data = resp || {};
                if (data.result) {
                    this.props.dispatch(addDataToStore(CREDIT_SAVE_DATA, { loanApplication: data.loanApplication }));
                    this.setState({ loanApplication: data.loanApplication })
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/updateUserStatus err", error, resp);
            }
        });
    }
    startApplication = () => {
        this.props.dispatch(addDataToStore(USERDEFAULTS_SAVE_FLAG, { CreditApplicationStarted: true }))
        this.setState({ applicationStarted: true })

        const { loanApplication, userDefaults, isCreditPlus } = this.props;
        const { currentViewIndex } = userDefaults;
        var { userStatus } = loanApplication;
        if (loanApplication.productType === "RLOC") {
            this.updateUserStatus("DueDiligenceStarted");
            if (!isCreditPlus) {
                this.props.history.push("/apply/credit/due-diligence")
            }
        } else {
            if (loanApplication.userStatus === "PreScreenCompleted") {
                this.updateUserStatus("DueDiligenceStarted");
                if (!isCreditPlus) {
                    this.props.history.push("/apply/credit/due-diligence/refresh")
                }
            } else {
                if (!isCreditPlus) {
                    this.props.history.push("/apply/credit/due-diligence/refresh")
                }
            }
        }
    }

    closeCreditPlus = () => {
        this.props.closeCreditPlus();
    }

    render() {
        const { store, userDefaults, loanApplication, isCreditPlus } = this.props
        const { applicationStarted, isOwnershipInformationActive, isAdditionalDocActive } = this.state
        var buttonTitle, tagBackgroundColor, tagTextColor, tagText = "";
        var userStatus = loanApplication.userStatus;
        var proposalTerms = store.proposalTerms || {};
        if (_.isEmpty(proposalTerms)) {
            proposalTerms = this.state.proposalTerms;
        }
        var isBusinessInformationActive;
        var { beneficialOwners, requests } = this.state;
        if (!(beneficialOwners.length > 0 && proposalTerms.creditLimit > 100000) && this.state.isAdditionalDocsAdded) {
            isBusinessInformationActive = true
        }
        else {
            isBusinessInformationActive = this.state.isBusinessInformationActive;
        }
        switch (userStatus) {
            case "PreScreenCompleted":
                buttonTitle = "START"; tagBackgroundColor = "#F2F2F2"; tagTextColor = "#666666"; tagText = "Not Started"
                break;
            case "DueDiligenceStarted":
                buttonTitle = "CONTINUE"; tagBackgroundColor = "#F5F6FE"; tagTextColor = "#1199FF"; tagText = "In Progress"
                break;
            case "BankingConnected":
                if (proposalTerms.creditLimit > 100000) {
                    buttonTitle = "CONTINUE"; tagBackgroundColor = "#F5F6FE"; tagTextColor = "#1199FF"; tagText = "In Progress"
                    break;
                } else {
                    buttonTitle = "REVIEW & SUBMIT"; tagBackgroundColor = "#F5F9F5"; tagTextColor = "#318F2F"; tagText = "Completed"
                    break;
                }
            case "FinanDocsUploaded":
                buttonTitle = "REVIEW & SUBMIT"; tagBackgroundColor = "#F5F9F5"; tagTextColor = "#318F2F"; tagText = "Completed"
                break;
            default:
                buttonTitle = "START"; tagBackgroundColor = "#F2F2F2"; tagTextColor = "#666666"; tagText = "Not Started"
                break;
        }

        return (
            <>
                {
                    true ? // applicationStarted !== true ?
                        <>
                            {
                                isCreditPlus && (userStatus === "DueDiligenceStarted" || userStatus === "Started" || userStatus === "AccountingSWConnected" || userStatus === "BankingConnected" || userStatus === "BizDocsUploaded" || userStatus === "FinanDocsUploaded" || userStatus == "Completed") ?
                                    loanApplication.productType === "RLOC" ? <CreditEligibility creditEligibilityApplied={false} isCreditPlus={true} closeCreditPlus={this.closeCreditPlus} /> :
                                        <CreditEligibilityRefresh creditEligibilityApplied={false} isCreditPlus={true} closeCreditPlus={this.closeCreditPlus} />
                                    :
                                    userStatus === "Completed" || userStatus === "DDCompleted" ? <>
                                        {/*

                                <Banner
                                    borderColor="#D7DCFB"
                                    background="#F5F6FE"
                                    style={{ marginBottom: "24px" }}
                                    message={`We are reviewing your application. Stay tuned for further updates. `}
                                />*/ }

                                        <LightContainer shadow margin="24px 0 24px 0" style={{ cursor: 'pointer' }}>
                                            <FlexColumn start>
                                                <Flex between centerHorizontally onClick={() => this.setState({ isBusinessInformationActive: !isBusinessInformationActive })} fullWidth style={{ height: '64px' }}>
                                                    <Flex center gap='8px'>
                                                        <CheckCircleOutlined style={{ color: "#318F2F", fontSize: '20px', marginRight: "10px" }} />
                                                        <Text weight='500' size='20px'>Additional Information</Text>
                                                    </Flex>
                                                    <Flex center gap='24px'>
                                                        <ImageButton src={DownIcon} transform={`rotate(${isBusinessInformationActive ? 180 : 0}deg)`} />
                                                    </Flex>
                                                </Flex>
                                                {
                                                    isBusinessInformationActive &&
                                                    <>
                                                        <Divider margin='24px 0 24px' />
                                                        <BusinessInformation reviewView proposalTerms={proposalTerms} />
                                                    </>
                                                }
                                            </FlexColumn>
                                        </LightContainer>


                                        {/* OWNERSHIP INFORMATION */}
                                        {proposalTerms != undefined ? <>
                                            {
                                                (beneficialOwners.length > 0 && proposalTerms.creditLimit > 100000) ? <LightContainer shadow margin="0 0 0 0" minHeight="300" style={{ cursor: 'pointer' }}>
                                                    <FlexColumn start>
                                                        <Flex between centerHorizontally onClick={() => this.setState({ isOwnershipInformationActive: !isOwnershipInformationActive })} fullWidth style={{ height: '64px' }}>
                                                            <Flex center gap='8px'>
                                                                <CheckCircleOutlined style={{ color: "#318F2F", fontSize: '20px', marginRight: "10px" }} />
                                                                <Text weight='500' size='20px'>Ownership Information</Text>
                                                            </Flex>
                                                            <Flex center gap='24px'>
                                                                <ImageButton src={DownIcon} transform={`rotate(${isOwnershipInformationActive ? 180 : 0}deg)`} />
                                                            </Flex>
                                                        </Flex>
                                                        {
                                                            isOwnershipInformationActive &&
                                                            <>
                                                                <Divider />
                                                                <OwnershipInformation reviewView proposalTerms={proposalTerms} />
                                                            </>
                                                        }
                                                    </FlexColumn>
                                                </LightContainer> : null}

                                        </> : null}

                                        {
                                            requests.length > 0 ?
                                                <LightContainer shadow margin="0 0 24px 0" style={{ cursor: 'pointer' }}>
                                                    <FlexColumn start>
                                                        <Flex between centerHorizontally onClick={() => this.setState({ isAdditionalDocActive: !isAdditionalDocActive })} fullWidth style={{ height: '64px' }}>
                                                            <Flex center gap='8px'>
                                                                <CheckCircleOutlined style={{ color: "#318F2F", fontSize: '20px', marginRight: "10px" }} />
                                                                <Text weight='500' size='20px'>Additional documents submitted</Text>
                                                            </Flex>
                                                            <Flex center gap='24px'>
                                                                <ImageButton src={DownIcon} transform={`rotate(${isAdditionalDocActive ? 180 : 0}deg)`} />
                                                            </Flex>
                                                        </Flex>
                                                        {
                                                            isAdditionalDocActive &&
                                                            <>
                                                                <Divider margin='24px 0 24px' />
                                                                <AdditionalDocumentSubmitted loanApplication={loanApplication} />
                                                            </>
                                                        }
                                                    </FlexColumn>
                                                </LightContainer>
                                                :
                                                null
                                        }
                                    </> : <><FlexColumn start style={{ width: 600, marginBottom: 24 }}>
                                        <FlexColumn style={{ marginBottom: 10 }}>
                                            <Flex centerHorizontally style={{ justifyContent: "left" }}>
                                                <Tag style={{ marginLeft: "0px", fontSize: "1rem", height: "35px" }}
                                                    background={tagBackgroundColor}
                                                    color={tagTextColor}>
                                                    {tagText}</Tag>
                                            </Flex>
                                        </FlexColumn>
                                        <Text weight='500' size='20px' height='40px' color='black' style={{ marginBottom: 10 }}>{applicationStarted === true ? 'Complete Application' : 'Finalize Application'}</Text>
                                        {
                                            (loanApplication.underwritingStatus != "Auto Rejected" && loanApplication.underwritingStatus != "Manually Rejected" && loanApplication.underwritingStatus != "Proposal Declined") &&
                                            <Text>Congratulations on being pre-qualified. We need some information to complete our<br />review. This is the final step of the application process .</Text>
                                        }

                                        <Button width='214px' margin='24px 0 0 0' solid
                                            text={buttonTitle}
                                            rightIcon={<ArrowRightOutlined style={{ color: 'white' }} />} onClick={this.startApplication} />
                                    </FlexColumn>
                                        {loanApplication.productType === "GNPL" ?
                                            <FlexColumn className={userStatus === 'FinanDocsUploaded' ? 'helpful-info-back' : 'helpful-info-back-grey'} fullWidth>
                                                <Flex start row> <InfoCircleOutlined style={{ color: '#7384AA', marginTop: 10, marginRight: 10, fontSize: "20px" }} /> <Text spacing="0.04em" icon={<InfoCircleOutlined />} weight='400' color='#7384AA' size='20px' height='40px' style={{ marginBottom: 4 }}> Helpful information to complete your application </Text>
                                                </Flex>
                                                <Text style={{ marginBottom: "24px" }}>Make sure you have the following documents handy to complete your application</Text>
                                                <Flex start row> <CheckOutlined style={{ color: '#7384AA', marginTop: 5, marginRight: 11.75, fontSize: "16px" }} /><Text style={{ marginBottom: "24px" }}>Bank Statements – all accounts, 12 previous months</Text></Flex>
                                                <Flex start row> <CheckOutlined style={{ color: '#7384AA', marginTop: 5, marginRight: 11.75, fontSize: "16px" }} /><Text style={{ marginBottom: "24px" }}>Entity tax returns – 2 prior years with all schedules</Text></Flex>
                                                <Flex start row> <CheckOutlined style={{ color: '#7384AA', marginTop: 5, marginRight: 11.75, fontSize: "16px" }} /><Text style={{ marginBottom: "24px" }}>Company debt schedule</Text></Flex>
                                                <Flex start row> <CheckOutlined style={{ color: '#7384AA', marginTop: 5, marginRight: 11.75, fontSize: "16px" }} />
                                                    {proposalTerms != undefined ? <Text style={{ marginBottom: "24px" }}>

                                                        {

                                                            proposalTerms.creditLimit > 100000 ? "Driver’s license, personal financial statements and tax returns for individuals who own 25% or more of your business"

                                                                : "Driver’s license for individuals who own 25% or more of your business"}

                                                    </Text> : null}
                                                </Flex>
                                            </FlexColumn>
                                            : <></>}</>}
                        </>
                        :
                        <>
                            <LabeledInput
                                type='upload'
                                label='EIN Verification'
                                uploadText='We accept 147c letters and ,CP 575 IRS documents as well'
                            />
                        </>
                }
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer,
        creditStore: state.creditAppReducer,
        userDefaults: state.userDefaultsReducer,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(CompleteApplication)));