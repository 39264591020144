import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { withTheme } from 'styled-components'
import { Text } from '../../Reusable/Text'
import { ErrorAlert } from '../../Reusable/Alert'
import 'draft-js/dist/Draft.css'
import moment from 'moment'
import { saveAs } from 'file-saver'
import { Modal, message, Skeleton, Affix, Timeline, Drawer, Tabs, Table, Dropdown, Menu } from 'antd';

// Util
import { addDataToStore, SAVE_DATA } from '../../../Actions/actions';
import { copyToClipboard, toCurrency } from '../../../Utils/util'
import { apiGET, apiGETDocUrl, apiPOSTReq } from '../../../Utils/api';
import environment from '../../../environment';

import PageHeader from "../../Reusable/PageHeader";
import { LabeledInput } from '../../Reusable/Input';
import { Flex, FlexColumn, CardContainer, LightContainer } from '../../Reusable/Container';
import { Tag } from '../../Reusable/Text';
import { Button, TextButton, ImageButton } from '../../Reusable/Button';
import { Image } from '../../Reusable/Image';
import AlertModal from '../../Reusable/AlertModal'
import SendToModal from '../../Reusable/SendToModal'


// Actions
import 'react-multi-email/style.css'
import ModalClose from '../../../Images/modal-close.png'
import Search from '../../../Images/search.png'
import Gift from '../../../Images/referrals-gift.svg'
import Dollar from '../../../Images/referrals_dollar.svg'
import EmailRead from '../../../Images/email-read.svg'
import EmailDelivered from '../../../Images/email-delivered.png'
import EmailClicked from '../../../Images/email-clicked.svg'
import EmailSent from '../../../Images/email-sent.svg'
import More from '../../../Images/more.png'
import Upload from "../../../Images/upload.svg"
import ChevronDown from "../../../Images/chevron-down.svg"
import PayerMsg from '../../../Images/payer-message-sent.svg'
import TableView from '../../Reusable/TableView'
import Copy from "../../../Images/copy.svg"

const { TabPane } = Tabs

class Index extends Component {
    ref = null
    loadingAnimation = null

    constructor(props) {
        super(props)
        this.state = {
            pagination: {
                current: 1,
                pageSize: 10,
            },
            customerPagination: {
                current: 1,
                pageSize: 50,
            },
            customers: [],
            payersAwaitingAcknowledgement: [],
            payersUnderReview: [],
            payers: [],
            payersAwaitingAcknowledgementCount: null,
            payersUnderReviewCount: null,
            payersCount: null,
            tab: this.props.location?.state?.tab || 'REQUESTED',
            showSentForReviewModal: false,
            showPaymentProofModal: false,
            showResendModal: false,
            subject: 'Change in payment method',
        };
    }

    componentDidMount() {
        var { tab } = this.state
        this.getReferralCode()
        this.getReferralStatus()
        var { businessName } = this.props.aionStore
        this.setState({ fromName: businessName })
    }

    getReferralStatus = () => {
        const body = {
        }

        this.setState({ loading: true })
        apiPOSTReq(`${environment.uamBaseUrl}/aion/system/getReferralStatus`, null, body, (err, resp) => {
            try {
                const data = resp || {}
                console.log("ReferralS getReferralStatus", data)
                if (data.result) {
                    this.setState({ referrals: data.referralStatus })
                } else {
                    throw Error(data.error || data.responseMessage || "Could not fetch payers")
                }
            } catch (error) {
                console.log("ERRR getPayors", error, err, resp)
                ErrorAlert({ description: error.message })
            } finally {
                this.setState({ loading: false })
            }
        })
    }

    getReferralCode = () => {
        console.log("/aion/system/getReferralCode body")
        apiPOSTReq(`${environment.uamBaseUrl}/aion/system/getReferralCode`, null, {}, (err, resp) => {
            try {
                const data = resp || {};
                if (data.result) {
                    console.log("/aion/system/getReferralCode data", data)
                    this.setState({ referralsData: { referralCode: data.referralCode, inviteCount: data.inviteCount, referralAmount: data.referralAmount }})
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("getReferralCode error", { description: error.message })
            }
        })
    }

    render() {
        const { moreToLoad, tab, showSendToModal, referrals, loading, showThankYouModal, referralsData } = this.state
        const { aionStore } = this.props
        var { referralCode, inviteCount, referralAmount } = referralsData || {}

        const columns = [
            {
                title: 'Email address',
                dataIndex: 'referredBusinessEmail',
                key: 'referredBusinessEmail',
                // render: (referredBusinessEmail, record) => {
                //     return <FlexColumn start>
                //         <a style={{ textDecoration: "underline" }} onClick={() => this.props.history.push({
                //             pathname: '/receivables/customers/details',
                //             state: { customer: record }
                //         })}>{displayName}</a>
                //         <Text>{record?.primaryEmailAddress?.address}</Text>
                //     </FlexColumn>
                // },
            },
            {
                title: 'Invited on',
                dataIndex: 'createdAt',
                key: 'createdAt',
                render: (createdAt, record) => createdAt ? moment(createdAt).format('ll') : '--',
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (status, record) => {
                    return <Tag
                        background={['Paid', 'Completed'].includes(status) && '#F0F8F3'}
                        color={['Paid', 'Completed'].includes(status) && '#6DB58B'}
                        primary={!['Paid', 'Completed'].includes(status)}>{status}
                    </Tag>
                },
            },
        ]

        const gettingStarted = false

        console.log("ReferralS getReferralStatus referralCode", referralCode)

        return (
            <FlexColumn start className='main-padding' grow fullHeight>
                <Skeleton loading={loading} active title={false} paragraph={{ rows: 7 }}>
                    {
                        gettingStarted ?
                            <>
                                <PageHeader
                                    titleText="Referrals"
                                />

                                <FlexColumn center gap='48px' fullHeight style={{ marginBottom: 100 }}>
                                    <FlexColumn start center style={{ width: '500px' }}>
                                        <Text heading size='20px'>Invite others to the future of business finances</Text>
                                        <Text style={{ marginBottom: 24 }} size='16px'>Get rewarded for every referral that submits an application</Text>
                                    </FlexColumn>

                                    <Flex gap='24px'>
                                        <LightContainer style={{ width: '296px' }}>
                                            <FlexColumn start centerHorizontally>
                                                <Image width='80px' height='80px' src={Gift} />
                                                <Text caption weight='700' margin='16px 0 8px'>Refer us to others</Text>
                                                <Text size='12px' color='#8181A0' center>Recommend our platform to other<br />businesses you believe could benefit from<br />partnering with us.</Text>
                                            </FlexColumn>
                                        </LightContainer>

                                        <LightContainer style={{ width: '296px' }}>
                                            <FlexColumn start centerHorizontally>
                                                <Image width='80px' height='80px' src={Dollar} />
                                                <Text caption weight='700' margin='16px 0 8px'>Get <Text primary weight='700'>${referralCode?.amount}</Text> for every referral</Text>
                                                <Text size='12px' color='#8181A0' center>Once your referral has successfully<br />completed their application, your Aion<br />account will be credited with ${referralCode?.amount}!</Text>
                                            </FlexColumn>
                                        </LightContainer>
                                    </Flex>

                                    <Button text='Email Invite' solid onClick={() => this.setState({ showSendToModal: true })} />

                                    <Flex center gap='8px'>
                                        <Text>Your referral code:</Text>
                                        <Text size='20px' heading>{referralCode?.id}</Text>
                                        {
                                            referralCode?.referralUrl &&
                                            <ImageButton src={Copy} onClick={() => copyToClipboard(referralCode?.referralUrl)} />
                                        }
                                    </Flex>
                                </FlexColumn>
                            </>
                            :
                            <>
                                <PageHeader
                                    titleText="Referrals"
                                />

                                <Flex between fullWidth style={{ marginBottom: 48 }} gap='24px' stretch>
                                    <FlexColumn style={{ marginTop: 0 }} grow>
                                        <Flex start fullWidth gap='24px'>
                                            <CardContainer style={{ height: 244, width: '33.4%' }} fullWidth center>
                                                <FlexColumn start gap='10px'>
                                                    <Text heading>Your referral bonus</Text>
                                                    <Text margin='8px 0 0'>Total referral bonus earned</Text>
                                                    <Text size='32px' height='40px'>{referralAmount && referralAmount > 0 ? `$${toCurrency(referralAmount)}` : '-'}</Text>
                                                    <Text>Total referral invites sent</Text>
                                                    <Text size='32px' height='40px'>{referralCode ? referralCode.inviteCount : '-'}</Text>
                                                </FlexColumn>
                                            </CardContainer>
                                            <CardContainer style={{ height: 244, width: '66.6%' }} fullWidth center>
                                                <Flex between fullHeight>
                                                    <FlexColumn left start style={{ minWidth: 400 }}>
                                                        <Text heading>Invite others to Aion!</Text>
                                                        <Text lightText>Refer other businesses to Aion and get rewarded!</Text>

                                                        <Button margin='48px 0 18px' text='Email Invite' solid onClick={() => this.setState({ showSendToModal: true })} />

                                                        <Flex center gap='8px'>
                                                            <Text>Your referral code:</Text>
                                                            <Text size='20px' heading>{referralCode?.id}</Text>
                                                            {
                                                                referralCode?.referralUrl &&
                                                                <ImageButton src={Copy} onClick={() => copyToClipboard(referralCode?.referralUrl)} />
                                                            }
                                                        </Flex>
                                                    </FlexColumn>

                                                    <div style={{ height: '100%', width: 1, background: '#E6E6EC', margin: '0 24px' }} />

                                                    <FlexColumn between fullHeight style={{ paddingRight: 24, minWidth: 400 }}>
                                                        <Flex start centerHorizontally gap='16px'>
                                                            <Image width='64px' height='64px' src={Gift} />
                                                            <FlexColumn left start>
                                                                <Text caption weight='700' margin='0 0 0'>Refer us to others</Text>
                                                                <Text size='12px' color='#8181A0' height='16px'>Share the benefits of Aion’s all-in-one finance<br />platform with other businesses!</Text>
                                                            </FlexColumn>
                                                        </Flex>

                                                        <Flex start centerHorizontally gap='16px'>
                                                            <Image width='64px' height='64px' src={Dollar} />
                                                            <FlexColumn left start>
                                                                <Text caption weight='700' margin='0 0 0'>Get <Text primary weight='700'>${referralCode?.amount}</Text> for every referral</Text>
                                                                <Text size='12px' color='#8181A0' height='16px'>Once your referral has opened their account<br />and completed their first 10 transactions, we<br />will deposit a ${referralCode?.amount} bonus in your account and<br />another ${referralCode?.amount} bonus to your referral's account.</Text>
                                                            </FlexColumn>
                                                        </Flex>
                                                    </FlexColumn>
                                                </Flex>
                                            </CardContainer>
                                        </Flex>

                                        <FlexColumn style={{ marginTop: 24 }}>
                                            <TableView
                                                id="customer-table"
                                                titleText="Your referrals"
                                                descText="Track your referrals in one place"
                                                tableLayout='auto'
                                                columns={columns}
                                                dataSource={referrals}
                                                rowKey='customerId'
                                                pagination={false}
                                                loading={loading}
                                            />

                                            {
                                                moreToLoad &&
                                                <Flex fullWidth centerVertically style={{ marginTop: "10px" }}>
                                                    <TextButton text='LOAD 10 MORE' loading={loading} onClick={this.loadMorePayers} rightIcon={<img style={{ cursor: 'pointer' }} width='24px' height='24px' src={ChevronDown} />} />
                                                </Flex>
                                            }
                                        </FlexColumn>
                                    </FlexColumn>
                                </Flex>
                            </>
                    }

                    <Modal
                        visible={showSendToModal}
                        footer={null}
                        closable={true}
                        maskClosable={true}
                        width={616}
                        destroyOnClose={true}
                        onCancel={() => this.setState({ showSendToModal: false })}
                        closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                    >
                        <SendToModal onCancel={() => {
                            this.getReferralCode()
                            this.getReferralStatus()
                            this.setState({ showSendToModal: false })
                        }} />
                    </Modal>

                    <AlertModal
                        visible={showThankYouModal}
                        title='Thank you for your referral'
                        description={`An invitation/s has been sent to sign up for a new Aion account.`}
                        buttonTitle='Okay'
                        onConfirm={() => this.setState({ showThankYouModal: false })}
                        imgSrc={PayerMsg}
                        imgHeight='80px'
                        imgWidth='80px'
                    />
                </Skeleton>
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.creditAppReducer,
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)))