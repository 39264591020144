import React, { useState } from 'react'
import { message } from 'antd'
import moment from 'moment'

import { Text } from '../../Reusable/Text'
import { Image } from '../../Reusable/Image'
import { FlexColumn } from '../../Reusable/Container'
import Void from '../../../Images/void.png'
import { TextButton, Button } from '../../Reusable/Button'

const VoidInvoice = (props) => {
    const { onCancel, invoice, onVoid, loading } = props

    return (
        <FlexColumn center >
            <Image margin='24px 0 12px' width='48px' height='48px' src={Void} />
            <Text size='28px' height='40px' weight='500' style={{ marginBottom: 24, marginTop: 8 }}>Void invoice?</Text>
            <Text center margin='0 12px 36px'>Are you sure you want to void this invoice ? The customer will be unable to pay it and all payments will be unlinked from this invoice. This action cannot be undone</Text>
            <Button
                solid
                text='YES'
                onClick={() => onVoid(invoice)}
                loading={loading} />
            <TextButton onClick={onCancel} margin='24px 0 24px' text='NO' />
        </FlexColumn>
    )
}

export default VoidInvoice