import React from 'react';
import _ from 'underscore';
import moment from "moment";
import momentTimezone from 'moment-timezone';
import { upperFirst, camelCase } from 'lodash';
import store from '../Reducers/store';
import { Text } from '../Components/Reusable/Text'
import { Select, message } from 'antd'

import { RiseOutlined, FallOutlined } from '@ant-design/icons';
import { FlexColumn } from '../Components/Reusable/Container';

import AionIcon from '../Images/aion-bank-icon.png'
import BankIcon from '../Images/bank-generic.png'
import environment from '../environment';

const { Option } = Select

// Onboarding helper
export function getCurrentOnboardingStep(ARCreditApplicationSubmitted, store) {
    const { RequestedCustomers, ApprovedCustomers } = store;
    var currentStep = 0;
    if (ARCreditApplicationSubmitted) currentStep = 1;
    // if((RequestedCustomers || []).length > 0 || (ApprovedCustomers || []).length > 0) currentStep = 2;
    return currentStep;
}

export function formatPhoneNumber(phoneNumberString, returnWithIntlCode) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
        var intlCode = (match[1] ? '+1 ' : '')
        if (returnWithIntlCode) {
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
        } else {
            return ['(', match[2], ') ', match[3], '-', match[4]].join('')
        }
    }
    return phoneNumberString
}

// Tests the validity of a single email as well as a comma seperated one
export function isEmailValid(emails) {
    emails = emails || "";
    const emailArr = emails.split(",");
    if (emailArr.length === 0) {
        return false;
    }
    var validated = [];
    emailArr.forEach(email => {
        const valid = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email.trim());
        validated.push(valid);
    });
    return !validated.includes(false);
}

export function getValidFullName(name) {
    name = name ? name : ''
    name = name.trim()
    name = name.replace(/ +/g, ' ')
    if (/^[a-zA-Z]+ [a-zA-Z]+$/.test(name)) {
        return name
    } else {
        return null
    }
}

export function getNameObj(fullname) {
    fullname = (fullname || "").split(' ')
    return {
        FirstName: fullname[0],
        LastName: fullname[1]
    }
}

export function getNameObj2(fullname) {
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    if (fullname) {
        fullname = fullname.split(' ') || []
        var names = []
        fullname.forEach(name => { names.push(capitalizeFirstLetter(name)) })
        return {
            firstName: names.shift() || "",
            spacer: names.length >= 1 ? ' ' : '',
            lastName: names.join(' ')
        }
    } else {
        return { firstName: '', spacer: '', lastName: '' }
    }
}

export function getAddressObj(location) {
    if (!location) {
        return null
    }
    return {
        "line1": location.line1 || location.streetNumber && location.street && `${location.streetNumber} ${location.street}`,
        "line2": location.line2,
        "city": location.city,
        "countrySubDivisionCode": location.countrySubDivisionCode || location.state,
        "state": location.state,
        "postalCode": location.postalCode,
        "meta": location.meta,
        "country": location.country,
        "countryCode": location.countryCode,
    }
}

export function addressObjectToStr(addrObj) {
    addrObj = addrObj || {};
    var addr = {};
    Object.keys(addrObj).forEach(key => {
        addr[camelCase(key)] = addrObj[key]
    });
    if (addr)
        return addr.line1 ? `${addr.line1 || ""}${addr.line2 ? `, ${addr.line2}` : ""}, ${addr.city || ""}, ${addr.countrySubDivisionCode || addr.state || ""} ${addr.postalCode || ""}${(addr.country || addr.countryCode) ? `, ${addr.country || addr.countryCode}` : ""}` : ''
    return '';
}

export function crbAddressObjectToStr(addrObj) {
    addrObj = addrObj || {};
    var addr = {};
    Object.keys(addrObj).forEach(key => {
        addr[camelCase(key)] = addrObj[key]
    });
    if (addr)
        return addr.street1 ? `${addr.street1 || ""}, ${addr.city || ""}, ${addr.state || ""} ${addr.postalCode || ""}` : ''
    return '';
}

export function addressObjectToMultiLineStr(addrObj) {
    addrObj = addrObj || {};
    var addr = {};
    Object.keys(addrObj).forEach(key => {
        addr[camelCase(key)] = addrObj[key]
    });
    if (addr)
        return <FlexColumn start>
            <Text>{addr.line1 ? `${addr.line1 || ""}${addr.line2 ? `, ${addr.line2}` : ""}` : ''}</Text>
            <Text>{addr.city || ""} {addr.countrySubDivisionCode || addr.state || ""}, {addr.postalCode || ""}</Text>
        </FlexColumn>
    return '';
}

export function crbAddressObjectToMultiLineStr(addrObj, right) {
    addrObj = addrObj || {};
    var addr = {};
    Object.keys(addrObj).forEach(key => {
        addr[camelCase(key)] = addrObj[key]
    });
    if (addr)
    return <FlexColumn start>
            <Text right={right}>{addr.street1 ? `${addr.street1 || ""}${addr.street2 ? `, ${addr.street2}` : ""}` : ''}</Text>
            <Text right={right}>{addr.city || ""}, {addr.state || ""} {addr.postalCode || ""}</Text>
        </FlexColumn>
    return '';
}

export function capitalizeWords(string) {
    string = string || ""
    var words = string.split(' ') || []
    var capitalizedWords = [];
    words.forEach(word => {
        word = word.toLowerCase();
        capitalizedWords.push(word.charAt(0).toUpperCase() + word.slice(1));
    })
    return capitalizedWords.join(' ')
}

export function capitalizeFirstLetterInWords(string) {
    var words = string.split(' ') || []
    var capitalizedWords = [];
    words.forEach(word => {
        capitalizedWords.push(word.charAt(0).toUpperCase() + word.slice(1));
    })
    return capitalizedWords.join(' ')
}

export const formatCurrency = (amount) => {
    if (amount > 0)
        return "+$" + toCurrency(amount);
    else
        return "$" + toCurrency(amount);
}

export function toCurrency(str, minimumFractionDigits, maximumFractionDigits) {
    str = String(str || '').replace(/[^\d.-]/g, '');
    const currency = `${Number(str).toLocaleString('USD', { minimumFractionDigits: (typeof (minimumFractionDigits) === "number") ? minimumFractionDigits : 2, maximumFractionDigits: (typeof (maximumFractionDigits) === "number") ? maximumFractionDigits : 2 })}`;
    return currency;
};

export function toAbsCurrency(str, minimumFractionDigits, maximumFractionDigits) {
    str = String(str || '').replace(/[^\d.-]/g, '');
    const currency = `${Number(Math.abs(str)).toLocaleString('USD', { minimumFractionDigits: (typeof (minimumFractionDigits) === "number") ? minimumFractionDigits : 2, maximumFractionDigits: (typeof (maximumFractionDigits) === "number") ? maximumFractionDigits : 2 })}`;
    return currency;
};

export function toNumberStr(str, minimumFractionDigits, maximumFractionDigits) {
    str = String(str || '').replace(/[^\d.-]/g, '');
    const currency = `${Number(str).toLocaleString('USD', { minimumFractionDigits: (typeof (minimumFractionDigits) === "number") ? minimumFractionDigits : 2, maximumFractionDigits: (typeof (maximumFractionDigits) === "number") ? maximumFractionDigits : 2 })}`;
    return (currency).replace(/[^\d.-]/g, '');
};

export function nFormatter(num, digits) {
    var si = [
        { value: 1, symbol: "" },
        { value: 1E3, symbol: "k" },
        { value: 1E6, symbol: "M" },
        { value: 1E9, symbol: "G" },
        { value: 1E12, symbol: "T" },
        { value: 1E15, symbol: "P" },
        { value: 1E18, symbol: "E" }
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i;
    for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) {
            break;
        }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}

export function toNumber(str) {
    str = String(str || '').replace(/[^\d.-]/g, '');
    return Number(str);
};

// This function handles the various formats the customer name can exist in QBO
export function getCustomerName(customer) {
    var name = ((customer.CompanyName !== "") || customer.CompanyName) ? customer.CompanyName : customer.DisplayName;
    if (customer.CompanyName == "" || !customer.CompanyName) {
        if (customer.PrintOnCheckName !== "" && customer.PrintOnCheckName) {
            name = customer.PrintOnCheckName;
        } else {
            name = customer.DisplayName;
        }
    }
    // Check if display name is different
    if (name !== customer.DisplayName) {
        name = `${name}: ${customer.DisplayName}`;
    }
    return name
}

// Provides multiple that rounds to nearest 100, 1000, 10000, 100000
export function getMultiples(val, count) {
    var multiples = [];
    _.times(count, (n) => {
        n += 1.5;
        var multiplier = parseInt(val / n);
        if (multiplier === 0) multiplier = 1;
        if (multiplier.toString().length === 2) {
            multiplier = Math.round(multiplier / 10) * 10
        } else if (multiplier.toString().length === 3) {
            multiplier = Math.round(multiplier / 100) * 100
        } else if (multiplier.toString().length === 4) {
            multiplier = Math.round(multiplier / 1000) * 1000
        } else if (multiplier.toString().length === 5) {
            multiplier = Math.round(multiplier / 10000) * 10000
        } else if (multiplier.toString().length === 6) {
            multiplier = Math.round(multiplier / 100000) * 100000
        }
        multiples.push(multiplier);
    });
    return multiples;
}

// returns example.com from any website
export const getDomain = (website) => {
    var domain = null;
    if (website) {
        try {
            const urlParams = new window.URL(website);
            var hostArr = urlParams.hostname.split(".");
            if (hostArr.length === 3) hostArr.shift();
            domain = hostArr.join(".");
        } catch (error) {
            domain = null
        }
    }
    return domain
}

export function toSSN(str) {
    str = str || ""
    var val = str.replace(/\D/g, '')
    val = val.replace(/^(\d{3})/, '$1-')
    val = val.replace(/-(\d{2})/, '-$1-')
    val = val.replace(/(\d)-(\d{4}).*/, '$1-$2')
    return str
}

// Format 123-444-5555
export function formatPhoneText(value) {
    value = value.replace(/[^\d]/g, '')

    if (value.length > 3 && value.length <= 6)
        value = value.slice(0, 3) + "-" + value.slice(3)
    else if (value.length > 6)
        value = value.slice(0, 3) + "-" + value.slice(3, 6) + "-" + value.slice(6)

    return value
}

// Format (123) 444 5555
export function formatPhoneTextV2(value) {
    value = value || "";
    if (value.includes("+")) value = value.substring(2, value.length);
    value = value.replace(/[^\d]/g, '')
    if (value.length > 10) value = value.substring(0, 10);
    if (value.length > 3 && value.length <= 6)
        value = "(" + value.slice(0, 3) + ") " + value.slice(3)
    else if (value.length > 6)
        value = "(" + value.slice(0, 3) + ") " + value.slice(3, 6) + " " + value.slice(6)
    return value
}

export function formatOwnershipPercentage(value) {
    value = value ? value.toString() : "";
    if (value > 100 ) value = value.substring(0, 2);
    if (value < 100 && value.includes('.')) value = value.substring(0, 5);
    return value
}



export function formatSSN(value) {
    value = value.replace(/[^\d]/g, '')

    if (value.length > 3 && value.length <= 5)
        value = value.slice(0, 3) + "-" + value.slice(3)
    else if (value.length > 5)
        value = value.slice(0, 3) + "-" + value.slice(3, 5) + "-" + value.slice(5)

    return value
}

export function formatEIN(value) {
    value = value.replace(/[^\d]/g, '')

    if (value.length > 2)
        value = value.slice(0, 2) + "-" + value.slice(2)

    return value
}

export function isDOB18(date) {
    return new Date(date.getFullYear() + 18, date.getMonth() - 1, date.getDate()) <= new Date()
}

export function isValidUrl(url) {
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(url)
}

export const FLOW_VIEW_TYPE = {
    MAIN_FLOW: "MAIN_FLOW",
    CO_OWNER_FLOW: "CO_OWNER_FLOW",
    SUBMIT_APPLICATION_FLOW: "SUBMIT_APPLICATION_FLOW",
    SUBMIT_MULTI_OWNER_APPLICATION_FLOW: "SUBMIT_MULTI_OWNER_APPLICATION_FLOW",
    PPP_FLOW: "PPP_FLOW",
    INVOICE_FINANCING_FLOW: "INVOICE_FINANCING_FLOW",
    SIGNER_FLOW: "SIGNER_FLOW",
}

export const STATES_MAP = {
    AL: "Alabama",
    AK: "Alaska",
    AS: "American Samoa",
    AZ: "Arizona",
    AR: "Arkansas",
    CA: "California",
    CO: "Colorado",
    CT: "Connecticut",
    DE: "Delaware",
    DC: "District Of Columbia",
    FM: "Federated States Of Micronesia",
    FL: "Florida",
    GA: "Georgia",
    GU: "Guam",
    HI: "Hawaii",
    ID: "Idaho",
    IL: "Illinois",
    IN: "Indiana",
    IA: "Iowa",
    KS: "Kansas",
    KY: "Kentucky",
    LA: "Louisiana",
    ME: "Maine",
    MH: "Marshall Islands",
    MD: "Maryland",
    MA: "Massachusetts",
    MI: "Michigan",
    MN: "Minnesota",
    MS: "Mississippi",
    MO: "Missouri",
    MT: "Montana",
    NE: "Nebraska",
    NV: "Nevada",
    NH: "New Hampshire",
    NJ: "New Jersey",
    NM: "New Mexico",
    NY: "New York",
    NC: "North Carolina",
    ND: "North Dakota",
    MP: "Northern Mariana Islands",
    OH: "Ohio",
    OK: "Oklahoma",
    OR: "Oregon",
    PW: "Palau",
    PA: "Pennsylvania",
    PR: "Puerto Rico",
    RI: "Rhode Island",
    SC: "South Carolina",
    SD: "South Dakota",
    TN: "Tennessee",
    TX: "Texas",
    UT: "Utah",
    VT: "Vermont",
    VI: "Virgin Islands",
    VA: "Virginia",
    WA: "Washington",
    WV: "West Virginia",
    WI: "Wisconsin",
    WY: "Wyoming"
}

export const STATES_ABBR_MAP = {
    'Alabama': 'AL',
    'Alaska': 'AK',
    'American Samoa': 'AS',
    'Arizona': 'AZ',
    'Arkansas': 'AR',
    'California': 'CA',
    'Colorado': 'CO',
    'Connecticut': 'CT',
    'Delaware': 'DE',
    'District Of Columbia': 'DC',
    'Federated States Of Micronesia': 'FM',
    'Florida': 'FL',
    'Georgia': 'GA',
    'Guam': 'GU',
    'Hawaii': 'HI',
    'Idaho': 'ID',
    'Illinois': 'IL',
    'Indiana': 'IN',
    'Iowa': 'IA',
    'Kansas': 'KS',
    'Kentucky': 'KY',
    'Louisiana': 'LA',
    'Maine': 'ME',
    'Marshall Islands': 'MH',
    'Maryland': 'MD',
    'Massachusetts': 'MA',
    'Michigan': 'MI',
    'Minnesota': 'MN',
    'Mississippi': 'MS',
    'Missouri': 'MO',
    'Montana': 'MT',
    'Nebraska': 'NE',
    'Nevada': 'NV',
    'New Hampshire': 'NH',
    'New Jersey': 'NJ',
    'New Mexico': 'NM',
    'New York': 'NY',
    'North Carolina': 'NC',
    'North Dakota': 'ND',
    'Northern Mariana Islands': 'MP',
    'Ohio': 'OH',
    'Oklahoma': 'OK',
    'Oregon': 'OR',
    'Palau': 'PW',
    'Pennsylvania': 'PA',
    'Puerto Rico': 'PR',
    'Rhode Island': 'RI',
    'South Carolina': 'SC',
    'South Dakota': 'SD',
    'Tennessee': 'TN',
    'Texas': 'TX',
    'Utah': 'UT',
    'Vermont': 'VT',
    'Virgin Islands': 'VI',
    'Virginia': 'VA',
    'Washington': 'WA',
    'West Virginia': 'WV',
    'Wisconsin': 'WI',
    'Wyoming': 'WY'
}

export const defaultLottieOptions = (animationData, path) => {
    var defaultOpts = {
        loop: false,
        autoplay: true,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    if (animationData) defaultOpts.animationData = animationData;
    if (path) defaultOpts.path = path; // e.g - path: `Animations/${props.animationname}.json`;
    return defaultOpts;
};

export const getBBAccountName = (account) => {
    account = account || {};
    var accountClosed = ((account.status || "").toLowerCase() === "closed");
    var accountLocked = account.locked;
    return `${(account.nickName || `Business ${account.accountSubType}`).toUpperCase()} - ${account.mask}${accountClosed ? " (Closed)" : ""}${accountLocked ? " (Locked)" : ""}`;
};
export const getBBAccount = (accountid, accounts) => {
    accounts = accounts || [];
    const filteredItem = accounts.find(item => { return ((item.AccountId === accountid) || (item.accountId === accountid)) });
    return filteredItem || {};
};

export const showCreditTab = (aionStore) => {
    const allowedApps = getUserApps(aionStore);
    var Attributes = aionStore.Attributes || {};
    const businessAttributes = Attributes.Businesses || {};
    return allowedApps.includes("ARCredit")
}

export const roleHeirarchy = { "Primary Admin": 0, "Owner": 1, "Company Admin": 2, "Banking Admin": 3, "Loans Admin": 4, "Revenue and Expenses Admin": 5, "Bookkeeper": 6, "API": 7 }

export const getUserRoles = (aionStore) => {
    const { UAM, BusinessUniqueKey } = aionStore;
    if (Object.keys(UAM).length == 0) return [];
    /**
     * userAccessInfoBizWrapperList is the new structure.
     * Remove (UAM.userAccessInfoList || []) after full transition
     */
    var userRoleNames = [];
    if (UAM.aionCustomerSupportUser) {
        userRoleNames = ["Aion Support User", "Company Admin"]
    } else if (UAM.aionUnderWriterUser) {
        userRoleNames = ["Aion Underwriter"]
    } else {
        const userAccessInfoBizWrapperList = UAM.userAccessInfoBizWrapperList || [];
        const currentBizUAM = userAccessInfoBizWrapperList.find(item => (item.businessId === BusinessUniqueKey)) || {};
        const assignedRoleNamesMap = currentBizUAM.assignedRoleNamesMap || {};
        Object.keys(assignedRoleNamesMap).forEach(applicationName => {
            (assignedRoleNamesMap[applicationName] || []).forEach(appRole => {
                if (!userRoleNames.includes(appRole)) userRoleNames.push(appRole);
            })
        })
    }
    return userRoleNames;
}

export const getUserApps = (aionStore) => {
    const { UAM, BusinessUniqueKey, BizAppNames, businessSubscriptionPlan } = aionStore;
    const { currentState } = businessSubscriptionPlan || {};
    if ((currentState || "").toLowerCase() === "inactive") return [];
    if (Object.keys(UAM).length == 0) return [];
    var assignedApplications = [];
    if (UAM.aionCustomerSupportUser || UAM.aionUnderWriterUser) {
        assignedApplications = BizAppNames || []; //["Receivables", "AionAPI", "Payables", "BusinessBanking", "Bookkeeping", "TermLoans", "ARCredit"]
    } else {
        const userAccessInfoBizWrapperList = UAM.userAccessInfoBizWrapperList || [];
        const currentBizUAM = userAccessInfoBizWrapperList.find(item => (item.businessId === BusinessUniqueKey)) || {};
        const userAccessInfoList = currentBizUAM.userAccessInfoList || [];
        const userRoleNames = getUserRoles(aionStore);
        const assignedAcctResPermMap = currentBizUAM.assignedAcctResPermMap || {};
        userAccessInfoList.forEach(item => {
            const applicationName = item.applicationName;
            if (item.assignedResPermMap || userRoleNames.includes("Owner") || userRoleNames.includes("Company Admin") || userRoleNames.includes("Primary Admin")) assignedApplications.push(applicationName);
        });
        var resourcePermMap = currentBizUAM.assignedCommonResPermMap || {};
        // console.log('getUserApps resourcePermMap UTIL: ', BusinessUniqueKey, currentBizUAM, currentBizUAM.assignedCommonResPermMap, resourcePermMap, assignedAcctResPermMap)
        Object.keys(resourcePermMap).forEach(applicationName => {
            if (!assignedApplications[applicationName] && (Object.keys(resourcePermMap[applicationName]).length > 0)) { assignedApplications.push(applicationName) }
            if ((applicationName == "BusinessBanking") && (Object.keys(assignedAcctResPermMap || {}).length > 0)) {
                assignedApplications.push(applicationName)
            }
        });
        // Check for any account permissions
        if ((Object.keys(assignedAcctResPermMap || {}).length > 0)) {
            Object.keys(assignedAcctResPermMap).forEach(accountId => {
                var acctPerm = assignedAcctResPermMap[accountId] || {};
                Object.keys(acctPerm).forEach(applicationName => {
                    if((Object.keys(acctPerm[applicationName] || {}).length > 0) && !assignedApplications.includes(applicationName)) {
                        assignedApplications.push(applicationName);
                    }
                });
            });
        }
        // console.log('getUserApps resourcePermMap: assignedApplications', assignedApplications)
    }
    return assignedApplications;
}

export const getResourcePerm = (resource, options) => {
    const state = store.getState();
    const aionStore = state.aionAppReducer || {};
    var { accountId, menuPerm } = options || {};
    // e.g. resource = BusinessBanking.Transactions
    var resourcePerm = {
        view: false,
        manage: false,
        approve: false,
        isAdmin: false,
        aionSystemUser: false
    }
    if (resource == "Override") return resourcePerm = {
        view: true,
        manage: true,
        approve: false
    }
    const { UAM, BusinessUniqueKey } = aionStore;
    if (Object.keys(UAM).length == 0) return [];
    if (UAM.aionCustomerSupportUser || UAM.aionUnderWriterUser) {
        resourcePerm.view = true;
        resourcePerm.manage = true;
        resourcePerm.approve = false;
        resourcePerm.aionSystemUser = true;
    } else {
        const userRoleNames = getUserRoles(aionStore);
        var isAdmin = userRoleNames.includes("Owner") || userRoleNames.includes("Company Admin") || userRoleNames.includes("Primary Admin");
        const userAccessInfoBizWrapperList = UAM.userAccessInfoBizWrapperList || [];
        const currentBizUAM = userAccessInfoBizWrapperList.find(item => (item.businessId === BusinessUniqueKey)) || {};
        const userAccessInfoList = currentBizUAM.userAccessInfoList || [];
        const assignedCommonResPermMap = currentBizUAM.assignedCommonResPermMap || {};
        const assignedAcctResPermMap = currentBizUAM.assignedAcctResPermMap || {};
        // Create a perm map with union of the perms in userAccessInfoList and assignedCommonResPermMap
        var resourcePermMap = Object.assign({}, assignedCommonResPermMap || {});
        userAccessInfoList.forEach(item => {
            const applicationName = item.applicationName;
            // Copy and store all the resource information
            Object.keys(item.assignedResPermMap || {}).forEach(resource => {
                var resourcePermMapCopy = resourcePermMap[applicationName] || {};
                if (resourcePermMapCopy[resource]) resourcePermMapCopy[resource] = resourcePermMapCopy[resource].concat(item.assignedResPermMap[resource]); // TODO: Only copy unique values
                else resourcePermMapCopy[resource] = item.assignedResPermMap[resource];
                resourcePermMap[applicationName] = resourcePermMapCopy;
            })
            if ((applicationName == "BusinessBanking") && menuPerm) {
                // Create a union of the account permissions to display menu
                Object.keys(assignedAcctResPermMap || {}).forEach(aId => {
                    const accountPerm = assignedAcctResPermMap[aId];
                    const accountPermApp = accountPerm[applicationName];
                    Object.keys(accountPermApp || {}).forEach(permItem => {
                        var resourcePermMapCopy = resourcePermMap[applicationName];
                        if(resourcePermMapCopy) {
                            if (resourcePermMapCopy[permItem]) resourcePermMapCopy[permItem] = resourcePermMapCopy[permItem].concat(accountPermApp[permItem]); // TODO: Only copy unique values
                            else resourcePermMapCopy[permItem] = accountPermApp[permItem]
                        }
                    })
                    // resourcePermMap[applicationName] = Object.assign(resourcePermMap[applicationName] || {}, accountPerm[applicationName] || {});
                });
            } else if ((applicationName == "BusinessBanking") && (Object.keys(assignedAcctResPermMap || {}).length > 0)) {
                const accountPerm = assignedAcctResPermMap[accountId];
                if (accountPerm) resourcePermMap[applicationName] = Object.assign({}, accountPerm[applicationName] || {});
                else resourcePermMap[applicationName] = {};
                // console.log("getResourcePerm accountId", accountId, resourcePermMap);
            }
        });
        // Check for any account permissions
        if ((Object.keys(assignedAcctResPermMap || {}).length > 0)) {
            var acctPerm = assignedAcctResPermMap[accountId] || {};
            
            if(menuPerm) {
                Object.keys(assignedAcctResPermMap || {}).forEach(aId => {
                    const aPerm = assignedAcctResPermMap[aId] || {};
                    Object.keys(aPerm).forEach(applicationName => {
                        resourcePermMap[applicationName] = Object.assign({}, aPerm[applicationName] || {});
                    })
                });
            } else if(Object.keys(acctPerm).length > 0) {
                Object.keys(acctPerm).forEach(applicationName => {
                    resourcePermMap[applicationName] = Object.assign({}, acctPerm[applicationName] || {});
                });
            }
        }
        
        var resourceArr = resource.split(".") || [];
        const appName = resourceArr[0];
        const resourceName = resourceArr[resourceArr.length - 1];
        var permApp = resourcePermMap[appName] || {};
        var permItem = permApp[resourceName] || [];
        resourcePerm.view = permItem.includes("View__Sys") ;
        resourcePerm.manage = permItem.includes("Manage__Sys") || isAdmin;
        resourcePerm.approve = permItem.includes("Approval__Sys");
        resourcePerm.isAdmin = isAdmin;
        // Also check against subscription plan features
        const businessFeatureMap = currentBizUAM.businessFeatureMap || {};
        const planAppAccess = businessFeatureMap[appName] || {};
        const planResourceAccess = planAppAccess[resourceName];
        if (planResourceAccess) {
            var hasAccess = false;
            Object.keys(planResourceAccess || {}).forEach(feature => {
                if ((planResourceAccess[feature].length > 0) && !planResourceAccess[feature].includes("N")) {
                    hasAccess = true;
                }
            })
            if (!hasAccess) {
                resourcePerm.view = false;
                resourcePerm.manage = false;
            }
        }
    }
    // console.log("getResourcePerm", accountId, resource, resourcePerm)
    return resourcePerm;
}

export const getFeatureLimit = (feature, options) => {
    const state = store.getState();
    const aionStore = state.aionAppReducer || {};
    // e.g. feature = BusinessBanking.Payments.ACH
    const { UAM, BusinessUniqueKey } = aionStore;
    // console.log("getFeaturePerm UAM", UAM);
    if (Object.keys(UAM).length == 0) return 0;
    const userAccessInfoBizWrapperList = UAM.userAccessInfoBizWrapperList || [];
    const currentBizUAM = userAccessInfoBizWrapperList.find(item => (item.businessId === BusinessUniqueKey)) || {};
    var limit = 0;
    const businessFeatureMap = currentBizUAM.businessFeatureMap || {};
    var featureArr = feature.split(".") || [];
    const appName = featureArr[0];
    const resourceName = featureArr[1];
    const featureName = featureArr[2];

    const planAppAccess = businessFeatureMap[appName] || {};
    const planResourceAccess = planAppAccess[resourceName] || {};
    const planFeatureAccess = planResourceAccess[featureName];
    if (planFeatureAccess) {
        limit = planFeatureAccess[0] || 0;
    }
    // console.log("getFeaturePerm", feature, limit);
    return limit;
}

export const getFeaturePerm = (feature, options) => {
    const state = store.getState();
    const aionStore = state.aionAppReducer || {};
    // e.g. feature = BusinessBanking.Payments.ACH
    const { UAM, BusinessUniqueKey } = aionStore;
    // console.log("getFeaturePerm UAM", UAM);
    if (Object.keys(UAM).length == 0) return 0;
    const userAccessInfoBizWrapperList = UAM.userAccessInfoBizWrapperList || [];
    const currentBizUAM = userAccessInfoBizWrapperList.find(item => (item.businessId === BusinessUniqueKey)) || {};
    var featureAccess = {
        view: false,
        manage: false
    };
    const businessFeatureMap = currentBizUAM.businessFeatureMap || {};
    var featureArr = feature.split(".") || [];
    const appName = featureArr[0];
    const resourceName = featureArr[1];
    const featureName = featureArr[2];

    // console.log("getFeaturePerm userAccessInfoBizWrapperList", userAccessInfoBizWrapperList);

    const planAppAccess = businessFeatureMap[appName] || {};
    const planResourceAccess = planAppAccess[resourceName] || {};
    const planFeatureAccess = planResourceAccess[featureName];
    if (planFeatureAccess) {
        const hasAccess = (planFeatureAccess[0] == "Y");
        featureAccess.view = hasAccess;
        featureAccess.manage = hasAccess;
    }
    // console.log("getFeaturePerm", feature, featureAccess);
    return featureAccess;
}

export const getBBAccountsWithAccess = (resource, options) => {
    const state = store.getState();
    const bbStore = state.bankingAppReducer || {};
    var accounts = (bbStore || {}).Accounts || [{}];
    var { permType, getActiveAccounts } = options || {};
    // Filter by permission
    accounts = accounts.filter(account => {
        var accountClosed = (account.Status === "closed") || ((account.status || "").toLowerCase() === "closed");
        var accountLocked = account.locked || account.Locked;
        const accountPerm = getResourcePerm(`BusinessBanking.${resource || "Transactions"}`, { accountId: account.AccountId });
        // console.log("getBBAccountsWithAccess", resource, account.AccountId, accountPerm);
        if (getActiveAccounts && (accountClosed || accountLocked)) {
            return false;
        }
        switch (permType) {
            case "manage":
                return (accountPerm.manage || accountPerm.approve);
            default:
                return (accountPerm.view || accountPerm.manage || accountPerm.approve);
        }
    });
    return accounts;
}

export const getBPAccountsWithAccess = (resource, options) => {
    const state = store.getState();
    const bbStore = state.bankingAppReducer || {};
    var accounts = (bbStore || {}).BBAccounts || [{}];
    var { permType, getActiveAccounts, resourceStr, ignoreARAcct } = options || {};

    // Filter by permission
    accounts = accounts.filter(account => {
        var accountClosed = ((account.status || "").toLowerCase() === "closed");
        var accountLocked = account.locked;
        const accountPerm = getResourcePerm(resourceStr || `BusinessBanking.${resource || "Transactions"}`, { accountId: account.accountId });
        // console.log("getBPAccountsWithAccess", resource, account.accountId, accountPerm);
        if (getActiveAccounts && (accountClosed || accountLocked)) {
            return false;
        }
        switch (permType) {
            case "manage":
                return (accountPerm.manage || accountPerm.approve);
            default:
                return (accountPerm.view || accountPerm.manage || accountPerm.approve);
        }
    });

    if (accounts.find(a => a.accountSubType === 'Transitional')) {
        accounts.push(accounts.splice(accounts.indexOf(accounts.find(a => a.accountSubType === 'Transitional')), 1)[0])
    }
    if(ignoreARAcct) {
        accounts = accounts.filter(acc => acc.accountType !== "ACCOUNTS_RECEIVABLE")
    }
    return accounts;
}

export const roleDescMap = {
    "Company Admin": {
        infoList: [
            {
                title: "Banking Account Management",
                items: [
                    "View Aion Banking statements",
                    "View Banking balances",
                    "View Banking account details",
                    "View all transactions",
                    "Add memos/notes to transactions",
                    "Attach receipts to all transactions",
                    "Re-categorize transactions",
                    "Download Aion statements & transaction reports",
                    "Send money from Banking Accounts",
                    "Add funds to Banking Accounts",
                    "View every payment made"
                ]
            },
            {
                title: "Loans Management",
                items: [
                    "View all transactions",
                    "View company’s current balance, amount available to advance/draw",
                    "Download statements & transaction reports",
                    "Request or take a loan (advance/draw)",
                    "View and manage approved customers",
                    "View and manage invoices for loan advances"
                ]
            },
            {
                title: "Receivables and Payables Management",
                items: [
                    "See all transactions",
                    "Download statements & transaction reports",
                    "View and manage customers",
                    "View and manage purchase orders",
                    "View and manage invoices",
                    "Submit invoices to customers",
                    "View and manage bills",
                    "View and manage Approvals",
                    "View Money-In and Money-Out",
                    "Manage bill payments"
                ]
            },
            {
                title: "User and Systems Management",
                items: [
                    "Invite new users",
                    "Invite a Bookkeeper",
                    "Assign & edit user roles",
                    "Deactivate users",
                    "Setup & manage accounting software integrations",
                    "View and manage Company Profile"
                ]
            },
            {
                title: "Bookkeeping and Accounting",
                items: [
                    "Oversee your company's financial data and compliance by maintaining accurate books and reconciliations.",
                    "Perform daily accounting tasks such as monthly financial reporting, general ledger entries, and record payments and adjustments."
                ]
            }
        ]
    },
    "Banking Admin": {
        infoList: [
            {
                title: "Banking Account Management",
                items: [
                    "View Aion Banking statements",
                    "View Banking balances",
                    "View Banking account details",
                    "View all transactions",
                    "Add memos/notes to transactions",
                    "Attach receipts to all transactions",
                    "Re-categorize transactions",
                    "Download Aion statements & transaction reports",
                    "View and manage recipients",
                    "Send money from Banking Accounts",
                    "Add funds to Banking Accounts",
                    "See every payment made"
                ]
            }
        ]
    },
    "Loans Admin": {
        infoList: [
            {
                title: "Loans Management",
                items: [
                    "View all transactions",
                    "View company’s current balance, amount available to advance/draw",
                    "Download statements & transaction reports",
                    "Request or take a loan (advance/draw)",
                    "View and manage approved customers",
                    "View and manage invoices for loan advances"
                ]
            }
        ]
    },
    "Revenue and Expenses Admin": {
        infoList: [
            {
                title: "Receivables and Payables Management",
                items: [
                    "See all transactions",
                    "Download statements & transaction reports",
                    "View and manage customers",
                    "View and manage purchase orders",
                    "View and manage invoices",
                    "Submit invoices to customers",
                    "View and manage bills",
                    "View and manage Approvals",
                    "View Money-In and Money-Out",
                    "Manage bill payments"
                ]
            },
            {
                title: "Bookkeeping and Accounting",
                items: [
                    "Oversee your company's financial data and compliance by maintaining accurate books and reconciliations.",
                    "Perform daily accounting tasks such as monthly financial reporting, general ledger entries, and record payments and adjustments."
                ]
            }
        ]
    },
    "Bookkeeper": {
        infoList: [
            {
                title: "Bookkeeping and Accounting",
                items: [
                    "Oversee your company's financial data and compliance by maintaining accurate books and reconciliations.",
                    "Perform daily accounting tasks such as monthly financial reporting, general ledger entries, and record payments and adjustments."
                ]
            }
        ]
    },
    "API": {
        infoList: [
            {
                title: "API Access",
                items: [
                    "View Banking balances",
                    "View Banking account details",
                    "View all transactions",
                ]
            }
        ]
    }
}

export const appNameMap = {
    "Receivables": "Receivables",
    "Payables": "Payables",
    "BusinessBanking": "Business Banking",
    "ARCredit": "AR Credit",
    "Bookkeeping": "Bookkeeping",
    "AionAPI": "API",
    "TermLoans": "Short Term Loans",
    "ABLCredit": "ABL Credit",
}

export const uamAppMap = {
    "Owner": ["Owner"],
    "Primary Admin": ["Primary Admin"],
    "Company Admin": ["Company Admin"],
    "Loans Admin": ["Loans Admin"],
    "Banking Admin": ["Banking Admin"],
    "Revenue and Expenses Admin": ["Revenue and Expenses Admin"],
    "Bookkeeper": ["Bookkeeper"],
    "API": ["API"]
}

export const dateFormatList = ['MM/DD/YYYY', 'MM/DD/YY', 'MM-DD-YYYY', 'MM-DD-YY'];

export const getGreeting = () => {
    let hour = moment().hour();
    const emojiStyle = { fontSize: "1.7rem", paddingLeft: 10 }
    if (hour >= 0 && hour < 5)
        return <>Good morning<span style={emojiStyle}>🌙</span></>
    if (hour >= 5 && hour < 12)
        return <>Good morning<span style={emojiStyle}>🌞</span></>
    if (hour >= 12 && hour < 17)
        return <>Good afternoon<span style={emojiStyle}>🌤</span></>
    if (hour >= 17 && hour < 24)
        return <>Good evening<span style={emojiStyle}>🌙</span></>
    else
        return <>Welcome back</>
}

export const removeArrItem = (array, item) => {
    var index = array.indexOf(item);
    if (index !== -1) {
        array.splice(index, 1);
    }
}

export const isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;
export const isTablet = window.matchMedia("only screen and (max-width: 1024px)").matches;
export const isSmallScreen = window.matchMedia("only screen and (max-width: 1600px)").matches;

export const camelCaseToPascalCase = (obj) => {
    if (typeof (obj) != "object") return obj;
    var newName;
    for (var oldName in obj) {
        // Camel to underscore
        newName = upperFirst(oldName);

        // Only process if names are different
        if (newName != oldName) {
            // Check for the old property name to avoid a ReferenceError in strict mode.
            if (obj.hasOwnProperty(oldName)) {
                obj[newName] = obj[oldName];
                delete obj[oldName];
            }
        }

        if (typeof (obj[newName]) == "object") {
            obj[newName] = camelCaseToPascalCase(obj[newName]);
        }

    }
    return obj;
}

export const toCamelCase = (obj) => {
    if (typeof (obj) != "object") return obj;
    var newName;
    for (var oldName in obj) {
        // Camel to underscore
        newName = camelCase(oldName);

        // Only process if names are different
        if (newName != oldName) {
            // Check for the old property name to avoid a ReferenceError in strict mode.
            if (obj.hasOwnProperty(oldName)) {
                obj[newName] = obj[oldName];
                delete obj[oldName];
            }
        }

        if (typeof (obj[newName]) == "object") {
            obj[newName] = toCamelCase(obj[newName]);
        }

    }
    return obj;
}

export const defaultPaymentTerms = ["Due on receipt", "Net 15", "Net 30", "Net 45", "Net 60", "Net 75", "Net 90"];

export const defaultPaymentTermObjects = [
    { name: "Due on receipt", id: "id-0", termId: "termId-0", dueDays: 0 },
    { name: "Net 15", id: "id-1", termId: "termId-1", dueDays: 15 },
    { name: "Net 30", id: "id-2", termId: "termId-2", dueDays: 30 },
    { name: "Net 45", id: "id-3", termId: "termId-3", dueDays: 45 },
    { name: "Net 60", id: "id-4", termId: "termId-4", dueDays: 60 },
    { name: "Net 75", id: "id-5", termId: "termId-5", dueDays: 75 },
    { name: "Net 90", id: "id-6", termId: "termId-6", dueDays: 90 },
    { name: "Custom", id: "id-7", termId: "termId-7", dueDays: 0 },
  ];

export const defaultCustomerPaymentTerms = [
    { name: "Due on receipt", id: "id-0", termId: "termId-0", dueDays: 0 },
    { name: "Net 15", id: "id-1", termId: "termId-1", dueDays: 15 },
    { name: "Net 30", id: "id-2", termId: "termId-2", dueDays: 30 },
    { name: "Net 45", id: "id-3", termId: "termId-3", dueDays: 45 },
    { name: "Net 60", id: "id-4", termId: "termId-4", dueDays: 60 },
    { name: "Net 75", id: "id-5", termId: "termId-5", dueDays: 75 },
    { name: "Net 90", id: "id-6", termId: "termId-6", dueDays: 90 },
];

export const getApplicationDisplayName = (appName) => {
    switch (appName) {
        case "BusinessBanking":
            return "Banking"
        case "BusinessCards":
            return "Cards"
        case "ARCredit":
            return "Credit"
        case "ABLCredit":
            return "ABL Credit"
        case "TermLoans":
            return "Short-Term Loans"
        case "AionAPI":
            return "API"
        case "Payables":
            return "Bill Payments"
        default:
            return appName
    }
}

export const isBeforeCutOff = () => {
    return (moment().date() === momentTimezone().tz("America/New_York").date()) &&  // If same day
        (momentTimezone().tz("America/New_York").hour() < 18 || // AND before cut off time 
            momentTimezone().tz("America/New_York").hour() === 18 && momentTimezone().tz("America/New_York").minute() < 30)  // LOCAL TODAY DAY == NY DAY
}

export function getDate(val) {
    const dFormat = "YYYY-MM-DD";
    let fromDate, toDate;
    switch (val) {
        case "Year to Date":
            fromDate = moment().startOf("year").format(dFormat);
            toDate = moment().endOf("day").format(dFormat);
            break;
        case "Last Year":
            fromDate = moment().subtract(1, "year").startOf("year").format(dFormat);
            toDate = moment().subtract(1, "year").endOf("year").format(dFormat);
            break;
        case "Current Month":
            fromDate = moment().startOf("month").format(dFormat);
            toDate = moment().format(dFormat);
            break;
        case "Previous Month":
            const prevMonth = moment().subtract(1, "month");
            fromDate = prevMonth.startOf("month").format(dFormat);
            toDate = prevMonth.endOf("month").format(dFormat);
            break;
        default:
            fromDate = this.state.fromDate
            toDate = this.state.toDate
            break;
    }
    return { fromDate, toDate }
}

export function getApproversText(approvers, approvalBy) {
    if (approvers.length == 1) {
        return `${approvers[0].firstName} ${approvers[0].lastName}`
    } else {
        return <>
            {approvers.map(a => `${a.firstName} ${a.lastName}`).slice(0, approvers.length - 1).join(", ")}<b>{approvalBy === "ANY" ? " or " : " and "}</b>{`${approvers[approvers.length - 1].firstName} ${approvers[approvers.length - 1].lastName}`}
        </>
    }
}

export function getBase64EncodedStr(str) {
    const buff = Buffer(str);
    return buff.toString('base64');
}

export function getBase64DecodedStr(str) {
    const buff = Buffer(str, 'base64');
    return buff.toString('ascii');
}

export function getDateString(date) {
    if (date) {
        date = new Date(date).toISOString();
        date = date.split('T')[0];
    }
    return date
}

export const getTrendIcon = (trend) => {
    if (trend === 'Rising') {
        return <RiseOutlined style={{ color: "#3EAD3C", fontSize: 18 }} />
    } else {
        return <FallOutlined style={{ color: "#E52D2D", fontSize: 18 }} />
    }
}

export const getUpperChartDomain = (data, key) => {
    if (!data || data.length === 0 || !key) return 0

    var upper = Number(data[0][key])

    data.forEach(val => {
        if (Number(val[key]) > upper) {
            upper = Number(val[key])
        }
    })

    return (upper * 1.05)
}

export const getLowerChartDomain = (data, key) => {
    if (!data || data.length === 0 || !key) return 0

    var lower = Number(data[0][key])

    data.forEach(val => {
        if (Number(val[key]) < lower) {
            lower = Number(val[key])
        }
    })

    return lower - (Math.abs(lower) * 1.2)
}

export function getFullCustomerName(customer) {
    const { title, firstName, middleName, lastName, suffix } = customer
    return `${title ? `${title} ` : ''}${firstName ? `${firstName} ` : ''}${middleName ? `${middleName} ` : ''}${lastName ? `${lastName} ` : ''}${suffix ? `${suffix} ` : ''}`;
}

export function getRoleOptions(allRoles, selectedRoles) {
    var loansAdminAdded = false
    var revenueAndExpensesAdded = false
    var roleItems = []

    if (allRoles) {
        allRoles.map((role, i) => {
            if (role.roleName === "Loans Admin" && loansAdminAdded) return
            if (role.roleName === "Revenue and Expenses Admin" && revenueAndExpensesAdded) return

            if (role.roleName != "Staff" && role.roleName != "Owner" && role.roleName != "Primary Admin") {
                if (role.roleType === "Custom__Sys") {
                    roleItems.push(
                        <Option key={role.roleName} value={role.roleName}>
                            <Text>{role.roleName}</Text>
                        </Option>)
                } else {
                    var disableStandardNonCompanyAdminRoles = (selectedRoles.includes("Company Admin") && role.roleName != "Company Admin")
                    roleItems.push(
                        <Option disabled={disableStandardNonCompanyAdminRoles} key={role.roleName} value={role.roleName}>
                            <Text>{role.roleName}</Text>
                        </Option>
                    )
                }
            }

            if (role.roleName === "Loans Admin") loansAdminAdded = true
            if (role.roleName === "Revenue and Expenses Admin") revenueAndExpensesAdded = true
        })
    }

    return roleItems
}

export function sliceIntoChunks(arr, chunkSize) {
    const res = []
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize)
        res.push(chunk)
    }
    return res
}

export function copyToClipboard(text) {
    navigator.clipboard.writeText(text)
    message.success('Copied to clipboard')
}

export function getInitials(string) {
    var names = string.split(' '),
        initials = names[0].substring(0, 1).toUpperCase()

    if (names.length > 1) {
        initials += names[1].substring(0, 1).toUpperCase()
    }
    return initials
}

export function getTotalOwnershipPercentage(options) {
    var options = options || {}
    var { preboardUsers } = options
    const state = store.getState()
    const onboardingStore = state.onboardingAppReducer || {}
    var { personalInfo, coOwnersInfo, signersInfo } = onboardingStore
    var allOwners = [personalInfo]
    allOwners = allOwners.concat(coOwnersInfo || []).concat(signersInfo || [])
    if(preboardUsers) {
        preboardUsers = (preboardUsers || []).filter(user => user.status != 'ProcessedPreseed')
        var invitedOwners = []
        preboardUsers.forEach(user => {
            var applicant = Object.assign({}, user.applicant)
            if(applicant.ownershipPercentage) {
                applicant.email = user.email
                invitedOwners.push(applicant)
            } else if(user.beneficialOwner) {
                // If ownership % was not filled up assume 25%
                applicant.ownershipPercentage = 25
                applicant.email = user.email
                invitedOwners.push(applicant)
            }
        })
        allOwners = allOwners.concat(invitedOwners)
    }

    var combinedOwnership = 0
    var dupeTracker = []
    allOwners.forEach(owner => {
        if(!dupeTracker.includes(owner.email)) {
            dupeTracker.push(owner.email)
            combinedOwnership += Number(owner.ownershipPercentage)
        }
    })
    // console.log("combinedOwnership", options, combinedOwnership, allOwners, coOwnersInfo)
    return combinedOwnership
}

export function getDisplayStatus(status, credit) {
    let displayStatus = '';
    switch ((status || '').toLowerCase()) {
        case 'pending_approval':
            displayStatus = 'Pending Approval';
            break;
        case 'pending':
        case 'created':
        case 'processing':
            displayStatus = 'Processing';
            break;
        case 'hold':
            displayStatus = 'In Review';
            break;
        case 'error':
            displayStatus = 'Error';
            break;
        case 'rejected':
        case 'denied_approval':
            displayStatus = 'Rejected';
            break;
        case 'canceled':
            displayStatus = 'Canceled';
            break;
        case 'sent':
        case 'complete':
        case 'completed':
            displayStatus = credit ? 'Received' : 'Sent';
            break;
        default:
            displayStatus = status && status.charAt(0).toUpperCase() + status.slice(1);
    }
    return displayStatus;
}

export function renderBankIcon (bankDetail, width = '16px') {
    let imgSrc;
    let marginBottom = 0;

    if (bankDetail.routingNumber === '021214891') {
        imgSrc = AionIcon;
        marginBottom = 2;
    } else if (bankDetail.logoUrl) {
        imgSrc = bankDetail.logoUrl;
    } else {
        imgSrc = BankIcon;
    }

    return <img width={width} height={width} src={imgSrc} style={{ marginRight: 8, marginBottom }} />;
}

export function getTextWidth(text, fontSetting = '14px InterDisplay') {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = fontSetting;
    return context.measureText(text).width;
}

export function getFilenameFromUrl(urlString) {
    try {
        const url = new URL(urlString);
        const pathname = url.pathname;
        return pathname.split('/').pop();
    } catch (e) {
        console.error("Invalid URL provided", e);
        return null;
    }
}

export function isEqual(obj1, obj2, fields) {
    // Check if either object is null or undefined
    if (obj1 == null || obj2 == null) {
        // Return false if one object is null and the other is not
        return obj1 === obj2;
    }

    const extractFields = (obj, fields) => {
        return fields.reduce((filtered, field) => {
            if (obj.hasOwnProperty(field)) {
                filtered[field] = obj[field];
            }
            return filtered;
        }, {});
    };

    const obj1Selected = extractFields(obj1, fields);
    const obj2Selected = extractFields(obj2, fields);

    return JSON.stringify(obj1Selected) === JSON.stringify(obj2Selected);
}

export function checkAccessForRoles(roles, resourceName, roleObjects) {
    // Initialize the access object with all permissions set to false
    const access = { view: false, manage: false, approve: false };
  
    // Iterate through the role objects to find matches with the specified roles
    (roleObjects || []).forEach(role => {
        if (roles.includes(role.roleName)) {
            // For each matching role, iterate through its resourcePermissions
            role.resourcePermissions.forEach(permission => {
                // Check if the resourceName matches
                if (permission.resourceName === resourceName) {
                    // Update the access object based on the permissionCodes
                    permission.permissionCodes.forEach(code => {
                        if (code === "View__Sys") {
                            access.view = true;
                        } else if (code === "Manage__Sys") {
                            access.manage = true;
                        } else if (code === "Approval__Sys") {
                            access.approve = true;
                        }
                    });
                }
            });
        }
    });
  
    return access;
}

export function showCodatConnectionBanner(store) {
    var { connection, hasIntegration, connectASBannerDismissed } = store
    const userRoles = getUserRoles(store)
    const isCompanyAdmin = userRoles.includes("Company Admin") || userRoles.includes("Owner") || userRoles.includes("Primary Admin")
    const isFeatureEnabled = getFeaturePerm("AionApplications.Onboarding.Codat").view
    // console.log("showCodatConnectionBanner", isFeatureEnabled, isCompanyAdmin, connectASBannerDismissed, hasIntegration, connection)

    return isFeatureEnabled && isCompanyAdmin && !connectASBannerDismissed && !hasIntegration && (!connection || connection.status === 'PendingAuth')
}

export function isCodatUser(store) {
    var { connection } = store
    const isFeatureEnabled = getFeaturePerm("AionApplications.Onboarding.Codat").view

    return connection // isFeatureEnabled && connection
}

export function getCodatPlatformName(store) {
    var { connection } = store
    const { platformName } = connection || {}

    return platformName
}

export function getSourceDisplayText(source, store) {
    const { connection } = store
    const { platformName } = connection

    switch((source || '').toLowerCase()) {
        case 'aion':
            return 'AION'
        case 'codat':
            return platformName
        default:
            return "Accounting Software"
    }
}

export function capitalizeFirstLetter(string) {
    if (typeof string !== 'string') return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export function getDateInLocalTZ(dt, dateFormat) {
    if(!dt) return dt
    const localTZ = momentTimezone.tz.guess()
    var locatDate
    if(dt.length <= 10) { // assumes YYYY-MM-DD or similar format
        locatDate = momentTimezone.tz(dt, localTZ).format(dateFormat || environment.defaultDateFormat)
    } else {
        // if passed date has a time component
        locatDate = momentTimezone.utc(dt).tz(localTZ).format(dateFormat || environment.defaultDateFormat)
    }

    return locatDate
}

export function getDateByTZ(dt, dateFormat, tz) {
    if (!dt) return dt;
    
    const timezone = tz || momentTimezone.tz.guess();
    let date;

    if (dt.length <= 10) { // assumes YYYY-MM-DD or similar format
        date = momentTimezone.tz(dt, timezone).format(dateFormat || environment.defaultDateFormat);
    } else {
        // if passed date has a time component
        date = momentTimezone.utc(dt).tz(timezone).format(dateFormat || environment.defaultDateFormat);
    }

    return date;
}

export const countryCodeMap = [
    {
        "name": "Afghanistan",
        "code": "AF"
    },
    {
        "name": "Åland Islands",
        "code": "AX"
    },
    {
        "name": "Albania",
        "code": "AL"
    },
    {
        "name": "Algeria",
        "code": "DZ"
    },
    {
        "name": "American Samoa",
        "code": "AS"
    },
    {
        "name": "AndorrA",
        "code": "AD"
    },
    {
        "name": "Angola",
        "code": "AO"
    },
    {
        "name": "Anguilla",
        "code": "AI"
    },
    {
        "name": "Antarctica",
        "code": "AQ"
    },
    {
        "name": "Antigua and Barbuda",
        "code": "AG"
    },
    {
        "name": "Argentina",
        "code": "AR"
    },
    {
        "name": "Armenia",
        "code": "AM"
    },
    {
        "name": "Aruba",
        "code": "AW"
    },
    {
        "name": "Australia",
        "code": "AU"
    },
    {
        "name": "Austria",
        "code": "AT"
    },
    {
        "name": "Azerbaijan",
        "code": "AZ"
    },
    {
        "name": "Bahamas",
        "code": "BS"
    },
    {
        "name": "Bahrain",
        "code": "BH"
    },
    {
        "name": "Bangladesh",
        "code": "BD"
    },
    {
        "name": "Barbados",
        "code": "BB"
    },
    {
        "name": "Belarus",
        "code": "BY"
    },
    {
        "name": "Belgium",
        "code": "BE"
    },
    {
        "name": "Belize",
        "code": "BZ"
    },
    {
        "name": "Benin",
        "code": "BJ"
    },
    {
        "name": "Bermuda",
        "code": "BM"
    },
    {
        "name": "Bhutan",
        "code": "BT"
    },
    {
        "name": "Bolivia",
        "code": "BO"
    },
    {
        "name": "Bosnia and Herzegovina",
        "code": "BA"
    },
    {
        "name": "Botswana",
        "code": "BW"
    },
    {
        "name": "Bouvet Island",
        "code": "BV"
    },
    {
        "name": "Brazil",
        "code": "BR"
    },
    {
        "name": "British Indian Ocean Territory",
        "code": "IO"
    },
    {
        "name": "Brunei Darussalam",
        "code": "BN"
    },
    {
        "name": "Bulgaria",
        "code": "BG"
    },
    {
        "name": "Burkina Faso",
        "code": "BF"
    },
    {
        "name": "Burundi",
        "code": "BI"
    },
    {
        "name": "Cambodia",
        "code": "KH"
    },
    {
        "name": "Cameroon",
        "code": "CM"
    },
    {
        "name": "Canada",
        "code": "CA"
    },
    {
        "name": "Cape Verde",
        "code": "CV"
    },
    {
        "name": "Cayman Islands",
        "code": "KY"
    },
    {
        "name": "Central African Republic",
        "code": "CF"
    },
    {
        "name": "Chad",
        "code": "TD"
    },
    {
        "name": "Chile",
        "code": "CL"
    },
    {
        "name": "China",
        "code": "CN"
    },
    {
        "name": "Christmas Island",
        "code": "CX"
    },
    {
        "name": "Cocos (Keeling) Islands",
        "code": "CC"
    },
    {
        "name": "Colombia",
        "code": "CO"
    },
    {
        "name": "Comoros",
        "code": "KM"
    },
    {
        "name": "Congo",
        "code": "CG"
    },
    {
        "name": "Congo, The Democratic Republic of the",
        "code": "CD"
    },
    {
        "name": "Cook Islands",
        "code": "CK"
    },
    {
        "name": "Costa Rica",
        "code": "CR"
    },
    {
        "name": "Cote D'Ivoire",
        "code": "CI"
    },
    {
        "name": "Croatia",
        "code": "HR"
    },
    {
        "name": "Cyprus",
        "code": "CY"
    },
    {
        "name": "Czech Republic",
        "code": "CZ"
    },
    {
        "name": "Denmark",
        "code": "DK"
    },
    {
        "name": "Djibouti",
        "code": "DJ"
    },
    {
        "name": "Dominica",
        "code": "DM"
    },
    {
        "name": "Dominican Republic",
        "code": "DO"
    },
    {
        "name": "Ecuador",
        "code": "EC"
    },
    {
        "name": "Egypt",
        "code": "EG"
    },
    {
        "name": "El Salvador",
        "code": "SV"
    },
    {
        "name": "Equatorial Guinea",
        "code": "GQ"
    },
    {
        "name": "Eritrea",
        "code": "ER"
    },
    {
        "name": "Estonia",
        "code": "EE"
    },
    {
        "name": "Ethiopia",
        "code": "ET"
    },
    {
        "name": "Falkland Islands (Malvinas)",
        "code": "FK"
    },
    {
        "name": "Faroe Islands",
        "code": "FO"
    },
    {
        "name": "Fiji",
        "code": "FJ"
    },
    {
        "name": "Finland",
        "code": "FI"
    },
    {
        "name": "France",
        "code": "FR"
    },
    {
        "name": "French Guiana",
        "code": "GF"
    },
    {
        "name": "French Polynesia",
        "code": "PF"
    },
    {
        "name": "French Southern Territories",
        "code": "TF"
    },
    {
        "name": "Gabon",
        "code": "GA"
    },
    {
        "name": "Gambia",
        "code": "GM"
    },
    {
        "name": "Georgia",
        "code": "GE"
    },
    {
        "name": "Germany",
        "code": "DE"
    },
    {
        "name": "Ghana",
        "code": "GH"
    },
    {
        "name": "Gibraltar",
        "code": "GI"
    },
    {
        "name": "Greece",
        "code": "GR"
    },
    {
        "name": "Greenland",
        "code": "GL"
    },
    {
        "name": "Grenada",
        "code": "GD"
    },
    {
        "name": "Guadeloupe",
        "code": "GP"
    },
    {
        "name": "Guam",
        "code": "GU"
    },
    {
        "name": "Guatemala",
        "code": "GT"
    },
    {
        "name": "Guernsey",
        "code": "GG"
    },
    {
        "name": "Guinea",
        "code": "GN"
    },
    {
        "name": "Guinea-Bissau",
        "code": "GW"
    },
    {
        "name": "Guyana",
        "code": "GY"
    },
    {
        "name": "Haiti",
        "code": "HT"
    },
    {
        "name": "Heard Island and Mcdonald Islands",
        "code": "HM"
    },
    {
        "name": "Holy See (Vatican City State)",
        "code": "VA"
    },
    {
        "name": "Honduras",
        "code": "HN"
    },
    {
        "name": "Hong Kong",
        "code": "HK"
    },
    {
        "name": "Hungary",
        "code": "HU"
    },
    {
        "name": "Iceland",
        "code": "IS"
    },
    {
        "name": "India",
        "code": "IN"
    },
    {
        "name": "Indonesia",
        "code": "ID"
    },
    {
        "name": "Iraq",
        "code": "IQ"
    },
    {
        "name": "Ireland",
        "code": "IE"
    },
    {
        "name": "Isle of Man",
        "code": "IM"
    },
    {
        "name": "Israel",
        "code": "IL"
    },
    {
        "name": "Italy",
        "code": "IT"
    },
    {
        "name": "Jamaica",
        "code": "JM"
    },
    {
        "name": "Japan",
        "code": "JP"
    },
    {
        "name": "Jersey",
        "code": "JE"
    },
    {
        "name": "Jordan",
        "code": "JO"
    },
    {
        "name": "Kazakhstan",
        "code": "KZ"
    },
    {
        "name": "Kenya",
        "code": "KE"
    },
    {
        "name": "Kiribati",
        "code": "KI"
    },
    {
        "name": "Korea, Republic of",
        "code": "KR"
    },
    {
        "name": "Kuwait",
        "code": "KW"
    },
    {
        "name": "Kyrgyzstan",
        "code": "KG"
    },
    {
        "name": "Lao People'S Democratic Republic",
        "code": "LA"
    },
    {
        "name": "Latvia",
        "code": "LV"
    },
    {
        "name": "Lebanon",
        "code": "LB"
    },
    {
        "name": "Lesotho",
        "code": "LS"
    },
    {
        "name": "Liberia",
        "code": "LR"
    },
    {
        "name": "Libyan Arab Jamahiriya",
        "code": "LY"
    },
    {
        "name": "Liechtenstein",
        "code": "LI"
    },
    {
        "name": "Lithuania",
        "code": "LT"
    },
    {
        "name": "Luxembourg",
        "code": "LU"
    },
    {
        "name": "Macao",
        "code": "MO"
    },
    {
        "name": "Macedonia, The Former Yugoslav Republic of",
        "code": "MK"
    },
    {
        "name": "Madagascar",
        "code": "MG"
    },
    {
        "name": "Malawi",
        "code": "MW"
    },
    {
        "name": "Malaysia",
        "code": "MY"
    },
    {
        "name": "Maldives",
        "code": "MV"
    },
    {
        "name": "Mali",
        "code": "ML"
    },
    {
        "name": "Malta",
        "code": "MT"
    },
    {
        "name": "Marshall Islands",
        "code": "MH"
    },
    {
        "name": "Martinique",
        "code": "MQ"
    },
    {
        "name": "Mauritania",
        "code": "MR"
    },
    {
        "name": "Mauritius",
        "code": "MU"
    },
    {
        "name": "Mayotte",
        "code": "YT"
    },
    {
        "name": "Mexico",
        "code": "MX"
    },
    {
        "name": "Micronesia, Federated States of",
        "code": "FM"
    },
    {
        "name": "Moldova, Republic of",
        "code": "MD"
    },
    {
        "name": "Monaco",
        "code": "MC"
    },
    {
        "name": "Mongolia",
        "code": "MN"
    },
    {
        "name": "Montserrat",
        "code": "MS"
    },
    {
        "name": "Morocco",
        "code": "MA"
    },
    {
        "name": "Mozambique",
        "code": "MZ"
    },
    {
        "name": "Myanmar",
        "code": "MM"
    },
    {
        "name": "Namibia",
        "code": "NA"
    },
    {
        "name": "Nauru",
        "code": "NR"
    },
    {
        "name": "Nepal",
        "code": "NP"
    },
    {
        "name": "Netherlands",
        "code": "NL"
    },
    {
        "name": "Netherlands Antilles",
        "code": "AN"
    },
    {
        "name": "New Caledonia",
        "code": "NC"
    },
    {
        "name": "New Zealand",
        "code": "NZ"
    },
    {
        "name": "Nicaragua",
        "code": "NI"
    },
    {
        "name": "Niger",
        "code": "NE"
    },
    {
        "name": "Nigeria",
        "code": "NG"
    },
    {
        "name": "Niue",
        "code": "NU"
    },
    {
        "name": "Norfolk Island",
        "code": "NF"
    },
    {
        "name": "Northern Mariana Islands",
        "code": "MP"
    },
    {
        "name": "Norway",
        "code": "NO"
    },
    {
        "name": "Oman",
        "code": "OM"
    },
    {
        "name": "Pakistan",
        "code": "PK"
    },
    {
        "name": "Palau",
        "code": "PW"
    },
    {
        "name": "Palestinian Territory, Occupied",
        "code": "PS"
    },
    {
        "name": "Panama",
        "code": "PA"
    },
    {
        "name": "Papua New Guinea",
        "code": "PG"
    },
    {
        "name": "Paraguay",
        "code": "PY"
    },
    {
        "name": "Peru",
        "code": "PE"
    },
    {
        "name": "Philippines",
        "code": "PH"
    },
    {
        "name": "Pitcairn",
        "code": "PN"
    },
    {
        "name": "Poland",
        "code": "PL"
    },
    {
        "name": "Portugal",
        "code": "PT"
    },
    {
        "name": "Puerto Rico",
        "code": "PR"
    },
    {
        "name": "Qatar",
        "code": "QA"
    },
    {
        "name": "Reunion",
        "code": "RE"
    },
    {
        "name": "Romania",
        "code": "RO"
    },
    {
        "name": "RWANDA",
        "code": "RW"
    },
    {
        "name": "Saint Helena",
        "code": "SH"
    },
    {
        "name": "Saint Kitts and Nevis",
        "code": "KN"
    },
    {
        "name": "Saint Lucia",
        "code": "LC"
    },
    {
        "name": "Saint Pierre and Miquelon",
        "code": "PM"
    },
    {
        "name": "Saint Vincent and the Grenadines",
        "code": "VC"
    },
    {
        "name": "Samoa",
        "code": "WS"
    },
    {
        "name": "San Marino",
        "code": "SM"
    },
    {
        "name": "Sao Tome and Principe",
        "code": "ST"
    },
    {
        "name": "Saudi Arabia",
        "code": "SA"
    },
    {
        "name": "Senegal",
        "code": "SN"
    },
    {
        "name": "Serbia and Montenegro",
        "code": "CS"
    },
    {
        "name": "Seychelles",
        "code": "SC"
    },
    {
        "name": "Sierra Leone",
        "code": "SL"
    },
    {
        "name": "Singapore",
        "code": "SG"
    },
    {
        "name": "Slovakia",
        "code": "SK"
    },
    {
        "name": "Slovenia",
        "code": "SI"
    },
    {
        "name": "Solomon Islands",
        "code": "SB"
    },
    {
        "name": "Somalia",
        "code": "SO"
    },
    {
        "name": "South Africa",
        "code": "ZA"
    },
    {
        "name": "South Georgia and the South Sandwich Islands",
        "code": "GS"
    },
    {
        "name": "Spain",
        "code": "ES"
    },
    {
        "name": "Sri Lanka",
        "code": "LK"
    },
    {
        "name": "Sudan",
        "code": "SD"
    },
    {
        "name": "Suriname",
        "code": "SR"
    },
    {
        "name": "Svalbard and Jan Mayen",
        "code": "SJ"
    },
    {
        "name": "Swaziland",
        "code": "SZ"
    },
    {
        "name": "Sweden",
        "code": "SE"
    },
    {
        "name": "Switzerland",
        "code": "CH"
    },
    {
        "name": "Taiwan, Province of China",
        "code": "TW"
    },
    {
        "name": "Tajikistan",
        "code": "TJ"
    },
    {
        "name": "Tanzania, United Republic of",
        "code": "TZ"
    },
    {
        "name": "Thailand",
        "code": "TH"
    },
    {
        "name": "Timor-Leste",
        "code": "TL"
    },
    {
        "name": "Togo",
        "code": "TG"
    },
    {
        "name": "Tokelau",
        "code": "TK"
    },
    {
        "name": "Tonga",
        "code": "TO"
    },
    {
        "name": "Trinidad and Tobago",
        "code": "TT"
    },
    {
        "name": "Tunisia",
        "code": "TN"
    },
    {
        "name": "Turkey",
        "code": "TR"
    },
    {
        "name": "Turkmenistan",
        "code": "TM"
    },
    {
        "name": "Turks and Caicos Islands",
        "code": "TC"
    },
    {
        "name": "Tuvalu",
        "code": "TV"
    },
    {
        "name": "Uganda",
        "code": "UG"
    },
    {
        "name": "Ukraine",
        "code": "UA"
    },
    {
        "name": "United Arab Emirates",
        "code": "AE"
    },
    {
        "name": "United Kingdom",
        "code": "GB"
    },
    {
        "name": "United States",
        "code": "US"
    },
    {
        "name": "United States Minor Outlying Islands",
        "code": "UM"
    },
    {
        "name": "Uruguay",
        "code": "UY"
    },
    {
        "name": "Uzbekistan",
        "code": "UZ"
    },
    {
        "name": "Vanuatu",
        "code": "VU"
    },
    {
        "name": "Venezuela",
        "code": "VE"
    },
    {
        "name": "Viet Nam",
        "code": "VN"
    },
    {
        "name": "Virgin Islands, British",
        "code": "VG"
    },
    {
        "name": "Virgin Islands, U.S.",
        "code": "VI"
    },
    {
        "name": "Wallis and Futuna",
        "code": "WF"
    },
    {
        "name": "Western Sahara",
        "code": "EH"
    },
    {
        "name": "Yemen",
        "code": "YE"
    },
    {
        "name": "Zambia",
        "code": "ZM"
    },
    {
        "name": "Zimbabwe",
        "code": "ZW"
    }
]

export const countryCodeToNameMap = {
    "AF": "Afghanistan",
    "AX": "Åland Islands",
    "AL": "Albania",
    "DZ": "Algeria",
    "AS": "American Samoa",
    "AD": "AndorrA",
    "AO": "Angola",
    "AI": "Anguilla",
    "AQ": "Antarctica",
    "AG": "Antigua and Barbuda",
    "AR": "Argentina",
    "AM": "Armenia",
    "AW": "Aruba",
    "AU": "Australia",
    "AT": "Austria",
    "AZ": "Azerbaijan",
    "BS": "Bahamas",
    "BH": "Bahrain",
    "BD": "Bangladesh",
    "BB": "Barbados",
    "BY": "Belarus",
    "BE": "Belgium",
    "BZ": "Belize",
    "BJ": "Benin",
    "BM": "Bermuda",
    "BT": "Bhutan",
    "BO": "Bolivia",
    "BA": "Bosnia and Herzegovina",
    "BW": "Botswana",
    "BV": "Bouvet Island",
    "BR": "Brazil",
    "IO": "British Indian Ocean Territory",
    "BN": "Brunei Darussalam",
    "BG": "Bulgaria",
    "BF": "Burkina Faso",
    "BI": "Burundi",
    "KH": "Cambodia",
    "CM": "Cameroon",
    "CA": "Canada",
    "CV": "Cape Verde",
    "KY": "Cayman Islands",
    "CF": "Central African Republic",
    "TD": "Chad",
    "CL": "Chile",
    "CN": "China",
    "CX": "Christmas Island",
    "CC": "Cocos (Keeling) Islands",
    "CO": "Colombia",
    "KM": "Comoros",
    "CG": "Congo",
    "CD": "Congo, The Democratic Republic of the",
    "CK": "Cook Islands",
    "CR": "Costa Rica",
    "CI": "Cote D'Ivoire",
    "HR": "Croatia",
    "CY": "Cyprus",
    "CZ": "Czech Republic",
    "DK": "Denmark",
    "DJ": "Djibouti",
    "DM": "Dominica",
    "DO": "Dominican Republic",
    "EC": "Ecuador",
    "EG": "Egypt",
    "SV": "El Salvador",
    "GQ": "Equatorial Guinea",
    "ER": "Eritrea",
    "EE": "Estonia",
    "ET": "Ethiopia",
    "FK": "Falkland Islands (Malvinas)",
    "FO": "Faroe Islands",
    "FJ": "Fiji",
    "FI": "Finland",
    "FR": "France",
    "GF": "French Guiana",
    "PF": "French Polynesia",
    "TF": "French Southern Territories",
    "GA": "Gabon",
    "GM": "Gambia",
    "GE": "Georgia",
    "DE": "Germany",
    "GH": "Ghana",
    "GI": "Gibraltar",
    "GR": "Greece",
    "GL": "Greenland",
    "GD": "Grenada",
    "GP": "Guadeloupe",
    "GU": "Guam",
    "GT": "Guatemala",
    "GG": "Guernsey",
    "GN": "Guinea",
    "GW": "Guinea-Bissau",
    "GY": "Guyana",
    "HT": "Haiti",
    "HM": "Heard Island and Mcdonald Islands",
    "VA": "Holy See (Vatican City State)",
    "HN": "Honduras",
    "HK": "Hong Kong",
    "HU": "Hungary",
    "IS": "Iceland",
    "IN": "India",
    "ID": "Indonesia",
    "IQ": "Iraq",
    "IE": "Ireland",
    "IM": "Isle of Man",
    "IL": "Israel",
    "IT": "Italy",
    "JM": "Jamaica",
    "JP": "Japan",
    "JE": "Jersey",
    "JO": "Jordan",
    "KZ": "Kazakhstan",
    "KE": "Kenya",
    "KI": "Kiribati",
    "KR": "Korea, Republic of",
    "KW": "Kuwait",
    "KG": "Kyrgyzstan",
    "LA": "Lao People'S Democratic Republic",
    "LV": "Latvia",
    "LB": "Lebanon",
    "LS": "Lesotho",
    "LR": "Liberia",
    "LY": "Libyan Arab Jamahiriya",
    "LI": "Liechtenstein",
    "LT": "Lithuania",
    "LU": "Luxembourg",
    "MO": "Macao",
    "MK": "Macedonia, The Former Yugoslav Republic of",
    "MG": "Madagascar",
    "MW": "Malawi",
    "MY": "Malaysia",
    "MV": "Maldives",
    "ML": "Mali",
    "MT": "Malta",
    "MH": "Marshall Islands",
    "MQ": "Martinique",
    "MR": "Mauritania",
    "MU": "Mauritius",
    "YT": "Mayotte",
    "MX": "Mexico",
    "FM": "Micronesia, Federated States of",
    "MD": "Moldova, Republic of",
    "MC": "Monaco",
    "MN": "Mongolia",
    "MS": "Montserrat",
    "MA": "Morocco",
    "MZ": "Mozambique",
    "MM": "Myanmar",
    "NA": "Namibia",
    "NR": "Nauru",
    "NP": "Nepal",
    "NL": "Netherlands",
    "AN": "Netherlands Antilles",
    "NC": "New Caledonia",
    "NZ": "New Zealand",
    "NI": "Nicaragua",
    "NE": "Niger",
    "NG": "Nigeria",
    "NU": "Niue",
    "NF": "Norfolk Island",
    "MP": "Northern Mariana Islands",
    "NO": "Norway",
    "OM": "Oman",
    "PK": "Pakistan",
    "PW": "Palau",
    "PS": "Palestinian Territory, Occupied",
    "PA": "Panama",
    "PG": "Papua New Guinea",
    "PY": "Paraguay",
    "PE": "Peru",
    "PH": "Philippines",
    "PN": "Pitcairn",
    "PL": "Poland",
    "PT": "Portugal",
    "PR": "Puerto Rico",
    "QA": "Qatar",
    "RE": "Reunion",
    "RO": "Romania",
    "RW": "RWANDA",
    "SH": "Saint Helena",
    "KN": "Saint Kitts and Nevis",
    "LC": "Saint Lucia",
    "PM": "Saint Pierre and Miquelon",
    "VC": "Saint Vincent and the Grenadines",
    "WS": "Samoa",
    "SM": "San Marino",
    "ST": "Sao Tome and Principe",
    "SA": "Saudi Arabia",
    "SN": "Senegal",
    "CS": "Serbia and Montenegro",
    "SC": "Seychelles",
    "SL": "Sierra Leone",
    "SG": "Singapore",
    "SK": "Slovakia",
    "SI": "Slovenia",
    "SB": "Solomon Islands",
    "SO": "Somalia",
    "ZA": "South Africa",
    "GS": "South Georgia and the South Sandwich Islands",
    "ES": "Spain",
    "LK": "Sri Lanka",
    "SD": "Sudan",
    "SR": "Suriname",
    "SJ": "Svalbard and Jan Mayen",
    "SZ": "Swaziland",
    "SE": "Sweden",
    "CH": "Switzerland",
    "TW": "Taiwan, Province of China",
    "TJ": "Tajikistan",
    "TZ": "Tanzania, United Republic of",
    "TH": "Thailand",
    "TL": "Timor-Leste",
    "TG": "Togo",
    "TK": "Tokelau",
    "TO": "Tonga",
    "TT": "Trinidad and Tobago",
    "TN": "Tunisia",
    "TR": "Turkey",
    "TM": "Turkmenistan",
    "TC": "Turks and Caicos Islands",
    "TV": "Tuvalu",
    "UG": "Uganda",
    "UA": "Ukraine",
    "AE": "United Arab Emirates",
    "GB": "United Kingdom",
    "US": "United States",
    "UM": "United States Minor Outlying Islands",
    "UY": "Uruguay",
    "UZ": "Uzbekistan",
    "VU": "Vanuatu",
    "VE": "Venezuela",
    "VN": "Viet Nam",
    "VG": "Virgin Islands, British",
    "VI": "Virgin Islands, U.S.",
    "WF": "Wallis and Futuna",
    "EH": "Western Sahara",
    "YE": "Yemen",
    "ZM": "Zambia",
    "ZW": "Zimbabwe"
}

export const countryNameToCodeMap = {
    "Afghanistan": "AF",
    "Åland Islands": "AX",
    "Albania": "AL",
    "Algeria": "DZ",
    "American Samoa": "AS",
    "AndorrA": "AD",
    "Angola": "AO",
    "Anguilla": "AI",
    "Antarctica": "AQ",
    "Antigua and Barbuda": "AG",
    "Argentina": "AR",
    "Armenia": "AM",
    "Aruba": "AW",
    "Australia": "AU",
    "Austria": "AT",
    "Azerbaijan": "AZ",
    "Bahamas": "BS",
    "Bahrain": "BH",
    "Bangladesh": "BD",
    "Barbados": "BB",
    "Belarus": "BY",
    "Belgium": "BE",
    "Belize": "BZ",
    "Benin": "BJ",
    "Bermuda": "BM",
    "Bhutan": "BT",
    "Bolivia": "BO",
    "Bosnia and Herzegovina": "BA",
    "Botswana": "BW",
    "Bouvet Island": "BV",
    "Brazil": "BR",
    "British Indian Ocean Territory": "IO",
    "Brunei Darussalam": "BN",
    "Bulgaria": "BG",
    "Burkina Faso": "BF",
    "Burundi": "BI",
    "Cambodia": "KH",
    "Cameroon": "CM",
    "Canada": "CA",
    "Cape Verde": "CV",
    "Cayman Islands": "KY",
    "Central African Republic": "CF",
    "Chad": "TD",
    "Chile": "CL",
    "China": "CN",
    "Christmas Island": "CX",
    "Cocos (Keeling) Islands": "CC",
    "Colombia": "CO",
    "Comoros": "KM",
    "Congo": "CG",
    "Congo, The Democratic Republic of the": "CD",
    "Cook Islands": "CK",
    "Costa Rica": "CR",
    "Cote D'Ivoire": "CI",
    "Croatia": "HR",
    "Cyprus": "CY",
    "Czech Republic": "CZ",
    "Denmark": "DK",
    "Djibouti": "DJ",
    "Dominica": "DM",
    "Dominican Republic": "DO",
    "Ecuador": "EC",
    "Egypt": "EG",
    "El Salvador": "SV",
    "Equatorial Guinea": "GQ",
    "Eritrea": "ER",
    "Estonia": "EE",
    "Ethiopia": "ET",
    "Falkland Islands (Malvinas)": "FK",
    "Faroe Islands": "FO",
    "Fiji": "FJ",
    "Finland": "FI",
    "France": "FR",
    "French Guiana": "GF",
    "French Polynesia": "PF",
    "French Southern Territories": "TF",
    "Gabon": "GA",
    "Gambia": "GM",
    "Georgia": "GE",
    "Germany": "DE",
    "Ghana": "GH",
    "Gibraltar": "GI",
    "Greece": "GR",
    "Greenland": "GL",
    "Grenada": "GD",
    "Guadeloupe": "GP",
    "Guam": "GU",
    "Guatemala": "GT",
    "Guernsey": "GG",
    "Guinea": "GN",
    "Guinea-Bissau": "GW",
    "Guyana": "GY",
    "Haiti": "HT",
    "Heard Island and Mcdonald Islands": "HM",
    "Holy See (Vatican City State)": "VA",
    "Honduras": "HN",
    "Hong Kong": "HK",
    "Hungary": "HU",
    "Iceland": "IS",
    "India": "IN",
    "Indonesia": "ID",
    "Iraq": "IQ",
    "Ireland": "IE",
    "Isle of Man": "IM",
    "Israel": "IL",
    "Italy": "IT",
    "Jamaica": "JM",
    "Japan": "JP",
    "Jersey": "JE",
    "Jordan": "JO",
    "Kazakhstan": "KZ",
    "Kenya": "KE",
    "Kiribati": "KI",
    "Korea, Republic of": "KR",
    "Kuwait": "KW",
    "Kyrgyzstan": "KG",
    "Lao People'S Democratic Republic": "LA",
    "Latvia": "LV",
    "Lebanon": "LB",
    "Lesotho": "LS",
    "Liberia": "LR",
    "Libyan Arab Jamahiriya": "LY",
    "Liechtenstein": "LI",
    "Lithuania": "LT",
    "Luxembourg": "LU",
    "Macao": "MO",
    "Macedonia, The Former Yugoslav Republic of": "MK",
    "Madagascar": "MG",
    "Malawi": "MW",
    "Malaysia": "MY",
    "Maldives": "MV",
    "Mali": "ML",
    "Malta": "MT",
    "Marshall Islands": "MH",
    "Martinique": "MQ",
    "Mauritania": "MR",
    "Mauritius": "MU",
    "Mayotte": "YT",
    "Mexico": "MX",
    "Micronesia, Federated States of": "FM",
    "Moldova, Republic of": "MD",
    "Monaco": "MC",
    "Mongolia": "MN",
    "Montserrat": "MS",
    "Morocco": "MA",
    "Mozambique": "MZ",
    "Myanmar": "MM",
    "Namibia": "NA",
    "Nauru": "NR",
    "Nepal": "NP",
    "Netherlands": "NL",
    "Netherlands Antilles": "AN",
    "New Caledonia": "NC",
    "New Zealand": "NZ",
    "Nicaragua": "NI",
    "Niger": "NE",
    "Nigeria": "NG",
    "Niue": "NU",
    "Norfolk Island": "NF",
    "Northern Mariana Islands": "MP",
    "Norway": "NO",
    "Oman": "OM",
    "Pakistan": "PK",
    "Palau": "PW",
    "Palestinian Territory, Occupied": "PS",
    "Panama": "PA",
    "Papua New Guinea": "PG",
    "Paraguay": "PY",
    "Peru": "PE",
    "Philippines": "PH",
    "Pitcairn": "PN",
    "Poland": "PL",
    "Portugal": "PT",
    "Puerto Rico": "PR",
    "Qatar": "QA",
    "Reunion": "RE",
    "Romania": "RO",
    "RWANDA": "RW",
    "Saint Helena": "SH",
    "Saint Kitts and Nevis": "KN",
    "Saint Lucia": "LC",
    "Saint Pierre and Miquelon": "PM",
    "Saint Vincent and the Grenadines": "VC",
    "Samoa": "WS",
    "San Marino": "SM",
    "Sao Tome and Principe": "ST",
    "Saudi Arabia": "SA",
    "Senegal": "SN",
    "Serbia and Montenegro": "CS",
    "Seychelles": "SC",
    "Sierra Leone": "SL",
    "Singapore": "SG",
    "Slovakia": "SK",
    "Slovenia": "SI",
    "Solomon Islands": "SB",
    "Somalia": "SO",
    "South Africa": "ZA",
    "South Georgia and the South Sandwich Islands": "GS",
    "Spain": "ES",
    "Sri Lanka": "LK",
    "Sudan": "SD",
    "Suriname": "SR",
    "Svalbard and Jan Mayen": "SJ",
    "Swaziland": "SZ",
    "Sweden": "SE",
    "Switzerland": "CH",
    "Taiwan, Province of China": "TW",
    "Tajikistan": "TJ",
    "Tanzania, United Republic of": "TZ",
    "Thailand": "TH",
    "Timor-Leste": "TL",
    "Togo": "TG",
    "Tokelau": "TK",
    "Tonga": "TO",
    "Trinidad and Tobago": "TT",
    "Tunisia": "TN",
    "Turkey": "TR",
    "Turkmenistan": "TM",
    "Turks and Caicos Islands": "TC",
    "Tuvalu": "TV",
    "Uganda": "UG",
    "Ukraine": "UA",
    "United Arab Emirates": "AE",
    "United Kingdom": "GB",
    "United States": "US",
    "United States Minor Outlying Islands": "UM",
    "Uruguay": "UY",
    "Uzbekistan": "UZ",
    "Vanuatu": "VU",
    "Venezuela": "VE",
    "Viet Nam": "VN",
    "Virgin Islands, British": "VG",
    "Virgin Islands, U.S.": "VI",
    "Wallis and Futuna": "WF",
    "Western Sahara": "EH",
    "Yemen": "YE",
    "Zambia": "ZM",
    "Zimbabwe": "ZW"
}

export const countryCodeMapUSFirst = [
    {
        "name": "United States",
        "code": "US"
    },
    {
        "name": "Afghanistan",
        "code": "AF"
    },
    {
        "name": "Åland Islands",
        "code": "AX"
    },
    {
        "name": "Albania",
        "code": "AL"
    },
    {
        "name": "Algeria",
        "code": "DZ"
    },
    {
        "name": "American Samoa",
        "code": "AS"
    },
    {
        "name": "AndorrA",
        "code": "AD"
    },
    {
        "name": "Angola",
        "code": "AO"
    },
    {
        "name": "Anguilla",
        "code": "AI"
    },
    {
        "name": "Antarctica",
        "code": "AQ"
    },
    {
        "name": "Antigua and Barbuda",
        "code": "AG"
    },
    {
        "name": "Argentina",
        "code": "AR"
    },
    {
        "name": "Armenia",
        "code": "AM"
    },
    {
        "name": "Aruba",
        "code": "AW"
    },
    {
        "name": "Australia",
        "code": "AU"
    },
    {
        "name": "Austria",
        "code": "AT"
    },
    {
        "name": "Azerbaijan",
        "code": "AZ"
    },
    {
        "name": "Bahamas",
        "code": "BS"
    },
    {
        "name": "Bahrain",
        "code": "BH"
    },
    {
        "name": "Bangladesh",
        "code": "BD"
    },
    {
        "name": "Barbados",
        "code": "BB"
    },
    {
        "name": "Belarus",
        "code": "BY"
    },
    {
        "name": "Belgium",
        "code": "BE"
    },
    {
        "name": "Belize",
        "code": "BZ"
    },
    {
        "name": "Benin",
        "code": "BJ"
    },
    {
        "name": "Bermuda",
        "code": "BM"
    },
    {
        "name": "Bhutan",
        "code": "BT"
    },
    {
        "name": "Bolivia",
        "code": "BO"
    },
    {
        "name": "Bosnia and Herzegovina",
        "code": "BA"
    },
    {
        "name": "Botswana",
        "code": "BW"
    },
    {
        "name": "Bouvet Island",
        "code": "BV"
    },
    {
        "name": "Brazil",
        "code": "BR"
    },
    {
        "name": "British Indian Ocean Territory",
        "code": "IO"
    },
    {
        "name": "Brunei Darussalam",
        "code": "BN"
    },
    {
        "name": "Bulgaria",
        "code": "BG"
    },
    {
        "name": "Burkina Faso",
        "code": "BF"
    },
    {
        "name": "Burundi",
        "code": "BI"
    },
    {
        "name": "Cambodia",
        "code": "KH"
    },
    {
        "name": "Cameroon",
        "code": "CM"
    },
    {
        "name": "Canada",
        "code": "CA"
    },
    {
        "name": "Cape Verde",
        "code": "CV"
    },
    {
        "name": "Cayman Islands",
        "code": "KY"
    },
    {
        "name": "Central African Republic",
        "code": "CF"
    },
    {
        "name": "Chad",
        "code": "TD"
    },
    {
        "name": "Chile",
        "code": "CL"
    },
    {
        "name": "China",
        "code": "CN"
    },
    {
        "name": "Christmas Island",
        "code": "CX"
    },
    {
        "name": "Cocos (Keeling) Islands",
        "code": "CC"
    },
    {
        "name": "Colombia",
        "code": "CO"
    },
    {
        "name": "Comoros",
        "code": "KM"
    },
    {
        "name": "Congo",
        "code": "CG"
    },
    {
        "name": "Congo, The Democratic Republic of the",
        "code": "CD"
    },
    {
        "name": "Cook Islands",
        "code": "CK"
    },
    {
        "name": "Costa Rica",
        "code": "CR"
    },
    {
        "name": "Cote D'Ivoire",
        "code": "CI"
    },
    {
        "name": "Croatia",
        "code": "HR"
    },
    {
        "name": "Cyprus",
        "code": "CY"
    },
    {
        "name": "Czech Republic",
        "code": "CZ"
    },
    {
        "name": "Denmark",
        "code": "DK"
    },
    {
        "name": "Djibouti",
        "code": "DJ"
    },
    {
        "name": "Dominica",
        "code": "DM"
    },
    {
        "name": "Dominican Republic",
        "code": "DO"
    },
    {
        "name": "Ecuador",
        "code": "EC"
    },
    {
        "name": "Egypt",
        "code": "EG"
    },
    {
        "name": "El Salvador",
        "code": "SV"
    },
    {
        "name": "Equatorial Guinea",
        "code": "GQ"
    },
    {
        "name": "Eritrea",
        "code": "ER"
    },
    {
        "name": "Estonia",
        "code": "EE"
    },
    {
        "name": "Ethiopia",
        "code": "ET"
    },
    {
        "name": "Falkland Islands (Malvinas)",
        "code": "FK"
    },
    {
        "name": "Faroe Islands",
        "code": "FO"
    },
    {
        "name": "Fiji",
        "code": "FJ"
    },
    {
        "name": "Finland",
        "code": "FI"
    },
    {
        "name": "France",
        "code": "FR"
    },
    {
        "name": "French Guiana",
        "code": "GF"
    },
    {
        "name": "French Polynesia",
        "code": "PF"
    },
    {
        "name": "French Southern Territories",
        "code": "TF"
    },
    {
        "name": "Gabon",
        "code": "GA"
    },
    {
        "name": "Gambia",
        "code": "GM"
    },
    {
        "name": "Georgia",
        "code": "GE"
    },
    {
        "name": "Germany",
        "code": "DE"
    },
    {
        "name": "Ghana",
        "code": "GH"
    },
    {
        "name": "Gibraltar",
        "code": "GI"
    },
    {
        "name": "Greece",
        "code": "GR"
    },
    {
        "name": "Greenland",
        "code": "GL"
    },
    {
        "name": "Grenada",
        "code": "GD"
    },
    {
        "name": "Guadeloupe",
        "code": "GP"
    },
    {
        "name": "Guam",
        "code": "GU"
    },
    {
        "name": "Guatemala",
        "code": "GT"
    },
    {
        "name": "Guernsey",
        "code": "GG"
    },
    {
        "name": "Guinea",
        "code": "GN"
    },
    {
        "name": "Guinea-Bissau",
        "code": "GW"
    },
    {
        "name": "Guyana",
        "code": "GY"
    },
    {
        "name": "Haiti",
        "code": "HT"
    },
    {
        "name": "Heard Island and Mcdonald Islands",
        "code": "HM"
    },
    {
        "name": "Holy See (Vatican City State)",
        "code": "VA"
    },
    {
        "name": "Honduras",
        "code": "HN"
    },
    {
        "name": "Hong Kong",
        "code": "HK"
    },
    {
        "name": "Hungary",
        "code": "HU"
    },
    {
        "name": "Iceland",
        "code": "IS"
    },
    {
        "name": "India",
        "code": "IN"
    },
    {
        "name": "Indonesia",
        "code": "ID"
    },
    {
        "name": "Iraq",
        "code": "IQ"
    },
    {
        "name": "Ireland",
        "code": "IE"
    },
    {
        "name": "Isle of Man",
        "code": "IM"
    },
    {
        "name": "Israel",
        "code": "IL"
    },
    {
        "name": "Italy",
        "code": "IT"
    },
    {
        "name": "Jamaica",
        "code": "JM"
    },
    {
        "name": "Japan",
        "code": "JP"
    },
    {
        "name": "Jersey",
        "code": "JE"
    },
    {
        "name": "Jordan",
        "code": "JO"
    },
    {
        "name": "Kazakhstan",
        "code": "KZ"
    },
    {
        "name": "Kenya",
        "code": "KE"
    },
    {
        "name": "Kiribati",
        "code": "KI"
    },
    {
        "name": "Korea, Republic of",
        "code": "KR"
    },
    {
        "name": "Kuwait",
        "code": "KW"
    },
    {
        "name": "Kyrgyzstan",
        "code": "KG"
    },
    {
        "name": "Lao People'S Democratic Republic",
        "code": "LA"
    },
    {
        "name": "Latvia",
        "code": "LV"
    },
    {
        "name": "Lebanon",
        "code": "LB"
    },
    {
        "name": "Lesotho",
        "code": "LS"
    },
    {
        "name": "Liberia",
        "code": "LR"
    },
    {
        "name": "Libyan Arab Jamahiriya",
        "code": "LY"
    },
    {
        "name": "Liechtenstein",
        "code": "LI"
    },
    {
        "name": "Lithuania",
        "code": "LT"
    },
    {
        "name": "Luxembourg",
        "code": "LU"
    },
    {
        "name": "Macao",
        "code": "MO"
    },
    {
        "name": "Macedonia, The Former Yugoslav Republic of",
        "code": "MK"
    },
    {
        "name": "Madagascar",
        "code": "MG"
    },
    {
        "name": "Malawi",
        "code": "MW"
    },
    {
        "name": "Malaysia",
        "code": "MY"
    },
    {
        "name": "Maldives",
        "code": "MV"
    },
    {
        "name": "Mali",
        "code": "ML"
    },
    {
        "name": "Malta",
        "code": "MT"
    },
    {
        "name": "Marshall Islands",
        "code": "MH"
    },
    {
        "name": "Martinique",
        "code": "MQ"
    },
    {
        "name": "Mauritania",
        "code": "MR"
    },
    {
        "name": "Mauritius",
        "code": "MU"
    },
    {
        "name": "Mayotte",
        "code": "YT"
    },
    {
        "name": "Mexico",
        "code": "MX"
    },
    {
        "name": "Micronesia, Federated States of",
        "code": "FM"
    },
    {
        "name": "Moldova, Republic of",
        "code": "MD"
    },
    {
        "name": "Monaco",
        "code": "MC"
    },
    {
        "name": "Mongolia",
        "code": "MN"
    },
    {
        "name": "Montserrat",
        "code": "MS"
    },
    {
        "name": "Morocco",
        "code": "MA"
    },
    {
        "name": "Mozambique",
        "code": "MZ"
    },
    {
        "name": "Myanmar",
        "code": "MM"
    },
    {
        "name": "Namibia",
        "code": "NA"
    },
    {
        "name": "Nauru",
        "code": "NR"
    },
    {
        "name": "Nepal",
        "code": "NP"
    },
    {
        "name": "Netherlands",
        "code": "NL"
    },
    {
        "name": "Netherlands Antilles",
        "code": "AN"
    },
    {
        "name": "New Caledonia",
        "code": "NC"
    },
    {
        "name": "New Zealand",
        "code": "NZ"
    },
    {
        "name": "Nicaragua",
        "code": "NI"
    },
    {
        "name": "Niger",
        "code": "NE"
    },
    {
        "name": "Nigeria",
        "code": "NG"
    },
    {
        "name": "Niue",
        "code": "NU"
    },
    {
        "name": "Norfolk Island",
        "code": "NF"
    },
    {
        "name": "Northern Mariana Islands",
        "code": "MP"
    },
    {
        "name": "Norway",
        "code": "NO"
    },
    {
        "name": "Oman",
        "code": "OM"
    },
    {
        "name": "Pakistan",
        "code": "PK"
    },
    {
        "name": "Palau",
        "code": "PW"
    },
    {
        "name": "Palestinian Territory, Occupied",
        "code": "PS"
    },
    {
        "name": "Panama",
        "code": "PA"
    },
    {
        "name": "Papua New Guinea",
        "code": "PG"
    },
    {
        "name": "Paraguay",
        "code": "PY"
    },
    {
        "name": "Peru",
        "code": "PE"
    },
    {
        "name": "Philippines",
        "code": "PH"
    },
    {
        "name": "Pitcairn",
        "code": "PN"
    },
    {
        "name": "Poland",
        "code": "PL"
    },
    {
        "name": "Portugal",
        "code": "PT"
    },
    {
        "name": "Puerto Rico",
        "code": "PR"
    },
    {
        "name": "Qatar",
        "code": "QA"
    },
    {
        "name": "Reunion",
        "code": "RE"
    },
    {
        "name": "Romania",
        "code": "RO"
    },
    {
        "name": "RWANDA",
        "code": "RW"
    },
    {
        "name": "Saint Helena",
        "code": "SH"
    },
    {
        "name": "Saint Kitts and Nevis",
        "code": "KN"
    },
    {
        "name": "Saint Lucia",
        "code": "LC"
    },
    {
        "name": "Saint Pierre and Miquelon",
        "code": "PM"
    },
    {
        "name": "Saint Vincent and the Grenadines",
        "code": "VC"
    },
    {
        "name": "Samoa",
        "code": "WS"
    },
    {
        "name": "San Marino",
        "code": "SM"
    },
    {
        "name": "Sao Tome and Principe",
        "code": "ST"
    },
    {
        "name": "Saudi Arabia",
        "code": "SA"
    },
    {
        "name": "Senegal",
        "code": "SN"
    },
    {
        "name": "Serbia and Montenegro",
        "code": "CS"
    },
    {
        "name": "Seychelles",
        "code": "SC"
    },
    {
        "name": "Sierra Leone",
        "code": "SL"
    },
    {
        "name": "Singapore",
        "code": "SG"
    },
    {
        "name": "Slovakia",
        "code": "SK"
    },
    {
        "name": "Slovenia",
        "code": "SI"
    },
    {
        "name": "Solomon Islands",
        "code": "SB"
    },
    {
        "name": "Somalia",
        "code": "SO"
    },
    {
        "name": "South Africa",
        "code": "ZA"
    },
    {
        "name": "South Georgia and the South Sandwich Islands",
        "code": "GS"
    },
    {
        "name": "Spain",
        "code": "ES"
    },
    {
        "name": "Sri Lanka",
        "code": "LK"
    },
    {
        "name": "Sudan",
        "code": "SD"
    },
    {
        "name": "Suriname",
        "code": "SR"
    },
    {
        "name": "Svalbard and Jan Mayen",
        "code": "SJ"
    },
    {
        "name": "Swaziland",
        "code": "SZ"
    },
    {
        "name": "Sweden",
        "code": "SE"
    },
    {
        "name": "Switzerland",
        "code": "CH"
    },
    {
        "name": "Taiwan, Province of China",
        "code": "TW"
    },
    {
        "name": "Tajikistan",
        "code": "TJ"
    },
    {
        "name": "Tanzania, United Republic of",
        "code": "TZ"
    },
    {
        "name": "Thailand",
        "code": "TH"
    },
    {
        "name": "Timor-Leste",
        "code": "TL"
    },
    {
        "name": "Togo",
        "code": "TG"
    },
    {
        "name": "Tokelau",
        "code": "TK"
    },
    {
        "name": "Tonga",
        "code": "TO"
    },
    {
        "name": "Trinidad and Tobago",
        "code": "TT"
    },
    {
        "name": "Tunisia",
        "code": "TN"
    },
    {
        "name": "Turkey",
        "code": "TR"
    },
    {
        "name": "Turkmenistan",
        "code": "TM"
    },
    {
        "name": "Turks and Caicos Islands",
        "code": "TC"
    },
    {
        "name": "Tuvalu",
        "code": "TV"
    },
    {
        "name": "Uganda",
        "code": "UG"
    },
    {
        "name": "Ukraine",
        "code": "UA"
    },
    {
        "name": "United Arab Emirates",
        "code": "AE"
    },
    {
        "name": "United Kingdom",
        "code": "GB"
    },
    {
        "name": "United States Minor Outlying Islands",
        "code": "UM"
    },
    {
        "name": "Uruguay",
        "code": "UY"
    },
    {
        "name": "Uzbekistan",
        "code": "UZ"
    },
    {
        "name": "Vanuatu",
        "code": "VU"
    },
    {
        "name": "Venezuela",
        "code": "VE"
    },
    {
        "name": "Viet Nam",
        "code": "VN"
    },
    {
        "name": "Virgin Islands, British",
        "code": "VG"
    },
    {
        "name": "Virgin Islands, U.S.",
        "code": "VI"
    },
    {
        "name": "Wallis and Futuna",
        "code": "WF"
    },
    {
        "name": "Western Sahara",
        "code": "EH"
    },
    {
        "name": "Yemen",
        "code": "YE"
    },
    {
        "name": "Zambia",
        "code": "ZM"
    },
    {
        "name": "Zimbabwe",
        "code": "ZW"
    }
]

export const incorpTypesArr = [
    "LLC",
    "C-Corp",
    "S-Corp",
    "Non-Profit",
    "Investment/Hedge/Private Equity Fund",
    "Trust",
    // "Special Purpose Vehicle/Special Purpose Entity", 
    // "Private Investment Company/Private Holding Company", 
    "Partnership",
    "Joint Venture",
] // "Sole Proprietorship" disabled for now

export const incorpTypeDocumentMap = {
    "LLC": [
        { name: "Filed Certificate of Formation/Articles of Organization, issued by Secretary of State" },
        { name: "Business Certificate/Business License (if professional LLC)" },
        { name: "Certificate of Authority files with state(s) the company is operating in (if incorporated outside of state in which operating)" },
        { name: "Operating Agreement, signed by members" },
    ],
    "C-Corp": [
        { name: "Certificate of Incorporation, issued by Secretary of State and filing receipt" },
        { name: "Trade/Assumed Name Certificate (if existing DBA), must be filed with the Secretary of State" },
        { name: "Certificate of Good Standing" },
    ],
    "S-Corp": [
        { name: "Certificate of Incorporation, issued by Secretary of State and filing receipt" },
        { name: "Trade/Assumed Name Certificate (if existing DBA), must be filed with the Secretary of State" },
        { name: "Certificate of Good Standing" },
    ],
    "Non-Profit": [
        { name: "Filed Certificate of Incorporation/Articles of Incorporation, if incorporated this must be issued be the Secretary of State" },
        { name: "Filed Trade Name Certificate, issued by Secretary of State" },
        { name: "Tax ID Number/501(c)(3) notification" },
    ],
    "Investment/Hedge/Private Equity Fund": [
        { name: "Filed business documents (e.g., LLC, Corporation, Limited Partnership) and respective agreements (e.g. Operating Agreement or Partnership Agreement)" },
        { name: "Offering Documents" },
    ],
    "Trust": [
        { name: "Trust Agreement, this should include the appointed trustee and must contain completed notary section" },
    ],
    // "Special Purpose Vehicle/Special Purpose Entity": [], 
    // "Private Investment Company/Private Holding Company": [], 
    "Partnership": [
        { name: "Filed Business/Trade Name Certificate, this must be issued and certified by the applicable county" },
        { name: "Filed Limited Partnership Certificate, issued by Secretary of State" },
        { name: "Certified Copy of Partnership Agreement" },
    ],
    "Joint Venture": [
        { name: "Filed business documents (e.g., LLC, Corporation, Limited Partnership) and respective agreements (e.g. Operating Agreement or Partnership Agreement)" },
        { name: "Assumed Name Certificate, which must include filing stamp/receipt" },
        { name: "Joint Venture Agreement" },
    ],
}

export const phoneCountryCode = [{
        "name": "United States",
        "flag": "🇺🇸",
        "code": "US",
        "dial_code": "+1"
    },
    {
        "name": "Afghanistan",
        "flag": "🇦🇫",
        "code": "AF",
        "dial_code": "+93"
    },
    {
        "name": "Åland Islands",
        "flag": "🇦🇽",
        "code": "AX",
        "dial_code": "+358"
    },
    {
        "name": "Albania",
        "flag": "🇦🇱",
        "code": "AL",
        "dial_code": "+355"
    },
    {
        "name": "Algeria",
        "flag": "🇩🇿",
        "code": "DZ",
        "dial_code": "+213"
    },
    {
        "name": "American Samoa",
        "flag": "🇦🇸",
        "code": "AS",
        "dial_code": "+1684"
    },
    {
        "name": "Andorra",
        "flag": "🇦🇩",
        "code": "AD",
        "dial_code": "+376"
    },
    {
        "name": "Angola",
        "flag": "🇦🇴",
        "code": "AO",
        "dial_code": "+244"
    },
    {
        "name": "Anguilla",
        "flag": "🇦🇮",
        "code": "AI",
        "dial_code": "+1264"
    },
    {
        "name": "Antarctica",
        "flag": "🇦🇶",
        "code": "AQ",
        "dial_code": "+672"
    },
    {
        "name": "Antigua and Barbuda",
        "flag": "🇦🇬",
        "code": "AG",
        "dial_code": "+1268"
    },
    {
        "name": "Argentina",
        "flag": "🇦🇷",
        "code": "AR",
        "dial_code": "+54"
    },
    {
        "name": "Armenia",
        "flag": "🇦🇲",
        "code": "AM",
        "dial_code": "+374"
    },
    {
        "name": "Aruba",
        "flag": "🇦🇼",
        "code": "AW",
        "dial_code": "+297"
    },
    {
        "name": "Australia",
        "flag": "🇦🇺",
        "code": "AU",
        "dial_code": "+61"
    },
    {
        "name": "Austria",
        "flag": "🇦🇹",
        "code": "AT",
        "dial_code": "+43"
    },
    {
        "name": "Azerbaijan",
        "flag": "🇦🇿",
        "code": "AZ",
        "dial_code": "+994"
    },
    {
        "name": "Bahamas",
        "flag": "🇧🇸",
        "code": "BS",
        "dial_code": "+1242"
    },
    {
        "name": "Bahrain",
        "flag": "🇧🇭",
        "code": "BH",
        "dial_code": "+973"
    },
    {
        "name": "Bangladesh",
        "flag": "🇧🇩",
        "code": "BD",
        "dial_code": "+880"
    },
    {
        "name": "Barbados",
        "flag": "🇧🇧",
        "code": "BB",
        "dial_code": "+1246"
    },
    {
        "name": "Belarus",
        "flag": "🇧🇾",
        "code": "BY",
        "dial_code": "+375"
    },
    {
        "name": "Belgium",
        "flag": "🇧🇪",
        "code": "BE",
        "dial_code": "+32"
    },
    {
        "name": "Belize",
        "flag": "🇧🇿",
        "code": "BZ",
        "dial_code": "+501"
    },
    {
        "name": "Benin",
        "flag": "🇧🇯",
        "code": "BJ",
        "dial_code": "+229"
    },
    {
        "name": "Bermuda",
        "flag": "🇧🇲",
        "code": "BM",
        "dial_code": "+1441"
    },
    {
        "name": "Bhutan",
        "flag": "🇧🇹",
        "code": "BT",
        "dial_code": "+975"
    },
    {
        "name": "Bolivia, Plurinational State of bolivia",
        "flag": "🇧🇴",
        "code": "BO",
        "dial_code": "+591"
    },
    {
        "name": "Bosnia and Herzegovina",
        "flag": "🇧🇦",
        "code": "BA",
        "dial_code": "+387"
    },
    {
        "name": "Botswana",
        "flag": "🇧🇼",
        "code": "BW",
        "dial_code": "+267"
    },
    {
        "name": "Bouvet Island",
        "flag": "🇧🇻",
        "code": "BV",
        "dial_code": "+47"
    },
    {
        "name": "Brazil",
        "flag": "🇧🇷",
        "code": "BR",
        "dial_code": "+55"
    },
    {
        "name": "British Indian Ocean Territory",
        "flag": "🇮🇴",
        "code": "IO",
        "dial_code": "+246"
    },
    {
        "name": "Brunei Darussalam",
        "flag": "🇧🇳",
        "code": "BN",
        "dial_code": "+673"
    },
    {
        "name": "Bulgaria",
        "flag": "🇧🇬",
        "code": "BG",
        "dial_code": "+359"
    },
    {
        "name": "Burkina Faso",
        "flag": "🇧🇫",
        "code": "BF",
        "dial_code": "+226"
    },
    {
        "name": "Burundi",
        "flag": "🇧🇮",
        "code": "BI",
        "dial_code": "+257"
    },
    {
        "name": "Cambodia",
        "flag": "🇰🇭",
        "code": "KH",
        "dial_code": "+855"
    },
    {
        "name": "Cameroon",
        "flag": "🇨🇲",
        "code": "CM",
        "dial_code": "+237"
    },
    {
        "name": "Canada",
        "flag": "🇨🇦",
        "code": "CA",
        "dial_code": "+1"
    },
    {
        "name": "Cape Verde",
        "flag": "🇨🇻",
        "code": "CV",
        "dial_code": "+238"
    },
    {
        "name": "Cayman Islands",
        "flag": "🇰🇾",
        "code": "KY",
        "dial_code": "+345"
    },
    {
        "name": "Central African Republic",
        "flag": "🇨🇫",
        "code": "CF",
        "dial_code": "+236"
    },
    {
        "name": "Chad",
        "flag": "🇹🇩",
        "code": "TD",
        "dial_code": "+235"
    },
    {
        "name": "Chile",
        "flag": "🇨🇱",
        "code": "CL",
        "dial_code": "+56"
    },
    {
        "name": "China",
        "flag": "🇨🇳",
        "code": "CN",
        "dial_code": "+86"
    },
    {
        "name": "Christmas Island",
        "flag": "🇨🇽",
        "code": "CX",
        "dial_code": "+61"
    },
    {
        "name": "Cocos (Keeling) Islands",
        "flag": "🇨🇨",
        "code": "CC",
        "dial_code": "+61"
    },
    {
        "name": "Colombia",
        "flag": "🇨🇴",
        "code": "CO",
        "dial_code": "+57"
    },
    {
        "name": "Comoros",
        "flag": "🇰🇲",
        "code": "KM",
        "dial_code": "+269"
    },
    {
        "name": "Congo",
        "flag": "🇨🇬",
        "code": "CG",
        "dial_code": "+242"
    },
    {
        "name": "Congo, The Democratic Republic of the Congo",
        "flag": "🇨🇩",
        "code": "CD",
        "dial_code": "+243"
    },
    {
        "name": "Cook Islands",
        "flag": "🇨🇰",
        "code": "CK",
        "dial_code": "+682"
    },
    {
        "name": "Costa Rica",
        "flag": "🇨🇷",
        "code": "CR",
        "dial_code": "+506"
    },
    {
        "name": "Cote d'Ivoire",
        "flag": "🇨🇮",
        "code": "CI",
        "dial_code": "+225"
    },
    {
        "name": "Croatia",
        "flag": "🇭🇷",
        "code": "HR",
        "dial_code": "+385"
    },
    {
        "name": "Cyprus",
        "flag": "🇨🇾",
        "code": "CY",
        "dial_code": "+357"
    },
    {
        "name": "Czech Republic",
        "flag": "🇨🇿",
        "code": "CZ",
        "dial_code": "+420"
    },
    {
        "name": "Denmark",
        "flag": "🇩🇰",
        "code": "DK",
        "dial_code": "+45"
    },
    {
        "name": "Djibouti",
        "flag": "🇩🇯",
        "code": "DJ",
        "dial_code": "+253"
    },
    {
        "name": "Dominica",
        "flag": "🇩🇲",
        "code": "DM",
        "dial_code": "+1767"
    },
    {
        "name": "Dominican Republic",
        "flag": "🇩🇴",
        "code": "DO",
        "dial_code": "+1849"
    },
    {
        "name": "Ecuador",
        "flag": "🇪🇨",
        "code": "EC",
        "dial_code": "+593"
    },
    {
        "name": "Egypt",
        "flag": "🇪🇬",
        "code": "EG",
        "dial_code": "+20"
    },
    {
        "name": "El Salvador",
        "flag": "🇸🇻",
        "code": "SV",
        "dial_code": "+503"
    },
    {
        "name": "Equatorial Guinea",
        "flag": "🇬🇶",
        "code": "GQ",
        "dial_code": "+240"
    },
    {
        "name": "Eritrea",
        "flag": "🇪🇷",
        "code": "ER",
        "dial_code": "+291"
    },
    {
        "name": "Estonia",
        "flag": "🇪🇪",
        "code": "EE",
        "dial_code": "+372"
    },
    {
        "name": "Ethiopia",
        "flag": "🇪🇹",
        "code": "ET",
        "dial_code": "+251"
    },
    {
        "name": "Falkland Islands (Malvinas)",
        "flag": "🇫🇰",
        "code": "FK",
        "dial_code": "+500"
    },
    {
        "name": "Faroe Islands",
        "flag": "🇫🇴",
        "code": "FO",
        "dial_code": "+298"
    },
    {
        "name": "Fiji",
        "flag": "🇫🇯",
        "code": "FJ",
        "dial_code": "+679"
    },
    {
        "name": "Finland",
        "flag": "🇫🇮",
        "code": "FI",
        "dial_code": "+358"
    },
    {
        "name": "France",
        "flag": "🇫🇷",
        "code": "FR",
        "dial_code": "+33"
    },
    {
        "name": "French Guiana",
        "flag": "🇬🇫",
        "code": "GF",
        "dial_code": "+594"
    },
    {
        "name": "French Polynesia",
        "flag": "🇵🇫",
        "code": "PF",
        "dial_code": "+689"
    },
    {
        "name": "French Southern Territories",
        "flag": "🇹🇫",
        "code": "TF",
        "dial_code": "+262"
    },
    {
        "name": "Gabon",
        "flag": "🇬🇦",
        "code": "GA",
        "dial_code": "+241"
    },
    {
        "name": "Gambia",
        "flag": "🇬🇲",
        "code": "GM",
        "dial_code": "+220"
    },
    {
        "name": "Georgia",
        "flag": "🇬🇪",
        "code": "GE",
        "dial_code": "+995"
    },
    {
        "name": "Germany",
        "flag": "🇩🇪",
        "code": "DE",
        "dial_code": "+49"
    },
    {
        "name": "Ghana",
        "flag": "🇬🇭",
        "code": "GH",
        "dial_code": "+233"
    },
    {
        "name": "Gibraltar",
        "flag": "🇬🇮",
        "code": "GI",
        "dial_code": "+350"
    },
    {
        "name": "Greece",
        "flag": "🇬🇷",
        "code": "GR",
        "dial_code": "+30"
    },
    {
        "name": "Greenland",
        "flag": "🇬🇱",
        "code": "GL",
        "dial_code": "+299"
    },
    {
        "name": "Grenada",
        "flag": "🇬🇩",
        "code": "GD",
        "dial_code": "+1473"
    },
    {
        "name": "Guadeloupe",
        "flag": "🇬🇵",
        "code": "GP",
        "dial_code": "+590"
    },
    {
        "name": "Guam",
        "flag": "🇬🇺",
        "code": "GU",
        "dial_code": "+1671"
    },
    {
        "name": "Guatemala",
        "flag": "🇬🇹",
        "code": "GT",
        "dial_code": "+502"
    },
    {
        "name": "Guernsey",
        "flag": "🇬🇬",
        "code": "GG",
        "dial_code": "+44"
    },
    {
        "name": "Guinea",
        "flag": "🇬🇳",
        "code": "GN",
        "dial_code": "+224"
    },
    {
        "name": "Guinea-Bissau",
        "flag": "🇬🇼",
        "code": "GW",
        "dial_code": "+245"
    },
    {
        "name": "Guyana",
        "flag": "🇬🇾",
        "code": "GY",
        "dial_code": "+592"
    },
    {
        "name": "Haiti",
        "flag": "🇭🇹",
        "code": "HT",
        "dial_code": "+509"
    },
    {
        "name": "Heard Island and Mcdonald Islands",
        "flag": "🇭🇲",
        "code": "HM",
        "dial_code": "+672"
    },
    {
        "name": "Holy See (Vatican City State)",
        "flag": "🇻🇦",
        "code": "VA",
        "dial_code": "+379"
    },
    {
        "name": "Honduras",
        "flag": "🇭🇳",
        "code": "HN",
        "dial_code": "+504"
    },
    {
        "name": "Hong Kong",
        "flag": "🇭🇰",
        "code": "HK",
        "dial_code": "+852"
    },
    {
        "name": "Hungary",
        "flag": "🇭🇺",
        "code": "HU",
        "dial_code": "+36"
    },
    {
        "name": "Iceland",
        "flag": "🇮🇸",
        "code": "IS",
        "dial_code": "+354"
    },
    {
        "name": "India",
        "flag": "🇮🇳",
        "code": "IN",
        "dial_code": "+91"
    },
    {
        "name": "Indonesia",
        "flag": "🇮🇩",
        "code": "ID",
        "dial_code": "+62"
    },
    {
        "name": "Iraq",
        "flag": "🇮🇶",
        "code": "IQ",
        "dial_code": "+964"
    },
    {
        "name": "Ireland",
        "flag": "🇮🇪",
        "code": "IE",
        "dial_code": "+353"
    },
    {
        "name": "Isle of Man",
        "flag": "🇮🇲",
        "code": "IM",
        "dial_code": "+44"
    },
    {
        "name": "Israel",
        "flag": "🇮🇱",
        "code": "IL",
        "dial_code": "+972"
    },
    {
        "name": "Italy",
        "flag": "🇮🇹",
        "code": "IT",
        "dial_code": "+39"
    },
    {
        "name": "Jamaica",
        "flag": "🇯🇲",
        "code": "JM",
        "dial_code": "+1876"
    },
    {
        "name": "Japan",
        "flag": "🇯🇵",
        "code": "JP",
        "dial_code": "+81"
    },
    {
        "name": "Jersey",
        "flag": "🇯🇪",
        "code": "JE",
        "dial_code": "+44"
    },
    {
        "name": "Jordan",
        "flag": "🇯🇴",
        "code": "JO",
        "dial_code": "+962"
    },
    {
        "name": "Kazakhstan",
        "flag": "🇰🇿",
        "code": "KZ",
        "dial_code": "+7"
    },
    {
        "name": "Kenya",
        "flag": "🇰🇪",
        "code": "KE",
        "dial_code": "+254"
    },
    {
        "name": "Kiribati",
        "flag": "🇰🇮",
        "code": "KI",
        "dial_code": "+686"
    },
    {
        "name": "Korea, Republic of South Korea",
        "flag": "🇰🇷",
        "code": "KR",
        "dial_code": "+82"
    },
    {
        "name": "Kosovo",
        "flag": "🇽🇰",
        "code": "XK",
        "dial_code": "+383"
    },
    {
        "name": "Kuwait",
        "flag": "🇰🇼",
        "code": "KW",
        "dial_code": "+965"
    },
    {
        "name": "Kyrgyzstan",
        "flag": "🇰🇬",
        "code": "KG",
        "dial_code": "+996"
    },
    {
        "name": "Laos",
        "flag": "🇱🇦",
        "code": "LA",
        "dial_code": "+856"
    },
    {
        "name": "Latvia",
        "flag": "🇱🇻",
        "code": "LV",
        "dial_code": "+371"
    },
    {
        "name": "Lebanon",
        "flag": "🇱🇧",
        "code": "LB",
        "dial_code": "+961"
    },
    {
        "name": "Lesotho",
        "flag": "🇱🇸",
        "code": "LS",
        "dial_code": "+266"
    },
    {
        "name": "Liberia",
        "flag": "🇱🇷",
        "code": "LR",
        "dial_code": "+231"
    },
    {
        "name": "Libyan Arab Jamahiriya",
        "flag": "🇱🇾",
        "code": "LY",
        "dial_code": "+218"
    },
    {
        "name": "Liechtenstein",
        "flag": "🇱🇮",
        "code": "LI",
        "dial_code": "+423"
    },
    {
        "name": "Lithuania",
        "flag": "🇱🇹",
        "code": "LT",
        "dial_code": "+370"
    },
    {
        "name": "Luxembourg",
        "flag": "🇱🇺",
        "code": "LU",
        "dial_code": "+352"
    },
    {
        "name": "Macao",
        "flag": "🇲🇴",
        "code": "MO",
        "dial_code": "+853"
    },
    {
        "name": "Macedonia",
        "flag": "🇲🇰",
        "code": "MK",
        "dial_code": "+389"
    },
    {
        "name": "Madagascar",
        "flag": "🇲🇬",
        "code": "MG",
        "dial_code": "+261"
    },
    {
        "name": "Malawi",
        "flag": "🇲🇼",
        "code": "MW",
        "dial_code": "+265"
    },
    {
        "name": "Malaysia",
        "flag": "🇲🇾",
        "code": "MY",
        "dial_code": "+60"
    },
    {
        "name": "Maldives",
        "flag": "🇲🇻",
        "code": "MV",
        "dial_code": "+960"
    },
    {
        "name": "Mali",
        "flag": "🇲🇱",
        "code": "ML",
        "dial_code": "+223"
    },
    {
        "name": "Malta",
        "flag": "🇲🇹",
        "code": "MT",
        "dial_code": "+356"
    },
    {
        "name": "Marshall Islands",
        "flag": "🇲🇭",
        "code": "MH",
        "dial_code": "+692"
    },
    {
        "name": "Martinique",
        "flag": "🇲🇶",
        "code": "MQ",
        "dial_code": "+596"
    },
    {
        "name": "Mauritania",
        "flag": "🇲🇷",
        "code": "MR",
        "dial_code": "+222"
    },
    {
        "name": "Mauritius",
        "flag": "🇲🇺",
        "code": "MU",
        "dial_code": "+230"
    },
    {
        "name": "Mayotte",
        "flag": "🇾🇹",
        "code": "YT",
        "dial_code": "+262"
    },
    {
        "name": "Mexico",
        "flag": "🇲🇽",
        "code": "MX",
        "dial_code": "+52"
    },
    {
        "name": "Micronesia, Federated States of Micronesia",
        "flag": "🇫🇲",
        "code": "FM",
        "dial_code": "+691"
    },
    {
        "name": "Moldova",
        "flag": "🇲🇩",
        "code": "MD",
        "dial_code": "+373"
    },
    {
        "name": "Monaco",
        "flag": "🇲🇨",
        "code": "MC",
        "dial_code": "+377"
    },
    {
        "name": "Mongolia",
        "flag": "🇲🇳",
        "code": "MN",
        "dial_code": "+976"
    },
    {
        "name": "Montenegro",
        "flag": "🇲🇪",
        "code": "ME",
        "dial_code": "+382"
    },
    {
        "name": "Montserrat",
        "flag": "🇲🇸",
        "code": "MS",
        "dial_code": "+1664"
    },
    {
        "name": "Morocco",
        "flag": "🇲🇦",
        "code": "MA",
        "dial_code": "+212"
    },
    {
        "name": "Mozambique",
        "flag": "🇲🇿",
        "code": "MZ",
        "dial_code": "+258"
    },
    {
        "name": "Myanmar",
        "flag": "🇲🇲",
        "code": "MM",
        "dial_code": "+95"
    },
    {
        "name": "Namibia",
        "flag": "🇳🇦",
        "code": "NA",
        "dial_code": "+264"
    },
    {
        "name": "Nauru",
        "flag": "🇳🇷",
        "code": "NR",
        "dial_code": "+674"
    },
    {
        "name": "Nepal",
        "flag": "🇳🇵",
        "code": "NP",
        "dial_code": "+977"
    },
    {
        "name": "Netherlands",
        "flag": "🇳🇱",
        "code": "NL",
        "dial_code": "+31"
    },
    {
        "name": "Netherlands Antilles",
        "flag": "",
        "code": "AN",
        "dial_code": "+599"
    },
    {
        "name": "New Caledonia",
        "flag": "🇳🇨",
        "code": "NC",
        "dial_code": "+687"
    },
    {
        "name": "New Zealand",
        "flag": "🇳🇿",
        "code": "NZ",
        "dial_code": "+64"
    },
    {
        "name": "Nicaragua",
        "flag": "🇳🇮",
        "code": "NI",
        "dial_code": "+505"
    },
    {
        "name": "Niger",
        "flag": "🇳🇪",
        "code": "NE",
        "dial_code": "+227"
    },
    {
        "name": "Nigeria",
        "flag": "🇳🇬",
        "code": "NG",
        "dial_code": "+234"
    },
    {
        "name": "Niue",
        "flag": "🇳🇺",
        "code": "NU",
        "dial_code": "+683"
    },
    {
        "name": "Norfolk Island",
        "flag": "🇳🇫",
        "code": "NF",
        "dial_code": "+672"
    },
    {
        "name": "Northern Mariana Islands",
        "flag": "🇲🇵",
        "code": "MP",
        "dial_code": "+1670"
    },
    {
        "name": "Norway",
        "flag": "🇳🇴",
        "code": "NO",
        "dial_code": "+47"
    },
    {
        "name": "Oman",
        "flag": "🇴🇲",
        "code": "OM",
        "dial_code": "+968"
    },
    {
        "name": "Pakistan",
        "flag": "🇵🇰",
        "code": "PK",
        "dial_code": "+92"
    },
    {
        "name": "Palau",
        "flag": "🇵🇼",
        "code": "PW",
        "dial_code": "+680"
    },
    {
        "name": "Palestinian Territory, Occupied",
        "flag": "🇵🇸",
        "code": "PS",
        "dial_code": "+970"
    },
    {
        "name": "Panama",
        "flag": "🇵🇦",
        "code": "PA",
        "dial_code": "+507"
    },
    {
        "name": "Papua New Guinea",
        "flag": "🇵🇬",
        "code": "PG",
        "dial_code": "+675"
    },
    {
        "name": "Paraguay",
        "flag": "🇵🇾",
        "code": "PY",
        "dial_code": "+595"
    },
    {
        "name": "Peru",
        "flag": "🇵🇪",
        "code": "PE",
        "dial_code": "+51"
    },
    {
        "name": "Philippines",
        "flag": "🇵🇭",
        "code": "PH",
        "dial_code": "+63"
    },
    {
        "name": "Pitcairn",
        "flag": "🇵🇳",
        "code": "PN",
        "dial_code": "+64"
    },
    {
        "name": "Poland",
        "flag": "🇵🇱",
        "code": "PL",
        "dial_code": "+48"
    },
    {
        "name": "Portugal",
        "flag": "🇵🇹",
        "code": "PT",
        "dial_code": "+351"
    },
    {
        "name": "Puerto Rico",
        "flag": "🇵🇷",
        "code": "PR",
        "dial_code": "+1939"
    },
    {
        "name": "Qatar",
        "flag": "🇶🇦",
        "code": "QA",
        "dial_code": "+974"
    },
    {
        "name": "Romania",
        "flag": "🇷🇴",
        "code": "RO",
        "dial_code": "+40"
    },
    {
        "name": "Rwanda",
        "flag": "🇷🇼",
        "code": "RW",
        "dial_code": "+250"
    },
    {
        "name": "Reunion",
        "flag": "🇷🇪",
        "code": "RE",
        "dial_code": "+262"
    },
    {
        "name": "Saint Barthelemy",
        "flag": "🇧🇱",
        "code": "BL",
        "dial_code": "+590"
    },
    {
        "name": "Saint Helena, Ascension and Tristan Da Cunha",
        "flag": "🇸🇭",
        "code": "SH",
        "dial_code": "+290"
    },
    {
        "name": "Saint Kitts and Nevis",
        "flag": "🇰🇳",
        "code": "KN",
        "dial_code": "+1869"
    },
    {
        "name": "Saint Lucia",
        "flag": "🇱🇨",
        "code": "LC",
        "dial_code": "+1758"
    },
    {
        "name": "Saint Martin",
        "flag": "🇲🇫",
        "code": "MF",
        "dial_code": "+590"
    },
    {
        "name": "Saint Pierre and Miquelon",
        "flag": "🇵🇲",
        "code": "PM",
        "dial_code": "+508"
    },
    {
        "name": "Saint Vincent and the Grenadines",
        "flag": "🇻🇨",
        "code": "VC",
        "dial_code": "+1784"
    },
    {
        "name": "Samoa",
        "flag": "🇼🇸",
        "code": "WS",
        "dial_code": "+685"
    },
    {
        "name": "San Marino",
        "flag": "🇸🇲",
        "code": "SM",
        "dial_code": "+378"
    },
    {
        "name": "Sao Tome and Principe",
        "flag": "🇸🇹",
        "code": "ST",
        "dial_code": "+239"
    },
    {
        "name": "Saudi Arabia",
        "flag": "🇸🇦",
        "code": "SA",
        "dial_code": "+966"
    },
    {
        "name": "Senegal",
        "flag": "🇸🇳",
        "code": "SN",
        "dial_code": "+221"
    },
    {
        "name": "Serbia",
        "flag": "🇷🇸",
        "code": "RS",
        "dial_code": "+381"
    },
    {
        "name": "Seychelles",
        "flag": "🇸🇨",
        "code": "SC",
        "dial_code": "+248"
    },
    {
        "name": "Sierra Leone",
        "flag": "🇸🇱",
        "code": "SL",
        "dial_code": "+232"
    },
    {
        "name": "Singapore",
        "flag": "🇸🇬",
        "code": "SG",
        "dial_code": "+65"
    },
    {
        "name": "Slovakia",
        "flag": "🇸🇰",
        "code": "SK",
        "dial_code": "+421"
    },
    {
        "name": "Slovenia",
        "flag": "🇸🇮",
        "code": "SI",
        "dial_code": "+386"
    },
    {
        "name": "Solomon Islands",
        "flag": "🇸🇧",
        "code": "SB",
        "dial_code": "+677"
    },
    {
        "name": "Somalia",
        "flag": "🇸🇴",
        "code": "SO",
        "dial_code": "+252"
    },
    {
        "name": "South Africa",
        "flag": "🇿🇦",
        "code": "ZA",
        "dial_code": "+27"
    },
    {
        "name": "South Sudan",
        "flag": "🇸🇸",
        "code": "SS",
        "dial_code": "+211"
    },
    {
        "name": "South Georgia and the South Sandwich Islands",
        "flag": "🇬🇸",
        "code": "GS",
        "dial_code": "+500"
    },
    {
        "name": "Spain",
        "flag": "🇪🇸",
        "code": "ES",
        "dial_code": "+34"
    },
    {
        "name": "Sri Lanka",
        "flag": "🇱🇰",
        "code": "LK",
        "dial_code": "+94"
    },
    {
        "name": "Sudan",
        "flag": "🇸🇩",
        "code": "SD",
        "dial_code": "+249"
    },
    {
        "name": "Suriname",
        "flag": "🇸🇷",
        "code": "SR",
        "dial_code": "+597"
    },
    {
        "name": "Svalbard and Jan Mayen",
        "flag": "🇸🇯",
        "code": "SJ",
        "dial_code": "+47"
    },
    {
        "name": "Swaziland",
        "flag": "🇸🇿",
        "code": "SZ",
        "dial_code": "+268"
    },
    {
        "name": "Sweden",
        "flag": "🇸🇪",
        "code": "SE",
        "dial_code": "+46"
    },
    {
        "name": "Switzerland",
        "flag": "🇨🇭",
        "code": "CH",
        "dial_code": "+41"
    },
    {
        "name": "Taiwan",
        "flag": "🇹🇼",
        "code": "TW",
        "dial_code": "+886"
    },
    {
        "name": "Tajikistan",
        "flag": "🇹🇯",
        "code": "TJ",
        "dial_code": "+992"
    },
    {
        "name": "Tanzania, United Republic of Tanzania",
        "flag": "🇹🇿",
        "code": "TZ",
        "dial_code": "+255"
    },
    {
        "name": "Thailand",
        "flag": "🇹🇭",
        "code": "TH",
        "dial_code": "+66"
    },
    {
        "name": "Timor-Leste",
        "flag": "🇹🇱",
        "code": "TL",
        "dial_code": "+670"
    },
    {
        "name": "Togo",
        "flag": "🇹🇬",
        "code": "TG",
        "dial_code": "+228"
    },
    {
        "name": "Tokelau",
        "flag": "🇹🇰",
        "code": "TK",
        "dial_code": "+690"
    },
    {
        "name": "Tonga",
        "flag": "🇹🇴",
        "code": "TO",
        "dial_code": "+676"
    },
    {
        "name": "Trinidad and Tobago",
        "flag": "🇹🇹",
        "code": "TT",
        "dial_code": "+1868"
    },
    {
        "name": "Tunisia",
        "flag": "🇹🇳",
        "code": "TN",
        "dial_code": "+216"
    },
    {
        "name": "Turkey",
        "flag": "🇹🇷",
        "code": "TR",
        "dial_code": "+90"
    },
    {
        "name": "Turkmenistan",
        "flag": "🇹🇲",
        "code": "TM",
        "dial_code": "+993"
    },
    {
        "name": "Turks and Caicos Islands",
        "flag": "🇹🇨",
        "code": "TC",
        "dial_code": "+1649"
    },
    {
        "name": "Tuvalu",
        "flag": "🇹🇻",
        "code": "TV",
        "dial_code": "+688"
    },
    {
        "name": "Uganda",
        "flag": "🇺🇬",
        "code": "UG",
        "dial_code": "+256"
    },
    {
        "name": "Ukraine",
        "flag": "🇺🇦",
        "code": "UA",
        "dial_code": "+380"
    },
    {
        "name": "United Arab Emirates",
        "flag": "🇦🇪",
        "code": "AE",
        "dial_code": "+971"
    },
    {
        "name": "United Kingdom",
        "flag": "🇬🇧",
        "code": "GB",
        "dial_code": "+44"
    },
    {
        "name": "Uruguay",
        "flag": "🇺🇾",
        "code": "UY",
        "dial_code": "+598"
    },
    {
        "name": "Uzbekistan",
        "flag": "🇺🇿",
        "code": "UZ",
        "dial_code": "+998"
    },
    {
        "name": "Vanuatu",
        "flag": "🇻🇺",
        "code": "VU",
        "dial_code": "+678"
    },
    {
        "name": "Venezuela, Bolivarian Republic of Venezuela",
        "flag": "🇻🇪",
        "code": "VE",
        "dial_code": "+58"
    },
    {
        "name": "Vietnam",
        "flag": "🇻🇳",
        "code": "VN",
        "dial_code": "+84"
    },
    {
        "name": "Virgin Islands, British",
        "flag": "🇻🇬",
        "code": "VG",
        "dial_code": "+1284"
    },
    {
        "name": "Virgin Islands, U.S.",
        "flag": "🇻🇮",
        "code": "VI",
        "dial_code": "+1340"
    },
    {
        "name": "Wallis and Futuna",
        "flag": "🇼🇫",
        "code": "WF",
        "dial_code": "+681"
    },
    {
        "name": "Yemen",
        "flag": "🇾🇪",
        "code": "YE",
        "dial_code": "+967"
    },
    {
        "name": "Zambia",
        "flag": "🇿🇲",
        "code": "ZM",
        "dial_code": "+260"
    },
    {
        "name": "Zimbabwe",
        "flag": "🇿🇼",
        "code": "ZW",
        "dial_code": "+263"
    }
]