import React, { Component } from "react"
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';
import environment from "../../../environment";
import { apiPOSTReq } from "../../../Utils/api";
import { ErrorAlert } from "../Alert";
import { LabeledInput } from "../Input";

class NaicsField extends Component {

    state = {};

    componentDidMount() {
        this.fetchNiacsList({ isLeaf: false });
    }

    fetchNiacsList = (options) => {
        var { isLeaf, naicsCode, naicsLevel } = options;
        var body = options || {};
        this.setState({ loading: true });
        apiPOSTReq(`${environment.uamBaseUrl}/aion/system/getNAICSCodes`, {}, body, (err, resp) => {
            // console.log("/aion/system/getNAICSCodes resp", options, resp);
            this.setState({ loading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    var dataToSave = {};
                    if(!isLeaf) {
                        dataToSave["naicsCodeList"] = data.naicsCodeList;
                    } else {
                        var { naicsCodeList } = this.state;
                        var childNaicsCodeList = data.naicsCodeList;
                        var childList = [];
                        naicsCodeList = (naicsCodeList || []).map(item => {
                            if(naicsLevel == 4) {
                                var parentCode = naicsCode.substr(0, 2);
                                var childCode = naicsCode;
                                if(isParentCode(item.code, parentCode)) {
                                    childList = item.children;
                                    (childList || []).map(childItem => {
                                        if(childItem.code == childCode) {
                                            // Mark level 4 children as leaf
                                            childNaicsCodeList.forEach(i => {i.isLeaf = true});
                                            childItem.children = childNaicsCodeList;
                                        }
                                    });
                                }
                                function isParentCode(pCode, cCode) {
                                    // Some pCode can be a range like manufacturing: "31-33"
                                    const pCodeArr = pCode.split("-");
                                    const startRange = pCodeArr[0];
                                    const endRange = pCodeArr[pCodeArr.length-1];
                                    if(startRange == endRange) return (pCode == cCode)
                                    else if(startRange < endRange) return ((cCode >= startRange) && (cCode <= endRange))
                                }
                            } else {
                                if(item.code == naicsCode) {
                                    item.children = childNaicsCodeList;
                                }
                            }
                            return item;
                        });
                        dataToSave["naicsCodeList"] = naicsCodeList;
                    }
                    // console.log("dataToSave naicsCodeList", dataToSave);
                    this.setState(dataToSave);
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    loadData = (selectedOptions) => {
        const selectedOption = selectedOptions[selectedOptions.length - 1];
        // console.log("selectedOption", selectedOption);
        // selectedOption.loading = true; // load options lazily
        var naicsLevel = 0;
        naicsLevel = selectedOption.level + 2;
        var options = {
            "naicsCode": selectedOption.code,
            "naicsLevel": naicsLevel, // get the next level
            "isLeaf": true
        }
        this.fetchNiacsList(options);
    }

    render() {
        const { naicsCodeList, selectedVal } = this.state;
        // console.log("naicsCodeList selectedVal", this.props.value, selectedVal)
        var items = [];
        function formatOptions(items) {
            return items.map(item => {
                var fItem = item;
                fItem["value"] = item.naicsTitle;
                fItem["label"] = item.naicsTitle;
                fItem["isLeaf"] = item.isLeaf || false;
                if(item.children) fItem["children"] = formatOptions(item.children);
                return fItem;
            })
        }
        items = formatOptions(naicsCodeList || []);
        return (
            <LabeledInput
                autoFocus={this.props.autoFocus}
                showAction={'focus'}
                labelcolor={this.props.theme.colors.secondary3}
                label="Industry"
                type="cascader"
                key={0}
                placeholder="Select industry"
                className="no-left-padding test"
                optionFilterProp="children"
                onChange={(value, item) => {
                    // console.log("NAICS onChange", value, item)
                    this.props.onChange(item[item.length-1]);
                    this.setState({selectedVal: item[item.length-1]});
                }}
                // expandTrigger={'hover'}
                options={items}
                changeOnSelect
                loadData={this.loadData}
                value={this.props.value ? [this.props.value] : null}
                containerStyle={{ height: 42, padding: '0 0 0 16px' }}
                error={this.props.error}
                errorMessage={this.props.errorMessage}
            />
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(NaicsField)));