import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'

import { message, Select } from 'antd'

// Components
import { Button } from '../../../Reusable/Button'
import { Paragraph, Title } from '../../../Reusable/Text'
import { FlexColumn, } from '../../../Reusable/Container'
import { LabeledInput } from '../../../Reusable/Input'
import { ErrorAlert } from '../../../Reusable/Alert'
import environment from '../../../../environment'
import { apiPOSTReq } from '../../../../Utils/api'

const { Option } = Select


class AddRestriction extends Component {

    state = {
        loading: false,
        account: this.props.account || {},
    }

    handleTextChange = (event) => {
        this.setState({ [event.target.id]: event.target.value })
    }

    handleSubmit = async (event) => {
        var { account, rail, transactionType, notes } = this.state

        // Check if a restriction exists already
        var rExists = this.props.doesRestrictionExist({ rail, transactionType })

        if(rExists !== undefined) {
            ErrorAlert({ description: "This restriction already exists" })
            return
        }

        if (!rail) {
            ErrorAlert({ description: "Select a rail" })
            return
        }
        if (!transactionType) {
            ErrorAlert({ description: "Select transaction type" })
            return
        }

        if (!notes) {
            ErrorAlert({ description: "Add a note" })
            return
        }

        const body = {
            "restrictionInfo": {
                masterAccountNumber: account.accountNumber,
                rail: rail,
                transactionType, transactionType,
                reason: 'Restricted',
                notes: notes,
                appliesTo: "All"
            },
        }

        this.setState({ loading: true })
        const headers = {
            BankProvider: environment.bankProvider.crb
        }
        apiPOSTReq(`${environment.bpBaseUrl}/bp/payments/restrictions`, headers, body, (err, resp) => {
            try {
                console.log("handleSubmit account response ", (resp))
                const data = resp
                console.log("/restrictions", data)
                if (data.result) {
                    message.success(`Restriction successfully created`)
                    this.props.submitComplete()
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                this.setState({ loading: false })
                console.log("ERRR /restrictions", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    render() {
        const { account, loading, rail, transactionType, notes } = this.state
        const { theme } = this.props

        return (
            <FlexColumn center style={{ minHeight: "80%" }}>
                <div style={{ width: "600px" }}>
                    <Title level={4}>Add Restriction</Title>
                    <Paragraph>Enter the details below.</Paragraph>
                    <FlexColumn>
                        <LabeledInput
                            label="Rail"
                            labelcolor={theme.colors.secondary3}
                            id="rail"
                            key="rail"
                            type="select"
                            className="no-left-padding"
                            placeholder="Select"
                            value={rail}
                            onChange={(val) => this.setState({ rail: val })}
                        >
                            {["Checks", /*"Ach", "Wire", "Internal", "CardManagement"*/].map(item => (
                                <Option key={item} id="rail" name="rail" value={item}>
                                    {item}
                                </Option>
                            ))}
                        </LabeledInput>
                        <LabeledInput
                            label="Transaction Type"
                            labelcolor={theme.colors.secondary3}
                            id="transactionType"
                            key="transactionType"
                            type="select"
                            className="no-left-padding"
                            placeholder="Select"
                            value={transactionType}
                            onChange={(val) => this.setState({ transactionType: val })}
                        >
                            {["Debit", "Credit"].map(item => (
                                <Option key={item} id="transactionType" name="transactionType" value={item}>
                                    {item}
                                </Option>
                            ))}
                        </LabeledInput>
                        <LabeledInput
                            label="Notes"
                            labelcolor={theme.colors.secondary3}
                            id="notes"
                            key="notes"
                            placeholder="Enter a note"
                            value={notes}
                            onChange={this.handleTextChange}
                        />
                        <Button style={{ alignSelf: 'center', width: '100%', margin: '20px 0' }} type="primary" size="large" loading={loading} onClick={this.handleSubmit} text={"Save"} />
                    </FlexColumn>
                </div>
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer,
        bankingStore: state.bankingAppReducer,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(AddRestriction))