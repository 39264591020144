import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { Tabs, Empty, Table, Affix, Drawer } from 'antd'

import PageHeader from "../../Reusable/PageHeader"
import { Flex, FlexColumn } from '../../Reusable/Container'
import { Image } from '../../Reusable/Image'
import { ErrorAlert } from '../../Reusable/Alert'
import { Tag } from '../../Reusable/Text'
import { Button, TextButton } from '../../Reusable/Button'
import AlertModal from '../../Reusable/AlertModal'
import ActivityTable from '../../Banking/Reusable/ActivityTable'
import { saveAs } from 'file-saver'

// Util
import environment from '../../../environment'
import { apiGET, apiPOSTReq, getPreSignedS3Url } from '../../../Utils/api'
import { getBPAccountsWithAccess, toCurrency, capitalizeWords } from '../../../Utils/util'
import { LabeledInput } from '../../Reusable/Input'
import { Text } from '../../Reusable/Text'
import { CardContainer } from '../../Reusable/Container'
import { withTheme } from 'styled-components'
import PaymentDrawer from '../Invoices/PaymentDrawer'

import MatchInvoices from '../../../Images/match-invoices.png'
import Search from '../../../Images/search.png'
import AI from '../../../Images/aisparkle.png'
import Add from '../../../Images/add.svg'
import Check from '../../../Images/check-round-white.png'
import Info from '../../../Images/info-gradient.png'
import ModalClose from '../../../Images/modal-close.png'


const { TabPane } = Tabs

const dFormat = "ll";

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
})

class Index extends Component {
    state = {
        loadingSuggested: true,
        loadingAll: true,
        customize: true,
        transactionsLoaded: false,
        transactions: [],
        showReconcileModal: false,
        modalWidth: 700,
        selectedTab: "Payments",
        // reconciledPaymentPagination: {
        //     current: 1,
        //     pageSize: 100,
        // },
        pagination: {
            current: 1,
            pageSize: 10,
        },
        expandedRowKeys: [],
        tab: 'suggested',
    }

    componentDidMount() {
        var accounts = getBPAccountsWithAccess("Transactions")
        var accountId = (accounts[0] || {}).AccountId
        this.setState({ accounts: accounts, accountId: accountId })
        this.getSuggestedPayments({ pagination: this.state.pagination })
        this.fetchTransactions({ pagination: this.state.pagination })
    }

    customizeRenderEmpty = () => (
        <Empty
            imageStyle={{
                height: 60,
            }}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
                <span>
                    No payments found
                </span>
            }
        />
    )

    getSuggestedPayments(options) {
        const { invoice, customer } = this.props.location.state
        const { searchStr, pagination } = options

        var body = {
            pagination,
            customerId: customer.customerId,
            customerName: customer.displayName,
            invoiceId: invoice.invoiceId,
        }

        if (searchStr && searchStr != '') {
            if (isNaN(searchStr)) {
                body.searchStr = searchStr
            } else {
                body.searchAmt = searchStr
            }
        }

        console.log("/ive/invoice/getSuggestedPayments body", body)

        this.setState({ loadingSuggested: true })

        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/getSuggestedPayments`, {}, body, (err, resp) => {
            try {
                const data = resp || {}
                console.log("/ive/invoice/getSuggestedPayments", data)
                if (data.result) {
                    this.setState({ suggestedPayments: data.suggestedBbTransactions })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getSuggestedPayments", error, resp)
                ErrorAlert({ description: error.message })
            } finally {
                this.setState({ loadingSuggested: false })
            }
        })
    }

    fetchTransactions = (options) => {
        var { currentOptions, allPayments, suggestedPayments } = this.state
        var { fromDate, toDate, searchStr, fromAmt, toAmt, railList, viewBy, pagination } = options
        suggestedPayments = suggestedPayments || []

        console.log('getAllPayments fetchTransactions body: currentOptions', options)

        var body = {
            size: pagination.pageSize,
            page: pagination.current - 1,
            BusinessId: null,
        }

        console.log('getAllPayments fetchTransactions body1:', body)

        if (searchStr && searchStr != '') body.searchStr = searchStr
        if (fromDate) body.fromDate = fromDate
        if (toDate) body.toDate = toDate
        if (fromAmt) body.fromAmount = fromAmt
        if (toAmt) body.toAmount = toAmt
        if (railList) body.railList = railList
        // if (viewBy && searchStr && searchStr != '') body.viewBy = viewBy

        console.log('getAllPayments fetchTransactions body:', body)

        if (currentOptions) currentOptions.BusinessId = null

        if (JSON.stringify(currentOptions) !== JSON.stringify(body)) {
            console.log('getAllPayments fetchTransactions body: currentOptions', currentOptions, body)
            this.setState({ loadingAll: true, currentOptions: body })

            apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/getAllPayments`, {}, body, (err, resp) => {
                try {
                    const data = resp || {}
                    console.log("getAllPayments fetchTransactions", data)
                    if (data.result) {
                        var transactions = pagination.current > 1 ? allPayments.concat(data.suggestedBbTransactions) : data.suggestedBbTransactions
                        var moreData = transactions.length == data.count ? false : true
                        console.log("getAllPayments fetchTransactions set", transactions)

                        transactions.forEach(payment => {
                            suggestedPayments.forEach(suggestedPayment => {
                                if (payment.id === suggestedPayment.id) {
                                    payment.reconcileAmount = suggestedPayment.reconcileAmount
                                }
                            })
                        })

                        this.setState({ loading: false, allPayments: transactions, moreToLoad: moreData, pagination })
                    } else {
                        throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                    }
                } catch (error) {
                    this.setState({ loading: false })
                    console.log("ERRR getAllPayments", error, resp);
                    ErrorAlert({ description: error.message })
                } finally {
                    this.setState({ loadingAll: false })
                }
            })
        }
    }

    loadMoreActivity = () => {
        const { pagination, currentOptions } = this.state

        pagination.current = pagination.current + 1

        if (currentOptions) {
            currentOptions.pagination = pagination
            this.fetchTransactions(currentOptions)
        } else {
            this.fetchTransactions({ pagination })
        }
    }

    handleMatchPayment = (qbo, payment) => {
        this.props.history.push({
            pathname: '/receivables/invoice-payments/match-invoice',
            state: { qbo, payment }
        })
    }

    handleTableChange = (pagination) => {
        this.setState({ pagination })
    }

    downloadInvoice = (invoice) => {
        var invoiceDoc = (invoice.attachments || []).find(item => item.type == "InvoiceDoc")
        if (invoiceDoc) {
            getPreSignedS3Url({ url: invoiceDoc.uri }, (err, preSignedUrl) => {
                invoiceDoc.preSignedUrl = preSignedUrl || ""
                saveAs(invoiceDoc.preSignedUrl, `Invoice ${invoice.docNumber}`)
            })
        } else {
            ErrorAlert({ description: "No document found for this invoice" })
        }
    }

    reconcile = () => {
        const userInfo = this.props.aionStore?.userInfo;
        const { invoice, balance } = this.props.location.state
        const { suggestedPayments, allPayments, tab } = this.state
        const invoicePayments = []
        // const transactions = tab === 'suggested' ? suggestedPayments : allPayments

        const transactions = this.mergeById((suggestedPayments || []), (allPayments || []));

        const totalReconcileAmount = transactions?.reduce((acc, transaction) => acc + Number(transaction.reconcileAmount || 0), 0);

        if ((balance - totalReconcileAmount) < 0) {
            ErrorAlert({ description: "You can't reconcile an amount greater than the invoice balance" })
            return
        }

        transactions.forEach(transaction => {
            if (transaction.reconcileAmount && transaction.reconcileAmount > 0) {
                invoicePayments.push({
                    transactionId: transaction.transactionId,
                    amount: transaction.reconcileAmount,
                    paymentDate: transaction.txnDate,
                })
            }
        })

        const body = {
            invoiceId: invoice.invoiceId,
            invoicePayments,
            modifiedByUserName: `${userInfo.firstName} ${userInfo.lastName}`
        }

        console.log('InvoicePayments reconcile reconcileInvoicePayments body', body)

        this.setState({ loading: true })

        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/reconcileInvoicePayments`, {}, body, (err, resp) => {
            try {
                const data = resp || {}
                if (data.result) {
                    console.log("/ive/invoice/reconcileInvoicePayments", data)
                    this.setState({ showSuccessModal: true })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                this.setState({ loading: false })
                console.log("ERRR reconcileInvoicePayments", error, resp);
                ErrorAlert({ description: error.message })
            } finally {
                this.setState({ loading: false })
            }
        })
    }

    onChangeTab = (key) => {
        const { allPayments, suggestedPayments } = this.state
        if (key === 'all') {
            allPayments.forEach(payment => {
                suggestedPayments.forEach(suggestedPayment => {
                    if (payment.id === suggestedPayment.id) {
                        payment.reconcileAmount = suggestedPayment.reconcileAmount
                    }
                })
            })
        }
        if (key === 'suggested') {
            suggestedPayments.forEach(suggestedPayment => {
                allPayments.forEach(payment => {
                    if (suggestedPayment.id === payment.id) {
                        suggestedPayment.reconcileAmount = payment.reconcileAmount
                    }
                })
            })
        }

        this.setState({ tab: key, allPayments, suggestedPayments })
    }

    mergeById = (arr1, arr2) => {
        const merged = [...arr2, ...arr1].reduce((acc, item) => {
            acc[item.id] = { ...acc[item.id], ...item };
            return acc;
        }, {});

        return Object.values(merged);
    };

    render() {
        const { invoice, customer, balance } = this.props.location.state
        const { tab, loading, loadingSuggested, loadingAll, showSuccessModal, suggestedPayments, allPayments, showAddPayment, searchTerm, moreToLoad } = this.state
        const { theme } = this.props
        var { customerName, docNumber, memo, invoiceId, paidOnDate } = invoice || {}
        // const transactions = tab === 'suggested' ? suggestedPayments : allPayments

        const transactions = this.mergeById((suggestedPayments || []), (allPayments || []));

        // const totalReconcileAmount = transactions?.reduce((acc, transaction) => acc + Number(transaction.reconcileAmount || 0), 0);

        const totalReconcileAmount = transactions?.reduce((acc, transaction) => {
            const amount = Number(transaction.reconcileAmount || 0);
            return Number((acc + amount).toFixed(2));
        }, 0);

        var accounts = getBPAccountsWithAccess("Transactions");
        accounts = accounts || []

        const remainingInvoiceBalance = Number(balance || 0) - Number(totalReconcileAmount || 0)

        console.log("InvoicePayments remainingInvoiceBalance, balance, totalReconcileAmount: ", remainingInvoiceBalance, balance, totalReconcileAmount)

        return (
            <FlexColumn className='main-padding' grow fullWidth>
                <PageHeader
                    titleText='Record a Payment'
                    // subtitle="Receivables"
                    onBack={() => this.props.history.replace({
                        pathname: '/receivables/invoices/view',
                        state: { invoice, balance }
                    })}
                    back
                />

                <Flex between fullWidth style={{ marginBottom: 24 }} gap='24px' grow>
                    <CardContainer style={{ marginBottom: 24 }} grow fullWidth fullHeight minWidth='950px'>
                        <FlexColumn start>
                            <FlexColumn start>
                                <Text heading>{`Match payments to invoice ${docNumber}`}</Text>
                                <Text margin='8px 0 24px' width={'592px'}>To settle this invoice, match transactions from your Aion bank accounts. You have the flexibility to match a single transaction or multiple transactions that collectively match the invoice amount.</Text>
                            </FlexColumn>

                            <Tabs size="large" activeKey={tab} onClick={() => { }} onChange={this.onChangeTab} style={{ marginBottom: 16 }}>
                                <TabPane tab={<Flex start centerHorizontally gap='8px' style={{ marginRight: 8 }}><Image src={AI} />Suggested</Flex>} key="suggested" />
                                <TabPane tab="All" key="all" />
                            </Tabs>

                            <ActivityTable
                                key='inv-payments'
                                filter={tab !== 'suggested'}
                                title='Payments'
                                subtitle={tab == 'suggested' ? 'Suggested transactions in your Aion bank accounts' : 'All transactions in your Aion bank accounts'}
                                noShadow
                                noCard
                                padding='0'
                                fetchTransactions={this.fetchTransactions}
                                data={(tab === 'suggested' ? suggestedPayments : allPayments) || []}
                                // subAccount={selectedSubAccount}
                                setSearchTerm={(searchTerm) => this.setState({ searchTerm })}
                                sharedSearchTerm={searchTerm}
                                reconcile
                                onReconcile={(transactions, inputId) => this.setState({ transactions, inputId })}
                                ctaContent={
                                    <Flex start centerHorizontally gap='8px' style={{ marginBottom: 12, marginTop: 12 }}>
                                        <Text>Don't see your payment?</Text>
                                        <TextButton weight='400' text='Add one manually' underline onClick={() => this.setState({ showAddPayment: true })} />
                                    </Flex>
                                }
                                expand={false}
                                moreToLoad={(tab === 'suggested') ? false : moreToLoad}
                                onLoadMore={() => this.loadMoreActivity()}
                                loading={tab === 'suggested' ? loadingSuggested : loadingAll}
                            />
                        </FlexColumn>
                    </CardContainer>

                    <Affix offsetTop={0}>
                        <CardContainer width='296px' fullHeight>
                            <FlexColumn start gap='24px'>
                                <Flex between fullWidth centerHorizontally>
                                    <Text heading>{'Summary'}</Text>
                                    {/* <Tag primary>Unsent</Tag> */}
                                </Flex>
                                {
                                    (invoice && customer) ?
                                        <>
                                            <LabeledInput
                                                nomargin
                                                type="read-only"
                                                label="Customer"
                                                value={customerName}
                                            />

                                            {
                                                customer?.fullyQualifiedName &&
                                                <LabeledInput
                                                    nomargin
                                                    type="read-only"
                                                    label="Contact name"
                                                    value={customer.fullyQualifiedName}
                                                />
                                            }

                                            <Flex between>
                                                <Text lightText>Balance</Text>

                                                <Text>${toCurrency(balance)}</Text>
                                            </Flex>

                                            <Flex between centerHorizontally>
                                                <Text lightText>Reconciled<br />Payments</Text>

                                                <Text weight='500'>${toCurrency(totalReconcileAmount)}</Text>
                                            </Flex>

                                            <div style={{ width: '100%', height: 1, background: '#E3E6EE', margin: '4px 0' }}></div>

                                            <Flex between centerHorizontally>
                                                <Text lightText>Remaining Invoice<br />Balance</Text>

                                                <Text weight='500' size='20px'>${toCurrency(remainingInvoiceBalance)}</Text>
                                            </Flex>

                                            <Button disabled={!(totalReconcileAmount > 0)} loading={loading} solid text='CONFIRM' margin='24px 0 0' onClick={this.reconcile} />
                                        </>
                                        :
                                        <FlexColumn center gap="8px">
                                            <Image src={Info} />
                                            <Text center>Start creating your invoice by choosing the customer</Text>
                                        </FlexColumn>
                                }
                            </FlexColumn>
                        </CardContainer>
                    </Affix>
                </Flex>

                <AlertModal
                    success
                    visible={showSuccessModal}
                    title='Payment matched'
                    description={`Payments amounting to $${toCurrency(totalReconcileAmount)} have been matched with invoice ${docNumber}`}
                    buttonTitle='OKAY'
                    onConfirm={() => {
                        invoice.balance = balance - totalReconcileAmount
                        if (invoice.invoiceStatus === 'SENT') invoice.invoiceStatus = 'PARTIALLYPAID'
                        if (Number(balance - totalReconcileAmount) === 0) invoice.invoiceStatus = 'PAID'
                        this.props.history.replace({
                            pathname: '/receivables/invoices/view',
                            state: { invoice, balance: balance - totalReconcileAmount, payments: true, }
                        })
                    }}
                />

                <Drawer
                    visible={showAddPayment}
                    placement="right"
                    closable={true}
                    onClose={() => this.setState({ showAddPayment: false })}
                    maskClosable={false}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                    width='444px'
                    destroyOnClose={true}
                >
                    <PaymentDrawer
                        invoiceId={invoiceId}
                        docNumber={docNumber}
                        balance={balance}
                        onSave={(newBalance) => {
                            invoice.balance = newBalance
                            if (invoice.invoiceStatus === 'SENT') invoice.invoiceStatus = 'PARTIALLYPAID'
                            if (Number(newBalance) === 0) invoice.invoiceStatus = 'PAID'
                            this.setState({ showAddPayment: false })
                            this.props.history.replace({
                                pathname: '/receivables/invoices/view',
                                state: { invoice, balance: newBalance, payments: true, }
                            })
                        }}
                    />
                </Drawer>
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.creditAppReducer,
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)))