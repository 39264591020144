import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { Modal, Divider, Popover, Collapse } from 'antd';
import { Flex, FlexColumn } from '../../Reusable/Container';
import Banner from '../../Reusable/Banner';
import { Image } from '../../Reusable/Image';
import { Text, Tag } from '../../Reusable/Refresh/Text';
import { TextButton } from '../../Reusable/Button'
import { Container, RefreshContainer, CardContainer } from '../../Reusable/Refresh/Container';
import { Button } from '../../Reusable/Refresh/Button';
import { LabeledInput } from '../../Reusable/Input';
import { addressObjectToStr, STATES_MAP, toCurrency } from '../../../Utils/util';
import { OwnerCard, SubsidirayCard } from '../../Reusable/Refresh/Card';
import FinancialInfo from '../Reusable/FinancialInfo';
import { GradientDivider } from '../../Reusable/Refresh/Input';
import { DownOutlined, InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import moment from 'moment';
import _ from 'lodash'
// Images
import Background from '../../../Images/credit-onb-back-2.png';
import Check from '../../../Images/check-star.png';
import ThumbsUp from '../../../Images/thumbs-up.png';
import TagIcon from '../../../Images/tag.png';
import Profile1 from '../../../Images/review-profile-2.png';
import Quote from '../../../Images/quote.png';
import Question from '../../../Images/question.png';
import Calendar from '../../../Images/calendar.png'
import CollapseCheck from '../../../Images/collapse-check.png';
import Like from '../../../Images/like.png';
import Bullet1 from '../../../Images/bullet-1.png';
import Bullet2 from '../../../Images/bullet-2.png';
import Bullet3 from '../../../Images/bullet-3.png';
import Bullet4 from '../../../Images/bullet-4.png';
import ModalClose from '../../../Images/modal-close.png'
import QB from '../../../Images/quickbooks-icon-sm.jpeg'
import Lightning from '../../../Images/Lightning.png';
import { apiPOSTReq } from '../../../Utils/api'
import environment from '../../../environment'
import DeclineProposal from './DeclineProposal';
import CreditUserProfile from '../Reusable/CreditUserProfile';
import AdditionalDocumentSubmitted from '../Reusable/AdditionalDocumentSubmitted';
import { addDataToStore, ONB_SAVE_DATA, UAM_SAVE_BUSINESS } from '../../../Actions/actions'

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
})

export class CustomerProposal extends Component {
    state = {
        showDeclineProposalModal: false,
        showNextStepModal: false,
        acceptLoading: false,
        pagination: {
            current: 1,
            pageSize: 100
        },
        requests: []
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        var { onboardingStore } = this.props;
        var { businessInfo } = onboardingStore || {};

        const { store, offers } = this.props
        const { Profile } = store || {}
        var { BusinessInfo } = Profile || {}

        console.log("CustomerProposalbusinessInfo", businessInfo, BusinessInfo)
        if (!offers) {
            this.fetchProposalTerms();
        }
        else {
            var proposalTerms = offers[0];
            this.setState({
                proposalTerms: proposalTerms,
                offers: offers
            });
        }
        this.fetchApprovedRequests({ pagination: this.state.pagination })

    }

    fetchApprovedRequests = (options) => {
        const { loanApplication } = this.props;
        const { pagination } = options || {};
        var body = {
            "size": pagination.pageSize,
            "page": pagination.current - 1,
            "sortDirection": "DESC"
        };
        if (loanApplication) {
            body["BusinessId"] = loanApplication.businessId;
            body["applicationId"] = loanApplication.applicationId;
            body["uploadCategory"] = "Custom Upload"
        }

        console.log("/lsm/getApprovedLoanRequest body", body);
        this.setState({ loading: true });
        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/getApprovedLoanRequest`, null, body, (err, resp) => {
            console.log("/lsm/getApprovedLoanRequest resp", resp);
            this.setState({ loading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    this.setState({ requests: data.requests });

                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/getApprovedLoanRequest err", error, resp);
                // ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    fetchProposalTerms = () => {
        const { loanApplication } = this.props;
        var body = {
            "clientBusinessId": loanApplication.businessId,
            "applicationId": loanApplication.applicationId
        };

        this.setState({ loanAppLoading: true });
        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/getProposalTerms`, {}, body, (err, resp) => {
            console.log("/getProposalTerms resp body", resp, body);
            this.setState({ loanAppLoading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    if (data.offers.length > 0) {
                        var proposalTerms = data.offers[0];
                        this.setState({
                            proposalTerms: proposalTerms,
                            offers: data.offers
                        });
                    }
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/getProposalTerms err", error, resp);
                // ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    acceptProposal = (parentOfferId, offerId, index) => {
        if (index != null) {
            this.setState({ ["acceptLoading" + index]: true });
        }

        const { loanApplication } = this.props;
        var body = {
            "clientBusinessId": loanApplication.businessId,
            "applicationId": loanApplication.applicationId,
            "parentOfferId": parentOfferId,
            "offerId": offerId,
            "saveProposalTerms": true
        };

        console.log("/lsm/saveProposalTerms body", body);
        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/saveProposalTerms`, {}, body, (err, resp) => {
            this.setState({ loading: false });
            if (index != null) {
                this.setState({ ["acceptLoading" + index]: false })
            }

            try {
                const data = resp || {};
                if (data.result) {
                    this.props.acceptProposal(() => {

                    })
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp);
                // ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });

    }

    declineProposal = () => {
        this.setState({ showDeclineProposalModal: true });
    }

    handleConfirmDecline = (reason) => {
        this.props.declineProposal(reason);
    }

    handleCloseDeclineProposal = () => {
        this.setState({ showDeclineProposalModal: false });
    }

    render() {
        const { loanApplication, theme, proposalPreview, store, flowView } = this.props;
        var { onboardingStore } = this.props;
        var { proposalTerms, offers, requests } = this.state;

        const { Profile, Attributes } = store || {}
        var { BusinessInfo } = Profile || {}
        // const businessAttributes = Attributes.Businesses || {};

        // var { businessInfo, coOwnersInfo, personalInfo } = onboardingStore;
        // var { firstName, lastName, dob, address, phone, ssn, ownershipPercentage, businessTitle } = personalInfo;
        console.log(this.props);
        const { showDeclineProposalModal, showNextStepModal, acceptLoading } = this.state;
        // var { proposalTerms } = store;

        var { business } = store || {};
        business = business || {};
        onboardingStore = onboardingStore || {}

        const businessAttributes = business.attributes || {};
        var { businessProfile } = business;
        var { businessInfo, coOwnerApplicantInfo, primaryApplicantInfo } = businessProfile || {};
        businessInfo = businessInfo || {}
        primaryApplicantInfo = primaryApplicantInfo || {}
        coOwnerApplicantInfo = coOwnerApplicantInfo || {}

        var { physicalAddress, mailingAddress, subsidiaries } = businessInfo || {};

        return (
            <FlexColumn style={{ alignItems: "start", margin: "24px 0", width: '100%' }}>
                {
                    proposalTerms && proposalTerms.productType == "RLOC" && ((loanApplication.underwritingStatus == "Proposal Issued" || loanApplication.underwritingStatus == "Proposal Expired") || proposalPreview) &&
                    <FlexColumn start style={{ width: "100%" }} centerHorizontally>
                        <Flex between centerHorizontally style={{ width: '100%', height: 144, borderRadius: '8px 8px 0 0', background: 'conic-gradient(from 99.91deg at 16.25% 86.88%, #1199FF 0deg, #1A8AFA 360deg)' }}>
                            <FlexColumn style={{ padding: 24 }}>
                                <Text margin='0 0 4px' color='white'>You are pre-qualified for</Text>
                                <Text margin='0 0 4px' color='white' id="CreditLimit" size='28px' weight='500' height='40px'>{proposalTerms && formatter.format(proposalTerms.creditLimit)}</Text>

                            </FlexColumn>

                            <div style={{ position: 'relative' }}>
                                <img height='144px' src={Background} />
                                <FlexColumn right style={{ position: 'absolute', top: '38px', right: '24px' }}>
                                    <Text color='white'>Offer {loanApplication.underwritingStatus == "Proposal Expired" ? "expired on" : "expires in"}</Text>
                                    {
                                        <>
                                            {loanApplication.underwritingStatus == "Proposal Expired" ?
                                                <Text id="ExpireDay" color='white' weight='500' size='20px' height='40px'>{proposalTerms && moment.utc(proposalTerms.proposalResponseDate).format("MMM DD, YYYY")}</Text> :
                                                <>
                                                    <Text id="ExpireDay" color='white' weight='500' size='20px' height='40px'>{proposalTerms && moment.utc(proposalTerms.proposalResponseDate).endOf('day').diff(moment.utc(), 'days')} {proposalTerms && moment.utc(proposalTerms.proposalResponseDate).endOf('day').diff(moment.utc(), 'days') > 1 ? "days" : proposalTerms && moment.utc(proposalTerms.proposalResponseDate).endOf('day').diff(moment.utc(), 'days') == 0 ? "days" : "day"}</Text>
                                                    <Text id="ExpireDay" color='white' weight='500' size='12px'>{proposalTerms && moment.utc(proposalTerms.proposalResponseDate).format("MMM DD, YYYY")}</Text>
                                                </>
                                            }
                                        </>
                                    }

                                </FlexColumn>
                            </div>
                        </Flex>

                        <FlexColumn start style={{ width: '100%', padding: '24px', borderRadius: '0 0 8px 8px', boxShadow: '0px 4px 8px rgba(102, 102, 102, 0.08)', background: 'white' }}>
                            {(proposalTerms != null && proposalTerms.productType == "RLOC") &&
                                <div style={{ width: 600, marginBottom: 24 }}>
                                    <Text color='#666666'>Only pay for the funds you use. Your customer payments flow through an Aion business banking account in your name and are automatically applied towards your outstanding principal and interest.</Text>
                                </div>
                            }
                            {
                                // (loanApplication.underwritingStatus != "Proposal Expired" && !proposalPreview) &&
                                <Flex start>
                                    {
                                        (loanApplication.underwritingStatus != "Proposal Expired" && !proposalPreview) && <Button weight='500' height='40px' radius='8px' solid text="ACCEPT" onClick={() => { this.acceptProposal(proposalTerms.parentOfferId, proposalTerms.offerId) }} loading={acceptLoading} />
                                    }
                                    {
                                        (loanApplication.underwritingStatus != "Proposal Expired" && !proposalPreview) && <Button weight='500' height='40px' radius='8px' text="NOT INTERESTED" style={{ marginLeft: 24 }} onClick={this.declineProposal} />
                                    }
                                    <Flex end style={{ width: '100%' }}>
                                        <img width='24px' height='24px' style={{ marginRight: '5px' }} src={Question} /> <TextButton margin='0' onClick={() => this.setState({ showNextStepModal: true })} text="See what my next steps are" />
                                    </Flex>
                                </Flex>
                            }
                        </FlexColumn>


                        <Flex style={{ width: '100%' }}>
                            <RefreshContainer style={{ width: '50%', margin: '24px 12px 24px 0' }}>
                                {
                                    (proposalTerms != null && proposalTerms.productType == "RLOC") &&
                                    <FlexColumn style={{ width: "100%" }}>
                                        <Text style={{ marginBottom: 24 }} color='#7384AA' weight='500' uppercase>Monthly Deductions</Text>
                                        <Text color='black' weight='600'>Interest Rate</Text>
                                        <Flex between style={{ width: "100%", marginBottom: 24 }}>
                                            <Text size='12px' color='#666666' height='20px' style={{ marginTop: 4, width: 414 }}>
                                                The rate on your revolving line of credit line would be {proposalTerms && parseFloat(proposalTerms.dailyInterest * 30).toFixed(2)}% monthly or {proposalTerms && parseFloat(proposalTerms.dailyInterest * 360).toFixed(2)}% annually. This rate applies only to the funds you use.
                                                {/* The rate on your line would be {proposalTerms && proposalTerms.dailyInterest * 30} % monthly or {proposalTerms && proposalTerms.dailyInterest * 360}% annually. This rate applies only to the funds you use. Each advance (plus interest and fees) will be repaid as your customers pay. */}
                                            </Text>
                                            <FlexColumn start right>
                                                <Text color='black' style={{ marginBottom: 4 }}>{proposalTerms && parseFloat(proposalTerms.dailyInterest * 30).toFixed(2)}%</Text>
                                                <Text color='#666666' size='14px'>/ Month</Text>
                                            </FlexColumn>
                                        </Flex>
                                        <Flex start centerHorizontally>
                                            <Text color='black' weight='600' style={{ marginRight: 10 }}>Monthly Subscription</Text>
                                            <Popover content={(
                                                <FlexColumn style={{ paddingLeft: 4 }}>
                                                    <Text size='14px'>• Online Banking</Text>
                                                    <Text size='14px'>• Automated Bill Pay</Text>
                                                    <Text size='14px'>• Custom Approval Workflows</Text>
                                                    <Text size='14px'>• Contractor Payroll</Text>
                                                    <Text size='14px'>• Streamlined Invoicing</Text>
                                                    <Text size='14px'>• QuickBooks Integration</Text>
                                                    <Text size='14px'>• Lockbox Services</Text>
                                                    <Text size='14px'>• And more!</Text>
                                                </FlexColumn>
                                            )}>
                                                <img width='24px' height='24px' src={Question} />
                                            </Popover>
                                        </Flex>
                                        <Flex between style={{ width: "100%", marginBottom: 0 }}>
                                            <Text size='12px' color='#666666' height='20px' style={{ marginTop: 4, width: 414 }}>
                                                A monthly subscription gives you access to the Aion platform to manage your revolving line of credit. Advance funds, track payments, and transfer money from anywhere.
                                            </Text>
                                            <FlexColumn start right>
                                                <Text color='black' style={{ marginBottom: 4 }}>{proposalTerms && formatter.format(proposalTerms.platformFee)}</Text>
                                                <Text color='#666666' size='14px'>/ Month</Text>
                                            </FlexColumn>
                                        </Flex>
                                        {/* TODO: TOOLTIP
                                            <Text size='12px' color='#737387'>
                                                Additional features included in your monthly subscription:
                                                <FlexColumn style={{ paddingLeft: 4 }}>
                                                    <Text size='12px' color='#737387' height='20px'>• Online Banking</Text>
                                                    <Text size='12px' color='#737387' height='20px'>• Automated Bill Pay</Text>
                                                    <Text size='12px' color='#737387' height='20px'>• Custom Approval Workflows</Text>
                                                    <Text size='12px' color='#737387' height='20px'>• Contractor Payroll</Text>
                                                    <Text size='12px' color='#737387' height='20px'>• Streamlined Invoicing</Text>
                                                    <Text size='12px' color='#737387' height='20px'>• QuickBooks Integration</Text>
                                                    <Text size='12px' color='#737387' height='20px'>• Lockbox Services</Text>
                                                    <Text size='12px' color='#737387' height='20px'>• And more!</Text>
                                                </FlexColumn>
                                            </Text> */}
                                    </FlexColumn>
                                }
                                {
                                    (proposalTerms != null && proposalTerms.productType == "RLOC") && <>
                                        <Divider />
                                        <Text size='12px' color='#666666' height='20px' style={{ width: '100%' }}>
                                            Available credit based will be based on up to {proposalTerms.advanceFactor}% of your eligible accounts receivable. Your line would be secured by a first-priority lien on all company assets. Individuals or entities that own 25% or more of the business must provide full repayment guarantees.
                                        </Text>
                                    </>
                                }
                            </RefreshContainer>

                            <FlexColumn start style={{ width: '50%', margin: '24px 0 24px 12px' }}>

                                <Flex style={{ width: '100%', marginBottom: 24, minHeight: 186 }}>
                                    <RefreshContainer style={{ width: `${100 / 3}%` }}>
                                        <FlexColumn center>
                                            <img width='40px' height='40px' src={Check} />
                                            <Text center style={{ marginTop: 24 }}>Simple and</Text>
                                            <Text center>transparent pricing</Text>
                                        </FlexColumn>
                                    </RefreshContainer>

                                    <RefreshContainer style={{ width: `${100 / 3}%`, margin: '0 12px', minHeight: 186 }}>
                                        <FlexColumn center>
                                            <img width='40px' height='40px' src={TagIcon} />
                                            <Text center style={{ marginTop: 24 }}>One fixed monthly</Text>
                                            <Text center>subscription fee</Text>
                                        </FlexColumn>
                                    </RefreshContainer>

                                    <RefreshContainer style={{ width: `${100 / 3}%`, minHeight: 186 }}>
                                        <FlexColumn center>
                                            <img width='40px' height='40px' src={ThumbsUp} />
                                            <Text center style={{ marginTop: 24 }}>No hidden fees, no</Text>
                                            <Text center>surprises</Text>
                                        </FlexColumn>
                                    </RefreshContainer>
                                </Flex>

                                <FlexColumn start centerHorizontally>
                                    <CardContainer style={{ width: '100%', marginBottom: 24 }}>
                                        <Text>In the early days of Purely Elizabeth, we needed to keep up with increasing demand. Aion provided flexible, non-dilutive funding that grew with our business and helped us scale.</Text>

                                        <Flex between style={{ marginTop: 12, width: '100%' }}>
                                            <Flex start>
                                                <img width='48px' height='48px' src={Profile1} />
                                                <FlexColumn between style={{ height: 48, marginLeft: 12 }}>
                                                    <Text color='#1199FF'>Mark Freeburg</Text>
                                                    <Text size='12px' height='20px' color='#666666'>CFO, Purely Elizabeth</Text>
                                                </FlexColumn>
                                            </Flex>

                                            <img width='48px' height='48px' src={Quote} />
                                        </Flex>
                                    </CardContainer>
                                </FlexColumn>
                            </FlexColumn>

                        </Flex>
                    </FlexColumn>

                }

                {
                    proposalTerms && proposalTerms.productType == "GNPL" && ((loanApplication.underwritingStatus == "Proposal Issued" || loanApplication.underwritingStatus == "Proposal Expired") || proposalPreview) &&
                    <FlexColumn start style={{ width: "100%" }} centerHorizontally>
                        <Flex between centerHorizontally style={{ width: '100%', borderRadius: '8px 8px 8px 8px', background: 'var(--gradient-vibrant-blue, radial-gradient(41.22% 85.08% at 16.25% 86.88%, #1199FF 0%, #1A8AFA 100%))' }}>
                            <FlexColumn fullWidth style={{ padding: 24, alignItems: 'center' }}>
                                <Text margin='0 0 4px' color='white'>You’ve been pre-approved for</Text>
                                <Text margin='0 0 4px' color='white' id="CreditLimit" size='48px' weight='500' height='60px'>{proposalTerms && formatter.format(proposalTerms.creditLimit)}</Text>
                                <Text color='white'>Offer {loanApplication.underwritingStatus == "Proposal Expired" ? "expired on" : "expires on"} {proposalTerms && moment.utc(proposalTerms.proposalResponseDate).format("MMM DD, YYYY")}</Text>
                                <Button style={{ marginTop: 20, background: "transparent" }} color='white' onClick={() => this.setState({ showNextStepModal: true })} text="Know more" />
                                {!proposalPreview && <TextButton style={{ marginTop: 20 }} color='white' onClick={() => this.declineProposal()} text="NOT INTERESTED" underline />}
                            </FlexColumn>
                            {
                                offers && <>
                                    {
                                        offers.map(
                                            (offer, index) => {
                                                if (offer.repaymentTerms.toString().includes("Months") || offer.repaymentTerms.toString().includes("Weeks")) {
                                                    offer.repaymentTerms = offer.repaymentTerms.split(' ')[0]
                                                }

                                                return <>
                                                    <FlexColumn fullWidth style={{ padding: 24, alignItems: 'center' }}>
                                                        <CardContainer style={{ width: '100%', backgroundColor: 'white' }} margin="0px 10px 10px 0px">
                                                            <Flex start noMargin>
                                                                <FlexColumn style={{ padding: 10, width: '49%', alignItems: 'start' }}>
                                                                    <Text size="20px">{offer.paymentPlan + " Plan"}</Text>
                                                                    {/* {offers.length == 1 && <Text size="20px">{"Proposed Plan"}</Text>} */}
                                                                </FlexColumn>
                                                                {/* <FlexColumn style={{ padding: 10, width: '49%', alignItems: 'end' }}>
                                                                    <Tag><Image src={Calendar} /> {offer.paymentPlan}</Tag>
                                                                </FlexColumn> */}
                                                            </Flex>
                                                            <Flex start noMargin>
                                                                <FlexColumn style={{ padding: 10, width: '49%', alignItems: 'start' }}>
                                                                    <Text color='#666666' size="16px">Flat financing fee</Text>
                                                                </FlexColumn>
                                                                <FlexColumn style={{ padding: 10, width: '49%', alignItems: 'end' }}>
                                                                    <Text size="16px">$ {toCurrency(parseFloat((offer.advanceFee / 100) * offer.creditLimit).toFixed(2))}</Text>
                                                                </FlexColumn>
                                                            </Flex>
                                                            <Flex start noMargin>
                                                                <FlexColumn style={{ padding: 10, width: '49%', alignItems: 'start' }}>
                                                                    <Text color='#666666' size="16px">Pay it back in</Text>
                                                                </FlexColumn>
                                                                <FlexColumn style={{ padding: 10, width: '49%', alignItems: 'end' }}>
                                                                    <Text size="16px">{offer.repaymentTerms + " " + (offer.paymentPlan == "Weekly" ? "Weeks" : "Months")}</Text>
                                                                </FlexColumn>
                                                            </Flex>
                                                            <Flex start noMargin>
                                                                <FlexColumn style={{ padding: 10, width: '49%', alignItems: 'start' }}>
                                                                    <Text color='#666666' size="16px">{offer.paymentPlan} repayment amount</Text>
                                                                </FlexColumn>
                                                                <FlexColumn style={{ padding: 10, width: '49%', alignItems: 'end' }}>
                                                                    <Text size="16px">$ {toCurrency(parseFloat((((offer.advanceFee / 100) * offer.creditLimit) + offer.creditLimit) / (offer.repaymentTerms)).toFixed(2))}</Text>
                                                                </FlexColumn>
                                                            </Flex>
                                                            {
                                                                loanApplication.underwritingStatus != "Proposal Expired" && <Flex style={{ marginTop: 24 }}>
                                                                    {
                                                                        (!proposalPreview && offers.length > 1) && <Button weight='500' loading={this.state["acceptLoading" + index]} height='40px' radius='8px' text="ACCEPT THIS OFFER" style={{ width: '100%' }} onClick={() => {
                                                                            this.acceptProposal(offer.parentOfferId, offer.offerId, index)
                                                                        }} />
                                                                    }
                                                                    {
                                                                        (!proposalPreview && offers.length == 1) && <Button solid weight='500' loading={this.state["acceptLoading" + index]} height='40px' radius='8px' text="ACCEPT" style={{ width: '100%' }} onClick={() => { this.acceptProposal(offer.parentOfferId, offer.offerId, index) }} />
                                                                    }
                                                                </Flex>
                                                            }
                                                        </CardContainer>
                                                    </FlexColumn>
                                                </>
                                            }
                                        )
                                    }
                                </>
                            }
                        </Flex>
                        <Flex style={{ width: '100%' }}>
                            <FlexColumn start style={{ width: '100%', margin: '24px 0 24px 0px' }}>
                                <Flex fullHeight style={{ width: '100%', marginBottom: 24 }}>
                                    <RefreshContainer style={{ width: `${100 / 3}%`, minHeight: 270, marginRight: 20 }}>
                                        <FlexColumn center>
                                            <img width='40px' height='40px' src={Check} />
                                            <Text center size="20px" style={{ marginTop: 24, marginBottom: 10 }}>Simple</Text>
                                            <Text center>Each advance plus the flat financing fee will be repaid over the specified time in equal installments debited from your Aion bank account.</Text>
                                        </FlexColumn>
                                    </RefreshContainer>

                                    <RefreshContainer style={{ width: `${100 / 3}%`, minHeight: 270, marginRight: 20 }}>
                                        <FlexColumn center>
                                            <img width='30px' height='35px' src={Lightning} />
                                            <Text center size="20px" style={{ marginTop: 24, marginBottom: 10 }}>Flexible</Text>
                                            <Text center>Once your advance is paid in full, you are eligible to draw another advance up to the above limit, until your contract period ends.</Text>
                                        </FlexColumn>
                                    </RefreshContainer>

                                    <CardContainer style={{ width: `100%`, minHeight: 270 }}>
                                        <Flex style={{ marginBottom: 24 }} start>
                                            <Text heading>Next Steps</Text>
                                        </Flex>
                                        <Flex style={{ marginBottom: 24 }} start gap='16px'>
                                            <Image src={Bullet1} />
                                            <Text>Provide some more information so we can complete your underwriting</Text>
                                        </Flex>
                                        <Flex style={{ marginBottom: 24 }} start gap='16px'>
                                            <Image src={Bullet2} />
                                            <Text>You sign your agreement documents, update your collateral into our platform</Text>
                                        </Flex>
                                        <Flex start style={{ marginBottom: 10 }} gap='16px'>
                                            <Image src={Bullet3} />
                                            <Text>You enter the amount of funds you would like to draw and start growing</Text>
                                        </Flex>
                                    </CardContainer>
                                </Flex>
                                <FlexColumn center centerHorizontally>
                                    <CardContainer style={{ width: '50%', marginBottom: 24 }}>
                                        <Text>In the early days of Purely Elizabeth, we needed to keep up with increasing demand. Aion provided flexible, non-dilutive funding that grew with our business and helped us scale.</Text>

                                        <Flex between style={{ marginTop: 12, width: '100%' }}>
                                            <Flex start>
                                                <img width='48px' height='48px' src={Profile1} />
                                                <FlexColumn between style={{ height: 48, marginLeft: 12 }}>
                                                    <Text color='#1199FF'>Mark Freeburg</Text>
                                                    <Text size='12px' height='20px' color='#666666'>CFO, Purely Elizabeth</Text>
                                                </FlexColumn>
                                            </Flex>

                                            <img width='48px' height='48px' src={Quote} />
                                        </Flex>
                                    </CardContainer>
                                </FlexColumn>
                            </FlexColumn>
                        </Flex>
                    </FlexColumn>
                }
                {
                    (loanApplication.underwritingStatus != "Proposal Issued" && loanApplication.underwritingStatus != "Proposal Expired" && !proposalPreview) &&
                    <>
                        {
                            (loanApplication.underwritingStatus == "Auto Rejected" || loanApplication.underwritingStatus == "Manually Rejected") &&
                            <Banner color='#E52D2D' background='#FEF5F5' borderColor='#FAD5D5' margin='0 0 24px' message='We regret to inform you that your application for financing could not be approved' />
                        }
                        {
                            (loanApplication.underwritingStatus == "Proposal Declined") &&
                            <Banner margin='0 0 24px' message='We regret to see that you are not interested. Please feel free to send us a chat message if you have any questions.' />
                        }
                        {
                            !proposalPreview && <FlexColumn fullWidth gap='24px'>
                                <Collapse
                                    onChange={() => this.setState({ "isYourInfoActive": !this.state.isYourInfoActive })}
                                    bordered={true}
                                    expandIcon={(obj) => <DownOutlined rotate={obj.isActive ? 180 : 0} end style={{
                                        color: "#1199FF",
                                        fontSize: "20px",
                                        marginTop: "2px"
                                    }} />}
                                    ghost
                                    style={{ boxShadow: "0px 4px 8px rgba(102, 102, 102, 0.08)", padding: "24px", gap: "24px" }}
                                >
                                    <Collapse.Panel header={
                                        <Flex start gap="12px" centerHorizontally style={{ marginLeft: 12 }}>
                                            <Image src={CollapseCheck} />
                                            <Text heading> Your Information</Text>
                                        </Flex>
                                    }>
                                        <CreditUserProfile reviewView />
                                    </Collapse.Panel>
                                </Collapse>
                                <Collapse
                                    onChange={() => this.setState({ "isBusinessProfileActive": !this.state.isBusinessProfileActive })}
                                    bordered={true}
                                    expandIcon={(obj) => <DownOutlined rotate={obj.isActive ? 180 : 0} end style={{
                                        color: "#1199FF",
                                        fontSize: "20px",
                                        marginTop: "2px"
                                    }} />}
                                    ghost
                                    expandIconPosition="end"
                                    style={{ boxShadow: "0px 4px 8px rgba(102, 102, 102, 0.08)", padding: "24px", gap: "24px" }}
                                >
                                    <Collapse.Panel header={
                                        <Flex start gap="12px" centerHorizontally style={{ marginLeft: 12 }}>
                                            <Image src={CollapseCheck} />
                                            <Text heading> Business Profile</Text>
                                        </Flex>
                                    }>
                                        <Flex between style={{ width: "100%", minHeight: '105px' }}>
                                            <div style={{ width: "25%", marginRight: 12 }}>
                                                <LabeledInput
                                                    autoFocus
                                                    labelcolor={theme.colors.secondary3}
                                                    label="Business Name"
                                                    id="businessName"
                                                    type="read-only"
                                                    value={businessInfo.name}
                                                />
                                            </div>
                                            <div style={{ width: "25%", marginRight: 12, marginLeft: 12 }}>
                                                <LabeledInput
                                                    labelcolor={theme.colors.secondary3}
                                                    label="Incorporation Type"
                                                    type="read-only"
                                                    size="large"
                                                    placeholder="Select an option" value={businessInfo.incorporationType}
                                                >
                                                </LabeledInput>
                                            </div>
                                            <div style={{ width: "25%", marginRight: 12, marginLeft: 12 }}>
                                                <LabeledInput
                                                    label="Formation Year"
                                                    type="read-only"
                                                    disabledDate={this.disabledDate}
                                                    id="incorporationYear"
                                                    value={moment(businessInfo.incorporationDate).format('yyyy')}
                                                    placeholder="Pick an Year"
                                                    picker="year"
                                                />
                                            </div>
                                            <div style={{ width: "25%", marginLeft: 12 }}>
                                                <LabeledInput
                                                    labelcolor={theme.colors.secondary3}
                                                    label="Formation State"
                                                    type="read-only"
                                                    size="large"
                                                    id="incorporationState"
                                                    placeholder="Select a state"
                                                    value={businessInfo.incorporationState}
                                                >
                                                </LabeledInput>
                                            </div>
                                        </Flex>
                                        <Flex between style={{ width: "100%", minHeight: '105px' }}>
                                            <div style={{ width: "25%", marginRight: 12, }}>
                                                <LabeledInput
                                                    labelcolor={theme.colors.secondary3}
                                                    label="DBA or Trade Name"
                                                    id="dBA"
                                                    type="read-only"
                                                    value={businessInfo.dba}
                                                    placeholder={businessInfo.name}
                                                />
                                            </div>
                                            <div style={{ width: "25%", marginRight: 12, marginLeft: 12 }}>
                                                <LabeledInput
                                                    labelcolor={theme.colors.secondary3}
                                                    label="States of Operation"
                                                    type="read-only"
                                                    size="large"
                                                    mode="multiple"
                                                    className="no-left-padding"
                                                    dropdownClassName="no-left-padding"
                                                    id="statesOfOperation"
                                                    placeholder="Select all that apply"
                                                    optionFilterProp="children"
                                                    value={(businessInfo.statesOfOperation || []).map((val, i) => {
                                                        return <>{i > 0 ? <>, </> : ''}{val}</>
                                                    })}
                                                    divider={false}
                                                />
                                            </div>
                                            <div style={{ width: "25%", marginRight: 12, marginLeft: 12 }}>
                                                <LabeledInput
                                                    labelcolor={theme.colors.secondary3}
                                                    label="Industry"
                                                    type="read-only"
                                                    size="large"
                                                    id="industry"
                                                    placeholder="Select an industry"
                                                    optionFilterProp="children"
                                                    onChange={value => { this.saveUserInput("industry", value) }}
                                                    value={businessInfo.industry}
                                                    divider={false}
                                                >
                                                </LabeledInput>
                                            </div>
                                            <div style={{ width: "25%", marginLeft: 12 }}>
                                                <LabeledInput
                                                    onKeyDown={this.handleOnKeyDown}
                                                    labelcolor={this.props.theme.colors.secondary3}
                                                    label="Number of Employees"
                                                    id="employeeCount"
                                                    value={businessInfo.employeeCount}
                                                    placeholder="10"
                                                    type="read-only"
                                                />
                                            </div>
                                        </Flex>

                                        <Flex between style={{ width: "100%", minHeight: '105px' }}>
                                            <div style={{ width: "25%", marginRight: 12 }}>
                                                <LabeledInput
                                                    autoFocus
                                                    labelcolor={theme.colors.secondary3}
                                                    label="Business Phone Number"
                                                    id="phone"
                                                    type="read-only"
                                                    value={businessInfo.phone}
                                                    placeholder="Enter phone number"
                                                />
                                            </div>
                                            <div style={{ width: "25%", marginRight: 12, marginLeft: 12 }}>
                                                <LabeledInput
                                                    autoFocus
                                                    labelcolor={theme.colors.secondary3}
                                                    label="Website"
                                                    id="websiteUrl"
                                                    type="read-only"
                                                    value={businessInfo.urls && businessInfo.urls[0]}
                                                    placeholder="Enter website URL"
                                                />
                                            </div>
                                            <div style={{ width: "25%", marginRight: 12, marginLeft: 12 }}>
                                                <LabeledInput
                                                    type="read-only"
                                                    id={"Address".toLowerCase()}
                                                    key={"Address"}
                                                    value={addressObjectToStr(physicalAddress)}
                                                    label="Business Address"
                                                    placeholder={`Enter ${"Address"}`}
                                                    // nextLine={true}
                                                    // country
                                                    divider={false}
                                                    fullWidth
                                                />
                                            </div>
                                            <div style={{ width: "25%", marginLeft: 12 }}>
                                                <LabeledInput
                                                    type="read-only"
                                                    id='mailingAddress'
                                                    key='mailingAddress'
                                                    value={addressObjectToStr(mailingAddress)}
                                                    label="Mailing Address"
                                                    placeholder={`Enter ${"Address"}`}
                                                    // nextLine={true}
                                                    // country
                                                    divider={false}
                                                    fullWidth
                                                />
                                            </div>
                                        </Flex>
                                    </Collapse.Panel>
                                </Collapse>
                                <Collapse
                                    onChange={() => this.setState({ "isOwnershipInfoActive": !this.state.isOwnershipInfoActive })}
                                    bordered={true}
                                    expandIcon={(obj) => <DownOutlined rotate={obj.isActive ? 180 : 0} end style={{
                                        color: "#1199FF",
                                        fontSize: "20px",
                                        marginTop: "2px"
                                    }} />}
                                    ghost
                                    expandIconPosition="end"
                                    style={{ boxShadow: "0px 4px 8px rgba(102, 102, 102, 0.08)", padding: "24px", gap: "24px" }}
                                >
                                    <Collapse.Panel header={
                                        <Flex start gap="12px" centerHorizontally style={{ marginLeft: 12 }}>
                                            <Image src={CollapseCheck} />
                                            <Text heading> Ownership Information</Text>
                                        </Flex>
                                    }>
                                        <Divider />
                                        <FlexColumn style={{ width: "100%", marginBottom: 15 }}>
                                            <Text weight='500' color='#7384AA'>YOUR OWNERSHIP</Text>
                                            <Flex start wrap fullWidth gap='24px'>
                                                <LabeledInput
                                                    type="read-only"
                                                    label="Name"
                                                    value={`${primaryApplicantInfo.firstName} ${primaryApplicantInfo.lastName}`}
                                                    width='288px'
                                                />
                                                <LabeledInput
                                                    type="read-only"
                                                    label="Title"
                                                    value={primaryApplicantInfo.businessTitle}
                                                    width='288px'
                                                />
                                                <LabeledInput
                                                    type="read-only"
                                                    label="Ownership Percentage"
                                                    value={`${primaryApplicantInfo.ownershipPercentage}%`}
                                                    width='288px'
                                                />
                                            </Flex>
                                        </FlexColumn>
                                        <FlexColumn start>
                                            <FlexColumn style={{ width: "100%", marginBottom: 15 }}>
                                                <Text style={{ marginBottom: 8 }} weight='500' color='#7384AA'>OWNERS</Text>
                                                {
                                                    coOwnerApplicantInfo && coOwnerApplicantInfo.length > 0 ?
                                                        <Flex wrap style={{ marginTop: 12 }} start >
                                                            {(coOwnerApplicantInfo || []).map((item, j) => {
                                                                return (
                                                                    <OwnerCard ownerInfos={item} theme={theme} symbolprefix="%" key={j} />
                                                                )
                                                            })}
                                                        </Flex>
                                                        :
                                                        <Text margin='8px 0 0'>None added</Text>
                                                }
                                            </FlexColumn>
                                            <FlexColumn style={{ width: "100%", margin: "15px 0" }}>
                                                <Text style={{ marginBottom: 8 }} weight='500' color='#7384AA'>PARENT COMPANIES & SUBSIDIARIES</Text>
                                                {
                                                    subsidiaries && subsidiaries.length > 0 ?
                                                        <Flex wrap style={{ marginTop: 12 }} start >
                                                            {(subsidiaries || []).map((item, j) => {
                                                                return (
                                                                    <SubsidirayCard subsidirayInfo={item} theme={theme} key={j} />
                                                                );
                                                            })}
                                                        </Flex>
                                                        :
                                                        <Text margin='8px 0 0'>None added</Text>
                                                }
                                            </FlexColumn>
                                        </FlexColumn>
                                    </Collapse.Panel>
                                </Collapse>

                                <Collapse
                                    onChange={() => this.setState({ "isFinancialInfoActive": !this.state.isFinancialInfoActive })}
                                    bordered={true}
                                    expandIcon={(obj) => <DownOutlined rotate={obj.isActive ? 180 : 0} end style={{
                                        color: "#1199FF",
                                        fontSize: "20px",
                                        marginTop: "2px"
                                    }} />}
                                    ghost
                                    expandIconPosition="end"
                                    style={{ boxShadow: "0px 4px 8px rgba(102, 102, 102, 0.08)", padding: "24px", gap: "24px" }}
                                >
                                    <Collapse.Panel header={
                                        <Flex style={{ justifyContent: "space-between" }}>
                                            <Flex start gap="12px" centerHorizontally style={{ marginLeft: 12 }}>
                                                <Image src={CollapseCheck} />
                                                <Text heading> Financial Information</Text>
                                            </Flex>
                                            <Flex end>
                                                {businessAttributes.AccountingSWLinked &&

                                                    <Button noClick noBorder background="#F5F9F5" margin='0 0 0 0' color="#318F2F" padding='0 0 0 0' width='290px' weight='500' height='40px' radius='4px'
                                                        text="Retrieved from Quickbooks Online" icon={<Image src={QB} width='24px' height='24px' />} ></Button>
                                                }
                                            </Flex>
                                        </Flex>
                                    }>

                                        <Divider />
                                        <FinancialInfo loanApplication={loanApplication} readonly flowView={flowView} />

                                    </Collapse.Panel>
                                </Collapse>
                                {
                                    requests.length > 0 ?
                                        <Collapse
                                            onChange={() => this.setState({ "isAdditionalDocsActive": !this.state.isAdditionalDocsActive })}
                                            bordered={true}
                                            expandIcon={(obj) => <DownOutlined rotate={obj.isActive ? 180 : 0} end style={{
                                                color: "#1199FF",
                                                fontSize: "20px",
                                                marginTop: "2px"
                                            }} />}
                                            ghost
                                            expandIconPosition="end"
                                            style={{ boxShadow: "0px 4px 8px rgba(102, 102, 102, 0.08)", padding: "24px", gap: "24px" }}
                                        >
                                            <Collapse.Panel header={
                                                <Flex style={{ justifyContent: "space-between" }}>
                                                    <Flex start gap="12px" centerHorizontally style={{ marginLeft: 12 }}>
                                                        <Image src={CollapseCheck} />
                                                        <Text heading> Additional documents submitted</Text>
                                                    </Flex>
                                                </Flex>
                                            }>

                                                <Divider />
                                                <AdditionalDocumentSubmitted loanApplication={loanApplication}></AdditionalDocumentSubmitted>
                                            </Collapse.Panel>
                                        </Collapse>

                                        : null
                                }

                            </FlexColumn>
                        }
                    </>
                }

                <Modal
                    visible={showDeclineProposalModal}
                    footer={null}
                    closable={true}
                    width={600}
                    destroyOnClose={true}
                    onCancel={() => {
                        this.setState({ showDeclineProposalModal: false })
                    }}
                    closeIcon={<Image src={ModalClose} />}
                >
                    <DeclineProposal loanApp={loanApplication} handleConfirmDecline={this.handleConfirmDecline} handleCloseDeclineProposal={this.handleCloseDeclineProposal}></DeclineProposal>
                </Modal>

                <Modal
                    visible={showNextStepModal}
                    footer={null}
                    closable={true}
                    width={600}
                    destroyOnClose={true}
                    onCancel={() => {
                        this.setState({ showNextStepModal: false })
                    }}
                    closeIcon={<Image src={ModalClose} />}
                >
                    <FlexColumn style={{ margin: '0 10px', minHeight: "25vh", justifyContent: "space-between" }}>
                        <FlexColumn style={{ justifyContent: "flex-start" }} gap='24px'>
                            <Text heading>Things to know</Text>
                            <Flex start gap='12px'>
                                <Image src={Bullet1} />
                                <Text>Your line would be secured by a first-priority lien on all company assets.</Text>
                            </Flex>
                            <Flex start gap='12px'>
                                <Image src={Bullet2} />
                                <Text>You will need to subscribe to one of our paid plans to utilize the platform to draw funds. Aion provides a streamlined banking, invoicing, bill payments, and cashflow management solution for all your business finance needs. Subscription plans begin as low as $29/month.</Text>
                            </Flex>
                            <Flex start gap='12px'>
                                <Image src={Bullet3} />
                                <Text>Available credit based will be based on up to 80% of your eligible accounts receivable.</Text>
                            </Flex>
                            <Flex start gap='12px' style={{ marginBottom: 12 }}>
                                <Image src={Bullet4} />
                                <Text>Contract periods are typically one calendar year.</Text>
                            </Flex>
                        </FlexColumn>
                    </FlexColumn>
                </Modal>
            </FlexColumn >
        );
    }
}

function mapStateToProps(state) {
    return {
        onboardingStore: state.onboardingAppReducer,
        store: state.aionAppReducer,
        bankingStore: state.bankingAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(CustomerProposal)))