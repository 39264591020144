import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled, { withTheme } from 'styled-components'
import moment from 'moment'
import { Modal, Select, Space} from 'antd'
import { random } from 'underscore'
import Vouched from '../Vouched/Index'
import { InfoCircleOutlined } from '@ant-design/icons';

import { CardContainer, Flex, FlexColumn } from '../../Reusable/Container'
import FlowViewComponent from '../../Reusable/Refresh/FlowViewComponent'
import { countryCodeMapUSFirst, countryCodeToNameMap, formatPhoneTextV2, formatSSN, getAddressObj, getTotalOwnershipPercentage, isEmailValid, STATES_ABBR_MAP, STATES_MAP, toCurrency, formatOwnershipPercentage } from '../../../Utils/util'
import { LabeledInput, StyledInput } from '../../Reusable/Input'
import { Paragraph, Text } from '../../Reusable/Refresh/Text'
import NaicsField from '../../Reusable/Refresh/NaicsField'
import { addDataToStore, REMOTE_SAVE_DATA, RESET_CO_OWNER_CACHE, SAVE_CO_OWNER, SAVE_CO_OWNER_CACHE, DELETE_CO_OWNER, SAVE_SIGNER, DELETE_SIGNER } from '../../../Actions/actions'

import CancelModal from '../../Reusable/CancelModal'
import Banner from '../../Reusable/Banner'
import Divider from '../../Reusable/Refresh/Divider'
import { ErrorAlert } from '../../Reusable/Alert'
import { Button, Chip, TextButton } from '../../Reusable/Button'
import ModalClose from '../../../Images/modal-close.png'
import { apiPOSTReq } from '../../../Utils/api'
import environment from '../../../environment'
import DeleteIcon from '../../../Images/delete-red-icon.png'

import { Image } from '../../Reusable/Image'
import Percentage from '../../../Images/Percentage.png'

const { Option } = Select
export const dateFormatList = ['MM/DD/YYYY', 'MM/DD/YY', 'MM-DD-YYYY', 'MM-DD-YY']

class OwnerProfile extends Component {

    state = {
        submitLoading: false,
        ownerType: this.props.ownerType, // coOwner, controlPerson, signer,
        ownerInfo: this.props.selectedOwner || this.props.onboardingStore.ownerInfoCache,
        flowStep: this.props.flowStep,
        phoneCode: (this.props.onboardingStore.ownerInfoCache || {}).phoneCode ? this.props.onboardingStore.ownerInfoCache.phoneCode : {
            "name": "United States",
            "flag": "🇺🇸",
            "code": "US",
            "dial_code": "+1"
        }
    }

    componentDidMount() {
        this.fetchIdentification()
        var { ownerInfo, ownerType } = this.state
        if(ownerType == 'controlPerson') {
            this.setState({ ownerInfo: { ...ownerInfo, controlPerson: true, authorizedSigner: true, ownershipPercentage: 0 } })
        }
        if(ownerType == 'signer') {
            this.setState({ ownerInfo: { ...ownerInfo, authorizedSigner: true, ownershipPercentage: 0 } })
        }
    }

    saveUserInput = (id, value) => {
        var dataToSave = {}
        var { citizenOrResident, countryOfCitizenship } = this.state.ownerInfo
        const foreignUser = (countryOfCitizenship != 'US') && (citizenOrResident == false)
        switch(id) {
            case "phone":
                if(!foreignUser) {
                    value = value.replace(/[^\d]/g, '')
                } else if(!this.props.selectedOwner) {
                    value = value.replace(/[^0-9A-Z]+/gi, '')
                } else {
                    value = `+${value.replace(/[^0-9A-Z]+/gi, '')}`
                }
                dataToSave = { [id]: value }
                break
            case "ssn":
                if(!foreignUser) {
                    value = value.replace(/[^\d]/g, '')
                }
                dataToSave = { [id]: value }
                break
            case "idNumber":
                var identification = this.state.ownerInfo.identification || {}
                identification[id] = value
                dataToSave = { identification: identification }
                break
            case "expDate":
                var identification = this.state.ownerInfo.identification || {}
                identification[id] = value
                dataToSave = { identification: identification }
                break
            case "firstName":
            case "lastName":
                var identification = this.state.ownerInfo.identification || {}
                identification[id] = value
                dataToSave = { identification: identification, [id]: value }
                break
            case "vouched":
                var vouchedData = value
                var { countryOfCitizenship } = this.state.ownerInfo
                var identification = this.state.ownerInfo.identification || {}
                var { result } = vouchedData || {}
                var idType = result.type
                switch (result.type) {
                    case "drivers-license":
                        idType = "DriversLicense"
                        break
                    case "passport":
                        idType = "Passport"
                        break
                    default:
                        idType = "DriversLicense" // "Other"
                        break;
                }
                console.log("saveUserInput vouched", countryOfCitizenship, idType, value)
                if (countryOfCitizenship !== 'US' && (idType.toLowerCase() !== "passport")) {
                    // Non-citizen user has to upload a passport
                    ErrorAlert({ description: "We only accept passport for non-US citizens. Please upload a passport" })
                    this.setState({ reloadIDV: true })
                    return
                }
                
                var identification = {
                    "isPrimary": true,
                    "firstName": result.firstName,
                    "lastName": result.lastName,
                    "type": idType,
                    "idNumber": result.id,
                    "issuedDate": result.issueDate ? moment.utc(result.issueDate, "MM/DD/YYYY").format("YYYY-MM-DD"): null,
                    "expDate": result.expireDate ? moment.utc(result.expireDate, "MM/DD/YYYY").format("YYYY-MM-DD") : null,
                    "issuingAuthority": foreignUser ? (result.country || "Passport Authority") : (result.state || "DMV"),
                    "issuingStateOrProvince": result.state,
                    "issuingCountryCode": (result.country || '').length == 2 ? result.country : (result.country || countryOfCitizenship).substring(0, 2)
                }
                const idAddress = result.idAddress || {}
                var userInfo = {
                    "firstName": result.firstName,
                    "lastName": result.lastName,
                    "dob": result.birthDate ? moment.utc(result.birthDate, "MM/DD/YYYY").startOf('day').toDate() : null
                }
                var addrCC = idAddress.country || ''
                if(addrCC.length > 2) addrCC = addrCC.substring(0, 2)

                if(result.idAddress) {
                    userInfo.address = {
                        "line1": idAddress.streetNumber ? `${idAddress.streetNumber} ` : "" + (idAddress.street || ""),
                        "city": idAddress.city,
                        "countrySubDivisionCode": ((idAddress.state || "").length == 2) ? idAddress.state : STATES_ABBR_MAP[idAddress.state],
                        "postalCode": idAddress.postalCode,
                        "country": countryCodeToNameMap[addrCC],
                        "countryCode": addrCC
                    }
                } else if((result.unverifiedIdAddress || []).length > 0) {
                    userInfo.address = {
                        "line1": result.unverifiedIdAddress[0],
                        "city": null,
                        "countrySubDivisionCode": null,
                        "postalCode": null,
                        "country": null,
                        "countryCode": null
                    }
                }
                console.log("vouched saving data", result, userInfo, identification)
                dataToSave = { vouchedJobId: vouchedData.id, vouchedData: vouchedData, identification: identification, ...userInfo }
                this.saveIdentification({ ownerInfo: { ...this.state.ownerInfo, ...dataToSave } })
                break
            default:
                dataToSave = { [id]: value }
                break
        }
        this.props.dispatch(addDataToStore(SAVE_CO_OWNER_CACHE, { ...this.state.ownerInfo, ...dataToSave, phoneCode: this.state.phoneCode }))
        // Save new data
        // console.log("data to save", id, { ...this.state.ownerInfo, ...dataToSave})
        this.setState({ ownerInfo: { ...this.state.ownerInfo, ...dataToSave} })

    }

    handleCodeChange = (code) => {
        this.setState({ phoneCode: code })
    }

    validateFlow = () => {
        var { citizenOrResident, countryOfCitizenship, vouchedJobId, identification, firstName, lastName, dob, address, phone, ssn, email, businessTitle, ownershipPercentage } = this.state.ownerInfo
        const { idNumber, expDate } = identification || {}
        const foreignUser = (countryOfCitizenship != 'US') && (citizenOrResident == false)

        console.log('validateAddOwnerFlow')
        console.log('validateAddOwnerFlow', citizenOrResident, countryOfCitizenship, ssn, dob)

        if(typeof(citizenOrResident) !== 'boolean') {
            this.setErrorField("citizenOrResident", "Select an option")
            return false;
        }
        if(!countryOfCitizenship){
            this.setErrorField("countryOfCitizenship", "Select your country of citizenship")
            return false;
        }
        if(!vouchedJobId) {
            ErrorAlert({description : "Please upload your government identification"})
            return false;
        }
        if(!firstName) {
            this.setErrorField("firstName", "Enter your first name")
            return false;
        }
        if(!lastName) {
            this.setErrorField("lastName", "Enter your last name")
            return false;
        }
        // if (!phone || (phone.replace(/[^\d]/g, '').length !== 11)) {
        //     this.setErrorField("phone", "Please enter a valid 10 digit mobile number")
        //     return;
        // }
        if(!phone) {
            this.setErrorField("phone", "Please enter a valid phone")
            return false;
        }
        if(!email || !isEmailValid(email)) {
            this.setErrorField("email", "Enter a valid email")
            return false;
        }
        if(!dob) {
            this.setErrorField("dob", "Select a date")
            return false;
        }
        if(foreignUser && (!ssn || (ssn.length < 9) || (ssn.length > 20))) {
            this.setErrorField("ssn", "Enter a valid Tax ID between 9 and 20 characters")
            return false;
        } else if(!foreignUser && (!ssn || ((ssn || "").replace(/[^\d]/g, '').length !== 9))) {
            this.setErrorField("ssn", "Enter a valid 9 digit SSN")
            return false;
        }
        if(!idNumber) {
            this.setErrorField("idNumber", "ID number is required")
            return false;
        }
        if(!expDate || !moment(expDate).isAfter(moment())) {
            this.setErrorField("expDate", "Their ID may have expired or been incorrectly filled. Update this field or reupload a valid document.")
            return false;
        }
        if(!address || !address.line1 || address.line1.includes("undefined") || !address.city || (!address.countrySubDivisionCode && !address.country) || !address.postalCode) {
            this.setErrorField("address", "Please enter a valid address")
            return false;
        }
        if(!businessTitle) {
            this.setErrorField("businessTitle", "Please select an option")
            return false;
        }
        if(this.state.ownerType == "coOwner") {
            if(!ownershipPercentage || ownershipPercentage < 25) {
                this.setErrorField("ownershipPercentage", "Needs to be 25% or more")
                return false;
            }
            if(this.props.selectedOwner) {
                var totalOwnershipPercent = getTotalOwnershipPercentage() - Number(this.props.selectedOwner.ownershipPercentage) // Because getTotalOwnershipPercentage already includes the selectedOwners percentage
                if((totalOwnershipPercent + Number(ownershipPercentage)) > 100) {
                    this.setErrorField("ownershipPercentage", "Total ownership cannot exceed 100%")
                    return false;
                }
            } else if((getTotalOwnershipPercentage() + Number(ownershipPercentage)) > 100) {
                this.setErrorField("ownershipPercentage", "Total ownership cannot exceed 100%")
                return false;
            }
        }
        // Update phone with dial code
        var ownerInfo = this.state.ownerInfo
        if(!this.props.selectedOwner) {
            ownerInfo["phone"] = `${this.state.phoneCode.dial_code}${phone}`
        } else if(!phone.includes("+")) {
            ownerInfo["phone"] = `+${phone}`
        }
        // Save to the DB
        if(this.state.ownerType == "coOwner" || this.state.ownerType == "controlPerson") {
            this.props.dispatch(addDataToStore(SAVE_CO_OWNER, { ownerInfo: ownerInfo }))
        } else if(this.state.ownerType == "signer") {
            this.props.dispatch(addDataToStore(SAVE_SIGNER, { ownerInfo: ownerInfo }))
        }
        this.props.dispatch(addDataToStore(REMOTE_SAVE_DATA, this.state.flowStep))
        // save any updated ID information
        this.saveIdentification({ ownerInfo : ownerInfo, resetCache: true })
        this.setState({ ownerInfo: ownerInfo });
        this.setErrorField({"": ""});
        return true;
    }

    validateExpiryDate = () => {
        var { identification } = this.state.ownerInfo
        const { expDate } = identification || {}

        if(!expDate || !moment(expDate).isAfter(moment())) {
            this.setErrorField("expDate", "Their ID may have expired or been incorrectly filled. Update this field or reupload a valid document.")
            return false;
        } else {
            this.setErrorField({"": ""});
        }
    }
    
    onSubmit = (ownerInfo) => {
        var isValid = this.validateFlow();
        if(isValid) {
            this.props.onSubmit({ ownerInfo: ownerInfo })
        }
    }

    handleOnChange = (event) => {
        const target = event.target
        if ((target.id || target.name) == 'ownershipPercentage') {
            if (!isNaN(target.value)) {
                if((target.value > 100)) {
                    this.saveUserInput(target.id || target.name, target.value.slice(0,2));
                }else {
                    this.saveUserInput(target.id || target.name, formatOwnershipPercentage(target.value >= 0 ? target.value : ''));
                    this.setState({ errorField: null, errorMessage: null });
                }
                        }
            else {
                this.setState({ errorField: 'ownershipPercentage', errorMessage: 'Please enter a valid ownership percentage' });
                this.saveUserInput(target.id || target.name, target.value);
            }
        } else {
            this.saveUserInput(target.id || target.name, target.value)
        }
    }

    setErrorField = (id, msg) => this.setState({ errorField: id, errorMessage: msg })

    fetchIdentification = () => {
        var ownerInfo = this.state.ownerInfo
        const { identificationId } = ownerInfo
        // console.log("/identifications/getActiveDocument resp", identificationId)
        if(!identificationId) return
        var body = {
            "id": identificationId
        }
        
        apiPOSTReq(`${environment.uamBaseUrl}/identifications/getActiveDocument`, {}, body, (err, resp) => {
            this.setState({ loading: false })
            try {
                const data = resp || {}
                console.log("/identifications/getActiveDocument resp", resp)
                if (data.result) {
                    var identification = data.identification
                    ownerInfo["identification"] = identification
                    this.setState({ ownerInfo })
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp)
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing ID document" })
            }
        })
    }

    saveIdentification = (options) => {
        var { ownerInfo, resetCache } = options 
        var idObj = ownerInfo.identification
        var body = {
            "identification": ownerInfo.identification
        }
        if(ownerInfo.identificationId) {
            idObj["id"] = ownerInfo.identificationId
        }
        
        var body = {
            "identification": idObj
        }
        // console.log("/identifications/save body", body, ownerInfo)
        this.setState({ submitLoading: true })
        apiPOSTReq(`${environment.uamBaseUrl}/identifications/save`, {}, body, (err, resp) => {
            this.setState({ submitLoading: false })
            try {
                // console.log("/identifications/save resp", resp)
                const data = resp || {}
                if (data.result && data.identification) {
                    ownerInfo["identificationId"] = data.identification.id
                    this.props.dispatch(addDataToStore(SAVE_CO_OWNER_CACHE, ownerInfo))
                    this.props.dispatch(addDataToStore(REMOTE_SAVE_DATA, this.state.flowStep))
                    this.setState({ ownerInfo: ownerInfo })
                    console.log("identification saved", ownerInfo)
                } else {
                    throw Error(data.responseMessage || data.error)
                }
                if(resetCache) {
                    // reset cache
                    this.props.dispatch(addDataToStore(RESET_CO_OWNER_CACHE, {}))
                }
            } catch (error) {
                console.log("/identifications/save err", error, resp)
                // ErrorAlert({ description: error.message || "Sorry, we had trouble saving your ID. Please try again" })
            }
        })
    }

    deleteOwner() {
        if(this.state.ownerType == "coOwner" || this.state.ownerType == "controlPerson") {
            this.props.dispatch(addDataToStore(DELETE_CO_OWNER, { ownerInfo: this.state.ownerInfo, ownerType: this.state.ownerType }))
            // reset cache
            this.props.dispatch(addDataToStore(RESET_CO_OWNER_CACHE, {}))
        } else if(this.state.ownerType == "signer") {
            this.props.dispatch(addDataToStore(DELETE_SIGNER, { ownerInfo: this.state.ownerInfo }))
            // reset cache
            this.props.dispatch(addDataToStore(RESET_CO_OWNER_CACHE, {}))
        }
        this.props.dispatch(addDataToStore(REMOTE_SAVE_DATA, this.state.flowStep))
        this.props.onSubmit()
    }

    getLocation(location, key) {
        let addrObj = getAddressObj(location)
        addrObj.city = addrObj.city ? addrObj.city.substring(0, 18) : ''
        this.saveUserInput(key, addrObj)
    }

    disabledDOBDate = (current) => {
        // Can not select days after today and today and weekends
        return current > moment().subtract('18', "years")
    }

    handleDateChange = (id, dateStr) => {
        if (dateStr) {
            if(id == "dob") {
                this.saveUserInput(id, moment.utc(dateStr).startOf('day').toDate())
            } else {
                this.saveUserInput(id, moment.utc(dateStr).startOf('day').format("YYYY-MM-DD"))
            }
        } else {
            this.saveUserInput(id, null)
        }
        this.validateExpiryDate();
    }

    getLocation = (location, formattedAddress) => {
        let addrObj = getAddressObj(location)
        addrObj.city = addrObj.city ? addrObj.city.substring(0, 18) : ''
        this.saveUserInput("address", addrObj)
    }

    render() {
        var { flowView, currentViewIndex, loadPrevView, flowSteps, onboardingStore, aionStore, theme, ownerType, hidePII } = this.props
        var { errorField, errorMessage, showVouched, showDelete, ownerInfo, submitLoading, phoneCode, isWeb, isMobile } =  this.state
        var { citizenOrResident, countryOfCitizenship, vouchedJobId, identification, firstName, lastName, dob, address, phone, ssn, email, businessTitle, ownershipPercentage } = ownerInfo
        flowSteps = flowSteps || []
        const { idNumber, type, expDate, issuingStateOrProvince, issuingCountryCode } = identification || {}
        const businessTitles = onboardingStore.businessTitles || [ "CEO", "CFO", "COO", "Other" ]
        const foreignUser = (countryOfCitizenship != 'US') && (citizenOrResident == false)
        const countryItems = []
        Object.values(countryCodeMapUSFirst).forEach(item => {
            countryItems.push(
                <Option key={item.code} value={item.code}>
                    {item.name}
                </Option>
            )
        })
        if(typeof(vouchedJobId) == 'undefined') vouchedJobId = null
        var idvKey = 'Ownership'
        if (this.state.reloadIDV) idvKey = idvKey + random(500)
        var title = `${this.props.selectedOwner ? 'Edit' : 'New'} Beneficial Owner`
        var desc = this.props.selectedOwner ? "" : "Please add an individual that owns 25% or more of your business."
        if(ownerType == 'controlPerson') {
            title = `${this.props.selectedOwner ? 'Edit' : 'New'} Controlling Party`
            desc = this.props.selectedOwner ? "" : "Please add an individual who is a controlling party in your business."
        }
        if(ownerType == 'signer') {
            title = `${this.props.selectedOwner ? 'Edit' : 'New'} Authorized Signer`
            desc = this.props.selectedOwner ? "" : "Please add an individual who is an authorized signer of your business."
        }

        var childViews = (
            <FlexColumn between left fullHeight style={{ marginTop: "0px", minHeight: '600px' }} gap='24px'>
                <FlexColumn fullWidth>
                    <Text size="20px" weight={500} height="40px">{title}</Text>
                    <Text style={{ maxWidth: '625px' }}>{desc}</Text>
                    <FlexColumn start style={{ width: "100%", marginTop: '24px' }} gap='24px'>
                        {
                            !vouchedJobId &&
                            <Flex gap="24px">
                                <LabeledInput
                                    margin='0px'
                                    label='Are they a resident of the U.S.?'
                                    type='switch'
                                    switchNames={['Yes', 'No']}
                                    onChange={(value) => this.saveUserInput("citizenOrResident", (value == 'Yes'))}
                                    value={(typeof(citizenOrResident) == 'boolean') ? (citizenOrResident ? 'Yes' : 'No') : null}
                                    error={errorField=="citizenOrResident"}
                                    errorMessage={errorMessage}
                                />
                                <LabeledInput
                                    margin='0px'
                                    labelcolor={this.props.theme.colors.secondary3}
                                    label="Which country are they a citizen of?"
                                    type="select"
                                    key='Citizenship'
                                    id="Citizenship"
                                    placeholder="Select country of citizenship"
                                    optionFilterProp="children"
                                    onChange={value => { this.saveUserInput("countryOfCitizenship", value) }}
                                    value={countryOfCitizenship}
                                    error={errorField=="countryOfCitizenship"}
                                    errorMessage={errorMessage}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().startsWith(input.toLowerCase())
                                    }
                                >
                                    {countryItems}
                                </LabeledInput>
                            </Flex>
                        }
                        
                        {
                            (countryOfCitizenship && (citizenOrResident || (countryOfCitizenship == 'US') || onboardingStore.allowNRFlow) && (typeof(citizenOrResident) == 'boolean')) &&
                            <FlexColumn gap='24px'>
                                {
                                    !vouchedJobId ? 
                                    <CardContainer style={{ position: 'relative', overflow: 'hidden', boxShadow: '0px 0px 32px rgba(102, 102, 102, 0.16)' }}>
                                        <div className='vector-8' />
                                        <div className='vector-7' />
                                        <div className='vector-6' />
                                        <FlexColumn left>
                                            <Text size='20px' height='40px' weight='500' spacing='0.04em'>Verify their government identification</Text>
                                            <Text>Please be ready with a copy of their government-issued identification</Text>
                                            <Text uppercase color={theme.colors.caption} style={{ marginTop: '24px' }}  spacing='0.08em'>Accepted Documents</Text>
                                            <ul style={{ paddingLeft: 20 }}>
                                                {
                                                    countryOfCitizenship == 'US' ? 
                                                    <>
                                                        <li>Driver's License</li>
                                                        <li>Passport</li>
                                                    </>
                                                    :
                                                    <li>Passport</li>
                                                }
                                            </ul>
                                            <Space size="large">
                                            <Button solid text='USE WEBCAM' style={{ marginTop: '16px' }} onClick={() => this.setState({ showVouched: true, isWeb: true, isMobile: false })} />
                                            <Button  text='USE PHONE' style={{ marginTop: '16px' }} onClick={() => this.setState({ showVouched: true, isWeb: false, isMobile: true })} rightIcon={ <InfoCircleOutlined style={{ marginLeft: 4, color: 'rgba(0,0,0,.45)' }} />}/>
                                             
                                            </Space>                                        
                                            </FlexColumn>
                                    </CardContainer>
                                :
                                    <FlexColumn>
                                        {
                                            (hidePII != true) &&
                                            <FlexColumn style={{ marginTop: "0px" }}>
                                                <Text size="20px" weight={500} height="40px">{type == "DriversLicense" ? "Drivers License" : (type == "Passport" ? "Passport" : "ID Document")}</Text>
                                                <Flex start >
                                                <Text spacing="0.04em" size="14px" height="24px">{type == "DriversLicense" ? `Issued in ${STATES_MAP[issuingStateOrProvince] || issuingStateOrProvince} • Expires on ${moment(expDate).format('ll')}` : `Issued in ${countryCodeToNameMap[issuingCountryCode] || issuingCountryCode} • Expires on ${moment(expDate).format('ll')}`} •</Text>
                                                    <TextButton size='14px' height='24px' padding='0px 5px' text='REUPLOAD' onClick={() => {this.setState({ showVouched: true });}} />
                                                </Flex>
                                                <Banner
                                                    style={{ marginTop: "24px" }}
                                                    message="We’ve autofilled the information from your document. You can edit the below details as required."
                                                />
                                                <Divider />
                                            </FlexColumn>
                                        }
                                        <FlexColumn style={{ width: "100%" }}>
                                            <Flex gap="24px">
                                                <LabeledInput
                                                    labelcolor={this.props.theme.colors.secondary3}
                                                    label="First Name"
                                                    key="firstName"
                                                    id="firstName"
                                                    onChange={this.handleOnChange}
                                                    value={firstName}
                                                    placeholder="Robert"
                                                    error={errorField=="firstName"}
                                                    errorMessage={errorMessage}
                                                />
                                                <LabeledInput
                                                    labelcolor={this.props.theme.colors.secondary3}
                                                    label="Last Name"
                                                    key="lastName"
                                                    id="lastName"
                                                    onChange={this.handleOnChange}
                                                    value={lastName}
                                                    placeholder="Glass"
                                                    error={errorField=="lastName"}
                                                    errorMessage={errorMessage}
                                                />
                                            </Flex>
                                            <Flex gap="24px">
                                                <LabeledInput
                                                    label="Mobile Number"
                                                    key="phone"
                                                    id="phone"
                                                    type={this.props.selectedOwner ? "text" : "phone-cc"}
                                                    phoneCode={phoneCode}
                                                    value={phone && (foreignUser ? phone : formatPhoneTextV2(phone))}
                                                    placeholder={foreignUser ? "Enter phone" : "(000) 000 0000"}
                                                    onCodeChange={(code) => this.handleCodeChange(code)}
                                                    onChange={this.handleOnChange}
                                                    error={errorField=="phone"}
                                                    errorMessage={errorMessage}
                                                />
                                                <LabeledInput
                                                    label="Business Email"
                                                    type="email"
                                                    key="email"
                                                    id="email"
                                                    onChange={this.handleOnChange}
                                                    value={email}
                                                    placeholder="tim@mail.com"
                                                    // instruction="This will be their Sign In ID"
                                                    error={errorField=="email"}
                                                    errorMessage={errorMessage}
                                                />
                                            </Flex>
                                            <LabeledInput
                                                labelcolor={this.props.theme.colors.secondary3}
                                                label="Home Address"
                                                type="location"
                                                key={"Address"}
                                                id="address"
                                                getLocation={this.getLocation}
                                                value={address}
                                                placeholder="Enter address"
                                                country
                                                error={errorField=="address"}
                                                errorMessage={errorMessage}
                                            />
                                            {
                                                (hidePII == true) &&
                                                <Banner message={`Personal Identifiable Information has been hidden for this ${ownerType == 'controlPerson' ? 'controlling party' : 'owner'}`} />
                                            }
                                            {
                                                (hidePII != true) &&
                                                <Flex gap="24px">
                                                    <LabeledInput
                                                        labelcolor={this.props.theme.colors.secondary3}
                                                        label={type == "DriversLicense" ? "License Number" : (type == "Passport" ? "Passport Number" : "ID Document Number")}
                                                        id="idNumber"    
                                                        value={idNumber}
                                                        placeholder="••••••••••"
                                                        onChange={this.handleOnChange}
                                                        error={errorField=="idNumber"}
                                                        errorMessage={errorMessage}
                                                        style={{ height: "30px" }}
                                                    />
                                                    <LabeledInput
                                                        labelcolor={this.props.theme.colors.secondary3}
                                                        label={type == "DriversLicense" ? "License Expiry" : (type == "Passport" ? "Passport Expiry" : "ID Document Expiry")}
                                                        id="expDate"
                                                        type="date-picker"
                                                        placeholder="Select"
                                                        onChange={(date, dateStr) => this.handleDateChange("expDate", dateStr)}
                                                        style={{ height: "30px" }}
                                                        format={dateFormatList}
                                                        value={expDate && moment.utc(expDate)}
                                                        error={errorField=="expDate"}
                                                        errorMessage={errorMessage}
                                                    />
                                                </Flex>
                                            }
                                            {
                                                (hidePII != true) &&
                                                <Flex gap="24px">
                                                    <LabeledInput
                                                        labelcolor={this.props.theme.colors.secondary3}
                                                        label="Date of Birth"
                                                        type="date-picker"
                                                        defaultPickerValue={moment().subtract(18, "years")}
                                                        disabledDate={this.disabledDOBDate}
                                                        key={"DOB"}
                                                        id="dob"
                                                        onChange={(date, dateStr) => this.handleDateChange("dob", dateStr)}
                                                        format={dateFormatList}
                                                        value={dob && moment.utc(dob)}
                                                        placeholder="Select"
                                                        error={errorField=="dob"}
                                                        errorMessage={errorMessage}
                                                        style={{ height: "30px" }}
                                                    />
                                                    <LabeledInput
                                                        autoFocus
                                                        onKeyDown={this.handleOnKeyDown}
                                                        labelcolor={this.props.theme.colors.secondary3}
                                                        label={foreignUser ? `Personal Tax ID Number` : "Social Security Number"}
                                                        id="ssn"
                                                        type="mask"
                                                        placeholder="123-44-5550"
                                                        onChange={this.handleOnChange}
                                                        value={ssn && (foreignUser ? ssn : formatSSN(ssn))}
                                                        error={errorField=="ssn"}
                                                        errorMessage={errorMessage}
                                                        maxLength={foreignUser ? 36 : 11}
                                                    />
                                                </Flex>
                                            }
                                            <Flex gap="24px">
                                                <LabeledInput
                                                    label="Title"
                                                    type="select"
                                                    key="businessTitle"
                                                    placeholder="Select an option"
                                                    onChange={value => { this.saveUserInput("businessTitle", value) }}
                                                    value={businessTitle}
                                                    id="incorpTypeDoc"
                                                    error={errorField=="businessTitle"}
                                                    errorMessage={errorMessage}
                                                >
                                                    {
                                                        businessTitles.map(item => {
                                                            return (
                                                                <Select.Option key={item} value={item}>
                                                                    {item}
                                                                </Select.Option>
                                                            )
                                                        })
                                                    }
                                                </LabeledInput>
                                                {
                                                    (ownerType == 'coOwner') &&
                                                    <LabeledInput
                                                        label="Ownership Percentage"
                                                        key=""
                                                        id="ownershipPercentage"
                                                        symbolsuffix="%"
                                                        value={formatOwnershipPercentage(ownershipPercentage ? ownershipPercentage : '')}
                                                        maxLength={6}
                                                        placeholder="Enter Percentage"
                                                        onChange={this.handleOnChange}
                                                        error={errorField=="ownershipPercentage"}
                                                        errorMessage={errorMessage}
                                                        suffixFontSzie="20px"
                                                    />
                                                }
                                            </Flex>
                                        </FlexColumn>
                                    </FlexColumn>
                                }
                            </FlexColumn>
                        }
                        {
                            (citizenOrResident == false) && (countryOfCitizenship != 'US') && !onboardingStore.allowNRFlow && 
                            <Banner style={{ marginTop: "24px", maxWidth: "700px" }} message="We currently do not support non US resident, non US citizen applicants" />
                        }
                    </FlexColumn>
                </FlexColumn>
                <Flex fullWidth centerHorizontally between>
                    <Button solid text='CONFIRM' onClick={() => this.onSubmit(ownerInfo)} loading={submitLoading} />
                    {this.props.selectedOwner && <img width='24px' height='24px' style={{ cursor: 'pointer' }} src={DeleteIcon} onClick={() => this.setState({ showDelete: true })} />}
                </Flex>
                
            </FlexColumn>
        )
        return (
            <>
                {childViews}
                <Modal
                    visible={showVouched}
                    footer={null}
                    closable={true}
                    width={700}
                    destroyOnClose={true}
                    onCancel={() => {
                        this.setState({ showVouched: false })
                    }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                    style={{ minWidth: '500px', minHeight: '500px', top: 20 }}
                >
                    <Vouched
                        key={idvKey}
                        isWeb={isWeb}
                        isMobile={isMobile}
                        onDone={(job) => {
                            this.saveUserInput("vouched", job)
                            console.log("PersonalInfo.IDV onDone", job)
                            this.setState({ showVouched: false });
                            this.validateExpiryDate();
                        }}
                        idType='id' // idv: document + selfie, id: document only
                    />
                </Modal>
                <CancelModal
                    visible={showDelete}
                    title={`Delete ${ownerInfo.firstName} ${ownerInfo.lastName}?`}
                    description='Are you sure you want to delete this person?'
                    buttonTitle='YES, DELETE'
                    onConfirm={() => this.deleteOwner()}
                    onCancel={() => this.setState({ showDelete: false })} 
                />
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        onboardingStore: state.onboardingAppReducer,
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(OwnerProfile))