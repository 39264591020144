import React from 'react'

import { Flex } from './Container'
import { Text } from './Text'

const Info = (props) => {
    return (
        <Flex start fullWidth style={{ margin: props.noMargin ? '0' : '0 0 16px' }}>
            <Text width='50%' size='14px' lightText>{props.label}</Text>
            <Text width='50%'>{props.value}</Text>
        </Flex>
    )
}

export default Info