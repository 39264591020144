import React from 'react';
import styled, { withTheme } from 'styled-components';

import { containerDimensions } from '../../Styles/theme';

const AionLogo = (props) => {
    return(
        <img {...props} src={props.symbol ? props.theme.logo.symbol : props.theme.logo.full} style={{height: props.logoHeight || containerDimensions.authPage.logoHeight, margin: props.margin || '20px'}} alt="Aion Logo" />
    );
}

export const Image = (props) => {
    return <img height={props.height || '24px'} width={props.width || '24px'} src={props.src} style={{ margin: props.margin, userSelect: 'none', WebkitUserSelect: 'none' }} {...props} />
}

export default withTheme(AionLogo);