import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'

// Components
import { Text, Tag } from '../../../Reusable/Text'
import { Flex, FlexColumn, CardContainer } from '../../../Reusable/Container'
import styled from 'styled-components'
import { Descriptions, Skeleton, Space, Modal, Tooltip } from 'antd'
import { Button, TextButton } from '../../../Reusable/Button'
import { Image } from '../../../Reusable/Image'
import { DownOutlined, UpOutlined, CheckCircleFilled, ExclamationCircleFilled, CloseCircleFilled } from '@ant-design/icons'

import environment from '../../../../environment'
import { ErrorAlert } from '../../../Reusable/Alert'
import { apiPOSTReq } from '../../../../Utils/api'
import Info from '../../../../Images/info.png'

import PreScreenReview from './PreScreenReview'

export const DescItem = styled.div`
    display: flex;
    justify-content: flex-end;
`

export const DescLabel = styled.span`
    fontWeight: 500;
    fontSize: 16;
`

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
})

class Index extends Component {

    state = {
        prescreenResultsLoaded: true,
        documentValidationExpandable: false,
        registrationSearchExpandable: false,
        kybVerificationExpandable: false,
        kycVerificationExpandable: false,
        creditLimitExpandable: false,
        kybMessage: '',
        kycMessage: '',
        viewDetails: false
    }

    componentDidMount() {
        this.getDocumentValidation();
        this.getRegistrationSearch();
        this.getLiens();
        this.getKnowYourBusiness();
        this.getKnowYourCustomer();
        this.getValidationLogs();
        // this.getCreditLimit()

        const { openVerification } = this.props;
        switch (openVerification) {
            case "kyb":
                this.setState({
                    documentValidationExpandable: false,
                    registrationSearchExpandable: false,
                    kybVerificationExpandable: true,
                    kycVerificationExpandable: false,
                })
                break;
            case "kyc":
                this.setState({
                    documentValidationExpandable: false,
                    registrationSearchExpandable: false,
                    kybVerificationExpandable: false,
                    kycVerificationExpandable: true,
                })
                break;
            default:
                this.setState({
                    documentValidationExpandable: false,
                    registrationSearchExpandable: false,
                    kybVerificationExpandable: false,
                    kycVerificationExpandable: false,
                })
                break;
        }
    }

    getDocumentValidation = () => {
        const { loanApp } = this.props
        var body = {
            "clientBusinessId": loanApp.businessId,
            "applicationId": loanApp.applicationId,
            "rootType": 'BusinessFinancials'
        }

        console.log("/ops/loans/application/getVerificationResults body", body)
        this.setState({ prescreenResultsLoaded: false })
        apiPOSTReq(`${environment.opsBaseUrl}/ops/loans/application/getVerificationResults`, null, body, (err, resp) => {
            this.setState({ prescreenResultsLoaded: true })
            try {
                const data = resp || {}
                if (data.result) {
                    var businessValidations = data.businessValidations
                    this.setState({
                        documentValidation: businessValidations
                    })
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    getRegistrationSearch = () => {
        const { loanApp } = this.props
        var body = {
            "clientBusinessId": loanApp.businessId,
            "applicationId": loanApp.applicationId,
            "rootType": 'RegistrationSearch'
        }

        console.log("/ops/loans/application/getVerificationResults body", body)
        this.setState({ prescreenResultsLoaded: false })
        apiPOSTReq(`${environment.opsBaseUrl}/ops/loans/application/getVerificationResults`, null, body, (err, resp) => {
            this.setState({ prescreenResultsLoaded: true })
            try {
                const data = resp || {}
                if (data.result) {
                    var businessValidations = data.businessValidations
                    this.setState({
                        registrationSearch: businessValidations
                    })
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    getLiens = () => {
        const { loanApp } = this.props
        var body = {
            "BusinessId": loanApp.businessId,
            "applicationId": loanApp.applicationId
        }

        console.log("/lsm/getLiens body", body)
        this.setState({ prescreenResultsLoaded: false })
        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/getLiens`, null, body, (err, resp) => {
            this.setState({ prescreenResultsLoaded: true })
            try {
                const data = resp || {}
                if (data.result) {
                    var liens = data.liens
                    this.setState({
                        liens: liens
                    })
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    getKnowYourBusiness = () => {
        const { loanApp } = this.props
        var body = {
            "clientBusinessId": loanApp.businessId,
            "applicationId": loanApp.applicationId,
            "rootType": 'KnowYourBusiness'
        }

        console.log("/ops/loans/application/getVerificationResults body", body)
        this.setState({ prescreenResultsLoaded: false })
        apiPOSTReq(`${environment.opsBaseUrl}/ops/loans/application/getVerificationResults`, null, body, (err, resp) => {
            this.setState({ prescreenResultsLoaded: true })
            try {
                const data = resp || {}
                if (data.result) {
                    var businessValidations = data.businessValidations
                    this.setState({
                        knowYourBusiness: businessValidations
                    })
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    getKnowYourCustomer = () => {
        const { loanApp } = this.props
        var body = {
            "clientBusinessId": loanApp.businessId,
            "applicationId": loanApp.applicationId,
            "rootType": 'KnowYourCustomer'
        }

        console.log("/ops/loans/application/getVerificationResults body", body)
        this.setState({ prescreenResultsLoaded: false })
        apiPOSTReq(`${environment.opsBaseUrl}/ops/loans/application/getVerificationResults`, null, body, (err, resp) => {
            this.setState({ prescreenResultsLoaded: true })
            try {
                const data = resp || {}
                if (data.result) {
                    var businessValidations = data.businessValidations
                    this.setState({
                        knowYourCustomer: businessValidations
                    })
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    getCreditLimit = () => {
        const { loanApp } = this.props
        var body = {
            "clientBusinessId": loanApp.businessId,
            "applicationId": loanApp.applicationId,
            "rootType": 'CreditLimitEvaluation'
        }

        console.log("/ops/loans/application/getVerificationResults body", body)
        this.setState({ prescreenResultsLoaded: false })
        apiPOSTReq(`${environment.opsBaseUrl}/ops/loans/application/getVerificationResults`, null, body, (err, resp) => {
            this.setState({ prescreenResultsLoaded: true })
            try {
                const data = resp || {}
                if (data.result) {
                    var businessValidations = data.businessValidations
                    this.setState({
                        creditLimit: businessValidations
                    })
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    getValidationLogs = () => {
        const { loanApp } = this.props
        var body = {
            "clientBusinessId": loanApp.businessId,
            "applicationId": loanApp.applicationId
        }

        console.log("/ops/loans/application/getVerificationLogs body", body)
        this.setState({ prescreenResultsLoaded: false })
        apiPOSTReq(`${environment.opsBaseUrl}/ops/loans/application/getVerificationLogs`, null, body, (err, resp) => {
            this.setState({ prescreenResultsLoaded: true })
            try {
                const data = resp || {}
                if (data.result) {
                    var validationLogs = data.validationLogs
                    var financialValidation = validationLogs.filter(x => x.type == 'BusinessFinancialValidation' && x.interpretedMessage != null && x.result != null);
                    var registrationValidation = validationLogs.filter(x => x.type == 'BusinessRegistrationExtraction' && x.interpretedMessage != null && x.result != null);
                    var kybValidation = validationLogs.filter(x => x.type == 'KnowYourBusinessExtraction' || x.type == 'KYB:BusinessIDConfirm' && x.result != null);
                    var kycValidation = validationLogs.filter(x => x.type == 'KnowYourCustomerExtraction' || x.type == 'KYC:BusinessIDConfirm' && x.result != null);
                    var kycCustomerValidation = validationLogs.filter(x => x.type.includes('KYC') && x.result != null);

                    this.setState({
                        validationLogs: validationLogs,
                        financialMessage: financialValidation.length > 0 ? financialValidation[0].interpretedMessage : '',
                        registrationMessage: registrationValidation.length > 0 ? registrationValidation[0].interpretedMessage : '',
                        kybMessage: kybValidation.length > 0 ? kybValidation[0].interpretedMessage : '',
                        kycMessage: kycValidation.length > 0 ? kycValidation[0].interpretedMessage : '',
                        customerValidations: kycCustomerValidation
                    });
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    createValidationItems = (validations) => {
        var items = [];
        var previous = '';
        var parentType = '';
        (validations || []).forEach(item => {
            if (item.rank != 0) {
                if (item.rank == 1) {
                    parentType = item.parentType;
                    previous = item.parentType;
                }
                else if (parentType != item.parentType) {
                    previous = parentType;
                    parentType = item.parentType;
                }

                var padding = 25;
                if (item.rootType == "KnowYourBusiness") {
                    if (item.rank > 2) {
                        padding = 35
                    }

                    if (item.rank > 7) {
                        padding = 45
                    }
                }

                items.push(
                    <Descriptions.Item label={(
                        <div style={{ paddingLeft: padding }}>{item.displayName}
                            {
                                item.displayName == 'ID Confirm score above threshold' && ' (Score: ' + item.additionalParameterReference.parameters[0].value + ')'
                            }
                            {
                                item.displayName == 'Risk Inform score above threshold' && ' (Score: ' + (item.additionalParameterReference.parameters[0].value == null ? '--' : item.additionalParameterReference.parameters[0].value) + ')'
                            }
                            {
                                item.displayName == 'Data Completeness Check: Balance sheet provided as of end of Prior Year' && ' (Timeframe: ' + item.additionalParameterReference.parameters[0].value + ')'
                            }
                            {
                                item.displayName == 'Data Completeness Check: Balance sheet provided as of Last Closed Month' && ' (Timeframe: ' + item.additionalParameterReference.parameters[0].value + ')'
                            }
                            {
                                item.displayName == 'Data Completeness Check: PnL provided for Prior Year' && ' (Timeframe: ' + item.additionalParameterReference.parameters[0].value + ')'
                            }
                            {
                                item.displayName == 'Data Completeness Check: PnL provided for YTD-Last Closed Month' && ' (Timeframe: ' + item.additionalParameterReference.parameters[0].value + ')'
                            }
                            {
                                item.rootType == "BusinessFinancials" && item.rank > 7 && <TextButton onClick={() => this.setState({ viewDetails: true, validation: item })} text={"VIEW DETAILS"} />
                            }
                        </div>)} labelStyle={{ fontSize: 14, width: 400 }} contentStyle={{ fontSize: 14 }}>
                        <DescItem>
                            {
                                item.value == 'Approved' ? <CheckCircleFilled style={{ color: 'green' }} /> :
                                    item.value == 'Review' ? <ExclamationCircleFilled style={{ color: 'orange' }} /> :
                                        item.value == 'ProcessingError' ? '--'
                                            : <CloseCircleFilled style={{ color: 'red' }} />
                            }
                        </DescItem>
                    </Descriptions.Item>
                )
            }
        })

        return items
    }

    createLiens = () => {
        const { liens } = this.state;
        var items = [];
        (liens || []).forEach(item => {
            var securedParties = item.name.split(';')[0].trim().replace(/,\s*$/, "");
            var status = item.name.split(';')[1];
            var debtors = item.value.replace(/,\s*$/, "");
            items.push(
                <Descriptions.Item label={(
                    <div style={{ paddingLeft: 25 }}>
                        <div style={{ marginBottom: 10 }}>
                            <span style={{ fontWeight: 500 }}>{"Secured Parties: ".toUpperCase()}</span> {securedParties.toUpperCase()}
                        </div>
                        <div>
                            <span style={{ fontWeight: 500 }}>{"Debtors: ".toUpperCase()}</span> {debtors.toUpperCase()}
                        </div>
                    </div>)}
                    labelStyle={{ fontSize: 14, width: 400 }} contentStyle={{ fontSize: 14 }}>
                    <DescItem>
                        {
                            status.toUpperCase()
                        }
                    </DescItem>
                </Descriptions.Item>
            )
        });
        return items;
    }

    createKYCItems = (validations) => {
        var items = [];
        var { customerValidations } = this.state;
        customerValidations = customerValidations || [];
        (validations || []).forEach(item => {
            if (item.rank != 0) {
                if (item.type == 'KnowYourCustomerPerson') {
                    var interpretedMessage = customerValidations.filter(x => x.responseMessage == item.name);

                    items.push(
                        <Descriptions.Item label={
                            <div style={{ paddingLeft: 25 }}>{item.displayName}
                                {
                                    interpretedMessage.length > 0 ? <Tag primary>{interpretedMessage[0].interpretedMessage}</Tag> : <></>
                                }
                            </div>
                        }
                            labelStyle={{ fontSize: 15, width: 400 }} contentStyle={{ fontSize: 14 }}>
                            <DescItem>
                                {
                                    item.value == 'Approved' ? <CheckCircleFilled style={{ color: 'green' }} /> :
                                        item.value == 'Review' || item.value == 'ProcessingError' ? <ExclamationCircleFilled style={{ color: 'orange' }} />
                                            : <CloseCircleFilled style={{ color: 'red' }} />
                                }
                            </DescItem>
                        </Descriptions.Item>
                    );

                    var interpretedMessage = customerValidations.filter(x => x.responseMessage == item.name);
                    if (interpretedMessage.length == 0 || interpretedMessage[0].interpretedMessage.includes('Person could not be found in CLEAR')) {
                        var previous = '';
                        var parentType = '';

                        (validations || []).forEach(subItem => {
                            if (subItem.type == 'KnowYourCustomerItem' && subItem.parentName == item.name) {
                                if (parentType != subItem.parentType) {
                                    previous = parentType;
                                    parentType = subItem.parentType;
                                }

                                var padding = 35;
                                if (subItem.rootType == "KnowYourCustomer") {
                                    if (subItem.rank > 2) {
                                        padding = 45
                                    }

                                    if (subItem.rank > 5) {
                                        padding = 55
                                    }
                                }

                                var additionalParameterReference = subItem.additionalParameterReference;
                                var parameters = additionalParameterReference.parameters;
                                var params = [];
                                if (subItem.displayName == 'Criminal History' && parameters) {
                                    params = parameters.filter(x => x.value != 'Approved')
                                }

                                items.push(
                                    <Descriptions.Item label={(<div style={{ paddingLeft: padding }}>{subItem.displayName}
                                        {
                                            subItem.displayName == 'Person ID Confirmation Threshold' && ' (Score: ' + (subItem.additionalParameterReference.parameters[0].value == null ? '--' : subItem.additionalParameterReference.parameters[0].value) + ')'
                                        }
                                        {
                                            subItem.displayName == 'Risk Inform score above threshold' && ' (Score: ' + (subItem.additionalParameterReference.parameters[0].value == null ? '--' : subItem.additionalParameterReference.parameters[0].value) + ')'
                                        }
                                        {
                                            (subItem.displayName == 'Criminal History' && (subItem.value != 'Approved' && subItem.value != 'ProcessingError')) && <Tooltip overlayInnerStyle={{ width: 400, minWidth: 400 }} overlayStyle={{ width: 400, minWidth: 400 }} overlayClassName='roles-tooltip' placement="right" arrowPointAtCenter={false} title={<FlexColumn>
                                                {
                                                    params.map(item => (
                                                        <Text color='white' size='14px' noWrap>{`${item.name} - ${item.value}`}</Text>
                                                    ))
                                                }
                                            </FlexColumn>}>
                                                <div style={{ float: "right", width: 30 }}>
                                                    <Image src={Info} />
                                                </div>
                                            </Tooltip>
                                        }
                                    </div>)} labelStyle={{ fontSize: 14, width: 400 }} contentStyle={{ fontSize: 14 }}>
                                        <DescItem>
                                            {
                                                subItem.value == 'Approved' ? <CheckCircleFilled style={{ color: 'green' }} /> :
                                                    subItem.value == 'Review' ? <ExclamationCircleFilled style={{ color: 'orange' }} /> :
                                                        subItem.value == 'ProcessingError' ? '--'
                                                            : <CloseCircleFilled style={{ color: 'red' }} />
                                            }
                                        </DescItem>
                                    </Descriptions.Item>
                                );
                            }
                        })
                    }
                }
            }
        })

        return items
    }

    createViewDetails = () => {
        var items = [];
        const { validation } = this.state
        const additionalParameterReference = validation.additionalParameterReference;
        const parameters = additionalParameterReference.parameters;
        (parameters || []).forEach(
            validationItem => {
                if (validationItem.name != 'Notes' && validationItem.name != 'BusinessDocumentName' && validationItem.name != 'Deviation')
                    items.push(
                        <Descriptions.Item label={(<div style={{ paddingLeft: 15 }}>{validationItem.name}</div>)} labelStyle={{ fontSize: 14, width: 400 }} contentStyle={{ fontSize: 14 }}>
                            <DescItem>
                                {
                                    formatter.format(validationItem.value)
                                }
                            </DescItem>
                        </Descriptions.Item>
                    );
            }
        )

        return items;
    }


    preScreenReview = () => {
        this.setState({ showPreScreenModal: true });
    }

    getPreScreenResult = () => {
        const { theme, loanApp, status } = this.props
        const { documentValidationExpandable, prescreenResultsLoaded, registrationSearchExpandable, kybVerificationExpandable, kycVerificationExpandable, showPreScreenModal, viewDetails, validation } = this.state
        const { documentValidation, registrationSearch, knowYourBusiness, knowYourCustomer, kybMessage, kycMessage, financialMessage, registrationMessage, liens, liensExpandable } = this.state
        var statusMessage = '';
        var showInfoIcon = false;
        var infoMessage = "";
        if (registrationSearch && registrationSearch.length > 0) {
            var message = registrationSearch[0].additionalParameterReference.parameters[0].value;
            if (message.includes('Error:')) {
                var splitMessage = message.split('Error: ')[1];
                statusMessage = splitMessage.split(':')[0];
                if (splitMessage.split(':').length > 1) {
                    showInfoIcon = true;
                    infoMessage = splitMessage.split(':')[1];
                }
            }
            else {
                statusMessage = 'Status: ' + message;
            }
        }
        return (
            <FlexColumn between>
                <FlexColumn>
                    <Text color={theme.colors.secondary3}>Pre-Screen Results
                        {
                            (status == "Pre Review" || status == "Manual Review" || status == "Auto Rejected" || status == "Processing Error") &&
                            <Space size="middle" style={{ width: "550px", justifyContent: "flex-end" }}>
                                <Button type="primary" style={{ marginRight: 0 }} permtype="Override" solid height="45px" onClick={this.preScreenReview} text="Review"></Button>
                            </Space>

                        }
                    </Text>
                    <div style={{ width: 700, padding: 10 }}>
                        <Skeleton loading={!prescreenResultsLoaded} active title={false} paragraph={{ rows: 9 }}>
                            <div style={{ display: "flex", flexDirection: "flex-start" }}>
                                <Descriptions
                                    bordered
                                    column={1}
                                    style={{ width: 700 }}
                                >
                                    <Descriptions.Item
                                        label={(
                                            <div style={{ display: "flex" }}><span>Document Validation</span>
                                                {
                                                    financialMessage != '' && <Tag primary>{financialMessage}</Tag>
                                                }
                                            </div>)}
                                        labelStyle={{ fontWeight: 500, fontSize: 16, width: 550 }}
                                        contentStyle={{ fontSize: 16 }}
                                    >
                                        <DescItem>
                                            <div style={{ marginRight: 10 }}>
                                                {
                                                    documentValidation ?
                                                        (documentValidation.length > 0 && documentValidation[0].value == 'Approved' ? <CheckCircleFilled style={{ color: 'green' }} /> :
                                                            documentValidation.length > 0 && documentValidation[0].value == 'Rejected' ? <CloseCircleFilled style={{ color: 'red' }} /> : <ExclamationCircleFilled style={{ color: 'orange' }} />)
                                                        : <CloseCircleFilled style={{ color: 'red' }} />
                                                }
                                            </div>
                                            <div
                                                style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                                                onClick={() => { this.setState({ documentValidationExpandable: !documentValidationExpandable }) }}
                                            >
                                                {(documentValidationExpandable === true ? <UpOutlined /> : <DownOutlined />)}
                                            </div>
                                        </DescItem>
                                    </Descriptions.Item>
                                    {
                                        documentValidationExpandable === true &&
                                        <>
                                            {
                                                this.createValidationItems(documentValidation)
                                            }
                                        </>
                                    }
                                </Descriptions>
                            </div>
                            <div style={{ display: "flex", flexDirection: "flex-start", marginTop: '10px' }}>
                                <Descriptions
                                    bordered
                                    column={1}
                                    style={{ width: 700 }}
                                >
                                    <Descriptions.Item
                                        label={(<div style={{ display: "flex" }}><span>Registration Search</span>
                                            {
                                                registrationMessage != '' && <Tag primary>{registrationMessage}</Tag>
                                            }
                                            {
                                                registrationSearch && registrationSearch.length > 0 && <><Tag primary>{statusMessage}</Tag>
                                                    {
                                                        showInfoIcon && <Tooltip overlayInnerStyle={{ width: 400, minWidth: 400 }} overlayStyle={{ width: 400, minWidth: 400 }} overlayClassName='roles-tooltip' placement="right" arrowPointAtCenter={false} title={<FlexColumn>
                                                            {
                                                                <Text color='white' size='14px' noWrap>{infoMessage}</Text>
                                                            }
                                                        </FlexColumn>}>
                                                            <div style={{ float: "right", width: 30 }}>
                                                                <Image src={Info} />
                                                            </div>
                                                        </Tooltip>
                                                    }
                                                </>
                                            }
                                            {
                                                registrationSearch && registrationSearch.length > 0 && registrationSearch[0].mutipleUCC && <Tag primary>UCC Search could not be completed as multiple entities were detected</Tag>
                                            }
                                            {
                                                registrationSearch && registrationSearch.length > 0 && registrationSearch[0].multipleCNV && <Tag primary>CNV Search could not be completed as multiple entities were detected</Tag>
                                            }
                                        </div>)}
                                        labelStyle={{ fontWeight: 500, fontSize: 16, width: 550 }}
                                        contentStyle={{ fontSize: 16 }}
                                    >
                                        <DescItem>
                                            <div style={{ marginRight: 25 }}>
                                                {
                                                    registrationSearch ?
                                                        (registrationSearch.length > 0 && registrationSearch[0].value == 'Approved' ? <CheckCircleFilled style={{ color: 'green' }} /> :
                                                            registrationSearch.length > 0 && registrationSearch[0].value == 'Rejected' ? <CloseCircleFilled style={{ color: 'red' }} /> :
                                                                <ExclamationCircleFilled style={{ color: 'orange' }} />)
                                                        : <CloseCircleFilled style={{ color: 'red' }} />
                                                }
                                            </div>
                                            {
                                                registrationSearch && registrationSearch.length > 0 && (registrationSearch[0].multipleCNV || registrationSearch[0].mutipleUCC) &&
                                                <div
                                                    style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                                                    onClick={() => { this.setState({ registrationSearchExpandable: !registrationSearchExpandable }) }}
                                                >
                                                    {(registrationSearchExpandable === true ? <UpOutlined /> : <DownOutlined />)}
                                                </div>
                                            }
                                        </DescItem>
                                    </Descriptions.Item>
                                    {/* {
                                        registrationSearchExpandable === true && registrationSearch.length > 0 && registrationSearch[0].mutipleUCC &&
                                        <>
                                            <Descriptions.Item label={(<div style={{ paddingLeft: 25 }}>UCC Search could not be completed as multiple entities were detected</div>)} labelStyle={{ fontSize: 14, width: 400 }} contentStyle={{ fontSize: 14 }}>
                                                <DescItem>
                                                    <ExclamationCircleFilled style={{ color: 'orange' }} />
                                                </DescItem>
                                            </Descriptions.Item>
                                        </>
                                    }
                                    {
                                        registrationSearchExpandable === true && registrationSearch.length > 0 && registrationSearch[0].multipleCNV &&
                                        <>
                                            <Descriptions.Item label={(<div style={{ paddingLeft: 25 }}>CNV Search could not be completed as multiple entities were detected</div>)} labelStyle={{ fontSize: 14, width: 400 }} contentStyle={{ fontSize: 14 }}>
                                                <DescItem>
                                                    <ExclamationCircleFilled style={{ color: 'orange' }} />
                                                </DescItem>
                                            </Descriptions.Item>
                                        </>
                                    } */}
                                </Descriptions>
                            </div>
                            <div style={{ display: "flex", flexDirection: "flex-start", marginTop: '10px' }}>
                                <Descriptions
                                    bordered
                                    column={1}
                                    style={{ width: 700 }}
                                >
                                    <Descriptions.Item
                                        label={(<div style={{ display: "flex" }}><span>UCC Lien Search</span></div>)}
                                        labelStyle={{ fontWeight: 500, fontSize: 16, width: 550 }}
                                        contentStyle={{ fontSize: 16 }}
                                    >
                                        <DescItem>
                                            <div style={{ marginRight: 10 }}>
                                                {
                                                    liens ?
                                                        (liens.length < 2 ? <CheckCircleFilled style={{ color: 'green' }} /> :
                                                            liens.length >= 2 ? <CloseCircleFilled style={{ color: 'red' }} /> :
                                                                <ExclamationCircleFilled style={{ color: 'orange' }} />)
                                                        : <CloseCircleFilled style={{ color: 'red' }} />
                                                }
                                            </div>
                                            {
                                                liens && liens.length > 0 &&
                                                <div
                                                    style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                                                    onClick={() => { this.setState({ liensExpandable: !liensExpandable }) }}
                                                >
                                                    {(liensExpandable === true ? <UpOutlined /> : <DownOutlined />)}
                                                </div>
                                            }
                                        </DescItem>
                                    </Descriptions.Item>
                                    {
                                        liensExpandable === true && <Descriptions.Item label={(
                                            <div style={{ fontWeight: 500 }}>
                                                Lien Information
                                            </div>)}
                                            labelStyle={{ fontSize: 14, width: 400 }} contentStyle={{ fontSize: 14 }}>
                                            <DescItem>
                                                <div style={{ fontWeight: 500 }}>
                                                    Status
                                                </div>
                                            </DescItem>
                                        </Descriptions.Item>
                                    }
                                    {
                                        liensExpandable === true && this.createLiens()
                                    }

                                </Descriptions>
                            </div>
                            <div style={{ display: "flex", flexDirection: "flex-start", marginTop: '10px' }}>
                                <Descriptions
                                    bordered
                                    column={1}
                                    style={{ width: 700 }}
                                >
                                    <Descriptions.Item
                                        label={(<div style={{ display: "flex" }}><span>KYB Verification</span>
                                            {
                                                kybMessage != '' && <Tag primary>{kybMessage}</Tag>
                                            }
                                        </div>)}
                                        labelStyle={{ fontWeight: 500, fontSize: 16, width: 550 }}
                                        contentStyle={{ fontSize: 16 }}
                                    >
                                        <DescItem>
                                            <div style={{ marginRight: 10 }}>
                                                {
                                                    knowYourBusiness ?
                                                        (knowYourBusiness.length > 0 && knowYourBusiness[0].value == 'Approved' ? <CheckCircleFilled style={{ color: 'green' }} /> :
                                                            knowYourBusiness.length > 0 && knowYourBusiness[0].value == 'Rejected' ? <CloseCircleFilled style={{ color: 'red' }} />
                                                                : <ExclamationCircleFilled style={{ color: 'orange' }} />)
                                                        : <CloseCircleFilled style={{ color: 'red' }} />
                                                }
                                            </div>
                                            <div
                                                style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                                                onClick={() => { this.setState({ kybVerificationExpandable: !kybVerificationExpandable }) }}
                                            >
                                                {(kybVerificationExpandable === true ? <UpOutlined /> : <DownOutlined />)}
                                            </div>
                                        </DescItem>
                                    </Descriptions.Item>
                                    {
                                        kybVerificationExpandable === true &&
                                        <>
                                            {
                                                (kybMessage == '' || kybMessage.toLowerCase().includes('business could not be found in clear')) && this.createValidationItems(knowYourBusiness)
                                            }
                                        </>
                                    }
                                </Descriptions>
                            </div>
                            <div style={{ display: "flex", flexDirection: "flex-start", marginTop: '10px' }}>
                                <Descriptions
                                    bordered
                                    column={1}
                                    style={{ width: 700 }}
                                >
                                    <Descriptions.Item
                                        label={(<div style={{ display: "flex" }}><span>KYC Verification</span>
                                            {
                                                kycMessage != '' && <Tag primary>{kycMessage}</Tag>
                                            }
                                        </div>)}
                                        labelStyle={{ fontWeight: 500, fontSize: 16, width: 550 }}
                                        contentStyle={{ fontSize: 16 }}
                                    >
                                        <DescItem>
                                            <div style={{ marginRight: 10 }}>
                                                {
                                                    knowYourCustomer ?
                                                        (knowYourCustomer.length > 0 && knowYourCustomer[0].value == 'Approved' ? <CheckCircleFilled style={{ color: 'green' }} /> :
                                                            knowYourCustomer.length > 0 && knowYourCustomer[0].value == 'Rejected' ? <CloseCircleFilled style={{ color: 'red' }} />
                                                                : <ExclamationCircleFilled style={{ color: 'orange' }} />)
                                                        : <CloseCircleFilled style={{ color: 'red' }} />
                                                }
                                            </div>
                                            <div
                                                style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                                                onClick={() => { this.setState({ kycVerificationExpandable: !kycVerificationExpandable }) }}
                                            >
                                                {(kycVerificationExpandable === true ? <UpOutlined /> : <DownOutlined />)}
                                            </div>
                                        </DescItem>
                                    </Descriptions.Item>
                                    {
                                        kycVerificationExpandable === true &&
                                        <>
                                            {
                                                kycMessage == '' && this.createKYCItems(knowYourCustomer)
                                            }
                                            {/* {
                                                kycMessage != '' &&
                                                <Descriptions.Item label={(<div style={{ paddingLeft: 25 }}>{kycMessage}</div>)} labelStyle={{ fontSize: 14, width: 400 }} contentStyle={{ fontSize: 14 }}>
                                                    <DescItem>
                                                        <ExclamationCircleFilled style={{ color: 'orange' }} />
                                                    </DescItem>
                                                </Descriptions.Item>
                                            } */}
                                        </>
                                    }
                                </Descriptions>
                            </div>
                            {/* <div style={{ display: "flex", flexDirection: "flex-start", marginTop: '10px' }}>
                                <Descriptions
                                    bordered
                                    column={1}
                                    style={{ width: 550 }}
                                >
                                    <Descriptions.Item
                                        label={(<div style={{ display: "flex" }}><span>Evaluate Product Type & Compute Credit Limit</span></div>)}
                                        labelStyle={{ fontWeight: 500, fontSize: 16, width: 400 }}
                                        contentStyle={{ fontSize: 16 }}
                                    >
                                        <DescItem>
                                            <div style={{ marginRight: 10 }}>
                                                {
                                                    creditLimit ?
                                                        (creditLimit.length > 0 && creditLimit[0].value == 'Approved' ? <CheckCircleFilled style={{ color: 'green' }} /> : <ExclamationCircleFilled style={{ color: 'orange' }} />)
                                                        : <ExclamationCircleFilled style={{ color: 'orange' }} />
                                                }
                                            </div>
                                            <div
                                                style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                                                onClick={() => { this.setState({ creditLimitExpandable: !creditLimitExpandable }) }}
                                            >
                                                {(creditLimitExpandable === true ? <UpOutlined /> : <DownOutlined />)}
                                            </div>
                                        </DescItem>
                                    </Descriptions.Item>
                                    {
                                        creditLimitExpandable === true &&
                                        <>
                                            {
                                                this.createValidationItems(creditLimit)
                                            }
                                        </>
                                    }
                                </Descriptions>
                            </div> */}
                        </Skeleton>
                    </div>
                </FlexColumn >
                <Modal
                    visible={showPreScreenModal}
                    footer={null}
                    closable={true}
                    width={600}
                    style={{ top: 10 }}
                    destroyOnClose={true}
                    onCancel={() => {
                        this.setState({ showPreScreenModal: false })
                    }}
                >
                    <PreScreenReview loanApp={loanApp} handleClosePreScreenReview={this.handleClosePreScreenReview}></PreScreenReview>
                </Modal >
                <Modal
                    visible={viewDetails}
                    footer={null}
                    closable={true}
                    width={600}
                    style={{ top: 10 }}
                    destroyOnClose={true}
                    onCancel={() => {
                        this.setState({ viewDetails: false })
                    }}
                >
                    <FlexColumn style={{ margin: '0 10px', minHeight: "60vh", justifyContent: "space-between" }}>
                        <FlexColumn style={{ justifyContent: "flex-start" }}>
                            <Text heading>Details</Text>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {validation && <Descriptions
                                    bordered
                                    column={1}
                                    style={{ width: 550 }}
                                >
                                    <Descriptions.Item
                                        label={(<div style={{ display: "flex" }}><span>{validation.displayName}</span></div>)}
                                        labelStyle={{ fontWeight: 500, fontSize: 16, width: 400 }}
                                        contentStyle={{ fontSize: 16 }}
                                    ></Descriptions.Item>
                                    {
                                        this.createViewDetails()
                                    }
                                </Descriptions>}
                            </div>
                        </FlexColumn>
                    </FlexColumn>
                </Modal >
            </FlexColumn >
        )
    }

    handleClosePreScreenReview = () => {
        this.setState({ showPreScreenModal: false })
        this.props.updateStatus('')
    }

    getDueDiligenceResult = () => {
        const { theme } = this.props
        const { business } = this.state

        return (
            <FlexColumn start>
                <Text level={3} bold color={theme.colors.secondary3}>Due Diligence Results</Text>
            </FlexColumn>
        )
    }

    render() {
        return (
            <FlexColumn style={{ marginTop: "20px" }}>
                <CardContainer fullWidth>
                    <Flex style={{ justifyContent: "flex-start", marginBottom: "50px" }}>
                        {this.getPreScreenResult()}
                    </Flex>
                    {/* {this.getDueDiligenceResult()} */}
                </CardContainer>
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)))