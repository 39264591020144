import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import moment from 'moment'
import {
    ConfigProvider,
    Modal,
    Empty,
    Button,
    Tooltip,
    Table,
    Tag,
    Space,
    Select,
    Popconfirm,
    message,
} from 'antd'

// Components
import { ErrorAlert } from '../../Reusable/Alert'
import { LabeledInput } from '../../Reusable/Input'
import { Text } from '../../Reusable/Text'
import { Image } from '../../Reusable/Image'

// Util
import { apiGET, apiPOSTReq } from '../../../Utils/api'
import environment from '../../../environment'

// Actions
import { CREDIT_SAVE_DATA, addDataToStore } from '../../../Actions/actions'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { getResourcePerm } from '../../../Utils/util'
import Search from '../../../Images/search.png'
import { FlexColumn } from '../../Reusable/Container'

const { confirm } = Modal
const { Option } = Select
var dFormat = "YYYY-MM-DD"

class InvoicesIndex extends Component {
    state = {
        customize: true,
        showAddPO: false,
        loading: false,
        pagination: {
            current: 1,
            pageSize: 100
        },
        sorter: {},
        sortDirection: "DESC",
        showClear: false,
    }

    constructor(props) {
        super(props)

        this.showAddPOConfirm = this.showAddPOConfirm.bind(this)
        this.state = Object.assign(this.state, this.props.location.state ? this.props.location.state : {})
    }

    componentDidMount() {
        if (this.props.qbExpired) {
            this.props.reconnectQB()
        }

        this.props.fetchQBInvoices({ pagination: this.props.pagination })
        this.fetchPOs()
        this.handleDateTypeSelection("Year to Date")
    }

    customizeRenderEmpty = () => (
        <Empty
            imageStyle={{
                height: 60,
            }}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
                <span>
                    No Invoices
                </span>
            }>
            {
                this.props.aionStore.Attributes.Businesses.AccountingSWLinked ?
                    <div>
                        <Button type="primary" size="large" onClick={this.onAddInvoiceViaQB} style={{ marginRight: '5pt' }}>Submit Invoices</Button>
                    </div>
                    : <Button type="primary" onClick={this.showAddPOConfirm} style={{ marginLeft: '5pt' }}>Add Invoices</Button>
            }
        </Empty>
    )

    showAddPOConfirm = () => {
        confirm({
            title: 'Do you have a purchase order for this invoice?',
            content: 'A purchase order helps us verify your invoice.',
            okText: 'Submit purchase order',
            cancelText: 'Add invoice',
            onOk: () => {
                this.showAddPOModal()
            },
            onCancel: () => {
                this.props.history.push('/receivables/invoices/add')
            }
        })
    }

    showAddPOModal = () => {
        this.setState({ showAddPO: true })
    }

    handleCancelPO = () => {
        this.setState({ showAddPO: false })
    }

    handleCreatePO = () => {
        this.setState({ showAddPO: false })
    }

    saveFormRef = formRef => {
        this.formRef = formRef
    }

    handleOnChange = (event) => {
        const target = event.target
        this.setState({ [target.id]: target.value })
    }

    handleOnSearchChange = (e) => {
        this.setState({ [e.target.id]: e.target.value })

        this.props.fetchQBInvoices({ searchTerm: e.target.value, pagination: this.props.pagination })
    }

    handleDateTypeSelection = (val) => {
        var fromDate, toDate = null
        switch (val) {
            case "Year to Date":
                fromDate = moment().utc(moment()).startOf("year").format(dFormat)
                toDate = moment().utc(moment()).format(dFormat)
                break
            case "Last Year":
                fromDate = moment().utc(moment()).subtract(1, "year").startOf("year").format(dFormat)
                toDate = moment().utc(moment()).subtract(1, "year").endOf("year").format(dFormat)
                break
            case "Current Month":
                fromDate = moment().utc(moment()).startOf("month").format(dFormat)
                toDate = moment().utc(moment()).format(dFormat)
                break
            case "Previous Month":
                const prevMonth = moment().utc(moment()).subtract(1, "month")
                fromDate = prevMonth.startOf("month").format(dFormat)
                toDate = prevMonth.endOf("month").format(dFormat)
                break
            default:
                fromDate = this.state.fromDate
                toDate = this.state.toDate
                break
        }
        var updatedState = { fromDate: fromDate, toDate: toDate, dateType: val, loading: true }
        this.props.fetchQBInvoices({ ...this.state, ...updatedState })
    }

    handleDateRange = (dates) => {
        var updatedState = {
            fromDate: dates[0].format(dFormat),
            toDate: dates[1].format(dFormat),
            dateType: "Custom",
            loading: true
        }
        this.props.fetchQBInvoices({ ...this.state, ...updatedState })
    }

    fetchPOs() {
        const headers = {
            businesskey: this.props.aionStore.BusinessUniqueKey
        }
        apiGET("/financing/invoices/po", headers, (err, resp) => {
            try {
                const data = resp.data
                if (data.success) {
                    this.props.dispatch(addDataToStore(CREDIT_SAVE_DATA, data))
                } else {
                    throw Error("Could not fetch purchase orders.")
                }
            } catch (error) {
                console.log("ERRR", error.stack)
            }
        })
    }

    onAddInvoiceViaQB = () => {
        if (this.props.qbExpired) {
            this.props.reconnectQB()
        } else {
            this.props.history.push('/receivables/invoices/add', { accountingSoftware: true })
        }
    }

    onAddInvoiceManually = () => {
        this.props.history.push('/receivables/invoices/add')
    }

    getVerificationStatusContent(item) {
        return (
            <FlexColumn>
                <Text color='white'>{item.SystemMessage}</Text>
                {item.CustomerNote ?
                    <div>
                        <br />
                        <Text color='white'>Customer note:</Text><Text mark color='white'> {item.CustomerNote}</Text>
                    </div>
                    : null}
            </FlexColumn>
        )
    }

    onTabChange = tab => {
        this.setState({ tab })
    }

    handleTableChange = (pagination, filters, sorter) => {
        this.props.fetchQBInvoices({ pagination: pagination, sorter: sorter })
    }

    canEdit = (record) => {

        if (!this.props.aionStore.hasIntegration || getResourcePerm("Support.Console").aionSystemUser) {
            return false
        }

        if (!record.invoiceStatus && !record.emailStatus) return true
        if (!record.invoiceStatus) {
            return (record.emailStatus === 'Not Sent')
        }
        if (!record.emailStatus) {
            return (record.invoiceStatus === 'NEW')
        }
        if (record.invoiceStatus !== 'NEW' || record.emailStatus !== 'Not Sent') return false
        return true
    }

    deleteInvoice = (options, pagination) => {
        const { invoiceId, docNumber } = options
        const body = {
            "invoiceId": invoiceId
        }
        console.log('body', body)
        this.setState({ deleteLoading: true })
        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/delete`, {}, body, (err, resp) => {
            try {
                this.setState({ deleteLoading: false })
                const data = resp
                console.log("/ive/invoice/delete", data)
                if (data.result) {
                    message.success(`Invoice No. ${docNumber} deleted!`)
                    this.props.fetchQBInvoices({ pagination: pagination })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /ive/product/delete", error.stack, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    render() {
        let { customize, pagination, searchTerm } = this.state
        const { theme, aionStore, fromDate, toDate, dateType } = this.props
        const attributes = aionStore.Attributes || {}
        const businessAttributes = attributes.Businesses || {}
        var dropdownOptions = []
        var dateOptions = ["Year to Date", "Current Month", "Previous Month", "Last Year", "Custom"]
        var statusFilterOptions = ["New", "Scheduled", "Pending", "Sent", "Canceled", "Delivered", "Opened", "Clicked"]
        dropdownOptions = dateOptions.map(item => (
            <Option key={item} value={item}>
                {item}
            </Option>
        ))

        let invoices = this.props.qbInvoices
        invoices = (invoices || []).map((x, i) => {
            return {
                ...x, key: x.id || i
            }
        })

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        })

        const StatusTag = (options) => {
            let statusMsg
            let statusColor
            let { status, invoice } = options

            if (status) {
                statusColor = ""
                statusMsg = status
                if (statusMsg == "Sent Via Aion") {
                    statusColor = "cyan"
                } else if (statusMsg == "Not Sent") {
                    statusColor = "blue"
                } else if (statusMsg == "NEW") {
                    statusMsg = "New"
                }
            } else {
                statusColor = ""
                statusMsg = "Sent Via Accounting Software"
            }

            // const popupContent = this.getVerificationStatusContent(invoice)
            return (invoice.systemMessage || invoice.customerNote ?
                statusMsg && <Tooltip title={<FlexColumn>
                    <Text color='white'>{invoice.systemMessage}</Text>
                    {invoice.customerNote ?
                        <div>
                            <br />
                            <Text color='white'>Customer note:</Text><Text mark color='white'> {invoice.customerNote}</Text>
                        </div>
                        : null}
                </FlexColumn>}
                    overlayClassName='roles-tooltip'
                    overlayStyle={{ width: 220 }}
                    overlayInnerStyle={{ width: 200 }}>
                    <Tag color={statusColor}>{statusMsg.toUpperCase()}</Tag>
                </Tooltip> :
                statusMsg && <Tag color={statusColor}>{statusMsg.toUpperCase()}</Tag>
            )
        }

        const CreditStatusTag = (options) => {
            let statusMsg
            let statusColor
            let { status, invoice } = options
            switch (status) {
                case "REQUESTED":
                    statusColor = "blue"
                    statusMsg = "Sent for Verification"
                    break
                case "INREVIEW":
                    statusColor = "cyan"
                    statusMsg = "In Review"
                    break
                case "VALIDATED":
                    statusColor = "green"
                    statusMsg = ""
                    break
                case "REJECTED":
                    statusColor = "red"
                    statusMsg = "Rejected"
                    break
                case "ELIGIBLE_FOR_ADVANCE":
                    statusColor = "green"
                    statusMsg = ""
                    break
                case "INELIGIBLE_FOR_ADVANCE":
                    statusColor = "red"
                    statusMsg = "Ineligible for Advance"
                    break
                case "ADVANCED":
                    statusColor = "purple"
                    statusMsg = "Advanced"
                    break
                default:
                    statusColor = ""
                    statusMsg = ""
                    break
            }
            if ((status != "ADVANCED") && (moment(invoice.dueDate).diff(moment()) < 0)) {
                statusColor = ""
                statusMsg = "Expired"
            }
            const popupContent = this.getVerificationStatusContent(invoice)
            return (invoice.systemMessage || invoice.customerNote ?
                <Tooltip title={popupContent}>
                    <Tag color={statusColor}>{statusMsg}</Tag>
                </Tooltip> :
                <Tag color={statusColor}>{statusMsg}</Tag>
            )
        }

        const columns = [
            {
                title: 'ID',
                dataIndex: 'docNumber',
                key: 'docNumber',
                sorter: true,
                showSorterTooltip: false,
            },
            {
                title: 'Customer',
                dataIndex: 'customerName',
                key: 'Customer',
                render: (name, invoice) => name || invoice.customerReference.name,
                sorter: true,
                showSorterTooltip: false,
            },
            {
                title: 'Invoice Date',
                dataIndex: 'txnDate',
                key: 'txnDate',
                render: (date, invoice) => {
                    if (date) {
                        date = new Date(date).toISOString()
                        date = date.split("T")[0]
                    }
                    return (moment(date).format('MM/DD/YY'))
                },
                sorter: true,
                showSorterTooltip: false,
                width: "120px",
            },
            {
                title: 'Due Date',
                dataIndex: 'dueDate',
                key: 'dueDate',

                render: (date, invoice) => {
                    if (date) {
                        date = new Date(date).toISOString()
                        date = date.split("T")[0]
                    }
                    return (moment(date).format('MM/DD/YY'))
                },

                width: "100px",
                sorter: true,
                showSorterTooltip: false,
            },
            {
                title: 'Amount',
                dataIndex: 'totalAmt',
                key: 'totalAmt',
                render: amount => (formatter.format(amount)),
                align: 'right',
                sorter: true,
                showSorterTooltip: false,
            },
            {
                title: 'Balance',
                dataIndex: 'balance',
                key: 'balance',
                render: amount => (formatter.format(amount)),
                align: 'right',
            },
            {
                title: 'Status',
                dataIndex: 'invoiceStatus',
                key: 'invoiceStatus',
                render: (invoiceStatus, invoice) => <StatusTag status={invoiceStatus} invoice={invoice} />,
                sorter: true,
                showSorterTooltip: false,
            },
            {
                title: '',
                key: 'action',
                align: 'center',
                render: (text, record) => (
                    <Space size="middle">
                        {this.canEdit(record) && <a onClick={() => this.props.showSelectedInvoice(record)}><EditOutlined style={{ fontSize: 18 }} /></a>}
                        {
                            this.canEdit(record) &&
                            <Popconfirm
                                title={<>
                                    <div>Are you sure you want to delete this invoice?</div>
                                    {record && record.source === 'QUICKBOOKS' && <div>This will also delete the invoice on QuickBooks Online</div>}
                                </>}
                                onConfirm={() => this.deleteInvoice(record, pagination)}
                                okText="Confirm"
                                placement="topLeft"
                                okButtonProps={{ loading: this.state.deleteLoading }}
                                cancelText="Cancel"
                            >
                                <a permtype="Receivables.Invoices"><DeleteOutlined style={{ fontSize: 18 }} /></a>
                            </Popconfirm>
                        }

                    </Space>
                ),
            }
        ]

        // Add a Credit Status column for AR Credit customers
        if (businessAttributes.ARAgreementSigned) {
            columns.splice(columns.length, 0, {
                title: 'Credit Status',
                dataIndex: 'creditStatus',
                key: 'creditStatus',
                render: (creditStatus, invoice) => <CreditStatusTag status={creditStatus} invoice={invoice} />,
                sorter: true,
                showSorterTooltip: false,
            })
        }

        return (
            <>
                <ConfigProvider renderEmpty={customize && this.customizeRenderEmpty}>
                    <div className="config-provider">
                        <Space size="large">
                            <LabeledInput
                                label="Search"
                                id="searchTerm"
                                key="searchTerm"
                                placeholder="Search invoices"
                                prefixIcon={<Image width='12px' height='12px' src={Search} />}
                                value={searchTerm}
                                onChange={this.handleOnSearchChange}
                                noAsterisk
                            />
                            <LabeledInput
                                label="Date"
                                labelcolor={theme.colors.secondary3}
                                id="statementType"
                                key="statementType"
                                type="select"
                                placeholder="Select"
                                className="no-left-padding"
                                value={dateType}
                                onChange={this.handleDateTypeSelection}
                                style={{ width: "150px", fontSize: "0.95rem", fontWeight: 500 }}
                                noAsterisk
                            >
                                {dropdownOptions}
                            </LabeledInput>
                            <LabeledInput
                                label="Date Range"
                                labelcolor={theme.colors.secondary3}
                                id="dateRange"
                                key="dateRange"
                                type="range-picker"
                                value={[moment(fromDate), moment(toDate)]}
                                format="MM/DD/YYYY"
                                onChange={this.handleDateRange}
                                style={{ width: "250px", fontSize: "1.1rem" }}
                                noAsterisk
                            />
                        </Space>
                        {/* <Skeleton loading={this.state.loading} active title={false} paragraph={{ rows: 4}}> */}
                        <Table
                            id="invoice-table"
                            tableLayout='auto'
                            columns={columns}
                            dataSource={invoices}
                            rowKey='InvoiceId'
                            pagination={this.props.pagination}
                            style={{ minHeight: "500px", marginTop: 12 }}
                            onChange={this.handleTableChange}
                            loading={this.state.loading}
                            scroll={{ y: '700px', x: '100%' }}
                        />
                        {/* </Skeleton> */}
                    </div>
                </ConfigProvider>
                {/* <AddPurchaseOrder
                    wrappedComponentRef={this.saveFormRef}
                    visible={this.state.showAddPO}
                    onCancel={this.handleCancelPO}
                    onCreate={this.handleCreatePO}
                /> */}
            </>
        )
    }

}

function mapStateToProps(state) {
    return {
        store: state.creditAppReducer,
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(InvoicesIndex)))