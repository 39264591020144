import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import { Tabs, Modal, Menu, Drawer, Dropdown, message, Affix, Table, Tooltip } from 'antd'
import { Flex, FlexColumn, CardContainer, LightContainer } from '../../../Reusable/Container'
import PageHeader from '../../../Reusable/PageHeader'
import { Text, Tag } from '../../../Reusable/Text'
import { Button, TextButton, ImageButton } from '../../../Reusable/Button'
import { Image } from '../../../Reusable/Image'
import { LabeledInput } from '../../../Reusable/Input'
import ViewInvoice from './ViewInvoice'
import HistoryDrawer from '../HistoryDrawer'
import PaymentDrawer from '../PaymentDrawer'
import SendInvoiceDrawer from '../SendInvoiceDrawer'
import AlertModal from '../../../Reusable/AlertModal'
import { ErrorAlert } from '../../../Reusable/Alert'
import { Editor, EditorState } from 'draft-js'
import 'draft-js/dist/Draft.css'
import { stateToHTML } from 'draft-js-export-html'
import { stateFromHTML } from 'draft-js-import-html'
import moment, { isMoment } from 'moment'
import { saveAs } from 'file-saver'
import cloneDeep from 'lodash/cloneDeep'

import { toNumber, toCurrency, getFilenameFromUrl, isCodatUser, getCodatPlatformName, getDateInLocalTZ, getDateByTZ } from '../../../../Utils/util'
import { apiPOSTReq, getPreSignedS3Url } from '../../../../Utils/api'
import environment from '../../../../environment'
import Info from '../../../../Images/info-gradient.png'
import ModalClose from '../../../../Images/modal-close.png'
import Download from '../../../../Images/download.png'
import Send from '../../../../Images/send-white.png'
import VoidButton from '../../../../Images/void-button.svg'
import Edit from "../../../../Images/edit.png"
import Emailed from "../../../../Images/emailed.svg"
import Upload from "../../../../Images/upload.svg"
import History from "../../../../Images/history.svg"
import Reminder from "../../../../Images/reminder.png"
import Add from '../../../../Images/add.svg'
import RecordPayment from '../../../../Images/record-payment.svg'
import Check from '../../../../Images/check-round-white.png'
import Void from '../Void'
import TableView from '../../../Reusable/TableView'
import Sync from '../../../../Images/sync-green.svg'
import SyncNow from '../../../../Images/sync-primary.svg'
import InfoError from '../../../../Images/info-error-light.svg'
import CheckGreen from '../../../../Images/check-filled.svg'

const { TabPane } = Tabs

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const etTimeZone = "America/New_York"

const CreditStatusTag = (options) => {
    let statusMsg;
    let statusColor;
    let { invoice, theme } = options;
    switch (invoice.creditStatus) {
        case "REQUESTED":
            statusColor = theme.colors.secondary3;
            statusMsg = "Sent for Verification";
            break
        case "INREVIEW":
            statusColor = theme.colors.secondary1;
            statusMsg = "In Review";
            break;
        case "VALIDATED":
        case "ELIGIBLE_FOR_ADVANCE":
            statusColor = theme.colors.primary;
            statusMsg = "";
            break;
        case "REJECTED":
            statusColor = "red";
            statusMsg = "Rejected";
            break;
        case "INELIGIBLE_FOR_ADVANCE":
            statusColor = "red";
            statusMsg = "Ineligible for Advance";
            break;
        case "ADVANCED":
            statusColor = theme.colors.primary;
            statusMsg = "Advanced";
            break;
        default:
            break
    }
    if ((invoice.CreditStatus != "ADVANCED") && (moment(invoice.dueDate).diff(moment()) < 0)) {
        statusColor = theme.colors.secondary7;
        statusMsg = "Expired";
    }
    return statusMsg ? <Tooltip title="Credit status"><Tag height="32px">{statusMsg}</Tag></Tooltip> : null
}

class Index extends Component {

    constructor(props) {
        super(props)

        var fileList = []

        if (this.props.location.state && this.props.location.state.invoice) {
            fileList = this.props.location.state.invoice.attachments
            fileList.forEach((item, index) => {
                item.uid = index
                item.name = item.fileName
                item.url = item.uri
            })
        }

        this.state = {
            loading: false,
            invoice: this.props.location.state && this.props.location.state.invoice || {},
            balance: this.props.location.state && this.props.location.state.balance || 0,
            scheduleTypeItems: ["Once", "Recurring"],
            schedulePeriodTypeItems: ["Weekly", "BiWeekly", "Monthly", "Quarterly"],
            showScheduleInfo: (this.props.invoice || {}).scheduleId,
            editorState: EditorState.createEmpty(),
            selectedTab: "email",
            toEmails: [],
            viewDetails: false,
            ccEmails: [],
            step: 0,
            steps: ['Invoice Details', 'Send Invoice'],
            fileList: fileList,
            emailTone: 'Formal',
            invoiceDoc: (this.props.location.state?.invoice?.attachments || []).find(item => item.type == "InvoiceDoc") || {},
            tab: this.props.location.state?.payments ? 'payments' : 'invoice',
        }
    }

    componentDidMount() {
        var { theme, aionStore } = this.props
        const { invoice } = this.state
        const pathname = this.props.location.pathname;
        const segments = pathname.split('/');
        const lastSegment = segments.pop() || segments.pop();
        const businessInfo = this.props.aionStore?.business?.businessProfile?.businessInfo;
        const userInfo = this.props.aionStore?.userInfo;

        const attributes = aionStore.Attributes || {};
        const businessAttributes = attributes.Businesses || {};

        this.setState({ ccAddr: userInfo.email, reminderTeamName: businessInfo.name })

        this.setState({ subject: `${businessInfo?.name}: Invoice # ${this.props.location.state.invoice?.docNumber}` })
        console.log("CreateInvoice invoice this.props.aionStore", this.props.aionStore)

        this.fetchTemplates()
        this.fetchProducts({
            pagination: {
                current: 1,
                pageSize: 500
            }
        })

        this.getCustomer(invoice.customerId)

        if ((this.state.invoice.lineItems || []).length === 0) this.addLineItemRow()

        this.updateTotals()

        if (lastSegment === 'resend') {
            this.handleFilePreview()
        }

        this.getInvoiceLogs(invoice)
        this.getInvoicePayments()

        if (invoice.voidedInvoices?.length > 0) this.getVoidedInvoices()

        const { invoiceStatus, docNumber, lastSynced } = invoice || {}

        const status = (invoiceStatus || '').toLowerCase()

        if (this.props.setTopNavContent) this.props.setTopNavContent(
            (<Flex start centerHorizontally gap="24px">
                {docNumber} <Tag
                    color={['paid', 'partiallypaid'].includes(status) && '#318F2F'}
                    background={['paid', 'partiallypaid'].includes(status) && '#F5F9F5'}
                >{status === 'partiallypaid' ? 'Partially Paid' : status === 'new' ? 'Draft' : status}</Tag>
                {
                    invoiceStatus !== 'VOIDED' && businessAttributes.ARAgreementSigned && invoice.creditStatus &&
                    <CreditStatusTag theme={theme} invoice={invoice} />
                }
                {
                    lastSynced &&
                    <Tooltip title={<FlexColumn fullWidth start style={{ margin: '8px' }}>
                        <Text color='white' size='14px'>{`Created on ${getCodatPlatformName(aionStore)},`}</Text>
                        <Text color='white' size='14px'>{`Last synced on ${moment.utc(lastSynced).tz(etTimeZone).format('ll')}`}</Text>
                    </FlexColumn>} color={'#030342'} placement='bottom'>
                        <Tag background='#F0F8F3' color='#6DB58B'><Image src={CheckGreen} /> Synced to {getCodatPlatformName(aionStore)}</Tag>
                    </Tooltip>
                }
            </Flex>)
        )
    }

    componentWillUnmount() {
        if (this.props.setTopNavContent) this.props.setTopNavContent(null)
    }

    fetchProducts(options) {
        const { pagination } = options
        // Fetch product list
        const body = {
            "size": pagination.pageSize,
            "page": pagination.current - 1
        }
        this.setState({ loadingProducts: true })
        apiPOSTReq(`${environment.iveBaseUrl}/ive/product/getActiveProducts`, {}, body, (err, resp) => {
            try {
                this.setState({ loadingProducts: false })
                const data = resp
                console.log("/getActiveProducts", data)
                if (data.result) this.setState({ activeProducts: data.products })
                else throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
            } catch (error) {
                console.log("ERRR activeProducts", error, err, resp)
            }
        })
    }

    fetchCustomerPO = (selectedCustomer) => {
        const body = {
            "BusinessId": this.props.aionStore.BusinessUniqueKey,
            "CustomerId": selectedCustomer.customerId,
        }

        apiPOSTReq(`${environment.iveBaseUrl}/ive/po/getPOsForCustomer`, this.iveHeaders, body, (err, resp) => {
            try {
                const data = resp
                console.log("CreateInvoice handleSelectPurchaseOrder /ive/po/getApprovedPOsForCustomer", (data))
                if (data.result) {
                    this.setState({ loadingPurchaseOrders: false, purchaseOrders: data.pos })
                } else {
                    throw Error("Could not fetch purchase orders.")
                }
            } catch (error) {
                console.log("ERRR", error.stack)
            }
        })
    }

    handleSelectPurchaseOrder = (value, option) => {
        var { invoice, purchaseOrders } = this.state
        invoice.ponumber = value
        this.setState({ invoice })

        const purchaseOrder = purchaseOrders.find(po => po.ponumber === value)
        const fileList = []

        if (purchaseOrder) {
            var { documentUrl } = purchaseOrder
            documentUrl.name = getFilenameFromUrl(documentUrl.uri)
            documentUrl.fileName = documentUrl.name
            documentUrl.uid = 0

            getPreSignedS3Url({ url: documentUrl.uri }, (err, preSignedUrl) => {
                documentUrl.url = preSignedUrl || ""
                fileList.push(documentUrl)
                this.setState({ fileList })
            })
        }
    }

    fetchTemplates() {
        this.setState({ loadingTemplates: true })
        apiPOSTReq(`${environment.iveBaseUrl}/ive/templates/getActiveTemplates`, {}, {}, (err, resp) => {
            try {
                const data = resp || {}
                console.log("/ive/templates/getActiveTemplates", data)
                if (data.result) {
                    var { templates } = data
                    templates.sort((a, b) => (a.default ? -1 : b.default ? 1 : 0));
                    this.setState({ templates, selectedTemplate: templates.find(template => template.default) })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getActiveTemplates", error, resp)
                ErrorAlert({ description: error.message })
            } finally {
                this.setState({ loadingTemplates: false })
            }
        })
    }

    getCustomer(customerId) {
        const body = {
            customerId
        }
        console.log("CreateInvoice invoice /getCustomer body", body)

        this.setState({ loadingCustomers: true })
        apiPOSTReq(`${environment.iveBaseUrl}/ive/bc/getCustomer`, {}, body, (err, resp) => {
            try {
                this.setState({ loadingCustomers: false })
                const data = resp
                console.log("CreateInvoice invoice /getCustomer", data)
                if (data.result) {
                    var selectedCustomer = data.customer

                    if (selectedCustomer) {
                        this.setState({
                            toEmails: this.getToEmails(selectedCustomer),
                            selectedCustomer,
                        })
                    }
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getActiveCustomers", error.stack, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    addLineItemRow = () => {
        const { invoice } = this.state;
        const itemId = invoice.lineItems?.length || 0;
        this.setState({
            invoice: {
                ...invoice,
                lineItems: [...(invoice.lineItems || []), { itemId, lineNum: itemId, unsavedItem: true }],
            },
        });
    }

    removeLineItemRow = (id) => {
        const { invoice } = this.state;
        invoice.lineItems = (invoice.lineItems || []).filter((_, index) => index !== id);
        this.setState({ invoice });
    }

    handleLineItemProductSelection = (value, key) => {
        const { invoice, activeProducts } = this.state;
        const selectedProduct = activeProducts.find(item => item.id === value);
        if (!selectedProduct) return;

        const { id, name, description, unitPrice } = selectedProduct;

        const updatedLineItems = (invoice.lineItems || []).map((item, index) => {
            if (index === key) {
                return {
                    ...item,
                    productId: id,
                    productName: name,
                    description,
                    unitPrice,
                    qty: item.qty || 1,
                    amount: (item.qty || 1) * unitPrice,
                    discount: 0
                };
            }
            return item;
        });

        if (updatedLineItems.length === key) {
            updatedLineItems.push({ itemId: key, productId: id, productName: name, description, unitPrice, qty: 1, amount: unitPrice, discount: 0 });
        }

        this.setState({ invoice: { ...invoice, lineItems: updatedLineItems } }, this.updateTotals);
    }

    getLineItemTotals = () => {
        const { lineItems = [], discount = 0, taxes = 0 } = this.state.invoice;

        const subTotal = lineItems.reduce((acc, item) => acc + parseFloat(item.amount || 0), 0);
        const parsedDiscount = parseFloat(discount);
        const parsedTaxes = parseFloat(taxes);

        return {
            subTotal,
            totalAmt: subTotal - parsedDiscount + parsedTaxes,
            discount: parsedDiscount,
        };
    }

    updateTotals = () => {
        var { invoice } = this.state
        var totals = this.getLineItemTotals()
        invoice.totalAmt = totals.totalAmt || 0
        invoice.balance = invoice.totalAmt
        invoice.subTotal = totals.subTotal || 0
        invoice.taxes = invoice.taxes || 0
        this.setState({ invoice })
    }

    handleSave = (saveAndPreview, callback) => {
        let { invoice, selectedCustomer } = this.state;
        let { templateName } = invoice;

        if (!selectedCustomer) {
            this.setState({ errorField: 'customerId', errorMessage: "Please select a customer." });
            return;
        }

        if (!invoice.termsId) {
            this.setState({ errorField: 'terms', errorMessage: 'Please select a payment term' });
            return;
        }

        invoice.lineItems = invoice.lineItems.filter(x => x.productId);
        ['txnDate', 'dueDate', 'shipDate'].forEach(dateField => {
            if (invoice[dateField]) invoice[dateField] = moment(invoice[dateField]).toDate();
        });

        const body = {
            Invoice: { ...invoice },
            ...(templateName !== "Default Template" && { templateName })
        };

        this.setState({
            loadingSave: !saveAndPreview,
            loadingPreview: saveAndPreview,
            errorField: '',
            errorMessage: '',
        });

        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/save`, {}, body, (err, resp) => {
            this.setState({ loadingSave: false, loadingPreview: false });

            if (resp?.result) {
                if (callback) callback()
                else {
                    message.success(`Invoice saved`);
                    if (resp.responseMessage && resp.responseMessage !== "Success") {
                        ErrorAlert({ description: resp.responseMessage });
                    }
                    this.setState({ invoice: resp.invoice });
                    if (saveAndPreview) this.handleFilePreview();
                }
            } else {
                const errorMsg = resp?.responseMessage || resp?.error || "Unknown error";
                ErrorAlert({ description: errorMsg });
            }
        });
    }

    handleFilePreview = () => {
        var { invoice } = this.state
        let { templateName } = invoice;

        const body = {
            InvoiceId: invoice.invoiceId,
            docTemplateName: templateName,
        }

        console.log("/ive/invoice/getInvoiceEmailBodyText", JSON.stringify(body))

        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/getInvoiceEmailBodyText`, {}, body, (err, resp) => {
            try {
                const data = resp
                console.log("POST success /ive/invoice/getInvoiceEmailBodyText", JSON.stringify(data))
                if (data.result) {
                    var invoiceDoc = (invoice.attachments || []).find(item => item.type == "InvoiceDoc") || {}
                    getPreSignedS3Url({ url: invoiceDoc.uri }, (err, preSignedUrl) => {
                        invoiceDoc.preSignedUrl = preSignedUrl || ""
                        this.setState({ invoiceDoc: invoiceDoc, invoice: invoice, showPreview: true, htmlMailtext: data.htmlMailtext, invoiceEmailCustomBody: data.invoiceEmailCustomBody, editorState: EditorState.createWithContent(stateFromHTML(data.invoiceEmailCustomBody)) })
                    })
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("ive/invoice/getInvoiceEmailBodyText", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    uploadProps = () => {
        return {
            action: `${environment.apiBaseUrl}/file/upload`,
            headers: {
                businesskey: this.props.aionStore.BusinessUniqueKey,
                jwt: this.props.aionStore.jwt
            },
            onChange: (info) => {
                const { invoice } = this.state
                const { fileList, event } = info
                var savedFile = {};
                fileList.forEach(file => {
                    console.log("CreateInvoice uploadProps file", file)
                    const { status, response, name, url } = file;
                    if (status === "done") {
                        savedFile = {
                            uid: fileList.length,
                            fileName: name,
                            uri: (response.UploadedUrls || [])[0],
                            url: (response.UploadedUrls || [])[0],
                            type: "OTHER",
                            objectType: "OTHER",
                            name,
                            mimeType: file.type
                        }

                        if (!file.uri && (response.UploadedUrls || []).length > 0) file.uri = (response.UploadedUrls || [])[0]
                        if (!file.url && (response.UploadedUrls || []).length > 0) file.url = (response.UploadedUrls || [])[0]

                        this.uploadInvoiceAttachments([file])
                    } else if (status === 'error') {
                        console.log("CreateInvoice uploadProps error", info)
                        message.error(`${info.file.name} file upload failed.`);
                    }
                });

                fileList.forEach(file => {
                    file.fileName = file.name
                })

                this.setState({ fileList: [...fileList], invoice, saveAttachments: true });
            },
            showUploadList: false,
            onRemove: (file) => {
                // this.handleDeleteDoc(doc);
            },
            onPreview: (file) => {
                getPreSignedS3Url({ url: file.uri }, (err, preSignedUrl) => saveAs(preSignedUrl, file.name))
            },
        }
    };

    uploadInvoiceAttachments = (attachments) => {
        const userInfo = this.props.aionStore?.userInfo;
        const { invoice, balance } = this.props.location.state
        this.setState({ uploadAttachmentLoading: true })

        var body = {
            attachments,
            invoiceId: invoice.invoiceId,
            customerId: invoice.customerId,
            modifiedByUserName: `${userInfo.firstName} ${userInfo.lastName}`,
        }

        console.log("Invoices invoice/uploadInvoiceAttachments body", invoice)

        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/uploadInvoiceAttachments`, null, body, (err, resp) => {
            try {
                const data = resp || {}

                console.log("Invoices invoice/uploadInvoiceAttachments", data)

                this.props.history.replace({
                    pathname: '/receivables/invoices/view',
                    state: { invoice: data.invoice, balance: balance }
                })
            } catch (error) {
                console.log("ERRR voidInvoice", error, err, resp)
                ErrorAlert({ description: error.message })
            } finally {
                this.setState({ uploadAttachmentLoading: false })
            }
        })
    }

    voidInvoice = (invoice) => {
        const userInfo = this.props.aionStore?.userInfo;
        this.setState({ voidLoading: true })

        var body = {
            invoiceId: invoice.invoiceId,
            modifiedByUserName: `${userInfo.firstName} ${userInfo.lastName}`,
        }

        console.log("Invoices invoice/voidInvoice body", invoice)

        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/voidInvoice`, null, body, (err, resp) => {
            try {
                const data = resp || {}

                console.log("Invoices invoice/voidInvoice", data)

                this.setState({ showVoidModal: false })
                message.success('Invoice voided!')
                window.history.back()
            } catch (error) {
                console.log("ERRR voidInvoice", error, err, resp)
                ErrorAlert({ description: error.message })
            } finally {
                this.setState({ voidLoading: false })
            }
        })
    }

    syncCodatInvoice = () => {
        this.setState({ syncNowLoading: true })

        var body = {
            invoice: this.state.invoice,
        }

        console.log("Invoices invoice/syncCodatInvoice body", body)

        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/syncCodatInvoice`, null, body, (err, resp) => {
            try {
                const data = resp || {}
                console.log("Invoices invoice/syncCodatInvoice", data)

                this.setState({ syncNowLoading: false })
                message.success('Syncing invoice...')
            } catch (error) {
                console.log("ERRR voidInvoice", error, err, resp)
                ErrorAlert({ description: error.message })
            } finally {
                this.setState({ syncNowLoading: false })
            }
        })
    }

    getInvoiceLogs = (invoice) => {
        var body = {
            docNumber: invoice.docNumber,
        }

        console.log("Invoices invoice/getInvoiceLogs body", body)

        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/getInvoiceLogs`, null, body, (err, resp) => {
            try {
                const data = resp || {}

                console.log("Invoices invoice/getInvoiceLogs", data)

                this.setState({ invoiceLog: data.invoiceLog })
            } catch (error) {
                console.log("ERRR getInvoiceLogs", error, err, resp)
                ErrorAlert({ description: error.message })
            } finally {
                // this.setState({ voidLoading: false })
            }
        })
    }

    handlePaymentReminder = () => {
        const userInfo = this.props.aionStore?.userInfo;
        const { invoice } = this.props.location.state
        var { ccAddr, reminderTeamName } = this.state
        var invoices = [invoice]
        this.setState({ loadingPaymentReminder: true })
        var body = {
            invoiceIdList: invoices.map(item => item.invoiceId),
            modifiedByUserName: `${userInfo.firstName} ${userInfo.lastName}`,
        }
        if (ccAddr) body["ccAddr"] = ccAddr
        if (reminderTeamName) body["reminderTeamName"] = reminderTeamName
        console.log("/ive/invoice/sendPaymentReminder", body)
        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/sendPaymentReminder`, {}, body, (err, resp) => {
            this.setState({ loadingPaymentReminder: false })
            try {
                const data = resp
                console.log("POST success /ive/invoice/sendPaymentReminder", data)
                if (data.result) {
                    this.setState({ showPaymentReminderModal: false, enablePaymentReminder: false, selectedRows: [], selectedRowKeys: [] }) // reset
                    message.success(`Payment reminder was sent`)
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("Err /ive/invoice/sendPaymentReminder", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    handleTabChange = (key) => {
        this.setState({
            tab: key,
        })
    }

    getInvoicePayments() {
        const { invoiceId } = this.props.location.state?.invoice

        this.setState({ loadingInvoicePayments: true })
        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/getInvoicePayments`, {}, { invoiceId }, (err, resp) => {
            try {
                const data = resp || {}
                console.log("/ive/invoice/getInvoicePayments", data)
                this.setState({ invoicePayments: data.invoicePayments })
            } catch (error) {
                console.log("ERRR getActiveTemplates", error, resp)
                ErrorAlert({ description: error.message })
            } finally {
                this.setState({ loadingInvoicePayments: false })
            }
        })
    }

    getVoidedInvoices() {
        const { invoiceId } = this.props.location.state?.invoice

        console.log("/ive/invoice/getVoidedInvoices invoice", this.props.location.state?.invoice)
        console.log("/ive/invoice/getVoidedInvoices body", { invoiceId })

        this.setState({ loadingInvoicePayments: true })
        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/getVoidedInvoices`, {}, { invoiceId }, (err, resp) => {
            try {
                const data = resp || {}
                console.log("/ive/invoice/getVoidedInvoices", data)
                this.setState({ voidedInvoiceList: data.voidedInvoiceList })
            } catch (error) {
                console.log("ERRR getActiveTemplates", error, resp)
                ErrorAlert({ description: error.message })
            } finally {
                this.setState({ loadingInvoicePayments: false })
            }
        })
    }

    getToEmails = (customer) => {
        if (!customer) return []

        if (customer.invoiceRecipientEmail && customer.invoiceRecipientEmail.length > 0) {
            return customer.invoiceRecipientEmail;
        } else if (customer.primaryEmailAddress && customer.primaryEmailAddress.address) {
            return [customer.primaryEmailAddress.address];
        } else {
            return [];
        }
    }

    render() {
        const { invoice, balance } = this.props.location.state
        var { theme, aionStore } = this.props
        const { voidLoading, editorState, selectedCustomer, subject, invoiceLog, invoiceLogVisible, loadingPaymentReminder, reminderTeamName,
            loadingInvoicePayments, showPreview, toEmails, ccEmails, showVoidModal, showPaymentReminderModal, ccAddr, invoiceDoc, showSentModal,
            showAddPayment, tab, invoicePayments, loadingVoided, voidedInvoiceList, uploadAttachmentLoading, loadingPreview, syncNowLoading } = this.state
        var { customerName, txnDate, dueDate, shipDate, docNumber, lineItems, attachments, invoiceStatus, templateName,
            memo, invoiceId, paidOnDate, createdSource, syncErrorMessage } = invoice
        lineItems = lineItems || []

        console.log("ViewInvoice invoice", invoice)

        const attributes = aionStore.Attributes || {};
        const businessAttributes = attributes.Businesses || {};

        const lineItemTotals = this.getLineItemTotals()

        if (txnDate && !isMoment(txnDate)) {
            txnDate = new Date(txnDate).toISOString()
            txnDate = txnDate.split("T")[0]
        }
        txnDate = txnDate && moment(txnDate)
        if (dueDate && !isMoment(dueDate)) {
            dueDate = new Date(dueDate).toISOString()
            dueDate = dueDate.split("T")[0]
        }
        dueDate = dueDate && moment(dueDate)

        if (shipDate) shipDate = moment(shipDate)

        // var invoiceDoc = (invoice.attachments || []).find(item => item.type == "InvoiceDoc") || {}

        const status = (invoiceStatus || '').toLowerCase()

        var columns = [
            {
                title: 'Date',
                dataIndex: 'paymentDate',
                key: 'paymentDate',
                render: date => getDateByTZ(date, null, "America/New_York"),
                width: '120pt'
            },
            {
                title: 'Payment Reference',
                dataIndex: 'privateNote',
                key: 'privateNote',
                render: (privateNote, payment) => privateNote || '--',
                // width: '30%'
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
                key: 'amount',
                render: amount => `$ ${toCurrency(amount)}`,
                align: 'right',
                width: '180px'
            },
            {
                title: 'Status',
                dataIndex: 'paymentStatus',
                key: 'paymentStatus',
                render: paymentStatus => <Tag>{paymentStatus}</Tag>,
                align: 'right',
                width: '140px'
            },
        ]

        var voidedInvoiceColumns = [
            {
                title: 'Invoice Date',
                dataIndex: 'txnDate',
                key: 'txnDate',
                render: (date, invoice) => {
                    if (date) {
                        date = new Date(date).toISOString();
                        date = date.split("T")[0];
                    }
                    return (moment(date).format('ll'))
                },
            },
            {
                title: 'Number',
                dataIndex: 'docNumber',
                key: 'docNumber',
                render: (docNumber, record) => {
                    // return v2BetaEnabled.manage ? <a style={{ textDecoration: "underline" }} onClick={() => {
                    //     this.props.history.push({
                    //         pathname: '/receivables/invoices/view',
                    //         state: { invoice: record, balance: record.balance }
                    //     })
                    // }
                    // }>{docNumber}</a> :
                    return <Text>{docNumber}</Text>
                },
            },
            {
                title: 'Due Date',
                dataIndex: 'dueDate',
                key: 'dueDate',
                render: (date, invoice) => {
                    if (date) {
                        date = new Date(date).toISOString();
                        date = date.split("T")[0];
                    }
                    return <Flex start centerHorizontally gap='12px'>
                        <Text>{moment(date).format('ll')}</Text>
                    </Flex>
                },
            },
            {
                title: 'Amount',
                dataIndex: 'totalAmt',
                key: 'totalAmt',
                render: amount => (formatter.format(amount)),
                align: 'right',
            },
            {
                title: 'Balance',
                dataIndex: 'balance',
                key: 'balance',
                render: balance => { return <Text weight='500'>{(formatter.format(balance))}</Text> },
                align: 'right',
            },
        ]

        console.log('ViewInvoice attachments', attachments)

        return (
            <FlexColumn start className='main-padding' fullWidth grow>
                <PageHeader
                    titleText={docNumber}
                    // titleText={<Flex start centerHorizontally gap="24px">
                    //     {docNumber} <Tag
                    //         color={['paid', 'partiallypaid'].includes(status) && '#318F2F'}
                    //         background={['paid', 'partiallypaid'].includes(status) && '#F5F9F5'}
                    //     >{status === 'partiallypaid' ? 'Partially Paid' : status === 'new' ? 'Draft' : status}</Tag>
                    //     {
                    //         invoiceStatus !== 'VOIDED' && businessAttributes.ARAgreementSigned && invoice.creditStatus &&
                    //         <CreditStatusTag theme={theme} invoice={invoice} />
                    //     }
                    //     {
                    //         isCodatUser(aionStore) &&
                    //         <Tag background='#F0F8F3' color='#6DB58B'>wefoihwef0ojw0efj</Tag>
                    //     }
                    // </Flex>}
                    marginBottom={24}
                    back
                />

                <Tabs
                    defaultActiveKey={tab}
                    size="large"
                    onClick={() => { }}
                    onChange={this.handleTabChange}
                    tabBarExtraContent={<Flex start centerHorizontally gap='24px'>
                        {
                            invoiceDoc?.uri &&
                            <ImageButton src={Download} onClick={() => {
                                var invoiceDoc = (attachments || []).find(item => item.type == "InvoiceDoc") || {}
                                getPreSignedS3Url({ url: invoiceDoc.uri }, (err, preSignedUrl) => {
                                    saveAs(preSignedUrl, `Invoice ${docNumber}`)
                                })
                            }} />
                        }
                        {
                            isCodatUser(aionStore) &&
                            <TextButton
                                rightIcon={<Image src={SyncNow} />}
                                text='Sync Now'
                                loading={syncNowLoading}
                                onClick={this.syncCodatInvoice}
                            />
                        }
                        {
                            ['new', 'draft'].includes(status) &&
                            <>
                                <ImageButton src={Edit} onClick={() => {
                                    this.props.history.replace({
                                        pathname: '/receivables/invoices/create',
                                        state: { invoice }
                                    })
                                }} />

                                <Button solid text='SEND' loading={loadingPreview} rightIcon={<Image src={Send} width='21px' height='21px' />} onClick={() => {
                                    var { invoice } = this.state
                                    var invAttachments = invoice.attachments
                                    var invoiceDoc = (invAttachments || []).find(item => item.type == "InvoiceDoc") || {}
                                    if (Object.keys(invoiceDoc).length === 0) {
                                        this.handleSave(true, false)
                                    }
                                    else {
                                        this.handleFilePreview()
                                    }
                                }} />
                            </>
                        }
                        {/* {
                            ['sent', 'sent via accounting software', 'partiallypaid'].includes(status) &&
                            <TextButton text='SEND REMINDER' rightIcon={<Image src={Reminder} />} onClick={() => this.setState({ showPaymentReminderModal: true })} />
                        } */}
                        {
                            ['sent', 'sent via accounting software', 'partiallypaid'].includes(status) &&
                            <Dropdown
                                placement='bottomRight'
                                trigger={['click']}
                                overlay={<Menu style={{ padding: 24, borderRadius: 8, zIndex: 1001 }} onClick={() => { }}>
                                    <FlexColumn gap='24px'>
                                        {
                                            ['sent', 'partiallypaid'].includes(status) &&
                                            <TextButton text='RESEND' rightIcon={<Image src={Send} />} onClick={() => this.handleFilePreview()} />
                                        }

                                        {
                                            (invoiceDoc?.uri && ['sent via accounting software'].includes(status)) &&
                                            <TextButton text='RESEND' rightIcon={<Image src={Send} />} onClick={() => this.handleFilePreview()} />
                                        }

                                        {
                                            ['sent', 'sent via accounting software'].includes(status) &&
                                            <TextButton text='REVISE' rightIcon={<Image src={Edit} />} onClick={() => {
                                                this.props.history.push({
                                                    pathname: '/receivables/invoices/revise',
                                                    state: { invoice }
                                                })
                                            }} />
                                        }

                                        {
                                            ['sent', 'partiallypaid', 'sent via accounting software'].includes(status) &&
                                            <TextButton text='RECORD A PAYMENT' rightIcon={<Image src={RecordPayment} />} onClick={() => {
                                                this.props.history.replace({
                                                    pathname: '/receivables/invoice-payments',
                                                    state: { invoice, customer: selectedCustomer, balance }
                                                })
                                            }} />
                                        }

                                        {
                                            ['sent', 'sent via accounting software'].includes(status) &&
                                            <TextButton text='VOID' rightIcon={<Image src={VoidButton} />} onClick={() => {
                                                this.setState({ showVoidModal: true })
                                            }} />
                                        }
                                    </FlexColumn>
                                </Menu>}
                            >
                                <Button dropdown text='MORE ACTIONS' />
                            </Dropdown>
                        }
                    </Flex>}
                >
                    <TabPane tab="Invoice" key="invoice"></TabPane>
                    {
                        invoice.voidedInvoices?.length > 0 &&
                        <TabPane tab="Previous Versions" key="previousVersions"></TabPane>
                    }
                    {
                        !['new', 'draft'].includes(status) &&
                        <TabPane tab="Payments" key="payments"></TabPane>
                    }
                    <TabPane tab="Attachments" key="attachments"></TabPane>
                </Tabs>

                <Flex between fullWidth style={{ marginBottom: 48 }} gap='24px' grow>
                    {
                        tab === 'invoice' &&
                        <ViewInvoice invoice={invoice} />
                    }

                    {
                        tab === 'previousVersions' &&
                        <CardContainer fullWidth margin='12px 0 0 0' fullHeight grow>
                            <Table
                                id="voided-table"
                                className="borderless-table"
                                loading={loadingVoided}
                                tableLayout='auto'
                                rowKey={'transactionId'}
                                columns={voidedInvoiceColumns}
                                dataSource={voidedInvoiceList}
                                pagination={false}
                            />
                        </CardContainer>
                    }

                    {
                        tab === 'payments' &&
                        <FlexColumn fullWidth style={{ marginTop: 24 }}>
                            <TableView
                                id="invoice-payments-table"
                                titleText="Recorded payments"
                                descText="Detailed information of the invoice payments"
                                loading={loadingInvoicePayments}
                                tableLayout='auto'
                                rowKey={'transactionId'}
                                columns={columns}
                                dataSource={invoicePayments}
                                pagination={false}
                                ctaContent={
                                    ['sent', 'partiallypaid', 'sent via accounting software'].includes(status) &&
                                    <TextButton text='RECORD A PAYMENT' rightIcon={<Image src={RecordPayment} />} onClick={() => {
                                        this.props.history.replace({
                                            pathname: '/receivables/invoice-payments',
                                            state: { invoice, customer: selectedCustomer, balance }
                                        })
                                    }} />
                                }
                            />
                        </FlexColumn>
                    }

                    {
                        tab === 'attachments' &&
                        <CardContainer fullWidth margin='12px 0 0 0' fullHeight grow minWidth='800px'>
                            <FlexColumn start gap='24px'>
                                <Flex between centerHorizontally>
                                    <Text heading>Attachments</Text>
                                    <LabeledInput
                                        nomargin
                                        {...this.uploadProps()}
                                        type='upload'
                                        labelText={<Text primary weight='600'>UPLOAD ATTACHMENT</Text>}
                                        noAsterisk
                                        width={190}
                                        loading={uploadAttachmentLoading}
                                        end
                                    />
                                </Flex>
                                {
                                    attachments?.length > 0 ?
                                        <FlexColumn start gap='8px'>
                                            {
                                                attachments.map(attachment => {
                                                    return <LightContainer fullWidth padding='12px 24px'>
                                                        <Flex between fullWidth>
                                                            <Flex start>
                                                                <a style={{ textDecoration: "underline" }} onClick={() => getPreSignedS3Url({ url: attachment.uri }, (err, preSignedUrl) => saveAs(preSignedUrl, attachment.fileName))
                                                                }>{attachment.fileName}</a>
                                                            </Flex>
                                                            {

                                                                (attachment.uploadDate || attachment.sentDate) &&
                                                                <Flex start gap='8px' centerHorizontally>
                                                                    {
                                                                        attachment.sentDate &&
                                                                        <Image src={Emailed} />
                                                                    }
                                                                    <Text lightText size='12px'>{attachment.sentDate ? `Emailed on ${moment.utc(attachment.sentDate).format('ll')}` : `Uploaded on ${moment.utc(attachment.uploadDate).format('ll')}`}</Text>
                                                                </Flex>
                                                            }
                                                        </Flex>
                                                    </LightContainer>
                                                })
                                            }
                                        </FlexColumn>
                                        :
                                        <Flex fullWidth center style={{ height: 400 }}>
                                            <Text heading>No Data</Text>
                                        </Flex>
                                }
                            </FlexColumn>
                        </CardContainer>
                    }

                    <Affix offsetTop={0}>
                        <FlexColumn start gap='24px' style={{ marginTop: 24 }}>
                            {
                                status === 'paid' &&
                                <Flex center gap='8px' style={{ height: 48, borderRadius: 8, background: 'var(--Solid-Green-100, #318F2F)', boxShadow: '0px 4px 8px 0px rgba(102, 102, 102, 0.08)' }}>
                                    <Image src={Check} />
                                    <Text color='white'>Invoice paid{paidOnDate ? ` on ${getDateInLocalTZ(paidOnDate)}` : ''}</Text>
                                </Flex>
                            }
                            <CardContainer width='296px'>
                                <FlexColumn start gap='24px'>
                                    <Flex between fullWidth centerHorizontally>
                                        <Text heading>{'Summary'}</Text>
                                        {/* <Tag primary>Unsent</Tag> */}
                                    </Flex>
                                    {
                                        (invoice && selectedCustomer) ?
                                            <>
                                                <LabeledInput
                                                    nomargin
                                                    type="read-only"
                                                    label="Customer"
                                                    value={customerName}
                                                />

                                                <LabeledInput
                                                    nomargin
                                                    type="read-only"
                                                    label="Contact name"
                                                    value={selectedCustomer.fullyQualifiedName}
                                                />

                                                <LabeledInput
                                                    nomargin
                                                    type="read-only"
                                                    label="Invoice Amount"
                                                    value={`$ ${toCurrency(lineItemTotals.totalAmt)}`}
                                                />

                                                {
                                                    <LabeledInput
                                                        nomargin
                                                        type="read-only"
                                                        label="Balance"
                                                        value={`$ ${toCurrency(balance)}`}
                                                    />
                                                }

                                                <LabeledInput
                                                    nomargin
                                                    type="read-only"
                                                    label="Due Date"
                                                    value={dueDate && dueDate.format('ll')}
                                                />

                                                {
                                                    memo &&
                                                    <LabeledInput
                                                        nomargin
                                                        type="read-only"
                                                        label="Internal note"
                                                        value={memo}
                                                    />
                                                }

                                                {
                                                    (invoiceLog?.invoiceActions || []).length > 0 &&
                                                    <TextButton
                                                        underline
                                                        onClick={() => this.setState({ invoiceLogVisible: true })}
                                                        weight='400'
                                                        text='View invoice history'
                                                        rightIcon={<Image src={History} />}
                                                    />
                                                }
                                            </>
                                            :
                                            <FlexColumn center gap="8px">
                                                <Image src={Info} />
                                                <Text center>Start creating your invoice by choosing the customer</Text>
                                            </FlexColumn>
                                    }
                                </FlexColumn>
                            </CardContainer>

                            {
                                syncErrorMessage?.length > 0 &&
                                <Flex start width='296px' gap='12px'>
                                    <Image src={InfoError} />
                                    <Text error>{syncErrorMessage[0]?.value}</Text>
                                </Flex>
                            }
                        </FlexColumn>
                    </Affix>
                </Flex>

                <SendInvoiceDrawer
                    toEmails={toEmails}
                    ccEmails={ccEmails}
                    subject={subject}
                    editorState={editorState}
                    invoice={invoice}
                    visible={showPreview}
                    previewUrl={invoiceDoc.preSignedUrl}
                    onClose={() => this.setState({ showPreview: false })}
                    handleSave={this.handleSave}
                    templateName={templateName}
                    onSuccess={(toEmails, ccEmails) => this.setState({ showSentModal: true, toEmails, ccEmails })}
                />

                <Modal
                    visible={showVoidModal}
                    footer={null}
                    closable={false}
                    width={456}
                    destroyOnClose={true}
                    onCancel={() => {
                        this.setState({ showVoidModal: false })
                    }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <Void
                        loading={voidLoading}
                        invoice={invoice}
                        onCancel={() => this.setState({ showVoidModal: false })}
                        onVoid={() => {
                            this.voidInvoice(invoice)
                        }}
                    />
                </Modal>

                <HistoryDrawer
                    invoice={invoice}
                    onClose={() => this.setState({ invoiceLogVisible: false })}
                    visible={invoiceLogVisible}
                    invoiceLog={invoiceLog} />

                <Drawer
                    visible={showAddPayment}
                    placement="right"
                    closable={true}
                    onClose={() => this.setState({ showAddPayment: false })}
                    maskClosable={false}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                    width='444px'
                    destroyOnClose={true}
                >
                    <PaymentDrawer
                        invoiceId={invoiceId}
                        docNumber={docNumber}
                        balance={balance}
                        onSave={(newBalance) => {
                            invoice.balance = newBalance
                            if (Number(newBalance) === 0) invoice.invoiceStatus = 'paid'
                            this.getInvoicePayments()
                            this.getInvoiceLogs(invoice)
                            this.setState({ showAddPayment: false })
                            this.props.history.replace({
                                pathname: '/receivables/invoices/view',
                                state: { invoice, balance: newBalance, payments: true, }
                            })
                        }}
                    />
                </Drawer>

                <Modal
                    visible={showPaymentReminderModal}
                    footer={null}
                    closable={true}
                    width={500}
                    destroyOnClose={true}
                    style={{ top: 20 }}
                    onCancel={() => { this.setState({ showPaymentReminderModal: false }) }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <FlexColumn>
                        <Text size='20px'>Payment reminder</Text>

                        <LabeledInput
                            label="Email CC"
                            labelcolor={theme.colors.secondary3}
                            id="ccAddr"
                            key="ccAddr"
                            placeholder="Enter an email address"
                            value={ccAddr}
                            onChange={(e) => this.setState({ [e.target.id]: e.target.value })}
                            instruction="This email will receive a copy of the payment reminder"
                        />
                        <LabeledInput
                            label="From Name"
                            labelcolor={theme.colors.secondary3}
                            id="reminderTeamName"
                            key="reminderTeamName"
                            placeholder="Enter a name"
                            value={reminderTeamName}
                            onChange={(e) => this.setState({ [e.target.id]: e.target.value })}
                            instruction="This name will show as the from name on the email"
                        />
                        <Button solid width='150px' permtype="Override" margin='20px 0 0' loading={loadingPaymentReminder} onClick={this.handlePaymentReminder} text="Send Reminder" />
                    </FlexColumn>
                </Modal>

                <AlertModal
                    success
                    visible={showSentModal}
                    title='Invoice Sent'
                    description={`Your invoice ${invoice?.docNumber} has been sent to ${invoice.customerName} (${toEmails.join(', ')}) ${(ccEmails && ccEmails.length > 0) ? ` and CC'd to ${ccEmails.join(', ')}` : ''}`}
                    buttonTitle='OKAY'
                    onConfirm={() => this.props.history.replace({
                        pathname: '/receivables/invoices',
                        state: { tab: 'unpaid' }
                    })}
                />
            </FlexColumn >
        )
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer,
        receivablesStore: state.receivablesAppReducer,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)))