import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useHistory } from "react-router-dom";
import { Dropdown, Menu, Tabs, Select, Tooltip } from 'antd'
import { saveAs } from 'file-saver'

import { Text, Tag } from './Text'
import { Flex, FlexColumn } from './Container'
import { Image } from './Image'
import { TextButton, Button, Chip, ImageButton } from './Button'
import { LabeledInput } from './Input'
import { BasicOption } from './Option'
import { ErrorAlert } from './Alert'
import ApprovalProgress from '../../Images/approval-progress.png'
import ApprovalProgressDone from '../../Images/approval-progress-done.png'
import ApprovalProgressNext from '../../Images/approval-progress-next.png'
import ApprovalDivider from '../../Images/approval-divider.png'
import { apiPOSTReq, getPreSignedS3Url, apiGETDocUrl } from '../../Utils/api'
import FilterIcon from '../../Images/filter.png'
import moment from 'moment'
import environment from '../../environment'
import { withTheme } from 'styled-components'

import More from "../../Images/more.png"
import Download from "../../Images/download.png"
import ArrowRight from "../../Images/arrow-right.png"
import ArrowUp from "../../Images/arrow-up.png"
import Info from '../../Images/info.png'
import Search from '../../Images/search.png'
import { debounce } from 'lodash';

const { TabPane } = Tabs

const { Option } = Select

const dateFormat = "YYYY-MM-DD";

const statusProcessing = ['pending', 'created', 'processing', 'Pending', 'Created', 'Processing']
const statusPendingApproval = ['pending_approval']
const statusCompleted = ['sent', 'complete', 'completed', 'Sent', 'Complete', 'Completed']
const statusError = ['error']
const statusRejected = ['Rejected', 'denied_approval']

const Filter = ({ sharedSearchTerm, setSharedSearchTerm, account, paymentErrorCodes, initialStatus, initialFromDate, ...props }) => {
    const [dropdownVisible, setDropdownVisible] = useState(false)
    const [tab, setTab] = useState('activityDate')
    const [fromDate, setFromDate] = useState(initialFromDate ? moment(initialFromDate) : null)
    const [toDate, setToDate] = useState()
    const [filterFromDate, setFilterFromDate] = useState(initialFromDate ? `${initialFromDate}` : null)
    const [filterToDate, setFilterToDate] = useState()
    const [fromAmount, setFromAmount] = useState()
    const [toAmount, setToAmount] = useState()
    const [filterFromAmount, setFilterFromAmount] = useState()
    const [filterToAmount, setFilterToAmount] = useState()
    const [achSelected, setACHSelected] = useState()
    const [wireSelected, setWireSelected] = useState()
    const [checkSelected, setCheckSelected] = useState()
    const [cardSelected, setCardSelected] = useState()
    const [internalSelected, setInternalSelected] = useState()
    const [incomingSelected, setIncomingSelected] = useState()
    const [outgoingSelected, setOutgoingSelected] = useState()
    const [returnsSelected, setReturnsSelected] = useState()
    const [reversedSelected, setReversedSelected] = useState()
    const [selectValue, setSelectValue] = useState('Description')
    const [searchTerm, setSearchTerm] = useState()
    const [moreDropdownVisible, setMoreDropdownVisible] = useState(false)
    const [downloadDropdownVisible, setDownloadDropdownVisible] = useState(false)
    const [downloadFromDate, setDownloadFromDate] = useState()
    const [downloadToDate, setDownloadToDate] = useState()
    const [downloadType, setDownloadType] = useState('CSV')
    const [downloading, setDownloading] = useState(false)
    const [error, setError] = useState()
    const [toAmtError, setToAmtError] = useState()
    const [toDateError, setToDateError] = useState()
    const [errorCodes, setErrorCodes] = useState(paymentErrorCodes)
    const [returnCodeSearchTerm, setReturnCodeSearchTerm] = useState('')
    const [status, setStatus] = useState(initialStatus)
    const [sent, setSent] = useState()
    const [received, setReceived] = useState()
    const [changeCounter, setChangeCounter] = useState(0) // This is purely used to trigger onChange handler. With this onChange will have the latest variables after the useEffect on changeCounter is completed

    const history = useHistory()

    const mounted = useRef(false);

    useEffect(() => {
        if (mounted.current) {
            handleChange();
        } else {
            mounted.current = true;
        }
    }, [
        account, selectValue, searchTerm, filterFromDate, filterToDate,
        achSelected, wireSelected, checkSelected, internalSelected, cardSelected,
        filterFromAmount, filterToAmount, incomingSelected, outgoingSelected,
        returnsSelected, reversedSelected, error, status, sent, received, changeCounter
    ]);

    const { margin, onChange, more, download, customFilterComp, title, accountIndex, tooltip, rail, transactionType, dateName, placeholder, showStatusFilter, searchWidth, noCard, pageSize, showErrorFilters } = props

    const onTabChange = tab => {
        setTab(tab)
    }

    const handleChange = () => {
        setDropdownVisible(false)

        const railList = []

        if (achSelected) railList.push('ACH')
        if (wireSelected) railList.push('Wire')
        if (checkSelected) railList.push('Check')
        if (internalSelected) railList.push('Internal')
        if (cardSelected) railList.push('Card')

        var toAmt = 2147483647

        if (filterToAmount) toAmt = filterToAmount
        var searchStr = searchTerm && searchTerm

        console.log("SearchAndFilter handleChange fromDate", fromDate)

        const body = {
            pagination: { current: 1, pageSize: pageSize || 50 },
            fromDate: filterFromDate && moment(filterFromDate).format(dateFormat),
            toDate: filterToDate && moment(filterToDate).format(dateFormat),
            fromAmt: filterFromAmount && filterFromAmount,
            toAmt: (filterFromAmount || filterToAmount) && toAmt,
            railList: railList.length > 0 && railList,
            searchStr: searchStr,
            viewBy: (searchTerm && searchTerm !== '') && (selectValue === 'Description' ? 'SEARCH_DESCRIPTION' : 'SEARCH_AMT'),
            filterType: incomingSelected ? 'INCOMING_TXNS' : outgoingSelected ? 'OUTGOING_TXNS' : returnsSelected ? 'RETURNS' : reversedSelected ? 'REVERSED' : error ? 'REASON_CODE' : null,
            filterValue: error ? error.code : null,
            paymentStatus: (status && status.length > 0) ? status : null,
        }

        console.log("SearchAndFilter handleChange body", body)

        if (sent && !received) body.filterType = 'OUTGOING_TXNS'
        if (!sent && received) body.filterType = 'INCOMING_TXNS'
        onChange(body)
    }

    const showClearAll = () => {
        return searchTerm || filterFromDate || achSelected || wireSelected || checkSelected || internalSelected || (filterFromAmount && filterToAmount) || incomingSelected || outgoingSelected || returnsSelected || reversedSelected || (status && status.length > 0)
    }

    const downloadCSVStmt = () => {
        setDownloading(true)
        var query = {
            FromDate: fromDate.format('YYYY-MM-DD'),
            ToDate: toDate.format('YYYY-MM-DD'),
            AccountId: account.accountId
        }
        apiGETDocUrl(`${environment.bbBaseUrl}/bb/export/csv/statement`, {}, query, (err, resp) => {
            setDownloading(false)
            const data = (resp || {}).data;
            try {
                if (err) throw Error("We had trouble downloading your statement. Please try again.");
                const blob = new Blob([data], {
                    type: 'application/vnd.ms-excel',
                });
                const fileURL = URL.createObjectURL(blob);
                saveAs(fileURL, `Account transactions ${fromDate} to ${toDate}.csv`);
            } catch (error) {
                ErrorAlert({ description: error.message });
            }
        })
    }

    const downloadQFXStmt = () => {
        setDownloading(true)
        var query = {
            FromDate: fromDate.format('YYYY-MM-DD'),
            ToDate: toDate.format('YYYY-MM-DD'),
            AccountId: account.accountId
        }
        apiGETDocUrl(`${environment.bbBaseUrl}/bb/qfx/export/transactions`, {}, query, (err, resp) => {
            setDownloading(false)
            const data = (resp || {}).data;
            try {
                if (err) throw Error("We had trouble downloading your statement. Please try again.");
                const blob = new Blob([data], {
                    type: 'application/vnd.intu.qfx',
                });
                const fileURL = URL.createObjectURL(blob);
                saveAs(fileURL, `Account transactions ${fromDate} to ${toDate}.qfx`);
            } catch (error) {
                ErrorAlert({ description: error.message });
            }
        })
    }

    const moreTransactions = (type) => {
        history.push({
            pathname: `/banking/accounts/${type}-transactions`,
            state: { account, type, accountIndex }
        })
    }

    const clearAll = () => {
        setSearchTerm(null)
        setFilterFromDate(null)
        setFilterToDate(null)
        setFromDate(null)
        setToDate(null)
        setACHSelected(false)
        setWireSelected(false)
        setCheckSelected(false)
        setInternalSelected(false)
        setCardSelected(false)
        setFilterFromAmount(null)
        setFilterToAmount(null)
        setFromAmount(null)
        setToAmount(null)
        setIncomingSelected(false)
        setOutgoingSelected(false)
        setReturnsSelected(false)
        setReversedSelected(false)
        setStatus(null)
    }

    const handleDebounceFn = (searchStr) => {
        setChangeCounter(c => c + 1) // This is the way to update the variable since it's inside an async function
    }
    const debounceFn = useCallback(debounce(handleDebounceFn, 400), []) // wait 400ms before calling handleChange via setChangeCounter

    const handleAmtFilter = () => {
        if (toAmount < fromAmount) {
            setToAmtError('To amount should be greater than or equal to from amount')
            return
        }
        setFilterFromAmount(`${fromAmount}`)
        setFilterToAmount(`${toAmount}`)
    }

    const handleDateFilter = () => {
        if (toDate && (toDate < fromDate)) {
            setToDateError('To date should be greater than or equal from date')
            return
        }
        setFilterFromDate(`${fromDate}`)
        if (toDate) setFilterToDate(`${toDate}`)
    }

    const handleSearch = (val) => {
        setSearchTerm(val)
        debounceFn(val)
    }

    return <FlexColumn start fullWidth>

        {
            title &&
            <Flex start fullWidth gap='8px' centerHorizontally style={{ margin: '0 0 24px' }}>
                <Text heading color='black'>{title || 'Transactions'}</Text>
                {
                    tooltip &&
                    <Tooltip overlayInnerStyle={{ width: 350, minWidth: 350 }} overlayStyle={{ width: 370, minWidth: 370 }} overlayClassName='roles-tooltip' placement="right" arrowPointAtCenter={false} title={<FlexColumn>
                        <Text color='white' size='14px' noWrap>{tooltip}</Text>
                    </FlexColumn>}>
                        <FlexColumn>
                            <Image src={Info} />
                        </FlexColumn>
                    </Tooltip>
                }
            </Flex>
        }

        <Flex between centerHorizontally gap='24px'>

            {
                <LabeledInput
                    type='select-search'
                    nomargin
                    width={searchWidth || '240px'}
                    searchWidth={searchWidth || '240px'}
                    options={[
                        <Option value='Description' label='Description' style={{ backgroundColor: "transparent" }}>
                            <BasicOption value='Description' />
                        </Option>,
                        <Option value='Amount' label='Amount' style={{ backgroundColor: "transparent" }}>
                            <BasicOption value='Amount' />
                        </Option>,
                    ]}
                    selectValue={selectValue}
                    searchValue={searchTerm}
                    onSearchChange={e => handleSearch(e.target.value)}
                    onSelectChange={(value) => setSelectValue(value)}
                    placeholder={placeholder ? placeholder : `Search by ${selectValue.toLowerCase()}`}
                />
            }
            {
                (achSelected || wireSelected || checkSelected || internalSelected || cardSelected || filterFromDate ||
                    filterFromAmount || incomingSelected || outgoingSelected || error || returnsSelected || reversedSelected || status) &&
                <Flex between centerHorizontally style={{ margin: margin || '0px 0 0px' }}>
                    <Flex fullWidth start wrap gap='8px'>
                        { // hide search term for now
                            searchTerm && false &&
                            <Chip primary closable onDelete={() => {
                                setSearchTerm(null)
                                if (setSharedSearchTerm) setSharedSearchTerm(null)
                            }} text={`"${searchTerm}"`} />
                        }

                        {
                            filterFromDate && !filterToDate &&
                            <Chip primary closable onDelete={() => {
                                setFilterFromDate(null)
                                setFilterToDate(null)
                                setFromDate(null)
                                setToDate(null)
                            }} text={`From ${moment(filterFromDate).format('ll')}`} />
                        }

                        {
                            filterFromDate && filterToDate &&
                            <Chip primary closable onDelete={() => {
                                setFilterFromDate(null)
                                setFilterToDate(null)
                                setFromDate(null)
                                setToDate(null)
                            }} text={`${moment(filterFromDate).format('ll')} - ${moment(filterToDate).format('ll')}`} />
                        }

                        {
                            achSelected &&
                            <Chip primary closable onDelete={() => {
                                setACHSelected(false)
                            }} text='ACH' />
                        }

                        {
                            wireSelected &&
                            <Chip primary closable onDelete={() => {
                                setWireSelected(false)
                            }} text='Wire' />
                        }

                        {
                            checkSelected &&
                            <Chip primary closable onDelete={() => {
                                setCheckSelected(false)
                            }} text='Check' />
                        }

                        {
                            internalSelected &&
                            <Chip primary closable onDelete={() => {
                                setInternalSelected(false)
                            }} text='Internal' />
                        }

                        {
                            cardSelected &&
                            <Chip primary closable onDelete={() => {
                                setCardSelected(false)
                            }} text='Card' />
                        }

                        {
                            incomingSelected &&
                            <Chip primary closable onDelete={() => {
                                setIncomingSelected(false)
                            }} text='Incoming' />
                        }

                        {
                            outgoingSelected &&
                            <Chip primary closable onDelete={() => {
                                setOutgoingSelected(false)
                            }} text='Outgoing' />
                        }

                        {
                            returnsSelected &&
                            <Chip primary closable onDelete={() => {
                                setReturnsSelected(false)
                            }} text='Returns' />
                        }

                        {
                            reversedSelected &&
                            <Chip primary closable onDelete={() => {
                                setReversedSelected(false)
                            }} text='Reversals' />
                        }

                        {
                            filterFromAmount && !filterToAmount &&
                            <Chip primary closable onDelete={() => {
                                setFilterFromAmount(null)
                                setFilterToAmount(null)
                                setFromAmount(null)
                                setToAmount(null)
                            }} text={`$${filterFromAmount}+`} />
                        }

                        {
                            filterFromAmount && filterToAmount &&
                            <Chip primary closable onDelete={() => {
                                setFilterFromAmount(null)
                                setFilterToAmount(null)
                                setFromAmount(null)
                                setToAmount(null)
                            }} text={`$${filterFromAmount} - $${filterToAmount}`} />
                        }

                        {
                            error &&
                            <Chip primary closable onDelete={() => {
                                setError(null)
                            }} text={`Return Code: "${error.code}"`} />
                        }

                        {
                            statusProcessing.every(item => (status || []).includes(item)) &&
                            <Chip text={'Processing'} primary closable
                                onDelete={() => setStatus((status || []).filter(item => !statusProcessing.includes(item)))}
                            />
                        }

                        {
                            statusPendingApproval.every(item => (status || []).includes(item)) &&
                            <Chip text={'Pending Approval'} primary closable
                                onDelete={() => setStatus((status || []).filter(item => !statusPendingApproval.includes(item)))}
                            />
                        }

                        {
                            (statusCompleted.every(item => (status || []).includes(item)) && sent) &&
                            <Chip text={'Sent'} primary closable
                                onDelete={() => {
                                    setSent(false)
                                    if (!received) setStatus((status || []).filter(item => !statusCompleted.includes(item)))
                                }}
                            />
                        }

                        {
                            (statusCompleted.every(item => (status || []).includes(item)) && received) &&
                            <Chip text={'Received'} primary closable
                                onDelete={() => {
                                    setReceived(false)
                                    if (!sent) setStatus((status || []).filter(item => !statusCompleted.includes(item)))
                                }}
                            />
                        }

                        {
                            statusError.every(item => (status || []).includes(item)) &&
                            <Chip text={'Error'} primary closable
                                onDelete={() => setStatus((status || []).filter(item => !statusError.includes(item)))}
                            />
                        }

                        {
                            statusRejected.every(item => (status || []).includes(item)) &&
                            <Chip text={'Rejected'} primary closable
                                onDelete={() => setStatus((status || []).filter(item => !statusRejected.includes(item)))}
                            />
                        }
                    </Flex>
                </Flex>
            }
            <Flex gap='12px' centerHorizontally>
                <Flex start centerHorizontally>
                    {
                        showClearAll() && <>
                            <Text primary noWrap underline style={{ cursor: 'pointer' }} onClick={clearAll}>Clear all</Text>
                            <Image src={ApprovalDivider} />
                        </>
                    }
                    <Dropdown
                        placement='bottomRight'
                        trigger={['click']}
                        onVisibleChange={flag => setDropdownVisible(flag)}
                        visible={dropdownVisible}
                        overlay={(
                            <Menu onClick={() => { }} style={{ width: showErrorFilters ? 612 : 528, padding: '12px 24px 24px', borderRadius: 8 }}>
                                <Tabs defaultActiveKey="2" onClick={() => { }} onChange={onTabChange}>
                                    <TabPane tab={dateName ? dateName : "Transaction Date"} key="activityDate">
                                        <FlexColumn left gap='24px'>
                                            <Flex between gap='24px'>
                                                <LabeledInput
                                                    label="From"
                                                    type="date-picker"
                                                    // disabledDate={this.disabledDate}
                                                    placeholder='MM/DD/YYYY'
                                                    value={(fromDate && fromDate !== null) && fromDate}
                                                    format={'MM/DD/YYYY'}
                                                    onChange={(date, dateStr) => setFromDate(date)}
                                                    allowClear
                                                />
                                                <LabeledInput
                                                    optional
                                                    label="To"
                                                    type="date-picker"
                                                    // disabledDate={this.disabledDate}
                                                    placeholder='MM/DD/YYYY'
                                                    value={toDate}
                                                    format={'MM/DD/YYYY'}
                                                    onChange={(date, dateStr) => {
                                                        setToDateError(null)
                                                        setToDate(date)
                                                    }}
                                                    allowClear
                                                    error={toDateError}
                                                    errorMessage={toDateError}
                                                />
                                            </Flex>
                                            <Button solid disabled={!fromDate} text='APPLY' onClick={() => handleDateFilter()} />
                                        </FlexColumn>
                                    </TabPane>
                                    {
                                        !rail &&
                                        <TabPane tab="Type" key="type">
                                            <Flex start wrap gap='8px'>
                                                <Chip onAdd={() => setACHSelected(true)} onDelete={() => setACHSelected(false)} selected={achSelected} add closable text='ACH' background='white' />
                                                <Chip onAdd={() => setWireSelected(true)} onDelete={() => setWireSelected(false)} selected={wireSelected} add closable text='Wire' background='white' />
                                                <Chip onAdd={() => setCheckSelected(true)} onDelete={() => setCheckSelected(false)} selected={checkSelected} add closable text='Check' background='white' />
                                                <Chip onAdd={() => setInternalSelected(true)} onDelete={() => setInternalSelected(false)} selected={internalSelected} add closable text='Internal' background='white' />
                                                {!noCard && <Chip onAdd={() => setCardSelected(true)} onDelete={() => setCardSelected(false)} selected={cardSelected} add closable text='Card' background='white' />}
                                            </Flex>
                                        </TabPane>
                                    }
                                    {
                                        transactionType &&
                                        <TabPane tab="Type" key="type">
                                            <Flex start wrap gap='8px'>
                                                <Chip onAdd={() => {
                                                    if (outgoingSelected === true) setOutgoingSelected(false)
                                                    if (returnsSelected === true) setReturnsSelected(false)
                                                    if (reversedSelected === true) setReversedSelected(false)
                                                    setIncomingSelected(true)
                                                }} onDelete={() => setIncomingSelected(false)} selected={incomingSelected} add closable text='Incoming' background='white' />
                                                <Chip onAdd={() => {
                                                    if (incomingSelected === true) setIncomingSelected(false)
                                                    if (returnsSelected === true) setReturnsSelected(false)
                                                    if (reversedSelected === true) setReversedSelected(false)
                                                    setOutgoingSelected(true)
                                                }} onDelete={() => setOutgoingSelected(false)} selected={outgoingSelected} add closable text='Outgoing' background='white' />
                                                {
                                                    rail === 'ACH' &&
                                                    <Chip onAdd={() => {
                                                        if (outgoingSelected === true) setOutgoingSelected(false)
                                                        if (incomingSelected === true) setIncomingSelected(false)
                                                        if (reversedSelected === true) setReversedSelected(false)
                                                        setReturnsSelected(true)
                                                    }} onDelete={() => setReturnsSelected(false)} selected={returnsSelected} add closable text='Returns' background='white' />
                                                }
                                                {
                                                    rail === 'Wire' &&
                                                    <Chip onAdd={() => {
                                                        if (outgoingSelected === true) setOutgoingSelected(false)
                                                        if (incomingSelected === true) setIncomingSelected(false)
                                                        if (returnsSelected === true) setReturnsSelected(false)
                                                        setReversedSelected(true)
                                                    }} onDelete={() => setReversedSelected(false)} selected={reversedSelected} add closable text='Reversals' background='white' />
                                                }
                                            </Flex>
                                        </TabPane>
                                    }
                                    {
                                        showStatusFilter &&
                                        <TabPane tab="Status" key="status">
                                            <Flex start wrap gap='8px'>
                                                <Chip
                                                    onAdd={() => setStatus([...new Set([...(status || []), ...statusProcessing])])}
                                                    onDelete={() => setStatus((status || []).filter(item => !statusProcessing.includes(item)))}
                                                    selected={statusProcessing.every(item => (status || []).includes(item))}
                                                    add
                                                    closable
                                                    text='Processing'
                                                    background='white'
                                                />
                                                <Chip
                                                    onAdd={() => setStatus([...new Set([...(status || []), ...statusPendingApproval])])}
                                                    onDelete={() => setStatus((status || []).filter(item => !statusPendingApproval.includes(item)))}
                                                    selected={statusPendingApproval.every(item => (status || []).includes(item))}
                                                    add
                                                    closable
                                                    text='Pending Approval'
                                                    background='white'
                                                />
                                                <Chip
                                                    onAdd={() => {
                                                        setSent(true)
                                                        setStatus([...new Set([...(status || []), ...statusCompleted])])
                                                    }}
                                                    onDelete={() => {
                                                        setSent(false)
                                                        if (!received) setStatus((status || []).filter(item => !statusCompleted.includes(item)))
                                                    }}
                                                    selected={sent && statusCompleted.every(item => (status || []).includes(item))}
                                                    add
                                                    closable
                                                    text='Sent'
                                                    background='white'
                                                />
                                                <Chip
                                                    onAdd={() => {
                                                        setReceived(true)
                                                        setStatus([...new Set([...(status || []), ...statusCompleted])])
                                                    }}
                                                    onDelete={() => {
                                                        setReceived(false)
                                                        if (!sent) setStatus((status || []).filter(item => !statusCompleted.includes(item)))
                                                    }}
                                                    selected={received && statusCompleted.every(item => (status || []).includes(item))}
                                                    add
                                                    closable
                                                    text='Received'
                                                    background='white'
                                                />
                                                {
                                                    showErrorFilters &&
                                                    <>
                                                        <Chip
                                                            onAdd={() => setStatus([...new Set([...(status || []), ...statusError])])}
                                                            onDelete={() => setStatus((status || []).filter(item => !statusError.includes(item)))}
                                                            selected={statusError.every(item => (status || []).includes(item))}
                                                            add
                                                            closable
                                                            text='Error'
                                                            background='white'
                                                        />
                                                        <Chip
                                                            onAdd={() => setStatus([...new Set([...(status || []), ...statusRejected])])}
                                                            onDelete={() => setStatus((status || []).filter(item => !statusRejected.includes(item)))}
                                                            selected={statusRejected.every(item => (status || []).includes(item))}
                                                            add
                                                            closable
                                                            text='Rejected'
                                                            background='white'
                                                        />
                                                    </>
                                                }
                                            </Flex>
                                        </TabPane>
                                    }
                                    <TabPane tab="Transaction Amount" key="amount">
                                        <FlexColumn left gap='24px'>
                                            <Flex between gap='24px'>
                                                <LabeledInput
                                                    // type="number"
                                                    label="From"
                                                    id="fromAmount"
                                                    key="fromAmount"
                                                    prefix="$"
                                                    placeholder="100"
                                                    min={0}
                                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    value={fromAmount}
                                                    onChange={(e) => setFromAmount(!isNaN(parseFloat(e.target.value)) ? parseFloat(e.target.value) : null)}
                                                />
                                                <LabeledInput
                                                    // type="number"
                                                    label="To"
                                                    id="toAmount"
                                                    key="toAmount"
                                                    prefix="$"
                                                    placeholder="1000"
                                                    min={0}
                                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    value={toAmount}
                                                    onChange={(e) => {
                                                        setToAmtError(null)
                                                        setToAmount(!isNaN(parseFloat(e.target.value)) ? parseFloat(e.target.value) : null)
                                                    }}
                                                    error={toAmtError}
                                                    errorMessage={toAmtError}
                                                />
                                            </Flex>
                                            <Button solid disabled={!(typeof (fromAmount) == 'number' && typeof (toAmount) == 'number')} text='APPLY' onClick={() => handleAmtFilter()} />
                                        </FlexColumn>
                                    </TabPane>
                                    {
                                        errorCodes &&
                                        <TabPane tab="Return Code" key="returnCode">
                                            <LabeledInput
                                                label=""
                                                type="select"
                                                showSearch
                                                prefixIcon={<img width='12px' height='12px' src={Search} />}
                                                placeholder="Search by Return code or description"
                                                value={error && <><Text weight='500'>{error.code}</Text><Text> - {error.description}</Text></>}
                                                onChange={(val) => {
                                                    if (incomingSelected === true) setIncomingSelected(false)
                                                    if (outgoingSelected === true) setOutgoingSelected(false)
                                                    if (returnsSelected === true) setReturnsSelected(false)
                                                    if (reversedSelected === true) setReversedSelected(false)

                                                    setError(paymentErrorCodes.find(error => error.id === val))
                                                    setDropdownVisible(false)
                                                }}
                                                onSearch={(val) => {
                                                    if (val && val !== '') {
                                                        console.log('paymentErrorCodesonSearch', val, paymentErrorCodes.filter(error => error.code.includes(val)))
                                                        setErrorCodes(paymentErrorCodes.filter(error => error.code.includes(val)))
                                                    } else {
                                                        setErrorCodes(paymentErrorCodes)
                                                    }
                                                }}
                                                optionFilterProp='name'
                                            >
                                                {
                                                    paymentErrorCodes.map(error => (
                                                        <Option key={error.id} id={error.id} value={error.id} name={`${error.code} - ${error.description}`}>
                                                            <Flex start style={{ height: 40 }} centerHorizontally>
                                                                <Text weight='500'>{error.code}</Text><Text> - {error.description}</Text>
                                                            </Flex>
                                                        </Option>)
                                                    )
                                                }
                                            </LabeledInput>
                                        </TabPane>
                                    }
                                </Tabs>
                            </Menu>
                        )}>
                        <TextButton text='FILTER' rightIcon={<Image src={FilterIcon} />} />
                    </Dropdown>
                </Flex>
                {
                    download &&
                    <Dropdown
                        placement='bottomRight'
                        trigger={['click']}
                        overlay={<Menu style={{ padding: 24, borderRadius: 8 }}>
                            <FlexColumn left gap='12px'>
                                <Text heading>Download Transactions</Text>
                                <Flex between gap='8px' bottom>
                                    <LabeledInput
                                        label="Select a date range"
                                        type="date-picker"
                                        nomargin
                                        // disabledDate={this.disabledDate}
                                        placeholder='From'
                                        value={fromDate}
                                        format={'MM/DD/YYYY'}
                                        onChange={(date, dateStr) => setFromDate(date)}
                                    />
                                    <LabeledInput
                                        // label="To"
                                        type="date-picker"
                                        nomargin
                                        // disabledDate={this.disabledDate}
                                        placeholder='To'
                                        value={toDate}
                                        format={'MM/DD/YYYY'}
                                        onChange={(date, dateStr) => setToDate(date)}
                                    />
                                </Flex>
                                <LabeledInput
                                    type='switch'
                                    label='File format'
                                    margin='12px 0 24px'
                                    nomargin
                                    switchNames={['CSV', 'QFX']}
                                    onChange={(value) => setDownloadType(value)}
                                    value={downloadType} />
                                <Button loading={downloading} solid disabled={!fromDate || !toDate} text='DOWNLOAD' onClick={() => {
                                    downloadType === 'CSV' ? downloadCSVStmt() : downloadQFXStmt()
                                }} />
                            </FlexColumn>
                        </Menu>}
                        onVisibleChange={flag => setDownloadDropdownVisible(flag)}
                        visible={downloadDropdownVisible}
                    >
                        <ImageButton src={Download} />
                    </Dropdown>
                }
                {
                    customFilterComp
                }
                {
                    more &&
                    <Dropdown
                        placement='bottomRight'
                        trigger={['click']}
                        overlay={<Menu style={{ padding: 24, borderRadius: 8 }}>
                            <FlexColumn gap='24px'>
                                <TextButton uppercase rightIcon={<Image src={ArrowRight} />} onClick={() => {
                                    setDropdownVisible(false)
                                    moreTransactions('Canceled')
                                }} text="Unsuccessful transfers" />
                                {/* <TextButton uppercase rightIcon={<Image src={ArrowRight} />} onClick={() => {
                                    setDropdownVisible(false)
                                    moreTransactions('Rejected')
                                }} text="Rejected Transactions" />
                                <TextButton uppercase rightIcon={<Image src={ArrowRight} />} onClick={() => {
                                    setDropdownVisible(false)
                                    moreTransactions('Errored')
                                }} text="Transaction Errors" /> */}
                            </FlexColumn>
                        </Menu>}
                        onVisibleChange={flag => setMoreDropdownVisible(flag)}
                        visible={moreDropdownVisible}
                    >
                        <ImageButton src={More} />
                    </Dropdown>
                }
            </Flex>
        </Flex>

    </FlexColumn>
}

export default withTheme(Filter)