import { EditOutlined } from '@ant-design/icons';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import { Select, Divider } from 'antd';
import { addDataToStore, REMOTE_SAVE_DATA, PERSONAL_INFO } from '../../../Actions/actions';
import { ErrorAlert } from '../../Reusable/Alert';
import { TextButton } from '../../Reusable/Refresh/Button';

import { Flex, FlexColumn } from '../../Reusable/Container';
import { LabeledInput } from '../../Reusable/Input'
import FlowViewComponent from '../../Reusable/Refresh/FlowViewComponent';
import { Text } from '../../Reusable/Refresh/Text';
import ListOwnerView from './ListOwnerView';
import ListSubsidiaryView from './ListSubsidiaryView';
import environment from '../../../environment';
import { apiPOSTReq } from '../../../Utils/api';
import { Image } from '../../Reusable/Image'
import Percentage from '../../../Images/Percentage.png'
import {  formatOwnershipPercentage} from '../../../Utils/util';

const { Option } = Select;


class OwnerInfo extends Component {

    state = {
        submitLoading: false,
        subsidiaries: [],
        ownerInfo: [],
        showSuccessBanner: false
    };

    setOwnerInfo = (addedItems) => {
        this.setState({ ownerInfo: addedItems })
    }
    setSubsidiaryInfo = (addedItems) => {
        this.setState({ subsidiaries: addedItems });
    }
    editBusinessProfile = () => (
        <TextButton icon={<EditOutlined />} onClick={() => {
            this.setState({ "currentViewIndex": 0 });
        }}></TextButton>);
    editOwnershipInfo = () => (
        <TextButton icon={<EditOutlined />} onClick={() => {
            this.setState({ "currentViewIndex": 1 });
        }}></TextButton>
    );

    validateFlow = () => {
        var { flowView, onboardingStore } = this.props;
        var { ownershipPercentage, businessTitle } = onboardingStore.personalInfo
        var { onboardingStore, flowView } = this.props;

        if (!businessTitle) {
            this.setState({ errorField: 'title', errorMessage: 'Please select your business title' })
            return
        }

        if (!ownershipPercentage) {
            this.setState({ errorField: 'ownershipPercentage', errorMessage: 'Please enter ownership percentage' })
            return
        }
        else {
            if (isNaN(ownershipPercentage)) {
                this.setState({ errorField: 'ownershipPercentage', errorMessage: 'Please enter a valid ownership percentage' });
                return
            }
        }


        // if ((onboardingStore.coOwnersInfo || []).length == 0) {
        //     ErrorAlert({ description: "Please add list of Owners & Affiliates with >=25% ownership" });
        //     return;
        // } else {

        var totalPc = Number(ownershipPercentage);
        onboardingStore.coOwnersInfo.forEach(coOwner => {
            totalPc = totalPc + parseFloat(coOwner.ownershipPercentage);
        });

        if (totalPc > 100) {
            ErrorAlert({ description: `Total Ownership percentage is greater than 100%. Please update your ownership details.` });
            return;
        }

        this.setState({ submitLoading: true });
        // }
        // Save to the DB
        console.log("Saving business", { businessInfo: onboardingStore.businessInfo }, onboardingStore.coOwnersInfo)
        this.props.dispatch(addDataToStore(REMOTE_SAVE_DATA, flowView.FlowStep));
        this.setState({showSuccessBanner: true})
        setTimeout(() => {
            this.setState({showSuccessBanner: false})
            this.props.loadNext();
        }, 1000)    }

    handleOnChange = (event) => {
        const target = event.target
        if ((target.id || target.name) == 'ownershipPercentage') {
            if (!isNaN(target.value)) {
                if((target.value > 100)) {
                    this.saveUserInput(target.id || target.name, target.value.slice(0,2));
                }else {
                    this.saveUserInput(target.id || target.name, target.value);
                    this.setState({ errorField: null, errorMessage: null });
                }
                        }
            else {
                this.setState({ errorField: 'ownershipPercentage', errorMessage: 'Please enter a valid ownership percentage' });
                this.saveUserInput(target.id || target.name, target.value);
            }
        }
        else {
            this.saveUserInput(target.id || target.name, target.value)
        }
    }

    saveUserInput = (id, value) => {
        var dataToSave = {}
        var remoteSave = false
        var { citizenOrResident, countryOfCitizenship } = this.props.onboardingStore.personalInfo
        const foreignUser = (countryOfCitizenship != 'US') && (citizenOrResident == false)
        switch (id) {
            case "ssn":
                if (!foreignUser) {
                    value = value.replace(/[^\d]/g, '')
                } else {
                    value = value.replace(/[^0-9A-Z]+/gi, '')
                }
                dataToSave = { [id]: value }
                break
            case "idNumber":
                var identification = this.props.onboardingStore.personalInfo.identification || {}
                identification[id] = value
                dataToSave = { identification: identification }
                break
            case "expDate":
                var identification = this.props.onboardingStore.personalInfo.identification || {}
                identification[id] = value
                dataToSave = { identification: identification }
                break
            case 'ownershipPercentage':
                dataToSave = { [id]: formatOwnershipPercentage(value >= 0 ? value.toString() : '') }
            default:
                dataToSave = { [id]: value }
                break
        }
        this.props.dispatch(addDataToStore(PERSONAL_INFO, dataToSave))
    }

    render() {
        var { flowView, currentViewIndex, loadPrevView, flowViews, onboardingStore } = this.props;
        var { errorField, errorMessage, ownerInfo } = this.state
        var { ownershipPercentage, businessTitle, firstName, lastName } = onboardingStore.personalInfo
        var childViews = (
            <FlexColumn start>
                <FlexColumn style={{ width: "100%"}}>
                    <Text weight='500' color='#7384AA'>YOUR OWNERSHIP</Text>
                    <Text>Provide your title and ownership information.</Text>
                    <Flex start gap='12px' style={{ width: "100%" }}>
                        <div style={{ width: "288px" }}>
                            <LabeledInput
                                labelcolor={this.props.theme.colors.secondary3}
                                label="Name"
                                type='read-only'
                                value={`${firstName} ${lastName}`}
                            />
                        </div>
                        <div style={{ width: "288px" }}>
                            <LabeledInput
                                label="Title"
                                id="businessTitle"
                                type="select"
                                placeholder="Select title"
                                value={businessTitle}
                                onChange={(value) => this.saveUserInput("businessTitle", value)}
                                error={errorField == "title"}
                                errorMessage={errorMessage}
                            >
                                {["CEO", "CFO", "COO", "Other"].map(item => (
                                    <Option key={item} id="title" name="Title" value={item}>
                                        {item}
                                    </Option>
                                )
                                )}
                            </LabeledInput>
                        </div>
                        <div style={{ width: "288px" }}>
                            <LabeledInput
                                label="Ownership Percentage"
                                id="ownershipPercentage"
                                symbolsuffix="%"
                                placeholder="Enter Percentage"
                                value={formatOwnershipPercentage(ownershipPercentage ? ownershipPercentage : '')}
                                maxLength={6}
                                onChange={this.handleOnChange}
                                error={errorField == "ownershipPercentage"}
                                errorMessage={errorMessage}
                                suffixFontSzie="20px"
                            />
                        </div>

                    </Flex>
                </FlexColumn>
                <Divider style={{margin: "24px 0"}}/>

                <FlexColumn style={{ width: "100%"}}>
                    <Text weight='500' color='#7384AA'> OTHER OWNERS</Text>
                    <Text>Please add all individuals that own 25% or more of your business</Text>
                    <ListOwnerView ownerInfo={this.state.ownerInfo} flowViewIndex={flowViews[currentViewIndex]} setOwner={this.setOwnerInfo} />
                </FlexColumn>

                <Divider style={{margin: "24px 0"}}/>

                <FlexColumn style={{ width: "100%"}}>
                    <Text  weight='500' color='#7384AA'>PARENT COMPANIES & SUBSIDIARIES</Text>
                    <Text>Please add all parent companies and subsidiaries of your business </Text>
                    <ListSubsidiaryView subsidiaries={this.state.subsidiaries} flowViewIndex={flowViews[this.state.currentViewIndex]} setSubsidiary={this.setSubsidiaryInfo} />
                </FlexColumn>
            </FlexColumn>
        );
        return (
            <FlowViewComponent
                flowView={flowView}
                currentViewIndex={currentViewIndex}
                loadPrev={loadPrevView}
                childViews={childViews}
                submitLoading={this.state.submitLoading}
                width={'100%'}
                back={currentViewIndex > 0}
                padding='0 64px'
                {...this.props}
                loadNext={this.validateFlow}
                showSuccessBanner={this.state.showSuccessBanner}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        onboardingStore: state.onboardingAppReducer,
        aionStore: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(OwnerInfo));