import React, { Component } from 'react'
import { useHistory, withRouter } from 'react-router-dom'

import { Modal, Select, Skeleton, Popover } from 'antd'
import Dimensions from 'react-dimensions'
import moment from 'moment';
import { Text } from '../../Reusable/Text'
import { KPILight } from '../../Reusable/KPI'
import { LabeledInput } from '../../Reusable/Input'
import { Flex, FlexColumn, LightContainer } from '../../Reusable/Container'
import { TextButton, ImageButton } from '../../Reusable/Button';

// Images
import CalendarIcon from '../../../Images/calendar-icon.png'
import ArrowRight from "../../../Images/arrow-right.png"

const RailCard = (props) => {
    const { width, rail, pending, posted, onClick } = props

    const history = useHistory()

    return <LightContainer style={{ width, minWidth: 236 }}>
        <FlexColumn start fullWidth gap='24px'>
            <Flex fullWidth between centerHorizontally>
                <Text heading>{rail}</Text>
                <ImageButton src={ArrowRight} onClick={onClick} />
            </Flex>
            <Flex fullWidth between centerHorizontally>
                <Text caption weight='400'>Pending</Text>
                <Text>{pending}</Text>
            </Flex>
            <Flex fullWidth between centerHorizontally>
                <Text caption weight='400'>Posted</Text>
                <Text>{posted}</Text>
            </Flex>
        </FlexColumn>
    </LightContainer>
}

export default withRouter(RailCard)