import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import moment from 'moment'
import { Modal, Drawer, Select, message, Badge, Spin } from 'antd'
import { Editor, EditorState } from 'draft-js'
import { Document, Page, pdfjs } from 'react-pdf'
import { saveAs } from 'file-saver'
import { stateToHTML } from 'draft-js-export-html'
import { stateFromHTML } from 'draft-js-import-html'
import { SyncOutlined } from '@ant-design/icons';

import _ from 'lodash'
import { Text, Tag } from '../../Reusable/Text'
import { Image } from '../../Reusable/Image';
import { Flex, FlexColumn } from '../../Reusable/Container'
import { Button, TextButton } from '../../Reusable/Button'
import { LabeledInput } from '../../Reusable/Input'
import { ErrorAlert } from '../../Reusable/Alert'
import DeleteModal from '../../Reusable/DeleteModal'
import { getUserApps, toCurrency } from '../../../Utils/util'
import { apiPOSTReq, getPreSignedS3Url } from '../../../Utils/api'
import environment from '../../../environment'
import { ExpenseCategory } from '../../Reusable/ExpenseCategory';

import Dot from '../../../Images/dot-grey.png'
import ModalClose from '../../../Images/modal-close.png'
import Download from '../../../Images/download.png'
import Sparkle from '../../../Images/aisparkle.png'
import Revert from '../../../Images/Revert.png'

const { Option } = Select

const dateFormat = 'MM/DD/YYYY'
const previewDrawerWidth = 600
const previewModalWidth = 920

const dFormat = "ll; h:mm A z"

class SendInvoiceDrawer extends Component {

    constructor(props) {
        super(props)

        this.state = {
            fileList: [],
            toEmails: props.toEmails || [],
            ccEmails: props.ccEmails,
            subject: props.subject,
            editorState: props.editorState,
            emailTone: 'Formal',
            templateName: props.templateName,
            originalDraft: false
        }

        this.customer = this.props.location.state && this.props.location.state.customer

        this.uploadRef = React.createRef()
        this.toRef = React.createRef()
        this.subjectRef = React.createRef()
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.toEmails) !== JSON.stringify(this.props.toEmails)) {
            this.setState({ toEmails: this.props.toEmails });
        }
        if (JSON.stringify(prevProps.ccEmails) !== JSON.stringify(this.props.ccEmails)) {
            this.setState({ ccEmails: this.props.ccEmails });
        }
        if (JSON.stringify(prevProps.subject) !== JSON.stringify(this.props.subject)) {
            this.setState({ subject: this.props.subject });
        }
        if (JSON.stringify(prevProps.editorState) !== JSON.stringify(this.props.editorState)) {
            this.setState({ editorState: this.props.editorState });
        }
        if (JSON.stringify(prevProps.templateName) !== JSON.stringify(this.props.templateName)) {
            this.setState({ templateName: this.props.templateName });
        }
    }

    uploadProps = () => {
        return {
            action: `${environment.apiBaseUrl}/file/upload`,
            headers: {
                businesskey: this.props.aionStore.BusinessUniqueKey,
                jwt: this.props.aionStore.jwt
            },
            onChange: (info) => {
                const { invoice } = this.state
                const { fileList, event } = info
                var savedFile = {};
                fileList.forEach(file => {
                    console.log("CreateInvoice uploadProps file", file)
                    const { status, response, name, url } = file;
                    if (status === "done") {
                        savedFile = {
                            uid: fileList.length,
                            fileName: name,
                            uri: (response.UploadedUrls || [])[0],
                            url: (response.UploadedUrls || [])[0],
                            type: "OTHER",
                            objectType: "OTHER",
                            name,
                            mimeType: file.type
                        }

                        if (!file.uri && (response.UploadedUrls || []).length > 0) file.uri = (response.UploadedUrls || [])[0]
                        if (!file.url && (response.UploadedUrls || []).length > 0) file.url = (response.UploadedUrls || [])[0]

                        this.uploadRef.current.scrollIntoView({ behavior: 'smooth' });
                    } else if (status === 'error') {
                        console.log("CreateInvoice uploadProps error", info)
                        message.error(`${info.file.name} file upload failed.`);
                    }
                });

                fileList.forEach(file => {
                    file.fileName = file.name
                })

                this.setState({ fileList: [...fileList], invoice, saveAttachments: true });
            },
            onRemove: (file) => {
                // this.handleDeleteDoc(doc);
            },
            onPreview: (file) => {
                getPreSignedS3Url({ url: file.uri }, (err, preSignedUrl) => saveAs(preSignedUrl, file.name))
            },
        }
    };

    generateAIEmail = () => {
        const userInfo = this.props.aionStore?.userInfo;
        const { invoice } = this.props;
        const { emailTone } = this.state

        const body = {
            Invoice: invoice,
            "emailTone": emailTone.toLowerCase(),
            "userName": userInfo.firstName + " " + userInfo.lastName,
            "emailMessage": this.state.emailMessage
        }
        this.setState({ loadingEmail: true })
        console.log("CreateInvoice generateAIEmail body", body)
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/openai/generateEmail`, {}, body, (err, resp) => {
            try {
                this.setState({ loadingEmail: false, originalDraft: true })
                const data = resp
                console.log("integration/openai/generateEmail", data)
                if (data.result) {
                    this.setState({ emailMessage: data.emailMessage, aiGeneratedEmail: true })
                    this.setState({ editorState: EditorState.createWithContent(stateFromHTML(data.emailMessage)) })
                } else {
                    message.error(data.responseMessage || "Sorry, we had trouble processing your request. Please try again.")
                }
            } catch (error) {
                console.log("integration/openai/generateEmail", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    generateOriginalEmail = () => {
        const { invoice } = this.props;
        const body = {
            InvoiceId: invoice.invoiceId,
        }

        this.setState({ loadingEmail: true })
        console.log("CreateInvoice generateOriginalEmail body", body)
        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/getDefaultInvoiceEmail`, {}, body, (err, resp) => {
            try {
                this.setState({ loadingEmail: false, originalDraft: false })
                const data = resp
                console.log("ive/invoice/getDefaultInvoiceEmail", data)
                if (data.result) {
                    this.setState({ editorState: EditorState.createWithContent(stateFromHTML(data.invoiceEmailCustomBody)) })
                } else {
                    message.error(data.responseMessage || "Sorry, we had trouble processing your request. Please try again.")
                }
            } catch (error) {
                console.log("ive/invoice/getDefaultInvoiceEmail", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    handleSend = () => {
        const userInfo = this.props.aionStore?.userInfo;
        const { invoice, handleSave, onSuccess, onlinePaymentEnabled, accountNumber } = this.props;
        var { editorState, fromName, toEmails, ccEmails, templateName, subject, saveAttachments } = this.state

        if (!toEmails || toEmails.length === 0) {
            this.setState({ errorField: 'TO' })
            this.toRef.current.scrollIntoView({ behavior: 'smooth' });
            return
        }
        if (!subject || subject === '') {
            this.setState({ errorField: 'subject' })
            this.subjectRef.current.focus()
            return
        }
        this.setState({ loadingSend: true, errorField: null })
        const body = {
            InvoiceId: invoice.invoiceId,
            InvoiceEmailCustomBody: stateToHTML(editorState.getCurrentContent()),
            FromName: fromName,
            docTemplateName: templateName,
            toAddr: toEmails.join(','),
            ccAddr: ccEmails.join(','),
            subject,
            modifiedByUserName: `${userInfo.firstName} ${userInfo.lastName}`,
            onlinePaymentEnabled: onlinePaymentEnabled,
            accountNumber: accountNumber,
        }

        console.log("SendInvoiceDrawer ive/invoice/send body", body, toEmails)

        var callback = () => {
            apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/send`, {}, body, (err, resp) => {
                this.setState({ loadingSend: false })
                try {
                    const data = resp
                    console.log("ive/invoice/send", JSON.stringify(data))
                    if (data.result) {
                        // message.success(`Invoice sent`)
                        // window.history.back()
                        onSuccess(toEmails, ccEmails)
                    } else {
                        throw Error(data.responseMessage || data.error)
                    }
                } catch (error) {
                    console.log("ive/invoice/send", error, err, resp)
                    ErrorAlert({ description: error.message })
                }
            })
        }

        if (saveAttachments) {
            invoice.attachments = this.state.fileList
            this.setState({ invoice }, () => handleSave(false, callback))
        } else {
            callback()
        }
    }

    onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
        this.setState({ numPages: nextNumPages })
    }

    render() {
        const { invoice, visible, onClose, previewUrl, revise } = this.props;
        const { toEmails, ccEmails, subject, loadingSend, loadingEmail, editorState, numPages, fileList, emailTone, errorField } = this.state
        const { invoiceStatus, docNumber } = invoice

        var invoiceDoc = (invoice.attachments || []).find(item => item.type == "InvoiceDoc") || {}

        return (
            <>
                <Modal
                    visible={visible}
                    footer={null}
                    maskClosable={false}
                    closable={false}
                    width={previewModalWidth}
                    destroyOnClose={true}
                    onCancel={onClose}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                    style={{ left: `calc(100% - ${previewDrawerWidth}px - ${previewModalWidth}px - 48px)` }}
                    className='invoice-preview'
                    wrapClassName="invoice-preview left-positioned-modal"
                >
                    <Document
                        file={previewUrl}
                        onLoadSuccess={this.onDocumentLoadSuccess}
                    >
                        {
                            Array.from(
                                new Array(numPages),
                                (el, index) => <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={1.5} />
                            )
                        }
                    </Document>
                </Modal>

                <Drawer
                    visible={visible}
                    placement="right"
                    closable={true}
                    onClose={onClose}
                    width={previewDrawerWidth}
                    mask={false}
                    maskClosable={false}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                    className='sendInvoiceDrawer'
                >
                    <FlexColumn start fullHeight style={{ marginBottom: 0 }}>
                        <Text heading margin='0 24px 24px'>Emailing Invoice {docNumber}</Text>
                        <FlexColumn start gap="24px" style={{ overflowY: 'auto', overflowX: 'hidden', padding: '0 24px 24px' }}>
                            <div ref={this.toRef}>
                                <LabeledInput
                                    label="TO"
                                    id="emails"
                                    key="emails"
                                    type="multi-email"
                                    nomargin
                                    disabled
                                    onChange={(toEmails) => {
                                        this.setState({ toEmails })
                                    }}
                                    onDelete={(index) => {
                                        var emails = []

                                        for (var i = 0; i < toEmails.length; i++) {
                                            if (i != index) emails.push(toEmails[i])
                                        }

                                        this.setState({ toEmails: emails })
                                    }}
                                    emails={toEmails && toEmails}
                                    // instruction="The email/s above will receive the invoice"
                                    error={errorField === "TO"}
                                    errorMessage={"This is a mandatory field"}
                                    containerStyle={{ padding: 12 }}
                                />
                            </div>

                            <LabeledInput
                                label="CC"
                                id="cc"
                                key="cc"
                                type="multi-email"
                                nomargin
                                disabled
                                optional
                                onChange={(ccEmails) => {
                                    this.setState({ ccEmails })
                                }}
                                onDelete={(index) => {
                                    var emails = []

                                    for (var i = 0; i < ccEmails.length; i++) {
                                        if (i != index) emails.push(ccEmails[i])
                                    }

                                    this.setState({ ccEmails: emails })
                                }}
                                emails={ccEmails && ccEmails}
                                noAsterisk
                                containerStyle={{ padding: 12 }}
                            />

                            <LabeledInput
                                inputRef={this.subjectRef}
                                nomargin
                                label="Subject"
                                id="subject"
                                key="subject"
                                placeholder="Enter subject"
                                onChange={(event) => this.setState({ subject: event.target.value })}
                                value={subject}
                                error={errorField === "subject"}
                                errorMessage={"This is a mandatory field"}
                            />

                            <FlexColumn start gap='4px'>
                                <FlexColumn start>
                                    <Text color='#7384AA' size='14px'>Body</Text>
                                    {
                                        loadingEmail ?
                                        <div style={{ margin: "20px 0", marginBottom: "20px", padding: "30px 50px", textAlign: "center", borderRadius: "4px" }}>
                                            <SyncOutlined spin style={{ color:"#1199FF", fontSize: '16px'  }} />
                                            <Text color="#1199FF" style={{ marginLeft: "5px" }}>Generating</Text>
                                        </div>
                                        :
                                        <div style={{ marginTop: 8 }} className="default-input">
                                            <Editor editorState={editorState} onChange={(editorState) => {
                                                this.setState({ editorState })
                                            }} />
                                        </div>
                                    }
                                    
                                </FlexColumn>
                                <Badge.Ribbon text="Beta" style={{ marginBottom: "5px" }}>
                                    <div className="ai-email-container">

                                        <Text color='black'>Generate email drafts using our new AI-powered assistant! Select a tone and click on generate.</Text>

                                        <Flex between fullWidth centerHorizontally>
                                            <FlexColumn start gap='16px'>
                                                <LabeledInput
                                                    nomargin
                                                    type='switch'
                                                    switchNames={['Formal', 'Semi-formal', 'Casual']}
                                                    onChange={(value) => this.setState({ emailTone: value })}
                                                    value={emailTone}
                                                />

                                                <TextButton text='GENERATE EMAIL' onClick={() => this.generateAIEmail()} />
                                                {
                                                    this.state.originalDraft ?
                                                    <Flex start style={{ marginTop: "10px" }}>
                                                        <Image src={Revert} width='25px' height='25px' />  
                                                        <TextButton style={{ marginLeft: "8px", marginTop:"3px" }} text='GO BACK TO ORIGINAL DRAFT' onClick={() => this.generateOriginalEmail()} />
                                                    </Flex>
                                                    :
                                                    <>
                                                    </> 
                                                }
                                           
                                            </FlexColumn>

                                            <Image src={Sparkle} width='80px' height='80px' />
                                        </Flex>
                                    </div>
                                </Badge.Ribbon>
                            </FlexColumn>

                            {
                                ['NEW', 'DRAFT'].includes(invoiceStatus) &&
                                <div ref={this.uploadRef} style={{ width: 550, maxWidth: 550 }}>
                                    <LabeledInput
                                        nomargin
                                        {...this.uploadProps()}
                                        type='upload'
                                        label='Attachments'
                                        fileList={fileList.filter(item => item.type != "InvoiceDoc")}
                                        noAsterisk
                                    />
                                </div>
                            }
                        </FlexColumn>
                        <FlexColumn end grow>
                            <Flex start centerHorizontally gap="24px" style={{ boxShadow: '0px -4px 8px -2px rgba(102, 102, 102, 0.08)', padding: '24px 24px 0' }}>
                                <Button loading={loadingSend} text='SEND' solid onClick={this.handleSend} />

                                <TextButton text='DOWNLOAD INVOICE AS PDF' onClick={() => { saveAs(invoiceDoc.preSignedUrl, `Invoice ${docNumber}`) }} rightIcon={<Image src={Download} />} />
                            </Flex>
                        </FlexColumn>
                    </FlexColumn>
                </Drawer>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(SendInvoiceDrawer)))