import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import moment from 'moment'
import { message, Table, Modal, Tabs, Drawer, Dropdown, Menu } from 'antd'

// Util
import { Button, TextButton, ImageButton } from '../../Reusable/Button'
import { Text } from '../../Reusable/Text'

import { Flex, FlexColumn, CardContainer } from '../../Reusable/Container'
import { Image } from '../../Reusable/Image'
import { apiPOSTReq } from '../../../Utils/api'
import { getFeaturePerm } from '../../../Utils/util'
import environment from '../../../environment'
import { toCurrency } from '../../../Utils/util'
import { LabeledInput } from '../../Reusable/Input'
import { ErrorAlert } from '../../Reusable/Alert'
import PageHeader from '../../Reusable/PageHeader'
import AlertModal from '../../Reusable/AlertModal'
import NewProduct from './NewProduct'
import ProductDrawer from './ProductDrawer'
import ModalClose from '../../../Images/modal-close.png'
import Search from '../../../Images/search.png'
import ChevronDown from "../../../Images/chevron-down.svg"
import More from "../../../Images/more.png"
import Edit from "../../../Images/edit.png"
import Add from '../../../Images/add-white.svg'
import TableView from '../../Reusable/TableView'

const { TabPane } = Tabs


class Index extends Component {
    state = {
        loading: true,
        pagination: {
            current: 1,
            pageSize: 10,
        },
        inactivePagination: {
            current: 1,
            pageSize: 10,
        },
        categories: [],
        tab: 'active',
    }

    componentDidMount() {
        this.fetchData({ pagination: this.state.pagination })
        this.fetchInactiveProducts({ pagination: this.state.inactivePagination })
    }

    fetchData = (options) => {
        var { pagination, searchTerm } = options
        var body = {
            "size": pagination.pageSize,
            "page": pagination.current - 1,
            "sortDirection": "DESC"
        }
        if (searchTerm) {
            body.searchStr = searchTerm
            body.sortDirection = "DESC"
        }
        console.log("fetchData BODY", body)
        this.setState({ loading: true })
        apiPOSTReq(`${environment.iveBaseUrl}/ive/product/getActiveProducts`, null, body, (err, resp) => {
            try {
                const data = resp || {}
                if (data.result) {
                    console.log("/ive/product/getActiveProducts", data, pagination)
                    var products = pagination.current > 1 ? this.state.products.concat(data.products) : data.products
                    var moreData = products.length == data.count ? false : true
                    this.setState({
                        ...options,
                        products: products,
                        pagination: {
                            ...pagination,
                            total: data.count
                        },
                        moreToLoad: moreData,
                    })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getActiveProducts", error, err, resp)
                ErrorAlert({ description: error.message })
            } finally {
                this.setState({ loading: false })
            }
        })
    }

    fetchInactiveProducts(options) {
        var { pagination, searchTerm } = options
        var body = {
            "size": pagination.pageSize,
            "page": pagination.current - 1,
            "sortDirection": "DESC"
        }
        if (searchTerm) {
            body.searchStr = searchTerm
            body.sortDirection = "DESC"
        }

        this.setState({ loadingInactive: true })
        console.log("/ive/product/getInActiveProducts body", body)
        apiPOSTReq(`${environment.iveBaseUrl}/ive/product/getInActiveProducts`, null, body, (err, resp) => {
            try {
                const data = resp || {}
                if (data.result) {
                    console.log("/ive/product/getInActiveProducts", data)
                    var products = pagination.current > 1 ? this.state.inactiveProducts.concat(data.products) : data.products
                    var moreData = products.length == data.count ? false : true
                    this.setState({
                        ...options,
                        inactiveProducts: products,
                        pagination: {
                            ...pagination,
                            total: data.count
                        },
                        moreInactiveToLoad: moreData,
                    })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getActiveProducts", error, err, resp)
                ErrorAlert({ description: error.message })
            } finally {
                this.setState({ loadingInactive: false })
            }
        })
    }

    deactivateProduct = (product) => {
        const body = {
            "productId": product.id
        }
        this.setState({ deactivateLoading: true })
        apiPOSTReq(`${environment.iveBaseUrl}/ive/product/deactivate`, {}, body, (err, resp) => {
            try {
                const data = resp
                console.log("/ive/product/deactivate", data)
                if (data.result) {
                    message.success(`${product.name} deactivated!`)
                    this.setState({ showDeactivate: false, tab: 'inactive' })
                    this.fetchData({ pagination: this.state.pagination })
                    this.fetchInactiveProducts({ pagination: this.state.inactivePagination })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /ive/product/deactivate", error.stack, resp)
                ErrorAlert({ description: error.message })
            } finally {
                this.setState({ deactivateLoading: false })
            }
        })
    }

    activateProduct = (product) => {
        const body = {
            "productId": product.id
        }
        this.setState({ [`${product.name}ActivateLoading`]: true });
        apiPOSTReq(`${environment.iveBaseUrl}/ive/product/activate`, {}, body, (err, resp) => {
            try {
                const data = resp;
                console.log("/ive/product/activate", data)
                if (data.result) {
                    message.success(`Product activated!`);
                    this.setState({ tab: 'active' })
                    this.fetchData({ pagination: this.state.pagination })
                    this.fetchInactiveProducts({ pagination: this.state.inactivePagination })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR activateProduct", error, resp);
                ErrorAlert({ description: error.message })
            } finally {
                this.setState({ [`${product.name}ActivateLoading`]: false });
            }
        })
    }

    handleTableChange = (pagination, filters, sorter) => {
        this.fetchData({ pagination })
    }

    selectRow = (record, view) => {
        this.setState({ selectedProduct: record, showNewProductModal: true, view })
    }

    onSearchChange = (e) => {
        const pagination = {
            current: 1,
            pageSize: 10,
        }

        const searchTerm = e.target.value

        this.setState({ searchTerm }, () => {
            this.fetchData({ pagination, searchTerm })
            this.fetchInactiveProducts({ pagination, searchTerm })
        })
    }

    onLoadMore = () => {
        const { searchTerm, tab, pagination, inactivePagination } = this.state

        if (tab === 'active') {
            var updatedState = {
                loading: true,
                pagination: {
                    current: pagination.current + 1,
                    pageSize: 10
                },
                searchTerm: searchTerm,
            }
            this.fetchData({ ...this.state, ...updatedState })
        } else {
            var updatedState = {
                loading: true,
                pagination: {
                    current: inactivePagination.current + 1,
                    pageSize: 10
                },
                searchTerm: searchTerm,
            }
            this.fetchInactiveProducts({ ...this.state, ...updatedState })
        }
    }

    render() {
        var { products, loading, showDelete, showNewProductModal, moreToLoad, moreInactiveToLoad, tab, inactiveProducts, view, searchTerm,
            deactivateLoading, showDeactivate, productToDeactivate, loadingInactive, showError, errorMessage } = this.state

        const columns = [
            {
                title: <Text noWrap weight="500">Name</Text>,
                dataIndex: 'productName',
                key: 'name',
                render: (name, record) => {
                    return <a style={{ textDecoration: "underline" }} onClick={() => this.selectRow(record, true)}>{name}</a>
                },
                // width: "250px",
            },
            {
                title: <Text noWrap weight="500">Type</Text>,
                dataIndex: 'type',
                key: 'type',
                // width: "150px"
            },
            // {
            //     title: <Text noWrap weight="500">Date</Text>,
            //     dataIndex: 'txnDate',
            //     key: 'txnDate',
            //     render: date => (moment(date).format('MM/DD/YY')),
            //     //width: "120px"
            // },
            // {
            //     title: <Text noWrap weight="500">Income Account</Text>,
            //     dataIndex: 'accountCategoryName',
            //     key: 'accountCategoryName',
            // },
            // {
            //     title: <Text noWrap weight="500">SKU</Text>,
            //     dataIndex: 'sku',
            //     key: 'sku',
            //     render: sku => <Text style={{ wordBreak: "break-all" }}>{sku}</Text>,
            //     // width: "250px"
            // },
            {
                title: <Text noWrap weight="500">Unit Price</Text>,
                dataIndex: 'unitPrice',
                key: 'unitPrice',
                render: amount => `$${toCurrency(amount)}`,
                align: 'right',
            },
        ]

        columns.push({
            title: '',
            key: 'action',
            align: 'right',
            dataIndex: 'productName',
            onHover: true,
            render: (productName, record) => (
                <Flex fullWidth end centerHorizontally>
                    {
                        tab === 'active' ?
                            <Dropdown
                                placement='bottomRight'
                                trigger={['click']}
                                key={`${record.docNumber}-Dropdown`}
                                overlay={<Menu style={{ padding: 24, borderRadius: 8, zIndex: 1001 }} key={`${record.docNumber}-Menu`} onClick={() => { }}>
                                    <FlexColumn gap='24px'>
                                        <TextButton text='EDIT' onClick={(event) => {
                                            event.stopPropagation(); // Stop event propagation here as well
                                            this.selectRow(record)
                                        }} rightIcon={<Image src={Edit} />} />
                                        <TextButton permtype="Receivables.Invoices" text='DEACTIVATE' onClick={(event) => {
                                            event.stopPropagation(); // Stop event propagation here as well
                                            this.setState({ productToDeactivate: record, showDeactivate: true })
                                        }} />
                                    </FlexColumn>
                                </Menu>}
                            >
                                <ImageButton src={More}
                                    onClick={(event) => {
                                        event.stopPropagation(); // Stop event propagation here as well
                                    }} />
                            </Dropdown>
                            :
                            <TextButton
                                loading={this.state[`${productName}ActivateLoading`]}
                                onClick={(event) => {
                                    event.stopPropagation(); // Stop event propagation here as well
                                    this.activateProduct(record)
                                }}
                                permtype="Receivables.Invoices"
                                text='ACTIVATE' />
                    }
                </Flex>
            ),
        })

        var v2BetaEnabled = getFeaturePerm("Receivables.Invoices.Receivables v2 Beta")

        return (
            <>
                <FlexColumn className='main-padding'>
                    <PageHeader
                        titleText="Receivables"
                        subtitle="Receivables"
                    />

                    <Tabs size="large" activeKey={tab} onClick={() => { }} onChange={(key) => this.setState({ tab: key })} style={{ marginBottom: 12 }}>
                        <TabPane tab="Active" key="active" />
                        <TabPane tab="Inactive" key="inactive" />
                    </Tabs>

                    <TableView
                        id="invoice-table"
                        titleText='Products & Services'
                        tableLayout='auto'
                        columns={columns}
                        dataSource={tab === 'active' ? products : inactiveProducts}
                        rowKey='productId'
                        pagination={false}
                        onChange={this.handleTableChange}
                        loading={tab === 'active' ? loading : loadingInactive}
                        ctaContent={
                            <Flex gap='24px'>
                                <LabeledInput
                                    id="searchTerm"
                                    key="searchTerm"
                                    placeholder="Search by name"
                                    prefixIcon={<Image width='12px' height='12px' src={Search} />}
                                    onChange={this.onSearchChange}
                                    width='250px'
                                    nomargin
                                />
                                <Button solid permtype="Receivables.Invoices" loading={this.state.newProductLoading} disabled={false} onClick={() => this.setState({ showNewProductModal: true })} text='ADD PRODUCT' icon={<Image src={Add} />} />
                            </Flex>
                        }
                        onRow={
                            (record, rowIndex) => {
                                return {
                                    onClick: event => {
                                        this.selectRow(record, true)
                                    }, // click row
                                }
                            }
                        }
                    />

                    {
                        ((tab === 'active' && moreToLoad) || (tab === 'inactive' && moreInactiveToLoad)) &&
                        <Flex fullWidth centerVerticall style={{ marginTop: "10px" }}>
                            <TextButton text='LOAD 10 MORE' loading={loading} onClick={this.onLoadMore} rightIcon={<img style={{ cursor: 'pointer' }} width='24px' height='24px' src={ChevronDown} />} />
                        </Flex>
                    }
                </FlexColumn>

                {
                    v2BetaEnabled.manage ?
                        <Drawer
                            visible={showNewProductModal}
                            placement="right"
                            closable={true}
                            mask={!showDelete && !showDeactivate && !showError}
                            maskClosable={true}
                            closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                            width='444px'
                            destroyOnClose={true}
                            onClose={() => { this.setState({ showNewProductModal: false, selectedProduct: null, view: false }) }}
                        >
                            <ProductDrawer
                                onClose={() => { this.setState({ showNewProductModal: false, selectedProduct: null, view: false }) }}
                                visible={showNewProductModal}
                                product={this.state.selectedProduct}
                                submitComplete={
                                    () => {
                                        this.setState({ showNewProductModal: false, selectedProduct: null, tab: 'active', searchTerm: null, view: false })
                                        this.fetchData({ pagination: this.state.pagination })
                                        this.fetchInactiveProducts({ pagination: this.state.inactivePagination })
                                    }
                                }
                                view={view}
                                showDelete={showDelete}
                                onShowDelete={(showDelete) => this.setState({ showDelete })}
                                showError={(message) => this.setState({ showError: true, errorMessage: message })}
                            />
                        </Drawer>
                        :
                        <Modal
                            visible={this.state.showNewProductModal}
                            footer={null}
                            closable={true}
                            width={700}
                            // style={{ top: 20 }}
                            destroyOnClose={true}
                            onCancel={() => { this.setState({ showNewProductModal: false, selectedProduct: null }) }}
                            closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                        >
                            <NewProduct
                                product={this.state.selectedProduct}
                                submitComplete={
                                    () => {
                                        this.setState({ showNewProductModal: false, selectedProduct: null, tab: 'active', searchTerm: null })
                                        this.fetchData({ pagination: this.state.pagination })
                                        this.fetchInactiveProducts({ pagination: this.state.inactivePagination })
                                    }
                                }
                            />
                        </Modal>
                }

                <AlertModal
                    deactivate
                    visible={showDeactivate}
                    loading={deactivateLoading}
                    title='Deactivate product / service?'
                    description={`Are you sure you want to deactivate ${productToDeactivate?.name}?`}
                    buttonTitle='DEACTIVATE'
                    cancelButtonText='CANCEL'
                    onConfirm={() => {
                        this.setState({ deleteLoading: true }, () => {
                            this.deactivateProduct(productToDeactivate)
                        })
                    }}
                    onCancel={() => this.setState({ showDeactivate: false, productToDeactivate: null })}
                />

                <AlertModal
                    error
                    visible={showError}
                    loading={deactivateLoading}
                    title="Uh oh. That didn't work."
                    titleSize="20px"
                    description={errorMessage}
                    onConfirm={() => this.setState({ showError: false })}
                />
            </>
        )
    }

}

function mapStateToProps(state) {
    return {
        store: state.creditAppReducer,
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)))