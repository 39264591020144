import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import { Tooltip, Table, Dropdown, Skeleton, Menu, Tabs, message } from 'antd'
import cloneDeep from 'lodash/cloneDeep'

import { Flex, FlexColumn, CardContainer, LightContainer } from '../../Reusable/Container'
import PageHeader from '../../Reusable/PageHeader'
import { LabeledInput } from '../../Reusable/Input'
import { Button, TextButton, Chip, ImageButton } from '../../Reusable/Button'
import { ErrorAlert } from '../../Reusable/Alert'
import { ApprovalJourney } from '../../Reusable/Approval'
import { Text, Tag } from '../../Reusable/Text'
import { Image } from '../../Reusable/Image'
import CancelModal from '../../Reusable/CancelModal'
import { apiPOSTReq } from '../../../Utils/api'
import environment from '../../../environment'
import { addDataToStore, BNK_SAVE_DATA } from '../../../Actions/actions'
import moment from 'moment'
import { crbAddressObjectToStr, getBPAccountsWithAccess, getResourcePerm } from '../../../Utils/util'

// Images
import { LoadingOutlined } from '@ant-design/icons'
import ChevronDown from "../../../Images/chevron-down.svg"
import ChevronUp from "../../../Images/chevron-up.png"
import Location from "../../../Images/table-location.png"
import More from "../../../Images/more.png"
import ArrowRight from "../../../Images/arrow-right.png"
import Lock from "../../../Images/lock-selected.svg"
import Unlock from "../../../Images/lock-primary.svg"
import Edit from "../../../Images/edit.png"
import TableView from '../../Reusable/TableView'

const { TabPane } = Tabs

class Index extends Component {

    constructor(props) {
        super(props)

        const paymentsPerm = getResourcePerm("BusinessBanking.Payments")
        const recipientsPerm = getResourcePerm("BusinessBanking.Recipients")
        const billPaymentsPerm = getResourcePerm("Payables.Bill Payments")
        const contractorPaymentsPerm = getResourcePerm("Payables.Contractor Payments")

        const { UserInfo } = this.props.store
        var username = UserInfo.Email

        this.state = {
            name: 'New Approval Setting',
            amount: 2000,
            paymentType: "ACH",
            rule: "greater than",
            approvers: {
                0: [],
                1: [],
                2: []
            },
            approvalSettings: [],
            approvalBy: "Any",
            stepCount: 1,
            showEditSettingModal: false,
            levelTypes: {
                0: "ANY",
                1: "ANY",
                2: "ANY"
            },
            registeredUsers: [],
            selectedTab: "todo",
            loading: true,
            objectMap: {},
            approvalsTab: (paymentsPerm.approve || recipientsPerm.approve) ? "Banking" : "Bill Payments",
            pageSize: 10,
            page: 0,
            payPage: 0,
            body: {
                "approverUserId": username,
                "sortDirection": "DESC",
            },
            payBody: {
                "approverUserId": username,
                "sortDirection": "DESC",
            },
            completedApprovals: [],
            completedPayApprovals: [],
            poll: false,
        }
    }

    componentDidMount() {
        const { poll } = this.state
        this.fetchCards()
        this.interval = setInterval(() => {
            this.pollCards()
        }, 2000)
    }

    componentWillUnmount() {
        // Clean up interval when component is unmounted
        clearInterval(this.interval)
    }

    handleOnChange = (event) => {
        const target = event.target
        this.setState({ [target.id]: target.value })
    }

    handleSelect = (value, option) => {
        this.setState({ [option.id]: value })
    }

    handleSelectLevelType = (value, option) => {
        const { levelTypes } = this.state
        levelTypes[option.id] = value
        this.setState({ levelTypes })
    }

    handleMultiSelect = (value, option) => {
        var { approvers, registeredUsers } = this.state

        var selectedUser

        registeredUsers.map(r => {
            if (r.id === option[option.length - 1].value) {
                selectedUser = r
            }
        })

        if (selectedUser) {
            approvers[option[option.length - 1].id].push(selectedUser)

            this.setState({ approvers })
        }
    }

    getApproversText = (approvers, approvalBy) => {
        if (approvers.length == 1) {
            return `${approvers[0].firstName} ${approvers[0].lastName}`
        } else {
            return <>
                {approvers.map(a => `${a.firstName} ${a.lastName}`).slice(0, approvers.length - 1).join(", ")}<b>{approvalBy === "ANY" ? " or " : " and "}</b>{`${approvers[approvers.length - 1].firstName} ${approvers[approvers.length - 1].lastName}`}
            </>
        }
    }

    getPaymentTypeText = (paymentType) => {
        switch (paymentType) {
            case 'ACH':
                return <><b>{paymentType}</b> transfers</>
            case 'Wire', 'Check', 'Bill Pay':
                return <><b>{paymentType}</b> transfers</>
            default:
                return <><b>Any</b> transfer</>
        }
    }

    getApproverNames = (level, levelCount) => {
        const { UserInfo } = this.props.store
        var username = UserInfo.Email

        var items = []
        level.performerInstances.map(a => {
            if (a.userName != username) {
                items.push(<Tag color={this.props.theme.colors.secondary3} style={{ marginRight: 10 }}>{`${a.firstName} ${a.lastName}`}</Tag>)
            } else {
                items.push(<Tag color={this.props.theme.colors.secondary3} style={{ marginRight: 10 }}>You</Tag>)
            }
        })

        return items
    }

    handleSearchChange = (e) => {
        this.setState({ [e.target.id]: e.target.value, wireSelected: false, achSelected: false, recipientSelected: false, dropdownVisible: false, approvedSelected: false, rejectedSelected: false }, this.fetchOutbox)
    }

    fetchCards = (loading, poll) => {
        var { UserInfo } = this.props.store
        UserInfo = UserInfo || {}
        const { selectedCard, pagination } = this.state
        this.setState({ loading: loading === false ? false : true })
        apiPOSTReq(`${environment.bbBaseUrl}/bb/card/getPersonCards`, {}, {}, (err, resp) => {
            this.setState({ loading: false })
            try {
                const data = resp || {}
                console.log('bb/card/getPersonCards', data)
                if (data.result) {
                    const cards = data.bbPersonCards || []
                    this.props.dispatch(addDataToStore(BNK_SAVE_DATA, { DebitCards: cards }))
                    var openCards = cards.filter(card => (card.status !== 'Closed')).sort((b, a) => moment(a.createdAt).toDate() - moment(b.createdAt).toDate())
                    var activeCard

                    if (this.props.location.state && this.props.location.state.cardId) {
                        activeCard = cards.find(card => card.cardId === this.props.location.state.cardId) || null
                    } else {
                        activeCard = (openCards.length > 0) ? openCards[0] : null
                    }

                    cards.sort((a, b) => `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`))

                    this.setState({ loading: false, cards: cards, originalCards: cloneDeep(cards) })
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp)
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            } finally {
                this.setState({ activateLoading: false })
            }
        })
    }

    pollCards = () => {
        const { selectedCard, pagination, cards } = this.state
        console.log('bb/card/getPersonCards interval', cards && cards.find(card => card.orderStatus === 'OrderPending'), cards)

        const noneEditing = !cards.some(card => card.editing === true);

        if (cards && cards.find(card => card.orderStatus === 'OrderPending') && noneEditing) {
            var { UserInfo } = this.props.store
            UserInfo = UserInfo || {}
            apiPOSTReq(`${environment.bbBaseUrl}/bb/card/getPersonCards`, {}, {}, (err, resp) => {
                this.setState({ loading: false })
                try {
                    const data = resp || {}
                    console.log('polling bb/card/getPersonCards', data)
                    if (data.result) {
                        const cards = data.bbPersonCards || []
                        this.props.dispatch(addDataToStore(BNK_SAVE_DATA, { DebitCards: cards }))
                        var openCards = cards.filter(card => (card.status !== 'Closed')).sort((b, a) => moment(a.createdAt).toDate() - moment(b.createdAt).toDate())

                        var tempCards = this.state.cards

                        tempCards.map(card => {
                            // Find the updated item in the updatedArray
                            const updatedItem = openCards.find(updated => updated.objectId === card.objectId)

                            // If the updatedItem exists and its orderStatus is different from the original
                            if (updatedItem && updatedItem.orderStatus !== card.orderStatus) {
                                // Update the orderStatus of the original item
                                card.orderStatus = updatedItem.orderStatus
                                card.last4 = updatedItem.last4
                                card.expirationDate = updatedItem.expirationDate
                            }

                            // Return the item (with or without updated orderStatus)
                            return card;
                        });

                        tempCards.sort((a, b) => `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`))

                        this.setState({ cards: tempCards })
                    } else {
                        throw Error(data.responseMessage || data.error)
                    }
                } catch (error) {
                    //console.log("/getPersonCards err", error, resp)
                    ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
                } finally {
                    this.setState({ activateLoading: false })
                }
            })
        }
    }

    suspendCard = (debitCard) => {
        this.setState({ loading: true })

        apiPOSTReq(`${environment.bbBaseUrl}/bb/card/suspendCard`, { "BankProvider": environment.bankProvider.crb }, { bbPersonCard: debitCard }, (err, resp) => {
            // this.setState({ loading: false })
            try {
                const data = resp || {}
                if (data.result) {
                    this.fetchCards()
                    console.log('suspendCard', data)
                    message.success('Card locked!')
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/suspendCard err", error, resp)
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    unsuspendCard = (debitCard) => {
        this.setState({ loading: true })

        apiPOSTReq(`${environment.bbBaseUrl}/bb/card/unsuspendCard`, { "BankProvider": environment.bankProvider.crb }, { bbPersonCard: debitCard }, (err, resp) => {
            // this.setState({ loading: false })
            try {
                const data = resp || {}
                if (data.result) {
                    this.fetchCards()
                    console.log('suspendCard', data)
                    message.success('Card unlocked!')
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/unsuspendCard err", error, resp)
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    cancelCard = (debitCard) => {
        const { UserInfo } = this.props.store
        var username = UserInfo.Email
        this.setState({ loading: true, cancelLoading: true })

        apiPOSTReq(`${environment.bbBaseUrl}/bb/card/closeCard`, { "BankProvider": environment.bankProvider.crb }, { notes: `${username} cancelled card`, bbPersonCard: debitCard }, (err, resp) => {
            // this.setState({ loading: false })
            try {
                const data = resp || {}
                if (data.result) {
                    this.setState({ showCancel: false, cardToCancel: null, cancelLoading: false })
                    this.fetchCards()
                    console.log('closeCard', data)
                    message.success('Card canceled!')
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/closeCard err", error, resp)
                this.setState({ showCancel: false, cardToCancel: null, cancelLoading: false, loading: false })
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    updateCard = (card, callback) => {

        var body = {
            // "BusinessCustomerId": BusinessInfo.Id,
            // "MailingAddress": businessInfo.mailingAddress,
            // "BillingAddress": businessInfo.mailingAddress,
            "BBPersonCard": card,
        }

        console.log('issueCard create card body', body)
        apiPOSTReq(`${environment.bbBaseUrl}/bb/card/updateCard`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            try {
                this.setState({ submitLoading: false })
                console.log(`${environment.bbBaseUrl}/bb/card/updateCard`, err, resp)
                const data = resp
                if (data.result) {
                    const { cards, originalCards } = this.state

                    cards.forEach(item => { if (item.objectId === card.objectId) item.nickName = card.nickName })
                    originalCards.forEach(item => { if (item.objectId === card.objectId) item.nickName = card.nickName })

                    this.setState({ cards, originalCards })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log(`ERROR ${environment.bbBaseUrl}/bb/card/updateCard`, error, err, resp)
                ErrorAlert({ description: error.message })
            } finally {
                callback()
            }
        })
    }

    render() {
        const { moreToLoad, morePayToLoad, moreDropdownVisible, page, payPage, loading, cards, body, payBody,
            showCancel, cardToCancel, objectDetail, cancelLoading, approvalsTab, originalCards } = this.state
        const { theme, noHeader } = this.props
        var { counterpartiesObj, achTransferObj, wireTransferObj, bpPaymentObj } = objectDetail || {}
        console.log("achTransferObj", achTransferObj, counterpartiesObj, wireTransferObj, bpPaymentObj)

        var Accounts = getBPAccountsWithAccess("Payments", { permType: "manage", getActiveAccounts: true }) || []

        const completedColumns = [
            {
                title: 'Cardholder',
                dataIndex: 'firstName',
                key: 'firstName',
                render: (firstName, record) => {
                    return `${firstName} ${record.lastName}`
                },
                width: '150px',
            },
            {
                title: 'Card number',
                dataIndex: 'last4',
                key: 'last4',
                render: last4 => {
                    return last4 ? '••••' + last4 : '--'
                },
                width: '120px',
            },
            {
                title: 'Nickname',
                dataIndex: 'nickName',
                key: 'nickName',
                render: (nickName, record) => {
                    return <Flex start centerHorizontally gap='8px' style={{ height: 28 }}>{
                        record.editing ?
                            <>
                                <LabeledInput
                                    nomargin
                                    defaultValue={nickName}
                                    minHeight='28px'
                                    maxLength={30}
                                    onChange={(event) => {
                                        const target = event.target
                                        record.nickName = target.value
                                        // this.setState({ cards })
                                    }}
                                />
                                <TextButton
                                    weight='400'
                                    text='Save'
                                    loading={record.loading}
                                    onClick={() => {
                                        record.loading = true
                                        this.setState({ cards }, () => {
                                            this.updateCard(record, () => {
                                                record.loading = false
                                                record.editing = false
                                                this.setState({ cards })
                                            })
                                        })
                                    }} />
                                <TextButton
                                    weight='400'
                                    text='Cancel'
                                    disabled={record.loading}
                                    onClick={() => {
                                        if (!record.loading) {
                                            const original = originalCards.find(c => c.cardId === record.cardId)
                                            record.editing = false
                                            record.nickName = original.nickName
                                            this.setState({ cards })
                                        }
                                    }} />
                            </>
                            :
                            <Flex between fullWidth centerHorizontally>
                                <Text break>{nickName}</Text>
                                <ImageButton src={Edit} onClick={() => {
                                    record.editing = true
                                    this.setState({ cards })
                                }} />
                            </Flex>
                    }
                    </Flex>
                },
                width: '220px',
            },
            {
                title: 'Expiry',
                dataIndex: 'expirationDate',
                key: 'expirationDate',
                render: date => {
                    return <Text noWrap>{date ? moment.utc(date).format('MM/YYYY') : '--'}</Text>
                },
            },
            {
                title: 'Issued on',
                dataIndex: 'createdAt',
                key: 'createdAt',
                render: date => {
                    return <Text noWrap>{date ? moment(date).format('MM/DD/YY') : '--'}</Text>
                },
            },
            // {
            //     title: 'Month’s spend',
            //     dataIndex: 'monthsSpend',
            //     key: 'monthsSpend',
            //     render: (monthsSpend, record) => {
            //         return '--'
            //     },
            // },
            {
                title: 'Type',
                dataIndex: 'paymentInstrument',
                key: 'paymentInstrument',
                render: paymentInstrument => {
                    return (paymentInstrument === 'VirualPan' || paymentInstrument === 'VirtualPan') ? 'Virtual' : 'Physical'
                },
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (status, record) => {
                    var color = theme.colors.secondary7
                    var background
                    var status = status
                    if (record.orderStatus === 'OrderPending') {
                        color = theme.colors.primary
                        background = theme.colors.backgroundPrimary
                        status = 'Processing'
                    } else if (status === 'Unactivated') {
                        color = theme.colors.primary
                        background = theme.colors.backgroundPrimary
                        status = 'Issued'
                    } else if (status === 'Suspended') {
                        color = theme.colors.redDeep
                        background = theme.colors.redPale
                        status = 'Locked'
                    } else if (status === 'Active') {
                        color = theme.colors.greenDeep
                        background = theme.colors.greenPale
                    } else if (status === 'Closed') {
                        status = 'Canceled'
                    }

                    return (
                        <Tag color={color} background={background}>{status}{status === 'Processing' && <LoadingOutlined style={{ color: this.props.theme.colors.primary, marginLeft: 8 }} />}</Tag>
                    )
                },
            },
            {
                title: 'Address',
                dataIndex: 'billingAddress',
                key: 'billingAddress',
                render: (billingAddress, record) => {
                    return (
                        <Tooltip overlayInnerStyle={{ width: 330, minWidth: 330 }} overlayStyle={{ width: 350, minWidth: 350 }} overlayClassName='roles-tooltip' placement="left" arrowPointAtCenter={false} title={<FlexColumn>
                            <Text color='white' size='14px'>Billing Address:</Text>
                            <Text color='white' size='14px'>{crbAddressObjectToStr(billingAddress)}</Text>
                        </FlexColumn>}>
                            <Image src={Location} />
                        </Tooltip>
                    )
                },
            },
            {
                title: 'Account',
                dataIndex: 'accountNumber',
                key: 'accountNumber',
                render: accountNumber => {
                    const account = Accounts.find(acc => acc.accountNumber === accountNumber)

                    return account && <Text >{`${account.mask ? account.mask : ''}${(account.mask && (account.productType || account.nickName)) ? ' • ' : ''}${account.nickName ? account.nickName : account.productType ? account.productType : ''}`}</Text>
                },
                width: '200px'
            },
            {
                dataIndex: 'status',
                key: 'status',
                render: (status, record) => {
                    return (
                        <Flex gap='12px' end>
                            <Dropdown
                                placement='bottomRight'
                                trigger={['click']}
                                overlay={<Menu style={{ padding: 24, borderRadius: 8 }}>
                                    <FlexColumn gap='24px'>

                                        <TextButton uppercase rightIcon={<Image src={ArrowRight} />} onClick={() => {
                                            this.props.history.push({
                                                pathname: '/cards/view',
                                                state: { view: true, card: record }
                                            })
                                            this.setState({ moreDropdownVisible: false })
                                        }} text="VIEW DETAILS" />
                                        {
                                            (record.orderStatus === 'Completed' && status !== 'Closed') &&
                                            <>
                                                {
                                                    status !== "Unactivated" &&
                                                    <TextButton uppercase rightIcon={<Image src={status === 'Suspended' ? Unlock : Lock} />} onClick={() => {
                                                        this.setState({ moreDropdownVisible: false })
                                                        if (status === 'Suspended') {
                                                            this.unsuspendCard(record)
                                                        } else {
                                                            this.suspendCard(record)
                                                        }
                                                    }} text={status === 'Suspended' ? 'UNLOCK' : 'LOCK'} />
                                                }
                                                <TextButton uppercase rightIcon={<Image src={ArrowRight} />} onClick={() => {
                                                    this.setState({ moreDropdownVisible: false, showCancel: true, cardToCancel: record })
                                                }} text="CANCEL CARD" />
                                            </>
                                        }
                                    </FlexColumn>
                                </Menu>}
                            // onVisibleChange={flag => this.setState({ moreDropdownVisible: flag })}
                            // visible={moreDropdownVisible}
                            >
                                <ImageButton src={More} />
                            </Dropdown>

                            {/* <TextButton icon={<EyeOutlined />} onClick={() => this.props.history.push({
                                pathname: '/cards/view',
                                state: { view: true, card: record }
                            })} /> */}
                        </Flex>
                    )
                },
                width: '100px'
            },
        ]

        return (
            <>
                <FlexColumn className={!noHeader && 'main-padding'} style={{ margin: noHeader ? '24px 24px 0 0' : 0 }}>
                    {
                        !noHeader &&
                        <PageHeader
                            titleText="Cards"
                            subtitle='Cards'
                            // back
                            ctaContent={<Button onClick={() => this.props.history.push('/cards/issue-card')} permtype="Override" solid text='ISSUE NEW CARD' />}
                        />
                    }
                    {/* <SearchAndFilter type={(paymentsPerm.approve || recipientsPerm.approve) ? 'Banking' : 'Bill Payments'} onChange={(options) => (paymentsPerm.approve || recipientsPerm.approve) ? this.fetchOutbox(options) : this.fetchPayOutbox(options)} /> */}
                    <TableView
                        id="completed-table"
                        tableLayout='auto'
                        columns={completedColumns}
                        dataSource={cards}
                        rowKey='objectId'
                        titleText='Debit Cards'
                        descText='Issue new debit cards and lock or cancel previously issued cards'
                        loading={loading}
                        pagination={false}
                        onChange={(pagination, filters, sorter) => {
                            this.setState({ outboxPagination: pagination })
                        }}
                        ctaContent={
                            !noHeader &&
                            <Button onClick={() => this.props.history.push({
                                pathname: '/cards/issue-card',
                                state: { backOnConfirm: true }
                            })} permtype="Override" solid text='ISSUE NEW CARD' />
                        }
                    />

                    {
                        ((approvalsTab === 'Bill Payments' && morePayToLoad === true) || (approvalsTab === 'Banking' && moreToLoad === true)) &&
                        <Flex style={{ marginTop: 24 }} fullWidth centerVertically><TextButton text='LOAD 10 MORE' loading={loading} onClick={() => {
                            if (approvalsTab === 'Bill Payments') {
                                this.setState({ payPage: payPage + 1 }, () => this.fetchPayOutbox(payBody, true))
                            } else {
                                this.setState({ page: page + 1 }, () => this.fetchOutbox(body, true))
                            }
                        }} rightIcon={<img style={{ cursor: 'pointer' }} width='24px' height='24px' src={ChevronDown} />} /></Flex>
                    }

                    <CancelModal
                        visible={showCancel}
                        loading={cancelLoading}
                        title='Cancel card?'
                        description='Are you sure you want to cancel this card? This action cannot be undone.'
                        buttonTitle='YES'
                        onConfirm={() => this.cancelCard(cardToCancel)}
                        onCancel={() => this.setState({ showCancel: false, cardToCancel: null })} />
                </FlexColumn>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer,
        bankingStore: state.bankingAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)))