import React, { Component } from 'react';
import { connect } from "react-redux";
import { random } from 'underscore';
import environment from "../../../environment";
import { isSmallScreen } from '../../../Utils/util';

class Vouched extends Component {
    
    componentDidMount() {
        this.loadVouched();
    }

    loadVouched = () => {
        var config = {
            appId: environment.vouchedKey,
            /**
             * Enums: idv, id, reverify
                The job type.
                idv - ID with selfie verification
                id - ID only
                reverify - reverification
             */
            type: this.props.idType || "id",
            /*
            liveness Enums: mouth, orientation, distance, straight
            Only applicable for selfie/headshot liveness check. Specify how Vouched should check for face liveness. The mouth liveness, checks for mouth movements, orientation checks the facial orientation, distance checks for user movements, and straight checks if the user is looking at the camera.
            */
            liveness: "straight",
          
            // your webhook for POST verification processing
            callbackURL: environment.bpBaseUrl,

            id: 'both', // upload, camera, both
          
            // have the user confirm information
            userConfirmation: {
                confirmData: false,
                confirmImages: false,
            },
            
            stepTitles: {
                FrontId: "Upload front of your ID",
                Face: "Show your face for a quick selfie",
                Done: "That's it!"
            },
            theme: {
                baseColor: "#1199FF",
                fontColor: "#222222"
            },
            // callback during initialization of the web app
            onInit: ({ token, job }) => {
                console.log("vouched initialization", token, job);
            },
          
            // callback when a user submits a photo
            onSubmit: ({ stage, attempts, job }) => {
                console.log("vouched photo submitted", job);
            },
          
            // called when a job is completed.
            onDone: (job) => this.props.onDone(job),
          
            // callback executed after attempt to find camera device
            onCamera: ({ hasCamera, hasPermission }) => {
                console.log("attempted to find camera");
            },
          
            // callback when there are changes to the Camera DOM element
            onCameraEvent: (cameraEvent) => {
                console.log("camera DOM element updated");
            },
          
            // callback when a reverification job is complete
            onReverify: (job) => {
                console.log("reverification complete");
            },
          
            // callback when a survey is submitted, (per customer basis)
            onSurveyDone: (job) => {
                console.log("survey done");
            },
          
            // callback when user confirms data and photos
            onConfirm: (userConfirmEvent) => {
                console.log("user confirmation");
            },
          
            // theme
            theme: {
                name: "avant",
            },

            // verification properties
            verification: {
                enablePhysicalAddress: true
            },
        };   
        if(this.props.isWeb) {
            config["content"] = {
                upperStartInstructions: "We need to verify your identity",
                cameraScreenLabelFrontId: "Upload front of your ID"
            }
        }
        if (this.props.isMobile) {
            config["crossDeviceQRCode"] = true
            config["content"] = {
                upperStartInstructions: "We need to verify your identity",
                cameraScreenLabelFrontId: "Upload front of your ID",
                crossDeviceInstructions: "We need to verify your identity. This requires government-issued photo ID as well as selfie. Please follow the instructions below to continue the verification process.",
                crossDeviceShowOff: false                
            };
        }
        
        const existingScript = document.getElementById("vouched");
        console.log("loadVouched", existingScript, document.getElementById("vouched-element"));
        if (!existingScript) {
            const script = document.createElement("script");
            script.src = "https://static.vouched.id/widget/vouched-2.0.0.js";
            script.id = "vouched";
            script.async = true;
            document.head.appendChild(script);
            script.onload = () => {
                var vouched = window["Vouched"]({ ...config });
                console.log("mount vouched-element");
                vouched.mount("#vouched-element");
            };
        } else {
            // Remount
            var vouched = window["Vouched"]({ ...config });
            vouched.mount("#vouched-element");
        }
    }

    render() {
        var dStyle = { minHeight: "80vh", maxHeight: "90vh", overflowY: 'auto' }
        if(isSmallScreen) dStyle['zoom'] = '1.175' // Usually 1.2 but optimized for vouched camera view
        return (
            <div id="vouched-element" style={dStyle}></div>
        );
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Vouched);