import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled, { withTheme } from 'styled-components';
// Components
import { Button } from '../../../Reusable/Button';
import { FlexColumn, Flex, InputContainer } from '../../../Reusable/Container';
import { Text } from '../../../Reusable/Text';
import { LabeledInput } from '../../../Reusable/Input';
import { apiPOSTReq } from '../../../../Utils/api';
import environment from '../../../../environment';
import { ErrorAlert } from '../../../Reusable/Alert';

class Index extends Component {
    state = {
        loading: false,
        creditLimit: this.props.arAdvanceInfo.advanceLimit,
        creditLimitChanged: false,
    }

    componentDidMount() {
    }

    handleCreditLimit = (event) => {
        const target = event.target;
        this.setState({ creditLimit: target.value, creditLimitChanged: true });
    }

    handleReason = (event) => {
        const target = event.target;
        this.setState({ reason: target.value });
    }

    handleSubmit = () => {
        const { creditLimit, reason } = this.state;
        const { creditLimitChanged } = this.state;

        if (creditLimit && reason != null && reason != '') {
            this.setState({ loading: true })
            const { UserInfo } = this.props.store;
            const { businessId } = this.props;

            var username = UserInfo.Email;
            var request = {
                "clientBusinessId": businessId,
                "userName": username,
                "reason": reason,
            }

            if (creditLimitChanged) {
                request["creditLimit"] = creditLimit;
            }

            apiPOSTReq(`${environment.opsBaseUrl}/ops/updateCreditLimit`, null, request, (err, resp) => {
                this.setState({ loading: false })
                try {
                    const data = resp;
                    if (data.result) {
                        if (err) throw new Error(err);

                        this.props.closeLineSetting()
                    } else {
                        throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                    }
                } catch (error) {
                    console.log("ERRR getAccounts", error, err, resp);
                }
            });
        }
        else {
            if (!creditLimit) {
                this.setState({ errorField: "creditLimit", errorMessage: "Please enter a valid credit limit." })
                return
            }
            if (!reason || reason == "") {
                this.setState({ errorField: "reason", errorMessage: "Please enter a valid reason." })
                return
            }
        }
    }

    render() {
        const { loading, creditLimit, errorField, errorMessage } = this.state;
        const { theme } = this.props;
        return (
            <FlexColumn center style={{ minHeight: "80%" }}>
                <div style={{ width: "600px" }}>
                    <Text heading>{"Line Settings"}</Text>
                    <FlexColumn>
                        <LabeledInput
                            autoFocus
                            label="Credit Limit"
                            labelcolor={theme.colors.secondary3}
                            symbolprefix="$"
                            id="creditLimit"
                            key="creditLimit"
                            placeholder="100,000.00"
                            onChange={this.handleCreditLimit}
                            value={creditLimit}
                            error={errorField == "creditLimit"}
                            errorMessage={errorMessage}
                        />
                        <LabeledInput
                            autoFocus
                            label="Reason"
                            type="text-area"
                            labelcolor={theme.colors.secondary3}
                            id="reason"
                            key="reason"
                            placeholder="Add your reason for update"
                            onChange={this.handleReason}
                            error={errorField == "reason"}
                            errorMessage={errorMessage}
                        />
                        <Button style={{ alignSelf: 'start', margin: '20px 0' }} solid size="large" loading={loading} onClick={this.handleSubmit} text="Save" />
                    </FlexColumn>
                </div>
            </FlexColumn>
        );
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer,
        receivablesStore: state.receivablesAppReducer,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Index));