import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'
import styled from 'styled-components'

import { message, Upload, Modal, Checkbox } from 'antd'

// Components
import { Text } from '../../Reusable/Text'
import { FlexColumn, Flex } from '../../Reusable/Container'
import { TextButton } from '../../Reusable/Button'
import environment from '../../../environment'
import LUploadIcon from '../../../Images/upload-bill-icon/blue.png'
import DUploadIcon from '../../../Images/upload-bill-icon/dark.png'
import { DownloadOutlined, } from '@ant-design/icons'
import Result from '../../Reusable/Result'
import ModalClose from '../../../Images/modal-close.png'

export const CardButton = styled.div`
    margin-top: 18px;
    background-color: rgb(255, 255, 255);
    border-radius: 0.5rem;
    width: 450px;
    margin-bottom: 14px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    opacity: 1;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.1);
    padding: 1.8rem;
    transition-property: box-shadow;
    transition-duration: 300ms;
    &.selected {
        cursor: pointer;
        box-shadow: 0 3px 15px rgb(0 0 0 / 0.2);
    };
    &.selected:hover {
        cursor: pointer;
        box-shadow: 0 3px 15px rgb(0 0 0 / 0.4);
    };
`

export const CardButtonText = styled.span`
    font-size: 18px;
    font-weight: 400;
`

class UploadAR extends Component {

    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            file: null,
            fileName: '',
            showErrorModal: false,
            fileList: [],
        }
    }

    render() {
        const { certifyChecked, responseErrorMessage, uploadARAgeingInfo } = this.state
        const { theme, aionStore } = this.props
        const darkMode = (theme.type === "dark")

        const { UserInfo } = aionStore
        var certifiedBy = `${UserInfo.FirstName} ${UserInfo.LastName}`

        var uploadProps = {
            accept: ".csv",
            beforeUpload: file => {
                console.log('before upload', file)
                this.setState({ polling: true, })
                return
            },
            multiple: false,
            action: this.props.actionUrl || `${environment.iveBaseUrl}/ar/aging/upload`,
            data: (file) => {
                return {
                    File: file,
                    CertifiedBy: certifiedBy,
                    Notes: null,
                }
            },
            headers: {
                AionCurrentBiz: this.props.aionStore.BusinessUniqueKey,
                AionAuth: this.props.aionStore.UAM.encryptedAuthHeader,
            },
            multiple: false,
            onChange: (info) => {
                var { file } = info
                console.log('info', info)
                this.setState({ fileList: [file] })
                const { status, response } = file

                console.log('status', status, response)
                if (status === "done") {
                    console.log('ar/aging/upload done', response)

                    if (response.result) {
                        if (response.uploadARAgeingInfo.newCustomers && response.uploadARAgeingInfo.newCustomers.length > 0) {
                            this.setState({
                                showErrorModal: true,
                                fileList: [],
                                responseErrorMessage: null,
                                uploadARAgeingInfo: response.uploadARAgeingInfo
                            })
                        } else {
                            console.log('ar/aging/upload job id', response.uploadJobId)
                            message.success("AR report uploaded successfully!")
                            this.props.submitComplete(response.uploadJobId)
                        }
                    } else {
                        console.log("ar/aging/upload response", JSON.stringify(response))
                        this.setState({
                            showErrorModal: true,
                            fileList: [],
                            responseErrorMessage: response.responseMessage,
                            uploadARAgeingInfo: response.uploadARAgeingInfo
                        })
                    }
                } else if (status === "error") {
                    console.log("ar/aging/upload file", JSON.stringify(file))
                    this.setState({ showErrorModal: true, fileList: [] })
                }
            }
        }

        var uploadTitle = "Upload Error"
        var errorData
        var errorMsg
        var onSubmit

        let showHeaderErrorTable = uploadARAgeingInfo && uploadARAgeingInfo.headerErrors && uploadARAgeingInfo.headerErrors.length > 0
        let errors = []
        // let errorMessage = this.stat>"?e.errorMessage
        if (uploadARAgeingInfo && uploadARAgeingInfo.headerErrors && uploadARAgeingInfo.headerErrors.length > 0) {
            errors = uploadARAgeingInfo.headerErrors.map((x, i) => {
                return { ...x, key: i }
            })
            // errorMessage = "The file is missing required column headers"
        } else if (uploadARAgeingInfo && uploadARAgeingInfo.valueErrors && uploadARAgeingInfo.valueErrors.length > 0) {
            errors = uploadARAgeingInfo.valueErrors.map((x, i) => {
                return { ...x, key: i }
            })
        }
        var errorMessage = "The file you uploaded contains the below errors. Please correct the errors and retry."

        let errorNum = 0
        if (uploadARAgeingInfo && uploadARAgeingInfo.recordsErrorCount) {
            errorNum = uploadARAgeingInfo.recordsErrorCount
        }
        if (uploadARAgeingInfo && uploadARAgeingInfo.newCustomers && uploadARAgeingInfo.newCustomers.length) {
            uploadTitle = "New Customers Detected"
            errorMessage = `We have detected ${uploadARAgeingInfo.newCustomers.length} new customers in the aging report you uploaded. You must add these customers before we can review the report.`
            errorNum = uploadARAgeingInfo.newCustomers.length
            errorData = []
            errorMsg = "New Customers"
            uploadARAgeingInfo.newCustomers.forEach(customer => {
                errorData.push({ colName: customer })
            })
            onSubmit = () => { this.props.addCustomers(uploadARAgeingInfo.newCustomers, uploadARAgeingInfo.uploadJobId) }
        }
        let successNum = 0
        if (uploadARAgeingInfo && uploadARAgeingInfo.recordsTotalCount && uploadARAgeingInfo.recordsErrorCount) {
            successNum = uploadARAgeingInfo.recordsTotalCount - uploadARAgeingInfo.recordsErrorCount
        }

        return (
            <>
                <FlexColumn>
                    <Text heading>Upload AR Aging Report</Text>
                    <Text noMargin>Import your latest AR aging report using our standard template</Text>

                    <TextButton margin='12px 0 0' id="downloadTemplate" icon={<DownloadOutlined />} text={<a href={this.props.downloadLink || "/Vendor_template.csv"} download={this.props.downloadName || "Vendor_template.csv"}>Download CSV Template</a>} />

                    <div style={{ display: "flex", flexDirection: 'column', alignItems: "center", marginTop: '30px' }} >
                        <Upload disabled={!certifyChecked} {...uploadProps} fileList={this.state.fileList}>
                            <CardButton className={certifyChecked && "selected"}>
                                <img src={darkMode ? DUploadIcon : LUploadIcon} width="25" height="25" style={{ marginRight: "15px", opacity: 0.8 }} />
                                <FlexColumn start>
                                    <CardButtonText>Upload File</CardButtonText>
                                    <Text color={theme.colors.systemGray}>Please upload file as per the CSV template above</Text>
                                </FlexColumn>
                            </CardButton>
                        </Upload>
                        <Checkbox style={{ width: 450, marginTop: 12 }} onChange={(e) => this.setState({ certifyChecked: e.target.checked })}>Check this box to certify that all of the invoices provided in your uploaded file are true and correct as of the date hereof.</Checkbox>
                    </div>
                </FlexColumn>
                <Modal
                    visible={this.state.showErrorModal}
                    footer={null}
                    closable={true}
                    width={750}
                    style={{ top: 80 }}
                    destroyOnClose={true}
                    onCancel={() => {
                        this.props.submitComplete()
                        this.setState({ showErrorModal: false, responseErrorMessage: null, uploadARAgeingInfo: null })
                    }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <Result
                        uploadTypeTitle={uploadTitle}
                        showHeaderErrorTable={showHeaderErrorTable}
                        errors={errors}
                        errorNum={errorNum}
                        successNum={successNum}
                        errorMessage={errorMessage}
                        responseErrorMessage={responseErrorMessage}
                        errorColumnTitle="Customer Name"
                        errorData={errorData}
                        errorMsg={errorMsg}
                        ctaText="Add Customers"
                        onSubmit={onSubmit}
                    />
                </Modal>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(UploadAR))