import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import moment from 'moment'
import { Modal, Tabs, Select, message, Skeleton, Table, Switch, Menu, Dropdown } from 'antd'

import _ from 'lodash'
import { Text, Tag } from '../../../Reusable/Text'
import { Image } from '../../../Reusable/Image'
import { Flex, FlexColumn, CardContainer } from '../../../Reusable/Container'
import PageHeader from '../../../Reusable/PageHeader'
import { Button, TextButton, ImageButton, Chip } from '../../../Reusable/Button'
import { ErrorAlert } from '../../../Reusable/Alert'
import DocumentDrawer from '../DocumentDrawer'
import Deactivate from '../Deactivate'
import Activate from '../Activate'

import InvoiceTable from '../../Invoices/InvoiceTable'
import SearchAndFilter from '../../Invoices/SearchAndFilter'
import { getUserApps, addressObjectToStr, getResourcePerm, defaultPaymentTermObjects } from '../../../../Utils/util'
import { apiPOSTReq, getPreSignedS3Url, apiPOSTReqPromise } from '../../../../Utils/api'
import environment from '../../../../environment'
import { saveAs } from 'file-saver'

import Edit from '../../../../Images/edit.png'
import Location from '../../../../Images/location.png'
import Pipe from '../../../../Images/pipe.png';
import Link from '../../../../Images/link.svg';
import Add from '../../../../Images/add.svg';
import Document from '../../../../Images/file-grey.png';
import Call from '../../../../Images/call.svg'
import Email from '../../../../Images/email.svg'
import { LabeledInput } from '../../../Reusable/Input'
import ModalClose from '../../../../Images/modal-close.png'

const { Option } = Select
const { TabPane } = Tabs

var dFormat = "YYYY-MM-DD"

const WOStatusTag = (options) => {
    let statusMsg;
    let color = "";
    let background = "";
    switch (options.status) {
        case "VALIDATED":
            color = "#318F2F";
            background = "#F5F9F5";
            statusMsg = "Approved"
            break
        case "REJECTED":
            color = "#E52D2D";
            background = "#FEF5F5";
            statusMsg = "Rejected"
            break
        default:
            statusMsg = "Verifying"
            break
    }
    return (<Tag primary={statusMsg === "Verifying"} color={color} background={background}>{statusMsg}</Tag>);
}

const dateFormat = 'MMM D, YYYY'

class CustomerDetails extends Component {

    constructor(props) {
        super(props)

        this.state = {
            documentsLoading: true,
            msasLoading: true,
            showMSAModal: false,
            pagination: {
                current: 1,
                pageSize: 10,
                showSizeChanger: false,
            },
            sorter: {},
            dashboardLoading: true,
            loading: false,
            selectedTab: 'details',
            transferMethods: [],
            fileList: [],
            paymentTermsItems: defaultPaymentTermObjects,
        }

        this.customer = this.props.location.state && this.props.location.state.customer
    }

    componentDidMount() {
        this.fetchTerms();
        // this.getMSAForCustomer();
        // this.getWOsForCustomer();
        this.getDocuments();
        console.log("CustomerDetails customer", this.customer)
        this.fetchInvoices({ ...this.state })
    }

    fetchTerms() {
        apiPOSTReq(`${environment.iveBaseUrl}/ive/term/getTerms`, {}, {}, (err, resp) => {
            try {
                const data = resp || {};
                console.log("/ive/term/getTerms", data)
                if (data.result) {
                    const combinedTerms = [...data.termList];

                    defaultPaymentTermObjects.forEach((term, index) => {
                        const exists = combinedTerms.some(detail => detail.name === term);
                        if (!exists) {
                            combinedTerms.push({ name: term, termId: index });
                        }
                    });

                    this.setState({ paymentTermsItems: combinedTerms })
                } else {
                    this.setState({ paymentTermsItems: defaultPaymentTermObjects })
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                this.setState({ paymentTermsItems: defaultPaymentTermObjects })
                console.log("ERRR getTerms", error, resp);
                ErrorAlert({ description: error.message })
            }
        })
    }

    getDocuments = () => {
        this.setState({ documentsLoading: true });
        Promise.all([this.getMSAForCustomer(), this.getWOsForCustomer(), this.getDocumentsForCustomer()])
            .then(() => {
                var { msas, workOrders, documents } = this.state
                const allDocuments = this.combineDocuments(msas || [], workOrders || [], documents || [])
                this.setState({ documentsLoading: false, allDocuments });
            });
    }

    getMSAForCustomer = () => {
        const body = {
            "BusinessId": this.props.aionStore.BusinessUniqueKey,
            "CustomerId": this.customer.customerId,
        };
        console.log("getMSAForCustomer", body);

        return apiPOSTReqPromise(`${environment.iveBaseUrl}/ive/msa/getMSAForCustomer`, this.iveHeaders, body)
            .then(data => {
                if (data.result) {
                    console.log("CustomerDetails getMSAForCustomer data", data);
                    this.setState({
                        msas: data.msas
                    });
                } else {
                    throw Error("Could not getActiveMSAs.");
                }
            })
            .catch(error => {
                console.log("ERRR", error.stack);
                //message.error(`Error adding MSA `);
            });
    };

    getWOsForCustomer = () => {
        const body = {
            "BusinessId": this.props.aionStore.BusinessUniqueKey,
            "CustomerId": this.customer.customerId,
        };
        return apiPOSTReqPromise(`${environment.iveBaseUrl}/ive/po/getPOsForCustomer`, this.iveHeaders, body)
            .then(data => {
                if (data.result) {
                    console.log("CustomerDetails POSs", data)
                    this.setState({
                        workOrders: data.pos
                    });
                } else {
                    throw Error("Could not fetch work orders.");
                }
            })
            .catch(error => {
                console.log("ERRR", error.stack);
                message.error(`Could not fetch work orders.`);
            });
    };

    getDocumentsForCustomer = () => {
        const body = {
            "BusinessId": this.props.aionStore.BusinessUniqueKey,
            "CustomerId": this.customer.customerId,
        };
        console.log("getDocumentsForCustomer", body);

        return apiPOSTReqPromise(`${environment.iveBaseUrl}/ive/document/get`, this.iveHeaders, body)
            .then(data => {
                console.log("CustomerDetails getDocumentsForCustomer data", data);
                if (data.result) {
                    console.log("CustomerDetails getDocumentsForCustomer data", data);
                    this.setState({
                        documents: data.receivablesCustomerDocuments
                    });
                } else {
                    throw Error("Could not getActiveMSAs.");
                }
            })
            .catch(error => {
                console.log("ERRR", error.stack);
                //message.error(`Error adding MSA `);
            });
    };

    uploadProps = () => {
        return {
            action: `${environment.apiBaseUrl}/file/upload`,
            headers: {
                businesskey: this.props.aionStore.BusinessUniqueKey,
                jwt: this.props.aionStore.jwt
            },
            onChange: (info) => {
                const { invoice } = this.state
                const { fileList, event } = info
                var savedFile = {};
                fileList.forEach(file => {
                    console.log("CreateInvoice uploadProps file", file)
                    const { status, response, name, url } = file;
                    if (status === "done") {
                        savedFile = {
                            uid: fileList.length,
                            fileName: name,
                            uri: (response.UploadedUrls || [])[0],
                            url: (response.UploadedUrls || [])[0],
                            type: "OTHER",
                            objectType: "OTHER",
                            name,
                            mimeType: file.type
                        }

                        if (!file.uri && (response.UploadedUrls || []).length > 0) file.uri = (response.UploadedUrls || [])[0]
                        if (!file.url && (response.UploadedUrls || []).length > 0) file.url = (response.UploadedUrls || [])[0]
                    } else if (status === 'error') {
                        console.log("CreateInvoice uploadProps error", info)
                        message.error(`${info.file.name} file upload failed.`);
                    }
                });

                fileList.forEach(file => {
                    file.fileName = file.name
                })

                this.setState({ fileList: [...fileList], invoice, saveAttachments: true });
            },
            onRemove: (file) => {
                // this.handleDeleteDoc(doc);
            },
            onPreview: (file) => {
                getPreSignedS3Url({ url: file.uri }, (err, preSignedUrl) => saveAs(preSignedUrl, file.name))
            },
        }
    };

    combineDocuments = (msaArray, poArray, documentArray) => {
        // Map the MSA array to the new format
        const combinedMSAs = msaArray.map(msa => ({
            id: msa.id,
            name: msa.name || msa.documentUrl.fileName,
            type: 'Contract or agreement',
            subType: msa.type,
            Status: msa.status || null, // Assuming that status might be directly on the MSA object
        }));

        // Map the PO array to the new format
        const combinedPOs = poArray.map(po => ({
            id: po.id,
            name: po.name || po.ponumber || po.msaid, // Assuming name should come from ponumber or msaid if ponumber is not available
            type: 'Purchase Order',
            subType: po.type,
            Status: po.postatus || null,
            verificationStatus: po.verificationStatus,
        }));

        // Map the document array to the new format
        const combinedDocuments = documentArray.map(document => ({
            id: document.id,
            name: document.name,
            type: 'Other',
            subType: document.subType,
            Status: null,
        }));

        // Combine both arrays
        return [...combinedMSAs, ...combinedPOs, ...combinedDocuments];
    }

    shouldShowQBTab = () => {
        const { Attributes, CurrentSync } = this.props.aionStore
        var { Businesses } = Attributes || {}
        var businessAttributes = Businesses || {}
        let shouldShowQBTab = false

        shouldShowQBTab = businessAttributes.AccountingSWLinked
        if (this.props.aionStore.hasIntegration) {
            if (CurrentSync && CurrentSync.syncSetupCompleted) {
                // has live integration
                shouldShowQBTab = false
            }
        }

        return shouldShowQBTab
    }

    fetchInvoices = (options) => {
        var { sortDirection, sortFieldname } = this.state
        var { pagination, fromDate, toDate, filterType, sorter, searchTerm, filterInvoiceFromDate, filterInvoiceToDate, filterDueFromDate, filterDueToDate, filterFromAmount, filterToAmount, filterCustomerName, amountError } = options
        filterType = filterType || []
        sorter = sorter || {}

        const shouldShowQBTab = this.shouldShowQBTab()

        let source = shouldShowQBTab ? "AION|ARUPLOAD" : "AION|ARUPLOAD|QUICKBOOKS|CODAT"

        var body = {
            "size": pagination.pageSize,
            "page": pagination.current - 1,
            "source": source,
            "viewBy": "FILTER_SOURCE",
            "sortDirection": sortDirection
        }

        if (filterInvoiceFromDate) {
            body.viewBy = "FILTER_INVOICE_DATE"
            body.invoiceFromDate = filterInvoiceFromDate
            body.invoiceToDate = filterInvoiceToDate
        }
        if (filterDueFromDate) {
            body.viewBy = "FILTER_DUE_DATE"
            body.dueFromDate = filterDueFromDate
            body.dueToDate = filterDueToDate
        }

        if ((filterFromAmount || filterToAmount) && !amountError) {
            body.viewBy = "FILTER_AMOUNT"
            body.fromAmount = filterFromAmount
            body.toAmount = filterToAmount
        }

        body.viewBy = "FILTER_CUSTOMER"
        body.customerName = this.customer.displayName

        if (sorter.field) {
            if (sorter.field === sortFieldname) {
                body.sortDirection = (sortDirection === "DESC") ? "ASC" : "DESC"
            } else {
                body.sortDirection = "ASC"
            }
            body.sortFieldname = sorter.field
        } else {
            if (sortFieldname) {
                body.sortFieldname = sortFieldname
            }
            body.sortDirection = sortDirection
        }

        if (searchTerm && searchTerm != "") {
            body.searchStr = searchTerm
        }

        if (filterType.length > 0) {
            var emailConstants = ["Delivered", "Opened", "Clicked"]
            var invStatus = filterType.filter(item => !emailConstants.includes(item)).join("|")
            var emailStatus = filterType.filter(item => emailConstants.includes(item)).join("|")

            // Check if filtering by date
            if (filterType.find(item => emailConstants.includes(item))) {
                body.viewBy = (body.viewBy == "FILTER_TXN_DATE") ? "FILTER_EMAIL_STATUS_TXN_DATE" : "FILTER_EMAIL_STATUS"
            } else {
                body.viewBy = (body.viewBy == "FILTER_TXN_DATE") ? "FILTER_STATUS_TXN_DATE" : "FILTER_STATUS"
            }
            body.status = invStatus
            body.emailStatus = emailStatus
        }

        this.setState({ ...options, invoicesLoading: true })

        console.log('getInvoices body', body)
        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/getInvoices`, null, body, (err, resp) => {
            try {
                const data = resp || {}
                console.log("/getInvoices", data)
                if (data.result) {
                    var invoices = this.state.pagination.current > 1 ? this.state.invoices.concat(data.invoices) : data.invoices
                    var moreInvoices = invoices.length == data.count ? false : true
                    this.setState({
                        invoices: invoices,
                        invoicesLoading: false,
                        pagination: {
                            ...pagination,
                            total: data.count
                        },
                        // sorter: _.isEqual(sorter, {}) ? this.state.sorter : sorter,
                        sortDirection: body.sortDirection,
                        sortFieldname: body.sortFieldname,
                        showClear: searchTerm ? true : false,
                        moreToLoad: moreInvoices
                    })

                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getInvoices", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    downloadCSVStmt = (options) => {
        this.setState({ csvDownloading: true })

        const businessInfo = this.props.aionStore?.business?.businessProfile?.businessInfo;
        var { name } = businessInfo

        var { searchTerm, filterInvoiceFromDate, filterInvoiceToDate, filterDueFromDate, filterDueToDate, filterFromAmount, filterToAmount, filterCustomerName } = options

        if (!searchTerm) searchTerm = this.state.searchTerm
        if (!filterInvoiceFromDate) filterInvoiceFromDate = this.state.filterInvoiceFromDate
        if (!filterInvoiceToDate) filterInvoiceToDate = this.state.filterInvoiceToDate

        if (!filterDueFromDate) filterDueFromDate = this.state.filterDueFromDate
        if (!filterDueToDate) filterDueToDate = this.state.filterDueToDate

        if (!filterCustomerName) filterCustomerName = this.state.filterCustomerName

        if (!filterFromAmount) filterFromAmount = this.state.filterFromAmount
        if (!filterToAmount) filterToAmount = this.state.filterToAmount

        var body = {}

        body.invoiceFromDate = filterInvoiceFromDate
        body.invoiceToDate = filterInvoiceToDate
        body.customerName = filterCustomerName
        body.dueFromDate = filterDueFromDate
        body.dueToDate = filterDueToDate
        body.fromAmount = filterFromAmount
        body.toAmount = filterToAmount
        body.searchStr = searchTerm

        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/exportInvoices`, null, body, (err, resp) => {
            try {

                this.setState({ csvDownloading: false })
                const data = resp || {}
                // console.log("/getInvoices", data)
                console.log("invoice/exportInvoices", JSON.stringify(data))
                if (err) throw Error("We had trouble downloading your statement. Please try again.");
                const blob = new Blob([data], {
                    type: 'application/vnd.ms-excel',
                });
                const fileURL = URL.createObjectURL(blob);
                saveAs(fileURL, `Invoice Report - ${name}.csv`);

            } catch (error) {
                console.log("ERRR getInvoices", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    clearAll = () => {
        this.setState({
            dueFromDate: null,
            dueToDate: null,
            filterDueFromDate: null,
            filterDueToDate: null,
            searchTerm: null,
            invoiceFromDate: null,
            invoiceToDate: null,
            filterInvoiceFromDate: null,
            filterInvoiceToDate: null,
            fromAmount: null,
            toAmount: null,
            filterFromAmount: null,
            filterToAmount: null,
            customerName: null,
            filterCustomerName: null,
            clearAll: null,
            pagination: {
                current: 1,
                pageSize: 10
            }
        }, () => this.fetchInvoices({ ...this.state }))
    }

    showClearAll = () => {
        var { searchTerm, filterInvoiceFromDate, filterInvoiceToDate, filterDueFromDate, filterDueToDate, filterFromAmount, filterToAmount, filterCustomerName } = this.state
        if (searchTerm || filterInvoiceFromDate || filterDueFromDate || filterFromAmount || filterCustomerName) {
            this.setState({ clearAll: true }, () => this.fetchInvoices({ ...this.state }))
        }
        else if (!searchTerm && !filterInvoiceFromDate && !filterDueFromDate && !filterFromAmount && !filterCustomerName) {
            this.setState({ clearAll: false }, () => this.fetchInvoices({ ...this.state }))
        }
    }

    viewDocument = (document) => {
        var { msas, documents, workOrders } = this.state
        switch (document.type) {
            case "Purchase Order":
                var po = workOrders.find(wo => wo.id === document.id)
                this.setState({ editPO: po, showAddDocument: true, viewDocument: true });
                break;
            case "Contract or agreement":
                var msa = msas.find(msa => msa.id === document.id)
                console.log("CustomerDetails editDocument msa", msa)
                this.setState({ editMSA: msa, showAddDocument: true, viewDocument: true });
                break;
            default:
                var doc = documents.find(doc => doc.id === document.id)
                this.setState({ editDocument: doc, showAddDocument: true, viewDocument: true });
                break;
        }
    }

    editDocument = (document) => {
        var { msas, documents, workOrders } = this.state
        console.log("CustomerDetails editDocument", document)
        console.log("CustomerDetails editDocument msas", msas)
        switch (document.type) {
            case "Purchase Order":
                var po = workOrders.find(wo => wo.id === document.id)
                this.setState({ editPO: po, showAddDocument: true });
                break;
            case "Contract or agreement":
                var msa = msas.find(msa => msa.id === document.id)
                console.log("CustomerDetails editDocument msa", msa)
                this.setState({ editMSA: msa, showAddDocument: true });
                break;
            default:
                var doc = documents.find(doc => doc.id === document.id)
                this.setState({ editDocument: doc, showAddDocument: true });
                break;
        }
    }

    handleSearchChange = (e) => {
        this.setState({ [e.target.id]: e.target.value })

        const pagination = {
            current: 1,
            pageSize: 10
        }
        this.fetchInvoices({
            searchTerm: e.target.value,
            pagination
        })
    }

    loadMoreInvoices = () => {
        var updatedState = {
            dateType: "Custom",
            loading: true,
            pagination: {
                current: this.state.pagination.current + 1,
                pageSize: 10
            }
        }
        this.fetchInvoices({ ...this.state, ...updatedState })
    }

    render() {
        const { theme } = this.props
        var { showAddDocument, msas, documentsLoading, invoicesLoading, invoices, enablePaymentReminder, selectedRowKeys, viewDocument,
            activeCustomers, editMSA, editPO, editDocument, allDocuments, showDeactivateModal, showActivateModal, moreToLoad } = this.state
        var customer = this.customer
        var { displayName, webSite, shippingAddress, paymentTerms, billingAddress, invoiceRecipientEmail, fullyQualifiedName, 
            phone, primaryEmailAddress, parentCustomerName } = this.customer;

        const poColumns = [
            {
                title: 'Document ID',
                dataIndex: 'name',
                key: 'name',
                render: (name, record) => {
                    // const customerName = renderCustomerName(item, record.primaryEmailAddress && record.primaryEmailAddress.address &&
                    // record.phone && record.msauploaded && record.pouploaded)

                    return <a style={{ textDecoration: "underline" }} onClick={() => this.viewDocument(record)}>{name}</a>
                },
            },
            {
                title: 'Type',
                dataIndex: 'subType',
                key: 'subType',
                render: (subType, document) => subType || document.type
            },
            // {
            //     title: 'Status',
            //     dataIndex: 'verificationStatus',
            //     key: 'verificationStatus',
            //     align: 'right',
            //     render: (verificationStatus, document) => (document.type === 'Purchase Order' ? <WOStatusTag status={verificationStatus} /> : '--')
            // },
            {
                key: 'action',
                width: 100,
                align: 'right',
                render: (text, document) => (
                    !getResourcePerm("Support.Console").aionSystemUser && <Flex end style={{ paddingRight: 12 }}>
                        <ImageButton src={Edit} onClick={() => this.editDocument(document)} />
                    </Flex>
                ),
            }
        ];


        return (
            <FlexColumn style={{ width: '100%' }} start grow>
                <PageHeader
                    titleText={displayName}
                    titleTag={customer.active === true ?
                        { text: 'Active', color: '#318F2F', background: '#F5F9F5' }
                        :
                        { text: 'Inactive' }
                    }
                    back
                    // backText='Your Customers'
                    marginBottom='24px'
                    marginLeft='36px'
                    marginRight='24px'
                />

                {/* <Flex start centerHorizontally style={{ marginLeft: 36 }}>
                    <Image src={Location} />
                    <Text margin='0 8px'>{addressObjectToStr(shippingAddress)}</Text>
                    {
                        webSite &&
                        <>
                            <Image width='2px' height='24px' src={Pipe} margin='0 11px' />
                            <Image src={Link} margin='0 8px 0 0' />
                            <Text underline primary><a href={webSite}>{webSite}</a></Text>
                        </>
                    }
                </Flex> */}

                <FlexColumn className='main-padding'>
                    <Tabs 
                        // className='grow-tabs' 
                        size="large" 
                        onChange={this.handleTabChange} 
                        // style={{ padding: '0 24px 36px 36px', marginTop: 24, flexGrow: 1, display: 'flex', flexDirection: 'column' }}
                        tabBarExtraContent={customer.active === true ?
                            <Flex start gap='24px'  centerHorizontally>
                                <TextButton color='#E52D2D' text="DEACTIVATE" onClick={() => this.setState({ showDeactivateModal: true })} />
    
                                <ImageButton
                                    src={Edit}
                                    onClick={() => {
                                        this.props.history.push({
                                            pathname: '/receivables/customers/create',
                                            state: { customer, details: true }
                                        })
                                    }} />
                                <Button permtype="Receivables.Invoices" text='CREATE INVOICE' onClick={() => this.props.history.push({
                                    pathname: '/receivables/invoices/create',
                                    state: { customer }
                                })} />
                            </Flex>
                            :
                            <TextButton color='#318F2F' text="ACTIVATE" onClick={() => this.setState({ showActivateModal: true })} />
                        }
                    >
                        <TabPane tab="Details" key="Details">
                            <Flex between fullWidth gap='24px'>
                                <CardContainer margin='12px 0 0 0' fullWidth>
                                    <FlexColumn gap='24px'>
                                        <span className="section-heading-text">CUSTOMER DETAILS</span>

                                        <Flex start wrap gap='24px'>
                                            <LabeledInput nomargin label='Payment terms' type='read-only' value={paymentTerms || "--"} width='432px' />
                                            <LabeledInput nomargin label='Website' type='read-only' value={webSite || "--"} width='432px' />
                                            <LabeledInput nomargin label='Billing address' type='read-only' value={billingAddress ? addressObjectToStr(billingAddress) : '--'} width='432px' />
                                            <LabeledInput nomargin label='Shipping address' type='read-only' value={shippingAddress ? addressObjectToStr(shippingAddress) : '--'} width='432px' />
                                            {parentCustomerName && <LabeledInput nomargin label='Parent name' type='read-only' value={parentCustomerName} width='432px' />}
                                        </Flex>

                                        <div style={{ height: 1, width: '100%', background: '#F2F2F2' }}></div>
                                        <span className="section-heading-text">INVOICING DETAILS</span>

                                        <Flex>
                                            <LabeledInput
                                                nomargin
                                                label='Send invoices to'
                                                type='read-only'
                                                value={(invoiceRecipientEmail && invoiceRecipientEmail.length > 0) ? invoiceRecipientEmail.join(', ') : '--'}
                                                instruction='The invoice will be sent to these recipients by default. You can add more recipients while creating the invoice'
                                            />
                                        </Flex>
                                    </FlexColumn>
                                </CardContainer>

                                <CardContainer margin='12px 0 0 0' minWidth='296px' width='296px'>
                                    <FlexColumn start fullWidth>
                                        <span className="section-heading-text">CONTACT DETAILS</span>
                                        <LabeledInput type='read-only' value={fullyQualifiedName} />
                                        <div style={{ height: 1, width: '100%', background: '#F2F2F2', margin: '16px 0' }}></div>
                                        <FlexColumn start gap='24px' fullWidth>
                                            {
                                                phone &&
                                                <Flex start gap='8px' fullWidth>
                                                    <Image src={Call} />
                                                    {/* <Text underline primary><a href={phone}>{phone}</a></Text> */}
                                                    <Text>{phone}</Text>
                                                </Flex>
                                            }
                                            {
                                                primaryEmailAddress &&
                                                <Flex start gap='8px' fullWidth>
                                                    <Image src={Email} />
                                                    {/* <Text underline primary><a href={primaryEmailAddress.address}>{primaryEmailAddress.address}</a></Text> */}
                                                    <Text break fullWidth>{primaryEmailAddress.address}</Text>
                                                </Flex>
                                            }
                                        </FlexColumn>
                                    </FlexColumn>
                                </CardContainer>
                            </Flex>
                        </TabPane>

                        <TabPane tab="Documents" key="Documents">
                            <CardContainer fullWidth margin='12px 0 0 0' fullHeight>
                                <Skeleton loading={documentsLoading} active title={false} paragraph={{ rows: 13 }}>
                                    {
                                        allDocuments && allDocuments.length > 0 ?
                                            <FlexColumn start>
                                                <Flex between>
                                                    <FlexColumn start>
                                                        <Text heading>Documents</Text>
                                                        <Text margin='0 0 24px'>Contracts, purchase orders, statement of work orders and all customer related files</Text>
                                                    </FlexColumn>
                                                    <TextButton text='ADD NEW' margin='14px 0 0' rightIcon={<Image src={Add} />} onClick={() => this.setState({ showAddDocument: true })} />
                                                </Flex>
                                                <Table
                                                    id="wo-table"
                                                    className='borderless-table'
                                                    scroll={{ y: '700px', x: '100%' }}
                                                    bordered={false}
                                                    size="middle"
                                                    columns={poColumns}
                                                    dataSource={allDocuments || []}
                                                    rowKey='ponumber'
                                                    pagination={false}
                                                />
                                            </FlexColumn>
                                            :
                                            <FlexColumn fullWidth fullHeight center gap='24px'>
                                                <Image src={Document} width='48px' height='48px' />
                                                <FlexColumn center style={{ width: 400 }}>
                                                    <Text caption size='20px' height='40px'>Add documents</Text>
                                                    <Text center>Add the supporting documents for this customer. These could be MSAs, contracts or Purchase Orders</Text>
                                                </FlexColumn>
                                                <TextButton text='ADD DOCUMENT' rightIcon={<Image src={Add} />} onClick={() => this.setState({ showAddDocument: true })} />
                                            </FlexColumn>
                                    }
                                </Skeleton>
                            </CardContainer>
                        </TabPane>

                        <TabPane tab="Invoices" key="Invoices">
                            <InvoiceTable
                                loading={invoicesLoading}
                                pagination={false}
                                onLoadMore={() => this.loadMoreInvoices()}
                                moreToLoad={moreToLoad}
                                invoices={invoices}
                                handleRowSelection={this.handleRowSelection}
                                showRowSelection={enablePaymentReminder}
                                selectedRowKeys={selectedRowKeys}
                                showStatus
                                ctaContent={
                                    <SearchAndFilter
                                        invoices={invoices}
                                        customers={activeCustomers}
                                        fetchInvoices={this.fetchInvoices}
                                        handleSearchChange={this.handleSearchChange}
                                        customerName={displayName}
                                        hideCustomers
                                    />
                                }
                            />
                        </TabPane>
                    </Tabs>
                </FlexColumn>

                <Modal
                    visible={showDeactivateModal}
                    footer={null}
                    closable={false}
                    width={456}
                    destroyOnClose={true}
                    onCancel={() => {
                        this.setState({ showDeactivateModal: false })
                    }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <Deactivate
                        customer={this.customer}
                        onCancel={() => this.setState({ showDeactivateModal: false })}
                        onDeactivated={() => {
                            this.setState({ showDeactivateModal: false })
                            window.history.back()
                        }} />
                </Modal>

                <Modal
                    visible={showActivateModal}
                    footer={null}
                    closable={false}
                    width={456}
                    destroyOnClose={true}
                    onCancel={() => {
                        this.setState({ showActivateModal: false })
                    }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <Activate
                        customer={this.customer}
                        onCancel={() => this.setState({ showActivateModal: false })}
                        onActivated={() => {
                            this.setState({ showActivateModal: false })
                            window.history.back()
                        }}
                    />
                </Modal>

                <DocumentDrawer
                    visible={showAddDocument}
                    onClose={() => this.setState({ showAddDocument: false, editPO: null, editMSA: null, editDocument: null, viewDocument: false })}
                    onSubmit={() => this.setState({ showAddDocument: false, viewDocument: false }, this.getDocuments)}
                    msas={msas}
                    customerId={this.customer.customerId}
                    customer={this.customer}
                    msa={editMSA}
                    po={editPO}
                    document={editDocument}
                    view={viewDocument}
                />
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(CustomerDetails)))