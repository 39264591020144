import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

// Components

import { FlexColumn } from '../../Reusable/Container'
import { Text } from '../../Reusable/Text'
import { Popover, Skeleton } from 'antd'
import styled from 'styled-components'
import { TextButton } from '../../Reusable/Button';
import ABLAvailableFunds from '../../Credit/ABLAvailableFunds'
import AvailableFunds from '../../Credit/AvailableFunds'
import { Button } from '../../Reusable/Button';

import { apiGET } from '../../../Utils/api'
// const [businessInfo, setBusinessInfo] = useState({})


export const DescItem = styled.div`
    display: flex;
    justify-content: flex-end;
`

export const DescLabel = styled.span`
    fontWeight: 500;
    fontSize: 16;
`

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
})

class AvailableFundsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            documentsLoading: false
        }
    }

    generateReport = () => {
        var { businessId, productType } = this.props
        const headers = {
            businesskey: businessId
        }
        this.setState({ documentsLoading: true });
        this.props.closeAvailableFunds();
        apiGET(`/${productType}/funds/view?requestreport=true`, headers, (err, resp) => {
            try {
                const data = resp.data
                console.log(`/${productType}/funds/view`, data);
                this.setState({ documentsLoading: false })
            } catch (error) {
                console.log("ERRR", error.stack)
            }
        })
    }

    render() {
        var { availableFunds, productType } = this.props
        return (
            <FlexColumn>
                {
                    productType == "arfinancing" &&
                    <AvailableFunds AvailableFunds={availableFunds} />
                }
                {
                    productType == "assetbasedloan" &&
                    <ABLAvailableFunds AvailableFunds={availableFunds} />
                }
                <Text size='20px'>
                    <Skeleton loading={this.state.documentsLoading} active title={false} paragraph={{ rows: 1 }}>
                        <Popover content={(
                            <FlexColumn>
                                <span>Once submitted please check the Documents Tab for the statement. The generation of statement might take some time depending upon the data.</span>
                            </FlexColumn>
                        )}>
                            <Button
                                solid
                                style={{ height: 45, width: "250px", margin: "10px 10px 10px 0" }}
                                onClick={this.generateReport} text="Generate Report" />
                        </Popover>
                    </Skeleton>
                </Text>
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer,
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AvailableFundsModal))