import React, { Component } from 'react'
import hexToRgba from 'hex-to-rgba';
import styled, { withTheme } from 'styled-components';
import { Paragraph } from './Text';
import { Flex } from './Container';
import { ImageButton } from './Button';
import Close from '../../Images/close-dark.svg'

class Banner extends Component {

    render() {
        const StyledContainer = styled.div`
            box-sizing: border-box;
            position: relative;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            padding: ${props => props.padding || '12px 24px'};
            margin: ${props => props.margin || '5px 0'};
            gap: 8px;

            background: ${props => props.alert ? '#FEF5F5' : props.background || '#F5F6FE'};
            /* Solid/Primary Blue/20 */

            border-left: ${props => props.alert ? '2px solid #E52D2D' : props.borderColor ? `2px solid ${props.borderColor}` : '2px solid #D7DCFB'};
            border-radius: 4px;
        `;

        const CloseButton = styled.div`
            background: none;
            border: none;
            color: white;
            font-size: 16px;
            cursor: pointer;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            &:hover {
                color: #ddd; // Lighter color on hover
            }
        `;

        return (
            <StyledContainer {...this.props}>
                <Flex start gap='8px' fullWidth>
                    <Flex start gap='8px'>
                        {this.props.icon}
                        <Paragraph noMargin fontWeight={400} color={this.props.alert ? '#E52D2D' : (this.props.color || this.props.theme.colors.primary2)} style={{ lineHeight: "24px" }}>
                            {this.props.message}
                        </Paragraph>
                    </Flex>
                    {this.props.cta}
                </Flex>

                {
                    this.props.onClose &&
                    <CloseButton>
                        <ImageButton src={Close} onClick={this.props.onClose} />
                    </CloseButton>
                }
            </StyledContainer>
        )
    }
}

export default withTheme(Banner);