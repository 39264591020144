import React, { Component } from 'react'
import { connect } from 'react-redux'
import { message } from 'antd'
import { Auth } from 'aws-amplify'
import { withTheme } from 'styled-components'

import { Layout } from 'antd'

// Util
import { containerDimensions } from '../../Styles/theme'

// Components
import { Button } from '../Reusable/Button'
import { Paragraph, Title } from '../Reusable/Text'
import { FlexColumn, SideBar, AbstractSideBar } from '../Reusable/Container'
import { LabeledInput } from '../Reusable/Input'
import AionLogo from '../Reusable/Image'

const { Content } = Layout

class ConfirmForgotPassword extends Component {
    constructor(props) {
        super(props)
        this.state = {code: ''}
    }
    
    handleChange = (event) => {
        if(event.target.id === "code") {
            this.setState({code: event.target.value})
        } else if(event.target.id === "password") {
            this.setState({password: event.target.value})
        }        
    }
    
    handleSubmit = async (event) => {
        if(this.state.code === '') {
            alert("Please enter a code.")
            return
        }
        if(this.state.password === '') {
            alert("Please enter a password.")
            return
        }
        this.setState({loading: true})
        try {
            const username = this.props.store.Username
            const password = this.state.password
            const code = this.state.code
            await Auth.forgotPasswordSubmit(username, code, password)
            message.success('Password update complete! Sign in with your credentials.')
            this.props.history.push('/')
        } catch (error) {
            this.setState({loading: false})
            console.log('error confirming sign up', error)
            message.error(`Error confirming sign up. ${error.message}`)
        }
        event.preventDefault()
    }

    render() {
        const { theme } = this.props
        const codeDestination = this.props.store.CodeDestination
        return (
            <Layout style={{ minHeight: '100vh', minWidth: '100vw', background: "transparent" }}>
                <SideBar />
                <Content>
                    <AionLogo />
                    <FlexColumn center style={{height: "80%"}}>
                        <div style={{ width: "400px" }}>
                            <Title level={4}>Confirmation Code</Title>
                            <Paragraph >A confirmation code has been sent to { codeDestination || "your phone/email" }.</Paragraph>
                            <FlexColumn onSubmit={this.handleSubmit} className="container" style={{flexDirection:'column'}}>
                                <LabeledInput
                                    label="Confirmation Code"
                                    id="code" 
                                    type="code" 
                                    placeholder="Enter Code" 
                                    onChange={this.handleChange} 
                                />
                                <LabeledInput
                                    label="New Password"
                                    id="password" 
                                    type="password" 
                                    placeholder="Enter Your New Password" 
                                    onChange={this.handleChange} 
                                />
                                <Button permtype="Override" style={{alignSelf: 'center', width: '100%', margin: '10px 0'}} solid loading={this.state.loading} onClick={this.handleSubmit} text='Update Password' />
                            </FlexColumn>
                        </div>
                    </FlexColumn>
                </Content>
                <AbstractSideBar 
                    width={containerDimensions.authPage.abstractSidebarWidth}
                />
            </Layout>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(withTheme(ConfirmForgotPassword))