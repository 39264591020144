import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { withTheme } from 'styled-components';
import {
    Card,
    Form,
    Result,
    message,
    Typography,
    List,
    Alert,
    Tag,
    Divider
} from 'antd';

// Utils
import { apiPOST, apiGET } from './../../../Utils/api';
import { getCustomerName } from './../../../Utils/util';
import { SYSTEM_COLORS, SYSTEM_STYLES } from './../../../Utils/stylingAssets';

// Actions
import { addDataToStore, CREDIT_SAVE_DATA } from '../../../Actions/actions';

// Components
import EditCustomersTable from './EditCustomersTable';
import { FlexColumn } from '../../Reusable/Container';
import { Button } from '../../Reusable/Button';
import { Title, Paragraph } from '../../Reusable/Text';


const { Text} = Typography;

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

class AddAllCustomers extends Component {

    constructor(props) {
        super(props);

        this.state = {
            current: 0,
            hasSubmitted: false,
            customersLoaded: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCustomerReviewed = this.handleCustomerReviewed.bind(this);
    }

    componentDidMount() {
        this.fetchCustomers(false);
    }

    fetchCustomers(loadNext) {
        // Fetch customer list
        // Check session and fetch attributes
        const headers = {
            businesskey: this.props.aionStore.BusinessUniqueKey
        }
        apiGET("/financing/customers", headers, (err, resp) => {
            try {
                const data = resp.data;
                if(data.success) {
                    this.props.dispatch(addDataToStore(CREDIT_SAVE_DATA, data))
                    this.setState({customersLoaded: true, selectedCustomers: this.prepareSelectedCustomers()});
                    if(loadNext) {
                        if(this.props.onboarding) {
                            this.props.loadNext();
                        } else {
                            this.next();
                        }
                    };
                } else {
                    throw Error("Could not fetch attributes.")
                }
            } catch (error) {
                console.log("ERRR", error.stack)
            }
        })
    }

    prepareSelectedCustomers() {
        const { store } = this.props;

        const preApprovedCustomers = store.TopGradeOfCustomers || [];
        // Get all previously submitted customers
        const submittedCustomers = (store.RequestedCustomers || []).concat((store.PendingCustomers || [])).concat((store.ApprovedCustomers || [])).concat((store.RejectedCustomers || []));

        const customerList = [];
        preApprovedCustomers.sort((a, b) => (getCustomerName(a)).localeCompare(getCustomerName(b)))
        preApprovedCustomers.forEach(customer => {
            if (!submittedCustomers.some(submittedCustomer => submittedCustomer.Id === customer.Id)) {
                customerList.push(customer)
            }
        })

        return customerList;
    }

    handleSubmit(event) {
        this.setState({ hasSubmitted: true });
        if (!this.handleCustomerReviewed(true))
            return;

        // Save customer data
        this.props.dispatch(addDataToStore(CREDIT_SAVE_DATA, { onboarding: { selectedCustomers: this.state.selectedCustomers }}))
        var payload = {
            customers: this.state.selectedCustomers
        };
        this.setState({ confirmLoading: true })
        const headers = {
            businesskey: this.props.aionStore.BusinessUniqueKey
        }
        apiPOST('/financing/customers/add', headers, payload, (err, resp) => {
            this.setState({ confirmLoading: false });
            if (!err) {
                try {
                    const data = resp.data;
                    if (data.success) {
                        // message.success('Submitted successfully!');
                        this.fetchCustomers(true);
                    } else {
                        throw Error("Could not fetch attributes.")
                    }
                } catch (error) {
                    alert("Sorry, we had trouble processing your request. Please try again.");
                }
            } else {
                alert("Sorry, we had trouble processing your request. Please try again.");
            }
        })
        event.preventDefault();
    }

    handleCustomerReviewed(customerId) {
        // Check if all customers were reviewed (all fields are filled)
        if (this.state.hasSubmitted) {
            var reviewComplete = true;
            this.state.selectedCustomers.forEach(customer => {
                if (!customer.ReviewComplete) {
                    reviewComplete = false
                }
            })
            console.log("handleCustomerReviewed", this.state.selectedCustomers, reviewComplete)
            if (!reviewComplete) {
                this.setState({ showError: true })
                return false;
            } 
            this.setState({ showError: false })
            return true;
        } else {
            var selectedCustomers = this.state.selectedCustomers;
            selectedCustomers.forEach(customer => {
                if (customer.Id == customerId) customer.ReviewComplete = true;
            });
            this.setState({ selectedCustomers: selectedCustomers });
            console.log("selectedCustomers",customerId , selectedCustomers)
        }
    }

    next() {
        const current = this.state.current + 1;
        this.setState({ current });
    }

    render() {
        const { current } = this.state;
        const { store } = this.props;

        const steps = (count) => {
            const tempFormData = this.props.aionStore.TempFormData ? this.props.aionStore.TempFormData : {};
            const formData = tempFormData["add_customer"] ? tempFormData["add_customer"] : { reviewerDetails: {}, senderDetails: {} };
            switch (count) {
                case 0:
                    return {
                        content: (
                            <div>
                                <Paragraph id='add-customers-note'>Please note: These customers are <b>pre-approved</b> by our systems. Other customers from your accounting software will not show here if they were not approved by our systems. If you need to add any other customer please reach out to contact@aionfi.com</Paragraph>
                                <div style={{marginTop: '40px'}}>
                                    <EditCustomersTable
                                        onCustomerReviewed={this.handleCustomerReviewed}
                                        customersLoaded={this.state.customersLoaded} 
                                        customerList={this.state.selectedCustomers} />
                                </div>
                            </div>
                        )
                    }
                case 1:
                    const selectedCustomers = this.state.selectedCustomers || [];
                    return {
                        title: 'Purchase Orders',
                        content: (
                            <div style={{padding: '0 20px'}}>
                                <div style={{ padding: '0 0 20px', width: '400px' }}>
                                    <Alert
                                        message="A purchase order is required to complete assessment. You can also add this later by going to the 'Purchase Orders' section."
                                        type="warning"
                                    />
                                    {/* You will be able to take an advance on approved customer invoices */}
                                </div>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={selectedCustomers}
                                    bordered
                                    style={{width: '80%'}}
                                    renderItem={item => (
                                    <List.Item
                                        actions={[
                                            <Link to="/receivables/customers/add" key="customers">
                                                <Button type="ghost" onClick={() => {this.setState({ showAddPO: true, poCustomer: item })}}>Submit Purchase Order</Button>
                                            </Link>
                                        ]}
                                    >
                                        <List.Item.Meta
                                            title={getCustomerName(item)}
                                        />
                                    </List.Item>
                                    )}
                                />
                            </div>
                        ),
                    }
                case 2:
                    return {
                        title: 'Confirmation',
                        content: (
                            <Result
                                status="success"
                                title="Submitted for review"
                                subTitle={`We'll notify you with next steps once verification is complete.`}
                                extra={[
                                    <Link to="/receivables/customers" key="customers">
                                        <Button size="large" type="primary">
                                            Done
                                        </Button>
                                    </Link>
                                ]}
                            />
                        ),
                    }
            }
        }

        let pageDescription = '';
        if (this.state.current == 0)
            pageDescription = 'Click on the Edit button to update the information for each customer';
        else if (this.state.current == 1)
            pageDescription = 'Add a purchase order/ work order / contract for each customer.'

        return (
            <>
                <FlexColumn style={{ minHeight: "85vh", justifyContent: "space-between", marginRight: 20 }}>
                    <div style={{ margin: "20px 0 50px 0" }} >
                        {this.props.onboarding ? <Tag color={SYSTEM_COLORS.secondary3} style={{margin: '20px 0'}}>{"Step two".toUpperCase()}</Tag> : null}
                        <Title level={4}>Add Customers</Title>
                        <Paragraph color={this.props.theme.colors.systemGray} level={3}>{pageDescription}</Paragraph>
                        <Divider />
                        <div style={{ paddingTop: '5px'}}>
                            {steps(this.state.current).content}
                        </div>
                        <Form.Item>
                            {this.state.showError ? <Alert
                                message="Review required"
                                description="A review of all customers is needed before submitting."
                                type="error"
                                showIcon
                            /> : null}
                        </Form.Item>
                    </div>
                    {(current === 0) && (
                        <Button solid loading={this.state.confirmLoading} type="primary" disabled={!this.state.selectedCustomers || this.state.selectedCustomers.length == 0} onClick={this.handleSubmit} text='Submit' />
                    )}
                    {(current === 1) && (
                        <Button solid onClick={() => this.next()} text='Done' />
                    )}
                </FlexColumn>
                {/* <AddPurchaseOrder
                    wrappedComponentRef={this.saveFormRef}
                    visible={this.state.showAddPO}
                    onCancel={() => { this.setState({ showAddPO: false }) }}
                    poCustomer={this.state.poCustomer}
                    onCreate={() => { this.setState({ showAddPO: false }) }}
                /> */}
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        store: state.creditAppReducer,
        aionStore: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(AddAllCustomers)));