import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled, { withTheme } from 'styled-components'
import { CardContainer } from '../Reusable/CardContainer'
import { Flex, FlexColumn } from '../../Reusable/Container'
import environment from '../../../environment'

import AppIcon from '../../../Images/product-menu/receivables-menu-icon-active.png'
import { apiPOSTReq } from '../../../Utils/api'
import CardHeader from '../Reusable/CardHeader'
import ARAgingChart from '../Reusable/ARAgingChart'
import ARAgingCustomer from '../Reusable/ARAgingCustomer'
import { TextButton } from '../../Reusable/Button'
import { Image } from '../../Reusable/Image'
import ArrowRight from "../../../Images/arrow-right.png"
import { Text } from '../../Reusable/Text'
import { toCurrency } from '../../../Utils/util'

class ARAging extends Component {
    state = {
        loading: true,
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {

        apiPOSTReq(`${environment.iveBaseUrl}/dashboard/getARAgingReport`, {}, null, (err, resp) => {
            try {
                this.setState({ loading: false })
                const data = resp
                if (data.result) {
                    if (err) throw new Error(err)
                    this.setState({ arAgingData: data })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getAccounts", error, err, resp)
            }
        })
    }

    render() {
        const { store, theme } = this.props
        const { arAgingData } = this.state
        console.log("arAgingData", arAgingData)

        var totalARBal = arAgingData?.arAgingReport?.totalARBalance
        return (
            <CardContainer>
                <FlexColumn>
                    <CardHeader 
                        imgSrc={AppIcon}
                        titleText='Receivables' 
                        desc='Outstanding invoices'
                        path='receivables/invoices'
                        secondaryContent={
                            <FlexColumn centerVertically>
                                {totalARBal && <Text size='16px'>Outstanding: <span style={{ fontWeight: 600 }}>${toCurrency(totalARBal)}</span></Text>}
                                {totalARBal > 0 && <TextButton onClick={() => this.props.history.push('receivables/invoices')} rightIcon={<Image src={ArrowRight} />} text="VIEW INVOICES" />}
                            </FlexColumn>
                        }
                    />
                    <Flex style={{ width: this.props.theme.dashboardCard.large }}>
                        {arAgingData?.arAgingReport && <ARAgingChart arAgingReport={arAgingData?.arAgingReport} />}
                        <div style={{ width: '77%' }}>
                            {arAgingData?.arAgingReport && <ARAgingCustomer arAgingReport={arAgingData?.arAgingReport} /> }
                        </div>
                        
                    </Flex>
                    
                </FlexColumn>
                
            </CardContainer>
            
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(ARAging)))