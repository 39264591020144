import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled, { withTheme } from 'styled-components'
import moment from 'moment'
import { Modal, Select, Space } from 'antd'
import { random } from 'underscore'
import Vouched from '../Vouched/Index'
import { InfoCircleOutlined } from '@ant-design/icons';

import { Flex, FlexColumn } from '../../Reusable/Container'
import FlowViewComponent from '../../Reusable/Refresh/FlowViewComponent'
import { addressObjectToStr, countryCodeMapUSFirst, countryCodeToNameMap, formatPhoneTextV2, formatSSN, getAddressObj, STATES_ABBR_MAP, STATES_MAP, toCurrency } from '../../../Utils/util'
import { LabeledInput } from '../../Reusable/Input'
import { Text } from '../../Reusable/Refresh/Text'
import { addDataToStore, PERSONAL_INFO, REMOTE_SAVE_DATA } from '../../../Actions/actions'
import { TextButton } from '../../Reusable/Button'
import Banner from '../../Reusable/Banner'
import Divider from '../../Reusable/Refresh/Divider'
import { ErrorAlert } from '../../Reusable/Alert'
import ModalClose from '../../../Images/modal-close.png'
import { apiPOSTReq } from '../../../Utils/api'
import environment from '../../../environment'

const { Option } = Select
export const dateFormatList = ['MM/DD/YYYY', 'MM/DD/YY', 'MM-DD-YYYY', 'MM-DD-YY']

class CreditUserProfile extends Component {

    state = {
        submitLoading: false,
        showSuccessBanner: false
    }

    componentDidMount() {
        var { onboardingStore, aionStore, flowView } = this.props
        var { firstName, lastName, email, phone } = onboardingStore.personalInfo
        const { UserInfo } = aionStore;

        if (!firstName || !lastName || !email || !phone) {
            this.props.dispatch(addDataToStore(PERSONAL_INFO, { firstName: UserInfo.FirstName, lastName: UserInfo.LastName, email: UserInfo.Email, phone: UserInfo.Phone }))
        }
        this.props.dispatch(addDataToStore(PERSONAL_INFO, { isContractOwner: false}))

        const { business } = aionStore;
        var { businessProfile } = business;
        var { primaryApplicantInfo } = businessProfile || {};
        primaryApplicantInfo = primaryApplicantInfo || {}

        console.log('onboardingStore.personalInfo', onboardingStore.personalInfo)
        console.log('onboardingStore.personalInfo primaryApplicantInfo', primaryApplicantInfo)

        this.fetchIdentification()
    }

    saveUserInput = (id, value) => {
        var dataToSave = {}
        var remoteSave = false
        var { citizenOrResident, countryOfCitizenship } = this.props.onboardingStore.personalInfo
        const foreignUser = (countryOfCitizenship != 'US') && (citizenOrResident == false)
        switch (id) {
            case "ssn":
                if (!foreignUser) {
                    value = value.replace(/[^\d]/g, '')
                } else {
                    value = value.replace(/[^0-9A-Z]+/gi, '')
                }
                dataToSave = { [id]: value }
                break
            case "idNumber":
                var identification = this.props.onboardingStore.personalInfo.identification || {}
                identification[id] = value
                dataToSave = { identification: identification }
                break
            case "expDate":
                var identification = this.props.onboardingStore.personalInfo.identification || {}
                identification[id] = value
                dataToSave = { identification: identification }
                break
            default:
                dataToSave = { [id]: value }
                break
        }
        this.props.dispatch(addDataToStore(PERSONAL_INFO, dataToSave))
    }

    handleOnChange = (event) => {
        const target = event.target
        this.saveUserInput(target.id || target.name, target.value)
    }

    setErrorField = (id, msg) => this.setState({ errorField: id, errorMessage: msg })

    fetchIdentification = () => {
        const { identificationId } = this.props.onboardingStore.personalInfo
        //console.log("/identifications/getActiveDocument resp", identificationId)
        if (!identificationId) return
        var body = {
            "id": identificationId
        }

        apiPOSTReq(`${environment.uamBaseUrl}/identifications/getActiveDocument`, {}, body, (err, resp) => {
            this.setState({ loading: false })
            try {
                const data = resp || {}
                console.log("/identifications/getActiveDocument resp", resp)
                if (data.result) {
                    var identification = data.identification
                    this.setState({
                        identification: identification
                    })
                    this.props.dispatch(addDataToStore(PERSONAL_INFO, { identification: identification }))
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp)
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again" })
            }
        })
    }

    validateFlow = () => {
        const { onboardingStore, flowView } = this.props
        var { citizenOrResident, countryOfCitizenship, identification, firstName, lastName, dob, address, phone, ssn } = onboardingStore.personalInfo
        const { idNumber, expDate } = identification || {}
        const foreignUser = (countryOfCitizenship != 'US') && (citizenOrResident == false)

        if (typeof (citizenOrResident) !== 'boolean') {
            this.setErrorField("citizenOrResident", "Select an option")
            return false;
        }
        if (!countryOfCitizenship) {
            this.setErrorField("countryOfCitizenship", "Select your country of citizenship")
            return false;
        }
        if (!firstName) {
            this.setErrorField("firstName", "Enter your first name")
            return false;
        }
        if (!lastName) {
            this.setErrorField("lastName", "Enter your last name")
            return false;
        }
        if (foreignUser && (!ssn || (ssn.length < 9) || (ssn.length > 20))) {
            this.setErrorField("ssn", "Enter a valid Tax ID between 9 and 20 characters")
            return false;
        } else if (!foreignUser && (!ssn || ((ssn || "").replace(/[^\d]/g, '').length !== 9))) {
            this.setErrorField("ssn", "Enter your SSN")
            return false;
        }
        if (!dob) {
            this.setErrorField("dob", "Select a date")
            return false;
        }
        if (!address || !address.line1 || address.line1.includes("undefined") || !address.city || (!address.countrySubDivisionCode && !address.country) || !address.postalCode) {
            this.setErrorField("address", "Please enter a valid address")
            return false;
        }
        // Save to the DB
        console.log("Saving personalInfo", { personalInfo: onboardingStore.personalInfo })
        this.props.dispatch(addDataToStore(REMOTE_SAVE_DATA, flowView.FlowStep))
        // save any updated ID information
        this.setErrorField({ "": "" });
        return true;
    }
    validateExpiryDate = () => {
        const { onboardingStore } = this.props
        var { identification } = onboardingStore.personalInfo
        const { expDate } = identification || {}

        if (!expDate || !moment(expDate).isAfter(moment())) {
            this.setErrorField("expDate", "Their ID may have expired or been incorrectly filled. Update this field or reupload a valid document.")
            return false;
        } else {
            this.setErrorField({ "": "" });
        }
    }
    loadNext = () => {
        var isValid = this.validateFlow();
        if (isValid) {
            this.setState({showSuccessBanner: true})
            setTimeout(() => {
                this.setState({showSuccessBanner: false})

                this.props.loadNext();
            }, 1000)
        }
    }

    disabledDOBDate = (current) => {
        // Can not select days after today and today and weekends
        return current > moment().subtract('18', "years")
    }

    handleDateChange = (id, dateStr) => {
        if (dateStr) {
            if (id == "dob") {
                this.saveUserInput(id, moment.utc(dateStr).startOf('day').toDate());
            } else {
                this.saveUserInput(id, moment.utc(dateStr).startOf('day').format("YYYY-MM-DD"))
            }
        } else {
            this.saveUserInput(id, null);
        }
        this.validateExpiryDate();
    }

    getLocation = (location, formattedAddress) => {
        let addrObj = getAddressObj(location)
        addrObj.city = addrObj.city ? addrObj.city.substring(0, 18) : ''
        console.log("getLocation", location, addrObj, formattedAddress)
        this.saveUserInput("address", addrObj)
    }

    render() {
        var { flowView, currentViewIndex, loadPrevView, flowSteps, onboardingStore, aionStore, theme, reviewView } = this.props
        var { errorField, errorMessage, showVouched, isWeb, isMobile, showSuccessBanner } = this.state
        var { citizenOrResident, countryOfCitizenship, identification, firstName, lastName, dob, address, phone, ssn } = onboardingStore.personalInfo
        const { idNumber, type, expDate, issuingStateOrProvince, issuingCountryCode } = identification || {}

        const { business } = aionStore;
        var { businessProfile } = business;
        var { primaryApplicantInfo } = businessProfile || {};
        primaryApplicantInfo = primaryApplicantInfo || {}

        flowSteps = flowSteps || []
        flowView = flowView || {}
        const foreignUser = (countryOfCitizenship != 'US') && (citizenOrResident == false)
        // console.log("identification", identification) 
        const countryItems = []
        Object.values(countryCodeMapUSFirst).forEach(item => {
            countryItems.push(
                <Option key={item.code} value={item.code}>
                    {item.name}
                </Option>
            )
        })
        var idvKey = flowView.FlowStep || random(500)
        if (this.state.reloadIDV) idvKey = idvKey + random(500)

        var childViews = (
            <FlexColumn between fullHeight style={{ marginTop: "0px", height: '100%' }}>
                <FlexColumn left start fullWidth gap='24px'>
                    <Flex start gap="24px">
                        <LabeledInput
                            margin='0px'
                            label='Are you a resident of the U.S.?'
                            type='switch'
                            switchNames={['Yes', 'No']}
                            onChange={(value) => this.saveUserInput("citizenOrResident", (value == 'Yes'))}
                            value={(typeof (citizenOrResident) == 'boolean') ? (citizenOrResident ? 'Yes' : 'No') : null}
                            error={errorField == "citizenOrResident"}
                            errorMessage={errorMessage}
                            width="288px"
                        />

                        <LabeledInput
                            margin='0px'
                            labelcolor={this.props.theme.colors.secondary3}
                            label="Which country are you a citizen of?"
                            type="select"
                            key='Citizenship'
                            id="Citizenship"
                            placeholder="Select country of citizenship"
                            optionFilterProp="children"
                            onChange={value => { this.saveUserInput("countryOfCitizenship", value) }}
                            value={countryOfCitizenship}
                            error={errorField == "countryOfCitizenship"}
                            errorMessage={errorMessage}
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().startsWith(input.toLowerCase())
                            }
                            width="288px"
                        >
                            {countryItems}
                        </LabeledInput>
                    </Flex>
                    <Flex gap="24px">
                        <LabeledInput
                            margin='0px'
                            labelcolor={this.props.theme.colors.secondary3}
                            label="First Name"
                            key="firstName"
                            id="firstName"
                            onChange={this.handleOnChange}
                            value={firstName}
                            placeholder="Robert"
                            error={errorField == "firstName"}
                            errorMessage={errorMessage}
                            width="288px"
                        />
                        <LabeledInput
                            margin='0px'
                            labelcolor={this.props.theme.colors.secondary3}
                            label="Last Name"
                            key="lastName"
                            id="lastName"
                            onChange={this.handleOnChange}
                            value={lastName}
                            placeholder="Glass"
                            error={errorField == "lastName"}
                            errorMessage={errorMessage}
                            width="288px"
                        />
                    </Flex>
                    <Flex gap="24px">
                        <LabeledInput
                            margin='0px'
                            autoFocus
                            onKeyDown={this.handleOnKeyDown}
                            labelcolor={this.props.theme.colors.secondary3}
                            label={foreignUser ? `Personal Tax ID Number` : "Social Security Number"}
                            id="ssn"
                            type="mask"
                            placeholder="123-44-5550"
                            onChange={this.handleOnChange}
                            value={ssn && (foreignUser ? ssn : formatSSN(ssn))}
                            error={errorField == "ssn"}
                            errorMessage={errorMessage}
                            maxLength={foreignUser ? 36 : 11}
                            width="288px"
                        />
                        <LabeledInput
                            margin='0px'
                            labelcolor={this.props.theme.colors.secondary3}
                            label="Date of Birth"
                            type="date-picker"
                            defaultPickerValue={moment().subtract(18, "years")}
                            disabledDate={this.disabledDOBDate}
                            key={"DOB"}
                            id="dob"
                            onChange={(date, dateStr) => this.handleDateChange("dob", dateStr)}
                            format={dateFormatList}
                            value={dob && moment.utc(dob)}
                            placeholder="Select"
                            error={errorField == "dob"}
                            errorMessage={errorMessage}
                            style={{ height: "30px" }}
                            width="288px"
                        />
                    </Flex>
                    <LabeledInput
                        margin='0px'
                        labelcolor={this.props.theme.colors.secondary3}
                        label="Home Address"
                        type="location"
                        key="Address"
                        id="address"
                        getLocation={this.getLocation}
                        value={address}
                        placeholder="Enter address"
                        country
                        error={errorField == "address"}
                        errorMessage={errorMessage}
                        width="600px"
                    />
                </FlexColumn>
            </FlexColumn>
        )

        if (reviewView) {
            ssn = ssn || primaryApplicantInfo.ssn
            return (
                <Flex start fullWidth gap='24px' wrap>
                    <LabeledInput
                        label='Are you a resident of the U.S.?'
                        type='read-only'
                        value={(typeof (citizenOrResident) == 'boolean' || typeof (primaryApplicantInfo.citizenOrResident) == 'boolean') ? ((citizenOrResident || primaryApplicantInfo.citizenOrResident) ? 'Yes' : 'No') : null}
                        width='288px'
                    />
                    <LabeledInput
                        label="Which country are you a citizen of?"
                        type="read-only"
                        value={countryCodeToNameMap[countryOfCitizenship] || countryCodeToNameMap[primaryApplicantInfo.countryOfCitizenship]}
                        width='288px'
                    />
                    <LabeledInput
                        label="First Name"
                        type='read-only'
                        value={firstName || primaryApplicantInfo.firstName}
                        width='288px'
                    />
                    <LabeledInput
                        label="Last Name"
                        type='read-only'
                        value={lastName || primaryApplicantInfo.lastName}
                        width='288px'
                    />
                    <LabeledInput
                        type='read-only'
                        label="Home address"
                        value={addressObjectToStr(address || primaryApplicantInfo.address)}
                        width='288px'
                    />
                    <LabeledInput
                        type='read-only'
                        label="Date of Birth"
                        value={(dob || primaryApplicantInfo.dob) && moment.utc(dob || primaryApplicantInfo.dob).format('MMM DD, YYYY')}
                        width='288px'
                    />
                    <LabeledInput
                        type='read-only'
                        label={foreignUser ? `Personal Tax ID Number` : "Social Security Number"}
                        value={ssn && (foreignUser ? `XXXX${ssn.substring(ssn.length - 4, ssn.length)}` : `XXX-XX-${ssn.substring(ssn.length - 4, ssn.length)}`)}
                        width='288px'
                    />
                </Flex>
            )
        }
        return (
            <>
                <FlowViewComponent
                    flowView={flowView}
                    currentViewIndex={currentViewIndex}
                    loadPrev={loadPrevView}
                    childViews={childViews}
                    submitLoading={this.state.submitLoading}
                    width={'100%'}
                    back={false}
                    padding='0 64px'
                    {...this.props}
                    loadNext={this.loadNext}
                    showSuccessBanner={this.state.showSuccessBanner}
                
                />
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        onboardingStore: state.onboardingAppReducer,
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(CreditUserProfile))