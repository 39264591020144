import { cloneDeep } from 'lodash';
import { SIGNUP_SUCCESS, CONFIRM_PHONE, SIGNIN, RESET_MAIN_STATE, SAVE_DATA, TEMPORARY_FORM_DATA, UAM_SAVE_PERMISSIONS, WINDOW_CLOSE_TIMESTAMP, UPDATE_LOGO, UAM_SAVE_BUSINESS, UAM_SAVE_USERINFO, UPDATE_PROFILE_IMG, SOFT_RESET_MAIN_STATE, NOTIFICATIONS, ERROR_MODAL, UPDATE_PG_TITLE, CODAT_DATA } from '../Actions/actions'
import { camelCaseToPascalCase } from '../Utils/util';

const initialState = {
	FirstName: "",
	LastName: "",
	Phone: "",
	Username: "",
	UserConfirmed: false,
	UserSignedUp: false,
	BusinessUniqueKey: null,
	business: {},
	UAM: {},
	userInfo: {},
	manualReviewRequired: false,
	subscriptionPlanName: "launch",
	subscriptionBilling: "yearly",
	Pinned: true,
	displayError: false,
	errorDescription: null,
	errorTitle: null,
	sessionRefreshing: false,
	switchBizInProgress: false,
	pageTitleText: '',
	navProps: {}
}

function aionAppReducer(state, action) {
	if (typeof state === 'undefined') {
		return initialState
	}
	// console.log("Running reducer...", action);
	var newState = cloneDeep(state);
	switch (action.type) {
		case SIGNUP_SUCCESS:
			newState.Username = action.username;
			newState.Phone = action.phone;
			newState.FirstName = action.firstName;
			newState.LastName = action.lastName;
			newState.userSignedUp = true;
			return newState
		case CONFIRM_PHONE:
			newState.userConfirmed = true;
			return newState
		case SIGNIN:
			newState = Object.assign(newState, action.data);
			return newState
		case RESET_MAIN_STATE:
			newState = initialState;
			return newState
		case SOFT_RESET_MAIN_STATE:
			newState = Object.assign(newState, action.data);
			newState["activeUsers"] = [];
			newState["inactiveUsers"] = [];
			newState["hasIntegration"] = false;
			newState["CurrentSync"] = null;
			return newState
		case TEMPORARY_FORM_DATA:
			newState.TempFormData = action.data;
			return newState
		case SAVE_DATA:
			newState = Object.assign(newState, action.data ? action.data : {});
			return newState
		case UAM_SAVE_PERMISSIONS:
			newState = Object.assign(newState, action.data ? action.data : {});
			newState.sessionRefreshing = false
			return newState
		case WINDOW_CLOSE_TIMESTAMP:
			newState = Object.assign(newState, action.data ? action.data : {});
			return newState
		case UPDATE_LOGO:
			var { Profile, business } = newState;
			var { BusinessInfo } = Profile || {};
			BusinessInfo.LogoUrl = action.data;
			((business.businessProfile || {}).businessInfo || {}).logoUrl = action.data // new structure
			return newState;
		case UPDATE_PROFILE_IMG:
			var { userInfo } = newState;
			userInfo.profileImage = action.data;
			return newState;
		case UAM_SAVE_USERINFO:
			var uInfo = action.data ? action.data : {};
			newState = Object.assign(newState, uInfo);
			newState.BusinessUniqueKey = uInfo.currentBusiness;
			// console.log("aionAppReducer UAM_SAVE_USERINFO", newState);
			return newState;
		case UAM_SAVE_BUSINESS:
			var business = action.data.business || {};
			// console.log("aionAppReducer UAM_SAVE_BUSINESS", newState);
			var profile = {}
			var { businessProfile } = business || {}
			businessProfile = businessProfile || {}
			profile = camelCaseToPascalCase(cloneDeep(businessProfile))
			var businessInfo = businessProfile.businessInfo || {}
			newState = Object.assign(newState, { business: business, businessName: businessInfo.name, Profile: profile });
			return newState
		case NOTIFICATIONS:
			newState = Object.assign(newState, action.data ? action.data : {});
			return newState
		case ERROR_MODAL:
			var aData = action.data ? action.data : {};
			console.log("ERROR_MODAL current state", newState, aData.sessionRefreshing);
			newState = Object.assign(newState, aData);
			if(aData.sessionRefreshing) {
				newState.UAM = {}; // Reset
			}
			return newState
		case UPDATE_PG_TITLE:
			newState = Object.assign(newState, action.data ? action.data : {});
			return newState
		case CODAT_DATA:
			newState = Object.assign(newState, action.data ? action.data : {});
			return newState
		default:
			return state
	}
}

export default aionAppReducer