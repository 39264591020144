import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'
import { Document, Page, pdfjs } from 'react-pdf'
import moment, { isMoment } from 'moment'
import {
    message, Select, Divider, Switch, Space, Tabs, Modal, Drawer, Skeleton, Badge
} from 'antd'
import { PlusOutlined, DownloadOutlined, SaveOutlined, EyeOutlined } from '@ant-design/icons'
import { saveAs } from 'file-saver'

// Components
import { Button, TextButton } from '../../../Reusable/Button'
import { Text, Tag } from '../../../Reusable/Text'
import { Image } from '../../../Reusable/Image'
import { Flex, FlexColumn, LineItemsHeader, LineItemRow, CardContainer } from '../../../Reusable/Container'
import { LabeledInput, GradientDivider } from '../../../Reusable/Input'
import { ErrorAlert } from '../../../Reusable/Alert'
import UploadFiles from '../../../Reusable/UploadFiles'

import environment from '../../../../environment'
import { apiPOSTReq, getPreSignedS3Url } from '../../../../Utils/api'
import { defaultPaymentTerms, toNumber, toCurrency, getUserApps, dateFormatList, getFilenameFromUrl } from '../../../../Utils/util'
import NewProduct from '../../Products/NewProduct'
import NewCustomer from '../../Customers/NewCustomer'
import AddModalWO from '../../Customers/AddModalWO'
import { Editor, EditorState } from 'draft-js'
import 'draft-js/dist/Draft.css'
import { stateToHTML } from 'draft-js-export-html'
import { stateFromHTML } from 'draft-js-import-html'
import renderHTML from 'react-render-html'
import DeleteIcon from '../../../../Images/delete-icon.png'
import ModalClose from '../../../../Images/modal-close.png'
import { template } from 'underscore'
import Sparkle from '../../../../Images/aisparkle.png'

const { Option } = Select
const { TabPane } = Tabs

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`


class Index extends Component {

    constructor(props) {
        super(props)

        var fileList = []

        if (this.props.invoice.attachments) {
            fileList = this.props.invoice.attachments
            fileList.forEach((item, index) => {
                item.uid = index
                item.name = item.fileName
                item.url = item.uri
            })
        }

        let invoice = props.receivablesStore.editedInvoice || {}
        this.state = {
            loading: false,
            invoice: this.props.invoice || {},
            paymentTermsItems: defaultPaymentTerms,
            scheduleTypeItems: ["Once", "Recurring"],
            schedulePeriodTypeItems: ["Weekly", "BiWeekly", "Monthly", "Quarterly"],
            showScheduleInfo: (this.props.invoice || {}).scheduleId,
            editorState: EditorState.createEmpty(),
            selectedTab: "email",
            showShippingInfo: (this.props.invoice.shipDate || this.props.invoice.shipTrackingNumber || this.props.invoice.shipVia),
            customerTemplate: false,
            toEmails: [],
            ccEmails: [],
            fileList: fileList
        }
    }

    componentDidMount() {
        const businessInfo = this.props.aionStore?.business?.businessProfile?.businessInfo;
        this.setState({ fromName: businessInfo.name })
        this.fetchTemplates()
        this.fetchTerms()
        if (this.props.invoice && this.props.invoice.customerName) {
            apiPOSTReq(`${environment.iveBaseUrl}/ive/templates/getActiveTemplatesForCustomer`, this.iveHeaders, { customerName: this.props.invoice.customerName }, (err, resp) => {
                try {
                    const data = resp
                    console.log("/ive/templates/getActiveTemplatesForCustomer", JSON.stringify(data))
                    if (data.result) {
                        if (data.templates.length > 0) {
                            console.log("/ive/templates/getActiveTemplatesForCustomer template", data.templates[0].templateName)
                            this.setState({ customerTemplate: true, customerTemplates: data.templates, docTemplateName: data.templates[0].templateName })
                            if (this.state.invoice.templateName) {
                                this.setState({ docTemplateName: this.state.invoice.templateName })
                            }
                        } else {
                            this.setState({ customerTemplate: false })
                            this.fetchTemplates()
                        }
                    } else {
                        throw Error("Could not fetch purchase orders.")
                    }
                } catch (error) {
                    console.log("ERRR", error.stack)
                }
            })
        }

        this.fetchCustomers({
            pagination: {
                current: 1,
                pageSize: 2000
            }
        })
        this.fetchProducts({
            pagination: {
                current: 1,
                pageSize: 500
            }
        })
        if ((this.state.invoice.lineItems || []).length === 0) this.addLineItemRow()

        if (this.state.invoice.generatedDocNumber) {
            this.state.invoice.docNumber = this.state.invoice.generatedDocNumber

            this.setState({ invoice: this.state.invoice })
        }

        this.fetchBusinessData()
    }

    fetchTerms() {
        apiPOSTReq(`${environment.iveBaseUrl}/ive/term/getTerms`, {}, {}, (err, resp) => {
            try {
                const data = resp || {}
                console.log("/ive/term/getTerms", data)
                if (data.result) {
                    const terms = data.termList && data.termList.length > 0 ? data.termList : defaultPaymentTerms
                    this.setState({ paymentTermsItems: terms })
                } else {
                    this.setState({ paymentTermsItems: defaultPaymentTerms })
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                this.setState({ paymentTermsItems: defaultPaymentTerms })
                console.log("ERRR getTerms", error, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    fetchTemplates() {
        apiPOSTReq(`${environment.iveBaseUrl}/ive/templates/getActiveTemplates`, {}, {}, (err, resp) => {
            try {
                const data = resp || {}
                console.log("/ive/templates/getActiveTemplates", data)
                if (data.result) {
                    var { templates } = data
                    this.setState({ activeTemplates: templates })
                    for (let i = 0; i < templates.length; i++) {
                        if (templates[i].default == true && this.state.customerTemplate != true) {
                            this.setState({ docTemplateName: templates[i].templateName })
                            break;
                        }
                    }
                    if (this.state.invoice.templateName) {
                        this.setState({ docTemplateName: this.state.invoice.templateName })
                    }
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getActiveTemplates", error, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    fetchCustomers(options) {
        const { pagination } = options
        // Fetch customer list
        const body = {
            "size": pagination.pageSize,
            "page": pagination.current - 1
        }
        this.setState({ loadingCustomers: true })
        apiPOSTReq(`${environment.iveBaseUrl}/ive/bc/getActiveCustomers`, {}, body, (err, resp) => {
            try {
                this.setState({ loadingCustomers: false })
                const data = resp
                console.log("/getActiveCustomers", data)
                if (data.result) {
                    var selectedCustomer
                    if (this.state.invoice) {
                        selectedCustomer = (data.customers || []).find(customer => (customer.customerId == this.state.invoice.customerId))
                    }

                    this.setState({ activeCustomers: data.customers, selectedCustomer })
                    if (selectedCustomer) {
                        this.setState({ toEmails: selectedCustomer.invoiceRecipientEmail || [] })
                        this.fetchCustomerPO(selectedCustomer);
                    }
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getActiveCustomers", error.stack, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    fetchProducts(options) {
        const { pagination } = options
        // Fetch product list
        const body = {
            "size": pagination.pageSize,
            "page": pagination.current - 1
        }
        this.setState({ loadingProducts: true })
        apiPOSTReq(`${environment.iveBaseUrl}/ive/product/getActiveProducts`, {}, body, (err, resp) => {
            try {
                this.setState({ loadingProducts: false })
                const data = resp
                console.log("/getActiveProducts", data)
                if (data.result) {
                    this.setState({
                        activeProducts: data.products
                    })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR activeProducts", error, err, resp)
            }
        })
    }

    saveUserInput = (id, dataToSave) => {
        console.log("/ive/invoice/save updateTotals saveUserInput", id)
        var { invoice } = this.state
        invoice[id] = dataToSave
        this.setState({ invoice }, () => {
            if (id === "discount" || id === "taxes") this.updateTotals()
        })
    }

    handleTextChange = (event) => {
        var val
        switch (event.target.id) {
            case "paymentTermsCustom":
                val = `Net ${event.target.value}`
                break
            default:
                val = event.target.value
                break
        }
        this.saveUserInput(event.target.id, val)
    }

    fetchCustomerPO = (selectedCustomer) => {
        const body = {
            "BusinessId": this.props.aionStore.BusinessUniqueKey,
            "CustomerId": selectedCustomer.customerId,
        }

        apiPOSTReq(`${environment.iveBaseUrl}/ive/po/getPOsForCustomer`, this.iveHeaders, body, (err, resp) => {
            try {
                const data = resp
                console.log("/ive/po/getApprovedPOsForCustomer", (data))
                if (data.result) {
                    // this.props.dispatch(addDataToStore(CREDIT_SAVE_DATA, data))
                    this.setState({ loadingPurchaseOrders: false, purchaseOrders: data.pos })
                } else {
                    throw Error("Could not fetch purchase orders.")
                }
            } catch (error) {
                console.log("ERRR", error.stack)
            }
        })
    }

    handleCustomerSelect = (value, option) => {
        this.setState({ errorField: '', errorMessage: "" });
        this.setState({ billingAddressLoading: true, shippingAddressLoading: true })
        var { invoice, activeCustomers } = this.state
        const selectedCustomer = (activeCustomers || []).find(customer => (customer.customerId == value))
        console.log("selectedCustomer", selectedCustomer)
        invoice.customerId = value
        invoice.customerName = option.name
        invoice.billingAddress = selectedCustomer.billingAddress
        invoice.shippingAddress = selectedCustomer.shippingAddress
        invoice.ponumber = null;
        this.setState({
            invoice, selectedCustomer, loadingPurchaseOrders: true, customerTemplate: false,
            toEmails: selectedCustomer.invoiceRecipientEmail || [],
            // billingAddress: selectedCustomer.billingAddress, shippingAddress: selectedCustomer.shippingAddress
        },
            () => this.setState({ billingAddressLoading: false, shippingAddressLoading: false }))
        this.handlePaymentTermsSelect(selectedCustomer.termId)

        this.fetchCustomerPO(selectedCustomer)

        apiPOSTReq(`${environment.iveBaseUrl}/ive/templates/getActiveTemplatesForCustomer`, this.iveHeaders, { customerName: option.name }, (err, resp) => {
            try {
                const data = resp
                console.log("/ive/templates/getActiveTemplatesForCustomer", JSON.stringify(data))
                if (data.result) {
                    if (data.templates.length > 0) {
                        console.log("/ive/templates/getActiveTemplatesForCustomer template", data.templates[0].templateName)
                        this.setState({ customerTemplate: true, customerTemplates: data.templates, docTemplateName: data.templates[0].templateName })
                    } else {
                        this.setState({ customerTemplate: false })
                        this.fetchTemplates()
                    }
                } else {
                    throw Error("Could not fetch purchase orders.")
                }
            } catch (error) {
                console.log("ERRR", error.stack)
            }
        })
    }

    handleSelectPurchaseOrder = (value, option) => {
        var { invoice, purchaseOrders, fileList } = this.state
        invoice.ponumber = value
        this.setState({ invoice })

        const purchaseOrder = purchaseOrders.find(po => po.ponumber === value)

        if (purchaseOrder) {
            var { documentUrl } = purchaseOrder

            invoice.attachments = (invoice.attachments || []).filter(attachment => attachment.type !== 'PO');
            fileList = (fileList || []).filter(file => file.type !== 'PO');

            documentUrl.name = getFilenameFromUrl(documentUrl.uri)
            documentUrl.fileName = documentUrl.name
            documentUrl.uid = invoice.attachments.length
            documentUrl.type = "PO"
            documentUrl.url = documentUrl.uri

            invoice.attachments.push(documentUrl)
            fileList.push(documentUrl)

            this.setState({ invoice, fileList })

        }
    }

    handlePaymentTermsSelect = (value, option) => {
        var { invoice } = this.state

        const term = this.state.paymentTermsItems.find(x => x.termId === value)
        if (term) {
            invoice.terms = term.name
            invoice.termsId = term.termId
            value = term.dueDays
        } else {
            invoice.terms = value
            invoice.termsId = value
        }

        this.setState({ errorField: '', errorMessage: "" });

        var netDays = toNumber(value)
        if (!invoice.txnDate) invoice.txnDate = moment.utc(moment().format('MM/DD/YYYY')).utcOffset(0)
        invoice.dueDate = moment.utc(invoice.txnDate).add(netDays, 'days')
        this.setState({ invoice })
    }

    handleCustomPaymentTerm = () => {
        var { paymentTermsItems, invoice } = this.state
        if (invoice.paymentTermsCustom) this.setState({ paymentTermsItems: [invoice.paymentTermsCustom, ...paymentTermsItems] })
    }

    handleLineItemProductSelection = (value, key) => {
        console.log("handleLineItemProductSelection", value, key)
        var { invoice, activeProducts } = this.state
        var lineItems = invoice.lineItems || []
        const selectedProduct = activeProducts.find(item => (item.id === value))
        var selectedLineItem = lineItems.find((item, index) => (index === key))
        if (selectedLineItem) {
            selectedLineItem.productId = selectedProduct.id
            selectedLineItem.productName = selectedProduct.name
            selectedLineItem.description = selectedProduct.description
            selectedLineItem.unitPrice = selectedProduct.unitPrice
            selectedLineItem.qty = selectedLineItem.qty || 1
            selectedLineItem.amount = selectedLineItem.qty * selectedLineItem.unitPrice
            selectedLineItem.discount = 0
        } else {
            lineItems.push({
                "itemId": key,
                "productId": selectedProduct.id,
                "productName": selectedProduct.name,
                "description": selectedProduct.description,
                "unitPrice": selectedProduct.unitPrice,
                "qty": 1,
                "amount": selectedProduct.unitPrice,
                "discount": 0,
            })
        }
        invoice.lineItems = lineItems
        this.setState({ invoice })
        this.updateTotals()
    }

    handleLineItemTextChange = (event, key) => {
        var { invoice } = this.state
        var lineItems = invoice.lineItems || []
        var selectedItem = lineItems.find((item, index) => (index === key))
        if (selectedItem) {
            selectedItem[event.target.id] = event.target.value
            if (event.target.id === "qty" || event.target.id === "unitPrice" || event.target.id === "discount") {
                selectedItem.amount = (selectedItem.qty * selectedItem.unitPrice) - (selectedItem.discount || 0)
            }
            if (event.target.id === "amount") {
                selectedItem.unitPrice = selectedItem.amount / selectedItem.qty
                selectedItem.discount = 0
            }
        } else {
            lineItems.push({
                "itemId": key,
                [event.target.id]: event.target.value
            })
        }
        this.setState({ invoice })
        this.updateTotals()
    }

    handleScheduleTypeSelect = (value) => {
        var { invoice } = this.state
        invoice.scheduleType = value.toLowerCase()
        this.setState({ invoice })
    }

    handleSchedulePeriodTypeSelect = (value) => {
        var { invoice } = this.state
        invoice.schedulePeriodType = value
        this.setState({ invoice })
    }

    handleTemplateSelect = (value) => {
        this.setState({ docTemplateName: value })
    }

    addLineItemRow = () => {
        var { invoice } = this.state
        var lineItems = invoice.lineItems || []
        invoice.lineItems = lineItems.concat([{
            itemId: lineItems.length,
            lineNum: lineItems.length,
            unsavedItem: true
        }])
        this.setState({ invoice })
    }

    removeLineItemRow = (id) => {
        var { invoice, fileList } = this.state
        invoice.lineItems = (invoice.lineItems || []).filter((item, index) => {
            if (index !== id) return item
        })
        this.setState({ invoice })
    }

    handleFileUpload = (attachments) => {
        console.log('NewInvoice fileList attachments', attachments)
        var { invoice } = this.state
        attachments.forEach((file, index) => {
            // file.fileName = file.fileName ? file.fileName : "Purchase Order"
            file.mimeType = "OTHER"
            file.type = file.type ? file.type : "OTHER"
            file.objectType = "OTHER"
            file.uid = file.uid ? file.uid : index
        }) //Add a file type
        invoice.attachments = attachments
        this.setState({ invoice })
    }

    getBillingLocation = (address, formattedAddress) => {
        var { invoice } = this.state
        invoice.billingAddress = address || {}
        this.setState({ invoice })
    }

    getShippingLocation = (address, formattedAddress) => {
        var { invoice } = this.state
        invoice.shippingAddress = address || {}
        this.setState({ invoice })
    }

    disabledDate = (current) => {
        // Can not select days before today and today and weekends
        return current < moment().startOf('day')
    }

    getLineItemTotals = () => {
        var { invoice } = this.state
        const reducer = (accumulator, item) => accumulator + parseFloat(item.amount || 0)
        // const discountReducer = (accumulator, item) => accumulator + parseFloat(item.discount || 0)
        var total = (invoice.lineItems || []).reduce(reducer, 0)
        // var discount = (invoice.lineItems || []).reduce(discountReducer, 0)
        return {
            subTotal: total,
            totalAmt: total - (invoice.discount ? parseFloat(invoice.discount) : 0) + (invoice.taxes ? parseFloat(invoice.taxes) : 0),
            discount: (invoice.discount ? parseFloat(invoice.discount) : 0),
        }
    }

    updateTotals = () => {
        var { invoice } = this.state
        var totals = this.getLineItemTotals()
        invoice.totalAmt = totals.totalAmt || 0
        invoice.balance = invoice.totalAmt
        invoice.subTotal = totals.subTotal || 0
        invoice.taxes = invoice.taxes || 0

        console.log("/ive/invoice/save updateTotals", invoice)

        this.setState({ invoice })
    }

    downloadAttachment = (url, name) => {
        getPreSignedS3Url({ url: url }, (err, preSignedUrl) => {
            if (!err) {
                saveAs(preSignedUrl, name)
            } else {
                ErrorAlert({ description: JSON.stringify(err) })
            }
        })
    }

    handleSave = (options) => {
        const { aionStore } = this.props
        const { saveAndPreview } = options || {}
        var { invoice, selectedCustomer, docTemplateName } = this.state

        invoice.lineItems = invoice.lineItems.filter(x => x.productId)

        const userApps = getUserApps(aionStore)

        this.setState({ errorField: '', errorMessage: "" });

        if (!selectedCustomer) {
            // ErrorAlert({ description: "Please select a customer." })
            this.setState({ errorField: 'customerId', errorMessage: "Please select a customer." });
            return
        }
        // else {
        //     if (selectedCustomer &&
        //         ((userApps.includes("ARCredit") || userApps.includes("ABLCredit")) && !invoice.ponumber) ||
        //         (selectedCustomer.areligible === true && selectedCustomer.paymentConfirmation === true && !invoice.ponumber)) {
        //         ErrorAlert({ description: "Purchase order is a mandatory field." })
        //         return
        //     }
        // }

        invoice = Object.assign({}, invoice) // clone

        // if (!invoice.termsId) {
        //     this.setState({ errorField: 'terms', errorMessage: 'Please select a payment term' });
        //     return
        // }

        this.setState({ loadingSave: true && !saveAndPreview, loadingPreview: saveAndPreview })
        // Convert dates from moment to Date
        if (invoice.txnDate) invoice.txnDate = moment(invoice.txnDate).toDate()
        if (invoice.dueDate) invoice.dueDate = moment(invoice.dueDate).toDate()
        if (invoice.shipDate) invoice.shipDate = moment(invoice.shipDate).toDate()

        const body = {
            Invoice: invoice,
        }

        if (docTemplateName != "Default Template") {
            body.docTemplateName = docTemplateName
        }

        console.log("/ive/invoice/save", body)
        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/save`, {}, body, (err, resp) => {
            this.setState({ loadingSave: false, loadingPreview: false })
            try {
                const data = resp
                if (data.result) {
                    message.success(`Invoice saved`)
                    if (data.responseMessage != null && data.responseMessage != "Success") {
                        ErrorAlert({ description: data.responseMessage })
                    }
                    this.props.refreshInvoices()
                    this.setState({ invoice: data.invoice })
                    if (saveAndPreview) this.handleFilePreview()
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("ive/invoice/save", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    handleSend = () => {
        var { invoice, editorState, fromName, toEmails, ccEmails } = this.state
        this.setState({ loadingSend: true })
        const body = {
            InvoiceId: invoice.invoiceId,
            InvoiceEmailCustomBody: stateToHTML(editorState.getCurrentContent()),
            FromName: fromName,
            docTemplateName: this.state.docTemplateName,
            toAddr: toEmails.join(','),
            ccAddr: ccEmails.join(','),
        }

        console.log("ive/invoice/send body", JSON.stringify(body))

        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/send`, {}, body, (err, resp) => {
            this.setState({ loadingSend: false })
            try {
                const data = resp
                console.log("ive/invoice/send", JSON.stringify(data))
                if (data.result) {
                    message.success(`Invoice sent`)
                    this.props.refreshInvoices()
                    this.setState({ showPreviewModal: false })
                    this.props.close()
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("ive/invoice/send", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    handleSchedule = () => {
        var { invoice } = this.state
        const { scheduleType, schedulePeriodType, scheduleDate, scheduleEndDate } = invoice || {}
        invoice = Object.assign({}, invoice) // clone
        this.setState({ loadingSchedule: true })
        // Convert dates from moment to Date
        if (invoice.txnDate) invoice.txnDate = moment(invoice.txnDate).toDate()
        if (invoice.dueDate) invoice.dueDate = moment(invoice.dueDate).toDate()
        if (invoice.shipDate) invoice.shipDate = moment(invoice.shipDate).toDate()
        const body = {
            "scheduleDate": moment(scheduleDate).set({ hour: 9, minute: 0 }).format("YYYY-MM-DDTHH:mm"),
            "scheduleEndDate": moment(scheduleEndDate).set({ hour: 9, minute: 0 }).format("YYYY-MM-DDTHH:mm"),
            "scheduleType": scheduleType,
            "schedulePeriodType": schedulePeriodType,
            Invoice: invoice
        }
        console.log("/ive/invoice/scheduleInvoice", body)
        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/scheduleInvoice`, {}, body, (err, resp) => {
            this.setState({ loadingSchedule: false })
            try {
                const data = resp
                console.log("POST success /ive/invoice/scheduleInvoice", data)
                if (data.result) {
                    message.success(`Invoice scheduled`)
                    this.props.refreshInvoices()
                    // this.setState({ invoice: data.invoice })
                    // this.handleFilePreview()
                    this.props.close()
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("ive/invoice/save", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    handleFilePreview = () => {
        var { invoice } = this.state

        const body = {
            InvoiceId: invoice.invoiceId,
            docTemplateName: this.state.docTemplateName,
        }

        console.log("/ive/invoice/getInvoiceEmailBodyText", JSON.stringify(body))

        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/getInvoiceEmailBodyText`, {}, body, (err, resp) => {
            try {
                const data = resp
                console.log("POST success /ive/invoice/getInvoiceEmailBodyText", JSON.stringify(data))
                if (data.result) {
                    var invoiceDoc = (invoice.attachments || []).find(item => item.type == "InvoiceDoc") || {}
                    getPreSignedS3Url({ url: invoiceDoc.uri }, (err, preSignedUrl) => {
                        invoiceDoc.preSignedUrl = preSignedUrl || ""
                        this.setState({ invoice: invoice, showPreviewModal: true, htmlMailtext: data.htmlMailtext, invoiceEmailCustomBody: data.invoiceEmailCustomBody, editorState: EditorState.createWithContent(stateFromHTML(data.invoiceEmailCustomBody)) })
                    })
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("ive/invoice/getInvoiceEmailBodyText", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    handleWOAdded = () => {
        var { selectedCustomer } = this.state
        this.setState({ loadingPurchaseOrders: true })

        const body = {
            "BusinessId": this.props.aionStore.BusinessUniqueKey,
            "CustomerId": selectedCustomer.customerId,
        }
        apiPOSTReq(`${environment.iveBaseUrl}/ive/po/getPOsForCustomer`, this.iveHeaders, body, (err, resp) => {
            try {
                const data = resp
                console.log("/ive/po/getPOsForCustomer", (data))
                if (data.result) {
                    // this.props.dispatch(addDataToStore(CREDIT_SAVE_DATA, data))
                    this.setState({ loadingPurchaseOrders: false, purchaseOrders: data.pos, showAddModalWO: false })
                } else {
                    this.setState({ showAddModalWO: false })
                    throw Error("Could not fetch purchase orders.")
                }
            } catch (error) {
                this.setState({ showAddModalWO: false })
                console.log("ERRR", error.stack)
            }
        })
    }

    onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
        this.setState({ numPages: nextNumPages })
    }

    isPurchaseOrderMandatory = () => {
        const { aionStore } = this.props
        const userApps = getUserApps(aionStore)
        var { selectedCustomer } = this.state

        if (userApps.includes("ARCredit") || userApps.includes("ABLCredit")) return true

        if (!selectedCustomer) {
            return false
        } else {
            return selectedCustomer.areligible === true && selectedCustomer.paymentConfirmation === true
        }
    }

    handleTabChange = (key) => {
        this.setState({ selectedTab: key })
    }

    fetchBusinessData = (options) => {
        const { aionStore } = this.props;

        var body = {
            "clientBusinessId": aionStore.BusinessUniqueKey
        };
        console.log("/ive/invoice/getBusinessData body", body);
        this.setState({ loading: true });
        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/getBusinessData`, null, body, (err, resp) => {
            this.setState({ loading: false });
            try {
                const data = resp || {};
                console.log("/business/admin/getBusinessData data", data);
                if (data.result) {
                    const arcommunicationInfo = ((data.business || {}).businessProfile || {}).arcommunicationInfo
                    if (arcommunicationInfo && arcommunicationInfo.cc) {
                        var ccEmails = [arcommunicationInfo.cc]
                        this.setState({ ccEmails })
                    }
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    generateAIEmail = () => {
        const userInfo = this.props.aionStore?.userInfo;
        var { invoice } = this.state

        if (invoice.txnDate) invoice.txnDate = moment(invoice.txnDate).toDate()
        if (invoice.dueDate) invoice.dueDate = moment(invoice.dueDate).toDate()

        const body = {
            Invoice: invoice,
            "emailTone": this.state.emailTone,
            "userName": userInfo.FirstName + " " + userInfo.LastName,
            "emailMessage": this.state.emailMessage
        }
        this.setState({ loadingEmail: true })
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/openai/generateEmail`, {}, body, (err, resp) => {
            try {
                this.setState({ loadingEmail: false })
                const data = resp
                if (data.result) {
                    this.setState({ emailMessage: data.emailMessage, aiGeneratedEmail: true })
                    this.setState({ editorState: EditorState.createWithContent(stateFromHTML(data.emailMessage)) })
                } else {
                    message.error("Sorry, we had trouble processing your request. Please try again.")
                }
            } catch (error) {
                console.log("integration/openai/generateEmail", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    handleEmailTone = (e) => {
        this.setState({ emailTone: e.target.value })
    }


    render() {
        const { theme, aionStore } = this.props
        var { loading, activeCustomers, loadingCustomers, loadingPurchaseOrders, invoice, paymentTermsItems, showShippingInfo, activeProducts, loadingProducts, loadingSave, loadingSend,
            loadingPreview, showPreviewModal, numPages, selectedCustomer, showScheduleInfo, schedulePeriodTypeItems, loadingSchedule, purchaseOrders, selectedTab, loadingSendEmail, ccEmails,
            htmlMailtext, invoiceEmailCustomBody, docTemplateName, customerTemplate, customerTemplates, showShippingInfo, billingAddressLoading, 
            shippingAddressLoading, toEmails, arcommunicationInfo, fileList } = this.state
        var { customerName, txnDate, dueDate, terms, shipVia, shipDate, shipTrackingNumber, lineItems, discount, taxes, attachments, privateNote, billingAddress, shippingAddress, scheduleType, schedulePeriodType, scheduleDate, scheduleEndDate, memo, docNumber, scheduleId, invoiceStatus, emailStatus, generatedDocNumber, ponumber } = invoice
        var { activeTemplates, errorMessage, errorField, loadingEmail, emailMessage } = this.state
        activeTemplates = activeTemplates || []

        if (txnDate && !isMoment(txnDate)) {
            txnDate = new Date(txnDate).toISOString()
            txnDate = txnDate.split("T")[0]
        }
        txnDate = txnDate && moment(txnDate)
        if (dueDate && !isMoment(dueDate)) {
            dueDate = new Date(dueDate).toISOString()
            dueDate = dueDate.split("T")[0]
        }
        dueDate = dueDate && moment(dueDate)

        if (shipDate) shipDate = moment(shipDate)

        var invoiceDoc = (invoice.attachments || []).find(item => item.type == "InvoiceDoc") || {}
        attachments = (attachments || []).filter(item => item.type != "InvoiceDoc") // Only show non-invoice doc attachments
        const lineItemTotals = this.getLineItemTotals()
        const dateFormat = 'MM/DD/YYYY'
        lineItems = lineItems || []
        // Do not show Schedule Info for already scheduled invoice
        showScheduleInfo = scheduleId ? false : showScheduleInfo

        var uploadProps = {
            beforeUpload: () => {
                this.setState({ uploading: true })
            },
        }

        console.log('selectedCustomer invoiceRecipientEmail', selectedCustomer && selectedCustomer.invoiceRecipientEmail)

        console.log('NewInvoice fileList', fileList)

        return (
            <>
                <FlexColumn start>
                    {/* <div style={{ width: "95%", marginBottom: "100px" }}> */}
                    {/* <PageHeader
                        noMargin
                        titleText={!generatedDocNumber ? <span>INVOICE <span style={{ color: theme.colors.secondary3 }}>{docNumber}</span></span> : "New Invoice"}
                        subtitle={
                            docNumber &&
                            <Space>
                                <Tooltip title="Invoice status"><Tag tagpreset={theme.colors.secondary7}>{capitalize(invoiceStatus)}</Tag></Tooltip>
                                {emailStatus && <Tooltip title="Email status"><Tag tagpreset={theme.colors.secondary4}>{emailStatus}</Tag></Tooltip>}
                            </Space>
                        }
                        ctaContent={<Flex>
                            {
                                !showScheduleInfo ?
                                    !scheduleId && // Do not show preview for scheduled invoice
                                    <Button permtype="Override" solid loading={loadingPreview} onClick={() => this.handleSave({ saveAndPreview: true })} text="PREVIEW & SEND" icon={<EyeOutlined />} />
                                    :
                                    <Button permtype="Override" loading={loadingSchedule} onClick={() => this.handleSchedule()} text="SCHEDULE" />
                            }

                            {
                                !showScheduleInfo && <Button onClick={this.handleSave} loading={loadingSave} text="SAVE" icon={<SaveOutlined />} margin='0 0 0 24px' />
                            }
                        </Flex>}
                    /> */}

                    <Text margin='0 0 40px' size='24px' weight={500} color='#444444'>{!generatedDocNumber ? <span>INVOICE <span style={{ color: theme.colors.secondary3 }}>{docNumber}</span></span> : "New Invoice"}</Text>

                    <FlexColumn>
                        {
                            !docNumber &&
                            <Space>
                                <Tag tagpreset={theme.colors.primary}>PRO</Tag>
                                <span style={{ fontSize: "0.9rem", color: theme.colors.systemGray }}>Schedule this invoice?</span>
                                <Switch size="small" defaultChecked={showScheduleInfo} onChange={() => this.setState({ showScheduleInfo: !this.state.showScheduleInfo })} />
                            </Space>
                        }
                        {
                            showScheduleInfo &&
                            <Flex style={{ justifyContent: (scheduleType == "once") ? "flex-start" : "flex-start" }}>
                                <FlexColumn style={{ width: '25%', marginRight: 12 }} centerVertically>
                                    <LabeledInput
                                        label="Schedule Type"
                                        type='switch'
                                        switchNames={['Once', 'Recurring']}
                                        onChange={this.handleScheduleTypeSelect}
                                        value={scheduleType} />
                                </FlexColumn>
                                {(scheduleType !== "once") &&
                                    <div style={{ width: '25%', marginRight: 12, marginLeft: 12 }}>
                                        <LabeledInput
                                            label="Schedule Frequency"
                                            labelcolor={theme.colors.secondary3}
                                            id="schedulePeriodType"
                                            key="schedulePeriodType"
                                            type="select"
                                            className="no-left-padding"
                                            placeholder="Select"
                                            value={schedulePeriodType}
                                            onChange={this.handleSchedulePeriodTypeSelect}
                                        >
                                            {schedulePeriodTypeItems.map(item => <Option key={item} value={item}>{item}</Option>)}
                                        </LabeledInput>
                                    </div>
                                }
                                <div style={{ width: '25%', marginRight: 12, marginLeft: 12 }}>
                                    <LabeledInput
                                        label={`Schedule ${(scheduleType == "once") ? "On" : "Start"}`}
                                        labelcolor={theme.colors.secondary3}
                                        id="scheduleDate"
                                        key="scheduleDate"
                                        type="date-picker"
                                        disabledDate={this.disabledDate}
                                        placeholder={moment().format(dateFormat)}
                                        value={scheduleDate}
                                        format={dateFormatList}
                                        instruction={(scheduleType == "once") ? "Invoice will be sent at 9:00 am PST" : null}
                                        onChange={(date, dateStr) => this.saveUserInput("scheduleDate", moment.utc(dateStr).utcOffset(0))}
                                    />
                                </div>
                                {(scheduleType !== "once") &&
                                    <div style={{ width: '25%', marginLeft: 12 }}>
                                        <LabeledInput
                                            label="Schedule End"
                                            labelcolor={theme.colors.secondary3}
                                            id="scheduleEndDate"
                                            key="scheduleEndDate"
                                            type="date-picker"
                                            disabledDate={this.disabledDate}
                                            placeholder={moment().add(90, "days").format(dateFormat)}
                                            value={scheduleEndDate}
                                            format={dateFormatList}
                                            onChange={(date, dateStr) => this.saveUserInput("scheduleEndDate", moment.utc(dateStr).utcOffset(0))}
                                        />
                                    </div>
                                }
                            </Flex>
                        }
                        <Flex start>
                            <div style={{ width: '15%', marginRight: 12 }}>
                                <LabeledInput
                                    label="Invoice No."
                                    labelcolor={theme.colors.secondary3}
                                    id="docNumber"
                                    key="docNumber"
                                    className="no-left-padding"
                                    placeholder="Invoice No."
                                    value={docNumber}
                                    loading={loadingProducts}
                                    onChange={this.handleTextChange}
                                />
                            </div>
                            <div style={{ width: '35%', marginLeft: 12, marginRight: 12 }}>
                                <LabeledInput
                                    label="Customer"
                                    labelcolor={theme.colors.secondary3}
                                    id="customerId"
                                    key="customerId"
                                    type="select"
                                    className="no-left-padding"
                                    placeholder="Select Customer"
                                    value={customerName}
                                    loading={loadingCustomers}
                                    onChange={this.handleCustomerSelect}
                                    showSearch
                                    optionFilterProp="children"
                                    error={errorField == "customerId"}
                                    errorMessage={errorMessage}
                                    filterOption={(input, option) => {
                                        return option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }}
                                    dropdownRender={option => (
                                        <div>
                                            {option}
                                        </div>
                                    )}
                                >
                                    {(activeCustomers || []).map(item => <Select.Option key={item.customerId} id="customerName" name={item.displayName || item.companyName} value={item.customerId} style={{ backgroundColor: "transparent" }}>{item.displayName || item.companyName}</Select.Option>)}
                                </LabeledInput>
                            </div>
                            <div style={{ width: '35%', marginLeft: 12, marginRight: 12 }}>
                                <LabeledInput
                                    label="Purchase Order"
                                    labelcolor={theme.colors.secondary3}
                                    disabled={!purchaseOrders}
                                    id="ponumber"
                                    key="ponumber"
                                    type="select"
                                    className="no-left-padding"
                                    placeholder="Select Purchase Order"
                                    value={ponumber}
                                    loading={loadingPurchaseOrders}
                                    onChange={this.handleSelectPurchaseOrder}
                                    optional
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => {
                                        return option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }}
                                    dropdownRender={option => (
                                        <div>
                                            {option}
                                            {/* <Divider style={{ margin: '4px 0' }} />
                                            <div style={{ padding: '8px', cursor: 'pointer', fontSize: "1.0rem" }}>
                                                <a onClick={() => this.setState({ showAddModalWO: true })}>
                                                    <PlusOutlined />
                                                    <span> Add Purchase Order</span>
                                                </a>
                                            </div> */}
                                        </div>
                                    )}
                                >
                                    {(purchaseOrders || []).map(item =>
                                        <Select.Option key={item.ponumber} id="ponumber" name={item.ponumber} value={item.ponumber} style={{ backgroundColor: "transparent" }}>
                                            <Flex between fullWidth>
                                                #{item.ponumber} {item.verificationStatus == "VALIDATED" ? <div style={{ marginRight: "7px" }}> <Tag padding='0 4px' height='28px' style={{ fontSize: "12px" }} primary>APPROVED</Tag> </div> : <></>}
                                            </Flex>
                                        </Select.Option>
                                    )}

                                </LabeledInput>
                            </div>
                            <div style={{ width: '15%', marginLeft: 12 }}>
                                <LabeledInput
                                    label="Payment Terms"
                                    labelcolor={theme.colors.secondary3}
                                    id="terms"
                                    key="terms"
                                    type="select"
                                    className="no-left-padding"
                                    placeholder="Select"
                                    value={terms}
                                    loading={loadingProducts}
                                    onChange={this.handlePaymentTermsSelect}
                                    error={errorField == "terms"}
                                    errorMessage={errorMessage}
                                    dropdownRender={menu => (
                                        <>
                                            {menu}
                                        </>
                                    )}
                                    noAsterisk
                                    optional
                                >
                                    {paymentTermsItems.map(item => <Option key={item.termId || item} value={item.termId || item}>{item.name || item}</Option>)}
                                </LabeledInput>
                            </div>
                        </Flex>
                        <Flex style={{ justifyContent: "flex-start" }}>
                            {billingAddress &&
                                <div style={{ width: '50%', marginRight: 12 }}>
                                    <Skeleton loading={billingAddressLoading} active>
                                        <LabeledInput
                                            id="billingAddress"
                                            key="billingAddress"
                                            label="Billing Address"
                                            labelcolor={theme.colors.secondary3}
                                            type="location"
                                            getLocation={this.getBillingLocation}
                                            address={billingAddress}
                                        />
                                    </Skeleton>
                                </div>}
                            {shippingAddress &&
                                <div style={{ width: '50%', marginLeft: 12 }}>
                                    <Skeleton loading={shippingAddressLoading} active>
                                        <LabeledInput
                                            id="shippingAddress"
                                            key="shippingAddress"
                                            label="Shipping Address"
                                            labelcolor={theme.colors.secondary3}
                                            type="location"
                                            getLocation={this.getShippingLocation}
                                            address={shippingAddress}
                                        />
                                    </Skeleton>
                                </div>}
                        </Flex>
                        <Flex style={{ justifyContent: "flex-start" }}>
                            <div style={{ width: `${100 / 3}%`, marginRight: 12 }}>
                                <LabeledInput
                                    label="Invoice Template"
                                    labelcolor={theme.colors.secondary3}
                                    id="docTemplateName"
                                    key="docTemplateName"
                                    type="select"
                                    className="no-left-padding"
                                    placeholder="Select Template"
                                    value={docTemplateName}
                                    defaultValue={docTemplateName}
                                    onChange={this.handleTemplateSelect}
                                >
                                    {(customerTemplate ? customerTemplates : activeTemplates).map(item => <Option key={item.templateName} value={item.templateName}>{item.templateName}</Option>)}
                                </LabeledInput>
                            </div>
                            {!showScheduleInfo &&
                                <div style={{ width: `${100 / 3}%`, marginRight: 12, marginLeft: 12 }}>
                                    <LabeledInput
                                        label="Invoice Date"
                                        labelcolor={theme.colors.secondary3}
                                        id="txnDate"
                                        key="txnDate"
                                        type="date-picker"
                                        // disabledDate={this.disabledDate}
                                        placeholder={moment().format(dateFormat)}
                                        value={txnDate && txnDate}
                                        format={dateFormat}
                                        onChange={(date, dateStr) => this.saveUserInput("txnDate", moment.utc(dateStr).utcOffset(0))}
                                    />
                                </div>
                            }
                            {!showScheduleInfo &&
                                <div style={{ width: `${100 / 3}%`, marginLeft: 12 }}>
                                    <LabeledInput
                                        label="Due Date"
                                        labelcolor={theme.colors.secondary3}
                                        id="dueDate"
                                        key="dueDate"
                                        type="date-picker"
                                        // disabledDate={this.disabledDate}
                                        placeholder={moment().add(30, "days").format(dateFormat)}
                                        value={dueDate && dueDate}
                                        format={dateFormat}
                                        onChange={(date, dateStr) => {
                                            this.saveUserInput("dueDate", moment.utc(dateStr).utcOffset(0))
                                        }}
                                    />
                                </div>
                            }
                        </Flex>
                        <Flex start style={{ marginBottom: 8 }}>
                            <span style={{ fontSize: "0.9rem", color: theme.colors.systemGray, marginRight: 8 }}>Add tracking information for shipment?</span>
                            <Switch size="small" defaultChecked={showShippingInfo} onChange={() => this.setState({ showShippingInfo: !showShippingInfo })} />
                        </Flex>
                        {
                            showShippingInfo &&
                            <Flex style={{ justifyContent: "flex-start" }}>
                                <div style={{ width: `${100 / 3}%`, marginRight: 12 }}>
                                    <LabeledInput
                                        label="Ship Via"
                                        labelcolor={theme.colors.secondary3}
                                        id="shipVia"
                                        key="shipVia"
                                        placeholder="FedEx"
                                        value={shipVia}
                                        onChange={this.handleTextChange}
                                    />
                                </div>
                                <div style={{ width: `${100 / 3}%`, marginRight: 12, marginLeft: 12 }}>
                                    <LabeledInput
                                        label="Ship Date"
                                        labelcolor={theme.colors.secondary3}
                                        id="shipDate"
                                        key="shipDate"
                                        type="date-picker"
                                        placeholder={moment().format(dateFormat)}
                                        value={shipDate && moment(shipDate)}
                                        format={dateFormat}
                                        onChange={(date, dateStr) => this.saveUserInput("shipDate", moment.utc(dateStr).utcOffset(0))}
                                    />
                                </div>
                                <div style={{ width: `${100 / 3}%`, marginLeft: 12 }}>
                                    <LabeledInput
                                        label="Tracking #"
                                        labelcolor={theme.colors.secondary3}
                                        id="shipTrackingNumber"
                                        key="shipTrackingNumber"
                                        placeholder="Enter a number"
                                        defaultValue={shipTrackingNumber}
                                        onChange={this.handleTextChange}
                                    />
                                </div>
                            </Flex>
                        }
                        {/* Line Items */}
                        {/* <InputContainer style={{ marginBottom: 0 }}> */}

                        <FlexColumn gap="10px" fullWidth>

                            <LineItemsHeader>
                                <div style={{ width: "29%" }}><Text color='#444444' weight='600'>Product</Text></div>
                                <div style={{ width: "29%" }}><Text color='#444444' weight='600'>Description</Text></div>
                                <div style={{ width: "12%" }}><Text color='#444444' weight='600'>Unit Price</Text></div>
                                <div style={{ width: "12%" }}><Text color='#444444' weight='600'>Quantity</Text></div>
                                {/* <div style={{ width: "10%" }}><Text color='#444444' weight='600'>Discount</Text></div> */}
                                <div style={{ width: "13%", display: "flex", justifyContent: "flex-end" }}><Text color='#444444' weight='600'>Net Amount</Text></div>
                                <div style={{ width: "5%" }} />
                            </LineItemsHeader>

                            {lineItems.map((lineItem, i) => {
                                var { itemId, productName, description, amount, qty, unitPrice } = lineItem || {}
                                var lineItemDiscount = (lineItem || {}).discount
                                // if (amount < 0) amount = amount * -1
                                var itemId = i;
                                return (
                                    <LineItemRow key={itemId + "container"}>
                                        <div style={{ width: "29%", paddingRight: 24 }}>
                                            <LabeledInput
                                                small
                                                nomargin
                                                id="product"
                                                key={itemId + "product"}
                                                type="select"
                                                placeholder="Select a product"
                                                value={productName}
                                                onChange={(value) => this.handleLineItemProductSelection(value, itemId)}
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => {
                                                    return option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }}
                                                dropdownRender={option => (
                                                    <div>
                                                        {option}
                                                        <Divider style={{ margin: '4px 0' }} />
                                                        <div style={{ padding: '8px', cursor: 'pointer', fontSize: "1.0rem" }}>
                                                            <a onClick={() => this.setState({ showNewProductModal: true })}>
                                                                <PlusOutlined />
                                                                <span> New Product</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                )}
                                            >
                                                {(activeProducts || []).map(item => <Select.Option key={item.id} id="productId" name={item.name} value={item.id} style={{ backgroundColor: "transparent" }}>{item.name}</Select.Option>)}
                                            </LabeledInput>
                                        </div>
                                        <div style={{ width: "29%", paddingRight: 24 }}>
                                            <LabeledInput
                                                small
                                                nomargin
                                                id="description"
                                                key={itemId + "description"}
                                                placeholder="Add text"
                                                value={(description)}
                                                onChange={e => this.handleLineItemTextChange(e, itemId)}
                                            />
                                        </div>
                                        <div style={{ width: "12%", paddingRight: 24, paddingTop: 8  }}>
                                            <LabeledInput
                                                small
                                                nomargin
                                                id="unitPrice"
                                                key={itemId + "unitPrice"}
                                                placeholder="1000"
                                                value={`$ ${unitPrice || 0}`}
                                                type="read-only"
                                                divider={false}
                                            />
                                        </div>
                                        <div style={{ width: "12%", paddingRight: 24 }}>
                                            <LabeledInput
                                                small
                                                nomargin
                                                id="qty"
                                                key={itemId + "qty"}
                                                placeholder="5"
                                                value={(qty)}
                                                onChange={e => this.handleLineItemTextChange(e, itemId)}
                                            />
                                        </div>
                                        {/* <div style={{ width: "10%" }}>
                                        <LabeledInput
                                            small
                                            nomargin
                                            id="discount"
                                            key={itemId + "discount"}
                                            placeholder="0"
                                            prefix="$"
                                            value={(lineItemDiscount)}
                                            onChange={e => this.handleLineItemTextChange(e, itemId)}
                                        />
                                    </div> */}
                                        <div style={{ width: "13%", paddingTop: 8 }}>
                                            <LabeledInput
                                                small
                                                nomargin
                                                id="amount"
                                                key={itemId + "amount"}
                                                placeholder="5000"
                                                value={`$ ${amount || 0}`}
                                                type="read-only"
                                                divider={false}
                                                alignRight
                                            />
                                        </div>
                                        <Flex end style={{ width: "5%", paddingTop: 8 }}>
                                            {
                                                lineItems.length > 1 &&
                                                <img width='24px' height='24px' style={{ cursor: 'pointer' }} src={DeleteIcon} onClick={() => this.removeLineItemRow(itemId)} />
                                            }
                                        </Flex>
                                    </LineItemRow>)
                            })}

                        </FlexColumn>

                        {/* </InputContainer> */}
                        {/* <Flex style={{ justifyContent: "space-between", marginTop: "15px" }}> */}
                        {/* <FlexColumn style={{ width: "50%" }}>
                                <TextButton style={{ textAlign: "left", paddingLeft: 0 }} icon={<PlusOutlined />} onClick={this.addLineItemRow} text='Add another line' />
                                <Tabs defaultActiveKey="0">
                                    <TabPane tab="Attachments" key="0">
                                        <Text style={{ marginBottom: "10px" }}>Add any attachments</Text>
                                        <UploadFiles
                                            uploadFileType="invoices"
                                            fileList={fileList}
                                            onUpload={this.handleFileUpload}
                                            hasUnsignedFiles={true}
                                            noPreview
                                            maxCount={5}
                                        />
                                    </TabPane>
                                    <TabPane tab="Memo on Invoice" key="1">
                                        <LabeledInput
                                            type="text-area"
                                            id="memo"
                                            key="memo"
                                            maxLength={500}
                                            placeholder="Add any message. This will appear on the invoice"
                                            defaultValue={memo}
                                            onChange={this.handleTextChange}
                                        />
                                    </TabPane>
                                    <TabPane tab="Private Note" key="2">
                                        <LabeledInput
                                            type="text-area"
                                            id="privateNote"
                                            key="privateNote"
                                            maxLength={500}
                                            placeholder="Add a private note. This if for your records only and will not appear on the invoice document."
                                            defaultValue={privateNote}
                                            onChange={this.handleTextChange}
                                        />
                                    </TabPane>
                                </Tabs>
                            </FlexColumn> */}

                        <div style={{ width: 160 }}>
                            <TextButton onClick={() => this.addLineItemRow()} margin="24px 0 24px 20px" icon={<PlusOutlined />} text="Add Line Item" />
                        </div>

                        <GradientDivider />

                        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", marginTop: 20, marginBottom: 30 }}>
                            <div style={{ display: "flex", alignItems: "center", marginRight: 24 }}>
                                <Text right size='14px'>SUBTOTAL</Text>
                                <Text right style={{ minWidth: 157 }}>{`$ ${toCurrency(lineItemTotals.subTotal)}`}</Text>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", marginRight: 24, marginTop: 8 }}>
                                <Text right size='14px'>DISCOUNT</Text>
                                {/* <Text right style={{ minWidth: 157 }}>{`$ ${toCurrency(lineItemTotals.discount)}`}</Text> */}
                                <Text right style={{ maxWidth: 145, marginLeft: 10 }}>
                                    <LabeledInput
                                        id="discount"
                                        key="discount"
                                        style={{ width: "150px", margin: 0 }}
                                        prefix="$"
                                        value={discount}
                                        defaultValue={0}
                                        onChange={this.handleTextChange}
                                        alignRight
                                    />
                                </Text>
                            </div>

                            <div style={{ display: "flex", alignItems: "center", marginRight: 24, marginTop: 8 }}>
                                <Text right size='14px'>TAXES</Text>
                                {/* <Text right style={{ minWidth: 157 }}>{`$ ${toCurrency(taxes)}`}</Text> */}
                                <Text right style={{ maxWidth: 145, marginLeft: 10 }}>
                                    <LabeledInput
                                        id="taxes"
                                        key="taxes"
                                        style={{ width: "150px", margin: 0 }}
                                        prefix="$"
                                        value={taxes}
                                        defaultValue={0}
                                        onChange={this.handleTextChange}
                                        alignRight
                                        nomargin="0"
                                    />
                                </Text>
                            </div>

                            <GradientDivider marginTop='24px' />

                            <div style={{ display: "flex", alignItems: "center", marginRight: 24, marginTop: 24 }}>
                                <Text right size='14px'>TOTAL</Text>
                                <Text right size='24px' style={{ minWidth: 157 }}>{`$ ${toCurrency(lineItemTotals.totalAmt)}`}</Text>
                            </div>
                        </div>
                        {/* </Flex> */}
                    </FlexColumn>
                    {/* <Affix style={{ position: 'absolute', bottom: 0, right: 0, left: 0 }}>
                            <Flex style={{ padding: "0 40px", width: "100%", background: theme.body, justifyContent: "flex-end" }}>
                                <Space>
                                    <Button type="primary" style={{margin: '10px 0', height: "50px"}} size="large" loading={loadingPreview} onClick={() => this.handleSave({ saveAndPreview: true })}>PREVIEW</Button>
                                    <Flex style={{ border: `1.5px solid ${theme.colors.secondary4}`, borderRadius: "5px", margin: '10px 0', height: "50px", background: theme.body }} center>
                                        <Button type="link" onClick={this.handleSave} loading={loadingSave} style={{ color: theme.colors.primary, fontSize: "1.2rem", fontWeight: 500, height: "100%" }}>SAVE</Button>
                                        <Dropdown overlay={buttonMenu}>
                                            <DownOutlined style={{color: theme.colors.primary, fontSize: "1.2rem", cursor: "pointer", padding: "0 10px", borderLeft: `1.5px solid ${theme.colors.secondary4}`, height: "100%", lineHeight: "50px"}} />
                                        </Dropdown>
                                    </Flex>
                                </Space>
                            </Flex>
                        </Affix> */}
                    {/* </div> */}

                    <Text style={{ marginBottom: "10px" }}>ADD ANY ATTACHMENTS</Text>
                    <UploadFiles
                        {...uploadProps}
                        uploadFileType="invoices"
                        fileList={fileList.filter(item => item.type != "InvoiceDoc")}
                        onUpload={this.handleFileUpload}
                        hasUnsignedFiles={true}
                        noPreview
                        maxCount={5}
                        showUploadList
                    />
                    <LabeledInput
                        margin="10px 0"
                        label="MEMO ON INVOICE"
                        id="memo"
                        key="memo"
                        placeholder="Enter text"
                        optional
                        onChange={(event) => this.saveUserInput(event.target.id, event.target.value)}
                        value={memo}
                    />
                    <LabeledInput
                        margin="10px 0"
                        type="text-area"
                        label="PRIVATE NOTE"
                        id="privateNote"
                        key="privateNote"
                        maxLength={500}
                        placeholder="Add a private note. This if for your records only and will not appear on the invoice document."
                        defaultValue={privateNote}
                        optional
                        onChange={this.handleTextChange}
                    />

                    <Flex style={{ marginTop: 40 }} start>
                        {
                            !showScheduleInfo ?
                                !scheduleId && // Do not show preview for scheduled invoice
                                <Button permtype="Override" solid loading={loadingPreview} onClick={() => this.handleSave({ saveAndPreview: true })} text="PREVIEW & SEND" icon={<EyeOutlined />} />
                                :
                                <Button permtype="Override" loading={loadingSchedule} onClick={() => this.handleSchedule()} text="SCHEDULE" />
                        }

                        {
                            !showScheduleInfo && <Button onClick={this.handleSave} loading={loadingSave} text="SAVE" icon={<SaveOutlined />} margin='0 0 0 24px' />
                        }
                    </Flex>
                </FlexColumn>
                <Modal
                    visible={this.state.showNewProductModal}
                    footer={null}
                    closable={true}
                    width={700}
                    style={{ top: 20 }}
                    destroyOnClose={true}
                    onCancel={() => { this.setState({ showNewProductModal: false }) }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <NewProduct
                        product={{}}
                        submitComplete={() => {
                            this.setState({ showNewProductModal: false })
                            this.fetchProducts({
                                pagination: {
                                    current: 1,
                                    pageSize: 500
                                }
                            })
                        }}
                        categories={this.props.categories}
                    />
                </Modal>
                <Modal
                    visible={this.state.showNewCustomerModal}
                    footer={null}
                    closable={true}
                    width={1200}
                    style={{ top: 20 }}
                    destroyOnClose={true}
                    className="scroll-overflow-95"
                    onCancel={() => { this.setState({ showNewCustomerModal: false }) }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <NewCustomer
                        submitComplete={() => {
                            this.setState({ showNewCustomerModal: false })
                            this.fetchCustomers({
                                pagination: {
                                    current: 1,
                                    pageSize: 1000
                                }
                            })
                        }}
                    />
                </Modal>

                <AddModalWO
                    visible={this.state.showAddModalWO}
                    onAdd={this.handleWOAdded}
                    onCancel={() => { this.setState({ showAddModalWO: false }) }}
                    aionStore={this.props.aionStore}
                    store={this.props.store}
                    customerId={selectedCustomer && selectedCustomer.customerId}
                    customer={selectedCustomer}
                />
                <Drawer
                    width={selectedTab == "email" ? 1400 : 900}
                    closable={true}
                    onClose={() => { this.setState({ showPreviewModal: false }) }}
                    visible={this.state.showPreviewModal}
                    destroyOnClose={true}
                    closeIcon={<Image src={ModalClose} />}
                >
                    <Flex center style={{ justifyContent: "space-between" }}>
                        <FlexColumn>
                            <Text size='28px' heading>Invoice <span style={{ color: theme.colors.primary }}>{invoice.docNumber}</span></Text>
                        </FlexColumn>
                        <Button
                            primary solid
                            margin='0 48px'
                            permtype="Receivables.Invoices"
                            loading={loadingSend}
                            onClick={() => !showScheduleInfo ? this.handleSend() : this.props.close()}
                            text={!showScheduleInfo ? "SEND" : "DONE"}
                        />
                    </Flex>
                    <Tabs size="large" defaultActiveKey={selectedTab} onChange={this.handleTabChange}>
                        <TabPane tab="Preview Email" key="email">
                            <div style={{ display: 'flex', width: '100%' }}>
                                <FlexColumn start style={{ width: '50%', paddingRight: 12 }}>
                                    <LabeledInput
                                        label="From"
                                        id="fromName"
                                        key="fromName"
                                        placeholder="Enter a name"
                                        value={this.state.fromName}
                                        onChange={(e) => this.setState({ [e.target.id]: e.target.value })}
                                        instruction="This name will show as the from name on the email"
                                    />

                                    <LabeledInput
                                        label="To"
                                        id="emails"
                                        key="emails"
                                        type="multi-email"
                                        disabled
                                        onChange={(toEmails) => {
                                            this.setState({ toEmails })
                                        }}
                                        onDelete={(index) => {
                                            var emails = []

                                            for (var i = 0; i < toEmails.length; i++) {
                                                if (i != index) emails.push(toEmails[i])
                                            }

                                            this.setState({ toEmails: emails })
                                        }}
                                        emails={toEmails && toEmails}
                                        instruction="The email/s above will receive the invoice"
                                    />

                                    <LabeledInput
                                        label="CC"
                                        id="cc"
                                        key="cc"
                                        type="multi-email"
                                        optional
                                        disabled
                                        onChange={(ccEmails) => {
                                            this.setState({ ccEmails })
                                        }}
                                        onDelete={(index) => {
                                            var emails = []

                                            for (var i = 0; i < ccEmails.length; i++) {
                                                if (i != index) emails.push(ccEmails[i])
                                            }

                                            this.setState({ ccEmails: emails })
                                        }}
                                        emails={ccEmails && ccEmails}
                                    />

                                    <br />

                                    <Text color='#7384AA'>Body</Text>

                                    {
                                        this.state.aiGeneratedEmail
                                            ?
                                            <div style={{ marginTop: 8 }} className="ant-input">
                                                <Editor editorState={this.state.editorState} onChange={(editorState) => {
                                                    this.setState({ editorState })
                                                }} />
                                            </div>
                                            :
                                            <div style={{ marginTop: 8 }} className="ant-input">
                                                <Editor editorState={this.state.editorState} onChange={(editorState) => {
                                                    this.setState({ editorState })
                                                }} />
                                            </div>
                                    }
                                    <Badge.Ribbon text="Beta" style={{ marginBottom:"5px"}}>
                                        <Flex start style={{ marginTop: "20px", marginBottom: "20px", alignItems: 'left' }}>
                                            <img height='30px' width='30px' src={Sparkle} />
                                            <Text style={{ textAlign: "left", fontSize: '16px', color: '#7384AA' }}>Generate email drafts using our new AI-powered assistant!</Text>
                                        </Flex>


                                        <Flex start style={{ alignItems: 'left' }}>
                                            <Button loading={loadingEmail} solid text="GENERATE EMAIL" onClick={() => this.generateAIEmail()}>Generate Email</Button>
                                        </Flex>
                                    </Badge.Ribbon>
                                    

                                </FlexColumn>

                                <FlexColumn style={{ width: '50%', paddingLeft: 12 }}>
                                    <>
                                        {htmlMailtext && renderHTML(htmlMailtext.replace("{CustomBody}", stateToHTML(this.state.editorState.getCurrentContent())))}
                                    </>

                                </FlexColumn>
                            </div>
                        </TabPane>
                        <TabPane tab="Preview Document" key="document">
                            <FlexColumn start className='no-select'>
                                <TextButton icon={<DownloadOutlined />} onClick={() => { saveAs(invoiceDoc.preSignedUrl, `Invoice ${docNumber}`) }} text="Download" />

                                <Document
                                    file={invoiceDoc.preSignedUrl}
                                    onLoadSuccess={this.onDocumentLoadSuccess}
                                    style={{ border: `1px solid ${theme.colors.systemGray6}`, borderRadius: "5px", marginTop: "15px" }}
                                >
                                    {
                                        Array.from(
                                            new Array(numPages),
                                            (el, index) => (
                                                <Page
                                                    key={`page_${index + 1}`}
                                                    pageNumber={index + 1}
                                                />
                                            ),
                                        )
                                    }
                                </Document>
                            </FlexColumn>
                        </TabPane>
                    </Tabs>
                </Drawer >
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer,
        receivablesStore: state.receivablesAppReducer,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Index))