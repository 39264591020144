import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Modal, Layout, Typography, Alert, message } from 'antd'
import queryString from 'query-string'

import OAuthClient from 'intuit-oauth'

// Components
import AdvancesTable from '../Advances/AdvancesTable'
import LimitsBalances from '../LimitsBalances'

import PageHeader from "../../Reusable/PageHeader"
import { FlexColumn, Container, Flex, LightContainer } from '../../Reusable/Container'
import { Button, TextButton } from '../../Reusable/Button'
import { Text, Tag } from '../../Reusable/Text'
import { addDataToStore, SAVE_DATA, CODAT_DATA } from '../../../Actions/actions'
import BoldBanner from '../../Reusable/BoldBanner'

// Util
import { apiGET, apiPOST, apiPOSTReq, getPreSignedS3Url } from '../../../Utils/api'
import { camelCaseToPascalCase } from '../../../Utils/util'
import { getAttributes } from '../../../Utils/dataManager'
import environment from '../../../environment'
import { completeSignin } from '../../../Actions/actions'

// import qbButton from '../../../Images/C2QB_auth.png'
import qbButton from '../../../Images/C2QB_green_btn_tall_hover.svg'
import { ErrorAlert } from '../../Reusable/Alert'
import Banner from '../../Reusable/Banner'
import AdvanceDetail from './AdvanceDetail'
import moment from 'moment'
import ModalClose from '../../../Images/modal-close.png'

import ArrowRight from '../../../Images/arrow-right.png'
import Dollar from "../../../Images/gs-dollar.png"
import ChkListIcon from '../../../Images/checklist-icon.png'
import Check from "../../../Images/gs-check.png"
import { GettingStartedCard } from '../../Reusable/Card'
import Lock from "../../../Images/gs-lock.png"
import ThumbsUp from "../../../Images/like-green.png"
import Question from '../../../Images/question-circle.png'
import QB from '../../../Images/quickbooks-icon-sm.jpeg'
import Clock from '../../../Images/clock.png';
import { Image } from '../../Reusable/Image'
import { update } from 'lodash'
import { saveAs } from 'file-saver'

const { Title } = Typography
const { Header, Content, Sider, Footer } = Layout

class Index extends Component {

    state = {
        loading: true,
        qbModalVisible: false,
        qbConnect: false,
        hideGettingStarted: false,
        showNoQBInfoModal: false,
        updateLoanApplication: true,
        isZeroAdvances: false,
        skipAccountingSoftware: false,
        ifFeesAreToBeDrawn: false,
        showBanner: true
    }

    componentDidMount() {
        // this.fetchLoanApp();
        // this.fetchBusinessData();
        const value = queryString.parse(this.props.location.search)
        const state = value.state
        const url = window.location.href

        if ((this.props.location.state)) {
            console.log("this.props.location.state", JSON.stringify(this.props.location.state))
        }

        if (state && state === 'qb-credit-term-loans') {
            this.props.history.replace({
                pathname: '/credit/term-loans/advances',
                state: { qbRedirect: true, qbModalVisible: false, qbUrl: url, qbRealmId: value.realmId },
            })
        } else if ((this.props.location.state || {}).qbRedirect) {
            const { qbUrl, qbRealmId } = this.props.location.state

            if (qbUrl && qbRealmId) {
                const headers = {
                    businesskey: this.props.aionStore.BusinessUniqueKey
                }

                apiGET(`/financing/oauth/token?url=${qbUrl}`, headers, (err, resp) => {
                    try {
                        const token = resp.data.Response
                        if (resp.data.success) {

                            const data = {
                                accessToken: token.access_token,
                                accessTokenExpiresIn: token.expires_in,
                                accessTokenSecret: "",
                                companyId: qbRealmId,
                                accountingSoftware: "QuickBooksOnline",
                                dateCreated: Date.now(),
                                refreshToken: token.refresh_token,
                                refreshTokenExpiresIn: token.x_refresh_token_expires_in,
                                oauthVersion: "2.0",
                                // refreshTokens: true
                            }

                            apiPOST('/connect/business_software', headers, data, (err, resp) => {
                                if (!err) {
                                    try {
                                        const data = resp.data

                                        if (data.success) {
                                            getAttributes((err, data) => {
                                                this.setState({ submitLoading: false })
                                                console.log("data", data)
                                                this.props.dispatch(completeSignin(data))
                                                console.log("Attributes", this.props.aionStore.Attributes)
                                            })

                                            message.success('QuickBooks connected successfully!')
                                            // this.props.history.replace('/credit/term-loans/advances')
                                            this.props.history.replace({
                                                pathname: '/credit/term-loans/advances',
                                                state: { qbConnected: true },
                                            })
                                        } else {
                                            message.error('Sorry, we had trouble connecting your QuickBooks. Please try again.')
                                            this.setState({ qbModalVisible: true })
                                            throw Error("Could not complete submission.")
                                        }
                                    } catch (error) {
                                        console.error("The error message is :" + JSON.stringify(error))
                                        message.error('Sorry, we had trouble connecting your QuickBooks. Please try again.')
                                        this.setState({ qbModalVisible: true })
                                    }
                                } else {
                                    console.error("The error message is :" + JSON.stringify(err))
                                    message.error('Sorry, we had trouble connecting your QuickBooks. Please try again.')
                                    this.setState({ qbModalVisible: true })
                                }
                                // this.setState({ invoiceRefreshLoading: false })
                            })
                        } else {
                            console.error("The error message is :" + JSON.stringify(err))
                            message.error('Sorry, we had trouble connecting your QuickBooks. Please try again.')
                            this.setState({ qbModalVisible: true })
                            // this.setState({ invoiceRefreshLoading: false })
                            throw Error("Could not fetch attributes.")
                        }
                    } catch (error) {
                        console.error("ERROR", error.stack)
                        message.error('Sorry, we had trouble connecting your QuickBooks. Please try again.')
                        this.setState({ qbModalVisible: true })
                        // this.setState({ invoiceRefreshLoading: false })
                    }
                })
            }
        } else if ((this.props.location.state || {}).qbConnected !== true) {
            this.checkForCodatConnection()
        }

        this.fetchAdvances()
    }

    checkForCodatConnection = () => {
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/codat/getConnections`, {}, {}, (err, resp) => {
            try {
                const data = resp || {};
                if (data.result) {
                    var connections = data.connections || []
                    if (connections.length > 0) {
                        this.setState({ connection: data.connection, company: data.company, qbModalVisible: false }) // company is a codat company construct
                        this.props.dispatch(addDataToStore(CODAT_DATA, { connections: connections, connection: connections.length > 0 && connections[0], company: data.company }))
                    } else {
                        throw Error("No connection found")
                    }
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("checkForCodatConnection error", { description: error.message })
                this.checkForQBOConnection()
            }
        })
    }

    checkForQBOConnection = () => {
        const { aionStore } = this.props
        const { Attributes } = aionStore
        var { Businesses } = Attributes || {}
        var businessAttributes = Businesses || {}

        if (businessAttributes.AccountingSWLinked !== true) {
            this.setState({ qbModalVisible: true })
        }
    }

    fetchBusinessData = () => {
        apiPOSTReq(`${environment.uamBaseUrl}/getBusiness`, {}, {}, (err, resp) => {
            try {
                if (err) throw Error(err);
                const data = resp || {};
                if (data.result) {
                    var business = data.business || {}
                    var attributes = {
                        Businesses: camelCaseToPascalCase(business.attributes || {})
                    }
                    this.props.dispatch(addDataToStore(SAVE_DATA, { Attributes: attributes }))
                } else {
                    throw Error(data.responseMessage || data.error);
                }
            } catch (error) {
                console.log("/getBusiness err", error, resp);
            }
        });
    }

    fetchLoanApp = () => {
        // Fetch document list
        var body = {
            "clientBusinessId": this.props.aionStore.BusinessUniqueKey,
            "size": 100,
            "page": 0,
            // "sortDirection": "ASC"
        }
        this.setState({ loanAppLoading: true });
        apiPOSTReq(`${environment.opsBaseUrl}/ops/loans/application/getApplications`, null, body, (err, resp) => {
            console.log("/getApplications resp body", resp, body);
            // this.setState({ loanAppLoading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    var loanApplications = data.loanApplications || [];
                    var loanApp = loanApplications.length > 0 ? loanApplications[loanApplications.length - 1] : {};
                    this.setState({ loanApplications: loanApplications, loanApplication: loanApp });
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        })
    }

    updateLoanApp = (updateData) => {
        const { loanApplication } = this.state;
        var body = {
            clientBusinessId: this.props.aionStore.BusinessUniqueKey,
            loanApplication: Object.assign(loanApplication || {}, updateData)
        }
        console.log("updateLoanApp", body);
        apiPOSTReq(`${environment.opsBaseUrl}/ops/loans/application/save`, {}, body, (err, resp) => {
            console.log("updateLoanApp /ops/loans/application/save err, resp", err, resp);
            try {
                const data = resp || {};
                if (data.result) {
                    this.setState({ loanApplication: data.loanApplication, updateLoanApplication: false });
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp);
                ErrorAlert({ description: `Error updating application. ${error.message}` || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    fetchInvoices() {
        const headers = {
            businesskey: this.props.aionStore.BusinessUniqueKey
        }
        this.setState({ loading: true });
        apiGET("/gnpl/funding/collateral", headers, (err, resp) => {
            this.setState({ loading: false });
            try {
                const data = resp.data;
                console.log("/gnpl/funding/collateral", data);
                this.setState({ ...data })
                var ifFeesAreToBeDrawn = data.DrawFeesToBorrower;
                this.setState({ ifFeesAreToBeDrawn: ifFeesAreToBeDrawn });
                if (!data.success && data.msg === "Tokens Expired") {
                    this.setState({ qbConnect: true, qbModalVisible: true, qbExpired: true })
                }
            } catch (error) {
                console.log("ERRR", error.stack);
                ErrorAlert({ description: "Sorry we had trouble processing your request 2, please try again." })
            }
        })
    }

    fetchAdvances() {
        const headers = {
            businesskey: this.props.aionStore.BusinessUniqueKey
        }
        this.setState({ loading: true })
        apiGET("/gnpl/funding/draws", headers, (err, resp) => {
            // this.setState({ loading: false })
            try {
                const data = resp.data
                console.log("gnpl/funding/draws", data)
                var ifFeesAreToBeDrawn = data.AdvanceInfo.ifFeesAreToBeDrawn;
                this.setState({ ...data, ifFeesAreToBeDrawn: ifFeesAreToBeDrawn })
                if (data.Advances.length == 0) {
                    this.setState({ isZeroAdvances: true })
                }

                this.fetchBusinessAttributes(this.props.aionStore.BusinessUniqueKey)
            } catch (error) {
                console.log("ERRR", error.stack)
                ErrorAlert({ description: "Sorry we had trouble processing your request, please try again." })
            }
        })
    }

    fetchBusinessAttributes = (businessId) => {
        var body = {
            "clientBusinessId": businessId
        }

        this.setState({ attributesLoading: true })
        apiPOSTReq(`${environment.opsBaseUrl}/ops/fetchBusinessAttributes`, null, body, (err, resp) => {
            this.setState({ attributesLoading: false })
            try {
                const data = resp || {}
                if (data.businessAttributes) {
                    var businessAttributes = data.businessAttributes
                    var freezeCreditLine = businessAttributes.filter(
                        item => {
                            return (item.name == "FreezeCreditLine" && moment(item.effectiveEndDate).toDate() >= moment().toDate())
                        }
                    )

                    if (freezeCreditLine.length > 0) {
                        var { lineFreeze } = this.state
                        lineFreeze = freezeCreditLine[0].value == "true" ? true : false
                        this.setState({
                            lineFreeze: lineFreeze
                        })
                    }

                    var accoutingSkipAttribute = businessAttributes.filter(item => item.name == "SkipAccountingSoftware")

                    if (accoutingSkipAttribute.length > 0) {
                        var { skipAccountingSoftware } = this.state
                        skipAccountingSoftware = accoutingSkipAttribute[0].value == "true" ? true : false
                        this.setState({
                            skipAccountingSoftware: skipAccountingSoftware
                        })
                    }

                    this.fetchInvoices();
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                // ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    showAdvance = (advance) => {
        console.log("showAdvance", advance)
        this.setState({ selectedAdvance: advance, showAdvanceModal: true })
    }

    reconnectQuickBooks() {
        this.setState({ qbModalVisible: false })

        var oauthClient = new OAuthClient(environment.qboOAuth)
        const authUri = oauthClient.authorizeUri({
            scope: [
                OAuthClient.scopes.Accounting,
                OAuthClient.scopes.OpenId,
                OAuthClient.scopes.Profile,
                OAuthClient.scopes.Email,
                OAuthClient.scopes.Phone,
                OAuthClient.scopes.Address
            ],
            state: 'qb-credit-term-loans'
        })

        window.location.href = authUri
    }
    getTaskCompletedCount = () => {
        var count = 0

        if (!this.state.qbModalVisible) count = count + 1
        if ((this.state.Advances || []).length > 0) count = count + 1
        return count
    }

    downloadContract() {
        return new Promise((resolve, reject) => {
            getPreSignedS3Url({ url: this.state.contractDocumentUrl }, (err, preSignedUrl) => {
                if (!err) {
                    saveAs(preSignedUrl, "AionContract");
                } else {
                    reject(JSON.stringify(err))
                }
            })
        })
    }

    fetchAndDownload = (item) => {
        var header = {
            AionCurrentBiz: this.props.aionStore.businessId
        }

        var body = {
            "BusinessId": this.props.aionStore.businessId,
            "noteId": item.NoteId
        }

        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/contract/downloadAdvancesContract`, header, body, (err, resp) => {
            try {
                const response = resp || {}
                if (response.result) {
                    const contractDocumentUrl = response.contractDocumentUrl
                    this.setState({ contractDocumentUrl: contractDocumentUrl }, () => this.downloadContract())

                }
                else {
                    ErrorAlert({ description: "Your loan agreement is currently unavailable. Please check back in a few minutes. If you are still unable to view the agreement, please reach out to your customer service contact to obtain this document." })
                }
            }
            catch (error) {
                console.log("ERRR downloading document", error, err, resp);
            }
        })

    }

    render() {
        const { AdvanceInfo, loading, qbModalVisible, lineFreeze, qbConnect, hideGettingStarted, updateLoanApplication, skipAccountingSoftware, isZeroAdvances, showBanner } = this.state
        const Advances = this.state.Advances || [];
        const { aionStore } = this.props
        const { Attributes, UAM } = aionStore
        var { Businesses } = Attributes || {}
        var businessAttributes = Businesses || {}

        // updating the loanApplication
        if ((this.state.loanApplication && Advances.length == 0 && updateLoanApplication && businessAttributes.AccountingSWLinked)
        ) {
            if (this.state.loanApplication.onboarding != undefined) {
                if (this.state.loanApplication.onboarding.toLowerCase() == "completed") {
                    this.setState({ updateLoanApplication: false })
                    this.updateLoanApp({ onboarding: "Collateral available" })
                }
            }
        }

        if (this.state.loanApplication && Advances.length == 1 && updateLoanApplication) {
            if (this.state.loanApplication.onboarding.toLowerCase() == "collateral available") {
                this.setState({ updateLoanApplication: false })
                this.updateLoanApp({ onboarding: "Funds requested" })
            }
        }

        return (
            <>
                <FlexColumn className='main-padding'>
                    <PageHeader
                        titleText="Advances"
                        subtitle="Term Loans"
                    />
                    <FlexColumn>
                        {
                            showBanner && <BoldBanner
                                icon={<Image src={Clock} />}
                                style={{ marginTop: "0", marginBottom: 24, width: "100%" }}
                                message="Funds will be available in 1 to 2 business days after the funding request is processed. Requests submitted after 6pm ET are processed the next business day."
                                onClose={() => {
                                    this.setState({ showBanner: false })
                                }}
                            />
                        }
                        {isZeroAdvances &&
                            <FlexColumn style={{ padding: "0 0 24px 0" }}>

                                <Flex between fullWidth style={{ height: '68px' }}>
                                    <Flex gap="24px">
                                        <FlexColumn center style={{
                                            height: '100%', margin: "auto"
                                        }}>
                                            <img width='48px' height='48px' src={ChkListIcon} />
                                        </FlexColumn>
                                        <FlexColumn centerVertically fullHeight start left>
                                            <Text size='20px' weight='500' height='40px'>Getting started with your Aion account</Text>
                                            <Text>Here are a few things that can help you get going</Text>
                                        </FlexColumn>
                                    </Flex>
                                    <Flex start centerHorizontally fullHeight gap='24px'>
                                        {/*
                           <Tag>{this.getTaskCompletedCount()}/2 steps completed</Tag>*/
                                        } <TextButton underline text={hideGettingStarted ? 'Show' : 'Hide'} weight='400' onClick={() => {
                                            this.props.dispatch(addDataToStore(SAVE_DATA, { hideGettingStarted: !hideGettingStarted }))
                                            this.setState({ hideGettingStarted: !hideGettingStarted })
                                        }} />
                                    </Flex>
                                </Flex>
                                {
                                    !hideGettingStarted &&
                                    <Flex start row gap="24px" style={{ margin: '40px 0 0 0' }} >
                                        {skipAccountingSoftware ? null : qbModalVisible ?
                                            <GettingStartedCard
                                                tabIcon={QB}
                                                title='Retrieve your invoices'
                                                desc='Please retrieve your collateral from QuickBooks Online to get funded easily'
                                                buttonText='CONNECT'
                                                buttonIcon={ArrowRight}
                                                onClick={() => this.setState({ qbConnect: true })}
                                                link
                                                linkText="Don’t have Quick Books Online"
                                                onLinkClick={() => {
                                                    this.setState({ showNoQBInfoModal: true });
                                                    this.updateLoanApp({ onboarding: "SkipAccountingSoftware" })
                                                }}
                                                linkIcon={Question}
                                                width={'400px'}
                                                minWidth={'592px'}
                                            />
                                            :
                                            <GettingStartedCard
                                                tabIcon={Dollar}
                                                title='Collateral Added'
                                                desc='Your invoices have been added to Aion successfully. You can select eligible collateral when you request funds.'
                                                completed
                                                width={'400px'}
                                                minWidth={'592px'}
                                            />

                                        }
                                        {qbModalVisible && !skipAccountingSoftware ?
                                            <GettingStartedCard
                                                tabIcon={Lock}
                                                title='Request funds'
                                                desc={`Request the available funds once you've completed adding your collateral to Aion.`}
                                                buttonText='REQUEST FUNDS'
                                                buttonIcon={ArrowRight}
                                                disabled
                                                width={'400px'}
                                                minWidth={'592px'}
                                            /> : <GettingStartedCard
                                                tabIcon={Dollar}
                                                title='Request funds'
                                                desc={`Request the available funds once you've completed adding your collateral to Aion.`}
                                                buttonText='REQUEST FUNDS'
                                                buttonIcon={ArrowRight}
                                                onClick={() => this.props.history.push('/credit/term-loans/advances/request')}
                                                width={'400px'}
                                                minWidth={'592px'}
                                            />}
                                    </Flex>
                                }
                            </FlexColumn>}
                        {/* Limits & Balances */}
                        <LimitsBalances advancesLoaded={!loading} data={AdvanceInfo || {}} type="TermLoans" />
                        <br />
                        {/* History */}
                        <AdvancesTable
                            type="short-term"
                            ifFeesAreToBeDrawn={this.state.ifFeesAreToBeDrawn}
                            advancesLoaded={!loading}
                            data={Advances}
                            allowRowClick={true}
                            selectRow={(record) => this.showAdvance(record)}
                            downloadContract={(record) => this.fetchAndDownload(record)}
                            ctaContent={Advances.length > 0 ?
                                <Button permtype="TermLoans.Advances" disabled={lineFreeze || UAM.aionCustomerSupportUser || UAM.aionUnderWriterUser} solid onClick={() => this.props.history.push('/credit/term-loans/advances/request')} text='Request Funds' />
                                :
                                null
                            }
                        />
                    </FlexColumn>
                </FlexColumn>
                <Modal
                    visible={this.state.showNoQBInfoModal}
                    footer={null}
                    closable={false}
                    width={456}
                    style={{ top: "35%" }}
                    height={324}
                    destroyOnClose={true}
                    onCancel={() => this.setState({ showNoQBInfoModal: false })}
                >
                    <FlexColumn start centerHorizontally>
                        <div style={{ width: '700px', zIndex: 5, }}>
                            <FlexColumn center style={{ margin: "24px" }}>
                                <img margin='24px 0 24px 12px' height='48px' width='48px' src={Question} />
                                <Text weight="500" margin="24px 0 0 0" size='28px' height='40px' width="448px" style={{ textAlign: 'center', maxWidth: '400px' }}>Contact Us</Text>
                                <Text margin='0 12px 24px 24px' weight="400" size="16px" spacing="0.8px" style={{ maxWidth: '360px', textAlign: 'center', lineHeight: "24px" }}>Don’t have QuickBooks Online? Contact us at <a href="mailto:contact@aionfi.com">contact@aionfi.com</a> and we can help you out.</Text>
                                <Button solid text='OKAY' onClick={() => this.setState({ showNoQBInfoModal: false })} />
                            </FlexColumn>
                        </div>
                    </FlexColumn>
                </Modal>
                <Modal
                    visible={this.state.showAdvanceModal}
                    footer={null}
                    closable={true}
                    width={800}
                    style={{ top: 20 }}
                    destroyOnClose={true}
                    onCancel={() => this.setState({ showAdvanceModal: false })}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <AdvanceDetail advance={this.state.selectedAdvance} downloadContract={(record) => this.fetchAndDownload(record)} />
                </Modal>
                <Modal
                    visible={qbConnect}
                    closable={false}
                    okText="Save"
                    onOk={this.saveCustomer}
                    confirmLoading={this.state.confirmLoading}
                    destroyOnClose={true}
                    width={624}
                    footer={null}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <FlexColumn padding="48px" >
                        <Text weight="500" style={{ fontSize: '20px', marginBottom: '48px' }}>{(businessAttributes.AccountingSWLinked ? "Reconnect " : "Connect ").concat("your accounting system")}</Text>
                        <Text weight="400" style={{ fontSize: '16px', marginBottom: '24px' }}>Use our one-way QuickBooks Online connection and automatically retrieve your receivables. You will be redirected to QuickBooks Online to provide authorization to Aion.</Text>
                        <Banner background="#F5F6FE" margin="0px" borderColor="2px solid var(--solid-primary-blue-20, #D7DCFB);" message="Make sure you have the right permissions. You will need master admin or company rights to connect to QuickBooks" />

                        <Banner background="#F5F6FE" margin="0px" padding="0px 24px 12px 24px" borderColor="2px solid var(--solid-primary-blue-20, #D7DCFB);" message="We do not store or have access to your QuickBooks Online credentials" />
                        <br />
                        <img style={{ cursor: 'pointer', height: 50 }} src={qbButton} onClick={() => { this.reconnectQuickBooks() }} />
                        <br />
                    </FlexColumn>
                </Modal>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.creditAppReducer,
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index))