import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled, { withTheme } from 'styled-components'

import { doSignout } from '../../Utils/api'

import BG from '../../Images/dynamic-dash-bg3.svg'
import TopNav from '../Reusable/TopNav'
import { Flex, FlexColumn } from '../Reusable/Container'
import UserWelcome from './Cards/UserWelcome'
import RecentTxns from './Cards/RecentTxns'
import { Text } from '../Reusable/Text'

import AionLogo from '../Reusable/Image'
import ARAging from './Cards/ARAging'
import CreditFunds from './Cards/CreditFunds'
import CardSpend from './Cards/CardSpend'
import BillsInbox from './Cards/BillsInbox'
import Apps from './Cards/Apps'
import Approvals from './Cards/Approvals'

const AnimatedBG = styled(FlexColumn)`
    background-image: url(${props => props.bgImage});
    background-size: cover;
    background-attachment: fixed;
    min-height: 100vh;
    width: 100%;
    overflow-y: auto;
`

class Index extends Component {
    state = {
    }

    componentDidMount() {
        
    }

    signout = () => {
        doSignout(this.props.history, this.props.dispatch)
    }

    render() {
        const { location, history, theme } = this.props
        const { notification, notificationText } = this.state

        return (
            <AnimatedBG start centerHorizontally bgImage={BG} style={{ padding: '20px 80px' }}>
                {/* <div style={{ marginLeft: 35, marginRight: 35, marginBottom: 20, paddingTop: '24px', width: "100%" }}>
                    <TopNav
                        content={
                            <Flex start centerHorizontally gap='16px'>
                                <AionLogo />
                            </Flex>
                        }
                        history={history} 
                        signout={() => this.signout()} 
                        notification={notification}
                        notificationText={notificationText} 
                        location={location} 
                    />
                </div> */}
                <Flex start centerVertically style={{ padding: '40px 80px', maxWidth: '1400px' }} gap='32px' wrap>
                    <UserWelcome />
                    <RecentTxns />
                    
                    
                    <CardSpend />
                    <Apps />

                    <CreditFunds />
                    <ARAging />
                    
                    <BillsInbox />
                    <Approvals />
                </Flex>
            </AnimatedBG>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)))