import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import { Tabs, Modal, Popconfirm, Table, Popover, message, Select, Dropdown, Menu, Upload } from 'antd'
import moment from 'moment'
import { saveAs } from 'file-saver'

import { USERDEFAULTS_SAVE_FLAG, addDataToStore } from '../../../Actions/actions'
import { FlexColumn } from '../../Reusable/Container'
import PageHeader from '../../Reusable/PageHeader'
import RescheduleBill from './Reschedule/Index'
import ViewBill from './ViewBill'
import ConnectionModal from '../../Reusable/ConnectionModal'

import { Image } from '../../Reusable/Image'
import { apiPOSTReq, getPreSignedS3Url } from '../../../Utils/api'
import environment from '../../../environment'
import { ErrorAlert } from '../../Reusable/Alert'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import ModalClose from '../../../Images/modal-close.png'
import Inbox from './Inbox/Index'
import BillTable from './BillTable'
import SearchAndFilter from './SearchAndFilter'
import { Button } from '../../Reusable/Button'
import _ from 'underscore'


const { TabPane } = Tabs
const { confirm } = Modal
var dFormat = "YYYY-MM-DD"

class Index extends Component {

    state = {
        loading: true,
        coaPagination: {
            current: 1,
            pageSize: 200
        },
        billPagination: {
            current: 1,
            pageSize: 100,
        },
        searchPagination: {
            current: 1,
            pageSize: 100
        },
        vendorPagination: {
            current: 1,
            pageSize: 100
        },
        modalWidth: 700,
        activeKey: this.props.userDefaultsStore.BillsActiveTab || "New",
        vendors: [],
        accountingSW: '',
        fromDate: null,
        toDate: null,
    }

    componentDidMount() {
        var { userDefaultsStore } = this.props
        console.log("userDefaultsStore", userDefaultsStore)
        this.onChangeTab(userDefaultsStore.BillsActiveTab || this.state.activeKey)
        this.fetchCOA({ coaPagination: this.state.coaPagination })
    }

    fetchBills = (options) => {
        var { activeKey } = this.state
        var { billPagination, status, searchTerm, fromDate, toDate, fromAmount, toAmount, vendorId } = options
        if(!billPagination) billPagination = {
            current: 1,
            pageSize: 100
        }
        var body = {
            "size": billPagination.pageSize || 100,
            "page": (billPagination.current || 1) - 1,
            "sortFieldName": "AuditData.LastUpdatedTime",
            "sortDirection": "DESC"
        }
        console.log("fetchBills options", status, activeKey)
        if(!status) status = activeKey

        if (status && status !== 'All') {
            body.viewby = "FILTER_STATUS"
            body.status = status
        }

        if (searchTerm) {
            const pagination = this.state.searchPagination
            body.size = pagination.pageSize
            body.page = pagination.current - 1
            body.searchStr = searchTerm
        }

        if (vendorId) {
            body.vendorId = vendorId
        }

        if (fromDate && toDate) {
            body.fromDate = fromDate
            body.toDate = toDate
        }

        if (_.isNumber(fromAmount) && _.isNumber(toAmount)) {
            body.fromAmount = fromAmount
            body.toAmount = toAmount
        }
        
        apiPOSTReq(`${environment.payBaseUrl}/payables/bills/getBills`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            try {
                const data = resp
                if (data.result) {
                    this.setState({
                        bills: data.bills || [], loading: false, showNewBillModal: false, payLoading: false, deleteLoading: false,
                        billPagination: {
                            ...billPagination,
                            total: data.count
                        }
                    })
                } else {
                    this.setState({ payLoading: false, deleteLoading: false })
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /payables/bills/getBills", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    // handleBillTableChange = (billPagination) => {
    //     this.fetchBills({ billPagination })
    // }

    fetchCOA = (options) => {
        const { coaPagination } = options
        const headers = {
            AionCurrentBiz: this.props.aionStore.BusinessUniqueKey,
            AionAuth: this.props.aionStore.UAM.encryptedAuthHeader
        }
        const body = {
            "BusinessId": this.props.aionStore.BusinessUniqueKey,
            "size": coaPagination.pageSize,
            "page": 0,
            "ActiveRecords": true,
            "sortFieldName": "Code",
            "sortDirection": "ASC"
        }
        this.setState({ newProductLoading: true })
        apiPOSTReq(`${environment.bbBaseUrl}/bk/getCategories`, headers, body, (err, resp) => {
            try {
                const data = resp
                console.log("/bk/getCategories", data)
                if (data.result) {
                    // this.props.dispatch(addDataToStore(BOOKKEEPING_SAVE_DATA, { COA: (data.systemCOAList || []).concat(data.customCOAList) }))
                    this.setState({
                        coaPagination: {
                            ...coaPagination,
                            total: data.count
                        },
                        categories: (data.systemCOAList || []).concat(data.customCOAList),
                    })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getActiveCustomers", error.stack, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    getParsedInvoice = (inboxItem) => {
        inboxItem = inboxItem || {}
        this.setState({ loading: true })
        apiPOSTReq(`${environment.payBaseUrl}/payables/bills/getParsedInvoice`, {}, { BillInboxItemId: inboxItem.id }, (err, resp) => {
            try {
                this.setState({ loading: false })
                const data = resp || {}
                console.log("/payables/bills/getParsedInvoice", err, data)
                var parsedInvoice = data.parsedInvoice || {}
                if (data.result) {
                    // Create a bill and vendor obj
                    var bill = {
                        "invoiceNumber": parsedInvoice.docNumber,
                        "dueDate": parsedInvoice.dueDate ? moment(parsedInvoice.dueDate).format("YYYY-MM-DD") : null,
                        "amount": parsedInvoice.totalAmt || parsedInvoice.balance,
                        "balance": parsedInvoice.balance,
                        "previewDocumentUrls": [
                            {
                                "uri": parsedInvoice.attachmentUrl
                            }
                        ],
                        "ponumber": parsedInvoice.ponumber
                    }

                    const body = {
                        "viewBy": "FILTER_ACTIVE"
                    }

                    apiPOSTReq(`${environment.payBaseUrl}/payables/vendors/getVendors`, {}, body, (err, resp) => {
                        try {
                            const data = resp
                            console.log("/payables/vendors/getVendors", data)
                            if (data.result) {
                                var vendors = data.vendors
                                var vendor = (vendors || []).find(item => item.vendorName == parsedInvoice.clientCompanyName)
                                vendor = vendor || {}
                                // var vendor = {
                                //     "vendorName": parsedInvoice.clientCompanyName,
                                //     "address": parsedInvoice.clientCompanyAddress
                                // }
                                getPreSignedS3Url({ url: bill.previewDocumentUrls[0].uri }, (err, preSignedUrl) => {
                                    this.setState({ parsedInvoice: parsedInvoice, bill: bill, billUrl: preSignedUrl, vendors: vendors, vendor: vendor, inboxItemLoading: false })
                                })
                            } else {
                                throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                            }
                        } catch (error) {
                            console.log("ERRR /payables/vendors/getVendors", error, err, resp)
                            ErrorAlert({ description: error.message })
                            this.setState({ inboxItemLoading: false })
                        }
                    })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /payables/bills/getParsedInvoice", error, err, resp)
                ErrorAlert({ description: error.message })
                this.setState({ inboxItemLoading: false })
            }
        })
    }

    handleNewBill = () => {
        this.setState({ bill: null, vendor: null, category: null, showNewBillModal: true, modalWidth: 700 })
    }

    handleNewBillFlow = () => {
        this.props.history.push('/payables/bills/new-bill')
    }

    handleSaveBill = () => {
        this.fetchBills({ billPagination: this.state.billPagination, status: "New" })
    }

    editBill = (bill) => {
        var { vendors } = this.state

        if (bill.vendorId) {
            if (bill.coaid) {
                category = this.state.categories.find(obj => {
                    return obj.id === bill.coaid
                })
            }

            this.props.history.push({
                pathname: '/payables/bills/edit',
                state: { bill, category, vendors, edit: true }
            })
        } else {
            var category
            if (bill.coaid) {
                category = this.state.categories.find(obj => {
                    return obj.id === bill.coaid
                })
            }

            this.props.history.push({
                pathname: '/payables/bills/edit',
                state: { bill, category, vendors, edit: true }
            })
        }
    }

    deleteBill = (id, invoiceNumber) => {
        if (!invoiceNumber) {
            invoiceNumber = ""
        }
        apiPOSTReq(`${environment.payBaseUrl}/payables/bills/delete`, { "BankProvider": environment.bankProvider.crb }, { billId: id }, (err, resp) => {
            try {
                const data = resp
                console.log("/payables/bills/delete", JSON.stringify(data))
                if (data.result) {
                    message.success(`Bill ${invoiceNumber} is deleted`)
                    this.fetchBills({ billPagination: this.state.billPagination, status: "Unpaid" })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /payables/bills/delete", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    cancelBillPayment = (id) => {
        apiPOSTReq(`${environment.payBaseUrl}/payables/cancelBillSchedule`, { "BankProvider": environment.bankProvider.crb }, { billId: id }, (err, resp) => {
            try {
                const data = resp
                console.log("/payables/cancelBillSchedule", JSON.stringify(data))
                if (data.result) {
                    this.fetchBills({ billPagination: this.state.billPagination, status: "Scheduled" })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /payables/bills/delete", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    downloadBill = (bill) => {
        getPreSignedS3Url({ url: bill.previewDocumentUrls[0].uri }, (err, preSignedUrl) => {
            if (!err) {
                saveAs(preSignedUrl, bill.invoiceNumber)
            } else {
                ErrorAlert({ description: JSON.stringify(err) })
            }
        })
    }

    handlePayBill = (bill) => {
        const { categories } = this.state

        if (bill.vendorId) {
            this.props.history.push({
                pathname: '/payables/bills/view',
                state: { bill, payFlow: true }
            })
        } else {
            var callback = () => {
                var category
                if (bill.coaid) {
                    category = categories.find(obj => {
                        return obj.id === bill.coaid
                    })
                }

                this.props.history.push({
                    pathname: '/payables/bills/edit',
                    state: { bill, category, edit: true }
                })
            }

            confirm({
                title: 'No vendor',
                icon: <ExclamationCircleOutlined />,
                content: "You haven't set a vendor for this bill. Click 'OK' to edit your bill and set one now.",
                onOk() {
                    callback()
                },
                onCancel() {
                },
            })
        }
    }

    handleRescheduleBill = (bill) => {
        this.props.history.push({
            pathname: '/payables/bills/reschedule',
            state: { bill }
        })
    }

    onChangeTab = (activeKey) => {
        this.props.dispatch(addDataToStore(USERDEFAULTS_SAVE_FLAG, { BillsActiveTab: activeKey }))
        this.setState({ activeKey, loading: true, bills: [], searchTerm: '', selectedVendorId: null })
        this.fetchBills({ billPagination: this.state.billPagination, status: activeKey })
    }

    handleTableChange = (pagination, filters, sorter) => {
        this.fetchBills({ billPagination: pagination })
    }
    
    onSearchChange = (e) => {
        this.setState({ searchTerm: e.target.value, selectedVendorId: '' })
        const pagination = {
            current: 1,
            pageSize: 100
        }
        this.fetchBills({ billPagination: pagination, searchTerm: e.target.value, status: this.state.activeKey })
    }

    handleFilterVendor = (e) => {
        this.setState({ selectedVendorId: e })
        this.fetchBills({ billPagination: this.state.billPagination, searchTerm: this.state.searchTerm, vendorId: e, status: this.state.activeKey })
    }

    handleDateRange = (dates) => {
        let fromDate = null
        let toDate = null
        if (dates) {
            fromDate = dates[0].format(dFormat)
            toDate = dates[1].format(dFormat)
        }
        this.setState({ fromDate, toDate })
        this.fetchBills({ billPagination: this.state.billPagination, searchTerm: this.state.searchTerm, vendorId: this.state.selectedVendorId, status: this.state.activeKey, fromDate, toDate })

    }

    handleNewBill = () => {
        this.setState({ bill: null, vendor: null, category: null, showNewBillModal: true, modalWidth: 700 })
    }

    handleNewBillFlow = () => {
        this.props.history.push('/payables/bills/new-bill')
    }

    render() {
        const { loading, bills, vendor, categories, billToView, uploading, showConnectAS, activeKey, deleteLoading, cancelLoading } = this.state
        const { theme, aionStore } = this.props
        const { Attributes } = aionStore

        var uploadProps = {
            accept: ".pdf",
            beforeUpload: file => {
                this.setState({ uploading: true })

                return
            },
            multiple: false,
            action: `${environment.payBaseUrl}/payables/bills/uploadBill`,
            headers: {
                AionCurrentBiz: this.props.aionStore.BusinessUniqueKey,
                AionAuth: this.props.aionStore.UAM.encryptedAuthHeader,
            },
            showUploadList: false,
            onChange: (info) => {
                var { fileList } = info
                var savedFileList = []
                fileList.forEach(file => {
                    const { status, response, name, url } = file

                    if (status === "done") {
                        console.log("payables/bills/uploadBill file", JSON.stringify(file))

                        var bill = file.response.bill

                        console.log("payables/bills/uploadBill file", JSON.stringify(file))

                        if (bill) {
                            if (file.response.vendor && !file.response.vendor.type) {
                                file.response.vendor.type = "Individual"
                            }

                            var vendor = file.response.vendor || {}

                            getPreSignedS3Url({ url: bill.previewDocumentUrls[0].uri }, (err, preSignedUrl) => {
                                this.props.history.push({
                                    pathname: '/payables/bills/new-bill',
                                    state: { bill: bill, billUrl: preSignedUrl, vendor: vendor, parsedVendor: vendor, title: "Bill Details", vendorType: vendor.id ? "Existing" : "New" }
                                })
                            })
                        } else {
                            ErrorAlert({ description: "Sorry we had trouble uploading your bill. Please try again later" })
                            // this.props.onCancel()
                        }
                    } else if (status === "error") {
                        console.log("payables/bills/uploadBill file", JSON.stringify(file))
                        ErrorAlert({ description: "Sorry we had trouble uploading your bill. Please try again later" })
                        // this.props.onCancel()
                    }
                })
            }
        }

        return (
            <>
                <FlexColumn start className='main-padding' grow>
                    <PageHeader
                        titleText="Bill Payments"
                        desc="List of all your bills"
                    />

                    {/* <Banner
                        icon={<Image src={Info} />}
                        style={{ marginTop: "0", width: "100%" }}
                        message="Connect your accounting system to start syncing your bill payments"
                        cta={<TextButton text='Connect now' onClick={() => this.props.history.push("/settings/sync")} underline weight='400' />}
                    /> */}


                    <Tabs defaultActiveKey="Unpaid" activeKey={activeKey} onChange={this.onChangeTab} style={{ marginBottom: 12 }} tabBarExtraContent={<Button solid onClick={this.handleNewBillFlow} text="ADD BILL" />}>
                        <TabPane tab="Inbox" key="New" />
                        <TabPane tab="Unpaid" key="Unpaid" />
                        <TabPane tab="Scheduled" key="Scheduled" />
                        <TabPane tab="All" key="All" />
                        <TabPane tab="Archived" key="Archive" />
                    </Tabs>

                    {
                        ["New", "Archive"].includes(activeKey) &&
                        <Inbox
                            key={activeKey}
                            selectedTab={activeKey} 
                            location={this.props.location} 
                            history={this.props.history}
                        />
                    }
                    {
                        ["Unpaid", "Scheduled", "All"].includes(activeKey) &&
                        <BillTable
                            activeTab={activeKey}
                            bills={bills}
                            pagination={this.state.billPagination}
                            loading={loading}
                            handleTableChange={this.handleTableChange}
                            titleText="Bills"
                            handleOnRow={(record) => this.handlePayBill(record)}
                            deleteBill={this.deleteBill}
                            handleRescheduleBill={this.handleRescheduleBill}
                            cancelBillPayment={this.cancelBillPayment}
                            deleteLoading={deleteLoading}
                            cancelLoading={cancelLoading}
                            ctaContent={
                                <SearchAndFilter
                                    key={activeKey}
                                    invoices={[]}
                                    fetchInvoices={this.fetchBills}
                                    handleSearchChange={this.onSearchChange}
                                />
                            }
                        />
                    }
                </FlexColumn>
                <Modal
                    visible={this.state.showViewBillModal}
                    footer={null}
                    closable={true}
                    maskClosable={true}
                    width={(billToView || {}).previewDocumentUrls && billToView.previewDocumentUrls.length > 0 ? 1050 : 700}
                    style={{ top: 20 }}
                    destroyOnClose={true}
                    onCancel={() => this.setState({ showViewBillModal: false, billToView: null })}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <ViewBill
                        bill={billToView}
                        vendor={vendor}
                        categories={categories}
                    />
                </Modal>
                <Modal
                    visible={this.state.showRescheduleBillModal}
                    footer={null}
                    closable={true}
                    maskClosable={true}
                    style={{ top: 20 }}
                    destroyOnClose={true}
                    onCancel={() => { this.setState({ showRescheduleBillModal: false, selectedVendor: null, selectedBill: null }) }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <RescheduleBill
                        vendor={this.state.selectedVendor}
                        bill={this.state.selectedBill}
                        submitComplete={
                            () => {
                                this.setState({ showRescheduleBillModal: false, selectedVendor: null, selectedBill: null })
                                this.fetchBills({ billPagination: this.state.billPagination, status: "Scheduled" })
                            }
                        }
                    />
                </Modal>
                <Modal
                    visible={showConnectAS}
                    footer={null}
                    closable={true}
                    maskClosable={true}
                    width={735}
                    destroyOnClose={true}
                    onCancel={() => this.setState({ showConnectAS: false })}
                    closeIcon={<Image src={ModalClose} />}
                >
                    <ConnectionModal
                        bill={billToView}
                        vendor={vendor}
                        categories={categories}
                    />
                </Modal>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer,
        userDefaultsStore: state.userDefaultsReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)))