import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'
import styled from 'styled-components'

import { message } from 'antd'

// Components
import { Title } from '../../Reusable/Text'
import { FlexColumn } from '../../Reusable/Container'
import environment from '../../../environment'

export const CardButton = styled.div`
    margin-top: 18px;
    background-color: rgb(255, 255, 255);
    border-radius: 0.5rem;
    width: 450px;
    margin-bottom: 14px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    opacity: 1;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.1);
    padding: 1.8rem;
    transition-property: box-shadow;
    transition-duration: 300ms;
    &.selected {
        cursor: pointer;
        box-shadow: 0 3px 15px rgb(0 0 0 / 0.2);
    };
    &.selected:hover {
        cursor: pointer;
        box-shadow: 0 3px 15px rgb(0 0 0 / 0.4);
    };
`

export const CardButtonText = styled.span`
    font-size: 18px
    font-weight: 400
`

class NewPayers extends Component {

    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            file: null,
            fileName: '',
            showErrorModal: false,
            fileList: [],
        }
    }

    render() {
        const { certifyChecked, responseErrorMessage } = this.state
        const { theme, aionStore } = this.props
        const darkMode = (theme.type === "dark")

        const { UserInfo } = aionStore
        var certifiedBy = `${UserInfo.FirstName} ${UserInfo.LastName}`

        var uploadProps = {
            accept: ".csv",
            beforeUpload: file => {
                console.log('before upload', file)
                this.setState({ polling: true, })
                return
            },
            multiple: false,
            action: this.props.actionUrl || `${environment.iveBaseUrl}/inventory/uploadInventories`,
            data: (file) => {
                return {
                    File: file,
                    CertifiedBy: certifiedBy,
                    Notes: null,
                }
            },
            headers: {
                AionCurrentBiz: this.props.aionStore.BusinessUniqueKey,
                AionAuth: this.props.aionStore.UAM.encryptedAuthHeader,
            },
            multiple: false,
            onChange: (info) => {
                var { file } = info
                console.log('info', info)
                this.setState({ fileList: [file] })
                const { status, response } = file

                console.log('status', status, response)
                if (status === "done") {
                    if (response.result) {
                        console.log('job id', response.uploadJobId)
                        message.success("Inventory uploaded successfully!")
                        this.props.submitComplete(response.uploadJobId)
                    } else {
                        console.log("inventory/uploadInventories response", JSON.stringify(response))
                        this.setState({
                            showErrorModal: true,
                            fileList: [],
                            responseErrorMessage: response.responseMessage,
                            uploadInventoryInfo: response.uploadInventoryInfo
                        })
                    }
                } else if (status === "error") {
                    console.log("inventory/uploadInventories file", JSON.stringify(file))
                    this.setState({ showErrorModal: true, fileList: [] })
                }
            }
        }

        return (
            <>
                <FlexColumn style={{ minHeight: "80%", margin: "5px 20px 35px 20px" }}>
                    <Title level={4}>New Payers</Title>
                    {/* <Paragraph noMargin>Import your inventory using our standard template</Paragraph>

                    <div>
                        <Button id="downloadTemplate" type="link" style={{ paddingLeft: 0, color: theme.colors.secondary1, fontSize: 16 }} icon={<DownloadOutlined />}>
                            <a href={this.props.downloadLink || "/Vendor_template.csv"} download={this.props.downloadName || "Vendor_template.csv"} style={{ color: theme.colors.secondary1 }}>&nbsp&nbspDownload CSV Template</a>
                        </Button>
                    </div>

                    <div style={{ display: "flex", flexDirection: 'column', alignItems: "center", marginTop: '30px' }} >
                        <Upload disabled={!certifyChecked} {...uploadProps} fileList={this.state.fileList}>
                            <CardButton className={certifyChecked && "selected"}>
                                <img src={darkMode ? DUploadIcon : LUploadIcon} width="25" height="25" style={{ marginRight: "15px", opacity: 0.8 }} />
                                <div>
                                    <CardButtonText>Upload File</CardButtonText>
                                    <Paragraph color={theme.colors.systemGray}>Please upload file as per the CSV template above</Paragraph>
                                </div>
                            </CardButton>
                        </Upload>
                        <Checkbox style={{ width: 450, marginTop: 4 }} onChange={(e) => this.setState({ certifyChecked: e.target.checked })}>Check this box to certify that all of the inventory records provided in the uploaded file are true and correct as of the date hereof.</Checkbox>
                    </div> */}
                </FlexColumn>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(NewPayers))