import React, { useState } from 'react'
import { message, Space, Table } from 'antd'

import { withTheme } from 'styled-components'
import { apiGET, apiPOSTReq, clearLocalStorage } from '../../Utils/api'
import { ErrorAlert } from './Alert'
import { getUserPermissions, uaApiPOST } from '../../Utils/userAccess'
import { completeSignin, SAVE_DATA, UAM_SAVE_PERMISSIONS, BNK_SAVE_DATA, addDataToStore, resetStore, RESET_BNK_STATE, SOFT_RESET_MAIN_STATE, RESET_ONBOARDING_STATE, RESET_CREDIT_STATE, RESET_RECEIVABLES_STATE, UAM_SAVE_BUSINESS, UAM_SAVE_USERINFO } from '../../Actions/actions'
import { LoadingOutlined } from '@ant-design/icons'
import { camelCaseToPascalCase, getUserApps } from '../../Utils/util'
import environment from '../../environment'
import { Text } from './Text'
import { Flex, FlexColumn } from './Container'
import { withRouter } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { cloneDeep } from 'lodash'

const SwitchBusiness = (props) => {
    const { linkedBusinessList, theme, aionStore } = props
    const { BusinessUniqueKey } = aionStore
    const [loading, setLoading] = useState(false)
    const [businessLoading, setBusinessLoading] = useState({})
    const [businessInfo, setBusinessInfo] = useState({})
    const dispatch = useDispatch()

    function switchToBusiness(record) {
        setLoading(true)
        setBusinessLoading(record)
        uaApiPOST("/aion/system/switchBusiness", { body: { businessId: record.businessId } }, (err, resp) => {
            try {
                var data = resp || {}
                if (err) throw Error(err)
                if (data.result) {
                    dispatch(addDataToStore(SAVE_DATA, { switchBizInProgress: true }))
                    // Erase data first
                    clearCache()
                }
                else throw Error(data.error || data.responseMessage)
            } catch (error) {
                console.log("switchBusiness Err", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    function clearCache() {
        dispatch(addDataToStore(BNK_SAVE_DATA, { BBAccounts: [] }))
        dispatch(resetStore(RESET_BNK_STATE))
        dispatch(resetStore(SOFT_RESET_MAIN_STATE))
        dispatch(resetStore(RESET_ONBOARDING_STATE))
        dispatch(resetStore(RESET_CREDIT_STATE))
        dispatch(resetStore(RESET_RECEIVABLES_STATE))

        fetchUserData(null, (err, resp) => {})
    }

    function fetchUserData (options, callback) {
        options = options || {}
        var { cognitoUserInfo } = aionStore
        cognitoUserInfo = cognitoUserInfo || {}
        var username = cognitoUserInfo.username
        apiPOSTReq(`${environment.uamBaseUrl}/getUserInfo`, {}, { userId: username }, (err, resp) => {
            try {
                if (err) throw Error(err)
                const data = resp || {}
                if (data.result) {
                    var registeredUser = data.registeredUser || {}
                    dispatch(addDataToStore(UAM_SAVE_USERINFO, registeredUser))
                    fetchPermissions()
                        fetchIntegration()
                    callback(null, true)
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                callback(error.message)
            }
        })
    }

    function fetchPermissions() {
        // Fetch user permissions and adapt the layout based on the user's access
        getUserPermissions(null, (err, resp) => {
            try {
                if (err) throw Error(err)
                if (!resp.data.authToken) throw Error(resp.data.responseMessage)
                dispatch(addDataToStore(UAM_SAVE_PERMISSIONS, { UAM: resp.data }))
                fetchBankAccounts()
                message.success(`Successfully switched!`)
            } catch (error) {
                ErrorAlert({ description: error.message })
                // this.props.history.push('/home')
                setLoading(false)
            }
        })
    }

    function fetchBankAccounts() {
        const allowedApps = getUserApps(aionStore)
        
        if (allowedApps.includes("BusinessBanking")) {
            apiPOSTReq(`${environment.bbBaseUrl}/bb/getAccounts`, { "BankProvider": environment.bankProvider.crb }, null, (err, resp) => {
                try {
                    console.log("/bb/getAccounts", err, resp)
                    const data = resp
                    if (data.result) {
                        if (err) throw new Error(err)
                        var accounts = data.bbaccounts
                        dispatch(addDataToStore(BNK_SAVE_DATA, { BBAccounts: accounts }))
                        loadApp()
                    } else {
                        throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                    }
                } catch (error) {
                    console.log("ERRR getAccounts", error, err, resp)
                    loadApp()
                }
            })
        } else {
            loadApp()
        }
    }

    function loadApp() {
        dispatch(addDataToStore(SAVE_DATA, { switchBizInProgress: false, TwoFAValidated: true }))
        // Load tabs and menu based on user's access
        const userApps = getUserApps(aionStore)
        if (userApps.includes("BusinessBanking")) props.history.push('/banking/home')
        else if (userApps.includes("Receivables")) props.history.push('/receivables/customers')
        else if (userApps.includes("ARCredit")) props.history.push('/credit/ar-credit/advances')
        else if (userApps.includes("Payables")) props.history.push('/payables')
        else if (userApps.includes("Bookkeeping")) props.history.push('/bookkeeping/overview')
        else props.history.push('/settings/profile')
        props.switchComplete()
    }

    function fetchIntegration() {
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/hasIntgration`, {}, {}, (err, resp) => {
            console.log("clear fetchIntegration", resp)
            try {
                if (err) throw Error(err)
                const data = resp || {}
                if (data.result) {
                    dispatch(addDataToStore(SAVE_DATA, { hasIntegration: data.hasIntegration || false }))
                    if (!data.hasIntegration) {
                        return
                    }

                    apiPOSTReq(`${environment.integrationBaseUrl}/integration/getCurrentSyncSW`, {}, {}, (err, resp) => {
                        try {
                            if (err) throw Error(err)
                            const data = resp || {}
                            if (data.result) {
                                dispatch(addDataToStore(SAVE_DATA, { CurrentSync: data.integration }))
                                dispatch(addDataToStore(SAVE_DATA, { hideNotification: data.hideNotification }))
                            } else {
                                throw Error(data.responseMessage || data.error)
                            }
                        } catch (error) {
                            console.log("/getCurrentSyncSW err", error, resp)
                        }
                    })
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/getCurrentSyncSW err", error, resp)
            }
        })
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'businessName'
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <a onClick={() => switchToBusiness(record)}>{(BusinessUniqueKey !== record.businessId) ? "Select" : "Currently selected"}</a>
            )
        }
    ]
    return (
        <FlexColumn>
            <Text size='24px' weight={500}>Switch Business</Text>
            <Text margin="10px 0">Select a business to switch.</Text>
            {
                loading &&
                <Flex start centerHorizontally gap='8px' style={{ margin: '10px 0 20px' }}>
                    <LoadingOutlined style={{ color: theme.colors.primary, fontSize: "1.5rem" }} />
                    <Text color={theme.colors.primary2}>
                        Switching to {businessLoading.businessName}...
                    </Text>
                </Flex>
            }
            <Table
                rowKey='businessId'
                columns={columns}
                dataSource={linkedBusinessList}
                pagination={false}
                scroll={{ y: '700px', x: '100%' }}
            />
        </FlexColumn>
    )
}

function mapStateToProps(state) {
    return {
        bankingStore: state.bankingAppReducer,
        aionStore: state.aionAppReducer,
        userDefaults: state.userDefaultsReducer,
        bkStore: state.bookeepingAppReducer
    }
}

export default withRouter(connect(mapStateToProps)(withTheme(SwitchBusiness)))