import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import { message, Modal, Dropdown, Menu } from 'antd'
import { isEmail } from 'react-multi-email'

import { Text, Tag } from '../../Reusable/Text'
import { Divider } from '../../Reusable/Divider'
import { Flex, FlexColumn, CardContainer, LightContainer } from '../../Reusable/Container'
import PageHeader from '../../Reusable/PageHeader'
import FlowFooter from '../../Reusable/FlowFooter'
import SuccessModal from '../../Reusable/SuccessModal'
import ProgressSteps from '../../Reusable/ProgressSteps'
import { LabeledInput, GradientDivider } from '../../Reusable/Input'
import { ErrorAlert } from '../../Reusable/Alert'
import { Button, TextButton, ImageButton } from '../../Reusable/Button'
import { Image } from '../../Reusable/Image'
import { Accordion } from '../../Reusable/Review'
import { ApprovalGraph } from '../../Reusable/Approval'
import Cat from '../../../Images/cat.png'
import TransferIcon from '../../../Images/match-invoices.png'
import AddTransferModal from './AddTransferModal'
import IndividualRecipientAdded from '../../../Images/individual-recipient-added.png'
import BusinessRecipientAdded from '../../../Images/business-recipient-added.png'
import DeactivateTransferMethod from './DeactivateTransferMethod'

import { PlusOutlined, ArrowLeftOutlined, CheckOutlined } from '@ant-design/icons'
import { addDataToStore, SAVE_DATA } from '../../../Actions/actions'
import { fetchCounterparties } from '../../../Utils/bankingDataManager'
import { apiPOSTReq } from '../../../Utils/api'
import { getFeaturePerm } from '../../../Utils/util'
import environment from '../../../environment'
import ModalClose from '../../../Images/modal-close.png'
import Edit from '../../../Images/edit.png'
import Delete from '../../../Images/delete-red-icon.png'
import More from "../../../Images/more.png"
import ArrowRight from "../../../Images/arrow-right.png"
import Info from '../../../Images/exclamation-icon.png'
import _ from 'underscore'

class Index extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            pagination: {
                current: 1,
                pageSize: 1000,
                hideOnSinglePage: true
            },
            recipients: [],
            showTransferModal: false,
            transferMethods: [],
            step: 0,
            steps: ['Details', 'Transfer Methods'], // , 'Additional Details', 'Review'],
            type: 'Business',
            notificationEmails: [],
            detailsOpen: false,
            paymentMethodsOpen: false,
            additionalDetailsOpen: false,
            recipientEmailError: false,
            transferMethodRequests: [],
        }

        this.edit = this.props.location.state && this.props.location.state.recipient
    }

    componentDidMount() {
        this.getRules()
        if (this.props.location.state && this.props.location.state.recipient) {
            const recipient = this.props.location.state.recipient
            const additionalDetails = recipient.additionalDetails
            console.log("fetchCounterparties: componentDidMount", recipient)
            this.getPaymentMethods(recipient.objectId)
            this.setState({
                recipient,
                recipientId: recipient.objectId,
                edit: true,
                type: recipient.type,
                notificationEmails: recipient.notificationEmails || [],
                recipientEmail: recipient.email,
                steps: ['Details', 'Transfer Methods'], //, 'Additional Details', 'Review'],
                recipientName: recipient.nameOnAccount,
                firstName: recipient.type === 'Individual' && recipient.nameOnAccount.split(" ")[0],
                lastName: recipient.type === 'Individual' && recipient.nameOnAccount.split(" ")[1],
                nickName: recipient.nickName,
                linkOrWeb: additionalDetails && additionalDetails.find(ad => ad.context === 'What is the recipient’s website?') && additionalDetails.find(ad => ad.context === 'What is the recipient’s website?').contextValue,
                recipientDo: additionalDetails && additionalDetails.find(ad => ad.context === 'What does the recipient do?') && additionalDetails.find(ad => ad.context === 'What does the recipient do?').contextValue,
            })
        }
        const { pagination } = this.state
        fetchCounterparties({ pagination }, (err, resp) => {
            this.setState({ loading: false, recipients: resp && resp.counterpartiesList || [] })
        })
    }

    viewRecipient = (recipient) => {
        this.props.history.push({
            pathname: '/banking/recipients/details',
            state: { recipient }
        })
    }

    getPaymentMethods = (id) => {
        this.setState({ paymentMethodsLoading: true })
        apiPOSTReq(`${environment.bbBaseUrl}/bb/getPaymentMethods`, null, { objectId: id }, (err, resp) => {
            this.setState({ paymentMethodsLoading: false })
            try {
                const data = resp

                if (data.result) {
                    if (err) throw new Error(err)
                    console.log("bb/getPaymentMethods", data.paymentMethods)
                    this.setState({ transferMethods: data.paymentMethods, counterpartiesObj: data.counterpartiesObj, transferMethodRequests: data.paymentMethods })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getAccounts", error, err, resp)
            }
        })
    }

    createRecipient = () => {
        const { firstName, lastName, recipientName, recipientEmail, type, transferMethods, notificationEmails, counterpartiesObj,
            recipientDo, linkOrWeb, whyTransfer, recipientId, requiresApproval, recipient, transferMethodRequests, nickName } = this.state
        const { step, steps } = this.state
        const { store } = this.props
        const { UserInfo } = store || {}

        var body = {
            counterparty: {
                type: type,
                nameOnAccount: type === 'Business' ? recipientName : `${firstName} ${lastName}`,
                initiatedBy: `${UserInfo.FirstName} ${UserInfo.LastName}`,
                contextIdentifier: type === 'Business' ? recipientName : `${firstName} ${lastName}`,
                nickName: nickName,
            },
            BankProvider: environment.bankProvider.crb,
            ruleType: "Recipient",
        }

        if (recipientId) {
            if (counterpartiesObj) {
                body.counterparty = counterpartiesObj;
            }
            body.counterparty.objectId = recipientId;
            body.counterparty.nickName = nickName;
            body.counterparty.type = type;
        }

        body.counterparty.email = recipientEmail || ""
        if (notificationEmails) body.counterparty.notificationEmails = notificationEmails

        if (recipientDo || linkOrWeb) {
            var additionalDetails = []

            if (recipientDo) additionalDetails.push({ context: 'What does the recipient do?', contextValue: recipientDo })
            if (linkOrWeb) additionalDetails.push({ context: 'What is the recipient’s website?', contextValue: linkOrWeb })
            // if (whyTransfer) additionalDetails.push({ context: 'Why do you pay this recipient?', contextValue: whyTransfer })

            body.counterparty.additionalDetails = additionalDetails
        }

        console.log("bb/createCounterparty body: ", body)

        this.setState({ submitLoading: true })

        apiPOSTReq(`${environment.bbBaseUrl}/bb/createCounterparty`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            // this.setState({ submitLoading: false })
            try {
                // if (err) throw Error(err)
                const data = resp || {}
                if (data.result) {
                    console.log("bb/createCounterparty", data)
                    if (this.edit) {
                        message.success("Changes saved")
                        // window.history.back()

                        this.props.history.replace({
                            pathname: '/banking/recipients/details',
                            state: { recipient: data.counterpartiesObj }
                        })
                        /**
                         * One more goBack call is needed as history.replace does not remove .push from the history stack that got us to /banking/recipients/edit
                         * this.props.history.push({
                                pathname: '/banking/recipients/edit',
                                state: { recipient }
                            })
                         */
                        this.props.history.goBack()  
                    } else {
                        // if (step < steps.length - 1) {
                        //     this.setState({ step: step + 1, counterpartiesObj: data.counterpartiesObj, recipientId: data.counterpartiesObj.objectId, requiresApproval: data.counterpartiesObj.approvalInstanceId })
                        // } else {
                        //     this.setState({ showSuccessModal: true, counterpartiesObj: data.counterpartiesObj, recipientId: data.counterpartiesObj.objectId })
                        // }

                        if (data.counterpartiesObj.approvalInstanceId) this.props.onApprovalAction()

                        this.setState({ counterpartiesObj: data.counterpartiesObj, recipientId: data.counterpartiesObj.objectId, requiresApproval: data.counterpartiesObj.approvalInstanceId })

                        this.executeMultipleTransfers(transferMethodRequests);
                    }
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                this.setState({ submitLoading: false })
                console.log("/createCounterparty err", error, resp, body)
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    addTransfer = (body) => {
        return new Promise((resolve, reject) => {
            const { recipientId } = this.state

            body.recipientId = recipientId

            apiPOSTReq(`${environment.bbBaseUrl}/bb/addTransferDelivery`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
                try {
                    const data = resp || {};
                    if (data.result) {
                        resolve(data);  // Resolve the promise with data if successful
                    } else {
                        throw Error(data.responseMessage || data.error);
                    }
                } catch (error) {
                    reject(error);  // Reject the promise with error if something goes wrong
                }
            });
        });
    };

    executeMultipleTransfers = (transferMethodRequests) => {
        const transferPromises = transferMethodRequests.map(body => this.addTransfer(body));

        Promise.all(transferPromises)
            .then((results) => {
                // All API calls have been executed
                console.log("All transfers executed:", results);
                // this.props.onConfirm();
                this.setState({ showSuccessModal: true, submitLoading: false })
            })
            .catch((error) => {
                // Handle any error that occurred during any of the API calls
                console.log("Error in transfers:", error);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            });
    };

    formatAddr = (address) => {
        return {
            line1: address.line1,
            line2: address.line2 || null,
            city: address.city,
            countrySubDivisionCode: address.countrySubDivisionCode,
            state: address.countrySubDivisionCode,
            postalCode: address.postalCode
        }
    }

    next = () => {
        // const { step, steps, recipientEmail, edit } = this.state

        // if (step === 0 && !isEmail(recipientEmail)) {
        //     this.setState({ recipientEmailError: true })
        // } else {
        //     this.setState({ recipientEmailError: false })
        //     if (edit && step < steps.length - 1) {
        //         this.setState({ step: step + 1 })
        //     } else {
        //         this.createRecipient()
        //     }
        // }

        this.createRecipient()
    }

    isDisabled = () => {
        const { edit, type, firstName, lastName, recipientName, transferMethods, transferMethodRequests } = this.state

        if (edit) return false

        // switch (steps[step]) {
        //     case 'Details': return !type || (type === 'Individual' && (!firstName || !lastName)) || (type === 'Business' && !recipientName) || !recipientEmail || recipientEmail.length === 0
        //     case 'Transfer Methods': return !transferMethods || Object.keys(transferMethods).length === 0
        //     default: return false
        // }

        return !type || (type === 'Individual' && (!firstName || !lastName)) || (type === 'Business' && !recipientName) || transferMethodRequests.length === 0
    }

    getButtonTitle = () => {
        // if (this.state.edit === true) {
        //     return 'SAVE CHANGES'
        // } else {
        return 'SAVE' // this.state.step === this.state.steps.length - 1 ? 'SUBMIT' : 'NEXT'
        // }
    }

    getRules = () => {
        apiPOSTReq(`${environment.bbBaseUrl}/bb/approvals/getRules`, null, { "RuleTypes": ["Recipient"] }, (err, resp) => {
            try {
                const data = resp

                console.log("Approvals /bb/getRules", data)

                if (data.result) {
                    if (err) throw new Error(err)

                    const recipientRule = data.approvalRuleList.find(rule => rule.type === 'Recipient')
                    console.log("Approvals /bb/getRules recipientRule", recipientRule)

                    this.setState({ recipientRule })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getAccounts", error, err, resp)
            }
        })
    }

    render() {
        const { step, steps, loading, showDeactivateModal, recipientName, recipientEmail, type, showTransferModal, recipientId, requiresApproval, showApprovalModal,
            transferMethods, submitLoading, recipientDo, linkOrWeb, firstName, lastName, notificationEmails, paymentMethodsLoading, transferMethodRequests,
            detailsOpen, paymentMethodsOpen, additionalDetailsOpen, showSuccessModal, recipientEmailError, edit, deactivateTm, recipientRule, nickName } = this.state
        var { editTm } = this.state

        var disabled = this.isDisabled()
        var buttonTitle = this.getButtonTitle()

        var instantPaymentsEnabled = getFeaturePerm("BusinessBanking.Payments.Instant Transfer")

        return (
            <FlexColumn className='main-padding' grow fullWidth start>
                <PageHeader
                    close
                    subtitle='Banking'
                    titleText={`${edit ? 'Edit' : 'New'} Recipient`}
                    back
                />

                {/* <ProgressSteps edit={edit} key={`${step}ProgressSteps`} step={step} steps={steps} onClick={(step) => this.setState({ step })} /> */}

                {
                    steps[step] !== 'Review' ?
                        <CardContainer padding='0' margin='0 0 24px' style={{ width: '100%', height: '100%' }} shadow>
                            <FlexColumn between fullHeight>
                                <FlexColumn start style={{ padding: 24, height: '100%' }}>
                                    <FlexColumn left fullHeight between>
                                        {
                                            steps[step] === 'Details' && <FlexColumn fullWidth fullHeight start gap='24px'>
                                                <FlexColumn start>
                                                    <Text heading>Recipient Details</Text>
                                                    <LabeledInput
                                                        label='Type'
                                                        type={'switch'}
                                                        switchNames={['Business', 'Individual']}
                                                        onChange={(value) => this.setState({ type: value })}
                                                        value={type} />

                                                    {
                                                        edit &&
                                                        <LabeledInput
                                                            label="Name"
                                                            id="recipientName"
                                                            key="recipientName"
                                                            type={'read-only'}
                                                            placeholder="Enter recipient name"
                                                            width='580px'
                                                            value={recipientName && recipientName}
                                                        />
                                                    }


                                                    {
                                                        (!edit && type === 'Individual') &&
                                                        <Flex start width='568px' gap='12px'>
                                                            <LabeledInput
                                                                label="First Name"
                                                                id="firstName"
                                                                key="firstName"
                                                                type={edit && 'read-only'}
                                                                placeholder="Enter recipient first name"
                                                                value={firstName && firstName}
                                                                width='284px'
                                                                autoFocus
                                                                onChange={(event) => this.setState({ [event.target.id]: event.target.value })}
                                                            />

                                                            <LabeledInput
                                                                label="Last Name"
                                                                id="lastName"
                                                                key="lastName"
                                                                type={edit && 'read-only'}
                                                                placeholder="Enter recipient last name"
                                                                value={lastName && lastName}
                                                                width='284px'
                                                                onChange={(event) => this.setState({ [event.target.id]: event.target.value })}
                                                            />
                                                        </Flex>
                                                    }

                                                    {
                                                        (!edit && type === 'Business') &&
                                                        <LabeledInput
                                                            label="Name"
                                                            id="recipientName"
                                                            key="recipientName"
                                                            type={edit && 'read-only'}
                                                            placeholder="Enter recipient name"
                                                            width='580px'
                                                            value={recipientName && recipientName}
                                                            autoFocus
                                                            maxLength={50}
                                                            onChange={(event) => this.setState({ [event.target.id]: event.target.value })}
                                                        />
                                                    }

                                                    <LabeledInput
                                                        label="Nickname"
                                                        id="nickName"
                                                        key="nickName"
                                                        placeholder="Enter recipient nickname"
                                                        width='580px'
                                                        value={nickName && nickName}
                                                        maxLength={50}
                                                        autoFocus={_.isEmpty(edit)}
                                                        onChange={(event) => this.setState({ nickName: event.target.value })}
                                                        optional
                                                    />

                                                    <LabeledInput
                                                        label="Recipient Email"
                                                        id="recipientEmail"
                                                        key="recipientEmail"
                                                        // type={edit && 'read-only'}
                                                        width='580px'
                                                        value={recipientEmail}
                                                        onChange={(event) => this.setState({ [event.target.id]: event.target.value })}
                                                        placeholder="Enter recipient email"
                                                        error={recipientEmailError}
                                                        errorMessage='Please enter a valid email'
                                                        optional
                                                    />

                                                    {/* <LabeledInput
                                                    optional
                                                    label="Notifications to"
                                                    id="notificationEmails"
                                                    key="notificationEmails"
                                                    width='580px'
                                                    type="multi-email"
                                                    emails={notificationEmails && notificationEmails}
                                                    onChange={(emails) => this.setState({ notificationEmails: emails })}
                                                    validateEmail={email => {
                                                        return isEmail(email) // return boolean
                                                    }}
                                                    placeholder="Additional emails to notify"
                                                    onDelete={(index) => {
                                                        var emails = []
                                                        for (var i = 0; i < notificationEmails.length; i++) {
                                                            if (i != index) emails.push(notificationEmails[i])
                                                        }
                                                        this.setState({ notificationEmails: emails })
                                                    }}
                                                /> */}
                                                </FlexColumn>


                                                {
                                                    !edit &&
                                                    <FlexColumn start>
                                                        <Text heading>Bank Details</Text>
                                                        <Text width='800px' style={{ marginTop: 16 }}>{edit ? 'Edit' : 'Add'} bank details for this recipient. You can add multiple transfer<br />methods {`(i.e. ${instantPaymentsEnabled.manage ? 'Instant, ' : ''}ACH, Wire)`} for each of your recipients.</Text>

                                                        {
                                                            transferMethodRequests.length > 0 &&
                                                            <FlexColumn style={{ marginTop: 24 }} gap='8px'>
                                                                {
                                                                    transferMethodRequests.sort((a, b) => b.active - a.active).map((transferMethod, index) => {
                                                                        return <LightContainer width='580px' padding='12px 24px'>
                                                                            <Flex between fullWidth centerHorizontally>
                                                                                <Flex start centerHorizontally gap='8px'>
                                                                                    <Text>{transferMethod.nickName}</Text>
                                                                                    <Tag primary>{transferMethod.type}</Tag>
                                                                                    <Tag>{transferMethod.bankIcon && <img width={16} height={16} src={transferMethod.bankIcon} />} {transferMethod.bankDetail?.accountNumber?.substring(transferMethod.bankDetail?.accountNumber.length - 4, transferMethod.bankDetail.accountNumber.length)}</Tag>
                                                                                </Flex>

                                                                                
                                                                            </Flex>
                                                                        </LightContainer>
                                                                    })
                                                                }
                                                            </FlexColumn>
                                                        }

                                                        <Flex start style={{ marginTop: 24 }}>
                                                            <TextButton uppercase loading={paymentMethodsLoading} onClick={() => this.setState({ showTransferModal: true })} icon={<PlusOutlined style={{ color: '#1199FF' }} />} text='ADD BANK DETAILS' />
                                                        </Flex>
                                                    </FlexColumn>
                                                }
                                            </FlexColumn>
                                        }

                                        {
                                            steps[step] === 'Transfer Methods' && <>
                                                {
                                                    <FlexColumn start>
                                                        <Text width='800px'>{edit ? 'Edit transfer methods' : 'Add ACH or Wire details'} for this recipient. Recipients can have multiple transfer methods.</Text>

                                                        {
                                                            transferMethods &&
                                                            <FlexColumn style={{ marginTop: 24 }} gap='8px'>
                                                                {
                                                                    transferMethods.sort((a, b) => b.active - a.active).map((transferMethod, index) => {
                                                                        return <LightContainer fullWidth padding='12px 24px'>
                                                                            <Flex between fullWidth centerHorizontally>
                                                                                <Text>{transferMethod.nickName}</Text>

                                                                                <Flex end centerHorizontally gap='8px'>
                                                                                    <Tag primary bordered>{transferMethod.type}</Tag>
                                                                                    <Tag>{transferMethod.bankIcon && <img width={16} height={16} src={transferMethod.bankIcon} />} {transferMethod.bankDetail.accountNumber.substring(transferMethod.bankDetail.accountNumber.length - 4, transferMethod.bankDetail.accountNumber.length)}</Tag>
                                                                                    {
                                                                                        transferMethod.active ?
                                                                                            <>
                                                                                                <Dropdown
                                                                                                    placement='bottomRight'
                                                                                                    trigger={['click']}
                                                                                                    key={`${index}-Dropdown`}
                                                                                                    overlay={<Menu style={{ padding: 24, borderRadius: 8 }} key={`${index}-Menu`}>
                                                                                                        <FlexColumn gap='24px'>
                                                                                                            <TextButton color='#E52D2D' text='DEACTIVATE' onClick={() => {
                                                                                                                this.setState({ showDeactivateModal: true, deactivateTm: transferMethod, moreDropdownVisible: false })
                                                                                                            }} />
                                                                                                            <TextButton text='EDIT' rightIcon={<Image src={Edit} />} onClick={() => {
                                                                                                                this.setState({ showTransferModal: true, editTm: transferMethod, moreDropdownVisible: false })
                                                                                                            }} />
                                                                                                        </FlexColumn>
                                                                                                    </Menu>}
                                                                                                // onVisibleChange={flag => this.setState({ moreDropdownVisible: flag })}
                                                                                                // visible={moreDropdownVisible}
                                                                                                >
                                                                                                    <ImageButton src={More} />
                                                                                                </Dropdown>
                                                                                            </>
                                                                                            :
                                                                                            <Tag alert>Inactive</Tag>
                                                                                    }
                                                                                </Flex>
                                                                            </Flex>
                                                                        </LightContainer>
                                                                    })
                                                                }
                                                            </FlexColumn>
                                                        }

                                                        <Flex start style={{ marginTop: 24 }}>
                                                            <TextButton uppercase loading={paymentMethodsLoading} onClick={() => this.setState({ showTransferModal: true })} icon={<PlusOutlined style={{ color: '#1199FF' }} />} text='ADD TRANSFER METHOD' />
                                                        </Flex>
                                                    </FlexColumn>
                                                }
                                            </>
                                        }

                                        {
                                            steps[step] === 'Additional Details' &&
                                            <>
                                                <FlexColumn start>
                                                    <LabeledInput
                                                        optional
                                                        label="What does the recipient do?"
                                                        id="recipientDo"
                                                        key="recipientDo"
                                                        placeholder="Eg: Vendor who supplies sugar"
                                                        value={recipientDo}
                                                        onChange={this.handleOnChange}
                                                    />

                                                    <LabeledInput
                                                        optional
                                                        label="What is the recipient’s website?"
                                                        id="linkOrWeb"
                                                        key="linkOrWeb"
                                                        placeholder="Eg: LinkedIn, Website, Facebook page"
                                                        value={linkOrWeb}
                                                        onChange={this.handleOnChange}
                                                    />

                                                    {/* <LabeledInput
                                                                optional
                                                                label="Why do you pay this recipient?"
                                                                id="whyTransfer"
                                                                key="whyTransfer"
                                                                placeholder="Eg: Transfer for April 2022’s orders"
                                                                value={whyTransfer}
                                                                onChange={this.handleOnChange}
                                                            /> */}
                                                </FlexColumn>
                                            </>
                                        }
                                    </FlexColumn>

                                    {
                                        !edit && recipientRule &&
                                        <Flex start gap='8px' style={{ marginTop: 24 }}>
                                            {/* <Image src={Info} /> */}
                                            <Text>This recipient requires approval</Text>
                                            <TextButton text='View details' rightIcon={<Image src={ArrowRight} />} onClick={() => this.setState({ showApprovalModal: true })} />
                                        </Flex>
                                    }
                                </FlexColumn>

                                <FlowFooter
                                    disabled={disabled}
                                    loading={submitLoading}
                                    back={false}
                                    solid
                                    backClick={this.handleBack}
                                    buttonTitle={buttonTitle}
                                    onClick={this.next}
                                    cancel={edit}
                                    onCancel={() => window.history.back()}
                                />
                            </FlexColumn>
                        </CardContainer>
                        :
                        <FlexColumn start left gap='24px' fullWidth style={{ marginTop: 24 }}>
                            <CardContainer fullWidth>
                                <Accordion title='Details' open={detailsOpen} onToggle={() => this.setState({ detailsOpen: !detailsOpen })} onEdit={() => this.setState({ step: 0 })} />

                                {
                                    detailsOpen &&
                                    <>
                                        <Divider />
                                        <Flex start wrap gap='24px'>
                                            <LabeledInput
                                                type='read-only'
                                                label="Type"
                                                value={type}
                                                width='282px'
                                                nomargin
                                            />
                                            <LabeledInput
                                                type='read-only'
                                                label="Name"
                                                value={type === 'Business' ? recipientName : `${firstName} ${lastName}`}
                                                width='282px'
                                                nomargin
                                            />
                                            <LabeledInput
                                                type='read-only'
                                                label="Email"
                                                value={recipientEmail}
                                                width='282px'
                                                nomargin
                                            />
                                        </Flex>
                                    </>
                                }
                            </CardContainer>

                            <CardContainer fullWidth>
                                <Accordion title='Transfer Methods' open={paymentMethodsOpen} onToggle={() => this.setState({ paymentMethodsOpen: !paymentMethodsOpen })} onEdit={() => this.setState({ step: 1 })} />

                                {
                                    paymentMethodsOpen &&
                                    <>
                                        <Divider />
                                        <FlexColumn style={{ marginTop: 24 }} gap='8px'>
                                            {
                                                Object.values(transferMethods).map(transferMethod => {
                                                    return <LightContainer fullWidth padding='12px 24px'>
                                                        <Flex between fullWidth centerHorizontally>
                                                            <Text>{transferMethod.nickName}</Text>

                                                            <Flex end>
                                                                <Tag primary bordered>{transferMethod.type}</Tag>
                                                                <Tag style={{ marginLeft: 8 }}>{transferMethod.bankIcon && <img style={{ marginRight: 8 }} width={16} height={16} src={transferMethod.bankIcon} />} {transferMethod.bankDetail.accountNumber.substring(transferMethod.bankDetail.accountNumber.length - 4, transferMethod.bankDetail.accountNumber.length)}</Tag>
                                                            </Flex>
                                                        </Flex>
                                                    </LightContainer>
                                                })
                                            }
                                        </FlexColumn>
                                    </>
                                }
                            </CardContainer>

                            <CardContainer fullWidth>
                                <Accordion title='Additional Details' open={additionalDetailsOpen} onToggle={() => this.setState({ additionalDetailsOpen: !additionalDetailsOpen })} onEdit={() => this.setState({ step: 2 })} />

                                {
                                    additionalDetailsOpen &&
                                    <>
                                        <Divider />
                                        <FlexColumn start wrap gap='8px'>
                                            {
                                                notificationEmails && notificationEmails.length > 0 &&
                                                <LabeledInput type='read-only' label='Notification emails' value={notificationEmails.join(', ')} />
                                            }
                                            <LabeledInput
                                                type='read-only'
                                                label="What does the recipient do?"
                                                value={recipientDo}
                                                width='282px'
                                            />
                                            <LabeledInput
                                                type='read-only'
                                                label="What is the recipient’s website?"
                                                value={linkOrWeb}
                                                width='282px'
                                            />
                                            {/* <LabeledInput
                                                            type='read-only'
                                                            label="Why do you pay this recipient?"
                                                            value={whyTransfer}
                                                            width='282px'
                                                        /> */}
                                        </FlexColumn>
                                    </>
                                }
                            </CardContainer>

                            {/* {
                                            recipientRule &&
                                            <FlexColumn start>
                                                {
                                                    recipientRule && <>
                                                        <Divider />
                                                        <Text caption spaced weight='500'>{'Approvals'.toUpperCase()}</Text>
                                                        <ApprovalGraph rule={recipientRule} />
                                                    </>
                                                }
                                            </FlexColumn>
                                        } */}

                            <Button margin='0 0 24px' solid loading={submitLoading} onClick={this.next} text='SAVE' />
                        </FlexColumn>
                }

                <Modal
                    visible={showTransferModal}
                    width={616}
                    closable={true}
                    maskClosable={true}
                    style={{ borderRadius: '8px !important' }}
                    footer={null}
                    destroyOnClose={true}
                    onCancel={() => this.setState({ showTransferModal: false, transferType: null, editTm: null })}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <AddTransferModal
                        addTransfer={this.edit}
                        transferMethodRequests={transferMethodRequests}
                        transferMethod={editTm}
                        recipientId={recipientId}
                        recipientEmail={recipientEmail}
                        onConfirm={(body, transferMethod) => {
                            if (this.edit) {
                                this.setState({ showTransferModal: false, transferType: null, editTm: null }, () => this.getPaymentMethods(recipientId))
                            } else {
                                if (transferMethod) {
                                    let index = transferMethodRequests.findIndex(request => request.nickName === transferMethod.nickName)
                                    transferMethodRequests[index] = body
                                } else {
                                    transferMethodRequests.push(body)
                                }
                                this.setState({ transferMethodRequests, showTransferModal: false, transferType: null, editTm: null })
                            }
                        }}
                        onDiscard={() => this.setState({ showTransferModal: false, transferType: null, editTm: null })}
                        visible={showTransferModal}
                        msas={[]}
                    />
                </Modal>

                <Modal
                    visible={showSuccessModal}
                    footer={null}
                    closable={false}
                    width={456}
                    destroyOnClose={true}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <SuccessModal
                        title={(!this.edit && requiresApproval) ? 'Sent For Approval' : 'Recipient Added'}
                        description={`${type === 'Business' ? recipientName : `${firstName} ${lastName}`} has been ${requiresApproval ? 'sent for approval' : 'added successfully'}`}
                        buttonTitle='OKAY'
                        onConfirm={() => window.history.back()} />
                </Modal>


                <Modal
                    visible={showDeactivateModal}
                    footer={null}
                    closable={false}
                    width={456}
                    destroyOnClose={true}
                    onCancel={() => {
                        this.setState({ showDeactivateModal: false })
                    }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <DeactivateTransferMethod
                        transferMethod={deactivateTm}
                        onCancel={() => this.setState({ showDeactivateModal: false })}
                        onDeactivated={() => {
                            this.setState({ showDeactivateModal: false, loading: true })
                            this.getPaymentMethods(recipientId)
                        }} />
                </Modal>

                {
                    recipientRule &&
                    <Modal
                        visible={showApprovalModal}
                        footer={null}
                        closable={true}
                        maskClosable={true}
                        onCancel={() => this.setState({ showApprovalModal: false })}
                        width={616}
                        destroyOnClose={true}
                        centered
                        closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                    >
                        <FlexColumn start gap='0' style={{ marginBottom: 10 }}>
                            <Text heading>Recipient Approval</Text>
                            <Text>This recipient requires {recipientRule.levels.length} level{recipientRule.levels.length > 1 ? 's' : ''} of approval</Text>
                            <ApprovalGraph rule={recipientRule} />
                        </FlexColumn>
                    </Modal>
                }
            </FlexColumn>
        )
    }

    handleOnChange = (event) => {
        const target = event.target
        this.setState({ [target.id || target.name]: target.value })
    }

    twoFAValidated = () => {
        this.props.dispatch(addDataToStore(SAVE_DATA, { "TwoFAForTransfersValidated": true }))
        this.loadNext()
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer,
        bankingStore: state.bankingAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)))