import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toCurrency } from '../../../Utils/util'
import { Text } from '../../Reusable/Text'
import { Image } from '../../Reusable/Image'
import { TextButton } from '../../Reusable/Button'
import { Flex, FlexColumn, LightContainer, CardContainer } from '../../Reusable/Container'

// Images
import Back from '../../../Images/account-card-slim-vectors.png'
import ArrowRight from '../../../Images/arrow-right.png'
import hexToRgba from 'hex-to-rgba';
import { withTheme } from 'styled-components';

class AccountList extends Component {

    render() {
        const { accounts, selectedAccount, onSelectAccount, accountsPage, theme } = this.props

        const accountDetails = (selectedAccountIndex) => {
            this.props.history.push({
                pathname: '/banking/accounts',
                state: { selectedAccountIndex }
            })
        }

        return (
            <FlexColumn key={`AccountList-${selectedAccount.accountId}`} fullWidth>
                {
                    accounts && accounts.map((account, index) => {
                        var accountNickName = (account.nickName || `Business ${account.accountSubType}`).trim()
                        var nickName = accountNickName
                        if (nickName.length > 25) nickName = `${nickName.substr(0, 25).trim()}...`
                        return <> {
                            account === selectedAccount ?
                                <CardContainer key={`accountItem-${account ? account.accountId : index}`} padding='0' fullWidth height='94px' margin='0 0 8px' borderRight clickable>
                                    <Flex between fullWidth fullHeight>
                                        <div style={{ padding: '16px', height: '100%' }}>
                                            <FlexColumn centerVertically fullHeight>
                                                {
                                                    account.all ?
                                                        <Text weight='600' color='#1199FF' capitalize>All</Text>
                                                        :
                                                        <Text weight='600'
                                                            size='16px' 
                                                            capitalize
                                                            color={hexToRgba(theme.colors.primary2, 0.7)}
                                                            title={accountNickName}
                                                        >
                                                                {nickName} • {account.mask}
                                                        </Text>
                                                }

                                                {
                                                    selectedAccount.accountSubType !== 'Transitional' &&
                                                    <TextButton margin='8px 0 0' onClick={() => accountDetails((accountsPage * 4) + index)} rightIcon={<Image src={ArrowRight} />} text="VIEW DETAILS" />
                                                }
                                            </FlexColumn>
                                        </div>
                                        {/* <Flex end>
                                            <img style={{ position: 'absolute', zIndex: 5 }} height='94px' src={Back} />
                                        </Flex> */}
                                    </Flex>
                                </CardContainer>
                                :
                                <LightContainer key={`accountItem-${account ? account.accountId : index}`} height='40px' padding='8px 16px' fullWidth margin='0 0 8px' clickable onClick={() => onSelectAccount(account)}>
                                    <Flex between fullWidth>
                                        {
                                            account.all ?
                                                <Text size='14px' capitalize>All</Text>
                                                :
                                                <Text title={accountNickName} size='14px' color={hexToRgba(theme.colors.primary2, 0.7)} capitalize>{nickName} • {account.mask}</Text>
                                        }
                                        <Text size='14px' color='#999999'>${toCurrency(account.availableBalance)}</Text>
                                    </Flex>
                                </LightContainer>
                        }
                        </>
                    })
                }
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.bankingAppReducer,
        aionStore: state.aionAppReducer,
        userDefaults: state.userDefaultsReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(AccountList)))