export const flowViewsData = [
    {
        "ViewType": "WithoutImage",
        "AnimationName": "money-stack",
        "Title": "Request Funds",
        "Description": "Select eligible collateral",
        "FlowStep": "Advance.Start",
        "Progress": 0.0,
        "Step": "Business info",
        // "HideButton": true,
        "ButtonTitle": "Continue",
        "Width": 1000
    },
    {
        "ViewType": "WithoutImage",
        "FlowStep": "Advance.Amount",
        "AnimationAspectFill": false,
        "Title": "How much would you like to advance?",
        "Description": "Enter an amount",
        "Progress": 0.99,
        "ButtonTitle": "Continue"
    },
    {
        "ViewType": "WithoutImage",
        "FlowStep": "Advance.Schedule",
        "Title": "Payment schedule",
        "Description": "Your payments are split according to the schedule below. Payments are debited automatically.",
        "Progress": 0.99,
        "ButtonTitle": "Continue"
    },
    {
        "ViewType": "WithoutImage",
        "FlowStep": "Advance.Terms",
        "Title": "Loan Agreement",
        "Description": "Please read carefully and sign at the bottom of the page.",
        "Progress": 0.99,
        "ButtonTitle": "Submit"
    }
]

export const customContractFlowViewsData = [
    {
        "ViewType": "WithoutImage",
        "AnimationName": "money-stack",
        "Title": "Request Funds",
        "Description": "Select eligible collateral",
        "FlowStep": "Advance.Start",
        "Progress": 0.0,
        "Step": "Business info",
        // "HideButton": true,
        "ButtonTitle": "Continue",
        "Width": 1000
    },
    {
        "ViewType": "WithoutImage",
        "FlowStep": "Advance.Amount",
        "AnimationAspectFill": false,
        "Title": "How much would you like to advance?",
        "Description": "Enter an amount",
        "Progress": 0.99,
        "ButtonTitle": "Continue"
    },
    {
        "ViewType": "WithoutImage",
        "FlowStep": "Advance.Submit",
        "Title": "Payment schedule",
        "Description": "Your payments are split according to the schedule below. Payments are debited automatically.",
        "Progress": 0.99,
        "ButtonTitle": "Submit Request"
    }
]