import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components';
import update from 'immutability-helper';
import moment from 'moment';
import { saveAs } from 'file-saver';

import {
    Popconfirm,
    Space,
    message,
    Table,
    Popover,
    Tooltip,
    Switch,
    Select
} from 'antd';
import { InfoCircleOutlined, DeleteOutlined, } from '@ant-design/icons'

// Util
import environment from '../../../environment'
import { apiPOSTReq, getPreSignedS3Url } from '../../../Utils/api'

import {
    isEmailValid,
    addressObjectToStr,
    formatPhoneText,
    getUserApps,
    getResourcePerm,
    defaultPaymentTerms,
    capitalizeWords,
    getNameObj,
    getValidFullName,
    toCurrency,
} from '../../../Utils/util'

// Components
import PageHeader from "../../Reusable/PageHeader";
import { FlexColumn, Flex, Container } from '../../Reusable/Container';
import { Text, Tag } from '../../Reusable/Text';
import { Image } from '../../Reusable/Image'
import { LabeledInput } from '../../Reusable/Input';
import Banner from '../../Reusable/Banner'
import AddModalMSA from './AddModalMSA';
import AddModalWO from './AddModalWO';
import { Button, TextButton } from '../../Reusable/Refresh/Button';
import { ErrorAlert } from '../../Reusable/Alert';
import { addDataToStore, RECEIVABLES_SAVE_DATA } from '../../../Actions/actions';
import { cloneDeep } from 'lodash';
import Asterisk from '../../../Images/asterisk.png'

const { Option } = Select;

const WOStatusTag = (options) => {
    let statusMsg;
    let statusColor = "blue";
    switch (options.status) {
        case "VALIDATED":
            statusColor = "green";
            statusMsg = "Approved"
            break
        case "REJECTED":
            statusColor = "red";
            statusMsg = "Rejected"
            break
        default:
            statusMsg = "Verifying"
            break
    }
    return (<Tag primary>{statusMsg}</Tag>);
}

class EditCustomer extends Component {

    constructor(props) {
        super(props);

        let customer = props.store.editedCustomer || {};
        let isNew = props.store.editedCustomer === null ? true : false;
        let actionStr = props.store.editedCustomer ? 'Edit' : 'Create';
        customer.primaryEmailAddress = customer.primaryEmailAddress || { address: null };
        customer.invoiceRecipientEmail = customer.invoiceRecipientEmail || [];
        this.state = {
            customize: true,
            customer: customer,
            msas: null,
            workOrders: null,
            showBanner: false,
            showAddModalMSA: false,
            showAddModalWO: false,
            paymentTermsItems: defaultPaymentTerms,
            actionStr,
            isNew,
            loading: false,
        }
        this.iveHeaders = {
            AionCurrentBiz: this.props.aionStore.BusinessUniqueKey,
            AionAuth: this.props.aionStore.UAM.encryptedAuthHeader
        }
        // this.invInfoCompleted = true;
    }

    componentDidMount() {
        console.log("EditCustomer customer", this.state.customer)
        if (!this.state.isNew) {
            this.getMSAForCustomer();
            this.getWOsForCustomer();
            this.fetchTerms();
        }

        this.fetchCustomers({
            pagination: {
                current: 1,
                pageSize: 2000,
            }
        })
    }

    fetchTerms() {
        apiPOSTReq(`${environment.iveBaseUrl}/ive/term/getTerms`, {}, {}, (err, resp) => {
            try {
                const data = resp || {};
                console.log("/ive/term/getTerms", data)
                if (data.result) {
                    const terms = data.termList && data.termList.length > 0 ? data.termList : defaultPaymentTerms;
                    this.handlePaymentTermsSelect(this.state.customer.termId);
                    this.setState({ paymentTermsItems: terms })
                } else {
                    this.setState({ paymentTermsItems: defaultPaymentTerms })
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                this.setState({ paymentTermsItems: defaultPaymentTerms })
                console.log("ERRR getTerms", error, resp);
                ErrorAlert({ description: error.message })
            }
        })
    }

    updateBanner = () => {
        const { customer } = this.state;
        this.invInfoCompleted = ((customer.invoiceRecipientEmail || []).length > 0) && (customer.primaryEmailAddress.address && customer.phone)
        this.setState({
            showBanner: !(this.state.msas && (this.state.workOrders && this.state.workOrders.length > 0) && ((customer.invoiceRecipientEmail || []).length > 0) && (customer.primaryEmailAddress.address && customer.phone))
        });
    }

    getMSAForCustomer = () => {
        const body = {
            "BusinessId": this.props.aionStore.BusinessUniqueKey,
            "CustomerId": this.state.customer.customerId,
        }
        console.log("getMSAForCustomer", body);
        apiPOSTReq(`${environment.iveBaseUrl}/ive/msa/getMSAForCustomer`, this.iveHeaders, body, (err, resp) => {
            try {
                const data = resp;
                if (data.result) {
                    console.log("getMSAForCustomer data", data);
                    this.setState({
                        msas: data.msas
                    })
                    this.updateBanner();
                } else {
                    throw Error("Could not getActiveMSAs.")
                }
            } catch (error) {
                console.log("ERRR", error.stack)
                //message.error(`Error adding MSA `);
            }
        })
    }

    getWOsForCustomer = () => {
        const body = {
            "BusinessId": this.props.aionStore.BusinessUniqueKey,
            "CustomerId": this.state.customer.customerId,
        }
        apiPOSTReq(`${environment.iveBaseUrl}/ive/po/getPOsForCustomer`, this.iveHeaders, body, (err, resp) => {
            try {
                const data = resp;
                if (data.result) {
                    console.log("POSs", data)
                    this.setState({
                        workOrders: data.pos
                    })
                    this.updateBanner();
                } else {
                    throw Error("Could not fetch work orders.")
                }
            } catch (error) {
                console.log("ERRR", error.stack, err, resp)
                message.error(`Could not fetch work orders.`);
            }
        })
    }

    /* Details */

    handleEmailChange = (event) => {
        this.setState({
            customer: update(this.state.customer, { invoiceRecipientEmail: { [event.target.getAttribute('index')]: { $set: event.target.value } } })
        })
    }

    handlePhoneChange = (event) => {
        this.setState({
            customer: update(this.state.customer, { phone: { $set: formatPhoneText(event.target.value) } })
        })
    }

    handleAddEmail = () => {
        this.setState({
            customer: update(this.state.customer, { invoiceRecipientEmail: { $push: [null] } })
        });
    }

    handleDeleteEmail = (event) => {
        this.setState({
            customer: update(this.state.customer, { invoiceRecipientEmail: { $splice: [[[event.currentTarget.getAttribute('index')], 1]] } })
        });
    }

    handleShipAddrSwitch = (checked) => {
        this.setState({ makeShipAddrAsBillAddr: checked });
        var stateToUpdate = {
            makeShipAddrAsBillAddr: checked
        };
        if (checked) {
            stateToUpdate.customer = update(this.state.customer, { "shippingAddress": { $set: this.state.customer.billingAddress } });
        } else {
            stateToUpdate.customer = update(this.state.customer, {
                "shippingAddress": {
                    $set: {
                        line1: null,
                        line2: null,
                        city: null,
                        countrySubDivisionCode: null,
                        postalCode: null
                    }
                }
            });
        }
        this.setState(stateToUpdate);
    }

    handlePaymentTermsSelect = (value, option) => {
        var { customer } = this.state;

        const term = this.state.paymentTermsItems.find(x => x.termId === value);
        if (term) {
            customer.paymentTerms = term.name;
            customer.termId = term.termId;
        } else {
            customer.paymentTerms = value;
            customer.termId = value;
        }

        this.setState({ customer });
    }

    handleTextChange = (event) => {
        var val;
        switch (event.target.id) {
            case "name":
                var name = capitalizeWords(event.target.value);
                var nameObj = getNameObj(getValidFullName(name));
                val = { firstName: { $set: nameObj.FirstName }, lastName: { $set: nameObj.LastName } }
                break;
            case "companyName":
                var captitalizedName = { $set: (event.target.value) };
                val = { [event.target.id]: captitalizedName, displayName: captitalizedName, printOnCheckName: captitalizedName };
                break;
            case "primaryEmailAddress":
                val = { [event.target.id]: { address: { $set: event.target.value } } };
                break;
            case "phone":
                val = { phone: { $set: formatPhoneText(event.target.value) } };
                break;
            case "webSite":
                val = { website: { $set: (event.target.value) } };
                break;
            default:
                val = { [event.target.id]: { $set: event.target.value } };
                break;
        }
        console.log("handleTextChange update", this.state.customer, val);
        this.setState({
            customer: update(this.state.customer, val)
        });
    }

    handleParentCustomerSelect = (value, option) => {
        this.setState({
            customer: update(this.state.customer, { parentCustomerId: { $set: value }, parentCustomerName: { $set: option.name } })
        });
    }

    getLocation = (location, addressField) => {
        if (!location) {
            if (addressField === 'billingAddress') {
                this.setState({
                    customer: update(this.state.customer, {
                        billingAddress: {
                            $set: {
                                line1: null,
                                line2: null,
                                city: null,
                                countrySubDivisionCode: null,
                                postalCode: null
                            }
                        }
                    })
                });
            } else {
                this.setState({
                    customer: update(this.state.customer, {
                        shippingAddress: {
                            $set: {
                                line1: null,
                                line2: null,
                                city: null,
                                countrySubDivisionCode: null,
                                postalCode: null
                            }
                        }
                    })
                });
            }
        }
        else {
            if (addressField === 'billingAddress') {
                this.setState({
                    customer: update(this.state.customer, {
                        billingAddress: {
                            $set: {
                                line1: location.line1,
                                line2: location.line2,
                                city: location.city,
                                countrySubDivisionCode: location.countrySubDivisionCode,
                                postalCode: location.postalCode
                            }
                        }
                    })
                });
            } else {
                this.setState({
                    customer: update(this.state.customer, {
                        shippingAddress: {
                            $set: {
                                line1: location.line1,
                                line2: location.line2,
                                city: location.city,
                                countrySubDivisionCode: location.countrySubDivisionCode,
                                postalCode: location.postalCode
                            }
                        }
                    })
                });
            }
        }
    }

    handleSave = () => {
        const { billingAddress, shippingAddress, fullyQualifiedName, paymentTerms } = this.state.customer
        const customer = this.state.customer;
        if (customer.website === 'https://') {
            customer.website = "";
        }

        const displayName = customer.displayName;
        if (!displayName || displayName == '') {
            ErrorAlert({ description: "Customer name is required" });
            return;
        };

        if (!fullyQualifiedName || fullyQualifiedName === '') {
            ErrorAlert({ description: "Contact name is required" });
            return;
        };

        const phone = customer.phone || "";
        const primaryEmailAddress = customer.primaryEmailAddress || {};
        const otherEmails = customer.invoiceRecipientEmail || [];
        if (!isEmailValid(primaryEmailAddress.address)) {
            ErrorAlert({ description: "Please enter a valid Contact email" });
            return;
        };
        if (phone.replace(/\D/g, '').length !== 10) {
            ErrorAlert({ description: "Please enter a valid 10 digit US phone number" });
            return;
        };
        if (otherEmails.length === 0) {
            ErrorAlert({ description: "Please enter a Invoice recipient email" });
            return;
        }
        var otherEmailsValidated = true;
        var invalidEmail = "";
        otherEmails.map(item => {
            if (!isEmailValid(item || "")) {
                otherEmailsValidated = false
                invalidEmail = item;
            };
        })
        if (!otherEmailsValidated) {
            ErrorAlert({ description: `${invalidEmail} is an invalid email, please update it.` });
            return;
        };
        if (!paymentTerms) {
            ErrorAlert({ description: 'Payment Terms is a required field' });
            return;
        }
        if (!billingAddress) {
            ErrorAlert({ description: 'Billing Address is a required field' });
            return;
        } else {
            const { line1, city, countrySubDivisionCode, state, postalCode } = billingAddress
            if (!line1) {
                ErrorAlert({ description: 'Billing address street is a required field' });
                return;
            }
            if (!city) {
                ErrorAlert({ description: 'Billing address city is a required field' });
                return;
            }
            if (!countrySubDivisionCode && !state) {
                ErrorAlert({ description: 'Billing address state is a required field' });
                return;
            }
            if (!postalCode) {
                ErrorAlert({ description: 'Billing address postal code is a required field' });
                return;
            }
        }
        if (!shippingAddress) {
            ErrorAlert({ description: 'Shipping Address is a required field' });
            return;
        } else {
            const { line1, city, countrySubDivisionCode, state, postalCode } = shippingAddress
            if (!line1) {
                ErrorAlert({ description: 'Shipping address street is a required field' });
                return;
            }
            if (!city) {
                ErrorAlert({ description: 'Shipping address city is a required field' });
                return;
            }
            if (!countrySubDivisionCode && !state) {
                ErrorAlert({ description: 'Shipping address state is a required field' });
                return;
            }
            if (!postalCode) {
                ErrorAlert({ description: 'Shipping address postal code is a required field' });
                return;
            }
        }
        const body = {
            Customer: this.state.customer
        }

        console.log("nextStep customer:", JSON.stringify(body))

        this.setState({ loading: true })

        apiPOSTReq(`${environment.iveBaseUrl}/ive/bc/save`, this.iveHeaders, body, (err, resp) => {
            try {
                const data = resp;
                if (data.result) {
                    message.success(`Details saved.`);
                    this.setState({ isNew: false, actionStr: 'Edit', customer: data.customer })
                    this.props.dispatch(addDataToStore(RECEIVABLES_SAVE_DATA, { editedCustomer: cloneDeep(data.customer) }));
                    this.updateBanner();
                } else {
                    message.error("Could not Save. " + data.responseMessage);
                }
            } catch (error) {
                console.log("ERRR", error.stack, err, resp)
                message.error("Could not Save.");
            } finally {
                this.setState({ loading: false })
            }
        })
    }

    /* MSA */

    handleShowMSAModal = () => {
        this.setState({ showAddModalMSA: true });
    }

    handleMSAAdded = () => {
        this.getMSAForCustomer();
        this.setState({ showAddModalMSA: false });
    }

    handleDeleteMSA = (msaId) => {
        const body = {
            "BusinessId": this.props.aionStore.BusinessUniqueKey,
            "CustomerId": this.state.customer.customerId,
            "msaId": msaId,
        }
        apiPOSTReq(`${environment.iveBaseUrl}/ive/msa/delete`, this.iveHeaders, body, (err, resp) => {
            try {
                const data = resp;
                if (data.result) {
                    this.getMSAForCustomer();
                } else {
                    console.log(data);
                    if (data.responseMessage) {
                        message.error(data.responseMessage);
                    }
                    else {
                        throw Error("Could not delete MSA.")
                    }
                }
            } catch (error) {
                console.log("ERRR", error.stack)
                message.error("Could not delete MSA.");
            }
        })
    }

    handleCancelMSA = () => {
        this.setState({ showAddModalMSA: false });
    }

    /* Work Order */

    handleShowWOModal = () => {
        this.setState({ showAddModalWO: true });
    }

    handleWOAdded = () => {
        this.getWOsForCustomer();
        this.setState({ showAddModalWO: false });
    }

    handleDeleteWO = (PONumber) => {
        const body = {
            "BusinessId": this.props.aionStore.BusinessUniqueKey,
            "CustomerId": this.state.customer.customerId,
            "PONumber": PONumber
        }
        console.log("handleDeleteWO", body)
        apiPOSTReq(`${environment.iveBaseUrl}/ive/po/delete`, this.iveHeaders, body, (err, resp) => {
            try {
                const data = resp;
                if (data.result) {
                    this.getWOsForCustomer();
                } else {
                    throw Error("Could not delete Work Order.")
                }
            } catch (error) {
                console.log("ERRR", error, err, resp.error)
                message.error("Could not delete Work Order.");
            }
        })
    }

    handleCancelWO = () => {
        this.setState({ showAddModalWO: false });
    }

    onCustomerMarkInactive = (customer) => {
        const headers = {
            AionCurrentBiz: this.props.aionStore.BusinessUniqueKey,
            AionAuth: this.props.aionStore.UAM.encryptedAuthHeader // This will be replaced with the token received from the access mgmt service
        }
        const body = {
            "BusinessId": this.props.aionStore.BusinessUniqueKey,
            "CustomerId": customer.customerId
        }
        apiPOSTReq(`${environment.iveBaseUrl}/ive/bc/deactivate`, headers, body, (err, resp) => {
            try {
                const data = resp;
                if (data.result) {
                    message.success(`${customer.displayName} was marked as inactive.`);
                    this.props.history.push('/receivables/customers');
                } else {
                    ErrorAlert({ description: `Failed to deactivate this customer. ${data.responseMessage}` })
                }
            } catch (error) {
                message.error(`Error marking inactive`);
                console.log("ERRR", error, err, resp)
            }
        })
    }

    downloadAttachment = (url, name) => {
        getPreSignedS3Url({ url: url }, (err, preSignedUrl) => {
            if (!err) {
                saveAs(preSignedUrl, name)
            } else {
                ErrorAlert({ description: JSON.stringify(err) })
            }
        })
    }

    fetchCustomers(options) {
        const { pagination } = options
        // Fetch customer list
        const body = {
            "size": pagination.pageSize,
            "page": pagination.current - 1
        }
        this.setState({ loadingCustomers: true })
        apiPOSTReq(`${environment.iveBaseUrl}/ive/bc/getActiveCustomers`, {}, body, (err, resp) => {
            try {
                this.setState({ loadingCustomers: false })
                const data = resp
                console.log("/getActiveCustomers", data)
                console.log("CreateCustomer nextStep customer:", data)
                if (data.result) {
                    this.setState({ activeCustomers: data.customers })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getActiveCustomers", error.stack, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    render() {
        const { theme } = this.props;
        var { customer, loading, isNew, activeCustomers, loadingCustomers } = this.state;
        const bannerMessage = `You will be able to upload your contract documents and work orders after saving the Invoicing Info below. Submit invoices ${customer.id ? `for ${customer.displayName}` : ''} once you have updated the below information.`

        const userApps = getUserApps(this.props.aionStore);
        const { CurrentSync } = this.props.aionStore;
        let qbo = false;
        if (CurrentSync && CurrentSync.platformName === 'QUICKBOOKS' && CurrentSync.syncSetupCompleted && userApps && userApps.includes("Receivables")) {
            qbo = true;
        }

        var { companyName, displayName, webSite, firstName, lastName, notes, fullyQualifiedName, billingAddress, shippingAddress, phone, paymentTerms, parentCustomerName, billWithParent, termId } = this.state.customer;
        shippingAddress = shippingAddress || {};
        displayName = displayName || companyName;

        activeCustomers = (activeCustomers || []).filter(x => x.displayName != displayName || x.companyName != displayName);

        if (webSite) {
            if (webSite.substring(0, 8) !== 'https://' && webSite.substring(0, 7) !== 'http://') {
                webSite = "https://" + webSite;
            }
        } else {
            webSite = "https://";
        }

        const msaColumns = [
            {
                title: 'Document',
                dataIndex: 'masterServiceAgreementId',
                key: 'masterServiceAgreementId',
                render: (masterServiceAgreementId, msa) => {
                    let uri = msa.documentUrl ? msa.documentUrl.uri : ""
                    return <div style={{ cursor: 'pointer' }} onClick={() => this.downloadAttachment(uri, masterServiceAgreementId)}>{msa.documentUrl.fileName}</div>
                }
            },
            {
                title: 'Valid From',
                dataIndex: 'validFrom',
                render: validFrom => validFrom && (moment(validFrom).format('ll')),
            },
            {
                title: 'Valid Through',
                dataIndex: 'validThrough',
                render: validThrough => validThrough && (moment(validThrough).format('ll')),
            },
            {
                title: 'Contract Value',
                dataIndex: 'contractValue',
                render: contractValue => contractValue && "$" + toCurrency(contractValue, 0, 2)
            },
            {
                title: 'Action',
                key: 'action',
                width: 150,
                render: (text, item) => (
                    !getResourcePerm("Support.Console").aionSystemUser && <Popconfirm title="Are you sure you want to delete this MSA?" onConfirm={() => this.handleDeleteMSA(item.masterServiceAgreementId)}>
                        <DeleteOutlined style={{ cursor: 'pointer' }} />
                    </Popconfirm>
                ),
            }
        ]

        const poColumns = [
            {
                title: 'Document',
                dataIndex: 'ponumber',
                key: 'ponumber',
                render: (ponumber, wo) => {
                    let uri = wo.documentUrl ? wo.documentUrl.uri : ""
                    return <div style={{ cursor: 'pointer' }} onClick={() => this.downloadAttachment(uri, ponumber)}>#{ponumber}</div>
                }
            },
            {
                title: 'Valid From',
                dataIndex: 'validFrom',
                render: validFrom => validFrom && (moment(validFrom).format('ll')),
            },
            {
                title: 'Valid Through',
                dataIndex: 'validThrough',
                render: validThrough => validThrough && (moment(validThrough).format('ll')),
            },
            {
                title: 'Total Value',
                dataIndex: 'totalValue',
                key: 'totalValue',
                render: totalValue => totalValue && "$" + toCurrency(totalValue, 0, 2)
            },
            {
                title: 'Status',
                dataIndex: 'verificationStatus',
                key: 'verificationStatus',
                render: verificationStatus => (<WOStatusTag status={verificationStatus} />)
            },
            {
                title: 'MSA',
                dataIndex: 'msaname',
                key: 'msaname',
                render: msaname => msaname
            },
            {
                title: 'Action',
                key: 'action',
                width: 150,
                render: (text, item) => (
                    !getResourcePerm("Support.Console").aionSystemUser && <Space size="middle">
                        <Popconfirm title="Are you sure you want to delete this Work Order?" onConfirm={() => this.handleDeleteWO(item.ponumber)}>
                            <a><DeleteOutlined style={{ cursor: 'pointer', fontSize: 18 }} /></a>
                        </Popconfirm>
                    </Space>
                ),
            }
        ];
        return (
            <>
                <FlexColumn className='main-padding' style={{ width: '100%' }} start>
                    <PageHeader
                        back
                        backText='Customers'
                        titleText={isNew ? 'New Customer' : (customer.displayName && customer.displayName !== '') ? customer.displayName : 'New Customer'}
                        ctaButton={!this.state.showBanner ? <Button permtype="Override" style={{ height: 45 }}
                            onClick={() => this.props.history.push('/receivables/customers')}
                            text="Done"
                        /> : null} />

                    <Container shadow style={{ marginBottom: 48 }}>
                        {/* <FlexColumn between >
                            <Text heading size='28px'>{this.state.actionStr || "Edit"} Details</Text>
                            <Text lightText>{this.state.actionStr || "Edit"} the invoicing info, MSA and Work Orders here.</Text>
                        </FlexColumn> */}
                        {
                            !customer.id &&
                            <Banner style={{ marginBottom: 24 }} title="Important" message={bannerMessage} />
                        }
                        <FlexColumn>
                            <FlexColumn start style={{ minWidth: '900px', paddingTop: 0 }}>
                                <Text heading>Step 1: Invoicing Info</Text>
                                <Flex between>
                                    <div style={{ width: "50%", marginTop: 6, paddingRight: 12 }}>
                                        <LabeledInput
                                            style={{ width: "100%" }}
                                            autoFocus
                                            label='Name'
                                            id="companyName"
                                            key="companyName"
                                            placeholder="Acme Inc."
                                            value={this.state.customer.displayName}
                                            onChange={this.handleTextChange}
                                        />
                                    </div>
                                    <div style={{ width: "50%", paddingLeft: 12 }}>
                                        <LabeledInput
                                            customlabelcomponent={
                                                <Flex between centerHorizontally style={{ marginBottom: 8, width: '100%' }}>
                                                    <Flex start centerHorizontally>
                                                        <Text color={'#7384AA'} size='14px' weight={400}>Parent Company</Text>
                                                        <span style={{ margin: '0 8px', fontSize: "0.9rem", color: theme.colors.secondary7 }}>Bill with parent?</span>
                                                        <Switch size="small" defaultChecked={this.state.customer.billWithParent} onChange={(checked) => this.setState({ customer: update(customer, { billWithParent: { $set: checked } }) })} />
                                                    </Flex>
                                                    <Text color={'#7384AA'} size='12px' weight={400}>optional</Text>
                                                </Flex>
                                            }
                                            type="select"
                                            className="no-left-padding"
                                            showSearch
                                            key="parentCustomer"
                                            placeholder="Select parent company"
                                            optionFilterProp="children"
                                            value={parentCustomerName}
                                            loading={loadingCustomers}
                                            onChange={this.handleParentCustomerSelect}
                                            filterOption={(input, option) => {
                                                return option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }}
                                        >
                                            {activeCustomers.map(item => <Option key={item.customerId} id="selectedParentCustomer" name={item.displayName || item.companyName} value={item.customerId} style={{ backgroundColor: "transparent" }}>{item.displayName || item.companyName}</Option>)}
                                        </LabeledInput>
                                    </div>
                                </Flex>
                                <Flex between>
                                    <div style={{ width: "33%", marginRight: 12 }}>
                                        <LabeledInput
                                            label="Contact Name"
                                            id="fullyQualifiedName"
                                            key="fullyQualifiedName"
                                            placeholder="Tony Adams"
                                            defaultValue={fullyQualifiedName}
                                            onChange={this.handleTextChange}
                                        />
                                    </div>
                                    <div style={{ width: "33%", marginLeft: 12, marginRight: 12 }}>
                                        <LabeledInput
                                            label="Contact Email"
                                            type="email"
                                            id="primaryEmailAddress"
                                            key="primaryEmailAddress"
                                            value={customer.primaryEmailAddress && customer.primaryEmailAddress.address}
                                            onChange={this.handleTextChange}
                                            placeholder="Email"
                                        />
                                    </div>
                                    <div style={{ width: "33%", marginLeft: 12 }}>
                                        <LabeledInput
                                            type="phone"
                                            label="Phone"
                                            value={phone}
                                            maxLength={12}
                                            onChange={this.handlePhoneChange}
                                            placeholder="Phone"
                                        />
                                    </div>

                                </Flex>
                                <Flex between>
                                    <div style={{ margin: "15px 0 0", width: "33%", marginRight: 12 }}>
                                        <Tooltip title="Email of the recipient(s) that will receive invoices submitted for this customer">
                                            <Flex start style={{ marginBottom: 8 }}>
                                                <Text color={'#7384AA'} size='14px' weight={400} margin='0 8px 0 0'>Invoice Recipient Email</Text>
                                                <InfoCircleOutlined style={{ color: '#7384AA' }} />
                                                <Image width='5px' height='5px' margin='4px 0 0 4px' src={Asterisk} />
                                            </Flex>
                                        </Tooltip>
                                        <div >
                                            {
                                                customer.invoiceRecipientEmail && customer.invoiceRecipientEmail.map((email, index) => {
                                                    return (
                                                        <div key={index} style={{ margin: "-15px 0 0", width: '100%' }}>
                                                            <Flex start centerHorizontally style={{ width: '100%' }}>
                                                                <LabeledInput
                                                                    index={index}
                                                                    type="email"
                                                                    value={email}
                                                                    onChange={this.handleEmailChange}
                                                                    placeholder="Email"
                                                                    id="invoiceEmailAddress"
                                                                    key={"invoiceEmailAddress" + index}
                                                                />

                                                                <span index={index} onClick={this.handleDeleteEmail} style={{ marginLeft: 12 }}>
                                                                    <DeleteOutlined style={{ cursor: 'pointer', color: theme.colors.primary }} />
                                                                </span>
                                                            </Flex>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <TextButton onClick={this.handleAddEmail} text='Add Email' />
                                        </div>
                                    </div>

                                    <div style={{ width: "33%", marginLeft: 12, marginRight: 12 }}>
                                        <LabeledInput
                                            label="Payment Terms"
                                            id="paymentTerms"
                                            key="paymentTerms"
                                            type="select"
                                            className="no-left-padding"
                                            placeholder="Select"
                                            value={termId}
                                            onChange={this.handlePaymentTermsSelect}
                                            // style={labelStyle}
                                            dropdownRender={menu => (
                                                <>
                                                    {menu}
                                                </>
                                            )}
                                        >
                                            {this.state.paymentTermsItems.map(item => <Option key={item.termId || item} value={item.termId || item}>{item.name || item}</Option>)}
                                        </LabeledInput>
                                    </div>

                                    <div style={{ width: "33%", marginLeft: 12 }}>
                                        <LabeledInput
                                            label="Website"
                                            id="webSite"
                                            key="webSite"
                                            placeholder="acme.com"
                                            defaultValue={webSite}
                                            onChange={this.handleTextChange}
                                            optional
                                        />
                                    </div>
                                </Flex>

                                <Flex between>
                                    <div style={{ width: '50%', marginRight: 12 }}>
                                        <LabeledInput
                                            id="billingAddress"
                                            key="billingAddress"
                                            label="Billing Address"
                                            type="location"
                                            getLocation={(loc) => this.getLocation(loc, 'billingAddress')}
                                            Line2
                                            address={billingAddress}
                                            value={billingAddress && billingAddress.line1 ?
                                                { line1: addressObjectToStr(billingAddress), line2: billingAddress.line2 } : null
                                            }
                                            placeholder="Address"
                                        />
                                    </div>
                                    <div style={{ width: '50%', margin: '15px 0 12px 15px' }}>
                                        <LabeledInput
                                            customlabelcomponent={
                                                <Flex start centerHorizontally style={{ marginBottom: 8, width: '100%' }}>
                                                    <Text color={'#7384AA'} size='14px' weight={400}>Shipping Address</Text>
                                                    <span style={{ margin: '0 8px', fontSize: "0.9rem", color: theme.colors.secondary7 }}>Same as billing?</span>
                                                    <Switch size="small" defaultChecked={this.state.makeShipAddrAsBillAddr} onChange={this.handleShipAddrSwitch} />
                                                </Flex>
                                            }
                                            id="shippingAddress"
                                            key={"shippingAddress" + this.state.makeShipAddrAsBillAddr}
                                            nomargin="nomargin"
                                            type="location"
                                            getLocation={(loc) => this.getLocation(loc, 'shippingAddress')}
                                            Line2
                                            address={shippingAddress}
                                            value={shippingAddress && shippingAddress.line1 ?
                                                { line1: addressObjectToStr(shippingAddress), line2: shippingAddress.line2 } : null
                                            }
                                            placeholder="Address"
                                        />
                                    </div>
                                </Flex>
                                <Flex start style={{ width: '100%' }}>
                                    {!getResourcePerm("Support.Console").aionSystemUser && <Button loading={loading} onClick={this.handleSave} margin='24px 0 48px' solid width='170px' text='SAVE' height='40px' radius='4px' />}
                                </Flex>
                            </FlexColumn>
                            <FlexColumn style={{ minWidth: '900px' }}>
                                {/* Master Service Agreement */}
                                <FlexColumn start>
                                    <Text heading>Step 2: Contracts</Text>
                                    <Text margin='0 0 24px' lightText>Legally binding agreements between you and your customer, e.g. Master Service Agreements</Text>
                                    {/* <Text lightText>{(!this.state.msas) ? "No contract added. This is required" : null}</Text> */}
                                    {
                                        ((this.state.msas || []).length == 0) ?
                                            <Text lightText>No contract added</Text>
                                            :
                                            <Table
                                                id="msa-table"
                                                className='borderless-table'
                                                scroll={{ y: '700px', x: '100%' }}
                                                bordered={false}
                                                size="middle"
                                                columns={msaColumns}
                                                dataSource={this.state.msas || []}
                                                rowKey='masterServiceAgreementId'
                                            />
                                    }
                                    {/* {(this.state.msas || []).map(msa => {
                                        return <div>
                                            <Space size="small">
                                                <div style={{ color: this.props.theme.colors.secondary3, fontSize: 17, cursor: 'pointer' }} onClick={() => this.downloadAttachment(msa.documentUrl.uri, msa.documentUrl.fileName)}>{msa.documentUrl.fileName}</div>
                                                {!getResourcePerm("Support.Console").aionSystemUser && <Popconfirm title="Are you sure you want to delete this MSA?" onConfirm={() => this.handleDeleteMSA(msa.masterServiceAgreementId)}>
                                                    <DeleteOutlined style={{ cursor: 'pointer' }} />
                                                </Popconfirm>}
                                            </Space>
                                        </div>
                                    })} */}
                                    {!getResourcePerm("Support.Console").aionSystemUser &&
                                        <Flex start style={{ width: '100%' }}>
                                            <Button disabled={!this.invInfoCompleted} onClick={this.invInfoCompleted && this.handleShowMSAModal} solid margin='12px 0 48px' width='170px' text="Add Contracts" height='40px' radius='4px' />
                                        </Flex>
                                    }
                                </FlexColumn>
                                <FlexColumn start>
                                    <Tooltip
                                        // title="Purchase Orders"
                                        placement='topLeft'
                                        overlayStyle={{ width: 370 }}
                                        overlayInnerStyle={{ width: 350 }}
                                        title={
                                            <Text color='white'>
                                                This document could be a <b>work order</b> or a <b>purchase order</b> or a <b>statement of work</b>.<br /><br />
                                                A <b>work order</b> is usually a task or a job for a customer. <br />
                                                <b>Statement of work</b> usually defines project-specific activities, deliverables and timelines for a vendor providing services to the customer.<br />
                                                A <b>purchase order</b> is a commercial document and first official offer issued by a buyer to a seller indicating types, quantities, and agreed prices for products or services.
                                            </Text>
                                        }
                                    >
                                        <Text heading>Step 3: Purchase Orders <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)', fontSize: 17 }} /></Text>
                                    </Tooltip>
                                    <Text margin='0 0 24px' lightText>Legally binding document you receive from your customers authorizing the purchase of products or services e.g. work order, statement of work etc.</Text>
                                    {
                                        ((this.state.workOrders || []).length == 0) ?
                                            <Text lightText>No work orders added</Text>
                                            :
                                            <Table
                                                id="wo-table"
                                                className='borderless-table'
                                                scroll={{ y: '700px', x: '100%' }}
                                                bordered={false}
                                                size="middle"
                                                columns={poColumns}
                                                dataSource={this.state.workOrders || []}
                                                rowKey='ponumber'
                                            />
                                    }
                                    <Flex start style={{ width: '100%' }}>
                                        {!getResourcePerm("Support.Console").aionSystemUser && <Button disabled={!this.invInfoCompleted} onClick={this.invInfoCompleted && this.handleShowWOModal} solid margin='12px 0 48px' width='170px' text="Add Purchase Order" height='40px' radius='4px' />}
                                    </Flex>

                                </FlexColumn>

                                {!getResourcePerm("Support.Console").aionSystemUser && <span style={{ color: theme.colors.systemGray, fontSize: 16 }}>
                                    Don’t do business with this customer?
                                    <Popconfirm
                                        title={<>
                                            <div>  {`Are you sure you want to mark ${customer.displayName} as inactive?`}</div>
                                            {qbo && customer.source === 'QUICKBOOKS' && <div>This will also deactivate the customer on QuickBooks Online</div>}
                                        </>}
                                        onConfirm={() => this.onCustomerMarkInactive(customer)}>
                                        <a> Mark them as Inactive</a>
                                    </Popconfirm>
                                </span>}
                            </FlexColumn>
                        </FlexColumn>
                    </Container>
                </FlexColumn>

                {/* Modals */}
                <AddModalMSA
                    visible={this.state.showAddModalMSA}
                    onAdd={this.handleMSAAdded}
                    onCancel={this.handleCancelMSA}
                    aionStore={this.props.aionStore}
                    store={this.props.store}
                    customer={customer}
                    customerId={customer.customerId}
                />

                <AddModalWO
                    visible={this.state.showAddModalWO}
                    onAdd={this.handleWOAdded}
                    onCancel={this.handleCancelWO}
                    aionStore={this.props.aionStore}
                    store={this.props.store}
                    customerId={customer.customerId}
                    customer={customer}
                    msas={this.state.msas || []}
                />
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.receivablesAppReducer,
        aionStore: state.aionAppReducer,
        receivablesStore: state.receivablesAppReducer,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(EditCustomer)))