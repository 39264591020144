import React from 'react'
import styled, { useTheme } from 'styled-components'
import { Text, Tag } from './Text'
import { TextButton } from './Button'
import { Flex, FlexColumn } from './Container'
import { Image } from './Image'
import ApprovalProgress from '../../Images/approval-progress.png'
import ApprovalProgressDone from '../../Images/approval-progress-done.png'
import ApprovalProgressNext from '../../Images/approval-progress-next.png'
import ApprovalDivider from '../../Images/approval-divider.png'
import ApprovalDividerGreen from '../../Images/approval-divider-green.png'
import Hold from '../../Images/hold.png'
import InfoRed from '../../Images/info-red.png'
import Rejected from '../../Images/rejected.png'
import moment from 'moment-timezone'

const dFormat = "ll; h:mm A z"
const etTimeZone = "America/New_York"

export const ApprovalGraph = (props) => {
    const { rule, margin } = props
    const { status } = rule

    console.log('ApprovalJourney instance rule', rule)
    return <FlexColumn start left style={{ margin: margin || '28px 0 0' }}>
        {
            rule.levels.map((level, index) => {
                return <>
                    {
                        index > 0 &&
                        <Image src={ApprovalDivider} />
                    }
                    <Flex centerHorizontally start gap='12px'>
                        <Image src={ApprovalProgress} />
                        <Text noWrap>{`Level ${index + 1} Approval`}</Text>
                        <Flex start wrap gap='8px'>
                            {
                                level.performers.map(performer => {
                                    return <Tag>{`${performer.firstName} ${performer.lastName}`}</Tag>
                                })
                            }
                        </Flex>
                    </Flex>
                </>
            })
        }

        {/* <TransferJourney status={status} /> */}
    </FlexColumn>
}



export const ApprovalJourney = (props) => {
    const theme = useTheme();

    const { instance, payment } = props
    const { initiatedBy, levelInstances, startTime, status } = instance
    var next = false

    const getProgressImage = (levelInstances, li, index) => {
        const { status } = li

        const rejected = (li.performerInstances || []).find(pi => pi.performedAction === 'Rejected')

        if (rejected) return Rejected

        if (index === 0) {
            return status === 'Completed' ? ApprovalProgressDone : status === 'InProgress' ? ApprovalProgressNext : ApprovalProgress
        } else {
            if (levelInstances[index - 1].status === 'Completed') {
                return status === 'Completed' ? ApprovalProgressDone : status === 'InProgress' ? ApprovalProgressNext : ApprovalProgress
            } else {
                return ApprovalProgress
            }
        }
    }

    const getProgressText = (levelInstances, li, index) => {
        const { status } = li
        if (index === 0) {
            return status === 'InProgress' ? 'Awaiting Approval' : 'Not Started'
        } else {
            if (levelInstances[index - 1].status === 'Completed') {
                return status === 'InProgress' ? 'Awaiting Approval' : 'Not Started'
            } else {
                return 'Not Started'
            }
        }
    }

    console.log('ApprovalJourney instance', instance)

    return <FlexColumn start left>
        <Flex between fullWidth centerHorizontally style={{ height: 32 }}>
            <Flex start centerHorizontally gap='12px'>
                <Image src={ApprovalProgressDone} />
                <Text size='14px' lightText>Initiated by</Text>
                <Tag color={theme.colors.defaultText}>{initiatedBy}</Tag>
            </Flex>

            <Text size='14px' lightText>{moment.utc(startTime).tz(etTimeZone).format(dFormat)}</Text>
        </Flex>

        {
            levelInstances.map((li, index) => {
                const { performerInstances, status, endTime, type } = li
                return <FlexColumn key={`permInstContainer${index}`} start fullWidth>
                    {
                        true &&
                        <Image src={ApprovalDivider} />
                    }
                    <Flex between fullWidth centerHorizontally style={{ height: 32 }}>
                        <Flex key={`permInst${index}`} start centerHorizontally gap='12px'>
                            <Image key={`permStatus${index}`} src={getProgressImage(levelInstances, li, index)} />
                            <Text size='14px' lightText>{`Level ${index + 1} Approval`}</Text>
                            {
                                performerInstances.map((performer, pIndex) => {
                                    const { performedAction } = performer
                                    return status === 'InProgress' ? <>
                                        {
                                            type === 'ANY' && pIndex > 0 &&
                                            <Text primary bordered>{type === 'ANY' ? 'or' : 'and'}</Text>
                                        }
                                        {
                                            type === 'ALL' ?
                                                <Tag bordered color={performedAction === 'Approved' ? '#318F2F' : '#1199FF'}>{`${performer.firstName} ${performer.lastName}`} <Image src={performedAction === 'Approved' ? ApprovalProgressDone : ApprovalProgress} margin='0 0 0 8px' /></Tag>
                                                :
                                                <Tag color={theme.colors.defaultText}>{`${performer.firstName} ${performer.lastName}`}</Tag>
                                        }
                                    </>
                                        :
                                        <>
                                            {
                                                performedAction === 'Approved' && <Tag color={theme.colors.defaultText}>{`${performer.firstName} ${performer.lastName}`}</Tag>
                                            }
                                            {
                                                performedAction === 'Rejected' && <Tag color={theme.colors.defaultText}>{`${performer.firstName} ${performer.lastName}`}</Tag>
                                            }
                                        </>
                                })
                            }
                        </Flex>

                        {
                            endTime ?
                                <Text size='14px' lightText>{moment.utc(endTime).tz(etTimeZone).format(dFormat)}</Text>
                                :
                                <Text size='14px' lightText>{getProgressText(levelInstances, li, index)}</Text>
                        }
                    </Flex>
                </FlexColumn>
            })
        }
    </FlexColumn>
}


export const TransferJourney = (props) => {
    const theme = useTheme()
    var { payment, approvalInstance, margin, objectDetail, fromDetail } = props
    const { status, updatedAt, statusMessage, transactionType, direction, rail, type } = payment
    objectDetail = objectDetail || {}
    const { createdAt, completedAt, canceledAt, postedAt, processedAt, lastModifiedAt, rejectionReason } = objectDetail

    // console.log('TransferJourney: approvalInstance', payment, objectDetail, status)

    return <FlexColumn start fullWidth style={{ margin: margin ? margin : 0 }}>

        {
            approvalInstance && <>
                <ApprovalJourney instance={approvalInstance} payment={payment} />
            </>
        }

        {
            approvalInstance && approvalInstance.status === 'InProgress' ?
                <FlexColumn start fullWidth>
                    {
                        true &&
                        <Image src={ApprovalDivider} />
                    }
                    <Flex between fullWidth centerHorizontally style={{ height: 32 }}>
                        <Flex start centerHorizontally gap='12px'>
                            <Image src={ApprovalProgress} />
                            <Text size='14px' lightText>Transfer creation</Text>
                        </Flex>
                        <Text size='14px' lightText>Not Started</Text>
                    </Flex>
                </FlexColumn>
                :
                <>
                    {
                        (status !== 'Hold' && status !== 'denied_approval') &&
                        <FlexColumn start fullWidth>
                            {
                                approvalInstance &&
                                <Image src={ApprovalDivider} />
                            }
                            <Flex between fullWidth centerHorizontally style={{ height: 32 }}>
                                <Flex start centerHorizontally gap='12px'>
                                    <Image src={ApprovalProgressDone} />
                                    <Text size='14px' lightText>Transfer {(['Internal'].includes(rail) || ['Internal'].includes(type)) ? 'completed' : 'created'}</Text>
                                </Flex>
                                <Text size='14px' lightText>{createdAt ? moment.utc(createdAt).tz(etTimeZone).format(dFormat) : moment(lastModifiedAt).tz(etTimeZone).format(dFormat)}</Text>
                            </Flex>
                        </FlexColumn>
                    }
                </>
        }

        {
            status && (() => {
                switch (status.toLowerCase()) {
                    case 'hold':
                        return <FlexColumn start fullWidth>
                            {
                                approvalInstance &&
                                <Image src={ApprovalDivider} />
                            }
                            <Flex between fullWidth centerHorizontally style={{ height: 32 }}>
                                <Flex start centerHorizontally gap='12px'>
                                    <Image src={Hold} />
                                    <Text size='14px' lightText>In Review</Text>
                                </Flex>
                                {
                                    updatedAt &&
                                    <Text size='14px' lightText>Since {moment.utc(updatedAt).tz(etTimeZone).format(dFormat)}</Text>
                                }
                            </Flex>
                        </FlexColumn>
                    case 'canceled':
                        return <FlexColumn start fullWidth>
                            {
                                true &&
                                <Image src={ApprovalDivider} />
                            }
                            <Flex between fullWidth centerHorizontally style={{ height: 32 }}>
                                <Flex start centerHorizontally gap='12px'>
                                    <Image src={Hold} />
                                    <Text size='14px' lightText>Canceled</Text>
                                </Flex>
                                {
                                    canceledAt &&
                                    <Text size='14px' lightText>{moment(canceledAt).tz(etTimeZone).format(dFormat)}</Text>
                                }
                            </Flex>
                        </FlexColumn>
                    case 'rejected':
                    case 'denied_approval':
                        return <FlexColumn start fullWidth>
                            {
                                true &&
                                <Image src={ApprovalDivider} />
                            }
                            <Flex between fullWidth centerHorizontally style={{ height: 32 }}>
                                <Flex start centerHorizontally gap='12px'>
                                    <Image src={Hold} />
                                    <Text size='14px' lightText>Rejected</Text>
                                    <Tag color='#E52D2D' background='#FEF5F5'><Image src={InfoRed} margin='0 8px 0 0' /> {statusMessage && statusMessage}</Tag>
                                </Flex>
                                {
                                    updatedAt &&
                                    <Text size='14px' lightText>-</Text>
                                }
                            </Flex>
                        </FlexColumn>
                    case 'error':
                        return <FlexColumn start fullWidth>
                            {
                                true &&
                                <Image src={ApprovalDivider} />
                            }
                            <Flex between fullWidth centerHorizontally style={{ height: 32 }}>
                                <Flex start centerHorizontally gap='12px'>
                                    <Image src={Hold} />
                                    <Text size='14px' lightText>Error</Text>
                                    <Tag color='#E52D2D' background='#FEF5F5' noCap>{statusMessage && statusMessage}{rejectionReason ? `: ${rejectionReason}` : ''}</Tag>
                                    
                                </Flex>
                                {
                                    updatedAt &&
                                    <Text size='14px' lightText>-</Text>
                                }
                            </Flex>
                        </FlexColumn>
                    case 'created':
                        return <FlexColumn start fullWidth>
                            {
                                true &&
                                <Image src={ApprovalDivider} />
                            }
                            <Flex between fullWidth centerHorizontally style={{ height: 32 }}>
                                <Flex start centerHorizontally gap='12px'>
                                    <Image src={ApprovalProgressNext} />
                                    <Text size='14px' lightText>Processing</Text>
                                </Flex>
                                <Text size='14px' lightText>-</Text>
                            </Flex>
                        </FlexColumn>
                    case 'pending':
                        return <FlexColumn start fullWidth>
                            {
                                true &&
                                <Image src={ApprovalDivider} />
                            }
                            <Flex between fullWidth centerHorizontally style={{ height: 32 }}>
                                <Flex start centerHorizontally gap='12px'>
                                    <Image src={completedAt ? ApprovalProgressDone : ApprovalProgressNext} />
                                    <Text size='14px' lightText>{completedAt ? 'Processed' : 'Processing'}</Text>
                                </Flex>
                                <Text size='14px' lightText>{processedAt ? moment(processedAt).tz(etTimeZone).format(dFormat) : '-'}</Text>
                            </Flex>
                        </FlexColumn>
                    case 'complete':
                    case 'completed':
                        return completedAt ? <FlexColumn start fullWidth>
                            {
                                true &&
                                <Image src={ApprovalDivider} />
                            }
                            <Flex between fullWidth centerHorizontally style={{ height: 32 }}>
                                <Flex start centerHorizontally gap='12px'>
                                    <Image src={ApprovalProgressDone} />
                                    {
                                        fromDetail ?
                                            <Text size='14px' lightText>{getCompletedStepText(direction, transactionType)}</Text>
                                            :
                                            <Text size='14px' lightText>{transactionType && transactionType.toLowerCase() === 'credit' ? 'Transfer received' : 'Sent'}</Text>
                                    }
                                </Flex>
                                {
                                    completedAt ?
                                        <Text size='14px' lightText>{moment(completedAt).tz(etTimeZone).format(dFormat)}</Text>
                                        :
                                        <Text size='14px' lightText>-</Text>
                                }
                            </Flex>
                        </FlexColumn> : null
                    default:
                        return null
                }
            })()
        }

        {
            (completedAt && (status || '').toLowerCase() !== 'created') &&
            <FlexColumn start fullWidth>
                {
                    true &&
                    <Image src={ApprovalDividerGreen} />
                }
                <Flex between fullWidth centerHorizontally style={{ height: 32 }}>
                    <Flex start centerHorizontally gap='12px'>
                        <Image src={ApprovalProgressDone} />
                        {
                            fromDetail ?
                                <Text size='14px' lightText>{getCompletedStepText(direction, transactionType)}</Text>
                                :
                                <Text size='14px' lightText>{transactionType && transactionType.toLowerCase() === 'credit' ? 'Transfer received' : 'Sent'}</Text>
                        }
                    </Flex>
                    <Text size='14px' lightText>{moment(completedAt).tz(etTimeZone).format(dFormat)}</Text>
                </Flex>
            </FlexColumn>
        }
    </FlexColumn>
}

const getCompletedStepText = (direction, transactionType) => {
    switch ((direction || '').toLowerCase()) {
        case 'inbound':
            if (transactionType === 'Push') {
                return 'Transfer received'
            } else if (transactionType === 'Pull') {
                return 'Sent'
            } else {
                return 'Transfer received'
            }
        case 'outbound':
            if (transactionType === 'Push') {
                return 'Sent'
            } else if (transactionType === 'Pull') {
                return 'Transfer received'
            } else {
                return 'Sent'
            }
        default:
            return 'Sent'
    }
}