import React from 'react';
import { ReactSVG } from 'react-svg';
import styled, { withTheme } from 'styled-components';

const SVG = styled(ReactSVG)`
    height: ${props => props.height || props.theme.icon.height}; // this sets the height on the div wrapper
    width: ${props => props.width || props.theme.icon.width}; // this sets the width on the div wrapper
    & svg path {
        fill: ${(props) => (props.fill || 'none')};
        stroke: ${(props) => (props.color || props.theme.colors.primary2)};
		stroke-width: ${(props) => (props.strokeWidth || '1.5px')};
    }
`;

const SVGIcon = ({ src, ...props }) => {
  	return (
		<SVG
			beforeInjection={(svg) => {
				svg.setAttribute('height', props.height || props.theme.icon.height);
				svg.setAttribute('width', props.width || props.theme.icon.width);
				if (svg.getElementsByTagName('title').length > 0) {
					svg.removeChild(svg.getElementsByTagName('title')[0]);
				}
			}}
			onError={(error) => {
				console.error('Error injecting svg icon', error);
			}}
			src={src}
			{...props}
		/>
  	);
};

export default withTheme(SVGIcon);