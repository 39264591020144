import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled, { withTheme } from 'styled-components'
import { CardContainer } from '../Reusable/CardContainer'
import { Flex, FlexColumn } from '../../Reusable/Container'
import environment from '../../../environment'

import AppIcon from '../../../Images/product-menu/CardsActive.png'
import { apiPOSTReq } from '../../../Utils/api'
import CardHeader from '../Reusable/CardHeader'
import ARAgingChart from '../Reusable/ARAgingChart'
import ARAgingCustomer from '../Reusable/ARAgingCustomer'
import { TextButton } from '../../Reusable/Button'
import { Image } from '../../Reusable/Image'
import ArrowRight from "../../../Images/arrow-right.png"
import { Text } from '../../Reusable/Text'
import { toCurrency } from '../../../Utils/util'
import CardSpendDonutChart from '../Reusable/CardSpendDonutChart'
import CardSpendList from '../Reusable/CardSpendList'

class CardSpend extends Component {
    state = {
        loading: true,
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        apiPOSTReq(`${environment.bbBaseUrl}/dashboard/compareBusinessCardSpend`, {}, null, (err, resp) => {
            try {
                this.setState({ loading: false })
                const data = resp || {}
                if (data.result) {
                    if (err) throw new Error(err)
                    console.log("CardSpendDonutChart fetchData", data.cardSpendReport?.spendByCard)
                    var chartData = data.cardSpendReport?.spendByCard?.map(cardSpend => {
                        return {
                            name: cardSpend?.card?.nickName,
                            value: cardSpend?.total
                        }
                    });
                    this.setState({ spendData: data, chartData })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getAccounts", error, err, resp)
            }
        })
    }

    render() {
        const { store, theme } = this.props
        const { spendData, chartData } = this.state

        console.log("CardSpendDonutChart chartData", chartData)
        return (
            <CardContainer>
                <FlexColumn>
                    <CardHeader 
                        imgSrc={AppIcon}
                        titleText='Cards' 
                        desc='Month to date spend'
                        path='cards/all'
                        secondaryContent={
                            <FlexColumn centerVertically>
                                {spendData?.cardSpendReport && <Text size='16px'>This month: <span style={{ fontWeight: 600 }}>${toCurrency(spendData?.cardSpendReport?.total)}</span></Text>}
                                {spendData?.cardSpendReport && <TextButton onClick={() => this.props.history.push('cards/all')} rightIcon={<Image src={ArrowRight} />} text="MANAGE" />}
                            </FlexColumn>
                        }
                    />
                    <Flex style={{ width: this.props.theme.dashboardCard.large }}>
                        {(chartData || []).length > 0 && <CardSpendDonutChart data={chartData} />}
                        {spendData && <CardSpendList cardSpendReport={spendData?.cardSpendReport} />  }
                    </Flex>
                    
                </FlexColumn>
                
            </CardContainer>
            
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(CardSpend)))