import React, { useState, useEffect } from 'react'
import { Table, Skeleton, Tooltip, Menu, Tabs } from 'antd'
import { withTheme } from 'styled-components'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import { saveAs } from 'file-saver'

import { Flex, FlexColumn, LightContainer, CardContainer } from '../../Reusable/Container'
import { Text, Tag } from '../../Reusable/Text'
import { Image } from '../../Reusable/Image'
import { Button, TextButton, ImageButton } from '../../Reusable/Button'
import { LabeledInput } from '../../Reusable/Input'
import { MutedTabs } from '../../Reusable/MutedTabs'
import { apiPOSTReq, getPreSignedS3Url, apiGETDocUrl } from '../../../Utils/api'
import { ErrorAlert } from '../../Reusable/Alert'
import { ACHDetail, WireDetail, CheckDetail, InternalDetail, CardDetail, InstantDetail } from './AccountDetail'
import { TransferJourney } from '../../Reusable/Approval'
import SearchAndFilter from '../../Reusable/SearchAndFilter'
import { getBPAccountsWithAccess } from '../../../Utils/util'
import environment from '../../../environment'

// Images
import ChevronDown from "../../../Images/chevron-down.png"
import ChevronUp from "../../../Images/chevron-up.png"
import Info from '../../../Images/info.png'
import Return from '../../../Images/return.png'
import TableView from '../../Reusable/TableView'

const { TabPane } = Tabs

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
})

const dFormat = "ll";
const etTimeZone = "America/New_York";

const ActivityTable = ({ sharedSearchTerm, ...props }) => {
    const [objectMap, setObjectMap] = useState({})
    const [expandedRowKeys, setExpandedRowKeys] = useState([])
    const [selectedRowId, setSelectedRowId] = useState(null)
    // const [searchTerm, setSearchTerm] = useState()
    const { theme, loading, data, onLoadMore, moreToLoad, title, subtitle, fetchTransactions, filter, showAccount, account, accountIndex, hideCardInfo, unexpandable, reconcile, onReconcile, noCard, ctaContent,
        runningBalance, more, hideType, download, searchAndFilterTitle, tooltip, subAccount, subAccounts, setSearchTerm, padding, rail, viewReturn, customFilterComp, searchPlaceholder, searchWidth, pageSize, customRowKey } = props

    var colSpan = subAccount ? 3 : 4
    if (hideType) colSpan = colSpan - 1
    if (!showAccount) colSpan = colSpan - 1


    const onTabChange = (tab, object) => {
        object.tab = tab
        const objectMapClone = cloneDeep(objectMap)
        objectMapClone[object.objectId] = object

        setObjectMap(objectMapClone)
    }

    const getRowDetails = (record, props) => {
        var { objectId, status, type, userNote, description, transactionCode, rail, subRail } = record
        var object = objectMap[objectId]
        var { approvalInstance, objectDetail } = (object || {})
        objectDetail = objectDetail || {}

        var additionalDetails = objectDetail.additionalDetails

        if (!objectDetail.userNote) objectDetail.userNote = userNote
        if (!objectDetail.description) objectDetail.description = description

        var displayTJ = true
        console.log("getRowDetails objectMap", record.subRail)
        return (<Skeleton loading={!object} active title={false} paragraph={{ rows: 3 }}>
            <FlexColumn start>
                {
                    object && (() => {
                        {/*
                        if (record.subRail && record.subRail.includes('Instant')) return (
                            <InstantDetail
                                detail={objectDetail}
                                traceNumber={record.traceNumber}
                            />)
                        */}

                        switch (record.rail) {
                            case 'Ach':
                                return <ACHDetail viewReturn={viewReturn} record={record} detail={objectDetail} onViewOriginal={setSearchTerm ? (referenceId) => {
                                    console.log('setting setSearchTerm: ', referenceId)
                                    setSearchTerm(referenceId)
                                    fetchTransactions({
                                        pagination: {  pageSize: pageSize || 10, current: 1 },
                                        searchStr: referenceId && referenceId,
                                        viewBy: 'SEARCH_DESCRIPTION',
                                    })
                                } : false} />
                            case 'Wires':
                                return <WireDetail viewReturn={viewReturn} record={record} detail={objectDetail} onViewOriginal={setSearchTerm ? (referenceId) => {
                                    console.log('setting setSearchTerm: ', referenceId)
                                    setSearchTerm(referenceId)
                                    fetchTransactions({
                                        pagination: {  pageSize: pageSize || 10, current: 1 },
                                        searchStr: referenceId && referenceId,
                                        viewBy: 'SEARCH_DESCRIPTION',
                                    })
                                } : false} />
                            case 'Checks':
                                if (objectDetail.direction == "Inbound") displayTJ = false
                                return <CheckDetail detail={objectDetail} />
                            case 'Internal':
                                return <InternalDetail detail={objectDetail} instant={subRail} />
                            case 'CardManagement':
                                return <CardDetail detail={objectDetail} hideCardInfo={hideCardInfo} />
                            default:
                                return null
                        }
                    })()
                }


                {
                    !['CardManagement', 'Card', 'CardMemo'].includes(rail) &&
                    <MutedTabs key={`${objectId}-pendingTabs`} size="large" defaultActiveKey="transferJourney" onChange={(tab) => onTabChange(tab, object)}>
                        {
                            displayTJ &&
                            <TabPane tab="Transfer Journey" key="transferJourney">
                                <LightContainer fullWidth>
                                    <TransferJourney approvalInstance={approvalInstance} payment={record} objectDetail={objectDetail} />
                                </LightContainer>
                            </TabPane>
                        }

                        {
                            additionalDetails && additionalDetails.length > 0 &&
                            <TabPane tab="Additional Details" key="additionalDetails">
                                <LightContainer fullWidth padding='8px 24px 12px'>
                                    {
                                        additionalDetails.map(additionalDetail => {
                                            if (additionalDetail.attachmentList) {
                                                if (additionalDetail.attachmentList.length > 0) {
                                                    return <FlexColumn style={{ marginBottom: 12 }}>
                                                        <Text size='14px' caption weight='400' margin='0 0 8px'>{additionalDetail.context}</Text>
                                                        <Flex start gap='8px' wrap>
                                                            {
                                                                additionalDetail.attachmentList.map((file, i) => {
                                                                    return <Tag key={`${i}-${file.fileName}`} onClick={() => downloadDocument(file.uri, file.fileName)} primary noCap bordered><Image src={File} margin='0 4px 0 0' />{file.fileName}</Tag>
                                                                })
                                                            }
                                                        </Flex>
                                                    </FlexColumn>
                                                }
                                            } else {
                                                if (additionalDetail.context === 'Notifications to') {
                                                    return <LabeledInput type='read-only' label={additionalDetail.context} value={additionalDetail.contextValue} />
                                                }
                                            }
                                        })
                                    }
                                </LightContainer>
                            </TabPane>
                        }
                    </MutedTabs>
                }
            </FlexColumn>
        </Skeleton>
        )
    }

    const getDataWithDates = (data) => {
        var dataWithDates = []

        data.forEach((item, i) => {
            /*
            if (i === 0 || (moment(data[i - 1].txnDate).dayOfYear() !== moment(item.txnDate).dayOfYear())) {
                dataWithDates.push({
                    dateRow: true,
                    date: moment(item.txnDate).format('MMM DD, YYYY')
                })
            }
            */
            item['date'] = moment(item.txnDate).format(dFormat)
            dataWithDates.push(item)
        })
        return dataWithDates
    }

    const getRowDetail = (record) => {
        if (record.approvalInstanceId) {
            getApprovalInstance(record)
        } else {
            getObjectDetail(record)
        }
    }

    const getObjectDetail = (record) => {
        const { objectId, transaction, rail, transactionId, subRail } = record
        const objectMapClone = cloneDeep(objectMap)

        var body = {}

        switch (rail) {
            case 'Ach':
                body.viewBy = 'ACH'
                body.objectId = record.achId
                break
            case 'Wires':
                body.viewBy = 'WIRE_TFER'
                body.objectId = record.wireId
                break
            case 'Checks':
                body.viewBy = 'CHECK_DEPOSIT'
                body.objectId = record.checkId
                break
            case 'Internal':
                body.viewBy = 'BOOK_TFER'
                body.objectId = record.transactionId
                break
            case 'Card':
            case 'CardManagement':
                body.viewBy = 'CARD_TXN'
                body.objectId = transactionId
                break
        }
        if (subRail && subRail.includes('Instant') && record.instantTransferId) {
            body.viewBy = 'INSTANT_TFER'
            body.objectId = record.instantTransferId
        }
        // console.log('bb/getObjectDetail ActivityTable body', body, record)

        apiPOSTReq(`${environment.bbBaseUrl}/bb/getObjectDetail`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            try {
                var data = resp || {}
                if (err) throw Error(err)
                if (!resp.result) throw Error(resp.responseMessage || resp.error)

                var object

                switch (rail) {
                    case 'Ach':
                        object = data.achTransferObj
                        break
                    case 'Wires':
                        object = data.wireTransferObj
                        if (object && data.fiToFiInfos) object.fiToFiInfos = data.fiToFiInfos
                        break
                    case 'Checks':
                        object = data.checkDepositObj
                        break
                    case 'Internal':
                        object = data.instantTransferObj || data.bookTransferObj
                        break
                    case 'CardManagement':
                        object = data.cardManagementTransactionInfo
                        break
                }
                if (subRail && subRail.includes('Instant') && data.instantTransferObj) {
                    object = data.instantTransferObj
                }

                if (!object) object = record

                if (objectMapClone[objectId]) {
                    objectMapClone[objectId].objectDetail = object
                } else {
                    objectMapClone[objectId] = { objectDetail: object } || true
                }

                if (object.approvalInstanceId) {
                    getApprovalInstance(record, object)
                } else {
                    setObjectMap(objectMapClone)
                }
            } catch (error) {
                // setObjectMap(objectMapClone)
                console.error('bb/getObjectDetail', error)
                if (objectMapClone[objectId]) {
                    objectMapClone[objectId].objectDetail = record
                } else {
                    objectMapClone[objectId] = { objectDetail: record } || true
                }
                setObjectMap(objectMapClone)
                // ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    const getApprovalInstance = (record, objectDetail) => {
        var { objectId, approvalInstanceId } = record
        if (objectDetail) approvalInstanceId = objectDetail.approvalInstanceId
        apiPOSTReq(`${environment.bbBaseUrl}/bb/approvals/getApprovalInstance `, { "BankProvider": environment.bankProvider.crb }, { instanceId: approvalInstanceId, }, (err, resp) => {
            try {
                const data = resp
                if (data.result && data.approvalRuleInstance) {
                    const objectMapClone = cloneDeep(objectMap)
                    if (objectMapClone[objectId]) {
                        objectMapClone[objectId].approvalInstance = data.approvalRuleInstance
                    } else {
                        objectMapClone[objectId] = { approvalInstance: data.approvalRuleInstance } || true
                    }

                    if (objectDetail) objectMapClone[objectId].objectDetail = objectDetail

                    setObjectMap(objectMapClone)
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                ErrorAlert({ description: error.message })
            }
        })
    }

    const downloadDocument = (url, name) => {
        getPreSignedS3Url({ url: url }, (err, preSignedUrl) => {
            saveAs(preSignedUrl, name)
        })
    }

    var activityColumns = []

    activityColumns.push({
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        align: "left",
        render: (date, record) => {
            if (record.sectionHeader === true) {
                return { props: { colSpan: 0 } }
            } else {
                return date
            }
        },
        width: 140,
    })

    if (!hideType) {
        activityColumns.push({
            title: 'Type',
            dataIndex: 'rail',
            key: 'type',
            align: "center",
            render: (type, record) => {
                if (record.dateRow === true) {
                    return { props: { colSpan: 0 } }
                } else {
                    var displayType = type
                    if (type === 'Ach') displayType = 'ACH'
                    if (type === 'CardManagement') displayType = 'Card'
                    if (type === 'Wires') displayType = 'Wire'
                    if (type === 'Checks') displayType = 'Check'
                    if (type === 'Internal') {
                        displayType = record.subRail
                            ? (record.subRail === 'Instant-Internal' ? 'Internal' : record.subRail)
                            : 'Internal';
                    }
                    if (type === 'Rtp') displayType = 'Instant'
                    // if (record.subRail && (record.subRail || '').includes('Instant')) displayType = 'Instant'

                    return <Tag>{displayType}</Tag>
                }
            },
            width: 82,
        })
    }

    if (!hideCardInfo) {
        activityColumns.push({
            title: 'Description',
            dataIndex: 'displayDescription',
            key: 'displayDescription',
            primaryCol: true,
            render: (desc, record) => {
                if (record.dateRow === true) {
                    return {
                        children: <Flex start fullWidth><Text size='14px' color={theme.colors.lightGrey}>{moment.utc(record.date).format(dFormat)}</Text></Flex>,
                        props: { colSpan: colSpan },
                    }
                } else {
                    return desc
                }
            },
        })
    }

    if (hideCardInfo) {
        activityColumns.push({
            title: 'Merchant & Location',
            dataIndex: 'merchantNameLocation',
            key: 'merchantNameLocation',
            render: (merchantName, record) => {
                if (record.dateRow === true) {
                    return {
                        children: <Flex start fullWidth><Text size='14px' color={theme.colors.lightGrey}>{moment.utc(record.date).format(dFormat)}</Text></Flex>,
                        props: { colSpan: colSpan },
                    }
                } else {
                    return <Text>{merchantName}</Text>
                }
            },
        })

        // activityColumns.push({
        //     title: 'Reference Number',
        //     dataIndex: 'retrievalReferenceNumber',
        //     key: 'retrievalReferenceNumber',
        // })

        // activityColumns.push({
        //     title: 'Category',
        //     dataIndex: 'merchantCategory',
        //     key: 'merchantCategory',
        // })
    }

    if (hideCardInfo) {
        activityColumns.push({
            title: 'Amount',
            dataIndex: 'amount',
            key: 'Amount',
            align: "right",
            render: (amount, record) => {
                if (record.dateRow === true) {
                    return { props: { colSpan: 0 } }
                } else {
                    console.log("record.transactionType", record.transactionType)
                    return <Flex end centerHorizontally gap='24px'>
                        {
                            record.transactionType == 'Credit' ?
                                <Text size='12px' noWrap weight='600' credit>+ {formatter.format(amount)}</Text>
                                :
                                <Text size='12px' noWrap weight='600' color={theme.colors.defaultLightText}>- {formatter.format(Math.abs(amount))}</Text>
                        }
                    </Flex>
                }
            },
            width: 140,
        })
    } else if (reconcile) {
        activityColumns.push({
            title: 'Transaction Amt',
            dataIndex: 'amount',
            key: 'Amount',
            align: "right",
            render: (amount, record) => {
                if (record.dateRow === true) {
                    return { props: { colSpan: 0 } }
                } else {
                    return <Flex end centerHorizontally gap='24px'>
                        {
                            amount > 0 ?
                                <Text noWrap weight='400'>{formatter.format(amount)}</Text>
                                :
                                <Text noWrap weight='400' color={theme.colors.defaultLightText}>{formatter.format(Math.abs(amount))}</Text>
                        }
                    </Flex>
                }
            },
            width: 150,
        })
        activityColumns.push({
            title: 'Reconciled Amt',
            dataIndex: 'amount',
            key: 'Amount',
            align: "right",
            render: (amount, record) => {
                if (record.dateRow === true) {
                    return { props: { colSpan: 0 } }
                } else {
                    return <Flex end centerHorizontally gap='24px'>
                        {
                            amount > 0 ?
                                <Text noWrap weight='400'>{formatter.format((amount - record.reconcilableAmount) || 0)}</Text>
                                :
                                <Text noWrap weight='400' color={theme.colors.defaultLightText}>{formatter.format(Math.abs(amount))}</Text>
                        }
                    </Flex>
                }
            },
            width: 145,
        })
        activityColumns.push({
            title: 'Available Amt',
            dataIndex: 'amount',
            key: 'Amount',
            align: "right",
            render: (amount, record) => {
                if (record.dateRow === true) {
                    return { props: { colSpan: 0 } }
                } else {
                    return <Flex end centerHorizontally gap='24px'>
                        {
                            amount > 0 ?
                                <Text noWrap weight='600'>{formatter.format(record.reconcilableAmount || 0)}</Text>
                                :
                                <Text noWrap weight='600' color={theme.colors.defaultLightText}>{formatter.format(Math.abs(amount))}</Text>
                        }
                    </Flex>
                }
            },
            width: 140,
        })
    } else {
        activityColumns.push({
            title: 'Amount',
            dataIndex: 'amount',
            key: 'Amount',
            align: "right",
            render: (amount, record) => {
                const { transactionCode, paymentType, wasReturned, wasReversed } = record
                if (record.dateRow === true) {
                    return { props: { colSpan: 0 } }
                } else {
                    return <Flex end centerHorizontally gap='24px'>
                        {
                            ((["Return", "Reversal"].includes(paymentType)) || wasReversed || wasReturned || (["Incoming ACH Return", "Reversal"].includes(transactionCode))) &&
                            <Image src={Return} />
                        }
                        {
                            amount > 0 ?
                                <Text noWrap weight='600' credit>+ {formatter.format(amount)}</Text>
                                :
                                <Text noWrap weight='600' color={theme.colors.defaultLightText}>- {formatter.format(Math.abs(amount))}</Text>
                        }
                    </Flex>
                }
            },
            width: 140,
        })
    }

    if (reconcile) activityColumns.push({
        title: 'Payment',
        key: 'action',
        align: 'right',
        width: '160px',
        render: (text, record) => {
            if (record.dateRow === true) {
                return { props: { colSpan: 0 } }
            } else {
                return <Flex center>
                    <LabeledInput
                        nomargin
                        type='number'
                        placeholder='0.0'
                        minHeight='28px'
                        alignRight
                        // precision={2}
                        value={record.reconcileAmount}
                        max={record.reconcilableAmount || 0}
                        min={0}
                        onChange={(value) => {
                            record.reconcileAmount = value
                        }}
                        containerStyle={{ padding: '4px 8px' }}
                        onBlur={() => onReconcile(data)}
                        onPressEnter={() => onReconcile(data)}
                    />
                </Flex>
            }
        },
    })

    if (runningBalance && !subAccount) {
        activityColumns.push({
            title: 'Balance',
            dataIndex: 'balance',
            key: 'balance',
            align: "right",
            render: (balance, record) => {
                if (record.dateRow === true) {
                    return { props: { colSpan: 0 } }
                } else {
                    return formatter.format(balance)
                }
            },
            width: 200,
        })
    }

    if (showAccount) {
        activityColumns.push({
            title: 'Account',
            dataIndex: 'account',
            key: 'account',
            align: "right",
            render: (amount, record) => {
                if (record.dateRow === true) {
                    return { props: { colSpan: 0 } }
                } else {
                    var accounts = getBPAccountsWithAccess("Transactions");
                    accounts = accounts || []

                    var accountMap = {}

                    accounts.forEach(account => {
                        accountMap[account.accountId] = account
                    })
                    const account = accountMap[record.accountId]

                    if (account) return <Tag>{account.mask} • {account.nickName || `Business ${account.accountSubType}`}</Tag>
                    else if (subAccounts && subAccounts.length > 0) {
                        const subAccount = subAccounts.find(subAccount => subAccount.accountId === record.accountId)
                        if (subAccount) return <Tag>{subAccount.mask} • {subAccount.nickName || `Business ${subAccount.accountSubType}`}</Tag>
                    }
                }
            },
            width: 300,
        })
    }
    const rowKey = customRowKey || 'id'
    return (
            <FlexColumn fullWidth start>

                <TableView
                    id="posted-table"
                    loading={loading}
                    titleText={
                        <Flex start gap='8px' centerHorizontally>
                            <>
                                {
                                    (title || searchAndFilterTitle) &&
                                    <Text heading height='20px'>{title || searchAndFilterTitle || 'Transactions'}</Text>
                                }
                                {
                                    tooltip &&
                                    <Tooltip overlayInnerStyle={{ width: 760, minWidth: 760 }} overlayStyle={{ width: 780, minWidth: 780 }} overlayClassName='roles-tooltip' placement="right" arrowPointAtCenter={false} title={<FlexColumn>
                                        <Text color='white' size='14px' noWrap>{tooltip}</Text>
                                    </FlexColumn>}>
                                        <FlexColumn>
                                            <Image src={Info} />
                                        </FlexColumn>
                                    </Tooltip>
                                }
                            </>
                        </Flex>
                    }
                    descText={props.subtitle}
                    pagination={false}
                    tableLayout='auto'
                    columns={activityColumns}
                    dataSource={getDataWithDates(data || [])}
                    rowKey={rowKey}
                    ctaContent={
                        filter ?
                        <SearchAndFilter
                            tooltip={tooltip}
                            title={searchAndFilterTitle}
                            account={subAccount ? subAccount : account}
                            accountIndex={accountIndex}
                            more={more}
                            download={download}
                            onChange={(options) => fetchTransactions(options)}
                            sharedSearchTerm={sharedSearchTerm}
                            setSharedSearchTerm={setSearchTerm}
                            rail={rail}
                            customFilterComp={customFilterComp}
                            placeholder={searchPlaceholder}
                            searchWidth={searchWidth}
                            noCard={noCard}
                            pageSize={pageSize}
                        />
                        :
                        ctaContent && ctaContent
                    }
                    onRow={
                        (props.expand != false) ?  // ON by default
                        (record, rowIndex) => {
                            return {
                                onClick: event => {
                                    if(record[rowKey] == selectedRowId) {
                                        setSelectedRowId(null)
                                    } else {
                                        getRowDetail(record)
                                        setSelectedRowId(record[rowKey])
                                    }
                                }, // click row
                            }
                        }
                        :
                        () => {}
                    }
                    expand={props.expand != false} // ON by default
                    expandedRowKey={selectedRowId}
                    getRowDetails={(record) => getRowDetails(record)}
                    onLoadMore={onLoadMore}
                    moreToLoad={moreToLoad}
                    loadingMore={loading}
                    pageSize={pageSize || 50}
                />

                {/* {
                    onLoadMore && moreToLoad &&
                    <Flex fullWidth centerVertically><TextButton text={`LOAD ${pageSize || 10} MORE`} loading={loading} onClick={onLoadMore} rightIcon={<img style={{ cursor: 'pointer' }} width='24px' height='24px' src={ChevronDown} />} /></Flex>
                } */}
            </FlexColumn>
    )
}

export default (withTheme(ActivityTable))