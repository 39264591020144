import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { Button, Upload, Input, Checkbox, message } from 'antd'
import { UploadOutlined } from '@ant-design/icons'

import { ADD_SUPPORTING_DOCS, DELETE_SUPPORTING_DOCS, NO_DOCUMENT_TOGGLE, DOCUMENT_NOTE, addDataToStore } from '../../Actions/actions'

// Util
import { getJwt } from '../../Utils/api';
import environment from '../../environment';
import { ErrorAlert } from '../Reusable/Alert'

const flexRowStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
}

const hintStyle = {
    fontSize: "12px",
    margin: "0px 0px 5px 0",
    color: "rgb(0, 122, 255)"
}

class UploadDoc extends Component {

    constructor(props) {
        super(props)
        this.state = {
            jwt: null
        }
    }

    componentDidMount() {
        getJwt((err, jwt) => {
            this.setState({ jwt });
        });
    }

    render() {
        const { title, name, docType, accept, checkNoDoc } = this.props;
        const { supportingDocuments } = this.props.store;

        const isNoDocumenChecked = (docType, name) => {
            if (checkNoDoc) {
                const doc = supportingDocuments[docType].find(doc => doc.Name == name && typeof doc.Note !== 'undefined');
                return !!doc && doc.noDocumentChecked;
            } else
                return false;
        }

        const getNoDocumenNote = (docType, name) => {
            const doc = supportingDocuments[docType].find(doc => doc.Name == name && typeof doc.Note !== 'undefined');
            return doc.Note;
        }

        const onDocCheckChange = (docType, year, e) => {
            this.props.dispatch(addDataToStore(NO_DOCUMENT_TOGGLE, { docType, name: year }))
        }

        const onDocNoteChange = (docType, year, e) => {
            this.props.dispatch(addDataToStore(DOCUMENT_NOTE, { docType, name: year, note: e.target.value }))
        }

        const profitAndLossUploadProps = (name) => {

            // Prepare the list of files cached in the last browser session
            const defaultFileList = [];
            supportingDocuments[docType].forEach((doc, index) => {
                if (doc.Name == name && doc.DocumentURL) 
                    defaultFileList.push({
                      uid: doc.uid,
                      name: doc.DocumentURL.FileName,
                      status: 'done',
                      url: doc.DocumentURL.URI
                    });
            });
            const { UAM } = this.props.aionStore;
            return {
                name: `${name}-${moment().format('MM-DD-YY')}`.toLowerCase(),
                multiple: false,
                action: `${environment.apiBaseUrl}/financing/upload`,
                headers: {
                    aionAuth: UAM.encryptedAuthHeader || "",
                    businesskey: this.props.businessUniqueKey,
                    type: "onboarding",
                    jwt: this.state.jwt
                },
                onChange: (info) => {
                    const { status } = info.file
                    if (status !== 'uploading') {
                        console.log(info.file, info.fileList)
                    }
                    if(info.file.response === "Not authorized") ErrorAlert({description: "Your session seems to have expired, please logout and try again."})
                    if (status === 'done' && info.file.response.SavedDocumentUrls) {
                        const newData = {
                            uid: info.file.uid,
                            Name: '' + name,
                            DocumentURL: {
                                "FileName": info.file.name,
                                "URI": info.file.response.SavedDocumentUrls.slice(-1).pop(),
                                "Date": info.file.response.Date
                            }
                        }
                        this.props.dispatch(addDataToStore(ADD_SUPPORTING_DOCS, {docType, payload: newData}))
                        message.success(`${info.file.name} file uploaded successfully.`)
                    } else if (status === 'error') {
                        message.error(`${info.file.name} file upload failed.`)
                    }
                },
                onRemove: (file) => {
                    this.props.dispatch(addDataToStore(DELETE_SUPPORTING_DOCS, {docType, file}))
                },
                defaultFileList
            };
        }

        return (
            this.state.jwt ?
            <div>
                <h4>{title || name}</h4>
                <div style={flexRowStyle}>
                    <Upload showUploadList={!isNoDocumenChecked(docType, name)} {...profitAndLossUploadProps(name)}>
                        <Button disabled={isNoDocumenChecked(docType, name)}>
                            <UploadOutlined /> Click to Upload
                        </Button>
                        {checkNoDoc &&
                            <span onClick={(e) => { e.stopPropagation() }}>
                                <Checkbox onChange={onDocCheckChange.bind(this, docType, name)} checked={isNoDocumenChecked(docType, name)} style={{ marginLeft: '10pt' }}>No document</Checkbox>
                            </span>
                        }
                    </Upload>
                </div>
                {
                    checkNoDoc && isNoDocumenChecked(docType, name) &&
                    <div>
                        <Input
                            style={{ marginTop: '10pt', marginBottom: '5pt' }}
                            onChange={onDocNoteChange.bind(this, docType, name)}
                            value={getNoDocumenNote(docType, name)}
                            placeholder="Note" />
                        <p style={hintStyle}>Add a note to tell us why you do not have this document</p>
                    </div>
                }
            </div> : null
        )
    }
}

function mapStateToProps(state) {
    return { 
        store: state.onboardingAppReducer,
        aionStore: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadDoc);