import React, { Component } from 'react'
import moment from 'moment'
import { Skeleton, Popover } from 'antd'
import Dimensions from 'react-dimensions'
import { withTheme } from 'styled-components';
import { VictoryChart, VictoryArea, VictoryAxis, Flyout, VictoryZoomContainer, VictoryScatter, VictoryTooltip, VictoryVoronoiContainer, VictoryLabel, VictoryCursorContainer, createContainer, VictoryTheme } from "victory"
import { getTextWidth } from '../../Utils/util';
import { Text } from './Text';
import { Image } from './Image'
import { Flex, FlexColumn } from './Container';
import Info from '../../Images/info.png'
import hexToRgba from 'hex-to-rgba';

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
})

const INITIAL_CHART_DAYS = 180

class CustomFlyout extends React.Component {
    render() {
        const { x, y, datum, xLabel, yLabel } = this.props;
        const labelStyle = { fontSize: 12, textAnchor: "start", fill: "white", fontFamily: 'InterDisplay', weight: '500' }
        const padding = 10; // Padding around text
        const width = 20 + getTextWidth(formatter.format(datum[yLabel]))
        const height = 50;
        // const newY = orientation === "bottom" ? y - 35 : y + 35;
        return (
            <g>
                <rect x={x - 10 - padding} y={y - 25} width={width} height={height} fill="#333333" rx="8" ry="8"  />
                <text style={labelStyle} x={x - 10} y={y - 5}>{moment.utc(datum[xLabel]).format('MMM DD')}</text>
                <text style={labelStyle} x={x - 10} y={y + 15}>{formatter.format(datum[yLabel])}</text>
            </g>
        );
    }
}

class Chart extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        var { height, paddingOffset, data, theme, xLabel, yLabel, loading, title, accountId } = this.props

        data = data || []

        const VictoryZoomVoronoiContainer = createContainer("voronoi", "zoom")

        var lowerY = 0//Math.min(...data.map(d => d[yLabel]))
        if(lowerY === 0) lowerY = -1
        const upperY = Math.max(10, ...data.map(d => d[yLabel]));

        const convertedData = data.map(item => ({
            ...item,
            xlabel: new Date(item.xlabel),
        }));

        return (
            <FlexColumn start style={{ width: this.props.containerWidth, padding: loading ? '0 24px' : '0' }}>
                <Skeleton loading={loading} active paragraph={{ rows: 7 }}>
                    <VictoryChart
                        key={`${accountId}Chart`}
                        // theme={VictoryTheme.material}
                        height={height}
                        width={this.props.containerWidth}
                        // domain={{ y: [lowerY - (delta * 0.25), upperY + (delta * 0.25)] }}
                        domain={{ y: [lowerY, upperY] }}
                        // domain={{ y: [getLowerChartDomain(data, yLabel), getUpperChartDomain(data, yLabel)] }}
                        padding={{ top: 20, right: 24, bottom: 25, left: 24 }}
                        domainPadding={{ y: 20 }}
                        containerComponent={<VictoryVoronoiContainer
                            responsive={false}
                            // zoomDimension='x'
                            // zoomDomain={data.length > INITIAL_CHART_DAYS && { x: [new Date(data[data.length - INITIAL_CHART_DAYS][xLabel]), new Date(data[data.length - 1][xLabel])] }}
                            labels={({ datum }) => {
                                if (datum.childName === 'chart-area-1') return `$${Math.max(...data.map(d => d[yLabel]))}`
                            }}
                            labelComponent={<VictoryTooltip
                                constrainToVisibleArea={true}
                                centerOffset={{ x: 55, y: -25 }}
                                style={{ fontSize: 12, textAnchor: "start", fill: "white", fontFamily: 'InterDisplay' }}
                                flyoutStyle={{ padding: 12, fontSize: 12, textAnchor: "start", stroke: "transparent", strokeWidth: 1, fill: "#333333", color: 'white', fontFamily: 'InterDisplay' }}
                                pointerLength={0}
                                cornerRadius={8}
                                flyoutPadding={18}
                                flyoutHeight={50}
                                labelComponent={<CustomFlyout xLabel={xLabel} yLabel={yLabel} />}
                            />}
                        />
                        }
                    >
                        {data &&
                            <VictoryAxis
                                fixLabelOverlap={true}
                                // offsetY={0.05 * delta}
                                tickCount={(convertedData || []).length < 5 ? 2 : 4}
                                tickFormat={(tick, index, ticks) => {
                                    return moment.utc(tick).add(1, 'd').format('MMM D')
                                }}
                                style={{
                                    axis: { stroke: "transparent" },
                                    tickLabels: { fontSize: 12, padding: 2, fill: '#333333', fontFamily: 'InterDisplay' }
                                }}
                            />}
                        <VictoryArea
                            // domain={{ y: [Math.min(...data.map(d => d[yLabel])), Math.max(...data.map(d => d[yLabel]))] }}
                            // domain={{ y: [getLowerChartDomain(data, yLabel), getUpperChartDomain(data, yLabel)] }}
                            // domainPadding={{y: [20, 20]}}
                            // padding={0}\
                            //“basis”, “cardinal”, “catmullRom”, “linear”, “monotoneX”, “monotoneY”, “natural”, “step”, “stepAfter”, “stepBefore”
                            interpolation="monotoneX"
                            style={{
                                data: {
                                    fill: "url(#colorBv)", fillOpacity: 0.7, stroke: hexToRgba('#1A8AFA', 0.95), strokeWidth: 4
                                },
                            }}
                            data={convertedData}
                            x={xLabel}
                            // x={d[yLabel]}
                            // x={(d) => moment(d[xLabel], 'YYYY-MM-DD').unix()}
                            y={(d) => Number(d[yLabel])}
                        />
                        <VictoryScatter
                            data={convertedData}
                            // dataComponent={<CatPoint />}
                            x={xLabel}
                            y={(d) => Number(d[yLabel])}
                            size={3}
                            style={{
                                data: {
                                    stroke: ({ active }) => active === true ? theme.colors.primary : "transparent",
                                    fill: ({ active }) => active === true ? 'white' : "transparent", strokeWidth: 2,
                                }
                            }}
                        // labels={({ datum }) => `$${toCurrency(datum[yLabel])}`} // Replace with prop
                        // labelComponent={<VictoryTooltip cornerRadius={0} flyoutStyle={{ fill: "white", fontFamily: 'InterDisplay' }} />}
                        />
                        <svg style={{ height: 0 }}>
                            <defs>
                                <radialGradient id="dsoGradient" cy="0%" r="90%">
                                    <stop offset="0%" stop-color="#1199FF47" />
                                    <stop offset="95%" stop-color="white" />
                                </radialGradient>
                                <radialGradient id="RadialGradient2" cx="0.25" cy="0.25" r="0.25">
                                    <stop offset="0%" stop-color="red" />
                                    <stop offset="100%" stop-color="blue" />
                                </radialGradient>
                                <linearGradient id="gradient3"
                                    x1="0%" y1="0%" x2="0%" y2="100%"
                                >
                                    <stop offset="0%" stopColor="#1199FF47" />
                                    <stop offset="100%" stopColor="white" />
                                </linearGradient>
                                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.6} />
                                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                                </linearGradient>
                                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.6} />
                                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                                </linearGradient>
                                <linearGradient id="colorBv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#1A8AFA47" stopOpacity={0.6} />
                                    <stop offset="95%" stopColor="#1A8AFA47" stopOpacity={0} />
                                </linearGradient>
                                <linearGradient id="colorTT" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#1A8AFA" />
                                    <stop offset="95%" stopColor="#1199FF" />
                                </linearGradient>
                            </defs>
                        </svg>
                    </VictoryChart>
                    {
                        title &&
                        <Flex fillWidth center style={{ marginTop: 0 }}>
                            <Text size='14px'>{title}</Text>
                            <Popover content={<Flex><Text size='14px'>The data in the chart shows the daily posted balances until the prior business day. This does not include any credits or debits that are in process or pending.</Text></Flex>}>
                                <Flex><Image width='16px' height='16px' margin='0 0 0 4px' src={Info} /></Flex>
                            </Popover>
                        </Flex>
                    }
                </Skeleton>
            </FlexColumn>
        )
    }
}

export default Dimensions({ elementResize: true })(withTheme(Chart))