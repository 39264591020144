
module.exports = {

    flowViewsData: [
        {
            "FlowStep": "Apply.BusinessInfo",
            "Title": "Finalize Application","Progress": 0.1,
            "ButtonTitle": "NEXT",
            "Step": "Business info",
            "DefaultUserInput": "NOTREQUIRED",
            "BackButton": true,
            "Shadow": true,
            "ShowChat": true,
            "ShowAionLogo": true,
        },
        {
            "FlowStep": "Apply.OwnershipInfo",
            "Title": "Finalize Application",
            "Progress": 0.5,
            "ButtonTitle": "REVIEW",
            "DefaultUserInput": "NOTREQUIRED",
            "Step": "Applicant info",
            "AnimationWidth": 300,
            "Shadow": true,
            "BackButton": true,
            "ShowChat": true,
            "ShowAionLogo": true,
        },
        {
            "FlowStep": "Apply.ReviewAndSubmit",
            "Title": "Finalize Application",
            "Progress": 0.99,
            "ButtonTitle": "SUBMIT",
            "DefaultUserInput": "NOTREQUIRED",
            "Step": "Applicantion info",
            "AnimationWidth": 300,
            "Shadow": true,
            "ShowChat": true,
            "ShowAionLogo": true,
        },
        {
            "FlowStep": "Apply.Submitted",
            "Title": "Applying for financing with Aion",
             //"Description": "Welcome to your one-stop shop for Aion Capital. Complete outstanding tasks, keep track of your application status, and chat with the Aion team throughout the process.",
            "ButtonTitle": "Done",
            "DefaultUserInput": "NOTREQUIRED",
            "HideButton": true,
            "BackButton": false,
            "ShowChat": true,
            "ShowAionLogo": true,
        }
    ]
,
    postDDViewsData : [
        {
            "FlowStep": "Apply.ContractSigned",
            "Title": "Finalize Application",
            "Progress": 0.1,
            "ButtonTitle": "NEXT",
            "Step": "Banking Approval",
            "DefaultUserInput": "NOTREQUIRED",
            "BackButton": true,
            "Shadow": true,
            "ShowChat": true,
            "ShowAionLogo": true,
        },
    ]
}

