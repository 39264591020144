import React from 'react'
import { Select } from 'antd'
import { FlexColumn, Flex } from './Container'
import { Text, Tag } from './Text'
import Individual from '../../Images/individual-recipient.png'
import Business from '../../Images/business-recipient.png'
import { toCurrency } from '../../Utils/util'

const { Option } = Select

export const FromAccountOption = (props) => {
    const { account } = props
    const nickNameMaxLen = 18
    var accountNickName = `${(account.nickName || "").trim().substr(0, nickNameMaxLen)}${(account.nickName || "").trim().length > nickNameMaxLen ? "..." : ""}`
    return <FlexColumn start fullWidth>
        <Text capitalize title={account.nickName}>{`${(accountNickName || `Business ${account.accountSubType}`)} • ${account.mask}`}</Text>
        <Text size='14px' lightText style={{ marginTop: 4 }}>${toCurrency(account.availableBalance)}</Text>
    </FlexColumn>
}

export const PaymentMethodOption = (props) => {
    const { paymentMethod, rail } = props
    const { bankDetail, type } = paymentMethod || {}
    const { accountNumber, checkDeliveryType } = bankDetail || {}
    const nickNameMaxLen = 30
    var pmNickName = `${(paymentMethod.nickName || "").trim().substr(0, nickNameMaxLen)}${(paymentMethod.nickName || "").trim().length > nickNameMaxLen ? "..." : ""}`

    return <Flex between style={{ width: props.width }}>
        <FlexColumn start>
            <Text title={paymentMethod.nickName}>{pmNickName} • {paymentMethod.bankDetail.accountNumber}</Text>
            {/* {
                !rail &&
                <Text lightText>{paymentMethod.type}</Text>
            } */}
        </FlexColumn>
        {
            type == 'Check' ?
                <Text lightText>{checkDeliveryType == 'PostalMail' ? 'Postal Mail' : checkDeliveryType}</Text>
                :
                <Tag primary>{paymentMethod.type}</Tag>
        }

    </Flex>
}

export const RecipientOption = (props) => {
    const { recipient } = props
    return <FlexColumn start fullWidth>
        <Flex start>
            <img width='12px' height='12px' src={recipient.type === "Business" ? Business : Individual} style={{ marginRight: 4, marginBottom: 2 }} />
            <Text capitalize>{recipient.nickName || recipient.nameOnAccount}</Text>
        </Flex>
        <Text lightText>{recipient.email}</Text>
    </FlexColumn>
}

export const BasicOption = (props) => {
    const { value } = props
    return <Flex start fullWidth centerHorizontally>
        <Text>{value}</Text>
    </Flex>
}