import React from 'react';
import { Skeleton } from 'antd';
import { Flex, FlexColumn } from './Container';
import { Text } from './Text';

export const KPICard = (props) => {
    const { value, label, icon, loading, margin, width, height, padding } = props

    return <div style={{ padding: padding && padding }}><FlexColumn start gap='4px' style={{ background: '#FBFBFB', width: width || 204, height: height || 100, padding: '16px 16px', borderRadius: 8, margin, minWidth: 204 }}>
        <Skeleton loading={loading} active title={true} paragraph={{ rows: 12 }}>
            {
                label && <Text size='14px'>{label}</Text>
            }

            <Flex between centerHorizontally>{value && <Text size='20px' height='40px'>{value}</Text>}{icon ? <img width='24px' height='24px' src={icon} /> : <></>}</Flex>
        </Skeleton>
    </FlexColumn>
    </div>
}

export const KPI = (props) => {
    const { value, label } = props

    return <FlexColumn style={{ marginBottom: props.noMargin ? 0 : 24 }}>
        <Text size='24px' height='32px' lightText>{value}</Text>
        <Text size='14px' weight='500' lightText>{label}</Text>
    </FlexColumn>
}

export const KPILight = (props) => {
    const { value, label, margin } = props

    return <Flex between centerHorizontally style={{ margin: margin, background: '#FBFBFB', width: '100%', height: 40, padding: '8px 16px' }}>
        <Text light size='14px'>{label}</Text>
        <Text weight={600} color={props.valueColor || '#7384AA'}>{value}</Text>
    </Flex>
}