import React, { useState } from 'react'
import { message } from 'antd'
import moment from 'moment'

import { Text } from '../../Reusable/Text'
import { Flex, FlexColumn } from '../../Reusable/Container'
import { Button } from '../../Reusable/Button'
import { LabeledInput } from '../../Reusable/Input'
import environment from '../../../environment'
import { apiPOSTReq } from '../../../Utils/api'
import { getRoleOptions } from '../../../Utils/util'
import { ErrorAlert } from '../../Reusable/Alert'
import { uaApiPOST } from '../../../Utils/userAccess'

const InviteUser = (props) => {
    const [last4Error, setLast4Error] = useState(false)
    const [expDateError, setExpDateError] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [selectedRoles, setSelectedRoles] = useState([])
    const [firstName, setFirstName] = useState()
    const [lastName, setLastName] = useState()
    const [email, setEmail] = useState()
    const [phone, setPhone] = useState()

    const { selectedCard, allRoles, closeModal } = props

    const handleSelectRole = (role) => {
        // var tempSelectedRoles = selectedRoles

        // if (tempSelectedRoles.includes("Company Admin")) {
        //     allRoles.forEach(role => {
        //         if (role.roleName != "Company Admin" && role.roleType != "Custom__Sys") {
        //             tempSelectedRoles = tempSelectedRoles.filter(e => e !== role.roleName)
        //         }
        //     })
        // }

        setSelectedRoles(role)
    }

    const inviteUser = () => {
        var body = {
            RoleNames: selectedRoles,
            User: {
                "FirstName": firstName,
                "LastName": lastName,
                "Email": email,
                "Phone": `+1${phone}`
            }
        }
        setSubmitLoading(true)
        console.log("UAM.Submit", JSON.stringify(body))
        uaApiPOST("/user/admin/createBusinessUser", { body: body }, (err, resp) => {
            try {
                console.log("UAM.Submit", JSON.stringify(resp))
                setSubmitLoading(false)
                if (err) throw Error(err)
                if (!resp.result) throw Error(resp.responseMessage || resp.error)
                message.success('Invite sent!')
                closeModal()
            } catch (error) {
                ErrorAlert({ description: error.message })
            }
        })
    }

    return (
        <FlexColumn left>
            <Text heading>Invite User</Text>
            <Text style={{ marginBottom: 24, marginTop: 8 }}>Add a new user to your Aion account</Text>
            <LabeledInput
                id='role'
                key='role'
                label='Roles'
                type='select'
                mode="multiple"
                placeholder='Select role/s'
                // margin='0 0 12px'
                // value={last4}
                maxLength={4}
                onChange={handleSelectRole}
            // error={last4Error}
            // errorMessage={'Please enter the last 4 digits of your card number'}
            >
                {getRoleOptions(allRoles, [])}
            </LabeledInput>
            <Flex between fullWidth>
                <LabeledInput
                    id='firstName'
                    key='firstName'
                    label='First Name'
                    placeholder='Enter first name'
                    value={firstName}
                    size={'large'}
                    margin='0 12px 0 0'
                    onChange={e => setFirstName(e.target.value)}
                // error={expDateError}
                // errorMessage={"Please enter the expiry date of your card e.g 10/20"}
                />

                <LabeledInput
                    id='lastName'
                    key='lastName'
                    label='Last Name'
                    placeholder='Enter last name'
                    value={lastName}
                    size={'large'}
                    margin='0 0 0 12px'
                    onChange={e => setLastName(e.target.value)}
                // error={expDateError}
                // errorMessage={"Please enter the expiry date of your card e.g 10/20"}
                />
            </Flex>
            <LabeledInput
                label="Phone"
                id="phone"
                type="phone"
                prefix="+1"
                placeholder="Enter US phone number"
                onChange={e => setPhone(e.target.value)}
                maxLength={10}
            />
            <LabeledInput
                label="Email"
                id="email"
                type="email"
                value={email}
                margin='12px 0 48px'
                placeholder="Business Email"
                onChange={e => setEmail(e.target.value)}
            // onChange={handleTextChange}
            // onKeyDown={handleOnKeyDown}
            />
            <Button
                solid
                radius='4px'
                width='140px'
                text='SEND INVITE'
                onClick={() => inviteUser()}
                loading={submitLoading} />
        </FlexColumn>
    )
}

export default InviteUser