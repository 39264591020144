import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';

import {
    message, Select, Popconfirm,
} from 'antd';

import {
    DeleteOutlined
} from '@ant-design/icons';

// Components
import { Button, TextButton } from '../../Reusable/Refresh/Button';
import { Text } from '../../Reusable/Text';
import { Flex, FlexColumn, } from '../../Reusable/Container';
import { LabeledInput } from '../../Reusable/Input';
import { ErrorAlert } from '../../Reusable/Alert';
import environment from '../../../environment';
import { apiPOSTReq } from '../../../Utils/api';
import { getUserApps, toNumberStr } from '../../../Utils/util';
import { ExpenseCategory } from '../../Reusable/ExpenseCategory';

const { Option } = Select;

const priorityMap = {
    "Accounts Payable": 2,
    "Accounts Receivable": 3,
    "Bank": 4,
    "Cost of Goods Sold": 5,
    "Credit Card": 6,
    "Equity": 7,
    "Expense": 8,
    "Fixed Asset": 9,
    "Income": 0,
    "Long Term Liability": 10,
    "Other Asset": 11,
    "Other Current Asset": 12,
    "Other Current Liability": 13,
    "Other Expense": 14,
    "Other Income": 1,
}

class NewProduct extends Component {

    state = {
        loading: false,
        product: this.props.product || {},
        category: (this.props.product || {}).accountCategoryName,
        allCustomCOA: [],
        catories: [],
    }

    componentDidMount() {
        this.fetchCOA({});
        console.log("handleSelectCategory", JSON.stringify(this.props.product))
    }

    fetchCOA = ({ searchStr }) => {
        const headers = {
            AionCurrentBiz: this.props.aionStore.BusinessUniqueKey,
            AionAuth: this.props.aionStore.UAM.encryptedAuthHeader
        }

        const body = {
            "BusinessId": this.props.aionStore.BusinessUniqueKey,
            "size": 1000,
            "page": 0,
            "ActiveRecords": true,
            "sortFieldName": "Code",
            "sortDirection": "ASC"
        }
        if (searchStr) {
            body.searchStr = searchStr;
        }

        apiPOSTReq(`${environment.bbBaseUrl}/bk/getCategories`, headers, body, (err, resp) => {
            try {
                const data = resp;
                console.log("/bk/getCategories", data)
                if (data.result) {
                    const customCOAList = data.customCOAList.filter(x => x.coatype !== 'Accounts Payable' && x.coatype !== 'Accounts Receivable')
                    let categories = (data.systemCOAList || []).concat(customCOAList);
                    this.setState({
                        categories: categories ? categories.sort((a, b) => {
                            if (priorityMap[a.coatype] < priorityMap[b.coatype]) return -1;
                            else return 1;
                        }).map(x => {
                            return {
                                ...x, key: x.id,
                            }
                        }) : [],
                    })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getActiveCustomers", error.stack, resp);
                ErrorAlert({ description: error.message })
            }
        })
    }

    handleSearch = value => {
        this.fetchCOA({ searchStr: value })
    }

    handleTextChange = (event) => {
        var { product } = this.state;
        product[event.target.id] = event.target.value;
        this.setState({ product });
    }

    handleSelect = (value, option) => {
        console.log("handleSelect", value, option)
        var { product } = this.state;
        product["type"] = value;
        this.setState({ product });
    }

    handleSelectCategory = (value, option) => {
        var category = this.state.categories[value]
        console.log("handleSelectCategory", value, category)
        var { product } = this.state;
        product["accountCategoryName"] = category.categoryName;
        product["accountCategoryId"] = category.id;
        product["accountCategoryType"] = category.coatype;
        this.setState({ product, category: category.categoryName });
    }

    handleSubmit = async (event) => {
        var { product, category } = this.state;
        const { name, type, unitPrice } = product;
        if (!name) {
            ErrorAlert({ description: "Please enter a product/service name." });
            return;
        }
        if (!type) {
            ErrorAlert({ description: "Please select a type." });
            return;
        }
        if (!category) {
            ErrorAlert({ description: "Please select an account." });
            return;
        }

        product.productName = name

        if (unitPrice) {
            const unitPriceAmt = toNumberStr(unitPrice)
            product.unitPrice = unitPriceAmt
        }

        const body = {
            "product": product,
        }

        this.setState({ loading: true })

        apiPOSTReq(`${environment.iveBaseUrl}/ive/product/save`, {}, body, (err, resp) => {
            try {
                console.log("handleSubmit product resp ", JSON.stringify(resp));
                const data = resp;
                console.log("/ive/product/save", data)
                if (data.result) {
                    message.success(`${name} successfully created`);
                    if (data.responseMessage != null && data.responseMessage != "Success") {
                        ErrorAlert({ description: data.responseMessage });
                    }
                    this.props.submitComplete();
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                this.setState({ loading: false })
                console.log("ERRR /ive/product/save", error, err, resp);
                ErrorAlert({ description: error.message })
            }
        })
    }

    deactivateProduct = () => {
        const { product } = this.state;
        // deactivates a Custom COA when there are no references to BKTransactions and is not a parent
        const body = {
            "productId": product.id
        }
        this.setState({ deactivateLoading: true });
        apiPOSTReq(`${environment.iveBaseUrl}/ive/product/deactivate`, {}, body, (err, resp) => {
            try {
                this.setState({ deactivateLoading: false });
                const data = resp;
                console.log("/ive/product/deactivate", data)
                if (data.result) {
                    message.success(`Product deactivated!`);
                    this.props.submitComplete();
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR deactivateProduct", error, resp);
                ErrorAlert({ description: error.message })
            }
        })
    }

    render() {
        const { product, loading } = this.state;
        const { productId, name, type, sku, unitPrice, description, accountCategoryName } = product;
        const { theme, aionStore } = this.props;

        const userApps = getUserApps(this.props.aionStore);
        const { CurrentSync } = this.props.aionStore;
        let qbo = false;
        if (CurrentSync && CurrentSync.platformName === 'QUICKBOOKS' && CurrentSync.syncSetupCompleted && userApps && userApps.includes("Receivables")) {
            qbo = true;
        }

        return (
            <FlexColumn start left style={{ width: "100%" }}>
                <Text heading>{this.props.product ? "Edit product or service" : "Create a new product or service"}</Text>
                {/* <Text>Enter the details below.</Text> */}
                <Flex between style={{ width: '100%' }}>
                    <FlexColumn style={{ width: '50%', marginRight: 12 }}>
                        <LabeledInput
                            autoFocus
                            label="Name"
                            labelcolor={theme.colors.secondary3}
                            id="name"
                            key="name"
                            placeholder="Enter your product or service name"
                            value={name}
                            onChange={this.handleTextChange}
                        />
                        <LabeledInput
                            showSearch
                            label="Income Account"
                            labelcolor={theme.colors.secondary3}
                            id="category"
                            key="category"
                            type="remote-search"
                            className="no-left-padding"
                            placeholder="Select an account"
                            value={accountCategoryName}
                            onChange={this.handleSelectCategory}
                            onSearch={this.handleSearch}
                        >
                            {(this.state.categories || []).map((category, i) => (
                                <Option key={category.id} id="category" name="category" value={i}>
                                    <ExpenseCategory category={category} theme={theme} />
                                </Option>
                            ))}
                        </LabeledInput>
                        <LabeledInput
                            optional
                            label="SKU"
                            labelcolor={theme.colors.secondary3}
                            id="sku"
                            key="sku"
                            placeholder="Enter your product/service sku"
                            value={sku}
                            onChange={this.handleTextChange}
                        />
                    </FlexColumn>
                    <FlexColumn style={{ width: '50%', marginLeft: 12 }}>
                        <LabeledInput
                            label="Type"
                            labelcolor={theme.colors.secondary3}
                            id="type"
                            key="type"
                            type="select"
                            className="no-left-padding"
                            placeholder="Product"
                            value={type}
                            onChange={this.handleSelect}
                        >
                            {["Product", "Service"].map(item => (
                                <Option key={item} id="type" name="type" value={item}>
                                    {item}
                                </Option>
                            )
                            )}
                        </LabeledInput>
                        <LabeledInput
                            label="Unit Price"
                            labelcolor={theme.colors.secondary3}
                            id="unitPrice"
                            key="unitPrice"
                            placeholder="Enter a price"
                            prefix="$"
                            value={unitPrice}
                            onChange={this.handleTextChange}
                        />
                        <LabeledInput
                            optional
                            label="Description"
                            labelcolor={theme.colors.secondary3}
                            id="description"
                            key="description"
                            placeholder="Enter any description"
                            value={description}
                            onChange={this.handleTextChange}
                        />
                    </FlexColumn>
                </Flex>

                <Flex start style={{ margin: '20px 0 0', width: '100%' }} centerHorizontally>
                    <Button permtype="Receivables.Invoices" solid loading={loading} onClick={this.handleSubmit} text={productId ? "Update" : "Save"} />
                    {
                        productId ?
                            <Popconfirm
                                title={<>
                                    <div>Are you sure you want to deactivate this product/service?</div>
                                    {qbo && this.state.product && this.state.product.source === 'QUICKBOOKS' && <div>This will also deactivate the product/service on QuickBooks Online</div>}
                                </>}
                                onConfirm={this.deactivateProduct}
                                okText="Confirm"
                                okButtonProps={{ loading: this.state.deactivateLoading }}
                                cancelText="Cancel"
                            >
                                <TextButton margin='0 0 0 24px' permtype="Receivables.Invoices" type="text" icon={<DeleteOutlined />} text='Deactivate' />
                            </Popconfirm>
                            : null
                    }
                </Flex>
            </FlexColumn>
        );
    }
}

function mapStateToProps(state) {
    return {
        bkStore: state.bookkeepingAppReducer,
        aionStore: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(NewProduct));