import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import cloneDeep from 'lodash/cloneDeep'

import { Flex, FlexColumn } from '../../Reusable/Container';
import FlowViewComponent from '../../Reusable/Refresh/FlowViewComponent';
import { toCurrency } from '../../../Utils/util';
import { LabeledInput } from '../../Reusable/Input';
import { Paragraph, Text } from '../../Reusable/Text';
import { addDataToStore, BUSINESS_INFO, REMOTE_SAVE_DATA } from '../../../Actions/actions';
import { TextButton } from '../../Reusable/Refresh/Button';
import { ErrorAlert } from '../../Reusable/Alert';
import { CloseOutlined, FilePdfOutlined } from '@ant-design/icons';
import environment from '../../../environment';
import { message, Upload } from 'antd';
import { apiPOSTReq } from '../../../Utils/api';
import { Divider } from '../../Reusable/Divider';
import _ from 'underscore';

const BIZ_DESC_CHAR = 20

class TxnActivityInfo extends Component {

    state = {
        businessInfoCopy: cloneDeep(this.props.onboardingStore.businessInfo)
    }
    onCloseClick = () => {
        this.props.dispatch(addDataToStore(BUSINESS_INFO, this.state.businessInfoCopy))
        this.props.loadNext();
    }

    saveUserInput = (id, value) => {
        var dataToSave = {};
        switch(id) {
            default:
                dataToSave[id] = value;
                break;
        }
        this.props.dispatch(addDataToStore(BUSINESS_INFO, dataToSave));
    }

    handleOnChange = (event) => {
        const target = event.target;
        switch(target.id) {
            case "revenue":
            case "moneyInLow":
            case "moneyInHigh":
            case "moneyOutLow":
            case "moneyOutHigh":
                this.saveUserInput(target.id || target.name, target.value.replace(/[^0-9.-]+/g,""))
                break
            default:
                this.saveUserInput(target.id || target.name, target.value)
        }
    }

    setErrorField = (id, msg) => this.setState({ errorField: id, errorMessage: msg });

    validateFlow = () => {
        const { onboardingStore, flowView } = this.props;
        var { revenue, description, moneyInLow, moneyInHigh, moneyInDescription, moneyOutLow, moneyOutHigh, moneyOutDescription } = onboardingStore.businessInfo || {};
        if(!revenue){
            this.setErrorField("revenue", "Please enter a value");
            return;
        }
        if (!description || description.length < BIZ_DESC_CHAR) {
            this.setErrorField("description", `Business description must be at least ${BIZ_DESC_CHAR} characters`);
            return;
        }
        if(!moneyInLow){
            this.setErrorField("moneyInLow", "Please enter a value");
            return;
        }
        if(!moneyInHigh){
            this.setErrorField("moneyInHigh", "Please enter a value");
            return;
        }
        if (parseFloat(moneyInLow) > parseFloat(moneyInHigh)) {
            this.setErrorField("moneyInHigh", "The value for high money in cannot be lower than the low amount");
            return;
        }
        console.log("moneyInLow?moneyInLow", (moneyInLow),  (moneyInHigh))
        if(!moneyInDescription){
            this.setErrorField("moneyInDescription", "Please enter a value.");
            return;
        }
        if(!moneyOutLow){
            this.setErrorField("moneyOutLow", "Please enter a value.");
            return;
        }
        if(!moneyOutHigh){
            this.setErrorField("moneyOutHigh", "Please enter a value.");
            return;
        }
        if (parseFloat(moneyOutLow) > parseFloat(moneyOutHigh)) {
            this.setErrorField("moneyOutHigh", "The value for high money out cannot be lower than the low amount");
            return;
        }
        if(!moneyOutDescription){
            this.setErrorField("moneyOutDescription", "Please enter a value.");
            return;
        }
        
        // Save to the DB
        console.log("Saving business", { businessInfo: onboardingStore.businessInfo })
        this.props.dispatch(addDataToStore(REMOTE_SAVE_DATA, flowView.FlowStep));
        this.props.loadNext();
    }

    render() {
        var { flowView, currentViewIndex, loadPrevView, onboardingStore, theme, reviewView } = this.props;
        var { errorField, errorMessage } = this.state;
        var { businessInfo } = onboardingStore;

        var bizDescInst = `Enter at least ${BIZ_DESC_CHAR} characters`
        if(!_.isEmpty(businessInfo.description)) {
            bizDescInst = (businessInfo.description.length < BIZ_DESC_CHAR) ? `Minimum of ${BIZ_DESC_CHAR - businessInfo.description.length} more characters required` : ''
        }

        flowView = flowView || {}
        var childViews = (
            <FlexColumn start style={{ marginTop: "0px", minHeight: '100%' }}>
                <Flex start style={{ width: "100%", marginTop: "24px" }}>
                    <div style={{ width: "25%", marginRight: 12 }}>
                        <LabeledInput
                            autoFocus
                            label="Annual Revenue"
                            symbolprefix="$"
                            key={flowView.FlowStep}
                            id="revenue"
                            placeholder="5,000,000"
                            onChange={this.handleOnChange}
                            value={businessInfo.revenue && toCurrency(businessInfo.revenue, 0, 0)}
                            error={errorField=="revenue"}
                            errorMessage={errorMessage}
                        />
                    </div> 
                    <div style={{ width: "73%", marginRight: 12, marginLeft: 12 }}>
                        <LabeledInput
                            label="Business Description"
                            key={flowView.FlowStep}
                            id="description"
                            // rows={1}
                            placeholder={`E.g. We build marketing webites for tech companies (minimum ${BIZ_DESC_CHAR} characters)`}
                            onChange={this.handleOnChange}
                            instruction={bizDescInst}
                            value={businessInfo.description}
                            error={errorField=="description"}
                            errorMessage={errorMessage}
                        />
                    </div>
                </Flex>
                <FlexColumn>
                    <Divider />
                    <Text uppercase spacing='0.08em' weight='500' color={theme.colors.secondary6}>Money In (Monthly)</Text>
                    <Text>Provide a description and an estimate of monthly incoming transaction dollar volumes.<br/>This could include revenue from sales, retained earnings, credit etc.</Text>
                    <Flex style={{ width: "100%", marginTop: "24px" }}>
                        <div style={{ width: "25%", marginRight: 12 }}>
                            <LabeledInput
                                label="Low"
                                key="moneyInLow"
                                id="moneyInLow"
                                placeholder="100,000"
                                prefix="$"
                                value={businessInfo.moneyInLow && toCurrency(businessInfo.moneyInLow, 0, 0)}
                                onChange={this.handleOnChange}
                                error={errorField=="moneyInLow"}
                                errorMessage={errorMessage}
                            />
                        </div>
                        <div style={{ width: "25%", marginRight: 12, marginLeft: 12 }}>
                            <LabeledInput
                                label="High"
                                key="moneyInHigh"
                                id="moneyInHigh"
                                placeholder="500,000"
                                prefix="$"
                                value={businessInfo.moneyInHigh && toCurrency(businessInfo.moneyInHigh, 0, 0)}
                                onChange={this.handleOnChange}
                                error={errorField=="moneyInHigh"}
                                errorMessage={errorMessage}
                            />
                        </div>
                        <div style={{ width: "45%", marginRight: 12, marginLeft: 12 }}>
                            <LabeledInput
                                // type="text-area"
                                label="Provide information about the incoming activity"
                                key="moneyInDescription"
                                id="moneyInDescription"
                                placeholder="Tell us more about the source and types of incoming transactions" 
                                onChange={this.handleOnChange}
                                value={businessInfo.moneyInDescription}
                                error={errorField=="moneyInDescription"}
                                errorMessage={errorMessage}
                            />
                        </div>
                    </Flex>
                </FlexColumn> 
                <FlexColumn>
                    <Divider />
                    <Text uppercase spacing='0.08em' weight='500' color={theme.colors.secondary6}>Money Out (Monthly)</Text>
                    <Text>Provide a description and an estimate of monthly outgoing transaction dollar volumes.<br/>This could include expenses such as business expenses, bill payments etc.</Text>
                    <Flex style={{ width: "100%", marginTop: "24px" }}>
                        <div style={{ width: "25%", marginRight: 12 }}>
                            <LabeledInput
                                label="Low"
                                key="moneyOutLow"
                                id="moneyOutLow"
                                placeholder="100,000"
                                prefix="$"
                                value={businessInfo.moneyOutLow && toCurrency(businessInfo.moneyOutLow, 0, 0)}
                                onChange={this.handleOnChange}
                                error={errorField=="moneyOutLow"}
                                errorMessage={errorMessage}
                            />
                        </div>
                        <div style={{ width: "25%", marginRight: 12, marginLeft: 12 }}>
                            <LabeledInput
                                label="High"
                                key="moneyOutHigh"
                                id="moneyOutHigh"
                                placeholder="500,000"
                                prefix="$"
                                value={businessInfo.moneyOutHigh && toCurrency(businessInfo.moneyOutHigh, 0, 0)}
                                onChange={this.handleOnChange}
                                error={errorField=="moneyOutHigh"}
                                errorMessage={errorMessage}
                            />
                        </div>
                        <div style={{ width: "45%", marginRight: 12, marginLeft: 12 }}>
                            <LabeledInput
                                // type="text-area"
                                label="Provide information about the outgoing activity"
                                key="moneyOutDescription"
                                id="moneyOutDescription"
                                placeholder="Tell us more about your expense types and vendors" 
                                onChange={this.handleOnChange}
                                value={businessInfo.moneyOutDescription}
                                error={errorField=="moneyOutDescription"}
                                errorMessage={errorMessage}
                            />
                        </div>
                    </Flex>
                </FlexColumn>                  
            </FlexColumn>
        );
        if(reviewView) {
            return (
                <FlexColumn start fullWidth left>
                    <Flex start fullWidth gap='24px' style={{ marginTop: '24px' }}>
                        <div style={{ width: "25%" }}>
                            <LabeledInput
                                type="read-only"
                                label="Annual Revenue"
                                value={businessInfo.revenue && `$${toCurrency(businessInfo.revenue, 0, 0)}`}
                            />
                        </div>
                        <div style={{ width: "50%" }}>
                            <LabeledInput
                                type="read-only"
                                label="Brief description of business"
                                value={businessInfo.description}
                            />
                        </div>
                    </Flex>
                    <FlexColumn fullWidth>
                        <Divider />
                        <Text uppercase spacing='0.08em' weight='500' color={theme.colors.secondary6}>Monthly Credit Information</Text>
                        <Flex start fullWidth gap='24px' style={{ marginTop: '24px' }}>
                            <div style={{ width: "25%" }}>
                                <LabeledInput
                                    type="read-only"
                                    label="Low"
                                    value={businessInfo.moneyInLow && `$${toCurrency(businessInfo.moneyInLow, 0, 0)}`}
                                />
                            </div>
                            <div style={{ width: "25%" }}>
                                <LabeledInput
                                    type="read-only"
                                    label="High"
                                    value={businessInfo.moneyInHigh && `$${toCurrency(businessInfo.moneyInHigh, 0, 0)}`}
                                />
                            </div>
                            <div style={{ width: "50%" }}>
                                <LabeledInput
                                    type="read-only"
                                    label="Provide a description of your incoming activity"
                                    value={businessInfo.moneyInDescription}
                                />
                            </div>
                        </Flex>
                    </FlexColumn> 
                    <FlexColumn fullWidth>
                        <Divider />
                        <Text uppercase spacing='0.08em' weight='500' color={theme.colors.secondary6}>Monthly Debit Information</Text>
                        <Flex start fullWidth gap='24px' style={{ marginTop: '24px' }}>
                            <div style={{ width: "25%" }}>
                                <LabeledInput
                                    type="read-only"
                                    label="Low"
                                    value={businessInfo.moneyOutLow && `${toCurrency(businessInfo.moneyOutLow, 0, 0)}`}
                                />
                            </div>
                            <div style={{ width: "25%" }}>
                                <LabeledInput
                                    type="read-only"
                                    label="High"
                                    value={businessInfo.moneyOutHigh && `$${toCurrency(businessInfo.moneyOutHigh, 0, 0)}`}
                                />
                            </div>
                            <div style={{ width: "50%" }}>
                                <LabeledInput
                                    type="read-only"
                                    label="Provide a description of your outgoing activity"
                                    value={businessInfo.moneyOutDescription}
                                />
                            </div>
                        </Flex>
                    </FlexColumn> 
                </FlexColumn>
            )
        }
        return (
            <FlowViewComponent
                flowView={flowView}
                currentViewIndex={currentViewIndex}
                loadPrev={loadPrevView}
                childViews={childViews}
                submitLoading={this.state.submitLoading}
                width={'100%'}
                back={currentViewIndex > 0}
                padding='0 64px'
                {...this.props}
                loadNext={this.validateFlow}
                onCloseClick={this.onCloseClick}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        onboardingStore: state.onboardingAppReducer,
        aionStore: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(TxnActivityInfo))