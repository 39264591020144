import React, { useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { lightTheme, darkTheme } from './Styles/theme'
import { GlobalStyles } from './Styles/global'
import { Router, Route, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import Amplify, { Auth } from 'aws-amplify'

// Components
import ProtectedRoute from './ProtectedRoute'
import Signin from './Components/Authentication/Signin'
import OAuthSignin from './Components/Authentication/OAuthSignin'
import Register from './Components/Authentication/Register'
import ConfirmRegistration from './Components/Authentication/ConfirmRegistration'
import ForgotPassword from './Components/Authentication/ForgotPassword'
import ConfirmForgotPassword from './Components/Authentication/ConfirmForgotPassword'
import InvitedUserOnboarding from './Components/OnboardingFlowCoordinator/InvitedUserOnboarding/Index'
import CustomerPayment from './Components/Receivables/CustomerPayment/Index'
import Home from './Components/Home/Index'
import PayNow from './Components/Pay Now/Index'
import Dashboard from './Components/Dashboard/Index'
import { isMobile, isSmallScreen } from './Utils/util'

import CreditAppStatus from './Components/Credit/EligibilityRefresh/Reusable/CreditAppStatus'

// Styles
import './App.css'
import environment from './environment'
import CreditContractSign from './Components/Credit/EligibilityRefresh/Reusable/CreditContractSign'
import ErrorModal from './Components/Reusable/ErrorModal'
import AdditionalDocument from './Components/OnboardingFlowCoordinator/Reusable/AdditionalDocument'
import AdditionalInformation from './Components/OnboardingFlowCoordinator/Reusable/AdditionalInformation'

// Config
Amplify.configure({
    Auth: environment.cognitoConfig
})

function App() {
    const [theme, setTheme] = useState('light')
    const [isMobileDevice, setIsMobileDevice] = useState(isMobile)

    // The function that toggles between themes
    const toggleTheme = () => {
        // if the theme is not light, then set it to dark
        if (theme === 'light') {
            setTheme('dark')
            // otherwise, it should be light
        } else {
            setTheme('light')
        }
    }

    useEffect(() => {
        window.document.firstElementChild.style.zoom = 0.85;
    }, []);

    return (
        <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
            <>
                <GlobalStyles />
                <Router history={createBrowserHistory()} theme={theme === 'light' ? lightTheme : darkTheme} >
                    <Switch>
                        <Route exact path="/" component={Signin} />
                        <Route exact path="/create-aion-account" component={Register} />
                        <Route exact path="/oauth/login" component={OAuthSignin} /> 
                        {/* <Route exact path="/signup" component={Register} /> */}
                        <Route exact path="/signup/confirm" component={ConfirmRegistration} />
                        <Route exact path="/forgot-password" component={ForgotPassword} />
                        <Route exact path="/forgot-password/confirm" component={ConfirmForgotPassword} />
                        <Route exact path="/signout" component={Home} />
                        <Route exact path="/dashboard" component={Dashboard} />
                        <ProtectedRoute path="/apps" component={Home} />
                        <ProtectedRoute path="/banking" component={Home} />
                        <ProtectedRoute path="/cards" component={Home} />
                        <ProtectedRoute path="/credit" component={Home} />
                        <ProtectedRoute path="/settings" component={Home} />
                        <ProtectedRoute path="/receivables" component={Home} />
                        <ProtectedRoute path="/payables" component={Home} />
                        <ProtectedRoute path="/contractor-payroll" component={Home} />
                        {/* <ProtectedRoute path="/bookkeeping" component={Home} /> */}
                        <ProtectedRoute path="/callback" component={Home} />
                        <ProtectedRoute path="/apply" component={Home} />
                        <ProtectedRoute path="/user-invite" component={InvitedUserOnboarding} />
                        <ProtectedRoute path="/contract-sign" component={CreditAppStatus} />
                        <ProtectedRoute path="/credit-contract-sign" component={CreditContractSign} />
                        <ProtectedRoute path="/additional-document" component={AdditionalDocument} />
                        <ProtectedRoute path="/approvals" component={Home} />
                        <ProtectedRoute path="/support" component={Home} />
                        <ProtectedRoute path="/underwriting" component={Home} />
                        <ProtectedRoute path="/paynow" component={PayNow} />
                        <ProtectedRoute path="/customer-payment" component={CustomerPayment} />
                    </Switch>
                </Router>
                <ErrorModal />
            </>
        </ThemeProvider>
    )
}

export default App
