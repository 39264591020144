import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import moment from 'moment'
import queryString from 'query-string'
import update from 'immutability-helper';
import {
    Input,
    Select,
    Divider,
    Upload,
    message,
    Button,
    DatePicker,
    Modal,
    Steps,
    Descriptions,
    Result,
    Alert,
    PageHeader,
    Table,
    Typography,
    Skeleton,
    List
} from 'antd'
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { PlusOutlined, InboxOutlined } from '@ant-design/icons';

import OAuthClient from 'intuit-oauth'

// import qbButton from '../../../Images/C2QB_auth.png'
import qbButton from '../../../Images/C2QB_green_btn_tall_hover.svg'

// Components
import InvoiceSelectionCard from './InvoiceSelectionCard';
import InvoiceSubmissionCard from './InvoiceSubmissionCard';

// Util
import { apiGET, apiPOST } from '../../../Utils/api'
import { toCurrency, getCustomerName } from '../../../Utils/util'
import { SYSTEM_COLORS, SYSTEM_STYLES } from '../../../Utils/stylingAssets'
import environment from '../../../environment';

// Actions
import { CREDIT_SAVE_DATA, TEMPORARY_FORM_DATA, addDataToStore } from '../../../Actions/actions'
import Banner from '../../Reusable/Banner'

const { Option } = Select
const { Dragger } = Upload
const { Step } = Steps
const { Text, Title } = Typography

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

class AddInvoice extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formLayout: 'horizontal',
            current: 0,
            formData: {},
            accountingSoftware: this.props.location.state ? this.props.location.state.accountingSoftware : false,
            invoices: [],
            selectedInvoices: [],
            selectedInvoiceFileUrls: [],
            selectedAdditionalDocumentUrls: [],
            invoicesLoaded: false
        };
    }

    componentDidMount() {
        // this.fetchFormData();

        const value = queryString.parse(this.props.location.search)
        const state = value.state
        const url = window.location.href

        if (url.includes('reconnect_qb')) {
            this.setState({
                accountingSoftware: true
            })
        }

        if (state === 'qb-invoices') {
            this.props.history.replace('/receivables/invoices/add?reconnect_qb=true')

            this.setState({
                accountingSoftware: true,
                invoiceRefreshLoading: true
            })

            if (value.code && value.realmId) {
                const headers = {
                    businesskey: this.props.aionStore.BusinessUniqueKey
                }

                apiGET(`/financing/oauth/token?url=${url}`, headers, (err, resp) => {
                    try {
                        const token = resp.data.Response;
                        if (resp.data.success) {

                            const data = {
                                accessToken: token.access_token,
                                accessTokenExpiresIn: token.expires_in,
                                accessTokenSecret: "",
                                companyId: value.realmId,
                                accountingSoftware: "QuickBooksOnline",
                                dateCreated: Date.now(),
                                refreshToken: token.refresh_token,
                                refreshTokenExpiresIn: token.x_refresh_token_expires_in,
                                oauthVersion: "2.0",
                                refreshTokens: true
                            }

                            apiPOST('accountingsoftwareinfo', headers, data, (err, resp) => {
                                console.log("accountingsoftwareinfo resp: " + JSON.stringify(resp))
                                if (!err) {
                                    try {
                                        const data = resp.data;

                                        if (data.success) {
                                            this.setState({ qbExpired: false })
                                            message.success('QuickBooks reconnected successfully!')
                                            this.refreshInvoices() // refreshInvoices() will call loadInvoicesForApprovedCustomers()
                                        } else {
                                            message.error('Sorry, we had trouble reconnecting your QuickBooks. Please try again.')
                                            throw Error("Could not complete submission.")
                                        }
                                    } catch (error) {
                                        console.error("The error message is :" + JSON.stringify(error))
                                        message.error('Sorry, we had trouble reconnecting your QuickBooks. Please try again.')
                                    }
                                } else {
                                    console.error("The error message is :" + JSON.stringify(err))
                                    message.error('Sorry, we had trouble reconnecting your QuickBooks. Please try again.')
                                }
                                this.setState({ invoiceRefreshLoading: false })
                            })
                        } else {
                            console.error("financing/oauth/token", token)
                            message.error('Sorry, we had trouble reconnecting your QuickBooks. Please try again.')
                            this.setState({ invoiceRefreshLoading: false })
                            throw Error("Could not fetch attributes.")
                        }
                    } catch (error) {
                        console.error("ERROR", error.stack)
                        message.error('Sorry, we had trouble reconnecting your QuickBooks. Please try again.')
                        this.setState({ invoiceRefreshLoading: false })
                    }
                })
            }
        } else {
            this.loadInvoicesForApprovedCustomers();
        }
    }

    fetchFormData() {
        this.setState({ loadingFormData: true })
        // Fetch customer list
        const headers = {
            businesskey: this.props.aionStore.BusinessUniqueKey
        }
        apiGET('/financing/formdata?customers=true&purchase-orders=true', headers, (err, resp) => {
            this.setState({ loadingFormData: false })
            try {
                const data = resp.data;
                if (data.success) {
                    console.log("form-data", data)
                    this.props.dispatch(addDataToStore(CREDIT_SAVE_DATA, data));
                } else {
                    throw Error("Could not fetch attributes.");
                }
            } catch (error) {
                console.log("ERRR", error.stack)
            }
        })
    }

    submitInvoice = () => {
        this.setState({ confirmLoading: true })
        const headers = {
            businesskey: this.props.aionStore.BusinessUniqueKey
        }
        var formData = this.state.formData;
        formData.selectedInvoiceFileUrls = [];
        formData.invoiceFileList.forEach(file => {
            formData.selectedInvoiceFileUrls.push(formData.allInvoiceFiles[file.name])
        })
        formData.selectedAdditionalDocumentUrls = [];
        formData.additionalDocumentFileList = [];
        formData.additionalDocumentFileList.forEach(file => {
            formData.selectedAdditionalDocumentUrls.push(formData.allAdditionalDocumentFiles[file.name])
        })
        var data = this.state.formData;
        apiPOST('/financing/invoices/add', headers, data, (err, resp) => {
            this.setState({ confirmLoading: false })
            if (!err) {
                try {
                    const data = resp.data;
                    if (data.success) {
                        this.props.form.resetFields();
                        message.success('Invoice submitted successfully!');
                        this.next();
                    } else {
                        throw Error("Could not complete submission.");
                    }
                } catch (error) {
                    alert("Sorry, we had trouble processing your request. Please try again.");
                }
            } else {
                alert("Sorry, we had trouble processing your request. Please try again.");
            }
        })
    }

    submitAccountingSWInvoice = () => {
        var error = false

        this.setState({ confirmLoading: true })

        var selectedInvoices = this.state.selectedInvoices

        // for each invoice, we need at least one invoice attachemnt, and one or more other types
        const attachmentsComplete = (invoice) => {
            if (!invoice.submittedAttachments || invoice.submittedAttachments.length == 0)
                return false;

            let haveInvoiceType = false;
            let haveOtherTypes = false;

            if (this.props.aionStore.Attributes.Businesses.ARSupportingDocNotNeeded === true)
                haveOtherTypes = true;

            invoice.submittedAttachments.forEach(attachment => {
                if (attachment.ObjectType == 'INVOICE')
                    haveInvoiceType = true;
                else if (attachment.ObjectType == 'TIMESHEET' || attachment.ObjectType == 'OTHER')
                    haveOtherTypes = true;
            });

            return haveInvoiceType && haveOtherTypes;
        }

        selectedInvoices.forEach(invoice => {
            console.log("invoice", invoice);
            if (!invoice.poNumber || !attachmentsComplete(invoice)) {
                error = true;
            }
        })

        if (error) {
            this.setState({ confirmLoading: false, showError: true })
        } else {
            const headers = {
                businesskey: this.props.aionStore.BusinessUniqueKey
            }

            var data = {
                selectedInvoices: selectedInvoices
            }

            apiPOST('/financing/customer/invoices/add', headers, data, (err, resp) => {
                this.setState({ confirmLoading: false });
                if (!err) {
                    try {
                        const data = resp.data;
                        if (data.success) {
                            this.setState({ selectedInvoices: [], invoices: [] })
                            message.success('Invoice submitted successfully!');
                            this.next();
                        } else {
                            throw Error("Could not complete submission.");
                        }
                    } catch (error) {
                        alert("Sorry, we had trouble processing your request. Please try again.");
                    }
                } else {
                    alert("Sorry, we had trouble processing your request. Please try again.");
                }
            })
        }
    }

    next() {
        this.setState({ showError: false })
        console.log("this.state.current", this.state.current)
        if (this.state.current === 0) {
            this.props.form.validateFields((err, values) => {
                console.log("err, values", err, values)
                if (err) {
                    return
                }
                if (this.state.accountingSoftware) {
                    if (this.state.selectedInvoices.length == 0) {
                        this.setState({ showError: true, errorMsg: "Please select at least one invoice." })
                        return
                    }
                }
                // console.log('Received values of form: ', values);
                const current = this.state.current + 1;
                this.props.dispatch(addDataToStore(TEMPORARY_FORM_DATA, { add_invoice: this.state.formData }));
                this.setState({ current });
            });
        } else {
            const current = this.state.current + 1;
            this.setState({ current });
        }
    }

    prev() {
        this.setState({ showError: false })
        const current = this.state.current - 1;
        this.setState({
            current,
            invoices: [],
            selectedInvoices: [],
            selectedInvoiceFileUrls: [],
            selectedAdditionalDocumentUrls: []
        });
        if (current == 0)
            this.loadInvoicesForApprovedCustomers();
    }

    // for non-accountingSoftware businesses
    onSelectChange = (value, option) => {
        console.log(`selected ${value}`, option);
        var formData = this.state.formData;
        if (option.props.id === "customer") {
            formData["customerId"] = option.key;
            formData["customerName"] = option.props.value;
        }
        if (option.props.id === "purchase-order") {
            formData["poNumber"] = option.props.value;
            formData["poDocumentUrl"] = option.key;
        }
        console.log(`formData`, formData);
        this.setState({ formData: formData });
    }

    // for accountingSoftware businesses
    loadInvoicesForApprovedCustomers = () => {
        if (!this.state.accountingSoftware)
            return;

        var approvedCustomers = this.props.store.ApprovedCustomers ? this.props.store.ApprovedCustomers : []

        this.setState({ loadingFormData: true, invoicesLoaded: false })

        // Fetch all invoices
        const headers = {
            businesskey: this.props.aionStore.BusinessUniqueKey
        }

        apiGET('/financing/customer/invoices', headers, (err, resp) => {
            this.setState({ loadingFormData: false })
            console.log("/financing/customer/invoices", err, resp)
            try {
                const data = resp.data;
                console.log("/financing/customer/invoices data", data, data.success);
                if (data.success) {
                    // filter in only those invoices with approvedCustomers
                    let invoices = data.Invoices
                    // sort by the name of the customer
                    invoices.sort((a, b) => ((a.CustomerReference || {}).Name).localeCompare((b.CustomerReference || {}).Name))
                    // add a 'key' field to invoice so Table component will work properly
                    invoices = invoices.map((invoice, index) => ({ ...invoice, key: index }));
                    this.setState({ invoices, invoicesLoaded: true });
                } else {
                    throw Error("Could not fetch attributes.");
                }
            } catch (error) {
                console.log("ERRR", error.stack)
                this.setState({ invoicesLoaded: true });
            }
        })
    }

    handleTextChange = (event) => {
        var formData = this.state.formData;
        var ids = event.target.id.split("add_invoice_");
        const field = ids[1];
        formData[field] = event.target.value;
        this.setState({ formData: formData });
    }

    handleFormatting = (value) => {
        if (typeof (value) === "number") {
            return toCurrency(value)
        } else {
            return value
        }
    }

    handleDateChange = (date, dateString) => {
        var formData = this.state.formData;
        formData["dueDate"] = date ? date.toDate() : null;
        this.setState({ formData: formData });
    }

    refreshInvoices() {
        this.setState({ invoiceRefreshLoading: true, invoicesLoaded: false })
        const headers = {
            businesskey: this.props.aionStore.BusinessUniqueKey
        }

        apiGET("/refreshinvoices", headers, (err, resp) => {
            this.setState({ invoiceRefreshLoading: false, invoicesLoaded: false })
            try {
                const data = (resp || {}).data || {};
                console.log("/refreshinvoices", data)
                if (data.success) {
                    this.props.dispatch(addDataToStore(CREDIT_SAVE_DATA, data));
                    this.setState({})
                    this.loadInvoicesForApprovedCustomers();
                } else {
                    if (data.msg = "Tokens Expired") {
                        this.setState({ qbModalVisible: true, qbExpired: true })
                    }
                    throw Error("Could not fetch invoices.")
                }
            } catch (error) {
                console.log("ERRR", error, err, resp)
            }
        })
    }

    reconnectQuickBooks() {
        this.setState({ qbModalVisible: false })
        var oauthClient = new OAuthClient(environment.qboOAuth);

        const authUri = oauthClient.authorizeUri({
            scope: [
                OAuthClient.scopes.Accounting,
                OAuthClient.scopes.OpenId,
                OAuthClient.scopes.Profile,
                OAuthClient.scopes.Email,
                OAuthClient.scopes.Phone,
                OAuthClient.scopes.Address
            ],
            state: 'qb-invoices'
        });

        window.location.href = authUri
    }

    render() {
        const { current } = this.state;
        var approvedCustomers = this.props.store.ApprovedCustomers ? this.props.store.ApprovedCustomers : [];
        var purchaseOrders = this.props.store.PurchaseOrders ? this.props.store.PurchaseOrders : [];

        approvedCustomers.sort((a, b) => (a.DisplayName).localeCompare(b.DisplayName))

        var customerList = approvedCustomers.map(customer => {
            return <Option id="customer" value={getCustomerName(customer)} key={customer.Id}>{getCustomerName(customer)}</Option>
        });

        var purchaseOrdersList = purchaseOrders.map(item => {
            var customerRef = item.CustomerRef ? item.CustomerRef : {};
            return <Option id="purchase-order" value={item.PurchaseOrder} key={item.DocumentUrl}>#{item.PurchaseOrder} - {customerRef.name}</Option>
        })

        const uploadProps = {
            name: `po-${this.state.poNumber}-${this.state.customer}-${moment().format('MM-DD-YY')}`,
            multiple: false,
            action: `${environment.apiBaseUrl}/financing/upload`,
            headers: {
                businesskey: this.props.aionStore.BusinessUniqueKey,
                type: "invoices",
                jwt: this.props.aionStore.jwt
            },
            onChange: (info) => {
                const { status } = info.file;
                if (status !== 'uploading') { // File was removed
                    var formData = this.state.formData;
                    formData.invoiceFileList = info.fileList;
                }
                if (status === 'done') {
                    console.log("info.file", info.file);
                    var formData = this.state.formData;
                    formData.invoiceFileList = info.fileList;
                    formData.documentUrls = formData.documentUrls || [];
                    (info.file.response.SavedDocumentUrls || []).forEach(url => {
                        formData.documentUrls.push(url);
                    })

                    formData.allInvoiceFiles = formData.allInvoiceFiles || {};
                    formData.allInvoiceFiles[info.file.name] = {
                        Name: info.file.name,
                        Url: info.file.response.SavedDocumentUrls[0],
                        Date: info.file.response.Date
                    };
                    formData.file = info.file;
                    this.setState({
                        formData: formData
                    })
                    message.success(`${info.file.name} file uploaded successfully.`);
                } else if (status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
        };

        const uploadAdditionalDocumentsProps = {
            name: `po-${this.state.poNumber}-${this.state.customer}-${moment().format('MM-DD-YY')}`,
            multiple: false,
            action: `${environment.apiBaseUrl}/financing/upload`,
            headers: {
                businesskey: this.props.aionStore.BusinessUniqueKey,
                type: "invoices",
                jwt: this.props.aionStore.jwt
            },
            onChange: (info) => {
                const { status } = info.file;
                if (status !== 'uploading') { // File was removed
                    var formData = this.state.formData;
                    formData.additionalDocumentFileList = info.fileList;
                }
                if (status === 'done') {
                    var formData = this.state.formData;

                    formData.additionalDocumentUrls = formData.additionalDocumentUrls || [];
                    (info.file.response.SavedDocumentUrls || []).forEach(fileUrl => {
                        formData.additionalDocumentUrls.push(fileUrl);
                    })
                    formData.allAdditionalDocumentFiles = formData.allAdditionalDocumentFiles || {};
                    formData.allAdditionalDocumentFiles[info.file.name] = {
                        Name: info.file.name,
                        Url: info.file.response.SavedDocumentUrls[0],
                        Date: info.file.response.Date
                    };
                    formData.file = info.file;
                    this.setState({
                        formData: formData
                    })
                    message.success(`${info.file.name} file uploaded successfully.`);
                } else if (status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
        };

        const invoiceSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                var selectedInvoices = []

                selectedRows.forEach(invoice => {
                    var POs = getPOsForCustomer(invoice.CustomerAncestorId);

                    var selectedInvoice = {
                        id: invoice.Id,
                        docNumber: invoice.DocNumber,
                        customerId: (invoice.CustomerReference || {}).Value,
                        customerName: invoice.CustomerAncestorName || (invoice.CustomerReference ? invoice.CustomerReference.Name : "NA"),
                        customerAncestorId: invoice.CustomerAncestorId,
                        totalAmt: invoice.TotalAmt,
                        balance: invoice.Balance,
                        date: (invoice.Sync || {}).CreateTime,
                        dueDate: invoice.DueDate,
                        poNumber: null, //POs.length ? POs[POs.length - 1].PONumber: null,  // pre-select the last PO // Uncomment for auto po selection
                        documentUrls: [],
                        additionalDocumentUrls: [],
                        attachments: invoice.Attachments || [],
                        submittedAttachments: invoice.Attachments || []
                    }

                    selectedInvoices.push(selectedInvoice)
                })

                this.setState({ selectedInvoices })
            },
            getCheckboxProps: record => ({
                disabled: record.name === 'Disabled User',
                // Column configuration not to be checked
                name: record.name,
            }),
        };

        const getPOsForCustomer = (customerId) => {
            const purchaseOrders = this.props.store.PurchaseOrders ? this.props.store.PurchaseOrders : [];
            // filter in only those POs belonging to this customer and are approved
            return purchaseOrders.filter(po => po.CustomerId == customerId && (po.VerificationStatus || "").toUpperCase() == 'VALIDATED');
        }

        const handleSelectPO = (value, invoiceId) => {
            this.setState({
                selectedInvoices: this.state.selectedInvoices.map(el => (el.id === invoiceId ? { ...el, poNumber: value } : el))
            });
        }

        const handleSelectFileType = (value, invoiceId, indexAttachmemt) => {
            let indexInvoice = this.state.selectedInvoices.findIndex(invoice => invoice.id == invoiceId);
            this.setState({
                selectedInvoices: update(this.state.selectedInvoices, {
                    [indexInvoice]: { submittedAttachments: { [indexAttachmemt]: { ObjectType: { $set: value } } } }
                })
            });
        }

        const handleUploadFile = (docNumber, info) => {
            // append the uploaded files to the submittedAttachments field
            let indexInvoice = this.state.selectedInvoices.findIndex(invoice => invoice.docNumber == docNumber);
            this.setState({
                selectedInvoices: update(this.state.selectedInvoices, {
                    [indexInvoice]: {
                        submittedAttachments: {
                            $push: [
                                { URI: (info.file.response.SavedDocumentUrls || []).slice(-1).pop(), FileName: info.file.name, Uploaded: true }
                            ]
                        }
                    }
                })
            });
        }

        const handleRemoveAttachment = (invoiceId, indexAttachmemt) => {
            let indexInvoice = this.state.selectedInvoices.findIndex(invoice => invoice.id == invoiceId);
            this.setState({
                selectedInvoices: update(this.state.selectedInvoices, {
                    [indexInvoice]: { submittedAttachments: { $splice: [[indexAttachmemt, 1]] } }
                })
            });
        }

        const { form } = this.props;
        const { getFieldDecorator } = form;
        const { formLayout } = this.state;

        const formItemLayout =
            formLayout === 'horizontal'
                ? {
                    labelCol: { span: 4, offset: 4 },
                    wrapperCol: { span: 8 },
                }
                : null;

        const buttonItemLayout =
            formLayout === 'horizontal'
                ? {
                    wrapperCol: { span: 28, offset: 0 },
                }
                : null;

        const step0Columns = [
            {
                title: 'Select all invoices',
                render: (field, invoice, index) => (
                    <div className="flex-container text-align-left justify-content-center">
                        <InvoiceSelectionCard invoice={invoice} />
                    </div>
                ),
            }
        ];

        const step1Columns = [
            {
                title: 'Invoice',
                key: 'id',
                align: 'center',
                showHeader: 'false',
                render: (field, invoice, index) => (
                    <div className="flex-container text-align-left justify-content-center">
                        <InvoiceSubmissionCard
                            invoice={invoice}
                            POs={getPOsForCustomer(invoice.customerAncestorId)}
                            onSelectPO={handleSelectPO}
                            onSelectFileType={handleSelectFileType}
                            onUploadFile={handleUploadFile}
                            onRemoveAttachment={handleRemoveAttachment}
                        />
                    </div>
                ),
            }
        ];

        const steps = (count) => {
            const tempFormData = this.props.aionStore.TempFormData ? this.props.aionStore.TempFormData : {};
            const formData = tempFormData["add_invoice"] ? tempFormData["add_invoice"] : {};
            const { accountingSoftware } = this.state

            switch (count) {
                case 0:
                    if (accountingSoftware) {
                        return {
                            title: 'Select Invoice',
                            content: (
                                <div style={{ padding: '0 30px' }} className="borderless-table">
                                    <p style={{ color: SYSTEM_COLORS.systemGray, marginBottom: '35px', marginLeft: '40px' }} className="p-large">Below are all your unsubmitted invoices. Select invoices for submission.</p>
                                    <div className="flex-container" style={{ margin: '10pt 0 20pt 0' }}>
                                        <Button
                                            style={{ marginLeft: '30pt', float: 'right' }}
                                            type="primary"
                                            size="large"
                                            loading={this.state.invoiceRefreshLoading}
                                            onClick={() => { this.refreshInvoices() }}>
                                            Refresh from QuickBooks
                                        </Button>
                                    </div>
                                    <Skeleton loading={!this.state.invoicesLoaded} active title={false} paragraph={{ rows: 4 }}>
                                        <Table
                                            rowKey='InvoiceId'
                                            rowSelection={{
                                                type: 'checkbox',
                                                ...invoiceSelection,
                                            }}
                                            bordered={false}
                                            tableLayout='auto'
                                            columns={step0Columns}
                                            dataSource={this.state.invoices}
                                            scroll={{ y: '700px', x: '100%' }}
                                        />
                                    </Skeleton>
                                    <div className="flex-container justify-content-center">
                                        {this.state.showError ? <Alert
                                            style={{ width: '100%', marginTop: '20pt' }}
                                            message="Required fields"
                                            description={this.state.errorMsg}
                                            type="error"
                                            showIcon
                                        /> : null}
                                    </div>
                                </div>
                            )
                        }
                    } else {
                        return {
                            title: 'Invoice Details',
                            content: (
                                <Form layout={formLayout} size='large'>
                                    <Form.Item label="Customer" {...formItemLayout}>
                                        {getFieldDecorator('customer', {
                                            valuePropName: 'option',
                                            rules: [{ required: true, message: 'Please select a customer' }]
                                        })(
                                            <Select
                                                showSearch
                                                placeholder="Select a customer"
                                                optionFilterProp="children"
                                                onChange={this.onSelectChange}
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                loading={this.state.loadingFormData}
                                                disabled={this.state.loadingFormData}
                                                dropdownRender={menu => (
                                                    <div>
                                                        {menu}
                                                        <Divider style={{ margin: '4px 0' }} />
                                                        <div style={{ padding: '8px', cursor: 'pointer' }}>
                                                            <Link to="/receivables/customers/add">
                                                                <PlusOutlined />
                                                                <span> Add new</span>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                )}
                                            >
                                                {customerList}
                                            </Select>
                                        )}
                                    </Form.Item>
                                    {/* <Form.Item label="Purchase order" {...formItemLayout}>
                                        {getFieldDecorator('po', {
                                            valuePropName: 'option',
                                            rules: [{ required: false, message: 'Please select a purchase order' }]
                                        })(
                                            <Select
                                                showSearch
                                                // style={{ width: 200 }}
                                                placeholder="Select a purchase order"
                                                optionFilterProp="children"
                                                onChange={this.onSelectChange}
                                                filterOption={(input, option) => {
                                                    console.log("option", option.props.children, input.toLowerCase())
                                                    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }}
                                                loading={this.state.loadingFormData}
                                                disabled={this.state.loadingFormData}
                                                dropdownRender={menu => (
                                                    <div>
                                                        {menu}
                                                        <Divider style={{ margin: '4px 0' }} />
                                                        <div style={{ padding: '8px', cursor: 'pointer' }}>
                                                            <Link to={{ pathname: "/receivables/invoices", state: { showAddPO: true } }}>
                                                                <PlusOutlined />
                                                                <span> Add purchase order</span>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                )}
                                            >
                                                {purchaseOrdersList}
                                            </Select>
                                        )}
                                    </Form.Item> */}
                                    <Form.Item label="Invoice number" {...formItemLayout}>
                                        {getFieldDecorator('invoiceId', {
                                            rules: [{ required: true, message: 'Please add a invoice number' }]
                                        })(
                                            <Input onChange={this.handleTextChange} addonBefore="#" placeholder="738377" />
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Total amount" {...formItemLayout}>
                                        {getFieldDecorator('invoiceTotal', {
                                            rules: [{ required: true, message: 'Please add the total value of the invoice' }]
                                        })(
                                            <Input onChange={this.handleTextChange} addonBefore="$" placeholder="100,000" />
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Balance" {...formItemLayout}>
                                        {getFieldDecorator('invoiceBalance', {
                                            rules: [{ required: true, message: 'Please add the balance amount for your invoice' }]
                                        })(
                                            <Input onChange={this.handleTextChange} addonBefore="$" placeholder="50,000" />
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Due date" {...formItemLayout}>
                                        {getFieldDecorator('dueDate', {
                                            rules: [{ required: true, message: 'Please add a due date' }]
                                        })(
                                            <DatePicker onChange={this.handleDateChange} />
                                        )}
                                    </Form.Item>

                                    {/* Invoice document upload */}
                                    <Form.Item label="Invoice file" {...formItemLayout}>
                                        {getFieldDecorator('invoice', {
                                            rules: [{ required: true, message: 'Invoice document needs to be uploaded' }]
                                        })(
                                            <Dragger {...uploadProps}>
                                                <p className="ant-upload-drag-icon">
                                                    <InboxOutlined />
                                                </p>
                                                <div style={{ padding: '5px' }}>
                                                    <p className="ant-upload-text">Click or drag file to this area to upload your invoice</p>
                                                    <p className="ant-upload-hint">
                                                        You can either upload a pdf, png or jpeg file
                                                    </p>
                                                </div>

                                            </Dragger>
                                        )}
                                    </Form.Item>

                                    {/* Additional document upload */}
                                    <Form.Item label="Additional documents" {...formItemLayout}>
                                        {getFieldDecorator('additional-documents', {
                                            rules: [{ required: false, message: '' }]
                                        })(
                                            <Dragger {...uploadAdditionalDocumentsProps}>
                                                <p className="ant-upload-drag-icon">
                                                    <InboxOutlined />
                                                </p>
                                                <div style={{ padding: '5px' }}>
                                                    <p className="ant-upload-text">Click or drag file to this area to upload your files</p>
                                                    <p className="ant-upload-hint">
                                                        You can either upload a pdf, excel, png or jpeg file
                                                    </p>
                                                </div>

                                            </Dragger>
                                        )}
                                    </Form.Item>
                                </Form>
                            )
                        }
                    }
                case 1:
                    if (accountingSoftware) {
                        // console.log('selectedInvoices: ', JSON.stringify(this.state.selectedInvoices))
                        let errorText = "You must provide a PO number, an invoice file, and at least one supporting document for each invoice.";

                        if (this.props.aionStore.Attributes.Businesses.ARSupportingDocNotNeeded === true)
                            errorText = "You must provide a PO number and an invoice file for each invoice.";

                        return {
                            title: 'Submit Invoices',
                            content: (
                                <div style={{ margin: '0px 30px' }} id="invoices-file-upload" className="borderless-table">
                                    <p style={{ color: SYSTEM_COLORS.systemGray, marginBottom: '35px', marginLeft: '40px' }} className="p-large">Invoices submitted here will be sent to your customer.</p>
                                    <Table
                                        rowKey='id'
                                        bordered={false}
                                        showHeader={false}
                                        tableLayout='auto'
                                        columns={step1Columns}
                                        dataSource={this.state.selectedInvoices}
                                        scroll={{ y: '700px', x: '100%' }}
                                    />
                                    <div className="flex-container justify-content-center">
                                        {this.state.showError ? <Alert
                                            style={{ width: '100%', marginTop: '10px' }}
                                            message="Required files"
                                            description={errorText}
                                            type="error"
                                            showIcon
                                        /> : null}
                                    </div>
                                </div>
                            )
                        }
                    } else {
                        var selectedInvoiceFiles = "";
                        var selectedAdditionalDocumentFiles = "";
                        if (formData.invoiceFileList) {
                            var ic = 0
                            formData.invoiceFileList.forEach(file => {
                                ic++;
                                if (ic < formData.invoiceFileList.length) {
                                    selectedInvoiceFiles += `${file.name}, `;
                                } else {
                                    selectedInvoiceFiles += `${file.name}`;
                                }
                            })
                        }
                        if (formData.additionalDocumentFileList) {
                            var ac = 0
                            formData.additionalDocumentFileList.forEach(file => {
                                ac++;
                                if (ac < formData.additionalDocumentFileList.length) {
                                    selectedAdditionalDocumentFiles += `${file.name}, `;
                                } else {
                                    selectedAdditionalDocumentFiles += `${file.name}`;
                                }
                            })
                        }


                        return {
                            title: 'Review',
                            // CONTENT BLOCK FOR STEP 2
                            content: (
                                <div style={{ padding: '0 30px' }}>
                                    <Descriptions
                                        title="Review and click submit"
                                        bordered
                                        column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                                    >
                                        <Descriptions.Item label="Name">{formData.customerName}</Descriptions.Item>
                                        <Descriptions.Item label="Invoice #">{formData.invoiceId}</Descriptions.Item>
                                        <Descriptions.Item label="Purchase order #">{formData.poNumber}</Descriptions.Item>
                                        <Descriptions.Item label="Total amount">${toCurrency(formData.invoiceTotal)}</Descriptions.Item>
                                        <Descriptions.Item label="Balance">${toCurrency(formData.invoiceBalance)}</Descriptions.Item>
                                        <Descriptions.Item label="Due date">{moment(formData.dueDate).format('ll')}</Descriptions.Item>
                                        <Descriptions.Item label="Invoice File uploaded">{formData.invoiceFileList ? `${selectedInvoiceFiles}` : null}</Descriptions.Item>
                                        <Descriptions.Item label="Additional documents uploaded">{formData.additionalDocumentFileList ? `${selectedAdditionalDocumentFiles}` : null}</Descriptions.Item>
                                    </Descriptions>
                                </div>
                            )
                        }
                    }
                case 2:
                    return {
                        title: 'Confirmation',
                        // CONTENT BLOCK FOR STEP 3
                        content: (
                            <Result
                                status="success"
                                title="Successfully submitted invoice!"
                                subTitle={`Invoice will be emailed to your customer.`}
                                extra={[
                                    <Link to="/receivables/invoices" key="invoices">
                                        <Button size="large" type="primary">
                                            Done
                                        </Button>
                                    </Link>,
                                    <Button size="large" key="add-more" onClick={() => { this.setState({ current: 0 }); this.loadInvoicesForApprovedCustomers() }} >Upload more</Button>
                                ]}
                            />
                        ),
                    }
            }
        }
        var totalSteps = [0, 1, 2];
        const routes = [
            {
                path: '/receivables',
                breadcrumbName: 'Dashboard',
            },
            {
                path: '/receivables/invoices',
                breadcrumbName: 'Invoices',
            },
            {
                path: '/receivables/invoices/add',
                breadcrumbName: 'Add',
            },
        ];
        return (
            <div>
                <PageHeader onBack={() => window.history.back()} titleText="Invoices" subTitle="" />
                <div>
                    <Steps style={{ padding: '30px' }} current={current}>
                        {totalSteps.map(step => {
                            const item = steps(step)
                            return <Step key={item.title} title={item.title} />
                        })}
                    </Steps>
                    <div className="steps-content">
                        {steps(this.state.current).content}
                    </div>
                    <Form.Item style={{ paddingBottom: '30px' }}>
                        <div className="steps-action flex-container justify-content-center">
                            {(current === 0) && (
                                <Button size="large" type="primary" onClick={() => this.next()}>
                                    Next
                                </Button>
                            )}
                            {(current === 1) && (
                                <Button size="large" loading={this.state.confirmLoading} type="primary" onClick={this.state.accountingSoftware ? this.submitAccountingSWInvoice : this.submitInvoice}>
                                    Submit
                                </Button>
                            )}
                            {(current === 1) && (
                                <Button size="large" style={{ marginLeft: 8 }} onClick={() => this.prev()}>
                                    Back
                                </Button>
                            )}
                        </div>
                    </Form.Item>
                </div>
                <Modal
                    visible={this.state.qbModalVisible}
                    title="Reconnect QuickBooks"
                    okText="Save"
                    onCancel={() => (this.setState({ qbModalVisible: false }))}
                    onOk={this.saveCustomer}
                    confirmLoading={this.state.confirmLoading}
                    destroyOnClose={true}
                    width={700}
                    footer={null}
                >
                    <div className="flex-column-center" style={{ textAlign: 'center', paddingBottom: '20pt' }}>
                        <Text strong={true} style={{ fontSize: '12pt', marginBottom: '12pt' }}>We need you to reconnect your QuickBooks account<br />before you can refresh your invoices</Text>
                        <Banner title="QuickBooks Master admin or Company admin rights required" message="Make sure you have the right permissions. You will need master admin or company rights to connect to QuickBooks" />
                        <br />
                        <Text>You will be redirected to QuickBooks to provide authorization to Aion</Text>
                        <Text style={{ margin: '32pt 0 12pt 0' }}>Tap on the button below to<br />reconnect you QuickBooks account</Text>
                        <img style={{ height: '40pt' }} src={qbButton} onClick={() => { this.reconnectQuickBooks() }} />
                        <br />
                        <Alert message="We do not store or have access to your QuickBooks credentials" type="info" />
                    </div>
                </Modal>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.creditAppReducer,
        aionStore: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'add_invoice' })(AddInvoice)));