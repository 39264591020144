import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import { getDateInLocalTZ, toAbsCurrency } from '../../../Utils/util'
import { Flex } from '../../Reusable/Container'
import { Text } from '../../Reusable/Text'
import { InitialCircle } from '../../Reusable/InitialCircle'
import { TrendingUp, TrendingDown } from 'lucide-react'
import { ScrollableTableView } from './ScrollableTableView'
import FormattedIcon from './FormattedIcon'
import moment from 'moment'

class CardSpendList extends Component {
    state = {
        spendByCard: this.props.cardSpendReport?.spendByCard
    }

    columns = [
        {
            title: 'Name',
            dataIndex: 'card',
            key: 'name',
            render: (card, record, i) => (
                <Flex start centerHorizontally gap='8px' wrap>
                    <InitialCircle name={card?.nickName} size={30} useFirstWordOnly={true} colorInd={i} />
                    <Text>{card?.nickName} • {card?.last4}</Text>
                </Flex>
            )
        },
        {
            title: 'Percent change',
            dataIndex: 'totalChangePercent',
            key: 'totalChangePercent',
            render: (totalChangePercent, record) => (
                <Flex start centerHorizontally gap='8px' wrap>
                    <FormattedIcon 
                        icon={totalChangePercent >= 0 ? TrendingUp : TrendingDown}
                        size={25} 
                        title="Trend"
                    />
                    <Text>{totalChangePercent}% vs {getDateInLocalTZ(moment().subtract(1, 'M'), "MMMM")}</Text>
                </Flex>
            ),
            width: '180px'
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            align: 'right',
            render: (total, record) => <span style={{ fontWeight: 600 }}>${toAbsCurrency(total)}</span>,
            width: '120px'
        }
    ]

    render() {
        const { spendByCard } = this.state

        return (
            <ScrollableTableView
                id="spendByCard"
                columns={this.columns}
                dataSource={spendByCard}
                rowKey='total'
                loading={false}
                borderView
                hideHeader
                scroll={{ y: 275 }} // Enable vertical scrolling
                pagination={false}
            />
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(CardSpendList)))