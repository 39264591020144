import React, { useState } from 'react';
import { Text, Tag } from '../../Reusable/Text';
import { LabeledInput } from '../../Reusable/Input';
import { TextButton } from '../../Reusable/Button'
import { Image } from '../../Reusable/Image'
import moment from 'moment'
import { toCurrency } from '../../../Utils/util'
import { Flex, FlexColumn, Container, Card, CardContainer } from '../../Reusable/Container';
import Arrow from '../../../Images/arrow-right.png'
import Profile from '../../../Images/merchant-profile.svg'
import Phone from '../../../Images/merchant-phone.svg'
import Email from '../../../Images/merchant-email.svg'
import PayCheck from '../../../Images/pay-check.svg'
import Void from '../../../Images/void.png'


const InvoicePaid = ({ invoice, width, height, textWidth, amount, businessName }) => {

    return (
        <CardContainer height={height} width={width} fullWidth={!width} padding='12px 24px 24px'>
            <FlexColumn fullWidth fullHeight center>
                <Image src={Void} width='80px' height='80px' />
                <Text heading weight='600' size='32px' margin='24px 0 8px'>Invoice voided</Text>
                <Text size='16px' center width={textWidth}>This invoice has been voided. Please contact your vendor.</Text>
            </FlexColumn>
        </CardContainer>
    );
};

export default InvoicePaid;
