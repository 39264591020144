import styled from "styled-components";
import TableView from "../../Reusable/TableView";

export const ScrollableTableView = styled(TableView)`
    .ant-table-container {
        max-height: 275px;
        overflow-y: scroll !important; // Force scrollbar to always be present
    }

    .ant-table-body {
        max-height: 100% !important;
        overflow-y: scroll !important; // Ensure scrollbar is visible on the table body
    }

    /* Force scrollbar to be always visible */
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, .5);
        box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }

    /* Scrollbar styling for Firefox */
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, .5) transparent;

    // Hide the first row if it's empty
    .ant-table-tbody > tr:first-child:not([data-row-key]) {
        display: none;
    }

    // Ensure consistent styling for data rows
    .ant-table-tbody > tr {
        height: 55px; // Adjust based on your design
    }

    // Remove bottom border from last row
    .ant-table-tbody > tr:last-child > td {
        border-bottom: none;
    }
`;