import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'
import styled from 'styled-components'

import { message, Upload, Modal, Checkbox } from 'antd'

// Components
import { Text } from '../../Reusable/Text'
import { FlexColumn, Flex } from '../../Reusable/Container'
import { TextButton } from '../../Reusable/Button'
import environment from '../../../environment'
import LUploadIcon from '../../../Images/upload-bill-icon/blue.png'
import DUploadIcon from '../../../Images/upload-bill-icon/dark.png'
import { DownloadOutlined, } from '@ant-design/icons'
import Result from '../../Reusable/Result'
import ModalClose from '../../../Images/modal-close.png'

export const CardButton = styled.div`
    margin-top: 18px;
    background-color: rgb(255, 255, 255);
    border-radius: 0.5rem;
    width: 450px;
    margin-bottom: 14px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    opacity: 1;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.1);
    padding: 1.8rem;
    transition-property: box-shadow;
    transition-duration: 300ms;
    &.selected {
        cursor: pointer;
        box-shadow: 0 3px 15px rgb(0 0 0 / 0.2);
    };
    &.selected:hover {
        cursor: pointer;
        box-shadow: 0 3px 15px rgb(0 0 0 / 0.4);
    };
`

export const CardButtonText = styled.span`
    font-size: 18px;
    font-weight: 400;
`

class ACHBatchUpload extends Component {

    state = {
        loading: false,
        file: null,
        fileName: '',
        showErrorModal: false,
        fileList: [],
        certifyChecked: true
    }

    render() {
        const { certifyChecked, responseErrorMessage, uploadBatchPaymentInfo } = this.state
        const { theme, aionStore } = this.props
        const darkMode = (theme.type === "dark")

        const { UserInfo } = aionStore
        var certifiedBy = `${UserInfo.FirstName} ${UserInfo.LastName}`

        var uploadProps = {
            accept: ".csv",
            beforeUpload: file => {
                console.log('before upload', file)
                this.setState({ polling: true, })
                return
            },
            multiple: false,
            action: `${environment.bbBaseUrl}/bb/batchpayment/uploadACH`,
            data: (file) => {
                return {
                    File: file,
                    CertifiedBy: certifiedBy,
                    Notes: null,
                }
            },
            headers: {
                AionCurrentBiz: this.props.aionStore.BusinessUniqueKey,
                AionAuth: this.props.aionStore.UAM.encryptedAuthHeader,
            },
            multiple: false,
            onChange: (info) => {
                var { file } = info
                this.setState({ fileList: [file] })
                const { status, response } = file

                console.log('status', status, response)
                if (status === "done") {
                    console.log('bb/batchpayment/uploadACH done', response)

                    if (response.result) {
                        if (response.uploadBatchPaymentInfo.newCustomers && response.uploadBatchPaymentInfo.newCustomers.length > 0) {
                            this.setState({
                                showErrorModal: true,
                                fileList: [],
                                responseErrorMessage: null,
                                uploadBatchPaymentInfo: response.uploadBatchPaymentInfo
                            })
                        } else {
                            console.log('bb/batchpayment/uploadACH job id', response.uploadJobId)
                            message.success("Batch uploaded successfully!")
                            this.props.submitComplete(response.uploadJobId)
                        }
                    } else {
                        console.log("bb/batchpayment/uploadACH response", JSON.stringify(response))
                        this.setState({
                            showErrorModal: true,
                            fileList: [],
                            responseErrorMessage: response.responseMessage,
                            uploadBatchPaymentInfo: response.uploadBatchPaymentInfo
                        })
                    }
                } else if (status === "error") {
                    console.log("bb/batchpayment/uploadACH file", JSON.stringify(file))
                    this.setState({ showErrorModal: true, fileList: [] })
                }
            }
        }

        var uploadTitle = "Upload Error"
        var errorData
        var errorMsg
        var onSubmit

        let showHeaderErrorTable = uploadBatchPaymentInfo && uploadBatchPaymentInfo.headerErrors && uploadBatchPaymentInfo.headerErrors.length > 0
        let errors = []
        // let errorMessage = this.stat>"?e.errorMessage
        if (uploadBatchPaymentInfo && uploadBatchPaymentInfo.headerErrors && uploadBatchPaymentInfo.headerErrors.length > 0) {
            errors = uploadBatchPaymentInfo.headerErrors.map((x, i) => {
                return { ...x, key: i }
            })
            // errorMessage = "The file is missing required column headers"
        } else if (uploadBatchPaymentInfo && uploadBatchPaymentInfo.valueErrors && uploadBatchPaymentInfo.valueErrors.length > 0) {
            errors = uploadBatchPaymentInfo.valueErrors.map((x, i) => {
                return { ...x, key: i }
            })
        }
        var errorMessage = "The file you uploaded contains the below errors. Please correct the errors and retry."

        let errorNum = 0
        if (uploadBatchPaymentInfo && uploadBatchPaymentInfo.recordsErrorCount) {
            errorNum = uploadBatchPaymentInfo.recordsErrorCount
        }
        
        let successNum = 0
        if (uploadBatchPaymentInfo && uploadBatchPaymentInfo.recordsTotalCount && uploadBatchPaymentInfo.recordsErrorCount) {
            successNum = uploadBatchPaymentInfo.recordsTotalCount - uploadBatchPaymentInfo.recordsErrorCount
        }

        return (
            <>
                <FlexColumn style={{ padding: '0px 20px 30px' }}>
                    <Text heading>Upload and initiate ACH batch</Text>
                    <Text noMargin>Download the below CSV template, and fill with appropriate banking transfer details</Text>

                    <TextButton margin='12px 0 0' id="downloadTemplate" icon={<DownloadOutlined />} text={<a href={this.props.downloadLink || "/Aion_ACH_Batch_Template.csv"} download={this.props.downloadName || "Aion_ACH_Batch_Template.csv"}>Download CSV Template</a>} />

                    <div style={{ display: "flex", flexDirection: 'column', alignItems: "center", marginTop: '30px' }} >
                        <Upload disabled={!certifyChecked} {...uploadProps} fileList={this.state.fileList}>
                            <CardButton className={certifyChecked && "selected"}>
                                <img src={darkMode ? DUploadIcon : LUploadIcon} width="25" height="25" style={{ marginRight: "15px", opacity: 0.8 }} />
                                <FlexColumn start>
                                    <CardButtonText>Upload File</CardButtonText>
                                    <Text color={theme.colors.systemGray}>Upload file as per CSV template provided. Please review the transfer details carefully. Transfers will be automatically initiated if the file contents are valid. You can easily view details of initiated transfers on the Transfers page.</Text>
                                </FlexColumn>
                            </CardButton>
                        </Upload>
                        {/* <Checkbox style={{ width: 450, marginTop: 12 }} onChange={(e) => this.setState({ certifyChecked: e.target.checked })}>Check this box to certify that all of the invoices provided in your uploaded file are true and correct as of the date hereof.</Checkbox> */}
                    </div>
                </FlexColumn>
                <Modal
                    visible={this.state.showErrorModal}
                    footer={null}
                    closable={true}
                    width={750}
                    style={{ top: 80 }}
                    destroyOnClose={true}
                    onCancel={() => {
                        this.setState({ showErrorModal: false, responseErrorMessage: null, uploadBatchPaymentInfo: null })
                    }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <Result
                        uploadTypeTitle={uploadTitle}
                        showHeaderErrorTable={showHeaderErrorTable}
                        errors={errors}
                        errorNum={errorNum}
                        successNum={successNum}
                        errorMessage={errorMessage}
                        responseErrorMessage={responseErrorMessage}
                        errorColumnTitle="Name"
                        errorData={errorData}
                        errorMsg={errorMsg}
                        ctaText="ACH Batch Upload"
                        onSubmit={onSubmit}
                        ignoreNameColumn={true}
                    />
                </Modal>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(ACHBatchUpload))