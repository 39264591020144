import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';
import moment from 'moment';
import _ from 'lodash';

import {
    Skeleton,
    Space,
    Table,
    Tooltip,
} from 'antd';

// Util
import { Tag, Text } from '../../../Reusable/Text';
import { Flex, FlexColumn } from '../../../Reusable/Container';
import { capitalize } from 'lodash';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

const CreditStatusTag = (options) => {
    let statusMsg;
    let statusColor;
    let { invoice, theme } = options;
    switch (invoice.creditStatus) {
        case "REQUESTED":
            statusColor = theme.colors.secondary3;
            statusMsg = "Sent for Verification";
            break
        case "INREVIEW":
            statusColor = theme.colors.secondary1;
            statusMsg = "In Review";
            break;
        case "VALIDATED":
        case "ELIGIBLE_FOR_ADVANCE":
            statusColor = theme.colors.primary;
            statusMsg = "Eligible for Advance";
            break;
        case "REJECTED":
            statusColor = "red";
            statusMsg = "Rejected";
            break;
        case "INELIGIBLE_FOR_ADVANCE":
            statusColor = "red";
            statusMsg = "Ineligible for Advance";
            break;
        case "ADVANCED":
            statusColor = theme.colors.primary;
            statusMsg = "Advanced";
            break;
        default:
            break
    }
    if ((invoice.CreditStatus != "ADVANCED") && (moment(invoice.DueDate).diff(moment()) < 0)) {
        statusColor = theme.colors.secondary7;
        statusMsg = "Expired";
    }
    return statusMsg ? <Tooltip title="Credit status"><Tag tagpreset={statusColor}>{statusMsg}</Tag></Tooltip> : null
}

class InvoiceTable extends Component {
    state = {
    };

    selectRow = (record) => {
        this.props.showSelectedInvoice(record);
    }

    handleTableChange = (pagination, filters, sorter) => {
        this.props.fetchInvoices({ pagination: pagination, sorter: sorter });
    }

    getRowDetails = (invoice) => {
        var { creditStatus, verificationStatus, reviewer, reviewedDate, reviewerNote } = invoice;
        const { theme } = this.props;
        const dateFormat = "MM/DD/YY";
        const approvalStatus = this.getApprovalStatus(creditStatus, verificationStatus);

        return (
            (approvalStatus != 'Pending') ? <Skeleton loading={this.state.loadingTrackLog} active title={false} paragraph={{ rows: 3 }}>
                <Flex style={{ padding: "20px", background: theme.body, justifyContent: "space-around", alignItems: 'flex-start' }}>
                    <FlexColumn style={{ width: '40%' }}>
                        <div style={{ fontWeight: 'bold', fontSize: '16px', marginBottom: '10px' }}>Invoice approvals</div>
                        <div>
                            <div style={{ color: theme.colors.secondary4 }}>{approvalStatus} by:</div>
                            <div>{reviewer}</div>
                        </div>
                        <div style={{ marginTop: '10px' }}>
                            <div style={{ color: theme.colors.secondary4 }}>{approvalStatus} on:</div>
                            <div>{moment(reviewedDate).format(dateFormat)}</div>
                        </div>
                    </FlexColumn>
                    <FlexColumn>
                        <div style={{ fontWeight: 'bold', fontSize: '16px', marginBottom: '10px' }}>Reviewer note</div>
                        <div>{reviewerNote}</div>
                    </FlexColumn>
                </Flex>
            </Skeleton> : <></>
        )
    }

    handleRowSelection = (selectedRowKeys, selectedRows) => {
        this.props.handleRowSelection(selectedRowKeys, selectedRows);
    }

    getApprovalStatus = (creditStatus, verificationStatus) => {
        let approvalStatus = "";
        if (verificationStatus === 'VALIDATED') {
            approvalStatus = "Approved";
        } else if (verificationStatus === 'REJECTED') {
            approvalStatus = "Rejected";
        } else if (creditStatus === 'INREVIEW') {
            approvalStatus = "Pending";
        }
        return approvalStatus;
    }

    render() {
        const { theme, aionStore, loading, pagination, invoices, showRowSelection } = this.props;

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });

        const StatusTag = (options) => {
            let { invoice } = options;
            const { invoiceStatus, emailStatus, cred } = invoice;
            var showCreditStatus;
            const attributes = aionStore.Attributes || {};
            const businessAttributes = attributes.Businesses || {};
            if (businessAttributes.ARAgreementSigned && invoice.creditStatus) {
                showCreditStatus = <CreditStatusTag theme={theme} invoice={invoice} />
            }
            return (
                <Space style={{ width: "120px" }} wrap>
                    <Tooltip title="Invoice status"><Tag tagpreset={theme.colors.secondary7}>{capitalize(invoiceStatus)}</Tag></Tooltip>
                    {emailStatus && <Tooltip title="Email status"><Tag tagpreset={theme.colors.secondary4}>{emailStatus}</Tag></Tooltip>}
                    {showCreditStatus}
                </Space>
            )
        }

        const ApprovalStatusTag = (options) => {
            let { invoice } = options;
            const { creditStatus, verificationStatus } = invoice;
            let approvalStatus = this.getApprovalStatus(creditStatus, verificationStatus);

            return (
                <Space style={{ width: "120px" }} wrap>
                    <Tooltip title="Approval status"><Tag tagpreset={theme.colors.secondary4}>{approvalStatus}</Tag></Tooltip>
                </Space>
            )
        }

        const columns = [
            {
                title: 'No.',
                dataIndex: 'docNumber',
                key: 'docNumber',
                width: "120px",
                sorter: true,
                showSorterTooltip: false,
            },
            {
                title: 'Customer',
                dataIndex: 'customerName',
                key: 'Customer',
                sorter: true,
                showSorterTooltip: false,
            },
            {
                title: <Text noWrap weight="500">Invoice Date</Text>,
                dataIndex: 'txnDate',
                key: 'txnDate',
                render: (date, invoice) => {
                    if (date) {
                        date = new Date(date).toISOString();
                        date = date.split("T")[0];
                    }
                    return (moment(date).format('MM/DD/YY'))
                },
                sorter: true,
                showSorterTooltip: false,
                width: "150px"
            },
            {
                title: <Text noWrap weight="500">Due Date</Text>,
                dataIndex: 'dueDate',
                key: 'dueDate',
                render: (date, invoice) => {
                    if (date) {
                        date = new Date(date).toISOString();
                        date = date.split("T")[0];
                    }
                    return (moment(date).format('MM/DD/YY'))
                },
                width: "150px",
                sorter: true,
                showSorterTooltip: false,
            },
            {
                title: 'Amount',
                dataIndex: 'totalAmt',
                key: 'totalAmt',
                render: amount => (formatter.format(amount)),
                align: 'right',
                sorter: true,
                showSorterTooltip: false,
            },
            {
                title: 'Purchase Order',
                dataIndex: 'ponumber',
                key: 'ponumber',
                align: 'right',
                sorter: true,
                width: "200px",
                showSorterTooltip: false,
            },
            {
                title: 'Invoice Status',
                dataIndex: 'invoiceStatus',
                key: 'status',
                render: (status, invoice) => <StatusTag invoice={invoice} />,
                width: "200px",
                sorter: true,
                showSorterTooltip: false,
            },
            {
                title: 'Approval Status',
                dataIndex: 'creditStatus',
                key: 'creditStatus',
                render: (status, invoice) => <ApprovalStatusTag invoice={invoice} />,
                sorter: true,
                showSorterTooltip: false,
                width: "200px"
            },
        ];

        return (
            <>
                <Table
                    id="invoice-table"
                    className="borderless-table row-pointer"
                    tableLayout='auto'
                    columns={columns}
                    dataSource={invoices}
                    rowKey='invoiceId'
                    pagination={pagination}
                    style={{ minHeight: "500px" }}
                    onChange={this.handleTableChange}
                    scroll={{ y: '700px', x: '100%' }}
                    loading={loading}
                    expandable={!this.props.disableExpandable && {
                        expandedRowRender: record => this.getRowDetails(record),
                        expandRowByClick: true,
                        expandIconColumnIndex: -1,
                        columnWidth: "2px",
                        indentSize: "20px",
                        expandIcon: ({ expanded, onExpand, record }) =>
                            !expanded ? (
                                <DownOutlined onClick={e => onExpand(record, e)} />
                            ) : (
                                <UpOutlined onClick={e => onExpand(record, e)} />
                            )
                    }}
                    rowSelection={showRowSelection && {
                        type: "checkbox",
                        onChange: this.props.handleRowSelection,
                        selectedRowKeys: this.props.selectedRowKeys
                    }}
                />
            </>
        )
    }

}

function mapStateToProps(state) {
    return {
        store: state.creditAppReducer,
        aionStore: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(InvoiceTable)));