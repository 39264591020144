import React, { useEffect, useState } from 'react';
import { Table } from "antd";
import styled, { withTheme } from "styled-components";
import hexToRgba from 'hex-to-rgba';
import { Flex, FlexColumn } from './Container';
import { Divider } from './Input';
import { Text } from './Text';
import { random } from 'underscore';

import ChevronDownPrimary from "../../Images/chevron-down.svg"
import ChevronDown from "../../Images/chevron-down.png"
import ChevronUp from "../../Images/chevron-up.png"
import { TextButton, ImageButton } from './Button';

const StyledTable = styled(Table)`
    height: 100%;
    div {
        background: transparent;
    }
    table {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05) !important;
    }
    thead > tr {
        border-radius: 8px;
        box-shadow: ${props => !props.borderView && `0px 0px 2px rgba(0, 0, 0, 0.1)`};
        background: transparent;
    }
    thead > tr > th {
        background: ${props => props.theme.colors.container};
        color: ${props => hexToRgba(props.theme.colors.primary2, 0.5)};
        border-bottom: ${props => props.borderView && `1px solid ${props.theme.colors.border}`};
    }
    thead > tr > th:first-child {
        border-bottom-left-radius: 8px;
        border-left: ${props => props.borderView && `1px solid ${props.theme.colors.border}`};
    }
    thead > tr > th:last-child {
        border-bottom-right-radius: 8px;
        padding-right: 20px;
        border-right: ${props => props.borderView && `1px solid ${props.theme.colors.border}`};
    }
    thead > tr {
        border-radius: 8px;
        background: transparent;
    }
    tbody > tr > td {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: ${props => !props.borderView && `none !important`};
        height: 55px !important;
    }
    tbody > tr > td:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }
    tbody > tr > td:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }
    tbody > tr:not(.expanded-row):hover {
        background: ${props => props.dataCount > 0 ? hexToRgba(props.theme.colors.primary, 0.06) : 'transparent'};
        cursor: ${props => props.onRow ? 'pointer' : 'auto'};
    }
    tr {
        color: ${props => hexToRgba(props.theme.colors.primary2, 1.0)};
    }
    .odd-row {
        border-radius: 8px;
        background-color: ${props => props.theme.colors.container}; /* white bg for alternate rows */
        box-shadow: ${props => !props.borderView && `0px 0px 2px rgba(0, 0, 0, 0.1)`};
    }
    .odd-row > td {
        border-top: ${props => props.borderView && `1px solid ${props.theme.colors.border}`};
        border-bottom: ${props => props.borderView && `1px solid ${props.theme.colors.border}`};
    }
    .odd-row > td:first-child {
        border-left: ${props => props.borderView && `1px solid ${props.theme.colors.border}`};
    }
    .odd-row > td:last-child {
        border-right: ${props => props.borderView && `1px solid ${props.theme.colors.border}`};
    }
    .odd-row.expanded-parent > td:first-child {
        border-bottom-left-radius: 0px !important;
        border-left: ${props => `4px solid ${hexToRgba(props.theme.colors.primary2, 0.7)}`};
    }
    .odd-row.expanded-parent > td:last-child {
        border-bottom-right-radius: 0px !important;
    }
    .even-row > td {
        border-bottom: ${props => props.borderView && `none !important`};
    }
    .even-row.expanded-parent {
        /* border-bottom: 0.1px solid rgba(0, 0, 0, 0.05) !important; */
        box-shadow: 0px 0.3px 0px rgba(0, 0, 0, 0.1);
    }
    .even-row.expanded-parent > td {
        /* border-bottom: 0.1px solid rgba(0, 0, 0, 0.05) !important; */
    }
    .even-row.expanded-parent > td:first-child {
        border-bottom-left-radius: 0px !important;
        border-left: ${props => `4px solid ${hexToRgba(props.theme.colors.primary2, 0.7)}`};
    }
    .even-row.expanded-parent > td:last-child {
        border-bottom-right-radius: 0px !important;
    }

    .expanded-row > td {
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
        border-left: ${props => `4px solid ${hexToRgba(props.theme.colors.primary2, 0.7)}`};
    }
    tbody > tr.expanded-row.odd-row:hover > td {
        background-color: ${(props) => props.theme.colors.container};
        cursor: default !important;
    }
    tbody > tr.expanded-row.even-row:hover {
        background: transparent;
        cursor: default !important;
    }
    tbody > tr.expanded-row.odd-row:hover > td {
        background-color: ${(props) => props.theme.colors.container};
        cursor: default !important;
    }

    tbody > tr > td.hover-column > * {
        visibility: hidden;
    }

    tbody > tr:hover > td.hover-column > * {
        visibility: visible;
    }

    tbody > tr > td.primary-column {
        /* font-size: 14px; */
    }

    .cell-content .hover-content {
        visibility: hidden;
    }

    tbody > tr:hover .cell-content .hover-content {
        visibility: visible;
    }

    td.ant-table-column-sort {
        background: inherit;
    }

    // To hide the table header
    ${props => props.hideHeader && `
        .ant-table-thead {
            display: none;
        }
    `}

    .group-header {
        background-color: ${props => hexToRgba(props.theme.colors.container, 0.5)} !important; /* Grey background color for group header */
        font-weight: 400;
        border-radius: 0px;
        box-shadow: 0px 0px 0px !important;
    }
    .group-header > td {
        padding: 8px 20px;
        border-radius: 0px;
    }
`;

const StyledHeader = styled.div`
    background: ${props => props.theme.colors.container};
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    border-bottom-right-radius: ${props => props.isCollapsed && '8px'};
    border-bottom-left-radius: ${props => props.isCollapsed && '8px'};
    border: ${props => props.borderView && `1px solid ${props.theme.colors.border}`};
    border-bottom: none;
    box-shadow: ${props => !props.borderView && `0px 0px 2px rgba(0, 0, 0, 0.1)`};
`;

const TableView = (props) => {
    const [isCollapsed, setIsCollapsed] = useState(false)
    const [canCollapse, setCanCollapse] = useState(false)
    var { theme, titleText, descText, ctaContent, ctaSecondary, pagination, expandedRowKey, rowKey, onLoadMore, moreToLoad, loadingMore, pageSize, hideHeader } = props

    if (pagination) pagination['hideOnSinglePage'] = true
    var dataRows = (props.dataSource || []).filter(item => !['sectionHeader', 'dateRow'].includes(item[rowKey]))
    var columns = props.columns || []
    
    useEffect(() => {
        if (props.canCollapse == true) {
            setIsCollapsed(props.isCollapsed)
            setCanCollapse(true)
        }
    }, []);

    if (props.expand && (columns.find(c => c.key == 'Expand') == undefined)) {
        columns = columns.push({
            title: '',
            dataIndex: rowKey,
            key: 'Expand',
            align: "right",
            onHover: true,
            render: (id) => (
                <ImageButton src={(id != expandedRowKey) ? ChevronDown : ChevronUp} />
            ),
            width: 50,
        })
    }

    const components = {
        body: {
            row: (rowProps) => {
                var rowKey = rowProps['data-row-key']
                if (rowKey == 'sectionHeader' || rowKey == 'dateRow') {
                    var rowDataItem = (rowProps.children || []).find(item => (item.key || '').toLowerCase() == 'description') || {}
                    var record = (rowDataItem.props || {}).record || {}
                    return (
                        <tr key={`group-${random(10000)}`} className="group-header">
                            <td colSpan={props.columns.length}>{record.date || record.header}</td>
                        </tr>
                    )
                }
                const rowIndex = dataRows.findIndex((item) => item[props.rowKey || props.expandedRowKey] == rowKey)
                const isEvenRow = rowIndex % 2 === 0
                const isExpanded = props.expandedRowKey ? (rowKey === props.expandedRowKey) : false // null & undefined case handled

                return (
                    <>
                        <tr {...rowProps} className={`${(!isEvenRow && rowIndex != -1) ? 'odd-row' : 'even-row'} ${isExpanded && 'expanded-parent'}`} />
                        {
                            isExpanded &&
                            <tr className={`expanded-row ${isEvenRow ? 'even-row' : 'odd-row'}`}>
                                <td colSpan={props.columns.length}>
                                    {/* Render expanded row content */}
                                    {props.getRowDetails(dataRows[rowIndex])}
                                </td>
                            </tr>
                        }
                    </>

                )
            },
            cell: (cellProps) => {
                const { onHover, primaryCol } = cellProps;
                if (onHover) {
                    return <td {...cellProps} className={'hover-column' + (primaryCol ? ' primary-column' : '')} />;
                }
                return <td {...cellProps} className={primaryCol ? 'primary-column' : ''} />;
            },
        },
    }
    // console.log("columnsWithOnHover", props.columns, columns)
    const primaryColInd = (props.columns || []).findIndex(column => column.primaryCol)
    /**
     * primaryCol indicates the primary column in a table. If unspecified, will be the first column
     */
    const columnsWithOnHover = (props.columns || []).map((column, i) => ({
        ...column,
        onCell: (record) => ({
            record,
            onHover: column.onHover,
            primaryCol: primaryColInd == -1 ? (i == 0) : (i == primaryColInd)
        }),
    }))

    return (
        <>
            <FlexColumn fullWidth gap='0px'>
                {
                    titleText &&
                    <StyledHeader isCollapsed={isCollapsed} borderView={props.borderView}>
                        <Flex centerHorizontally between gap='16px' style={{ padding: isCollapsed ? '10px 20px' : '20px', minHeight: isCollapsed ? '40px' : '85px' }}>
                            <FlexColumn gap={descText && '6px'}>
                                <Text margin='0' weight={500} size='18px' color={theme.colors.primary2}>
                                    {titleText}
                                </Text>
                                {
                                    !isCollapsed &&
                                    <Text color={theme.colors.defaultLightText}>
                                        {descText}
                                    </Text>
                                }
                            </FlexColumn>
                            <Flex start centerHorizontally gap='48px'>
                                {ctaSecondary || null}
                                {ctaContent || null}
                                {
                                    canCollapse &&
                                    <ImageButton src={isCollapsed ? ChevronDown : ChevronUp} onClick={() => setIsCollapsed(!isCollapsed)} />
                                }
                            </Flex>
                        </Flex>
                    </StyledHeader>
                }
                {titleText && !isCollapsed && <Divider color={theme.colors.divider} margin='0px' />}
                {
                    !isCollapsed &&
                    <>
                        <StyledTable
                            dataCount={(props.dataSource || []).length}
                            {...props}
                            columns={columnsWithOnHover}
                            components={components}
                            pagination={pagination || false}
                            hideHeader={hideHeader || false}
                        />

                        {
                            onLoadMore && moreToLoad &&
                            <Flex fullWidth centerVertically style={{ marginTop: 10 }}><TextButton text={`LOAD ${pageSize} MORE`} loading={loadingMore} onClick={onLoadMore} rightIcon={<img style={{ cursor: 'pointer', marginBottom: 1 }} width='24px' height='24px' src={ChevronDownPrimary} />} /></Flex>
                        }
                    </>
                }
            </FlexColumn>

        </>
    )
}

export default withTheme(TableView);
