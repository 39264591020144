import React, { Component } from 'react'
import { withTheme } from 'styled-components'

import { FlexColumn } from '../../Reusable/Container'
import { Image } from '../../Reusable/Image'
import { Text } from '../../Reusable/Text'


class SplashPage extends Component {

    componentDidMount() {
        // defaults to 3 sec display of splash page
        setTimeout(() => {
            this.props.loadNext();
        }, this.props.delay || 3000)
    }

    render() {
        return (
            <FlexColumn center style={{ height: "85vh" }}>
                <FlexColumn center gap="24px">
                    <Image src={this.props.theme.logo.symbol} />
                    <Text size="28px" heading>Let's get started!</Text>
                </FlexColumn>
            </FlexColumn>
        );
    }
}

export default withTheme(SplashPage)