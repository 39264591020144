import { RECEIVABLES_SAVE_DATA, RESET_RECEIVABLES_STATE } from '../Actions/actions'

const initialState = {
}

function creditAppReducer(state, action) {
	if (typeof state === 'undefined') {
		return initialState
	}
	var newState = state;
	switch (action.type) {
		case RECEIVABLES_SAVE_DATA:
			newState = Object.assign(newState, action.data ? action.data : {});
			return newState
		case RESET_RECEIVABLES_STATE:
			newState = initialState;
			return newState
		default:
			return state
	}
}

export default creditAppReducer