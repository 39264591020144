import React, { Component } from 'react'
import { useHistory, withRouter } from 'react-router-dom';
import Dimensions from 'react-dimensions'
import moment from 'moment';
import { Text } from '../../Reusable/Text'
import { Flex, FlexColumn, CardContainer } from '../../Reusable/Container'
import { TextButton } from '../../Reusable/Button';

const DebitCard = (props) => {
    const { card, onClick } = props

    const history = useHistory()

    return <CardContainer
        className={card.status !== 'Unactivated' && 'debit-card-dash'}
        minWidth='296px'
        width='296px'
        height='148px'
        margin='20px 24px 24px 0'
        onClick={onClick}
    >
        <FlexColumn start left style={{ width: '100%' }}>
            {
                (card.status === "Unactivated" && card.orderStatus === "OrderPending") ?
                    <FlexColumn fullHeight center>
                        <Text margin='24px 0 0'>Your new Debit Card application is being processed</Text>
                    </FlexColumn>
                    :
                    <>
                        <Text size='16px' height='40px'>{`•••• •••• •••• ${(["VirualPan", "VirtualPan"].includes(card.paymentInstrument) || card.status !== 'Unactivated') ? card.last4 : '••••'}`}</Text>
                        <Text size='14px' margin='4px 0 16px'>{(["VirualPan", "VirtualPan"].includes(card.paymentInstrument) || card.status !== 'Unactivated') ? `Valid thru ${moment(card.expirationDate).format('MM/YY')}` : 'Valid thru ••/••'}</Text>
                        {/* <Flex start>
                            <Text light size='14px' margin='0 4px 0 0'>{`This month's spend:`}</Text>
                            <Text>{`$${card.balance || 0}`}</Text>
                        </Flex> */}

                        {
                            card.status !== 'Unactivated' ?
                                <Text>{`${["VirualPan", "VirtualPan"].includes(card.paymentInstrument) ? 'Virtual ' : 'Physical'}`}</Text>
                                :
                                <Flex start gap='4px'>
                                    <Text>Received your card?</Text>
                                    <TextButton weight='400' text='Activate now' onClick={() => history.push({
                                        pathname: '/cards/my-cards',
                                        state: { cardId: card.cardId }
                                    })} />
                                </Flex>
                        }
                    </>
            }
        </FlexColumn>
    </CardContainer>
}

class DebitCardList extends Component {

    constructor(props) {
        super(props)
        this.state = {

        }
    }

    render() {
        const { loading, cards } = this.props
        const activeCard = cards.find(card => card.orderStatus !== "OrderPending")

        return (
            <div {...this.props}>
                {/* <FlexColumn style={{ width: this.props.containerWidth, height: 116, padding: '24px 48px 24px 24px', background: '#F2F2F2', borderRadius: '8px', margin: '42px 0', zIndex: 0 }}>
                    <Text heading>Aion Debit Cards</Text>
                    <Text light margin='4px 0 0'>Manage your Aion Debit Cards</Text>
                </FlexColumn> */}

                <Flex className='no-scrollbar' start centerHorizontally style={{ zIndex: 1, overflow: 'scroll', padding: '0 0 0 24px' }}>
                    <FlexColumn style={{ minWidth: 250, width: this.props.containerWidth, height: 116, padding: '24px 48px 24px 24px', background: '#F2F2F2', borderRadius: '8px', margin: '42px 0', zIndex: 0 }}>
                        <Text heading>Aion Debit Cards</Text>
                        {
                            cards && cards.length === 1 ?
                                <Text lightText margin='4px 0 0'>Click on the card to access it</Text>
                                :
                                <Text lightText margin='4px 0 0'>Click on any of your cards to access them</Text>
                        }
                    </FlexColumn>

                    {
                        cards && cards.length > 0 && activeCard &&
                        cards.map(card => {
                            if (card.orderStatus !== "OrderPending") {
                                return <DebitCard card={card} onClick={() => this.props.history.push({
                                    pathname: '/cards/my-cards',
                                    state: { cardId: card.cardId }
                                })} />
                            }
                        })
                    }

                    {
                        cards && cards.length > 0 && !activeCard &&
                        <DebitCard card={cards[0]} onClick={() => this.props.history.push({
                            pathname: '/cards/my-cards',
                            state: { cardId: cards[0].cardId }
                        })} />
                    }
                </Flex>
            </div>
        )
    }
}

export default withRouter(Dimensions()(DebitCardList))