import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'

import { Flex, FlexColumn, LightContainer } from '../../Reusable/Container'
import { Tag, Text } from '../../Reusable/Text'
import { apiPOSTReq } from '../../../Utils/api';
import moment from 'moment';
import environment from '../../../environment';
import Delete from '../../../Images/delete-red-icon.png';
import { ErrorAlert } from '../../Reusable/Alert'
import hexToRgba from 'hex-to-rgba'
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons'
import { ImageButton } from '../../Reusable/Button'
import { message } from 'antd'

const dFormat = "MMM Do, YYYY"
const etTimeZone = "America/New_York"

class OwnerPendingInvite extends Component {

    state = {
        preboardUsers: []
    }

    componentDidMount() {
        if(!this.props.preboardUsersLoaded) this.fetchPendingInvites()
        else this.setState({ preboardUsers: this.props.preboardUsers })
    }

    fetchPendingInvites = () => {
        var criteriaList = [
            {
                "fieldName": "Status",
                "value": "Invited"
            },
            {
                "fieldName": "Status",
                "value": "Error"
            },
            {
                "fieldName": "Status",
                "value": "ProcessedPreseed"
            }
        ] 

        /*
        // Not using this way as ProcessedPreseed user info is required for hiding PII. Instead using array filter method

        if(displayPendingOnly) criteriaList = [
            {
                "fieldName": "Status",
                "value": "Invited"
            },
            {
                "fieldName": "Status",
                "value": "Error"
            }
        ]
        */

        var body = {
            "searchFilter": {
                "criteriaList": criteriaList
            },
            "sortDirection": "ASC"
        }
        this.setState({ preboardUserLoading: true })
        apiPOSTReq(`${environment.uamBaseUrl}/getPreboardUser`, {}, body, (err, resp) => {
            try {
                this.setState({ preboardUserLoading: false })
                var data = resp || {}
                if (data.result) {
                    this.setState({ preboardUsers: data.preboardUsers })
                    if(this.props.setPreboardUsers) this.props.setPreboardUsers({ preboardUsers: data.preboardUsers })
                } else {
                    throw Error(data.error || data.responseMessage)
                }
            } catch (error) {
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    resendInviteEmail = (preboardUser) => {
        
        var body = {
            "referenceNumber": preboardUser.inviteReferenceNumber
        }
        console.log("preboardUser body", body)
        // this.setState({ loading: true })
        apiPOSTReq(`${environment.uamBaseUrl}/resendPreboardUserInvite`, {}, body, (err, resp) => {
            try {
                if (err) throw Error(err)
                var data = resp || {}
                console.log("resendInviteEmail data", err, data)
                if (!data.result) throw Error(data.responseMessage || data.error)
                var preboardUsers = this.state.preboardUsers || []
                preboardUsers = preboardUsers.map(item => (item.inviteReferenceNumber == preboardUser.inviteReferenceNumber) ? data.preboardUser : item )
                this.setState({ loading: false, preboardUsers })
                message.success('Successfully resent email!')
            } catch (error) {
                this.setState({ loading: false })
                ErrorAlert({ description: error.message || "Sorry we had trouble processing your request. Please try again." })
            }
        })
    }

    deletePreboardUser = (preboardUser) => {
        this.setState({ deletingUser: preboardUser.email })
        apiPOSTReq(`${environment.uamBaseUrl}/deletePreboardUser`, {}, { referenceNumber: preboardUser.inviteReferenceNumber }, (err, resp) => {
            try {
                this.setState({ deletingUser: null })
                var data = resp || {}
                if (data.result) {
                    this.fetchPendingInvites()
                    message.success(`Successfully canceled invite!`)
                } else {
                    throw Error(data.error || data.responseMessage)
                }
            } catch (error) {
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    render() {
        var { preboardUsers, deletingUser } = this.state
        var { theme, displayPendingOnly, displayCPOnly, displayBOOnly } = this.props
        
        if(displayPendingOnly) preboardUsers = (preboardUsers || []).filter(user => user.status != 'ProcessedPreseed')
        if(displayCPOnly) preboardUsers = (preboardUsers || []).filter(user => user.controlPerson)
        if(displayBOOnly) preboardUsers = (preboardUsers || []).filter(user => user.beneficialOwner)

        return (
            <Flex start fullWidth gap='24px' wrap>
                {
                    preboardUsers && preboardUsers.map(preboardUser => {
                        var applicant = preboardUser.applicant || {}
                        var status = preboardUser.status
                        if(status == 'ProcessedPreseed' || status == 'Processed') status = 'Completed'
                        return (
                            <FlexColumn style={{ maxWidth: '380px' }}>
                                <LightContainer fullHeight>
                                    <Flex gap='8px'>
                                        <FlexColumn start fullWidth gap='4px'>
                                            <Flex start centerHorizontally gap='8px' style={{ height: '25px' }}>
                                                <Text weight={600} size='13px' height='14px' color={status == 'Completed' ? theme.colors.primary9 : hexToRgba(theme.colors.primary2, 0.7)}>{status}</Text>
                                                <Tag
                                                    background={hexToRgba(theme.colors.primary2, 0.05)}
                                                    color={hexToRgba(theme.colors.primary2, 0.7)}
                                                    size='11px'
                                                    padding='2px 6px'
                                                    weight={500}
                                                >
                                                    {preboardUser.beneficialOwner && 'Beneficial Owner'}
                                                    {preboardUser.controlPerson && 'Controlling Party'}
                                                </Tag>
                                            </Flex>
                                            <Flex start fullWidth centerHorizontally gap='8px'>
                                                {
                                                    (preboardUser.firstName || applicant.firstName) ?
                                                    <Text weight={500} style={{ overflowWrap: 'break-word', cursor: 'default' }} title={preboardUser.email}>{preboardUser.firstName || applicant.firstName} {preboardUser.lastName || applicant.lastName}</Text>
                                                    :
                                                    <Text weight={500} style={{ overflowWrap: 'break-word' }}>{preboardUser.email}</Text>
                                                }
                                                {(status !== 'Completed') && <img style={{ cursor: 'pointer', opacity: 0.7 }} height={15} width={15} src={Delete} onClick={() => this.deletePreboardUser(preboardUser)} />}
                                                {(preboardUser.email == deletingUser) && <LoadingOutlined spin={true} />}
                                            </Flex>
                                            <Flex start centerHorizontally fullWidth gap='8px'>
                                                <Text lightText size='14px' style={{ overflowWrap: 'break-word' }}>Invited on {moment.utc((preboardUser.metaData.createTime)).tz(etTimeZone).format(dFormat)}</Text>
                                                { preboardUser.status == "Invited" && <a onClick={() => this.resendInviteEmail(preboardUser)}>Resend</a> }
                                            </Flex>
                                        </FlexColumn>
                                        {
                                            !preboardUser.controlPerson && (preboardUser.applicant.ownershipPercentage > 0) &&
                                            <Text size='12px' weight={600} color={hexToRgba(theme.colors.primary2, 1.0)}>{preboardUser.applicant.ownershipPercentage}%</Text>
                                        }
                                    </Flex>
                                </LightContainer>
                            </FlexColumn>
                        )
                    })
                }
                
            </Flex>
        )
    }
}

function mapStateToProps(state) {
    return {
        onboardingStore: state.onboardingAppReducer,
        aionStore: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(OwnerPendingInvite));