import { Modal, Select, Tag } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import { addDataToStore, BUSINESS_INFO, PERSONAL_INFO, REMOTE_SAVE_DATA } from '../../../Actions/actions';
import { ErrorAlert } from '../../Reusable/Alert';
import { TextButton } from '../../Reusable/Button';
import { Flex } from '../../Reusable/Container';
import { Divider } from '../../Reusable/Divider';
import cloneDeep from 'lodash/cloneDeep'

import { FlexColumn } from '../../Reusable/Container';
import FlowViewComponent from '../../Reusable/Refresh/FlowViewComponent';
import OwnerProfile from './OwnerProfile';
import OwnerInvite from './OwnerInvite';
import { LabeledInput } from '../../Reusable/Input';
import { Text } from '../../Reusable/Refresh/Text';
import ModalClose from '../../../Images/modal-close.png'
import { OwnerCard, MissingOwnerInfoCard } from '../../Reusable/Refresh/Card';
import { getTotalOwnershipPercentage, formatOwnershipPercentage} from '../../../Utils/util';
import OwnerPendingInvite from './OwnerPendingInvite';
import { random } from 'lodash';
import Banner from '../../Reusable/Banner';
import { apiPOSTReq } from '../../../Utils/api';
import environment from '../../../environment';

class BeneficialOwner extends Component {

    state = {
        personalInfoCopy: cloneDeep(this.props.onboardingStore.personalInfo),
        businessInfoCopy: cloneDeep(this.props.onboardingStore.businessInfo),
        preboardUsers: [],
        inviteCompKey: random(500) // used to refresh OwnerPendingInvite component
    }
    componentDidMount() {
        this.fetchPendingInvites()
    }
    fetchPendingInvites = () => {
        var criteriaList = [
            {
                "fieldName": "Status",
                "value": "Invited"
            },
            {
                "fieldName": "Status",
                "value": "Error"
            },
            {
                "fieldName": "Status",
                "value": "ProcessedPreseed"
            }
        ]

        var body = {
            "searchFilter": {
                "criteriaList": criteriaList
            },
            "sortDirection": "ASC"
        }
        this.setState({ preboardUserLoading: true })
        apiPOSTReq(`${environment.uamBaseUrl}/getPreboardUser`, {}, body, (err, resp) => {
            try {
                this.setState({ preboardUserLoading: false })
                var data = resp || {}
                if (data.result) {
                    this.setState({ preboardUsers: data.preboardUsers })
                } else {
                    throw Error(data.error || data.responseMessage)
                }
            } catch (error) {
                console.log({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    } 
    onCloseClick = () => {
        this.props.dispatch(addDataToStore(PERSONAL_INFO, this.state.personalInfoCopy))
        this.props.dispatch(addDataToStore(BUSINESS_INFO, this.state.businessInfoCopy))
        this.props.loadNext();
    }
    saveUserInput = (id, value) => {
        var dataToSave = {}
        switch(id) {
            case "businessTitle":
                dataToSave = { [id]: value }
                this.props.dispatch(addDataToStore(PERSONAL_INFO, dataToSave))
                break
            case "ownershipPercentage":
                dataToSave = { [id]: formatOwnershipPercentage(value >= 0 ? value.toString() : '') }
                this.props.dispatch(addDataToStore(PERSONAL_INFO, dataToSave))
                break
            default:
                dataToSave[id] = value
                this.props.dispatch(addDataToStore(BUSINESS_INFO, dataToSave))
                break
        }
    }

    handleOnChange = (event) => {
        const target = event.target;
        if ((target.id || target.name) == 'ownershipPercentage') {
            if (!isNaN(target.value)) {
                if((target.value > 100)) {
                    this.saveUserInput(target.id || target.name, target.value.slice(0,2));
                }else {
                    this.saveUserInput(target.id || target.name, target.value);
                    this.setState({ errorField: null, errorMessage: null });
                }
            }
            else {
                this.setState({ errorField: 'ownershipPercentage', errorMessage: 'Please enter a valid ownership percentage' });
                this.saveUserInput(target.id || target.name, target.value);
            }
        } else {
            this.saveUserInput(target.id || target.name, target.value)
        }
    }

    setErrorField = (id, msg) => this.setState({ errorField: id, errorMessage: msg })

    validateFlow = () => {
        var { preboardUsers } = this.state
        var { onboardingStore, flowView } = this.props
        var { businessTitle, ownershipPercentage } = onboardingStore.personalInfo
        var beneficialOwners = onboardingStore.coOwnersInfo.filter(owner => Number(owner.ownershipPercentage) >= 25)
        // if ((onboardingStore.coOwnersInfo || []).length == 0) {
        //     ErrorAlert({description : "Please add list of Owners & Affiliates with >=25% ownership"});
        //     return;
        // }
        if(!businessTitle) {
            this.setErrorField("businessTitle", "Please select an option")
            return
        }
        if(!(ownershipPercentage >= 0 && ownershipPercentage <= 100)) {
            this.setErrorField("ownershipPercentage", "Needs to be value between 0 and 100")
            return
        }
        if(getTotalOwnershipPercentage({ preboardUsers: preboardUsers || [] }) > 100) {
            this.setErrorField("ownershipPercentage", "Total ownership cannot exceed 100%")
            return
        }

        let filteredOwners = [];
        if(this.props.aionStore.OnboardingType === "credit") {
            filteredOwners = beneficialOwners.filter(owner => owner.vouchedJobId === null);
            
        }
        if(filteredOwners.length > 0) {
            ErrorAlert({description : "Please fill out missing information in the beneficial ownership section"});
            return
        }
        // Save to the DB
        console.log("Saving business", getTotalOwnershipPercentage({ preboardUsers: preboardUsers || [] }))
        this.props.dispatch(addDataToStore(REMOTE_SAVE_DATA, flowView.FlowStep));
        this.props.loadNext();
    }

    render() {
        var { flowView, currentViewIndex, loadPrevView, onboardingStore, theme, reviewView } = this.props
        var { errorField, errorMessage, selectedOwner, showOwnerModal, showInviteOwnerModal, preboardUsers, inviteCompKey } = this.state
        var { firstName, lastName, businessTitle, ownershipPercentage } = onboardingStore.personalInfo
        flowView = flowView || []
        const businessTitles = onboardingStore.businessTitles || [ "CEO", "CFO", "COO", "Other" ]
        var beneficialOwners = onboardingStore.coOwnersInfo.filter(owner => Number(owner.ownershipPercentage) >= 25)
        
        // We do not need to show completed ones
        preboardUsers = (preboardUsers || []).filter(user => user.status != 'ProcessedPreseed')

        var boInvited = (preboardUsers || []).filter(user => user.beneficialOwner)
        console.log("boInvited", preboardUsers, boInvited)

        var childViews = (
            <FlexColumn start style={{ marginTop: "0px", height: '100%' }}>
                <FlexColumn>
                    <Text uppercase spacing='0.08em' weight='500' color={theme.colors.secondary6}>YOUR OWNERSHIP</Text>
                    <Text>Provide your title and ownership information.</Text>
                    <Flex start style={{ width: "100%", marginTop: "24px" }}>
                        <div style={{ width: "25%", marginRight: 12 }}>
                            <LabeledInput
                                type="read-only"
                                label="Name"
                                value={`${firstName} ${lastName}`}
                            />
                        </div>
                        <div style={{ width: "25%", marginRight: 12, marginLeft: 12 }}>
                            <LabeledInput
                                label="Title"
                                type="select"
                                key="businessTitle"
                                // size="large"
                                padding='0 0 0 12px'
                                placeholder="Select an option"
                                onChange={value => { this.saveUserInput("businessTitle", value) }}
                                value={businessTitle}
                                id="incorpTypeDoc"
                                error={errorField=="businessTitle"}
                                errorMessage={errorMessage}
                            >
                                {
                                    businessTitles.map(item => {
                                        return (
                                            <Select.Option key={item} value={item}>
                                                {item}
                                            </Select.Option>
                                        )
                                    })
                                }
                            </LabeledInput>
                        </div>
                        <div style={{ width: "25%", marginRight: 12, marginLeft: 12 }}>
                            <LabeledInput
                                label="Ownership Percentage"
                                key=""
                                id="ownershipPercentage"
                                symbolsuffix="%"
                                value={formatOwnershipPercentage(ownershipPercentage ? ownershipPercentage.toString() : '')}
                                maxLength={6}
                                placeholder="Enter Percentage"
                                onChange={this.handleOnChange}
                                error={errorField=="ownershipPercentage"}
                                errorMessage={errorMessage}
                                suffixFontSzie="20px"
                            />
                        </div>
                    </Flex>
                    <FlexColumn gap='24px' start>
                        <FlexColumn>
                            <Divider />
                            <Text uppercase spacing='0.08em' weight='500' color={theme.colors.secondary6}>Other Owners</Text>
                            <Text style={{ maxWidth: '625px' }}>We only need to know about individuals who own 25% or more of your business.</Text>
                        </FlexColumn>
                        {
                            ((100 - (getTotalOwnershipPercentage({ preboardUsers: preboardUsers || [] }) || 0)) >= 25)
                            ? 
                            <FlexColumn start gap='16px'>
                                <Flex gap='16px' start><Text>Add information on their behalf: </Text><TextButton text={'Add Owner'.toUpperCase()} onClick={() => this.setState({ showOwnerModal: true })} /></Flex>
                                <Flex gap='16px' start><Text>Invite owner to add information: </Text><TextButton text={'Invite Owner'.toUpperCase()} onClick={() => this.setState({ showInviteOwnerModal: true })} /></Flex>
                                {/* <Text color={theme.colors.divider}>|</Text> */}
                                
                            </FlexColumn>
                            :
                            <Text weight='400' color={theme.colors.defaultLightText}>You have added the maximum number of beneficial owners.</Text>
                        }
                        <Flex wrap start>
                            {
                                beneficialOwners.map((item, j) => {
                                    if(item.vouchedJobId) {
                                        return <OwnerCard padding='12px 0' ownerInfos={item} theme={theme} symbolprefix="%" key={j} onClick={() => this.setState({ showOwnerModal: true, selectedOwner: item })}/>
                                    }else {
                                        return <MissingOwnerInfoCard padding='12px 0' ownerInfos={item} theme={theme} symbolprefix="%" key={j} onClick={() => this.setState({ showOwnerModal: true, selectedOwner: item })}/>
                                    }
                                })
                            }
                        </Flex>
                    </FlexColumn>
                    <FlexColumn gap='24px'>
                        {
                            preboardUsers.length > 0 &&
                            <FlexColumn>
                                <Divider />
                                <Text uppercase spacing='0.08em' weight='500' color={theme.colors.secondary6}>Pending Invites</Text>
                            </FlexColumn>
                        }
                        <OwnerPendingInvite
                            key={inviteCompKey + preboardUsers.length}
                            displayPendingOnly={true}
                            displayBOOnly={true}
                            setPreboardUsers={ (data) => {
                                this.setState({ preboardUsers: data.preboardUsers })
                            } }
                        />
                    </FlexColumn>
                    {
                        ((preboardUsers || []).filter(user => user.beneficialOwner).length > 0) &&
                        <Banner style={{ maxWidth: '950px', marginTop: '24px' }} message='You can proceed further, however, you will be able to submit your application only once the invited owners have provided their required information.' />
                    }
                </FlexColumn>
            </FlexColumn>
        );
        if(reviewView) {
            return (
                <FlexColumn start fullWidth left>
                    <Text uppercase spacing='0.08em' weight='500' color={theme.colors.secondary6}>YOUR OWNERSHIP</Text>
                    <Flex start fullWidth gap='24px' style={{ marginTop: "24px" }}>
                        <div style={{ width: "25%" }}>
                            <LabeledInput
                                type="read-only"
                                label="Name"
                                value={`${firstName} ${lastName}`}
                            />
                        </div>
                        <div style={{ width: "25%" }}>
                            <LabeledInput
                                type="read-only"
                                label="Title"
                                value={businessTitle}
                            />
                        </div>
                        <div style={{ width: "50%" }}>
                            <LabeledInput
                                type="read-only"
                                label="Ownership Percentage"
                                value={ownershipPercentage}
                            />
                        </div>
                    </Flex>
                    <Divider />
                    <Text uppercase spacing='0.08em' weight='500' color={theme.colors.secondary6}>Beneficial ownership</Text>
                    <Flex wrap start fullWidth gap='24px' style={{ marginTop: "24px" }}>
                        {
                            beneficialOwners.map((item, j) => {
                                return <OwnerCard hideEdit={true} padding='12px 0' ownerInfos={item} theme={theme} symbolprefix="%" key={j} style={{cursor: 'not-allowed'}} />
                            })
                        }
                        {
                            (beneficialOwners.length == 0) &&
                            <Banner style={{ width: 350 }} message={boInvited.length == 0 ? 'None added' : 'Beneficial owner invite is pending'} />
                        }
                    </Flex>
                </FlexColumn>
            )
        }
        return (
            <>
                <FlowViewComponent
                    flowView={flowView}
                    currentViewIndex={currentViewIndex}
                    loadPrev={loadPrevView}
                    childViews={childViews}
                    submitLoading={this.state.submitLoading}
                    width={'100%'}
                    back={currentViewIndex > 0}
                    padding='0 64px'
                    {...this.props}
                    loadNext={this.validateFlow}
                    onCloseClick={this.onCloseClick}
                />
                <Modal
                    visible={showOwnerModal}
                    footer={null}
                    closable={true}
                    width={700}
                    destroyOnClose={true}
                    onCancel={() => { 
                        this.setState({ showOwnerModal: false, selectedOwner: null })
                    }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                    style={{ top: '20px' }}
                >
                    <OwnerProfile
                        ownerType="coOwner" // coOwner, controlPerson, signer
                        selectedOwner={selectedOwner}
                        hidePII={(this.state.preboardUsers || []).filter(pbUser => pbUser.email == (selectedOwner || {}).email).length > 0}
                        flowStep={"Apply.TxnActivity"} // Set remote save to prev step
                        onSubmit={() => {
                            this.setState({ showOwnerModal: false, selectedOwner: null })
                        }}
                    />
                </Modal>
                <Modal
                    visible={showInviteOwnerModal}
                    footer={null}
                    closable={true}
                    width={500}
                    destroyOnClose={true}
                    onCancel={() => { 
                        this.setState({ showInviteOwnerModal: false })
                    }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                    style={{ top: '20px' }}
                >
                    <OwnerInvite
                        ownerType="coOwner" // coOwner, controlPerson, signer
                        selectedOwner={{}}
                        onSubmit={() => {
                            this.setState({ showInviteOwnerModal: false, inviteCompKey: random(500) })
                        }}
                        preboardUsers={preboardUsers}
                    />
                </Modal>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        onboardingStore: state.onboardingAppReducer,
        aionStore: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(BeneficialOwner));