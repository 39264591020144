import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import moment from 'moment'
import { Image } from '../../Reusable/Image';
import { Select, Modal, message } from 'antd'
import { isEmail } from 'react-multi-email'

import { Text, Tag } from '../../Reusable/Text'
import { TextButton } from '../../Reusable/Button'
import { Flex, FlexColumn, CardContainer } from '../../Reusable/Container'
import PageHeader from '../../Reusable/PageHeader'
import { LabeledInput } from '../../Reusable/Input'
import { ErrorAlert } from '../../Reusable/Alert'
import SuccessModal from '../../Reusable/SuccessModal'
import FlowFooter from '../../Reusable/FlowFooter'
import { FromAccountOption } from '../../Reusable/Option'
import AionIcon from '../../../Images/aion-bank-icon.png'

import { addDataToStore, BNK_SAVE_DATA, SAVE_DATA } from '../../../Actions/actions'
import { isEmailValid, getBPAccountsWithAccess, formatPhoneTextV2, addressObjectToMultiLineStr, getFeatureLimit } from '../../../Utils/util'
import { apiPOSTReq } from '../../../Utils/api'
import { uaApiPOST } from '../../../Utils/userAccess'
import environment from '../../../environment'
import ModalClose from '../../../Images/modal-close.png'
import Check from '../../../Images/check-light.png'
import { parsePhoneNumber } from 'libphonenumber-js'

const { Option } = Select

class Index extends Component {

    constructor(props) {
        super(props)
        const { store } = this.props
        var { business } = store
        const { businessProfile } = business
        console.log('eligibleCardUsers', businessProfile)
        var eligibleCardUsers = []
        // eligibleCardUsers = eligibleCardUsers.concat(businessProfile.coOwnerApplicantInfo)
        if(businessProfile.primaryApplicantInfo) {
            businessProfile.primaryApplicantInfo.primaryApplicant = true
            eligibleCardUsers.push(businessProfile.primaryApplicantInfo)
    
            businessProfile.coOwnerApplicantInfo.forEach(coOwner => {
                if (coOwner.authorizedSigner) {
                    coOwner.signer = true
                    eligibleCardUsers.push(coOwner)
                }
            })
    
            businessProfile.signerInfo.forEach(signer => {
                signer.signer = true
            })
    
            eligibleCardUsers = eligibleCardUsers.concat(businessProfile.signerInfo)
        }
        

        this.state = {
            loading: false,
            pagination: {
                current: 1,
                pageSize: 1000,
                hideOnSinglePage: true
            },
            recipients: [],
            showTransferModal: false,
            transferMethods: {},
            showResult: false,
            step: 0,
            steps: ['Transfer Details', 'Review'],
            confirmDisabled: true,
            sendEmail: false,
            eligibleCardUsers: eligibleCardUsers,
            cardType: 'Virtual',
            registeredUsers: [],
            preboardUsers: [],
            deliverTo: 'Company mailing address',
            recipientEmailError: false,
            singleAccount: false,
            phoneCode: {
                "name": "United States",
                "flag": "🇺🇸",
                "code": "US",
                "dial_code": "+1"
            },
        }

        this.nameRef = React.createRef()
        this.bankRef = React.createRef()
        this.accountNumberRef = React.createRef()
        this.routingNumberRef = React.createRef()
    }

    componentDidMount() {
        const { theme, bankingStore } = this.props
        const { AllRecipients } = bankingStore

        this.setState({ recipients: AllRecipients })

        if (this.props.store.activeUsers && this.props.store.activeUsers.length > 0) {
            this.setState({ registeredUsers: this.props.store.activeUsers })
        } else {
            this.setState({ loading: true })
            this.fetchData()
        }

        this.fetchPendingInvites()
        this.fetchAccounts()
    }

    fetchData() {
        var userData = {}

        uaApiPOST("/user/admin/getUsersForBusinessEx", null, (err, resp) => {
            try {
                if (err) throw Error(err)
                if (resp.error) throw Error(resp.error)
                userData = resp
                console.log("getUsersForBusiness userData", userData)
                loadUsers()
            } catch (error) {
                console.log("getUsersForBusiness error", error)
                ErrorAlert({ description: error.message })
            }
        })

        const loadUsers = () => {
            var registeredUsers = userData.registeredUsers || []
            var activeUserListData = []
            var inactiveUserListData = []
            var inactiveUsers = []
            registeredUsers.forEach(user => {
                const userInfo = user.userInfo
                const metaData = user.metaData || {}
                var assignedApplications = []
                var userRoleNames = []
                const userAccessInfoList = user.userAccessInfoList || []
                const assignedRoleNamesMap = userData.assignedRoleNamesMap || {}
                var roles = assignedRoleNamesMap[userInfo.email] || {}
                Object.keys(roles).forEach(applicationName => {
                    (roles[applicationName] || []).forEach(appRole => {
                        if (!userRoleNames.includes(appRole)) userRoleNames.push(appRole)
                    })
                })

                var userItem = {
                    roleTags: userRoleNames,
                    // permissionTags: ["View", "Manage"],
                    assignedApplications: assignedApplications,
                    FirstName: userInfo.firstName,
                    LastName: userInfo.lastName,
                    Name: `${userInfo.firstName} ${userInfo.lastName}`,
                    Email: userInfo.email,
                    Phone: userInfo.phone,
                    CreatedOn: userInfo.createdOn ? moment(userInfo.createdOn) : moment(metaData.createTime)
                }
                if (userInfo.enabled) activeUserListData.push(userItem)
                else {
                    inactiveUsers.push(user)
                    inactiveUserListData.push(userItem)
                }
            })

            this.props.dispatch(addDataToStore(SAVE_DATA, { activeUsers: activeUserListData, inactiveUsers: inactiveUserListData }))
            this.setState({ loading: false, registeredUsers: activeUserListData })
        }
    }

    fetchPendingInvites = () => {
        var body = {
            "searchFilter": {
                "criteriaList": [
                    {
                        "fieldName": "Status",
                        "value": "Invited"
                    },
                    {
                        "fieldName": "Status",
                        "value": "Error"
                    },
                    {
                        "fieldName": "Status",
                        "value": "Manual Review"
                    },
                    {
                        "fieldName": "Status",
                        "value": "Denied"
                    }
                ]
            }
        }
        this.setState({ preboardUserLoading: true })
        apiPOSTReq(`${environment.uamBaseUrl}/getPreboardUser`, {}, body, (err, resp) => {
            try {
                this.setState({ preboardUserLoading: false })
                var data = resp || {}
                if (data.result) {
                    console.log('fetchPendingInvites', data)
                    const preboardUsers = data.preboardUsers
                    preboardUsers.forEach(preboardUser => {
                        preboardUser.roleTags = preboardUser.roles
                        preboardUser.FirstName = preboardUser.firstName
                        preboardUser.LastName = preboardUser.lastName
                        preboardUser.Phone = preboardUser.phone
                        preboardUser.Email = preboardUser.email
                    })
                    this.setState({ preboardUsers })
                } else {
                    throw Error(data.error || data.responseMessage)
                }
            } catch (error) {
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    fetchAccounts = () => {
        this.setState({ loading: true })

        apiPOSTReq(`${environment.bbBaseUrl}/bb/getAccounts`, { "BankProvider": environment.bankProvider.crb }, null, (err, resp) => {
            try {
                console.log("/bb/getAccounts", err, resp)
                this.setState({ loading: false })
                const data = resp
                if (data.result) {
                    if (err) throw new Error(err)
                    var bbaccounts = data.bbaccounts || []
                    this.props.dispatch(addDataToStore(BNK_SAVE_DATA, { BBAccounts: bbaccounts }))
                    bbaccounts = bbaccounts.filter(acc => acc.accountType !== "ACCOUNTS_RECEIVABLE")
                    if (bbaccounts.length === 1) this.setState({ singleAccount: true, fromAccount: bbaccounts[0] })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getAccounts", error, err, resp)
                ErrorAlert({ description: "Error fetching accounts" })
            }
        })
    }

    viewRecipient = (recipient) => {
        this.props.history.push({
            pathname: '/banking/recipients/details',
            state: { recipient }
        })
    }

    formatAddr = (address) => {
        return {
            line1: address.line1,
            line2: address.line2 || null,
            city: address.city,
            countrySubDivisionCode: address.countrySubDivisionCode,
            state: address.countrySubDivisionCode,
            postalCode: address.postalCode
        }
    }

    handleSelectPerson = (value, option) => {
        const { registeredUsers, preboardUsers } = this.state
        const totalUserCount = (registeredUsers || []).length + (preboardUsers || []).length
        const userLimit = getFeatureLimit("AionApplications.Users.Users")
        if (value === 'addNewCardholder') {
            if (totalUserCount >= userLimit) {
                ErrorAlert({ description: "You have reached the maximum user limit for your plan. Please reach out to contact@aionfi.com for limit increases." })
                this.setState({ addNewCardholder: false, issueTo: this.state.issueTo ? this.state.issueTo : null })
                return
            }
            this.setState({ addNewCardholder: true, issueTo: null, recipientEmail: null, incompleteUser: false })
        } else {
            const { registeredUsers, preboardUsers } = this.state
            var user = registeredUsers.find(u => u.Email === value)
            if (!user) user = preboardUsers.find(u => u.id === value)
            console.log('handleSelectPersonhandleSelectPerson: ', user)
            const incompleteUser = !user.FirstName || !user.LastName || !user.Phone || !user.Email
            this.setState({ addNewCardholder: false, issueTo: user, recipientEmail: user.Email, incompleteUser })
        }
    }

    handleSelectAccount = (value, option) => {
        var Accounts = getBPAccountsWithAccess("", { resourceStr: "BusinessCards.Cards", getActiveAccounts: true })
        var account = Accounts.find(a => a.accountId === value)
        this.setState({ [option.id]: account })
    }

    handleTextChange = (event) => {
        const target = event.target
        this.saveUserInput(target.id || target.name, target.value)
    }

    saveUserInput = (id, dataToSave) => {
        this.setState({ [id]: dataToSave })
    }

    selectRecipient = (value, option) => {
        const { recipients } = this.state
        const recipient = recipients.find(r => r.objectId === value)
        console.log("selectRecipient: ", recipient)
        this.setState({ recipient })
    }

    getTransferMethods = () => {
        const { recipient } = this.state
        const transferMethods = []

        if (recipient && recipient.ach) {
            recipient.ach.type = "ACH"
            transferMethods.push(recipient.ach)
        }

        if (recipient && recipient.wire) {
            recipient.wire.type = "Wire"
            transferMethods.push(recipient.wire)
        }

        if (recipient && recipient.check) {
            recipient.check.type = "Check"
            transferMethods.push(recipient.check)
        }

        return transferMethods
    }

    onSubmit = () => {
        const { issueTo, fromAccount, nickName, addNewCardholder, firstName, lastName, phone, email, phoneCode, incompleteUser } = this.state
        const { store } = this.props
        var { business } = store
        const { businessProfile } = business
        const { businessInfo } = businessProfile

        var phone_number = addNewCardholder ? phone : (issueTo.Phone || '')

        // if ((phone_number.replace(/[^\d]/g, '').length < 6 || phone_number.replace(/[^\d]/g, '').length > 10)) {
        //     this.setState({ errorField: 'phone', errorMessage: 'Please enter a valid mobile number.' })
        //     return;
        // }

        if (addNewCardholder) {
            phone_number = `${phoneCode.dial_code}${(phone_number)}`
        }

        var formattedPhone = phone_number.replace(/[^a-zA-Z0-9]/g, '')

        var phoneType = "Mobile"
        const parsedPhoneNumber = parsePhoneNumber('+' + formattedPhone)
        // For non-US phone numbers set type to Work so that the phone doesn't appear as a
        if (parsedPhoneNumber.country != "US") {
            phoneType = "Work"
            if (formattedPhone.length < 10) {
                this.setState({ errorField: "phone", errorMessage: "Phone number has to be at least 10 digits" })
                return
            }
            formattedPhone = (formattedPhone.length > 14) ? formattedPhone.substring(formattedPhone.length - 14) : formattedPhone
        } else {
            // only pass the last 10 digits of a phone for US numbers
            formattedPhone = (formattedPhone.length > 10) ? formattedPhone.substring(formattedPhone.length - 10) : formattedPhone
        }
        if (addNewCardholder === true) {
            if (!firstName) {
                this.setState({ errorField: "firstName", errorMessage: "Please enter a first name" })
                return
            }
            if (!lastName) {
                this.setState({ errorField: "lastName", errorMessage: "Please enter a last name" })
                return
            }
            if (!phone || (phone.replace(/[^\d]/g, '').length < 6)) {
                this.setState({ errorField: "phone", errorMessage: "Please enter a valid mobile number" })
                return
            }
            if (!isEmailValid(email)) {
                this.setState({ errorField: "email", errorMessage: "Enter a valid email" })
                return
            }
        }

        if(businessInfo.mailingAddress && !businessInfo.mailingAddress.countryCode) businessInfo.mailingAddress.countryCode = "US"

        var body = {
            "BusinessCustomerId": fromAccount.customerId || businessInfo.id,
            "MailingAddress": businessInfo.mailingAddress,
            "BillingAddress": businessInfo.mailingAddress,
            "BBPersonCard": {
                "CompanyNameOnCard": (businessInfo.name || "").trim(),
                "NameOnCard": `${addNewCardholder ? firstName : issueTo.FirstName} ${addNewCardholder ? lastName : issueTo.LastName}`,
                "AccountId": fromAccount.accountId,
                "AccountNumber": fromAccount.accountNumber,
                "EmailAddress": addNewCardholder ? email : issueTo.Email,
                "FirstName": addNewCardholder ? firstName : issueTo.FirstName,
                "LastName": addNewCardholder ? lastName : issueTo.LastName,
                "Phone": {
                    "PhoneType": phoneType,
                    "PhoneNumber": formattedPhone
                },
                "NickName": nickName,
            },
            "newUser": addNewCardholder,
        }

        if (!addNewCardholder && !issueTo.roleTags.some(r => ['Owner', 'Primary Admin', 'Company Admin', 'Card Admin', 'Cardholder'].includes(r))) {
            this.updateUser(issueTo)
        }

        // if (addNewCardholder) {
        //     this.preboardUser(body)
        // } else {
        //     this.issueCard(body)
        // }

        this.issueCard(body, addNewCardholder)

        this.setState({ submitLoading: true })
    }

    preboardUser = (issueCardBody) => {
        var { email, cardType, firstName, lastName, phone, phoneCode } = this.state
        var { BusinessUniqueKey, userInfo } = this.props.store
        if (!isEmailValid(email)) {
            this.setState({ errorField: "email", errorMessage: "Enter a valid email" })
            return
        }

        var phone_number = `${phoneCode.dial_code}${(phone)}`
        var formattedPhone = phone_number.replace(/[^a-zA-Z0-9]/g, '')

        var body = {
            "user": {
                "email": email,
                "businessId": BusinessUniqueKey,
                "roles": ['Cardholder'],
                "invitedByEmail": userInfo.email,
                "invitedByName": `${userInfo.firstName} ${userInfo.lastName}`,
                "userType": "BusinessUser",
                "cardIssueType": cardType.toLowerCase(),
                "firstName": firstName,
                "lastName": lastName,
                "phone": `+${formattedPhone}`,
            }
        }
        console.log("preboardUser body", body)

        apiPOSTReq(`${environment.uamBaseUrl}/preboardUser`, {}, body, (err, resp) => {
            try {
                if (err) throw Error(err)
                var data = resp || {}
                console.log("preboardUser data", err, data.preboardUser)
                if (!data.result) throw Error(data.responseMessage || data.error)

                // this.issueCard(issueCardBody)
                this.setState({ submitLoading: false, showResult: true })

                // message.success('Successfully invited user!')
                // window.history.back()
            } catch (error) {
                this.setState({ submitLoading: false })
                ErrorAlert({ description: error.message || "Sorry we had trouble processing your request. Please try again." })
            }
        })
    }

    issueCard = (body, addNewCardholder) => {
        const { cardType } = this.state

        console.log('issueCard create card body', body)
        apiPOSTReq(`${environment.bbBaseUrl}/bb/card/${cardType === 'Physical' ? 'createPhysicalCard' : 'createVirtualCard'}`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            try {
                console.log(`${environment.bbBaseUrl}/bb/card/${cardType === 'Physical' ? 'createPhysicalCard' : 'createVirtualCard'}`, err, resp)
                const data = resp
                if (data.result) {
                    console.log(`${environment.bbBaseUrl}/bb/card/${cardType === 'Physical' ? 'createPhysicalCard' : 'createVirtualCard'}`, data)
                    if (addNewCardholder) {
                        this.preboardUser(body)
                    } else {
                        this.setState({ submitLoading: false, showResult: true })
                    }
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log(`ERROR ${environment.bbBaseUrl}/bb/card/${cardType === 'Physical' ? 'createPhysicalCard' : 'createVirtualCard'}`, error, err, resp)
                const fd = this.fetchData
                ErrorAlert({ description: error.message })
            }
        })
    }

    updateUser = (user) => {
        const { roleTags } = user

        roleTags.push('Cardholder')

        var body = {
            RoleNames: roleTags,
            UserId: user.Email,
        }

        console.log("IssueCardUpdatingUserupdateUser", body)
        uaApiPOST("/user/admin/updateUserRoles", { body: body }, (err, resp) => {
            try {
                console.log("IssueCardUpdatingUserupdateUser UAM.Submit", resp)
                if (err) throw Error(err)
                if (!resp.result) throw Error(resp.responseMessage || resp.error)
                // message.success('User roles updated')
            } catch (error) {
                console.log("IssueCardUpdatingUserupdateUser UAM.Submit error", error.message)
                // ErrorAlert({ description: error.message })
            }
        })
    }

    getPersonOptions = () => {
        const { registeredUsers, preboardUsers } = this.state

        var options = []

        options.push(
            <Option key='addNewCardholder' id='addNewCardholder' value='addNewCardholder' style={{ backgroundColor: "transparent" }}>
                <FlexColumn start fullWidth>
                    <Flex between gap='24px' centerHorizontally style={{ height: '100%', width: '100%' }}>
                        <Text capitalize primary>+ Issue to new user</Text>
                    </Flex>
                </FlexColumn>
            </Option>
        )

        registeredUsers.forEach(user => {
            // if (user.roleTags.includes('Owner') || user.roleTags.includes('Primary Admin') || user.roleTags.includes('Company Admin') || user.roleTags.includes('Banking Admin')) {
            options.push(
                <Option key={user.Email} id={user.Email} value={user.Email} style={{ backgroundColor: "transparent" }}>
                    <FlexColumn start fullWidth>
                        <Flex between gap='24px' centerHorizontally style={{ height: '100%', width: '100%' }}>
                            <Text capitalize>{`${user.Name}`}</Text>
                            <Tag padding='0 12px' height='25px' noCap>{user.Email}</Tag>
                            {/* {
                                user.roleTags.some(r => ['Owner', 'Primary Admin', 'Company Admin', 'Banking Admin'].includes(r)) &&
                                <Tag padding='0 12px' height='25px'>{user.roleTags.includes('Owner') ? 'Owner' : user.roleTags.includes('Primary Admin') ? 'Primary Admin' : user.roleTags.includes('Company Admin') ? 'Company Admin' : user.roleTags.includes('Banking Admin') ? 'Banking Admin' : ''}</Tag>
                            } */}
                        </Flex>
                    </FlexColumn>
                </Option>
            )
            // }
        })

        preboardUsers.forEach(user => {
            // if (user.roleTags.includes('Owner') || user.roleTags.includes('Primary Admin') || user.roleTags.includes('Company Admin') || user.roleTags.includes('Banking Admin')) {
            options.push(
                <Option key={user.id} id={user.id} value={user.id} style={{ backgroundColor: "transparent" }}>
                    <FlexColumn start fullWidth>
                        <Flex between gap='24px' centerHorizontally style={{ height: '100%', width: '100%' }}>
                            <Text>{(user.firstName && user.lastName) ? `${user.firstName} ${user.lastName}` : `${user.email}`}</Text>
                            <Tag padding='0 12px' height='25px' primary>Invited</Tag>
                            {/* {
                                user.roleTags.some(r => ['Owner', 'Primary Admin', 'Company Admin', 'Banking Admin'].includes(r)) &&
                                <Tag padding='0 12px' height='25px'>{user.roleTags.includes('Owner') ? 'Owner' : user.roleTags.includes('Primary Admin') ? 'Primary Admin' : user.roleTags.includes('Company Admin') ? 'Company Admin' : user.roleTags.includes('Banking Admin') ? 'Banking Admin' : ''}</Tag>
                            } */}
                        </Flex>
                    </FlexColumn>
                </Option>
            )
            // }
        })

        return options
    }

    getAccountOptions = (id) => {
        var Accounts = getBPAccountsWithAccess("", { resourceStr: "BusinessCards.Cards", getActiveAccounts: true }) || []
        Accounts = Accounts.filter(acc => acc.accountType !== "ACCOUNTS_RECEIVABLE")

        console.log("eligibleCardUsers Accounts", Accounts)

        var options = []
        Accounts.forEach(account => {
            options.push(
                <Option key={account.accountId} id={id} value={account.accountId} style={{ backgroundColor: "transparent" }}>
                    <FromAccountOption account={account} />
                </Option>
            )
        })
        return options
    }

    getDisabled = () => {
        const { issueTo, cardType, fromAccount, nickName, addNewCardholder, firstName, lastName, phone, email } = this.state

        if (addNewCardholder) {
            return !firstName || !lastName || !phone || !email
        } else {
            return !issueTo || !cardType || !fromAccount || !issueTo.FirstName || !issueTo.LastName || !issueTo.Phone || !issueTo.Email
        }
    }

    getButtonTitle = () => {
        return this.state.step === this.state.steps.length - 1 ? 'SUBMIT' : 'NEXT'
    }

    getLocation = (address, key) => {
        this.setState({ newAddress: address })
    }

    handleOnChange = (event) => {
        const { phoneCode } = this.state
        const target = event.target
        switch (target.id) {
            case "phone":
                console.log('formatPhoneTextV2', phoneCode.dial_code, event.target.value, formatPhoneTextV2(event.target.value))
                // var phone = (phoneCode.dial_code == '+1') ? formatPhoneTextV2(event.target.value) : event.target.value
                // phone = `${phoneCode.dial_code}${(phone.replace(/[^\d]/g, ''))}`
                // console.log("handleOnChange phone_number", phone)
                this.saveUserInput(target.id || target.name, (phoneCode.dial_code == '+1') ? formatPhoneTextV2(event.target.value) : event.target.value)
                break;
            default:
                this.saveUserInput(target.id || target.name, target.value)
                break;
        }
    }

    handleIssueToOnChange = (event) => {
        const { phoneCode, issueTo } = this.state
        const target = event.target
        switch (target.id) {
            case "phone":
                // var phone = (phoneCode.dial_code == '+1') ? formatPhoneTextV2(event.target.value) : event.target.value
                // phone = `${phoneCode.dial_code}${(phone.replace(/[^\d]/g, ''))}`
                // console.log("handleOnChange phone_number", phone)
                issueTo.Phone = (phoneCode.dial_code == '+1') ? formatPhoneTextV2(event.target.value) : event.target.value
                break;
            default:
                issueTo[target.id || target.name] = target.value
                break;
        }

        this.setState({ issueTo })
    }

    getSuccessText = () => {
        const { addNewCardholder, cardType, issueTo, email, firstName, lastName } = this.state
        var { UserInfo } = this.props.store

        const currentUser = issueTo && (UserInfo.Email === issueTo.Email)

        var cardholder
        if (issueTo) {
            cardholder = `${issueTo.FirstName} ${issueTo.LastName}`
        } else {
            cardholder = `${firstName} ${lastName}`
        }

        if (cardType === 'Virtual') {
            if (addNewCardholder) return <>Your card request has been submitted<br />and is being processed.<br /><br />Once the card has been issued, we will notify the cardholder ({email}).</>
            if (currentUser) return <>Your card request has been submitted<br />and is being processed.<br /><br />Once the card has been issued, we will send you an email ({UserInfo.Email}).</>
        } else {
            if (addNewCardholder) return <>Your card request has been submitted<br />and is being processed.<br /><br />Once the card has been issued, we will notify the cardholder ({email}).<br /><br />The card will be delivered to your company's<br />mailing address in 5-7 business days.</>
            if (currentUser) return <>Your card request has been submitted<br />and is being processed.<br /><br />Once the card has been issued, we will send you an email ({UserInfo.Email}).<br /><br />The card will be delivered to your company's<br />mailing address in 5-7 business days.</>
            return <>Your card request has been submitted<br />and is being processed.<br /><br />Once the card has been issued, we will notify the cardholder ({issueTo && issueTo.Email}).<br /><br />The card will be delivered to your company's<br />mailing address in 5-7 business days.</>
        }

        return <>Your card request has been submitted<br />and is being processed.<br /><br />Once the card has been issued, we will notify the cardholder ({issueTo && issueTo.Email}).</>
    }

    render() {
        var { step, steps, fromAccount, existingUser, issueTo, cardType, showResult, singleAccount, submitLoading, loading, deliverTo, phoneCode, incompleteUser,
            newAddress, newAddressError, nickName, nickNameError, addNewCardholder, firstName, lastName, phone, email, errorField, errorMessage } = this.state
        const { theme, bankingStore, store } = this.props
        var { business } = store
        const { businessProfile } = business
        const { businessInfo } = businessProfile
        var { UserInfo } = this.props.store

        console.log('businessInfo', businessInfo)

        var fromOptions = this.getAccountOptions("fromAccount")
        var personOptions = this.getPersonOptions()

        var disabled = this.getDisabled()
        const nickNameMaxLen = 18
        fromAccount = fromAccount || {}
        var accountNickName = `${(fromAccount.nickName || "").trim().substr(0, nickNameMaxLen)}${(fromAccount.nickName || "").trim().length > nickNameMaxLen ? "..." : ""}`

        return (
            <FlexColumn className='main-padding' style={{ width: '100%', height: '100%' }} start>
                <PageHeader
                    close
                    titleText="Issue new card"
                    back
                />

                <CardContainer padding='0' margin='0 0 24px' style={{ width: '100%', height: '100%', minWidth: 1240, minHeight: 700 }} shadow>
                    <FlexColumn between fullHeight>
                        <Flex start gap='48px' fullHeight>
                            <FlexColumn start style={{ padding: 24, height: '100%' }}>
                                {
                                    steps[step] === 'Transfer Details' &&
                                    <>
                                        <FlexColumn start>
                                            <Flex start centerHorizontally gap='24px' style={{ marginBottom: 12 }}>
                                                <LabeledInput
                                                    inputRef={this.invoiceNumberInput}
                                                    label="Issue to"
                                                    type="select"
                                                    id="issueTo"
                                                    nomargin
                                                    key="issueTo"
                                                    placeholder="Select a person"
                                                    loading={loading}
                                                    width='583px'
                                                    value={(issueTo || addNewCardholder) && <Flex between gap='24px' centerHorizontally style={{ height: '100%', width: '100%' }}>
                                                        {
                                                            addNewCardholder ?
                                                                <Text color='#7384AA'>+ New user</Text>
                                                                :
                                                                <>
                                                                    {
                                                                        issueTo.FirstName && issueTo.LastName &&
                                                                        <Text color='#7384AA'>{`${issueTo.FirstName} ${issueTo.LastName}`}</Text>
                                                                    }
                                                                    {
                                                                        !(issueTo.FirstName && issueTo.LastName) &&
                                                                        <Text color='#7384AA'>{issueTo.Email}</Text>
                                                                    }
                                                                    {
                                                                        issueTo.Email ?
                                                                            <Tag padding='0 12px' height='25px' noCap>{issueTo.Email}</Tag>
                                                                            :
                                                                            <Tag padding='0 12px' height='25px' primary>Invited</Tag>
                                                                    }
                                                                </>
                                                        }
                                                    </Flex>}
                                                    autoFocus
                                                    // instruction={(addNewCardholder || (issueTo && !issueTo.roleTags.some(r => ['Owner', 'Primary Admin', 'Company Admin', 'Banking Admin', 'Staff'].includes(r)))) && 'The Employee role will be added for this user'}
                                                    onChange={this.handleSelectPerson}
                                                // tooltip="Cards can be issued only to users with the following roles: Primary Admin, Company Admin and Banking Admin"
                                                >
                                                    {personOptions}
                                                </LabeledInput>
                                            </Flex>

                                            {/* {
                                            issueTo && !existingUser &&
                                            <Text margin='12px 0'>This person will require access to Aion to activate their card. Submitting this request will invite them to create an Aion account.</Text>
                                        }

                                        {
                                            issueTo && !existingUser &&
                                            <LabeledInput
                                                label="Email"
                                                id='recipientEmail'
                                                width='560px'
                                                value={recipientEmail}
                                                onChange={(event) => this.setState({ [event.target.id]: event.target.value })}
                                                placeholder="Enter email"
                                                error={recipientEmailError}
                                                errorMessage='Please enter a valid email'
                                            />
                                        } */}

                                            {
                                                (issueTo || addNewCardholder) &&

                                                <Flex start gap='24px'>
                                                    <LabeledInput
                                                        label="Type"
                                                        type='switch'
                                                        switchNames={['Virtual', 'Physical']}
                                                        onChange={(value) => this.setState({ "cardType": value })}
                                                        width='264px'
                                                        value={cardType} />

                                                    <LabeledInput
                                                        key="nickName"
                                                        inputRef={this.nameRef}
                                                        label='Nickname'
                                                        id="nickName"
                                                        placeholder="Enter a nickname for this card"
                                                        value={nickName && nickName}
                                                        autoFocus
                                                        onChange={this.handleTextChange}
                                                        error={nickNameError}
                                                        errorMessage="Nickname is required"
                                                        width='295px'
                                                        maxLength={30}
                                                        optional
                                                    />
                                                </Flex>
                                            }

                                            {/* {
                                            issueTo && cardType === 'Physical' &&
                                            <LabeledInput
                                                label="Deliver to"
                                                type='switch'
                                                switchNames={['Company mailing address', 'Home address']}
                                                onChange={(value) => this.setState({ "deliverTo": value })}
                                                value={deliverTo} />
                                        } */}

                                            {
                                                issueTo &&
                                                <>
                                                    {
                                                        !incompleteUser ?
                                                            <FlexColumn start>
                                                                <Flex start gap='24px'>
                                                                    <LabeledInput
                                                                        type='read-only'
                                                                        label="First Name"
                                                                        value={issueTo.FirstName}
                                                                        width='264px'
                                                                    />

                                                                    <LabeledInput
                                                                        type='read-only'
                                                                        label="Last Name"
                                                                        value={issueTo.LastName}
                                                                        width='288px'
                                                                    />
                                                                </Flex>

                                                                <Flex start gap='24px'>
                                                                    <LabeledInput
                                                                        type='read-only'
                                                                        label="Phone"
                                                                        value={issueTo.Phone && formatPhoneTextV2(issueTo.Phone)}
                                                                        width='264px'
                                                                    />

                                                                    <LabeledInput
                                                                        type='read-only'
                                                                        label="Email"
                                                                        value={issueTo.Email}
                                                                        width='298px'
                                                                        instruction={(UserInfo.Email !== issueTo.Email) && 'Cardholder will receive an invite to access their card'}
                                                                    />
                                                                </Flex>
                                                            </FlexColumn>
                                                            :
                                                            <FlexColumn start>
                                                                <Flex start gap='24px'>
                                                                    <LabeledInput
                                                                        label="First Name"
                                                                        id="FirstName"
                                                                        type="firstName"
                                                                        value={issueTo.FirstName}
                                                                        placeholder="John"
                                                                        width='264px'
                                                                        onChange={this.handleIssueToOnChange}
                                                                        error={errorField == "firstName"}
                                                                        errorMessage={errorMessage}
                                                                    />

                                                                    <LabeledInput
                                                                        label="Last Name"
                                                                        id="LastName"
                                                                        type="lastName"
                                                                        value={issueTo.LastName}
                                                                        placeholder="Smith Jr."
                                                                        width='322px'
                                                                        onChange={this.handleIssueToOnChange}
                                                                        error={errorField == "lastName"}
                                                                        errorMessage={errorMessage}
                                                                    />
                                                                </Flex>

                                                                <Flex start gap='24px'>
                                                                    <LabeledInput
                                                                        label="Phone Number"
                                                                        id="phone"
                                                                        type="phone-cc"
                                                                        placeholder="(000) 000 0000"
                                                                        onChange={this.handleIssueToOnChange}
                                                                        onCodeChange={(code) => this.saveUserInput("phoneCode", code)}
                                                                        value={phoneCode.code == 'US' ? formatPhoneTextV2(issueTo.Phone) : issueTo.Phone}
                                                                        phoneCode={phoneCode}
                                                                        error={errorField == "phone"}
                                                                        errorMessage={errorMessage}
                                                                        width='264px'
                                                                    />

                                                                    <LabeledInput
                                                                        type='read-only'
                                                                        label="Email"
                                                                        value={issueTo.Email}
                                                                        width='298px'
                                                                        instruction={(UserInfo.Email !== issueTo.Email) && 'Cardholder will receive an invite to access their card'}
                                                                    />
                                                                </Flex>
                                                            </FlexColumn>
                                                    }
                                                </>
                                            }

                                            {
                                                addNewCardholder &&
                                                <FlexColumn start>
                                                    <Flex start gap='24px'>
                                                        <LabeledInput
                                                            label="First Name"
                                                            id="firstName"
                                                            type="firstName"
                                                            value={firstName}
                                                            placeholder="John"
                                                            width='264px'
                                                            onChange={this.handleOnChange}
                                                            error={errorField == "firstName"}
                                                            errorMessage={errorMessage}
                                                        />

                                                        <LabeledInput
                                                            label="Last Name"
                                                            id="lastName"
                                                            type="lastName"
                                                            value={lastName}
                                                            placeholder="Smith Jr."
                                                            width='295px'
                                                            onChange={this.handleOnChange}
                                                            error={errorField == "lastName"}
                                                            errorMessage={errorMessage}
                                                        />
                                                    </Flex>

                                                    <Flex start gap='24px'>
                                                        <LabeledInput
                                                            label="Phone Number"
                                                            id="phone"
                                                            type="phone-cc"
                                                            placeholder="(000) 000 0000"
                                                            onChange={this.handleOnChange}
                                                            onCodeChange={(code) => this.saveUserInput("phoneCode", code)}
                                                            value={phoneCode.code == 'US' ? formatPhoneTextV2(phone) : phone}
                                                            phoneCode={phoneCode}
                                                            error={errorField == "phone"}
                                                            errorMessage={errorMessage}
                                                            width='264px'
                                                        />

                                                        <LabeledInput
                                                            label="Email"
                                                            type="email"
                                                            key="email"
                                                            id="email"
                                                            onChange={this.handleOnChange}
                                                            value={email}
                                                            placeholder="tim@mail.com"
                                                            instruction='Cardholder will receive an invite to access their card'
                                                            error={errorField == "email"}
                                                            errorMessage={errorMessage}
                                                            width='298px'
                                                        />
                                                    </Flex>
                                                </FlexColumn>
                                            }

                                            {/* {
                                                deliverTo === "New address" &&
                                                <div style={{ width: '50%' }}>
                                                    <LabeledInput
                                                        label='New address'
                                                        id="newAccount"
                                                        key="newAccount"
                                                        placeholder="Enter Address"
                                                        address={newAddress}
                                                        autoFocus
                                                        instruction='This will not update existing records'
                                                        type="location"
                                                        getLocation={(loc) => this.getLocation(loc, 'newAccount')}
                                                        error={newAddressError}
                                                        errorMessage="New address is required"
                                                    />
                                                </div>
                                            } */}

                                            {
                                                deliverTo === "Home address" && (issueTo.address.countryCode === null || issueTo.address.countryCode === 'US') &&
                                                <LabeledInput
                                                    type='read-only'
                                                    label="Home address"
                                                    value={addressObjectToMultiLineStr(issueTo.address)}
                                                />
                                            }


                                            <Flex start gap='24px'>
                                                {
                                                    (issueTo || addNewCardholder) && cardType === 'Physical' && // deliverTo === "Company mailing address" &&
                                                    <LabeledInput
                                                        type='read-only'
                                                        label="Company mailing address"
                                                        tooltip="Company mailing address that was provided during your company’s banking application"
                                                        value={addressObjectToMultiLineStr(businessInfo.mailingAddress)}
                                                        width='264px'
                                                    />
                                                }

                                                {
                                                    (issueTo || addNewCardholder) && // cardType === 'Physical' &&
                                                    <LabeledInput
                                                        type='read-only'
                                                        label="Billing address"
                                                        value={addressObjectToMultiLineStr(businessInfo.mailingAddress)}
                                                        width='288px'
                                                    />
                                                }
                                            </Flex>

                                            {
                                                (issueTo || addNewCardholder) && cardType && (singleAccount !== true) &&
                                                <LabeledInput
                                                    inputRef={this.invoiceNumberInput}
                                                    label="Associated account"
                                                    type="select"
                                                    id="fromAccount"
                                                    key="fromAccount"
                                                    placeholder="Select account"
                                                    width='280px'
                                                    value={fromAccount.accountId && <><img width='16px' height='16px' src={AionIcon} style={{ marginRight: 8, marginBottom: 2 }} /><Text color='#7384AA' title={`${(fromAccount.nickName || `Business ${fromAccount.accountSubType}`)} • ${fromAccount.mask}`}>{`${(accountNickName || `Business ${fromAccount.accountSubType}`)} • ${fromAccount.mask}`}</Text></>}
                                                    autoFocus
                                                    loading={loading}
                                                    onChange={this.handleSelectAccount}
                                                >
                                                    {fromOptions}
                                                </LabeledInput>
                                            }
                                        </FlexColumn>
                                    </>
                                }
                            </FlexColumn>

                            {
                                (issueTo || addNewCardholder) && cardType && cardType === 'Virtual' &&
                                <FlexColumn className='info-panel-back' start style={{ padding: '32px 48px' }} gap='8px'>
                                    <Text heading color='#666666'>Virtual Cards For Every Business Need</Text>
                                    <Text color='#666666'>Enjoy the convenience and security of a virtual card for different categories of business expenses</Text>
                                    <FlexColumn start gap='24px' style={{ marginTop: 12 }}>
                                        <Flex gap='8px'>
                                            <Image src={Check} />
                                            <Text color='#666666'>Flexibility: Use your virtual card for online payments, subscriptions, and recurring transactions or, issue one for each department to manage their expenses</Text>
                                        </Flex>
                                        <Flex gap='8px'>
                                            <Image src={Check} />
                                            <Text color='#666666'>Instant Card Issuance: Cards are available immediately and ready to be activated for your usage</Text>
                                        </Flex>
                                        <Flex gap='8px'>
                                            <Image src={Check} />
                                            <Text color='#666666'>Security: Extra layer of security, protecting your sensitive payment information with enhanced fraud mitigation using our embedded security features</Text>
                                        </Flex>
                                        <Flex gap='8px'>
                                            <Image src={Check} />
                                            <Text color='#666666'>Easy Expense Tracking: Each transaction made with your virtual card will be clearly recorded, making it simple for you to track and manage your business expenses</Text>
                                        </Flex>
                                    </FlexColumn>
                                </FlexColumn>
                            }

                            {
                                (issueTo || addNewCardholder) && cardType && cardType === 'Physical' &&
                                <FlexColumn className='info-panel-back' start style={{ padding: '32px 48px' }} gap='8px'>
                                    <Text heading color='#666666'>Corporate Cards For Every Business Need</Text>
                                    <Text color='#666666'>Easy access, enhanced security, and seamless expense tracking for business expenses</Text>
                                    <FlexColumn start gap='24px' style={{ marginTop: 12 }}>
                                        <Flex gap='8px'>
                                            <Image src={Check} />
                                            <Text color='#666666'>Convenience: Tap or swipe, you can use a variety of ways to pay using your debit card</Text>
                                        </Flex>
                                        <Flex gap='8px'>
                                            <Image src={Check} />
                                            <Text color='#666666'>Easy Expense Management: No need to sort through numerous receipts or dig through piles of paperwork. Our business debit card provides detailed transaction records, making expense tracking effortless and real-time</Text>
                                        </Flex>
                                        <Flex gap='8px'>
                                            <Image src={Check} />
                                            <Text color='#666666'>Security: You card comes with EMV chip technology. This provides a higher level of security compared to traditional magnetic stripe cards and are widely accepted across the world</Text>
                                        </Flex>
                                        <Flex gap='8px'>
                                            <Image src={Check} />
                                            <Text color='#666666'>Additional controls: Lock and unlock feature to freeze/unfreeze the card and set PIN easily</Text>
                                        </Flex>
                                    </FlexColumn>
                                </FlexColumn>
                            }
                        </Flex>

                        <FlowFooter
                            disabled={disabled}
                            loading={submitLoading}
                            back={false}
                            solid={!disabled}
                            backClick={this.handleBack}
                            buttonTitle={'Submit'}
                            onClick={this.onSubmit} />
                    </FlexColumn>
                </CardContainer>

                <Modal
                    visible={showResult}
                    footer={null}
                    closable={false}
                    width={510}
                    destroyOnClose={true}
                    closeIcon={<Image src={ModalClose} />}
                >
                    <SuccessModal
                        title='Processing Card'
                        description={this.getSuccessText()}
                        descWidth={'470px'}
                        buttonTitle='OKAY'
                        onConfirm={() => {
                            // if (this.props.location.state && this.props.location.state.backOnConfirm === true) {
                            if (issueTo && (UserInfo.Email === issueTo.Email)) {
                                // window.history.back()
                                this.props.history.replace({
                                    pathname: '/cards/my-cards',
                                    state: { all: true }
                                })
                            } else {
                                this.props.history.replace({
                                    pathname: '/cards/all',
                                    state: { all: true }
                                })
                            }
                            // } else {
                            //     window.history.back()
                            // this.props.history.replace('/settings/manage-cards')
                            // }
                        }} />
                </Modal>
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer,
        bankingStore: state.bankingAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)))