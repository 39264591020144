import React, { Component } from 'react';
import { connect } from 'react-redux';
import { saveAs } from 'file-saver'
import { PlaidLink } from "react-plaid-link";
import { withTheme } from 'styled-components';
import { message, Divider, Modal, Upload, Table, Select, Skeleton, Space, Popconfirm, Badge } from 'antd';
import { PlusOutlined } from '@ant-design/icons'
import { Flex, FlexColumn, } from '../../../Reusable/Container';
import { LightContainer } from '../../../Reusable/Container';

import FlowViewComponent from '../../../Reusable/Refresh/FlowViewComponent';
import { Text } from '../../../Reusable/Refresh/Text';
import { Button, TextButton } from '../../../Reusable/Refresh/Button';
import { Image } from '../../../Reusable/Image';
import { LabeledInput } from '../../../Reusable/Input';
import environment from '../../../../environment';
import { apiGET, apiPOST, apiPOSTReq, getPreSignedS3Url } from '../../../../Utils/api';
import { toCurrency } from '../../../../Utils/util';
import { ErrorAlert } from '../../../Reusable/Alert';
import { CloseOutlined, FilePdfOutlined, DeleteOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import DownloadIcon from '../../../../Images/download.png';
import File from '../../../../Images/file.png'
import BankIcon from '../../../../Images/bank-generic.png'
import Connection from '../../../../Images/connection.png'
import ModalClose from '../../../../Images/modal-close.png';
import infoRed from '../../../../Images/info-red.png'
import Info from '../../../Reusable/Info';
import moment from 'moment';
// Actions
import { addDataToStore, CREDIT_SAVE_DATA, UAM_SAVE_BUSINESS, ONB_SAVE_DATA } from '../../../../Actions/actions';
var businessDocs = [
    { name: "2022 Tax Returns" },
    { name: "2021 Tax Returns" },
    { name: "BankStatement" }
];
const { Option } = Select
class BusinessInformation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            submitLoading: false,
            submitPlaidLoading: false,
            creditOnboarding: (this.props.loanApplication || {}).applicationId,
            loading: false,
            valid: false,
            uploadList: [],
            debtOverview: [],
            selectedContractOwner: "" || (this.props.store.business.businessProfile || {}).coOwnerApplicantInfo ? (this.props.store.business.businessProfile.coOwnerApplicantInfo || []).filter(owner => owner.isContractOwner == true).length > 0 ? (this.props.store.business.businessProfile.coOwnerApplicantInfo.filter(owner => owner.isContractOwner == true)[0].email) : "" : "",
            gnplLow: 0,
            gnplHigh: 100001,
            showBankDetails: false,
            reconnectBank: false,
            hideError: true
        }
    }

    componentDidMount() {
        this.fetchBanks();
        this.fetchDocuments();

        var { store } = this.props;
        var { business } = store;
        var businessProfile = (business || {}).businessProfile || {};
        if (businessProfile.primaryApplicantInfo != undefined) {
            if (Number(businessProfile.primaryApplicantInfo.ownershipPercentage) <= 25) {
                var coOwnerApplicantInfo = businessProfile.coOwnerApplicantInfo || [];

                if (coOwnerApplicantInfo.length > 0) {
                    this.setState({ beneficialOwners: this.fetchOwners(businessProfile.coOwnerApplicantInfo || []) })
                }
                else {
                    this.setState({ filteredContractOwner: businessProfile.primaryApplicantInfo })
                }
            } else {
                this.setState({ filteredContractOwner: businessProfile.primaryApplicantInfo })
            }
        }

        this.fetchBusinessAttributes();
    }

    fetchBusinessAttributes = () => {
        var body = {
            "clientBusinessId": ''
        };

        this.setState({ attributesLoading: true });
        apiPOSTReq(`${environment.opsBaseUrl}/ops/fetchBusinessAttributes`, null, body, (err, resp) => {
            try {
                if (err) throw Error(err);
                const data = resp || {};
                if (data.result) {
                    var lineSetup = data.businessAttributes.filter(x => x.name == "GNPLAutomatedLineSetupFlow");
                    if (lineSetup.length > 0) {
                        var attribute = lineSetup[0];
                        var additionalParameterReference = attribute.additionalParameterReference;
                        var parameters = additionalParameterReference.parameters;
                        var low = parseInt(parameters[0].value);
                        var high = parseInt(parameters[1].value);
                        this.setState({ gnplLow: low, gnplHigh: high })
                    }
                } else {
                    throw Error(data.responseMessage || data.error);
                }
            } catch (error) {
                console.log("/generateDebtSchedule err", error, resp);
            }
        });
    }

    fetchBusinessData = () => {
        apiPOSTReq(`${environment.uamBaseUrl}/getBusinessOwnership`, {}, {}, (err, resp) => {
            try {
                if (err) throw Error(err);
                const data = resp || {};
                if (data.result) {
                    var business = data.business || {}
                    this.props.dispatch(addDataToStore(UAM_SAVE_BUSINESS, data));
                } else {
                    throw Error(data.responseMessage || data.error);
                }
            } catch (error) {
                console.log("/getBusinessOwnership err", error, resp);
            }
        });
    }
    fetchOwners = (coOwnerApplicantInfo) => {
        var beneficialOwners = [];
        var filteredcoOwners = coOwnerApplicantInfo.filter(owner => Number(owner.ownershipPercentage) >= 25)

        if (filteredcoOwners.length > 0) {
            filteredcoOwners.map(owner => beneficialOwners.push(owner));
        }
        return beneficialOwners;
    }
    setErrorField = (id, msg) => this.setState({ errorField: id, errorMessage: msg });

    validateFlow = () => {
        var { flowView, proposalTerms } = this.props;
        var { creditLimit } = proposalTerms;
        var { selectedContractOwner, gnplHigh, submitLoading } = this.state;
        var beneficialOwners = this.state.beneficialOwners || [];

        if (!submitLoading) {
            this.setState({ submitLoading: true });

            this.fetchDocuments();
            // if ((this.props.creditStore.BankInfo || []).length === 0) {
            //     ErrorAlert({ description: "Please connect your account" });
            //     return;
            // }

            var docToBeValidated = businessDocs.slice(0, 2)
            /*  if (creditLimit < gnplHigh) {
                  docToBeValidated = docToBeValidated.filter(doc => doc.name != "Company Debt Schedule");
              }*/

            if (docToBeValidated.find(item => {
                if (!this.getDocument(item.name).fileName) {
                    return true;
                }
            })
            ) {
                ErrorAlert({ description: "Please upload all documents" });
                return;
            }

            // PO owns <25 && Co-Owners added
            if (creditLimit < gnplHigh && beneficialOwners.length > 0 && selectedContractOwner == "") {
                ErrorAlert({ description: "Please select authorized signer" });
                return;
            }
            // PO owns <25 && No Co-Owners added
            if (!(creditLimit < gnplHigh && beneficialOwners.length == 0) || this.state.filteredContractOwner) {
                this.updateContractOwnerInfo([]);
            } else {
                this.props.loadNext();
            }
        }
    }

    updateContractOwnerInfo(contractOwnerApplicantInfo) {
        const { loanApplication } = this.props.creditStore;
        var info = {};
        if (contractOwnerApplicantInfo.length > 0) {
            info = contractOwnerApplicantInfo[0];
        } else {

            info = (this.props.store.business.businessProfile || {}).coOwnerApplicantInfo ? (this.props.store.business.businessProfile.coOwnerApplicantInfo || []).filter(owner => owner.isContractOwner == true).length > 0 ? this.props.store.business.businessProfile.coOwnerApplicantInfo.filter(owner => owner.isContractOwner == true)[0] : this.state.filteredContractOwner : this.state.filteredContractOwner
        }
        var body = {
            "clientBusinessId": loanApplication.businessId,
            "contractOwnerInfo": info
        };
        console.log("/business/admin/updateContractOwner body", body);
        this.setState({ loading: true });
        apiPOSTReq(`${environment.uamBaseUrl}/business/admin/updateContractOwner`, {}, body, (err, resp) => {
            this.setState({ loading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    apiPOSTReq(`${environment.uamBaseUrl}/getBusinessOwnership`, {}, {}, (err, resp) => {
                        try {
                            if (err) throw Error(err);
                            const data = resp || {};
                            if (data.result) {
                                var business = data.business || {}
                                this.props.dispatch(addDataToStore(UAM_SAVE_BUSINESS, data));
                            } else {
                                throw Error(data.responseMessage || data.error);
                            }
                        } catch (error) {
                            console.log("/getBusinessOwnership err", error, resp);
                        }
                    }); if (contractOwnerApplicantInfo.length == 0) {
                        this.props.loadNext();
                    }
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }
    fetchBankInfo() {
        const headers = {
            businesskey: this.props.store.BusinessUniqueKey
        }
        apiGET("/bankinfo", headers, (err, resp) => {
            try {
                const data = resp.data
                this.props.dispatch(addDataToStore(CREDIT_SAVE_DATA, data))
            } catch (error) {
                this.setState({ showConnectionErrorModal: true })
                console.log("ERROR", error.stack)
            }
        })
    }

    generateExternalBankStatement(institutionId) {
        let { loanApplication } = this.props.creditStore;
        loanApplication = loanApplication || {};
        const body = {
            loanApplicationId: loanApplication.applicationId,
            institutionId,
        }
        console.log('body', body)
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/plaid/getExternalBankTxn`, {}, body, (err, resp) => {
            try {
                const data = resp || {};
                if (!data.result) {
                    //ErrorAlert({ description: data.responseMessage || "Sorry, we had trouble processing your request. Please try again." });
                    this.setState({ showConnectionErrorModal: true })
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp);
                // ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
                this.setState({ showConnectionErrorModal: true })
            }
        });
    }

    plaidOnSuccess = (public_token, metadata) => {
        const headers = {
            businesskey: this.props.store.BusinessUniqueKey
        }
        var body = {
            data: {
                publicToken: public_token,
                accountId: metadata.account.id,
                accountName: metadata.account.name,
                institution: metadata.institution.name,
                institutionId: metadata.institution.institution_id,
            }
        }
        this.setState({ submitPlaidLoading: true });
        apiPOST("/plaidauthenticate", headers, body, (err, resp) => {
            try {
                if (err) throw Error(err);
                const data = resp.data;
                this.setState({ submitPlaidLoading: false });
                if (data.success) {
                    this.fetchBankInfo();
                    this.generateExternalBankStatement(metadata.institution.institution_id);
                } else {
                    this.setState({ showConnectionErrorModal: true })
                }
            } catch (error) {
                this.setState({ showConnectionErrorModal: true })
            }
        });
    }

    logPlaidEvent = (eventName, metadata) => {
        apiPOST("/logError", {}, { logType: "PLAID", eventName: eventName, metadata: metadata }, (err, resp) => {
            console.log("Plaid event logged", err, resp);
        });
    }

    fetchDocuments = () => {
        const { loanApplication } = this.props.creditStore;
        var body = {
            "sortDirection": "DESC"
        };
        if (loanApplication) {
            body["clientBusinessId"] = loanApplication.businessId;
            body["applicationId"] = loanApplication.applicationId;
            body["viewBy"] = "FILTER_APPLICATION"
        }
        console.log("/docs/getActiveDocuments body", body, loanApplication);
        apiPOSTReq(`${environment.uamBaseUrl}/docs/getActiveDocuments`, {}, body, (err, resp) => {
            console.log("/docs/getActiveDocuments resp", resp);
            this.setState({ submitLoading: false });

            try {
                const data = resp || {};
                if (data.result) {
                    this.setState({
                        businessDocuments: data.businessDocuments
                    });
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    onUploadBankStatementSubmit = () => {
        var { uploadList, bankName } = this.state;

        if (!bankName) {
            ErrorAlert({ description: "Please enter bank name." });
            return false;
        }
        if (uploadList.length === 0) {
            ErrorAlert({ description: "Please upload bank statement." });
            return false;
        }
        var valid = this.validateUpload({});

        if (valid) {
            var docIds = [];

            uploadList.map(doc => {
                docIds.push(doc.documentId);
            })
            const { bankName } = this.state;
            var body = {
                "bankName": bankName,
                "documentIds": docIds
            }
            console.log("handleSubmit DOC/SAVE uploadItem body", body);
            apiPOSTReq(`${environment.uamBaseUrl}/docs/uploadBankStatements`, {}, body, (err, resp) => {
                this.setState({ loadingItem: null });
                try {
                    const data = resp || {};
                    if (data.result) {
                        message.success(`Uploaded successfully.`, 0.75);
                        this.setState({
                            showManualUploadModal: false,
                            uploadList: [], bankName: ""
                        })
                        this.fetchDocuments();

                    } else {
                        throw Error(data.responseMessage || data.error)
                    }
                } catch (error) {
                    console.log("/docs/save err", error, resp);
                    ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
                }
            });
        }
    }
    handleDocSubmit = (uploadItem, uploadCategory) => {
        var { loadingItem } = uploadItem || {};
        var { loanApplication } = this.props.creditStore;
        var businessDocument = {
            "documentUrl": uploadItem.savedFile || {},
            "name": uploadItem.name
        };
        if (uploadItem.name === "BankStatement") {
            businessDocument.category = "DueDiligence"
            businessDocument.type = uploadItem.name
        } else {
            businessDocument.category = "Business Finanicals"
        }
        if (loanApplication) businessDocument.applicationId = loanApplication.applicationId;
        if (uploadItem.effectivePeriod) businessDocument.effectivePeriod = uploadItem.effectivePeriod
        var body = {
            "businessDocument": businessDocument
        }
        console.log("handleSubmit DOC/SAVE uploadItem body", uploadItem, body);
        apiPOSTReq(`${environment.uamBaseUrl}/docs/save`, {}, body, (err, resp) => {
            this.setState({ uploadLoading: false, uploadCategory: uploadCategory });
            try {
                const data = resp || {};
                var uploadList = this.state.uploadList;
                if (data.result) {
                    message.success(`File saved successfully.`, 0.75);
                    uploadList.push(data.businessDocument);
                    this.setState({ uploadList: uploadList })
                    this.parseDocument(uploadItem)
                    this.fetchDocuments();
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/docs/save err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    parseDocument = (doc) => {
        var { loanApplication } = this.props.creditStore;
        var body = {
            "attachmentUrl": doc.savedFile.uri || {},
            "documentType": doc.name,
            "businessId": loanApplication.businessId,
            "applicationId": loanApplication.applicationId
        }

        console.log("handleSubmit DOC/SAVE uploadItem body", body);
        apiPOSTReq(`${environment.parserUrl}/parseTaxReturns`, {}, body, (err, resp) => {
            // try {
            //     const data = resp || {};
            //     if (data.result) {
            //     } else {
            //         throw Error(data.responseMessage || data.error)
            //     }
            // } catch (error) {
            //     console.log("/docs/save err", error, resp);
            //     ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            // }
        });
    }

    validateUpload = (uploadedDoc) => {

        var { businessDocuments, bankName, uploadList } = this.state;
        var filteredDocs = [];
        var duplicateDocs = [];
        var alreadySavedDocs = [];

        if (!bankName) {
            ErrorAlert({ description: "Please enter bank name." });
            return false;
        }
        if (Object.keys(uploadedDoc).length > 0) {
            duplicateDocs = uploadList.filter(file => file.documentUrl.fileName === uploadedDoc.name)
            alreadySavedDocs = businessDocuments.filter(doc => (doc.bankName === bankName) && (doc.documentUrl.fileName === uploadedDoc.name))
        }
        if (duplicateDocs.length > 0 || alreadySavedDocs.length > 0) {
            ErrorAlert({ description: `A file with this name already exists: ${uploadedDoc.name}. Please review and upload the correct document.` });
            return false;
        }

        filteredDocs = businessDocuments.filter(doc => (doc.bankName || '').toLowerCase() === bankName.toLowerCase())

        if ((filteredDocs.length + uploadList.length) > (Object.keys(uploadedDoc).length > 0 ? 14 : 15)) {
            ErrorAlert({ description: "To add more bank statements, please remove one of your files and upload a zip (compress) of the remaining statements." });
            return false;
        }
        else {
            return true;
        }
    }
    uploadProps = (doc, uploadCategory) => {
        return {
            name: `business-docs`,
            action: `${environment.apiBaseUrl}/file/upload`,
            headers: {
                businesskey: this.props.store.BusinessUniqueKey,
                jwt: this.props.store.jwt
            },
            beforeUpload: (doc) => {
                var { valid } = this.state;
                this.setState({ valid: uploadCategory === "BankStatement" ? this.validateUpload(doc) : valid })
                const isPDF = doc.type === 'application/pdf';
                const isXLSX = doc.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                const isZip = doc.type === 'application/zip';

                if ((uploadCategory === "BankStatement") && !isPDF && !isZip) {
                    message.error("Uploaded document is not a pdf/zip file");
                    return isPDF || Upload.LIST_IGNORE;
                } else if ((uploadCategory != "BankStatement") && !isXLSX && !isPDF) {
                    message.error("Uploaded document is not a pdf/xlsx file");
                    return isPDF || isXLSX || Upload.LIST_IGNORE;
                }

                var maxFileSize = 10240; // 10MB

                const uploadedFileSizeKiloBytes = doc.size / 1024

                if (uploadedFileSizeKiloBytes > maxFileSize) {
                    message.error(`File size is greater than 10MB`);
                    this.setState({ uploadLoading: false, uploadingItem: uploadCategory });

                    return false;
                }
            },
            showUploadList: false,
            onChange: (info) => {
                const { valid } = this.state;
                if ((uploadCategory === "BankStatement" && valid) || uploadCategory != "BankStatement") {
                    {
                        this.setState({ uploadLoading: true, uploadCategory: uploadCategory });
                        var savedFile = {};
                        var file = info.file;
                        var { status, response, name, url } = file;
                        if (status === "done") {
                            savedFile = {
                                fileName: name,
                                uri: (response.UploadedUrls || [])[0],
                            }
                            if (doc.type) savedFile.type = doc.type;
                            // message.success(`${info.file.name} file uploaded successfully.`, 0.75);
                            doc.savedFile = savedFile;
                            this.handleDocSubmit(doc);

                        } else if (status === 'error') {
                            this.setState({ uploadLoading: false });
                            message.error(`${info.file.name} file upload failed.`);
                        }


                        if (!status) {
                            this.setState({ uploadLoading: false });
                        }
                        if (((Object.keys(savedFile)).length) !== 0) {
                            doc.savedFile = savedFile;
                        }
                        this.setState({ [doc.name]: doc });
                    }
                }
            },
            accept: ".pdf, .xlsx,.zip",
            onRemove: (file) => {
                this.handleDeleteDoc(doc);
            }
        }
    };

    getDocument = (name, options) => {
        options = options || {};
        var { businessDocuments } = this.state;
        var savedDoc = this.state[name] || {};
        var filteredDoc = (businessDocuments || []).find(item => (item.name == name));
        if (filteredDoc != undefined) {
            // Replace with remotely stored data
            if (options.businessDocument) savedDoc = filteredDoc;
            else savedDoc = filteredDoc.documentUrl || {};
        }
        return savedDoc;
    }

    handleDeleteDoc = (uploadItem) => {
        var { loanApplication } = this.props;
        var businessDocument = {
            "name": uploadItem.name
        };

        if (loanApplication) businessDocument.applicationId = loanApplication.applicationId;
        var body = {
            "businessDocument": businessDocument,
            "documentId": uploadItem.documentId
        }

        console.log("handleSubmit DOC/delete uploadItem body", body);
        apiPOSTReq(`${environment.uamBaseUrl}/docs/delete`, {}, body, (err, resp) => {
            this.setState({ loadingItem: null });
            try {
                const data = resp || {};
                if (data.result) {
                    message.success(`File Deleted successfully.`, 0.75);
                    this.fetchDocuments();
                    let doc = this.state[uploadItem.name] || {};
                    doc.savedFile = {};
                    this.setState({ [doc.name]: doc });

                    // multiple doc delete and updated in the filelist
                    let removedList = this.state.uploadList.filter(item => {
                        if (item.documentId !== uploadItem.documentId) return item
                    });
                    this.setState({ uploadList: removedList });
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/docs/deleteUpload err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    downloadStatement(uri, name) {
        return new Promise((resolve, reject) => {
            getPreSignedS3Url({ url: uri }, (err, preSignedUrl) => {
                if (!err) {
                    saveAs(preSignedUrl, name);
                } else {
                    reject(JSON.stringify(err))
                }
            })
        })
    }

    onClickManualUploadModal = () => {
        this.setState({ showManualUploadModal: true, uploadList: [], bankName: "" });
    }

    onClickConnectionErrorModal = () => {
        this.setState({ showConnectionErrorModal: true });
    }

    handleChange = (event) => {
        this.setState({ [event.target.id]: event.target.value });
    }

    handleSelectContractOwner = (value) => {
        var filteredContractOwner = this.state.beneficialOwners.filter(owner => owner.email === value);

        this.setState({ selectedContractOwner: value });
        this.setState({ filteredContractOwner: filteredContractOwner })
        this.updateContractOwnerInfo(filteredContractOwner);

    }

    fetchBanks = () => {
        const headers = {}

        const body = {
            "BusinessId": this.props.store.businessId
        }
        this.setState({ loadingBanks: true })
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/plaid/getInstitutionInfo`, headers, body, (err, resp) => {
            try {
                const data = resp;
                this.props.dispatch(addDataToStore(CREDIT_SAVE_DATA, data))
                console.log("fetchBankInfo", data.externalBBInstitutions)
                this.setState({ banks: data.externalBBInstitutions })
            } catch (error) {
                console.log("ERROR", error.stack)
            } finally {
                this.setState({ loadingBanks: false })
            }
        })
    }

    getPlaidAccessToken = (public_token, metadata, includeAuth, accessToken) => {
        const { loanApplication } = this.props.creditStore;
        var header = {}
        var body = {
            "redirectUri": window.location.href,
            "plaidTokenData": {
                publicToken: public_token,
                accountId: metadata.account.id,
                accountName: metadata.account.name,
                institution: metadata.institution.name,
                institutionId: metadata.institution.institution_id,
            },
            "loggedInUserId": this.props.store.UserInfo.Email,
            "BusinessId": this.props.store.businessId,
            "includeAuth": includeAuth,
            "accessToken": accessToken,
            "LoanApplicationId": loanApplication.applicationId

        }


        this.setState({ loadingBanks: true })
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/plaid/getAccessToken`, header, body, (err, resp) => {
            try {
                const response = resp || {};
                if (response.result) {
                    this.fetchBanks()
                    this.setState({ showBankDetails: false, hideError: true })
                } else {
                    ErrorAlert({ description: response.responseMessage });
                    this.setState({ loadingBanks: false, hideError: false })
                }
            }
            catch (err) {

            }

        })

    }

    logPlaidEvent = (eventName, metadata) => {
        apiPOST("/logError", {}, { logType: "PLAID_DESKTOP", eventName: eventName, metadata: metadata }, (err, resp) => {
            console.log("Plaid event logged", err, resp);
        });
    }

    handleBankDetails = (bankInfo) => {
        const headers = {}

        const body = {
            "itemId": bankInfo.itemId,
            "redirectUri": window.location.href,
            "connectionStatus": bankInfo.status

        }

        console.log("Metabody:", body)
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/plaid/getAccountDetails`, headers, body, (err, resp) => {
            try {
                const response = resp || {};
                if (response.result) {
                    this.setState({
                        accountDetails: response.accountsList, showBankDetails: true, bankStatus: bankInfo.status, showConnectionErrorModal: false,
                        bankUrl: bankInfo.logoUrl, bankName: bankInfo.bankName, bankLastUpdated: bankInfo.lastUpdatedAt, relinkToken: response.linkToken,
                        bankAccessToken: bankInfo.accessToken, reconnectBank: false, itemId: bankInfo.itemId
                    })
                    if (bankInfo.status != "Connected") {
                        this.setState({ reconnectBank: true })
                    }
                } else {
                    ErrorAlert({ description: response.responseMessage });
                }
            }
            catch (err) {

            }

        })

    }

    closeBankDetails = () => {
        this.setState({ showBankDetails: false })
    }

    render() {
        var { flowView, currentViewIndex, loadPrevView, loadNext, flowSteps, onboardingStore, theme, reviewView, store, proposalTerms } = this.props
        var { showBankDetails, reconnectBank, loadingBanks, errorMessage, accountDetails, bankUrl, bankName, bankLastUpdated, relinkToken, bankAccessToken,
            submitLoading, uploadList, submitPlaidLoading, beneficialOwners, selectedContractOwner,
            debtOverview, debtName, outstandingDebt, debtNote, debtAddloading, collateralType, gnplHigh } = this.state;
        flowSteps = flowSteps || []
        flowView = flowView || {}
        var bankInfos = this.props.creditStore.externalBBInstitutions || [];
        var businessDocuments = this.state.businessDocuments || [];
        var { business } = store;
        var businessProfile = (business || {}).businessProfile || {};
        const AccountDetailColumns = [
            {
                title: 'Title',
                dataIndex: 'title',
                align: 'left'
            },
            {
                title: 'Account Ending',
                dataIndex: 'mask',
                align: 'left'
            },
            {
                title: 'Available Balance',
                dataIndex: 'availableBalance',
                align: 'left',
                render: (availableBalance, record) => {
                    return (formatter.format(availableBalance ? availableBalance : record.currentBalance))
                },
            }
        ]

        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
        });

        let connectedBanks = (bankInfos).map(bankInfo => {
            return (

                <LightContainer key={bankInfo.InstitutionId} padding='5px 0' margin='0 5px 5px 0' height='56px' noBorder='true'>
                    <Flex style={{ height: '100%' }} noMargin row between>


                        <FlexColumn start style={{ width: `75%`, flexDirection: "row" }} gap="10px" >
                            {/* {
                                bankInfo.logoUrl &&
                                <img style={{ marginRight: 8 }} height='24px' src={BankIcon} />
                            }
                            {
                                bankInfo.Institution &&
                                <Text lightText noWrap start style={{ marginRight: 8 }}>{bankInfo.Institution}</Text>
                            } */}

                            {
                                bankInfo.logoUrl && bankInfo.bankName &&
                                <Button type="primary" icon={<img style={{ marginRight: 8 }} height='24px' src={`${bankInfo.logoUrl}` || BankIcon} />} text={bankInfo.bankName} rightIcon={bankInfo.status == "Connected" ? <></> : <ExclamationCircleFilled style={{ color: 'orange' }} />} onClick={() => this.handleBankDetails(bankInfo)} />
                            }
                        </FlexColumn>
                        <FlexColumn style={{ width: `25%`, flexDirection: "column" }}>
                            {/* {
                                bankInfo.Accounts[0] &&
                                <Text lightText noWrap style={{ marginRight: 8 }}>{bankInfo.Accounts[0].Mask}</Text>
                            }                             */}
                        </FlexColumn>
                    </Flex>
                </LightContainer>
            )
        }
        )
        var filteredBanks = [];
        var filteredDocs = [];
        if (businessDocuments) {
            filteredBanks = businessDocuments.map(item => item.bankName)
                .filter((value, index, self) => self.indexOf(value) === index).slice(1)
        }
        let uploadedStatements =
            (filteredBanks).map(bank => {
                var bdocs = businessDocuments.filter(doc => doc.bankName === bank);
                return (
                    <FlexColumn style={{ width: "100%", margin: "12px 0 0" }}>
                        <Text weight="400" color="#7384AA" style={{ fontSize: "14px", }} >{bank}</Text>
                        <Flex wrap start style={{ width: '100%', padding: "4px", marginTop: 8, gap: 18 }}>
                            {
                                bdocs.map(doc => {
                                    return (
                                        <TextButton style={{ textAlign: "left", padding: "8px 8px" }} type="link" icon={<Image src={File} />}
                                            rightIcon={reviewView ? null : <CloseOutlined onClick={() => this.handleDeleteDoc(doc)} />}
                                            text={doc.displayName} ></TextButton>

                                    )
                                })
                            }
                        </Flex>
                    </FlexColumn>
                )
            })

        var items = [];
        if (beneficialOwners) {
            if (beneficialOwners.length > 0) {

                beneficialOwners.forEach(item => {
                    items.push(
                        <Option key={item.email} name={item.email} value={item.email}>
                            {item.firstName + " " + item.lastName}
                        </Option>
                    )
                })
            }
            else {
                if (businessProfile.primaryApplicantInfo != undefined) {
                    this.setState({ filteredContractOwner: businessProfile.primaryApplicantInfo });
                }
            }
        }
        var childViews = (
            <FlexColumn start style={{ marginTop: "0px", minHeight: '100%', padding: "24px" }}>
                {/* <Flex start fullWidth >
                    <FlexColumn style={{ width: "100%", margin: "12px 0 0" }}>
                        <Flex noMargin row between>
                            <FlexColumn style={{ width: `75%`, flexDirection: "column" }} >
                                <Text margin="0px 0px 12px 0px" weight='500' spacing="0.08em" color='#7384AA' >BUSINESS BANK STATEMENTS</Text>
                                <Text size="16px" width="75%" margin="0px 0px 12px 0px" >Connect all your bank accounts so we can automatically retrieve you bank statements. Alternately choose to upload each of your bank account statements manually </Text>
                            </FlexColumn>
                            <FlexColumn style={{ width: `25%`, flexDirection: "column" }}>
                            </FlexColumn>
                        </Flex>

                        <FlexColumn left start gap="24px" style={{ flexDirection: "row" }}>
                            <PlaidLink
                                {...environment.plaid}
                                selectAccount="false"
                                clientName="Aion"
                                isWebview="true"
                                apiVersion="v2"
                                onSuccess={(public_token, metadata) => {
                                    this.setState({showConnectionErrorModal: false})
                                    this.getPlaidAccessToken(public_token, metadata, true, null)}
                                }
                                onExit={() => {
                                    if(this.state.hideError) {
                                        this.setState({ showConnectionErrorModal: true })
                                    }}
                                }
                                style={{ border: "none", background: "none", paddingLeft: 0, paddingBottom: 0, margin: '0px 0px 0px 0px', fontSize: 18, cursor: 'pointer' }}
                                onEvent={this.logPlaidEvent}
                            >
                                <Button loading={submitPlaidLoading} margin='0 0 0 0' padding='0 0 0 0' width='350px' weight='500' height='40px' radius='4px' solid text={bankInfos.length ? "CONNECT ADDITIONAL ACCOUNTS" : "CONNECT YOUR ACCOUNT"} icon={<Image src={Connection} style={{ cursor: 'pointer' }} width='24px' height='24px' />} ></Button>
                            </PlaidLink>
                            <TextButton width="147px" size="16px" style={{ textAlign: "left", padding: "14px 14px" }} type="link"
                                onClick={() => this.onClickManualUploadModal()} text="UPLOAD MANUALLY"></TextButton>


                        </FlexColumn>
                        <Flex wrap start style={{ margin: '12px 0 0' }}>
                            {connectedBanks}
                            {uploadedStatements}
                        </Flex>
                    </FlexColumn>
                </Flex>
                <Divider />
                <Modal
                    visible={this.state.showManualUploadModal}
                    footer={null}
                    closable={true}
                    width={624}
                    destroyOnClose={true}
                    onCancel={() => {
                    }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} onClick={() => this.setState({ showManualUploadModal: false, uploadList: [], bankName: "" })} />}
                >
                    <FlexColumn start>
                        <Text weight="500" style={{ marginBottom: 20 }} size='20px'>Upload Bank Statements</Text>
                        <LabeledInput
                            autoFocus
                            label="Bank Name"
                            labelcolor={theme.colors.secondary3}
                            id="bankName"
                            placeholder="Enter the name of the bank"
                            onChange={this.handleChange}
                            errorMessage={errorMessage}
                        />
                        <LabeledInput
                            {...this.uploadProps(businessDocs[2], businessDocs[2].name)}
                            type='upload'
                            label='Bank Statements'
                            uploadText='Accepted file types include PDF and ZIP. File size cannot exceed 10 M.B'
                            loading={this.state.uploadCategory === businessDocs[2].name ? this.state.uploadLoading : false}
                        />
                        <Flex start style={{ flexWrap: "wrap", padding: "4px", marginTop: uploadList && uploadList.length > 0 ? 8 : 0, gap: 16 }}>
                            {
                                uploadList && uploadList.map((file, i) => {
                                    return (
                                        <TextButton
                                            noBorder height='32px' padding='4px 8px' radius='4px' background='#F8F8F8' key={i}
                                            icon={<FilePdfOutlined style={{ fontSize: 14, }} />} rightIcon={<CloseOutlined style={{ fontSize: 14 }} onClick={() => this.handleDeleteDoc(file)} />} text={file.displayName} />
                                    );
                                })
                            }
                        </Flex>
                        <Button weight='500' width='119px' margin="24px 0 0 0" onClick={this.onUploadBankStatementSubmit} text="CONFIRM" solid radius='4px' />
                    </FlexColumn>
                </Modal>
                <Modal
                    visible={this.state.showConnectionErrorModal}
                    footer={null}
                    closable={true}
                    width={456}
                    destroyOnClose={true}
                    onCancel={() => { this.setState({ showConnectionErrorModal: false }) }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <FlexColumn center>
                        <Image margin='24px 0 12px' width='48px' height='48px' src={infoRed} />
                        <Text size='28px' height='40px' weight='500' style={{ marginBottom: 8, marginTop: 8 }}>Connection Unsuccessful</Text>
                        <Text center margin='0 12px 24px' width={'360px'}>Looks like your bank account sync through Plaid was not successful.</Text>

                        <Text center margin='0 12px 24px' width={'360px'}>To continue, please upload your bank statements for the last 12 months.</Text>

                        <Button weight='500' width='214px' margin="12px 0 0 0" onClick={() => {
                            this.setState({ showConnectionErrorModal: false, showManualUploadModal: true, uploadList: [], bankName: "" })
                        }} text="UPLOAD STATEMENTS" solid radius='4px' />

                        <PlaidLink
                            {...environment.plaid}
                            selectAccount="false"
                            clientName="Aion"
                            isWebview="true"
                            apiVersion="v2"
                            onSuccess={(public_token, metadata) => {
                                this.setState({showConnectionErrorModal: false})
                                this.getPlaidAccessToken(public_token, metadata, true, null)}
                            }onExit={() => {
                                if(this.state.hideError) {
                                    this.setState({ showConnectionErrorModal: true })
                                }}
                            }
                            style={{ border: "none", background: "none", paddingLeft: 0, paddingBottom: 0, margin: '0px 0px 0px 0px', fontSize: 18, cursor: 'pointer' }}
                            onEvent={this.logPlaidEvent}
                        >

                            <TextButton width="61px" size="16px" margin="12px 0 0 0" style={{ textAlign: "left", padding: "14px 14px" }} type="link"
                                text="RETRY"></TextButton>
                        </PlaidLink>


                    </FlexColumn>
                </Modal>
                <Skeleton loading={loadingBanks} active>
                    <Modal
                        visible={showBankDetails}
                        footer={null}
                        onCancel={() => this.closeBankDetails()}
                        width={600}
                    >
                        <div style={{ textAlign: 'left' }}>
                            <Text heading caption margin='12px 12px 0 12px' >BANK DETAILS</Text>
                            {
                                <FlexColumn style={{ margin: '24px 0' }}>
                                <div style={{ textAlign: 'left', margin: '0 12px 12px 12px'  }}>
                                    <Text>
                                        <img style={{ marginRight: 8 }} height='35px' src={bankUrl} />
                                        {bankName}
                                    </Text> 
                                </div>
                                <LightContainer start wrap="wrap" style={{ margin: '12px 12px 12px 12px' }}>
                                    <Info label='Last Synced' value={moment(bankLastUpdated).format("MMM DD, YYYY hh:mm A")} />
                                    <Info label='Status' value={reconnectBank ? <Badge status="error" text="DISCONNECTED" /> : <Badge status="success" text="CONNECTED" />} />
                                </LightContainer>
                                <Flex start wrap="wrap" style={{ margin: '12px 12px 12px 12px' }}>
                                    <Text heading caption margin='12px 12px 12px 0'>ACCOUNT DETAILS</Text>
                                    {
                                        accountDetails && accountDetails.length>0 && 
                                            <Table
                                                id="AccountDetailsTable"
                                                tableLayout='fixed'
                                                columns={AccountDetailColumns}
                                                dataSource={accountDetails}
                                                pagination={false}
                                                style={{ margin: '12px 0 12px 0' }}
                                            />
                                    }
                                    </Flex>
                                    <Flex fullWidth centerHorizontally start gap='12px' style={{ margin: '12px 12px 0 12px' }}>
                                    {
                                        reconnectBank && 
                                        <PlaidLink
                                            {...environment.plaid}
                                            token={relinkToken}
                                            className='plaid-link-button'
                                            selectAccount="false"
                                            clientName="Aion"
                                            isWebview="true"
                                            apiVersion="v2"
                                            onSuccess={(public_token, metadata) => {
                                                this.setState({showConnectionErrorModal: false})
                                                this.getPlaidAccessToken(public_token, metadata, true, null)}
                                            }onEvent={this.logPlaidEvent()}
                                        >
                                            <Button solid type="primary" text='RECONNECT'/>
                                        </PlaidLink>
                                    }
                                    </Flex>
                                </FlexColumn>
                            }
                        </div>
                    </Modal>
                </Skeleton> */}
                <Flex start fullWidth gap='24px'>
                    <FlexColumn style={{ width: "100%", margin: "12px 0 0" }}>
                        <Text margin="0px 0px 4px 0px" spacing="0.08em" size="16px" weight='500' color='#7384AA'>BUSINESS TAX RETURNS</Text>
                        <Text size="16px">Provide business tax returns for the last 2 years</Text>

                        <Flex style={{ justifyContent: "between", width: "100%", borderRadius: "8px", padding: "0px 0px", marginTop: 16 }}>
                            <Flex style={{ width: `${100 / 2}%`, flexDirection: "column" }}>
                                {!this.getDocument(businessDocs[0].name).fileName ?
                                    <LabeledInput
                                        {...this.uploadProps(businessDocs[0], businessDocs[0].name)}
                                        type='upload'
                                        label='2022 Tax returns'
                                        uploadText='We accept PDFs and XLSX formats'
                                        width='288px'
                                        loading={this.state.uploadCategory === businessDocs[0].name ? this.state.uploadLoading : false}
                                    />
                                    :
                                    <><Text weight="400" color="#7384AA"
                                        style={{ fontSize: "14px", }}
                                    >2022 Tax returns</Text>
                                        <TextButton style={{ textAlign: "left", padding: "8px 8px" }} type="link" icon={<Image src={File} />}
                                            rightIcon={<CloseOutlined onClick={() => this.handleDeleteDoc(this.getDocument(businessDocs[0].name, { businessDocument: true }))} />}
                                            text={this.getDocument(businessDocs[0].name).fileName} ></TextButton>

                                    </>
                                }
                            </Flex>
                            <Flex style={{ width: `${100 / 2}%`, flexDirection: "column" }}>
                                {!this.getDocument(businessDocs[1].name).fileName ?

                                    <LabeledInput
                                        {...this.uploadProps(businessDocs[1], businessDocs[1].name)}
                                        type='upload'
                                        label='2021 Tax returns'
                                        uploadText='We accept PDFs and XLSX formats'
                                        width='288px'
                                        loading={this.state.uploadCategory === businessDocs[1].name ? this.state.uploadLoading : false}
                                    />
                                    :
                                    <><Text weight="400" color="#7384AA"
                                        style={{ fontSize: "14px", }}
                                    >2021 Tax returns</Text>
                                        <TextButton style={{ textAlign: "left", padding: "8px 8px" }} type="link" icon={<Image src={File} />}
                                            rightIcon={<CloseOutlined onClick={() => this.handleDeleteDoc(this.getDocument(businessDocs[1].name, { businessDocument: true }))} />}
                                            text={this.getDocument(businessDocs[1].name).fileName} ></TextButton>

                                    </>
                                }
                            </Flex>
                        </Flex>
                    </FlexColumn>
                </Flex>
                {/* <Flex start fullWidth gap='24px'>
                    <FlexColumn style={{ width: "100%", margin: "12px 0 0" }}>
                        <div style={{ height: 1, width: '100%', background: '#F2F2F2', margin: '0 0 24px' }} />
                        <Flex noMargin row between>
                            <FlexColumn>
                                {
                                    proposalTerms.creditLimit > 100000 &&
                                    <>
                                        <Text margin="0px 0px 4px 0px" weight='500' spacing="0.08em" color='#7384AA'>COMPANY DEBT SCHEDULE</Text>
                                        <Text size="16px" width="90%">Provide your company debt schedule using the template provided</Text>
                                    </>
                                }
                            </FlexColumn>
                            {
                                proposalTerms.creditLimit > 100000 &&
                                <Text primary underline><a onClick={() => this.downloadStatement("https://s3-us-west-1.amazonaws.com/fpb-business-documents/templates/Form-Aion_Client_Debt_Schedule.xlsx")}><Image src={DownloadIcon} /> Download Template</a></Text>
                            }
                        </Flex>
                        <Flex style={{ justifyContent: "between", width: "100%", borderRadius: "8px", padding: "0px 0px", marginTop: 16 }}>
                            {
                                proposalTerms.creditLimit > 100000 &&
                                <Flex style={{ width: `100%`, flexDirection: "column" }}>
                                    {!this.getDocument(businessDocs[2].name).fileName ?
                                        <LabeledInput
                                            {...this.uploadProps(businessDocs[2], businessDocs[2].name)}
                                            type='upload'
                                            label='Company Debt Schedule'
                                            uploadText='Please use the template provided' />
                                        :
                                        <><Text weight="400" color="#7384AA"
                                            style={{ fontSize: "14px", }}
                                        >Company Debt Schedule</Text>
                                            <TextButton style={{ textAlign: "left", padding: "8px 8px" }} type="link" icon={<Image src={File} />}
                                                rightIcon={<CloseOutlined onClick={() => this.handleDeleteDoc(this.getDocument(businessDocs[2].name, { businessDocument: true }))} />}
                                                text={this.getDocument(businessDocs[2].name).fileName}
                                            ></TextButton>

                                        </>
                                    }
                                </Flex>
                            }
                        </Flex>
                    </FlexColumn>
                </Flex> */}
                {proposalTerms.creditLimit < gnplHigh && businessProfile.primaryApplicantInfo && ((businessProfile.primaryApplicantInfo || {}).ownershipPercentage || 0) < 25 && (beneficialOwners && beneficialOwners.length > 0) ?
                    <Flex start fullWidth gap='24px'>
                        <FlexColumn style={{ width: "100%", margin: "12px 0 0" }}>
                            <div style={{ height: 1, width: '100%', background: '#F2F2F2', margin: '0 0 24px' }} />
                            <Flex noMargin row between>
                                <FlexColumn>
                                    <Text margin="0px 0px 4px 0px" weight='500' spacing="0.08em" color='#7384AA'>AUTHORIZED SIGNER</Text>
                                    <Text size="16px" width="90%">Designate an authorized signer for your account. An authorized signer is a representative who can sign a loan agreement on behalf of the business and is a guarantor.</Text>
                                </FlexColumn>
                            </Flex>
                            <Flex style={{ width: `${100 / 5}%`, flexDirection: "column" }}>

                                <LabeledInput
                                    labelcolor={this.props.theme.colors.secondary3}
                                    label="Select an authorized person to sign                        "
                                    type="select"
                                    key="selectedContractOwner"
                                    id="selectedContractOwner"
                                    placeholder="Select Individual"
                                    className="no-left-padding"
                                    onChange={this.handleSelectContractOwner}
                                    value={selectedContractOwner}
                                >
                                    {items}
                                </LabeledInput>
                            </Flex>

                        </FlexColumn>
                    </Flex> : null
                }



            </FlexColumn>

        )
        if (reviewView) {
            return (
                <FlexColumn start fullWidth left >
                    <Flex start fullWidth style={{ width: "-webkit-fill-available" }}>
                        <FlexColumn style={{ width: "100%" }}>
                            <Flex noMargin row between>
                                <FlexColumn style={{ width: `75%`, flexDirection: "column" }} >
                                    <Text margin="0px 0px 4px 0px" spacing="0.08em" weight='500' color='#7384AA' >BUSINESS BANK STATEMENTS</Text>
                                </FlexColumn>
                                <FlexColumn style={{ width: `25%`, flexDirection: "column" }}>
                                    {/*<TextButton onClick={""} text={"Can't Connect to your bank? Upload your statements"} />*/}
                                </FlexColumn>
                            </Flex>
                            <Flex wrap start style={{ margin: '12px 0 0' }}>
                                {connectedBanks}
                                {uploadedStatements}
                            </Flex>
                        </FlexColumn>
                    </Flex>
                    <Divider />

                    <Flex start fullWidth style={{ width: "-webkit-fill-available" }}>
                        <FlexColumn style={{ width: "100%" }}>
                            <Text margin="0px 0px 4px 0px" weight='500' spacing="0.08em" color='#7384AA'>BUSINESS TAX RETURNS</Text>
                            <Flex style={{ justifyContent: "between", width: "100%", borderRadius: "8px", padding: "0px 0px", marginTop: 16 }}>
                                <Flex style={{ width: `${100 / 2}%`, flexDirection: "column" }}>

                                    <><Text weight="400" color="#7384AA"
                                        style={{ fontSize: "14px", }}
                                    >2022 Tax returns</Text>
                                        <TextButton style={{ textAlign: "left", padding: "8px 8px" }} type="link" icon={<Image src={File} />}
                                            text={this.getDocument(businessDocs[0].name).fileName}></TextButton>

                                    </>
                                </Flex>
                                <Flex style={{ width: `${100 / 2}%`, flexDirection: "column" }}>
                                    <><Text weight="400" color="#7384AA"
                                        style={{ fontSize: "14px", }}
                                    >2021 Tax returns</Text>
                                        <TextButton style={{ textAlign: "left", padding: "8px 8px" }} type="link" icon={<Image src={File} />}
                                            text={this.getDocument(businessDocs[1].name).fileName}></TextButton>
                                    </>
                                </Flex>
                            </Flex>
                        </FlexColumn>
                    </Flex>
                    <Divider />
                    {/*
                        proposalTerms.creditLimit > 100000 &&
                        <Flex style={{ justifyContent: "between", width: "100%", borderRadius: "8px", padding: "0px 0px" }}>
                            <Flex style={{ width: `100%`, flexDirection: "column" }}>
                                <Flex noMargin row between>
                                    <FlexColumn>
                                        <Text margin="0px 0px 4px 0px" weight='500' spacing="0.08em" color='#7384AA'>COMPANY DEBT SCHEDULE</Text>
                                    </FlexColumn>
                                </Flex>
                                <FlexColumn margin="12px 0 0 0"><Text weight="400" color="#7384AA"
                                    style={{ fontSize: "14px", }}
                                >Company debt schedule</Text>
                                    <TextButton style={{ textAlign: "left", padding: "8px 8px" }} type="link" icon={<Image src={File} />}
                                        text={this.getDocument(businessDocs[2].name).fileName}></TextButton>

                                </FlexColumn>
                            </Flex>
                        </Flex>
            */}
                    {proposalTerms.creditLimit < gnplHigh && businessProfile.primaryApplicantInfo && businessProfile.primaryApplicantInfo.ownershipPercentage < 25 && (beneficialOwners && beneficialOwners.length > 0) ?
                        <Flex start fullWidth gap='24px'>
                            <FlexColumn style={{ width: "100%", margin: "12px 0 0" }}>
                                <div style={{ height: 1, width: '100%', background: '#F2F2F2', margin: '0 0 24px' }} />
                                <Flex noMargin row between>
                                    <FlexColumn>
                                        <Text margin="0px 0px 4px 0px" weight='500' spacing="0.08em" color='#7384AA'>AUTHORIZED SIGNER</Text>
                                    </FlexColumn>
                                </Flex>
                                <Flex style={{ width: `${100 / 5}%`, flexDirection: "column" }}>

                                    <LabeledInput
                                        labelcolor={this.props.theme.colors.secondary3}
                                        label="Select an authorized person to sign"
                                        type="read-only"
                                        key="selectedContractOwner"
                                        id="selectedContractOwner"
                                        placeholder="Select Individual"
                                        className="no-left-padding"
                                        value={items.filter(i => i.props.value === selectedContractOwner)[0].props.children}
                                    >
                                    </LabeledInput>
                                </Flex>

                            </FlexColumn>
                        </Flex> : null
                    }
                </FlexColumn>
            )
        }
        return (
            <FlowViewComponent
                flowView={flowView}
                currentViewIndex={currentViewIndex}
                loadPrev={loadPrevView}
                childViews={childViews}
                submitLoading={this.state.submitLoading}
                width={'100%'}
                back={currentViewIndex > 0}
                padding='0 64px'
                {...this.props}
                hideSteps={proposalTerms.creditLimit < gnplHigh ? true : false}
                loadNext={this.validateFlow}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer,
        creditStore: state.creditAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(BusinessInformation));