import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import {
	Modal,
	Input,
	Upload,
	message,
	Select,
	DatePicker,
	Alert
} from 'antd'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { UploadOutlined } from '@ant-design/icons'
import ModalClose from '../../../Images/modal-close.png'
import { FlexColumn, Flex, InputContainer } from '../../Reusable/Container'
import { Text } from '../../Reusable/Text'
import { LabeledInput } from '../../Reusable/Refresh/Input'
import { Button, TextButton } from '../../Reusable/Refresh/Button'

// Util
import environment from '../../../environment'
import { getJwt } from '../../../Utils/api'
import { toCurrency } from '../../../Utils/util';
import cloneDeep from 'lodash/cloneDeep'
const { Option } = Select

const dateFormat = 'MMM D, YYYY'

class AddModalBase extends Component {

	constructor(props) {
		super(props)
		this.state = {
			jwt: null,
			loading: false,
		}
	}

	componentDidMount() {
		getJwt((err, jwt) => {
			this.setState({ jwt })
		})
	}

	componentWillReceiveProps(props) {
		this.setState({
			EndDate: props.EndDate,
			StartDate: props.StartDate,
			contractValue: props.contractValue,
			FileName: props.FileName,
			URI: props.URI,
			totalValue: props.totalValue,
			woNumber: props.woNumber,
			masterServiceAgreementId: props.masterServiceAgreementId
		});
	}

	cacheFileData = data => {
		this.setState(data)
	}

	onSubmit() {
		const stateCopy = cloneDeep(this.state);		
		this.props.onSubmit({ ...stateCopy })
	}

	render() {
		const { visible, form } = this.props
		const { woNumber, EndDate, StartDate, contractValue, totalValue, masterServiceAgreementId } = this.state

		const fieldWidth = 200
		const formItemLayout = {
			labelCol: { span: 6 },
			wrapperCol: { span: 16 },
		}
		const fieldProps = {
			style: { margin: "5 0 10 0" }
		}
		const uploadProps = {
			name: `customerUpload`,
			multiple: false,
			action: `${environment.apiBaseUrl}/financing/upload`,
			headers: {
				businesskey: this.props.aionStore.BusinessUniqueKey,
                jwt: this.props.aionStore.jwt
			},
			onChange: (info) => {
				const { status, response, name } = info.file
				if (status === 'done') {
					this.cacheFileData({
						FileName: name,
						URI: (response.SavedDocumentUrls || []).slice(-1).pop()
					})
					message.success(`${info.file.name} file uploaded successfully.`, 0.75)
				} else if (status === 'error') {
					message.error(`${info.file.name} file upload failed.`)
				}
			},
			onRemove: (file) => {
				this.cacheFileData({
					FileName: "",
					URI: ""
				})
			},
		}

		return (
			<Modal
				visible={visible}
				width="800px"
				okText="Submit"
				// maskClosable={true}
				onCancel={() => {
					this.props.onCancel();
				}}
				// addonBefore="#"
				// onOk={() => {
				// 	form
				// 		.validateFields()
				// 		.then((values) => {
				// 			this.props.onSubmit({ ...values, ...this.state })
				// 		})
				// 		.catch((info) => {
				// 			console.log('Validate Failed:', info)
				// 		})
				// }}
				destroyOnClose={true}
				closeIcon={<img width='24px' height='24px' src={ModalClose} />}
				footer={null}
			>
				<FlexColumn style={{ marginBottom: 24 }}>
					<Text size='20px' weight="500">{this.props.title}</Text>

					{
						this.props.type == 'WO' &&
						<LabeledInput
							label="Document Number"
							id="woNumber"
							key="woNumber"
							placeholder={2020612}
							value={woNumber}
							// instruction={(scheduleType == "once") ? "Invoice will be sent at 9:00 am PST" : null}
							onChange={(event) => { this.setState({ woNumber: event.target.value }) }}
						/>
					}

					<LabeledInput
						label="Valid from"
						id="StartDate"
						key="StartDate"
						type="date-picker"
						placeholder={moment().format(dateFormat)}
						value={StartDate}
						format={dateFormat}
						// instruction={(scheduleType == "once") ? "Invoice will be sent at 9:00 am PST" : null}
						onChange={(date, dateString) => { this.setState({ StartDate: date }) }}
					/>

					<LabeledInput
						label="Valid through"
						id="EndDate"
						key="EndDate"
						type="date-picker"
						placeholder={moment().format(dateFormat)}
						value={EndDate}
						format={dateFormat}
						// instruction={(scheduleType == "once") ? "Invoice will be sent at 9:00 am PST" : null}
						onChange={(date, dateString) => { this.setState({ EndDate: date }) }}
					/>
					
					{
						this.props.type == 'MSA' &&
						<LabeledInput
						autoFocus
						optional
						symbolprefix="$"
						type="number"
						label="Contract Value"
						id="contractValue"
						key="contractvalue"
						placeholder={"100,000"} 
						min={0}
						formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
						value={contractValue}
						onChange={(value) => {
							this.setState({ contractValue: toCurrency(value, 0, 2) })
						}}
					/>
						
					}
					
					{
						this.props.type == 'WO' &&
						<LabeledInput
						autoFocus
						symbolprefix="$"
						type="number"
						label="Total Value"
						id="totalValue"
						key="totalvalue"
						placeholder={"100,000"} 
						min={0}
						formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
						value={totalValue}
						onChange={(value) => {
							this.setState({ totalValue: toCurrency(value, 0, 2) })
						}}
					/>
					}

					{
						this.props.selectMSA &&
						<LabeledInput
							label="Please select an MSA"
							id="selectMSA"
							key="selectMSA"
							type="select"
							className="no-left-padding"
							placeholder="Select"
							// value={item.masterServiceAgreementId}
							onChange={(value) => this.setState({ masterServiceAgreementId: value })}
						>
							{this.props.msas.map(item => <Option key={item.id} name={item.documentUrl.fileName || ""} value={item.masterServiceAgreementId} style={{ backgroundColor: "transparent" }}>{item.documentUrl.fileName}</Option>)}
						</LabeledInput>
					}

					<LabeledInput
						{...uploadProps}
						type='upload'
						label='Document'
					/>

					<Flex start style={{ marginTop: 24 }}>
						<Button height='40px' style={{ marginRight: 24 }} solid radius='4px' text='SUBMIT' onClick={() => this.onSubmit()} />
						<TextButton color='#1199FF' text='CANCEL' onClick={() => {
							this.props.onCancel();
						}} />
					</Flex>
				</FlexColumn>

				{/* <Form {...formItemLayout}>
					{this.props.type == 'WO' &&
						<Form.Item label="Document Number" {...fieldProps}>
							{form.getFieldDecorator('poNumber', {
								rules: [{ required: true, message: 'Please add a work order number' }],
							})(
								<Input id="poNumber" placeholder="2020612" onChange={(event) => { this.setState({ woNumber: event.target.value }) }} addonBefore="#" style={{ width: fieldWidth }} />
							)}
						</Form.Item>
					}
					<Form.Item label="Valid through" {...fieldProps}>
						{form.getFieldDecorator('EndDate', {
							rules: [{ required: true, message: 'Please provide an end date' }],
						})(
							<DatePicker format={dateFormat} placeholder="Enter date" onChange={(date, dateString) => { this.setState({ EndDate: date }) }} style={{ width: fieldWidth }} />
						)}
					</Form.Item>
					{this.props.selectMSA && <Form.Item label="MSA" {...fieldProps}>
						{form.getFieldDecorator('MSA', {
							rules: [{ required: true, message: 'Please select an MSA' }],
						})(
							<Select>
								{this.props.msas.map(item => <Option key={item.id} name={item.documentUrl.fileName || ""} value={item.masterServiceAgreementId} style={{ backgroundColor: "transparent" }}>{item.documentUrl.fileName}</Option>)}
							</Select>
						)}
					</Form.Item>}
					<Form.Item required label="Document">
						<Upload {...uploadProps}>
							<Button>
								<UploadOutlined /> Upload file
							</Button>
						</Upload>
					</Form.Item>
				</Form> */}
				{/*this.props.showError ? <Alert
					message="Required fields"
					description="Please upload a supporting document."
					type="error"
					showIcon
			/> : null*/}
			</Modal>
		)
	}
}

export default (Form.create()(AddModalBase))
