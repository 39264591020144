import CircularJSON from 'circular-json';

export const loadState = () => {
    // It’s important that this piece of code is wrapped in a try/catch block because calls to localStorage.getItem can fail if the user privacy mode does not allow the use of localStorage.
    try {
      const serializedState = localStorage.getItem('state');
      if (serializedState === null) {
        return undefined;
      }
      const serializedState1 = localStorage.getItem('state1');
      const state = CircularJSON.parse(serializedState);
      const state1 = CircularJSON.parse(serializedState1);
      state.aionAppReducer = Object.assign(state.aionAppReducer, state1);
      return state;
    } catch (err) {
      console.log("UAM_SAVE_PERMISSIONS loadState error", err)
      return undefined;
      
    }
};

export const saveState = (state) => {
    try {
      const serializedState = CircularJSON.stringify(state);
      // const blob = new Blob([serializedState]);
      // console.log("UAM_SAVE_PERMISSIONS saveState", `${(blob.size / (1024 * 1024))} MB`)
      localStorage.setItem('state', serializedState);
    } catch (e) {
      // ignore write errors
      console.log("UAM_SAVE_PERMISSIONS localStorage error", e, state)
    }
};