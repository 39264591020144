import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import styled, { withTheme } from 'styled-components'
import Lottie from 'react-lottie'
import { Alert, Layout, message, Modal } from 'antd'

// Util
import { apiGET, apiPOST, apiPOSTNoAuth, apiPOSTReq, clearLocalStorage, doSignout, getJwt } from '../../Utils/api'
import { getUserPermissions } from '../../Utils/userAccess'
import { camelCaseToPascalCase, defaultLottieOptions, getUserApps, getResourcePerm, getFeaturePerm, getUserRoles } from '../../Utils/util'
import { containerDimensions } from '../../Styles/theme'
import Bowser from "bowser"

// Actions
import { completeSignin, addDataToStore, SAVE_DATA, UAM_SAVE_PERMISSIONS, CODAT_DATA, USERDEFAULTS_SAVE_FLAG, ONB_SAVE_DATA, UAM_SAVE_BUSINESS, UAM_SAVE_USERINFO } from '../../Actions/actions';

// Components
import { Button, TextButton } from '../Reusable/Button'
import { Text } from '../Reusable/Text'
import { Flex, FlexColumn, SideBar, GradientSideBar } from '../Reusable/Container'
import { Divider, LabeledInput } from '../Reusable/Input'
import { StyledExtLink, StyledLink } from '../Reusable/Link'
import TwoFactor from '../TwoFactor/Index'
import NewPassword from './NewPassword'
import ReferralCode from './ReferralCode'
import AionLogo from '../Reusable/Image'
import { ErrorAlert } from '../Reusable/Alert'
import environment from '../../environment'
import ModalClose from '../../Images/modal-close.png'
import { PageTitle } from '../Reusable/Refresh/Text'
import { BrandedContainer } from '../Reusable/Container'
import AionOverlayImg from '../../Images/aion-wing-overlay.png';
import CoinStackImg from '../../Images/stack-of-coins.png';
import _ from 'underscore'
import MobileLanding from '../Reusable/MobileLanding'

const { Content, Sider } = Layout

const AionOverLay = styled.div`
    position: absolute;
    mix-blend-mode: overlay;
    opacity: 0.16;
`;

class SignIn extends Component {
    refArr = []
    constructor(props) {
        super(props)

        this.state = {
            showRequired: { username: false, password: false },
            formData: {},
            loading: false,
            isMobile: window.matchMedia("only screen and (max-width: 760px)").matches,
            isTablet: window.matchMedia("only screen and (max-width: 1100px)").matches,
            step: 0,
            delivery: "text", //text or email
            codeInd: 0,
            password: environment.showAutofill ? "Aion@123" : null,
            fetchPermissionsLoading: false
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        _.times(6, (n) => {
            var r = React.createRef()
            this.refArr.push(r)
        })
    }

    componentDidMount() {
        var { sessionRefreshing} = this.props.store

        window.addEventListener("resize", this.handleResize)
        // this.getAttributes()
        this.checkBrowser()
        this.checkForNewUserReference()
        
        if(sessionRefreshing) {
            message.loading("Refreshing your session")
            this.checkUserSession()
            this.fetchPermissions()
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize)
    }

    handleResize = () => {
        if (typeof (window)) {
            this.setState({
                isMobile: window.matchMedia("only screen and (max-width: 760px)").matches,
                isTablet: window.matchMedia("only screen and (max-width: 1100px)").matches,
            })
        }
    }

    checkUserSession = () => {
        var { sessionRefreshing} = this.props.store
        console.log("checkUserSession")
        Auth.currentUserInfo()
            .then(cognitoUserInfo => {
                // console.log("checkUserSession cognitoUserInfo", cognitoUserInfo)
                if(cognitoUserInfo) {
                    // User is already signed in
                    if(!sessionRefreshing) message.loading("Refreshing your session")
                    this.props.dispatch(addDataToStore(SAVE_DATA, { cognitoUserInfo: { username: cognitoUserInfo.username, attributes: cognitoUserInfo.attributes } }))
                    this.setState({ cognitoUserInfo: cognitoUserInfo })
                    this.fetchUserData(null, (err, resp) => {})
                }
            })
            .catch(err => {
                console.log("fetchUserData Err", err)
            })
    }

    checkForNewUserReference = () => {
        const refCode = new URLSearchParams(this.props.location.search).get('newUserReference')
        console.log("checkForNewUserReference", refCode)
        if (refCode) {
            this.setState({ loading: true })
            apiPOSTNoAuth(`${environment.uamBaseUrl}/getAuthFromLinkReference`, {}, { passwordLinkReference: refCode }, (err, resp) => {
                try {
                    if (err) throw Error(err)
                    const data = (resp || {}).data || {}
                    if (data.result) {
                        const firstTimePasswordLinkInfo = data.firstTimePasswordLinkInfo || {}
                        console.log("firstTimePasswordLinkInfo", firstTimePasswordLinkInfo)
                        this.setState({ username: firstTimePasswordLinkInfo.userName, password: firstTimePasswordLinkInfo.generatedPassword })
                        this.cognitoSignIn()
                    } else {
                        const responseMessage = data.responseMessage || ""
                        if (responseMessage.includes("expired") || responseMessage.includes("invalid")) {
                            const firstTimePasswordLinkInfo = data.firstTimePasswordLinkInfo || {}
                            this.resendNewUserLink(firstTimePasswordLinkInfo.userName)
                        }
                        throw Error(data.responseMessage || data.error)
                    }
                } catch (error) {
                    console.log("/checkForNewUserReference error", error, resp)
                    ErrorAlert({ description: error.message })
                }
            })
        }
    }

    resendNewUserLink = (username) => {
        apiPOSTNoAuth(`${environment.uamBaseUrl}/refreshLinkReference`, {}, { userId: username }, (err, resp) => {
            try {
                if (err) throw Error(err)
                const data = (resp || {}).data || {}
                if (data.result) {
                    console.log("New link sent!")
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/checkForNewUserReference error", error, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    checkBrowser = () => {
        const browser = Bowser.getParser(window.navigator.userAgent)
        const browserName = browser.getBrowserName()
        if (!(browserName.toLowerCase().includes("chrome") || browserName.toLowerCase().includes("safari") || browserName.toLowerCase().includes("edge"))) {
            this.setState({ unsupported: true })
        }
    }

    getAppConfig() {
        // Check session and fetch attributes
        apiGET("/appConfig", null, (err, resp) => {
            try {
                const data = resp.data || {}
                console.log("appConfig", data)
                if (data.success) {
                    this.props.dispatch(completeSignin(data))
                } else {
                    throw Error("Could not fetch appConfig.")
                }
            } catch (error) {
                console.log("/appConfig ERR", error, err, resp)
            }
        })
    }

    saveOnboardingData = (cognitoUserInfo, business, ownershipInfo) => {
        var businessProfile = business.businessProfile || {}
        var cognitoAttr = cognitoUserInfo.attributes || {}

        this.props.dispatch(addDataToStore(SAVE_DATA, { 
            OnboardingType: cognitoAttr["custom:onboardingType"], 
            subscriptionPlanName: cognitoAttr["custom:subscriptionPlanName"], 
            subscriptionBilling: cognitoAttr["custom:subscriptionBilling"], 
            referralCode: cognitoAttr["custom:referralCode"] 
        }))

        if(ownershipInfo) {
            this.props.dispatch(addDataToStore(ONB_SAVE_DATA, { 
                businessUniqueKey: business.Id,
                personalInfo: businessProfile.primaryApplicantInfo || {},
                coOwnersInfo: businessProfile.coOwnerApplicantInfo || [],
                signersInfo: businessProfile.signerInfo || [],
                businessInfo: businessProfile.businessInfo,
                contractOwnerApplicantInfo: businessProfile.contractOwnerApplicantInfo,
                ...business
            }))
        } else {
            this.props.dispatch(addDataToStore(ONB_SAVE_DATA, { 
                businessUniqueKey: business.Id,
                businessInfo: businessProfile.businessInfo,
                ...business
            }))
        }
        
    }

    fetchBusinessData = (options, callback) => {
        options = options || {}
        var { store, businessSubscriptionPlan } = this.props
        const cognitoUserInfo = store.cognitoUserInfo || {}
        var cognitoAttr = cognitoUserInfo.attributes || {}
        var { currentPlan } = businessSubscriptionPlan || {}
        console.log("fetchBusinessData", cognitoUserInfo, cognitoAttr)
        apiPOSTReq(`${environment.uamBaseUrl}/getBusiness`, {}, {}, (err, resp) => {
            try {
                if (err) throw Error(err)
                const data = resp || {}
                if (data.result) {
                    var business = data.business || {}
                    var businessBanking = business.businessBanking || {}
                    // Set do this later flag
                    this.props.dispatch(addDataToStore(USERDEFAULTS_SAVE_FLAG, { DoInitialDepositLater: false }))
                    this.props.dispatch(addDataToStore(USERDEFAULTS_SAVE_FLAG, { BBInitialDepositMade: false }))

                    const bankingAttributes = camelCaseToPascalCase(businessBanking.attributes || {});
                    var attributes = {
                        BankingAttributes: bankingAttributes,
                        Businesses: camelCaseToPascalCase(business.attributes || {})
                    }
                    this.props.dispatch(addDataToStore(SAVE_DATA, { Attributes: attributes }))
                    this.props.dispatch(addDataToStore(UAM_SAVE_BUSINESS, data))
                    this.props.dispatch(addDataToStore(ONB_SAVE_DATA, { userStatus: business.userStatus })) // tracks userStatus

                    if (bankingAttributes.ApplicationSubmitted || bankingAttributes.ForceCRBAccountOpening || currentPlan) {
                        var onboarding = bankingAttributes.ForceCRBAccountOpening || false
                        if(onboarding) this.saveOnboardingData(cognitoUserInfo, business, false)
                        this.fetchPermissions()
                    } else {
                        // New business
                        this.props.dispatch(addDataToStore(SAVE_DATA, { 
                            "TwoFAValidated": true
                        }))
                        this.saveOnboardingData(cognitoUserInfo, business, false)
                        // this.setState({ loading: false })
                        if(cognitoAttr["custom:onboardingType"] == "credit") {
                            this.props.history.push('/apply/credit')
                        } else {
                            this.props.history.push('/apply')
                        }
                    }
                    
                    callback(null, true)
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/getBusiness err", error, resp)
                callback(error.message)
            }
        });
    }

    fetchOwnershipData = () => {
        apiPOSTReq(`${environment.uamBaseUrl}/getBusinessOwnership`, {}, {}, (err, resp) => {
            try {
                if (err) throw Error(err);
                const data = resp || {};
                if (data.result) {
                    var { cognitoUserInfo } = this.props.store
                    var { currentPlan } = this.props.businessSubscriptionPlan || {}

                    var business = data.business || {}
                    var businessBanking = business.businessBanking || {}

                    this.props.dispatch(addDataToStore(UAM_SAVE_BUSINESS, data));

                    const bankingAttributes = camelCaseToPascalCase(businessBanking.attributes || {});
                    this.props.dispatch(addDataToStore(UAM_SAVE_BUSINESS, data));
                    var onboarding = false

                    if (bankingAttributes.ApplicationSubmitted || bankingAttributes.ForceCRBAccountOpening || currentPlan) {
                        var onboarding = bankingAttributes.ForceCRBAccountOpening || false
                    } else {
                        onboarding = true
                    }
                    if(onboarding) this.saveOnboardingData(cognitoUserInfo, business, true)
                } else {
                    throw Error(data.responseMessage || data.error);
                }
            } catch (error) {
                console.log("/getBusinessOwnership err", error, resp);
            }
        });
    }

    fetchUserData = (options, callback) => {
        options = options || {}
        var { cognitoUserInfo } = this.props.store
        cognitoUserInfo = cognitoUserInfo || {}
        var username = cognitoUserInfo.username
        this.setState({ loading: true })
        getJwt((err, jwt) => {
            this.props.dispatch(addDataToStore(UAM_SAVE_PERMISSIONS, { jwt: jwt }))
        })
        apiPOSTReq(`${environment.uamBaseUrl}/getUserInfo`, {}, { userId: username }, (err, resp) => {
            try {
                if (err) throw Error(err)
                const data = resp || {}
                if (data.result) {
                    var registeredUser = data.registeredUser || {}
                    var userInfo = registeredUser.userInfo || {}
                    this.props.dispatch(addDataToStore(UAM_SAVE_USERINFO, registeredUser))
                    // We use camelCase in some places and PascalCase in other places
                    this.props.dispatch(addDataToStore(SAVE_DATA, { UserInfo: camelCaseToPascalCase(Object.assign({}, userInfo)), userInfo: userInfo }))
                    var currentBusiness = registeredUser.currentBusiness
                    // Update browser info
                    this.saveBrowserInfo()
                    this.getAppConfig() // background call

                    const { store } = this.props
                    const { TwoFAValidated } = store
                    if(currentBusiness) {
                        this.fetchBusinessData(null, (err, resp) => {})
                        if (!TwoFAValidated) {
                            this.begin2FA()
                            return
                        } else {
                            this.twoFAValidated()
                        }
                    } else {
                        this.fetchBusinessData(null, (err, resp) => {
                            this.loadApp()
                        })
                    }
                    callback(null, true)
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                this.setState({ loading: false })
                callback(error.message)
                ErrorAlert({ description: error.message })
            }
        })
    }

    fetchIntegration = () => {
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/hasIntgration`, {}, {}, (err, resp) => {
            try {
                if (err) throw Error(err)
                const data = resp || {}
                if (data.result) {
                    console.log("/getCurrentSyncSW", data)
                    this.props.dispatch(addDataToStore(SAVE_DATA, { hasIntegration: data.hasIntegration || false }))
                    if (!data.hasIntegration) {
                        return
                    }

                    apiPOSTReq(`${environment.integrationBaseUrl}/integration/getCurrentSyncSW`, {}, {}, (err, resp) => {
                        try {
                            if (err) throw Error(err)
                            const data = resp || {}
                            if (data.result) {
                                console.log("/getCurrentSyncSW", data)
                                this.props.dispatch(addDataToStore(SAVE_DATA, { CurrentSync: data.integration }))
                                this.props.dispatch(addDataToStore(SAVE_DATA, { hideNotification: data.hideNotification }))
                            } else {
                                throw Error(data.responseMessage || data.error)
                            }
                        } catch (error) {
                            console.log("/getCurrentSyncSW err", error, resp)
                            // ErrorAlert({ description: error.message })
                        }
                    })
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/getCurrentSyncSW err", error, resp)
                // ErrorAlert({ description: error.message })
            }
        })
    }

    fetchExternalBankConnection = () => {
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/plaid/getExternalBankConnection`, {}, {}, (err, resp) => {
            try {
                if (err) throw Error(err)
                const data = resp || {}
                if (data.result) {
                    console.log("/getExternalBankConnection", data)
                    this.props.dispatch(addDataToStore(SAVE_DATA, { initBankNameList: data.bankNameList }))
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/getExternalBankConnection err", error, resp)
                // ErrorAlert({ description: error.message })
            }
        })
    }

    fetchPermissions = () => {
        // Fetch user permissions and adapt the layout based on the user's access
        this.setState({ fetchPermissionsLoading: true })
        getUserPermissions(null, (err, resp) => {
            try {
                console.log("fetchPermissions resp.data", resp.data)
                // this.setState({ loading: true })
                if (err) throw Error(err)
                if (!resp.data.authToken) throw Error(resp.data.responseMessage)
                this.props.dispatch(addDataToStore(UAM_SAVE_PERMISSIONS, { UAM: resp.data }))
                this.getConnections()
                this.fetchExternalBankConnection()
                this.setState({ fetchPermissionsLoading: false })
                getJwt((err, jwt) => {
                    this.props.dispatch(addDataToStore(UAM_SAVE_PERMISSIONS, { jwt: jwt }))
                })
            } catch (error) {
                ErrorAlert({ description: error.message })
                // this.props.history.push('/home')
                // this.setState({ loading: false })
            }
        })
    }

    // fetchBusinessApps ONLY USED FOR AION SUPPORT TO LOAD AVAILABLE USER APPS
    fetchBusinessApps = () => {
        apiPOSTReq(`${environment.uamBaseUrl}/aion/system/getAvailableApplications`, {}, {}, (err, resp) => {
            try {
                if (err) throw Error(err)
                const data = resp || {}
                if (data.result) {
                    console.log("/aion/system/getAvailableApplications", data)
                    this.props.dispatch(addDataToStore(UAM_SAVE_PERMISSIONS, { BizAppNames: data.applicationNames || [] }))
                    this.loadApp()
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/aion/system/getAvailableApplications err", error, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    getConnections = () => {
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/codat/getConnections`, {}, {}, (err, resp) => {
            try {
                const data = resp || {};
                if (data.result) {
                    var connections = data.connections || []
                    console.log("/integration/codat/getConnections data", data)
                    this.props.dispatch(addDataToStore(CODAT_DATA, { connections: connections, connection: connections.length > 0 && connections[0], company: data.company }))
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("getConnections error", { description: error.message })
            }
        })
    }

    getReferralCode = () => {
        apiPOSTReq(`${environment.uamBaseUrl}/aion/system/getReferralCode`, {}, {}, (err, resp) => {
            try {
                const data = resp || {};
                if (data.result) {
                    console.log("/aion/system/getReferralCode data", data)
                    this.props.dispatch(addDataToStore(SAVE_DATA, { referralsData: { referralCode: data.referralCode, inviteCount: data.inviteCount, referralAmount: data.referralAmount }}))
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("getReferralCode error", { description: error.message })
            }
        })
    }

    loadApp = () => {
        // Load tabs and menu based on user's access
        const userApps = getUserApps(this.props.store)
        var { UAM, business, cognitoUserInfo } = this.props.store
        var { businessBanking } = business || {}
        var bankingApplications = (businessBanking || {}).applications || [];        
        var Attributes = this.props.store.Attributes || {}
        const bbAttributes = Attributes.BankingAttributes || {}
        const onboardingApproved = bbAttributes.ApplicationApproved || userApps.includes("ARCredit") || userApps.includes("ABLCredit") || userApps.includes("TermLoans")
        const advPerm = getResourcePerm("ARCredit.Advances")
        const invAdvPerm = getResourcePerm("ARCredit.Inventory Advances")
        const cardsPerm = getResourcePerm("BusinessCards.Cards")
        const { cognitoUser } = this.state
        const t = this
        console.log("loadApp bbAttributes userApps", bbAttributes, userApps)
        this.setState({ loading: false })
        var cognitoAttr = cognitoUserInfo.attributes || {}

        // Mark UAM as complete for new users
        this.props.dispatch(addDataToStore(SAVE_DATA, { "TwoFAValidated": true, OnboardingType: cognitoAttr["custom:onboardingType"], subscriptionPlanName: cognitoAttr["custom:subscriptionPlanName"], subscriptionBilling: cognitoAttr["custom:subscriptionBilling"], referralCode: cognitoAttr["custom:referralCode"] }))
        if (!onboardingApproved) {
            if (cognitoAttr["custom:onboardingType"] == "credit") {
                if (bbAttributes.ForceCRBAccountOpening || bankingApplications.length > 0) {
                    this.props.history.push('/apply')
                }else {
                    this.props.history.push('/apply/credit')
                }
            } else {
                this.props.history.push('/apply')
            }
        } else {
            if (bbAttributes.ForceCRBAccountOpening) {
                this.props.history.push('/apply')
            } else {
                loadPath()
            }
        }

        function loadPath() {
            // console.log('getUserApps resourcePermMap: assignedApplications', userApps.includes("BusinessBanking"), userApps, bbAttributes.ApplicationApproved)
            const showWidgetDashboard = getFeaturePerm("AionApplications.System.WidgetsDashboard").view
            const userRole = getUserRoles(t.props.store)
            const isCompanyAdmin = userRole.includes("Company Admin") || userRole.includes("Owner") || userRole.includes("Primary Admin")

            if (userApps.includes("BusinessBanking") && bbAttributes.ApplicationApproved) {
                if(isCompanyAdmin && showWidgetDashboard) {
                    t.props.history.push('/dashboard')
                } else {
                    t.props.history.push('/banking/home')
                }
            }
            else if (userApps.includes("BusinessCards") && (cardsPerm.view || cardsPerm.manage)) t.props.history.push('/cards/my-cards')
            else if (userApps.includes("ARCredit") && (advPerm.view || advPerm.manage)) t.props.history.push('/credit/ar-credit/advances')
            else if (userApps.includes("ARCredit") && (invAdvPerm.view || invAdvPerm.manage)) t.props.history.push('/credit/inventory-loc/advances')
            else if (userApps.includes("ABLCredit")) t.props.history.push('/credit/abl-credit/advances')
            else if (userApps.includes("TermLoans")) t.props.history.push('/credit/term-loans/advances')
            else if (userApps.includes("Payables")) t.props.history.push('/payables/inbox')
            else if (userApps.includes("Receivables")) t.props.history.push('/receivables/customers')
            // else if (userApps.includes("Bookkeeping")) t.props.history.push('/bookkeeping/overview')
            else if (UAM.aionUnderWriterUser) t.props.history.push('/underwriting/applications/prescreen')
            else t.props.history.push('/settings/profile')
            // t.props.history.push('/home')
        }
    }

    handleChange = (event) => {
        this.setState({ [event.target.id]: event.target.value })
        event.preventDefault()
    }

    handle2FAChange = (event) => {
        event.preventDefault()
        const nextInputId = parseInt(event.target.id) + 1
        var newState = Object.assign({ ...this.state }, { ["code_"+event.target.id]: event.target.value, codeInd: nextInputId }) 
        this.setState(newState)
        if(this.refArr[nextInputId] && (event.target.value != "")) this.refArr[nextInputId].current.focus()
        if(nextInputId == this.refArr.length) this.verifyCode(newState)
    }

    handle2FABackspace = (event) => {
        event.preventDefault()
        const prevInputId = parseInt(event.target.id) - 1
        var newState = Object.assign({ ...this.state }, { ["code_"+prevInputId]: event.target.value })
        this.setState(newState)
        if(this.refArr[prevInputId] && (event.target.value == "")) {
            this.refArr[prevInputId].current.focus()
            return
        }        
        
    }

    handlePaste = (event) => {
        console.log("handlePaste", event.clipboardData.getData('Text'));
        const pastedTxt = event.clipboardData.getData('Text')
        // Reset code
        var updatedCode = {}
        _.times(6, (n) => {
            updatedCode["code_"+n] = pastedTxt[n] || ""
        })
        this.setState(updatedCode)
        if(updatedCode["code_5"]) this.verifyCode(Object.assign({ ...this.state }, updatedCode) )
    }

    setError = (field, msg) => this.setState({ errorField: field, errorMessage: msg })

    handleSubmit = async () => {
        const { username, password } = this.state;
        if (!username) {
            this.setError("username", "Please enter a valid username.");
            return;
        }
        if (!password) {
            this.setError("password", "Please enter a valid password.");
            return;
        }
        this.setState({ loading: true })
        this.cognitoSignIn()   
    }

    cognitoSignIn = async () => {
        const username = this.state.username
        const password = this.state.password
        try {
            const user = await Auth.signIn(username, password)
            // console.log('Cognito user', user)
            this.props.dispatch(addDataToStore(SAVE_DATA, { cognitoUserInfo: { username: user.username, attributes: user.attributes } }))
            this.setState({ cognitoUserInfo: { username: user.username, attributes: user.attributes } })
            if (user.challengeName === "NEW_PASSWORD_REQUIRED") this.setState({ showNewPasswordModal: true, cognitoUser: { username: user.username, attributes: user.attributes } })
            else this.fetchUserData(null, (err, resp) => {})
        } catch (error) {
            console.log('error signing in', error)
            if(error.code === "UserNotConfirmedException") {
                this.props.history.push({
                    pathname: '/signup/confirm',
                    state: { fromSignIn: true, username, password }
                })
            } else {
                alert(error.message)
            }
            this.setState({ loading: false })
        }
    }

    saveBrowserInfo = () => {
        const browser = Bowser.parse(window.navigator.userAgent)
        var browserInfo = camelCaseToPascalCase(browser)
        browserInfo["AppVersion"] = environment.appVersion
        browserInfo["OS"] = browserInfo["Os"] || {}
        if (browserInfo["Os"]) delete browserInfo["Os"]
        apiPOST("/browserinfo", {}, browserInfo, (err, resp) => {
            console.log("browserinfo saved", err, resp)
        })
    }

    begin2FA = () => {
        this.sendCode()
    }

    sendCode = (options) => {
        options = options || {}
        var { delivery } = this.state
        var { resend } = options
        if(options.delivery) delivery = options.delivery
        if (delivery === "email") delivery = "mail"

        if(resend) {
            // Reset code
            var updatedCode = {}
            _.times(6, (n) => {
                updatedCode["code_"+n] = null
            })
            this.setState(updatedCode)
        }
        apiGET(`/mfa/generate?delivery=${delivery.toUpperCase()}`, null, (err, resp) => {
            try {
                if (err) throw Error(err)
                var data = resp.data
                console.log('twoFactorData', data)
                if (!data.success) throw Error("")
                if (resend) message.success("Successfully sent")
                this.setState({ loading: false, twoFactorData: data, step: 1, delivery: delivery })
            } catch (error) {
                console.log("2FA.SendCode", error, err, resp)
                ErrorAlert({ description: "Sorry we had trouble processing your request. Please try again." })
                this.setState({ loading: false })
            }
        })
    }

    verifyCode = (state) => {
        state = state || {}
        var code = ""
        _.times(6, (n) => {
            code = code + state["code_"+n]
        })
        if(this.state.loading) return // To avoid multiple clicks
        if (!code) {
            // this.setError("code", "Please enter a valid code.");
            ErrorAlert({ description: "Please enter a valid code." })
            return;
        }
        var endpoint = "/mfa/verify"
        if (this.props.type) endpoint = `${endpoint}?type=${this.props.type}`
        let body = {
            "token": code
        }
        this.setState({ loading: true })
        console.log("verifyCode", endpoint, body)
        apiPOST(endpoint, null, body, (err, resp) => {
            try {
                if (err) throw Error(err)
                var data = resp.data
                if (!data.success) throw Error("")
                if (data.TokenValidated) {
                    this.twoFAValidated()
                } else {
                    this.setState({ loading: false })
                    ErrorAlert({ description: "Invalid verification code. Please enter the correct code or request a new one." })
                }
            } catch (error) {
                this.setState({ loading: false })
                ErrorAlert({ description: "Sorry we had trouble processing your request. Please try again." })
            }
        })
    }

    twoFAValidated = () => {
        var { UAM } = this.props.store
        this.props.dispatch(addDataToStore(SAVE_DATA, { "TwoFAValidated": true }))
        this.fetchOwnershipData()
        this.getReferralCode()
        this.fetchIntegration()
        console.log("twoFAValidated fetchPermissionsLoading", UAM)
        if (UAM.aionCustomerSupportUser || UAM.aionUnderWriterUser) {
            this.fetchBusinessApps()
        } else {
            console.log("twoFAValidated fetchPermissionsLoading", this.state.fetchPermissionsLoading)
            if(!this.state.fetchPermissionsLoading) {
                this.loadApp()
            } else {
                setTimeout(this.twoFAValidated, 200)
            }
        }
    }

    next = () =>  this.setState({step: this.state.step + 1})
    back = () => this.setState({step: this.state.step - 1})

    getInputContent = () => {
        const { theme } = this.props;
        const { isTablet, twoFactorData, step, errorField, errorMessage, codeInd, delivery } = this.state
        return (
            <FlexColumn fullWidth>
                {
                    (step == 0) && 
                    <FlexColumn gap="24px">
                        <Text size="28px" weight="500" height="">Sign In</Text>
                        <FlexColumn left>
                            <LabeledInput
                                autoFocus={!isTablet}
                                label="Email"
                                id="username"
                                key="username"
                                type="email"
                                placeholder="Enter your email registered with Aion"
                                value={this.state.username}
                                onChange={this.handleChange}
                                error={errorField == "username"}
                                errorMessage={errorMessage}
                                noAsterisk
                                autocomplete
                            />
                            <LabeledInput
                                label="Password"
                                id="password"
                                type="password"
                                key="password"
                                placeholder="Enter your password"
                                value={this.state.password}
                                onChange={this.handleChange}
                                error={errorField == "password"}
                                errorMessage={errorMessage}
                                onKeyDown={(event) => { if (event.key === "Enter") this.handleSubmit() }}
                                noAsterisk
                            />
                            <StyledLink style={{ textAlign: "center", textDecoration: "underline" }} to="/forgot-password">Forgot Password?</StyledLink>
                        </FlexColumn>
                    </FlexColumn>
                }
                {
                    (step == 1) &&
                    <FlexColumn gap="24px">
                        <Text size="28px" weight="500" height="">Sign In</Text>
                        <Text >{(twoFactorData || {}).Message || (twoFactorData || {}).msg}</Text>
                        <FlexColumn left gap="24px">
                            <Flex gap="8px">
                                {
                                    _.times(6, (n) => {
                                        return (
                                            <LabeledInput
                                                autoFocus={codeInd == n}
                                                inputRef={this.refArr[n]}
                                                id={n}
                                                key={n}
                                                type="code"
                                                // onFocus={e => e.target.select()}
                                                placeholder="_"
                                                onChange={this.handle2FAChange}
                                                onPaste={this.handlePaste}
                                                maxLength={1}
                                                error={errorField == `code_${n}`}
                                                errorMessage={errorMessage}
                                                value={this.state[`code_${n}`]}
                                                onKeyDown={(event) => { if(event.key == "Backspace" && event.target.value == "") this.handle2FABackspace(event) }}
                                                style={{ fontSize: "20px", weight: 400, lineHeight: "32px", textAlign: "center" }}
                                                width="60px"
                                                height="48px"
                                            />
                                        )
                                    })
                                }
                            </Flex>
                            <StyledExtLink key="StyledExtLink" style={{ textAlign: "center", fontSize: "16px", lineHeight: "24px", textDecoration: "underline" }} onClick={() => this.sendCode({ resend: true })}>Resend code</StyledExtLink>
                            <StyledExtLink 
                                key="StyledExtLink" style={{ textAlign: "center", fontSize: "16px", lineHeight: "24px", textDecoration: "underline" }} 
                                onClick={() => {
                                    this.sendCode({ resend: true, delivery: (delivery == "text") ? "email" : "text" })
                                }}
                            >
                                Send my code via {(delivery == "text") ? "email" : "text"}
                            </StyledExtLink>
                        </FlexColumn>
                    </FlexColumn>
                }
            </FlexColumn>
        )
    }

    getCTA = () => {
        const { step, loading } = this.state
        return (
            <FlexColumn>
                {
                    (step == 0) &&
                    <FlexColumn left gap="24px">
                        <Button solid permtype="Override" loading={loading} onClick={() => this.handleSubmit()} text={'Sign In'.toUpperCase()} />
                        <Text>Don't have an Aion account yet? <StyledExtLink href="https://aionfi.com/pricing" style={{ textDecoration: "underline" }}>Get Started</StyledExtLink></Text>
                    </FlexColumn>
                }
                {
                    (step == 1) &&
                    <FlexColumn>
                        <Flex center style={{ gap: '24px' }}>
                            <Button permtype="Override" solid loading={loading} onClick={() => this.verifyCode()} text={'Confirm'.toUpperCase()} />
                            <TextButton permtype="Override" onClick={() => this.back()} text={'Cancel'.toUpperCase()} />
                        </Flex>
                    </FlexColumn>
                }
            </FlexColumn>
        )
    }

    render() {
        const { theme } = this.props
        const { isMobile, isTablet, twoFactorData } = this.state

        // For now show mobile landing page for phone users
        if(isMobile) return <MobileLanding />
        return (
            <>
                <Flex style={{ paddingTop: '80px'  }}>
                    {
                        isMobile || isTablet ?
                            <Flex gap='132px'>
                                <FlexColumn start left gap='48px' style={{ width: "350px", alignSelf: "stretch" }}>
                                    <FlexColumn left style={{ gap: '24px', width: 'inherit' }}>
                                        <AionLogo symbol margin='0px' />
                                        {this.getInputContent()}
                                    </FlexColumn>
                                    {this.getCTA()}
                                </FlexColumn>
                            </Flex>
                            :
                            <Flex gap='132px'>
                                <FlexColumn centerVertically left gap='48px' style={{ width: "350px", alignSelf: "stretch" }}>
                                    <FlexColumn left style={{ gap: '24px', width: 'inherit' }}>
                                        <AionLogo symbol margin='0px' />
                                        {this.getInputContent()}
                                    </FlexColumn>
                                    {this.getCTA()}
                                </FlexColumn>
                                <BrandedContainer style={{ height: "95vh" }}>
                                    <AionOverLay>
                                        <img src={AionOverlayImg} />
                                    </AionOverLay>
                                    <FlexColumn center gap="16px" style={{ padding: '24px 80px', height: '100%' }}>
                                        <Text size="32px" height="60px" center color={theme.body}>Welcome back</Text>
                                        <Divider style={{ background: theme.body }} />
                                        <Text center color={theme.body}>
                                            Log into your Aion account to manage all your business finances. Check account balances, pay vendors, send invoices, unlock capital, and more.
                                        </Text>
                                    </FlexColumn>
                                </BrandedContainer>
                            </Flex>
                    }
                </Flex>
                <Modal
                    visible={this.state.show2FAModal}
                    footer={null}
                    closable={true}
                    width={500}
                    // style={{ top: 20 }}
                    destroyOnClose={true}
                    onCancel={() => { this.setState({ show2FAModal: false }) }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <TwoFactor validationComplete={this.twoFAValidated} />
                </Modal>
                <Modal
                    visible={this.state.showNewPasswordModal}
                    footer={null}
                    closable={true}
                    width={500}
                    style={{ top: 20 }}
                    destroyOnClose={true}
                    onCancel={() => { this.setState({ showNewPasswordModal: false }) }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <NewPassword
                        user={this.state.cognitoUser}
                        newPasswordComplete={
                            () => {
                                this.setState({ showNewPasswordModal: false })
                                this.begin2FA()
                            }
                        }
                    />
                </Modal>
                {/* Referral Code */}
                <Modal
                    visible={this.state.showReferralModal}
                    footer={null}
                    closable={true}
                    width={500}
                    style={{ top: 20 }}
                    destroyOnClose={true}
                    onCancel={() => { this.setState({ showReferralModal: false }) }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <ReferralCode
                        showSignup={
                            (code) => {
                                this.setState({ showReferralModal: false })
                                this.props.history.push(`/create-aion-account?referral=${code}`)
                            }
                        }
                    />
                </Modal>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(SignIn)))