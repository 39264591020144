import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { Modal, Menu, Dropdown } from 'antd';
import { PlusOutlined, CheckOutlined, MoreOutlined, DeleteOutlined } from '@ant-design/icons';

import { Paragraph, Text } from '../../Reusable/Text';
import { Flex, FlexColumn, Container } from '../../Reusable/Refresh/Container';

// Actions
import { LabeledInput, StyledLabel } from '../../Reusable/Refresh/Input';
import { Button, Capsule, TextButton, TagButton } from '../../Reusable/Refresh/Button';
import { capitalize } from 'lodash';
import { SubsidirayCard } from '../../Reusable/Refresh/Card';
import ModalClose from '../../../Images/modal-close.png';
import { ErrorAlert } from '../../Reusable/Alert';
import { addDataToStore, BUSINESS_INFO, REMOTE_SAVE_DATA } from '../../../Actions/actions';
import { capitalizeWords } from '../../../Utils/util';
import cloneDeep from 'lodash/cloneDeep'

const textFieldStyle = {
    background: "#F4F5F7",
    borderRadius: "8px",
    paddingLeft: "5px",
    height: "40px",
    marginTop: "2px"
}


class ListSubsidiaryView extends Component {

    state = {
        subsidiaryInfoClone: {},
        subsidiariesClone: []
    }

    saveUserInput = (id, value) => {
        var dataToSave = this.state.subsidiaryInfo || {};
        switch(id) {
            default:
                dataToSave[id] = value;
                break;
        }
        console.log("ListSubsidiaryView saveUserInput", dataToSave);
        this.setState({ subsidiaryInfo: dataToSave });
    }

    handleOnChange = (event) => {
        const target = event.target;
        var value = target.value;
        if(target.id == "name") value = capitalizeWords(value);
        this.saveUserInput(target.id || target.name, value)
    }

    removeSubsidiary = (name) => {
        var { onboardingStore } = this.props;
        var subsidiaries = (onboardingStore.businessInfo || {}).subsidiaries || [];
        console.log("removeSubsidiary", name);
        subsidiaries = subsidiaries.filter(item => {
            if (item.name !== name) return item
        });
        this.props.dispatch(addDataToStore(BUSINESS_INFO, { subsidiaries: subsidiaries }));
        this.props.dispatch(addDataToStore(REMOTE_SAVE_DATA, "Apply.Basic")); // Apply.Basic because we want the user back on this step on next login
        this.setState({ showParentModal: false })
    }

    setErrorField = (id, msg) => this.setState({ errorField: id, errorMessage: msg });

    validateFlow = () => {
        var { subsidiaryInfo } = this.state;
        var { onboardingStore } = this.props;
        var subsidiaries = (onboardingStore.businessInfo || {}).subsidiaries || [];
        var { name, type } = subsidiaryInfo || {};

        if(!name) {
            this.setErrorField("name", "Please enter a name.");
            return;
        }
        if(!type) {
            ErrorAlert({ description: "Please select a type." });
            return;
        }
        
        // Save to the DB
        console.log("Saving subsidiary", { subsidiaryInfo: subsidiaryInfo });
        var existingSubsidiaryInd = null;
        subsidiaries.forEach((item, i) => {
            if(item.name == name) {
                existingSubsidiaryInd = i;
                item = subsidiaryInfo; // replace
            }
        });
        if(existingSubsidiaryInd == null) {
            subsidiaries.push(subsidiaryInfo);
        }
        this.props.dispatch(addDataToStore(BUSINESS_INFO, { subsidiaries: subsidiaries }));
        this.props.dispatch(addDataToStore(REMOTE_SAVE_DATA, "Apply.Basic"));
        this.setState({ showParentModal: false });
    }
    render() {
        var { theme, onboardingStore } = this.props;
        var subsidiaries = (onboardingStore.businessInfo || {}).subsidiaries || [];
        var { subsidiaryInfo, errorField, errorMessage } = this.state;
        subsidiaryInfo = subsidiaryInfo || {};
        var t = this;

        function addSubsidiary() {
            t.setState({ showParentModal: true, subsidiaryInfo: { type: "Parent" }});
        }

        function editSubsidiary(name) {
            var selectedSubsidiaryInfo = subsidiaries.find((item) => {
                return item.name == name;
            })
            t.setState({ showParentModal: true, subsidiaryInfo: selectedSubsidiaryInfo, subsidiaryInfoClone: cloneDeep(selectedSubsidiaryInfo) , subsidiariesClone: cloneDeep(subsidiaries)});
        }

        return (
            <FlexColumn start>
                 <Flex wrap start >
                {subsidiaries.map((item, j) => {
                   return (
                        <SubsidirayCard subsidirayInfo={item} theme={theme} key={j} onClick={() => editSubsidiary(item.name)}/>    
                    );
                })}
                </Flex>
                <Modal
                    visible={this.state.showParentModal}
                    footer={null}
                    closable={true}
                    width={700}
                    destroyOnClose={true}
                    onCancel={() => { 
                        this.props.dispatch(addDataToStore(BUSINESS_INFO, { subsidiaries: this.state.subsidiariesClone }));
                        this.setState({ showParentModal: false, subsidiaryInfo: this.state.subsidiaryInfoClone }); }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <FlexColumn start>
                        <Text style={{ marginBottom: 20 }} size='20px'>{!subsidiaryInfo.name ? "New Parent or Subsidiary" : "Update Parent or Subsidiary"}</Text>
                        <Text color={'#7384AA'} size='14px' weight={400}>Type</Text>

                        <Flex between style={{ width: "20%", marginTop: 8 }}>
                            <Button weight={subsidiaryInfo.type === 'Parent' ? '600' : '400'} color={subsidiaryInfo.type === 'Parent' ? '#1199FF' : '#7384AA'} onClick={() => this.saveUserInput("type", "Parent")} text='Parent' radius='4px' style={{ marginRight: 4 }} />
                            <Button weight={subsidiaryInfo.type === 'Subsidiary' ? '600' : '400'} color={subsidiaryInfo.type === 'Subsidiary' ? '#1199FF' : '#7384AA'} onClick={() => this.saveUserInput("type", "Subsidiary")} text='Subsidiary' radius='4px' />
                        </Flex>

                        <LabeledInput
                            autoFocus
                            margin='24px 0 48px'
                            label="Name"
                            labelcolor={theme.colors.secondary3}
                            id="name"
                            placeholder="Enter name"
                            value={subsidiaryInfo.name}
                            onChange={this.handleOnChange}
                            error={errorField=="name"}
                            errorMessage={errorMessage}
                        />
                        <Flex between>
                            {<Button weight='500' onClick={() => this.validateFlow()} text={!subsidiaryInfo.name ? "CONFIRM" : "SAVE CHANGES"} solid radius='4px' />}
                            {this.state.newSubsidiary ? null: <TextButton onClick={() => this.removeSubsidiary(subsidiaryInfo.name)} icon={<DeleteOutlined  style={{color: "red"}}/>} /> } 
                        </Flex>
                    </FlexColumn>
                </Modal>
                {<TextButton margin="0 0 24px 0"  onClick={() => addSubsidiary()} text={"ADD ENTITY"}></TextButton>}

            </FlexColumn>
        );
    }


}
function mapStateToProps(state) {
    return {
        store: state.aionAppReducer,
        onboardingStore: state.onboardingAppReducer,
        creditStore: state.creditAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(ListSubsidiaryView)));