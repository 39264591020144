import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { cloneDeep } from 'lodash'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Empty, Popover, Table, Dropdown, Menu, Tabs } from 'antd'

// Util
import environment from '../../../environment'
import { apiPOSTReq } from '../../../Utils/api'
import { getResourcePerm, getFeaturePerm } from '../../../Utils/util'
import PageHeader from "../../Reusable/PageHeader"
import { LabeledInput } from '../../Reusable/Input'
import { Flex, FlexColumn, CardContainer, InputContainer } from '../../Reusable/Container'
import { TextButton, ImageButton } from '../../Reusable/Button';
import Deactivate from './Deactivate'
import Activate from './Activate'

// Actions
import { RECEIVABLES_SAVE_DATA, addDataToStore } from '../../../Actions/actions'

// Images
import TickIcon from "../../../Images/tick.png"
import MissingIcon from "../../../Images/missing.png"
import { ErrorAlert } from '../../Reusable/Alert'
import { Button } from '../../Reusable/Button'
import { Image } from '../../Reusable/Image'
import { Text } from '../../Reusable/Text';
import Modal from 'antd/lib/modal/Modal'
import NewCustomer from './NewCustomer'
import EditQBOCustomer from './EditCustomer'
import Search from '../../../Images/search.png'
import ChevronDown from "../../../Images/chevron-down.svg"
import Edit from "../../../Images/edit.png"
import Add from '../../../Images/add-white.svg'
import More from '../../../Images/more.png'
import ModalClose from '../../../Images/modal-close.png'
import TableView from '../../Reusable/TableView'

const { TabPane } = Tabs

class Index extends Component {
    ref = null
    loadingAnimation = null
    state = {
        loading: true,
        loadingInactive: true,
        customize: true,
        pagination: {
            current: 1,
            pageSize: 10,
            hideOnSinglePage: true
        },
        inactivePagination: {
            current: 1,
            pageSize: 10,
        },
        tab: 'active',
    }

    componentDidMount() {
        const { loanApp } = this.props
        if (loanApp) {
            this.fetchTopCustomers({ pagination: this.state.pagination })
        } else {
            this.fetchCustomers({ pagination: this.state.pagination })
            this.fetchInactiveCustomers({ pagination: this.state.inactivePagination })
        }
    }

    componentDidUpdate(prevProps) {
        let { location } = this.props
        var state = location.state ? location.state : {}
        if (state.reloadData) {
            this.props.location.state = {}
            this.fetchCustomers({ pagination: this.state.pagination })
        }
    }

    customizeRenderEmpty = () => (
        <Empty
            imageStyle={{
                height: 60,
            }}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
                <span>
                    Nothing found
                </span>
            }
        >
            {/* <Link to="/receivables/customers/add" key="customers">
                <Button type="primary" size="large">Submit Customer</Button>
            </Link> */}
        </Empty>
    )

    fetchCustomers(options) {
        let { pagination, searchTerm } = options
        // Fetch customer list
        const headers = {
            AionCurrentBiz: this.props.aionStore.BusinessUniqueKey,
            AionAuth: this.props.aionStore.UAM.encryptedAuthHeader
        }
        const body = {
            "BusinessId": this.props.aionStore.BusinessUniqueKey,
            "size": pagination.pageSize,
            "page": pagination.current - 1
        }
        if (searchTerm) {
            body.size = pagination.pageSize
            body.page = pagination.current - 1

            body.searchStr = searchTerm
        }
        this.setState({ loading: true })
        console.log("/getActiveCustomers body", body)
        apiPOSTReq(`${environment.iveBaseUrl}/ive/bc/getActiveCustomers`, headers, body, (err, resp) => {
            try {
                const data = resp
                console.log("/getActiveCustomers", data)
                if (data.result) {
                    this.props.dispatch(addDataToStore(RECEIVABLES_SAVE_DATA, { ActiveCustomers: data.customers }))
                    var customers = pagination.current > 1 ? this.state.customers.concat(data.customers) : data.customers
                    var moreCustomers = customers.length == data.count ? false : true
                    this.setState({
                        customers: customers,
                        pagination: {
                            ...pagination,
                            total: data.count
                        },
                        moreToLoad: moreCustomers,
                    })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getActiveCustomers", error, err, resp)
                ErrorAlert({ description: error.message })
            } finally {
                this.setState({ loading: false })
            }
        })
    }

    fetchInactiveCustomers(options) {
        let { pagination, searchTerm } = options
        // Fetch customer list
        const headers = {
            AionCurrentBiz: this.props.aionStore.BusinessUniqueKey,
            AionAuth: this.props.aionStore.UAM.encryptedAuthHeader
        }
        const body = {
            "BusinessId": this.props.aionStore.BusinessUniqueKey,
            "size": pagination.pageSize,
            "page": pagination.current - 1
        }
        if (searchTerm) {
            body.size = pagination.pageSize
            body.page = pagination.current - 1

            body.searchStr = searchTerm
        }
        this.setState({ loadingInactive: true })
        apiPOSTReq(`${environment.iveBaseUrl}/ive/bc/getInActiveCustomers`, headers, body, (err, resp) => {
            try {
                const data = resp
                console.log("/getInActiveCustomers", data)
                if (data.result) {
                    var inactiveCustomers = pagination.current > 1 ? this.state.inactiveCustomers.concat(data.customers) : data.customers
                    var moreCustomers = inactiveCustomers.length == data.count ? false : true
                    this.setState({
                        inactiveCustomers: inactiveCustomers,
                        inactivePagination: {
                            ...pagination,
                            total: data.count
                        },
                        moreInactiveToLoad: moreCustomers,
                    })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getInActiveCustomers", error, err, resp)
                ErrorAlert({ description: error.message })
            } finally {
                this.setState({ loadingInactive: false })
            }
        })
    }

    fetchTopCustomers(options) {
        const { pagination } = options
        const { loanApp } = this.props
        const body = {
            "clientBusinessId": this.props.aionStore.BusinessUniqueKey,
            "applicationId": loanApp.applicationId,
            "size": pagination.pageSize,
            "page": pagination.current - 1
        }
        this.setState({ loading: true })
        apiPOSTReq(`${environment.opsBaseUrl}/ops/loans/application/getTopCustomers`, {}, body, (err, resp) => {
            try {
                const data = resp
                console.log("/getTopCustomers", (data))
                if (data.result) {
                    this.setState({
                        customers: data.customers,
                        pagination: {
                            ...pagination,
                            total: data.count
                        }
                    })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getTopCustomers", error, err, resp)
                ErrorAlert({ description: error.message })
            } finally {
                this.setState({ loading: false })
            }
        })
    }

    onTabChange = tab => {
        this.setState({ tab })
    }

    onCustomerEdit = (customer) => {
        var v2BetaEnabled = getFeaturePerm("Receivables.Invoices.Receivables v2 Beta")

        if (customer) {
            this.props.dispatch(addDataToStore(RECEIVABLES_SAVE_DATA, { editedCustomer: cloneDeep(customer) }))
        } else {
            this.props.dispatch(addDataToStore(RECEIVABLES_SAVE_DATA, { editedCustomer: null }))
        }

        this.props.history.push(v2BetaEnabled.manage ? {
            pathname: '/receivables/customers/create',
            state: { customer }
        } : '/receivables/customers/edit')
    }

    handleTableChange = (pagination, filters, sorter) => {
        this.fetchCustomers({ pagination: pagination })
    }

    onSearchChange = (e) => {
        this.setState({ searchTerm: e.target.value })
        const pagination = {
            current: 1,
            pageSize: 10
        }
        this.fetchCustomers({ pagination, searchTerm: e.target.value })
        this.fetchInactiveCustomers({ pagination, searchTerm: e.target.value })
    }

    loadMoreCustomers = () => {
        const { tab, searchTerm } = this.state

        if (tab === 'active') {
            this.fetchCustomers({
                pagination: {
                    current: this.state.pagination.current + 1,
                    pageSize: 10,
                },
                searchTerm: searchTerm && searchTerm,
            })
        } else {
            this.fetchInactiveCustomers({
                pagination: {
                    current: this.state.inactivePagination.current + 1,
                    pageSize: 10,
                },
                searchTerm: searchTerm && searchTerm,
            })
        }
    }

    render() {
        var { customerToDeactivate, customers, inactiveCustomers, tab, moreToLoad, moreInactiveToLoad, loading, showDeactivate, showActivate, customerToActivate, loadingInactive } = this.state
        const { aionStore, loanApp } = this.props
        customers = customers || []
        customers = customers.sort((b, a) => (b.displayName || "").toLowerCase().localeCompare((a.displayName || "").toLowerCase()))

        var v2BetaEnabled = getFeaturePerm("Receivables.Invoices.Receivables v2 Beta")

        const renderIcon = (complete) => {
            return <Image width='20px' height='20px' src={complete ? TickIcon : MissingIcon} />
        }

        var columns = [
            {
                title: 'Name',
                dataIndex: 'displayName',
                key: 'displayName',
                render: (displayName, record) => {
                    return v2BetaEnabled.manage ? <a style={{ textDecoration: "underline" }}>{displayName}</a> :
                        <Text>{displayName}</Text>
                },
            },
            // {
            //     title: 'Parent Name',
            //     dataIndex: 'parentCustomerName',
            //     key: 'parentCustomerName',
            //     render: (item, record) => {
            //         let parentCustomerName = item
            //         let parentCustomer = null
            //         if (record.parentCustomerId) {
            //             parentCustomer = customers.find(x => x.customerId === record.parentCustomerId)
            //         }
            //         if (!parentCustomerName && parentCustomer) {
            //             parentCustomerName = parentCustomer.displayName
            //         }
            //         return parentCustomerName ? parentCustomerName : '-'
            //     }
            // },
            {
                title: 'Contact Name',
                dataIndex: 'fullyQualifiedName',
                key: 'fullyQualifiedName',
                render: (fullyQualifiedName, record) => {
                    return fullyQualifiedName ? fullyQualifiedName : '-'
                }
            },
            {
                title: 'Contact Email',
                dataIndex: 'primaryEmailAddress',
                key: 'primaryEmailAddress',
                textWrap: 'word-break',
                render: (item) => <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>{(item ? item.address || "" : "")}</div>
            },
        ]

        if (!v2BetaEnabled.manage) {
            columns = columns.concat([{
                title: (
                    <Popover placement='left' content="Details indicate if the invoicing info for the customer has been filled.">
                        <Text noWrap>Details </Text>
                        <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                    </Popover>
                ),
                key: 'Details',
                align: 'center',
                width: "80px",
                render: (item, record) => renderIcon(record.primaryEmailAddress && record.primaryEmailAddress.address),
            },
            {
                title: (
                    <Popover placement='left' title="MSA or Master Service Agreement" content="A master service agreement is a contract entered into by you and your customer. This agreement details the expectations and the terms to fulfil the contract.">
                        <Text noWrap>MSA </Text>
                        <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                    </Popover>
                ),
                dataIndex: 'msauploaded',
                key: 'msauploaded',
                align: 'center',
                width: "80px",
                render: (item) => renderIcon(item)
            },
            {
                title: (
                    <Popover placement='left' title="Work Orders" content="This document could be a work order or a purchase order or a statement of work.">
                        <Text noWrap>Work Orders </Text>
                        <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                    </Popover>
                ),
                dataIndex: 'pouploaded',
                key: 'pouploaded',
                align: 'center',
                width: "120px",
                render: (item) => renderIcon(item)
            },
            ])
        }

        columns.push({
            title: '',
            key: 'action',
            align: 'center',
            width: "40px",
            dataIndex: 'displayName',
            onHover: true,
            render: (displayName, record) => (
                <Flex fullWidth end centerHorizontally>
                    {
                        tab === 'active' ?
                            <Dropdown
                                placement='bottomRight'
                                trigger={['click']}
                                key={`${record.docNumber}-Dropdown`}
                                overlay={<Menu style={{ padding: 24, borderRadius: 8, zIndex: 1001 }} key={`${record.docNumber}-Menu`} onClick={() => { }}>
                                    <FlexColumn gap='24px'>
                                        <TextButton text='EDIT' onClick={(event) => {
                                            event.stopPropagation(); // Stop event propagation here as well
                                            this.onCustomerEdit(record)
                                        }} rightIcon={<Image src={Edit} />} />
                                        <TextButton permtype="Receivables.Invoices" text='DEACTIVATE' onClick={(event) => {
                                            event.stopPropagation(); // Stop event propagation here as well
                                            this.setState({ customerToDeactivate: record, showDeactivate: true })
                                        }} />
                                    </FlexColumn>
                                </Menu>}
                            >
                                <ImageButton
                                    src={More}
                                    onClick={(event) => {
                                        event.stopPropagation(); // Stop event propagation here as well
                                    }} />
                            </Dropdown>
                            :
                            <TextButton
                                loading={this.state[`${displayName}ActivateLoading`]}
                                onClick={(event) => {
                                    event.stopPropagation(); // Stop event propagation here as well
                                    this.setState({ customerToActivate: record, showActivate: true })
                                }}
                                permtype="Receivables.Invoices"
                                text='ACTIVATE'
                            />
                    }
                    {
                        getResourcePerm("Support.Console").aionSystemUser &&
                        <a onClick={() => { this.onCustomerEdit(record) }}>View</a>
                    }
                </Flex>
            ),
        })

        return (
            <FlexColumn className='main-padding'>
                {
                    !loanApp &&
                    <PageHeader
                        titleText="Receivables"
                        subtitle="Receivables"

                    />
                }

                <Tabs size="large" activeKey={tab} defaultActiveKey={tab} onClick={() => { }} onChange={(key) => this.setState({ tab: key })} style={{ marginBottom: 12 }}>
                    <TabPane tab="Active" key="active" />
                    <TabPane tab="Inactive" key="inactive" />
                </Tabs>

                <TableView
                    id="customer-table"
                    titleText='Customers'
                    tableLayout='auto'
                    columns={columns}
                    dataSource={tab === 'active' ? customers : inactiveCustomers}
                    rowKey='customerId'
                    pagination={false}
                    onChange={this.handleTableChange}
                    loading={tab === 'active' ? loading : loadingInactive}
                    ctaContent={
                        !loanApp &&
                        <Flex gap='24px'>
                            <div>
                                <LabeledInput
                                    id="searchTerm"
                                    key="searchTerm"
                                    nomargin
                                    placeholder="Search by name"
                                    prefixIcon={<Image width='12px' height='12px' src={Search} />}
                                    onChange={this.onSearchChange}
                                    width='250px'
                                />
                            </div>
                            <Button permtype="Receivables.Invoices" solid onClick={() => v2BetaEnabled.manage ? this.props.history.push('/receivables/customers/create') : this.onCustomerEdit(null)} text='ADD CUSTOMER' icon={<Image src={Add} />} />
                        </Flex>
                    }
                    onRow={
                        (record, rowIndex) => {
                            return {
                                onClick: event => {
                                    this.props.history.push({
                                        pathname: '/receivables/customers/details',
                                        state: { customer: record }
                                    })
                                }, // click row
                            }
                        }
                    }
                />

                {
                    ((tab === 'active' && moreToLoad) || (tab === 'inactive' && moreInactiveToLoad)) &&
                    <Flex fullWidth centerVerticall style={{ marginTop: "10px" }}>
                        <TextButton text='LOAD 10 MORE' loading={loading} onClick={this.loadMoreCustomers} rightIcon={<img style={{ cursor: 'pointer' }} width='24px' height='24px' src={ChevronDown} />} />
                    </Flex>
                }

                <Modal
                    visible={this.state.showNewCustomerModal}
                    footer={null}
                    closable={true}
                    width={1000}
                    style={{ top: 20 }}
                    destroyOnClose={true}
                    onCancel={() => { this.setState({ showNewCustomerModal: false }) }}
                >
                    <NewCustomer
                        customer={this.state.selectedCustomer}
                        submitComplete={() => {
                            this.setState({ showNewCustomerModal: false })
                            this.fetchCustomers({ pagination: this.state.pagination })
                        }}
                    />
                </Modal>

                <Modal
                    visible={this.state.showQBOCustomerModal}
                    footer={null}
                    closable={true}
                    width={1000}
                    style={{ top: 20 }}
                    destroyOnClose={true}
                    onCancel={() => { this.setState({ showQBOCustomerModal: false }) }}
                >
                    <EditQBOCustomer
                        customer={this.state.selectedCustomer}
                        submitComplete={() => {
                            this.setState({ showQBOCustomerModal: false })
                            this.fetchCustomers({ pagination: this.state.pagination })
                        }}
                    />
                </Modal>

                <Modal
                    visible={showDeactivate}
                    footer={null}
                    closable={false}
                    width={456}
                    destroyOnClose={true}
                    onCancel={() => {
                        this.setState({ showDeactivate: false })
                    }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <Deactivate
                        customer={customerToDeactivate}
                        onCancel={() => this.setState({ showDeactivate: false })}
                        onDeactivated={() => {
                            this.setState({ showDeactivate: false, tab: 'inactive' })
                            this.fetchCustomers({ pagination: { current: 1, pageSize: 10 } })
                            this.fetchInactiveCustomers({ pagination: { current: 1, pageSize: 10 } })
                        }} />
                </Modal>

                <Modal
                    visible={showActivate}
                    footer={null}
                    closable={false}
                    width={456}
                    destroyOnClose={true}
                    onCancel={() => {
                        this.setState({ showActivate: false })
                    }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <Activate
                        customer={customerToActivate}
                        onCancel={() => this.setState({ showActivate: false })}
                        onActivated={() => {
                            this.setState({ showActivate: false, tab: 'active' })
                            this.fetchCustomers({ pagination: { current: 1, pageSize: 10 } })
                            this.fetchInactiveCustomers({ pagination: { current: 1, pageSize: 10 } })
                        }}
                    />
                </Modal>
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.receivablesAppReducer,
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index))