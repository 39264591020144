import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Route, Switch } from 'react-router-dom';
import { withTheme } from 'styled-components';

import UserAccess from './UserAccess/Index';
import UserProfile from './Profile/UserProfile/Index';
import Support from './Support/Index';
import API from './API Keys/Index';
import QuickBooksOnline from './QuickBooksOnline/Index';
import Sync from './Sync/Index';
import ASSync from './ASSync/Index';
import Integration from './Integration/Index';
import COA from './COA/Index';
import BankConnect from './External Bank Connect/Index';
import CompanyProfile from './Company Profile/Index';
import NewRole from './UserAccess/NewRole/Index';
import Ownership from './Company Profile/Ownership';
import CompanyCards from './Company Profile/CompanyCards';
import InviteUser from './UserAccess/InviteUser/Index';
import Referrals from './Referrals/Index';

class Index extends Component {

    render() {
        let { location } = this.props;
        return (
            <Switch location={location}>
                <Route exact path="/settings/user-access" component={UserAccess} />
                <Route exact path="/settings/profile" component={CompanyProfile} />
                <Route exact path="/settings/profile/company-ownership" component={Ownership} />
                <Route exact path="/settings/user-profile" component={UserProfile} />
                <Route exact path="/settings/manage-cards" component={CompanyCards} />
                <Route exact path="/settings/support" component={Support} />
                <Route exact path="/settings/api-access" component={API} />
                <Route exact path="/settings/coa" component={COA} />
                <Route exact path="/settings/integration" component={Integration} />
                <Route exact path="/settings/integration/qb-setup" component={QuickBooksOnline} />
                <Route exact path="/settings/sync" component={Sync} />
                <Route exact path="/settings/accounting-system-sync" component={ASSync} />
                <Route exact path="/settings/bank-connect" component={BankConnect} />
                <Route exact path="/settings/new-role" component={NewRole} />
                <Route exact path="/settings/invite-user" component={InviteUser} />
                <Route exact path="/settings/referrals" component={Referrals} />
            </Switch>
        );
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)));