import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'

import { FlexColumn } from '../../Reusable/Container'
import { Text } from '../../Reusable/Refresh/Text'
import ClockIcon from '../../../Images/clock-icon.png'
import RejectedIcon from '../../../Images/rejected.png'
import Vector8 from '../../../Images/vector-8.png'
import Vector7 from '../../../Images/vector-7.png'
import ArrowRight from '../../../Images/arrow-right.png'
import SignUpImg from '../../../Images/signup-img-blue.png';

import { TextButton } from '../../Reusable/Button'
import { Image } from '../../Reusable/Image'

class InviteeAppStatus extends Component {

    state = {}

    componentDidMount() {
        
    }

    render() {
        var { preboardUser, theme, inviteUserType, authUser } = this.props
        var { email, status } = preboardUser

        var icon = ClockIcon
        var title = 'Your information is being reviewed'
        var cImg = null
        var desc = <Text style={{ maxWidth: '575px', textAlign: 'center' }}>{authUser ? "We will get back to once your information has been verified. If we have any questions, we will reach out to you at" : "If we have any questions, we will reach out to you at"} <span style={{ color: theme.colors.primary, textDecorationLine: 'underline' }}>{email}</span></Text>
        var cta = null
        var showBgDesign = true
        
        switch (status) {
            case "Processed":
                icon = null
                cImg = SignUpImg
                title = 'Welcome to Aion'
                desc = <Text style={{ maxWidth: '320px', textAlign: 'center' }}>One platform to manage all your business finances and operations from banking, paying your employees and vendors, getting paid early on your invoices, managing spend to operating with real time insights.</Text>
                cta = <TextButton text='LETS GET STARTED' onClick={() => {
                    window.location.href = '/' 
                } } rightIcon={<Image src={ArrowRight} />} />
                showBgDesign = false
                break
            case "Denied":
                icon = RejectedIcon
                title = 'Verification unsuccessful'
                desc = <Text style={{ maxWidth: '575px', textAlign: 'center' }}>We cannot verify the information that you provided to us. Please contact your invitee to proceed further.<br/><br/>Thank you for your understanding and we apologize for any inconvenience this may have caused.</Text>
                showBgDesign = false
                break
            case "Error":
                icon = RejectedIcon
                title = 'Error'
                desc = <Text style={{ maxWidth: '575px', textAlign: 'center' }}>There was an error while processing your request. Please reach out to contact@aionfi.com</Text>
                showBgDesign = false
                break
            default:
                title = <>Thank you!<br/><br/>{authUser ? "Your information is being reviewed" : "Your information was successfully submitted"}</>
                break
        }
        return (
            <>
                <FlexColumn fullHeight fullWidth style={{ position: 'relative' }}>
                    {
                        showBgDesign &&
                        <>
                            <img className='vector-8-onb' src={Vector8} />
                            <img className='vector-7-onb' src={Vector7} />
                        </>
                    }
                    
                    <FlexColumn center gap='24px' style={{ marginBottom: '5%' }}>
                        {icon && <img height='48px' width='48px' src={icon} />}
                        {cImg  && <img height='320px' width='320px' src={cImg} />}
                        <Text bold size='28px' height='40px' style={{ textAlign: 'center', maxWidth: '400px' }}>{title}</Text>
                        <div style={{ width: '80px', height: '1px', background: 'radial-gradient(83.75% 38.54% at 16.25% 86.88%, #1199FF 0%, #1A8AFA 100%)' }}/>
                        {desc}
                        {cta}
                    </FlexColumn>
                    
                </FlexColumn>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        onboardingStore: state.onboardingAppReducer,
        aionStore: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(InviteeAppStatus));