import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'

import { Flex, FlexColumn } from '../../Reusable/Container'
import { getTotalOwnershipPercentage, isEmailValid, formatOwnershipPercentage } from '../../../Utils/util'
import { LabeledInput } from '../../Reusable/Input'
import { Text } from '../../Reusable/Refresh/Text'

import { ErrorAlert } from '../../Reusable/Alert'
import { Button } from '../../Reusable/Button'
import { apiPOSTReq } from '../../../Utils/api'
import environment from '../../../environment'
import { message } from 'antd'

class OwnerInvite extends Component {

    state = {
        loading: false,
        ownerType: this.props.ownerType, // coOwner, controlPerson, signer,
        ownerInfo: this.props.selectedOwner || {},
        flowStep: this.props.flowStep
    }

    componentDidMount() {
    }

    saveUserInput = (id, value) => {
        var dataToSave = {}
        dataToSave = { [id]: value }
        // Save new data
        this.setState({ ownerInfo: { ...this.state.ownerInfo, ...dataToSave} })

    }

    validateFlow = () => {
        var { email, ownershipPercentage } = this.state.ownerInfo
        var { userInfo } = this.props.aionStore
        userInfo = userInfo || {}
        
        if(!email || !isEmailValid(email)) {
            this.setErrorField("email", "Enter a valid email")
            return false;
        }
        if(email == userInfo.email) {
            this.setErrorField("email", "This appears to be your email. Enter a different one")
            return false;
        }
        
        if(this.state.ownerType == "coOwner") {
            if (ownershipPercentage && isNaN(ownershipPercentage)) {
                this.setErrorField("ownershipPercentage", "Needs to be a valid number")
                return false;
            }
            if(ownershipPercentage && ownershipPercentage < 25) {
                this.setErrorField("ownershipPercentage", "Needs to be 25% or more")
                return false;
            }
            if(ownershipPercentage && (getTotalOwnershipPercentage({ preboardUsers: this.props.preboardUsers }) + Number(ownershipPercentage)) > 100) {
                this.setErrorField("ownershipPercentage", "Total ownership cannot exceed 100%")
                return false;
            }
        }
        var ownerInfo = this.state.ownerInfo
        this.setState({ ownerInfo: ownerInfo })
        this.setErrorField({"": ""})

        return true;
    }

    inviteUser = () => {
        var { BusinessUniqueKey, userInfo } = this.props.aionStore
        var { firstName, lastName, email, ownershipPercentage } = this.state.ownerInfo
        var { ownerType, onboardingStore } = this.props
        
        var body = {
            "user": {
                "email": email,
                "businessId": BusinessUniqueKey,
                "roles": [],
                "invitedByEmail": userInfo.email,
                "invitedByName": `${userInfo.firstName} ${userInfo.lastName}`,
                "userType": "CoOwner",
                "firstName": firstName || null,
                "lastName": lastName || null,
                "ownershipPercentage": ownershipPercentage ? parseFloat(ownershipPercentage) : null,
                "beneficialOwner": ownerType == "coOwner",
                "controlPerson": ownerType == "controlPerson",
                "nonResidentFlow": onboardingStore.allowNRFlow
            }
        }
        
        this.setState({ loading: true })
        apiPOSTReq(`${environment.uamBaseUrl}/preboardPreSeedUser`, {}, body, (err, resp) => {
            try {
                if (err) throw Error(err)
                var data = resp || {}
                console.log("preboardUser data", err, data.preboardUser)
                if (!data.result) throw Error(data.responseMessage || data.error)
                this.setState({ loading: false })
                message.success('Successfully invited user!')
                this.props.onSubmit()
            } catch (error) {
                this.setState({ loading: false })
                ErrorAlert({ description: error.message || "Sorry we had trouble processing your request. Please try again." })
            }
        })
    }
    
    onSubmit = (ownerInfo) => {
        var isValid = this.validateFlow()
        if(isValid) {
            this.inviteUser()
        }
    }

    handleOnChange = (event) => {
        const target = event.target
        if ((target.id || target.name) == 'ownershipPercentage') {
            if (!isNaN(target.value)) {
                if((target.value > 100)) {
                    this.saveUserInput(target.id || target.name, target.value.slice(0,2));
                }else {
                    this.saveUserInput(target.id || target.name, formatOwnershipPercentage(target.value >= 0 ? target.value : ''));
                    this.setState({ errorField: null, errorMessage: null });
                }
            }
            else {
                this.setState({ errorField: 'ownershipPercentage', errorMessage: 'Please enter a valid ownership percentage' });
                this.saveUserInput(target.id || target.name, target.value);
            }
        } else {
            this.saveUserInput(target.id || target.name, target.value)
        }
    }

    setErrorField = (id, msg) => this.setState({ errorField: id, errorMessage: msg })

    render() {
        var { flowSteps, ownerType } = this.props
        var { errorField, errorMessage, showVouched, showDelete, ownerInfo, loading, isWeb, isMobile } =  this.state
        var { firstName, lastName, email, ownershipPercentage } = ownerInfo
        flowSteps = flowSteps || []
        
        var title = `Invite a beneficial owner`
        var desc = `Invite an owner who has more than 25% ownership to add their details to your applciation.`

        if(ownerType == 'controlPerson') {
            title = `Invite a controlling party`
            desc = `Invite a controlling party to add their details to your applciation.`
        }
        var childViews = (
            <FlexColumn between left fullHeight style={{ marginTop: "0px", minHeight: '300px' }} gap='24px'>
                <FlexColumn fullWidth>
                    <Text size="20px" weight={500} height="40px">{title}</Text>
                    <Text style={{ maxWidth: '625px' }}>{desc}</Text>
                    <FlexColumn start style={{ width: "100%", marginTop: '24px' }} gap='8px'>
                        <Flex>
                            <LabeledInput
                                label="Business Email"
                                type="email"
                                key="email"
                                id="email"
                                onChange={this.handleOnChange}
                                value={email}
                                placeholder="tim@mail.com"
                                // instruction="Email for the invite"
                                error={errorField=="email"}
                                errorMessage={errorMessage}
                            />
                        </Flex>
                        <Flex gap="24px">
                            <LabeledInput
                                labelcolor={this.props.theme.colors.secondary3}
                                label="First Name"
                                key="firstName"
                                id="firstName"
                                onChange={this.handleOnChange}
                                value={firstName}
                                placeholder="Robert"
                                error={errorField=="firstName"}
                                errorMessage={errorMessage}
                                optional
                            />
                            <LabeledInput
                                labelcolor={this.props.theme.colors.secondary3}
                                label="Last Name"
                                key="lastName"
                                id="lastName"
                                onChange={this.handleOnChange}
                                value={lastName}
                                placeholder="Glass"
                                error={errorField=="lastName"}
                                errorMessage={errorMessage}
                                optional
                            />
                        </Flex>
                        <Flex gap="24px">
                            {
                                (ownerType == 'coOwner') &&
                                <LabeledInput
                                    label="Ownership Percentage"
                                    key=""
                                    id="ownershipPercentage"
                                    symbolsuffix="%"
                                    value={formatOwnershipPercentage(ownershipPercentage ? ownershipPercentage : '')}
                                    maxLength={6}
                                    placeholder="Enter Percentage"
                                    onChange={this.handleOnChange}
                                    error={errorField=="ownershipPercentage"}
                                    errorMessage={errorMessage}
                                    suffixFontSzie="20px"
                                    optional
                                />
                            }
                        </Flex>
                    </FlexColumn>
                </FlexColumn>
                <Flex fullWidth centerHorizontally between>
                    <Button solid text='SEND INVITE' onClick={() => this.onSubmit(ownerInfo)} loading={loading} />
                </Flex>                
            </FlexColumn>
        )
        return (
            <>
                {childViews}
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        onboardingStore: state.onboardingAppReducer,
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(OwnerInvite))