import { createStore, combineReducers, applyMiddleware } from 'redux';
import { logger } from 'redux-logger';

import aionAppReducer from './aionAppReducer';
import onboardingAppReducer from './onboardingAppReducer';
import bankingAppReducer from './bankingAppReducer';
import bookkeepingAppReducer from './bookkeepingAppReducer';
import creditAppReducer from './creditAppReducer';
import receivablesAppReducer from './receivablesAppReducer';
import userDefaultsReducer from './userDefaultsReducer';

// Utils
import { loadState } from '../Utils/localStorage';

const persistedState = loadState();

const store = createStore(
	combineReducers({
		aionAppReducer,
        onboardingAppReducer,
		bankingAppReducer,
		creditAppReducer,
		receivablesAppReducer,
		userDefaultsReducer,
		bookkeepingAppReducer
	}),
	persistedState
);

export default store;