import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { EditorState } from 'draft-js';
import 'draft-js/dist/Draft.css';
import {
    ConfigProvider,
    Modal,
    Skeleton,
    Typography,
    Tabs,
    Input,
} from 'antd';

import { saveAs } from 'file-saver'
import { TextButton } from '../../../Reusable/Button';
import { apiPOSTReq, getPreSignedS3Url } from '../../../../Utils/api';
import environment from '../../../../environment';

// Util

import { Flex, FlexColumn } from '../../../Reusable/Container';
// import { Paragraph, Title } from '../../Reusable/Text';

// Actions

import 'react-multi-email/style.css';
import moment from 'moment';
import TableView from '../../../Reusable/TableView';

const { TextArea } = Input;
const { confirm } = Modal;
const { Paragraph } = Typography;
const { TabPane } = Tabs;



class Index extends Component {
    ref = null;
    loadingAnimation = null;

    constructor(props) {
        super(props);
        this.state = {
            customersLoaded: true,
            customize: true,
            emails: [],
            attachments: [],
            showEditModal: false,
            editorState: EditorState.createEmpty(),
            paymentConfirmationTrackLog: {}
        };
    }

    componentDidMount() {
        this.fetchBusinessDocs();
    }

    componentDidUpdate(prevProps) {
        var { changed } = this.props;
        if (changed != prevProps.changed) {
            this.fetchBusinessDocs();
        }
    }

    fetchBusinessDocs() {
        console.log('businesskey', this.props.businessId)
        // Fetch payer list
        // Check session and fetch attributes
        const headers = {
            AionCurrentBiz: this.props.businessId
        }

        apiPOSTReq(`${environment.opsBaseUrl}/ops/fetchBusinessDocuments`, headers, { "clientBusinessId": this.props.businessId, rootType: 'External Bank Statement' }, (err, resp) => {
            try {
                const data = resp || {};
                console.log("/getActivePayors", data)
                if (data.result) {
                    this.setState({ customersLoaded: true, businessDocs: data.businessDocuments })
                } else {
                    throw Error(data.error || data.responseMessage || "Could not fetch payers")
                }
            } catch (error) {
                console.log("ERRR getActivePayors", error, err, resp);
                // ErrorAlert({description: error.message})
            }
        })
    }

    // Download Individual Doc
    downloadDoc = (item) => {
        const urlArr = item.documentUrl.uri.split('.');
        var fileExtension = urlArr[urlArr.length - 1];
        var filename = (item.name).includes(fileExtension) ? item.name : item.name + "." + fileExtension;
        getPreSignedS3Url({ url: item.documentUrl.uri }, async (err, preSignedUrl) => {
            try {
                // prevents opening file in new tab
                let blob = await fetch(preSignedUrl).then((r) => r.blob());
                saveAs(blob, filename);

            } catch (err) {
                console.log(err);
            }
        })
    }

    render() {
        const { customize } = this.state
        const { aionStore } = this.props
        var { Profile } = aionStore;
        var { BusinessInfo } = Profile || {};
        BusinessInfo = BusinessInfo || {};

        const columns = [
            {
                title: 'Bank Name',
                dataIndex: 'displayName',
                key: 'displayName',
            },
            {
                title: 'Statement Period',
                render: (item) => { return item.effectiveStartDate == null ? '' : moment(item.effectiveStartDate).format('MMM DD, YYYY') + ' - ' + moment(item.effectiveEndDate).format('MMM DD, YYYY') }
            },
            {
                title: 'Generated on',
                render: (item) => { return moment(item.auditData.createTime).format('MMM DD, YYYY') }
            },
            {
                title: 'File Type',
                render: (item) => (item.documentUrl || {}).uri && (item.documentUrl || {}).uri.includes("pdf") ? "PDF" : "CSV"
            },
            {
                title: 'Action',
                align: 'center',
                // render: (item) => (item.documentUrl || {}).uri ? <SignedLink {...item.documentUrl} /> : ``
                render: (item) =>
                    <Flex center>
                        {
                            (item.documentUrl || {}).uri ?
                                <TextButton margin='0 24px' onClick={() => this.downloadDoc(item)} text="Download" />
                                : ``
                        }
                    </Flex>
            }
        ];

        return (
            <FlexColumn>
                <ConfigProvider renderEmpty={customize && this.customizeRenderEmpty}>
                    <div className="config-provider" >
                        <Skeleton loading={!this.state.customersLoaded} active title={false} paragraph={{ rows: 4 }} className="skeleton-padding">
                            <TableView
                                id="business-doc-table"
                                className="borderless-table row-pointer"
                                tableLayout='auto'
                                columns={columns}
                                dataSource={this.state.businessDocs}
                                rowKey='id'
                                titleText={"Bank Statements"}
                                pagination={false}
                            />
                        </Skeleton>
                    </div>
                </ConfigProvider>

            </FlexColumn>
        );
    }
}

function mapStateToProps(state) {
    return {
        store: state.creditAppReducer,
        aionStore: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)));