import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import { StyledExtLink } from '../Reusable/Link'

import { ErrorAlert } from '../Reusable/Alert'
import { LabeledInput } from '../Reusable/Input'
import { FlexColumn } from '../Reusable/Container'
import { Text } from '../Reusable/Text'
import { Button } from '../Reusable/Button'
import { apiGET, apiPOST } from '../../Utils/api'
import { capitalizeWords } from '../../Utils/util'


class Index extends Component {

    constructor(props) {
        super(props)
        var { Attributes } = props.store
        Attributes = Attributes || {}
        const users = Attributes.Users || {}
        var delivery = users.TwoFactorType ? capitalizeWords(users.TwoFactorType) : "Text"
        if (delivery === "Mail") delivery = "Email"
        this.state = {
            currentViewIndex: 0,
            submitLoading: false,
            stepProgress: 0,
            delivery: props.delivery ? props.delivery : delivery,
            step: 0,
            steps: ["2FA.SendCode", "2FA.Verify"],
        }
    }

    render() {
        const { step, steps, submitLoading, delivery, twoFactorData } = this.state
        const options = ["Text", "Email"]

        return (
            <FlexColumn between left style={{ height: 320, width: '100%' }}>
                {
                    steps[step] === "2FA.SendCode" &&
                    <>
                        <FlexColumn start>
                            <Text size='28px' weight='500'>Two-Factor Authentication</Text>
                            <Text lightText style={{ marginBottom: 24, marginTop: 16 }}>For your security, we need to send you a verification code</Text>
                            {/* <Text lightText style={{ marginBottom: 8 }}>How would you like the code delivered?</Text> */}
                            {!this.props.delivery &&
                            <LabeledInput
                                type='switch'
                                label="How would you like the code delivered?"
                                switchNames={options}
                                onChange={this.handleDeliveryChange}
                                value={delivery} />}
                        </FlexColumn>

                        <Button
                            solid
                            margin='48px 0 0'
                            text='SEND CODE'
                            onClick={() => this.loadNext()}
                            loading={submitLoading} />
                    </>
                }

                {
                    steps[step] === "2FA.Verify" &&
                    // !this.props.delivery &&
                    <>
                        <FlexColumn start fullWidth>
                            <Text size='28px' weight='500'>Enter verification code</Text>
                            <Text style={{ marginBottom: 24, marginTop: 16 }}>{twoFactorData.Message}</Text>
                            <LabeledInput
                                autoFocus
                                // labelcolor={this.props.theme.colors.secondary3}
                                label="Code"
                                name="code"
                                key="code"
                                placeholder="XXXXXX"
                                instruction='The code expires in 5 minutes'
                                onChange={this.handleTextChange}
                                onKeyDown={this.handleOnKeyDown}
                                maxLength={6}
                            />
                            <StyledExtLink key="StyledExtLink" onClick={() => this.setState({ step: 0 })}>Request another code</StyledExtLink>
                        </FlexColumn>

                        <Button
                            solid
                            margin='48px 0 0'
                            text='CONTINUE'
                            onClick={() => this.loadNext()}
                            loading={submitLoading} />
                    </>
                }
            </FlexColumn>
        )
    }

    handleOnChange = (event) => {
        const target = event.target
        this.saveUserInput(target.id || target.name, target.value)
        this.loadNext()
    }

    handleTextChange = (event) => {
        const target = event.target
        this.saveUserInput(target.id || target.name, target.value)
    }

    handleDeliveryChange = (delivery) => {
        this.setState({ delivery })
    }

    handleOnKeyDown = (event) => { if (event.key === "Enter") this.loadNext() }

    // Validate input and go to next view if it's good
    loadNext = () => {
        const { step, steps, code } = this.state
        var { delivery } = this.state
        const validation = this.validateFlow()
        if (validation.validated) {
            // Save the data and load next
            switch (steps[step]) {
                case "2FA.SendCode":
                    this.setState({ submitLoading: true })
                    if (delivery === "Email") delivery = "Mail"
                    console.log("delivery", delivery)
                    apiGET(`/mfa/generate?delivery=${delivery.toUpperCase()}`, null, (err, resp) => {
                        try {
                            if (err) throw Error(err)
                            var data = resp.data
                            console.log('twoFactorData', data)
                            if (!data.success) throw Error("")
                            this.setState({ submitLoading: false, twoFactorData: data, step: 1 })
                        } catch (error) {
                            console.log("2FA.SendCode", error, err, resp)
                            ErrorAlert({ description: "Sorry we had trouble processing your request. Please try again." })
                            this.setState({ submitLoading: false })
                        }
                    })
                    return
                case "2FA.Verify":
                    var endpoint = "/mfa/verify"
                    if (this.props.type) endpoint = `${endpoint}?type=${this.props.type}`
                    let body = {
                        "token": code
                    }
                    this.setState({ submitLoading: true })
                    apiPOST(endpoint, null, body, (err, resp) => {
                        try {
                            if (err) throw Error(err)
                            var data = resp.data
                            if (!data.success) throw Error("")
                            if (data.TokenValidated) {
                                this.props.validationComplete()
                            } else {
                                this.setState({ submitLoading: false })
                                ErrorAlert({ description: "Invalid verification code. Please enter the correct code or request a new one." })
                            }
                        } catch (error) {
                            this.setState({ submitLoading: false })
                            ErrorAlert({ description: "Sorry we had trouble processing your request. Please try again." })
                        }
                    })
                    return
                default:
                    break
            }
        } else {
            ErrorAlert({ description: validation.message })
        }
    }

    saveUserInput = (id, dataToSave) => {
        this.setState({ [id]: dataToSave })
    }

    validateFlow = () => {
        var { step, steps, code } = this.state

        if (steps[step] === "2FA.Verify" && !code) {
            return { validated: false, message: "Enter a valid code" }
        } else {
            return { validated: true }
        }
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer,
        bankingStore: state.bankingAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)))