import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';

import { Affix, Layout } from 'antd';

import { LeftOutlined } from '@ant-design/icons';

// Components
import { TextButton } from '../../../Reusable/Button';
import { Flex, FlexColumn, Container } from '../../../Reusable/Container';
import { ErrorAlert } from '../../../Reusable/Alert';
import PageHeader from '../../../Reusable/PageHeader';
import environment from '../../../../environment';
import { apiPOSTReq } from '../../../../Utils/api';
import RescheduleBill from '../RescheduleBill';

const { Content } = Layout;


class Index extends Component {

    render() {
        const { theme, bankingStore } = this.props
        return (
            <FlexColumn className='main-padding' style={{ width: '100%' }} start>
                <PageHeader
                    back
                    backText='Bills'
                    // titleText={<>Reschedule your bill from <span style={{ color: theme.colors.primary }}>{vendor.vendorName}</span></>}
                />
                {
                    <Container shadow style={{ marginBottom: 48 }}>
                        <RescheduleBill bill={this.props.location.state.bill || {}} onPay={() => this.props.history.push('/payables/bills')} />
                    </Container>
                }
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        bkStore: state.bookkeepingAppReducer,
        store: state.aionAppReducer,
        bankingStore: state.bankingAppReducer,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Index))