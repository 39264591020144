import React, { Component } from 'react';
import moment from 'moment';
import _ from 'lodash';
import {
    ConfigProvider,
    Empty,
    Skeleton,
    Typography,
    Tag,
    Select,
    Table,
    Popconfirm,
    Input,
    InputNumber
} from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

// Utils
import { getCustomerName, getAddressObj } from './../../../Utils/util';
import { SYSTEM_COLORS, SYSTEM_STYLES } from '../../../Utils/stylingAssets'

import LocationSearchInput from '../../Reusable/LocationSearchInput'

const { Title } = Typography;
const { Option } = Select;

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const customizeRenderEmpty = () => (
    <Empty
        imageStyle={{
            height: 60,
        }}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={
            <span>
                No Customers
            </span>
        }>
    </Empty>
)

// Convert FormattedAddress to string for displaying
const formattedAddressToStr = (addr) => {
    if (addr)
        return addr.line1 ? `${addr.line1 || ""}, ${addr.line2 && addr.line2 + ',' || ""} ${addr.city || ""}, ${addr.countrySubDivisionCode || ""} ${addr.postalCode || ""}` : '';
    return '';
}

// Convert FormattedAddress to object with line1 and line2 strings for displaying inside the LocationSearchInput comp.
const getAddressValueObj = (addr) => {
    if (addr)
        return { line1: formattedAddressToStr(_.omit(addr, 'Line2')), line2: addr.Line2 }
    else
        return null;
}

const EditableContext = React.createContext();

class EditableCell extends React.Component {

    getInput = () => {
        if (this.props.dataIndex === 'Revenue') {
            return <InputNumber placeholder="Enter" />;
        }
        else if (this.props.dataIndex === 'FormattedAddress') {
            return <LocationSearchInput Line2 bordered={true} getLocation={() => { }} />
        }
        else if (this.props.inputtype === 'select') {
            const options = [];
            (this.props.selectoptions).forEach(option => {
                options.push(<Option key={option} value={option} title={option}>{option}</Option>);
            });
            return <Select
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Select"
            >
                {options}
            </Select>
        }
        return <Input />;
    };

    renderCell = ({ getFieldDecorator }) => {
        const {
            editing,
            dataIndex,
            record,
            children,
            ...restProps
        } = this.props;

        let message = 'Required';
        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            rules: [{
                                required: true,
                                message: message
                            }],
                            initialValue: dataIndex == 'FormattedAddress' ? getAddressValueObj(record[dataIndex]) : record[dataIndex],
                            //initialValue: record[dataIndex],
                        })(this.getInput())}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    render() {
        return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
    }
}

class EditCustomersTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            customerList: this.props.customerList,
            editingKey: ''
        };

        this.columns = [
            {
                title: 'Company Name',
                key: 'CompanyName',
                dataIndex: 'CompanyName',
                width: 200,
                fixed: 'left',
                render: (field, customer) => getCustomerName(customer)
            },
            {
                title: 'Address',
                key: 'FormattedAddress',
                dataIndex: 'FormattedAddress',
                editable: true,
                width: 300,
                render: FormattedAddress => formattedAddressToStr(FormattedAddress)
            },
            {
                title: 'Payment Method',
                key: 'PaymentModes',
                dataIndex: 'PaymentModes',
                editable: true,
                inputtype: 'select',
                selectoptions: ["ACH", "Wire", "Check"],
                width: 100,
                render: PaymentModes => (
                    (PaymentModes || []).map(mode => <Tag key={mode}>{mode}</Tag>)
                )
            },
            {
                title: 'Invoicing Method',
                key: 'InvoiceSubmissionMethod',
                dataIndex: 'InvoiceSubmissionMethod',
                editable: true,
                inputtype: 'select',
                selectoptions: ["Email", "Portal upload", "Mail"],
                width: 140,
                render: InvoiceSubmissionMethod => (
                    (InvoiceSubmissionMethod || []).map(method => <Tag key={method}>{method}</Tag>)
                )
            },
            {
                title: 'Est. Annual Revenue',
                key: 'Revenue',
                dataIndex: 'Revenue',
                editable: true,
                // align: 'right',
                className: 'numeric',
                render: Revenue => Revenue && formatter.format(Revenue),
            },
            {
                title: '',
                key: 'Operation',
                dataIndex: 'Operation',
                width: 60,
                fixed: 'right',
                render: (text, record) => {
                    const { editingKey } = this.state;
                    const editable = this.isEditing(record);
                    return editable ? (
                        <span>
                            <EditableContext.Consumer>
                                {form => (
                                    <a onClick={() => this.save(form, record.Id)} style={{ marginRight: 8 }}>Save</a>
                                )}
                            </EditableContext.Consumer>
                            <a onClick={() => this.cancel(record.Id)}>Cancel</a>
                            {/*
                        <Popconfirm title="Sure to cancel?" onConfirm={() => this.cancel(record.Id)}>
                            <a>Cancel</a>
                        </Popconfirm>
                        */}
                        </span>
                    ) : (
                        <a disabled={editingKey !== ''} onClick={() => this.edit(record.Id)}>
                            Edit
                        </a>
                    );
                },
            }
        ]
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props.customerList, prevProps.customerList)) {
            this.setState({ customerList: this.props.customerList });
        }
    }

    isEditing = record => record.Id === this.state.editingKey;

    save = (form, customerId) => {
        form.validateFields((error, row) => {
            if (error) {
                return;
            }
            const newData = this.state.customerList;
            const index = newData.findIndex(item => customerId === item.Id);
            const item = newData[index];
            if (row.FormattedAddress.formData && row.FormattedAddress.formData.address) { // if user entered new line 1 address by selecting from the suggestion list
                row.FormattedAddress = getAddressObj(row.FormattedAddress.formData.address);
            } else {  // use previous address and possible new line2
                row.FormattedAddress = { ...item.FormattedAddress, Line2: row.FormattedAddress.line2 };
            }
            row.ReviewComplete = true;
            newData.splice(index, 1, {
                ...item,
                ...row,
            });
            console.log("row", row)
            const cData = newData.map(d => ({ ...d, name: "" })); // To show react mutation
            this.setState({ customerList: cData, editingKey: '' });
            this.props.onCustomerReviewed(item.Id);
        });
    }

    edit = (Id) => {
        this.setState({ editingKey: Id });
    }

    cancel = () => {
        this.setState({ editingKey: '' });
    };

    render() {

        const components = {
            body: {
                cell: EditableCell
            },
        };

        const columns = this.columns.map(col => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    inputtype: col.inputtype,
                    selectoptions: col.selectoptions,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: this.isEditing(record),
                }),
            };
        });
        console.log("customerList", this.state.customerList)
        return (
            <EditableContext.Provider value={this.props.form}>
                <ConfigProvider renderEmpty={customizeRenderEmpty}>
                    <div className="config-provider">
                        <Skeleton loading={!this.props.customersLoaded} active title={false} paragraph={{ rows: 4 }}>
                            <Table
                                id='edit-customers-table'
                                rowClassName='editable-row'
                                components={components}
                                rowKey='Id'
                                tableLayout='auto'
                                columns={columns}
                                scroll={{ x: 'max-content', y: '100vh' }}
                                dataSource={this.state.customerList}
                            />
                        </Skeleton>
                    </div>
                </ConfigProvider>
            </EditableContext.Provider>
        )
    }
}

export default Form.create()(EditCustomersTable);