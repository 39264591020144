import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import styled, { withTheme } from 'styled-components'
import Lottie from 'react-lottie'
import { Alert, Layout, message, Modal } from 'antd'

// Util
import { apiGET, apiPOST, apiPOSTNoAuth, apiPOSTReq, doSignout } from '../../Utils/api'


// Components
import { Button, TextButton } from '../Reusable/Button'
import { Text } from '../Reusable/Text'
import { Flex, FlexColumn, SideBar, GradientSideBar } from '../Reusable/Container'
import { Divider, LabeledInput } from '../Reusable/Input'
import AionLogo from '../Reusable/Image'
import { ErrorAlert } from '../Reusable/Alert'
import environment from '../../environment'

import _ from 'underscore'
import MobileLanding from '../Reusable/MobileLanding'


class SignIn extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            authParams: {
                responseType: '',
                redirectUri: '',
                scope: [],
                clientId: '',
                state: '',
                userId: '',
                passwd: ''
            } 
        }

        this.handleChange = this.handleChange.bind(this)
        // this.handleSubmit = this.handleSubmit.bind(this)

    }

    componentDidMount() {
        this.fetchConfig()
    }

    fetchConfig = () =>{
        const queryString = this.props.location.search;
        const queryParams = new URLSearchParams(queryString);
        const responseType = queryParams.get('response_type') || '';
        const redirectUri = queryParams.get('redirect_uri') || '';
        const scope = queryParams.get('scope') || '';
        const scopeArray = (scope ? scope.split(' ') : []) || [];
        const clientId = queryParams.get('client_id') || '';
        const state = queryParams.get('state') || '';
        this.setState({ authParams: {responseType, redirectUri, scope: scopeArray, clientId, state} })
    }

    handleChange = (event) => {
        this.setState({ [event.target.id]: event.target.value })
        event.preventDefault()
    }

    setError = (field, msg) => this.setState({ errorField: field, errorMessage: msg })

    handleSubmit = () => {
        const { username, password, authParams } = this.state;
        if (!username) {
            this.setError("username", "Please enter a valid username.");
            return;
        }
        if (!password) {
            this.setError("password", "Please enter a valid password.");
            return;
        }

        const userId = username;
        const passwd = password;
        authParams.userId = userId;
        authParams.passwd = passwd;
        this.setState({ loading: true, authParams: authParams })
        const header = {
        }

        const body = {
            plaidAuthParams: this.state.authParams 
        }
        
        apiPOSTReq(`${environment.bbxBaseUrl}/bbx/authUser`, header, body, (err, resp) => {
            try {
                const response = resp || {};
                if (response.result) {
                    this.setState({ loading: false, redirectUri:response.authCodeRedirectUri })
                    console.log("Response:", this.state.redirectUri)
                    window.location.href = this.state.redirectUri
                }
                else{
                    this.setState({ loading: false })
                    ErrorAlert({description: "Username or Password is wrong. Please try again"})
                }
            }
            catch (error) {
                console.log("ERRR authorizing user", error, err, resp);
            }
        })
    }

    getInputContent = () => {
        const { theme } = this.props;
        const { isTablet, errorField, errorMessage } = this.state
        return (
            <FlexColumn fullWidth>
                    <FlexColumn gap="24px">
                        <Text size="28px" weight="500" height="">Sign In</Text>
                        <FlexColumn left>
                            <LabeledInput
                                autoFocus={!isTablet}
                                label="Email"
                                id="username"
                                key="username"
                                type="email"
                                placeholder="Enter your email registered with Aion"
                                value={this.state.username}
                                onChange={this.handleChange}
                                error={errorField == "username"}
                                errorMessage={errorMessage}
                            />
                            <LabeledInput
                                label="Password"
                                id="password"
                                type="password"
                                key="password"
                                placeholder="Enter your password"
                                value={this.state.password}
                                onChange={this.handleChange}
                                error={errorField == "password"}
                                errorMessage={errorMessage}
                                onKeyDown={(event) => { if (event.key === "Enter") this.handleSubmit() }}
                            />
                            {/* <StyledLink style={{ textAlign: "center", fontSize: "1.0rem", textDecoration: "underline" }} to="/forgot-password">Forgot Password?</StyledLink> */}
                        </FlexColumn>
                    </FlexColumn>
            </FlexColumn>
        )
    }

    getCTA = () => {
        const { loading } = this.state
        return (
            <FlexColumn>
                <FlexColumn left gap="24px">
                    <Button solid permtype="Override" loading={loading} onClick={() => this.handleSubmit()} text={'Sign In'.toUpperCase()} />
                </FlexColumn>
            </FlexColumn>
        )
    }

    render() {
        const { theme } = this.props
        const { isMobile, isTablet, authorizeTab, loading } = this.state

        // For now show mobile landing page for phone users
        // if(isMobile) return <MobileLanding />
        return (
            <>
                <Flex style={{ paddingTop: '80px'  }}>
                    {
                        isMobile || isTablet ?
                            <Flex gap='132px'>
                                <FlexColumn start left gap='48px' style={{ width: "408px", alignSelf: "stretch" }}>
                                    <FlexColumn left style={{ gap: '24px', width: 'inherit' }}>
                                        <AionLogo symbol margin='0px' />
                                        {this.getInputContent()}
                                    </FlexColumn>
                                    {this.getCTA()}
                                </FlexColumn>
                            </Flex>
                            :
                            <Flex gap='132px'>
                                <FlexColumn centerVertically center gap='48px' style={{ width: "408px", alignSelf: "stretch" }}>
                                    <FlexColumn center style={{ gap: '24px', width: 'inherit' }}>
                                        <AionLogo full margin='0px' />
                                        {this.getInputContent()}
                                    </FlexColumn>
                                    {this.getCTA()}
                                </FlexColumn>
                            </Flex>
                    }
                </Flex>  
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(SignIn)))