import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter, useLocation, useHistory } from 'react-router-dom'
import { withTheme } from 'styled-components'
import moment from 'moment'

import { Modal, Tabs, Select, Dropdown, Menu } from 'antd'
import { CardContainer, FlexColumn } from '../../Reusable/Container'
import PageHeader from '../../Reusable/PageHeader'
import { apiPOSTReq } from '../../../Utils/api'
import { getBPAccountsWithAccess, getResourcePerm, toCurrency, getFeaturePerm } from '../../../Utils/util'
import { fetchBBHistory, fetchBBTransactions, normalizeAndSortHistory, fetchBBWires } from '../../../Utils/bankingDataManager'
import { ErrorAlert } from '../../Reusable/Alert'
import { Image } from '../../Reusable/Image'
import { Button, TextButton } from '../../Reusable/Button'
import WireTable from '../Reusable/WireTable'
import { FromAccountOption } from '../../Reusable/Option'
import { addDataToStore, BNK_SAVE_DATA } from '../../../Actions/actions'
import environment from '../../../environment'
import UploadBatchModal from '../../Reusable/UploadBatchModal'
import AccountNameModal from '../Reusable/AccountNameModal'
import ModalClose from '../../../Images/modal-close.png'
import AionIcon from '../../../Images/aion-bank-icon.png'
import ArrowRight from "../../../Images/arrow-right.png"

const { TabPane } = Tabs
const { Option } = Select

const pageSize = 50;

const WireActivity = (props) => {
    const [accounts, setAccounts] = useState([])
    const [subAccounts, setSubAccounts] = useState([])
    const [account, setAccount] = useState()
    const [accountIndex, setAccountIndex] = useState(0)
    const [type, setType] = useState()
    const [paymentStatus, setPaymentStatus] = useState([])
    const [pendingTransfers, setPendingTransfers] = useState([])
    const [postedTransfers, setPostedTransfers] = useState([])
    const [showAccountNameModal, setShowAccountNameModal] = useState(false)
    const [loadingPending, setLoadingPending] = useState(true)
    const [loadingPosted, setLoadingPosted] = useState(true)
    const [moreToLoad, setMoreToLoad] = useState(false)
    const [currentOptions, setCurrentOptions] = useState({})
    const [singleAccount, setSingleAccount] = useState()
    const [back, setBack] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')
    const [achDropdownVisible, setAchDropdownVisible] = useState(false);
    const [wireDropdownVisible, setWireDropdownVisible] = useState(false);
    const [showBatchUploadModal, setShowBatchUploadModal] = useState(false)
    const [showWireBatchUploadModal, setWireShowBatchUploadModal] = useState(false)

    const { store } = props

    const location = useLocation()
    const history = useHistory()

    const manageAccounts = getBPAccountsWithAccess("Payments", { permType: "manage", getActiveAccounts: true })

    const { UAM } = store
    const isAionCustomerSupportUser = UAM.aionCustomerSupportUser
    const batchUploadEnabled = getFeaturePerm("BusinessBanking.Payments.ACHBatchUpload").manage

    const initialStatus = (location.state && location.state.fromErrorBanner) ? ['error', 'Rejected', 'denied_approval'] : null
    const initialFromDate = (location.state && location.state.fromErrorBanner) ? moment().subtract(7, 'days').format("YYYY-MM-DD") : null

    useEffect(() => {
        var Accounts = getBPAccountsWithAccess("Transactions", { getActiveAccounts: true })
        Accounts = Accounts || []
        setSingleAccount(Accounts.length === 1)
        setAccounts(Accounts)

        Accounts.forEach(acc => {
            fetchSubAccounts(acc.accountNumber)
        })

        if (location.state && location.state.account) {
            setAccount(location.state.account)
            setAccountIndex(location.state.accountIndex)
            setType(location.state.type)
            setBack(true)
        } else {
            if (Accounts.length > 0) {
                setAccount(Accounts[0])
            }
        }
    }, [])

    const fetchTransactions = (options) => {
        var { fromDate, toDate, searchStr, fromAmt, toAmt, railList, viewBy, filterType, paymentStatus } = options

        var fetchOptions = {
            pagination: options.pagination, // || pagination
        }

        console.log('fetchTransactions fetchOptions1:', fetchOptions)

        if (searchStr && searchStr != '') fetchOptions.searchStr = searchStr
        if (fromDate) fetchOptions.fromDate = fromDate
        if (toDate) fetchOptions.toDate = toDate
        if (fromAmt) fetchOptions.fromAmt = fromAmt
        if (toAmt) fetchOptions.toAmt = toAmt
        if (railList) fetchOptions.railList = railList
        if (viewBy && searchStr && searchStr != '') fetchOptions.viewBy = viewBy
        if (filterType) fetchOptions.filterType = filterType
        if (paymentStatus) fetchOptions.paymentStatus = paymentStatus

        fetchOptions.bankProvider = "CrossRiverBank"

        setLoadingPosted(true)

        setCurrentOptions(fetchOptions)
        fetchBBWires(fetchOptions, (err, resp) => {
            try {
                if (err) throw new Error(err)
                console.log("fetchData fetchBBTransactions fetchOptions", fetchOptions)
                console.log("fetchData fetchBBTransactions resp", resp)

                const newData = fetchOptions.pagination.current > 1 ? postedTransfers.concat(resp.Transactions) : resp.Transactions

                setMoreToLoad(newData.length !== resp.wireTransfersCount)
                setPostedTransfers(newData)

                if (!searchStr) {
                    props.dispatch(addDataToStore(BNK_SAVE_DATA, resp || {}))
                }
            } catch (error) {
                ErrorAlert({ description: error.message })
            } finally {
                setLoadingPosted(false)
            }
        })
    }

    const loadMoreActivity = () => {
        currentOptions.pagination.current = currentOptions.pagination.current + 1
        fetchTransactions(currentOptions)
    }

    const getAccountOptions = (id) => {
        var Accounts = getBPAccountsWithAccess("", { resourceStr: "BusinessCards.Cards", getActiveAccounts: true }) || []
        Accounts = Accounts.filter(acc => acc.accountType !== "ACCOUNTS_RECEIVABLE")

        console.log("eligibleCardUsers Accounts", Accounts)

        var options = []
        Accounts.forEach(account => {
            options.push(
                <Option key={account.accountId} id={id} value={account.accountId} style={{ backgroundColor: "transparent" }}>
                    <FromAccountOption account={account} />
                </Option>
            )
        })
        return options
    }

    const handleSelectAccount = (value, option) => {
        var account = accounts.find(a => a.accountId === value)
        setAccount(account)
    }

    const fetchSubAccounts = (accountNumber) => {
        apiPOSTReq(`${environment.bbBaseUrl}/bb/getSubAccounts`, { "BankProvider": environment.bankProvider.crb }, { "BankProvider": environment.bankProvider.crb, "AccountNumber": accountNumber, "BusinessId": store.BusinessUniqueKey }, (err, resp) => {
            try {
                console.log("/bb/getSubAccounts", err, resp);
                const data = resp;
                if (data.result) {
                    if (err) throw new Error(err);
                    console.log('getSubAccounts', data.subAccounts)

                    if (data.subAccounts && data.subAccounts.length > 0) {
                        setSubAccounts(subAccounts.concat(data.subAccounts))
                    }
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getSubAccounts", error, err, resp);
            }
        })
    }

    return (
        <FlexColumn start>
            <FlexColumn between style={{ width: '100%', paddingBottom: 24, marginTop: 0 }}>
                <WireTable
                    filter
                    showErrorFilters
                    rail={'Wire'}
                    transactionType
                    download
                    runningBalance
                    viewReturn
                    account={account}
                    accounts={accounts.concat(subAccounts)}
                    accountIndex={0}
                    fetchTransactions={fetchTransactions}
                    moreToLoad={moreToLoad}
                    onLoadMore={() => loadMoreActivity()}
                    padding='6px 0 0'
                    loading={loadingPosted}
                    data={postedTransfers}
                    setSearchTerm={setSearchTerm}
                    sharedSearchTerm={searchTerm}
                    showAccount={accounts.length > 1}
                    ctaContent={(isAionCustomerSupportUser || batchUploadEnabled) ?
                        <Dropdown
                            trigger={['click']}
                            placement="bottomRight"
                            overlay={<Menu style={{ padding: 24, borderRadius: 8 }}>
                                <FlexColumn gap='18px'>
                                    <TextButton rightIcon={<Image src={ArrowRight} />} onClick={() => {
                                        setWireDropdownVisible(false)
                                        props.history.push({
                                            pathname: '/banking/ach-transfers/transfer-to-recipient',
                                            state: { rail: 'Wire' }
                                        })
                                    }} text={"Wire Transfer".toLocaleUpperCase()} />
                                    <TextButton rightIcon={<Image src={ArrowRight} />} onClick={() => {
                                        setWireDropdownVisible(false)
                                        setWireShowBatchUploadModal(true)
                                    }} text={"Batch Upload".toLocaleUpperCase()} />
                                </FlexColumn>
                            </Menu>}
                            onVisibleChange={flag => setWireDropdownVisible(flag)}
                            visible={wireDropdownVisible}
                        >
                            <Button permtype={manageAccounts.length > 0 ? "Override" : "BusinessBanking.Payments"} solid text='TRANSFER' dropdown />
                        </Dropdown>
                        :
                        <Button permtype={manageAccounts.length > 0 ? "Override" : "BusinessBanking.Payments"} solid text='TRANSFER' onClick={() => history.push({
                            pathname: '/banking/wire-transfers/transfer-to-recipient',
                            state: { rail: 'Wire' }
                        })} />}
                    initialStatus={initialStatus}
                    initialFromDate={initialFromDate}
                />
            </FlexColumn>

            <Modal
                wrapClassName='account-name-modal'
                visible={showAccountNameModal}
                footer={null}
                closable={false}
                maskClosable={true}
                width={776}
                destroyOnClose={true}
                onCancel={() => setShowAccountNameModal(false)}
                closeIcon={<img width='24px' height='24px' src={ModalClose} />}
            >
                <AccountNameModal onCancel={() => setShowAccountNameModal(false)} />
            </Modal>

            <UploadBatchModal
                rail='wire'
                visible={showWireBatchUploadModal}
                onCancel={() => setWireShowBatchUploadModal(false)}
                submitComplete={() => {
                    setWireShowBatchUploadModal(false)
                    setLoadingPosted(true)
                    setTimeout(() => fetchTransactions({ pagination: { pageSize: pageSize, current: 1 } }), 1000)
                }}
            />
        </FlexColumn>
    )
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer,
        bankingStore: state.bankingAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(WireActivity)))