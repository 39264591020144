import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { withTheme } from 'styled-components'
import {
    ConfigProvider,
    Empty,
    Skeleton,
    Popover,
    Table,
    Modal,
    Space
} from 'antd'
import {
    InfoCircleOutlined,
    EditOutlined
} from '@ant-design/icons'

// Actions
import { BOOKKEEPING_SAVE_DATA, addDataToStore } from '../../../Actions/actions'

// Components
import environment from '../../../environment'
import { apiPOSTReq } from '../../../Utils/api'
import PageHeader from "../../Reusable/PageHeader"
import { FlexColumn, CardContainer } from '../../Reusable/Container'
import { Text, Tag } from '../../Reusable/Text'
import { ErrorAlert } from '../../Reusable/Alert'
import SaveCOA from './SaveCOA'
import { Button } from '../../Reusable/Button'
import { getResourcePerm, getSourceDisplayText } from '../../../Utils/util'
import TableView from '../../Reusable/TableView'

class Index extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            customize: true,
            pagination: {
                current: 1,
                pageSize: 100
            },
        }
    }

    componentDidMount() {
        this.fetchCOA({ pagination: this.state.pagination })
    }

    fetchCOA = (options) => {
        const { pagination } = options
        let { Profile } = this.props.aionStore

        const headers = {
            AionCurrentBiz: this.props.aionStore.BusinessUniqueKey,
            AionAuth: this.props.aionStore.UAM.encryptedAuthHeader
        }

        const body = {
            "BusinessId": this.props.aionStore.BusinessUniqueKey,
            "size": pagination.pageSize,
            "page": pagination.current - 1,
            "ActiveRecords": true,
            "sortFieldName": "Code",
            "sortDirection": "ASC"
        }
        this.setState({ loading: true })

        apiPOSTReq(`${environment.bbBaseUrl}/bk/getQuickBookAndCustomCOAs`, headers, body, (err, resp) => {
            try {
                const data = resp
                console.log('/bk/getQuickBookAndCustomCOAs', data)
                if (data.result) {
                    this.props.dispatch(addDataToStore(BOOKKEEPING_SAVE_DATA, { COA: (data.systemCOAList || []).concat(data.customCOAList) }))
                    this.setState({
                        loading: false,
                        pagination: {
                            ...pagination,
                            total: data.count
                        }
                    })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getActiveCustomers", error.stack, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }


    handleTableChange = (pagination, filters, sorter) => {
        this.fetchCOA({ pagination: pagination })
    }

    render() {
        const { customize, pagination, loading } = this.state
        const { bkStore, theme, aionStore } = this.props
        const { connection } = aionStore
        
        var coa = (bkStore.COA || []).sort((a, b) => {
            return a.code > b.code
        })

        const isCodatUser = connection && connection.status !== "PendingAuth"

        coa = coa.filter(item => item !== null);

        var columns = [
            {
                title: (
                    <Popover title="Account Number" content="Account number refers to the category an individual account belongs to. Example: 50040 for Business Meals Expense.">
                        <span>Account # </span>
                        <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                    </Popover>
                ),
                dataIndex: 'code',
                key: 'code',
                width: '50px'
            },
            {
                title: "Name",
                dataIndex: 'categoryName',
                key: 'categoryName',
                textWrap: 'word-break',
                render: (item, record) => (
                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                        <FlexColumn>
                            <Text>{record?.categoryName} <Tag style={{ margin: "5px " }} tagpreset={theme.colors.secondary4}>{getSourceDisplayText(record?.createdSource, aionStore)}</Tag>
                            </Text>
                            {(record?.description || "").length > 0 ? <Text color={theme.colors.systemGray} style={{ fontSize: "0.9rem" }}>{record?.description} </Text> : null}
                        </FlexColumn>
                    </div>
                ),
                width: "300px"
            },
            {
                title: 'Type',
                dataIndex: 'coatype',
                key: 'coatype',
                render: (item) => item === "COGS" ? "Cost of Goods Sold" : item,
                width: '100px'
            },
            {
                title: 'Action',
                key: 'action',
                align: 'center',
                render: (text, record) => (
                    <Space size="middle">
                        {!getResourcePerm("Support.Console").aionSystemUser &&
                            <a onClick={() => this.setState({ showSaveCOAModal: true, editCOA: record })}><EditOutlined style={{ fontSize: 18 }} /></a>}
                    </Space>
                ),
                width: '100px'
            }
        ]

        return (
            <>
                <FlexColumn className='main-padding'>
                    <PageHeader
                        titleText="Chart of Accounts"
                        desc="List of all your Chart of Accounts"
                        back
                        
                    />
                    <Skeleton loading={this.state.loading} active title={false} paragraph={{ rows: 4 }} className="skeleton-padding">
                        <TableView
                            id="customer-table"
                            titleText='Accounts'
                            descText="List of all your Chart of Accounts"
                            // tableLayout='auto'
                            columns={columns}
                            dataSource={coa}
                            style={{ minHeight: 500, minWidth: 922 }}
                            rowKey='id'
                            pagination={pagination}
                            onChange={this.handleTableChange}
                            loading={loading}
                            ctaContent={!isCodatUser && <Button solid primary onClick={() => this.setState({ showSaveCOAModal: true })} text='Create Account' />}
                        />
                    </Skeleton>
                </FlexColumn>
                <Modal
                    visible={this.state.showSaveCOAModal}
                    footer={null}
                    closable={true}
                    width={700}
                    style={{ top: 20 }}
                    destroyOnClose={true}
                    onCancel={() => { this.setState({ showSaveCOAModal: false, editCOA: null }) }}
                >
                    <SaveCOA
                        editCOA={this.state.editCOA}
                        coaSaved={
                            () => {
                                this.setState({ showSaveCOAModal: false, editCOA: null })
                                this.fetchCOA({ pagination: this.state.pagination })
                            }
                        }
                    />
                </Modal>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        bkStore: state.bookkeepingAppReducer,
        userDefaults: state.userDefaultsReducer,
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)))