import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import { Divider, Affix, message } from 'antd'
import moment from 'moment'
import { saveAs } from 'file-saver'
import { Flex, FlexColumn, CardContainer } from '../../Reusable/Container'
import PageHeader from '../../Reusable/PageHeader'
import { Text, Tag } from '../../Reusable/Text'
import { LabeledInput } from '../../Reusable/Input'
import { Button, TextButton } from '../../Reusable/Button'
import { Image } from '../../Reusable/Image'
import AlertModal from '../../Reusable/AlertModal'
import { ErrorAlert } from '../../Reusable/Alert'
import { Editor, EditorState } from 'draft-js'
import 'draft-js/dist/Draft.css'
import { stateFromHTML } from 'draft-js-import-html'
import { stateToHTML } from 'draft-js-export-html'
import renderHTML from 'react-render-html'
import { LoadingOutlined } from '@ant-design/icons';

import { defaultPaymentTermObjects, toNumber, getFilenameFromUrl, isEqual } from '../../../Utils/util'
import { apiPOSTReq, apiGETDocUrl } from '../../../Utils/api'
import environment from '../../../environment'

import Refresh from '../../../Images/refresh.svg'
import PayerMsg from '../../../Images/payer-message-sent.svg'
import PayerUpload from '../../../Images/payer-upload.svg'

class SendPaymentNotification extends Component {

    constructor(props) {
        super(props)

        this.state = {
            customers: this.props.location?.state?.customers,
            subject: 'Invoice payment change instructions',
        }
    }

    componentDidMount() {
        this.preview()
        this.fetchBusinessData()

        var { businessName } = this.props.aionStore
        this.setState({ fromName: businessName })
    }

    preview = () => {
        const { customers } = this.state
        this.setState({ loadingPreviewEmail: true })

        var body = {
            CustomerId: customers[0]?.CustomerId,
            ToAddr: customers[0]?.ToAddr,
            // "ccAddr": 'bholland@aionfi.com',
        }

        console.log("SendPaymentNotification previewMultiplePaymentConfirmation body: ", body)

        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/previewMultiplePaymentConfirmation`, null, body, (err, resp) => {
            try {
                const data = resp || {};

                console.log("SendPaymentNotification previewMultiplePaymentConfirmation: ", data)
                if (data.result) {
                    this.setState({
                        mainHTML: data.htmlMailtext,
                        // customHTMLBody: data.invoiceEmailCustomBody,
                        // previewPaymentConfirmationEmail: true,
                        // loadingPreviewEmail: false,
                        // editorState: EditorState.createWithContent(stateFromHTML(data.invoiceEmailCustomBody))
                    })
                } else {
                    throw Error(data.error || data.responseMessage || "Could not fetch payers")
                }
            } catch (error) {
                console.log("ERRR previewMultiplePaymentConfirmation", error, err, resp)
            }
        })
    }

    downloadAccountVerificationPDF = (accountId) => {
        this.setState({ loadingAccountVerificationPDF: true })
        var query = {
            AccountId: accountId
        }
        apiGETDocUrl(`${environment.bbBaseUrl}/bb/export/pdf/accountVerificationTemplate`, {}, query, (err, resp) => {
            this.setState({ loadingAccountVerificationPDF: false })
            const data = (resp || {}).data
            try {
                if (err) throw Error("We had trouble downloading your account verification. Please try again.")
                const blob = new Blob([data], {
                    type: 'application/pdf',
                })
                const fileURL = URL.createObjectURL(blob)
                saveAs(fileURL, `Bank Account Letter ${moment().format('MMDDYY')}`)
            } catch (error) {
                ErrorAlert({ description: error.message })
            }
        })
    }

    fetchBusinessData = (options) => {
        const { aionStore } = this.props;
        var body = {
            "clientBusinessId": aionStore.BusinessUniqueKey
        };
        console.log("/ive/invoice/getBusinessData body", body);
        this.setState({ loading: true });
        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/getBusinessData`, null, body, (err, resp) => {
            this.setState({ loading: false });
            try {
                const data = resp || {};
                console.log("/ive/invoice/getBusinessData data", data);
                if (data.result) {
                    const arcommunicationInfo = ((data.business || {}).businessProfile || {}).arcommunicationInfo
                    const bbAccounts = ((data.business || {}).businessBanking || {}).accounts
                    if (arcommunicationInfo && arcommunicationInfo.cc) {
                        var ccEmails = [arcommunicationInfo.cc]
                        this.setState({ ccEmails })
                    }

                    if (bbAccounts) {
                        const arAccount = bbAccounts.find(account => (account.accountType == 'ACCOUNTS_RECEIVABLE'));
                        this.setState({ arAccount: arAccount })
                    }
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    send = () => {
        var { fromName, subject, customers } = this.state;
        this.setState({ loadingSendEmail: true })

        var body = {
            FromName: fromName,
            Subject: subject,
            PaymentConfirmationRequest: customers,
        }

        console.log("sendMultiplePaymentConfirmations: ", body)
        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/sendMultiplePaymentConfirmations`, null, body, (err, resp) => {
            try {
                const data = resp || {}
                console.log("/sendMultiplePaymentConfirmations", data)
                if (data.result) {
                    // message.success(`Payment instructions sent!`)
                    this.setState({ showSentModal: true })
                } else {
                    message.error(`Error sending payment instructions!`)
                    throw Error(data.error || data.responseMessage || "Could not send payment confirmation email")
                }
            } catch (error) {
                console.log("ERRR sendMultiplePaymentConfirmations", error, err, resp)
                ErrorAlert({ description: error.message })
            } finally {
                this.setState({ loadingSendEmail: false })
            }
        })
    }

    render() {
        const { theme } = this.props
        const { subject, customers, errorField, showSentModal, loadingSendEmail, loadingAccountVerificationPDF, mainHTML, arAccount } = this.state


        return (
            <FlexColumn start className='main-padding' fullWidth fullHeight style={{ paddingBottom: 24 }}>
                <PageHeader
                    titleText={`Send Payment Update Email`}
                    marginBottom={24}
                    close
                    fullpage
                />

                <Flex between fullWidth style={{ marginBottom: 48 }} gap='24px'>
                    <CardContainer style={{ marginTop: 24, minWidth: 936, marginBottom: 48 }} grow center>
                        <FlexColumn fullWidth>
                            <Text heading margin='0 0 4px'>Email preview</Text>
                            <Text>This email will be sent to the customer’s contact email that was previously displayed</Text>

                            <Flex start centerHorizontally gap='8px' style={{ marginTop: 24 }}>
                                <Text caption weight='400'>Subject:</Text>

                                <LabeledInput
                                    inputRef={this.subjectRef}
                                    nomargin
                                    small
                                    id="subject"
                                    key="subject"
                                    placeholder="Enter subject"
                                    onChange={(event) => this.setState({ subject: event.target.value })}
                                    value={subject}
                                    error={errorField === "subject"}
                                    errorMessage={"This is a mandatory field"}
                                />
                            </Flex>

                            <Flex start centerHorizontally gap='8px' style={{ marginTop: 24 }}>
                                <Text caption weight='400' size='14px'>Attachment:</Text>
                                <Tag primary noCap radius='24px' loading={loadingAccountVerificationPDF} onClick={() => this.downloadAccountVerificationPDF(arAccount.accountId)}>{loadingAccountVerificationPDF && <LoadingOutlined style={{ color: theme.colors.primary, fontSize: 16, marginRight: 8 }} />}Bank account letter.pdf</Tag>
                            </Flex>

                            <Divider margin='24px 0' color='#F2F2F2' />

                            <Flex fullWidth center>
                                <Flex style={{ width: 600 }} center>
                                    {mainHTML && renderHTML(mainHTML)}
                                </Flex>
                            </Flex>
                        </FlexColumn>
                    </CardContainer>

                    <Affix offsetTop={0}>
                        <FlexColumn start gap='24px' style={{ marginTop: 24 }}>
                            <CardContainer width='296px'>
                                <FlexColumn start gap='48px'>
                                    <Flex between bottom>
                                        <LabeledInput
                                            label='Sending to'
                                            type='read-only'
                                            nomargin
                                            value={`${customers.length} customer${customers.length > 1 ? 's' : ''}`}
                                        />

                                        <TextButton text='Reselect' underline weight='400' onClick={() => window.history.back()} rightIcon={<Image src={Refresh} />} />
                                    </Flex>

                                    <Button solid text='Send' onClick={this.send} loading={loadingSendEmail} />
                                </FlexColumn>
                            </CardContainer>

                            <CardContainer width='296px'>
                                <FlexColumn start gap='24px' center>
                                    <Image width='80px' height='80px' src={PayerUpload} />

                                    <Text weight='600' center>Payment Update Proofs</Text>
                                    <Text center>Don’t think your customer will review this email? Once you’ve sent this initial notification we offer an ability to upload a PDF, screenshot or email indicating that your customer will make future payments into your Aion receivables bank account</Text>
                                </FlexColumn>
                            </CardContainer>
                        </FlexColumn>
                    </Affix>
                </Flex>

                <AlertModal
                    visible={showSentModal}
                    title={`Email${(customers || []).length > 1 ? 's' : ''} sent`}
                    description={`${customers?.length} customer${(customers || []).length > 1 ? 's' : ''} ${(customers || []).length > 1 ? 'were' : 'was'} sent a request to update their payment details`}
                    buttonTitle='Okay'
                    onConfirm={() => window.history.back()}
                    imgSrc={PayerMsg}
                    imgHeight='80px'
                    imgWidth='80px'
                />
            </FlexColumn >
        )
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer,
        receivablesStore: state.receivablesAppReducer,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(SendPaymentNotification)))