import React, { Component } from 'react'
import { saveAs } from 'file-saver'
import { Spin } from 'antd'
import { TextButton } from './Button'
import { Flex, FlexColumn, Container } from './Container'
import { Document, Page, pdfjs } from 'react-pdf'
import { LeftOutlined, RightOutlined, DownloadOutlined } from '@ant-design/icons'
import { withTheme } from 'styled-components'
import hexToRgba from 'hex-to-rgba'

class PDFDocument extends Component {

    constructor(props) {
        super(props)
        this.state = {
            pageNumber: 1,
        }
    }

    onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
        this.setState({ numPages: nextNumPages })
    }

    previousPage = () => {
        const { pageNumber } = this.state
        this.setState({ pageNumber: pageNumber - 1 })
    }

    nextPage = () => {
        const { pageNumber } = this.state
        this.setState({ pageNumber: pageNumber + 1 })
    }

    render() {
        const { width, height, url, fileName, download, theme } = this.props
        const { pageNumber, numPages } = this.state

        return (
            <Container radius='2px' padding='20px' style={{ width: width && width + 48, borderRadius: '8px', border: `1px solid ${hexToRgba(theme.colors.border, 1)}` }} >
                {/* , height: height && height + 100 }}> */}
                <FlexColumn start>
                    <Document
                        file={url}
                        onLoadSuccess={this.onDocumentLoadSuccess}
                        onLoadError={error => {

                        }}
                        loading={<Flex center><Spin size="large" /></Flex>}
                    >
                        <Page
                            key={`page_${pageNumber}`}
                            pageNumber={pageNumber}
                            width={width && width}
                            height={height && height}
                            loading=""
                            onLoadSuccess={() => this.setState({ documentLoaded: true })}
                        />
                    </Document>
                    <Flex start centerHorizontally style={{ width: '100%', marginTop: 12 }}>
                        <Flex start centerHorizontally style={{ width: '50%' }}>
                        {
                            download &&
                            <TextButton onClick={() => saveAs(url, fileName || "bill")} text='Download' icon={<DownloadOutlined style={{ fontSize: 18 }} />} />
                        }
                        </Flex>
                        {
                            numPages > 1 &&
                            <Flex end centerHorizontally style={{ width: '50%' }}>
                                <a style={{ fontSize: 18 }} disabled={pageNumber <= 1} onClick={() => this.previousPage()}><LeftOutlined /></a>
                                <span style={{ fontSize: 16, margin: 8 }}>
                                    Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
                                </span>
                                <a style={{ fontSize: 18 }} disabled={pageNumber >= numPages} onClick={() => this.nextPage()}><RightOutlined /></a>
                            </Flex>
                        }
                    </Flex>
                </FlexColumn>
            </Container>
        )
    }
}

export default withTheme(PDFDocument)