import React, { useEffect } from 'react'
import { Modal } from 'antd'
import { Text } from './Text'
import { Image } from './Image'
import { FlexColumn } from './Container'
import { Button, TextButton } from './Button'
import Exclamation from '../../Images/exclamation.png'
import ModalClose from '../../Images/modal-close.png'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'
import { handleErrorModal } from '../../Actions/actions'

const ErrorModal = (props) => {
    const { store } = props
    var { displayError, errorDescription, errorTitle } = store
    var sessionExpired = false
    useEffect(() => {
        // Always closed when app is loaded
        sessionExpired = false
        onClose()
    }, []);

    const onClose = () => {
        if(sessionExpired) {
            props.dispatch(handleErrorModal({ displayError: false, errorDescription: null, errorTitle: null, sessionRefreshing: true }))
            window.location.href = "/"
        } else {
            props.dispatch(handleErrorModal({ displayError: false, errorDescription: null, errorTitle: null }))
        }
        sessionExpired = false //reset
    }
    
    if(errorDescription == "Invalid Auth Token : Expired JWT") {
        errorTitle = "Login Session Expired"
        errorDescription = "For your safety, your session needs a quick breather. We'll refresh your page and log you back in securely!"
        sessionExpired = true
    }

    return (
        <Modal
            visible={displayError}
            footer={null}
            closable={false}
            width={500}
            destroyOnClose={true}
            // closeIcon={<img width='24px' height='24px' src={ModalClose} />}
            onCancel={onClose}
        >
            <FlexColumn center gap='4px' style={{ padding: '0px 15px' }}>
                <Image margin='24px 0 12px' width='48px' height='48px' src={Exclamation} />
                <Text size='18px' weight='500' height='40px'>{errorTitle || "Uh oh. That didn’t work."}</Text>
                <Text center size='18px' margin='0 0 24px' style={{ maxWidth: '450px' }} >{errorDescription || "Sorry we had trouble processing your request. Please try again later."}</Text>
                <Button
                    solid
                    text='OKAY'
                    onClick={onClose}
                    margin='0 0 24px'
                />
            </FlexColumn>
        </Modal>
    )
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(ErrorModal))