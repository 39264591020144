import React, { Component } from 'react';
import { withTheme } from 'styled-components';

import { FlexColumn } from './Container';
import { Paragraph, Title } from './Text';

class StatView extends Component {
    render() {
        const { name, value, theme } = this.props;
        return (
            <FlexColumn>
                <Paragraph color={theme.colors.systemGray} style={{ fontSize: "0.9rem" }} noMargin>{name}</Paragraph>
                <Title style={{ fontWeight: 600 }} level={5} noMargin>{value}</Title>
            </FlexColumn>
        )
    }
}

export default withTheme(StatView);