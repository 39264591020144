import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import { Modal, Table, Tooltip, Tabs, Dropdown, Menu, message } from 'antd'
import { FlexColumn, CardContainer, Flex } from '../../../Reusable/Container'
import PageHeader from '../../../Reusable/PageHeader'
import { Button, TextButton, ImageButton } from '../../../Reusable/Button'
import { Text } from '../../../Reusable/Text'
import { Image } from '../../../Reusable/Image'
import { Tag } from '../../../Reusable/Text'
import { LabeledInput } from '../../../Reusable/Input'
import environment from '../../../../environment'
import { apiPOSTReq } from '../../../../Utils/api'
import { ErrorAlert } from '../../../Reusable/Alert'
import EditTemplate from './EditTemplate'
import AlertModal from '../../../Reusable/AlertModal'
import moment from 'moment'
import ModalClose from '../../../../Images/modal-close.png'
import Missing from '../../../../Images/missing.png'
import ChevronDown from "../../../../Images/chevron-down.svg"
import More from "../../../../Images/more.png"
import Edit from "../../../../Images/edit.png"
import Add from '../../../../Images/add-white.svg'
import Search from '../../../../Images/search.png'
import Info from '../../../../Images/info.png'
import TableView from '../../../Reusable/TableView'

const { TabPane } = Tabs


class Template extends Component {
    state = {
        loading: true,
        loadingInactive: true,
        pagination: {
            current: 1,
            pageSize: 10,
        },
        inactivePagination: {
            current: 1,
            pageSize: 10,
        },
        tab: 'active',
    }

    componentDidMount() {
        this.fetchData({ pagination: this.state.pagination })
        this.fetchInactiveTemplates({ pagination: this.state.inactivePagination })
    }

    fetchData = (options) => {
        var { pagination, searchTerm } = options
        var body = {
            "size": pagination.pageSize,
            "page": pagination.current - 1,
            "sortDirection": "DESC"
        }
        if (searchTerm) {
            body.searchStr = searchTerm
            body.sortDirection = "DESC"
        }
        this.setState({ loading: true })
        console.log("/ive/templates/getActiveTemplates", body)
        apiPOSTReq(`${environment.iveBaseUrl}/ive/templates/getActiveTemplates`, {}, body, (err, resp) => {
            try {
                const data = resp || {}
                console.log("/ive/templates/getActiveTemplates", data)
                if (data.result) {
                    var templates = pagination.current > 1 ? this.state.templates.concat(data.templates) : data.templates
                    var moreData = templates.length == data.count ? false : true
                    this.setState({
                        loading: false,
                        pagination: {
                            ...pagination,
                            total: data.count
                        },
                        templates: templates,
                        moreToLoad: moreData,
                    })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getActiveTemplates", error, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    fetchInactiveTemplates(options) {
        var { pagination, searchTerm } = options
        var body = {
            "size": pagination.pageSize,
            "page": pagination.current - 1,
            "sortDirection": "DESC"
        }
        if (searchTerm) {
            body.searchStr = searchTerm
            body.sortDirection = "DESC"
        }
        this.setState({ loadingInactive: true })
        apiPOSTReq(`${environment.iveBaseUrl}/ive/templates/getInActiveTemplates`, {}, body, (err, resp) => {
            try {
                const data = resp || {}
                console.log("/ive/templates/getInActiveTemplates", data)
                if (data.result) {
                    var templates = pagination.current > 1 ? this.state.templates.concat(data.templates) : data.templates
                    var moreData = templates.length == data.count ? false : true
                    this.setState({
                        loadingInactive: false,
                        pagination: {
                            ...pagination,
                            total: data.count
                        },
                        inactiveTemplates: data.templates,
                        moreInactiveToLoad: moreData,
                    })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getInActiveTemplates", error, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    createInvoiceTemplate = () => {
        this.setState({ loadingInactive: true })
        apiPOSTReq(`${environment.iveBaseUrl}/ive/templates/createInvoiceTemplate`, {}, { templateType: "INVOICE", templateName: `Template ${(this.state.templates || []).length + 1} ${moment().format('ll')}` }, (err, resp) => {
            this.setState({ loadingInactive: false })
            try {
                const data = resp || {}
                console.log("/ive/templates/createInvoiceTemplate", data)
                if (data.result) {
                    this.setState({
                        newTemplate: true,
                        templateMetadata: data.template,
                        showEditTemplateModal: true
                    })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR createInvoiceTemplate", error, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    makeDefaultTemplate = (template) => {
        this.setState({ loadingAction: template.templateId })
        apiPOSTReq(`${environment.iveBaseUrl}/ive/templates/makeDefault`, {}, { ...template, templateType: "INVOICE" }, (err, resp) => {
            this.setState({ loadingAction: null })
            try {
                const data = resp || {}
                console.log("/ive/templates/makeDefault", data)
                if (data.result) {
                    this.fetchData({ pagination: this.state.inactivePagination })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /ive/templates/makeDefault", error, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    onSearchChange = (e) => {
        const pagination = {
            current: 1,
            pageSize: 10,
        }

        this.setState({ searchTerm: e.target.value })
        this.fetchData({ pagination, searchTerm: e.target.value })
        this.fetchInactiveTemplates({ pagination, searchTerm: e.target.value })
    }

    deactivateTemplate = (templateId) => {
        this.setState({ loadingAction: templateId })
        this.setState({ deactivateLoading: true })
        apiPOSTReq(`${environment.iveBaseUrl}/ive/templates/deactivate`, {}, { templateId: templateId }, (err, resp) => {
            this.setState({ loadingAction: null })
            this.setState({ deactivateLoading: false })
            try {
                const data = resp || {}
                console.log("/ive/templates/deactivate", data)
                if (data.result) {
                    const pagination = {
                        current: 1,
                        pageSize: 10,
                    }

                    this.setState({ tab: 'inactive', showDeactivateModal: false })
                    this.fetchData({ pagination })
                    this.fetchInactiveTemplates({ pagination })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /ive/templates/deactivate", error, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    activateTemplate = (templateId) => {
        const body = {
            templateId
        }
        this.setState({ [`${templateId}ActivateLoading`]: true });
        apiPOSTReq(`${environment.iveBaseUrl}/ive/templates/activate`, {}, body, (err, resp) => {
            try {
                const data = resp;
                console.log("/ive/templates/activate", data)
                if (data.result) {
                    message.success(`Template activated!`);
                    const pagination = {
                        current: 1,
                        pageSize: 10,
                    }

                    this.setState({ tab: 'active' })
                    this.fetchData({ pagination })
                    this.fetchInactiveTemplates({ pagination })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR activateProduct", error, resp);
                ErrorAlert({ description: error.message })
            } finally {
                this.setState({ [`${templateId}ActivateLoading`]: false });
            }
        })
    }

    saveTemporaryTemplate = (template) => {
        var body = {
            "template": template
        }
        this.setState({ loading: true })
        apiPOSTReq(`${environment.iveBaseUrl}/ive/templates/save`, {}, body, (err, resp) => {
            try {
                const data = resp || {}
                console.log("/ive/templates/save", data)
                if (data.result) {
                    this.setState({ showEditTemplateModal: false, newTemplate: false, saveTemporaryTemplate: false })
                    this.fetchData({ pagination: this.state.inactivePagination })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /ive/templates/save", error, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    onLoadMore = () => {
        const { searchTerm, tab, pagination, inactivePagination } = this.state

        if (tab === 'active') {
            var updatedState = {
                loading: true,
                pagination: {
                    current: pagination.current + 1,
                    pageSize: 10
                },
                searchTerm: searchTerm,
            }
            this.fetchData({ ...this.state, ...updatedState })
        } else {
            var updatedState = {
                loading: true,
                pagination: {
                    current: inactivePagination.current + 1,
                    pageSize: 10
                },
                searchTerm: searchTerm,
            }
            this.fetchInactiveTemplates({ ...this.state, ...updatedState })
        }
    }

    render() {
        const { templates, editTemplate, showEditTemplateModal, loading, loadingNew, loadingInactive, templateMetadata, newTemplate, searchTerm, showExitModal,
            saveTemporaryTemplate, tab, inactiveTemplates, moreToLoad, moreInactiveToLoad, showDeactivateModal, templateToDeactivate, deactivateLoading } = this.state
        const { theme } = this.props
        const columns = [
            {
                title: 'Name',
                dataIndex: 'templateName',
                key: 'templateName',
                render: (text, record) => (
                    <Flex start centerHorizontally gap='12px'>
                        {record?.displayName ? record?.displayName : text}
                        {record.default && <Tag>Default</Tag>}
                        {record.customerName && <Tag primary>{record.customerName}</Tag>}
                    </Flex>
                )
            },
            // {
            //     title: 'Customer',
            //     dataIndex: 'customerName',
            //     key: 'customerName',
            //     width: 350
            // },
            {
                // title: 'Action',
                key: 'templateId',
                dataIndex: 'templateId',
                align: 'right',
                render: (templateId, record) => (
                    <Flex fullWidth end centerHorizontally>
                        {
                            tab === 'active' ?
                                <Dropdown
                                    placement='bottomRight'
                                    trigger={['click']}
                                    key={`${record.docNumber}-Dropdown`}
                                    overlay={<Menu style={{ padding: 24, borderRadius: 8, zIndex: 1001 }} key={`${record.docNumber}-Menu`} onClick={() => { }}>
                                        <FlexColumn gap='24px'>
                                            {
                                                !record.customerId && !record.default &&
                                                <TextButton permtype="Receivables.Invoices" onClick={(event) => {
                                                    event.stopPropagation(); // Stop event propagation here as well
                                                    this.makeDefaultTemplate(record)
                                                }} text='MAKE DEFAULT' />
                                            }

                                            <TextButton text='EDIT' onClick={(event) => {
                                                event.stopPropagation(); // Stop event propagation here as well
                                                this.setState({ showEditTemplateModal: true, editTemplate: record })
                                            }} rightIcon={<Image src={Edit} />} />

                                            <TextButton permtype="Receivables.Invoices" text='DEACTIVATE' onClick={(event) => {
                                                event.stopPropagation(); // Stop event propagation here as well
                                                this.setState({ templateToDeactivate: record, showDeactivateModal: true })
                                            }} />
                                        </FlexColumn>
                                    </Menu>}
                                // onVisibleChange={flag => this.setState({ moreDropdownVisible: flag })}
                                // visible={moreDropdownVisible}
                                >
                                    <ImageButton src={More}
                                        onClick={(event) => {
                                            event.stopPropagation(); // Stop event propagation here as well
                                        }} />
                                </Dropdown>
                                :
                                <TextButton
                                    loading={this.state[`${templateId}ActivateLoading`]}
                                    onClick={(event) => {
                                        event.stopPropagation(); // Stop event propagation here as well
                                        this.activateTemplate(templateId)
                                    }}
                                    permtype="Receivables.Invoices"
                                    text='ACTIVATE'
                                />
                        }
                    </Flex>
                ),
            }
        ]
        return (
            <>
                <FlexColumn className='main-padding'>
                    <PageHeader
                        titleText="Receivables"

                    />

                    <Tabs size="large" activeKey={tab} onClick={() => { }} onChange={(key) => this.setState({ tab: key })} style={{ marginBottom: 12 }}>
                        <TabPane tab="Active" key="active" />
                        <TabPane tab="Inactive" key="inactive" />
                    </Tabs>

                    <TableView
                        id="template-table"
                        titleText={
                            <Flex start centerHorizontally>Invoice Templates <Tooltip overlayInnerStyle={{ width: 500, minWidth: 500 }} overlayStyle={{ width: 520, minWidth: 520 }} overlayClassName='roles-tooltip' placement="right" arrowPointAtCenter={false} title={<FlexColumn>
                                <Text color='white' size='14px' noWrap>Create and tailor multiple invoice templates to suit various business use cases</Text>
                            </FlexColumn>}>
                                <FlexColumn>
                                    <Image src={Info} />
                                </FlexColumn>
                            </Tooltip>
                            </Flex>
                        }
                        tableLayout='auto'
                        columns={columns}
                        dataSource={tab === 'active' ? templates : inactiveTemplates}
                        rowKey='templateId'
                        pagination={false}
                        style={{ minHeight: "500px" }}
                        loading={tab === 'active' ? loading : loadingInactive}
                        ctaContent={
                            <Flex gap='24px'>
                                <LabeledInput
                                    id="searchTerm"
                                    key="searchTerm"
                                    placeholder="Search by name"
                                    prefixIcon={<Image width='12px' height='12px' src={Search} />}
                                    onChange={this.onSearchChange}
                                    width='250px'
                                    value={searchTerm}
                                    nomargin
                                />
                                <Button permtype="Receivables.Invoices" loading={loadingNew} solid onClick={this.createInvoiceTemplate} text='ADD TEMPLATE' icon={<Image src={Add} />} />
                            </Flex>
                        }
                        onRow={
                            (record, rowIndex) => {
                                return {
                                    onClick: event => {
                                        this.setState({ showEditTemplateModal: true, editTemplate: record })
                                    }, // click row
                                }
                            }
                        }
                    />

                    {
                        ((tab === 'active' && moreToLoad) || (tab === 'inactive' && moreInactiveToLoad)) &&
                        <Flex fullWidth centerVerticall style={{ marginTop: "10px" }}>
                            <TextButton text='LOAD 10 MORE' loading={loading} onClick={this.onLoadMore} rightIcon={<img style={{ cursor: 'pointer' }} width='24px' height='24px' src={ChevronDown} />} />
                        </Flex>
                    }
                </FlexColumn>

                <AlertModal
                    deactivate
                    visible={showDeactivateModal}
                    loading={deactivateLoading}
                    title={`Deactivate Template?`}
                    description={`Are you sure you want to deactivate ${templateToDeactivate?.templateName}?`}
                    buttonTitle='DEACTIVATE'
                    cancelButtonText='CANCEL'
                    onConfirm={() => this.deactivateTemplate(templateToDeactivate.templateId)}
                    onCancel={() => this.setState({ showDeactivateModal: false })}
                />

                <Modal
                    visible={showEditTemplateModal}
                    footer={null}
                    closable={true}
                    width="100vw"
                    style={{ top: 10, minHeight: "100vh" }}
                    destroyOnClose={true}
                    onCancel={() => {
                        this.setState({ showExitModal: true })
                        // this.fetchData()
                    }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <EditTemplate
                        editing={!templateMetadata}
                        template={newTemplate ? templateMetadata : editTemplate}
                        onSave={() => {
                            this.setState({ showEditTemplateModal: false, newTemplate: false })
                            this.fetchData({ pagination: this.state.inactivePagination })
                        }}
                    />
                </Modal>

                <Modal
                    visible={saveTemporaryTemplate}
                    footer={null}
                    closable={true}
                    width={500}
                    destroyOnClose={true}
                    onCancel={() => {
                        this.setState({ showEditTemplateModal: false, newTemplate: false, saveTemporaryTemplate: false })
                        this.fetchData({ pagination: this.state.inactivePagination })
                    }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <FlexColumn center gap='24px' style={{ marginBottom: '5%' }}>
                        {<img height='48px' width='48px' src={Missing} />}
                        <Text bold size='28px' height='40px' style={{ textAlign: 'center', maxWidth: '800px' }}>Do you want to save the template?</Text>
                        <Flex center style={{ width: "75%" }}>
                            <div>
                                <Flex start centerHorizontally gap='12px'>
                                    <Button solid loading={loading} style={{ alignItems: "left" }} type="primary" text='Yes' onClick={() => this.saveTemporaryTemplate(newTemplate ? templateMetadata : editTemplate)} />
                                    <Button style={{ alignItems: "left" }} type="primary" text='No'
                                        onClick={() => {
                                            this.setState({ showEditTemplateModal: false, newTemplate: false, saveTemporaryTemplate: false })
                                            this.fetchData({ pagination: this.state.inactivePagination })
                                        }}
                                    />
                                </Flex>
                            </div>
                        </Flex>
                    </FlexColumn>
                </Modal>

                <AlertModal
                    close
                    visible={showExitModal}
                    title='Are you sure you want to exit?'
                    description='You will lose your progress if you exit before saving your template.'
                    buttonTitle='EXIT WITHOUT SAVING'
                    cancelButtonText='GO BACK'
                    onConfirm={() => {
                        this.setState({ showExitModal: false, showEditTemplateModal: false,  newTemplate: false })
                        // onClose()
                    }}
                    onCancel={() => this.setState({ showExitModal: false })}
                />
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Template)))