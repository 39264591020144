import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'

import { Divider, Select, Space, Popconfirm, Modal, Switch, message, Upload } from 'antd'
import { Flex, FlexColumn } from '../../Reusable/Container';
import { Text } from '../../Reusable/Text';
import { Button, TextButton } from '../../Reusable/Button';
import { LineItemsHeader, LineItemRow } from '../../Reusable/Container';
import { LabeledInput } from '../../Reusable/Input';
import moment from 'moment';
import { PlusOutlined, DeleteOutlined, CloseOutlined } from '@ant-design/icons'
import { apiPOSTReq } from '../../../Utils/api';
import environment from '../../../environment';
import { ErrorAlert } from '../../Reusable/Alert';
import { Image } from '../../Reusable/Image';
import { at } from 'lodash'
import File from '../../../Images/file.png'

const { Option } = Select
const dFormat = "YYYY-MM-DD";
var businessDocs = [
    { name: "Template" },
    { name: "Attachment" }
];
class ApplicationRequest extends Component {
    state = {
        loading: false,
        applicationRequests: [{
            id: 1, documentType: null, period: null, fromDate: moment().subtract(1, "month").format(dFormat),
            toDate: moment().format(dFormat), message: null, attachmentDocId: null, templateDocId: null, uploadCategory: "PreScreen"
        }],
        requests: [],
        pagination: {
            current: 1,
            pageSize: 100
        },
        valid: false
    }

    constructor(props) {
        super(props)
        this.fetchActiveRequests({ pagination: this.state.pagination });
    }

    componentDidMount() {
        this.fetchDocuments();
    }


    fetchActiveRequests = (options) => {
        const { loanApp } = this.props;
        const { pagination } = options || {};
        var body = {
            "size": pagination.pageSize,
            "page": pagination.current - 1,
            "sortDirection": "DESC"
        };
        if (loanApp) {
            body["BusinessId"] = loanApp.businessId;
            body["applicationId"] = loanApp.applicationId;
            body["uploadCategory"] = "PreScreen"
        }

        console.log("/lsm/getActiveLoanRequest body", body);
        this.setState({ loading: true });
        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/getActiveLoanRequest`, null, body, (err, resp) => {
            console.log("/lsm/getActiveLoanRequest resp", resp);
            this.setState({ loading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    this.setState({ activeRequests: data.requests })
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    disabledDate = (current) => {
        return moment().subtract('1', "years") > current || current > moment();
    }

    handleClose = () => {
        this.props.closeRequest();
    }

    handleSubmit = (append) => {
        var { loanApp, requestType } = this.props;
        const { applicationRequests, activeRequests } = this.state;
        const { UserInfo } = this.props.store
        var username = UserInfo.Email

        if (!this.validateApplicationRequests()) {
            return;
        }

        var requests = [];
        applicationRequests.forEach(req => {
            requests.push({
                type: requestType,
                status: 'InProgress',
                createdBy: username,
                lastUpdatedBy: username,
                documentType: req.documentType,
                period: req.period,
                fromDate: req.fromDate,
                toDate: req.toDate,
                message: req.message,
                parentRequestId: append && activeRequests[0].parentRequestId,
                allowMultipleUpload: req.allowMultipleUpload,
                templateDocId: req.templateDocId,
                attachmentDocId: req.attachmentDocId,
                uploadCategory: req.uploadCategory
            })
        })

        var body = {
            applicationId: loanApp.applicationId,
            BusinessId: loanApp.businessId,
            requests: requests,
            appendRequest: append
        }
        this.setState({ loading: true });
        console.log('Application Requests Body', body);
        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/createLoanRequest`, null, body, (err, resp) => {
            this.setState({ loading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    this.setState({
                        loading: false
                    });

                    this.props.closeRequest();
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/createLoanRequest err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
                this.setState({
                    loading: false
                });
            }
        });


    }

    validateApplicationRequests = () => {
        const { applicationRequests } = this.state;
        this.setState({ errorField: '', errorMessage: '' });
        for (var i = 0; i < applicationRequests.length; i++) {
            let request = applicationRequests[i];
            if (request.documentType == null || request.documentType == '') {
                this.setState({ errorField: i + 'documentType', errorMessage: 'Please select a document type.' });
                return false;
            }
            if (request.period == null || request.period == '') {
                this.setState({ errorField: i + 'period', errorMessage: 'Please select a period.' });
                return false;
            }
            if (request.fromDate == null || request.fromDate == '') {
                this.setState({ errorField: i + 'fromDate', errorMessage: 'Please select a date.' });
                return false;
            }
            if (request.toDate == null || request.toDate == '') {
                this.setState({ errorField: i + 'toDate', errorMessage: 'Please select a date.' });
                return false;
            }
            if (request.message == null || request.message == '') {
                this.setState({ errorField: i + 'message', errorMessage: 'Please enter message.' });
                return false;
            }
        }

        return true;
    }

    handleDateRange = (id, dates) => {
        this.saveRowUserInput(id, 'fromDate', dates[0].format(dFormat));
        this.saveRowUserInput(id, 'toDate', dates[1].format(dFormat));
    }

    saveRowUserInput = (id, key, dataToSave) => {
        var { applicationRequests } = this.state;

        for (var i = 0; i < applicationRequests.length; i++) {
            if (applicationRequests[i].id == id) {
                applicationRequests[i][key] = dataToSave;
            }
        }

        this.setState({ applicationRequests: applicationRequests });
    }

    addLineItemRow = () => {
        var { applicationRequests } = this.state;
        applicationRequests.push({
            id: 0, documentType: null, period: null, fromDate: moment().subtract(1, "month").format(dFormat),
            toDate: moment().format(dFormat), message: null, allowMultipleUpload: false, templateDocId: null, attachmentDocId: null, uploadCategory: "PreScreen"
        })

        applicationRequests.map((lineItem, i) => {
            lineItem.id = i;
        });

        this.setState({ applicationRequests: applicationRequests });
    }

    removeLineItemRow = (id) => {
        var { applicationRequests } = this.state;

        applicationRequests = (applicationRequests || []).filter((item, index) => {
            if (index !== id) return item
        });

        applicationRequests.map((lineItem, i) => {
            lineItem.id = i;
        });

        this.setState({ applicationRequests: applicationRequests });
    }

    validateUpload = (id) => {
        var { applicationRequests } = this.state;
        var request = {};
        for (var i = 0; i < applicationRequests.length; i++) {
            if (applicationRequests[i].id == id) {
                request = applicationRequests[i]

            }
        }
        if (request.documentType == null) {
            ErrorAlert({ description: "Please select document type" });
            return false;
        } else {
            return true;
        }
    }

    uploadProps = (doc, id, documentType, key) => {
        return {
            name: `business-docs`,
            action: `${environment.apiBaseUrl}/file/upload`,
            headers: {
                businesskey: this.props.store.BusinessUniqueKey,
                jwt: this.props.store.jwt
            },
            beforeUpload: (doc) => {
                var { valid } = this.state;
                this.setState({ valid: this.validateUpload(id) || valid })

            },
            showUploadList: false,
            onChange: (info) => {
                var savedFile = {};
                if (this.state.valid) {
                    var file = info.file;
                    var { status, response, name, url } = file;
                    if (status === "done") {
                        savedFile = {
                            fileName: name,
                            uri: (response.UploadedUrls || [])[0],
                        }
                        if (doc.type) savedFile.type = doc.type;
                        // message.success(`${info.file.name} file uploaded successfully.`, 0.75);
                        doc.savedFile = savedFile;
                        this.handleDocSubmit(doc, id, documentType, key);

                    } else if (status === 'error') {
                        message.error(`${info.file.name} file upload failed.`);
                    }
                    if (((Object.keys(savedFile)).length) !== 0) {
                        doc.savedFile = savedFile;
                    }
                    this.setState({ [doc.name]: doc });

                }

            },
            accept: ".pdf, .xlsx",
            onRemove: (file) => {
                this.handleDeleteDoc(doc);
            }
        }
    };
    fetchDocuments = () => {
        const { loanApp } = this.props;
        var body = {
            "sortDirection": "DESC"
        };
        if (loanApp) {
            body["applicationId"] = loanApp.applicationId;
        }
        console.log("/docs/getActiveDocuments body", body, loanApp);
        this.setState({ qbConnectLoading: true });
        apiPOSTReq(`${environment.uamBaseUrl}/docs/getActiveDocuments`, {}, body, (err, resp) => {
            console.log("/docs/getActiveDocuments resp", resp);
            this.setState({ qbConnectLoading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    this.setState({ businessDocuments: data.businessDocuments });

                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    handleDocSubmit = (uploadItem, id, documentType, key) => {
        var { loadingItem } = uploadItem || {};
        var { loanApp } = this.props;
        var businessDocument = {
            "documentUrl": uploadItem.savedFile || {},
            "name": documentType + " - " + uploadItem.name,
            "subType": 'Template',
            "type": "Document",
            "source": 'User',
            "category": "SupportConsole"
        };

        if (loanApp) {
            businessDocument.applicationId = loanApp.applicationId;
            businessDocument.businessId = loanApp.businessId;
        }
        if (uploadItem.effectivePeriod) businessDocument.effectivePeriod = uploadItem.effectivePeriod
        var body = {
            "businessDocument": businessDocument
        }
        console.log("handleSubmit DOC/SAVE uploadItem body", uploadItem, body);
        this.setState({ loadingItem: loadingItem });
        apiPOSTReq(`${environment.uamBaseUrl}/docs/save`, {}, body, (err, resp) => {
            this.setState({ loadingItem: null });
            try {
                const data = resp || {};
                var uploadList = this.state.uploadList;
                if (data.result) {
                    message.success(`File saved successfully.`, 0.75);

                    if (key === "Template") {
                        this.saveRowUserInput(id, "templateDocId", data.businessDocument.documentId);
                    } else {
                        this.saveRowUserInput(id, "attachmentDocId", data.businessDocument.documentId);

                    }
                    this.fetchDocuments();
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/docs/save err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    getDocument = (documentId, options) => {
        options = options || {};
        var { businessDocuments } = this.state;
        var savedDoc = {};
        var filteredDoc = (businessDocuments || []).find(item => ((item.documentId == documentId) && (item.applicationId == this.props.loanApp.applicationId)));
        if (filteredDoc != undefined) {
            // Replace with remotely stored data
            if (options.businessDocument) savedDoc = filteredDoc;
            else savedDoc = filteredDoc.documentUrl || {};
        }
        return savedDoc;
    }


    handleDeleteDoc = (uploadItem, id, key) => {
        var { loanApp } = this.props;
        var businessDocument = {
            "name": uploadItem.name
        };

        if (loanApp) businessDocument.applicationId = loanApp.applicationId;
        var body = {
            "businessDocument": businessDocument,
            "documentId": uploadItem.documentId
        }

        console.log("handleSubmit DOC/delete uploadItem body", body);
        apiPOSTReq(`${environment.uamBaseUrl}/docs/delete`, {}, body, (err, resp) => {
            this.setState({ loadingItem: null });
            try {
                const data = resp || {};
                if (data.result) {
                    message.success(`File Deleted successfully.`, 0.75);
                    this.fetchDocuments();
                    let doc = this.state[uploadItem.name] || {};
                    doc.savedFile = {};
                    this.setState({ [doc.name]: doc });

                    if (key === "Template") {
                        this.saveRowUserInput(id, "templateDocId", null);
                    } else {
                        this.saveRowUserInput(id, "attachmentDocId", null);

                    }
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/docs/deleteUpload err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    render() {
        var { applicationRequests, loading, activeRequests, errorMessage, errorField, showAppendModal, allowMultipleUpload } = this.state;
        applicationRequests = applicationRequests || [];
        activeRequests = activeRequests || [];

        return (
            <FlexColumn style={{ margin: '0 10px', minHeight: "70vh", justifyContent: "space-between" }}>
                <FlexColumn style={{ justifyContent: "flex-start" }}>
                    <Text heading>REQUEST DOCUMENTS</Text>
                    <Divider />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      
                            {/*  <LineItemsHeader>
                            <div style={{ width: "20%", paddingRight: 10 }}><Text color='#444444' weight='600'>Document Type</Text></div>
                            <div style={{ width: "40%", paddingRight: 10 }}><Text color='#444444' weight='600'>Period</Text></div>
<div style={{ width: "30%" }}><Text color='#444444' weight='600'>Multiple Upload</Text></div>
                            <div style={{ width: "25%" }}><Text color='#444444' weight='600'>Template</Text></div>

                            <div style={{ width: "25%" }}><Text color='#444444' weight='600'>Attachement</Text></div>
      
                            <div style={{ width: "15%", paddingLeft: 10 }}><Text color='#444444' weight='600'>Message</Text></div>

                            <div style={{ width: "10%", paddingRight: 10}}><Text color='#444444' weight='600'>Multiple Upload</Text></div>
                            <div style={{ width: "10%" }} />
                        </LineItemsHeader>  */}
                        {
                            applicationRequests.map((request, i) => {
                                var { id, documentType, period, fromDate, toDate, message, allowMultipleUpload, templateDocId, attachmentDocId } = request || {}
                                var itemId = i;
                                return (
                                    <>

                                        <LineItemRow key={itemId + "container"} style={{marginBottom: "24px"}}>
                                            <div style={{ width: "20%", paddingRight: 10 }}>
                                                <LabeledInput
                                                    small
                                                    nomargin
                                                    type="select"
                                                    className="no-left-padding"
                                                    value={documentType}
                                                    label="Document Type"
                                                    placeholder="Select a document"
                                                    style={{ marginRight: 10, fontSize: "1.0rem", paddingLeft: 0, marginLeft: 0 }}
                                                    error={errorField == itemId + "documentType"}
                                                    errorMessage={errorMessage}
                                                    onChange={(value) => { this.saveRowUserInput(id, "documentType", value) }}
                                                >
                                                    <Option id="documentType" value="8821">8821</Option>
                                                    <Option id="documentType" value="Proof of Insurance">Proof of Insurance</Option>
                                                    <Option id="documentType" value="POs">POs</Option>
                                                    <Option id="documentType" value="Inventory">Inventory</Option>
                                                    <Option id="documentType" value="Bank Statements">Bank Statements</Option>
                                                    <Option id="documentType" value="Promissory Note">Promissory Note</Option>
                                                </LabeledInput>
                                            </div>
                                            <div style={{ width: "20%", paddingRight: 10 }}>
                                                <LabeledInput
                                                    small
                                                    nomargin
                                                    type="select"
                                                    className="no-left-padding"
                                                    value={period}
                                                    label="Period"
                                                    placeholder="Select a period"
                                                    style={{ marginRight: 10, fontSize: "1.0rem", paddingLeft: 0, marginLeft: 0 }}
                                                    error={errorField == itemId + "period"}
                                                    errorMessage={errorMessage}
                                                    onChange={(value) => { this.saveRowUserInput(id, "period", value) }}
                                                >
                                                    <Option id="period" value="Date Range">Date Range</Option>
                                                    <Option id="period" value="As of Date">As of Date</Option>
                                                    <Option id="period" value="Not Required">Not Required</Option>
                                                </LabeledInput>
                                                
                                            </div>
                                            <div style={{ width: "20%", paddingRight: 10, marginTop: "9px"}}>
                                            {
                                                    period == 'Date Range' && <LabeledInput
                                                        id="dateRange"
                                                        key="dateRange"
                                                        type="range-picker"
                                                        label=" "
                                                        value={[moment(fromDate), moment(toDate)]}
                                                        format="MM-DD-YYYY"
                                                        onChange={dates => this.handleDateRange(id, dates)}
                                                        style={{ width: "300px", fontSize: "1.1rem", fontWeight: 500,height: '32px' }}
                                                    />
                                                }
                                                {
                                                    period == 'As of Date' && <LabeledInput
                                                        key="asOfDate"
                                                        id="asOfDate"
                                                        type="date-picker"
                                                        format="MM-DD-YYYY"
                                                        label=" "
                                                        error={errorField == itemId + "asOfDate"}
                                                        errorMessage={errorMessage}
                                                        disabledDate={this.disabledDate}
                                                        value={toDate && moment(toDate)}
                                                        style={{ width: "300px", fontSize: "1.1rem", fontWeight: 500, height: '32px' }}
                                                        
                                                        onChange={(date, dateStr) => this.saveRowUserInput(id, "toDate", dateStr)}
                                                    />
                                                }
                                            </div>
                                            <div style={{ width: "20%", paddingLeft: 10 }}>
                                                <LabeledInput
                                                    small
                                                    nomargin
                                                    key="message"
                                                    id="message"
                                                    label="Message"
                                                    value={message}
                                                    error={errorField == itemId + "message"}
                                                    errorMessage={errorMessage}
                                                    placeholder="Enter a message"
                                                    onChange={e => { this.saveRowUserInput(id, "message", e.target.value) }}
                                                />
                                            </div>
                                            <div style={{ width: "10%", paddingRight: 10, marginLeft: 'auto'}}>
                                            
                                                <FlexColumn start centerHorizontally >
                                                        <Text color={'#7384AA'} size='14px' weight={400} style={{marginBottom: "4px"}}>Multiple Upload</Text>
                                                        <Switch style={{marginTop: "10px"}} checked={allowMultipleUpload} onChange={(value) => this.saveRowUserInput(id, "allowMultipleUpload", value)} />
                                                    </FlexColumn>
                                            </div>
                                            <div style={{ width: "10%",  }}>
                                                <Space size="middle" style={{marginTop: "40px"}}>
                                                    {
                                                        <Popconfirm
                                                            title="Are you sure you want to delete?"
                                                            onConfirm={() => this.removeLineItemRow(id)}
                                                            okText="Confirm"
                                                            cancelText="Cancel"
                                                        >
                                                            <TextButton icon={<DeleteOutlined style={{ fontSize: 18 }} />} />
                                                        </Popconfirm>
                                                    }
                                                </Space>
                                            </div>
                                        </LineItemRow>
                                        <LineItemRow key={itemId + "container"} style={{margin: "0px 0px 24px 0px"}}>

                                        <Flex noMargin style={{ justifyContent: "between", width: "100%", borderRadius: "8px", padding: "8px 0px", marginTop: 4 }} gap='24px'>
                                            <FlexColumn wrap style={{width: `${100 / 2}%`, marginRight: "12px"}}>
                                                {templateDocId == null ?
                                                    <LabeledInput
                                                        {...this.uploadProps(businessDocs[0], id, documentType, "Template")}
                                                        type='upload'
                                                        label="Template"
                                                        uploadText='We accept PDFs and XLSX formats'
                                                        width='288px'
                                                    />
                                                    :
                                                    <>
                                                    <Text weight="400" color="#7384AA"
                                                                style={{ fontSize: "14px" }}
                                                                margin='0 0 4px'
                                                            >Template</Text>
                                                        <TextButton inLine breakWord preLine style={{ textAlign: "left", padding: "8px 8px" }} type="link" icon={<Image src={File} />}
                                                            rightIcon={<CloseOutlined onClick={() => this.handleDeleteDoc(this.getDocument(templateDocId, { businessDocument: true }), id, "Template")} />}
                                                            text={this.getDocument(templateDocId).fileName} ></TextButton>

                                                    </>
                                                }
                                            </FlexColumn>

                                            <FlexColumn style={{width: `${100 / 2}%`}}>
                                                {attachmentDocId == null ?
                                                    <LabeledInput
                                                        {...this.uploadProps(businessDocs[1], id, documentType, "Attachment")}
                                                        type='upload'
                                                        label="Attachment"
                                                        uploadText='We accept PDFs and XLSX formats'
                                                        width='288px'
                                                    />
                                                    :
                                                    <>
                                                    <Text weight="400" color="#7384AA"
                                                                style={{ fontSize: "14px" }}
                                                                margin='0 0 4px'
                                                            >Attachment</Text>
                                                        <TextButton  breakWord preLine style={{ textAlign: "left", padding: "8px 8px" }} type="link" icon={<Image src={File} />}
                                                            rightIcon={<CloseOutlined onClick={() => this.handleDeleteDoc(this.getDocument(attachmentDocId, { businessDocument: true }), id, "Attachement")} />}
                                                            text={this.getDocument(attachmentDocId).fileName} ></TextButton>

                                                    </>
                                                }
                                            </FlexColumn>
                                            </Flex>
                                            </LineItemRow>
                                            
                                            <Divider margin='8px 0 12px' color='#8795F2' />

                                    </>
                                )
                            })
                        }
                        <TextButton onClick={() => this.addLineItemRow()} margin="24px 0 24px 0" rightIcon={<PlusOutlined />} text="ADD ROW" />
                    </div>
                </FlexColumn>
                <Flex end centerHorizontally>
                    <Button type="secondary" style={{ alignSelf: 'center', margin: '0 10px' }} onClick={this.handleClose} text="Close" />
                    <Button type="primary" loading={loading} solid style={{ alignSelf: 'center', margin: '0 10px' }} onClick={() => {
                        if (this.validateApplicationRequests())
                            activeRequests.length > 0 ? this.setState({ showAppendModal: true }) : this.handleSubmit(false);
                    }} text="Submit" />
                </Flex>
                <Modal
                    visible={showAppendModal}
                    footer={null}
                    closable={true}
                    width={450}
                    style={{ top: 10 }}
                    destroyOnClose={true}
                    onCancel={() => {
                        this.setState({ showAppendModal: false })
                    }}
                >
                    <FlexColumn style={{ margin: '0 10px', minHeight: "15vh", justifyContent: "space-between" }}>
                        <FlexColumn style={{ justifyContent: "flex-start" }}>
                            <Text >Looks like there is an active request, would you like to create a new request or like to append to the older request?</Text>
                        </FlexColumn>

                        <Flex end centerHorizontally>
                            <Button type="secondary" style={{ alignSelf: 'center', margin: '0 10px' }} onClick={() => { this.setState({ showAppendModal: false }) }} text="Close" />
                            <Button type="primary" loading={loading} solid style={{ alignSelf: 'center', margin: '0 10px' }} onClick={() => {
                                this.handleSubmit(true);
                            }} text="Append" />
                            <Button type="primary" loading={loading} solid style={{ alignSelf: 'center', margin: '0 10px' }} onClick={() => {
                                this.handleSubmit(false);
                            }} text="Create" />
                        </Flex>
                    </FlexColumn>
                </Modal>
            </FlexColumn>
        );
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer,
        bankingStore: state.bankingAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(ApplicationRequest)))