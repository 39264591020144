import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import { Tabs, Table, Modal, Tag, Skeleton, message } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { saveAs } from 'file-saver'

// Components
import PageHeader from '../../Reusable/PageHeader';
import { FlexColumn, Flex, Container } from '../../Reusable/Container';
import environment from '../../../environment';
import { apiPOSTReq } from '../../../Utils/api';
import moment from 'moment';
import { ErrorAlert } from '../../Reusable/Alert';
import { Button, TextButton, ImageButton } from '../../Reusable/Button';
import { LabeledInput } from '../../Reusable/Input';
import ReviewForm from '../Review/ReviewForm';
import { Text } from '../../Reusable/Text';
import { SignedLink } from '../../Reusable/Link';
import { toCurrency } from '../../../Utils/util';
import ModalClose from '../../../Images/modal-close.png'

const { TabPane } = Tabs


const WOStatusTag = (options) => {
    let statusMsg;
    let statusColor = "blue";
    switch (options.status) {
        case "VALIDATED":
            statusColor = "green";
            statusMsg = "Approved"
            break
        case "REJECTED":
            statusColor = "red";
            statusMsg = "Rejected"
            break
        default:
            statusMsg = "Verifying"
            break
    }
    return (<Tag color={statusColor}>{statusMsg}</Tag>);
}

class Index extends Component {

    state = {
        loading: true,
        pagination: {
            current: 1,
            pageSize: 100
        },
        pos: []
    };

    componentDidMount() {
        this.getPayors({ pagination: this.state.pagination, viewBy: 'INREVIEW' })
    }

    handleTableChange = (pagination, filters, sorter) => {
        this.fetchData({ pagination: pagination });
    }

    handleRowSelection = (selectedRowKeys, selectedRows) => {
        console.log("handleRowSelection", selectedRows, selectedRowKeys)
        this.setState({ selectedRows: selectedRows, selectedRowKeys: selectedRowKeys })
    }

    handleApprove = (type) => {
        const { note, itemType, selectedPayer } = this.state;

        this.setState({ approveLoading: true });

        var body = {
            CustomerId: selectedPayer.customerId,
        }

        console.log("/ive/bc/approvePayor body", body)
        apiPOSTReq(`${environment.iveBaseUrl}/ive/bc/approvePayor`, null, body, (err, resp) => {
            try {
                console.log("/ive/bc/approvePayor", err, resp);
                const data = resp || {};
                if (data.result) {
                    message.success(`Payment proof approved updated successfully`);
                    this.getPayors({ pagination: this.state.pagination, viewBy: 'QUALIFIED' })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR ive/bc/approvePayor", error, err, resp);
                ErrorAlert({ description: error.message })
            } finally {
                this.setState({ approveLoading: false, selectedPayer: null, showApprovalModal: false });
            }
        })
    }

    getPayors(options) {
        let { pagination, viewBy } = options
        // Fetch customer list
        const headers = {
            AionCurrentBiz: this.props.aionStore.BusinessUniqueKey,
            AionAuth: this.props.aionStore.UAM.encryptedAuthHeader
        }
        const body = {
            size: pagination.pageSize,
            page: pagination.current - 1,
            viewBy,
        }

        this.setState({ loading: true })
        console.log("Payers getPayors", body)
        apiPOSTReq(`${environment.iveBaseUrl}/ive/bc/getPayors`, headers, body, (err, resp) => {
            try {
                const data = resp
                console.log("Payers getPayors", data)
                if (data.result) {
                    var customers = pagination.current > 1 ? this.state.customers.concat(data.customers) : data.customers
                    var moreCustomers = (customers || []).length == data.count ? false : true
                    this.setState({
                        payers: customers,
                        customerPagination: {
                            ...pagination,
                            total: data.count
                        },
                        moreCustomersToLoad: moreCustomers,
                        tab: viewBy,
                    })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getPayors", error, err, resp)
                ErrorAlert({ description: error.message })
            } finally {
                this.setState({ loading: false })
            }
        })
    }

    onTabChange = (tab) => {
        this.getPayors({ pagination: { current: 1, pageSize: 10 }, viewBy: tab });
    }

    render() {
        const { theme, hidetitle } = this.props;
        const { loading, pos, pagination, selectedRows, selectedRowKeys, showUpdateModal, payers, tab, showApprovalModal, selectedPayer, approveLoading } = this.state;

        const userInfo = this.props.aionStore?.userInfo;

        var columns = [
            {
                title: 'Name',
                dataIndex: 'displayName',
                key: 'displayName',
                render: (displayName, record) => {
                    return <FlexColumn start>
                        <a style={{ textDecoration: "underline" }} onClick={() => this.props.history.push({
                            pathname: '/receivables/customers/details',
                            state: { customer: record }
                        })}>{displayName}</a>
                        <Text>{record.primaryEmailAddress?.address}</Text>
                    </FlexColumn>
                },
            },
            {
                title: 'Payment Proof',
                dataIndex: 'upload',
                key: 'upload',
                align: 'right',
                render: (displayName, record) => {
                    return <SignedLink uri={record?.paymentConfirmationDocument?.url?.uri} fileName={record?.paymentConfirmationDocument?.name} />
                },
            },
            {
                title: 'Uploaded On',
                dataIndex: 'uploadedOn',
                key: 'uploadedOn',
                align: 'right',
                render: (qualifiedOn, record) => record?.verificationStatusDates?.inReviewDate ? moment(record?.verificationStatusDates?.inReviewDate).format('ll') : '--',
            },
        ]

        if (tab === 'INREVIEW') {
            columns = columns.concat([
                {
                    dataIndex: 'approve',
                    key: 'approve',
                    align: 'right',
                    render: (displayName, record) => {
                        return <Flex end><TextButton text='Approve' onClick={() => this.setState({ showApprovalModal: true, selectedPayer: record })} /></Flex>
                    },
                }
            ])
        }

        if (tab === 'QUALIFIED') {
            columns = columns.concat([
                {
                    title: 'Qualified On',
                    dataIndex: 'qualifiedOn',
                    key: 'qualifiedOn',
                    align: 'right',
                    render: (qualifiedOn, record) => record?.verificationStatusDates?.validatedDate ? moment(record?.verificationStatusDates?.validatedDate).format('ll') : '--',
                },
            ])
        }

        return (
            <>
                <FlexColumn style={{ margin: hidetitle ? 0 : `0 40px` }}>
                    {!hidetitle &&
                        <PageHeader
                            titleText="Payment Proof"
                            desc=""
                        />}

                    <FlexColumn>
                        {((selectedRows || []).length > 0) &&
                            <Button
                                permtype="Override"
                                onClick={() => this.setState({ showUpdateModal: true })}
                                style={{ height: 45, width: "250px", margin: "0 10px 10px 0" }}
                                text='Update POs' />
                        }
                    </FlexColumn>
                    <Container shadow style={{ margin: '24px 0' }}>
                        <Flex start style={{ width: '100%' }}>
                            <Tabs size="large" style={{ width: '100%' }} activeKey={tab} onChange={this.onTabChange} >
                                <TabPane tab="Under Review" key="INREVIEW"></TabPane>
                                <TabPane tab="Qualified" key="QUALIFIED"></TabPane>
                            </Tabs>
                        </Flex>

                        <Table
                            id="customer-table"
                            className="borderless-table"
                            tableLayout='auto'
                            columns={columns}
                            dataSource={payers}
                            rowKey='customerId'
                            pagination={false}
                            loading={loading}
                        />
                    </Container>
                </FlexColumn>
                <Modal
                    visible={showUpdateModal}
                    footer={null}
                    closable={true}
                    width={700}
                    style={{ top: 20 }}
                    destroyOnClose={true}
                    onCancel={() => this.setState({ showUpdateModal: false })}
                >
                    <ReviewForm
                        itemType="po"
                        ids={selectedRowKeys}
                        title="Reviewer details"
                        desc={<Text><span style={{ fontWeight: 500 }}>{(selectedRows || []).map(item => item.ponumber).join(", ")}</span> Please add your details to approve/reject POs </Text>}
                        onSuccess={() => {
                            this.setState({ showUpdateModal: false, selectedRowKeys: null, selectedRows: null })
                            this.fetchData({ pagination: this.state.pagination })
                        }}
                    />
                </Modal>

                <Modal
                    visible={showApprovalModal}
                    footer={null}
                    closable={true}
                    width={450}
                    destroyOnClose={true}
                    onCancel={() => {
                        this.setState({ showApprovalModal: false })
                    }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <FlexColumn start>
                        <Text heading>Reviewer details</Text>
                        <Text>Reviewer: {userInfo.firstName} {userInfo.lastName} ({userInfo.email})</Text>
                        <Text>Review date: {moment().format('ll')}</Text>
                        <LabeledInput
                            label="Customer"
                            type='read-only'
                            value={selectedPayer?.displayName}
                        />

                        <Flex start style={{ marginTop: 24 }}>
                            <Button text='Approve' solid loading={approveLoading} onClick={this.handleApprove} />
                        </Flex>
                    </FlexColumn>
                </Modal >
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        bkStore: state.bookkeepingAppReducer,
        aionStore: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Index));