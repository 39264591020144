import React from 'react'
import { Collapse } from 'antd'
import { withTheme } from 'styled-components'

import './PermissionsCollapse.css'

const { Panel } = Collapse

const PermissionsCollapse = (props) => {
    const { roleType, customRoleInfoItems, key } = props

    return (
        <div class="permissions-collapse">
            <Collapse key={`${roleType}-viewPermissions`} accordion bordered={true}>
                <Panel header={"View Role Permissions"} key={key}>
                    {customRoleInfoItems}
                </Panel>
            </Collapse>
        </div>
    )
}

export default withTheme(PermissionsCollapse)