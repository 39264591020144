import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import {
    Select,
    Divider,
    Upload,
    Button,
    Row,
    Col,
    Card,
    Alert,
    message,
    Typography,
    Popconfirm
} from 'antd'
import { CloseCircleOutlined, UploadOutlined } from '@ant-design/icons';

// Components
import StatView from '../../Reusable/StatView';
import environment from '../../../environment';
import { withTheme } from 'styled-components';
import { FlexColumn } from '../../Reusable/Container';
import { Paragraph } from '../../Reusable/Text';

import { getJwt } from '../../../Utils/api'

const { Option } = Select;
const { Dragger } = Upload;
const { Text } = Typography;

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const StatViewCompact = (props) => {
    const { name, value, theme } = props;
    return (
        <FlexColumn style={{ marginTop: 5 }}>
            <Paragraph color={theme.colors.systemGray} style={{ fontSize: "0.9rem" }} noMargin>{name}</Paragraph>
            <Paragraph bold style={{ fontSize: "1.1rem" }} noMargin>{value}</Paragraph>
        </FlexColumn>
    )
}

// Component to render a PO dropdown
const SelectPurchaseOrder = (options) => {

    const handleSelectPO = (value) => {
        options.onSelectPO(value, options.invoice.id);
    }

    options.POs.sort((a, b) => (a.PONumber).localeCompare(b.PONumber));
    const purchaseOrderOptions = options.POs.map(po => {
        return <Option value={po.PONumber} key={po.PONumber}>{po.PONumber}</Option>
    });

    return (
        <Select
            // value={options.invoice.poNumber}
            showSearch
            onChange={handleSelectPO}
            placeholder="Select PO"
            style={{ minWidth:"50%", marginRight: '6px'}}
        >
            {purchaseOrderOptions}
        </Select>
    )
};

// Component to render an attachment with document type selection dropdwon
const AttachmentWithType = (options) => {
    const handleSelectFileType = (value) => {
        options.onSelectFileType(value, options.invoice.id, options.indexAttachmemt);
    }

    const removeAttachment = () => {
        options.onRemoveAttachment(options.invoice.id, options.indexAttachmemt);
    }

    const { FileName } = options.attachment;
    return (
        <div className="attachment-with-type">
            <Select 
                placeholder="Select Type"
                style={{ minWidth: 150, marginRight: '15px'}}
                // size="large"
                onChange={handleSelectFileType}
                // defaultValue={options.attachment.FileName.includes("INVOICEDOC") ? "INVOICE" : null}
            >
                <Option value="INVOICE" key="INVOICE">Invoice</Option>
                <Option value="TIMESHEET" key="TIMESHEET">Timesheet</Option>
                <Option value="OTHER" key="OTHER">Other</Option>
            </Select>
            {options.attachment.Uploaded ?
                <Text ellipsis title={FileName}>{FileName}</Text> :
                <Text ellipsis title={FileName}><a href={options.attachment.URI}>{FileName}</a></Text>
            }
            <Popconfirm placement="top" title={<span>Are you sure to remove <b>{FileName}</b> ？</span>} okText="Yes" cancelText="No" onConfirm={() => {removeAttachment()}}>
                <div className="remove-attachment-icon" style={{marginLeft: 8}} title="Don't submit"><CloseCircleOutlined /></div>
            </Popconfirm>
        </div>
    )
};

class InvoiceSubmissionCard extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
		getJwt((err, jwt) => {
			this.setState({jwt: jwt});
		})
    }

    getInvoiceFileUploadProps = (docNumber, type) => {
        return {
            name: `invoice-${docNumber}-${moment().format('MM-DD-YY')}`.toLowerCase(),
            multiple: true,
            action: `${environment.apiBaseUrl}/financing/upload`,
            headers: {
                businesskey: this.props.aionStore.BusinessUniqueKey,
                type: "invoices",
                jwt: this.props.aionStore.jwt
            },
            onChange: (info) => {
                const { status } = info.file
                if (status !== 'uploading') {
                    console.log(info.file, info.fileList)
                }
                if (status === 'done') {
                    this.props.onUploadFile(docNumber, info);
                    message.success(`${info.file.name} file uploaded successfully.`)
                } else if (status === 'error') {
                    message.error(`${info.file.name} file upload failed.`)
                }
            },
        };
    }

    render() {
        const { invoice, POs } = this.props;
        console.log("INVSUBMISSIOn", invoice)
        return (
            <Card size='small' style={{minWidth: '100%', padding: "5px 20px"}}>
                <div>
                    <Row>
                        <Col span={8}><StatViewCompact {...this.props} name={"Invoice #"} value={invoice.docNumber || invoice.DocNumber} /></Col>
                        <Col span={8}><StatViewCompact {...this.props} name={"Date"} value={moment(invoice.date || (invoice.Sync || {}).CreateTime).format('MMM Do, YYYY')} /></Col>
                        <Col span={8}><StatViewCompact {...this.props} name={"Customer Name"} value={invoice.customerName || (invoice.CustomerReference || {}).Name} /></Col>
                    </Row>
                    <Row>
                        <Col span={8}><StatViewCompact {...this.props} name={"Total"} value={formatter.format(invoice.totalAmt || invoice.TotalAmt)} /></Col>
                        <Col span={8}><StatViewCompact {...this.props} name={"Balance"} value={formatter.format(invoice.balance || invoice.Balance)} /></Col>
                        <Col span={8}><StatViewCompact {...this.props} name={"Due On"} value={moment(invoice.dueDate || invoice.DueDate).format('MMM Do, YYYY')} /></Col>
                    </Row>
                    <Divider style={{margin: '15px 0'}} />
                    <Row>
                        <Col span={24}>
                            <StatViewCompact {...this.props} name={"Purchase Order"} value={<SelectPurchaseOrder {...this.props} invoice={invoice} POs={POs} />} />
                        </Col>
                    </Row>
                    <Divider style={{margin: '15px 0'}} />
                    <Row>
                        <Col span={24}>
                            <StatViewCompact {...this.props} name={"Attachments"} valueStyle={{fontSize: '14px', fontWeight: 'normal'}} value={
                                <div className="flex-container flex-column">
                                    {
                                        invoice.submittedAttachments.map((attachment, mapIndex) => {
                                            return <AttachmentWithType key={attachment.FileName+mapIndex} {...this.props} attachment={attachment} invoice={invoice} indexAttachmemt={mapIndex} />
                                        }) 
                                    }
                                    {
                                        <Upload {...this.getInvoiceFileUploadProps(invoice.docNumber, 'supportingDocs')}>
                                            <Button style={{marginTop: 20, fontWeight: 600}}>
                                                <UploadOutlined style={{fontWeight: 600}} /> Click to upload {invoice.submittedAttachments.length > 0 && 'more'}
                                            </Button>
                                        </Upload>
                                    }
                                </div> 
                            } />
                        </Col>
                    </Row>
                    {/*
                    {!invoice.attachmentComplete &&
                    <Alert
                        style={{ margin: '12px 10px 0 10px' }}
                        message="Missing required files"
                        type="error"
                        showIcon
                    />}
                    */}

                </div>
            </Card>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.creditAppReducer,
        aionStore: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(InvoiceSubmissionCard))
