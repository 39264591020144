import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'
import styled from 'styled-components'

import { Tabs, Table } from 'antd'

// Components
import { Title } from '../../Reusable/Text'
import environment from '../../../environment'
import { apiPOSTReq } from '../../../Utils/api'
import { ErrorAlert } from '../../Reusable/Alert'
import { LabeledInput } from '../../Reusable/Input';
import { Paragraph } from '../../Reusable/Text';
import InvoiceTable from './InvoiceTable'
import { Flex, FlexColumn, Container, InputContainer } from '../../Reusable/Container'

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
})

const { TabPane } = Tabs

export const CardButton = styled.div`
    margin-top: 18px;
    background-color: rgb(255, 255, 255);
    border-radius: 0.5rem;
    width: 450px;
    margin-bottom: 14px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    opacity: 1;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.1);
    padding: 1.8rem;
    transition-property: box-shadow;
    transition-duration: 300ms;
    &.selected {
        cursor: pointer;
        box-shadow: 0 3px 15px rgb(0 0 0 / 0.2);
    };
    &.selected:hover {
        cursor: pointer;
        box-shadow: 0 3px 15px rgb(0 0 0 / 0.4);
    };
`

export const CardButtonText = styled.span`
    font-size: 18px;
    font-weight: 400;
`

class UploadSummary extends Component {

    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            file: null,
            fileName: '',
            showErrorModal: false,
            fileList: [],
            activeKey: "RollForward",
            cachedInvoices: {},
        }
    }

    componentDidMount() {
        this.getAgingSummary()
    }

    getAgingSummary = () => {
        this.setState({ loading: true })

        var body = { UploadId: this.props.upload.uploadJobId }

        apiPOSTReq(`${environment.iveBaseUrl}/ar/aging/getAgingSummary`, null, body, (err, resp) => {
            try {
                const data = resp || {}
                console.log("/ar/aging/getAgingSummary", data)
                if (data.result) {
                    if (data.arAgingRollForwardSummary) {
                        var summary = data.arAgingRollForwardSummary
                        var pendingAmount = data.arSnapShot.pendingPayments || 0;
                        var appliedAmount = data.arSnapShot.appliedPayments || 0;

                        if (summary.newList) {
                            summary.newList.forEach(invoice => {
                                invoice.docNumber = invoice.invoiceNumber
                                invoice.totalAmt = invoice.invoiceAmount
                                invoice.txnDate = invoice.invoiceDate
                            })
                        }

                        if (summary.partialPaidList) {
                            summary.partialPaidList.forEach(invoice => {
                                invoice.docNumber = invoice.invoiceNumber
                                invoice.totalAmt = invoice.invoiceAmount
                                invoice.txnDate = invoice.invoiceDate
                            })
                        }

                        this.setState({ summary, customerNames: data.customerNames || [], appliedAmount: appliedAmount, pendingAmount: pendingAmount })
                    }
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getAgingSummary", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    onChangeTab = (activeKey) => {
        this.setState({ activeKey })
    }

    render() {
        const { summary, customerNames, appliedAmount, pendingAmount } = this.state

        const rollForwardColumns = [
            {
                title: "",
                dataIndex: 'name',
                key: 'name',
                width: 300,
                render: (name, data) => {
                    var fontWeight, color

                    if (data.bold === true) fontWeight = 500

                    return (
                        <span style={{ fontWeight }}>{name}</span>
                    )
                }
            },
            {
                title: "Invoice Count",
                dataIndex: 'invoiceCount',
                key: 'invoiceCount',
                render: (count, data) => {
                    var fontWeight, color

                    if (data.bold === true) fontWeight = 500

                    if (data.color) color = data.color

                    if (data.warning) color = "#FF2423"

                    return (
                        <span style={{ fontWeight, color }}>{count}</span>
                    )
                }
            },
            {
                title: "Gross Balance",
                dataIndex: 'grossBalance',
                key: 'grossBalance',
                align: 'right',
                render: (grossBalance, data) => {
                    var fontWeight, color

                    if (data.bold === true) {
                        fontWeight = 500
                    }

                    if (data.warning) color = "#FF2423"

                    return (
                        <span style={{ fontWeight, color }}>{formatter.format(grossBalance)}</span>
                    )
                }
            },
        ]

        var rollForwardData
        var paymentsReceived = 0;
        if (summary) {
            rollForwardData = [
                {
                    name: "Gross AR (as of prior upload)",
                    invoiceCount: summary.beginInvCount,
                    grossBalance: summary.grossBalance,
                    bold: true,
                    color: "#009A9F",
                },
                {
                    name: "Paid Invoices",
                    invoiceCount: summary.paidInvCount,
                    grossBalance: summary.paidInvAmount,
                    warning: true,
                },
                {
                    name: "Partial Payments",
                    invoiceCount: summary.partialPaidInvCount,
                    grossBalance: summary.partialInvAmount,
                    warning: true,
                },
                {
                    name: "New Invoices",
                    invoiceCount: summary.newInvCount,
                    grossBalance: summary.newInvAmount,
                    color: "#009A9F",
                },
                {
                    name: "Ending AR",
                    invoiceCount: summary.endInvCount,
                    grossBalance: summary.endingBalance,
                    bold: true,
                    color: "#009A9F",
                },
            ]

            paymentsReceived = summary.paidInvAmount + summary.partialInvAmount;
        }

        const newCustomerColumns = [
            {
                title: "Customer Name",
                dataIndex: 'name',
                key: 'name',
            },
        ]

        var newCustomerData = []

        if (customerNames) {
            customerNames.forEach(customer => {
                newCustomerData.push({ name: customer })
            })
        }

        var totalPaidBalance = 0;
        var totalPaidTotalAmount = 0;
        var totalPartialPaidBalance = 0;
        var totalPartialPaidTotalAmount = 0;
        var totalPartiallyPaid = 0;
        var paidInvoices = [];

        if (summary) {
            for (var i = 0; i < summary.paidList.length; i++) {

                paidInvoices.push({
                    "customerName": summary.paidList[i].customerName,
                    "amount": summary.paidList[i].amount,
                    "paidAmount": summary.paidList[i].balance,
                    "invoiceDate": summary.paidList[i].txnDate,
                    "dueDate": summary.paidList[i].dueDate,
                    "docNumber": summary.paidList[i].docNumber,
                    "totalAmt": 0,
                    "txnDate": summary.paidList[i].txnDate
                });
                var amount = summary.paidList[i].totalAmt;
                var balance = summary.paidList[i].balance;
                totalPaidTotalAmount = totalPaidTotalAmount + amount;
                totalPaidBalance = totalPaidBalance + balance;
            }

            for (var i = 0; i < summary.partialPaidList.length; i++) {
                var pinv = summary.partialPaidList[i];
                var balance = pinv.totalAmt;
                var amount = pinv.amount;
                var paid = pinv.paidAmount;

                totalPartialPaidTotalAmount = totalPartialPaidTotalAmount + amount;
                totalPartialPaidBalance = totalPartialPaidBalance + balance;
                totalPartiallyPaid = totalPartiallyPaid + paid;
            }
        }

        var variancePc = ((((appliedAmount + pendingAmount) - paymentsReceived) / paymentsReceived) * 100);
        if (paymentsReceived == 0) {
            variancePc = 0;
        }

        if (variancePc < 0) {
            variancePc = variancePc * -1;
        }

        return (
            <>
                <div style={{ display: "flex", flexDirection: "column", margin: "5px 20px 35px 20px" }}>
                    <Title level={4}>Upload Summary</Title>

                    <Tabs defaultActiveKey="RollForward" onChange={this.onChangeTab}>
                        <TabPane tab="Roll Forward Summary" key="RollForward">
                            {
                                summary &&
                                <Table
                                    dataSource={rollForwardData}
                                    columns={rollForwardColumns}
                                    pagination={false}
                                />
                            }
                        </TabPane>
                        <TabPane tab="Invoice Payment Comparison (Beta)" key="InvoicePayment">
                            {
                                summary &&
                                <FlexColumn>
                                    <Flex between style={{ width: "100%", marginTop: 24 }}>
                                        <Paragraph style={{ width: "100%" }}>
                                            <Flex start>
                                                <FlexColumn style={{ width: "40%" }}>
                                                    <span style={{ color: this.props.theme.colors.systemGray, display: "inline-block" }}>
                                                        <b>Applied Payments</b> <br />(As of Upload) </span>
                                                </FlexColumn>
                                                <FlexColumn>
                                                    {formatter.format(appliedAmount)}
                                                </FlexColumn>
                                            </Flex>
                                        </Paragraph>
                                    </Flex>
                                    <Flex between style={{ width: "100%", marginTop: 24 }}>
                                        <Paragraph style={{ width: "100%" }}>
                                            <Flex start>
                                                <FlexColumn style={{ width: "40%" }}>
                                                    <span style={{ color: this.props.theme.colors.systemGray, display: "inline-block" }}>
                                                        <b>Pending Payments</b> <br />(Expected to be applied by receipts cutoff on upload date) </span>
                                                </FlexColumn>
                                                <FlexColumn>
                                                    {formatter.format(pendingAmount)}
                                                </FlexColumn>
                                            </Flex>
                                        </Paragraph>
                                    </Flex>
                                    <Flex between style={{ width: "100%", marginTop: 24 }}>
                                        <Paragraph style={{ width: "100%" }}>
                                            <Flex start>
                                                <FlexColumn style={{ width: "40%" }}>
                                                    <span style={{ color: this.props.theme.colors.systemGray, display: "inline-block" }}>
                                                        <b>AR Aging Report - Invoice Value Reduction</b> </span>
                                                </FlexColumn>
                                                <FlexColumn>
                                                    {formatter.format(paymentsReceived)}
                                                </FlexColumn>
                                            </Flex>
                                        </Paragraph>
                                    </Flex>
                                    <Flex between style={{ width: "100%", marginTop: 24 }}>
                                        <Paragraph style={{ width: "100%" }}>
                                            <Flex start>
                                                <FlexColumn style={{ width: "40%" }}>
                                                    <span style={{ color: this.props.theme.colors.systemGray, display: "inline-block" }}>
                                                        <b>Variance</b> </span>
                                                </FlexColumn>
                                                <FlexColumn>{variancePc}% ({formatter.format((appliedAmount + pendingAmount) - paymentsReceived)})</FlexColumn>
                                            </Flex>
                                        </Paragraph>
                                    </Flex>
                                </FlexColumn>
                            }
                        </TabPane>
                        <TabPane tab="New Customers" key="NewCustomers">
                            {
                                summary &&
                                <Table
                                    dataSource={newCustomerData}
                                    columns={newCustomerColumns}
                                    size="small"
                                />
                            }
                        </TabPane>
                        <TabPane tab="Paid Invoices" key="PaidInvoices">
                            {
                                summary &&
                                <>
                                    <InvoiceTable
                                        key="paidInvoicesTable"
                                        rowKey="id"
                                        invoices={paidInvoices}
                                        hideEditColumn={true}
                                        disableExpandable
                                        type={"Paid"}
                                    />
                                    <Flex start>
                                        Total Amount: {formatter.format(totalPaidTotalAmount)}
                                    </Flex>
                                    <Flex start>
                                        Total Balance: {formatter.format(0)}
                                    </Flex>
                                    <Flex start>
                                        Total Payments: {formatter.format(totalPaidBalance)}
                                    </Flex>
                                </>
                            }
                        </TabPane>
                        <TabPane tab="Partially Paid Invoices" key="PartiallyPaidInvoices">
                            {
                                summary &&
                                <>
                                    <InvoiceTable
                                        key="partiallyPaidInvoicesTable"
                                        rowKey="id"
                                        invoices={summary.partialPaidList}
                                        hideEditColumn={true}
                                        disableExpandable
                                        type={"PartiallyPaid"}
                                    />
                                    <Flex start>
                                        Total Amount: {formatter.format(totalPartialPaidTotalAmount)}
                                    </Flex>
                                    <Flex start>
                                        Total Balance: {formatter.format(totalPartialPaidBalance)}
                                    </Flex>
                                    <Flex start>
                                        Total Payments: {formatter.format(totalPartiallyPaid)}
                                    </Flex>
                                </>
                            }
                        </TabPane>
                        <TabPane tab="New Invoices" key="NewInvoices">
                            {
                                summary &&
                                <InvoiceTable
                                    key="newInvoicesTable"
                                    rowKey="id"
                                    invoices={summary.newList}
                                    hideEditColumn={true}
                                    disableExpandable
                                    type={"New"}
                                />
                            }
                        </TabPane>
                    </Tabs>
                </div >
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(UploadSummary))