import React, { useState, useEffect } from 'react'
import { Tabs } from 'antd'
import moment from 'moment'
import { Text } from '../../Reusable/Refresh/Text'
import { TextButton } from '../../Reusable/Button'
import { Flex, FlexColumn, LightContainer } from '../../Reusable/Container'
import { LabeledInput } from '../../Reusable/Input'
import { Image } from '../../Reusable/Image'
import { FromAccountOption } from '../../Reusable/Option'
import { ErrorAlert } from '../../Reusable/Alert'
import { apiGETDocUrl } from '../../../Utils/api'
import Info from '../../Reusable/Info'
import { getBPAccountsWithAccess, addressObjectToMultiLineStr } from '../../../Utils/util'
import { Select } from 'antd'
import environment from '../../../environment'
import { saveAs } from 'file-saver'
import Download from "../../../Images/download.png"
import AppStore from "../../../Images/appstore.jpg"

const { TabPane } = Tabs
const { Option } = Select

const AddFunds = (props) => {
    const [account, setAccount] = useState(props.account)
    const [accounts, setAccounts] = useState(false)
    const [accountOptions, setAccountOptions] = useState([])
    const [loading, setLoading] = useState(false)
    const [tab, setTab] = useState(false)

    useEffect(() => {
        var Accounts = getBPAccountsWithAccess("Transactions", { getActiveAccounts: true })
        Accounts = Accounts || []

        var accountOptions = []
        var filteredAccounts = Accounts.filter(item => { return (item.accountType !== 'ACCOUNTS_RECEIVABLE') })
        setAccounts(filteredAccounts)
        filteredAccounts.forEach((account, i) => {
            accountOptions.push(<Option key={account.accountId} value={i} style={{ backgroundColor: 'transparent' }}>
                <FromAccountOption account={account} />
            </Option>)
        })
        setAccountOptions(accountOptions)
    }, [])

    const onTabChange = tab => {
        setTab(tab)
    }

    const downloadAccountVerificationPDF = () => {
        setLoading(true)
        var query = {
            AccountId: account.accountId
        }
        apiGETDocUrl(`${environment.bbBaseUrl}/bb/export/pdf/accountVerificationTemplate`, {}, query, (err, resp) => {
            setLoading(false)
            const data = (resp || {}).data
            try {
                if (err) throw Error("We had trouble downloading your account verification. Please try again.")
                const blob = new Blob([data], {
                    type: 'application/pdf',
                })
                const fileURL = URL.createObjectURL(blob)
                saveAs(fileURL, `Bank Account Letter ${moment().format('MMDDYY')}`)
            } catch (error) {
                ErrorAlert({ description: error.message })
            }
        })
    }

    return (
        <FlexColumn start left style={{ height: 675 }} fullWidth>
            <Text heading>Deposit Details</Text>

            <Tabs size="large" defaultActiveKey="details" onChange={onTabChange} style={{ margin: '0 0 12px', width: '100%' }}>
                <TabPane tab="Wire or ACH" key="details">
                    <Text style={{ marginBottom: 24, marginTop: 0 }}>Use the following details to make either a Wire or ACH transfer into this account</Text>

                    {
                        (accountOptions && accountOptions?.length > 1) &&
                        < LabeledInput
                            label="Select account"
                            type="select"
                            className="no-left-padding"
                            id="fromAccount"
                            key="fromAccount"
                            width='100%'
                            placeholder="From account"
                            autoFocus
                            onChange={(value, option) => { console.log('accounts[value]: ', accounts[value]); setAccount(accounts[value]) }}
                            value={account && <Text capitalize>{(account.nickName || `Business ${account.accountSubType}`)} • {account.mask}</Text>}
                            noAsterisk
                        >
                            {accountOptions}
                        </LabeledInput>
                    }

                    {
                        account &&
                        <>
                            <LightContainer fullWidth margin='24px 0 12px'>
                                <Info label='Beneficiary Name' value={account.accountName} />
                                <Info label='Beneficiary Address' value={addressObjectToMultiLineStr(account.address)} />
                                <Info label='Account Type' value={account.productType} />
                                <Info label='Account Number' value={account.accountNumber} />
                                <Info label='Routing Number' value={account.routingNumber} />
                                <Info label='Bank Name' value='Cross River Bank' />
                                <Info label='Bank Address'
                                    value={<FlexColumn start>
                                        <Text>885 Teaneck Road</Text>
                                        <Text>Teaneck, NJ 07666</Text>
                                    </FlexColumn>}
                                    noMargin />
                            </LightContainer>

                            <TextButton loading={loading} onClick={() => downloadAccountVerificationPDF()} rightIcon={<Image src={Download} />} text="DOWNLOAD" />
                        </>
                    }
                </TabPane>

                <TabPane tab="Check Deposit" key="mobile">
                    <FlexColumn start centerHorizontally fullWidth fullHeight>
                        <Flex start fullWidth>
                            <Text style={{ marginBottom: 75, marginTop: 0 }}>Use the following details to deposit a check into this account</Text>
                        </Flex>

                        <Text margin='36px 0 0'><i>Download our mobile app on Apple’s</i></Text>
                        <Text margin='0 0 24px'><i>App Store to deposit your check</i></Text>
                        <img width='250px' src={AppStore} style={{ cursor: 'pointer' }} onClick={() => window.open('https://apps.apple.com/us/app/aion-business-banking/id1529925509?platform=iphone', '_blank')} />
                    </FlexColumn>
                </TabPane>
            </Tabs>
        </FlexColumn >
    )
}

export default AddFunds