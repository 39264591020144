import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Divider, message } from 'antd';
import { withTheme } from 'styled-components';

import { Flex, FlexColumn, InputContainer } from '../Reusable/Container';
import { Paragraph, Title } from '../Reusable/Text';
import { InputConfirmation, LabeledInput } from '../Reusable/Input';
import { dateFormatList, getAddressObj, toCurrency } from '../../Utils/util';
import { Button } from '../Reusable/Button';
import { apiPOSTNoAuth } from '../../Utils/api';
import environment from '../../environment';
import { ErrorAlert } from '../Reusable/Alert';


class Index extends Component {

    state = {
        depositType: "Aion Banking",
        loading: false,
        paymentSuccess: false
    }

    componentDidMount = () => {
        const params = new URLSearchParams(this.props.location.search.replaceAll("+", "%2B"));
        // console.log("componentDidMount", params.get("token"));
        this.setState({
            token: params.get("token"),
            type: params.get("type"),
            frequency: params.get("frequency"),
            amount: params.get("amount"),
            plan: params.get("plan"),
            invoice: params.get("invoice")
        });
    }

    handleOnChange = (event) => {
        const target = event.target;
        this.saveUserInput(target.id || target.name, target.value);
    }

    saveUserInput = (id, dataToSave) => {
        this.setState({ [id]: dataToSave });
    }

    getLocation = (location, formattedAddress) => {
        let addrObj = getAddressObj(location);
        addrObj.city = addrObj.city ? addrObj.city.substring(0, 18) : '';
        this.saveUserInput("address", addrObj)
    }

    handleOnKeyDown = (event) => { if (event.key === "Enter") this.handleSubmit() };

    handleSubmit = () => {
        var body = {};
        const { token, type, frequency, amount, plan, depositType, aionAccountNumber, accountNumber, routingNumber, nameOnAccount, cardNumber, cvc, expMonthYear, zip, address, invoice } = this.state;
        if(!aionAccountNumber || (aionAccountNumber == "")) {
            ErrorAlert({ description: "Please provide a valid account number" });
            return;
        }
        if(!address) {
            ErrorAlert({ description: "Please provide a billing address to continue" });
            return;
        }
        if(depositType == "Aion Banking") {
            body = {
                "token": token,
                "paymentDeliveryMode": "AION_BANKING",
                "billingType": type,
                "billingContext": `Payment for invoice #${invoice}. ${type} - ${plan}, ${frequency}`,
                "amount": amount,
                "aionAccountNumber": aionAccountNumber,
                "billingAddress": address,
                "frequency": frequency
            }
        }
        if(depositType == "Other Banks") {
            body = {
                "token": token,
                "paymentDeliveryMode": "OTHER_BANKS",
                "billingType": type,
                "billingContext": `Payment for invoice #${invoice}. ${type} - ${plan}, ${frequency}`,
                "nameOnAccount": nameOnAccount,
                "accountNumber": accountNumber,
                "accountType": "checking",
                "routingNumber": routingNumber,
                "billingAddress": this.state.address,
                "frequency": frequency
            }
        }
        console.log("handleSubmit", body);
        this.setState({ loading: true });
        apiPOSTNoAuth(`${environment.opsBaseUrl}/ops/pay`, null, body, (err, resp) => {
            try {
                this.setState({ loading: false });
                if (err) throw new Error(err);
                const data = resp.data || {};
                if (data.result) {
                    message.success("Payment successfully initiated!");
                    this.setState({ paymentSuccess: true });
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later");
                }
            } catch (error) {
                console.log("ERRR apiPOSTNoAuth", error, err, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    render() {
        const { token, type, frequency, amount, plan, depositType, invoice, aionAccountNumber, accountNumber, routingNumber, nameOnAccount, cardNumber, cvc, expMonthYear, zip, address, loading, paymentSuccess } = this.state || {};
        console.log("paymentsucc", paymentSuccess);
        return (
            <FlexColumn center>
                <FlexColumn style={{ padding: "30px 60px", width: "88%" }}>
                    <Flex between>
                        <Flex>
                            <img src="https://aion-assets.s3-us-west-2.amazonaws.com/logos/aion_full_blk.png" style={{height: "80px", margin: '20px'}} alt="Aion Logo" />
                            <FlexColumn>
                                <Paragraph>
                                    Aion Financial<br/>Technologies, Inc.
                                </Paragraph>
                                <Paragraph>
                                    75 E Santa Clara St., Suite 900<br/>
                                    San Jose, CA 95113
                                </Paragraph>
                            </FlexColumn>
                        </Flex>
                        <Paragraph>
                            contact@aionfi.com<br/>
                            1-855-800-AION
                        </Paragraph>
                    </Flex>
                    <Divider />
                    {
                    !paymentSuccess ?
                        <Flex style={{ justifyContent: "space-between", alignItems: "start" }}>
                            <FlexColumn style={{ width: "600px" }}>
                                <Paragraph bold size="1.2rem">Payment information</Paragraph>
                                {/* <Radio.Group size="large" style={{ marginTop: 5 }} key="radio" id="depositType" name="depositType" onChange={this.handleOnChange} value={depositType}
                                    buttonStyle="solid" size="large">
                                    {["Aion Banking", "Other Banks"].map(item => <Radio.Button key={item} value={item}>{item}</Radio.Button>)}
                                </Radio.Group>
                                <br/> */}
                                {
                                    (() => {
                                        if (depositType == "Aion Banking") {
                                            return (
                                                <>
                                                    <LabeledInput
                                                        labelcolor={this.props.theme.colors.secondary3}
                                                        label="Aion Account Number*"
                                                        key={depositType + ".AccountNumber"}
                                                        id="aionAccountNumber"
                                                        onChange={this.handleOnChange}
                                                        value={aionAccountNumber}
                                                        placeholder="776288901123"
                                                        onKeyDown={this.handleOnKeyDown}
                                                    />
                                                </>
                                            )
                                        }
                                        if (depositType == "Other Banks") {
                                            return (
                                                <>
                                                    <LabeledInput
                                                        labelcolor={this.props.theme.colors.secondary3}
                                                        label="Name on account*"
                                                        key={depositType + ".NameOnAccount"}
                                                        id="NameOnAccount"
                                                        onChange={this.handleOnChange}
                                                        value={nameOnAccount}
                                                        placeholder="Richard Hendricks"
                                                        onKeyDown={this.handleOnKeyDown}
                                                    />
                                                    <LabeledInput
                                                        labelcolor={this.props.theme.colors.secondary3}
                                                        label="Checking account number*"
                                                        key={depositType + ".AccountNumber"}
                                                        id="AccountNumber"
                                                        onChange={this.handleOnChange}
                                                        value={accountNumber}
                                                        placeholder="776288901123"
                                                        onKeyDown={this.handleOnKeyDown}
                                                    />
                                                    <LabeledInput
                                                        labelcolor={this.props.theme.colors.secondary3}
                                                        label="Routing number*"
                                                        key={depositType + ".RoutingNumber"}
                                                        id="RoutingNumber"
                                                        onChange={this.handleOnChange}
                                                        value={routingNumber}
                                                        placeholder="052001633"
                                                        onKeyDown={this.handleOnKeyDown}
                                                    />
                                                </>
                                            )
                                        }
                                        if (depositType == "Debit card") {
                                            return (
                                                <>
                                                    <LabeledInput
                                                        labelcolor={this.props.theme.colors.secondary3}
                                                        label="Name on account*"
                                                        key={depositType + ".NameOnAccount"}
                                                        id="NameOnAccount"
                                                        onChange={this.handleOnChange}
                                                        value={nameOnAccount}
                                                        placeholder="Richard Hendricks"
                                                        onKeyDown={this.handleOnKeyDown}
                                                    />
                                                    <LabeledInput
                                                        labelcolor={this.props.theme.colors.secondary3}
                                                        label="Card number*"
                                                        key={depositType + ".CardNumber"}
                                                        id="CardNumber"
                                                        onChange={this.handleOnChange}
                                                        value={cardNumber}
                                                        placeholder="4242424242425555"
                                                        onKeyDown={this.handleOnKeyDown}
                                                    />
                                                    <LabeledInput
                                                        labelcolor={this.props.theme.colors.secondary3}
                                                        label="CVC* (Three or four digit card verification code)"
                                                        key={depositType + ".CVC"}
                                                        id="CVC"
                                                        onChange={this.handleOnChange}
                                                        value={cvc}
                                                        placeholder="567"
                                                        maxLength={4}
                                                        onKeyDown={this.handleOnKeyDown}
                                                    />
                                                    <LabeledInput
                                                        labelcolor={this.props.theme.colors.secondary3}
                                                        label="Card expiration date*"
                                                        type="date-picker"
                                                        picker="month"
                                                        format={dateFormatList}
                                                        key={depositType + ".ExpMonthYear"}
                                                        id="ExpMonthYear"
                                                        onChange={(date, dateStr) => this.saveUserInput("expMonthYear", dateStr)}
                                                        placeholder="01/2031"
                                                        onKeyDown={this.handleOnKeyDown}
                                                    />
                                                    <LabeledInput
                                                        labelcolor={this.props.theme.colors.secondary3}
                                                        label="Zip code*"
                                                        key={depositType + ".Zip"}
                                                        id="Zip"
                                                        onChange={this.handleOnChange}
                                                        value={zip}
                                                        placeholder="94131"
                                                        onKeyDown={this.handleOnKeyDown}
                                                    />
                                                </>
                                            )
                                        } else {
                                            return null;
                                        }
                                    })()
                                }
                                <br/>
                                <Paragraph bold size="1.2rem" noMargin>Billing address*</Paragraph>
                                <LabeledInput
                                    labelcolor={this.props.theme.colors.secondary3}
                                    label=""
                                    type="location"
                                    key={depositType + ".HomeAddr"}
                                    getLocation={this.getLocation}
                                    value={(address)}
                                    placeholder="Enter address"
                                />
                                <Button solid style={{ alignSelf: "end", width: "100px", height: "50px" }} color={this.props.theme.colors.secondary1} permtype="Override" loading={loading} onClick={() => this.handleSubmit()} text='Pay' />
                            </FlexColumn>
                            {/* <Divider type="vertical" style={{ height: "500px" }} /> */}
                            <div style={{ height: "500px", width: "8px", background: this.props.theme.colors.systemGray6, margin: "0 30px" }} />
                            <FlexColumn style={{ width: "400px" }}>
                                <Paragraph bold size="1.2rem" noMargin>Billing information</Paragraph>
                                <InputContainer>
                                    <Flex between style={{ flexWrap: "wrap" }}>
                                        {plan && <InputConfirmation key="0" label="Your Plan" value={plan} />}
                                        <InputConfirmation key="1" label="Total Amount" value={"$" + toCurrency(amount)} />
                                        <InputConfirmation key="2" label="Billing" value={(frequency == "none") ? "One Time Payment" : frequency} />
                                        <InputConfirmation key="3" label="Invoice No." value={`${invoice}`} />
                                    </Flex>
                                    
                                </InputContainer>
                            </FlexColumn>
                        </Flex>
                        :
                        <InputContainer>
                            <Title level={5}>Thank you!</Title>
                            <Paragraph>Your information was successfully submitted and the payment is now in processing. Please reach out to contact@aionfi.com with any questions.</Paragraph>
                        </InputContainer>
                    }
                    {
                    !paymentSuccess && 
                        <FlexColumn style={{ marginTop: "20px" }}>
                            <Paragraph bold noMargin>Disclaimer</Paragraph>
                            <Paragraph level={1}>By clicking "Pay" above and subsequently submitting your payment information to us, you agree to pay the entire <br/>Payment Amount for the bill(s) selected, and authorize us to charge your designated payment method for such Payment Amount.</Paragraph>
                        </FlexColumn>
                    }
                </FlexColumn>
            </FlexColumn>
        );
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)));