import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment'
import { withTheme } from 'styled-components'
import { saveAs } from 'file-saver'

import { Modal, Select, Skeleton, Popover } from 'antd'
import { Flex, FlexColumn, CardContainer, LightContainer } from '../../Reusable/Container'
import { KPILight } from '../../Reusable/KPI'
import PageHeader from '../../Reusable/PageHeader'
import { Text, Tag } from '../../Reusable/Text'
import { Image } from '../../Reusable/Image'
import { BasicOption } from '../../Reusable/Option'
import Chart from '../../Reusable/Chart'
import { ErrorAlert } from '../../Reusable/Alert'
import { addDataToStore, SAVE_DATA, BNK_SAVE_DATA } from '../../../Actions/actions'
import { getBPAccountsWithAccess, toCurrency, sliceIntoChunks, getUserRoles, getResourcePerm } from '../../../Utils/util'
import { Button, TextButton } from '../../Reusable/Button'
import { fetchCounterparties, fetchBBTransactions, fetchBBHistory, normalizeAndSortHistory } from '../../../Utils/bankingDataManager'
import DebitCardList from '../Reusable/DebitCardList'
import AccountList from '../Reusable/AccountList'
import Pager from '../../Reusable/Pager'
import { GettingStartedCard } from '../../Reusable/Card'
import AccountNameModal from '../Reusable/AccountNameModal'
import AddFundsModal from '../Reusable/AddFundsModal'
import TransactCard from '../Reusable/TransactCard'
import PendingTable from '../Reusable/PendingTable'
import ActivityTable from '../Reusable/ActivityTable'
import ActivityByRail from '../Reusable/ActivityByRail'
import PostedTransactionsCard from '../Reusable/PostedTransactionsCard'
import RailCard from '../Reusable/RailCard'
import MinimumDepositModal from './MinimumDepositModal'
import DocumentDownloadCard from '../Reusable/DocumentDownloadCard'
import { apiPOSTReq, apiGETDocUrl } from '../../../Utils/api'
import { uaApiPOST } from '../../../Utils/userAccess'
import environment from '../../../environment'

// Images
import ModalClose from '../../../Images/modal-close.png'
import ArrowRight from "../../../Images/arrow-right.png"
import WarningBlue from "../../../Images/warning-blue.png"
import ChartImage from "../../../Images/chart.png"
import GSBack from "../../../Images/gs-back.png"
import Check from "../../../Images/gs-check.png"
import GSCard from "../../../Images/gs-card.png"
import Lock from "../../../Images/gs-lock.png"
import User from "../../../Images/gs-user.png"
import Dollar from "../../../Images/gs-dollar.png"
import Invite from "../../../Images/invite.png"
import Clock from "../../../Images/clock.png"
import Graph from "../../../Images/graph.png"

const Dashboard = (props) => {
    const [accounts, setAccounts] = useState([])
    const [accountMap, setAccountMap] = useState([])
    const [accountsPage, setAccountsPage] = useState(0)
    const [cardsLoading, setCardsLoading] = useState(true)
    const [allCardsLoading, setAllCardsLoading] = useState(true)
    const [chartLoading, setChartLoading] = useState(true)
    const [usersLoading, setUsersLoading] = useState(true)
    const [loadingAccountVerificationPDF, setLoadingAccountVerificationPDF] = useState(false)
    const [loadingRecipients, setLoadingRecipients] = useState(true)
    const [dropdownVisible, setDropdownVisible] = useState(false)
    const [totalRecipients, setTotalRecipients] = useState(0)
    const [multiAccount, setMultiAccount] = useState(false)
    const [activityDuration, setActivityDuration] = useState('Last 7 days')
    const [showActivity, setShowActivity] = useState(true)
    const [selectedAccount, setSelectedAccount] = useState({})
    const [showAccountNameModal, setShowAccountNameModal] = useState(false)
    const [showAddFundsModal, setShowAddFundsModal] = useState(false)
    const [pagination, setPagination] = useState({ pageSize: 10, current: 1 })
    const [pendingTransfers, setPendingTransfers] = useState(null)
    const [loadingPending, setLoadingPending] = useState(true)
    const [newAccount, setNewAccount] = useState(false)
    const [zeroBalance, setZeroBalance] = useState(false)
    const [personCardsLoading, setPersonCardsLoading] = useState(true)
    const [cards, setCards] = useState([])
    const [allCards, setAllCards] = useState([])
    const [dailyBalanceChart, setDailyBalanceChart] = useState()
    const [accountsLoading, setAccountsLoading] = useState(true)
    const [loadingPosted, setLoadingPosted] = useState(true)
    const [postedTransfers, setPostedTransfers] = useState([])
    const [showMinimumDeposit, setShowMinimumDeposit] = useState(false)
    const [registeredUsers, setRegisteredUsers] = useState([])
    const [hideGettingStarted, setHideGettingStarted] = useState(props.aionStore.hideGettingStarted || false)
    const [subAccounts, setSubAccounts] = useState()
    const [outboundACH, setOutboundACH] = useState(false)

    const { aionStore } = props
    const { business } = aionStore || {}
    const userRole = getUserRoles(aionStore)
    const isCompanyAdmin = userRole.includes("Company Admin") || userRole.includes("Owner") || userRole.includes("Primary Admin")
    const businessSubscriptionPlan = aionStore.businessSubscriptionPlan || {}
    const railActivity = false

    const manageAccounts = getBPAccountsWithAccess("Payments", { permType: "manage", getActiveAccounts: true })

    const NUM_POSTED_TRANSFERS = 10

    const businessCreatedOn = moment(business?.createdOn)
    const daysDiff = businessCreatedOn.diff(moment(), 'days');
    const isWithin30Days = businessCreatedOn ? Math.abs(daysDiff) <= 30 : true

    useEffect(() => {
        getDailyBalances()

        var Accounts = getBPAccountsWithAccess("Transactions", { getActiveAccounts: true })
        Accounts = Accounts || []
        Accounts = Accounts.filter(account => account.accountId) // Filter out empty accounts
        if (Accounts.length > 0) {
            setSelectedAccount(Accounts[0])
            setAccounts(Accounts)
            if (Accounts.length > 1) setAccountMap(sliceIntoChunks(Accounts, 4))
            getPending(Accounts[0])
            // fetchAccounts()
        } else {
            fetchAccounts()
        }

        fetchCounterparties({ pagination: { pageSize: 1000, current: 1 } }, (err, resp) => {
            if (resp && resp.counterpartiesCount) {
                setTotalRecipients(resp.counterpartiesCount)
            }
            // props.dispatch(addDataToStore(BNK_SAVE_DATA, resp || {}))
            setLoadingRecipients(false)
        })

        fetchRecentActivity()

        if (isWithin30Days) {
            fetchCards()
            fetchUsers()
            fetchAllCards()
            fetchScheduledPayments()
        }
    }, [])

    useEffect(() => {
        if (accounts && accounts.length > 0) {
            fetchSubAccounts()
            setAccountsLoading(false)
        }
    }, [accounts])

    const fetchAccounts = () => {
        apiPOSTReq(`${environment.bbBaseUrl}/bb/getAccounts`, { "BankProvider": environment.bankProvider.crb }, null, (err, resp) => {
            try {
                const data = resp;
                if (data.result) {
                    if (err) throw new Error(err);
                    var accounts = data.bbaccounts;
                    props.dispatch(addDataToStore(BNK_SAVE_DATA, { BBAccounts: accounts }))

                    var Accounts = getBPAccountsWithAccess("Transactions", { getActiveAccounts: true })
                    console.log("/bb/getAccounts", Accounts, resp);
                    setSelectedAccount(Accounts[0] || {})
                    setAccounts(Accounts)
                    if (Accounts.length > 1) setAccountMap(sliceIntoChunks(Accounts, 4))
                    getPending(Accounts[0] || {})
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getAccounts", error, err, resp);
                setAccountsLoading(false)
                // const fd = fetchAccounts
                // ErrorAlert({ description: error.message, okText: "Retry", onOk() { fd() } });
            }
        })
    }

    const fetchSubAccounts = () => {
        setSubAccounts([])
        accounts.forEach(account => {

            apiPOSTReq(`${environment.bbBaseUrl}/bb/getSubAccounts`, { "BankProvider": environment.bankProvider.crb }, { "BankProvider": environment.bankProvider.crb, "AccountNumber": account.accountNumber, "BusinessId": aionStore.BusinessUniqueKey }, (err, resp) => {
                try {
                    console.log("/bb/getSubAccounts", err, resp);
                    const data = resp;
                    if (data.result) {
                        if (err) throw new Error(err);
                        console.log('getSubAccounts', data.subAccounts)

                        if (data.subAccounts && data.subAccounts.length > 0) {
                            setSubAccounts(subAccounts.concat(data.subAccounts))
                        }
                    } else {
                        throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                    }
                } catch (error) {
                    console.log("ERRR getSubAccounts", error, err, resp);
                }
            })
        })
    }

    const fetchRecentActivity = (options) => {
        var fetchOptions = {
            // accountId: account.accountId,
            pagination: { pageSize: NUM_POSTED_TRANSFERS, current: 1 }
        }
        fetchOptions.fromDate = moment().subtract(90, 'days').format("YYYY-MM-DD")
        fetchOptions.toDate = moment().format('YYYY-MM-DD')
        fetchOptions.bankProvider = environment.bankProvider.crb
        fetchOptions.providerStatus = "Pending|Posted"
        setLoadingPosted(true)
        console.log('fetchTransactions fetchOptions:', fetchOptions)
        fetchBBTransactions(fetchOptions, (err, resp) => {
            try {
                if (err) throw new Error(err)
                console.log('fetchTransactions :', resp.Transactions)
                setLoadingPosted(false)
                setPostedTransfers(resp.Transactions)
            } catch (error) {
                // ErrorAlert({ description: error.message })
            }
        })
    }

    const getPending = (account) => {
        console.log('getPending accountId', account.accountId)
        // setLoadingPending(true)
        fetchBBHistory({ viewBy: "PENDING_ACTIVITY", accountId: account.accountId }, (err, resp) => {
            if (resp) {
                console.log('getPending resp', resp)
                const normalizedHistory = normalizeAndSortHistory(resp)
                setPendingTransfers(normalizedHistory.normalizedData || [])
            }
            setLoadingPending(false)
        })
    }

    const fetchScheduledPayments = () => {
        fetchBBHistory({ viewBy: "ALL" }, (err, resp) => {
            setOutboundACH(((resp || {}).achTransfersCount || 0) > 0)
        })
    }

    const fetchCards = () => {
        var { UserInfo } = props.aionStore
        UserInfo = UserInfo || {}
        setPersonCardsLoading(true)
        apiPOSTReq(`${environment.bbBaseUrl}/bb/card/getPersonCards`, {}, { "PersonEmail": UserInfo.Email }, (err, resp) => {
            try {
                const data = resp || {}
                console.log('bb/card/getPersonCards', data)
                if (data.result) {
                    var openCards = (data.bbPersonCards || []).filter(card => (card.status !== 'Closed')).sort((b, a) => moment(a.createdAt).toDate() - moment(b.createdAt).toDate())
                    setPersonCardsLoading(false)
                    console.log('setCards(openCards)', openCards)
                    setCards(openCards)
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                // ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            } finally {
                setCardsLoading(false)
            }
        })
    }

    const fetchAllCards = () => {
        var { UserInfo } = props.aionStore
        UserInfo = UserInfo || {}
        apiPOSTReq(`${environment.bbBaseUrl}/bb/card/getPersonCards`, {}, {}, (err, resp) => {
            try {
                const data = resp || {}
                console.log('bb/card/getPersonCards', data)
                if (data.result) {
                    setAllCards(data.bbPersonCards || [])
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                // ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            } finally {
                setAllCardsLoading(false)
            }
        })
    }

    const fetchUsers = () => {
        uaApiPOST("/user/admin/getUsersForBusinessEx", null, (err, resp) => {
            try {
                if (err) throw Error(err)
                if (resp.error) throw Error(resp.error)
                var userData = resp
                setRegisteredUsers(userData.registeredUsers)

                console.log("getUsersForBusiness userData", userData)
            } catch (error) {
                console.log("getUsersForBusiness error", error)
            } finally {
                setUsersLoading(false)
            }
        })
    }

    const accountDetails = (selectedAccountIndex) => {
        props.history.push({
            pathname: '/banking/accounts',
            state: { selectedAccountIndex }
        })
    }

    const downloadAccountVerificationPDF = () => {
        setLoadingAccountVerificationPDF(true)
        var query = {
            AccountId: accounts[0].accountId
        }
        apiGETDocUrl(`${environment.bbBaseUrl}/bb/export/pdf/accountVerificationTemplate`, {}, query, (err, resp) => {
            setLoadingAccountVerificationPDF(false)
            const data = (resp || {}).data
            try {
                if (err) throw Error("We had trouble downloading your account verification. Please try again.")
                const blob = new Blob([data], {
                    type: 'application/pdf',
                })
                const fileURL = URL.createObjectURL(blob)
                saveAs(fileURL, `Bank Account Letter ${moment().format('MMDDYY')}`)
            } catch (error) {
                // ErrorAlert({ description: error.message })
            }
        })
    }

    const getDailyBalances = () => {
        setChartLoading(true)
        const body = {
            "FromDate": moment().subtract(30, 'days').format("YYYY-MM-DD"),
            "ToDate": moment().format('YYYY-MM-DD'),
        }

        apiPOSTReq(`${environment.bbBaseUrl}/bb/dailyBalancesChart`, {}, body, (err, resp) => {
            setChartLoading(false)
            try {
                const data = resp || {}
                console.log('bb/dailyBalancesChart', data)
                if (data.result) {
                    if (data.dailyBalanceChart && Object.keys(data.dailyBalanceChart).length > 0) {
                        setDailyBalanceChart(data.dailyBalanceChart)
                    }
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("bb/dailyBalancesChart err", error, resp)
                // ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    const getTaskCompletedCount = () => {
        var count = 0

        if ((selectedAccount && Number(selectedAccount.currentBalance) > 0) || pendingTransfers?.length > 0) count = count + 1
        if (registeredUsers.length > 1) count = count + 1
        if (allCards && allCards.length > 0) count = count + 1

        return count
    }

    const isChartDataToShow = () => {
        return dailyBalanceChart && dailyBalanceChart[selectedAccount.accountNumber] &&
            dailyBalanceChart[selectedAccount.accountNumber].data &&
            dailyBalanceChart[selectedAccount.accountNumber].data.length > 2 &&
            dailyBalanceChart[selectedAccount.accountNumber].data.find(d => d.data2 !== '0')
    }

    return (
        <FlexColumn className='main-left-padding main-top-padding' style={{ margin: 0 }}>
            <>
                <PageHeader
                    titleText="Banking"
                    subtitle='Banking'
                />

                {
                    isWithin30Days && ['Launch', 'Essentials'].includes(businessSubscriptionPlan.currentPlan) &&
                    (accounts.length > 0 && selectedAccount && !allCardsLoading && !usersLoading && pendingTransfers) && isCompanyAdmin &&
                    !((selectedAccount && Number(selectedAccount.currentBalance) > 0) && registeredUsers.length > 1 && (allCards || []).length > 0) &&
                    <FlexColumn start left fullWidth style={{ paddingRight: 24 }}>
                        <Flex between fullWidth style={{ height: '68px' }}>
                            <FlexColumn centerVertically fullHeight start left>
                                <Text size='20px' weight='500' height='40px'>Getting started with your Aion account</Text>
                                <Text>Here are a few things that can help you get going</Text>
                            </FlexColumn>

                            <Flex start centerHorizontally fullHeight gap='24px'>
                                <Tag>{getTaskCompletedCount()}/3 steps completed</Tag>
                                <TextButton underline text={hideGettingStarted ? 'Show' : 'Hide'} weight='400' onClick={() => {
                                    props.dispatch(addDataToStore(SAVE_DATA, { hideGettingStarted: !hideGettingStarted }))
                                    setHideGettingStarted(!hideGettingStarted)
                                }} />
                            </Flex>
                        </Flex>
                        {
                            !hideGettingStarted &&
                            <Flex start style={{ marginTop: 24 }} fullWidth gap='24px'>
                                {
                                    selectedAccount && !Number(selectedAccount.currentBalance) > 0 && !outboundACH &&
                                    <GettingStartedCard
                                        tabIcon={Dollar}
                                        title='Activate Account'
                                        desc='Now that your account is set up, transfer funds to this account through ACH or Wires.'
                                        buttonText='ADD FUNDS'
                                        buttonIcon={ArrowRight}
                                        onClick={() => setShowMinimumDeposit(true)}
                                        postedTransfers={postedTransfers}
                                    />
                                }
                                {
                                    selectedAccount && !Number(selectedAccount.currentBalance) > 0 && outboundACH &&
                                    <GettingStartedCard
                                        tabIcon={Clock}
                                        title='Activating your account'
                                        desc='We are currently processing your deposit request.'
                                        processing
                                    />
                                }
                                {
                                    !(registeredUsers.length > 1) &&
                                    <GettingStartedCard
                                        tabIcon={User}
                                        title='Add an authorized user'
                                        desc='Share access with your team by adding an additional user to your account.'
                                        buttonText='INVITE USER'
                                        buttonIcon={ArrowRight}
                                        onClick={() => props.history.push('/settings/invite-user')}
                                    />
                                }
                                {
                                    allCards && !(allCards.length > 0) &&
                                    <GettingStartedCard
                                        tabIcon={GSCard}
                                        title='Issue your Aion Debit Card'
                                        desc='Issue your new debit card so you can start making important purchases for your business.'
                                        buttonText='ISSUE DEBIT CARD'
                                        buttonIcon={ArrowRight}
                                        onClick={() => props.history.push({
                                            pathname: '/cards/issue-card',
                                            state: { gettingStarted: true }
                                        })}
                                    />
                                }
                                {
                                    selectedAccount && Number(selectedAccount.currentBalance) > 0 &&
                                    <GettingStartedCard
                                        title='Activate Account'
                                        desc='Now that your account has funds, you can add new recipients and move money!'
                                        completed
                                    />
                                }
                                {
                                    registeredUsers.length > 1 &&
                                    <GettingStartedCard
                                        title='Add an authorized user'
                                        desc='Share access with your team by adding an additional user to your account.'
                                        completed
                                    />
                                }
                                {
                                    allCards && allCards.length > 0 &&
                                    <GettingStartedCard
                                        title='Issue your Aion Debit Card'
                                        desc='Issue your new debit card so you can start making important purchases for your business.'
                                        completed
                                    />
                                }
                            </Flex>
                        }

                        <div style={{ background: '#E3E6EE', width: '100%', height: 1, margin: '24px 0' }} />
                    </FlexColumn>
                }

                <Flex style={{ alignItems: "start", width: '100%' }} center>
                    <FlexColumn between style={{ width: '100%' }}>
                        <Flex className='main-right-padding' between style={{ marginBottom: 24, width: '100%' }}>
                            {
                                accounts.length === 1 ?
                                    <>
                                        {
                                            selectedAccount && Number(selectedAccount.currentBalance) > 0 ?
                                                <CardContainer padding='24px 0 0 0' fullWidth style={{ height: 388, minWidth: 862 }}>
                                                    <FlexColumn start fullHeight>
                                                        <Flex between style={{ width: '100%', padding: '0 24px' }}>
                                                            <FlexColumn start>
                                                                <Text heading>Account</Text>
                                                                <Flex start centerHorizontally>
                                                                    <Text capitalize noWrap size='20px' height='40px'>{selectedAccount.nickName || `Business ${selectedAccount.accountSubType}`} • {selectedAccount.mask}</Text>
                                                                </Flex>
                                                            </FlexColumn>

                                                            <LightContainer
                                                                noBorder
                                                                background='#FBFBFB'
                                                                padding='24px'>
                                                                <Flex fullWidth between>
                                                                    <FlexColumn start>
                                                                        <Text noWrap size='14px' id='availableBalance'>Available balance</Text>
                                                                        <Text size='28px' height='40px' weight='500'>{`$${toCurrency(selectedAccount.availableBalance)}`}</Text>
                                                                    </FlexColumn>

                                                                    <TextButton margin='0 0 0 48px' onClick={() => accountDetails(accounts.indexOf(selectedAccount))} rightIcon={<Image src={ArrowRight} />} text="VIEW DETAILS" />
                                                                </Flex>
                                                            </LightContainer>
                                                        </Flex>
                                                        {
                                                            isChartDataToShow() ?
                                                                <Chart
                                                                    accountId={selectedAccount.accountId}
                                                                    title='Posted Balances'
                                                                    loading={chartLoading}
                                                                    style={{ width: '100%' }}
                                                                    height={220}
                                                                    data={dailyBalanceChart[selectedAccount.accountNumber].data}
                                                                    paddingOffset={48}
                                                                    xLabel='xlabel'
                                                                    yLabel='data2' />
                                                                :
                                                                <FlexColumn fullWidth start style={{ padding: 24 }}>
                                                                    {
                                                                        !chartLoading &&
                                                                        <FlexColumn start style={{ width: '100%', minWidth: 432, margin: '0' }}>
                                                                            <div className='chart-card-getting-started' style={{ height: 200 }}>
                                                                                <Image src={Graph} width='48px' height='48px' margin='18px 0 0' />
                                                                                <Text noWrap heading color='#7384AA' margin='8px 0 8px'>Working on your chart</Text>
                                                                                <Text margin='0 0 24px' size='16px' width='400px' center>We’ll need a few more days of data to help us show you a chart of your daily balances.</Text>
                                                                            </div>
                                                                        </FlexColumn>
                                                                    }
                                                                </FlexColumn>
                                                        }
                                                    </FlexColumn>
                                                </CardContainer>
                                                :
                                                <CardContainer fullWidth style={{ height: 388, minWidth: 862 }}>
                                                    <FlexColumn start>
                                                        <FlexColumn start gap='6px'>
                                                            <Text caption noWrap>Account</Text>
                                                            <Text capitalize noWrap size='20px' height='40px'>{selectedAccount.nickName || `Business ${selectedAccount.accountSubType}`} • {selectedAccount.mask}</Text>
                                                        </FlexColumn>

                                                        <div className='chart-card-getting-started' style={{ height: 240, marginTop: 32, gap: 8 }}>
                                                            {
                                                                outboundACH ?
                                                                    <>
                                                                        <Image src={Clock} width='48px' height='48px' margin='18px 0 0' />
                                                                        <Text noWrap size='20px' caption margin='8px 0 8px'>Processing</Text>
                                                                        <Text width='400px' center>Your balance will be updated once we have processed your request to add funds.</Text>
                                                                    </>
                                                                    :
                                                                    !loadingPending &&
                                                                    <>
                                                                        <Text noWrap size='20px' caption>Activate account</Text>
                                                                        <Text width='400px' center>Initiate a $50 deposit to get started!</Text>

                                                                        <TextButton margin='24px 0 0' onClick={() => setShowMinimumDeposit(true)} rightIcon={<Image src={ArrowRight} />} text="ADD FUNDS" />
                                                                    </>
                                                            }
                                                        </div>
                                                    </FlexColumn>
                                                </CardContainer>
                                        }
                                    </>
                                    :
                                    <CardContainer key={`accountContainer-${selectedAccount && selectedAccount.accountId}`} fullWidth style={{ height: 388, minWidth: 862 }} padding={accountsLoading ? '24px' : '24px 0 24px 24px'}>
                                        <Skeleton loading={accountsLoading} active title={true} paragraph={{ rows: 8 }} style={{ paddingRight: 24 }}>
                                            <Flex between fullWidth>
                                                <FlexColumn style={{ width: 432, minWidth: 432 }}>
                                                    <Flex between fullWidth centerHorizontally>
                                                        <FlexColumn start>
                                                            {/* <Text heading>Accounts</Text> */}
                                                            <Text margin='0 0 24px 0'>Select an account to view details about it</Text>
                                                        </FlexColumn>

                                                        {
                                                            accountMap && accountMap.length > 1 &&
                                                            <Pager
                                                                pages={accountMap.length}
                                                                page={accountsPage}
                                                                next={() => setAccountsPage(accountsPage + 1)}
                                                                previous={() => setAccountsPage(accountsPage - 1)}
                                                            />
                                                        }
                                                    </Flex>

                                                    {
                                                        selectedAccount &&
                                                        <AccountList
                                                            key={`accountList-${selectedAccount && selectedAccount.accountId}`}
                                                            accounts={accountMap[accountsPage]}
                                                            selectedAccount={selectedAccount}
                                                            onSelectAccount={(account) => {
                                                                console.log('onSelectAccount: ', account)
                                                                setSelectedAccount(account)
                                                                // fetchTransactions({ account: account })
                                                            }}
                                                            accountsPage={accountsPage}
                                                        />
                                                    }
                                                </FlexColumn>

                                                {
                                                    selectedAccount && Number(selectedAccount.currentBalance) > 0 ?
                                                        <FlexColumn start style={{ width: '100%', minWidth: 432, paddingLeft: 12 }}>
                                                            {/* <Flex between fullWidth> */}
                                                            <div style={{ margin: '0 24px' }}>
                                                                <LightContainer
                                                                    noBorder
                                                                    background='transparent'
                                                                    padding='0'
                                                                    width='100%'
                                                                    label='Available balance'>
                                                                    <Flex fullWidth between>
                                                                        <FlexColumn start>
                                                                            <Text id='availableBalance'>Available balance</Text>
                                                                            <Text size='28px' height='40px' weight='500'>{`$${toCurrency(selectedAccount.availableBalance)}`}</Text>
                                                                        </FlexColumn>

                                                                        {/* <FlexColumn start right>
                                                                            <TextButton margin='0 0 24px' onClick={() => accountDetails(accounts.indexOf(selectedAccount))} rightIcon={<Image src={ArrowRight} />} text="VIEW DETAILS" />
                                                                        </FlexColumn> */}
                                                                    </Flex>
                                                                </LightContainer>
                                                            </div>
                                                            {
                                                                isChartDataToShow() ?
                                                                    <Chart
                                                                        accountId={selectedAccount.accountId}
                                                                        title='Posted Balances'
                                                                        loading={chartLoading}
                                                                        style={{ width: '100%' }}
                                                                        height={268}
                                                                        data={dailyBalanceChart && dailyBalanceChart[selectedAccount.accountNumber] && dailyBalanceChart[selectedAccount.accountNumber].data}
                                                                        paddingOffset={48}
                                                                        xLabel='xlabel'
                                                                        yLabel='data2' />
                                                                    :
                                                                    <FlexColumn fullWidth start style={{ padding: 24 }}>
                                                                        {
                                                                            !chartLoading &&
                                                                            <div className='chart-card-getting-started' style={{ height: 250 }}>
                                                                                <Image src={Graph} width='48px' height='48px' margin='18px 0 0' />
                                                                                <Text noWrap heading color='#7384AA' margin='8px 0 8px'>Working on your chart</Text>
                                                                                <Text margin='0 0 24px' size='16px' width='400px' center>We’ll need a few more days of data to help us show you a chart of your daily balances.</Text>
                                                                            </div>
                                                                        }
                                                                    </FlexColumn>
                                                            }
                                                        </FlexColumn>
                                                        :
                                                        <>
                                                            {
                                                                selectedAccount && selectedAccount.accountSubType !== 'Transitional' ?
                                                                    <FlexColumn start style={{ width: '100%', minWidth: 432, margin: '0 24px' }}>
                                                                        <div className='add-funds-card' style={{ height: 332 }}>
                                                                            <Text heading capitalize>Add Funds</Text>
                                                                            <Text size='16px'>Add funds to your account to start transacting.</Text>

                                                                            <TextButton margin='24px 0 0' onClick={() => setShowAddFundsModal(true)} rightIcon={<Image src={ArrowRight} />} text="DEPOSIT DETAILS" />
                                                                        </div>
                                                                    </FlexColumn>

                                                                    :
                                                                    <FlexColumn start style={{ width: '100%', minWidth: 432, margin: '0 24px' }}>
                                                                        <div className='add-funds-card' style={{ height: 332 }}>
                                                                            <Flex start centerHorizontally gap='8px'>
                                                                                <Image src={WarningBlue} />
                                                                                <Text heading primary capitalize>Transitional Account</Text>
                                                                            </Flex>
                                                                            <Text size='16px'> Please work with your customers to start paying into your newly opened Aion Accounts Receivable bank account on the platform! Starting in <b>April 2023</b>, we will be reducing support for payments coming into your transitional account. Please reach out to our customer success team in case of any questions.</Text>
                                                                        </div>
                                                                    </FlexColumn>
                                                            }
                                                        </>
                                                }
                                            </Flex>
                                        </Skeleton>
                                    </CardContainer>
                            }

                            {
                                manageAccounts.length > 0 &&
                                <TransactCard onAddFunds={() => setShowAddFundsModal(true)} transitional={selectedAccount && selectedAccount.accountSubType === 'Transitional'} />
                            }
                        </Flex>

                        {
                            // Disabled with the UI KIT updates
                            false && !accountsLoading && cards && cards.length > 0 &&
                            selectedAccount.accountSubType !== 'Transitional' &&
                            <Flex start centerHorizontally style={{ marginBottom: 24, width: '100%' }}>
                                {
                                    selectedAccount && Number(selectedAccount.availableBalance) > 0 ?
                                        <PostedTransactionsCard />
                                        :
                                        <DocumentDownloadCard
                                            width='50%'
                                            title='Bank Account Letter'
                                            description='This letter confirms that you currently have an active bank account with Aion'
                                            onClick={downloadAccountVerificationPDF}
                                            margin='0 12px 0 0'
                                        />
                                }

                                {
                                    cards && cards.length > 0 ?
                                        <FlexColumn centerVertically style={{ minWidth: '50%', height: 200, paddingLeft: 0 }}>
                                            <DebitCardList cards={cards} />
                                        </FlexColumn>
                                        :
                                        <>
                                            {
                                                (userRole.includes("Banking Admin") || userRole.includes("Company Admin") || userRole.includes("Owner") || userRole.includes("Primary Admin")) &&
                                                <CardContainer minWidth='616px' width='50%' height='148px' margin='0 24px 24px 0'>
                                                    <FlexColumn fullWidth>
                                                        <Flex between fullWidth>
                                                            <Text heading>Issue Aion Debit Card</Text>
                                                            <TextButton onClick={() => props.history.push('/cards/issue-card')} text='ISSUE DEBIT CARD' rightIcon={<Image src={ArrowRight} />} />
                                                        </Flex>
                                                        <Text>Issue a new debit card to start making important purchases for your business.</Text>
                                                    </FlexColumn>
                                                </CardContainer>
                                            }
                                        </>
                                }
                            </Flex>
                        }

                        {
                            !accountsLoading && !personCardsLoading && !(cards && cards.length) > 0 &&
                            <>
                                {
                                    (userRole.includes("Banking Admin") || userRole.includes("Company Admin") || userRole.includes("Owner") || userRole.includes("Primary Admin")) &&
                                    <Flex className='main-right-padding' between style={{ marginBottom: 0, width: '100%' }}>
                                        <CardContainer padding='0' minWidth='616px' width='100%' height='170px' margin='0 0 24px 0'>
                                            <FlexColumn className='no-debit-card-back' fullWidth fullHeight style={{ padding: 24 }}>
                                                <Text heading>Issue Aion Debit Card</Text>
                                                <Text>Issue a new debit card so you can start<br />making important purchases for your business.</Text>
                                                <TextButton margin='12px 0' onClick={() => props.history.push('/cards/issue-card')} text='ISSUE DEBIT CARD' rightIcon={<Image src={ArrowRight} />} />
                                            </FlexColumn>
                                        </CardContainer>
                                    </Flex>
                                }
                            </>
                        }

                        {
                            !accountsLoading && selectedAccount.accountSubType !== 'Transitional' &&
                            <>
                                {
                                    selectedAccount && Number(selectedAccount.availableBalance) > 0 &&
                                    <Flex fullWidth className='main-right-padding' start centerHorizontally style={{ marginBottom: 24, width: '100%' }}>
                                        <ActivityTable
                                            showAccount={true} //accounts.length > 1}
                                            tooltip={<FlexColumn start>
                                                <Text color='white' size='14px' margin='0 0'>All transactions shown are based on ET regardless of the time zone in which they were initiated.</Text>
                                            </FlexColumn>}
                                            title='Recent Transactions'
                                            subtitle='10 most recent posted transactions in the last 90 days across all your accounts'
                                            loading={loadingPosted}
                                            data={postedTransfers}
                                            subAccounts={subAccounts}
                                        />
                                    </Flex>
                                }
                            </>
                        }

                        {
                            railActivity &&
                            <Flex className='main-right-padding' start style={{ marginBottom: 24, width: '100%' }}>
                                <CardContainer padding='0' minWidth='616px' width='100%' margin='0 0 24px 0'>
                                    <FlexColumn start fullWidth fullHeight style={{ padding: 24 }}>
                                        <Text heading>Activity</Text>
                                        <ActivityByRail account={selectedAccount} />
                                    </FlexColumn>
                                </CardContainer>
                            </Flex>
                        }
                    </FlexColumn>
                </Flex>
            </>

            <Modal
                wrapClassName='account-name-modal'
                visible={showAccountNameModal}
                footer={null}
                closable={false}
                maskClosable={true}
                width={776}
                destroyOnClose={true}
                onCancel={() => {
                    setShowAccountNameModal(false)
                    setNewAccount(false)
                }}
                closeIcon={<img width='24px' height='24px' src={ModalClose} />}
            >
                <AccountNameModal getStarted={() => {
                    setShowAccountNameModal(false)
                    setNewAccount(false)
                }} account={selectedAccount} onCancel={() => setShowAccountNameModal(false)} />
            </Modal>

            <Modal
                visible={showAddFundsModal}
                footer={null}
                closable={true}
                maskClosable={true}
                width={616}
                destroyOnClose={true}
                onCancel={() => {
                    setShowAddFundsModal(false)
                }}
                closeIcon={<img width='24px' height='24px' src={ModalClose} />}
            >
                <AddFundsModal account={accounts && selectedAccount} onCancel={() => setShowAddFundsModal(false)} />
            </Modal>
            <Modal
                visible={showMinimumDeposit}
                footer={null}
                closable={true}
                maskClosable={true}
                width={500}
                destroyOnClose={true}
                onCancel={() => setShowMinimumDeposit(false)}
                closeIcon={<img width='24px' height='24px' src={ModalClose} />}
            >
                <MinimumDepositModal onCancel={() => {
                    setShowMinimumDeposit(false)
                    window.location.reload()
                }} />
            </Modal>
        </FlexColumn >
    )
}

function mapStateToProps(state) {
    return {
        store: state.bankingAppReducer,
        aionStore: state.aionAppReducer,
        userDefaults: state.userDefaultsReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Dashboard)))