import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import moment from 'moment';

import { Skeleton, Divider, Table, Collapse, Switch, message, Tooltip, Popconfirm } from 'antd';
import { LeftOutlined, WarningFilled, CheckCircleFilled, InfoCircleOutlined } from '@ant-design/icons';

import { FlexColumn, Flex, Container } from '../../Reusable/Container';
import PageHeader from '../../Reusable/PageHeader';
import { ErrorAlert } from '../../Reusable/Alert';
import { Paragraph, Title } from '../../Reusable/Text';
import { Button, TextButton } from '../../Reusable/Refresh/Button';

import environment from '../../../environment';
import { apiPOSTReq } from '../../../Utils/api';
import AdvancedSettings from '../QuickBooksOnline/AdvancedSettings';
import IntegrationSetup from '../Integration/Index';
import { addDataToStore, SAVE_DATA } from '../../../Actions/actions';
import { capitalizeWords } from '../../../Utils/util';
import { Image } from '../../Reusable/Image'
import Disconnect from '../../../Images/disconnect.svg'

const { Panel } = Collapse;

const CollapseHeader = (props) => {
    return <Flex style={{ justifyContent: 'space-between' }}>
        <Flex style={{ alignItems: 'center' }}>
            <div style={{ marginRight: '10px' }}>Sync Summary</div>
            {props.error && <WarningFilled style={{ color: props.theme.colors.warning }} />}
            {!props.error && props.lastSyncedEndDate && <CheckCircleFilled style={{ color: props.theme.colors.secondary1 }} />}
        </Flex>
        {props.error && <div>{props.error && `Unresolved Errors:`} <span style={{ color: props.theme.colors.warning }}>{props.errorNum}</span></div>}
    </Flex>
}

const connectionUI = {
    CONNECTED: 'Connected',
    DISCONNECTED: 'Disconnected',
}

const itemNameDict = {
    "BorrowerCustomers": "Customers",
    "CustomCOA": "Chart of account",
}

const conflictTooltip = "The conflict source indicates the system where the item in error originated. The item in error was unable to be pushed from the conflict source to the other system, and the error will need to be resolved in the conflict souce system. Please see the description for further details on the conflict."

class Index extends Component {
    state = {
        currentViewIndex: 0,
        historyPagination: {
            current: 1,
            pageSize: 50
        },
        errorPagination: {
            current: 1,
            pageSize: 80
        },
        qbErrors: [],
        integrationHistory: [],
        showAdvancedSetting: false,
        syncQB: false,
    };

    componentDidMount() {
        if (this.props.aionStore.hasIntegration) {
            this.fetchIntegrationHistory({ historyPagination: this.state.historyPagination });
            this.fetchQBErrors({ errorPagination: this.state.errorPagination });
            this.fetchIntegration({})
            const { syncJobId } = this.props.aionStore;
            if (syncJobId) {
                this.timer = setInterval(() => this.fetchQBErrors({ errorPagination: this.state.errorPagination }), 5000);
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.aionStore.syncJobId !== prevProps.aionStore.syncJobId) {
            const { syncJobId } = this.props.aionStore;
            if (!syncJobId) {
                clearInterval(this.timer)
                this.timer = null;
            } else {
                if (!this.timer) {
                    this.timer = setInterval(() => this.fetchQBErrors({ errorPagination: this.state.errorPagination }), 5000);
                }
            }
        }
    }

    fetchIntegrationHistory = ({ historyPagination }) => {
        this.setState({ loading: true });
        const body = {
            page: historyPagination.current - 1,
            size: historyPagination.pageSize,
        }
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/getIntegrationHistory`, {}, body, (err, resp) => {
            try {
                this.setState({ loading: false });
                const data = resp;
                console.log("/integration/getIntegrationHistory", data)
                if (data.result) {
                    this.setState({
                        integrationHistory: (data.integrationList || []).map(x => {
                            return {
                                ...x, key: x.id,
                                platformName: x.platformName === 'QUICKBOOKS' ? 'QuickBooks Online' : x.platformName,
                                connectionStatus: connectionUI[x.connectionStatus],
                            }
                        })
                    })

                    if (data.integrationList && data.integrationList.length > 0) {
                        var prev = data.integrationList[0];
                        this.setState({ showReconnect: prev.syncSetupCompleted });
                    }
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getIntegrationHistory", error.stack, resp);
                ErrorAlert({ description: error.message })
            }
        })
    }

    fetchQBErrors = ({ errorPagination }) => {
        const body = {
            page: errorPagination.current - 1,
            size: errorPagination.pageSize,
        }
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/qb/getLastErrorLog`, {}, body, (err, resp) => {
            try {
                const data = resp;
                console.log("/getLastErrorLog", data)
                if (data.result) {
                    if (data.qbErrorLog) {
                        this.setState({
                            qbErrors: (data.qbErrorLog || []).map(x => {
                                return {
                                    ...x, key: x.id,
                                }
                            }) || [],
                            totalError: data.count,
                            lastSyncedEndDate: data.integration ? data.integration.lastSyncedEndDate : null,
                            errorPagination: {
                                ...errorPagination,
                                total: data.count,
                            }
                        })
                    }
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                clearInterval(this.timer)
                this.timer = null;
                console.log("ERRR getLastErrorLog", error.stack, resp);
                ErrorAlert({ description: error.message })
            }
        })
    }

    fetchIntegration = () => {
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/qb/getQBIntegration`, {}, {}, (err, resp) => {
            try {
                const data = resp;
                console.log('/integration/qb/getQBIntegration', data)
                if (data.result) {
                    if (data.integration) {
                        this.setState({
                            integration: data.integration,
                            syncQB: data.integration.shouldSync,
                            disableSyncQB: !data.integration.syncSetupCompleted,
                        })
                        this.props.dispatch(addDataToStore(SAVE_DATA, { CurrentSync: data.integration }));
                    } else {
                        this.setState({ integration: data.integration, syncQB: false, disableSyncQB: true })
                        this.props.dispatch(addDataToStore(SAVE_DATA, { CurrentSync: null }));
                        this.fetchIntegrationHistory({ historyPagination: this.state.historyPagination });
                    }
                    this.setState({ loading: false });
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getActiveCustomers", error.stack, resp);
                ErrorAlert({ description: error.message })
            }
        })
    }

    toggleSync = (sync) => {
        const body = {
            BusinessId: this.props.aionStore.BusinessUniqueKey,
            shouldSync: sync,
        }

        this.setState({ syncQB: !this.state.syncQB });

        apiPOSTReq(`${environment.integrationBaseUrl}/integration/qb/updateSyncSetting`, {}, body, (err, resp) => {
            try {
                const data = resp;
                console.log('/integration/qb/updateSyncSetting', data)
                if (data.result) {
                    if (sync) {
                        message.success("Start syncing QuickBooks");
                    } else {
                        message.success("Stop syncing QuickBooks");
                    }

                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getActiveCustomers", error.stack, resp);
                ErrorAlert({ description: error.message })
            }
        })
    }

    syncNow = () => {
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/qb/syncQuickBooks`, {}, {}, (err, resp) => {
            try {
                this.setState({ loading: false });
                const data = resp;
                console.log("/syncQuickBooks", data);
                if (data.result) {
                    message.success(data.responseMessage);
                    if (data.jobId) {
                        this.props.dispatch(addDataToStore(SAVE_DATA, { syncJobId: data.jobId }));
                    }
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later");
                }
            } catch (error) {
                console.log("ERRR syncQuickBooks", error.stack, resp);
                ErrorAlert({ description: error.message });
            }
        })
        if (!this.timer) {
            this.timer = setInterval(() => this.fetchQBErrors({ errorPagination: this.state.errorPagination }), 5000);
        }
    }

    revokeToken = () => {
        const body = {
            businessId: this.props.aionStore.BusinessUniqueKey
        }

        this.setState({ loading: true });
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/qb/revokeToken`, {}, body, (err, resp) => {
            try {
                if (err) throw Error(err);
                const data = resp || {};
                if (data.result) {
                    message.success("Disconnected Successfully.");
                    if (this.props.aionStore.hasIntegration) {
                        this.fetchIntegrationHistory({ historyPagination: this.state.historyPagination });
                        this.fetchQBErrors({ errorPagination: this.state.errorPagination });
                        this.fetchIntegration({});
                    }

                } else {
                    this.setState({ loading: false });
                    message.error(data.responseMessage);
                    throw Error(data.responseMessage || data.error);
                }
            } catch (error) {
                console.log("/revokeToken err", error, resp);
            }
        });
    }

    componentWillUnmount() {
        clearInterval(this.timer);
        this.timer = null;
    }

    back = () => {
        if (this.state.showAdvancedSetting) {
            this.setState({ showAdvancedSetting: false });
        } else {
            this.props.history.replace('/settings/integration');
        }
    }

    handleTableChange = (pagination) => {
        this.fetchQBErrors({ errorPagination: pagination });
    }

    render() {
        const { theme } = this.props;
        var { loading } = this.state;

        const columns = [
            {
                title: 'Third Party',
                dataIndex: 'platformName',
                key: 'platformName',
            },
            {
                title: 'Connection Status',
                dataIndex: 'connectionStatus',
                key: 'connectionStatus',
                render: (status, item) => {
                    return <span style={{ color: status === 'Connected' ? theme.colors.secondary1 : theme.colors.systemGray }}>{status}</span>
                }
            },
            {
                title: 'Last Synced',
                dataIndex: 'lastSyncedEndDate',
                key: 'lastSyncedEndDate',
                render: (lastSyncedEndDate, item) => {
                    return lastSyncedEndDate ? moment(lastSyncedEndDate).format('MM/DD/YY, h:mm a') : '-'
                }
            },
            {
                title: 'Connected Date',
                dataIndex: 'connectedDate',
                key: 'connectedDate',
                render: (connectedDate, item) => {
                    return connectedDate ? moment(connectedDate).format('MM/DD/YY') : '-'
                }
            },
            {
                title: 'Connected By',
                dataIndex: 'connectedBy',
                key: 'connectedBy',
            },
        ]

        const errorColumns = [
            {
                title: 'Item',
                dataIndex: 'conflictEntity',
                key: 'conflictEntity',
                width: 60,
                render: (object) => {
                    return itemNameDict[object] ? itemNameDict[object] : object
                }
            },
            {
                title: 'Reference ID',
                dataIndex: 'name',
                width: 100,
                key: 'name',
            },
            {
                title: <>
                    <div>Error source
                        <Tooltip title={conflictTooltip}>
                            <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                        </Tooltip>
                    </div>
                </>,
                dataIndex: 'updateSrc',
                key: 'updateSrc',
                width: 80,
                render: src => {
                    return src == "QUICKBOOKS" ? "QuickBooks" : capitalizeWords(src)
                }
            },
            {
                title: 'Description',
                width: 220,
                dataIndex: 'errorMsg',
                key: 'errorMsg',

            },
        ]

        let statusText = "N/A";
        if (this.state.qbErrors && this.state.lastSyncedEndDate) {
            statusText = this.state.qbErrors.length > 0 ? "Synced with errors" : "Successfully completed";
        }

        console.log(2, this.state.integration)
        return (
            <>
                <FlexColumn className='main-padding'>
                    {this.state.integrationHistory.length === 0 && <IntegrationSetup hideKey />}
                    {
                        !this.state.showAdvancedSetting && this.state.integrationHistory.length > 0 &&
                        <FlexColumn>
                            <Skeleton loading={loading} active title={true} paragraph={{ rows: 5 }}>
                                <PageHeader
                                    titleText={"Integration History"}
                                    desc={""}
                                />
                                <Container shadow style={{ marginBottom: 48 }}>
                                    {this.state.integrationHistory && this.state.integrationHistory.length > 0 &&
                                        <Table
                                            dataSource={this.state.integrationHistory}
                                            columns={columns}
                                            pagination={this.state.historyPagination}
                                            size="small"
                                            scroll={{ y: '700px', x: '100%' }}
                                        />}

                                    <Flex style={{ marginTop: '30px', justifyContent: 'space-between', alignItems: 'center' }} >
                                        <Title noMargin level={6}>QuickBooks Online </Title>

                                        <Flex end gap="20px">
                                            {(this.state.integration && this.state.integration.syncSetupCompleted) && <Button noShadow="none" solid onClick={this.syncNow} text='Sync Now' />}

                                            {(!this.state.integration || !this.state.integration.syncSetupCompleted) &&
                                                <div>
                                                    {this.state.showReconnect &&
                                                        <Button noShadow="none" permtype="Override" onClick={() => this.props.history.push('/settings/integration/qb-setup')} text={'Reconnect'}
                                                            rightIcon={<WarningFilled style={{ color: theme.colors.warning, fontSize: '18px' }} />}
                                                        />}
                                                    {!this.state.showReconnect &&
                                                        <Button noShadow="none" permtype="Override" onClick={() => this.props.history.push('/settings/integration/qb-setup')} text={'Complete Sync Setup'}
                                                            rightIcon={<WarningFilled style={{ color: theme.colors.warning, fontSize: '18px' }} />}
                                                        />}
                                                </div>
                                            }

                                            {
                                                (this.state.integration && this.state.integration.syncSetupCompleted) &&
                                                <Popconfirm
                                                    title="Are you sure you want to Disconnect?"
                                                    onConfirm={() => this.revokeToken()}
                                                    okText="Disconnect"
                                                    okButtonProps={{ loading: this.state.deleteLoading }}
                                                    cancelText="Cancel"
                                                >
                                                    <TextButton text='DISCONNECT' rightIcon={<Image src={Disconnect} />} color={"#e82c2c"} />
                                                </Popconfirm>
                                            }
                                        </Flex>
                                    </Flex>
                                    <Flex style={{ justifyContent: 'flex-start', alignItems: 'center' }}>
                                        <Paragraph style={{ width: '20%' }}>Last synced: {this.state.lastSyncedEndDate ? moment(this.state.lastSyncedEndDate).format("MM/DD/YY, h:mm a") : "-"}</Paragraph>
                                    </Flex>

                                    <Divider />

                                    {!this.state.disableSyncQB && <>
                                        <Flex style={{ justifyContent: 'space-between', marginTop: '50px' }}>
                                            <div>
                                                <Paragraph level={3} noMargin>Sync with QuickBooks Online</Paragraph>
                                                <Paragraph level={1} style={{ color: theme.colors.systemGray }}>Enable both real time and automated nightly sync with QuickBooks Online</Paragraph>
                                            </div>
                                            {!this.state.disableSyncQB && <Switch checked={this.state.syncQB} disabled={this.state.disableSyncQB} onChange={() => this.toggleSync(!this.state.syncQB)} />}
                                            {this.state.disableSyncQB && <div>Disabled</div>}
                                        </Flex>
                                        <TextButton style={{ display: 'flex', padding: 0 }} onClick={() => this.setState({ showAdvancedSetting: true })} text='Advanced Sync Preferences' />
                                    </>}
                                </Container>

                            </Skeleton>
                        </FlexColumn>
                    }

                    {
                        !this.state.disableSyncQB && this.state.showAdvancedSetting && this.state.integrationHistory.length > 0 &&
                        <FlexColumn >
                            <PageHeader
                                back
                                onBack={() => {
                                    this.back()
                                }}
                                titleText={"Advanced Sync Preferences"}
                                desc={""}
                            />
                            <AdvancedSettings
                                fetchIntegration={this.fetchIntegration}
                                settings={this.state.integration && this.state.integration.settings}
                                apAccountId={this.state.integration && this.state.integration.apAccountId}
                            />
                        </FlexColumn>
                    }
                </FlexColumn>

            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer,
        bankingStore: state.bankingAppReducer,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Index));