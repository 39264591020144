import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'
import styled from 'styled-components'
import moment from 'moment-business-days'

import { message, Table, Input, InputNumber, Timeline, Switch, Tabs, Drawer, Popover } from 'antd'

// Components
import { Button } from '../../../Reusable/Refresh/Button'
import { Text, Tag } from '../../../Reusable/Text'
import { FlexColumn, Flex, Container } from '../../../Reusable/Container'
import { LabeledInput } from '../../../Reusable/Input'
import { ErrorAlert } from '../../../Reusable/Alert'
import PageHeader from '../../../Reusable/PageHeader'
import Banner from '../../../Reusable/Banner'
import { ConfirmStep } from '../PayFlow'
import environment from '../../../../environment'
import { apiPOSTReq } from '../../../../Utils/api'
import { isBeforeCutOff, toCurrency, getFeatureLimit, dateFormatList } from '../../../../Utils/util'
import { CheckCircleFilled, MailTwoTone, ClockCircleTwoTone, CheckCircleTwoTone, MinusCircleTwoTone, InfoCircleOutlined } from '@ant-design/icons'
import NewContractor from '../NewContractor'


const TotalAmountText = styled.span`
    font-size: 16px;
    font-weight: 400;
`

const TimelineText = styled.span`
    margin-bottom: 5px;
    font-size: 16px;
`

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
})

const { TabPane } = Tabs

class PayContractors extends Component {

    constructor(props) {
        super(props)

        // Update moment to include holidays
        const { aionStore } = this.props
        var { publicHolidays } = aionStore

        moment.updateLocale('us', {
            holidays: publicHolidays,
            holidayFormat: 'YYYY-MM-DD'
        })

        this.state = {
            pagination: this.props.pagination,
            selectedRowKeys: [],
            selectedContractors: [],
            selectedTemplateRowKeys: [],
            selectedTemplate: [],
            payOnOption: "Select Date",
            paymentDate: moment().businessAdd(1),
            flowStep: "selectDate",
            saveTemplate: false,
            templateName: '',
            usingTemplate: false,
            selectedTab: 'contractors',
            showContractorDrawer: false,
            selectedContractor: null,
            selectedContractorRowsPageDict: {},
            selectedContractorRowKeyPageDict: {},
            contractorsLoading: true,
            contractorPagination: {
                current: 1,
                pageSize: 100,
                showSizeChanger: false,
            },
        }
    }

    componentDidMount() {
        this.fetchContractors({ pagination: this.state.contractorPagination })
    }

    fetchContractors = (options) => {
        const { pagination } = options
        const body = {
            "size": pagination.pageSize,
            "page": pagination.current - 1
        }
        apiPOSTReq(`${environment.payBaseUrl}/payables/contractors/getContractors`, {}, body, (err, resp) => {
            try {
                const data = resp
                console.log("/payables/contractors/getContractors", JSON.stringify(data))
                if (data.result) {
                    this.setState({
                        ...data,
                        contractors: data.contractors,
                        contractorPagination: {
                            ...pagination,
                            total: data.count
                        },
                        contractorsLoading: false,
                    })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /payables/contractors/getContractors", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    getSelectedRows = (selectedRowsPageDict) => {
        let selectedRows = []
        Object.values(selectedRowsPageDict).forEach(x => {
            selectedRows = selectedRows.concat(x)
        })
        return selectedRows
    }

    getSelectedRowKeys = (selectedRowKeyPageDict) => {
        let selectedRowKeys = []
        Object.values(selectedRowKeyPageDict).forEach(x => {
            selectedRowKeys = selectedRowKeys.concat(x)
        })
        return selectedRowKeys
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        console.log('selectedRowKeys changed: ', (selectedRows))
        let selectedContractorRowsPageDict = this.state.selectedContractorRowsPageDict
        let selectedContractorRowKeyPageDict = this.state.selectedContractorRowKeyPageDict

        selectedContractorRowsPageDict[this.props.contractorPagination.current] = selectedRows
        selectedContractorRowKeyPageDict[this.props.contractorPagination.current] = selectedRowKeys
        let selectedContractors = this.getSelectedRows(selectedContractorRowsPageDict)
        let newSelectedRowKeys = this.getSelectedRowKeys(selectedContractorRowKeyPageDict)

        this.setState({
            selectedContractorRowsPageDict, selectedContractorRowKeyPageDict,
            selectedRowKeys: newSelectedRowKeys, selectedContractors,
            selectedTemplateRowKeys: [],
            usingTemplate: false,
        })
    }

    onSelectTemplate = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedContractorRowsPageDict: {}, selectedContractorRowKeyPageDict: {},
            selectedTemplateRowKeys: selectedRowKeys,
            selectedRowKeys: [], selectedContractors: [], usingTemplate: true
        })
    }

    continue = () => {
        var { selectedContractors, selectedTemplateRowKeys } = this.state

        if (selectedContractors.length === 0 && selectedTemplateRowKeys.length === 0) {
            ErrorAlert({ description: "Please select at least one contractor." })
            return
        }

        this.fetchTemplateDetail()
        this.setState({ flowStep: "confirm" })
        window.scrollTo(0, 0)
    }

    confirm = () => {
        var { selectedContractors } = this.state
        let totalPay = {}
        const availableToTransfer = {}

        this.props.accounts.forEach(x => {
            availableToTransfer[x.AccountId] = (x.AvailableBalance - this.props.scheduled[x.AccountId]) || 0
            totalPay[x.AccountId] = 0
        })

        for (var i = 0; i < selectedContractors.length; i++) {
            const accountId = selectedContractors[i].fromAccountId
            totalPay[accountId] += (selectedContractors[i].wages || 0) + (selectedContractors[i].reimbursementAmount || 0)
            if ((selectedContractors[i].wages || 0) + (selectedContractors[i].reimbursementAmount || 0) === 0) {
                ErrorAlert({ description: "Please enter either a wage or reimbursement amount for each of the contractors you wish to pay." })
                return
            }
        }

        for (let i = 0; i < this.props.accounts.length; i++) {
            const accountId = this.props.accounts[i].AccountId
            const scheduled = this.props.scheduled[accountId]
            const available = availableToTransfer[accountId]
            const total = totalPay[accountId]
            const mask = this.props.accounts[i].Mask

            if ((total + scheduled) > this.props.dailyLimit) {
                ErrorAlert({ description: `You've hit the daily limit for ach payments. Available amount to make a payment today is $${toCurrency(this.props.dailyLimit)}`, title: "Insufficient Funds" })
                return
            } else if (moment(this.state.paymentDate).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") && total > available) {
                const errDesc = (this.props.scheduled > 0) ?
                    `You do not have enough funds to make this transfer. Available amount to transfer is $${toCurrency(available)}(includes scheduled transfers of $${toCurrency(this.props.scheduled)}) in account ending ${mask}`
                    :
                    `You do not have enough funds to make this transfer. Available amount to transfer is $${toCurrency(available)} in account ending ${mask}`

                ErrorAlert({ description: errDesc, title: "Insufficient Funds" })
                return
            }
        }

        this.setState({ flowStep: "pay" })
    }

    pay = () => {
        const { aionStore } = this.props
        const { UserInfo } = aionStore

        this.setState({ payLoading: true })
        var { selectedContractors, paymentDate, paymentDateStr } = this.state

        var contractorPayments = []
        let contractPaymentTemplateValue = []

        let data = this.state.usingTemplate ? this.state.selectedTemplate : selectedContractors

        data.forEach(contractor => {
            let name = this.state.usingTemplate ? contractor.name : `${contractor.firstName} ${contractor.lastName}`
            let id = this.state.usingTemplate ? contractor.contractorId : contractor.id

            contractorPayments.push({
                "ContractorId": id,
                "TotalAmount": (contractor.wages || 0) + (contractor.reimbursementAmount || 0),
                "PaymentDateStr": moment(paymentDate).format("YYYY-MM-DD"),
                "contextIdentifier": `Payment to contractor: ${name}`,
                "initiatedBy": `${UserInfo.FirstName} ${UserInfo.LastName}`,
                "Memo": contractor.memo,
            })

            if (this.state.saveTemplate) {
                contractPaymentTemplateValue.push({
                    "contractorId": contractor.id,
                    "name": `${contractor.firstName} ${contractor.lastName}`,
                    "wages": contractor.wages || 0,
                    "reimbursementAmount": contractor.reimbursementAmount || 0,
                    "memo": contractor.memo,
                    "createdAt": new Date(),
                    "lastUpdatedAt": new Date(),
                })
            }
        })

        if (this.state.saveTemplate) {
            const templateBody = {
                contractorPaymentTemplate: {
                    "name": this.state.templateName,
                    "items": contractPaymentTemplateValue,
                }
            }

            apiPOSTReq(`${environment.payBaseUrl}/payables/contractors/savePaymentTemplate`, {}, templateBody, (err, resp) => {
                try {
                    const data = resp
                    if (data.result) {
                        message.success(`Template successfully saved!`)
                        this.props.onSave()
                    } else {
                        throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                    }
                } catch (error) {
                    console.log("ERRR /payables/savePaymentTemplate", error, err, resp)
                    ErrorAlert({ description: error.message })
                }
            })
        }

        var body = {
            "contractorPayments": contractorPayments,
            "ruleType": "Contractor",
            "contextIdentifier": `Contractor payment`,
            "initiatedBy": `${UserInfo.FirstName} ${UserInfo.LastName}`,
        }

        console.log("/payables/payContractors", JSON.stringify(body))

        apiPOSTReq(`${environment.payBaseUrl}/payables/payContractors`, {}, body, (err, resp) => {
            try {
                const data = resp
                console.log("/payables/payContractors", JSON.stringify(data))
                if (data.result) {
                    message.success(`Payment successfully scheduled!`)
                    this.setState({ payLoading: false })
                    // this.props.onPay()
                    window.history.back()
                } else {
                    this.setState({ payLoading: false })
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                this.setState({ payLoading: false })
                console.log("ERRR /payables/payContractors", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    getTotal = () => {
        var total = 0

        if (!this.state.usingTemplate) {
            this.state.selectedContractors.forEach(contractor => {
                total = total + (contractor.wages || 0) + (contractor.reimbursementAmount || 0)
            })
        } else {
            if (this.state.selectedTemplate) {
                this.state.selectedTemplate.forEach(contractor => {
                    total = total + (contractor.wages || 0) + (contractor.reimbursementAmount || 0)
                })
            }
        }

        return formatter.format(total)
    }

    disabledDate = (current) => {
        // Can not select days before today and today and weekends / holidays
        return !current.isBusinessDay() || current < moment().add(1, 'd').startOf('day')
    }

    getACHPaymentDate = () => {
        const { paymentDate } = this.state

        if (this.state.payOnOption === "payNow") {
            if (isBeforeCutOff()) {
                return moment(paymentDate)
            } else {
                return moment(paymentDate).businessAdd(1)
            }
        } else {
            return moment(paymentDate)
        }
    }

    fetchTemplateDetail = () => {
        if (!this.state.selectedTemplateRowKeys || this.state.selectedTemplateRowKeys.length === 0) return
        const contractorPaymentTemplate = {
            name: this.state.selectedTemplateRowKeys[0],
        }

        apiPOSTReq(`${environment.payBaseUrl}/payables/contractors/getPaymentTemplate`, {}, { contractorPaymentTemplate }, (err, resp) => {
            try {
                const data = resp
                if (data.contractorPaymentTemplate) {
                    this.setState({
                        templateDetail: data.contractorPaymentTemplate || [],
                        selectedTemplate: data.contractorPaymentTemplate ? data.contractorPaymentTemplate.items : []
                    })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /payables/contractors/getPaymentTemplate", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    onEditContractorFromTemplate = (contractor) => {
        apiPOSTReq(`${environment.payBaseUrl}/payables/contractors/getContractor`, {}, { contractorId: contractor.contractorId }, (err, resp) => {
            try {
                const data = resp
                if (data.contractor) {
                    this.setState({
                        selectedContractor: data.contractor,
                        showContractorDrawer: true,
                    })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /payables/contractors/getContractor", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    handleDateChange = (payOnOption) => {
        if (payOnOption === "Pay Now") {
            var paymentDate = moment()

            if (!isBeforeCutOff()) paymentDate = paymentDate.businessAdd(1)

            this.setState({ payOnOption, paymentDate })
        } else {
            this.setState({ payOnOption, paymentDate: moment().businessAdd(1) })
        }
    }

    render() {
        const { templates, theme, bankingStore } = this.props
        const { selectedRowKeys, pay, selectedContractors, selectedTemplate, confirm, payOnOption, paymentDate, payLoading, flowStep, contractors, contractorsLoading } = this.state

        let businessDailyLimits = bankingStore.BusinessDailyLimits || {}
        var achDailyLimit = businessDailyLimits.ACH || parseFloat(getFeatureLimit("BusinessBanking.Payments.Daily ACH Limit")) || 5000

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            getCheckboxProps: (record) => ({
                disabled: !record.deliveryMode,
            }),
        }

        const templateRowSelection = {
            type: 'radio',
            selectedRowKeys: this.state.selectedTemplateRowKeys,
            onChange: this.onSelectTemplate,
        }

        const columns = [
            {
                title: 'Name',
                dataIndex: 'firstName',
                render: (name, contractor) => `${contractor.firstName} ${contractor.lastName}`,
            },
            {
                title: 'From Account',
                dataIndex: 'fromAccountMask',
                render: (fromAccountMask, contractor) => <Tag style={{ margin: "5px 0", height: '28px' }} tagpreset={theme.colors.secondary4}>{contractor.fromAccountMask && <span className="MICR-font">****{contractor.fromAccountMask}</span>}</Tag>
            },
            {
                title: 'To Account',
                dataIndex: 'deliveryMode',
                render: (deliveryMode, contractor) => deliveryMode ? <Tag style={{ margin: "5px 0", height: '28px' }} tagpreset={theme.colors.secondary4}>{deliveryMode}<CheckCircleFilled /><span className="MICR-font">{contractor.toAccountMask && ` | ****${contractor.toAccountMask}`}</span></Tag> : <Tag style={{ margin: "5px 0", height: '28px' }} tagpreset={theme.colors.secondary4}>None</Tag>
            },
            {
                title: 'Tax ID',
                dataIndex: 'taxId',
            },
            {
                title: 'Email',
                dataIndex: 'primaryEmail',
            },
        ]

        const templateColumns = [
            {
                title: 'Name',
                dataIndex: '',
                render: (name, template) => template,
            },
        ]

        const entryColumns = [
            {
                title: 'Name',
                dataIndex: 'name',
                // width: "25%",
                render: (name, contractor) => this.state.usingTemplate ?
                    <>{name}</>
                    : <>{contractor.firstName} {contractor.lastName}</>,
            },
            {
                title: 'From Account',
                dataIndex: 'fromAccountMask',
                // width: "10%",
                render: (fromAccountMask, contractor) => <Tag style={{ margin: "5px 0", height: '28px' }} tagpreset={theme.colors.secondary4}>{contractor.fromAccountMask && <span className="MICR-font">****{contractor.fromAccountMask}</span>}</Tag>
            },
            {
                title: 'To Account',
                dataIndex: 'deliveryMode',
                // width: "10%",
                render: (deliveryMode, contractor) => deliveryMode ? <Tag style={{ margin: "5px 0", height: '28px' }} tagpreset={theme.colors.secondary4}>{deliveryMode}<CheckCircleFilled /><span className="MICR-font">{contractor.toAccountMask && ` | ****${contractor.toAccountMask}`}</span></Tag> : <Tag style={{ margin: "5px 0", height: '28px' }} tagpreset={theme.colors.secondary4}>None</Tag>
            },
            {
                title: 'Wages',
                // width: "10%",
                render: (name, contractor) => <InputNumber min={0} value={contractor.wages} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} onChange={(value) => { contractor.wages = value; this.setState(selectedContractors) }} type="text" />,
            },
            {
                title: 'Reimbursements',
                // width: "10%",
                render: (name, contractor) => <InputNumber min={0} value={contractor.reimbursementAmount} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} onChange={(value) => { contractor.reimbursementAmount = value; this.setState(selectedContractors) }} type="text" />,
            },
            {
                title: 'Total Amount',
                // width: "10%",
                render: (name, contractor) => <TotalAmountText>{formatter.format((contractor.wages || 0) + (contractor.reimbursementAmount || 0))}</TotalAmountText>,
            },
            {
                title: (
                    <Popover placement='left' content="Add a description here and it will appear on the payment record.">
                        <span>Payment Note </span>
                        <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                    </Popover>
                ),
                // width: "25%",
                render: (name, contractor) => <Input maxLength={80} value={contractor.memo} onChange={(event) => { contractor.memo = event.target.value; this.setState(selectedContractors) }} type="text" />,
            },
        ]


        var content = (<div></div>)

        var contractorWithoutDeliveryMode = false

        (contractors || []).forEach(c => {
            if (!c.deliveryMode) contractorWithoutDeliveryMode = true
        })

        switch (flowStep) {
            case "selectDate":
                content = <>
                    <FlexColumn className='main-padding' style={{ width: '100%' }} start>
                        <PageHeader
                            back
                            backText='Payments'
                            titleText="New contractor payment"
                        />

                        <Container shadow>

                            <FlexColumn>
                                <FlexColumn>
                                    <LabeledInput
                                        label="Pay on"
                                        type='switch'
                                        nomargin
                                        switchNames={["Select Date", "Pay Now"]}
                                        onChange={this.handleDateChange}
                                        value={payOnOption} />
                                </FlexColumn>

                                {
                                    (payOnOption === "Select Date") &&
                                    <div style={{ width: '50%' }}>
                                        <LabeledInput
                                            labelcolor={theme.colors.secondary3}
                                            label="Payment date"
                                            key="paymentDate"
                                            id="paymentDate"
                                            type="date-picker"
                                            format={dateFormatList}
                                            value={paymentDate && moment(paymentDate)}
                                            disabledDate={this.disabledDate}
                                            placeholder={moment().format("MM-DD-YYYY")}
                                            onChange={(date, dateStr) => this.setState({ paymentDateStr: dateStr, paymentDate: date })}
                                            onKeyDown={this.handleOnKeyDown}
                                        />
                                    </div>
                                }

                                <Flex style={{ marginTop: 24 }}>
                                    <FlexColumn left style={{ width: "50%", paddingLeft: 5 }}>
                                        <Text size='18px' style={{ marginBottom: 24 }}>For <b>ACH</b> payments</Text>
                                        {
                                            // TODO: Do we want to add TimeLines for contractor payments requiring approval?
                                            true && //approvalRequired ?
                                            // <Timeline>
                                            //     <Timeline.Item dot={<ExclamationCircleTwoTone style={{ fontSize: '16px' }} twoToneColor="#f81d22" />}>
                                            //         <TimelineText>This payment requires approval</TimelineText>
                                            //     </Timeline.Item>
                                            //     <Timeline.Item dot={<QuestionCircleTwoTone style={{ fontSize: '16px' }} />}>
                                            //         <TimelineText>
                                            //             {
                                            //                 approvalRule && approvalRule.levels.length > 1 ?
                                            //                     approvalRule.levels.map((level, i) => {
                                            //                         return <>{(i > 0) && ', followed by'} <b>{ordinal(i + 1)}</b> level approval {i == 0 && 'is required '}from {this.getApproversText(level.performers, level.type)}</>
                                            //                     })
                                            //                     :
                                            //                     <>This payment requires approval from {this.getApproversText(approvalRule.levels[0].performers, approvalRule.levels[0].type)}</>
                                            //             }
                                            //         </TimelineText>
                                            //     </Timeline.Item>
                                            //     <Timeline.Item dot={<CheckCircleTwoTone style={{ fontSize: '16px' }} twoToneColor="#52c41a" />} color="green">
                                            //         <TimelineText>Once approved your payment will be initiated and will take 1-3 days to reach <b>{vendorName}</b></TimelineText>
                                            //     </Timeline.Item>
                                            // </Timeline>
                                            // :
                                            <div style={{ paddingLeft: 5 }}>
                                                <Timeline>
                                                    <Timeline.Item dot={<ClockCircleTwoTone style={{ fontSize: '16px' }} />}>
                                                        <TimelineText>Payment will be inititiated on <b>{paymentDate.format("ll")}</b></TimelineText>
                                                    </Timeline.Item>
                                                    <Timeline.Item dot={<MinusCircleTwoTone style={{ fontSize: '16px' }} twoToneColor="#eb2f96" />}>
                                                        <TimelineText>Payment will be deducted on <b>{paymentDate.businessAdd(1).format("ll")}</b></TimelineText>
                                                    </Timeline.Item>
                                                    <Timeline.Item dot={<CheckCircleTwoTone style={{ fontSize: '16px' }} twoToneColor="#52c41a" />} color="green">
                                                        <TimelineText>Payment estimated to reach your contractor/s<br />between <b>{paymentDate.businessAdd(1).format("ll")}</b> and <b>{paymentDate.businessAdd(3).format("ll")}</b> as per federal and receiving bank payment settlement timelines</TimelineText>
                                                    </Timeline.Item>
                                                </Timeline>
                                            </div>
                                        }

                                        {
                                            (payOnOption === "Pay Now" && isBeforeCutOff()) &&
                                            <FlexColumn>
                                                <TimelineText>The daily cut-off time for ACH payments is 6:00 PM ET. Payments initiated after this time are processed the next business day.</TimelineText>
                                            </FlexColumn>
                                        }

                                        {
                                            (payOnOption === "Pay Now" && !isBeforeCutOff()) &&
                                            <FlexColumn>
                                                <TimelineText>The daily cut-off time for ACH payments is 6:00 PM ET. Payments initiated after this time are processed the next business day.</TimelineText>
                                            </FlexColumn>
                                        }

                                        <Text size='18px'>
                                            Daily ACH limit: <b style={{ color: theme.colors.creditGreen }}>${toCurrency(achDailyLimit)}</b><br />
                                        </Text>
                                    </FlexColumn>
                                    <FlexColumn left start style={{ width: "50%", paddingLeft: 5 }}>
                                        <Text size='18px' style={{ marginBottom: 24 }}>For <b>Check</b> payments</Text>
                                        {
                                            // TODO: Do we want to add TimeLines for contractor payments requiring approval?x 
                                            true && //approvalRequired ?
                                            // <Timeline>
                                            //     <Timeline.Item dot={<ExclamationCircleTwoTone style={{ fontSize: '16px' }} twoToneColor="#f81d22" />}>
                                            //         <TimelineText>This payment requires approval</TimelineText>
                                            //     </Timeline.Item>
                                            //     <Timeline.Item dot={<QuestionCircleTwoTone style={{ fontSize: '16px' }} />}>
                                            //         <TimelineText>
                                            //             {
                                            //                 approvalRule && approvalRule.levels.length > 1 ?
                                            //                     approvalRule.levels.map((level, i) => {
                                            //                         return <>{(i > 0) && ', followed by'} <b>{ordinal(i + 1)}</b> level approval {i == 0 && 'is required '}from {this.getApproversText(level.performers, level.type)}</>
                                            //                     })
                                            //                     :
                                            //                     <>This payment requires approval from {this.getApproversText(approvalRule.levels[0].performers, approvalRule.levels[0].type)}</>
                                            //             }
                                            //         </TimelineText>
                                            //     </Timeline.Item>
                                            //     <Timeline.Item dot={<CheckCircleTwoTone style={{ fontSize: '16px' }} twoToneColor="#52c41a" />} color="green">
                                            //         <TimelineText>Once approved your payment will be initiated and will take 1-3 days to reach <b>{vendorName}</b></TimelineText>
                                            //     </Timeline.Item>
                                            // </Timeline>
                                            // :
                                            <div style={{ paddingLeft: 5 }}>
                                                <Timeline>
                                                    <Timeline.Item dot={<MailTwoTone style={{ fontSize: '16px' }} />}><TimelineText>A check will be mailed to your contractor/s on <br /><b>{moment(paymentDate).businessAdd(1).format("ll")}</b></TimelineText></Timeline.Item>
                                                    <Timeline.Item dot={<CheckCircleTwoTone style={{ fontSize: '16px' }} twoToneColor="#52c41a" />}><TimelineText>Check estimated to reach your contractor/s<br />around <b>{moment(paymentDate).businessAdd(7).format("ll")} - {moment(paymentDate).businessAdd(9).format("ll")}</b></TimelineText></Timeline.Item>
                                                </Timeline>
                                            </div>
                                        }
                                    </FlexColumn>
                                </Flex>
                            </FlexColumn>

                            <Flex start style={{ width: "100%", marginTop: 48 }}>
                                <Button permtype="Payables.Vendors" solid onClick={() => this.setState({ flowStep: "selectContractors" })} text='Continue' />
                            </Flex>
                        </Container>
                    </FlexColumn>
                </>

                break
            case "selectContractors":
                content = <>
                    <FlexColumn key="selectContractorsLayout" className='main-padding' style={{ width: '100%' }} start>
                        <PageHeader
                            back
                            backText='Back'
                            onBack={() => this.setState({ flowStep: "selectDate" })}
                            titleText="New contractor payment"
                        />


                        <Container shadow style={{ marginBottom: 48 }} padding='12px 24px 24px'>
                            <FlexColumn between>
                                <FlexColumn start>
                                    <Tabs size="large" defaultActiveKey={this.state.selectedTab} onChange={(key) => this.setState({ selectedTab: key })}>
                                        <TabPane tab="Contractors" key="contractors" />
                                        <TabPane tab={"Pay groups"} key="template" />
                                    </Tabs>

                                    {this.state.selectedTab === "contractors" &&
                                        <>
                                            {contractorWithoutDeliveryMode && <Banner style={{ marginTop: 24 }} title={`Default payment method not set`} message="At least one of your contractors does not have a default payment method set. You must have a default payment method set for each contractor before you can pay them." />}
                                            <Table
                                                rowKey="id"
                                                id="contractors-table"
                                                tableLayout='auto'
                                                columns={columns}
                                                dataSource={contractors}
                                                pagination={this.props.contractorPagination}
                                                onChange={this.props.handleContractorTableChange}
                                                style={{ marginTop: 24, width: "100%" }}
                                                rowSelection={rowSelection}
                                                loading={contractorsLoading}
                                                scroll={{ y: '700px', x: '100%' }}
                                            />
                                        </>
                                    }
                                    {this.state.selectedTab === "template" &&
                                        <>
                                            {templates && templates.length > 0 && <Banner style={{ marginTop: 24 }} title={`Contractor pay groups`} message="Select a pay group to auto-populate your payment information from a previously saved pay group." />}
                                            {templates && templates.length > 0 &&
                                                <Table
                                                    rowKey={row => row}
                                                    id="templates-table"
                                                    tableLayout='auto'
                                                    columns={templateColumns}
                                                    dataSource={templates}
                                                    pagination={this.props.payGroupPagination}
                                                    onChange={this.props.handlePayGroupTableChange}
                                                    style={{ marginTop: 24, width: "100%" }}
                                                    rowSelection={templateRowSelection}
                                                    scroll={{ y: '700px', x: '100%' }}
                                                />}
                                        </>
                                    }
                                </FlexColumn>
                            </FlexColumn>

                            <Flex start style={{ width: "100%", marginTop: 24 }}>
                                <Button permtype="Payables.Vendors" solid onClick={this.continue} text='Continue' />
                            </Flex>
                        </Container>
                    </FlexColumn>
                </>

                break
            case "confirm":
                content =
                    <FlexColumn className='main-padding'>
                        <ConfirmStep
                            theme={theme}
                            selectedContractors={selectedContractors}
                            selectedTemplate={selectedTemplate}
                            usingTemplate={this.state.usingTemplate}
                            entryColumns={entryColumns}
                            getTotal={this.getTotal}
                            confirm={this.confirm}
                            confirmText={"Confirm"}
                            onClick={() => this.setState({ flowStep: "selectContractors" })}
                            showAvailableAmount={moment(this.state.paymentDate).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") ? true : false}
                            showDailyLimit={true}
                            dailyLimit={this.props.dailyLimit}
                            accounts={this.props.accounts}
                            scheduled={this.props.scheduled}
                            accountIds={!this.state.usingTemplate ? selectedContractors.map(x => x.fromAccountId) : selectedTemplate.map(x => x.fromAccountId)}
                        />
                    </FlexColumn>
                break
            case "pay":
                let contractorLength = this.state.usingTemplate ? selectedTemplate.length : selectedContractors.length
                content = <>
                    <FlexColumn key="payLayout" className='main-padding' style={{ width: '100%' }} start>
                        <PageHeader
                            back
                            backText='Back'
                            onBack={() => this.setState({ flowStep: "selectDate" })}
                            titleText="New contractor payment"
                        />

                        <Container shadow style={{ marginBottom: 48 }}>
                            <FlexColumn start>
                                <Text size='20px'>You're about to pay <b style={{ color: theme.colors.creditGreen }}>{contractorLength}</b> {contractorLength > 1 ? "contractors" : "contractor"} <b style={{ color: theme.colors.creditGreen }}>{this.getTotal()}</b></Text>
                                {/* <Text margin='8px 0 12px' light>Please verify the information below and click submit</Text> */}
                                <Text margin="10px 0 12px 0">Please verify the information below before submitting</Text>

                                <FlexColumn between>
                                    <FlexColumn>
                                        <Flex between style={{ width: '100%' }}>
                                            <FlexColumn style={{ width: '50%', marginRight: 12 }}>
                                                <LabeledInput type='read-only' key="contractors" label="Total Contractors" value={contractorLength} />
                                                <LabeledInput type='read-only' key="payment" label="Total Payment" value={this.getTotal()} />
                                            </FlexColumn>
                                            <FlexColumn style={{ width: '50%', marginLeft: 12 }}>
                                                <LabeledInput type='read-only' style={{ width: "100%" }} key="date" label="Payment date" value={moment(paymentDate).format("ll")} />
                                                <LabeledInput type='read-only' key="fee" label="Transaction fee" value={"$0"} />
                                            </FlexColumn>
                                        </Flex>

                                        {!this.state.usingTemplate &&
                                            <FlexColumn left style={{ width: '50%' }}>
                                                <Flex centerHorizontally style={{ marginBottom: "10px", marginTop: 24 }}>
                                                    <span style={{ fontSize: "14px", color: theme.colors.systemGray, marginRight: '8px' }}>Save this pay group for future payments?</span>
                                                    <Switch size="small" defaultChecked={this.state.saveTemplate} onChange={() => this.setState({ saveTemplate: !this.state.saveTemplate })} />
                                                </Flex>
                                                {this.state.saveTemplate &&
                                                    <LabeledInput
                                                        label="Name"
                                                        labelcolor={theme.colors.secondary3}
                                                        id="templateName"
                                                        key="templateName"
                                                        value={this.state.templateName}
                                                        placeholder="Enter a name for the group"
                                                        onChange={(e) => this.setState({ [e.target.id]: e.target.value })}
                                                    />
                                                }
                                            </FlexColumn>
                                        }
                                    </FlexColumn>

                                    <Flex start style={{ width: "100%", marginTop: 24 }}>
                                        <Button permtype="Payables.Vendors"
                                            loading={payLoading}
                                            solid
                                            onClick={this.pay}
                                            disabled={this.state.saveTemplate && !this.state.templateName}
                                            text='Submit' />
                                    </Flex>
                                </FlexColumn>
                            </FlexColumn>
                        </Container>
                    </FlexColumn>
                </>

                break
            default:
                break
        }

        return (
            <>
                {content}
                {
                    this.state.showContractorDrawer &&
                    <Drawer placement="right" width={1000} onClose={() => this.setState({ showContractorDrawer: false })} visible={this.state.showContractorDrawer}>
                        <NewContractor
                            key={this.state.selectedContractor ? this.state.selectedContractor.id : moment().format("HH:ss")}
                            editcontractor={this.state.selectedContractor}
                            contractor={this.state.selectedContractor}
                            submitComplete={
                                () => {
                                    this.setState({ showContractorDrawer: false, selectedContractor: null })
                                }
                            }
                        />
                    </Drawer>
                }
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        bkStore: state.bookkeepingAppReducer,
        aionStore: state.aionAppReducer,
        bankingStore: state.bankingAppReducer,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(PayContractors))