import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter, useLocation } from 'react-router-dom'
import { withTheme } from 'styled-components'

import { Modal, Tabs } from 'antd'
import { CardContainer, FlexColumn } from '../../Reusable/Container'
import PageHeader from '../../Reusable/PageHeader'
import { fetchBBHistory, fetchBBTransactions, normalizeAndSortHistory } from '../../../Utils/bankingDataManager'
import { Text } from '../../Reusable/Text'
import { ErrorAlert } from '../../Reusable/Alert'
import PendingTable from '../Reusable/PendingTable'
import ActivityTable from '../Reusable/ActivityTable'
import { addDataToStore, BNK_SAVE_DATA } from '../../../Actions/actions'

// Images
import AccountNameModal from '../Reusable/AccountNameModal'
import ModalClose from '../../../Images/modal-close.png'

const { TabPane } = Tabs

const ACHActivity = (props) => {
    const [account, setAccount] = useState()
    const [accountIndex, setAccountIndex] = useState(0)
    const [type, setType] = useState()
    const [paymentStatus, setPaymentStatus] = useState([])
    const [pendingTransfers, setPendingTransfers] = useState([])
    const [postedTransfers, setPostedTransfers] = useState([])
    const [showAccountNameModal, setShowAccountNameModal] = useState(false)
    const [loadingPending, setLoadingPending] = useState(true)
    const [loadingPosted, setLoadingPosted] = useState(true)
    const [moreToLoad, setMoreToLoad] = useState(false)
    const [currentOptions, setCurrentOptions] = useState({})

    const location = useLocation()

    useEffect(() => {
        if (location.state && location.state.account) {
            console.log('MoreTransactions', location.state.account, location.state.type)
            setAccount(location.state.account)
            setAccountIndex(location.state.accountIndex)
            setType(location.state.type)
            setPaymentStatus(location.state.type === 'Canceled' ? ['canceled'] : location.state.type === 'Rejected' ? ['Rejected', 'denied_approval'] : location.state.type === 'Errored' ? ['error'] : [])
        }

        // getPending({ pagination: { pageSize: 10, current: 1 } })
        // fetchTransactions({ pagination: { pageSize: 10, current: 1 } })
    }, [])

    useEffect(() => {
        console.log('MoreTransactions account', account)
        if (account) {
            getPending({ pagination: { pageSize: 10, current: 1 } })
            fetchTransactions({ pagination: { pageSize: 10, current: 1 } })
        }
    }, [account])


    const getPending = (options) => {
        setLoadingPending(true)
        fetchBBHistory({
            viewBy: "PENDING_ACTIVITY",
            accountId: account.accountId,
            // pagination: options.pagination,
        }, (err, resp) => {
            if (resp) {
                console.log('getPending ACHActivity: resp', resp)
                const normalizedHistory = normalizeAndSortHistory(resp)
                setPendingTransfers(normalizedHistory.normalizedData.filter(transaction => transaction.type === 'ACH'))
            }
            setLoadingPending(false)
        })
    }

    const fetchTransactions = (options) => {
        var { fromDate, toDate, searchStr, fromAmt, toAmt, railList, viewBy } = options

        if (account) {
            var fetchOptions = {
                accountId: account.accountId,
                pagination: options.pagination, // || pagination
                railList: ['Card'],
            }

            console.log('fetchTransactions fetchOptions1:', fetchOptions)

            if (searchStr && searchStr != '') fetchOptions.searchStr = searchStr
            if (fromDate) fetchOptions.fromDate = fromDate
            if (toDate) fetchOptions.toDate = toDate
            if (fromAmt) fetchOptions.fromAmt = fromAmt
            if (toAmt) fetchOptions.toAmt = toAmt
            if (railList) fetchOptions.railList = railList
            if (viewBy && searchStr && searchStr != '') fetchOptions.viewBy = viewBy

            fetchOptions.bankProvider = "CrossRiverBank"

            fetchOptions.providerStatus = "Posted"
            setLoadingPosted(true)

            setCurrentOptions(fetchOptions)
            fetchBBTransactions(fetchOptions, (err, resp) => {
                try {
                    if (err) throw new Error(err)
                    console.log("fetchData fetchBBTransactions fetchOptions", fetchOptions)
                    console.log("fetchData fetchBBTransactions resp", resp)

                    const newData = fetchOptions.pagination.current > 1 ? postedTransfers.concat(resp.Transactions) : resp.Transactions

                    setMoreToLoad(newData.length !== resp.transactionsCount)
                    setPostedTransfers(newData)

                    if (!searchStr) {
                        props.dispatch(addDataToStore(BNK_SAVE_DATA, resp || {}))
                    }
                } catch (error) {
                    ErrorAlert({ description: error.message })
                } finally {
                    setLoadingPosted(false)
                }
            })
        }
    }

    const loadMoreActivity = () => {
        // setPagination({ pageSize: 10, current: pagination.current + 1 })
        currentOptions.pagination.current = currentOptions.pagination.current + 1
        fetchTransactions(currentOptions) // { account: accounts[selectedAccount], pagination: { pageSize: 10, current: pagination.current + 1 } })
    }

    return (
        <FlexColumn className='main-padding' start>
            <PageHeader
                titleText='Debit Card Activity'
                back
                onBack={() => window.history.back()}
            />

            <FlexColumn between style={{ width: '100%', paddingBottom: 24 }}>
                <CardContainer padding='12px 24px 24px'>
                    <Tabs size="large">
                        <TabPane tab="Pending" key="Pending">
                            <PendingTable
                                hideType
                                showAccount
                                account={account}
                                noTitle={true}
                                collapsible={false}
                                padding='0'
                                loadingPending={loadingPending}
                                pendingTransfers={pendingTransfers} />
                        </TabPane>
                        <TabPane tab="Posted" key="Posted">
                            <ActivityTable
                                filter
                                // more
                                download
                                runningBalance
                                tooltip={<FlexColumn start>
                                    <Text color='white' size='14px'>All transactions shown are based on ET regardless of<br />the time zone in which they were initiated.</Text>
                                </FlexColumn>}
                                // searchAndFilterTitle='Posted Transactions'
                                account={account}
                                accountIndex={0}
                                fetchTransactions={fetchTransactions}
                                moreToLoad={moreToLoad}
                                onLoadMore={() => loadMoreActivity()}
                                padding='12px 0 0'
                                loading={loadingPosted}
                                data={postedTransfers} />
                        </TabPane>
                    </Tabs>
                </CardContainer>
            </FlexColumn>

            <Modal
                wrapClassName='account-name-modal'
                visible={showAccountNameModal}
                footer={null}
                closable={false}
                maskClosable={true}
                width={776}
                destroyOnClose={true}
                onCancel={() => setShowAccountNameModal(false)}
                closeIcon={<img width='24px' height='24px' src={ModalClose} />}
            >
                <AccountNameModal onCancel={() => setShowAccountNameModal(false)} />
            </Modal>
        </FlexColumn>
    )
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer,
        bankingStore: state.bankingAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(ACHActivity)))