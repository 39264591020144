import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import moment from 'moment'
import { Modal, Drawer, Select, message } from 'antd'

import _ from 'lodash'
import { Text, Tag } from '../../Reusable/Text'
import { Image } from '../../Reusable/Image';
import { Flex, FlexColumn } from '../../Reusable/Container'
import { Button, ImageButton, TextButton } from '../../Reusable/Button'
import { LabeledInput } from '../../Reusable/Input'
import { ErrorAlert } from '../../Reusable/Alert'
import DeleteModal from '../../Reusable/DeleteModal'
import { getDateInLocalTZ, getUserApps, toCurrency } from '../../../Utils/util'
import { apiPOSTReq } from '../../../Utils/api'
import environment from '../../../environment'
import { ExpenseCategory } from '../../Reusable/ExpenseCategory';

import Dot from '../../../Images/dot-grey.png'
import ModalClose from '../../../Images/modal-close.png'
import ChevronUp from '../../../Images/chevron-up.png'
import ChevronDown from '../../../Images/chevron-down.png'
import MailReceipt from '../../../Images/mail-receipt.png'


const dFormat = "ll; h:mm A z"
const etTimeZone = "America/New_York"
const dateFormat = 'MMM D, YYYY'

class PaymentDrawer extends Component {

    constructor(props) {
        super(props)

        var ABLUser = false

        const userApps = getUserApps(this.props.aionStore)

        if (userApps && userApps.includes("ABLCredit")) {
            ABLUser = true
        }

        this.state = {
            documentsLoading: true,
            msasLoading: true,
            showMSAModal: false,
            pagination: {
                current: 1,
                pageSize: 100,
                showSizeChanger: false,
            },
            sorter: {},
            ABLUser: ABLUser,
            dashboardLoading: true,
            loading: false,
            selectedTab: 'details',
            transferMethods: [],
            fileList: [],
            name: props.msa ? props.msa.name : null,
            contractValue: props.msa ? props.msa.contractValue : null,
            product: this.props.product || {},
        }

        this.customer = this.props.location.state && this.props.location.state.customer

        this.amountRef = React.createRef()
        this.dateRef = React.createRef()
        this.refRef = React.createRef()
    }

    addInvoicePayment = () => {
        const userInfo = this.props.aionStore?.userInfo;

        const { docNumber, invoiceId, onSave, balance } = this.props;
        var { paymentDate, amount, reference } = this.state

        if (!amount) {
            this.setState({ errorField: 'amount', errorMessage: "This is a mandatory field" });
            this.amountRef.current.focus()
            return;
        }

        if (Number(amount) > Number(balance)) {
            this.setState({ errorField: 'amount', errorMessage: `Amount can't be greater than the balance of $ ${toCurrency(balance)}` });
            this.amountRef.current.focus()
            return;
        }

        if (!paymentDate) {
            this.setState({ errorField: 'paymentDate' });
            this.dateRef.current.focus()
            return;
        }

        var body = {
            invPayment: {
                businessId: this.props.aionStore.businessId,
                invoiceId,
                paymentDate,
                amount,
                privateNote: reference,
            },
            docNumber,
            modifiedByUserName: `${userInfo.firstName} ${userInfo.lastName}`,
            invPaymentDate: getDateInLocalTZ(paymentDate)
        }

        console.log("Invoices invoice/saveInvoicePayments body", body)

        this.setState({ loadingInvoicePayments: true })

        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/saveInvoicePayments`, null, body, (err, resp) => {
            try {
                const data = resp || {}
                if (data.result) {
                    console.log("Invoices invoice/saveInvoicePayments data", data)
                    onSave(Number(balance) - Number(amount))
                }
                else throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
            } catch (error) {
                console.log("ERRR saveInvoicePayments", error, err, resp)
                ErrorAlert({ description: error.message })
            } finally {
                this.setState({ loadingInvoicePayments: false })
            }
        })
    }

    disabledDate = (current) => {
        // Can not select days before today and today and weekends / holidays
        return current > moment().add(1, 'd').startOf('day')
    }

    render() {
        const { docNumber } = this.props;
        var { loadingInvoicePayments, errorField, paymentDate, amount, errorField, errorMessage, reference } = this.state
        return (
            <FlexColumn start gap="24px" fullHeight style={{ paddingBottom: 0, marginBottom: 0 }}>
                <Text heading>Add a payment you received<br />for invoice # {docNumber}</Text>

                <LabeledInput
                    inputRef={this.amountRef}
                    nomargin
                    autoFocus
                    prefixText="$"
                    type="number"
                    label="Amount received"
                    id="amount"
                    key="amount"
                    placeholder={"E.g. 100,000"}
                    // min={0}
                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    value={amount}
                    onChange={(value) => {
                        this.setState({ amount: value })
                    }}
                    error={errorField === "amount"}
                    errorMessage={errorMessage}
                />

                <LabeledInput
                    inputRef={this.dateRef}
                    nomargin
                    label="Payment date"
                    id="paymentDate"
                    key="paymentDate"
                    type="date-picker"
                    placeholder={moment().format(dateFormat)}
                    value={paymentDate}
                    format={dateFormat}
                    onChange={(date, dateString) => { this.setState({ paymentDate: date }) }}
                    error={errorField === "paymentDate"}
                    errorMessage={"This is a mandatory field"}
                    disabledDate={this.disabledDate}
                />

                <LabeledInput
                    inputRef={this.refRef}
                    nomargin
                    label="Reference"
                    id="reference"
                    key="reference"
                    placeholder="Enter a reference number or notes"
                    onChange={(event) => this.setState({ reference: event.target.value })}
                    value={reference}
                    error={errorField === "reference"}
                    errorMessage={"This is a mandatory field"}
                    optional
                />

                <FlexColumn end grow>
                    <Flex start gap='24px' style={{ paddingBottom: 0 }} centerHorizontally>
                        <Button onClick={this.addInvoicePayment} solid text={'ADD'} loading={loadingInvoicePayments} />
                        {/* {
                            (msa || po || document) &&
                            <ImageButton onClick={() => this.setState({ showDelete: true })} src={Delete} />
                        } */}
                    </Flex>
                </FlexColumn>
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(PaymentDrawer)))