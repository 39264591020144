import React, { Component } from 'react';
import moment from 'moment';
import { withTheme } from 'styled-components';
import { saveAs } from 'file-saver'

import {
    ConfigProvider,
    Empty,
    Skeleton,
    Table,
} from 'antd';

import { PaperClipOutlined } from '@ant-design/icons';

import { Flex, FlexColumn } from '../../Reusable/Container';
import { Text, Tag } from '../../Reusable/Text';
import { ErrorAlert } from '../../Reusable/Alert'

import { getPreSignedS3Url } from '../../../Utils/api'
import TableView from '../../Reusable/TableView';

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const customizeRenderEmpty = () => (
    <Empty
        imageStyle={{
            height: 60,
        }}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={
            <span>
                No Advances
            </span>
        }>
    </Empty>
)

const downloadAttachment = (url, name) => {
    getPreSignedS3Url({ url: url }, (err, preSignedUrl) => {
        if (!err) {
            saveAs(preSignedUrl, name)
        } else {
            ErrorAlert({ description: JSON.stringify(err) })
        }
    })
}

const getColumns = (props) => {
    const { theme } = props;

    var columns = [
        {
            title: 'Update Date',
            dataIndex: 'uploadDate',
            render: (date) => {
                if (date) {
                    date = new Date(date).toISOString();
                    date = date.split("T")[0];
                } else {
                    return '--'
                }
                return (moment(date).format('ll'))
            },
        },
    ]

    if (props.userApps.includes("ABLCredit")) {
        columns = columns.concat(
            {
                title: 'As of Date',
                dataIndex: 'inventoryAsOf',
                render: (date) => {
                    if (date) {
                        date = new Date(date).toISOString();
                        date = date.split("T")[0];
                    } else {
                        return '--'
                    }
                    return (moment(date).format('ll'))
                },
            },
            {
                title: 'Gross Inventory',
                dataIndex: 'grossInventory',
                render: (item) => (formatter.format(item)),
            },
            {
                title: 'Ineligible Inventory',
                dataIndex: 'ineligibleInventory',
                render: (item) => (formatter.format(item)),
            },
        )
    }

    columns = columns.concat(
        {
            title: 'Updated and Certified  by',
            dataIndex: 'certifiedBy',
        },
    )

    if (props.userApps.includes("ABLCredit")) {
        columns = columns.concat(
            {
                title: 'Status',
                dataIndex: 'status',
                render: (status, info) => {
                    if (status === "PendingUpload") {
                        return <Tag tagpreset={"#EC3F25"}>{"Pending Upload"}</Tag>
                    } else if (status === "InReview") {
                        return <Tag tagpreset={theme.colors.secondary4}>{"In Review"}</Tag>
                    } else {
                        return <Tag tagpreset={theme.colors.secondary4}>{status}</Tag>
                    }
                },
            }
        )
    }

    if (props.userApps.includes("ARCredit")) {
        columns = columns.concat(
            {
                title: '',
                dataIndex: 'action',
                align: 'center',
                render: (text, inventory) => {
                    return inventory.inventoryFileURL && inventory.inventoryFileURL.uri && <a onClick={() => downloadAttachment(inventory.inventoryFileURL.uri, inventory.inventoryFileURL.fileName)}><PaperClipOutlined style={{ fontSize: 18 }} /></a>
                },
            }
        )
    }


    return columns
}

const getRowDetails = (record, props) => {
    const { theme } = props

    return (
        <Flex style={{ padding: "20px", background: theme.body, justifyContent: "space-between", alignItems: 'flex-start' }}>
            <FlexColumn>
                <div style={{ fontWeight: 'bold', fontSize: '16px', marginBottom: '10px' }}>Reviewer Notes:</div>
                <div>{record.notes ? record.notes : ''}</div>
            </FlexColumn>
        </Flex>
    )
}

function InventoryTable(props) {
    var items = props.data || [];
    items.sort(function (a, b) {
        return moment(b.AdvanceDate).toDate() - moment(a.AdvanceDate).toDate();
    });
    return (
        <FlexColumn>
            {/* <Text size='20px' margin='0 0 24px 0'>History</Text> */}

            <ConfigProvider renderEmpty={customizeRenderEmpty}>
                <div className="config-provider">
                    <Skeleton loading={!props.inventoryLoaded} active title={false} paragraph={{ rows: 4 }}>
                        <TableView
                            id='advances-table'
                            titleText='History'
                            rowKey='id'
                            tableLayout='auto'
                            columns={getColumns(props)}
                            dataSource={items}
                            // onRow={(record) => record.notes ? ({
                            //     onClick: () => {
                            //         props.selectRow(record);
                            //     },
                            // }) : {}}
                            expandable={!props.disableExpandable && {
                                expandedRowRender: record => getRowDetails(record, props),
                                expandRowByClick: true,
                                expandIconColumnIndex: -1,
                                columnWidth: "2px",
                                indentSize: "20px",
                                rowExpandable: record => record.notes
                            }}
                            titleText={props.titleText}
                            ctaContent={props.ctaContent}
                        />
                    </Skeleton>
                </div>
            </ConfigProvider>
        </FlexColumn>
    )
}

export default withTheme(InventoryTable);