import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import styled from 'styled-components';
import moment from 'moment';

import {
    Affix, Select, Table, Input, InputNumber, message,
} from 'antd';

// Components
import { Button, TextButton } from '../../Reusable/Button';
import { Paragraph, Title } from '../../Reusable/Text';
import { FlexColumn, Flex } from '../../Reusable/Container';
import { ErrorAlert } from '../../Reusable/Alert';
import { LabeledInput, InputConfirmation } from '../../Reusable/Input';
import { apiPOST, apiGET } from '../../../Utils/api';
import { LeftOutlined } from '@ant-design/icons';

const { Option } = Select;

export const CardButton = styled.div`
    margin-top: 18px;
    background-color: rgb(255, 255, 255);
    border-radius: 0.5rem;
    width: 400px;
    height: 200px
    margin-bottom: 1.5rem;
    box-sizing: border-box;
    display: flex;
    align-items: center
    opacity: 1;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    padding: 1.8rem;
    transition-property: box-shadow;
    transition-duration: 300ms;
    &:hover {
        cursor: pointer;
        box-shadow: 0 3px 15px rgb(0 0 0 / 0.4);
    }
`

export const CardButtonText = styled.span`
    font-size: 18px;
    font-weight: 400;
`

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

class ReconcileModal extends Component {

    state = {
        loading: false,
        flowStep: "selectCustomer",
        invoices: [],
        reconcileLoading: false,
        continueDisabled: true,
        continueLoading: false,
    }

    handleCustomerSelect = (value, option) => {
        const { customers } = this.props

        this.fetchCustomerInvoices(value)

        var customer = customers.find(customer => customer.customerId === value)

        this.setState({ continueLoading: true, customer })
    }

    continue = () => {
        var { customer } = this.state

        // if (selectedContractors.length === 0) {
        //     ErrorAlert({ description: "Please select at least one contractor." });
        //     return;
        // }

        if (customer) {
            this.setState({ flowStep: "selectInvoice" })
            this.props.onWidthChange(1000)
        }
    }

    confirm = () => {
        if (this.isValid() === true) {
            this.props.onWidthChange(700)
            this.setState({ flowStep: "reconcile" })
        } else {
            ErrorAlert({ description: this.isValid() })
        }
    }

    reconcile = () => {
        const { invoices } = this.state;
        const { txnRecord } = this.props;
        this.setState({ reconcileLoading: true })

        // const body = {
        //     "BusinessId": this.props.aionStore.BusinessUniqueKey,
        //     "size": pagination.pageSize,
        //     "page": pagination.current - 1
        // }

        var reconcileBody = []
        var reconcileAndSyncBody = []

        invoices.forEach(invoice => {

            if (invoice.paymentAmount && invoice.paymentAmount > 0) {
                console.log("/receivables/reconcile body invoice", JSON.stringify(invoice))

                var body = {
                    "payment": invoice.paymentAmount,
                    "invoiceId": invoice.InvoiceId,
                    "invoiceAmount": invoice.TotalAmt,
                    "invoiceBalance": invoice.Balance,
                    "docNumber": invoice.DocNumber,
                    "paymentDate": this.props.paymentDate,
                    "privateNote": invoice.note || "",
                    "processed": false,
                    "bbTransactionsId": txnRecord.objectId,
                    "transactionId": txnRecord.id,
                    "customerId": invoice.CustomerId,
                    "accountId": txnRecord.accountId,
                    "amount": txnRecord.amount,
                    "date": txnRecord.date,
                    "txnDate": txnRecord.date,
                    "name": txnRecord.name,
                    "desc": txnRecord.desc,
                    "displayDescription": txnRecord.displayDescription,
                    "summary": txnRecord.summary
                }
                if (invoice.Source === "AION") {
                    reconcileBody.push(
                        body
                    )
                }

                if (invoice.Source === "QUICKBOOKS") {
                    reconcileAndSyncBody.push(
                        body
                    )
                }
            }
        })

        console.log("/receivables/reconcile body txnRecord", JSON.stringify(txnRecord))

        if (reconcileBody.length > 0) {
            console.log("/receivables/reconcile body", JSON.stringify(reconcileBody))

            apiPOST("/receivables/reconcile", { businesskey: this.props.aionStore.BusinessUniqueKey }, reconcileBody, (err, resp) => {
                try {
                    const data = resp;
                    console.log("/receivables/reconcile", data)

                    this.setState({ reconcileLoading: false })

                    if (data) {
                        message.success(`${reconcileBody.length} Aion invoice${reconcileBody.length > 1 ? "s" : ""} successfully reconciled!`);
                    } else {
                        throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later.")
                    }

                    this.props.submitComplete()
                } catch (error) {
                    var affectedInvoices = reconcileBody.map(invoice => {
                        return invoice.docNumber
                    }).join(', ')

                    ErrorAlert({ description: <>{(error && error.message) && <>({error.message}<br /><br />)</>}The following invoices were not reconciled: {affectedInvoices}</> })
                }
            })
        }

        if (reconcileAndSyncBody.length > 0) {
            console.log("/receivables/reconcileandsync body", JSON.stringify(reconcileAndSyncBody))

            apiPOST("/receivables/reconcileandsync", { businesskey: this.props.aionStore.BusinessUniqueKey }, reconcileAndSyncBody, (err, resp) => {
                try {
                    const data = resp;
                    console.log("/receivables/reconcileandsync", data)

                    this.setState({ reconcileLoading: false })

                    if (data) {
                        message.success(`${reconcileAndSyncBody.length} QuickBooks invoice${reconcileAndSyncBody.length > 1 ? "s" : ""} successfully reconciled!`);
                    } else {
                        throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later.")
                    }

                    this.props.submitComplete()
                } catch (error) {
                    var affectedInvoices = reconcileAndSyncBody.map(invoice => {
                        return invoice.docNumber
                    }).join(', ')

                    ErrorAlert({ description: <>{(error && error.message) && <>({error.message}<br /><br />)</>}The following invoices were not reconciled: {affectedInvoices}</> })
                }
            })
        }

    }

    fetchCustomerInvoices = (customerid) => {
        // var { customers } = this.state;

        const headers = {
            businesskey: this.props.aionStore.BusinessUniqueKey,
            customerid: customerid,
        }

        console.log("/receivables/customerinvoices", JSON.stringify(headers))

        apiGET("/receivables/customerinvoices", headers, (err, resp) => {
            try {
                if (err) throw Error(err);
                if (resp.error) throw Error(resp.error);

                const data = resp.data;
                console.log("/receivables/customerinvoices", JSON.stringify(resp))
                if (data) {
                    if (data.length > 0) {
                        this.setState({ invoices: data, continueLoading: false, continueDisabled: false })
                    } else {
                        throw Error("No customer invoices available for reconciliation.")
                    }
                } else {
                    throw Error(data.error || data.responseMessage || "Error retrieving customer invoices for reconciliation.")
                }
            } catch (error) {
                this.setState({ invoices: [], continueLoading: false, continueDisabled: true })
                this.setState({ transactionsLoaded: true, transactions: [] });
                ErrorAlert({ description: error.message });
            }
        })
    }

    getTotal = () => {
        var total = 0

        this.state.invoices.forEach(invoice => {
            total = total + (invoice.paymentAmount || 0)
        })

        return total
    }

    getReconciliationCount = () => {
        const { invoices } = this.state
        var count = 0

        invoices.forEach(invoice => {
            if (invoice.paymentAmount && invoice.paymentAmount > 0) count++
        })

        return count
    }

    isValid = () => {
        const { invoices } = this.state

        console.log("this.props.totalPaymentAmount", this.props.totalPaymentAmount)

        var paymentAmount = false
        var paymentAmountLessThanBalance = true

        invoices.forEach(invoice => {
            if (invoice.paymentAmount && invoice.paymentAmount > 0) paymentAmount = true
            if (invoice.paymentAmount && invoice.paymentAmount > invoice.Balance) paymentAmountLessThanBalance = false
        })

        if (paymentAmount === false) {
            return "Please enter a payment amount for at least one invoice to continue."
        }

        if (paymentAmountLessThanBalance === false) {
            return "Paymount amount cannot be greater than the invoice balance."
        }

        if (this.getTotal() > this.props.totalPaymentAmount) {
            return `Total amount to reconcile cannot exceed the payment amount of ${formatter.format(this.props.totalPaymentAmount)}.`
        }

        return true
    }

    render() {
        console.log("reconcile", this.props.txnRecord)
        const { flowStep, customer, invoices, reconcileLoading, continueDisabled, continueLoading } = this.state;
        const { theme, bill, customers, aionStore } = this.props

        var darkMode = (theme.type === "dark");

        const entryColumns = [
            {
                title: 'No.',
                dataIndex: 'DocNumber',
                key: 'DocNumber',
                width: "120px"
            },
            {
                title: 'Invoice Date',
                dataIndex: 'TxnDate',
                key: 'TxnDate',
                render: date => (moment(date).format('MM/DD/YY')),
                width: "120px"
            },
            {
                title: 'Balance',
                dataIndex: 'Balance',
                key: 'Balance',
                render: amount => (formatter.format(amount)),
                align: 'right',
            },
            {
                title: 'Payment Amount',
                width: 140,
                render: (name, invoice) => <InputNumber min={0} value={invoice.paymentAmount} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} onChange={(value) => { invoice.paymentAmount = value; this.setState(invoices) }} type="text" />,
            },
            {
                title: 'Note',
                render: (name, invoice) => <Input value={invoice.note} onChange={(event) => invoice.note = event.target.value} type="text" />,
            },
        ];

        var content = (<div></div>)

        switch (flowStep) {
            case "selectCustomer":
                content = <>
                    <Title level={4}>Select a customer</Title>
                    <Paragraph color={theme.colors.systemGray}>Select the customer who made this invoice payment</Paragraph>

                    <LabeledInput
                        label="Customer*"
                        labelcolor={theme.colors.secondary3}
                        id="customerId"
                        key="customerId"
                        type="select"
                        className="no-left-padding"
                        placeholder="Select Customer"
                        value={customer && (customer.displayName || customer.companyName)}
                        onChange={this.handleCustomerSelect}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                            return option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }}
                    >
                        {(customers || []).map(item => <Select.Option key={item.customerId} id="customerName" name={item.displayName || item.companyName} value={item.customerId} style={{ backgroundColor: "transparent" }}>{item.displayName || item.companyName}</Select.Option>)}
                    </LabeledInput>

                    <Button loading={continueLoading} disabled={continueDisabled} permtype="Receivables.Invoices" style={{ margin: '20px 0' }} solid onClick={this.continue} text='Continue' />
                </>
                break
            case "selectInvoice":
                content = <>
                    <Affix offsetTop={0}>
                        <Flex style={{ justifyContent: "start", background: theme.body }}><TextButton onClick={() => {
                            this.props.onWidthChange(700)
                            this.setState({ flowStep: "selectCustomer" })
                        }}>
                            <LeftOutlined />Back</TextButton>
                        </Flex>
                    </Affix>
                    <Title level={4}>Reconcile your invoices</Title>
                    <Paragraph style={{ color: theme.colors.systemGray }}>Enter reconciliation information for each of your invoices below.</Paragraph>
                    <Table
                        rowKey="InvoiceId"
                        id="invoices-entry-table"
                        className="row-pointer"
                        tableLayout='auto'
                        columns={entryColumns}
                        dataSource={invoices}
                        pagination={false}
                        style={{ marginTop: 30, marginBottom: 40, width: "100%" }}
                        scroll={{ y: '700px', x: '100%' }}
                    />

                    <Button permtype="Receivables.Invoices" style={{ margin: '20px 0' }} solid onClick={this.confirm} text='Confirm' />
                </>
                break
            case "reconcile":
                content = <>
                    <FlexColumn>
                        <Affix offsetTop={0}>
                            <Flex style={{ justifyContent: "start", background: theme.body }}><TextButton onClick={() => {
                                this.props.onWidthChange(1000)
                                this.setState({ flowStep: "selectInvoice" })
                            }}><LeftOutlined />Back</TextButton></Flex>
                        </Affix>
                        <Title level={4}>You're about to reconcile <span style={{ color: theme.colors.secondary1 }}>{formatter.format(this.getTotal())}</span> off <span style={{ color: theme.colors.secondary1 }}>{this.getReconciliationCount()}</span> {this.getReconciliationCount() > 1 ? "invoices" : "invoice"} </Title>
                        <Paragraph level={3} style={{ color: theme.colors.systemGray }}>Please verify the information below and click submit</Paragraph>
                        <InputConfirmation key="5" label="Total Invoices" value={this.getReconciliationCount()} />
                        <InputConfirmation key="6" label="Total Amount to Reconcile" value={formatter.format(this.getTotal())} />
                        <InputConfirmation style={{ width: "100%" }} key="3" label="Payment date" value={moment(this.props.paymentDate).format("ll")} />
                    </FlexColumn>

                    <Affix offsetBottom={0}>
                        <Button permtype="Receivables.Invoices" loading={reconcileLoading} style={{ margin: '20px 0' }} solid onClick={this.reconcile} text='Reconcile' />
                    </Affix>
                </>
                break
        }


        return (
            <FlexColumn center style={{ minHeight: "80%" }}>
                <div style={{ width: "95%" }}> {/* display: "flex", flexDirection: "column", margin: "15px 0 30px 0" }}> */}
                    {content}
                </div>
            </FlexColumn>
        );
    }
}

function mapStateToProps(state) {
    return {
        bkStore: state.bookkeepingAppReducer,
        aionStore: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(ReconcileModal));