import React, { useState } from 'react'

import { message } from 'antd'
import { Text } from '../../Reusable/Text'
import { Flex, FlexColumn, Container, LightContainer, CardContainer } from '../../Reusable/Container'
import { LabeledInput, GradientDivider } from '../../Reusable/Input'
import { Button } from '../../Reusable/Refresh/Button'
import { apiPOSTReq } from '../../../Utils/api'
import { ErrorAlert } from '../../Reusable/Alert'
import moment from 'moment'
import environment from '../../../environment'

// Images
import Visibility from "../../../Images/visibility.png"
import VisibilityOff from "../../../Images/visibility-off.png"

import AccountNameBack from "../../../Images/name-account-back.png"

const AccountNameModal = (props) => {
    const [name, setName] = useState()
    const [masked, setMasked] = useState(true)
    const [loading, setLoading] = useState(false)

    const { account, onCancel } = props

    const updateNickname = () => {
        var body = { 
            AccountNumber: account.accountNumber,
            AccountNickName: name
        }

        setLoading(true)
        apiPOSTReq(`${environment.bbBaseUrl}/bb/updateAccountNickName`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            setLoading(false)
            try {
                const data = resp || {}
                if (data.result) {
                    console.log("bb/updateAccountNickName", data)
                    message.success('Account name set')
                    onCancel()
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/updateAccountNickName err", error, resp, body)
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    return (
        <Flex className='refresh-container' fullWidth style={{ height: 368 }}>
            <FlexColumn fullHeight between style={{ width: '50%', padding: 48 }}>
                <FlexColumn start>
                    <Text size='28px'>Welcome</Text>
                    <Text margin='8px 0 24px'>Thank you for choosing to bank with Aion. Name your account to get started</Text>
                    <LabeledInput
                        margin="10px 0"
                        id="name"
                        key="name"
                        placeholder="Eg: savings, payroll"
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                        maxLength={15}
                        instruction={name && `${name.length}/15`}
                    />
                </FlexColumn>
                <Flex start>
                    <Button loading={loading} height='40px' onClick={() => updateNickname()}style={{ marginRight: 24 }} solid disabled={!name} radius='4px' text='GET STARTED' />
                </Flex>
            </FlexColumn>

            <FlexColumn center style={{ width: 388, height: 368, backgroundImage: `url(${AccountNameBack}`, backgroundSize: 'contain', borderRadius: '0 8px 8px 0' }}>
                {
                    account && account.accountNumber && account.accountNumber.length > 5 &&
                    <Flex center style={{ width: '100%', height: '100%' }}>
                        <Text color='#FFFFFF' size='20px' weight='500' margin='0 16px 0 0'>{masked ? `•••••${account.accountNumber.substring(account.accountNumber.length - 4, account.accountNumber.length)}` : account.accountNumber}</Text>
                        <img style={{ cursor: 'pointer' }} onClick={() => setMasked(!masked)} width='20px' height='20px' src={masked ? Visibility : VisibilityOff} />
                    </Flex>
                }
            </FlexColumn>
        </Flex>
    )
}

export default (AccountNameModal)