import axios from 'axios';
import _ from 'underscore';
import { Auth } from 'aws-amplify';
import store from '../Reducers/store';
import environment from '../environment';

var request = require("request");

const baseURL = environment.uamBaseUrl;
const instance = axios.create({
    baseURL: baseURL
});
instance.defaults.headers["Content-Type"] = "application/JSON";

function getJwt(callback) {
    Auth.currentSession()
        .then(data => {
            callback(null, data.getIdToken().getJwtToken());
        })
        .catch(err => {
            console.log("getJwt Err", err);
            callback("Error! No active session");
        });
}

export function getUserPermissions(options, callback) {
    getJwt((err, jwt) => {
        if(!err) {
            const state = store.getState();
            const aionAppReducer = state.aionAppReducer || {};
            var { UserInfo } = aionAppReducer;
            UserInfo = UserInfo || {};
            var body = {};
            instance.defaults.headers["AionAuth"] = jwt;
            body['authToken'] = jwt;
            body['userId'] = UserInfo.Email;
            instance.post("/buildAuthHeader", body)
            .then(resp => {
                // console.log("/buildAuthHeader", resp);
                callback(null, resp || {});
            })
            .catch(err => {
                console.log("apiPOST", err.stack);
                callback(err);
            })
        } else {
            callback(err);
        }
    });
}

export function uaApiPOST(endpoint, options, callback) {
    options = options || {};
    const state = store.getState();
    const aionAppReducer = state.aionAppReducer || {};
    var { UAM, UserInfo } = aionAppReducer;
    UserInfo = UserInfo || {};
    const body = Object.assign({ businessId: aionAppReducer.BusinessUniqueKey, aionUserEmail: UserInfo.Email }, options.body || {});
    var options = { 
        method: 'POST',
        url: `${baseURL}${endpoint}`,
        headers: { 
            AionAuth: UAM.encryptedAuthHeader,
            AionCurrentBiz: aionAppReducer.BusinessUniqueKey,
            AionCurrentUser: UserInfo.Email,
            'Content-Type': 'application/json' 
        },
        body: body,
        json: true 
    };
    request(options, function (error, response, data) {
        // console.log(endpoint, error, data)
        callback(error, data);
    });
}