import React, { Component } from 'react'
import styled, { withTheme } from 'styled-components'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Descriptions, Modal, Skeleton, Popover } from 'antd'

// Components
import ARTable from '../../../Credit/AR Upload/ARTable'
import { Flex, FlexColumn, InputContainer } from '../../../Reusable/Container'
import { Text } from '../../../Reusable/Text'
import { TextButton } from '../../../Reusable/Button'
import { Image } from '../../../Reusable/Image'
import { ErrorAlert } from '../../../Reusable/Alert'

// Util
import { apiGET, apiPOSTReq } from '../../../../Utils/api'
import { toCurrency } from '../../../../Utils/util'
import environment from '../../../../environment'

import { InfoCircleOutlined } from '@ant-design/icons'
import ArrowRight from "../../../../Images/arrow-right.png"
import ModalClose from '../../../../Images/modal-close.png'

export const DescItem = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 120px;
`

export const DescLabel = styled.span`
    fontWeight: 500;
    fontSize: 16;
`

class Index extends Component {
    constructor(props) {
        super(props)

        this.state = {
            advancesLoaded: false,
            showUploadModal: false,
            agingHistoryLoaded: false,
            showNewPayers: false,
            fundsLoaded: false,
            showAdvanceableAR: false,
        }
    }

    componentDidMount() {
        this.fetchAgingReportHistory();
        this.fetchFundsData();
    }

    fetchAgingReportHistory = () => {
        apiPOSTReq(`${environment.iveBaseUrl}/ar/aging/getHistory`, { AionCurrentBiz: this.props.businessId }, {}, (err, resp) => {
            try {
                const data = resp
                console.log("/ar/aging/getHistory", JSON.stringify(data))
                console.log("/ar/aging/getHistory err", JSON.stringify(err))
                if (data.result) {
                    this.setState({ uploadARAgingInfos: data.uploadARAgingInfos, agingHistoryLoaded: true })
                } else {
                    this.setState({ payLoading: false, deleteLoading: false })
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /ar/aging/getHistory", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })

        this.setState({ agingHistoryLoaded: true })
    }

    fetchFundsData = () => {
        const headers = {
            businesskey: this.props.businessId
        }

        var endpoint = "/inventoryfinancing/funds/view"

        if (this.props.creditType == "assetbasedloan") endpoint = "/assetbasedloan/funds/view"

        apiGET(endpoint, headers, (err, resp) => {
            try {
                if (err) throw Error(err)
                const data = resp.data
                console.log(endpoint, data)
                if (data.success) {
                    this.setState({ fundsLoaded: true, AvailableFunds: data })
                } else {
                    throw Error("We had some trouble. Please try again later.")
                }
            } catch (error) {
                ErrorAlert({ description: error.message })
                this.setState({ fundsLoaded: true })
            }
        })
    }

    handleAddCustomers = (newCustomers, uploadId) => {
        this.props.history.push({
            pathname: '/credit/abl-credit/new-customer-setup',
            state: { newCustomers, uploadId }
        })
    }

    render() {
        const { theme } = this.props
        const { inventoryLoaded, AvailableFunds, agingHistoryLoaded, uploadARAgingInfos, fundsLoaded, showAdvanceableAR } = this.state

        return (
            <FlexColumn>
                <FlexColumn>
                    <Flex between>
                        <div style={{ width: 250, marginBottom: 20 }}>
                            <Skeleton loading={!fundsLoaded} active title={false} paragraph={{ rows: 4 }}>
                                <InputContainer padding='20px 20px 10px' margin='0'>
                                    <FlexColumn start>
                                        <Text color={theme.colors.caption} style={{ marginBottom: 4 }}>Gross Advanceable AR</Text>
                                        <Text size='26px' weight='600'>{AvailableFunds && !isNaN(AvailableFunds.GrossAdvanceableAR) ? `$${toCurrency(AvailableFunds.GrossAdvanceableAR)}` : "--"}</Text>
                                        <TextButton style={{ marginTop: 4 }} uppercase rightIcon={<Image src={ArrowRight} />} onClick={() => this.setState({ showAdvanceableAR: true })} text="VIEW DETAILS" />
                                    </FlexColumn>
                                </InputContainer>
                            </Skeleton>
                        </div>
                    </Flex>
                    <ARTable
                        titleText="AR Aging History"
                        inventoryLoaded={agingHistoryLoaded}
                        data={uploadARAgingInfos}
                        handleAddCustomers={(newCustomers, uploadId) => this.handleAddCustomers(newCustomers, uploadId)}
                    />
                </FlexColumn>
                <Modal
                    visible={showAdvanceableAR}
                    footer={false}
                    style={{ top: 20 }}
                    destroyOnClose={true}
                    width={550}
                    closable={true}
                    onCancel={() => this.setState({ showAdvanceableAR: false })}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <div style={{ display: "flex", flexDirection: "flex-start" }}>
                        <Descriptions
                            bordered
                            column={1}
                            style={{ width: '100%', marginTop: 24 }}
                        >
                            <Descriptions.Item label="Gross Accounts Receivable (AR)" labelStyle={{ fontWeight: 500, fontSize: 16 }} contentStyle={{ fontSize: 16 }}>
                                <DescItem>{AvailableFunds && !isNaN(AvailableFunds.GrossAR) ? `$${toCurrency(AvailableFunds.GrossAR)}` : "--"}</DescItem>
                            </Descriptions.Item>
                            <Descriptions.Item label="- Ineligible Invoices" labelStyle={{ fontSize: 16 }} contentStyle={{ fontSize: 16 }}>
                                <DescItem>{AvailableFunds && !isNaN(AvailableFunds.IneligibleInvoices) ? `$${toCurrency(AvailableFunds.IneligibleInvoices)}` : "--"}</DescItem>
                            </Descriptions.Item>
                            <Descriptions.Item label="- Receipts" labelStyle={{ fontSize: 16 }} contentStyle={{ fontSize: 16 }}>
                                <DescItem>{AvailableFunds && !isNaN(AvailableFunds.AggregatePayments) ? `$${toCurrency(AvailableFunds.AggregatePayments)}` : "--"}</DescItem>
                            </Descriptions.Item>
                            <Descriptions.Item label="Eligible AR" labelStyle={{ fontWeight: 500, fontSize: 16 }} contentStyle={{ fontSize: 16 }}>
                                <DescItem>{AvailableFunds && !isNaN(AvailableFunds.EligibleAR) ? `$${toCurrency(AvailableFunds.EligibleAR)}` : "--"}</DescItem>
                            </Descriptions.Item>
                            <Descriptions.Item label={
                                <Popover content={(
                                    <FlexColumn>
                                        <span>Certain payers may have customized advance rates</span>
                                    </FlexColumn>
                                )}>
                                    <div style={{ display: "flex" }}><span>* Standard AR Advance Rate</span><InfoCircleOutlined style={{ marginLeft: 4, color: 'rgba(0,0,0,.45)' }} /></div>
                                </Popover>
                            } labelStyle={{ fontWeight: 500, fontSize: 16 }} contentStyle={{ fontSize: 16 }}>
                                <DescItem>{AvailableFunds && !isNaN(AvailableFunds.ARAdvanceRate) ? `${AvailableFunds.ARAdvanceRate * 100}%` : "--"}</DescItem>
                            </Descriptions.Item>
                            <Descriptions.Item label="Gross Advanceable AR" labelStyle={{ fontWeight: 500, fontSize: 16 }} contentStyle={{ fontSize: 16 }}>
                                <DescItem>{AvailableFunds && !isNaN(AvailableFunds.GrossAdvanceableAR) ? `$${toCurrency(AvailableFunds.GrossAdvanceableAR)}` : "--"}</DescItem>
                            </Descriptions.Item>
                        </Descriptions>
                    </div>
                </Modal>
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.creditAppReducer,
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)))