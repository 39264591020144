import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';

import { message, Select } from 'antd';

// Components
import { Button } from '../../Reusable/Button';
import { Paragraph, Title } from '../../Reusable/Text';
import { FlexColumn, } from '../../Reusable/Container';
import { LabeledInput } from '../../Reusable/Input';
import { ErrorAlert } from '../../Reusable/Alert';
import environment from '../../../environment';
import { apiPOSTReq } from '../../../Utils/api';

const { Option } = Select;


class AddBankAccount extends Component {

    state = {
        loading: false,
        account: this.props.account || {},
    }

    componentDidMount() {
        console.log("account details", JSON.stringify(this.props.account))
    }

    handleTextChange = (event) => {
        var { account } = this.state;
        account[event.target.id] = event.target.value;
        this.setState({ account });
    }

    handleSelect = (value, option) => {
        console.log("handleSelect", value, option)
        var { account } = this.state;
        account["accountType"] = value;
        this.setState({ account });
    }

    handleSubmit = async (event) => {
        var { account } = this.state;
        const { accountNickName, accountType,  } = account;
        if (!accountNickName) {
            ErrorAlert({ description: "Please enter nick name" });
            return;
        }
        if (!accountType) {
            ErrorAlert({ description: "Please select a account type." });
            return;
        }

        const body = {
            "accountTitle": accountNickName,
            "accountNickName": accountNickName,
            "accountType": accountType,
        }

        this.setState({ loading: true })
        const headers = {
            AionCurrentBiz: this.props.aionStore.BusinessUniqueKey,
            AionAuth: this.props.aionStore.UAM.encryptedAuthHeader,
            BankProvider: environment.bankProvider.crb
        }
        apiPOSTReq(`${environment.bbBaseUrl}/bb/createAccount`, headers, body, (err, resp) => {
            try {
                console.log("handleSubmit account response ", JSON.stringify(resp));
                const data = resp;
                console.log("/createAccount", data)
                if (data.result) {
                    message.success(`${accountNickName} successfully created`);
                    if (data.responseMessage != null && data.responseMessage != "Success") {
                        ErrorAlert({ description: data.responseMessage });
                    }
                    this.props.submitComplete();
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                this.setState({ loading: false })
                console.log("ERRR /createAccount", error, err, resp);
                ErrorAlert({ description: error.message })
            }
        })
    }

    render() {
        const { account, loading } = this.state;
        const { accountId, accountNickName, accountType } = account;
        const { theme, aionStore } = this.props;

        return (
            <FlexColumn center style={{ minHeight: "80%" }}>
                <div style={{ width: "600px" }}>
                    <Title level={4}>{this.props.editAccount ? "Update Account" : "Create a new account"}</Title>
                    <Paragraph>Enter the details below.</Paragraph>
                    <FlexColumn>
                        <LabeledInput
                            label="Nickname"
                            labelcolor={theme.colors.secondary3}
                            id="accountNickName"
                            key="accountNickName"
                            placeholder="Enter a nickname"
                            value={accountNickName}
                            onChange={this.handleTextChange}
                        />
                        <LabeledInput
                            label="Account Type"
                            labelcolor={theme.colors.secondary3}
                            id="accountType"
                            key="accountType"
                            type="select"
                            className="no-left-padding"
                            placeholder="Account Type"
                            value={accountType}
                            onChange={this.handleSelect}
                        >
                            {["STANDARD", "OPERATING", "ACCOUNTS_RECEIVABLE", "TAX"].map(item => (
                                <Option key={item} id="accountType" name="accountType" value={item}>
                                    {item}
                                </Option>
                            )
                            )}
                        </LabeledInput>
                        <Button style={{ alignSelf: 'center', width: '100%', margin: '20px 0' }} type="primary" size="large" loading={loading} onClick={this.handleSubmit} text={accountId ? "Update" : "Save"} />
                    </FlexColumn>
                </div>
            </FlexColumn>
        );
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer,
        bankingStore: state.bankingAppReducer,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(AddBankAccount));