import axios from 'axios';
import _ from 'underscore';
import { Auth } from 'aws-amplify';
import store from '../Reducers/store';
import environment from '../environment';
import { resetStore, addDataToStore, RESET_BNK_STATE, RESET_MAIN_STATE, RESET_ONBOARDING_STATE, RESET_CREDIT_STATE, RESET_RECEIVABLES_STATE, WINDOW_CLOSE_TIMESTAMP, BNK_SAVE_DATA } from '../Actions/actions';
import { message } from 'antd';

var request = require("request");

const instance = axios.create({
    baseURL: environment.apiBaseUrl
});
instance.defaults.headers["Content-Type"] = "application/JSON"

const urlInstance = axios.create({
    baseURL: ''
});
urlInstance.defaults.headers["Content-Type"] = "application/JSON"

export function getJwt(callback) {
    Auth.currentSession()
        .then(data => {
            // console.log("getJwt", data.getIdToken().getJwtToken());
            callback(null, data.getIdToken().getJwtToken());
        })
        .catch(err => {
            console.log("getJwt Err", err);
            callback("Error! No active session");
        });
}

// API Utility
export function apiGET(endpoint, headers, callback) {
    getJwt((err, jwt) => {
        const state = store.getState();
        const aionAppReducer = state.aionAppReducer || {};
        const { UAM } = aionAppReducer;
        // console.log("AionCurrentBiz: ", aionAppReducer.BusinessUniqueKey)
        instance.defaults.headers = {};
        instance.defaults.headers['businesskey'] = aionAppReducer.BusinessUniqueKey;
        instance.defaults.headers['AionCurrentBiz'] = aionAppReducer.BusinessUniqueKey;
        instance.defaults.headers['jwt'] = jwt || "";
        // instance.defaults.headers['aionAuth'] = UAM.encryptedAuthHeader || "";
        instance.defaults.headers = Object.assign(instance.defaults.headers, headers ? headers : {});
        instance.defaults.responseType = 'json';
        instance.get(endpoint)
        .then(resp => {
            callback(null, resp)
        })
        .catch(err => {
            console.log("GET err", err)
            callback(err)
        })
    });
}

export function apiGETDoc(endpoint, headers, callback) {
    getJwt((err, jwt) => {
        const state = store.getState();
        const aionAppReducer = state.aionAppReducer || {};
        const { UAM } = aionAppReducer;
        instance.defaults.headers = {};
        instance.defaults.headers['businesskey'] = aionAppReducer.BusinessUniqueKey;
        instance.defaults.headers['AionCurrentBiz'] = aionAppReducer.BusinessUniqueKey;
        instance.defaults.headers['jwt'] = jwt || "";
        // instance.defaults.headers['aionAuth'] = UAM.encryptedAuthHeader || "";
        instance.defaults.headers = Object.assign(instance.defaults.headers, headers ? headers : {});
        instance.defaults.responseType = 'arraybuffer';
        instance.get(endpoint)
        .then(resp => {
            callback(null, resp)
        })
        .catch(err => {
            console.log("GET err", err)
            callback(err)
        })
    });
}

export function apiPOST(endpoint, headers, data, callback) {
    getJwt((err, jwt) => {
        if(!err) {
            const state = store.getState();
            const aionAppReducer = state.aionAppReducer || {};
            const { UAM } = aionAppReducer;
            instance.defaults.headers = {};
            instance.defaults.headers['jwt'] = jwt;
            // instance.defaults.headers['aionAuth'] = UAM.encryptedAuthHeader || "";
            instance.defaults.headers['AionCurrentBiz'] = aionAppReducer.BusinessUniqueKey;
            instance.defaults.headers['businesskey'] = aionAppReducer.BusinessUniqueKey;
            instance.defaults.headers = Object.assign(instance.defaults.headers, headers ? headers : {});
            instance.defaults.responseType = 'json';
            instance.post(endpoint, data)
            .then(resp => {
                callback(null, resp)
            })
            .catch(err => {
                console.log("apiPOST Error", err)
                callback(err)
            })
        } else {
            callback(err)
        }
    });
}

export function apiPOSTNoAuth(endpoint, headers, data, callback) {
    instance.defaults.headers = {};
    instance.defaults.headers = Object.assign(instance.defaults.headers, headers ? headers : {});
    console.log("getInvoicePaymentReferralInfo", instance.defaults.headers)
    instance.defaults.responseType = 'json';
    instance.post(endpoint, data)
    .then(resp => {
        callback(null, resp)
    })
    .catch(err => {
        console.log("apiPOST Error", err)
        callback(err)
    })
}

export function apiPOSTURL(url, headers, data, callback) {
    getJwt((err, jwt) => {
        if(!err) {
            const state = store.getState();
            const aionAppReducer = state.aionAppReducer || {};
            const { UAM } = aionAppReducer;
            urlInstance.defaults.baseURL = url;
            urlInstance.defaults.headers = {};
            // urlInstance.defaults.headers['jwt'] = jwt;
            urlInstance.defaults.headers['aionAuth'] = UAM.encryptedAuthHeader || jwt;
            urlInstance.defaults.headers['AionCurrentBiz'] = aionAppReducer.BusinessUniqueKey;
            // urlInstance.defaults.headers['businesskey'] = aionAppReducer.BusinessUniqueKey;
            urlInstance.defaults.headers = Object.assign(urlInstance.defaults.headers, headers ? headers : {})
            urlInstance.defaults.responseType = 'json';
            urlInstance.post('', data)
            .then(resp => {
                if(resp.data) {
                    resp.data["jwt"] = jwt;
                }
                callback(null, resp)
            })
            .catch(err => {
                callback(err)
            })
        } else {
            callback(err)
        }
    });
}

export function apiPOSTReq(url, customHeaders, data, callback) {
    getJwt((err, jwt) => {
        const state = store.getState();
        const aionAppReducer = state.aionAppReducer || {};
        const { UAM } = aionAppReducer;
        var headers = {'Content-Type': 'application/json' };
        headers['jwt'] = jwt;
        headers['aionAuth'] = UAM.encryptedAuthHeader || jwt;
        // headers['businesskey'] = aionAppReducer.BusinessUniqueKey;
        headers["AionCurrentBiz"] = aionAppReducer.BusinessUniqueKey;
        headers = Object.assign(headers, customHeaders || {});
        data = data || {};
        data["BusinessId"] = data["BusinessId"] ? data["BusinessId"] : aionAppReducer.BusinessUniqueKey;
        var options = { 
            method: 'POST',
            url: url,
            headers: headers,
            body: data,
            json: true 
        };
        request(options, function (error, response, data) {
            callback(error, data);
        });
    });
}

export function apiPOSTReqPromise(url, headers, body) {
    return new Promise((resolve, reject) => {
        apiPOSTReq(url, headers, body, (err, resp) => {
            if (err) {
                reject(err);
            } else {
                resolve(resp);
            }
        });
    });
};

export function apiGETDocUrl(url, customHeaders, query, callback) {
    getJwt((err, jwt) => {
        const state = store.getState();
        const aionAppReducer = state.aionAppReducer || {};
        const { UAM } = aionAppReducer;
        // urlInstance.defaults.baseURL = url;
        urlInstance.defaults.headers = {};
        // urlInstance.defaults.headers['businesskey'] = aionAppReducer.BusinessUniqueKey;
        urlInstance.defaults.headers['AionCurrentBiz'] = aionAppReducer.BusinessUniqueKey;
        // urlInstance.defaults.headers['jwt'] = jwt || "";
        urlInstance.defaults.headers['aionAuth'] = UAM.encryptedAuthHeader || jwt;
        urlInstance.defaults.headers = Object.assign(urlInstance.defaults.headers, customHeaders || {});
        urlInstance.defaults.responseType = 'blob';
        urlInstance.defaults.params = query;
        urlInstance.get(url)
        .then(resp => {
            callback(null, resp)
        })
        .catch(err => {
            console.log("GET err", err)
            callback(err)
        })
    });
}

export function apiPATCH(endpoint, headers, data, callback) {
    getJwt((err, jwt) => {
        if(!err) {
            const state = store.getState();
            const aionAppReducer = state.aionAppReducer || {};
            const { UAM } = aionAppReducer;
            instance.defaults.headers = {};
            // instance.defaults.headers['jwt'] = jwt;
            instance.defaults.headers['aionAuth'] = UAM.encryptedAuthHeader || jwt;
            // instance.defaults.headers['businesskey'] = aionAppReducer.BusinessUniqueKey;
            instance.defaults.headers['AionCurrentBiz'] = aionAppReducer.BusinessUniqueKey;
            instance.defaults.headers = Object.assign(instance.defaults.headers, headers ? headers : {});
            instance.defaults.responseType = 'json';
            instance.patch(endpoint, data)
            .then(resp => {
                if(resp.data) {
                    resp.data["jwt"] = jwt;
                }
                callback(null, resp)
            })
            .catch(err => {
                console.log("apiPATCH", err.stack)
                callback(err)
            })
        } else {
            callback(err)
        }
    });
}

export const getPreSignedS3Url = (options, callback) => {
    var headers = {
    }
    var body = {
        "attachmentURL": options.url
    }
    apiPOSTReq(`${environment.iveBaseUrl}/ive/aws/preSignedURL`, headers, body, (err, resp) => {
        var data = resp || {};
        const preSignedURL = data.preSignedURL || options.url;
        callback(null, preSignedURL);
    })
} 

export const doSignout = async(history, dispatch, hideMsg, noRedirect) => {
    apiPOST("/signout", null, {}, (err, resp) => {
        try {
            if(err) throw Error(err);
            endCognitoSession(history, dispatch, hideMsg);
        } catch (error) {
            console.log('error signing out: ', error);
            message.error("Error signing out. Please try again");
        }
    });

    const endCognitoSession = async(history, dispatch, hideMsg) => {
        try {
            await Auth.signOut();
            
            // if (!hideMsg)
                // message.success("Sign out complete");
        } catch (error) {
            console.log('error signing out: ', error);
            // message.error("Error signing out. Please try again");
        }
        if(noRedirect !== true) history.push('/');
        clearLocalStorage(dispatch);
        localStorage.removeItem('state1');  // remove WINDOW_CLOSE_TIMESTAMP so a signout will not be called when user tries to sign in again later
        window.removeEventListener("beforeunload", recordTimeStamp); // Do this so WINDOW_CLOSE_TIMESTAMP will not be recorded when tab/window is closed
    }
}

export function recordTimeStamp() {
    localStorage.setItem('state1', JSON.stringify({ WINDOW_CLOSE_TIMESTAMP: Date.now() }));
}

export function clearLocalStorage(dispatch) {
    dispatch(addDataToStore(BNK_SAVE_DATA, { BBAccounts: [] }))
    dispatch(resetStore(RESET_BNK_STATE));
    dispatch(resetStore(RESET_MAIN_STATE));
    dispatch(resetStore(RESET_ONBOARDING_STATE));
    dispatch(resetStore(RESET_CREDIT_STATE));
    dispatch(resetStore(RESET_RECEIVABLES_STATE));
}