import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { Tag } from 'antd';
import { CheckCircleTwoTone } from '@ant-design/icons';

// Components
import Apply from '../OnboardingFlowCoordinator/index';
import { Title, Paragraph } from '../Reusable/Text';
import { Button } from '../Reusable/Button';

// Util
import { apiGET } from '../../Utils/api'
import { getCustomerName, getCurrentOnboardingStep } from '../../Utils/util'
import { SYSTEM_STYLES } from '../../Utils/stylingAssets'

// Actions
import { completeSignin, CREDIT_SAVE_DATA, addDataToStore } from '../../Actions/actions'
import { Flex, FlexColumn } from '../Reusable/Container';
import Modal from 'antd/lib/modal/Modal';
import PageHeader from '../Reusable/PageHeader';

class Dashboard extends Component {
    constructor(props) {
        super(props)
        const { aionStore } = this.props;
        const attributes = aionStore.Attributes || {};
        const businessAttributes = attributes.Businesses || {};
        this.state = {
            currentStep: this.props.currentStep,
            applicationSubmitted: businessAttributes.ARCreditApplicationSubmitted,
            loading: false
        }
    }

    static getDerivedStateFromProps(nextProps, currentState) {
        const { aionStore } = nextProps;
        const attributes = aionStore.Attributes || {};
        const businessAttributes = attributes.Businesses || {};
        if (currentState.applicationSubmitted !== businessAttributes.ARCreditApplicationSubmitted) {
            return {
                applicationSubmitted: businessAttributes.ARCreditApplicationSubmitted
            }
        }
        return null
    }

    componentDidMount() {
        const applicationSubmitted = new URLSearchParams(this.props.location.search).get("applicationSubmitted") // from OnboardingFlowCoordinator
        if (applicationSubmitted === 'true') {
            this.props.history.replace('/credit')
            this.setState({ loading: true })
        }
        this.getAttributes()
    }

    getAttributes() {
        // Check session and fetch attributes
        apiGET("/attributes", null, (err, resp) => {
            try {
                const data = resp.data;
                if (data.success) {
                    // console.log("/attributes", JSON.stringify(data))
                    this.props.dispatch(completeSignin(data));
                    this.props.updateSideMenu(data.Attributes.Businesses.ARCreditApplicationSubmitted);
                    this.setState({ loading: false, currentStep: getCurrentOnboardingStep(data.Attributes.Businesses.ARCreditApplicationSubmitted, this.props.store) });
                    //this.setState({ loading: false, currentStep: 2 });  // testing
                } else {
                    throw Error("Could not fetch attributes.")
                }
            } catch (error) {
                console.log("/attributes ERRR", error.stack)
            }
        })
    }

    loadNext = () => {
        this.props.updateState({currentStep: this.state.currentStep+1})
        this.setState({currentStep: this.state.currentStep+1})
    }

    steps = (count) => {
        switch (count) {
            case 0:
                const { Attributes } = this.props.aionStore;
                const businessesAttributes = (Attributes || {}).Businesses || {};
                const applicationSubmitted = true//businessesAttributes.ApplicationSubmitted; // This attribute indicates that the user has already filled an application.
                // Only request for ar credit specific info if application was already submitted
                const additionalParams = applicationSubmitted ? '&step=3&primary_application_completed=true' : '';
                return {
                    title: 'Customer Setup',
                    content: (
                        <FlexColumn style={{ width: "550px", minHeight: "85vh", justifyContent: "space-between" }}>
                            <div style={{ margin: "20px 0 50px 0" }} >
                                <Tag color={this.props.theme.colors.secondary3} style={{margin: '20px 0'}}>{"Getting Started".toUpperCase()}</Tag>
                                <Title level={4}>Step one is submitting your current financials</Title>
                                <Paragraph color={this.props.theme.colors.systemGray} level={3}>Have the following documents in hand to complete this step.</Paragraph>
                                <ol style={{...SYSTEM_STYLES.cardStyle, padding: '20px 40px'}}>
                                    <li>Debt schedule</li>
                                    <li>Latest tax returns (3 years)</li>
                                    <li>PNL/Income statement (2 years)</li>
                                    <li>Balance sheet</li>
                                    <li>AR & AP aging report</li>
                                    <li>Personal financial statement</li>
                                </ol>
                            </div>
                            
                            <Button permtype="Override" solid loading={this.state.loading} key="start-application" onClick={() => {
                                this.setState({ showApplyModal: true })
                            }} text='Start' />
                        </FlexColumn>
                    )
                }
            /**
             * Vae 1 and 2 not needed
             * With the new flow only financial documents and a credit agreement are needed
            case 1: 
                return {
                    title: 'Customers',
                    content: (
                        <BulkAddCustomer onboarding loadNext={this.loadNext} />
                    )
                }
            case 2:
                var { onboarding, PurchaseOrders, PendingCustomers, RequestedCustomers, ApprovedCustomers } = this.props.store;
                const purchaseOrders = PurchaseOrders || [];
                onboarding = onboarding || {};
                
                var selectedCustomers = (RequestedCustomers || []).concat((PendingCustomers || [])).concat((ApprovedCustomers || []));
                // Mark customers for POs already submitted
                var poSubmittedForAll = true;
                selectedCustomers.forEach(customer => {
                    purchaseOrders.forEach(item => {
                        if(item.CustomerId === customer.Id) customer.POSubmitted = true;
                    });
                    if(!customer.POSubmitted) poSubmittedForAll = false;
                });
                // console.log("selectedCustomers", selectedCustomers, purchaseOrders);
                
                return {
                    title: 'Purchase orders',
                    content: (
                        <FlexColumn style={{ width: "550px", minHeight: "85vh", justifyContent: "space-between" }}>
                            <div style={{ margin: "20px 0 50px 0" }} >
                                <Tag color={this.props.theme.colors.secondary3} style={{margin: '20px 0'}}>{"Step three".toUpperCase()}</Tag>
                                <Title level={4}>Next step is to submit purchase orders</Title>
                                <Paragraph color={this.props.theme.colors.systemGray} level={3}>For each of your customers add a valid purchase order, contract or work order.</Paragraph>
                                <List
                                    dataSource={selectedCustomers}
                                    bordered
                                    style={{width: "100%", marginTop: 50}}
                                    renderItem={item => {
                                        var listActions = [];
                                        listActions.push(<Button type="primary" size="large" onClick={() => {this.setState({ showAddPO: true, poCustomer: item })}}>{item.POSubmitted ? "Add more": "Add"}</Button>);
                                        return (
                                        <List.Item
                                            actions={listActions}
                                            style={{ height: 80 }}
                                        >   
                                            <Flex center>
                                                <Paragraph bold noMargin>{getCustomerName(item)}</Paragraph>
                                                {item.POSubmitted ? <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: '25px', marginLeft: 10 }} /> : null}
                                            </Flex>
                                        </List.Item>
                                    )}}
                                />
                            </div>
                            {poSubmittedForAll ? <StyledButton style={{ width: '100%', alignSelf: "center" }} size="large" key="po-done" type="primary" onClick={this.loadNext}>
                                Done
                            </StyledButton> : null}
                        </FlexColumn>
                    )
                }*/
            case 1:
                const { UserInfo } = this.props.aionStore;
                return {
                    title: 'Agreement',
                    content: (
                        <div className="flex-column align-items-center" style={{padding: '40px', margin: '0 0 0 25px'}}>
                            <div className="flex-column-center" style={{width: '100%', marginBottom: '40px'}}>
                                <img height="220px" src="https://img.icons8.com/fluent/240/000000/agreement.png"/>
                            </div>
                            <Tag color={this.props.theme.colors.secondary3} style={{margin: '20px 0'}}>{"Next Steps".toUpperCase()}</Tag>
                            <Title level={4}>That's it for now, {UserInfo.FirstName}!</Title>
                            <Paragraph color={this.props.theme.colors.systemGray} level={3}>Once your data has been reviewed you will be receiving a credit agreement to sign.<br/>Reach out to <a href="mailto:contact@aionfi.com">support</a> with any questions.</Paragraph>
                        </div>
                    )
                }
        }
    }

    render() {
        const { current } = this.state;
        const totalSteps = [0, 1, 2];
        return (
            <>
                {/* <PageHeader titleText="AR Credit Setup" /> */}
                <div className="flex-container space-around" style={{margin: '50px 5px', height: '100%'}}>
                    <div></div>
                    {(this.steps(this.state.currentStep) || {}).content}
                    <div></div>
                    {/* <AddPurchaseOrder
                        wrappedComponentRef={this.saveFormRef}
                        visible={this.state.showAddPO}
                        onCancel={() => { this.setState({ showAddPO: false }) }}
                        poCustomer={this.state.poCustomer}
                        onCreate={() => { 
                            this.setState({ showAddPO: false, poUploaded: true }) 
                        }}
                    /> */}
                </div>
                <Modal
                    visible={this.state.showApplyModal}
                    footer={null}
                    onCancel={() => { this.setState({ showApplyModal: false }) }}
                    width={850}
                    style={{ top: 20 }}
                    destroyOnClose={true}
                >
                    <Apply
                        applicationType="invoice_financing"
                        primary_application_completed={true}
                        step="3"
                        width="600px"
                        modal={true} 
                        closeModal={() => {
                            this.setState({ showApplyModal: false, loading: true })
                            this.getAttributes() 
                        }}
                    />
                </Modal>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        store: state.creditAppReducer,
        aionStore: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

//export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Dashboard));
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Dashboard)));