import React from 'react';

import { Table, Row, Col } from 'antd';

import { Button } from '../../Reusable/Refresh/Button';
import PageHeader from '../../Reusable/PageHeader';
import { Text } from '../../Reusable/Text';
import { FlexColumn, Flex, Container } from '../../Reusable/Container';
import { toCurrency, } from '../../../Utils/util';
import { AccountInfoCard } from '../../Reusable/Card';

export const ConfirmStep = (props) => {
    return <>
        <FlexColumn key="confirmStep">
            <PageHeader
                back
                backText='Back'
                onBack={props.onClick}
                titleText={props.showDailyLimit ? "New contractor payment" : "New contractor paygroup"}
            />

            <FlexColumn between>
                <FlexColumn>
                    {props.showAvailableAmount && <>
                        <Row>
                            {props.accounts.filter(account => {
                                return props.accountIds.includes(account.AccountId)
                            }).map((x, i) => {
                                return <Col key={i} md={{ span: 8, }} lg={{ span: 8, }}>
                                    <AccountInfoCard
                                        theme={props.theme}
                                        mask={x.Mask}
                                        availableBalance={toCurrency(x.AvailableBalance)}
                                        scheduled={toCurrency(props.scheduled[x.AccountId])}
                                    />
                                </Col>
                            })}
                        </Row>
                    </>}


                    <Container shadow style={{ marginBottom: 48, minWidth: 450 }}>
                        <FlexColumn start>
                            <Text size='20px'>Enter payment information for each of your contractors below</Text>
                            {/* How much do you want to pay your contractor{props.selectedContractors.length > 1 && "s"}?</Text> */}
                            {/* <Text margin="10px 0 0 0">Enter payment information for each of your contractors below</Text> */}

                            <Table
                                rowKey="id"
                                id="contractors-entry-table"
                                tableLayout='auto'
                                columns={props.entryColumns}
                                dataSource={!props.usingTemplate ? props.selectedContractors : props.selectedTemplate}
                                pagination={false}
                                style={{ marginTop: 24 }}
                            />
                            {/* {
                                props.showDailyLimit && <Text size='18px'>
                                    Daily ACH limit: <b style={{ color: props.theme.colors.creditGreen }}>${toCurrency(props.dailyLimit)}</b>
                                </Text>
                            } */}
                            <Text size='20px' margin='24px 0 0'>Total to pay: <b style={{ color: props.theme.colors.creditGreen }}>{props.getTotal()}</b></Text>

                            <Flex start style={{ width: "100%", marginTop: 48 }}>
                                <Button permtype="Payables.Vendors" solid onClick={props.confirm} text={props.confirmText || "Confirm"} />
                            </Flex>
                        </FlexColumn>
                    </Container>
                </FlexColumn>
            </FlexColumn>
        </FlexColumn>
    </>
}