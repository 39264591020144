import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'
import update from 'immutability-helper'

import {
    message, Popconfirm, Space, Upload, Select, List, Table, Modal
} from 'antd'

import {
    DeleteOutlined, CheckCircleFilled, InboxOutlined, EditOutlined
} from '@ant-design/icons'

// Components
import { TextButton } from '../../Reusable/Button'
import { Button } from '../../Reusable/Refresh/Button'
import { FromAccountOption } from '../../Reusable/Option'
import { Text, Tag } from '../../Reusable/Text'
import { FlexColumn, Flex, } from '../../Reusable/Container'
import { LabeledInput } from '../../Reusable/Input'
import { ErrorAlert } from '../../Reusable/Alert'
import environment from '../../../environment'
import { apiPOSTReq } from '../../../Utils/api'
import { addressObjectToStr, isEmailValid, toCurrency, getBPAccountsWithAccess } from '../../../Utils/util'
import { SignedLink, } from '../../Reusable/Link'
import AddPaymentMethod from './AddPaymentMethod'
import ModalClose from '../../../Images/modal-close.png'

const docTypes = [
    {
        name: "Purchase Order",
        type: "PO",
    },
    {
        name: "Work Order",
        type: "WO",
    },
    {
        name: "Master Service Agreement",
        type: "MSA",
    },
    {
        name: "Other",
        type: "OTHER",
    }
]
const { Option } = Select
const { Dragger } = Upload

class NewContractor extends Component {

    state = {
        paymentMethod: "ACH",
        accountType: "Checking",
        loading: false,
        contractor: this.props.contractor || { type: "Individual" },
    }

    componentDidMount() {
        if (this.props.contractor) this.getPaymentMethods()
    }

    handleTextChange = (event) => {
        var { contractor } = this.state
        contractor[event.target.id] = event.target.value
        this.setState({ contractor })
    }

    handlePaymentTextChange = (event) => {
        const target = event.target
        this.saveInput(target.id || target.name, target.value)
    }

    getPaymentMethods = () => {
        const { contractor } = this.state
        const body = {
            "contractorId": contractor.id
        }
        console.log("/payables/contractors/getPaymentMethods body", body)
        // this.setState({ loading: true })
        apiPOSTReq(`${environment.payBaseUrl}/payables/contractors/getPaymentMethods`, {}, body, (err, resp) => {
            try {
                // this.setState({ loading: false })
                const data = resp
                console.log("/payables/contractors/getPaymentMethods", data)
                if (data.result) {
                    this.setState({ paymentMethods: data.paymentMethods })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getPaymentMethods", error, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    makeDefaultPaymentMethod = (paymentMethod) => {
        const { contractor } = this.state
        const body = {
            "contractorId": contractor.id,
            "paymentMethodId": paymentMethod.id
        }
        this.setState({ loading: true })
        apiPOSTReq(`${environment.payBaseUrl}/payables/contractors/makeDefaultPaymentMethod`, {}, body, (err, resp) => {
            this.setState({ loading: false })
            try {
                const data = resp || {}
                console.log("/payables/contractors/makeDefaultPaymentMethod resp", data)
                if (data.result) {
                    this.getPaymentMethods()
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /ive/templates/makeDefault", error, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    deletePaymentMethod = (paymentMethod) => {
        const { contractor } = this.state
        const body = {
            "contractorId": contractor.id,
            "paymentMethodId": paymentMethod.id
        }
        this.setState({ loading: true })
        apiPOSTReq(`${environment.payBaseUrl}/payables/contractors/deletePaymentMethod`, {}, body, (err, resp) => {
            this.setState({ loading: false })
            try {
                const data = resp || {}
                console.log("/payables/contractors/deletePaymentMethod", data)
                if (data.result) {
                    this.getPaymentMethods()
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /payables/contractors/deletePaymentMethod", error, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    getModalForDocUpload = (doc) => {
        const { theme } = this.props
        var { uploadedFile } = this.state
        uploadedFile = uploadedFile || { ...doc }
        const uploadProps = () => {
            return {
                name: `payables-docs`,
                multiple: false,
                action: `${environment.apiBaseUrl}/file/upload`,
                headers: {
                    businesskey: this.props.aionStore.BusinessUniqueKey,
                    jwt: this.props.aionStore.jwt
                },
                onChange: (info) => {
                    var { file } = info
                    console.log("uploadProps info file", file)
                    const { status, response, name } = file
                    if (status === "done") {
                        this.setState({
                            uploadedFile: {
                                ...uploadedFile,
                                fileName: (doc.name == "W-9") ? doc.name : name,
                                uri: (response.UploadedUrls || [])[0]
                            }
                        })
                    }
                }
            }
        }

        return (
            <FlexColumn left style={{ width: '100%' }}>
                {/* <div style={{ width: "95%" }}> */}
                <Text size='20px' color='#444444'>Upload {doc.name === "W-9" ? "W-9" : "document"}</Text>
                <Text margin='8px 0 12px'>Upload your document and add an optional note</Text>
                {/* <FlexColumn> */}
                <div style={{ width: '100%' }}>
                    <Dragger {...uploadProps(doc)}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    </Dragger>
                </div>
                {(doc.name !== "W-9") &&
                    <LabeledInput
                        label="Document type"
                        showAction={'focus'}
                        type="select"
                        size="type"
                        key="type"
                        defaultValue={doc.type}
                        placeholder="Purchase Order"
                        optionFilterProp="children"
                        onChange={(value, option) => this.setState({ uploadedFile: { ...uploadedFile, "type": value } })}
                        className="no-left-padding"
                    >
                        {docTypes.map(item =>
                            <Option key={item.type} id="type" name={item.name} value={item.type} style={{ backgroundColor: "transparent" }}>
                                {item.name}
                            </Option>
                        )}
                    </LabeledInput>}
                <LabeledInput
                    label="Note"
                    placeholder="Note"
                    key="note"
                    id="note"
                    onChange={e => { this.setState({ uploadedFile: { ...uploadedFile, "notes": e.target.value } }) }}
                />
                <Button
                    solid
                    permtype="Override"
                    style={{ marginTop: 24 }}
                    onClick={() => {
                        if (!uploadedFile.fileName) {
                            ErrorAlert({ description: "Please upload a file before saving" })
                            return
                        }
                        this.setState({
                            contractor: update(this.state.contractor, { attachments: { $set: [...(this.state.contractor.attachments || []), uploadedFile] } }),
                            showUploadModal: false
                        })
                    }}
                    text='Save' />
                {/* </FlexColumn> */}
                {/* </div> */}
            </FlexColumn>
        )
    }

    getDocumentSection = () => {
        var { contractor } = this.state
        var { attachments } = contractor || {}
        attachments = attachments || []
        const { theme } = this.props
        var documentsToUpload = [
            {
                name: `W-9`,
                type: "W-9"
            },
            {
                name: `Other`,
                type: "OTHER"
            }
        ]
        documentsToUpload.forEach(item => {
            if (attachments.find(document => document.fileName == item.name)) item.uploaded = true
        })

        const handleDeleteFile = (uri) => {
            var contractor = this.state.contractor || {}
            var attachments = (contractor.attachments || []).filter(item => item.uri !== uri)
            this.setState({
                contractor: update(this.state.contractor, { attachments: { $set: attachments } })
            })
        }

        return (
            <>
                <FlexColumn>
                    <Text size='20px' style={{ marginTop: "25px", marginBottom: '12px' }}>Documents</Text>
                    {/* <Divider style={{ margin: "0 0 0 5" }} /> */}
                    <List
                        bordered
                        loading={this.state.loading}
                        dataSource={documentsToUpload}
                        renderItem={item =>
                            <Flex centerHorizontally between style={{ padding: "6px 15px", borderBottom: `1px solid ${theme.colors.systemGray6}` }}>
                                <FlexColumn>
                                    <Text>{item.name}</Text>
                                    {
                                        item.type === "W-9" &&
                                        <Text>IRS W-9: <a href="https://www.irs.gov/pub/irs-pdf/fw9.pdf" target="_blank">https://www.irs.gov/pub/irs-pdf/fw9.pdf</a></Text>
                                    }
                                </FlexColumn>
                                {(!item.uploaded || (item.type === "OTHER")) ?
                                    <div style={{ textAlign: "right" }}>
                                        <TextButton onClick={() => { this.setState({ showUploadModal: true, uploadDoc: item, uploadedFile: null }) }} text='Upload' />
                                    </div>
                                    :
                                    <Tag style={{ margin: "5px 0" }} tagpreset={theme.colors.secondary4}>Uploaded <CheckCircleFilled /></Tag>
                                }
                            </Flex>
                        }
                    />
                    {(attachments.length > 0) &&
                        <>
                            <Text size='20px' color='#444444' style={{ marginTop: "40px", marginBottom: '12px' }}>Uploaded Documents</Text>
                            <Table
                                loading={this.state.loading}
                                dataSource={attachments}
                                pagination={false}
                                scroll={{ y: '700px', x: '100%' }}
                                rowKey='uri'
                                columns={[{
                                    title: 'Name',
                                    dataIndex: 'fileName',
                                    render: (name, item) => <SignedLink {...item} />
                                },
                                {
                                    title: 'Type',
                                    dataIndex: 'type',
                                    render: item => <Tag tagpreset={theme.colors.secondary8}>{item}</Tag>
                                },
                                {
                                    title: 'Note',
                                    dataIndex: 'notes'
                                },
                                {
                                    title: 'Action',
                                    width: "100px",
                                    render: (text, item) => (
                                        <Space size="middle">
                                            <Popconfirm title="Are you sure you want to delete this file?" onConfirm={() => handleDeleteFile(item.uri)}>
                                                <a><DeleteOutlined style={{ cursor: 'pointer', fontSize: 18 }} /></a>
                                            </Popconfirm>
                                        </Space>
                                    ),
                                    align: "right"
                                }]}
                            />
                        </>
                    }
                </FlexColumn>
            </>
        )
    }

    getDeliveryMethods = () => {
        const { theme } = this.props
        var { contractor, paymentMethods } = this.state
        var { bankDetail } = contractor || {}
        var { accountNumber } = bankDetail || {}
        var accountMask = accountNumber ? accountNumber.slice(-4) : ""
        paymentMethods = paymentMethods || []
        return (
            <FlexColumn>
                <Text size='20px' color='#444444' margin="10px 0 20px 0">Payment Methods</Text>
                <Table
                    className='borderless-table'
                    style={{ width: "100%", marginBottom: 10 }}
                    loading={this.state.loading}
                    dataSource={paymentMethods}
                    pagination={false}
                    rowKey='id'
                    columns={[{
                        title: 'Type',
                        dataIndex: 'type',
                        render: item => <Tag tagpreset={theme.colors.secondary8}>{item}</Tag>
                    },
                    {
                        title: 'Details',
                        dataIndex: 'bankDetail',
                        render: bankDetail =>
                            <>
                                {bankDetail.accountNumber && <Space style={{ color: theme.colors.secondary7 }}><span><b>Account Number: </b>{bankDetail.accountNumber}</span> | <span><b>Routing Number: </b>{bankDetail.routingNumber}</span></Space>}
                                {bankDetail.bankAddress && <span style={{ color: theme.colors.secondary7 }}><b>Mailing address: </b>{addressObjectToStr(bankDetail.bankAddress)}</span>}
                            </>
                    },
                    {
                        title: '',
                        dataIndex: 'default',
                        render: (isdefault, record) => (
                            <Space size="middle" style={{ width: "200px", justifyContent: "flex-end" }}>
                                {isdefault && <Tag tagpreset={theme.colors.primary}>{"Default".toUpperCase()}</Tag>}
                                {!isdefault && <a permtype="Receivables.Invoices" onClick={() => this.makeDefaultPaymentMethod(record)}>Make default</a>}
                                {!isdefault && <a permtype="Receivables.Invoices" onClick={() => this.deletePaymentMethod(record)}><DeleteOutlined style={{ fontSize: 18 }} /></a>}
                                <a permtype="Receivables.Invoices" onClick={() => this.setState({ showPaymentModal: true, editPm: record })}><EditOutlined style={{ fontSize: 18 }} /></a>
                            </Space>
                        ),
                        align: "right"
                    }]}
                />

                <Flex center>
                    <a style={{ fontSize: 15 }} permtype="Payables.Vendors" onClick={() => this.setState({ showPaymentModal: true, editPm: null })}>Add Payment Method</a>
                </Flex>
            </FlexColumn>
        )
    }

    getLocation = (location, formattedAddress) => {
        if (!location)
            this.setState({
                contractor: update(this.state.contractor, {
                    address: {
                        $set: {
                            line1: null,
                            line2: null,
                            city: null,
                            countrySubDivisionCode: null,
                            state: null,
                            postalCode: null
                        }
                    }
                })
            })
        else
            this.setState({
                contractor: update(this.state.contractor, {
                    address: {
                        $set: {
                            line1: location.line1,
                            line2: location.line2,
                            city: location.city,
                            countrySubDivisionCode: location.countrySubDivisionCode,
                            state: location.state,
                            postalCode: location.postalCode
                        }
                    }
                })
            })
    }

    getCheckLocation = (location, formattedAddress) => {
        console.log("getCheckLocation", location)

        if (!location)
            this.setState({
                checkAddress: {
                    // $set: {
                    line1: null,
                    line2: null,
                    city: null,
                    countrySubDivisionCode: null,
                    state: null,
                    postalCode: null
                    // }
                }
            })
        else
            this.setState({
                checkAddress: {
                    // $set: {
                    line1: location.line1,
                    line2: location.line2,
                    city: location.city,
                    countrySubDivisionCode: location.countrySubDivisionCode,
                    state: location.state,
                    postalCode: location.postalCode
                    // }
                }
            })
    }

    saveInput = (id, dataToSave) => {
        this.setState({ [id]: dataToSave })
    }

    saveUserInput = (key, value) => {
        this.setState({ contractor: update(this.state.contractor, { [key]: { $set: value } }) })
    }

    handleAccountSelect = (value, option) => {
        const Accounts = getBPAccountsWithAccess(null, { resourceStr: "Payables.Vendors", permType: "manage", getActiveAccounts: true })
        var fromAccount = Accounts.find(account => account.accountId === value)
        this.setState({ fromAccount })
    }

    addPaymentMethod = (type) => {
        var { contractor } = this.state
        var { id } = contractor || {}
        if (!id) {
            console.log("addPaymentMethod", type)
            this.handleSubmit(type)
            return
        }
        if ((type) == "ACH") this.setState({ showAddACHMethod: true })
        if ((type) == "Check") this.setState({ showAddCheckMethod: true })
    }

    saveACHPayment = (successMessage) => {
        const { contractor, fromAccount } = this.state

        var body = {
            "contractorId": contractor.id,
            "accountId": fromAccount.accountId,
            "type": contractor.type,
            "bankDetail": {
                "accountName": contractor.vendorName || "",
                "accountNumber": this.state.accountNumber || "",
                "routingNumber": this.state.routingNumber || "",
                "accountType": (this.state.accountType || "").toLowerCase()
            },
        }

        this.setState({ submitLoading: true })
        apiPOSTReq(`${environment.payBaseUrl}/payables/contractors/addTransferDelivery`, {}, body, (err, resp) => {
            this.setState({ createVendorLoading: false })
            try {
                if (err) throw Error(err)
                const data = resp || {}
                if (data.result) {
                    this.setState({ submitLoading: false })
                    message.success(successMessage)
                    // body.type = "ACH"
                    // contractor.deliveryMode = "ACH"
                    // this.props.onSave(contractor, body)

                    window.history.back()
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/createCounterparty err", error, resp, body)
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    saveCheckPayment = (successMessage) => {
        var { contractor, checkAddress, fromAccount, contractor } = this.state
        var { line1, line2, city, state, postalCode, countrySubDivisionCode } = checkAddress

        var body = {
            "contractorId": contractor.id,
            "accountId": fromAccount.accountId,
            "bankDetail": {
                "bankAddress": {
                    "line1": line1,
                    "line2": line2,
                    "city": city,
                    "state": countrySubDivisionCode,
                    "postalCode": postalCode
                }
            },
        }

        console.log("/addCheckDelivery body", JSON.stringify(body))
        this.setState({ submitLoading: true })
        apiPOSTReq(`${environment.payBaseUrl}/payables/contractors/addCheckDelivery`, {}, body, (err, resp) => {
            console.log("/addCheckDelivery err, resp", err, resp)
            this.setState({ createVendorLoading: false })
            try {
                const data = resp || {}
                if (data.result) {
                    this.setState({ submitLoading: false })
                    message.success(successMessage)
                    // body.type = "Check"
                    // contractor.deliveryMode = "Check"
                    // this.props.onSave(contractor, body)

                    window.history.back()
                } else {
                    throw Error(data.error || data.responseMessage)
                }
            } catch (error) {
                console.log("/addCheckDelivery err", error)
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    handleSubmit = (showAddPaymentMethod) => {
        var { contractor, fromAccount, paymentMethod, accountNumber, routingNumber, checkAddress } = this.state
        const { vendorName, primaryEmail, firstName, lastName, type, primaryPhone } = contractor

        if (!vendorName && (type !== "Individual")) {
            ErrorAlert({ description: "Please enter a contractor name." })
            return
        }

        if (!(firstName && lastName)) {
            ErrorAlert({ description: (type !== "Individual") ? "Please enter a contact name for the business" : "Please enter contractor first and last name." })
            return
        }

        if (!primaryEmail) {
            ErrorAlert({ description: "Please enter an email." })
            return
        }

        if (primaryEmail && !isEmailValid(primaryEmail)) {
            ErrorAlert({ description: "Please enter a valid email." })
            return
        }

        if (!primaryPhone) {
            ErrorAlert({ description: "Please enter a phone number." })
            return
        }

        if (contractor.primaryEmail === "") {
            contractor.primaryEmail = null
        }

        if (!this.props.contractor) {
            if (!fromAccount) {
                ErrorAlert({ description: "Please select an account." })
                return
            }

            if (paymentMethod === "ACH") {
                if (!accountNumber) {
                    ErrorAlert({ description: "Please enter an account number." })
                    return
                }

                if (accountNumber && (isNaN(accountNumber) || accountNumber.length > 17)) {
                    ErrorAlert({ description: "Please enter a valid account number. It can have a maximum of 17 characters." })
                    return
                }

                if (!routingNumber) {
                    ErrorAlert({ description: "Please enter a routing number." })
                    return
                }

                if (routingNumber && (isNaN(routingNumber) || routingNumber.length != 9)) {
                    ErrorAlert({ description: "Please enter a valid 9-digit routing number." })
                    return
                }
            } else if (paymentMethod === "Check") {
                if (!checkAddress) {
                    ErrorAlert({ description: "Check address is required. Please enter a valid address." })
                    return
                }

                var { line1, city, state, postalCode } = checkAddress
                if (!line1 || !city || !state || !postalCode) {
                    ErrorAlert({ description: "Check address is required. Please enter a valid address. 2" })
                    return
                }
            }
        }

        const body = {
            "contractor": contractor,
        }

        var successMessage = contractor.id ? "Contractor updated successfully!" : "Contractor created successfully!"


        this.setState({ submitLoading: true })

        apiPOSTReq(`${environment.payBaseUrl}/payables/contractors/save`, {}, body, (err, resp) => {
            try {
                const data = resp
                console.log("/payables/contractors/save", data)
                if (data.result) {
                    // message.success(contractor.id ? "Successfully updated" : `New contractor successfully created`)
                    this.setState({ contractor: data.contractor })
                    // this.getPaymentMethods()
                    // this.props.submitComplete()
                    // if (showAddPaymentMethod) this.addPaymentMethod(showAddPaymentMethod)
                    // window.history.back()
                    if (!this.props.contractor) {
                        if (paymentMethod === "ACH") {
                            this.saveACHPayment(successMessage)
                        } else if (paymentMethod === "Check") {
                            this.saveCheckPayment(successMessage)
                        }
                    } else {
                        this.setState({ submitLoading: false })
                        message.success(successMessage)
                        window.history.back()
                    }
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /payables/contractors/save", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    render() {
        var { contractor, loading, uploadItem, fromAccount, paymentMethod, accountNumber, accountType, checkAddress, submitLoading } = this.state
        const { id, vendorName, firstName, lastName, primaryEmail, primaryPhone, taxId, address, type, rate } = contractor
        const { theme, bankingStore } = this.props
        uploadItem = uploadItem || {}

        var Accounts = getBPAccountsWithAccess(null, { resourceStr: "Payables.Vendors", permType: "manage", getActiveAccounts: true }) || []

        var accountOptions = [];
        var filteredAccounts = Accounts.filter(item => { return (item.accountType !== 'ACCOUNTS_RECEIVABLE') }) || []
        filteredAccounts.forEach(account => {
            accountOptions.push(
                <Option key={account.accountId} id={id} value={account.accountId} style={{ backgroundColor: "transparent" }}>
                    <FromAccountOption account={account} />
                </Option>
            )
        })

        return (
            <FlexColumn style={{ minHeight: "85vh", justifyContent: "space-between" }}>
                <FlexColumn>
                    <Flex style={{ width: "100%", justifyContent: "space-between" }}>
                        <FlexColumn style={{ width: "48%", justifyContent: "flex-start", marginBottom: 12 }}>
                            <Text color='#444444' margin="10px 0 0 0">Contractor Details</Text>

                            <LabeledInput
                                label="Type"
                                type='switch'
                                switchNames={['Individual', 'Business']}
                                onChange={(value) => this.saveUserInput("type", value)}
                                value={type} />

                            <Flex style={{ width: "100%", justifyContent: "space-between" }}>
                                {
                                    type !== "Individual" &&
                                    <FlexColumn style={{ width: "32%" }}>
                                        <LabeledInput
                                            autoFocus
                                            label="Name"
                                            id="vendorName"
                                            key="vendorName"
                                            placeholder="Enter business name"
                                            value={vendorName}
                                            onChange={this.handleTextChange}
                                        />
                                    </FlexColumn>
                                }
                                <FlexColumn style={{ width: type === "Individual" ? "48%" : "32%" }}>
                                    <LabeledInput
                                        label={(type !== "Individual") ? "Contact First Name" : "First Name"}
                                        id="firstName"
                                        key="firstName"
                                        placeholder="John"
                                        value={firstName}
                                        onChange={this.handleTextChange}
                                    />
                                </FlexColumn>
                                <FlexColumn style={{ width: type === "Individual" ? "48%" : "32%" }}>
                                    <LabeledInput
                                        label={(type !== "Individual") ? "Contact Last Name" : "Last Name"}
                                        id="lastName"
                                        key="lastName"
                                        placeholder="Wright"
                                        value={lastName}
                                        onChange={this.handleTextChange}
                                    />
                                </FlexColumn>
                            </Flex>
                            <Flex style={{ width: "100%", justifyContent: "space-between" }}>
                                <FlexColumn style={{ width: "48%" }}>
                                    <LabeledInput
                                        label="Email"
                                        id="primaryEmail"
                                        key="primaryEmail"
                                        placeholder="Enter email"
                                        value={primaryEmail}
                                        onChange={this.handleTextChange}
                                    />
                                </FlexColumn>
                                <FlexColumn style={{ width: "48%" }}>
                                    <LabeledInput
                                        label="Phone"
                                        id="primaryPhone"
                                        key="primaryPhone"
                                        placeholder="Enter phone number"
                                        value={primaryPhone}
                                        onChange={this.handleTextChange}
                                        maxLength={10}
                                    />
                                </FlexColumn>
                            </Flex>
                            <LabeledInput
                                optional
                                label={(type !== "Individual") ? "EIN" : "SSN"}
                                id="taxId"
                                key="taxId"
                                placeholder="373888567"
                                value={taxId}
                                onChange={this.handleTextChange}
                                maxLength={9}
                            />
                            <LabeledInput
                                optional
                                id="address"
                                key="address"
                                label={(type !== "Individual") ? "Business Address" : "Address"}
                                type="location"
                                getLocation={this.getLocation}
                                Line2
                                address={address}
                                value={address && address.line1 ?
                                    { line1: addressObjectToStr(address), line2: address.line2 } : null
                                }
                                placeholder="Address"
                            />
                            {/* <Paragraph bold level={3} margin="10px 0 0 0">Wages</Paragraph>
                            <LabeledInput
                                label={"Hourly Rate"}
                                labelcolor={theme.colors.secondary3}
                                id="rate"
                                key="rate"
                                placeholder="50"
                                value={rate}
                                onChange={this.handleTextChange}
                                symbolprefix="$"
                            /> */}
                        </FlexColumn>

                        {/* <FlexColumn style={{ width: "48%", justifyContent: "flex-start" }}> */}

                        {
                            this.props.contractor ?
                                <FlexColumn style={{ width: "48%", justifyContent: "flex-start" }}>
                                    {this.getDeliveryMethods()}
                                </FlexColumn>
                                :
                                <FlexColumn style={{ width: "48%", justifyContent: "flex-start" }}>
                                    <Text color='#444444' margin="10px 0 0 0">Your Payment Account</Text>

                                    <LabeledInput
                                        label="Bank Account"
                                        showAction={'focus'}
                                        type="select"
                                        size="large"
                                        className="no-left-padding"
                                        instruction="We will use this account for your payments to this contractor"
                                        placeholder="From account"
                                        onChange={this.handleAccountSelect}
                                        value={fromAccount &&
                                            <Text capitalize>{(fromAccount.nickName || `Business ${fromAccount.accountSubType}`)} • {fromAccount.mask}</Text>}
                                    >
                                        {accountOptions}
                                    </LabeledInput>

                                    <Text color='#444444' margin="10px 0 0 0">Contractor Payment Account Details</Text>

                                    <LabeledInput
                                        label="Payment Method"
                                        type='switch'
                                        switchNames={['ACH', 'Check']}
                                        onChange={(value) => this.saveInput("paymentMethod", value)}
                                        value={paymentMethod} />

                                    {
                                        paymentMethod === "ACH" &&
                                        <>
                                            <LabeledInput
                                                autoFocus
                                                label="Account Number"
                                                name="accountNumber"
                                                key="accountNumber"
                                                value={accountNumber}
                                                placeholder="xxxxxxxx"
                                                onChange={this.handlePaymentTextChange}
                                                maxLength={17}
                                                instruction="Maximum of 17 characters"
                                            />
                                            <LabeledInput
                                                autoFocus
                                                label="Routing Number"
                                                name="routingNumber"
                                                key="routingNumber"
                                                placeholder="052001633"
                                                onChange={this.handlePaymentTextChange}
                                                maxLength={9}
                                                instruction="9 digit routing number"
                                            />

                                            <LabeledInput
                                                label="Account Type"
                                                type='switch'
                                                switchNames={['Checking', 'Savings']}
                                                onChange={(value) => this.saveInput("accountType", value)}
                                                value={accountType} />
                                        </>
                                    }

                                    {
                                        paymentMethod === "Check" &&
                                        <LabeledInput
                                            id="checkAddress"
                                            key="checkAddress"
                                            label="Check Address"
                                            type="location"
                                            getLocation={this.getCheckLocation}
                                            Line2
                                            address={checkAddress}
                                            value={checkAddress && checkAddress.line1 ?
                                                { line1: addressObjectToStr(checkAddress), line2: checkAddress.line2 } : null
                                            }
                                            placeholder="Address"
                                            instruction="We'll mail a check to this address free of cost"
                                        />
                                    }
                                </FlexColumn>
                        }
                        {/* </FlexColumn> */}
                    </Flex>

                    {this.getDocumentSection()}
                </FlexColumn>

                <Flex start style={{ width: "100%", marginTop: 48 }}>
                    <Button permtype="Payables.Contractors" solid loading={submitLoading} onClick={() => this.handleSubmit()} text={this.props.contractor ? "Update Contractor" : "Create Contractor"} />
                </Flex>

                <Modal
                    visible={this.state.showUploadModal}
                    footer={null}
                    closable={true}
                    width={700}
                    destroyOnClose={true}
                    onCancel={() => { this.setState({ showUploadModal: false }) }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    {this.getModalForDocUpload(this.state.uploadDoc || {})}
                </Modal>
                <Modal
                    visible={this.state.showPaymentModal}
                    footer={null}
                    closable={true}
                    width={700}
                    destroyOnClose={true}
                    onCancel={() => this.setState({ showPaymentModal: false, editPm: null })}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <AddPaymentMethod
                        pm={this.state.editPm}
                        contractor={contractor}
                        onSave={() => {
                            this.getPaymentMethods()
                            this.setState({ showPaymentModal: false })
                        }} />
                </Modal>
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer,
        bankingStore: state.bankingAppReducer,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(NewContractor))