import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import { Skeleton } from 'antd'
import { Flex, FlexColumn, LineItemsHeader, LineItemRow } from '../../../Reusable/Container'
import { Text, Tag } from '../../../Reusable/Text'
import { Image } from '../../../Reusable/Image'
import { LabeledInput } from '../../../Reusable/Input'
import Banner from '../../../Reusable/Banner'
import { ErrorAlert } from '../../../Reusable/Alert'
import 'draft-js/dist/Draft.css'
import moment, { isMoment } from 'moment'

import { toCurrency, addressObjectToMultiLineStr } from '../../../../Utils/util'
import { apiPOSTReq } from '../../../../Utils/api'
import environment from '../../../../environment'

import PoweredBy from '../../../../Images/PoweredByAion.png'
import Call from '../../../../Images/invoice-template/Call.png'
import Divider from '../../../../Images/invoice-template/Divider.png'
import Email from '../../../../Images/invoice-template/Email.png'
import Individual from '../../../../Images/invoice-template/Individual.png'


class ViewInvoice extends Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidMount() {
        const { invoice } = this.props.location.state
        console.log("ViewInvoice invoice", invoice)
        const businessInfo = this.props.aionStore?.business?.businessProfile?.businessInfo;
        this.setState({ logoUrl: businessInfo.logoUrl })

        this.fetchTemplate(invoice)

        this.getCustomer(invoice.customerId)
    }

    fetchTemplate(invoice) {
        this.setState({ loadingTemplates: true })
        console.log("/ive/templates/getActiveTemplateByName", invoice)
        console.log("/ive/templates/getActiveTemplateByName", { templateName: invoice.templateName })
        apiPOSTReq(`${environment.iveBaseUrl}/ive/templates/getActiveTemplateByName`, {}, { templateName: invoice.templateName }, (err, resp) => {
            try {
                const data = resp || {}
                console.log("/ive/templates/getActiveTemplateByName", data)
                if (data.result) {
                    var { template } = data
                    if (invoice) {
                        var { sections } = (template || {})
                        var lineItemHeaderSection = sections.find(item => (item.sectionName === "LineItemHeader")) || {}
                        this.setState({ selectedTemplate: template, lineItemHeaderSectionFields: lineItemHeaderSection.sectionFields, loadingTemplates: false })
                    }
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getActiveTemplateByName", error, resp)
                this.getDefaultTemplate()
            }
        })
    }

    getDefaultTemplate() {
        this.setState({ loadingTemplates: true })
        apiPOSTReq(`${environment.iveBaseUrl}/ive/templates/getActiveTemplates`, {}, {}, (err, resp) => {
            try {
                const data = resp || {}
                console.log("/ive/templates/getActiveTemplates", data)
                if (data.result) {
                    var { templates } = data

                    var selectedTemplate = templates.find(template => template.default)

                    this.setState({ selectedTemplate })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getActiveTemplates", error, resp)
                ErrorAlert({ description: error.message })
            } finally {
                this.setState({ loadingTemplates: false })
            }
        })
    }

    getCustomer(customerId) {
        const body = {
            customerId
        }
        console.log("CreateInvoice invoice /getCustomer body", body)

        this.setState({ loadingCustomers: true })
        apiPOSTReq(`${environment.iveBaseUrl}/ive/bc/getCustomer`, {}, body, (err, resp) => {
            try {
                this.setState({ loadingCustomers: false })
                const data = resp
                console.log("CreateInvoice invoice /getCustomer", data)
                if (data.result) {
                    var selectedCustomer = data.customer

                    if (selectedCustomer) {
                        this.setState({ toEmails: selectedCustomer.invoiceRecipientEmail || [], selectedCustomer })
                    }
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getActiveCustomers", error.stack, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    getSection = (sectionName) => {
        var { selectedTemplate } = this.state
        var { sections } = (selectedTemplate || {})
        sections = sections || []
        return sections.find(item => (item.sectionName === sectionName)) || {}
    }

    getSectionField = (sectionName, fieldName) => {
        var { selectedTemplate } = this.state
        var { sections } = (selectedTemplate || {})
        sections = sections || []
        const section = sections.find(item => (item.sectionName === sectionName)) || {}
        const sectionFields = section.sectionFields || []
        return sectionFields.find(item => (item.fieldName === fieldName)) || {}
    }

    render() {
        const { invoice } = this.props.location.state
        const { aionStore } = this.props
        const { loadingTemplates, logoUrl, selectedTemplate, selectedCustomer, lineItemHeaderSectionFields, activeProducts } = this.state
        var { customerName, txnDate, dueDate, terms, shipVia, shipDate, shipTrackingNumber, lineItems, discount, taxes, attachments, privateNote, billingAddress, shippingAddress,
            scheduleType, schedulePeriodType, scheduleDate, scheduleEndDate, memo, docNumber, scheduleId, invoiceStatus, emailStatus, generatedDocNumber, ponumber, subTotal, totalAmt, shipVia, shipDate, shipTrackingNumber } = invoice
        var { business, connections } = aionStore
        var { businessProfile } = business
        const businessInfo = this.props.aionStore?.business?.businessProfile?.businessInfo;
        const primaryApplicantInfo = this.props.aionStore?.business?.businessProfile?.primaryApplicantInfo;

        console.log("ViewInvoice invoice", invoice)

        if (txnDate && !isMoment(txnDate)) {
            txnDate = new Date(txnDate).toISOString()
            txnDate = txnDate.split("T")[0]
        }
        txnDate = txnDate && moment(txnDate)
        if (dueDate && !isMoment(dueDate)) {
            dueDate = new Date(dueDate).toISOString()
            dueDate = dueDate.split("T")[0]
        }
        dueDate = dueDate && moment(dueDate)

        const isCodatCustomer = connections && connections.length > 0

        return (
            <FlexColumn className='view-invoice-container' style={{ marginTop: 24 }}>
                <Skeleton loading={loadingTemplates} active title={false} paragraph={{ rows: 9 }}>
                    <Flex between fullWidth>
                        <FlexColumn>
                            <Text heading>{this.getSectionField("CompanyInformation", "Name").fieldValue || businessInfo.name}</Text>
                            <Text color={'#666'}>{addressObjectToMultiLineStr(businessInfo.mailingAddress)}</Text>
                        </FlexColumn>

                        {
                            logoUrl &&
                            <FlexColumn center style={{ height: 88, marginRight: 24 }}>
                                <img src={logoUrl} style={{ height: '48px' }} />
                            </FlexColumn>
                        }
                    </Flex>

                    {
                        selectedTemplate && (this.getSectionField("CompanyInformation", "Phone").visible || this.getSectionField("CompanyInformation", "Email").visible || this.getSectionField("CompanyInformation", "Website").visible) &&
                        <Flex start centerHorizontally gap='8px' fullWidth>
                            {
                                (this.getSectionField("CompanyInformation", "Phone").visible && (this.getSectionField("CompanyInformation", "Phone").fieldValue || businessInfo.phone)) &&
                                <>
                                    <Image src={Call} />
                                    <Text>{(this.getSectionField("CompanyInformation", "Phone").fieldValue || businessInfo.phone)}</Text>
                                </>
                            }
                            {
                                (this.getSectionField("CompanyInformation", "Email").visible && (this.getSectionField("CompanyInformation", "Email").fieldValue || primaryApplicantInfo.email)) &&
                                <>
                                    {
                                        (this.getSectionField("CompanyInformation", "Phone").visible && businessInfo.phone) &&
                                        <Image src={Divider} />
                                    }
                                    <Image src={Email} />
                                    <Text>{(this.getSectionField("CompanyInformation", "Email").fieldValue || primaryApplicantInfo.email)}</Text>
                                </>
                            }
                            {
                                (this.getSectionField("CompanyInformation", "Website").visible && (this.getSectionField("CompanyInformation", "Website").fieldValue || (businessInfo.urls && businessInfo.urls.length > 0))) &&
                                <>
                                    {
                                        ((this.getSectionField("CompanyInformation", "Phone").visible && businessInfo.phone) ||
                                            (this.getSectionField("CompanyInformation", "Email").visible && primaryApplicantInfo.Email)) &&
                                        <Image src={Divider} />
                                    }
                                    <Text underline primary>
                                        <a href={this.getSectionField("CompanyInformation", "Website").fieldValue || businessInfo.urls[0]} target="_blank">
                                            {this.getSectionField("CompanyInformation", "Website").fieldValue || businessInfo.urls[0]}
                                        </a>
                                    </Text>
                                </>
                            }
                        </Flex>
                    }

                    <div style={{ width: '100%', height: 1, background: '#E3E6EE', margin: '4px 0' }}></div>

                    <Text heading margin='0 4px 0 0' weight='600' spacing='0.8px'>Invoice # {docNumber}</Text>

                    <Text size="20px">{customerName}</Text>

                    {
                        selectedCustomer &&
                        <>
                            <Flex start stretch>
                                <FlexColumn start gap="12px">
                                    <Flex start gap="48px">
                                        <FlexColumn start style={{ width: 264, marginLeft: 0 }}>
                                            <div className="field-container" style={{ marginBottom: 12 }}>
                                                <span className="label-text">Invoice date</span>
                                                <Text weight='500'>{txnDate.format('ll')}</Text>
                                            </div>

                                            <div className="field-container" style={{ marginBottom: 0 }}>
                                                <span className="label-text">Due date</span>
                                                <Text weight='500'>{dueDate && dueDate.format('ll')}</Text>
                                            </div>
                                        </FlexColumn>

                                        <FlexColumn start style={{ width: 264, marginLeft: 0 }}>
                                            <div className="field-container" style={{ marginBottom: 12 }}>
                                                <span className="label-text">Payment terms</span>
                                                <Text>{terms}</Text>
                                            </div>

                                            <div className="field-container" style={{ marginBottom: 0 }}>
                                                <span className="label-text">Purchase order</span>
                                                <Text>{ponumber || '-'}</Text>
                                            </div>
                                        </FlexColumn>
                                    </Flex>

                                    <Flex between centerHorizontally gap="24px">
                                        <LabeledInput
                                            id="billingAddress"
                                            key="billingAddress"
                                            label="Bill to"
                                            type="read-only"
                                            nomargin
                                            width="264px"
                                            value={addressObjectToMultiLineStr(billingAddress)}
                                            padding="0"
                                        />

                                        {
                                            (this.getSection("ShipTo").visible && shippingAddress) &&
                                            <LabeledInput
                                                id="shippingAddress"
                                                key="shippingAddress"
                                                label="Ship to"
                                                type="read-only"
                                                nomargin
                                                width="264px"
                                                value={addressObjectToMultiLineStr(shippingAddress)}
                                                padding="0"
                                            />
                                        }
                                    </Flex>
                                </FlexColumn>

                                {
                                    (this.getSectionField("ShipTo", "ContactName").visible || this.getSectionField("ShipTo", "Phone").visible || this.getSectionField("ShipTo", "Email").visible) &&
                                    <FlexColumn start className="contact-container" fullHeight>
                                        <span className="section-heading-text" style={{ marginBottom: 12 }}>CONTACT DETAILS</span>
                                        {
                                            (this.getSectionField("ShipTo", "ContactName").visible && selectedCustomer.fullyQualifiedName) &&
                                            <Flex start style={{ marginBottom: 12 }} gap='8px' centerHorizontally>
                                                <Image src={Individual} />
                                                <Text>{selectedCustomer.fullyQualifiedName}</Text>
                                            </Flex>
                                        }
                                        {
                                            (this.getSectionField("ShipTo", "Phone").visible && selectedCustomer.phone) &&
                                            <Flex start style={{ marginBottom: 12 }} gap='8px' centerHorizontally>
                                                <Image src={Call} />
                                                <Text>{selectedCustomer.phone}</Text>
                                            </Flex>
                                        }
                                        {
                                            (this.getSectionField("ShipTo", "Email").visible && selectedCustomer?.primaryEmailAddress?.address) &&
                                            <Flex start gap='8px' centerHorizontally>
                                                <Image src={Email} />
                                                <Text style={{ wordWrap: 'break-word', overflowWrap: 'break-word', whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>{selectedCustomer?.primaryEmailAddress?.address}</Text>
                                            </Flex>
                                        }
                                    </FlexColumn>
                                }
                            </Flex>

                            <div style={{ width: '100%', height: 1, background: '#E3E6EE', margin: '0' }} />

                            {/* <div className="section-heading-text">PRODUCTS / SERVICES</div> */}


                            {
                                (this.getSection("ShippingDetails").visible && (shipVia || shipDate || shipTrackingNumber)) &&
                                <>
                                    <Flex start fullWidth style={{ marginTop: 0 }}>

                                        {
                                            shipDate &&
                                            <div className="address-container" style={{ marginLeft: 0, width: '33%' }}>
                                                <span className="section-heading-text" style={{ marginBottom: 8, weight: 500 }}>Ship date</span>
                                                <FlexColumn>
                                                    <Text>{moment(shipDate).format('ll')}</Text>
                                                </FlexColumn>
                                            </div>
                                        }

                                        {
                                            shipVia &&
                                            <div className="address-container" style={{ width: '33%' }}>
                                                <span className="section-heading-text" style={{ marginBottom: 8 }}>Ship via</span>
                                                <FlexColumn>
                                                    <Text>{shipVia}</Text>
                                                </FlexColumn>
                                            </div>
                                        }

                                        {
                                            shipTrackingNumber &&
                                            <div className="address-container" style={{ marginLeft: 0, width: '33%' }}>
                                                <span className="section-heading-text" style={{ marginBottom: 8 }}>Tracking #</span>
                                                <FlexColumn>
                                                    <Text>{shipTrackingNumber}</Text>
                                                </FlexColumn>
                                            </div>
                                        }
                                    </Flex>
                                </>
                            }

                            <FlexColumn gap="8px" fullWidth>
                                <LineItemsHeader>
                                    <div style={{ width: 220 }}><Text color='#333' weight='600'>{lineItemHeaderSectionFields ? lineItemHeaderSectionFields[0].fieldValue : 'Name'}</Text></div>
                                    <div style={{ flexGrow: 1 }}><Text color='#333' weight='600'>{lineItemHeaderSectionFields ? lineItemHeaderSectionFields[1].fieldValue : 'Description'}</Text></div>
                                    <div style={{ width: 100, display: "flex", justifyContent: "flex-end" }}><Text color='#333' weight='600'>{lineItemHeaderSectionFields ? lineItemHeaderSectionFields[3].fieldValue : 'Unit Price'}</Text></div>
                                    <div style={{ width: 80, display: "flex", justifyContent: "flex-end" }}><Text color='#333' weight='600'>{lineItemHeaderSectionFields ? lineItemHeaderSectionFields[2].fieldValue : 'Qty'}</Text></div>
                                    <div style={{ width: 100, display: "flex", justifyContent: "flex-end" }}><Text color='#333' weight='600'>{lineItemHeaderSectionFields ? lineItemHeaderSectionFields[4].fieldValue : 'Amount'}</Text></div>
                                </LineItemsHeader>

                                {
                                    lineItems.map((lineItem, i) => {
                                        var { itemId, productName, description, amount, qty, unitPrice } = lineItem || {};
                                        var itemId = itemId || i;
                                        return (
                                            <LineItemRow key={itemId + "container"}>
                                                <div style={{ width: 220, minWidth: 220 }}>
                                                    <Text
                                                        key={itemId + "product"}>
                                                        {productName}
                                                    </Text>
                                                </div>
                                                <div style={{ flexGrow: 1, wordWrap: 'break-word', overflowWrap: 'break-word', whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
                                                    <Text
                                                        key={itemId + "description"}>
                                                        {description}
                                                    </Text>
                                                </div>
                                                <div style={{ width: 100, minWidth: 100, display: "flex", justifyContent: "flex-end" }}>
                                                    <Text
                                                        key={itemId + "unitPrice"}>
                                                        {`$ ${toCurrency(unitPrice || 0)}`}
                                                    </Text>
                                                </div>
                                                <div style={{ width: 80, minWidth: 80, display: "flex", justifyContent: "flex-end" }}>
                                                    <Text
                                                        key={itemId + "qty"}>
                                                        {qty}
                                                    </Text>
                                                </div>
                                                <div style={{ width: 100, minWidth: 100, display: "flex", justifyContent: "flex-end" }}>
                                                    <Text
                                                        right
                                                        key={itemId + "amount"}>
                                                        {`$ ${toCurrency(amount || 0)}`}
                                                    </Text>
                                                </div>
                                            </LineItemRow>)
                                    })
                                }
                            </FlexColumn>

                            <FlexColumn right fullWidth gap='8px' style={{ paddingRight: 24 }}>
                                <Flex between style={{ width: 264, paddingRight: 0 }} centerHorizontally>
                                    <Text width='80px' right>Subtotal</Text>
                                    <Text right size='20px'>{`$ ${toCurrency(subTotal)}`}</Text>
                                </Flex>

                                {
                                    discount !== 0 &&
                                    <Flex between style={{ width: 264 }} centerHorizontally>
                                        <Text width='80px' right>Discount</Text>
                                        <Flex start centerHorizontally gap="8px">
                                            <Text right size='20px'>{`$ ${toCurrency(discount)}`}</Text>
                                        </Flex>
                                    </Flex>
                                }

                                {
                                    ((taxes && taxes !== '') || !isCodatCustomer) &&
                                    <Flex between style={{ width: 264 }} centerHorizontally>
                                        <Text width='80px' right>Taxes</Text>
                                        <Flex start centerHorizontally gap="8px">
                                            <Text right size='20px'>{`$ ${toCurrency(taxes)}`}</Text>
                                        </Flex>
                                    </Flex>
                                }

                                <div style={{ width: '100%', height: 1, background: '#F2F2F2', margin: '8px 0' }} />

                                <Flex between style={{ width: 264, paddingRight: 0 }} centerHorizontally>
                                    <Text width='80px' right weight='600'>Total</Text>
                                    <Text right size='20px'>{`$ ${toCurrency(totalAmt)}`}</Text>
                                </Flex>

                                <div style={{ width: '100%', height: 1, background: '#F2F2F2', margin: '8px 0' }} />
                            </FlexColumn>

                            {
                                this.getSection("PaymentDetails").visible &&
                                (this.getSectionField("PaymentDetails", "Account Number").visible || this.getSectionField("PaymentDetails", "ACH Routing Number").visible || this.getSectionField("PaymentDetails", "Wire Routing Number").visible ||
                                    this.getSectionField("PaymentDetails", "Check Address").visible || this.getSectionField("PaymentDetails", "Footer").visible) &&
                                <FlexColumn start className="payment-detials-container">
                                    <span className="section-heading-text" style={{ marginBottom: 16 }}>PAYMENT DETAILS</span>
                                    {/* {getSectionField("PaymentDetails", "Header").visible && <span style={{ fontSize: "1.1rem", fontWeight: 500 }}>{getSectionField("PaymentDetails", "Header").fieldValue ? getSectionField("PaymentDetails", "Header").fieldValue : "Add payment header here"}<br /></span>}<br /> */}

                                    {this.getSectionField("PaymentDetails", "Account Number").visible && <Flex between style={{ marginBottom: 8 }}>
                                        <span className="label-text payment-details-label">Account number:</span><span className="value-text">{this.getSectionField("PaymentDetails", "Account Number").fieldValue}</span>
                                    </Flex>}
                                    {this.getSectionField("PaymentDetails", "ACH Routing Number").visible && <Flex between style={{ marginBottom: 8 }}>
                                        <span className="label-text payment-details-label">ACH routing number:</span><span className="value-text">{this.getSectionField("PaymentDetails", "ACH Routing Number").fieldValue}</span>
                                    </Flex>}
                                    {this.getSectionField("PaymentDetails", "Wire Routing Number").visible && <Flex between style={{ marginBottom: 8 }}>
                                        <span className="label-text payment-details-label">Wire routing number:</span><span className="value-text">{this.getSectionField("PaymentDetails", "Wire Routing Number").fieldValue}</span>
                                    </Flex>}
                                    {/* {getSectionField("PaymentDetails", "Name").visible && <span>Bank Name: {getSectionField("PaymentDetails", "Name").fieldValue} <br /></span>} */}
                                    {(this.getSectionField("PaymentDetails", "Check Address").visible && this.getSectionField("PaymentDetails", "Check Address")?.fieldValue !== '') && <Flex between style={{ marginBottom: 8 }}>
                                        <span className="label-text payment-details-label">Check address:</span><span className="value-text">{this.getSectionField("PaymentDetails", "Check Address").fieldValue}</span>
                                    </Flex>}
                                    {this.getSectionField("PaymentDetails", "Bank Name").visible && <Flex between style={{ marginBottom: 8 }}>
                                        <span className="label-text payment-details-label">Bank name:</span><span className="value-text">{this.getSectionField("PaymentDetails", "Bank Name").fieldValue}</span>
                                    </Flex>}
                                    {this.getSectionField("PaymentDetails", "Bank Address").visible && <Flex between style={{ marginBottom: 8 }}>
                                        <span className="label-text payment-details-label">Bank address:</span><span className="value-text">{this.getSectionField("PaymentDetails", "Bank Address").fieldValue}</span>
                                    </Flex>}
                                    {/* {getSectionField("PaymentDetails", "Note").visible && <span>Note: {getSectionField("BankDetails", "Note").fieldValue}<br /><br /></span>} */}
                                    {this.getSectionField("PaymentDetails", "Footer").visible && <Text size='14px' color='#333' margin='10px 0 0'>{this.getSectionField("PaymentDetails", "Footer").fieldValue}</Text>}
                                </FlexColumn>
                            }

                            {
                                this.getSectionField("Messages", "Text").visible && this.getSectionField("Messages", "Text").fieldValue &&
                                <Text margin='0 0 8px'>{this.getSectionField("Messages", "Text").fieldValue}</Text>
                            }

                            <Flex start>
                                <Image src={PoweredBy} width="156px" height="24px" />
                            </Flex>
                        </>
                    }
                </Skeleton>
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer,
        receivablesStore: state.receivablesAppReducer,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(ViewInvoice)))