import styled from "styled-components";
import { Tabs } from 'antd'

export const MutedTabs = styled(Tabs)`
    .ant-tabs-ink-bar {
        height: 2px !important;
        background: #030342 !important;
        border-radius: 8px !important;
    }

    .ant-tabs-tab:hover {
        color: #030342 !important;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #030342 !important;
        font-weight: 500 !important;
    }
`;