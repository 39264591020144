
import { apiGET } from './api';

export const getAttributes = (callback) => {
    // Check session and fetch attributes
    apiGET("/attributes", null, (err, resp) => {
        try {
            const data = resp.data;
            if (data.success) {
                callback(null, data);
            } else {
                throw Error("Could not fetch attributes.")
            }
        } catch (error) {
            callback(error.message);
        }
    })
}