export const coaTypes = [
    "Accounts Payable",
    "Accounts Receivable",
    "Bank",
    "Cost of Goods Sold",
    "Credit Card",
    "Equity",
    "Expense",
    "Fixed Asset",
    "Income",
    "Long Term Liability",
    "Other Asset",
    "Other Current Asset",
    "Other Current Liability",
    "Other Expense",
    "Other Income",
];

export const coaSubTypes = {
    "Accounts Payable": [
        "AccountsPayable",
    ],
    "Accounts Receivable": [
        "AccountsReceivable",
    ],
    "Bank": [
        "CashOnHand",
        "Checking",
        "MoneyMarket",
        "RentsHeldInTrust",
        "Savings",
        "TrustAccounts",
    ],
    "Cost of Goods Sold": [
        "EquipmentRentalCos",
        "OtherCostsOfServiceCos",
        "ShippingFreightDeliveryCos",
        "SuppliesMaterialsCogs",
        "CostOfLaborCos",
    ],
    "Credit Card": [
        "CreditCard",
    ],
    "Equity": [
        "OpeningBalanceEquity",
        "PartnersEquity",
        "RetainedEarnings",
        "AccumulatedAdjustment",
        "OwnersEquity",
        "PaidInCapitalOrSurplus",
        "​PartnerContributions",
        "PartnerDistributions",
        "PreferredStock",
        "CommonStock",
        "TreasuryStock",
        "EstimatedTaxes",
        "Healthcare",
        "PersonalIncome",
        "PersonalExpense",
    ],
    "Expense": [
        "AdvertisingPromotional",
        "BadDebts",
        "BankCharges",
        "CharitableContributions",
        "CommissionsAndFees",
        "Entertainment",
        "EntertainmentMeals",
        "EquipmentRental",
        "FinanceCosts",
        "GlobalTaxExpense",
        "Insurance",
        "InterestPaid",
        "LegalProfessionalFees",
        "OfficeExpenses",
        "OfficeGeneralAdministrativeExpenses",
        "OtherBusinessExpenses",
        "OtherMiscellaneousServiceCost",
        "PromotionalMeals",
        "RentOrLeaseOfBuildings",
        "RepairMaintenance",
        "ShippingFreightDelivery",
        "SuppliesMaterials",
        "Travel",
        "TravelMeals",
        "Utilities",
        "Auto",
        "CostOfLabor",
        "DuesSubscriptions",
        "PayrollExpenses",
        "TaxesPaid",
        "UnappliedCashBillPaymentExpense",
        "Utilities",
    ],
    "Fixed Asset": [
        "AccumulatedDepletion",
        "AccumulatedDepreciation",
        "DepletableAssets",
        "FixedAssetComputers",
        "FixedAssetCopiers",
        "FixedAssetFurniture",
        "FixedAssetPhone",
        "FixedAssetPhotoVideo",
        "FixedAssetSoftware",
        "FixedAssetOtherToolsEquipment",
        "FurnitureAndFixtures",
        "Land",
        "LeaseholdImprovements",
        "OtherFixedAssets",
        "AccumulatedAmortization",
        "Buildings",
        "IntangibleAssets",
        "MachineryAndEquipment",
        "Vehicles",
    ],
    "Income": [
        "NonProfitIncome",
        "OtherPrimaryIncome",
        "SalesOfProductIncome",
        "ServiceFeeIncome",
        "DiscountsRefundsGiven",
        "UnappliedCashPaymentIncome",
    ],
    "Long Term Liability": [
        "NotesPayable",
        "OtherLongTermLiabilities",
        "ShareholderNotesPayable",
    ],
    "Other Asset": [
        "LeaseBuyout",
        "OtherLongTermAssets",
        "SecurityDeposits",
        "AccumulatedAmortizationOfOtherAssets",
        "Goodwill",
        "Licenses",
        "OrganizationalCosts",
    ],
    "Other Current Asset": [
        "AllowanceForBadDebts",
        "DevelopmentCosts",
        "EmployeeCashAdvances",
        "OtherCurrentAssets",
        "Inventory",
        "Investment_MortgageRealEstateLoans",
        "Investment_Other",
        "Investment_TaxExemptSecurities",
        "Investment_USGovernmentObligations",
        "LoansToOfficers",
        "LoansToOthers",
        "LoansToStockholders",
        "PrepaidExpenses",
        "Retainage",
        "UndepositedFunds",
    ],
    "Other Current Liability": [
        "DirectDepositPayable",
        "LineOfCredit",
        "LoanPayable",
        "GlobalTaxPayable",
        "GlobalTaxSuspense",
        "OtherCurrentLiabilities",
        "PayrollClearing",
        "PayrollTaxPayable",
        "PrepaidExpensesPayable",
        "RentsInTrustLiability",
        "TrustAccountsLiabilities",
        "FederalIncomeTaxPayable",
        "InsurancePayable",
        "SalesTaxPayable",
        "StateLocalIncomeTaxPayable",
    ],
    "Other Expense": [
        "Depreciation",
        "ExchangeGainOrLoss",
        "OtherMiscellaneousExpense",
        "PenaltiesSettlements",
        "Amortization",
        "GasAndFuel",
        "HomeOffice",
        "HomeOwnerRentalInsurance",
        "OtherHomeOfficeExpenses",
        "MortgageInterest",
        "RentAndLease",
        "RepairsAndMaintenance",
        "ParkingAndTolls",
        "Vehicle",
        "VehicleInsurance",
        "VehicleLease",
        "VehicleLoanInterest",
        "VehicleLoan",
        "VehicleRegistration",
        "VehicleRepairs",
        "OtherVehicleExpenses",
        "Utilities",
        "WashAndRoadServices",
    ],
    "Other Income": [
        "DividendIncome",
        "InterestEarned",
        "OtherInvestmentIncome",
        "OtherMiscellaneousIncome",
        "TaxExemptInterest",
    ],
}

export const subTypeValueToName = {
    "AccountsPayable": "Accounts Payable",
    "AccountsReceivable": "Accounts Receivable",
    "CashOnHand": "Cash On Hand",
    "Checking": "Checking",
    "MoneyMarket": "Money Market",
    "RentsHeldInTrust": "Rents Held In Trust",
    "Savings": "Savings",
    "TrustAccounts": "Trust Accounts",
    "EquipmentRentalCos": "Equipment Rental COS",
    "OtherCostsOfServiceCos": "Other Costs Of Service COS",
    "ShippingFreightDeliveryCos": "Shipping Freight Delivery COS",
    "SuppliesMaterialsCogs": "Supplies Materials Cogs",
    "CostOfLaborCos": "Cost Of Labor Cos",
    "CreditCard": "Credit Card",
    "OpeningBalanceEquity": "Opening Balance Equity",
    "PartnersEquity": "Partners Equity",
    "RetainedEarnings": "Retained Earnings",
    "AccumulatedAdjustment": "Accumulated Adjustment",
    "OwnersEquity": "Owners Equity",
    "PaidInCapitalOrSurplus": "Paid In Capital Or Surplus",
    "​PartnerContributions": "​Partner Contributions",
    "PartnerDistributions": "Partner Distributions",
    "PreferredStock": "Preferred Stock",
    "CommonStock": "Common Stock",
    "TreasuryStock": "Treasury Stock",
    "EstimatedTaxes": "Estimated Taxes",
    "Healthcare": "Healthcare",
    "PersonalIncome": "Personal Income",
    "PersonalExpense": "Personal Expense",
    "AdvertisingPromotional": "Advertising Promotional",
    "BadDebts": "Bad Debts",
    "BankCharges": "Bank Charges",
    "CharitableContributions": "Charitable Contributions",
    "CommissionsAndFees": "Commissions And Fees",
    "Entertainment": "Entertainment",
    "EntertainmentMeals": "Entertainment Meals",
    "EquipmentRental": "Equipment Rental",
    "FinanceCosts": "Finance Costs",
    "GlobalTaxExpense": "Global Tax Expense",
    "Insurance": "Insurance",
    "InterestPaid": "Interest Paid",
    "LegalProfessionalFees": "Legal Professional Fees",
    "OfficeExpenses": "Office Expenses",
    "OfficeGeneralAdministrativeExpenses": "Office General Administrative Expenses",
    "OtherBusinessExpenses": "Other Business Expenses",
    "OtherMiscellaneousServiceCost": "Other Miscellaneous ServiceCost",
    "PromotionalMeals": "Promotional Meals",
    "RentOrLeaseOfBuildings": "Rent Or Lease Of Buildings",
    "RepairMaintenance": "Repair Maintenance",
    "ShippingFreightDelivery": "Shipping Freight Delivery",
    "SuppliesMaterials": "Supplies Materials",
    "Travel": "Travel",
    "TravelMeals": "Travel Meals",
    "Utilities": "Utilities",
    "Auto": "Auto",
    "CostOfLabor": "Cost Of Labor",
    "DuesSubscriptions": "Dues Subscriptions",
    "PayrollExpenses": "Payroll Expenses",
    "TaxesPaid": "Taxes Paid",
    "UnappliedCashBillPaymentExpense": "Unapplied Cash Bill Payment Expense",
    "Utilities": "Utilities",
    "AccumulatedDepletion": "Accumulated Depletion",
    "AccumulatedDepreciation": "Accumulated Depreciation",
    "DepletableAssets": "Depletable Assets",
    "FixedAssetComputers": "FixedAsset Computers",
    "FixedAssetCopiers": "Fixed Asset Copiers",
    "FixedAssetFurniture": "Fixed Asset Furniture",
    "FixedAssetPhone": "Fixed Asset Phone",
    "FixedAssetPhotoVideo": "Fixed Asset Photo Video",
    "FixedAssetSoftware": "Fixed Asset Software",
    "FixedAssetOtherToolsEquipment": "Fixed Asset Other Tools Equipment",
    "FurnitureAndFixtures": "Furniture And Fixtures",
    "Land": "Land",
    "LeaseholdImprovements": "Leasehold Improvements",
    "OtherFixedAssets": "Other Fixed Assets",
    "AccumulatedAmortization": "Accumulated Amortization",
    "Buildings": "Buildings",
    "IntangibleAssets": "Intangible Assets",
    "MachineryAndEquipment": "Machinery And Equipment",
    "Vehicles": "Vehicles",
    "NonProfitIncome": "Non Profit Income",
    "OtherPrimaryIncome": "Other Primary Income",
    "SalesOfProductIncome": "Sales Of Product Income",
    "ServiceFeeIncome": "Service Fee Income",
    "DiscountsRefundsGiven": "Discounts Refunds Given",
    "UnappliedCashPaymentIncome": "Unapplied Cash Payment Income",
    "NotesPayable": "Notes Payable",
    "OtherLongTermLiabilities": "Other Long Term Liabilities",
    "ShareholderNotesPayable": "Shareholder Notes Payable",
    "LeaseBuyout": "Lease Buyout",
    "OtherLongTermAssets": "Other Long Term Assets",
    "SecurityDeposits": "Security Deposits",
    "AccumulatedAmortizationOfOtherAssets": "Accumulated Amortization Of Other Assets",
    "Goodwill": "Goodwill",
    "Licenses": "Licenses",
    "OrganizationalCosts": "Organizational Costs",
    "AllowanceForBadDebts": "Allowance For Bad Debts",
    "DevelopmentCosts": "Development Costs",
    "EmployeeCashAdvances": "Employee Cash Advances",
    "OtherCurrentAssets": "Other Current Assets",
    "Inventory": "Inventory",
    "Investment_MortgageRealEstateLoans": "Investment Mortgage Real Estate Loans",
    "Investment_Other": "Investment Other",
    "Investment_TaxExemptSecurities": "Investment Tax Exempt Securities",
    "Investment_USGovernmentObligations": "Investment US Government Obligations",
    "LoansToOfficers": "Loans To Officers",
    "LoansToOthers": "Loans To Others",
    "LoansToStockholders": "Loans To Stockholders",
    "PrepaidExpenses": "Prepaid Expenses",
    "Retainage": "Retainage",
    "UndepositedFunds": "Undeposited Funds",
    "DirectDepositPayable": "Direct Deposit Payable",
    "LineOfCredit": "Line Of Credit",
    "LoanPayable": "Loan Payable",
    "GlobalTaxPayable": "Global Tax Payable",
    "GlobalTaxSuspense": "Global Tax Suspense",
    "OtherCurrentLiabilities": "Other Current Liabilities",
    "PayrollClearing": "Payroll Clearing",
    "PayrollTaxPayable": "Payroll TaxPayable",
    "PrepaidExpensesPayable": "Prepaid Expenses Payable",
    "RentsInTrustLiability": "Rents In Trust Liability",
    "TrustAccountsLiabilities": "Trust Accounts Liabilities",
    "FederalIncomeTaxPayable": "Federal Income Tax Payable",
    "InsurancePayable": "Insurance Payable",
    "SalesTaxPayable": "Sales Tax Payable",
    "StateLocalIncomeTaxPayable": "State Local Income Tax Payable",
    "Depreciation": "Depreciation",
    "ExchangeGainOrLoss": "Exchange Gain Or Loss",
    "OtherMiscellaneousExpense": "Other Miscellaneous Expense",
    "PenaltiesSettlements": "Penalties Settlements",
    "Amortization": "Amortization",
    "GasAndFuel": "Gas And Fuel",
    "HomeOffice": "Home Office",
    "HomeOwnerRentalInsurance": "Home Owner Rental Insurance",
    "OtherHomeOfficeExpenses": "Other Home Office Expenses",
    "MortgageInterest": "Mortgage Interest",
    "RentAndLease": "Rent And Lease",
    "RepairsAndMaintenance": "Repairs And Maintenance",
    "ParkingAndTolls": "Parking And Tolls",
    "Vehicle": "Vehicle",
    "VehicleInsurance": "Vehicle Insurance",
    "VehicleLease": "Vehicle Lease",
    "VehicleLoanInterest": "Vehicle Loan Interest",
    "VehicleLoan": "Vehicle Loan",
    "VehicleRegistration": "Vehicle Registration",
    "VehicleRepairs": "Vehicle Repairs",
    "OtherVehicleExpenses": "Other Vehicle Expenses",
    "Utilities": "Utilities",
    "WashAndRoadServices": "Wash And Road Services",
    "DividendIncome": "Dividend Income",
    "InterestEarned": "Interest Earned",
    "OtherInvestmentIncome": "Other Investment Income",
    "OtherMiscellaneousIncome": "Other Miscellaneous Income",
    "TaxExemptInterest": "Tax Exempt Interest",
}

export const subTypeNameToValue = {
    "Accounts Payable": "AccountsPayable",
    "Accounts Receivable": "AccountsReceivable",
    "Cash On Hand": "CashOnHand",
    "Checking": "Checking",
    "Money Market": "MoneyMarket",
    "Rents Held In Trust": "RentsHeldInTrust",
    "Savings": "Savings",
    "Trust Accounts": "TrustAccounts",
    "Equipment Rental COS": "EquipmentRentalCos",
    "Other Costs Of Service COS": "OtherCostsOfServiceCos",
    "Shipping Freight Delivery COS": "ShippingFreightDeliveryCos",
    "Supplies Materials Cogs": "SuppliesMaterialsCogs",
    "Cost Of Labor Cos": "CostOfLaborCos",
    "Credit Card": "CreditCard",
    "Opening Balance Equity": "OpeningBalanceEquity",
    "Partners Equity": "PartnersEquity",
    "Retained Earnings": "RetainedEarnings",
    "Accumulated Adjustment": "AccumulatedAdjustment",
    "Owners Equity": "OwnersEquity",
    "Paid In Capital Or Surplus": "PaidInCapitalOrSurplus",
    "​Partner Contributions": "​PartnerContributions",
    "Partner Distributions": "PartnerDistributions",
    "Preferred Stock": "PreferredStock",
    "Common Stock": "CommonStock",
    "Treasury Stock": "TreasuryStock",
    "Estimated Taxes": "EstimatedTaxes",
    "Healthcare": "Healthcare",
    "Personal Income": "PersonalIncome",
    "Personal Expense": "PersonalExpense",
    "Advertising Promotional": "AdvertisingPromotional",
    "Bad Debts": "BadDebts",
    "Bank Charges": "BankCharges",
    "Charitable Contributions": "CharitableContributions",
    "Commissions And Fees": "CommissionsAndFees",
    "Entertainment": "Entertainment",
    "Entertainment Meals": "EntertainmentMeals",
    "Equipment Rental": "EquipmentRental",
    "Finance Costs": "FinanceCosts",
    "Global Tax Expense": "GlobalTaxExpense",
    "Insurance": "Insurance",
    "Interest Paid": "InterestPaid",
    "Legal Professional Fees": "LegalProfessionalFees",
    "Office Expenses": "OfficeExpenses",
    "Office General Administrative Expenses": "OfficeGeneralAdministrativeExpenses",
    "Other Business Expenses": "OtherBusinessExpenses",
    "Other Miscellaneous ServiceCost": "OtherMiscellaneousServiceCost",
    "Promotional Meals": "PromotionalMeals",
    "Rent Or Lease Of Buildings": "RentOrLeaseOfBuildings",
    "Repair Maintenance": "RepairMaintenance",
    "Shipping Freight Delivery": "ShippingFreightDelivery",
    "Supplies Materials": "SuppliesMaterials",
    "Travel": "Travel",
    "Travel Meals": "TravelMeals",
    "Utilities": "Utilities",
    "Auto": "Auto",
    "Cost Of Labor": "CostOfLabor",
    "Dues Subscriptions": "DuesSubscriptions",
    "Payroll Expenses": "PayrollExpenses",
    "Taxes Paid": "TaxesPaid",
    "Unapplied Cash Bill Payment Expense": "UnappliedCashBillPaymentExpense",
    "Utilities": "Utilities",
    "Accumulated Depletion": "AccumulatedDepletion",
    "Accumulated Depreciation": "AccumulatedDepreciation",
    "Depletable Assets": "DepletableAssets",
    "FixedAsset Computers": "FixedAssetComputers",
    "Fixed Asset Copiers": "FixedAssetCopiers",
    "Fixed Asset Furniture": "FixedAssetFurniture",
    "Fixed Asset Phone": "FixedAssetPhone",
    "Fixed Asset Photo Video": "FixedAssetPhotoVideo",
    "Fixed Asset Software": "FixedAssetSoftware",
    "Fixed Asset Other Tools Equipment": "FixedAssetOtherToolsEquipment",
    "Furniture And Fixtures": "FurnitureAndFixtures",
    "Land": "Land",
    "Leasehold Improvements": "LeaseholdImprovements",
    "Other Fixed Assets": "OtherFixedAssets",
    "Accumulated Amortization": "AccumulatedAmortization",
    "Buildings": "Buildings",
    "Intangible Assets": "IntangibleAssets",
    "Machinery And Equipment": "MachineryAndEquipment",
    "Vehicles": "Vehicles",
    "Non Profit Income": "NonProfitIncome",
    "Other Primary Income": "OtherPrimaryIncome",
    "Sales Of Product Income": "SalesOfProductIncome",
    "Service Fee Income": "ServiceFeeIncome",
    "Discounts Refunds Given": "DiscountsRefundsGiven",
    "Unapplied Cash Payment Income": "UnappliedCashPaymentIncome",
    "Notes Payable": "NotesPayable",
    "Other Long Term Liabilities": "OtherLongTermLiabilities",
    "Shareholder Notes Payable": "ShareholderNotesPayable",
    "Lease Buyout": "LeaseBuyout",
    "Other Long Term Assets": "OtherLongTermAssets",
    "Security Deposits": "SecurityDeposits",
    "Accumulated Amortization Of Other Assets": "AccumulatedAmortizationOfOtherAssets",
    "Goodwill": "Goodwill",
    "Licenses": "Licenses",
    "Organizational Costs": "OrganizationalCosts",
    "Allowance For Bad Debts": "AllowanceForBadDebts",
    "Development Costs": "DevelopmentCosts",
    "Employee Cash Advances": "EmployeeCashAdvances",
    "Other Current Assets": "OtherCurrentAssets",
    "Inventory": "Inventory",
    "Investment Mortgage Real Estate Loans": "Investment_MortgageRealEstateLoans",
    "Investment Other": "Investment_Other",
    "Investment Tax Exempt Securities": "Investment_TaxExemptSecurities",
    "Investment US Government Obligations": "Investment_USGovernmentObligations",
    "Loans To Officers": "LoansToOfficers",
    "Loans To Others": "LoansToOthers",
    "Loans To Stockholders": "LoansToStockholders",
    "Prepaid Expenses": "PrepaidExpenses",
    "Retainage": "Retainage",
    "Undeposited Funds": "UndepositedFunds",
    "Direct Deposit Payable": "DirectDepositPayable",
    "Line Of Credit": "LineOfCredit",
    "Loan Payable": "LoanPayable",
    "Global Tax Payable": "GlobalTaxPayable",
    "Global Tax Suspense": "GlobalTaxSuspense",
    "Other Current Liabilities": "OtherCurrentLiabilities",
    "Payroll Clearing": "PayrollClearing",
    "Payroll TaxPayable": "PayrollTaxPayable",
    "Prepaid Expenses Payable": "PrepaidExpensesPayable",
    "Rents In Trust Liability": "RentsInTrustLiability",
    "Trust Accounts Liabilities": "TrustAccountsLiabilities",
    "Federal Income Tax Payable": "FederalIncomeTaxPayable",
    "Insurance Payable": "InsurancePayable",
    "Sales Tax Payable": "SalesTaxPayable",
    "State Local Income Tax Payable": "StateLocalIncomeTaxPayable",
    "Depreciation": "Depreciation",
    "Exchange Gain Or Loss": "ExchangeGainOrLoss",
    "Other Miscellaneous Expense": "OtherMiscellaneousExpense",
    "Penalties Settlements": "PenaltiesSettlements",
    "Amortization": "Amortization",
    "Gas And Fuel": "GasAndFuel",
    "Home Office": "HomeOffice",
    "Home Owner Rental Insurance": "HomeOwnerRentalInsurance",
    "Other Home Office Expenses": "OtherHomeOfficeExpenses",
    "Mortgage Interest": "MortgageInterest",
    "Rent And Lease": "RentAndLease",
    "Repairs And Maintenance": "RepairsAndMaintenance",
    "Parking And Tolls": "ParkingAndTolls",
    "Vehicle": "Vehicle",
    "Vehicle Insurance": "VehicleInsurance",
    "Vehicle Lease": "VehicleLease",
    "Vehicle Loan Interest": "VehicleLoanInterest",
    "Vehicle Loan": "VehicleLoan",
    "Vehicle Registration": "VehicleRegistration",
    "Vehicle Repairs": "VehicleRepairs",
    "Other Vehicle Expenses": "OtherVehicleExpenses",
    "Utilities": "Utilities",
    "Wash And Road Services": "WashAndRoadServices",
    "Dividend Income": "DividendIncome",
    "Interest Earned": "InterestEarned",
    "Other Investment Income": "OtherInvestmentIncome",
    "Other Miscellaneous Income": "OtherMiscellaneousIncome",
    "Tax Exempt Interest": "TaxExemptInterest",
}

export const defaultSubType = {
    "Accounts Payable": "AccountsPayable",
    "Accounts Receivable": "AccountsReceivable",
    "Bank": "CashOnHand",
    "Cost of Goods Sold": "CostOfLaborCos",
    "Credit Card": "CreditCard",
    "Equity": "OpeningBalanceEquity",
    "Expense": "Travel",
    "Fixed Asset": "FurnitureAndFixtures",
    "Income": "OtherPrimaryIncome",
    "Long Term Liability": "NotesPayable",
    "Other Asset": "Licenses",
    "Other Current Asset": "EmployeeCashAdvances",
    "Other Current Liability": "OtherCurrentLiabilities",
    "Other Expense": "Depreciation",
    "Other Income": "OtherInvestmentIncome",
}