import React, { useState, useEffect } from 'react'
import { saveAs } from 'file-saver'

import { Text } from '../../Reusable/Text'
import { Flex, FlexColumn, LightContainer } from '../../Reusable/Container'
import { LabeledInput } from '../../Reusable/Input'
import { Image } from '../../Reusable/Image'
import { TextButton } from '../../Reusable/Button'
import Banner from '../../Reusable/Banner'
import HiddenInfo from '../../Reusable/HiddenInfo'
import Info from '../../../Images/info.png';
import InfoRed from '../../../Images/info-red.png';
import { apiPOSTReq, getPreSignedS3Url, apiGETDocUrl } from '../../../Utils/api'
import { ErrorAlert } from '../../Reusable/Alert'
import environment from '../../../environment'
import Download from '../../../Images/download.png'
import DownloadMute from '../../../Images/download-mute.svg'
import moment from 'moment'


const downloadAccountVerificationPDF = (options, callback) => {
    var { transferMethod, transferId, referenceId } = options || {}
    var query = {
        TransferMethod: transferMethod,
        TransferId: transferId
    }

    apiGETDocUrl(`${environment.bbBaseUrl}/bb/export/pdf/exportPDFXferReceipt`, { BankProvider: environment.bankProvider.crb }, query, (err, resp) => {
        const data = (resp || {}).data
        try {
            if (err) throw Error("We had trouble downloading your transfer receipt. Please try again.")
            const blob = new Blob([data], {
                type: 'application/pdf',
            })
            const fileURL = URL.createObjectURL(blob)
            saveAs(fileURL, `Transfer Receipt ${referenceId || transferId}`)
            callback(null)
        } catch (error) {
            ErrorAlert({ description: error.message })
            callback(error.message)
        }
    })
}

export const ACHDetail = (props) => {
    const [loading, setLoading] = useState(false)
    const [xferReceiptLoading, setXferReceiptLoading] = useState(false)

    const { detail, viewReturn, record, onViewOriginal } = props
    var { transactionCode } = record || {}
    const { traceNumber, referenceId, description, userNote, objectId, originator, direction, serviceType, paymentType, error, original, wasReturned, purpose, remittanceInfo, status, id } = detail || {}
    console.log("ACHDetail", id)
    return <FlexColumn start gap='24px'>
        <LightContainer fullWidth>
            <Flex start wrap gap='24px' key={objectId} style={{ maxWidth: '900px' }}>
                {
                    referenceId &&
                    <div style={{ width: '248px' }}>
                        <LabeledInput
                            label='Reference ID'
                            key={`${objectId}-referenceId`}
                            nomargin
                            type='read-only'
                            value={referenceId}
                            copy
                            mute
                            noWidth
                        />
                    </div>
                }
                {
                    traceNumber &&
                    <div style={{ width: '248px' }}>
                        <HiddenInfo label='Trace Number' value={traceNumber} />
                    </div>
                }

                {
                    (direction !== 'Outbound') && originator && originator.name &&
                    <LabeledInput
                        label='Originator'
                        key={`${objectId}-originator`}
                        nomargin
                        type='read-only'
                        value={originator.name}
                        width='248px'
                    />
                }

                {
                    (purpose || description) &&
                    <LabeledInput
                        label='Purpose'
                        key={`${objectId}-purpose`}
                        nomargin
                        type='read-only'
                        value={purpose || description}
                        width='248px'
                    />
                }

                {
                    (remittanceInfo || userNote) &&
                    <LabeledInput
                        label='Remittance Information'
                        key={`${objectId}-remittanceInformation`}
                        nomargin
                        type='read-only'
                        value={remittanceInfo || userNote}
                        width='248px'
                    />
                }

                {
                    serviceType &&
                    <LabeledInput
                        label='Delivery Type'
                        key={`${objectId}-serviceType`}
                        nomargin
                        type='read-only'
                        value={serviceType === 'SameDay' ? 'Same Day' : serviceType}
                        width='248px'
                    />
                }

                {
                    paymentType && paymentType === 'Return' &&
                    <LabeledInput
                        label='Return Code & Description'
                        key={`${objectId}-returnCode`}
                        nomargin
                        type='read-only'
                        value={error}
                        width='248px'
                    />
                }

                {
                    (status == "sent") && (direction == 'Outbound') &&
                    <FlexColumn style={{ width: 248 }} gap='4px'>
                        <Text color={'#7384AA'} size='14px' weight={400}>Transfer Receipt</Text>
                        <TextButton
                            text="Download"
                            rightIcon={<img style={{ cursor: 'pointer' }} width='20px' height='20px' src={Download} />}
                            onClick={() => {
                                setXferReceiptLoading(true)
                                downloadAccountVerificationPDF({ transferMethod: "ACH", transferId: id, referenceId: referenceId }, (err, resp) => {
                                    setXferReceiptLoading(false)
                                })
                            }}
                            loading={xferReceiptLoading}
                        />
                    </FlexColumn>
                }
            </Flex>
        </LightContainer>

        {
            (paymentType === 'Return' || transactionCode === "Incoming ACH Return") &&
            <Banner
                icon={<Image src={Info} />}
                style={{ marginTop: "0", width: "100%" }}
                message="This is an ACH Return"
                cta={viewReturn && <TextButton loading={loading} onClick={() => {
                    var body = {
                        viewBy: 'ACH',
                        objectId: original.paymentId,
                    }

                    setLoading(true)

                    apiPOSTReq(`${environment.bbBaseUrl}/bb/getObjectDetail`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
                        try {
                            var data = resp || {}
                            if (err) throw Error(err)
                            if (!resp.result) throw Error(resp.responseMessage || resp.error)

                            var achTransferObj = data.achTransferObj

                            if (achTransferObj) {
                                onViewOriginal(achTransferObj.referenceId)
                            } else {
                                ErrorAlert({ description: "Error viewing original payment" })
                            }

                            console.log('bb/getObjectDetail ActivityTable objectDetail', data)

                        } catch (error) {
                            console.error('bb/getObjectDetail', error)
                            ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
                        } finally {
                            setLoading(false)
                        }
                    })
                }} text='View Original' underline weight='400' />}
            />
        }

        {
            (wasReturned || record.wasReturned) &&
            <Banner
                background='#FEF5F5'
                color='#E52D2D'
                borderColor='#FAD5D5'
                icon={<Image src={InfoRed} />}
                style={{ marginTop: "0", width: "100%" }}
                message="This transaction was returned"
                cta={viewReturn && <TextButton loading={loading} color='#E52D2D' onClick={() => {
                    var body = {
                        viewBy: 'ACH_RETURN',
                        objectId: record.achId ? record.achId : record.id,
                    }

                    console.log('bb/getObjectDetail ActivityTable objectDetail body', body)

                    setLoading(true)

                    apiPOSTReq(`${environment.bbBaseUrl}/bb/getObjectDetail`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
                        try {
                            var data = resp || {}
                            if (err) throw Error(err)
                            if (!resp.result) throw Error(resp.responseMessage || resp.error)

                            var achTransferObj = data.achTransferObj

                            if (achTransferObj) {
                                onViewOriginal(achTransferObj.referenceId)
                            } else {
                                ErrorAlert({ description: "Error viewing reversal payment" })
                            }

                            console.log('bb/getObjectDetail ActivityTable objectDetail', data)

                        } catch (error) {
                            console.error('bb/getObjectDetail', error)
                            ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
                        } finally {
                            setLoading(false)
                        }
                    })
                }} text='View Return' underline weight='400' />}
            />
        }
    </FlexColumn>
}

export const WireDetail = (props) => {
    const [loading, setLoading] = useState(false)
    const [xferReceiptLoading, setXferReceiptLoading] = useState(false)

    const { detail, record, onViewOriginal, viewReturn } = props
    const { traceNumber, referenceId, userNote, objectId, imad, originator, direction, purpose, originatorToBeneficiary5,
        remittanceInfo, instructions, status, id, wasReversed, paymentType, original, originalPaymentId, fiToFiInfos } = detail || {}

    return <FlexColumn start gap='24px'>
        <LightContainer fullWidth>
            <Flex start wrap gap='24px' key={objectId}>
                {
                    referenceId &&
                    <div style={{ width: '248px' }}>
                        <LabeledInput
                            label='Reference ID'
                            key={`${objectId}-referenceId`}
                            nomargin
                            type='read-only'
                            value={referenceId}
                            copy
                            mute
                            noWidth
                        />
                    </div>
                }

                {
                    imad &&
                    <div style={{ width: 250, marginRight: 24 }}>
                        <HiddenInfo label='FedWire Reference' value={imad} />
                    </div>
                }

                {
                    (direction !== 'Outbound') && originator && originator.name &&
                    <LabeledInput
                        label='Originator'
                        key={`${objectId}-originator`}
                        nomargin
                        type='read-only'
                        value={originator.name}
                        width='248px'
                    />
                }

                {
                    (purpose || userNote || instructions) &&
                    <LabeledInput
                        label='Purpose'
                        key={`${objectId}-purpose`}
                        nomargin
                        type='read-only'
                        value={purpose || userNote || instructions}
                        width='248px'
                    />
                }

                {
                    (status == "sent") && (direction == 'Outbound') &&
                    <FlexColumn style={{ width: 248 }} gap='4px'>
                        <Text color={'#7384AA'} size='14px' weight={400}>Transfer Receipt</Text>
                        <TextButton
                            text="Download"
                            rightIcon={<img style={{ cursor: 'pointer' }} width='20px' height='20px' src={Download} />}
                            onClick={() => {
                                setXferReceiptLoading(true)
                                downloadAccountVerificationPDF({ transferMethod: "Wire", transferId: id, referenceId: referenceId }, (err, resp) => {
                                    setXferReceiptLoading(false)
                                })
                            }}
                            loading={xferReceiptLoading}
                        />
                    </FlexColumn>
                }

                {
                    (remittanceInfo || originatorToBeneficiary5) &&
                    <LabeledInput
                        label='Remittance Information'
                        key={`${objectId}-remittanceInformation`}
                        nomargin
                        type='read-only'
                        value={remittanceInfo || originatorToBeneficiary5}
                        width='480px'
                    />
                }

                {
                    (fiToFiInfos && fiToFiInfos.length > 0) &&
                    <LabeledInput
                        label='Reversal Information'
                        key={`${objectId}-reversalInformation`}
                        nomargin
                        type='read-only'
                        value={fiToFiInfos.map((item, index) => (
                            <React.Fragment key={index}>
                              {item}
                              {index < fiToFiInfos.length - 1 && <br />}
                            </React.Fragment>
                          ))}
                        width='480px'
                    />
                }
            </Flex>
        </LightContainer>

        {
            (paymentType === 'Reversal') &&
            <Banner
                icon={<Image src={Info} />}
                style={{ marginTop: "0", width: "100%" }}
                message="This is a Wire Reversal"
                cta={viewReturn && <TextButton loading={loading} onClick={() => {
                    var body = {
                        viewBy: 'WIRE_TFER',
                        objectId: originalPaymentId,
                    }

                    console.log('bb/getObjectDetail ActivityTable objectDetail body', body)

                    setLoading(true)

                    apiPOSTReq(`${environment.bbBaseUrl}/bb/getObjectDetail`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
                        try {
                            var data = resp || {}
                            if (err) throw Error(err)
                            if (!resp.result) throw Error(resp.responseMessage || resp.error)

                            console.log('bb/getObjectDetail ActivityTable objectDetail', data)

                            var wireTransferObj = data.wireTransferObj

                            if (wireTransferObj) {
                                onViewOriginal(wireTransferObj.referenceId)
                            } else {
                                ErrorAlert({ description: "Error viewing original payment" })
                            }

                        } catch (error) {
                            console.error('bb/getObjectDetail', error)
                            ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
                        } finally {
                            setLoading(false)
                        }
                    })
                }} text='View Original' underline weight='400' />}
            />
        }

        {
            (wasReversed || record.wasReversed) &&
            <Banner
                background='#FEF5F5'
                color='#E52D2D'
                borderColor='#FAD5D5'
                icon={<Image src={InfoRed} />}
                style={{ marginTop: "0", width: "100%" }}
                message="This transaction was reversed"
                cta={viewReturn && <TextButton loading={loading} color='#E52D2D' onClick={() => {
                    var body = {
                        viewBy: 'WIRE_REVERSAL',
                        objectId: record.wireId ? record.wireId : record.id,
                    }

                    console.log('bb/getObjectDetail ActivityTable objectDetail body', body)

                    setLoading(true)

                    apiPOSTReq(`${environment.bbBaseUrl}/bb/getObjectDetail`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
                        try {
                            var data = resp || {}

                            console.log('bb/getObjectDetail ActivityTable objectDetail', data)

                            if (err) throw Error(err)
                            if (!resp.result) throw Error(resp.responseMessage || resp.error)

                            var wireTransferObj = data.wireTransferObj

                            if (wireTransferObj) {
                                onViewOriginal(wireTransferObj.referenceId)
                            } else {
                                ErrorAlert({ description: "Error viewing reversal payment" })
                            }

                            console.log('bb/getObjectDetail ActivityTable objectDetail', data)

                        } catch (error) {
                            console.error('bb/getObjectDetail', error)
                            ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
                        } finally {
                            setLoading(false)
                        }
                    })
                }} text='View Reversal' underline weight='400' />}
            />
        }
    </FlexColumn>
}

export const CheckDetail = (props) => {
    const { detail } = props
    const { checkNumber, referenceId, source, objectId, frontImage, backImage, direction, returnCodeDescription, returnCode, remittenceUrl } = detail || {}

    if ((frontImage && frontImage.imageBase64Content) && !frontImage.imageBase64Content.includes('data:image')) frontImage.imageBase64Content = `data:image/jpeg;base64,${frontImage.imageBase64Content}`
    if ((backImage && backImage.imageBase64Content) && !backImage.imageBase64Content.includes('data:image')) backImage.imageBase64Content = `data:image/jpeg;base64,${backImage.imageBase64Content}`

    console.log('CheckDetail', detail)

    return <FlexColumn start gap='24px'>
        {
            (checkNumber || referenceId || source || returnCodeDescription) &&
            <LightContainer fullWidth>
                <Flex start wrap gap='24px' key={objectId}>
                    {
                        referenceId &&
                        <div style={{ width: '248px' }}>
                            <LabeledInput
                                label='Reference ID'
                                key={`${objectId}-referenceId`}
                                nomargin
                                type='read-only'
                                value={referenceId}
                                noWidth
                                copy
                                mute
                            />
                        </div>
                    }
                    {
                        checkNumber &&
                        <div style={{ width: '248px' }}>
                            <LabeledInput
                                label='Check Number'
                                key={`${objectId}-checkNumber`}
                                nomargin
                                noWidth
                                type='read-only'
                                value={checkNumber}
                                copy
                                mute
                            />
                        </div>
                    }

                    {
                        source && (direction != "Inbound") &&
                        <LabeledInput
                            label='Source'
                            key={`${objectId}-checkNumber`}
                            nomargin
                            type='read-only'
                            value={source === 'Api' ? 'Mobile Check Deposit' : 'Lockbox (Lighthouse)'}
                            width='248px'
                        />
                    }

                    {
                        returnCodeDescription &&
                        <LabeledInput
                            label='Return Code & Description'
                            key={`${objectId}-returnCode`}
                            nomargin
                            type='read-only'
                            value={`${returnCode} - ${returnCodeDescription}`}
                            width='248px'
                        />
                    }

                    {
                        remittenceUrl &&
                        <FlexColumn style={{ width: 248 }} gap='4px'>
                            <Text color={'#7384AA'} size='14px' weight={400}>Remittance Information</Text>
                            <TextButton
                                text="Download"
                                rightIcon={<img style={{ cursor: 'pointer' }} width='20px' height='20px' src={Download} />}
                                onClick={() => {
                                    getPreSignedS3Url({ url: remittenceUrl }, (err, preSignedUrl) => {
                                        saveAs(preSignedUrl, checkNumber)
                                    })
                                }}
                                loading={false}
                            />
                        </FlexColumn>
                    }
                </Flex>
            </LightContainer>
        }

        {
            (frontImage || backImage) &&
            <Flex start wrap gap='24px' key={objectId}>
                {
                    frontImage &&
                    <img className='check' height='100px' src={frontImage.imageBase64Content} onClick={() => saveAs(frontImage.imageBase64Content, referenceId)} />
                }

                {
                    backImage &&
                    <img className='check' height='100px' src={backImage.imageBase64Content} onClick={() => saveAs(backImage.imageBase64Content, referenceId)} />
                }
            </Flex>
        }
    </FlexColumn>
}

export const InternalDetail = (props) => {
    const { detail, instant } = props
    const { traceNumber, referenceId, userNote, objectId, purpose, remittanceInfo } = detail || {}

    return <FlexColumn start gap='24px'>
        {
            (traceNumber || referenceId || userNote) &&
            <LightContainer fullWidth>
                <Flex start wrap gap='24px' key={objectId}>
                    {
                        referenceId &&
                        <div style={{ width: '248px' }}>
                            <LabeledInput
                                label='Reference ID'
                                key={`${objectId}-referenceId`}
                                nomargin
                                type='read-only'
                                value={referenceId}
                                noWidth
                                copy
                                mute
                            />
                        </div>
                    }

                    {
                        traceNumber &&
                        <div style={{ width: '248px' }}>
                            <HiddenInfo label='Trace Number' value={traceNumber} />
                            {/* <LabeledInput
                                label='Trace Number'
                                key={`${objectId}-traceNumber`}
                                nomargin
                                noWidth
                                type='read-only-password'
                                value={traceNumber}
                            /> */}
                        </div>
                    }

                    {
                        (purpose || userNote) &&
                        <LabeledInput
                            label={'Purpose'}
                            key={`${objectId}-purpose`}
                            nomargin
                            type='read-only'
                            value={purpose || userNote}
                            width='248px'
                        />
                    }

                    {/* {
                        (remittanceInfo) &&
                        <LabeledInput
                            label={'Remittance Information'}
                            key={`${objectId}-rInfo`}
                            nomargin
                            type='read-only'
                            value={remittanceInfo}
                            width='248px'
                        />
                    } */}
                </Flex>
            </LightContainer>
        }
    </FlexColumn>
}

export const InstantDetail = (props) => {
    var { detail, traceNumber } = props
    var { referenceId, remittanceInfo, purpose, objectId, rtpPaymentInfo } = detail || {}

    rtpPaymentInfo = rtpPaymentInfo || {}
    if (rtpPaymentInfo.referenceId) referenceId = rtpPaymentInfo.referenceId
    return <FlexColumn start gap='24px'>
        {
            (traceNumber || referenceId || remittanceInfo) &&
            <LightContainer fullWidth>
                <Flex start wrap gap='24px' key={objectId}>
                    {
                        referenceId &&
                        <div style={{ width: '248px' }}>
                            <LabeledInput
                                label='Reference ID'
                                key={`${objectId}-referenceId`}
                                nomargin
                                type='read-only'
                                value={referenceId}
                                noWidth
                                copy
                                mute
                            />
                        </div>
                    }

                    {/*
                        traceNumber &&
                        <div style={{ width: '248px' }}>
                            <HiddenInfo label='Trace Number' value={traceNumber} />
                        </div>
                    */}

                    {
                        (purpose) &&
                        <LabeledInput
                            label={'Purpose'}
                            key={`${objectId}-purpose`}
                            nomargin
                            type='read-only'
                            value={purpose}
                            width='248px'
                        />
                    }

                    {
                        (remittanceInfo) &&
                        <LabeledInput
                            label={'Remittance Information'}
                            key={`${objectId}-rInfo`}
                            nomargin
                            type='read-only'
                            value={remittanceInfo}
                            width='248px'
                        />
                    }
                </Flex>
            </LightContainer>
        }
    </FlexColumn>
}

export const CardDetail = (props) => {
    const { detail, hideCardInfo } = props
    const { traceNumber, referenceId, userNote, objectId, panLastFour, merchantNameLocation, merchantName, retrievalReferenceNumber } = detail || {}

    console.log('AccountDetail CardDetail props: ', props)

    return <FlexColumn start gap='24px'>
        <LightContainer fullWidth>
            <Flex start wrap gap='24px' key={objectId}>
                {
                    retrievalReferenceNumber &&
                    <div style={{ width: '248px' }}>
                        <LabeledInput
                            label='Reference Number'
                            key={`${objectId}-Reference Number`}
                            nomargin
                            type='read-only'
                            value={retrievalReferenceNumber}
                        />
                    </div>
                }

                {
                    !hideCardInfo && panLastFour &&
                    <div style={{ width: '248px' }}>
                        <LabeledInput
                            label='Card Ending'
                            key={`${objectId}-cardNo`}
                            nomargin
                            type='read-only'
                            value={panLastFour}
                        />
                    </div>
                }

                {
                    !hideCardInfo && (merchantNameLocation || merchantName) &&
                    <div style={{ minWidth: '248px' }}>
                        <LabeledInput
                            label='Merchant & Location'
                            key={`${objectId}-mnl`}
                            nomargin
                            type='read-only'
                            value={merchantNameLocation || merchantName}
                        />
                    </div>
                }
            </Flex>
        </LightContainer>
    </FlexColumn>
}