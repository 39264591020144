import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';

// Components
import { Button } from '../../../Reusable/Button';
import { Text } from '../../../Reusable/Text';
import { LabeledInput } from '../../../Reusable/Input'
import { Flex, FlexColumn } from '../../../Reusable/Container';
import { ErrorAlert } from '../../../Reusable/Alert';
import environment from '../../../../environment';
import { apiPOSTReq } from '../../../../Utils/api';


class SetupMailbox extends Component {

    state = {
        loading: false
    }

    handleChange = (event) => {
        console.log("event", event)
        if (event.target.id === "mailboxId") {
            this.setState({ mailboxId: event.target.value });
        }
    }

    handleSubmit = async (event) => {
        const { mailboxId } = this.state;
        if (mailboxId === '') {
            ErrorAlert({ description: "Please enter a email id." });
            return;
        }
        this.setState({ loading: true });
        var body = { billInboxAddress: `${mailboxId}@${environment.billsDomain}` };
        apiPOSTReq(`${environment.payBaseUrl}/payables/bills/setupBillInbox`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            try {
                this.setState({ loading: false });
                const data = resp || {};
                console.log("/payables/bills/setupBillInbox", err, data, body);
                if (data.result) {
                    this.setState({ billInboxSetups: data.billInboxSetups || "" });
                    this.props.setupComplete(data.billInboxSetups);
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /payables/bills/setupBillInbox", error, err, resp);
                ErrorAlert({ description: error.message });
            }
        });
    }

    render() {
        const { theme } = this.props;
        const { mailboxId } = this.state;

        return (
            <FlexColumn between gap='24px'>
                <FlexColumn left start>
                    <Text heading>Set up your Inbox</Text>
                    <Text>Create a custom email box to receive bills from your vendors</Text>
                    <FlexColumn gap='4px' style={{ margin: '24px 0' }}>
                        <Flex centerHorizontally gap='8px'>
                            <LabeledInput
                                nomargin
                                label="Email"
                                placeholder="Type your preferred email id"
                                id='mailboxId'
                                value={mailboxId}
                                onChange={this.handleChange}
                                width="300px"
                            />
                            <Text style={{ marginTop: 20 }}>@{environment.billsDomain}</Text>
                        </Flex>
                        <Text color={theme.colors.systemGray} style={{ marginTop: 10 }}>{`E.g: business_name@${environment.billsDomain}`}</Text>
                    </FlexColumn>
                    
                </FlexColumn>
                <Flex start>
                    <Button solid permtype="Override" type="primary" loading={this.state.loading} onClick={this.handleSubmit} text='Save' />
                </Flex>
            </FlexColumn>
        );
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(SetupMailbox));