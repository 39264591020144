import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { withTheme } from 'styled-components';

// Util
import { apiGET } from '../../Utils/api'

// Actions
import { completeSignin, CREDIT_SAVE_DATA, addDataToStore } from '../../Actions/actions'

class Dashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false
        }
    }

    componentDidMount() {
        if (!this.props.store.AllCustomers || this.props.store.AllCustomers.length == 0) {
            const applicationSubmitted = new URLSearchParams(this.props.location.search).get("applicationSubmitted")

            if (applicationSubmitted === 'true') {
                this.props.history.replace('/receivables')
                this.setState({ loading: true })
            }

            this.getAttributes()
            this.fetchPOs()
        } else {
            this.props.history.push('/receivables/customers')
        }
    }

    getAttributes() {
        // Check session and fetch attributes
        apiGET("/attributes", null, (err, resp) => {
            try {
                const data = resp.data;
                if (data.success) {
                    this.props.dispatch(completeSignin(data));
                    this.setState({ loading: false });
                } else {
                    throw Error("Could not fetch attributes.")
                }
            } catch (error) {
                console.log("/attributes ERRR", error.stack)
            }
        })
    }

    fetchPOs() {
        const headers = {
            businesskey: this.props.aionStore.BusinessUniqueKey
        }
        apiGET("/financing/invoices/po", headers, (err, resp) => {
            try {
                const data = resp.data;
                if(data.success) {
                    this.props.dispatch(addDataToStore(CREDIT_SAVE_DATA, data));
                } else {
                    throw Error("Could not fetch purchase orders.")
                }
            } catch (error) {
                console.log("ERRR", error.stack);
            }
        });
    }

    render() {
        return (
            <div></div>
        );
    }
}

function mapStateToProps(state) {
    return {
        store: state.creditAppReducer,
        aionStore: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Dashboard)));