import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';
import {
    Table,
    Menu,
    message,
    Dropdown,
    Button,
    Tabs,
    Space,
    Select,
    Popover
} from 'antd';

// Components
import { FlexColumn, Flex, CardContainer } from '../../Reusable/Container';
import PageHeader from '../../Reusable/PageHeader';
import { StyledLink } from '../../Reusable/Link';
import { apiPOSTReq } from '../../../Utils/api';
import { toCurrency } from '../../../Utils/util';
import environment from '../../../environment';
import { ErrorAlert } from '../../Reusable/Alert';
import moment from 'moment';
import { DownOutlined } from '@ant-design/icons';
import { LabeledInput } from '../../Reusable/Input';
import TableView from '../../Reusable/TableView'
import { TextButton } from '../../Reusable/Button';
import ChevronDown from "../../../Images/chevron-down.svg"
import { Text } from '../../Reusable/Text';

const dateFormat = 'YYYY-MM-DD';
const { Option } = Select;

class Index extends Component {

    state = {
        tab: "PreScreen",
        applicationType: "prescreen",
        pagination: {
            current: 1,
            pageSize: 50
        }
    };

    componentDidMount() {
        const applicationType = this.props.location.pathname.includes("duediligence") ? "duediligence" : "prescreen";

        this.setState({ applicationType: applicationType });
        this.fetchData({ ...this.state, applicationType: applicationType });
    }

    fetchData = (options) => {
        const { sortDirection, sortFieldname } = this.state;
        var { pagination, applicationType, tab, sorter, searchTerm, filterType, fromDate, toDate, } = options || {};
        var body = {
            "size": pagination.pageSize,
            "page": pagination.current - 1,
            "sortDirection": "DESC"
        };

        filterType = filterType || null;
        sorter = sorter || {}
        if (sorter.field) {
            if (sorter.field === sortFieldname) {
                body.sortDirection = (sortDirection === "DESC") ? "ASC" : "DESC"
            } else {
                body.sortDirection = "ASC"
            }
            body.sortFieldname = sorter.field
        } else {
            if (sortFieldname) {
                body.sortFieldname = sortFieldname
            }
            body.sortDirection = sortDirection
        }

        if (searchTerm && searchTerm != "") {
            body.searchStr = searchTerm
        }

        if (applicationType == "duediligence") {
            // body["ViewBy"] = (tab == "PreScreen") ? "FILTER_PENDING_APPLICATION" : "FILTER_COMPLETED_APPLICATION";
            // body["Status"] = "Approved";
            body["ViewBy"] = (tab == "Completed") ? "FILTER_COMPLETED_APPLICATION" : "FILTER_PENDING_APPLICATION";
        } else {
            // body["ViewBy"] = (tab == "PreScreen") ? "FILTER_PENDING_PRESCREEN_APPLICATION" : "FILTER_COMPLETED_PRESCREEN_APPLICATION";
            body["ViewBy"] = "FILTER_PENDING_PRESCREEN_APPLICATION";
        }

        if (filterType) {
            body.viewBy = "FILTER_UNDERWRITER"
            body.status = filterType;
        }

        if (fromDate) {
            body.viewBy = "FILTER_MODIFIED_DATE"
            body.fromDate = fromDate
            body.toDate = toDate
        }

        console.log("/getApplications body", body);
        this.setState({ loading: true })
        apiPOSTReq(`${environment.opsBaseUrl}/ops/loans/application/getApplications`, null, body, (err, resp) => {
            console.log("/getApplications err, resp", err, resp);
            this.setState({ loading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    var loanApplications = pagination.current > 1 ? this.state.loanApplications.concat(data.loanApplications) : data.loanApplications
                    var moreLoanApplications = loanApplications.length == data.count ? false : true
                    this.setState({
                        loanApplications: loanApplications,
                        pagination: {
                            ...pagination,
                            total: data.count
                        },
                        moreToLoad: moreLoanApplications,
                    })


                    // this.setState({
                    //     loanApplications: data.loanApplications || [], pagination: { ...pagination, total: data.count }, sortDirection: body.sortDirection,
                    //     sortFieldname: body.sortFieldname,
                    // })
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/getPersonCards err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    loadMore = () => {
        this.fetchData({
            ... this.state,
            pagination: {
                current: this.state.pagination.current + 1,
                pageSize: 50
            },
            applicationType: this.state.applicationType, tab: this.state.tab
        })
    }

    fetchBusinessAttributes = () => {
        var body = {
            "clientBusinessId": ''
        };

        this.setState({ attributesLoading: true });
        // apiPOSTReq(`${environment.opsBaseUrl}/ops/fetchBusinessAttributes`, {}, body, (err, resp) => {
        apiPOSTReq(`${environment.opsBaseUrl}/ops/fetchBusinessAttributes`, null, body, (err, resp) => {
            try {
                if (err) throw Error(err);
                const data = resp || {};
                if (data.result) {
                    var lineSetup = data.businessAttributes.filter(x => x.name == "GNPLAutomatedLineSetupFlow");
                    if (lineSetup.length > 0) {
                        var attribute = lineSetup[0];
                        var additionalParameterReference = attribute.additionalParameterReference;
                        var parameters = additionalParameterReference.parameters;
                        var low = parseInt(parameters[0].value);
                        var high = parseInt(parameters[1].value);
                        this.setState({ gnplLow: low, gnplHigh: high })
                    }
                } else {
                    throw Error(data.responseMessage || data.error);
                }
            } catch (error) {
                console.log("/generateDebtSchedule err", error, resp);
            }
        });
    }

    automateContract = (loanApplication) => {
        var body = {
            "clientBusinessId": loanApplication.businessId,
            "BusinessId": loanApplication.businessId,
            "applicationId": loanApplication.applicationId
        }
        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/updateContractConfig`, {}, body, (err, resp) => {
            try {
                apiPOSTReq(`${environment.lsmBaseUrl}/lsm/getProposalTerms`, {}, body, (err, resp) => {
                    try {
                        const response = resp || {};
                        if (response.result) {
                            const { proposalTerms, offers } = response
                            proposalTerms = offers[0];
                            if (proposalTerms.creditLimit >= this.state.gnplLow && proposalTerms.creditLimit < this.state.gnplHigh && proposalTerms.productType == "GNPL") {
                                var automation_body = {
                                    "BusinessId": loanApplication.businessId,
                                    "applicationId": loanApplication.applicationId
                                }
                                apiPOSTReq(`${environment.lsmBaseUrl}/lsm/contract/automateContract`, {}, automation_body, (err, resp) => {
                                    try {
                                    }
                                    catch (error) {
                                        console.error("Apply.Submit lsm/contract/automateContract, the error message is :" + error)
                                    }
                                });
                            }
                        }
                    }
                    catch (error) {
                        console.log("ERRR loading contract", error, err, resp);
                    }

                })
            }
            catch (error) {
                console.log("ERRR updateContractConfig", error, err, resp);
            }

        })
    }

    handleMenuClick = (status, item) => {
        console.log("handleMenuClick", status, item);
        const { applicationType } = this.state;
        const { store } = this.props;
        var { UserInfo } = store;
        this.setState({ loading: true });
        var loanApplication = {
            ...item
        };
        loanApplication[applicationType == "prescreen" ? "preScreenStatus" : "underwritingStatus"] = status;
        if (["Approved", "Rejected", "TermLoanApproved", "ARCreditApproved"].includes(status)) {
            loanApplication["completedBy"] = `${UserInfo.FirstName} ${UserInfo.LastName}`;
            loanApplication["completedDate"] = moment().format(dateFormat);
        }
        var body = {
            clientBusinessId: item.businessId,
            loanApplication: loanApplication
        }
        console.log("/ops/loans/application/save", body);
        this.setState({ loading: true });
        apiPOSTReq(`${environment.opsBaseUrl}/ops/loans/application/save`, null, body, (err, resp) => {
            this.fetchData(this.state);
            this.setState({ loading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    if (status == "Approved") {
                        this.fetchBusinessAttributes()
                        this.automateContract(loanApplication);
                    }
                    message.success(`Status updated successfully.`, 0.75);
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/docs/save err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    getColumns = () => {
        const { applicationType, tab } = this.state;
        const statusMenu = (item) => {

            if (applicationType == "prescreen") {
                return <Menu selectedKeys={[item.preScreenStatus]} onClick={(e) => this.handleMenuClick(e.key, item)}>
                    <Menu.Item key="Under Review">
                        Under Review
                    </Menu.Item>
                    <Menu.Item key="TermLoanApproved">
                        Approve GNLP Term Sheet
                    </Menu.Item>
                    <Menu.Item key="ARCreditApproved">
                        Approve RLOC Term Sheet
                    </Menu.Item>
                    <Menu.Item key="Rejected">
                        Rejected
                    </Menu.Item>
                </Menu>
            } else if (applicationType == "duediligence") {
                return <Menu selectedKeys={[item.underwritingStatus]} onClick={(e) => this.handleMenuClick(e.key, item)}>
                    <Menu.Item key="Under Review">
                        Under Review
                    </Menu.Item>
                    <Menu.Item key="Approved">
                        Approved
                    </Menu.Item>
                    <Menu.Item key="Rejected">
                        Rejected
                    </Menu.Item>
                    {/* <Menu.Item key="Auto Approved">
                        Auto Approved
                    </Menu.Item> */}
                </Menu>
            }
        }
        const mapMenu = (item) => {
            if (applicationType == "prescreen") {
                var preScreenItems = {
                    "Not Started": "Not Started",
                    "Under Review": "Under Review",
                    "TermLoanApproved": "Approve GNLP Term Sheet",
                    "ARCreditApproved": "Approve RLOC Term Sheet",
                    "Rejected": "Rejected"
                };

                return item.preScreenStatus ? preScreenItems[item.preScreenStatus] : "Not Started";
            } else if (applicationType == "duediligence") {
                var dueDiligenceItems = {
                    "Not Started": "Not Started",
                    "Under Review": "Under Review",
                    "Approved": "Approved",
                    "Rejected": "Rejected"
                };

                return item.underwritingStatus ? dueDiligenceItems[item.underwritingStatus] : "Not Started";
            }
        }

        if (applicationType == "duediligence") {
            return [
                {
                    title: 'Id',
                    render: item => (
                        <a style={{ textDecoration: "underline" }} onClick={
                            () => {
                                this.props.history.push({
                                    pathname: `pending/${item.applicationId}`,
                                    state: { loanApp: item, appType: applicationType }
                                })
                            }
                        }>{item.applicationId}</a>
                    ),
                    width: "200px"
                },
                {
                    title: 'Date',
                    dataIndex: 'auditData',
                    width: "150px",
                    render: item => (moment(item.lastUpdatedTime).format('ll')),
                },
                {
                    title: 'Business Name',
                    dataIndex: 'businessName',
                    width: "250px"
                },
                {
                    title: 'Applicant Name',
                    width: "200px",
                    render: item => `${item.applicantFirstName} ${item.applicantLastName}`
                },
                {
                    title: 'Email',
                    dataIndex: 'emailAddress',
                    width: "200px"
                },
                {
                    title: 'Status',
                    render: (item) => (
                        <Dropdown overlay={statusMenu(item)}>
                            <Button>
                                {applicationType == "prescreen" ? item.preScreenStatus || "Not Started" : item.underwritingStatus || "Not Started"} <DownOutlined />
                            </Button>
                        </Dropdown>
                    )
                }
            ]
        }
        return [
            {
                title: 'ID',
                dataIndex: 'applicationId',
                render: (applicationId, record) => <a style={{ textDecoration: "underline" }}>{applicationId}</a>,
                width: "100px"
            },
            {
                title: 'Submission Date',
                dataIndex: 'auditData',
                width: "150px",
                render: item => item && (moment(item.createTime).format('lll')),
                sorter: true,
                showSorterTooltip: false,
            },
            {
                title: 'Business Name',
                dataIndex: 'businessName',
                width: "200px",
                sorter: true,
                showSorterTooltip: false,
            },
            {
                title: 'Status',
                render: (item) =>
                    <Popover placement='left' content={`Last Update on ${moment(item.lastUpdatedTime).format('lll')}`}>
                        <Text noWrap>{`${(item.underwritingStatus == "Approved" || item.underwritingStatus == "Rejected" || item.underwritingStatus == 'Not Started') ? item.preScreenStatus : item.underwritingStatus}` || "--"}</Text>
                    </Popover>,
                width: "150px"
            },
            {
                title: 'Product',
                render: (item) => item.productType ? `${item.productType}` : "",
                width: "150px"
            },
            {
                title: 'Referral Code',
                render: (item) => item.referralCode ? `${item.referralCode}` : "",
                width: "150px"
            },
            {
                title: 'Capital Request',
                render: (item) => item.capitalRequest != null ? `$${toCurrency(item.capitalRequest)}` : "--",
                width: "150px"
            },
            {
                title: 'Credit Limit',
                render: (item) => item.creditLimit != null ? `$${toCurrency(item.creditLimit)}` : "--",
                width: "150px"
            },
        ]
    }

    handleTableChange = (pagination, filters, sorter) => {
        this.fetchData({ pagination: pagination, applicationType: this.state.applicationType, tab: this.state.tab, sorter });
    }

    handleOnChange = (e) => {
        this.setState({ [e.target.id]: e.target.value, loanApplications: [] })

        const pagination = {
            current: 1,
            pageSize: 50
        };

        this.fetchData({
            searchTerm: e.target.value,
            pagination
        })
    }

    handleDateRange = (dates) => {
        const dFormat = "YYYY-MM-DD";
        let fromDate = null
        let toDate = null
        if (dates) {
            fromDate = dates[0].format(dFormat)
            toDate = dates[1].format(dFormat)
        }
        this.setState({ fromDate, toDate, filterType: undefined })
        this.fetchData({ ...this.state, fromDate: fromDate, toDate: toDate, filterType: null });
    }

    handleFilterSelection = (val) => {
        var updatedState = { filterType: val, fromDate: null, toDate: null };
        this.setState({ fromDate: null, toDate: null, filterType: val, loanApplications: [] })
        console.log("handleFilterSelection", updatedState)
        this.fetchData({ ...this.state, ...updatedState });
    }

    onTabChange = tab => {
        this.setState({ tab, loanApplications: [] });
        this.fetchData({
            pagination: {
                current: 1,
                pageSize: 50
            }, applicationType: this.state.applicationType, tab: tab
        });
    };

    render() {
        const { theme } = this.props;
        const { loanApplications, pagination, loading, applicationType, searchTerm, fromDate, toDate, filterType } = this.state;
        var statusFilterOptions = ["Processing", "Processing Error", "Manual Review", "Auto Rejected", "Manually Rejected",
            "Proposal Ready", "Proposal Issued", "Proposal Accepted", "Proposal Declined", "Proposal Expired"];

        return (
            <FlexColumn className='main-padding'>
                <PageHeader
                    titleText="Aion Credit"
                />
                {
                    (applicationType != "prescreen") &&
                    <Tabs size="large" defaultActiveKey="1" onChange={this.onTabChange}>
                        <Tabs.TabPane tab="Pending" key="Pending" />
                        <Tabs.TabPane tab="Completed" key="Completed" />
                    </Tabs>
                }
                <TableView
                    id="prescreen-table"
                    titleText={(applicationType == "prescreen") ? "Pre-Screen Applications" : "Due Diligence Applications"}
                    tableLayout='auto'
                    columns={this.getColumns()}
                    dataSource={loanApplications}
                    rowKey='applicationId'
                    pagination={false}
                    onChange={this.handleTableChange}
                    loading={loading}
                    ctaContent={(applicationType == "prescreen") &&
                        <FlexColumn>
                            <Space size="large">
                                <LabeledInput
                                    style={{ width: "500px", fontSize: "14px" }}
                                    label="Search"
                                    labelcolor={theme.colors.secondary3}
                                    id="searchTerm"
                                    key="searchTerm"
                                    type="search"
                                    placeholder="Search by ID / Business Name"
                                    className="no-left-padding"
                                    allowClear
                                    enterButton="Search"
                                    value={searchTerm}
                                    noAsterisk
                                    onChange={this.handleOnChange}
                                    onSearch={(value, event) => {
                                        if (value && value.length > 0) {
                                            this.fetchData({
                                                searchTerm: value, pagination: {
                                                    current: 1,
                                                    pageSize: 50
                                                }
                                            })
                                        } else {
                                            this.fetchData({ searchTerm: "", pagination: this.state.pagination });
                                        }
                                    }}
                                />
                                {/* <LabeledInput
                                    label="Last Updated"
                                    labelcolor={theme.colors.secondary3}
                                    allowClear
                                    id="dateRange"
                                    key="dateRange"
                                    type="range-picker"
                                    value={[this.state.fromDate ? moment(this.state.fromDate) : null, this.state.toDate ? moment(this.state.toDate) : null]}
                                    format="MM/DD/YYYY"
                                    onChange={this.handleDateRange}
                                    style={{ width: "250px", fontSize: "1.1rem" }}
                                    noAsterisk
                                /> */}
                                <LabeledInput
                                    label="Filter Status"
                                    labelcolor={theme.colors.secondary3}
                                    id="filter"
                                    key="filter"
                                    type="select"
                                    placeholder="Select"
                                    className="no-left-padding"
                                    mode="single"
                                    allowClear
                                    value={filterType}
                                    onChange={this.handleFilterSelection}
                                    style={{ width: "250px" }}
                                    noAsterisk
                                >
                                    {statusFilterOptions.map(item => (
                                        <Option key={item} value={item}>
                                            {item}
                                        </Option>
                                    ))}
                                </LabeledInput>
                            </Space>
                        </FlexColumn>
                    }
                    onRow={
                        (record, rowIndex) => {
                            return {
                                onClick: event => {
                                    if (applicationType == "prescreen")
                                        this.props.history.push({
                                            pathname: `prescreen/${record.applicationId}`,
                                            state: { loanApp: record, appType: applicationType }
                                        })
                                }, // click row
                            }
                        }
                    }
                />
                {
                    this.state.moreToLoad &&
                    <Flex fullWidth centerVerticall style={{ marginTop: "10px" }}>
                        <TextButton text='LOAD 50 MORE' loading={loading} onClick={this.loadMore} rightIcon={<img style={{ cursor: 'pointer' }} width='24px' height='24px' src={ChevronDown} />} />
                    </Flex>
                }
            </FlexColumn>
        );
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)));