import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import { Input, Space, message } from 'antd';

// Components
import { Button } from '../../Reusable/Button';
import { Text } from '../../Reusable/Text';
import { FlexColumn } from '../../Reusable/Container';
import { ErrorAlert } from '../../Reusable/Alert';
import { LabeledInput } from '../../Reusable/Input';
import moment from 'moment';
import environment from '../../../environment';
import { apiPOSTReq } from '../../../Utils/api';


class ReviewForm extends Component {
    
    state = {
        loading: false,
        itemType: this.props.itemType // po, invoice etc.
    }

    handleChange = (event) => {
        this.setState({[event.target.id]: event.target.value});     
    }
    
    handleSubmit = (type) => {
        const { note, itemType } = this.state;
        if(!note || note == '') {
            ErrorAlert({ description: "Please add a note to submit." });
            return;
        }
        const { UserInfo } = this.props.store;
        this.setState({ loading: type });
        var body = {
            "reviewer" : `${UserInfo.FirstName} ${UserInfo.LastName} (${UserInfo.Email})`,
            "reviewedDate" : moment().toDate(),
            "reviewerNote" : note,
            "verificationStatus" : (type == "Approve" ? "VALIDATED" : "REJECTED") 
        }

        switch(itemType) {
            case "po":
                body["purchaseOrderIds"] = this.props.ids;
                console.log("/ops/po/approvePO body", body)
                apiPOSTReq(`${environment.opsBaseUrl}/ops/po/approvePO`, null, body, (err, resp) => {
                    try {
                        console.log("/ops/po/approvePO", err, resp);
                        const data = resp || {};
                        if (data.result) {
                            message.success(`POs updated successfully`);
                            this.props.onSuccess();
                        } else {
                            throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                        }
                    } catch (error) {
                        console.log("ERRR approvePO", error, err, resp);
                        ErrorAlert({ description: error.message })
                    }
                })
                break;
            default:
                this.setState({ loading: false });

                break;
        }
    }


    render() {
        const { loading } = this.state;
        const { theme, title, desc } = this.props;
        const { UserInfo } = this.props.store;

        return (
            <FlexColumn between style={{ minHeight: "400px", justifyContent: "start"}}>
                <FlexColumn>
                    <Text heading>{title || "Update item"}</Text>
                    <Text>Reviewer: {UserInfo.FirstName} {UserInfo.LastName} ({UserInfo.Email})</Text>
                    <Text>Review date: {moment().format('ll')}</Text>
                    {desc || <Text>Add your details to update</Text>}
                    <LabeledInput
                        autoFocus
                        label="Note"
                        labelcolor={theme.colors.secondary3}
                        id="note" 
                        placeholder="Why was it approved or rejected" 
                        onChange={this.handleChange} 
                    />
                </FlexColumn>
                <Space>
                    <Button
                        permtype="Override"
                        onClick={() => this.handleSubmit("Approve")}
                        style={{ height: 45, width: "250px", margin: "0 10px 10px 0" }}
                        loading={loading == "Approve"}
                        text='Approve' />

                    <Button
                        permtype="Override"
                        onClick={() => this.handleSubmit("Reject")}
                        style={{ height: 45, width: "250px", margin: "0 0 10px 0" }}
                        loading={loading == "Reject"}
                        text='Reject' />
                </Space>
            </FlexColumn>
        );
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(withTheme(ReviewForm));