import React, { Component } from 'react'
import styled, { withTheme } from 'styled-components';
import { Modal } from 'antd';

import { Flex, FlexColumn, LightContainer } from '../../Reusable/Container';
import { Tag, Text } from '../../Reusable/Text';
import { toAbsCurrency } from '../../../Utils/util';
import hexToRgba from 'hex-to-rgba';
import BillTable from '../Bills/BillTable';
import ModalClose from '../../../Images/x.svg'


class VendorCard extends Component {
    state = {
        showBillsModal: false
    }
    render() {
        var { vendor, newVendor, vendorBills, unpaidBillsCount, totalBillsCount, paidBillsCount, theme, margin, vendorCardLoading } = this.props
        var { showBillsModal } = this.state
        var balanceTotal = 0;
        (vendorBills || []).forEach(bill => { balanceTotal += bill.balance })
        vendor = vendor || {}
        return (
            <>
                {
                    !vendorCardLoading && (newVendor || vendor.vendorName) &&
                    <LightContainer padding='8px 20px' margin={margin} background={hexToRgba(theme.colors.backgroundPrimary, 1)}>
                        <Flex centerHorizontally between gap='8px' fullWidth>
                            <FlexColumn>
                                <Text weight='600' color={theme.colors.primary2}>
                                    {newVendor ? vendor.vendorName : `${vendor.vendorName || ''}`}
                                </Text>
                                {/* Hide until logic for paid is figured out */}
                                {false && !newVendor && <Flex start gap='8px'><Text light>{totalBillsCount} bills received | {paidBillsCount} Paid</Text></Flex>}
                            </FlexColumn>
                                
                            <FlexColumn right>
                                { newVendor && <Tag>NEW VENDOR</Tag> }
                                {
                                    !newVendor &&
                                    <>
                                        { (vendorBills || []).length > 0 && <a onClick={() => this.setState({ showBillsModal: true })}>{unpaidBillsCount} unpaid bills</a> }
                                        { <Text light>Vendor Balance: ${toAbsCurrency(balanceTotal)}</Text> }
                                    </>
                                }
                                
                            </FlexColumn>
                        </Flex>
                    </LightContainer>
                }
                <Modal
                        visible={showBillsModal}
                        footer={null}
                        closable={true}
                        maskClosable={true}
                        width={1000}
                        style={{ top: 20 }}
                        destroyOnClose={true}
                        onCancel={() => this.setState({ showBillsModal: false })}
                        closeIcon={<img width='16px' height='16px' src={ModalClose} />}
                    >
                        <FlexColumn style={{margin: '40px 20px'}}>
                            <BillTable 
                                activeTab={"Unpaid"}
                                bills={vendorBills}
                                pagination={{}}
                                titleText={"Unpaid bills from " + vendor.vendorName}
                                descText={`Unpaid bills from this vendor. Balance to be paid $${toAbsCurrency(balanceTotal)}`}
                                hideColumns={["vendorName", "status"]}                                
                                hideAction={true}
                                borderView={true}
                            />
                        </FlexColumn>
                </Modal>
            </>
            
        )
    }
}

export default withTheme(VendorCard)