import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Space, Table, Tabs, Input, Divider, Modal, Alert, message, Skeleton, Tooltip } from 'antd';
import renderHTML from 'react-render-html';
import SignatureCanvas from 'react-signature-canvas';
import _ from 'underscore';

// Components
import PageHeader from "../../../Reusable/PageHeader";

// Util
import { apiGET, apiPOST, apiPOSTReq } from '../../../../Utils/api';

// Actions
import BoldBanner from '../../../Reusable/BoldBanner'
import { ErrorAlert } from '../../../Reusable/Alert';
import { Flex, FlexColumn, Capsule, Container } from '../../../Reusable/Container';
import { toCurrency, getMultiples, getUserApps } from '../../../../Utils/util';
import moment from 'moment';
import { Paragraph, Title } from '../../../Reusable/Text';
import { Button } from '../../../Reusable/Button';
import { withTheme } from 'styled-components';
import FlowViewComponent from '../../../Reusable/Refresh/FlowViewComponent';
import { FlowViewContainer } from '../../../Reusable/FlowViewContainer';
import SuccessModal from '../../../Reusable/SuccessModal'
import { flowViewsData, customContractFlowViewsData } from './viewData';
import { InputConfirmation, LabeledInput, StyledInput } from '../../../Reusable/Input';
import { CREDIT_SAVE_DATA, TEMPORARY_FORM_DATA, addDataToStore } from '../../../../Actions/actions'
import { SignedLink } from '../../../Reusable/Link';
import { Text } from '../../../Reusable/Text'
import Banner from '../../../Reusable/Banner'
import qbButton from '../../../../Images/C2QB_green_btn_tall_hover.svg'
import ModalClose from '../../../../Images/modal-close.png'
import OAuthClient from 'intuit-oauth'
import environment from '../../../../environment'
import queryString from 'query-string'
import Clock from '../../../../Images/clock.png';
import { Image } from '../../../Reusable/Image'
import Info from '../../../../Images/info.png'
import HelloSign from "hellosign-embedded";

const { TextArea } = Input;

class Index extends Component {

    constructor(props) {
        super(props)

        const { aionStore } = props;
        const { Attributes } = aionStore;
        var { Businesses } = Attributes || {};
        var businessAttributes = Businesses || {};

        this.state = {
            loading: true,
            businessLoading: true,
            currentViewIndex: 0,
            submitLoading: false,
            // flowViews: businessAttributes.CustomPaymentPlan && businessAttributes.CustomPaymentPlan === true ? customContractFlowViewsData : flowViewsData,
            // customPaymentPlan: businessAttributes.CustomPaymentPlan,
            current: 1, // current page
            pageSize: 10, // page size
            sorter: {},
            showSubmittedModal: false,
            showBanner: true
        }
    }

    handleTableChange = (pagination, filters, sorter) => {
        const { current, pageSize } = pagination;
        const { field, order } = sorter;

        if (JSON.stringify(sorter) != JSON.stringify(this.state.sorter)) {
            this.setState({
                current: 1,
                pageSize,
                sorter: sorter
            });
        }

        else {
            this.setState({
                current,
                pageSize,
                sorter: sorter
            });
        }
    }

    reconnectQuickBooks() {
        this.setState({ qbModalVisible: false })
        var oauthClient = new OAuthClient(environment.qboOAuth)

        const authUri = oauthClient.authorizeUri({
            scope: [
                OAuthClient.scopes.Accounting,
                OAuthClient.scopes.OpenId,
                OAuthClient.scopes.Profile,
                OAuthClient.scopes.Email,
                OAuthClient.scopes.Phone,
                OAuthClient.scopes.Address
            ],
            state: 'qb-advances'
        })

        window.location.href = authUri
    }

    componentDidMount() {
        // this.fetchInvoices();
        this.fetchBusinessData();
        const value = queryString.parse(this.props.location.search)
        const state = value.state
        const url = window.location.href

        if (url.includes('reconnect_qb')) {
            this.setState({
                accountingSoftware: true
            })
        }

        if (state === 'qb-advances') {
            // this.props.history.replace('/receivables/invoices/add?reconnect_qb=true')
            this.props.history.replace('/credit/term-loans/advances/request')
            this.setState({
                accountingSoftware: true,
                invoiceRefreshLoading: true
            })

            if (value.code && value.realmId) {
                const headers = {
                    businesskey: this.props.aionStore.BusinessUniqueKey
                }

                apiGET(`/financing/oauth/token?url=${url}`, headers, (err, resp) => {
                    try {
                        const token = resp.data.Response
                        if (resp.data.success) {

                            const data = {
                                accessToken: token.access_token,
                                accessTokenExpiresIn: token.expires_in,
                                accessTokenSecret: "",
                                companyId: value.realmId,
                                accountingSoftware: "QuickBooksOnline",
                                dateCreated: Date.now(),
                                refreshToken: token.refresh_token,
                                refreshTokenExpiresIn: token.x_refresh_token_expires_in,
                                oauthVersion: "2.0",
                                refreshTokens: true
                            }

                            apiPOST('accountingsoftwareinfo', headers, data, (err, resp) => {
                                console.log("accountingsoftwareinfo resp: " + JSON.stringify(resp))
                                if (!err) {
                                    try {
                                        const data = resp.data

                                        if (data.success) {
                                            this.setState({ qbExpired: false, selectedTab: "AccountingSW" })
                                            message.success('QuickBooks reconnected successfully!')
                                            this.refreshInvoices() // refreshInvoices() will call loadInvoicesForApprovedCustomers()
                                        } else {
                                            message.error('Sorry, we had trouble reconnecting your QuickBooks. Please try again.')
                                            throw Error("Could not complete submission.")
                                        }
                                    } catch (error) {
                                        console.error("The error message is :" + JSON.stringify(error))
                                        message.error('Sorry, we had trouble reconnecting your QuickBooks. Please try again.')
                                    }
                                } else {
                                    console.error("The error message is :" + JSON.stringify(err))
                                    message.error('Sorry, we had trouble reconnecting your QuickBooks. Please try again.')
                                }
                                this.setState({ invoiceRefreshLoading: false })
                            })
                        } else {
                            console.error("financing/oauth/token", token)
                            message.error('Sorry, we had trouble reconnecting your QuickBooks. Please try again.')
                            this.setState({ invoiceRefreshLoading: false })
                            throw Error("Could not fetch attributes.")
                        }
                    } catch (error) {
                        console.error("ERROR", error.stack)
                        message.error('Sorry, we had trouble reconnecting your QuickBooks. Please try again.')
                        this.setState({ invoiceRefreshLoading: false })
                    }
                })
            }
        }
    }

    fetchInvoices() {
        const headers = {
            businesskey: this.props.aionStore.BusinessUniqueKey
        }
        this.setState({ loading: true });
        apiGET("/gnpl/funding/collateral", headers, (err, resp) => {
            this.setState({ loading: false });
            try {
                const data = resp.data;
                console.log("/gnpl/funding/collateral", data);
                this.setState({ ...data })
            } catch (error) {
                console.log("ERRR", error.stack);
                ErrorAlert({ description: "Sorry we had trouble processing your request 2, please try again." })
            }
        })
    }

    fetchBusinessData = () => {
        this.setState({ businessLoading: true })
        apiPOSTReq(`${environment.uamBaseUrl}/getBusiness`, null, {}, (err, resp) => {
            try {
                if (err) throw Error(err);
                const data = resp || {};
                if (data.result) {
                    var business = data.business || {}
                    this.setState({
                        flowViews: business.attributes.CustomPaymentPlan && business.attributes.CustomPaymentPlan === true ? customContractFlowViewsData : flowViewsData,
                        customPaymentPlan: business.attributes.CustomPaymentPlan,
                    });

                    this.setState({ businessLoading: false })
                    this.fetchInvoices();
                } else {
                    throw Error(data.responseMessage || data.error);
                }
            } catch (error) {
                console.log("/getBusiness err", error, resp);
            }
        });
    }

    render() {
        const title = null//"Aion Credit";
        return (
            <div className='main-padding'>
                <Skeleton loading={this.state.businessLoading} active title={false} paragraph={{ rows: 7 }}>
                    {
                        this.state.showBanner && <BoldBanner
                            icon={<Image src={Clock} />}
                            style={{ marginTop: "0", marginBottom: 24, width: "100%" }}
                            message="Funds will be available in 1 to 2 business days after the funding request is processed. Requests submitted after 6pm ET are processed the next business day."
                            onClose={() => {
                                this.setState({ showBanner: false })
                            }}
                        />
                    }
                    {
                        this.state.businessLoading == false && <FlowViewContainer title={title} contentViews={this.getContentViews()} hidesidebar={true} />
                    }
                </Skeleton>
                <Modal
                    visible={this.state.qbModalVisible}
                    title="Reconnect QuickBooks"
                    okText="Save"
                    onCancel={() => (this.setState({ qbModalVisible: false }))}
                    confirmLoading={this.state.confirmLoading}
                    destroyOnClose={true}
                    width={700}
                    footer={null}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <div className="flex-column-center" style={{ textAlign: 'center', paddingBottom: '20pt' }}>
                        <Text strong={true} style={{ fontSize: '12pt', marginBottom: '12pt' }}>We need you to reconnect your QuickBooks account<br />before you can refresh your invoices</Text>
                        <Banner title="QuickBooks Master admin or Company admin rights required" message="Make sure you have the right permissions. You will need master admin or company rights to connect to QuickBooks" />
                        <br />
                        <Text>You will be redirected to QuickBooks to provide authorization to Aion</Text>
                        <Text style={{ margin: '32pt 0 12pt 0' }}>Tap on the button below to<br />reconnect you QuickBooks account</Text>
                        <img style={{ height: '40pt' }} src={qbButton} onClick={() => { this.reconnectQuickBooks() }} />
                        <br />
                        <Alert message="We do not store or have access to your QuickBooks credentials" type="info" />
                    </div>
                </Modal>
            </div>
        );
    }

    saveUserInput = (id, dataToSave) => {
        // var { flowViews, currentViewIndex } = this.state;
        this.setState({ [id]: dataToSave });
    }

    refreshInvoices() {
        this.setState({ loading: true });
        // this.setState({ invoiceRefreshLoading: true, invoicesLoaded: false })
        const headers = {
            businesskey: this.props.aionStore.BusinessUniqueKey
        }

        apiGET("/refreshinvoices", headers, (err, resp) => {
            // this.setState({ invoiceRefreshLoading: false })
            try {
                const data = (resp || {}).data || {};
                console.log("/refreshinvoices", JSON.stringify(data))
                if (data.success) {
                    // this.props.dispatch(addDataToStore(CREDIT_SAVE_DATA, data));
                    this.fetchInvoices()
                } else {
                    console.log("/refreshinvoices", JSON.stringify(err))
                    if (data.msg = "Tokens Expired") {
                        this.setState({ qbModalVisible: true, qbExpired: true })
                    }
                    else {
                        throw Error("Could not fetch invoices.")
                    }
                    this.setState({ loading: false });

                }
            } catch (error) {
                console.log("ERRR", error, err, resp)
                ErrorAlert({ description: "Sorry we had trouble processing your request 1, please try again." })
            }
        })
    }

    onOkay = () => {
        const { history } = this.props;

        this.setState({ showSubmittedModal: false });
        history.push('/credit/term-loans/advances');
    }
    getContentViews = () => {
        const { theme, store, aionStore } = this.props;
        var { current, pageSize, currentViewIndex, AdvanceInfo, IneligibleInvoices, Invoices, loading, selectedInvoices, totalInvVal, advanceLimit, customPaymentPlan, requestedAmt, flowViews, FeeRecipient } = this.state;
        AdvanceInfo = AdvanceInfo || {};

        const flowView = flowViews[currentViewIndex];
        var childViews = (<div></div>); // Initialize
        const limitsBlock = (
            <Space>
                <InputConfirmation key="0" label="Available credit" value={`$${toCurrency(AdvanceInfo.CreditLeft)}`} />
                {(selectedInvoices || []).length > 0 &&
                    <>
                        <Divider type="vertical" style={{ margin: "0 12px", height: "100px" }} />
                        <Space>
                            <InputConfirmation key="1" label="Selected collateral value" value={`$${toCurrency(totalInvVal)}`} tooltip={customPaymentPlan && "Selected invoice value is required to be greater than $12,500 for each funding request."} />
                            <InputConfirmation key="2" label="You can advance up to" value={`$${toCurrency(advanceLimit)}`} />
                        </Space>
                    </>
                }
            </Space>
        )

        const userApps = getUserApps(aionStore);
        const { Attributes, CurrentSync, Profile } = aionStore;
        var { Businesses } = Attributes || {};
        var businessAttributes = Businesses || {};

        let qbo = false;
        if (businessAttributes.AccountingSWLinked && Profile.AccountingSW || (CurrentSync && CurrentSync.platformName === 'QUICKBOOKS' && CurrentSync.syncSetupCompleted)) {
            qbo = true;
        }

        // Make any customizations here
        switch (flowView.FlowStep) {
            case "Advance.Start":
                const columns = [
                    {
                        title: 'Date',
                        dataIndex: 'TxnDate',
                        key: 'TxnDate',
                        sorter: (a, b) => moment(a.TxnDate) - moment(b.TxnDate),
                        sortDirections: ['ascend', 'descend'],
                        render: (date) => {
                            if (date) {
                                date = new Date(date).toISOString();
                                date = date.split("T")[0];
                            }
                            return (moment(date).format('MMM Do, YYYY'))
                        },
                        width: '25%'
                    },
                    {
                        title: 'No.',
                        dataIndex: 'DocNumber',
                        key: 'DocNumber',
                        width: '20%'
                    },
                    {
                        title: 'Name',
                        dataIndex: 'CustomerReference',
                        key: 'customerName',
                        width: '20%',
                        render: item => item.Name
                    },
                    {
                        title: 'Balance',
                        dataIndex: 'Balance',
                        key: 'Balance',
                        sorter: (a, b) => a.Balance - b.Balance,
                        sortDirections: ['ascend', 'descend'],
                        render: amount => "$" + toCurrency(amount),
                        width: '20%'
                    },
                    {
                        title: 'Document',
                        dataIndex: 'Attachments',
                        key: 'Attachments',
                        render: attachments => <SignedLink uri={((attachments || []).find(item => item.Type == "InvoiceDoc") || {}).URI} />,
                        width: '20%'
                    }
                ]

                const invoiceSelection = {
                    onChange: (selectedRowKeys, selectedRows) => {
                        // console.log("selectedRows", selectedRows);
                        var totalInvVal = (selectedRows || []).reduce((prev, curr) => (prev + curr.Balance), 0);
                        var advanceLimit
                        if (customPaymentPlan === true) {
                            advanceLimit = ((totalInvVal * 0.8) <= AdvanceInfo.CreditLeft) ? (totalInvVal * 0.8) : AdvanceInfo.CreditLeft
                        } else {
                            advanceLimit = (totalInvVal <= AdvanceInfo.CreditLeft) ? totalInvVal : AdvanceInfo.CreditLeft
                        }
                        this.setState({
                            selectedInvoices: selectedRows,
                            totalInvVal: totalInvVal,
                            advanceLimit
                        });
                    }
                }
                childViews = (
                    <Container shadow style={{ marginBottom: 24 }}>
                        <FlexColumn style={{ margin: '0 0px' }}>
                            {limitsBlock}

                            <Tabs size="large" defaultActiveKey="0" onChange={this.onTabChange} tabBarExtraContent={
                                qbo &&
                                <Button
                                    solid
                                    primary
                                    style={{ marginLeft: '30pt', float: 'right' }}
                                    loading={this.state.invoiceRefreshLoading}
                                    onClick={() => { this.refreshInvoices() }}
                                    text='Refresh from QuickBooks'
                                />
                            }>
                                <Tabs.TabPane tab="Eligible" key="0">
                                    <Paragraph level={2}>{(selectedInvoices && selectedInvoices.length > 0) ? `${selectedInvoices.length} item${selectedInvoices.length > 1 ? 's' : ''} selected.` : "Select collateral to take an advance."}</Paragraph>
                                    <Table
                                        tableLayout='auto'
                                        columns={columns}
                                        dataSource={Invoices || []}
                                        loading={loading}
                                        //sorter={true}
                                        pagination={{ current, pageSize }}
                                        onChange={this.handleTableChange}
                                        rowKey="InvoiceId"
                                        rowSelection={{
                                            ...invoiceSelection
                                        }}
                                        scroll={{ x: '100%' }}
                                    />
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Ineligible" key="1">
                                    <Paragraph level={2}>Collateral that remains unpaid for over 90 days will be ineligible for advances.</Paragraph>
                                    <Table
                                        tableLayout='auto'
                                        columns={columns}
                                        dataSource={IneligibleInvoices || []}
                                        loading={loading}
                                        //sorter={true}
                                        pagination={{ current, pageSize }}
                                        onChange={this.handleTableChange}
                                        rowKey="InvoiceId"
                                        scroll={{ x: '100%' }}
                                    />
                                </Tabs.TabPane>
                            </Tabs>
                        </FlexColumn>
                    </Container>
                );
                break;
            case "Advance.Amount":
                const maxTags = 4;
                var multiples = getMultiples(advanceLimit, maxTags);
                multiples = _.uniq(multiples);
                var amtTags = [];
                // const tagStyle = { margin: '0 10px 0 0', padding: '0px 10px', height: '28px', borderRadius: '10px' };
                amtTags.push(<Capsule id={advanceLimit} key="Full" onClick={() => this.setState({ requestedAmt: (Number(advanceLimit).toFixed(2)) })} text='Full' />);
                multiples.forEach(val => {
                    if (val < advanceLimit && val >= 10000) {
                        amtTags.push(<Capsule key={val} onClick={() => this.setState({ requestedAmt: (val) })} text={`$${toCurrency(val, 0)}`} />);
                    }
                });
                childViews = (
                    <Container shadow style={{ marginBottom: 24 }}>
                        <FlexColumn>
                            <Space>
                                <InputConfirmation key="4" label="You can advance up to" value={`$${toCurrency(advanceLimit)}`} />
                            </Space>
                            {/* <div style={{ width: "600px" }}> */}
                            <LabeledInput
                                label={
                                    <Flex start fullWidth gap='8px' centerHorizontally>
                                        Advance Amount<Tooltip overlayInnerStyle={{ minWidth: 300 }} overlayStyle={{ minWidth: 450 }} overlayClassName='roles-tooltip' placement="right" arrowPointAtCenter={false} title={<FlexColumn>
                                            <Text color='white' size='14px' noWrap>The amount will be rounded down to the nearest hundred dollars</Text>
                                        </FlexColumn>}>
                                            <FlexColumn>
                                                <Image src={Info} />
                                            </FlexColumn>
                                        </Tooltip>
                                    </Flex>
                                }
                                labelcolor={theme.colors.secondary3}
                                onChange={(e) => { this.setState({ requestedAmt: e.target.value }) }}
                                size="large"
                                symbolprefix="$"
                                placeholder="Enter amount"
                                value={this.state.requestedAmt || null}
                            // instruction="The amount will be rounded down to the nearest hundred dollars"
                            />
                            {
                                FeeRecipient && FeeRecipient.FeeType == "Percentage" && <Paragraph color={theme.colors.systemGray}>A {FeeRecipient.FeePercentage}% draw fee will be deducted from your draw amount and the net amount will be deposited in your Aion account.</Paragraph>
                            }
                            {
                                FeeRecipient && FeeRecipient.FeeType == "Flat Fee" && <Paragraph color={theme.colors.systemGray}>A ${toCurrency(FeeRecipient.FlatFee)} draw fee will be deducted from your draw amount and the net amount will be deposited in your Aion account.</Paragraph>
                            }
                            <Flex gap='12px' start wrap>
                                {amtTags}
                            </Flex>
                            <LabeledInput
                                label="Briefly describe purpose of your funding request"
                                labelcolor={theme.colors.secondary3}
                                onChange={(e) => { this.setState({ notes: e.target.value }) }}
                                size="large"
                                placeholder="Enter purpose (E.g. Payroll, Operating Expenses, etc.)" value={this.state.notes || null}
                            />
                            {/* </div> */}
                        </FlexColumn>
                    </Container>
                )
                break;
            case "Advance.Schedule":
            case "Advance.Submit":
                var { repayInfo } = this.state;
                repayInfo = repayInfo || [];

                var paymentColumns = [
                    {
                        title: 'Period',
                        dataIndex: 'Type',
                        width: '20%'
                    },
                    {
                        title: 'Debit Date',
                        dataIndex: 'Date',
                        width: '20%',
                        render: date => moment(date).format('MMM Do, YYYY')
                    },
                    {
                        title: 'Principal',
                        dataIndex: 'Amount',
                        render: item => "$" + toCurrency(item)
                    },
                    {
                        title: (customPaymentPlan === true ? 'Fees' : 'Interest'),
                        dataIndex: 'Fees',
                        render: item => "$" + toCurrency(item)
                    },
                    {
                        title: 'Total',
                        dataIndex: 'Amount',
                        render: (amt, item) => "$" + toCurrency(item.Amount + item.Fees)
                    }
                ];
                childViews = (
                    <>
                        <Container shadow style={{ marginBottom: 24 }}>
                            <FlexColumn>
                                <Space style={{ marginBottom: 12 }}>
                                    <InputConfirmation key="2" label="Amount requested" value={`$${toCurrency(requestedAmt)}`} />
                                </Space>
                                <Table
                                    tableLayout='auto'
                                    columns={paymentColumns}
                                    dataSource={repayInfo.Items || []}
                                    rowKey="Type"
                                    pagination={false}
                                // scroll={{ y: '700px', x: '100%' }}
                                />
                            </FlexColumn>
                        </Container>
                        <Modal
                            visible={this.state.showSubmittedModal}
                            footer={null}
                            closable={false}
                            width={456}
                            destroyOnClose={true}
                            onCancel={() => {
                            }}
                        >
                            <SuccessModal
                                title='Successfully submitted'
                                // description={"The funds will be available in your account in 1-2 business days."}
                                description={"Funds will be available in 1 to 2 business days after the funding request is processed. Requests submitted after 6pm ET are processed the next business day."}
                                buttonTitle='OKAY'
                                descWidth='330px'
                                onConfirm={this.onOkay} />
                        </Modal>
                    </>
                );
                break;
            case "Advance.Terms":
                childViews = (
                    <>
                        {
                            this.state.termsHTML &&
                            <Container shadow style={{ marginBottom: 24 }}>
                                <FlexColumn center style={{ background: "rgba(0,0,0,0.02)", borderRadius: "5px", padding: '20pt' }}>
                                    {renderHTML(this.state.termsHTML)}
                                    <Paragraph bold style={{ color: '#5A8FF4' }}>SIGNATURE REQUIRED</Paragraph>
                                    <br />
                                    <Paragraph style={{ fontSize: 16 }}>By signing below you agree to Aion's loan agreement.</Paragraph>
                                    <br />
                                    <Paragraph style={{ fontSize: 16, color: '#5A8FF4', cursor: 'pointer', marginBottom: '10pt' }} onClick={() => { this.sigBusiness.clear() }}>Clear Signature</Paragraph>
                                    <SignatureCanvas
                                        backgroundColor='white'
                                        penColor='black'
                                        ref={(ref) => { this.sigBusiness = ref }}
                                        canvasProps={{ width: 450, height: 120, className: 'sigCanvas' }} />
                                    <Paragraph bold style={{ fontSize: 16, width: '100%' }}>{this.state.signatureBlock.CompanyName}</Paragraph>
                                    <Paragraph bold style={{ fontSize: 16, width: '100%' }}>{this.state.signatureBlock.BusinessAddress}</Paragraph>
                                    <br />
                                    <Paragraph bold style={{ fontSize: 16, width: '100%' }}>Guarantor:</Paragraph>
                                    <Paragraph style={{ fontSize: 16, color: '#5A8FF4', cursor: 'pointer', marginBottom: '10pt' }} onClick={() => { this.sigPersonal.clear() }}>Clear Signature</Paragraph>
                                    <SignatureCanvas
                                        backgroundColor='white'
                                        penColor='black'
                                        ref={(ref) => { this.sigPersonal = ref }}
                                        canvasProps={{ width: 450, height: 120, className: 'sigCanvas' }} />
                                    <Paragraph bold style={{ fontSize: 16, width: '100%', marginTop: '10pt' }}>{this.state.signatureBlock.OwnerName}</Paragraph>
                                    <Paragraph bold style={{ fontSize: 16, width: '100%' }}>{this.state.signatureBlock.PersonalAddress}</Paragraph>
                                </FlexColumn>
                            </Container>
                        }
                        <Modal
                            visible={this.state.showSubmittedModal}
                            footer={null}
                            closable={false}
                            width={456}
                            destroyOnClose={true}
                            onCancel={() => {
                            }}
                        >
                            <SuccessModal
                                title='Successfully submitted'
                                description={"Funds will be available in 1 to 2 business days after the funding request is processed. Requests submitted after 6pm ET are processed the next business day."}
                                buttonTitle='OKAY'
                                descWidth='330px'
                                onConfirm={this.onOkay} />
                        </Modal>
                    </>
                );
                break;
            default:
                break;
        }
        return <FlowViewComponent
            flowView={flowView}
            back
            currentViewIndex={currentViewIndex}
            loadPrev={this.loadPrevView}
            loadNext={this.loadNext}
            childViews={childViews}
            submitLoading={this.state.submitLoading}
            width={flowView.Width || 1000}
            showFooter={true}
        // affixButton
        />
    }

    // Validate input and go to next view if it's good
    loadNext = () => {
        var flowView = this.state.flowViews[this.state.currentViewIndex];
        var validation = this.validateFlow();
        if (validation.validated) {
            switch (flowView.FlowStep) {
                case "Advance.Start":
                    this.setState({ requestedAmt: null, notes: null })
                    this.loadNextView();
                    break;
                case "Advance.Amount":
                    var { requestedAmt } = this.state
                    //To roundoff to the nearest 100
                    var roundedRequestedAmt = Math.floor(requestedAmt / 100) * 100

                    var headers = {
                        "advanceamt": roundedRequestedAmt
                    }
                    this.setState({ submitLoading: true, requestedAmt: roundedRequestedAmt });
                    apiGET('/advances/terms', headers, (err, resp) => {
                        console.log("/advances/terms", err, resp);
                        this.setState({ submitLoading: false })
                        try {
                            const data = resp ? resp.data : {};
                            if (data.success) {
                                this.setState({
                                    termsHTML: data.Terms.html,
                                    repayInfo: data.Terms.RepayInfo,
                                    signatureBlock: data.Terms.SignatureBlock
                                });
                                this.loadNextView();
                            } else {
                                console.log("/advances/terms", JSON.stringify(err));
                                throw Error((err || {}).message || data.msg);
                            }
                        } catch (error) {
                            console.log("ERROR", error.stack)
                            ErrorAlert({ description: error.message || "We had trouble processing your request, please try again." })
                        }
                    })
                    break;
                case "Advance.Terms":
                    var { requestedAmt, notes, selectedInvoices } = this.state;
                    var headers = {
                        // businesskey: this.props.store.BusinessUniqueKey
                    }
                    const { UserInfo } = this.props.aionStore
                    var username = UserInfo.Email

                    var body = {
                        advanceAmount: requestedAmt,
                        advanceNotes: notes,
                        invoiceIds: (selectedInvoices || []).map(item => item.InvoiceId),
                        businessSignatureStr: this.sigBusiness.getTrimmedCanvas().toDataURL("image/png"),
                        personalSignatureStr: this.sigPersonal.getTrimmedCanvas().toDataURL("image/png"),
                        drawFeesToBorrower: this.state.DrawFeesToBorrower,
                        feeRecipient: this.state.FeeRecipient,
                        UserEmail: username,
                        FirstName: UserInfo.FirstName,
                        LastName: UserInfo.LastName,
                    }
                    this.setState({ submitLoading: true });
                    // console.log("Advance.Terms body", body);
                    apiPOST("/gnpl/funding/savedraw", headers, body, (err, resp) => {
                        console.log("/gnpl/funding/savedraw", err, resp);
                        this.setState({ submitLoading: false });
                        try {
                            if (err) throw Error(err);
                            if (resp.data.success) {
                                this.setState({ showSubmittedModal: true });
                                this.loadNextView();
                            } else {
                                throw Error(resp.data.msg);
                            }
                        } catch (error) {
                            ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
                        }
                    });
                    break;
                case "Advance.Submit":
                    var { requestedAmt, notes, selectedInvoices, repayInfo } = this.state;

                    var contractHeader = {
                        AionCurrentBiz: this.props.aionStore.BusinessUniqueKey
                    }

                    var contractBody = {
                        requestedAmount: requestedAmt,
                        businessId: this.props.aionStore.BusinessUniqueKey,
                        repayInfos: repayInfo.Items
                    }
                    this.setState({ submitLoading: true });

                    console.log("Headers,body", contractBody)
                    apiPOSTReq(`${environment.lsmBaseUrl}/lsm/contract/requestAdvancesContract`, contractHeader, contractBody, (err, resp) => {
                        try {
                            const contractResponse = resp || {};
                            console.log("Response:", contractResponse)
                            this.setState({ submitLoading: false });
                            if (contractResponse.result) {
                                const embeddedUrl = contractResponse.helloSignEmbeddedUrl
                                this.setState({ embeddedUrl: embeddedUrl })

                                const client = new HelloSign({
                                    clientId: `${environment.hellosignClientKey}`
                                });

                                client.open(this.state.embeddedUrl, {
                                    testMode: environment.isSandbox
                                });

                                client.on('sign', () => {
                                    console.log('The document has been signed!');
                                    // this.setState({ signatureStatus: true })
                                    var saveHeaders = {
                                        businesskey: this.props.store.BusinessUniqueKey
                                    }

                                    var saveBody = {
                                        businessId: this.props.store.BusinessUniqueKey,
                                        advancesContract: contractResponse.advancesContract
                                    }

                                    apiPOSTReq(`${environment.lsmBaseUrl}/lsm/contract/saveAdvancesContract`, saveHeaders, saveBody, (err, resp) => {
                                        const response = resp || {};
                                        console.log("Response:", response)

                                        if (response.result) {
                                            this.setState({ submitLoading: true });
                                            var { advancesContract } = response
                                            var headers = {
                                                // businesskey: this.props.store.BusinessUniqueKey
                                            }
                                            const { UserInfo } = this.props.aionStore
                                            var username = UserInfo.Email

                                            var body = {
                                                advanceAmount: requestedAmt,
                                                advanceNotes: notes,
                                                invoiceIds: (selectedInvoices || []).map(item => item.InvoiceId),
                                                noteId: advancesContract.noteId,
                                                drawFeesToBorrower: this.state.DrawFeesToBorrower,
                                                feeRecipient: this.state.FeeRecipient,
                                                UserEmail: username,
                                                FirstName: UserInfo.FirstName,
                                                LastName: UserInfo.LastName,
                                            }

                                            // console.log("Advance.Terms body", body);
                                            apiPOST("/gnpl/funding/savedraw", headers, body, (err, resp) => {
                                                console.log("/gnpl/funding/savedraw", err, resp);
                                                this.setState({ submitLoading: false });
                                                try {
                                                    if (err) throw Error(err);
                                                    if (resp.data.success) {
                                                        this.setState({ showSubmittedModal: true });
                                                        this.loadNextView();
                                                    } else {
                                                        throw Error(resp.data.msg);
                                                    }
                                                } catch (error) {
                                                    ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
                                                }
                                            });
                                        }
                                    })
                                });
                            }
                            else {
                                ErrorAlert({ description: contractResponse.responseMessage || "Sorry, we had trouble processing your request. Please try again." });
                            }
                        }
                        catch (error) {
                            console.log("ERRR saving values", error, err, resp);
                        }
                    })

                    break;
                default:
                    this.loadNextView();
                    break;
            }
        } else {
            ErrorAlert({ description: validation.message });
        }
    }

    validateFlow = () => {
        const { store } = this.props;
        const { requestedAmt, notes, advanceLimit, selectedInvoices, customPaymentPlan, flowViews, MinLoanAmount } = this.state;
        const flowView = flowViews[this.state.currentViewIndex];
        var validation = {
            validated: false,
            message: flowView.ValidationErrorMessage ?? "Please provide an input to continue"
        }
        switch (flowView.FlowStep) {
            case "Advance.Start":
                if ((selectedInvoices || []).length == 0) {
                    validation.message = `Please select at least one eligible invoice.`;
                } else if (advanceLimit < MinLoanAmount) {
                    validation.message = `Sorry, but your advanceable funds are under our minimum limit of $${MinLoanAmount}. Please select additional invoices to proceed.`
                } else {
                    validation.validated = true;
                }
                break;
            case "Advance.Amount":
                // console.log
                if (isNaN(requestedAmt)) {
                    validation.message = "Please enter a valid amount to advance."
                } else if (requestedAmt < MinLoanAmount) {
                    validation.message = `Your requested advance amount is below our minimum limit of $${MinLoanAmount}.`
                } else if (requestedAmt > advanceLimit) {
                    validation.message = `You can advance up to $${toCurrency(advanceLimit)}. Please enter a value below this amount.`;
                } else if (!notes) {
                    validation.message = `Please add a note.`;
                } else {
                    validation.validated = true;
                }
                break;
            case "Advance.Terms":
                if (this.sigBusiness.isEmpty()) {
                    validation.message = `Please add a signature for your business.`;
                } else if (this.sigPersonal.isEmpty()) {
                    validation.message = `Please add a guarantor signature.`;
                } else {
                    validation.validated = true;
                }
                break;
            default:
                validation.validated = true;
                break;
        }
        return validation;
    }

    loadPrevView = () => {
        if (this.state.currentViewIndex !== 0) {
            this.setState({
                currentViewIndex: this.state.currentViewIndex - 1,
                showError: false, errorMsg: "",      // dismiss any validatin error, if any, for the current step
            });
        }
        else {
            window.history.back();
        }
    }

    loadNextView = () => {
        if (this.state.currentViewIndex + 1 < this.state.flowViews.length) {
            this.setState({
                currentViewIndex: this.state.currentViewIndex + 1
            });
        }
        window.scrollTo(0, 0);
    }
}

function mapStateToProps(state) {
    return {
        store: state.creditAppReducer,
        aionStore: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)));