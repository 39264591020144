import React, { useState } from 'react'
import { Flex, FlexColumn, CardContainer } from '../../Reusable/Container'
import { LabeledInput } from '../../Reusable/Input'
import { saveAs } from 'file-saver'
import moment from 'moment'
import { apiGETDocUrl } from '../../../Utils/api'
import { ErrorAlert } from '../../Reusable/Alert'
import environment from '../../../environment'

// Images
import Visibility from "../../../Images/visibility-blue.png"
import VisibilityOff from "../../../Images/visibility-off.png"
import { TextButton } from '../../Reusable/Button'

const AccountDetailsCard = (props) => {
    const [masked, setMasked] = useState(true)
    const [loadingAccountVerificationPDF, setLoadingAccountVerificationPDF] = useState(false)
    const { account, height, onViewAccountLetter } = props


    const downloadAccountVerificationPDF = () => {
        setLoadingAccountVerificationPDF(true)
        var query = {
            AccountId: account.accountId
        }

        apiGETDocUrl(`${environment.bbBaseUrl}/bb/export/pdf/accountVerificationTemplate`, {}, query, (err, resp) => {
            setLoadingAccountVerificationPDF(false)
            const data = (resp || {}).data
            try {
                if (err) throw Error("We had trouble downloading your account verification. Please try again.")
                const blob = new Blob([data], {
                    type: 'application/pdf',
                })
                const fileURL = URL.createObjectURL(blob)
                // saveAs(fileURL, `Bank Account Letter ${moment().format('MMDDYY')}`)
                window.open(fileURL, '_blank');
            } catch (error) {
                ErrorAlert({ description: error.message })
            }
        })
    }

    return (
        <CardContainer minWidth='200px' width='200px' height={height || '220px'}>
            <FlexColumn centerVertically left fullWidth fullHeight gap='24px'>
                {
                    account && account.accountNumber && account.accountNumber.length > 5 &&
                    <Flex start bottom>
                        <LabeledInput
                            nomargin
                            type='read-only'
                            noWidth
                            // width='220px'
                            label='Account number'
                            value={masked ? `•••••${account.accountNumber.substring(account.accountNumber.length - 4, account.accountNumber.length)}` : account.accountNumber}
                        />

                        <img style={{ cursor: 'pointer', marginLeft: 12 }} onClick={() => setMasked(!masked)} width='20px' height='20px' src={masked ? Visibility : VisibilityOff} />
                    </Flex>
                }

                {
                    account &&
                    <LabeledInput
                        nomargin
                        type='read-only'
                        label='Routing number'
                        value={account.routingNumber || '021214891'}
                    />
                }

                <TextButton text='View account letter' underline weight='400' onClick={downloadAccountVerificationPDF} loading={loadingAccountVerificationPDF} />
            </FlexColumn>
        </CardContainer>
    )
}

export default AccountDetailsCard