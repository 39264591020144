import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter, useLocation, useHistory } from 'react-router-dom'
import { withTheme } from 'styled-components'

import { Modal, Tabs, Select } from 'antd'
import { CardContainer, FlexColumn } from '../../Reusable/Container'
import PageHeader from '../../Reusable/PageHeader'
import { LabeledInput } from '../../Reusable/Input'
import { apiPOSTReq } from '../../../Utils/api'
import { getBPAccountsWithAccess, getResourcePerm, toCurrency } from '../../../Utils/util'
import { fetchBBHistory, fetchBBInternal, normalizeAndSortHistory } from '../../../Utils/bankingDataManager'
import { Text } from '../../Reusable/Text'
import { ErrorAlert } from '../../Reusable/Alert'
import { Button } from '../../Reusable/Button'
import PendingTable from '../Reusable/PendingTable'
import InternalTable from '../Reusable/InternalTable'
import { FromAccountOption } from '../../Reusable/Option'
import { addDataToStore, BNK_SAVE_DATA } from '../../../Actions/actions'
import environment from '../../../environment'

// Images
import AccountNameModal from '../Reusable/AccountNameModal'
import ModalClose from '../../../Images/modal-close.png'
import AionIcon from '../../../Images/aion-bank-icon.png'

const { TabPane } = Tabs
const { Option } = Select

const pageSize = 50;

const InternalActivity = (props) => {
    const [accounts, setAccounts] = useState([])
    const [subAccounts, setSubAccounts] = useState([])
    const [account, setAccount] = useState()
    const [accountIndex, setAccountIndex] = useState(0)
    const [type, setType] = useState()
    const [pendingTransfers, setPendingTransfers] = useState([])
    const [postedTransfers, setPostedTransfers] = useState([])
    const [showAccountNameModal, setShowAccountNameModal] = useState(false)
    const [loadingPending, setLoadingPending] = useState(true)
    const [loadingPosted, setLoadingPosted] = useState(true)
    const [moreToLoad, setMoreToLoad] = useState(false)
    const [currentOptions, setCurrentOptions] = useState({})
    const [singleAccount, setSingleAccount] = useState()
    const [back, setBack] = useState(false)

    const location = useLocation()
    const history = useHistory()

    const manageAccounts = getBPAccountsWithAccess("Payments", { permType: "manage", getActiveAccounts: true })

    useEffect(() => {
        var Accounts = getBPAccountsWithAccess("Transactions", { getActiveAccounts: true })
        Accounts = Accounts || []
        setSingleAccount(Accounts.length === 1)
        setAccounts(Accounts)

        if (location.state && location.state.account) {
            setAccount(location.state.account)
            setAccountIndex(location.state.accountIndex)
            setType(location.state.type)
            setBack(true)
        } else {
            if (Accounts.length > 0) {
                setAccount(Accounts[0])
            }
        }
    }, [])

    useEffect(() => {
        if (account) {
            getPending({ pagination: { pageSize: pageSize, current: 1 } })
            fetchTransactions({ pagination: { pageSize: pageSize, current: 1 } })
        }
    }, [account])


    const getPending = (options) => {
        setLoadingPending(true)
        fetchBBHistory({
            viewBy: "PENDING_ACTIVITY",
            accountId: account.accountId,
            // pagination: options.pagination,
        }, (err, resp) => {
            if (resp) {
                console.log('getPending ACHActivity: resp', resp)
                const normalizedHistory = normalizeAndSortHistory(resp)
                setPendingTransfers(normalizedHistory.normalizedData.filter(transaction => transaction.type === 'Internal'))
            }
            setLoadingPending(false)
        })
    }

    const fetchTransactions = (options) => {
        var { fromDate, toDate, searchStr, fromAmt, toAmt, railList, viewBy } = options

        var fetchOptions = {
            pagination: options.pagination, // || pagination
            // RailType: 'Internal Book Transfer',
        }

        console.log('fetchTransactions fetchOptions1:', fetchOptions)

        if (searchStr && searchStr != '') fetchOptions.searchStr = searchStr
        if (fromDate) fetchOptions.fromDate = fromDate
        if (toDate) fetchOptions.toDate = toDate
        if (fromAmt) fetchOptions.fromAmt = fromAmt
        if (toAmt) fetchOptions.toAmt = toAmt
        if (railList) fetchOptions.railList = railList
        if (viewBy && searchStr && searchStr != '') fetchOptions.viewBy = viewBy

        fetchOptions.bankProvider = "CrossRiverBank"

        setLoadingPosted(true)

        setCurrentOptions(fetchOptions)
        fetchBBInternal(fetchOptions, (err, resp) => {
            try {
                if (err) throw new Error(err)
                console.log("fetchData fetchBBInternal fetchOptions", fetchOptions)
                console.log("fetchData fetchBBInternal resp", resp)

                const newData = fetchOptions.pagination.current > 1 ? postedTransfers.concat(resp.Transactions) : resp.Transactions

                setMoreToLoad(newData.length !== resp.bookTransfersCount)
                setPostedTransfers(newData)

                if (!searchStr) {
                    props.dispatch(addDataToStore(BNK_SAVE_DATA, resp || {}))
                }
            } catch (error) {
                ErrorAlert({ description: error.message })
            } finally {
                setLoadingPosted(false)
            }
        })
    }

    const loadMoreActivity = () => {
        currentOptions.pagination.current = currentOptions.pagination.current + 1
        fetchTransactions(currentOptions)
    }

    return (
        <FlexColumn start>
            {/* <PageHeader
                // titleText='Internal Transfers'
                // subtitle='Banking'
                back={back}
                onBack={() => window.history.back()}
                ctaContent={<Button permtype="BusinessBanking.Payments" solid text='TRANSFER' onClick={() => history.push({
                    pathname: '/banking/internal-transfers/internal-transfer',
                    state: { rail: 'Internal' }
                })} />}
            /> */}

            <FlexColumn between style={{ width: '100%', paddingBottom: 24, marginTop: 0 }}>
                <InternalTable
                    filter
                    rail
                    download
                    runningBalance
                    account={account}
                    accounts={accounts.concat(subAccounts)}
                    accountIndex={0}
                    fetchTransactions={fetchTransactions}
                    moreToLoad={moreToLoad}
                    onLoadMore={() => loadMoreActivity()}
                    padding='6px 0 0'
                    loading={loadingPosted}
                    data={postedTransfers}
                    ctaContent={props.ctaContent}
                />
            </FlexColumn>

            <Modal
                wrapClassName='account-name-modal'
                visible={showAccountNameModal}
                footer={null}
                closable={false}
                maskClosable={true}
                width={776}
                destroyOnClose={true}
                onCancel={() => setShowAccountNameModal(false)}
                closeIcon={<img width='24px' height='24px' src={ModalClose} />}
            >
                <AccountNameModal onCancel={() => setShowAccountNameModal(false)} />
            </Modal>
        </FlexColumn>
    )
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer,
        bankingStore: state.bankingAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(InternalActivity)))