import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import { withTheme } from 'styled-components';
import moment from 'moment'
import { Table, Select, message, Dropdown, Menu } from 'antd'

import _ from 'lodash'
import { Text, Tag } from '../../Reusable/Text'
import { Image } from '../../Reusable/Image';
import { Flex, FlexColumn } from '../../Reusable/Container'
import { Button, ImageButton, TextButton } from '../../Reusable/Button'
import { LabeledInput } from '../../Reusable/Input'
import Banner from '../../Reusable/Banner'
import { ErrorAlert } from '../../Reusable/Alert'
import { isEmailValid } from '../../../Utils/util'
import { apiPOSTReq } from '../../../Utils/api'
import environment from '../../../environment'
import Add from '../../../Images/add.svg';
import Edit from "../../../Images/edit.svg"
import ChevronDown from "../../../Images/chevron-down.svg"
import InfoRed from "../../../Images/exclamation-red.svg"

const { Option } = Select


const QualifyCustomerDrawer = ({ onEditEmail, aionStore }) => {
    const [customers, setCustomers] = useState([]);
    const [moreCustomers, setMoreCustomers] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState(null);
    const [customerPagination, setCustomerPagination] = useState({ current: 1, pageSize: 50, });
    const [dropdownVisible, setDropdownVisible] = useState({});
    const [updateEmailLoading, setUpdateEmailLoading] = useState({});

    useEffect(() => {
        getNewCustomers({ pagination: customerPagination })
    }, []);

    var columns = [
        {
            title: 'All customers',
            dataIndex: 'displayName',
            key: 'displayName',
        },
        {
            dataIndex: 'primaryEmailAddress',
            key: 'primaryEmailAddress',
            textWrap: 'word-break',
            align: 'right',
            render: (primaryEmailAddress, customer) => {
                if (primaryEmailAddress?.address) {
                    return <Flex end>
                        <Dropdown
                            placement='bottomRight'
                            trigger={['click']}
                            key={`${customer.customerId}-Dropdown`}
                            visible={dropdownVisible[`${customer.customerId}`]}
                            onVisibleChange={flag => {
                                if (flag) setEmail(primaryEmailAddress ? primaryEmailAddress.address || "" : "")
                                else setEmail(null)
                                setDropdownVisible({ [`${customer.customerId}`]: flag })
                            }}
                            overlay={<Menu style={{ padding: 24, borderRadius: 8, zIndex: 3001 }} key={`${customer.customerId}-Menu`}>
                                <FlexColumn gap='24px'>
                                    <LabeledInput
                                        // inputRef={this.emailRef}
                                        nomargin
                                        label="Update email"
                                        type="email"
                                        id="primaryEmailAddress"
                                        key="primaryEmailAddress"
                                        width="284px"
                                        value={email}
                                        onChange={(event) => {
                                            setEmail(event.target.value)
                                        }}
                                        placeholder="Email"
                                    // error={errorField === "primaryEmailAddress"}
                                    // errorMessage={errorMessage}
                                    />

                                    <Banner message="This email will be added to the customer record" />

                                    <Flex start centerHorizontally gap='24px'>
                                        <Button primary disabled={!email || email === '' || !isEmailValid(email)} solid loading={updateEmailLoading[`${customer.customerId}`]} onClick={() => {
                                            setUpdateEmailLoading({ [`${customer.customerId}`]: true })
                                            handleSave(customer)
                                        }} text="CONFIRM" />
                                        <TextButton text='CANCEL' onClick={() => setDropdownVisible({ [`${customer.customerId}`]: false })} />
                                    </Flex>
                                </FlexColumn>
                            </Menu>}
                        >
                            <Tag primary noCap onClick={() => { }} radius='24px' style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>{(primaryEmailAddress ? primaryEmailAddress.address || "" : "")}
                                <Image src={Edit} margin='0 0 0 4px' />
                            </Tag>
                        </Dropdown>
                    </Flex>
                } else {
                    return <Flex end>
                        <Dropdown
                            placement='bottomRight'
                            trigger={['click']}
                            key={`${customer.customerId}-Dropdown`}
                            visible={dropdownVisible[`${customer.customerId}`]}
                            onVisibleChange={flag => {
                                setDropdownVisible({ [`${customer.customerId}`]: flag })
                            }}
                            overlay={<Menu style={{ padding: 24, borderRadius: 8, zIndex: 3001 }} key={`${customer.customerId}-Menu`}>
                                <FlexColumn gap='24px'>
                                    <LabeledInput
                                        // inputRef={this.emailRef}
                                        nomargin
                                        label="Add email"
                                        type="email"
                                        id="primaryEmailAddress"
                                        key="primaryEmailAddress"
                                        width="284px"
                                        value={email}
                                        onChange={(event) => {
                                            setEmail(event.target.value)
                                        }}
                                        placeholder="Email"
                                    // error={errorField === "primaryEmailAddress"}
                                    // errorMessage={errorMessage}
                                    />

                                    <Banner message="This email will be added to the customer record" />

                                    <Flex start centerHorizontally gap='24px'>
                                        <Button primary disabled={!email || email === '' || !isEmailValid(email)} solid loading={updateEmailLoading[`${customer.customerId}`]} onClick={() => {
                                            setUpdateEmailLoading({ [`${customer.customerId}`]: true })
                                            handleSave(customer)
                                        }} text="CONFIRM" />
                                        <TextButton text='CANCEL' onClick={() => setDropdownVisible({ [`${customer.customerId}`]: false })} />
                                    </Flex>
                                </FlexColumn>
                            </Menu>}
                        >
                            <TextButton underline weight='400' text='Add email' rightIcon={<Image src={Add} />} />
                        </Dropdown>
                    </Flex>
                }
            }
        },
    ];

    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        onSelectAll: (selected) => {
            if (selected) getNewCustomers({ pagination: { current: 1, pageSize: 2000, } }, true)
            else setSelectedRowKeys([])
        },
    };

    const history = useHistory()

    const onSubmit = () => {
        var paymentConfirmationRequests = [];
        const selectedCustomers = customers.filter(customer => selectedRowKeys.includes(customer.customerId));

        selectedCustomers.forEach((customer) => {
            paymentConfirmationRequests.push({
                CustomerId: customer.customerId,
                ToAddr: customer.primaryEmailAddress?.address,
            })
        })

        history.push({
            pathname: '/credit/payers/send-payment-notification',
            state: { customers: paymentConfirmationRequests }
        })
    }

    const getNewCustomers = (options, selectAll) => {
        let { pagination } = options
        // Fetch customer list
        const headers = {
            AionCurrentBiz: aionStore.BusinessUniqueKey,
            AionAuth: aionStore.UAM.encryptedAuthHeader
        }
        const body = {
            size: pagination.pageSize,
            page: pagination.current - 1,
            viewBy: "NEW",
        }

        setLoading(true)
        console.log("Payers getNewCustomers", body)
        apiPOSTReq(`${environment.iveBaseUrl}/ive/bc/getPayors`, headers, body, (err, resp) => {
            try {
                const data = resp
                console.log("Payers getNewCustomers", data)
                if (data.result) {
                    var newCustomers = pagination.current > 1 ? customers.concat(data.customers) : data.customers
                    var moreCustomers = (newCustomers || []).length == data.count ? false : true
                    setMoreCustomers(moreCustomers)
                    setCustomers(newCustomers)
                    setCustomerPagination({
                        ...pagination,
                        total: data.count
                    })
                    if (selectAll) setSelectedRowKeys(newCustomers.map(customer => customer.customerId))
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getNewCustomers", error, err, resp)
                ErrorAlert({ description: error.message })
            } finally {
                setLoading(false)
            }
        })
    }

    const loadMoreCustomers = () => {
        getNewCustomers({ pagination: { current: customerPagination.current + 1, pageSize: 50 } });
    }

    const handleSave = (customer) => {
        customer.primaryEmailAddress = { address: email }

        const body = {
            Customer: customer
        }

        console.log("CreateCustomer nextStep customer:", body)

        apiPOSTReq(`${environment.iveBaseUrl}/ive/bc/save`, null, body, (err, resp) => {
            try {
                const data = resp;
                if (data.result) {
                    message.success(`Email updated`);
                } else {
                    message.error("Could not Save. " + data.responseMessage);
                }
            } catch (error) {
                console.log("ERRR", error.stack, err, resp)
                message.error("Could not Save.");
            } finally {
                setUpdateEmailLoading({ [`${customer.customerId}`]: false })
                setDropdownVisible({ [`${customer.customerId}`]: false })
                setEmail(null)
            }
        })
    }

    const selectedCustomers = (customers || []).filter(customer => selectedRowKeys.includes(customer.customerId));
    const isEmailMissing = (selectedCustomers || []).length > 0 && selectedCustomers.some(customer => !customer.primaryEmailAddress?.address);

    return (
        <>
            <FlexColumn start gap="24px" fullHeight style={{ marginBottom: 24 }}>
                <FlexColumn start>
                    <Text heading>Qualify new payer</Text>
                    <Text>We’ve listed customers we could find. Select one or more that you would like to qualify as a payer for credit. You will need to send a payment notification to them once you proceed further.</Text>
                </FlexColumn>

                <Table
                    id="customer-table"
                    className="borderless-table"
                    tableLayout='auto'
                    columns={columns}
                    dataSource={customers}
                    rowKey='customerId'
                    pagination={false}
                    showHeader={true}
                    rowSelection={rowSelection}
                    loading={loading}
                />

                {
                    moreCustomers &&
                    <Flex fullWidth centerVertically style={{ marginTop: "10px" }}>
                        <TextButton text='LOAD MORE' loading={loading} onClick={loadMoreCustomers} rightIcon={<img style={{ cursor: 'pointer' }} width='24px' height='24px' src={ChevronDown} />} />
                    </Flex>
                }

                {
                    isEmailMissing &&
                    <Banner alert message='All selected customers require an email ID' icon={<Image src={InfoRed} />} />
                }

                <FlexColumn end grow style={{ paddingBottom: 24 }}>
                    <Flex start gap='24px' centerHorizontally>
                        <Button disabled={isEmailMissing || (selectedRowKeys || []).length < 1} onClick={onSubmit} solid text={'Continue'} loading={loading} />
                        {selectedRowKeys?.length > 0 && <Text color='#666666'>{selectedRowKeys?.length} Customer{selectedRowKeys?.length > 1 ? 's' : ''} selected</Text>}
                    </Flex>
                </FlexColumn>
            </FlexColumn>
        </>
    );
};

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer,
        bankingStore: state.bankingAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(QualifyCustomerDrawer)))
