import React from 'react'
import { Divider } from 'antd'
import { InputContainer, FlexColumn, Flex } from './Container'
import { Text, Tag } from './Text'
import { Image } from './Image'
import { TextButton } from './Button'
import ordinal from 'ordinal'
import { getApproversText } from '../../Utils/util'
import moment from 'moment'
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons'
import Check from "../../Images/gs-check.png"
import Card from "../../Images/gs-card.png"
import Lock from "../../Images/gs-lock.png"
import RightDisabled from "../../Images/arrow-right-disabled.png"


export const AccountInfoCard = (props) => {
    return <FlexColumn style={{ width: "100%", justifyContent: "start", marginBottom: '10px', marginRight: '10px' }}>
        <InputContainer style={{ margin: "0px 0" }}>
            {/* <div className="MICR-font">****{props.mask}</div> */}
            <Flex style={{ justifyContent: "flex-start" }}>
                {
                    props.fromAccount &&
                    <>
                        <FlexColumn>
                            <Text size='14px' color={props.theme.colors.secondary3}>From Account</Text>
                            <Text weight='500'>{(props.fromAccount.NickName || `Business ${props.fromAccount.AccountSubType}`).toUpperCase()} - <span className="MICR-font">****{props.fromAccount.Mask}</span></Text>
                        </FlexColumn>
                        <Divider type="vertical" style={{ margin: "0 20px", height: "50px", borderColor: props.theme.colors.systemGray4 }} />
                    </>
                }
                <FlexColumn>
                    <Text size='14px' color={props.theme.colors.secondary3}>Available</Text>
                    <Text weight='500'>{props.availableBalance}</Text>
                </FlexColumn>
                <Divider type="vertical" style={{ margin: "0 20px", height: "50px", borderColor: props.theme.colors.systemGray4 }} />
                <FlexColumn>
                    <Text size='14px' color={props.theme.colors.secondary3}>Scheduled</Text>
                    <Text weight='500'>{props.scheduled}</Text>
                </FlexColumn>
            </Flex>
        </InputContainer>
    </FlexColumn>
}

export const ApproversCard = (props) => {
    return <FlexColumn style={{ width: "100%", justifyContent: "start", marginBottom: '10px', marginRight: '10px' }}>
        {
            props.approvalRule && props.approvalRule.levels.map(
                (level, index) =>
                    <Flex start style={{ marginTop: 3 }}>
                        <span style={{ fontSize: 14, color: props.theme.colors.secondary3, width: 80 }}><b>{`${ordinal(index + 1)} level`}</b></span>
                        <span style={{ fontSize: 14 }}>{getApproversText(level.performers, level.type)}</span>
                    </Flex>
            )
        }
    </FlexColumn>
}

export const ApproversStatusCard = (props) => {
    return <FlexColumn style={{ width: "100%", justifyContent: "start", margin: '8px 10px 10px 0' }}>
        {
            props.approvalRule && props.approvalRule.levels.map(
                (level, index) => <>
                    {
                        index > 0 &&
                        <Divider />
                    }
                    <div style={{ width: "100%", marginTop: 3, display: "flex", justifyContent: "flex-start" }}>
                        <span style={{ fontSize: 14, color: props.theme.colors.secondary3, width: 80, marginRight: 25 }}><b>{`${ordinal(index + 1)} level`}</b></span>
                        <FlexColumn style={{ width: "100%" }}>
                            {
                                level.performers.map((performer, index) =>
                                    <Flex between style={{ width: "100%" }}>
                                        <span style={{ fontSize: 14 }}>{`${performer.firstName} ${performer.lastName}`}</span>
                                        {
                                            props.approvalRuleInstance ?
                                                getApprovalStatus(performer.userName, props.approvalRuleInstance, level.rank)
                                                :
                                                <span style={{ fontSize: 14, fontWeight: 400, textAlign: "center" }}>--</span>
                                        }
                                    </Flex>
                                )
                            }
                        </FlexColumn>
                    </div>
                </>
            )
        }
    </FlexColumn>
}

function getApprovalStatus(userName, approvalRuleInstance, rank) {
    var levelInstance = approvalRuleInstance.levelInstances.find(i => { return i.rank === rank })

    console.log("getApprovalStatus levelInstance: ", JSON.stringify(levelInstance))

    var performerInstance = levelInstance ? levelInstance.performerInstances.find(i => { return i.userName === userName }) : undefined

    console.log("getApprovalStatus performerInstance: ", JSON.stringify(performerInstance))

    if (performerInstance) {
        switch (performerInstance.performedAction) {
            case "Approved":
                return <span style={{ fontSize: 14, fontWeight: 400, textAlign: "center" }}><CheckCircleTwoTone style={{ fontSize: '16px', marginRight: 4 }} twoToneColor="#65CED4" /> on {moment(performerInstance.actionTime).format("MMM D, h:mm a z")}</span>
                break
            case "Rejected":
                return <span style={{ fontSize: 14, fontWeight: 400, textAlign: "center" }}><CloseCircleTwoTone style={{ fontSize: '16px', marginRight: 4 }} twoToneColor="#f81d22" /> on {moment(performerInstance.actionTime).format("MMM D, h:mm a z")}</span>
                break
            default:
                return <span style={{ fontSize: 14, fontWeight: 400, textAlign: "center" }}>--</span>
        }


        return <span style={{ fontSize: 14, fontWeight: 400, textAlign: "center" }}><CheckCircleTwoTone style={{ fontSize: '16px', marginRight: 4 }} twoToneColor="#65CED4" /> on {moment().format("MMM D, h:mm a z")}</span>
    } else {
        return <span style={{ fontSize: 14, fontWeight: 400, textAlign: "center" }}>--</span>
    }
}

export const GettingStartedCard = (props) => {
    return <Flex className='gs-card' start padding='0 24px 0 0' style={{ minWidth: props.minWidth ? props.minWidth : 402, height: 200, width: props.cardWidth ? props.cardWidth :'33.3%' }}>
        <Flex center className='gs-card-tab'>
            <Image height='48px' width='48px' src={props.disabled ? Lock : props.completed ? Check : props.tabIcon} />
        </Flex>
        <FlexColumn left style={{ padding: 24 }}>
            <Text>{props.title}</Text>
            <Text size='14px' margin='4px 0 20px' width={props.width ? props.width : '234px'}>{props.desc}</Text>
            {
                props.completed &&
                <Tag color='#318F2F' background='#F5F9F5'>Completed</Tag>
            }
            {
                props.processing &&
                <Tag primary margin='24px 0 0'>Processing</Tag>
            }
            <Flex between fullWidth> {
                !props.completed && !props.processing &&
                <TextButton onClick={() => !props.disabled && props.onClick()} disabled={props.disabled} text={props.buttonText} rightIcon={<Image src={props.disabled ? RightDisabled : props.buttonIcon} />} />
            }
             {
                props.link && !props.completed &&
                <TextButton underline onClick={() => props.link && props.onLinkClick()}  text={props.linkText} icon={<Image src={props.linkIcon} />} />
            }</Flex>
            
        </FlexColumn>
    </Flex>
}

export const GettingStartedCardBlue = (props) => {
    return <Flex className='gs-card' start padding='0 24px 0 0' style={{ minWidth: props.minWidth ? props.minWidth : 402, height: 200, width: props.cardWidth ? props.cardWidth :'33.3%' }}>
        <Flex center className='gs-card-tab-blue'>
            <Image height='48px' width='48px' src={props.disabled ? Lock : props.completed ? Check : props.tabIcon} />
        </Flex>
        <FlexColumn left style={{ padding: 24 }}>
            <Text>{props.title}</Text>
            <Text size='14px' margin='4px 0 20px' width={props.width ? props.width : '234px'}>{props.desc}</Text>
            {
                props.completed &&
                <Tag color='#318F2F' background='#F5F9F5'>Completed</Tag>
            }
            {
                props.processing &&
                <Tag primary margin='24px 0 0'>Processing</Tag>
            }
            <Flex between fullWidth> {
                !props.completed && !props.processing &&
                <TextButton onClick={() => !props.disabled && props.onClick()} disabled={props.disabled} text={props.buttonText} rightIcon={<Image src={props.disabled ? RightDisabled : props.buttonIcon} />} />
            }
             {
                props.link && !props.completed &&
                <TextButton underline onClick={() => props.link && props.onLinkClick()}  text={props.linkText} icon={<Image src={props.linkIcon} />} />
            }</Flex>
            
        </FlexColumn>
    </Flex>
}