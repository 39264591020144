import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import { Tabs, Select, Dropdown, Menu } from 'antd'

import moment from 'moment'
import _ from 'lodash'
import { saveAs } from 'file-saver'
import { Flex, FlexColumn } from '../../Reusable/Container'
import { Button, TextButton, Chip, ImageButton } from '../../Reusable/Button'
import { Image } from '../../Reusable/Image'
import { apiPOSTReq } from '../../../Utils/api'
import environment from '../../../environment'
import { ErrorAlert } from '../../Reusable/Alert'
import { LabeledInput } from '../../Reusable/Input'
import { Text } from '../../Reusable/Text'
import { LoadingOutlined } from '@ant-design/icons'
import Search from '../../../Images/search.png'
import FilterIcon from '../../../Images/filter.png'
import Download from "../../../Images/download.png"


const { TabPane } = Tabs

var dFormat = "YYYY-MM-DD"

class SearchAndFilter extends Component {

    state = {
        loading: true,
        selectedTab: "Aion",
        pagination: {
            current: 1,
            pageSize: 10,
            showSizeChanger: false,
        },
        sorter: {},
        sortDirection: "DESC",
        showClear: false,
        selectedRowsPageDict: {},
        selectedRowKeyPageDict: {},
        customers: this.props.customers,
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.customers) !== JSON.stringify(this.props.customers)) {
            this.setState({ customers: this.props.customers });
        }
    }

    showClearAll = () => {
        const { fetchInvoices } = this.props

        var { searchTerm, filterInvoiceFromDate, filterInvoiceToDate, filterDueFromDate, filterDueToDate, filterFromAmount, filterToAmount, filterCustomerName } = this.state
        if (searchTerm || filterInvoiceFromDate || filterDueFromDate || filterFromAmount || filterCustomerName) {
            this.setState({ clearAll: true }, () => fetchInvoices({ ...this.state }))
        }
        else if (!searchTerm && !filterInvoiceFromDate && !filterDueFromDate && !filterFromAmount && !filterCustomerName) {
            this.setState({ clearAll: false }, () => fetchInvoices({ ...this.state }))
        }
    }

    clearAll = () => {
        const { fetchInvoices, customers } = this.props

        this.setState({
            dueFromDate: null,
            dueToDate: null,
            filterDueFromDate: null,
            filterDueToDate: null,
            searchTerm: null,
            invoiceFromDate: null,
            invoiceToDate: null,
            filterInvoiceFromDate: null,
            filterInvoiceToDate: null,
            fromAmount: null,
            toAmount: null,
            filterFromAmount: null,
            filterToAmount: null,
            customerName: null,
            filterCustomerName: null,
            clearAll: null,
            pagination: {
                current: 1,
                pageSize: 10
            },
            customers: [],
        }, () => {
            this.setState({ customers })
            fetchInvoices({ ...this.state })
        })
    }

    onTabChange = tab => {
        this.setState({ tab })
    }

    downloadCSVStmt = (options) => {
        this.setState({ csvDownloading: true })

        const businessInfo = this.props.aionStore?.business?.businessProfile?.businessInfo;
        var { name } = businessInfo

        var { searchTerm, filterInvoiceFromDate, filterInvoiceToDate, filterDueFromDate, filterDueToDate, filterFromAmount, filterToAmount, filterCustomerName } = options
        var { filterStatus, customerName } = this.props

        if (!searchTerm) searchTerm = this.state.searchTerm
        if (!filterInvoiceFromDate) filterInvoiceFromDate = this.state.filterInvoiceFromDate
        if (!filterInvoiceToDate) filterInvoiceToDate = this.state.filterInvoiceToDate

        if (!filterDueFromDate) filterDueFromDate = this.state.filterDueFromDate
        if (!filterDueToDate) filterDueToDate = this.state.filterDueToDate

        if (!filterCustomerName) filterCustomerName = this.state.filterCustomerName

        if (!filterFromAmount) filterFromAmount = this.state.filterFromAmount
        if (!filterToAmount) filterToAmount = this.state.filterToAmount

        var body = {}

        body.invoiceFromDate = filterInvoiceFromDate
        body.invoiceToDate = filterInvoiceToDate
        body.customerName = customerName || filterCustomerName
        body.dueFromDate = filterDueFromDate
        body.dueToDate = filterDueToDate
        body.fromAmount = filterFromAmount
        body.toAmount = filterToAmount
        body.searchStr = searchTerm

        if (filterStatus) {
            body.viewBy = "FILTER_STATUS"
            body.invoiceStatus = filterStatus.toUpperCase()
        }

        console.log("SearchAndFilter invoice/exportInvoices body", body)

        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/exportInvoices`, null, body, (err, resp) => {
            try {

                this.setState({ csvDownloading: false })
                const data = resp || {}
                // console.log("/getInvoices", data)
                console.log("invoice/exportInvoices", data)
                if (err) throw Error("We had trouble downloading your statement. Please try again.");
                const blob = new Blob([data], {
                    type: 'application/vnd.ms-excel',
                });
                const fileURL = URL.createObjectURL(blob);
                saveAs(fileURL, `Invoice Report - ${name}.csv`);

            } catch (error) {
                console.log("ERRR getInvoices", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    handleSearchChange = (e) => {
        const { fetchInvoices } = this.props

        const pagination = {
            current: 1,
            pageSize: 10
        }

        this.setState({ [e.target.id]: e.target.value, pagination, amountError: false }, () => fetchInvoices({ ...this.state }))


        // fetchInvoices({
        //     searchTerm: e.target.value,
        //     pagination,
        //     filterStatus: 'voided',
        // })
    }

    render() {
        const { invoiceFromDate, invoiceToDate, filterInvoiceFromDate, filterInvoiceToDate, voidLoading,
            dueFromDate, dueToDate, filterDueFromDate, filterDueToDate, filterType, filterFromAmount, filterToAmount, filterCustomerName,
            customers, csvDownloading, searchTerm, dropdownVisible, fromAmount, toAmount, customerName, clearAll, amountError } = this.state

        const { fetchInvoices, hideCustomers } = this.props

        return (
            <Flex between centerHorizontally gap='12px'>
                <LabeledInput
                    id="searchTerm"
                    nomargin
                    width='250px'
                    placeholder="Search by invoice #"
                    prefixIcon={<img width='12px' height='12px' src={Search} />}
                    value={searchTerm}
                    onChange={this.handleSearchChange}
                />

                <Flex end fullWidth style={{  }}>
                {
                    ((filterInvoiceFromDate && filterInvoiceToDate) || (filterDueFromDate && filterDueToDate) || searchTerm || (filterFromAmount || filterToAmount) || (filterCustomerName)) &&
                    <Flex between fullWidth centerHorizontally>
                        <Flex start wrap gap='8px' centerHorizontally>
                            {
                                searchTerm && false &&
                                <Chip closable onDelete={() => {
                                    this.setState({ searchTerm: null, pagination: { current: 1, pageSize: 10 } }, () => this.showClearAll())
                                }} text={`"${searchTerm}"`} />
                            }


                            {
                                filterDueFromDate && filterDueToDate &&
                                <Chip closable onDelete={() => {
                                    this.setState({ dueFromDate: null, dueToDate: null, filterDueFromDate: null, filterDueToDate: null, pagination: { current: 1, pageSize: 10 } }, () => this.showClearAll())
                                }} text={`Due Date: ${moment(filterDueFromDate).format('ll')} - ${moment(filterDueToDate).format('ll')}`} />
                            }

                            {
                                filterInvoiceFromDate && filterInvoiceToDate &&
                                <Chip closable onDelete={() => {
                                    this.setState({ invoiceFromDate: null, invoiceToDate: null, filterInvoiceFromDate: null, filterInvoiceToDate: null, pagination: { current: 1, pageSize: 10 } }, () => this.showClearAll())
                                }} text={`Invoice Date: ${moment(filterInvoiceFromDate).format('ll')} - ${moment(filterInvoiceToDate).format('ll')}`} />
                            }

                            {
                                (filterFromAmount || filterToAmount) && !amountError &&
                                <Chip closable onDelete={() => {
                                    this.setState({ fromAmount: null, toAmount: null, filterFromAmount: null, filterToAmount: null, pagination: { current: 1, pageSize: 10 } }, () => this.showClearAll())
                                }} text={`Amount: $${filterFromAmount} - $${filterToAmount}`} />
                            }

                            {
                                filterCustomerName &&
                                <Chip closable onDelete={() => {
                                    const { customers } = this.props
                                    this.setState({ filterCustomerName: null, pagination: { current: 1, pageSize: 10 }, customers: [], customerName: null, }, () => {
                                        this.setState({ customers })
                                        this.showClearAll()
                                    })
                                }} text={`"Customer Name: ${filterCustomerName}"`} />
                            }


                        </Flex>
                    </Flex>
                }
                </Flex>

                <Flex gap='12px' centerHorizontally>
                    {
                        clearAll &&
                        <>
                            <Text primary noWrap underline style={{ cursor: 'pointer' }} onClick={() => this.clearAll()}>Clear all</Text>
                        </>
                    }

                    <Flex gap="8px" center>
                        <ImageButton src={Download} onClick={() => this.downloadCSVStmt({ ...this.state })} />
                        {csvDownloading && <LoadingOutlined style={{ fontSize: 18 }} spin />}
                    </Flex>

                    <Dropdown
                        placement='bottomRight'
                        trigger={['click']}
                        onVisibleChange={flag => this.setState({ dropdownVisible: flag })}
                        visible={dropdownVisible}
                        overlay={(
                            <Menu onClick={() => { }} style={{ width: 600, padding: '12px 24px 24px', borderRadius: 8 }}>
                                <Tabs onClick={() => { }} onChange={this.onTabChange}>
                                    <TabPane tab="Invoice Date" key="invoiceDate">
                                        <FlexColumn left gap='24px'>
                                            <Flex between gap='24px'>
                                                <LabeledInput
                                                    label="From"
                                                    type="date-picker"
                                                    disabledDate={(date) => invoiceToDate != null ? date > this.state.invoiceToDate : false}
                                                    placeholder='MM/DD/YYYY'
                                                    value={invoiceFromDate}
                                                    format={'MM/DD/YYYY'}
                                                    onChange={(invoiceFromDate, dateStr) => this.setState({ invoiceFromDate: invoiceFromDate })}
                                                />
                                                <LabeledInput
                                                    label="To"
                                                    type="date-picker"
                                                    disabledDate={(date) => invoiceFromDate != null ? date < this.state.invoiceFromDate : false}
                                                    placeholder='MM/DD/YYYY'
                                                    value={invoiceToDate}
                                                    format={'MM/DD/YYYY'}
                                                    onChange={(invoiceToDate, dateStr) => this.setState({ invoiceToDate: invoiceToDate })}
                                                />
                                            </Flex>
                                            <Button solid disabled={!invoiceFromDate || !invoiceToDate} text='APPLY' onClick={() => {
                                                this.setState({ filterInvoiceFromDate: invoiceFromDate.format(dFormat), filterInvoiceToDate: invoiceToDate.format(dFormat) }, () => {
                                                    var updatedState = {
                                                        dateType: "Custom",
                                                        loading: true,
                                                        filterInvoiceFromDate: invoiceFromDate.format(dFormat),
                                                        filterInvoiceToDate: invoiceToDate.format(dFormat),
                                                        pagination: {
                                                            current: 1,
                                                            pageSize: 10
                                                        },
                                                        clearAll: true,
                                                        amountError: false,
                                                    }
                                                    fetchInvoices({ ...this.state, ...updatedState })
                                                    this.setState({ dropdownVisible: false, clearAll: true, amountError: false, })
                                                })
                                            }} />

                                        </FlexColumn>
                                    </TabPane>

                                    <TabPane tab="Due Date" key="dueDate">
                                        <FlexColumn left gap='24px'>
                                            <Flex between gap='24px'>
                                                <LabeledInput
                                                    label="From"
                                                    type="date-picker"
                                                    disabledDate={(date) => dueToDate != null ? date > this.state.dueToDate : false}
                                                    placeholder='MM/DD/YYYY'
                                                    value={dueFromDate}
                                                    format={'MM/DD/YYYY'}
                                                    onChange={(dueFromDate, dateStr) => this.setState({ dueFromDate: dueFromDate })}
                                                />
                                                <LabeledInput
                                                    label="To"
                                                    type="date-picker"
                                                    disabledDate={(date) => dueFromDate != null ? date < this.state.dueFromDate : false}
                                                    placeholder='MM/DD/YYYY'
                                                    value={dueToDate}
                                                    format={'MM/DD/YYYY'}
                                                    onChange={(dueToDate, dateStr) => this.setState({ dueToDate: dueToDate })}
                                                />
                                            </Flex>
                                            <Button solid disabled={!dueFromDate || !dueToDate} text='APPLY' onClick={() => {
                                                this.setState({ filterDueFromDate: dueFromDate.format(dFormat), filterDueToDate: dueToDate.format(dFormat) }, () => {
                                                    var updatedState = {
                                                        dateType: "Custom",
                                                        loading: true,
                                                        filterDueFromDate: dueFromDate.format(dFormat),
                                                        filterDueToDate: dueToDate.format(dFormat),
                                                        pagination: {
                                                            current: 1,
                                                            pageSize: 10
                                                        },
                                                        clearAll: true,
                                                        amountError: false,
                                                    }
                                                    fetchInvoices({ ...this.state, ...updatedState })
                                                    this.setState({ dropdownVisible: false, clearAll: true, amountError: false, })
                                                })
                                            }} />
                                        </FlexColumn>
                                    </TabPane>

                                    <TabPane tab="Amount" key="amount">
                                        <FlexColumn left gap='24px'>
                                            <Flex between gap='24px'>
                                                <LabeledInput
                                                    // type="number"
                                                    label="From"
                                                    id="fromAmount"
                                                    key="fromAmount"
                                                    prefix="$"
                                                    placeholder="100"
                                                    min={0}
                                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    value={fromAmount}
                                                    onChange={(e) => this.setState({ fromAmount: (!isNaN(parseFloat(e.target.value)) ? parseFloat(e.target.value) : null) })}
                                                />
                                                <LabeledInput
                                                    // type="number"
                                                    label="To"
                                                    id="toAmount"
                                                    key="toAmount"
                                                    prefix="$"
                                                    placeholder="1000"
                                                    min={0}
                                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    value={toAmount}
                                                    onChange={(e) => this.setState({ toAmount: (!isNaN(parseFloat(e.target.value)) ? parseFloat(e.target.value) : null) })}
                                                    error={amountError}
                                                    errorMessage={amountError}
                                                />
                                            </Flex>
                                            <Button solid disabled={!(typeof (fromAmount) == 'number' && typeof (toAmount) == 'number')} text='APPLY' onClick={() => {
                                                this.setState({ filterFromAmount: fromAmount, filterToAmount: toAmount }, () => {
                                                    if (this.state.filterFromAmount > this.state.filterToAmount) {
                                                        this.setState({ amountError: "To amount should be greater than or equal to From amount" })
                                                        return
                                                    }
                                                    else if (this.state.filterFromAmount == 0 && this.state.filterToAmount == 0) {
                                                        this.setState({ amountError: "Please enter an amount greater than $0" })
                                                        return
                                                    }
                                                    var updatedState = {
                                                        dateType: "Custom",
                                                        loading: true,
                                                        filterFromAmount: fromAmount,
                                                        filterToAmount: toAmount,
                                                        pagination: {
                                                            current: 1,
                                                            pageSize: 10
                                                        },
                                                        clearAll: true,
                                                        amountError: false,
                                                    }
                                                    fetchInvoices({ ...this.state, ...updatedState })
                                                    this.setState({ dropdownVisible: false, clearAll: true, amountError: false, })
                                                })
                                            }} />
                                        </FlexColumn>
                                    </TabPane>

                                    {/* <TabPane tab="Invoice Status" key="invoiceStatus">
                                                            <FlexColumn left gap='24px'>
                                                                <Flex between gap='24px'>
                                                                </Flex>
                                                            </FlexColumn>
                                                        </TabPane> */}

                                    {
                                        !hideCustomers &&
                                        <TabPane tab="Customers" key="customer">
                                            <FlexColumn left gap='24px'>
                                                <Flex between gap='24px'>
                                                    <LabeledInput
                                                        label=""
                                                        type="select"
                                                        showSearch
                                                        prefixIcon={<img width='12px' height='12px' src={Search} />}
                                                        placeholder="Search by Customer Name"
                                                        value={customerName}
                                                        onChange={(val) => { this.setState({ customerName: val }) }}
                                                        width={250}
                                                        dropdownRender={option => (
                                                            <div>
                                                                {option}
                                                            </div>
                                                        )}
                                                    >
                                                        {(customers || []).map(item => <Select.Option key={item.customerId} id="customerName" name={item.displayName || item.companyName} value={item.displayName || item.companyName} style={{ backgroundColor: "transparent" }}>{item.displayName || item.companyName}</Select.Option>)}
                                                    </LabeledInput>
                                                </Flex>
                                                <Button solid disabled={!customerName} text='APPLY' onClick={() => {
                                                    this.setState({ filterCustomerName: customerName }, () => {
                                                        var updatedState = {
                                                            dateType: "Custom",
                                                            loading: true,
                                                            filterCustomerName: customerName,
                                                            pagination: {
                                                                current: 1,
                                                                pageSize: 10
                                                            },
                                                            clearAll: true,
                                                            amountError: false,
                                                        }
                                                        fetchInvoices({ ...this.state, ...updatedState })
                                                        this.setState({ dropdownVisible: false, clearAll: true, amountError: false, })
                                                    })
                                                }} />
                                            </FlexColumn>
                                        </TabPane>
                                    }
                                </Tabs>
                            </Menu>
                        )}
                    >
                        <TextButton text='FILTER' rightIcon={<Image src={FilterIcon} />} />
                    </Dropdown>
                </Flex>
            </Flex>
        )
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(SearchAndFilter)))