import { Divider } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import environment from '../../../environment';

// Components
import { FlexColumn, Flex } from '../../Reusable/Container';
import { Title } from '../../Reusable/Refresh/Text';
import { Text } from '../../Reusable/Text';
import { apiPOSTReq } from '../../../Utils/api';
import { crbAddressObjectToMultiLineStr, toCurrency } from '../../../Utils/util'

const dividerMargin = "10px";

class ViewCard extends Component {
    vgsFrame = null;
    cardNumRef = React.createRef();
    cvvRef = React.createRef();
    state = { loading: true };
    frameStyle = {};
    keyStyle = {
        'font-size': '14px',
        'font-weight': '400',
        // 'color': this.props.theme.colors.systemGray
    }
    frameStyle = {
        zoom: 0.85,
        'font-style': 'normal',
        '-webkit-font-smoothing': 'auto',
        'color': this.props.theme.colors.secondary6,
        'font-size': '14px',
        'font-weight': '300',
        'text-align': 'right',
        'font-display': 'swap',
        'unicode-range': 'U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116',
        '@font-face': {
            'font-family': 'PT Mono',
            'font-style': 'light',
            'font-weight': '300',
            'text-align': 'right',
            'font-display': 'swap',
            'src': 'local("PT Mono"), local("PTMono-Regular") url(https://fonts.gstatic.com/s/ptmono/v7/9oRONYoBnWILk-9AnCszM_HxEcn7Hg.woff2) format("woff2")',
            'unicode-range': 'U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116'
        },
        'font-family': '"PT Mono", monospace',
    }

    state = {
        crbSensitiveCardInfo: {}
    }

    componentDidMount() {
        const { selectedCard } = this.props;
        var virtual = ["VirualPan", "VirtualPan"].includes(selectedCard.paymentInstrument)

        if (virtual) {
            this.loadVGS();
        }
    }

    loadVGS = () => {
        const { aionStore, bbPersonCardSensitiveInfo } = this.props;
        var { UAM } = aionStore;
        UAM = UAM || {};
        console.log("environment", environment);
        const show = window.VGSShow.create(environment.vgs.vaultID, (state) => { console.log("VGSShow", state) }).setEnvironment(environment.vgs.env);
        const body = {
            name: 'secret-card-number',
            method: 'POST',
            path: "/bp/cards/authCardSensitiveInfo",
            // path: "/post",
            headers: {
                AionAuth: UAM.encryptedAuthHeader,
                AionCurrentBiz: aionStore.BusinessUniqueKey
            },
            payload: bbPersonCardSensitiveInfo || {},
            serializers: [show.SERIALIZERS.replace('(\\d{4})(\\d{4})(\\d{4})(\\d{4})', '$1 $2 $3 $4')],
            htmlWrapper: 'text',
            jsonPathSelector: 'pan'
        };
        console.log("vgs body", body);

        this.vgsFrame = show.request(body);
        this.vgsFrame.render(this.cardNumRef.current, this.frameStyle);

        // Display CVV2
        this.vgsFrame = show.request(Object.assign(body, { jsonPathSelector: 'cvv2', serializers: [] }));
        this.vgsFrame.render(this.cvvRef.current, this.frameStyle);
        var t = this;
        this.vgsFrame.on('revealSuccess', function () {
            console.log('Reveal opeation successfully was completed. Secure value revealed!');
            t.setState({ loading: false });
            document.getElementById('secret-card-number-mask').style.display = 'none';
            document.getElementById('cvv2-mask').style.display = 'none';
        });
        var t = this
        this.vgsFrame.on('requestFail', function () {
            console.log('Reveal opeation was failed in the iframe!')
            if(!environment.isSandbox) t.loadCardBackup()
        })
    }

    loadCardBackup = () => {
        const { bbPersonCardSensitiveInfo } = this.props;
        apiPOSTReq(`${environment.bpBaseUrl}/bp/cards/getBPCardSensitiveInfo`, {}, bbPersonCardSensitiveInfo, (err, resp) => {
            try {
                const data = resp || {}
                // console.log('/getBPCardSensitiveInfo', data)
                if (data.result) {
                    var crbSensitiveCardInfo = data.crbSensitiveCardInfo || {}
                    this.setState({ crbSensitiveCardInfo: crbSensitiveCardInfo })
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    getCardRow = (key, value) => {
        return (
            <FlexColumn>
                <Flex between>
                    <Text size='14px' color={this.props.theme.colors.caption}>{key}</Text>
                    <Text style={{ textAlign: 'right' }}>{value}</Text>
                </Flex>
            </FlexColumn>
        )
    }

    getLimitCell = (amount, per) => {
        return (
            <Flex start left gap='5px'>
                <Text>{amount}</Text>
                <Text color='#666666' size='12px'>/ {per}</Text>
            </Flex>
        )
    }

    render() {
        var { crbSensitiveCardInfo } = this.state
        var { selectedCard, virtual } = this.props
        selectedCard = selectedCard || {}
        var pan = crbSensitiveCardInfo.pan
        if (pan) {
            pan = pan.match(/.{1,4}/g).join(" ")
            console.log("pan", pan)
        }
        var virtual = ["VirualPan", "VirtualPan"].includes(selectedCard.paymentInstrument)

        return (
            <FlexColumn>
                <Text heading>Card Details</Text>
                {/* <Text lightText>Use this card number online. Request a new number if vou suspect your current one has been compromised.</Text> */}
                <FlexColumn style={{ marginTop: "24px" }} gap='12px'>
                    {this.getCardRow("Type", ["VirualPan", "VirtualPan"].includes(selectedCard.paymentInstrument) ? 'Virtual' : 'Physical')}
                    {this.getCardRow("Name", selectedCard.nameOnCard)}
                    {
                        virtual &&
                        <FlexColumn>
                            <Flex between>
                                <Text size='14px' color={this.props.theme.colors.caption}>Card Number</Text>
                                <div style={{ width: "160px", height: "0px" }} id="secret-card-number" ref={this.cardNumRef} />
                                <div id="secret-card-number-mask"><span style={this.frameStyle}><Text>{pan || `•••• •••• •••• ${selectedCard.panLastFour}`}</Text></span></div>
                            </Flex>
                        </FlexColumn>
                    }
                    {
                        virtual &&
                        <FlexColumn>
                            <Flex between>
                                <Text size='14px' color={this.props.theme.colors.caption}>Security Code</Text>
                                <div style={{ width: "27px", height: "0px" }} id="cvv2" ref={this.cvvRef} />
                                <div id="cvv2-mask"><span style={this.frameStyle}><Text>{crbSensitiveCardInfo.cvv2 || `***`}</Text></span></div>
                            </Flex>
                        </FlexColumn>
                    }
                    {this.getCardRow("Issued on", moment(selectedCard.createdAt).format("MM/DD/YY"))}
                    {this.getCardRow("Expiry", moment(selectedCard.expirationDate).format("MM/YYYY"))}
                    {this.getCardRow("Network", "Visa")}
                    {this.getCardRow("Billing address", crbAddressObjectToMultiLineStr(selectedCard.billingAddress, true))}
                    
                    <div style={{ height: 1, width: '100%', background: '#F2F2F2', margin: '12px 0' }} />

                    <FlexColumn start left gap='16px'>
                        <>
                            <Text size='14px' color={this.props.theme.colors.caption}>Purchase limits</Text>
                            <Flex between fullWidth gap="32px" style={{ marginBottom: 12 }}>
                                {this.getLimitCell(selectedCard ? `$${toCurrency(selectedCard.perTransactionLimit, 0, 0)}` : "$2,000.00", 'Transaction')}
                                {this.getLimitCell(selectedCard ? `$${toCurrency(selectedCard.maxDailyLimit, 0, 0)}` : "$5,000.00", 'Day')}
                                {this.getLimitCell(selectedCard ? `$${toCurrency(selectedCard.maxMonthlyLimit, 0, 0)}` : "$150,000.00", 'Month')}
                                {/* {this.getLimitCell('Withdrawal limit', selectedCard ? `$${toCurrency(selectedCard.cashWithdrawalLimit)}` : "$1,000.00", 'Day')} */}
                            </Flex>
                        </>
                        <>
                            <Text size='14px' color={this.props.theme.colors.caption}>Cash withdrawal limit</Text>
                            <Flex start fullWidth gap="32px" style={{ marginBottom: 12 }}>
                                {this.getLimitCell(selectedCard ? `$${toCurrency(selectedCard.cashWithdrawalLimit, 0, 0)}` : "$1,000.00", 'Day')}
                            </Flex>
                        </>
                    </FlexColumn>
                    
                </FlexColumn>
            </FlexColumn>

        )
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(ViewCard));