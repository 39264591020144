import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import moment from 'moment'
import { Flex, FlexColumn, CardContainer } from '../../Reusable/Container'
import PageHeader from '../../Reusable/PageHeader'

import { Tooltip, message, Modal, Skeleton, Spin } from 'antd'
import { apiGETDocUrl, apiPOSTReq } from '../../../Utils/api'
import environment from '../../../environment'
import { addDataToStore, BNK_SAVE_DATA } from '../../../Actions/actions'
import { ErrorAlert } from '../../Reusable/Alert'
import { LabeledInput } from '../../Reusable/Input'
import { Button, ImageButton, TextButton } from '../../Reusable/Button'
import { Text, Tag } from '../../Reusable/Text'
import { Image } from '../../Reusable/Image'
import CardSelector from '../Reusable/CardSelector'
import DebitCard from '../Reusable/DebitCard'
import { fetchBBTransactions, normalizeAndSortHistory } from '../../../Utils/bankingDataManager'
import ViewCard from './ViewCard'
import ValidateCard from './ValidateCard'
import ActivateWithPin from './ActivateWithPin'
import MonthlySpendBarChart from '../../Reusable/MonthlySpendBarChart'
import SpendByCategory from '../../Reusable/SpendByCategory'
import ActivityTable from '../Reusable/ActivityTable'
import PendingTable from '../Reusable/PendingTable'
import CardSelectorList from '../Reusable/CardSelectorList'
import ModalClose from '../../../Images/modal-close.png'
import Info from '../../../Images/info.png'
import CardImg from '../../../Images/aion-debit-card.png'
import Download from "../../../Images/download.png"
import ArrowRight from "../../../Images/arrow-right.png"
import { LoadingOutlined } from '@ant-design/icons'
import { saveAs } from 'file-saver'
import { toCurrency } from '../../../Utils/util'

const etTimeZone = "America/New_York"

class Index extends Component {
    state = {
        loading: true,
        pinError: false,
        pinLoading: false,
        loadingPosted: true,
        pagination: { pageSize: 10, current: 1 },
        activateLoading: false,
        masked: true,
        cards: [],
        moreToLoad: false,
        loadingPending: true,
    }

    componentDidMount() {
        const { view, card } = this.props
        const { pagination } = this.state
        if (view && card) {
            console.log('componentDidMount Index card', card)
            this.fetchActivity({ accountId: card.accountId, selectedCard: card, pagination })
            this.setState({ loading: false, cards: [card], selectedCard: card, activeCard: card }, () => this.fetchAllPendingData({ accountId: card.accountId, selectedCard: card, pagination }))
        } else {
            this.fetchCards()
        }

        this.getCardTxnsSpendAnalysis()
    }

    fetchCards = () => {
        const { view, viewEmail } = this.props
        var { UserInfo } = this.props.aionStore
        UserInfo = UserInfo || {}
        var { selectedCard, pagination } = this.state
        this.setState({ loading: true })
        apiPOSTReq(`${environment.bbBaseUrl}/bb/card/getPersonCards`, {}, { "PersonEmail": viewEmail || UserInfo.Email }, (err, resp) => {
            this.setState({ loading: false })
            try {
                const data = resp || {}
                console.log('bb/card/getPersonCards', data)
                if (data.result) {
                    const cards = data.bbPersonCards || []
                    this.props.dispatch(addDataToStore(BNK_SAVE_DATA, { DebitCards: cards }))
                    var openCards = cards.filter(card => (card.status !== 'Closed')).sort((b, a) => moment(a.createdAt).toDate() - moment(b.createdAt).toDate())
                    var closedCards = cards.filter(card => (card.status === 'Closed')).sort((b, a) => moment(a.createdAt).toDate() - moment(b.createdAt).toDate())
                    var sortedCards = openCards.concat(closedCards)
                    var activeCard

                    if (this.props.location.state && this.props.location.state.cardId) {
                        activeCard = cards.find(card => card.cardId === this.props.location.state.cardId) || null
                    } else {
                        activeCard = (openCards.length > 0) ? openCards[0] : null
                    }

                    if (activeCard) {
                        activeCard.selected = true
                        this.fetchActivity({ accountId: activeCard.accountId, selectedCard: activeCard, pagination })
                    }

                    if (selectedCard) {
                        selectedCard = cards.find((card) => card.cardId === selectedCard.cardId)
                        if(selectedCard) selectedCard.selected = true
                    }

                    console.log('UserCards fetchCards selectedCard: ', selectedCard)

                    this.setState({ loading: false, cards: sortedCards, selectedCard: selectedCard ? selectedCard : activeCard, activeCard },
                        () => {
                            if (selectedCard || activeCard) {
                                this.fetchAllPendingData({ accountId: (selectedCard ? selectedCard : activeCard).accountId, selectedCard: selectedCard ? selectedCard : activeCard, pagination })
                            }
                        }
                    )
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp)
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            } finally {
                this.setState({ activateLoading: false })
            }
        })
    }

    setPin = (bbPersonCard) => {
        const { pin, confirmPin } = this.state

        this.setState({ pinError: false, confirmPinError: false })

        if (isNaN(pin) || pin.length < 4) {
            this.setState({ pinError: true })
        } else if (pin !== confirmPin) {
            this.setState({ confirmPinError: true })
        } else {
            this.setState({ pinError: false, confirmPinError: false, pinLoading: true })

            apiPOSTReq(`${environment.bbBaseUrl}/bb/card/setPinOnCard`, { "BankProvider": environment.bankProvider.crb }, { pin, bbPersonCard }, (err, resp) => {
                try {
                    const data = resp || {}
                    if (data.result) {
                        this.setState({ showSetPinModal: false, pin: null, confirmPin: null, selectedCard: data.bbPersonCard })
                        message.success('PIN set!')
                    } else {
                        throw Error(data.responseMessage || data.error)
                    }
                } catch (error) {
                    console.log("/bb/card/setPinOnCard err", error, resp)
                    ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
                } finally {
                    this.setState({ pinLoading: false })
                }
            })
        }
    }

    fetchActivity = (options) => {
        const { postedTransfers } = this.state
        var { fromDate, toDate, searchStr, fromAmt, toAmt, accountId, pagination } = options
        var { selectedCard } = this.state
        selectedCard = selectedCard || {}
        if (options.selectedCard) selectedCard = options.selectedCard
        // var fetchOptions = {
        //     pagination: pagination
        // }
        // // if (accountId) {
        // //     fetchOptions.accountId = accountId
        // // }
        // if (searchStr) {
        //     fetchOptions.searchStr = searchStr
        // }
        // if (fromDate) {
        //     fetchOptions.fromDate = fromDate
        //     fetchOptions.toDate = toDate
        // }
        // fetchOptions.bankProvider = environment.bankProvider.crb
        // fetchOptions.providerStatus = "Posted"
        // fetchOptions.cardId = selectedCard.cardId

        this.setState({ loadingPosted: true, ...options })

        // console.log('fetchActivity body: ', fetchOptions)

        // fetchBBTransactions(fetchOptions, (err, resp) => {
        //     try {
        //         if (err) throw new Error(err)
        //         const newData = fetchOptions.pagination.current > 1 ? postedTransfers.concat(resp.Transactions) : resp.Transactions

        //         this.setState({ loadingPosted: false, postedTransfers: newData, moreToLoad: newData.length !== resp.transactionsCount, pagination })
        //     } catch (error) {
        //         ErrorAlert({ description: error.message })
        //     }
        // })
        const body = {
            accountNumber: selectedCard.accountNumber,
            panLastFour: selectedCard.last4,
            sortDirection: "DESC"
        };
        if (searchStr) body.searchStr = searchStr
        if (fromDate) body.fromDate = fromDate
        if (toDate) body.toDate = toDate
        if (fromAmt) body.fromAmt = fromAmt
        if (toAmt) body.toAmt = toAmt

        if (pagination) {
            body.size = pagination.pageSize;
            body.page = pagination.current - 1
        }

        console.log('bb/getPostedCardTxns body', body)

        apiPOSTReq(`${environment.bbBaseUrl}/bb/getPostedCardTxns`, {}, body, (err, data) => {
            try {
                if (err) throw new Error(err)

                if (data) {
                    console.log('bb/getPostedCardTxns resp', data)

                    const postedCardTransactionList = data.postedCardTransactionList || []

                    const newData = pagination.current > 1 ? postedTransfers.concat(postedCardTransactionList) : postedCardTransactionList;

                    (newData || []).forEach(txn => {
                        txn.rail = 'CardManagement'
                        txn.amount = txn.transactionAmount
                        txn.txnDate = moment.utc(txn.createdAt).tz(etTimeZone)
                    })

                    this.setState({ loadingPosted: false, postedTransfers: newData, moreToLoad: newData.length !== data.transactionsCount, pagination })
                }
            } catch (error) {
                ErrorAlert({ description: error.message })
            }
        })
    }

    fetchPendingActivity = (options) => {
        return new Promise((resolve, reject) => {
            const { postedTransfers } = this.state
            var { fromDate, toDate, searchStr, accountId, pagination, selectedCard } = options
            selectedCard = selectedCard || {}

            var fetchOptions = {
                pagination: pagination
            }
            // if (accountId) {
            //     fetchOptions.accountId = accountId
            // }
            if (searchStr) {
                fetchOptions.searchStr = searchStr
            }
            if (fromDate) {
                fetchOptions.fromDate = fromDate
                fetchOptions.toDate = toDate
            }
            fetchOptions.bankProvider = environment.bankProvider.crb
            fetchOptions.providerStatus = "Pending"

            fetchOptions.cardId = selectedCard.cardId

            this.setState({ loadingPosted: true })

            console.log('fetchPendingActivity body: ', fetchOptions)

            fetchBBTransactions(fetchOptions, (err, resp) => {
                // try {
                //     if (err) throw new Error(err)
                //     const newData = fetchOptions.pagination.current > 1 ? postedTransfers.concat(resp.Transactions) : resp.Transactions

                //     this.setState({ loadingPosted: false, postedTransfers: newData, moreToLoad: newData.length !== resp.transactionsCount, pagination })
                // } catch (error) {
                //     ErrorAlert({ description: error.message })
                // }
                if (err) {
                    console.log('fetchPendingActivity err: ', err)
                    reject(err);
                    return;
                }

                console.log('fetchPendingActivity resp: ', resp)
                resolve(resp);
            })
        });
    }

    getPendingCardTxns = () => {
        return new Promise((resolve, reject) => {
            var { selectedCard } = this.state

            const body = {
                "AccountNumber": selectedCard.accountNumber,
                "RailType": "CARDMANAGEMENT",
                "ProviderStatus": "Active",
                "PanLastFour": selectedCard.last4,
            };

            console.log('bb/getMemoPosts body', body)

            apiPOSTReq(`${environment.bbBaseUrl}/bb/getMemoPosts`, {}, body, (err, data) => {
                // try {
                //     const data = resp || {}
                //     console.log('bb/getMemoPosts', data)
                //     if (data.memoPostList) {
                //         console.log("bb/getMemoPosts pendingTransfers", { cardMemoList: data.memoPostList })
                //         const normalizedHistory = normalizeAndSortHistory({ cardMemoList: data.memoPostList });
                //         this.setState({ pendingTransfers: normalizedHistory.normalizedData, pendingSums: normalizedHistory.pendingSums })
                //     } else {
                //         throw Error(data.responseMessage || data.error)
                //     }
                // } catch (error) {
                //     console.log("bb/getMemoPosts err", error, resp)
                //     // ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
                // } finally {
                //     this.setState({ loadingPending: false })
                // }

                console.log('fetchAllData bb/getMemoPosts', data);

                if (err) {
                    console.log("fetchAllData bb/getMemoPosts err", err, data);
                    // ErrorAlert({ description: err.message || "Sorry, we had trouble processing your request. Please try again." });
                    reject(err);
                    return;
                }

                if (data && data.memoPostList) {
                    resolve(data.memoPostList);
                } else {
                    resolve([]);
                    // reject(new Error(data.responseMessage || data.error));
                }
            })
        });
    }

    fetchAllPendingData = async (options) => {
        this.setState({ loadingPending: true })
        try {
            let pendingTransfers = {} // await this.fetchPendingActivity(options);

            let memoPostList = await this.getPendingCardTxns();

            if (memoPostList) {
                pendingTransfers.cardMemoList = memoPostList;
            }

            console.log('fetchAllData: ', pendingTransfers,)

            const normalizedHistory = normalizeAndSortHistory(pendingTransfers);
            // setPendingTransfers(normalizedHistory.normalizedData);
            // setPendingSums(normalizedHistory.pendingSums);
            // setLoadingPending(false);

            this.setState({ pendingTransfers: normalizedHistory.normalizedData, pendingSums: normalizedHistory.pendingSums, loadingPending: false })
        } catch (error) {
            console.log('fetchAllData ERROR:', error);
            // Handle the error, maybe show an error alert or something similar.
        }
    };

    loadMorePostedTransactions = () => {
        this.setState({ pagination: { pageSize: 10, current: this.state.pagination.current + 1 } }, () => {
            var { fromDate, toDate, searchStr, fromAmt, toAmt, selectedCard, pagination } = this.state
            var options = {
                accountId: selectedCard.accountId,
                selectedCard: selectedCard,
                pagination: pagination
            }
            if (searchStr) options.searchStr = searchStr
            if (fromDate) options.fromDate = fromDate
            if (toDate) options.toDate = toDate
            if (fromAmt) options.fromAmt = fromAmt
            if (toAmt) options.toAmt = toAmt
            this.fetchActivity(options)
        })
    }

    handleSelectCard = (card) => {
        const { cards } = this.state
        cards.forEach(c => c.selected = false)
        card.selected = true
        this.setState({ selectedCard: card, cards }, () => this.fetchAllPendingData({ accountId: card.accountId, selectedCard: card, pagination: { pageSize: 10, current: 1 } }))
        this.fetchActivity({
            accountId: card.accountId,
            selectedCard: card,
            pagination: { pageSize: 10, current: 1 }
        })
    }

    handleViewCard = (card) => {
        this.setState({ viewCardLoading: true })
        apiPOSTReq(`${environment.bpBaseUrl}/bp/cards/getCardSensitiveInfo`, {}, { cardId: card.cardId, }, (err, resp) => {
            this.setState({ viewCardLoading: false })
            try {
                const data = resp || {}
                if (data.result) {
                    this.setState({ selectedCard: card, bbPersonCardSensitiveInfo: data.bbPersonCardSensitiveInfo, showViewCardModal: true })
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })

            }
        })
    }

    activateCard = (selectedCard) => {
        if (["VirualPan", "VirtualPan"].includes(selectedCard.paymentInstrument)) {
            this.setState({ [`activateLoading${selectedCard.last4}`]: true, activateLoading: true })

            apiPOSTReq(`${environment.bbBaseUrl}/bb/card/activateCard`, { "BankProvider": environment.bankProvider.crb }, { bbPersonCard: selectedCard }, (err, resp) => {
                try {
                    const data = resp || {}
                    if (data.result) {
                        // this.fetchCards()
                        selectedCard.status = 'Active'
                        message.success('Card activated!')
                        this.setState({ selectedCard })
                    } else {
                        throw Error(data.responseMessage || data.error)
                    }
                } catch (error) {
                    console.log("/activateCard err", error, resp)
                    ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
                } finally {
                    this.setState({ activateLoading: false })
                }
            })
        } else {
            this.setState({ selectedCard, showValidateCardModal: true })
        }
    }

    fetchSpendAnalysisData = async (body) => {
        return new Promise((resolve, reject) => {
            apiPOSTReq(`${environment.bbBaseUrl}/bb/cardTxnsSpendAnalysis`, {}, body, (err, resp) => {
                if (err || !resp || !resp.result) {
                    reject(err || resp.responseMessage || resp.error);
                    return;
                }
                console.log('fetchSpendAnalysisData fetching data for:', body, resp);
                resolve(resp);
            });
        });
    };

    fetchSpendAnalysisDataSafe = (body) => {
        return this.fetchSpendAnalysisData(body).catch((err) => {
            console.error('Error fetching data for:', body, err);
            return null;  // or some default/fallback data structure
        });
    }

    getCardTxnsSpendAnalysis = () => {
        this.setState({ chartsLoading: true })

        const body = {
            fromDate: moment().subtract(1, 'years').format("YYYY-MM-DD"),
            toDate: moment().format('YYYY-MM-DD'),
        }

        const currentMonthBody = {
            fromDate: moment().startOf('month').format("YYYY-MM-DD"),
            toDate: moment().format('YYYY-MM-DD'),
        }

        const lastMonthBody = {
            fromDate: moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD"),
            toDate: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
        }

        const yearToDateBody = {
            fromDate: moment().startOf('year').format("YYYY-MM-DD"),
            toDate: moment().format('YYYY-MM-DD'),
        }

        Promise.all([
            this.fetchSpendAnalysisDataSafe(body),
            this.fetchSpendAnalysisDataSafe(currentMonthBody),
            this.fetchSpendAnalysisDataSafe(lastMonthBody),
            this.fetchSpendAnalysisDataSafe(yearToDateBody)
        ])
            .then(([lastYearData, currentMonthData, lastMonthData, yearToDateData]) => {

                this.setState({
                    chartsLoading: false,

                    lastYearCategorySpend: lastYearData ? lastYearData.cardCategorySpendListMap : null,
                    lastYearMonthlySpend: lastYearData ? lastYearData.monthlySpendMap : null,

                    currentMonthCategorySpend: currentMonthData ? currentMonthData.cardCategorySpendListMap : null,
                    // currentMonthMonthlySpend: currentMonthData ? currentMonthData.monthlySpendMap : null,

                    lastMonthCategorySpend: lastMonthData ? lastMonthData.cardCategorySpendListMap : null,
                    // lastMonthMonthlySpend: lastMonthData ? lastMonthData.monthlySpendMap : null,

                    yearToDateCategorySpend: yearToDateData ? yearToDateData.cardCategorySpendListMap : null,
                    // yearToDateMonthlySpend: yearToDateData ? yearToDateData.monthlySpendMap : null,
                });
            });
    }

    downloadCSVStmt = () => {
        this.setState({ csvDownloading: true })
        var { selectedCard, searchStr, fromDate, toDate, fromAmt, toAmt } = this.state
        var query = {
            accountNumber: selectedCard.accountNumber,
            lastFour: selectedCard.last4,
            sortDirection: "DESC"
        }
        if (searchStr) query.searchStr = searchStr
        if (fromDate) query.fromDate = fromDate
        if (toDate) query.toDate = toDate
        if (fromAmt) query.fromAmt = fromAmt
        if (toAmt) query.toAmt = toAmt
        apiGETDocUrl(`${environment.bbBaseUrl}/bb/export/csv/cardTxns`, {}, query, (err, resp) => {
            this.setState({ csvDownloading: false })
            const data = (resp || {}).data;
            try {
                if (err) throw Error("We had trouble downloading your statement. Please try again.");
                const blob = new Blob([data], {
                    type: 'application/vnd.ms-excel',
                });
                const fileURL = URL.createObjectURL(blob);
                saveAs(fileURL, `Card activity - ${selectedCard.last4}.csv`);
            } catch (error) {
                ErrorAlert({ description: error.message });
            }
        })
    }

    render() {
        const { theme, aionStore, view } = this.props

        var { showSetPinModal, pin, pinError, pinLoading, confirmPin, confirmPinError, showViewCardModal, showValidateCardModal, monthlySpendMap,
            cards, bbPersonCardSensitiveInfo, activateLoading, loadingPosted, loadingPending, postedTransfers, activeCard, loading, moreToLoad,
            cardCategorySpendListMap, pendingTransfers, pendingSums, selectedCard,
            lastYearMonthlySpend, currentMonthCategorySpend, lastMonthCategorySpend, yearToDateCategorySpend, selectedCard, lastYearCategorySpend, chartsLoading,
            searchTerm, csvDownloading
        } = this.state

        return (
            <FlexColumn className={!view && 'main-top-padding'} style={{ width: '100%', margin: 0, paddingLeft: 0 }} start fullWidth>
                {
                    !view &&
                    <PageHeader
                        titleText='Cards'
                        subtitle='Cards'
                        marginRight='36px'
                        marginLeft='36px'
                    // ctaContent={}
                    />
                }

                <FlexColumn start fullWidth>
                    {
                        activeCard ?
                            <>
                                {
                                    false && !view &&
                                    <Flex start gap='24px' fullWidth style={{ overflow: 'scroll', overflowY: 'hidden', overflowX: 'hidden', paddingBottom: 12, marginBottom: 12 }}>
                                        <Skeleton loading={loading} active title={false} paragraph={{ rows: 3 }}>
                                            {
                                                cards.map((card, index) => {
                                                    // if (card.orderStatus !== "OrderPending") {
                                                    return <CardSelector
                                                        id={card.cardId}
                                                        key={card.cardId}
                                                        debitCard={card}
                                                        onUpdateCard={(selectedCard) => {
                                                            this.setState({ selectedCard })
                                                        }}
                                                        onActivateCard={(selectedCard) => this.activateCard(selectedCard)}
                                                        onClick={(card) => this.handleSelectCard(card)}
                                                    />
                                                    // }
                                                })
                                            }
                                        </Skeleton>

                                    </Flex>
                                }

                                {
                                    !view &&
                                    <CardSelectorList
                                        cards={cards}
                                        onUpdateCard={(selectedCard) => {
                                            this.setState({ selectedCard })
                                        }}
                                        onActivateCard={(selectedCard) => this.activateCard(selectedCard)}
                                        onClick={(card) => this.handleSelectCard(card)}
                                    />
                                }

                                {
                                    true && // (monthlySpendMap || cardCategorySpendListMap) &&
                                    <Flex start className="cards-container" centerHorizontally style={{ marginBottom: 24, marginLeft: view ? 0 : 36 }}>
                                        <div className="debit-card-container">
                                            <DebitCard
                                                view={view}
                                                debitCard={selectedCard}
                                                setDebitCard={(debitCard) => this.setState({ selectedCard: debitCard })}
                                                setPin={() => this.setState({ showSetPinModal: true })}
                                                onViewCard={() => this.handleViewCard(selectedCard)}
                                                activateCard={() => this.activateCard(selectedCard)}
                                            />
                                        </div>

                                        <Flex between className='cards-charts-back' centerHorizontally>
                                            <Skeleton loading={chartsLoading} active title={false} paragraph={{ rows: 7 }}>
                                                {
                                                    ((selectedCard.status && selectedCard.status !== 'Unactivated') &&
                                                        ((lastYearMonthlySpend || {})[selectedCard.last4] || (currentMonthCategorySpend || {})[selectedCard.last4] || (lastMonthCategorySpend || {})[selectedCard.last4] || (yearToDateCategorySpend || {})[selectedCard.last4])) ?
                                                        <>
                                                            {
                                                                lastYearMonthlySpend &&
                                                                <MonthlySpendBarChart data={lastYearMonthlySpend[selectedCard.last4]} />
                                                            }

                                                            <div style={{ height: 270, width: 1, background: '#F2F2F2', margin: '0 12px' }} />

                                                            {
                                                                selectedCard && (currentMonthCategorySpend || lastMonthCategorySpend || yearToDateCategorySpend) &&
                                                                <SpendByCategory
                                                                    last4={selectedCard.last4}
                                                                    currentMonthCategorySpend={currentMonthCategorySpend}
                                                                    lastMonthCategorySpend={lastMonthCategorySpend}
                                                                    yearToDateCategorySpend={yearToDateCategorySpend}
                                                                />
                                                            }
                                                        </>
                                                        :
                                                        <FlexColumn center style={{ width: 800, height: 170, gap: 0, paddingTop: 28 }}>
                                                            <Text heading>No posted transactions</Text>
                                                            <Text center>Your spend charts will load once you have card activity on your account</Text>
                                                        </FlexColumn>
                                                }
                                            </Skeleton>
                                        </Flex>
                                    </Flex>
                                }

                                {
                                    selectedCard.status === 'Unactivated' ?
                                        <>
                                            {
                                                selectedCard.orderStatus !== 'OrderPending' &&
                                                <Flex start fullWidth>
                                                    <CardContainer fullWidth style={{ margin: '0 24px 0 36px' }}>
                                                        <FlexColumn fullWidth>
                                                            <Flex between fullWidth>
                                                                <Text heading>Activate your card!</Text>
                                                            </Flex>
                                                            <Text>Before you can use it, click Activate Now to get started.</Text>
                                                            <TextButton text='ACTIVATE NOW' rightIcon={<Image src={ArrowRight} />} loading={activateLoading} margin='24px 0 0' onClick={() => this.activateCard(selectedCard)} />
                                                        </FlexColumn>
                                                    </CardContainer>
                                                </Flex>
                                            }
                                        </>
                                        :
                                        <FlexColumn style={{ margin: view ? '0 24px 24px 0' : '0 24px 24px 36px' }} gap='24px'>
                                            {
                                                !loadingPending && pendingTransfers.length > 0 &&
                                                <PendingTable
                                                    title='Pending'
                                                    subtitle={<>These transactions have not been finalized yet so they are not included in your current balance. They are reflected in your available balance but please note that the final transaction amount may be different from this pending amount. Some merchants (such as hotels, restaurants, gas stations, car rental agencies, etc.) will put a hold on your card before finalizing the amount.</>}
                                                    hideType
                                                    hideCardInfo
                                                    hideStatus
                                                    // onCancelled={() => fetchAllData(accounts[selectedAccount])}
                                                    loadingPending={loadingPending}
                                                    pendingTransfers={pendingTransfers}
                                                    pendingSums={pendingSums}
                                                    tooltip={<FlexColumn start left>
                                                        <Text color='white' size='14px'>All transactions shown are based on ET regardless of the time zone in which they were initiated.</Text>
                                                        <Text color='white' size='14px'>For some debit card transactions, the amount you see listed may not be the final amount deducted<br />from your account, for example, it may initially not include a tip you added to a restaurant bill.</Text>
                                                    </FlexColumn>}
                                                    padding='12px 24px'
                                                />
                                            }

                                            <ActivityTable
                                                key={selectedCard.last4}
                                                filter
                                                customFilterComp={
                                                    <Flex gap="8px" center>
                                                        <ImageButton src={Download} onClick={() => this.downloadCSVStmt()} />
                                                        {csvDownloading && <LoadingOutlined style={{ fontSize: 18 }} spin />}
                                                    </Flex>
                                                }
                                                hideType
                                                title='Card Activity'
                                                hideCardInfo
                                                fetchTransactions={this.fetchActivity}
                                                data={postedTransfers}
                                                setSearchTerm={(txt) => this.setState({ searchTerm: txt })}
                                                sharedSearchTerm={searchTerm}
                                                rail="CardActivity"
                                                searchPlaceholder="Search by Merchant & Location, Reference Number"
                                                searchWidth={'370px'}
                                                padding='4px 24px 24px 24px'
                                                moreToLoad={moreToLoad}
                                                onLoadMore={() => this.loadMorePostedTransactions()}
                                                loading={loadingPosted}
                                                rowKey={'objectId'}
                                            />
                                        </FlexColumn>
                                }
                            </>
                            :
                            <Flex start style={{ margin: '0 0 0 36px', paddingRight: 24 }}>
                                <Skeleton loading={loading} active title={false} paragraph={{ rows: 3 }}>
                                    <CardContainer height='216px' width='375px' padding='0 24px 0 24px' margin='0'>
                                        <Flex start fullWidth fullHeight>
                                            <FlexColumn center fullHeight fullWidth style={{ padding: '24px 0 32px' }}>
                                                <Text width='200px'>No cards issued to you. Request your company admin to issue a debit card.</Text>
                                            </FlexColumn>
                                            <img
                                                style={{ marginLeft: 30 }}
                                                src={CardImg}
                                                height="216px"
                                                alt="Aion Debit Card"
                                            />
                                        </Flex>
                                    </CardContainer>
                                </Skeleton>
                            </Flex>

                    }

                </FlexColumn>

                <Modal
                    visible={showSetPinModal}
                    footer={null}
                    closable={true}
                    width={300}
                    destroyOnClose={true}
                    onCancel={() => {
                        this.setState({ showSetPinModal: false })
                    }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <FlexColumn left>
                        <Text heading margin='0 0 12px'>Set PIN</Text>
                        {/* <Text style={{ marginBottom: 24, marginTop: 8 }}>Enter a new 4 digit PIN for this card</Text> */}
                        <LabeledInput
                            type='password'
                            id='debit-card-pin'
                            key='debit-card-pin'
                            label='Enter a new 4 digit PIN for this card'
                            placeholder='Enter PIN'
                            margin='0 0 12px'
                            // bordered={false}
                            value={pin}
                            // iconRender={visible => (visible ? <Tooltip title="Hide key"><EyeTwoTone style={{ fontSize: iconSize }} onClick={this.togglePWVisibility} /></Tooltip> : <Tooltip title="View key"><EyeInvisibleOutlined style={{ fontSize: iconSize }} onClick={this.togglePWVisibility} /></Tooltip>)}
                            size={'large'}
                            maxLength={4}
                            onChange={e => { this.setState({ pin: e.target.value }) }}
                            error={pinError}
                            errorMessage={'Please enter a valid 4 digit PIN number'}
                        />
                        <LabeledInput
                            type='password'
                            id='confirm-card-pin'
                            key='confirm-card-pin'
                            label='Confirm PIN'
                            placeholder='Confirm PIN'
                            // bordered={false}
                            value={confirmPin}
                            // iconRender={visible => (visible ? <Tooltip title="Hide key"><EyeTwoTone style={{ fontSize: iconSize }} onClick={this.togglePWVisibility} /></Tooltip> : <Tooltip title="View key"><EyeInvisibleOutlined style={{ fontSize: iconSize }} onClick={this.togglePWVisibility} /></Tooltip>)}
                            size={'large'}
                            maxLength={4}
                            onChange={e => { this.setState({ confirmPin: e.target.value }) }}
                            error={confirmPinError}
                            errorMessage={"PIN & confirmation PIN don't match"}
                        />
                        <Button
                            solid
                            height='40px'
                            radius='4px'
                            width='120px'
                            text='Set PIN'
                            onClick={() => this.setPin(selectedCard)}
                            loading={pinLoading} />
                    </FlexColumn>
                </Modal>

                <Modal
                    visible={showValidateCardModal}
                    footer={null}
                    closable={true}
                    width={350}
                    destroyOnClose={true}
                    onCancel={() => {
                        this.setState({ showValidateCardModal: false })
                    }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <ActivateWithPin selectedCard={selectedCard} onActivated={() => {
                        this.fetchCards()
                        this.setState({ showValidateCardModal: false })
                    }} />
                </Modal>

                <Modal
                    visible={showViewCardModal}
                    footer={null}
                    closable={true}
                    width={600}
                    destroyOnClose={true}
                    onCancel={() => {
                        this.setState({ showViewCardModal: false })
                    }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <ViewCard selectedCard={selectedCard} bbPersonCardSensitiveInfo={bbPersonCardSensitiveInfo} />
                </Modal>
            </FlexColumn >
        )
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer,
        bankingStore: state.bankingAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)))