import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'
import { saveAs } from 'file-saver'
import moment from 'moment'
import { Select, Table, Upload, message, Skeleton, Tooltip, Input } from 'antd'

import { Flex, FlexColumn, CardContainer } from '../../Reusable/Container'
import { LabeledInput, ErrorLabel } from '../../Reusable/Input'
import { Text, Tag } from '../../Reusable/Refresh/Text'
import { ErrorAlert } from '../../Reusable/Alert'
import { TextButton, Button } from '../../Reusable/Button'
import { apiPOSTReq, getPreSignedS3Url } from '../../../Utils/api'
import environment from '../../../environment';
import Message from '../../../Images/message.png'
import RejectedIcon from '../../../Images/rejected.png'

import Info from '../../../Images/info-square.png'
import UploadIcon from '../../../Images/upload.png'
import ClockIcon from '../../../Images/clock-icon.png'
import ThumbsUp from '../../../Images/thumbs-up.png';
import Vector7 from '../../../Images/vector-7.png'
import Asterisk from '../../../Images/asterisk.png'
import { Image } from '../../Reusable/Image';
import { CloseOutlined, FilePdfOutlined, DeleteOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { split } from 'lodash'

const { Option } = Select
export const dateFormatList = ['MM/DD/YYYY', 'MM/DD/YY', 'MM-DD-YYYY', 'MM-DD-YY']
const etTimeZone = "America/New_York";
const { TextArea } = Input

class AdditionalDocument extends Component {

    state = {
        haveActiveLoanAppRequest: false,
        uploadList: [],
        notesList: [],
        uploadLoading: false
    }

    componentDidMount() {
        // Fetch on refresh page
        this.fetchApplicationRequest();

    }

    fetchApplicationRequest = () => {
        this.setState({ loading: true })
        const urlStr = this.props.location.search.replace('?', '')
        const ref = (new URLSearchParams(urlStr).get('ref') || '')

        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/getLoanRequest`, {}, { referenceNumber: ref }, (err, resp) => {
            try {
                const response = resp || {};
                if (response.result) {
                    const { request } = response
                    this.setState({ loanApplicationRequest: request })
                    if (request != null) {
                        this.setState({ haveActiveLoanAppRequest: true })
                        this.fetchDocuments(request);
                        this.fetchLoanApp(request);
                    }
                }
            }
            catch (error) {
                console.log("ERRR fetching contract", error, err, resp);
            }

        })
        this.setState({ loading: false })

    }

    saveUserInput = (id, dataToSave) => {
        var { loanApplicationRequest } = this.state;
        loanApplicationRequest[id] = dataToSave.target.value;

        this.setState({ loanApplicationRequest: loanApplicationRequest });
    }

    setErrorField = (id, msg) => this.setState({ errorField: id, errorMessage: msg });

    validateFlow = () => {
        var { loanApplicationRequest } = this.state;

        if (loanApplicationRequest.submitterName == null || loanApplicationRequest.submitterName == "") {
            this.setErrorField("name", "Please enter name.");
            return;
        }
        if (loanApplicationRequest.submitterEmail == null || loanApplicationRequest.submitterEmail == "") {
            this.setErrorField("email", "Please enter email.");
            return;
        }

        this.setErrorField("", "");
        return true
    }

    fetchLoanApp = (loanApplicationRequest) => {
        // Fetch document list
        var body = {
            "clientBusinessId": loanApplicationRequest.businessId,
            "size": 100,
            "page": 0,
            // "sortDirection": "ASC"
        }
        this.setState({ loanAppLoading: true });
        apiPOSTReq(`${environment.opsBaseUrl}/ops/loans/application/getApplications`, {}, body, (err, resp) => {
            console.log("/getApplications resp body", resp, body);
            // this.setState({ loanAppLoading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    var loanApplications = data.loanApplications || [];
                    var loanApp = loanApplications.length > 0 ? loanApplications[loanApplications.length - 1] : {};
                    this.setState({ loanApplication: loanApp });
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        })
    }
    fetchDocuments = (loanApplicationRequest) => {
        const { loanApplication } = this.props;
        var body = {
            "sortDirection": "DESC"
        };
        body["applicationId"] = loanApplicationRequest.applicationId;
        body["viewBy"] = "FILTER_APPLICATION_ID"

        this.setState({ qbConnectLoading: true });
        apiPOSTReq(`${environment.uamBaseUrl}/docs/getActiveDocuments`, {}, body, (err, resp) => {
            console.log("/docs/getActiveDocuments resp", resp);
            this.setState({ qbConnectLoading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    this.setState({ businessDocuments: data.businessDocuments });
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }
    uploadProps = (doc, loanApplicationRequest) => {
        return {
            name: `business-docs`,
            action: `${environment.apiBaseUrl}/financing/upload`,
            headers: {
                businesskey: (this.state.businessDocuments || []).length > 0 ? this.state.businessDocuments[0].businessId : "",
                jwt: this.props.aionStore.jwt
            },
            beforeUpload: (doc) => {

                var maxFileSize = 10240; // 10MB

                const uploadedFileSizeKiloBytes = doc.size / 1024

                if (uploadedFileSizeKiloBytes > maxFileSize) {
                    message.error(`File size is greater than 10MB`);
                    this.setState({ uploadLoading: false });

                    return false;
                }
            },
            multiple: true,
            onChange: (info) => {
                this.setState({ uploadLoading: true });

                var { file } = info;
                var savedFile = {};
                const { status, response, name, url } = file;
                if (status === "done") {
                    savedFile = {
                        fileName: name,
                        uri: (response.SavedDocumentUrls || [])[0],
                    }
                    if (doc.type) savedFile.type = doc.type;
                    // message.success(`${info.file.name} file uploaded successfully.`, 0.75);
                    doc.savedFile = savedFile;

                    this.handleDocSubmit(doc, loanApplicationRequest);
                } else if (status === 'error') {
                    this.setState({ uploadLoading: false });

                    message.error(`${info.file.name} file upload failed.`);
                }
                if (!status) {
                    this.setState({ uploadLoading: false });
                }
                if (((Object.keys(savedFile)).length) !== 0) {
                    doc.savedFile = savedFile;
                }
                this.setState({ [doc.name]: doc });

            },
            showUploadList: loanApplicationRequest ? !loanApplicationRequest.allowMultipleUpload : false,
            accept: ".jpeg,.jpg,.csv,.png,.doc,.xls,.xlsx,.pdf,.docx,.pptx,.txt",
            onRemove: (file) => {
                this.handleDeleteDoc(doc);
            }
        }
    };

    saveDoc = (loanApplicationRequest, note) => {
        var selecteddoc = (this.state.businessDocuments || []).filter(doc => doc.id == note.docId)
        selecteddoc[0].notes = note.notes;


        var body = {
            businessDocument: selecteddoc[0]
        }

        console.log("handleSubmit DOC/SAVE uploadItem body", body);
        this.setState({ submitLoading: true, })
        apiPOSTReq(`${environment.uamBaseUrl}/docs/save`, {}, body, (err, resp) => {
            this.setState({ submitLoading: false });
            try {
                const data = resp || {};

                if (data.result) {
                    this.fetchDocuments(loanApplicationRequest);

                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/docs/save err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }
    sendCreditUserEmail = (loanApplicationRequest) => {
        var body = {
            businessId: loanApplicationRequest.businessId,
            applicationId: loanApplicationRequest.applicationId,
            request: loanApplicationRequest
        }
        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/sendAdditionalUploadEmail`, {}, body, (err, resp) => {
            this.setState({ submitLoading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    console.log("Email send Successfully!")
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/lsm/sendAdditionalUploadEmail err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    handleDocSubmit = (uploadItem, loanApplicationRequest) => {
        var { loadingItem } = uploadItem || {}
        var { loanApplication, onboardingStore } = this.props
        var businessDocument = {
            "name": uploadItem.savedFile.fileName,
            "documentUrl": uploadItem.savedFile || {},
            "subType": 'AdditionalDocument',
            "type": "Document",
            "source": 'User',
            "category": "Custom Upload",
            "RequestId": loanApplicationRequest.requestId
        };
        businessDocument.applicationId = loanApplicationRequest.applicationId;
        businessDocument.businessId = loanApplicationRequest.businessId;
        var body = {
            businessDocument: businessDocument
        }

        console.log("handleSubmit DOC/SAVE uploadItem body", body);
        apiPOSTReq(`${environment.uamBaseUrl}/docs/save`, {}, body, (err, resp) => {
            this.setState({ uploadLoading: false });
            try {
                const data = resp || {};
                var uploadList = this.state.uploadList;
                var existingUploadList = uploadList.filter(list => list.requestId === loanApplicationRequest.requestId);

                var acitveUploadList = existingUploadList.length > 0 ? existingUploadList : [{
                    "requestId": loanApplicationRequest.requestId,
                    "docs": []
                }];

                if (data.result) {
                    message.success(`File saved successfully.`, 0.75);
                    this.fetchDocuments(loanApplicationRequest);

                    var activeUpload = acitveUploadList[0];
                    var uploadedDocs = activeUpload.docs || [];
                    uploadedDocs.push(data.businessDocument);

                    activeUpload.docs = uploadedDocs;

                    var index = uploadList.findIndex(list => list.requestId === loanApplicationRequest.requestId) || [];
                    if (index >= 0)
                        uploadList[index] = activeUpload;
                    else
                        uploadList.push(activeUpload)
                    this.setState({ uploadList: uploadList })


                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/docs/save err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    downloadStatement(uri, name) {
        return new Promise((resolve, reject) => {
            getPreSignedS3Url({ url: uri }, (err, preSignedUrl) => {
                if (!err) {
                    saveAs(preSignedUrl, name);
                } else {
                    reject(JSON.stringify(err))
                }
            })
        })
    }

    handleDeleteDoc = (uploadItem, request) => {
        var businessDocument = {
            "category": "Custom Upload",
            "name": uploadItem.name,
        };

        businessDocument.applicationId = request.applicationId;
        var body = {
            "businessDocument": businessDocument,
            "documentId": uploadItem.documentId
        }

        console.log("handleSubmit DOC/delete uploadItem body", body);
        // this.setState({ submitLoading: true });
        apiPOSTReq(`${environment.uamBaseUrl}/docs/delete`, {}, body, (err, resp) => {
            this.setState({ submitLoading: false, loadingItem: null });
            try {
                const data = resp || {};
                if (data.result) {

                    this.fetchDocuments(request)
                    this.removeNotes(uploadItem.id);
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/docs/deleteUpload err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    saveRowUserInput = (id, key, dataToSave) => {
        var { notesList } = this.state;

        var updatedlist = [];
        if (notesList.length > 0) {
            var isExist = false;
            updatedlist = notesList.map(note => {
                if (note.docId == id) {
                    isExist = true;
                    note["notes"] = dataToSave;
                    return note;
                } return note;
            })
            if (!isExist) {
                updatedlist.push({
                    "docId": id,
                    "notes": dataToSave
                })
            }
        } else {
            updatedlist.push({
                "docId": id,
                "notes": dataToSave
            })
        }

        this.setState({ notesList: updatedlist });
    }

    removeNotes = (docId) => {
        const { notesList } = this.state;
        let removedList = notesList.filter(note => note.docId != docId);

        this.setState({ notesList: removedList });
    }

    updateRequestStatus = (loanApplicationRequest) => {
        var i = 0;
        if (this.state.notesList.length > 0) {
            this.state.notesList.map(note => {
                i = i + 1;
                this.saveDoc(loanApplicationRequest, note);
            })
        }
        if (i == this.state.notesList.length) {
            var { aionStore } = this.props;
            var { UserInfo } = aionStore;
            var businessId = (this.state.businessDocuments || []).length > 0 ? this.state.businessDocuments[0].businessId : "";

            var body = {
                businessId: businessId,
                applicationId: loanApplicationRequest.applicationId,
                request: loanApplicationRequest,
                loggedInUser: ""
            }

            console.log("handleSubmit update loan request body", body);
            this.setState({ submitLoading: true });
            apiPOSTReq(`${environment.lsmBaseUrl}/lsm/updateLoanRequest`, {}, body, (err, resp) => {
                this.setState({ submitLoading: false });
                try {
                    const data = resp || {};
                    if (data.result) {
                        this.fetchApplicationRequest();
                    } else {
                        throw Error(data.responseMessage || data.error)
                    }
                } catch (error) {
                    console.log("/lsm/updateLoanRequest err", error, resp);
                    ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
                }
            });
        }


    }
    getColumns = () => {
        var { loanApplicationRequest, notesList } = this.state;

        return [
            {
                title: <FlexColumn center ><Text color='#444444' weight='500'>File</Text></FlexColumn>,
                width: 208,
                render: (file) => {
                    var fileName = file.documentUrl.fileName;
                    var array = fileName.split('.');
                    var alteredName = fileName.length < 20 ? fileName :
                        `${fileName.substring(0, 26)}...${array[array.length - 1]}`;
                    return (
                        <div style={{ marginTop: "0px!important" }}>
                            {
                                fileName.length < 20 ?
                                    <TextButton
                                        breakWord preLine
                                        underLine
                                        noBorder height='32px' padding='4px 8px' radius='4px' background='#F8F8F8'
                                        style={{ textAlign: "left", }}
                                        text={alteredName}
                                        onClick={() => this.downloadStatement(file.documentUrl.uri, fileName)}
                                        icon={<FilePdfOutlined style={{ fontSize: 14, }} />}
                                    />
                                    :
                                    <Tooltip overlayInnerStyle={{ width: 330, minWidth: 330 }} overlayStyle={{ width: 350, minWidth: 350 }} overlayClassName='roles-tooltip' placement="top" arrow={true} title={<FlexColumn>
                                        <Text color='white' size='14px' wrap>{fileName}</Text>
                                    </FlexColumn>}>
                                        <TextButton
                                            breakWord preLine
                                            underLine
                                            noBorder height='32px' padding='4px 8px' radius='4px' background='#F8F8F8'
                                            style={{ textAlign: "left", }}
                                            text={alteredName}
                                            onClick={() => this.downloadStatement(file.documentUrl.uri, fileName)}
                                            icon={<FilePdfOutlined style={{ fontSize: 14, }} />}
                                        />
                                    </Tooltip>
                            }
                        </div>



                    )
                }
            },
            {
                title: <FlexColumn center><Text color='#444444' weight='500'>Note</Text></FlexColumn>,
                width: 300,
                render: (file) => {
                    var filtered = notesList.filter(note => note.docId === file.id);
                    var note = filtered.length > 0 ? filtered[0].notes : file.notes || "";
                    var notes = (note.split(/(.{30})/) || []).filter(note => note != "");
                    return (
                        <div>
                            <TextArea placeholder="Enter a message" rows={notes.length > 5 ? 6 : notes.length || 1} value={note} onChange={e => this.saveRowUserInput(file.id, "notes", e.target.value)} style={{ borderRadius: '8px', marginLeft: '12px' }} />

                        </div>


                    )
                }
            },
            {
                title: '',
                width: 48,
                render: (file) => {
                    return (
                        <TextButton deleteConfirmMessage={'Are you sure you want to delete this file?'} onClick={() => this.handleDeleteDoc(file, loanApplicationRequest)} icon={<DeleteOutlined style={{ color: "red" }} />} />

                    )
                }
            },
        ]
    }
    render() {
        var { loanApplicationRequest, uploadList, notesList, errorField, errorMessage } = this.state;

        var icon = <></>
        var title = ''
        var desc = <Text center margin='0 12px 24px' width={'576px'}>If we have any questions, we will reach out to you at <a>{this.state.loanApplication ? this.state.loanApplication.emailAddress : ""}</a></Text>
        var uploads = [];
        if (loanApplicationRequest) {
            uploads = (this.state.businessDocuments || []).filter(doc => doc.requestId === loanApplicationRequest.requestId);
            switch (loanApplicationRequest.status) {
                case "InReview":
                    icon = ClockIcon;
                    title = "Your information is being reviewed";
                    break;
                case "Cancelled":
                    icon = ClockIcon;
                    title = "Your information is being cancelled";
                    break;
                case "Approved":
                    icon = ClockIcon;
                    title = "Your information is being reviewed";
                    break;
                case "Rejected":
                    icon = RejectedIcon;
                    title = "Your information is being rejected";
                    break;
                default:
                    break;
            }
        }

        return (
            <>
                <FlexColumn >
                    {
                        <>
                            <img className='vector-7-onb' src={Vector7} />
                        </>
                    }
                    <FlexColumn start fullWidth fullHeight style={{ position: 'relative' }}>
                        <Skeleton loading={""} active title={false} paragraph={{ rows: 3 }}>
                            {(loanApplicationRequest && loanApplicationRequest.status == "InProgress") &&
                                <>
                                    <FlexColumn start centerHorizontally style={{ marginTop: "10%" }}>
                                        <CardContainer style={{ width: '624px', zIndex: 5, }}>
                                            <FlexColumn center gap='24px' style={{ marginTop: "" }}>
                                                <img height='48px' width='48px' src={Info} />
                                                <Text weight="500" size='28px' height='40px' style={{ textAlign: 'center', maxWidth: '400px' }}>Additional information needed</Text>
                                                <Text center margin='0 12px 0 24px' width={'576px'}>Upload documents and add any notes to help us review your information better.</Text>

                                                {
                                                    uploads.length > 0 &&
                                                    <>
                                                        <Flex style={{ width: '576px' }} gap="12px">
                                                            <FlexColumn style={{ width: "35%" }}>
                                                                <Text margin='6px 12px 0 82px' weight="500" width={'576px'}>
                                                                    Name <Image width='5px' height='5px' margin='-10px 0 0' src={Asterisk} />
                                                                </Text>
                                                            </FlexColumn>
                                                            <FlexColumn style={{ width: "47%" }}>
                                                                <TextArea placeholder="Enter your name" rows={1} value={loanApplicationRequest.submitterName}
                                                                    onChange={value => { this.saveUserInput("submitterName", value) }}
                                                                    style={{ borderRadius: '8px' }}
                                                                    key={"name"}
                                                                    id="name"
                                                                />
                                                                {errorField == "name" &&
                                                                    <ErrorLabel>{errorMessage}</ErrorLabel>
                                                                }
                                                            </FlexColumn>
                                                            <FlexColumn style={{ width: "5%" }}> </FlexColumn>

                                                        </Flex>
                                                        <Flex style={{ width: '576px' }}>
                                                            <FlexColumn style={{ width: "35%" }}>
                                                                <Text margin='6px 12px 0 80px' weight="500" width={'576px'}>
                                                                    Email <Image width='5px' height='5px' margin='-10px 0 0' src={Asterisk} />
                                                                </Text>
                                                            </FlexColumn><FlexColumn style={{ width: "47%" }}>

                                                                <TextArea placeholder="mail@mail.com" rows={1} value={loanApplicationRequest.submitterEmail}
                                                                    onChange={value => { this.saveUserInput("submitterEmail", value) }}
                                                                    style={{ borderRadius: '8px' }}
                                                                    key={"email"}
                                                                    id="email"
                                                                />
                                                                {errorField == "email" &&
                                                                    <ErrorLabel>{errorMessage}</ErrorLabel>
                                                                }
                                                            </FlexColumn>
                                                            <FlexColumn style={{ width: "5%" }}> </FlexColumn>


                                                        </Flex>
                                                    </>
                                                }
                                                {uploads.length > 0 ?
                                                    <Table
                                                        id="todo-table"
                                                        key="todo-table"
                                                        className='borderless-table'
                                                        tableLayout='auto'
                                                        columns={this.getColumns()}
                                                        dataSource={uploads || []}
                                                        rowKey='objectId'
                                                        pagination={false}
                                                        scroll={{ x: '75%' }}
                                                    /> : null}

                                                <Flex center nomargin>
                                                    <LabeledInput
                                                        loading={this.state.uploadLoading}
                                                        {...this.uploadProps({ name: "Additional Document" }, loanApplicationRequest)}
                                                        type='upload'
                                                        allowPadding={uploads.length > 0 ? false : true}
                                                        uploadText='We only allow files under 10 M.B.'
                                                        labelText={uploads.length > 0 ? "UPLOAD ANOTHER FILE" : "UPLOAD"}
                                                    />

                                                </Flex>
                                                {uploads.length > 0 ?
                                                    <Button type="primary" loading={this.state.submitLoading} solid style={{ alignSelf: 'center', margin: '-12px  12px 12px 10px' }}
                                                        onClick={() => {
                                                            var valid = this.validateFlow();
                                                            if (valid) {
                                                                loanApplicationRequest.status = "Approved"
                                                                this.updateRequestStatus(loanApplicationRequest);
                                                                this.sendCreditUserEmail(loanApplicationRequest)
                                                            }
                                                        }} text="Submit" />
                                                    : null}
                                            </FlexColumn>
                                        </CardContainer>
                                    </FlexColumn>
                                    <FlexColumn center style={{ marginBottom: "96px" }}>
                                        <CardContainer style={{ width: '624px', marginTop: 150, marginBottom: 24, zIndex: 5, textAlign: 'center' }}>
                                            <Text center>Questions? Reach out to your Aion representative that you have been working with, or send out an email to hello@aionfi.com</Text>
                                            {/*
                                            <Flex between style={{ marginTop: 12, width: '100%' }}>
                                                <Flex start>
                                                     <img width='48px' height='48px' src={Profile1} /> 
                                                    <FlexColumn between style={{ height: 48 }}>
                                                        <TextButton text="CONTACT US" icon={<img height='24px' width='24px' src={Message} />} />


                                                    </FlexColumn>
                                                    
                                                </Flex>
                                            </Flex>
                                            */}
                                        </CardContainer>
                                    </FlexColumn></>
                            }
                            {
                                (loanApplicationRequest && loanApplicationRequest.status != "InProgress") &&
                                <FlexColumn start centerHorizontally style={{ marginTop: "15%" }}>
                                    <FlexColumn center gap='24px' style={{ marginTop: "" }}>
                                        <img height='48px' width='48px' src={icon} />
                                        <Text weight="500" size='28px' height='40px' style={{ textAlign: 'center', maxWidth: '400px' }}>{title}</Text>
                                        <div style={{ width: '80px', height: '1px', background: 'radial-gradient(83.75% 38.54% at 16.25% 86.88%, #1199FF 0%, #1A8AFA 100%)' }} />

                                        {desc}

                                    </FlexColumn>
                                </FlexColumn>
                            }

                        </Skeleton>
                    </FlexColumn>
                    <FlexColumn between left fullHeight style={{ marginTop: "0px", }} gap='24px'>



                    </FlexColumn>
                </FlexColumn>

            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        onboardingStore: state.onboardingAppReducer,
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(AdditionalDocument))