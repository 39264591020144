import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';
import {
    Button,
    Input,
    Space,
    Modal,
    Collapse,
    Upload,
    message
} from 'antd';
import { saveAs } from 'file-saver';

// Components
import moment from 'moment';
import { apiPOSTReq, getPreSignedS3Url } from '../../../../Utils/api';
import environment from '../../../../environment';
import { ErrorAlert } from '../../../Reusable/Alert';
import { Flex, FlexColumn, CardContainer } from '../../../Reusable/Container';
import { DownloadOutlined, PaperClipOutlined, PlusOutlined, FilePdfOutlined, CloseOutlined } from '@ant-design/icons';
import { Button as RefreshButton } from '../../../Reusable/Button';
import { TextButton } from '../../../Reusable/Refresh/Button';
import UploadFiles from '../../../Reusable/UploadFiles';
import { SignedLink } from '../../../Reusable/Link';

const { TextArea } = Input;
const { Panel } = Collapse;

const Message = (props) => {

    return <>
        <span style={{ marginBottom: '5pt', color: props.theme.colors.secondary4 }}>{props.sender && props.sender.userOrGroupName}</span>
        <FlexColumn style={{ backgroundColor: '#fafafa', marginBottom: '30px', borderRadius: '8px' }}>
            <FlexColumn style={{ padding: '10px 15px 0px' }}>
                <Flex gap="20px" style={{ justifyContent: 'space-between', fontWeight: 400, marginBottom: '10px' }}>
                    <div style={{ overflowWrap: "break-word", whiteSpace: 'pre-line', wordBreak: "break-all" }}>{props.message && props.message.body}</div>
                    <div>
                        <span style={{ fontWeight: 400, fontStyle: 'italic' }}>{props.creationDate && moment(props.creationDate).format("MM/DD/YYYY hh:mm a")}</span>
                    </div>
                </Flex>

                {/* <div>{props.message && props.message.body}</div> */}
            </FlexColumn>
            {props.message && props.message.attachmentUrls.length > 0 &&
                <Space style={{ borderTop: '1px solid #fafafa' }}>
                    <Collapse bordered={false} ghost>
                        <Panel header={<div style={{ fontSize: 14 }}>Attachments</div>} key="1" >
                            {props.message && props.message.attachmentUrls.map((x, i) => {
                                return <><SignedLink {...x} showfilename={true} /><span> {i !== (props.message.attachmentUrls.length - 1) ? "," : null} </span></>
                            })}
                        </Panel>

                    </Collapse>

                </Space>
            }
        </FlexColumn>
    </>
}

class Index extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pagination: {
                current: 1,
                pageSize: 500
            },
            conversationItems: [],
            fileList: [],
            sumbitted: false,
            uploading: false
        };
    }

    componentDidMount() {
        this.fetchConversationList({ pagination: this.state.pagination });
    }

    fetchConversationList = (options) => {
        const { pagination } = options;
        const { loanApp } = this.props;

        const body = {
            "size": pagination.pageSize,
            "page": pagination.current - 1,
            "applicationId": loanApp.applicationId,
        }
        this.setState({ loading: true });

        // apiPOSTReq(`${environment.opsBaseUrl}/ops/getLoanApplicationMessages`, {}, body, (err, resp) => {
        apiPOSTReq(`${environment.opsBaseUrl}/ops/getLoanApplicationMessages`, null, body, (err, resp) => {
            this.setState({ loading: false });
            try {
                const data = resp;
                // console.log("/getLoanApplicationMessages", data)
                if (data.result) {
                    this.setState({
                        conversationItems: data.conversationItems,
                        pagination: {
                            ...pagination,
                            total: data.count
                        }
                    })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getLoanApplicationMessages", error, err, resp);
                this.setState({ loading: false })
                ErrorAlert({ description: error.message })
            }
        })
    }

    createNewMessage = () => {
        const { loanApp, store } = this.props;
        const { UAM, UserInfo } = store;

        const body = {
            applicationId: loanApp.applicationId,
            message: {
                // subject: this.state.subject,
                body: this.state.body,
                attachmentUrls: this.state.fileList.map(x => { return { fileName: x.fileName, url: x.uri } })
            },
            underwriter: UAM.aionUnderWriterUser,
            userName: `${UserInfo.FirstName} ${UserInfo.LastName}`
        }

        if (this.state.body == null || this.state.body == '') {
            ErrorAlert({ description: 'Please enter a valid message!!!' });
            return;
        }

        // apiPOSTReq(`${environment.opsBaseUrl}/ops/newLoanApplicationMessage`, {}, body, (err, resp) => {
        apiPOSTReq(`${environment.opsBaseUrl}/ops/newLoanApplicationMessage`, null, body, (err, resp) => {
            this.setState({ sumbitted: true })
            try {
                const data = resp;
                // console.log("/newLoanApplicationMessage", data)
                if (data.result) {
                    this.setState({ subject: '', body: '', fileList: [], sumbitted: false });
                    this.fetchConversationList({ pagination: this.state.pagination })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR newLoanApplicationMessage", error, err, resp);
                ErrorAlert({ description: error.message })
            }
        })
    }
    handleDeleteDoc = (uploadItem) => {
        var { loanApplication } = this.state;
        var businessDocument = {
            "category": "PreScreen",
            "name": uploadItem.fileName
        };

        if (loanApplication) businessDocument.applicationId = loanApplication.applicationId;
        var body = {
            "businessDocument": businessDocument,
            "documentId": uploadItem.documentId
        }

        console.log("handleSubmit DOC/delete uploadItem body", uploadItem, body);
        this.setState({ submitLoading: true });
        apiPOSTReq(`${environment.uamBaseUrl}/docs/delete`, {}, body, (err, resp) => {
            this.setState({ submitLoading: false, loadingItem: null });
            try {
                const data = resp || {};
                if (data.result) {
                    message.success(`File Deleted successfully.`, 0.75);
                    let removedList = this.state.fileList.filter(item => {
                        if (item.fileName !== uploadItem.fileName) return item
                    });
                    this.setState({ fileList: removedList });
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/docs/deleteUpload err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    handleDownloadResult = () => {
        const { loanApp } = this.props;

        const body = {
            "applicationId": loanApp.applicationId,
            "sortFieldName": "DESC"
        }
        this.setState({ loading: true });

        apiPOSTReq(`${environment.opsBaseUrl}/ops/downloadLoanApplicationMessages`, {}, body, (err, resp) => {
            this.setState({ loading: false });
            try {
                const data = resp;
                // console.log("/downloadLoanApplicationMessages", data)
                if (data.result) {
                    const blob = new Blob([data.conversationHistory], {
                        type: 'txt',
                    });
                    const fileURL = URL.createObjectURL(blob);
                    saveAs(fileURL, `history.txt`);
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR downloadLoanApplicationMessages", error, err, resp);
                this.setState({ loading: false })
                ErrorAlert({ description: error.message })
            }
        })
    }

    render() {
        const { loanApp } = this.props;
        const applicationId = loanApp.applicationId;
        let { fileList } = this.state;
        var uploadProps = {
            action: `${environment.apiBaseUrl}/file/upload`,
            headers: {
                AionCurrentBiz: this.props.store.BusinessUniqueKey,
                AionAuth: this.props.store.UAM.encryptedAuthHeader,
                businesskey: this.props.store.BusinessUniqueKey,
                jwt: this.state.jwt,
                username: "",
            },
            beforeUpload: (doc) => {
                this.setState({ uploading: true })
                var maxFileSize = 10240; // 10MB
                const uploadedFileSizeKiloBytes = doc.size / 1024
                if (uploadedFileSizeKiloBytes > maxFileSize) {
                    message.error(`File size is greater than 10MB`);
                    this.setState({ uploading: false });
                    return false;
                }
            },
            showUploadList: false,
            multiple: true,
            onChange: (info) => {
                var savedFileList = this.state.fileList;
                var file = info.file;
                console.log("info file", file);
                var { status, response, name, url } = file;
                if (status === "done") {
                    response = response || {};
                    savedFileList.push({
                        fileName: name,
                        uri: (response.UploadedUrls || response.uploadedUrls || [])[0],
                        file: this.props.includebase64 ? file : null
                    });
                    this.setState({ uploading: false })
                }
                this.setState({ fileList: savedFileList.map(item => ({ ...item, url: item.uri })) })
            }
        };
        return (
            <>
                <CardContainer fullWidth>
                    <FlexColumn style={{ marginTop: "10px" }}>
                        <Flex style={{ justifyContent: 'space-between', }}>
                            <div style={{ fontWeight: 600, fontSize: '1.2rem' }}>{this.props.title || "New message"}</div>
                            <Button type='link' onClick={this.handleDownloadResult} ><DownloadOutlined />Download History</Button>
                        </Flex>

                        {/* <Input placeholder="Type the subject" style={{ marginBottom: '10px', borderRadius: '8px', marginTop: '20px' }} value={this.state.subject} onChange={e => this.setState({ subject: e.target.value, sumbitted: false })} /> */}
                        <TextArea placeholder="Type the message" rows={6} value={this.state.body} onChange={e => this.setState({ body: e.target.value })} style={{ borderRadius: '8px' }} />

                        <Space style={{ paddingTop: '10px', display: 'flex', justifyContent: 'space-between', marginBottom: '40px', alignItems: 'flex-start' }}>
                            <Flex style={{ flexDirection: "column" }}>
                                <Upload
                                    {...uploadProps}
                                >
                                    <TextButton loading={this.state.uploading} text={"Add Attachments"} color="#40a9ff" icon={<PlusOutlined style={{ fontSize: 14, width: 24, height: 24, margin: '0 12px', color: "#40a9ff" }} />} />
                                </Upload>
                                <div style={{
                                    maxWidth: "1200px"
                                }}>
                                    <Flex start style={{ flexWrap: "wrap", padding: "4px", marginTop: fileList && fileList.length > 0 ? 8 : 0, gap: 16 }}>
                                        {
                                            fileList && fileList.map((file, i) => {
                                                return (
                                                    <TextButton
                                                        noBorder height='32px' padding='4px 8px' radius='4px' background='#F8F8F8' key={i} color="#40a9ff"
                                                        icon={<FilePdfOutlined style={{ fontSize: 14, color: "#40a9ff" }} />} rightIcon={<CloseOutlined style={{ color: "#40a9ff", fontSize: 14 }} onClick={() => this.handleDeleteDoc(file)} />} text={file.fileName} />
                                                );
                                            })
                                        }
                                    </Flex>
                                </div>
                            </Flex>
                            <Space>
                                <RefreshButton type="primary" solid style={{ marginRight: 0 }} permtype="Override" height="45px" onClick={this.createNewMessage} text="Update"></RefreshButton>
                            </Space>
                        </Space>



                        {
                            this.state.conversationItems.map(message => {
                                return <Message key={message.id} {...message} theme={this.props.theme} />
                            })
                        }
                    </FlexColumn >
                    <Modal
                        visible={this.state.previewVisible}
                        title={this.state.previewTitle}
                        footer={null}
                        onCancel={this.handleCancel}
                    >
                        <img alt="attachment" style={{ width: '100%' }} src={this.state.previewImage} />
                    </Modal>
                </CardContainer>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)));