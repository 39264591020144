import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import { message } from 'antd'

import _, { cloneDeep } from 'lodash'
import { FlexColumn, CardContainer } from '../../Reusable/Container'
import PageHeader from '../../Reusable/PageHeader'
import InvoiceTable from './InvoiceTable'
import SearchAndFilter from './SearchAndFilter'
import { apiPOSTReq } from '../../../Utils/api'
import { getFeaturePerm } from '../../../Utils/util'
import environment from '../../../environment'
import { ErrorAlert } from '../../Reusable/Alert'

// Actions
import { addDataToStore, RECEIVABLES_SAVE_DATA } from '../../../Actions/actions'
import { getUserApps } from '../../../Utils/util'


class Index extends Component {

    state = {
        loading: true,
        selectedTab: "Aion",
        pagination: {
            current: 1,
            pageSize: 10,
            showSizeChanger: false,
        },
        sorter: {},
        sortDirection: "DESC",
        showClear: false,
        selectedRowsPageDict: {},
        selectedRowKeyPageDict: {},
        filterStatus: 'VOIDED',
    }

    componentDidMount() {
        this.fetchBorrowerCustomers()
        const userInfo = this.props.aionStore?.userInfo;
        const businessInfo = this.props.aionStore?.business?.businessProfile?.businessInfo;
        this.setState({ ccAddr: userInfo.email, reminderTeamName: businessInfo.name })
        this.fetchInvoices({ ...this.state })
        const userApps = getUserApps(this.props.aionStore)
        if (userApps && userApps.includes("ABLCredit")) {
            this.setState({ ABLUser: true })
        } else {
            this.setState({ ABLUser: false })
        }
    }

    fetchBorrowerCustomers = () => {
        apiPOSTReq(`${environment.iveBaseUrl}/ive/bc/getActiveCustomers`, {}, {}, (err, resp) => {
            try {
                const data = resp
                console.log("/getActiveCustomers", data)
                if (data.result) {
                    this.setState({ activeCustomers: data.customers })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getActiveCustomers", error.stack, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    handleSearchChange = (e) => {
        this.setState({ [e.target.id]: e.target.value })

        const pagination = {
            current: 1,
            pageSize: 10
        }
        this.fetchInvoices({
            searchTerm: e.target.value,
            pagination,
            filterStatus: 'voided',
        })
    }

    showSelectedInvoice = (selectedInvoice) => {
        var v2BetaEnabled = getFeaturePerm("Receivables.Invoices.Receivables v2 Beta")

        if (v2BetaEnabled.manage) {
            this.props.history.push({
                pathname: '/receivables/invoices/create',
                state: { invoice: selectedInvoice }
            })
        } else {
            this.setState({ selectedInvoice: selectedInvoice, showInvoiceModal: true })
            if (selectedInvoice) {
                this.props.dispatch(addDataToStore(RECEIVABLES_SAVE_DATA, { editedInvoice: cloneDeep(selectedInvoice) }))
            } else {
                this.props.dispatch(addDataToStore(RECEIVABLES_SAVE_DATA, { editedInvoice: null }))
            }
        }
    }

    shouldShowQBTab = () => {
        const { Attributes, CurrentSync } = this.props.aionStore
        var { Businesses } = Attributes || {}
        var businessAttributes = Businesses || {}
        let shouldShowQBTab = false

        shouldShowQBTab = businessAttributes.AccountingSWLinked
        if (this.props.aionStore.hasIntegration) {
            if (CurrentSync && CurrentSync.syncSetupCompleted) {
                // has live integration
                shouldShowQBTab = false
            }
        }

        return shouldShowQBTab
    }

    fetchInvoices = (options) => {

        console.log('getInvoices body options', options)
        var { sortDirection, sortFieldname, filterStatus } = this.state
        var { pagination, fromDate, toDate, filterType, sorter, searchTerm, filterInvoiceFromDate, filterInvoiceToDate, filterDueFromDate, filterDueToDate, filterFromAmount, filterToAmount, filterCustomerName, amountError } = options
        filterType = filterType || []
        sorter = sorter || {}

        // if (!fromDate) fromDate = this.state.fromDate
        // if (!toDate) toDate = this.state.toDate

        // if (!filterInvoiceFromDate) filterInvoiceFromDate = this.state.filterInvoiceFromDate
        // if (!filterInvoiceToDate) filterInvoiceToDate = this.state.filterInvoiceToDate

        // if (!filterDueFromDate) filterDueFromDate = this.state.filterDueFromDate
        // if (!filterDueToDate) filterDueToDate = this.state.filterDueToDate

        // if (!filterFromAmount && !amountError) filterFromAmount = this.state.filterFromAmount
        // if (!filterToAmount && !amountError) filterToAmount = this.state.filterToAmount

        // if (!filterCustomerName) filterCustomerName = this.state.filterCustomerName

        const shouldShowQBTab = this.shouldShowQBTab()

        let source = shouldShowQBTab ? "AION|ARUPLOAD" : "AION|ARUPLOAD|QUICKBOOKS|CODAT"

        var body = {
            "size": pagination.pageSize,
            "page": pagination.current - 1,
            "source": source,
            "viewBy": "FILTER_SOURCE",
            "sortDirection": sortDirection
        }

        if (filterInvoiceFromDate) {
            body.viewBy = "FILTER_INVOICE_DATE"
            body.invoiceFromDate = filterInvoiceFromDate
            body.invoiceToDate = filterInvoiceToDate
        }
        if (filterDueFromDate) {
            body.viewBy = "FILTER_DUE_DATE"
            body.dueFromDate = filterDueFromDate
            body.dueToDate = filterDueToDate
        }

        if ((filterFromAmount || filterToAmount) && !amountError) {
            body.viewBy = "FILTER_AMOUNT"
            body.fromAmount = filterFromAmount
            body.toAmount = filterToAmount
        }

        if (filterCustomerName) {
            body.viewBy = "FILTER_CUSTOMER"
            body.customerName = filterCustomerName
        }

        if (filterStatus) {
            body.viewBy = "FILTER_STATUS"
            body.invoiceStatus = 'VOIDED'
        }

        if (sorter.field) {
            if (sorter.field === sortFieldname) {
                body.sortDirection = (sortDirection === "DESC") ? "ASC" : "DESC"
            } else {
                body.sortDirection = "ASC"
            }
            body.sortFieldname = sorter.field
        } else {
            if (sortFieldname) {
                body.sortFieldname = sortFieldname
            }
            body.sortDirection = sortDirection
        }

        if (searchTerm && searchTerm != "") {
            body.searchStr = searchTerm
        }

        if (filterType.length > 0) {
            var emailConstants = ["Delivered", "Opened", "Clicked"]
            var invStatus = filterType.filter(item => !emailConstants.includes(item)).join("|")
            var emailStatus = filterType.filter(item => emailConstants.includes(item)).join("|")

            // Check if filtering by date
            if (filterType.find(item => emailConstants.includes(item))) {
                body.viewBy = (body.viewBy == "FILTER_TXN_DATE") ? "FILTER_EMAIL_STATUS_TXN_DATE" : "FILTER_EMAIL_STATUS"
            } else {
                body.viewBy = (body.viewBy == "FILTER_TXN_DATE") ? "FILTER_STATUS_TXN_DATE" : "FILTER_STATUS"
            }
            body.status = invStatus
            body.emailStatus = emailStatus
        }

        // this.setState({ ...options, loading: true })
        this.setState({ loading: true })

        console.log('VoidedInvoices getInvoices body', body)

        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/getInvoices`, null, body, (err, resp) => {
            try {
                const data = resp || {}
                console.log("/getInvoices", data)
                if (data.result) {
                    var invoices = this.state.pagination.current > 1 ? this.state.invoices.concat(data.invoices) : data.invoices
                    var moreInvoices = invoices.length == data.count ? false : true
                    this.setState({
                        invoices: invoices,
                        loading: false,
                        pagination: {
                            ...pagination,
                            total: data.count
                        },
                        // sorter: _.isEqual(sorter, {}) ? this.state.sorter : sorter,
                        sortDirection: body.sortDirection,
                        sortFieldname: body.sortFieldname,
                        showClear: searchTerm ? true : false,
                        moreToLoad: moreInvoices
                    })

                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getInvoices", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    onTabChange = tab => {
        this.setState({ tab })
    }

    loadMoreInvoices = () => {
        var updatedState = {
            dateType: "Custom",
            loading: true,
            pagination: {
                current: this.state.pagination.current + 1,
                pageSize: 10
            }
        }
        this.fetchInvoices({ ...this.state, ...updatedState })
    }

    voidInvoice = (invoice) => {
        const userInfo = this.props.aionStore?.userInfo;
        this.setState({ voidLoading: true })

        var body = {
            invoiceId: invoice.invoiceId,
            modifiedByUserName: `${userInfo.firstName} ${userInfo.lastName}`,
        }

        console.log("Invoices invoice/voidInvoice body", invoice)

        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/voidInvoice`, null, body, (err, resp) => {
            try {
                const data = resp || {}

                console.log("Invoices invoice/voidInvoice", data)

                this.setState({ showVoidModal: false })
                this.fetchInvoices({ ...this.state })
                message.success('Invoice voided!')
            } catch (error) {
                console.log("ERRR voidInvoice", error, err, resp)
                ErrorAlert({ description: error.message })
            } finally {
                this.setState({ voidLoading: false })
            }
        })
    }

    render() {
        const { loading, invoices, enablePaymentReminder, selectedRowKeys, moreToLoad, sorter, ABLUser, activeCustomers } = this.state

        return (
            <>
                <FlexColumn className='main-padding'>
                    <PageHeader
                        titleText="Voided Invoices"
                        back
                    />

                    <CardContainer minWidth='970px' style={{ marginBottom: 24 }}>
                        <SearchAndFilter
                            invoices={invoices}
                            customers={activeCustomers}
                            fetchInvoices={this.fetchInvoices}
                            handleSearchChange={this.handleSearchChange}
                        />

                        <InvoiceTable
                            showSelectedInvoice={this.showSelectedInvoice}
                            loading={loading}
                            pagination={false}
                            onLoadMore={() => this.loadMoreInvoices()}
                            moreToLoad={moreToLoad}
                            sorter={sorter}
                            fetchInvoices={this.fetchInvoices}
                            invoices={invoices}
                            handleRowSelection={this.handleRowSelection}
                            showRowSelection={enablePaymentReminder}
                            selectedRowKeys={selectedRowKeys}
                            ablUser={ABLUser}
                            onVoid={(invoiceToVoid) => this.setState({ showVoidModal: true, invoiceToVoid })}
                        />

                    </CardContainer>
                </FlexColumn>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)))