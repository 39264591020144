import React, { useState, useEffect } from 'react'
import Dimensions from 'react-dimensions';
import moment from 'moment';
import { withTheme } from 'styled-components';
import {
    VictoryChart,
    VictoryBar,
    VictoryAxis,
    VictoryTooltip,
} from "victory";
import { getTextWidth, toCurrency } from '../../Utils/util';
import { Flex, FlexColumn, CardContainer } from './Container';
import { Text, Tag } from './Text';
import Pager from './Pager';
import { Image } from './Image'
import Clock from "../../Images/clock.png"

const TOOLTIP_HEIGHT = 32;
const BAR_WIDTH = 12;

const CustomTooltip = ({ x, y, datum, active }) => {
    if (!active) return null;

    const centerX = x; // Bar's center minus half of the tooltip's width
    const topY = y - TOOLTIP_HEIGHT - 5;

    const tooltipWidth = 20 + getTextWidth(`$${toCurrency(datum.spend)}`)

    return (
        <g>
            <rect x={centerX - tooltipWidth / 2} y={topY} width={tooltipWidth} height={TOOLTIP_HEIGHT} fill="url(#gradient)" rx="4" ry="4" />
            {/* <text x={centerX} y={topY + 22} fill="white" textAnchor="middle">
                {moment(datum.label, 'M-YYYY').format('MMM')}:
            </text> */}
            <text x={centerX} y={topY + 22} fill="white" textAnchor="middle">
                ${toCurrency(datum.spend)}
            </text>
        </g>
    );
};

const MonthlySpendBarChart = ({ height, paddingOffset, data, theme, xLabel, yLabel }) => {
    const [step, setStep] = useState(0)
    const [chunkedData, setChunkedData] = useState([]);
    const [activeIndex, setActiveIndex] = useState(null);


    useEffect(() => {
        const chunkArray = (array, chunkSize) => {
            const chunks = [];

            const remainder = array.length % chunkSize;
            if (remainder !== 0 && remainder < chunkSize) {
                chunks.push(array.slice(0, remainder));
                array = array.slice(remainder);
            }

            for (let i = 0; i < array.length; i += chunkSize) {
                chunks.push(array.slice(i, i + chunkSize));
            }

            return chunks;
        }

        if (data) {
            var chunkedData = chunkArray([...data], 5);

            console.log('cardTxnsSpendAnalysis', chunkedData)

            setChunkedData(chunkedData)
            setStep(chunkedData.length - 1)
        }
    }, [data]);

    return (
        <Flex style={{ height: '300px', minWidth: '420px', width: '100%', padding: '24px 8px 24px 24px' }}>
            <FlexColumn start style={{ width: 408, height: 320 }}>
                <Flex between>
                    <Text heading style={{ zIndex: 1 }}>Monthly Spend</Text>

                    {
                        data && chunkedData.length > 1 &&
                        <Pager
                            pages={chunkedData.length}
                            page={step}
                            next={() => setStep(step + 1)}
                            previous={() => setStep(step - 1)}
                            dotClickable
                            noDots
                        />
                    }
                </Flex>

                {
                    !data ?
                        <FlexColumn center style={{ height: 170, gap: 0, paddingTop: 28 }}>
                            <Text heading>No transactions</Text>
                            <Text width='400px' center>No spend to show</Text>
                        </FlexColumn>
                        :
                        <Flex centerHorizontally style={{ width: 400, height: 270, zIndex: 2, marginTop: -10 }}>
                            {
                                chunkedData && chunkedData[step] &&
                                <VictoryChart
                                    height={250}
                                    width={400}
                                    domain={{ x: [0.5, chunkedData[step].length + 0.5] }}  // Adjust the domain here
                                    domainPadding={{ x: [5, 0] }}  // Add padding to the left side of the chart
                                    padding={{ top: 38, right: 5, bottom: 65, left: 80 }}
                                >
                                    <defs>
                                        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                                            <stop offset="0%" stop-color="#000000" />
                                            <stop offset="100%" stop-color="#333333" />
                                        </linearGradient>
                                    </defs>

                                    <VictoryAxis
                                        padding={{ left: 100 }}
                                        dependentAxis
                                        style={{
                                            axis: { stroke: "transparent" },
                                            grid: {
                                                stroke: "#E3E6ED",
                                                strokeWidth: 1,
                                                strokeDasharray: "5,5"
                                            },
                                            tickLabels: {
                                                fontSize: 14,
                                                fontFamily: 'InterDisplay',
                                                padding: 20,
                                            }
                                        }}
                                        tickFormat={(t, index, ticks) => {
                                            const maxTick = Math.max(...ticks);
                                            if (maxTick >= 2000 && t >= 1000) {
                                                return `$${(t / 1000)}k`;
                                            }
                                            return `$${t}`;
                                        }}
                                    />

                                    <VictoryBar
                                        cornerRadius={{ topLeft: 5, topRight: 5, bottomLeft: 5, bottomRight: 5 }}
                                        barWidth={BAR_WIDTH}
                                        style={{
                                            data: {
                                                fill: "#48A1FB",
                                                stroke: "transparent", strokeWidth: 1, radius: 8
                                            },
                                        }}
                                        data={chunkedData[step] && [...chunkedData[step]]}
                                        // labelComponent={<VictoryLabel style={{ visibility: 'hidden' }} />}
                                        labels={() => null}
                                        x="label"
                                        y="spend"
                                        labelComponent={
                                            <CustomTooltip
                                                constrainToVisibleArea={true}
                                                cornerRadius={2}
                                                orientation="top"
                                            />
                                        }
                                        events={[
                                            {
                                                target: "data",
                                                eventHandlers: {
                                                    onMouseOver: (event, props) => {
                                                        return [
                                                            {
                                                                target: "data",
                                                                eventKey: props.index,
                                                                mutation: (props) => {
                                                                    return {
                                                                        radius: props.radius + 10, // increase radius by 10 (you can adjust this value)
                                                                    };
                                                                }
                                                            },
                                                            {
                                                                target: "labels",
                                                                eventKey: props.index,
                                                                mutation: (props) => {
                                                                    return { active: true };
                                                                }
                                                            }
                                                        ];
                                                    },
                                                    onMouseOut: (event, props) => {
                                                        return [
                                                            {
                                                                target: "data",
                                                                eventKey: props.index,
                                                                mutation: () => {
                                                                    return {};
                                                                }
                                                            },
                                                            {
                                                                target: "labels",
                                                                eventKey: props.index,
                                                                mutation: () => {
                                                                    return { active: false };
                                                                }
                                                            }
                                                        ];
                                                    }
                                                }
                                            }
                                        ]}
                                    />

                                    <VictoryAxis
                                        tickFormat={(tick) => moment(tick, 'M-YYYY').format('MMM ‘YY')}
                                        style={{
                                            axis: { stroke: "transparent" },
                                            ticks: {},
                                            tickLabels: {
                                                fontSize: 14,
                                                fontFamily: 'InterDisplay'
                                            }
                                        }}
                                    />
                                </VictoryChart>
                            }
                        </Flex>
                }
            </FlexColumn>
        </Flex>
    )
}

export default withTheme(MonthlySpendBarChart);
