import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { Typography, message, Skeleton, Space, Divider, Upload, Select, Collapse } from 'antd';
import { CheckCircleFilled, UploadOutlined, ArrowRightOutlined, PlusOutlined, DownOutlined, CheckCircleOutlined, EditOutlined, FilePdfOutlined } from '@ant-design/icons';

import { FlowViewContainer } from '../../Reusable/FlowViewContainer';
import FlowViewComponent from '../../Reusable/FlowViewComponent';
import { Text, Tag } from '../../Reusable/Refresh/Text';
import { Flex, FlexColumn, Container, Card } from '../../Reusable/Container';

import { TextButton } from '../../Reusable/Refresh/Button';
import { ErrorAlert } from '../../Reusable/Alert';
import AionLogo from '../../Reusable/Image';
import { flowViewsData, applyDoneStep } from './viewData';
import { apiPOSTReq } from '../../../Utils/api';
import environment from '../../../environment';

// Actions
import { addDataToStore, ONB_SAVE_DATA, PERSONAL_INFO, RESET_ONBOARDING_STATE, resetStore, SAVE_DATA } from '../../../Actions/actions';
import UserProfile from '../Reusable/UserProfile';
import { apiPOSTNoAuth } from '../../../Utils/api';
import UserCredentials from '../Reusable/UserCredentials';
import InviteeAppStatus from '../Reusable/InviteeAppStatus';

class Index extends Component {

    state = {
        submitLoading: false,
        loading: true,
        step: 0,
        flowSteps: [
            "Your Information",
            "Credentials"
        ],
        preboardUser: {},
        applicationStep: 0
    }
    flowViews = flowViewsData;

    componentDidMount() {
        window.scroll({ top: 0, behavior: 'smooth' })
        this.fetchInviteData()
        this.fetchBusinessData()
    }

    fetchBusinessData = () => {
        apiPOSTReq(`${environment.uamBaseUrl}/getBusinessOwnership`, {}, {}, (err, resp) => {
            try {
                if (err) throw Error(err)
                const data = resp || {}
                if (data.result) {
                    var business = data.business || {}                    
                    var businessProfile = business.businessProfile || {}

                    this.props.dispatch(addDataToStore(ONB_SAVE_DATA, { 
                        businessUniqueKey: business.Id,
                        coOwnersInfo: (businessProfile.coOwnerApplicantInfo || []).concat([businessProfile.primaryApplicantInfo]), // concat for calculating total ownership percentage
                        signersInfo: businessProfile.signerInfo || [],
                        businessInfo: businessProfile.businessInfo,
                        ...business
                    }))                    
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/getBusiness err", error, resp)
            }
        });
    }

    updateCurrentViewIndex = (i) => {
        this.setState({ currentViewIndex: i })
    }

    goToStep = (step) => {
        const stepMap = {
            "Your Information": "Apply.PersonalInfo",
            "Credentials": "Apply.Credentials"
        }
        const selectedStep = stepMap[this.state.flowSteps[step]]
        var currentStep = 0
        this.flowViews.forEach((item, i) => { if (item.FlowStep == selectedStep) currentStep = i })
        this.setState({ currentViewIndex: currentStep })
    }

    fetchInviteData = () => {
        var { onboardingStore } = this.props
        var { personalInfo } = onboardingStore

        const urlStr = this.props.location.search.replace('?', '')
        const ref = (new URLSearchParams(urlStr).get('ref') || '')
        // console.log("fetchInviteData", environment.uamBaseUrl, ref)
        apiPOSTNoAuth(`${environment.uamBaseUrl}/getPreboardUser`, { AionAuth: ref }, { referenceNumber: ref }, (err, resp) => {
            try {
                if (err) throw Error(err)
                var data = (resp || {}).data || {}
                console.log("fetchInviteData data", err, data.preboardUser)
                if (!data.result) throw Error(data.responseMessage || data.error)
                var preboardUser = data.preboardUser || {}
                if(preboardUser.authUser) {
                    this.props.dispatch(addDataToStore(ONB_SAVE_DATA, { allowNRFlow: preboardUser.nonResidentFlow }))
                    this.setState({ preboardUser: preboardUser, ref: ref, loading: false, currentViewIndex: preboardUser.status == 'Invited' ? 0 : 2 })
                } else {
                    // PersonalInfo not needed for non auth users
                    if(preboardUser.userType == 'CoOwner') { 
                        // this.flowViews = flowViewsData.filter(item => (item.FlowStep !== 'Apply.Credentials'))
                        this.props.dispatch(addDataToStore(ONB_SAVE_DATA, { allowNRFlow: preboardUser.nonResidentFlow }))
                        this.setState({ preboardUser: preboardUser, 
                                        ref: ref, 
                                        loading: false, 
                                        currentViewIndex: preboardUser.status == 'Invited' ? 0 : 2
                                    })
                        if(!personalInfo.firstName || !personalInfo.ownershipPercentage) this.props.dispatch(addDataToStore(PERSONAL_INFO, (preboardUser.applicant || {})))
                    } else {
                        this.props.dispatch(addDataToStore(ONB_SAVE_DATA, { allowNRFlow: true }))
                        this.flowViews = flowViewsData.filter(item => (item.FlowStep !== 'Apply.PersonalInfo'))
                        this.setState({ preboardUser: preboardUser, 
                                        ref: ref, 
                                        loading: false, 
                                        currentViewIndex: preboardUser.status == 'Invited' ? 0 : 1,
                                        flowSteps: ["Credentials"]
                                    })
                    }
                    
                }
                
                var businessId = preboardUser.businessId
                this.props.dispatch(addDataToStore(SAVE_DATA, { BusinessUniqueKey: businessId }))
                this.props.dispatch(addDataToStore(PERSONAL_INFO, { email: preboardUser.email, userType: preboardUser.userType, roles: preboardUser.roles }))
            } catch (error) {
                this.setState({ loading: false })
                ErrorAlert({ description: error.message || "Sorry, we had trouble fetching your invite. Please try again." })
            }
        })
    }

    render() {
        const flowView = this.flowViews[this.state.currentViewIndex] || {}
        return (
            <FlexColumn fullHeight={!['Apply.BusinessInfo'].includes(flowView.FlowStep)} style={{ minHeight: '100%' }} start>
                <Flex>
                    <AionLogo />
                </Flex>
                
                {this.getContentViews()}
            </FlexColumn>
        )
    }

    // This will provide the view for each flowView
    getContentViews = () => {
        var { currentViewIndex, loading, flowSteps, applicationStep, preboardUser } = this.state;
        var { businessName, userType, authUser } = preboardUser || {}
        const isCP = preboardUser.controlPerson || false

        if (loading) {
            return (
                <FlexColumn style={{ margin: '80px', width: '80%' }}>
                    <Skeleton loading={true} active title={false} paragraph={{ rows: 10 }}></Skeleton>
                </FlexColumn>
            )
        }

        const flowView = this.flowViews[currentViewIndex] || {};
        flowView.Title = businessName
        flowView.Description = authUser ? `You have been invited as an ${userType == 'CoOwner' ? 'owner' : 'authorized signer'} of ${businessName}. Please provide the below information to access your account.` : `You have been invited as a user of ${businessName}. Please provide the below information to access your account.`
        if(userType == 'CoOwner') {
            flowView.Description = `You have been invited as a beneficial owner of ${businessName}. Please provide the below information to complete the Aion application.`
            if(isCP) {
                flowView.Description = `You have been invited as a controlling party of ${businessName}. Please provide the below information to complete the Aion application.`
            }
            // flowView.ButtonTitle = 'SUBMIT'
        }
        switch (flowView.FlowStep) {
            case "Apply.PersonalInfo":
                return (
                    <UserProfile 
                        loading={loading}
                        flowView={flowView}
                        currentViewIndex={currentViewIndex}
                        loadPrev={this.loadPrevView}
                        loadNext={this.loadNext}
                        width={'100%'}
                        back={false}
                        padding='0 64px'
                        step={currentViewIndex}
                        steps={flowSteps}
                        showFooter={currentViewIndex < flowSteps.length}
                        headerMargin='4px 0'
                        applicationStep={applicationStep}
                        goToStep={this.goToStep}
                        disableRemoteSave={true}
                        allowAnyIdType={true}
                        hideSteps={flowSteps.length == 1}
                        inviteUserType={userType}
                        isCP={isCP}
                    />
                );
            case "Apply.Credentials":
                return (
                    <UserCredentials 
                        loading={loading}
                        flowView={flowView}
                        currentViewIndex={currentViewIndex}
                        loadPrev={this.loadPrevView}
                        loadNext={this.loadNext}
                        width={'100%'}
                        back={!this.state.preboardUser.authUser}
                        padding='0 64px'
                        step={currentViewIndex}
                        steps={flowSteps}
                        showFooter={currentViewIndex < flowSteps.length}
                        headerMargin='4px 0'
                        applicationStep={applicationStep}
                        goToStep={this.goToStep}
                        preboardUser={this.state.preboardUser}
                        hideSteps={!this.state.preboardUser.authUser}
                        inviteUserType={userType}
                        isCP={isCP}
                    />
                );
            case "Apply.Submitted":
                return (
                    <InviteeAppStatus
                        preboardUser={this.state.preboardUser}
                        inviteUserType={userType}
                        authUser={authUser}
                    />
                );
            default:
                break;
        }
    }

    // Validate input and go to next view if it's good
    loadNext = () => {
        var flowView = this.flowViews[this.state.currentViewIndex];
        var validation = { validated: true };
        if (validation.validated) {
            switch (flowView.FlowStep) {
                case "Apply.Credentials":
                    this.fetchInviteData()
                    break
                default:
                    this.loadNextView();
                    break
            }
        } else {
            ErrorAlert({ description: validation.message });
        }
    }

    loadPrevView = () => {
        if (this.state.currentViewIndex !== 0) {
            this.setState({
                currentViewIndex: this.state.currentViewIndex - 1,
                showError: false, errorMsg: ""      // dismiss any validatin error, if any, for the current step
            });
        }
    }

    loadNextView = () => {
        if (this.state.currentViewIndex + 1 < this.flowViews.length) {
            this.setState({
                currentViewIndex: this.state.currentViewIndex + 1
            });
        }
        window.scrollTo(0, 0);
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer,
        creditStore: state.creditAppReducer,
        onboardingStore: state.onboardingAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)));