import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'

import { Flex, FlexColumn } from '../../../Reusable/Container'
import { Text } from '../../../Reusable/Refresh/Text'

import Agreement from '../../../../Images/Agreement.png'
import Pager from '../../../Reusable/Pager'
import { Button } from '../../../Reusable/Button';
import { Skeleton, Space } from 'antd';
import { apiPOSTReq } from '../../../../Utils/api'
import { addDataToStore, ONB_SAVE_DATA, UAM_SAVE_BUSINESS } from '../../../../Actions/actions'
import environment from '../../../../environment'
import HelloSign from "hellosign-embedded";
import { Modal } from 'antd';
import { ErrorAlert } from '../../../Reusable/Alert'

class CreditAppStatus extends Component {

    state = {
        page: 0,
        loanApplication: this.props.loanApplication || {},
        signatureStatus: false
    }

    componentDidMount() {
        // Fetch on refresh page
        this.fetchContract()
    }

    closePreview = () => {
        this.setState({ contractSigned: false });
    };

    fetchContract = () => {
        this.setState({ loading: true })
        const urlStr = this.props.location.search.replace('?', '')
        const ref = (new URLSearchParams(urlStr).get('ref') || '')

        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/contract/getContract`, {}, { referenceNumber: ref }, (err, resp) => {
            try {
                const response = resp || {};
                if (response.result) {
                    const { contracts } = response
                    this.setState({ contracts: contracts })

                    const signatureStatus = contracts.promiseeSignatureStatus
                        if (signatureStatus == "Completed") {
                            this.setState({ signatureStatus: true })
                        }
                }
            }
            catch (error) {
                console.log("ERRR fetching contract", error, err, resp);
            }

        })
        this.setState({ loading: false })

    }

    openSignature = () => {
        this.setState({ isSignLoading: true })
        const contracts = this.state.contracts
        const header = {
            AionCurrentBiz: contracts.businessId
        }

        const body = {
            "BusinessId": contracts.businessId,
            "contracts": contracts
        }

        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/contract/getCreditSignature`, header, body, (err, resp) => {
            try {
                const response = resp || {};
                if (response.result) {
                    const embeddedUrl = response.helloSignEmbeddedUrl
                    this.setState({ embeddedUrl: embeddedUrl })

                    const client = new HelloSign({
                        clientId: `${environment.hellosignClientKey}`
                    });

                    client.open(this.state.embeddedUrl, {
                        testMode: environment.isSandbox
                    });

                    client.on('sign', () => {
                        console.log('The document has been signed!');
                        this.setState({ signatureStatus: true })
                    });

                    this.setState({ isSignLoading: false })
                }
                else {
                    this.setState({ isSignLoading: false })
                    if (contracts.contractSignatureStatus=="Cancelled") {
                        ErrorAlert({ description: "This contract is no longer valid" })
                    } else {
                        this.setState({ isSignLoading: false })
                        ErrorAlert({ description: "You have already signed the contract." })
                    }
                }
            }
            catch (error) {
                console.log("ERRR loading contract", error, err, resp);
            }

        })
    }

    render() {

        var { isSignLoading, loading, contracts, signatureStatus } = this.state
        var icon = <></>
        var title = ''
        var desc = <></>
        var cta = null
        var marginTop = "";
        contracts = contracts || {}


        icon = Agreement
        title = 'Signature Request'
        desc = <>
            
            <Text weight="400" size="16px" style={{ textAlign: 'center' }}>Exciting News!</Text>
            <Text weight="400" size="16px" style={{ textAlign: 'center', marginBottom: "10px" }}>The contract for {contracts.businessName} is ready for your signature.</Text>
            <div style={{ width: '80px', height: '1px', background: 'radial-gradient(83.75% 38.54% at 16.25% 86.88%, #1199FF 0%, #1A8AFA 100%)' }} />
                {
                    signatureStatus 
                    ? <div style={{ marginTop: "20px" }}>
                        <h3>Thank you for signing the contract.</h3>
                    </div>
                    :
                    <div style={{ textAlign: 'center', marginBottom: "20px" }}> 
                        {/* <Text weight="400" size="16px" style={{ textAlign: 'center', marginBottom: "100px" }}>Please click below to sign the contract.</Text> */}
                        <div style={{ marginTop: "20px" }}>
                            <Space size="middle" style={{ justifyContent: "center" }}>
                                <Button
                                    solid
                                    primary
                                    loading={isSignLoading}
                                    onClick={() => { this.openSignature() }}
                                    text='REVIEW AND SIGN AGREEMENT'
                                />
                            </Space>
                        </div>
                    </div>
                }
            
        </>
        marginTop = "220px"


        return (
            <FlexColumn start fullWidth fullHeight style={{ position: 'relative' }}>
                <Skeleton loading={loading} active title={false} paragraph={{ rows: 3 }}>
                    <FlexColumn start centerHorizontally>
                        <div style={{ width: '624px', zIndex: 5, }}>
                            <FlexColumn center gap='24px' style={{ marginTop: marginTop }}>
                                <img height='48px' width='48px' src={icon} />
                                <Text weight="500" size='28px' height='40px' style={{ textAlign: 'center', maxWidth: '400px' }}>{title}</Text>
                                {desc}
                                {cta}
                            </FlexColumn>
                        </div>
                    </FlexColumn>
                </Skeleton>
            </FlexColumn>
        );
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer,
        creditStore: state.creditAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(CreditAppStatus));