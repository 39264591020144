import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import { Modal, Table, Space, Skeleton, Dropdown, Menu, Tabs } from 'antd'
import cloneDeep from 'lodash/cloneDeep'

import { Flex, FlexColumn, CardContainer, LightContainer } from '../Reusable/Container'
import PageHeader from '../Reusable/PageHeader'
import { LabeledInput } from '../Reusable/Input'
import { Button, TextButton, Chip, ImageButton } from '../Reusable/Button'
import { ErrorAlert } from '../Reusable/Alert'
import { ApprovalJourney } from '../Reusable/Approval'
import { Text, Tag } from '../Reusable/Text'
import { apiPOSTReq } from '../../Utils/api'
import SearchAndFilter from './SearchAndFilter'
import environment from '../../environment'
import moment from 'moment'
import { addressObjectToStr, toCurrency, getResourcePerm } from '../../Utils/util'
import TableView from '../Reusable/TableView'

// Images
import ChevronDown from "../../Images/chevron-down.svg"
import ChevronUp from "../../Images/chevron-up.svg"
import ModalClose from '../../Images/modal-close.png'
import Search from '../../Images/search.png'
import FilterIcon from '../../Images/filter.png'

const { TabPane } = Tabs

class Index extends Component {

    constructor(props) {
        super(props)

        const paymentsPerm = getResourcePerm("BusinessBanking.Payments")
        const recipientsPerm = getResourcePerm("BusinessBanking.Recipients")
        const billPaymentsPerm = getResourcePerm("Payables.Bill Payments")
        const contractorPaymentsPerm = getResourcePerm("Payables.Contractor Payments")

        const { UserInfo } = this.props.store
        var username = UserInfo.Email

        this.state = {
            name: 'New Approval Setting',
            amount: 2000,
            paymentType: "ACH",
            rule: "greater than",
            approvers: {
                0: [],
                1: [],
                2: []
            },
            approvalSettings: [],
            approvalBy: "Any",
            stepCount: 1,
            showEditSettingModal: false,
            levelTypes: {
                0: "ANY",
                1: "ANY",
                2: "ANY"
            },
            registeredUsers: [],
            selectedTab: "todo",
            loading: true,
            objectMap: {},
            approvalsTab: (paymentsPerm.approve || recipientsPerm.approve) ? "Banking" : "Bill Payments",
            pageSize: 10,
            page: 0,
            payPage: 0,
            body: {
                "approverUserId": username,
                "sortDirection": "DESC",
            },
            payBody: {
                "approverUserId": username,
                "sortDirection": "DESC",
            },
            completedApprovals: [],
            completedPayApprovals: [],
        }
    }

    componentDidMount() {
        const { body, payBody } = this.state

        this.fetchOutbox(body)
        this.fetchPayOutbox(payBody)
    }

    fetchOutbox = (body, loadMore) => {
        const { pageSize, page, completedApprovals } = this.state

        console.log("Approvals /bb/getOutboxItems body", body)

        body.size = pageSize
        body.page = page

        this.setState({ loading: true })
        apiPOSTReq(`${environment.bbBaseUrl}/bb/approvals/getOutboxItems`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            try {
                const data = resp

                console.log("Approvals /bb/getOutboxItems", data)

                if (data.result) {
                    if (err) throw new Error(err)

                    this.setState({ completedApprovals: loadMore === true ? completedApprovals.concat(data.completedItemList) : data.completedItemList, loading: false, moreToLoad: data.pageable })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getOutboxItems", error, err, resp)
            } finally {
                this.setState({ loading: false })
            }
        })
    }

    fetchPayOutbox = (body, loadMore) => {
        const { pageSize, payPage, completedPayApprovals } = this.state

        console.log("Approvals /payables/getOutboxItems body", body)

        body.size = pageSize
        body.page = payPage

        this.setState({ loadingPay: true })
        apiPOSTReq(`${environment.payBaseUrl}/payables/approvals/getOutboxItems`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            try {
                const data = resp

                console.log("Approvals /payables/getOutboxItems", data)

                if (data.result) {
                    if (err) throw new Error(err)

                    this.setState({ completedPayApprovals: loadMore === true ? completedPayApprovals.concat(data.completedItemList) : data.completedItemList, loadingPay: false, morePayToLoad: data.pageable })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getOutboxItems", error, err, resp)
            } finally {
                this.setState({ loading: false })
            }
        })
    }

    handleOnChange = (event) => {
        const target = event.target
        this.setState({ [target.id]: target.value })
    }

    handleSelect = (value, option) => {
        this.setState({ [option.id]: value })
    }

    handleSelectLevelType = (value, option) => {
        const { levelTypes } = this.state
        levelTypes[option.id] = value
        this.setState({ levelTypes })
    }

    handleMultiSelect = (value, option) => {
        var { approvers, registeredUsers } = this.state

        var selectedUser

        registeredUsers.map(r => {
            if (r.id === option[option.length - 1].value) {
                selectedUser = r
            }
        })

        if (selectedUser) {
            approvers[option[option.length - 1].id].push(selectedUser)

            this.setState({ approvers })
        }
    }

    getApproversText = (approvers, approvalBy) => {
        if (approvers.length == 1) {
            return `${approvers[0].firstName} ${approvers[0].lastName}`
        } else {
            return <>
                {approvers.map(a => `${a.firstName} ${a.lastName}`).slice(0, approvers.length - 1).join(", ")}<b>{approvalBy === "ANY" ? " or " : " and "}</b>{`${approvers[approvers.length - 1].firstName} ${approvers[approvers.length - 1].lastName}`}
            </>
        }
    }

    getPaymentTypeText = (paymentType) => {
        switch (paymentType) {
            case 'ACH':
                return <><b>{paymentType}</b> transfers</>
            case 'Wire', 'Check', 'Bill Pay':
                return <><b>{paymentType}</b> transfers</>
            default:
                return <><b>Any</b> transfer</>
        }
    }

    getApproverNames = (level, levelCount) => {
        const { UserInfo } = this.props.store
        var username = UserInfo.Email

        var items = []
        level.performerInstances.map(a => {
            if (a.userName != username) {
                items.push(<Tag color={this.props.theme.colors.secondary3} style={{ marginRight: 10 }}>{`${a.firstName} ${a.lastName}`}</Tag>)
            } else {
                items.push(<Tag color={this.props.theme.colors.secondary3} style={{ marginRight: 10 }}>You</Tag>)
            }
        })

        return items
    }

    getRowDetails = (record, props) => {
        var { objectMap } = this.state
        var { ruleInstanceId } = record
        var instance = objectMap[ruleInstanceId]
        var { approvalInstance } = (instance || {})

        return (<Skeleton loading={!approvalInstance} active title={false} paragraph={{ rows: 3 }}>
            <FlexColumn start>
                {
                    approvalInstance && approvalInstance.reason &&
                    <LightContainer fullWidth>
                        <Flex start wrap gap='24px' key={ruleInstanceId}>
                            <LabeledInput
                                label='Reason'
                                nomargin
                                type='read-only'
                                value={approvalInstance.reason}
                                width='248px'
                            />
                        </Flex>
                    </LightContainer>
                }
                <LightContainer fullWidth padding='8px 24px 24px'>
                    <Tabs key={`${ruleInstanceId}-pendingTabs`} size="large">
                        <TabPane tab="Approval Journey" key="transferJourney">
                            <ApprovalJourney instance={approvalInstance} />
                        </TabPane>
                    </Tabs>
                </LightContainer>
            </FlexColumn>
        </Skeleton>)
    }

    getRowDetail = (record) => {
        this.getApprovalInstance(record)
    }

    getApprovalInstance = (record) => {
        var { objectMap } = this.state
        var { ruleInstanceId } = record

        apiPOSTReq(`${environment.bbBaseUrl}/bb/approvals/getApprovalInstance `, { "BankProvider": environment.bankProvider.crb }, { instanceId: ruleInstanceId }, (err, resp) => {
            try {
                const data = resp
                if (data.result && data.approvalRuleInstance) {
                    console.log('approvalHistory getRowDetails record: getApprovalInstance', data)

                    const objectMapClone = cloneDeep(objectMap)
                    if (objectMapClone[ruleInstanceId]) {
                        objectMapClone[ruleInstanceId].approvalInstance = data.approvalRuleInstance
                    } else {
                        objectMapClone[ruleInstanceId] = { approvalInstance: data.approvalRuleInstance } || true
                    }

                    this.setState({ objectMap: objectMapClone })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                ErrorAlert({ description: error.message })
            }
        })
    }

    handleSearchChange = (e) => {
        this.setState({ [e.target.id]: e.target.value, wireSelected: false, achSelected: false, recipientSelected: false, dropdownVisible: false, approvedSelected: false, rejectedSelected: false }, this.fetchOutbox)
    }

    render() {
        const { moreToLoad, morePayToLoad, selectedApproval, page, payPage, loading, loadingPay, body, payBody,
            completedApprovals, completedPayApprovals, objectDetail, expandedRowKeys, approvalsTab } = this.state
        const { theme } = this.props
        var { counterpartiesObj, achTransferObj, wireTransferObj, bpPaymentObj } = objectDetail || {}
        console.log("achTransferObj", achTransferObj, counterpartiesObj, wireTransferObj, bpPaymentObj)

        const paymentsPerm = getResourcePerm("BusinessBanking.Payments")
        const recipientsPerm = getResourcePerm("BusinessBanking.Recipients")
        const billPaymentsPerm = getResourcePerm("Payables.Bill Payments")
        const contractorPaymentsPerm = getResourcePerm("Payables.Contractor Payments")

        const completedColumns = [
            {
                title: 'Date',
                dataIndex: 'actionTime',
                key: 'actionTime',
                render: date => {
                    return <Text noWrap>{moment.utc(date).tz(environment.etTimeZone).format('MMM Do, YYYY')}</Text>
                },
            },
            {
                title: 'Description',
                dataIndex: 'contextIdentifier',
                key: 'contextIdentifier',
                render: (contextIdentifier, item) => {
                    var tag = item.objectType

                    if (item.objectType === "Counterparty") tag = "Recipient"
                    if (item.objectType === "InstantXfer") tag = "Instant"
                    
                    return (
                        <Space><b>{contextIdentifier}</b><Tag tagpreset={theme.colors.secondary4}>{tag}</Tag></Space>
                    )
                },
            },
            {
                title: 'Requested By',
                dataIndex: 'initiatedBy',
                key: 'initiatedBy'
            },
            {
                title: 'Status',
                dataIndex: 'performedAction',
                key: 'performedAction',
                render: (performedAction) => {
                    return (
                        <Tag tagpreset={theme.colors.secondary7}>{performedAction}</Tag>
                    )
                },
            }
        ]
        console.log("this.objectDetail:", this.state.objectDetail, selectedApproval)

        return (
            <>
                <FlexColumn className='main-padding'>
                    <PageHeader
                        subtitle="Approvals"
                        titleText="Approvals"
                    />
                    {/* <CardContainer minHeight='700px' style={{ marginBottom: 48 }}> */}
                        {
                            ((paymentsPerm.approve || recipientsPerm.approve) && (billPaymentsPerm.approve || contractorPaymentsPerm.approve)) ?
                                <>
                                    <Tabs size="large" onChange={(tab) => this.setState({ approvalsTab: tab }, () => console.log('approvalsTab', this.state.approvalsTab, this.state.morePayToLoad))}>
                                        <TabPane tab="Banking" key="Banking">
                                            
                                            <TableView
                                                id="completed-table"
                                                titleText="History"
                                                key='completed-table'
                                                tableLayout='auto'
                                                columns={completedColumns}
                                                dataSource={completedApprovals || []}
                                                rowKey='objectId'
                                                loading={loading}
                                                pagination={false}
                                                ctaContent={
                                                    <Flex start centerHorizontally gap='24px'>
                                                        <SearchAndFilter type='Banking' onChange={(options) => this.setState({ body: options }, () => this.fetchOutbox(options))} />
                                                    </Flex>
                                                }
                                                onChange={(pagination, filters, sorter) => {
                                                    this.setState({ outboxPagination: pagination })
                                                }}
                                                expandable={{
                                                    expandedRowRender: record => this.getRowDetails(record, this.props),
                                                    expandRowByClick: false,
                                                    expandIconColumnIndex: 4,
                                                    expandIcon: ({ expanded, onExpand, record }) => {
                                                        if (record.dateRow) {
                                                            return null
                                                        } else {
                                                            return !expanded ? (
                                                                <img style={{ cursor: 'pointer' }} width='24px' height='24px' src={ChevronDown} onClick={e => {
                                                                    this.getRowDetail(record)
                                                                    onExpand(record, e)
                                                                }} />
                                                            ) : (
                                                                <img style={{ cursor: 'pointer' }} width='24px' height='24px' src={ChevronUp} onClick={e => onExpand(record, e)} />
                                                            )
                                                        }
                                                    },
                                                    onExpand: (expanded, record) => {
                                                        console.log('approvalHistory getRowDetails record: onExpand:', record)
                                                        this.setState({ expandedRowKeys: expanded ? [record.objectId] : [] })
                                                    },
                                                    expandedRowKeys: expandedRowKeys,
                                                    // rowExpandable: record => !record.dateRow
                                                }}
                                            />
                                        </TabPane>
                                        <TabPane tab="Bill Payments" key="Bill Payments">
                                            <TableView
                                                id="completed-pay-table"
                                                key='completed-pay-table'
                                                titleText='History'
                                                className='borderless-table'
                                                tableLayout='auto'
                                                columns={completedColumns}
                                                dataSource={completedPayApprovals || []}
                                                rowKey='objectId'
                                                loading={loadingPay}
                                                pagination={false}
                                                ctaContent={
                                                    <Flex start centerHorizontally gap='24px'>
                                                        <SearchAndFilter type='Bill Payments' onChange={(options) => this.setState({ payBody: options }, () => this.fetchPayOutbox(options))} />
                                                    </Flex>
                                                }
                                                onChange={(pagination, filters, sorter) => {
                                                    this.setState({ outboxPagination: pagination })
                                                }}
                                                expandable={{
                                                    expandedRowRender: record => this.getRowDetails(record, this.props),
                                                    expandRowByClick: false,
                                                    expandIconColumnIndex: 4,
                                                    expandIcon: ({ expanded, onExpand, record }) => {
                                                        if (record.dateRow) {
                                                            return null
                                                        } else {
                                                            return !expanded ? (
                                                                <img style={{ cursor: 'pointer' }} width='24px' height='24px' src={ChevronDown} onClick={e => {
                                                                    this.getRowDetail(record)
                                                                    onExpand(record, e)
                                                                }} />
                                                            ) : (
                                                                <img style={{ cursor: 'pointer' }} width='24px' height='24px' src={ChevronUp} onClick={e => onExpand(record, e)} />
                                                            )
                                                        }
                                                    },
                                                    onExpand: (expanded, record) => {
                                                        console.log('approvalHistory getRowDetails record: onExpand:', record)
                                                        this.setState({ expandedRowKeys: expanded ? [record.objectId] : [] })
                                                    },
                                                    expandedRowKeys: expandedRowKeys,
                                                    // rowExpandable: record => !record.dateRow
                                                }} />
                                        </TabPane>
                                    </Tabs>
                                </>
                                :
                                <>
                                    <SearchAndFilter type={(paymentsPerm.approve || recipientsPerm.approve) ? 'Banking' : 'Bill Payments'} onChange={(options) => (paymentsPerm.approve || recipientsPerm.approve) ? this.fetchOutbox(options) : this.fetchPayOutbox(options)} />
                                    <TableView
                                        id="completed-table"
                                        className='borderless-table'
                                        tableLayout='auto'
                                        columns={completedColumns}
                                        dataSource={(paymentsPerm.approve || recipientsPerm.approve) ? completedApprovals : completedPayApprovals}
                                        rowKey='objectId'
                                        style={{ marginTop: 24 }}
                                        loading={loading}
                                        pagination={false}
                                        onChange={(pagination, filters, sorter) => {
                                            this.setState({ outboxPagination: pagination })
                                        }}
                                        expandable={{
                                            expandedRowRender: record => this.getRowDetails(record, this.props),
                                            expandRowByClick: false,
                                            expandIconColumnIndex: 4,
                                            expandIcon: ({ expanded, onExpand, record }) => {
                                                if (record.dateRow) {
                                                    return null
                                                } else {
                                                    return !expanded ? (
                                                        <img style={{ cursor: 'pointer' }} width='24px' height='24px' src={ChevronDown} onClick={e => {
                                                            this.getRowDetail(record)
                                                            onExpand(record, e)
                                                        }} />
                                                    ) : (
                                                        <img style={{ cursor: 'pointer' }} width='24px' height='24px' src={ChevronUp} onClick={e => onExpand(record, e)} />
                                                    )
                                                }
                                            },
                                            onExpand: (expanded, record) => {
                                                console.log('approvalHistory getRowDetails record: onExpand:', record)
                                                this.setState({ expandedRowKeys: expanded ? [record.objectId] : [] })
                                            },
                                            expandedRowKeys: expandedRowKeys,
                                            // rowExpandable: record => !record.dateRow
                                        }} />
                                </>

                        }

                        {
                            ((approvalsTab === 'Bill Payments' && morePayToLoad === true) || (approvalsTab === 'Banking' && moreToLoad === true)) &&
                            <Flex style={{ marginTop: 24 }} fullWidth centerVertically><TextButton text='LOAD 10 MORE' loading={loading} onClick={() => {
                                if (approvalsTab === 'Bill Payments') {
                                    this.setState({ payPage: payPage + 1 }, () => this.fetchPayOutbox(payBody, true))
                                } else {
                                    this.setState({ page: page + 1 }, () => this.fetchOutbox(body, true))
                                }
                            }} rightIcon={<img style={{ cursor: 'pointer' }} width='24px' height='24px' src={ChevronDown} />} /></Flex>
                        }
                    {/* </CardContainer> */}
                </FlexColumn>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer,
        bankingStore: state.bankingAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)))