export const flowViewsData = [
    {
        "ViewType": "WithoutImage",
        "AnimationName": "money-stack",
        "FlowStep": "Apply.Intro",
        // "Title": "Unlock cash stuck in invoices",
        // "Description": "With fast invoice funding, thousands of business owners have become cash flow positive.",
        "Progress": 0.0,
        "ButtonTitle": "Start",
        "Step": "Business info",
        "DefaultUserInput": "NOTREQUIRED"
    },
    // {
    //     "ViewType": "WithoutImage",
    //     "AnimationName": "name-card",
    //     "FlowStep": "Apply.Start",
    //     "Title": "How it works",
    //     "Description": "Complete your application in 3 simple steps:",
    //     "Progress": 0.0,
    //     "ButtonTitle": "Continue",
    //     "Step": "Business info",
    //     "DefaultUserInput": "NOTREQUIRED"
    // },
    // {
    //     "ViewType": "WithoutImage",
    //     "FlowStep": "Apply.Products",
    //     "Title": "The best credit solution for your business",
    //     "Description": null,
    //     "Progress": 0.0,
    //     "ButtonTitle": "Continue",
    //     "Step": "Business info",
    //     "DefaultUserInput": "NOTREQUIRED",
    //     "HideButton": true
    // },
    // {
    //     "ViewType": "WithAnimation",
    //     "FlowStep": "Apply.AccountingSW.Add",
    //     "AnimationAspectFill": false,
    //     "Title": "Connect accounting system",
    //     "Description": "Connect the accounting system your business uses. This will help us understand your invoicing history.",
    //     "Progress": 0.99,
    //     "AnimationName": "connect",
    //     "ButtonTitle": "Continue",
    //     "Step": "Business info",
    //     "DefaultUserInput": "NOTREQUIRED"
    // },
    // {
    //     "ViewType": "WithoutImage",
    //     "AnimationName": "connect",
    //     "FlowStep": "Apply.AccountingSW.QBO",
    //     "Title": "Connect your accounting system",
    //     "Description": "You will be redirected to your accounting system to provide authorization to Aion.",
    //     "Progress": 0.99,
    //     "Step": "Business info",
    //     "ButtonTitle": "Continue",
    //     "DefaultUserInput": "NOTREQUIRED",
    //     "HideButton": true
    // },
    {
        "ViewType": "WithoutImage",
        "FlowStep": "Apply.Banks.Add",
        "Title": "Add your external business bank accounts",
        "Description": "Connect all your business bank accounts to give us a picture of your cash flow.",
        "Progress": 0.99,
        "ImageName": "AvataaarsSmile",
        "ButtonTitle": "Continue",
        "Step": "Business info",
        "ValidationErrorMessage": "Connect at least one bank account.",            
    },
    {
        "ViewType": "WithoutImage",
        "FlowStep": "Apply.DocumentUpload",
        "Title": "Business information",
        "Description": "Please provide the information and documents requested below. We need this information for assessing your business.",
        "Progress": 0.0,
        "ButtonTitle": "Continue",
        "Step": "Business info",
        "ValidationErrorMessage": "Please upload your financial documents", 
    },
    // {
    //     "ViewType": "WithoutImage",
    //     "FlowStep": "Apply.TopCustomers",
    //     "Title": "Top customers",
    //     "Description": "Please add details for these customers",
    //     "Progress": 0.0,
    //     "ButtonTitle": "Continue",
    //     "Step": "Business info",
    //     "ValidationErrorMessage": "Please add data for this customer", 
    // },
    {
        "ViewType": "WithoutImage",
        "FlowStep": "Apply.Submit",
        "AnimationAspectFill": false,
        "Title": "Submit application",
        "Description": "Your application is ready to submit.",
        "Progress": 0.99,
        "ButtonTitle": "Submit",
        "DefaultUserInput": "NOTREQUIRED"
    },
    {
        "ViewType": "WithAnimation",
        "FlowStep": "Apply.Success",
        "AnimationName": "application-complete",
        "AnimationAspectFill": false,
        "Title": "Successfully submitted",
        "Description": "Based on the information you provided, if your business is eligible for credit, we will reach out to you with next steps.",
        "Progress": 0.99,
        "ImageName": "AvataaarsSmile",
        "ButtonTitle": "Done",
        "DefaultUserInput": "NOTREQUIRED"
    }
]

export const applyDoneStep = [{
    "FlowStep": "Apply.Done",
   // "AnimationName": "money-stack",
    "AnimationAspectFill": false,
   // "Title": "Thanks for your interest",
   // "Description": "Based on the information you provided, if your business is eligible for credit, we will reach out to you with next steps.",
    "Progress": 0.99,
   // "ImageName": "AvataaarsSmile",
    "DefaultUserInput": "NOTREQUIRED",
    "BackButton": false,
    "HideButton": true,
    "AnimationWidth": 400
}]