import { BNK_SAVE_DATA, TEMPORARY_FORM_DATA, RESET_BNK_STATE } from '../Actions/actions'

const initialState = {
}

function bankingAppReducer(state, action) {
	if (typeof state === 'undefined') {
		return initialState
	}
	var newState = state;
	switch (action.type) {
		case TEMPORARY_FORM_DATA:
			newState.TempData = action.data;
			return newState
		case BNK_SAVE_DATA:
			newState = Object.assign(newState, action.data ? action.data : {});
			newState.ACHRecipients = [];
			newState.WireRecipients = [];
			return newState
		case RESET_BNK_STATE:
			newState = initialState;
			return newState
		default:
			return state
	}
}

export default bankingAppReducer