import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'
import styled from 'styled-components'
import { saveAs } from 'file-saver'

import {
    Table, Button
} from 'antd'

// Components
import { Paragraph, Title } from '../../../Reusable/Text'
import { Flex } from '../../../Reusable/Container'
import environment from '../../../../environment'
import { CheckCircleFilled, DownloadOutlined, WarningFilled, WarningOutlined } from '@ant-design/icons'
import { apiPOSTReq } from '../../../../Utils/api'
import { ErrorAlert } from '../../../Reusable/Alert'

export const CardButton = styled.div`
    margin-top: 18px;
    background-color: rgb(255, 255, 255);
    border-radius: 0.5rem;
    width: 450px;
    margin-bottom: 1.5rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    opacity: 1;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    padding: 1.8rem;
    transition-property: box-shadow;
    transition-duration: 300ms;
    &:hover {
        cursor: pointer;
        box-shadow: 0 3px 15px rgb(0 0 0 / 0.4);
    };
`

export const CardButtonText = styled.span`
    font-size: 18px;
    font-weight: 400;
`

class Result extends Component {

    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            file: null,
            fileName: '',
            showErrorModal: false,
            fileList: [],
        }
    }

    handleDownloadResult = () => {
        const { downloadVendorUploadJobId } = this.props.aionStore
        if (!downloadVendorUploadJobId) {
            ErrorAlert({ description: "Sorry, we had trouble processing your request. Please try again." })
            return
        }

        apiPOSTReq(`${environment.payBaseUrl}/payables/vendors/getUploadStatusAsCsv`, { "BankProvider": environment.bankProvider.crb }, { uploadJobId: downloadVendorUploadJobId }, (err, resp) => {
            try {
                const data = resp || {}
                if (data) {
                    const blob = new Blob([data.uploadVendorInfoStr], {
                        type: 'application/vnd.ms-excel',
                    })
                    const fileURL = URL.createObjectURL(blob)
                    saveAs(fileURL, `error.csv`)
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/counterparty err", error, resp)
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    render() {
        const { theme } = this.props

        const headerErrorsColumns = [
            {
                title: 'Header Name',
                dataIndex: 'colName',
                key: 'colName',
                render: (item, record) => item
            },
        ]

        const columns = [
            {
                title: 'Row No.',
                dataIndex: 'row',
                key: 'row',
                width: 100,
                render: (item) => item
            },
            {
                title: 'Name',
                dataIndex: 'rowKey',
                key: 'rowKey',
                render: (item) => item
            },
        ]
        if (!this.props.afterInitialUpload) {
            columns.push({
                title: 'Error Type',
                dataIndex: 'errorMessage',
                key: 'errorMessage',
                render: (item) => item
            })
        }

        let showHeaderErrorTable = this.props.showHeaderErrorTable
        let errors = this.props.errors

        let errorNum = this.props.errorNum
        let successNum = this.props.successNum

        return (
            <>
                <div style={{ padding: 40 }}>
                    <Flex style={{ justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: '20px' }}>
                        <Title level={5} style={{ marginBottom: 0 }}>{this.props.uploadTypeTitle}</Title>
                        {this.props.afterInitialUpload && <Button type='link' style={{ color: theme.colors.secondary1 }} onClick={this.handleDownloadResult} ><DownloadOutlined />Download results</Button>}
                    </Flex>
                    <div style={{ display: 'flex', margin: '20px 0', alignItems: 'center' }}>
                        {showHeaderErrorTable && <WarningOutlined style={{ fontSize: 60, marginRight: '20px', marginBottom: '20px' }} />}
                        <div >
                            <Paragraph>{this.props.errorMessage}</Paragraph>
                        </div>
                    </div>

                    {!showHeaderErrorTable && <div style={{ fontSize: '20px', fontWeight: 600, marginBottom: '30px' }}>
                        {this.props.afterInitialUpload && <div style={{ display: 'flex', alignItems: 'center', color: theme.colors.secondary1, marginBottom: '15px' }}>
                            <CheckCircleFilled style={{ marginRight: '15px', fontSize: '34px' }} />
                            <div>{successNum} {this.props.successMsg}</div>
                        </div>}
                        <div style={{ color: theme.colors.warning }}><WarningFilled style={{ marginRight: '15px', fontSize: '34px' }} />{errorNum} {this.props.errorMsg || "Errors"}</div>
                    </div>}

                    <Table
                        scroll={{ y: '700px', x: '100%' }}
                        dataSource={errors}
                        columns={showHeaderErrorTable ? headerErrorsColumns : columns}
                        size="small"
                    />
                </div>

            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        bkStore: state.bookkeepingAppReducer,
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Result))