import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import {
    message, Popconfirm, Button, Space, Tooltip, Popover, Table
} from 'antd';
import { DeleteOutlined, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';

// Components
import { Paragraph } from '../../../Reusable/Text';
import { Flex, FlexColumn } from '../../../Reusable/Container';
import AddModalMSA from '../AddModalMSA';

import environment from '../../../../environment';
import { apiPOSTReq } from '../../../../Utils/api';
import { SignedLink } from '../../../Reusable/Link';
import moment from 'moment';
import AddModalWO from '../AddModalWO';

class Index extends Component {
    state = {
        customer: this.props.customer || {},
        loading: false,
        workOrders: []
    }

    componentDidMount() {
        this.getMSAForCustomer();
        this.getWOsForCustomer();
    }

    getMSAForCustomer = () => {
        var { loanApp, underwriter } = this.props;
        var body = {
            "BusinessId": this.props.aionStore.BusinessUniqueKey,
            "CustomerId": this.state.customer.customerId,
        }
        var uri = `${environment.iveBaseUrl}/ive/msa/getMSAForCustomer`;
        if(loanApp) {
            body.applicationId = loanApp.applicationId;
            uri = `${environment.iveBaseUrl}/ive/msa/getMSAsForApplication`;
            if(underwriter) body.clientBusinessId = loanApp.businessId;
        }
        console.log("getMSAForCustomer body", uri, body);
        apiPOSTReq(uri, null, body, (err, resp) => {
            try {
                console.log("getMSAForCustomer err, resp", err, resp);
                const data = resp;
                if (data.result) {
                    const msas = (data.msas || []);
                    this.setState({
                        msas: msas
                    });
                } else {
                    throw Error("Could not getActiveMSAs.")
                }
            } catch (error) {
                //console.log("ERRR", error.stack)
                //message.error(`Error adding MSA `);
            }
        })
    }

    getWOsForCustomer = () => {
        var { loanApp, underwriter, reportWOStatus } = this.props;
        var body = {
            "BusinessId": this.props.aionStore.BusinessUniqueKey,
            "CustomerId": this.state.customer.customerId,
        }
        var uri = `${environment.iveBaseUrl}/ive/po/getPOsForCustomer`;
        if(loanApp) {
            body.applicationId = loanApp.applicationId;
            uri = `${environment.iveBaseUrl}/ive/po/getPOsForApplication`;
            if(underwriter) body.clientBusinessId = loanApp.businessId;
        }
        console.log("getPOsForCustomer body", uri, body);
        apiPOSTReq(uri, null, body, (err, resp) => {
            try {
                const data = resp;
                if (data.result) {
                    //console.log("POSs", data)
                    var pos = (data.pos || []);
                    this.setState({
                        workOrders: pos
                    })
                    // Notify parent component
                    if(reportWOStatus) this.props.msaUploaded(this.state.customer, pos);
                } else {
                    throw Error("Could not fetch work orders.")
                }
            } catch (error) {
                //console.log("ERRR", error.stack, err, resp)
                // message.error(`Could not fetch work orders.`);
            }
        })
    }

    handleShowWOModal = () => {
        this.getMSAForCustomer();
        this.setState({ showAddModalWO: true });
    }

    handleWOAdded = () => {
        this.getWOsForCustomer();
        this.setState({ showAddModalWO: false });
    }

    handleDeleteWO = (PONumber) => {
        const body = {
            "BusinessId": this.props.aionStore.BusinessUniqueKey,
            "CustomerId": this.state.customer.customerId,
            "PONumber": PONumber
        }
        //console.log("handleDeleteWO", body)
        apiPOSTReq(`${environment.iveBaseUrl}/ive/po/delete`, this.iveHeaders, body, (err, resp) => {
            try {
                const data = resp;
                if (data.result) {
                    this.getWOsForCustomer();
                } else {
                    throw Error(data.error || data.responseMessage || "Could not delete Work Order.")
                }
            } catch (error) {
                //console.log("ERRR", error, err, resp.error)
                message.error(error.message);
            }
        })
    }

    handleCancelWO = () => {
        this.setState({ showAddModalWO: false });
    }

    render() {
        var { customer, workOrders } = this.state;
        var { theme, loanApp, underwriter } = this.props

        return (
            <>
                <div>
                    {
                        ((this.state.workOrders || []).length == 0) ?
                            <Paragraph size="0.9rem" color={theme.colors.systemGray2}>No purchase orders added.</Paragraph>
                            :
                            <Table
                                id="wo-table"
                                bordered={false}
                                size="middle"
                                pagination={{hideOnSinglePage: true}}
                                columns={[
                                    {
                                        title: 'Purchase Order',
                                        dataIndex: 'ponumber',
                                        key: 'ponumber',
                                        render: (ponumber, item) => <Space>#{ponumber}<SignedLink {...item.documentUrl} /></Space>
                                    },
                                    {
                                        title: 'Date',
                                        dataIndex: 'metaData',
                                        key: 'Date',
                                        render: item => (moment(item.createTime).format('ll')),
                                    },
                                    {
                                        title: 'Action',
                                        key: 'action',
                                        width: 150,
                                        render: (text, item) => (
                                            !underwriter && <Space size="middle">
                                                {/* <a target="_blank" href={item.documentUrl.uri}><FileTextOutlined style={{ fontSize: 18 }} /></a> */}
                                                <Popconfirm title="Are you sure you want to delete this Work Order?" onConfirm={() => this.handleDeleteWO(item.ponumber)}>
                                                    <a><DeleteOutlined style={{ cursor: 'pointer', fontSize: 18 }} /></a>
                                                </Popconfirm>
                                            </Space>
                                        ),
                                    }
                                ]}
                                dataSource={workOrders || []}
                                rowKey='ponumber'
                            />
                    }
                    {/* {
                        this.state.workOrders && this.state.workOrders.map((wo, index) => {
                            return (
                                <div key={wo.PONumber} style={{ margin: "8px 0" }}>
                                    <Space size="small">
                                        <span style={{ color: this.props.theme.colors.secondary3, fontSize: 17 }}>#{wo.ponumber}</span>
                                        <span style={{ color: this.props.theme.colors.secondary3, fontSize: 17 }}>{wo.documentUrl.fileName}</span>
                                        <Popconfirm title="Are you sure you want to delete this Work Order?" onConfirm={() => this.handleDeleteWO(wo.ponumber)}>
                                            <DeleteOutlined style={{cursor: 'pointer'}} />
                                        </Popconfirm>
                                    </Space>
                                </div>
                            )
                        })
                    } */}
                    {!underwriter && <Button disabled={false/*!this.invInfoCompleted*/} onClick={this.handleShowWOModal} type="primary" style={{ marginTop: 16 }}>
                        Add Purchase Order
                    </Button>}

                </div>
                {/* Modals */}
                <AddModalWO
                    visible={this.state.showAddModalWO}
                    onAdd={this.handleWOAdded}
                    onCancel={this.handleCancelWO}
                    aionStore={this.props.aionStore}
                    store={this.props.store}
                    customerId={customer.customerId}
                    customer={customer}
                    loanApp={loanApp}
                    msas={this.state.msas}
                />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Index));