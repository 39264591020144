import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { Text, Tag } from '../../Reusable/Text';
import { Image } from '../../Reusable/Image';
import { Flex, FlexColumn, Container, Card, CardContainer } from '../../Reusable/Container';
import { Modal, Skeleton } from 'antd'
import { TextButton } from '../../Reusable/Button';
import { ErrorAlert } from '../../Reusable/Alert';
import AlertModal from '../../Reusable/AlertModal'
import AionLogo from '../../Reusable/Image';
import AionLinkLogo from "../../../Images/aion.svg"
import { flowViewsData, applyDoneStep } from './viewData';
import { apiPOSTReq, doSignout } from '../../../Utils/api';
import { isMobile, toCurrency, getBPAccountsWithAccess, toNumberStr } from '../../../Utils/util'
import environment from '../../../environment';
import Welcome from './Welcome';
import PaymentInformation from './PaymentInformation';
import InvoiceSummary from './InvoiceSummary';
import InvoiceVoided from './InvoiceVoided';
import MerchantDetails from './MerchantDetails';
import PaymentHistory from './PaymentHistory';
import AboutAion from './AboutAion';
import AICPA from './AICPA';
import PaymentOptions from './PaymentOptions';
import InvoicePaid from './InvoicePaid';
import SignInModal from '../../Authentication/SigninModal';
import ModalClose from '../../../Images/modal-close.png'

import { addDataToStore, BNK_SAVE_DATA } from '../../../Actions/actions';
import { apiPOSTNoAuth } from '../../../Utils/api';


class Index extends Component {

    state = {
        submitLoading: false,
        loading: true,
        step: 0,
        flowSteps: [
            "Your Information",
            "Credentials"
        ],
        preboardUser: {},
        applicationStep: 0,
        getStarted: true,
        getStartedLoading: true,
    }

    componentDidMount() {
        const urlStr = this.props.location.search.replace('?', '')
        const ref = (new URLSearchParams(urlStr).get('ref') || '')

        window.scroll({ top: 0, behavior: 'smooth' })
        var Accounts = getBPAccountsWithAccess("Transactions", { getActiveAccounts: true })
        this.setState({ accounts: Accounts, ref: ref }, () => this.getInvoicePaymentReferralInfo())
    }

    getInvoicePaymentReferralInfo = () => {
        const { ref } = this.state

        console.log("CustomerPayment getInvoicePaymentReferralInfo body", { referralCode: ref })

        apiPOSTNoAuth(`${environment.iveBaseUrl}/ive/invoice/getInvoicePaymentReferralInfo`, { AionAuth: ref }, { referralCode: ref }, (err, resp) => {
            try {
                if (err) throw Error(err)
                var data = (resp || {}).data || {}
                console.log("CustomerPayment getInvoicePaymentReferralInfo data", err, data)
                if (!data.result) throw Error(data.responseMessage || data.error)
                this.setState({
                    customer: data.customer,
                    customerPaymentMethod: data.customerPaymentMethod,
                    invoice: data.invoice,
                    attachmentUrl: data.attachmentUrl,
                    paymentAmount: data.invoice?.balance,
                    email: data.referralInfo?.customerEmail || data.customer?.primaryEmailAddress?.address || null,
                    name: data.referralInfo?.customerName,
                    referralInfo: data.referralInfo,
                    business: data.business,
                })
            } catch (error) {
                console.log("CustomerPayment getInvoicePaymentReferralInfo error", error)
                // ErrorAlert({ description: error.message || "Sorry, we had trouble fetching your invite. Please try again." })
            } finally {
                this.setState({ getStartedLoading: false, loading: false })
            }
        })
    }

    getAccounts = () => {
        this.setState({ aionAccountsLoading: true })

        apiPOSTReq(`${environment.bbBaseUrl}/bb/getAccounts`, { "BankProvider": environment.bankProvider.crb }, null, (err, resp) => {
            try {
                console.log("CustomerPayment /bb/getAccounts", err, resp);
                const data = resp || {};
                if (data.result) {
                    if (err) throw new Error(err)
                    var accounts = data.bbaccounts

                    console.log("CustomerPayment /bb/getAccounts", accounts)

                    var zeroBalance = true
                    var crb = false

                    accounts.forEach(account => {
                        if (account.bankProvider === "CrossRiverBank") {
                            crb = true
                        }
                        if (account.currentBalance != 0) {
                            zeroBalance = false
                        }
                    })

                    this.setState({ accounts })
                    this.props.dispatch(addDataToStore(BNK_SAVE_DATA, { BBAccounts: accounts }))
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("CustomerPayment ERRR getAccounts", error, err, resp);
                const fd = this.fetchData;
                // ErrorAlert({ description: error.message });
            } finally {
                this.setState({ aionAccountsLoading: false })
            }
        })
    }

    pay = (payByAion, fromAccount) => {
        console.log("CustomerPayment pay payByAion, fromAccount", payByAion, fromAccount)
        const { ref } = this.state
        const { invoice, customer, name, email, paymentAmount, accounts } = this.state

        const { invoiceId, balance } = invoice
        const { customerId, businessId, displayName } = customer

        if (!name) {
            this.setState({ errorField: 'name', errorMessage: 'Name is a required field' })
            return
        }
        if (!email) {
            this.setState({ errorField: 'email', errorMessage: 'Email is a required field' })
            return
        }
        if (!paymentAmount) {
            this.setState({ errorField: 'paymentAmount', errorMessage: 'Payment amount is a required field' })
            return
        }
        if (isNaN(paymentAmount)) {
            this.setState({ errorField: 'paymentAmount', errorMessage: 'Enter a valid payment amount' })
            return
        }
        if (paymentAmount > balance) {
            this.setState({ errorField: 'paymentAmount', errorMessage: `Payment amount cannot exceed the remaining balance of $${toCurrency(balance)}` })
            return
        }
        if (payByAion && !fromAccount) {
            this.setState({ errorField: 'fromAccount', errorMessage: 'Select your Aion account to continue' })
            return
        }

        const { accountName, accountNumber, routingNumber, accountSubType, confirmAccountNumber, loggedIn } = fromAccount || {}

        if (!routingNumber) {
            this.setState({ errorField: 'routingNumber', errorMessage: 'Routing number is a required field' })
            return
        }
        if (isNaN(routingNumber)) {
            this.setState({ errorField: 'routingNumber', errorMessage: 'Please enter a valid 9 digit routing number' })
            return
        }
        if (routingNumber?.length !== 9) {
            this.setState({ errorField: 'routingNumber', errorMessage: 'Routing number must be exactly 9 digits' })
            return
        }
        if (!accountNumber) {
            this.setState({ errorField: 'accountNumber', errorMessage: 'Account number is a required field' })
            return
        }
        if ((!payByAion || loggedIn === false) && !confirmAccountNumber) {
            this.setState({ errorField: 'confirmAccountNumber', errorMessage: 'Confirm account number is a required field' })
            return
        }
        if ((!payByAion || loggedIn === false) && (accountNumber !== confirmAccountNumber)) {
            this.setState({ errorField: 'accountNumber', errorMessage: 'Account numbers do not match' })
            return
        }
        if (!accountSubType) {
            this.setState({ errorField: 'accountType', errorMessage: 'Select your account type to continue' })
            return
        }

        this.setState({ errorField: null, errorMessage: null })

        var body = {
            customerId: customerId,
            businessId: businessId,
            bankDetail: {
                accountName: accountName,
                accountNumber: accountNumber,
                routingNumber: routingNumber,
                accountType: accountSubType,
            },
            bankProvider: "CrossRiverBank",
            invoiceId: invoiceId,
            paymentAmount: toNumberStr(paymentAmount),
            paymentMethodId: null, // paymentMethodId,
            customerName: name,
            customerEmail: email,
            aionPay: payByAion,
        }

        console.log("CustomerPayment pay", body)
        console.log("CustomerPayment header", { AionAuth: ref, AionCurrentBiz: businessId })

        this.setState({ payLoading: true })

        apiPOSTNoAuth(`${environment.iveBaseUrl}/ive/invoice/pay`, { AionAuth: ref, AionCurrentBiz: businessId }, body, (err, resp) => {
            console.log("CustomerPayment /ive/invoice/pay err resp", err, resp)
            this.setState({ submitLoading: false })
            try {
                if (err) throw Error(err)
                const data = resp.data || {}
                if (data.result) {
                    console.log("CustomerPayment /ive/invoice/pay data ", data)
                    // if (approvalRequired) this.props.onApprovalAction()
                    if (accounts && accounts?.length > 0) this.signout()
                    this.setState({ lastPaidAmount: paymentAmount, invoicePartiallyPaid: paymentAmount !== balance, accounts: null })
                    this.getInvoicePaymentReferralInfo()
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("CustomerPayment /ive/invoice/pay err", error, error.stack)
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            } finally {
                this.setState({ payLoading: false })
            }
        })
    }

    signout = () => {
        doSignout(this.props.history, this.props.dispatch, null, true)
    }

    render() {
        var { getStarted, name, email, paymentAmount, errorField, errorMessage, getStartedLoading, invoice, customer, loading, invoicePartiallyPaid,
            aionAccountsLoading, attachmentUrl, accounts, showSuccessModal, payLoading, referralInfo, business, lastPaidAmount } = this.state
        var { payments, invoiceStatus } = invoice || {}

        return (
            <FlexColumn start className='main-padding' fullWidth fullHeight style={{ marginBottom: 48 }}>
                <Flex fullWidth between centerHorizontally style={{ padding: isMobile ? '0 12px' : '0 84px' }}>
                    <AionLogo />

                    <div style={{ borderRadius: 24, background: 'var(--Solid-Primary-Blue-0, #F3FAFF)', padding: '4px 8px' }}>
                        <TextButton icon={<Image src={AionLinkLogo} />} text={<a target="_blank" href="https://www.aionfi.com">Try Aion for your business!</a>} underline weight='400' />
                    </div>
                </Flex>

                {
                    isMobile ?
                        <>
                            <FlexColumn start left gap='24px' fullWidth style={{ paddingBottom: 24, marginBottom: 0 }}>
                                <Skeleton loading={loading} active title={false} paragraph={{ rows: 33 }}>
                                    {(() => {
                                        switch (true) {
                                            case invoicePartiallyPaid:
                                                return <InvoicePaid
                                                    partial
                                                    amount={lastPaidAmount}
                                                    businessName={business?.businessProfile?.businessInfo?.name}
                                                    onMakeAnotherPayment={() => this.setState({ invoicePartiallyPaid: false })}
                                                />
                                            case invoiceStatus === "VOIDED":
                                                return <InvoiceVoided
                                                    amount={invoice?.amount}
                                                    businessName={business?.businessProfile?.businessInfo?.name} />
                                            case invoice?.balance > 0:
                                                return <>
                                                    {
                                                        referralInfo?.balance !== referralInfo?.amount ?
                                                            <Text heading size='32px'>You have ${toCurrency(invoice?.balance)} remaining on this invoice from <Text heading size='32px' primary>{business?.businessProfile?.businessInfo?.name}</Text></Text>
                                                            :
                                                            <Text heading size='32px'>You have received an invoice for ${toCurrency(invoice?.amount)} from <Text heading size='32px' primary>{business?.businessProfile?.businessInfo?.name}</Text></Text>
                                                    }

                                                    <InvoiceSummary
                                                        invoice={invoice}
                                                        referralInfo={referralInfo}
                                                        attachmentUrl={attachmentUrl}
                                                    />

                                                    <PaymentInformation
                                                        name={name}
                                                        email={email}
                                                        paymentAmount={paymentAmount}
                                                        errorField={errorField}
                                                        errorMessage={errorMessage}
                                                        setName={(name) => this.setState({ name })}
                                                        setEmail={(email) => this.setState({ email })}
                                                        setAmount={(paymentAmount) => this.setState({ paymentAmount })}
                                                    />

                                                    <PaymentOptions
                                                        aionAccountsLoading={aionAccountsLoading}
                                                        payLoading={payLoading}
                                                        onLogin={() => this.setState({ showSignIn: true })}
                                                        accounts={accounts}
                                                        pay={this.pay}
                                                        errorField={errorField}
                                                        errorMessage={errorMessage}
                                                    />
                                                </>
                                            case invoice?.balance === 0:
                                                return <>
                                                    <InvoicePaid
                                                        amount={invoice?.amount}
                                                        businessName={business?.businessProfile?.businessInfo?.name}
                                                    />

                                                    <InvoiceSummary
                                                        invoice={invoice}
                                                        referralInfo={referralInfo}
                                                        attachmentUrl={attachmentUrl}
                                                    />
                                                </>
                                            default:
                                                return null
                                        }
                                    })()}


                                    <MerchantDetails
                                        businessInfo={business?.businessProfile?.businessInfo}
                                        referralInfo={referralInfo}
                                    />

                                    {
                                        (payments && payments.length > 0) &&
                                        <PaymentHistory
                                            invoice={invoice}
                                            referralInfo={referralInfo}
                                            attachmentUrl={attachmentUrl}
                                        />
                                    }

                                    <AboutAion />

                                    <AICPA />
                                </Skeleton>
                            </FlexColumn>
                        </>
                        :
                        <>
                            <Flex between fullWidth center gap='24px' style={{ paddingBottom: 48 }}>
                                <FlexColumn start left fullHeight gap='24px' style={{ width: 624 }}>
                                    <Skeleton loading={loading} active title={false} paragraph={{ rows: 33 }}>
                                        {(() => {
                                            switch (true) {
                                                case invoicePartiallyPaid:
                                                    return <InvoicePaid
                                                        partial
                                                        textWidth='406px'
                                                        width='624px'
                                                        height='484px'
                                                        amount={lastPaidAmount}
                                                        businessName={business?.businessProfile?.businessInfo?.name}
                                                        onMakeAnotherPayment={() => this.setState({ invoicePartiallyPaid: false })}
                                                    />
                                                case invoiceStatus === "VOIDED":
                                                    return <InvoiceVoided
                                                        textWidth='406px'
                                                        width='624px'
                                                        height='484px'
                                                        amount={invoice?.amount}
                                                        businessName={business?.businessProfile?.businessInfo?.name} />
                                                case invoice?.balance > 0:
                                                    return <>
                                                        {
                                                            invoice?.balance !== invoice?.amount ?
                                                                <Text heading size='32px'>You have ${toCurrency(invoice?.balance)} remaining on this invoice from <Text heading size='32px' primary>{business?.businessProfile?.businessInfo?.name}</Text></Text>
                                                                :
                                                                <Text heading size='32px'>You have received an invoice for ${toCurrency(invoice?.amount)} from <Text heading size='32px' primary>{business?.businessProfile?.businessInfo?.name}</Text></Text>
                                                        }

                                                        <PaymentInformation
                                                            name={name}
                                                            email={email}
                                                            paymentAmount={paymentAmount}
                                                            errorField={errorField}
                                                            errorMessage={errorMessage}
                                                            setName={(name) => this.setState({ name })}
                                                            setEmail={(email) => this.setState({ email })}
                                                            setAmount={(paymentAmount) => this.setState({ paymentAmount })}
                                                            width='624px'
                                                        />

                                                        <PaymentOptions
                                                            loading={aionAccountsLoading}
                                                            payLoading={payLoading}
                                                            onLogin={() => this.setState({ showSignIn: true })}
                                                            accounts={accounts}
                                                            pay={this.pay}
                                                            errorField={errorField}
                                                            errorMessage={errorMessage}
                                                            width='624px'
                                                        />
                                                    </>
                                                case invoice?.balance === 0:
                                                    return <InvoicePaid
                                                        textWidth='406px'
                                                        width='624px'
                                                        height='484px'
                                                        amount={invoice?.amount}
                                                        businessName={business?.businessProfile?.businessInfo?.name} />
                                                default:
                                                    return null
                                            }
                                        })()}
                                    </Skeleton>
                                </FlexColumn>
                                <FlexColumn start centerHorizontally fullHeight style={{ width: 408 }} gap='24px'>
                                    <Skeleton loading={loading} active title={false} paragraph={{ rows: 33 }}>
                                        <InvoiceSummary
                                            width='408px'
                                            invoice={invoice}
                                            referralInfo={referralInfo}
                                            attachmentUrl={attachmentUrl}
                                        />

                                        <MerchantDetails
                                            width='408px'
                                            businessInfo={business?.businessProfile?.businessInfo}
                                            referralInfo={referralInfo}
                                        />

                                        {
                                            (payments && payments.length > 0) &&
                                            <PaymentHistory
                                                width='408px'
                                                invoice={invoice}
                                                referralInfo={referralInfo}
                                                attachmentUrl={attachmentUrl}
                                            />
                                        }

                                        <AboutAion />

                                        <AICPA />
                                    </Skeleton>
                                </FlexColumn>
                            </Flex>
                        </>
                }

                <Modal
                    visible={this.state.showSignIn}
                    footer={null}
                    closable={true}
                    width={448}
                    // style={{ top: 20 }}
                    destroyOnClose={true}
                    onCancel={() => { this.setState({ showSignIn: false }) }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <SignInModal validationComplete={(registeredUser) => {
                        this.setState({ showSignIn: false })
                        console.log("validationComplete registeredUser", registeredUser)

                        this.getAccounts()
                    }} />
                </Modal>

                <AlertModal
                    success
                    visible={showSuccessModal}
                    title='Payment initiated'
                    description={`Your payment of $${toCurrency(lastPaidAmount)} for this invoice has been initiated. `}
                    buttonTitle='OKAY'
                    onConfirm={() => this.setState({ showSuccessModal: false })}
                />
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer,
        creditStore: state.creditAppReducer,
        onboardingStore: state.onboardingAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)));