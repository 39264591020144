import styled from 'styled-components'
import { Link } from "react-router-dom"
import React, { useState } from 'react'
import { Space, Tooltip } from 'antd'
import { EyeOutlined, LoadingOutlined, PaperClipOutlined } from '@ant-design/icons'
import { getPreSignedS3Url } from '../../Utils/api'
import { Flex } from './Container'

const viewComponent = <Flex center style={{ whiteSpace: 'nowrap' }}><EyeOutlined style={{ cursor: 'pointer', fontSize: 18, marginRight: "5px" }} /> View</Flex>

const pinComponent = <Flex center><PaperClipOutlined style={{ fontSize: 18 }} /></Flex>

export const SignedLink = (props) => {
    const [loading, setLoading] = useState(false)

    function handleClick() {
        setLoading(true)
        getPreSignedS3Url({ url: props.uri || props.url }, (err, preSignedUrl) => {
            setLoading(false)
            window.open(preSignedUrl, '_blank')
        })
    }
    const actionComponent = props.usePin ? pinComponent : viewComponent
    return (
        (props.uri || props.url) ?
            <Space>
                <Tooltip title={props.tooltip}> <a onClick={handleClick}>{props.showfilename ? props.fileName : actionComponent}</a> </Tooltip>
                {loading && <LoadingOutlined />}
            </Space >
            :
            null
    )
}

export const StyledLink = styled(Link)`
    color: ${props => props.theme.button.primary};
`;

export const StyledExtLink = styled.a`
    color: ${props => props.theme.button.primary};
`;
