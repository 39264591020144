import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { Space, Select, message, Input } from 'antd';
import { saveAs } from 'file-saver';
import moment from 'moment';
import _ from 'lodash';
import { FlexColumn, Container, Flex } from '../../Reusable/Container';
import PageHeader from '../../Reusable/PageHeader';
import { Button } from '../../Reusable/Button';
import { Image } from '../../Reusable/Image'

import InvoiceTable from './InvoiceTable';
import { apiPOSTReq } from '../../../Utils/api';
import environment from '../../../environment';
import { ErrorAlert } from '../../Reusable/Alert';
import { LabeledInput } from '../../Reusable/Input';
import { LoadingOutlined, FileExcelOutlined } from '@ant-design/icons';
import { Text} from '../../Reusable/Text';

const { Search } = Input;
const { Option } = Select;
var dFormat = "YYYY-MM-DD";

class Index extends Component {
    state = {
        loading: true,
        pagination: {
            current: 1,
            pageSize: 100,
            showSizeChanger: false,
        },
        sorter: {},
        sortDirection: "DESC",
        showClear: false,
        selectedRowsPageDict: {},
        selectedRowKeyPageDict: {},
        reviewerNote: '',
    };

    componentDidMount() {
        var { Profile, UserInfo } = this.props.aionStore;
        var { BusinessInfo } = Profile || {};
        BusinessInfo = BusinessInfo || {};
        this.setState({ ccAddr: UserInfo.Email, reminderTeamName: BusinessInfo.Name });
        this.handleDateTypeSelection("Year to Date");
    }

    onSearchChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
        this.setState({
            pagination: {
                current: 1,
                pageSize: 100
            }
        })
        this.fetchInvoices({ pagination: this.state.pagination,  searchTerm: e.target.value});
    }

    showSelectedInvoice = (selectedInvoice) => {
        this.setState({ selectedInvoice: selectedInvoice, showInvoiceModal: true })
    }

    fetchInvoices = (options) => {
        var { sortDirection, sortFieldname } = this.state
        var { pagination, fromDate, toDate, filterType, sorter, searchTerm, approvalFilterType} = options;
        filterType = filterType || [];
        approvalFilterType = approvalFilterType || null;
        sorter = sorter || {}

        if (!fromDate) fromDate = this.state.fromDate;
        if (!toDate) toDate = this.state.toDate;

        var body = {
            "size": pagination.pageSize,
            "page": pagination.current - 1,
            "source": "AION|ARUPLOAD|QUICKBOOKS|CODAT",
            "viewBy": "FILTER_SOURCE",
            // "sortDirection": sortDirection
        }

        if (sorter.field) {
            if (sorter.field === sortFieldname) {
                body.sortDirection = (sortDirection === "DESC") ? "ASC" : "DESC"
            } else {
                body.sortDirection = "ASC"
            }
            body.sortFieldname = sorter.field
        } else {
            if (sortFieldname) {
                body.sortFieldname = sortFieldname
            }
            body.sortDirection = sortDirection
        }

        if (fromDate) {
            body.viewBy = "FILTER_CREATE_DATE"//"FILTER_TXN_DATE"
            body.fromDate = fromDate;
            body.toDate = toDate
        }

        if (searchTerm && searchTerm != "") {
            body.searchStr = searchTerm
        }

        if (filterType.length > 0) {
            var emailConstants = ["Delivered", "Opened", "Clicked"];
            var invStatus = filterType.filter(item => !emailConstants.includes(item)).join("|");
            var emailStatus = filterType.filter(item => emailConstants.includes(item)).join("|");

            // Check if filtering by date
            if (filterType.find(item => emailConstants.includes(item))) {
                if(approvalFilterType) {
                    body.viewBy = (body.viewBy == "FILTER_CREATE_DATE") ? "FILTER_EMAIL_STATUS_CREDIT_STATUS_CREATE_DATE" : "FILTER_EMAIL_STATUS_AND_CREDIT_STATUS";
                } else {
                    body.viewBy = (body.viewBy == "FILTER_CREATE_DATE") ? "FILTER_EMAIL_STATUS_CREATE_DATE" : "FILTER_EMAIL_STATUS";
                }
            } else {
                if(approvalFilterType) {
                    body.viewBy = (body.viewBy == "FILTER_CREATE_DATE") ? "FILTER_STATUS_CREDIT_STATUS_CREATE_DATE" : "FILTER_STATUS_AND_CREDIT_STATUS"
                } else {
                    body.viewBy = (body.viewBy == "FILTER_CREATE_DATE") ? "FILTER_STATUS_CREATE_DATE" : "FILTER_STATUS"
                }
            };
            body.status = invStatus;
            body.emailStatus = emailStatus;
        } else {
            if(approvalFilterType) {
                body.viewBy = (body.viewBy == "FILTER_CREATE_DATE") ? "FILTER_CREDIT_STATUS_CREATE_DATE" : "FILTER_CREDIT_STATUS";
            }
        }
        if(approvalFilterType) {
            switch(approvalFilterType){
                case "Approved": 
                body.creditStatus = "VALIDATED";
                break;
                case "Pending": 
                body.creditStatus = "INREVIEW";
                break;
                case "Rejected": 
                body.creditStatus = "REJECTED";
                break;
            }
        }

        

        this.setState({ loading: true });

        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/getInvoices`, null, body, (err, resp) => {
            try {
                const data = resp || {};
                // console.log("/getInvoices", body, data)
                if (data.result) {
                    this.setState({
                        // ...options,
                        invoices: data.invoices,
                        loading: false,
                        pagination: {
                            ...pagination,
                            total: data.count
                        },
                        // sorter: _.isEqual(sorter, {}) ? this.state.sorter : sorter,
                        sortDirection: body.sortDirection,
                        sortFieldname: body.sortFieldname,
                        showClear: searchTerm ? true : false,
                    })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getInvoices", error, err, resp);
                ErrorAlert({ description: error.message })
            }
        })
    }

    downloadCSVReport = () => {
        let fetchOptions = {
            sortFieldDirection: 'DESC',
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            download: true,
            source: "AION|ARUPLOAD|QUICKBOOKS|CODAT"
        }

        this.setState({ loadingCsv: true })

        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/getcsvInvoices`, {}, fetchOptions, (err, resp) => {
            this.setState({ loadingCSV: false });
            try {
                const data = resp || {};
                if (data) {
                    const blob = new Blob([data.invoiceCsv], {
                        type: 'application/vnd.ms-excel',
                    });
                    const fileURL = URL.createObjectURL(blob);
                    saveAs(fileURL, `Invoice ${this.state.fromDate} to ${this.state.toDate}.csv`);
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/counterparty err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }
    handleDateTypeSelection = (val) => {
        var fromDate, toDate = null
        switch (val) {
            case "Year to Date":
                fromDate = moment().utc(moment()).startOf("year").format(dFormat)
                toDate = moment().utc(moment()).format(dFormat)
                break
            case "Last Year":
                fromDate = moment().utc(moment()).subtract(1, "year").startOf("year").format(dFormat)
                toDate = moment().utc(moment()).subtract(1, "year").endOf("year").format(dFormat)
                break
            case "Current Month":
                fromDate = moment().utc(moment()).startOf("month").format(dFormat)
                toDate = moment().utc(moment()).format(dFormat)
                break
            case "Previous Month":
                const prevMonth = moment().utc(moment()).subtract(1, "month")
                fromDate = prevMonth.startOf("month").format(dFormat)
                toDate = prevMonth.endOf("month").format(dFormat)
                break
            default:
                fromDate = this.state.fromDate
                toDate = this.state.toDate
                break
        }
        var updatedState = { fromDate: fromDate, toDate: toDate, dateType: val, loading: true }
        this.setState(updatedState);
        this.fetchInvoices({ ...this.state, ...updatedState })
    }


    handleDateRange = (dates) => {
        if(dates) {
            var updatedState = {
                fromDate: dates[0].format(dFormat),
                toDate: dates[1].format(dFormat),
                dateType: "Custom",
                loading: true
            };
            this.setState(updatedState);
            this.fetchInvoices({ ...this.state, ...updatedState });
        }
    }
    handleFilterSelection = (val) => {
        var updatedState = { filterType: val };
        console.log("handleFilterSelection", updatedState)
        this.setState(updatedState)
        this.fetchInvoices({ ...this.state, ...updatedState });
    }

    handleApprovalFilterSelection = (val) => {
        var updatedState = { approvalFilterType: val };
        console.log("handleApprovalFilterSelection", updatedState)
        this.setState(updatedState)
        this.fetchInvoices({ ...this.state, ...updatedState });
    }

    getSelectedRows = (selectedRowsPageDict) => {
        let selectedRows = []
        Object.values(selectedRowsPageDict).forEach(x => {
            selectedRows = selectedRows.concat(x)
        })
        return selectedRows
    }

    getSelectedRowKeys = (selectedRowKeyPageDict) => {
        let selectedRowKeys = []
        Object.values(selectedRowKeyPageDict).forEach(x => {
            selectedRowKeys = selectedRowKeys.concat(x)
        })
        return selectedRowKeys
    }

    handleRowSelection = (selectedRowKeys, selectedRows) => {
        let selectedRowsPageDict = this.state.selectedRowsPageDict;
        let selectedRowKeyPageDict = this.state.selectedRowKeyPageDict;

        selectedRowsPageDict[this.state.pagination.current] = selectedRows;
        selectedRowKeyPageDict[this.state.pagination.current] = selectedRowKeys;
        let newSelectedRows = this.getSelectedRows(selectedRowsPageDict);
        let newSelectedRowKeys = this.getSelectedRowKeys(selectedRowKeyPageDict);

        this.setState({ selectedRows: newSelectedRows, selectedRowKeys: newSelectedRowKeys, ponumber: null }, () => { 
                if(this.state.selectedRows.length == 1){
                    this.fetchPurchaseOrders()
                }
            }
        )
    }

    handleProcessInvoice = (invoicePaymentProcessMethod) => {
        if (!this.state.reviewerNote) {
            ErrorAlert({ description: "Please update reviewer notes before proceeding." });
            return;
        }

        const { UserInfo } = this.props.aionStore;
        const body = {
            invoiceDocNumberList: this.state.selectedRows.map(x => x.docNumber),
            invoicePaymentProcessMethod: invoicePaymentProcessMethod,
            reviewerNote: this.state.reviewerNote,
            "reviewer": `${UserInfo.FirstName} ${UserInfo.LastName} (${UserInfo.Email})`,
            "ponumber": this.state.ponumber
        }
        console.log('/ive/invoice/processPayment', body)
        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/processPayment`, null, body, (err, resp) => {
            try {
                const data = resp || {};
                if (data.result) {
                    message.success(`Invoice ${invoicePaymentProcessMethod}ed`);
                    this.fetchInvoices({ ...this.state })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getInvoices", error, err, resp);
                ErrorAlert({ description: error.message })
            }
        })
    }

    fetchPurchaseOrders = () =>{
        this.setState({ loadingPurchaseOrders: true })

        const body = {
            "BusinessId": this.props.aionStore.BusinessUniqueKey,
            "CustomerId": this.state.selectedRows[0].customerId
        }
        apiPOSTReq(`${environment.iveBaseUrl}/ive/po/getApprovedPOsForCustomer`, this.iveHeaders, body, (err, resp) => {
            try {
                const data = resp
                console.log("/ive/po/getApprovedPOsForCustomer", (data))
                if (data.result) {
                    // this.props.dispatch(addDataToStore(CREDIT_SAVE_DATA, data))
                    this.setState({ loadingPurchaseOrders: false, purchaseOrders: data.pos })
                } else {
                    throw Error("Could not fetch purchase orders.")
                }
            } catch (error) {
                console.log("ERRR", error.stack)
            }
        })
    }

    handleSelectPurchaseOrder = (value) => {
        this.setState({ ponumber: value })
    }

    render() {
        const { loading, pagination, invoices, dateType, fromDate, toDate, filterType, enablePaymentReminder, 
            selectedRows, selectedRowKeys, loadingPaymentReminder, ccAddr, reminderTeamName, sorter, searchTerm, 
            invoiceRefreshLoading, approvalFilterType, loadingCSV, loadingPurchaseOrders, purchaseOrders, ponumber } = this.state;
        const { theme, aionStore } = this.props;
        const { Pinned } = aionStore;

        var dropdownOptions = [];
        var dateOptions = ["Year to Date", "Current Month", "Previous Month", "Last Year", "Custom"];
        var statusFilterOptions = ["New", "Scheduled", "Pending", "Sent", "Canceled", "Delivered", "Opened", "Clicked"];
        var approvalFilterOptions = ["Approved", "Rejected", "Pending"];
        dropdownOptions = dateOptions.map(item => (
            <Option key={item} value={item}>
                {item}
            </Option>
        ));

        return (
            <>
                <FlexColumn style={{ margin: '0 40px' }}>
                    <PageHeader
                        titleText="Invoices"
                        desc="List of all your invoices"
                    />
                    <Container shadow style={{ margin: '24px 0' }}>

                    <FlexColumn>
                        <Space size="large">
                        <div style={{ width: Pinned ? 200 : 400, marginRight: 0 }}>
                            <FlexColumn >
                                <Text size='14px' color={theme.colors.secondary6} style={{ marginBottom: "3px" }}>Search</Text>
                                <Space>
                            <Search
                                placeholder="Search"
                                id="searchTerm"
                                key="searchTerm"
                                value={searchTerm}
                                size="large" allowClear
                                label="Search"
                                onSearch={() => this.fetchInvoices({ pagination: this.state.pagination, searchTerm: searchTerm})}
                                onChange={this.onSearchChange}
                                style={{ width: Pinned ? "200px" : "400px"}}
                            />
                            </Space>
                            </FlexColumn>
                            </div>
                            <LabeledInput
                                label="Date"
                                labelcolor={theme.colors.secondary3}
                                id="statementType"
                                key="statementType"
                                type="select"
                                placeholder="Select"
                                className="no-left-padding"
                                value={dateType}
                                onChange={this.handleDateTypeSelection}
                                style={{ width: "150px", fontSize: "0.95rem", fontWeight: 500 }}
                                noAsterisk
                            >
                                {dropdownOptions}
                            </LabeledInput>
                            
                            <LabeledInput
                                label="Filter Status"
                                labelcolor={theme.colors.secondary3}
                                id="filter"
                                key="filter"
                                type="select"
                                placeholder="Select"
                                className="no-left-padding"
                                mode="multiple"
                                allowClear
                                value={filterType}
                                onChange={this.handleFilterSelection}
                                style={{ width: "120px" }}
                                noAsterisk
                            >
                                {statusFilterOptions.map(item => (
                                    <Option key={item} value={item}>
                                        {item}
                                    </Option>
                                ))}
                            </LabeledInput>
                            
                            <LabeledInput
                                label="Approval Filter Status"
                                labelcolor={theme.colors.secondary3}
                                id="approvalFilter"
                                key="approvalFilter"
                                type="select"
                                placeholder="Select"
                                className="no-left-padding"
                                mode="single"
                                allowClear
                                value={approvalFilterType}
                                onChange={this.handleApprovalFilterSelection}
                                style={{ width: "120px" }}
                                noAsterisk
                            >
                                {approvalFilterOptions.map(item => (
                                    <Option key={item} value={item}>
                                        {item}
                                    </Option>
                                ))}
                            </LabeledInput>

                            <LabeledInput
                                nomargin
                                label="Date Range"
                                labelcolor={theme.colors.secondary3}
                                id="dateRange"
                                key="dateRange"
                                type="range-picker"
                                value={[moment(fromDate), moment(toDate)]}
                                format="MM/DD/YYYY"
                                onChange={this.handleDateRange}
                                style={{ width: "250px", fontSize: "1.1rem" }}
                                noAsterisk
                            />
                            <FlexColumn >
                            <Text size='14px' color={theme.colors.secondary3} style={{ marginBottom: "3px" }}>Download</Text>
                            <Space>
                                <FileExcelOutlined style={{ color: theme.colors.secondary3, fontSize: 20 }} onClick={this.downloadCSVReport} />
                                {loadingCSV && <LoadingOutlined style={{ color: theme.colors.secondary3, fontSize: 22 }} />}
                            </Space>
                        </FlexColumn>
                        </Space>

                        <FlexColumn>
                            {((selectedRows || []).length > 0) &&
                                <div>
                                    <Flex between fullWidth>
                                        <div style={{ width: "40%", marginRight: 12 }}>
                                            <LabeledInput
                                                label="Reviewer Notes"
                                                id="reviewerNote"
                                                type="text-area"
                                                placeholder="Enter reason for confirmation/rejection"
                                                onChange={(e) => this.setState({ reviewerNote: e.target.value })}
                                            />
                                        </div>
                                        {
                                            (selectedRows.length == 1) &&
                                            <div style={{ width: "40%", marginRight: 12 }}>
                                                <LabeledInput
                                                    label="Purchase Order"
                                                    labelcolor={theme.colors.secondary3}
                                                    id="ponumber"
                                                    key="ponumber"
                                                    type="select"
                                                    className="no-left-padding"
                                                    placeholder="Select Purchase Order"
                                                    value={ponumber}
                                                    loading={loadingPurchaseOrders}
                                                    onChange={this.handleSelectPurchaseOrder}
                                                    optional
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => {
                                                        return option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }}
                                                    dropdownRender={option => (
                                                        <div>
                                                            {option}
                                                        </div>
                                                    )}
                                                >
                                                    {(purchaseOrders || []).map(item =>
                                                        <Select.Option key={item.ponumber} id="ponumber" name={item.ponumber} value={item.ponumber} style={{ backgroundColor: "transparent" }}>
                                                            <Flex start fullWidth>
                                                                #{item.ponumber} 
                                                            </Flex>
                                                        </Select.Option>
                                                    )}

                                                </LabeledInput>
                                            </div>
                                        }
                                    </Flex>
                                    <div style={{ display: 'flex', marginTop: '20px' }}>
                                        <Button
                                            permtype="Override"
                                            onClick={() => this.handleProcessInvoice("Confirm")}
                                            style={{ height: 45, width: "250px", margin: "0 10px 10px 0" }}
                                            text='Confirm invoices' 
                                            disabled={!this.state.reviewerNote}
                                        />
                                        <Button
                                            permtype="Override"
                                            onClick={() => this.handleProcessInvoice("Reject")}
                                            style={{ height: 45, width: "250px", margin: "0 0 10px 0" }}
                                            text='Reject invoices' 
                                            disabled={!this.state.reviewerNote}
                                        />
                                    </div>
                                </div>
                            }
                        </FlexColumn>
                    </FlexColumn>

                    <InvoiceTable
                        showSelectedInvoice={this.showSelectedInvoice}
                        loading={loading}
                        pagination={pagination}
                        sorter={sorter}
                        fetchInvoices={this.fetchInvoices}
                        invoices={invoices}
                        handleRowSelection={this.handleRowSelection}
                        showRowSelection={true}
                        selectedRowKeys={selectedRowKeys}
                        hideEditColumn={true}
                    />
                    </Container>
                </FlexColumn>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)));
