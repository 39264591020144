import React, { Component } from 'react'
import { withTheme } from 'styled-components';
import moment from 'moment'
import {
    Divider,
    Row,
    Col,
    Card,
} from 'antd'

// Components
import { FlexColumn } from '../../Reusable/Container';
import { Paragraph } from '../../Reusable/Text';

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const StatViewCompact = (props) => {
    const { name, value, theme } = props;
    return (
        <FlexColumn style={{ marginTop: 5 }}>
            <Paragraph color={theme.colors.systemGray} style={{ fontSize: "0.9rem" }} noMargin>{name}</Paragraph>
            <Paragraph bold style={{ fontSize: "1.1rem" }} noMargin>{value}</Paragraph>
        </FlexColumn>
    )
}

class InvoiceSelectionCard extends Component {

    render() {
        var { invoice, theme } = this.props;
        invoice = invoice || {};
        return (
            <Card size='small' style={{minWidth: '100%', padding: "5px 20px"}}>
                <div>
                    <Row>
                        <Col span={8}><StatViewCompact {...this.props} name={"Invoice #"} value={invoice.DocNumber} /></Col>
                        <Col span={8}><StatViewCompact {...this.props} name={"Date"} value={moment((invoice.Sync || {}).CreateTime).format('MMM Do, YYYY')} /></Col>
                        <Col span={8}><StatViewCompact {...this.props} name={"Customer Name"} value={invoice.CustomerAncestorName || (invoice.CustomerReference ? invoice.CustomerReference.Name : "NA")} /></Col>
                    </Row>
                    <Row>
                        <Col span={8}><StatViewCompact {...this.props} name={"Total"} value={formatter.format(invoice.TotalAmt)} /></Col>
                        <Col span={8}><StatViewCompact {...this.props} name={"Balance"} value={formatter.format(invoice.Balance)} /></Col>
                        <Col span={8}><StatViewCompact {...this.props} name={"Due On"} value={moment(invoice.DueDate).format('MMM Do, YYYY')} /></Col>
                    </Row>
                    <Divider style={{margin: '4px 0'}} />
                    <Row>
                        <Col span={24}>
                            <StatViewCompact {...this.props} name={"Attachments"} valueStyle={{fontSize: '14px', fontWeight: 'normal'}} value={
                                <div className="flex-container flex-column">
                                    {
                                        (invoice.Attachments || []).map((attachment) => {
                                            return <a key={attachment.FileName} href={attachment.URI}>{attachment.FileName}</a>
                                        })
                                    }
                                </div> 
                            } />
                        </Col>
                    </Row>
                </div>
            </Card>
        )
    }
}

export default withTheme(InvoiceSelectionCard)
