import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled, { withTheme } from 'styled-components'
import { Tooltip, Checkbox, message, Table, Skeleton, Divider } from 'antd'

import { FlexColumn, Flex, CardContainer } from '../../../Reusable/Container'
import PageHeader from "../../../Reusable/PageHeader"
import { Text } from '../../../Reusable/Text'
import { Image } from '../../../Reusable/Image'
import { ErrorAlert } from '../../../Reusable/Alert'
import { LabeledInput } from '../../../Reusable/Refresh/Input'
import { getUserApps, getApplicationDisplayName, getBPAccountsWithAccess } from '../../../../Utils/util'
import { apiPOSTReq } from '../../../../Utils/api'
import environment from '../../../../environment'
import { Button } from '../../../Reusable/Refresh/Button'

import BankingInactiveIcon from "../../../../Images/product-menu/bank-menu-icon-active.png"
import CreditInactiveIcon from "../../../../Images/product-menu/credit-menu-icon-active.png"
import ContractorsInactiveIcon from "../../../../Images/product-menu/contractors-menu-icon-active.png"
import PayablesInactiveIcon from "../../../../Images/product-menu/payables-menu-icon-active.png"
import ReceivablesInactiveIcon from "../../../../Images/product-menu/receivables-menu-icon-active.png"
import CardsActiveIcon from "../../../../Images/product-menu/CardsActive.png"
import Info from '../../../../Images/info.png'

const permissionCodeStrings = ["View__Sys", "Manage__Sys", "Approval__Sys"]

class Index extends Component {

    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            submitLoading: false,
            permissions: {},
            resourcePermissions: {},
            allRoles: props.allRoles,
            applyToAllAccounts: true,
            accountPermissions: {},
            permissonChecked: false,
        }
    }

    componentDidMount() {
        var headers = {
        }

        apiPOSTReq(`${environment.uamBaseUrl}/business/admin/getAllApplications`, headers, {}, (err, resp) => {
            try {
                var data = resp || {}
                if (data.result) {
                    console.log('getAllApplications', data)
                    let applications = (data.applications || []).filter(x => x.applicationName !== 'Bookkeeping')

                    applications.sort((x, y) => { return x.containerName === 'TermLoans' ? -1 : y.containerName === 'TermLoans' ? 1 : 0; })
                    applications.sort((x, y) => { return x.containerName === 'ABLCredit' ? -1 : y.containerName === 'ABLCredit' ? 1 : 0; })
                    applications.sort((x, y) => { return x.containerName === 'ARCredit' ? -1 : y.containerName === 'ARCredit' ? 1 : 0; })
                    applications.sort((x, y) => { return x.containerName === 'Payables' ? -1 : y.containerName === 'Payables' ? 1 : 0; })
                    applications.sort((x, y) => { return x.containerName === 'Receivables' ? -1 : y.containerName === 'Receivables' ? 1 : 0; })
                    applications.sort((x, y) => { return x.containerName === 'BusinessCards' ? -1 : y.containerName === 'BusinessCards' ? 1 : 0; })
                    applications.sort((x, y) => { return x.containerName === 'BusinessBanking' ? -1 : y.containerName === 'BusinessBanking' ? 1 : 0; })

                    this.setState({ allApplications: applications })
                } else {
                    throw Error(data.error || data.responseMessage)
                }
            } catch (error) {
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            } finally {
                this.setState({ loading: false })
            }
        })

        if (this.props.aionStore.activeUsers) {
            console.log("this.props.aionStore.activeUsers: ", this.props.aionStore.activeUsers)
            this.setState({ activeUsers: this.props.aionStore.activeUsers })
        }
    }

    handleTextChange = (event) => {
        const target = event.target
        this.setState({ [target.id]: target.value })
    }

    handleBankingControlChange = (event) => {
        var { permissions } = this.state

        delete permissions.BusinessBanking

        this.setState({ applyToAllAccounts: !this.state.applyToAllAccounts, permissions, accountPermissions: {} })
    }

    handleSubmit = () => {
        var { roleName, description, permissions, accountPermissions } = this.state

        console.log("handleSubmit permissions: ", permissions)

        if (!roleName) {
            ErrorAlert({ description: "Please enter a name for your new role." })
            return
        } else if (roleName.length > 128) {
            ErrorAlert({ description: "The name for your new role must not exceed 128 characters." })
            return
        } else if (!description) {
            ErrorAlert({ description: "Please enter a description for your new role." })
            return
        } else if (Object.keys(permissions).length === 0 && Object.keys(accountPermissions).length === 0) {
            ErrorAlert({ description: "Please select at least one permission for your new role." })
            return
        }

        this.setState({ submitLoading: true })

        var createRoleRequestBody = {
            "BusinessId": this.props.store.BusinessUniqueKey
        }

        createRoleRequestBody.Role = {
            RoleName: this.state.roleName,
            Description: this.state.description,
        }

        var resourcePermissions = {}
        var keys = Object.keys(permissions)

        Object.values(permissions).map((permission, i) => {
            permissionCodeStrings.map(permissionCode => {
                if (permission[permissionCode]) {
                    permission[permissionCode].Resources.map(resource => {
                        var resourcePermission = resourcePermissions[`${keys[i]}-${resource}`] || {}

                        resourcePermission.ResourceName = resource
                        resourcePermission.NameSpace = keys[i]

                        var permissionCodes = resourcePermission.PermissionCodes || []
                        permissionCodes.push(permissionCode)

                        resourcePermission.PermissionCodes = permissionCodes

                        resourcePermissions[`${keys[i]}-${resource}`] = resourcePermission
                    })
                }
            })
        })

        if (Object.values(resourcePermissions).length > 0) createRoleRequestBody.ResourcePermissions = Object.values(resourcePermissions)

        var accountResourcePermissionArray = []

        Object.values(accountPermissions).forEach((accountPermission) => {
            var accountResourcePermissions = {
                accountId: accountPermission.account.accountId,
                accountName: accountPermission.account.accountName,
            }
            var arPermissions = {}

            permissionCodeStrings.forEach(permissionCode => {
                if (accountPermission.permissions[permissionCode]) {
                    accountPermission.permissions[permissionCode].Resources.map(resource => {
                        var resourcePermission = accountResourcePermissions[`BusinessBanking-${resource}`] || {}

                        resourcePermission.ResourceName = resource
                        resourcePermission.NameSpace = "BusinessBanking"

                        var permissionCodes = resourcePermission.PermissionCodes || []
                        permissionCodes.push(permissionCode)

                        resourcePermission.PermissionCodes = permissionCodes

                        arPermissions[`BusinessBanking-${resource}`] = resourcePermission
                    })
                }
            })

            accountResourcePermissions.ResourcePermissions = Object.values(arPermissions)
            accountResourcePermissionArray.push(accountResourcePermissions)
        })

        if (accountResourcePermissionArray.length > 0) createRoleRequestBody.AccountResourcePermissions = accountResourcePermissionArray

        console.log("uam/saveRole", JSON.stringify(createRoleRequestBody))

        apiPOSTReq(`${environment.uamBaseUrl}/uam/saveRole`, {}, createRoleRequestBody, (err, resp) => {
            this.setState({ submitLoading: false })
            try {
                var data = resp || {}

                if (err) throw Error(err)
                if (!resp.result) throw Error(resp.responseMessage || resp.error)

                message.success(`${this.state.roleName} role created successfully!`)
                this.props.history.replace('/settings/user-access')
            } catch (error) {
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    isPermissionChecked = () => {
        var { permissions, accountPermissions } = this.state
        console.log('isPermissionCheckedaccountPermissions', accountPermissions)
        var permissonChecked = false

        Object.keys(permissions).forEach(pKey => {
            Object.keys(permissions[pKey]).forEach(appKey => {
                console.log('isPermissionChecked', permissions[pKey][appKey].Resources)
                if (permissions[pKey][appKey].Resources && permissions[pKey][appKey].Resources.length > 0) permissonChecked = true
            })
        })

        Object.values(accountPermissions).forEach((accountPermission) => {
            Object.keys(accountPermission.permissions).forEach(pKey => {
                Object.keys(accountPermission.permissions[pKey]).forEach(appKey => {
                    console.log('isPermissionChecked accountPermission', accountPermission.permissions[pKey].Resources)
                    if (accountPermission.permissions[pKey].Resources && accountPermission.permissions[pKey].Resources.length > 0) permissonChecked = true
                })
            })
        })

        this.setState({ permissonChecked })
    }

    handlePermissionToggle = (checked, name, application, permission) => {

        console.log('handlePermissionToggle permissions: checked ', checked)

        var { permissions } = this.state
        var appPermissions = permissions[application] || {}

        var data

        if (appPermissions[permission]) {
            var resources = appPermissions[permission].Resources
            if (checked === true && !resources.includes(name)) {
                resources.push(name)
            }

            if (checked === false && resources.includes(name)) {
                if (permission === 'Manage__Sys' && (["Payments", "Recipients", "Bill Payments", "Contractor Payments"].includes(name))) {
                    if (!appPermissions['Approval__Sys']) {
                        resources = resources.filter(resource => resource !== name)
                    } else if (!appPermissions['Approval__Sys'].Resources.includes(name)) {
                        resources = resources.filter(resource => resource !== name)
                    }
                } else if (permission === 'View__Sys') {
                    if ((!appPermissions['Manage__Sys'] && (!appPermissions['Approval__Sys']))) {
                        resources = resources.filter(resource => resource !== name)
                    } else if ((["Payments", "Recipients", "Bill Payments", "Contractor Payments"].includes(name))) {
                        if (appPermissions['Approval__Sys']) {
                            if (!appPermissions['Approval__Sys'].Resources.includes(name)) {
                                if (appPermissions['Manage__Sys']) {
                                    if (!appPermissions['Manage__Sys'].Resources.includes(name)) {
                                        resources = resources.filter(resource => resource !== name)
                                    }
                                }
                            }
                        } else {
                            if (appPermissions['Manage__Sys']) {
                                if (!appPermissions['Manage__Sys'].Resources.includes(name)) {
                                    resources = resources.filter(resource => resource !== name)
                                }
                            }
                        }

                    } else {
                        if (!appPermissions['Manage__Sys']) {
                            resources = resources.filter(resource => resource !== name)
                        } else if (!appPermissions['Manage__Sys'].Resources.includes(name)) {
                            resources = resources.filter(resource => resource !== name)
                        }
                    }
                } else {
                    resources = resources.filter(resource => resource !== name)
                }
            }

            data = {
                Resources: resources,
            }
        } else {
            data = {
                Resources: [name],
            }
        }

        appPermissions[permission] = data
        permissions[application] = appPermissions

        this.setState({ permissions }, () => {
            if (checked) {
                if (permission === 'Approval__Sys') {
                    this.handlePermissionToggle(checked, name, application, 'Manage__Sys')
                    this.handlePermissionToggle(checked, name, application, 'View__Sys')
                }

                if (permission === 'Manage__Sys') {
                    this.handlePermissionToggle(checked, name, application, 'View__Sys')
                }
            }
            this.isPermissionChecked()
        })

        console.log('handlePermissionToggle permissions: ', permissions)
    }

    handleAccountPermissionToggle = (checked, name, application, permission, account) => {

        console.log('handleAccountPermissionToggle permissions: checked ', checked)

        var { accountPermissions } = this.state
        var appPermissions = (accountPermissions[account.accountId] || {}).permissions || {}

        var data

        if (appPermissions[permission]) {
            var resources = appPermissions[permission].Resources
            if (checked === true && !resources.includes(name)) {
                resources.push(name)
            }

            if (checked === false && resources.includes(name)) {
                if (permission === 'Manage__Sys' && (["Payments", "Recipients", "Bill Payments", "Contractor Payments"].includes(name))) {
                    if (!appPermissions['Approval__Sys']) {
                        resources = resources.filter(resource => resource !== name)
                    } else if (!appPermissions['Approval__Sys'].Resources.includes(name)) {
                        resources = resources.filter(resource => resource !== name)
                    }
                } else if (permission === 'View__Sys') {
                    if ((!appPermissions['Manage__Sys'] && (!appPermissions['Approval__Sys']))) {
                        resources = resources.filter(resource => resource !== name)
                    } else if ((["Payments", "Recipients", "Bill Payments", "Contractor Payments"].includes(name))) {
                        if (appPermissions['Approval__Sys']) {
                            if (!appPermissions['Approval__Sys'].Resources.includes(name)) {
                                if (appPermissions['Manage__Sys']) {
                                    if (!appPermissions['Manage__Sys'].Resources.includes(name)) {
                                        resources = resources.filter(resource => resource !== name)
                                    }
                                }
                            }
                        } else {
                            if (appPermissions['Manage__Sys']) {
                                if (!appPermissions['Manage__Sys'].Resources.includes(name)) {
                                    resources = resources.filter(resource => resource !== name)
                                }
                            }
                        }

                    } else {
                        if (!appPermissions['Manage__Sys']) {
                            resources = resources.filter(resource => resource !== name)
                        } else if (!appPermissions['Manage__Sys'].Resources.includes(name)) {
                            resources = resources.filter(resource => resource !== name)
                        }
                    }
                } else {
                    resources = resources.filter(resource => resource !== name)
                }
            }

            data = {
                Resources: resources,
            }
        } else {
            data = {
                Resources: [name],
            }
        }

        appPermissions[permission] = data

        accountPermissions[account.accountId] = {
            account: account,
            permissions: appPermissions,
        }

        this.setState({ accountPermissions }, () => {
            if (checked) {
                if (permission === 'Approval__Sys') {
                    this.handleAccountPermissionToggle(checked, name, application, 'Manage__Sys', account)
                    this.handleAccountPermissionToggle(checked, name, application, 'View__Sys', account)
                }

                if (permission === 'Manage__Sys') {
                    this.handleAccountPermissionToggle(checked, name, application, 'View__Sys', account)
                }
            }
            this.isPermissionChecked()
        })

        console.log('handleAccountPermissionToggle accountPermissions: ', accountPermissions)
    }

    getColumns = (app, account) => {
        var { permissions, accountPermissions, applyToAllAccounts } = this.state

        const tableColumns = [{
            title: "Permission",
            dataIndex: 'label',
            width: '100%',
            render: (label, record) => <FlexColumn>
                <Text weight={500}>{(app.applicationName === 'BusinessBanking' && label === 'Payments') ? 'Transfers' : label}</Text>
                {
                    record.description &&
                    <Text size='14px'>{record.description}</Text>
                }
            </FlexColumn>,
        }]

        if (getApplicationDisplayName(app.applicationName) === "Banking" || getApplicationDisplayName(app.applicationName) === "Payables" || getApplicationDisplayName(app.applicationName) === "Bill Payments") {
            tableColumns.push({
                title: <Flex style={{ marginLeft: 22 }} start centerHorizontally gap='4px'>
                    <Text>Approve</Text>
                    <Tooltip overlayInnerStyle={{ width: app.applicationName === 'BusinessBanking' ? 250 : 160, minWidth: app.applicationName === 'BusinessBanking' ? 250 : 160 }} overlayStyle={{ width: app.applicationName === 'BusinessBanking' ? 270 : 180, minWidth: app.applicationName === 'BusinessBanking' ? 270 : 180 }} overlayClassName='roles-tooltip' placement="right" arrowPointAtCenter={false} title={<FlexColumn>
                        <Text color='white' size='14px' noWrap>{app.applicationName === 'BusinessBanking' ? 'Approve new recipients and transfers' : 'Approve bill payments'}</Text>
                    </FlexColumn>}>
                        <FlexColumn>
                            <Image width='18px' height='18px' src={Info} />
                        </FlexColumn>
                    </Tooltip>
                </Flex>,
                dataIndex: 'name',
                align: 'center',
                width: '120px',
                render: (name, record) => {
                    if (["Payments", "Recipients", "Contractor Payments", "Bill Payments", "Transfers" ].includes(name)) {
                        if (app.applicationName === "BusinessBanking" && !applyToAllAccounts) {
                            return <Checkbox
                                checked={((((accountPermissions[account.accountId] || {}).permissions || {}).Approval__Sys || {}).Resources || []).includes(name)}
                                onChange={(event) => this.handleAccountPermissionToggle(event.target.checked, name, app.applicationName, "Approval__Sys", account)}
                            />
                        } else {
                            return <Checkbox
                                checked={(((permissions[app.applicationName] || {}).Approval__Sys || {}).Resources || []).includes(name)}
                                onChange={(event) => this.handlePermissionToggle(event.target.checked, name, app.applicationName, "Approval__Sys")}
                            />
                        }
                    } else {
                        return null
                    }
                },
            })
        }

        tableColumns.push({
            title: <Flex style={{ marginLeft: 22 }} start centerHorizontally gap='4px'>
                <Text>Manage</Text>
                <Tooltip overlayInnerStyle={{ width: 130, minWidth: 130 }} overlayStyle={{ width: 150, minWidth: 150 }} overlayClassName='roles-tooltip' placement="right" arrowPointAtCenter={false} title={<FlexColumn>
                    <Text color='white' size='14px' noWrap>Perform actions</Text>
                </FlexColumn>}>
                    <FlexColumn>
                        <Image width='18px' height='18px' src={Info} />
                    </FlexColumn>
                </Tooltip>
            </Flex>,
            dataIndex: 'name',
            align: 'center',
            width: '120px',
            render: (name, record) => {
                if (app.applicationName === "BusinessBanking" && !applyToAllAccounts) {
                    return <Checkbox
                        checked={((((accountPermissions[account.accountId] || {}).permissions || {}).Manage__Sys || {}).Resources || []).includes(name)}
                        onChange={(event) => this.handleAccountPermissionToggle(event.target.checked, name, app.applicationName, "Manage__Sys", account)}
                    />
                } else {
                    return <Checkbox
                        checked={(((permissions[app.applicationName] || {}).Manage__Sys || {}).Resources || []).includes(name)}
                        onChange={(event) => this.handlePermissionToggle(event.target.checked, name, app.applicationName, "Manage__Sys")}
                    />
                }
            },
        })
        tableColumns.push({
            title: <Flex style={{ marginLeft: 22 }} start centerHorizontally gap='4px'>
                <Text>View</Text>
                <Tooltip overlayInnerStyle={{ width: 200, minWidth: 200 }} overlayStyle={{ width: 220, minWidth: 220 }} overlayClassName='roles-tooltip' placement="right" arrowPointAtCenter={false} title={<FlexColumn>
                    <Text color='white' size='14px' noWrap>View or download information</Text>
                </FlexColumn>}>
                    <FlexColumn>
                        <Image width='18px' height='18px' src={Info} />
                    </FlexColumn>
                </Tooltip>
            </Flex>,
            dataIndex: 'name',
            align: 'center',
            width: '120px',
            render: (name, record) => {
                if (app.applicationName === "BusinessBanking" && !applyToAllAccounts) {
                    return <Checkbox
                        checked={((((accountPermissions[account.accountId] || {}).permissions || {}).View__Sys || {}).Resources || []).includes(name)}
                        onChange={(event) => this.handleAccountPermissionToggle(event.target.checked, name, app.applicationName, "View__Sys", account)}
                    />
                } else {
                    return <Checkbox
                        checked={(((permissions[app.applicationName] || {}).View__Sys || {}).Resources || []).includes(name)}
                        onChange={(event) => this.handlePermissionToggle(event.target.checked, name, app.applicationName, "View__Sys")}
                    />
                }
            },
        })

        return tableColumns
    }

    render() {
        const { submitLoading, applyToAllAccounts, allApplications, loading, description, roleName, accountPermissions, permissonChecked } = this.state
        const { store, aionStore, theme, pageTitle } = this.props
        const userApps = getUserApps(aionStore)
        var Accounts = getBPAccountsWithAccess("Transactions", { getActiveAccounts: true })
        Accounts = Accounts || []

        return (
            <FlexColumn className='main-padding'>
                <PageHeader
                    titleText="New Custom Role"
                    close
                />

                <CardContainer margin='0 0 48px'>
                    <Flex start fullWidth gap='24px'>
                        <LabeledInput
                            autoFocus
                            labelcolor={this.props.theme.colors.secondary3}
                            label="Role Name"
                            id="roleName"
                            width='284px'
                            margin='0 0 24px'
                            onChange={this.handleTextChange}
                            placeholder="Enter name of role"
                            maxLength={128}
                            noAsterisk
                        />
                        <LabeledInput
                            labelcolor={this.props.theme.colors.secondary3}
                            label="Description"
                            id="description"
                            width='500px'
                            margin='0 0 24px'
                            onChange={this.handleTextChange}
                            placeholder="Enter description"
                            maxLength={1024}
                            noAsterisk
                        />
                    </Flex>

                    <Skeleton loading={loading} active title={true} paragraph={{ rows: 23 }}>
                        {
                            allApplications && allApplications.map((app, index) => {
                                if (userApps.includes(app.applicationName) && app.resources) {
                                    var data = []
                                    var approveData = []

                                    app.resources.map(resource => {
                                        console.log('CreateRole permission column resource', resource)
                                        data.push({
                                            key: resource.resourceName,
                                            name: resource.resourceName,
                                            label: resource.resourceLabel,
                                            description: resource.description,
                                        })

                                        if (resource.resourceName === "Payments" || resource.resourceName === "Recipients" ||
                                            resource.resourceName === "Bill Payments" || resource.resourceName === "Contractor Payments") {
                                            approveData.push({
                                                key: resource.resourceName,
                                                name: resource.resourceName,
                                                label: resource.resourceLabel,
                                                description: resource.description,
                                            })
                                        }
                                    })



                                    return <FlexColumn fullWidth>
                                        {
                                            index > 0 &&
                                            <Divider />
                                        }

                                        <Flex between>
                                            <Flex start>
                                                {
                                                    (() => {
                                                        switch (app.applicationName) {
                                                            case 'BusinessBanking':
                                                                return <Image src={BankingInactiveIcon} margin='0 8px 0' />
                                                            case 'ARCredit':
                                                            case 'ABLCredit':
                                                            case 'TermLoans':
                                                                return <Image src={CreditInactiveIcon} margin='0 8px 0' />
                                                            case 'Payables':
                                                                return <Image src={PayablesInactiveIcon} margin='0 8px 0' />
                                                            case 'Receivables':
                                                                return <Image src={ReceivablesInactiveIcon} margin='0 8px 0' />
                                                            case 'BusinessCards':
                                                                return <Image src={CardsActiveIcon} margin='0 8px 0' />
                                                            default:
                                                                return null
                                                        }
                                                    })()
                                                }

                                                <Text margin='0 0 16px' caption>{getApplicationDisplayName(app.applicationName).toUpperCase()}</Text>
                                            </Flex>

                                            {
                                                app.applicationName === "BusinessBanking" &&
                                                <Checkbox checked={applyToAllAccounts} onChange={() => this.handleBankingControlChange()}>Apply to all accounts</Checkbox>
                                            }
                                        </Flex>

                                        {
                                            app.applicationName === "BusinessBanking" ?
                                                <>
                                                    {
                                                        applyToAllAccounts ?
                                                            <Table
                                                                className='borderless-table'
                                                                columns={this.getColumns(app)}
                                                                dataSource={data}
                                                                pagination={false}
                                                            />
                                                            :
                                                            <>
                                                                {
                                                                    Accounts.map((account, i) => {
                                                                        return <>
                                                                            {
                                                                                i > 0 &&
                                                                                <Divider />
                                                                            }
                                                                            <Text weight='500' margin='0 0 24px'>{`${(account.nickName || `Business ${account.accountSubType}`)} • ${account.mask}`}</Text>
                                                                            <Table
                                                                                className='borderless-table'
                                                                                columns={this.getColumns(app, account)}
                                                                                dataSource={data}
                                                                                pagination={false}
                                                                            />
                                                                        </>
                                                                    })
                                                                }
                                                            </>
                                                    }
                                                </>
                                                :
                                                <Table
                                                    className='borderless-table'
                                                    columns={this.getColumns(app)}
                                                    dataSource={data}
                                                    pagination={false}
                                                />
                                        }

                                    </FlexColumn>
                                }
                            })
                        }
                    </Skeleton>

                    <Button
                        style={{ alignSelf: 'center', width: 119, marginTop: 48 }}
                        loading={submitLoading}
                        solid
                        onClick={this.handleSubmit}
                        permtype="Override"
                        text='CONFIRM'
                        disabled={!roleName || !description || !permissonChecked}
                    />
                </CardContainer>
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.bankingAppReducer,
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Index))