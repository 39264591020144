import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components';
import { Menu, message, Tabs, Dropdown, Modal, Tooltip } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons'

// Components
import { Flex, FlexColumn } from '../../Reusable/Container';
import PageHeader from '../../Reusable/PageHeader';
import BusinessDocuments from '../../Reusable/BusinessDocuments';
import Summary from './Summary/Index';
import Conversation from './Conversation/Index';
import BizDocsTable from './BusinessDocuments/Index';
import VerificationResults from './VerificationResults/Index';
import ProposalTerms from './ProposalTerms/Index';
import ReviewLog from './ReviewLog/Index';
import { Text, Tag } from '../../Reusable/Text';
import { TextButton, Button } from '../../Reusable/Button';
import { Image } from '../../Reusable/Image';
import File from "../../../Images/file.png"
import Copy from "../../../Images/copy.png"
import Link from "../../../Images/link.png"
import Switch from '../../../Images/switch.png';
import moment from 'moment';
import { apiPOSTReq } from '../../../Utils/api';
import environment from '../../../environment';
import { ErrorAlert } from '../../Reusable/Alert';
import Contracts from './Contracts/Index';
import Note from './Note/Index';
import ApplicationRequest from './ApplicationRequest'
import Check from '../../../Images/check-green.png'
import CheckBlue from '../../../Images/check-blue.png'
import ModalClose from '../../../Images/modal-close.png'
import Banner from '../../Reusable/Banner'
import Info from '../../../Images/info-square.png'

class Index extends Component {

    state = {
        selectedTab: "Summary",
        documentsCompKey: "initial-doc-tab-key",
        showRequestModal: false,
        showLinkCopyModal: false,
        showRequestedTab: false,
        uploadLink: "",
        showSuccessBanner: false,
        hasActiveLoanApplicationRequest: false,
        pagination: {
            current: 1,
            pageSize: 100
        }
    }

    componentDidMount() {
        this.fetchBusinessData();
        this.fetchLoanApplication();
        this.fetchActiveRequests({ pagination: this.state.pagination });
        this.fetchCodatCompany();
    }

    fetchActiveRequests = (options) => {
        const { loanApp } = this.props.location.state;
        const { pagination } = options || {};
        var body = {
            "size": pagination.pageSize,
            "page": pagination.current - 1,
            "sortDirection": "DESC"
        };
        if (loanApp) {
            body["BusinessId"] = loanApp.businessId;
            body["applicationId"] = loanApp.applicationId;
            body["uploadCategory"] = "Custom Upload"
        }

        console.log("/lsm/getActiveLoanRequest body", body);
        this.setState({ loading: true });
        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/getActiveLoanRequest`, null, body, (err, resp) => {
            console.log("/lsm/getActiveLoanRequest resp", resp);
            this.setState({ loading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    this.setState({ requests: data.requests });
                    if (data.requests.length == 0) {
                        this.setState({ showRequestedTab: false })
                    } else {
                        this.setState({ showRequestedTab: true })
                    }
                    // this.fetchDocuments();
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    onChangeTab = (selectedTab) => {
        this.setState({ selectedTab: selectedTab, openVerification: null });
        if (selectedTab === "Customers") {
            this.fetchTopCustomers();
        }
    }

    onChangeTabSummary = (selectedTab, openVerification) => {
        this.setState({ selectedTab: selectedTab, openVerification: openVerification });
    }

    fetchBusinessData = () => {
        const { loanApp } = this.props.location.state;
        var body = {
            "clientBusinessId": loanApp.businessId
        };
        console.log("/business/admin/getBusinessData body", body);
        this.setState({ loading: true });
        apiPOSTReq(`${environment.uamBaseUrl}/business/admin/getBusinessData`, null, body, (err, resp) => {
            this.setState({ loading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    this.setState({
                        business: data.business
                    });
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    validateAll = () => {
        const { loanApp } = this.props.location.state;
        var body = {
            clientBusinessId: loanApp.businessId,
            applicationId: loanApp.applicationId
        }
        console.log("validateAll body", body);
        this.setState({ validateLoading: true });
        apiPOSTReq(`${environment.opsBaseUrl}/ops/loans/application/validateAll`, null, body, (err, resp) => {
            this.setState({ validateLoading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    this.setState({ documentsCompKey: `${moment().toISOString()}-validated` })
                    message.success("Successfully validated");
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    // Top Customers

    fetchTopCustomers() {
        var { loanApp } = this.props.location.state;
        loanApp = loanApp || {};
        const body = {
            "clientBusinessId": loanApp.businessId,
            "applicationId": loanApp.applicationId,
            "size": 10,
            "page": 0
        }
        this.setState({ customersLoading: true })
        apiPOSTReq(`${environment.opsBaseUrl}/ops/loans/application/getTopCustomers`, {}, body, (err, resp) => {
            try {
                const data = resp;
                console.log("/getTopCustomers", body, data)
                if (data.result) {
                    this.setState({
                        customers: data.customers,
                        customersLoading: false
                    })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getTopCustomers", error, err, resp);
                ErrorAlert({ description: error.message })
            }
        })
    }

    fetchLoanApplication = () => {
        const { loanApp } = this.props.location.state;
        var body = {
            clientBusinessId: loanApp.businessId,
            applicationId: loanApp.applicationId
        }
        console.log("loanApplication body", body);
        this.setState({ validateLoading: true });
        apiPOSTReq(`${environment.opsBaseUrl}/ops/loans/application/getApplication`, null, body, (err, resp) => {
            this.setState({ validateLoading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    console.log(data.loanApplication);
                    if (data.loanApplication) {
                        var applicationStatus = data.loanApplication.preScreenStatus == 'Proposal Accepted' ? data.loanApplication.preScreenStatus : data.loanApplication.underwritingStatus
                        this.setState({ applicationStatus: applicationStatus });
                        this.fetchProposalTerms();
                    }
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    fetchProposalTerms = () => {
        const { loanApp } = this.props.location.state;
        var body = {
            "clientBusinessId": loanApp.businessId,
            "applicationId": loanApp.applicationId,
        };
        var underWritingStatus = ["Processing", "Processing Error", "Manual Review", "Pre Review"]
        if (underWritingStatus.includes(this.state.applicationStatus)) {
            return;
        }

        console.log("/lsm/getProposalTerms body", body, loanApp);
        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/getProposalTerms`, null, body, (err, resp) => {
            this.setState({ loanAppLoading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    console.log(data);
                    this.setState({
                        "proposalTerms": data.offers[0]
                    });
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    closeRequest = () => {
        this.setState({ showRequestModal: false })
    }

    generateLink = () => {
        var { loanApp } = this.props.location.state;
        const { UserInfo } = this.props.store
        var username = UserInfo.Email

        var activeRequests = this.state.requests;

        if (activeRequests.length > 0) {
            this.setState({ hasActiveLoanApplicationRequest: true });
        } else {

            var requests = [];
            requests.push({
                type: "Document",
                status: 'InProgress',
                createdBy: username,
                lastUpdatedBy: username,
                documentType: "",
                period: "",
                fromDate: "",
                toDate: "",
                message: "",
                parentRequestId: false,
                allowMultipleUpload: true,
                uploadCategory: "Custom Upload"
            })

            var body = {
                applicationId: loanApp.applicationId,
                BusinessId: loanApp.businessId,
                requests: requests,
                appendRequest: false,
                generateLink: true
            }
            this.setState({ loading: true });
            console.log('Application Requests Body', body);
            apiPOSTReq(`${environment.lsmBaseUrl}/lsm/createLoanRequest`, null, body, (err, resp) => {
                this.setState({ loading: false });
                try {
                    const data = resp || {};
                    if (data.result) {
                        console.log(data);
                        this.setState({
                            uploadLink: data.doUploadLink,
                            loading: false,
                            showLinkCopyModal: true,
                            showRequestedTab: true
                        });
                        navigator.clipboard.writeText(data.doUploadLink)
                        this.fetchActiveRequests({ pagination: this.state.pagination });
                    } else {
                        throw Error(data.responseMessage || data.error)
                    }
                } catch (error) {
                    console.log("/createLoanRequest err", error, resp);
                    ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
                    this.setState({
                        loading: false
                    });
                }
            });

        }

    }

    openCodat = () => {
        const { codatCompany } = this.state;
        var url = 'https://app.codat.io/companies/' + codatCompany.companyId + '/summary';
        var win = window.open(url, '_blank');
    }

    fetchCodatCompany = () => {
        const { loanApp } = this.props.location.state;
        var body = {
            "BusinessId": loanApp.businessId
        };

        console.log("/integration/getProposalTerms body", body, loanApp);
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/codat/getCompany`, {}, body, (err, resp) => {
            this.setState({ loanAppLoading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    this.setState({ codatCompany: data.company })
                } else {
                    this.setState({ codatCompany: null})
                }
            } catch (error) {
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    closeCopyModal = () => {
        this.setState({ showLinkCopyModal: false, showSuccessBanner: true });
        setTimeout(() => {
            this.setState({ showSuccessBanner: false })
        }, 6000)
    }

    closeActiveRequestAlertModal = () => {
        this.setState({ hasActiveLoanApplicationRequest: false, showSuccessBanner: true });
        setTimeout(() => {
            this.setState({ showSuccessBanner: false })
        }, 6000)
    }

    refreshInfoRequestedTag = (refresh) => {
        this.setState({ showRequestedTab: refresh })
        this.fetchActiveRequests({ pagination: this.state.pagination })
    }

    getTabContent() {
        const { proposalTerms } = this.state;
        return (
            <>
                {
                    proposalTerms && proposalTerms.creditLimit < 10001 && <Tooltip title="Proposal terms is unable to be calculated as the business does not have sufficient revenue or collateral to qualify for minimum terms of $10.000">
                        {
                            "Proposal Terms "
                        }
                        <ExclamationCircleFilled style={{ color: 'orange' }} />
                    </Tooltip>
                }
                {
                    ((proposalTerms && proposalTerms.creditLimit > 10000) || !proposalTerms) && <>Proposal Terms</>
                }
            </>
        )

    }

    render() {
        let { location, theme } = this.props;
        const { loanApp, appType } = location.state;
        const { selectedTab, documentsCompKey, business, applicationStatus, showRequestModal, requestType, showLinkCopyModal, hasActiveLoanApplicationRequest, codatCompany, openVerification } = this.state;
        var businessProfile = (business || {}).businessProfile || {};
        var businessInfo = businessProfile.businessInfo || {};
        const { parentCompanyName, naics6 } = businessInfo || {};
        const disableStates = [
            "Proposal Ready", "Proposal Issued", "Proposal Accepted", "Proposal Declined", "Manually Rejected", "Proposal Expired"
        ];

        return (
            <FlexColumn className='main-left-padding main-top-padding' style={{ margin: 0 }}>
                <FlexColumn style={{ margin: '0 40px' }}>
                    <PageHeader
                        back
                        backText='Pre Screen'
                        titleText={<div> {loanApp.businessName} | {`Application ID: ${loanApp.applicationId}`} {this.state.showRequestedTab ? <Tag height="40px" textsize="1.25rem" style={{ color: "#1199FF", background: "#F5F6FE" }} > Additional Info Requested </Tag> : null} </div>}
                        subtitle={<div> {`Application ID: ${loanApp.applicationId}`}   </div>}
                    />
                    <Tabs
                        defaultActiveKey="1"
                        activeKey={selectedTab}
                        onChange={this.onChangeTab}
                        tabBarExtraContent={
                            <Flex between centerHorizontally>
                                <Text heding margin="10px 0">{`Application Status: `}
                                    <Tag height="40px" textsize="1.25rem" tagpreset={theme.colors.secondary4}>{`${applicationStatus ?
                                        appType == "prescreen" ? (applicationStatus == "Approved" || applicationStatus == "Rejected" || applicationStatus == 'Not Started') ? loanApp.preScreenStatus : applicationStatus : applicationStatus
                                            || "Not Started" :
                                        appType == "prescreen" ? (loanApp.underwritingStatus == "Approved" || loanApp.underwritingStatus == "Rejected" || loanApp.underwritingStatus == 'Not Started') ? loanApp.preScreenStatus : loanApp.underwritingStatus : loanApp.underwritingStatus}`}</Tag>
                                </Text>
                                <Flex start centerHorizontally gap='8px' style={{ marginLeft: 30 }}>
                                    <Dropdown
                                        // trigger={['click']}
                                        placement="bottomRight"
                                        overlay={
                                            <Menu style={{ padding: 24, borderRadius: 8 }}>
                                                <FlexColumn gap='18px'>
                                                    {/*<TextButton margin='0' onClick={() => { this.setState({ showRequestModal: true, requestType: 'Document' }) }}  text="DOCUMENTS" /> */}
                                                    <TextButton margin='0' onClick={this.generateLink} rightIcon={<Image src={Link} />} text="GENERATE UPLOAD LINK" />
                                                </FlexColumn>
                                                {
                                                    codatCompany && <FlexColumn style={{ marginTop: 20 }} gap='18px'>
                                                        <TextButton margin='0' onClick={this.openCodat} rightIcon={<Image src={Switch} />} text="OPEN CODAT" />
                                                    </FlexColumn>
                                                }
                                            </Menu>
                                        }
                                    >
                                        <TextButton text='REQUEST' dropdown />
                                    </Dropdown>
                                </Flex>
                            </Flex>
                        }
                    >
                        <Tabs.TabPane tab="Summary" key="Summary">
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Documents" key="Documents">
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Conversation History" key="Conversation">
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Verification Results" key="Verification">
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Review Log" key="ReviewLog">
                        </Tabs.TabPane>
                        <Tabs.TabPane disabled={!disableStates.includes(applicationStatus)} tab={this.getTabContent()} key="Proposal">
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Contracts" key="Contracts">
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Internal Notes" key="Note">
                        </Tabs.TabPane>
                    </Tabs>
                    {
                        (selectedTab == "Summary") ?
                            <Summary key={documentsCompKey} loanApp={loanApp} changeTabClick={this.onChangeTabSummary} />
                            : (selectedTab == "Documents") ?
                                (appType == "prescreen" || appType == "duediligence") ?
                                    <BizDocsTable key={documentsCompKey} loanApp={loanApp} ucView={true}
                                        changeInfoRequestedTag={this.refreshInfoRequestedTag} />
                                    :
                                    <BusinessDocuments
                                        key={documentsCompKey}
                                        loanApp={loanApp}
                                        savedState={{ naicsCode6: naics6, parentCompanyName: parentCompanyName }}
                                        ucView={true}
                                        requestFinancials={true}
                                    />
                                : (selectedTab == "Conversation") ?
                                    <Conversation key={documentsCompKey} loanApp={loanApp} />
                                    : (selectedTab == "Verification") ?
                                        <VerificationResults key={documentsCompKey} updateStatus={this.fetchLoanApplication} status={applicationStatus} loanApp={loanApp} openVerification={openVerification} />
                                        : (selectedTab == "ReviewLog") ?
                                            <ReviewLog status={applicationStatus} loanApp={loanApp} />
                                            : (selectedTab == "Proposal") ?
                                                <ProposalTerms key={documentsCompKey} updateStatus={this.fetchLoanApplication} status={applicationStatus} loanApp={loanApp} />
                                                : (selectedTab == "Contracts") ?
                                                    <Contracts businessId={loanApp.businessId} applicationId={loanApp.applicationId} loanApplication={loanApp} />
                                                    : (selectedTab == "Note") ?
                                                        <Note key={documentsCompKey} loanApp={loanApp} /> : <></>
                    }
                    {
                        this.state.showSuccessBanner &&
                        <Banner
                            background="#F5F6FE"
                            style={{ width: 320, height: 48, marginLeft: 'auto', marginRight: 'auto', position: 'sticky', zIndex: 2, bottom: "24px" }}
                            message="Link copied"
                            borderColor="#D7DCFB"
                            color="#1199FF"
                            icon={(<Image src={CheckBlue} />)}
                            cta={< Flex gap="8px" style={{ marginLeft: "20px" }}>

                                <FlexColumn>
                                    <TextButton loading={""} color='#1199FF' onClick={() => { navigator.clipboard.writeText(this.state.uploadLink) }}
                                        text='Copy' underline weight='400' rightIcon={<Image src={File} />} />
                                </FlexColumn>
                                <FlexColumn>
                                    <TextButton loading={""} color='#E52D2D' onClick={() => { this.setState({ showSuccessBanner: false }) }}
                                        underline weight='400' icon={<Image src={ModalClose} />} />
                                </FlexColumn>
                            </Flex>
                            }
                        />
                    }
                </FlexColumn>
                <Modal
                    visible={showRequestModal}
                    footer={null}
                    closable={false}
                    width={1550}
                    style={{ top: 10 }}
                    destroyOnClose={true}
                    onCancel={() => {
                        // this.setState({ showRequestModal: false })
                    }}
                >
                    <ApplicationRequest loanApp={loanApp} requestType={requestType} closeRequest={this.closeRequest}></ApplicationRequest>
                </Modal>
                <Modal
                    visible={showLinkCopyModal}
                    footer={null}
                    closable={false}
                    width={500}
                    style={{ top: '25%' }}
                    destroyOnClose={true}
                >
                    <FlexColumn center>
                        <Image margin='24px 0 12px' width='48px' height='48px' src={Check} />
                        <Text size='28px' height='40px' weight='500' style={{ marginBottom: 24, marginTop: 8 }}>Link Copied</Text>
                        <TextButton underline margin='0 0 24px 0' text={this.state.uploadLink} />
                        <TextButton underline margin='0 0 24px 0' onClick={() => {
                            navigator.clipboard.writeText(this.state.uploadLink)
                            this.closeCopyModal()
                        }} rightIcon={<Image src={Copy} />} text="Copy link again" />

                        <Button
                            solid
                            text={"OKAY"}
                            onClick={() => {
                                navigator.clipboard.writeText(this.state.uploadLink)
                                this.closeCopyModal()
                            }}
                            margin='0 0 24px'
                        />
                    </FlexColumn>
                </Modal>
                <Modal
                    visible={hasActiveLoanApplicationRequest}
                    footer={null}
                    closable={false}
                    width={500}
                    style={{ top: '25%' }}
                    destroyOnClose={true}
                >
                    <FlexColumn center>
                        <Image margin='24px 0 12px' width='48px' height='48px' src={Info} />
                        <Text size='28px' height='40px' weight='500' style={{ marginBottom: 24, marginTop: 8 }}>Active request already exists</Text>
                        <Text center margin='0 12px 24px' width={'450px'}>You can only generate a new link once the prospect submits their information using the link you earlier generated.</Text>

                        <TextButton underline margin='0 0 24px 0' onClick={() => {
                            navigator.clipboard.writeText(this.state.requests[0].uploadLink)
                            this.closeActiveRequestAlertModal()
                        }} rightIcon={<Image src={Copy} />} text="Copy link again" />

                        <Button
                            solid
                            text={"OKAY"}
                            onClick={() => {
                                navigator.clipboard.writeText(this.state.requests[0].uploadLink)
                                this.closeActiveRequestAlertModal();
                            }}
                            margin='0 0 24px'
                        />
                    </FlexColumn>
                </Modal>
            </FlexColumn>
        );
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)));