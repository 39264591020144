import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import moment from 'moment';
import { message, Modal, Select, Switch, Upload } from 'antd';
import cloneDeep from 'lodash/cloneDeep'

import { Flex, FlexColumn } from '../../Reusable/Container';
import FlowViewComponent from '../../Reusable/Refresh/FlowViewComponent';
import { addressObjectToStr, formatEIN, formatPhoneTextV2, getAddressObj, incorpTypeDocumentMap, incorpTypesArr, STATES_MAP, toCurrency } from '../../../Utils/util';
import { LabeledInput } from '../../Reusable/Input';
import { Tag, Text } from '../../Reusable/Text';
import NaicsField from '../../Reusable/Refresh/NaicsField';
import { addDataToStore, BUSINESS_INFO, REMOTE_SAVE_DATA } from '../../../Actions/actions';
import { TextButton, ImageButton } from '../../Reusable/Button';
import SearchIcon from '../../../Images/icon-search.svg';
import Edit from '../../../Images/edit.png';
import Banner from '../../Reusable/Banner';
import { Divider } from '../../Reusable/Divider';
import { ErrorAlert } from '../../Reusable/Alert';
import environment from '../../../environment';
import { apiPOSTReq } from '../../../Utils/api';
import { CloseOutlined, FilePdfOutlined } from '@ant-design/icons';
import InfoRed from '../../../Images/info-red.png'
import _ from 'underscore';

const { Option } = Select;

const einDocumentKey = 'EIN Document';
const incorpDocuments = incorpTypeDocumentMap;
const BIZ_DESC_CHAR = 60;

class BusinessProfile extends Component {

    state = {
        submitLoading: false,
        creditOnboarding: (this.props.loanApplication || {}).applicationId,
        phoneCode: {
            "name": "United States",
            "flag": "🇺🇸",
            "code": "US",
            "dial_code": "+1"
        },
        isIncorporationDatePrior: false,
        showSuccessFooter: false,
        businessInfoCopy: cloneDeep(this.props.onboardingStore.businessInfo),
        businessNameEdit: this.props.onboardingStore?.businessInfo?.name ? false : true,
    }

    componentDidMount() {
        this.fetchDocuments()
    }

    onCloseClick = () => {
        this.props.dispatch(addDataToStore(BUSINESS_INFO, this.state.businessInfoCopy))
        this.props.loadNext();
    }

    saveUserInput = (id, value) => {
        var dataToSave = {};
        switch (id) {
            case "statesOfOperation":
                if (value.includes('All')) {
                    dataToSave = { [id]: ['All'] };
                } else dataToSave = { [id]: value };
                break;
            case "capitalRequest":
                dataToSave = { [id]: toCurrency(value, 0, 0) };
                break;
            case "industry":
                dataToSave = { industry: value ? value.naicsTitle : null, naics: value ? value.code : null, naics6: value ? value.code : null };
                break;
            case "websiteUrl":
                dataToSave = { websiteUrl: `https://${value}`, urls: [`https://${value}`] };
                break;
            case "phone":
                if (this.state.phoneCode.dial_code == "+1" && !value.includes("+")) {
                    value = value.replace(/[^\d]/g, '')
                } else if (!value.includes("+")) {
                    value = value.replace(/[^0-9A-Z]+/gi, '')
                } else {
                    value = `+${value.replace(/[^0-9A-Z]+/gi, '')}`
                }
                dataToSave = { [id]: value }
                break;
            case "physicalAddress":
                dataToSave = { [id]: value }
                if (this.state.copyToMailingAddr) dataToSave["mailingAddress"] = value
                break
            default:
                dataToSave = { [id]: value }
                break;
        }
        this.props.dispatch(addDataToStore(BUSINESS_INFO, dataToSave));
    }

    handleOnChange = (event) => {
        if (event) {
            const target = event.target;
            if (!target) {
                // since type number doesnt have event.target
                console.log("BusinessProfile handleOnChange", "capitalRequest");
                this.saveUserInput("capitalRequest", event)
            } else {
                console.log("BusinessProfile handleOnChange", target);
                var id = (target.id || target.name);
                switch (id) {
                    /*case 'employeeCount':
                        if (!isNaN(target.value)) {
                            this.saveUserInput(target.id || target.name, target.value)
                            this.setErrorField(null, null);
                        }
                        else {
                            this.setErrorField("employeeCount", "Please enter a valid employee count");
                            this.saveUserInput(target.id || target.name, target.value);
                        }
                        break;*/
                    case "phone":
                        var value = target.value;
                        if (this.state.phoneCode.dial_code == "+1" && !value.includes("+")) {
                            value = value.replace(/[^\d]/g, '')
                        } else if (!value.includes("+")) {
                            value = value.replace(/[^0-9A-Z]+/gi, '')
                        } else {
                            value = `+${value.replace(/[^0-9A-Z]+/gi, '')}`
                        }

                        if (!isNaN(value)) {
                            this.saveUserInput(target.id || target.name, target.value)
                            this.setErrorField(null, null);
                        }
                        else {
                            this.setErrorField("phone", "Please enter a valid phone number");
                            this.saveUserInput(target.id || target.name, target.value);
                        }
                        break;
                    default:
                        this.saveUserInput(target.id || target.name, target.value)
                        break;
                }
                // if ((target.id || target.name) == 'employeeCount') {
                //     if (!isNaN(target.value)) {
                //         this.saveUserInput(target.id || target.name, target.value)
                //         this.setErrorField(null, null);
                //     }
                //     else {
                //         this.setErrorField("employeeCount", "Please enter a valid employee count");
                //         this.saveUserInput(target.id || target.name, target.value);
                //     }
                // }
                // else {
                //     this.saveUserInput(target.id || target.name, target.value)
                // }
            }
        }
        else {
            this.saveUserInput("capitalRequest", null)
        }
    }

    setErrorField = (id, msg) => this.setState({ errorField: id, errorMessage: msg });

    validateFlow = () => {
        const { onboardingStore, flowView } = this.props;
        var { name, incorporationDate, incorporationType, incorporationState, statesOfOperation, industry, employeeCount, phone, urls, physicalAddress, mailingAddress, ein, description } = onboardingStore.businessInfo || {};
        var { creditOnboarding } = this.state;
        urls = urls || [];
        if (creditOnboarding && (!description || description.length < 60)) {
            this.setErrorField("description", "Business description must be at least 60 characters");
            return;
        }
        if (!name) {
            ErrorAlert({ description: "Please enter a valid business name" });
            return;
        }
        if (!incorporationType) {
            this.setErrorField("incorporationType", "Please select an incorporation type");
            return;
        }
        if (!incorporationDate) {
            this.setErrorField("incorporationDate", "Please select an incorporation date");
            return;
        }

        if (!incorporationState) {
            this.setErrorField("incorporationState", "Please select an incorporation state");
            return;
        }
        if (creditOnboarding) {
            if (!statesOfOperation || statesOfOperation.length == 0) {
                this.setErrorField("statesOfOperation", "Please select your states of operation");
                return;
            }
            if (moment(incorporationDate).isAfter(moment().subtract(1, "years"))) {
                this.setErrorField("incorporationDate", "");
                this.setState({ isIncorporationDatePrior: true })
                return;
            } else {
                this.setState({ isIncorporationDatePrior: false })
            }
        }
        if (!industry) {
            this.setErrorField("industry", "Please select your industry");
            return;
        }
        if (!employeeCount) {
            this.setErrorField("employeeCount", "Please select an employee count");
            return;
        }

        if (!phone || phone == '+1') {
            this.setErrorField("phone", "Please enter a valid phone");
            return;
        }
        else {
            var value = phone;
            if (this.state.phoneCode.dial_code == "+1" && !value.includes("+")) {
                value = value.replace(/[^\d]/g, '')
            } else if (!value.includes("+")) {
                value = value.replace(/[^0-9A-Z]+/gi, '')
            } else {
                value = `+${value.replace(/[^0-9A-Z]+/gi, '')}`
            }

            if (isNaN(value)) {
                this.setErrorField("phone", "Please enter a valid phone number");
                return;
            };
        }

        if (!urls[0]) {
            this.setErrorField("websiteUrl", "Please enter a valid website");
            return;
        }
        console.log("physicalAddress", physicalAddress)
        if (!physicalAddress || !physicalAddress.line1 || !physicalAddress.city || !physicalAddress.countrySubDivisionCode || !physicalAddress.postalCode) {
            this.setErrorField("physicalAddress", "Please enter a valid address");
            return;
        }
        if (!mailingAddress || !mailingAddress.line1 || !mailingAddress.city || !mailingAddress.countrySubDivisionCode || !mailingAddress.postalCode) {
            this.setErrorField("mailingAddress", "Please enter a valid address");
            return;
        }
        if (!ein || ((ein || "").replace(/[^\d]/g, '').length !== 9)) {
            this.setErrorField("ein", "Please enter a valid EIN");
            return;
        }
        if (!creditOnboarding) {
            if (!this.getDocument(einDocumentKey).fileName) {
                ErrorAlert({ description: "Please upload your EIN document" });
                return;
            }
            const incorpUploaded = (incorpDocuments[incorporationType] || []).find(item => {
                return this.getDocument(item.name).fileName
            })
            if (!incorpUploaded) {
                ErrorAlert({ description: "Please upload a document from the Other Documents section" });
                return;
            }
        }

        // Save to the DB
        var businessInfo = onboardingStore.businessInfo;
        if (!phone.includes("+")) {
            businessInfo["phone"] = `${this.state.phoneCode.dial_code}${phone}`
        }

        console.log("Saving business", { businessInfo: onboardingStore.businessInfo })
        this.props.dispatch(addDataToStore(REMOTE_SAVE_DATA, flowView.FlowStep));

        if (creditOnboarding) {
            var { loanApplication } = this.props;
            var body = {
                applicationId: loanApplication.applicationId,
                BusinessId: loanApplication.businessId,
            }

            if (!this.state.submitLoading) {
                this.setState({ submitLoading: true });
                apiPOSTReq(`${environment.obpBaseUrl}/obp/ilien/fetchUCCNoReport`, {}, body, (err, resp) => {
                    try {
                        if (err) throw Error(err);
                        const data = resp || {};
                        if (data) {
                            // this.setState({ submitLoading: false, showSuccessBanner: true });
                            // setTimeout(() => {
                            //     this.setState({ showSuccessBanner: false })
                            //     this.props.loadNext();
                            // }, 1000)
                        } else {
                            throw Error(data.responseMessage || data.error);
                        }
                    }
                    catch (error) {
                        this.setState({ submitLoading: false });
                        console.log("/fetchUCCNoReport err", error, resp);
                    }
                });

                this.setState({ submitLoading: false, showSuccessBanner: true });
                setTimeout(() => {
                    this.setState({ showSuccessBanner: false })
                    this.props.loadNext();
                }, 1000)
            }
        } else {
            setTimeout(() => {
                this.setState({ showSuccessBanner: false })
                this.props.loadNext();
            }, 1000)
        }

    }

    showUnsupportedBusiness = () => {
        const StyledUl = styled.ul`
            li::before: {
                color: red;
            }
        `;
        Modal.warning({
            width: 700,
            title: "Prohibited Customers",
            content: (
                <div style={{ height: 700, overflowY: 'auto', paddingTop: 12, paddingRight: 40 }}>
                    <p>Aion maintains a list of customers and activities that are deemed to pose a high risk for money laundering and terrorist financing, and may prohibit customers engaged in such activities from using Aion’s products or services.</p>
                    <br />
                    <p>Aion’s list of prohibited business and activities includes the following:</p>
                    <StyledUl>
                        <li>Any potential customer whose identity cannot be verified</li>
                        <li>Individuals prohibited under applicable sanctions programs</li>
                        <li>Individuals included on Aion internal watch list (or equivalent)</li>
                        <li>Marijuana, Hemp, CBD, Private ATM Owners</li>
                        <li>Unlicensed or Unregistered Money Service Business</li>
                        <li>Online/Internet Gambling</li>
                        <li>Countries, governments, entities and individuals subject to sanctions or included in any applicable internal lists;</li>
                        <li>Marijuana-related businesses (MRB) (such as manufacturers, dispensers, and those engaged in medical marijuana), including companies whose main source of revenue is derived from this type of activity are prohibited;</li>
                        <li>Customers engaged in the defense sector or the manufacture or production of arms, military equipment or weapons of mass destruction. This includes, but is not limited to: anti-personnel mines, chemical weapons, cluster munitions, military equipment or technology, nuclear weapons, military and dual-use equipment, internal repression equipment and security and police equipment;</li>
                        <ul>
                            <li>There may be instances dependent on the Client Type or nature of business that may require an analysis of the customer’s activity/operations in the defense sector and annual revenue and percentage of sales/revenue attributed to the above referenced defense sector activities. These should be escalated to Cross River Financial Crime Compliance.</li>
                        </ul>
                        <li>Anonymous or numbered accounts or Customers seeking to maintain an account in an obviously fictitious name;</li>
                        <li>New bearer share issuance or issued bearer shares that have not been immobilized or are not with an approved custodian</li>
                        <li>Customers whose identities are not known or cannot be verified;</li>
                        <li>Customers exited for financial crime concerns. In addition, customers or related parties where there is a strong suspicion or direct evidence that criminal activity has taken place, where a criminal offence has been committed and charges have been brought or where there is a suspicion of terrorist financing;</li>
                        <li>Shell banks – i.e. an entity that has no physical existence in the country in which it is incorporated and licensed, and which is unaffiliated with a regulated financial group that is subject to effect consolidated supervision (Section 313 of the USA PATRIOT Act);</li>
                        <li>Unlicensed or unregistered Money Services Businesses – i.e., companies offering services involving money/currency exchange, money transfer, cheque cashing, and issuing or selling travelers cheques, money orders or stored value cards that meet the regulatory definition.</li>
                        <li>Unlawful Internet Gaming Companies, their principals, and their payment processors and companies whose main source of revenue is derived from either the development of gambling software or hosting environments as defined in the Unlawful Internet Gambling Act of 2006 (UIGEA) and Prohibition of Funding of Unlawful Internet Gambling (“Regulation GG”);</li>
                        <li>Adult entertainment businesses, escort services and sexually oriented or pornographic products and services;</li>
                        <li>Payday lenders, their owners, and principals; and</li>
                        <li>Missions, embassies, and consulates</li>
                        <li>Verified Hackers</li>
                    </StyledUl>
                </div>
            ),
            okText: "Okay"
        });
    }

    getLocation(location, key) {
        let addrObj = getAddressObj(location);
        addrObj.city = addrObj.city ? addrObj.city.substring(0, 18) : '';
        console.log("location", location, addrObj);
        this.saveUserInput(key, addrObj);
    }

    handleDocSubmit = (uploadItem) => {
        var { loadingItem } = uploadItem || {};
        var { loanApplication } = this.props;
        var { creditOnboarding } = this.state;

        var businessDocument = {
            "category": creditOnboarding ? "PreScreen" : "BBOnboarding",
            "name": uploadItem.name,
            "documentUrl": uploadItem.savedFile || {}
        };
        if (creditOnboarding) businessDocument.applicationId = loanApplication.applicationId;
        var body = {
            "businessDocument": businessDocument
        }
        console.log("handleSubmit DOC/SAVE uploadItem body", body);
        this.setState({ submitLoading: true, loadingItem: loadingItem });
        apiPOSTReq(`${environment.uamBaseUrl}/docs/save`, {}, body, (err, resp) => {
            this.setState({ submitLoading: false, loadingItem: null });
            try {
                const data = resp || {};
                if (data.result) {
                    message.success(`File saved successfully.`, 0.75);
                    this.fetchDocuments();
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/docs/save err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again" });
            }
        });
    }

    handleDeleteDoc = (uploadItem) => {
        var { loanApplication } = this.props;
        var { creditOnboarding } = this.state;

        var businessDocument = {
            "category": creditOnboarding ? "PreScreen" : "BBOnboarding",
            "name": uploadItem.name
        };

        if (creditOnboarding) businessDocument.applicationId = loanApplication.applicationId;
        var body = {
            "businessDocument": businessDocument,
            "documentId": uploadItem.documentId
        }

        console.log("handleSubmit DOC/delete uploadItem body", body);
        this.setState({ submitLoading: true });
        apiPOSTReq(`${environment.uamBaseUrl}/docs/delete`, {}, body, (err, resp) => {
            this.setState({ submitLoading: false, loadingItem: null });
            try {
                const data = resp || {};
                if (data.result) {
                    message.success(`File Deleted successfully.`, 0.75);
                    this.fetchDocuments();
                    let doc = this.state[uploadItem.name] || {};
                    doc.savedFile = {};
                    this.setState({ [doc.name]: doc });
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/docs/deleteUpload err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again" });
            }
        });
    }

    fetchDocuments = () => {
        const { loanApplication } = this.props;
        var { creditOnboarding } = this.state;

        var body = {
            "sortDirection": "DESC"
        };
        if (creditOnboarding) {
            body["clientBusinessId"] = loanApplication.businessId;
            body["applicationId"] = loanApplication.applicationId;
            body["viewBy"] = "FILTER_APPLICATION"
        }
        console.log("/docs/getActiveDocuments body", body, loanApplication);
        this.setState({ loading: true });
        apiPOSTReq(`${environment.uamBaseUrl}/docs/getActiveDocuments`, {}, body, (err, resp) => {
            console.log("/docs/getActiveDocuments resp", resp);
            this.setState({ loading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    this.setState({
                        businessDocuments: data.businessDocuments
                    });
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again" });
            }
        });
    }

    uploadProps = (doc) => {
        return {
            name: `business-docs`,
            action: `${environment.apiBaseUrl}/file/upload`,
            headers: {
                businesskey: this.props.aionStore.BusinessUniqueKey,
                jwt: this.props.aionStore.jwt
            },
            beforeUpload: (doc) => {
                const isPDF = doc.type === 'application/pdf';
                const isPNG = doc.type === 'image/png';
                const isJPG = doc.type === 'image/jpeg';
                if (!(isPNG || isPDF || isJPG)) {
                    message.error(`Please upload a PDF, PNG or JPEG file`);
                }
                return isPDF || isPNG || isJPG || Upload.LIST_IGNORE;
            },
            onChange: (info) => {
                var { fileList } = info
                var savedFile = {}
                fileList.forEach(file => {
                    const { status, response, name, url } = file
                    if (status === "done") {
                        savedFile = {
                            fileName: name,
                            uri: (response.UploadedUrls || [])[0],
                        }
                        if (doc.type) savedFile.type = doc.type
                        // message.success(`${info.file.name} file uploaded successfully.`, 0.75);
                        doc.savedFile = savedFile
                        this.handleDocSubmit(doc)
                    } else if (status === 'error') {
                        message.error(`${info.file.name} file upload failed.`)
                    }
                });
                if (((Object.keys(savedFile)).length) !== 0) {
                    doc.savedFile = savedFile
                }
                this.setState({ [doc.name]: doc })
            },
            onRemove: (file) => {
                this.handleDeleteDoc(doc)
            }
        }
    }

    getDocument = (name, options) => {
        options = options || {};
        var { businessDocuments } = this.state;
        var savedDoc = this.state[name] || {};
        var filteredDoc = (businessDocuments || []).find(item => (item.name == name));
        if (filteredDoc != undefined) {
            // Replace with remotely stored data
            if (options.businessDocument) savedDoc = filteredDoc;
            else savedDoc = filteredDoc.documentUrl || {};
        }
        return savedDoc;
    }

    handleCodeChange = (code) => {
        this.setState({ phoneCode: code })
    }

    render() {
        var { flowView, currentViewIndex, loadPrevView, loadNext, flowSteps, onboardingStore, theme, loanApplication, applicationStep, businessInfo, reviewView, reviewEditMode } = this.props
        var { errorField, errorMessage, creditOnboarding, copyToMailingAddr, businessNameEdit } = this.state
        businessInfo = Object.assign(businessInfo || {}, onboardingStore.businessInfo)
        const { appState } = onboardingStore;
        var businessSearchResults = appState.businessSearchResults || [];
        var { physicalAddress, mailingAddress, urls, incorpTypeDoc } = businessInfo
        flowSteps = flowSteps || []
        flowView = flowView || {}
        var entityItems = []
        var usStates = []
        var mulipleStates = []
        var incorpDocumentsOptions = []
        var employeeItems = []
        var employeeItemsArray = [
            "1 - 50",
            "51 - 100",
            "101 - 500",
            "500+"
        ]
        employeeItemsArray.forEach(item => {
            employeeItems.push(
                <Option key={item} value={item}>
                    {item}
                </Option>
            )
        })
        if (!incorpTypeDoc) {
            (incorpDocuments[businessInfo.incorporationType] || []).forEach(item => {
                if (this.getDocument(item.name).fileName) incorpTypeDoc = item.name
            })
        }

        var bizDescInst = `Enter at least ${BIZ_DESC_CHAR} characters`
        if(!_.isEmpty(businessInfo.description)) {
            bizDescInst = (businessInfo.description.length < BIZ_DESC_CHAR) ? `Minimum of ${BIZ_DESC_CHAR - businessInfo.description.length} more characters required` : ''
        }

        incorpTypesArr.forEach(item => {
            entityItems.push(
                <Option key={item} value={item}>
                    {item}
                </Option>
            )
        })

        mulipleStates.push(
            <Option key={"All"} value={"All"}>
                {"All States"}
            </Option>
        )
        Object.keys(STATES_MAP).forEach(item => {
            usStates.push(
                <Option key={item} value={item}>
                    {item}
                </Option>
            )

            mulipleStates.push(
                <Option disabled={this.state.statesOfOperation ? this.state.statesOfOperation.includes('All') : false} key={item} value={item}>
                    {item}
                </Option>
            )
        });

        (incorpDocuments[businessInfo.incorporationType] || []).forEach(item => {
            incorpDocumentsOptions.push(
                <Option key={item.name} value={item.name}>
                    {item.name}
                </Option>
            )
        })

        console.log('Key: businessInfo', businessInfo, (businessInfo.phone || "").includes("+"))
        var childViews = (
            <FlexColumn start style={{ marginTop: "0px", minHeight: '100%' }}>
                <FlexColumn>
                    {/* {
                        !reviewEditMode &&
                        <TextButton underline weight={400}
                            icon={<img height={"20px"} width={"20px"} src={SearchIcon} />}
                            text="Search again"
                            onClick={() => this.props.loadPrev()}
                        />
                    } */}
                    <Flex start gap='12px' centerHorizontally style={{ marginBottom: "24px" }}>
                        {
                            businessNameEdit ?
                                <div style={{ width: "24%", marginRight: 12 }}>
                                    <LabeledInput
                                        onKeyDown={this.handleOnKeyDown}
                                        labelcolor={this.props.theme.colors.secondary3}
                                        label="Business Name"
                                        key={flowView.OnboardingStep}
                                        id="name"
                                        onChange={this.handleOnChange}
                                        value={businessInfo.name}
                                        placeholder={businessInfo.name}
                                        error={errorField == "name"}
                                        errorMessage={errorMessage}
                                    />
                                </div>
                                :
                                <Text heading>{businessInfo.name}</Text>
                        }
                        {
                            !reviewEditMode && !businessNameEdit &&
                            <ImageButton src={Edit} onClick={() => this.setState({ businessNameEdit: true })} />
                        }
                    </Flex>
                    {
                        businessSearchResults.length > 0 ?
                            <Banner
                                style={{ marginBottom: "24px" }}
                                message="We've filled in some of the information available on the public database. You can edit the details as required"
                            />
                            :
                            ""
                    }
                    {
                        this.state.isIncorporationDatePrior ?
                            <Banner
                                icon={<img style={{ cursor: 'pointer' }} width='24px' height='24px' src={InfoRed} />}
                                background="#FEF5F5"
                                borderColor="#FAD5D5"
                                color="#E62D2D"
                                style={{ marginBottom: "24px" }}
                                message="The formation year of your business indicates that you do not meet eligibility requirements to qualify for credit with Aion. We require businesses to be in operation for over a year."
                            />
                            :
                            ""
                    }

                </FlexColumn>
                {
                    creditOnboarding && <Flex style={{ width: "49%" }}>
                        <LabeledInput
                            label="Brief description of business"
                            value={businessInfo.description}
                            labelcolor={this.props.theme.colors.secondary3}
                            key={flowView.OnboardingStep}
                            minHeight='42px'
                            id="description"
                            placeholder="Please enter a brief description of business"
                            onChange={this.handleOnChange}
                            error={errorField == "description"}
                            errorMessage={errorMessage}
                            instruction={bizDescInst}
                        />
                    </Flex>
                }
                <Flex style={{ width: "100%" }}>
                    <div style={{ width: "25%", marginRight: 12 }}>
                        <LabeledInput
                            labelcolor={this.props.theme.colors.secondary3}
                            label="Incorporation Type"
                            type="select"
                            key={flowView.OnboardingStep}
                            padding='0 0 0 12px'
                            placeholder="Select an option"
                            optionFilterProp="children"
                            onChange={value => { this.saveUserInput("incorporationType", value) }}
                            value={businessInfo.incorporationType}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            id="incorporationType"
                            error={errorField == "incorporationType"}
                            errorMessage={errorMessage}
                        >
                            {entityItems}
                        </LabeledInput>
                    </div>
                    <div style={{ width: "25%", marginRight: 12, marginLeft: 12 }}>
                        <LabeledInput
                            label={creditOnboarding ? "Formation Date" : "Incorporation Date"}
                            type="date-picker"
                            key={flowView.OnboardingStep + ".Date"}
                            id="incorporationDate"
                            onChange={(date, yearString) => {
                                console.log("incorporationDate", date, yearString)
                                // if (creditOnboarding && yearString) {
                                //     this.saveUserInput("incorporationDate", `${yearString}-01-01`)
                                // } else {
                                this.saveUserInput("incorporationDate", date ? moment(date).format("YYYY-MM-DD") : null);
                                // }
                            }}
                            format={creditOnboarding ? "MM-DD-YYYY" : "YYYY-MM-DD"}
                            value={businessInfo.incorporationDate && moment(businessInfo.incorporationDate)}
                            placeholder={creditOnboarding ? "Pick a Date" : "Pick a Date"}
                            disabledDate={(current) => current > moment()}
                            picker={creditOnboarding ? 'date' : 'date'}
                            containerStyle={{ height: 42 }}
                            error={errorField == "incorporationDate"}
                            errorMessage={errorMessage}
                        />
                    </div>
                    <div style={{ width: "25%", marginRight: 12, marginLeft: 12 }}>
                        <LabeledInput
                            labelcolor={this.props.theme.colors.secondary3}
                            label={creditOnboarding ? "Formation State" : "Incorporation State"}
                            type="select"
                            padding='0 0 0 12px'
                            key={flowView.OnboardingStep + ".State"}
                            id="incorporationState"
                            placeholder="Select a state"
                            optionFilterProp="children"
                            onChange={value => { this.saveUserInput("incorporationState", value) }}
                            value={businessInfo.incorporationState}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().startsWith(input.toLowerCase())
                            }
                            showSearch
                            divider={false}
                            error={errorField == "incorporationState"}
                            errorMessage={errorMessage}
                        >
                            {usStates}
                        </LabeledInput>
                    </div>
                    {
                        creditOnboarding ?
                            <div style={{ width: "25%", marginLeft: 12 }}>
                                <LabeledInput
                                    labelcolor={this.props.theme.colors.secondary3}
                                    label="States of Operation"
                                    mode="tags"
                                    type="select"
                                    allowClear={false}
                                    showArrow={true}
                                    style={{
                                        width: '100%',
                                        borderRadius: "4px"
                                    }}
                                    containerStyle={{ height: 42, padding: '0 8px 0 8px' }}
                                    key={flowView.OnboardingStep + ".StatesOfOperation"}
                                    id="statesOfOperation"
                                    placeholder="Select all that apply"
                                    optionFilterProp="children"
                                    onChange={value => { this.saveUserInput("statesOfOperation", value) }}
                                    value={businessInfo.statesOfOperation}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    divider={false}
                                    error={errorField == "statesOfOperation"}
                                    errorMessage={errorMessage}
                                >
                                    {mulipleStates}
                                </LabeledInput>
                            </div>
                            :
                            <div style={{ width: "25%", marginLeft: 12 }}></div>
                    }
                </Flex>
                <Flex start style={{ width: "100%" }}>
                    <div style={{ width: "25%", marginRight: 12 }}>
                        <LabeledInput
                            onKeyDown={this.handleOnKeyDown}
                            labelcolor={this.props.theme.colors.secondary3}
                            label="DBA or Trade Name"
                            key={flowView.OnboardingStep}
                            minHeight='42px'
                            id="dba"
                            onChange={this.handleOnChange}
                            value={businessInfo.dba}
                            placeholder={businessInfo.name}
                            optional
                            error={errorField == "dba"}
                            errorMessage={errorMessage}
                        />
                    </div>
                    <div style={{ width: "25%", marginRight: 12, marginLeft: 12 }}>
                        <NaicsField
                            onChange={value => { this.saveUserInput("industry", value) }}
                            value={businessInfo.industry}
                            error={errorField == "industry"}
                            errorMessage={errorMessage}
                        />
                    </div>
                    <div style={{ width: "25%", marginRight: 12, marginLeft: 12 }}>
                        {/* <LabeledInput
                            onKeyDown={this.handleOnKeyDown}
                            labelcolor={this.props.theme.colors.secondary3}
                            label="Number of Employees"
                            key={flowView.OnboardingStep}
                            id="employeeCount"
                            onChange={this.handleOnChange}
                            value={businessInfo.employeeCount}
                            placeholder="10"
                            error={errorField == "employeeCount"}
                            errorMessage={errorMessage}
                /> */}
                        <LabeledInput
                            labelcolor={this.props.theme.colors.secondary3}
                            label="Number of Employees"
                            type="select"
                            key={flowView.OnboardingStep}
                            padding='0 0 0 12px'
                            placeholder="Select number of employees"
                            onChange={value => { this.saveUserInput("employeeCount", value) }}
                            value={businessInfo.employeeCount}
                            id="employeeCount"
                            error={errorField == "employeeCount"}
                            errorMessage={errorMessage}
                        >
                            {employeeItems}
                        </LabeledInput>
                    </div>
                    <div style={{ width: "25%", marginLeft: 12 }}></div>
                </Flex>
                <Flex start style={{ width: "100%" }}>
                    <div style={{ width: "25%", marginRight: 12 }}>
                        <LabeledInput
                            labelcolor={this.props.theme.colors.secondary3}
                            label="Business Phone Number"
                            id="phone"
                            type={(businessInfo.phone || "").includes("+") ? "text" : "phone-cc"}
                            minHeight='42px'
                            // maxLength={10}
                            value={((businessInfo.phone || "").includes("+") || this.state.phoneCode.dial_code != "+1") ? formatPhoneTextV2(businessInfo.phone) : formatPhoneTextV2(businessInfo.phone)}
                            placeholder={(this.state.phoneCode.dial_code != "+1") ? "Enter phone" : "(000) 000 0000"}
                            onChange={this.handleOnChange}
                            phoneCode={this.state.phoneCode}
                            onCodeChange={(code) => this.handleCodeChange(code)}
                            error={errorField == "phone"}
                            errorMessage={errorMessage}
                        />
                    </div>
                    <div style={{ width: "25%", marginRight: 12, marginLeft: 12 }}>
                        <LabeledInput
                            labelcolor={this.props.theme.colors.secondary3}
                            label="Website"
                            id="websiteUrl"
                            type="text"
                            prefix="https://"
                            minHeight='42px'
                            value={(urls || []).length > 0 ? (urls[0]).replace("https://", "") : null}
                            placeholder="Enter website URL"
                            onChange={this.handleOnChange}
                            error={errorField == "websiteUrl"}
                            errorMessage={errorMessage}
                        />
                    </div>
                    {
                        creditOnboarding &&
                        <div style={{ width: "25%", marginLeft: 12, marginRight: 12 }}>
                            <LabeledInput
                                onKeyDown={this.handleOnKeyDown}
                                labelcolor={this.props.theme.colors.secondary3}
                                label="Federal EIN"
                                key="ein"
                                id="ein"
                                minHeight='42px'
                                onChange={this.handleOnChange}
                                value={businessInfo.ein && formatEIN(businessInfo.ein)}
                                maxLength={10}
                                placeholder="22-0000000"
                                error={errorField == "ein"}
                                errorMessage={errorMessage}
                            />
                        </div>
                    }
                    {
                        !creditOnboarding &&
                        <div style={{ width: "25%", marginRight: 12, marginLeft: 12 }}></div>
                    }
                    <div style={{ width: "25%", marginLeft: 12 }}></div>
                </Flex>
                <Flex between style={{ width: "100%" }}>
                    <div style={{ width: "50%", marginRight: 12 }}>
                        <LabeledInput
                            type="location"
                            id={"physicalAddress"}
                            key={"Address"}
                            value={physicalAddress}
                            label="Registered Address"
                            getLocation={(location, formattedAddress) => this.getLocation(location, 'physicalAddress')}
                            placeholder={`Enter ${"Address"}`}
                            // nextLine={true}
                            // country
                            divider={false}
                            fullWidth
                            error={errorField == "physicalAddress"}
                            errorMessage={errorMessage}
                        />
                    </div>
                    <div style={{ width: "50%", marginLeft: 12 }}>
                        <LabeledInput
                            type="location"
                            id='mailingAddress'
                            key={'mailingAddress' + `${copyToMailingAddr ? addressObjectToStr(physicalAddress) : ""}`}
                            value={copyToMailingAddr ? physicalAddress : mailingAddress}
                            label="Mailing Address"
                            getLocation={(location, formattedAddress) => this.getLocation(location, 'mailingAddress')}
                            placeholder={`Enter ${"Address"}`}
                            // country
                            divider={false}
                            fullWidth
                            instruction='All communications will be sent to this address'
                            custLblComp={
                                <Flex center gap="8px">
                                    <Text color={theme.colors.secondary6} size='14px'>Same as Registered Address</Text>
                                    <Switch
                                        defaultChecked={copyToMailingAddr}
                                        size="small"
                                        onChange={(checked) => {
                                            if (checked) { this.saveUserInput("mailingAddress", physicalAddress) }
                                            else { this.saveUserInput("mailingAddress", {}) }
                                            this.setState({ copyToMailingAddr: checked })
                                        }}
                                    />
                                </Flex>
                            }
                            error={errorField == "mailingAddress"}
                            errorMessage={errorMessage}
                        />
                    </div>
                </Flex>
                {
                    !creditOnboarding &&
                    <FlexColumn>
                        <Divider />
                        <Text uppercase spacing='0.08em' weight='500' color={theme.colors.secondary6}>Federal EMPLOYER IDENTIFICATION NUMBER (EIN)</Text>
                        <Flex start style={{ marginTop: '24px' }}>
                            <div style={{ width: "50%", marginRight: 12 }}>
                                {
                                    !this.getDocument(einDocumentKey).fileName ?
                                        <LabeledInput
                                            {...this.uploadProps({ name: einDocumentKey })}
                                            type='upload'
                                            label='EIN Letter'
                                            uploadText='We accept 147c letter and CP 575 IRS documents as well'
                                        />
                                        :
                                        <>
                                            <Text weight="400" color="#7384AA" style={{ fontSize: "14px", }}>EIN Letter</Text>
                                            <TextButton
                                                style={{ textAlign: "left", padding: "8px 8px" }}
                                                type="link"
                                                icon={<FilePdfOutlined />}
                                                rightIcon={<CloseOutlined onClick={() => this.handleDeleteDoc(this.getDocument(einDocumentKey, { businessDocument: true }))} />}
                                                text={this.getDocument(einDocumentKey).fileName}
                                            />
                                        </>
                                }
                            </div>
                            <div style={{ width: "25%", marginRight: 12, marginLeft: 12 }}>
                                <LabeledInput
                                    onKeyDown={this.handleOnKeyDown}
                                    labelcolor={this.props.theme.colors.secondary3}
                                    label="EIN"
                                    key="ein"
                                    id="ein"
                                    onChange={this.handleOnChange}
                                    value={businessInfo.ein && formatEIN(businessInfo.ein)}
                                    maxLength={10}
                                    placeholder="22-0000000"
                                    error={errorField == "ein"}
                                    errorMessage={errorMessage}
                                />
                            </div>
                        </Flex>
                    </FlexColumn>
                }

                {
                    incorpDocuments[businessInfo.incorporationType] && !creditOnboarding &&
                    <FlexColumn>
                        <Divider />
                        <Text uppercase spacing='0.08em' weight='500' color={theme.colors.secondary6}>Other Documents</Text>
                        <Text size='14px' color={theme.colors.defaultLightText} margin='12px 0 0'>We need one of these documents</Text>

                        <Flex start wrap style={{ marginTop: '24px' }}>
                            {
                                !this.getDocument(incorpTypeDoc).fileName ?
                                    <>
                                        <div style={{ width: "50%", marginRight: 12 }}>
                                            <LabeledInput
                                                labelcolor={this.props.theme.colors.secondary3}
                                                label="Select a document to upload"
                                                type="select"
                                                key="other document"
                                                padding='0 0 0 12px'
                                                placeholder="Select an option"
                                                optionFilterProp="children"
                                                onChange={value => { this.saveUserInput("incorpTypeDoc", value) }}
                                                value={incorpTypeDoc}
                                                id="incorpTypeDoc"
                                                error={errorField == "incorpTypeDoc"}
                                                errorMessage={errorMessage}
                                            >
                                                {incorpDocumentsOptions}
                                            </LabeledInput>
                                            <LabeledInput
                                                {...this.uploadProps({ name: incorpTypeDoc })}
                                                type='upload'
                                                label=''
                                                uploadText='We accept PDF, PNG and JPEG formats'
                                                disabled={!incorpTypeDoc}
                                            />
                                        </div>

                                    </>
                                    :
                                    <div style={{ width: "50%", marginRight: 12 }}>
                                        <Text weight="400" color={theme.colors.secondary6} style={{ fontSize: "14px", }}>{incorpTypeDoc}</Text>
                                        <TextButton
                                            style={{ textAlign: "left", padding: "8px 8px" }}
                                            type="link"
                                            icon={<FilePdfOutlined />}
                                            rightIcon={<CloseOutlined onClick={() => this.handleDeleteDoc(this.getDocument(incorpTypeDoc, { businessDocument: true }))} />}
                                            text={this.getDocument(incorpTypeDoc).fileName}
                                        />
                                    </div>
                            }
                        </Flex>
                    </FlexColumn>
                }
                <Text color={theme.colors.defaultLightText} margin="20px 0 0">By clicking on ‘Next’ you are confirming that this business is not involved or<br />associated in any <a style={{ textDecoration: "underline", color: theme.colors.primary }} onClick={() => this.showUnsupportedBusiness()}>unsupported activities or practices</a>.</Text>
            </FlexColumn>
        )
        if (reviewView) {
            return (
                <FlexColumn start fullWidth left>
                    <Flex start fullWidth gap='24px'>
                        <FlexColumn style={{ width: "25%" }}>
                            <LabeledInput
                                label="Business Name"
                                type="read-only"
                                value={businessInfo.name}
                            />
                        </FlexColumn>
                        <FlexColumn style={{ width: "25%" }}>
                            <LabeledInput
                                label="Incorporation Type"
                                type="read-only"
                                value={businessInfo.incorporationType}
                            >
                            </LabeledInput>
                        </FlexColumn>
                        <FlexColumn style={{ width: "25%" }}>
                            <LabeledInput
                                label={creditOnboarding ? "Formation Date" : "Incorporation Date"}
                                type="read-only"
                                value={moment(businessInfo.incorporationDate).format(creditOnboarding ? 'MMM DD, YYYY' : 'MMM DD, YYYY')}
                            />
                        </FlexColumn>
                        <FlexColumn style={{ width: "25%" }}>
                            <LabeledInput
                                label={creditOnboarding ? "Formation State" : "Incorporation State"}
                                type="read-only"
                                value={businessInfo.incorporationState}
                            >
                            </LabeledInput>
                        </FlexColumn>
                    </Flex>
                    <Flex start fullWidth gap='24px'>
                        <FlexColumn style={{ width: "25%" }}>
                            {
                                creditOnboarding ?
                                    <LabeledInput
                                        label="States of Operation"
                                        type="read-only"
                                        value={businessInfo.statesOfOperation.map((val, i) => {
                                            return <>{i > 0 ? <>, </> : ''}{val}</>
                                        })}
                                    />
                                    :
                                    <LabeledInput
                                        type="read-only"
                                        label="DBA or Trade Name"
                                        value={businessInfo.dba || '--'}
                                    />
                            }
                        </FlexColumn>
                        <FlexColumn style={{ width: "25%" }}>
                            <LabeledInput
                                label="Industry"
                                type="read-only"
                                value={businessInfo.industry}
                            />
                        </FlexColumn>
                        <FlexColumn style={{ width: "25%" }}>
                            <LabeledInput
                                label="Number of Employees"
                                value={businessInfo.employeeCount}
                                type="read-only"
                            />
                        </FlexColumn>
                        <FlexColumn style={{ width: "25%" }}></FlexColumn>
                    </Flex>

                    <Flex start fullWidth gap='24px'>
                        <FlexColumn style={{ width: "25%" }}>
                            <LabeledInput
                                label="Business Phone Number"
                                type="read-only"
                                value={businessInfo.phone}
                            />
                        </FlexColumn>
                        <FlexColumn style={{ width: "25%" }}>
                            <LabeledInput
                                label="Website"
                                type="read-only"
                                value={(urls || []).length > 0 ? (urls[0]) : null}
                            />
                        </FlexColumn>
                        <FlexColumn style={{ width: "25%" }}></FlexColumn>
                        <FlexColumn style={{ width: "25%" }}></FlexColumn>
                    </Flex>
                    <Flex start fullWidth gap='24px'>
                        <FlexColumn style={{ width: "50%" }}>
                            <LabeledInput
                                type="read-only"
                                value={addressObjectToStr(physicalAddress)}
                                label="Business Address"
                            />
                        </FlexColumn>
                        <FlexColumn style={{ width: "50%" }}>
                            <LabeledInput
                                type="read-only"
                                value={addressObjectToStr(mailingAddress)}
                                label="Mailing Address"
                            />
                        </FlexColumn>
                    </Flex>
                    <Flex start fullWidth gap='24px'>
                        <FlexColumn style={{ width: "25%" }}>
                            <LabeledInput
                                label="EIN"
                                type="read-only"
                                value={businessInfo.ein}
                            />
                        </FlexColumn>
                        <FlexColumn style={{ width: "50%" }}>
                            <LabeledInput
                                label="EIN Letter"
                                type="read-only"
                                value={<Tag primary>{<Flex center gap='5px'><FilePdfOutlined /><span>{this.getDocument(einDocumentKey).fileName}</span></Flex>}</Tag>}
                            />
                        </FlexColumn>

                        <FlexColumn style={{ width: "25%" }}></FlexColumn>
                    </Flex>
                    <Flex start fullWidth gap='24px'>
                        {
                            incorpTypeDoc && this.getDocument(incorpTypeDoc).fileName &&
                            <FlexColumn style={{ width: "25%" }}>
                                <LabeledInput
                                    label={incorpTypeDoc}
                                    type="read-only"
                                    value={<Tag primary>{<Flex center gap='5px'><FilePdfOutlined /><span>{this.getDocument(incorpTypeDoc).fileName}</span></Flex>}</Tag>}
                                />
                            </FlexColumn>
                        }
                    </Flex>
                </FlexColumn>
            )
        }
        return (
            <FlowViewComponent
                flowView={flowView}
                currentViewIndex={currentViewIndex}
                loadPrev={loadPrevView}
                childViews={childViews}
                submitLoading={this.state.submitLoading}
                width={'100%'}
                back={currentViewIndex > 0}
                padding='0 64px'
                {...this.props}
                loadNext={this.validateFlow}
                showSuccessBanner={this.state.showSuccessBanner}
                onCloseClick={this.onCloseClick}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        onboardingStore: state.onboardingAppReducer,
        aionStore: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(BusinessProfile));