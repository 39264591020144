import React, { useState, useEffect } from 'react'
import { withTheme } from 'styled-components'
import { Skeleton, Divider, Modal, Popover } from 'antd'

// Components
import { Flex, FlexColumn, InputContainer, CardContainer } from '../Reusable/Container'
import { Text } from '../Reusable/Text'
import { TextButton } from '../Reusable/Button'
import AvailableFunds from './AvailableFunds'
import ABLAvailableFunds from './ABLAvailableFunds'

import { toCurrency } from '../../Utils/util'
import ModalClose from '../../Images/modal-close.png'
import { Image } from '../Reusable/Image'
import ArrowRight from "../../Images/arrow-right.png"
import { EyeOutlined } from '@ant-design/icons'
import { InfoCircleOutlined } from '@ant-design/icons'
import moment from 'moment'

const LimitsBalances = (props) => {
    const [showAvailableFunds, setShowAvailableFunds] = useState(false)

    const { data, availableFunds, theme, type, abl, dailyReport } = props
    var interest = dailyReport ? (dailyReport.totalOutstandingInterestToDate ? parseFloat(dailyReport.totalOutstandingInterestToDate) : 0) : 0;
    var totalOutstanding = (type !== "TermLoans") ? (parseFloat(dailyReport && dailyReport.outstandingLoanBalance) + interest) : 0;

    return (
        <FlexColumn>
            {/* <Title level={6}>Limits & Balances</Title> */}
            {/* <Divider style={{ margin: "15px 0" }} /> */}
            <Skeleton loading={!props.advancesLoaded} active title={false} paragraph={{ rows: 4 }}>
                <Flex start centerHorizontally>
                    {/* {(type !== "TermLoans") &&
                        <>
                            <InputContainer padding='20px 20px 32px' margin='0' bgColor={props.theme.colors.primary5}>
                                <FlexColumn start>
                                    <Text color={theme.colors.caption} style={{ marginBottom: 4 }}>Credit Limit</Text>
                                    <Text size='26px' weight='600'>{`$${toCurrency(data.AdvanceLimit)}`}</Text>
                                </FlexColumn>
                            </InputContainer>
                            {
                                <>
                                    <Divider type="vertical" style={{ margin: "0 30px", height: "100px" }} />
                                    <InputContainer padding='20px 20px 32px' margin='0' bgColor={props.theme.colors.primary5}>
                                        <FlexColumn start>
                                            <Text color={theme.colors.caption} style={{ marginBottom: 4 }}>Outstanding Interest
                                                <Popover content={"Outstanding interest post-application of payments. As of " + moment().add(-1, 'd').format('MMM DD, YYYY') + " 6PM ET."}>
                                                    {" "}<InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                                </Popover>
                                            </Text>
                                            <Text size='26px' weight='600'>{`$${outstandingInterest ? toCurrency(outstandingInterest) : "--"}`}</Text>
                                        </FlexColumn>
                                    </InputContainer>
                                </>
                            }
                            {
                                availableFunds && !isNaN(availableFunds.BorrowerAdvanceTaken) &&
                                <>
                                    <Divider type="vertical" style={{ margin: "0 30px", height: "100px" }} />
                                    <InputContainer padding='20px 20px 32px' margin='0' bgColor={props.theme.colors.primary5}>
                                        <FlexColumn start>
                                            <Text color={theme.colors.caption} style={{ marginBottom: 4 }}>Outstanding Loan Balance</Text>
                                            <Text size='26px' weight='600'>{`$${toCurrency(availableFunds.BorrowerAdvanceTaken)}`}</Text>
                                        </FlexColumn>
                                    </InputContainer>
                                </>
                            }
                            {
                                // availableFunds && !isNaN(availableFunds.AvailableFunds) &&
                                <>
                                    <Divider type="vertical" style={{ margin: "0 30px", height: "100px" }} />
                                    <InputContainer padding='20px 20px 10px' margin='0' bgColor={props.theme.colors.primary5}>
                                        <FlexColumn start>
                                            <Text color={theme.colors.caption} style={{ marginBottom: 4 }}>Available Funds</Text>
                                            <Text size='26px' weight='600'>{`$${toCurrency(availableFunds.AvailableFunds)}`}</Text>
                                            <Flex start fullWidth><a style={{ marginTop: 4 }} onClick={() => setShowAvailableFunds(true)}><EyeOutlined style={{ marginRight: 3 }} />View details</a></Flex>
                                        </FlexColumn>
                                    </InputContainer>
                                </>
                            }
                        </>
                    } */}
                    {
                        (type !== "TermLoans") &&
                        <>
                            <Flex between fullWidth style={{ marginBottom: 48 }} gap='24px' stretch>
                                <FlexColumn style={{ marginTop: 0 }} grow>
                                    <Flex start fullWidth gap='24px'>
                                        <CardContainer style={{ height: 250, width: '40%' }} fullWidth>
                                            <Flex start style={{ marginBottom: 15 }}>
                                                <FlexColumn style={{ width: '60%' }}>
                                                    <Text size='20px' height='40px' style={{ fontWeight: 500 }}>Credit Limit</Text>
                                                </FlexColumn>
                                                <FlexColumn gap='10px' style={{ width: '40%', alignItems: 'end' }}>
                                                    <Text size='20px' height='40px' style={{ fontWeight: 500 }}>{`$${toCurrency(data.AdvanceLimit)}`}</Text>
                                                </FlexColumn>
                                            </Flex>
                                            <Flex start>
                                                <FlexColumn style={{ width: '60%' }}>
                                                    <Text size='20px' height='40px' style={{ fontWeight: 500 }}>Available Funds</Text>
                                                </FlexColumn>
                                                <FlexColumn gap='10px' style={{ width: '40%', alignItems: 'end' }}>
                                                    <Text size='20px' height='40px' style={{ fontWeight: 500 }}>{`$${toCurrency(availableFunds.AvailableFunds)}`}</Text>
                                                </FlexColumn>
                                            </Flex>
                                            <Flex start style={{ marginBottom: 15 }}>
                                                <FlexColumn style={{ width: '60%' }}>
                                                    <Text weight='400' caption>Funds available to draw based on your eligible collateral.</Text>
                                                </FlexColumn>
                                            </Flex>
                                            <Flex start style={{ marginBottom: 15 }}>
                                                <TextButton uppercase rightIcon={<Image src={ArrowRight} />} onClick={() => { setShowAvailableFunds(true) }} text="VIEW DETAILS" />
                                            </Flex>
                                        </CardContainer>
                                        <CardContainer style={{ height: 250, width: '40%' }} fullWidth center>
                                            <Flex start>
                                                <FlexColumn style={{ width: '60%' }}>
                                                    <Text size='20px' height='40px' style={{ fontWeight: 500 }}>Total Outstanding</Text>
                                                </FlexColumn>
                                                <FlexColumn style={{ width: '40%', alignItems: 'end' }}>
                                                    <Text size='20px' height='40px' style={{ fontWeight: 500 }}>{`$${toCurrency(totalOutstanding)}`}</Text>
                                                </FlexColumn>
                                            </Flex>
                                            <Flex start>
                                                <FlexColumn style={{ width: '60%' }}>
                                                    <Text weight='400' caption>As of {dailyReport && dailyReport.reconciliationDate ? moment(dailyReport.reconciliationDate).format("MMM DD, YYYY") : moment().add(-1, 'd').format('MMM DD, YYYY')}</Text>
                                                </FlexColumn>
                                            </Flex>
                                            <Divider style={{ background: theme.body, margin: "4px 0" }} />
                                            <Flex start>
                                                <FlexColumn style={{ width: '60%' }}>
                                                    <Text style={{ fontWeight: 500 }} size='15px' height='40px'>Outstanding Principal</Text>
                                                </FlexColumn>
                                                <FlexColumn style={{ width: '40%', alignItems: 'end' }}>
                                                    <Text size='15px' height='40px' style={{ fontWeight: 500 }}>{`$${dailyReport && dailyReport.outstandingLoanBalance ? toCurrency(dailyReport.outstandingLoanBalance) : toCurrency(0)}`}</Text>
                                                </FlexColumn>
                                            </Flex>
                                            <Flex start style={{ marginBottom: 0 }}>
                                                <FlexColumn style={{ width: '60%' }}>
                                                    <Text weight='400' style={{ fontSize: '12px' }} caption>This does not include draws/advances that were requested and not deposited</Text>
                                                </FlexColumn>
                                            </Flex>
                                            <Flex start>
                                                <FlexColumn style={{ width: '60%' }}>
                                                    <Text size='15px' height='40px' style={{ fontWeight: 500 }}>Outstanding Interest</Text>
                                                </FlexColumn>
                                                <FlexColumn gap='10px' style={{ width: '40%', alignItems: 'end' }}>
                                                    <Text size='15px' height='40px' style={{ fontWeight: 500 }}>{`$${dailyReport && dailyReport.totalOutstandingInterestToDate ? toCurrency(dailyReport.totalOutstandingInterestToDate) : toCurrency(0)}`}</Text>
                                                </FlexColumn>
                                            </Flex>
                                        </CardContainer>
                                    </Flex>
                                </FlexColumn>
                            </Flex>
                        </>
                    }
                    {
                        (type != "Advances") &&
                        <>
                            <InputContainer margin='0' bgColor={props.theme.colors.primary5}>
                                <FlexColumn start>
                                    <Text color={theme.colors.caption} style={{ marginBottom: 4 }}>Outstanding Balance</Text>
                                    <Text size='26px' weight='600'>{`$${toCurrency(data.CreditTaken)}`}</Text>
                                </FlexColumn>
                            </InputContainer>
                            {availableFunds &&
                                <>
                                    <Divider type="vertical" style={{ margin: "0 30px", height: "70px" }} />
                                    <InputContainer margin='0' bgColor={props.theme.colors.primary5}>
                                        <FlexColumn start>
                                            <Text color={theme.colors.caption} style={{ marginBottom: 4 }}>Ready to Advance</Text>
                                            <Text size='26px' weight='600'>{`$${toCurrency(availableFunds.AvailableAR || 0)}`}</Text>
                                        </FlexColumn>
                                    </InputContainer>
                                </>
                            }
                            {
                                (type == "TermLoans") &&
                                <>
                                    <Divider type="vertical" style={{ margin: "0 30px", height: "70px" }} />
                                    <InputContainer margin='0' bgColor={props.theme.colors.primary5}>
                                        <FlexColumn start>
                                            <Text color={theme.colors.caption} style={{ marginBottom: 4 }}>Available Credit</Text>
                                            <Text size='26px' weight='600'>{`$${toCurrency(data.CreditLeft || 0)}`}</Text>
                                        </FlexColumn>
                                    </InputContainer>
                                </>
                            }
                        </>
                    }
                </Flex>
            </Skeleton>

            <Modal
                visible={showAvailableFunds}
                footer={false}
                style={{ top: 20 }}
                destroyOnClose={true}
                width={550}
                closable={true}
                onCancel={() => { setShowAvailableFunds(false) }}
                closeIcon={<img width='24px' height='24px' src={ModalClose} />}
            >
                {
                    abl ?
                        <ABLAvailableFunds AvailableFunds={availableFunds} />
                        :
                        <AvailableFunds AvailableFunds={availableFunds} />
                }
            </Modal>
        </FlexColumn >
    )
}

export default withTheme(LimitsBalances)