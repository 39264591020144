import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { Tabs, Modal, Skeleton, Table } from 'antd';

import moment from 'moment';
import { saveAs } from 'file-saver';
import { Flex, FlexColumn, CardContainer } from '../../../Reusable/Container';
import PageHeader from '../../../Reusable/PageHeader';
import RescheduleBill from '../Reschedule/Index';
import ViewBill from '../ViewBill';

import { Tag } from '../../../Reusable/Text';
import { apiPOSTReq, getPreSignedS3Url } from '../../../../Utils/api';
import { getResourcePerm } from '../../../../Utils/util';
import environment from '../../../../environment';
import { ErrorAlert } from '../../../Reusable/Alert';
import TableView from '../../../Reusable/TableView';


const { TabPane } = Tabs;

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

class Index extends Component {

    state = {
        billPagination: {
            current: 1,
            pageSize: 100,
            showViewBillModal: false,
        },
        coaPagination: {
            current: 1,
            pageSize: 200
        },
    }

    componentDidMount() {
        this.fetchCOA({ coaPagination: this.state.coaPagination })
        this.fetchBills({ billPagination: this.state.billPagination })
    }

    fetchCOA = (options) => {
        const { coaPagination } = options;
        const headers = {
            AionCurrentBiz: this.props.aionStore.BusinessUniqueKey,
            AionAuth: this.props.aionStore.UAM.encryptedAuthHeader
        }
        const body = {
            "BusinessId": this.props.aionStore.BusinessUniqueKey,
            "size": coaPagination.pageSize,
            "page": 0,
            "ActiveRecords": true,
            "sortFieldName": "Code",
            "sortDirection": "ASC"
        }
        this.setState({ newProductLoading: true });
        apiPOSTReq(`${environment.bbBaseUrl}/bk/getCategories`, headers, body, (err, resp) => {
            try {
                const data = resp;
                console.log("/bk/getCategories", data)
                if (data.result) {
                    // this.props.dispatch(addDataToStore(BOOKKEEPING_SAVE_DATA, { COA: (data.systemCOAList || []).concat(data.customCOAList) }))
                    this.setState({
                        coaPagination: {
                            ...coaPagination,
                            total: data.count
                        },
                        categories: (data.systemCOAList || []).concat(data.customCOAList),
                    })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getActiveCustomers", error.stack, resp);
                ErrorAlert({ description: error.message })
            }
        })
    }

    fetchBills = ({ billPagination, status }) => {
        var body = {
            size: billPagination.pageSize,
            page: billPagination.current - 1,
            sortFieldName: "Payments.0.PaymentDate",
            sortDirection: "DESC",
            viewby: "FILTER_STATUS",
            status: "Paid",
        }

        console.log("/payables/bills/getBills body", JSON.stringify(body))

        apiPOSTReq(`${environment.payBaseUrl}/payables/bills/getBills`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            try {
                const data = resp;
                console.log("/payables/bills/getBills", JSON.stringify(data))
                console.log("/payables/bills/getBills err", JSON.stringify(err))
                if (data.result) {
                    this.setState({
                        bills: data.bills || [], loading: false, showNewBillModal: false, payLoading: false, deleteLoading: false,
                        billPagination: {
                            ...billPagination,
                            total: data.count
                        }
                    })
                } else {
                    this.setState({ payLoading: false, deleteLoading: false })
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /payables/bills/getBills", error, err, resp);
                ErrorAlert({ description: error.message })
            }
        })
    }

    getPaymentMethods = (vendorId, key) => {
        const body = {
            "vendorId": vendorId
        }

        console.log("/payables/vendors/getPaymentMethods body", JSON.stringify(body))
        this.setState({ [`${key}-loading`]: true })
        apiPOSTReq(`${environment.payBaseUrl}/payables/vendors/getPaymentMethods`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            this.setState({ [`${key}-loading`]: false })
            try {
                // this.setState({ loading: false });
                const data = resp;
                console.log("/payables/vendors/getPaymentMethods", JSON.stringify(data))
                if (data.result) {
                    const vendorPaymentAccount = (data.paymentMethods || []).find(account => account.default === true)
                    console.log("/payables/vendors/getPaymentMethods vendorPaymentAccount", JSON.stringify(vendorPaymentAccount))
                    // this.setState({ [`${key}-loading`]: false, [`${key}-paymentMethod`]: vendorPaymentAccount })
                    var paymentMap = this.state.paymentMap || {};
                    paymentMap[key] = vendorPaymentAccount
                    this.setState({ paymentMap })
                } else {
                    // throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getPaymentMethods", error, resp);
                // ErrorAlert({ description: error.message })
            }
            // callback()
        })
    }

    getBill = (id, callback) => {
        apiPOSTReq(`${environment.payBaseUrl}/payables/bills/getBill`, { "BankProvider": environment.bankProvider.crb }, { billId: id }, (err, resp) => {
            try {
                const data = resp;
                console.log("/payables/bills/getBill", JSON.stringify(data))
                if (data.result && data.bill) {
                    callback(data.bill)
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /payables/bills/getBill", error, err, resp);
                ErrorAlert({ description: error.message })
            }
        })
    }

    render() {
        const { loading, bills, vendor, categories, billToView, showViewBillModal } = this.state;
        const { theme, aionStore } = this.props;
        const { Attributes } = aionStore;
        var { Businesses } = Attributes || {};

        var columns = [
            {
                title: 'Date',
                dataIndex: 'payments',
                key: 'payments',
                width: "10%",
                render: (payments, item) => {
                    if (payments && payments.length > 0) {
                        return moment(payments[0].paymentDate, "YYYY-MM-DDTHH:mm").utc().format('MM/DD/YY')
                    } else {
                        return ""
                    }
                },
            },
            {
                title: 'Vendor',
                dataIndex: 'vendorName',
                key: 'vendorName',
                width: "20%",
                render: (vendorName, item) => {
                    if (vendorName) {
                        return vendorName
                    } else {
                        return ""
                    }
                },
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
                key: 'amount',
                width: "15%",
                render: amount => (formatter.format(amount)),
            },
            {
                title: 'Payment Method',
                dataIndex: 'payments',
                key: 'payments',
                width: "15%",
                render: (payments, item) => {
                    if (payments && payments.length > 0 && payments[0].paymentMethod) {
                        return <Tag primary tagpreset={theme.colors.secondary4}>
                            {(payments[0].paymentMethod === "BillPay" || payments[0].paymentMethod === "CHECK") ? "Check" : payments[0].paymentMethod}
                        </Tag>
                    } else {
                        return "--"
                    }
                },
            },
            {
                title: 'Status',
                dataIndex: 'payments',
                key: 'payments',
                width: "20%",
                render: (payments, item) => {
                    if (payments && payments.length > 0 && payments[0].paymentStatus) {
                        return <Tag tagpreset={theme.colors.secondary4}>{payments[0].paymentStatus}</Tag>
                    } else {
                        return "--"
                    }
                },
            },
            {
                title: 'Bill #',
                dataIndex: 'invoiceNumber',
                key: 'invoiceNumber',
                width: "20%",
                render: (invoiceNumber, bill) => {
                    return invoiceNumber
                },
            },
        ]

        return (
            <>
                <FlexColumn className='main-padding'>
                    <PageHeader subtitle="Bill Payments"
                        titleText="Bill Payments"
                        desc="List of all your bill payments" />

                    <TableView
                        id="bill-table"
                        titleText="Payments"
                        className='row-pointer borderless-table'
                        tableLayout='auto'
                        columns={columns}
                        dataSource={bills}
                        rowKey='id'
                        pagination={this.state.billPagination}
                        loading={loading}
                        onChange={this.handleTableChange}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event => {
                                    this.setState({ billToView: record, showViewBillModal: true })
                                }, // click row
                            };
                        }}
                    />
                </FlexColumn>
                <Modal
                    visible={showViewBillModal}
                    footer={null}
                    closable={true}
                    maskClosable={true}
                    width={(billToView || {}).previewDocumentUrls && billToView.previewDocumentUrls.length > 0 ? 1150 : 700}
                    style={{ top: 20 }}
                    destroyOnClose={true}
                    onCancel={() => this.setState({ showViewBillModal: false, billToView: null })}
                >
                    <ViewBill
                        bill={billToView}
                        vendor={vendor}
                        categories={categories}
                    />
                </Modal>
            </>
        )

    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer,
        bankingStore: state.bankingAppReducer,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)));