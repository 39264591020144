import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled, { withTheme } from 'styled-components'
import { Checkbox, Collapse, Skeleton, Space, Switch, Table, Select, Modal, message } from 'antd'
import { Flex, FlexColumn, CardContainer, LightContainer } from '../../../Reusable/Container'
import { Button, TextButton } from '../../../Reusable/Refresh/Button'
import { Text } from '../../../Reusable/Text'
import { Image } from '../../../Reusable/Image'
import { FromAccountOption, PaymentMethodOption } from '../../../Reusable/Option'
import Banner from '../../../Reusable/Banner'
import { addDataToStore, UPDATE_LOGO } from '../../../../Actions/actions';
import { LabeledInput } from '../../../Reusable/Input'
import { apiPOSTReq } from '../../../../Utils/api'
import { toCurrency, getBPAccountsWithAccess, getFeaturePerm } from '../../../../Utils/util'
import environment from '../../../../environment'
import { ErrorAlert } from '../../../Reusable/Alert'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import PoweredBy from '../../../../Images/PoweredByAion.png'
import Agreement from '../../../../Images/invoice-template/Agreement.png'
import Call from '../../../../Images/invoice-template/Call.png'
import Divider from '../../../../Images/invoice-template/Divider.png'
import Email from '../../../../Images/invoice-template/Email.png'
import Individual from '../../../../Images/invoice-template/Individual.png'
import Line from '../../../../Images/invoice-template/Line.png'
import Asterisk from '../../../../Images/asterisk.png'
import ModalClose from '../../../../Images/modal-close.png'
import AionIcon from '../../../../Images/aion-bank-icon.png'
import { CameraOutlined } from '@ant-design/icons';

const { Option } = Select
const { confirm } = Modal

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
}

function InvoicePreview(props) {
    var { customerId, sections, logoUrl, theme, getSection, getSectionField, onlinePaymentEnabled } = props
    sections = sections || []

    const skeletonStyle = { width: 180, height: 15, marginBottom: "2px" }
    const addressSkeletonStyle = { width: 200, height: 15, marginBottom: "2px" }
    const skeletonValueStyle = { width: 80, height: 18 }
    const tableFooterStyle = { width: 60, height: 18 }

    var lineItemTableColumns = []
    const lineItemHeaderSection = getSection("LineItemHeader") || {}
    const lineItemHeaderFields = lineItemHeaderSection.sectionFields || []

    const nameItem = lineItemHeaderFields.find(item => item.mappedName === "LineItemHeader_Name");
    const descriptionItem = lineItemHeaderFields.find(item => item.mappedName === "LineItemHeader_Desc");
    const qtyItem = lineItemHeaderFields.find(item => item.mappedName === "LineItemHeader_Qty");
    const priceItem = lineItemHeaderFields.find(item => item.mappedName === "LineItemHeader_Price");
    const amountItem = lineItemHeaderFields.find(item => item.mappedName === "LineItemHeader_Total");

    if (nameItem && nameItem.visible) lineItemTableColumns.push({ title: nameItem.fieldValue, render: (i, record) => <Skeleton.Input style={{ width: 60, height: 15 }} /> })
    if (descriptionItem && descriptionItem.visible) lineItemTableColumns.push({ title: descriptionItem.fieldValue, render: (i, record) => <Skeleton.Input style={{ width: 160, height: 15 }} /> })
    if (priceItem && priceItem.visible) lineItemTableColumns.push({ title: priceItem.fieldValue, align: 'right', render: (i, record) => <Skeleton.Input style={{ width: 40, height: 15 }} /> })
    if (qtyItem && qtyItem.visible) lineItemTableColumns.push({ title: qtyItem.fieldValue, align: 'right', render: (i, record) => <Skeleton.Input style={{ width: 40, height: 15 }} /> })
    if (amountItem && amountItem.visible) lineItemTableColumns.push({ title: amountItem.fieldValue, align: 'right', render: (i, record) => <Skeleton.Input style={{ width: 40, height: 15 }} /> })

    console.log("EditTemplate lineItemTableColumns", lineItemHeaderSection.sectionFields)

    return (
        <FlexColumn between style={{ padding: "10px", minHeight: "842pt", border: `1px solid ${theme.colors.systemGray6}`, borderRadius: "10px", width: "60%", boxShadow: "0 5px 25px 0px rgba(0,0,0,0.2)", position: "relative" }}>
            <FlexColumn start style={{ padding: 24 }}>
                {/* Customer info section */}
                <Flex between fullWidth>
                    {/* <Flex between> */}
                    <FlexColumn>
                        <Text heading>{getSectionField("CompanyInformation", "Name").fieldValue}</Text>
                        <Text color={'#666'}>
                            {getSectionField("CompanyInformation", "AddressLine1").fieldValue}
                            {getSectionField("CompanyInformation", "AddressLine2").fieldValue || null}<br />
                            {getSectionField("CompanyInformation", "City").fieldValue},
                            {` ${getSectionField("CompanyInformation", "State").fieldValue} `}
                            {getSectionField("CompanyInformation", "PostalCode").fieldValue}
                        </Text>
                    </FlexColumn>

                    {
                        getSection("CompanyLogo").visible && logoUrl &&
                        <FlexColumn center style={{ height: 88, marginRight: 24 }}>
                            <img src={logoUrl} style={{ height: '48px' }} />
                        </FlexColumn>
                    }
                    {/* </Flex> */}
                    {/* <Flex start centerHorizontally>
                        {getSectionField("Invoice", "Number").visible && <><Text heading style={{ marginRight: 4 }}>Invoice No.</Text><Skeleton.Input style={skeletonValueStyle} /></>}
                    </Flex> */}
                </Flex>

                <br />

                {
                    (getSectionField("CompanyInformation", "Phone").visible || getSectionField("CompanyInformation", "Email").visible || getSectionField("CompanyInformation", "Website").visible) &&
                    <Flex start centerHorizontally gap='8px'>
                        {
                            getSectionField("CompanyInformation", "Phone").visible &&
                            <>
                                <Image src={Call} />
                                <Text>{getSectionField("CompanyInformation", "Phone").fieldValue}</Text>
                            </>
                        }
                        {
                            getSectionField("CompanyInformation", "Email").visible &&
                            <>
                                {
                                    getSectionField("CompanyInformation", "Phone").visible &&
                                    <Image src={Divider} />
                                }
                                <Image src={Email} />
                                <Text>{getSectionField("CompanyInformation", "Email").fieldValue}</Text>
                            </>
                        }
                        {
                            getSectionField("CompanyInformation", "Website").visible &&
                            <>
                                {
                                    (getSectionField("CompanyInformation", "Phone").visible || getSectionField("CompanyInformation", "Email").visible) &&
                                    <Image src={Divider} />
                                }
                                <Text>{getSectionField("CompanyInformation", "Website").fieldValue}</Text>
                            </>
                        }
                    </Flex>
                }

                <div style={{ width: '100%', height: 1, background: '#E3E6EE', margin: '24px 0' }}></div>

                {/* <Flex start centerHorizontally style={{ margin: '0 0 24px' }} gap='8px'>
                    <Text size='20px'>Customer Name</Text>
                    <Skeleton.Input style={{ ...skeletonStyle, marginTop: 4 }} />
                </Flex> */}


                {
                    getSectionField("Invoice", "Number").visible &&
                    <div className="field-container" style={{ width: 180, marginBottom: 12 }}>
                        <Text heading style={{ marginRight: 4 }}>Invoice #</Text>
                        <Skeleton.Input style={{ ...skeletonValueStyle, marginTop: 2 }} />
                    </div>
                }

                <Flex start fullWidth>
                    {/* <FlexColumn start> */}
                    {/* <Flex start>
                            <FlexColumn start style={{ width: 180, marginLeft: 0 }}>
                                <div className="field-container" style={{ marginBottom: 12 }}>
                                    <span className="label-text">Invoice date</span>
                                    <Skeleton.Input style={skeletonValueStyle} />
                                </div>

                                <div className="field-container" style={{ marginBottom: 12 }}>
                                    <span className="label-text">Due date</span>
                                    <Skeleton.Input style={skeletonValueStyle} />
                                </div>
                            </FlexColumn>

                            <FlexColumn start style={{ width: 200, marginLeft: 48 }}>
                                <div className="field-container" style={{ marginBottom: 12 }}>
                                    <span className="label-text">Net terms</span>
                                    <Skeleton.Input style={skeletonValueStyle} />
                                </div>

                                <div className="field-container" style={{ marginBottom: 12 }}>
                                    <span className="label-text">Purchase order</span>
                                    <Skeleton.Input style={skeletonValueStyle} />
                                </div>
                            </FlexColumn>
                        </Flex> */}

                    {/* <Flex centerHorizontally> */}
                    <div className="address-container" style={{ marginLeft: 0, width: '33%' }}>
                        <span className="section-heading-text" style={{ marginBottom: 8, weight: 500 }}>Bill to</span>
                        {/* {customerId ?
                                    <Text color={theme.colors.systemGray}>
                                        {getSectionField("BillTo", "Name").fieldValue}<br />
                                        {getSectionField("BillTo", "AddressLine1").fieldValue}
                                        {getSectionField("BillTo", "AddressLine2").fieldValue || null}<br />
                                        {getSectionField("BillTo", "City").fieldValue},
                                        {getSectionField("BillTo", "State").fieldValue}
                                        {getSectionField("BillTo", "PostalCode").fieldValue}
                                    </Text>
                                    : */}
                        <FlexColumn>
                            <Skeleton.Input style={addressSkeletonStyle} />
                            <Skeleton.Input style={addressSkeletonStyle} />
                            <Skeleton.Input style={addressSkeletonStyle} />
                        </FlexColumn>
                        {/* } */}
                    </div>

                    {
                        getSection("ShipTo").visible &&
                        <div className="address-container" style={{ width: '33%' }}>
                            <span className="section-heading-text" style={{ marginBottom: 8 }}>Ship to</span>
                            {/* {customerId ?
                                    <Text color={theme.colors.systemGray}>
                                        {getSectionField("ShipTo", "Name").fieldValue}<br />
                                        {getSectionField("ShipTo", "AddressLine1").fieldValue}
                                        {getSectionField("ShipTo", "AddressLine2").fieldValue || null}<br />
                                        {getSectionField("ShipTo", "City").fieldValue},
                                        {getSectionField("ShipTo", "State").fieldValue}
                                        {getSectionField("ShipTo", "PostalCode").fieldValue}
                                    </Text>
                                    : */}
                            <FlexColumn>
                                <Skeleton.Input style={addressSkeletonStyle} />
                                <Skeleton.Input style={addressSkeletonStyle} />
                                <Skeleton.Input style={addressSkeletonStyle} />
                            </FlexColumn>
                            {/* } */}
                        </div>
                    }

                    <div className="address-container" style={{ marginLeft: 0, width: '33%' }}>
                        <span className="section-heading-text" style={{ marginBottom: 8 }}>Invoice details</span>
                        {/* {customerId ?
                                    <Text color={theme.colors.systemGray}>
                                        {getSectionField("BillTo", "Name").fieldValue}<br />
                                        {getSectionField("BillTo", "AddressLine1").fieldValue}
                                        {getSectionField("BillTo", "AddressLine2").fieldValue || null}<br />
                                        {getSectionField("BillTo", "City").fieldValue},
                                        {getSectionField("BillTo", "State").fieldValue}
                                        {getSectionField("BillTo", "PostalCode").fieldValue}
                                    </Text>
                                    : */}
                        <FlexColumn>
                            <Skeleton.Input style={addressSkeletonStyle} />
                            <Skeleton.Input style={addressSkeletonStyle} />
                            <Skeleton.Input style={addressSkeletonStyle} />
                        </FlexColumn>
                        {/* } */}
                    </div>

                    {/* </Flex> */}
                    {/* </FlexColumn> */}

                    {/* <FlexColumn start className="contact-container">
                        <span className="section-heading-text" style={{ marginBottom: 12 }}>CONTACT DETAILS</span>
                        <Flex start style={{ marginBottom: 12 }} gap='8px' centerHorizontally>
                            <Image src={Individual} />
                            <Skeleton.Input style={skeletonStyle} />
                        </Flex>
                        <Flex start style={{ marginBottom: 12 }} gap='8px' centerHorizontally>
                            <Image src={Call} />
                            <Skeleton.Input style={skeletonStyle} />
                        </Flex>
                        <Flex start gap='8px' centerHorizontally>
                            <Image src={Email} />
                            <Skeleton.Input style={skeletonStyle} />
                        </Flex>
                    </FlexColumn> */}
                </Flex>

                {
                    getSection("ShippingDetails").visible &&
                    <>
                        <div style={{ width: '100%', height: 1, background: '#E3E6EE', margin: '16px 0 12px' }}></div>
                        <Flex start fullWidth style={{ marginTop: 0 }}>

                            <div className="address-container" style={{ marginLeft: 0, width: '33%' }}>
                                <span className="section-heading-text" style={{ marginBottom: 8, weight: 500 }}>Ship date</span>
                                <FlexColumn>
                                    <Skeleton.Input style={addressSkeletonStyle} />
                                </FlexColumn>
                            </div>

                            {
                                getSection("ShipTo").visible &&
                                <div className="address-container" style={{ width: '33%' }}>
                                    <span className="section-heading-text" style={{ marginBottom: 8 }}>Ship via</span>
                                    <FlexColumn>
                                        <Skeleton.Input style={addressSkeletonStyle} />
                                    </FlexColumn>
                                </div>
                            }

                            <div className="address-container" style={{ marginLeft: 0, width: '33%' }}>
                                <span className="section-heading-text" style={{ marginBottom: 8 }}>Tracking #</span>
                                <FlexColumn>
                                    <Skeleton.Input style={addressSkeletonStyle} />
                                </FlexColumn>
                            </div>
                        </Flex>
                    </>
                }

                {/* Line item section */}
                <Table
                    size="small"
                    id="inv-table"
                    className="inv-template-table"
                    tableLayout='auto'
                    columns={lineItemTableColumns}
                    dataSource={[{ id: 1 }, { id: 2 }]}
                    rowKey='id'
                    pagination={false}
                    style={{ marginTop: "20px" }}
                    scroll={{ y: '700px', x: '100%' }}
                />

                <FlexColumn right fullWidth>
                    <Flex between style={{ width: 200, height: 36, paddingRight: 8 }} centerHorizontally><span className="table-subtitle">Subtotal</span><Skeleton.Input style={tableFooterStyle} /></Flex>
                    <div style={{ width: '100%', height: 1, background: '#F2F2F2' }} />
                    <Flex between style={{ width: 200, height: 36, paddingRight: 8 }} centerHorizontally><span className="table-subtitle">Discount</span><Skeleton.Input style={tableFooterStyle} /></Flex>
                    <div style={{ width: '100%', height: 1, background: '#F2F2F2' }} />
                    <Flex between style={{ width: 200, height: 36, paddingRight: 8, paddingLeft: 19 }} centerHorizontally><span className="table-subtitle">Taxes</span><Skeleton.Input style={tableFooterStyle} /></Flex>
                    <div style={{ width: '100%', height: 1, background: '#F2F2F2' }} />
                    <Flex between style={{ width: 200, height: 36, paddingRight: 8, paddingLeft: 22 }} centerHorizontally><span className="table-title">Total</span><Skeleton.Input style={tableFooterStyle} /></Flex>
                    <div style={{ width: '100%', height: 1, background: '#F2F2F2' }} />
                </FlexColumn>

                {
                    !onlinePaymentEnabled &&
                    <>
                        {getSection("PaymentDetails").visible &&
                            (getSectionField("PaymentDetails", "Account Number").visible || getSectionField("PaymentDetails", "ACH Routing Number").visible || getSectionField("PaymentDetails", "Wire Routing Number").visible ||
                                getSectionField("PaymentDetails", "Check Address").visible || getSectionField("PaymentDetails", "Bank Name").visible || getSectionField("PaymentDetails", "Bank Address").visible || getSectionField("PaymentDetails", "Footer").visible) &&
                            <FlexColumn start className="payment-detials-container" style={{ marginTop: 48 }}>
                                <span className="section-heading-text" style={{ marginBottom: 16 }}>Payment Details</span>
                                {/* {getSectionField("PaymentDetails", "Header").visible && <span style={{ fontSize: "1.1rem", fontWeight: 500 }}>{getSectionField("PaymentDetails", "Header").fieldValue ? getSectionField("PaymentDetails", "Header").fieldValue : "Add payment header here"}<br /></span>}<br /> */}

                                {getSectionField("PaymentDetails", "Account Number").visible && <Flex between style={{ marginBottom: 8 }}>
                                    <span className="label-text payment-details-label">Account number:</span><span className="value-text">{getSectionField("PaymentDetails", "Account Number").fieldValue}</span>
                                </Flex>}
                                {getSectionField("PaymentDetails", "ACH Routing Number").visible && <Flex between style={{ marginBottom: 8 }}>
                                    <span className="label-text payment-details-label">ACH routing number:</span><span className="value-text">{getSectionField("PaymentDetails", "ACH Routing Number").fieldValue}</span>
                                </Flex>}
                                {getSectionField("PaymentDetails", "Wire Routing Number").visible && <Flex between style={{ marginBottom: 8 }}>
                                    <span className="label-text payment-details-label">Wire routing number:</span><span className="value-text">{getSectionField("PaymentDetails", "Wire Routing Number").fieldValue}</span>
                                </Flex>}
                                {/* {getSectionField("PaymentDetails", "Name").visible && <span>Bank Name: {getSectionField("PaymentDetails", "Name").fieldValue} <br /></span>} */}
                                {getSectionField("PaymentDetails", "Check Address").visible && <Flex between style={{ marginBottom: 8 }}>
                                    <span className="label-text payment-details-label">Check address:</span><span className="value-text">{getSectionField("PaymentDetails", "Check Address").fieldValue}</span>
                                </Flex>}
                                {getSectionField("PaymentDetails", "Bank Name").visible && <Flex between style={{ marginBottom: 8 }}>
                                    <span className="label-text payment-details-label">Bank name:</span><span className="value-text">{getSectionField("PaymentDetails", "Bank Name").fieldValue}</span>
                                </Flex>}
                                {getSectionField("PaymentDetails", "Bank Address").visible && <Flex between style={{ marginBottom: 8 }}>
                                    <span className="label-text payment-details-label">Bank address:</span><span className="value-text">{getSectionField("PaymentDetails", "Bank Address").fieldValue}</span>
                                </Flex>}
                                {/* {getSectionField("PaymentDetails", "Note").visible && <span>Note: {getSectionField("BankDetails", "Note").fieldValue}<br /><br /></span>} */}
                                <br />
                                {getSectionField("PaymentDetails", "Footer").visible && <Text size='14px' color='#333'>{getSectionField("PaymentDetails", "Footer").fieldValue}</Text>}
                            </FlexColumn>
                        }
                    </>
                }

                <br />
                {
                    getSectionField("Messages", "Text").visible &&
                    <Text>{getSectionField("Messages", "Text").fieldValue}</Text>
                }
            </FlexColumn>

            <Flex center fullWidth style={{ paddingBottom: 8 }}>
                <Image src={PoweredBy} width="156px" height="24px" />
            </Flex>
        </FlexColumn >
    )
}

class EditTemplate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            loadingCustomers: true,
        }

        this.nameRef = React.createRef()
    }

    showConfirm = (ok, cancel) => {
        confirm({
            title: 'Add your company logo',
            icon: <ExclamationCircleOutlined />,
            content: "Make your invoice look professional with your company's logo. Click 'OK' to go to 'Profile' to add it now.",
            onOk() {
                ok()
            },
            onCancel() {
                cancel()
            },
        })
    }

    componentDidMount() {
        this.fetchCustomers()
        const businessInfo = this.props.aionStore?.business?.businessProfile?.businessInfo;
        this.setState({ logoUrl: businessInfo.logoUrl })

        if (!businessInfo.logoUrl) this.setState({ showLogoModal: true })

        var { template, editing } = this.props

        var Accounts = getBPAccountsWithAccess("Payments", { permType: "manage", getActiveAccounts: true })
        var account = Accounts.find(a => a.accountNumber === template?.accountNumber) || Accounts[0]

        this.setState({ 
            editing: editing, 
            onlinePaymentEnabled: template?.onlinePaymentEnabled, 
            fromAccount: account, 
            templateName: template?.displayName || template?.templateName 
        })
    }

    getSection = (sectionName) => {
        var { template } = this.props
        var { sections } = template
        sections = sections || []
        return sections.find(item => (item.sectionName === sectionName)) || {}
    }

    getSectionField = (sectionName, fieldName) => {
        var { template } = this.props
        var { sections } = template
        sections = sections || []
        const section = sections.find(item => (item.sectionName === sectionName))
        const sectionFields = section.sectionFields || []
        return sectionFields.find(item => (item.fieldName === fieldName)) || {}
    }

    handleTextChange = (event) => {
        var sectionInfo = event.target.id.split("+")
        var sectionField = this.getSectionField(sectionInfo[0], sectionInfo[1])
        console.log("handleTextChange sectionField", sectionField)
        if (sectionField.fieldName) sectionField.fieldValue = event.target.value
        else {
            var section = this.getSection(sectionInfo[0])
            section.sectionFields.push({
                "fieldName": sectionInfo[1],
                "mappedName": `CompanyInfo_${sectionInfo[1]}`,
                "fieldValue": event.target.value,
                "visible": true,
                "optional": false,
                "editable": true
            })
        }
        this.setState({})
    }

    handleCustomerSelect = (value, option) => {
        if (option) {
            var { template } = this.props
            template.customerId = value
            template.customerName = option.name
            this.setState({})
        }
    }

    updateTemplateName = (event) => {
        // var { template } = this.props
        // template.templateName = event.target.value
        this.setState({ templateName: event.target.value })
    }

    handleSwitch = (item, checked) => {
        item.visible = checked
        this.setState({})
    }

    handleSubmit = () => {
        const { template } = this.props
        const { editing, onlinePaymentEnabled, fromAccount, templateName } = this.state
        var Accounts = getBPAccountsWithAccess("Payments", { permType: "manage", getActiveAccounts: true })
        if (!template.templateName) {
            this.setState({ errorField: 'templateName' })
            this.nameRef.current.focus()
            return;
        }
        if (editing) {
            template.displayName = templateName
            // template.templateName = editingTemplateName
        } else {
            template.templateName = template.templateName
        }
        template.onlinePaymentEnabled = onlinePaymentEnabled
        if (onlinePaymentEnabled) template.accountNumber = Accounts?.length > 1 ? fromAccount?.accountNumber : Accounts[0].accountNumber
        var body = {
            "template": template
        }
        this.setState({ loading: true })

        console.log("EditTemplate /ive/templates/save body", body)
        console.log("EditTemplate /ive/templates/save fromAccount", fromAccount)

        apiPOSTReq(`${environment.iveBaseUrl}/ive/templates/save`, {}, body, (err, resp) => {
            try {
                const data = resp || {}
                console.log("EditTemplate /ive/templates/save", data)
                if (data.result) {
                    this.setState({
                        loading: false
                    })
                    this.props.onSave()
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /ive/templates/save", error, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    fetchCustomers() {
        // Fetch customer list
        const headers = {
            // AionCurrentBiz: this.props.aionStore.BusinessUniqueKey,
            // AionAuth: this.props.aionStore.UAM.encryptedAuthHeader
        }
        const body = {
            "size": 5000,
            "page": 0
        }
        this.setState({ loadingCustomers: true })
        apiPOSTReq(`${environment.iveBaseUrl}/ive/bc/getActiveCustomers`, headers, body, (err, resp) => {
            try {
                this.setState({ loadingCustomers: false })
                const data = resp
                console.log("/getActiveCustomers", data)
                if (data.result) {
                    this.setState({
                        activeCustomers: data.customers
                    })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getActiveCustomers", error.stack, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    // Save logo URL
    saveLogoUrl = () => {
        apiPOSTReq(`${environment.uamBaseUrl}/user/admin/updateLogo`, null, { logoUrl: this.state.logoUrl }, (err, resp) => {
            try {
                const data = resp || {};
                console.log("/updateLogo", this.state.logoUrl, data)
                if (data.result) {
                    // Update business profile
                    this.props.dispatch(addDataToStore(UPDATE_LOGO, this.state.logoUrl));
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR updateLogo", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    handleAionChange = (e) => {
        this.setState({ onlinePaymentEnabled: e.target.checked })
    };

    handleBankChange = (e) => {
        this.setState({ onlinePaymentEnabled: !e.target.checked })
    };

    handleSelectAccount = (value, option) => {
        var Accounts = getBPAccountsWithAccess("Payments", { permType: "manage", getActiveAccounts: true })
        var account = Accounts.find(a => a.accountId === value)
        console.log("EditTemplate /ive/templates/save handleSelectAccount", account)
        this.setState({ fromAccount: account })
    }

    render() {
        const { loading, activeCustomers, loadingCustomers, logoUrl, errorField, showLogoModal, payByAion, onlinePaymentEnabled, fromAccount, templateName } = this.state
        const { theme, template, paymentDetailsOpen } = this.props

        var { business } = this.props.aionStore;
        var { businessInfo } = business.businessProfile || {};
        businessInfo = businessInfo || {};

        const logoSize = 64;
        var uploadProps = {
            action: `${environment.apiBaseUrl}/file/upload`,
            headers: {
                businesskey: this.props.aionStore.BusinessUniqueKey,
                type: "logo",
                jwt: this.props.aionStore.jwt
            },
            onChange: (info) => {
                var { fileList } = info;
                fileList.forEach(file => {
                    const { status, response, name, url } = file;
                    console.log("fileList response", response);
                    if (status === "done") {
                        this.setState({
                            logoUrl: (response.UploadedUrls || [])[0],
                            showLogoModal: false,
                        });
                        this.saveLogoUrl();
                    }
                });
            }
        };

        var Accounts = getBPAccountsWithAccess("Payments", { permType: "manage", getActiveAccounts: true })
        var options = []
        var filteredAccounts = Accounts?.filter(item => { return (!['ACCOUNTS_RECEIVABLE', 'Transitional'].includes(item.accountType)) }) || []
        filteredAccounts.forEach(account => {
            options.push(
                <Option key={account.accountId} value={account.accountId} style={{ backgroundColor: "transparent" }}>
                    <FromAccountOption account={account} />
                </Option>)
        })

        const showPayByBank = getFeaturePerm("Receivables.Invoices.PayByBank").view

        return (<>
            <FlexColumn left style={{ margin: '0 40px' }}>
                <Text weight='500' id='title' size='28px' height='40px'>Invoice Customization</Text>
                <Flex style={{ justifyContent: "flex-start", alignItems: "start", marginTop: 24 }}>
                    <InvoicePreview {...template} logoUrl={logoUrl} theme={theme} getSection={this.getSection} getSectionField={this.getSectionField} onlinePaymentEnabled={onlinePaymentEnabled} />
                    <FlexColumn style={{ marginLeft: "50px" }}>
                        <Text color={theme.colors.systemGray} margin='0 0 12px'>Make any customizations to your template here</Text>

                        <Collapse defaultActiveKey={paymentDetailsOpen ? "PaymentDetails" : "TemplateInfo"} bordered={true} style={{ minWidth: "450px" }}>
                            <Collapse.Panel header="Template Information" key="TemplateInfo">
                                <LabeledInput
                                    inputRef={this.nameRef}
                                    label="Template Name"
                                    labelcolor={theme.colors.secondary3}
                                    id="templateName"
                                    key="templateName"
                                    placeholder="Enter a name"
                                    value={templateName}
                                    onChange={this.updateTemplateName}
                                    error={errorField === "templateName"}
                                    errorMessage={"This is a mandatory field"}
                                />
                                {
                                    template.default === false &&
                                    <LabeledInput
                                        optional
                                        label="Customer"
                                        labelcolor={theme.colors.secondary3}
                                        id="customerId"
                                        key="customerId"
                                        type="select"
                                        className="no-left-padding"
                                        placeholder="Select"
                                        value={template.customerName}
                                        loading={loadingCustomers}
                                        onChange={this.handleCustomerSelect}
                                        instruction="If you select a customer, for any new invoices to this customer, we will use this template"
                                        filterOption={(input, option) => {
                                            return option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }}
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        onClear={() => {
                                            template.customerName = null
                                            template.customerId = null
                                            this.setState({})
                                        }}
                                    >
                                        {(activeCustomers || []).map(item => <Select.Option key={item.customerId} id="customerName" name={item.displayName || item.companyName} value={item.customerId} style={{ backgroundColor: "transparent" }}>{item.displayName || item.companyName}</Select.Option>)}
                                    </LabeledInput>
                                }
                            </Collapse.Panel>
                            {template.sections.map(section => {
                                return (section.editable) ?
                                    (
                                        <Collapse.Panel header={section.displayName || section.sectionName} key={section.sectionName}>
                                            {
                                                (showPayByBank && section.sectionName === "PaymentDetails") &&
                                                <FlexColumn start gap='16px' style={{ marginTop: 16 }}>
                                                    <LightContainer className="radio-table" fullWidth>
                                                        <Flex start style={{ cursor: 'pointer', marginBottom: 16 }} onClick={() => this.setState({ onlinePaymentEnabled: !onlinePaymentEnabled })}>
                                                            <Checkbox checked={onlinePaymentEnabled} onChange={this.handleAionChange} />
                                                            <FlexColumn style={{ margin: '0 0 0 8px' }} gap='8px'>
                                                                <Text size="16px" weight={onlinePaymentEnabled ? '700' : '400'}>Accept online payments</Text>
                                                                <Text lightText>Your customers can pay your invoice via bank transfer using Aion’s payment portal</Text>
                                                            </FlexColumn>
                                                        </Flex>

                                                        {
                                                            (onlinePaymentEnabled) &&
                                                            <>
                                                                <LabeledInput
                                                                    nomargin
                                                                    label="Where would you like to receive payment?"
                                                                    type="select"
                                                                    id="fromAccount"
                                                                    // width='276px'
                                                                    placeholder="Select account"
                                                                    value={fromAccount && <Flex between centerHorizontally style={{ height: 30 }}>
                                                                        <Text><img width='16px' height='16px' src={AionIcon} style={{ marginRight: 8, marginBottom: 2 }} />{`${(fromAccount.nickName || `Business ${fromAccount.accountSubType}`)} • ${fromAccount.mask}`}</Text>
                                                                        <Text size='14px' lightText style={{ marginLeft: 8 }}>${toCurrency(fromAccount.availableBalance)}</Text>
                                                                    </Flex>}
                                                                    onChange={this.handleSelectAccount}
                                                                // noAsterisk
                                                                // error={errorField === "fromAccount"}
                                                                // errorMessage={errorMessage}
                                                                >
                                                                    {options}
                                                                </LabeledInput>
                                                            </>
                                                        }
                                                    </LightContainer>

                                                    <LightContainer className="radio-table" fullWidth>
                                                        <Flex start style={{ cursor: 'pointer' }} onClick={() => this.setState({ onlinePaymentEnabled: !onlinePaymentEnabled })}>
                                                            <Checkbox checked={!onlinePaymentEnabled} onChange={this.handleBankChange} />
                                                            <FlexColumn style={{ margin: '0 0 0 8px' }} gap='8px'>
                                                                <Text size="16px" weight={!onlinePaymentEnabled ? '700' : '400'}>Send payment instructions</Text>
                                                                <Text lightText>Send specific payment instructions to your customers’ on how to pay</Text>
                                                            </FlexColumn>
                                                        </Flex>
                                                    </LightContainer>
                                                </FlexColumn>
                                            }
                                            {section.optional &&
                                                <Space style={{ marginTop: 12 }}>
                                                    <span style={{ fontSize: "0.9rem", color: theme.colors.secondary7 }}>Toggle to show/hide section? </span>
                                                    <Switch size="small" checked={section.visible} onChange={(checked) => {
                                                        this.handleSwitch(section, checked)
                                                        section.sectionFields.forEach(sectionField => this.handleSwitch(sectionField, checked))
                                                    }} />
                                                </Space>
                                            }
                                            {section.description ? <Text color={theme.colors.systemGray}>{section.description}</Text> : null}
                                            {
                                                (section.sectionName !== "PaymentDetails" || !onlinePaymentEnabled) && section.sectionFields.map(sectionField => {
                                                    return (sectionField.editable) ?
                                                        <LabeledInput
                                                            customlabelcomponent={
                                                                <Flex between centerHorizontally fullWidth>
                                                                    <Flex start style={{ marginBottom: 4 }}>
                                                                        <Text size='14px' color='#7384AA'>{`${sectionField.fieldName}`}</Text>
                                                                        {
                                                                            sectionField.optional ?
                                                                                <Switch size="small"
                                                                                    style={{ marginLeft: 12, marginBottom: 4 }}
                                                                                    checked={sectionField.visible}
                                                                                    onChange={(checked) => this.handleSwitch(sectionField, checked)}
                                                                                />
                                                                                :
                                                                                <>
                                                                                    {
                                                                                        sectionField.fieldName !== 'Text' &&
                                                                                        <Image width='5px' height='5px' margin='4px 0 0 4px' src={Asterisk} />
                                                                                    }
                                                                                </>
                                                                        }
                                                                    </Flex>
                                                                    <Flex>
                                                                        {(sectionField.optional || sectionField.fieldName === 'Text') && <Text style={{ marginBottom: 4 }} size='12px' color={theme.colors.greySecondary}>optional</Text>}
                                                                    </Flex>
                                                                </Flex>
                                                            }
                                                            id={`${section.sectionName}+${sectionField.fieldName}`}
                                                            key={`${section.sectionName}+${sectionField.fieldName}`}
                                                            placeholder={sectionField.fieldName}
                                                            value={sectionField.fieldValue}
                                                            onChange={this.handleTextChange}
                                                            type={(section.sectionName == "Messages") ? "text-area" : null}
                                                        />
                                                        : null
                                                })}
                                        </Collapse.Panel>
                                    )
                                    : null
                            })}
                        </Collapse>
                    </FlexColumn>
                </Flex>
                <Button solid permtype="Receivables.Invoices" onClick={this.handleSubmit} style={{ marginTop: 48, width: 107 }} loading={loading} text='Save' />
            </FlexColumn>


            <Modal
                visible={showLogoModal}
                footer={null}
                closable={true}
                width={420}
                destroyOnClose={true}
                // style={{ top: 20 }}
                onCancel={() => { this.setState({ showLogoModal: false }) }}
                closeIcon={<img width='24px' height='24px' src={ModalClose} />}
            >
                <FlexColumn gap='24px'>
                    <Text heading>Add your company logo</Text>
                    <Text margin='0 0 12px'>Make your invoice look professional with your company's logo</Text>

                    <LabeledInput
                        nomargin
                        {...uploadProps}
                        type='upload'
                        noAsterisk
                    />

                </FlexColumn>
            </Modal>
        </>
        )
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(EditTemplate)))