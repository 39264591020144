import React, { Component } from 'react'
import environment from '../../environment'
import { Select, Skeleton } from 'antd'
import { ErrorAlert } from './Alert'
import { Flex, FlexColumn } from './Container'
import { Button, TextButton } from './Button'
import { apiPOSTReq } from '../../Utils/api'
import { ONB_SAVE_DATA, REMOTE_SAVE_DATA, addDataToStore, CODAT_DATA } from '../../Actions/actions'
import { LabeledInput } from './Input'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'
import { Tag, Text } from './Text'
import { CheckCircleFilled, CheckCircleTwoTone } from '@ant-design/icons'
import { StyledLabel } from './Refresh/Input'
import { StyledExtLink } from './Link'

const { Option } = Select

class AccountingSysConnect extends Component {
    constructor(props) {
        super(props)
        const { referralCode } = props.aionStore;
        this.state = {
            loading: false,
            intLoading: false,
            integrations: [],
            selectedIntegration: this.props.onbStore.selectedIntegration,
            showUploadDocsManually: false,
            showUploadBankStatements: false,
            referralCode: referralCode,
        }
    }

    componentDidMount() {
        var { referralCode } = this.state
        const urlStr = this.props.location.search.replace('?', '')
        const queryParams = new URLSearchParams(urlStr)
        const statusCode = queryParams.get('statuscode')
        const errorMessage = queryParams.get('errormessage')
        const statusText = queryParams.get('statustext')
        // http://{website}/{path}/?statuscode={statusCode}&errormessage={errorMessage}&statusText={statusText}
        // Check if redirected from codat
        if (statusCode) {
            if (statusCode == 200) {
                this.updateConnection()
            } else {
                ErrorAlert({ description: errorMessage || statusText || "Sorry we had trouble processing your request. Please try connecting again" })
            }
        } else {
            this.getConnection()
        }
        this.getIntegrations()

        if (referralCode) setTimeout(() => {
            this.setState({ showUploadDocsManually: true });
        }, 30000)
    }

    getConnection = () => {
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/codat/getConnections`, {}, {}, (err, resp) => {
            try {
                const data = resp || {};
                if (data.result) {
                    var connections = data.connections || []
                    if (connections.find(i => (i.status == "Linked")) && this.props.onAccountingSysConnected) this.props.onAccountingSysConnected({ accountingSysConnected: true })
                    this.setState({ connections: connections, connection: data.connection, company: data.company }) // company is a codat company construct
                    this.props.dispatch(addDataToStore(ONB_SAVE_DATA, { codatData: { connections: connections, connection: data.connection, company: data.company } }))
                    this.props.dispatch(addDataToStore(CODAT_DATA, { connections: connections, connection: connections.length > 0 && connections[0], company: data.company }))
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                ErrorAlert({ description: error.message })
            }
        })
    }

    createConnection = (options, callback) => {
        var body = {
            platformKey: options.platformKey,
            logoUrl: options.logoUrl
        }

        apiPOSTReq(`${environment.integrationBaseUrl}/integration/codat/createConnection`, {}, body, (err, resp) => {
            try {
                const data = resp || {}
                if (data.result) {
                    this.setState({ connection: data.connection })
                    this.props.dispatch(addDataToStore(ONB_SAVE_DATA, { codatData: { connection: data.connection, company: this.state.company } }))
                    callback(data.connection)
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                this.setState({ loading: false })
                callback(null)
                ErrorAlert({ description: error.message })
            }
        })
    }

    updateConnection = () => {
        var { codatData } = this.props.onbStore
        var { connection } = codatData
        console.log("updateConnection", this.props)
        if (!connection) {
            this.getConnection()
            return
        }
        var body = {
            companyId: connection.companyId,
            connectionId: connection.connectionId
        }
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/codat/updateConnection`, {}, body, (err, resp) => {
            try {
                const data = resp || {};
                if (data.result) {
                    if (!data.connection && !data.company) {
                        // Create a new connection
                        this.getIntegrations()
                    } else {
                        this.getIntegrations()
                        this.getConnection()
                        if (connection.status == "Linked" && this.props.onAccountingSysConnected) this.props.onAccountingSysConnected({ accountingSysConnected: true })
                        this.setState({ connection: data.connection, company: data.company }) // company is a codat company construct
                    }
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                ErrorAlert({ description: error.message })
            }
        })
    }

    getIntegrations = () => {
        this.setState({ intLoading: true })
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/codat/getIntegrations`, {}, {}, (err, resp) => {
            try {
                this.setState({ intLoading: false })
                const data = resp || {}
                if (data.result) {
                    this.setState({ integrations: data.integrations })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                ErrorAlert({ description: error.message })
            }
        })
    }

    beginAuth = () => {
        var { connection, selectedIntegration } = this.state
        var { location } = this.props
        var { linkUrl } = connection || {}
        var { pathname } = location
        this.setState({ loading: true })
        if (connection) {
            var redirectUri = `${linkUrl}?website=${environment.uiBaseUrl}&path=${pathname}`
            window.location.href = redirectUri
        } else {
            this.createConnection({ platformKey: selectedIntegration.key, logoUrl: selectedIntegration.logoUrl }, (result) => {
                if (result) {
                    linkUrl = result.linkUrl
                    var redirectUri = `${linkUrl}?website=${environment.uiBaseUrl}&path=${pathname}`
                    window.location.href = redirectUri
                }
            })
        }
    }

    handleIntegrationSelect = (key) => {
        var { integrations } = this.state
        const { isCreditPlus } = this.props;
        var selectedIntegration = integrations.find(integration => integration.key == key)
        this.setState({ selectedIntegration: selectedIntegration }, () => this.beginAuth())
        this.props.dispatch(addDataToStore(ONB_SAVE_DATA, { selectedIntegration: selectedIntegration }))
        this.props.dispatch(addDataToStore(ONB_SAVE_DATA, { isCreditPlus: true }));
        if (isCreditPlus) {
            this.props.dispatch(addDataToStore(REMOTE_SAVE_DATA, "Apply.GetStarted"));
        }
        // else {
        //     this.props.dispatch(addDataToStore(REMOTE_SAVE_DATA, "Apply.Ownership"));
        // }
    }

    render() {
        var { connections, integrations, selectedIntegration, errorField, errorMessage, loading, intLoading, showUploadDocsManually } = this.state
        selectedIntegration = selectedIntegration || {}
        connections = connections || []

        var sysConnected = connections.find(c => c.status == "Linked")

        if (sysConnected) {
            integrations = integrations.filter(i => {
                var c = connections.find(c => c.integrationKey == i.key) || {}
                return (c.status == "Linked")
            })
        }

        var { inputWidth, showUploadOption, readonly } = this.props

        var intOptions = integrations.map(item => {
            var connection = connections.find(c => c.integrationKey == item.key) || {}
            return (
                <FlexColumn center key={item.key} gap='5px' style={{ border: `1px solid ${this.props.theme.colors.systemGray5}`, borderRadius: '5px', padding: '30px 30px' }}>
                    <Flex gap='8px' start centerHorizontally style={{ height: 72 }}>
                        <img height={72} width={150} style={{ objectFit: 'contain' }} src={item.logoUrl} />
                    </Flex>
                    {
                        (connection.status == "Linked") ?
                            <Tag size="16px" color={this.props.theme.colors.secondary3}><Flex gap='8px' start centerHorizontally>Connected</Flex></Tag>
                            :
                            <Button permtype="Override" loading={loading && (selectedIntegration.key == item.key)} onClick={() => {
                                this.handleIntegrationSelect(item.key)
                                // this.beginAuth()
                            }} text={'Connect'} />
                    }
                </FlexColumn>
            )
        }
        )

        if (readonly && !sysConnected) return (<></>)

        return (
            <FlexColumn left bottom gap='8px'>
                <Flex start wrap gap="24px" style={{ maxWidth: '1000px' }}>
                    {intOptions}

                    {
                        showUploadDocsManually && showUploadOption && !sysConnected &&
                        <FlexColumn start gap='5px' style={{ width: 212, height: 179 }} center>
                            <Text>Unable to connect?</Text>
                            <StyledExtLink
                                key="StyledExtLink"
                                style={{ textAlign: "center", fontSize: "14px", lineHeight: "24px", textDecoration: "underline" }}
                                onClick={() => this.props.onClickManualUploadModal()}
                            >
                                Click here
                            </StyledExtLink>
                        </FlexColumn>
                    }
                </Flex>
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        onbStore: state.onboardingAppReducer,
        aionStore: state.aionAppReducer,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(AccountingSysConnect))