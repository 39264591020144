import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import { Modal } from 'antd';
import { FlexColumn, Container } from '../../Reusable/Container';
import PageHeader from '../../Reusable/PageHeader';
import { Button } from '../../Reusable/Button';
import { apiGET, apiPOSTReq } from '../../../Utils/api';
import environment from '../../../environment';
import { addDataToStore, BNK_SAVE_DATA } from '../../../Actions/actions';
import AccountsTable from './AccountsTable';
import AddBankAccount from './AddBankAccount';

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            pagination: {
                current: 1,
                pageSize: 100,
                showSizeChanger: false,
                sorter: {},
            },
            sortDirection: "DESC",
        }
    }

    componentDidMount = () => {
        this.refreshAppData();
        this.fetchBBAccounts({ pagination: this.state.pagination});
    }

    handleOnChange = (event) => {
        const target = event.target;
        this.setState({ [target.id]: target.value })
    }

    refreshAppData = (reload) => {
        apiGET("/banking/radius/dashboard", null, (err, resp) => {
            try {
                if (err) throw new Error(err);
                this.props.dispatch(addDataToStore(BNK_SAVE_DATA, resp.data));
                if (reload) window.location.reload();
                this.setState({ loading: false });
            } catch (error) {
                console.log("refreshAppData", error.message);
            }
        });
    }
    fetchBBAccounts = (options) => {
        var { sortDirection, sortFieldname } = this.state
        var { pagination, sorter } = options;
        sorter = sorter || {}

        var body = {
            "size": pagination.pageSize,
            "page": pagination.current - 1,
            "source": "AION|ARUPLOAD|QUICKBOOKS|CODAT",
            "viewBy": "FILTER_SOURCE",
            // "sortDirection": sortDirection
        }
        console.log("function called")

        if (sorter.field) {
            if (sorter.field === sortFieldname) {
                body.sortDirection = (sortDirection === "DESC") ? "ASC" : "DESC"
            } else {
                body.sortDirection = "ASC"
            }
            body.sortFieldname = sorter.field
        } else {
            if (sortFieldname) {
                body.sortFieldname = sortFieldname
            }
            body.sortDirection = sortDirection
        }

        this.setState({ loading: true });

        apiPOSTReq(`${environment.bbBaseUrl}/bb/getAccounts`, { "BankProvider": environment.bankProvider.crb }, null, (err, resp) => {
            try {
                console.log("/bb/getAccounts", err, resp);
                const data = resp;
                if (data.result) {
                    if (err) throw new Error(err);
                    var accounts = data.bbaccounts;
                    this.props.dispatch(addDataToStore(BNK_SAVE_DATA, { BBAccounts: accounts }))
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
                this.refreshAppData();
            } catch (error) {
                console.log("ERRR getAccounts", error, err, resp);
                this.refreshAppData();
            }
        })
    }


    showSelectedAccount = (selectedAccount) => {
        this.setState({ selectedAccount: selectedAccount, showAccountModal: true })
    }

    render() {
        const { loading, pagination, sorter, selectedRowKeys } = this.state;
        const BBAccounts = this.props.bankingStore.BBAccounts;

        return (
            <>
                <FlexColumn className='main-padding'>

                    <PageHeader
                        titleText="Bank Accounts"
                        desc="List of all your accounts"
                        
                    />
                    <AccountsTable
                        showSelectedAccount={this.showSelectedAccount}
                        loading={loading}
                        pagination={pagination}
                        sorter={sorter}
                        fetchBBAccounts={this.fetchBBAccounts}
                        bbAccounts={BBAccounts}
                        handleRowSelection={this.handleRowSelection}
                        showRowSelection={true}
                        selectedRowKeys={selectedRowKeys}
                        hideEditColumn={true}
                        ctaContent={<Button solid loading={this.state.newAccountLoading} disabled={false} style={{ height: "45px" }} onClick={() => this.setState({ showNewAccountModal: true })} text='New Bank Account' />}
                    />
                </FlexColumn>
                <Modal
                    visible={this.state.showNewAccountModal}
                    footer={null}
                    closable={true}
                    width={700}
                    style={{ top: 20 }}
                    destroyOnClose={true}
                    onCancel={() => { this.setState({ showNewAccountModal: false, selectedAccount: null }) }}
                >
                    <AddBankAccount
                        account={this.state.selectedAccount}
                        submitComplete={
                            () => {
                                this.setState({ showNewAccountModal: false, selectedAccount: null });
                                this.fetchBBAccounts({ pagination: this.state.pagination });
                                this.refreshAppData(true);
                            }
                        }
                    />
                </Modal>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer,
        bankingStore: state.bankingAppReducer,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Index));