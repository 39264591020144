import React, { useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { ReactMultiEmail } from 'react-multi-email'
import { Input, InputNumber, DatePicker, Select, Radio, Upload, Popover, Cascader, Checkbox, Tooltip } from 'antd'
import { Text, Paragraph } from './Text'
import { Image } from './Image'
import { Button, TextButton, Chip, ImageButton } from './Button'
import { FlexColumn, Flex, InputContainer } from './Container'
import LocationSearchInput from './LocationSearchInput'
import { copyToClipboard, phoneCountryCode } from '../../Utils/util'
import { InfoCircleOutlined, LoadingOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons'
import UploadIcon from '../../Images/upload.svg'
import UploadIconDisabled from '../../Images/upload-disabled.svg'
import Copy from '../../Images/copy.png'
import CopyMute from '../../Images/copy-mute.svg'
import Visibility from "../../Images/visibility-blue.png"
import VisibilityOff from "../../Images/visibility-off.png"
import VisibilityMute from "../../Images/visibility-mute.svg"
import VisibilityOffMute from "../../Images/visibility-mute-off.svg"
import Calendar from '../../Images/calendar.svg'
import ChevronDown from '../../Images/chevron-down-nopad.svg'
import Search from '../../Images/search.png'
import Pipe from '../../Images/pipe.png'
import Asterisk from '../../Images/asterisk.png'
import hexToRgba from 'hex-to-rgba'
import SVGIcon from './SVGIcon'
import Unsyncable from '../../Images/unsyncable.svg'


const { RangePicker } = DatePicker
const { Dragger } = Upload

// This button will have all the functionality as the antd Button
export const StyledInput = styled(Input)`
    font-family: 'InterDisplay';
    color: ${props => props.color ? props.color : props.theme.input.txtColor} !important;
    font-size: ${props => props.textSize ? props.textSize : props.large ? "24px" : props.theme.input.fontSize};
    font-weight: ${props => props.large ? "500" : "400"};
    line-height: ${props => props.large ? "32px" : "24px"};
    letter-spacing: 0em;
    text-align: ${props => props.alignRight ? "right !important" : props.alignCenter ? "center !important" : "left"};
    &::placeholder {
        font-weight: 500;
        color: ${props => props.theme.input.placeholderTxtColor};
    };
    /* duplicating the style is needed as ANT sometimes embeds the input if there's a prefix */
    > input {
        font-family: 'InterDisplay';
        color: ${props => props.color ? props.color : props.theme.input.txtColor} !important;
        font-size: ${props => props.textSize ? props.textSize : props.large ? "24px" : props.theme.input.fontSize};
        font-weight: ${props => props.large ? "500" : "400"};
        line-height: ${props => props.large ? "32px" : "24px"};
        letter-spacing: 0em;
        text-align: ${props => props.alignRight ? "right !important" : props.alignCenter ? "center !important" : "left"};
        &::placeholder {
            font-weight: 500;
            color: ${props => props.theme.input.placeholderTxtColor};
        }
    };
    padding: 0;
    width: 100%;
`;

export const ErrorLabel = styled.span`
    font-size: 12px;
    color: red;
    margin-top: 4px;
    margin-left: ${props => props.alignError ? 'auto' : 0};
    margin-right: ${props => props.alignError ? 'auto' : 0};
`;

export const StyledReactMultiEmail = styled(ReactMultiEmail)`
    font-family: 'InterDisplay';
    font-size: ${props => props.large ? "24px" : props.theme.input.fontSize};
    font-weight: ${props => props.large ? "500" : "400"};
    line-height: ${props => props.large ? "32px" : "24px"};
    letter-spacing: 0em;
    text-align: left;
    &::placeholder {
        font-weight: 500;
        color: ${props => props.theme.input.placeholderTxtColor};
    }
    padding: 0;
    background: transparent;
    background-color: transparent;
    border: none;
    &:focus {
        background: transparent;
        border: none;
    }
    &.focused {
        background: transparent;
        border: none;
    }
    > input {
        background: transparent;
        background-color: transparent;
        border: none;
        &::placeholder {
            font-weight: 300;
            color: ${props => props.theme.colors.placeholder};
        }
    }
    > span[data-placeholder] {
        font-style: italic;
        left: 0;
        top: 0;
        font-weight: 300;
        color: ${props => props.theme.colors.placeholder};
    }
    gap: 12px;
`;

export const StyledPlaceholder = styled.span`
    font-family: 'InterDisplay';
    font-size: ${props => props.large ? "24px" : props.theme.input.fontSize};
    font-weight: ${props => props.large ? "500" : "400"};
    line-height: ${props => props.large ? "32px" : "24px"};
    font-style: italic;
    color: ${props => props.theme.input.placeholderTxtColor};
`;

export const StyledInputPassword = styled(StyledInput.Password)`
    background: transparent;
    font-family: 'InterDisplay';
    color: ${props => props.theme.input.txtColor} !important;
    font-size: ${props => props.large ? "24px" : props.theme.input.fontSize};
    font-weight: ${props => props.large ? "500" : "400"};
    line-height: ${props => props.large ? "32px" : "24px"};
    margin: ${props => props.padded ? '1em' : 0};
    padding: ${props => props.padded ? '0.25em 1em' : '0.25em 0'};
    > input {
        color: ${props => props.color ? props.color : props.theme.input.txtColor} !important;
        font-size: ${props => props.textSize ? props.textSize : props.large ? "24px" : props.theme.input.fontSize};
        font-weight: ${props => props.large ? "500" : "400"};
        &::placeholder {
            font-weight: 300;
            color: ${props => props.theme.input.placeholderTxtColor};
        }
    }
    
`;

export const StyledInputSearch = styled(Input.Search)`
    font-family: 'InterDisplay';
    color: ${props => props.color ? props.color : props.theme.input.txtColor} !important;
    font-size: ${props => props.textSize ? props.textSize : props.large ? "24px" : props.theme.input.fontSize};
    font-weight: ${props => props.large ? "500" : "400"};
    line-height: ${props => props.large ? "32px" : "24px"};
    background: transparent;
    margin: ${props => props.padded ? '1em' : 0};
    padding: 0;
`;

export const StyledInputNumber = styled(InputNumber)`
    font-family: 'InterDisplay';
    color: ${props => props.color ? props.color : props.theme.input.txtColor} !important;
    font-size: ${props => props.textSize ? props.textSize : props.large ? "24px" : props.theme.input.fontSize};
    font-weight: ${props => props.large ? "500" : "400"};
    line-height: ${props => props.large ? "32px" : "24px"};
    background: transparent;
    width: 100%;
`;

export const StyledTextArea = styled(Input.TextArea)`
    font-family: 'InterDisplay';
    color: ${props => props.color ? props.color : props.theme.input.txtColor} !important;
    font-size: ${props => props.textSize ? props.textSize : props.large ? "24px" : props.theme.input.fontSize};
    font-weight: ${props => props.large ? "500" : "400"};
    background: transparent;
    margin: ${props => props.padded ? '1em' : 0};
    padding: ${props => props.padded ? '0.25em 1em' : '0.25em 0'};
`;

export const StyledDatePicker = styled(DatePicker)`
    background: transparent;
    font-family: 'InterDisplay';
    color: ${props => props.color ? props.color : props.theme.input.txtColor} !important;
    font-size: ${props => props.textSize ? props.textSize : props.large ? "24px" : props.theme.input.fontSize};
    margin: ${props => props.padded ? '1em' : 0};
    padding: ${props => props.padded ? '0.25em 1em' : '0.25em 0'};
    width: 100%;
    > div > input {
        font-size: ${props => props.large ? "24px" : props.theme.input.fontSize};
        color: ${props => props.color ? props.color : props.theme.input.txtColor} !important;
        &::placeholder {
            font-weight: 500;
            color: ${props => props.theme.input.placeholderTxtColor};
        }
    }
`;

export const StyledRangePicker = styled(RangePicker)`
    background: transparent;
    font-family: 'InterDisplay';
    color: ${props => props.color ? props.color : props.theme.input.txtColor} !important;
    font-size: ${props => props.textSize ? props.textSize : props.large ? "24px" : props.theme.input.fontSize};
    font-weight: ${props => props.large ? "500" : "400"};
    margin: ${props => props.padded ? '1em' : 0};
    padding: ${props => props.padded ? '0.25em 1em' : '0.25em 0'};
`;

export const StyledSelect = styled(Select)`
    font-family: 'InterDisplay';
    color: ${props => props.color ? props.color : props.theme.input.txtColor} !important;
    font-size: ${props => props.large ? "24px" : props.theme.input.fontSize};
    font-weight: ${props => props.large ? "500" : "400"};
    line-height: ${props => props.large ? "32px" : "24px"};
    width: 100%;
    > span > input {
        font-size: "25px";
        &::placeholder {
            font-weight: 500;
            color: ${props => props.theme.input.placeholderTxtColor};
        }
        .ant-select-selection-search-input {
            cursor: pointer !important;
        }
    }
    .ant-select-selector {
        cursor: pointer !important;
    }
`;

export const StyledInputContainer = styled.div`
    min-height: ${props => props.minHeight ? props.minHeight : !props.noHeight && (props.location ? (props.nextLine ? "105px" : "125px") : (props.minHeight ? props.minHeight : props.small ? "42px" : (props.large ? "56px" : "42px")))};
    width: ${props => !props.noWidth && (props.width ? props.width : "100%")};
    padding: ${props => props.padding ? props.padding : "4px 16px"};
    background: white;
    display: flex;
    justify-content: flex-start !important;
    align-items: center;
    border: ${props => !props.noBorder && (props.error ? '1px solid red' : `1px solid ${props.theme.input.bdrColor}`)};
    border-radius: 8px;
    &:focus-within {
        border: ${props => `1px solid ${props.theme.input.bdrColor}`};
    }
`;

export const BlankContainer = styled.div`
    height: 16px;
    border-radius: 16px;
    padding: 16px;
    background: #F4F5F6;
    display: flex;
    align-items: center;
`;

const StyledCascader = styled(Cascader)`
    background: transparent;
    font-family: 'InterDisplay';
    color: ${props => props.color ? props.color : props.theme.input.txtColor} !important;
    font-size: ${props => props.large ? "24px" : "16px"};
    font-weight: ${props => props.large ? "500" : "400"};
    line-height: ${props => props.large ? "32px" : "24px"};
    width: 100%;
    > input {
        &::placeholder {
            font-weight: 500;
            color: ${props => props.theme.input.placeholderTxtColor};
        }
    }
`;

const StyledText = styled.span`
    font-family: 'InterDisplay';
    font-size: ${props => props.large ? "24px" : props.theme.input.readFontSize};
    font-style: normal;
    font-weight: ${props => props.large ? "500" : "400"};
    line-height: ${props => props.large ? "32px" : "24px"};
    letter-spacing: 0em;
    text-align: ${props => props.alignRight ? "right" : "left"};
    color: ${props => props.color || "#444444"};
    text-transform: ${props => props.capitalize && 'capitalize'};
    word-wrap: break-word;
    word-break: break-word;
    width: 100%;
`;
const StyledInstrLabel = styled.p`
    color: ${props => props.theme.input.instrColor}; 
    margin: 4px 0 0; 
    font-size: 12px;
`;
const StyledSuffix = styled.span`
    color: ${props => props.theme.colors.systemGray};
    margin-left: 5px;
`;
const Optional = styled.span`
    color: ${props => props.color || '#7384AA'};
    font-family: 'InterDisplay';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
`;
const Prefix = styled.span`
    color: ${props => props.color || hexToRgba(props.theme.colors.primary2, 0.8)};
    font-family: 'InterDisplay';
    font-size: ${props => props.theme.input.fontSize};
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: right;
    margin-right: 8px;
`;
export const LabeledInput = (props) => {
    const [masked, setMasked] = useState(true)
    const theme = useTheme()

    const getInput = () => {
        const { type } = props;

        function tagRender(props) {
            const { label, value, closable, onClose } = props;
            const onPreventMouseDown = event => {
                event.preventDefault();
                event.stopPropagation();
            };
            return (
                <Chip
                    onMouseDown={onPreventMouseDown}
                    closable={value === 'Primary Admin' ? false : closable}
                    primary
                    onDelete={onClose}
                    margin='4px'
                    background='#F5F6FE'
                    text={label || value}
                    fontSize='14px'
                    height='32px'
                    border='0px'
                    noWrap
                />
            );
        }

        if (type === "password" || type === "new-password" || type === "current-password" || type === "read-only-password") {
            return (<StyledInputPassword
                {...props}
                bordered={false}
                readOnly={type === "read-only-password" && "readonly"}
                iconRender={(visible) => (!visible ? <img width='20px' height='20px' src={VisibilityOff} /> : <img width='20px' height='20px' src={Visibility} />)}
            />)
        } else if (type === "text-area") {
            return (<StyledTextArea
                {...props}
                bordered={false}
            />)
        } else if (type === "date-picker") {
            return (<StyledDatePicker
                style={{ color: '#444444' }}
                {...props}
                bordered={false}
                ref={props.inputRef}
                allowClear={props.allowClear}
                suffixIcon={<SVGIcon src={Calendar} strokeWidth='0px' fill={hexToRgba(theme.colors.primary2, 0.7)} />}
            />)
        } else if (type === "range-picker") {
            return (<StyledRangePicker
                {...props}
                bordered={false}
            />)
        } else if (type === "location") {
            return (<LocationSearchInput
                {...props}
                bordered={false}
            // ref={props.inputRef}
            />)
        } else if (type === "select") {
            return (<StyledSelect
                suffixIcon={props.loading ? <LoadingOutlined style={{ color: '#1199FF', fontSize: 16 }} /> : <Image width='16px' height='8.75px' src={ChevronDown} />}
                {...props}
                placeholder={<span style={{ fontWeight: 500 }}>{props.placeholder}</span>}
                bordered={false}
                ref={props.inputRef}
                tagRender={tagRender}
                autocomplete={!props.autocomplete && 'off'}
                data-lpignore={!props.autocomplete && "true"}
            />)
        } else if (type === "select-search") {
            return (<Flex start centerHorizontally>
                {/* <div style={{ width: props.selectWidth }}>
                    <StyledSelect
                        suffixIcon={props.loading ? <LoadingOutlined style={{ color: '#1199FF', fontSize: 16 }} /> : <Image width='16px' height='8.75px' src={ChevronDown} />}
                        value={props.selectValue && <Text>{props.selectValue}</Text>}
                        bordered={false}
                        ref={props.inputRef}
                        tagRender={tagRender}
                        onChange={props.onSelectChange}
                        width={props.selectWidth}
                    >
                        {props.options}
                    </StyledSelect>
                </div>
                <Image margin='0 12px' width='2px' height='24px' src={Pipe} /> */}
                {/* <Flex start centerHorizontally style={{ width: '100%' }}> */}
                <Image width='12px' height='12px' src={Search} />
                <div style={{ marginLeft: 8, width: props.searchWidth }}>
                    <StyledInput
                        value={props.searchValue}
                        bordered={false}
                        onChange={props.onSearchChange}
                        width={props.searchWidth}
                        placeholder={props.placeholder}
                    />
                </div>
                {/* </Flex> */}
            </Flex>)
        } else if (type === "radio") {
            return (<Radio.Group
                style={{ paddingLeft: 0, marginLeft: 0 }}
                {...props}
                bordered={false}
            />)
        } else if (type === "checkbox") {
            return (<Checkbox.Group
                style={{ paddingLeft: 0, marginLeft: 0 }}
                {...props}
                bordered={false}
            />)
        } else if (type === "search") {
            return (<StyledInputSearch
                {...props}
                bordered={false}
                allowClear
            />)
        } else if (type === "remote-search") {
            return (
                <div style={{ width: '100%', position: "relative" }}>
                    {props.symbolprefix && <Flex center style={{ marginRight: "5px", position: "absolute", top: 0, left: props.left || 12, bottom: 0, height: "100%" }}>{props.symbolprefix}</Flex>}
                    <StyledSelect
                        {...props}
                        ref={props.inputRef}
                        bordered={false}
                        showSearch
                        defaultActiveFirstOption={false}
                        filterOption={false}
                        notFoundContent={null}
                        style={{ textIndent: props.textIndent || (props.symbolprefix ? "35px" : 0), right: 0 }}
                        suffixIcon={props.loading ? <LoadingOutlined style={{ color: '#1199FF', fontSize: 16 }} /> : <Image width='16px' height='8.75px' src={ChevronDown} />}
                    />
                    {props.symbolsuffix && <Flex center style={{ position: "absolute", top: 0, right: props.right || 12, bottom: 0, height: "100%" }}>{props.symbolsuffix}</Flex>}
                </div>
            )
        } else if (type === "sync-select") {
            return (<StyledSelect
                suffixIcon={props.loading ? <LoadingOutlined style={{ color: '#1199FF', fontSize: 16 }} /> : <Image width='16px' height='8.75px' src={ChevronDown} />}
                {...props}
                ref={props.inputRef}
                bordered={false}
                defaultActiveFirstOption={false}
                style={{ textIndent: props.textIndent || (props.symbolprefix ? "35px" : 0), right: 0 }}
                autocomplete={!props.autocomplete && 'off'}
                data-lpignore={!props.autocomplete && "true"}
            />)
        } else if (type === "multi-email") {
            return (<StyledReactMultiEmail
                // ref={props.inputRef}
                {...props}
                getLabel={(
                    email,
                    index
                ) => {
                    return (
                        <EmailLabel key={email} onDelete={props.onDelete} email={email} index={index} />
                    );
                }}
            />)
        } else if (type === "switch") {
            var items = [];
            (props.switchNames || []).forEach((switchName, index) => {
                items.push(<Chip selected={props.value === switchName} margin='0 8px 0 0' onClick={() => props.onChange(switchName)} text={switchName} />)
            })

            return (<Flex start>
                {items}
            </Flex>)
        } else if (type === "switch-button") {
            var items = [];
            (props.switchNames || []).forEach((switchName, index) => {
                items.push(<Button height='40px' weight={props.value === switchName ? '600' : '400'} color={props.value === switchName ? '#1199FF' : '#7384AA'} onClick={() => props.onChange(switchName)} text={switchName} radius='4px' style={{ marginRight: 8 }} />)
            })

            return (<Flex start>
                {items}
            </Flex>)
        } else if (type === "upload") {
            return (<Upload {...props} start >
                <Flex style={{ padding: props.allowPadding ? "0 0 0 48px" : "0px" }}>
                    <TextButton
                        {...props}
                        ref={props.inputRef}
                        weight='500'
                        text={props.labelText ? props.labelText : 'UPLOAD'}
                        icon={!props.end && (props.loading ? <LoadingOutlined style={{ color: '#1199FF', fontSize: 16 }} /> : <img width='24px' height='24px' src={props.disabled ? UploadIconDisabled : UploadIcon} />)}
                        rightIcon={props.end && <img width='24px' height='24px' src={props.disabled ? UploadIconDisabled : UploadIcon} />}
                    />
                </Flex>
            </Upload>)
        } else if (type === "blank") {
            return (<></>)
        } else if (type === "cascader") {
            return (<StyledCascader
                suffixIcon={props.loading ? <LoadingOutlined style={{ color: '#1199FF', fontSize: 16 }} /> : <Image width='16px' height='8.75px' src={ChevronDown} />}
                style={{ fontSize: "14px", paddingLeft: 0, marginLeft: 0, right: 0 }}
                // style={{ textIndent: props.textIndent || (props.symbolprefix ? "35px" : 0), right: 0 }}
                {...props}
                bordered={false}
                ref={props.inputRef}
            />)
        } else if (type === "number") {
            return (<StyledInputNumber
                min={props.min && props.min}
                max={props.max && props.max}
                value={props.value && props.value}
                formatter={props.formatter && props.formatter}
                type="text"
                onChange={props.onChange}
                bordered={false}
                placeholder={props.placeholder && props.placeholder}
                style={props.style && props.style}
                id={props.id && props.id}
                name={props.name && props.name}
                ref={props.inputRef}
                precision={props.precision}
                onBlur={props.onBlur}
                onPressEnter={props.onPressEnter}
                className={props.alignRight && 'align-right'}
            />)
        } else if (type === "currency") {
            return (<StyledInputNumber
                className="currency-input"
                min={props.min && props.min}
                max={props.max && props.max}
                value={props.value && props.value}
                type="text"
                onChange={props.onChange}
                bordered={false}
                placeholder={props.placeholder && props.placeholder}
                style={props.style && props.style}
                id={props.id && props.id}
                name={props.name && props.name}
                formatter={value => {
                    // Ensure that the value is a number
                    const num = isNaN(value) ? 0 : parseFloat(value);

                    // Format as a dollar amount with two decimal places
                    return `${num.toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                }}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                precision={2}
                decimalSeparator="."
            />)
        } else if (type === "dragger") {
            return <Dragger {...props} showUploadList={true}>
                <FlexColumn style={{ padding: '12px 0 12px' }} center>
                    <Text margin='0 0 24px' size='12px' color='#7384AA'>Drag and drop documents here to attach them</Text>
                    <TextButton uppercase icon={<PlusOutlined style={{ fontSize: 16, color: '#1199FF' }} />} text='Upload Documents' />
                </FlexColumn>
            </Dragger>
        } else if (type === "read-only") {
            return (<StyledText {...props} alignRight={props.alignRight} margin={props.nomargin ? "0" : "4px 0"} fontSize={props.fontSize || "14px"} labelcolor="black">{props.value}</StyledText>)
        } else if (type === "mask") {
            return (
                <Flex start centerHorizontally style={{ width: '100%' }}>
                    <StyledInput
                        {...props}
                        bordered={false}
                        ref={props.inputRef}
                        className={masked && "mask-input"}
                    />
                    <StyledSuffix>{<a onClick={() => setMasked(!masked)}>{masked ? <img width='20px' height='20px' src={VisibilityOff} /> : <img width='20px' height='20px' src={Visibility} />}</a>}</StyledSuffix>
                </Flex>
            )
        } else if (type === "phone-cc") { // Country code selection
            return (
                <Flex start centerHorizontally style={{ width: '100%' }}>
                    <div style={{ width: 50 }}>
                        <Select
                            showSearch
                            style={{ fontSize: 18, width: 50 }}
                            dropdownStyle={{ width: 300 }}
                            dropdownMatchSelectWidth={false}
                            bordered={false}
                            filterOption={(input, option) =>
                                option.name.toLowerCase().startsWith(input.toLowerCase())
                            }
                            value={props.phoneCode && (phoneCountryCode.find(i => i.dial_code == props.phoneCode.dial_code) || {}).flag}
                            onChange={(dialCode) => props.onCodeChange(phoneCountryCode.find(i => i.dial_code == dialCode))}
                        >
                            {
                                phoneCountryCode.map(item => (
                                    <Select.Option key={item.code} name={item.name} value={item.dial_code} label={item.flag}>
                                        <Flex start gap="8px">
                                            <Text>{item.flag}</Text>
                                            <Text>{item.name}</Text>
                                            <Text color="#666666">{item.dial_code}</Text>
                                        </Flex>
                                    </Select.Option>
                                )
                                )
                            }
                        </Select>
                    </div>

                    <StyledInput
                        {...props}
                        bordered={false}
                    />
                </Flex>
            )
        } else {
            if (props.symbolprefix) {
                return (
                    <Flex start centerHorizontally style={{ width: '100%' }}>
                        <span style={{ marginRight: "5px" }}>{props.symbolprefix}</span>
                        <StyledInput
                            {...props}
                            bordered={false}
                            autocomplete={!props.autocomplete && 'off'}
                            data-lpignore={!props.autocomplete && "true"}
                        />
                    </Flex>
                )
            }
            if (props.symbolsuffix) {
                return (
                    <Flex start centerHorizontally style={{ width: '100%' }}>
                        <StyledInput
                            {...props}
                            bordered={false}
                            autocomplete={!props.autocomplete && 'off'}
                            data-lpignore={!props.autocomplete && "true"}
                        />
                        <StyledSuffix style={{ width: "24px", height: props.suffixFontSzie ? "32px" : "24px", fontSize: props.large ? "24px" : props.suffixFontSzie ? props.suffixFontSzie : "16px" }}>{props.symbolsuffix}</StyledSuffix>
                    </Flex>
                )
            }
            return (<StyledInput
                {...props}
                bordered={false}
                ref={props.inputRef}
                autocomplete={!props.autocomplete && 'off'}
                data-lpignore={!props.autocomplete && "true"}
            />)
        }
    }

    return (
        <FlexColumn left style={{ margin: props.margin ? props.margin : (props.nomargin ? "0" : "15px 0"), width: props.width || '100%' }}>
            {
                (props.label || props.optional) &&
                <Flex between centerHorizontally style={{ marginBottom: 0, width: props.width || '100%' }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {
                            props.label ?
                                <Flex start bottom gap='4px'>
                                    <Text color={props.labelColor || '#7384AA'} size='14px' margin='0 0 4px' weight={400}>{props.label}</Text>
                                    {
                                        props.unsyncable &&
                                        <Tooltip overlayClassName='roles-tooltip' placement="right" arrowPointAtCenter={false} title={<FlexColumn>
                                            <Text color='white' size='14px'>{`${props.label} can't be synced with ${props.accountingSoftware || 'your accounting system'}`}</Text>
                                        </FlexColumn>}>
                                            <Image margin='0 0 2px' src={Unsyncable} />
                                        </Tooltip>
                                    }
                                    {
                                        (!props.optional && !props.noAsterisk && !['read-only', 'read-only-password'].includes(props.type)) &&
                                        <Image width='5px' height='5px' margin='0 0 16px' src={Asterisk} />
                                    }
                                </Flex> : null
                        }
                        {
                            props.tooltip &&
                            <Tooltip overlayClassName='roles-tooltip' placement="right" arrowPointAtCenter={false} title={<FlexColumn>
                                <Text color='white' size='14px'>{props.tooltip}</Text>
                            </FlexColumn>}>
                                <FlexColumn>
                                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)', marginLeft: 4, marginBottom: 4 }} />
                                </FlexColumn>
                            </Tooltip>
                        }
                    </div>
                    {!['read-only'].includes(props.type) && props.optional && <Optional>optional</Optional>}
                    {props.custLblComp}
                </Flex>
            }
            {props.customlabelcomponent || null}

            {
                ['read-only', 'radio', 'dragger', 'checkbox', 'upload', 'switch', 'switch-button', 'read-only-password'].includes(props.type) ?
                    <Flex start centerHorizontally style={{ width: !props.noWidth && (props.width || '100%') }}>
                        {getInput()}
                        {props.copy && <ImageButton margin='0 0 0 8px' src={props.mute ? CopyMute : Copy} onClick={() => copyToClipboard(props.value)} />}
                        {props.hide && <ImageButton margin='0 0 0 8px' src={props.masked ? (props.mute ? VisibilityMute : Visibility) : (props.mute ? VisibilityOffMute : VisibilityOff)} onClick={() => props.setMasked(!props.masked)} />}
                    </Flex>
                    :
                    (
                        props.type === "blank" ?
                            <BlankContainer>{getInput()}</BlankContainer>
                            :
                            <StyledInputContainer noWidth={props.noWidth} key={`${props.key}Container`} width={props.width} error={props.error} padding={(['select'].includes(props.type) && props.mode === 'multiple') ? '4px' : ['select', 'cascader'].includes(props.type) ? '4px 8px 4px 16px' : ['remote-search'].includes(props.type) && '4px 8px 4px 12px'} style={props.containerStyle} location={props.type === "location"} large={props.large} small={props.small} noHeight={props.type === "text-area" || props.noHeight} minHeight={props.minHeight} noBorder={props.noBorder}>
                                <Flex start centerHorizontally style={{ width: props.width || '100%' }}>
                                    {
                                        props.prefixText &&
                                        <Prefix>{props.prefixText}</Prefix>
                                    }
                                    {props.prefixIcon && <div style={{ marginRight: 10 }}>{props.prefixIcon}</div>}
                                    {getInput()}
                                </Flex>
                            </StyledInputContainer>
                    )
            }
            {
                props.instruction &&
                <Flex start centerHorizontally gap='4px' style={{ marginTop: props.instructionTooltip ? 2 : 0 }}>
                    <StyledInstrLabel>{props.instruction}</StyledInstrLabel>
                    {
                        props.instructionTooltip &&
                        <Tooltip overlayInnerStyle={{ width: 500, minWidth: 500 }} overlayStyle={{ width: 520, minWidth: 520 }} overlayClassName='roles-tooltip' placement="right" arrowPointAtCenter={false} title={<FlexColumn>
                            <Text color='white' size='14px'>{props.instructionTooltip}</Text>
                        </FlexColumn>}>
                            <FlexColumn>
                                <InfoCircleOutlined style={{ color: '#1199FF' }} />
                            </FlexColumn>
                        </Tooltip>
                    }
                </Flex>
            }
            {props.uploadText && <Text size='14px' color='#666666' margin='12px 0 0'>{props.uploadText}</Text>}
            {props.error && <ErrorLabel alignError={props.alignError}>{props.errorMessage}</ErrorLabel>}
        </FlexColumn>
    );
}

export const InputConfirmation = (props) => {
    const StyledParagraph = styled(Paragraph)`
        color: ${props => props.labelcolor || props.theme.colors.secondary3};
        margin: 0; 
        font-size: ${props => props.labelSize || "0.9rem"};
        font-weight: ${props => props.labelWeight || "400"};
    `;
    if (props.withdivider)
        return (
            <>
                <StyledParagraph labelSize={props.labelSize} labelWeight={props.labelWeight}>{props.label}</StyledParagraph>
                <Paragraph level={props.level || 3} bold noMargin>{props.value}</Paragraph>
                <Divider margin="15px 0" />
            </>
        )
    else
        return (
            <InputContainer style={{ margin: "10px 0" }}>
                {/* <StyledParagraph labelSize={props.labelSize} labelWeight={props.labelWeight}>{props.label}</StyledParagraph> */}
                <div style={{ display: "flex", alignItems: "center" }}>
                    <StyledParagraph labelSize={props.labelSize} labelWeight={props.labelWeight}>{props.label}</StyledParagraph>
                    {
                        props.tooltip &&
                        <Popover content={props.tooltip}>
                            <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)', marginLeft: 4 }} />
                        </Popover>
                    }
                </div>
                <Paragraph level={props.level || 3} bold noMargin>{props.value}</Paragraph>
            </InputContainer>
        )
}

export const InputConfirmationEdit = (props) => {
    const StyledParagraph = styled(Paragraph)`
        color: ${props => props.labelcolor || props.theme.colors.secondary3};
        margin: 0; 
        font-size: 0.9rem;
    `;

    return (
        <InputContainer style={{ margin: "10px 0" }}>
            <StyledParagraph>{props.label}</StyledParagraph>
            <Paragraph level={3} bold noMargin>{props.value} <Button onClick={props.onClick} text={props.buttonText} /></Paragraph>
        </InputContainer>
    )
}

export const GradientDivider = styled.div`
    background-image: ${props => props.reverse ? 'linear-gradient(90deg, #CBCED6 0%, rgba(203, 206, 214, 0) 124.48%)' : 'linear-gradient(to right, rgba(203, 206, 214, 0.1), rgba(203, 206, 214, 1))'}; 
    height: 1px;
    width: 100%;
    margin-top: ${props => props.marginTop && props.marginTop};
    margin-bottom: ${props => props.marginBottom && props.marginBottom};
`;

export const Divider = styled.div`
    background: ${props => props.color ? props.color : '#F2F2F2'};
    height: 1px;
    width: 100%;
    margin: ${props => props.margin ? props.margin : '0 0 12px'};
`;

const EmailLabel = (props) => {
    const theme = useTheme()
    const color = hexToRgba(theme.colors.primary2, 0.7)

    const emailDivStyle = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: 24,
        background: 'transparent',
        border: `1px solid ${color}`,
        borderRadius: '4px',
        color: color,
        fontSize: theme.input.fontSize,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '24px',
        textAlign: 'left',
        padding: '12px 12px',
        margin: '0',
    }

    return <div style={emailDivStyle} key={props.index}>
        {props.email}
        <CloseOutlined onClick={() => props.onDelete(props.index)} style={{ color: color, fontSize: 14, marginLeft: 10, cursor: 'pointer' }} />
    </div>
}
