import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import styled from 'styled-components'
import { message, Radio, Divider, Popover, Modal } from 'antd'

import { Flex, FlexColumn } from '../../Reusable/Container'
import { LabeledInput } from '../../Reusable/Input'
import { Paragraph, Title, Text } from '../../Reusable/Text'
import { Button } from '../../Reusable/Button'
import { ErrorAlert } from '../../Reusable/Alert'
import { CheckOutlined, InfoCircleOutlined } from '@ant-design/icons'

// Util
import { apiPOST } from '../../../Utils/api'
import { toNumber, getUserApps, toCurrency } from '../../../Utils/util'

// Images
import ModalClose from '../../../Images/modal-close.png'


export const CardButton = styled.div`
    background-color: rgb(255, 255, 255);
    border-radius: 0.5rem;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    opacity: 1;
    padding: 15px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    transition-property: box-shadow;
    transition-duration: 300ms;
    &.selected {
        cursor: pointer;
        box-shadow: 0 3px 15px rgb(0 0 0 / 0.4);
    };
`

export const CardButtonText = styled.span`
    font-size: 18px;
    font-weight: 400;
`

class ExpressFunds extends Component {

    constructor(props) {
        super(props)

        this.state = {
            advanceAmount: this.props.advanceAmount,
            loading: false,
            funding: "standard",
        }
    }

    handleChange = (event) => {
        this.setState({ [event.target.id]: event.target.value })
    }

    handleSubmit = () => {
        const userApps = getUserApps(this.props.store)
        const { funding } = this.state
        const requestedAmt = toNumber(this.props.advanceAmount)

        try {
            if (requestedAmt <= 0) throw Error("Please enter a valid amount.")
            if (isNaN(requestedAmt)) throw Error("Please enter a valid amount.")
            // if (!this.props.notes) throw Error("Please enter a note.")
        } catch (error) {
            ErrorAlert({ description: error.message })
            return
        }
        this.setState({ loading: true })
        const headers = {
            businesskey: this.props.store.BusinessUniqueKey
        }
        // TODO: endpoint need to be determined based on AR / Short Term
        // AR Express Loans: POST Endpoint:  /financing/funds/expressrequest
        // Short Term Express Loans: POST Endpoint:  /expressrequest

        if (funding === "express") {
            var endpoint = userApps.includes("ARCredit") ? "/financing/funds/expressrequest" : "/expressrequest"

            console.log("express endpoint", endpoint)

            apiPOST(endpoint, headers, { fundsrequested: requestedAmt, notes: this.props.notes }, (err, resp) => {
                try {
                    if (err) throw Error(err.response.data || "Sorry we had trouble processing your request. Please try again.")
                    const data = resp.data
                    if (data.success) {
                        message.success('Express advance requested successfully. The funds will be available in your account within 3-4 hrs.')
                        this.setState({ requestedAmt: null })
                        this.props.onSubmit()
                        // this.props.history.push('/credit/ar-credit/advances')
                    } else {
                        throw Error("Sorry we had trouble processing your request. Please try again.")
                    }
                } catch (error) {
                    this.setState({ loading: false })
                    ErrorAlert({ description: error.message })
                }
            })
        } else if (funding === "standard") {
            const { UserInfo } = this.props.store
            var username = UserInfo.Email

            var body = {
                ...this.props.availableFunds,
                FundsRequested: requestedAmt,
                Notes: this.props.notes,
                UserEmail: username,
                FirstName: UserInfo.FirstName,
                LastName: UserInfo.LastName,
            }

            console.log("arfinancing/funds/request body: ", body)

            apiPOST('/arfinancing/funds/request', headers, body, (err, resp) => {
                console.log("arfinancing/funds/request: ", resp)
                try {
                    if (err) throw Error(err.response.data || "Sorry we had trouble processing your request. Please try again.")
                    const data = resp.data
                    if (data.success) {
                        message.success('Advance requested successfully. The funds will be available in your account in 1-3 business days.')
                        this.setState({ requestedAmt: null })
                        this.props.onSubmit()
                    } else {
                        throw Error("Sorry we had trouble processing your request. Please try again.")
                    }
                } catch (error) {
                    this.setState({ loading: false })
                    ErrorAlert({ description: error.message })
                }
            })
        }
    }

    onChange = e => {
        this.setState({
            funding: e.target.value,
        })
    }

    render() {
        const { loading, funding } = this.state
        const { advanceAmount, theme, store, fundingData, drawFeesToBorrower, feeRecipient } = this.props

        // const { ExpressFunding, StandardFunding } = fundingData

        return (
            <Modal
                visible={this.props.visible}
                footer={null}
                closable={true}
                width={700}
                style={{ top: 20 }}
                destroyOnClose={true}
                onCancel={() => this.props.onCancel(loading)}
                closeIcon={<img width='24px' height='24px' src={ModalClose} />}
            >
                <FlexColumn start fullWidth>
                    <Text heading>Funding Request</Text>
                    <div>
                        {
                            fundingData && fundingData.ExpressFunding === true ?
                                <Paragraph>Select delivery speed and submit your request</Paragraph>
                                :
                                <Paragraph>Confirm the amount and submit your request. Funds will be deposited in your account in 1-3 business days.</Paragraph>
                        }
                        <FlexColumn start style={{ marginTop: 24 }} gap='24px'>
                            <LabeledInput
                                autoFocus
                                label="Amount"
                                id="advanceAmount"
                                type="advanceAmount"
                                value={`${toCurrency(advanceAmount)}`}
                                placeholder="1000"
                                onChange={this.handleChange}
                                labelcolor={theme.colors.secondary3}
                                prefix="$"
                                disabled
                                fontSize="15px"
                                fontWeight="500"
                            />
                            {/* {
                                drawFeesToBorrower && drawFeesToBorrower == false && <p style={{ color: theme.colors.secondary3, fontSize: 15, fontWeight: 500 }} level={1} key="fees" label="Delivery Speed">Delivery Speed</p>
                            }
                            {

                                <Radio.Group onChange={this.onChange} value={funding}>
                                    <CardButton className={funding === "standard" && "selected"} onClick={() => this.setState({ funding: "standard" })}>
                                        <Flex style={{ justifyContent: "flex-start", alignItems: "center" }}>
                                            <Radio value={"standard"} />
                                            <Divider style={{ margin: "0 10px", height: 70 }} type="vertical" />
                                            <FlexColumn>
                                                <span style={{ fontSize: 15, fontWeight: 500, marginBottom: 2 }} label="Delivery Speed">Standard</span>(<span style={{ fontSize: 15 }} label="Delivery Speed">1-3 business days</span>)
                                                {drawFeesToBorrower && drawFeesToBorrower == false && <span style={{ fontSize: 15 }} label="Delivery Speed"><CheckOutlined style={{ marginRight: 8 }} />No fees</span>}
                                                {drawFeesToBorrower && drawFeesToBorrower == true && feeRecipient && feeRecipient.FeeType == "Percentage" && <span style={{ fontSize: 15 }} label="Delivery Speed"><CheckOutlined style={{ marginRight: 8 }} />{feeRecipient.FeePercentage}% as Fee</span>}
                                                {drawFeesToBorrower && drawFeesToBorrower == true && feeRecipient && feeRecipient.FeeType == "Flat Fee" && <span style={{ fontSize: 15 }} label="Delivery Speed"><CheckOutlined style={{ marginRight: 8 }} />${toCurrency(feeRecipient.FlatFee)} as Flat Fee</span>}
                                                <span style={{ fontSize: 15 }} label="Delivery Speed"><CheckOutlined style={{ marginRight: 8 }} />1-3 business days</span>
                                            </FlexColumn>
                                        </Flex>
                                    </CardButton>
                                    {
                                        fundingData && fundingData.ExpressFunding === true && // isBeforeCutOff() &&
                                        <CardButton className={funding === "express" && "selected"} onClick={() => this.setState({ funding: "express" })} style={{ marginTop: 20 }}>
                                            <Flex style={{ justifyContent: "flex-start", alignItems: "center" }}>
                                                <Radio value={"express"} />
                                                <Divider style={{ margin: "0 10px", height: 70 }} type="vertical" />
                                                <FlexColumn>
                                                    <span style={{ fontSize: 15, fontWeight: 500, marginBottom: 2 }} label="Delivery Speed">Express</span>
                                                    <span style={{ fontSize: 15 }} label="Delivery Speed"><CheckOutlined style={{ marginRight: 8 }} />$250 flat fee</span>
                                                    <span style={{ fontSize: 15 }} label="Delivery Speed"><CheckOutlined style={{ marginRight: 8 }} />Within 24 hours</span>
                                                </FlexColumn>
                                            </Flex>
                                            <Popover content={(
                                                <FlexColumn>
                                                    <span>Please note that express funding</span>
                                                    <span>requests may sometimes not be fulfilled</span>
                                                    <span>due to unforeseen circumstances.</span>
                                                    <span>Under such circumstances, we will</span>
                                                    <span>refund the express funding fees and</span>
                                                    <span>and the advances will be fulfilled as per</span>
                                                    <span>standard funding timelines.</span>
                                                </FlexColumn>
                                            )}>
                                                <span style={{ fontSize: 15, marginRight: 5 }} label="Delivery Speed">Terms & Conditions <InfoCircleOutlined /></span>

                                            </Popover>
                                        </CardButton>
                                    }
                                </Radio.Group>
                            } */}
                        </FlexColumn>
                    </div>
                    <Flex start>
                        <Button permtype="Override" margin='48px 0 0' solid loading={loading} onClick={() => this.handleSubmit()} text='SUBMIT' />
                    </Flex>
                </FlexColumn>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer,
        bankingStore: state.bankingAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(ExpressFunds)))