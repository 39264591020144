import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'
import update from 'immutability-helper'

import {
    message, Select, Popconfirm, Space, Upload, Modal, List, Table
} from 'antd'

import {
    InboxOutlined, DeleteOutlined, CheckCircleFilled, InfoCircleOutlined
} from '@ant-design/icons'

// Components
import { TextButton, Button, ImageButton } from '../../../Reusable/Button'
import { Image } from '../../../Reusable/Image'
import { Text, Tag } from '../../../Reusable/Text'
import PageHeader from "../../../Reusable/PageHeader";
import { FlexColumn, Flex, CardContainer, LightContainer } from '../../../Reusable/Container'
import { LabeledInput } from '../../../Reusable/Input'
import { ErrorAlert } from '../../../Reusable/Alert'
import environment from '../../../../environment'
import { FromAccountOption } from '../../../Reusable/Option'
import FlowFooter from '../../../Reusable/FlowFooter'
import AddPaymentMethod from '../AddPaymentMethod'
import { SignedLink } from '../../../Reusable/Link'
import { apiPOSTReq } from '../../../../Utils/api'
import { addressObjectToStr, toCurrency, isEmailValid, getBPAccountsWithAccess, isCodatUser } from '../../../../Utils/util'
import ModalClose from '../../../../Images/modal-close.png'
import Add from '../../../../Images/add.svg';
import Edit from '../../../../Images/edit.png'
import Delete from '../../../../Images/delete.svg'

const docTypes = [
    {
        name: "Purchase Order",
        type: "PO",
    },
    {
        name: "Work Order",
        type: "WO",
    },
    {
        name: "Master Service Agreement",
        type: "MSA",
    },
    {
        name: "Other",
        type: "OTHER",
    }
]

const { Option } = Select
const { Dragger } = Upload

class NewVendor extends Component {

    constructor(props) {
        super(props)

        const { vendorPaymentAccount, userPaymentAccount } = props

        this.state = {
            paymentMethod: (vendorPaymentAccount && vendorPaymentAccount.type) ? vendorPaymentAccount.type : "ACH",
            accountNumber: (vendorPaymentAccount && vendorPaymentAccount.bankDetail) && vendorPaymentAccount.bankDetail.accountNumber,
            routingNumber: (vendorPaymentAccount && vendorPaymentAccount.bankDetail) && vendorPaymentAccount.bankDetail.routingNumber,
            accountType: (vendorPaymentAccount && vendorPaymentAccount.bankDetail) ? vendorPaymentAccount.bankDetail.accountType : "Checking",
            checkAddress: (vendorPaymentAccount && vendorPaymentAccount.bankDetail) && vendorPaymentAccount.bankDetail.bankAddress,
            createVendorLoading: false,
            vendor: props.vendor || { type: "Individual", billRecipientEmail: [] },
            fromAccount: userPaymentAccount && userPaymentAccount,
            vendorType: "Individual",
            paymentMethods: [],
        }
    }

    saveVendorInput = (key, value) => {
        var { vendor } = this.state
        vendor[key] = value
        this.setState({ vendor })
    }

    handleVendorTextChange = (event) => {
        var { vendor } = this.state
        vendor[event.target.id] = event.target.value
        this.setState({ vendor })
    }

    handleAccountSelect = (value, option) => {
        const Accounts = getBPAccountsWithAccess(null, { resourceStr: "Payables.Vendors", permType: "manage", getActiveAccounts: true })
        var fromAccount = Accounts.find(account => account.accountId === value)
        this.setState({ fromAccount })
    }

    getVendorLocation = (location, formattedAddress) => {
        if (!location)
            this.setState({
                vendor: update(this.state.vendor, {
                    address: {
                        $set: {
                            line1: null,
                            line2: null,
                            city: null,
                            countrySubDivisionCode: null,
                            state: null,
                            postalCode: null
                        }
                    }
                })
            })
        else
            this.setState({
                vendor: update(this.state.vendor, {
                    address: {
                        $set: {
                            line1: location.line1,
                            line2: location.line2,
                            city: location.city,
                            countrySubDivisionCode: location.countrySubDivisionCode,
                            state: location.state,
                            postalCode: location.postalCode
                        }
                    }
                })
            })
    }

    getModalForDocUpload = (doc) => {
        const { theme } = this.props
        var { uploadedFile } = this.state
        uploadedFile = uploadedFile || { ...doc }
        const uploadProps = () => {
            return {
                name: `payables-docs`,
                multiple: false,
                action: `${environment.apiBaseUrl}/file/upload`,
                headers: {
                    businesskey: this.props.aionStore.BusinessUniqueKey,
                    jwt: this.props.aionStore.jwt
                },
                onChange: (info) => {
                    var { file } = info
                    console.log("uploadProps info file", file)
                    const { status, response, name } = file
                    if (status === "done") {
                        this.setState({
                            uploadedFile: {
                                ...uploadedFile,
                                fileName: (doc.name == "W-9") ? doc.name : name,
                                uri: (response.UploadedUrls || [])[0]
                            }
                        })
                    }
                }
            }
        }

        return (
            <FlexColumn between gap='24px' fullWidth>
                <FlexColumn left start fullWidth>
                    <Text heading>Upload {doc.name === "W-9" ? "W-9" : "document"}</Text>
                    <Text>Upload your document and add an optional note.</Text>
                    <FlexColumn start gap='24px' style={{ marginTop: 24 }} fullWidth>
                        <Dragger {...uploadProps(doc)}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text" style={{ padding: '0 24px' }}>Click or drag file to this area to upload</p>
                        </Dragger>
                        {(doc.name !== "W-9") &&
                            <LabeledInput
                                label="Document type"
                                type="select"
                                size="type"
                                key="type"
                                defaultValue={doc.type}
                                placeholder="Purchase Order"
                                optionFilterProp="children"
                                onChange={(value, option) => this.setState({ uploadedFile: { ...uploadedFile, "type": value } })}
                                className="no-left-padding"
                            >
                                {docTypes.map(item =>
                                    <Option key={item.type} id="type" name={item.name} value={item.type} style={{ backgroundColor: "transparent" }}>
                                        {item.name}
                                    </Option>
                                )}
                            </LabeledInput>}
                        <LabeledInput
                            label="Note"
                            placeholder="Note"
                            nomargin
                            key="note"
                            id="note"
                            onChange={e => { this.setState({ uploadedFile: { ...uploadedFile, "notes": e.target.value } }) }}
                        />
                        <Flex start>
                            <Button solid permtype="Override" onClick={() => {
                                if (!uploadedFile.fileName) {
                                    ErrorAlert({ description: "Please upload a file before saving" })
                                    return
                                }
                                this.setState({
                                    vendor: update(this.state.vendor, { attachments: { $set: [...(this.state.vendor.attachments || []), uploadedFile] } }),
                                    showUploadModal: false
                                })
                            }
                            } text='Save' />
                        </Flex>
                    </FlexColumn>
                </FlexColumn>
            </FlexColumn>
        )
    }

    getDocumentSection = () => {
        var { vendor } = this.state
        var { attachments } = vendor || {}
        attachments = attachments || []
        const { theme } = this.props
        var documentsToUpload = [
            {
                name: `W-9`,
                type: "W-9"
            },
            {
                name: `Other`,
                type: "OTHER"
            }
        ]
        documentsToUpload.forEach(item => {
            if (attachments.find(document => document.fileName == item.name)) item.uploaded = true
        })

        const handleDeleteFile = (uri) => {
            var vendor = this.state.vendor || {}
            var attachments = (vendor.attachments || []).filter(item => item.uri !== uri)
            this.setState({
                vendor: update(this.state.vendor, { attachments: { $set: attachments } })
            })
        }

        return (
            <>
                <FlexColumn style={{ marginBottom: 24 }}>
                    <Text caption spaced weight='500' margin='0 0 24px'>DOCUMENTS</Text>

                    <List
                        bordered
                        loading={this.state.loading}
                        dataSource={documentsToUpload}
                        renderItem={item =>
                            <Flex centerHorizontally between style={{ padding: "6px 15px", borderBottom: `1px solid ${theme.colors.systemGray6}` }}>
                                <FlexColumn>
                                    <Text weight='500'>{item.name}</Text>
                                    {
                                        item.type === "W-9" &&
                                        <Text>IRS W-9: <a href="https://www.irs.gov/pub/irs-pdf/fw9.pdf" target="_blank">https://www.irs.gov/pub/irs-pdf/fw9.pdf</a></Text>
                                    }
                                </FlexColumn>
                                {(!item.uploaded || (item.type === "OTHER")) ?
                                    <div style={{ textAlign: "right" }}>
                                        <TextButton onClick={() => { this.setState({ showUploadModal: true, uploadDoc: item, uploadedFile: null }) }} text='Upload' />
                                    </div>
                                    :
                                    <Tag style={{ margin: "5px 0" }} tagpreset={theme.colors.secondary4}>Uploaded <CheckCircleFilled /></Tag>
                                }
                            </Flex>
                        }
                    />
                    {(attachments.length > 0) &&
                        <>
                            <Text weight='500' size='20px' style={{ marginTop: "40px" }}>Uploaded Documents</Text>
                            <Table
                                loading={this.state.loading}
                                dataSource={attachments}
                                scroll={{ y: '700px', x: '100%' }}
                                pagination={false}
                                rowKey='uri'
                                columns={[{
                                    title: 'Name',
                                    dataIndex: 'fileName',
                                    render: (name, item) => <SignedLink {...item} />
                                },
                                {
                                    title: 'Type',
                                    dataIndex: 'type',
                                    render: item => <Tag tagpreset={theme.colors.secondary8}>{item}</Tag>
                                },
                                {
                                    title: 'Note',
                                    dataIndex: 'notes'
                                },
                                {
                                    title: 'Action',
                                    width: "100px",
                                    render: (text, item) => (
                                        <Space size="middle">
                                            <Popconfirm title="Are you sure you want to delete this file?" onConfirm={() => handleDeleteFile(item.uri)}>
                                                <a><DeleteOutlined style={{ cursor: 'pointer', fontSize: 18 }} /></a>
                                            </Popconfirm>
                                        </Space>
                                    ),
                                    align: "right"
                                }]}
                            />
                        </>
                    }
                </FlexColumn>
            </>
        )
    }

    createVendor = () => {
        var { vendor, fromAccount, paymentMethod, accountNumber, routingNumber, checkAddress, paymentMethods } = this.state
        const { vendorName, primaryEmail, type } = vendor

        var successMessage = vendor.id ? "Vendor updated successfully!" : "Vendor created successfully!"

        if (!type) {
            ErrorAlert({ description: "Please select a type for this vendor." })
            return
        }

        if (!vendorName) {
            ErrorAlert({ description: "Please enter a vendor name." })
            return
        }

        if (vendorName.includes('|')) {
            ErrorAlert({ description: "Please enter a valid vendor name." })
            return
        }

        if (primaryEmail && !isEmailValid(primaryEmail)) {
            ErrorAlert({ description: "Please enter a valid email." })
            return
        }

        if (vendor.primaryEmail === "") {
            vendor.primaryEmail = null
        }

        // if (!fromAccount) {
        //     ErrorAlert({ description: "Please select an account." })
        //     return
        // }

        // if (paymentMethod === "ACH") {
        //     if (!accountNumber) {
        //         ErrorAlert({ description: "Please enter an account number." })
        //         return
        //     }

        //     if (accountNumber && (isNaN(accountNumber) || accountNumber.length > 17)) {
        //         ErrorAlert({ description: "Please enter a valid account number. It can have a maximum of 17 characters." })
        //         return
        //     }

        //     if (!routingNumber) {
        //         ErrorAlert({ description: "Please enter a routing number." })
        //         return
        //     }

        //     if (routingNumber && (isNaN(routingNumber) || routingNumber.length != 9)) {
        //         ErrorAlert({ description: "Please enter a valid 9-digit routing number." })
        //         return
        //     }
        // } else if (paymentMethod === "Check") {
        //     if (!checkAddress) {
        //         ErrorAlert({ description: "Check address is required. Please enter a valid address." })
        //         return
        //     }

        //     var { line1, city, state, postalCode } = checkAddress
        //     if (!line1 || !city || !state || !postalCode) {
        //         ErrorAlert({ description: "Check address is required. Please enter a valid address. 2" })
        //         return
        //     }
        // }

        this.setState({ createVendorLoading: true })

        const body = {
            "vendor": vendor,
            "saveToQBO": this.state.saveToQBO,
        }

        console.log("CreateVendor /payables/vendors/save", body, paymentMethods)

        apiPOSTReq(`${environment.payBaseUrl}/payables/vendors/save`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            try {
                this.setState({ loading: false })
                const data = resp
                console.log("/payables/vendors/save", JSON.stringify(data))
                if (data.result) {
                    this.setState({ vendor: data.vendor, editvendor: data.editvendor }, () => this.executeMultipleTransfers(paymentMethods, successMessage))

                    // if (paymentMethod === "ACH") {
                    //     this.saveACHPayment(successMessage)
                    // } else if (paymentMethod === "Check") {
                    //     this.saveCheckPayment(successMessage)
                    // }
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                this.setState({ createVendorLoading: false })
                console.log("ERRR /payables/vendors/save", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    addTransfer = (body) => {
        return new Promise((resolve, reject) => {
            const { vendor } = this.state
            body.vendorId = vendor.id

            apiPOSTReq(`${environment.payBaseUrl}/payables/vendors/addTransferDelivery`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
                try {
                    const data = resp || {};
                    if (data.result) {
                        resolve(data);  // Resolve the promise with data if successful
                    } else {
                        throw Error(data.responseMessage || data.error);
                    }
                } catch (error) {
                    reject(error);  // Reject the promise with error if something goes wrong
                }
            });
        });
    };

    executeMultipleTransfers = (paymentMethods, successMessage) => {
        const transferPromises = paymentMethods.map(body => this.addTransfer(body));

        Promise.all(transferPromises)
            .then((results) => {
                console.log("All transfer promises executed:", results);

                message.success(successMessage)
                window.history.back()
            })
            .catch((error) => {
                // Handle any error that occurred during any of the API calls
                console.log("Error in transfers:", error);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            });
    };

    saveACHPayment = (successMessage) => {
        const { vendor, vendorType, fromAccount } = this.state

        var body = {
            "vendorId": vendor.id,
            "accountId": fromAccount.accountId,
            "vendorType": vendorType,
            "bankDetail": {
                "accountName": vendor.vendorName || "",
                "accountNumber": this.state.accountNumber || "",
                "routingNumber": this.state.routingNumber || "",
                "accountType": (this.state.accountType || "").toLowerCase()
            },
        }

        this.setState({ submitLoading: true })
        console.log("/payables/vendors/addTransferDelivery", JSON.stringify(body))
        apiPOSTReq(`${environment.payBaseUrl}/payables/vendors/addTransferDelivery`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            this.setState({ createVendorLoading: false })
            try {
                if (err) throw Error(err)
                const data = resp || {}
                if (data.result) {
                    message.success(successMessage)
                    body.type = "ACH"
                    vendor.deliveryMode = "ACH"
                    this.props.onSave(vendor, body)
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/createCounterparty err", error, resp, body)
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    saveCheckPayment = (successMessage) => {
        var { vendor, checkAddress, vendorType, vendor, fromAccount } = this.state
        var { line1, line2, city, state, postalCode, countrySubDivisionCode } = checkAddress

        var body = {
            "vendorId": vendor.id,
            "accountId": fromAccount.accountId,
            "vendorType": vendorType,
            "bankDetail": {
                "bankAddress": {
                    "line1": line1,
                    "line2": line2,
                    "city": city,
                    "state": countrySubDivisionCode,
                    "postalCode": postalCode
                }
            },
        }

        console.log("/addCheckDelivery body", JSON.stringify(body))
        this.setState({ submitLoading: true })
        apiPOSTReq(`${environment.payBaseUrl}/payables/vendors/addCheckDelivery`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            console.log("/addCheckDelivery err, resp", err, resp)
            this.setState({ createVendorLoading: false })
            try {
                const data = resp || {}
                if (data.result) {
                    message.success(successMessage)
                    body.type = "Check"
                    vendor.deliveryMode = "Check"
                    this.props.onSave(vendor, body)
                } else {
                    throw Error(data.error || data.responseMessage)
                }
            } catch (error) {
                console.log("/addCheckDelivery err", error)
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    render() {
        const { aionStore } = this.props;
        const { vendor, paymentMethod, accountType, checkAddress, createVendorLoading, accountNumber, edit, vendorType, showPaymentModal, paymentMethods } = this.state;

        var { id, vendorName, primaryEmail, primaryPhone, taxId, address, type, billRecipientEmail } = vendor;

        var Accounts = getBPAccountsWithAccess(null, { resourceStr: "Payables.Vendors", permType: "manage", getActiveAccounts: true }) || []
        var accountOptions = [];
        var filteredAccounts = Accounts.filter(item => { return (item.accountType !== 'ACCOUNTS_RECEIVABLE') }) || []
        filteredAccounts.forEach(account => {
            accountOptions.push(
                <Option key={account.accountId} id={id} value={account.accountId} style={{ backgroundColor: "transparent" }}>
                    <FromAccountOption account={account} />
                </Option>
            )
        })

        return (
            <FlexColumn className='main-padding' fullWidth start grow>
                <PageHeader
                    back
                    titleText={this.props.location.state?.vendor ? this.props.location.state?.vendor.displayName : 'New Vendor'}
                />

                <CardContainer padding='0' style={{ minWidth: '1258px', marginBottom: 24 }} grow>
                    <FlexColumn between fullHeight>
                        <FlexColumn start style={{ padding: '24px 24px 0 24px' }} grow gap="24px">
                            <Text caption spaced weight='500'>VENDOR DETAILS</Text>

                            <Flex start gap='24px'>
                                <LabeledInput
                                    label="Type"
                                    type='switch'
                                    width="592px"
                                    nomargin
                                    switchNames={['Individual', 'Business']}
                                    onChange={(value) => this.saveVendorInput("type", value)}
                                    value={vendor.type} />
                            </Flex>

                            <Flex start gap='24px'>
                                <LabeledInput
                                    autoFocus
                                    label="Name"
                                    nomargin
                                    width="592px"
                                    id="vendorName"
                                    key="vendorName"
                                    placeholder="Enter vendor name"
                                    value={vendorName}
                                    onChange={this.handleVendorTextChange}
                                />

                                <LabeledInput
                                    optional
                                    nomargin
                                    label="Email"
                                    width="592px"
                                    id="primaryEmail"
                                    key="primaryEmail"
                                    placeholder="Enter email"
                                    value={primaryEmail}
                                    onChange={this.handleVendorTextChange}
                                />
                            </Flex>

                            <Flex start gap='24px'>
                                <FlexColumn between style={{ height: 149 }}>
                                    <LabeledInput
                                        optional
                                        label="Phone"
                                        nomargin
                                        width="592px"
                                        id="primaryPhone"
                                        key="primaryPhone"
                                        placeholder="Enter phone number"
                                        value={primaryPhone}
                                        maxLength={10}
                                        onChange={this.handleVendorTextChange}
                                    />

                                    <LabeledInput
                                        optional
                                        label={(type !== "Individual") ? "EIN" : "SSN"}
                                        id="taxId"
                                        key="taxId"
                                        width="592px"
                                        nomargin
                                        placeholder="373888567"
                                        value={taxId}
                                        onChange={this.handleVendorTextChange}
                                        maxLength={9}
                                        unsyncable={isCodatUser(aionStore)}
                                    />
                                </FlexColumn>

                                <LabeledInput
                                    optional
                                    id="address"
                                    key="address"
                                    label="Address"
                                    type="location"
                                    nomargin
                                    width="592px"
                                    getLocation={this.getVendorLocation}
                                    Line2
                                    address={address}
                                    value={address && address.line1 ?
                                        { line1: addressObjectToStr(address), line2: address.line2 } : null
                                    }
                                    placeholder="Address"
                                />

                            </Flex>

                            <div style={{ width: '592px' }}>
                                <LabeledInput
                                    label="Bill payment notification email"
                                    id="emails"
                                    key="emails"
                                    type="multi-email"
                                    nomargin
                                    onChange={(billRecipientEmail) => {
                                        console.log("CreateCustomer nextStep customer:", billRecipientEmail)
                                        this.setState({ billRecipientEmail: billRecipientEmail })
                                        this.setState({
                                            vendor: update(this.state.vendor, { billRecipientEmail: { $set: (billRecipientEmail) } })
                                        });
                                    }}
                                    onDelete={(index) => {
                                        var emails = []

                                        for (var i = 0; i < billRecipientEmail.length; i++) {
                                            if (i != index) emails.push(billRecipientEmail[i])
                                        }

                                        this.setState({
                                            vendor: update(this.state.vendor, { billRecipientEmail: { $set: (emails) } })
                                        });
                                    }}
                                    emails={billRecipientEmail && billRecipientEmail}
                                    // error={errorField === "TO"}
                                    errorMessage={"This is a mandatory field"}
                                    instruction="Email of the recipient(s) that will receive bill payment notifications"
                                    containerStyle={{ padding: '4px 8px', width: '592px' }}
                                    optional
                                />
                            </div>

                            <div style={{ height: 1, width: '100%', background: '#F2F2F2' }}></div>

                            <Flex start gap='24px'>
                                <FlexColumn start style={{ width: "592px" }}>
                                    <Text caption spaced weight='500'>VENDOR PAYMENT DETAILS</Text>
                                    <Text width='800px' style={{ marginTop: 16 }}>{edit ? 'Edit' : 'Add'} bank details for this vendor. You can add multiple transfer<br />methods {/** `(i.e. ${instantPaymentsEnabled.manage ? 'Instant, ' : ''}ACH, Wire)` */} for each of your vendors.</Text>

                                    {
                                        paymentMethods.length > 0 &&
                                        <FlexColumn style={{ marginTop: 24 }} gap='8px'>
                                            {
                                                paymentMethods.sort((a, b) => b.active - a.active).map((transferMethod, index) => {
                                                    return <LightContainer width='580px' padding='12px 24px'>
                                                        <Flex between fullWidth centerHorizontally>
                                                            <Flex start centerHorizontally gap='8px'>
                                                                <Text>{transferMethod.nickName}</Text>
                                                                <Tag primary>{transferMethod.type}</Tag>
                                                                <Tag>{transferMethod.bankIcon && <img width={16} height={16} src={transferMethod.bankIcon} />} {transferMethod.bankDetail.accountNumber.substring(transferMethod.bankDetail.accountNumber.length - 4, transferMethod.bankDetail.accountNumber.length)}</Tag>
                                                            </Flex>

                                                            <Flex end centerHorizontally gap='8px'>

                                                                {
                                                                    (!this.edit || transferMethod.active) ?
                                                                        <>
                                                                            <ImageButton src={Edit} onClick={() => {
                                                                                this.setState({ showTransferModal: true, editTm: transferMethod, moreDropdownVisible: false })
                                                                            }} />
                                                                            <ImageButton src={Delete} color='#E52D2D' onClick={() => {
                                                                                if (this.edit) {
                                                                                    this.setState({ showDeactivateModal: true, deactivateTm: transferMethod, moreDropdownVisible: false })
                                                                                } else {
                                                                                    const updatedTransferMethods = paymentMethods.filter((tm) => tm !== transferMethod)
                                                                                    this.setState({ paymentMethods: updatedTransferMethods })
                                                                                }
                                                                            }} />
                                                                        </>
                                                                        :
                                                                        <Tag alert>Inactive</Tag>
                                                                }
                                                            </Flex>
                                                        </Flex>
                                                    </LightContainer>
                                                })
                                            }
                                        </FlexColumn>
                                    }

                                    <Flex start style={{ marginTop: 24 }}>
                                        <TextButton uppercase onClick={() => this.setState({ showPaymentModal: true })} icon={<Image src={Add} />} text='ADD PAYMENT DETAILS' />
                                    </Flex>
                                </FlexColumn>
                            </Flex>
                        </FlexColumn>

                        <FlowFooter
                            disabled={false}
                            loading={createVendorLoading}
                            back={false}
                            buttonTitle={'CONFIRM'}
                            onClick={this.createVendor}
                            solid
                        />
                    </FlexColumn>
                </CardContainer>

                <Modal
                    visible={this.state.showUploadModal}
                    footer={null}
                    closable={true}
                    width={550}
                    destroyOnClose={true}
                    onCancel={() => { this.setState({ showUploadModal: false }) }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    {this.getModalForDocUpload(this.state.uploadDoc || {})}
                </Modal>

                <Modal
                    visible={showPaymentModal}
                    footer={null}
                    closable={true}
                    width={700}
                    destroyOnClose={true}
                    onCancel={() => this.setState({ showPaymentModal: false, editPm: null })}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <AddPaymentMethod
                        returnBody
                        pm={this.state.editPm}
                        vendor={vendor}
                        onSave={(vendor, body) => {
                            paymentMethods.push(body)
                            this.setState({ showPaymentModal: false, paymentMethods })
                        }} />
                </Modal>
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        bkStore: state.bookkeepingAppReducer,
        aionStore: state.aionAppReducer,
        bankingStore: state.bankingAppReducer,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(NewVendor))