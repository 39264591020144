import React, { Component, useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { withTheme } from 'styled-components';

import { FlexColumn, Flex, CardContainer } from '../../../Reusable/Container';
import { LabeledInput } from '../../../Reusable/Input';
import { Text } from '../../../Reusable/Text';
import { Divider } from '../../../Reusable/Divider';
import Banner from '../../../Reusable/Banner'
import { apiPOSTReq } from '../../../../Utils/api';
import environment from '../../../../environment';
import { getApplicationDisplayName, isEmailValid, isSmallScreen, roleHeirarchy } from '../../../../Utils/util';
import { ErrorAlert } from '../../../Reusable/Alert';
import PageHeader from '../../../Reusable/PageHeader';
import { Button, Chip } from '../../../Reusable/Button';
import { Affix, Checkbox, Radio, message } from 'antd';
import { Image } from '../../../Reusable/Image';
import AlertModal from '../../../Reusable/AlertModal'

// Images
import NewUser from '../../../../Images/new-user.svg'
import Info from '../../../../Images/info-gradient.png'
import styled from 'styled-components';
import ViewRole from '../ViewRole';
import FlowFooter from '../../../Reusable/FlowFooter';

const RoleContainerBorder = styled.div`
    padding: 1px;
    border-radius: 8px;
    background: ${props => props.active ? `linear-gradient(90deg, #1199FF 0%, #FFFFFF 50%)` : `#E3E6EE`};
    box-shadow: ${props => props.active && `0px 4px 8px rgba(102, 102, 102, 0.08)`};
`

const RoleContainer = styled(FlexColumn)`
    padding: 12px 24px;
    min-height: 76px;
    background: ${props => props.theme.body};
    border-radius: 8px;
    cursor: pointer;
    pointer-events: ${props => props.disabled ? 'none' : 'auto'};
`

const BulletConnector = styled.div`
    position: absolute;
    width: 32px;
    height: ${props => props.height || '120px'};
    left: 36px;
    top: 0px;

    border-bottom: 1px solid rgb(229, 229, 234);
    border-left:   1px solid  rgb(229, 229, 234);
    border-bottom-left-radius: 4px;
`

class Index extends Component {

    state = {
        loading: false,
        systemRoles: [],
        customRoles: [],
        roleType: "Standard",
        selectedRole: "",
        selectedSubRole: "",
        selectedPrdRoleNames: [],
        selectedRoleList: [],
        selectedCustomRoleList: []
        // email: "engr0425232@mg.aionfi.com"
    }

    componentDidMount() {
        this.fetchRoles()
    }

    fetchRoles() {
        const { aionStore } = this.props
        var { businessSubscriptionPlan } = aionStore
        businessSubscriptionPlan = businessSubscriptionPlan || {}

        console.log('fetchRoles businessSubscriptionPlan', businessSubscriptionPlan)

        apiPOSTReq(`${environment.uamBaseUrl}/uam/getRolesForBusiness`, {}, { PlanName: (businessSubscriptionPlan || {}).currentPlan }, (err, resp) => {
            try {
                var roles = resp.roles || []
                roles.sort(function (x, y) { return x.roleType == "Custom__Sys" ? 1 : y.roleType == "Custom__Sys" ? -1 : 0 })

                console.log("getRolesForBusiness", resp)

                // Revenue and Expenses Admin handling
                var revenueAndAdminRoles = []
                var indexesToRemove = []

                roles.forEach((role, i) => {
                    // Add App names to system roles
                    role.resourcePermissions.forEach(permission => {
                        if (!permission.nameSpace) {
                            permission.nameSpace = getApplicationDisplayName(role.applicationName) // getAppDisplayNameBySystemRole(role.roleName)
                        }
                    })

                    // Revenue and Expenses Admin handling
                    if (role.roleName === "Revenue and Expenses Admin") {
                        revenueAndAdminRoles.push(role)
                        indexesToRemove.push(i)
                    }
                })

                // Revenue and Expenses Admin handling
                if (revenueAndAdminRoles.length > 1) {
                    var revenueAndAdminRole = revenueAndAdminRoles[0]

                    for (var i = 1; i < revenueAndAdminRoles.length; i++) {
                        revenueAndAdminRole.resourcePermissions = revenueAndAdminRole.resourcePermissions.concat(revenueAndAdminRoles[i].resourcePermissions)
                    }

                    while (indexesToRemove.length) {
                        roles.splice(indexesToRemove.pop(), 1)
                    }

                    roles.push(revenueAndAdminRole)
                }

                var systemRoles = roles.filter(function (role) {
                    return role.roleType != "Custom__Sys" && role.roleName != "Staff"
                })

                var customRoles = roles.filter(function (role) {
                    return role.roleType === "Custom__Sys" && role.roleName != "Staff"
                })

                console.log('fetchRoles: ', systemRoles, customRoles)

                this.setState({ allRoles: roles, rolesLoading: false, systemRoles, customRoles })

                if (err) throw Error(err)
                if (!resp.result) throw Error(resp.responseMessage || resp.error)
            } catch (error) {
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    handleOnChange = (event) => {
        const target = event.target
        this.saveUserInput(target.id || target.name, target.value)
    }

    saveUserInput = (id, value) => {
        this.setState({ [id]: value })
    }

    handlePrdSelection = (roleName) => {
        console.log("handlePrdSelection", roleName)
        var { selectedPrdRoleNames } = this.state
        if (selectedPrdRoleNames.includes(roleName)) {
            selectedPrdRoleNames = selectedPrdRoleNames.filter(name => (name != roleName))
        }
        else {
            selectedPrdRoleNames.push(roleName)
        }
        this.handleSelectedRoleList(roleName)
        this.setState({ selectedPrdRoleNames })
    }

    handleSelectedRoleList = (roleName, options) => {
        var { selectedRoleList, selectedCustomRoleList } = this.state
        var { parent, custom } = options

        if (selectedRoleList.includes(roleName)) {
            selectedRoleList = selectedRoleList.filter(name => (name != roleName))
            if (custom) selectedCustomRoleList = selectedCustomRoleList.filter(name => (name != roleName))
        }
        else {
            // Clear all non-custom roleNames and replace with roleName for parent
            if (parent) {
                if (selectedCustomRoleList.length == 0) {
                    selectedRoleList = []
                } else {
                    selectedRoleList = selectedRoleList.filter(name => (selectedCustomRoleList.includes(name)))
                }

            }
            selectedRoleList.push(roleName)
            if (custom) selectedCustomRoleList.push(roleName)
        }
        this.setState({ selectedRoleList, selectedCustomRoleList })
    }

    getRoleInfo = () => {
        var { systemRoles, customRoles, selectedRoleList } = this.state
        var roles = selectedRoleList
        var roleInfo = []
        var resourcePermissions = {}
        const allAccountResourcePermissions = [];
        roles.forEach(role => {
            let sr = (systemRoles || []).find(item => role == item.roleName)
            let cr = (customRoles || []).find(item => role == item.roleName)
            if (sr) roleInfo.push(sr);
            if (cr) roleInfo.push(cr);
        })

        roleInfo.forEach(item => {
            item.resourcePermissions.forEach(perm => {
                let key = `${perm.nameSpace}_${perm.resourceLabel}_${perm.resourceDescription}`;
                let permissions = perm.permissionCodes;
                if (resourcePermissions[key]) {
                    let concatinatedPermissions = resourcePermissions[key].concat(permissions);
                    let uniquePermisssions = [];
                    //remove duplicate permissions
                    for (let i of concatinatedPermissions) {
                        if (uniquePermisssions.indexOf(i) === -1) {
                            uniquePermisssions.push(i);
                        }
                    }
                    resourcePermissions[key] = uniquePermisssions;
                }
                else {
                    resourcePermissions[key] = (permissions);
                }
            });

            (item.accountResourcePermissions || []).forEach(accountResourcePermission => {
                if (!allAccountResourcePermissions.some(perm => perm.accountId === accountResourcePermission.accountId)) {
                    allAccountResourcePermissions.push(accountResourcePermission);
                }
            });
        })
        var formattedRole = {
            roleName: "My Permissions",
            resourcePermissions: [],
            accountResourcePermissions: allAccountResourcePermissions,
        }
        formattedRole.resourcePermissions = Object.keys(resourcePermissions).map(key => {
            const keyArr = key.split("_")
            return {
                nameSpace: keyArr[0],
                resourceLabel: keyArr[1],
                permissionCodes: resourcePermissions[key],
                resourceDescription: keyArr[2],
            }
        })
        console.log("getRoleInfo", roleInfo, formattedRole)
        return formattedRole
    }

    handleSubmit = () => {
        var { email, selectedRoleList } = this.state
        var { BusinessUniqueKey, userInfo } = this.props.aionStore
        if (!isEmailValid(email)) {
            this.setState({ errorField: "email", errorMessage: "Enter a valid email" })
            return
        }
        if (selectedRoleList.length == 0) {
            ErrorAlert({ description: "Assign at least one role to this user" })
            return
        }
        var body = {
            "user": {
                "email": email,
                "businessId": BusinessUniqueKey,
                "roles": selectedRoleList,
                "invitedByEmail": userInfo.email,
                "invitedByName": `${userInfo.firstName} ${userInfo.lastName}`,
                "userType": "BusinessUser",
            }
        }
        console.log("preboardUser body", body)
        this.setState({ loading: true })
        apiPOSTReq(`${environment.uamBaseUrl}/preboardUser`, {}, body, (err, resp) => {
            try {
                if (err) throw Error(err)
                var data = resp || {}
                console.log("preboardUser data", err, data.preboardUser)
                if (!data.result) throw Error(data.responseMessage || data.error)
                this.setState({ loading: false })
                message.success('Successfully invited user!')
                window.history.back()
            } catch (error) {
                this.setState({ loading: false })
                ErrorAlert({ description: error.message || "Sorry we had trouble processing your request. Please try again." })
            }
        })
    }

    confirmNewUser = () => {
        var { email, selectedRoleList } = this.state
        if (!isEmailValid(email)) {
            this.setState({ errorField: "email", errorMessage: "Enter a valid email" })
            return
        }
        if (selectedRoleList.length == 0) {
            ErrorAlert({ description: "Assign at least one role to this user" })
            return
        }
        this.setState({ addNewUserVisible: true })
    }

    render() {
        var { theme } = this.props
        var { systemRoles, customRoles, email, roleType, selectedRole, addNewUserVisible, selectedRoleList, errorField, errorMessage, loading } = this.state
        var productAdminRoles = []
        var customRoleType = roleType == "Custom"
        var companyAdminRole = systemRoles.find(role => role.roleName == "Company Admin")
        var otherRoles = []
        systemRoles = systemRoles ? systemRoles.map((role, i) => ({ ...role, id: roleHeirarchy[role.roleName] || i + 1 })) : []
        systemRoles.sort((a, b) => a.id - b.id)
        systemRoles.forEach(role => {
            var roleName = role.roleName
            if (["Primary Admin", "Company Admin"].includes(roleName)) return

            otherRoles.push(role)
        })

        const outOfUsers = this.props.location.state?.outOfUsers

        const RoleTypeContainer = (props) => {
            var { role, customContainer, subRole, parentChecked, custom } = props
            role = role || {}
            var active = selectedRoleList.includes(role.roleName)
            console.log("selectedRole", selectedRole)

            return (
                <RoleContainerBorder active={active}>
                    <RoleContainer disabled={parentChecked} onClick={() => this.handleSelectedRoleList(role.roleName, { custom: custom })}>
                        <Flex start gap='8px'>
                            <Checkbox checked={parentChecked || active} disabled={parentChecked} onChange={(e) => { }} />
                            <Text lightText={parentChecked}>{role.roleName}</Text>
                        </Flex>
                        <Text size='14px' lightText={parentChecked}>{role.description}</Text>
                        {active && customContainer}
                    </RoleContainer>
                </RoleContainerBorder>
            )
        }

        const ParentRoleTypeContainer = (props) => {
            var { role, subRoles } = props
            role = role || {}
            subRoles = subRoles || []
            const roleName = role.roleName
            var parentChecked = selectedRoleList.includes(roleName)
            // console.log('productAdminRoles', productAdminRoles)
            return (
                <FlexColumn>
                    <RoleContainerBorder active={parentChecked}>
                        <RoleContainer onClick={() => this.handleSelectedRoleList(roleName, { parent: true })}>
                            <Flex start gap='8px'>
                                <Checkbox checked={parentChecked} onChange={(e) => this.handleSelectedRoleList(roleName, { parent: true })} />
                                <Text>{roleName}</Text>
                            </Flex>
                            <Text size='14px'>{role.description}</Text>
                        </RoleContainer>
                    </RoleContainerBorder>
                    <FlexColumn gap='8px' style={{ padding: '8px 0 0 68px', position: "relative" }}>
                        {
                            subRoles.map((subRole, i) => (
                                <>
                                    <BulletConnector height={`${34 + i * 86}px`} />
                                    <RoleTypeContainer
                                        subRole={true}
                                        role={subRole}
                                        parentChecked={parentChecked}
                                    />
                                </>
                            ))
                        }

                        {/* <RoleTypeContainer 
                            subRole
                            role={{ 
                                roleName: "Product Admin", 
                                description: "Provides complete access to one or more of your products available on your plan." 
                            }}
                            customContainer={
                                <FlexColumn start gap='8px' style={{margin: '16px 0'}}>
                                    <Text lightText>Select from any of the admin roles below.</Text>
                                    {productAdminRoles.map(productRole => <Flex start gap='8px' centerHorizontally>
                                        <Checkbox checked={selectedPrdRoleNames.includes(productRole.roleName)} onChange={(e) => this.handlePrdSelection(productRole.roleName)}>{productRole.roleName}</Checkbox>
                                    </Flex>)}
                                </FlexColumn>
                            }
                        /> */}
                    </FlexColumn>
                </FlexColumn>

            )
        }

        console.log('all roles', selectedRole, productAdminRoles, otherRoles, customRoles)
        const conatinerHeight = isSmallScreen ? '650px' : '800px'
        return (
            <FlexColumn className='main-padding' fullWidth>
                <PageHeader
                    titleText="Invite User"
                    close
                />

                <Banner
                    color='#1199FF'
                    icon={<Image src={Info} />}
                    message={outOfUsers ? "Looks like you are out of your free users! It’s only an additional $10 per month for adding this user" : "The first two users on your account are free! It only costs $10 per month to invite additional users"}
                />

                <CardContainer margin='24px 0 48px' padding='0px' style={{ overflowY: 'scroll', maxHeight: conatinerHeight }} fullWidth>
                    <Flex between style={{ padding: '24px', minHeight: conatinerHeight }}>
                        <FlexColumn left fullHeight>
                            <Flex fullHeight gap="48px">
                                <FlexColumn style={{ width: '450px' }}>
                                    <LabeledInput
                                        label="Email Address"
                                        type="email"
                                        key="email"
                                        id="email"
                                        onChange={this.handleOnChange}
                                        value={email}
                                        placeholder="tim@mail.com"
                                        instruction="This will be their Sign In ID"
                                        error={errorField == "email"}
                                        errorMessage={errorMessage}
                                    />
                                    <Divider />
                                    <FlexColumn gap='16px'>
                                        <Text uppercase spacing='0.08em' weight='500' color={theme.colors.secondary6}>Select a Role</Text>
                                        <Text style={{ maxWidth: '450px' }}>You can select one or more roles</Text>

                                        {
                                            customRoles.length > 0 &&
                                            <LabeledInput
                                                margin='0px'
                                                label=''
                                                type='switch'
                                                switchNames={['Standard', 'Custom']}
                                                onChange={(value) => this.saveUserInput("roleType", value)}
                                                value={roleType}
                                            />
                                        }
                                        {
                                            !customRoleType && <ParentRoleTypeContainer role={companyAdminRole} subRoles={otherRoles} />
                                        }
                                        {
                                            customRoleType && customRoles.map(role => <RoleTypeContainer role={role} custom={true} />)
                                        }
                                    </FlexColumn>
                                </FlexColumn>
                                <FlexColumn>

                                </FlexColumn>
                            </Flex>

                            {/* <Button style={{ marginTop: '24px' }} solid text="INVITE" onClick={() => this.handleSubmit()} /> */}
                        </FlexColumn>
                        <FlexColumn fullHeight>
                            {
                                (selectedRoleList.length > 0) &&
                                <div style={{ border: '1px solid #E3E6EE', borderRadius: '8px', padding: '24px', marginTop: '8px', maxWidth: 698 }}>
                                    <ViewRole
                                        width="650px"
                                        selectedRole={this.getRoleInfo()}
                                        closeModal={() => {
                                            this.setState({ showViewRoleModal: false, selectedRole: null })
                                        }}
                                        customDescComponent={
                                            <Flex start centerHorizontally wrap gap='8px'>
                                                <Text caption>Selected roles: </Text>
                                                {
                                                    selectedRoleList.map(rName => (
                                                        <Chip
                                                            selected={true}
                                                            text={rName}
                                                            background='#F5F6FE'
                                                            fontSize='14px'
                                                            height='32px'
                                                            border='0px'
                                                            noWrap
                                                        />
                                                    ))
                                                }
                                            </Flex>
                                        }
                                    />
                                </div>
                            }
                        </FlexColumn>
                    </Flex>
                    <div style={{ position: 'sticky', bottom: '0px' }}>
                        <FlowFooter
                            loading={loading}
                            back={false}
                            buttonTitle='INVITE'
                            onClick={() => outOfUsers ? this.confirmNewUser() : this.handleSubmit()}
                        />
                    </div>
                </CardContainer>

                <AlertModal
                    visible={addNewUserVisible}
                    title='Add new user'
                    description="You will be charged an additional $10 per month for this new user"
                    buttonTitle='CONFIRM'
                    cancelButtonText='CANCEL'
                    onConfirm={() => this.handleSubmit()}
                    onCancel={() => this.setState({ addNewUserVisible: false })}
                    imgSrc={NewUser}
                    imgWidth='80px'
                    imgHeight='80px'
                    loading={loading}
                />
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)));