import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { Modal, Select, Menu, Dropdown } from 'antd';
import { PlusOutlined, CheckOutlined, MoreOutlined } from '@ant-design/icons';

import { Text } from '../../Reusable/Text';
import { Flex, FlexColumn, Container } from '../../Reusable/Refresh/Container';
import { ErrorAlert } from '../../Reusable/Alert';
import AddOwnerModal from './AddOwnerModal'

// Actions
import { LabeledInput, StyledLabel } from '../../Reusable/Refresh/Input';
import { Button, TextButton, Capsule } from '../../Reusable/Refresh/Button';
import { OwnerCard } from '../../Reusable/Refresh/Card';
import { getAddressObj } from '../../../Utils/util';
import ModalClose from '../../../Images/modal-close.png';
import { addDataToStore, ONB_SAVE_DATA, REMOTE_SAVE_DATA } from '../../../Actions/actions';
import cloneDeep from 'lodash/cloneDeep'

const textFieldStyle = {
    background: "#F4F5F7",
    borderRadius: "8px",
    paddingLeft: "5px",
    height: "40px",
    marginTop: "2px"
}
class ListOwnerView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rowItem: { type: 'Individual' },
            newOwner: true
        }
    }

    setOwnerInfo = (addedItems) => {
        this.props.setOwner(addedItems);
    }

    setRowItem = (rowItem) => {
        this.setState({ rowItem });
    }

    removeOwner = (email) => {
        var { onboardingStore } = this.props;
        var coOwnersInfo = onboardingStore.coOwnersInfo || [];
        console.log("removeOwner", email);
        coOwnersInfo = coOwnersInfo.filter(item => {
            if (item.email !== email) return item
        });
        this.props.dispatch(addDataToStore(ONB_SAVE_DATA, { coOwnersInfo: coOwnersInfo }));
        this.props.dispatch(addDataToStore(REMOTE_SAVE_DATA, "Apply.Basic")); // Apply.Basic because we want the user back on this step on next login
        this.setState({ showOwnerModal: false })
    }

    render() {
        var { theme, onboardingStore } = this.props;
        var coOwnersInfo = onboardingStore.coOwnersInfo || [];
        var t = this;

        function addOwner() {
            t.setState({ showOwnerModal: true, ownerInfo: {}, newOwner: true});
        }

        function editOwner(email) {
            var selectedOwner = coOwnersInfo.find((item) => {
                return item.email == email;
            })
            t.setState({ ownerInfo: selectedOwner, showOwnerModal: true, newOwner: false});
        }

        const coOwnerInfoClone = cloneDeep(coOwnersInfo);

        var menuItems = [];
        menuItems.push(
            <Menu.Item >
                <a onClick={() => editOwner(this.state.selectedKey)}>
                    Edit
                </a>
            </Menu.Item>,
            <Menu.Item >
                <a onClick={() => this.removeOwner(this.state.selectedKey)}>
                    Delete
                </a>
            </Menu.Item>

        )
        const menu = <Menu style={{ maxWidth: "250px" }} >{menuItems}</Menu>;

        return (
            <FlexColumn start style={{ width: "100%" }}>
                <Flex wrap start >
                    {coOwnersInfo.map((item, j) => {
                    return (
                                <OwnerCard ownerInfos={item} theme={theme} symbolprefix="%" key={j} onClick={() => editOwner(item.email)}/>
                            )
                    })}
                </Flex>
                <Modal
                    visible={this.state.showOwnerModal}
                    footer={null}
                    closable={true}
                    width={700}
                    destroyOnClose={true}
                    onCancel={() => { 
                        this.props.dispatch(addDataToStore(ONB_SAVE_DATA, { coOwnersInfo: coOwnerInfoClone }));
                        this.setState({ showOwnerModal: false });
                    }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <AddOwnerModal
                        onCancel={() => {this.setState({ showOwnerModal: false })}}
                        onSubmit={(coOwnersInfo) => {
                        this.props.dispatch(addDataToStore(ONB_SAVE_DATA, { coOwnersInfo: coOwnersInfo }));
                        this.setState({ showOwnerModal: false });
                    }}
                        onDelete={(email) => this.removeOwner(email)}
                        ownerInfo={this.state.ownerInfo}
                        coOwnersInfo={coOwnersInfo}
                        newOwner={this.state.newOwner}
                    />
                </Modal>
                {<TextButton onClick={() => addOwner()} text={"ADD OWNER"}></TextButton>}
            </FlexColumn>
        );
    }


}
function mapStateToProps(state) {
    return {
        store: state.aionAppReducer,
        onboardingStore: state.onboardingAppReducer,
        creditStore: state.creditAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(ListOwnerView)));