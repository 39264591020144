import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'
import styled from 'styled-components'
import { saveAs } from 'file-saver'

import {
    message, Upload, Modal, Checkbox
} from 'antd'

// Components
import { Text } from '../../Reusable/Text'
import { Image } from '../../Reusable/Image'
import { TextButton } from '../../Reusable/Button'
import { FlexColumn, Flex } from '../../Reusable/Container'
import environment from '../../../environment'
import LUploadIcon from '../../../Images/upload-bill-icon/blue.png'
import DUploadIcon from '../../../Images/upload-bill-icon/dark.png'
import Result from '../../Reusable/Result'
import ModalClose from '../../../Images/modal-close.png'
import Download from '../../../Images/download.png'

export const CardButton = styled.div`
    margin-top: 18px;
    background-color: rgb(255, 255, 255);
    border-radius: 0.5rem;
    width: 450px;
    margin-bottom: 14px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    opacity: 1;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.1);
    padding: 1.8rem;
    transition-property: box-shadow;
    transition-duration: 300ms;
    &.selected {
        cursor: pointer;
        box-shadow: 0 3px 15px rgb(0 0 0 / 0.2);
    };
    &.selected:hover {
        cursor: pointer;
        box-shadow: 0 3px 15px rgb(0 0 0 / 0.4);
    };
`

export const CardButtonText = styled.span`
    font-size: 18px;
    font-weight: 400;
`

class UploadInventory extends Component {

    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            file: null,
            fileName: '',
            showErrorModal: false,
            fileList: [],
        }
    }

    render() {
        const { certifyChecked, responseErrorMessage } = this.state
        const { theme, aionStore } = this.props
        const darkMode = (theme.type === "dark")

        const { UserInfo } = aionStore
        var certifiedBy = `${UserInfo.FirstName} ${UserInfo.LastName}`

        var uploadProps = {
            accept: ".csv",
            beforeUpload: file => {
                console.log('before upload', file)
                this.setState({ polling: true, })
                return
            },
            multiple: false,
            action: this.props.actionUrl || `${environment.iveBaseUrl}/inventory/uploadInventories`,
            data: (file) => {
                return {
                    File: file,
                    CertifiedBy: certifiedBy,
                    Notes: null,
                }
            },
            headers: {
                AionCurrentBiz: this.props.aionStore.BusinessUniqueKey,
                AionAuth: this.props.aionStore.UAM.encryptedAuthHeader,
            },
            multiple: false,
            onChange: (info) => {
                var { file } = info
                console.log('info', info)
                this.setState({ fileList: [file] })
                const { status, response } = file

                console.log('status', status, response)
                if (status === "done") {
                    if (response.result) {
                        console.log('job id', response.uploadJobId)
                        message.success("Inventory uploaded successfully!")
                        this.props.submitComplete(response.uploadJobId)
                    } else {
                        console.log("inventory/uploadInventories response", JSON.stringify(response))
                        this.setState({
                            showErrorModal: true,
                            fileList: [],
                            responseErrorMessage: response.responseMessage,
                            uploadInventoryInfo: response.uploadInventoryInfo
                        })
                    }
                } else if (status === "error") {
                    console.log("inventory/uploadInventories file", JSON.stringify(file))
                    this.setState({ showErrorModal: true, fileList: [] })
                }
            }
        }

        let showHeaderErrorTable = this.state.uploadInventoryInfo && this.state.uploadInventoryInfo.headerErrors && this.state.uploadInventoryInfo.headerErrors.length > 0
        let errors = []
        // let errorMessage = this.stat>"?e.errorMessage
        if (this.state.uploadInventoryInfo && this.state.uploadInventoryInfo.headerErrors && this.state.uploadInventoryInfo.headerErrors.length > 0) {
            errors = this.state.uploadInventoryInfo.headerErrors.map((x, i) => {
                return { ...x, key: i }
            })
            // errorMessage = "The file is missing required column headers"
        } else if (this.state.uploadInventoryInfo && this.state.uploadInventoryInfo.valueErrors && this.state.uploadInventoryInfo.valueErrors.length > 0) {
            errors = this.state.uploadInventoryInfo.valueErrors.map((x, i) => {
                return { ...x, key: i }
            })
        }
        let errorMessage = "The file you uploaded contains the below errors. Please correct the errors and retry."

        let errorNum = 0
        if (this.state.uploadInventoryInfo && this.state.uploadInventoryInfo.recordsErrorCount) {
            errorNum = this.state.uploadInventoryInfo.recordsErrorCount
        }
        let successNum = 0
        if (this.state.uploadInventoryInfo && this.state.uploadInventoryInfo.recordsTotalCount && this.state.uploadInventoryInfo.recordsErrorCount) {
            successNum = this.state.uploadInventoryInfo.recordsTotalCount - this.state.uploadInventoryInfo.recordsErrorCount
        }

        return (
            <>
                <FlexColumn style={{ minHeight: "80%", margin: "5px 20px 35px 20px" }}>
                    <Text heading>Upload Inventory</Text>
                    <Text margin='0 0 12px'>Import your inventory using our standard template</Text>

                    <TextButton rightIcon={<Image src={Download} />} onClick={() => saveAs((this.props.downloadLink || "/Vendor_template.csv"), 'Vendor-template.csv')} text='Download CSV Template' />

                    <div style={{ display: "flex", flexDirection: 'column', alignItems: "center", marginTop: '30px' }} >
                        <Upload disabled={!certifyChecked} {...uploadProps} fileList={this.state.fileList}>
                            <CardButton className={certifyChecked && "selected"}>
                                <img src={darkMode ? DUploadIcon : LUploadIcon} width="25" height="25" style={{ marginRight: "15px", opacity: 0.8 }} />
                                <FlexColumn start>
                                    <CardButtonText>Upload File</CardButtonText>
                                    <Text lightText>Please upload file as per the CSV template above</Text>
                                </FlexColumn>
                            </CardButton>
                        </Upload>
                        <Checkbox style={{ width: 450, marginTop: 4 }} onChange={(e) => this.setState({ certifyChecked: e.target.checked })}>Check this box to certify that all of the inventory records provided in the uploaded file are true and correct as of the date hereof.</Checkbox>
                    </div>
                </FlexColumn>
                <Modal
                    visible={this.state.showErrorModal}
                    footer={null}
                    closable={true}
                    width={750}
                    style={{ top: 80 }}
                    destroyOnClose={true}
                    onCancel={() => {
                        this.setState({ showErrorModal: false, responseErrorMessage: null, uploadInventoryInfo: null })
                    }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <Result
                        uploadTypeTitle="Upload Error"
                        showHeaderErrorTable={showHeaderErrorTable}
                        errors={errors}
                        errorNum={errorNum}
                        successNum={successNum}
                        errorMessage={errorMessage}
                        responseErrorMessage={responseErrorMessage}
                    />
                </Modal>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(UploadInventory))