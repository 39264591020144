import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import { Checkbox } from 'antd'

import { Flex, FlexColumn } from '../../Reusable/Container'
import { LabeledInput } from '../../Reusable/Input'
import { Text } from '../../Reusable/Text'
import { Button, TextButton } from '../../Reusable/Button'
import { ErrorAlert } from '../../Reusable/Alert'
import Banner from '../../Reusable/Banner'
import { apiPOSTReq } from '../../../Utils/api'
import environment from '../../../environment'

import AionIcon from '../../../Images/aion-shadow.png'
import BofAIcon from '../../../Images/bofa-icon.png'
import ChaseIcon from '../../../Images/chase-icon.png'
import SearchBanks from './SearchBanks'
import { getFeaturePerm } from '../../../Utils/util'


class AddTransferModal extends Component {

    constructor(props) {
        super(props)

        this.state = {
            transfer: {
                type: props.transferMethod ? props.transferMethod.type : null,
                name: props.transferMethod ? props.transferMethod.nickName : null,
                accountNumber: props.transferMethod ? props.transferMethod.bankDetail.accountNumber : null,
                routingNumber: props.transferMethod ? props.transferMethod.bankDetail.routingNumber : null,
                addressOnAccount: props.transferMethod ? props.transferMethod.bankDetail.addressOnAccount : null,
                accountType: props.transferMethod ? props.transferMethod.bankDetail.accountType : (props.rail && props.rail === 'Instant') ? 'Checking' : null,
                remittanceInfo: props.transferMethod ? props.transferMethod.remittanceInfo : null,
            },
            nameError: false,
            accountNumberError: false,
            routingNumberError: false,
            addressOnAccountError: false,
            banks: [
                {
                    key: 'aion',
                    name: 'Aion',
                    icon: AionIcon,
                },
                {
                    key: 'bofa',
                    name: 'Bank of America',
                    icon: BofAIcon,
                },
                {
                    key: 'chase',
                    name: 'Chase',
                    icon: ChaseIcon,
                },
            ],
            type: props.rail ? props.rail : props.transferMethod ? props.transferMethod.type : null,
            aionAccount: (props.transferMethod && props.transferMethod.bankDetail.bankName === "Aion Banking (Powered by Cross River Bank)") ? true : false,
            accountId: props.transferMethod ? props.transferMethod.accountId : null,
        }

        this.nameRef = React.createRef()
        this.accountNumberRef = React.createRef()
        this.routingNumberRef = React.createRef()
        this.addressOnAccountRef = React.createRef()
    }

    componentDidMount() {
        console.log('AddTransferModaltransferMethod', this.props.transferMethod)
    }

    handleTextChange = (event) => {
        const { transfer } = this.state
        transfer[event.target.id] = event.target.value
        this.setState({ transfer })
    }

    handleBankSelect = (value, option) => {
        const { transfer, banks } = this.state

        const bank = banks.find(bank => bank.key === value)
        transfer.bankName = bank.name
        transfer.bankIcon = bank.icon

        console.log('handleBankSelect', transfer)

        this.setState({ transfer })
    }

    setType = (type) => {
        const { transfer } = this.state
        transfer.type = type
        // transfer.routingNumber = type === 'Instant' ? "021214891" : null
        transfer.accountType = type === 'Instant' ? 'Checking' : null
        this.setState({ transfer, type })
    }

    setAccountType = (type) => {
        const { transfer } = this.state
        transfer.accountType = type
        this.setState({ transfer })
    }

    setCDType = (checkDeliveryType) => {
        const { transfer } = this.state
        transfer.checkDeliveryType = checkDeliveryType
        this.setState({ transfer, checkDeliveryType })
    }

    getLocation = (address, key) => {
        const { transfer } = this.state
        transfer[key] = address
        this.setState({ transfer })
    }

    handleBankSelect = (bank) => {
        var { transfer } = this.state;
        transfer.bankName = bank.customerName;
        transfer.routingNumber = bank.routingNumber;
        this.setState({ selectedBank: bank, transfer: transfer })
    }

    validate = () => {
        var { transfer, type, nameErrorText } = this.state
        var { transferMethodRequests } = this.props
        var { name, accountNumber, routingNumber, addressOnAccount, accountType, checkDeliveryType } = transfer
        var { nameError, accountNumberError, routingNumberError, addressOnAccountError, typeError, accountTypeError, checkDeliveryTypeError } = this.state

        console.log('AddTransferModal validate', transfer)

        var error = false

        if (!name) {
            nameError = true
            nameErrorText = "Nickname is required"
            this.nameRef.current.focus()
            error = true
        } else {
            nameError = false
        }

        if (transferMethodRequests && !this.props.transferMethod && transferMethodRequests.find(request => request.nickName === name)) {
            nameError = true
            nameErrorText = "Nickname must be unique"
            this.nameRef.current.focus()
            error = true
        } else {
            nameError = false
        }

        if (!type) {
            typeError = true
            if (!error) {
                error = true
            }
        } else {
            typeError = false
        }

        if (type === 'Check' && !checkDeliveryType) {
            checkDeliveryTypeError = true
            error = true
        } else {
            checkDeliveryTypeError = false
        }
        if ((type === 'Wire' || type === 'Check') && (!addressOnAccount || !addressOnAccount.line1 || addressOnAccount.line1 === '' || !addressOnAccount.city ||
            addressOnAccount.city === '' || !addressOnAccount.state || addressOnAccount.state === '' || !addressOnAccount.postalCode || addressOnAccount.postalCode === '')) {
            addressOnAccountError = true
            if (type === 'Check' && checkDeliveryType === 'Email') {
                addressOnAccountError = false
            } else {
                if (!error && this.accountNumberRef.current) {
                    // this.addressOnAccountRef.current.focus()
                    error = true
                }
            }
        } else {
            addressOnAccountError = false
        }

        if ((type !== 'Check') && !accountType) {
            accountTypeError = true
            if (!error) {
                error = true
            }
        } else {
            accountTypeError = false
        }

        if ((type !== 'Check') && (!accountNumber || isNaN(accountNumber))) {
            accountNumberError = true
            if (!error) {
                this.accountNumberRef.current.focus()
                error = true
            }
        } else {
            accountNumberError = false
        }

        if ((type !== 'Check') && (!routingNumber || isNaN(routingNumber) || routingNumber.length != 9)) {
            routingNumberError = true
            if (!error) {
                this.routingNumberRef.current.focus()
                error = true
            }
        } else {
            routingNumberError = false
        }

        if (error === true) {
            this.setState({ nameError, accountNumberError, routingNumberError, addressOnAccountError, typeError, accountTypeError, checkDeliveryTypeError, nameErrorText })
            return
        } else {
            this.addTransfer()
        }
    }

    addTransfer = async () => {
        const { recipientId, transferMethod, addTransfer } = this.props
        const { type, transfer, accountId } = this.state
        var { name, accountNumber, routingNumber, addressOnAccount, accountType, bankAddress, checkDeliveryType, remittanceInfo } = transfer

        var body = {
            recipientId: recipientId,
            accountId: accountId || accountNumber || "",
            recipientDeliveryMode: type,
            nickName: name,
            type: type,
            bankDetail: {
                bankName: transfer.bankName,
                // accountName: accountNumber,
                accountNumber: accountNumber,
                routingNumber: routingNumber,
                accountType: accountType,
                type: type,
                // bankAddress: bankAddress,
                addressOnAccount: addressOnAccount,
            },
            remittanceInfo: remittanceInfo,
        }

        if (type == "Check") body.bankDetail.checkDeliveryType = checkDeliveryType

        const logo = await this.getInstitutionDetails()

        if (type == "Instant") {
            const rtpInstResp = await this.getRTPInstitution()

            if (!rtpInstResp.rtpSupportedInstitution) {
                this.setState({ loading: false })
                ErrorAlert({ description: rtpInstResp.errorMsg || "This institution does not support instant transfers" })
                return
            }
        }

        body.bankDetail.logoUrl = logo

        if (addTransfer) {
            if (transferMethod) body.paymentMethodId = transferMethod.id

            console.log("bb/addTransferDelivery body", body)
            this.setState({ loading: true })
            apiPOSTReq(`${environment.bbBaseUrl}/bb/addTransferDelivery`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
                this.setState({ loading: false })
                try {
                    // if (err) throw Error(err)
                    const data = resp || {}
                    if (data.result) {
                        console.log("bb/addTransferDelivery", data)
                        this.props.onConfirm()
                    } else {
                        throw Error(data.responseMessage || data.error)
                    }
                } catch (error) {
                    console.log("/addTransferDelivery err", error, resp, body)
                    ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
                }
            })
        } else {
            this.props.onConfirm(body, transferMethod)
        }
    }

    getInstitutionDetails = async () => {
        const { transfer } = this.state
        var { routingNumber } = transfer

        return new Promise((resolve) => {
            const body = { routingNumber };
            this.setState({ loading: true })
            apiPOSTReq(`${environment.integrationBaseUrl}/integration/plaid/getInstitutionDetails`, {}, body, (err, resp) => {
                try {
                    const data = resp;
                    if (data.result && data.institution) {
                        resolve(data.institution.logo);
                    } else {
                        throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later");
                    }
                } catch (error) {
                    console.log("ERRR getInstitutionDetails", error.stack, resp);
                    // ErrorAlert({ description: error.message });
                    resolve(null); // resolve with null in case of error
                }
            });
        });
    }

    getRTPInstitution = async () => {
        const { transfer } = this.state
        var { routingNumber } = transfer
        var body = {
            routingNumber: routingNumber
        }
        return new Promise((resolve) => {
            this.setState({ loading: true })
            apiPOSTReq(`${environment.bpBaseUrl}/bp/payments/getRTPDirectory`, {}, body, (err, resp) => {
                try {
                    console.log("/bp/payments/getRTPDirectory", err, resp)
                    const data = resp || {}
                    if (data.results) {
                        resolve({ rtpSupportedInstitution: (data.results.length > 0), rtpInstitution: data.results[0] })
                    } else {
                        throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                    }
                } catch (error) {
                    console.log("ERRR getRTPDirectory", error, err, resp)
                    ErrorAlert({ description: error.message })
                    resolve({ errorMsg: error.message })
                }
            })
        })
    }

    render() {
        var { loading, dropdownVisible, transfer, banks, type, aionAccount } = this.state
        var { name, accountNumber, routingNumber, addressOnAccount, bank, bankAddress, accountType, checkDeliveryType, remittanceInfo } = transfer
        const { visible, onDiscard, recipientEmail, transferMethod, rail, addTransfer } = this.props

        var { nameError, accountNumberError, routingNumberError, addressOnAccountError, typeError, accountTypeError, checkDeliveryTypeError, nameErrorText } = this.state
        var checkPaymentsEnabled = getFeaturePerm("BusinessBanking.Payments.CheckPayment")
        var instantPaymentsEnabled = getFeaturePerm("BusinessBanking.Payments.Instant Transfer")

        var rails = ['ACH', 'Wire']
        if (checkPaymentsEnabled.manage) rails.push('Check')
        if (instantPaymentsEnabled.manage) rails.unshift('Instant')

        var rInfoLength = 160
        if(type == "ACH") rInfoLength = 80
        return (
            <FlexColumn start style={{ width: 616 }}>
                <FlexColumn start>
                    <Text margin='0 0 40px' size='24px' weight={500} color='#444444'>{transferMethod ? 'Edit' : 'New'} Transfer Method</Text>

                    <Flex start gap='24px'>
                        <LabeledInput
                            key="name"
                            inputRef={this.nameRef}
                            label='Nickname'
                            id="name"
                            // type={(addTransfer && transferMethod) && 'read-only'}
                            placeholder="Enter nickname for this transfer method"
                            value={name && name}
                            autoFocus
                            nomargin
                            maxLength={50}
                            onChange={this.handleTextChange}
                            error={nameError}
                            errorMessage={nameErrorText}
                            width={transferMethod ? '270px' : '568px'}
                        />

                        {
                            transferMethod &&
                            <LabeledInput
                                label='Type'
                                type='read-only'
                                value={type}
                                nomargin
                            />
                        }
                    </Flex>

                    {
                        !transferMethod && !rail &&
                        <LabeledInput
                            label='Type'
                            type='switch'
                            switchNames={rails}
                            onChange={(value) => this.setType(value)}
                            value={type}
                            error={typeError}
                            errorMessage='Type is required'
                        />
                    }

                    {
                        type === "Check" &&
                        <LabeledInput
                            label='Check delivery type'
                            type='switch'
                            switchNames={["Email", "Postal Mail"]}
                            onChange={(value) => this.setCDType(value)}
                            value={checkDeliveryType}
                            error={checkDeliveryTypeError}
                            errorMessage='Check delivery type is required'
                        />
                    }

                    {type &&
                        <>
                            {
                                (type != "Check") &&
                                <Checkbox style={{ marginTop: 30, width: 300 }} checked={aionAccount} onChange={(e) => {
                                    transfer.routingNumber = e.target.checked ? "021214891" : null
                                    transfer.bankName = e.target.checked ? "Aion Banking (Powered by Cross River Bank)" : null
                                    transfer.accountType = e.target.checked ? 'Checking' : null
                                    this.setState({ aionAccount: e.target.checked, transfer })
                                }}>Recipient has their account at Aion</Checkbox>
                            }

                            {
                                type === "Wire" && <>

                                    {
                                        !aionAccount &&
                                        <SearchBanks
                                            handleSelect={bank => this.handleBankSelect(bank)}
                                            width='568px'
                                        />
                                    }
                                </>
                            }

                            {
                                (aionAccount) ?
                                    <LabeledInput
                                        type='read-only'
                                        label="Routing Number"
                                        value={routingNumber && routingNumber}
                                        width='282px'
                                    />
                                    :
                                    <>
                                        {
                                            (type != "Check") &&
                                            < LabeledInput
                                                inputRef={this.routingNumberRef}
                                                label="Routing Number"
                                                id="routingNumber"
                                                key="routingNumber"
                                                placeholder="Enter up to 9 digits"
                                                value={routingNumber && routingNumber}
                                                maxLength={9}
                                                onChange={this.handleTextChange}
                                                error={routingNumberError}
                                                errorMessage="A valid routing number is required"
                                                disabled={aionAccount}
                                                width='568px'
                                            />
                                        }
                                    </>
                            }

                            {
                                (type != "Check") &&
                                <FlexColumn start gap='0'>
                                    <LabeledInput
                                        inputRef={this.accountNumberRef}
                                        label="Account Number"
                                        id="accountNumber"
                                        key="accountNumber"
                                        placeholder="Enter up to 17 digits"
                                        value={accountNumber && accountNumber}
                                        maxLength={17}
                                        onChange={this.handleTextChange}
                                        error={accountNumberError}
                                        errorMessage="A valid account number is required"
                                        width='568px'
                                    />

                                    {
                                        (!aionAccount) &&
                                        <LabeledInput
                                            label='Account Type'
                                            type='switch'
                                            switchNames={['Checking', 'Savings']}
                                            onChange={(value) => this.setAccountType(value)}
                                            value={accountType}
                                            error={accountTypeError}
                                            errorMessage='Account type is required'
                                        />
                                    }
                                </FlexColumn>
                            }

                            {
                                (type === 'Wire' || (type === 'Check' && checkDeliveryType == 'Postal Mail')) &&
                                <LabeledInput
                                    inputRef={this.addressOnAccountRef}
                                    label={(type === "Check") ? 'Recipient mailing address' : 'Recipient address at their bank'}
                                    id="addressOnAccount"
                                    key="addressOnAccount"
                                    placeholder="Enter Address"
                                    address={addressOnAccount}
                                    autoFocus
                                    type="location"
                                    getLocation={(loc) => this.getLocation(loc, 'addressOnAccount')}
                                    error={addressOnAccountError}
                                    errorMessage="Please enter a valid recipient address"
                                    width='568px'
                                />
                            }

                            {
                                <LabeledInput
                                    label="Remittance Information"
                                    id="remittanceInfo"
                                    type="text-area"
                                    placeholder="Save any remittance info to submit along with a transfer"
                                    maxLength={rInfoLength}
                                    width='576px'
                                    optional
                                    value={remittanceInfo}
                                    autoSize={{ minRows: 1, maxRows: 2 }}
                                    onChange={this.handleTextChange}
                                    instruction={remittanceInfo ? `${remittanceInfo.length}/${rInfoLength}` : `0/${rInfoLength}`}
                                />
                            }

                            {
                                checkDeliveryType == "Email" &&
                                <Text defaultLightText>Check will be emailed to <b>{recipientEmail}</b></Text>
                            }

                            {
                                (type === "Instant") &&
                                <div style={{ width: '550px' }}>
                                    <Banner
                                        message="Instant transfers generally complete in less than 30 seconds. These transfers are irrevocable so please be sure to double check your recipient details before submitting your transfer"
                                    />
                                </div>
                            }
                        </>
                    }
                </FlexColumn>

                <Flex style={{ marginTop: 40 }} start>
                    <Button primary solid loading={loading} onClick={() => this.validate()} text="CONFIRM" />
                </Flex>
            </FlexColumn>
        )
    }
}


function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(AddTransferModal)))