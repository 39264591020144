import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import { AppImgContainer, CardContainer } from '../Reusable/CardContainer'
import { Flex, FlexColumn } from '../../Reusable/Container'
import { Image } from '../../Reusable/Image'

import BnkAppIcon from '../../../Images/product-menu/bank-menu-icon-active.png'
import CardsAppIcon from '../../../Images/product-menu/CardsActive.png'
import RcvAppIcon from '../../../Images/product-menu/receivables-menu-icon-active.png'
import PayAppIcon from '../../../Images/product-menu/payables-menu-icon-active.png'
import CrdAppIcon from '../../../Images/product-menu/credit-menu-icon-active.png'
import ApprAppIcon from '../../../Images/product-menu/approvals-menu-icon-active.png'
import SAppIcon from '../../../Images/product-menu/settings-menu-icon.png'
import { Text } from '../../Reusable/Text'


class UserWelcome extends Component {
    state = {
    }

    componentDidMount() {
        
    }

    render() {
        var appList = [{
            name: "Banking",
            imgSrc: BnkAppIcon,
            path: "banking/home"
        },{
            name: "Cards",
            imgSrc: CardsAppIcon,
            path: "cards/all"
        },{
            name: "Receivables",
            imgSrc: RcvAppIcon,
            path: "receivables/invoices"
        },{
            name: "Bill Payments",
            imgSrc: PayAppIcon,
            path: "payables/bills"
        },{
            name: "Credit",
            imgSrc: CrdAppIcon,
            path: "credit/ar-credit/advances"
        },{
            name: "Approvals",
            imgSrc: ApprAppIcon,
            path: "approvals/pending"
        }
        // ,{
        //     name: "Settings",
        //     imgSrc: SAppIcon,
        //     path: "settings/profile"
        // }
    ]

        const appSize = 70

        return (
            <CardContainer small style={{ padding: '30px 40px' }}>
                <Text weight={600} size='24px' color={this.props.theme.colors.primary2}>
                    Your Apps
                </Text>
                <Flex start gap='32px' style={{ paddingTop: '40px' }} wrap>
                    {
                        appList.map(app => (
                            <FlexColumn center gap='4px' style={{ width: appSize+15 }}>
                                <AppImgContainer center onClick={() => this.props.history.push(app.path)} style={{ width: appSize, height: appSize }}>
                                    <Image height='28px' width='28px' src={app.imgSrc} />
                                </AppImgContainer>
                                <Text light size='12px'>{app.name}</Text>
                            </FlexColumn>
                            
                        ))
                    }
                    
                </Flex>
            </CardContainer>
        )
    }
}

export default withRouter((withTheme(UserWelcome)))