import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import moment from 'moment'
import { saveAs } from 'file-saver'
import { Modal, Drawer, Select, message } from 'antd'

import _ from 'lodash'
import { Text, Tag } from '../../Reusable/Text'
import { Image } from '../../Reusable/Image';
import { Flex, FlexColumn } from '../../Reusable/Container'
import { Button, ImageButton } from '../../Reusable/Button'
import { LabeledInput } from '../../Reusable/Input'
import { ErrorAlert } from '../../Reusable/Alert'
import AlertModal from '../../Reusable/AlertModal'
import DeleteModal from '../../Reusable/DeleteModal'
import { getUserApps, toCurrency } from '../../../Utils/util'
import { apiPOSTReq, getPreSignedS3Url } from '../../../Utils/api'
import environment from '../../../environment'

import Delete from '../../../Images/delete-red-icon.png'
import ModalClose from '../../../Images/modal-close.png'

const { Option } = Select

const dateFormat = 'MMM D, YYYY'

class DocumentDrawer extends Component {

    constructor(props) {
        super(props)

        var ABLUser = false

        const userApps = getUserApps(this.props.aionStore)

        if (userApps && userApps.includes("ABLCredit")) {
            ABLUser = true
        }

        this.state = {
            documentsLoading: true,
            msasLoading: true,
            showMSAModal: false,
            pagination: {
                current: 1,
                pageSize: 100,
                showSizeChanger: false,
            },
            sorter: {},
            ABLUser: ABLUser,
            dashboardLoading: true,
            loading: false,
            selectedTab: 'details',
            transferMethods: [],
            fileList: [],
            name: props.msa ? props.msa.name : null,
            // contractValue: props.msa ? props.msa.contractValue : null,
            documentTypes: props.addPO ? ["Purchase Order", "Work Order", "Statement of Work"] : ["Master Service Agreement", "Master Supply Agreement",
                "Purchase Order", "Work Order", "Statement of Work",
                "Service-level Agreement", "Non-disclosure Agreement", "Confidentiality Agreement", "Other"],
            view: props.view,
        }

        this.customer = this.props.location.state && this.props.location.state.customer

        this.uploadRef = React.createRef()
        this.nameRef = React.createRef()
        this.typeRef = React.createRef()
        this.docNumberRef = React.createRef()
        this.msaRef = React.createRef()
        this.fromRef = React.createRef()
        this.toRef = React.createRef()
        this.contractValueRef = React.createRef()
    }

    componentDidUpdate(prevProps) {
        console.log("DocumentDrawer msa", this.props.po)
        var contractValue

        if (JSON.stringify(prevProps.msa) !== JSON.stringify(this.props.msa)) {
            var documentUrl = this.props.msa ? this.props.msa.documentUrl : null

            if (documentUrl) {
                documentUrl.uid = 0
                documentUrl.name = documentUrl.fileName
                documentUrl.url = documentUrl.uri
            }

            if (this.props.msa && this.props.msa.contractValue) contractValue = parseFloat(this.props.msa.contractValue).toFixed(2);

            this.setState({
                id: this.props.msa ? this.props.msa.id : null,
                name: this.props.msa ? this.props.msa.name : null,
                contractValue: contractValue ? contractValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : null,
                notes: this.props.msa ? this.props.msa.notes : null,
                StartDate: this.props.msa ? moment(this.props.msa.validFrom) : null,
                EndDate: this.props.msa ? moment(this.props.msa.validThrough) : null,
                fileList: documentUrl ? [documentUrl] : [],
                FileName: documentUrl && documentUrl.fileName,
                URI: documentUrl && documentUrl.uri,
            });
        }

        if (JSON.stringify(prevProps.po) !== JSON.stringify(this.props.po)) {
            var documentUrl = this.props.po ? this.props.po.documentUrl : null

            if (documentUrl) {
                documentUrl.uid = 0
                documentUrl.name = documentUrl.fileName
                documentUrl.url = documentUrl.uri
            }

            if (this.props.po && this.props.po.totalValue) contractValue = parseFloat(this.props.po.totalValue).toFixed(2);

            this.setState({
                id: this.props.po ? this.props.po.id : null,
                name: this.props.po ? this.props.po.name : null,
                woNumber: this.props.po ? this.props.po.ponumber : null,
                contractValue: contractValue ? contractValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : null,
                masterServiceAgreementId: this.props.po ? this.props.po.msaid : null,
                StartDate: this.props.po ? moment(this.props.po.validFrom) : null,
                EndDate: this.props.po ? moment(this.props.po.validThrough) : null,
                fileList: documentUrl ? [documentUrl] : [],
                FileName: documentUrl && documentUrl.fileName,
                URI: documentUrl && documentUrl.uri,
            });
        }

        if (JSON.stringify(prevProps.document) !== JSON.stringify(this.props.document)) {
            var documentUrl = this.props.document ? this.props.document.url : null

            if (documentUrl) {
                documentUrl.uid = 0
                documentUrl.name = documentUrl.fileName
                documentUrl.url = documentUrl.uri
            }

            this.setState({
                id: this.props.document ? this.props.document.id : null,
                name: this.props.document ? this.props.document.name : null,
                woNumber: this.props.document ? this.props.document.documentNumber : null,
                contractValue: this.props.document ? this.props.document.contractValue : null,
                masterServiceAgreementId: this.props.document ? this.props.document.linkedMSAId : null,
                StartDate: this.props.document ? moment(this.props.document.validFrom) : null,
                EndDate: this.props.document ? moment(this.props.document.validThrough) : null,
                fileList: documentUrl ? [documentUrl] : [],
                FileName: documentUrl && documentUrl.fileName,
                URI: documentUrl && documentUrl.uri,
                documentType: this.props.document ? this.props.document.subType : null,
            });
        }

        if (prevProps.view !== this.props.view) {
            this.setState({ view: this.props.view })
        }
    }

    cacheFileData = data => {
        this.setState(data)
    }

    uploadProps = () => {
        return {
            name: `customerUpload`,
            multiple: false,
            action: `${environment.apiBaseUrl}/financing/upload`,
            headers: {
                businesskey: this.props.aionStore.BusinessUniqueKey,
                jwt: this.props.aionStore.jwt
            },
            beforeUpload: () => this.setState({ defaultFileList: null }),
            onChange: (info) => {
                const { fileList, event } = info
                const { status, response, name } = info.file
                if (status === 'done') {
                    this.cacheFileData({
                        FileName: name,
                        URI: (response.SavedDocumentUrls || []).slice(-1).pop()
                    })
                    message.success(`${info.file.name} file uploaded successfully.`, 0.75)
                } else if (status === 'error') {
                    message.error(`${info.file.name} file upload failed.`)
                }

                this.setState({ fileList: fileList });
            },
            onRemove: (file) => {
                this.cacheFileData({
                    FileName: "",
                    URI: ""
                })
            },
            onPreview: (file) => {
                getPreSignedS3Url({ url: file.uri }, (err, preSignedUrl) => saveAs(preSignedUrl, file.name))
            },
        }
    }

    submitMSA = () => {
        const { aionStore, store, customerId, loanApp, msa } = this.props;
        const { EndDate, StartDate, contractValue, FileName, URI, documentType, name, notes, id } = this.state;

        console.log("DocumentDrawer ive/msa contractValue", contractValue)

        if (!msa && !FileName) {
            this.setState({ errorField: 'upload', errorMessage: 'Please upload a supporting document' })
            this.uploadRef.current.scrollIntoView({ behavior: 'smooth' });
            return
        }
        if (!StartDate) {
            this.setState({ errorField: 'StartDate', errorMessage: 'This is a mandatory field' })
            if (this.fromRef.current) this.fromRef.current.focus()
            return;
        }
        if (!EndDate) {
            this.setState({ errorField: 'EndDate', errorMessage: 'This is a mandatory field' })
            if (this.toRef.current) this.toRef.current.focus()
            return;
        }
        if (moment(EndDate).diff(moment(StartDate)) < 0) {
            this.setState({ errorField: 'EndDate', errorMessage: 'Please provide a valid to date' })
            if (this.toRef.current) this.fromRef.current.focus()
            return;
        }
        // if (contractValue && isNaN(contractValue.replace(',', ''))) {
        //     this.setState({ errorField: 'contractValue', errorMessage: 'Please provide a valid contract value' });
        //     if (this.contractValueRef.current) this.contractValueRef.current.focus();
        //     return
        // }
        this.setState({ showError: false, addLoading: true });
        const headers = {
            AionCurrentBiz: aionStore.BusinessUniqueKey,
            AionAuth: aionStore.UAM.encryptedAuthHeader // This will be replaced with the token received from the access mgmt service
        }
        var body = {
            msa: {
                "BusinessId": aionStore.BusinessUniqueKey,
                "CustomerId": customerId,
                "EndDate": EndDate,
                "validThrough": EndDate,
                "validFrom": StartDate,
                "contractValue": typeof contractValue === 'string' ? contractValue.replace(',', '') : contractValue,
                "DocumentURL": {
                    "FileName": FileName,
                    "URI": URI,
                    "ObjectType": "MSA"
                },
                name: name,
                type: documentType,
                notes: notes,
            }
        }
        if (id) body.msa.id = id;
        if (loanApp) body.msa.applicationId = loanApp.applicationId;
        if (msa) {
            body.msaObj = body.msa;
            body.msaObj.validFrom = moment(StartDate).toDate();
            body.msaObj.validThrough = moment(EndDate).toDate();
        }
        console.log("DocumentDrawer ive/msa body", body)
        apiPOSTReq(`${environment.iveBaseUrl}/ive/msa/${msa ? 'save' : 'validate'}`, headers, body, (err, resp) => {
            try {
                const data = resp;
                console.log("DocumentDrawer ive/msa resp", resp)
                if (data.result) {
                    this.setState({
                        EndDate: null,
                        StartDate: null,
                        contractValue: null,
                        FileName: null,
                        URI: null,
                        fileList: [],
                        name: null,
                        documentType: null,
                        addLoading: false,
                        showDelete: false,
                        errorField: null,
                    });
                    message.success('MSA added successfully!');
                    this.props.onSubmit(); // Closes modal and refresh
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("ERRR", error, err, resp)
                ErrorAlert({ description: error.message || "Error adding MSA." });
            } finally {
                this.setState({ addLoading: false });
            }
        })
    }

    submitWO = () => {
        const { EndDate, StartDate, contractValue, FileName, URI, woNumber, masterServiceAgreementId, documentType, name, notes, id } = this.state;
        const msa = (this.props.msas || []).find(x => x.masterServiceAgreementId === masterServiceAgreementId);
        let msaName = msa ? msa.documentUrl.fileName : '';
        var { aionStore, customerId, customer, loanApp, po } = this.props;
        customer = customer || {};
        if (!po && !FileName) {
            this.setState({ errorField: 'upload', errorMessage: 'Please upload a supporting document' });
            this.uploadRef.current.scrollIntoView({ behavior: 'smooth' });
            return
        }
        if (!woNumber) {
            this.setState({ errorField: 'woNumber', errorMessage: 'This is a mandatory field' });
            if (this.docNumberRef.current) this.docNumberRef.current.focus();
            return;
        }
        // if (!msaName) {
        //     this.setState({ errorField: 'msaName', errorMessage: 'Please select an MSA' });
        //     if (this.msaRef.current) this.msaRef.current.focus();
        //     return;
        // }
        if (!StartDate) {
            this.setState({ errorField: 'StartDate', errorMessage: 'This is a mandatory field' });
            if (this.fromRef.current) this.fromRef.current.focus();
            return;
        }
        if (!EndDate) {
            this.setState({ errorField: 'EndDate', errorMessage: 'This is a mandatory field' });
            if (this.toRef.current) this.toRef.current.focus();
            return;
        }
        if (moment(EndDate).diff(moment(StartDate)) < 0) {
            this.setState({ errorField: 'EndDate', errorMessage: 'Please provide a valid to date' });
            if (this.fromRef.current) this.fromRef.current.focus();
            return;
        }
        if (!contractValue) {
            this.setState({ errorField: 'contractValue', errorMessage: 'This is a mandatory field' });
            if (this.contractValueRef.current) this.contractValueRef.current.focus();
            return
        }
        // if (isNaN(contractValue.replace(',', ''))) {
        //     this.setState({ errorField: 'contractValue', errorMessage: 'Please provide a valid contract value' });
        //     if (this.contractValueRef.current) this.contractValueRef.current.focus();
        //     return
        // }
        this.setState({ showError: false, addLoading: true });
        const headers = {
            AionCurrentBiz: aionStore.BusinessUniqueKey,
            AionAuth: aionStore.UAM.encryptedAuthHeader // This will be replaced with the token received from the access mgmt service
        }
        var body = {
            po: {
                "businessId": aionStore.BusinessUniqueKey,
                "ponumber": woNumber,
                "customerId": customerId,
                "validFrom": moment(StartDate).toDate(),
                "validThrough": moment(EndDate).toDate(),
                "dueDate": moment(EndDate).toDate(),
                "documentURL": {
                    "fileName": FileName,
                    "uri": URI,
                    "objectType": "MSA",
                },
                "customerName": customer.displayName,
                "msaId": masterServiceAgreementId,
                "totalValue": typeof contractValue === 'string' ? contractValue.replace(',', '') : contractValue,
                "msaName": msaName,
                // "rootCustomerId": customer.rootCustomerId,
                // "rootCustomerName": customer.rootCustomerName
                name: name,
                type: documentType,
                notes: notes,
            }
        }
        if (id) body.po.id = id;
        if (loanApp) body.po.applicationId = loanApp.applicationId;
        console.log("submitWO", body);
        console.log("submitWO msas", this.props.msas);
        console.log("DocumentDrawer ive/po body", body)
        apiPOSTReq(`${environment.iveBaseUrl}/ive/po/save`, headers, body, (err, resp) => {
            try {
                console.log("/validate", err, resp);
                const data = resp || {};
                if (!data.error) {
                    this.setState({
                        EndDate: null,
                        StartDate: null,
                        FileName: null,
                        URI: null,
                        totalValue: null,
                        woNumber: null,
                        masterServiceAgreementId: null,
                        fileList: [],
                        name: null,
                        documentType: null,
                        addLoading: false,
                        showDelete: false,
                        errorField: null,
                    });
                    message.success('Document added successfully!');
                    this.props.onSubmit(); // Closes modal and refresh
                } else {
                    throw Error(data.error || "Could not complete submission.");
                }
            } catch (error) {
                alert(error.message || "Sorry, we had trouble processing your request. Please try again.");
            }
        })
    }

    submitDocument = () => {
        var { aionStore, customerId, customer, document } = this.props;
        var { documentType, name, notes, URI, EndDate, StartDate, masterServiceAgreementId, woNumber, contractValue, FileName, id } = this.state;

        if (!document && !FileName) {
            this.setState({ errorField: 'upload', errorMessage: 'Please upload a supporting document' })
            this.uploadRef.current.scrollIntoView({ behavior: 'smooth' });
            return
        }

        const headers = {
            AionCurrentBiz: aionStore.BusinessUniqueKey,
            AionAuth: aionStore.UAM.encryptedAuthHeader // This will be replaced with the token received from the access mgmt service
        }

        const body = {
            receivablesCustomerDocuments: {
                customerId: customerId,
                customerName: customer.displayName,
                name: name,
                type: "Other",
                subType: documentType,
                notes: notes,
                url: {
                    FileName,
                    URI,
                    ObjectType: "Other",
                },
                validFrom: StartDate && moment(StartDate).toDate(),
                validThrough: EndDate && moment(EndDate).toDate(),
                linkedMSAId: masterServiceAgreementId,
                documentNumber: woNumber,
                contractValue,
            }
        }
        if (id) body.receivablesCustomerDocuments.id = id;

        console.log("DocumentDrawer ive/document/save body", body);

        apiPOSTReq(`${environment.iveBaseUrl}/ive/document/save`, headers, body, (err, resp) => {
            try {
                console.log("/validate", err, resp);
                const data = resp || {};
                if (!data.error) {
                    console.log("DocumentDrawer data", data);
                    this.setState({
                        EndDate: null,
                        StartDate: null,
                        FileName: null,
                        URI: null,
                        totalValue: null,
                        woNumber: null,
                        masterServiceAgreementId: null,
                        fileList: [],
                        name: null,
                        documentType: null,
                        addLoading: false,
                        showDelete: false,
                        errorField: null,
                    });
                    message.success('Document added successfully!');
                    this.props.onSubmit(); // Closes modal and refresh
                } else {
                    throw Error(data.error || "Could not complete submission.");
                }
            } catch (error) {
                alert(error.message || "Sorry, we had trouble processing your request. Please try again.");
            }
        })
    }

    addDocument = () => {
        const { msa, po, document } = this.props;
        const { name, documentType } = this.state;

        // if (!name) {
        //     this.setState({ errorField: 'name' })
        //     this.nameRef.current.focus()
        //     return;
        // }

        if (!msa && !po && !document && !documentType) {
            this.setState({ errorField: 'documentType' })
            this.typeRef.current.focus()
            return;
        }

        if (msa) {
            this.submitMSA();
            return;
        } else if (po) {
            this.submitWO();
            return;
        } else if (document) {
            this.submitDocument();
            return;
        }

        switch (documentType) {
            case "Master Service Agreement":
            case "Master Supply Agreement":
                this.submitMSA();
                break;
            case "Purchase Order":
            case "Work Order":
            case "Statement of Work":
                this.submitWO();
                break;
            default:
                this.submitDocument();
                break;
        }
    }

    handleDeleteMSA = (msaId) => {
        const { onSubmit, onClose, customerId } = this.props;
        const body = {
            "BusinessId": this.props.aionStore.BusinessUniqueKey,
            "CustomerId": customerId,
            "msaId": msaId,
        }
        apiPOSTReq(`${environment.iveBaseUrl}/ive/msa/delete`, this.iveHeaders, body, (err, resp) => {
            try {
                const data = resp;
                if (data.result) {
                    onClose()
                    onSubmit()
                    this.setState({ addLoading: false, showDelete: false })
                } else {
                    console.log(data);
                    if (data.responseMessage) {
                        message.error(data.responseMessage);
                    }
                    else {
                        throw Error("Could not delete MSA.")
                    }
                }
            } catch (error) {
                console.log("ERRR", error.stack)
                message.error("Could not delete MSA.");
            } finally {
                this.setState({ deleteLoading: false })
            }
        })
    }

    handleDeleteWO = (PONumber) => {
        const { onSubmit, onClose, customerId } = this.props;
        const body = {
            "BusinessId": this.props.aionStore.BusinessUniqueKey,
            "CustomerId": customerId,
            "PONumber": PONumber
        }
        console.log("handleDeleteWO", body)
        apiPOSTReq(`${environment.iveBaseUrl}/ive/po/delete`, this.iveHeaders, body, (err, resp) => {
            try {
                const data = resp;
                if (data.result) {
                    onClose()
                    onSubmit()
                    this.setState({ addLoading: false, showDelete: false })
                } else {
                    throw Error("Could not delete Work Order.")
                }
            } catch (error) {
                console.log("ERRR", error, err, resp.error)
                message.error("Could not delete Work Order.");
            } finally {
                this.setState({ deleteLoading: false })
            }
        })
    }

    handleDeleteDocument = (document) => {
        const { onSubmit, onClose, customerId } = this.props;
        const body = {
            "BusinessId": this.props.aionStore.BusinessUniqueKey,
            "receivablesCustomerDocuments": document
        }
        console.log("handleDeleteDocument", body)
        apiPOSTReq(`${environment.iveBaseUrl}/ive/document/delete`, this.iveHeaders, body, (err, resp) => {
            try {
                const data = resp;
                if (data.result) {
                    onClose()
                    onSubmit()
                    this.setState({ addLoading: false, showDelete: false })
                } else {
                    throw Error("Could not delete document")
                }
            } catch (error) {
                console.log("ERRR", error, err, resp.error)
                message.error("Could not delete document.");
            } finally {
                this.setState({ deleteLoading: false })
            }
        })
    }

    render() {
        const { visible, onClose, msas, msa, po, document } = this.props;
        var { name, errorField, documentType, EndDate, StartDate, contractValue, woNumber, showDelete, addLoading, notes, fileList,
            id, deleteLoading, masterServiceAgreementId, documentTypes, errorMessage, view, showExitModal } = this.state

        return (
            <>
                <Drawer
                    visible={visible}
                    placement="right"
                    closable={true}
                    onClose={() => {
                        this.setState({ addLoading: false, showDelete: false, showExitModal: true })
                    }}
                    mask={!showExitModal && !showDelete}
                    maskClosable={false}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                    width='444px'
                >
                    <FlexColumn start gap="24px" fullHeight>
                        <Text heading>{view ? 'View document' : (msa || po || document) ? 'Edit document' : 'Add new document'}</Text>

                        {/* <LabeledInput
                            inputRef={this.nameRef}
                            nomargin
                            label="Name"
                            placeholder="Enter the name of the document"
                            onChange={(event) => this.setState({ name: event.target.value })}
                            value={name}
                            error={errorField === "name"}
                            errorMessage={"This is a mandatory field"}
                        /> */}

                        {
                            (!msa && !po && !document) &&
                            <LabeledInput
                                inputRef={this.typeRef}
                                nomargin
                                label="Document Type"
                                type={view ? 'read-only' : "select"}
                                className="no-left-padding"
                                placeholder="Select"
                                value={documentType}
                                onChange={(val) => {
                                    this.contractValueRef = React.createRef()
                                    this.setState({ documentType: val })
                                }}
                                error={errorField === "documentType"}
                                errorMessage={"This is a mandatory field"}
                            >
                                {documentTypes.map(item => (
                                    <Option key={item} id="documentType" name="documentType" value={item}>
                                        {item}
                                    </Option>
                                ))}
                            </LabeledInput>
                        }

                        {
                            (po || ["Purchase Order", "Work Order", "Statement of Work"].includes(documentType)) &&
                            <>
                                <LabeledInput
                                    inputRef={this.docNumberRef}
                                    nomargin
                                    label="Document ID"
                                    type={view && 'read-only'}
                                    id="woNumber"
                                    key="woNumber"
                                    placeholder="Enter document ID"
                                    onChange={(event) => this.setState({ woNumber: event.target.value })}
                                    value={woNumber}
                                    error={errorField === "woNumber"}
                                    errorMessage={"This is a mandatory field"}
                                />

                                {/* <LabeledInput
                                    inputRef={this.msaRef}
                                    nomargin
                                    label="Please select an MSA"
                                    id="selectMSA"
                                    key="selectMSA"
                                    type="select"
                                    className="no-left-padding"
                                    placeholder="Select"
                                    value={masterServiceAgreementId}
                                    onChange={(value) => this.setState({ masterServiceAgreementId: value })}
                                    error={errorField === "msaName"}
                                    errorMessage={"Please select an MSA"}
                                    optional
                                >
                                    {msas && msas.map(item => <Option key={item.id} name={item.documentUrl.fileName || ""} value={item.masterServiceAgreementId} style={{ backgroundColor: "transparent" }}>{item.documentUrl.fileName}</Option>)}
                                </LabeledInput> */}
                            </>
                        }

                        {
                            ((msa) || (po) ||
                                ["Master Service Agreement", "Master Supply Agreement",
                                    "Purchase Order", "Work Order", "Statement of Work"].includes(documentType)) &&
                            <>

                                <LabeledInput
                                    inputRef={this.fromRef}
                                    nomargin
                                    label="Valid From"
                                    id="StartDate"
                                    key="StartDate"
                                    type={view ? 'read-only' : "date-picker"}
                                    placeholder={moment().format(dateFormat)}
                                    value={view ? moment(StartDate).format(dateFormat) : StartDate}
                                    format={dateFormat}
                                    onChange={(date, dateString) => { this.setState({ StartDate: date }) }}
                                    error={errorField === "StartDate"}
                                    errorMessage={"This is a mandatory field"}
                                />

                                <LabeledInput
                                    inputRef={this.toRef}
                                    nomargin
                                    label="Valid To"
                                    id="EndDate"
                                    key="EndDate"
                                    type={view ? 'read-only' : "date-picker"}
                                    placeholder={moment().format(dateFormat)}
                                    value={view ? moment(EndDate).format(dateFormat) : EndDate}
                                    format={dateFormat}
                                    onChange={(date, dateString) => { this.setState({ EndDate: date }) }}
                                    error={errorField === "EndDate"}
                                    errorMessage={"This is a mandatory field"}
                                />

                                <LabeledInput
                                    inputRef={this.contractValueRef}
                                    nomargin
                                    autoFocus
                                    optional={!["Purchase Order", "Work Order", "Statement of Work"].includes(documentType) && !po}
                                    prefixText="$"
                                    type={view ? 'read-only' : "number"}
                                    label="Contract Value"
                                    id="contractValue"
                                    key="contractvalue"
                                    placeholder={"E.g. 100,000"}
                                    min={0}
                                    // precision={2}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    // parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    value={view ? (contractValue || '--') : (contractValue && contractValue)}
                                    onChange={(value) => {
                                        if (value !== 0) this.setState({ contractValue: value })
                                    }}
                                    // onBlur={() => {
                                    //     if (contractValue !== undefined && contractValue !== null && contractValue !== '') {
                                    //         const numericValue = parseFloat(contractValue.replace(',', '')).toFixed(2);
                                    //         this.setState({ contractValue: numericValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') });
                                    //     }
                                    // }}
                                    // onKeyPress={e => {
                                    //     if (e.key === 'Enter' && contractValue !== undefined && contractValue !== null && contractValue !== '') {
                                    //         const numericValue = parseFloat(contractValue.replace(',', '')).toFixed(2);
                                    //         this.setState({ contractValue: numericValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') });
                                    //     }
                                    // }}
                                    error={errorField === "contractValue"}
                                    errorMessage={errorMessage}
                                />
                            </>
                        }

                        {
                            view ?
                                <LabeledInput
                                    nomargin
                                    label="Uploaded Document(s)"
                                    type={'read-only'}
                                    value={fileList.length > 0 && <a onClick={() => {
                                        const file = fileList[fileList.length - 1]
                                        getPreSignedS3Url({ url: file.uri }, (err, preSignedUrl) => saveAs(preSignedUrl, file.name))
                                    }}>{fileList[fileList.length - 1].fileName}</a>}
                                />
                                :
                                <div ref={this.uploadRef}>
                                    <LabeledInput
                                        nomargin
                                        {...this.uploadProps()}
                                        type='upload'
                                        label='Uploaded Document(s)'
                                        fileList={fileList.length > 0 ? [fileList[fileList.length - 1]] : fileList}
                                        error={errorField === "upload"}
                                        errorMessage={"Please upload a document"}
                                    />
                                </div>
                        }

                        {
                            (!view || (view && notes)) &&
                            <LabeledInput
                                nomargin
                                label="Notes"
                                type={view && 'read-only'}
                                placeholder="Add any notes regarding this document"
                                onChange={(event) => this.setState({ notes: event.target.value })}
                                value={notes}
                                optional
                            />
                        }

                        <FlexColumn end grow>
                            <Flex start gap='24px' style={{ paddingBottom: 24 }} centerHorizontally>
                                {
                                    view ?
                                        <Button onClick={() => this.setState({ view: false })} solid text={'EDIT'} loading={addLoading} />
                                        :
                                        <Button onClick={this.addDocument} solid text={(msa || po || document) ? 'SAVE' : 'ADD'} loading={addLoading} />
                                }

                                {
                                    (msa || po || document) &&
                                    <ImageButton onClick={() => this.setState({ showDelete: true })} src={Delete} />
                                }
                            </Flex>
                        </FlexColumn>
                    </FlexColumn>
                </Drawer>

                <Modal
                    visible={showDelete}
                    footer={null}
                    closable={false}
                    width={510}
                    destroyOnClose={true}
                    closeIcon={<Image src={ModalClose} />}
                    className='drawer-modal'
                    wrapClassName="drawer-modal"
                >
                    <DeleteModal
                        title='Delete document?'
                        description={<FlexColumn start centerHorizontally>
                            <Text>Are you sure you want to delete this document?</Text>
                            <Text>This cannot be undone</Text>
                        </FlexColumn>}
                        descWidth={'470px'}
                        buttonTitle='OKAY'
                        onConfirm={() => {
                            this.setState({ deleteLoading: true }, () => {
                                if (msa) {
                                    this.handleDeleteMSA(msa.masterServiceAgreementId)
                                } else if (po) {
                                    this.handleDeleteWO(po.ponumber)
                                } else if (document) {
                                    this.handleDeleteDocument(document)
                                }
                            })
                        }}
                        onClose={() => this.setState({ showDelete: false })}
                        deleteLoading={deleteLoading}
                    />
                </Modal>

                <AlertModal
                    close
                    visible={showExitModal}
                    title='Are you sure you want to exit?'
                    description='You will lose your progress if you exit before saving your document.'
                    buttonTitle='EXIT WITHOUT SAVING'
                    cancelButtonText='GO BACK'
                    onConfirm={() => {
                        this.setState({ addLoading: false, showExitModal: false, showDelete: false })
                        onClose()
                    }}
                    onCancel={() => this.setState({ showExitModal: false })}
                />
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(DocumentDrawer)))