import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { Typography, message, Skeleton, Space, Divider, Upload, Select, Collapse } from 'antd';
import { CheckCircleFilled, UploadOutlined, ArrowRightOutlined, PlusOutlined, DownOutlined, CheckCircleOutlined, EditOutlined, FilePdfOutlined } from '@ant-design/icons';

import { FlowViewContainer } from '../../Reusable/FlowViewContainer';
import FlowViewComponent from '../../Reusable/FlowViewComponent';
import { Text, Tag } from '../../Reusable/Refresh/Text';
import { Flex, FlexColumn, Container, Card } from '../../Reusable/Container';

import { ErrorAlert } from '../../Reusable/Alert';
import { flowViewsData, applyDoneStep } from './viewData';
import { apiPOSTReq, getJwt } from '../../../Utils/api';
import environment from '../../../environment';

// Actions
import { addDataToStore, ONB_SAVE_DATA, SAVE_DATA, UAM_SAVE_BUSINESS, UAM_SAVE_PERMISSIONS } from '../../../Actions/actions';
import BusinessLookup from '../Reusable/BusinessLookup';
import BusinessProfile from '../Reusable/BusinessProfile';
import SplashPage from '../Reusable/SplashPage';
import UserProfile from '../Reusable/UserProfile';
import TxnActivityInfo from '../Reusable/TxnActivityInfo';
import OwnershipInfo from '../Reusable/OwnershipInfo';
import ControlPersonInfo from '../Reusable/ControlPersonInfo';
import AuthSignerInfo from '../Reusable/AuthSignerInfo';
import BankAppReview from '../Reusable/BankAppReview';
import BankAppStatus from '../Reusable/BankAppStatus';
import UserConsent from '../Reusable/UserConsent';

class Index extends Component {

    state = {
        submitLoading: false,
        loading: true,
        step: 0,
        flowSteps: [
            "Your Information",
            "Business Profile",
            "Business Activity",
            "Ownership",
            "Controlling Party",
            // "Authorized Signers",
            "Review"
        ],
        applicationStep: 0,
    }
    flowViews = [];

    componentDidMount() {
        window.scroll({ top: 0, behavior: 'smooth' })
        const { store, onboardingStore } = this.props;
        const { initialBusinessId, BusinessUniqueKey } = store;
        console.log("componentDidMount userStatus", onboardingStore.userStatus)
        var validNRCodes = (this.props.store.AppConfig || {}).NonResidentCodes || ["AINR323"]
        this.props.dispatch(addDataToStore(ONB_SAVE_DATA, { allowNRFlow: validNRCodes.includes(this.props.store.referralCode) }))
        
        if(store.OnboardingType == "credit") {
            this.flowViews = flowViewsData.slice(1)
        } else {
            this.flowViews = flowViewsData
        }
        if (!(initialBusinessId || BusinessUniqueKey)) {
            this.createInitialBusinessSignup();
        } else {
            this.fetchBusinessData();
            this.loadAppFromState()
        }
        getJwt((err, jwt) => {
            this.props.dispatch(addDataToStore(UAM_SAVE_PERMISSIONS, { jwt: jwt }))
        })
    }

    fetchBusinessData = () => {
        apiPOSTReq(`${environment.uamBaseUrl}/getBusinessOwnership`, {}, {}, (err, resp) => {
            try {
                if (err) throw Error(err);
                const data = resp || {};
                if (data.result) {
                    var business = data.business || {}
                    var businessProfile = business.businessProfile || {}
                    this.props.dispatch(addDataToStore(UAM_SAVE_BUSINESS, data))
                    this.props.dispatch(addDataToStore(ONB_SAVE_DATA, { 
                        businessUniqueKey: business.Id,
                        personalInfo: businessProfile.primaryApplicantInfo || {},
                        coOwnersInfo: businessProfile.coOwnerApplicantInfo || [],
                        signersInfo: businessProfile.signerInfo || [],
                        businessInfo: businessProfile.businessInfo,
                        ...business
                    }))
                    } else {
                    throw Error(data.responseMessage || data.error);
                }
            } catch (error) {
                console.log("/getBusiness err", error, resp);
            }
        });
    }

    updateCurrentViewIndex = (i) => {
        this.setState({ currentViewIndex: i })
    }

    goToStep = (step) => {
        const stepMap = {
            "Your Information": "Apply.PersonalInfo",
            "Business Profile": "Apply.BusinessInfo",
            "Business Activity": "Apply.TxnActivity",
            "Ownership": "Apply.Ownership",
            "Controlling Party": "Apply.ControlPerson",
            "Authorized Signers": "Apply.AuthorizedSigner",
            "Review": "Apply.Review"
        }
        const selectedStep = stepMap[this.state.flowSteps[step]]
        var currentStep = 0
        this.flowViews.forEach((item, i) => { if (item.FlowStep == selectedStep) currentStep = i })
        this.setState({ currentViewIndex: currentStep })
    }

    createInitialBusinessSignup = () => {
        const { UserInfo } = this.props.store || {};
        console.log("/initialSignUp PRE body", { userId: UserInfo.Email });
        apiPOSTReq(`${environment.uamBaseUrl}/initialSignUp`, {}, { userId: UserInfo.Email }, (err, resp) => {
            console.log("/initialSignUp POST body", { userId: UserInfo.Email });
            this.setState({ loading: false, currentViewIndex: 0 });
            try {
                var data = resp || {};
                console.log("/initialSignUp", err, resp);
                if (data.result) {
                    this.setState({ initialBusiness: data });
                    var businessId = (data.business || {}).id;
                    this.props.dispatch(addDataToStore(SAVE_DATA, { initialBusinessId: businessId, BusinessUniqueKey: businessId }));
                } else {
                    throw Error(data.error || data.responseMessage);
                }
            } catch (error) {
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        })
    }

    loadAppFromState = () => {
        var { userStatus } = this.props.onboardingStore
        var currentStep = 0
        if (userStatus == "Apply.BankSubmitted") {
            currentStep = this.flowViews.length - 1
        } else {
            this.flowViews.forEach((item, i) => { if (item.FlowStep == userStatus) currentStep = i + 1 })
        }

        this.setState({ currentViewIndex: currentStep, loading: false })
    }

    render() {
        const flowView = this.flowViews[this.state.currentViewIndex] || {}
        return (
            <FlexColumn fullHeight={!['Apply.BusinessInfo'].includes(flowView.FlowStep)} style={{ minHeight: '100%' }} start>
                {this.getContentViews()}
            </FlexColumn>
        )
    }

    // This will provide the view for each flowView
    getContentViews = () => {
        var { currentViewIndex, loading, flowSteps, applicationStep } = this.state;
        var {store} = this.props;

        
        if (loading) {
            return (
                <FlexColumn style={{ margin: '80px', width: '80%' }}>
                    <Skeleton loading={true} active title={false} paragraph={{ rows: 10 }}></Skeleton>
                </FlexColumn>
            )
        }

        const flowView = this.flowViews[currentViewIndex] || {};
        switch (flowView.FlowStep) {
            case "Apply.Splash":
                return (
                    <SplashPage
                        delay={3000}
                        loadNext={this.loadNext}
                    />
                );
            case "Apply.Consent":
                return (
                    <UserConsent
                        loading={loading}
                        flowView={flowView}
                        currentViewIndex={currentViewIndex}
                        loadPrev={this.loadPrevView}
                        loadNext={this.loadNext}
                        width={'100%'}
                        showFooter={false}
                    />
                );
            case "Apply.PersonalInfo":
                return (
                    <UserProfile 
                        loading={loading}
                        flowView={flowView}
                        currentViewIndex={currentViewIndex}
                        loadPrev={this.loadPrevView}
                        loadNext={this.loadNext}
                        width={'100%'}
                        back={currentViewIndex > 1}
                        padding='0 64px'
                        step={currentViewIndex - (store.OnboardingType == "credit" ? 1 : 2)}
                        steps={flowSteps}
                        showFooter={currentViewIndex < flowSteps.length}
                        headerMargin='24px 0'
                        applicationStep={applicationStep}
                        goToStep={this.goToStep}
                    />
                );
            case "Apply.Lookup":
                return (
                    <BusinessLookup
                        loading={loading}
                        flowView={flowView}
                        currentViewIndex={currentViewIndex}
                        loadPrev={this.loadPrevView}
                        loadNext={this.loadNext}
                        width={'100%'}
                        back={currentViewIndex > 0}
                        padding='0 64px'
                        step={currentViewIndex -  (store.OnboardingType == "credit" ? 1 : 2)}
                        steps={flowSteps}
                        showFooter={currentViewIndex < flowSteps.length}
                        headerMargin='24px 0'
                        applicationStep={applicationStep}
                        goToStep={this.goToStep}
                    />
                );
            case "Apply.BusinessInfo":
                return (
                    <BusinessProfile
                        loading={loading}
                        flowView={flowView}
                        currentViewIndex={currentViewIndex}
                        loadPrev={this.loadPrevView}
                        loadNext={this.loadNext}
                        loadNextView={this.loadNextView}
                        width={'100%'}
                        back={currentViewIndex > 0}
                        padding='0 64px'
                        step={currentViewIndex - (store.OnboardingType == "credit" ? 2 : 3)} // So that step shows as Business Profile
                        steps={flowSteps}
                        showFooter={currentViewIndex < flowSteps.length}
                        headerMargin='24px 0'
                        applicationStep={applicationStep}
                        goToStep={this.goToStep}
                    />
                )
            case "Apply.TxnActivity":
                return (
                    <TxnActivityInfo
                        loading={loading}
                        flowView={flowView}
                        flowViews={this.flowViews}
                        currentViewIndex={currentViewIndex}
                        loadPrev={this.loadPrevView}
                        loadNext={this.loadNext}
                        loadNextView={this.loadNextView}
                        width={'100%'}
                        back={currentViewIndex > 0}
                        padding='0 64px'
                        step={currentViewIndex - (store.OnboardingType == "credit" ? 2 : 3)}
                        steps={flowSteps}
                        showFooter={currentViewIndex < flowSteps.length}
                        headerMargin='24px 0'
                        applicationStep={applicationStep}
                        goToStep={this.goToStep}
                    />
                )
            case "Apply.Ownership":
                return (
                    <OwnershipInfo
                        loading={loading}
                        flowView={flowView}
                        flowViews={this.flowViews}
                        currentViewIndex={currentViewIndex}
                        loadPrev={this.loadPrevView}
                        loadNext={this.loadNext}
                        loadNextView={this.loadNextView}
                        width={'100%'}
                        back={currentViewIndex > 0}
                        padding='0 64px'
                        step={currentViewIndex -(store.OnboardingType == "credit" ? 2 :3)}
                        steps={flowSteps}
                        showFooter={true}
                        headerMargin='24px 0'
                        applicationStep={applicationStep}
                        goToStep={this.goToStep}
                    />
                )
            case "Apply.ControlPerson":
                return (
                    <ControlPersonInfo
                        loading={loading}
                        flowView={flowView}
                        flowViews={this.flowViews}
                        currentViewIndex={currentViewIndex}
                        loadPrev={this.loadPrevView}
                        loadNext={this.loadNext}
                        loadNextView={this.loadNextView}
                        width={'100%'}
                        back={currentViewIndex > 0}
                        padding='0 64px'
                        step={currentViewIndex - (store.OnboardingType == "credit" ? 2 : 3)}
                        steps={flowSteps}
                        showFooter={true}
                        headerMargin='24px 0'
                        applicationStep={applicationStep}
                        goToStep={this.goToStep}
                    />
                )
            case "Apply.AuthorizedSigner":
                return (
                    <AuthSignerInfo
                        loading={loading}
                        flowView={flowView}
                        flowViews={this.flowViews}
                        currentViewIndex={currentViewIndex}
                        loadPrev={this.loadPrevView}
                        loadNext={this.loadNext}
                        loadNextView={this.loadNextView}
                        width={'100%'}
                        back={currentViewIndex > 0}
                        padding='0 64px'
                        step={currentViewIndex - (store.OnboardingType == "credit" ? 2 : 3)}
                        steps={flowSteps}
                        showFooter={true}
                        headerMargin='24px 0'
                        applicationStep={applicationStep}
                        goToStep={this.goToStep}
                    />
                )
            case "Apply.Review":
                return (
                    <BankAppReview
                        loading={loading}
                        flowView={flowView}
                        flowViews={this.flowViews}
                        currentViewIndex={currentViewIndex}
                        loadPrev={this.loadPrevView}
                        loadNext={this.loadNext}
                        loadNextView={this.loadNextView}
                        width={'100%'}
                        padding='0 64px'
                        step={currentViewIndex}
                        steps={flowSteps}
                        headerMargin='24px 0'
                        applicationStep={applicationStep}
                        updateCurrentViewIndex={this.updateCurrentViewIndex}
                        hideSteps={true}
                        goToStep={this.goToStep}
                    />
                )
            case "Apply.BankSubmitted":
                return (
                    <BankAppStatus
                        flowView={flowView}
                    />
                )
            default:
                break;
        }
    }

    // Validate input and go to next view if it's good
    loadNext = () => {
        var flowView = this.flowViews[this.state.currentViewIndex];
        var validation = { validated: true };
        if (validation.validated) {
            switch (flowView.FlowStep) {
                case "Apply.PersonalInfo":
                    const { onboardingStore } = this.props;
                    var businessInfo = onboardingStore.businessInfo || {};
                    if (businessInfo.name) {
                        // If businessInfo has already been loaded skip Apply.Lookup step
                        if (this.state.currentViewIndex + 2 < this.flowViews.length) {
                            this.setState({
                                currentViewIndex: this.state.currentViewIndex + 2
                            });
                        }
                        window.scrollTo(0, 0);
                        break
                    }
                default:
                    this.loadNextView();
                    break
            }
        } else {
            ErrorAlert({ description: validation.message });
        }
    }

    loadPrevView = () => {
        if (this.state.currentViewIndex !== 0) {
            this.setState({
                currentViewIndex: this.state.currentViewIndex - 1,
                showError: false, errorMsg: ""      // dismiss any validatin error, if any, for the current step
            });
        }
    }

    loadNextView = () => {
        if (this.state.currentViewIndex + 1 < this.flowViews.length) {
            this.setState({
                currentViewIndex: this.state.currentViewIndex + 1
            });
        }
        window.scrollTo(0, 0);
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer,
        creditStore: state.creditAppReducer,
        onboardingStore: state.onboardingAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)));