import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import { CardContainer } from '../Reusable/CardContainer'
import { Flex, FlexColumn } from '../../Reusable/Container'
import { Text } from '../../Reusable/Text'
import { getDateInLocalTZ, toAbsCurrency, toCurrency } from '../../../Utils/util'
import moment from 'moment'
import environment from '../../../environment'
import TableView from '../../Reusable/TableView'

import MoneyOut from '../../../Images/money-out.svg'
import MoneyIn from '../../../Images/money-in.svg'
import AppIcon from '../../../Images/product-menu/bank-menu-icon-active.png'
import { apiPOSTReq } from '../../../Utils/api'
import CardHeader from '../Reusable/CardHeader'
import { TextButton } from '../../Reusable/Button'
import ArrowRight from "../../../Images/arrow-right.png"
import { Image } from '../../Reusable/Image'
import hexToRgba from 'hex-to-rgba'

class RecentTxns extends Component {
    state = {
        loading: true,
        transactionsList: []
    }

    componentDidMount() {
        this.fetchRecentActivity()
        this.fetchAccounts()
    }

    fetchRecentActivity = () => {
        var fetchOptions = {
            size: 5,
            page: 0,
            sortDirection: "DESC"
        }
        fetchOptions.fromDate = moment().subtract(900, 'days').format("YYYY-MM-DD")
        fetchOptions.toDate = moment().format('YYYY-MM-DD')
        fetchOptions.bankProvider = environment.bankProvider.crb
        fetchOptions.providerStatus = "Pending|Posted"
        
        apiPOSTReq(`${environment.bbBaseUrl}/bb/getTransactions`, {}, fetchOptions, (err, resp) => {
            try {
                this.setState({ loading: false })
                const data = resp || {};
                if (data.result) {
                    var transactionsList = data.transactionsList || [];
                    this.setState({ transactionsList: transactionsList })
                } else {
                    throw Error(data.error || data.responseMessage || "Could not fetch transactions.")
                }
            } catch (error) {
                console.log("ERRR getTransactions", error, resp);
            }
        })
    }

    fetchAccounts = () => {

        apiPOSTReq(`${environment.bbBaseUrl}/bb/getAccounts`, { "BankProvider": environment.bankProvider.crb }, null, (err, resp) => {
            try {
                const data = resp
                if (data.result) {
                    if (err) throw new Error(err)
                    var bbaccounts = data.bbaccounts || []
                    var totalAvblBal = 0
                    totalAvblBal = bbaccounts.reduce((prev, curr) => (prev + curr.availableBalance), 0)
                    this.setState({ totalAvblBal: totalAvblBal })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getAccounts", error, err, resp)
            }
        })
    }

    columns = [
        {
            title: 'Description',
            dataIndex: 'displayDescription',
            key: 'displayDescription',
            render: (desc, item) => <Flex start centerH gap='16px'>
                {item.transactionType == 'Credit' ? <img src={MoneyIn} /> : <img src={MoneyOut} />}
                {desc.substring(0, 55)}
            </Flex>
        },
        {
            title: '',
            dataIndex: 'date',
            key: 'date',
            align: "right",
            render: (dt) => <span style={{ color: hexToRgba(this.props.theme.colors.primary2, 0.5) }}>{getDateInLocalTZ(dt)}</span>
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'Amount',
            align: "right",
            render: amount => <span style={{ fontWeight: 600, color: amount > 0 && this.props.theme.colors.creditGreen }}>{amount > 0 ? `+ $${toAbsCurrency(amount)}` : `- $${toAbsCurrency(amount)}`}</span>
        },
    ]

    render() {
        const { store, theme } = this.props
        const { transactionsList, loading, totalAvblBal } = this.state

        console.log('transactionsList', transactionsList)
        return (
            <CardContainer>
                <FlexColumn  style={{ width: this.props.theme.dashboardCard.large }}>
                    <CardHeader 
                        imgSrc={AppIcon}
                        titleText='Banking' 
                        desc='Recent activity'
                        path='banking/home'
                        secondaryContent={
                            <FlexColumn centerVertically>
                                {totalAvblBal && <Text size='16px'>Available cash: <span style={{ fontWeight: 600 }}>${toCurrency(totalAvblBal)}</span></Text>}
                                {totalAvblBal > 0 && <TextButton onClick={() => this.props.history.push('banking/transfers')} rightIcon={<Image src={ArrowRight} />} text="MOVE MONEY" />}
                                {/* <Text size='16px' weight={500} color={theme.colors.primary9}>$10,000.00 in today</Text> */}
                            </FlexColumn>
                        }
                    />
                    <TableView
                        id="recent-txns"
                        tableLayout='auto'
                        columns={this.columns}
                        dataSource={transactionsList}
                        rowKey='id'
                        loading={loading}
                        borderView
                        hideHeader
                    />
                </FlexColumn>
                
            </CardContainer>
            
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(RecentTxns)))