import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import moment from 'moment';

import { Affix, Layout } from 'antd';

import { LeftOutlined } from '@ant-design/icons';

// Components
import { TextButton } from '../../../Reusable/Button';
import { FlexColumn, Container } from '../../../Reusable/Container';
import PageHeader from '../../../Reusable/PageHeader';
import NewContractor from '../NewContractor';

const { Content } = Layout;

class Pay extends Component {

    constructor(props) {
        super(props)

        this.state = {}
    }

    componentDidMount() {
        if (this.props.location.state && this.props.location.state.contractor) {
            var contractor = this.props.location.state.contractor || {}
            this.setState({ contractor })
        }
    }

    render() {
        const { theme } = this.props

        return (
            <FlexColumn className='main-padding' style={{ width: '100%' }} start>
                <PageHeader
                    back
                    backText='Contractors'
                    titleText={this.state.contractor ? "Edit contractor" : "Create new contractor"}
                />

                <Container shadow style={{ marginBottom: 48 }} padding='12px 24px 24px'>
                    <NewContractor
                        key={this.state.contractor ? this.state.contractor.id : moment().format("HH:ss")}
                        contractor={this.state.contractor && this.state.contractor}
                        onSave={() => window.history.back()} />
                </Container>
            </FlexColumn>

        )
    }
}

function mapStateToProps(state) {
    return {
        bkStore: state.bookkeepingAppReducer,
        store: state.aionAppReducer,
        bankingStore: state.bankingAppReducer,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Pay))