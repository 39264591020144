import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled, { withTheme } from 'styled-components'
import { addDataToStore, BUSINESS_INFO, REMOTE_SAVE_DATA } from '../../../Actions/actions'
import { Button } from '../../Reusable/Button'
import { Steps } from 'antd'

import { Flex, FlexColumn, CardContainer, LightContainer } from '../../Reusable/Container'
import { toCurrency } from '../../../Utils/util';
import { Divider } from '../../Reusable/Divider'
import { Image } from '../../Reusable/Image'
import { LabeledInput, StyledInput } from '../../Reusable/Input'
import { Text } from '../../Reusable/Text'
import ChkListIcon from '../../../Images/checklist-icon.png'
import ChkIcon from '../../../Images/check-secondary-icon.png'
import Check from '../../../Images/check.png';
import CheckCaption from '../../../Images/check-caption.png';
import STF from '../../../Images/stc.png'
import RLOC from '../../../Images/rloc.png'
import STEP1 from '../../../Images/grad-step-1.png'
import STEP2 from '../../../Images/grad-step-2.png'
import STEP3 from '../../../Images/grad-step-3.png'
import STEP4 from '../../../Images/grad-step-dollar.png'
import InfoCaption from '../../../Images/info-caption.png';
import environment from '../../../environment';
import { apiPOSTReq } from '../../../Utils/api';

const { Step } = Steps

class CreditGetStarted extends Component {

    state = {
        businessInfo: {},
        submitLoading: false,
    }

    saveUserInput = (id, value) => {
        var dataToSave = {};
        switch (id) {
            default:
                dataToSave = { [id]: value }
                break;
        }
        this.props.dispatch(addDataToStore(BUSINESS_INFO, dataToSave));
    }

    handleOnChange = (event) => {
        if (event) {
            const target = event.target;
            if (!target) {
                // since type number doesnt have event.target
                this.saveUserInput("capitalRequest", event)
            } else {
                this.saveUserInput(target.id || target.name, target.value.replace("$ ", "").replace(",", ""))
            }
        } else {
            this.saveUserInput("capitalRequest", null)
        }
    }

    render() {
        var { businessInfo, onboardingStore } = this.props
        var { errorField, errorMessage } = this.state
        businessInfo = Object.assign(businessInfo || {}, onboardingStore.businessInfo)

        return (
            <FlexColumn start left fullHeight style={{ margin: '24px 64px', paddingTop: 24, minHeight: "600px" }}>
                <FlexColumn center fullWidth style={{ padding: '0 0 24px 0' }}>
                    <Text heading size='28px'>Grow your business with Aion</Text>
                    <Text width='408px' center>Capital to cover your cash flow gaps and scale your business, without diluting your ownership</Text>
                    <Text size='20px' margin='48px 0 0' spacing>Get funded in 3 easy steps</Text>
                </FlexColumn>

                <Flex fullWidth style={{ padding: '0 96px', marginBottom: 48 }}>
                    <Steps current={3} labelPlacement='vertical'>
                        <Step status="finish" title="Submit your financing application" icon={<Image src={STEP1} />} />
                        <Step status="finish" title={<>Receive a credit<br />proposal</>} icon={<Image src={STEP2} />} />
                        <Step status="finish" title="Complete your application and sign agreement" icon={<Image src={STEP3} />} />
                        <Step status="finish" title={<>Start using your<br />funds</>} icon={<Image src={STEP4} />} />
                    </Steps>
                </Flex>

                <CardContainer padding='0' fullWidth height='264px'>
                    <FlexColumn className='capital-card-back' center fullWidth fullHeight style={{ padding: '0', border: "1px solid #1A8AFA" }}>
                        <Text size='20px' margin='0 0 0' spacing>How much working capital do you need?</Text>
                        <LabeledInput
                            autoFocus
                            alignCenter
                            margin='24px 0 24px'
                            labelcolor={this.props.theme.colors.secondary3}
                            width='480px'
                            id="capitalRequest"
                            textSize='20px'
                            minHeight='48px'
                            value={businessInfo.capitalRequest && `$ ${toCurrency(businessInfo.capitalRequest, 0, 0)}`}
                            placeholder="Enter the amount of funding you need"
                            onChange={this.handleOnChange}
                            error={errorField === "capitalRequest"}
                            errorMessage={errorMessage}
                            alignError={true}
                        />
                        <Button primary solid text="BEGIN APPLICATION" loading={this.state.submitLoading} onClick={() => {
                            businessInfo.capitalRequest = businessInfo.capitalRequest.toString().replaceAll(',', '')
                            console.log("businessInfo.capitalRequest", Number(businessInfo.capitalRequest))
                            if (businessInfo.capitalRequest && Number(businessInfo.capitalRequest) >= 10000) {
                                if (Number(businessInfo.capitalRequest) > 5000000) {
                                    this.setState({ errorField: "capitalRequest", errorMessage: 'If you require credit greater than $5M, please contact us at hello@aionfi.com' })
                                }
                                else {
                                    this.props.dispatch(addDataToStore(REMOTE_SAVE_DATA, "Apply.GetStarted"))
                                    var { loanApplication, isCreditPlus } = this.props;

                                    if (isCreditPlus) {
                                        var body = {
                                            applicationId: loanApplication.applicationId,
                                            BusinessId: loanApplication.businessId,
                                        }

                                        if (!this.state.submitLoading) {
                                            this.setState({ submitLoading: true });
                                            apiPOSTReq(`${environment.obpBaseUrl}/obp/ilien/fetchUCCNoReport`, {}, body, (err, resp) => {
                                                try {
                                                    if (err) throw Error(err);
                                                    const data = resp || {};
                                                    if (data) {
                                                        this.setState({ submitLoading: false, showSuccessBanner: true });
                                                        setTimeout(() => {
                                                            this.setState({ showSuccessBanner: false })
                                                            this.props.loadNext();
                                                        }, 1000)
                                                    } else {
                                                        throw Error(data.responseMessage || data.error);
                                                    }
                                                }
                                                catch (error) {
                                                    this.setState({ submitLoading: false });
                                                    console.log("/fetchUCCNoReport err", error, resp);
                                                }
                                            });
                                        }
                                    }
                                    else {
                                        this.props.loadNext()
                                    }
                                }
                            } else {
                                this.setState({ errorField: "capitalRequest", errorMessage: 'Enter an amount greater than or equal to $10,000' })
                            }
                        }} />
                    </FlexColumn>
                </CardContainer>

                <CardContainer padding='0' fullWidth height='314px' margin='24px 0'>
                    <Flex className='helpful-pre-qual-back' fullHeight fullWidth style={{ padding: '24px' }}>
                        <FlexColumn style={{ width: '100%', margin: '0 24px 24px' }}>
                            <Flex start gap='8px' style={{ margin: '24px 0 16px' }}>
                                <Image src={InfoCaption} />
                                <Text color='#7384AA' size='20px'>Helpful information for pre-qualification</Text>
                            </Flex>
                            <FlexColumn start left gap='24px'>
                                <Text>Please have the following information handy so you can submit your application</Text>
                                <Flex start gap='8px'>
                                    <Image src={CheckCaption} />
                                    <Text>Company prepared balance sheet - most recent closed month and most recent fiscal year end</Text>
                                </Flex>
                                <Flex start gap='8px'>
                                    <Image src={CheckCaption} />
                                    <Text>Company prepared profit and loss - YTD through most recent closed month and full year for most recent fiscal year end</Text>
                                </Flex>
                                <Flex start gap='8px'>
                                    <Image src={CheckCaption} />
                                    <Text>Federal Employer Identification Number (FEIN) of your business</Text>
                                </Flex>
                                <Flex start gap='8px'>
                                    <Image src={CheckCaption} />
                                    <Text>Social Security Number and Date of Birth for individuals who own 25% or more of your business</Text>
                                </Flex>
                            </FlexColumn>
                        </FlexColumn>
                    </Flex>
                </CardContainer>
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        onboardingStore: state.onboardingAppReducer,
        aionStore: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(CreditGetStarted))