import React, { useState } from 'react'

import { Text } from '../Reusable/Text'
import { FlexColumn } from '../Reusable/Container'
import { Button } from '../Reusable/Refresh/Button'
import environment from '../../environment'
import { apiPOSTReq } from '../../Utils/api'
import { ErrorAlert } from '../Reusable/Alert'
import moment from 'moment'

const GettingStarted = (props) => {
    const [loading, setLoading] = useState(false)

    const createInvoiceTemplate = () => {
        setLoading(true)
        apiPOSTReq(`${environment.iveBaseUrl}/ive/templates/createInvoiceTemplate`, {}, { templateType: "INVOICE", templateName: `Template 1 ${moment().format('ll')}`, displayName: `Template 1 ${moment().format('ll')}`, newTemplateFlag: true }, (err, resp) => {
            try {
                const data = resp
                console.log("/ive/templates/createInvoiceTemplate", data)
                if (data.result) {
                    props.submitComplete()
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                setLoading(false)
                console.log("ERRR createInvoiceTemplate", error.stack, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    return (
        <FlexColumn left>
            <Text heading margin='0 0 16px'>Get paid faster with Aion Invoices</Text>
            <Text caption weight='500'>{'Get started with Aion Invoices'.toUpperCase()}</Text>
            <Text margin='0 0 16px'>Send invoices from anywhere, anytime to get paid quickly. Aion invoicing has everything in one place -- create, send, track, record and send a thank you!</Text>
            <Text caption weight='500'>{'Send invoices in a zip'.toUpperCase()}</Text>
            <Text margin='0 0 16px'>Enter your customer information, products or services you sold and click Send.</Text>
            <Text caption weight='500'>{'Automated tracking'.toUpperCase()}</Text>
            <Text margin='0 0 16px'>Every invoice you send is tracked automatically for you. You can see when it was delivered and viewed. It automatically tracks who reviewed the invoices.</Text>
            <Text caption weight='500'>{'Payment instructions'.toUpperCase()}</Text>
            <Text margin='0 0 16px'>Provide payment details (ACH, Wire, Check) and any special instructions, just the way you want to be paid.</Text>
            <Text caption weight='500'>{'Reconcile Payments'.toUpperCase()}</Text>
            <Text margin='0 0 16px'>Get your invoice payment deposited directly into your Aion account for free. Manage your cash flow easily -- invoices and payments, all in one place.</Text>
            <Text caption weight='500'>{'Customize invoices the way you like'.toUpperCase()}</Text>
            <Text margin='0 0 48px'>Upload your business logo. Add payment instructions, attachments and custom notes. Save the customization as a template. The only invoicing app to provide you with the ability to have customer specific templates.</Text>
            <Button
                solid
                radius='4px'
                width='140px'
                text='Get Started'
                onClick={() => createInvoiceTemplate()}
                loading={loading} />
        </FlexColumn>
    )
}

export default GettingStarted