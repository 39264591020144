import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'

import {
    message, Select, Popconfirm, Switch
} from 'antd'

import {
    DeleteOutlined
} from '@ant-design/icons'

// Components
import { Button, TextButton } from '../../Reusable/Button'
import { Text } from '../../Reusable/Text'
import { FlexColumn } from '../../Reusable/Container'
import { LabeledInput } from '../../Reusable/Input'
import { ErrorAlert } from '../../Reusable/Alert'
import environment from '../../../environment'
import { apiPOSTReq } from '../../../Utils/api'
import { capitalizeWords, getUserApps } from '../../../Utils/util'

import { coaSubTypes, coaTypes, subTypeValueToName, defaultSubType, subTypeNameToValue } from './qboAccountConstants'

const { Option } = Select

class SaveCOA extends Component {
    constructor(props) {
        super(props)
        let editCOA = props.editCOA || {}
        this.state = {
            loading: false, ...editCOA,
            hasParent: editCOA.parentCategoryName ? true : false,
        }
    }

    handleTextChange = (event) => {
        this.setState({ [event.target.id]: (["categoryName"].includes(event.target.id)) ? capitalizeWords(event.target.value) : event.target.value })
    }

    handleSubmit = async (event) => {
        const { categoryName, description, code, coatype, parentCategoryName, hasParent, id } = this.state
        if (!categoryName) {
            ErrorAlert({ description: "Please enter a category name." })
            return
        }
        if (!coatype) {
            ErrorAlert({ description: "Please select an account type." })
            return
        }
        const validateAccountCode = this.validateAccountCode(coatype, code)
        if (!validateAccountCode.validated) {
            ErrorAlert({ description: validateAccountCode.message })
            return
        }
        this.setState({ loading: true })
        const headers = {
            AionCurrentBiz: this.props.aionStore.BusinessUniqueKey,
            AionAuth: this.props.aionStore.UAM.encryptedAuthHeader
        }
        var coaObj = {
            "id": id,
            "Code": code,
            "categoryName": categoryName,
            "Description": description,
            "COAType": coatype,
            "COASubType": this.state.coasubType,
            "ParentCategoryName": hasParent ? parentCategoryName : null,
            "QuickBooksId": this.state.quickBooksId
        }
        // Only for new COA include the CategoryName
        if (!this.props.editCOA) coaObj["CategoryName"] = categoryName
        else coaObj["CategoryName"] = categoryName
        const body = {
            "BusinessId": this.props.aionStore.BusinessUniqueKey,
            "CustomCOA": coaObj,
        }

        console.log("coaObj", hasParent, coaObj)
        apiPOSTReq(`${environment.bbBaseUrl}/bk/saveCategory`, headers, body, (err, resp) => {
            try {
                this.setState({ loading: false })
                const data = resp
                console.log("/bk/saveCategory", data)
                if (data.result) {
                    message.success(id ? `${categoryName} successfully updated` : `${categoryName} successfully created`)
                    this.props.coaSaved()
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR saveCategory", error.stack, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    deactivateCategory = () => {
        const { categoryName } = this.state
        // deactivates a Custom COA when there are no references to BKTransactions and is not a parent
        const headers = {
            AionCurrentBiz: this.props.aionStore.BusinessUniqueKey,
            AionAuth: this.props.aionStore.UAM.encryptedAuthHeader
        }
        const body = {
            "BusinessId": this.props.aionStore.BusinessUniqueKey,
            "CategoryName": categoryName,
            "AccountId": this.state.coaId,
        }

        this.setState({ deactivateLoading: true })
        apiPOSTReq(`${environment.bbBaseUrl}/bk/deactivateCategory`, headers, body, (err, resp) => {
            try {
                this.setState({ deactivateLoading: false })
                const data = resp
                console.log("/bk/deactivateCategory", data)
                if (data.result) {
                    message.success(`${categoryName} deactivated!`)
                    this.props.coaSaved()
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR deactivateCategory", error.stack, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    coatypeMap = (coatype) => {
        return (coatype === "COGS") ? "Cost of Goods Sold" : coatype
    }

    getCOASubType = () => {
        if (!this.state.coatype) {
            return []
        }
        const coaSubtypeList = coaSubTypes[this.state.coatype]
        if (!coaSubtypeList) {
            return []
        }
        var coaSubtypeOptions = []
        coaSubtypeList.forEach(value => {
            let name = subTypeValueToName[value]
            coaSubtypeOptions.push(
                <Option key={value} value={value}>
                    {name}
                </Option>
            )
        })
        return coaSubtypeOptions
    }

    coasubtypeMap = () => {
        if (!this.state.coasubType) {
            return null
        }
        return subTypeNameToValue[this.state.coasubType]
    }

    getDefaultSubtype = () => {
        if (!this.state.coatype) {
            return null
        }
        return defaultSubType[this.state.coatype]
    }

    render() {
        const { categoryName, description, code, coatype, parentCategoryName, hasParent } = this.state
        const { theme, bkStore } = this.props
        console.log(this.state)
        var coa = bkStore.COA || []
        var coaOptions = []
        coaTypes.forEach(item => {
            coaOptions.push(
                <Option key={item} value={item}>
                    {item}
                </Option>
            )
        })

        const userApps = getUserApps(this.props.aionStore)
        const { CurrentSync } = this.props.aionStore
        let qbo = false
        if (CurrentSync && CurrentSync.platformName === 'QUICKBOOKS' && CurrentSync.syncSetupCompleted && userApps && userApps.includes("Bookkeeping")) {
            qbo = true
        }

        var parentCategoryOptions = []
        coa.map(item => parentCategoryOptions.push(
            <Option key={item?.categoryName} value={item?.categoryName}>
                {item?.categoryName}
            </Option>
        ))
        return (
            <FlexColumn start left style={{ width: "100%" }}>
                {/* <div style={{ width: "600px" }}> */}
                    <Text heading>{this.props.editCOA ? "Update account" : "Create a new account"}</Text>
                    <Text>Enter the details below</Text>
                    {/* <FlexColumn start> */}
                        <LabeledInput
                            autoFocus
                            label="Account name"
                            labelcolor={theme.colors.secondary3}
                            id="categoryName"
                            key="categoryName"
                            placeholder="Office Equipment"
                            value={categoryName}
                            onChange={this.handleTextChange}
                        />
                        <Text color={theme.colors.secondary7}>
                            Does this have a parent account? <Switch defaultChecked={hasParent} size='small' onChange={(checked) => { this.setState({ hasParent: checked }) }} />
                        </Text>
                        {
                            hasParent ?
                                <LabeledInput
                                    label="Parent Account"
                                    labelcolor={theme.colors.secondary3}
                                    id="parentCategoryName"
                                    key="parentCategoryName"
                                    type="select"
                                    placeholder="Product Revenue"
                                    value={parentCategoryName}
                                    onChange={value => { this.setState({ "parentCategoryName": value }) }}
                                >
                                    {parentCategoryOptions}
                                </LabeledInput>
                                :
                                null
                        }
                        <LabeledInput
                            label="Description"
                            labelcolor={theme.colors.secondary3}
                            id="description"
                            key="description"
                            placeholder="Purchases made for office supplies"
                            value={description}
                            onChange={this.handleTextChange}
                            optional
                        />
                        <LabeledInput
                            label="Account type"
                            labelcolor={theme.colors.secondary3}
                            id="coatype"
                            key="coatype"
                            type="select"
                            placeholder="Income"
                            value={coatype}
                            onChange={value => { this.setState({ "coatype": value }) }}
                        >
                            {coaOptions}
                        </LabeledInput>
                        <LabeledInput
                            label="Account subtype"
                            labelcolor={theme.colors.secondary3}
                            id="coasubType"
                            key="coasubType"
                            type="select"
                            placeholder={this.getDefaultSubtype()}
                            value={(this.state.coasubType)}
                            onChange={value => { this.setState({ coasubType: value }) }}
                        >
                            {this.state.coatype && this.getCOASubType()}
                        </LabeledInput>
                        <LabeledInput
                            label="Account number"
                            labelcolor={theme.colors.secondary3}
                            id="code"
                            key="code"
                            placeholder="5 digit code"
                            maxLength={5}
                            value={code}
                            onChange={this.handleTextChange}
                            instruction={this.getSampleAccountCodes(coatype)}
                        />
                        {
                            this.props.editCOA ?
                                <Popconfirm
                                    title={<>
                                        <div>Are you sure you want to deactivate this category?</div>
                                        {qbo && this.state.source && this.state.source === 'QUICKBOOKS' && <div>This will also deactivate the category on QuickBooks Online</div>}
                                    </>}
                                    onConfirm={this.deactivateCategory}
                                    okText="Confirm"
                                    okButtonProps={{ loading: this.state.deactivateLoading }}
                                    cancelText="Cancel"
                                >
                                    <TextButton icon={<DeleteOutlined />} text='Deactivate Account' />
                                </Popconfirm>
                                : null
                        }
                        <Button permtype="Bookkeeping.Account Transactions" style={{ margin: '20px 0 0' }} solid primary loading={this.state.loading} onClick={this.handleSubmit} text='Save' />
                    {/* </FlexColumn> */}
                {/* </div> */}
            </FlexColumn>
        )
    }

    validateAccountCode = (coatype, code) => {
        code = parseInt(code || 0)
        var validation = {
            validated: false,
            message: "Please enter a valid account number."
        }
        switch (coatype) {
            case "Accounts Payable":
                if ((code >= 20000 && code <= 20999)) validation.validated = true
                else validation.message = `Account number ${code} is invalid. ${coatype} category needs to start with 2 and be a 5 digit account number.`
                break
            case "Accounts Receivable":
                if ((code >= 10000 && code <= 10999)) validation.validated = true
                else validation.message = `Account number ${code} is invalid. ${coatype} category needs to start with 1 and be a 5 digit account number.`
                break
            case "Bank":
                if ((code >= 11000 && code <= 11999)) validation.validated = true
                else validation.message = `Account number ${code} is invalid. ${coatype} category needs to start with 1 and be a 5 digit account number.`
                break
            case "Cost of Goods Sold":
                if ((code >= 50000 && code <= 59999)) validation.validated = true
                else validation.message = `Account number ${code} is invalid. ${coatype} category needs to start with 5 and be a 5 digit account number.`
                break
            case "Credit Card":
                if ((code >= 21000 && code <= 21999)) validation.validated = true
                else validation.message = `Account number ${code} is invalid. ${coatype} category needs to start with 2 and be a 5 digit account number.`
                break
            case "Equity":
                if ((code >= 30000 && code <= 39999)) validation.validated = true
                else validation.message = `Account number ${code} is invalid. ${coatype} category needs to start with 3 and be a 5 digit account number.`
                break
            case "Expense":
                if ((code >= 60000 && code <= 69999)) validation.validated = true
                else validation.message = `Account number ${code} is invalid. ${coatype} category needs to start with 6 and be a 5 digit account number.`
                break
            case "Fixed Asset":
                if ((code >= 12000 && code <= 12999)) validation.validated = true
                else validation.message = `Account number ${code} is invalid. ${coatype} category needs to start with 1 and be a 5 digit account number.`
                break
            case "Income":
                if ((code >= 40000 && code <= 40999)) validation.validated = true
                else validation.message = `Account number ${code} is invalid. ${coatype} category needs to start with 4 and be a 5 digit account number.`
                break
            case "Long Term Liability":
                if ((code >= 22000 && code <= 22999)) validation.validated = true
                else validation.message = `Account number ${code} is invalid. ${coatype} category needs to start with 2 and be a 5 digit account number.`
                break
            case "Other Asset":
                if ((code >= 13000 && code <= 13999)) validation.validated = true
                else validation.message = `Account number ${code} is invalid. ${coatype} category needs to start with 1 and be a 5 digit account number.`
                break
            case "Other Current Asset":
                if ((code >= 14000 && code <= 14999)) validation.validated = true
                else validation.message = `Account number ${code} is invalid. ${coatype} category needs to start with 1 and be a 5 digit account number.`
                break
            case "Other Current Liability":
                if ((code >= 23000 && code <= 23999)) validation.validated = true
                else validation.message = `Account number ${code} is invalid. ${coatype} category needs to start with 2 and be a 5 digit account number.`
                break
            case "Other Expense":
                if ((code >= 70000 && code <= 79999)) validation.validated = true
                else validation.message = `Account number ${code} is invalid. ${coatype} category needs to start with 7 and be a 5 digit account number.`
                break
            case "Other Income":
                if ((code >= 41000 && code <= 42999)) validation.validated = true
                else validation.message = `Account number ${code} is invalid. ${coatype} category needs to start with 4 and be a 5 digit account number.`
                break
            default:
                break
        }
        return validation
    }

    getSampleAccountCodes = (coatype) => {
        var instruction = `${coatype} codes are between `
        switch (coatype) {
            case "Accounts Payable":
                return `${instruction} 20000 & 20999`
            case "Accounts Receivable":
                return `${instruction} 10000 & 10999`
            case "Bank":
                return `${instruction} 11000 & 11999`
            case "Cost of Goods Sold":
                return `${instruction} 50000 & 59999`
            case "Credit Card":
                return `${instruction} 21000 & 21999`
            case "Equity":
                return `${instruction} 30000 & 39999`
            case "Expense":
                return `${instruction} 60000 & 69999`
            case "Fixed Asset":
                return `${instruction} 12000 & 12999`
            case "Income":
                return `${instruction} 40000 & 40999`
            case "Long Term Liability":
                return `${instruction} 22000 & 22999`
            case "Other Asset":
                return `${instruction} 13000 & 13999`
            case "Other Current Asset":
                return `${instruction} 14000 & 14999`
            case "Other Current Liability":
                return `${instruction} 23000 & 23999`
            case "Other Expense":
                return `${instruction} 70000 & 79999`
            case "Other Income":
                return `${instruction} 41000 & 42999`

            default:
                return ""
                break
        }
    }
}

function mapStateToProps(state) {
    return {
        bkStore: state.bookkeepingAppReducer,
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(SaveCOA))