import React from 'react';
import { VictoryBar, VictoryChart, VictoryAxis, VictoryTheme, VictoryLabel } from 'victory';
import { Text } from '../../Reusable/Text';
import hexToRgba from 'hex-to-rgba';

const ARAgingChart = (props) => {

    var arAgingTotal = props.arAgingReport?.arAgingTotal || {}

    function getAgingTotal(name) {
        return arAgingTotal.find(item => item.name == name)?.total || 0
    }

    const opacity = 1;
    const data = [
        { name: 'Current', total: getAgingTotal("Current"), color: hexToRgba('#52B788', opacity) },
        { name: "0-30", total: getAgingTotal("0-30"), color: hexToRgba('#4299E1', opacity) },
        { name: "31-60", total: getAgingTotal("31-60"), color: hexToRgba('#8740F6', opacity) },
        { name: "61-90", total: getAgingTotal("61-90"), color: hexToRgba('#1EC3FF', opacity) },
        { name: "90+", total: getAgingTotal("90+"), color: hexToRgba('#9370DA', opacity) }
    ];

    return (
        <div className="w-full max-w-2xl mx-auto" style={{ position: 'relative' }}>
            <div style={{ position: 'absolute' }}>
                <Text heading margin={'40px'}>AR Aging</Text>
            </div>
            <VictoryChart
                domainPadding={{ x: 25, y: [0, 20] }}
                theme={VictoryTheme.material}
                height={270}
                padding={{ top: 70, bottom: 30, left: 50, right: 50 }}
            >
                <VictoryAxis
                    tickValues={[1, 2, 3, 4, 5]}
                    tickFormat={["Current", "0 - 30", "30 - 60", "60 - 90", "90+"]}
                    style={{
                        tickLabels: { fontSize: 10, padding: 5 }
                    }}
                />
                <VictoryBar
                    data={data}
                    x="name"
                    y="total"
                    cornerRadius={{ top: 5 }}
                    barRatio={0.3}
                    style={{
                        data: { fill: ({ datum }) => datum.color }
                    }}
                    labels={({ datum }) => `$${(datum.total / 1000).toFixed(1)}k`}
                    labelComponent={<VictoryLabel dy={-10} style={{ fontSize: 16 }} />}
                />
            </VictoryChart>
        </div>
    );
};

export default ARAgingChart;