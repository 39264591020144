import hexToRgba from 'hex-to-rgba';
import React from 'react';
import styled, { keyframes } from 'styled-components'


// Use this component to pass a 'lucide-react' icon

const clickAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
`;

const IconWrapper = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: ${props => props.onClick && 'pointer'};
    transition: background-color 0.3s ease;
    border-radius: 50%;
    padding: 4px;
    margin: ${props => props.margin};
    z-index: ${props => props.zIndex};

    &:hover {
        background-color: ${props => hexToRgba(props.theme.colors.primary10, 0.2)};
    }

    &:active {
        animation: ${clickAnimation} 0.3s ease;
    }
`;

const StyledIcon = styled.span`
    color: ${props => props.onClick ? props.theme.colors.primary : props.color || hexToRgba(props.theme.colors.secondary, 0.5)};
    transition: color 0.3s ease;

    ${IconWrapper}:hover & {
        color: ${props => props.onClick && props.theme.colors.secondary};
    }

    svg {
        width: ${props => props.size}px;
        height: ${props => props.size}px;
        stroke-width: ${props => props.strokeWidth};
    }
`;

const FormattedIcon = ({ icon: Icon, ...props }) => {
    return (
        <IconWrapper onClick={props.onClick} margin={props.margin} zIndex={props.zIndex} title={props.title}>
            <StyledIcon as={Icon} {...props} />
        </IconWrapper>
    );
};

export default FormattedIcon;