import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Auth } from 'aws-amplify'
import styled, { withTheme } from 'styled-components'

// Components
import { Button } from '../../../Reusable/Button'
import { Text } from '../../../Reusable/Text'
import { LabeledInput } from '../../../Reusable/Input'
import { ErrorAlert } from '../../../Reusable/Alert'
import { Flex, FlexColumn } from '../../../Reusable/Container'
import { TextButton } from '../../../Reusable/Button'

class ChangePassword extends Component {
    state = {
        oldPassword: "",
        newPassword: "",
        confirmPassword: ""
    }
    
    handleChange = (event) => {
        if(event.target.id == this.state.errorField) this.setState({ errorField: "" }); // reset
        this.setState({ [event.target.id]: event.target.value });
    }
    
    handleSubmit = async () => {
        const { oldPassword, newPassword, confirmPassword } = this.state;
        if (!oldPassword) {
            this.setState({ errorField: "oldPassword", errorMessage: "Please enter your old password." });
            return;
        }
        if (!newPassword) {
            this.setState({ errorField: "newPassword", errorMessage: "Please enter your new password." });
            return;
        }
        if (this.getPasswordValidations().includes(false)) {
            this.setState({ errorField: "newPassword", errorMessage: "Please enter a valid password." });
            return;
        }
        if (!confirmPassword) {
            this.setState({ errorField: "confirmPassword", errorMessage: "Please confirm your new password." });
            return;
        }
        if (newPassword != confirmPassword) {
            this.setState({ errorField: "confirmPassword", errorMessage: "Passwords don't match." });
            return;
        }
        this.setState({loading: true});
        const user = await Auth.currentAuthenticatedUser();
        Auth.changePassword(user, oldPassword, newPassword)
            .then(data => {
                this.props.onSuccess();
            })
            .catch(error => {
                this.setState({ loading: false });
                console.log('error changing pwd', error);
                ErrorAlert({ description: error.message || "Error changing your password." });
            })
    }

    getPasswordValidations = () => {
        var { newPassword } = this.state;
        var validationArr = [];
        if((newPassword.length >= 8) && (newPassword.length < 25)) validationArr.push(true)
        else validationArr.push(false)
        const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        validationArr.push(specialChars.test(newPassword));
        validationArr.push(/^(?=.*[A-Z])/.test(newPassword));
        validationArr.push(/^(?=.*[a-z])/.test(newPassword));
        validationArr.push(/^(?=.*\d)/.test(newPassword));
        return validationArr;
    }

    pwdOverlay =  () => {
        const StyledOverlay = styled.div`
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 16px;

            position: absolute;
            width: 408px;
            height: 162px;

            background: conic-gradient(from 156.95deg at 92.52% 82.42%, #000000 0deg, #333333 360deg);
            border-radius: 4px;
            z-index: 100;
        `;
        const StyledUL = styled.ul`
            font-weight: 400;
            font-size: 16px;
            color: #FFFFFF;
            padding: 0 16px;
            list-style: none;
        `;
        const StyledSpan = styled.span`
            display: inline-block;
            width: 15px;
        `;
        const bullet = <StyledSpan>•</StyledSpan>
        const checkMark = <StyledSpan>✓</StyledSpan>
        const validations = this.getPasswordValidations();
        return (
            <StyledOverlay>
                <StyledUL className='checked-bullet'>
                    <li>{validations[0] ? checkMark : bullet} 8 to 25 characters</li>
                    <li>{validations[1] ? checkMark : bullet} Atleast 1 special character (@,#,$,%)</li>
                    <li>{validations[2] ? checkMark : bullet} Atleast 1 character from A-Z</li>
                    <li>{validations[3] ? checkMark : bullet} Atleast 1 character from a-z</li>
                    <li>{validations[4] ? checkMark : bullet} Atleast 1 character from 0-9</li>
                </StyledUL>
            </StyledOverlay>   
        )
    }

    handleOnKeyDown = (event) => { if (event.key === "Enter") this.handleSubmit() };

    render() {
        const { loading, showPwdOverlay, errorField, errorMessage } = this.state;
        return (
            <FlexColumn  gap='24px'>
                <Text size="20px">Change Password</Text>
                <LabeledInput
                    label="Old Password"
                    id="oldPassword"
                    type="new-password"
                    placeholder="Enter password"
                    onChange={this.handleChange}
                    onKeyDown={this.handleOnKeyDown}
                    error={errorField=="oldPassword"}
                    errorMessage={errorMessage}
                    style={{ width: '100%' }}
                    margin="0 0"
                />
                <div style={{ background: 'transparent' }}>
                    <LabeledInput
                        label="New Password"
                        id="newPassword"
                        type="new-password"
                        placeholder="Enter password"
                        onChange={this.handleChange}
                        onKeyDown={this.handleOnKeyDown}
                        error={errorField=="newPassword"}
                        errorMessage={errorMessage}
                        style={{ width: '100%' }}
                        margin="0 0"
                        onFocus={() => this.setState({ showPwdOverlay: true })}
                        onBlur={() => this.setState({ showPwdOverlay: false })}
                    />
                    {showPwdOverlay && this.pwdOverlay()}
                </div>
                <LabeledInput
                    label="Confirm Password"
                    id="confirmPassword"
                    type="new-password"
                    placeholder="Enter password"
                    onChange={this.handleChange}
                    onKeyDown={this.handleOnKeyDown}
                    error={errorField=="confirmPassword"}
                    errorMessage={errorMessage}
                    style={{ width: '100%' }}
                    margin="0 0 0px"
                />
                <Flex start centerHorizontally gap='24px'>
                    <Button permtype="Override" solid loading={loading} onClick={() => this.handleSubmit()} text={'Confirm'.toUpperCase()} />
                    <TextButton permtype="Override" onClick={() => this.props.closeModal()} text={'Cancel'.toUpperCase()} />
                </Flex>
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(withTheme(ChangePassword))