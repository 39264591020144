import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'
import moment from 'moment'
import { Modal, message } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'

import { Skeleton, Tooltip, Tabs, Collapse } from 'antd'
import { FlexColumn, Flex, CardContainer, LightContainer } from '../../Reusable/Container'
import { Text, Tag } from '../../Reusable/Text'
import { Image } from '../../Reusable/Image'
import { Button, TextButton } from '../../Reusable/Button'
import PageHeader from '../../Reusable/PageHeader'
import ListItem from '../../Reusable/ListItem'
import { ErrorAlert } from '../../Reusable/Alert'
import { uaApiPOST } from '../../../Utils/userAccess'
import { apiPOSTReq } from '../../../Utils/api'
import UserCard from '../Reusable/UserCard'
import { getUserApps, getApplicationDisplayName, getFeatureLimit, getResourcePerm, getBPAccountsWithAccess, roleHeirarchy, getFeaturePerm } from '../../../Utils/util'
import InviteUser from './InviteUser'
import ViewRole from './ViewRole'
import NewRole from './NewRole/Index'
// import UpdateAccess from './InviteUser/UpdateAccess'
import PermissionsCollapse from './PermissionsCollapse'
import environment from '../../../environment'
import Email from "../../../Images/email.svg"
import { ArrowRightOutlined, DownloadOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import _, { random } from 'underscore'
import ModalClose from '../../../Images/modal-close.png'
import Individual from '../../../Images/individual.png'
import IndividualRed from '../../../Images/individual-red.png'
import Question from '../../../Images/question-circle.png'

import { appNameMap, showCreditTab } from '../../../Utils/util'

// Actions
import { addDataToStore, SAVE_DATA } from '../../../Actions/actions'

const { TabPane } = Tabs
const { Panel } = Collapse
const dFormat = "MMM Do, YYYY"
const etTimeZone = "America/New_York"

class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            selectedTab: "active",
            rolesLoading: true,
            deleteRoleLoading: false,
            showLearnMore: false,
            gettingStarted: false,
            invitedUserCount: 0
        }
    }

    componentDidMount() {
        this.fetchRoles()

        // Fetch users for business
        // this.fetchData()

        var headers = {
        }

        apiPOSTReq(`${environment.uamBaseUrl}/business/admin/getAllApplications`, headers, {}, (err, resp) => {
            try {
                var data = resp || {}
                if (data.result) {
                    let applications = (data.applications || []).filter(x => x.applicationName !== 'Bookkeeping')
                    this.setState({ allApplications: applications })
                } else {
                    throw Error(data.error || data.responseMessage)
                }
            } catch (error) {
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })

        if (this.props.aionStore.activeUsers && this.props.aionStore.activeUsers.length > 0) {
            this.setState({ activeUsers: this.props.aionStore.activeUsers, inactiveUsers: this.props.aionStore.inactiveUsers })
            this.fetchData()
        } else {
            this.setState({ loading: true })
            this.fetchData()
        }

        if (this.props.location.state && this.props.location.state.gettingStarted) {
            this.setState({ showLearnMore: true, gettingStarted: true })
        }
        this.fetchOpenInvites()
    }

    fetchRoles() {
        const { aionStore } = this.props
        var { businessSubscriptionPlan } = aionStore
        businessSubscriptionPlan = businessSubscriptionPlan || {}

        console.log('fetchRoles businessSubscriptionPlan', businessSubscriptionPlan)

        apiPOSTReq(`${environment.uamBaseUrl}/uam/getRolesForBusiness`, {}, { PlanName: (businessSubscriptionPlan || {}).currentPlan }, (err, resp) => {
            try {
                var roles = resp.roles || []
                roles.sort(function (x, y) { return x.roleType == "Custom__Sys" ? 1 : y.roleType == "Custom__Sys" ? -1 : 0 })

                console.log("getRolesForBusiness", resp)

                // Revenue and Expenses Admin handling
                var revenueAndAdminRoles = []
                var indexesToRemove = []

                roles.forEach((role, i) => {
                    // Add App names to system roles
                    role.resourcePermissions.forEach(permission => {
                        if (!permission.nameSpace) {
                            permission.nameSpace = getApplicationDisplayName(role.applicationName) // getAppDisplayNameBySystemRole(role.roleName)
                        }
                    })

                    // Revenue and Expenses Admin handling
                    if (role.roleName === "Revenue and Expenses Admin") {
                        revenueAndAdminRoles.push(role)
                        indexesToRemove.push(i)
                    }
                })

                // Revenue and Expenses Admin handling
                if (revenueAndAdminRoles.length > 1) {
                    var revenueAndAdminRole = revenueAndAdminRoles[0]

                    for (var i = 1; i < revenueAndAdminRoles.length; i++) {
                        revenueAndAdminRole.resourcePermissions = revenueAndAdminRole.resourcePermissions.concat(revenueAndAdminRoles[i].resourcePermissions)
                    }

                    while (indexesToRemove.length) {
                        roles.splice(indexesToRemove.pop(), 1)
                    }

                    roles.push(revenueAndAdminRole)
                }

                var systemRoles = roles.filter(function (role) {
                    return role.roleType != "Custom__Sys" && role.roleName != "Staff"
                })

                var customRoles = roles.filter(function (role) {
                    return role.roleType === "Custom__Sys" && role.roleName != "Staff"
                })

                // var roleSections = []

                // roleSections.push({
                //     Header: "System Roles",
                //     Rows: systemRoles
                // })

                // if (customRoles.length > 0) roleSections.push({
                //     Header: "Custom Roles",
                //     Rows: customRoles
                // })

                console.log('UserAccess allRoles: ', roles)

                this.setState({ allRoles: roles, rolesLoading: false, systemRoles, customRoles })

                if (err) throw Error(err)
                if (!resp.result) throw Error(resp.responseMessage || resp.error)
            } catch (error) {
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    fetchData() {
        var userData = {}

        uaApiPOST("/user/admin/getUsersForBusinessEx", null, (err, resp) => {
            try {
                if (err) throw Error(err)
                if (resp.error) throw Error(resp.error)
                userData = resp
                console.log("UserAccess getUsersForBusiness userData", userData)
                loadUsers()
            } catch (error) {
                console.log("getUsersForBusiness error", error)
                ErrorAlert({ description: error.message })
            }
        })

        const loadUsers = () => {
            var registeredUsers = userData.registeredUsers || []
            var activeUserListData = []
            var inactiveUserListData = []
            var inactiveUsers = []
            registeredUsers.forEach(user => {
                const userInfo = user.userInfo
                const metaData = user.metaData || {}
                var assignedApplications = []
                var userRoleNames = []
                const userAccessInfoList = user.userAccessInfoList || []
                const assignedRoleNamesMap = userData.assignedRoleNamesMap || {}
                var roles = assignedRoleNamesMap[userInfo.email] || {}
                Object.keys(roles).forEach(applicationName => {
                    (roles[applicationName] || []).forEach(appRole => {
                        if (!userRoleNames.includes(appRole)) userRoleNames.push(appRole)
                    })
                })

                var userItem = {
                    roleTags: userRoleNames,
                    // permissionTags: ["View", "Manage"],
                    assignedApplications: assignedApplications,
                    FirstName: userInfo.firstName,
                    LastName: userInfo.lastName,
                    Name: `${userInfo.firstName} ${userInfo.lastName}`,
                    Email: userInfo.email,
                    Phone: userInfo.phone,
                    CreatedOn: userInfo.createdOn ? moment(userInfo.createdOn) : moment(metaData.createTime)
                }
                if (userInfo.enabled) activeUserListData.push(userItem)
                else {
                    inactiveUsers.push(user)
                    inactiveUserListData.push(userItem)
                }
            })

            console.log("UserAccess getUsersForBusiness activeUserListData", activeUserListData)

            this.props.dispatch(addDataToStore(SAVE_DATA, { activeUsers: activeUserListData, inactiveUsers: inactiveUserListData }))
            this.setState({ loading: false, activeUsers: activeUserListData, inactiveUsers: inactiveUserListData, userCount: userData.count })


            // activeUserListData.sort((a, b) =>  { return moment(b.CreatedOn) - moment(a.CreatedOn) })
            // inactiveUserListData.sort((a, b) =>  { return moment(b.CreatedOn) - moment(a.CreatedOn) })

            // var sections = []
            // sections.push({
            //     Header: "Active Users",
            //     Rows: activeUserListData
            // })
            // if (inactiveUserListData.length > 0) sections.push({
            //     Header: "Inactive Users",
            //     Rows: inactiveUserListData
            // })
            // this.setState({ loading: false, sections: sections, registeredUsers: registeredUsers })
        }
    }

    fetchPendingInvites = () => {
        var body = {
            "searchFilter": {
                "criteriaList": [
                    {
                        "fieldName": "Status",
                        "value": "Invited"
                    },
                    {
                        "fieldName": "Status",
                        "value": "Error"
                    },
                    {
                        "fieldName": "Status",
                        "value": "Manual Review"
                    },
                    {
                        "fieldName": "Status",
                        "value": "Denied"
                    }
                ]
            }
        }
        this.setState({ preboardUserLoading: true })
        apiPOSTReq(`${environment.uamBaseUrl}/getPreboardUser`, {}, body, (err, resp) => {
            try {
                this.setState({ preboardUserLoading: false })
                var data = resp || {}
                if (data.result) {
                    this.setState({ preboardUsers: data.preboardUsers })
                } else {
                    throw Error(data.error || data.responseMessage)
                }
            } catch (error) {
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    fetchOpenInvites = () => {
        var body = {
            "searchFilter": {
                "criteriaList": [
                    {
                        "fieldName": "Status",
                        "value": "Invited"
                    },
                    {
                        "fieldName": "Status",
                        "value": "Manual Review"
                    }
                ]
            }
        }
        this.setState({ loadingUserCount: true })
        apiPOSTReq(`${environment.uamBaseUrl}/getPreboardUser`, {}, body, (err, resp) => {
            try {
                this.setState({ loadingUserCount: false })
                var data = resp || {}
                if (data.result) {
                    console.log("invitedUserCount", data.count)
                    this.setState({ invitedUserCount: data.count || 0 })
                } else {
                    throw Error(data.error || data.responseMessage)
                }
            } catch (error) {
                console.log({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    resendInviteEmail = (preboardUser) => {
        
        var body = {
            "referenceNumber": preboardUser.inviteReferenceNumber
        }
        console.log("preboardUser body", body)
        // this.setState({ loading: true })
        apiPOSTReq(`${environment.uamBaseUrl}/resendPreboardUserInvite`, {}, body, (err, resp) => {
            try {
                if (err) throw Error(err)
                var data = resp || {}
                console.log("resendInviteEmail data", err, data)
                if (!data.result) throw Error(data.responseMessage || data.error)
                var preboardUsers = this.state.preboardUsers || []
                preboardUsers = preboardUsers.map(item => (item.inviteReferenceNumber == preboardUser.inviteReferenceNumber) ? data.preboardUser : item )
                this.setState({ loading: false, preboardUsers })
                message.success('Successfully resent email!')
            } catch (error) {
                this.setState({ loading: false })
                ErrorAlert({ description: error.message || "Sorry we had trouble processing your request. Please try again." })
            }
        })
    }

    handleTabChange = (key) => {
        this.setState({ selectedTab: key })
        if(key == "pending") {
            this.fetchPendingInvites()
        }
    }

    deleteRole = (roleName) => {
        apiPOSTReq(`${environment.uamBaseUrl}/uam/deleteRole`, {}, { roleName }, (err, resp) => {
            try {
                var data = resp || {}
                if (data.result) {
                    this.setState({ deleteRoleLoading: false, rolesLoading: true })

                    this.fetchRoles()
                } else {
                    throw Error(data.error || data.responseMessage)
                }
            } catch (error) {
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    handleUserInvite = () => {
        const { activeUsers, invitedUserCount } = this.state
        const userLimit = getFeatureLimit("AionApplications.Users.Users")
        const totalUserCount = (activeUsers || []).length + invitedUserCount
        console.log("userLimit", totalUserCount, userLimit)
        // if (totalUserCount >= userLimit) {
        //     ErrorAlert({ description: "You have reached the maximum user limit for your plan. Please reach out to contact@aionfi.com for limit increases." })
        //     return
        // }
        this.props.history.push({
            pathname: '/settings/invite-user',
            state: { outOfUsers: totalUserCount >= userLimit }
        })
    }

    getPBStatus = (status) => {
        if(status == "Manual Review") return "Processing"
        else return status
    }

    render() {
        const { store, aionStore, theme, pageTitle } = this.props
        var { loading, selectedTab, selectedRole, showLearnMore, activeUsers, inactiveUsers, systemRoles, customRoles, showViewRoleModal, gettingStarted, continueGettingStartedInvite, preboardUsers, invitedUserCount, loadingUserCount } = this.state
        var { UserInfo } = aionStore || {}
        UserInfo = UserInfo || {}
        const showCredit = showCreditTab(aionStore)
        const customRoleAccess = getFeaturePerm("AionApplications.Custom.Custom Roles")
        // console.log("showCustomRole", customRoleAccess)
        var Attributes = aionStore.Attributes || {}
        systemRoles = systemRoles ? systemRoles.map((role, i) => ({ ...role, id: roleHeirarchy[role.roleName] || i + 1 })) : []
        systemRoles.sort((a, b) => a.id - b.id)
        customRoles = customRoles || []
        const userLimit = getFeatureLimit("AionApplications.Users.Users")
        const businessSubscriptionPlan = aionStore.businessSubscriptionPlan || {}
        const totalUserCount = (activeUsers || []).length + invitedUserCount

        return (
            <>
                <FlexColumn start className='main-padding' fullHeight>
                    <PageHeader
                        titleText={"Users & Roles"}
                        back
                        desc={<span>View and manage users who have access to Aion</span>}
                        marginBottom='12px'
                        ctaContent={activeUsers && !loadingUserCount &&
                            <Tooltip
                                overlayInnerStyle={{ width: 180, minWidth: 180 }}
                                overlayStyle={{ width: 200, minWidth: 200 }}
                                overlayClassName='roles-tooltip'
                                placement="left"
                                arrowPointAtCenter={false}
                                title={
                                    <FlexColumn>
                                        {
                                            totalUserCount < userLimit ?
                                                <Text color='white' size='14px'>{totalUserCount} Active user{totalUserCount > 1 ? 's' : ''}</Text>
                                                :
                                                <Text width='180px' color='white' size='14px'>You have reached the maximum user limit for your plan. Please reach out to contact@aionfi.com for limit increases.</Text>
                                        }

                                    </FlexColumn>}>
                                <Flex>
                                    {
                                        totalUserCount < userLimit ?
                                            <>
                                                <Image src={Individual} />
                                                <Text weight='600' color='#318F2F'>{totalUserCount}</Text><Text>/{userLimit}</Text>
                                            </>
                                            :
                                            <>
                                                <Image src={IndividualRed} />
                                                <Text weight='600' color='#E52D2D'>{totalUserCount}</Text><Text color='#E52D2D'>/{Math.max(totalUserCount, userLimit)}</Text>
                                            </>
                                    }
                                </Flex>
                            </Tooltip>
                        }
                    />
                    <Flex between gap='24px' fullWidth margin='0 0 24px'>
                        <FlexColumn start fullWidth fullHeight>
                            <Tabs size="large" defaultActiveKey={selectedTab} onChange={this.handleTabChange}>
                                <TabPane tab="Active" key="active" />
                                <TabPane tab="Inactive" key="inactive" />
                                <TabPane tab="Invites" key="pending" />
                            </Tabs>

                            <CardContainer padding={loading ? '12px 24px' : '12px'} fullWidth fullHeight margin='24px 0'>
                                <Skeleton loading={loading} active title={true} paragraph={{ rows: 15 }}>
                                    <Flex start wrap>
                                        {
                                            selectedTab === 'active' && activeUsers && activeUsers.map(activeUser => {
                                                return <UserCard
                                                    user={activeUser}
                                                    allRoles={this.state.allRoles}
                                                    onUpdateRoles={() => {
                                                        this.setState({ loading: true })
                                                        this.fetchData()
                                                    }} />
                                            })
                                        }

                                        {
                                            selectedTab === 'inactive' && inactiveUsers && inactiveUsers.map(inactiveUser => {
                                                return <UserCard user={inactiveUser} allRoles={this.state.allRoles} />
                                            })
                                        }

                                        {
                                            selectedTab === 'pending' && preboardUsers && preboardUsers.map(preboardUser => {
                                                return (
                                                    <div style={{ width: '50%', padding: 12 }}>
                                                        <LightContainer>
                                                            <Flex fullWidth between centerHorizontally gap='12px'>
                                                                <Flex start bottom width='100%' centerHorizontally>
                                                                    <Text className='initial-back' light center>{`${preboardUser.email.charAt(0)}`.toUpperCase()}</Text>
                                                                    <FlexColumn fullWidth>
                                                                        <Text weight={500} style={{ overflowWrap: 'break-word' }}>{preboardUser.email}</Text>
                                                                        <Flex start fullWidth centerHorizontally gap='8px'>
                                                                            <Text lightText size='14px' style={{ overflowWrap: 'break-word' }}>Invited on {moment.utc((preboardUser.metaData.lastUpdatedTime)).tz(etTimeZone).format(dFormat)}</Text>
                                                                            { preboardUser.status == "Invited" && <a onClick={() => this.resendInviteEmail(preboardUser)}>Resend</a> }
                                                                        </Flex>
                                                                    </FlexColumn>
                                                                </Flex>
                                        
                                                                <Flex centerHorizontally end gap='8px'  style={{ minWidth: 150 }}>
                                                                    <Tag noCap key={preboardUser.email} background='#F2F2F2'>{this.getPBStatus(preboardUser.status)}</Tag>
                                                                </Flex>
                                                            </Flex>
                                                        </LightContainer>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Flex>
                                </Skeleton>
                            </CardContainer>
                        </FlexColumn>

                        <FlexColumn start>
                            <CardContainer style={{ minWidth: 296 }} width='296px' padding='0'>
                                <FlexColumn className='invite-users-back' style={{ width: '100%', height: '100%' }}>
                                    <FlexColumn start style={{ width: '100%', height: '100%', paddingBottom: 8 }}>
                                        <Text heading>Invite Users</Text>
                                        <Text style={{ marginTop: 5, marginBottom: 8 }}>Add new users to your Aion account</Text>
                                    </FlexColumn>
                                    <TextButton onClick={() => this.handleUserInvite()} rightIcon={<img width='24px' height='24px' src={Email} />} text="INVITE USER" />
                                </FlexColumn>
                            </CardContainer>

                            <CardContainer style={{ minWidth: 296, marginTop: 24 }} width='296px'>
                                <FlexColumn fullWidth>
                                    <FlexColumn start style={{ width: '100%', height: '100%', paddingBottom: 8 }}>
                                        <Flex between centerHorizontally>
                                            <Text heading>Standard Roles</Text>
                                        </Flex>
                                        <Text style={{ marginTop: 5, marginBottom: 24 }}>Click on a role to view it's details</Text>
                                        <Flex start wrap gap='8px'>
                                            {
                                                systemRoles && systemRoles.map(systemRole => {
                                                    return <Tag
                                                        onClick={() => {
                                                            this.setState({ selectedRole: systemRole, showViewRoleModal: true })
                                                        }}
                                                        noCap={true}
                                                        bordered
                                                        primary>
                                                        {systemRole.roleName}
                                                    </Tag>
                                                })
                                            }
                                        </Flex>
                                    </FlexColumn>
                                    {
                                        businessSubscriptionPlan.currentPlan === 'Launch' &&
                                        <TextButton margin='24px 0 0' weight='400' underline text='Learn more' onClick={() => this.setState({ showLearnMore: true })} rightIcon={<Image src={Question} />} />
                                    }
                                </FlexColumn>
                            </CardContainer>

                            {
                                customRoleAccess.view &&
                                <CardContainer style={{ minWidth: 296, marginTop: 24, marginBottom: 48 }} width='296px'>
                                    <FlexColumn fullWidth>
                                        <FlexColumn start style={{ width: '100%', height: '100%', paddingBottom: 8 }}>
                                            <Flex between centerHorizontally>
                                                <Text heading>Custom Roles</Text>
                                                {customRoleAccess.manage && <TextButton onClick={() => this.props.history.push('/settings/new-role')} leftIcon={<PlusOutlined />} text="ROLE" />}
                                            </Flex>
                                            <Text style={{ marginTop: 5, marginBottom: 24 }}>View and manage roles with custom permissions</Text>

                                            <Flex start wrap gap='8px'>
                                                {
                                                    customRoles.map(customRole => {
                                                        return <Tag onClick={() => {
                                                            this.setState({ selectedRole: customRole, showViewRoleModal: true })
                                                        }} bordered primary>{customRole.roleName}</Tag>
                                                    })
                                                }
                                            </Flex>
                                        </FlexColumn>
                                    </FlexColumn>
                                </CardContainer>
                            }
                        </FlexColumn>
                    </Flex>
                </FlexColumn>
                <Modal
                    visible={this.state.showInviteModal}
                    footer={null}
                    onCancel={() => { this.setState({ showInviteModal: false }) }}
                    width={616}
                    destroyOnClose={true}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <InviteUser
                        allRoles={this.state.allRoles}
                        modal={true}
                        closeModal={() => {
                            this.setState({ showInviteModal: false, loading: true })
                            this.fetchData()
                        }}
                        onUpdateRoles={this.onUpdateRoles} />
                </Modal>
                <Modal
                    visible={this.state.showCreateRoleModal}
                    footer={null}
                    onCancel={() => { this.setState({ showCreateRoleModal: false }) }}
                    width={750}
                    destroyOnClose={true}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <NewRole
                        allApplications={this.state.allApplications}
                        closeModal={() => {
                            this.setState({ showCreateRoleModal: false })
                        }}
                        onSubmit={() => {
                            this.setState({ rolesLoading: true })
                            this.fetchRoles()
                        }}
                    />
                </Modal>
                <Modal
                    visible={showViewRoleModal}
                    footer={null}
                    onCancel={() => { this.setState({ showViewRoleModal: false, selectedRole: null }) }}
                    width={900}
                    destroyOnClose={true}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <ViewRole
                        selectedRole={selectedRole}
                        closeModal={() => {
                            this.setState({ showViewRoleModal: false, selectedRole: null })
                        }}
                    />
                </Modal>
                <Modal
                    visible={showLearnMore}
                    footer={null}
                    onCancel={() => { this.setState({ showLearnMore: false }) }}
                    width={616}
                    destroyOnClose={true}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    {
                        continueGettingStartedInvite ?
                            <InviteUser
                                allRoles={this.state.allRoles}
                                modal={true}
                                closeModal={() => {
                                    this.setState({ showLearnMore: false, loading: true })
                                    this.fetchData()
                                }}
                                onUpdateRoles={this.onUpdateRoles} />
                            :
                            <FlexColumn start left>
                                <Text heading>Learn more about roles</Text>
                                <Text>Standard roles to manage access based on your users</Text>
                                <div style={{ background: '#E3E6EE', width: '100%', height: 1, margin: '24px 0' }} />
                                <Tag>Company Admin</Tag>
                                <Text size='14px' margin='4px 0 28px'>Provides all applicable product permissions available on your plan. This is typically used for authorized administrators who can conduct financial tasks on behalf of your business. </Text>
                                <Tag>Banking Admin</Tag>
                                <Text size='14px' margin='4px 0 28px'>Provides users an ability to transact on your bank account - create recipients, move money, deposit checks, and download statements or reports.</Text>
                                <Tag>Bookkeeper</Tag>
                                <Text size='14px' margin='4px 0 28px'>Perform read-only tasks on your bank account such as: view transactions, account balances and download statements. Depending on your plan, this role may also allow you to create bills and invoices.</Text>
                                <Tag>Card Admin</Tag>
                                <Text size='14px' margin='4px 0 28px'>Provide users the ability to issue a new card, lock, unlock, or cancel any corporate card, set or reset their own PIN, and view all your company’s card transactions.</Text>
                                <Tag>Cardholder</Tag>
                                <Text size='14px' margin='4px 0 28px'>Provide users the ability to lock or unlock their card, set or reset their PIN, and view their card transactions.</Text>
                                {
                                    gettingStarted &&
                                    <Button solid primary text='Continue' onClick={() => this.setState({ continueGettingStartedInvite: true })} />
                                }
                            </FlexColumn>
                    }
                </Modal>
            </>
        )
    }

    onUpdateRoles = (roles) => {
        this.setState({ allRoles: roles })
    }
}

function mapStateToProps(state) {
    return {
        store: state.bankingAppReducer,
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Index))