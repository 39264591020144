
module.exports = {

    flowViewsData: [
        {
            "FlowStep": "Apply.PersonalInfo",
            "Title": "Banking with Aion",
            "ButtonTitle": "NEXT",
            "DefaultUserInput": "NOTREQUIRED",
            "Step": "User Profile",
            "Shadow": true,
        },
        {
            "FlowStep": "Apply.Credentials",
            "Title": "Banking with Aion",
            "ButtonTitle": "SUBMIT",
            "DefaultUserInput": "NOTREQUIRED",
            "Step": "User Profile",
            "Shadow": true
        },
        {
            "FlowStep": "Apply.Submitted",
            "Title": "Banking with Aion",
            "Description": "",
            "ButtonTitle": "Done",
            "DefaultUserInput": "NOTREQUIRED",
            "HideButton": true,
            "BackButton": false
        }
    ]
}


