import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled, { withTheme } from 'styled-components'
import { CardContainer } from '../Reusable/CardContainer'
import { Flex, FlexColumn } from '../../Reusable/Container'
import BusinessLogo from '../../Reusable/BusinessLogo'
import { Text } from '../../Reusable/Text'
import { getDateInLocalTZ, getGreeting, toCurrency } from '../../../Utils/util'
import moment from 'moment'
import hexToRgba from 'hex-to-rgba'
import { Divider } from '../../Reusable/Input'
import { apiGET } from '../../../Utils/api'
import CardHeader from '../Reusable/CardHeader'
import FormattedIcon from '../Reusable/FormattedIcon'
import AppIcon from '../../../Images/product-menu/credit-menu-icon-active.png'
import { Button, TextButton } from '../../Reusable/Button'
import ArrowRight from "../../../Images/arrow-right.png"
import { Image } from '../../Reusable/Image'

import { Clock } from 'lucide-react'

class CreditFunds extends Component {
    state = {
        loading: true,
        arAdvances: []
    }

    componentDidMount() {
        this.fetchARAdvanceData()
        this.fetchAdvances()
    }

    fetchARAdvanceData = () => {
        const headers = {
            businesskey: this.props.store.BusinessUniqueKey
        }

        apiGET("/arfinancing/funds/view", headers, (err, resp) => {
            this.setState({ loading: false })
            try {
                const data = resp.data
                console.log("/arfinancing/funds/view", JSON.stringify(data))
                this.setState({ availableFunds: data })
            } catch (error) {
                console.log("ERRR", error.stack)
            }
        })
    }

    fetchAdvances() {
        const headers = {
            businesskey: this.props.store.BusinessUniqueKey
        }

        apiGET("/financing/advances/view", headers, (err, resp) => {
            try {
                const data = resp.data || {}
                this.setState({ arAdvances: data.ARAdvances || [] })
            } catch (error) {
                console.log("ERRR", error.stack)
            }
        })
    }

    render() {
        const { UAM } = this.props.store
        const { availableFunds, arAdvances } = this.state
        var { AvailableFunds, BorrowerAdvanceLimit, BorrowerAdvanceTaken, OverAdvanced } = availableFunds || {}

        return (
            <CardContainer small>
                <FlexColumn>
                    <CardHeader 
                        imgSrc={AppIcon}
                        titleText='Credit' 
                        desc='Overview'
                        path='credit/ar-credit/advances'
                    />
                    <FlexColumn style={{ padding: "20px 40px" }} gap='12px'>
                        <FlexColumn gap='8px'>
                            <Text size='24px' weight={600}>${toCurrency(AvailableFunds)}</Text>
                            <Text lightText size='16px'>Available funds</Text>
                            <TextButton disabled={OverAdvanced || UAM.aionCustomerSupportUser || UAM.aionUnderWriterUser} permtype="ARCredit.Advances" onClick={() => this.props.history.push('/credit/ar-credit/advances/request')} text='REQUEST FUNDS' rightIcon={<Image src={ArrowRight} />} />
                        </FlexColumn>
                        <Divider />
                        <FlexColumn gap='8px'>
                            <Text size='20px' weight={600}>${toCurrency(BorrowerAdvanceLimit)}</Text>
                            <Text lightText size='16px'>Limit</Text>
                        </FlexColumn>
                        <Divider />
                        <Flex between gap='16px'>
                            <FlexColumn gap='8px'>
                                <Text size='20px' weight={600}>${toCurrency(BorrowerAdvanceTaken)}</Text>
                                <Text lightText size='16px'>Outstanding principal</Text>
                            </FlexColumn>
                            {
                                (arAdvances || []).length > 0 &&
                                <FlexColumn gap='8px'>
                                    <Text size='20px' weight={600}>${toCurrency(arAdvances[arAdvances.length-1].AdvanceAmount)}</Text>
                                    <Flex start centerHorizontally gap='4px'>
                                        <FormattedIcon icon={Clock} size={15} /> 
                                        <Text lightText size='16px'>Recent draw</Text>
                                    </Flex>
                                </FlexColumn>
                            }
                        </Flex>
                        
                    </FlexColumn>
                </FlexColumn>
            </CardContainer>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(CreditFunds)))