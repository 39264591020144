import React, { Component } from 'react'
import hexToRgba from 'hex-to-rgba';
import styled, { withTheme } from 'styled-components';
import { Text } from './Text';
import { ImageButton } from './Button';
import { Flex } from './Container';
import Close from '../../Images/close-dark.svg'

class BoldBanner extends Component {

    render() {
        const StyledContainer = styled.div`
            position: relative;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            padding: ${props => props.padding || '12px 48px'};
            margin: ${props => props.margin || '5px 0'};
            background: ${props => props.alert ? '#FEF5F5' : props.background || '#6BECF6'};
            border-radius: 8px;
        `;

        const CloseButton = styled.div`
            background: none;
            border: none;
            color: white;
            font-size: 16px;
            cursor: pointer;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            &:hover {
                color: #ddd; // Lighter color on hover
            }
        `;

        return (
            <StyledContainer {...this.props}>
                <Flex center gap='8px' fullWidth centerHorizontally>
                    <Flex start gap='8px' centerHorizontally>
                        {this.props.icon}
                        <Text weight={400} color={this.props.alert ? '#E52D2D' : (this.props.color || this.props.theme.colors.primary2)} height="24px">
                            {this.props.message}
                        </Text>
                    </Flex>
                    {this.props.cta}
                </Flex>

                {
                    this.props.onClose &&
                    <CloseButton>
                        <ImageButton src={Close} onClick={this.props.onClose} />
                    </CloseButton>
                }
            </StyledContainer>
        )
    }
}

export default withTheme(BoldBanner);