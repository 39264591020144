import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';
import 'draft-js/dist/Draft.css';
import {
    ConfigProvider,
    Modal,
    Skeleton,
    Select, Input, message, Upload
} from 'antd';

import { LabeledInput } from '../../../Reusable/Input'
import { SignedLink } from '../../../Reusable/Link';
import { apiPOSTReq } from '../../../../Utils/api';
import environment from '../../../../environment';
import TableView from '../../../Reusable/TableView';
import { TextButton } from '../../../Reusable/Refresh/Button'
import { Text } from '../../../Reusable/Text'
import UploadIcon from '../../../../Images/upload.svg'
import { ErrorAlert } from '../../../Reusable/Alert';
import { Button } from '../../../Reusable/Button'

// Util
import { FlexColumn } from '../../../Reusable/Container';

// Actions

import 'react-multi-email/style.css';
import moment from 'moment';

const { Option } = Select
const { TextArea } = Input

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            documentsLoading: false,
            fileList: [],
        }
    }

    componentDidMount() {
        this.fetchBusinessDocs();
    }

    fetchBusinessDocs() {
        var { type } = this.props;
        console.log('businesskey', this.props.businessId)
        // Fetch payer list
        // Check session and fetch attributes
        const headers = {
            AionCurrentBiz: this.props.businessId
        }

        var url = (type == "Documents") ? `${environment.opsBaseUrl}/ops/fetchAllBusinessDocuments` : `${environment.opsBaseUrl}/ops/fetchBusinessDocuments`;

        apiPOSTReq(url, headers, { "clientBusinessId": this.props.businessId, rootType: (this.props.type != "Documents" ? 'Collateral View Report' : 'ControlCenter') }, (err, resp) => {
            try {
                const data = resp || {};
                console.log("/fetchBusinessDocuments", data)
                if (data.result) {
                    this.setState({ documentsLoading: true, businessDocs: data.businessDocuments })
                } else {
                    throw Error(data.error || data.responseMessage || "Could not fetch payers")
                }
            } catch (error) {
                console.log("ERRR getActivePayors", error, err, resp);
                // ErrorAlert({description: error.message})
            }
        })
    }

    handleNote = (event) => {
        const target = event.target;
        this.setState({ notes: target.value });
    }

    handleDocTypeSelection = (value) => {
        this.setState({ docType: value });
    }

    uploadProps = (doc) => {
        return {
            name: `business-docs`,
            action: `${environment.apiBaseUrl}/file/upload`,
            headers: {
                businesskey: this.props.businessId,
                jwt: this.props.aionStore.jwt
            },
            beforeUpload: (doc) => {
                var maxFileSize = 10240; // 10MB
                const uploadedFileSizeKiloBytes = doc.size / 1024
                if (uploadedFileSizeKiloBytes > maxFileSize) {
                    message.error(`File size is greater than 10MB`);
                    this.setState({ uploadLoading: false });
                    return false;
                }
            },
            onChange: (info) => {
                var { fileList } = info
                var savedFile = {}
                fileList.forEach(file => {
                    const { status, response, name, url } = file
                    if (status === "done") {
                        savedFile = {
                            fileName: name,
                            uri: (response.UploadedUrls || [])[0],
                        }
                        if (doc.type) savedFile.type = doc.type
                        // message.success(`${info.file.name} file uploaded successfully.`, 0.75);
                        doc.savedFile = savedFile
                        this.handleDocSubmit(doc)
                    } else if (status === 'error') {
                        message.error(`${info.file.name} file upload failed.`)
                    }
                });
                if (((Object.keys(savedFile)).length) !== 0) {
                    doc.savedFile = savedFile
                }
                this.setState({ [doc.name]: doc })
            },
            onRemove: (file) => {
                this.handleDeleteDoc(doc)
            }
        }
    }

    handleDocSubmit = (uploadItem) => {
        var { loadingItem } = uploadItem || {};
        var { notes, docType } = this.state;

        var businessDocument = {
            "name": "ControlCenter",
            "category": docType,
            "documentUrl": uploadItem.savedFile || {},
            "notes": notes
        };

        var body = {
            "businessDocument": businessDocument
        }
        console.log("handleSubmit DOC/SAVE uploadItem body", body);
        this.setState({ submitLoading: true, loadingItem: loadingItem });
        apiPOSTReq(`${environment.uamBaseUrl}/docs/save`, { AionCurrentBiz: this.props.businessId }, body, (err, resp) => {
            this.setState({ submitLoading: false, loadingItem: null });
            try {
                const data = resp || {};
                if (data.result) {
                    message.success(`File saved successfully.`, 0.75);
                    this.fetchBusinessDocs();
                    this.setState({ showUploadModal: false });
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/docs/save err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again" });
            }
        });
    }

    handleDeleteDoc = (uploadItem) => {
        var businessDocument = {
            "category": "ControlCenter",
            "name": uploadItem.name
        };

        var body = {
            "businessDocument": businessDocument,
            "documentId": uploadItem.documentId
        }

        console.log("handleSubmit DOC/delete uploadItem body", body);
        this.setState({ submitLoading: true });
        apiPOSTReq(`${environment.uamBaseUrl}/docs/delete`, { AionCurrentBiz: this.props.businessId }, body, (err, resp) => {
            this.setState({ submitLoading: false, loadingItem: null });
            try {
                const data = resp || {};
                if (data.result) {
                    message.success(`File Deleted successfully.`, 0.75);
                    this.fetchBusinessDocs();
                    let doc = this.state[uploadItem.name] || {};
                    doc.savedFile = {};
                    this.setState({ [doc.name]: doc });
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/docs/deleteUpload err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again" });
            }
        });
    }

    render() {
        const { customize, showUploadModal, docType, notes } = this.state
        const { aionStore, theme, type } = this.props
        var { Profile } = aionStore;
        var { BusinessInfo } = Profile || {};
        BusinessInfo = BusinessInfo || {};
        var columns = [];
        if (type == "Documents") {
            columns.push(
                {
                    title: 'Name',
                    render: (item) => { return item.name },
                    width: 100
                });
        }

        columns.push(
            {
                title: 'Type',
                render: (item) => { return (type == "Documents") ? item.category : item.name }
            },
            {
                title: type == "Documents" ? 'Uploaded On' : 'Generated on',
                render: (item) => { return moment(item.auditData.createTime).format('MMM DD, YYYY') }
            },
            {
                title: 'Notes',
                render: (item) => { return item.notes },
                width: 200
            },
            (type != "Documents") && {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
            },
            {
                title: 'Action',
                align: 'center',
                render: (item) => (item.documentUrl || {}).uri ? <SignedLink {...item.documentUrl} /> : ``
            }
        );

        var docTypeOptions = ["Financials", "Know Your Business", "Know Your Customer", "Others"];

        return (
            <FlexColumn>
                <ConfigProvider renderEmpty={customize && this.customizeRenderEmpty}>
                    <div className="config-provider" >
                        <Skeleton loading={!this.state.documentsLoading} active title={false} paragraph={{ rows: 4 }} className="skeleton-padding">
                            {
                                (type == "CollateralReports") &&
                                <TableView
                                    id="business-doc-table-2"
                                    className="borderless-table row-pointer"
                                    tableLayout='auto'
                                    columns={columns}
                                    dataSource={this.state.businessDocs}
                                    rowKey='id'
                                    titleText='Collateral Reports'
                                    pagination={false}
                                />
                            }
                            {
                                (type == "Documents") &&
                                <TableView
                                    id="business-doc-table-2"
                                    className="borderless-table row-pointer"
                                    tableLayout='auto'
                                    columns={columns}
                                    dataSource={this.state.businessDocs}
                                    rowKey='id'
                                    titleText='Documents'
                                    pagination={false}
                                    ctaContent={<div>
                                        <TextButton loading={this.state.uploading} text={"Upload Documents"} color="#40a9ff"
                                            onClick={() => {
                                                this.setState({ showUploadModal: true, docType: null, notes: null })

                                            }}
                                            rightIcon={<img width='24px' height='24px' src={UploadIcon} />} />
                                    </div>}
                                />
                            }
                        </Skeleton>
                    </div>
                    <Modal
                        visible={showUploadModal}
                        footer={null}
                        closable={false}
                        width={600}
                        style={{ top: 10 }}
                        destroyOnClose={true}
                    // onCancel={() => {
                    //     this.setState({ showUploadModal: false })
                    // }}
                    >
                        <FlexColumn style={{ margin: '0 10px', minHeight: "35vh", justifyContent: "space-between" }}>
                            <FlexColumn style={{ justifyContent: "flex-start" }}>
                                <Text heading>Upload Document</Text>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <LabeledInput
                                        label="Document Type"
                                        labelcolor={theme.colors.secondary3}
                                        id="docType"
                                        key="docType"
                                        type="select"
                                        placeholder="Select"
                                        className="no-left-padding"
                                        mode="single"
                                        allowClear
                                        value={docType}
                                        onChange={this.handleDocTypeSelection}
                                    >
                                        {docTypeOptions.map(item => (
                                            <Option key={item} value={item}>
                                                {item}
                                            </Option>
                                        ))}
                                    </LabeledInput>
                                    <LabeledInput
                                        label="Notes"
                                        type="text-area"
                                        id="notes"
                                        key="notes"
                                        placeholder={`Add a note`}
                                        onChange={this.handleNote}
                                        value={notes}
                                    />
                                    {
                                        (docType && notes) && <LabeledInput
                                            {...this.uploadProps({ name: docType })}
                                            type='upload'
                                        />}
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    <Button type="primary" style={{ alignSelf: 'center', width: '100%', margin: '10px 0' }} onClick={() => { this.setState({ showUploadModal: false }) }} text="Close" />                                </div>
                            </FlexColumn>
                        </FlexColumn>
                    </Modal>
                </ConfigProvider>

            </FlexColumn>
        );
    }
}

function mapStateToProps(state) {
    return {
        store: state.creditAppReducer,
        aionStore: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)));