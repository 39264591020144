/*** 
1. An address has to be selected from the dropdown for it to be considered valid. 
2. User can click and scroll in the input box to examine the address, but input will be cleared (and parent component notified) as soon as any modification is made.
***/
import React from "react";
import styled from 'styled-components';
import { Select } from 'antd';
import { addressObjectToStr, countryCodeToNameMap, countryNameToCodeMap } from "../../../Utils/util";
import { Flex, FlexColumn } from "../Container";
import { StyledSelect } from "./Input";

const { Option } = Select

/* global google */

class LocationSearchInput extends React.Component {
	// This method causes problem when an inital value is passed in to the component (needed when editing an existing address). Do we really need this?
	// (appended with an underscore to comment it out for now.)
	static getDerivedStateFromProps_(nextProps) {
		// Should be a controlled component.
		// console.log("getDerivedStateFromProps", nextProps)
		if ('value' in nextProps) {
			return {
				...(nextProps.value || {}),
			};
		}
		return null;
	}

	constructor(props) {
		super(props);
		const value = props.value || '';
		const address = props.address || {};
		this.state = {
			line1: value.line1,
			line2: value.line2,
			city: value.city,
			postalCode: value.postalCode,
			state: value.state,
			countrySubDivisionCode: value.countrySubDivisionCode,
			country: value.country,
			countryCode: value.countryCode,
			formData: {},
			validInput: !value.line1 && !value,
			...address
		};
		console.log("LocationSearchInput", address);
		this.autocomplete = null;
		this.autocompleteInput = React.createRef();
		this.autocomplete = null;
	}

	componentDidMount() {
		this.autocomplete = new google.maps.places.Autocomplete(this.autocompleteInput.current, { "types": ["geocode"] });
		this.autocomplete.addListener('place_changed', this.handlePlaceChanged);
	}

	handleTextChange = (event) => {
		var updatedState = this.state;
		updatedState[event.target.id] = event.target.value;
		if (event.target.id === "state") updatedState["countrySubDivisionCode"] = event.target.value;
		// console.log("LocationSearchInput updatedState", updatedState, event.target.value);
		this.setState(updatedState);
		this.props.getLocation(updatedState, this.state.formattedAddress);
	}

	handleSelectCountry = (country) => {
		var updatedState = this.state
		updatedState.country = country
		updatedState.countryCode = countryNameToCodeMap[country]
		console.log("handleSelectCountry: ", JSON.stringify(updatedState))
		this.setState(updatedState);
		this.props.getLocation(updatedState, this.state.formattedAddress);
	}

	triggerChange = changedValue => {
		// console.log("Trigger change called", changedValue)
		// Should provide an event to pass value to Form.
		const { onChange } = this.props;
		if (onChange) {
			onChange(Object.assign({}, this.state, changedValue));
		}
	};

	handlePlaceChanged = () => {
		var address = this.state;
		const place = this.autocomplete.getPlace();
		console.log("handlePlaceChanged", place)
		if (place.address_components) {
			// formData.address.meta = place;
			place.address_components.forEach(item => {
				if (item.types.includes("street_number")) {
					address["streetNumber"] = item.long_name;
				}
				if (item.types.includes("route")) {
					address["street"] = item.short_name;
				}
				address["line1"] = `${address["streetNumber"]} ${address["street"]}`
				if (item.types.includes("locality")) {
					address["city"] = item.long_name;
				}
				if (item.types.includes("administrative_area_level_1")) {
					address["state"] = item.long_name;
					address["countrySubDivisionCode"] = item.short_name;
				}
				if (item.types.includes("postal_code")) {
					address["postalCode"] = item.short_name;
				}
				if (item.types.includes("country")) {
					address["country"] = item.long_name;
					address["countryCode"] = countryNameToCodeMap[address["country"]]
				}
			})
			var formattedAddress = place.formatted_address;
			this.setState({ formattedAddress: formattedAddress, validInput: false, ...address })
			this.props.getLocation(address, formattedAddress)
			this.triggerChange({ address });
		}
	}

	render() {
		var { line1, line2, city, countrySubDivisionCode, state, postalCode, country } = this.state;
		const { bordered, nextLine, lineWidth } = this.props;
		// if (bordered) Object.assign(fieldStyle, {
		// 	border: "1px solid rgba(0, 0, 0, 0.1)",
		// 	borderRadius: "3px",
		// 	padding: "15px 10px",
		// 	fontSize: "0.8rem",
		// 	height: 33,
		// 	background: "#fff"
		// })

		var countryItems = []
		Object.values(countryCodeToNameMap).forEach(item => {
			countryItems.push(
				<Option key={item} value={item}>
					{item}
				</Option>
			)
		})

		if (nextLine) {
			return (
				<Flex style={{ width: '100%' }}>
					<FlexColumn start style={{ width: '50%', paddingTop: 12 }}>
						<input
							className="location-input"
							onFocus={() => { }}
							type="text"
							placeholder="Street"//{this.props.placeholder}
							id="line1"
							ref={this.autocompleteInput}
							style={{ }}
							value={this.state.line1 || ''}
							onChange={this.handleTextChange}
							autocomplete='off'
							data-lpignore="true"
						/>
						<input
							className="location-input"
							type="text"
							placeholder="Suite/Floor (e.g. Suite 600)"
							id="line2"
							style={{ marginTop: 2, marginBottom: 2 }}
							value={line2 || ''}
							onChange={this.handleTextChange}
							autocomplete='off'
							data-lpignore="true"
						/>

						<input
							className="location-input"
							type="text"
							placeholder="City"
							id="city"
							style={{ }}
							value={city || ''}
							onChange={this.handleTextChange}
							autocomplete='off'
							data-lpignore="true"
						/>
					</FlexColumn>
					<FlexColumn start style={{ width: '50%', paddingTop: 12 }}>
						<input
							className="location-input"
							type="text"
							placeholder="State"
							id="state"
							style={{ }}
							value={countrySubDivisionCode || state}
							onChange={this.handleTextChange}
							maxLength="2"
							autocomplete='off'
							data-lpignore="true"
						/>
						<input
							className="location-input"
							type="text"
							placeholder="Postal Code"
							id="postalCode"
							style={{ marginTop: 2 }}
							value={postalCode || ''}
							onChange={this.handleTextChange}
							autocomplete='off'
							data-lpignore="true"
						/>
						{
							this.props.country &&
							<StyledSelect
								id="country"
								style={{ color: 'black', fontSize: "1.0rem", padding: '0 0 0 0', marginLeft: 0, width: "100%" }}
								// {...props}
								bordered={false}
								className="no-left-padding"
								placeholder='Country'
								optionFilterProp="children"
								onChange={value => { this.handleSelectCountry(value) }}
								value={country && country}
								showSearch
								filterOption={(input, option) =>
									option.children.toLowerCase().startsWith(input.toLowerCase())
								}
								autocomplete='off'
								data-lpignore="true"
							>
								{countryItems}
							</StyledSelect>
						}
					</FlexColumn>
				</Flex>
			);
		} else {
			return (
				<FlexColumn style={{ width: '100%' }} left>
					<input
						className="location-input"
						// onDrag={(e) => e.preventDefault()}
						// onDrop={(e) => e.preventDefault()}
						// onPaste={(e) => e.preventDefault()} // This disables pasting text in to this field
						// autoComplete="off"
						onFocus={() => { }}
						type="text"
						placeholder="Street"//{this.props.placeholder}
						id="line1"
						ref={this.autocompleteInput}
						value={this.state.line1 || ''}
						onChange={this.handleTextChange}
						autocomplete='off'
						data-lpignore="true"
					// ref={this.props.inputRef}
					/>
					<input
						className="location-input"
						type="text"
						placeholder="Suite/Floor (e.g. Suite 600)"
						id="line2"
						value={line2 || ''}
						onChange={this.handleTextChange}
						autocomplete='off'
						data-lpignore="true"
					/>
					<input
						className="location-input"
						type="text"
						placeholder="City"
						id="city"
						value={city || ''}
						onChange={this.handleTextChange}
						autocomplete='off'
						data-lpignore="true"
					/>
					<Flex style={{ width: '100%' }} between>
						<input
							className="location-input"
							type="text"
							placeholder="State"
							id="state"
							value={countrySubDivisionCode || state}
							onChange={this.handleTextChange}
							maxLength="2"
							autocomplete='off'
							data-lpignore="true"
						/>
						<input
							className="location-input"
							type="text"
							placeholder="Postal Code"
							id="postalCode"
							value={postalCode || ''}
							onChange={this.handleTextChange}
							autocomplete='off'
							data-lpignore="true"
						/>
					</Flex>
					{
						this.props.country &&
						<Select
							id="country"
							style={{ color: 'black', fontSize: "1.0rem", paddingLeft: 0, marginLeft: 0, width: "100%" }}
							// {...props}
							bordered={false}
							className="no-left-padding"
							placeholder={<span style={{ color: '#757575' }}>Country</span>}
							optionFilterProp="children"
							onChange={value => { this.handleSelectCountry(value) }}
							value={country && country}
							showSearch
							filterOption={(input, option) =>
								option.children.toLowerCase().startsWith(input.toLowerCase())
							}
							autocomplete='off'
							data-lpignore="true"
						>
							{countryItems}
						</Select>
					}
				</FlexColumn>
			);
		}
	}
}
export default LocationSearchInput;