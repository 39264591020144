import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

// Components
import { FlexColumn } from '../Reusable/Container'
import { Text } from '../Reusable/Text'
import { Descriptions, Popover } from 'antd'
import styled from 'styled-components'
import { InfoCircleOutlined, UpOutlined, DownOutlined } from '@ant-design/icons'

// Util
import { toCurrency, getUserApps } from '../../Utils/util'


export const DescItem = styled.div`
    display: flex;
    justify-content: flex-end;
`

export const DescLabel = styled.span`
    fontWeight: 500;
    fontSize: 16;
`

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
})

class AvailableFunds extends Component {
    constructor(props) {

        super(props)

        const userApps = getUserApps(this.props.aionStore)

        this.state = {
            grossAdvanceableExpandable: userApps.includes("ABLCredit"),
            grossAdvanceableExpanded: false,
        }
    }

    render() {
        const { grossAdvanceableExpanded, grossAdvanceableExpandable } = this.state;
        var { AvailableFunds } = this.props;
        var amount = '';
        if (AvailableFunds && AvailableFunds.OverAdvanced === true) {
            amount = AvailableFunds.OverAdvancedAmount ? AvailableFunds.OverAdvancedAmount.toString() : "0";
            amount = amount.replace('$', '');
            amount = formatter.format(amount);
        }

        const userApps = getUserApps(this.props.aionStore);

        return (
            <FlexColumn>
                <Text size='20px'>Available Funds</Text>
                <Descriptions
                    bordered
                    column={1}
                    style={{ width: '100%', marginTop: 24 }}
                >
                    <Descriptions.Item
                        label={(
                            <Popover content={(
                                <FlexColumn>
                                    <span><b>Calculation:</b> Sum of the gross advanceable amounts for each of your collateral</span>
                                </FlexColumn>
                            )}>
                                <div
                                    style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                                    onClick={() => { grossAdvanceableExpandable && this.setState({ grossAdvanceableExpanded: !grossAdvanceableExpanded }) }}
                                >
                                    <div style={{ display: "flex" }}><span>Gross Advanceable Collateral</span><InfoCircleOutlined style={{ marginLeft: 4, color: 'rgba(0,0,0,.45)' }} /></div> {grossAdvanceableExpandable && (grossAdvanceableExpanded === true ? <UpOutlined /> : <DownOutlined />)}
                                </div>
                            </Popover>
                        )}
                        labelStyle={{ fontWeight: 500, fontSize: 16 }}
                        contentStyle={{ fontSize: 16 }}
                    >
                        <DescItem>{AvailableFunds && !isNaN(AvailableFunds.GrossAdvanceableAssets) ? `$${toCurrency(AvailableFunds.GrossAdvanceableAssets)}` : "--"}</DescItem>
                    </Descriptions.Item>
                    {
                        grossAdvanceableExpanded === true &&
                        <>
                            <Descriptions.Item label={(<span style={{ paddingLeft: 25 }}>Gross Advanceable AR</span>)} labelStyle={{ fontSize: 14 }} contentStyle={{ fontSize: 14 }}>
                                <DescItem>{AvailableFunds && !isNaN(AvailableFunds.GrossAdvanceableAR) ? `$${toCurrency(AvailableFunds.GrossAdvanceableAR)}` : "--"}</DescItem>
                            </Descriptions.Item>
                            <Descriptions.Item label={(<span style={{ paddingLeft: 25 }}>Gross Advanceable Inventory</span>)} labelStyle={{ fontSize: 14 }} contentStyle={{ fontSize: 14 }}>
                                <DescItem>{AvailableFunds && !isNaN(AvailableFunds.GrossAdvanceableInventory) ? `$${toCurrency(AvailableFunds.GrossAdvanceableInventory)}` : "--"}</DescItem>
                            </Descriptions.Item>
                        </>
                    }
                    {
                        (userApps.includes("ARCredit")) &&
                        <Descriptions.Item label="- Receipts" labelStyle={{ fontSize: 16 }} contentStyle={{ fontSize: 16 }}>
                            <DescItem>{AvailableFunds && !isNaN(AvailableFunds.AggregatePayments) ? `$${toCurrency(AvailableFunds.AggregatePayments)}` : "--"}</DescItem>
                        </Descriptions.Item>
                    }
                    <Descriptions.Item label="- Adjustments" labelStyle={{ fontSize: 16 }} contentStyle={{ fontSize: 16 }}>
                        <DescItem>{AvailableFunds && !isNaN(AvailableFunds.Adjustments) ? `$${toCurrency(AvailableFunds.Adjustments)}` : "--"}</DescItem>
                    </Descriptions.Item>
                    <Descriptions.Item label={(
                        <Popover content={(
                            <FlexColumn>
                                <span><b>Calculation:</b> Gross advanceable collateral minus any adjustments</span>
                            </FlexColumn>
                        )}>
                            <div style={{ display: "flex" }}>
                                <DescLabel>Net Advanceable Collateral</DescLabel>
                                <InfoCircleOutlined style={{ marginLeft: 4, color: 'rgba(0,0,0,.45)' }} />
                            </div>
                        </Popover>
                    )} labelStyle={{ fontWeight: 500, fontSize: 16 }} contentStyle={{ fontSize: 16 }}>
                        <DescItem>{AvailableFunds && !isNaN(AvailableFunds.NetAdvanceableAssets) ? `$${toCurrency(AvailableFunds.NetAdvanceableAssets)}` : "--"}</DescItem>
                    </Descriptions.Item>
                    <Descriptions.Item label="- Outstanding Principal" labelStyle={{ fontSize: 16 }} contentStyle={{ fontSize: 16 }}>
                        <DescItem>{AvailableFunds && !isNaN(AvailableFunds.BorrowerAdvanceTaken) ? `$${toCurrency(AvailableFunds.BorrowerAdvanceTaken)}` : "--"}</DescItem>
                    </Descriptions.Item>
                    <Descriptions.Item label={(
                        <Popover content={(
                            <FlexColumn>
                                <span><b>Calculation:</b> Net advanceable collateral or credit limit,</span>
                                <span>whichever is lesser, minus the outstanding principal</span>
                            </FlexColumn>
                        )}>
                            <div style={{ display: "flex" }}>
                                <DescLabel>Available Funds</DescLabel>
                                <InfoCircleOutlined style={{ marginLeft: 4, color: 'rgba(0,0,0,.45)' }} />
                            </div>
                        </Popover>
                    )} labelStyle={{ fontWeight: 500, fontSize: 16 }} contentStyle={{ fontSize: 16 }}>
                        <DescItem>{AvailableFunds && !isNaN(AvailableFunds.AvailableFunds) ? `$${toCurrency(AvailableFunds.AvailableFunds)}` : "--"}</DescItem>
                    </Descriptions.Item>
                </Descriptions>
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.creditAppReducer,
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AvailableFunds))