import React, { useState, useEffect } from 'react'
import moment from 'moment'

import { Modal, Select, Skeleton, Popover } from 'antd'
import { Flex, FlexColumn, CardContainer, LightContainer } from '../../Reusable/Container'
import { KPILight } from '../../Reusable/KPI'
import { Text, Tag } from '../../Reusable/Text'
import { LabeledInput } from '../../Reusable/Input'
import { BasicOption } from '../../Reusable/Option'
import { apiPOSTReq, apiGETDocUrl } from '../../../Utils/api'
import environment from '../../../environment'


// Images
import CalendarIcon from '../../../Images/calendar-icon.png'


const { Option } = Select

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
})

function PostedTransactionsCard({ initialActivityDuration = "Last 7 days" }) {
    const [activityDuration, setActivityDuration] = useState(initialActivityDuration);
    const [activityLoading, setActivityLoading] = useState(true)
    const [sevenDayActivity, setSevenDayActivity] = useState(true)
    const [monthActivity, setMonthActivity] = useState(true)
    const [yearActivity, setYearActivity] = useState(true)

    useEffect(() => {
        getDailyTxnData()
    }, [])

    const getDailyTxnData = () => {
        setActivityLoading(true)

        apiPOSTReq(`${environment.bbBaseUrl}/bb/getDailyTxnData`, {}, { fromDate: moment().subtract(7, 'days').format("YYYY-MM-DD"), toDate: moment().format('YYYY-MM-DD') }, (err, resp) => {
            setActivityLoading(false)
            try {
                const data = resp || {}
                console.log('bb/getDailyTxnData 7 days', data)
                if (data.result) {
                    if (data.dailyTxnData) {
                        setSevenDayActivity(data.dailyTxnData || 0)
                    }
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("bb/getDailyTxnData err", error, resp)
                // ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })

        apiPOSTReq(`${environment.bbBaseUrl}/bb/getDailyTxnData`, {}, { fromDate: moment().subtract(1, 'months').format("YYYY-MM-DD"), toDate: moment().format('YYYY-MM-DD') }, (err, resp) => {
            try {
                const data = resp || {}
                console.log('bb/getDailyTxnData 1 month', data)
                if (data.result) {
                    if (data.dailyTxnData) {
                        setMonthActivity(data.dailyTxnData || 0)
                    }
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("bb/getDailyTxnData err 1 month", error, resp)
                // ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })

        apiPOSTReq(`${environment.bbBaseUrl}/bb/getDailyTxnData`, {}, { fromDate: moment().subtract(1, 'years').format("YYYY-MM-DD"), toDate: moment().format('YYYY-MM-DD') }, (err, resp) => {
            try {
                const data = resp || {}
                console.log('bb/getDailyTxnData 1 year', data)
                if (data.result) {
                    if (data.dailyTxnData) {
                        setYearActivity(data.dailyTxnData || 0)
                    }
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("bb/getDailyTxnData err 1 year", error, resp)
                // ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    return (
        <CardContainer
            padding='8px 24px'
            minWidth='456px'
            width='456px'
            height='200px'>
            <FlexColumn start>
                <Flex between centerHorizontally>
                    <Text heading>Posted Transactions</Text>
                    <div style={{ width: 191 }}>
                        <LabeledInput
                            prefixIcon={<img width='18px' height='18px' src={CalendarIcon} />}
                            type='select'
                            defaultValue={initialActivityDuration}
                            value={<Text>{activityDuration}</Text>}
                            onChange={(value) => setActivityDuration(value)}>
                            <Option value='Last 7 days' label='Last 7 days' style={{ backgroundColor: "transparent" }}>
                                <BasicOption value='Last 7 days' />
                            </Option>
                            <Option value='Last 30 days' label='Last 30 days' style={{ backgroundColor: "transparent" }}>
                                <BasicOption value='Last 30 days' />
                            </Option>
                            <Option value='Last 365 days' label='Last 365 days' style={{ backgroundColor: "transparent" }}>
                                <BasicOption value='Last 365 days' />
                            </Option>
                        </LabeledInput>
                    </div>
                </Flex>
                <Skeleton loading={activityLoading} active title={false} paragraph={{ rows: 3 }}>
                    <FlexColumn start style={{ width: '100%' }}>
                        {(() => {
                            switch (activityDuration) {
                                case 'Last 7 days':
                                    return <>
                                        <KPILight label='Money In' value={formatter.format(sevenDayActivity.creditsSum || 0)} valueColor='#318F2F' />
                                        <KPILight label='Money Out' value={formatter.format(Math.abs(sevenDayActivity.debitsSum || 0))} start margin='8px 0 0' />
                                    </>
                                case 'Last 30 days':
                                    return <>
                                        <KPILight label='Money In' value={formatter.format(monthActivity.creditsSum || 0)} valueColor='#318F2F' />
                                        <KPILight label='Money Out' value={formatter.format(Math.abs(monthActivity.debitsSum || 0))} start margin='8px 0 0' />
                                    </>
                                case 'Last 365 days':
                                    return <>
                                        <KPILight label='Money In' value={formatter.format(yearActivity.creditsSum || 0)} valueColor='#318F2F' />
                                        <KPILight label='Money Out' value={formatter.format(Math.abs(yearActivity.debitsSum || 0))} start margin='8px 0 0' />
                                    </>
                                default:
                                    return null
                            }
                        })()}
                    </FlexColumn>
                </Skeleton>
            </FlexColumn>
        </CardContainer>
    );
}

export default PostedTransactionsCard;