import React, { Component, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { Modal, Select, Menu, Dropdown, Tabs, Upload, Divider, message, Image } from 'antd';
import { PaperClipOutlined, SendOutlined, FilePdfOutlined, LoadingOutlined, CloseOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Text } from '../../Reusable/Text';
import { Flex, FlexColumn, Container, CardContainer } from '../../Reusable/Refresh/Container';
import { ErrorAlert } from '../../Reusable/Alert';
import { saveAs } from 'file-saver'

import { getPreSignedS3Url } from '../../../Utils/api'

// Actions
import { LabeledInput, } from '../../Reusable/Refresh/Input';
import { Button, TextButton } from '../../Reusable/Refresh/Button';
import environment from '../../../environment';
import { apiPOSTReq } from '../../../Utils/api';

import ChatIcon from '../../../Images/chat-icon.png';
import MessageIconWhite from '../../../Images/message-white.png';
import MessageIcon from '../../../Images/message.png';

import ModalClose from '../../../Images/modal-close.png';
import { isNull } from 'underscore';

const { Option } = Select;

const { TabPane } = Tabs;

const downloadAttachment = (url, name) => {
    getPreSignedS3Url({ url: url }, (err, preSignedUrl) => {
        if (!err) {
            saveAs(preSignedUrl, name)
        } else {
            ErrorAlert({ description: JSON.stringify(err) })
        }
    })
}

const Message = (props) => {
    const messageRef = useRef(null);

    useEffect(() => {
        messageRef.current.scrollIntoView({ behavior: 'smooth' });
    });
    const isSameUser = (props.businesskey === props.sender.userOrGroupBusinessId);
    const attachedURLs = props.message && props.message.attachmentUrls;
    return <>
        <FlexColumn ref={messageRef} style={{ marginBottom: '30px', borderRadius: '8px', alignItems: isSameUser ? 'flex-end' : 'flex-start' }}>
            <div style={{
                backgroundColor: isSameUser ? "white" : '#F4F5F7',
                padding: '8px 16px',
                maxWidth: "526px",
                boxShadow: isSameUser && "0px 4px 16px rgba(111, 111, 111, 0.08)",
                borderRadius: "8px"
            }}>
                <Flex style={{ justifyContent: "right", fontWeight: 600, flexDirection: "column" }}>
                    {
                        props.message && props.message.body &&
                        <Text style={{ fontSize: "16px", color: "#444444", marginBottom: 8, marginTop: 8 }} weight="400">{props.message && props.message.body}</Text>
                    }

                    {
                        attachedURLs && attachedURLs.length > 0 &&
                        <Flex wrap start style={{ width: '100%', padding: "4px", marginTop: 8, gap: 12 }}>
                            {
                                attachedURLs.map((file, i) => {
                                    return (
                                        <TextButton
                                            breakWord
                                            preLine
                                            onClick={() => console.log(downloadAttachment(file.url, file.fileName))}
                                            noBorder padding='4px 8px' radius='4px' background='#F8F8F8' key={i}
                                            icon={<FilePdfOutlined />} text={file.fileName} />
                                    );
                                })
                            }
                        </Flex>
                    }
                    <Text style={{ fontSize: "12px", align: "right", color: "#CBCED6", textAlign: "right" }} weight="400">{props.creationDate && moment(props.creationDate).format("LT")}</Text>
                </Flex>
            </div>
        </FlexColumn>
    </>
}

const File = (props) => {
    const isFilesAttached = props.message.attachmentUrls.length;
    const attachedURLs = props.message.attachmentUrls;

    return <>
        {isFilesAttached ?
            <FlexColumn start style={{ marginBottom: 20 }}>
                <Text style={{ fontSize: "14px", align: "right", color: "#CBCED6" }} weight="400">{props.creationDate && moment(props.creationDate).format("LL")}</Text>
                {
                    attachedURLs && attachedURLs.length > 0 &&
                    <Flex wrap start style={{ width: '100%', padding: "4px", marginTop: 8, gap: 12 }}>
                        {
                            attachedURLs.map((file, i) => {
                                return (
                                    <TextButton
                                        preLine
                                        breakWord
                                        onClick={() => console.log(downloadAttachment(file.url, file.fileName))}
                                        noBorder height='32px' padding='4px 8px' radius='4px' background='#F8F8F8' key={i}
                                        icon={<FilePdfOutlined />} text={file.fileName} />
                                );
                            })
                        }
                    </Flex>
                }
            </FlexColumn>
            : null}
    </>
}


class Chat extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showChatModal: false,
            activeKey: "conversation",
            pagination: {
                current: 1,
                pageSize: 500
            },
            conversationItems: [],
            filteredCoversationItems: {},
            fileList: [],
            sumbitted: false,
            uploading: false,
            loading: false,
            unreadMessagesCount: 0,
            submitLoading: false
        }
    }
    componentDidMount() {
        this.fetchConversationList({ pagination: this.state.pagination });
        // poll api every 15seconds for the incoming messages
        if ((this.props.loanApp || {}).applicationId) {
            this.interval = setInterval(() => {
                window.sessionStorage.setItem("initialRender", false);
                this.fetchConversationList({ pagination: this.state.pagination });
            }, 15000);
        }
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }
    fetchConversationList = (options) => {
        const { pagination } = options;
        const { loanApp } = this.props;

        const body = {
            "size": pagination.pageSize,
            "page": pagination.current - 1,
            "applicationId": loanApp.applicationId,
        }
        this.setState({ loading: true });

        // apiPOSTReq(`${environment.opsBaseUrl}/ops/getLoanApplicationMessages`, {}, body, (err, resp) => {
        apiPOSTReq(`${environment.opsBaseUrl}/ops/getLoanApplicationMessages`, null, body, (err, resp) => {
            this.setState({ loading: false });
            try {
                let data = resp || {};

                if (data.result) {
                    const filteredItemsByDate = data.conversationItems.reverse().reduce((obj, item) => {
                        var creationDate = moment(item.creationDate).format('D MMMM YYYY');
                        if (obj[creationDate]) {
                            obj[creationDate].push(item);

                            return obj;
                        }
                        obj[creationDate] = [
                            { ...item }
                        ];
                        return obj;
                    }, {});
                    this.setState({
                        conversationItems: data.conversationItems.reverse(),
                        filteredCoversationItems: filteredItemsByDate,
                        pagination: {
                            ...pagination,
                            total: data.count
                        }
                    })
                    // to set previous conversation message initially
                    if (JSON.parse(window.sessionStorage.getItem("initialRender")) || isNull(JSON.parse(window.sessionStorage.getItem("initialRender")))) {
                        window.sessionStorage.setItem("initialRender", false);
                        // removes messages sent by sender
                        let oldMessages = this.state.conversationItems.filter(item => {
                            if (item.sender.userOrGroupBusinessId !== this.props.loanApp.businessId) {
                                return item;
                            }
                        })
                        localStorage.setItem('oldConversationMessages', JSON.stringify(oldMessages));
                        data = window.localStorage.getItem('oldConversationMessages');
                        this.setState({ oldConversationMessages: JSON.parse(data) });
                    }
                    if (this.state.showChatModal) {
                        this.setState({ unreadMessagesCount: 0 });
                        // removes messages sent by sender
                        let oldMessages = this.state.conversationItems.filter(item => {
                            if (item.sender.userOrGroupBusinessId !== this.props.loanApp.businessId) {
                                return item;
                            }
                        })
                        localStorage.setItem('oldConversationMessages', JSON.stringify(oldMessages));
                        data = window.localStorage.getItem('oldConversationMessages');
                        this.setState({ oldConversationMessages: JSON.parse(data) });
                    } else {
                        let newlyReceivedMessages = this.state.conversationItems.filter(item => {
                            if (item.sender.userOrGroupBusinessId !== this.props.loanApp.businessId) {
                                return item;
                            }
                        })
                        let oldMessagesLength = JSON.parse(window.localStorage.getItem('oldConversationMessages')).length;

                        if (oldMessagesLength < 0) {
                            localStorage.setItem('oldConversationMessages', JSON.stringify([]));
                            data = window.localStorage.getItem('oldConversationMessages');
                        }
                        //let MessagesCount = newlyReceivedMessages.length - JSON.parse(window.localStorage.getItem('oldConversationMessages')).length;
                        let count = 0;

                        newlyReceivedMessages.map(item => {
                            if (!item.isRead) {
                                count = count + 1;
                            }
                        })
                        this.setState({ unreadMessagesCount: count });
                    }

                    if (this.state.unreadMessagesCount > 0) {
                        this.setState({ showChatDropdown: true })
                    }
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getLoanApplicationMessages", error, err, resp);
                this.setState({ loading: false })
                // ErrorAlert({ description: error.message })
            }
        })
    }

    createNewMessage = () => {
        const { loanApp, store } = this.props;
        const { UAM } = store;

        const body = {
            applicationId: loanApp.applicationId,
            message: {
                body: this.state.body,
                attachmentUrls: this.state.fileList.map(x => { return { fileName: x.fileName, url: x.uri } })
            },
            underwriter: UAM.aionUnderWriterUser,
        }

        this.setState({ submitLoading: true })
        // apiPOSTReq(`${environment.opsBaseUrl}/ops/newLoanApplicationMessage`, {}, body, (err, resp) => {
        apiPOSTReq(`${environment.opsBaseUrl}/ops/newLoanApplicationMessage`, null, body, (err, resp) => {
            this.setState({ sumbitted: true, submitLoading: false })
            try {
                const data = resp;
                if (data.result) {
                    this.setState({ subject: '', body: '', fileList: [], sumbitted: false });
                    this.fetchConversationList({ pagination: this.state.pagination })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR newLoanApplicationMessage", error, err, resp);
                ErrorAlert({ description: error.message })
            }
        })
    }



    handleDeleteDoc = (uploadItem) => {
        var { loanApplication } = this.state;
        var businessDocument = {
            "category": "PreScreen",
            "name": uploadItem.fileName
        };

        if (loanApplication) businessDocument.applicationId = loanApplication.applicationId;
        var body = {
            "businessDocument": businessDocument,
            "documentId": uploadItem.documentId
        }

        console.log("handleSubmit DOC/delete uploadItem body", uploadItem, body);
        this.setState({ deleteLoading: true });
        apiPOSTReq(`${environment.uamBaseUrl}/docs/delete`, {}, body, (err, resp) => {
            this.setState({ deleteLoading: false, loadingItem: null });
            try {
                const data = resp || {};
                if (data.result) {
                    message.success(`File saved successfully.`, 0.75);
                    // this.fetchDocuments();
                    let removedList = this.state.fileList.filter(item => {
                        if (item.fileName !== uploadItem.fileName) return item
                    });
                    this.setState({ fileList: removedList });
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/docs/deleteUpload err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }


    onChangeTab = (activeKey) => {
        this.setState({ activeKey });
        this.fetchConversationList({ pagination: this.state.pagination });
    }
    render() {
        var { theme, loanApp, store, applicationStep } = this.props;
        var { fileList, uploading, loading, submitLoading } = this.state;
        var { UserInfo } = store;

        var t = this;
        var applicationId = loanApp.applicationId;
        function openChat() {


            const body = {
                "size": t.state.pagination.pageSize,
                "page": t.state.pagination.current - 1,
                "applicationId": t.props.loanApp.applicationId,
            }

            // apiPOSTReq(`${environment.opsBaseUrl}/ops/updateLoanApplicationMessage`, {}, body, (err, resp) => {
            apiPOSTReq(`${environment.opsBaseUrl}/ops/updateLoanApplicationMessage`, null, body, (err, resp) => {
                try {
                    let data = resp || {};

                    if (data.result) {
                        console.log(data + "conosling the result updated")
                        t.setState({ showChatModal: true, unreadMessagesCount: 0 });
                        let oldMessages = t.state.conversationItems.filter(item => {
                            if (item.sender.userOrGroupBusinessId !== loanApp.businessId) {
                                return item;
                            }
                        })
                        window.localStorage.setItem('oldConversationMessages', JSON.stringify(oldMessages));
                        this.fetchConversationList();
                    }
                } catch (err) {

                }
            })
        }

        var uploadProps = {
            action: `${environment.apiBaseUrl}/file/upload`,
            headers: {
                AionCurrentBiz: this.props.store.BusinessUniqueKey,
                AionAuth: this.props.store.UAM.encryptedAuthHeader,
                businesskey: this.props.store.BusinessUniqueKey,
                jwt: this.state.jwt,
                username: UserInfo.Email || "",
            },
            disabled: uploading,
            showUploadList: false,
            multiple: true,
            beforeUpload: (doc) => {
                console.log('beforeUploadbeforeUploadbeforeUpload')
                var maxFileSize = 10240; // 10MB

                const uploadedFileSizeKiloBytes = doc.size / 1024

                if (uploadedFileSizeKiloBytes > maxFileSize) {
                    message.error(`File size is greater than 10MB`);
                    this.setState({ uploading: false });

                    return false;
                }
                this.setState({ uploading: true })
            },
            onChange: (info) => {
                var savedFileList = this.state.fileList;
                var file = info.file;
                console.log("info file", file);
                var { status, response, name, url } = file;
                if (status === "done") {
                    response = response || {};
                    savedFileList.push({
                        fileName: name,
                        uri: (response.UploadedUrls || response.uploadedUrls || [])[0],
                        file: this.props.includebase64 ? file : null
                    });
                }

                this.setState({ fileList: savedFileList.map(item => ({ ...item, url: item.uri })), uploading: false })
            },
            accept: ".jpeg,.jpg,.csv,.png,.doc,.xls,.xlsx,.pdf,.docx,.pptx,.txt"
        };
        return (
            <FlexColumn style={{ width: "100%" }}>

                <Modal
                    className='chat-modal'
                    visible={this.state.showChatModal}
                    width={936}
                    footer={false}
                    style={{ top: 20 }}
                    destroyOnClose={true}
                    closable={true}
                    onCancel={() => { this.setState({ showChatModal: false }) }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <FlexColumn between style={{ width: "100%" }}>
                        <Flex>
                            <Text size='20px' style={{ margin: "0 0px 10px 24px", width: "100%" }} weight="400">Conversation With Aion</Text>
                        </Flex>
                        <Tabs defaultActiveKey={this.state.activeKey} style={{ width: "100%", overflow: "hidden", padding: '0 24px' }} onChange={this.onChangeTab}>
                            <TabPane noMargin className="keep-scrolling" tab="Conversations" key="conversation" style={{ overflowY: "scroll", height: "700px", background: "linear-gradient(180deg, #FFFFFF 0%, #F4F5F7 100%)" }}>
                                {
                                    Object.entries(this.state.filteredCoversationItems).map(([key, value]) => {
                                        return <>
                                            <Flex style={{ justifyContent: "center", margin: "10px" }}>
                                                <Text style={{ fontSize: "14px", lineHeight: "24px", color: "#CBCED6", textAlign: "center" }} weight="400"> {key} </Text>

                                            </Flex>
                                            {
                                                value.map(message => {
                                                    return <>
                                                        <Message key={message.id} ref={message.id} {...message} theme={this.props.theme} businesskey={loanApp.businessId} />
                                                    </>
                                                })
                                            }
                                        </>
                                    })
                                }

                            </TabPane>
                            <TabPane noMargin tab="Files" className="keep-scrolling" key="file" style={{ overflowY: "scroll", height: "700px", background: "linear-gradient(180deg, #FFFFFF 0%, #F4F5F7 100%)" }} >
                                {this.state.conversationItems.map(message => {
                                    return <File key={message.id} {...message} theme={this.props.theme} />
                                })
                                }
                            </TabPane>
                        </Tabs>

                    </FlexColumn>
                    {
                        this.state.activeKey === "conversation" ? <Flex style={{ width: "100%", flexDirection: "column", }}>
                            <div className='chat-input-container'>
                                <Flex between centerHorizontally style={{ width: '100%' }}>
                                    <LabeledInput
                                        margin='0 8px 0 0'
                                        autoFocus
                                        labelcolor={theme.colors.secondary3}
                                        id="message"
                                        key=""
                                        placeholder="Enter message"
                                        value={this.state.body}
                                        onChange={e => this.setState({ body: e.target.value })}
                                        onKeyDown={(event) => { if (event.key === "Enter") this.createNewMessage() }}
                                    />

                                    <Upload
                                        {...uploadProps}
                                    >
                                        <TextButton loading={uploading} icon={<PaperClipOutlined style={{ fontSize: 20, width: 24, height: 24, margin: '0 12px', color: "#1199FF" }} />} />
                                    </Upload>

                                    <TextButton loading={submitLoading} style={{ textAlign: "left" }} type="link" icon={<SendOutlined style={{ fontSize: 18, width: 24, height: 24, color: "#1199FF" }} />} onClick={this.createNewMessage} ></TextButton>
                                </Flex>

                                <Flex wrap start style={{ width: '100%', padding: "4px", marginTop: fileList && fileList.length > 0 ? 8 : 0, gap: 12 }}>
                                    {
                                        fileList && fileList.map((file, i) => {
                                            return (
                                                <Button
                                                    onClick={() => console.log(downloadAttachment(file.url, file.fileName))}
                                                    noBorder height='32px' padding='4px 8px' radius='4px' background='#F8F8F8' key={i}
                                                    icon={<FilePdfOutlined />} rightIcon={<CloseOutlined onClick={() => this.handleDeleteDoc(file)} />} text={file.fileName} />
                                            );
                                        })
                                    }
                                </Flex>
                            </div>
                        </Flex> : null}

                </Modal>

                {this.state.unreadMessagesCount > 0 ?
                    <Dropdown
                        placement='bottomRight'
                        overlay={<div style={{
                            padding: 24, borderRadius: 8, alignSelf: 'center', background: "white", border: "none"
                        }}>
                            <Flex between style={{ width: '400px' }}>
                                <Flex start>
                                    <img width='24px' height='24px' src={MessageIcon} style={{ marginRight: "8px" }} />
                                    <Text color="#1199FF" weight="600" >You have {`${this.state.unreadMessagesCount} unread message${this.state.unreadMessagesCount > 1 ? 's' : ''}`}</Text>
                                </Flex>
                                <img width='24px' height='24px' src={ModalClose} onClick={() => { this.setState({ showChatDropdown: false }) }} />
                            </Flex>
                            <FlexColumn style={{ margin: "15px 0 6px 0" }}>
                                {
                                    <Text break width="352px"> {`${(this.state.conversationItems[0].message.body || "").trim().substr(0, 100)}${(this.state.conversationItems[0].message.body || "").trim().length > 100 ? "..." : ""}`}</Text>
                                }</FlexColumn>
                            <FlexColumn style={{ margin: "15px 0 0 0", }}>   {
                                this.state.unreadMessagesCount > 1 &&
                                <Text size="14px" color="#666"> +{this.state.unreadMessagesCount - 1} more</Text>
                            }
                            </FlexColumn>                                <TextButton underline color="#1199FF" style={{ textAlign: "left" }} onClick={() => openChat()} text={`View message${this.state.unreadMessagesCount > 1 ? 's' : ''}`}></TextButton>

                        </div>}
                        visible={this.state.showChatDropdown}
                    // visible={this.state.dropdownVisible}
                    >
                        <div onClick={() => openChat()}>
                            <p style={{ cursor: "pointer", fontSize: "14px", paddingTop: "16px", marginRight: "1px", color: "#374FEA", fontWeight: "600", textDecoration: "underline" }} >
                                <Image preview={false} style={{ height: '24px', width: '24px', display: "inline-block", marginRight: "3px" }} src={MessageIcon} />

                                {`${this.state.unreadMessagesCount} New Message${this.state.unreadMessagesCount > 1 ? 's' : ''}`}
                            </p></div>
                    </Dropdown>
                    :
                    <CardContainer style={{ width: 200, marginTop: 0, marginRight: 16, alignSelf: 'center', border: "none", boxShadow: "none" }}><TextButton underline icon={<img width='24px' height='24px' src={ChatIcon} color="white" />} style={{ textAlign: "left" }} onClick={() => openChat()} text={'Chat with us'}></TextButton></CardContainer>}



            </FlexColumn>
        );
    }


}
function mapStateToProps(state) {
    return {
        store: state.aionAppReducer,
        creditStore: state.creditAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Chat)));