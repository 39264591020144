import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import { FlexColumn } from '../../../Reusable/Container';
import { ErrorAlert } from '../../../Reusable/Alert';
import PageHeader from '../../../Reusable/PageHeader';
import environment from '../../../../environment';
import { apiPOSTReq } from '../../../../Utils/api';
import PayBill from '../PayBill';

class Pay extends Component {

    render() {

        return (
            <FlexColumn className='main-padding' fullWidth start grow>
                <PageHeader
                    back
                    backText='Bills'
                    titleText="Pay bill"
                />
                <PayBill bill={this.props.location.state.bill || {}} onPay={() => this.props.history.push('/payables/bills')} />
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        bkStore: state.bookkeepingAppReducer,
        store: state.aionAppReducer,
        bankingStore: state.bankingAppReducer,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Pay))