import React, { useState } from 'react'
import { message } from 'antd'
import moment from 'moment'

import { Text } from '../../Reusable/Text'
import { FlexColumn } from '../../Reusable/Container'
import { Button } from '../../Reusable/Button'
import { LabeledInput } from '../../Reusable/Input'
import environment from '../../../environment'
import { apiPOSTReq } from '../../../Utils/api'
import { ErrorAlert } from '../../Reusable/Alert'

const ActivateWithPin = (props) => {
    const [last4, setLast4] = useState()
    const [expDate, setExpDate] = useState()
    const [last4Error, setLast4Error] = useState(false)
    const [expDateError, setExpDateError] = useState(false)
    const [activateLoading, setActivateLoading] = useState(false)
    const [pin, setPin] = useState()
    const [pinError, setPinError] = useState()
    const [pinLoading, setPinLoading] = useState(false)
    const [confirmPin, setConfirmPin] = useState()
    const [confirmPinError, setConfirmPinError] = useState()

    const { selectedCard } = props

    const activateCard = () => {
        console.log('selectedCard', selectedCard)
        if (last4 !== selectedCard.last4 || moment(expDate, "MM/YY").year() !== moment(selectedCard.expirationDate).year() ||
            moment(expDate, "MM/YY").month() !== moment(selectedCard.expirationDate).month()) {
            ErrorAlert({ description: 'The information you have entered is incorrect, please try again.' })
            return
        } 
        if (isNaN(pin) || pin.length < 4) {
            setPinError(true)
            return
        }
        if (pin !== confirmPin) {
            setConfirmPinError(true)
            return
        }

        setPinError(false)
        setConfirmPinError(false)
        setActivateLoading(true)

        console.log('activateCard, ', selectedCard)

        apiPOSTReq(`${environment.bbBaseUrl}/bb/card/activateCard`, { "BankProvider": environment.bankProvider.crb }, { bbPersonCard: selectedCard }, (err, resp) => {
            setActivateLoading(false)
            try {
                const data = resp || {}
                if (data.result) {
                    setPinOnCard(selectedCard)
                    props.onActivated()
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/activateCard err", error, resp)
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    const setPinOnCard = (bbPersonCard) => {
        apiPOSTReq(`${environment.bbBaseUrl}/bb/card/setPinOnCard`, { "BankProvider": environment.bankProvider.crb }, { pin, bbPersonCard }, (err, resp) => {
            try {
                const data = resp || {}
                if (data.result) {
                    // this.setState({ showSetPinModal: false, pin: null, confirmPin: null, selectedCard: data.bbPersonCard })
                    // message.success('PIN set!')
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/bb/card/setPinOnCard err", error, resp)
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    return (
        <FlexColumn left gap='24px'>
            <Text heading>Activate Your Card</Text>
            {/* <Text style={{ marginBottom: 24, marginTop: 8 }}>Provide the following information to activate this card</Text> */}
            <LabeledInput
                nomargin
                label='Enter the last 4 digits of your card'
                id='last4'
                key='last4'
                placeholder='Enter the last 4 digits of your card number'
                value={last4}
                maxLength={4}
                onChange={e => setLast4(e.target.value)}
                error={last4Error}
                errorMessage={'Please enter the last 4 digits of your card number'}
            />
            <LabeledInput
                nomargin
                label='Expiry date'
                id='expiry-date'
                key='expiry-date'
                placeholder='Enter expiry date (MM/YY)'
                value={expDate}
                maxLength={5}
                onChange={e => setExpDate(e.target.value)}
                error={expDateError}
                errorMessage={"Please enter the expiry date of your card e.g 10/27"}
            />
            <LabeledInput
                nomargin
                label='Set your pin'
                type='password'
                id='debit-card-pin'
                key='debit-card-pin'
                placeholder='Enter PIN'
                value={pin}
                size={'large'}
                maxLength={4}
                onChange={e => setPin(e.target.value)}
                error={pinError}
                errorMessage={'Please enter a valid 4 digit PIN number'}
            />
            <LabeledInput
                nomargin
                label='Confirm pin'
                type='password'
                id='confirm-card-pin'
                key='confirm-card-pin'
                placeholder='Confirm PIN'
                value={confirmPin}
                size={'large'}
                maxLength={4}
                onChange={e => setConfirmPin(e.target.value)}
                error={confirmPinError}
                errorMessage={"PIN & confirmation PIN don't match"}
            />
            <Button
                solid
                margin='24px 0 0'
                radius='4px'
                text='ACTIVATE NOW'
                onClick={() => activateCard()}
                loading={activateLoading} />
        </FlexColumn>
    )
}

export default ActivateWithPin