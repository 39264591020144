import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';

import environment from '../../../environment';
import { toCurrency } from '../../../Utils/util';
import { apiPOSTReq, apiGET } from '../../../Utils/api';
import { ErrorAlert } from '../../Reusable/Alert';
import { uaApiPOST, getUserPermissions } from '../../../Utils/userAccess';

import {
    message,
    Menu,
    Dropdown,
    Tabs,
    Space
} from 'antd';
import moment from 'moment'
import { FlexColumn, Flex } from '../../Reusable/Container';
import { Text, Tag } from '../../Reusable/Text';
import PageHeader from '../../Reusable/PageHeader';
import TableView from '../../Reusable/TableView'
import { TextButton, ImageButton } from '../../Reusable/Button';
import ChevronDown from "../../../Images/chevron-down.svg"
import Switch from "../../../Images/switch.png"
import More from "../../../Images/more.png"
import { Image } from '../../Reusable/Image'
import { LabeledInput } from '../../Reusable/Input';
import { LoadingOutlined } from '@ant-design/icons';
import { completeSignin, SAVE_DATA, addDataToStore, UAM_SAVE_PERMISSIONS, UAM_SAVE_BUSINESS, resetStore, RESET_BNK_STATE, SOFT_RESET_MAIN_STATE, RESET_ONBOARDING_STATE, RESET_CREDIT_STATE, RESET_RECEIVABLES_STATE } from '../../../Actions/actions';

class Index extends Component {
    state = {
        tab: this.props.location.state?.tab || "RLOC",
        pagination: {
            current: 1,
            pageSize: 50
        }
    };

    componentDidMount() {
        this.fetchData({ ...this.state });
    }

    fetchData = (options) => {
        const { sortDirection, sortFieldname } = this.state;
        var { pagination, sorter, tab, searchTerm } = options || {};
        var body = {
            "viewBy": tab,
            "size": pagination.pageSize,
            "page": pagination.current - 1
        };

        sorter = sorter || {}
        if (sorter.field) {
            if (sorter.field === sortFieldname) {
                body.sortDirection = (sortDirection === "DESC") ? "ASC" : "DESC"
            } else {
                body.sortDirection = "ASC"
            }
            body.sortFieldname = sorter.field
        } else {
            if (sortFieldname) {
                body.sortFieldname = sortFieldname
            }
            body.sortDirection = sortDirection
        }

        if (searchTerm && searchTerm != "") {
            body.searchStr = searchTerm
        }

        this.setState({ loading: true });
        apiPOSTReq(`${environment.opsBaseUrl}/ops/fetchARBusiness`, null, body, (err, resp) => {
            this.setState({ loading: false });
            try {
                const data = resp || {};
                console.log("fetchARBusiness", resp);
                if (data.result) {
                    var businesses = [];
                    data.businesses = data.businesses || [];
                    data.controlCenterList = data.controlCenterList || [];
                    businesses = data.controlCenterList;
                    // data.businesses.forEach(business => {
                    //     if (business.aradvanceInfo) {
                    //         businesses.push({
                    //             'businessId': business.id,
                    //             'name': business.businessProfile.businessInfo.name,
                    //             'advanceLimit': toCurrency(business.aradvanceInfo.advanceLimit),
                    //             'fees': business.aradvanceInfo.fees,
                    //             'lineStatus': business.lineStatus,
                    //             'creditTaken': toCurrency(business.aradvanceInfo.creditTaken)
                    //         });
                    //     }
                    //     else if (business.advanceInfo) {
                    //         businesses.push({
                    //             'businessId': business.id,
                    //             'name': business.businessProfile.businessInfo.name,
                    //             'advanceLimit': toCurrency(business.advanceInfo.advanceLimit),
                    //             'fees': business.advanceInfo.fees,
                    //             'lineStatus': business.lineStatus,
                    //             'creditTaken': toCurrency(business.advanceInfo.creditTaken)
                    //         });
                    //     }
                    // });

                    var moreBusinesses = data.businesses.length == data.count ? false : true
                    this.setState({
                        businesses: businesses || [], pagination: { ...pagination, total: data.count }, sortDirection: body.sortDirection,
                        sortFieldname: body.sortFieldname, moreToLoad: moreBusinesses,
                    })
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    onTabChange = tab => {
        if (tab != this.state.tab) {
            this.setState({ tab: tab, businesses: [] });
            this.fetchData({ pagination: this.state.pagination, tab: tab });
            this.props.history.replace({
                pathname: '/underwriting/clients',
                state: { tab: tab }
            });
        }
    };

    loadMore = () => {
        this.fetchData({
            ... this.state,
            pagination: {
                current: this.state.pagination.current + 1,
                pageSize: 50
            }, tab: this.state.tab
        })
    }

    switchToBusiness(record) {
        // console.log("switchToBusiness", record);
        this.setState({ switchLoading: true, businessLoading: record });
        uaApiPOST("/aion/system/switchBusiness", { body: { businessId: record.businessId } }, (err, resp) => {
            try {
                var data = resp || {};
                if (err) throw Error(err);
                if (data.result) {
                    this.clearCache();
                    this.getAttributes();
                }
                else throw Error(data.error || data.responseMessage);
            } catch (error) {
                console.log("switchBusiness Err", error, err, resp);
                ErrorAlert({ description: error.message })
            }
        });
    }

    clearCache = () => {
        this.props.dispatch(resetStore(RESET_BNK_STATE))
        this.props.dispatch(resetStore(SOFT_RESET_MAIN_STATE))
        this.props.dispatch(resetStore(RESET_ONBOARDING_STATE))
        this.props.dispatch(resetStore(RESET_CREDIT_STATE))
        this.props.dispatch(resetStore(RESET_RECEIVABLES_STATE))
    }

    getAttributes() {
        // Check session and fetch attributes
        apiGET("/attributes", null, (err, resp) => {
            try {
                const data = resp.data;
                if (data.success) {
                    this.props.dispatch(completeSignin(data));
                    // Fetch Profile
                    this.fetchBusinessApps();
                } else {
                    throw Error("Could not fetch attributes.")
                }
            } catch (error) {
                console.log("/attributes ERR", error, err, resp)
            }
        })
    }

    fetchBusinessApps = () => {
        apiPOSTReq(`${environment.uamBaseUrl}/aion/system/getAvailableApplications`, {}, {}, (err, resp) => {
            try {
                if (err) throw Error(err);
                const data = resp || {};
                if (data.result) {
                    console.log("/aion/system/getAvailableApplications", data);
                    this.props.dispatch(addDataToStore(UAM_SAVE_PERMISSIONS, { BizAppNames: data.applicationNames || [] }));
                    this.fetchProfile();
                } else {
                    throw Error(data.responseMessage || data.error);
                }
            } catch (error) {
                console.log("/aion/system/getAvailableApplications err", error, resp);
                ErrorAlert({ description: error.message });
            }
        });
    }

    fetchProfile() {
        apiGET("/profile", null, (err, resp) => {
            try {
                const pData = resp.data || {};
                // this.setState({loading: false})
                if (pData.success) {
                    this.props.dispatch(addDataToStore(SAVE_DATA, { Profile: pData.profileInfo }));
                    var { BusinessInfo } = pData.profileInfo || {};
                    this.fetchPermissions()
                    this.fetchIntegration()
                    this.fetchBusinessData()
                    message.success(`Successfully switched to ${BusinessInfo.Name}`)
                    setTimeout(() => { window.location.reload() }, 1000);
                } else {
                    throw Error("Could not fetch profile.")
                }
            } catch (error) {
                console.log("ERRR", error.stack)
            }
        });
    }

    fetchPermissions = () => {
        // Fetch user permissions and adapt the layout based on the user's access
        getUserPermissions(null, (err, resp) => {
            try {
                if (err) throw Error(err)
                if (!resp.data.authToken) throw Error(resp.data.responseMessage)
                this.props.dispatch(addDataToStore(UAM_SAVE_PERMISSIONS, { UAM: resp.data }))
            } catch (error) {
                ErrorAlert({ description: error.message })
            }
        })
    }

    fetchIntegration = () => {
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/hasIntgration`, {}, {}, (err, resp) => {
            console.log("clear fetchIntegration", resp)
            try {
                if (err) throw Error(err)
                const data = resp || {}
                if (data.result) {
                    this.props.dispatch(addDataToStore(SAVE_DATA, { hasIntegration: data.hasIntegration || false }))
                    if (!data.hasIntegration) {
                        return
                    }

                    apiPOSTReq(`${environment.integrationBaseUrl}/integration/getCurrentSyncSW`, {}, {}, (err, resp) => {
                        try {
                            if (err) throw Error(err)
                            const data = resp || {}
                            if (data.result) {
                                this.props.dispatch(addDataToStore(SAVE_DATA, { CurrentSync: data.integration }))
                                this.props.dispatch(addDataToStore(SAVE_DATA, { hideNotification: data.hideNotification }))
                            } else {
                                throw Error(data.responseMessage || data.error)
                            }
                        } catch (error) {
                            console.log("/getCurrentSyncSW err", error, resp)
                        }
                    })
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/getCurrentSyncSW err", error, resp)
            }
        })
    }

    fetchBusinessData = (options) => {
        options = options || {};
        apiPOSTReq(`${environment.uamBaseUrl}/getBusiness`, {}, {}, (err, resp) => {
            try {
                if (err) throw Error(err);
                const data = resp || {};
                if (data.result) {
                    this.props.dispatch(addDataToStore(UAM_SAVE_BUSINESS, data));
                } else {
                    throw Error(data.responseMessage || data.error);
                }
            } catch (error) {
                console.log("/getBusiness err", error, resp);
            }
        });
    }

    getColumns = () => {
        var { tab } = this.state;

        if (tab == "RLOC") {
            return [
                {
                    title: 'Business Name',
                    width: "250px",
                    sorter: true,
                    showSorterTooltip: false,
                    render: item =>
                        <>
                            <a style={{ textDecoration: "underline" }}>{(item.businessName || "").toUpperCase()}</a>
                            {
                                item.frozen && <Tag style={{ marginLeft: 10 }}>Frozen</Tag>
                            }
                        </>,
                },
                {
                    title: 'DBA',
                    render: item => item.dbaName ? `${item.dbaName}` : "--"
                },
                {
                    title: 'Credit Limit',
                    // width: "200px",
                    render: item => `$${toCurrency(item.advanceLimit)}`
                },
                {
                    title: 'Daily Interest Rate',
                    // width: "200px",
                    render: item => `${parseFloat((item.fees).toFixed(6))}%`
                },
                {
                    title: 'Outstanding Balance',
                    // width: "200px",
                    render: item => `$${toCurrency(item.creditTaken)}`
                },
                {
                    title: 'Contract Start Date',
                    // width: "200px",
                    render: item => item.contractStartDate ? `${moment(item.contractStartDate).format('MMM DD, YYYY')}` : "--"
                },
                {
                    title: 'Contract Expires On',
                    // width: "200px",
                    render: item => item.contractEndDate ? `${moment(item.contractEndDate).format('MMM DD, YYYY')}` : "--"
                },
                {
                    title: 'Referral Code',
                    // width: "200px",
                    render: item => item.referralCode ? `${item.referralCode}` : "--"
                },
                {
                    width: 100,
                    render: item =>
                        <Flex fullWidth end centerHorizontally>
                            {
                                <Dropdown
                                    placement='bottomRight'
                                    trigger={['click']}
                                    key={`Dropdown`}
                                    overlay={<Menu style={{ padding: 24, borderRadius: 8, zIndex: 1001 }} key={`Menu`} onClick={() => { }}>
                                        <FlexColumn gap='24px'>
                                            <TextButton text='Switch to this Business' onClick={(event) => {
                                                this.switchToBusiness(item);
                                                event.stopPropagation();
                                            }
                                            } rightIcon={<Image src={Switch} />} />
                                        </FlexColumn>
                                    </Menu>}
                                >
                                    <ImageButton src={More}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                        }} />
                                </Dropdown>
                            }
                        </Flex>
                }
            ]
        } else {
            return [
                {
                    title: 'Business Name',
                    width: "250px",
                    sorter: true,
                    showSorterTooltip: false,
                    render: item =>
                        <>
                            <a style={{ textDecoration: "underline" }}>{(item.businessName || "").toUpperCase()}</a>
                            {
                                item.frozen && <Tag style={{ marginLeft: 10 }}>Frozen</Tag>
                            }
                        </>,
                },
                {
                    title: 'DBA',
                    render: item => item.dbaName ? `${item.dbaName}` : "--"
                },
                {
                    title: 'Credit Limit',
                    // width: "200px",
                    render: item => `$${toCurrency(item.advanceLimit)}`
                },
                {
                    title: 'Outstanding Balance',
                    // width: "200px",
                    render: item => `$${toCurrency(item.creditTaken)}`
                },
                {
                    title: 'Contract Start Date',
                    // width: "200px",
                    render: item => item.contractStartDate ? `${moment(item.contractStartDate).format('MMM DD, YYYY')}` : "--"
                },
                {
                    title: 'Contract Expires On',
                    // width: "200px",
                    render: item => item.contractEndDate ? `${moment(item.contractEndDate).format('MMM DD, YYYY')}` : "--"
                },
                {
                    title: 'Payment Plan',
                    // width: "200px",
                    render: item => item.paymentPlan ? `${item.paymentPlan}` : "--"
                },
                {
                    title: 'Monthly Subscription Amount',
                    // width: "200px",
                    render: item => `$${toCurrency(item.monthlySubscriptionAmount)}`
                },
                {
                    title: 'Commitment Fees',
                    // width: "200px",
                    render: item => `$${toCurrency(item.commitmentFees)}`
                },
                {
                    title: 'Referral Code',
                    // width: "200px",
                    render: item => item.referralCode ? `${item.referralCode}` : "--"
                },
                {
                    // width: 100,
                    render: item =>
                        <Flex fullWidth end centerHorizontally>
                            {
                                <Dropdown
                                    placement='bottomRight'
                                    trigger={['click']}
                                    key={`Dropdown`}
                                    overlay={<Menu style={{ padding: 24, borderRadius: 8, zIndex: 1001 }} key={`Menu`} onClick={() => { }}>
                                        <FlexColumn gap='24px'>
                                            <TextButton text='Switch to this Business' onClick={(event) => {
                                                this.switchToBusiness(item);
                                                event.stopPropagation();
                                            }
                                            } rightIcon={<Image src={Switch} />} />
                                        </FlexColumn>
                                    </Menu>}
                                >
                                    <ImageButton src={More}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                        }} />
                                </Dropdown>
                            }
                        </Flex>
                }
            ]
        }
    }

    render() {
        const { theme } = this.props;
        const { businesses, pagination, loading, searchLoading, searchTerm, tab, switchLoading, businessLoading } = this.state;
        return (
            <FlexColumn className='main-padding'>
                <PageHeader
                    titleText="Aion Credit"
                />
                <Tabs size="large" defaultActiveKey={tab} onChange={this.onTabChange}>
                    <Tabs.TabPane tab="RLOC Clients" key="RLOC" disabled={loading} />
                    <Tabs.TabPane tab="GNPL Clients" key="GNPL" disabled={loading} />
                </Tabs>
                {
                    !switchLoading && <TableView
                        id="control-center-table"
                        titleText={(tab == "RLOC") ? "RLOC Clients" : "GNPL Clients"}
                        tableLayout='auto'
                        columns={this.getColumns()}
                        dataSource={businesses}
                        rowKey='businessId'
                        pagination={false}
                        onChange={this.handleTableChange}
                        loading={loading}
                        ctaContent={
                            <FlexColumn>
                                <Space size="large">
                                    <LabeledInput
                                        style={{ width: "500px", fontSize: "14px" }}
                                        label="Search"
                                        labelcolor={theme.colors.secondary3}
                                        id="searchTerm"
                                        key="searchTerm"
                                        type="search"
                                        placeholder="Search by Business Name / DBA"
                                        className="no-left-padding"
                                        allowClear
                                        enterButton="Search"
                                        value={searchTerm}
                                        noAsterisk
                                        onChange={this.handleSearchTextChange}
                                        onSearch={(value, event) => {
                                            if (value && value.length > 0) {
                                                this.fetchData({
                                                    searchTerm: value, pagination: {
                                                        current: 1,
                                                        pageSize: 50
                                                    },
                                                    filterType: this.state.filterType,
                                                    tab: tab,
                                                })
                                            } else {
                                                this.fetchData({ searchTerm: "", pagination: this.state.pagination, filterType: this.state.filterType, tab: tab, });
                                            }
                                        }}
                                    />
                                </Space>
                            </FlexColumn>
                        }
                        onRow={
                            (record, rowIndex) => {
                                return {
                                    onClick: event => {
                                        this.props.history.push({
                                            pathname: `clients/creditdashboard`,
                                            state: { clientDetail: record, tab: tab }
                                        })
                                    }, // click row
                                }
                            }
                        }
                    />
                }
                {
                    this.state.moreToLoad &&
                    <Flex fullWidth centerVerticall style={{ marginTop: "10px" }}>
                        <TextButton text='LOAD 50 MORE' loading={loading} onClick={this.loadMore} rightIcon={<img style={{ cursor: 'pointer' }} width='24px' height='24px' src={ChevronDown} />} />
                    </Flex>
                }
                {
                    switchLoading &&
                    <Flex start centerHorizontally gap='8px' style={{ margin: '20px 0' }}>
                        <LoadingOutlined style={{ color: theme.colors.secondary3, fontSize: "1.5rem" }} />
                        <Text color={theme.colors.primary2}>
                            Switching to {businessLoading.businessName.toUpperCase()}...
                        </Text>
                    </Flex>

                }
            </FlexColumn>
        );
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)));