import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components';

// Components
import Search from './Search/Index';
import Profile from './Profile/Index';
import BankingApplication from './Banking Applications/Index';
import Invoices from './Invoices/Index';
import PurchaseOrders from './Purchase Orders/Index';
import AgingReports from './Aging Reports/Index';
import Inventory from './Inventory/Index';
import BankAccount from './Bank Accounts/Index';
import Restrictions from './Bank Accounts/Restrictions/Index';
import PaymentProof from './Payment Proof/Index';

class Index extends Component {

    render() {
        let { location } = this.props;
        return (
            <>
                <Switch location={location}>
                    <Route exact path="/support/search" component={Search} />
                    <Route exact path="/support/business-profile" component={Profile} />
                    <Route exact path="/support/bb-app" component={BankingApplication} />
                    <Route exact path="/support/invoices" component={Invoices} />
                    <Route exact path="/support/pos" component={PurchaseOrders} />
                    <Route exact path="/support/ars" component={AgingReports} />
                    <Route exact path="/support/inventory" component={Inventory} />
                    <Route exact path="/support/bb-acc" component={BankAccount}/>
                    <Route exact path="/support/bb-acc/restrictions" component={Restrictions}/>
                    <Route exact path="/support/payment-proof" component={PaymentProof} />
                </Switch>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)));