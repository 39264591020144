
module.exports = {
    flowViewsData: [
        {
            "FlowStep": "Apply.Splash",
            "Title": "Banking with Aion",
            "ButtonTitle": "NEXT",
            "DefaultUserInput": "NOTREQUIRED",
            "Step": "Splash Page",
            "Shadow": true,
            "ShowAionLogo": false,
        },
        {
            "FlowStep": "Apply.ProductInfo",
            // "Title": "Grow your business with Aion",
            "ButtonTitle": "NEXT",
            "DefaultUserInput": "NOTREQUIRED",
            "Step": "Business Profile",
            "Shadow": true,
            "ShowAionLogo": false,
        },
        {
            "FlowStep": "Apply.GetStarted",
            // "Title": "Grow your business with Aion",
            "ButtonTitle": "NEXT",
            "DefaultUserInput": "NOTREQUIRED",
            "Step": "Business Profile",
            "Shadow": true,
            "ShowAionLogo": false,
        },
        {
            "FlowStep": "Apply.PersonalInfo",
            "Title": "Pre-Qualify",
            "Progress": 0.1,
            "ButtonTitle": "NEXT",
            "DefaultUserInput": "NOTREQUIRED",
            "Step": "Applicant info",
            "AnimationWidth": 300,
            "Shadow": true,
            "ShowAionLogo": false,
        },
        {
            "FlowStep": "Apply.Lookup",
            "Title": "Pre-Qualify",
            "Progress": 0.1,
            "ButtonTitle": "NEXT",
            "DefaultUserInput": "NOTREQUIRED",
            "Step": "Business Profile",
            "AnimationWidth": 300,
            "Shadow": true,
            "ShowAionLogo": false,
        },
        {
            "FlowStep": "Apply.Basic",
            "Title": "Pre-Qualify",
            "Progress": 0.1,
            "ButtonTitle": "NEXT",
            "DefaultUserInput": "NOTREQUIRED",
            "NoInput": true,
            "Step": "Applicant info",
            "AnimationWidth": 300,
            "Shadow": true,
            "ShowAionLogo": false,
        },
        {
            "FlowStep": "Apply.Ownership",
            "Title": "Pre-Qualify",
            "Progress": 0.3,
            "ButtonTitle": "NEXT",
            "DefaultUserInput": "NOTREQUIRED",
            "NoInput": true,
            "Step": "Applicant info",
            "AnimationWidth": 300,
            "Shadow": true,
            "ShowAionLogo": false,
        },
        {
            "FlowStep": "Apply.Connection",
            "Title": "Pre-Qualify",
            "Progress": 0.6,
            "ButtonTitle": "NEXT",
            "SolidButton": true,
            "DefaultUserInput": "NOTREQUIRED",
            "NoInput": true,
            "Step": "Applicant info",
            "AnimationWidth": 300,
            "Shadow": true,
            "ShowAionLogo": false,
        },
        {
            "FlowStep": "Apply.Documents",
            "Title": "Pre-Qualify",
            "Progress": 0.6,
            "ButtonTitle": "REVIEW",
            "SolidButton": true,
            "DefaultUserInput": "NOTREQUIRED",
            "NoInput": true,
            "Step": "Applicant info",
            "AnimationWidth": 300,
            "Shadow": true,
            "ShowAionLogo": false,
        },
        {
            "FlowStep": "Apply.Review",
            "Title": "Review",
            "Description": "Please review the information you have provided before submitting your application.",
            "Progress": 0.6,
            "ButtonTitle": "SUBMIT",
            "DefaultUserInput": "NOTREQUIRED",
            "NoInput": true,
            "Step": "Applicant info",
            "AnimationWidth": 300,
            "Shadow": true,
            "ShowAionLogo": false,
        },
        {
            "FlowStep": "Apply.PreScreenSubmitted",
            "AnimationAspectFill": false,
            "Progress": 0.99,
            "ButtonTitle": "Done",
            "DefaultUserInput": "NOTREQUIRED",
            "HideButton": true,
            "BackButton": false,
            "ShowChat": true,
            "ShowAionLogo": false,
        },
        {
            "FlowStep": "Apply.ViewApplication",
            "AnimationAspectFill": false,
            "Progress": 1,
            "ButtonTitle": "Done",
            "DefaultUserInput": "NOTREQUIRED",
            "HideButton": true,
            "BackButton": false,
            "ShowChat": true,
            "ShowAionLogo": false,
        },
        {
            "FlowStep": "Apply.AdditionalInformation",
            "AnimationAspectFill": false,
            "Progress": 1,
            "ButtonTitle": "Done",
            "DefaultUserInput": "NOTREQUIRED",
            "HideButton": true,
            "BackButton": false,
            "ShowChat": true,
            "ShowAionLogo": false,
        }
    ],
    creditPlustFlowViewData: [
        {
            "FlowStep": "Apply.Splash",
            "Title": "Banking with Aion",
            "ButtonTitle": "NEXT",
            "DefaultUserInput": "NOTREQUIRED",
            "Step": "Splash Page",
            "Shadow": true,
            "ShowAionLogo": false,
        },
        {
            "FlowStep": "Apply.GetStarted",
            // "Title": "Grow your business with Aion",
            "ButtonTitle": "NEXT",
            "DefaultUserInput": "NOTREQUIRED",
            "Step": "Business Profile",
            "Shadow": true,
            "ShowAionLogo": false,
        },
        {
            "FlowStep": "Apply.Connection",
            "Title": "Pre-Qualify",
            "Progress": 0.6,
            "ButtonTitle": "NEXT",
            "SolidButton": true,
            "DefaultUserInput": "NOTREQUIRED",
            "NoInput": true,
            "Step": "Applicant info",
            "AnimationWidth": 300,
            "Shadow": true,
            "ShowAionLogo": false,
        },
        {
            "FlowStep": "Apply.Documents",
            "Title": "Pre-Qualify",
            "Progress": 0.6,
            "ButtonTitle": "SUBMIT",
            "SolidButton": true,
            "DefaultUserInput": "NOTREQUIRED",
            "NoInput": true,
            "Step": "Applicant info",
            "AnimationWidth": 300,
            "Shadow": true,
            "ShowAionLogo": false,
        },
        {
            "FlowStep": "Apply.PreScreenLoading",
            "AnimationAspectFill": false,
            "Progress": 0.99,
            "ButtonTitle": "Done",
            "DefaultUserInput": "NOTREQUIRED",
            "HideButton": true,
            "BackButton": false,
            "ShowChat": false,
            "ShowAionLogo": false,
        },
        {
            "FlowStep": "Apply.PreScreenSubmitted",
            "AnimationAspectFill": false,
            "Progress": 0.99,
            "ButtonTitle": "Done",
            "DefaultUserInput": "NOTREQUIRED",
            "HideButton": true,
            "BackButton": false,
            "ShowChat": true,
            "ShowAionLogo": false,
        },
    ]
}


