import React, { Component } from 'react'
import { useHistory, withRouter } from 'react-router-dom'

import { Modal, Select, Skeleton, Popover } from 'antd'
import Dimensions from 'react-dimensions'
import moment from 'moment';
import { Text } from '../../Reusable/Text'
import { KPILight } from '../../Reusable/KPI'
import { LabeledInput } from '../../Reusable/Input'
import { Flex, FlexColumn, CardContainer } from '../../Reusable/Container'
import { TextButton } from '../../Reusable/Button';
import RailCard from './RailCard'

// Images
import CalendarIcon from '../../../Images/calendar-icon.png'

const ActivityByRail = (props) => {
    const { card, onClick, account, activityLoading } = props

    const history = useHistory()

    return <Flex start centerHorizontally style={{ marginBottom: 24, marginTop: 24 }} fullWidth>
        <FlexColumn start fullWidth gap='24px'>
            <Skeleton loading={activityLoading || false} active title={false} paragraph={{ rows: 3 }}>
                <Flex start fullWidth centerHorizontally gap='24px'>
                    <div style={{ width: '50%' }}>
                        <KPILight label='Money In' value={'+ $44,391.00'} valueColor='#318F2F' />
                    </div>
                    <div style={{ width: '50%' }}>
                        <KPILight label='Money Out' value={'- $14,384.00'} />
                    </div>
                </Flex>

                <Flex start fullWidth gap='24px'>
                    <RailCard rail='Wire' width='25%' pending='$1,789.50' posted='$3,721.22' onClick={() => history.push({
                        pathname: `/banking/wire-transfers`,
                        state: { account }
                    })} />
                    <RailCard rail='ACH' width='25%' pending='$1,789.50' posted='$3,721.22' onClick={() => history.push({
                        pathname: `/banking/ach-transfers`,
                        state: { account }
                    })} />
                    <RailCard rail='Check' width='25%' pending='$1,789.50' posted='$3,721.22' onClick={() => history.push({
                        pathname: `/banking/check-transfers`,
                        state: { account }
                    })} />
                    <RailCard rail='Debit Cards' width='25%' pending='$1,789.50' posted='$3,721.22' onClick={() => history.push({
                        pathname: `/banking/card-transfers`,
                        state: { account }
                    })} />
                </Flex>
            </Skeleton>
        </FlexColumn>
    </Flex>
}

export default withRouter(ActivityByRail)