import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled, { withTheme } from 'styled-components'
import { Flex, FlexColumn } from '../../Reusable/Container'
import { Text } from '../../Reusable/Text'
import hexToRgba from 'hex-to-rgba'
import { Image } from '../../Reusable/Image'
import { AppImgContainer } from './CardContainer'
import { Divider } from '../../Reusable/Divider'


const StyledHeader = styled.div`
    background: ${props => props.theme.colors.container};
    border-top-right-radius: 24px;
    border-top-left-radius: 24px;
    border-bottom-right-radius: ${props => props.isCollapsed && '8px'};
    border-bottom-left-radius: ${props => props.isCollapsed && '8px'};
    border: ${props => props.borderView && `1px solid ${props.theme.colors.border}`};
    border-bottom: none;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
`;

const PrimaryContainer = styled(Flex)`
    padding: 8px;
    border-radius: 5px;
    flex-grow: 1;
    &:hover {
        background: ${props => hexToRgba(props.theme.colors.primary2, 0.06)};
        cursor: pointer;
    }
`;

class CardHeader extends Component {
    state = {
    }

    componentDidMount() {
        
    }

    render() {
        const { theme, titleText, desc, secondaryContent, imgSrc, path, borderView } = this.props
        const {  } = this.state

        return (
            <StyledHeader borderView={borderView}>
                <Flex centerHorizontally start gap='16px' style={{ padding: '15px 20px' }}>
                    <PrimaryContainer onClick={() => path ? this.props.history.push(path) : {}}  centerHorizontally start gap='8px'>
                        {
                            imgSrc &&
                            <AppImgContainer>
                                <Image height='28px' width='28px' src={imgSrc} />
                            </AppImgContainer>
                        }
                        <FlexColumn gap={desc && '6px'}>
                            <Text margin='0' weight={600} size='24px' color={theme.colors.primary2}>
                                {titleText}
                            </Text>
                            <Text color={theme.colors.defaultLightText}>
                                {desc}
                            </Text>
                        </FlexColumn>
                    </PrimaryContainer>
                    {secondaryContent || null}
                </Flex>
                <Divider margin='0' />
            </StyledHeader>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(CardHeader)))