import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { message, Skeleton, Space, Divider, Collapse, Steps, Badge, Table, Modal } from 'antd';
import { CaretRightOutlined, CheckCircleFilled, CheckCircleTwoTone, DollarCircleTwoTone, EyeTwoTone, FundTwoTone, LikeTwoTone, UserAddOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import OAuthClient from 'intuit-oauth';
import { PlaidLink } from "react-plaid-link";
import queryString from 'query-string';
import { Image } from '../../Reusable/Image';
import { FlowViewContainer } from '../../Reusable/FlowViewContainer';
import FlowViewComponent from '../../Reusable/FlowViewComponent';
import { Text, Tag } from '../../Reusable/Text';
import { InputContainer, Flex, FlexColumn, LightContainer } from '../../Reusable/Container';
import { ErrorAlert } from '../../Reusable/Alert';
import { flowViewsData, applyDoneStep } from './viewData';
import { apiGET, apiPOST, apiPOSTReq, apiPOSTURL } from '../../../Utils/api';
import environment from '../../../environment';
import Customers from '../../Receivables/Customers/Index';
import Conversation from '../../UnderwritingConsole/ApplicationDetail/Conversation/Index'

// Actions
import { addDataToStore, CREDIT_SAVE_DATA } from '../../../Actions/actions';

// import qbButton from '../../../Images/C2QB_auth.png'
import qbButton from '../../../Images/C2QB_green_btn_tall_hover.svg'
import creditGS from '../../../Images/credit_get_started.svg';
import lowPrice from '../../../Images/low-price.svg';
import iconKey from '../../../Images/icon-key.svg';
import iconSlider from '../../../Images/icon-slider.svg';
import iconCircularArrows from '../../../Images/icon-circular-arrows.svg';
import iconKeepDry from '../../../Images/icon-keep-dry.svg';
import iconOnlineMoneyTransfer from '../../../Images/icon-online-money-transfer.svg';
import iconPayDate from '../../../Images/icon-pay-date.svg';
import iconDurationFinance from '../../../Images/icon-duration-finance.svg';
import iconStackOfMoney from '../../../Images/icon-stack-of-money.svg';
import CheckGreen from '../../../Images/check-green.png'
import { Button } from '../../Reusable/Button';
import BusinessDocuments from '../../Reusable/BusinessDocuments';
import { InputConfirmation, LabeledInput } from '../../Reusable/Input';
import store from '../../../Reducers/store';
import moment from 'moment';
import Banner from '../../Reusable/Banner';
import hexToRgba from 'hex-to-rgba';
import { dateFormatList } from '../../../Utils/util';
import RejectedIcon from '../../../Images/rejected.png'
import ThumbsUp from '../../../Images/thumbs-up.png';
import CreditAppStatus from '../EligibilityRefresh/Reusable/CreditAppStatus';
import BankIcon from '../../../Images/bank-generic.png'
import Info from '../../Reusable/Info';

var request = require("request");

const dateFormat = 'MM/DD/YYYY';
const etTimeZone = "America/New_York";

class Index extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentViewIndex: 0,
            submitLoading: false,
            loading: false,
            requestFinancials: true,
            linkToken: ''
        }
        this.flowViews = props.creditEligibilityApplied ? applyDoneStep : flowViewsData.concat(applyDoneStep);
    }

    componentDidMount() {
        this.handleCallback();
        this.fetchBanks();
    }

    handleCallback = () => {
        const { store } = this.props;
        var { Attributes } = store;
        Attributes = Attributes || {};
        const businessAttributes = Attributes.Businesses || {};
        if (businessAttributes.CreditEligibilityApplied) {
            this.flowViews = applyDoneStep;
            this.setState({});
            return;
        }
        if (businessAttributes.CreditEligibilityApproved) {
            window.location.href = "/credit"
            return;
        }

        var value = "";
        if (!this.props.isCreditPlus) {
            value = queryString.parse(this.props.history.location.state?.data);
        }
        else {
            value = queryString.parse(this.props.location.search);
        }

        const state = value.state
        var url = "";
        if (!this.props.isCreditPlus) {
            url = this.props.history.location.state?.url
        } else {
            url = window.location.href;
        }

        console.log("/credit/eligibility componentDidMount", value, state, url, this.props.location.search)
        if (state && (state === 'qb-credit-eligibility' || state === 'qb-credit-plus')) {

            if (value.code && value.realmId) {

                const headers = {
                    businesskey: this.props.store.BusinessUniqueKey,
                    url: url
                }
                console.log("making api call for /oauth");
                this.setState({ loading: true });
                apiGET(`/financing/oauth/token?url=${url}`, headers, (err, resp) => {
                    console.log("making api call for /financing/oauth/token", err, resp);
                    try {
                        const token = resp.data.Response;
                        if (resp.data.success) {

                            const data = {
                                accessToken: token.access_token,
                                accessTokenExpiresIn: token.expires_in,
                                accessTokenSecret: "",
                                companyId: value.realmId,
                                accountingSoftware: "QuickBooksOnline",
                                dateCreated: Date.now(),
                                refreshToken: token.refresh_token,
                                refreshTokenExpiresIn: token.x_refresh_token_expires_in,
                                oauthVersion: "2.0",
                                //refreshTokens: true
                            }

                            apiPOST('/connect/business_software', headers, data, (err, resp) => {
                                this.setState({ loading: false });
                                if (!err) {
                                    try {
                                        const data = resp.data;
                                        if (data.success) {
                                            message.success('QuickBooks reconnected successfully!', 0.75);
                                            if (state === 'qb-credit-plus') {
                                                this.props.history.replace('/banking/home')
                                            }
                                            else {
                                                window.history.replaceState(null, '', '/credit/apply');
                                            }
                                            this.setState({ AccountingSWLinked: true });
                                            this.setState({ currentViewIndex: 3 });  // back to where we were
                                            if (!this.props.isCreditPlus) {
                                                window.location.reload();
                                            }
                                        } else {
                                            throw Error("Could not complete submission.")
                                        }
                                    } catch (error) {
                                        console.error("The error message is :" + JSON.stringify(error))
                                        ErrorAlert({ description: "Sorry, we had trouble processing your request. Please try again." });
                                        window.history.replaceState(null, '', '/credit/apply');

                                        this.setState({ currentViewIndex: 2 });//message.error('Sorry, we had trouble reconnecting your QuickBooks. Please try again.')
                                    }
                                } else {
                                    console.error("The error message is :" + JSON.stringify(err))
                                    message.error('Sorry, we had trouble reconnecting your QuickBooks. Please try again.')
                                }
                            })
                        } else {
                            this.setState({ loading: false });
                            console.error("The error message is :" + JSON.stringify(err))
                            message.error('Sorry, we had trouble reconnecting your QuickBooks. Please try again.')
                            throw Error("Could not fetch attributes.")
                        }
                    } catch (error) {
                        this.setState({ loading: false });
                        console.error("ERROR", error.stack)
                        message.error('Sorry, we had trouble reconnecting your QuickBooks. Please try again.')
                    }
                })
            }
        } else {
            this.fetchLoanApp();
            this.fetchBanks();
        }
        // this.fetchQBODocuments();
    }

    fetchBanks = () => {
        const headers = {}

        const body = {
            "BusinessId": this.props.store.businessId
        }
        this.setState({ loadingBanks: true })
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/plaid/getInstitutionInfo`, headers, body, (err, resp) => {
            try {
                const data = resp;
                this.setState({ banks: data.externalBBInstitutions })
                this.props.dispatch(addDataToStore(CREDIT_SAVE_DATA, data))
            } catch (error) {
                console.log("ERROR", error.stack)
            } finally {
                this.setState({ loadingBanks: false })
            }
        })
    }

    getPlaidLinkToken = () => {
        var header = {}
        var body = {
            "redirectUri": window.location.href,
            "BusinessId": this.props.store.businessId,
        }

        apiPOSTReq(`${environment.integrationBaseUrl}/integration/plaid/getLinkToken`, header, body, (err, resp) => {
            try {
                const response = resp || {};
                if (response.result) {
                    this.setState({ linkToken: response.linkToken })
                } else {
                    ErrorAlert({ description: response.responseMessage });
                }
            }
            catch (err) {
            }
        })
    }

    fetchLoanApp = () => {
        // Fetch document list
        var body = {
            "clientBusinessId": this.props.store.BusinessUniqueKey,
            "size": 100,
            "page": 0,
            // "sortDirection": "ASC"
        }
        this.setState({ loanAppLoading: true });
        apiPOSTReq(`${environment.opsBaseUrl}/ops/loans/application/getApplications`, {}, body, (err, resp) => {
            console.log("/getApplications resp body", resp, body);
            // this.setState({ loanAppLoading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    var loanApplications = data.loanApplications || [];
                    var loanApp = loanApplications.length > 0 ? loanApplications[loanApplications.length - 1] : {};
                    this.setState({ loanApplications: loanApplications, loanApplication: loanApp });
                    this.props.dispatch(addDataToStore(CREDIT_SAVE_DATA, { loanApplication: loanApp }));
                    // this.loadAppFromState();
                    this.fetchProposalTerms(loanApp);
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        })
    }

    loadAppFromState = () => {
        var { loanApplication } = this.props.creditStore;
        var { isCreditPlus } = this.props;
        loanApplication = loanApplication || {};
        console.log("loadAppFromState", loanApplication);
        var currentStep = 0;
        switch (loanApplication.userStatus) {
            case "Started":
                currentStep = 2;
                break;
            case "DueDiligenceStarted":
                currentStep = isCreditPlus ? currentStep = 1 : currentStep;
                break;
            case "AccountingSWConnected":
                currentStep = 3;
                break;
            case "BankingConnected":
                currentStep = 2;
                break;
            case "BizDocsUploaded":
                currentStep = 3;
                break;
            case "TopCustomerSubmitted":
                currentStep = 4;
                break;
            case "Completed":
                this.flowViews = applyDoneStep;
                this.setState({});
                return;
            default:
                break;
        }
        this.setState({ currentViewIndex: currentStep })
    }

    fetchProposalTerms = (loanApp) => {
        var body = {
            "clientBusinessId": loanApp.businessId,
            "applicationId": loanApp.applicationId,
        };
        console.log("/lsm/getProposalTerms body", body);
        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/getProposalTerms`, null, body, (err, resp) => {
            this.setState({ loanAppLoading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    console.log(data);
                    this.setState({
                        "proposalTerms": data.offers[0]
                    });

                    this.loadAppFromState();
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    fetchBankInfo() {
        const headers = {
            businesskey: this.props.store.BusinessUniqueKey
        }
        apiGET("/bankinfo", headers, (err, resp) => {
            try {
                const data = resp.data
                this.props.dispatch(addDataToStore(CREDIT_SAVE_DATA, data))
            } catch (error) {
                console.log("ERROR", error.stack)
            }
        })
    }

    generateExternalBankStatement(institutionId) {
        let { loanApplication } = this.props.creditStore;
        loanApplication = loanApplication || {};
        const body = {
            loanApplicationId: loanApplication.applicationId,
            institutionId,
        }

        apiPOSTReq(`${environment.integrationBaseUrl}/integration/plaid/getExternalBankTxn`, null, body, (err, resp) => {
            try {
                const data = resp || {};
                if (!data.result) {
                    ErrorAlert({ description: data.responseMessage || "Sorry, we had trouble processing your request. Please try again." });
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    fetchQBODocuments = () => {
        const headers = {
            businesskey: this.props.store.BusinessUniqueKey
        }
        console.log("fetchQBODocuments /arfinancing/financials");
        apiGET("/arfinancing/financials", headers, (err, resp) => {
            console.log("/arfinancing/financials", err, resp);
            try {
                const data = resp.data;
                this.props.dispatch(addDataToStore(CREDIT_SAVE_DATA, { qboReports: data }))
            } catch (error) {
                console.log("ERROR", error.stack)
            }
        })
    }

    getPlaidAccessToken = (public_token, metadata, includeAuth, accessToken) => {
        var header = {}
        var body = {
            "redirectUri": window.location.href,
            "plaidTokenData": {
                publicToken: public_token,
                accountId: metadata.account.id,
                accountName: metadata.account.name,
                institution: metadata.institution.name,
                institutionId: metadata.institution.institution_id,
            },
            "loggedInUserId": this.props.store.UserInfo.Email,
            "BusinessId": this.props.store.businessId,
            "includeAuth": includeAuth,
            "accessToken": accessToken

        }


        this.setState({ loadingBanks: true })
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/plaid/getAccessToken`, header, body, (err, resp) => {
            try {
                const response = resp || {};
                if (response.result) {
                    this.fetchBanks()
                    this.setState({ showBankDetails: false })
                } else {
                    ErrorAlert({ description: response.responseMessage });
                    this.setState({ loadingBanks: false })
                }
            }
            catch (err) {

            }

        })

    }

    handleBankDetails = (bankInfo) => {
        const headers = {}

        const body = {
            "itemId": bankInfo.itemId,
            "redirectUri": window.location.href,
            "connectionStatus": bankInfo.status

        }
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/plaid/getAccountDetails`, headers, body, (err, resp) => {
            try {
                const response = resp || {};
                if (response.result) {
                    this.setState({
                        accountDetails: response.accountsList, showBankDetails: true, bankStatus: bankInfo.status,
                        bankUrl: bankInfo.logoUrl, bankName: bankInfo.bankName, bankLastUpdated: bankInfo.lastUpdatedAt, relinkToken: response.linkToken,
                        bankAccessToken: bankInfo.accessToken, reconnectBank: false, itemId: bankInfo.itemId
                    })
                    if (bankInfo.status != "Connected") {
                        this.setState({ reconnectBank: true })
                    }
                } else {
                    ErrorAlert({ description: response.responseMessage });
                }
            }
            catch (err) {

            }

        })

    }

    plaidOnSuccess = (public_token, metadata) => {
        const headers = {
            businesskey: this.props.store.BusinessUniqueKey
        }
        var body = {
            data: {
                publicToken: public_token,
                accountId: metadata.account.id,
                accountName: metadata.account.name,
                institution: metadata.institution.name,
                institutionId: metadata.institution.institution_id,
            }
        }
        this.setState({ loading: true });
        apiPOST("/plaidauthenticate", headers, body, (err, resp) => {
            try {
                if (err) throw Error(err);
                const data = resp.data;
                this.setState({ loading: false });
                if (data.success) {
                    this.fetchBankInfo();
                    this.generateExternalBankStatement(metadata.institution.institution_id);
                } else {
                    ErrorAlert({ description: data.msg });
                }
            } catch (error) {
                ErrorAlert({ description: "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    logPlaidEvent = (eventName, metadata) => {
        apiPOST("/logError", {}, { logType: "PLAID", eventName: eventName, metadata: metadata }, (err, resp) => {
            console.log("Plaid event logged", err, resp);
        });
    }

    reconnectQuickBooks() {
        var oauthClient = new OAuthClient(environment.qboOAuth);
        var { isCreditPlus } = this.props;
        const authUri = oauthClient.authorizeUri({
            scope: [
                OAuthClient.scopes.Accounting,
                OAuthClient.scopes.OpenId,
                OAuthClient.scopes.Profile,
                OAuthClient.scopes.Email,
                OAuthClient.scopes.Phone,
                OAuthClient.scopes.Address
            ],
            state: isCreditPlus ? 'qb-credit-plus' : 'qb-credit-eligibility'
        });

        window.location.href = authUri
    }

    render() {
        const title = null//"Aion Credit";
        return (
            <div>
                <FlowViewContainer title={title} contentViews={this.getContentViews()} hidesidebar={true} />
                {this.props.creditEligibilityApplied && <Conversation />}
            </div>
        );
    }

    saveUserInput = (id, dataToSave) => {
        var { loanApplication } = this.state;
        this.setState({ [id]: dataToSave });
        console.log("saveUserInput", id, dataToSave);
    }

    disabledDate = (current) => {
        // Can not select days after today
        return current > moment().endOf('day');
    }

    // This will provide the view for each flowView
    getContentViews = () => {
        const { theme, store, creditStore } = this.props;
        const { currentViewIndex, requestFinancials, loanAppLoading, proposalTerms, showBankDetails, reconnectBank, accountDetails, bankAccessToken, linkToken, relinkToken, bankName, bankUrl, loadingBanks } = this.state;
        var { selectedProduct, loanApplication } = creditStore;
        loanApplication = loanApplication || {};
        var { CreditConfiguration } = store.AppConfig || {};
        const { Attributes, Profile } = store;
        const businessAttributes = Attributes.Businesses || {};
        const allowedCreditProduct = businessAttributes.AllowedCreditProduct;
        const flowView = this.flowViews[currentViewIndex];
        var childViews = (<div></div>); // Initialize
        // Make any customizations here
        const AccountDetailColumns = [
            {
                title: 'Title',
                dataIndex: 'title',
                align: 'left'
            },
            {
                title: 'Account Ending',
                dataIndex: 'mask',
                align: 'left'
            },
            {
                title: 'Available Balance',
                dataIndex: 'availableBalance',
                align: 'left',
                render: (availableBalance, record) => {
                    return (formatter.format(availableBalance ? availableBalance : record.currentBalance))
                },
            }
        ]
        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
        });
        let contentItems = [];
        var iconSize = 25;
        var iconColor = "#5663E9";
        var selectedProductHeader = (
            <FlexColumn>
                <Flex centerHorizontally style={{ justifyContent: flowView.FlowStep == "Apply.Done" ? "center" : "left" }}>
                    <Tag style={{ marginLeft: "0px", fontSize: "1.3rem", height: "45px", padding: "0 15px" }} tagpreset={theme.colors.primary}><span style={{ color: theme.colors.secondary8, marginRight: "5px" }}>Application ID:</span>{loanApplication.applicationId}</Tag>
                </Flex>
                <Divider style={{ margin: "10px 0" }} />
            </FlexColumn>
        );

        function getStepComponent(current, percent) {
            function getStatus(stepCount) {
                if (current == stepCount) return "process";
                if (current > stepCount) return "finish";
                if (current < stepCount) return "wait";
            }
            return (
                <Steps
                    type="navigation"
                    current={current}
                    // onChange={this.onChange}
                    className="site-navigation-steps"
                    percent={percent}
                    style={{ margin: "0px 0 30px" }}
                >
                    {/* <Steps.Step status={getStatus(0)} title="Connect to your accounting software and bank" /> */}
                    <Steps.Step status={getStatus(0)} title="Upload additional information" />
                    <Steps.Step status={getStatus(1)} title="Submit application" />
                </Steps>
            )
        }

        if (loanAppLoading) {
            return <FlowViewComponent
                flowView={{}}
                childViews={<Skeleton loading={true} active title={false} paragraph={{ rows: 10 }}></Skeleton>}
                width={1200}
            />
        }
        if (loanApplication.id && flowView.FlowStep != "Apply.Done") flowView.HeaderBlk = selectedProductHeader;
        switch (flowView.FlowStep) {
            case "Apply.Intro":
                var preScreenApproved = (loanApplication.preScreenStatus == "TermLoanApproved" || loanApplication.preScreenStatus == "ARCreditApproved");
                contentItems = [
                    {
                        icon: <DollarCircleTwoTone style={{ fontSize: iconSize }} twoToneColor={iconColor} />,
                        title: "Flexible credit limits",
                        descComponent: <>Credit lines up to $2M that are designed to grow with you and your business.</>
                    },
                    {
                        icon: <EyeTwoTone style={{ fontSize: iconSize }} twoToneColor={iconColor} />,
                        title: "Get paid faster",
                        descComponent: <>Take control of your cash flow with instant access to capital for unpaid invoices.</>
                    },
                    {
                        icon: <FundTwoTone style={{ fontSize: iconSize }} twoToneColor={iconColor} />,
                        title: "Tailored to you",
                        descComponent: <>Interest only on what you borrow, so you can use what you need, when you need it.</>
                    },
                    {
                        icon: <LikeTwoTone style={{ fontSize: iconSize }} twoToneColor={iconColor} />,
                        title: "Transparent pricing",
                        descComponent: <>Say goodbye to hidden fees with simple, transparent pricing upfront.</>
                    }
                ]
                // const steps = [{checked: true, title: "Submit application", desc: "Tell us a bit about your business"}, {checked: true, title: "Sign proposal", desc: "Receive proposal in 24 hours and agree to terms"}, {checked: false, title: "Enter additional info", desc: "Provide a few additional details and documents"}, {checked: false, title: "Sign agreement", desc: "Review and sign the final agreement to start growing!"}];
                const steps = [{ checked: true, title: "Application", desc: "Tell us a bit about your business" },
                { checked: true, title: "Proposal", desc: "Receive proposal in 24 hours and agree to terms" },
                { checked: false, title: "Underwriting", desc: "Provide a few additional details and documents" },
                { checked: false, title: "Agreement", desc: "Review and sign the final agreement to start growing!" }];
                childViews = (
                    <FlexColumn>
                        {preScreenApproved &&
                            <Space size={50} style={{ marginBottom: "50px" }}>
                                {steps.map(step =>
                                    <FlexColumn style={{ alignItems: "start", maxWidth: "220px" }}>
                                        {step.checked ? <CheckCircleFilled style={{ color: theme.colors.secondary3, fontSize: "30px" }} /> : <div style={{ background: "rgba(0,0,0,0.3)", height: "30px", width: "30px", borderRadius: "15px" }} />}
                                        <Text weight='500' style={{ marginTop: "10px" }}>
                                            {step.title}
                                        </Text>
                                        <Text weight='500'>
                                            {step.desc}
                                        </Text>
                                    </FlexColumn>
                                )}
                            </Space>}
                        <Flex center>
                            <img src={creditGS} height="350px" />
                            {!preScreenApproved ?
                                <FlexColumn>
                                    <Text heading>Capital that grows with you.</Text>
                                    <Text>Unlock instant revenue to accelerate business growth with revolving lines of credit against receivables or inventory.</Text>
                                </FlexColumn>
                                :
                                <FlexColumn style={{ alignItems: "start", margin: "80px 0" }}>
                                    <Flex center>
                                        {/* <img src={creditGS} height="200px" /> */}
                                        <FlexColumn style={{ maxWidth: "660px", paddingLeft: "30px" }}>
                                            <Text heading>Congratulations, you've been pre-qualified!</Text>
                                            <Text size="1.5rem">We just need a bit more information to complete your application. Get started below.</Text>
                                        </FlexColumn>
                                    </Flex>
                                </FlexColumn>
                            }
                        </Flex>
                        {!preScreenApproved &&
                            <Flex style={{ marginTop: "40px" }}>
                                {contentItems.map((item, i) => (
                                    <FlexColumn key={i} style={{ width: "230px", justifyContent: "start", margin: "0 15px", alignItems: "start" }}>
                                        {item.icon}
                                        <Text bold noMargin style={{ marginTop: "10px" }}>{item.title}</Text>
                                        <Text noMargin style={{ textAlign: "left" }}>{item.descComponent}</Text>
                                    </FlexColumn>
                                ))}
                            </Flex>}
                    </FlexColumn>
                )
                break;
            case "Apply.Products":
                var iconSize = 60;
                var shortTermHighlights = [
                    { icon: iconStackOfMoney, desc: "Up to $100K" },
                    { icon: iconPayDate, desc: "Weekly payments" },
                    { icon: iconOnlineMoneyTransfer, desc: <span>Auto<br />debits</span> },
                    { icon: iconKeepDry, desc: "No prepayment penalty" }
                ];
                var arHighlights = [
                    { icon: iconDurationFinance, desc: "Up to $2M" },
                    { icon: iconCircularArrows, desc: "Revolving line" },
                    { icon: iconSlider, desc: "Interest only on what you borrow" },
                    { icon: iconKey, desc: "Grows with you" }
                ];
                var cardStyle = { background: theme.colors.systemGray6, width: "500px", border: `1px solid ${theme.colors.systemGray5}`, padding: "30px", borderRadius: "30px", alignItems: "start", margin: "10px" };
                var containerWidth = "480px";
                var selectButtonStyle = { alignSelf: "center", height: "40px" };
                var { ShortTermGS, ARCreditGS } = CreditConfiguration || {};
                // Disabled short-term AION-717
                const showShortTerm = false; //(allowedCreditProduct == "ShortTermLoans") || !allowedCreditProduct;
                const showARCredit = true; //(allowedCreditProduct == "ARCredit") || !allowedCreditProduct;
                childViews = (
                    <FlexColumn>
                        <Flex center style={{ justifyContent: "space-between", alignItems: "start" }}>
                            {showShortTerm &&
                                <FlexColumn style={{ width: containerWidth }}>
                                    <FlexColumn style={cardStyle}>
                                        <Tag tagpreset={theme.colors.primary}>Fill your Cash flow gaps</Tag>
                                        <Text heading>Short-term loans</Text>
                                        <Text>Get a short term loan against your outstanding invoices</Text>
                                        <Flex style={{ flexWrap: "wrap" }}>
                                            {shortTermHighlights.map((item, i) => (
                                                <FlexColumn key={i} center style={{ width: "150px", margin: "5px", textAlign: "center" }}>
                                                    <img height={iconSize} src={item.icon} />
                                                    <Text bold>{item.desc}</Text>
                                                </FlexColumn>
                                            ))}
                                        </Flex>
                                        <Button
                                            permtype="Override"
                                            style={selectButtonStyle}
                                            onClick={() => {
                                                this.props.dispatch(addDataToStore(CREDIT_SAVE_DATA, { selectedProduct: "Short-term loans" }))
                                                this.loadNext();
                                            }}
                                            text='Select' />
                                    </FlexColumn>
                                    <Collapse
                                        bordered={false}
                                        defaultActiveKey={['0']}
                                        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                                        ghost
                                    >
                                        <Collapse.Panel header={`${(ShortTermGS.MinimumRequirements || {}).Header}`}>
                                            <ol>
                                                {((ShortTermGS.MinimumRequirements || {}).ListItems || []).map((item, i) => <li key={i}>{item}</li>)}
                                            </ol>
                                        </Collapse.Panel>
                                        <Collapse.Panel header={`${(ShortTermGS.Terms || {}).Header}`}>
                                            <ol>
                                                {((ShortTermGS.Terms || {}).ListItems || []).map(item => <li>{item}</li>)}
                                            </ol>
                                        </Collapse.Panel>
                                    </Collapse>
                                </FlexColumn>}
                            {showARCredit &&
                                <Flex style={{ justifyContent: "start" }}>
                                    <FlexColumn style={cardStyle}>
                                        <Tag tagpreset={theme.colors.primary}>High Revenue Business</Tag>
                                        <Text heading>Line of Credit</Text>
                                        <Text>Instant working capital for your invoices</Text>
                                        <Flex style={{ flexWrap: "wrap" }}>
                                            {arHighlights.map(item => (
                                                <FlexColumn center style={{ width: "150px", margin: "5px", textAlign: "center" }}>
                                                    <img height={iconSize} src={item.icon} />
                                                    <Text weight='500'>{item.desc}</Text>
                                                </FlexColumn>
                                            ))}
                                        </Flex>
                                        <Button
                                            permtype="Override"
                                            style={selectButtonStyle}
                                            onClick={() => {
                                                this.props.dispatch(addDataToStore(CREDIT_SAVE_DATA, { selectedProduct: "AR Credit" }));
                                                this.loadNext();
                                            }}
                                            text='Select' />
                                    </FlexColumn>
                                    <Collapse
                                        bordered={false}
                                        defaultActiveKey={['0', '1']}
                                        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                                        ghost
                                    >
                                        <Collapse.Panel header={`${(ARCreditGS.MinimumRequirements || {}).Header}`}>
                                            <ol>
                                                {((ARCreditGS.MinimumRequirements || {}).ListItems || []).map(item => <li>{item}</li>)}
                                            </ol>
                                        </Collapse.Panel>
                                        <Collapse.Panel header={`${(ARCreditGS.Terms || {}).Header}`}>
                                            <ol>
                                                {((ARCreditGS.Terms || {}).ListItems || []).map(item => <li>{item}</li>)}
                                            </ol>
                                        </Collapse.Panel>
                                    </Collapse>
                                </Flex>}
                        </Flex>
                    </FlexColumn>
                )
                break;
            case "Apply.Start":
                contentItems = [
                    // {
                    //     title: "Connect to your accounting system and business bank accounts",
                    //     desc: "We'll automatically retrieve your financials and save you the hassle of manually uploading documents"
                    // },
                    {
                        title: "Upload additional information",
                        desc: "While we do our best to automatically retrieve your business information in previous steps, we need some more information to accurately evaluate your application"
                    },
                    {
                        title: "Submit application",
                        desc: "Easily submit and track your application",
                        additionalInfo: ""
                    }
                ]
                childViews = (
                    <FlexColumn>
                        {contentItems.map((item, i) =>
                            <Space size="large" key={i} style={{ border: `1px solid ${theme.colors.systemGray5}`, margin: "15px 0", padding: "20px 30px" }}>
                                <Flex center style={{ height: 80, width: 80, borderRadius: 40, background: hexToRgba(theme.colors.secondary1, 0.5) }}><Text>{i + 1}</Text></Flex>
                                <FlexColumn>
                                    <Text heading>{item.title}</Text>
                                    <Text style={{ color: theme.colors.systemGray }} noMargin>{item.desc}</Text>
                                </FlexColumn>
                            </Space>
                        )}
                    </FlexColumn>
                );
                break;
            case "Apply.AccountingSW.Add":
                break;
            case "Apply.AccountingSW.QBO":
                var AccountingSWLinked = businessAttributes.AccountingSWLinked || this.state.AccountingSWLinked;
                if (AccountingSWLinked) flowView.HideButton = false;
                flowView.HeaderBlk = selectedProductHeader;
                flowView.TitleBlk = getStepComponent(0, 0);
                childViews = (
                    <div>
                        {!AccountingSWLinked ?
                            <Skeleton loading={this.state.loading} active title={false} paragraph={{ rows: 5 }}>
                                <InputContainer style={{ padding: "50px 0" }}>
                                    <FlexColumn center>
                                        <img style={{ cursor: 'pointer', height: 50 }} src={qbButton} onClick={() => { this.reconnectQuickBooks() }} />
                                        <Text style={{ marginTop: '12pt' }}>You'll need admin rights to connect to QuickBooks</Text>
                                    </FlexColumn>
                                </InputContainer>
                            </Skeleton>
                            :
                            <FlexColumn style={{ maxWidth: "450px" }}>
                                <InputContainer>
                                    <Flex between centerHorizontally>
                                        <FlexColumn>
                                            <div style={{ color: theme.colors.secondary3 }}>Connected</div>
                                            <Text weight='500'>{Profile.AccountingSW || "QuickBooks"}</Text>
                                        </FlexColumn>
                                        <CheckCircleTwoTone style={{ fontSize: 28 }} twoToneColor={theme.colors.secondary1} />
                                    </Flex>
                                </InputContainer>
                                <FlexColumn>
                                    <Banner
                                        title={"Auto-fetch Financials"}
                                        message="We will automatically fetch your Income Statement, Balance Sheet, AR and AP Aging from QuickBooks"
                                    />
                                    <InputContainer style={{ padding: "10px 20px" }}>
                                        <LabeledInput
                                            label={"When did you last close your accounts on QuickBooks?*"}
                                            labelcolor={theme.colors.secondary3}
                                            type="date-picker"
                                            disabledDate={this.disabledDate}
                                            placeholder={moment().format(dateFormat)}
                                            format={dateFormatList}
                                            onChange={(date, dateStr) => this.saveUserInput("accountClosingMonth", date.format("YYYY-MM-DD"))}
                                        />
                                    </InputContainer>
                                </FlexColumn>

                            </FlexColumn>
                        }
                    </div>
                );
                break;
            case "Apply.Banks.Add":
                flowView.HeaderBlk = selectedProductHeader;
                flowView.TitleBlk = getStepComponent(0, 50);
                let connectedBanks = (this.props.creditStore.externalBBInstitutions || []).map(bank => {
                    return (
                        <LightContainer padding='5px 0' margin='0 5px 5px 0' height='56px' noBorder='true'>
                            <Flex style={{ height: '100%' }} start centerHorizontally>
                                {
                                    (bank.logoUrl || bank.bankName) &&
                                    <Button type="primary" icon={<img style={{ marginRight: 8 }} height='24px' src={bank.logoUrl ? `${bank.logoUrl}` : BankIcon} />} text={bank.bankName} rightIcon={bank.status == "Connected" ? <></> : <ExclamationCircleFilled style={{ color: 'orange' }} />} onClick={() => this.handleBankDetails(bank)} />
                                }
                            </Flex>
                        </LightContainer>
                    )
                }
                )
                childViews = (
                    <div style={{ fontSize: 16 }}>
                        <Skeleton loading={this.state.loading} active title={false} paragraph={{ rows: 5 }}>
                            {connectedBanks}
                            {!this.state.loading &&
                                <InputContainer style={{ maxWidth: "350px" }}>
                                    <div style={{ color: theme.colors.secondary3 }}>{(connectedBanks || []).length > 0 ? "Connect more" : "Connect"}</div>
                                    <Flex centerHorizontally between>
                                        <PlaidLink
                                            {...environment.plaid}
                                            token={linkToken}
                                            selectAccount="false"
                                            clientName="Aion"
                                            isWebview="true"
                                            apiVersion="v2"
                                            onSuccess={(public_token, metadata) => this.getPlaidAccessToken(public_token, metadata, true, null)}
                                            style={{ background: 'transparent', paddingLeft: 0, paddingBottom: 0, fontSize: 18, cursor: 'pointer' }}
                                            onEvent={this.logPlaidEvent}
                                        >
                                            <Button solid text='Add Bank' />
                                        </PlaidLink>
                                        {/* <UserAddOutlined style={{fontSize: 22, color: '#333333'}} /> */}
                                    </Flex>
                                </InputContainer>}
                        </Skeleton>
                        <Skeleton loading={loadingBanks} active>
                            <Modal
                                visible={showBankDetails}
                                footer={null}
                                onCancel={() => this.setState({ showBankDetails: false })}
                                width={600}
                            >
                                <div style={{ textAlign: 'left' }}>
                                    <Text heading caption margin='12px 12px 0 12px' >BANK DETAILS</Text>
                                    {
                                        <FlexColumn style={{ margin: '24px 0' }}>
                                            <div style={{ textAlign: 'left', margin: '0 12px 12px 12px' }}>
                                                <Text>
                                                    <img style={{ marginRight: 8 }} height='35px' src={bankUrl} />
                                                    {bankName}
                                                </Text>
                                            </div>
                                            <Flex start wrap="wrap" style={{ margin: '12px 12px 0px 12px' }}>
                                                <Info label='Status' value={reconnectBank ? <Badge status="error" text="DISCONNECTED" /> : <Badge status="success" text="CONNECTED" />} />
                                            </Flex>
                                            <Flex start wrap="wrap" style={{ margin: '12px 12px 0px 12px' }}>
                                                <Text heading caption margin='12px 12px 12px 0'>ACCOUNT DETAILS</Text>
                                                {
                                                    accountDetails && accountDetails.length > 0 &&
                                                    <Table
                                                        id="AccountDetailsTable"
                                                        tableLayout='fixed'
                                                        columns={AccountDetailColumns}
                                                        dataSource={accountDetails}
                                                        pagination={false}
                                                        style={{ margin: '12px 0 12px 0' }}
                                                    />
                                                }
                                            </Flex>
                                            <Flex fullWidth centerHorizontally start gap='12px' style={{ margin: '12px 12px 0 12px' }}>
                                                {
                                                    reconnectBank &&
                                                    <PlaidLink
                                                        {...environment.plaid}
                                                        token={relinkToken}
                                                        className='plaid-link-button'
                                                        selectAccount="false"
                                                        clientName="Aion"
                                                        isWebview="true"
                                                        apiVersion="v2"
                                                        onSuccess={(public_token, metadata) => this.getPlaidAccessToken(public_token, metadata, false, bankAccessToken)}
                                                        onEvent={this.logPlaidEvent()}
                                                    >
                                                        <Button solid type="primary" text='RECONNECT' />
                                                    </PlaidLink>
                                                }
                                            </Flex>
                                        </FlexColumn>
                                    }
                                </div>
                            </Modal>
                        </Skeleton>
                    </div>
                );
                break;
            case "Apply.DocumentUpload":
                flowView.TitleBlk = getStepComponent(1, 0);
                flowView.HeaderBlk = selectedProductHeader;
                childViews = (
                    <BusinessDocuments
                        saveUserInput={this.saveUserInput}
                        loanApp={loanApplication}
                        proposalTerms={proposalTerms}
                        savedState={this.state || {}}
                        requestFinancials={requestFinancials}
                    />
                );
                break;
            case "Apply.TopCustomers":
                flowView.TitleBlk = getStepComponent(1, 50);
                childViews = (
                    <Customers
                        loanApp={loanApplication}
                    />
                );
                break;
            case "Apply.Submit":
                flowView.TitleBlk = getStepComponent(2, 0);
                flowView.HeaderBlk = selectedProductHeader;
                childViews = (
                    <FlexColumn>
                        <Text style={{ color: theme.colors.systemGray }}>
                            By submitting your application you represent that you have the authority to open and conduct business on this account on behalf of the Entity and agree to our <a target="_blank" href="https://aion-assets.s3.us-west-2.amazonaws.com/terms/credit-profile-authorization.html">Credit Profile Authorization</a> and the following terms.
                        </Text>
                        <ul style={{ fontSize: '1.2rem' }}>
                            <li><a target="_blank" href="https://www.aionfi.com/privacy">Privacy Policy</a></li>
                            <li><a target="_blank" href="https://www.aionfi.com/e-sign-agreement">E-Signature Agreement</a></li>
                        </ul>
                    </FlexColumn>

                )
                break
            case "Apply.Success":
                break;
            case "Apply.Done":
                childViews = (<>
                    {
                        (!this.props.isCreditPlus && (loanApplication.underwritingStatus == "Under Review" || loanApplication.underwritingStatus == "Not Started")) ?
                            this.props.history.push("/apply/credit") : null
                    }
                    {
                        (loanApplication.underwritingStatus == "Rejected" || loanApplication.underwritingStatus == "Approved") ?
                            <CreditAppStatus loanApplication={loanApplication}
                                proposalTerms={proposalTerms} /> : null
                    }
                </>
                )
                break;
            default:
                break;
        }

        return <FlowViewComponent
            flowView={flowView}
            currentViewIndex={currentViewIndex}
            loadPrev={this.loadPrevView}
            loadNext={this.loadNext}
            childViews={childViews}
            submitLoading={this.state.submitLoading}
            width={(loanApplication.underwritingStatus == "Rejected" || loanApplication.underwritingStatus == "Approved") ? '100%' : 1200}
        // affixButton
        />
    }

    updateUserStatus = (userStatus) => {
        const { creditStore } = this.props;
        var { loanApplication } = creditStore;
        var { store } = this.props;
        var { BusinessUniqueKey } = store;
        var body = {
            clientBusinessId: BusinessUniqueKey,
            loanApplication: Object.assign(loanApplication, { userStatus: userStatus })
        }
        apiPOSTReq(`${environment.opsBaseUrl}/ops/loans/application/save`, {}, body, (err, resp) => {
            try {
                const data = resp || {};
                if (data.result) {
                    this.props.dispatch(addDataToStore(CREDIT_SAVE_DATA, { loanApplication: data.loanApplication }));
                    this.setState({ loanApplication: data.loanApplication })
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/updateUserStatus err", error, resp);
            }
        });
    }

    // Validate input and go to next view if it's good
    loadNext = () => {
        var { parentCompanyName, naicsCode6, accountClosingMonth, loanApp, ein } = this.state;
        const { creditStore } = this.props;
        var { selectedProduct, loanApplication } = creditStore;
        var flowView = this.flowViews[this.state.currentViewIndex];
        var validation = this.validateFlow();
        var { store } = this.props;
        var { Profile, UserInfo, BusinessUniqueKey } = store;
        console.log("Profile", Profile);
        if (validation.validated) {
            switch (flowView.FlowStep) {
                case "Apply.Submit":
                    loanApplication["underwritingStatus"] = "Not Started";
                    loanApplication["userStatus"] = "Completed";
                    var body = {
                        clientBusinessId: BusinessUniqueKey,
                        loanApplication: loanApplication
                    }
                    if (accountClosingMonth) body["accountClosingMonth"] = accountClosingMonth;
                    if (naicsCode6) body["naics6"] = naicsCode6;
                    if (parentCompanyName) body["parentCompanyName"] = parentCompanyName;
                    if (ein) body["ein"] = ein;
                    console.log("/ops/loans/application/save", body);
                    this.setState({ submitLoading: true });

                    // Update the application with the closing month
                    apiPOSTReq(`${environment.opsBaseUrl}/ops/loans/application/save`, {}, body, (err, resp) => {
                        this.setState({ submitLoading: false });
                        try {
                            const data = resp || {};
                            var body = {
                                BusinessId: BusinessUniqueKey,
                                ApplicationId: (loanApplication || {}).applicationId,
                                ClosingDate: accountClosingMonth, // YYYY-MM-DD
                            }
                            // apiPOST('/assess/Borrower', body, {}, (err, resp) => {
                            //     console.log("Apply.Submit /assess/Borrower", body, err, resp);
                            //     this.setState({ submitLoading: false });
                            //     if (!err) {
                            //         try {
                            //             const data = resp.data;
                            //             if (data.success) {
                            //             } else {
                            //                 throw Error(data.message)
                            //             }
                            //         } catch (error) {
                            //             console.error("Apply.Submit /assess/Borrower, the error message is :" + error)
                            //         }
                            //     } else {
                            //         console.error("Apply.Submit /assess/Borrower, the error message is :" + err)
                            //     }
                            // });
                            if (data.result) {
                                if (!Profile.BusinessInfo || !Profile.BusinessInfo.Name) {
                                    const headers = {
                                        businesskey: this.props.store.BusinessUniqueKey
                                    }

                                    apiGET("/profile", headers, (err, resp) => {
                                        try {
                                            const pData = resp.data;
                                            if (pData.success) {
                                                var profile = pData.profileInfo;
                                                this.sendMail(loanApplication, profile, UserInfo);
                                            }
                                        }
                                        catch (error) {
                                            console.error("Apply.Submit /profile, the error message is :" + error)
                                        }
                                    });
                                }
                                else {
                                    this.sendMail(loanApplication, Profile, UserInfo)
                                }
                            } else {
                                throw Error(data.responseMessage || data.error)
                            }
                        } catch (error) {
                            //console.log("/getPersonCards err", error, resp);
                            ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
                        }
                    });
                    break
                case "Apply.Start":
                    this.updateUserStatus("Started");
                    var { store } = this.props;
                    var { Profile, UserInfo, BusinessUniqueKey } = store;
                    if (loanApplication.applicationId) {
                        this.loadNextView();
                        return;
                    }
                    // Get an application ID
                    var body = {
                        clientBusinessId: BusinessUniqueKey,
                        loanApplication: {
                            "businessName": Profile.BusinessInfo.Name,
                            "applicantFirstName": UserInfo.FirstName,
                            "applicantLastName": UserInfo.LastName,
                            "emailAddress": UserInfo.Email,
                        }
                    }
                    console.log("/ops/loans/application/save", body);
                    this.setState({ submitLoading: true });
                    apiPOSTReq(`${environment.opsBaseUrl}/ops/loans/application/save`, {}, body, (err, resp) => {
                        this.setState({ submitLoading: false });
                        try {
                            const data = resp || {};
                            if (data.result) {
                                this.props.dispatch(addDataToStore(CREDIT_SAVE_DATA, { loanApplication: data.loanApplication }))
                                this.loadNextView();
                            } else {
                                throw Error(data.responseMessage || data.error)
                            }
                        } catch (error) {
                            //console.log("/getPersonCards err", error, resp);
                            ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
                        }
                    });
                    break
                case "Apply.AccountingSW.QBO":
                    this.updateUserStatus("AccountingSWConnected");
                    var { accountClosingMonth, requestFinancials } = this.state;
                    if (requestFinancials) {
                        var body = {
                            BusinessId: BusinessUniqueKey,
                            ApplicationId: (loanApplication || {}).applicationId,
                            ClosingDate: accountClosingMonth, // YYYY-MM-DD
                        }
                        var options = {
                            method: 'POST',
                            url: `${environment.creditRiskBaseUrl}/assess/borrower`,
                            headers: {},
                            body: body,
                            json: true
                        };
                        this.setState({ submitLoading: true });
                        var t = this;
                        t.setState({ submitLoading: false });

                        t.loadNextView();

                        // request(options, function (err, response, resp) {
                        //     console.log("/assess/borrower", body, err, resp);
                        //     t.setState({ submitLoading: false });
                        //     if (!err) {
                        //         try {
                        //             const data = resp;
                        //             if (data.success) {
                        //                 t.loadNextView();
                        //             } else {
                        //                 throw Error(data.message)
                        //             }
                        //         } catch (error) {
                        //             console.error("The error message is :" + JSON.stringify(error))
                        //             ErrorAlert({ description: error.message });
                        //         }
                        //     } else {
                        //         console.error("The error message is :" + JSON.stringify(err))
                        //         ErrorAlert({ description: err.message || "Sorry, we had trouble processing your request." });
                        //     }
                        // })
                    } else {
                        this.loadNextView();
                    }
                    break;
                case "Apply.Banks.Add":
                    this.updateUserStatus("BankingConnected");
                    var { store } = this.props;
                    var { BusinessUniqueKey } = store;
                    var body = {
                        "clientBusinessId": BusinessUniqueKey
                    };
                    this.setState({ submitLoading: true });
                    apiPOSTReq(`${environment.uamBaseUrl}/business/admin/getBusinessData`, {  }, body, (err, resp) => {
                        this.setState({ submitLoading: false });
                        console.log("/business/admin/getBusinessData resp", resp);
                        try {
                            const data = resp || {};
                            var business = data.business || {};
                            var { businessProfile } = business;
                            var { businessInfo } = businessProfile || {};
                            var { naics6, parentCompanyName } = businessInfo || {};
                            if (data.result) {
                                this.setState({
                                    business: data.business,
                                    naicsCode6: naics6,
                                    parentCompanyName: parentCompanyName
                                });
                                this.loadNextView();
                            } else {
                                throw Error(data.responseMessage || data.error)
                            }
                        } catch (error) {
                            console.log("/getBusinessData err", error, resp);
                            ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
                        }
                    });
                    break;
                case "Apply.DocumentUpload":
                    var body = {
                        clientBusinessId: BusinessUniqueKey,
                        loanApplication: Object.assign(loanApplication, { userStatus: "BizDocsUploaded" })
                    }
                    if (accountClosingMonth) body["accountClosingMonth"] = accountClosingMonth;
                    if (naicsCode6) body["naics6"] = naicsCode6;
                    if (parentCompanyName) body["parentCompanyName"] = parentCompanyName;
                    if (ein) body["ein"] = ein;
                    console.log("/ops/loans/application/save DocumentUpload", body);
                    this.setState({ submitLoading: true });
                    apiPOSTReq(`${environment.opsBaseUrl}/ops/loans/application/save`, {}, body, (err, resp) => {
                        this.setState({ submitLoading: false });
                        try {
                            const data = resp || {};
                            if (data.result) {
                                this.props.dispatch(addDataToStore(CREDIT_SAVE_DATA, { loanApplication: data.loanApplication }))
                                this.loadNextView();
                            } else {
                                throw Error(data.responseMessage || data.error)
                            }
                        } catch (error) {
                            //console.log("/getPersonCards err", error, resp);
                            ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
                        }
                    });
                    break;
                case "Apply.TopCustomers":
                    this.updateUserStatus("TopCustomerSubmitted");
                    this.loadNextView();
                    break;
                case "Apply.Success": {
                    this.props.history.push('/apply/credit');
                    break;
                }
                default:
                    this.loadNextView();
                    break
            }
        } else {
            ErrorAlert({ description: validation.message });
        }
    }

    sendMail = (loanApplication, profile, UserInfo) => {
        // Send confirmation email to Underwriting
        var notificationBody = {
            "fromAddr": "Aion <notification@aionfi.com>",
            "toAddr": process.env.REACT_APP_ENV == "production" ? "creditops@mg.aionfi.com" : "stagingmoderators@mg.aionfi.com",
            // "ccAddr" : "", 
            "bccAddr": process.env.REACT_APP_ENV == "production" ? "creditcustomops@mg.aionfi.com" : "akumar@aionfi.com",
            "emailSubject": `Due Diligence Submitted by ${profile.BusinessInfo.Name}`,
            "emailBody": `<p>Dear Underwriting Team,<p>Due diligence information was submitted for below business/application. You can view this application by logging in to https://app.aionfi.com.</p><p>ID: <b>${(loanApplication || {}).applicationId}</b><br/>Business: <b>${profile.BusinessInfo.Name}</b><br/>User: <b>${UserInfo.FirstName} ${UserInfo.LastName}</b></p></p>`
        }
        // apiPOSTReq(`${environment.opsBaseUrl}/ops/sendEmail`, {  }, notificationBody, (err, resp) => {
        apiPOSTReq(`${environment.opsBaseUrl}/ops/sendEmail`, null, notificationBody, (err, resp) => {
            console.log("sendEmail", err, resp, notificationBody);
        })
        this.loadNextView();
    }

    validateFlow = () => {
        const { naicsCode6, accountClosingMonth } = this.state;
        var { store, creditStore } = this.props;
        const flowView = this.flowViews[this.state.currentViewIndex];
        var validation = {
            validated: false,
            message: flowView.ValidationErrorMessage ?? "Please provide an input to continue"
        }
        switch (flowView.FlowStep) {
            case "Apply.Banks.Add":
                if ((this.props.creditStore.externalBBInstitutions || []).length > 0)
                    validation.validated = true;
                break;
            case "Apply.DocumentUpload":
                validation.validated = true;
                if (!naicsCode6) {
                    validation.validated = false;
                    validation.message = "Please provide NAICS code to continue"
                }
                var dbMissingSections = creditStore.dbMissingSections || [];
                if (dbMissingSections.length > 0) {
                    validation.validated = false;
                    validation.message = `Mandatory documents missing in ${dbMissingSections.join(", ")} sections. Please upload these documents to continue.`
                }
                var dbMissingMSAs = creditStore.dbMissingMSAs || [];
                if (dbMissingMSAs.length > 0) {
                    validation.validated = false;
                    validation.message = `MSA missing for ${dbMissingMSAs.join(", ")} under Customers section. Please upload these documents to continue.`
                }
                var dbMissingWOs = creditStore.dbMissingWOs || [];
                if (dbMissingWOs.length > 0) {
                    validation.validated = false;
                    validation.message = `Purchase order missing for ${dbMissingWOs.join(", ")} under Customers section. Please upload these documents to continue.`
                }
                break;
            case "Apply.AccountingSW.QBO":
                validation.validated = true;
                var { store } = this.props;
                var { Attributes } = store;
                var businessAttributes = Attributes.Businesses || {};
                var AccountingSWLinked = businessAttributes.AccountingSWLinked || this.state.AccountingSWLinked;
                if (AccountingSWLinked && !accountClosingMonth) {
                    validation.validated = false;
                    validation.message = "Please provide your account closing date to continue"
                }
                break;
            default:
                validation.validated = true;
                break;
        }
        return validation;
    }

    loadPrevView = () => {
        if (this.state.currentViewIndex !== 0) {
            this.setState({
                currentViewIndex: this.state.currentViewIndex - 1,
                showError: false, errorMsg: ""      // dismiss any validatin error, if any, for the current step
            });
        }
    }

    loadNextView = () => {
        if (this.state.currentViewIndex + 1 < this.flowViews.length) {
            this.setState({
                currentViewIndex: this.state.currentViewIndex + 1
            });
        }
        console.log(444, this.state.currentViewIndex)
        window.scrollTo(0, 0);
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer,
        creditStore: state.creditAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)));