import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import styled, { withTheme } from 'styled-components';
import { Button, Upload, Modal, Skeleton, message } from 'antd';
import { InboxOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';

import environment from '../../environment';
import { getPreSignedS3Url, getJwt } from '../../Utils/api';
import Dragger from 'antd/lib/upload/Dragger';
import { FlexColumn } from './Container';

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

class UploadFiles extends React.Component {

    state = {
        previewVisible: false,
        previewImage: '',
        previewTitle: '',
        fileList: this.props.fileList || [],
        loading: true
    };

    componentDidMount() {
        this.fetchSignedUrls();
        getJwt((err, jwt) => this.setState({ jwt: jwt }))
    }

    componentDidUpdate(prevProps) {
        if (this.props.sumbitted !== prevProps.sumbitted) {
            this.setState({ fileList: [] });
        }
        if (JSON.stringify(prevProps.fileList) !== JSON.stringify(this.props.fileList)) {
            this.setState({ fileList: this.props.fileList }, () => {
                this.fetchSignedUrls();
            })
        }
    }

    fetchSignedUrls = () => {
        var fileList = this.state.fileList;
        if ((fileList.length > 0) && this.props.hasUnsignedFiles) {
            var filecount = 0;
            var t = this;
            function signUrl() {
                if (filecount < fileList.length) {
                    var file = fileList[filecount]
                    // console.log("fetchSignedUrls file", file.url || file.uri)
                    getPreSignedS3Url({ url: file.url || file.uri }, (err, preSignedUrl) => {
                        file.url = preSignedUrl;
                        // console.log("getPreSignedS3Url", preSignedUrl)
                        filecount++;
                        signUrl();
                    });
                } else {
                    t.setState({ loading: false, fileList: fileList })
                }

            }
            signUrl();
        } else {
            this.setState({ loading: false })
        }
    }

    imagesOnly = (file) => {
        const isJpgOrPng = (file.type === 'image/jpeg' || file.type === 'image/png');
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!', 5);
        }
        return isJpgOrPng || Upload.LIST_IGNORE;
    }

    jpegImagesOnly = (file) => {
        const isJpgOrPng = (file.type === 'image/jpeg');
        if (!isJpgOrPng) {
            message.error('You can only upload JPG files', 5);
        }
        return isJpgOrPng || Upload.LIST_IGNORE;
    }

    fileLimit = (file, limit) => {
        const isLt2M = file.size / 1024 / 1024 < limit || 10;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isLt2M;
    }

    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async file => {
        console.log("handlePreview", file)
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
        });
    };

    handleChange = ({ fileList }) => this.setState({ fileList });

    getBase64Str(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    render() {
        const { previewVisible, previewImage, fileList, previewTitle } = this.state;
        const { customUploadURI, uploadType, maxCount, fullResponse, aionStore, showDragger, listType, noPreview, disabled, beforeUpload } = this.props;
        const uploadURI = customUploadURI || `${environment.apiBaseUrl}/file/upload`;
        var { UserInfo } = aionStore;
        UserInfo = UserInfo || {};
        var uploadProps = {
            // multiple: true,
            action: uploadURI,
            headers: {
                AionCurrentBiz: this.props.aionStore.BusinessUniqueKey,
                AionAuth: this.props.aionStore.UAM.encryptedAuthHeader,
                businesskey: this.props.aionStore.BusinessUniqueKey,
                jwt: this.props.aionStore.jwt,
                type: this.props.uploadFileType,
                username: UserInfo.Email || "",
                applicationId: this.props.applicationId || "",
            },
            disabled: disabled && disabled,
            // beforeUpload: () => {
            //     beforeUpload && beforeUpload()
            // },
            onChange: (info) => {
                var { fileList } = info;
                var savedFileList = [];
                fileList.forEach(file => {
                    console.log("info file", file);
                    var { status, response, name, url } = file;
                    console.log("Status", status);
                    if (status === "done") {
                        response = response || {};
                        response["file"] = this.props.includebase64 ? file : null;
                        savedFileList.push(fullResponse ? response : {
                            fileName: name,
                            uri: (response.UploadedUrls || response.uploadedUrls || [])[0],
                            file: this.props.includebase64 ? file : null,
                            mimeType: file.mimeType,
                            type: file.type
                        });
                    }
                    else if (status == undefined) {
                        savedFileList.push({
                            fileName: file.name,
                            uri: file.uri ? file.uri : file.url,
                            file: this.props.includebase64 ? file : null,
                            mimeType: file.mimeType,
                            type: file.type
                        });
                    }
                });
                // console.log("fileList", fileList, savedFileList, this.props.fileList);
                if (this.props.includebase64) {
                    var fileItem = savedFileList.length > 0 ? savedFileList[0] : {}; // Get first item
                    console.log("fileItem", fileItem);
                    if (fileItem.file) {
                        this.getBase64Str(fileItem.file.originFileObj, resp => {
                            fileItem["base64Str"] = resp;
                            // console.log("getBase64Str", resp);
                            this.setState({ fileList });
                            this.props.onUpload(savedFileList);
                        });
                    } else {
                        this.setState({ fileList });
                        this.props.onUpload(savedFileList);
                    }
                } else {
                    this.setState({ fileList });
                    this.props.onUpload(savedFileList);
                }
            }
        };

        if (beforeUpload) {
            uploadProps["beforeUpload"] = () => {
                beforeUpload();
            }
        }
        else {
            uploadProps["beforeUpload"] = () => {
            }
        }

        if (uploadType === "IMAGES_ONLY") {
            uploadProps["beforeUpload"] = this.imagesOnly
        }
        if (uploadType === "JPG_IMAGES_ONLY") {
            uploadProps["beforeUpload"] = this.jpegImagesOnly
        }
        if (maxCount) uploadProps["maxCount"] = maxCount;
        const uploadButton = (
            <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
            </div>
        );

        return (
            <>
                {
                    this.state.loading ?
                        <Skeleton.Avatar size="large" shape="square" active={true} loading={this.state.loading} />
                        :
                        (showDragger ?
                            <FlexColumn style={{ height: "100px" }}>
                                <Dragger
                                    onDownload={file => {
                                        console.log("onDownload", file)
                                    }}
                                    onPreview={this.props.onPreview && this.props.onPreview}
                                    fileList={fileList}
                                    {...uploadProps}>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click or drag file here to upload</p>
                                </Dragger>
                            </FlexColumn>
                            :
                            <Upload
                                beforeUpload={(file) => {
                                    console.log("beforeUploadbeforeUploadbeforeUpload")
                                    beforeUpload()

                                    return
                                }}
                                listType={listType ? listType : "picture-card"}
                                fileList={fileList}
                                onPreview={(!noPreview) && this.handlePreview}
                                onChange={this.handleChange}
                                {...uploadProps}
                                showUploadList={this.props.showUploadList ? this.props.showUploadList : false}
                            >
                                {
                                    (!listType || listType === "picture-card") ?
                                        <>{((maxCount > 0) ? (fileList.length != maxCount) : true) && uploadButton}</>
                                        :
                                        <Button
                                            type={this.props.buttonType ? this.props.buttonType : "default"}
                                            icon={this.props.uploadIcon ? this.props.uploadIcon : <UploadOutlined />}
                                            disabled={this.props.disabled}
                                            style={this.props.style ? this.props.style : {}}
                                        >
                                            {this.props.uploadText ? this.props.uploadText : "Upload"}
                                        </Button>
                                }
                            </Upload>
                        )
                }
                <Modal
                    visible={previewVisible}
                    title={previewTitle}
                    footer={null}
                    onCancel={this.handleCancel}
                >
                    <img alt="attachment" style={{ width: '100%' }} src={previewImage} />
                </Modal>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        bkStore: state.bookkeepingAppReducer,
        aionStore: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(UploadFiles)));