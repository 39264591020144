import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled, { withTheme } from 'styled-components'
import { addDataToStore, PERSONAL_INFO, RESET_ONBOARDING_STATE, resetStore } from '../../../Actions/actions'

import { Flex, FlexColumn } from '../../Reusable/Container'
import FlowViewComponent from '../../Reusable/Refresh/FlowViewComponent'
import { Text } from '../../Reusable/Text'
import { LabeledInput } from '../../Reusable/Input'
import { Button } from '../../Reusable/Button'
import { apiPOSTNoAuth, apiPOSTReq } from '../../../Utils/api'
import environment from '../../../environment'
import { ErrorAlert } from '../../Reusable/Alert'
import { formatPhoneTextV2 } from '../../../Utils/util'

class UserCredentials extends Component {

    state = {
        loading: false,
        phoneCode: {
            "name": "United States",
            "flag": "🇺🇸",
            "code": "US",
            "dial_code": "+1"
        }
    }

    pwdOverlay = () => {
        const StyledOverlay = styled.div`
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 16px;

            position: absolute;
            width: 408px;
            height: 162px;

            background: conic-gradient(from 156.95deg at 92.52% 82.42%, #000000 0deg, #333333 360deg);
            border-radius: 4px;
            z-index: 10;
        `;
        const StyledUL = styled.ul`
            font-weight: 400;
            font-size: 16px;
            color: #FFFFFF;
            padding: 0 16px;
            list-style: none;
        `;
        const StyledSpan = styled.span`
            display: inline-block;
            width: 15px;
        `;
        const bullet = <StyledSpan>•</StyledSpan>
        const checkMark = <StyledSpan>✓</StyledSpan>
        const validations = this.getPasswordValidations()
        return (
            <StyledOverlay>
                <StyledUL className='checked-bullet'>
                    <li>{validations[0] ? checkMark : bullet} 8 to 25 characters</li>
                    <li>{validations[1] ? checkMark : bullet} Atleast 1 special character (@,#,$,%)</li>
                    <li>{validations[2] ? checkMark : bullet} Atleast 1 character from A-Z</li>
                    <li>{validations[3] ? checkMark : bullet} Atleast 1 character from a-z</li>
                    <li>{validations[4] ? checkMark : bullet} Atleast 1 character from 0-9</li>
                </StyledUL>
            </StyledOverlay>
        )
    }

    getPasswordValidations = () => {
        var { password } =  (this.props.onboardingStore || {}).personalInfo || {}
        password =  password || ''
        var validationArr = []
        if ((password.length >= 8) && (password.length < 25)) validationArr.push(true)
        else validationArr.push(false)
        const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        validationArr.push(specialChars.test(password))
        validationArr.push(/^(?=.*[A-Z])/.test(password))
        validationArr.push(/^(?=.*[a-z])/.test(password))
        validationArr.push(/^(?=.*\d)/.test(password))
        return validationArr;
    }

    saveUserInput = (id, value) => {
        var dataToSave = {}
        switch(id) {
            default:
                dataToSave[id] = value
                this.props.dispatch(addDataToStore(PERSONAL_INFO, dataToSave))
                this.setState({ ...dataToSave, errorField: null })
                break
        }
    }

    handleOnChange = (event) => {
        const target = event.target
        switch(target.id) {
            default:
                this.saveUserInput(target.id || target.name, target.value)
        }
    }

    setErrorField = (id, msg) => this.setState({ errorField: id, errorMessage: msg })

    handleOnKeyDown = (event) => { if (event.key === "Enter") this.validateFlow() }

    validateFlow = () => {
        var { onboardingStore, preboardUser, isCP } = this.props
        var { personalInfo } = onboardingStore
        var { phone, firstName, lastName, password } = personalInfo
        if(!preboardUser.authUser) {
            if (!firstName) {
                this.setState({ errorField: "firstName", errorMessage: "Please enter a first name" })
                return
            }
            if (!lastName) {
                this.setState({ errorField: "lastName", errorMessage: "Please enter a last name" })
                return
            }
        }
        if (!phone || (phone.replace(/[^\d]/g, '').length < 6)) {
            this.setState({ errorField: "phone", errorMessage: "Please enter a valid mobile number" })
            return
        }
        personalInfo.phone = `${this.state.phoneCode.dial_code}${phone.replace(/[^0-9A-Z]+/gi, '')}`
        if(!preboardUser.existingUser) {
            
            if (this.getPasswordValidations().includes(false)) {
                this.setState({ errorField: "password", errorMessage: "Please enter a valid password" })
                return
            }
        }
        personalInfo.controlPerson = isCP || false
        
        var body = {
            ...preboardUser,
            "primaryApplicantInfo": personalInfo,
            "passwd": !preboardUser.existingUser ? password : 'Aion@1234', // We don't need a password for existing users
            "referenceNumber": preboardUser.inviteReferenceNumber
        }
        console.log("saveInvitedUser validateFlow", password, personalInfo)
        this.setState({ loading: true })
        apiPOSTReq(`${environment.uamBaseUrl}/saveInvitedUser`, { AionAuth: preboardUser.inviteReferenceNumber }, body, (err, resp) => {
            this.setState({ loading: false })
            try {
                const data = resp || {}
                console.log("/saveInvitedUser resp", err, resp)
                if (data.result) {
                    this.props.dispatch(resetStore(RESET_ONBOARDING_STATE))
                    this.props.loadNext()
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp)
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again" })
            }
        })
    }

    render() {
        var { phoneCode, showPwdOverlay, errorMessage, errorField } = this.state
        var { flowView, onboardingStore, theme, currentViewIndex, loadPrevView, preboardUser, inviteUserType } = this.props
        onboardingStore = onboardingStore|| {}
        var { firstName, lastName, phone, email, password } = onboardingStore.personalInfo || {}

        var childViews = (
            <FlexColumn start style={{ marginTop: "0px", height: '100%' }}>
                <Text uppercase spacing='0.08em' weight='500' color={theme.colors.secondary6}>Sign in Credentials</Text>
                <Text>{preboardUser.existingUser ? `You can login to this business with your existing Aion credentials once your details have been approved.` : `You can use the following credentials to log into Aion once your details have been approved`}</Text>
                <FlexColumn start style={{ marginTop: "16px", height: '100%', width: 400 }}>
                    {
                        (preboardUser.authUser || inviteUserType == 'CoOwner') ?
                        (
                            !preboardUser.existingUser ?
                            <LabeledInput
                                label="Name"
                                id="name"
                                type="read-only"
                                value={`${firstName} ${lastName}`}
                            />
                            :
                            <></>
                        )
                        :
                        <Flex gap='24px'>
                            <LabeledInput
                                autoFocus
                                label="First Name"
                                id="firstName"
                                type="firstName"
                                value={firstName}
                                placeholder="John"
                                onChange={this.handleOnChange}
                                error={errorField == "firstName"}
                                errorMessage={errorMessage}
                            />
                            <LabeledInput
                                label="Last Name"
                                id="lastName"
                                type="lastName"
                                value={lastName}
                                placeholder="Smith Jr."
                                onChange={this.handleOnChange}
                                error={errorField == "lastName"}
                                errorMessage={errorMessage}
                            />
                        </Flex>
                        
                    }
                    <LabeledInput
                        label="Email"
                        id="email"
                        type="read-only"
                        value={email}
                        instruction="This will be your Sign In ID"
                    />
                    {
                        inviteUserType != 'CoOwner' &&
                        <LabeledInput
                            label="Mobile Number"
                            id="phone"
                            type="phone-cc"
                            placeholder="(000) 000 0000"
                            onChange={this.handleOnChange}
                            onCodeChange={(code) => this.saveUserInput("phoneCode", code)}
                            value={phoneCode.code == 'US' ? formatPhoneTextV2(phone) : phone}
                            phoneCode={phoneCode}
                            error={errorField == "phone"}
                            errorMessage={errorMessage}
                        />
                    }
                    {
                        !preboardUser.existingUser ?
                        <>
                            <div style={{ background: 'transparent', marginTop: '8px' }}>
                                <LabeledInput
                                    label="Enter a Password"
                                    id="password"
                                    type="new-password"
                                    placeholder="Enter a password for your account"
                                    onChange={this.handleOnChange}
                                    onKeyDown={this.handleOnKeyDown}
                                    error={errorField == "password"}
                                    errorMessage={errorMessage}
                                    defaultValue={password}
                                    style={{ width: '100%' }}
                                    margin="0 0 8px"
                                    onFocus={() => this.setState({ showPwdOverlay: true })}
                                    onBlur={() => this.setState({ showPwdOverlay: false })}
                                />
                                {showPwdOverlay && this.pwdOverlay()}
                            </div>
                        </>
                        :
                        <></>
                    }
                    
                    
                </FlexColumn>
                
                {/* <Button style={{ marginTop: '24px' }} solid text="CREATE ACCOUNT" onClick={() => {
                    this.props.loadNext()
                }} /> */}
            </FlexColumn>
        )
        return (
            <FlowViewComponent
                flowView={flowView}
                currentViewIndex={currentViewIndex}
                loadPrev={loadPrevView}
                childViews={childViews}
                padding='0 64px'
                {...this.props}
                loadNext={this.validateFlow}
                submitLoading={this.state.loading}
                back={false}
            />
        )
    }
}

function mapStateToProps(state) {
    return {
        onboardingStore: state.onboardingAppReducer,
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(UserCredentials))