import { cloneDeep } from 'lodash';
import { CREDIT_SAVE_DATA, RESET_CREDIT_STATE } from '../Actions/actions'

const initialState = {
}

function creditAppReducer(state, action) {
	if (typeof state === 'undefined') {
		return initialState
	}
	var newState = cloneDeep(state);
	switch (action.type) {
		case CREDIT_SAVE_DATA:
			newState = Object.assign(newState, action.data ? action.data : {});
			return newState
		case RESET_CREDIT_STATE:
			newState = initialState;
			return newState
		default:
			return state
	}
}

export default creditAppReducer