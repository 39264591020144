import React from 'react';
import { Button, Modal, notification } from 'antd';

import ErrorImg from '../../Images/error.png';
import { Paragraph } from './Text';
import { FlexColumn } from './Container';
import { InfoCircleTwoTone } from '@ant-design/icons';
import { handleErrorModal } from '../../Actions/actions';
import { connect } from 'react-redux';
import store from '../../Reducers/store';

export const ErrorAlert = (props) => {
    store.dispatch(handleErrorModal({ displayError: true, errorDescription: props.description, errorTitle: props.title }))
}

export const Notification = (props) => {
    const key = `open${Date.now()}`;
    var url = null;
    if(props.url) url = (
        <a target="_blank" href={props.url} onClick={() => notification.close(key)}>
            See more
        </a>
    );
    notification.open({
        message: props.title,
        description: props.description,
        icon: <InfoCircleTwoTone />,
        btn: url,
        key,
        onClose: notification.close(key),
        duration: (props.duration != undefined) ? props.duration : 8,
        className: 'error-alert-modal drawer-modal',
    });
};