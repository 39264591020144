import React from 'react';
import styled from 'styled-components';
import hexToRgba from 'hex-to-rgba';
import { Button as AntDButton, Tooltip } from 'antd';
import { getResourcePerm } from '../../../Utils/util';
import { DownOutlined, PlusOutlined, LoadingOutlined, CloseOutlined } from '@ant-design/icons';

// This button will have all the functionality as the antd Button
export const StyledButton = (props) => {
    const perm = getResourcePerm(props.permtype || "");
    const SButton = styled(AntDButton)`
        color: ${props => {
            if (props.type == "secondary") return props.theme.colors.primary
            else return props.theme.body
        }};
        background: ${props => {
            if (props.type == "secondary") return props.theme.body
            else if (props.background) return props.background
            else return props.theme.colors.primary
        }};
        font-size: 1.2rem;
        font-weight: 500;
        margin: ${props => {
            if (props.noMargin) return `0`
            else return `1em`
        }};
        padding: 0.25em 1em;
        border-radius: 5px;
        border: ${props => {
            if (props.type == "secondary") return `1.5px solid ${props.theme.colors.secondary4}`
            else if (props.background) return `0px solid ${props.background}`
            else return `0px solid ${props.theme.colors.primary}`
        }};
        height: ${props => props.height || '60px'};
        &:hover {
            background: ${props => {
            if (props.type == "secondary") return null
            else if (props.background) return props.background
            else return props.theme.colors.secondary4
        }};
            color: ${props => {
            if (props.type == "secondary") return props.theme.colors.primary
            else return props.theme.body
        }};
            border: 1.5px solid ${props => {
            if (props.background) return props.background
            else return props.theme.colors.primary
        }};
        }
        disabled: ${props => {
            if (props.disabled) return props.disabled;
            else return false;
        }}
    `;
    return (
        (perm.manage || perm.approve) ?
            <Tooltip placement="top" title={props.tooltip}>
                <SButton {...props} />
            </Tooltip>
            : null
    )
}
export const SecondaryButton = styled(AntDButton)`
    color: ${props => props.theme.text};
    background: ${props => props.theme.colors.systemGray5};
    font-size: 1.0rem;
    font-weight: 500;
    border-radius: 15px;
    border: 0px solid ${props => props.theme.colors.secondary2};
`;
export const StdButton = (props) => {
    const perm = getResourcePerm(props.permtype || "");
    const SButton = styled(AntDButton)``;
    return perm.manage ? <SButton {...props} /> : null;
}
export const StyledAnchor = (props) => {
    const perm = getResourcePerm(props.permtype || "");
    const SAnchor = styled.a`
        color: ${props => props.theme.secondary3};
    `;
    return perm.manage ? <SAnchor /> : null;
}
// export const TextButton = styled(Button)`
//     color: ${props => props.theme.colors.secondary3};
//     font-size: 1.0rem;
//     background: none;
//     border: none;
//     margin: 0;
//     padding: 0;
//     font-weight: 500;
//     outline: none;
//     &:hover {
//         background: transparent;
//         color: ${props => props.theme.colors.secondary2};
//     }
// `;
// export const TagButton = styled(AntDButton)`
//     color: ${props => props.textcolor || props.theme.text};
//     background: ${props => props.background || props.theme.colors.systemGray6};
//     font-size: ${props => props.textsize || '0.75rem'};
//     font-weight: ${props => props.weight || '500'};
//     border-radius: 5px;
//     margin: ${props => props.margin || '0'};
//     padding: ${props => props.padding || '0px 6px'};
//     height: ${props => props.height || '22px'};
//     border: 0px solid ${props => props.theme.colors.secondary2};
//     &:hover {
//         /* Retain all styles */
//         color: ${props => props.textcolor || props.theme.text};
//         background: ${props => props.background || props.theme.colors.systemGray6};
//         color: ${props => {
//         if (props.tagpreset) return hexToRgba(props.tagpreset, 0.85)
//     }
//     };
//         background: ${props => {
//         if (props.tagpreset) return hexToRgba(props.tagpreset, 0.1)
//     }
//     };
//         border: ${props => {
//         if (props.tagpreset) return `0px solid ${hexToRgba(props.tagpreset, 0.2)}`
//     }
//     };
//     }
//     color: ${props => {
//         if (props.tagpreset) return hexToRgba(props.tagpreset, 0.85)
//     }
//     };
//     background: ${props => {
//         if (props.tagpreset) return hexToRgba(props.tagpreset, 0.1)
//     }
//     };
//     border: ${props => {
//         if (props.tagpreset) return `0px solid ${hexToRgba(props.tagpreset, 0.2)}`
//     }
//     };
//     cursor: ${props => (props.onClick == null) ? "unset" : "pointer"};
// `;

export const DashedButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 100%;
    border: 1px dashed #1199FF;
    border-radius: 10px;
    cursor: pointer;
`;

export const TextButton = (props) => {
    const Icon = styled.span`
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        margin-right: ${props => !props.right && '8px'};
        margin-left: ${props => props.right && '8px'};
        color: ${props => props.disabled ? '#737387' : (props.color ? props.color : '#1199FF')};
        cursor: ${props => !props.noClick && 'pointer'};
    `;

    const TextButton = styled.span`
        padding: ${props => props.padding ? props.padding : '8px 0px'};
        font-family: 'InterDisplay';
        font-size: ${props => props.size ? props.size : '16px'};
        font-style: normal;
        font-weight: ${props => props.weight ? props.weight : '500'};
        line-height: ${props => props.height ? props.height : '24px'};
        letter-spacing: 0em;
        text-align: left; 
        color: ${props => props.disabled ? '#737387' : (props.color ? props.color : props.theme.button.primary)};
        text-transform: ${props => props.uppercase && 'uppercase'};
        cursor: ${props => !props.noClick && 'pointer'};
        text-decoration-line: ${props => props.underline && 'underline'};
    `;

    return <div {...props} style={{ display: 'flex', alignItems: 'center', margin: props.margin, cursor: !props.noClick && (props.disabled ? 'not-allowed' : 'pointer') }}>
        {
            props.loading &&
            <Icon disabled={props.disabled}><LoadingOutlined style={{ color: props.color }} /></Icon>
        }
        {
            !props.loading && props.icon &&
            <Icon disabled={props.disabled}>{props.icon}</Icon>
        }
        <TextButton {...props}>{props.text && props.text}</TextButton>
        {
            props.rightIcon &&
            <Icon right disabled={props.disabled} noClick={props.noClick}>{props.rightIcon}</Icon>
        }
    </div>
}


export const Button = (props) => {
    const Button = styled.span`
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: ${props => props.padding ? props.padding : '16px 24px'};
        width: ${props => props.width && props.width};
        height: ${props => props.height ? props.height : '40px'};
        background: ${props => props.background ? props.background : (props.disabled ? '#CBCED6' : (props.solid ? '#1199FF' : 'white'))};
        /* box-shadow: ${props => (!props.disabled && props.solid) && '0px 4px 32px rgba(55, 79, 234, 0.24)'}; */
        border: ${props => (!props.disabled && !props.noBorder) && `1px solid ${props.color ? props.color : '#1199FF'}`};
        border-radius: ${props => props.radius ? props.radius : '8px'};
        margin: ${props => props.margin && props.margin};
        cursor: ${props => !props.noClick && 'pointer'};
        white-space: nowrap;
        box-shadow: ${props => props.shadow && '0px 4px 8px rgba(102, 102, 102, 0.08)'};
    `;

    return <Button {...props}>
        <TextButton
            loading={props.loading}
            text={props.text}
            icon={props.icon}
            rightIcon={props.rightIcon}
            color={props.disabled ? '#737387' : (props.solid ? 'white' : props.color)}
            weight={props.weight}
            noClick={props.noClick} />

        {
            props.dropdown &&
            <DownOutlined style={{ marginLeft: 6, fontSize: 18, fontWeight: 500, color: props.solid ? 'white' : '#1199FF' }} />
        }
        {/* {
            props.loading &&
            <LoadingOutlined style={{ marginLeft: 10, fontSize: 16, color: props.solid ? 'white' : '#1199FF' }} />
        } */}
    </Button>
}

export const Capsule = (props) => {
    const Capsule = styled.div`
        height: ${props => props.height ? props.height : '32px'};
        border: 1px solid #1199FF;
        border-radius: 40px;
        padding: 4px 16px;
        background-color: ${props => props.selected === true ? '#1199FF' : 'white'};
        cursor: ${props => (props.onClick == null) ? "unset" : "pointer"};
        margin: ${props => props.margin && props.margin};
        display: flex;
        align-items: center;
        justify-content: center;
    `

    const CapsuleText = styled.span`
        font-family: 'InterDisplay';
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: ${props => props.selected === true ? 'white' : '#1199FF'};
    `

    return (
        <Capsule {...props}>
            <CapsuleText {...props}>{props.text}</CapsuleText>
            {
                props.closable &&
                <CloseOutlined onClick={() => props.onDelete(props.index)} style={{ color: '#1199FF', fontSize: 14, marginLeft: 10, cursor: 'pointer' }} />
            }
        </Capsule>
    );
}