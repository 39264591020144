import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import { Table, Skeleton } from 'antd'
import moment from 'moment'
import Dimensions from 'react-dimensions'

import { Flex, FlexColumn } from '../../Reusable/Container'
import { Text, Tag } from '../../Reusable/Text'
import { renderBankIcon } from '../../../Utils/util'

// Images
import Individual from '../../../Images/individual-recipient.png'
import Business from '../../../Images/business-recipient.png'

const dFormat = "MMM Do, YYYY (z)"
const etTimeZone = "America/New_York"

const TransferToRecipientTable = (props) => {
    const history = useHistory();
    const { recipients, pagination, loading, showHeader, showRowSelection, view, rail, paymentMethodRecipientMap } = props

    useEffect(() => {
        console.log('RecipientsTable: ', props.containerWidth, props.containerHeight)
    }, [])

    function viewRecipient(recipient) {
        history.push({
            pathname: '/banking/recipients/details',
            state: { recipient }
        })
    }

    var columns = [
        {
            title: 'Name',
            dataIndex: 'nameOnAccount',
            key: 'nameOnAccount',
            render: (item, record) => <FlexColumn start gap='8px'>
                <Flex start gap='12px' centerHorizontally>
                    {
                        record.type === 'Individual' ?
                            <img src={Individual} width='24px' height='24px' />
                            :
                            <img src={Business} width='24px' height='24px' />
                    }
                    {
                        view ?
                            <a style={{ textDecoration: "underline", color: "#1199FF", marginLeft: 12 }} onClick={() => viewRecipient(record)}>
                                <Text primary noWrap>{record.nickName || item}</Text>
                            </a>
                            :
                            <Text noWrap>{record.nickName || item}</Text>
                    }
                    {
                        !rail && record.transferMethodACH &&
                        <Tag>ACH</Tag>
                    }
                    {
                        !rail && record.transferMethodWire &&
                        <Tag>Wire</Tag>
                    }
                    {
                        !rail && record.transferMethodCheck &&
                        <Tag>Check</Tag>
                    }
                </Flex>
                {
                    rail &&
                    <Flex start wrap gap='8px'>
                        {
                            (paymentMethodRecipientMap[record.objectId] || []).filter(item => item.type === rail).map(paymentMethod => {
                                return <Tag>{renderBankIcon(paymentMethod.bankDetail)}{paymentMethod.nickName} • {paymentMethod.bankDetail.accountNumber.slice(-4)}</Tag>
                            })
                        }
                    </Flex>
                }
            </FlexColumn>
        },
        {
            title: 'Last Transfer',
            dataIndex: 'lastTransferDate',
            key: 'lastTransferDate',
            align: 'right',
            render: date => <>
                {
                    date ?
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Text size='14px' color='#7384AA' right noWrap>Last transfer:<Text color='#444444' margin='0 0 0 8px' noWrap>{moment.utc(date).tz(etTimeZone).format(dFormat)}</Text></Text>
                        </div>
                        :
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Text size='14px' color='#7384AA' right noWrap>Last transfer: <Text color='#444444' margin='0 0 0 8px'>--</Text></Text>
                        </div>
                }
            </>
        },
    ]

    return (<Table
        scroll={{ y: props.containerHeight }}
        id="recipient-table"
        className='borderless-table radio-table'
        tableLayout='auto'
        columns={columns}
        dataSource={recipients}
        rowKey='objectId'
        pagination={pagination}
        showHeader={showHeader}
        // onChange={this.handleTableChange}
        loading={loading}
        rowSelection={showRowSelection && {
            type: "checkbox",
            onChange: props.handleRowSelection,
            selectedRowKeys: props.selectedRowKeys
        }}
    />
    )
}


export default Dimensions({ elementResize: true })(TransferToRecipientTable)