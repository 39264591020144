import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import moment from 'moment-business-days'

// Components
import { Button } from '../../../Reusable/Button';
import { Flex, FlexColumn } from '../../../Reusable/Container';
import { Text } from '../../../Reusable/Text';
import { LabeledInput } from '../../../Reusable/Input';
import { apiPOSTReq } from '../../../../Utils/api';
import environment from '../../../../environment';
import { ErrorAlert } from '../../../Reusable/Alert';
import { dateFormatList } from '../../../../Utils/util'

class Index extends Component {
    state = {
        loading: false,
        reason: '',
        effectiveStartDate: moment(),
        effectiveEndDate: null,
        adjustmentAmount: null
    }

    componentDidMount() {
    }

    handleReason = (event) => {
        const target = event.target;
        this.setState({ reason: target.value });
    }
    disabledDate = (current) => {
        // Can not select days before today and today and weekends / holidays
        return current < moment().startOf('day')
    }
    handleSubmit = async () => {
        const { UserInfo } = this.props.store;
        const { businessId, isFrozen } = this.props;
        const { adjustmentAmount, reason, effectiveStartDate, effectiveEndDate } = this.state;
        var username = UserInfo.Email;

        if (adjustmentAmount == null || adjustmentAmount == '' && adjustmentAmount != 0) {
            ErrorAlert({ description: "Adjustment amount is required." });
            return;
        }
        if (effectiveStartDate == null || effectiveStartDate == '') {
            ErrorAlert({ description: "Start date is required." });
            return;
        }
        if (effectiveEndDate == null || effectiveEndDate == '') {
            ErrorAlert({ description: "End date is required." });
            return;
        }
        if (moment(effectiveEndDate).isBefore(moment(effectiveStartDate))) {
            ErrorAlert({ description: `End date should be greater than Start date.` });
            return;
        }
        if (reason == null || reason == '') {
            ErrorAlert({ description: "Reason is required. Please enter a valid reason." });
            return;
        }
        this.setState({ loading: true });
        var request = {
            "clientBusinessId": businessId,
            "creditOpsParams": {
                "businessAttributeParams": [
                    {
                        "name": "Adjustment",
                        "type": "Adjustment",
                        "value": adjustmentAmount,
                        "effectiveStartDate": effectiveStartDate,
                        "effectiveEndDate": effectiveEndDate
                    }
                ]
            },
            "userName": username,
            "reason": reason
        }
        apiPOSTReq(`${environment.opsBaseUrl}/ops/setBusinessInfo`, null, request, (err, resp) => {
            this.setState({ loading: false })
            try {
                const data = resp;
                if (data.result) {
                    if (err) throw new Error(err);

                    this.props.closeAdjustment();
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR setBusinessInfo", error, err, resp);
            }
        });
    }

    render() {
        const { reason, loading, adjustmentAmount, effectiveStartDate, effectiveEndDate } = this.state;

        return (
            <FlexColumn center style={{ minHeight: "80%" }}>
                <div style={{ width: "600px" }}>
                    {
                        <Text heading>Add adjustments</Text>
                    }
                    <Flex start>
                        <LabeledInput
                            type="number"
                            label="Adjustment amount"
                            id="adjustmentAmount"
                            key="adjustmentAmount"
                            className="no-left-padding"
                            placeholder="Enter adjustment amount"
                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            value={adjustmentAmount}
                            onChange={(value) => {
                                this.setState({ adjustmentAmount: value })
                            }}
                        />
                    </Flex>
                    <Flex start>
                        <LabeledInput
                            label="Start Date"
                            id="effectiveStartDate"
                            key="effectiveStartDate"
                            type="date-picker"
                            format={dateFormatList}
                            disabledDate={this.disabledDate}
                            defaultValue={effectiveStartDate && moment(effectiveStartDate)}
                            value={effectiveStartDate && moment(effectiveStartDate)}
                            placeholder={moment().format("MM-DD-YYYY")}
                            onChange={(date, dateStr) => {
                                this.setState({ effectiveStartDate: date })
                            }}
                        />
                    </Flex>
                    <Flex start>
                        <LabeledInput
                            label="End Date"
                            id="effectiveEndDate"
                            key="effectiveEndDate"
                            type="date-picker"
                            format={dateFormatList}
                            disabledDate={this.disabledDate}
                            defaultValue={effectiveEndDate && moment(effectiveEndDate)}
                            value={effectiveEndDate && moment(effectiveEndDate)}
                            placeholder={moment().format("MM-DD-YYYY")}
                            onChange={(date, dateStr) => {
                                this.setState({ effectiveEndDate: date })
                            }}
                        />
                    </Flex>
                    <Flex start>
                        <LabeledInput
                            label="Reason"
                            type="text-area"
                            id="reason"
                            key="reason"
                            placeholder={`Add your reason`}
                            onChange={this.handleReason}
                            value={reason}
                        />
                    </Flex>
                    <Flex start>
                        <Button style={{ alignSelf: 'Start', margin: '20px 0' }} solid loading={loading} onClick={this.handleSubmit} text="Save" />
                    </Flex>
                </div>
            </FlexColumn>
        );
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer,
        receivablesStore: state.receivablesAppReducer,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Index));