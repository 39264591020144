import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';

// Components
import { Text } from '../../../Reusable/Text';
import { Button, TextButton } from '../../../Reusable/Button';
import { Flex, FlexColumn, CardContainer } from '../../../Reusable/Container';
import { EditOutlined } from '@ant-design/icons';
import { Space, Skeleton, Dropdown, Menu, Select, Radio, Tooltip, Modal, Popconfirm } from 'antd';
import environment from '../../../../environment';
import { ErrorAlert } from '../../../Reusable/Alert';
import { LabeledInput } from '../../../Reusable/Input';
import { apiPOSTReq } from '../../../../Utils/api';
import { dateFormatList, getAddressObj, STATES_MAP, toCurrency } from '../../../../Utils/util';
import { DownOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { CustomerProposal } from '../../../OnboardingFlowCoordinator/CreditOnboarding/CustomerProposal';
import { OfferCard } from '../../../Reusable/Refresh/Card';
import cloneDeep from 'lodash/cloneDeep'

const { Option } = Select;


class Index extends Component {

    state = {
        loading: false,
        businessInfoEdit: false,
        sendProposalLoading: false,
        gnplLow: 0,
        gnplHigh: 100001,
        offers: [],
        maxOffer: 2,
        newOffers: []
    };

    constructor(props) {
        super(props);
    }

    getMenu = () => {
        return <Menu onClick={(e) => this.sendProposal(e.key)}>
            <Menu.Item key="Manual Review">
                Manual Review
            </Menu.Item>
            <Menu.Item key="Manually Rejected">
                Manual Reject
            </Menu.Item>
        </Menu>
    }

    componentDidMount() {
        this.fetchBusinessData();
        this.fetchBusinessAttributes();
        this.getCreditLimit();
    }

    fetchBusinessData = () => {
        const { loanApp } = this.props;
        var body = {
            "clientBusinessId": loanApp.businessId
        };
        console.log("/business/admin/getBusinessData body", body);
        this.setState({ loading: true });
        apiPOSTReq(`${environment.uamBaseUrl}/business/admin/getBusinessData`, null, body, (err, resp) => {
            this.setState({ loading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    this.setState({
                        business: data.business
                    });

                    this.fetchData();
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    fetchBusinessAttributes = () => {
        const { clientDetail } = this.props.location.state;
        var body = {
            "clientBusinessId": ''
        };

        this.setState({ attributesLoading: true });
        apiPOSTReq(`${environment.opsBaseUrl}/ops/fetchBusinessAttributes`, null, body, (err, resp) => {
            // this.setState({ attributesLoading: false });
            try {
                const data = resp || {};
                if (data.businessAttributes) {
                    var businessAttributes = data.businessAttributes;

                    var lineSetup = data.businessAttributes.filter(x => x.name == "GNPLAutomatedLineSetupFlow");
                    if (lineSetup.length > 0) {
                        var attribute = lineSetup[0];
                        var additionalParameterReference = attribute.additionalParameterReference;
                        var parameters = additionalParameterReference.parameters;
                        var low = parseInt(parameters[0].value);
                        var high = parseInt(parameters[1].value);
                        this.setState({ gnplLow: low, gnplHigh: high })
                    }

                    var maxOffer = data.businessAttributes.filter(x => x.name == "DefaultMaxOffer");
                    if (maxOffer.length > 0) {
                        var attribute = maxOffer[0];
                        this.setState({ maxOffer: attribute.value })
                    }

                    this.setState({
                        businessAttributes: businessAttributes
                    });
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    fetchData = () => {
        const { loanApp } = this.props;
        const { business } = this.state;
        var businessProfile = (business || {}).businessProfile || {};
        var businessInfo = businessProfile.businessInfo || {};
        if (!businessInfo.name) return null;
        var { capitalRequest } = businessInfo || {};
        var body = {
            "clientBusinessId": loanApp.businessId,
            "applicationId": loanApp.applicationId,
            "requestedAmount": parseFloat(capitalRequest.toString().replace(',', '')),
        };
        console.log("/lsm/getProposalTerms body", body);
        this.setState({ loading: true });
        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/getProposalTerms`, null, body, (err, resp) => {
            this.setState({ loading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    if (data.offers.length > 0) {
                        var proposalTerms = data.offers[0];
                        // proposalTerms.repaymentTerms = proposalTerms.repaymentTerms + (proposalTerms.paymentPlan == "Weekly" ? " Weeks" : " Months");
                        data.offers.map(item => {
                            item.repaymentTerms = item.repaymentTerms + (item.paymentPlan == "Weekly" ? " Weeks" : " Months");
                        })

                        this.setState({
                            "proposalTerms": proposalTerms,
                            "offers": data.offers
                        });
                    }
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    sendProposal = (status) => {
        const { loanApp } = this.props;
        var body = {
            "clientBusinessId": loanApp.businessId,
            "applicationId": loanApp.applicationId,
            "verificationStatus": status
        };
        console.log("/ops/application/sendProposal body", body);
        this.setState({ sendProposalLoading: true });
        apiPOSTReq(`${environment.opsBaseUrl}/ops/loans/application/sendProposal`, null, body, (err, resp) => {
            this.setState({ sendProposalLoading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    this.setState({
                        businessInfoEdit: false
                    });
                    this.setState({
                        proposalInfoEdit: false
                    });

                    if (status == "Proposal Issued") {
                        //Email Triggered to User confirmation
                        var userNotificationBody = {
                            "fromAddr": "Aion <credit@aionfi.com>",
                            "toAddr": loanApp.emailAddress,
                            "ccAddr": process.env.REACT_APP_ENV != "production" ? "stagingmoderators@mg.aionfi.com" : "",
                            "bccAddr": process.env.REACT_APP_ENV != "production" ? "akumar@aionfi.com" : "creditcustomops@mg.aionfi.com",
                            "emailSubject": `Your Financing Proposal is Ready 🎉`,
                            "emailBody": `<p>Hi ${loanApp.applicantFirstName},<p>Congratulations, you’ve been prequalified for Aion Capital!</p><p>We have reviewed the information you submitted and prepared a proposal.</p><p>The terms of your proposal are active for a limited time. Log into your Aion account now to review the proposal and continue the application process: <a href="https://app.aionfi.com/">Review Proposal</a>.</p><p>As always, we’re here to answer any questions you have. Feel free to reach out to us at hello@aionfi.com.</p></p>`
                        }

                        apiPOSTReq(`${environment.opsBaseUrl}/ops/sendEmail`, null, userNotificationBody, (err, resp) => {
                            console.log("sendEmail", err, resp, userNotificationBody);
                        })
                    }

                    this.fetchData();
                    this.props.updateStatus(status);
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    saveProposalTerms = () => {
        const { loanApp } = this.props;
        const { offers } = this.state;

        // if (this.validateProposalTerms()) {
        if (true) {
            var proposalTerms = [];
            offers.forEach(offer => {
                proposalTerms.push({
                    "businessId": loanApp.businessId,
                    "applicationId": loanApp.applicationId,
                    creditLimit: parseFloat(offer.creditLimit.toString().replaceAll(',', '')),
                    productType: offer.productType,
                    advanceFee: parseFloat(offer.advanceFee.toString().replaceAll(',', '')),
                    advanceFactor: offer.advanceFactor && parseFloat(offer.advanceFactor.toString().replaceAll(',', '')),
                    repaymentTerms: offer.repaymentTerms && parseInt(offer.repaymentTerms.split(' ')[0]),
                    platformFee: offer.productType == 'GNPL' ? parseFloat(offer.gnplPlatformFee.toString().replaceAll(',', '')) : parseFloat(offer.rlocPlatformFee.toString().replaceAll(',', '')),
                    gnplPlatformFee: offer.gnplPlatformFee && parseFloat(offer.gnplPlatformFee.toString().replaceAll(',', '')),
                    rlocPlatformFee: offer.rlocPlatformFee && parseFloat(offer.rlocPlatformFee.toString().replaceAll(',', '')),
                    commitmentFee: parseFloat(offer.commitmentFee.toString().replaceAll(',', '')),
                    paymentPlan: offer.paymentPlan,
                    dailyInterest: offer.dailyInterest,
                    otherFee: parseFloat(offer.otherFee.toString().replaceAll(',', '')),
                    proposalResponseDate: offer.proposalResponseDate,
                    physicalAddress: offer.physicalAddress,
                    businessName: offer.businessName,
                    incorporationType: offer.incorporationType,
                    incorporationState: offer.incorporationState,
                    formatAddress: offer.formatAddress
                })
            });
            var body = {
                "clientBusinessId": loanApp.businessId,
                "applicationId": loanApp.applicationId,
                "offers": proposalTerms
            };

            console.log("/lsm/saveProposalTerms body", body);
            this.setState({ loading: true });
            apiPOSTReq(`${environment.lsmBaseUrl}/lsm/saveProposalTerms`, null, body, (err, resp) => {
                this.setState({ loading: false });
                try {
                    const data = resp || {};
                    if (data.result) {
                        this.setState({
                            businessInfoEdit: false
                        });
                        this.setState({
                            proposalInfoEdit: false
                        });
                        this.fetchData();
                    } else {
                        throw Error(data.responseMessage || data.error)
                    }
                } catch (error) {
                    //console.log("/getPersonCards err", error, resp);
                    ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
                }
            });
        }
    }

    validateProposalTerms = () => {
        const { proposalTerms } = this.state;

        console.log(proposalTerms);

        if (!proposalTerms.creditLimit) {
            this.setState({ errorField: "creditLimit", errorMessage: 'Please enter Credit Limit' })
            return false;
        }

        if (!proposalTerms.advanceFee) {
            this.setState({ errorField: "advanceFee", errorMessage: 'Please enter Advance Fee' })
            return false;
        }

        if (!proposalTerms.advanceFactor) {
            this.setState({ errorField: "advanceFactor", errorMessage: 'Please enter Advance Factor / Rate' })
            return false;
        }

        if (!proposalTerms.dailyInterest) {
            this.setState({ errorField: "dailyInterestRate", errorMessage: 'Please enter Daily Interest Rate' })
            return false;
        }

        if (proposalTerms.gnplPlatformFee == null) {
            this.setState({ errorField: "platformFee", errorMessage: 'Please enter Monthly Platform Fee' })
            return false;
        }

        if (!proposalTerms.rlocPlatformFee) {
            this.setState({ errorField: "platformFee", errorMessage: 'Please enter Monthly Platform Fee' })
            return false;
        }

        if (proposalTerms.commitmentFee == null) {
            this.setState({ errorField: "commitmentFee", errorMessage: 'Please enter Monthly Committment Fee' })
            return false;
        }

        if (proposalTerms.otherFee == null) {
            this.setState({ errorField: "otherFee", errorMessage: 'Please enter Other Fees' })
            return false;
        }

        if (!proposalTerms.proposalResponseDate) {
            this.setState({ errorField: "proposalResponseDate", errorMessage: 'Please select Proposal Response Date' })
            return false;
        }

        return true;
    }

    getLocation(location) {
        let addrObj = getAddressObj(location);
        addrObj.city = addrObj.city ? addrObj.city.substring(0, 18) : '';
        this.saveUserInput("physicalAddress", addrObj);
    }

    handleOnChange = (event) => {
        const target = event.target;
        this.saveUserInput(target.id || target.name, target.value)
    }

    savePaymentPlan = (value, index) => {
        this.saveOffersUserInput("paymentPlan", value, index);
        var repaymentTerm = 0;
        if (value == "Weekly") {
            repaymentTerm = "13 Weeks"
        }
        else {
            repaymentTerm = "3 Months"
        }

        this.saveRepaymentTerms(repaymentTerm, index);
    }

    saveRepaymentTerms = (value, index) => {
        const { businessAttributes } = this.state;
        var advanceFee = 0;
        var defaultAdvanceFee = businessAttributes.filter(
            item => {
                return (item.name == "DefaultAdvanceFee" && moment(item.effectiveEndDate).toDate() >= moment().toDate())
            }
        )

        if (defaultAdvanceFee.length > 0) {
            this.setState({
                isFromBusinessAttribute: false
            });

            const { business } = this.state;
            if (defaultAdvanceFee[0].additionalParameterReference != null) {
                if (defaultAdvanceFee[0].additionalParameterReference.parameters != null) {
                    defaultAdvanceFee[0].additionalParameterReference.parameters.forEach(param => {
                        if (param.name == value.split(' ')[0]) {
                            advanceFee = param.value;
                        }
                    });
                }
            }
        }

        this.saveOffersUserInput("repaymentTerms", value, index);
        if (advanceFee != 0)
            this.saveOffersUserInput("advanceFee", advanceFee, index)
    }

    saveUserInput = (id, dataToSave) => {
        var { proposalTerms, offers } = this.state;
        if (id == 'productType' && dataToSave == 'RLOC') {
            offers.splice(1, offers.length);
            offers[0].rlocPlatformFee = 0;
            offers[0].commitmentFee = 0;
            offers[0].otherFee = 0;
        }

        offers.forEach(offer => {
            offer[id] = dataToSave
        })

        proposalTerms = offers[0];
        proposalTerms[id] = dataToSave;
        this.setState({ [id]: dataToSave, proposalTerms: proposalTerms, offers: offers });

        console.log("saveUserInput", id, dataToSave, proposalTerms, offers);
    }

    saveOffersUserInput = (id, dataToSave, index) => {
        var { offers, newOffers } = this.state;
        var proposalTerms = newOffers[index];
        proposalTerms[id] = dataToSave;
        this.setState({ [id]: dataToSave, offers: offers, newOffers: newOffers });
    }

    businessDataLabels = () => {
        const { theme } = this.props;
        const { proposalTerms } = this.state;
        var { physicalAddress, businessName, incorporationType, incorporationState, formatAddress } = proposalTerms || {};
        physicalAddress = physicalAddress || {};
        var items = [
            {
                name: "Company Name",
                value: businessName
            },
            {
                name: "Formation State",
                value: incorporationState
            },
            {
                name: "Entity Type",
                value: incorporationType
            },
            {
                name: "Business Address",
                value: formatAddress
            }
        ]
        return (
            <div style={{ display: 'flex', flexDirection: 'flex-start' }}>
                <FlexColumn style={{ marginRight: 48 }}>
                    {
                        items.map(item =>
                            <>
                                <Text style={{ maxWidth: "600px" }} key={item.name}>
                                    <span style={{ color: theme.colors.systemGray, width: "250px", display: "inline-block" }}>{item.name}:</span>
                                </Text>
                                <Text margin='0 0 24px'>{item.value}</Text>
                            </>
                        )
                    }
                </FlexColumn>
            </div>
        )
    }

    businessDataEdits = () => {
        const { theme } = this.props;
        const { proposalTerms } = this.state;
        var { physicalAddress, businessName, incorporationType, incorporationState } = proposalTerms || {};
        physicalAddress = physicalAddress || {};
        var entityItems = [];
        ["C-Corp", "S-Corp", "LLC", "Partnership"].forEach(item => {
            entityItems.push(
                <Option key={item} value={item}>
                    {item}
                </Option>
            )
        });

        var usStates = []
        Object.keys(STATES_MAP).forEach(item => {
            usStates.push(
                <Option key={item} value={item}>
                    {item}
                </Option>
            )
        });

        return (
            <div style={{ display: 'flex', flexDirection: 'flex-start' }}>
                <FlexColumn style={{ marginRight: 48, width: "100%" }}>
                    <LabeledInput
                        labelcolor={this.props.theme.colors.secondary3}
                        label="Company Name"
                        key="CompanyName"
                        id="businessName"
                        value={businessName}
                        onChange={this.handleOnChange}
                    />
                    <LabeledInput
                        labelcolor={this.props.theme.colors.secondary3}
                        label="Formation State"
                        type="select"
                        size="large"
                        placeholder="Select a state"
                        optionFilterProp="children"
                        className="no-left-padding"
                        key="FormationState"
                        value={incorporationState}
                        onChange={value => { this.saveUserInput("incorporationState", value) }}
                        id="incorporationState"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        divider={true}
                    >
                        {usStates}
                    </LabeledInput>
                    <LabeledInput
                        labelcolor={this.props.theme.colors.secondary3}
                        label="Entity Type"
                        type="select"
                        size="large"
                        placeholder="Select an option"
                        optionFilterProp="children"
                        key="EntityType"
                        value={incorporationType}
                        onChange={value => { this.saveUserInput("incorporationType", value) }}
                        id="incorporationType"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        divider={true}
                    >
                        {entityItems}
                    </LabeledInput>
                    <LabeledInput
                        labelcolor={this.props.theme.colors.secondary3}
                        label="Business Address"
                        type="location"
                        key="BusinessAddress"
                        value={physicalAddress}
                        id="BusinessAddress"
                        getLocation={(location) => this.getLocation(location)}
                        placeholder={`Enter ${"Address"}`}
                        style={{ width: "250px" }}
                        lineWidth={"900px"}
                    // value={handleRowItemsValues("address", itemId)}
                    />
                    <Flex style={{ justifyContent: 'flex-end' }}>
                        <FlexColumn style={{ marginRight: "10px" }}>
                            <Button type="default" loading={false} size="large" style={{ marginRight: 0 }} text="Cancel" onClick={this.businessEditCancel}>Cancel</Button>
                        </FlexColumn>
                        <FlexColumn>
                            <Button type="primary" solid loading={false} size="large" style={{ marginRight: 0 }} text="Save" onClick={this.saveProposalTerms}>Save</Button>
                        </FlexColumn>
                    </Flex>
                </FlexColumn>
            </div>
        )
    }

    businessEditCancel = () => {
        this.setState({
            businessInfoEdit: false
        });

        this.fetchData();
    }

    proposalEditCancel = () => {
        this.setState({
            proposalInfoEdit: false
        });

        this.fetchData();
    }

    proposalDataLabels = () => {
        const { theme } = this.props;
        const { proposalTerms, offers } = this.state;
        var { productType, creditLimit, proposalResponseDate } = proposalTerms || {};
        var items = [
            {
                name: "Product Selection",
                value: productType
            },
            {
                name: "Credit Limit",
                value: "$ " + toCurrency(creditLimit)
            },
            {
                name: "Proposal Response Date",
                value: moment(proposalResponseDate).format('ll')
            },
            {
                name: "Offers",
                value: offers
            }
        ]

        return (
            <div style={{ display: 'flex', flexDirection: 'flex-start' }}>
                <FlexColumn style={{ marginRight: 48 }}>
                    {
                        items.map(item => {
                            if (item.name != "Offers") {
                                return <>
                                    <Text style={{ maxWidth: "600px" }} key={item.name}>
                                        <span style={{ color: theme.colors.systemGray, width: "250px", display: "inline-block" }}>{item.name}:</span>
                                    </Text>
                                    <Text margin='0 0 24px'>{item.value}</Text>
                                </>
                            }
                            else {
                                return <>
                                    <Text style={{ maxWidth: "600px" }} key={item.name}>
                                        <span style={{ color: theme.colors.systemGray, width: "250px", display: "inline-block" }}>{item.name}:</span>
                                    </Text>
                                    {
                                        item.value.map(val => {
                                            return <OfferCard offer={val}></OfferCard>
                                        })
                                    }
                                </>
                            }
                        })
                    }
                </FlexColumn>
            </div>
        )
    }

    disabledDate = (current) => {
        // Can not select days before today and today and weekends
        return current < moment().startOf('day');
    }

    validate = (s) => {
        var rgx = /^[0-9]*\.?[0-9]*$/;
        return s.match(rgx);
    }

    addNewOffers = () => {
        var { newOffers, offers } = this.state;
        newOffers = cloneDeep(offers);
        newOffers.push(
            offers.length > 0 ? cloneDeep(offers[0]) : {}
        );
        newOffers[newOffers.length - 1].advanceFee = 0;
        newOffers[newOffers.length - 1].paymentPlan = null;
        newOffers[newOffers.length - 1].repaymentTerms = null;
        newOffers[newOffers.length - 1].gnplPlatformFee = 0;
        newOffers[newOffers.length - 1].rlocPlatformFee = 0;
        newOffers[newOffers.length - 1].commitmentFee = 0;
        newOffers[newOffers.length - 1].otherFee = 0;
        this.setState({ newOffers: newOffers, index: newOffers.length - 1, newOffer: newOffers[newOffers.length - 1] });
    }

    proposalDataEdits = () => {
        const { theme } = this.props;
        var { proposalTerms, errorField, errorMessage, offers, showOffersModal, newOffer, newOffers, maxOffer, index, isNew } = this.state;
        var { productType, creditLimit, proposalResponseDate } = proposalTerms || {};

        offers.map(item => {
            item.repaymentTermOptions = [];
            if (item.paymentPlan == "Weekly") {
                item.repaymentTermOptions = ["13 Weeks"];
            }
            else {
                item.repaymentTermOptions = ["3 Months", "6 Months"];
            }
        })

        newOffers.map(item => {
            item.repaymentTermOptions = [];
            if (item.paymentPlan == "Weekly") {
                item.repaymentTermOptions = ["13 Weeks"];
            }
            else {
                item.repaymentTermOptions = ["3 Months", "6 Months"];
            }
        })

        return (
            <div style={{ display: 'flex', flexDirection: 'flex-start' }}>
                <FlexColumn style={{ marginRight: 48, width: "100%" }}>
                    <LabeledInput
                        label="Product Selection"
                        type='switch'
                        switchNames={["GNPL", "RLOC"]}
                        onChange={(value) => { this.saveUserInput("productType", value) }}
                        value={productType} />

                    <LabeledInput
                        labelcolor={this.props.theme.colors.secondary3}
                        label="Credit Limit"
                        type="number"
                        key="CreditLimit"
                        id="creditLimit"
                        symbolprefix="$"
                        value={creditLimit && creditLimit}
                        onChange={value => { this.saveUserInput("creditLimit", value) }}
                        min={0}
                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        error={errorField == "creditLimit"}
                        errorMessage={errorMessage}
                    />
                    <LabeledInput
                        labelcolor={theme.colors.secondary3}
                        label="Proposal Response Date"
                        key="proposalResponseDate"
                        id="proposalResponseDate"
                        type="date-picker"
                        format={dateFormatList}
                        disabledDate={this.disabledDate}
                        placeholder={moment().add(1, "day").format("MM-DD-YYYY")}
                        onChange={(date, dateStr) => this.saveUserInput("proposalResponseDate", dateStr ? moment.utc(dateStr).startOf('day') : null)}
                        value={proposalResponseDate && moment(proposalResponseDate)}
                        error={errorField == "proposalResponseDate"}
                        errorMessage={errorMessage}
                    />
                    <Text style={{ maxWidth: "600px" }} key={"Offers"}>
                        <span style={{ color: theme.colors.systemGray, width: "250px", display: "inline-block" }}>Offers:</span>
                    </Text>
                    {
                        offers.map((item, index) => {
                            return <>
                                <OfferCard offer={item} onClick={() => {
                                    var newOffers = cloneDeep(offers);
                                    this.setState({ showOffersModal: true, newOffers: newOffers, index: index, newOffer: newOffers[index], isNew: false })
                                }}></OfferCard>
                            </>
                        })
                    }
                    {
                        productType == 'GNPL' && offers.length < maxOffer && <TextButton uppercase onClick={() => {
                            this.addNewOffers();
                            this.setState({ showOffersModal: true, isNew: true })
                        }} icon={<PlusOutlined style={{ color: '#1199FF' }} />} text='Create New Offer' />
                    }
                    <Flex style={{ justifyContent: 'flex-end' }}>
                        <FlexColumn style={{ marginRight: "10px" }}>
                            <Button type="default" loading={false} size="large" style={{ marginRight: 0 }} onClick={this.proposalEditCancel} text="Cancel">Cancel</Button>
                        </FlexColumn>
                        <FlexColumn>
                            <Button type="primary" solid loading={false} size="large" style={{ marginRight: 0 }} onClick={this.saveProposalTerms} text="Save">Save</Button>
                        </FlexColumn>
                    </Flex>
                </FlexColumn>
                <Modal
                    visible={showOffersModal}
                    footer={null}
                    closable={true}
                    width={700}
                    style={{ top: 10 }}
                    destroyOnClose={true}
                    onCancel={() => {
                        this.setState({ showOffersModal: false })
                    }}
                >
                    {
                        newOffer &&
                        <FlexColumn style={{ width: "100%" }}>
                            <Text size='20px' weight="500">{isNew ? "Create New Offer" : "Update Offer"}</Text>
                            <Flex between style={{ width: "100%", marginTop: 24 }}>
                                <div style={{ width: "100%" }}>
                                    {productType == "GNPL" && <LabeledInput
                                        label="Payment Plan"
                                        type='switch'
                                        switchNames={["Weekly", "Monthly"]}
                                        onChange={(value) => { this.savePaymentPlan(value, index); }}
                                        error={errorField == "paymentPlan"}
                                        errorMessage={errorMessage}
                                        value={newOffer.paymentPlan} />
                                    }
                                    {productType == "GNPL" && <LabeledInput
                                        label="Repayment Terms"
                                        type='switch'
                                        switchNames={newOffer.repaymentTermOptions}
                                        onChange={(value) => { this.saveRepaymentTerms(value, index) }}
                                        error={errorField == "repaymentTerms"}
                                        errorMessage={errorMessage}
                                        value={newOffer.repaymentTerms} />
                                    }
                                    {
                                        productType == "GNPL" &&
                                        <LabeledInput
                                            labelcolor={this.props.theme.colors.secondary3}
                                            label={"Advance Fee"}
                                            key="AdvanceFee"
                                            id="advanceFee"
                                            value={newOffer.advanceFee && newOffer.advanceFee}
                                            symbolprefix="%"
                                            onChange={e => {
                                                if (this.validate(e.target.value)) this.saveOffersUserInput("advanceFee", e.target.value, index)
                                            }}
                                            error={errorField == "advanceFee"}
                                            errorMessage={errorMessage}
                                        />
                                    }
                                    {
                                        productType == "RLOC" &&
                                        <LabeledInput
                                            labelcolor={this.props.theme.colors.secondary3}
                                            label={"Advance Factor / Rate"}
                                            key="advanceFactor"
                                            id="advanceFactor"
                                            value={newOffer.advanceFactor && newOffer.advanceFactor}
                                            symbolprefix="%"
                                            onChange={e => {
                                                if (this.validate(e.target.value))
                                                    this.saveOffersUserInput("advanceFactor", e.target.value, index)
                                            }}
                                            error={errorField == "advanceFactor"}
                                            errorMessage={errorMessage}
                                        />
                                    }
                                    {
                                        productType == "RLOC" &&
                                        <LabeledInput
                                            labelcolor={this.props.theme.colors.secondary3}
                                            label="Daily Interest Rate"
                                            key="dailyInterestRate"
                                            id="dailyInterestRate"
                                            value={newOffer.dailyInterest && newOffer.dailyInterest}
                                            symbolprefix="%"
                                            onChange={e => {
                                                if (this.validate(e.target.value)) this.saveOffersUserInput("dailyInterest", e.target.value, index)
                                            }}
                                            error={errorField == "dailyInterest"}
                                            errorMessage={errorMessage}
                                        />
                                    }
                                    {
                                        productType == "RLOC" &&
                                        <LabeledInput
                                            labelcolor={this.props.theme.colors.secondary3}
                                            label="Monthly Platform Fee"
                                            key="PlatformFee"
                                            id="platformFee"
                                            value={newOffer.rlocPlatformFee && toCurrency(newOffer.rlocPlatformFee, 0, 0)}
                                            symbolprefix="$"
                                            onChange={e => { this.saveOffersUserInput("rlocPlatformFee", e.target.value == '' ? null : toCurrency(e.target.value, 0, 0), index) }}
                                            error={errorField == "platformFee"}
                                            errorMessage={errorMessage}
                                        />
                                    }
                                    {
                                        productType == "GNPL" &&
                                        <LabeledInput
                                            labelcolor={this.props.theme.colors.secondary3}
                                            label="Monthly Platform Fee"
                                            key="PlatformFee"
                                            id="platformFee"
                                            value={newOffer.gnplPlatformFee && toCurrency(newOffer.gnplPlatformFee, 0, 0)}
                                            symbolprefix="$"
                                            onChange={e => { this.saveOffersUserInput("gnplPlatformFee", e.target.value == '' ? null : toCurrency(e.target.value, 0, 0), index) }}
                                            error={errorField == "platformFee"}
                                            errorMessage={errorMessage}
                                        />
                                    }
                                    <LabeledInput
                                        labelcolor={this.props.theme.colors.secondary3}
                                        label="Monthly Committment Fee"
                                        key="CommittmentFee"
                                        id="commitmentFee"
                                        value={newOffer.commitmentFee && toCurrency(newOffer.commitmentFee, 0, 0)}
                                        symbolprefix="$"
                                        onChange={e => { this.saveOffersUserInput("commitmentFee", e.target.value == '' ? null : toCurrency(e.target.value, 0, 0), index) }}
                                        error={errorField == "commitmentFee"}
                                        errorMessage={errorMessage}
                                    />
                                    <LabeledInput
                                        labelcolor={this.props.theme.colors.secondary3}
                                        label="Other Fees"
                                        key="otherFees"
                                        id="otherFee"
                                        value={newOffer.otherFee && toCurrency(newOffer.otherFee, 0, 0)}
                                        symbolprefix="$"
                                        onChange={e => { this.saveOffersUserInput("otherFee", e.target.value == '' ? null : toCurrency(e.target.value, 0, 0), index) }}
                                        error={errorField == "otherFee"}
                                        errorMessage={errorMessage}
                                    />
                                    <Flex between style={{ marginTop: 40 }}>
                                        {
                                            newOffers.length > 1 && <Popconfirm
                                                title="Are you sure you want to continue?"
                                                onConfirm={() => {
                                                    newOffers.splice(index, 1);
                                                    this.setState({ showOffersModal: false, offers: cloneDeep(newOffers) })
                                                }}
                                                okText="Continue"
                                                cancelText="Cancel"
                                                placement='topLeft'
                                            >
                                                <TextButton icon={<DeleteOutlined style={{ color: "red" }} />} />
                                            </Popconfirm>
                                        }
                                        <Flex style={{ justifyContent: 'flex-end' }}>
                                            <FlexColumn style={{ marginRight: "10px" }}>
                                                <Button type="default" weight='500' loading={false} size="large" style={{ marginRight: 0 }} onClick={() => {
                                                    this.setState({ showOffersModal: false, newOffers: [] })
                                                }} text="Cancel">Cancel</Button>
                                            </FlexColumn>
                                            <FlexColumn>
                                                <Button type="primary" weight='500' solid loading={false} size="large" style={{ marginRight: 0 }} onClick={() => {
                                                    if (this.validateOffers(newOffer))
                                                        this.setState({ showOffersModal: false, offers: cloneDeep(newOffers) })
                                                }} text="OK"></Button>
                                            </FlexColumn>
                                        </Flex>
                                    </Flex>
                                </div>
                            </Flex>
                        </FlexColumn>
                    }
                </Modal >
            </div>
        )
    }

    validateOffers = (newOffer) => {
        this.setState({ errorField: '', errorMessage: '' })
        if (newOffer.productType == 'GNPL') {
            if (newOffer.paymentPlan == null) {
                this.setState({ errorField: 'paymentPlan', errorMessage: 'Please select payment plan.' })
                return false;
            }
            if (newOffer.dailyInterest == null) {
                this.setState({ errorField: 'dailyInterest', errorMessage: 'Please enter daily interest.' })
                return false;
            }
            if (newOffer.advanceFee == null) {
                this.setState({ errorField: 'advanceFee', errorMessage: 'Please enter advance fee.' })
                return false;
            }
            if (newOffer.gnplPlatformFee == null) {
                this.setState({ errorField: 'platformFee', errorMessage: 'Please enter platform fee.' })
                return false;
            }
        }
        else {
            if (newOffer.dailyInterest == null) {
                this.setState({ errorField: 'dailyInterest', errorMessage: 'Please enter daily interest.' })
                return false;
            }
            if (newOffer.advanceFactor == null) {
                this.setState({ errorField: 'advanceFactor', errorMessage: 'Please enter advance factor.' })
                return false;
            }
            if (newOffer.rlocPlatformFee == null) {
                this.setState({ errorField: 'platformFee', errorMessage: 'Please enter platform fee.' })
                return false;
            }
        }

        if (newOffer.commitmentFee == null) {
            this.setState({ errorField: 'commitmentFee', errorMessage: 'Please enter commitment fee.' })
            return false;
        }
        if (newOffer.otherFee == null) {
            this.setState({ errorField: 'otherFee', errorMessage: 'Please enter other fee.' })
            return false;
        }

        return true;
    }

    getBusinessInfo = () => {
        const { theme } = this.props;
        const { businessInfoEdit, status } = this.state;
        const businessDataLabels = this.businessDataLabels();
        const businessDataEdits = this.businessDataEdits();
        return (
            <FlexColumn start style={{ marginRight: "20px" }}>
                {/* <FlexColumn> */}
                <Text weight='500' color={theme.colors.secondary3}>
                    Business Info
                    <Space size="middle" style={{ width: "87%", justifyContent: "flex-end" }}>
                        <Tooltip title="Edit">
                            <a disabled={status == "Proposal Accepted"} onClick={() => this.setState({ businessInfoEdit: true })}><EditOutlined style={{ fontSize: 18 }} /></a>
                        </Tooltip>
                    </Space>
                </Text>
                {
                    !businessInfoEdit ?
                        <FlexColumn>
                            {businessDataLabels}
                        </FlexColumn>
                        :
                        <FlexColumn>
                            {businessDataEdits}
                        </FlexColumn>
                }
                {/* </FlexColumn> */}
            </FlexColumn>
        );
    }

    getCreditLimit = () => {
        const { loanApp } = this.props
        var body = {
            "clientBusinessId": loanApp.businessId,
            "applicationId": loanApp.applicationId,
            "rootType": 'CreditLimitEvaluation'
        }

        console.log("/ops/loans/application/getVerificationResults body", body)
        this.setState({ prescreenResultsLoaded: false })
        apiPOSTReq(`${environment.opsBaseUrl}/ops/loans/application/getVerificationResults`, null, body, (err, resp) => {
            this.setState({ prescreenResultsLoaded: true })
            try {
                const data = resp || {}
                if (data.result) {
                    var businessValidations = data.businessValidations
                    const node8 = businessValidations.filter(x => x.rank == 0);
                    this.setState({
                        creditLimit: node8.length > 0 ? node8[0] : {}
                    })
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    getCreditInfo = () => {
        const { theme } = this.props;
        const { creditLimit } = this.state;
        var { additionalParameterReference } = creditLimit || {};
        var { parameters } = additionalParameterReference || {};
        parameters = parameters || []
        var items = [];
        parameters.map(item => {
            switch (item.name) {
                case "Eligible AR":
                case "Total Debt Payments":
                case "Evaluated Credit Limit":
                case "Requested Amount":
                case "Approved Credit Limit":
                case "Proposed Credit Limit":
                    items.push({
                        name: item.name,
                        value: "$ " + toCurrency(item.value)
                    })
                    break;
                case "Advance Rate":
                case "Dilution Factor":
                    items.push({
                        name: item.name,
                        value: parseFloat(item.value) * 100 + "%"
                    })
                    break;
                case "Source of AR":
                case "Proposed Product":
                    items.push(item);
                    break;
            }
        });
        return (
            <FlexColumn start style={{ marginRight: "20px" }}>
                <Text weight='500' color={theme.colors.secondary3}>
                    Credit Info
                </Text>
                {
                    <div style={{ display: 'flex', flexDirection: 'flex-start' }}>
                        <FlexColumn style={{ marginRight: 48 }}>
                            {
                                items.map(item =>
                                    <>
                                        <Text style={{ maxWidth: "600px" }} key={item.name}>
                                            <span style={{ color: theme.colors.systemGray, width: "250px", display: "inline-block" }}>{item.name}:</span>
                                        </Text>
                                        <Text margin='0 0 24px'>{item.value}</Text>
                                    </>
                                )
                            }
                        </FlexColumn>
                    </div>
                }
            </FlexColumn>
        );
    }

    getProposalInfo = () => {
        const { theme } = this.props;
        const { proposalInfoEdit, status } = this.state;
        const proposalDataLabels = this.proposalDataLabels();
        const proposalDataEdits = this.proposalDataEdits();
        return (
            <FlexColumn start>
                {/* <FlexColumn> */}
                <Text weight='500' color={theme.colors.secondary3}>
                    Proposal Info
                    <Space size="middle" style={{ width: "87%", justifyContent: "flex-end" }}>
                        <Tooltip title="Edit">
                            <a disabled={status == "Proposal Accepted"} onClick={() => this.setState({ proposalInfoEdit: true, errorField: "", errorMessage: '' })}><EditOutlined style={{ fontSize: 18 }} /></a>
                        </Tooltip>
                    </Space>
                </Text>
                {
                    !proposalInfoEdit ?
                        <FlexColumn>
                            {proposalDataLabels}
                        </FlexColumn>
                        :
                        <FlexColumn>
                            {proposalDataEdits}
                        </FlexColumn>
                }
                {/* </FlexColumn> */}
            </FlexColumn>
        );
    }

    previewProposal = () => {
        this.setState({ showPreviewProposal: true })
    }

    render() {
        const { loading, sendProposalLoading, showPreviewProposal, proposalTerms, business, offers } = this.state;
        const { loanApp, status, theme } = this.props;
        var businessProfile = (business || {}).businessProfile || {};
        var businessInfo = businessProfile.businessInfo || {};
        if (!businessInfo.name) return null;
        var { capitalRequest } = businessInfo || {};
        console.log("proposalTerms", proposalTerms)
        return (
            <FlexColumn style={{ marginTop: "20px" }}>
                <Flex style={{ justifyContent: "flex-start", marginBottom: "50px" }}>
                    <FlexColumn style={{ marginRight: 20, width: "50%" }}>
                        <CardContainer fullWidth margin="0px 20px 20px 0px">
                            <Skeleton loading={loading} active title={false} paragraph={{ rows: 9 }}>
                                {this.getBusinessInfo()}
                            </Skeleton>
                        </CardContainer>
                        {
                            capitalRequest && parseFloat(capitalRequest.toString().replace(',', '')) < this.state.gnplHigh && <CardContainer fullWidth margin="0px 20px 0px 0px">
                                <Skeleton loading={loading} active title={false} paragraph={{ rows: 9 }}>
                                    {this.getCreditInfo()}
                                </Skeleton>
                            </CardContainer>
                        }
                    </FlexColumn>
                    {/* </Flex>
                <Flex style={{ justifyContent: "flex-start", marginBottom: "50px" }}> */}
                    <FlexColumn style={{ marginRight: 20, width: "50%" }}>
                        <CardContainer fullWidth>
                            <Skeleton loading={loading} active title={false} paragraph={{ rows: 9 }}>
                                {this.getProposalInfo()}
                            </Skeleton>
                        </CardContainer>
                    </FlexColumn>
                </Flex>
                {/* <StyledButton permtype="Override" style={{ alignSelf: 'center', width: '100%', margin: '10px 0' }} type="primary" size="large" loading={this.state.loading} onClick={this.handleSubmit}>Sign In</StyledButton> */}
                <Space size="middle" style={{ justifyContent: "flex-end" }}>
                    <Button type="primary" loading={false} style={{ marginRight: 0 }} onClick={this.previewProposal} text="Preview">Preview</Button>
                    {(status == "Manually Rejected" || status == "Proposal Declined") &&
                        <Button type="primary" solid loading={false} style={{ marginRight: 0 }} onClick={() => this.sendProposal("Manual Review")} text="Manual Review">Manual Review</Button>
                    }
                    {
                        (status == 'Proposal Expired' && (proposalTerms && moment(proposalTerms.proposalResponseDate).toDate() > moment().toDate())) && <Dropdown.Button type="primary" loading={sendProposalLoading}
                            placement="topRight"
                            icon={<DownOutlined />}
                            disabled={(status != "Proposal Ready" && status != 'Proposal Issued' && status != 'Proposal Expired')}
                            onClick={() => this.sendProposal("Proposal Issued")}
                            overlay={this.getMenu()}>
                            Issue Proposal
                        </Dropdown.Button>
                    }
                    {
                        (status != 'Proposal Expired') && <Dropdown.Button type="primary" loading={sendProposalLoading}
                            placement="topRight"
                            icon={<DownOutlined />}
                            disabled={(status != "Proposal Ready" && status != 'Proposal Issued' && status != 'Proposal Expired')}
                            onClick={() => this.sendProposal("Proposal Issued")}
                            overlay={this.getMenu()}>
                            Issue Proposal
                        </Dropdown.Button>
                    }

                </Space>
                <Modal
                    visible={showPreviewProposal}
                    footer={null}
                    closable={true}
                    width={1400}
                    style={{ top: 10 }}
                    destroyOnClose={true}
                    onCancel={() => {
                        this.setState({ showPreviewProposal: false })
                    }}
                >
                    <Text heading>Proposal Preview</Text>
                    <CustomerProposal loanApplication={loanApp} proposalTerms={proposalTerms} offers={offers} proposalPreview={true} theme={theme}></CustomerProposal>
                </Modal >
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)));