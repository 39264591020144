import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import { Tabs, Modal, Space, Select, message, Popconfirm, Table } from 'antd'

import moment from 'moment'
import { getBBAccountsWithAccess, getFeatureLimit } from '../../../../Utils/util'
import { Container, FlexColumn } from '../../../Reusable/Container'
import PageHeader from '../../../Reusable/PageHeader'
import PayContractors from './PayContractors'

import { Button } from '../../../Reusable/Button'
import { Tag } from '../../../Reusable/Text'
import { apiPOSTReq, apiGET } from '../../../../Utils/api'
import { getResourcePerm } from '../../../../Utils/util'
import environment from '../../../../environment'
import { ErrorAlert } from '../../../Reusable/Alert'
import ModalClose from '../../../../Images/modal-close.png'


const { TabPane } = Tabs
const { Option } = Select
var dFormat = "YYYY-MM-DD"

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
})

class Index extends Component {
    state = {
        loading: true,
        selectedTab: "Aion",
        pagination: {
            current: 1,
            pageSize: 100
        },
        contractorPagination: {
            current: 1,
            pageSize: 100,
            showSizeChanger: false,
        },
        payGroupPagination: {
            current: 1,
            pageSize: 100,
            showSizeChanger: false,
        },
        modalWidth: 700,
        selectedRowKeys: [], // Check here to configure the default column
        payments: [],
        templates: [],
        totalScheduled: '',
        scheduledByAccounts: {},
    }

    componentDidMount() {
        this.fetchContractors({ pagination: this.state.contractorPagination })
        this.fetchTemplate({ pagination: this.state.payGroupPagination })
        this.fetchPayments({ pagination: this.state.pagination })
        this.fetchAccount()
    }

    fetchAccount = () => {
        let accounts = getBBAccountsWithAccess("Payments")
        apiGET("/banking/radius/payments/scheduled", {}, (err, resp) => {
            try {
                const data = resp.data || {}
                if (data.success) {
                    this.setState({
                        totalScheduled: data.totalScheduled,
                        scheduledByAccounts: data.scheduledByAccounts,
                        accounts: accounts,
                        scheduled: data.scheduledByAccounts || {},
                    })
                } else {
                    throw Error(data.msg || "Sorry we had trouble processing your request, please try again.")
                }
            } catch (error) {
                ErrorAlert({ description: error.message })
            }
        })
    }

    fetchPayments = (options) => {
        const { pagination } = options
        var body = {
            "size": pagination.pageSize,
            "page": pagination.current - 1,
            "sortDirection": "DESC",
        }

        apiPOSTReq(`${environment.payBaseUrl}/payables/getContractorPayments`, {}, body, (err, resp) => {
            try {
                const data = resp
                console.log("/payables/getContractorPayments", data)
                if (data.result) {
                    this.setState({
                        loading: false,
                        payLoading: false,
                        deleteLoading: false,
                        payments: data.contractorPaymentList || [],
                        pagination: {
                            ...pagination,
                            total: data.contractorPaymentCount
                        }
                    })
                } else {
                    this.setState({ loading: false, payLoading: false, deleteLoading: false })
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /payables/getContractorPayments", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    fetchContractors = (options) => {
        const { pagination } = options
        const body = {
            "size": pagination.pageSize,
            "page": pagination.current - 1,
        }
        apiPOSTReq(`${environment.payBaseUrl}/payables/contractors/getContractors`, {}, body, (err, resp) => {
            try {
                const data = resp
                console.log("/payables/contractors/getContractors", JSON.stringify(data))
                if (data.result) {
                    this.setState({
                        ...data,
                        contractors: data.contractors,
                        contractorPagination: {
                            ...pagination,
                            total: data.count
                        }
                    })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /payables/contractors/getContractors", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    handleContractorTableChange = (pagination, filters, sorter) => {
        this.fetchContractors({ pagination })
    }

    handlePayGroupTableChange = (pagination, filters, sorter) => {
        this.fetchTemplate({ pagination })
    }

    fetchTemplate = (options) => {
        const { pagination } = options
        const body = {
            "size": pagination.pageSize,
            "page": pagination.current - 1
        }
        apiPOSTReq(`${environment.payBaseUrl}/payables/contractors/listPaymentTemplates`, {}, body, (err, resp) => {
            try {
                const data = resp
                console.log("/payables/contractors/listPaymentTemplates", JSON.stringify(data))
                if (data.contractorPaymentTemplateNames) {
                    this.setState({
                        ...data,
                        templates: data.contractorPaymentTemplateNames || [],
                        payGroupPagination: {
                            ...pagination,
                            total: data.count
                        }
                    })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /payables/contractors/listPaymentTemplates", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    cancelContractorPayment = (payment) => {
        console.log("cancelContractorPayment payment", payment)
        const body = {
            "contractorPaymentId": payment.id
        }
        console.log("cancelContractorPayment body", body)
        this.setState({ deleteLoading: true })
        apiPOSTReq(`${environment.payBaseUrl}/payables/cancelContractorPayment`, {}, body, (err, resp) => {
            try {
                this.setState({ deleteLoading: false })
                const data = resp
                console.log("/payables/cancelContractorPayment", data)
                if (data.result) {
                    message.success(`Successfully deleted!`)
                    this.fetchPayments({ pagination: this.state.pagination })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR deletecontractor", error, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    handleTableChange = (pagination, filters, sorter) => {
        this.fetchPayments({ pagination: pagination })
    }

    onChangeTab = (activeKey) => {
        this.fetchPayments({
            activeKey: activeKey,
            pagination: {
                current: 1,
                pageSize: 100
            }
        })
    }

    render() {
        const { loading, pagination, contractors, templates, selectedRowKeys, payments, showPayContractorModal } = this.state
        const { theme, aionStore, bankingStore } = this.props
        const { Attributes } = aionStore
        var { Businesses } = Attributes || {}

        let { BusinessDailyLimits } = bankingStore
        var businessDailyLimits = BusinessDailyLimits || {}
        const columns = [
            {
                title: 'Payment Date',
                width: 120,
                dataIndex: 'paymentDateStr',
                render: paymentDateStr => moment(paymentDateStr).format('MM/DD/YY')
            },
            {
                title: 'Name',
                width: 120,
                dataIndex: 'counterpartyName',
            },
            {
                title: 'Amount',
                width: 120,
                dataIndex: 'totalAmount',
                render: amount => formatter.format(amount)
            },
            {
                title: 'Payment Method',
                width: 120,
                dataIndex: 'paymentType',
                render: paymentType => paymentType ? <Tag style={{ margin: "5px 0" }} tagpreset={theme.colors.secondary4}>{paymentType === "BillPay" ? "Check" : paymentType}</Tag> : null
            },
            {
                title: 'Status',
                width: 80,
                dataIndex: 'status',
                render: status => status ? <Tag style={{ margin: "5px 0" }} tagpreset={theme.colors.secondary4}>{status}</Tag> : null
            },
            {
                title: 'Note',
                width: 220,
                dataIndex: 'statusMessage',
                render: note => note ? note : "--"
            },
        ]

        const contractorPaymentsPerm = getResourcePerm("Payables.Contractor Payments")

        if (contractorPaymentsPerm.manage) {
            columns.push(
                {
                    title: '',
                    key: 'action',
                    align: 'center',
                    width: 80,
                    dataIndex: 'status',
                    render: (status, record) => (
                        ["new", "scheduled", "pending"].includes(status.toLowerCase()) &&
                        <Space size="middle">
                            <Popconfirm
                                title="Are you sure you want to cancel this payment?"
                                onConfirm={() => this.cancelContractorPayment(record)}
                                okText="Confirm"
                                okButtonProps={{ loading: this.state.deleteLoading }}
                                cancelText="Cancel"
                            >
                                <a permtype="Override">Cancel</a>
                            </Popconfirm>
                        </Space>
                    ),
                }
            )
        }

        return (
            <>
                <FlexColumn className='main-padding'>
                    <PageHeader
                        titleText="Payments"
                        subtitle="Contractor Payroll"
                        ctaContent={
                            <Button solid permtype="Payables.Contractor Payments" loading={this.state.loadingNewInvoice} onClick={() => this.props.history.push('/contractor-payroll/payments/pay')} text='NEW PAYMENT' />
                        }
                    />
                    {/* <Tabs defaultActiveKey="All" onChange={this.onChangeTab}>
                        <TabPane tab="All" key="All" />
                        <TabPane tab="Scheduled" key="Scheduled" />
                        <TabPane tab="Paid" key="Sent" />
                    </Tabs> */}

                    <Container shadow>
                        <Table
                            id="contractors-table"
                            className="row-pointer"
                            tableLayout='auto'
                            columns={columns}
                            dataSource={payments}
                            rowKey='id'
                            pagination={pagination}
                            onChange={this.handleTableChange}
                            style={{ minHeight: "500px" }}
                            loading={loading}
                            scroll={{ y: '700px', x: '100%' }}
                        />
                    </Container>
                </FlexColumn>
                <Modal
                    visible={showPayContractorModal}
                    footer={null}
                    closable={true}
                    width={1000}
                    style={{ top: 20 }}
                    destroyOnClose={true}
                    onCancel={() => this.setState({ showPayContractorModal: false })}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <PayContractors
                        templates={templates && JSON.parse(JSON.stringify(templates))}
                        onPay={() => {
                            this.setState({ showPayContractorModal: false })
                            this.fetchPayments({ pagination: pagination })
                        }}
                        onSave={() => this.fetchTemplate({ pagination: this.state.payGroupPagination })}
                        accounts={this.state.accounts}
                        totalScheduled={this.state.totalScheduled}
                        scheduled={this.state.scheduled}
                        dailyLimit={businessDailyLimits.ACH || parseFloat(getFeatureLimit("BusinessBanking.Payments.Daily ACH Limit")) || 5000}
                        handleContractorTableChange={this.handleContractorTableChange}
                        contractorPagination={this.state.contractorPagination}
                        handlePayGroupTableChange={this.handlePayGroupTableChange}
                        payGroupPagination={this.state.payGroupPagination}
                    />
                </Modal>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer,
        bankingStore: state.bankingAppReducer,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)))