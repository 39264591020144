import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter, useLocation, useHistory } from 'react-router-dom'
import { saveAs } from 'file-saver'
import { withTheme } from 'styled-components'
import moment from 'moment'
import { Document, Page, pdfjs } from 'react-pdf';

import { message, Modal, Tabs, Dropdown, Menu, Select, Skeleton } from 'antd'
import { Flex, FlexColumn, CardContainer, LightContainer } from '../../Reusable/Container'
import PageHeader from '../../Reusable/PageHeader'
import { Text } from '../../Reusable/Text'
import { ErrorAlert } from '../../Reusable/Alert'
import Chart from '../../Reusable/Chart'
import { Image } from '../../Reusable/Image'
import { getBPAccountsWithAccess, getFeaturePerm, getResourcePerm, toCurrency, getTextWidth } from '../../../Utils/util'
import { Button, TextButton, ImageButton } from '../../Reusable/Button'
import { apiPOSTReq, apiGETDocUrl, getPreSignedS3Url } from '../../../Utils/api'
import { fetchBBHistory, fetchBBTransactions, normalizeAndSortHistory } from '../../../Utils/bankingDataManager'
import AccountCardList from '../Reusable/AccountCardList'
import AccountDetailsCard from '../Reusable/AccountDetailsCard'
import AddFundsModal from '../Reusable/AddFundsModal'
import ActivityTable from '../Reusable/ActivityTable'
import PendingTable from '../Reusable/PendingTable'
import TransactCard from '../Reusable/TransactCard'
import DocumentDownloadCard from '../Reusable/DocumentDownloadCard'
import { LabeledInput } from '../../Reusable/Input'
import environment from '../../../environment'
import { addDataToStore, BNK_SAVE_DATA } from '../../../Actions/actions'
import { EyeOutlined } from '@ant-design/icons'
import { FromAccountOption } from '../../Reusable/Option'
import Banner from '../../Reusable/Banner'

// Images
import AccountNameModal from '../Reusable/AccountNameModal'
import Download from '../../../Images/download.png'
import ModalClose from '../../../Images/modal-close.png'
import ArrowRight from "../../../Images/arrow-right.png"
import WarningBlue from "../../../Images/warning-blue.png"
import ChartImage from "../../../Images/chart.png"
import AionIcon from '../../../Images/aion-bank-icon.png'
import Clock from "../../../Images/clock.png"
import Edit from "../../../Images/edit.svg"
import Info from '../../../Images/info.png';

const { TabPane } = Tabs
const { Option } = Select

const AccountsDashboard = (props) => {
    const [accounts, setAccounts] = useState([])
    const [subAccounts, setSubAccounts] = useState(null)
    const [loadingAccountVerificationPDF, setLoadingAccountVerificationPDF] = useState(false)
    const [postedTransfers, setPostedTransfers] = useState([])
    const [pendingTransfers, setPendingTransfers] = useState([])
    const [selectedAccount, setSelectedAccount] = useState()
    const [selectedSubAccount, setSelectedSubAccount] = useState()
    const [tab, setTab] = useState('details')
    const [showAccountNameModal, setShowAccountNameModal] = useState(false)
    const [showNickNameModal, setShowNickNameModal] = useState(false)
    const [showAddFundsModal, setShowAddFundsModal] = useState(false)
    const [showAccountStmtModal, setShowAccountStmtModal] = useState(false)
    const [statementMap, setStatementMap] = useState(null)
    const [statementYear, setStatementYear] = useState('All')
    const [loadingPending, setLoadingPending] = useState(true)
    const [loadingPosted, setLoadingPosted] = useState(true)
    const [chartLoading, setChartLoading] = useState(true)
    const [statementsLoading, setStatementsLoading] = useState(false)
    const [currentBalance, setCurrentBalance] = useState(false)
    const [dailyBalanceChart, setDailyBalanceChart] = useState()
    const [moreToLoad, setMoreToLoad] = useState(false)
    const [currentOptions, setCurrentOptions] = useState({})
    const [pendingSums, setPendingSums] = useState({})
    const [dropdownVisible, setDropdownVisible] = useState(false)
    const [singleAccount, setSingleAccount] = useState()
    const [stmtFile, setStmtFile] = useState(null)
    const [fileName, setFileName] = useState()
    const [numPages, setNumPages] = useState()
    const [autoExpandPending, setAutoExpandPending] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')
    const [nickname, setNickname] = useState()
    const [editingNickname, setEditingNickname] = useState(false)
    const [editLoading, setEditLoading] = useState(false)
    const [morePendingTransactions, setMorePendingTransactions] = useState(false)
    const [totalPendingTransactions, setTotalPendingTransactions] = useState(0)
    const [erroredTransfers, setErroredTransfers] = useState([])
    const [bannerDismissed, setBannerDismissed] = useState(false)

    const paymentsPerm = getResourcePerm("BusinessBanking.Payments")
    const instantPaymentsEnabled = getFeaturePerm("BusinessBanking.Payments.Instant Transfer")
    const { theme, store, bankingStore } = props
    const { lastDismissedErrorId } = bankingStore

    const location = useLocation()
    const history = useHistory()

    const NUM_POSTED_TRANSFERS = 50

    useEffect(() => {
        console.log("AccountsDashboard useEffect fetchTransactions fetchOptions1: ")
        getDailyBalances()
        var Accounts = getBPAccountsWithAccess("Transactions", { getActiveAccounts: true })
        Accounts = Accounts || []
        setSingleAccount(Accounts.length === 1)
        setAccounts(Accounts)

        getErroredTransfers({ pagination: { pageSize: 50, current: 1 } })

        console.log('AccountsDashboard Accounts', Accounts)

        if (Accounts.length > 0) {
            if (location.state && location.state.selectedAccountIndex) {
                console.log("AccountsDashboard useEffect 0")
                setSelectedAccount(location.state.selectedAccountIndex)
            } else if (location.state && location.state.accountId) {
                console.log("AccountsDashboard useEffect 1")
                var index = 0

                Accounts.forEach((account, i) => {
                    if (account.accountId === location.state.accountId) index = i
                })

                // setAutoExpandPending(true)
                setSelectedAccount(index)
            } else {
                console.log("AccountsDashboard useEffect 2")
                setSelectedAccount(0)
            }
        }
    }, [])

    useEffect(() => {
        console.log("AccountsDashboard useEffect fetchTransactions fetchOptions1:  selectedAccount", selectedAccount)
        if (selectedSubAccount) setSelectedSubAccount(null)
        setSubAccounts([])
        // setErroredTransfers([])
        if (accounts.length > 0) {
            if (tab === "statements") getAccountStatements(accounts[selectedAccount].accountId)
            fetchAllData(accounts[selectedAccount])
            fetchTransactions({ account: accounts[selectedAccount], pagination: { pageSize: 50, current: 1 } })
            fetchSubAccounts(accounts[selectedAccount].accountNumber)
            setNickname(accounts[selectedAccount].nickName || `Business ${accounts[selectedAccount].accountSubType}`)
        }
    }, [selectedAccount])

    useEffect(() => {
        console.log("AccountsDashboard useEffect fetchTransactions fetchOptions1:  selectedSubAccount", selectedSubAccount)
        if (selectedSubAccount) {
            if (tab === "statements") getAccountStatements(selectedSubAccount.accountId)
            fetchAllData(selectedSubAccount)
            fetchTransactions({ account: selectedSubAccount, pagination: { pageSize: 50, current: 1 } })
            fetchSubAccounts(selectedSubAccount.accountNumber)
        } else {
            if (accounts.length > 0) {
                if (tab === "statements") getAccountStatements(accounts[selectedAccount].accountId)
                fetchAllData(accounts[selectedAccount])
                fetchTransactions({ account: accounts[selectedAccount], pagination: { pageSize: 50, current: 1 } })
                fetchSubAccounts(accounts[selectedAccount].accountNumber)
            }
        }
    }, [selectedSubAccount])

    const getDailyBalances = () => {
        setChartLoading(true)
        const body = {
            "FromDate": moment().subtract(30, 'days').format("YYYY-MM-DD"),
            "ToDate": moment().format('YYYY-MM-DD'),
        }

        apiPOSTReq(`${environment.bbBaseUrl}/bb/dailyBalancesChart`, {}, body, (err, resp) => {
            setChartLoading(false)
            try {
                const data = resp || {}
                console.log('bb/dailyBalancesChart', data)
                if (data.result) {
                    if (data.dailyBalanceChart) {
                        setDailyBalanceChart(data.dailyBalanceChart)
                    }
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("bb/dailyBalancesChart err", error, resp)
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    const getAccountStatements = (accountId) => {
        const body = {
            "AccountId": accountId,
            "FromDate": moment().subtract(1, 'years').format("YYYY-MM-DD"),
            "ToDate": moment().format('YYYY-MM-DD'),
        }

        console.log('bb/getAccountStatements', body)
        setStatementsLoading(true)
        apiPOSTReq(`${environment.bbBaseUrl}/bb/getAccountStatements`, {}, body, (err, resp) => {
            try {
                const data = resp || {}
                console.log('bb/getAccountStatements', data)
                if (data.result) {
                    var statements = data.accountStmtInfos

                    if (!statements || statements.length === 0) {
                        setStatementMap(null)
                    } else {
                        var statementMap = {}

                        statements.sort((a, b) => (a.periodStart > b.periodStart) ? 1 : ((b.periodStart > a.periodStart) ? -1 : 0)).reverse().forEach(statement => {
                            const statementYear = statement.periodStart.split('-')[0]

                            if (statementMap[statementYear]) {
                                statementMap[statementYear].push({
                                    month: moment(statement.periodStart, 'YYYY-MM-DD').format('MMMM'),
                                    url: statement.statementUrl.uri,
                                })
                            } else {
                                statementMap[statementYear] = [{
                                    month: moment(statement.periodStart, 'YYYY-MM-DD').format('MMMM'),
                                    url: statement.statementUrl.uri,
                                }]
                            }
                        })

                        setStatementMap(statementMap)
                    }
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("bb/getAccountStatements err", error, resp)
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            } finally {
                setStatementsLoading(false)
            }
        })
    }

    const downloadAccountVerificationPDF = () => {
        setLoadingAccountVerificationPDF(true)
        var query = {
            AccountId: selectedSubAccount ? selectedSubAccount.accountId : accounts[selectedAccount].accountId
        }

        apiGETDocUrl(`${environment.bbBaseUrl}/bb/export/pdf/accountVerificationTemplate`, {}, query, (err, resp) => {
            setLoadingAccountVerificationPDF(false)
            const data = (resp || {}).data
            try {
                if (err) throw Error("We had trouble downloading your account verification. Please try again.")
                const blob = new Blob([data], {
                    type: 'application/pdf',
                })
                const fileURL = URL.createObjectURL(blob)
                saveAs(fileURL, `Bank Account Letter ${moment().format('MMDDYY')}`)
            } catch (error) {
                ErrorAlert({ description: error.message })
            }
        })
    }

    const getPending = (account) => {
        return new Promise((resolve, reject) => {
            setPendingTransfers([]);
            setLoadingPending(true);
            fetchBBHistory({ viewBy: "PENDING_ACTIVITY", accountId: account.accountId }, (err, resp) => {

                if (err) {
                    reject(err);
                    return;
                }
                setTotalPendingTransactions((resp.achTransfersCount || 0) + (resp.checkDepositCount || 0) + (resp.instantTransfersCount || 0) + (resp.transactionsCount || 0) + (resp.wireTransfersCount || 0))
                resolve(resp);
            });
        });
    };

    const getPendingCardTxns = (accountNumber) => {
        return new Promise((resolve, reject) => {
            const body = {
                "AccountNumber": accountNumber,
                "RailType": "CARDMANAGEMENT",
                "ProviderStatus": "Active",
            };

            console.log('fetchAllData bb/getMemoPosts body', body);

            apiPOSTReq(`${environment.bbBaseUrl}/bb/getMemoPosts`, {}, body, (err, data) => {
                console.log('fetchAllData bb/getMemoPosts', data);

                if (err) {
                    console.log("fetchAllData bb/getMemoPosts err", err, data);
                    // ErrorAlert({ description: err.message || "Sorry, we had trouble processing your request. Please try again." });
                    reject(err);
                    return;
                }

                if (data && data.memoPostList) {
                    resolve(data.memoPostList);
                } else {
                    resolve([]);
                    // reject(new Error(data.responseMessage || data.error));
                }
            });
        });
    };

    const fetchAllData = async (account) => {
        try {
            let pendingTransfers = await getPending(account);

            if (account && account.accountNumber) {
                let memoPostList = await getPendingCardTxns(account.accountNumber);

                if (memoPostList) {
                    pendingTransfers.cardMemoList = memoPostList;
                }
            }

            var normalizedHistory = normalizeAndSortHistory(pendingTransfers);
            // if (normalizedHistory.normalizedData.length > 20) {
            //     normalizedHistory = normalizeAndSortHistory(normalizedHistory.normalizedData.slice(0, 20))
            //     console.log('fetchAllData normalizedHistory normalize 20:', normalizedHistory);
            // }
            setPendingTransfers(normalizedHistory.normalizedData);
            setPendingSums(normalizedHistory.pendingSums);
            setLoadingPending(false);
        } catch (error) {
            console.log('fetchAllData ERROR:', error);
            // Handle the error, maybe show an error alert or something similar.
        }
    };

    const fetchTransactions = (options, callback) => {
        var { fromDate, toDate, searchStr, account, fromAmt, toAmt, railList, viewBy } = options

        if (!account) account = selectedSubAccount ? selectedSubAccount : accounts[selectedAccount]

        if (account) {
            var fetchOptions = {
                accountId: account.accountId,
                pagination: options.pagination // || pagination
            }

            console.log('fetchTransactions fetchOptions1:', fetchOptions)

            if (searchStr && searchStr != '') fetchOptions.searchStr = searchStr
            if (fromDate) fetchOptions.fromDate = fromDate
            if (toDate) fetchOptions.toDate = toDate
            if (fromAmt) fetchOptions.fromAmt = fromAmt
            if (toAmt) fetchOptions.toAmt = toAmt
            if (railList) fetchOptions.railList = railList
            if (viewBy && searchStr && searchStr != '') fetchOptions.viewBy = viewBy

            fetchOptions.bankProvider = account.bankProvider
            fetchOptions.providerStatus = "Posted"
            setLoadingPosted(true)

            setCurrentOptions(fetchOptions)
            fetchBBTransactions(fetchOptions, (err, resp) => {
                try {
                    if (err) throw new Error(err)
                    console.log("fetchData fetchBBTransactions fetchOptions", fetchOptions)
                    console.log("fetchData fetchBBTransactions resp", resp)

                    const newData = fetchOptions.pagination.current > 1 ? postedTransfers.concat(resp.Transactions) : resp.Transactions

                    setMoreToLoad(newData.length !== resp.transactionsCount)
                    setPostedTransfers(newData)

                    if (!searchStr) {
                        props.dispatch(addDataToStore(BNK_SAVE_DATA, resp || {}))
                    }
                } catch (error) {
                    ErrorAlert({ description: error.message })
                } finally {
                    setLoadingPosted(false)
                }
            })
        }
    }

    const getErroredTransfers = (options) => {
        var { fromDate, toDate, searchStr, account, fromAmt, toAmt, railList, viewBy, pagination } = options

        const body = {
            viewBy: "PAYMENT_STATUS",
            paymentStatus: ['canceled', 'error', 'Rejected', 'denied_approval'],
            // accountId: account.accountId,
            pagination: pagination,
        }

        body.fromDate = moment().subtract(7, 'days').format("YYYY-MM-DD")
        body.toDate = moment().format('YYYY-MM-DD')

        console.log('getErroredTransfers fetchOptions0: body', body)

        fetchBBHistory(body, (err, resp) => {
            if (resp) {
                console.log('getErroredTransfers fetchOptions0: resp', resp)
                const normalizedTransfers = normalizeAndSortHistory(resp)
                console.log('getErroredTransfers fetchOptions0: normalizedHistory', normalizedTransfers)

                const sevenDaysAgo = moment().subtract(7, 'days');

                const filteredTransfers = normalizedTransfers.normalizedData.filter(transaction => {
                    const createdAt = moment(transaction.createdAt);
                    return createdAt.isSameOrAfter(sevenDaysAgo);
                });

                console.log('getErroredTransfers fetchOptions0: filteredTransfers', filteredTransfers)

                setErroredTransfers(filteredTransfers)
            }
        })
    }

    const fetchSubAccounts = (accountNumber) => {
        console.log("/bb/getSubAccounts", { "BankProvider": environment.bankProvider.crb, "AccountNumber": accountNumber, "BusinessId": store.BusinessUniqueKey });
        apiPOSTReq(`${environment.bbBaseUrl}/bb/getSubAccounts`, { "BankProvider": environment.bankProvider.crb }, { "BankProvider": environment.bankProvider.crb, "AccountNumber": accountNumber, "BusinessId": store.BusinessUniqueKey }, (err, resp) => {
            try {
                console.log("/bb/getSubAccounts", err, resp);
                const data = resp;
                if (data.result) {
                    if (err) throw new Error(err);
                    console.log('getSubAccounts', data.subAccounts)

                    if (data.subAccounts && data.subAccounts.length > 0) {
                        setSubAccounts(data.subAccounts)
                    }
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getSubAccounts", error, err, resp);
            }
        })
    }

    const onTabChange = tab => {
        if (tab === "statements") getAccountStatements(accounts[selectedAccount].accountId)
        setTab(tab)
    }

    const handleStatementYear = (value) => {
        setStatementYear(value)
    }

    const loadMoreActivity = () => {
        currentOptions.pagination.current = currentOptions.pagination.current + 1
        fetchTransactions(currentOptions)
    }

    const downloadStatement = (url, name) => {
        getPreSignedS3Url({ url: url }, (err, preSignedUrl) => {
            saveAs(preSignedUrl, name)
        })
    }

    const viewStatement = (url, name) => {
        getPreSignedS3Url({ url: url }, (err, preSignedUrl) => {
            setStmtFile({ preSignedUrl, name })
        })
    }

    const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
        setNumPages(nextNumPages)
    }

    const handleSelectSubAccount = (value, option) => {
        var subAccount = subAccounts.find(a => a.accountNumber === value)
        setSelectedSubAccount(subAccount)
    }

    const updateNickname = () => {
        var body = {
            AccountNumber: accounts[selectedAccount].accountNumber,
            AccountNickName: nickname
        }

        setEditLoading(true)
        apiPOSTReq(`${environment.bbBaseUrl}/bb/updateAccountNickName`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            setEditLoading(false)
            try {
                const data = resp || {}
                if (data.result) {
                    accounts[selectedAccount].nickName = nickname
                    setAccounts(accounts)
                    setSelectedAccount(selectedAccount)
                    setEditingNickname(false)
                    setShowNickNameModal(false)
                    console.log("bb/updateAccountNickName", data)
                    message.success('Account name set')
                    props.dispatch(addDataToStore(BNK_SAVE_DATA, { BBAccounts: accounts }))
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/updateAccountNickName err", error, resp, body)
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    const maxBalanceWidth = 64 + accounts.reduce((max, account) => {
        const textWidth = getTextWidth(`$${toCurrency(account.availableBalance)}`, '500 28px InterDisplay');
        return Math.max(max, textWidth);
    }, 0);

    const getRailsMessage = (transactions) => {
        const railsSet = new Set();

        transactions.forEach(transaction => {
            if (transaction.status !== 'successful') {
                railsSet.add(transaction.type);
            }
        });

        const railsArray = Array.from(railsSet);
        const railsString = railsArray.join('/');

        return <Flex start centerHorizontally gap='4px'>
            You have recent unsuccessful
            {railsArray.map((rail, index) => (
                <React.Fragment key={rail}>
                    <TextButton weight='400' underline text={rail} onClick={() => history.push({ pathname: '/banking/transfers', state: { tab: rail, fromErrorBanner: true } })} />
                    {index < railsArray.length - 1 && '/'}
                </React.Fragment>
            ))}
            transfers on your account
        </Flex>
    };

    return (
        <FlexColumn className='main-top-padding' style={{ width: '100%', margin: 0, paddingLeft: 0 }} start>
            <PageHeader
                titleText='Banking'
                subtitle='Banking'
                marginLeft='36px'
                marginBottom='0'
                marginRight='24px'
                ctaContent={accounts && accounts.length > 0 && accounts[selectedAccount].accountSubType !== 'Transitional' &&
                    <Dropdown
                        trigger={['click']}
                        placement="bottomRight"
                        overlay={<Menu style={{ padding: 24, borderRadius: 8 }}>
                            <FlexColumn gap='18px'>
                                {
                                    instantPaymentsEnabled.manage &&
                                    <TextButton rightIcon={<Image src={ArrowRight} />} onClick={() => {
                                        setDropdownVisible(false)
                                        props.history.push({
                                            pathname: '/banking/instant-transfers/transfer-to-recipient',
                                            state: { rail: 'Instant', fromAccount: accounts[selectedAccount] }
                                        })
                                    }} text={"Instant transfer".toLocaleUpperCase()} />
                                }
                                <TextButton rightIcon={<Image src={ArrowRight} />} onClick={() => {
                                    setDropdownVisible(false)
                                    props.history.push({
                                        pathname: '/banking/ach-transfers/transfer-to-recipient',
                                        state: { rail: 'ACH', fromAccount: accounts[selectedAccount] }
                                    })
                                }} text={"ACH Transfer".toLocaleUpperCase()} />
                                <TextButton rightIcon={<Image src={ArrowRight} />} onClick={() => {
                                    setDropdownVisible(false)
                                    props.history.push({
                                        pathname: '/banking/wire-transfers/transfer-to-recipient',
                                        state: { rail: 'Wire', fromAccount: accounts[selectedAccount] }
                                    })
                                }} text={"Wire Transfer".toLocaleUpperCase()} />
                                {
                                    accounts.length > 1 &&
                                    <TextButton rightIcon={<Image src={ArrowRight} />} onClick={() => {
                                        setDropdownVisible(false)
                                        props.history.push({
                                            pathname: '/banking/internal-transfer',
                                            state: { fromAccount: accounts[selectedAccount] }
                                        })
                                    }} text={"Internal transfer".toLocaleUpperCase()} />
                                }
                                {/* <TextButton margin='0' onClick={() => { }} rightIcon={<Image src={ArrowRight} />} text="DEPOSIT DETAILS" /> */}
                                {/* <TextButton margin='0' onClick={() => props.history.push('/banking/recipients')} rightIcon={<Image src={ArrowRight} />} text="MANAGE RECIPIENTS" /> */}
                            </FlexColumn>
                        </Menu>}
                        onVisibleChange={flag => setDropdownVisible(flag)}
                        visible={dropdownVisible}
                    >
                        <Button permtype="BusinessBanking.Payments" solid text='MOVE MONEY' dropdown />
                    </Dropdown>
                }
            />

            {
                (erroredTransfers?.length > 0 && erroredTransfers[0].objectId !== lastDismissedErrorId && !bannerDismissed) &&
                <Banner icon={<Image src={Info} />} style={{ margin: "0 24px 15px 36px" }} message={getRailsMessage(erroredTransfers)} onClose={() => {
                    setBannerDismissed(true)
                    props.dispatch(addDataToStore(BNK_SAVE_DATA, { lastDismissedErrorId: erroredTransfers[0].objectId }))
                }} />
            }

            <FlexColumn between style={{ width: '100%' }}>
                {
                    accounts && accounts.length > 1 &&
                    <AccountCardList accounts={accounts} selectedAccount={selectedAccount} onSelectAccount={(i) => {
                        setEditingNickname(false)
                        setSelectedAccount(i)
                    }} />
                }

                {
                    subAccounts && subAccounts.length > 0 &&
                    <div style={{ margin: '0 24px 12px 36px' }}>
                        <LabeledInput
                            label="Subledger"
                            id="selectedSubAccount"
                            type="select"
                            width='400px'
                            placeholder="Select subledger account"
                            value={selectedSubAccount && <Flex start centerHorizontally style={{ height: 30 }}>
                                <Text><img width='16px' height='16px' src={AionIcon} style={{ marginRight: 8, marginBottom: 2 }} />{`${(selectedSubAccount.nickName || `Business ${selectedSubAccount.accountSubType}`)} • ${selectedSubAccount.mask}`}</Text>
                                {/* <Text size='14px' lightText style={{ marginLeft: 8 }}>${toCurrency(selectedSubAccount.availableBalance)}</Text> */}
                            </Flex>}
                            onChange={handleSelectSubAccount}
                            allowClear
                            onClear={() => setSelectedSubAccount(null)}
                            clearIcon={<img style={{ marginTop: -7 }} width='24px' height='24px' src={ModalClose} />}
                        >
                            {/* {
                                accounts && accounts[selectedAccount] &&
                                <Option key={accounts[selectedAccount].accountNumber} value={accounts[selectedAccount].accountNumber} style={{ backgroundColor: "transparent" }}>
                                    <FromAccountOption account={accounts[selectedAccount]} />
                                </Option>
                            } */}

                            {
                                subAccounts.map((sAccount, i) => (
                                    <Option key={sAccount.accountNumber} value={sAccount.accountNumber} style={{ backgroundColor: "transparent" }}>
                                        <FromAccountOption account={sAccount} />
                                    </Option>
                                ))
                            }
                        </LabeledInput>
                    </div>
                }

                <Tabs size="large" defaultActiveKey="details" onChange={onTabChange} style={{ margin: '0 24px 12px 36px' }} tabBarExtraContent={accounts && accounts.length > 0 && accounts[selectedAccount].accountSubType !== 'Transitional' &&
                    <Dropdown
                        trigger={['click']}
                        placement="bottomRight"
                        overlay={<Menu style={{ padding: 24, borderRadius: 8 }}>
                            <FlexColumn gap='18px'>
                                {
                                    instantPaymentsEnabled.manage &&
                                    <TextButton rightIcon={<Image src={ArrowRight} />} onClick={() => {
                                        setDropdownVisible(false)
                                        props.history.push({
                                            pathname: '/banking/instant-transfers/transfer-to-recipient',
                                            state: { rail: 'Instant', fromAccount: accounts[selectedAccount] }
                                        })
                                    }} text={"Instant transfer".toLocaleUpperCase()} />
                                }
                                <TextButton rightIcon={<Image src={ArrowRight} />} onClick={() => {
                                    setDropdownVisible(false)
                                    props.history.push({
                                        pathname: '/banking/ach-transfers/transfer-to-recipient',
                                        state: { rail: 'ACH', fromAccount: accounts[selectedAccount] }
                                    })
                                }} text={"ACH Transfer".toLocaleUpperCase()} />
                                <TextButton rightIcon={<Image src={ArrowRight} />} onClick={() => {
                                    setDropdownVisible(false)
                                    props.history.push({
                                        pathname: '/banking/wire-transfers/transfer-to-recipient',
                                        state: { rail: 'Wire', fromAccount: accounts[selectedAccount] }
                                    })
                                }} text={"Wire Transfer".toLocaleUpperCase()} />
                                {
                                    accounts.length > 1 &&
                                    <TextButton rightIcon={<Image src={ArrowRight} />} onClick={() => {
                                        setDropdownVisible(false)
                                        props.history.push({
                                            pathname: '/banking/internal-transfer',
                                            state: { fromAccount: accounts[selectedAccount] }
                                        })
                                    }} text={"Internal transfer".toLocaleUpperCase()} />
                                }
                                {/* <TextButton margin='0' onClick={() => { }} rightIcon={<Image src={ArrowRight} />} text="DEPOSIT DETAILS" /> */}
                                {/* <TextButton margin='0' onClick={() => props.history.push('/banking/recipients')} rightIcon={<Image src={ArrowRight} />} text="MANAGE RECIPIENTS" /> */}
                            </FlexColumn>
                        </Menu>}
                        onVisibleChange={flag => setDropdownVisible(flag)}
                        visible={dropdownVisible}
                    >
                        <Button permtype="BusinessBanking.Payments" solid text='MOVE MONEY' dropdown />
                    </Dropdown>
                }
                >
                    <TabPane tab="Details" key="details" />

                    {
                        accounts[selectedAccount] && accounts[selectedAccount].accountSubType !== 'Transitional' &&
                        <>
                            {
                                !selectedSubAccount &&
                                <TabPane tab="Statements" key="statements">
                                    <Skeleton loading={statementsLoading} active title={false} paragraph={{ rows: 7 }}>
                                        <Flex fullWidth fullHeight>
                                            {
                                                statementMap != null ?
                                                    <CardContainer margin='12px 0 24px' fullWidth>
                                                        <FlexColumn start>
                                                            <Text margin='0 0 12px'>Download monthly account statements</Text>
                                                            <LabeledInput
                                                                type='switch-button'
                                                                switchNames={['All'].concat(Object.keys(statementMap).reverse())}
                                                                onChange={handleStatementYear}
                                                                value={statementYear} />

                                                            {
                                                                Object.keys(statementMap).reverse().map((year, i) => {
                                                                    if (!statementYear || statementYear === 'All' || statementYear === year) {
                                                                        return <>
                                                                            <Text margin='12px 0 24px' caption>{year}</Text>
                                                                            <Flex start fullWidth wrap>
                                                                                {
                                                                                    statementMap[year].map(statement => {
                                                                                        return <LightContainer width='284px' padding='12px 24px' margin='0 24px 12px 0'>
                                                                                            <Flex between fullWidth centerHorizontally>
                                                                                                <Text>{statement.month}</Text>
                                                                                                <Flex centerHorizontally>
                                                                                                    <TextButton icon={<EyeOutlined />} onClick={() => viewStatement(statement.url, `${statement.month} ${year}`)} />
                                                                                                    <ImageButton src={Download} onClick={() => downloadStatement(statement.url, `${statement.month} ${year}`)} />
                                                                                                </Flex>
                                                                                            </Flex>
                                                                                        </LightContainer>
                                                                                    })
                                                                                }
                                                                            </Flex>
                                                                        </>
                                                                    }
                                                                })
                                                            }
                                                        </FlexColumn>
                                                    </CardContainer>
                                                    :
                                                    <LightContainer fullWidth padding='96px 24px'>
                                                        <FlexColumn center>
                                                            <Text heading>No statements available.</Text>

                                                            <Text lightText center width='297px'>Your statements have not been generated yet. Please check back at the start of next month.</Text>
                                                        </FlexColumn>
                                                    </LightContainer>
                                            }
                                        </Flex>
                                    </Skeleton>
                                </TabPane>
                            }

                            {/* <TabPane tab="Documents" key="documents" /> */}
                        </>
                    }
                </Tabs>

                {
                    tab === 'details' &&
                    <FlexColumn start gap='24px' style={{ margin: '0 24px 24px 36px' }}>
                        {
                            accounts.length > selectedAccount &&
                            <Flex fullWidth gap='24px'>
                                <CardContainer padding='0 24px 5px' fullWidth style={{ minWidth: 696, height: 220 }}>
                                    <Flex start fullWidth>
                                        <FlexColumn style={{ padding: '0 12px 24px 0' }} between>
                                            {
                                                true && // singleAccount &&
                                                <Flex start centerHorizontally style={{ marginBottom: editingNickname ? 0 : 8 }}>
                                                    {
                                                        editingNickname ?
                                                            <FlexColumn start centerHorizontally gap='2px' style={{ marginTop: singleAccount ? 16 : 16 }}>
                                                                <LabeledInput
                                                                    nomargin
                                                                    value={nickname}
                                                                    minHeight='28px'
                                                                    width='200px'
                                                                    maxLength={30}
                                                                    onChange={(event) => {
                                                                        setNickname(event.target.value)
                                                                    }}
                                                                />
                                                                <Flex start centerHorizontally gap='8px'>
                                                                    <TextButton
                                                                        weight='400'
                                                                        text='Save'
                                                                        disabled={!nickname || nickname === ''}
                                                                        loading={editLoading}
                                                                        onClick={() => {
                                                                            if (nickname && nickname.length > 0) {
                                                                                setEditLoading(true)
                                                                                updateNickname()
                                                                            }
                                                                        }} />
                                                                    <TextButton
                                                                        weight='400'
                                                                        text='Cancel'
                                                                        disabled={editLoading}
                                                                        onClick={() => {
                                                                            if (!editLoading) {
                                                                                setEditingNickname(false)
                                                                                if (accounts[selectedAccount].nickName) setNickname(accounts[selectedAccount].nickName)
                                                                            }
                                                                        }} />
                                                                </Flex>
                                                            </FlexColumn>
                                                            :
                                                            <Flex start centerHorizontally gap='8px' style={{ marginTop: singleAccount ? 18 : 26 }}>
                                                                <Text capitalize noWrap size='20px' height='40px'>{accounts[selectedAccount].nickName || `Business ${accounts[selectedAccount].accountSubType}`}</Text>
                                                                {
                                                                    accounts[selectedAccount] && accounts[selectedAccount].accountSubType !== 'Transitional' &&
                                                                    <ImageButton src={Edit} onClick={() => {
                                                                        // setEditingNickname(true)
                                                                        setShowNickNameModal(true)
                                                                    }} />
                                                                }
                                                            </Flex>
                                                    }
                                                </Flex>
                                            }

                                            {
                                                accounts[selectedAccount] && accounts[selectedAccount].accountSubType !== 'Transitional' &&
                                                <>
                                                    <LightContainer
                                                        noBorder
                                                        background='#FBFBFB'
                                                        padding='12px 24px'
                                                        width={`${maxBalanceWidth}px`}>
                                                        <Flex fullWidth between>
                                                            <FlexColumn start>
                                                                <Text noWrap size='14px'>{`${currentBalance ? 'Current balance' : 'Available balance'}`}</Text>
                                                                {
                                                                    selectedSubAccount ?
                                                                        <Text size='28px' height='40px' weight='500'>{`$${toCurrency(selectedSubAccount[currentBalance ? 'currentBalance' : 'availableBalance'])}`}</Text>
                                                                        :
                                                                        <Text size='28px' height='40px' weight='500'>{`$${toCurrency(accounts[selectedAccount][currentBalance ? 'currentBalance' : 'availableBalance'])}`}</Text>
                                                                }
                                                            </FlexColumn>
                                                        </Flex>
                                                    </LightContainer>

                                                    <TextButton margin='18px 0 0' weight='400' onClick={() => setCurrentBalance(!currentBalance)} text={`Show ${currentBalance ? 'available' : 'current'} balance`} underline />
                                                </>
                                            }
                                        </FlexColumn>

                                        {
                                            accounts[selectedAccount] && accounts[selectedAccount].accountSubType !== 'Transitional' ?
                                                <>
                                                    {
                                                        selectedSubAccount ?
                                                            <FlexColumn start style={{ width: '100%', minWidth: 432 }}>
                                                                <FlexColumn fullWidth start style={{ padding: '24px 0 24px 24px' }}>
                                                                    {
                                                                        !chartLoading &&
                                                                        <div className='chart-card-getting-started' style={{ height: singleAccount ? 170 : 141, gap: 8 }}>
                                                                            <Image src={Clock} width='48px' height='48px' margin='18px 0 0' />
                                                                            <Text width='400px' center>Charts coming soon for subledger accounts</Text>
                                                                        </div>
                                                                    }
                                                                </FlexColumn>
                                                            </FlexColumn>
                                                            :
                                                            accounts[selectedAccount] && Number(accounts[selectedAccount].currentBalance) > 0 ?
                                                                <FlexColumn start style={{ width: '100%', minWidth: 432 }}>
                                                                    {
                                                                        dailyBalanceChart && dailyBalanceChart[accounts[selectedAccount].accountNumber] && dailyBalanceChart[accounts[selectedAccount].accountNumber].data && dailyBalanceChart[accounts[selectedAccount].accountNumber].data.length > 2 ?
                                                                            <Chart
                                                                                accountId={accounts[selectedAccount].accountId}
                                                                                style={{ width: '100%' }}
                                                                                title='Posted Balances'
                                                                                height={singleAccount ? 185 : 156}
                                                                                paddingOffset={48}
                                                                                data={dailyBalanceChart[accounts[selectedAccount]?.accountNumber]?.data}
                                                                                xLabel='xlabel'
                                                                                yLabel='data2' />
                                                                            :
                                                                            <FlexColumn fullWidth start style={{ padding: '24px 0 24px 24px' }}>
                                                                                {
                                                                                    !chartLoading &&
                                                                                    <div className='chart-card-getting-started' style={{ height: singleAccount ? 170 : 141 }}>
                                                                                        <Flex between fullWidth>
                                                                                            <FlexColumn start>
                                                                                                <Text noWrap heading primary>Working on your chart</Text>
                                                                                                <Text margin='0 0 24px' size='16px'>We’ll need a few more days of data to help us show you a chart of your daily balances.</Text>
                                                                                            </FlexColumn>

                                                                                            <Image margin='0 24px 0 0' src={ChartImage} width='166px' height='80px' />
                                                                                        </Flex>
                                                                                    </div>
                                                                                }
                                                                            </FlexColumn>
                                                                    }
                                                                </FlexColumn>
                                                                :
                                                                <FlexColumn start style={{ width: '100%', minWidth: 432, margin: '24px 0 24px 24px' }}>
                                                                    <div className='add-funds-card' style={{ height: singleAccount ? 170 : 135 }}>
                                                                        <Text heading capitalize>Add Funds</Text>
                                                                        <Text size='16px'>Add funds to your account to start transacting.</Text>

                                                                        <TextButton margin='24px 0 0' onClick={() => setShowAddFundsModal(true)} rightIcon={<Image src={ArrowRight} />} text="DEPOSIT DETAILS" />
                                                                    </div>
                                                                </FlexColumn>
                                                    }
                                                </>
                                                :
                                                <FlexColumn start style={{ width: '100%', minWidth: 432, margin: '24px 12px 24px 0' }}>
                                                    <div className='add-funds-card' style={{ height: singleAccount ? 170 : 135 }}>
                                                        <Flex start centerHorizontally gap='8px'>
                                                            <Image src={WarningBlue} />
                                                            <Text heading primary capitalize>Transitional Account</Text>
                                                        </Flex>
                                                        <Text size='16px'> Please work with your customers to start paying into your newly opened Aion Accounts Receivable bank account on the platform! Starting in <b>April 2023</b>, we will be reducing support for payments coming into your transitional account. Please reach out to our customer success team in case of any questions.</Text>
                                                    </div>
                                                </FlexColumn>
                                        }
                                    </Flex>
                                </CardContainer>

                                {
                                    accounts[selectedAccount] && accounts[selectedAccount].accountSubType !== 'Transitional' &&
                                    <AccountDetailsCard
                                        height={singleAccount ? '220px' : '220px'}
                                        account={selectedSubAccount ? selectedSubAccount : accounts[selectedAccount]}
                                        onViewAccountLetter={downloadAccountVerificationPDF}
                                    />
                                }

                                {/* {
                                    singleAccount && paymentsPerm.manage &&
                                    <TransactCard noDescription height={'220px'} onAddFunds={() => setShowAddFundsModal(true)} />
                                } */}

                                {
                                    false &&
                                    <>
                                        <CardContainer
                                            padding='0'
                                            height={'200px'}
                                            width='296px'>
                                            <FlexColumn between style={{ padding: 24, width: '100%', height: '100%' }}>
                                                <Text margin='0' color={theme.colors.defaultLightText}>Send funds externally or transfer between accounts</Text>
                                                {
                                                    accounts.length > 1 ?
                                                        <Dropdown
                                                            trigger={['click']}
                                                            overlay={<Menu style={{ padding: 24, borderRadius: 8 }}>
                                                                <FlexColumn gap='18px'>
                                                                    <TextButton rightIcon={<Image src={ArrowRight} />} onClick={() => {
                                                                        setDropdownVisible(false)
                                                                        props.history.push('/banking/transfer-to-recipient')
                                                                    }} text="Transfer to a recipient" />
                                                                    <TextButton rightIcon={<Image src={ArrowRight} />} onClick={() => {
                                                                        setDropdownVisible(false)
                                                                        props.history.push('/banking/internal-transfer')
                                                                    }} text="Internal transfer" />
                                                                </FlexColumn>
                                                            </Menu>}
                                                            onVisibleChange={flag => setDropdownVisible(flag)}
                                                            visible={dropdownVisible}
                                                        >
                                                            <TextButton margin='0' onClick={() => setDropdownVisible(!dropdownVisible)} rightIcon={<Image src={ArrowRight} />} text="MOVE MONEY" />
                                                        </Dropdown>
                                                        :
                                                        <TextButton margin='0' onClick={() => props.history.push('/banking/transfer-to-recipient')} rightIcon={<Image src={ArrowRight} />} text="MOVE MONEY" />
                                                }
                                            </FlexColumn>
                                        </CardContainer>

                                        <CardContainer
                                            padding='0'
                                            height={'200px'}
                                            width='296px'>
                                            <FlexColumn between style={{ padding: 24, width: '100%', height: '100%' }}>
                                                <Text margin='0' color={theme.colors.defaultLightText}>Add funds from an external source</Text>
                                                <TextButton margin='0' onClick={() => { }} rightIcon={<Image src={ArrowRight} />} text="DEPOSIT DETAILS" />
                                            </FlexColumn>
                                        </CardContainer>

                                        <CardContainer
                                            padding='0'
                                            height={'200px'}
                                            width='296px'>
                                            <FlexColumn between style={{ padding: 24, width: '100%', height: '100%' }}>
                                                <Text margin='0' color={theme.colors.defaultLightText}>Add or manage recipients</Text>
                                                <TextButton margin='0' onClick={() => props.history.push('/banking/recipients')} rightIcon={<Image src={ArrowRight} />} text="MANAGE RECIPIENTS" />
                                            </FlexColumn>
                                        </CardContainer>
                                    </>
                                }
                            </Flex>
                        }

                        {
                            (loadingPending || pendingTransfers.length >= 0) &&
                            <PendingTable
                                title='Pending Transactions'
                                subtitle={<>Incoming and outgoing transactions that we know about but have not yet been fully processed (posted) on your account.</>}
                                onCancelled={() => fetchAllData(accounts[selectedAccount])}
                                loadingPending={loadingPending}
                                pendingTransfers={pendingTransfers.length > 20 ? pendingTransfers.slice(0, 20) : pendingTransfers}
                                pendingSums={pendingSums}
                                tooltip={<FlexColumn start left>
                                    <Text color='white' size='14px'>All transactions shown are based on ET regardless of the time zone in which they were initiated.</Text>
                                    <Text color='white' size='14px'>For some debit card transactions, the amount you see listed may not be the final amount deducted<br />from your account, for example, it may initially not include a tip you added to a restaurant bill.</Text>
                                </FlexColumn>}
                                canCollapse
                                isCollapsed={(location.state && location.state.accountId) ? true : true}
                                autoExpand={(location.state && location.state.accountId) ? true : false}
                                setSearchTerm={setSearchTerm}
                                padding='12px 24px'
                                moreTransactions={pendingTransfers?.length > 20}
                                // totalTransactions={totalPendingTransactions}
                                totalTransactions={pendingTransfers?.length}
                            />
                        }


                        <ActivityTable
                            key={selectedAccount}
                            filter
                            more
                            download
                            runningBalance
                            viewReturn
                            tooltip={<FlexColumn start>
                                <Text color='white' size='14px'>All transactions shown are based on ET regardless of the time zone in which they were initiated.</Text>
                            </FlexColumn>}
                            title='Posted Transactions'
                            account={accounts[selectedAccount]}
                            accountIndex={selectedAccount}
                            fetchTransactions={fetchTransactions}
                            data={postedTransfers}
                            subAccount={selectedSubAccount}
                            setSearchTerm={setSearchTerm}
                            sharedSearchTerm={searchTerm}
                            padding='4px 24px 24px 24px'
                            searchWidth={'200px'}
                            moreToLoad={moreToLoad}
                            onLoadMore={() => loadMoreActivity()}
                            loading={loadingPosted}
                            pageSize={NUM_POSTED_TRANSFERS}
                        />
                    </FlexColumn>
                }

                {
                    tab === 'documents' &&
                    <Flex start fullWidth style={{ margin: '0 24px 0 36px' }}>
                        <DocumentDownloadCard
                            width='500px'
                            title='Bank Account Letter'
                            description='This letter confirms that you currently have an active bank account with Aion'
                            onClick={downloadAccountVerificationPDF}
                            loading={loadingAccountVerificationPDF}
                        />
                    </Flex>
                }
            </FlexColumn>

            <Modal
                wrapClassName='account-name-modal'
                visible={showAccountNameModal}
                footer={null}
                closable={false}
                maskClosable={true}
                width={776}
                destroyOnClose={true}
                onCancel={() => setShowAccountNameModal(false)}
                closeIcon={<img width='24px' height='24px' src={ModalClose} />}
            >
                <AccountNameModal onCancel={() => setShowAccountNameModal(false)} />
            </Modal>

            <Modal
                visible={showNickNameModal}
                footer={null}
                closable={true}
                maskClosable={true}
                width={450}
                destroyOnClose={true}
                onCancel={() => setShowNickNameModal(false)}
                closeIcon={<img width='24px' height='24px' src={ModalClose} />}
            >

                <FlexColumn left gap='24px'>
                    <Text heading>Edit Account Name</Text>
                    <LabeledInput
                        nomargin
                        value={nickname}
                        placeholder='Enter Account Name'
                        maxLength={30}
                        onChange={(event) => {
                            setNickname(event.target.value)
                        }}
                    />
                    <Button
                        solid
                        margin='24px 0 0'
                        radius='4px'
                        text='SAVE'
                        onClick={() => {
                            if (nickname && nickname.length > 0) {
                                setEditLoading(true)
                                updateNickname()
                            }
                        }}
                        loading={editLoading} />
                </FlexColumn>
            </Modal>

            <Modal
                visible={showAddFundsModal}
                footer={null}
                closable={true}
                maskClosable={true}
                width={616}
                destroyOnClose={true}
                onCancel={() => {
                    setShowAddFundsModal(false)
                }}
                closeIcon={<img width='24px' height='24px' src={ModalClose} />}
            >
                <AddFundsModal account={accounts && accounts[selectedAccount]} onCancel={() => setShowAddFundsModal(false)} />
            </Modal>

            <Modal
                visible={stmtFile}
                footer={null}
                onCancel={() => setStmtFile(null)}
                width={700}
                style={{ top: 20 }}
                destroyOnClose={true}
            >
                <TextButton onClick={() => {
                    downloadStatement(stmtFile.preSignedUrl, stmtFile.name)
                    // setStmtFile(null)
                }} text='Download Statement' />
                <Document
                    file={stmtFile && stmtFile.preSignedUrl}
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    {
                        Array.from(
                            new Array(numPages),
                            (el, index) => (
                                <Page
                                    key={`page_${index + 1}`}
                                    pageNumber={index + 1}
                                />
                            ),
                        )
                    }
                </Document>
            </Modal>
        </FlexColumn>
    )
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer,
        bankingStore: state.bankingAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(AccountsDashboard)))