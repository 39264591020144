import React, { useState, useEffect } from 'react'
import { Table, Skeleton, Tooltip, Menu, Tabs } from 'antd'
import { withTheme } from 'styled-components'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import { saveAs } from 'file-saver'

import { Flex, FlexColumn, LightContainer, CardContainer } from '../../Reusable/Container'
import { Text, Tag } from '../../Reusable/Text'
import { Image } from '../../Reusable/Image'
import { Button, TextButton, ImageButton } from '../../Reusable/Button'
import { LabeledInput } from '../../Reusable/Input'
import { apiPOSTReq, getPreSignedS3Url, apiGETDocUrl } from '../../../Utils/api'
import { getDisplayStatus } from '../../../Utils/util'
import { ErrorAlert } from '../../Reusable/Alert'
import { ACHDetail, WireDetail, CheckDetail, InternalDetail } from './AccountDetail'
import { TransferJourney } from '../../Reusable/Approval'
import SearchAndFilter from '../../Reusable/SearchAndFilter'
import environment from '../../../environment'

// Images
import ChevronDown from "../../../Images/chevron-down.svg"
import ChevronUp from "../../../Images/chevron-up.svg"
import Info from '../../../Images/info.png'
import Return from '../../../Images/return.png'
import TableView from '../../Reusable/TableView'

const { TabPane } = Tabs

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
})

const dFormat = "ll";
const etTimeZone = "America/New_York";

const pageSize = 50;

const ACHTable = ({ sharedSearchTerm, ...props }) => {
    const [objectMap, setObjectMap] = useState({})
    const [expandedRowKeys, setExpandedRowKeys] = useState([])
    const [selectedRowId, setSelectedRowId] = useState(null)
    // const [searchTerm, setSearchTerm] = useState()

    const { theme, loading, data, onLoadMore, moreToLoad, title, subtitle, fetchTransactions, filter, showAccount, account, accountIndex, ctaContent, showErrorFilters, initialStatus,
        initialFromDate, paymentErrorCodes, more, hideType, download, searchAndFilterTitle, tooltip, subAccount, subAccounts, setSearchTerm, padding, rail, accounts, viewReturn } = props

    const onTabChange = (tab, object) => {
        object.tab = tab
        const objectMapClone = cloneDeep(objectMap)
        objectMapClone[object.objectId] = object

        setObjectMap(objectMapClone)
    }

    const getRowDetails = (record, props) => {
        var { objectId, status, type, userNote, description, transactionCode } = record
        var object = objectMap[objectId]
        var { approvalInstance, objectDetail } = (object || {})
        objectDetail = objectDetail || {}

        var additionalDetails = objectDetail.additionalDetails

        if (!objectDetail.userNote) objectDetail.userNote = userNote
        if (!objectDetail.description) objectDetail.description = description

        return (<Skeleton loading={false} active title={false} paragraph={{ rows: 3 }}>
            <FlexColumn start>
                <ACHDetail viewReturn={viewReturn} record={record} detail={record} onViewOriginal={setSearchTerm ? (referenceId) => {
                    console.log('setting setSearchTerm: ', referenceId)
                    setSearchTerm(referenceId)
                    fetchTransactions({
                        pagination: { current: 1, pageSize: pageSize },
                        searchStr: referenceId && referenceId,
                        viewBy: 'SEARCH_DESCRIPTION',
                    })
                } : false} />

                <Tabs key={`${objectId}-pendingTabs`} size="large" defaultActiveKey="transferJourney" onChange={(tab) => onTabChange(tab, object)}>
                    <TabPane tab="Transfer Journey" key="transferJourney">
                        <LightContainer fullWidth>
                            <TransferJourney approvalInstance={approvalInstance} payment={record} objectDetail={record} fromDetail={true} />
                        </LightContainer>
                    </TabPane>

                    {
                        additionalDetails && additionalDetails.length > 0 &&
                        <TabPane tab="Additional Details" key="additionalDetails">
                            <LightContainer fullWidth padding='8px 24px 12px'>
                                {
                                    additionalDetails.map(additionalDetail => {
                                        if (additionalDetail.attachmentList) {
                                            if (additionalDetail.attachmentList.length > 0) {
                                                return <FlexColumn style={{ marginBottom: 12 }}>
                                                    <Text size='14px' caption weight='400' margin='0 0 8px'>{additionalDetail.context}</Text>
                                                    <Flex start gap='8px' wrap>
                                                        {
                                                            additionalDetail.attachmentList.map((file, i) => {
                                                                return <Tag key={`${i}-${file.fileName}`} onClick={() => downloadDocument(file.uri, file.fileName)} primary noCap bordered><Image src={File} margin='0 4px 0 0' />{file.fileName}</Tag>
                                                            })
                                                        }
                                                    </Flex>
                                                </FlexColumn>
                                            }
                                        } else {
                                            if (additionalDetail.context === 'Notifications to') {
                                                return <LabeledInput type='read-only' label={additionalDetail.context} value={additionalDetail.contextValue} />
                                            }
                                        }
                                    })
                                }
                            </LightContainer>
                        </TabPane>
                    }
                </Tabs>
            </FlexColumn>
        </Skeleton>
        )
    }

    const getRowDetail = (record) => {
        if (record.approvalInstanceId) {
            getApprovalInstance(record)
        }
    }

    const getApprovalInstance = (record, objectDetail) => {
        var { objectId, approvalInstanceId } = record
        if (objectDetail) approvalInstanceId = objectDetail.approvalInstanceId
        apiPOSTReq(`${environment.bbBaseUrl}/bb/approvals/getApprovalInstance `, { "BankProvider": environment.bankProvider.crb }, { instanceId: approvalInstanceId, }, (err, resp) => {
            try {
                const data = resp
                if (data.result && data.approvalRuleInstance) {
                    const objectMapClone = cloneDeep(objectMap)
                    if (objectMapClone[objectId]) {
                        objectMapClone[objectId].approvalInstance = data.approvalRuleInstance
                    } else {
                        objectMapClone[objectId] = { approvalInstance: data.approvalRuleInstance } || true
                    }

                    if (objectDetail) objectMapClone[objectId].objectDetail = objectDetail

                    setObjectMap(objectMapClone)
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                ErrorAlert({ description: error.message })
            }
        })
    }

    const downloadDocument = (url, name) => {
        getPreSignedS3Url({ url: url }, (err, preSignedUrl) => {
            saveAs(preSignedUrl, name)
        })
    }

    var activityColumns = [{
        title: 'Created date',
        dataIndex: 'createdAt',
        key: 'createdAt',
        // width: 133,
        render: (createdAt, record) => {
            return moment.utc(createdAt).tz(etTimeZone).format('MMM DD, YYYY')
        },
        width: 140,
    }]

    activityColumns.push({
        title: 'Reference ID',
        dataIndex: 'referenceId',
        key: 'referenceId',
        render: (referenceId, record) => {
            return referenceId || '--'
        },
        // width: 150,
    })

    activityColumns.push({
        title: 'From',
        dataIndex: 'counterpartyName',
        key: 'counterpartyName',
        // width: 160,
        render: (counterpartyName, record) => {
            const { direction, transactionType, receiver, originator } = record
            var fromAccount = accounts.find(account => account.accountId === record.accountId) || {}

            switch ((direction || '').toLowerCase()) {
                case 'inbound':
                    if (transactionType === 'Push') {
                        return <Text noWrap>{counterpartyName || (originator ? originator.name : '--')}</Text>
                    } else if (transactionType === 'Pull') {
                        return <Text noWrap>{fromAccount.nickName || `Business ${fromAccount.accountSubType}`} • {fromAccount.mask}</Text>
                    } else {
                        return <Text noWrap>{fromAccount.nickName || `Business ${fromAccount.accountSubType}`} • {fromAccount.mask}</Text>
                    }
                case 'outbound':
                    if (transactionType === 'Push') {
                        return <Text noWrap>{fromAccount.nickName || `Business ${fromAccount.accountSubType}`} • {fromAccount.mask}</Text>
                    } else if (transactionType === 'Pull') {
                        return <Text noWrap>{counterpartyName || (receiver ? receiver.name : '--')}</Text>
                    } else {
                        return <Text noWrap>{counterpartyName || (receiver ? receiver.name : '--')}</Text>
                    }
                default:
                    return <Text noWrap>{fromAccount.nickName || `Business ${fromAccount.accountSubType}`} • {fromAccount.mask}</Text>
            }
        },
    })

    activityColumns.push({
        title: 'To',
        dataIndex: 'counterpartyName',
        key: 'counterpartyName',
        // width: 160,
        render: (counterpartyName, record) => {
            const { direction, transactionType, receiver, originator,  } = record
            var toAccount = accounts.find(account => account.accountId === record.accountId) || {}
            // console.log("ach counterpartyName", receiver)
            
            switch ((direction || '').toLowerCase()) {
                case 'inbound':
                    if (transactionType === 'Push') {
                        return <Text noWrap>{toAccount.nickName || `Business ${toAccount.accountSubType}`} • {toAccount.mask}</Text>
                    } else if (transactionType === 'Pull') {
                        return <Text noWrap>{counterpartyName || (originator ? originator.name : '--')}</Text>
                    } else {
                        return <Text noWrap>{counterpartyName || (originator ? originator.name : '--')}</Text>
                    }
                case 'outbound':
                    if (transactionType === 'Push') {
                        return <Text noWrap>{counterpartyName || (receiver ? receiver.name : '--')}{ receiver ? ` • ${receiver.accountNumber.substr(receiver.accountNumber.length-4, receiver.accountNumber.length)}` : "" }</Text>
                    } else if (transactionType === 'Pull') {
                        return <Text noWrap>{toAccount.nickName || `Business ${toAccount.accountSubType}`} • {toAccount.mask}</Text>
                    } else {
                        return <Text noWrap>{toAccount.nickName || `Business ${toAccount.accountSubType}`} • {toAccount.mask}</Text>
                    }
                default:
                    return <Text noWrap>{counterpartyName || (receiver ? receiver.name : '--')}</Text>
            }
        },
    })

    activityColumns.push({
        title: 'Amount',
        dataIndex: 'amount',
        key: 'Amount',
        align: "right",
        render: (amount, record) => {
            const { direction, transactionType, paymentType, wasReturned } = record
            if (record.dateRow === true) {
                return { props: { colSpan: 0 } }
            } else {
                return <Flex end centerHorizontally gap='24px'>
                    {
                        (paymentType === 'Return' || wasReturned) &&
                        <Image src={Return} />
                    }

                    {
                        (() => {
                            switch ((direction || '').toLowerCase()) {
                                case 'inbound':
                                    if (transactionType === 'Push') {
                                        return <Text noWrap weight='600' credit>+ {formatter.format(amount)}</Text>
                                    } else if (transactionType === 'Pull') {
                                        return <Text noWrap weight='600' color={theme.colors.defaultLightText}>- {formatter.format(Math.abs(amount))}</Text>
                                    } else {
                                        return <Text noWrap weight='600' color={theme.colors.defaultLightText}>{formatter.format(Math.abs(amount))}</Text>
                                    }
                                case 'outbound':
                                    if (transactionType === 'Push') {
                                        return <Text noWrap weight='600' color={theme.colors.defaultLightText}>- {formatter.format(Math.abs(amount))}</Text>
                                    } else if (transactionType === 'Pull') {
                                        return <Text noWrap weight='600' credit>+ {formatter.format(amount)}</Text>
                                    } else {
                                        return <Text noWrap weight='600' color={theme.colors.defaultLightText}>{formatter.format(Math.abs(amount))}</Text>
                                    }
                                default:
                                    return <Text noWrap weight='600' color={theme.colors.defaultLightText}>{formatter.format(Math.abs(amount))}</Text>
                            }
                        })()
                    }
                </Flex>
            }
        },
        // width: 140,
    })

    activityColumns.push({
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        align: "right",
        render: (status, record) => {
            const { direction, transactionType, wasReturned } = record
            if (record.dateRow === true) {
                return { props: { colSpan: 0 } }
            } else {
                var isCredit = true
                const lowerDirection = (direction || '').toLowerCase();

                if (lowerDirection === 'inbound') {
                    isCredit = transactionType === 'Pull' ? false : true;
                } else if (lowerDirection === 'outbound') {
                    isCredit = transactionType === 'Push' ? false : (transactionType === 'Pull' ? true : false);
                } else {
                    isCredit = true;
                }

                var displayStatus = wasReturned ? 'Returned' : getDisplayStatus(status, isCredit)

                return <Tag alert={status === 'error'} primary={status === 'pending_approval' || (displayStatus || '').toLowerCase() === 'processing'}>{displayStatus}</Tag>
            }
        },
        // width: 150,
    })
    const rowKey = 'objectId'
    return (
        <FlexColumn start {...props} fullWidth style={{ padding: padding || '24px 24px 24px' }}>
            <TableView
                id="posted-table"
                titleText='Transfers'
                descText='View all transfer activity in your bank account, including any unsuccessful ones.'
                loading={loading}
                pagination={false}
                tableLayout='auto'
                columns={activityColumns}
                dataSource={data}
                rowKey={rowKey}
                ctaContent={
                    <Flex start fullWidth gap='24px'>
                        <SearchAndFilter
                            showStatusFilter
                            showErrorFilters={showErrorFilters}
                            initialStatus={initialStatus}
                            initialFromDate={initialFromDate}
                            transactionType
                            tooltip={tooltip}
                            title={searchAndFilterTitle}
                            account={subAccount ? subAccount : account}
                            accountIndex={accountIndex}
                            more={more}
                            // download={download}
                            onChange={(options) => fetchTransactions(options)}
                            sharedSearchTerm={sharedSearchTerm}
                            rail={rail}
                            paymentErrorCodes={paymentErrorCodes}
                            dateName='Created Date'
                            placeholder='Search by From, To or Ref ID'
                            pageSize={pageSize}
                        />

                        {
                            ctaContent &&
                            ctaContent
                        }
                    </Flex>
                }
                onRow={ 
                    (record, rowIndex) => {
                        return {
                            onClick: event => {
                                if(record[rowKey] == selectedRowId) {
                                    setSelectedRowId(null)
                                } else {
                                    getRowDetail(record)
                                    setSelectedRowId(record[rowKey])
                                }
                            }, // click row
                        }
                    }
                }
                expand
                expandedRowKey={selectedRowId}
                getRowDetails={(record) => getRowDetails(record)} 
                onLoadMore={onLoadMore}
                moreToLoad={moreToLoad}
                loadingMore={loading}
                pageSize={pageSize}
            />
        </FlexColumn>
    )
}

export default (withTheme(ACHTable))