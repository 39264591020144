import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { withTheme } from 'styled-components'
import { Text } from '../../Reusable/Text'
import { ErrorAlert } from '../../Reusable/Alert'
import 'draft-js/dist/Draft.css'
import moment from 'moment'
import { saveAs } from 'file-saver'
import { Modal, message, Skeleton, Affix, Timeline, Drawer, Tabs, Table, Dropdown, Menu } from 'antd';

// Util
import { apiGET, apiGETDocUrl, apiPOSTReq } from '../../../Utils/api';
import environment from '../../../environment';

import PageHeader from "../../Reusable/PageHeader";
import { LabeledInput } from '../../Reusable/Input';
import { Flex, FlexColumn, CardContainer, LightContainer } from '../../Reusable/Container';
import { Tag } from '../../Reusable/Text';
import { Button, TextButton, ImageButton } from '../../Reusable/Button';
import { Image } from '../../Reusable/Image';
import QualifyCustomerDrawer from './QualifyCustomerDrawer'
import AlertModal from '../../Reusable/AlertModal'
import UploadPaymentProofModal from './UploadPaymentProofModal'


// Actions
import 'react-multi-email/style.css'
import ModalClose from '../../../Images/modal-close.png'
import Search from '../../../Images/search.png'
import PayerConf from '../../../Images/payer-confirmation.svg'
import PayerMsg from '../../../Images/payer-message-sent.svg'
import PayerDollar from '../../../Images/payer-dollar.svg'
import PayerUpload from '../../../Images/payer-upload.svg'
import PayerReview from '../../../Images/payer-review.svg'
import PayerConfirm from '../../../Images/payer-confirm.svg'
import EmailRead from '../../../Images/email-read.svg'
import EmailDelivered from '../../../Images/email-delivered.png'
import EmailClicked from '../../../Images/email-clicked.svg'
import EmailSent from '../../../Images/email-sent.svg'
import More from '../../../Images/more.png'
import Upload from "../../../Images/upload.svg"
import ChevronDown from "../../../Images/chevron-down.svg"
import TableView from '../../Reusable/TableView'

const { TabPane } = Tabs

class Index extends Component {
    ref = null
    loadingAnimation = null

    constructor(props) {
        super(props)
        this.state = {
            pagination: {
                current: 1,
                pageSize: 10,
            },
            customerPagination: {
                current: 1,
                pageSize: 50,
            },
            customers: [],
            payersAwaitingAcknowledgement: [],
            payersUnderReview: [],
            payers: [],
            payersAwaitingAcknowledgementCount: null,
            payersUnderReviewCount: null,
            payersCount: null,
            tab: this.props.location?.state?.tab || 'REQUESTED',
            showSentForReviewModal: false,
            showPaymentProofModal: false,
            showResendModal: false,
            subject: 'Change in payment method',
        };
    }

    componentDidMount() {
        var { tab } = this.state
        this.getPayors({ pagination: this.state.pagination, viewBy: 'REQUESTED' }, tab !== 'REQUESTED');
        this.getPayors({ pagination: this.state.pagination, viewBy: 'INREVIEW' }, tab !== 'INREVIEW');
        this.getPayors({ pagination: this.state.pagination, viewBy: 'QUALIFIED' }, tab !== 'QUALIFIED');
        this.fetchBusinessData()

        var { businessName } = this.props.aionStore
        this.setState({ fromName: businessName })
    }

    getPayors(options, ignoreTab) {
        var { tab, moreToLoad } = this.state
        var { pagination, viewBy, searchTerm } = options

        const body = {
            size: pagination.pageSize,
            page: pagination.current - 1,
            viewBy,
        }

        if (searchTerm) {
            body.searchStr = searchTerm
        }

        this.setState({ loading: true })
        apiPOSTReq(`${environment.iveBaseUrl}/ive/bc/getPayors`, null, body, (err, resp) => {
            try {
                const data = resp || {}
                console.log("Payers getPayors", data)
                if (data.result) {
                    var payers = pagination.current > 1 ? this.getPayers().concat(data.customers) : data.customers
                    var morePayors = (payers || []).length == data.count ? false : true

                    switch (viewBy) {
                        case "REQUESTED":
                            this.setState({
                                payersAwaitingAcknowledgement: payers || [],
                                pagination: {
                                    ...pagination,
                                    total: data.count
                                },
                                payersAwaitingAcknowledgementCount: (data?.count || 0),
                                tab: ignoreTab ? tab : viewBy,
                                // moreToLoad: ignoreTab ? moreToLoad : morePayors,
                            })
                            break;
                        case "INREVIEW":
                            this.setState({
                                payersUnderReview: payers || [],
                                pagination: {
                                    ...pagination,
                                    total: data.count
                                },
                                payersUnderReviewCount: (data?.count || 0),
                                tab: ignoreTab ? tab : viewBy,
                                // moreToLoad: ignoreTab ? moreToLoad : morePayors,
                            })
                            break;
                        case "QUALIFIED":
                            this.setState({
                                payers: payers || [],
                                pagination: {
                                    ...pagination,
                                    total: data.count
                                },
                                payersCount: (data?.count || 0),
                                tab: ignoreTab ? tab : viewBy,
                                // moreToLoad: ignoreTab ? moreToLoad : morePayors,
                            })
                            break;
                        default:
                            break;
                    }

                    if (!ignoreTab) this.setState({ moreToLoad: morePayors })
                } else {
                    throw Error(data.error || data.responseMessage || "Could not fetch payers")
                }
            } catch (error) {
                console.log("ERRR getPayors", error, err, resp)
                ErrorAlert({ description: error.message })
            } finally {
                this.setState({ loading: false })
            }
        })
    }

    fetchBusinessData = (options) => {
        const { aionStore } = this.props;
        var body = {
            "clientBusinessId": aionStore.BusinessUniqueKey
        };
        console.log("/ive/invoice/getBusinessData body", body);
        this.setState({ loading: true });
        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/getBusinessData`, null, body, (err, resp) => {
            this.setState({ loading: false });
            try {
                const data = resp || {};
                console.log("/ive/invoice/getBusinessData data", data);
                if (data.result) {
                    const arcommunicationInfo = ((data.business || {}).businessProfile || {}).arcommunicationInfo
                    const bbAccounts = ((data.business || {}).businessBanking || {}).accounts
                    if (arcommunicationInfo && arcommunicationInfo.cc) {
                        var ccEmails = [arcommunicationInfo.cc]
                        this.setState({ ccEmails })
                    }

                    if (bbAccounts) {
                        const arAccount = bbAccounts.find(account => (account.accountType == 'ACCOUNTS_RECEIVABLE'));
                        this.setState({ arAccount: arAccount })
                    }
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    onTabChange = (tab) => {
        this.setState({ searchTerm: null })

        this.props.history.replace({
            pathname: '/credit/ar-credit/payers',
            state: { tab }
        })

        this.getPayors({ pagination: { current: 1, pageSize: 10 }, viewBy: tab });
    }

    getPayers = () => {
        const { tab, payersAwaitingAcknowledgement, payersUnderReview, payers } = this.state

        switch (tab) {
            case "REQUESTED":
                return payersAwaitingAcknowledgement;
            case "INREVIEW":
                return payersUnderReview;
            case "QUALIFIED":
                return payers;
            default:
                return payers;
        }
    }

    uploadPaymentConfirmationDocument = (logoUrl, name) => {
        const { selectedPayer } = this.state;

        var body = {
            customerId: selectedPayer.customerId,
            paymentConfirmationDocuments: {
                name: name,
                url: {
                    FileName: name,
                    URI: logoUrl,
                    ObjectType: "Other"
                },
            }
        };

        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/uploadPaymentConfirmationDocument`, null, body, (err, resp) => {
            try {
                const data = resp || {};
                console.log("/uploadPaymentConfirmationDocument", this.state.logoUrl, data)
                if (data.result) {
                    // message.success(`Payment proof uploaded successfully!`)
                    this.setState({ selectedPayer: null, showPaymentProofModal: false, showSentForReviewModal: true })
                    this.onTabChange('INREVIEW')
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR uploadPaymentConfirmationDocument", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    loadMorePayers = () => {
        const { tab, searchTerm } = this.state

        this.getPayors({ pagination: { current: this.state.pagination.current + 1, pageSize: 10 }, viewBy: tab, searchTerm: (searchTerm && searchTerm !== "") && searchTerm });
    }

    onSearchChange = (e) => {
        const pagination = {
            current: 1,
            pageSize: 10,
        }

        const searchTerm = e.target.value

        this.setState({ searchTerm })
        this.getPayors({ pagination: pagination, viewBy: this.state.tab, searchTerm });
    }

    send = () => {
        var { fromName, subject, selectedPayer } = this.state;
        this.setState({ loadingSendEmail: true })

        var body = {
            FromName: fromName,
            Subject: subject,
            PaymentConfirmationRequest: [{
                CustomerId: selectedPayer?.customerId,
                ToAddr: selectedPayer?.primaryEmailAddress?.address,
            }],
        }

        console.log("sendMultiplePaymentConfirmations: ", body)
        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/sendMultiplePaymentConfirmations`, null, body, (err, resp) => {
            try {
                const data = resp || {}
                console.log("/sendMultiplePaymentConfirmations", data)
                if (data.result) {
                    message.success(`Payment instructions sent!`)
                    this.setState({ showResendModal: false, selectedPayer: null })
                } else {
                    message.error(`Error sending payment instructions!`)
                    throw Error(data.error || data.responseMessage || "Could not send payment confirmation email")
                }
            } catch (error) {
                console.log("ERRR sendMultiplePaymentConfirmations", error, err, resp)
                ErrorAlert({ description: error.message })
            } finally {
                this.setState({ loadingSendEmail: false })
            }
        })
    }

    render() {
        const { showQualifyCustomerDrawer, payersAwaitingAcknowledgementCount, payersUnderReviewCount, payersCount, moreToLoad,
            showPaymentProofModal, tab, showSentForReviewModal, customers, loading, selectedPayer, searchTerm, showResendModal, loadingSendEmail } = this.state
        const { data, store, theme, aionStore } = this.props

        const payers = this.getPayers()

        const columns = [
            {
                title: 'Name',
                dataIndex: 'displayName',
                key: 'displayName',
                render: (displayName, record) => {
                    return <FlexColumn start>
                        <a style={{ textDecoration: "underline" }} onClick={() => this.props.history.push({
                            pathname: '/receivables/customers/details',
                            state: { customer: record }
                        })}>{displayName}</a>
                        <Text>{record?.primaryEmailAddress?.address}</Text>
                    </FlexColumn>
                },
            },
        ]

        if (tab === 'REQUESTED') {
            columns.push({
                title: 'Mail Status',
                dataIndex: 'latestEmailStatus',
                key: 'latestEmailStatus',
                align: 'right',
                render: (latestEmailStatus, record) => {
                    switch (latestEmailStatus) {
                        case "Delivered":
                            return <Tag id={`${record.customerId}Tag`} height='32px' size='14px' primary background='#F5F6FE' radius='24px' padding='4px 12px'><Image src={EmailDelivered} margin='0 4px 0 0' /> Delivered</Tag>;
                        case "Opened":
                            return <Tag id={`${record.customerId}Tag`} height='32px' size='14px' primary background='#F5F6FE' radius='24px' padding='4px 12px'><Image src={EmailRead} margin='0 4px 0 0' /> Read</Tag>;
                        case "Clicked":
                            return <Tag id={`${record.customerId}Tag`} height='32px' size='14px' primary background='#F5F6FE' radius='24px' padding='4px 12px'><Image src={EmailClicked} margin='0 4px 0 0' /> Clicked</Tag>;
                        case "Sending":
                            return <Tag id={`${record.customerId}Tag`} height='32px' size='14px' primary background='#F5F6FE' radius='24px' padding='4px 12px'><Image src={EmailSent} margin='0 4px 0 0' /> Sending...</Tag>;
                        default:
                            return "--";
                    }
                },
            })

            columns.push({
                title: '',
                key: 'action',
                align: 'center',
                width: "40px",
                dataIndex: 'displayName',
                render: (displayName, record) => (
                    <Flex fullWidth end centerHorizontally>
                        <Dropdown
                            placement='bottomRight'
                            trigger={['click']}
                            key={`${record?.docNumber}-Dropdown`}
                            overlay={<Menu style={{ padding: 24, borderRadius: 8, zIndex: 1001 }} key={`${record?.docNumber}-Menu`} onClick={() => { }}>
                                <FlexColumn gap='24px'>
                                    <TextButton text='UPLOAD PAYMENT PROOF' onClick={() => this.setState({ selectedPayer: record, showPaymentProofModal: true })} rightIcon={<Image src={Upload} />} />
                                    <TextButton text='RESEND' onClick={() => this.setState({ selectedPayer: record, showResendModal: true })} />
                                </FlexColumn>
                            </Menu>}
                        >
                            <ImageButton src={More} />
                        </Dropdown>
                    </Flex>
                ),
            })
        }

        if (tab === 'INREVIEW') {
            columns.push({
                title: 'Upload',
                dataIndex: 'upload',
                key: 'upload',
                align: 'right',
                render: (displayName, record) => {
                    return <Flex end>
                        <TextButton
                            underline
                            weight='400'
                            text={record?.paymentConfirmationDocument?.name}
                            onClick={() => saveAs(record?.paymentConfirmationDocument?.url?.uri, record?.paymentConfirmationDocument?.name)}
                        />
                    </Flex>
                },
            })
        }

        if (tab === 'QUALIFIED') {
            columns.push({
                title: 'Qualified on',
                dataIndex: 'qualifiedOn',
                key: 'qualifiedOn',
                align: 'right',
                render: (qualifiedOn, record) => record?.verificationStatusDates?.validatedDate ? moment(record?.verificationStatusDates?.validatedDate).format('ll') : '--',
            })
        }

        return (
            <FlexColumn start className='main-padding' grow>
                <Skeleton loading={payersAwaitingAcknowledgementCount === null || payersUnderReviewCount === null || payersCount === null} active title={false} paragraph={{ rows: 7 }}>
                    {
                        (payersAwaitingAcknowledgementCount === 0 && payersUnderReviewCount === 0 && payersCount === 0) ?
                            <>
                                <Flex center gap='48px' fullHeight style={{ marginBottom: 100 }}>
                                    <FlexColumn start left style={{ width: '400px' }}>
                                        <Text heading>Payers in Aion</Text>
                                        <Text style={{ marginBottom: 24 }}>A payer is a customer who has been verified by us.<br />Adding a payer enables you to borrow against invoices<br />sent to them.</Text>
                                        <Button text='Add a new payer' solid onClick={() => this.setState({ showQualifyCustomerDrawer: true })} />
                                    </FlexColumn>

                                    <FlexColumn gap='24px' style={{ width: '400px' }}>
                                        <CardContainer>
                                            <FlexColumn gap='24px'>
                                                <Text caption>HOW DOES IT WORK?</Text>
                                                <Flex start gap='16px' centerHorizontally>
                                                    <Image width='80px' height='80px' src={PayerMsg} />
                                                    <FlexColumn start left>
                                                        <Text size='14px' weight='500' height='24px'>Notify your customers</Text>
                                                        <Text size='12px'>Inform your customers to pay into your freshly minted Aion account</Text>
                                                    </FlexColumn>
                                                </Flex>
                                                <Flex start gap='16px' centerHorizontally>
                                                    <Image width='80px' height='80px' src={PayerConf} />
                                                    <FlexColumn start left>
                                                        <Text size='14px' weight='500' height='24px'>Payment updated</Text>
                                                        <Text size='12px'>Your customer acknowledges that they are<br />going to be sending future payments to your<br />Aion account</Text>
                                                    </FlexColumn>
                                                </Flex>
                                                <Flex start gap='16px' centerHorizontally>
                                                    <Image width='80px' height='80px' src={PayerDollar} />
                                                    <FlexColumn start left>
                                                        <Text size='14px' weight='500' height='24px'>Get funded</Text>
                                                        <Text size='12px'>Get advance credit against invoices raised for<br />your customers</Text>
                                                    </FlexColumn>
                                                </Flex>
                                            </FlexColumn>
                                        </CardContainer>

                                        <CardContainer>
                                            <FlexColumn start left>
                                                <Text weight='500' height='24px'>Why is this important?</Text>
                                                <Text size='12px'>Confirming that your payers’ have updated their payments to your Aion account will help us underwrite their respective collateral and provide you the funds you need to grow your business!</Text>
                                            </FlexColumn>
                                        </CardContainer>
                                    </FlexColumn>
                                </Flex>
                            </>
                            :
                            <>
                                <PageHeader
                                    titleText="Credit"
                                    subtitle="Credit"
                                />

                                <Flex between fullWidth style={{ marginBottom: 48 }} gap='24px' stretch>
                                    <FlexColumn style={{ marginTop: 0 }} grow>
                                        <Flex start fullWidth gap='24px'>
                                            <CardContainer style={{ height: 130, width: '33.3%' }} fullWidth center>
                                                <FlexColumn start gap='10px'>
                                                    <Text weight='400' caption>Awaiting acknowledgment</Text>
                                                    <Text size='32px' height='40px'>{payersAwaitingAcknowledgementCount || 0}</Text>
                                                </FlexColumn>
                                            </CardContainer>
                                            <CardContainer style={{ height: 130, width: '33.3%' }} fullWidth center>
                                                <FlexColumn start gap='10px'>
                                                    <Text weight='400' caption>Under Review</Text>
                                                    <Text size='32px' height='40px'>{payersUnderReviewCount || 0}</Text>
                                                </FlexColumn>
                                            </CardContainer>
                                            <CardContainer style={{ height: 130, width: '33.3%' }} fullWidth center>
                                                <FlexColumn start gap='10px'>
                                                    <Text weight='400' caption>Qualified</Text>
                                                    <Flex start bottom gap='8px'>
                                                        <Text size='32px' height='40px'>{payersCount || 0}</Text>
                                                        <Text margin='0 0 5px'>/ {(payersAwaitingAcknowledgementCount || 0) + (payersUnderReviewCount || 0) + (payersCount || 0)}</Text>
                                                    </Flex>
                                                </FlexColumn>
                                            </CardContainer>
                                        </Flex>
                                        <FlexColumn>
                                            <Flex start style={{ width: '100%' }}>
                                                <Tabs size="large" style={{ width: '100%' }} activeKey={tab} onChange={this.onTabChange} >
                                                    <TabPane tab="Awaiting acknowledgement" key="REQUESTED"></TabPane>
                                                    <TabPane tab="Under Review" key="INREVIEW"></TabPane>
                                                    <TabPane tab="Qualified" key="QUALIFIED"></TabPane>
                                                </Tabs>
                                            </Flex>

                                            {
                                                tab === 'REQUESTED' &&
                                                <LightContainer padding='16px 24px' margin='12px 0 0'>
                                                    <Flex start gap='24px' centerHorizontally>
                                                        <Image src={PayerConfirm} height='64px' width='64px' />
                                                        <FlexColumn start>
                                                            <Text heading>Ask your customer to hit confirm</Text>
                                                            <Text>Great job! You have sent out notifications to the below customers. Request<br />your customers to click on the confirm button on the email notification.</Text>
                                                        </FlexColumn>
                                                    </Flex>
                                                </LightContainer>
                                            }

                                            {
                                                tab === 'INREVIEW' &&
                                                <LightContainer padding='16px 24px' margin='12px 0 0'>
                                                    <Flex start gap='24px' centerHorizontally>
                                                        <Image src={PayerReview} height='64px' width='64px' />
                                                        <FlexColumn start>
                                                            <Text heading>Your information is being reviewed by a member of our team</Text>
                                                            <Text>...we’ll reach out to you via email if we need anything else. </Text>
                                                        </FlexColumn>
                                                    </Flex>
                                                </LightContainer>
                                            }
                                            <br/>
                                            <TableView
                                                id="customer-table"
                                                titleText="Payers"
                                                tableLayout='auto'
                                                columns={columns}
                                                dataSource={payers}
                                                rowKey='customerId'
                                                pagination={false}
                                                loading={loading}
                                                ctaContent={
                                                    <LabeledInput
                                                        id="searchTerm"
                                                        key="searchTerm"
                                                        placeholder="Search by name"
                                                        prefixIcon={<Image width='12px' height='12px' src={Search} />}
                                                        onChange={this.onSearchChange}
                                                        width='400px'
                                                        nomargin
                                                        value={searchTerm}
                                                    />
                                                }
                                            />

                                            {
                                                moreToLoad &&
                                                <Flex fullWidth centerVertically style={{ marginTop: "10px" }}>
                                                    <TextButton text='LOAD 10 MORE' loading={loading} onClick={this.loadMorePayers} rightIcon={<img style={{ cursor: 'pointer' }} width='24px' height='24px' src={ChevronDown} />} />
                                                </Flex>
                                            }
                                        </FlexColumn>
                                    </FlexColumn>

                                    <Affix offsetTop={0}>
                                        <FlexColumn start gap='24px' style={{ marginTop: 0 }}>
                                            <CardContainer width='296px'>
                                                <FlexColumn start gap='24px' center>
                                                    <Image width='80px' height='80px' src={PayerMsg} />

                                                    <Text weight='600'>Payer Qualification</Text>
                                                    <Text center>Qualifying a payer in Aion requires you to send an email to this payer notifying them that you will receive future payments in your Aion receivables bank account</Text>

                                                    <Button solid text='Add new payers' onClick={() => this.setState({ showQualifyCustomerDrawer: true })} />
                                                </FlexColumn>
                                            </CardContainer>

                                            <CardContainer width='296px'>
                                                <FlexColumn start gap='24px' center>
                                                    <Image width='80px' height='80px' src={PayerUpload} />

                                                    <Text weight='600' center>Payment Update Proofs</Text>
                                                    <Text center>You can click on the action button (<Text primary>•••</Text>) for any customer and upload a PDF, screenshot or email indicating that your customer will make future payments into your Aion receivables bank account</Text>
                                                </FlexColumn>
                                            </CardContainer>
                                        </FlexColumn>
                                    </Affix>
                                </Flex>
                            </>
                    }

                    <Modal
                        visible={this.state.showFirstTimeModal}
                        footer={null}
                        closable={true}
                        width={600}
                        destroyOnClose={true}
                        onCancel={() => { this.setState({ showFirstTimeModal: false }) }}
                        closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                    >
                        <FlexColumn>
                            <Text heading>Send payment instructions</Text>
                            <Text size='14px'>Send payment instructions to your payers requesting them to confirm that they will update their records with your Aion Receivables Account bank details.</Text>
                            <br />
                            <div><Tag tagpreset={theme.colors.secondary4}>CONFIRMED</Tag><Text size='14px'> Payers have acknowledged and confirmed the payment change.</Text></div>
                            <br />
                            <div><Tag tagpreset={theme.colors.secondary7}>UNCONFIRMED</Tag><Text size='14px'> Payers have not yet confirmed the payment change.</Text></div>
                            <br />
                            <div>Once payment instructions are <Tag tagpreset={theme.colors.secondary6}>SENT</Tag> <Text size='14px'>Payer status will change to <Tag tagpreset={theme.colors.secondary6}>PENDING CONFIRMATION</Tag> until they confirm the payment change.</Text></div>
                        </FlexColumn>
                    </Modal>

                    <Drawer
                        visible={showQualifyCustomerDrawer}
                        placement="right"
                        closable={true}
                        maskClosable={true}
                        closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                        width={600}
                        destroyOnClose={true}
                        onClose={() => { this.setState({ showQualifyCustomerDrawer: false, selectedProduct: null, view: false }) }}
                    >
                        <QualifyCustomerDrawer
                            onClose={() => { this.setState({ showQualifyCustomerDrawer: false, selectedProduct: null, view: false }) }}
                            visible={showQualifyCustomerDrawer}
                            product={this.state.selectedProduct}
                            submitComplete={
                                () => {
                                    this.setState({ showQualifyCustomerDrawer: false, selectedProduct: null, tab: 'active', searchTerm: null, view: false })
                                    this.fetchData({ pagination: this.state.pagination })
                                    this.fetchInactiveProducts({ pagination: this.state.inactivePagination })
                                }
                            }
                            customers={customers}
                        />
                    </Drawer>

                    <AlertModal
                        visible={showSentForReviewModal}
                        title='Sent for review'
                        description={`The payment proof for the customer will be reviewed by our team shortly.`}
                        buttonTitle='OKAY'
                        onConfirm={() => this.setState({ showSentForReviewModal: false })}
                        imgSrc={PayerReview}
                        imgHeight='80px'
                        imgWidth='80px'
                    />

                    <AlertModal
                        visible={showResendModal}
                        title='Resend payment notification'
                        description={`Resend payment notification to ${selectedPayer?.displayName}?`}
                        buttonTitle='SEND'
                        onConfirm={() => this.send()}
                        imgSrc={PayerMsg}
                        imgHeight='80px'
                        imgWidth='80px'
                        onCancel={() => this.setState({ showResendModal: false })}
                        loading={loadingSendEmail}
                    />

                    <Modal
                        visible={showPaymentProofModal}
                        footer={null}
                        closable={true}
                        width={420}
                        destroyOnClose={true}
                        // style={{ top: 20 }}
                        onCancel={() => { this.setState({ showPaymentProofModal: false }) }}
                        closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                    >
                        <UploadPaymentProofModal
                            customer={selectedPayer}
                            onUpload={(logoUrl, name) => this.uploadPaymentConfirmationDocument(logoUrl, name)}
                        />
                    </Modal>
                </Skeleton>
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.creditAppReducer,
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)))