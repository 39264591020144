import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import moment from 'moment';

import { Skeleton, Divider, Collapse, Switch, message, Tooltip, Popconfirm, Tabs } from 'antd';
import { LeftOutlined, WarningFilled, CheckCircleFilled, InfoCircleOutlined } from '@ant-design/icons';

import { FlexColumn, Flex, CardContainer, LightContainer } from '../../Reusable/Container';
import PageHeader from '../../Reusable/PageHeader';
import ASConnection from '../../Reusable/ASConnection';
import { ErrorAlert } from '../../Reusable/Alert';
import { Text, Tag } from '../../Reusable/Text';
import { Button, TextButton } from '../../Reusable/Button';
import FlowFooter from '../../Reusable/FlowFooter'
import { SettingOutlined } from '@ant-design/icons'
import BillSettings from '../../Pay/Bills/BillSettings'

import environment from '../../../environment';
import { apiPOSTReq } from '../../../Utils/api';
import AdvancedSettings from '../QuickBooksOnline/AdvancedSettings';
import IntegrationSetup from '../Integration/Index';
import { addDataToStore, SAVE_DATA } from '../../../Actions/actions';
import { capitalizeWords } from '../../../Utils/util';
import { Image } from '../../Reusable/Image'
import disconnect from '../../../Images/disconnect.png'
import GSyncIcon from '../../../Images/integration/sync-green.png';
import BSyncIcon from '../../../Images/sync.svg';
import SettingsIcon from '../../../Images/settings.svg';
import Pipe from '../../../Images/pipe.png'
import BusinessLogo from '../../Reusable/BusinessLogo'
import { LoadingOutlined } from '@ant-design/icons'
import TableView from '../../Reusable/TableView';

const { Panel } = Collapse;

const CollapseHeader = (props) => {
    return <Flex style={{ justifyContent: 'space-between' }}>
        <Flex style={{ alignItems: 'center' }}>
            <div style={{ marginRight: '10px' }}>Sync Summary</div>
            {props.error && <WarningFilled style={{ color: props.theme.colors.warning }} />}
            {!props.error && props.lastSyncedEndDate && <CheckCircleFilled style={{ color: props.theme.colors.secondary1 }} />}
        </Flex>
        {props.error && <div>{props.error && `Unresolved Errors:`} <span style={{ color: props.theme.colors.warning }}>{props.errorNum}</span></div>}
    </Flex>
}

const connectionUI = {
    CONNECTED: 'Connected',
    DISCONNECTED: 'Disconnected',
}

class Index extends Component {
    state = {
        currentViewIndex: 0,
        historyPagination: {
            current: 1,
            pageSize: 50
        },
        errorPagination: {
            current: 1,
            pageSize: 80
        },
        qbErrors: [],
        integrationHistory: [],
        showAdvancedSetting: false,
        syncQB: false,
        isSyncInprogress: false,
        connectedPlatform: {},
        syncNow: false,
        showReconnect: true,
        selectedTab: 'Connections',
        pollInterval: null,
    };

    back = () => {
        if (this.state.showAdvancedSetting) {
            this.setState({ showAdvancedSetting: false });
        } else {
            this.props.history.replace('/settings/integration');
        }
    }

    onChangeTab = (selectedTab) => {
        this.setState({ selectedTab: selectedTab })
    }

    render() {
        const { theme, aionStore } = this.props;
        var { connections } = aionStore
        var { loading, selectedTab, isSyncInprogress, connectedPlatform, syncNow, showReconnect, disabled, submitLoading, latestEndTime } = this.state;
        const { platformName, logoUrl } = connections[0]

        const columns = [
            {
                title: 'Third Party',
                dataIndex: 'platformName',
                key: 'platformName',
            },
            {
                title: 'Connection Status',
                dataIndex: 'connectionStatus',
                key: 'connectionStatus',
                render: (status, item) => {
                    return <span style={{ color: status === 'Connected' ? theme.colors.secondary1 : theme.colors.systemGray }}>{status}</span>
                }
            },
            {
                title: 'Last Synced',
                dataIndex: 'lastSyncedEndDate',
                key: 'lastSyncedEndDate',
                render: (lastSyncedEndDate, item) => {
                    return lastSyncedEndDate ? moment(lastSyncedEndDate).format('MM/DD/YY, h:mm a') : '-'
                }
            },
            {
                title: 'Connected Date',
                dataIndex: 'connectedDate',
                key: 'connectedDate',
                render: (connectedDate, item) => {
                    return connectedDate ? moment(connectedDate).format('MM/DD/YY') : '-'
                }
            },
            {
                title: 'Connected By',
                dataIndex: 'connectedBy',
                key: 'connectedBy',
            },
        ]

        return (
            <>
                <FlexColumn className='main-padding'>
                    {/* {true && <IntegrationSetup hideKey />} */}
                    <FlexColumn>
                        <Skeleton loading={loading} active title={true} paragraph={{ rows: 5 }}>
                            <PageHeader
                                titleText="Accounting System Sync"
                                desc={""}
                                back
                            />

                            {/* <Tabs
                                activeKey={selectedTab}
                                defaultActiveKey={"Connections"}
                                onChange={this.onChangeTab}
                                style={{ marginBottom: 12 }}
                            >
                                <Tabs.TabPane tab="Connections" key="Connections" />
                                <Tabs.TabPane tab="Settings" key="Settings" />
                            </Tabs> */}

                            {
                                selectedTab === 'Connections' &&
                                <FlexColumn style={{ marginTop: 0, marginBottom: 12 }}>
                                    <ASConnection disconnect />

                                    {/* <TableView
                                            id='connection-history-table'
                                            titleText='History'
                                            rowKey='id'
                                            tableLayout='auto'
                                            columns={columns}
                                            dataSource={integrationHistory}
                                            // onRow={(record) => props.allowRowClick ? ({
                                            //     onClick: () => {
                                            //         props.selectRow(record);
                                            //     },
                                            // }) : {}}
                                            // ctaContent={props.ctaContent}
                                        // scroll={{ y: '700px', x: '100%' }}
                                        /> */}
                                </FlexColumn>
                                // </CardContainer>F
                            }

                            <CardContainer style={{ marginBottom: 48 }} padding='0'>
                                <FlexColumn between>
                                    <FlexColumn between style={{ padding: 24, marginBottom: 0 }}>
                                        <FlexColumn start style={{ width: 600 }}>
                                            <BillSettings buttonText={'SAVE'} />
                                        </FlexColumn>
                                    </FlexColumn>

                                    {/* <FlowFooter
                                            disabled={disabled}
                                            loading={submitLoading}
                                            back={false}
                                            backClick={this.handleBack}
                                            buttonTitle={'CONFIRM'}
                                            onClick={this.next}
                                            start
                                            solid
                                        /> */}
                                </FlexColumn>
                            </CardContainer>
                        </Skeleton>
                    </FlexColumn>
                </FlexColumn>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer,
        bankingStore: state.bankingAppReducer,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Index));