import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'

import { Select } from 'antd'
import { FlexColumn, Flex } from '../../Reusable/Container'
import { Text } from '../../Reusable/Text'
import { Image } from '../../Reusable/Image'
import { LabeledInput } from '../../Reusable/Input'
import { Button, TextButton } from '../../Reusable/Button'
import { ErrorAlert } from '../../Reusable/Alert'
import { apiPOSTReqPromise } from '../../../Utils/api'
import { uaApiPOST } from '../../../Utils/userAccess'
import { getFeaturePerm, checkAccessForRoles } from '../../../Utils/util'
import environment from '../../../environment'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import Asterisk from '../../../Images/asterisk.png'


const { Option } = Select

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
})

class SaveApprovalRule extends Component {

    constructor(props) {
        super(props)

        const { approvalRule, registeredUsers } = props;

        let initialState = {
            approvers: {},
            selectedApproverNames: {},
            approvalSettings: [],
            stepCount: 1,
            showEditSettingModal: false,
            level1Type: "ANY",
            level2Type: "ANY",
            level3Type: "ANY",
            level1Approvers: [],
            level2Approvers: [],
            level3Approvers: [],
            remainingApprovers: [],
            approvalType: "Recipient",
            approverDropdown1Open: false,
            approverDropdown2Open: false,
            approverDropdown3Open: false,
        }

        if (approvalRule) {
            console.log("SaveApprovalRule approvalRule", approvalRule, registeredUsers);
            const { levels, name, condition } = approvalRule;

            initialState.approvalType = approvalRule.type === "Recipient" ? "Recipient" : "Transfer"

            if(initialState.approvalType === "Transfer") initialState.paymentType = approvalRule.type === 'Payment' ? 'Instant' : approvalRule.type

            initialState = {
                ...initialState,
                name,
                stepCount: levels.length,
            };

            if (condition) condition.expressions.forEach(({ lhsValue, rhsValue }) => {
                if (lhsValue === "Payment.Amount") initialState.amount = rhsValue;
            });

            levels.forEach((level, index) => {
                if (!level) return;

                const levelKey = `level${index + 1}`;
                initialState[`${levelKey}Type`] = level.type;

                const approvers = level.performers.map(performer =>
                    registeredUsers.find(user => user.userInfo.email === performer.userName)?.objectId
                ).filter(Boolean); // Remove any undefined values (e.g., if find returns undefined)

                initialState[`${levelKey}Approvers`] = approvers;
            });
        }


        console.log("SaveApprovalRule approvalRule initialState", initialState);

        this.state = initialState;
    }

    handleOnChange = (event) => {
        const target = event.target
        this.setState({ [target.id]: target.value })
    }

    handleSelect = (value, option) => {
        this.setState({ [option.id]: value })
    }

    getInstruction = (userIds) => {
        const { approvalType } = this.state
        const { registeredUsers } = this.props
        const users = []
        const newApprovers = []

        userIds.forEach(userId => users.push(registeredUsers.find(user => user.objectId === userId)))

        console.log('SaveApprovalRule allApprovers getInstruction', users)

        users.forEach(user => {
            if (approvalType === 'Transfer' && !user.paymentAccess.approve) newApprovers.push(user.name)
            if (approvalType === 'Recipient' && !user.recipientAccess.approve) newApprovers.push(user.name)
        })

        return newApprovers.length === 0 ? null : `The Banking Approver role will be added for ${newApprovers.join(', ')}`
    }

    getUpdateUserPromise = (user) => {
        const { roleTags } = user

        roleTags.push('Banking Approver')

        var body = {
            RoleNames: roleTags,
            UserId: user.userInfo.email,
        }

        console.log("IssueCardUpdatingUserupdateUser", body)

        return new Promise((resolve, reject) => {
            uaApiPOST("/user/admin/updateUserRoles", { body: body }, (err, resp) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(resp);
                }
            })
        })
    }

    handleSaveApprovalSetting = () => {
        const { approvalType, name, paymentType, amount, stepCount, level1Approvers, level2Approvers, level3Approvers } = this.state;
        const { registeredUsers, approvalRule } = this.props;
        const allApproverUsers = []

        level1Approvers.concat(level2Approvers).concat(level3Approvers).forEach(userId => allApproverUsers.push(registeredUsers.find(user => user.objectId === userId)))

        if (!approvalType) return ErrorAlert({ description: "Please select a type for this approval rule." });
        if (!name) return ErrorAlert({ description: "Please enter a name for this approval rule." });
        if (approvalType === "Transfer" && (!paymentType || !amount)) {
            return ErrorAlert({ description: `Please enter a ${!paymentType ? 'transfer method' : 'transfer amount'} for this approval rule.` });
        }

        const levelChecks = [
            { count: 1, approvers: level1Approvers, message: "Please select at least one 1st level approver." },
            { count: 2, approvers: level2Approvers, message: "Please select at least one 2nd level approver or remove the 2nd level." },
            { count: 3, approvers: level3Approvers, message: "Please select at least one 3rd level approver or remove the 3rd level." },
        ];

        for (let { count, approvers, message } of levelChecks) {
            if (stepCount >= count && approvers.length === 0) return ErrorAlert({ description: message });
        }

        const type = approvalType === "Transfer" ? (paymentType === 'Instant' ? 'Payment' : paymentType) : approvalType;
        const levels = [level1Approvers, level2Approvers, level3Approvers].slice(0, stepCount).map((approvers, index) => ({
            Type: this.state[`level${index + 1}Type`],
            RANK: index + 1,
            Performers: approvers.map(approver => {
                const user = registeredUsers.find(rUser => rUser.objectId === approver);
                return user ? {
                    UserName: user.userInfo.email,
                    FirstName: user.userInfo.firstName,
                    LastName: user.userInfo.lastName
                } : null;
            }).filter(Boolean)
        }));

        const request = {
            ApprovalRule: {
                Name: name,
                Type: type,
                ...(approvalType === "Transfer" && {
                    Condition: {
                        Type: "OR",
                        Expressions: [{
                            LhsType: "OBJECT",
                            LhsValue: "Payment.Amount",
                            LhsValueType: "DOUBLE",
                            RelationalOperator: "GE",
                            RhsType: "CONSTANT",
                            RhsValue: amount,
                            RhsValueType: "DOUBLE"
                        }]
                    }
                }),
                Levels: levels
            },
            Action: { Name: "Create" }
        };

        if (approvalRule) {
            request.ApprovalRule.approvalRuleId = approvalRule.approvalRuleId
        }

        console.log("Approvals /bb/approvals/saveRule", request);
        this.setState({ loadingSaveApprovalSetting: true });

        const saveRulePromise = apiPOSTReqPromise(`${environment.bbBaseUrl}/bb/approvals/saveRule`, { "BankProvider": environment.bankProvider.crb }, request)
            .then(data => {
                if (data.result) {
                    console.log("CustomerDetails getMSAForCustomer data", data);
                } else {
                    throw Error("Error saving approval rule");
                }
            })
            .catch(error => {
                console.log("ERRR", error.stack);
                ErrorAlert({ description: error.message })
            });

        const updateUserRolePromises = []

        allApproverUsers.forEach(user => {
            if ((approvalType === 'Transfer' && !user.paymentAccess.approve) || (approvalType === 'Recipient' && !user.recipientAccess.approve)) {
                updateUserRolePromises.push(this.getUpdateUserPromise(user))
            }
        })

        Promise.all([saveRulePromise].concat(updateUserRolePromises))
            .then(() => {
                this.setState({ loadingSaveApprovalSetting: false });
                this.props.handleSaveApprovalSetting();
            });
    };

    render() {
        const { approvalSettings, approvers, stepCount, showEditSettingModal, loadingNew, amount, loadingSaveApprovalSetting, paymentType,
            level1Approvers, level2Approvers, level3Approvers, rules, loading, name, approvalType, selectedApproverNames,
            approverDropdown1Open, approverDropdown2Open, approverDropdown3Open, level1Type, level2Type, level3Type } = this.state

        const { theme, allRoles, registeredUsers, approvalRule } = this.props

        var level1Options = (registeredUsers || []).filter(o => !level2Approvers.concat(level3Approvers).find(o2 => { return o.objectId === o2 }))
        var level2Options = (registeredUsers || []).filter(o => !level1Approvers.concat(level3Approvers).find(o2 => { return o.objectId === o2 }))
        var level3Options = (registeredUsers || []).filter(o => !level1Approvers.concat(level2Approvers).find(o2 => { return o.objectId === o2 }))

        const allApprovers = (approvers[0] || []).concat((approvers[1] || []), (approvers[2] || [])) || []

        const filteredOptions = (registeredUsers || []).filter((o) => !allApprovers.find(o2 => { return o2.objectId === o.objectId }))

        console.log("Approvals /bb/approvals/saveRule registeredUsers", registeredUsers);

        var instantPaymentsEnabled = getFeaturePerm("BusinessBanking.Payments.Instant Transfer")

        registeredUsers.forEach(approver => {
            approver.paymentAccess = checkAccessForRoles(approver.roleTags, "Payments", allRoles)
            approver.recipientAccess = checkAccessForRoles(approver.roleTags, "Recipients", allRoles)
        })

        return (
            <FlexColumn start fullWidth>
                <Text heading>{approvalRule ? `Edit control: ${approvalRule?.name}` : "New Control"}</Text>

                <FlexColumn start style={{ marginTop: 24 }} gap='24px'>
                    <LabeledInput
                        type='switch'
                        label='Select one'
                        nomargin
                        switchNames={['Recipient', 'Transfer']}
                        onChange={(value) => this.setState({ approvalType: value })}
                        value={approvalType} />

                    {
                        !approvalRule &&
                        <LabeledInput
                            nomargin
                            label="Name"
                            labelcolor={theme.colors.secondary3}
                            value={name}
                            placeholder="Enter control name"
                            onChange={(e) => this.setState({ name: e.target.value })}
                        />
                    }
                    {
                        approvalType === "Transfer" &&
                        <>
                            <LabeledInput
                                nomargin
                                label="Transfer Method"
                                labelcolor={theme.colors.secondary3}
                                type="select"
                                className="no-left-padding"
                                value={paymentType}
                                placeholder="Select a transfer method"
                                style={{ marginRight: 10, fontSize: "1.0rem", paddingLeft: 0, marginLeft: 0 }}
                                instruction={paymentType ? `This approval rule will apply to ${paymentType} transfers` : "This approval rule will apply to transfers of the above type"}
                                onChange={(value) => this.setState({ paymentType: value })}
                            >
                                {
                                    instantPaymentsEnabled.manage &&
                                    <Option id="paymentType" value="Instant">Instant</Option>
                                }
                                <Option id="paymentType" value="ACH">ACH</Option>
                                <Option id="paymentType" value="Wire">Wire</Option>
                            </LabeledInput>

                            <LabeledInput
                                nomargin
                                label="Transfer Amount"
                                labelcolor={theme.colors.secondary3}
                                prefix="$"
                                value={this.state.amount}
                                placeholder="Enter a transfer amount"
                                onChange={(event) => this.setState({ amount: event.target.value })}
                                instruction={amount ? `This approval rule will apply to transfers of ${formatter.format(amount)} or more` : "This approval rule will apply to transfers of the above amount or more"}
                            />
                        </>
                    }
                    {/* {items} */}

                    <FlexColumn start>
                        <Flex start bottom style={{ marginBottom: 8 }}>
                            <Text light>Require approval from</Text>
                            <Select
                                id="levelType"
                                bordered={false}
                                size="small"
                                defaultValue="One"
                                value={level1Type}
                                style={{ width: 65, marginLeft: 5, marginRight: 5, backgroundColor: '#E6E2F2', borderRadius: '5px', fontSize: '0.7rem', paddingLeft: 5 }}
                                onChange={(value) => this.setState({ level1Type: value })}>
                                <Option value="ANY">One</Option>
                                <Option value="ALL">All</Option>
                            </Select>
                            <Text light>of the following:</Text>
                            <Image width='5px' height='5px' margin='0 0 15px 4px' src={Asterisk} />
                        </Flex>

                        <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                            <LabeledInput
                                nomargin
                                type="select"
                                mode="multiple"
                                optionLabelProp="label"
                                value={level1Approvers}
                                placeholder="Select approvers"
                                padding='0'
                                onChange={level1Approvers => this.setState({ level1Approvers })}
                                open={approverDropdown1Open}
                                onDropdownVisibleChange={(open) => this.setState({ approverDropdown1Open: open })}
                                dropdownRender={menu => (
                                    <div style={{ marginTop: 8 }}>
                                        {menu}
                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <TextButton margin='4px 18px 8px 0' onClick={() => this.setState({ approverDropdown1Open: false })} text='CLOSE' />
                                        </div>
                                    </div>
                                )}
                                instruction={this.getInstruction(level1Approvers)}
                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                            >
                                {level1Options.map(item => (
                                    <Select.Option id={item.objectId} key={item.objectId} value={item.objectId} label={`${item.userInfo.firstName} ${item.userInfo.lastName}`} style={{ backgroundColor: "transparent" }}>
                                        <Flex between style={{ paddingRight: 8 }}>
                                            <span>{`${item.userInfo.firstName} ${item.userInfo.lastName}`}</span>
                                            <span>{`${item.userInfo.email}`}</span>
                                        </Flex>
                                    </Select.Option>
                                ))}
                            </LabeledInput>
                        </div>
                    </FlexColumn>

                    {
                        stepCount > 1 &&
                        <FlexColumn start>
                            <Flex start bottom style={{ marginBottom: 8 }}>
                                <Text light>And then require 2nd level approval from</Text>
                                <Select
                                    bordered={false}
                                    size="small"
                                    defaultValue="One"
                                    value={level2Type}
                                    style={{ width: 65, marginLeft: 5, marginRight: 5, backgroundColor: '#E6E2F2', borderRadius: '5px', fontSize: '0.7rem', paddingLeft: 5 }}
                                    onChange={(value) => this.setState({ level2Type: value })}>
                                    <Option value="ANY">One</Option>
                                    <Option value="ALL">All</Option>
                                </Select>
                                <Text light>of the following:</Text>
                                <Image width='5px' height='5px' margin='0 0 15px 4px' src={Asterisk} />
                            </Flex>

                            <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                                <LabeledInput
                                    nomargin
                                    type="select"
                                    mode="multiple"
                                    optionLabelProp="label"
                                    value={level2Approvers}
                                    placeholder="Select approvers"
                                    padding='4px'
                                    onChange={level2Approvers => this.setState({ level2Approvers })}
                                    open={approverDropdown2Open}
                                    onDropdownVisibleChange={(open) => this.setState({ approverDropdown2Open: open })}
                                    dropdownRender={menu => (
                                        <div>
                                            {menu}
                                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <TextButton margin='4px 18px 8px 0' onClick={() => this.setState({ approverDropdown2Open: false })} text='CLOSE' />
                                            </div>
                                        </div>
                                    )}
                                    instruction={this.getInstruction(level2Approvers)}
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                >
                                    {level2Options.map(item => (
                                        <Select.Option id={item.objectId} key={item.objectId} value={item.objectId} label={`${item.userInfo.firstName} ${item.userInfo.lastName}`} style={{ backgroundColor: "transparent" }}>
                                            <Flex between style={{ paddingRight: 8 }}>
                                                <span>{`${item.userInfo.firstName} ${item.userInfo.lastName}`}</span>
                                                <span>{`${item.userInfo.email}`}</span>
                                            </Flex>
                                        </Select.Option>
                                    ))}
                                </LabeledInput>

                                <a style={{ marginLeft: 12 }} onClick={
                                    () => {
                                        this.setState({ stepCount: stepCount - 1, level2Approvers: [] })
                                    }
                                }><DeleteOutlined style={{ cursor: 'pointer', fontSize: 18 }} /></a>
                            </div>
                        </FlexColumn>
                    }

                    {
                        stepCount > 2 &&
                        <FlexColumn start>
                            <Flex start bottom style={{ marginBottom: 8 }}>
                                <Text light>And then require 3rd level approval from</Text>
                                <Select
                                    bordered={false}
                                    size="small"
                                    defaultValue="One"
                                    value={level3Type}
                                    style={{ width: 65, marginLeft: 5, marginRight: 5, backgroundColor: '#E6E2F2', borderRadius: '5px', fontSize: '0.7rem', paddingLeft: 5 }}
                                    onChange={(value) => this.setState({ level3Type: value })}>
                                    <Option value="ANY">One</Option>
                                    <Option value="ALL">All</Option>
                                </Select>
                                <Text light>of the following:</Text>
                                <Image width='5px' height='5px' margin='0 0 15px 4px' src={Asterisk} />
                            </Flex>

                            <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                                <LabeledInput
                                    nomargin
                                    type="select"
                                    mode="multiple"
                                    optionLabelProp="label"
                                    value={level3Approvers}
                                    placeholder="Select approvers"
                                    padding='4px'
                                    onChange={level3Approvers => this.setState({ level3Approvers })}
                                    open={approverDropdown3Open}
                                    onDropdownVisibleChange={(open) => this.setState({ approverDropdown3Open: open })}
                                    dropdownRender={menu => (
                                        <div>
                                            {menu}
                                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <TextButton margin='4px 18px 8px 0' onClick={() => this.setState({ approverDropdown3Open: false })} text='CLOSE' />
                                            </div>
                                        </div>
                                    )}
                                    instruction={this.getInstruction(level3Approvers)}
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                >
                                    {level3Options.map(item => (
                                        <Select.Option id={item.objectId} key={item.objectId} value={item.objectId} label={`${item.userInfo.firstName} ${item.userInfo.lastName}`} style={{ backgroundColor: "transparent" }}>
                                            <Flex between style={{ paddingRight: 8 }}>
                                                <span>{`${item.userInfo.firstName} ${item.userInfo.lastName}`}</span>
                                                <span>{`${item.userInfo.email}`}</span>
                                            </Flex>
                                        </Select.Option>
                                    ))}
                                </LabeledInput>

                                <a style={{ marginLeft: 12 }} onClick={
                                    () => {
                                        this.setState({ stepCount: stepCount - 1, level3Approvers: [] })
                                    }
                                }><DeleteOutlined style={{ cursor: 'pointer', fontSize: 18 }} /></a>
                            </div>
                        </FlexColumn>
                    }

                    {
                        filteredOptions.length > 0 && stepCount < 3 &&
                        <TextButton margin='12px 0 0' onClick={() => this.setState({ stepCount: stepCount + 1 })} icon={<PlusOutlined />} text='ADD APPROVAL LEVEL' />
                    }
                </FlexColumn>
                <Flex start>
                    <Button permtype="Override" margin='48px 0 0' solid loading={loadingSaveApprovalSetting} onClick={this.handleSaveApprovalSetting} text="SAVE" />
                </Flex>
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer,
        bankingStore: state.bankingAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(SaveApprovalRule)))