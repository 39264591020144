import React from 'react';
import { connect } from 'react-redux'
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { Text } from '../../Reusable/Text'
import { Flex, FlexColumn, CardContainer } from '../../Reusable/Container';
import { LabeledInput } from '../../Reusable/Input';
import environment from '../../../environment';

const UploadPaymentProofModal = ({ aionStore, customer, onUpload }) => {
    // // Access Redux dispatch function
    // const dispatch = useDispatch();
    // // Access Redux state
    // const aionStore = useSelector(state => state.aionAppReducer);

    var uploadProps = {
        action: `${environment.apiBaseUrl}/file/upload`,
        headers: {
            businesskey: aionStore.BusinessUniqueKey,
            type: "logo",
            jwt: aionStore.jwt
        },
        onChange: (info) => {
            var { fileList } = info;
            fileList.forEach(file => {
                const { status, response, name, url } = file;
                console.log("fileList response", response);
                if (status === "done") {
                    onUpload((response.UploadedUrls || [])[0], name)
                }
            });
        }
    };

    return (
        <FlexColumn gap='24px'>
            <Text heading>Upload payment proof</Text>
            <Text margin='0 0 12px'>Upload a payment proof to indicate that {customer.displayName} will start sending payments into your Aion Receivables account</Text>

            <LabeledInput
                nomargin
                {...uploadProps}
                type='upload'
                noAsterisk
            />
        </FlexColumn>
    );
};

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer,
        bankingStore: state.bankingAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(UploadPaymentProofModal)))