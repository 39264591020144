import React from 'react';
import { connect } from 'react-redux';
import { containerDimensions } from '../../Styles/theme';
import SideNav from './SideNav';
// import { getMenuData } from '../../Utils/menuUtil';
import { Affix, Layout } from 'antd';
import { withTheme } from 'styled-components';
import { withRouter, Link } from 'react-router-dom';
import { getResourcePerm } from '../../Utils/util';

const { Sider } = Layout;

const SideMenu = (props) => {
    const { theme, location, bankingStore, userDefaults, store } = props;
    var { Attributes } = store;
    Attributes = Attributes || {};
    const businessAttributes = Attributes.Businesses || {};
    var { BankingAttributes, Applications } = bankingStore;
    BankingAttributes = BankingAttributes || {};
    Applications = Applications || [{}];
    const bankingPending = ((BankingAttributes.ApplicationSubmitted) && ((Applications[Applications.length - 1] || {}).Status !== "approved") || userDefaults.BBShowGettingStarted);
    const creditPending = !businessAttributes.ARAgreementSigned;
    const subpaths = location.pathname.split("/");
    subpaths.shift();
    const tabPath = (subpaths.length > 0) ? subpaths.shift() : null;
    var showMenu = ["banking", "settings", "credit", "receivables", "bookkeeping", "payables", "approvals", "support"].includes(tabPath) && !bankingPending && !subpaths.includes("apply");
    const perm = getResourcePerm("Support.Console");
    if (perm.aionSystemUser) {
        showMenu = true;
    }
    // showMenu = location.pathname.includes("/credit") ? businessAttributes.ARAgreementSigned : showMenu;

    // if (tabPath == 'credit' && creditPending)
    //     showMenu = false;
    // const showMenu = (location.pathname.includes("/banking") && !bankingPending) || location.pathname.includes("/credit") || location.pathname.includes("/receivables");
    return (
        showMenu ? 
        <Affix offsetTop={0}>
            <Sider collapsed={props.collapsed} collapsible collapsedWidth={0} width={containerDimensions.menu.sideMenuWidth} style={{ background: theme.menu.menuBG, padding: "20px 0", overflow: 'auto', height: '100vh' }} >
                {/* <SideNav data={getMenuData(props)} /> */}
            </Sider>
        </Affix> 
        : null
    )
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(SideMenu)));