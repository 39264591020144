import React, { Component } from 'react'
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Dropdown, Menu, Tabs, Select, Tooltip } from 'antd'
import { saveAs } from 'file-saver'

import { Text, Tag } from '../Reusable/Text'
import { Flex, FlexColumn } from '../Reusable/Container'
import { Image } from '../Reusable/Image'
import { TextButton, Button, Chip, ImageButton } from '../Reusable/Button'
import { LabeledInput } from '../Reusable/Input'
import { BasicOption } from '../Reusable/Option'
import { ErrorAlert } from '../Reusable/Alert'
import ApprovalProgress from '../../Images/approval-progress.png'
import ApprovalProgressDone from '../../Images/approval-progress-done.png'
import ApprovalProgressNext from '../../Images/approval-progress-next.png'
import ApprovalDivider from '../../Images/approval-divider.png'
import { apiPOSTReq, getPreSignedS3Url, apiGETDocUrl } from '../../Utils/api'
import FilterIcon from '../../Images/filter.png'
import moment from 'moment'
import environment from '../../environment'
import { withTheme } from 'styled-components'

import Search from '../../Images/search.png'

const { TabPane } = Tabs

const { Option } = Select

const dateFormat = "YYYY-MM-DD";


class SearchAndFilter extends Component {
    constructor(props) {
        super(props)

        this.state = {
            name: 'New Approval Setting',
            amount: 2000,
            paymentType: "ACH",
            rule: "greater than",
            approvers: {
                0: [],
                1: [],
                2: []
            },
            approvalSettings: [],
            approvalBy: "Any",
            stepCount: 1,
            showEditSettingModal: false,
            levelTypes: {
                0: "ANY",
                1: "ANY",
                2: "ANY"
            },
            registeredUsers: [],
            selectedTab: "todo",
            loading: true,
            objectMap: {},
        }
    }

    onTabChange = tab => {
        this.setState({ tab })
    }

    handleSearchChange = (e) => {
        this.setState({ [e.target.id]: e.target.value, wireSelected: false, achSelected: false, recipientSelected: false, dropdownVisible: false, approvedSelected: false, rejectedSelected: false }, this.onChange)
    }

    onChange = () => {
        const { UserInfo } = this.props.store
        var username = UserInfo.Email

        const { searchTerm, dropdownVisible, selectedApproval, achSelected, wireSelected, recipientSelected, loadingReject, loadingApprove, loading, loadingPay, approvedSelected,
            rejectedSelected, fromDate, toDate, completedApprovals, completedPayApprovals, objectDetail, expandedRowKeys, filterFromDate, filterToDate } = this.state

        const body = {
            "approverUserId": username,
            "sortDirection": "DESC",
            fromDate: filterFromDate && filterFromDate.format('YYYY-MM-DD'),
            toDate: filterToDate && filterToDate.format('YYYY-MM-DD'),
            viewBy: achSelected ? 'ACH' : wireSelected ? 'Wire' : recipientSelected ? 'Counterparty' : approvedSelected ? 'Approved' : rejectedSelected ? 'Rejected' : null,
        }

        if (searchTerm && searchTerm.length > 0) body.searchStr = searchTerm

        this.props.onChange(body)
    }

    render() {
        const { searchTerm, dropdownVisible, selectedApproval, achSelected, wireSelected, recipientSelected, loadingReject, loadingApprove, loading, loadingPay, approvedSelected,
            rejectedSelected, fromDate, toDate, completedApprovals, completedPayApprovals, objectDetail, expandedRowKeys, outboxPagination, filterFromDate, filterToDate } = this.state
        const { type } = this.props

        return (<Flex between fullWidth gap='12px'>
            <LabeledInput
                id="searchTerm"
                nomargin
                width='250px'
                placeholder="Search by description"
                prefixIcon={<img width='12px' height='12px' src={Search} />}
                value={searchTerm}
                onChange={this.handleSearchChange}
            />

            <Flex style={{ height: 40 }} start centerHorizontally gap='12px'>
                {
                    (achSelected || wireSelected || recipientSelected || searchTerm || (filterFromDate && !filterToDate) || (filterFromDate && filterToDate) || approvedSelected || rejectedSelected) &&
                    <Flex between fullWidth centerHorizontally>
                        <Flex start wrap gap='8px' centerHorizontally>
                            {
                                searchTerm &&
                                <Chip closable onDelete={() => {
                                    this.setState({ searchTerm: null }, this.onChange)
                                }} text={`"${searchTerm}"`} />
                            }

                            {
                                filterFromDate && !filterToDate &&
                                <Chip closable onDelete={() => {
                                    this.setState({ filterFromDate: null, filterToDate: null, fromDate: null, toDate: null }, this.onChange)
                                }} text={`From ${moment(filterFromDate).format('ll')}`} />
                            }

                            {
                                filterFromDate && filterToDate &&
                                <Chip closable onDelete={() => {
                                    this.setState({ filterFromDate: null, filterToDate: null, fromDate: null, toDate: null }, this.onChange)
                                }} text={`${moment(filterFromDate).format('ll')} - ${moment(filterToDate).format('ll')}`} />
                            }

                            {
                                achSelected &&
                                <Chip closable onDelete={() => {
                                    this.setState({ achSelected: false }, this.onChange)
                                }} text='ACH' />
                            }

                            {
                                wireSelected &&
                                <Chip closable onDelete={() => {
                                    this.setState({ wireSelected: false }, this.onChange)
                                }} text='Wire' />
                            }

                            {
                                recipientSelected &&
                                <Chip closable onDelete={() => {
                                    this.setState({ recipientSelected: false }, this.onChange)
                                }} text='Recipient' />
                            }

                            {
                                approvedSelected &&
                                <Chip closable onDelete={() => {
                                    this.setState({ approvedSelected: false }, this.onChange)
                                }} text='Approved' />
                            }

                            {
                                rejectedSelected &&
                                <Chip closable onDelete={() => {
                                    this.setState({ rejectedSelected: false }, this.onChange)
                                }} text='Rejected' />
                            }
                        </Flex>

                        {/* <Text primary noWrap underline style={{ cursor: 'pointer' }} onClick={() => this.setState({ achSelected: false, wireSelected: false }, this.fetchRecipients)}>Clear all</Text> */}
                    </Flex>
                }

                <Dropdown
                    placement='bottomRight'
                    trigger={['click']}
                    onVisibleChange={flag => this.setState({ dropdownVisible: flag })}
                    visible={dropdownVisible}
                    overlay={(
                        <Menu onClick={() => { }} style={{ width: 370, padding: '12px 24px 24px', borderRadius: 8 }}>
                            <Tabs onClick={() => { }} onChange={this.onTabChange}>
                                <TabPane tab="Approval Date" key="approvalDate">
                                    <FlexColumn left gap='24px'>
                                        <Flex between gap='24px'>
                                            <LabeledInput
                                                label="From"
                                                type="date-picker"
                                                // disabledDate={this.disabledDate}
                                                placeholder='MM/DD/YYYY'
                                                value={fromDate}
                                                format={'MM/DD/YYYY'}
                                                onChange={(date, dateStr) => this.setState({ fromDate: date })}
                                            />
                                            <LabeledInput
                                                label="To"
                                                type="date-picker"
                                                // disabledDate={this.disabledDate}
                                                placeholder='MM/DD/YYYY'
                                                value={toDate}
                                                format={'MM/DD/YYYY'}
                                                onChange={(date, dateStr) => this.setState({ toDate: date })}
                                            />
                                        </Flex>
                                        <Button solid disabled={!fromDate || !toDate} text='APPLY' onClick={() => {
                                            this.setState({ filterFromDate: fromDate, filterToDate: toDate }, this.onChange)
                                        }} />
                                    </FlexColumn>
                                </TabPane>
                                <TabPane tab="Type" key="type">
                                    <Flex start wrap gap='8px'>
                                        <Chip
                                            onAdd={() =>
                                                this.setState({ achSelected: true, wireSelected: false, recipientSelected: false, dropdownVisible: false, approvedSelected: false, rejectedSelected: false }, this.onChange)
                                            }
                                            onDelete={() =>
                                                this.setState({ achSelected: false, dropdownVisible: false }, this.onChange)
                                            }
                                            selected={achSelected}
                                            add
                                            closable
                                            text='ACH'
                                            background='white'
                                        />
                                        <Chip
                                            onAdd={() =>
                                                this.setState({ wireSelected: true, achSelected: false, recipientSelected: false, dropdownVisible: false, approvedSelected: false, rejectedSelected: false }, this.onChange)
                                            }
                                            onDelete={() =>
                                                this.setState({ wireSelected: false, dropdownVisible: false }, this.onChange)
                                            }
                                            selected={wireSelected}
                                            add
                                            closable
                                            text='Wire'
                                            background='white' />
                                        {
                                            type === 'Banking' &&
                                            <Chip
                                                onAdd={() =>
                                                    this.setState({ wireSelected: false, achSelected: false, recipientSelected: true, dropdownVisible: false, approvedSelected: false, rejectedSelected: false }, this.onChange)
                                                }
                                                onDelete={() =>
                                                    this.setState({ recipientSelected: false, dropdownVisible: false }, this.onChange)
                                                }
                                                selected={recipientSelected}
                                                add
                                                closable
                                                text='Recipient'
                                                background='white' />
                                        }
                                    </Flex>
                                </TabPane>
                                <TabPane tab="Status" key="status">
                                    <Flex start wrap gap='8px'>
                                        <Chip
                                            onAdd={() =>
                                                this.setState({ achSelected: false, wireSelected: false, recipientSelected: false, dropdownVisible: false, approvedSelected: true, rejectedSelected: false }, this.onChange)
                                            }
                                            onDelete={() =>
                                                this.setState({ approvedSelected: false, dropdownVisible: false }, this.onChange)
                                            }
                                            selected={approvedSelected}
                                            add
                                            closable
                                            text='Approved'
                                            background='white'
                                        />
                                        <Chip
                                            onAdd={() =>
                                                this.setState({ wireSelected: false, achSelected: false, recipientSelected: false, dropdownVisible: false, approvedSelected: false, rejectedSelected: true }, this.onChange)
                                            }
                                            onDelete={() =>
                                                this.setState({ rejectedSelected: false, dropdownVisible: false }, this.onChange)
                                            }
                                            selected={rejectedSelected}
                                            add
                                            closable
                                            text='Rejected'
                                            background='white' />
                                    </Flex>
                                </TabPane>
                            </Tabs>
                        </Menu>
                    )}>
                    <TextButton text='FILTER' rightIcon={<Image src={FilterIcon} />} />
                </Dropdown>
            </Flex>
        </Flex >
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer,
        bankingStore: state.bankingAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(SearchAndFilter)))