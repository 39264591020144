import React, { useContext, useState, useEffect, useRef } from 'react';
import { Table, Input, Form, Popover, InputNumber } from 'antd';
import { ErrorAlert } from '../../Reusable/Alert';
const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };

    const save = async () => {
        try {
            const values = await form.validateFields();
            console.log('values', values)
            toggleEdit();
            if (!values.code) {
                return;
            }

            if(values.code > 99999 || values.code < 1){
                values.code = null;
                handleSave({ ...record, ...values });
                return;
            }

            handleSave({ ...record, ...values });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;

    const getSampleAccountCodes = () => {
        const instruction = `${record.coatype} codes are between `;
        switch (record.coatype) {
            case "Accounts Payable":
                return `${instruction} 20000 & 20999`;
            case "Accounts Receivable":
                return `${instruction} 10000 & 10999`;
            case "Bank":
                return `${instruction} 11000 & 11999`;
            case "Cost of Goods Sold":
                return `${instruction} 50000 & 59999`;
            case "Credit Card":
                return `${instruction} 21000 & 21999`;
            case "Equity":
                return `${instruction} 30000 & 39999`;
            case "Expense":
                return `${instruction} 60000 & 69999`;
            case "Fixed Asset":
                return `${instruction} 12000 & 12999`;
            case "Income":
                return `${instruction} 40000 & 40999`;
            case "Long Term Liability":
                return `${instruction} 22000 & 22999`;
            case "Other Asset":
                return `${instruction} 13000 & 13999`;
            case "Other Current Asset":
                return `${instruction} 14000 & 14999`;
            case "Other Current Liability":
                return `${instruction} 23000 & 23999`;
            case "Other Expense":
                return `${instruction} 70000 & 79999`;
            case "Other Income":
                return `${instruction} 41000 & 42999`;

            default:
                return "";
                break;
        }
    }

    if (editable) {
        childNode = editing ? (
            <Popover content={getSampleAccountCodes()} visible>
                <Form.Item
                    style={{
                        margin: 0,
                    }}
                    name={dataIndex}

                >
                    <InputNumber ref={inputRef} type="number" onPressEnter={save} onBlur={save} />
                </Form.Item>
            </Popover>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{
                    paddingRight: 24,
                    borderColor: '#5663E9',
                }}
                onClick={toggleEdit}
            >
                {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

class EditableTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSource: [
                {
                    key: '0',
                    name: 'Edward King 0',
                    age: '32',
                    address: 'London, Park Lane no. 0',
                },
                {
                    key: '1',
                    name: 'Edward King 1',
                    age: '32',
                    address: 'London, Park Lane no. 1',
                },
            ],
            count: 2,
        };
    }


    handleSave = (row) => {
        const newData = [...this.props.dataSource];
        const index = newData.findIndex((item) => row.id === item.id);
        console.log('row', row)
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        this.props.saveRow(row, newData);
    };

    render() {
        const { dataSource } = this.props;
        const components = {
            body: {
                row: EditableRow,
                cell: EditableCell,
            },
        };
        const columns = this.props.columns.map((col) => {
            if (!col.editable) {
                return col;
            }

            return {
                ...col,
                onCell: (record) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: this.handleSave,
                }),
            };
        });
        return (

            <Table
                components={components}
                rowClassName={() => 'editable-row'}
                bordered
                dataSource={dataSource}
                columns={columns}
                pagination={this.props.pagination}
                onChange={this.props.onChange}
                scroll={{ y: '700px', x: '100%' }}
            />

        );
    }
}

export default EditableTable;