import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';
import {
    Table,
    Menu,
    message,
    Dropdown,
    Button,
    Tabs,
    Space,
    Select
} from 'antd';

// Components
import { FlexColumn, Flex, CardContainer } from '../../Reusable/Container';
import PageHeader from '../../Reusable/PageHeader';
import { StyledLink } from '../../Reusable/Link';
import { apiPOSTReq } from '../../../Utils/api';
import environment from '../../../environment';
import { ErrorAlert } from '../../Reusable/Alert';
import moment from 'moment';
import { DownOutlined } from '@ant-design/icons';
import { LabeledInput } from '../../Reusable/Input';

const dateFormat = 'YYYY-MM-DD';
const { Option } = Select;

class Index extends Component {
    state = {
        pagination: {
            current: 1,
            pageSize: 100
        },
        sortFieldname: "loanId",
        fromDate: moment().startOf('month'),
        toDate: moment().endOf('month'),
        businesses: []
    };

    componentDidMount() {
        this.fetchData({ ...this.state });
    }

    fetchData = (options) => {
        const { sortDirection, sortFieldname, fromDate, toDate } = this.state;
        var { pagination, sorter } = options || {};
        const dFormat = "YYYY-MM-DD";
        var body = {
            "size": pagination.pageSize,
            "page": pagination.current - 1,
            "sortDirection": "DESC",
            fromDate: fromDate.format(dFormat),
            toDate: toDate.format(dFormat)
        };
        sorter = sorter || {}
        if (sorter.field) {
            if (sorter.field === sortFieldname) {
                body.sortDirection = (sortDirection === "DESC") ? "ASC" : "DESC"
            } else {
                body.sortDirection = "ASC"
            }
            body.sortFieldname = sorter.field
        } else {
            if (sortFieldname) {
                body.sortFieldname = sortFieldname
            }
            body.sortDirection = sortDirection
        }
        console.log("/getAdvances body", body);
        this.setState({ loading: true })
        apiPOSTReq(`${environment.opsBaseUrl}/ops/loans/getAdvances`, { AionCurrentBiz: this.props.store.BusinessUniqueKey }, body, (err, resp) => {
            console.log("/getAdvances err, resp", err, resp);
            this.setState({ loading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    this.setState({
                        advances: data.advances || [], pagination: { ...pagination, total: data.count }, sortDirection: body.sortDirection,
                        sortFieldname: body.sortFieldname,
                        businesses: data.businesses
                    })
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    handleTableChange = (pagination, filters, sorter) => {
        this.fetchData({ pagination: pagination, sorter });
    }

    getColumns = () => {
        return [
            {
                title: 'Business Name',
                render: (item) => <div> {this.state.businesses.length ? this.state.businesses.map(business => {
                    var { businessInfo } = business.businessProfile || {};
                    if ((business.id) == (item.businessId)) {
                        return (
                            <div> {businessInfo.name.toUpperCase()} </div>
                        )
                    }
                }) : ""} </div>,
                width: "150px"
            },
            {
                title: 'Loan ID',
                dataIndex: 'loanId',
                width: "150px",
                sorter: true
            },
            {
                title: 'Loan Amount',
                dataIndex: 'advanceAmount',
                width: "150px",
                sorter: true
            },
            {
                title: 'Requested Date',
                dataIndex: 'advanceDate',
                key: 'advanceDate',
                render: advanceDate => (moment(advanceDate).format('MM/DD/YY')),
                width: "150px",
                sorter: true
            },
            {
                title: 'Status',
                dataIndex: 'loanStatus',
                width: "150px",
                sorter: true
            }
        ]
    }
    render() {
        const { advances, pagination, loading, selectedRowKeys } = this.state;
        const { theme } = this.props;

        return (
            <FlexColumn className='main-left-padding main-top-padding' style={{ margin: 0 }}>
                <FlexColumn style={{ margin: '0 40px' }}>
                    <PageHeader
                        titleText={"Advances"}
                    />
                    <CardContainer fullWidth>
                        <Table
                            id="business-table"
                            className="row-pointer"
                            tableLayout='auto'
                            columns={this.getColumns()}
                            dataSource={advances}
                            rowKey='id'
                            pagination={pagination}
                            style={{ minHeight: "500px" }}
                            loading={loading}
                            scroll={{ y: '700px', x: '100%' }}
                            onChange={this.handleTableChange}
                            rowSelection={{
                                type: "checkbox",
                                onChange: this.handleRowSelection,
                                selectedRowKeys: selectedRowKeys,
                            }}
                        />
                    </CardContainer>
                </FlexColumn>
            </FlexColumn>
        );
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)));