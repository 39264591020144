import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import { Select, Modal, Dropdown, Menu, Tabs, Checkbox, Table } from 'antd'
import { isEmail } from 'react-multi-email'
import moment from 'moment'
import html2canvas from 'html2canvas'

import { Text, Tag } from '../../Reusable/Text'
import { Divider } from '../../Reusable/Divider'
import { Button, TextButton, ImageButton, Chip } from '../../Reusable/Button'
import { Image } from '../../Reusable/Image'
import { Flex, FlexColumn, CardContainer, LightContainer } from '../../Reusable/Container'
import PageHeader from '../../Reusable/PageHeader'
import { LabeledInput } from '../../Reusable/Input'
import { ErrorAlert } from '../../Reusable/Alert'
import FlowFooter from '../../Reusable/FlowFooter'
import ProgressSteps from '../../Reusable/ProgressSteps'
import TransferToRecipientTable from '../Reusable/TransferToRecipientTable'
import SuccessModal from '../../Reusable/SuccessModal'
import CancelModal from '../../Reusable/CancelModal'
import PendingTable from '../Reusable/PendingTable'
import { ApprovalGraph } from '../../Reusable/Approval'
import { FromAccountOption, PaymentMethodOption } from '../../Reusable/Option'
import AddTransferModal from '../Recipients/AddTransferModal'
import AionIcon from '../../../Images/aion-bank-icon.png'
import TransferSent from '../../../Images/transfer-sent-for-approval.png'
import Search from '../../../Images/search.png'
import ChevronDown from '../../../Images/chevron-down.png'
import ChevronUp from '../../../Images/chevron-up.png'
import Edit from '../../../Images/edit.png'
import Add from '../../../Images/add.png'
import ModalClose from '../../../Images/modal-close.png'
import ArrowRight from '../../../Images/arrow-right.png'
import { PlusOutlined } from '@ant-design/icons'

import { addDataToStore, SAVE_DATA } from '../../../Actions/actions'
import { toCurrency, getBPAccountsWithAccess, toNumberStr, getFeatureLimit, addressObjectToStr, addressObjectToMultiLineStr, renderBankIcon } from '../../../Utils/util'
import { apiPOSTReq } from '../../../Utils/api'
import { fetchCounterparties } from '../../../Utils/bankingDataManager'
import environment from '../../../environment'
import store from '../../../Reducers/store'
import Banner from '../../Reusable/Banner'

const { Option } = Select
const { TabPane } = Tabs

const dFormat = "ll; h:mm A z"
const etTimeZone = "America/New_York";
const largeWireAmount = 1000000

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
})

const deprecated = true

class Index extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            pagination: {
                current: 1,
                pageSize: 1000,
                hideOnSinglePage: true
            },
            recipients: [],
            showTransferModal: false,
            paymentMethods: {},
            showResult: false,
            step: 0,
            steps: ['Recipient', 'Transfer Details', 'Review'],
            sendEmail: false,
            referenceDocuments: [],
            selectedRowKeys: [],
            viewDetails: true,
            paymentMethods: [],
            fileList: [],
            uploadedFileList: [],
            noData: false,
            payorName: '',
        }

        this.rail = this.props.location.state && this.props.location.state.rail
        this.transferMethod = this.props.location.state && this.props.location.state.transferMethod
        if (this.props.location.state.fromAccount && this.props.location.state.fromAccount.accountType !== 'ACCOUNTS_RECEIVABLE') {
            this.fromAccount = this.props.location.state && this.props.location.state.fromAccount
        }
    }

    componentDidMount() {
        var { UserInfo } = this.props.store
        UserInfo = UserInfo || []
        this.getRules()
        this.getSameDayFees()

        var Accounts = getBPAccountsWithAccess("Transactions", { getActiveAccounts: true })
        Accounts = Accounts || []

        var accountMap = {}

        Accounts.forEach(account => {
            accountMap[account.accountId] = account
        })

        this.setState({ accountMap, payorName: `${UserInfo.FirstName} ${UserInfo.LastName}` })

        var filteredAccounts = Accounts.filter(item => { return (!['ACCOUNTS_RECEIVABLE', 'Transitional'].includes(item.accountType)) }) || []
        if ((filteredAccounts || []).length === 1) this.setState({ fromAccount: filteredAccounts[0] })

        // if (this.props.location.state && this.props.location.state.rail) this.setState({ rail: this.props.location.state.rail }, () => console.log('fetchRecipients viewBy', this.props.location.state.rail))

        if (this.props.location.state && this.props.location.state.recipient) {
            const recipient = this.props.location.state.recipient
            const transferMethod = this.props.location.state.transferMethod
            const recipientId = recipient.objectId

            console.log('componentDidMountrecipient transferMethod', transferMethod)

            var recipientDo, linkOrWeb, purpose

            var notificationEmails = []

            if (recipient && recipient.email) {
                const emails = recipient.email.split(',');
                notificationEmails.push(...emails);
            }

            if (recipient && recipient.notificationEmails) {
                notificationEmails = notificationEmails.concat(recipient.notificationEmails)
            }

            if (recipient && recipient.additionalDetails) {
                const { additionalDetails } = recipient

                additionalDetails.forEach(additionalDetail => {
                    if (additionalDetail.context === 'What does the recipient do?') recipientDo = additionalDetail.contextValue
                    if (additionalDetail.context === 'What is the recipient’s website?') linkOrWeb = additionalDetail.contextValue
                    // if (additionalDetail.context === 'Why do you pay this recipient?') purpose = additionalDetail.contextValue
                })
            }

            this.getPaymentMethods(recipientId)
            this.setState({ recipient, notificationEmails, recipientDo, linkOrWeb, purpose, fromDetails: true, loading: false, steps: ['Transfer Details', 'Review'] })
        } else {
            this.fetchRecipients()
        }

        this.getTransferLimit('Instant-Internal Transfer')
        this.getTransferLimit('ACH')
        this.getTransferLimit('Wire')

        if (this.fromAccount) {
            // var Accounts = getBPAccountsWithAccess("Payments", { permType: "manage", getActiveAccounts: true })
            // var account = Accounts.find(a => a.accountId === value)

            // console.log('handleSelectAccount', account)

            this.setState({ fromAccount: this.fromAccount })
            this.getFees({ ...(this.state.paymentMethod || {}), fromAccount: this.fromAccount || {} })
        }
    }

    fetchRecipients = () => {
        this.setState({ loading: true })

        const body = { railType: this.rail ? this.rail.toUpperCase() : null }
        console.log('getPaymentMethodsByRecipients: ', body)
        apiPOSTReq(`${environment.bbBaseUrl}/bb/getPaymentMethodsByRecipients`, null, body, (err, resp) => {
            try {
                const data = resp

                console.log("/bb/getPaymentMethodsByRecipients", data)

                if (data.result) {
                    if (err) throw new Error(err)

                    if (resp && resp.result) {
                        var sortedArray = Object.values(resp.recipientMap || []).sort((a, b) => {
                            // Handle null values by setting them to a default date in the distant past
                            const dateA = a.lastTransferDate || "1900-01-01";
                            const dateB = b.lastTransferDate || "1900-01-01";
                            return new Date(dateB) - new Date(dateA);  // Sort in descending order
                        });

                        this.setState({
                            loading: false,
                            recipients: sortedArray,
                            noData: (!sortedArray || sortedArray.length === 0),
                            paymentMethodRecipientMap: resp.paymentMethodRecipientMap,
                            recipientMap: resp.recipientMap,
                        })
                    }
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getPaymentMethodsByRecipients", error, err, resp)
            } finally {
                this.setState({
                    loading: false
                })
            }
        })
    }

    handleSearchChange = (e) => {
        this.setState({ [e.target.id]: e.target.value })

        const body = { railType: this.rail ? this.rail.toUpperCase() : null }

        if (e.target.value !== '') body.searchStr = e.target.value

        console.log('getPaymentMethodsByRecipients handleSearchChange: ', body)

        apiPOSTReq(`${environment.bbBaseUrl}/bb/getPaymentMethodsByRecipients`, null, body, (err, resp) => {
            try {
                const data = resp

                console.log("/bb/getPaymentMethodsByRecipients", data)

                if (data.result) {
                    if (err) throw new Error(err)

                    if (resp && resp.result) {
                        var sortedArray = Object.values(resp.recipientMap || []).sort((a, b) => {
                            // Handle null values by setting them to a default date in the distant past
                            const dateA = a.lastTransferDate || "1900-01-01";
                            const dateB = b.lastTransferDate || "1900-01-01";
                            return new Date(dateB) - new Date(dateA);  // Sort in descending order
                        });

                        this.setState({
                            loading: false,
                            recipients: sortedArray,
                            noData: (!sortedArray || sortedArray.length === 0),
                            paymentMethodRecipientMap: resp.paymentMethodRecipientMap,
                            recipientMap: resp.recipientMap,
                        })
                    }
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getPaymentMethodsByRecipients", error, err, resp)
            }
        })
    }

    viewRecipient = (recipient) => {
        this.props.history.push({
            pathname: '/banking/recipients/details',
            state: { recipient }
        })
    }

    formatAddr = (address) => {
        return {
            line1: address.line1,
            line2: address.line2 || null,
            city: address.city,
            countrySubDivisionCode: address.countrySubDivisionCode,
            state: address.countrySubDivisionCode,
            postalCode: address.postalCode
        }
    }

    handleSelectAccount = (value, option) => {
        const { paymentMethod } = this.state
        var Accounts = getBPAccountsWithAccess("Payments", { permType: "manage", getActiveAccounts: true })
        var account = Accounts.find(a => a.accountId === value)

        console.log('handleSelectAccount', account)

        this.setState({ fromAccount: account }, () => this.getDailyLimits(paymentMethod))
        this.getFees({ ...(this.state.paymentMethod || {}), fromAccount: account || {} })
    }

    onTabChange = tab => {
        this.setState({ tab })
    }

    handleSelectpaymentMethod = (value, option) => {
        const { recipient, paymentMethods } = this.state
        var paymentMethod = paymentMethods.find(pm => pm.id === value)

        console.log('handleSelectpaymentMethod', paymentMethod)
        var newState = { paymentMethod, sameDay: false, purpose: null }
        if (paymentMethod.type == "ACH") {
            newState["addenda"] = paymentMethod.remittanceInfo
        } else {
            newState["note"] = paymentMethod.remittanceInfo
        }
        this.setState(newState)
        // Fetch daily limits
        this.getDailyLimits(paymentMethod)
        this.getFees({ ...paymentMethod, fromAccount: this.state.fromAccount || {} })
    }

    saveUserInput = (id, dataToSave) => {
        this.setState({ [id]: dataToSave })
    }

    getRules = () => {
        apiPOSTReq(`${environment.bbBaseUrl}/bb/approvals/getRules`, null, { "RuleTypes": ["ACH", "Wire", "Recipient"] }, (err, resp) => {
            try {
                const data = resp

                console.log("Approvals /bb/getRules", data)

                if (data.result) {
                    if (err) throw new Error(err)

                    const recipientRule = data.approvalRuleList.find(rule => rule.type === 'Recipient')
                    console.log("Approvals /bb/getRules recipientRule", recipientRule)

                    this.setState({ recipientRule })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getAccounts", error, err, resp)
            }
        })
    }

    getDailyLimits = (options) => {
        const { fromAccount } = this.state
        options = options || {};
        var paymentType = options.type;
        var url;
        var body = {
            "wireTransferObj": {
                "Amount": options.amount || "0.00"
            },
            "achTransferObj": {
                "Amount": options.amount || "0.00"
            },
            "checkPaymentObj": {
                "Amount": options.amount || "0.00"
            },
            "bookTransferObj": {
                "Amount": options.amount || "0.00"
            },
            "instantTransferObj": {
                "Amount": options.amount || "0.00"
            }
        }
        if (paymentType == "ACH") {
            if (fromAccount?.accountNumber) {
                body.accountNumber = fromAccount?.accountNumber
            }

            url = `${environment.bbBaseUrl}/bb/getDailyLimitsACH`
        } else if (paymentType == "Wire") {
            if (fromAccount?.accountNumber) {
                body.accountNumber = fromAccount?.accountNumber
            }

            url = `${environment.bbBaseUrl}/bb/getDailyLimitsWire`
        } else if (paymentType == "Check") {
            url = `${environment.bbBaseUrl}/bb/getDailyLimitsCheck`
        } else if (paymentType == "Instant") {
            if (fromAccount && fromAccount.accountId) {
                body.bookTransferObj.fromAccountId = fromAccount.accountId
            }

            url = `${environment.bbBaseUrl}/bb/getDailyLimitsInstant`
        } else {
            return;
        }

        console.log("getDailyLimits: ", body)

        apiPOSTReq(url, null, body, (err, resp) => {
            try {
                const data = resp || {};

                if (data.result) {
                    if (err) throw new Error(err)
                    console.log("bb/getDailyLimits", data);
                    this.setState({ dailyLimits: data || {} }, this.checkTransferAmount);
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getDailyLimits", error, err, resp)
            }
        });
    }

    getTransferLimit = (transferMethod) => {
        var body = {
            transferMethod: transferMethod,
        }

        console.log("bb/getTransferLimits body", body);
        apiPOSTReq(`${environment.bbBaseUrl}/bb/getTransferLimits`, null, body, (err, resp) => {
            try {
                if (err) throw new Error(err)
                const data = resp || {};
                console.log("bb/getTransferLimits resp", resp);

                if (data.result) {
                    this.setState({ [`${transferMethod}TransferLimit`]: data.featureValue });
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getTransferLimits", error, err, resp)
            }
        });
    }

    getFees = (options) => {
        options = options || {}
        var { fromAccount, type } = options
        var feeType;
        feeType = (type == "Wire") ? "DomesticWireOut" : "OutgoingACH"
        if (type == "Check") feeType = "DomesticCheckPayment"
        if (type == "Instant") feeType = "InstantTransfer"
        var body = {
            "feeType": feeType
        }
        if ((fromAccount || {}).accountNumber) {
            body.accountNumber = fromAccount.accountNumber
        }
        console.log("getFees", body)
        apiPOSTReq(`${environment.bbBaseUrl}/bb/getFeeInfo`, null, body, (err, resp) => {
            try {
                const data = resp || {};

                if (data.result) {
                    if (err) throw new Error(err)
                    console.log("bb/getFeeInfo", data);
                    this.setState({ feeInfo: data || {} });
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getFeeInfo", error, err, resp)
            }
        });
    }

    getSameDayFees = (options) => {
        options = options || {}
        var { fromAccount, type } = options
        var body = {
            "feeType": "SamedayACH"
        }
        if ((fromAccount || {}).accountNumber) {
            body.accountNumber = fromAccount.accountNumber
        }
        console.log("getFees", body)
        apiPOSTReq(`${environment.bbBaseUrl}/bb/getFeeInfo`, null, body, (err, resp) => {
            try {
                const data = resp || {};

                if (data.result) {
                    if (err) throw new Error(err)
                    console.log("bb/getFeeInfo SamedayACH", data);
                    this.setState({ samedayACHFee: data.feeAmount || 2 })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getFeeInfo", error, err, resp)
            }
        });
    }

    getPaymentMethods = (id) => {
        this.setState({ paymentMethodsLoading: true })
        apiPOSTReq(`${environment.bbBaseUrl}/bb/getPaymentMethods`, null, { objectId: id }, (err, resp) => {
            try {
                const data = resp

                if (data.result) {
                    if (err) throw new Error(err)
                    console.log("bb/getPaymentMethods", data.paymentMethods, this.rail)
                    var paymentMethods = (data.paymentMethods || []).filter(pm => pm.active === true)
                    if (this.rail) paymentMethods = paymentMethods.filter(pm => pm.type === this.rail)
                    console.log("bb/getPaymentMethods", paymentMethods)
                    if (paymentMethods && paymentMethods.length === 1) this.getDailyLimits(paymentMethods[0])
                    var paymentMethod = (paymentMethods && paymentMethods.length === 1) ? paymentMethods[0] : null
                    if (this.transferMethod) paymentMethod = paymentMethods.find(paymentMethod => paymentMethod.id === this.transferMethod.id)
                    var newState = { paymentMethods: paymentMethods, paymentMethod: paymentMethod }
                    if ((paymentMethod || {}).type == "ACH") {
                        newState["addenda"] = (paymentMethod || {}).remittanceInfo
                    } else {
                        newState["note"] = (paymentMethod || {}).remittanceInfo
                    }
                    this.setState(newState)
                    this.getFees({ ...paymentMethod, fromAccount: this.state.fromAccount || {} })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getAccounts", error, err, resp)
            } finally {
                this.setState({ paymentMethodsLoading: false })
            }
        })
    }

    evaluatePaymentApproval = () => {
        const { transferAmount, paymentMethod } = this.state

        const body = {
            paymentAmount: transferAmount,
            evaluateApproval: paymentMethod.type === 'Instant' ? 'Payment' : paymentMethod.type,
        }

        console.log(`/bb/evaluatePaymentApproval body`, body)
        apiPOSTReq(`${environment.bbBaseUrl}/bb/evaluatePaymentApproval`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            this.setState({ submitLoading: false })
            try {
                const data = resp
                console.log(`/bb/evaluatePaymentApproval`, data)
                if (data.result) {
                    this.setState({ approvalRequired: data.approvalRequired, approvalRule: data.approvalRule })
                } else {
                    this.setState({ approvalRequired: data.approvalRequired })
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /bb/evaluatePaymentApproval", error, err, resp)
                // ErrorAlert({ description: error.message })
            }
        })
    }

    next = () => {
        const { step, steps, recipient, transferAmount, paymentMethod, ACHTransferLimit, WireTransferLimit } = this.state

        switch (steps[step]) {
            case 'Recipient':
                var Accounts = getBPAccountsWithAccess("Transactions", { getActiveAccounts: true })
                Accounts = Accounts || []
                var filteredAccounts = Accounts.filter(item => { return (!['ACCOUNTS_RECEIVABLE', 'Transitional'].includes(item.accountType)) }) || []
                var fromAccount = (filteredAccounts || []).length === 1 ? filteredAccounts[0] : this.state.fromAccount ? this.state.fromAccount : this.fromAccount

                this.setState({ step: step + 1, paymentMethod: null, paymentMethods: [], fromAccount }, () => this.getPaymentMethods(recipient.objectId))
                break
            case 'Transfer Details':
                if (WireTransferLimit && (paymentMethod.type === 'Wire' && transferAmount > Number(WireTransferLimit))) {
                    this.setState({ errorField: 'transferAmount', errorMessage: `Wire transfer amount cannot exceed your per transfer limit of $${toCurrency(WireTransferLimit)}` })
                    break
                }

                if (ACHTransferLimit && (paymentMethod.type === 'ACH' && transferAmount > Number(ACHTransferLimit))) {
                    this.setState({ errorField: 'transferAmount', errorMessage: `ACH transfer amount cannot exceed your per transfer limit of $${toCurrency(ACHTransferLimit)}` })
                    break
                }
                if (paymentMethod.type == 'Check') {
                    // Save signature img base64
                    var t = this;
                    html2canvas(document.querySelector("#sigContainer")).then(canvas => {
                        var sigImg = canvas.toDataURL("img/png");
                        t.setState({ userSignatureBase64: sigImg.split(',')[1] })
                    });
                }

                this.setState({ step: step + 1, errorField: null, errorMessage: null }, () => this.evaluatePaymentApproval())
                break
            case 'Review':
                this.confirmPayment()
                break
            default:
                this.setState({ step: step + 1 })
                break
        }
    }

    isDisabled = () => {
        const { step, transferAmount, paymentMethod, fromAccount, recipient, note, steps, purpose, payorName } = this.state

        switch (steps[step]) {
            case 'Recipient': return !recipient
            case 'Transfer Details': return !transferAmount || !paymentMethod || (paymentMethod.type === 'ACH' && !purpose) || (paymentMethod.type === 'Instant' && !purpose) || (paymentMethod.type === 'Wire' && !purpose) || !fromAccount || (paymentMethod.type === 'Check' && !payorName)
            // case 'Additional Details': return (paymentMethod && paymentMethod.type === 'Wire') ? !purpose : false
            default: return false
        }
    }

    getButtonTitle = () => {
        return this.state.step === this.state.steps.length - 1 ? 'SUBMIT' : 'NEXT'
    }

    confirmPayment = () => {
        const { approvalRequired, fromAccount, recipient, paymentMethod, transferAmount, sendEmail, addenda, note,
            notificationEmails, recipientDo, linkOrWeb, fileList, sameDay, purpose, userSignatureBase64, payorName } = this.state
        const { store } = this.props
        const { UserInfo, business, BusinessUniqueKey } = store || {}
        var { bankDetail } = paymentMethod
        bankDetail = bankDetail || {}
        var { businessInfo } = business.businessProfile || {}

        const paymentAmt = toNumberStr(transferAmount)

        var secCode = ""
        if (recipient.type === "Business") {
            secCode = "ccd"
        } else if (recipient.type === "Individual") {
            secCode = "ppd"
        }

        var additionalDetails = []

        if ((notificationEmails || recipientDo || linkOrWeb || fileList)) {
            if (notificationEmails) additionalDetails.push({ context: 'Notifications to', contextValue: notificationEmails.join(', ') })
            if (recipientDo) additionalDetails.push({ context: 'What does the recipient do?', contextValue: recipientDo })
            if (linkOrWeb) additionalDetails.push({ context: 'What is the recipient’s website?', contextValue: linkOrWeb })
            // if (purpose) additionalDetails.push({ context: 'Why do you pay this recipient?', contextValue: purpose })
            if (fileList) additionalDetails.push({ context: 'Reference documents', attachmentList: fileList })

            additionalDetails = additionalDetails
        }

        let email = recipient.email || "";
        if (recipient.notificationEmails && recipient.notificationEmails.length > 0) {
            if (email.length > 0) email += ","

            recipient.notificationEmails.forEach((x) => {
                email += x + ",";
            })
            email = email.substring(0, email.length - 1);
        }

        switch (paymentMethod.type) {
            case 'ACH':
                var body = {
                    achTransferObj: {
                        "paymentMethodId": paymentMethod.id,
                        "accountId": fromAccount.accountId || "",
                        "accountNumber": fromAccount.accountNumber || "",
                        "amount": paymentAmt,
                        "counterpartyName": recipient.nameOnAccount || "",
                        "counterpartyId": recipient.id || "",
                        "counterpartyType": recipient.type || "",
                        "direction": "credit",
                        "secCode": secCode,
                        "email": email,
                        "sendEmail": sendEmail,
                        "description": purpose || null,
                        "contextIdentifier": `Transfer $${toCurrency(paymentAmt)} to ${(recipient.nameOnAccount) || ""}`,
                        "initiatedBy": `${UserInfo.FirstName} ${UserInfo.LastName}`,
                        "initiatedByUserName": UserInfo.Email,
                        "status": "pending",
                        "statusMessage": "Transfer has been queued for processing.",
                        "serviceType": sameDay === true ? "SameDay" : "Standard",
                        "remittanceInfo": addenda,
                        "purpose": purpose,
                        "userNote": addenda
                    },
                    "ruleType": "ACH"
                }

                if (additionalDetails.length > 0) body.achTransferObj.additionalDetails = additionalDetails

                console.log('createACH body: ', body)

                this.setState({ submitLoading: true })

                apiPOSTReq(`${environment.bbBaseUrl}/bb/createACH`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
                    this.setState({ submitLoading: false })
                    try {
                        if (err) throw Error(err)
                        const data = resp || {}
                        if (data.result) {
                            console.log("/createACH data ", data)
                            if (approvalRequired) this.props.onApprovalAction()
                            this.setState({ showResult: true })
                        } else {
                            throw Error(data.responseMessage || data.error)
                        }
                    } catch (error) {
                        console.log("/createACH err", error.stack)
                        ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
                    }
                })
                break
            case 'Wire':
                var body = {
                    wireTransferObj: {
                        "paymentMethodId": paymentMethod.id,
                        "accountId": fromAccount.accountId || "",
                        "accountNumber": fromAccount.accountNumber || "",
                        "amount": paymentAmt,
                        "counterpartyName": recipient.nameOnAccount || "",
                        "counterpartyId": recipient.id || "",
                        "counterpartyType": recipient.type || "",
                        "email": email,
                        "sendEmail": sendEmail,
                        "originatorToBeneficiary5": note || "",
                        "instructions": purpose || "",
                        "contextIdentifier": `Transfer $${toCurrency(paymentAmt)} to ${(recipient.nameOnAccount) || ""}`,
                        "initiatedBy": `${UserInfo.FirstName} ${UserInfo.LastName}`,
                        "initiatedByUserName": UserInfo.Email,
                        "status": "pending",
                        "statusMessage": "Transfer has been queued for processing.",
                        "remittanceInfo": note,
                        "purpose": purpose,
                        "userNote": purpose
                    },
                    "ruleType": "Wire"
                }

                if (additionalDetails.length > 0) body.wireTransferObj.additionalDetails = additionalDetails

                this.setState({ submitLoading: true })
                console.log(`${environment.bbBaseUrl}/bb/createWire`, body)
                apiPOSTReq(`${environment.bbBaseUrl}/bb/createWire`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
                    this.setState({ submitLoading: false })
                    try {
                        if (err) throw Error(err)
                        const data = resp || {}
                        if (data.result) {
                            console.log("/createWire data ", data)
                            if (approvalRequired) this.props.onApprovalAction()
                            this.setState({ showResult: true })
                        } else {
                            throw Error(data.responseMessage || data.error)
                        }
                    } catch (error) {
                        console.log("/createWire debit err", error.stack)
                        ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
                    }
                })
                break
            case 'Check':
                var body = {
                    "checkPaymentObj": {
                        "paymentMethodId": paymentMethod.id,
                        "accountId": fromAccount.accountId || "",
                        "accountNumber": fromAccount.accountNumber || "",
                        "routingNumber": fromAccount.routingNumber || "021214891",
                        "amount": paymentAmt,
                        "counterpartyName": recipient.nameOnAccount || "",
                        "counterpartyId": recipient.id || "",
                        "counterpartyType": recipient.type || "",
                        "counterpartyEmail": email,
                        "memo": note || "",
                        "description": note || "",
                        "contextIdentifier": `Check payment to $${toCurrency(paymentAmt)} to ${(recipient.nameOnAccount) || ""}`,
                        "initiatedBy": `${UserInfo.FirstName} ${UserInfo.LastName}`,
                        "initiatedByUserName": UserInfo.Email,
                        "status": "created",
                        "deliveryType": bankDetail.checkDeliveryType,
                        "payorName": payorName,
                        "payorCompanyName": businessInfo.name,
                        "payorAddress": businessInfo.mailingAddress || {},
                        "sourceApplication": "BusinessBanking",
                        "userSignatureBase64": userSignatureBase64
                    },
                    "ruleType": "CheckPayment"
                }

                if (additionalDetails.length > 0) body.checkPaymentObj.additionalDetails = additionalDetails

                this.setState({ submitLoading: true })
                console.log(`${environment.bbBaseUrl}/bb/createCheckPayment`, body)
                apiPOSTReq(`${environment.bbBaseUrl}/bb/createCheckPayment`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
                    this.setState({ submitLoading: false })
                    try {
                        if (err) throw Error(err)
                        const data = resp || {}
                        if (data.result) {
                            console.log("/createCheckPayment data ", data)
                            this.setState({ showResult: true })
                        } else {
                            throw Error(data.responseMessage || data.error)
                        }
                    } catch (error) {
                        console.log("/createCheckPayment debit err", error.stack)
                        ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
                    }
                })
                break
            case 'Instant':
                var body = {
                    instantTransferObj: {
                        "fromAccountNumber": fromAccount.accountNumber || "",
                        "businessId": BusinessUniqueKey,
                        "paymentMethodId": paymentMethod.id,
                        "amount": paymentAmt,
                        "transferType": paymentMethod.subType,
                        "SenderName": businessInfo.name,
                        "ReceiverName": recipient.nameOnAccount,
                        "senderDescription": `Instant Transfer to ${(recipient.nickName || recipient.nameOnAccount) || ""}`,
                        "receiverDescription": `Instant Transfer from ${businessInfo.name || ""}`,
                        "counterpartyName": recipient.nameOnAccount || "",
                        "counterpartyId": recipient.id || "",
                        "counterpartyType": recipient.type || "",
                        "counterpartyEmail": email,
                        "contextIdentifier": `Transfer $${toCurrency(paymentAmt)} to ${(recipient.nameOnAccount) || ""}`,
                        "initiatedBy": `${UserInfo.FirstName} ${UserInfo.LastName}`,
                        "initiatedByUserName": UserInfo.Email,
                        "remittanceInfo": note,
                        "userNote": purpose,
                        "purpose": purpose
                    },
                    "ruleType": "Payment",
                    "remittanceData": note,
                    "purpose": purpose
                }

                if (additionalDetails.length > 0) body.instantTransferObj.additionalDetails = additionalDetails

                console.log('xferInstant body: ', body)

                this.setState({ submitLoading: true })

                apiPOSTReq(`${environment.bbBaseUrl}/bb/xferInstant`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
                    this.setState({ submitLoading: false })
                    try {
                        if (err) throw Error(err)
                        const data = resp || {}
                        console.log("/xferInstant data ", data)
                        if (data.result) {
                            console.log("/xferInstant data ", data)
                            if (approvalRequired) this.props.onApprovalAction()
                            this.setState({ showResult: true })
                        } else {
                            throw Error(data.responseMessage || data.error)
                        }
                    } catch (error) {
                        console.log("/xferInstant err", error.stack)
                        ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
                    }
                })
                break
        }
    }

    handleRowSelection = (selectedRowKeys, selectedRows) => {
        const recipientId = selectedRowKeys.length > 0 ? selectedRowKeys[selectedRowKeys.length - 1] : null
        const recipient = this.state.recipients.find(r => r.objectId === recipientId)

        var recipientDo, linkOrWeb, purpose

        var notificationEmails = []

        if (recipient && recipient.email) {
            const emails = recipient.email.split(',');
            notificationEmails.push(...emails);
        }

        if (recipient && recipient.notificationEmails) {
            notificationEmails = notificationEmails.concat(recipient.notificationEmails)
        }

        if (recipient && recipient.additionalDetails) {
            const { additionalDetails } = recipient

            additionalDetails.forEach(additionalDetail => {
                if (additionalDetail.context === 'What does the recipient do?') recipientDo = additionalDetail.contextValue
                if (additionalDetail.context === 'What is the recipient’s website?') linkOrWeb = additionalDetail.contextValue
                // if (additionalDetail.context === 'Why do you pay this recipient?') purpose = additionalDetail.contextValue
            })
        }

        if (recipient && recipient.objectId) {
            this.getPaymentMethods(recipient.objectId)
        }

        this.setState({ selectedRowKeys: recipientId ? [recipientId] : [], recipient, notificationEmails, recipientDo, linkOrWeb, purpose, note: null, addenda: null, transferAmount: null, paymentMethod: null, fromAccount: null, dailyLimits: null })
    }

    handleOnChange = (event) => {
        const target = event.target
        switch (target.id) {
            case "wireRemittance":
                // this.saveUserInput('note', target.value.replace(/[a-zA-Z0-9~!@#$%&()_+=-"'\:;<>,.?/\\]/g, '').substring(0, 160))
                this.saveUserInput('note', target.value)
                break
            case "purpose":
                this.saveUserInput(target.id, target.value)
                break
            case "note":
                this.saveUserInput(target.id, target.value)
                break
            case "addenda":
                this.saveUserInput(target.id, target.value.substring(0, 80))
                break
            default:
                this.saveUserInput(target.id || target.name, target.value)
        }
    }

    handleFocusChange = (event) => {
        const { paymentMethod, feeInfo, sameDay, samedayACHFee, transferAmount, fromAccount } = this.state

        const target = event.target

        if (target.id === "transferAmount") this.checkTransferAmount()
    }

    checkTransferAmount = () => {
        const { paymentMethod, feeInfo, sameDay, samedayACHFee, transferAmount, fromAccount } = this.state

        const feeAmount = sameDay ? (samedayACHFee || 2) : ((feeInfo || {}).feeAmount != null) ? (feeInfo || {}).feeAmount : (paymentMethod.type === "ACH" ? 0 : 15)
        const transferMax = fromAccount && Math.max(0, fromAccount.availableBalance - Number(feeAmount));

        if (transferAmount > transferMax) {
            this.setState({ errorField: 'transferAmount', errorMessage: "The transfer amount plus fees exceeds the available balance in your account" })
        } else {
            this.setState({ errorField: null, errorMessage: null })
        }
    }

    isSameDayEnabled = () => {
        const { paymentMethod } = this.state

        if (!paymentMethod || paymentMethod.type !== "ACH") {
            return false
        }

        let currentDay = moment().day()
        let currentDayInET = moment.tz(etTimeZone).day()
        let currentHourInET = moment.tz(etTimeZone).hour()

        return currentHourInET < 10
    }

    handleSameDayCheck = (checked) => {
        this.setState({ sameDay: checked }, this.checkTransferAmount)
    }

    render() {
        const { loading, step, steps, fromAccount, transferAmount, recipients, fromDetails, selectedRowKeys, addenda, recipientDo, linkOrWeb, paymentMethods, paymentMethodsLoading,
            dropdownVisible, showResult, submitLoading, fileList, note, pagination, searchTerm, viewDetails, achSelected, wireSelected, showTransferModal, notificationEmails, purpose,
            approvalRequired, approvalRule, dailyLimits, feeInfo, showCancel, showNewRecipient, noData, sameDay, samedayACHFee, showPendingACHTransfers, showPendingWireTransfers, showPendingCheckTransfers, showPendingInstantTransfers,
            errorField, errorMessage, payorName, uploadedFileList, paymentMethodRecipientMap, recipientMap, showFeeInstruction } = this.state
        var { paymentMethod, recipient } = this.state
        const { theme, bankingStore } = this.props
        const { UserInfo } = store
        var Accounts = getBPAccountsWithAccess("Payments", { permType: "manage", getActiveAccounts: true })
        var achTransferLimit = parseFloat(getFeatureLimit("BusinessBanking.Payments.ACH Limit")) || 10000
        var wireTransferLimit = parseFloat(getFeatureLimit("BusinessBanking.Payments.Wire Limit")) || 10000
        var instantTransferLimit = parseFloat(getFeatureLimit("BusinessBanking.Payments.Instant Transfer Limit")) || 10000
        paymentMethod = paymentMethod || {}
        var bankDetail = paymentMethod.bankDetail
        bankDetail = bankDetail || {}

        var purposeMaxLength = 50

        if (paymentMethod.type == "ACH") purposeMaxLength = 10

        var uploadProps = {
            action: `${environment.apiBaseUrl}/file/upload`,
            headers: {
                businesskey: this.props.store.BusinessUniqueKey,
                jwt: this.props.store.jwt
            },
            onChange: (info) => {
                var savedFileList = this.state.fileList
                var uploadedFileList = this.state.uploadedFileList
                var file = info.file

                var { status, response, name, url } = file
                if (status === "done") {
                    response = response || {}
                    savedFileList.push({
                        fileName: name,
                        uri: (response.UploadedUrls || response.uploadedUrls || [])[0],
                        file: null
                    })
                    uploadedFileList.push(info.file)

                    console.log('uploadedFileListsavedFileListinfo', uploadedFileList, savedFileList, info)
                }

                this.setState({ fileList: savedFileList.map(item => ({ ...item, url: item.uri })), uploading: false, uploadedFileList })
            }
        }

        recipient = recipient || {}
        const { additionalDetails } = recipient

        var options = []
        var filteredAccounts = Accounts.filter(item => { return (!['ACCOUNTS_RECEIVABLE', 'Transitional'].includes(item.accountType)) }) || []
        filteredAccounts.forEach(account => {
            options.push(
                <Option key={account.accountId} value={account.accountId} style={{ backgroundColor: "transparent" }}>
                    <FromAccountOption account={account} />
                </Option>)
        })

        const paymentMethodOptions = []
        paymentMethods.forEach((paymentMethod, index) => {
            paymentMethodOptions.push(
                <Option key={paymentMethod.id} value={paymentMethod.id} style={{ backgroundColor: "transparent", width: '100%' }}>
                    <PaymentMethodOption paymentMethod={paymentMethod} width={526} rail={this.rail} />
                </Option>)
        })

        var disabled = this.isDisabled()
        var buttonTitle = this.getButtonTitle()

        const pendingColumns = [{
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt, record) => {
                return <Text>{moment.utc(createdAt).tz(etTimeZone).format(dFormat)}</Text>
            }
        }, {
            title: 'From Account',
            dataIndex: 'accountNumber',
            key: 'accountNumber',
            render: (accountNumber, record) => {
                var account = Accounts.find(acc => acc.accountNumber === accountNumber)
                return <Text capitalize>{account ? `${(account.nickName || `Business ${account.accountSubType}`)} • ${account.mask}` : '--'}</Text>
            }
        }, {
            title: 'Recipient',
            dataIndex: 'recipient',
            key: 'recipient',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'Amount',
            align: "right",
            render: (amount, record) => {
                if (record.dateRow === true) {
                    return { props: { colSpan: 0 } }
                } else {
                    switch (record.transactionType) {
                        case 'credit':
                            return <Text weight='600' credit>+ {formatter.format(amount)}</Text>
                        case 'debit':
                            return <Text weight='600' color={theme.colors.defaultLightText}>- {formatter.format(Math.abs(amount))}</Text>
                        default:
                            return <Text weight='600' color={theme.colors.defaultLightText}>{formatter.format(Math.abs(amount))}</Text>
                    }
                }
            },
            width: 140,
        }, {
            title: 'Initiated By',
            dataIndex: 'initiatedBy',
            key: 'initiatedBy',
        },]

        if (paymentMethod.type === 'ACH') {
            pendingColumns.push({
                title: 'Delivery Type',
                dataIndex: 'deliveryType',
                key: 'deliveryType',
            })
        }

        const feeAmount = sameDay ? (samedayACHFee || 2) : ((feeInfo || {}).feeAmount != null) ? (feeInfo || {}).feeAmount : (paymentMethod.type === "ACH" ? 0 : 15)
        const transferMax = fromAccount && Math.max(0, fromAccount.availableBalance - Number(feeAmount));

        return (
            <FlexColumn start className='main-padding' fullWidth fullHeight>
                <PageHeader
                    back
                    titleText={`${this.rail ? `${this.rail} ` : ''}Transfer to ${(recipient && (recipient.nameOnAccount || recipient.nickName)) ? (recipient.nickName || recipient.nameOnAccount) : 'a Recipient'}`}
                    marginBottom={24}
                />

                <ProgressSteps key={`${step}ProgressSteps`} step={step} steps={steps} onClick={(step) => this.setState({ step })} />

                {
                    steps[step] !== 'Review' &&
                    <CardContainer padding='0' margin='24px 0 24px' fullHeight fullWidth minWidth='624px'>
                        <FlexColumn between fullHeight>
                            <FlexColumn start style={{ padding: '24px 24px 0 24px', height: '100%' }}>
                                {(() => {
                                    switch (steps[step]) {
                                        case 'Recipient':
                                            return <FlexColumn start fullHeight>
                                                <FlexColumn start gap='24px' style={{ marginBottom: 24, paddingRight: 12 }}>
                                                    <Flex start fullWidth centerHorizontally>
                                                        <LabeledInput
                                                            id="searchTerm"
                                                            nomargin
                                                            width='250px'
                                                            placeholder="Search by name"
                                                            prefixIcon={<img width='12px' height='12px' src={Search} />}
                                                            value={searchTerm}
                                                            onChange={this.handleSearchChange}
                                                        />

                                                        <div style={{ width: 1, height: 36, background: '#F2F2F2', margin: '0 20px 0 28px' }} />

                                                        {/* {
                                                            !this.rail &&
                                                            <Dropdown
                                                                placement='bottomRight'
                                                                trigger={['click']}
                                                                onVisibleChange={flag => this.setState({ dropdownVisible: flag })}
                                                                visible={dropdownVisible}
                                                                overlay={(
                                                                    <Menu onClick={() => { }} style={{ width: 230, padding: '12px 24px 24px', borderRadius: 8 }}>
                                                                        <Tabs defaultActiveKey="2" onClick={() => { }} onChange={this.onTabChange}>
                                                                            <TabPane tab="Type" key="type">
                                                                                <Flex start wrap gap='8px'>
                                                                                    <Chip onAdd={() => this.setState({ achSelected: true, wireSelected: false, dropdownVisible: false }, this.fetchRecipients)} onDelete={() => this.setState({ achSelected: false, dropdownVisible: false }, this.fetchRecipients)} selected={achSelected} add closable text='ACH' background='white' />
                                                                                    <Chip onAdd={() => this.setState({ wireSelected: true, achSelected: false, dropdownVisible: false }, this.fetchRecipients)} onDelete={() => this.setState({ wireSelected: false, dropdownVisible: false }, this.fetchRecipients)} selected={wireSelected} add closable text='Wire' background='white' />
                                                                                </Flex>
                                                                            </TabPane>
                                                                        </Tabs>
                                                                    </Menu>
                                                                )}>
                                                                <ImageButton src={FilterIcon} />
                                                            </Dropdown>
                                                        } */}
                                                        <TextButton solid onClick={() => this.props.history.push('/banking/recipients/new')} icon={<PlusOutlined />} text="ADD RECIPIENT" />
                                                    </Flex>

                                                    {
                                                        (achSelected || wireSelected) &&
                                                        <Flex between fullWidth centerHorizontally>
                                                            <Flex start wrap gap='8px' centerHorizontally>
                                                                {
                                                                    achSelected &&
                                                                    <Chip closable onDelete={() => {
                                                                        this.setState({ achSelected: false }, this.fetchRecipients)
                                                                    }} text='ACH' />
                                                                }

                                                                {
                                                                    wireSelected &&
                                                                    <Chip closable onDelete={() => {
                                                                        this.setState({ wireSelected: false }, this.fetchRecipients)
                                                                    }} text='Wire' />
                                                                }
                                                            </Flex>

                                                            <Text primary noWrap underline style={{ cursor: 'pointer' }} onClick={() => this.setState({ achSelected: false, wireSelected: false }, this.fetchRecipients)}>Clear all</Text>
                                                        </Flex>
                                                    }
                                                </FlexColumn>

                                                <div style={{ height: '100%' }}>
                                                    <TransferToRecipientTable
                                                        responsive
                                                        showRowSelection
                                                        showHeader={false}
                                                        fetch={fetchCounterparties}
                                                        pagination={pagination}
                                                        recipients={recipients}
                                                        loading={loading}
                                                        handleRowSelection={this.handleRowSelection}
                                                        selectedRowKeys={selectedRowKeys}
                                                        rail={this.rail}
                                                        paymentMethodRecipientMap={paymentMethodRecipientMap}
                                                        recipientMap={recipientMap}
                                                    />
                                                </div>
                                            </FlexColumn>
                                        case 'Transfer Details':
                                            const nickNameMaxLen = 30
                                            var pmNickName = `${(paymentMethod.nickName || "").trim().substr(0, nickNameMaxLen)}${(paymentMethod.nickName || "").trim().length > nickNameMaxLen ? "..." : ""}`
                                            return <>
                                                <Flex start gap='24px'>
                                                    <LabeledInput
                                                        inputRef={this.invoiceNumberInput}
                                                        label="From Account"
                                                        type="select"
                                                        id="fromAccount"
                                                        width='576px'
                                                        placeholder="Select account"
                                                        value={fromAccount && <Flex between centerHorizontally style={{ height: 30 }}>
                                                            <Text><img width='16px' height='16px' src={AionIcon} style={{ marginRight: 8, marginBottom: 2 }} />{`${(fromAccount.nickName || `Business ${fromAccount.accountSubType}`)} • ${fromAccount.mask}`}</Text>
                                                            <Text size='14px' lightText style={{ marginLeft: 8 }}>${toCurrency(fromAccount.availableBalance)}</Text>
                                                        </Flex>}
                                                        onChange={this.handleSelectAccount}
                                                    >
                                                        {options}
                                                    </LabeledInput>

                                                    <LabeledInput
                                                        label={"To Account"}
                                                        type="select"
                                                        id="paymentMethod"
                                                        width='576px'
                                                        placeholder={"Select to account"}
                                                        value={
                                                            (paymentMethod && paymentMethod.bankDetail) &&
                                                            <Flex between centerHorizontally style={{ height: '100%', width: '100%' }}>
                                                                <Flex start centerHorizontally>
                                                                    {renderBankIcon(paymentMethod.bankDetail)}
                                                                    <Text title={paymentMethod.nickName}>
                                                                        {
                                                                            paymentMethod.type == "Check" ?
                                                                                `${pmNickName || paymentMethod.type} • ${paymentMethod.bankDetail.checkDeliveryType == 'PostalMail' ? 'Postal Mail' : paymentMethod.bankDetail.checkDeliveryType}`
                                                                                :
                                                                                `${pmNickName || paymentMethod.type} • ${paymentMethod.bankDetail.accountNumber}`
                                                                        }
                                                                    </Text>
                                                                </Flex>

                                                                <Tag primary>{paymentMethod.type}</Tag>
                                                            </Flex>
                                                        }
                                                        loading={paymentMethodsLoading}
                                                        onChange={this.handleSelectpaymentMethod}
                                                        dropdownRender={menu => (
                                                            <FlexColumn start left style={{ width: 312 }}>
                                                                {menu}
                                                                <TextButton margin='8px 16px' onClick={() => this.setState({ showTransferModal: true })} text='ADD NEW' rightIcon={<Image src={Add} />} />
                                                            </FlexColumn>
                                                        )}
                                                    >
                                                        {paymentMethodOptions}
                                                    </LabeledInput>
                                                </Flex>

                                                <Flex start gap='24px'>
                                                    <Flex start gap='36px' centerHorizontally>
                                                        <LabeledInput
                                                            autoFocus
                                                            inputRef={this.accountNumberInput}
                                                            type="number"
                                                            label="Transfer Amount"
                                                            id="transferAmount"
                                                            placeholder="0.00"
                                                            width='576px'
                                                            minHeight='42px'
                                                            min={0}
                                                            // max={transferMax}
                                                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            value={transferAmount}
                                                            onChange={(value) => {
                                                                this.setState({ transferAmount: value })
                                                            }}
                                                            onFocus={this.handleFocusChange}
                                                            onBlur={this.handleFocusChange}
                                                            error={errorField === "transferAmount"}
                                                            errorMessage={errorMessage}
                                                            instruction={(transferAmount && paymentMethod.type === 'Wire' && transferAmount > largeWireAmount) && 'This wire transfer will require phone verification'}
                                                            instructionTooltip={(transferAmount && paymentMethod.type === 'Wire' && transferAmount > largeWireAmount) && <>1. Large amount transfers will require additional phone verification from our bank partner (Cross River Bank)<br />
                                                                2. Phone calls are typically made to the primary administrator or owner on your Aion account<br />
                                                                3. Providing accurate purpose and any additional remittance information will help speed up review of these transactions<br />
                                                                4. Once verification is complete, wire transfers will be processed.</>
                                                            }
                                                        />
                                                    </Flex>

                                                    <Flex start gap='24px' fullWidth>
                                                        <LabeledInput
                                                            label="Purpose"
                                                            id="purpose"
                                                            placeholder="Enter information to be provided to the recipient"
                                                            maxLength={purposeMaxLength}
                                                            value={purpose}
                                                            minHeight={'42px'}
                                                            width='576px'
                                                            onChange={this.handleOnChange}
                                                            instruction={purpose ? `${purpose.length}/${purposeMaxLength}` : `0/${purposeMaxLength}`}
                                                        />
                                                    </Flex>

                                                    {
                                                        false && paymentMethod && dailyLimits &&
                                                        <Flex start gap='12px' style={{ marginTop: 24, marginLeft: 24 }}>
                                                            {/* {
                                                            bankDetail.bankName &&
                                                            <div style={{ minWidth: 282, maxWidth: 500, paddingRight: 8 }}>
                                                                <LabeledInput
                                                                    type='read-only'
                                                                    label="Bank Name"
                                                                    value={bankDetail.bankName}
                                                                />
                                                            </div>
                                                        } */}
                                                            {
                                                                bankDetail.accountNumber &&
                                                                <LabeledInput
                                                                    nomargin
                                                                    type='read-only'
                                                                    label="Account Number"
                                                                    value={bankDetail.accountNumber}
                                                                    width='200px'
                                                                />
                                                            }
                                                            {
                                                                bankDetail.routingNumber &&
                                                                <LabeledInput
                                                                    nomargin
                                                                    type='read-only'
                                                                    label="Routing Number"
                                                                    value={bankDetail.routingNumber}
                                                                    width='200px'
                                                                />
                                                            }
                                                            {
                                                                bankDetail.checkDeliveryType &&
                                                                <LabeledInput
                                                                    label='Delivery Type'
                                                                    nomargin
                                                                    type='read-only'
                                                                    value={bankDetail.checkDeliveryType == 'PostalMail' ? 'Postal Mail' : bankDetail.checkDeliveryType}
                                                                    width='248px'
                                                                />
                                                            }

                                                            {
                                                                bankDetail.checkDeliveryType && bankDetail.addressOnAccount &&
                                                                <LabeledInput
                                                                    label='Delivery Address'
                                                                    nomargin
                                                                    type='read-only'
                                                                    value={addressObjectToMultiLineStr(bankDetail.addressOnAccount)}
                                                                    width='248px'
                                                                />
                                                            }

                                                            {
                                                                (bankDetail.checkDeliveryType == 'Email') &&
                                                                <LabeledInput
                                                                    label='Delivery Address'
                                                                    nomargin
                                                                    type='read-only'
                                                                    value={recipient.email}
                                                                    width='248px'
                                                                />
                                                            }
                                                        </Flex>
                                                    }
                                                </Flex>

                                                {
                                                    this.isSameDayEnabled() &&
                                                    <Checkbox style={{ margin: '0 0 10px 0' }} checked={sameDay} onChange={(e) => this.handleSameDayCheck(e.target.checked)}>Send as Same Day ACH</Checkbox>
                                                }

                                                {
                                                    paymentMethod && paymentMethod !== null && dailyLimits && !sameDay &&
                                                    <FlexColumn start gap='12px' style={{ margin: "12px 0", backgroundColor: theme.colors.backgroundBanner, padding: "12px 24px", borderRadius: "8px", width: 1176 }}>
                                                        {
                                                            paymentMethod.type == "ACH" &&
                                                            <>
                                                                <Text lightText>The daily cut-off time for ACH transfers is 6:00 PM ET. Transfers created after this time are processed the next business day. Generally takes 1 to 3 business days to complete this transfer.</Text>
                                                                <Flex start style={{ gap: "24px" }}>
                                                                    {/* <Text caption>
                                                                        Per Transfer Limit: <span style={{ color: theme.colors.blackPrimary }}>
                                                                            ${toCurrency(achTransferLimit)}
                                                                        </span>
                                                                    </Text> */}

                                                                    <Text caption>Today's Remaining Limit:
                                                                        {
                                                                            dailyLimits.limit === (dailyLimits || {}).dailyAvailableACHBalance ?
                                                                                <span style={{ color: theme.colors.blackPrimary }}> ${toCurrency((dailyLimits || {}).dailyAvailableACHBalance)}</span>
                                                                                :
                                                                                <Text underline primary margin='0 0 0 8px' onClick={() => this.setState({ showPendingACHTransfers: true })}>${toCurrency((dailyLimits || {}).dailyAvailableACHBalance)}</Text>
                                                                        }
                                                                    </Text>
                                                                    <Text caption>
                                                                        ACH Fees: <span style={{ color: theme.colors.blackPrimary }}>
                                                                            ${toCurrency(
                                                                                ((feeInfo || {}).feeAmount != null) ? (feeInfo || {}).feeAmount : 0
                                                                            )}
                                                                        </span>
                                                                    </Text>
                                                                </Flex>
                                                            </>
                                                        }
                                                        {
                                                            paymentMethod.type == "Wire" &&
                                                            <>
                                                                <Text lightText>The daily cut-off time for Wire transfers is 3:00 PM ET. Transfers created after this time are processed the next business day.</Text>
                                                                <Flex start style={{ gap: "24px" }}>
                                                                    {/* <Text caption>
                                                                        Per Transfer Limit: <span style={{ color: theme.colors.blackPrimary }}>
                                                                            ${toCurrency(wireTransferLimit)}
                                                                        </span>
                                                                    </Text> */}

                                                                    <Text caption>Today's Remaining Limit:
                                                                        {
                                                                            dailyLimits.limit === (dailyLimits || {}).dailyAvailableWireBalance ?
                                                                                <span style={{ color: theme.colors.blackPrimary }}> ${toCurrency((dailyLimits || {}).dailyAvailableWireBalance)}</span>
                                                                                :
                                                                                <Text underline primary margin='0 0 0 8px' onClick={() => this.setState({ showPendingWireTransfers: true })}>${toCurrency((dailyLimits || {}).dailyAvailableWireBalance)}</Text>
                                                                        }
                                                                    </Text>
                                                                    <Text caption>
                                                                        Wire Fees: <span style={{ color: theme.colors.blackPrimary }}>
                                                                            ${toCurrency(
                                                                                ((feeInfo || {}).feeAmount != null) ? (feeInfo || {}).feeAmount : 15
                                                                            )}
                                                                        </span>
                                                                    </Text>
                                                                </Flex>
                                                            </>
                                                        }
                                                        {
                                                            paymentMethod.type == "Check" &&
                                                            <>
                                                                <Text lightText>The daily cut-off time for Check transfers is 1:30 PM ET. Transfers created after this time are processed the next business day.</Text>
                                                                <Flex start style={{ gap: "24px" }}>
                                                                    <Text caption>Today's Remaining Limit:
                                                                        {
                                                                            dailyLimits.limit === (dailyLimits || {}).dailyAvailableCheckBalance ?
                                                                                <span style={{ color: theme.colors.blackPrimary }}> ${toCurrency((dailyLimits || {}).dailyAvailableCheckBalance)}</span>
                                                                                :
                                                                                <Text underline primary margin='0 0 0 8px' onClick={() => this.setState({ showPendingCheckTransfers: true })}>${toCurrency((dailyLimits || {}).dailyAvailableCheckBalance)}</Text>
                                                                        }
                                                                    </Text>
                                                                    <Text caption>
                                                                        Check Fees: <span style={{ color: theme.colors.blackPrimary }}>
                                                                            ${toCurrency(
                                                                                ((feeInfo || {}).feeAmount != null) ? (feeInfo || {}).feeAmount : 15
                                                                            )}
                                                                        </span>
                                                                    </Text>
                                                                </Flex>
                                                            </>
                                                        }
                                                        {
                                                            paymentMethod.type == "Instant" &&
                                                            <>
                                                                <Text lightText>Instant transfers are readily available at any time, day or night, without any restrictions on daily cut-off times.</Text>
                                                                <Flex start style={{ gap: "24px" }}>
                                                                    {/* <Text caption>
                                                                        Per Transfer Limit: <span style={{ color: theme.colors.blackPrimary }}>
                                                                            ${toCurrency(instantTransferLimit)}
                                                                        </span>
                                                                    </Text> */}

                                                                    <Text caption>Today's Remaining Limit:
                                                                        {
                                                                            dailyLimits.limit === (dailyLimits || {}).dailyAvailableInstantXferBalance ?
                                                                                <span style={{ color: theme.colors.blackPrimary }}> ${toCurrency((dailyLimits || {}).dailyAvailableInstantXferBalance)}</span>
                                                                                :
                                                                                <Text underline primary margin='0 0 0 8px' onClick={() => this.setState({ showPendingInstantTransfers: true })}>${toCurrency((dailyLimits || {}).dailyAvailableInstantXferBalance)}</Text>
                                                                        }
                                                                    </Text>
                                                                    <Text caption>
                                                                        Instant Transfer Fees: <span style={{ color: theme.colors.blackPrimary }}>
                                                                            ${toCurrency(
                                                                                ((feeInfo || {}).feeAmount != null) ? (feeInfo || {}).feeAmount : 15
                                                                            )}
                                                                        </span>
                                                                    </Text>
                                                                </Flex>
                                                            </>
                                                        }
                                                    </FlexColumn>
                                                }

                                                {
                                                    sameDay &&
                                                    <FlexColumn start gap='12px' style={{ margin: "12px 0", backgroundColor: theme.colors.backgroundBanner, padding: "12px 24px", borderRadius: "8px", width: 1176 }}>
                                                        <Text lightText>The daily cut-off time for Same Day ACH transfers is 10 AM ET.</Text>
                                                        <Flex start style={{ gap: "24px" }}>
                                                            {/* <Text caption>
                                                                Per Transfer Limit: <span style={{ color: theme.colors.blackPrimary }}>
                                                                    ${toCurrency(achTransferLimit)}
                                                                </span>
                                                            </Text> */}

                                                            <Text caption>Today's Remaining Limit:
                                                                {
                                                                    dailyLimits.limit === (dailyLimits || {}).dailyAvailableACHBalance ?
                                                                        <span style={{ color: theme.colors.blackPrimary }}> ${toCurrency((dailyLimits || {}).dailyAvailableACHBalance)}</span>
                                                                        :
                                                                        <Text underline primary margin='0 0 0 8px' onClick={() => this.setState({ showPendingACHTransfers: true })}>${toCurrency((dailyLimits || {}).dailyAvailableACHBalance)}</Text>
                                                                }
                                                            </Text>
                                                            <Text caption>
                                                                Same Day ACH Fees: <span style={{ color: theme.colors.blackPrimary }}>
                                                                    ${toCurrency(
                                                                        samedayACHFee ? samedayACHFee : 2
                                                                    )}
                                                                </span>
                                                            </Text>
                                                        </Flex>
                                                    </FlexColumn>
                                                }

                                                {
                                                    paymentMethod && paymentMethod.type === 'ACH' &&
                                                    <Flex start gap='24px' fullWidth>
                                                        <LabeledInput
                                                            optional
                                                            label="Remittance Information (Addenda)"
                                                            id="addenda"
                                                            placeholder="E.g. Payment for Invoice #4A12"
                                                            maxLength={80}
                                                            value={addenda}
                                                            width='576px'
                                                            onChange={this.handleOnChange}
                                                            instruction={addenda ? `${addenda.length}/80` : '0/80'}
                                                            tooltip="An ACH addenda record is an optional record attached to an ACH transfer. Essentially, it's a note attached to the transfer that is used to provide additional information to the recipient"
                                                        />

                                                        <LabeledInput
                                                            optional
                                                            label="Send email notifications to"
                                                            id="notificationEmails"
                                                            key="notificationEmails"
                                                            type="multi-email"
                                                            instruction="Email notification will contain details of the Date, Amount and Remittance information"
                                                            emails={notificationEmails && notificationEmails}
                                                            onChange={(notificationEmails) => this.setState({ notificationEmails })}
                                                            validateEmail={email => {
                                                                return isEmail(email) // return boolean
                                                            }}
                                                            placeholder="Enter emails"
                                                            onDelete={(index) => {
                                                                var emails = []
                                                                for (var i = 0; i < notificationEmails.length; i++) {
                                                                    if (i != index) emails.push(notificationEmails[i])
                                                                }
                                                                this.setState({ notificationEmails: emails })
                                                            }}
                                                            width='576px'
                                                        />
                                                    </Flex>
                                                }

                                                {
                                                    paymentMethod && paymentMethod.type === 'Wire' &&
                                                    <Flex start gap='24px'>
                                                        <LabeledInput
                                                            label="Remittance Information"
                                                            id="wireRemittance"
                                                            type="text-area"
                                                            placeholder="Enter information to be provided to the recipient"
                                                            maxLength={160}
                                                            width='576px'
                                                            optional
                                                            value={note}
                                                            autoSize={{ minRows: 1, maxRows: 2 }}
                                                            onChange={this.handleOnChange}
                                                            instruction={note ? `${note.length}/160` : '0/160'}
                                                        />

                                                        <LabeledInput
                                                            optional
                                                            label="Send email notifications to"
                                                            id="notificationEmails"
                                                            key="notificationEmails"
                                                            type="multi-email"
                                                            instruction="Email notification will contain details of the Date, Amount and Remittance information"
                                                            tooltip="Email notifications are automatically updated from the associated recipients notification preferences"
                                                            emails={notificationEmails && notificationEmails}
                                                            onChange={(notificationEmails) => this.setState({ notificationEmails })}
                                                            validateEmail={email => {
                                                                return isEmail(email) // return boolean
                                                            }}
                                                            placeholder="Enter emails"
                                                            onDelete={(index) => {
                                                                var emails = []
                                                                for (var i = 0; i < notificationEmails.length; i++) {
                                                                    if (i != index) emails.push(notificationEmails[i])
                                                                }
                                                                this.setState({ notificationEmails: emails })
                                                            }}
                                                            width='576px'
                                                        />
                                                    </Flex>
                                                }

                                                {
                                                    paymentMethod && paymentMethod.type === 'Instant' &&
                                                    <Flex start gap='24px'>
                                                        <LabeledInput
                                                            label="Remittance Information"
                                                            id="note"
                                                            type="text-area"
                                                            placeholder="Enter information to be provided to the recipient"
                                                            maxLength={140}
                                                            width='576px'
                                                            optional
                                                            value={note}
                                                            autoSize={{ minRows: 1, maxRows: 2 }}
                                                            onChange={this.handleOnChange}
                                                            instruction={note ? `${note.length}/140` : '0/140'}
                                                        />

                                                        <LabeledInput
                                                            optional
                                                            label="Send email notifications to"
                                                            id="notificationEmails"
                                                            key="notificationEmails"
                                                            type="multi-email"
                                                            instruction="Email notification will contain details of the Date, Amount and Remittance information"
                                                            tooltip="Email notifications are automatically updated from the associated recipients notification preferences"
                                                            emails={notificationEmails && notificationEmails}
                                                            onChange={(notificationEmails) => this.setState({ notificationEmails })}
                                                            validateEmail={email => {
                                                                return isEmail(email) // return boolean
                                                            }}
                                                            placeholder="Enter emails"
                                                            onDelete={(index) => {
                                                                var emails = []
                                                                for (var i = 0; i < notificationEmails.length; i++) {
                                                                    if (i != index) emails.push(notificationEmails[i])
                                                                }
                                                                this.setState({ notificationEmails: emails })
                                                            }}
                                                            width='576px'
                                                        />
                                                    </Flex>
                                                }

                                                {
                                                    paymentMethod && paymentMethod.type === 'Check' &&
                                                    <Flex between gap='24px' fullWidth>
                                                        <LabeledInput
                                                            label="Payor Name"
                                                            id="payorName"
                                                            placeholder="Enter a name"
                                                            maxLength={60}
                                                            value={payorName}
                                                            onChange={this.handleOnChange}
                                                        />
                                                        <LabeledInput
                                                            label="Memo"
                                                            id="note"
                                                            placeholder="Enter a short memo"
                                                            maxLength={20}
                                                            value={note}
                                                            onChange={this.handleOnChange}
                                                            instruction={note ? `${note.length}/20` : '0/20'}
                                                        />
                                                    </Flex>
                                                }
                                                {
                                                    paymentMethod && paymentMethod.type === 'Check' &&
                                                    <FlexColumn>
                                                        <Text color={'#7384AA'} size='14px' weight={400}>{'Signature on check'}</Text>
                                                        <div id='sigContainer'>
                                                            <Text size='28px' style={{ fontFamily: '\'Alex Brush\', cursive' }} weight={400}>{payorName}</Text>
                                                        </div>
                                                    </FlexColumn>
                                                }
                                            </>
                                        case 'Additional Details':
                                            return <FlexColumn start>

                                                <LabeledInput
                                                    optional
                                                    label="Send email notifications to"
                                                    id="notificationEmails"
                                                    key="notificationEmails"
                                                    type="multi-email"
                                                    emails={notificationEmails && notificationEmails}
                                                    onChange={(notificationEmails) => this.setState({ notificationEmails })}
                                                    validateEmail={email => {
                                                        return isEmail(email) // return boolean
                                                    }}
                                                    placeholder="Enter emails"
                                                    onDelete={(index) => {
                                                        var emails = []
                                                        for (var i = 0; i < notificationEmails.length; i++) {
                                                            if (i != index) emails.push(notificationEmails[i])
                                                        }
                                                        this.setState({ notificationEmails: emails })
                                                    }}
                                                />

                                                <LabeledInput
                                                    optional
                                                    label="What does the recipient do?"
                                                    id="recipientDo"
                                                    placeholder="Eg: Vendor who supplies sugar"
                                                    value={recipientDo}
                                                    onChange={this.handleOnChange}
                                                />

                                                <LabeledInput
                                                    optional
                                                    label="What is the recipient’s website?"
                                                    id="linkOrWeb"
                                                    placeholder="Eg: LinkedIn, Website, Facebook page"
                                                    value={linkOrWeb}
                                                    onChange={this.handleOnChange}
                                                />

                                                <LabeledInput
                                                    optional
                                                    {...uploadProps}
                                                    type='upload'
                                                    label="Reference documents"
                                                    showUploadList
                                                    id="referenceDocuments"
                                                    instruction='We accept jpg, png, pdf'
                                                    accept=".jpg,.png,.pdf"
                                                    defaultFileList={uploadedFileList && uploadedFileList}
                                                />
                                            </FlexColumn>
                                        default:
                                            return null
                                    }
                                })()}
                            </FlexColumn>

                            <FlowFooter
                                disabled={disabled}
                                loading={submitLoading}
                                back={false}
                                backClick={this.handleBack}
                                buttonTitle={buttonTitle}
                                onClick={this.next} />
                        </FlexColumn>
                    </CardContainer>
                }

                {
                    steps[step] === 'Review' &&
                    <FlexColumn start style={{ margin: '24px 0' }}>
                        <CardContainer style={{ marginBottom: 24, minWidth: 600 }}>
                            <FlexColumn start>
                                <Flex start gap='24px' style={{ margin: '24px 0' }}>
                                    <CardContainer shadow padding='24px' style={{ minWidth: 280, height: 112 }}>
                                        <Flex start>
                                            <div style={{ width: 8, height: 64, background: '#F4F5F7', borderRadius: 8, marginRight: 40 }} />
                                            <FlexColumn left>
                                                <Text uppercase color='#737387' weight={500}>{`${(fromAccount.nickName || `Business ${fromAccount.accountSubType}`)}`}</Text>
                                                <Tag style={{ marginTop: 8 }}>{<img style={{ marginRight: 8 }} width={16} height={16} src={AionIcon} />} {fromAccount.mask}</Tag>
                                            </FlexColumn>
                                        </Flex>
                                    </CardContainer>
                                    <FlexColumn center style={{ width: 280 }}>
                                        <Text size='20px' height='32px' weight='500' color='#1199FF'>{`$ ${toCurrency(transferAmount)}`}</Text>
                                        <Image src={ArrowRight} margin='8px 0 12px' />
                                        {
                                            paymentMethod &&
                                            <Tag primary>{(paymentMethod.type === 'ACH' && sameDay) ? 'Same Day ACH' : paymentMethod.type}</Tag>
                                        }
                                    </FlexColumn>
                                    <CardContainer shadow padding='24px' style={{ minWidth: 280, height: 112 }}>
                                        <Flex end>
                                            <FlexColumn right>
                                                <Text uppercase color='#737387' weight={500}>{recipient.nickName || recipient.nameOnAccount}</Text>
                                                <Tag style={{ marginTop: 8 }}>{renderBankIcon(bankDetail)} {bankDetail.accountNumber && bankDetail.accountNumber.length > 3 ? bankDetail.accountNumber.substring(bankDetail.accountNumber.length - 4, bankDetail.accountNumber.length) : bankDetail.accountNumber}</Tag>
                                            </FlexColumn>
                                            <div style={{ width: 8, height: 64, background: '#F4F5F7', borderRadius: 8, marginLeft: 40 }} />
                                        </Flex>
                                    </CardContainer>
                                </Flex>
                                {
                                    paymentMethod && paymentMethod.type == "Wire" &&
                                    <Banner
                                        style={{ maxWidth: "850px" }}
                                        message='This transfer cannot be cancelled. Please carefully review your transfer details before submitting your transfer.'
                                    />
                                }
                                <Flex between style={{ marginTop: 24 }} centerHorizontally>
                                    <Flex start gap='12px'>
                                        <Button text='CANCEL' onClick={() => this.setState({ showCancel: true })} />
                                        <Button solid loading={submitLoading} onClick={this.next} text='CONFIRM' />
                                    </Flex>

                                    {
                                        !viewDetails &&
                                        <TextButton text='VIEW DETAILS' onClick={() => this.setState({ viewDetails: true })} icon={<img width='24px' height='24px' src={ChevronDown} />} />
                                    }
                                </Flex>

                                {
                                    viewDetails &&
                                    <>
                                        {
                                            approvalRequired === true && <>
                                                <Divider />
                                                <Text caption spaced weight='500'>{'Approvals'.toUpperCase()}</Text>
                                                <ApprovalGraph rule={approvalRule} />
                                            </>
                                        }
                                        <Divider />
                                        <Flex between fullWidth>
                                            <Text caption spaced weight='500'>{'Transfer Details'.toUpperCase()}</Text>
                                            <ImageButton src={Edit} onClick={() => this.setState({ step: fromDetails ? 0 : 1 })} />
                                        </Flex>
                                        <Flex start wrap gap='24px'>
                                            <LabeledInput
                                                type='read-only'
                                                label="Transfer Amount"
                                                value={`$${toCurrency(transferAmount)}`}
                                                width='282px'
                                            />
                                            <LabeledInput
                                                type='read-only'
                                                label="From Account"
                                                value={fromAccount && `${fromAccount.productType} • ${fromAccount.mask}`}
                                                width='282px'
                                                capitalize
                                            />
                                            <LabeledInput
                                                type='read-only'
                                                label="Type"
                                                value={paymentMethod.type}
                                                width='282px'
                                            />

                                            {
                                                purpose &&
                                                <LabeledInput type='read-only' label="Purpose" value={purpose} width='282px' />
                                            }

                                            {
                                                note &&
                                                <LabeledInput type='read-only' label="Remittance Information" value={note} width='568px' />
                                            }

                                            {/* {
                                                paymentMethod.type === 'ACH' && purpose &&
                                                <LabeledInput type='read-only' label="Purpose" value={purpose} width='282px' />
                                            } */}

                                            {
                                                addenda &&
                                                <LabeledInput type='read-only' label="Remittance Information (Addenda)" value={addenda} width='282px' />
                                            }

                                            {
                                                paymentMethod.type === 'ACH' &&
                                                <LabeledInput type='read-only' label="Delivery Type" value={sameDay ? 'Same Day' : 'Standard'} width='282px' />
                                            }

                                            {
                                                notificationEmails && notificationEmails.length > 0 &&
                                                <LabeledInput type='read-only' label="Send email notifications to" value={notificationEmails.join(', ')} width='282px' />
                                            }
                                        </Flex>

                                        <Divider />
                                        <Flex between fullWidth>
                                            <Text caption spaced weight='500'>{'Recipient Details'.toUpperCase()}</Text>
                                            {/* {!fromDetails &&
                                                <ImageButton src={Edit} onClick={() => this.setState({ step: 0 })} />
                                            } */}
                                        </Flex>
                                        <FlexColumn>
                                            <Flex start wrap gap='24px'>
                                                <LabeledInput
                                                    type='read-only'
                                                    label="Type"
                                                    value={recipient.type}
                                                    width='282px'
                                                />
                                                <LabeledInput
                                                    type='read-only'
                                                    label="Name"
                                                    value={recipient.nameOnAccount}
                                                    width='282px'
                                                />
                                                {
                                                    this.rail !== 'Instant' &&
                                                    <LabeledInput
                                                        type='read-only'
                                                        label="Email"
                                                        value={recipient.email}
                                                        width='282px'
                                                    />
                                                }
                                                {
                                                    bankDetail.addressOnAccount &&
                                                    <LabeledInput
                                                        type='read-only'
                                                        label="Address"
                                                        value={addressObjectToStr(bankDetail.addressOnAccount)}
                                                        width='282px'
                                                    />
                                                }
                                                {
                                                    bankDetail.accountType &&
                                                    <LabeledInput
                                                        type='read-only'
                                                        label="Account Type"
                                                        value={bankDetail.accountType}
                                                        capitalize
                                                        width='282px'
                                                    />
                                                }
                                                {
                                                    (paymentMethod.type === 'ACH' || paymentMethod.type === 'Wire') && bankDetail.bankAddress &&
                                                    <LabeledInput
                                                        type='read-only'
                                                        label="Bank Address"
                                                        value={addressObjectToStr(bankDetail.bankAddress)}
                                                        width='282px'
                                                    />
                                                }

                                            </Flex>
                                            <Flex start wrap gap='24px'>
                                                <LabeledInput
                                                    type='read-only'
                                                    label="Account Number"
                                                    value={bankDetail.accountNumber}
                                                    width='282px'
                                                />
                                                <LabeledInput
                                                    type='read-only'
                                                    label="Routing Number"
                                                    value={bankDetail.routingNumber}
                                                    width='282px'
                                                />
                                            </Flex>
                                        </FlexColumn>


                                        <Divider />

                                        <TextButton text='HIDE DETAILS' onClick={() => this.setState({ viewDetails: false })} icon={<img width='24px' height='24px' src={ChevronUp} />} />
                                    </>
                                }
                            </FlexColumn>
                        </CardContainer>
                    </FlexColumn>
                }

                <CancelModal
                    visible={showCancel}
                    title='Cancel Transfer?'
                    description='Are you sure you want to cancel this transfer?'
                    buttonTitle='OKAY'
                    onConfirm={() => window.history.back()}
                    onCancel={() => this.setState({ showCancel: false })} />

                <Modal
                    visible={showResult}
                    footer={null}
                    closable={false}
                    width={456}
                    destroyOnClose={true}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <SuccessModal
                        title={`Transfer ${approvalRequired ? 'sent for approval' : 'initiated'}`}
                        description={<FlexColumn gap='24px'>
                            <Text center>Your transfer of ${toCurrency(transferAmount)} to {recipient.nameOnAccount} {approvalRequired ? 'has been sent for approval' : 'has been initiated'}</Text>
                            <Text center>You can view this transfer under the Pending Transactions section in the Accounts page within a few minutes</Text>
                            {
                                (transferAmount > largeWireAmount && paymentMethod.type === 'Wire') &&
                                <Text center>This wire transfer will require phone verification by our bank partner (Cross River Bank)</Text>
                            }
                        </FlexColumn>}
                        buttonTitle='OKAY'
                        descWidth='370px'
                        onConfirm={() => this.props.history.replace({
                            pathname: '/banking/accounts',
                            state: { accountId: fromAccount.accountId }
                        })} />
                </Modal>

                <Modal
                    visible={showTransferModal}
                    width={616}
                    closable={true}
                    maskClosable={true}
                    style={{ borderRadius: '8px !important' }}
                    footer={null}
                    destroyOnClose={true}
                    onCancel={() => this.setState({ showTransferModal: false, transferType: null })}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <AddTransferModal
                        addTransfer
                        recipientId={recipient.objectId}
                        recipientEmail={recipient.email}
                        onConfirm={() => {
                            this.getPaymentMethods(recipient.objectId)
                            this.setState({ showTransferModal: false })
                        }}
                        onDiscard={() => this.setState({ showTransferModal: false, transferType: null })}
                        visible={showTransferModal}
                        msas={[]}
                        rail={this.rail}
                    />
                </Modal>

                <Modal
                    visible={showPendingACHTransfers || showPendingWireTransfers || showPendingCheckTransfers || showPendingInstantTransfers}
                    footer={null}
                    closable={false}
                    width={1200}
                    destroyOnClose={true}
                    onCancel={() => this.setState({ showPendingACHTransfers: false, showPendingWireTransfers: false, showPendingCheckTransfers: false, showPendingInstantTransfers: false })}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    {
                        dailyLimits &&
                        <FlexColumn>
                            <Text heading color='black'>Pending {showPendingACHTransfers ? 'ACH' : (showPendingInstantTransfers ? 'Instant' : 'Wire')} Transfers</Text>
                            {
                                dailyLimits && showPendingACHTransfers &&
                                <Text margin='0 0 12px'>{`$${toCurrency((dailyLimits || {}).dailyLimit - (dailyLimits || {}).dailyAvailableACHBalance)} utilized of $${toCurrency((dailyLimits || {}).dailyLimit)}`}</Text>
                            }
                            {
                                dailyLimits && showPendingWireTransfers &&
                                <Text margin='0 0 12px'>{`$${toCurrency((dailyLimits || {}).dailyLimit - (dailyLimits || {}).dailyAvailableWireBalance)} utilized of $${toCurrency((dailyLimits || {}).dailyLimit)}`}</Text>
                            }
                            {
                                dailyLimits && showPendingCheckTransfers &&
                                <Text margin='0 0 12px'>{`$${toCurrency((dailyLimits || {}).dailyLimit - (dailyLimits || {}).dailyAvailableCheckBalance)} utilized of $${toCurrency((dailyLimits || {}).dailyLimit)}`}</Text>
                            }
                            {
                                dailyLimits && showPendingInstantTransfers &&
                                <Text margin='0 0 12px'>{`$${toCurrency((dailyLimits || {}).dailyLimit - (dailyLimits || {}).dailyAvailableInstantXferBalance)} utilized of $${toCurrency((dailyLimits || {}).dailyLimit)}`}</Text>
                            }
                            <Table
                                id="pending-table"
                                className='borderless-table'
                                pagination={false}
                                tableLayout='auto'
                                columns={pendingColumns}
                                dataSource={dailyLimits.txnDetails}
                                rowKey='objectId' />
                        </FlexColumn>
                    }
                </Modal>
            </FlexColumn >
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer,
        bankingStore: state.bankingAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)))