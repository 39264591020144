import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';

import { FlowViewContainer } from '../../Reusable/FlowViewContainer';
import FlowViewComponent from '../../Reusable/FlowViewComponent';
import {Paragraph} from '../../Reusable/Text';
import Zendesk from '../../Reusable/Zendesk';

const flowViewsData = [{
    "ViewType": "WithAnimation",
    "AnimationName": "support",
    "FlowStep": "Help",
    "Title": "Help Center and Support",
    "Description": null,
    "Progress": 0.0,
    "DefaultUserInput": "Funding Source",
    // "ButtonTitle": "Continue",
    "Step": "Deposit money",
    "ValidationErrorMessage": "Please make a selection"
}];

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentViewIndex: 0,
            flowViews: flowViewsData,
            submitLoading: false,
            stepProgress: 0
        }
    }

    render() {
        const title = "Support";
        return (
            <>
               <FlowViewContainer title={title} contentViews={this.getContentViews()} />
               <Zendesk openSupportWidget={this.state.openSupportWidget} /> 
            </>
        );
    }

    // This will provide the view for each flowView
    getContentViews = () => {
        const { theme } = this.props
        const { flowViews, currentViewIndex } = this.state;
        const flowView = flowViews[currentViewIndex];
        var childViews = (<div></div>); // Initialize
        // Make any customizations here
        switch (flowView.FlowStep) {
            default:
                childViews = (
                    <div>
                        <Paragraph key="1" level={5} style={{ color: theme.colors.systemGray }}>For FAQs please use the <a onClick={()=> this.setState({openSupportWidget : true}) }>support widget</a> or go to our <a target="_blank" href="https://aionfi.zendesk.com">Help Center</a>.</Paragraph>
                        <Paragraph key="2" level={5} style={{ color: theme.colors.systemGray }}>To talk to a human use the <b>Leave us a Message</b> or <b>Live Chat</b> option on the <a onClick={()=> this.setState({openSupportWidget : true}) }>support widget</a></Paragraph>
                        <Paragraph key="2" level={5} style={{ color: theme.colors.systemGray }}>You can also email us at <a href="mailto:contact@aionfi.com">contact@aionfi.com</a></Paragraph>
                    </div>
                )
                break;
        }

        return <FlowViewComponent
            width={550}
            flowView={flowView}
            loadPrev={this.loadPrevView}
            loadNext={this.loadNext}
            saveUserInput={this.saveUserInput}
            childViews={childViews}
            submitLoading={this.state.submitLoading}
        />
    }

    handleOnChange = (event) => {
        const target = event.target;
        this.saveUserInput(target.id || target.name, target.value);
        this.loadNext();
    }

    handleTextChange = (event) => {
        const target = event.target;
        this.saveUserInput(target.id || target.name, target.value);
    }

    handleOnKeyDown = (event) => { if(event.key === "Enter") this.loadNext() }

    // Validate input and go to next view if it's good
    loadNext = () => {
        var stateToSave = this.state;
        var { flowViews, currentViewIndex } = stateToSave;
        
        this.loadNextView();
    }

    saveUserInput = (id, dataToSave) => {
        var { flowViews, currentViewIndex } = this.state;
        var flowView = flowViews[currentViewIndex];
        switch (flowView.FlowStep) {
            default:
                flowView.UserInput = dataToSave;
                break;
        }
        this.setState({ flowViews: flowViews, [id]: dataToSave });
    }

    validateFlow = () => {
        var { flowViews, currentViewIndex } = this.state;
        var flowView = flowViews[currentViewIndex];
        if(!flowView.UserInput) {
            // Check if default exists
            if(flowView.DefaultUserInput) flowView.UserInput = flowView.DefaultUserInput;
        }
        var validation = {
            validated: false,
            message: flowView.ValidationErrorMessage ?? "Please provide an input to continue"
        }
        switch (flowView.FlowStep) {
            default:
                if(flowView.UserInput || flowView.DefaultUserInput) validation.validated = true;
                break;
        }
        this.setState({ flowViews: flowViews });
        return validation;
    }

    loadPrevView = () => {
        let { flowViews, currentViewIndex } = this.state;
        if (currentViewIndex !== 0) {
            this.setState({
                currentViewIndex: currentViewIndex - 1,
                flowViews: flowViews,
                flowView: flowViews[currentViewIndex - 1],
                showError: false, errorMsg: ""      // dismiss any validatin error, if any, for the current step
            });
        }
    }

    loadNextView = () => {
        var flowViews = this.state.flowViews;
        var currentViewIndex = this.state.currentViewIndex + 1
        if (currentViewIndex < this.state.flowViews.length) {
            this.setState({
                currentViewIndex: currentViewIndex,
                flowView: flowViews[currentViewIndex]
            });
        }
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)));