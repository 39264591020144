import React, { Component } from 'react';
import { connect } from 'react-redux';
import Lottie from 'react-lottie';
import { withTheme } from 'styled-components';

import { Divider, Skeleton, Affix } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { Button, TextButton } from './Button';
import { Paragraph, Title } from './Text';
import { FlexColumn, Flex } from './Container';

// Lottie
import usFlagData from '../Lotties/us-flag.json';
import nameCardData from '../Lotties/name-card.json';
import moneyStackData from '../Lotties/money-stack.json';
import connectData from '../Lotties/connect.json';
import postBoxData from '../Lotties/post-box.json';
import editData from '../Lotties/edit.json'
import browserClickData from '../Lotties/browser-click.json';
import applicationCompleteData from '../Lotties/application-complete.json';
import rocketTakeoff2Data from '../Lotties/rocket-takeoff-2.json';
import clap from '../Lotties/clap.json';
import coinStack from '../Lotties/coin-stack.json';
import success from '../Lotties/success.json';
import debitCards3 from '../Lotties/debit-cards-3.json';
import sendTransfer2 from '../Lotties/send-transfer-2.json';
import user from '../Lotties/user.json';
import support from '../Lotties/support.json';
import bookkeeping from '../Lotties/bookkeeping.json';
import sign from '../Lotties/sign-off.json';
import driverLicense from '../Lotties/driver-license.json';
import driverLicenseScan from '../Lotties/driver-licence-scan.json';
import genericBankCard from '../Lotties/generic-bank-card.json';
import globeData from '../Lotties/globe.json';

// Images
import twoFA from '../../Images/2FA.png';
import bankCheck from '../../Images/BankCheck.png';
import MobileLogoutIcon from '../../Images//logout_mobile.png';
import mobile_deposit_front from '../../Images/mobile_deposit_front.png';
import mobile_deposit_back from '../../Images/mobile_deposit_back.png';
import signup_illustration from '../../Images/signup-illustration.png';
import { isMobile } from '../../Utils/util';
import { doSignout } from '../../Utils/api';

// Components
import Banner from '../Reusable/Banner';

const LOTTIES = {
    'us-flag': usFlagData,
    'name-card': nameCardData,
    'money-stack': moneyStackData,
    'connect': connectData,
    'post-box': postBoxData,
    'edit': editData,
    'browser-click': browserClickData,
    'application-complete': applicationCompleteData,
    'rocket-takeoff-2': rocketTakeoff2Data,
    'clap': clap,
    'coin-stack': coinStack,
    'success': success,
    'debit-cards-3': debitCards3,
    'send-transfer-2': sendTransfer2,
    'user': user,
    'support': support,
    'bookkeeping': bookkeeping,
    'sign': sign,
    'driver-license': driverLicense,
    'driver-license-scan': driverLicenseScan,
    'generic-bank-card': genericBankCard,
    'globe': globeData,
}

const IMAGES = {
    '2FA': twoFA,
    'BankCheck': bankCheck,
    'mobile_deposit_front': mobile_deposit_front,
    'mobile_deposit_back': mobile_deposit_back,
    'signup-illustration': signup_illustration
}

class OnboardingFlowViewComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {};
    }

    handleSubmit = () => {
        this.props.loadNext()
    }

    handleBack = () => {
        this.props.loadPrev()
    }

    getLottieOptions = (animationName) => {
        return {
            loop: false,
            autoplay: true,
            animationData: LOTTIES[animationName],
            rendererSettings: {
                preserveAspectRatio: "xMidYMid meet"
            }
        }
    }

    signout = () => {
        doSignout(this.props.history, this.props.dispatch);
    }

    render() {
        const { loading, flowView, theme, currentViewIndex, width, childViews, rightBlock, rightBlockWidth, banner } = this.props;
        var desc = flowView.DescriptionToDisplay || flowView.Description;
        var descArr = null;
        if ((desc || "").includes("\n")) {
            const arr = desc.split("\n");
            descArr = [];
            arr.forEach((item, i) => {
                descArr.push(<br key={i} />);
                descArr.push(item);
            });
        }
        const animationWidth = flowView.AnimationWidth || 250;
        return (
            <Skeleton loading={loading} active title={true} paragraph={{ rows: 3 }}>
                <FlexColumn style={{ minHeight: "85vh", justifyContent: "space-between", marginTop: 20, padding: this.props.padding || null }}>
                    <Flex>
                        <FlexColumn style={{ width: width || "450px", justifyContent: "space-between", padding: '0 20px' }}>
                            <div>
                                {
                                    isMobile ?
                                        flowView.BackButton !== false && !(flowView.FlowStep || "").includes(".Success") && (currentViewIndex > 0) &&
                                        <Flex style={{ justifyContent: "space-between" }}>
                                            <TextButton onClick={this.handleBack} leftIcon={<LeftOutlined />} text="Back" />
                                            <img src={MobileLogoutIcon} height="32" alt="Logout" title="Logout" style={{ cursor: "pointer" }} onClick={() => this.signout()} />
                                        </Flex>
                                        :
                                        <Affix offsetTop={0}>
                                            {
                                                flowView.BackButton !== false && !(flowView.FlowStep || "").includes(".Success") && (currentViewIndex > 0) &&
                                                <Flex style={{ justifyContent: "start", background: "#FBFBFB" }}>
                                                    <TextButton onClick={this.handleBack} leftIcon={<LeftOutlined />} text="Back" />
                                                </Flex>
                                            }
                                        </Affix>
                                }

                                {
                                    banner &&
                                    <Banner
                                        style={{ marginTop: flowView.BackButton !== false && !(flowView.FlowStep || "").includes(".Success") && (currentViewIndex > 0) && 10 }}
                                        title={banner.title}
                                        message={banner.message} />
                                }

                                {
                                    flowView.ViewType === "WithAnimation" &&
                                    <FlexColumn center>
                                        <Lottie
                                            key={`lottie${flowView.FlowStep}${currentViewIndex}`}
                                            options={this.getLottieOptions(flowView.AnimationName)}
                                            width={animationWidth}
                                            height={animationWidth}
                                        />
                                    </FlexColumn>
                                }
                                {
                                    flowView.ViewType === "WithImage" &&
                                    <FlexColumn center>
                                        <img
                                            src={IMAGES[flowView.ImageName]}
                                            width={animationWidth}
                                            // height={animationWidth}
                                            alt="logo"
                                            style={{ marginBottom: 20 }}
                                        />
                                    </FlexColumn>
                                }
                                <div style={{ margin: "20px 0 0 0" }} >
                                    <Title level={4} style={{ fontWeight: 600 }}>{flowView.AttrTitle || (flowView.TitleToDisplay || flowView.Title)}</Title>
                                    {desc ? <Paragraph level={3} style={{ color: theme.colors.systemGray }}>{flowView.descComponent || (descArr || desc)}</Paragraph> : null}
                                </div>
                                {childViews}
                            </div>
                        </FlexColumn>
                        {rightBlock &&
                            <Flex>
                                <Divider type="vertical" style={{ height: "auto", margin: "0 5px 0 15px" }} />
                                <FlexColumn style={{ width: rightBlockWidth || "450px" }}>
                                    <div>
                                        {rightBlock}
                                    </div>
                                </FlexColumn>
                            </Flex>
                        }
                    </Flex>
                    <Affix offsetBottom={0}>
                        {!flowView.hideButton && 
                        <Button
                            solid
                            permtype="Override"
                            style={{ alignSelf: 'center', width: width || '450px', margin: '20px 0' }}
                            loading={this.props.submitLoading}
                            type="primary"
                            onClick={this.handleSubmit}
                            text={this.props.altButtonTitle ? this.props.altButtonTitle : flowView.ButtonTitle}
                        />}
                        {
                            this.props.continueLink &&
                            <div style={{ marginLeft: '8pt' }}>
                                <a onClick={this.props.altButtonClick}>{this.props.continueLink}</a>
                            </div>
                        }
                    </Affix>
                </FlexColumn>
            </Skeleton>

        )
    }
}

function mapStateToProps(state) {
    return { store: state };
}

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(OnboardingFlowViewComponent));