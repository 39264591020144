import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';

import { Skeleton } from 'antd';
import { Button } from './Button';
import { Text } from './Text';
import { Image } from '../Image';
import { FlexColumn, Flex } from '../Container';
import FlowFooter from './FlowFooter';
import ProgressSteps from '../ProgressSteps';
import Chat from '../../OnboardingFlowCoordinator/CreditOnboarding/Chat';
// Lottie
import usFlagData from '../../Lotties/us-flag.json';
import nameCardData from '../../Lotties/name-card.json';
import moneyStackData from '../../Lotties/money-stack.json';
import connectData from '../../Lotties/connect.json';
import postBoxData from '../../Lotties/post-box.json';
import editData from '../../Lotties/edit.json'
import browserClickData from '../../Lotties/browser-click.json';
import applicationCompleteData from '../../Lotties/application-complete.json';
import rocketTakeoff2Data from '../../Lotties/rocket-takeoff-2.json';
import clap from '../../Lotties/clap.json';
import coinStack from '../../Lotties/coin-stack.json';
import success from '../../Lotties/success.json';
import debitCards3 from '../../Lotties/debit-cards-3.json';
import genericBankCard from '../../Lotties/generic-bank-card.json';
import sendTransfer2 from '../../Lotties/send-transfer-2.json';
import user from '../../Lotties/user.json';
import support from '../../Lotties/support.json';
import bookkeeping from '../../Lotties/bookkeeping.json';

// Images
import AionLogo from '../../../Images/aion-shadow.png'
import Banner from '../../Reusable/Banner'
import Check from "../../../Images/gs-check.png"
import ModalClose from '../../../Images/modal-close.png'

const LOTTIES = {
    'us-flag': usFlagData,
    'name-card': nameCardData,
    'money-stack': moneyStackData,
    'connect': connectData,
    'post-box': postBoxData,
    'edit': editData,
    'browser-click': browserClickData,
    'application-complete': applicationCompleteData,
    'rocket-takeoff-2': rocketTakeoff2Data,
    'clap': clap,
    'coin-stack': coinStack,
    'success': success,
    'debit-cards-3': debitCards3,
    'send-transfer-2': sendTransfer2,
    'user': user,
    'support': support,
    'bookkeeping': bookkeeping,
    'generic-bank-card': genericBankCard,
}

class FlowViewComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {};
    }

    handleSubmit = () => {
        this.props.loadNext()
    }

    handleClose = () => {
        this.props.onCloseClick()
    }

    handleBack = () => {
        this.props.loadPrev()
    }

    getLottieOptions = (animationName) => {
        return {
            loop: false,
            autoplay: true,
            animationData: LOTTIES[animationName],
            rendererSettings: {
                preserveAspectRatio: "xMidYMid meet"
            }
        }
    }

    render() {
        const { loading, flowView, currentViewIndex, width, childViews, back, step, steps, hideSteps, showFooter, headerMargin, loanApplication, applicationStep, reviewEditMode, cvOverFlow, showSuccessBanner, reviewEditCancel} = this.props;
        const { ShowChat, ShowAionLogo } = flowView;
        var desc = flowView.DescriptionToDisplay || flowView.Description;
        var descArr = null;
        if ((desc || "").includes("\n")) {
            const arr = desc.split("\n");
            descArr = [];
            arr.forEach((item, i) => {
                descArr.push(<br key={i} />);
                descArr.push(item);
            });
        }
        var backButton = false;
        (!back && currentViewIndex == 0) ? backButton = false : backButton = true;
        const animationWidth = flowView.AnimationWidth || 250;
        const isreviewEditMode = (reviewEditMode === undefined);
        return (
            <Skeleton loading={loading} active title={true} paragraph={{ rows: 3 }}>
                <FlexColumn start style={{ width: width || "450px", minHeight: "100%", padding: this.props.padding || null }}>
                    <FlexColumn start style={{ margin: headerMargin ? headerMargin : "20px 0 0px 0" }} >
                        <Flex between fullWidth>
                            <FlexColumn>
                                <Flex start style={{ margin: '32px 24px 12px', position: "absolute", top: "12px", left: "75px" }}>
                                    {ShowAionLogo && <Image src={AionLogo} />}
                                </Flex>
                                <Text style={{ justifyContent: "flex-start" }} weight='500' color='#444444' size='28px' height='40px'>{flowView.AttrTitle || (flowView.TitleToDisplay || flowView.Title)}</Text>
                            </FlexColumn>
                            <span style={{ justifyContent: "flex-end", position: "absolute", top: "10px", right: "110px" }}>
                                {ShowChat === true ? <Chat loanApp={loanApplication || {}} applicationStep={applicationStep} /> : null}
                            </span>
                            <span style={{ justifyContent: "flex-end", margin: '4px -96px 24px' ,position: "absolute", top: "10px", right: "110px" }}>
                                {reviewEditCancel ? <img width='24px' height='24px' src={ModalClose} onClick={this.handleClose} /> : null}
                            </span>
                        </Flex>
                        {desc ? <Text color='#444444' margin='8px 0 0'>{flowView.descComponent || (descArr || desc)}</Text> : null}
                    </FlexColumn>
                    {
                        !hideSteps &&
                        <ProgressSteps key={`${flowView.FlowStep}ProgressSteps`} step={step} steps={steps} onClick={(step) => this.props.goToStep && this.props.goToStep(step)} />
                    }
                    <FlexColumn start fullHeight style={flowView.Shadow && { borderRadius: 8, boxShadow: '0px 4px 8px rgba(102, 102, 102, 0.08)', margin: '24px 0', height: '100%', background: 'white' }}>
                        <FlexColumn start fullHeight style={flowView.Shadow && { padding: 24, overflow: cvOverFlow} }>
                            {childViews}
                        </FlexColumn>
                        {
                            showFooter &&
                            <FlexColumn end style={{ width: '100%' }}>
                                 {
                                    this.props.showSuccessBanner &&
                                    <Banner
                                        background="#F5F9F5"
                                            style={{ width: 320, height: 48, marginBottom: 10, marginTop: '24px', marginLeft: 'auto', marginRight: 'auto', padding: '10px' }}
                                            message="Your information has been saved"
                                            borderColor="#D6E9D5"
                                            color="#318F2F"
                                            icon={( <Image src={Check} />)}
                                        />
                                 }
                                <FlowFooter
                                    key={flowView.FlowStep}
                                    loading={this.props.submitLoading}
                                    back={backButton}
                                    backClick={this.handleBack}
                                    buttonTitle={flowView.ButtonTitle}
                                    onClick={this.handleSubmit} />
                            </FlexColumn>
                        }
                        {
                            reviewEditMode &&
                            <Button loading={this.props.submitLoading} solid height='40px' radius='4px' onClick={this.handleSubmit} text={flowView.ButtonTitle} style={{ alignSelf: 'start', marginTop: '24px' }} />
                        }
                    </FlexColumn>
                </FlexColumn>
            </Skeleton>

        )
    }
}

function mapStateToProps(state) {
    return { store: state };
}

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(FlowViewComponent));
