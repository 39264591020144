import React, { Component } from 'react'
import { withTheme } from 'styled-components';
import styled from 'styled-components';

import { Divider, Skeleton, Empty } from 'antd'
import { LabeledInput } from '../../Reusable/Input';
import { capitalizeWords, STATES_MAP } from '../../../Utils/util'
import { TextButton } from '../../Reusable/Button';
import { Image } from '../../Reusable/Image';

import Search from '../../../Images/search.png'
import XIcon from '../../../Images/icon-x.svg';
import { FlexColumn } from '../../Reusable/Container';
import { Flex } from '../../Reusable/Container';
import { Paragraph } from '../../Reusable/Text';
import environment from '../../../environment';
import { apiPOSTReq } from '../../../Utils/api';

const Entry = styled.div`
    cursor: pointer;
    /* padding: 3px 4px; */
    font-size: 16px;
    &:hover {
        background: ${props => props.theme.colors.systemGray7};
    }
`;

class SearchBanks extends Component {

    state = {
        searchText: this.props.searchText || "",
        results: [],
        loading: false
    };
    cancel = '';
    searchRef = React.createRef();

    fetchSearchResults = (searchText) => {
        this.setState({ loading: true });
        apiPOSTReq(`${environment.bpBaseUrl}/bp/payments/getAbadirectory`, {}, { searchStr: searchText, size: 50 }, (err, resp) => {
            try {
                this.setState({ loading: false })
                const data = resp || {};
                console.log("/payments/getAbadirectory", data);

                this.setState({ ...data });
            } catch (error) {
                console.log("ERRR getAbadirectory", error, err, resp);
            } finally {
                console.log('fetchSearchResults finally')
                this.setState({ loading: false })
            }
        })
    };

    handleInputChange = (searchText) => {
        if (!searchText && (searchText == "")) {
            this.setState({ searchText, results: [], loading: false });
        } else {
            this.setState({ searchText, results: [], loading: true });
            this.fetchSearchResults(searchText);
        }
    };

    handleSelectBank = (bank) => {
        this.setState({ searchText: "", companies: [], loading: false });
        this.props.handleSelect(bank);
    }

    handleClearSearch = () => {
        this.setState({ searchText: "", companies: [], loading: false })
        // this.searchRef.current.value = null
    }

    getBusinessListItem = (bank, options) => {
        const { noMatches } = options || {};
        const { searchText } = this.state;

        const boldSubStr = (str, query) => {
            query = query || "";
            const n = str.toUpperCase();
            const q = query.toUpperCase();
            const x = n.indexOf(q);
            if (!q || x === -1) {
                return str; // bail early
            }
            const l = q.length;
            return <span>{str.substr(0, x)}<b>{str.substr(x, l)}</b>{str.substr(x + l)}</span>
        }

        const getFullStateName = (jurisdiction_code) => {
            jurisdiction_code = jurisdiction_code || "";
            let state = jurisdiction_code.substr(jurisdiction_code.lastIndexOf('_') + 1);
            return capitalizeWords(STATES_MAP[state.toUpperCase()]);
        }

        var name = (bank.customerName || "");
        name = boldSubStr(name, searchText);
        // console.log("bank", bank);
        return (
            <div key={bank.routingNumber}>
                <Entry onClick={() => this.handleSelectBank(bank)}>
                    <Flex style={{ gap: "8px", justifyContent: "space-between" }}>
                        <FlexColumn style={{ gap: "4px" }}>
                            <Paragraph noMargin>{name}</Paragraph>
                            <Paragraph noMargin style={{ color: this.props.theme.colors.defaultLightText, fontSize: "14px" }}>{!noMatches ? <span>Routing No.: <b>{bank.routingNumber}</b></span> : "Can't find your bank, enter manually below."}</Paragraph>
                        </FlexColumn>
                        {!noMatches && <span style={{ color: this.props.theme.colors.defaultLightText, fontSize: "12px" }}>
                            {capitalizeWords(bank.city)}, {getFullStateName(bank.state)}
                        </span>}
                    </Flex>
                </Entry>
            </div>
        )
    }

    render() {
        const { theme } = this.props;
        const { closeDropdown } = this.state;
        const renderSearchResults = () => {
            const { loading, results, searchText } = this.state;
            if (!loading && (results.length == 0) && (searchText != ""))
                return this.getBusinessListItem({ customerName: searchText }, { noMatches: true });
            else if (loading) {
                return (
                    <FlexColumn style={{ height: "120px" }}>
                        <Skeleton active paragraph={{ rows: 2 }} />
                    </FlexColumn>
                )
            }
            else
                return results.map(result => this.getBusinessListItem(result));
        };

        return (
            <LabeledInput
                inputRef={this.searchRef}
                type="remote-search"
                label="Bank"
                optional
                showArrow={false}
                labelcolor={this.props.theme.colors.secondary3}
                placeholder={this.state.searchText == "" ? "Search for bank" : ""}
                searchValue={this.state.searchText}
                onSearch={this.handleInputChange}
                symbolprefix={<Image width='12px' height='12px' src={Search} />}
                symbolsuffix={
                    (this.state.searchText != "") && <TextButton onClick={() => this.handleClearSearch()} text={<img height={"15.5px"} width={"15.5px"} src={XIcon} />} />
                }
                open={(this.state.searchText != "") && !closeDropdown}
                onBlur={() => { this.setState({ closeDropdown: true }) }}
                onFocus={() => { this.setState({ closeDropdown: false }) }}
                dropdownStyle={{ minHeight: "206px" }}
                style={{ padding: 0 }}
                color={theme.colors.primary}
                padding="8px"
                left={1}
                right={1}
                textIndent="24px"
                disabled={this.props.disabled}
                width={this.props.width && this.props.width}
            >
                {renderSearchResults()}
            </LabeledInput>
        )
    }
}

export default withTheme(SearchBanks);