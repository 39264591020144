import React from 'react'

import { Flex } from './Container'

// Images
import ChevronLeft from '../../Images/chevron-left.svg'
import ChevronRight from '../../Images/chevron-right.svg'
import ChevronLeftUnselected from '../../Images/chevron-left-unselected.svg'
import ChevronRightUnselected from '../../Images/chevron-right-unselected.svg'
import PagerDot from '../../Images/pager-dot.svg'
import PagerDotUnselected from '../../Images/pager-dot-unselected.svg'

const Pager = (props) => {
    const { page, pages, noArrows, dotClickable, onClick, noDots } = props

    const pageItems = []
    for (let i = 0; i < pages; i++) {
        pageItems.push(<img style={{ cursor: dotClickable ? 'pointer' : null }} width='8px' height='8px' onClick={() => onClick && onClick(i)} src={page === i ? PagerDot : PagerDotUnselected} />)
    }

    return (
        <Flex centerHorizontally style={{ zIndex: 99 }}>
            {
                !noArrows &&
                <Flex center style={{ width: 40, height: 40, zIndex: 99 }}>
                    <img
                        width='16px'
                        height='16px'
                        style={{ cursor: page > 0 && 'pointer' }}
                        src={page === 0 ? ChevronLeftUnselected : ChevronLeft}
                        onClick={() => page > 0 && props.previous()}
                    />
                </Flex>
            }
            {
                !noDots && 
                <Flex center gap='8px' style={{ zIndex: 99 }}>
                    {pageItems}
                </Flex>
            }
            {
                !noArrows &&
                <Flex center style={{ width: 40, height: 40, zIndex: 99 }}>
                    <img
                        width='16px'
                        height='16px'
                        style={{ cursor: page < pages - 1 && 'pointer' }}
                        src={page === pages - 1 ? ChevronRightUnselected : ChevronRight}
                        onClick={() => page < pages - 1 && props.next()}
                    />
                </Flex>
            }
        </Flex>
    )
}

export default Pager