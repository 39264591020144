import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import { PlaidLink, usePlaidLink } from "react-plaid-link";
import { Skeleton, Modal, Table, Badge, Alert } from 'antd';
import { TextButton, Button } from '../../Reusable/Button'

import { Flex, FlexColumn, InputContainer, LightContainer } from '../../Reusable/Container'
import PageHeader from '../../Reusable/PageHeader';
import { ErrorAlert } from '../../Reusable/Alert';
import { Paragraph } from '../../Reusable/Text';
import environment from '../../../environment';
import { apiGET, apiPOST, apiPOSTReq } from '../../../Utils/api';
import { CheckCircleTwoTone, ExclamationCircleTwoTone, PlusOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { Text } from '../../Reusable/Text'
import { addDataToStore, CREDIT_SAVE_DATA, SAVE_DATA } from '../../../Actions/actions';
import Info from '../../Reusable/Info';
import moment from 'moment'
import Banner from '../../Reusable/Banner';
import YodleeIntegration from './YodleeIntegration';
import BankIcon from '../../../Images/bank-generic.png';

class Index extends Component {
    state = {
        loading: false,
        loadingBanks: false,
        showBankDetails: false, 
        linkToken: '',
        reconnectBank: false,
        loadTransactions: false,
        reconnectBanner: false,
        yodleeLink: false,
        loadingButton: null
    }


    componentDidMount() {
        this.getPlaidLinkToken();
        this.fetchBanks();
    }

    fetchBanks = () => {
        const headers = {
            
            // businesskey: props.aionStore.BusinessUniqueKey,
        }

        const body = {
            "BusinessId": this.props.store.BusinessUniqueKey
        }
        this.setState({ loadingBanks:true })
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/plaid/getInstitutionInfo`, headers, body, (err, resp) => {
            try {
                const data = resp;
                console.log("fetchBankInfo", data.externalBBInstitutions)
                var bankInfo = data.externalBBInstitutions
                for (let index = 0; index < bankInfo.length; index++) {
                    if(bankInfo[index].status != "Connected"){
                        this.setState({ reconnectBanner: true })
                    }    
                }
                this.setState({ banks: bankInfo })
            } catch (error) {
                console.log("ERROR", error.stack)
            } finally {
                this.setState({ loadingBanks:false })
            }
        })
    }

    getPlaidLinkToken = () => {
        var header = {
            
        }
        var body = {
            "redirectUri": window.location.href,
            "BusinessId": this.props.store.BusinessUniqueKey,
        }

        apiPOSTReq(`${environment.integrationBaseUrl}/integration/plaid/getLinkToken`, header, body, (err, resp) => {
            try {
                const response = resp || {};
                if (response.result) {
                    this.setState({ linkToken: response.linkToken })
                } else {
                    ErrorAlert({ description: response.responseMessage });
                }
            }
            catch (err) {
            }
        })
    }

    getPlaidAccessToken = (public_token, metadata, includeAuth, accessToken) => {
        this.setState({ loadingReconnect: true })
        var header = {
        }
        var body = {
            "redirectUri": window.location.href,
            "plaidTokenData": {
                publicToken: public_token,
                accountId: metadata.account.id,
                accountName: metadata.account.name,
                institution: metadata.institution.name,
                institutionId: metadata.institution.institution_id,
            },
            "loggedInUserId": this.props.store.UserInfo.Email,
            "BusinessId": this.props.store.BusinessUniqueKey,
            "includeAuth": includeAuth,
            "accessToken": accessToken
            
        }

        apiPOSTReq(`${environment.integrationBaseUrl}/integration/plaid/getAccessToken`, header, body, (err, resp) => {
            try {
                const response = resp || {};
                if (response.result) {
                    this.setState({ reconnectBanner: false, showBankDetails: false, loadingReconnect: false })
                    this.fetchBanks()
                } else {
                    ErrorAlert({ description: response.responseMessage });
                    this.setState({ loadingBanks:false })
                }
            }
            catch (err) {
                
            }

        })

    }

    logPlaidEvent = (eventName, metadata) => {
        apiPOST("/logError", {}, { logType: "PLAID_DESKTOP", eventName: eventName, metadata: metadata }, (err, resp) => {
            console.log("Plaid event logged", err, resp);
        });
    }

    handleBankDetails = (bankInfo) => {
        this.setState({ loading: true, loadingButton: bankInfo.itemId })
        if(bankInfo.integrationProvider == "Plaid") {
            const headers = {
                
                // businesskey: props.aionStore.BusinessUniqueKey,
            }
    
            const body = {
                "itemId": bankInfo.itemId,
                "redirectUri": window.location.href,
                "connectionStatus": bankInfo.status
    
            }
    
            console.log("Metabody:",body)
            apiPOSTReq(`${environment.integrationBaseUrl}/integration/plaid/getAccountDetails`, headers, body, (err, resp) => {
                try {
                    const response = resp || {};
                    this.setState({ loading: false, loadingButton: null })
                    if (response.result) {
                        this.setState({ accountDetails: response.accountsList, showBankDetails: true, bankStatus: bankInfo.status, 
                            bankUrl: bankInfo.logoUrl, bankName: bankInfo.bankName, bankLastUpdated: bankInfo.lastUpdatedAt, relinkToken: response.linkToken, 
                            bankAccessToken: bankInfo.accessToken, reconnectBank: false, itemId: bankInfo.itemId })
                        if(bankInfo.status != "Connected" ) {
                            this.setState({ reconnectBank: true })
                        }
                    } else {
                        ErrorAlert({ description: response.responseMessage });
                    }
                }
                catch (err) {
                    
                }
    
            })
        }
        else {
            const headers = {
                
                // businesskey: props.aionStore.BusinessUniqueKey,
            }
    
            const body = {
                "providerAccountId": bankInfo.providerAccountId,
    
            }
    
            apiPOSTReq(`${environment.integrationBaseUrl}/integration/yodlee/getAccountDetails`, headers, body, (err, resp) => {
                try {
                    const response = resp || {};
                    if (response.result) {
                        this.setState({ accountDetails: response.accountsList, showBankDetails: true, bankStatus: bankInfo.status, 
                            bankUrl: bankInfo.logoUrl, bankName: bankInfo.bankName, bankLastUpdated: bankInfo.lastUpdatedAt, relinkToken: response.linkToken, 
                            bankAccessToken: bankInfo.accessToken, reconnectBank: false, itemId: bankInfo.itemId })
                        if(bankInfo.status != "Connected" ) {
                            this.setState({ reconnectBank: true })
                        }
                    } else {
                        ErrorAlert({ description: response.responseMessage });
                    }
                }
                catch (err) {
                    
                }
    
            })
    
        }
    }

    closeBankDetails = () => {
        this.setState({ showBankDetails: false })
    }

    removePlaidConnection = () => {
        this.setState({ loadTransactions: true })
        const headers = {
            
        }

        const body = {
            "itemId": this.state.itemId,
            "BusinessId": this.props.store.businessId
        }

        apiPOSTReq(`${environment.integrationBaseUrl}/integration/plaid/removeConnection`, headers, body, (err, resp) => {
            try {
                const response = resp || {};
                if (response.result) {
                    this.setState({ loadTransactions: false })
                } else {
                    ErrorAlert({ description: "Transactions not available to sync" });
                    this.setState({ loadTransactions: false })
                }
            }
            catch (err) {
                
            }

        })
    }

    getBankInfo = (data) => {
        var bankInfo = data.externalBBInstitutions
            for (let index = 0; index < bankInfo.length; index++) {
                if(bankInfo[index].status != "Connected"){
                    this.setState({ reconnectBanner: true })
                }    
            }
        this.setState({ banks: bankInfo })
    }

    handleYodleeLink = () => {
        if(this.state.yodleeLink == true){
            this.setState({ yodleeLink: false })
        }
        else{
            this.setState({ yodleeLink: true })
        }
        
    }


    render() {
        const { theme, store } = this.props;
        const { linkToken, yodleeLink, loadingButton, loading, loadingReconnect, reconnectBank, showBankDetails, loadingBanks, banks, accountDetails, bankUrl, bankName, bankLastUpdated, relinkToken, bankAccessToken, loadTransactions } = this.state;
        const AccountDetailColumns = [
            {
                title: 'Title',
                dataIndex: 'title',
                align: 'left'
            },
            {
                title: 'Account Ending',
                dataIndex: 'mask',
                align: 'left'
            },
            {
                title: 'Available Balance',
                dataIndex: 'availableBalance',
                align: 'left',
                render: (availableBalance, record) => {
                    return (formatter.format(availableBalance ? availableBalance : record.currentBalance))
                },
            }
        ]
        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
        });

        return (
            <>
                <FlexColumn style={{ margin: '24px 0' }}>
                    <Text caption margin='0 0 16px'>LINKED BANK ACCOUNTS</Text>
                    <Skeleton loading={loadingBanks} active title={false} paragraph={{ rows: 2 }}>
                        <Flex start wrap="wrap" style={{ margin: '0 0 12px' }}>
                            {
                                banks && banks.length > 0 &&
                                banks.map(bank => {
                                    return <LightContainer padding='5px 0' margin='0 5px 5px 0' height='56px' noBorder='true'>
                                        <Flex style={{ height: '100%' }} start centerHorizontally>
                                            {
                                                (bank.logoUrl || bank.bankName) &&
                                                <Button type="primary" loading={loadingButton === bank.itemId} icon={<img style={{ marginRight: 8 }} height='24px' src={bank.logoUrl ? `${bank.logoUrl}` : BankIcon} />} text={bank.bankName} rightIcon={bank.status == "Connected" ? <></> : <ExclamationCircleFilled style={{ color: 'orange' }}/>} onClick={() => this.handleBankDetails(bank)}/>
                                            }
                                        </Flex>
                                    </LightContainer>
                                })

                                
                            }
                        </Flex>
                    </Skeleton>
                    
                    <PlaidLink
                        {...environment.plaid}
                        token={linkToken}
                        className='plaid-link-button'
                        selectAccount="false"
                        clientName="Aion"
                        isWebview="true"
                        apiVersion="v2"
                        onSuccess={(public_token, metadata) => this.getPlaidAccessToken(public_token, metadata, true, null)}
                        onExit={() => this.setState({ yodleeLink: true })}
                        onCancel={() => this.setState({ yodleeLink: true })}
                        style={{ background: 'transparent', paddingLeft: 0, paddingBottom: 0, fontSize: 18, cursor: 'pointer' }}
                        onEvent={this.logPlaidEvent()}
                    >
                        <TextButton text='ADD NEW' icon={<PlusOutlined />} />
                    </PlaidLink>

                    {/* {
                        yodleeLink &&
                        <YodleeIntegration store={store} businessId={this.props.store.businessId} businessUniqueKey={this.props.store.BusinessUniqueKey} openYodleeLink={yodleeLink} handleYodleeLink={this.handleYodleeLink} fetchBanks={this.fetchBanks} />
                    }
                     */}

                </FlexColumn>
                <Skeleton loading={loadingBanks} active>
                    <Modal
                        visible={showBankDetails}
                        footer={null}
                        onCancel={() => this.closeBankDetails()}
                        width={600}
                    >
                        <div style={{ textAlign: 'left' }}>
                            <Text heading caption margin='12px 12px 0 12px' >BANK DETAILS</Text>
                            {
                                <FlexColumn style={{ margin: '24px 0' }}>
                                <div style={{ textAlign: 'left', margin: '0 12px 12px 12px'  }}>
                                    <Text>
                                        <img style={{ marginRight: 8 }} height='35px' src={bankUrl ? bankUrl : BankIcon} />
                                        {bankName}
                                    </Text> 
                                </div>
                                <Flex start wrap="wrap" style={{ margin: '12px 12px 0px 12px' }}>
                                    <Info label='Status' value={reconnectBank ? <Badge status="error" text="DISCONNECTED" /> : <Badge status="success" text="CONNECTED" />} />    
                                </Flex>                                   
                                <Flex start wrap="wrap" style={{ margin: '12px 12px 0px 12px' }}>
                                    <Text heading caption margin='12px 12px 12px 0'>ACCOUNT DETAILS</Text>
                                    {
                                        accountDetails && accountDetails.length>0 && 
                                            <Table
                                                id="AccountDetailsTable"
                                                tableLayout='fixed'
                                                columns={AccountDetailColumns}
                                                dataSource={accountDetails}
                                                pagination={false}
                                                style={{ margin: '12px 0 12px 0' }}
                                            />
                                    }
                                    </Flex>
                                    <Flex fullWidth centerHorizontally start gap='12px' style={{ margin: '12px 12px 0 12px' }}>
                                    {
                                        reconnectBank && 
                                        <PlaidLink
                                            {...environment.plaid}
                                            token={relinkToken}
                                            className='plaid-link-button'
                                            selectAccount="false"
                                            clientName="Aion"
                                            isWebview="true"
                                            apiVersion="v2"
                                            onSuccess={(public_token, metadata) => this.getPlaidAccessToken(public_token, metadata, false, bankAccessToken)}
                                            onEvent={this.logPlaidEvent()}
                                        >
                                            <Button loading={loadingReconnect} solid type="primary" text='RECONNECT'/>
                                        </PlaidLink>
                                    }
                                    {/* {
                                        !reconnectBank &&
                                        <Button loading={loadTransactions} type="primary" text="DISCONNECT" onClick={() => this.removePlaidConnection()}/>
                                    } */}
                                    </Flex>
                                </FlexColumn>
                            }
                        </div>
                    </Modal>
                </Skeleton>
                

            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer,
        bankingStore: state.bankingAppReducer,
        creditStore: state.creditAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Index));

