import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { withTheme } from 'styled-components';
import _ from 'underscore';
import { Divider, Input, Collapse, Skeleton } from 'antd';
import { Text } from '../../Reusable/Text'

// Util
import { apiGET, apiPOST } from '../../../Utils/api'
import { toCurrency, getMultiples, toNumber } from '../../../Utils/util'

// Components
import PageHeader from "../../Reusable/PageHeader";
import { FlexColumn, Flex, InputContainer, Capsule, Container } from '../../Reusable/Container';
import { Title, Paragraph } from '../../Reusable/Text';
import { Button } from '../../Reusable/Refresh/Button';
import { ErrorAlert } from '../../Reusable/Alert'
import { LabeledInput } from '../../Reusable/Input';
import Clock from '../../../Images/clock.png';
import BoldBanner from '../../Reusable/BoldBanner'
import { Image } from '../../Reusable/Image'
import ExpressFunds from './ExpressFunds';

// Actions
import { CREDIT_SAVE_DATA, addDataToStore } from '../../../Actions/actions'

const { Panel } = Collapse
const { TextArea } = Input

class RequestFunds extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true, loading: false, showBanner: true }
    }

    componentDidMount() {
        this.fetchARData();
    }

    // Fetch data for AR
    fetchARData = () => {
        const headers = {
            businesskey: this.props.aionStore.BusinessUniqueKey
        }
        apiGET("/arfinancing/funds/view", headers, (err, resp) => {
            try {
                if (err) throw Error(err);
                var data = resp.data;
                console.log("arfinancing/funds/view", (data))

                if (data.success) {
                    this.props.dispatch(addDataToStore(CREDIT_SAVE_DATA, { AvailableFunds: data }));
                    this.setState({ ...data })
                    this.setState({ isLoading: false });
                } else {
                    throw Error("We had some trouble. Please try again later.")
                }
            } catch (error) {
                alert(error.message);
            }
        })
    }

    getFundingType = (fundsrequested) => {
        const headers = {
            businesskey: this.props.aionStore.BusinessUniqueKey
        }
        apiPOST(`/financing/funds/fundingtype?fundsrequested=${fundsrequested}`, headers, { fundsrequested: fundsrequested }, (err, resp) => {
            try {
                if (err) throw Error(err);
                var data = resp.data;
                console.log("financing/funds/fundingtype", (data))

                if (data.success && (data.ExpressFunding === true || data.StandardFunding === true)) {
                    this.setState({ isLoading: false, showExpressFundsModal: true, fundingData: data })
                } else {
                    throw Error("Sorry we had trouble processing your request. Please try again.")
                }
            } catch (error) {
                alert(error.message);
            }
            this.setState({ loading: false })
        })
    }

    handleAmtSelection = (amt) => {
        this.setState({ requestedAmt: amt });
    }

    handleSubmit = () => {
        const { store } = this.props;
        const { AvailableFunds, ARAdvanceInfo } = store;
        const { ExpressFundingBorrower } = this.state;
        const requestedAmt = toNumber(this.state.requestedAmt);

        console.log("AvailableFunds: ", AvailableFunds)

        try {
            if (!AvailableFunds.AvailableFunds || AvailableFunds.AvailableFunds <= 0) throw Error("You don't have any funds available.");
            if (requestedAmt > AvailableFunds.AvailableFunds) throw Error(`Please enter an amount less than $${toCurrency(AvailableFunds.AvailableFunds)}.`);
            if (requestedAmt <= 0) throw Error("Please enter a valid amount.");
            if (isNaN(requestedAmt)) throw Error("Please enter a valid amount.");
            if (!this.state.notes) throw Error("Please enter a note.");
        } catch (error) {
            ErrorAlert({ description: error.message })
            return
        }

        if (ExpressFundingBorrower === true) {
            this.setState({ loading: true })
            this.getFundingType(requestedAmt)
        } else {
            this.setState({ showExpressFundsModal: true })
        }

        // this.setState({ isLoading: true });
        // const headers = {
        //     businesskey: this.props.aionStore.BusinessUniqueKey
        // }
        // apiPOST('/financing/funds/request', headers, { fundsrequested: requestedAmt, notes: this.state.notes }, (err, resp) => {
        //     try {
        //         if (err) throw Error(err.response.data || "Sorry we had trouble processing your request. Please try again.");
        //         const data = resp.data;
        //         if (data.success) {
        //             message.success('Advance requested successfully. The funds will be available in your account in 1-4 business days.');
        //             this.setState({ requestedAmt: null })
        //             this.fetchARData();
        //             this.props.history.push('/credit/ar-credit/advances');
        //         } else {
        //             throw Error("Sorry we had trouble processing your request. Please try again.");
        //         }
        //     } catch (error) {
        //         this.setState({ isLoading: false });
        //         ErrorAlert({ description: error.message })
        //     }
        // });
    }

    render() {
        const { store, theme } = this.props;
        const { requestedAmt, ExpressFundingBorrower, fundingData, loading, notes, FeeRecipient, DrawFeesToBorrower, showBanner } = this.state;
        const { AvailableFunds, ARAdvanceInfo } = store;
        const availableFunds = AvailableFunds || {};
        // const availableAR = availableFunds.AvailableAR;
        const maxTags = 4;
        var multiples = getMultiples(availableFunds.AvailableFunds, maxTags);
        multiples = _.uniq(multiples);
        var amtTags = [];
        const tagStyle = { margin: '0 10px 0 0', padding: '0px 10px', height: '28px', borderRadius: '10px' };
        amtTags.push(<Capsule id={availableFunds.AvailableFunds} key={availableFunds.AvailableFunds} margin='0 10px 0 0' onClick={() => this.handleAmtSelection(availableFunds.AvailableFunds)} text='Full' />);
        multiples.forEach(val => {
            if (val < availableFunds.AvailableFunds) {
                amtTags.push(<Capsule id={val} key={val} margin='0 10px 0 0' onClick={() => this.handleAmtSelection(val)} text={`${toCurrency(val, 0)}`} />);
            }
        });
        console.log("AvailableFunds: ", AvailableFunds)

        console.log("ExpressFundingBorrower: ", ExpressFundingBorrower)

        return (
            <FlexColumn className='main-padding'>
                <PageHeader
                    titleText="Request Funds"
                    backText='Back'
                    back
                />

                <FlexColumn>
                    <Skeleton loading={this.state.isLoading} active title={false} paragraph={{ rows: 4 }}>
                        {
                            showBanner && <BoldBanner
                                icon={<Image src={Clock} />}
                                style={{ marginTop: "0", marginBottom: 24, width: "100%" }}
                                message="Funds will be available in 1 to 2 business days after the funding request is processed. Requests submitted after 6pm ET are processed the next business day."
                                onClose={() => {
                                    this.setState({ showBanner: false })
                                }}
                            />
                        }
                        <Flex style={{ justifyContent: "start" }} center>
                            <InputContainer style={{ margin: "20px 0" }}>
                                <Paragraph color={theme.colors.caption} noMargin>Credit Limit</Paragraph>
                                <Title style={{ fontWeight: 600 }} level={5} noMargin>{`$${toCurrency(availableFunds.BorrowerAdvanceLimit)}`}</Title>
                            </InputContainer>
                            <Divider type="vertical" style={{ margin: "0 30px", height: "100px" }} />
                            <InputContainer style={{ margin: "20px 0" }}>
                                <Paragraph color={theme.colors.caption} noMargin>Outstanding Balance</Paragraph>
                                <Title style={{ fontWeight: 600 }} level={5} noMargin>{`$${toCurrency(availableFunds.BorrowerAdvanceTaken)}`}</Title>
                            </InputContainer>
                        </Flex>
                    </Skeleton>
                </FlexColumn>

                <Container shadow style={{ marginBottom: 48, marginTop: 24 }}>
                    <FlexColumn style={{ alignItems: "flex-start" }}>
                        <Flex center>
                            <FlexColumn>
                                <Paragraph bold style={{ color: theme.colors.secondary3, margin: '0px 0' }} level={3}>Available Funds</Paragraph>
                                <Title bold style={{ margin: '0px 0' }} level={3}>{availableFunds.AvailableFunds ? `$${toCurrency(availableFunds.AvailableFunds)}` : '--'}
                                </Title>
                            </FlexColumn>
                            <Divider type="vertical" style={{ height: "90px", margin: "0 30px" }} />
                            <FlexColumn style={{ alignItems: "flex-start" }}>
                                <Paragraph color={theme.colors.systemGray} style={{ maxWidth: "500px" }} noMargin>The available funds is calculated based on your advanceable collateral, customer limits and the outstanding balance.</Paragraph>
                            </FlexColumn>

                        </Flex>
                        <Divider />
                        <div style={{ width: "600px" }}>
                            {/* <Title level={6}>How much would you like to advance?</Title> */}
                            <LabeledInput prefix='$' label="How much would you like to advance?" onChange={(e) => { this.setState({ requestedAmt: e.target.value }) }} size="large" placeholder="Enter amount" value={this.state.requestedAmt || null} />
                            <Flex start wrap style={{ margin: '20px 0' }}>
                                {amtTags}
                            </Flex>
                            <LabeledInput type="text-area" rows={3} onChange={(e) => { this.setState({ notes: e.target.value }) }} size="large" placeholder="Enter purpose (E.g. Payroll, Operating Expenses, etc.)" value={this.state.notes || null} label="Briefly describe purpose of your funding request" />
                            <FlexColumn start gap='12px' style={{ margin: "12px 0", backgroundColor: theme.colors.backgroundBanner, padding: "12px 24px", borderRadius: "8px" }}>
                                <Text lightText>The daily cut-off time for funding requests is 6:00 PM ET. Requests submitted after this time are processed the next business day.</Text>
                                {
                                    FeeRecipient && FeeRecipient.FeeType == "Percentage" && <Text lightText>A {FeeRecipient.FeePercentage}% draw fee will be deducted from your draw amount and the net amount will be deposited in your Aion account.</Text>
                                }
                                {
                                    FeeRecipient && FeeRecipient.FeeType == "Flat Fee" && <Text lightText>A ${toCurrency(FeeRecipient.FlatFee)} draw fee will be deducted from your draw amount and the net amount will be deposited in your Aion account.</Text>
                                }
                            </FlexColumn>
                            {/* <Popconfirm
                            title={`We'll be initiating an advance for $ ${toCurrency(this.state.requestedAmt)}.`}
                            onConfirm={this.handleSubmit}
                            onCancel={this.cancel}
                            okText="Confirm"
                            cancelText="Cancel"
                        >
                            <Button loading={this.state.isLoading} type="primary" size="large" style={{ margin: '30px 0', fontWeight: '500', fontSize: '18px' }}>Submit</Button>
                        </Popconfirm> */}

                            <Flex start style={{ width: '100%', marginTop: 48 }}>
                                {/* <FlexColumn center> */}
                                {/* <Popconfirm
                                    title={`We'll be initiating an advance for $${toCurrency(this.state.requestedAmt)}.`}
                                    onConfirm={this.handleSubmit}
                                    onCancel={this.cancel}
                                    okText="Confirm"
                                    cancelText="Cancel"
                                > */}
                                <Button solid loading={loading} onClick={this.handleSubmit} style={{ width: 120 }} noMargin permtype="ARCredit.Advances" text='Submit' />
                                {/* </Popconfirm> */}
                                {/* <span style={{ marginTop: 4 }}>No fees, 1-3 business days</span> */}
                                {/* </FlexColumn> */}

                                {/* {
                                ExpressFundingBorrower &&
                                <FlexColumn center>
                                    <Button background="#60C0F0" noMargin permtype="ARCredit.Advances" height="50px" onClick={() => this.setState({ showExpressFundsModal: true })}>Express Funding</Button>
                                    <span style={{ marginTop: 4 }}>Flat fees, same day</span>
                                </FlexColumn>
                            } */}
                            </Flex>
                        </div>

                    </FlexColumn>
                </Container>

                <ExpressFunds
                    visible={this.state.showExpressFundsModal}
                    onSubmit={() => {
                        this.setState({ showExpressFundsModal: false })
                        this.fetchARData()
                        window.history.back()
                    }}
                    advanceAmount={requestedAmt}
                    fundingData={fundingData}
                    drawFeesToBorrower={DrawFeesToBorrower}
                    feeRecipient={FeeRecipient}
                    availableFunds={availableFunds}
                    notes={notes}
                    onCancel={(loading) => {
                        if (!loading) this.setState({ showExpressFundsModal: false })
                    }}
                />
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.creditAppReducer,
        aionStore: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(RequestFunds)))