import React, { useState } from 'react';
import { Text } from '../../Reusable/Text';
import { Image } from '../../Reusable/Image'
import { Flex } from '../../Reusable/Container';
import AICPA from '../../../Images/AiCPA.png'


const MerchantDetails = ({ customer, width }) => {
    var { displayName, phone, primaryEmailAddress } = customer || {}

    return (
        <Flex gap='16px' start centerHorizontally>
            <Image src={AICPA} height='40px' width='40px' />
            <Text size='12px' color='#4F4F78'>Aion protects your data with commercial grade SOC 2 Type II compliance in accordance with AICPA</Text>
        </Flex>
    );
};

export default MerchantDetails;
