import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled, { withTheme } from 'styled-components'
import { addDataToStore, REMOTE_SAVE_DATA } from '../../../Actions/actions'
import { Button } from '../../Reusable/Button'

import { Flex, FlexColumn } from '../../Reusable/Container'
import { Divider } from '../../Reusable/Divider'
import { Image } from '../../Reusable/Image'
import FlowViewComponent from '../../Reusable/Refresh/FlowViewComponent'
import { Text } from '../../Reusable/Text'
import ChkListIcon from '../../../Images/checklist-icon.png'
import ChkIcon from '../../../Images/check-secondary-icon.png'

const CheckListContainer = styled.div`
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 48px;
    gap: 24px;
    isolation: isolate;

    width: 456px;
    height: 100%;

    background: linear-gradient(343.86deg, #F5F6FE 0.62%, #FFFFFF 25.81%);
    /* Solid/Black/0 */

    border: 1px solid #F2F2F2;
    box-shadow: 0px 4px 8px rgba(102, 102, 102, 0.08);
    border-radius: 8px;

    /* Inside auto layout */
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
`

class UserConsent extends Component {

    state = {}

    render() {
        var { flowView, currentViewIndex, loadPrevView } = this.props
        var chkListItems = [
            "Government identification documents for you, beneficial owners, controlling party and authorized signers (i.e.  Drivers License, Passport)",
            "Business Federal EIN document",
            "Government-issued documentation certifying the existence and operations of the business (e.g. Operating agreement, Certificate of Incorporation or Formation, issued by Secretary of State)"
        ]
        var childViews = (
            <FlexColumn left fullHeight>
                <Flex fullHeight gap="48px">
                    <FlexColumn>
                        <Text heading>US Patriot Act</Text>
                        <Text style={{ maxWidth: '600px' }}>
                            To help the government fight the funding of terrorism and money laundering activities, federal law requires all financial institutions to obtain, verify, and record information that identifies each person who opens an account.
                            <br/>
                            <br/>
                            We will ask for your name, address date of birth (for individuals), and other information that will allow us to identify you. We may also ask to see your driver’s license (for individuals) or other identifying documents.
                        </Text>
                        <Divider />
                        <Text heading>Company Administrator</Text>
                        <Text style={{ maxWidth: '600px' }}>
                            You represent that you have the authority to open bank accounts and conduct business on behalf of the entity.
                            <br/>
                            <br/>
                            By starting this application, you acknowledge all of the above information.
                        </Text>
                    </FlexColumn>
                    <CheckListContainer>
                        <Flex start center gap="8px">
                            <Image src={ChkListIcon} />
                            <Text large weight={400}>Helpful Information</Text>
                        </Flex>
                        <Text>Make sure you have the following documents to have an easy onboarding experience</Text>
                        {
                            chkListItems.map(li => (
                                <Flex start gap="8px">
                                    <Image src={ChkIcon} />
                                    <Text>{li}</Text>
                                </Flex>
                            ))
                        }
                    </CheckListContainer>
                </Flex>
                
                <Button style={{ marginTop: '24px' }} solid text="BEGIN APPLICATION" onClick={() => {
                    this.props.dispatch(addDataToStore(REMOTE_SAVE_DATA, flowView.FlowStep))
                    this.props.loadNext()
                }} />
            </FlexColumn>
        )
        return (
            <FlowViewComponent
                flowView={flowView}
                currentViewIndex={currentViewIndex}
                loadPrev={loadPrevView}
                childViews={childViews}
                padding='0 64px'
                {...this.props}
                loadNext={this.validateFlow}
                hideSteps={true}
            />
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapDispatchToProps)(withTheme(UserConsent))