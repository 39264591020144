import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { withTheme } from 'styled-components'
import ordinal from 'ordinal'

import {
    Checkbox, Select, Input, Modal,
    Upload,
    message
} from 'antd'
import { FlexColumn, Flex } from '../../../Reusable/Container'
import { Text } from '../../../Reusable/Text'
import { LabeledInput } from '../../../Reusable/Input'
import { Button } from '../../../Reusable/Button'
import { ErrorAlert } from '../../../Reusable/Alert'
import { apiPOSTReq } from '../../../../Utils/api'
import environment from '../../../../environment'
import { PlusOutlined, FilePdfOutlined, CloseOutlined } from '@ant-design/icons'
import { TextButton } from '../../../Reusable/Refresh/Button'

const { Option } = Select
const { TextArea } = Input

class PreScreenReview extends Component {
    state = {
        deniedList: false,
        fileList: [],
        submitDisabled: false,
        uploading: false,
        loading: false,
    }
    constructor(props) {
        super(props)
    }

    handleSelect = (value, option) => {
        this.setState({ [option.id]: value })
    }

    handleSubmitReview = () => {
        const { loanApp } = this.props
        const { UserInfo } = this.props.store
        var { deniedList, review, notes, fileList } = this.state
        fileList = fileList || []

        var body = {
            clientBusinessId: loanApp.businessId,
            applicationId: loanApp.applicationId,
            verificationStatus: review,
            reviewer: `${UserInfo.FirstName} ${UserInfo.LastName}`,
            reviewerEmail: `${UserInfo.Email}`,
            reviewerNote: notes,
            deniedList: deniedList,
            attachmentUrls: fileList.map(x => { return { fileName: x.fileName, url: x.uri } })
        }

        if (review == null) {
            ErrorAlert({ description: "Please select a review outcome." })
            return
        }

        this.setState({ loadingSubmitReview: true })
        console.log("body /ops/loans/application/submitReview", body)

        apiPOSTReq(`${environment.opsBaseUrl}/ops/loans/application/submitReview`, null, body, (err, resp) => {
            try {
                const data = resp

                console.log("Review /ops/loans/application/submitReview", JSON.stringify(data))

                if (data.result) {
                    if (err) throw new Error(err)

                    if (review == "Manually Rejected") {
                        //Email Triggered to User confirmation
                        var userNotificationBody = {
                            "fromAddr": "Aion <credit@aionfi.com>",
                            "toAddr": loanApp.emailAddress,
                            "ccAddr": process.env.REACT_APP_ENV != "production" ? "stagingmoderators@mg.aionfi.com" : "",
                            "bccAddr": process.env.REACT_APP_ENV != "production" ? "akumar@aionfi.com" : "",
                            "emailSubject": `Your Application for Financing`
                        }

                        if (deniedList) {
                            userNotificationBody["emailBody"] = `<p>Hi ${loanApp.applicantFirstName},<p>Thank you for your interest in Aion Capital.</p><p>We have carefully assessed your information and, at this time, we are unable to approve your business for financing.</p></p>`
                        }
                        else {
                            userNotificationBody["emailBody"] = `<p>Hi ${loanApp.applicantFirstName},<p>Thank you for your interest in Aion Capital.</p><p>We have carefully assessed your information and, at this time, we are unable to approve your request for business financing.</p><p>We would still love to serve your business with our all-in-one banking platform that launches soon! To join the waitlist, visit aionfi.com/waitlist.</p><p>If you have any questions, please reach out to hello@aionfi.com.</p></p>`
                        }

                        apiPOSTReq(`${environment.opsBaseUrl}/ops/sendEmail`, null, userNotificationBody, (err, resp) => {
                            console.log("sendEmail", err, resp, userNotificationBody)
                        })
                    }

                    this.props.handleClosePreScreenReview()
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getAccounts", error, err, resp)
            }
        })

    }

    handleCloseReview = () => {
        this.props.handleClosePreScreenReview()
    }
    handleDeleteDoc = (uploadItem) => {
        var { loanApplication } = this.state
        var businessDocument = {
            "category": "PreScreen",
            "name": uploadItem.fileName
        }

        if (loanApplication) businessDocument.applicationId = loanApplication.applicationId
        var body = {
            "businessDocument": businessDocument,
            "documentId": uploadItem.documentId
        }

        console.log("handleSubmit DOC/delete uploadItem body", uploadItem, body)
        this.setState({ submitLoading: true })
        apiPOSTReq(`${environment.uamBaseUrl}/docs/delete`, {}, body, (err, resp) => {
            this.setState({ submitLoading: false, loadingItem: null })
            try {
                const data = resp || {}
                if (data.result) {
                    message.success(`File Deleted successfully.`, 0.75)
                    let removedList = this.state.fileList.filter(item => {
                        if (item.fileName !== uploadItem.fileName) return item
                    })
                    this.setState({ fileList: removedList })
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/docs/deleteUpload err", error, resp)
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    render() {
        const { theme, loanApp } = this.props
        const { deniedList, review, notes, loadingSubmitReview, fileList, submitDisabled, uploading } = this.state
        var uploadProps = {
            action: `${environment.apiBaseUrl}/file/upload`,
            headers: {
                AionCurrentBiz: this.props.store.BusinessUniqueKey,
                AionAuth: this.props.store.UAM.encryptedAuthHeader,
                businesskey: this.props.store.BusinessUniqueKey,
                jwt: this.state.jwt,
                username: "",
            },
            showUploadList: false,
            beforeUpload: () => {
                this.setState({ uploading: true })
            },
            onChange: (info) => {
                var savedFileList = this.state.fileList
                var file = info.file
                console.log("info file", file)
                var { status, response, name, url } = file
                if (status === "done") {
                    response = response || {}
                    savedFileList.push({
                        fileName: name,
                        uri: (response.UploadedUrls || response.uploadedUrls || [])[0],
                        file: this.props.includebase64 ? file : null
                    })
                    this.setState({ uploading: false })
                }
                this.setState({ fileList: savedFileList.map(item => ({ ...item, url: item.uri })) })
            }
        }

        return (
            <FlexColumn style={{ margin: '0 10px', minHeight: "60vh", justifyContent: "space-between" }}>
                <FlexColumn style={{ justifyContent: "flex-start" }}>
                    <Text heading>Manual Review for {loanApp.businessName}</Text>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <LabeledInput
                            label="Outcome of this review"
                            labelcolor={theme.colors.secondary3}
                            type="select"
                            className="no-left-padding"
                            value={review}
                            placeholder="Select a outcome"
                            style={{ marginRight: 10, fontSize: "1.0rem", paddingLeft: 0, marginLeft: 0, marginBottom: 10 }}
                            onChange={this.handleSelect}
                        >
                            {
                                loanApp.underwritingStatus == "Pre Review" && <Option id="review" value="Manual Review">Manual Review</Option>
                            }
                            {
                                loanApp.underwritingStatus == "Pre Review" && <Option id="review" value="Proposal Issued">Proposal Issued</Option>
                            }
                            {
                                loanApp.underwritingStatus == "Pre Review" && <Option id="review" value="Proposal Ready">Proposal Ready</Option>
                            }
                            {
                                loanApp.underwritingStatus == "Pre Review" && <Option id="review" value="Auto Rejected">Auto Rejected</Option>
                            }
                            {
                                loanApp.underwritingStatus == "Pre Review" && <Option id="review" value="Not Started">Not Started</Option>
                            }
                            {
                                loanApp.underwritingStatus != "Pre Review" && <Option id="review" value="Approve">Approve</Option>
                            }
                            <Option id="review" value="Manually Rejected">Reject</Option>

                        </LabeledInput>
                        {review == "Manually Rejected" &&
                            <Checkbox style={{ marginBottom: 10 }} value={deniedList} onChange={(e) => this.setState({ deniedList: e.target.checked })}>
                                Add this entity to denied list
                            </Checkbox>}
                        <Text color={theme.colors.secondary3}>Note on this review</Text>
                        <TextArea rows={3} style={{ marginBottom: 10 }} onChange={(e) => { this.setState({ notes: e.target.value }) }} size="large" placeholder="Enter note" value={notes || null} />

                        <Flex style={{ flexDirection: "column" }}>
                            <Upload
                                {...uploadProps}
                            >
                                <TextButton loading={this.state.uploading} text={"Add Attachments"} color="#40a9ff" icon={<PlusOutlined style={{ fontSize: 14, width: 24, height: 24, margin: '0 12px', color: "#40a9ff" }} />} />
                            </Upload>
                            <div style={{
                                maxWidth: "526px"
                            }}>
                                <Flex wrap start style={{ flexWrap: "wrap", padding: "4px", marginTop: fileList && fileList.length > 0 ? 8 : 0, gap: 12 }}>
                                    {
                                        fileList && fileList.map((file, i) => {
                                            return (
                                                <TextButton
                                                    noBorder height='32px' padding='4px 8px' radius='4px' background='#F8F8F8' key={i} color="#40a9ff"
                                                    icon={<FilePdfOutlined style={{ fontSize: 14, color: "#40a9ff" }} />} rightIcon={<CloseOutlined style={{ color: "#40a9ff", fontSize: 14 }} onClick={() => this.handleDeleteDoc(file)} />} text={file.fileName} />
                                            )
                                        })
                                    }
                                </Flex>
                            </div>

                        </Flex>
                    </div>
                </FlexColumn>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button type="secondary" style={{ alignSelf: 'center', width: '45%', margin: '0 10px' }} onClick={this.handleCloseReview} text="Close" />
                    <Button type="primary" solid style={{ alignSelf: 'center', width: '45%', margin: '0 10px' }} disabled={submitDisabled} loading={loadingSubmitReview} onClick={this.handleSubmitReview} text="Submit" />
                </div>
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer,
        bankingStore: state.bankingAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(PreScreenReview)))