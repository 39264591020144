import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter, Route, Switch } from 'react-router-dom'
import { withTheme } from 'styled-components'

import Home from './Home/Index'
import AccountTransactions from './Transactions/AccountTransactions'
import Recipients from './Recipients/Index'
import RecipientDetails from './Recipients/RecipientDetails'
import NewRecipient from './Recipients/NewRecipient'
import DebitCard from './Debit Card/Index'
import UserCards from './Debit Card/UserCards'
import CompanyCards from '../Settings/Company Profile/CompanyCards'
import ApprovalRules from './Approval Rules/Index'
import TransferToRecipient from './Transfer to Recipient/Index'
import InternalTransfer from './Internal Transfer/Index'
import OneTimeTransfer from './One Time Transfer/Index'
import Accounts from './Accounts/Index'
import MoreTransactions from './Accounts/MoreTransactions'
import IssueCard from './Debit Card/IssueCard'
import ACHActivity from './Transfers/ACHActivity'
import WireActivity from './Transfers/WireActivity'
import CheckActivity from './Transfers/CheckActivity'
import CardActivity from './Home/CardActivity'
import InternalActivity from './Transfers/InternalActivity'
import Transfers from './Transfers/Index'

class Index extends Component {

    render() {
        let { location } = this.props
        return (
            <>
                <Switch location={location}>
                    <Route exact
                        path="/banking/home"
                        render={(props) => (
                            <Home {...props} {...this.props} />
                        )}
                    />
                    <Route exact path="/banking/account/transactions" component={AccountTransactions} />
                    <Route exact path="/banking/recipients" component={Recipients} />
                    <Route exact path="/banking/recipients/details" component={RecipientDetails} />
                    <Route exact
                        path="/banking/recipients/new"
                        render={(props) => (
                            <NewRecipient {...props} {...this.props} />
                        )}
                    />
                    <Route exact path="/banking/recipients/edit" component={NewRecipient} />
                    <Route exact path="/banking/debit-card" component={DebitCard} />
                    <Route exact path="/cards/issue-card" component={IssueCard} />
                    <Route exact path="/banking/cards" component={DebitCard} />
                    <Route exact path="/cards/my-cards" component={UserCards} />
                    <Route exact path="/cards/all" component={CompanyCards} />
                    <Route exact path="/cards/view" component={DebitCard} />
                    <Route exact path="/banking/controls" component={ApprovalRules} />
                    <Route exact
                        path="/banking/transfer-to-recipient"
                        render={(props) => (
                            <TransferToRecipient {...props} {...this.props} />
                        )}
                    />
                    <Route exact path="/banking/internal-transfer" component={InternalTransfer} />
                    <Route exact path="/banking/one-time-transfer" component={OneTimeTransfer} />
                    <Route exact path="/banking/accounts" component={Accounts} />
                    <Route exact path="/banking/accounts/rejected-transactions" component={MoreTransactions} />
                    <Route exact path="/banking/accounts/canceled-transactions" component={MoreTransactions} />
                    <Route exact path="/banking/accounts/errored-transactions" component={MoreTransactions} />
                    <Route exact path="/banking/wire-transfers" component={WireActivity} />
                    <Route exact path="/banking/ach-transfers" component={ACHActivity} />
                    <Route exact path="/banking/check-transfers" component={CheckActivity} />
                    <Route exact path="/banking/card-transfers" component={CardActivity} />
                    <Route exact path="/banking/internal-transfers" component={InternalActivity} />
                    <Route exact
                        path="/banking/instant-transfers/transfer-to-recipient"
                        render={(props) => (
                            <TransferToRecipient {...props} {...this.props} />
                        )}
                    />
                    <Route exact
                        path="/banking/ach-transfers/transfer-to-recipient"
                        render={(props) => (
                            <TransferToRecipient {...props} {...this.props} />
                        )}
                    />
                    <Route exact
                        path="/banking/wire-transfers/transfer-to-recipient"
                        render={(props) => (
                            <TransferToRecipient {...props} {...this.props} />
                        )}
                    />
                    <Route exact path="/banking/internal-transfers/internal-transfer" component={InternalTransfer} />
                    <Route exact path="/banking/transfers" component={Transfers} />
                    {/* <Route exact
                        path="/banking/approvals"
                        render={(props) => (
                            <Approvals {...props} {...this.props} />
                        )}
                    /> */}
                </Switch>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)))