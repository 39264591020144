import React, { Component } from 'react'
import { withTheme } from 'styled-components'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { WarningFilled } from '@ant-design/icons'

// Components
import AdvancesTable from './AdvancesTable'
import LimitsBalances from '../LimitsBalances'
import { ErrorAlert } from '../../Reusable/Alert'
import PageHeader from "../../Reusable/PageHeader"
import { FlexColumn, Container } from '../../Reusable/Container'
import { Button } from '../../Reusable/Button'
import Banner from '../../Reusable/Banner'

import Clock from '../../../Images/clock.png';
import BoldBanner from '../../Reusable/BoldBanner'
import { Image } from '../../Reusable/Image'

// Util
import { apiGET, apiPOSTReq } from '../../../Utils/api'
import { getUserApps } from '../../../Utils/util'

// Actions
import { CREDIT_SAVE_DATA, addDataToStore } from '../../../Actions/actions'

import environment from '../../../environment'
import moment from 'moment'

export const DescItem = styled.div`
    display: flex;
    justify-content: flex-end;
`

export const DescLabel = styled.span`
    fontWeight: 500;
    fontSize: 16;
`


class AdvancesIndex extends Component {

    constructor(props) {
        super(props)

        const userApps = getUserApps(this.props.aionStore)

        this.state = {
            advancesLoaded: false,
            lineFreeze: false,
            ifFeesAreToBeDrawn: false,
            showBanner: false,
            pagination: {
                current: 1,
                pageSize: 100,
            },
        }
    }

    componentDidMount() {
        // this.fetchAdvances();    
        this.fetchFundsData();
        this.fetchData({ fromDate: moment().startOf('year').format('YYYY-MM-DD'), toDate: moment().format('YYYY-MM-DD') });
    }

    fetchAdvances() {
        const headers = {
            businesskey: this.props.aionStore.BusinessUniqueKey
        }

        apiGET("/financing/advances/view", headers, (err, resp) => {
            try {
                const data = resp.data
                console.log("/financing/advances/view", JSON.stringify(data))
                this.props.dispatch(addDataToStore(CREDIT_SAVE_DATA, data))
                var initiatedLoans = data.ARAdvances.filter(x => x.LoanStatus == "Initiated");
                this.setState({ advancesLoaded: true, showBanner: (initiatedLoans || []).length > 0 });
            } catch (error) {
                console.log("ERRR", error.stack)
            }
        })
    }

    // Fetch data for AR
    fetchFundsData = () => {
        const userApps = getUserApps(this.props.aionStore)

        const headers = {
            businesskey: this.props.aionStore.BusinessUniqueKey
        }

        var endpoint = "/inventoryfinancing/funds/view"

        if (userApps.includes("ABLCredit")) endpoint = '/assetbasedloan/funds/view'

        apiGET(endpoint, headers, (err, resp) => {
            try {
                if (err) throw Error(err)
                const data = resp.data
                console.log(endpoint, data)
                if (data.success) {
                    var overadvanced = data.OverAdvanced || data.InventoryUploadDue || data.ARAgingUploadDue
                    var ifFeesAreToBeDrawn = data.DrawFeesToBorrower;
                    this.setState({ fundsLoaded: true, AvailableFunds: data, overadvanced: overadvanced, ifFeesAreToBeDrawn: ifFeesAreToBeDrawn })
                    this.fetchAdvances();
                    this.fetchBusinessAttributes(this.props.aionStore.BusinessUniqueKey)
                } else {
                    throw Error("We had some trouble. Please try again later.")
                }
            } catch (error) {
                ErrorAlert({ description: error.message })
                this.setState({ fundsLoaded: true })
            }
        })
    }

    fetchBusinessAttributes = (businessId) => {
        var body = {
            "clientBusinessId": businessId
        }

        this.setState({ attributesLoading: true })
        apiPOSTReq(`${environment.opsBaseUrl}/ops/fetchBusinessAttributes`, null, body, (err, resp) => {
            this.setState({ attributesLoading: false })
            try {
                const data = resp || {}
                if (data.businessAttributes) {
                    var businessAttributes = data.businessAttributes
                    var freezeCreditLine = businessAttributes.filter(
                        item => {
                            return (item.name == "FreezeCreditLine" && moment(item.effectiveEndDate).toDate() >= moment().toDate())
                        }
                    )

                    if (freezeCreditLine.length > 0) {
                        var { lineFreeze } = this.state
                        lineFreeze = freezeCreditLine[0].value == "true" ? true : false
                        this.setState({
                            lineFreeze: lineFreeze
                        })
                    }
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                // ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    requestFunds = () => {
        const { AvailableFunds } = this.state
        const userApps = getUserApps(this.props.aionStore)

        if (AvailableFunds && AvailableFunds.AvailableFunds > 0) {
            if (userApps.includes("ABLCredit")) {
                this.props.history.push('/credit/abl-credit/advances/request')
            } else {
                this.props.history.push('/credit/inventory-loc/advances/request')
            }
        } else {
            ErrorAlert({ description: "You don't have any funds available." })
        }
    }

    fetchData(options) {
        let { fromDate, toDate, pagination } = options;

        let fetchOptions = {
            sortFieldDirection: 'DESC',
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            size: this.state.pagination.pageSize,
            page: this.state.pagination.current - 1,
            download: false,
        }
        if (pagination) {
            fetchOptions.size = pagination.pageSize;
            fetchOptions.page = pagination.current - 1;
        }

        if (fromDate) {
            fetchOptions.fromDate = fromDate;
            fetchOptions.toDate = toDate;
        }
        this.setState({ loadingReport: true })

        if (!pagination) {
            pagination = this.state.pagination;
        }
        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/getDailyReport`, {}, fetchOptions, (err, resp) => {
            try {
                const data = resp || {};
                if (data) {
                    this.setState({
                        ...options,
                        loadingReport: false,
                        dailyReportItemList: data.dailyReportItemList,
                        pagination: {
                            ...pagination,
                            total: data.count,
                        }
                    })
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/counterparty err", error, resp);
                // ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    render() {
        var { fundsLoaded, AvailableFunds, grossAdvanceableExpanded, grossAdvanceableExpandable, lineFreeze, overadvanced, showBanner, dailyReportItemList } = this.state
        const { theme, aionStore } = this.props
        const userApps = getUserApps(this.props.aionStore)
        const { UAM } = aionStore


        const Attributes = this.props.aionStore.Attributes || {}
        const businessAttributes = Attributes.Businesses || {}
        const inventoryLOC = businessAttributes.InventoryLOCEnabled

        dailyReportItemList = dailyReportItemList || [];
        var outstandingInterest = null;
        if (dailyReportItemList.length > 0) {
            var dailyReport = dailyReportItemList[0];
            outstandingInterest = dailyReport.outstandingInterestToDate;
        }

        return (
            <FlexColumn className='main-padding'>
                <PageHeader
                    titleText="Credit"
                    subtitle={inventoryLOC === true ? 'Inventory line of credit' : "ABL Credit"}
                    ctaContent={
                        <div><Button disabled={lineFreeze || overadvanced || UAM.aionCustomerSupportUser || UAM.aionUnderWriterUser} loading={!fundsLoaded} permtype={userApps.includes("ABLCredit") ? "ABLCredit.ABL Advances" : "ARCredit.Inventory Advances"} solid onClick={() => this.requestFunds()} text='REQUEST FUNDS' /></div>
                    }
                />
                {
                    showBanner && <BoldBanner
                        icon={<Image src={Clock} />}
                        style={{ marginTop: "0", marginBottom: 24, width: "100%" }}
                        message="Funds will be available in 1 to 2 business days after the funding request is processed. Requests submitted after 6pm ET are processed the next business day."
                        onClose={() => {
                            this.setState({ showBanner: false })
                        }}
                    />
                }
                {
                    AvailableFunds && AvailableFunds.OverAdvanced === true &&
                    <Banner
                        style={{ marginBottom: 10 }}
                        title="Inactive credit line"
                        message="Please note that you are over-advanced on your line of credit. The line may be reactivated once you have provided additional collateral or payments on the outstanding balance are received."
                    />
                }

                {
                    AvailableFunds && AvailableFunds.InventoryUploadDue === true &&
                    <Banner
                        style={{ marginBottom: 10 }}
                        title="Inactive credit line"
                        message="Please note that your credit line is inactive as you are past due on your inventory update. Upload your latest inventory to reactivate your line."
                    />
                }

                {
                    AvailableFunds && AvailableFunds.ARAgingUploadDue === true &&
                    <Banner
                        style={{ marginBottom: 10 }}
                        title="Inactive credit line"
                        message="Please note that your credit line is inactive as you are past due on your AR Aging report upload. Upload your latest report to reactivate your line."
                    />
                }
                <FlexColumn style={{ width: "100%" }}>
                    {this.props.store.ARAdvanceInfo ? <LimitsBalances abl advancesLoaded={fundsLoaded} data={this.props.store.ARAdvanceInfo} availableFunds={AvailableFunds || {}} type="Advances" dailyReport={dailyReport} /> : null}
                </FlexColumn>
                <AdvancesTable
                    ifFeesAreToBeDrawn={this.state.ifFeesAreToBeDrawn}
                    advancesLoaded={this.state.advancesLoaded}
                    data={this.props.store.ARAdvances}
                    theme={this.props.theme}
                    ctaContent={
                        <div><Button disabled={lineFreeze || overadvanced || UAM.aionCustomerSupportUser || UAM.aionUnderWriterUser} loading={!fundsLoaded} permtype={userApps.includes("ABLCredit") ? "ABLCredit.ABL Advances" : "ARCredit.Inventory Advances"} solid onClick={() => this.requestFunds()} text='REQUEST FUNDS' /></div>
                    }
                />
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.creditAppReducer,
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(AdvancesIndex)))