import hexToRgba from "hex-to-rgba"

export const lightTheme = {
	type: 'light',
	body: '#FFFFFF',
	text: '#000',
	toggleBorder: '#FFF',
	colors: {
		// NEW BRAND BOOK - 1/9/24
		primary: '#1199FF',
		primary2: '#030342',
		primary3: '#8740F6',
		primary4: '#6BECF6',
		primary5: '#FFFFFF',
		primary6: '#FF6B6B', // Orange
		primary7: '#E63946', // Red
		primary8: '#FFD166', // Yellow
		primary9: '#52B788', // Green
		primary10: '#A8A391', // Gray


		// =======================

		secondary: '#030342',
		secondary1: '#8740F6',
		secondary2: '#32C2F5',
		secondary3: '#374FEA',
		secondary4: '#5663E9',
		secondary5: '#DEE4EF',
		secondary6: '#7384AA',
		secondary7: '#3C508A',
		secondary8: '#041858',
		systemGray: 'rgb(142, 142, 147)',
		systemGray2: 'rgb(174, 174, 178)',
		systemGray3: 'rgb(199, 199, 204)',
		systemGray4: 'rgb(209, 209, 214)',
		systemGray5: 'rgb(229, 229, 234)',
		systemGray6: 'rgb(242, 242, 247)',
		creditGreen: '#318F2F',
		warning: '#FFCC00',
		defaultText: '#333333',
		defaultLightText: '#7A7C9B',
		lightGrey: '#999999',
		error: '#FF0000',
		// Greys
		blackPrimary: '#444444',
		greySecondary : '#7384AA',
		greyQuaternery: '#F4F5F7',

		// Reds
		redDeep: '#E62D2D',
		redPale: '#FDEAEA',

		// Greens
		greenDeep: '#318F2F',
		greenPale: '#F5F9F5',

		// Text
		heading: '#333333',
		caption: '#7384AA',

		// Background
		backgroundPrimary: '#F6F6F6',
		backgroundDefault: hexToRgba('#030342', 0.05),
		backgroundBanner: '#FBFBFB',
		placeholder: '#BFBFBF',
		divider: '#DCDCDC',
		container: '#FFFFFF',
		border: '#D8D8D8',

		chart: [
			'#1E90FF', '#32CD32', '#FF69B4', '#FFA500', '#9370DB',
			'#20B2AA', '#FF6347', '#4169E1', '#DA70D6', '#3CB371'
		]
	},
	button: {
		primary: '#1199FF',
		navSelected: hexToRgba('#030342', 0.85),
		// Primary Background color
		primaryBg1: '#1199FF',
		primaryBg2: '#8740F6',
		primaryBg3: '#6BECF6',

		// Primary Text color
		primaryTxt1: '#FFFFFF',
		primaryTxt2: '#FFFFFF',
		primaryTxt3: '#030342',

		// Primary Border color
		primaryBdr1: '#1199FF',
		primaryBdr2: '#8740F6',
		primaryBdr3: '#6BECF6',

		// Secondary Background color
		secondaryBg1: '#FFFFFF',
		secondaryBg2: '#FFFFFF',
		secondaryBg3: '#FFFFFF',

		// Secondary Text color
		secondaryTxt1: '#1199FF',
		secondaryTxt2: '#030342',
		secondaryTxt3: '#52B788',

		// Secondary Border color
		secondaryBdr1: '#1199FF',
		secondaryBdr2: '#030342',
		secondaryBdr3: '#52B788',
	},
	input: {
		txtColor: hexToRgba('#030342', 0.9),
		placeholderTxtColor: '#AFB0C3',
		bdrColor: '#AFB0C3',
		instrColor: '#969696',
		fontSize: '14px',
		readFontSize: '14px'
	},
	text: {
		fontSize: '14px'
	},
	gradients: {
		primary: { start: "#4C33D2", end: "#763CDA" },
		primary2: { start: "#39598A", end: "#79D7ED" },
	},
	logo: {
		full: "https://aion-assets.s3-us-west-2.amazonaws.com/logos/aion_full_blk.png",
		symbol: "https://aion-assets.s3-us-west-2.amazonaws.com/logos/aion_symbol_only.png"
	},
	tab: {
		tabBG: "#000",
		colors: {
			itemColor: "rgba(256, 256, 256, 0.5)",
			itemBackground: "rgba(256, 256, 256, 0.5)",
			itemColorHovered: "#C0E8FF",
			itemBackgroundHovered: "#C0E8FF",
			itemColorSelected: "#24E9FF",
			itemBackgroundSelected: "#24E9FF",
			disabled: "rgba(256, 256, 256, 0.3)"
		}
	},
	menu: {
		topMenuColor: "rgba(0, 0, 0, 0.95)",
		menuBG: "#FAFAFA",
		colors: {
			itemColor: "rgba(0, 0, 0, 0.85)",
			itemBackground: "transparent",
			itemColorHovered: "rgba(4, 24, 88, 0.6)",
			itemBackgroundHovered: "rgba(26, 138, 244, 0.05)",
			itemColorSelected: "rgba(4, 24, 88, 1)",
			itemBackgroundSelected: "rgba(26, 138, 244, 0.2)"
		}
	},
	icon: {
		height: '24px',
		width: '24px'
	},
	dashboardCard: {
		large: 790
	}
}

export const darkTheme = {
	type: 'dark',
	body: '#363537',
	text: '#FAFAFA',
	toggleBorder: '#6B8096',
	gradient: 'linear-gradient(#091236, #1E215D)',
	colors: {
		primary: '#1199FF',
		secondary1: '#2CD1D6',
		secondary2: '#32C2F5',
		secondary3: '#1199FF',
		secondary4: '#5663E9',
		secondary5: '#DEE4EF',
		secondary6: '#7384AA',
		secondary7: '#3C508A',
		secondary8: '#041858',
		systemGray: 'rgb(142, 142, 147)',
		systemGray2: 'rgb(174, 174, 178)',
		systemGray3: 'rgb(199, 199, 204)',
		systemGray4: 'rgb(209, 209, 214)',
		systemGray5: 'rgb(229, 229, 234)',
		systemGray6: 'rgb(242, 242, 247)',
		warning: '#FFCC00',
		error: '#FF0000',
	},
	logo: {
		full: "https://aion-assets.s3-us-west-2.amazonaws.com/logos/aion_full_white.png",
		symbol: "https://aion-assets.s3-us-west-2.amazonaws.com/logos/aion_symbol_only.png"
	},
	tab: {
		tabBG: "#000",
		colors: {
			itemColor: "#1199FF",
			itemBackground: "#1199FF",
			itemColorHovered: "#D1FFD5",
			itemBackgroundHovered: "#D1FFD5",
			itemColorSelected: "#D1FFD5",
			itemBackgroundSelected: "#D1FFD5",
			disabled: "rgba(256, 256, 256, 0.2)"
		}
	},
	menu: {
		topMenuColor: "rgba(0, 0, 0, 0.95)",
		colors: {
			itemColor: "#9CA6AA",
			itemBackground: "transparent",
			itemColorHovered: "rgba(4, 24, 88, 0.6)",
			itemBackgroundHovered: "#EFFFEF",
			itemColorSelected: "rgba(4, 24, 88, 0.6)",
			itemBackgroundSelected: "rgba(26, 138, 244, 0.2)"
		}
	}
}

export const SYSTEM_STYLES = {
	cardStyle: { borderRadius: '10px', backgroundColor: lightTheme.colors.systemGray6, padding: '20px' }
}

export const containerDimensions = {
	authPage: {
		sideBar: "20",
		gradientSidebarWidth: "700",
		abstractSidebarWidth: "400",
		logoHeight: "40px"
	},
	menu: {
		symbolHeight: "30px",
		sideMenuWidth: "250px"
	},
	buttons: {
		outlineButtonRadius: "5px"
	},
	cards: {
		borderRadius: '5px',
		minWidth: 450
	},
	logo: {
		txnLogoWidth: 35
	},
	tab: {
		iconSize: 20
	}
}