import React, { useState } from 'react';
import { Text, Tag } from '../../Reusable/Text';
import { Checkbox, Select } from 'antd'
import { LabeledInput } from '../../Reusable/Input';
import { Button, TextButton } from '../../Reusable/Button'
import Banner from '../../Reusable/Banner'
import { Image } from '../../Reusable/Image'
import { ErrorAlert } from '../../Reusable/Alert'
import { FromAccountOption, PaymentMethodOption } from '../../Reusable/Option'
import moment from 'moment'
import { toCurrency, getBPAccountsWithAccess, toNumberStr } from '../../../Utils/util'
import { apiPOSTReq } from '../../../Utils/api'
import environment from '../../../environment'
import { Flex, FlexColumn, Container, Card, CardContainer } from '../../Reusable/Container';
import Arrow from '../../../Images/arrow-right.png'
import Profile from '../../../Images/merchant-profile.svg'
import Phone from '../../../Images/merchant-phone.svg'
import Email from '../../../Images/merchant-email.svg'
import Check from '../../../Images/check-green.svg'
import AionIcon from '../../../Images/aion-bank-icon.png'

const { Option } = Select


const PaymentOptions = ({ width, onLogin, aionAccountsLoading, accounts, pay, errorField, errorMessage, payLoading }) => {
    const [loggedIntoAion, setLoggedIntoAion] = useState(false);
    const [payByBank, setPayByBank] = useState(false);
    const [payByAion, setPayByAion] = useState(false);
    const [routingNumber, setRoutingNumber] = useState();
    const [accountNumber, setAccountNumber] = useState();
    const [accountType, setAccountType] = useState();
    const [confirmAccountNumber, setConfirmAccountNumber] = useState();
    const [fromAccount, setFromAccount] = useState();


    const handleBankChange = (e) => {
        setPayByBank(e.target.checked);
        setPayByAion(false);
    };

    const handleAionChange = (e) => {
        setPayByAion(e.target.checked);
        setPayByBank(false);
    };

    const handleSelectAccount = (value, option) => {
        var Accounts = getBPAccountsWithAccess("Payments", { permType: "manage", getActiveAccounts: true })
        var account = Accounts.find(a => a.accountId === value)

        console.log('CustomerPayment handleSelectAccount', account)

        setFromAccount(account)
        // this.getFees({ ...(this.state.paymentMethod || {}), fromAccount: account || {} })
    }

    var options = []
    var filteredAccounts = accounts?.filter(item => { return (!['ACCOUNTS_RECEIVABLE', 'Transitional'].includes(item.accountType)) }) || []
    filteredAccounts.forEach(account => {
        options.push(
            <Option key={account.accountId} value={account.accountId} style={{ backgroundColor: "transparent" }}>
                <FromAccountOption account={account} />
            </Option>)
    })

    const loggedIn = (accounts && accounts.length > 0)

    return (
        <FlexColumn start left gap="24px" style={{ width: width }} fullWidth={!width}>
            <Text size="16px" weight="500">PAYMENT OPTIONS</Text>

            <FlexColumn left start gap="24px" fullWidth>
                <CardContainer className="radio-table" fullWidth>
                    <Flex start style={{ cursor: 'pointer' }} onClick={() => {
                        setPayByBank(!payByBank);
                        setPayByAion(false);
                    }}>
                        <Checkbox checked={payByBank} onChange={handleBankChange} />
                        <FlexColumn style={{ margin: '0 0 0 8px' }}>
                            <Text size="16px" weight={payByBank ? '700' : '400'}>Pay by Bank Transfer</Text>
                            <Text lightText>Enter your bank details and authorize aion to pull funds</Text>
                        </FlexColumn>
                    </Flex>

                    {
                        payByBank &&
                        <FlexColumn start left>
                            <div style={{ width: '100%', height: 1, background: '#E6E6EC', margin: '24px 0' }}></div>
                            <Flex wrap gap='24px' start>
                                <LabeledInput
                                    label="ACH Routing Number"
                                    id="routingNumber"
                                    key="routingNumber"
                                    placeholder="Enter a 9 digit routing number"
                                    value={routingNumber && routingNumber}
                                    maxLength={9}
                                    onChange={(event) => setRoutingNumber(event.target.value)}
                                    error={errorField === "routingNumber"}
                                    errorMessage={errorMessage}
                                    nomargin
                                // width={width && '276px'}
                                />

                                <LabeledInput
                                    label="Account Number"
                                    placeholder="Eg: 112454696"
                                    value={accountNumber && accountNumber}
                                    maxLength={17}
                                    onChange={(event) => setAccountNumber(event.target.value)}
                                    error={errorField === "accountNumber"}
                                    errorMessage={errorMessage}
                                    nomargin
                                    width={width && '276px'}
                                />

                                <LabeledInput
                                    label="Confirm Account Number"
                                    placeholder="Confirm your account #"
                                    value={confirmAccountNumber && confirmAccountNumber}
                                    maxLength={17}
                                    onChange={(event) => setConfirmAccountNumber(event.target.value)}
                                    error={errorField === "confirmAccountNumber"}
                                    errorMessage={errorMessage}
                                    nomargin
                                    width={width && '276px'}
                                />

                                <LabeledInput
                                    label='Account Type'
                                    type='switch'
                                    switchNames={['Checking', 'Savings']}
                                    onChange={(value) => setAccountType(value)}
                                    value={accountType}
                                    error={errorField === "accountType"}
                                    errorMessage={errorMessage}
                                    nomargin
                                    width={width && '276px'}
                                />
                            </Flex>

                            <Flex start fullWidth style={{ margin: '24px 0 8px' }} centerHorizontally gap='4px' wrap>
                                <Text>By clicking on “Authorize and Pay”, you are agreeing to Aion’s <a target="_blank" href="https://www.aionfi.com/ach-authorization-terms-conditions">ACH Debit authorization</a> </Text>
                            </Flex>

                            <Button text='Authorize and Pay' loading={payLoading} solid onClick={() => pay(payByAion, { accountName: null, accountNumber, confirmAccountNumber, routingNumber, accountSubType: accountType })} />
                        </FlexColumn>
                    }


                </CardContainer>

                <CardContainer className="radio-table" fullWidth>
                    <Flex start style={{ cursor: 'pointer' }} onClick={() => {
                        setPayByAion(!payByAion);
                        setPayByBank(false);
                    }}>
                        <Checkbox checked={payByAion} onChange={handleAionChange} />
                        <FlexColumn style={{ margin: '0 0 0 8px' }}>
                            <Text size="16px" weight={payByAion ? '700' : '400'}>Aion Pay</Text>
                            <Text lightText>Have an Aion account? Pay via Aion Pay at no extra charge</Text>
                        </FlexColumn>
                    </Flex>

                    {
                        payByAion &&
                        <FlexColumn start left fullWidth>
                            <div style={{ width: '100%', height: 1, background: '#E6E6EC', margin: '24px 0' }}></div>

                            {
                                loggedIn ?
                                    <>
                                        <Banner
                                            style={{ marginBottom: 10, width: '100%' }}
                                            borderColor='#6DB58B'
                                            background='#F0F8F3'
                                            color='#6DB58B'
                                            message="Logged in successfully via Aion"
                                            icon={<Image src={Check} />}
                                        />

                                        <LabeledInput
                                            label="Select Aion Account"
                                            type="select"
                                            id="fromAccount"
                                            // width='276px'
                                            placeholder="Select account"
                                            value={fromAccount && <Flex between centerHorizontally style={{ height: 30 }}>
                                                <Text><img width='16px' height='16px' src={AionIcon} style={{ marginRight: 8, marginBottom: 2 }} />{`${(fromAccount.nickName || `Business ${fromAccount.accountSubType}`)} • ${fromAccount.mask}`}</Text>
                                                <Text size='14px' lightText style={{ marginLeft: 8 }}>${toCurrency(fromAccount.availableBalance)}</Text>
                                            </Flex>}
                                            onChange={handleSelectAccount}
                                            // noAsterisk
                                            error={errorField === "fromAccount"}
                                            errorMessage={errorMessage}
                                        >
                                            {options}
                                        </LabeledInput>
                                    </>
                                    :
                                    <>
                                        <FlexColumn fullWidth center gap='16px'>
                                            <Text>Login to Aion to retrieve your accounts</Text>
                                            <Button loading={aionAccountsLoading} text='Login' width='244px' onClick={onLogin} />
                                        </FlexColumn>

                                        <Flex center fullWidth style={{ margin: '24px 0' }}>
                                            <span style={{ flex: 1, borderBottom: '1px solid #E6E6EC' }}></span>
                                            <Text color='#8181A0' margin='0 16px'>OR</Text>
                                            <span style={{ flex: 1, borderBottom: '1px solid #E6E6EC' }}></span>
                                        </Flex>

                                        <Flex wrap gap='24px' start fullWidth style={{ marginBottom: 24 }}>
                                            <LabeledInput
                                                label="Account Number"
                                                placeholder="Eg: 112454696"
                                                value={accountNumber && accountNumber}
                                                maxLength={17}
                                                onChange={(event) => setAccountNumber(event.target.value)}
                                                error={errorField === "accountNumber"}
                                                errorMessage={errorMessage}
                                                nomargin
                                                width={width && '276px'}
                                            />

                                            <LabeledInput
                                                label="Confirm Account Number"
                                                placeholder="Confirm your account #"
                                                value={confirmAccountNumber && confirmAccountNumber}
                                                maxLength={17}
                                                onChange={(event) => setConfirmAccountNumber(event.target.value)}
                                                error={errorField === "confirmAccountNumber"}
                                                errorMessage={errorMessage}
                                                nomargin
                                                width={width && '276px'}
                                            />
                                        </Flex>


                                        <LabeledInput
                                            label='Account Type'
                                            type='switch'
                                            switchNames={['Checking', 'Savings']}
                                            onChange={(value) => setAccountType(value)}
                                            value={accountType}
                                            error={errorField === "accountType"}
                                            errorMessage={errorMessage}
                                            nomargin
                                            width={width && '276px'}
                                        />
                                    </>
                            }

                            <Flex start fullWidth style={{ margin: '24px 0 8px' }} centerHorizontally gap='4px' wrap>
                                <Text>By clicking on “Authorize and Pay”, you are agreeing to Aion’s <a target="_blank" href="https://www.aionfi.com/ach-authorization-terms-conditions">ACH Debit authorization</a> </Text>
                            </Flex>

                            <Button loading={payLoading} text='Authorize and Pay' solid onClick={() => pay(payByAion, loggedIn ? fromAccount : { loggedIn: false, accountName: null, accountNumber, confirmAccountNumber, routingNumber: "021214891", accountSubType: accountType })} />
                        </FlexColumn>
                    }
                </CardContainer>
            </FlexColumn>
        </FlexColumn>
    );
};

export default PaymentOptions;

