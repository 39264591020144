import React, { useState } from 'react';
import { Text, Tag } from '../../Reusable/Text';
import { LabeledInput } from '../../Reusable/Input';
import { TextButton } from '../../Reusable/Button'
import { Image } from '../../Reusable/Image'
import moment from 'moment'
import { formatPhoneTextV2 } from '../../../Utils/util'
import { Flex, FlexColumn, Container, Card, CardContainer } from '../../Reusable/Container';
import Download from '../../../Images/download.png'
import Profile from '../../../Images/merchant-profile.svg'
import Phone from '../../../Images/merchant-phone.svg'
import Email from '../../../Images/merchant-email.svg'


const MerchantDetails = ({ width, referralInfo }) => {
    var { name, phone, email } = referralInfo || {}

    return (
        <CardContainer width={width} fullWidth={!width} padding='12px 24px 24px'>
            <FlexColumn gap='24px'>
                <Text heading>Vendor details</Text>
                <Flex wrap gap='16px' start>
                    {
                        name &&
                        <Flex start style={{ width: 172 }} gap='2px' centerHorizontally>
                            <Image src={Profile} />
                            <Text>{name}</Text>
                        </Flex>
                    }
                    {
                        phone &&
                        <Flex start style={{ width: 172 }} gap='2px' centerHorizontally>
                            <Image src={Phone} />
                            <Text>{formatPhoneTextV2(phone)}</Text>
                        </Flex>
                    }
                    {
                        email &&
                        <Flex start style={{ width: 172 }} gap='2px' centerHorizontally>
                            <Image src={Email} />
                            <Text>{email}</Text>
                        </Flex>
                    }
                </Flex>
            </FlexColumn>
        </CardContainer>
    );
};

export default MerchantDetails;
