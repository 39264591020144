import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { Flex, FlexColumn } from '../../../Reusable/Container'
import { Text } from '../../../Reusable/Refresh/Text'
import Submission from '../../../../Images/submission.png'
import { apiPOSTReq } from '../../../../Utils/api';
import environment from '../../../../environment';
import moment from 'moment'
import { addDataToStore, CREDIT_SAVE_DATA } from '../../../../Actions/actions';

class PreScreenLoading extends Component {
    componentDidMount() {
        this.timer = setInterval(() => this.fetchLoanApp(), 30000);
    }

    fetchLoanApp = () => {
        var { isCreditPlus } = this.props
        // Fetch document list
        var body = {
            "clientBusinessId": this.props.store.BusinessUniqueKey,
            "size": 100,
            "page": 0,
            // "sortDirection": "ASC"
        }
        this.setState({ loanAppLoading: true });
        apiPOSTReq(`${environment.opsBaseUrl}/ops/loans/application/getApplications`, {}, body, (err, resp) => {
            try {
                const data = resp || {};
                if (data.result) {
                    var loanApplications = data.loanApplications || [];
                    var loanApp = loanApplications.length > 0 ? loanApplications[loanApplications.length - 1] : {};
                    this.props.dispatch(addDataToStore(CREDIT_SAVE_DATA, { loanApplication: loanApp }));
                    let now = moment().utc();
                    let createTime = moment(loanApp.auditData.lastUpdatedTime);
                    let difference = now.diff(createTime, "minutes");

                    if (difference > 3) {
                        clearInterval(this.timer)
                        isCreditPlus == true ? this.props.loadNext() :
                            this.props.history.push('/apply/credit');
                    }

                    if (loanApp.underwritingStatus != "Not Started" && loanApp.underwritingStatus != "Processing") {
                        clearInterval(this.timer)
                        isCreditPlus == true ? this.props.loadNext() :
                            this.props.history.push('/apply/credit');
                    }
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/getApplications err", error, resp);
                // ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            } finally {
                this.setState({ loanAppLoading: false });
            }
        })
    }

    render() {
        var marginTop = "220px"
        return (
            <FlexColumn start centerHorizontally style={{ backgroundColor: '#333333', height: '100%' }}>
                <div style={{ width: '624px', zIndex: 5, }}>
                    <FlexColumn center gap='24px' style={{ marginTop: marginTop }}>
                        <img height='48px' width='48px' src={Submission} />
                        <Text weight="500" size='28px' height='40px' color='#FFFFFF' style={{ textAlign: 'center', maxWidth: '400px' }}>Your application is currently being processed</Text>
                        <Text color='#FFFFFF' style={{ textAlign: 'center', maxWidth: '400px' }}>Our team is working on your application. We will also send you an email when we have an update.</Text>
                    </FlexColumn>
                </div>
            </FlexColumn>
        );
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer,
        creditStore: state.creditAppReducer,
        onboardingStore: state.onboardingAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(PreScreenLoading)));