import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { withTheme } from 'styled-components';
import queryString from 'query-string'
import { saveAs } from 'file-saver'

import { Flex, FlexColumn, LineItemsHeader, LineItemRow } from '../../Reusable/Container';
import FlowViewComponent from '../../Reusable/Refresh/FlowViewComponent';
import moment from 'moment'
import { Image } from '../../Reusable/Image'
import { Select, Modal, Space, Popconfirm, Tooltip, Checkbox, Table, Collapse, Divider } from 'antd'
import { PlusOutlined, DeleteOutlined, EditOutlined, DownOutlined, WarningFilled, PaperClipOutlined, FilePdfOutlined, CloseOutlined } from '@ant-design/icons'
import { LabeledInput } from '../../Reusable/Input';
import { Paragraph, Text } from '../../Reusable/Text';
import { addDataToStore, BUSINESS_INFO, REMOTE_SAVE_DATA, UAM_SAVE_BUSINESS, ONB_SAVE_DATA } from '../../../Actions/actions';
import { Button, TextButton, ImageButton } from '../../Reusable/Button';
import { ErrorAlert } from '../../Reusable/Alert';
import environment from '../../../environment';
import { LightContainer, CardContainer } from '../../Reusable/Container';
import { apiGET, apiPOST, apiPOSTReq, getPreSignedS3Url } from '../../../Utils/api';
import { message, Upload, Drawer } from 'antd';
import QB from '../../../Images/quickbooks-icon-sm.jpeg'
import Connection from '../../../Images/connection.png'
import ModalClose from '../../../Images/modal-close.png'
import DownloadIcon from '../../../Images/download.png'
import Info from '../../../Images/info.png'
import File from '../../../Images/file.png'
import OAuthClient from 'intuit-oauth';
import { capitalizeFirstLetterInWords, toCurrency } from '../../../Utils/util';
import CollapseCheck from '../../../Images/collapse-check.png';
import MessageIcon from '../../../Images/message.png';
import cloneDeep from 'lodash/cloneDeep'
import AccountingSysConnect from '../../Reusable/AccountingSysConnect';
import { capitalize, flow, isEqual } from 'lodash';
import DownIcon from '../../../Images/chevron-down-icon.png'
import Banner from '../../Reusable/Banner'
import Edit from '../../../Images/edit.png';
import Delete from '../../../Images/delete-red-icon.png'
import Asterisk from '../../../Images/asterisk.png'
import { StyledExtLink } from '../../Reusable//Link'

const { Option } = Select

var preScreenDocs = [
    { name: "Company-prepared balance sheet — most recent closed month" },
    { name: "Company-prepared balance sheet — most recent fiscal year end" },
    { name: "Company-prepared profit & loss statement — YTD through most recent closed month" },
    { name: "Company-prepared profit & loss statement — full year for most recent fiscal year end" },
    { name: "AR Aging Report" }
];

class FinancialInfo extends Component {

    constructor(props) {
        super(props)
        const { UserInfo } = this.props.aionStore

        this.state = {
            uploadMethod: 'QuickBooks Online',
            showUploadMethodChangeModal: false,
            debtOverview: [],
            gnplLow: 0,
            gnplHigh: 100001,
            documentUrls: [],
            showSuccessBanner: false,
            uploadingItem: "",
            uploadLoading: false,
            isDebtInfoActive: false,
            accountingSysConnected: false,
            isDocUploadsActive: false,
            showDrawer: false,
            selectedDebtOverview: {},
            selectedDebtOverviewClone: {},
            debtOverviewCopy: [],
            valueToCheck: true
        }
    }

    componentDidMount() {
        this.fetchDocuments();
        this.handleCallback();
        this.loadDebtSchedule();
        this.fetchBusinessAttributes();
    }

    fetchBusinessAttributes = () => {
        var body = {
            "clientBusinessId": ''
        };

        this.setState({ attributesLoading: true });
        // apiPOSTReq(`${environment.opsBaseUrl}/ops/fetchBusinessAttributes`, {}, body, (err, resp) => {
        apiPOSTReq(`${environment.opsBaseUrl}/ops/fetchBusinessAttributes`, null, body, (err, resp) => {
            try {
                if (err) throw Error(err);
                const data = resp || {};
                if (data.result) {
                    var lineSetup = data.businessAttributes.filter(x => x.name == "GNPLAutomatedLineSetupFlow");
                    if (lineSetup.length > 0) {
                        var attribute = lineSetup[0];
                        var additionalParameterReference = attribute.additionalParameterReference;
                        var parameters = additionalParameterReference.parameters;
                        var low = parseInt(parameters[0].value);
                        var high = parseInt(parameters[1].value);
                        this.setState({ gnplLow: low, gnplHigh: high })
                    }
                } else {
                    throw Error(data.responseMessage || data.error);
                }
            } catch (error) {
                console.log("/generateDebtSchedule err", error, resp);
            }
        });
    }

    loadDebtSchedule = () => {
        var { loanApplication, onboardingStore, readonly, aionStore } = this.props;
        var { businessInfo } = onboardingStore;

        if (!businessInfo.capitalRequest && readonly) {
            var { business } = aionStore || {};
            business = business || {};
            var { businessProfile } = business;
            var { businessInfo } = businessProfile || {};
            businessInfo = businessInfo || {}
        }

        // if (businessInfo.capitalRequest < this.state.gnplHigh) {
        var body = {
            applicationId: loanApplication && loanApplication.applicationId,
            BusinessId: loanApplication && loanApplication.businessId
        }

        apiPOSTReq(`${environment.uamBaseUrl}/uam/generateDebtSchedule`, {}, body, (err, resp) => {
            try {
                if (err) throw Error(err);
                const data = resp || {};
                if (data.result) {
                    this.setState({ debtAddloading: false });
                    data.debtOverview.map((lineItem, i) => {
                        lineItem.id = i;
                    });

                    if (data.debtOverview.length > 0 || data.noOutstandingDebt) {
                        this.setState({ isDebtInfoActive: true });
                    }
                    this.setState({ debtOverview: data.debtOverview, noOutstandingDebt: (typeof (data.noOutstandingDebt) == 'boolean') ? (data.noOutstandingDebt ? true : data.debtOverview.length > 0 ? true : false) : null })
                    this.setCheckMark();
                } else {
                    throw Error(data.responseMessage || data.error);
                }
            } catch (error) {
                console.log("/generateDebtSchedule err", error, resp);
            }
        });
        // }
    }

    handleCallback = () => {
        console.log('handleCallback qb-credit-eligibility')
        const { UserInfo } = this.props.aionStore;
        const value = queryString.parse(this.props.location.search)
        const state = value.state
        const url = window.location.href
        this.props.dispatch(addDataToStore(ONB_SAVE_DATA, { isCreditPlus: false }));

        if (state && (state === 'qb-credit-eligibility' || state === 'qb-credit-plus')) {
            console.log('handleCallback qb-credit-eligibility')
            if (state === 'qb-credit-plus') {
                this.props.history.replace('/banking/home')
            }
            else {
                this.props.history.replace('/apply/credit')
            }

            if (value.code && value.realmId) {
                const headers = {
                    businesskey: this.props.aionStore.BusinessUniqueKey
                }

                apiGET(`/financing/oauth/token?url=${url}`, headers, (err, resp) => {
                    try {
                        const token = resp.data.Response;
                        if (resp.data.success) {
                            console.log('handleCallback qb-credit-eligibility financing/oauth/token', resp)

                            const data = {
                                accessToken: token.access_token,
                                accessTokenExpiresIn: token.expires_in,
                                accessTokenSecret: "",
                                companyId: value.realmId,
                                accountingSoftware: "QuickBooksOnline",
                                dateCreated: Date.now(),
                                refreshToken: token.refresh_token,
                                refreshTokenExpiresIn: token.x_refresh_token_expires_in,
                                oauthVersion: "2.0",
                                //refreshTokens: true
                            }

                            apiPOST('/connect/business_software', headers, data, (err, resp) => {
                                if (!err) {
                                    try {
                                        const data = resp.data;
                                        if (data.success) {
                                            message.success('QuickBooks connected successfully!', 0.75);
                                            this.getFinancials()
                                        } else {
                                            throw Error("Could not complete submission.")
                                        }
                                    } catch (error) {
                                        console.error("The error message is :" + JSON.stringify(error))
                                        ErrorAlert({ description: "Sorry, we had trouble processing your request. Please try again." });
                                    }
                                } else {
                                    console.error("The error message is :" + JSON.stringify(err))
                                    message.error('Sorry, we had trouble reconnecting your QuickBooks. Please try again.')
                                }
                            })
                        } else {
                            console.error("The error message is :" + JSON.stringify(err))
                            message.error('Sorry, we had trouble reconnecting your QuickBooks. Please try again.')
                            this.setState({ invoiceRefreshLoading: false })
                            throw Error("Could not fetch attributes.")
                        }
                    } catch (error) {
                        console.error("handleCallback qb-credit-eligibility ERROR", error.stack)
                        message.error('Sorry, we had trouble reconnecting your QuickBooks. Please try again.')
                        this.setState({ invoiceRefreshLoading: false })
                    }
                })
            }
        } else {
            // this.fetchConnectedSW();
        }
    }

    saveUserInput = (id, value) => {
        var dataToSave = {};
        switch (id) {
            default:
                dataToSave[id] = value;
                break;
        }
        console.log("saveUserInput", dataToSave)
        this.props.dispatch(addDataToStore(BUSINESS_INFO, dataToSave));
    }

    setErrorField = (id, msg) => this.setState({ errorField: id, errorMessage: msg });

    validateFlow = () => {
        var { onboardingStore, flowView, isCreditPlus } = this.props;
        var { capitalRequest, closeBooksDate, fiscalYearStart, fiscalYearEnd } = onboardingStore.businessInfo;
        const { debtOverview, accountingSysConnected, noOutstandingDebt } = this.state;

        this.setState({ submitLoading: true })
        var filteredDebtOverview = [];
        if (debtOverview.length > 0 && noOutstandingDebt) {
            for (var i = 0; i < debtOverview.length; i++) {

                if ((debtOverview[i]['name'] == '' || debtOverview[i]['name'] == null) && (debtOverview[i]['collateralType'].length > 0 || debtOverview[i]['outstandingDebt'] > 0 || debtOverview[i]['totalOutstandingDebt'] > 0 || debtOverview[i]['note'].length > 0 || debtOverview[i]['documentUrls'].length > 0)) {
                    ErrorAlert({ description: "Please enter Creditor Name for the debt information provided." });
                    return;
                } else if ((debtOverview[i]['name'] == '' || debtOverview[i]['name'] == null) && !(debtOverview[i]['collateralType'].length > 0 || debtOverview[i]['outstandingDebt'] > 0 || debtOverview[i]['totalOutstandingDebt'] > 0 || debtOverview[i]['note'].length > 0 || debtOverview[i]['documentUrls'].length > 0)) {

                } else {
                    filteredDebtOverview.push(debtOverview[i]);
                }
            }
        }

        // if (!closeBooksDate) {
        //     this.setState({ errorField: "closeBooksDate", errorMessage: 'Please select a closing date', submitLoading: false })
        //     return
        // }

        // if (!fiscalYearEnd) {
        //     this.setState({ errorField: "fiscalYearEnd", errorMessage: 'Please select fiscal year end', submitLoading: false })
        //     return
        // }

        // if (moment.utc(closeBooksDate).isBefore(moment.utc(fiscalYearStart).startOf('month'))) {
        //     this.setState({ errorField: "closeBooksDate", errorMessage: 'Closing date cannot be lesser than start of fiscal year', submitLoading: false })
        //     return
        // }

        if (typeof (noOutstandingDebt) != 'boolean') {
            this.setState({ errorField: "noOutstandingDebt", errorMessage: 'Please provide a response', submitLoading: false })
            return
        }

        // const manualUpload = preScreenDocs.find(item => {
        //     if (!this.getDocument(item.name).fileName && item.name != 'AR Aging Report') {
        //         return true;
        //     }
        // })
        // console.log("accountingSysConnected validation", accountingSysConnected, manualUpload, !(accountingSysConnected || (manualUpload == undefined)))
        // if (!(accountingSysConnected || (manualUpload == undefined))) {
        //     ErrorAlert({ title: "Incomplete Document Upload", description: "Please connect to an accounting system or upload the requested financial statements to submit your application.", submitLoading: false});
        //     return;
        // }

        // Save to the DB
        this.updateDebtSchedule(filteredDebtOverview, null);
        this.props.dispatch(addDataToStore(REMOTE_SAVE_DATA, flowView.FlowStep));

        this.setState({ showSuccessBanner: true, })
        if (isCreditPlus) {
            setTimeout(() => {
                this.creditPlusSubmit();
            }, 1000)
        } else {
            setTimeout(() => {
                this.setState({ submitLoading: false, showSuccessBanner: false })
                this.props.loadNext();
            }, 1000)
        }
    }

    validateDebt = () => {
        var { selectedDebtOverview } = this.state;

        if ((selectedDebtOverview['name'] == '' || selectedDebtOverview['name'] == null)) {
            this.setErrorField("name", "Please enter Creditor Name for the debt information provided.");
            return;
        }

        this.setErrorField("", "");
        return true;
    }

    creditPlusSubmit = () => {
        var { aionStore, onboardingStore, loanApplication } = this.props || {};
        var { businessInfo } = onboardingStore || {};
        businessInfo = businessInfo || {};
        var { name, capitalRequest } = businessInfo;
        var { UserInfo } = aionStore || {};

        this.props.updateLoanApp({
            "applicantFirstName": UserInfo.FirstName,
            "applicantLastName": UserInfo.LastName,
            "emailAddress": UserInfo.Email,
            "userStatus": "PreScreenCompleted",
            "businessName": name
        });

        var notificationBody = {
            "fromAddr": "Aion <notification@aionfi.com>",
            "toAddr": process.env.REACT_APP_ENV == "production" ? "kveid@aionfi.com, cpetre@aionfi.com, bsterrett@aionfi.com" : "stagingmoderators@mg.aionfi.com",
            // "ccAddr" : "", 
            // "bccAddr" : "" , 
            "emailSubject": `New pre-screen credit application`,
            "emailBody": `<p>Dear Credit Team,<p>A new pre-screen credit application was submitted, you can view this application by logging in to https://app.aionfi.com.</p><p>ID: <b>${(loanApplication || {}).applicationId}</b><br/>Business: <b>${name}</b><br/>User: <b>${UserInfo.FirstName} ${UserInfo.LastName}</b></p></p>`
        }

        // apiPOSTReq(`${environment.opsBaseUrl}/ops/sendEmail`, header, notificationBody, (err, resp) => {
        //     console.log("sendEmail", err, resp, notificationBody);

        //Email Triggered to User confirmation
        var userNotificationBody = {
            "fromAddr": "Aion <credit@aionfi.com>",
            "toAddr": UserInfo.Email,
            "ccAddr": process.env.REACT_APP_ENV != "production" ? "stagingmoderators@mg.aionfi.com" : "",
            "bccAddr": process.env.REACT_APP_ENV != "production" ? "akumar@aionfi.com" : "",
            "emailSubject": `Aion Application Submitted ✅`,
            "emailBody": `<p>Hi ${UserInfo.FirstName},<p>Your application for financing has been received! Thank you for taking the time to apply. Aion is hard at work reviewing the information you provided.</p><p>Stay tuned for an update on the status of your application.</p></p>`
        }

        apiPOSTReq(`${environment.opsBaseUrl}/ops/sendEmail`, null, userNotificationBody, (err, resp) => {
            console.log("sendEmail", err, resp, userNotificationBody);
        })
        // })

        var orchestratorBody = {
            "businessId": loanApplication.businessId,
            "clientBusinessId": loanApplication.businessId,
            "applicationId": loanApplication.applicationId,
            "requestedAmount": parseFloat(capitalRequest.replace(/[$,]/g, ''))
        }

        apiPOSTReq(`${environment.uamBaseUrl}/orchestrator`, {}, orchestratorBody, (err, resp) => {
            console.log("orchestrator", err, resp, orchestratorBody);
        })
        apiPOSTReq(`${environment.uamBaseUrl}/getBusinessOwnership`, {}, {}, (err, resp) => {
            try {
                if (err) throw Error(err);
                const data = resp || {};
                if (data.result) {
                    var business = data.business || {}
                    this.props.dispatch(addDataToStore(UAM_SAVE_BUSINESS, data));
                } else {
                    throw Error(data.responseMessage || data.error);
                }
            } catch (error) {
                console.log("/getBusiness err", error, resp);
            }
        });
        this.setState({ submitLoading: false });
        this.props.loadNext();
    }

    updateDebtSchedule = (debtOverview, outstandingDebt) => {
        var { loanApplication } = this.props;
        //var { debtOverview } = this.state;
        var body = {
            applicationId: loanApplication.applicationId,
            BusinessId: loanApplication.businessId,
            DebtSchedule: {
                applicationId: loanApplication.applicationId,
                BusinessId: loanApplication.businessId,
                noOutstandingDebt: this.state.noOutstandingDebt,
                debtOverview: outstandingDebt == null ? this.state.noOutstandingDebt ? debtOverview : [] : outstandingDebt
            }
        }

        this.setState({ debtAddloading: true });
        apiPOSTReq(`${environment.uamBaseUrl}/uam/updateDebtSchedule`, {}, body, (err, resp) => {
            try {
                if (err) throw Error(err);
                const data = resp || {};
                if (data.result) {
                    console.log('Debt Schedule Updated');
                    this.setState({ debtAddloading: false, showDebtInfoModal: false })
                    this.loadDebtSchedule();
                } else {
                    throw Error(data.responseMessage || data.error);
                }
            } catch (error) {
                console.log("/updateDebtSchedule err", error, resp);
            }
        });
    }

    handleDocSubmit = (uploadItem, index) => {
        var { loadingItem } = uploadItem || {}
        var { loanApplication, onboardingStore } = this.props
        var businessInfo = onboardingStore.businessInfo || {}
        var startDate = moment.utc(businessInfo.fiscalYearStart);
        var endDate = moment.utc(businessInfo.fiscalYearEnd);
        var businessDocument = {
            "name": uploadItem.name,
            "documentUrl": uploadItem.savedFile || {},
            "subType": 'BusinessFinancials',
            "source": 'User'
        };

        if (uploadItem.name != "AR Aging Report") {
            businessDocument.category = "PreScreen"
            businessDocument.closingDate = businessInfo.closeBooksDate
            businessDocument.fiscalFromMonth = startDate.format('MM')
            businessDocument.fiscalFromYear = startDate.format('YYYY')
            businessDocument.fiscalToMonth = endDate.format('MM')
            businessDocument.fiscalToYear = endDate.format('YYYY')
        }

        if (loanApplication) businessDocument.applicationId = loanApplication.applicationId;
        var body = {
            businessDocument: businessDocument
        }

        console.log("handleSubmit DOC/SAVE uploadItem body", body);
        //this.setState({ submitLoading: true, loadingItem: loadingItem });
        apiPOSTReq(`${environment.uamBaseUrl}/docs/save`, {}, body, (err, resp) => {
            this.setState({ uploadLoading: false, uploadingItem: "uploading" + index, loadingItem: null });
            try {
                const data = resp || {};
                if (data.result) {
                    message.success(`File saved successfully.`, 0.75);
                    var { businessDocuments } = this.state;
                    if (businessDocuments.length == 0) {
                        this.setState({ showManualUploadModal: true, isDocUploadsActive: true })
                    }
                    this.fetchDocuments();
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/docs/save err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    handleDeleteDoc = (uploadItem) => {
        var { loanApplication } = this.props;
        var businessDocument = {
            "category": "PreScreen",
            "name": uploadItem.name
        };

        if (loanApplication) businessDocument.applicationId = loanApplication.applicationId;
        var body = {
            "businessDocument": businessDocument,
            "documentId": uploadItem.documentId
        }

        console.log("handleSubmit DOC/delete uploadItem body", body);
        this.setState({ submitLoading: true });
        apiPOSTReq(`${environment.uamBaseUrl}/docs/delete`, {}, body, (err, resp) => {
            this.setState({ submitLoading: false, loadingItem: null });
            try {
                const data = resp || {};
                if (data.result) {
                    message.success(`File Deleted successfully.`, 0.75);
                    this.fetchDocuments();
                    let doc = this.state[uploadItem.name] || {};
                    doc.savedFile = {};
                    this.setState({ [doc.name]: doc });
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/docs/deleteUpload err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    fetchDocuments = () => {
        const { loanApplication } = this.props;
        var body = {
            "sortDirection": "DESC"
        };
        if (loanApplication) {
            body["clientBusinessId"] = loanApplication.businessId;
            body["applicationId"] = loanApplication.applicationId;
            body["viewBy"] = "FILTER_APPLICATION"
        }
        console.log("/docs/getActiveDocuments body", body, loanApplication);
        this.setState({ qbConnectLoading: true });
        apiPOSTReq(`${environment.uamBaseUrl}/docs/getActiveDocuments`, {}, body, (err, resp) => {
            console.log("/docs/getActiveDocuments resp", resp);
            this.setState({ qbConnectLoading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    this.setState({ businessDocuments: data.businessDocuments });

                    if (data.businessDocuments && data.businessDocuments.length > 0 &&
                        data.businessDocuments[0].documentUrl.fileName
                        && !data.businessDocuments[0].documentUrl.fileName.includes('QBO_Generated')) {
                        this.saveUserInput('uploadMethod', "Upload Manually")
                    }
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    uploadProps = (doc, index) => {
        return {
            name: `business-docs`,
            action: `${environment.apiBaseUrl}/file/upload`,
            headers: {
                businesskey: this.props.aionStore.BusinessUniqueKey,
                jwt: this.props.aionStore.jwt
            },
            showUploadList: false,
            beforeUpload: (doc) => {
                var { onboardingStore } = this.props
                var businessInfo = onboardingStore.businessInfo || {}

                if (!businessInfo.closeBooksDate) {
                    ErrorAlert({ description: "Please select last closed books date." });
                    return Upload.LIST_IGNORE;
                }

                if (!businessInfo.fiscalYearEnd) {
                    ErrorAlert({ description: "Please select most recent fiscal year end." });
                    return Upload.LIST_IGNORE;
                }

                const isPDF = doc.type === 'application/pdf';

                const isXLSX = doc.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                if (!isXLSX && !isPDF) {
                    message.error(`${doc.name} is not a pdf file`);
                    return isPDF || isXLSX || Upload.LIST_IGNORE;
                }

                var maxFileSize = 10240; // 10MB

                const uploadedFileSizeKiloBytes = doc.size / 1024

                if (uploadedFileSizeKiloBytes > maxFileSize) {
                    message.error(`File size is greater than 10MB`);
                    this.setState({ uploadLoading: false, uploadingItem: "uploading" + index });

                    return false;
                }
            },
            onChange: (info) => {
                var { fileList } = info;
                var savedFile = {};

                fileList.forEach(file => {
                    this.setState({ uploadLoading: true, uploadingItem: "uploading" + index });
                    const { status, response, name, url } = file;
                    if (status === "done") {
                        savedFile = {
                            fileName: name,
                            uri: (response.UploadedUrls || [])[0],
                        }
                        if (doc.type) savedFile.type = doc.type;
                        // message.success(`${info.file.name} file uploaded successfully.`, 0.75);
                        doc.savedFile = savedFile;
                        this.handleDocSubmit(doc, index);
                    } else if (status === 'error') {
                        this.setState({ uploadLoading: false });
                        message.error(`${info.file.name} file upload failed.`);
                    }

                    if (!status) {
                        this.setState({ uploadLoading: false });
                    }
                });
                if (((Object.keys(savedFile)).length) !== 0) {
                    doc.savedFile = savedFile;
                }
                this.setState({ [doc.name]: doc });
            },
            accept: ".pdf",
            onRemove: (file) => {
                this.handleDeleteDoc(doc);
            }
        }
    };

    getDocument = (name, options) => {
        options = options || {};
        var { businessDocuments } = this.state;
        var savedDoc = this.state[name] || {};
        var filteredDoc = (businessDocuments || []).find(item => (item.name == name));
        if (filteredDoc != undefined) {
            // Replace with remotely stored data
            if (options.businessDocument) savedDoc = filteredDoc;
            else savedDoc = filteredDoc.documentUrl || {};
        }
        return savedDoc;
    }

    handleDateChange = (id, dateStr) => {
        if (dateStr) {
            if (id == "dob") {
                this.saveUserInput(id, moment.utc(dateStr).startOf('day').toDate())
            } else {
                this.saveUserInput(id, moment.utc(dateStr).startOf('day').format("YYYY-MM-DD"))
            }
        } else {
            this.saveUserInput(id, null)
        }
        var { flowView } = this.props;
        this.props.dispatch(addDataToStore(REMOTE_SAVE_DATA, "Apply.Ownership")); // Apply.Ownership so that it does not take user to the next step on reload
    }

    handleFiscalYear = (id, dateStr, date) => {
        if (id == "fiscalYearStart") {
            var startDate = moment.utc(dateStr, "MMM-YYYY").startOf('month');
            this.saveUserInput("fiscalYearStart", startDate.format("YYYY-MM-DD"));
            this.saveUserInput("fiscalYearEnd", startDate.add(11, 'months').endOf('month').format("YYYY-MM-DD"));
        }
        else if (id == "fiscalYearEnd") {
            var endDate = moment.utc(dateStr, "MMM-YYYY").endOf('month');
            this.saveUserInput("fiscalYearEnd", endDate.format("YYYY-MM-DD"));
            this.saveUserInput("fiscalYearStart", endDate.add(-11, 'months').startOf('month').format("YYYY-MM-DD"));
        }
        var { flowView } = this.props;
        this.props.dispatch(addDataToStore(REMOTE_SAVE_DATA, "Apply.Ownership")); // Apply.Ownership so that it does not take user to the next step on reload
    }

    connectQuickBooks = () => {
        var { aionStore, loanApplication, onboardingStore, isCreditPlus, flowView } = this.props;
        const { Attributes } = aionStore;
        const businessAttributes = Attributes.Businesses || {};
        var businessInfo = onboardingStore.businessInfo || {};
        var { debtOverview } = this.state;

        if (!businessInfo.closeBooksDate) {
            this.setState({ errorField: "closeBooksDate", errorMessage: 'Please select a closing date' })
            return
        }

        if (!businessInfo.fiscalYearEnd) {
            this.setState({ errorField: "fiscalYearEnd", errorMessage: 'Please select fiscal year end' })
            return
        }

        var filteredDebtOverview = [];
        if (debtOverview.length > 0 && this.state.noOutstandingDebt) {
            for (var i = 0; i < debtOverview.length; i++) {

                if ((debtOverview[i]['name'] == '' || debtOverview[i]['name'] == null) && (debtOverview[i]['collateralType'].length > 0 || debtOverview[i]['outstandingDebt'] > 0 || debtOverview[i]['totalOutstandingDebt'] > 0 || debtOverview[i]['note'].length > 0 || debtOverview[i]['documentUrls'].length > 0)) {
                    ErrorAlert({ description: "Please enter Creditor Name for the debt information provided." });
                    return;
                } else if ((debtOverview[i]['name'] == '' || debtOverview[i]['name'] == null) && !(debtOverview[i]['collateralType'].length > 0 || debtOverview[i]['outstandingDebt'] > 0 || debtOverview[i]['totalOutstandingDebt'] > 0 || debtOverview[i]['note'].length > 0 || debtOverview[i]['documentUrls'].length > 0)) {

                } else {
                    filteredDebtOverview.push(debtOverview[i]);
                }
            }
        }

        console.log('FinancialInfo businessAttributes', businessAttributes, loanApplication)

        this.updateDebtSchedule(filteredDebtOverview, null);
        if (isCreditPlus) {
            this.props.dispatch(addDataToStore(REMOTE_SAVE_DATA, "Apply.GetStarted"));
        }

        if (businessAttributes.AccountingSWLinked === true) {

            // const headers = {
            //     businessId: aionStore.BusinessUniqueKey
            // }

            this.getFinancials()
        } else {
            var oauthClient = new OAuthClient(environment.qboOAuth);
            const authUri = oauthClient.authorizeUri({
                scope: [
                    OAuthClient.scopes.Accounting,
                    OAuthClient.scopes.OpenId,
                    OAuthClient.scopes.Profile,
                    OAuthClient.scopes.Email,
                    OAuthClient.scopes.Phone,
                    OAuthClient.scopes.Address
                ],
                state: isCreditPlus ? 'qb-credit-plus' : 'qb-credit-eligibility'
            });

            window.location.href = authUri
        }
    }

    getFinancials = () => {
        var { aionStore, loanApplication, onboardingStore, flowView, isCreditPlus } = this.props;
        const { Attributes } = aionStore;
        var businessInfo = onboardingStore.businessInfo || {};
        var startDate = moment.utc(businessInfo.fiscalYearStart);
        var endDate = moment.utc(businessInfo.fiscalYearEnd);

        const data = {
            ClosingDate: businessInfo.closeBooksDate,
            ApplicationId: loanApplication.applicationId,
            "FiscalFromMonth": startDate.format('MM'),
            "FiscalFromYear": startDate.format('YYYY'),
            "FiscalToMonth": endDate.format('MM'),
            "FiscalToYear": endDate.format('YYYY')
        }

        console.log('FinancialInfo businessAttributes financials body', data)

        this.setState({ qbConnectLoading: true })

        apiPOST("/financials", {}, data, (err, resp) => {
            console.log('FinancialInfo businessAttributes financials resp', resp)
            if (!err) {
                try {
                    const data = resp.data;
                    if (data.success) {
                        // message.success('Your documents were retreived from QuickBooks Online successfully!', 2);
                        this.props.dispatch(addDataToStore(REMOTE_SAVE_DATA, flowView.FlowStep));
                        if (isCreditPlus) {
                            // this.creditPlusSubmit();
                            this.fetchDocuments();
                        }
                        else {
                            this.props.loadNext();
                            this.props.onQBUpload()
                        }
                    } else {
                        throw Error("Something went wrong while trying to retrieve your statements from QuickBooks Online.")
                    }
                } catch (error) {
                    console.error("The error message is :" + JSON.stringify(error))
                    ErrorAlert({ description: "Something went wrong while trying to retrieve your statements from QuickBooks Online." });
                } finally {
                    this.setState({ qbConnectLoading: false })
                }
            } else {
                console.error("FinancialInfo businessAttributes financials The error message is :" + JSON.stringify(err))
                ErrorAlert({ description: "Something went wrong while trying to retrieve your statements from QuickBooks Online." });
                this.setState({ qbConnectLoading: false })
            }
        })
    }

    discardFinancials = () => {
        var { aionStore, loanApplication, onboardingStore, flowView } = this.props;

        const data = {
            ApplicationId: loanApplication.applicationId,
        }

        console.log('FinancialInfo businessAttributes discardFinancials body', data, flowView.FlowStep)

        apiPOST("/discardFinancials", {}, data, (err, resp) => {
            if (!err) {
                console.log("FinancialInfo businessAttributes discardFinancials")
            } else {
                console.error("FinancialInfo businessAttributes discardFinancials The error message is :" + JSON.stringify(err))
            }
        })
    }

    downloadStatement = (url, name) => {
        getPreSignedS3Url({ url: url }, (err, preSignedUrl) => {
            saveAs(preSignedUrl, name)
        })
    }

    disabledDate = (current) => {
        return moment().subtract('1', "years") > current || current > moment();
    }

    disabledFiscalYearEndDate = (current) => {
        return current > moment();
    }

    onClickManualUploadModal = () => {
        var { onboardingStore } = this.props;
        var businessInfo = onboardingStore.businessInfo || {};

        if (!businessInfo.closeBooksDate) {
            this.setState({ errorField: "closeBooksDate", errorMessage: 'Please select a closing date' })
            return
        } else if (!businessInfo.fiscalYearEnd) {
            this.setState({ errorField: "fiscalYearEnd", errorMessage: 'Please select fiscal year end' })
            return
        }

        this.setState({ showManualUploadModal: true, showManualUpload: false, errorField: '', errorMessage: '' });
    }

    onUploadBankStatementSubmit = () => {
        if (preScreenDocs.find(item => {
            if (!this.getDocument(item.name).fileName && item.name != 'AR Aging Report') {
                return true;
            }
        })
        ) {
            ErrorAlert({ title: "Incomplete Document Upload", description: "Please make sure to provide the requested financial statements to submit your application." });
        }
        this.setState({ showManualUploadModal: false, showManualUpload: true });
    }

    saveRowUserInput = (id, key, dataToSave) => {
        var { debtOverview, selectedDebtOverview } = this.state;

        /* for (var i = 0; i < debtOverview.length; i++) {
             if (debtOverview[i].id == id) {
                 debtOverview[i][key] = dataToSave;
             }
         }
 
         if (key == 'note') {
             this.setState({ 'note': dataToSave })
         }
 
         this.setState({ debtOverview: debtOverview });
         this.setCheckMark();*/

        selectedDebtOverview[key] = dataToSave;
        this.setState({ selectedDebtOverview: selectedDebtOverview });
    }

    removeLineItemRow = (id) => {
        var debtOverview = this.state.debtOverview || [];

        var filteredDebt = (debtOverview || []).filter((item, index) => {
            if (item.id !== id) return item
        }) || [];

        filteredDebt = filteredDebt.map((lineItem, i) => {
            lineItem.id = i;
            return lineItem;
        });

        this.setState({ debtOverview: filteredDebt, showDrawer: false });
        //this.setCheckMark();
        this.updateDebtSchedule(filteredDebt, null);
    }

    showDebtModal = () => {
        this.setState({ showDebtInfoModal: true, errorField: '', errorMessage: '' })
    }

    showAttachmentModal = (id, documentUrls) => {
        this.setState({ showAttachmentModal: true, id: id, documentUrls: documentUrls })
    }

    showNotesModal = (id, note) => {
        this.setState({ showNotesModal: true, id: id, note: note })
    }

    addLineItemRow = () => {
        var { debtOverview } = this.state;
        var newDebt = {
            id: debtOverview.length + 1,
            name: '',
            outstandingDebt: 0,
            totalOutstandingDebt: 0,
            collateralType: '',
            note: '',
            documentUrls: [],
            source: 'Manual'
        }

        /* debtOverview.map((lineItem, i) => {
             lineItem.id = i;
         });*/

        this.setState({ selectedDebtOverview: newDebt, showDrawer: true, newDebt: true, debtOverviewCopy: cloneDeep(debtOverview) });
        //this.setCheckMark();
    }

    EditLineItemRow = (lineItem) => {

        /* debtOverview.map((lineItem, i) => {
             lineItem.id = i;
         });*/

        this.setState({ selectedDebtOverview: lineItem, selectedDebtOverviewClone: cloneDeep(lineItem), showDrawer: true, newDebt: false, debtOverviewCopy: cloneDeep(this.state.debtOverview) });
        //this.setCheckMark();
    }

    saveLineItemRow = () => {
        var { debtOverview, selectedDebtOverview } = this.state;

        var isValidDebt = this.validateDebt();

        if (isValidDebt) {
            if (this.state.newDebt) {
                debtOverview.push(selectedDebtOverview);
            } else {
                var updatedDebtOverview = debtOverview.map(debt => {
                    if (debt.id == selectedDebtOverview.id) {
                        return selectedDebtOverview;
                    } else {
                        return debt;
                    }
                }) || [];
                debtOverview = updatedDebtOverview;
            }
            this.setState({ debtOverview: debtOverview, showDrawer: false, newDebt: false });
        }

        this.updateDebtSchedule(debtOverview, null);
        //this.setCheckMark();
    }

    setCheckMark = () => {
        var { debtOverview } = this.state;
        if (debtOverview.length > 0) {
            var systemDebtOverview = debtOverview.filter(x => x.source != 'Manual');
            if (systemDebtOverview.length > 0) {
                for (var i = 0; i < systemDebtOverview.length; i++) {
                    if ((debtOverview[i]['collateralType'] == '' || debtOverview[i]['collateralType'] == null)) {
                        this.setState({ showCheck: false });
                        return;
                    }
                    if (debtOverview[i]['name'] == '' || debtOverview[i]['name'] == null) {
                        this.setState({ showCheck: false });
                        return;
                    }
                    if (debtOverview[i]['outstandingDebt'] == '' || debtOverview[i]['outstandingDebt'] == null) {
                        this.setState({ showCheck: false });
                        return;
                    }
                    if (debtOverview[i]['totalOutstandingDebt'] == '' || debtOverview[i]['totalOutstandingDebt'] == null) {
                        this.setState({ showCheck: false });
                        return;
                    }
                }

                this.setState({ showCheck: true });
                return;
            } else {
                this.setState({ showCheck: true });
                return;
            }
        }
        else {
            this.setState({ showCheck: true });
        }
    }

    handleDeleteAttachment = (id, uploadItem) => {
        var { selectedDebtOverview } = this.state;
        message.success(`File Deleted successfully.`, 0.75)
        let removedList = selectedDebtOverview.documentUrls.filter(item => {
            if (item.fileName !== uploadItem.fileName) return item
        })

        selectedDebtOverview['documentUrls'] = removedList;

        this.setState({ selectedDebtOverview: selectedDebtOverview });
    }

    render() {
        var { flowView, currentViewIndex, loadPrevView, onboardingStore, readonly, theme, aionStore, isCreditPlus } = this.props;
        var { errorField, errorMessage, businessDocuments, showUploadMethodChangeModal, qbConnectLoading, debtOverview, noOutstandingDebt, showCheck, documentUrls, note, id, showSuccessBanner, selectedDebtOverview, accountingSysConnected } = this.state;
        var { businessInfo } = onboardingStore;
        debtOverview = debtOverview || []
        var prescreenDocs = (businessDocuments || []).filter(x => x.category == "PreScreen");

        var prescreenDocsuploaded = (businessDocuments || []).filter(x => x.category == "PreScreen");

        if (!businessInfo.closeBooksDate && readonly) {
            var { business } = aionStore || {};
            business = business || {};
            var { businessProfile } = business;
            var { businessInfo } = businessProfile || {};
            businessInfo = businessInfo || {}
        }

        documentUrls = documentUrls || []
        var debtColumns = [
            {
                title: <FlexColumn ><Text color='#444444' weight='600'>Creditor Name </Text><br /></FlexColumn>,
                dataIndex: 'name',
                key: 'name',
                width: 150,
            },
            {
                title: <FlexColumn ><Text color='#444444' weight='600'>Monthly Debt Payment </Text><Text size="12px" weight='400'>(Optional)</Text></FlexColumn>,
                dataIndex: 'outstandingDebt',
                key: 'outstandingDebt',
                render: (item) => '$' + toCurrency(item),
                width: 200,
            },
            {
                title: <FlexColumn ><Text color='#444444' weight='600'>Total Outstanding Balance </Text><Text size="12px" weight='400'>(Optional)</Text></FlexColumn>,
                dataIndex: 'totalOutstandingDebt',
                key: 'totalOutstandingDebt',
                render: (item) => '$' + toCurrency(item),
                width: 240,
            },
            {
                title: <FlexColumn ><Text color='#444444' weight='600'>Collateral Type  </Text><Text size="12px" weight='400'>(Optional)</Text></FlexColumn>,
                dataIndex: 'collateralType',
                key: 'collateralType',
                width: 140,
            },
            {
                title: <FlexColumn ><Text color='#444444' weight='600'>Note  </Text><Text size="12px" weight='400'>(Optional)</Text></FlexColumn>,
                dataIndex: 'note',
                key: 'note',
                width: 130,
            },
            {
                title: <FlexColumn ><Text color='#444444' weight='600'>Attachments  </Text><Text size="12px" weight='400'>(Optional)</Text></FlexColumn>,
                width: 250,
                render: (item) => item.documentUrls.map((data) => {
                    return (
                        <TextButton
                            preLine
                            style={{ textAlign: "left" }}
                            icon={<Image src={File} />}
                            onClick={() => this.downloadStatement(data.uri, data.fileName)}
                            text={data.fileName || ''} />
                    )
                }),
            }
        ]

        var uploadPropsAttachments = {
            name: `business-docs`,
            action: `${environment.apiBaseUrl}/financing/upload`,
            headers: {
                businesskey: this.props.aionStore.BusinessUniqueKey,
                jwt: this.props.aionStore.jwt
            },
            showUploadList: false,
            beforeUpload: (doc) => {
                var maxFileSize = 10240; // 10MB

                const uploadedFileSizeKiloBytes = doc.size / 1024

                if (uploadedFileSizeKiloBytes > maxFileSize) {
                    message.error(`File size is greater than 10MB`);
                    this.setState({ uploadLoading: false });

                    return false;
                }
            },
            onChange: (info) => {
                this.setState({ uploadLoading: true });
                var { debtOverview, selectedDebtOverview, documentUrls } = this.state;
                var savedFileList = selectedDebtOverview.documentUrls || []

                /*for (var i = 0; i < debtOverview.length; i++) {
                    if (debtOverview[i].id == selectedDebtOverview.id) {
                        savedFileList = debtOverview[i]['documentUrls'];
                    }
                }*/

                var file = info.file
                console.log("info file", file)
                var { status, response, name, url } = file
                if (status === "done") {
                    response = response || {}
                    savedFileList.push({
                        fileName: name,
                        uri: (response.SavedDocumentUrls || [])[0],
                        file: this.props.includebase64 ? file : null
                    })
                    this.setState({ uploadLoading: false })
                } else if (status === 'error') {
                    this.setState({ uploadLoading: false });

                    message.error(`${info.file.name} file upload failed.`);
                }
                if (!status) {
                    this.setState({ uploadLoading: false });
                }

                /*for (var i = 0; i < debtOverview.length; i++) {
                    if (debtOverview[i].id == selectedDebtOverview.id) {
                        debtOverview[i]['documentUrls'] = savedFileList.map(item => ({ ...item, url: item.uri }));
                        documentUrls = savedFileList.map(item => ({ ...item, url: item.uri }));
                        this.setState({ selectedDebtOverview: debtOverview[i] });
                    }
                }*/
                selectedDebtOverview['documentUrls'] = savedFileList.map(item => ({ ...item, url: item.uri }));
                this.setState({ debtOverview: debtOverview, selectedDebtOverview: selectedDebtOverview })
            },
            accept: ".jpeg,.jpg,.csv,.png,.doc,.xls,.xlsx,.pdf,.docx,.pptx,.txt"
        }

        const accountingSection = (<>
            <FlexColumn style={{ marginTop: "0", marginBottom: "12px" }}>
                <Text caption>ACCOUNTING INFORMATION</Text>
                <Text width='800px' margin='4px 0'>Help us understand your accounting cycle and financials.</Text>

                {/* <div style={{ height: 1, width: '100%', background: '#F2F2F2', margin: '0 0 12px' }} /> */}

                <Flex start top gap='12px'>
                    {/* <LabeledInput
        type="select"
        label='How would you like to connect?'
        width='288px'
        value={businessInfo.uploadMethod || 'QuickBooks Online'}
        onChange={uploadMethod => {
            this.saveUserInput('businessDocumentsuploadMethod', businessDocuments)
            if (uploadMethod === 'QuickBooks Online' && (businessDocuments && businessDocuments.length > 0)) {
                this.setState({ showUploadMethodChangeModal: true })
            } else {
                if (uploadMethod === 'Upload Manually' && (businessDocuments && businessDocuments.length > 0) && businessDocuments[0].documentUrl.fileName.includes('QBO_Generated')) {
                    this.discardFinancials()
                    this.setState({ businessDocuments: [] })
                }

                this.saveUserInput('uploadMethod', uploadMethod)
            }
        }}
    >
        <Option value="QuickBooks Online"><Flex start gap='8px' centerHorizontally><Image src={QB} />QuickBooks Online</Flex></Option>
        <Option value="Upload Manually">Upload Manually</Option>
    </LabeledInput> */}

                    <LabeledInput
                        labelcolor={this.props.theme.colors.secondary3}
                        label={<Flex between gap="35px"><Text color='#7384AA' size='14px' weight={400}>When did you last close your books?</Text>  <Tooltip title="Latest month end date when financial records were finalized and closed for a specific accounting period.">
                            <Image src={Info} />
                        </Tooltip></Flex>}
                        type="date-picker"
                        disabledDate={this.disabledDate} id="closeBooksDate"
                        format="ll"
                        width='295px'
                        onChange={(date, dateStr) => this.handleDateChange("closeBooksDate", dateStr)}
                        value={businessInfo.closeBooksDate && moment.utc(businessInfo.closeBooksDate)}
                        error={errorField == "closeBooksDate"}
                        errorMessage={errorMessage}
                        style={{ height: "32px" }}
                    />

                    {/* <LabeledInput
        labelcolor={this.props.theme.colors.secondary3}
        label="When does your fiscal year start?"
        type="date-picker"
        picker="month"
        id="fiscalYearStart"
        onChange={(date, dateStr) => this.handleFiscalYear("fiscalYearStart", dateStr, date)}
        format="MMM-YYYY"
        value={businessInfo.fiscalYearStart && moment.utc(businessInfo.fiscalYearStart)}
        placeholder="Pick a Month"
        width='288px'
        style={{ height: "32px" }}
        error={errorField == "fiscalYearStart"}
        errorMessage={errorMessage}
    /> */}

                    <LabeledInput
                        labelcolor={this.props.theme.colors.secondary3}
                        label={<Flex between gap="38px"><Text color='#7384AA' size='14px' weight={400}>When did your most recent fiscal year end?</Text>  <Tooltip title={`Fiscal year end is the specific date  when your financial accounting period concludes. It signifies the end of a 12-month financial cycle.`}>
                            <Image src={Info} />
                        </Tooltip></Flex>}
                        type="date-picker"
                        picker="month"
                        id="fiscalYearEnd"
                        disabledDate={this.disabledFiscalYearEndDate}
                        onChange={(date, dateStr) => this.handleFiscalYear("fiscalYearEnd", dateStr)}
                        format="MMM-YYYY"
                        value={businessInfo.fiscalYearEnd && moment.utc(businessInfo.fiscalYearEnd)}
                        placeholder="Pick a Month"
                        width='340px'
                        style={{ height: "32px" }}
                        error={errorField == "fiscalYearEnd"}
                        errorMessage={errorMessage}
                    />

                    {/* {
        businessInfo.uploadMethod !== 'Upload Manually' &&
        <Button loading={qbConnectLoading} solid primary height='40px' text='CONNECT' icon={<Image src={Connection} />} margin='44px 0 0' onClick={() => { this.connectQuickBooks() }} />
    } */}
                    {/* <Tooltip title={prescreenDocs && prescreenDocs.length > 0 ? "Remove your manual uploads to use this option" : !businessInfo.closeBooksDate || !businessInfo.fiscalYearEnd ? "This field will be enabled once you have the entered the required date fields" : ""}>
    <Button loading={qbConnectLoading} disabled={prescreenDocs && prescreenDocs.length > 0 || !businessInfo.closeBooksDate || !businessInfo.fiscalYearEnd} solid primary height='40px' text='CONNECT TO QUICKBOOKS ONLINE' icon={<Image src={Connection} />} margin='44px 0 0' onClick={() => { this.connectQuickBooks() }} />
</Tooltip>
<Tooltip title={!businessInfo.closeBooksDate || !businessInfo.fiscalYearEnd ? "This field will be enabled once you have the entered the required date fields" : ""}>
    <TextButton width="147px" size="16px" style={{ textAlign: "left", padding: "14px 14px 14px 0" }} type="link" margin='40px 0 0'
        onClick={() => this.onClickManualUploadModal()} disabled={!businessInfo.closeBooksDate || !businessInfo.fiscalYearEnd} text="UPLOAD MANUALLY"></TextButton>
</Tooltip> */}
                </Flex>
                {
                    businessInfo.closeBooksDate && businessInfo.fiscalYearEnd &&
                    businessDocuments && businessDocuments.length == 0 &&
                    <FlexColumn left style={{ margin: '16px 0' }} gap='8px'>
                        <Text margin='0px' color='#7384AA' weight={500}>{capitalize('CONNECT YOUR ACCOUNTING SYSTEM')}</Text>
                        <Text margin='0px 0 16px'>Connecting your accounting system enables instant offers on various financial products.</Text>
                        <AccountingSysConnect
                            location={this.props.location}
                            onAccountingSysConnected={(options) => {
                                console.log("onAccountingSysConnected", options)
                                this.setState({ accountingSysConnected: options.accountingSysConnected })
                            }}
                            showUploadOption
                            onClickManualUploadModal={() => this.onClickManualUploadModal()}
                            isCreditPlus={isCreditPlus}
                        />
                    </FlexColumn>
                }
                {/* <div style={{ height: 1, width: '100%', background: '#F2F2F2', margin: '12px 0 24px' }} /> */}
                <Modal
                    visible={this.state.showManualUploadModal}
                    footer={null}
                    closable={false}
                    width={1000}
                    destroyOnClose={true}
                    onCancel={() => {

                    }}
                // closeIcon={<img width='24px' height='24px' src={ModalClose} onClick={() => this.setState({ showManualUploadModal: false, uploadList: [], bankName: "" })} />}
                >
                    <FlexColumn start>
                        <Text weight="500" style={{ marginBottom: 5 }} size='20px'>UPLOAD FINANCIAL STATEMENTS</Text>
                        <Text margin='4px 0 24px'>Download, fill and upload the PDF template provided for each of the requested periods.</Text>

                        <>
                            <Flex fullWidth between>
                                <Text weight='500' color='#7384AA'>COMPANY PREPARED BALANCE SHEET</Text>
                                <Text primary underline><a href="/Balance_Sheet_Template_v_2023-01.pdf" download="Balance_Sheet_Template_v_2023-01.pdf"><Image src={DownloadIcon} /> Download PDF Template</a></Text>
                            </Flex>
                            <Flex style={{ justifyContent: "between", width: "100%", borderRadius: "8px", padding: "8px 0px", marginTop: 4 }} gap='24px'>
                                <FlexColumn start style={{ width: `${100 / 2}%` }}>
                                    {!this.getDocument(preScreenDocs[0].name).fileName ?
                                        <LabeledInput
                                            nomargin
                                            {...this.uploadProps(preScreenDocs[0], 0)}
                                            loading={this.state.uploadingItem === "uploading0" ? this.state.uploadLoading : false}
                                            type='upload'
                                            label='Most recent closed month'
                                            uploadText='Only PDF file types accepted. File size cannot exceed 10 M.B.'
                                        /> : <>
                                            <Flex start gap='4px'>
                                                <Text weight="400" color="#7384AA" style={{ fontSize: "14px", }} margin='0 0 4px'>Most recent closed month</Text>
                                                <Image width='5px' height='5px' margin='4px 0 0' src={Asterisk} />
                                            </Flex>
                                            <TextButton
                                                preLine
                                                style={{ textAlign: "left" }}
                                                icon={<Image src={File} />}
                                                onClick={() => this.downloadStatement(this.getDocument(preScreenDocs[0].name).uri, this.getDocument(preScreenDocs[0].name).fileName)}
                                                text={this.getDocument(preScreenDocs[0].name).fileName}
                                                deleteConfirmMessage={'Are you sure you want to delete this file?'}
                                                onDelete={() => this.handleDeleteDoc(this.getDocument(preScreenDocs[0].name, { businessDocument: true }))} />
                                        </>}
                                </FlexColumn>
                                <FlexColumn start style={{ width: `${100 / 2}%` }}>
                                    {!this.getDocument(preScreenDocs[1].name).fileName ?
                                        <LabeledInput
                                            nomargin
                                            {...this.uploadProps(preScreenDocs[1], 1)}
                                            loading={this.state.uploadingItem === "uploading1" ? this.state.uploadLoading : false}
                                            type='upload'
                                            label='Most recent fiscal year end'
                                            uploadText='Only PDF file types accepted. File size cannot exceed 10 M.B.'
                                        /> : <>
                                            <Flex start gap='4px'>
                                                <Text weight="400" color="#7384AA"
                                                    style={{ fontSize: "14px" }}
                                                    margin='0 0 4px'
                                                >
                                                    Most recent fiscal year end
                                                </Text>                                            <Image width='5px' height='5px' margin='4px 0 0' src={Asterisk} />
                                            </Flex>

                                            <TextButton
                                                preLine
                                                style={{ textAlign: "left" }}
                                                icon={<Image src={File} />}
                                                onClick={() => this.downloadStatement(this.getDocument(preScreenDocs[1].name).uri, this.getDocument(preScreenDocs[1].name).fileName)}
                                                text={this.getDocument(preScreenDocs[1].name).fileName}
                                                deleteConfirmMessage={'Are you sure you want to delete this file?'}
                                                onDelete={() => this.handleDeleteDoc(this.getDocument(preScreenDocs[1].name, { businessDocument: true }))} />

                                        </>}
                                </FlexColumn>
                            </Flex>

                            <div style={{ height: 1, width: '100%', background: '#F2F2F2', margin: '24px 0' }} />

                            <Flex fullWidth between>
                                <Text weight='500' color='#7384AA'>COMPANY PREPARED PROFIT & LOSS STATEMENT</Text>
                                <Text primary underline><a href="/Income Statement Template (Version 2023-01).pdf" download="Income Statement Template (Version 2023-01).pdf"><Image src={DownloadIcon} /> Download PDF Template</a></Text>
                            </Flex>

                            <Flex noMargin style={{ justifyContent: "between", width: "100%", borderRadius: "8px", padding: "8px 0px", marginTop: 4 }} gap='24px'>
                                <FlexColumn start style={{ width: `${100 / 2}%` }}>
                                    {!this.getDocument(preScreenDocs[2].name).fileName ?
                                        <LabeledInput
                                            nomargin
                                            {...this.uploadProps(preScreenDocs[2], 2)}
                                            loading={this.state.uploadingItem === "uploading2" ? this.state.uploadLoading : false}
                                            type='upload'
                                            label='YTD through most recent closed month'
                                            uploadText='Only PDF file types accepted. File size cannot exceed 10 M.B.'
                                        /> : <>
                                            <Flex start gap='4px'>
                                                <Text weight="400" color="#7384AA"
                                                    style={{ fontSize: "14px" }}
                                                    margin='0 0 4px'
                                                >YTD through most recent closed month</Text>
                                                <Image width='5px' height='5px' margin='4px 0 0' src={Asterisk} />
                                            </Flex>

                                            <TextButton
                                                preLine
                                                style={{ textAlign: "left" }}
                                                icon={<Image src={File} />}
                                                onClick={() => this.downloadStatement(this.getDocument(preScreenDocs[2].name).uri, this.getDocument(preScreenDocs[2].name).fileName)}
                                                text={this.getDocument(preScreenDocs[2].name).fileName}
                                                deleteConfirmMessage={'Are you sure you want to delete this file?'}
                                                onDelete={() => this.handleDeleteDoc(this.getDocument(preScreenDocs[2].name, { businessDocument: true }))} />

                                        </>}
                                </FlexColumn>
                                <FlexColumn start style={{ width: `${100 / 2}%` }}>
                                    {!this.getDocument(preScreenDocs[3].name).fileName ?
                                        <LabeledInput
                                            nomargin
                                            {...this.uploadProps(preScreenDocs[3], 3)}
                                            loading={this.state.uploadingItem === "uploading3" ? this.state.uploadLoading : false}
                                            type='upload'
                                            label='Full year for most recent fiscal year end'
                                            uploadText='Only PDF file types accepted. File size cannot exceed 10 M.B.'
                                        /> : <>
                                            <Flex start gap='4px'>
                                                <Text weight="400" color="#7384AA"
                                                    style={{ fontSize: "14px", }}
                                                    margin='0 0 4px'
                                                >Full year for most recent fiscal year end</Text>
                                                <Image width='5px' height='5px' margin='4px 0 0' src={Asterisk} />
                                            </Flex>

                                            <TextButton
                                                preLine
                                                style={{ textAlign: "left" }}
                                                icon={<Image src={File} />}
                                                onClick={() => this.downloadStatement(this.getDocument(preScreenDocs[3].name).uri, this.getDocument(preScreenDocs[3].name).fileName)}
                                                text={this.getDocument(preScreenDocs[3].name).fileName}
                                                deleteConfirmMessage={'Are you sure you want to delete this file?'}
                                                onDelete={() => this.handleDeleteDoc(this.getDocument(preScreenDocs[3].name, { businessDocument: true }))} />
                                        </>}
                                </FlexColumn>
                            </Flex>


                            <div style={{ height: 1, width: '100%', background: '#F2F2F2', margin: '24px 0' }} />

                            <Flex fullWidth between>
                                <FlexColumn>
                                    <Text weight='500' color='#7384AA'>AR AGING SUMMARY</Text>
                                    <Text margin='4px 0 24px' width="600px">Please upload a copy of the AR aging report as of your most recent financial closing month. Feel free to use the template provided as a reference.</Text>

                                </FlexColumn>
                                <Text primary underline><a href="/ARAgingSummary_Aion Template_Watermarked.pdf" download="ARAgingSummary Aion Template.pdf"><Image src={DownloadIcon} /> Download Template</a></Text>
                            </Flex>
                            <Flex noMargin style={{ justifyContent: "start", width: "100%", borderRadius: "8px", padding: "8px 0px", marginTop: 4 }} gap='24px'>
                                <FlexColumn start style={{ width: `60%` }}>
                                    {!this.getDocument(preScreenDocs[4].name).fileName ?
                                        <LabeledInput
                                            nomargin
                                            {...this.uploadProps(preScreenDocs[4], 4)}
                                            loading={this.state.uploadingItem === "uploading4" ? this.state.uploadLoading : false}
                                            type='upload'
                                            label='AR Aging Report'
                                            uploadText='Accepted file types include PDF, XLS and XLSX. File size cannot exceed 10 M.B.'
                                            accept=".pdf,.xlsx"
                                            optional
                                        /> : <>
                                            <Text weight="400" color="#7384AA"
                                                style={{ fontSize: "14px" }}
                                                margin='0 0 4px'
                                            >AR Aging Report</Text>
                                            <TextButton
                                                preLine
                                                style={{ textAlign: "left" }}
                                                icon={<Image src={File} />}
                                                onClick={() => this.downloadStatement(this.getDocument(preScreenDocs[4].name).uri, this.getDocument(preScreenDocs[4].name).fileName)}
                                                text={this.getDocument(preScreenDocs[4].name).fileName}
                                                deleteConfirmMessage={'Are you sure you want to delete this file?'}
                                                onDelete={() => this.handleDeleteDoc(this.getDocument(preScreenDocs[4].name, { businessDocument: true }))} />

                                        </>}
                                </FlexColumn>
                            </Flex>
                        </>
                        <Button weight='500' width='119px' margin="24px 0 0 0" onClick={this.onUploadBankStatementSubmit} text="CLOSE" solid radius='4px' />
                    </FlexColumn>
                </Modal>
            </FlexColumn>

            {
                businessDocuments && businessDocuments.length >= 1 && businessInfo.uploadMethod === 'Upload Manually' && businessDocuments.length > 0 &&
                <>{businessDocuments.length < 5 && businessDocuments.length > 0 ?
                    <Flex start gap='5px' style={{ margin: '12px 0 24px' }}>
                        <Text> Make sure to upload all required documents. </Text>
                        <StyledExtLink
                            key="StyledExtLink"
                            style={{ textAlign: "center", fontSize: "16px", lineHeight: "24px", textDecoration: "underline" }}
                            onClick={() => this.onClickManualUploadModal()}
                        >
                            Click to upload
                        </StyledExtLink>
                    </Flex> : null}

                    <Flex fullWidth between>
                        <Text weight='500' color='#7384AA'>COMPANY PREPARED BALANCE SHEET</Text>
                        {/* <Text primary underline><a href="/Balance_Sheet_Template_v_2023-01.pdf" download="Balance_Sheet_Template_v_2023-01.pdf"><Image src={DownloadIcon} /> Download Template</a></Text> */}
                    </Flex>
                    <Flex style={{ justifyContent: "between", width: "100%", borderRadius: "8px", padding: "8px 0px", marginTop: 4 }} gap='24px'>
                        <FlexColumn start style={{ width: `${100 / 2}%` }}>
                            {!this.getDocument(preScreenDocs[0].name).fileName ?
                                <>
                                    <Flex start gap='4px'>
                                        <Text weight="400" color="#7384AA" style={{ fontSize: "14px", }} margin='0 0 4px'>Most recent closed month</Text>
                                        <Image width='5px' height='5px' margin='4px 0 0' src={Asterisk} />
                                    </Flex>

                                    <Text width='600px' margin='4px 0 24px'>None</Text>
                                </>
                                : <>
                                    <Flex start gap='4px'>
                                        <Text weight="400" color="#7384AA" style={{ fontSize: "14px", }} margin='0 0 4px'>Most recent closed month</Text>
                                        <Image width='5px' height='5px' margin='4px 0 0' src={Asterisk} />
                                    </Flex>
                                    <TextButton
                                        preLine
                                        style={{ textAlign: "left" }}
                                        icon={<Image src={File} />}
                                        onClick={() => this.downloadStatement(this.getDocument(preScreenDocs[0].name).uri, this.getDocument(preScreenDocs[0].name).fileName)}
                                        text={this.getDocument(preScreenDocs[0].name).fileName}
                                        deleteConfirmMessage={'Are you sure you want to delete this file?'}
                                        onDelete={() => this.handleDeleteDoc(this.getDocument(preScreenDocs[0].name, { businessDocument: true }))}
                                    />
                                </>}
                        </FlexColumn>
                        <FlexColumn start style={{ width: `${100 / 2}%` }}>
                            {!this.getDocument(preScreenDocs[1].name).fileName ?
                                <>
                                    <Flex start gap='4px'>
                                        <Text weight="400" color="#7384AA"
                                            style={{ fontSize: "14px" }}
                                            margin='0 0 4px'
                                        >
                                            Most recent fiscal year end
                                        </Text>
                                        <Image width='5px' height='5px' margin='4px 0 0' src={Asterisk} />
                                    </Flex>

                                    <Text width='600px' margin='4px 0 24px'>None</Text>
                                </>
                                : <>
                                    <Flex start gap='4px'>
                                        <Text weight="400" color="#7384AA"
                                            style={{ fontSize: "14px" }}
                                            margin='0 0 4px'
                                        >
                                            Most recent fiscal year end
                                        </Text>
                                        <Image width='5px' height='5px' margin='4px 0 0' src={Asterisk} />
                                    </Flex>
                                    <TextButton
                                        preLine
                                        style={{ textAlign: "left" }}
                                        icon={<Image src={File} />}
                                        onClick={() => this.downloadStatement(this.getDocument(preScreenDocs[1].name).uri, this.getDocument(preScreenDocs[1].name).fileName)}
                                        text={this.getDocument(preScreenDocs[1].name).fileName}
                                        deleteConfirmMessage={'Are you sure you want to delete this file?'}
                                        onDelete={() => this.handleDeleteDoc(this.getDocument(preScreenDocs[1].name, { businessDocument: true }))}
                                    />

                                </>}
                        </FlexColumn>
                    </Flex>

                    <div style={{ height: 1, width: '100%', background: '#F2F2F2', margin: '12px 0 24px 0' }} />

                    <Flex fullWidth between>
                        <Text weight='500' color='#7384AA'>COMPANY PREPARED PROFIT & LOSS STATEMENT</Text>
                        {/* <Text primary underline><a href="/Income Statement Template (Version 2023-01).pdf" download="Income Statement Template (Version 2023-01).pdf"><Image src={DownloadIcon} /> Download Template</a></Text> */}
                    </Flex>

                    <Flex noMargin style={{ justifyContent: "between", width: "100%", borderRadius: "8px", padding: "8px 0px", marginTop: 4 }} gap='24px'>
                        <FlexColumn start style={{ width: `${100 / 2}%` }}>
                            {!this.getDocument(preScreenDocs[2].name).fileName ?
                                <>
                                    <Flex start gap='4px'>
                                        <Text weight="400" color="#7384AA"
                                            style={{ fontSize: "14px" }}
                                            margin='0 0 4px'
                                        >YTD through most recent closed month</Text><Image width='5px' height='5px' margin='4px 0 0' src={Asterisk} />
                                    </Flex>

                                    <Text width='600px' margin='4px 0 24px'>None</Text>
                                </>
                                : <>
                                    <Flex start gap='4px'>
                                        <Text weight="400" color="#7384AA"
                                            style={{ fontSize: "14px" }}
                                            margin='0 0 4px'
                                        >YTD through most recent closed month</Text><Image width='5px' height='5px' margin='4px 0 0' src={Asterisk} />
                                    </Flex>
                                    <TextButton
                                        preLine
                                        style={{ textAlign: "left" }}
                                        icon={<Image src={File} />}
                                        onClick={() => this.downloadStatement(this.getDocument(preScreenDocs[2].name).uri, this.getDocument(preScreenDocs[2].name).fileName)}
                                        text={this.getDocument(preScreenDocs[2].name).fileName}
                                        deleteConfirmMessage={'Are you sure you want to delete this file?'}
                                        onDelete={() => this.handleDeleteDoc(this.getDocument(preScreenDocs[2].name, { businessDocument: true }))}
                                    />

                                </>}
                        </FlexColumn>
                        <FlexColumn start style={{ width: `${100 / 2}%` }}>
                            {!this.getDocument(preScreenDocs[3].name).fileName ?
                                <>
                                    <Flex start gap='4px'>
                                        <Text weight="400" color="#7384AA"
                                            style={{ fontSize: "14px", }}
                                            margin='0 0 4px'
                                        >Full year for most recent fiscal year end</Text>
                                        <Image width='5px' height='5px' margin='4px 0 0' src={Asterisk} />
                                    </Flex>

                                    <Text width='600px' margin='4px 0 24px'>None</Text>
                                </>
                                : <>
                                    <Flex start gap='4px'>
                                        <Text weight="400" color="#7384AA"
                                            style={{ fontSize: "14px", }}
                                            margin='0 0 4px'
                                        >Full year for most recent fiscal year end</Text>
                                        <Image width='5px' height='5px' margin='4px 0 0' src={Asterisk} />
                                    </Flex>
                                    <TextButton
                                        preLine
                                        style={{ textAlign: "left" }}
                                        icon={<Image src={File} />}
                                        onClick={() => this.downloadStatement(this.getDocument(preScreenDocs[3].name).uri, this.getDocument(preScreenDocs[3].name).fileName)}
                                        text={this.getDocument(preScreenDocs[3].name).fileName}
                                        deleteConfirmMessage={'Are you sure you want to delete this file?'}
                                        onDelete={() => this.handleDeleteDoc(this.getDocument(preScreenDocs[3].name, { businessDocument: true }))}
                                    />
                                </>}
                        </FlexColumn>
                    </Flex>
                    {
                        (flowView && flowView.FlowStep == "Apply.Documents" && !this.getDocument(preScreenDocs[4].name).fileName) ? null :
                            <>
                                <div style={{ height: 1, width: '100%', background: '#F2F2F2', margin: '24px 0' }} />

                                <Flex fullWidth between>
                                    <Text weight='500' color='#7384AA'>AR AGING SUMMARY</Text>
                                </Flex>

                                <Flex noMargin style={{ justifyContent: "start", width: "100%", borderRadius: "8px", padding: "8px 0px", marginTop: 4 }} gap='24px'>
                                    <FlexColumn start style={{ width: `${100 / 2}%` }}>
                                        {!this.getDocument(preScreenDocs[4].name).fileName ?
                                            <>
                                                <Text weight="400" color="#7384AA"
                                                    style={{ fontSize: "14px" }}
                                                    margin='0 0 4px'
                                                >AR Aging Report</Text>
                                                <Text width='600px' margin='4px 0 24px'>None</Text>
                                            </>
                                            : <>
                                                <Text weight="400" color="#7384AA"
                                                    style={{ fontSize: "14px" }}
                                                    margin='0 0 4px'
                                                >AR Aging Report</Text>
                                                <TextButton
                                                    preLine
                                                    style={{ textAlign: "left" }}
                                                    icon={<Image src={File} />}
                                                    onClick={() => this.downloadStatement(this.getDocument(preScreenDocs[4].name).uri, this.getDocument(preScreenDocs[4].name).fileName)}
                                                    text={this.getDocument(preScreenDocs[4].name).fileName}
                                                    deleteConfirmMessage={'Are you sure you want to delete this file?'}
                                                    onDelete={() => this.handleDeleteDoc(this.getDocument(preScreenDocs[4].name, { businessDocument: true }))}
                                                />

                                            </>}
                                    </FlexColumn>
                                </Flex></>
                    }

                </>
            }
        </>
        )

        var childViews = (
            <FlexColumn start style={{ minHeight: isCreditPlus ? '60vh' : '' }}>
                <FlexColumn style={{ marginTop: "0px" }}>
                    <FlexColumn style={{ width: "100%", margin: "12px 0 24px" }}>
                        {
                           /* (prescreenDocsuploaded && prescreenDocsuploaded.length >= 1 ) &&
                            <LightContainer shadow margin="12px 0 12px 0" padding="5px 16px" style={{ cursor: 'pointer' }}>
                                <FlexColumn start>
                                    <Flex between centerHorizontally onClick={() => this.setState({ isDocUploadsActive: !this.state.isDocUploadsActive })} fullWidth style={{ height: '56px' }}>
                                        <Flex center gap='8px'>
                                            <Text >Document Uploads</Text>
                                        </Flex>
                                        <Flex center gap='24px'>
                                            <ImageButton src={DownIcon} transform={`rotate(${this.state.isDocUploadsActive ? 180 : 0}deg)`} />
                                        </Flex>
                                    </Flex>
                                    {
                                        this.state.isDocUploadsActive &&
                                        <>
                                            <Divider style={{ margin: '0 0 24px 0' }} />

                                            {accountingSection}
                                        </>
                                    }
                                </FlexColumn>
                            </LightContainer>
                                */}

                        {/*!(prescreenDocsuploaded && prescreenDocsuploaded.length >= 1 ) &&
                            <>
                                {accountingSection}
                    </>*/}
                        {/* {accountingSection}
                        <Divider style={{ margin: '12px 0 24px 0' }} /> */}
                        {
                            // businessInfo.capitalRequest < this.state.gnplHigh &&
                            <>
                                <Text margin="0px 0px 4px 0px" weight='500' spacing="0.08em" color='#7384AA'>DEBT OVERVIEW</Text>
                                <Text size="16px" width='800px' margin="4px 0 12px 0">Please provide details on any active outstanding debt for your business.</Text>
                                {
                                    (!noOutstandingDebt || debtOverview.length == 0) &&
                                   /* <Checkbox style={{ width: 450, marginTop: 15, marginBottom: "12px" }} onChange={(e) => this.setState({ noOutstandingDebt: e.target.checked })} checked={noOutstandingDebt}>{businessInfo.name ? businessInfo.name + " has no outstanding debt" : "Business has no outstanding debt"}</Checkbox>
*/<LabeledInput
                                        margin='0px'
                                        label='Do you have any Outstanding debt?'
                                        type='switch'
                                        switchNames={['Yes', 'No']}
                                        onChange={(e) => {
                                            if (e == 'Yes') {
                                                this.setState({ noOutstandingDebt: e == "Yes", isDebtInfoActive: true })
                                            } else {
                                                var debtFromIlien = debtOverview.filter(debt => debt.source != "Manual");
                                                var otherDebts = debtOverview.filter(debt => debt.source == "Manual");
                                                if (debtFromIlien.length == 0 && otherDebts.length == 0) {
                                                    this.setState({ noOutstandingDebt: e == "Yes" })
                                                }
                                            }
                                            this.updateDebtSchedule(debtOverview, e == 'Yes');

                                        }}
                                        value={(typeof (noOutstandingDebt) == 'boolean') ? (noOutstandingDebt ? 'Yes' : 'No') : null}
                                        width="288px"
                                        error={errorField == "noOutstandingDebt"}
                                        errorMessage={errorMessage}
                                    />
                                }



                                {(noOutstandingDebt != null && noOutstandingDebt != false) ?
                                    <LightContainer shadow margin="12px 0 12px 0" padding="5px 16px" >
                                        <FlexColumn start>
                                            <Flex between centerHorizontally onClick={() => this.setState({ isDebtInfoActive: !this.state.isDebtInfoActive })} fullWidth style={{ height: '56px', cursor: "pointer" }}>
                                                <Flex center gap='8px'>
                                                    {/*{showCheck ? <Image margin='0 12px 5px' src={CollapseCheck} /> : <WarningFilled style={{ color: theme.colors.warning, fontSize: '18px', margin: '0 12px 5px' }} />}*/}
                                                    <Text >Debt Information</Text>
                                                </Flex>
                                                <Flex center gap='24px'>
                                                    <ImageButton src={DownIcon} transform={`rotate(${this.state.isDebtInfoActive ? 180 : 0}deg)`} />
                                                </Flex>
                                            </Flex>
                                            {
                                                this.state.isDebtInfoActive &&
                                                <>
                                                    <Banner
                                                        borderColor="#D7DCFB"
                                                        background="#F5F6FE"
                                                        style={{ marginBottom: "6px" }}
                                                        message={`Providing supporting information upfront will help us evaluate your application faster.`}
                                                    />
                                                    {


                                                        <>
                                                            <LineItemsHeader>
                                                                <Flex style={{ width: "100%", marginBottom: "12px" }} gap="12px" >
                                                                    <div style={{ width: "75%", align: "center" }} ><Text color='#444444' weight='600'>Creditor Name </Text><Text size="12px" weight='400'></Text></div>
                                                                    {/* <div style={{ width: "23%" }}><Text color='#444444' weight='600'>Total Outstanding Balance <Text size="12px" weight='400'>(Optional)</Text></Text></div>
                                                                    <div style={{ width: "22%" }}><Text color='#444444' weight='600'>Monthly Debt Payment <Text size="12px" weight='400'>(Optional)</Text></Text></div>
                                                                    <div style={{ width: "16%" }}><Text color='#444444' weight='600'>Collateral Type <Text size="12px" weight='400'>(Optional)</Text></Text></div>
                                                                    <div style={{ width: "19%" }}><Text color='#444444' weight='600'>Note (Optional)</Text></div> */}
                                                                    <div style={{ width: "25%" }} ><Text color='#444444' weight='600'>Supporting Information</Text></div>
                                                                </Flex>
                                                            </LineItemsHeader>
                                                            {
                                                                (debtOverview.length > 0) &&
                                                                <FlexColumn style={{ marginTop: 12 }}>
                                                                    {
                                                                        debtOverview.map((lineItem, i) => {
                                                                            var { id, name, outstandingDebt, totalOutstandingDebt, collateralType, note, source, documentUrls } = lineItem || {}
                                                                            documentUrls = documentUrls || [];
                                                                            var itemId = i;
                                                                            return (
                                                                                <Flex style={{ width: "100%", marginBottom: "12px" }} gap="24px" key={itemId + "container"} >
                                                                                    <div style={{ width: "25%", marginLeft: "8px" }}>
                                                                                        <Text color='#444444' weight='400'>{name}</Text>
                                                                                    </div>
                                                                                    <div style={{ width: "25%" }}>
                                                                                        {/*} <LabeledInput
                                                                                        small
                                                                                        nomargin
                                                                                        key={itemId + "totalOutstandingDebt"}
                                                                                        id="totalOutstandingDebt"
                                                                                        value={totalOutstandingDebt && toCurrency(totalOutstandingDebt, 0, 0)}
                                                                                        symbolprefix="$"
                                                                                        placeholder="0.00"
                                                                                        error={errorField == itemId + "totalOutstandingDebt"}
                                                                                        errorMessage={errorMessage}
                                                                                        onChange={e => { this.saveRowUserInput(id, "totalOutstandingDebt", e.target.value == '' ? null : parseFloat(toCurrency(e.target.value, 0, 0).replaceAll(',', ''))) }}
                                                                        />*/}
                                                                                    </div>
                                                                                    <div style={{ width: "18%" }}>
                                                                                        {/*  <LabeledInput
                                                                                        small
                                                                                        nomargin
                                                                                        key={itemId + "outstandingDebt"}
                                                                                        id="outstandingDebt"
                                                                                        value={outstandingDebt && toCurrency(outstandingDebt, 0, 0)}
                                                                                        symbolprefix="$"
                                                                                        placeholder="0.00"
                                                                                        error={errorField == itemId + "outstandingDebt"}
                                                                                        errorMessage={errorMessage}
                                                                                        onChange={e => { this.saveRowUserInput(id, "outstandingDebt", e.target.value == '' ? null : parseFloat(toCurrency(e.target.value, 0, 0).replaceAll(',', ''))) }}
                                                                    />*/}
                                                                                    </div>
                                                                                    {/* <div style={{ width: "26%" }}>
                                                                                   } <LabeledInput
                                                                                        small
                                                                                        nomargin
                                                                                        type="select"
                                                                                        className="no-left-padding"
                                                                                        value={collateralType}
                                                                                        placeholder="Select a transfer method"
                                                                                        style={{ marginRight: 10, fontSize: "1.0rem", paddingLeft: 0, marginLeft: 0, width: "200px!important" }}
                                                                                        error={errorField == itemId + "collateralType"}
                                                                                        errorMessage={errorMessage}
                                                                                        onChange={(value) => { this.saveRowUserInput(id, "collateralType", value) }}
                                                                                    >
                                                                                        <Option id="paymentType" value="Unsecured">Unsecured</Option>
                                                                                        <Option id="paymentType" value="All Business Assets">All Business Assets</Option>
                                                                                        <Option id="paymentType" value="Accounts Receivable">Accounts Receivable</Option>
                                                                                        <Option id="paymentType" value="Inventory">Inventory</Option>
                                                                                        <Option id="paymentType" value="Titled Vehicle">Titled Vehicle</Option>
                                                                                        <Option id="paymentType" value="Titled Equipment">Titled Equipment</Option>
                                                                                        <Option id="paymentType" value="Commercial Real Estate">Commercial Real Estate</Option>
                                                                                        <Option id="paymentType" value="Residential Real Estate">Residential Real Estate</Option>
                                                                                        <Option id="paymentType" value="Other">Other</Option>
                                                                </LabeledInput>
                                                                                </div>*/}

                                                                                    {/* <div style={{ width: "24%", paddingRight: 24 }}>
                                                               <LabeledInput
                                                                   small
                                                                   nomargin
                                                                   key="note"
                                                                   id="note"
                                                                   value={note}
                                                                   placeholder="Note"
                                                                   onChange={e => { this.saveRowUserInput(id, "note", e.target.value) }}
                                                               />
                                                           </div> */}
                                                                                    <Flex start style={{ width: "25%" }}>
                                                                                        {
                                                                                            <> {/*
                                                                                            <TextButton onClick={() => this.showAttachmentModal(id, documentUrls)} rightIcon={<PaperClipOutlined style={{ fontSize: 18 }} />} text={((documentUrls.length == 0) ? "Add Document" : documentUrls.length + ` Document${documentUrls.length > 1 ? 's' : ''}`)} />
                                                                                            <TextButton onClick={() => this.showNotesModal(id, note)} style={{ margin: 0 }} rightIcon={<img width='18px' height='18px' style={{ marginBottom: 5 }} src={MessageIcon} />} />
                                                                                            {
                                                                                                source == 'Manual' && <Popconfirm
                                                                                                    title="Are you sure you want to delete this Outstanding Debt?"
                                                                                                    onConfirm={() => this.removeLineItemRow(id)}
                                                                                                    okText="Confirm"
                                                                                                    cancelText="Cancel"
                                                                                                >
                                                                                                    <TextButton rightIcon={<DeleteOutlined style={{ fontSize: 18 }} />} />
                                                                                                </Popconfirm>
                                                                                            }*/}

                                                                                                <TextButton onClick={() => this.EditLineItemRow(lineItem)} rightIcon={<PlusOutlined />} text={"Add Supporting Information"} />
                                                                                            </>
                                                                                        }
                                                                                    </Flex>
                                                                                </Flex>
                                                                            );
                                                                        })
                                                                    }
                                                                </FlexColumn>
                                                            }

                                                        </>
                                                    }
                                                    <TextButton onClick={() => this.addLineItemRow()} margin="6px 0 12px 12px" rightIcon={<PlusOutlined />} text={"Add debt info"} />
                                                </>
                                            }

                                            <Drawer placement="right" width={492}
                                                closeIcon={
                                                    <Popconfirm
                                                        title="Are you sure you want to exit?"
                                                        onConfirm={() => {
                                                            this.setState({ showDrawer: false, selectedDebtOverview: this.state.selectedDebtOverviewClone, debtOverview: this.state.debtOverviewCopy, errorField: "", errorMessage: "" });
                                                        }}
                                                        okText="Confirm"
                                                        cancelText="Cancel"
                                                        placement="bottomRight"
                                                    >
                                                        <img width='24px' height='24px' src={ModalClose} style={{ marginRight: "18px" }} />
                                                    </Popconfirm>
                                                }

                                                visible={this.state.showDrawer}>
                                                <FlexColumn start gap="24px" fullHeight style={{ marginBottom: 24 }}>

                                                    {
                                                        selectedDebtOverview.source == "Manual" ?
                                                            <FlexColumn>
                                                                <Text heading style={{ margin: "-10px 0 24px 0" }}>Debt Details</Text>

                                                                <LabeledInput
                                                                    small
                                                                    nomargin
                                                                    id="name"
                                                                    label="Creditor Institution"
                                                                    key={selectedDebtOverview.id + "name"}
                                                                    placeholder="Provide name of the institution you owe this debt to"
                                                                    value={selectedDebtOverview.name}
                                                                    error={errorField == "name"}
                                                                    errorMessage={errorMessage}
                                                                    disabled={selectedDebtOverview.source != 'Manual' ? true : false}
                                                                    onChange={e => { this.saveRowUserInput(id, "name", e.target.value) }}
                                                                />
                                                            </FlexColumn>

                                                            :
                                                            <Text heading>{selectedDebtOverview.name}</Text>
                                                    }
                                                    {
                                                        selectedDebtOverview.source != "Manual" &&
                                                        <LabeledInput
                                                            margin='0px'
                                                            label='Have you paid off this debt?'
                                                            type='switch'
                                                            switchNames={['Yes', 'No']}
                                                            onChange={(value) => this.saveRowUserInput(id, "paidOffDebt", (value == 'Yes'))}
                                                            value={((typeof (selectedDebtOverview.paidOffDebt) == 'boolean') ? (selectedDebtOverview.paidOffDebt ? 'Yes' : 'No') : null)}
                                                            error={errorField == "paidOffDebt"}
                                                            errorMessage={errorMessage}
                                                            width="288px"
                                                        />
                                                    }
                                                    {
                                                        ((selectedDebtOverview.source != "Manual" && !selectedDebtOverview.paidOffDebt) || selectedDebtOverview.source == "Manual") &&
                                                        <LabeledInput
                                                            small
                                                            nomargin
                                                            key={"totalOutstandingDebt"}
                                                            id="totalOutstandingDebt"
                                                            label="Outstanding Balance"
                                                            value={selectedDebtOverview.totalOutstandingDebt && toCurrency(selectedDebtOverview.totalOutstandingDebt, 0, 0)}
                                                            symbolprefix="$"
                                                            placeholder="0.00"
                                                            optional
                                                            error={errorField == selectedDebtOverview.id + "totalOutstandingDebt"}
                                                            errorMessage={errorMessage}
                                                            onChange={e => { this.saveRowUserInput(id, "totalOutstandingDebt", e.target.value == '' ? null : parseFloat(toCurrency(e.target.value, 0, 0).replaceAll(',', ''))) }}
                                                        />
                                                    }
                                                    {
                                                        ((selectedDebtOverview.source != "Manual" && !selectedDebtOverview.paidOffDebt) || selectedDebtOverview.source == "Manual") &&
                                                        <LabeledInput
                                                            small
                                                            nomargin
                                                            key={"outstandingDebt"}
                                                            id="outstandingDebt"
                                                            label="Monthly Debt Payment"
                                                            value={selectedDebtOverview.outstandingDebt && toCurrency(selectedDebtOverview.outstandingDebt, 0, 0)}
                                                            symbolprefix="$"
                                                            placeholder="0.00"
                                                            optional
                                                            error={errorField == selectedDebtOverview.id + "outstandingDebt"}
                                                            errorMessage={errorMessage}
                                                            onChange={e => { this.saveRowUserInput(selectedDebtOverview.id, "outstandingDebt", e.target.value == '' ? null : parseFloat(toCurrency(e.target.value, 0, 0).replaceAll(',', ''))) }}
                                                        />
                                                    }
                                                    {
                                                        ((selectedDebtOverview.source != "Manual" && !selectedDebtOverview.paidOffDebt) || selectedDebtOverview.source == "Manual") &&
                                                        <LabeledInput
                                                            small
                                                            nomargin
                                                            type="select"
                                                            allowClear={true}
                                                            className="no-left-padding"
                                                            label="Collateral type"
                                                            padded
                                                            value={selectedDebtOverview.collateralType}
                                                            placeholder="Select a collateral type"
                                                            optional
                                                            style={{ fontSize: "1.0rem", paddingLeft: 0, marginLeft: 0, width: "200px!important" }}
                                                            error={errorField == selectedDebtOverview.id + "collateralType"}
                                                            errorMessage={errorMessage}
                                                            onChange={(value) => { this.saveRowUserInput(id, "collateralType", value) }}
                                                        >
                                                            <Option id="paymentType" value="Unsecured">Unsecured</Option>
                                                            <Option id="paymentType" value="All Business Assets">All Business Assets</Option>
                                                            <Option id="paymentType" value="Accounts Receivable">Accounts Receivable</Option>
                                                            <Option id="paymentType" value="Inventory">Inventory</Option>
                                                            <Option id="paymentType" value="Titled Vehicle">Titled Vehicle</Option>
                                                            <Option id="paymentType" value="Titled Equipment">Titled Equipment</Option>
                                                            <Option id="paymentType" value="Commercial Real Estate">Commercial Real Estate</Option>
                                                            <Option id="paymentType" value="Residential Real Estate">Residential Real Estate</Option>
                                                            <Option id="paymentType" value="Other">Other</Option>
                                                        </LabeledInput>
                                                    }
                                                    <FlexColumn>
                                                        <LabeledInput
                                                            nomargin
                                                            {...uploadPropsAttachments}
                                                            //loading={this.state.uploadingItem === "uploading0" ? this.state.uploadLoading : false}
                                                            type='upload'
                                                            label='Supporting documentation'
                                                            optional
                                                            loading={this.state.uploadLoading}
                                                            uploadText={selectedDebtOverview.source == "Manual" ? 'File size cannot exceed 10 M.B.' : selectedDebtOverview.paidOffDebt ? "Attach documents indicating termination or closure of this debt. File size cannot exceed 10 M.B." : 'File size cannot exceed 10 M.B.'}
                                                        />
                                                        {
                                                            (selectedDebtOverview.documentUrls || []).length > 0 &&
                                                            <Flex wrap start style={{ flexWrap: "wrap", padding: "4px 4px 0 4px", marginTop: documentUrls && documentUrls.length > 0 ? 8 : 0, gap: 12 }}>
                                                                {
                                                                    selectedDebtOverview.documentUrls && selectedDebtOverview.documentUrls.map((file, i) => {
                                                                        return (
                                                                            <TextButton
                                                                                preLine
                                                                                noBorder height='32px' padding='4px 8px' radius='4px' background='#F8F8F8' key={i}
                                                                                icon={<FilePdfOutlined style={{ fontSize: 14 }} />} rightIcon={<CloseOutlined style={{ fontSize: 14 }} onClick={() => this.handleDeleteAttachment(id, file)} />} text={file.fileName} />
                                                                        )
                                                                    })
                                                                }
                                                            </Flex>
                                                        }
                                                    </FlexColumn>
                                                    <LabeledInput
                                                        small
                                                        nomargin
                                                        optional
                                                        key="note"
                                                        id="note"
                                                        uploadText="Adding supporting details such as forgiveness, ongoing forbearance, etc. will help us better evaluate your application"
                                                        label={selectedDebtOverview.source == "Manual" ? "Add a note" : selectedDebtOverview.paidOffDebt ? "Provide some additional context when you paid off your debt" : "Add a note"}
                                                        value={selectedDebtOverview.note}
                                                        placeholder={selectedDebtOverview.source == "Manual" ? "Add any supporting details" : selectedDebtOverview.paidOffDebt ? "Add details on when you paid off this debt" : "Add any supporting details"}
                                                        onChange={e => { this.saveRowUserInput(selectedDebtOverview.id, "note", e.target.value) }}
                                                    />

                                                    <FlexColumn end grow>
                                                        <Flex between gap='24px' centerHorizontally>
                                                            <Button onClick={() => { this.saveLineItemRow() }} solid text={'SAVE'} />

                                                            {
                                                                (selectedDebtOverview.source == 'Manual' && !this.state.newDebt) && <Popconfirm
                                                                    title="Are you sure you want to delete this Outstanding Debt?"
                                                                    onConfirm={() => this.removeLineItemRow(selectedDebtOverview.id)}
                                                                    okText="Confirm"
                                                                    cancelText="Cancel"
                                                                    placement="topRight"
                                                                >
                                                                    <ImageButton onClick={() => this.setState({ showDelete: true })} src={Delete} />

                                                                </Popconfirm>
                                                            }
                                                        </Flex>
                                                    </FlexColumn>
                                                </FlexColumn>
                                            </Drawer>
                                        </FlexColumn>
                                    </LightContainer> : null
                                }
                                <Modal
                                    visible={this.state.showAttachmentModal}
                                    footer={null}
                                    closable={true}
                                    width={700}
                                    destroyOnClose={true}
                                    onCancel={() => {
                                        this.setState({ showAttachmentModal: false });
                                    }}
                                >
                                    <FlexColumn start>
                                        <Text weight="500" style={{ marginBottom: 20 }} size='20px'>Supporting Documents (Optional)</Text>
                                        <Text margin='4px 0 24px'>Upload any pertinent documents corroborating the status and conditions of your debt. Provide any supporting documents such as loan agreements, forebearance agreements, promissory notes etc. </Text>
                                        <Upload
                                            {...uploadPropsAttachments}
                                        >
                                            <TextButton loading={this.state.uploading} text={"Add Attachments"} icon={<PlusOutlined style={{ fontSize: 14, width: 24, height: 24, margin: '0 12px' }} />} />
                                        </Upload>
                                        <div style={{
                                            maxWidth: "526px"
                                        }}>
                                            <Flex wrap start style={{ flexWrap: "wrap", padding: "4px", marginTop: documentUrls && documentUrls.length > 0 ? 8 : 0, gap: 12 }}>
                                                {
                                                    documentUrls && documentUrls.map((file, i) => {
                                                        return (
                                                            <TextButton
                                                                noBorder height='32px' padding='4px 8px' radius='4px' background='#F8F8F8' key={i}
                                                                icon={<FilePdfOutlined style={{ fontSize: 14 }} />} rightIcon={<CloseOutlined style={{ fontSize: 14 }} onClick={() => this.handleDeleteAttachment(id, file)} />} text={file.fileName} />
                                                        )
                                                    })
                                                }
                                            </Flex>
                                        </div>
                                        <Button weight='500' width='119px' margin="24px 0 0 0" onClick={() => {
                                            this.setState({ showAttachmentModal: false });
                                        }} text="CLOSE" solid radius='4px' />
                                    </FlexColumn>
                                </Modal>
                                <Modal
                                    visible={this.state.showNotesModal}
                                    footer={null}
                                    closable={true}
                                    width={500}
                                    destroyOnClose={true}
                                    onCancel={() => {
                                        this.setState({ showNotesModal: false });
                                    }}
                                >
                                    <FlexColumn start>
                                        <Text weight="500" style={{ marginBottom: 20 }} size='20px'>Add a note</Text>
                                        <LabeledInput
                                            small
                                            nomargin
                                            optional
                                            key="note"
                                            id="note"
                                            label="Note"
                                            value={note}
                                            placeholder="Please provide any additional context about your debt"
                                            onChange={e => { this.saveRowUserInput(id, "note", e.target.value) }}
                                        />

                                        <Button weight='500' width='119px' margin="24px 0 0 0" onClick={() => {
                                            this.setState({ showNotesModal: false });
                                        }} text="CLOSE" solid radius='4px' />


                                    </FlexColumn>
                                </Modal>
                            </>
                        }

                    </FlexColumn>
                </FlexColumn>
            </FlexColumn>
        );

        var readOnlyViews = (
            <>
                <Flex start style={{ width: "100%", marginBottom: 24 }}>
                    <Text uppercase weight='500' color='#7384AA' >DEBT OVERVIEW</Text>
                </Flex>
                {

                    // businessInfo.capitalRequest < this.state.gnplHigh && 
                    <Flex style={{ width: "100%", marginBottom: 15 }}>

                        <FlexColumn row style={{ width: "100%", wordBreak: "break-word" }}>
                            <Table
                                id="todo-table"
                                key="todo-table"
                                className='borderless-table'
                                tableLayout='auto'
                                columns={debtColumns}
                                dataSource={debtOverview}
                                pagination={false}
                                scroll={{ x: '100%' }}
                                align="center"
                            />
                        </FlexColumn>
                    </Flex>
                }
                <Flex style={{ width: "100%", marginBottom: 15 }}>
                    <Flex start wrap fullWidth gap='24px'>
                        <LabeledInput
                            type="read-only"
                            label="When did you last close your books?"
                            value={`${businessInfo.closeBooksDate && moment.utc(businessInfo.closeBooksDate).format('ll')}`}
                            width='288px'
                        />
                        <LabeledInput
                            type="read-only"
                            label="When did your most recent fiscal year end?"
                            value={`${businessInfo.fiscalYearEnd && moment.utc(businessInfo.fiscalYearEnd).format('MMM-YYYY')}`}
                            width='288px'
                        />
                    </Flex>
                </Flex>
                {
                    <FlexColumn left style={{ margin: '16px 0' }} gap='8px'>
                        <Text color={'#7384AA'} size='14px' weight={400}>Accounting system</Text>
                        <AccountingSysConnect
                            readonly
                            location={this.props.location}
                        />
                    </FlexColumn>
                }
                {
                    this.getDocument(preScreenDocs[0].name).fileName &&
                    <Flex style={{ width: "100%", marginBottom: "24px" }}>
                        <Flex style={{ width: "50%", marginRight: 12, margintTop: "10px", flexDirection: "column" }}>
                            <Text uppercase weight='500' color='#7384AA' style={{ marginBottom: "16px" }}>Company Prepared Balance Sheet</Text>

                            <Flex row style={{ width: "100%", wordBreak: "break-word" }}>
                                <div style={{ width: '50%', marginRight: '10px' }}>
                                    <FlexColumn>
                                        <Flex start noMargin>
                                            <Text weight="400" color="#7384AA"
                                                style={{ fontSize: "14px" }}> Most recent closed month</Text>
                                        </Flex>
                                        <Flex start noMargin wrap>
                                            <TextButton
                                                preLine
                                                breakWord
                                                style={{ textAlign: "left" }}
                                                icon={<Image src={File} />}
                                                onClick={() => this.downloadStatement(this.getDocument(preScreenDocs[0].name).uri, this.getDocument(preScreenDocs[0].name).fileName)}
                                                text={this.getDocument(preScreenDocs[0].name).fileName || ''} />

                                        </Flex>
                                    </FlexColumn>
                                </div>
                                <div style={{ width: '50%', marginRight: '10px' }}>
                                    <FlexColumn>
                                        <Flex start noMargin>
                                            <Text weight="400" color="#7384AA"
                                                style={{ fontSize: "14px" }}> Most recent fiscal year end</Text>
                                        </Flex>
                                        <Flex start noMargin wrap>
                                            <TextButton
                                                preLine
                                                breakWord
                                                style={{ textAlign: "left" }}
                                                icon={<Image src={File} />}
                                                onClick={() => this.downloadStatement(this.getDocument(preScreenDocs[1].name).uri, this.getDocument(preScreenDocs[1].name).fileName)}
                                                text={this.getDocument(preScreenDocs[1].name).fileName || ''} />

                                        </Flex>
                                    </FlexColumn>
                                </div>
                            </Flex>
                        </Flex>
                        <Flex row style={{ width: "50%", flexDirection: "column" }}>
                            <Text weight='500' color='#7384AA' style={{ marginBottom: "16px" }}>COMPANY PREPARED PROFIT & LOSS STATEMENT</Text>
                            <Flex row style={{ width: "100%", wordBreak: "break-word" }}>
                                <div style={{ width: '50%', marginRight: '10px' }}>
                                    <FlexColumn>
                                        <Flex start noMargin>
                                            <Text weight="400" color="#7384AA"
                                                style={{ fontSize: "14px" }}> YTD through most recent closed month</Text>
                                        </Flex>
                                        <Flex start noMargin wrap>
                                            <TextButton
                                                preLine
                                                breakWord
                                                style={{ textAlign: "left" }}
                                                icon={<Image src={File} />}
                                                onClick={() => this.downloadStatement(this.getDocument(preScreenDocs[2].name).uri, this.getDocument(preScreenDocs[2].name).fileName)}
                                                text={this.getDocument(preScreenDocs[2].name).fileName || ''} />

                                        </Flex>
                                    </FlexColumn>
                                </div>
                                <div style={{ width: '50%', marginRight: '10px' }}>
                                    <FlexColumn>
                                        <Flex start noMargin>
                                            <Text weight="400" color="#7384AA"
                                                style={{ fontSize: "14px" }}> Full year for most recent fiscal year end</Text>
                                        </Flex>
                                        <Flex start noMargin wrap>
                                            <TextButton
                                                preLine
                                                breakWord
                                                style={{ textAlign: "left" }}
                                                icon={<Image src={File} />}
                                                onClick={() => this.downloadStatement(this.getDocument(preScreenDocs[3].name).uri, this.getDocument(preScreenDocs[3].name).fileName)}
                                                text={this.getDocument(preScreenDocs[3].name).fileName || ''} />

                                        </Flex>
                                    </FlexColumn>
                                </div>
                            </Flex>
                        </Flex>
                    </Flex>
                }
                {
                    this.getDocument(preScreenDocs[4].name).fileName &&
                    <Flex style={{ width: "100%", justifyContent: 'start', marginTop: 15 }}>
                        <Flex row style={{ width: "50%", flexDirection: "column" }}>
                            <Text weight='500' color='#7384AA' style={{ marginBottom: "16px" }}>AR AGING SUMMARY</Text>
                            <Flex row style={{ width: "100%", wordBreak: "break-word", justifyContent: 'start' }}>
                                <div style={{ width: '50%', marginRight: '10px' }}>
                                    <FlexColumn>
                                        <Flex start noMargin>
                                            <Text weight="400" color="#7384AA"
                                                style={{ fontSize: "14px" }}> AR Aging Report</Text>
                                        </Flex>{
                                            this.getDocument(preScreenDocs[4].name).fileName ?
                                                <Flex start noMargin wrap>
                                                    <TextButton
                                                        preLine
                                                        breakWord
                                                        style={{ textAlign: "left" }}
                                                        icon={<Image src={File} />}
                                                        onClick={() => this.downloadStatement(this.getDocument(preScreenDocs[4].name).uri, this.getDocument(preScreenDocs[4].name).fileName)}
                                                        text={this.getDocument(preScreenDocs[4].name).fileName || ''} />

                                                </Flex> :
                                                <Text width='600px' margin='4px 0 24px'>None</Text>
                                        }

                                    </FlexColumn>
                                </div>
                            </Flex>
                        </Flex>
                    </Flex>
                }
            </>
        )
        return (
            readonly ? readOnlyViews :
                <>
                    <FlowViewComponent
                        flowView={flowView}
                        currentViewIndex={currentViewIndex}
                        loadPrev={loadPrevView}
                        childViews={childViews}
                        submitLoading={this.state.submitLoading}
                        width={'100%'}
                        back={currentViewIndex > 0}
                        padding='0 64px'
                        {...this.props}
                        loadNext={this.validateFlow}
                        showSuccessBanner={showSuccessBanner}
                    />

                    <Modal
                        visible={showUploadMethodChangeModal}
                        width={500}
                        closable={true}
                        maskClosable={true}
                        style={{ borderRadius: '8px !important' }}
                        footer={null}
                        destroyOnClose={true}
                        onCancel={() => this.setState({ showUploadMethodChangeModal: false })}
                        closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                    >
                        <FlexColumn between>
                            <FlexColumn start>
                                <Text heading>Change Upload Method</Text>
                                <Text>You will lose the documents you uploaded, do you still want to continue?</Text>
                            </FlexColumn>

                            <Flex style={{ marginTop: 40 }} start gap='24px' centerHorizontally>
                                <Button primary solid onClick={() => {
                                    this.discardFinancials()
                                    this.setState({ businessDocuments: [], showUploadMethodChangeModal: false })
                                    this.saveUserInput('uploadMethod', 'QuickBooks Online')
                                }} text="CONFIRM" />
                                <TextButton text='CANCEL' onClick={() => this.setState({ showUploadMethodChangeModal: false })} />
                            </Flex>
                        </FlexColumn>
                    </Modal>
                </>
        );
    }
}

function mapStateToProps(state) {
    return {
        onboardingStore: state.onboardingAppReducer,
        aionStore: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(FinancialInfo)));