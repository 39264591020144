import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'
import { saveAs } from 'file-saver'
import moment from 'moment'
import { Select, Table, Upload, message, Tooltip } from 'antd'

import { Flex, FlexColumn } from '../../Reusable/Container'
import { LabeledInput } from '../../Reusable/Input'
import { Text, Tag } from '../../Reusable/Refresh/Text'

import { ErrorAlert } from '../../Reusable/Alert'
import { TextButton } from '../../Reusable/Button'
import { apiPOSTReq, getPreSignedS3Url } from '../../../Utils/api'
import environment from '../../../environment'
import Info from '../../../Images/info.png'
import Download from '../../../Images/download.png'
import { Image } from '../../Reusable/Image';
import MessageIcon from '../../../Images/message.png';

import { CloseOutlined, FilePdfOutlined, DeleteOutlined, ExclamationCircleFilled } from '@ant-design/icons';

const { Option } = Select
export const dateFormatList = ['MM/DD/YYYY', 'MM/DD/YY', 'MM-DD-YYYY', 'MM-DD-YY']
const etTimeZone = "America/New_York";

class AdditionalInformation extends Component {

    state = {
        uploadList: []
    }

    componentDidMount() {
        this.fetchLoanApplicationRequest();
        this.fetchDocuments({});
    }

    getColumns = () => {


        var columns = [];
        columns = [
            {
                title: 'Document Type',
                render: (item) => <div >
                    {item.documentType}
                </div>,
                width: 150
            },
            {
                title: 'Document Period',
                render: (item) => <div >
                    {
                        !item.documentType ? <>-  </> :
                       <>
                        {(item.fromDate != null && item.toDate != null) ?
                            <>{moment.utc(item.fromDate).tz(etTimeZone).format('MMM DD, YYYY')}  -  {moment.utc(item.toDate).tz(etTimeZone).format('MMM DD, YYYY')} </> : null}
                        {(item.fromDate == null || item.toDate == null ) ?
                            <> {item.fromDate != null ? <>{moment.utc(item.fromDate).tz(etTimeZone).format('MMM DD, YYYY')} </> : null}
                                {item.toDate != null ? <>{moment.utc(item.toDate).tz(etTimeZone).format('MMM DD, YYYY')} </> : null} </> : null}
                    </>
                    }
                    </div>,
                width: 200
            },
            {
                title: 'File',
                width: 250,
                render: (item) => {
                    var uploadList = [];
                    if(item.allowMultipleUpload) {
                        uploadList = (this.state.businessDocuments || []).filter(doc => doc.requestId === item.requestId);

                    }
                    return (<Flex start>

                        {item.status != "Cancelled" && <div style={{width: `${100/2}`, overflow: "wrap", whiteSpace: "normal"}}>
                            {
                                item.allowMultipleUpload ? <>
                                    {(item.status == "InProgress" || item.status == "Submitted" || item.status == "Rejected") && 
                                    <LabeledInput
                                    {...this.uploadProps({ name: item.documentType }, item)}
                                    type='upload'
                                />}
                                    
                                    {
                                        uploadList.map(file => {
                                            return (
                                                <>
                                                {(item.status == "Submitted" || item.status == "Rejected") ?
                                            <TextButton
                                            breakWord preLine
                                                noBorder height='32px' padding='4px 8px' radius='4px' background='#F8F8F8'
                                                style={{ textAlign: "left" }}
                                                text={file.documentUrl.fileName}
                                                onClick={() => this.downloadStatement(file.documentUrl.uri, file.documentUrl.fileName)}
                                                deleteConfirmMessage={'Are you sure you want to delete this file?'}
                                                onDelete={() => this.handleDeleteDoc(file, item)} 
                                              />
                                            : <TextButton
                                            breakWord preLine
                                                noBorder height='32px' padding='4px 8px' radius='4px' background='#F8F8F8'
                                                style={{ textAlign: "left" }}
                                                text={file.documentUrl.fileName} />
                                    }</>
                                            )
                                        })
                                    }
          
                                
                                </> :
                                    <>
                                        {((!this.getDocument(item.documentType, { requestId: item.requestId }).fileName)) ?
                                            <LabeledInput
                                                {...this.uploadProps({ name: item.documentType }, item)}
                                                type='upload'
                                            /> :
                                            <>

                                                {
                                                    (item.status == "Submitted" || item.status == "Rejected") ?
                                                        <TextButton
                                                        breakWord preLine
                                                            noBorder height='32px' padding='4px 8px' radius='4px' background='#F8F8F8'
                                                            style={{ textAlign: "left" }}
                                                            onClick={() => this.downloadStatement(this.getDocument(item.documentType, { requestId: item.requestId }).uri, this.getDocument(item.documentType, { requestId: item.requestId }).fileName)}
                                                            text={this.getDocument(item.documentType, { requestId: item.requestId }).fileName}
                                                            deleteConfirmMessage={'Are you sure you want to delete this file?'}
                                                            onDelete={() => this.handleDeleteDoc(this.getDocument(item.documentType, { businessDocument: true, requestId: item.requestId }), item)} />
                                                        : <TextButton
                                                        breakWord preLine
                                                            noBorder height='32px' padding='4px 8px' radius='4px' background='#F8F8F8'
                                                            style={{ textAlign: "left" }}
                                                            onClick={() => this.downloadStatement(this.getDocument(item.documentType, { requestId: item.requestId }).uri, this.getDocument(item.documentType, { requestId: item.requestId }).fileName)}
                                                            text={this.getDocument(item.documentType, { requestId: item.requestId }).fileName} />

                                                }
                                            </>
                                        }
                                    </>
                            }
                        </div>}
                    </Flex>

                    )
                }
            },
            {
                title: 'Status',
                render: (item) => {
                    var { tagBackgroundColor, tagTextColor, tagText } = "";
                    switch (item.status) {
                        case "Approved":
                            tagBackgroundColor = "#F5F9F5"; tagTextColor = "#318F2F"; tagText = "Accepted"
                            break;
                        case "InReview":
                            tagBackgroundColor = "#F5F6FE"; tagTextColor = "#1199FF"; tagText = "In Review"
                            break;
                        case "Submitted":
                            tagBackgroundColor = "#F5F6FE"; tagTextColor = "#1199FF"; tagText = "Submitted"
                            break;
                        case "Cancelled":
                            tagBackgroundColor = "#F5F6FE"; tagTextColor = "red"; tagText = "Cancelled"
                            break;
                        case "Rejected":
                            tagBackgroundColor = "#F5F6FE"; tagTextColor = "red"; tagText = "Rejected"
                            break;
                        default:
                            tagBackgroundColor = "#F5F6FE"; tagTextColor = "#1199FF"; tagText = "Not Started"
                            break;
                    }
                    return (
                        <div>
                            {
                                <Tag style={{ marginLeft: "0px", fontSize: "1rem", height: "35px" }}
                                    background={tagBackgroundColor}
                                    color={tagTextColor}>
                                    {tagText}</Tag>
                            }

                        </div>
                    )
                },
                width: 150
            },
            {

                title: '',
                render: (item) => {
                    return (
                        <div>
                            {
                                item.status == "Submitted" ?
                                    <TextButton
                                        height='32px' padding='8px 12px 8px 0' radius='4px'
                                        style={{ textAlign: "left" }}
                                        onClick={() => {
                                            item.status = 'InReview'
                                            this.updateRequestStatus(item);
                                            this.sendCreditUserEmail(item);
                                        }}
                                        text={"Send For Review"}
                                    /> : null
                            }
                        </div>
                    )
                },
                width: 150
            },
            {

                title: 'Attachment',
                width: 200,
                render: (item) => {
                    var businessDocs = this.state.businessDocuments || [];
                    var doc = [];
                    if(item.attachmentDocId) {
                        doc = businessDocs.filter(doc => doc.documentId === item.attachmentDocId) || []
                    }
                    return (
                        <div>
                            {
                                (doc.length > 0 && doc[0].name) ?
                                    <TextButton
                                    underLine breakWord preLine
                                        noBorder height='32px' padding='4px 8px 4px 0' radius='4px' background='#F8F8F8'
                                        style={{ textAlign: "left" }}
                                        text={"Attachment"}
                                        onClick={() => this.downloadStatement(doc[0].documentUrl.uri, doc[0].documentUrl.fileName)}
                                    />
                                    : null
                            }
                        </div>
                    )
                }
            },
            {

                title: 'Template',
                render: (item) => {
                    var businessDocs = this.state.businessDocuments || [];
                    var doc = [];
                    if(item.templateDocId) {
                        doc = businessDocs.filter(doc => doc.documentId === item.templateDocId)
                    }
                    return (
                        <div>
                            {
                                (doc.length > 0 && doc[0].name) ?
                                    <TextButton
                                        noBorder height='32px' padding='4px 8px' radius='4px' background='#F8F8F8'
                                        style={{ textAlign: "left" }}
                                        text={"Download Template"}
                                        rightIcon={<Image src={Download} />}
                                        onClick={() => this.downloadStatement(doc[0].documentUrl.uri)}
                                    />
                                    : null
                            }
                        </div>
                    )
                },
                width: 200
            },
            {
                title: 'Notes',
                width: 100,
                render: (item) => {
                    return( 
                        <>
                        {
                            !item.message ? null : 
                            (item.message != null || item.message != "") ? 

                            <Tooltip overlayInnerStyle={{ width: 330, minWidth: 330 }} overlayStyle={{ width: 350, minWidth: 350 }} overlayClassName='roles-tooltip' placement="left" arrowPointAtCenter={false} title={<FlexColumn>
                                <Text color='white' size='14px' wrap>{item.message}</Text>
                                </FlexColumn>}>
                                <FlexColumn>
                                    <Image src={MessageIcon} />
                                </FlexColumn>
                            </Tooltip> : null
                        }
                        </>
                    
                       

                        
                    )
                }
            },
        ];
        return columns;

    }
    downloadStatement = (uri, name) => {
        getPreSignedS3Url({ url: uri }, async (err, preSignedUrl) => {
            try {
                // prevents opening file in new tab
                let blob = await fetch(preSignedUrl).then((r) => r.blob());
                saveAs(blob, name);

            } catch (err) {
                console.log(err);
            }
        })


    }

    uploadProps = (doc, loanApplicationRequest) => {
        return {
            name: `business-docs`,
            action: `${environment.apiBaseUrl}/file/upload`,
            headers: {
                businesskey: this.props.aionStore.BusinessUniqueKey,
                jwt: this.props.aionStore.jwt
            },
            beforeUpload: (doc) => {

                const isPDF = doc.type === 'application/pdf';

                const isXLSX = doc.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                if (!isXLSX && !isPDF) {
                    message.error(`${doc.name} is not a pdf file`);
                }
                return isPDF || isXLSX || Upload.LIST_IGNORE;
            },
            onChange: (info) => {
                var { file } = info;
                var savedFile = {};
                const { status, response, name, url } = file;
                    if (status === "done") {
                        savedFile = {
                            fileName: name,
                            uri: (response.UploadedUrls || [])[0],
                        }
                        if (doc.type) savedFile.type = doc.type;
                        // message.success(`${info.file.name} file uploaded successfully.`, 0.75);
                        doc.savedFile = savedFile;

                        this.handleDocSubmit(doc, loanApplicationRequest);
                    } else if (status === 'error') {
                        message.error(`${info.file.name} file upload failed.`);
                    }
                if (((Object.keys(savedFile)).length) !== 0) {
                    doc.savedFile = savedFile;
                }
                this.setState({ [doc.name]: doc });
            },
            showUploadList: !loanApplicationRequest.allowMultipleUpload,
            accept: ".pdf",
            onRemove: (file) => {
                this.handleDeleteDoc(doc);
            }
        }
    };

    handleDocSubmit = (uploadItem, loanApplicationRequest) => {
        var { loadingItem } = uploadItem || {}
        var { loanApplication, onboardingStore } = this.props
        var businessDocument = {
            "name": uploadItem.name,
            "documentUrl": uploadItem.savedFile || {},
            "subType": 'AdditionalDocument',
            "type": "Document",
            "source": 'User',
            "category": "PreScreen",
            "RequestId": loanApplicationRequest.requestId
        };

        if (loanApplication) businessDocument.applicationId = loanApplication.applicationId;
        var body = {
            businessDocument: businessDocument
        }

        console.log("handleSubmit DOC/SAVE uploadItem body", body);
        this.setState({ submitLoading: true, loadingItem: loadingItem });
        apiPOSTReq(`${environment.uamBaseUrl}/docs/save`, {}, body, (err, resp) => {
            this.setState({ submitLoading: false, loadingItem: null });
            try {
                const data = resp || {};
                var uploadList = this.state.uploadList;
                var existingUploadList = uploadList.filter(list => list.requestId === loanApplicationRequest.requestId);

                var acitveUploadList = existingUploadList.length > 0 ? existingUploadList : [{
                    "requestId": loanApplicationRequest.requestId,
                    "docs": []
                }];

                if (data.result) {
                    message.success(`File saved successfully.`, 0.75);
                    loanApplicationRequest.status = 'Submitted';
                    loanApplicationRequest.uploadedAt = moment().utc();
                    this.updateRequestStatus(loanApplicationRequest);
                    this.fetchDocuments(loanApplicationRequest);

                    var activeUpload = acitveUploadList[0];
                    var uploadedDocs = activeUpload.docs || [];
                    uploadedDocs.push(data.businessDocument);

                    activeUpload.docs = uploadedDocs;

                    var index = uploadList.findIndex(list => list.requestId === loanApplicationRequest.requestId) || [];
                    if (index >= 0)
                        uploadList[index] = activeUpload;
                    else
                        uploadList.push(activeUpload)
                    this.setState({ uploadList: uploadList })


                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/docs/save err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    handleDeleteDoc = (uploadItem, request) => {
        var { loanApplication } = this.props;
        var businessDocument = {
            "category": "PreScreen",
            "name": uploadItem.name,
        };

        if (loanApplication) businessDocument.applicationId = loanApplication.applicationId;
        var body = {
            "businessDocument": businessDocument,
            "documentId": uploadItem.documentId
        }

        console.log("handleSubmit DOC/delete uploadItem body", body);
        this.setState({ submitLoading: true });
        apiPOSTReq(`${environment.uamBaseUrl}/docs/delete`, {}, body, (err, resp) => {
            this.setState({ submitLoading: false, loadingItem: null });
            try {
                const data = resp || {};
                if (data.result) {

                    if(request.allowMultipleUpload) {
                        this.fetchDocuments(request)
                    } else {
                        this.fetchDocuments({})
                    }
                        let doc = this.state[uploadItem.name] || {};
                    doc.savedFile = {};
                    this.setState({ [doc.name]: doc });
                    if(!request.allowMultipleUpload) {

                        request.status = "InProgress";
                        request.uploadedAt = null;
                        this.updateRequestStatus(request);
                    }
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/docs/deleteUpload err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    getDocument = (name, options) => {
        options = options || {};
        var { businessDocuments } = this.state;
        var savedDoc = this.state[name] || {};
        var filteredDoc = (businessDocuments || []).filter(item => (item.name == name && item.requestId == options.requestId));

        if (filteredDoc.length > 0) {
            // Replace with remotely stored data
            if (options.businessDocument) {
                savedDoc = filteredDoc[filteredDoc.length - 1];
            }
            else
                savedDoc = filteredDoc[filteredDoc.length - 1].documentUrl || {};
        }
        return savedDoc;
    }

    fetchDocuments = (loanApplicationRequest) => {
        const { loanApplication } = this.props;
        var body = {
            "sortDirection": "DESC"
        };
        if (loanApplication) {
            body["applicationId"] = loanApplication.applicationId;
            body["viewBy"] = "FILTER_APPLICATION_ID"
        }
        console.log("/docs/getActiveDocuments body", body, loanApplication);
        this.setState({ qbConnectLoading: true });
        apiPOSTReq(`${environment.uamBaseUrl}/docs/getActiveDocuments`, {}, body, (err, resp) => {
            console.log("/docs/getActiveDocuments resp", resp);
            this.setState({ qbConnectLoading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    this.setState({ businessDocuments: data.businessDocuments });
                    if (Object.keys(loanApplicationRequest).length > 0) {
                        if (loanApplicationRequest.status === "Rejected")
                            this.updateRequestStatus(loanApplicationRequest);
                        if (loanApplicationRequest.allowMultipleUpload) {
                          var uploadList = (this.state.businessDocuments || []).filter(doc => doc.requestId === loanApplicationRequest.requestId);
                            if(uploadList.length == 0 ) {
                                loanApplicationRequest.status = "InProgress";
                                loanApplicationRequest.uploadedAt = null;
                                this.updateRequestStatus(loanApplicationRequest);
                            }
                        }
                        
                    }
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    fetchLoanApplicationRequest = () => {
        // Fetch document list
        const { loanApplication } = this.props;
        var body = {
            "businessId": loanApplication.businessId,
            "applicationId": loanApplication.applicationId,
            "uploadCategory" : "PreScreen"
            // "sortDirection": "ASC"
        };
        this.setState({ loanAppLoading: true });
        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/getActiveLoanRequest`, {}, body, (err, resp) => {
            console.log("/getApplications resp body", resp, body);
            try {
                const data = resp || {};
                if (data.result) {
                    var loanApplicationRequests = data.requests || [];
                    this.setState({ loanApplicationRequests: loanApplicationRequests })
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/getApplications err", error, resp);
                // ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            } finally {
                this.setState({ loanAppLoading: false });
            }
        })
    }

    sendCreditUserEmail = (loanApplicationRequest) => {
        const { loanApplication } = this.props;
        var { aionStore } = this.props;
        var { UserInfo } = aionStore;

        var body = {
            businessId: loanApplication.businessId,
            applicationId: loanApplication.applicationId,
            request: loanApplicationRequest,
            loggedInUser: UserInfo.Email
        }
        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/sendAdditionalUploadEmail`, {}, body, (err, resp) => {
            this.setState({ submitLoading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    console.log("Email send Successfully!")
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/lsm/sendAdditionalUploadEmail err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    updateRequestStatus = (loanApplicationRequest) => {

        const { loanApplication } = this.props;
        var { aionStore } = this.props;
        var { UserInfo } = aionStore;

        // if (!this.getDocument(loanApplicationRequest.documentType, { requestId: loanApplicationRequest.requestId }).fileName) {
        //     ErrorAlert({ description: "Please upload file." });
        //     return;
        // }

        var body = {
            businessId: loanApplication.businessId,
            applicationId: loanApplication.applicationId,
            request: loanApplicationRequest,
            loggedInUser: UserInfo.Email
        }

        console.log("handleSubmit update loan request body", body);
        this.setState({ submitLoading: true });
        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/updateLoanRequest`, {}, body, (err, resp) => {
            this.setState({ submitLoading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    console.log("Status Updated Successfully!")
                    this.fetchLoanApplicationRequest();
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/lsm/updateLoanRequest err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    render() {
        var { loanApplicationRequests } = this.state;
        var childViews = (
            <FlexColumn >
                <FlexColumn between left fullHeight style={{ marginTop: "0px", }} gap='24px'>

                    <Flex fullWidth noMargin row between>
                        <FlexColumn style={{ width: `95%`, flexDirection: "column" }} >
                            <Text margin="0px 0px 12px 0px" weight='500' spacing="0.08em" color='#7384AA' >DOCUMENTS</Text>
                            <Text size="16px" width="75%" margin="0px 0px 12px 0px" >Provide the below additional documents to help us complete underwriting and provide a faster and better decision. Please make sure to upload documents as per the period requested, when applicable.</Text>
                        </FlexColumn>
                        <FlexColumn style={{ width: `5%`, flexDirection: "column", justifyContent: "flex-end" }}>
                            <TextButton onClick={""} text={""} icon={<Image src={Info} />} />
                        </FlexColumn>

                    </Flex>

                </FlexColumn>
                <FlexColumn>
                    {/*<Banner
                                    borderColor="#D6E9D5"
                                    background="#F5F9F5"
                                    color="#318F2F"
                                    style={{ marginBottom: "24px" }}
                                    message={`Tax return document ‘Document.pdf’ was accepted`}
                                    cta={<TextButton text='X' color="#318F2F" weight='400' />}
                                />
                <Banner
                                    borderColor="#FAD5D5"
                                    background="#FEF5F5"
                                    color="#E52D2D"
                                    style={{ marginBottom: "24px" }}
                                    message={`Tax return document ‘Document.pdf’ was rejected because of reason, please upload again`}
                                    cta={<TextButton text='X' color="#E52D2D" weight='400' />}
        />*/}
                </FlexColumn>
                <FlexColumn>
                    <Table
                        id="todo-table"
                        key="todo-table"
                        className='borderless-table'
                        tableLayout='auto'
                        columns={this.getColumns()}
                        dataSource={loanApplicationRequests || []}
                        rowKey='objectId'
                        pagination={false}
                        scroll={{ x: '75%' }}
                    />
                </FlexColumn>
            </FlexColumn>
        )
        return (
            <>
                {childViews}

            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        onboardingStore: state.onboardingAppReducer,
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(AdditionalInformation))