import React from 'react'
import styled from 'styled-components'

import AionIcon from '../../Images/aion-bank-icon.png'
import BofAIcon from '../../Images/bofa-icon.png'
import ChaseIcon from '../../Images/chase-icon.png'


const TagContainer = styled.div`
    height: 32px;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 8px;
    background: #F8F8F8;
`

const Text = styled.span`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #7384AA;
`


export const BankAccountTag = (props) => {
    const { bank, icon, type, mask } = props

    var img

    switch (bank) {
        case 'aion':
            img = AionIcon
            break
        case 'bofa':
            img = BofAIcon
            break
        case 'chase':
            img = ChaseIcon
            break
        default:
            img = icon
            break
    }

    return <TagContainer {...props}>
        {
            img &&
            <img style={{ marginRight: 8 }} src={img} width='16px' height='16px' />
        }

        <Text>{`${type} • ${mask}`}</Text>
    </TagContainer>
}