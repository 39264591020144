import React, { Component } from 'react'
import moment from 'moment'
import { saveAs } from 'file-saver'
import { Flex, FlexColumn, InputContainer } from './Container'
import { Text, Tag } from './Text'
import { Modal, List, message, Space, Divider, Upload, Switch, Table, Popconfirm, Menu, Tooltip, Popover, Dropdown } from 'antd'
import { withTheme } from 'styled-components'
import { CheckCircleFilled, DownOutlined, DeleteOutlined, DownloadOutlined, InfoCircleOutlined, LoadingOutlined, MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons'
import environment from '../../environment'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { Button } from './Button'
import { LabeledInput } from './Input'
import { ErrorAlert } from './Alert'
import { apiPOSTReq, getPreSignedS3Url } from '../../Utils/api'
import { SignedLink } from './Link'
import { addDataToStore, SAVE_DATA, CREDIT_SAVE_DATA } from '../../Actions/actions'
import { getResourcePerm, dateFormatList } from '../../Utils/util'

import MSA from '../Receivables/Customers/MSA/Index'
import WorkOrder from '../Receivables/Customers/PurchaseOrder/Index'
import ModalClose from '../../Images/modal-close.png'

const dateFormat = 'MM/DD/YYYY'

class BusinessDocuments extends Component {

    state = {
        pagination: {
            current: 1,
            pageSize: 50
        },
        selectedMenu: "General",
        loadingDocuments: true,
        msaModalCustomer: {},
        loadingContents: true
    }

    componentDidMount() {
        this.fetchDocuments(this.state);
        this.checkMandatoryDocsStatus();
        this.fetchTopCustomers();
        this.fetchProposalTerms();
    }

    fetchDocuments = (options) => {
        var { loanApp } = this.props
        const { pagination } = options
        // Fetch document list
        var body = {
            "size": pagination.pageSize,
            "page": pagination.current - 1
        }
        if (loanApp) {
            body.applicationId = loanApp.applicationId || ""
            body.viewBy = "FILTER_APPLICATION"
            body.clientBusinessId = loanApp.businessId
        }
        apiPOSTReq(`${environment.uamBaseUrl}/docs/getActiveDocuments`, {}, body, (err, resp) => {
            console.log("/getActiveDocuments resp", resp)
            this.setState({ loadingDocuments: false })
            try {
                const data = resp
                if (data.result) {
                    this.setState({
                        businessDocuments: data.businessDocuments,
                        pagination: {
                            ...pagination,
                            total: data.count,
                            pageable: data.pageable
                        }
                    })
                    this.props.dispatch(addDataToStore(SAVE_DATA, { businessDocuments: data.businessDocuments }))
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getActiveDocuments", error, err, resp)
                ErrorAlert({ description: error.message })
            }
            this.checkMandatoryDocsStatus()
        })
    }

    fetchTopCustomers(options) {
        var { loanApp } = this.props
        loanApp = loanApp || {}
        const body = {
            "clientBusinessId": loanApp.businessId,
            "applicationId": loanApp.applicationId,
            "size": 10,
            "page": 0
        }
        this.setState({ customersLoading: true })
        apiPOSTReq(`${environment.opsBaseUrl}/ops/loans/application/getTopCustomers`, {}, body, (err, resp) => {
            try {
                const data = resp
                console.log("/getTopCustomers", data)
                if (data.result) {
                    this.setState({
                        customers: data.customers,
                        customersLoading: false
                    })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
                this.checkMSAUploadStatus()
                this.checkWOUploadStatus()
            } catch (error) {
                console.log("ERRR getTopCustomers", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    fetchProposalTerms = () => {
        var { loanApp } = this.props;
        loanApp = loanApp || {}
        var body = {
            "clientBusinessId": loanApp.businessId,
            "applicationId": loanApp.applicationId,
        };
        console.log("/lsm/getProposalTerms body", body);
        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/getProposalTerms`, null, body, (err, resp) => {
            this.setState({ loanAppLoading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    console.log(data);
                    this.setState({
                        "proposalTerms": data.offers[0]
                    });
                    if(!this.state.proposalTerms.productType) {
                        this.setState({loadingContents: true})
                    } else {
                        this.setState({loadingContents: false})
                    }
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/getProposalTerms body", error, resp);
                // ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    handleOnChange = (event) => {
        const target = event.target
        this.setState({ [target.id]: target.value })
        if (this.props.saveUserInput) this.props.saveUserInput(target.id, target.value)
    }

    handleTableChange = (pagination, filters, sorter) => {
        this.fetchDocuments({ pagination: pagination })
    }

    handleSubmit = (uploadItem) => {
        console.log("handleSubmit DOC/SAVE uploadItem", uploadItem)
        var { note, type, loadingItem, inputType, namedValues } = uploadItem || {}
        uploadItem = uploadItem || {}
        var savedItem = this.state[uploadItem.name] || {}
        namedValues = savedItem.namedValues || namedValues
        var closeModal = !["TaxReturns", "PNL"].includes(type)
        var { loanApp } = this.props
        if (inputType == "List") {
            var rowItems = namedValues.filter(item => (item.name != "Headers"))
            if (rowItems.length == 0) {
                ErrorAlert({ description: "Please add data to before clicking submit" })
                return
            }
        } else if (!uploadItem.savedFile && !note) {
            ErrorAlert({ description: "Please upload a file or add a note before clicking submit" })
            return
        }

        var businessDocument = {
            "category": uploadItem.section,
            "name": uploadItem.name,
            "documentUrl": uploadItem.savedFile || {},
            "namedValues": namedValues
        }
        if (loanApp) businessDocument.applicationId = loanApp.applicationId
        if (uploadItem.effectivePeriod) businessDocument.effectivePeriod = uploadItem.effectivePeriod
        if (note) businessDocument.namedValues = [{ name: "Note", value: note }]
        var body = {
            "businessDocument": businessDocument
        }
        this.setState({ submitLoading: true, loadingItem: loadingItem })
        apiPOSTReq(`${environment.uamBaseUrl}/docs/save`, {}, body, (err, resp) => {
            this.setState({ submitLoading: false, loadingItem: null })
            try {
                const data = resp || {}
                if (data.result) {
                    message.success(`File saved successfully.`, 0.75)
                    this.fetchDocuments(this.state)
                    this.setState({ showUploadModal: !closeModal })
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/docs/save err", error, resp)
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    handleDeleteFile = (id) => {
        var body = {
            "documentId": id
        }
        this.setState({ loadingDocuments: true })
        apiPOSTReq(`${environment.uamBaseUrl}/docs/delete`, {}, body, (err, resp) => {
            this.setState({ loadingDocuments: false })
            try {
                const data = resp || {}
                if (data.result) {
                    this.fetchDocuments(this.state)
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/docs/delete err", err, resp)
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    handleUCMenuClick = (status, item) => {
        console.log("handleUCMenuClick", status, item)
        this.setState({ loading: true })
        var businessDocument = {
            ...item
        }
        businessDocument["status"] = status
        var body = {
            "businessDocument": businessDocument
        }
        this.setState({ loading: true })
        apiPOSTReq(`${environment.uamBaseUrl}/docs/save`, {}, body, (err, resp) => {
            this.fetchDocuments({ pagination: this.state.pagination })
            this.setState({ loading: false })
            try {
                const data = resp || {}
                if (data.result) {
                    message.success(`Status updated successfully.`, 0.75)
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/docs/save err", error, resp)
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    getUCDropdownMenu = (item) => {
        const statusMenu = () => (
            <Menu onClick={(e) => this.handleUCMenuClick(e.key, item)}>
                <Menu.Item key="In Review">
                    In Review
                </Menu.Item>
                <Menu.Item key="Validated">
                    Validated
                </Menu.Item>
                <Menu.Item key="Additional Info Requested">
                    Additional Info Requested
                </Menu.Item>
            </Menu>
        )

        return (
            <Dropdown overlay={statusMenu()}>
                <Button>
                    {item.status || "Select Review Status"} <DownOutlined />
                </Button>
            </Dropdown>
        )
    }

    downloadStatement(uri, name) {
        return new Promise((resolve, reject) => {
            getPreSignedS3Url({ url: uri }, (err, preSignedUrl) => {
                if (!err) {
                    saveAs(preSignedUrl, name);
                } else {
                    reject(JSON.stringify(err))
                }
            })
        })
    }

    getModalContentForDocument = (uploadItem) => {
        const { theme } = this.props
        var { businessDocuments, submitLoading } = this.state
        businessDocuments = businessDocuments || []
        var { type, requiredDocs } = uploadItem
        requiredDocs = (requiredDocs || []).filter(item => !item.hide)
        const uploadProps = (doc) => {
            return {
                name: `business-docs`,
                multiple: false,
                action: `${environment.apiBaseUrl}/file/upload`,
                headers: {
                    businesskey: this.props.aionStore.BusinessUniqueKey,
                    jwt: this.props.aionStore.jwt
                },
                onChange: (info) => {
                    var { fileList } = info
                    var savedFile = {}
                    fileList.forEach(file => {
                        const { status, response, name, url } = file
                        if (status === "done") {
                            savedFile = {
                                fileName: name,
                                uri: (response.UploadedUrls || [])[0],
                            }
                            if (doc.type) savedFile.type = doc.type
                            message.success(`${info.file.name} file uploaded successfully.`, 0.75)
                            doc.savedFile = savedFile
                            this.handleSubmit(doc)
                        } else if (status === 'error') {
                            message.error(`${info.file.name} file upload failed.`)
                        }
                    })
                    doc.savedFile = savedFile
                    this.setState({ [doc.name]: doc })
                }
            }
        }

        const uploadContainer = (doc, i) => {
            var { savedState, ucView } = this.props
            savedState = savedState || {}
            const docUploadItem = this.state[doc.name]
            var { note, noDocument, submitLoading } = docUploadItem || {}
            var showBlkSave = doc.requiredDocs
            var { namedValues } = docUploadItem || doc
            namedValues = namedValues || []
            var itemUploaded = businessDocuments.find(document => (document.name == doc.name || document.displayName == doc.name))

            // if() itemUploaded = true
            var numContainerConst = 40
            var t = this
            function getInputComponent(doc, uploaded) {
                if (uploaded) namedValues = doc.namedValues || []
                if (doc.inputType == "Text") {
                    return (
                        <LabeledInput
                            id={doc.id}
                            maxLength={doc.limit || 100}
                            value={savedState[doc.id]}
                            placeholder={doc.placeholder}
                            onChange={t.handleOnChange}
                        />
                    )
                } else if (doc.inputType == "Date") {
                    return (
                        <LabeledInput
                            id={doc.id}
                            type="date-picker"
                            placeholder={doc.placeholder}
                            format={dateFormatList}
                            onChange={(date, dateStr) => {
                                t.setState({ [doc.id]: date })
                                if (t.props.saveUserInput) t.props.saveUserInput(doc.id, date.format("YYYY-MM-DD"))
                            }}
                        />
                    )
                } else if (doc.inputType == "List" || namedValues.find(item => (item.name == "Headers"))) {
                    /**
                     * E.g namedValues = [ { name: "Headers", value: "Name,Title" }, { name: "0", value: "John Doe,CEO" }  ]
                     */
                    var headerItem = namedValues.find(item => (item.name == "Headers")) || {}
                    var rowItems = namedValues.filter(item => (item.name != "Headers"))
                    var headers = (headerItem.value || "").split(",")
                    if (rowItems.length == 0) rowItems = [{ name: 0, value: "" }]
                    // console.log("rowitems", rowItems)
                    function handleRowItemsTextChange(event, key) {
                        var selectedItem = rowItems.find(item => (item.name === key))
                        if (selectedItem) {
                            var currVal = selectedItem.value.split(",")
                            currVal[headers.indexOf(event.target.id)] = event.target.value
                            selectedItem.value = currVal.join(",")
                        } else {
                            var newVal = headers.map(header => (header == key) ? event.target.value : "")
                            rowItems.push({
                                name: key,
                                value: newVal.join(",")
                            })
                        }
                        console.log("handleRowItemsTextChange", doc, rowItems, [headerItem].concat(rowItems))
                        doc.namedValues = [headerItem].concat(rowItems)
                        t.setState({ [doc.name]: doc })
                    }

                    function addLineItemRow(key) {
                        console.log("addLineItemRow", key, doc)
                        doc.namedValues = namedValues.concat([{ name: key, value: "" }])
                        t.setState({ [doc.name]: doc })
                    }

                    function removeLineItemRow(key) {
                        console.log("removeLineItemRow", key, doc)
                        doc.namedValues = namedValues.filter(item => {
                            if (item.name !== key) return item
                        })
                        t.setState({ [doc.name]: doc })
                    }

                    return (
                        <>
                            {rowItems.map((rowItem, i) => {
                                var { value } = rowItem || ""
                                var columns = value.split(",")
                                var itemId = i
                                return (
                                    <div key={itemId + "container"}>
                                        <Space key={itemId + "space"}>
                                            <Flex center style={{ height: 30, width: 30, borderRadius: 15, background: theme.colors.systemGray6 }}><Text noMargin bold>{i + 1}.</Text></Flex>
                                            {headers.map((header, hInd) => {
                                                var cVal = (columns.length > hInd) ? columns[hInd] : ""
                                                return (
                                                    <LabeledInput
                                                        type="text-area"
                                                        labelcolor={theme.colors.secondary3}
                                                        label={header}
                                                        id={header}
                                                        key={itemId + header}
                                                        placeholder={`Add ${header}`}
                                                        style={{ width: "320px" }}
                                                        value={(cVal)}
                                                        autoSize={{ minRows: 1, maxRows: 8 }}
                                                        onChange={e => handleRowItemsTextChange(e, itemId)}
                                                    />
                                                )
                                            })}

                                            {(i != 0) ? <MinusCircleOutlined style={{ "cursor": "pointer", color: "red" }} onClick={() => removeLineItemRow(itemId)} /> : null}
                                        </Space>
                                    </div>
                                )
                            })}
                            {!ucView && <Button style={{ textAlign: "left" }} type="link" icon={<PlusOutlined />} onClick={() => addLineItemRow(rowItems.length)} text="Add more">Add more</Button>}
                            {!showBlkSave && !ucView && <Button type="primary" solid loading={submitLoading} onClick={() => t.handleSubmit(doc)} style={{ marginTop: "5px" }} text={uploaded ? "Update" : "Save"}>{uploaded ? "Update" : "Save"}</Button>}
                        </>
                    )
                } else if (doc.name === 'External Bank Statement') {
                    let statements = businessDocuments.filter(document => (document.name == doc.name || document.displayName == doc.name))
                    console.log('bank stat', statements)
                    return (statements.map(x => {
                        let tokens = (x.displayName || "").split("-")
                        let bankName = ""
                        if (tokens.length > 0) {
                            bankName = tokens[0]
                        }
                        const accountMask = x.displayName ? x.displayName.substring(x.displayName.length - 4) : ""
                        return <Space style={{ margin: "5px 50px" }} key={x.id}>
                            <div style={{ marginRight: '5px' }}>{bankName} {accountMask}</div>
                            <div style={{ marginRight: '5px' }}>
                                <SignedLink {...x.documentUrl} />
                            </div>
                            {/* {(itemUploaded || {}).documentUrl && !ucView &&
                                <>
                                    <Divider type="vertical" />
                                    <Popconfirm title="Are you sure you want to delete this file?" onConfirm={() => t.handleDeleteFile(itemUploaded.documentId)}>
                                        <a><DeleteOutlined style={{ cursor: 'pointer', fontSize: 18 }} /> Delettttte</a>
                                    </Popconfirm>
                                </>
                            } */}
                            {
                                ucView && t.getUCDropdownMenu(x)
                            }
                        </Space>
                    }))
                } else if ((doc.documentUrl || {}).uri || namedValues.find(item => (item.name == "Note"))) {
                    return (
                        <Space style={{ margin: "5px 50px" }}>
                            <SignedLink {...(itemUploaded || {}).documentUrl} />
                            {namedValues.find(item => (item.name == "Note")) && <Text noMargin color={theme.colors.secondary8}>{!ucView ? "Your" : "User"} note: {(itemUploaded.namedValues || []).map(item => item.value).join(", ")}</Text>}
                            {(itemUploaded || {}).documentUrl && !ucView &&
                                <>
                                    <Divider type="vertical" />
                                    <Popconfirm title="Are you sure you want to delete this file?" onConfirm={() => t.handleDeleteFile(itemUploaded.documentId)}>
                                        <a><DeleteOutlined style={{ cursor: 'pointer', fontSize: 18 }} /> Delete</a>
                                    </Popconfirm>
                                </>
                            }
                            {
                                ucView &&
                                t.getUCDropdownMenu(itemUploaded)
                            }
                        </Space>
                    )
                } else {
                    return (
                        <FlexColumn style={{ marginTop: "15px", alignItems: "start" }}>
                            <Flex style={{ justifyContent: "start", margin: "0px 50px", alignItems: "baseline" }}>
                                <Upload {...uploadProps(doc)}>
                                    <Button icon={<UploadOutlined />} text="Click to Upload">Click to Upload</Button>
                                </Upload>
                                <Space style={{ marginTop: "5px", marginLeft: "10px" }}>
                                    <Switch checked={noDocument} onChange={(checked) => t.setState({ [doc.name]: { ...doc, ...docUploadItem, "noDocument": checked } })} />
                                    Do not have this document?
                                </Space>
                            </Flex>
                            
                            {
                                noDocument &&
                                <FlexColumn style={{ margin: "0px 50px" }}>
                                    <LabeledInput
                                        label="Add a note to indicate why you do not have this document*"
                                        labelcolor={theme.colors.secondary3}
                                        placeholder="Note"
                                        key="note"
                                        id="note"
                                        value={note}
                                        onChange={e => { t.setState({ [doc.name]: { ...doc, ...docUploadItem, "note": (e.target.value) } }) }}
                                    />
                                    {!showBlkSave && <Button type="primary" loading={submitLoading} onClick={() => t.handleSubmit(docUploadItem)} style={{ marginTop: "5px" }} text="Save">Save</Button>}
                                </FlexColumn>
                            }
                            
                        </FlexColumn>
                    )
                }
            }

            return (
                <FlexColumn key={doc.name}>
                    <InputContainer style={{ margin: "10px 0" }}>
                        <FlexColumn>
                            <Flex between >
                                <Space style={{width: '85%'}}>

                                    {itemUploaded ?
                                        <CheckCircleFilled style={{ fontSize: `${numContainerConst}px`, color: theme.colors.secondary1 }} />
                                        :
                                        <Flex center style={{ background: theme.colors.secondary5, height: numContainerConst, width: numContainerConst, borderRadius: numContainerConst / 2, color: theme.text, fontSize: "17px", fontWeight: 600 }}>{i + 1}</Flex>
                                    }
                                    <Text bold noMargin>{doc.name}</Text>
                                    {doc.toolTip && <Tooltip title={doc.toolTip}><InfoCircleOutlined /></Tooltip>}
                                    <span style={{ color: theme.colors.secondary7 }}>{doc.mandatory ? " *Required" : ""}</span>
                                </Space>
                                <div style={{width: '15%'}}>
                                {doc.externalLink}
                                </div>
                                
                            </Flex>

                            {itemUploaded ?
                                getInputComponent(itemUploaded, true)
                                :
                                getInputComponent(doc, false)
                            }
                        </FlexColumn>
                    </InputContainer>
                    {showBlkSave && <Button permtype="Override" loading={submitLoading} onClick={() => this.handleSubmit(docUploadItem)} text='Save' />}
                </FlexColumn>
            )
        }
        switch (type) {
            case "PFStatement":
                return (
                    <FlexColumn>
                        <InputContainer>
                            <h4>First download the template</h4>
                            <Button id="downloadTemplate" icon={<DownloadOutlined />}>
                                <a href="/Aion-Personal-Financial-Statement-Template.xlsx" download="Aion-Personal-Financial-Statement-Template.xlsx">&nbsp&nbspDownload Template</a>
                            </Button>
                            <div style={{ marginBottom: '30px' }} />
                            <h4>Then upload the completed document</h4>
                            <Upload {...uploadProps(uploadItem)}>
                                <Button icon={<UploadOutlined />} text="Click to Upload">Click to Upload</Button>
                            </Upload>
                        </InputContainer>
                        <Button permtype="Override" loading={submitLoading} onClick={() => this.handleSubmit(uploadItem)} style={{ marginTop: "5px" }} text='Save' />
                    </FlexColumn>
                )
            case "DebtSchedule":
                return (
                    <FlexColumn>
                        <InputContainer>
                            <h4>First download the template</h4>
                            <Button id="downloadTemplate" icon={<DownloadOutlined />}>
                                <a href="/Debt-Schedule-Sample.xlsx" download="Debt-Schedule-Sample.xlsx">&nbsp&nbspDownload Template</a>
                            </Button>
                            <div style={{ marginBottom: '30px' }} />
                            <h4>Then upload the completed document</h4>
                            <Upload {...uploadProps(uploadItem)}>
                                <Button icon={<UploadOutlined />} text="Click to Upload">Click to Upload</Button>
                            </Upload>
                        </InputContainer>
                        <Button permtype="Override" loading={submitLoading} onClick={() => this.handleSubmit(uploadItem)} style={{ marginTop: "5px" }} text='Save' />
                    </FlexColumn>
                )
            default:
                if (requiredDocs) {
                    return (
                        <FlexColumn>
                            {requiredDocs.map((doc, i) => uploadContainer(doc, i))}
                            {/* <Button permtype="Override" onClick={() => this.setState({ showUploadModal: false })}>Close</Button> */}
                        </FlexColumn>
                    )
                } else {
                    return uploadContainer(uploadItem)
                }
        }
    }

    getModalContent = (sectionItem) => {
        var { businessDocuments, pagination, customers, customersLoading } = this.state
        var { theme, loanApp, ucView } = this.props
        businessDocuments = businessDocuments || []
        switch (sectionItem.name) {
            case "Uploaded Documents":
                return (
                    <Table
                        loading={this.state.loading}
                        dataSource={businessDocuments}
                        pagination={pagination.pageable}
                        rowKey='id'
                        columns={[
                            {
                                title: 'Id',
                                width: "100px",
                                dataIndex: 'documentId'
                            },
                            {
                                title: 'Date',
                                width: "100px",
                                dataIndex: 'auditData',
                                render: item => moment(item.createTime).format('MM/DD/YY')
                            },
                            {
                                title: 'Name',
                                dataIndex: 'displayName',
                                render: name => <Text size="0.95rem" bold noMargin>{name}</Text>
                            },
                            {
                                title: 'Period',
                                render: (record) => record.effectiveStartDate ? `${moment(record.effectiveStartDate).format('MM/DD/YY')} to ${moment(record.effectiveEndDate).format('MM/DD/YY')}` : "--"
                            },
                            {
                                title: 'Category',
                                dataIndex: 'category'
                            },
                            {
                                title: 'Download',
                                dataIndex: 'documentUrl',
                                render: item => <SignedLink {...item} />
                            },
                            {
                                title: 'Note',
                                dataIndex: 'namedValues',
                                render: items => (items || []).map(item => item.value).join(", ")
                            },
                            {
                                title: 'Action',
                                width: "100px",
                                render: (text, item) => (
                                    <Space size="middle">
                                        {getResourcePerm("BusinessBanking.Transactions").isAdmin &&
                                            <Popconfirm title="Are you sure you want to delete this file?" onConfirm={() => this.handleDeleteFile(item.documentId)}>
                                                <a><DeleteOutlined style={{ cursor: 'pointer', fontSize: 18 }} /></a>
                                            </Popconfirm>}
                                    </Space>
                                ),
                                align: "right"
                            }]}
                    />
                )
            case "Customers":
                return (
                    <FlexColumn>
                        <Text color={theme.colors.systemGray}>
                            Provide a Master Service Agreement and Purchase Order for each customer
                            <span style={{ color: theme.colors.secondary3 }}><Popover title="MSA or Master Service Agreement" content="A master service agreement is a contract entered into by you and your customer. This agreement details the expectations and the terms to fulfil the contract. A purchase order document could be a work order or a purchase order or a statement of work"> <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)', fontSize: 17 }} /></Popover></span>
                        </Text>
                        <List
                            bordered
                            dataSource={customers}
                            loading={customersLoading}
                            renderItem={customer => (
                                <FlexColumn style={{ padding: "15px" }}>
                                    <Text bold level={2}>{customer.displayName}</Text>
                                    <Flex style={{ alignItems: "start", justifyContent: "space-around" }}>
                                        <MSA customer={customer} loanApp={loanApp} reportMSAStatus msaUploaded={this.updateMSAUploadStatus} underwriter={ucView} />
                                        <Divider type="vertical" style={{ height: "100%" }} />
                                        <WorkOrder customer={customer} loanApp={loanApp} reportWOStatus msaUploaded={this.updateWOUploadStatus} underwriter={ucView} />
                                    </Flex>
                                    <Divider />
                                </FlexColumn>
                            )}
                        />
                    </FlexColumn>
                )
            default:
                return this.getModalContentForDocument(sectionItem)
        }
    }

    getQBODocuments = () => {
        const { theme, creditStore, aionStore } = this.props
        var { loadingItem, businessDocuments } = this.state
        businessDocuments = businessDocuments || []
        var { Profile } = aionStore || {}
        var { qboReports } = creditStore || {}
        var fetchedDocuments = [
            {
                name: `Profit and loss/Income statement`,
                type: "ProfitAndLoss"
            },
            {
                name: `Balance sheet`,
                type: "BalanceSheet"
            },
            {
                name: `AR aging summary`,
                type: "AgedReceivables"
            },
            {
                name: `AP aging summary`,
                type: "AgedPayables"
            }
        ]
        // console.log("qboReports", qboReports.length)
        return (
            qboReports ?
                <FlexColumn>
                    <Text bold level={5} style={{ marginTop: "40px" }}>Automatically retrieved from {Profile.AccountingSW}</Text>
                    <Table
                        key="qboReports"
                        pagination={false}
                        dataSource={qboReports || []}
                        rowKey='ReportName'
                        scroll={{ y: '700px', x: '100%' }}
                        columns={[{
                            title: 'Name',
                            dataIndex: 'ReportName',
                            render: reportName => <Text size="0.95rem" bold noMargin>{(fetchedDocuments.find(item => (item.type == reportName)) || {}).name || reportName}</Text>
                        },
                        {
                            title: 'File',
                            dataIndex: 'Attachment',
                            render: (item, record) => <SignedLink uri={item.URI} fileName={item.FileName} />
                        },
                        {
                            title: 'Period',
                            dataIndex: 'StartPeriod',
                            render: (item, record) => `${moment(record.StartPeriod).format('MM/DD/YY')} to ${moment(record.EndPeriod).format('MM/DD/YY')}`
                        },
                        {
                            title: 'Action',
                            dataIndex: 'ReportName',
                            render: (reportName, record) => {
                                var uploadItem = fetchedDocuments.find(item => (item.type == reportName))
                                if (!uploadItem) uploadItem = { name: reportName, type: reportName }
                                uploadItem.savedFile = record.Attachment
                                uploadItem.loadingItem = record.Id + reportName
                                if (businessDocuments.find(document => (document.documentUrl || {}).fileName == (record.Attachment || {}).FileName)) uploadItem.uploaded = true
                                return (
                                    !uploadItem.uploaded ?
                                        <Flex>
                                            <Button type="link" onClick={() => this.handleSubmit(uploadItem)} text="Use this document">Use this document</Button>
                                            {(loadingItem == (record.Id + reportName)) && <LoadingOutlined />}
                                            <Button type="link" onClick={() => this.setState({ showUploadModal: true, uploadItem: uploadItem })} style={{ paddingRight: 0 }} text="Upload">Upload</Button>
                                        </Flex>
                                        :
                                        <Tag style={{ margin: "5px 0" }} tagpreset={theme.colors.secondary4}>Uploaded <CheckCircleFilled /></Tag>
                                )
                            },
                            align: "right"
                        }]}
                    />
                </FlexColumn>
                :
                null
        )
    }

    handleMenuClick = (e) => {
        console.log("handleMenuClick", e)
        const selectedMenu = e.key
        this.setState({ selectedMenu: e.key })
        if (selectedMenu === "Customers") {
            this.fetchTopCustomers()
        }
    }

    getDocumentUploadSections = () => {
        var { theme, requestFinancials, loanApp, ucView } = this.props;
        var { proposalTerms } = this.state;
        proposalTerms = proposalTerms || {};
        loanApp = loanApp || {};
        var currentYear = moment().format("YYYY");
        var prevYear = moment().subtract(1, "year").format("YYYY");
        var prevToLastYear = moment().subtract(2, "year").format("YYYY");
        console.log("getDocumentUploadSections", loanApp);
        var productType = proposalTerms.productType;
        // if(loanApp.preScreenStatus == "TermLoanApproved") {
        if(!productType) {
            return[{}]
        }
        if (productType == "GNPL") {
            return [
                {
                    name: "General",
                    type: "OrgInfo",
                    requiredDocs: [
                        {
                            name: `EIN Number`,
                            limit: 9,
                            id: "ein",
                            inputType: "Text",
                            placeholder: "455161210",
                            hide: ucView,
                            mandatory: true
                        },
                        {
                            name: `NAICS code (6 digits)`,
                            limit: 6,
                            id: "naicsCode6",
                            inputType: "Text",
                            placeholder: "511210",
                            mandatory: true,
                            hide: ucView,
                            externalLink: <a href='https://www.naics.com/search' target='_blank' style={{ textDecoration: "underline" }}>NAICS Code Lookup</a>
                        },
                        {
                            name: `EIN verification letter`,
                            inputType: "Document",
                            docType: "pdf",
                            mandatory: true
                        },
                        {
                            name: `Driver License`,
                            inputType: "Document",
                            docType: "jpeg",
                            toolTip: <Text style={{ color: theme.body }}>Upload front image of license</Text>,
                            mandatory: true
                        }
                    ]
                },
                {
                    name: "Financials",
                    type: "Financials",
                    requiredDocs: [
                        {
                            name: `${prevYear} Tax Returns`,
                            inputType: "Document",
                            docType: "pdf",
                            effectivePeriod: prevYear,
                            mandatory: true,
                        },
                        {
                            name: `${prevToLastYear} Tax Returns`,
                            inputType: "Document",
                            docType: "pdf",
                            effectivePeriod: prevToLastYear,
                            mandatory: true,
                        },
                        {
                            name: `External Bank Statement`,
                            inputType: "Document",
                            docType: "pdf",
                            hide: !ucView,
                            mandatory: false,
                        },
                        // {
                        //     name: `Company-prepared balance sheet — most recent closed month`,
                        //     inputType: "Document",
                        //     docType: "pdf",
                        //     hide: !requestFinancials,
                        //     mandatory: true,
                        // },
                        // {
                        //     name: `Company-prepared balance sheet — most recent fiscal year end`,
                        //     inputType: "Document",
                        //     docType: "pdf",
                        //     hide: !requestFinancials,
                        //     mandatory: true,
                        // },
                        // {
                        //     name: `Company-prepared profit & loss statement — YTD through most recent closed month`,
                        //     inputType: "Document",
                        //     docType: "pdf",
                        //     hide: !requestFinancials,
                        //     mandatory: true,
                        // },
                        // {
                        //     name: `Company-prepared profit & loss statement — full year for most recent fiscal year end`,
                        //     inputType: "Document",
                        //     docType: "pdf",
                        //     hide: !requestFinancials,
                        //     mandatory: true,
                        // },
                        {
                            name: `Personal financial statements for all owners with 20% or more`,
                            type: "PFStatement",
                            inputType: "Document",
                            docType: "csv",
                            allOwners: true
                        }
                    ]
                }
            ]
        } else {
            return [
                {
                    name: "General",
                    type: "OrgInfo",
                    requiredDocs: [
                        {
                            name: `Parent company name`,
                            id: "parentCompanyName",
                            inputType: "Text",
                            placeholder: "Enter your business name if you don't have a parent company",
                        },
                        {
                            name: `NAICS code (6 digits)`,
                            limit: 6,
                            id: "naicsCode6",
                            inputType: "Text",
                            placeholder: "511210",
                            mandatory: true,
                            // toolTip: <Text style={{ color: theme.body }}>Please use this link <a href='https://www.naics.com/search/#naics' target='_blank'>https://www.naics.com/search</a></Text>,
                            externalLink: <a href='https://www.naics.com/search' target='_blank' style={{ textDecoration: "underline" }}>NAICS Code Lookup</a>
                        },
                        {
                            name: `List of operating states`,
                            inputType: "List",
                            mandatory: true,
                            namedValues: [{ name: "Headers", value: "Name" }]
                        },
                        {
                            name: `List of related corporate entities/affiliates/subsidiaries`,
                            inputType: "List",
                            mandatory: true,
                            namedValues: [{ name: "Headers", value: "Name" }]
                        }
                    ]
                },
                {
                    name: "Organization",
                    type: "OrgInfo",
                    requiredDocs: [
                        {
                            name: `Bylaws or Operating Agreement`,
                            inputType: "Document",
                            docType: "pdf",
                            toolTip: <Text style={{ color: theme.body }}>E.g. Operating or partnership agreements, bylaws and articles of Incorporation</Text>,
                            mandatory: true
                        },
                        {
                            name: `Officers & Executives authorized to negotiate/sign on behalf of Company`,
                            inputType: "List",
                            toolTip: <Text style={{ color: theme.body }}>Name and title for each executive</Text>,
                            namedValues: [{ name: "Headers", value: "Name,Title" }]
                        }
                    ]
                },
                {
                    name: "Financials",
                    type: "Financials",
                    requiredDocs: [
                        {
                            name: `${prevYear} Tax Returns`,
                            inputType: "Document",
                            docType: "pdf",
                            effectivePeriod: prevYear,
                            mandatory: true,
                        },
                        {
                            name: `${prevToLastYear} Tax Returns`,
                            inputType: "Document",
                            docType: "pdf",
                            effectivePeriod: prevToLastYear,
                            mandatory: true,
                        },
                        {
                            name: `External Bank Statement`,
                            inputType: "Document",
                            docType: "pdf",
                            hide: !ucView,
                            mandatory: false,
                        },
                        // {
                        //     name: `Profit And Loss: Year to closing date`,
                        //     inputType: "Document",
                        //     docType: "pdf",
                        //     hide: !requestFinancials,
                        //     mandatory: true,
                        // },
                        // {
                        //     name: `Profit And Loss: Previous year`,
                        //     inputType: "Document",
                        //     docType: "pdf",
                        //     hide: !requestFinancials,
                        //     mandatory: true,
                        // },
                        // {
                        //     name: `Balance Sheet: As of last closed month`,
                        //     inputType: "Document",
                        //     docType: "pdf",
                        //     hide: !requestFinancials,
                        //     mandatory: true,
                        // },
                        // {
                        //     name: `Balance Sheet: As of end of previous year`,
                        //     inputType: "Document",
                        //     docType: "pdf",
                        //     hide: !requestFinancials,
                        //     mandatory: true,
                        // },
                        {
                            name: `AR Aging: As of last closed month`,
                            inputType: "Document",
                            docType: "pdf",
                            hide: !requestFinancials,
                            mandatory: true,
                        },
                        // {
                        //     name: `AR Aging: As of end of previous year`,
                        //     inputType: "Document",
                        //     docType: "pdf",
                        //     hide: !requestFinancials,
                        //     mandatory: true,
                        // },
                        {
                            name: `AP Aging: As of last closed month`,
                            inputType: "Document",
                            docType: "pdf",
                            hide: !requestFinancials,
                            mandatory: true,
                        },
                        // {
                        //     name: `AP Aging: As of end of previous year`,
                        //     inputType: "Document",
                        //     docType: "pdf",
                        //     hide: !requestFinancials,
                        //     mandatory: true,
                        // },
                        {
                            name: `Capitalization table: List of shareholders and ownership`,
                            inputType: "Document",
                            docType: "csv",
                            mandatory: true,
                        },
                        {
                            name: `Personal financial statements for all individuals who own 25% or more. Please use the template provided here:`,
                            type: "PFStatement",
                            inputType: "Document",
                            docType: "csv",
                            allOwners: true,
                            externalLink: <a onClick={() => this.downloadStatement("https://s3-us-west-1.amazonaws.com/fpb-business-documents/templates/PersonalFinancialStatement_AION_fillable.pdf")} target='_blank' style={{ textDecoration: "underline" }}>Download Template</a>
                        },
                        // {
                        //     name: `Debt schedule form - Personal for all owners with 20% or more`,
                        //     type: "DebtSchedule",
                        //     inputType: "Document",
                        //     docType: "csv",
                        //     allOwners: true,
                        // },
                        {
                            name: `Business Debt Schedule Form using the template provided here: `,
                            inputType: "Document",
                            docType: "csv",
                            externalLink: <a onClick={() => this.downloadStatement("https://s3-us-west-1.amazonaws.com/fpb-business-documents/templates/Form-Aion_Client_Debt_Schedule.xlsx")} target='_blank' style={{ textDecoration: "underline" }}>Download Template</a>
                            },
                        {
                            name: `Copies of promissory/convertible notes corresponding to debt schedule`,
                            inputType: "Document",
                            docType: "pdf",
                            limit: 10
                        },
                        {
                            name: `Charge-offs by customer`,
                            inputType: "Document",
                            docType: "csv"
                        },
                    ]
                },
                {
                    name: "Customers",
                    type: "Customers"
                },
                // {
                //     name: "Uploaded Documents",
                //     type: "Uploaded Documents"
                // }
            ]
        }

    }

    checkMandatoryDocsStatus = () => {
        var { businessDocuments } = this.state
        businessDocuments = businessDocuments || []
        var documentUploadSections = this.getDocumentUploadSections()
        var dbMissingSections = []
        documentUploadSections.forEach(item => {
            if (item.requiredDocs) {
                item.requiredDocs.forEach(reqDocument => {
                    var itemUploaded = businessDocuments.find(document => (document.name == reqDocument.name || document.displayName == reqDocument.name))
                    if (!itemUploaded && reqDocument.mandatory && reqDocument.inputType != "Text") {
                        if (dbMissingSections.indexOf(item.name) == -1) dbMissingSections.push(item.name)
                    }
                })
            }
        })
        console.log("checkMandatoryDocsStatus", dbMissingSections)
        this.props.dispatch(addDataToStore(CREDIT_SAVE_DATA, { dbMissingSections: dbMissingSections }))
    }

    updateMSAUploadStatus = (customer, msas) => {
        this.setState({ [`msa_uploaded_${customer.customerId}`]: (msas || []).length > 0 })
        this.checkMSAUploadStatus()
    }

    checkMSAUploadStatus = () => {
        var { customers } = this.state
        customers = customers || []
        var dbMissingMSAs = []
        customers.forEach(customer => {
            if (!this.state[`msa_uploaded_${customer.customerId}`]) {
                if (dbMissingMSAs.indexOf(customer.displayName) == -1) dbMissingMSAs.push(customer.displayName)
            }
        })
        console.log("checkMSAUploadStatus", dbMissingMSAs, customers)
        this.props.dispatch(addDataToStore(CREDIT_SAVE_DATA, { dbMissingMSAs: dbMissingMSAs }))
    }

    updateWOUploadStatus = (customer, pos) => {
        this.setState({ [`wo_uploaded_${customer.customerId}`]: (pos || []).length > 0 })
        this.checkWOUploadStatus()
    }

    checkWOUploadStatus = () => {
        var { customers } = this.state
        customers = customers || []
        var dbMissingWOs = []
        customers.forEach(customer => {
            if (!this.state[`wo_uploaded_${customer.customerId}`]) {
                if (dbMissingWOs.indexOf(customer.displayName) == -1) dbMissingWOs.push(customer.displayName)
            }
        })
        console.log("checkWOUploadStatus", dbMissingWOs, customers)
        this.props.dispatch(addDataToStore(CREDIT_SAVE_DATA, { dbMissingWOs: dbMissingWOs }))
    }

    render() {
        const { theme, showUploadedOnly, requestFinancials } = this.props
        var { showUploadModal, uploadItem, businessDocuments, pagination, selectedMenu, showAddModalMSA, msaModalCustomer } = this.state
        uploadItem = uploadItem || {}
        businessDocuments = businessDocuments || []

        var documentUploadSections = this.getDocumentUploadSections()
        documentUploadSections.forEach(item => {
            if (item.requiredDocs) {
                item.uploaded = true
                item.requiredDocs.forEach(reqDocument => {
                    reqDocument.section = item.name
                    if (!businessDocuments.find(uploadedDocument => reqDocument.name == uploadedDocument.name)) item.uploaded = false
                })
            } else if (businessDocuments.find(document => document.name == item.name)) item.uploaded = true
        })
        var sectionItem = documentUploadSections.find(item => (item.name == selectedMenu)) || {}
        return (
            <>
                <FlexColumn>
                    {/* {!showUploadedOnly && <Divider style={{ margin: "0" }} />} */}
                    {!showUploadedOnly &&
                        <>
                            {/* <Text bold level={5} style={{ marginTop: "25px" }}>Documents to upload</Text> */}
                            <Flex style={{ justifyContent: "start" }}>
                                <Menu
                                    style={{ width: "250px", fontSize: "16px" }}
                                    defaultSelectedKeys={[selectedMenu]}
                                    mode="inline"
                                    onClick={this.handleMenuClick}
                                >
                                    {documentUploadSections.map(section => (
                                        <Menu.Item key={section.name}>
                                            {section.name}
                                        </Menu.Item>
                                    ))}
                                </Menu>
                                <FlexColumn style={{ height: "100%", padding: "10px 40px", border: `1px solid ${theme.colors.systemGray6}`, width: "100%", minHeight: "700px", justifyContent: "start" }}>
                                    {
                                        this.state.loadingContents ? 
                                        <Text heading><span style={{ color: theme.colors.secondary3 }}>{sectionItem.name}</span></Text>
                                        :
                                        <Text heading><span style={{ color: theme.colors.secondary3 }}>{sectionItem.name || "Upload Documents"}</span></Text>
                                    }
                                    {/* <Text noMargin>Provide the following information</Text> */}
                                    {this.getModalContent(sectionItem)}
                                </FlexColumn>
                            </Flex>
                        </>}
                </FlexColumn>
                <Modal
                    visible={showUploadModal}
                    footer={null}
                    closable={true}
                    width={600}
                    style={{ top: 20 }}
                    destroyOnClose={true}
                    onCancel={() => { this.setState({ showUploadModal: false }) }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <FlexColumn style={{ height: "100%", padding: "20px" }}>
                        <Text heading>Upload <span style={{ color: theme.colors.secondary3 }}>{uploadItem.name || "File"}</span></Text>
                        <Text>Upload your document</Text>
                        {
                            this.getModalContentForDocument(uploadItem)
                        }
                    </FlexColumn>
                </Modal>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer,
        creditStore: state.creditAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(BusinessDocuments)))