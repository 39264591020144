import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { saveAs } from 'file-saver';
import {
    Modal,
    Skeleton,
    Table,
    Select,
    Space,
    Divider,
} from 'antd';
import { LoadingOutlined, FileExcelOutlined } from '@ant-design/icons';

// Components
import PageHeader from "../../Reusable/PageHeader";
import ReportTable from './ReportTable';
import { Flex, FlexColumn, Container } from '../../Reusable/Container';
import { LabeledInput } from '../../Reusable/Input';
import { ErrorAlert } from '../../Reusable/Alert';
import { TextButton } from '../../Reusable/Button';
import { Text, Tag } from '../../Reusable/Text';
import AccountStatement from '../AccountStatement/Index'

// Util
import { apiGET, apiPOST, apiPOSTReq } from '../../../Utils/api';
import environment from '../../../environment';

// Actions
import { CREDIT_SAVE_DATA, addDataToStore } from '../../../Actions/actions';
import { withTheme } from 'styled-components';

import CSV from '../../../Images/csv.png'
import ModalClose from '../../../Images/modal-close.png';
import { getDate } from '../../../Utils/util';
import TableView from '../../Reusable/TableView';


const { Option } = Select;

const dFormat = "YYYY-MM-DD";
const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customize: true,
            loadingPassThrough: true,
            loadingTransactions: true,
            loadingReport: true,
            pagination: {
                current: 1,
                pageSize: 100,
            },
            modalWidth: 900,
            dateType: "Year to Date",
        };

        this.getDate = getDate.bind(this);
        this.fetchData = this.fetchData.bind(this)
        this.handleDateTypeSelection = this.handleDateTypeSelection.bind(this)
        this.downloadCSVReport = this.downloadCSVReport.bind(this)
    }

    componentDidMount() {
        this.handleDateTypeSelection('Year to Date');
        this.fetchTransactions()
        this.fetchPassThrough()
    }

    componentDidUpdate(prevProps) {
        let { location } = this.props
        var state = location.state ? location.state : {}
        if (state.reloadData) {
            this.props.location.state = {}
            this.handleDateTypeSelection('Year to Date');
            this.fetchTransactions()
            this.fetchPassThrough()
        }
    }

    fetchData(options) {
        let { fromDate, toDate, pagination } = options;

        let fetchOptions = {
            sortFieldDirection: 'DESC',
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            size: this.state.pagination.pageSize,
            page: this.state.pagination.current - 1,
            download: false,
        }
        if (pagination) {
            fetchOptions.size = pagination.pageSize;
            fetchOptions.page = pagination.current - 1;
        }

        if (fromDate) {
            fetchOptions.fromDate = fromDate;
            fetchOptions.toDate = toDate;
        }
        this.setState({ loadingReport: true })

        if (!pagination) {
            pagination = this.state.pagination;
        }
        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/getDailyReport`, {}, fetchOptions, (err, resp) => {
            try {
                const data = resp || {};
                if (data) {
                    this.setState({
                        ...options,
                        loadingReport: false,
                        dailyReportItemList: data.dailyReportItemList,
                        pagination: {
                            ...pagination,
                            total: data.count,
                        },
                        availableStatements: data.availableStatements
                    })
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/counterparty err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    downloadCSVReport() {
        let fetchOptions = {
            sortFieldDirection: 'DESC',
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            download: true,
        }

        this.setState({ loadingCsv: true })

        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/getDailyReport`, {}, fetchOptions, (err, resp) => {
            this.setState({ loadingCSV: false });
            try {
                const data = resp || {};
                if (data) {
                    const blob = new Blob([data.dailyReportCsv], {
                        type: 'application/vnd.ms-excel',
                    });
                    const fileURL = URL.createObjectURL(blob);
                    saveAs(fileURL, `Daily Report ${this.state.fromDate} to ${this.state.toDate}.csv`);
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/counterparty err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    handleDateTypeSelection = (val) => {
        let { fromDate, toDate } = this.getDate(val);

        this.setState({
            dateType: val,
        })
        this.fetchData({ fromDate, toDate })
    }

    handleDateRange = (dates) => {
        this.fetchData({
            fromDate: dates[0].format(dFormat),
            toDate: dates[1].format(dFormat),
            dateType: "Custom"
        });
    }

    fetchTransactions() {
        const headers = {
            businesskey: this.props.aionStore.BusinessUniqueKey,
            customerid: ''
        }

        apiGET("/financing/transactions", headers, (err, resp) => {
            try {
                const data = resp.data;
                console.log("/transactions", data)
                if (data.success) {
                    var transactions = data.Response.Transactions.sort((a, b) => new Date(b.Date) - new Date(a.Date))

                    this.setState({
                        transactions: transactions,
                        outstandingBalance: data.Response.OutstandingBalance,
                        interestAsOfToday: data.Response.InterestAsOfToday,
                        loadingTransactions: false
                    })
                } else {
                    throw Error("Could not fetch transactions.")
                }
            } catch (error) {
                console.log("ERRR", error.stack)
            }
        })
    }

    fetchSettlements() {
        const headers = {
            businesskey: this.props.aionStore.BusinessUniqueKey
        }

        apiGET("/financing/settlements", headers, (err, resp) => {
            try {
                const data = resp.data;
                console.log("/financing/settlements", data)
                if (data.success) {
                    var settlements = data.Response.sort((a, b) => new Date(b.SettlementDate) - new Date(a.SettlementDate))

                    this.setState({
                        settlements: settlements,
                        loadingSettlements: false
                    })
                } else {
                    throw Error("Could not fetch transactions.")
                }
            } catch (error) {
                console.log("ERRR", error.stack)
            }
        })
    }

    fetchPassThrough() {
        const headers = {
            businesskey: this.props.aionStore.BusinessUniqueKey,
            customerid: ''
        }

        apiGET("/financing/passthrough", headers, (err, resp) => {
            try {
                const data = resp.data;
                console.log("/passthrough", data)
                if (data.success) {
                    var passThrough = data.Response.Transactions.sort((a, b) => new Date(b.Date) - new Date(a.Date))

                    this.setState({
                        passThrough: passThrough,
                        loadingPassThrough: false
                    })
                } else {
                    throw Error("Could not fetch pass through transactions.")
                }
            } catch (error) {
                console.log("ERRR", error.stack)
            }
        })
    }

    disabledDate = (current) => {
        // Can not select days after today
        return current > moment().endOf('day');
    }

    render() {
        const { transactions, passThrough,
            loadingTransactions, loadingPassThrough,
            fromDate, toDate, loadingCSV } = this.state;
        const { theme } = this.props;

        const columns = [
            {
                title: 'Date',
                dataIndex: 'Date',
                key: 'Date',
                render: date => (moment(date).format('MMM Do, YYYY')),
                width: '100pt'
            },
            {
                title: 'Customer',
                dataIndex: 'CustomerName',
                key: 'CustomerName',
                width: '120pt'
            },
            {
                title: 'Description',
                dataIndex: 'DisplayDescription',
                key: 'Description',
            },
            {
                title: 'Type',
                dataIndex: 'DisplayType',
                key: 'Type',
            },
            {
                title: 'Credit/Debit',
                dataIndex: 'Amount',
                key: 'Amount',
                render: amount => (<b>{formatter.format(amount)}</b>),
                width: '100pt'
            },
            {
                title: 'Running Balance',
                dataIndex: 'BalanceEnd',
                key: 'BalanceEnd',
                render: balanceEnd => (formatter.format(balanceEnd)),
                width: '100pt'
            },
        ];

        const passThroughColumns = [
            {
                title: 'Date',
                dataIndex: 'Date',
                key: 'Date',
                render: date => (moment(date).format('MMM Do, YYYY')),
                width: '100pt'
            },
            {
                title: 'Customer Name',
                dataIndex: 'CustomerName',
                key: 'CustomerName',
            },
            {
                title: 'Type',
                dataIndex: 'DisplayType',
                key: 'Type',
            },
            {
                title: 'Description',
                dataIndex: 'Description',
                key: 'Description',
            },
            {
                title: 'Amount',
                dataIndex: 'AmountStr',
                key: 'AmountStr',
                render: amount => (`$${amount}`),
                width: '100pt'
            },
        ];

        const DateRangeDropdown = () => {
            const dateOptions = ["Year to Date", "Current Month", "Previous Month", "Last Year", "Custom"];
            const dropdownOptions = dateOptions.map(item => (
                <Option key={item} value={item}>
                    {item}
                </Option>
            ));

            return (
                <Skeleton loading={this.state.loadingDateRange} active title={false} paragraph={{ rows: 1 }}>
                    <Space size="large" style={{ marginBottom: 24 }}>
                        <LabeledInput
                            label="Date"
                            labelcolor={theme.colors.secondary3}
                            id="statementType"
                            key="statementType"
                            type="select"
                            placeholder="Select"
                            className="no-left-padding"
                            value={this.state.dateType}
                            onChange={this.handleDateTypeSelection}
                            style={{ width: "150px", fontSize: "0.95rem", fontWeight: 500 }}
                            noAsterisk
                        >
                            {dropdownOptions}
                        </LabeledInput>
                        <LabeledInput
                            label="Date Range"
                            labelcolor={theme.colors.secondary3}
                            id="dateRange"
                            key="dateRange"
                            type="range-picker"
                            disabledDate={this.disabledDate}
                            value={[moment(fromDate), moment(toDate)]}
                            format="MM/DD/YYYY"
                            onChange={this.handleDateRange}
                            style={{ width: "300px", fontSize: "1.1rem", fontWeight: 500 }}
                            noAsterisk
                        />
                        <FlexColumn >
                            <Text size='14px' color={theme.colors.secondary3} style={{ marginBottom: "3px" }}>Download</Text>
                            <Space>
                                <FileExcelOutlined style={{ color: theme.colors.secondary3, fontSize: 20 }} onClick={this.downloadCSVReport} />
                                {loadingCSV && <LoadingOutlined style={{ color: theme.colors.secondary3, fontSize: 22 }} />}
                            </Space>
                        </FlexColumn>
                        <Divider type="vertical" style={{ margin: "0 10px", height: "50px", borderColor: theme.colors.systemGray6 }} />
                        <FlexColumn >
                            <Text size='14px' color={theme.colors.secondary3} style={{ marginBottom: "3px" }}>Monthly Statements</Text>
                            <Space>
                                <Tag tagpreset={theme.colors.secondary8} style={{ marginTop: "7px" }} onClick={e => this.setState({ showAccountStmtModal: true })}>VIEW</Tag>
                            </Space>
                        </FlexColumn>
                    </Space>
                </Skeleton>
            )
        }

        return (
            <FlexColumn className='main-padding'>
                <PageHeader
                    titleText="Credit"
                    subtitle="Revolving Line of Credit"
                    ctaContent={
                        <Flex>
                            {/* <TextButton type='link' onClick={() => { this.setState({ showPaymentModal: true }) }} text='Payments' /> */}
                            {/* <Divider type="vertical" style={{ margin: "0 20px", height: "25px", borderColor: theme.colors.systemGray4 }} /> */}
                            {/*<TextButton type='link' labelcolor={theme.colors.secondary3} onClick={() => { this.setState({ showPassThroughModal: true }) }} text='Pass Through' /> */}
                        </Flex>
                    }
                />

                {!this.state.loadingTransactions ? <DateRangeDropdown /> : null}
                <Skeleton loading={this.state.loadingReport} active title={false} paragraph={{ rows: 3 }}>
                    {<ReportTable 
                        componentType="Reports"
                        reports={this.state.dailyReportItemList || []}
                        fetchReport={this.fetchData}
                        pagination={this.state.pagination}
                    />}
                </Skeleton>

                <Modal
                    visible={this.state.showPaymentModal}
                    footer={null}
                    closable={true}
                    width={this.state.modalWidth}
                    style={{ top: 20 }}
                    destroyOnClose={true}
                    onCancel={() => this.setState({ showPaymentModal: false })}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <TableView
                        style={{ marginTop: 28 }}
                        tableLayout='auto'
                        columns={columns}
                        dataSource={transactions}
                        loading={loadingTransactions}
                        scroll={{ y: '700px', x: '100%' }}
                    />
                </Modal>
                <Modal
                    visible={this.state.showPassThroughModal}
                    footer={null}
                    closable={true}
                    width={this.state.modalWidth}
                    style={{ top: 20 }}
                    destroyOnClose={true}
                    onCancel={() => this.setState({ showPassThroughModal: false })}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <TableView
                        style={{ marginTop: 28 }}
                        tableLayout='auto'
                        columns={passThroughColumns}
                        dataSource={passThrough}
                        loading={loadingPassThrough}
                        scroll={{ y: '700px', x: '100%' }}
                    />
                </Modal>
                <Modal
                    visible={this.state.showAccountStmtModal}
                    footer={null}
                    onCancel={() => { this.setState({ showAccountStmtModal: false }) }}
                    width={500}
                    style={{ top: 20 }}
                    destroyOnClose={true}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <AccountStatement business={this.props.aionStore.BusinessUniqueKey} availableStatements={this.state.availableStatements} modal />
                </Modal>
            </FlexColumn>
        );
    }
}

function mapStateToProps(state) {
    return {
        store: state.creditAppReducer,
        aionStore: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)));