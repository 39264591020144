import React, { Component } from 'react';
import { message } from 'antd';
import AddModalBase from './AddModalBase';

// Util
import environment from '../../../environment'
import { apiPOSTReq } from '../../../Utils/api'
import { ErrorAlert } from '../../Reusable/Alert';
import { getCustomerName } from '../../../Utils/util';
import moment from 'moment';

class AddModalWO extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showError: false,
            EndDate: null,
            StartDate: null,
            totalValue: null,
            FileName: null,
            URI: null,
            woNumber: null,
            masterServiceAgreementId: null
        };
    }

    onCancel = () => {
        this.setState({ 
            showError: false,
            EndDate: null,
            StartDate: null,
            totalValue: null,
            FileName: null,
            URI: null,
            woNumber: null,
            masterServiceAgreementId: null
         });
        this.props.onCancel();
    }

    submitWO = (values) => {
        const msa = (this.props.msas || []).find(x => x.masterServiceAgreementId === values.masterServiceAgreementId);
        let msaName = msa ? msa.documentUrl.fileName : '';
        var { aionStore, customerId, customer ,loanApp} = this.props;
        customer = customer || {};
        this.setState({
            EndDate: values.EndDate,
			StartDate: values.StartDate,
            totalValue: values.totalValue,
            FileName: values.FileName,
            URI: values.URI,
            woNumber: values.woNumber,
            masterServiceAgreementId: values.masterServiceAgreementId
        })
        if(!values.woNumber) {
            ErrorAlert({ description: `Please provide Document Number` });
            return;
        }
        if (!values.StartDate) {
            ErrorAlert({ description: `Please provide Valid From` });
            return;
        }
        if (!values.EndDate) {
            ErrorAlert({ description: `Please provide Valid Through` });
            return;
        }
        if (!values.totalValue) {
            ErrorAlert({ description: `Please provide Total Value` });
            return;
        }
        if (moment(values.EndDate).diff(moment(values.StartDate)) < 0) {
            ErrorAlert({ description: `Please provide Valid Through` });
            return;
        }
        if(!msaName) {
            ErrorAlert({ description: `Please provide MSA` });
            return;
        }
        if (!values.FileName) {
			ErrorAlert({description: `Please upload a supporting document.`})
			return
		}
        if (!values.totalValue) {
			ErrorAlert({description: `Please provide Total Value.`})
			return
		}
        this.setState({ showError: false });
        const headers = {
            AionCurrentBiz: aionStore.BusinessUniqueKey,
            AionAuth: aionStore.UAM.encryptedAuthHeader // This will be replaced with the token received from the access mgmt service
        }
        var body = {
            po: {
                "businessId": aionStore.BusinessUniqueKey,
                "ponumber": values.woNumber,
                "customerId": customerId,
                "validFrom": moment(values.StartDate).toDate(),
                "validThrough": moment(values.EndDate).toDate(),
                "dueDate": moment(values.EndDate).toDate(),
                "documentUrl": { "uri": values.URI },
                "customerName": customer.displayName,
                "msaId": values.masterServiceAgreementId,
                "totalValue": values.totalValue ? parseFloat(values.totalValue.toString().replaceAll(',', '')) : null,
                "msaName": msaName,
                // "rootCustomerId": customer.rootCustomerId,
                // "rootCustomerName": customer.rootCustomerName
            }
        }
        if(loanApp) body.po.applicationId = loanApp.applicationId;
        console.log("submitWO values", values);
        console.log("submitWO", body);
        console.log("submitWO msas", this.props.msas);
        apiPOSTReq(`${environment.iveBaseUrl}/ive/po/save`, headers, body, (err, resp) => {
            try {
                console.log("/validate", err, resp);
                const data = resp || {};
                if (!data.error) {
                    this.setState({
                        EndDate: null,
                        StartDate: null,
                        FileName: null,
                        URI: null,
                        totalValue: null,
                        woNumber: null,
                        masterServiceAgreementId: null
                    });
                    message.success('Document added successfully!');
                    this.props.onAdd(); // Closes modal and refresh
                } else {
                    throw Error(data.error || "Could not complete submission.");
                }
            } catch (error) {
                alert(error.message || "Sorry, we had trouble processing your request. Please try again.");
            }
        })
    }

    render() {
        return (
            <AddModalBase
                type="WO"
                visible={this.props.visible}
                title="Purchase Order"
                selectMSA={true}
                msas={this.props.msas || []}
                onAdd={this.props.onAdd}
                onCancel={this.onCancel}
                onSubmit={this.submitWO}
                showError={this.state.showError}
                errorMsg={this.state.errorMsg}
                aionStore={this.props.aionStore}
                store={this.props.store}
                EndDate={this.state.EndDate}
                StartDate={this.state.StartDate}
                totalValue={this.state.totalValue}
                FileName={this.state.FileName}
                URI={this.state.URI}
                woNumber={this.state.woNumber}
                masterServiceAgreementId={this.state.masterServiceAgreementId}
            />
        )
    }
}

export default AddModalWO;
