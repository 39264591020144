import React, { Component } from 'react'
import { withTheme } from 'styled-components';
import styled from 'styled-components';
import moment from 'moment'
import axios from 'axios';

import { Divider, Skeleton, Empty } from 'antd'
import { LabeledInput } from '../Reusable/Input';
import { STATES_MAP } from '../../Utils/util'
import { TextButton } from '../Reusable/Button';
import { Image } from '../Reusable/Image';

import PlaceMarkerImg from '../../Images/place-marker.png';
import SearchIcon from '../../Images/search.png';
import XIcon from '../../Images/icon-x.svg';
import { FlexColumn } from '../Reusable/Refresh/Container';
import { Flex } from '../Reusable/Container';
import { Paragraph } from '../Reusable/Refresh/Text';

const cardStyle = {
    maxWidth: "400px",
    margin: "20px 0 40px 0",
    padding: "20px 20px",
    borderRadius: "15px",
    backgroundColor: "rgba(242, 242, 247, 0.6)"
}

const Entry = styled.div`
    cursor: pointer;
    padding: 3px 4px;
    font-size: 16px;
    &:hover {
        background: ${props => props.theme.colors.systemGray7};
    }
`;

class SearchBusiness extends Component {

    state = {
        searchText: this.props.searchText || "",
        companies: this.props.searchResults || [],
        totalCount: 0,
        loading: false
    };
    cancel = '';

    fetchSearchResults = (searchText) => {
        const searchUrl = `https://api.opencorporates.com/v0.4/companies/search?api_token=XICh3unxYK7xknx3Pujc
            &country_code=us&inactive=false&branch=false&order=score&per_page=50&q=${searchText}`;

        // cancel the previous request if it's still going
        if (this.cancel) {
            this.cancel.cancel();
        }
        this.cancel = axios.CancelToken.source();

        axios.get(searchUrl, {
            cancelToken: this.cancel.token
        })
        .then(res => {
            const {results} = res.data;
            this.setState({
                companies: this.state.searchText ? results.companies : [],
                totalCount: this.state.searchText ? results.total_count : 0,
                loading: false
            })
            this.props.onSearchResults(results.companies);
        })
        .catch(error => {
            if (!axios.isCancel(error)) {
                // if the error if not caused by request being canceled, then remove the loading indicator
                this.setState({
                    loading: false
                })
                this.props.onSearchResults(null);
            }
        })
    };

    handleInputChange = (searchText) => {
        if (!searchText && (searchText == "")) {
            this.setState({ searchText, companies: [], totalCount: 0, loading: false });
        } else {
            this.setState({ searchText, companies: [], loading: true });
            this.fetchSearchResults(searchText);
        }
        this.props.onSearchText(searchText);
    };

    handleSelectBusiness = (company) => {
        this.props.onSelectBusiness(company);
    }

    handleClearSearch = () => {
        this.setState({ searchText: "", companies: [], totalCount: 0, loading: false })
    }

    getBusinessListItem = (result, options) => {
        const { noMatches } = options || {};
        const { searchText } = this.state;

        const boldSubStr = (str, query) => {
            query = query || "";
            const n = str.toUpperCase();
            const q = query.toUpperCase();
            const x = n.indexOf(q);
            if (!q || x === -1) {
                return str; // bail early
            }
            const l = q.length;
            return <span>{str.substr(0, x)} <b>{str.substr(x, l)}</b> {str.substr(x + l)}</span>
        }
        
        const getFullStateName = (jurisdiction_code) => {
            let state = jurisdiction_code.substr(jurisdiction_code.lastIndexOf('_') + 1);
            return STATES_MAP[state.toUpperCase()];
        }

        const company = result.company || {};
        var name = (company.name || "");
        name = boldSubStr(name, searchText);
        // console.log("result.company", result.company);
        return (
            <div key={result.company.company_number}>
                <Entry onClick={() => this.handleSelectBusiness(result.company)}>
                    <Flex between style={{ gap: "8px" }}>
                        <FlexColumn style={{ lineHeight: "24px", gap: "4px" }}>
                            <Paragraph noMargin>{name}</Paragraph>
                            <Paragraph noMargin style={{color: this.props.theme.colors.defaultLightText, fontSize: "14px"}}>{ !noMatches ? company.registered_address_in_full : "This company is not available in our database. Click here and enter the information manually."}</Paragraph>
                        </FlexColumn>
                        {!noMatches && <span style={{ color: this.props.theme.colors.defaultLightText, lineHeight: "20px", fontSize: "12px" }}>
                            Incorporated {company.incorporation_date ? "on " + moment(company.incorporation_date).format('MMM D, YYYY') : ""} in {getFullStateName(result.company.jurisdiction_code)}
                        </span>}
                    </Flex>
                    
                </Entry>
            </div>
        )
    }

    render() {
        const { theme } = this.props;
        const { closeDropdown } = this.state;
        
        const renderSearchResults = () => {
            const { loading, companies, searchText } = this.state;
            if (!loading && (companies.length == 0) && (searchText != ""))
                return this.getBusinessListItem({ company: { name: searchText } }, { noMatches: true })
                /*(
                    <FlexColumn style={{ height: "120px" }}>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No matches found" />
                    </FlexColumn>  
                )*/
            else if (loading) {
                return  (
                    <FlexColumn style={{ height: "120px" }}>
                        <Skeleton active paragraph={{ rows: 2 }} />
                    </FlexColumn>
                )
            }
            else
                return this.state.companies.map(result => this.getBusinessListItem(result));
        };
        
        return (
            <LabeledInput
                autoFocus
                type="remote-search"
                showArrow={false}
                labelcolor={this.props.theme.colors.secondary3}
                placeholder={this.state.searchText == "" ? "Search for your business" : ""}
                searchValue={this.state.searchText}
                onSearch={this.handleInputChange}
                symbolprefix={<Image height='16px' width='16px' src={SearchIcon} />}
                symbolsuffix={
                    (this.state.searchText != "") && <TextButton onClick={() => this.handleClearSearch()} text={<img height={"15.5px"} width={"15.5px"} src={XIcon} />} />
                }
                open={(this.state.searchText != "") && !closeDropdown}
                onBlur={() => { this.setState({ closeDropdown: true }) }}
                onFocus={() => { this.setState({ closeDropdown: false }) }}
                dropdownStyle={{ minHeight: "206px" }}
                style={{ padding: 0 }}
                color={theme.colors.primary2}
                padding="8px"
                left={1}
                right={1}
                textIndent="25px"
            >
                {renderSearchResults()}
            </LabeledInput>
        )
    }
}

export default withTheme(SearchBusiness);