import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';

import { Select } from 'antd';
import { Flex, FlexColumn } from '../../Reusable/Container';
import { Text } from '../../Reusable/Refresh/Text';
import { LabeledInput } from '../../Reusable/Input';
import { Button, TextButton } from '../../Reusable/Refresh/Button';
const { Option } = Select;

class DeclineProposal extends Component {
    state = {
        reason: null
    }

    constructor(props) {
        super(props);
    }

    handleSelect = (value, option) => {
        this.setState({ [option.id]: value });
    }

    handleConfirmDecline = () => {
        const { reason } = this.state;
        if (reason != null) {
            this.props.handleConfirmDecline(reason);
        }
        else {

        }
    }

    handleCloseDecline = () => {
        this.props.handleCloseDeclineProposal()
    }

    render() {
        const { theme, loanApp } = this.props;
        const { reason } = this.state;
        const reasons = [
            "The fee or rate is too high",
            "I have an issue with the proposal terms",
            "I no longer need the funds",
            "Other"
        ]
        return (
            <FlexColumn style={{ width: "100%" }}>
                <Text heading margin='0 0 12px'>Are you sure?</Text>
                <Text size='16px'>Are you sure you want to decline the proposal? You will have to re-apply again. Please provide a reason, so that we may help you better</Text>

                <LabeledInput
                    label="Reason"
                    type="select"
                    value={reason}
                    placeholder="Select a reason"
                    onChange={this.handleSelect}
                    margin='24px 0 48px'
                >
                    {
                        reasons.map(
                            reason => {
                                return <Option id="reason" value={reason}>{reason}</Option>
                            }
                        )
                    }
                </LabeledInput>
                <Flex start gap='12px'>
                    <Button type="primary" solid text="CONFIRM" style={{ marginRight: 10 }} onClick={this.handleConfirmDecline} />
                    <TextButton type="primary" text="CANCEL" onClick={this.handleCloseDecline} />
                </Flex>
            </FlexColumn>
        );
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer,
        bankingStore: state.bankingAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(DeclineProposal)))