import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Divider, Table } from 'antd';

// Components
import AdvancesTable from '../Advances/AdvancesTable';
import LimitsBalances from '../LimitsBalances';

import PageHeader from "../../Reusable/PageHeader";
import { FlexColumn } from '../../Reusable/Container';

// Util
import { apiGET, apiPOSTReq } from '../../../Utils/api';

import { ErrorAlert } from '../../Reusable/Alert';
import environment from '../../../environment';
import { Paragraph, Title } from '../../Reusable/Text';
import { toCurrency } from '../../../Utils/util';
import { withTheme } from 'styled-components';
import moment from 'moment';
import { ArrowRightOutlined, RightOutlined } from '@ant-design/icons';
import Banner from '../../Reusable/Banner';
import { TextButton } from '../../Reusable/Button';
import TableView from '../../Reusable/TableView';

class AdvanceDetail extends Component {

    state = {
        loading: true
    };

    componentDidMount() {
        this.fetchPaymentSchedules();
    }

    fetchPaymentSchedules() {
        this.setState({ loading: true });
        console.log("getPaymentSchedules", this.props.advance.LoanId)
        var header = {};
        if (this.props.businessId) {
            header = {
                businesskey: this.props.businessId,
                Aioncurrentbiz: this.props.businessId
            }
        }
        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/getPaymentSchedulesForLoan`, header, { "LoanId": this.props.advance.LoanId }, (err, resp) => {
            this.setState({ loading: false });
            try {
                const data = resp || {};
                console.log("fetchPaymentSchedules", data);
                if (data.result) {
                    this.setState({ paymentSchedules: data.paymentSchedules })
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/counterparty err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    render() {
        const { advance, loading, theme } = this.props;
        const { Balance, LoanId, LoanAgreement, DepositedDate } = advance;
        const paymentSchedules = (this.state.paymentSchedules || []).sort((a, b) => (new Date(a.paymentDateStr) - new Date(b.paymentDateStr)));
        console.log("advance", advance)

        const { aionStore } = this.props;
        const { Attributes } = aionStore;
        var { Businesses } = Attributes || {};
        var businessAttributes = Businesses || {};

        const customPaymentPlan = businessAttributes.CustomPaymentPlan

        const columns = [
            {
                title: 'Period',
                dataIndex: 'paymentPeriod'
            },
            {
                title: 'Date',
                dataIndex: 'paymentDateStr',
                render: date => (moment(date, "YYYY-MM-DD").format("MM/DD/YYYY")),
                width: 120
            },
            {
                title: 'Principal',
                dataIndex: 'principal',
                render: item => "$" + toCurrency(item)
            },
            {
                title: (customPaymentPlan === true ? 'Fees' : 'Interest'),
                dataIndex: 'interest',
                render: item => "$" + toCurrency(item)
            },
            {
                title: 'Total',
                dataIndex: 'Amount',
                render: (amt, item) => "$" + toCurrency(item.principal + item.interest)
            },
            {
                title: 'Status',
                dataIndex: 'paymentStatus'
            }
        ]
        return (
            <FlexColumn style={{ margin: '0 40px' }}>
                <PageHeader
                    titleText={<span>Advance <span style={{ color: theme.colors.secondary3 }}>#{LoanId}</span></span>}
                    desc="Payment schedule and details"
                    fullpage
                />
                <FlexColumn>
                    {!DepositedDate &&
                        <Banner style={{ marginBottom: "15px" }} title="Advance Initiated!" message="The funds will be available in your account in 1-3 business days." />}
                    <Paragraph bold style={{ color: theme.colors.secondary3, margin: '0px 0' }} level={3}>Advance balance</Paragraph>
                    <Title bold style={{ margin: '0px 0' }} level={5}>${toCurrency(Balance)}</Title>
                    <Divider style={{ margin: "15px 0" }} />
                    <TextButton onClick={() => this.props.downloadContract(advance)} text="View Loan Agreement"></TextButton>
                    <Divider style={{ margin: "15px 0" }} />
                    {/*{customPaymentPlan && customPaymentPlan == true && <a target="_blank" href={LoanAgreement} style={{ fontSize: "1.0rem" }}>View Loan Agreement  <RightOutlined /></a>}
                    {customPaymentPlan && customPaymentPlan == true && <Divider style={{ margin: "15px 0" }} />} */}
                    {DepositedDate ?
                        <>
                            {/* <Paragraph bold level={2}>Payment Schedule</Paragraph> */}
                            <TableView
                                id='advances-table'
                                rowKey='id'
                                titleText='Payment Schedule'
                                tableLayout='auto'
                                columns={columns}
                                dataSource={paymentSchedules}
                                pagination={false}
                                loading={this.state.loading}
                                scroll={{ y: '700px', x: '100%' }}
                            />
                        </>
                        :
                        <>
                            <Paragraph bold level={2}>Payment Schedule</Paragraph>
                            <Banner style={{ marginBottom: "15px" }} message="The payment schedule will be available soon" />

                        </>
                    }

                </FlexColumn>
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.creditAppReducer,
        aionStore: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(AdvanceDetail)));