import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'

import { Flex, FlexColumn } from '../../Reusable/Container'
import { LabeledInput } from '../../Reusable/Input'
import { Text } from '../../Reusable/Text'
import { Button } from '../../Reusable/Button'
import { renderBankIcon, addressObjectToStr } from '../../../Utils/util'

import { getFeaturePerm } from '../../../Utils/util'


class ViewTransferModal extends Component {

    constructor(props) {
        super(props)

        this.state = {
            transfer: {
                type: props.transferMethod ? props.transferMethod.type : null,
                name: props.transferMethod ? props.transferMethod.nickName : null,
                accountNumber: props.transferMethod ? props.transferMethod.bankDetail.accountNumber : null,
                routingNumber: props.transferMethod ? props.transferMethod.bankDetail.routingNumber : (props.rail && props.rail === 'Instant') ? '021214891' : null,
                addressOnAccount: props.transferMethod ? props.transferMethod.bankDetail.addressOnAccount : null,
                accountType: props.transferMethod ? props.transferMethod.bankDetail.accountType : (props.rail && props.rail === 'Instant') ? 'Checking' : null,
            },
            type: props.rail ? props.rail : props.transferMethod ? props.transferMethod.type : null,
            aionAccount: (props.transferMethod && props.transferMethod.bankDetail.bankName === "Aion Banking (Powered by Cross River Bank)") ? true : false,
            logoUrl: props.transferMethod ? props.transferMethod.bankDetail.logoUrl : null,
        }
    }

    componentDidMount() {
        console.log('AddTransferModaltransferMethod', this.props.transferMethod)
    }

    render() {
        var { loading, logoUrl, transfer, type } = this.state
        var { name, accountNumber, routingNumber, addressOnAccount, accountType, checkDeliveryType } = transfer
        const { recipientEmail, transferMethod, recipient } = this.props

        var { accountNumberError, accountTypeError, checkDeliveryTypeError } = this.state
        var checkPaymentsEnabled = getFeaturePerm("BusinessBanking.Payments.CheckPayment")
        var instantPaymentsEnabled = getFeaturePerm("BusinessBanking.Payments.Instant Transfer")

        var rails = ['ACH', 'Wire']
        if (checkPaymentsEnabled.manage) rails.push('Check')
        if (instantPaymentsEnabled.manage) rails.unshift('Instant')
        const nickNameMaxLen = 50
        var nickName = `${(name || "").trim().substr(0, nickNameMaxLen)}${(name || "").trim().length > nickNameMaxLen ? "..." : ""}`

        return (
            <FlexColumn start style={{ width: 616 }}>
                <FlexColumn start>
                    <Flex start gap='8px' centerHorizontally style={{ marginBottom: 28 }}>
                        {renderBankIcon(transferMethod.bankDetail, '48px')}
                        <Text style={{ maxWidth: '80%' }} height='34px' size='24px' weight={500} color='#444444' title={name}>{nickName}</Text>
                    </Flex>

                    <Flex start gap='24px'>
                        {
                            transferMethod &&
                            <LabeledInput
                                label='Type'
                                type='read-only'
                                value={type}
                                nomargin
                            />
                        }
                    </Flex>

                    {
                        type === "Check" &&
                        <LabeledInput
                            label='Check delivery type'
                            type='read-only'
                            switchNames={["Email", "Postal Mail"]}
                            onChange={(value) => this.setCDType(value)}
                            value={checkDeliveryType}
                            error={checkDeliveryTypeError}
                            errorMessage='Check delivery type is required'
                        />
                    }

                    <LabeledInput
                        type='read-only'
                        label="Routing Number"
                        value={routingNumber && routingNumber}
                        width='282px'
                    />

                    <LabeledInput
                        inputRef={this.accountNumberRef}
                        label="Account Number"
                        id="accountNumber"
                        key="accountNumber"
                        type='read-only'
                        placeholder="Enter Account Number"
                        value={accountNumber && accountNumber}
                        maxLength={17}
                        onChange={this.handleTextChange}
                        error={accountNumberError}
                        errorMessage="A valid account number is required"
                        width='272px'
                    />

                    {
                        accountType &&
                        <LabeledInput
                            label='Account Type'
                            type='read-only'
                            switchNames={['Checking', 'Savings']}
                            onChange={(value) => this.setAccountType(value)}
                            value={accountType}
                            error={accountTypeError}
                            errorMessage='Account type is required'
                        />
                    }

                    {
                        (type === 'Wire' || (type === 'Check' && checkDeliveryType == 'Postal Mail')) &&
                        <LabeledInput
                            label={(type === "Check") ? 'Recipient mailing address' : 'Recipient address at their bank'}
                            id="addressOnAccount"
                            key="addressOnAccount"
                            placeholder="Enter Address"
                            address={addressOnAccount}
                            autoFocus
                            type='read-only'
                            value={addressObjectToStr(addressOnAccount)}
                            width='568px'
                        />
                    }

                    {
                        checkDeliveryType == "Email" &&
                        <Text defaultLightText>Check will be emailed to <b>{recipientEmail}</b></Text>
                    }
                </FlexColumn>

                <Flex style={{ marginTop: 40 }} start>
                    <Button permtype="BusinessBanking.Transactions" solid text='TRANSFER' onClick={() => this.props.history.push({
                        pathname: '/banking/transfer-to-recipient',
                        state: { recipient, transferMethod }
                    })} />
                </Flex>
            </FlexColumn>
        )
    }
}


function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(ViewTransferModal)))