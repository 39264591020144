import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled, { withTheme } from 'styled-components';

import { Select, Table, message, Divider } from 'antd';

import { Flex, FlexColumn, CardContainer, LightContainer } from '../../Reusable/Container';
import { LabeledInput } from '../../Reusable/Input';
import { ErrorAlert } from '../../Reusable/Alert';
import { Tag, Text } from '../../Reusable/Text';
import { Button } from '../../Reusable/Button'
import { Image } from '../../Reusable/Image';
import { ExpenseCategory } from '../../Reusable/ExpenseCategory';
import FlowFooter from '../../Reusable/FlowFooter'
import { addDataToStore, SAVE_DATA } from '../../../Actions/actions';

import environment from '../../../environment';
import { apiPOSTReq } from '../../../Utils/api';
import { capitalizeWords, getUserApps, getBPAccountsWithAccess } from '../../../Utils/util';
import BillSettings from './BillSettings'
import TableView from '../../Reusable/TableView'
import AionIcon from '../../../Images/aion-bank-icon.png'
import Lightning from '../../../Images/lightning-dark.svg';
import ThumbsUp from '../../../Images/thumbs-up.svg';
import Shield from '../../../Images/shield.svg';
import Connection from '../../../Images/sync-connected.svg';
import Sync from '../../../Images/sync-refresh.svg';

const { Option } = Select;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%; // Adjust width as necessary
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 155px;
  padding: 10px; // Add some padding around columns
`;

const priorityMap = {
    "Accounts Payable": 1,
    "Accounts Receivable": 2,
    "Bank": 0,
    "Cost of Goods Sold": 3,
    "Credit Card": 4,
    "Equity": 5,
    "Expense": 6,
    "Fixed Asset": 7,
    "Income": 8,
    "Long Term Liability": 9,
    "Other Asset": 10,
    "Other Current Asset": 11,
    "Other Current Liability": 12,
    "Other Expense": 13,
    "Other Income": 14,
}

class SettingsModal extends Component {

    constructor(props) {
        super(props)

        this.state = {
            currentViewIndex: 0,
            historyPagination: {
                current: 1,
                pageSize: 50
            },
            errorPagination: {
                current: 1,
                pageSize: 80
            },
            qbErrors: [],
            integrationHistory: [],
            showAdvancedSetting: false,
            syncQB: false,
            pagination: {
                current: 1,
                pageSize: 1000,
            },
            pairPagination: {
                current: 1,
                pageSize: 1000,
            },
            accountCOA: {},
            customCOA: [],
            step: props.learn ? 'learn' : 'setup',
        }
    }

    componentDidMount() {
        this.fetchCOA({ pagination: this.state.pagination });
        this.fetchCOAPair({ pagination: this.state.pairPagination });
    }

    componentDidUpdate(prevProps) {
        if (this.props.aionStore.syncJobId !== prevProps.aionStore.syncJobId) {
            const { syncJobId } = this.props.aionStore;
            if (!syncJobId) {
                clearInterval(this.timer)
                this.timer = null;
            } else {
                if (!this.timer) {
                    this.timer = setInterval(() => this.fetchQBErrors({ errorPagination: this.state.errorPagination }), 5000);
                }
            }
        }
    }

    fetchCOAPair = ({ pagination }) => {
        const body = {
            page: pagination.current - 1,
            size: pagination.pageSize
        }
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/qb/getAcctCOAPair`, {}, body, (err, resp) => {
            try {
                const data = resp;
                console.log('/integration/qb/getAcctCOAPair', data)
                if (data.result) {
                    const accountCOA = this.state.accountCOA;
                    if (data.accountPairList) {
                        data.accountPairList.forEach(x => {
                            accountCOA[x.accountId] = x.coaId
                        });
                        this.setState({ accountCOA });
                    }

                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getActiveCustomers", error.stack, resp);
                // ErrorAlert({ description: error.message })
            }
        })
    }

    fetchCOA = ({ pagination, searchStr }) => {
        const headers = {
            AionCurrentBiz: this.props.aionStore.BusinessUniqueKey,
            AionAuth: this.props.aionStore.UAM.encryptedAuthHeader
        }

        const body = {
            "BusinessId": this.props.aionStore.BusinessUniqueKey,
            "size": pagination.pageSize,
            "page": pagination.current - 1,
            "ActiveRecords": true,
            "sortFieldName": "Code",
            "sortDirection": "ASC",
        }
        if (searchStr) {
            body.searchStr = searchStr;
        }
        this.setState({ loading: true, });

        apiPOSTReq(`${environment.bbBaseUrl}/bk/getCategories`, headers, body, (err, resp) => {
            try {
                const data = resp;
                console.log('/bk/getCategories', data)
                if (data.result) {
                    this.setState({
                        loading: false,
                        customCOA: data.customCOAList ? data.customCOAList.sort((a, b) => {
                            if (priorityMap[a.coatype] < priorityMap[b.coatype]) return -1;
                            else return 1;
                        }).map(x => {
                            return {
                                ...x, key: x.id,
                            }
                        }) : [],
                    })
                    if (!searchStr) {
                        this.setState({
                            allCustomCOA: data.customCOAList ? data.customCOAList.sort((a, b) => {
                                if (priorityMap[a.coatype] < priorityMap[b.coatype]) return -1;
                                else return 1;
                            }).map(x => {
                                return {
                                    ...x, key: x.id,
                                }
                            }) : [],
                        })
                    }
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getActiveCustomers", error.stack, resp);
                ErrorAlert({ description: error.message })
            }
        })
    }

    handleSelect = (accountId, coaId) => {
        let accountCOA = this.state.accountCOA;
        accountCOA[accountId] = coaId;
        this.setState({ accountCOA, customCOA: this.state.allCustomCOA });
    }

    render() {
        const { theme, onCancel, aionStore, learn } = this.props;
        var { connections } = aionStore
        var { loading, customCOA, accountCOA, submitLoading, step } = this.state;

        var logoUrl

        const showConnections = connections && connections?.length > 0

        if (showConnections) {
            logoUrl = connections[0].logoUrl
        }

        return (
            <FlexColumn left between fullHeight gap='24px'>
                {(() => {
                    switch (step) {
                        case 'learn':
                            return <>
                                <FlexColumn start>
                                    <Text heading>Learn what's being synced</Text>
                                    {
                                        showConnections &&
                                        <LightContainer padding='12px 24px' margin='24px 0 24px 0' height='56px' fullWidth>
                                            <Flex style={{ height: '100%' }} between centerHorizontally>
                                                {/* <Flex start style={{ width: '50%' }} gap='24px' centerHorizontally> */}
                                                <img height='50px' src={logoUrl} />
                                                <Tag size='14px' height='35px' color='#318F2F' background='#F5F9F5'>{'Connected'}</Tag>
                                                {/* </Flex> */}
                                            </Flex>
                                        </LightContainer>
                                    }

                                    <Flex start gap='16px' centerHorizontally style={{ padding: '12px 24px' }}>
                                        <Image width='40px' height='40px' src={Connection} />
                                        <FlexColumn start left gap='8px'>
                                            <Text weight='500' height='24px'>{"Two-way Sync"}</Text>
                                            <Text>The following records will be in sync with your accounting system</Text>

                                            <Container>
                                                <Column>
                                                    <Text>•  Bills</Text>
                                                    <Text>•  Bill payments</Text>
                                                    <Text>•  Chart of accounts *</Text>
                                                </Column>
                                                <Column>
                                                    <Text>•  Customers *</Text>
                                                    <Text>•  Invoices</Text>
                                                    <Text>•  Invoice Payments</Text>
                                                </Column>
                                                <Column>
                                                    <Text>•  Products *</Text>
                                                    <Text>•  Vendors</Text>
                                                </Column>
                                            </Container>

                                            <Text>* Note: Draft invoices, new chart of accounts, new products, new customers and delete actions will not sync to your accounting system. Other limitations may apply.</Text>
                                        </FlexColumn>
                                    </Flex>
                                    <Divider style={{ margin: '8px 0' }} />
                                    <Flex start gap='16px' centerHorizontally style={{ padding: '12px 24px' }}>
                                        <Image width='40px' height='40px' src={Lightning} />
                                        <FlexColumn start left gap='8px'>
                                            <Text weight='500' height='24px'>{"Real Time Updates"}</Text>
                                            <Text>Records will be synced to your accounting system as soon as you create or edit it in Aion.</Text>
                                        </FlexColumn>
                                    </Flex>
                                    <Divider style={{ margin: '8px 0' }} />
                                    <Flex start gap='16px' centerHorizontally style={{ padding: '12px 24px' }}>
                                        <Image width='40px' height='40px' src={Sync} />
                                        <FlexColumn start left gap='8px'>
                                            <Text weight='500' height='24px'>{"Automated Refreshes"}</Text>
                                            <Text>Any updates to related accounting system records will be refreshed on an hourly basis or earlier. You can also easily sync on-demand from the Business Profile page of your Aion account.</Text>
                                        </FlexColumn>
                                    </Flex>
                                </FlexColumn>

                                <Button solid text='NEXT' margin='24px 0 0' onClick={() => this.setState({ step: 'setup' })} />
                            </>
                        case 'setup':
                            return <>
                                <Text heading>Complete your setup for Bill Payments</Text>
                                {
                                    showConnections &&
                                    <LightContainer padding='12px 24px' margin='0 0 24px 0' height='56px' fullWidth>
                                        <Flex style={{ height: '100%' }} between centerHorizontally>
                                            {/* <Flex start style={{ width: '50%' }} gap='24px' centerHorizontally> */}
                                            <img height='50px' src={logoUrl} />
                                            <Tag size='14px' height='35px' color='#318F2F' background='#F5F9F5'>{'Connected'}</Tag>
                                            {/* </Flex> */}
                                        </Flex>
                                    </LightContainer>
                                }
                                <BillSettings onCancel={onCancel} poll />
                            </>
                        default:
                            return null
                    }
                })()}
            </FlexColumn>
        );
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer,
        bankingStore: state.bankingAppReducer,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(SettingsModal));