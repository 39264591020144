import { BOOKKEEPING_SAVE_DATA, RESET_BOOKKEEPING_DATA } from '../Actions/actions'

const initialState = {
}

function bankingAppReducer(state, action) {
	if (typeof state === 'undefined') {
		return initialState
	}
	var newState = state;
	switch (action.type) {
		case BOOKKEEPING_SAVE_DATA:
			newState = Object.assign(newState, action.data ? action.data : {});
			return newState
		case RESET_BOOKKEEPING_DATA:
			newState = initialState;
			return newState
		default:
			return state
	}
}

export default bankingAppReducer