import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import SignatureCanvas from 'react-signature-canvas'

import { CardContainer, Flex, FlexColumn } from '../../Reusable/Container';
import FlowViewComponent from '../../Reusable/Refresh/FlowViewComponent';
import { LabeledInput } from '../../Reusable/Refresh/Input';
import { Text } from '../../Reusable/Text';
import { addDataToStore, ONB_SAVE_DATA, PERSONAL_INFO, REMOTE_SAVE_DATA, UAM_SAVE_BUSINESS } from '../../../Actions/actions';
import { TextButton } from '../../Reusable/Refresh/Button';
import { ErrorAlert } from '../../Reusable/Alert';
import { CheckCircleOutlined, DownOutlined, EditOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Collapse, Divider, message, Select, Modal } from 'antd';
import { addressObjectToStr, getTotalOwnershipPercentage, isSmallScreen, STATES_MAP } from '../../../Utils/util';
import { OwnerCard, SubsidirayCard } from '../../Reusable/Refresh/Card';
import FinancialInfo from './FinancialInfo';
import { capitalize, random } from 'lodash';
import { apiPOSTReq } from '../../../Utils/api';
import environment from '../../../environment';
import moment from 'moment'
import { Button, ImageButton } from '../../Reusable/Button';
import ModalClose from '../../../Images/modal-close.png'
import EditIcon from '../../../Images/edit.png'
import DownIcon from '../../../Images/chevron-down-icon.png'
import UserProfile from './UserProfile';
import BusinessProfile from './BusinessProfile';
import TxnActivityInfo from './TxnActivityInfo';
import OwnershipInfo from './OwnershipInfo';
import ControlPersonInfo from './ControlPersonInfo';
import AuthSignerInfo from './AuthSignerInfo';
import Banner from '../../Reusable/Banner';
import OwnerPendingInvite from './OwnerPendingInvite';

const { Option } = Select;

class BankAppReview extends Component {

    state = {
        submitLoading: false,
        preboardUsers: [],
        viewUpdateKey: random(500)
    }

    getIovationBBString = () => {
        try {
            var bb3 = window.ioGetBlackbox()
            return bb3.blackbox
        } catch (error) {
            return error.message || "Unable to get device info"
        }
    }

    validateFlow = (options) => {
        var { aionStore, onboardingStore, creditStore } = this.props || {};
        var { personalInfo, businessInfo, coOwnersInfo, signersInfo, contractOwnerApplicantInfo } = onboardingStore || {};
        businessInfo = businessInfo || {};
        var { subscriptionPlanName, subscriptionBilling, manualReviewRequired, manualReviewRequiredMsg, business } = aionStore || {};
        var { businessBanking } = business
        businessBanking = businessBanking || {}
        var bbAttributes = businessBanking.attributes || {}
        var primaryApplicantInfo = { ...personalInfo, ...options}
        primaryApplicantInfo["iovationBlackbox"] = this.getIovationBBString()
        var body = {
            primaryApplicantInfo: primaryApplicantInfo,
            coOwnerInfo: coOwnersInfo,
            businessInfo: businessInfo,
            contractOwnerApplicantInfo: contractOwnerApplicantInfo,
            manualReviewRequired: manualReviewRequired,
            reviewComments: manualReviewRequiredMsg ? [manualReviewRequiredMsg] : [],
            subscriptionPlanName: capitalize(subscriptionPlanName),
            subscriptionBilling: capitalize(subscriptionBilling),
            accountApplication: {
                "accountNickName": "Business Checking"
            },
            signerInfo: signersInfo,
            userStatus: "Apply.BankSubmitted"
        }
        
        this.setState({ submitLoading: true })
        const signupEndpoint = `${environment.uamBaseUrl}/bp/${bbAttributes.forceCRBAccountOpening ? 'existingCustomerSignUp' : 'signUp'}`
        console.log("SubmitInfo.Submit", signupEndpoint, body)
        apiPOSTReq(signupEndpoint, {}, body, (err, resp) => {
            this.setState({ submitLoading: false })
            try {
                var data = resp || {}
                if (data.result) {
                    message.success('Application submitted successfully!')
                    
                    // Generate BO Certificate
                    apiPOSTReq(`${environment.uamBaseUrl}/uam/generateBoCertificate`, {}, {}, (err, resp) => {
                        try {
                            var data = resp || {}
                            console.log("/bb/generateBoCertificate", err, resp)
                            if (!data.result) throw Error(data.error || data.responseMessage)
                        } catch (error) {
                            console.log("/application err", error, err, resp)
                        }
                    })
                    
                    this.props.loadNext()
                } else {
                    throw Error(data.error || data.responseMessage)
                }
            } catch (error) {
                console.log("/application err", error, err, resp)
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    handleSubmit = () => {
        var { onboardingStore } = this.props
        var { controlPerson } = onboardingStore.personalInfo
        var cpOwners = onboardingStore.coOwnersInfo.filter(owner => owner.controlPerson)
        if(!controlPerson && cpOwners.length == 0) {
            ErrorAlert({ description: "Please select a controlling party to continue" })
            return
        }
        if(getTotalOwnershipPercentage() > 100) {
            ErrorAlert({ description: "Total ownership cannot exceed 100%. Go to the Ownership section to edit." })
            return
        }
        this.setState({ showSignatureModal: true })
    }
    // Go to the ownership section to edit
    handleSignatureSubmit = () => {
        if(this.sigBOCert.isEmpty()) {
            ErrorAlert({ description: "Your signature is required to submit" })
            return
        }
        var dataToSave = {
            certifiedOn: moment().toDate(),
            digitalSignatureStr: this.sigBOCert.getTrimmedCanvas().toDataURL("image/png")
        }
        this.props.dispatch(addDataToStore(PERSONAL_INFO, dataToSave))
        this.setState({ showSignatureModal: false, submitLoading: true })
        this.validateFlow(dataToSave)
    }

    render() {
        var { theme, flowView, currentViewIndex, loadPrevView } = this.props
        var { submitLoading, isUserProfileActive, editUserProfile, isBusinessProfileActive, editBusinessProfile, isFinancialsActive, editFinancials, isOwnershipActive, editOwnership, isCPActive, editCP, isASActive, editAS, preboardUsers, viewUpdateKey, preboardUsersLoaded } = this.state
        var sigStyle = { borderRadius: 5, marginBottom: 6 }
        if(isSmallScreen) sigStyle["zoom"] = "1.2"
        var pendingInvites = preboardUsers.filter(preboardUser => !['Processed', 'ProcessedPreseed'].includes(preboardUser.status)).length > 0

        // isUserProfileActive = false
        // isBusinessProfileActive = true
        // console.log("viewUpdateKey", viewUpdateKey, preboardUsers)
        var childViews = (
            <FlexColumn style={{ gap: "24px" }}>
                {/* <Button noClick shadow padding='0 16px' height='40px' text="Review" weight='600' radius='4px' style={{ width: '84px' }} /> */}
                <FlexColumn>
                    <Text weight={400} size='20px' height='40px'>Review and submit!</Text>
                    <Text>Please go through all the information you’ve provided before hitting the submit button to submit your application</Text>
                </FlexColumn>

                {
                    preboardUsers.length > 0 &&
                    <CardContainer>
                        <FlexColumn gap='24px'>
                            {
                                pendingInvites ?
                                <FlexColumn>
                                    <Text uppercase spacing='0.08em' weight='500' color={theme.colors.secondary6} style={{ marginBottom: '10px' }}>Your Invites</Text>
                                    <Banner message='You will be able to submit your application once the following individuals have provided their required information.' />
                                </FlexColumn>
                                :
                                <FlexColumn>
                                    <Text uppercase spacing='0.08em' weight='500' color={theme.colors.secondary6} style={{ marginBottom: '10px' }}>Your Invites</Text>
                                    <Banner message='Your invites have submitted their information. You can now submit the application.' />
                                </FlexColumn>
                            }
                            <OwnerPendingInvite
                                key={viewUpdateKey+preboardUsers.length}
                                preboardUsersLoaded={preboardUsersLoaded}
                                preboardUsers={preboardUsers}
                                setPreboardUsers={ (data) => {
                                    this.setState({ preboardUsers: data.preboardUsers, preboardUsersLoaded: true, viewUpdateKey: random(500) })
                                } }
                            />
                        </FlexColumn>
                    </CardContainer>
                }
                {
                    // THIS IS PURELY TO TRIGGER setPreboardUsers
                    preboardUsers.length == 0 && !preboardUsersLoaded &&
                    <OwnerPendingInvite
                        setPreboardUsers={ (data) => {
                            this.setState({ preboardUsers: data.preboardUsers, preboardUsersLoaded: true, viewUpdateKey: random(500) })
                        } }
                    />
                }
                
                {/* YOUR INFORMATION */}
                <CardContainer onClick={() => this.setState({ isUserProfileActive: !isUserProfileActive })} style={{ cursor: 'pointer' }}>
                    <FlexColumn start>
                        <Flex between centerHorizontally fullWidth >
                            <Flex center gap='8px'>
                                
                                <Text weight='500' size='20px'>Your Information</Text>
                            </Flex>
                            <Flex center gap='24px'>
                                <ImageButton src={EditIcon} onClick={() => this.setState({ editUserProfile: !editUserProfile, showEditInfoModal: true })} />
                                <ImageButton src={DownIcon} onClick={() => this.setState({ isUserProfileActive: !isUserProfileActive })} transform={`rotate(${isUserProfileActive ? 180 : 0}deg)`} />
                            </Flex>
                        </Flex>
                        {
                            isUserProfileActive && 
                            <>
                                <Divider />
                                <UserProfile reviewView />
                            </>
                        }
                    </FlexColumn>
                </CardContainer>
                
                {/* BUSINESS PROFILE */}
                <CardContainer onClick={() => this.setState({ isBusinessProfileActive: !isBusinessProfileActive })} style={{ cursor: 'pointer' }}>
                    <FlexColumn start>
                        <Flex between centerHorizontally fullWidth >
                            <Flex center gap='8px'>
                                
                                <Text weight='500' size='20px'>Business Profile</Text>
                            </Flex>
                            <Flex center gap='24px'>
                                <ImageButton src={EditIcon} onClick={() => this.setState({ editBusinessProfile: !editBusinessProfile, showEditInfoModal: true })} />
                                <ImageButton src={DownIcon} onClick={() => this.setState({ isBusinessProfileActive: !isBusinessProfileActive })} transform={`rotate(${isUserProfileActive ? 180 : 0}deg)`} />
                            </Flex>
                        </Flex>
                        {
                            isBusinessProfileActive && 
                            <>
                                <Divider />
                                <BusinessProfile reviewView />
                            </>
                        }
                    </FlexColumn>
                </CardContainer>

                {/* Financials */}
                <CardContainer onClick={() => this.setState({ isFinancialsActive: !isFinancialsActive })} style={{ cursor: 'pointer' }}>
                    <FlexColumn start>
                        <Flex between centerHorizontally fullWidth >
                            <Flex center gap='8px'>
                                
                                <Text weight='500' size='20px'>Business Activity</Text>
                            </Flex>
                            <Flex center gap='24px'>
                                <ImageButton src={EditIcon} onClick={() => this.setState({ editFinancials: !editFinancials, showEditInfoModal: true })} />
                                <ImageButton src={DownIcon} onClick={() => this.setState({ isFinancialsActive: !isFinancialsActive })} transform={`rotate(${isFinancialsActive ? 180 : 0}deg)`} />
                            </Flex>
                        </Flex>
                        {
                            isFinancialsActive && 
                            <>
                                <Divider />
                                <TxnActivityInfo reviewView />
                            </>
                        }
                    </FlexColumn>
                </CardContainer>

                {/* Ownership */}
                <CardContainer onClick={() => this.setState({ isOwnershipActive: !isOwnershipActive })} style={{ cursor: 'pointer' }}>
                    <FlexColumn start>
                        <Flex between centerHorizontally fullWidth >
                            <Flex center gap='8px'>
                                
                                <Text weight='500' size='20px'>Ownership</Text>
                            </Flex>
                            <Flex center gap='24px'>
                                <ImageButton src={EditIcon} onClick={() => this.setState({ editOwnership: !editOwnership, showEditInfoModal: true })} />
                                <ImageButton src={DownIcon} onClick={() => this.setState({ isOwnershipActive: !isOwnershipActive })} transform={`rotate(${isOwnershipActive ? 180 : 0}deg)`} />
                            </Flex>
                        </Flex>
                        {
                            isOwnershipActive && 
                            <>
                                <Divider />
                                <OwnershipInfo reviewView />
                            </>
                        }
                    </FlexColumn>
                </CardContainer>

                {/* Controlling party */}
                <CardContainer onClick={() => this.setState({ isCPActive: !isCPActive })} style={{ cursor: 'pointer' }}>
                    <FlexColumn start>
                        <Flex between centerHorizontally fullWidth >
                            <Flex center gap='8px'>
                                
                                <Text weight='500' size='20px'>Controlling Party</Text>
                            </Flex>
                            <Flex center gap='24px'>
                                <ImageButton src={EditIcon} onClick={() => this.setState({ editCP: !editCP, showEditInfoModal: true })} />
                                <ImageButton src={DownIcon} onClick={() => this.setState({ isCPActive: !isCPActive })} transform={`rotate(${isCPActive ? 180 : 0}deg)`} />
                            </Flex>
                        </Flex>
                        {
                            isCPActive && 
                            <>
                                <Divider />
                                <ControlPersonInfo reviewView />
                            </>
                        }
                    </FlexColumn>
                </CardContainer>

                {/* Authorized signers */}
                {/* <CardContainer onClick={() => this.setState({ isASActive: !isASActive })} style={{ cursor: 'pointer' }}>
                    <FlexColumn start>
                        <Flex between centerHorizontally fullWidth >
                            <Flex center gap='8px'>
                                
                                <Text weight='500' size='20px'>Authorized Signers</Text>
                            </Flex>
                            <Flex center gap='24px'>
                                <ImageButton src={EditIcon} onClick={() => this.setState({ editAS: !editAS, showEditInfoModal: true })} />
                                <ImageButton src={DownIcon} onClick={() => this.setState({ isASActive: !isASActive })} transform={`rotate(${isASActive ? 180 : 0}deg)`} />
                            </Flex>
                        </Flex>
                        {
                            isASActive && 
                            <>
                                <Divider />
                                <AuthSignerInfo reviewView />
                            </>
                        }
                    </FlexColumn>
                </CardContainer> */}

            </FlexColumn>
        )
        return (
            <FlexColumn left fullWidth style={{ margin: '24px 0', paddingBottom: '48px' }} key={viewUpdateKey+preboardUsers.length}>

                <FlexColumn fullWidth style={{ padding: '0 64px' }} gap='24px'>
                    <Text weight='500' color='#444444' size='28px' height='40px'>{flowView.AttrTitle || (flowView.TitleToDisplay || flowView.Title)}</Text>
                    {childViews}
                    <FlexColumn left gap='24px'>
                        <Text>I acknowledge that the information being submitted is accurate and correct. I agree to the <a target='blank' href='https://aionfi.com/legal'>Aion Legal Terms and Conditions</a>.</Text>
                        <Button disabled={pendingInvites} solid loading={submitLoading} text='Acknowledge & submit' style={{ textTransform: 'uppercase' }} onClick={() => this.handleSubmit()} />
                    </FlexColumn>
                </FlexColumn>
                
                
                
                <Modal
                    visible={this.state.showSignatureModal}
                    footer={null}
                    closable={true}
                    width={625}
                    destroyOnClose={true}
                    onCancel={() => { 
                        this.setState({ showSignatureModal: false })
                    }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                    style={{ top: '20px' }}
                >
                    <FlexColumn left >
                        <Text size="20px" weight={500} height="40px">Acknowledgement</Text>
                        <Text>Acknowledge your application by signing below with your cursor or finger </Text>
                        <div style={{ border: "1px solid #C7CEDD", borderRadius: '4px', marginTop: '24px', width: '100%' }}>
                            <SignatureCanvas
                                // backgroundColor='#F7F7FA'
                                penColor='black'
                                ref={(ref) => { this.sigBOCert = ref }}
                                canvasProps={{ width: isSmallScreen ? 480 : 575, height: 240, className: 'sigCanvas', style: sigStyle }}
                            />
                        </div>
                        <Text style={{ fontSize: 14, color: theme.colors.secondary5, cursor: 'pointer', marginBottom: 24 }} onClick={() => { this.sigBOCert.clear() }}>Clear Signature</Text>
                        <Button solid text='Submit' style={{ marginTop: '24px', textTransform: 'uppercase' }} onClick={ () => this.handleSignatureSubmit() } />
                    </FlexColumn>
                </Modal>
                <Modal
                    visible={this.state.showEditInfoModal}
                    footer={null}
                    closable={false}
                    width={"90%"}
                    destroyOnClose={true}
                    onCancel={() => { 
                        // this.setState({ showEditInfoModal: false, editUserProfile: false, editBusinessProfile: false })
                    }}
                    // closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                    style={{ top: '20px' }}
                >
                    <FlexColumn style={{ padding: "0 0 24px 0" }}>
                        {
                            editUserProfile &&
                            <UserProfile 
                                reviewEditMode 
                                reviewEditCancel
                                flowView={{ FlowStep: "Apply.ControlPerson", Title: "Your Information", ButtonTitle: "Save Changes" }} // FlowStep: set to step before review
                                hideSteps={true}
                                back={false}
                                loadNext={() => this.setState({ editUserProfile: false, showEditInfoModal: false })}
                                showFooter={false}
                            />
                        }
                        {
                            editBusinessProfile &&
                            <BusinessProfile 
                                reviewEditMode 
                                reviewEditCancel
                                flowView={{ FlowStep: "Apply.ControlPerson", Title: "Business Profile", ButtonTitle: "Save Changes" }} // FlowStep: set to step before review
                                hideSteps={true}
                                back={false}
                                loadNext={() => this.setState({ editBusinessProfile: false, showEditInfoModal: false })}
                                showFooter={false}
                            />
                        }
                        {
                            editFinancials &&
                            <TxnActivityInfo 
                                reviewEditMode 
                                reviewEditCancel
                                flowView={{ FlowStep: "Apply.ControlPerson", Title: "Financials", ButtonTitle: "Save Changes" }} // FlowStep: set to step before review
                                hideSteps={true}
                                back={false}
                                loadNext={() => this.setState({ editFinancials: false, showEditInfoModal: false })}
                                showFooter={false}
                            />
                        }
                        {
                            editOwnership &&
                            <OwnershipInfo 
                                reviewEditMode 
                                reviewEditCancel
                                flowView={{ FlowStep: "Apply.ControlPerson", Title: "Ownership", ButtonTitle: "Save Changes" }} // FlowStep: set to step before review
                                hideSteps={true}
                                back={false}
                                loadNext={() => this.setState({ editOwnership: false, showEditInfoModal: false, viewUpdateKey: random(500), preboardUsersLoaded: false })}
                                showFooter={false}
                            />
                        }
                        {
                            editCP &&
                            <ControlPersonInfo 
                                reviewEditMode 
                                reviewEditCancel
                                flowView={{ FlowStep: "Apply.ControlPerson", Title: "Controlling Party", ButtonTitle: "Save Changes" }} // FlowStep: set to step before review
                                hideSteps={true}
                                back={false}
                                loadNext={() => this.setState({ editCP: false, showEditInfoModal: false, viewUpdateKey: random(500), preboardUsersLoaded: false })}
                                showFooter={false}
                            />
                        }
                        {
                            editAS &&
                            <AuthSignerInfo 
                                reviewEditMode 
                                reviewEditCancel
                                flowView={{ FlowStep: "Apply.ControlPerson", Title: "Authorized Signers", ButtonTitle: "Save Changes" }} // FlowStep: set to step before review
                                hideSteps={true}
                                back={false}
                                loadNext={() => this.setState({ editAS: false, showEditInfoModal: false })}
                                showFooter={false}
                            />
                        }
                    </FlexColumn>
                </Modal>
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        onboardingStore: state.onboardingAppReducer,
        aionStore: state.aionAppReducer,
        creditStore: state.creditAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(BankAppReview));