import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { Skeleton, Modal } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

import { FlowViewContainer } from '../../../Reusable/FlowViewContainer';
import FlowViewComponent from '../../../Reusable/Refresh/FlowViewComponent';
import { Text } from '../../../Reusable/Refresh/Text';
import { Flex, FlexColumn } from '../../../Reusable/Container';

import { ErrorAlert } from '../../../Reusable/Alert';
import SuccessModal from '../../../Reusable/SuccessModal'
import { flowViewsData, postDDViewsData } from './viewData';
import BusinessInformation from '../Reusable/BusinessInformation';
import OwnershipInformation from '../Reusable/OwnershipInformation';
import { apiGET, apiPOST, apiPOSTReq } from '../../../../Utils/api';
import environment from '../../../../environment';
import Check from '../../../../Images/check-green.png'
import { Button } from '../../../Reusable/Refresh/Button';
import { Image } from '../../../Reusable/Image';
// Actions
import { addDataToStore, CREDIT_SAVE_DATA, UAM_SAVE_BUSINESS } from '../../../../Actions/actions';
import ReviewAndSubmit from '../Reusable/ReviewAndSubmit';
import CreditAppStatus from '../Reusable/CreditAppStatus';
import AionLogo from '../../../../Images/aion-shadow.png'
import _ from 'underscore';

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentViewIndex: 0,
            submitLoading: false,
            loading: false,
            requestFinancials: true,
            businessInfo: {},
            ownerInfo: [],
            subsidiaries: [],
            rowItem: {},
            newOwner: true,
            newSubsidiary: true,
            selectedType: "",
            step: 0,
            flowSteps: [],
            applicationStep: 0,
            proposalTerms: {},
            hasBeneficialOwners: false,
            beneficialOwners: [],
            gnplLow: 0,
            gnplHigh: 100001,
            autoApproveDD: false
        }
        this.allFlowViews = flowViewsData;
        this.flowViews = [];

    }

    componentDidMount() {
        window.scroll({ top: 0, behavior: 'smooth' })
        const { store } = this.props;
        var { proposalTerms } = this.state;
        this.setState({ beneficialOwners: this.fetchOwners() })
        this.fetchBusinessData();
        this.loadAppFromState();
        this.fetchLoanApp();
        this.fetchBusinessAttributes();
        console.log('CreditAppStatusCreditAppStatus')
    }

    fetchBusinessAttributes = () => {
        var body = {
            "clientBusinessId": ''
        };

        this.setState({ attributesLoading: true });
        // apiPOSTReq(`${environment.opsBaseUrl}/ops/fetchBusinessAttributes`, {}, body, (err, resp) => {
        apiPOSTReq(`${environment.opsBaseUrl}/ops/fetchBusinessAttributes`, null, body, (err, resp) => {
            try {
                if (err) throw Error(err);
                const data = resp || {};
                if (data.result) {
                    var lineSetup = data.businessAttributes.filter(x => x.name == "GNPLAutomatedLineSetupFlow");
                    if (lineSetup.length > 0) {
                        var attribute = lineSetup[0];
                        var additionalParameterReference = attribute.additionalParameterReference;
                        var parameters = additionalParameterReference.parameters;
                        var low = parseInt(parameters[0].value);
                        var high = parseInt(parameters[1].value);
                        var autoApprovalAmount = parseInt(parameters[2].value);
                        this.setState({ gnplLow: low, gnplHigh: high, autoApprovalAmount: autoApprovalAmount })
                    }

                    var autoApproveDD = data.businessAttributes.filter(x => x.name == "AutoApproveDD");
                    if (autoApproveDD.length > 0) {
                        var attribute = autoApproveDD[0];
                        var value = attribute.value;
                        this.setState({ autoApproveDD: value });
                    }
                } else {
                    throw Error(data.responseMessage || data.error);
                }
            } catch (error) {
                console.log("/generateDebtSchedule err", error, resp);
            }
        });
    }

    fetchOwners = () => {
        var { store, isCreditPlus, onboardingStore } = this.props;
        var { business } = store;
        var businessProfile = isCreditPlus ? onboardingStore.businessProfile : (business || {}).businessProfile || {};
        var coAppInfo = businessProfile.coOwnerApplicantInfo || [];
        var beneficialOwners = [];
        if (isCreditPlus) {
            coAppInfo = this.props.onboardingStore.coOwnersInfo
        }
        if (businessProfile.primaryApplicantInfo != undefined) {
            if (Number(businessProfile.primaryApplicantInfo.ownershipPercentage) >= 25) {
                beneficialOwners.push(businessProfile.primaryApplicantInfo)
            }
        }
        if (coAppInfo.length > 0) {
            var filteredcoOwners = coAppInfo.filter(owner => Number(owner.ownershipPercentage) >= 25)

            if (filteredcoOwners.length > 0) {
                filteredcoOwners.map(owner => beneficialOwners.push(owner));
            }
        }

        return beneficialOwners;
    }
    fetchLoanApp = () => {
        // Fetch document list
        var body = {
            "clientBusinessId": this.props.store.BusinessUniqueKey,
            "size": 100,
            "page": 0,
            // "sortDirection": "ASC"
        }
        this.setState({ loanAppLoading: true });
        apiPOSTReq(`${environment.opsBaseUrl}/ops/loans/application/getApplications`, {}, body, (err, resp) => {
            console.log("/getApplications resp body", resp, body);
            // this.setState({ loanAppLoading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    console.log("/getApplications resp data", resp, data);

                    var loanApplications = data.loanApplications || [];
                    var loanApp = loanApplications.length > 0 ? loanApplications[loanApplications.length - 1] : {};
                    this.setState({ loanApplications: loanApplications, loanApplication: loanApp });
                    this.props.dispatch(addDataToStore(CREDIT_SAVE_DATA, { loanApplication: loanApp }));
                    // this.loadAppFromState();
                    this.fetchProposalTerms();
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        })
    }
    fetchProposalTerms = () => {
        const { creditStore } = this.props;
        var { beneficialOwners } = this.state;
        var { loanApplication } = creditStore;
        var body = {
            "clientBusinessId": loanApplication.businessId,
            "applicationId": loanApplication.applicationId,
        };
        console.log("/lsm/getProposalTerms body", body);
        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/getProposalTerms`, {}, body, (err, resp) => {
            this.setState({ loanAppLoading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    console.log(data);
                    this.setState({
                        "proposalTerms": data.offers[0],
                        offers: data.offers
                    });

                    if (beneficialOwners.length > 0 && data.offers[0].creditLimit >= this.state.gnplHigh) {
                        this.flowViews = this.allFlowViews;
                        this.setState({
                            hasBeneficialOwners: true, flowSteps: [
                                "Business Information",
                                "Ownership Information",
                                "Review and submit"
                            ]
                        })

                    } else {
                        this.flowViews = this.allFlowViews.filter((data, idx) => (idx !== 1))
                        if (data.offers[0].creditLimit >= this.state.gnplHigh) {
                            this.flowViews[0].ButtonTitle = "SUBMIT";
                            this.setState({
                                flowSteps: [
                                    "Business Information"
                                ]
                            });
                        } else {
                            this.flowViews[0].Title = "Almost there! A little more information to complete our underwriting and finalize your agreement.";
                            this.flowViews[0].ButtonTitle = "SUBMIT";
                            this.setState({
                                flowSteps: [
                                    "Business Information"
                                ]
                            });
                        }
                        this.setState({
                            hasBeneficialOwners: false
                        })
                    }
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            } finally {
                this.loadAppFromState()
            }
        });
    }
    updateCurrentViewIndex = (i) => {
        this.setState({ currentViewIndex: i });
    }

    updateUserStatus = (userStatus) => {
        const { creditStore } = this.props;
        var { loanApplication } = creditStore;
        var { store } = this.props;
        var { BusinessUniqueKey } = store;
        var body = {
            clientBusinessId: BusinessUniqueKey,
            loanApplication: Object.assign(loanApplication, { userStatus: userStatus })
        }
        console.log("updated User satatus")
        apiPOSTReq(`${environment.opsBaseUrl}/ops/loans/application/save`, {}, body, (err, resp) => {
            try {
                const data = resp || {};
                if (data.result) {
                    this.props.dispatch(addDataToStore(CREDIT_SAVE_DATA, { loanApplication: data.loanApplication }));
                    this.setState({ loanApplication: data.loanApplication })
                    this.fetchBusinessData();
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/updateUserStatus err", error, resp);
            }
        });
    }
    loadAppFromState = () => {
        var { loanApplication } = this.props.creditStore;
        loanApplication = loanApplication || {};
        console.log("loadAppFromState", loanApplication, this.flowViews);
        var currentStep = 0;
        switch (loanApplication.userStatus) {
            case "DueDiligenceStarted":
                currentStep = 0;
                break;
            case "BankingConnected":
                currentStep = 1;
                break;
            case "FinanDocsUploaded":
                currentStep = 2;
                break;
            default:
                break;
        }
        console.log(loanApplication.underwritingStatus + "9999999999999999999999")
        if (loanApplication.underwritingStatus == "Auto Approved" || loanApplication.underwritingStatus == "Approved" || loanApplication.underwritingStatus == "Rejected") {
            if (this.flowViews && this.flowViews.length > 1) {
                currentStep = this.flowViews.length - 1
            } else {
                currentStep = 3;
            }
        } else if (loanApplication.underwritingStatus == "Under Review") {
            this.props.history.push('/apply/credit');
        } else if (loanApplication.contracting == "Contract Signed") {
            this.flowViews = postDDViewsData;
        }
        this.setState({ currentViewIndex: currentStep })
    }

    fetchBusinessData = () => {
        apiPOSTReq(`${environment.uamBaseUrl}/getBusinessOwnership`, {}, {}, (err, resp) => {
            try {
                if (err) throw Error(err);
                const data = resp || {};
                if (data.result) {
                    var business = data.business || {}
                    this.props.dispatch(addDataToStore(UAM_SAVE_BUSINESS, data));
                } else {
                    throw Error(data.responseMessage || data.error);
                }
            } catch (error) {
                console.log("/getBusinessOwnership err", error, resp);
            }
        });
    }
    render() {
        const { currentViewIndex } = this.state
        var { loanApplication } = this.props.creditStore;
        var { userStatus } = loanApplication || {};
        const flowView = this.flowViews[currentViewIndex] || {};

        return (
            <FlexColumn fullHeight style={{ background: currentViewIndex === 3 ? 'white' : '#FBFBFB' }} >
                {
                    flowView.FlowStep === 'Apply.Submitted' &&
                    <Flex start style={{ margin: '12px 24px 12px', position: "absolute", top: "12px", left: "75px" }}>
                        <Image src={AionLogo} />
                    </Flex>
                }
                <FlowViewContainer fullHeight={!['Apply.ReviewAndSubmit'].includes(flowView.FlowStep)} title={null} contentViews={this.getContentViews()} hidesidebar={true} />
                {/* {userStatus == "PreScreenCompleted" && <Conversation/>} */}
            </FlexColumn>
        );
    }

    onOkay = () => {
        this.setState({ showSubmittedModal: false });
        if (!this.props.isCreditPlus) {
            this.props.history.push('/apply/credit');
        }
        else {
            this.loadNextView();
        }
    }

    closeCreditPlus = () => {
        this.props.closeCreditPlus();
    }

    // This will provide the view for each flowView
    getContentViews = () => {
        const { theme, store, creditStore, onboardingStore, isCreditPlus } = this.props;
        var { currentViewIndex, loanAppLoading, businessInfo, businessDocuments, proposalTerms, showDeclineProposalModal, step, flowSteps, applicationStep } = this.state;
        var { loanApplication } = creditStore;
        loanApplication = loanApplication || {};
        const flowView = this.flowViews[currentViewIndex] || {};
        var childViews = (<div></div>); // Initialize
        if (_.isEmpty(proposalTerms)) {
            proposalTerms = store.proposalTerms;
        }

        if (loanAppLoading) {
            return <FlowViewComponent
                flowView={{}}
                childViews={<Skeleton loading={true} active title={false} paragraph={{ rows: 10 }}></Skeleton>}
                width={1200}
                rightIcon={<ArrowRightOutlined />}
                loanApplication={loanApplication}
                applicationStep={applicationStep}
                hideSteps={true}
                close={true}
            />
        }

        switch (flowView.FlowStep) {
            case "Apply.BusinessInfo":
                return (
                    <>
                        <Modal
                            visible={this.state.showSubmittedModal}
                            footer={null}
                            closable={false}
                            width={456}
                            destroyOnClose={true}
                            onCancel={() => {
                            }}
                        >
                            <SuccessModal
                                title='Application Submitted'
                                description={`Thank you for submitting the additional information. We will reach out to you at ${loanApplication.emailAddress} if we have any questions.`}
                                buttonTitle='OKAY'
                                descWidth='330px'
                                onConfirm={this.onOkay} />
                        </Modal>
                        <BusinessInformation
                            loading={loanAppLoading}
                            flowView={flowView}
                            currentViewIndex={currentViewIndex}
                            loadPrev={this.loadPrevView}
                            loadNext={this.loadNext}
                            width={'100%'}
                            back={currentViewIndex > 0}
                            padding='0 64px'
                            step={currentViewIndex}
                            steps={flowSteps}
                            showFooter={currentViewIndex < flowSteps.length}
                            headerMargin='24px 0'
                            loanApplication={loanApplication}
                            proposalTerms={proposalTerms}
                            applicationStep={applicationStep}
                        />
                    </>


                );
            case "Apply.OwnershipInfo":
                return (
                    <OwnershipInformation
                        loading={loanAppLoading}
                        flowView={flowView}
                        currentViewIndex={currentViewIndex}
                        loadPrev={this.loadPrevView}
                        loadNext={this.loadNext}
                        width={'100%'}
                        back={currentViewIndex > 0}
                        padding='0 64px'
                        step={currentViewIndex}
                        steps={flowSteps}
                        showFooter={currentViewIndex < flowSteps.length}
                        headerMargin='24px 0'
                        loanApplication={loanApplication}
                        applicationStep={applicationStep}
                        proposalTerms={proposalTerms}
                        isCreditPlus={isCreditPlus}
                    />
                )
            case "Apply.ReviewAndSubmit":
                return (
                    <>
                        <Modal
                            visible={this.state.showSubmittedModal}
                            footer={null}
                            closable={false}
                            width={456}
                            destroyOnClose={true}
                            onCancel={() => {
                            }}
                        >
                            <SuccessModal
                                title='Application Submitted'
                                description={`Thank you for submitting the additional information. We will reach out to you at ${loanApplication.emailAddress} if we have any questions.`}
                                buttonTitle='OKAY'
                                descWidth='330px'
                                onConfirm={this.onOkay} />
                        </Modal>
                        {
                            proposalTerms.creditLimit < this.state.gnplHigh ? null :
                                <ReviewAndSubmit
                                    flowView={flowView}
                                    flowViews={this.flowViews}
                                    currentViewIndex={currentViewIndex}
                                    loadPrev={this.loadPrevView}
                                    loadNext={this.loadNext}
                                    loadNextView={this.loadNextView}
                                    width={'100%'}
                                    padding='0 64px'
                                    step={currentViewIndex}
                                    steps={flowSteps}
                                    headerMargin='24px 0'
                                    applicationStep={applicationStep}
                                    updateCurrentViewIndex={this.updateCurrentViewIndex}
                                    hideSteps={true}
                                    goToStep={this.goToStep}
                                    loanApplication={loanApplication}
                                    proposalTerms={proposalTerms}
                                    isCreditPlus={isCreditPlus}
                                />
                        }

                    </>
                )
            case "Apply.Submitted":
                return (<><CreditAppStatus loanApplication={loanApplication} isCreditPlus={isCreditPlus ? true : false}
                    proposalTerms={proposalTerms} closeCreditPlus={this.closeCreditPlus} /></>)
            case "Apply.ContractSigned":
                return (<><CreditAppStatus loanApplication={loanApplication} isCreditPlus={isCreditPlus ? true : false}
                    proposalTerms={proposalTerms} /></>)
            default:
                break;
        }

        return <FlowViewComponent
            loading={loanAppLoading}
            flowView={flowView}
            currentViewIndex={currentViewIndex}
            loadPrev={this.loadPrevView}
            loadNext={this.loadNext}
            childViews={childViews}
            submitLoading={this.state.submitLoading}
            width={'100%'}
            back={currentViewIndex > 0}
            padding='0 64px'
            step={currentViewIndex}
            steps={flowSteps}
            showFooter={currentViewIndex < flowSteps.length}
            headerMargin='24px 0'
            loanApplication={loanApplication}
            applicationStep={applicationStep}
            hideSteps={true}
        />
    }

    sendMail = (loanApplication, profile, UserInfo) => {
        // Send confirmation email to Underwriting
        var notificationBody = {
            "fromAddr": "Aion <notification@aionfi.com>",
            "toAddr": process.env.REACT_APP_ENV == "production" ? "creditops@mg.aionfi.com" : "stagingmoderators@mg.aionfi.com",
            // "ccAddr" : "", 
            "bccAddr": process.env.REACT_APP_ENV == "production" ? "creditcustomops@mg.aionfi.com" : "akumar@aionfi.com",
            "emailSubject": `Due Diligence Submitted by ${profile.BusinessInfo.Name}`,
            "emailBody": `<p>Dear Underwriting Team,<p>Due diligence information was submitted for below business/application. You can view this application by logging in to https://app.aionfi.com.</p><p>ID: <b>${(loanApplication || {}).applicationId}</b><br/>Business: <b>${profile.BusinessInfo.Name}</b><br/>User: <b>${UserInfo.FirstName} ${UserInfo.LastName}</b></p></p>`
        }
        // apiPOSTReq(`${environment.opsBaseUrl}/ops/sendEmail`, {}, notificationBody, (err, resp) => {
        apiPOSTReq(`${environment.opsBaseUrl}/ops/sendEmail`, null, notificationBody, (err, resp) => {
            console.log("sendEmail", err, resp, notificationBody);
        })
        this.setState({ showSubmittedModal: true })
    }

    sendAutoApprovedMail = (loanApplication, profile, UserInfo) => {
        const { offers } = this.state;
        var proposalTerms = offers.filter(x => x.selected == true);
        var pt = proposalTerms[0];
        // Send confirmation email to Underwriting
        var notificationBody = {
            "fromAddr": "Aion <notification@aionfi.com>",
            "toAddr": process.env.REACT_APP_ENV == "production" ? "spalvai@aionfi.com, akrishnan@aionfi.com, dsunku@aionfi.com, akodialbail@aionfi.com, akumar@aionfi.com" : "stagingmoderators@mg.aionfi.com",
            "ccAddr": process.env.REACT_APP_ENV == "production" ? "credit@aionfi.com" : "akumar@aionfi.com",
            "emailSubject": `GNPL Application Auto-Approved: ${profile.BusinessInfo.Name} ${loanApplication.applicationId}`,
            "emailBody": `<p>Hello,<p>The following application was automatically approved:</p><p><b>Business & Application Details:</b></p>
            <p>Business Name: <b>${profile.BusinessInfo.Name}</b><br/>Applicant Name: <b>${UserInfo.FirstName} ${UserInfo.LastName}</b><br/>Applicant Email: <b>${(loanApplication || {}).emailAddress}</b><br/>Application ID: <b>${(loanApplication || {}).applicationId}</b></p>
            <p><b>Proposal Terms</b></p>
            <p>Proposal Amount: <b>${pt.creditLimit}</b><br/>Product: <b>${pt.productType}</b><br/>Repayment Frequency: <b>${pt.paymentPlan}</b><br/>Repayment Term: <b>${pt.repaymentTerms}</b></p>
            <p>Please feel free to review the  log in to app.aionfi.com to review this application.</p>
            </p>`
        }
        // apiPOSTReq(`${environment.opsBaseUrl}/ops/sendEmail`, {}, notificationBody, (err, resp) => {
        apiPOSTReq(`${environment.opsBaseUrl}/ops/sendEmail`, null, notificationBody, (err, resp) => {
            console.log("sendEmail", err, resp, notificationBody);
        })
        this.setState({ showSubmittedModal: true })
    }

    // Validate input and go to next view if it's good
    loadNext = () => {
        var submitLoading = this.state.submitLoading;

        if (!submitLoading) {
            var { parentCompanyName, naicsCode6, accountClosingMonth, loanApp, ein, proposalTerms, autoApproveDD } = this.state;
            const { creditStore } = this.props;
            var { selectedProduct, loanApplication } = creditStore;
            var flowView = this.flowViews[this.state.currentViewIndex];
            var { store } = this.props;
            var { Profile, UserInfo, BusinessUniqueKey } = store;
            console.log("Profile", Profile);
            var flowView = this.flowViews[this.state.currentViewIndex];
            var validation = { validated: true };
            if (validation.validated) {
                switch (flowView.FlowStep) {
                    case "Apply.BusinessInfo":
                        this.updateUserStatus("BankingConnected");
                        var { store } = this.props;
                        var { BusinessUniqueKey } = store;
                        var body = {
                            "clientBusinessId": BusinessUniqueKey
                        };

                        if (proposalTerms.creditLimit < this.state.gnplHigh) {
                            this.completeGnplProcess();
                        }
                        else {
                            this.loadNextView();
                        }

                        break;
                    case "Apply.OwnershipInfo":
                        this.updateUserStatus("FinanDocsUploaded");
                        var body = {
                            clientBusinessId: BusinessUniqueKey,
                            loanApplication: Object.assign(loanApplication, { userStatus: "FinanDocsUploaded" })
                        }
                        if (accountClosingMonth) body["accountClosingMonth"] = accountClosingMonth;
                        if (naicsCode6) body["naics6"] = naicsCode6;
                        if (parentCompanyName) body["parentCompanyName"] = parentCompanyName;
                        if (ein) body["ein"] = ein;
                        console.log("/ops/loans/application/save DocumentUpload", body);
                        this.setState({ submitLoading: true });
                        apiPOSTReq(`${environment.opsBaseUrl}/ops/loans/application/save`, {}, body, (err, resp) => {
                            this.setState({ submitLoading: false });
                            try {
                                const data = resp || {};
                                if (data.result) {
                                    this.props.dispatch(addDataToStore(CREDIT_SAVE_DATA, { loanApplication: data.loanApplication }))
                                    this.loadNextView();
                                } else {
                                    throw Error(data.responseMessage || data.error)
                                }
                            } catch (error) {
                                //console.log("/getPersonCards err", error, resp);
                                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
                            }
                        });
                        break;
                    case "Apply.ReviewAndSubmit":
                        this.completeGnplProcess();
                        // this.updateUserStatus("DDCompleted");
                        // if (proposalTerms.creditLimit < this.state.autoApprovalAmount) {
                        //     if (autoApproveDD === 'true') {
                        //         loanApplication["underwritingStatus"] = "Auto Approved";

                        //     } else {
                        //         loanApplication["underwritingStatus"] = "Not Started";
                        //     }
                        // }
                        // else {
                        //     loanApplication["underwritingStatus"] = "Not Started";
                        // }
                        // var body = {
                        //     clientBusinessId: BusinessUniqueKey,
                        //     loanApplication: loanApplication
                        // }
                        // if (accountClosingMonth) body["accountClosingMonth"] = accountClosingMonth;
                        // if (naicsCode6) body["naics6"] = naicsCode6;
                        // if (parentCompanyName) body["parentCompanyName"] = parentCompanyName;
                        // if (ein) body["ein"] = ein;
                        // console.log("/ops/loans/application/save", body);
                        // this.setState({ submitLoading: true });

                        // // Update the application with the closing month
                        // apiPOSTReq(`${environment.opsBaseUrl}/ops/loans/application/save`, {}, body, (err, resp) => {
                        //     this.setState({ submitLoading: false });
                        //     try {
                        //         const data = resp || {};
                        //         var body = {
                        //             BusinessId: BusinessUniqueKey,
                        //             ApplicationId: (loanApplication || {}).applicationId,
                        //             ClosingDate: accountClosingMonth, // YYYY-MM-DD
                        //         }
                        //         apiPOST('/assess/Borrower', body, {}, (err, resp) => {
                        //             console.log("Apply.Submit /assess/Borrower", body, err, resp);
                        //             this.setState({ submitLoading: false });
                        //             if (!err) {
                        //                 try {
                        //                     const data = resp.data;
                        //                     if (data.success) {
                        //                     } else {
                        //                         throw Error(data.message)
                        //                     }
                        //                 } catch (error) {
                        //                     console.error("Apply.Submit /assess/Borrower, the error message is :" + error)
                        //                 }
                        //             } else {
                        //                 console.error("Apply.Submit /assess/Borrower, the error message is :" + err)
                        //             }
                        //         });
                        //         if (data.result) {
                        //             var body = {
                        //                 BusinessId: BusinessUniqueKey,
                        //                 ApplicationId: (loanApplication || {}).applicationId,
                        //             }
                        //             apiPOSTReq(`${environment.lsmBaseUrl}/lsm/updateContractConfig`, {}, body, (err, resp) => {
                        //                 try {
                        //                     if (proposalTerms.creditLimit < this.state.autoApprovalAmount) {
                        //                         if (autoApproveDD === 'true') {
                        //                             var body = {
                        //                                 BusinessId: BusinessUniqueKey,
                        //                                 ApplicationId: (loanApplication || {}).applicationId,
                        //                             }
                        //                             apiPOSTReq(`${environment.lsmBaseUrl}/lsm/contract/automateContract`, {}, body, (err, resp) => {
                        //                                 try {
                        //                                 }
                        //                                 catch (error) {
                        //                                     console.error("Apply.Submit lsm/contract/automateContract, the error message is :" + error)
                        //                                 }
                        //                             });

                        //                             this.sendAutoApprovedMail(loanApplication, Profile, UserInfo);
                        //                         }
                        //                     }
                        //                     if (!Profile.BusinessInfo || !Profile.BusinessInfo.Name) {
                        //                         const headers = {
                        //                             businesskey: this.props.store.BusinessUniqueKey
                        //                         }

                        //                         apiGET("/profile", headers, (err, resp) => {
                        //                             try {
                        //                                 const pData = resp.data;
                        //                                 if (pData.success) {
                        //                                     var profile = pData.profileInfo;
                        //                                     this.sendMail(loanApplication, profile, UserInfo);
                        //                                 }
                        //                             }
                        //                             catch (error) {
                        //                                 console.error("Apply.Submit /profile, the error message is :" + error)
                        //                             }
                        //                         });
                        //                     }
                        //                     else {
                        //                         this.sendMail(loanApplication, Profile, UserInfo)
                        //                     }
                        //                 }
                        //                 catch (error) {
                        //                     console.error("Apply.Submit lsm/updateContractConfig, the error message is :" + error)
                        //                 }
                        //             });
                        //             // if (proposalTerms.creditLimit < this.state.gnplHigh) {
                        //             //     if (autoApproveDD == true) {
                        //             //         var body = {
                        //             //             BusinessId: BusinessUniqueKey,
                        //             //             ApplicationId: (loanApplication || {}).applicationId,
                        //             //         }
                        //             //         apiPOSTReq(`${environment.lsmBaseUrl}/lsm/contract/automateContract`, {}, body, (err, resp) => {
                        //             //             try {
                        //             //             }
                        //             //             catch (error) {
                        //             //                 console.error("Apply.Submit lsm/contract/automateContract, the error message is :" + error)
                        //             //             }
                        //             //         });

                        //             //         this.sendAutoApprovedMail(loanApplication, Profile, UserInfo);
                        //             //     }
                        //             // }
                        //             // if (!Profile.BusinessInfo || !Profile.BusinessInfo.Name) {
                        //             //     const headers = {
                        //             //         businesskey: this.props.store.BusinessUniqueKey
                        //             //     }

                        //             //     apiGET("/profile", headers, (err, resp) => {
                        //             //         try {
                        //             //             const pData = resp.data;
                        //             //             if (pData.success) {
                        //             //                 var profile = pData.profileInfo;
                        //             //                 this.sendMail(loanApplication, profile, UserInfo);
                        //             //             }
                        //             //         }
                        //             //         catch (error) {
                        //             //             console.error("Apply.Submit /profile, the error message is :" + error)
                        //             //         }
                        //             //     });
                        //             // }
                        //             // else {
                        //             //     this.sendMail(loanApplication, Profile, UserInfo)
                        //             // }
                        //         } else {
                        //             throw Error(data.responseMessage || data.error)
                        //         }
                        //     } catch (error) {
                        //         //console.log("/getPersonCards err", error, resp);
                        //         ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
                        //     }
                        // });
                        break;
                    default:
                        this.loadNextView();
                        break
                }
            } else {
                ErrorAlert({ description: validation.message });
            }
        }

    }

    completeGnplProcess = () => {
        var submitLoading = this.state.submitLoading;
        if (!submitLoading) {
            this.setState({ submitLoading: true });
            const { creditStore } = this.props;
            var { loanApplication } = creditStore;
            loanApplication["underwritingStatus"] = "Not Started";
            this.props.dispatch(addDataToStore(CREDIT_SAVE_DATA, { loanApplication: loanApplication }));
            this.updateUserStatus("DDCompleted");
            this.setState({ showSubmittedModal: true });
        }
    }

    // completeGnplProcess = () => {

    //     var submitLoading = this.state.submitLoading;

    //     if (!submitLoading) {
    //         this.setState({ submitLoading: true });
    //         var { accountClosingMonth, proposalTerms, autoApproveDD } = this.state;
    //         const { creditStore } = this.props;
    //         var { loanApplication } = creditStore;
    //         var { store } = this.props;
    //         var { Profile, UserInfo, BusinessUniqueKey } = store;

    //         this.updateUserStatus("Completed");
    //         if (proposalTerms.creditLimit < this.state.autoApprovalAmount) {
    //             if (autoApproveDD === 'true') {
    //                 loanApplication["underwritingStatus"] = "Auto Approved";
    //             }
    //             else {
    //                 loanApplication["underwritingStatus"] = "Not Started";
    //             }
    //         }
    //         else {
    //             loanApplication["underwritingStatus"] = "Not Started";
    //         }
    //         var body = {
    //             clientBusinessId: BusinessUniqueKey,
    //             loanApplication: loanApplication
    //         }

    //         console.log("/ops/loans/application/save", body);
    //         this.setState({ submitLoading: true });

    //         // Update the application with the closing month
    //         apiPOSTReq(`${environment.opsBaseUrl}/ops/loans/application/save`, {}, body, (err, resp) => {
    //             this.setState({ submitLoading: false });
    //             try {
    //                 const data = resp || {};
    //                 var body = {
    //                     BusinessId: BusinessUniqueKey,
    //                     ApplicationId: (loanApplication || {}).applicationId,
    //                     ClosingDate: accountClosingMonth, // YYYY-MM-DD
    //                 }
    //                 apiPOST('/assess/Borrower', body, {}, (err, resp) => {
    //                     console.log("Apply.Submit /assess/Borrower", body, err, resp);
    //                     this.setState({ submitLoading: false });
    //                     if (!err) {
    //                         try {
    //                             const data = resp.data;
    //                             if (data.success) {
    //                             } else {
    //                                 throw Error(data.message)
    //                             }
    //                         } catch (error) {
    //                             console.error("Apply.Submit /assess/Borrower, the error message is :" + error)
    //                         }
    //                     } else {
    //                         console.error("Apply.Submit /assess/Borrower, the error message is :" + err)
    //                     }
    //                 });
    //                 if (data.result) {
    //                     var body = {
    //                         BusinessId: BusinessUniqueKey,
    //                         ApplicationId: (loanApplication || {}).applicationId,
    //                     }
    //                     apiPOSTReq(`${environment.lsmBaseUrl}/lsm/updateContractConfig`, {}, body, (err, resp) => {
    //                         try {
    //                             if (proposalTerms.creditLimit < this.state.autoApprovalAmount) {
    //                                 if (autoApproveDD === 'true') {
    //                                     var body = {
    //                                         BusinessId: BusinessUniqueKey,
    //                                         ApplicationId: (loanApplication || {}).applicationId,
    //                                     }
    //                                     apiPOSTReq(`${environment.lsmBaseUrl}/lsm/contract/automateContract`, {}, body, (err, resp) => {
    //                                         try {
    //                                         }
    //                                         catch (error) {
    //                                             console.error("Apply.Submit lsm/contract/automateContract, the error message is :" + error)
    //                                         }
    //                                     });
    //                                     this.sendAutoApprovedMail(loanApplication, Profile, UserInfo);
    //                                 }
    //                             }
    //                             if (!Profile.BusinessInfo || !Profile.BusinessInfo.Name) {
    //                                 const headers = {
    //                                     businesskey: this.props.store.BusinessUniqueKey
    //                                 }

    //                                 apiGET("/profile", headers, (err, resp) => {
    //                                     try {
    //                                         const pData = resp.data;
    //                                         if (pData.success) {
    //                                             var profile = pData.profileInfo;
    //                                             this.sendMail(loanApplication, profile, UserInfo);
    //                                         }
    //                                     }
    //                                     catch (error) {
    //                                         console.error("Apply.Submit /profile, the error message is :" + error)
    //                                     }
    //                                 });
    //                             }
    //                             else {
    //                                 this.sendMail(loanApplication, Profile, UserInfo)
    //                             }
    //                         }
    //                         catch (error) {
    //                             console.error("Apply.Submit lsm/updateContractConfig, the error message is :" + error)
    //                         }
    //                     });
    //                     // if (proposalTerms.creditLimit < this.state.gnplHigh) {
    //                     //     if (autoApproveDD == true) {
    //                     //         var body = {
    //                     //             BusinessId: BusinessUniqueKey,
    //                     //             ApplicationId: (loanApplication || {}).applicationId,
    //                     //         }
    //                     //         apiPOSTReq(`${environment.lsmBaseUrl}/lsm/contract/automateContract`, {}, body, (err, resp) => {
    //                     //             try {
    //                     //             }
    //                     //             catch (error) {
    //                     //                 console.error("Apply.Submit lsm/contract/automateContract, the error message is :" + error)
    //                     //             }
    //                     //         });

    //                     //         this.sendAutoApprovedMail(loanApplication, Profile, UserInfo);
    //                     //     }
    //                     // }
    //                     // if (!Profile.BusinessInfo || !Profile.BusinessInfo.Name) {
    //                     //     const headers = {
    //                     //         businesskey: this.props.store.BusinessUniqueKey
    //                     //     }

    //                     //     apiGET("/profile", headers, (err, resp) => {
    //                     //         try {
    //                     //             const pData = resp.data;
    //                     //             if (pData.success) {
    //                     //                 var profile = pData.profileInfo;
    //                     //                 this.sendMail(loanApplication, profile, UserInfo);
    //                     //             }
    //                     //         }
    //                     //         catch (error) {
    //                     //             console.error("Apply.Submit /profile, the error message is :" + error)
    //                     //         }
    //                     //     });
    //                     // }
    //                     // else {
    //                     //     this.sendMail(loanApplication, Profile, UserInfo)
    //                     // }
    //                 } else {
    //                     throw Error(data.responseMessage || data.error)
    //                 }
    //             } catch (error) {
    //                 //console.log("/getPersonCards err", error, resp);
    //                 ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
    //             }
    //         });
    //     }


    // }

    loadPrevView = () => {
        if (this.state.currentViewIndex !== 0) {
            this.setState({
                currentViewIndex: this.state.currentViewIndex - 1,
                showError: false, errorMsg: ""      // dismiss any validatin error, if any, for the current step
            });
        } else {
            this.props.history.push("/apply/credit");
        }
    }

    loadNextView = () => {
        const { creditStore, store } = this.props;
        var { loanApplication } = creditStore;
        var { business } = store;


        if (this.state.currentViewIndex + 1 < this.flowViews.length) {
            this.setState({ currentViewIndex: this.state.currentViewIndex + 1 });
        }

        window.scrollTo(0, 0);
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer,
        creditStore: state.creditAppReducer,
        onboardingStore: state.onboardingAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)));