import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import { saveAs } from 'file-saver'
import { startCase } from 'lodash';
import { Skeleton, Divider, Table } from 'antd';
import { FlexColumn, Container, Flex} from '../../Reusable/Container';
import PageHeader from '../../Reusable/PageHeader';
import { Title } from '../../Reusable/Text';
import { addressObjectToStr } from "../../../Utils/util";
import { apiPOSTReq, getPreSignedS3Url } from '../../../Utils/api';
import environment from '../../../environment';
import { ErrorAlert } from '../../Reusable/Alert';

const downloadAttachment = (url, name) => {
    getPreSignedS3Url({ url: url }, (err, preSignedUrl) => {
        if (!err) {
            saveAs(preSignedUrl, name)
        } else {
            ErrorAlert({ description: JSON.stringify(err) })
        }
    })
}

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            pagination: {
                current: 1,
                pageSize: 100
            }
        }
    }

    componentDidMount() {
        this.fetchDocuments({ pagination: this.state.pagination });
    }

    fetchDocuments = (options) => {
        const { loanApp } = this.props;
        const { pagination } = options || {};
        var body = {
            "size": pagination.pageSize,
            "page": pagination.current - 1,
            "sortDirection": "DESC"
        };
        if (loanApp) {
            body["clientBusinessId"] = loanApp.businessId;
            body["applicationId"] = loanApp.applicationId;
            body["viewBy"] = "FILTER_APPLICATION"
        }
        console.log("/docs/getActiveDocuments body", body);
        this.setState({ loading: true });
        apiPOSTReq(`${environment.uamBaseUrl}/docs/getActiveDocuments`, {}, body, (err, resp) => {
            console.log("/docs/getActiveDocuments resp", resp);
            this.setState({ loading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    this.setState({
                        businessDocuments: data.businessDocuments,
                        pagination: {
                            ...pagination,
                            total: data.count,
                            pageable: data.pageable
                        }
                    });
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    businessData = () => {
        const { aionStore, theme } = this.props;
        var { Profile, linkedBusinessList } = aionStore;
        linkedBusinessList = linkedBusinessList || [];
        var { BusinessInfo } = Profile || {};
        BusinessInfo = BusinessInfo || {};
        if (!BusinessInfo.Name) return null;
        var items = [];
        console.log("Business Profile BusinessInfo :", BusinessInfo)
        Object.keys(BusinessInfo).forEach(key => {
            switch (key) {
                case "MailingAddress":
                case "PhysicalAddress":
                    items.push({
                        name: startCase(key),
                        value: addressObjectToStr(BusinessInfo[key])
                    })
                    break;
                case "Urls":
                    items.push({
                        name: startCase(key),
                        value: BusinessInfo[key].join(" ")
                    })
                    break;
                case "OpenCorporates":
                    items.push({
                        name: startCase(key),
                        value: BusinessInfo[key] ? JSON.stringify(BusinessInfo[key]) : null
                    })
                    break;
                default:
                    items.push({
                        name: startCase(key),
                        value: BusinessInfo[key]
                    })
            }
        });

        return (
            <Table
                key="profile-table"
                tableLayout='auto'
                columns={[
                    {
                        title: 'Name',
                        dataIndex: 'name',
                        width: "250px"
                    },
                    {
                        title: 'Value',
                        dataIndex: 'value',
                        render: val => typeof(val) == 'string' ? val : JSON.stringify(val)
                    }
                ]}
                dataSource={items || []}
                rowKey='name'
                pagination={false}
                style={{ minHeight: "500px" }}
            // scroll={{ y: '700px', x: '100%' }}
            />
        )
    }

    applicantData = (profile) => {
        const { Pinned } = this.props.aionStore;
        profile = profile || {};
        var items = [];
        console.log("Business Profile profile :", profile)
        Object.keys(profile).forEach(key => {
            switch (key) {
                case "Address":
                    items.push({
                        name: startCase(key),
                        value: addressObjectToStr(profile[key])
                    })
                    break;
                case "SSN":
                    items.push({
                        name: startCase(key),
                        value: (profile[key] && profile[key].length > 4) ? `###-##-${profile[key].substring(profile[key].length - 4)}` : profile[key]
                    })
                    break
                case "Pdf417Info":
                    break;
                case "CitizenOrResident":
                case "AuthorizedSigner":
                case "ControlPerson":
                    items.push({
                        name: startCase(key),
                        value: profile[key] === true ? "Yes" : "No"
                    })
                    break;
                default:
                    if(typeof(profile[key]) != 'object') {
                        items.push({
                            name: startCase(key),
                            value: profile[key]
                        });
                    }
                    break;
            }
        });

        return (
            <Table
                key={profile.SSN}
                tableLayout='auto'
                columns={[
                    {
                        title: 'Name',
                        dataIndex: 'name',
                        width: "250px"
                    },
                    {
                        title: 'Value',
                        dataIndex: 'value',
                        render: (value) => <Flex start wrap style={{width:  value.length > 140 ? Pinned ? `1.7%` : `2%` : `100%`, display: "inline-block"}}>
                        {value}
                        </Flex>
                            }
                ]}
                dataSource={items || []}
                rowKey='name'
                pagination={false}
                style={{ minHeight: "500px" }}
            // scroll={{ y: '700px', x: '100%' }}
            />
        )
    }

    documentList = () => {
        const { businessDocuments } = this.state

        var items = [];

        businessDocuments.forEach(doc => {
            items.push({
                name: doc.name,
                value: doc.documentUrl,
            })
        })

        return (
            <Table
                key='businessDocuments'
                tableLayout='auto'
                columns={[
                    {
                        title: 'Name',
                        dataIndex: 'name',
                        width: "250px"
                    },
                    {
                        title: 'Value',
                        dataIndex: 'value',
                        render: (value, item) => <a onClick={() => downloadAttachment(value.uri, value.fileName)}>{value.fileName}</a>
                    }
                ]}
                dataSource={items || []}
                rowKey='name'
                pagination={false}
            />
        )
    }

    render() {
        const { aionStore } = this.props;
        var { Profile } = aionStore;
        var { BusinessInfo, PrimaryApplicantInfo, CoOwnerApplicantInfo } = Profile || {};
        CoOwnerApplicantInfo = CoOwnerApplicantInfo || [];
        const { loading, businessDocuments } = this.state;
        const businessData = this.businessData();

        return (
            <>
                <FlexColumn style={{ margin: "10px 70px 50px" }}>
                    <Skeleton loading={loading} active title={true} paragraph={{ rows: 5 }}>
                        <PageHeader
                            titleText={"Business Profile"}
                        />
                        <Container shadow style={{ margin: '24px 0' }}>
                        <FlexColumn>
                            {businessData}
                        </FlexColumn>
                        <Divider style={{ margin: "15px 0" }} />
                        <FlexColumn style={{ marginTop: 20 }}>
                            <Title level={6}>Primary Applicant</Title>
                            {this.applicantData(PrimaryApplicantInfo)}
                        </FlexColumn>
                        <FlexColumn style={{ marginTop: 20 }}>
                            <Title level={6}>Beneficial Owners</Title>
                            {CoOwnerApplicantInfo.map(applicant => this.applicantData(applicant))}
                        </FlexColumn>
                        {
                            (businessDocuments || []).length > 0 && 
                            <FlexColumn style={{ marginTop: 20 }}>
                                <Title level={6}>Documents</Title>
                                {this.documentList()}
                            </FlexColumn>
                        }
                        </Container>
                    </Skeleton>
                </FlexColumn>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer,
        bankingStore: state.bankingAppReducer,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Index));