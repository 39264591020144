import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';
import {
    Table,
    Skeleton,
    Modal, Badge, Popover, Tag, Select
} from 'antd';
import _ from 'lodash'

// Components
import moment from 'moment';
import { apiPOSTReq, getPreSignedS3Url } from '../../../../Utils/api';
import environment from '../../../../environment';
import { Paragraph, Text } from '../../../Reusable/Text';
import { LabeledInput } from '../../../Reusable/Input';
import { ErrorAlert } from '../../../Reusable/Alert';
import { Flex, FlexColumn, CardContainer, LightContainer } from '../../../Reusable/Container';
import { addressObjectToStr } from "../../../../Utils/util";
import { toCurrency } from '../../../../Utils/util';
import { TextButton, Button } from '../../../Reusable/Button';
import { FilePdfOutlined, EyeOutlined, EyeInvisibleOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { OwnserSummaryCard } from '../../../Reusable/Refresh/Card';
import { saveAs } from 'file-saver'
import { Image } from '../../../Reusable/Image'
import SuccessModal from '../../../Reusable/SuccessModal';
import File from '../../../../Images/file.png'
import BankIcon from '../../../../Images/bank-generic.png';
import Info from '../../../Reusable/Info';

const { Option } = Select;

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

class Index extends Component {

    state = {
        highCreditOwnerDocs: [
            { name: "License" },
            { name: "PFS" },
            { name: "2021 Tax Returns" },
            { name: "2022 Tax Returns" }
        ],
        viewSSN: false,
        viewEIN: false,
        gnplLow: 0,
        gnplHigh: 100001,
        selectedBank: {},
        selectedConnection: {},
        reportType: "Profit and Loss",
        fromDate: moment().startOf('month'),
        toDate: moment(),
        month: moment().startOf('month')
    };

    componentDidMount() {
        this.fetchData({});
        this.fetchDocuments({});
        this.fetchBusinessAttributes();
        this.fetchDebtSchedule();
        this.fetchBanks();
        this.fetchCodatConnections();
        this.fetchKnowYourBusiness();
    }

    fetchData = () => {
        const { loanApp } = this.props;
        var body = {
            "clientBusinessId": loanApp.businessId
        };
        console.log("/business/admin/getBusinessData body", body);
        this.setState({ loading: true });
        apiPOSTReq(`${environment.uamBaseUrl}/business/admin/getBusinessData`, null, body, (err, resp) => {
            this.setState({ loading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    this.setState({
                        business: data.business
                    });

                    this.fetchReferralCode();
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    fetchReferralCode = () => {
        const { loanApp } = this.props;
        var body = {
            "BusinessId": loanApp.businessId
        };
        console.log("/business/admin/getBusinessData body", body);
        this.setState({ loading: true });
        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/getReferralCode`, null, body, (err, resp) => {
            this.setState({ loading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    this.setState({
                        referralCode: data.referralCode
                    });
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    fetchDebtSchedule = () => {
        const { loanApp } = this.props;
        var body = {
            applicationId: loanApp.applicationId,
            BusinessId: loanApp.businessId
        }
        apiPOSTReq(`${environment.uamBaseUrl}/uam/generateDebtSchedule`, {}, body, (err, resp) => {
            try {
                if (err) throw Error(err);
                const data = resp || {};
                if (data.result) {
                    if (data.debtOverview)
                        this.setState({ debtOverview: data.debtOverview })
                } else {
                    throw Error(data.responseMessage || data.error);
                }
            } catch (error) {
                console.log("/generateDebtSchedule err", error, resp);
            }
        });
    }

    fetchBusinessAttributes = () => {
        var body = {
            "clientBusinessId": ''
        };

        this.setState({ attributesLoading: true });
        // apiPOSTReq(`${environment.opsBaseUrl}/ops/fetchBusinessAttributes`, {}, body, (err, resp) => {
        apiPOSTReq(`${environment.opsBaseUrl}/ops/fetchBusinessAttributes`, null, body, (err, resp) => {
            try {
                if (err) throw Error(err);
                const data = resp || {};
                if (data.result) {
                    var lineSetup = data.businessAttributes.filter(x => x.name == "GNPLAutomatedLineSetupFlow");
                    if (lineSetup.length > 0) {
                        var attribute = lineSetup[0];
                        var additionalParameterReference = attribute.additionalParameterReference;
                        var parameters = additionalParameterReference.parameters;
                        var low = parseInt(parameters[0].value);
                        var high = parseInt(parameters[1].value);
                        this.setState({ gnplLow: low, gnplHigh: high })
                    }
                } else {
                    throw Error(data.responseMessage || data.error);
                }
            } catch (error) {
                console.log("/generateDebtSchedule err", error, resp);
            }
        });
    }

    fetchDocuments = () => {
        const { loanApp } = this.props;
        var body = {
            "sortDirection": "DESC"
        };
        if (loanApp) {
            body["clientBusinessId"] = loanApp.businessId;
            body["applicationId"] = loanApp.applicationId;
            body["viewBy"] = "FILTER_APPLICATION"
        }
        console.log("/docs/getActiveDocuments body", body, loanApp);
        this.setState({ loading: true });
        apiPOSTReq(`${environment.uamBaseUrl}/docs/getActiveDocuments`, {}, body, (err, resp) => {
            console.log("/docs/getActiveDocuments resp", resp);
            this.setState({ loading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    this.setState({
                        businessDocuments: data.businessDocuments
                    });
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    downloadStatement = (url, name) => {
        getPreSignedS3Url({ url: url }, (err, preSignedUrl) => {
            saveAs(preSignedUrl, name)
        })
    }

    getDocument = (name, options) => {
        options = options || {};
        var { businessDocuments } = this.state;
        var savedDoc = this.state[name] || {};
        var filteredDoc = (businessDocuments || []).find(item => (item.name == name));
        if (filteredDoc != undefined) {
            // Replace with remotely stored data
            if (options.businessDocument) savedDoc = filteredDoc;
            else savedDoc = filteredDoc.documentUrl || {};
        }
        return savedDoc;
    }
    getColumns = () => {
        const { theme, store } = this.props;
        let { highCreditOwnerDocs, business } = this.state;
        var businessProfile = (business || {}).businessProfile || {};
        var contractOwnerApplicantInfo = businessProfile.contractOwnerApplicantInfo || [];

        const { Pinned } = store || false;
        var columns = [
            {
                title: 'Individual',
                render: (item) => <div >
                    <OwnserSummaryCard padding='12px 0' ownerInfos={item} theme={theme} symbolprefix="%" onClick={() => {
                        this.props.changeTabClick("Verification", "kyc");
                        this.setState({ showOwnerModal: true, selectedOwner: item });
                    }} />
                </div>,
                width: 100
            },
            {
                title: 'Contract Owner',
                render: (item) => <div >
                    {item.email === contractOwnerApplicantInfo.email ? <div> true </div> : null}
                </div>,
                width: 100
            },
            {
                title: 'Ownership Percentage',
                dataIndex: 'ownershipPercentage',
                width: 50
            }
        ];
        let ownerDocColumns = [];
        highCreditOwnerDocs.map(doc => {
            ownerDocColumns.push({
                title: doc.name,
                width: 100,
                render: (item) => {
                    var docUploaded = this.getDocument(doc.name + "-" + item.email);

                    return (<Flex start >
                        {_.isEmpty(docUploaded) ? <></> :
                            <><TextButton inLine breakWord preLine width={Pinned ? "100px" : "150px"} size="14px" style={{ textAlign: "left", padding: "8px 8px" }} type="link" icon={<FilePdfOutlined />}
                                onClick={() => this.downloadStatement(this.getDocument(doc.name + "-" + item.email).uri, this.getDocument(doc.name + "-" + item.email).fileName)}
                                text={this.getDocument(doc.name + "-" + item.email).fileName}></TextButton>
                            </>
                        }
                    </Flex>)
                }
            }
            )

        });
        ownerDocColumns.map(col => columns.push(col));


        return columns;

    }
    ownershipData = () => {
        const { theme } = this.props;
        const { business } = this.state;
        var businessProfile = (business || {}).businessProfile || {};
        var ownerInfo = [];
        var coOwnerApplicantInfo = businessProfile.coOwnerApplicantInfo || [];
        var primaryOwnerApplicantInfo = businessProfile.primaryApplicantInfo || [];
        ownerInfo = ownerInfo.concat(primaryOwnerApplicantInfo);
        ownerInfo = ownerInfo.concat(coOwnerApplicantInfo);
        console.log("ownerInfo", ownerInfo, businessProfile.coOwnerApplicantInfo);
        return (
            <>
                <Paragraph level={3} bold color={theme.colors.secondary3}>Ownership Structure</Paragraph>
                <Table
                    id="owner-financials-table"
                    className='borderless-table'
                    tableLayout='auto'
                    columns={this.getColumns()}
                    dataSource={ownerInfo}
                    rowKey='id'
                    style={{ minHeight: "300px" }}
                    pagination={false}
                />
            </>
        )
    }

    getDebtScheduleColumns = () => {
        return [
            {
                title: 'Creditor Name',
                dataIndex: 'name',
                width: 300
            },
            {
                title: 'Monthly Debt Payment',
                dataIndex: 'outstandingDebt',
                key: 'outstandingDebt',
                render: (item) => '$' + toCurrency(item),
                width: 150,
            },
            {
                title: 'Total Outstanding Balance',
                dataIndex: 'totalOutstandingDebt',
                key: 'totalOutstandingDebt',
                render: (item) => '$' + toCurrency(item),
                width: 150,
            },
            {
                title: 'Collateral Type',
                width: 150,
                dataIndex: 'collateralType'
            },
            {
                title: 'Source',
                width: 150,
                dataIndex: 'source',
                render: (item) => item == "System" ? "Ilien" : "Manual",
            },
            {
                title: 'Note',
                dataIndex: 'note',
                width: 275
            },
            {
                title: 'Attachments',
                width: 400,
                render: (item) => item.documentUrls.map((data) => {
                    return (
                        <TextButton
                            preLine
                            style={{ textAlign: "left" }}
                            icon={<Image src={File} />}
                            onClick={() => this.downloadStatement(data.uri, data.fileName)}
                            text={data.fileName || ''} />
                    )
                }),
            }
        ]
    }

    debtScheduleData = () => {
        const { theme } = this.props;
        const { debtOverview } = this.state;

        const { business } = this.state;
        var businessProfile = (business || {}).businessProfile || {};
        var businessInfo = businessProfile.businessInfo || {};
        var { capitalRequest } = businessInfo || {};

        capitalRequest = capitalRequest ? parseFloat(capitalRequest.replace(/[^\d]/g, '')) : 0;

        return (
            <>
                <Paragraph level={3} bold color={theme.colors.secondary3}>Debt Overview</Paragraph>
                <Flex row style={{ width: "100%", wordBreak: "break-word" }}>
                    <Table
                        id="debtScheduletable"
                        className='borderless-table'
                        tableLayout='auto'
                        columns={this.getDebtScheduleColumns()}
                        dataSource={debtOverview}
                        rowKey='id'
                        style={{ minHeight: "200px" }}
                        pagination={false}
                    />
                </Flex>
            </>
        )
    }

    userData = () => {
        const { theme } = this.props;
        const { business, referralCode } = this.state;
        var businessProfile = (business || {}).businessProfile || {};
        var primaryApplicantInfo = businessProfile.primaryApplicantInfo || {};
        var { firstName, middleName, lastName, ssn, email, phone, address, dob } = primaryApplicantInfo;
        address = address || {};
        var items = [
            {
                name: "Name",
                value: `${firstName} ${middleName || ""} ${lastName}`
            },
            {
                name: "SSN",
                value: ssn ? <Flex gap="10px"> {this.state.viewSSN ? ssn : ssn.replace(/.(?=.{4})/g, 'x')} <TextButton icon={this.state.viewSSN ? <EyeInvisibleOutlined /> : <EyeOutlined />} onClick={() => this.setState({ viewSSN: !this.state.viewSSN })} /> </Flex> : ''
            },
            {
                name: "DOB",
                value: dob ? moment(dob).format('MMM DD, YYYY') : ""
            },
            {
                name: "Email",
                value: email
            },
            {
                name: "Phone",
                value: phone
            },
            {
                name: "Referral Code",
                value: referralCode ? referralCode : ""
            }
            // {
            //     name: "Address",
            //     value: address.line1 ? `${address.line1} ${address.line2 || ""}, ${address.city}, ${address.countrySubDivisionCode} ${address.postalCode}` : ""
            // },
            // {
            //     name: "Ownership Percentage",
            //     value: ownershipPercentage ? ownershipPercentage + "%" : ""
            // }
        ]

        console.log("primaryApplicantInfo", business)

        return (
            <FlexColumn start>
                <Paragraph level={3} bold color={theme.colors.secondary3}>Primary Applicant</Paragraph>
                {
                    items.map(item =>
                        <Paragraph key={item.name}>
                            <Flex start>
                                <span style={{ color: theme.colors.systemGray, width: "100px", display: "inline-block" }}>{item.name}:</span> <Flex>{item.value}</Flex>
                            </Flex>
                        </Paragraph>)
                }
            </FlexColumn>
        )
    }

    businessData = () => {
        const { theme } = this.props;
        var { business, knowYourBusiness } = this.state;
        var businessProfile = (business || {}).businessProfile || {};
        var businessInfo = businessProfile.businessInfo || {};
        if (!businessInfo.name) return null;
        var { physicalAddress, name, ein, incorporationType, incorporationState, incorporationDate, subsidiaries, statesOfOperation, capitalRequest, urls, naics, industry, fiscalYearEnd, closeBooksDate, dba, description } = businessInfo || {};

        capitalRequest = capitalRequest ? capitalRequest.replace(/[^\d]/g, '') : "";
        physicalAddress = physicalAddress || {};
        knowYourBusiness = knowYourBusiness || [];
        var idConfirm = knowYourBusiness.filter(x => x.name == "BizIdScoreThreshold");
        var idConfirmResult = "Review";
        if (idConfirm.length > 0) {
            idConfirmResult = idConfirm[0].value;
        }

        var duplicateEIN = knowYourBusiness.filter(x => x.name == "BusinessDuplicateFEIN");
        var einResult = "Review";
        if (duplicateEIN.length > 0) {
            einResult = duplicateEIN[0].value;
        }

        var items = [
            {
                name: "Name",
                value: name ?
                    <Popover placement='top' content={`ID Confirm: ${idConfirmResult}`}>
                        <Text noWrap>
                            <a style={{ textDecoration: "underline" }} onClick={
                                () => {
                                    this.props.changeTabClick("Verification", "kyb")
                                }
                            }>{name}</a></Text>
                    </Popover> : ''
            },
            {
                name: "DBA",
                value: dba
            },
            {
                name: "EIN",
                value: ein ?
                    <Popover placement='top' content={`Duplicate EIN: ${einResult}`}>
                        <Flex gap="10px"> {this.state.viewEIN ? ein : ein.replace(/.(?=.{4})/g, 'x')} <TextButton icon={this.state.viewEIN ? <EyeInvisibleOutlined /> : <EyeOutlined />} onClick={() => this.setState({ viewEIN: !this.state.viewEIN })} /> </Flex>
                    </Popover> : ''
            },
            {
                name: "NAICS Code",
                value: naics
            },
            {
                name: "Industry",
                value: industry
            },
            // {
            //     name: "Phone",
            //     value: phone
            // },
            {
                name: "Address",
                value: physicalAddress.line1 ? `${physicalAddress.line1} ${physicalAddress.line2 || ""}, ${physicalAddress.city || ""}, ${physicalAddress.countrySubDivisionCode || ""} ${physicalAddress.postalCode}` : ""
            },
            {
                name: "Capital Request",
                value: formatter.format(parseFloat(capitalRequest))
            },
            {
                name: "Website Url",
                value: <a href={urls} target="_blank">{urls}</a>
            },
            {
                name: "Description",
                value: description
            }
        ]
        var items2 = [
            {
                name: "Entity Type",
                value: incorporationType
            },
            {
                name: "Formation State",
                value: incorporationState
            },
            {
                name: "Formation Date",
                value: moment(incorporationDate).format('MMM DD, YYYY')
            },
            {
                name: "States of Operation",
                value: (statesOfOperation || []).join(', ')
            },
            {
                name: "Parent or Subsidiary Names",
                value: subsidiaries
            },
            {
                name: "Most Recent Fiscal Year End",
                value: fiscalYearEnd && moment(fiscalYearEnd, 'YYYY-MM-DD').format('MMM-YYYY')
            },
            {
                name: "Last Close Date",
                value: closeBooksDate && moment(closeBooksDate, 'YYYY-MM-DD').format('MMM DD, YYYY')
            }
        ]
        return (
            <FlexColumn start>
                <Paragraph level={3} bold color={theme.colors.secondary3}>Business Information</Paragraph>
                <div style={{ display: 'flex', flexDirection: 'flex-start' }}>
                    <FlexColumn style={{ marginRight: 48, width: '55%' }}>
                        {
                            items.map(item => <Paragraph key={item.name}>
                                <Flex start>
                                    <FlexColumn start style={{ marginRight: 48, width: '20%' }}>
                                        <span style={{ color: theme.colors.systemGray, width: "100px", display: "inline-block" }}>{item.name}:</span>
                                    </FlexColumn>
                                    <FlexColumn start style={{ marginRight: 48, width: '80%' }}>
                                        {item.name == "Description" &&
                                            <Popover placement='top' content={item.value}>
                                                <div style={{ whiteSpace: 'nowrap', width: '300px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.value}</div>
                                            </Popover>
                                        }
                                        {item.name != "Description" && <Flex start>{item.value}</Flex>}
                                    </FlexColumn>
                                </Flex>
                            </Paragraph>)
                        }
                    </FlexColumn>
                    <FlexColumn style={{ width: '45%' }}>
                        {
                            items2.map(item => {
                                if (item.name === "Parent or Subsidiary Names") {
                                    return (
                                        <Flex column style={{ justifyContent: 'flex-start' }}>
                                            <Paragraph key={item.name}>
                                                <span style={{ color: theme.colors.systemGray, width: item.value ? "180px" : "283px", display: "inline-block" }}>{item.name}:</span>
                                            </Paragraph>
                                            <Paragraph key={item.name}>
                                                {
                                                    item.value && item.value.map((val, i) => {
                                                        return <>{i > 0 ? <>, <br /></> : ''}{val.name} ({val.type})</>
                                                    })
                                                }
                                            </Paragraph>
                                        </Flex>
                                    )
                                } else {
                                    return <Paragraph key={item.name}>
                                        <Flex start>
                                            <FlexColumn start style={{ marginRight: 48, width: '25%' }}>
                                                <span style={{ color: theme.colors.systemGray, width: "100px", display: "inline-block" }}>{item.name}:</span>
                                            </FlexColumn>
                                            <FlexColumn start style={{ marginRight: 48, width: '75%' }}>
                                                <Flex start>{item.value}</Flex>
                                            </FlexColumn>
                                        </Flex>
                                    </Paragraph>
                                }
                            })
                        }
                    </FlexColumn>
                </div >
            </FlexColumn >
        )
    }

    fetchBanks = () => {
        const { loanApp } = this.props;

        const headers = {
        }

        var body = {
            BusinessId: loanApp.businessId
        }

        this.setState({ loadingBanks: true })
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/plaid/getInstitutionInfo`, headers, body, (err, resp) => {
            try {
                const data = resp;
                console.log("fetchBankInfo", data.externalBBInstitutions)
                var bankInfo = data.externalBBInstitutions
                for (let index = 0; index < bankInfo.length; index++) {
                    if (bankInfo[index].status != "Connected") {
                        this.setState({ reconnectBanner: true })
                    }
                }
                this.setState({ banks: bankInfo })
            } catch (error) {
                console.log("ERROR", error.stack)
            } finally {
                this.setState({ loadingBanks: false })
            }
        })
    }

    closeBankDetails = () => {
        this.setState({
            showBankDetails: false, loadingButton: null, fromDate: moment().startOf('month'),
            toDate: moment(), month: moment().startOf('month')
        })
    }

    closeQBDetails = () => {
        this.setState({
            showQBDetails: false, fromDate: moment().startOf('month'),
            toDate: moment()
        })
    }

    handleBankDetails = (bankInfo) => {
        this.setState({ loadingButton: bankInfo.itemId })
        var body = {};
        const headers = {
        }

        var postUrl = `${environment.integrationBaseUrl}/integration/plaid/getAccountDetails`;
        if (bankInfo.integrationProvider == "Plaid") {
            body = {
                "itemId": bankInfo.itemId,
                "redirectUri": window.location.href,
                "connectionStatus": bankInfo.status
            }

            postUrl = `${environment.integrationBaseUrl}/integration/plaid/getAccountDetails`;
        }
        else {
            postUrl = `${environment.integrationBaseUrl}/integration/yodlee/getAccountDetails`;
            const body = {
                "providerAccountId": bankInfo.providerAccountId,
            }
        }

        apiPOSTReq(postUrl, headers, body, (err, resp) => {
            try {
                const response = resp || {};
                if (response.result) {
                    this.setState({
                        accountDetails: response.accountsList, showBankDetails: true,
                        reconnectBank: false, itemId: bankInfo.itemId, loadingButton: null,
                        selectedBank: bankInfo, reportType: 'CSV'
                    })
                    if (bankInfo.status != "Connected") {
                        this.setState({ reconnectBank: true })
                    }
                } else {
                    ErrorAlert({ description: response.responseMessage });
                }
            }
            catch (err) {

            }

        })
    }

    handleDateRange = (dates) => {
        const dateFormat = 'MM/DD/YYYY';
        this.setState({
            fromDate: dates[0].format(dateFormat),
            toDate: dates[1].format(dateFormat)
        });
    }

    handleSubmit = async () => {
        if (this.state.reportType == 'CSV') {
            this.setState({ statementLoading: false })
            const { selectedBank } = this.state;
            const { loanApp } = this.props;
            var header = {
                'AionCurrentBiz': loanApp.businessId,
                'BusinessId': loanApp.businessId
            }

            console.log(selectedBank);
            var request = {}
            var postUrl = '';
            if (selectedBank.integrationProvider == "Plaid") {
                request = {
                    'fromDate': moment(this.state.fromDate).format('MM/DD/YYYY'),
                    'toDate': moment(this.state.toDate).format('MM/DD/YYYY'),
                    'startDate': moment(this.state.fromDate).toDate(),
                    'endDate': moment(this.state.toDate).toDate(),
                    'institutionId': selectedBank.InstitutionId,
                    'BusinessId': loanApp.businessId,
                    'itemId': selectedBank.itemId
                }
                postUrl = `${environment.integrationBaseUrl}/integration/plaid/generateBankStatement`;
            }
            else {
                request = {
                    'fromDate': this.state.fromDate,
                    'toDate': this.state.toDate,
                    'startDate': moment(this.state.fromDate).toDate(),
                    'endDate': moment(this.state.toDate).toDate(),
                    'BusinessId': this.props.businessId,
                    'providerAccountId': selectedBank.providerAccountId
                }
                postUrl = `${environment.integrationBaseUrl}/integration/yodlee/generateBankStatement`;
            }
            apiPOSTReq(`${environment.integrationBaseUrl}/integration/plaid/generateBankStatement`, header, request, (err, resp) => {
                this.setState({ statementLoading: false })
                try {
                    const data = resp;
                    if (data.result) {
                        // this.setState({ statementGenerated: true });
                        this.setState({ statementGenerated: false });
                        this.closeBankDetails();
                    } else {
                        ErrorAlert({ description: (data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later") })
                    }
                } catch (error) {
                    console.log("ERRR getAccounts", error, err, resp);
                }
            });
        }
        else {
            this.generatePDF();
        }
    }

    generatePDF = () => {
        this.setState({ statementLoading: true })
        const { loanApp } = this.props;
        const { selectedBank } = this.state;
        var header = {
            'AionCurrentBiz': loanApp.businessId,
            'BusinessId': loanApp.businessId
        }

        var request = {}
        var postUrl = '';
        if (selectedBank.integrationProvider == "Plaid") {
            request = {
                'StartDate': moment(this.state.month).startOf('month').format('YYYY-MM-DD'),
                'EndDate': moment(this.state.month).endOf('month').format('YYYY-MM-DD'),
                'BusinessId': this.props.businessId,
                'itemId': selectedBank.itemId
            }
            postUrl = `${environment.integrationBaseUrl}/integration/plaid/refreshBankStatements`;
            apiPOSTReq(postUrl, header, request, (err, resp) => {
                this.setState({ statementLoading: false })
                try {
                    const data = resp;
                    if (data.result) {
                        // this.setState({ statementGenerated: true })
                        this.setState({ statementGenerated: false });
                        this.closeBankDetails();
                    } else {
                        ErrorAlert({ description: (data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later") })
                    }
                } catch (error) {
                    console.log("ERRR getAccounts", error, err, resp);
                }
            });
        }
    }

    banksConnected = () => {
        const { theme } = this.props;
        const { loadingBanks, banks, loadingButton } = this.state;
        return (
            <FlexColumn start>
                <Paragraph level={3} bold color={theme.colors.secondary3}>Linked Bank Accounts</Paragraph>
                <Skeleton loading={loadingBanks} active title={false} paragraph={{ rows: 2 }}>
                    <Flex start wrap="wrap" style={{ margin: '0 0 12px' }}>
                        {
                            banks && banks.length > 0 &&
                            banks.map(bank => {
                                return <LightContainer padding='5px 0' margin='0 5px 5px 0' height='56px' noBorder='true'>
                                    <Flex style={{ height: '100%' }} start centerHorizontally>
                                        {
                                            (bank.logoUrl || bank.bankName) &&
                                            <Button type="primary" loading={loadingButton === bank.itemId} icon={<img style={{ marginRight: 8 }} height='24px' src={bank.logoUrl ? `${bank.logoUrl}` : BankIcon} />} text={bank.bankName} rightIcon={bank.status == "Connected" ? <></> : <ExclamationCircleFilled style={{ color: 'orange' }} />} onClick={() => this.handleBankDetails(bank)} />
                                        }
                                    </Flex>
                                </LightContainer>
                            })
                        }
                        {
                            banks && banks.length == 0 && "No Bank connections found."
                        }
                    </Flex>
                </Skeleton>
            </FlexColumn>
        )
    }

    fetchCodatConnections = () => {
        const { loanApp } = this.props;
        var body = {
            "BusinessId": loanApp.businessId
        };
        this.setState({ loadingConnections: true });
        console.log("/integration/getConnections body", body, loanApp);
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/codat/getConnections`, { AionCurrentBiz: loanApp.businessId }, body, (err, resp) => {
            this.setState({ loadingConnections: false });
            try {
                const data = resp || {};
                if (data.result) {
                    this.setState({ connections: data.connections })
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    accountingSystemsConnected = () => {
        const { theme } = this.props;
        const { connections, loadingConnections } = this.state;
        return (
            <FlexColumn start>
                <Paragraph level={3} bold color={theme.colors.secondary3}>Accounting Systems</Paragraph>
                <Skeleton loading={loadingConnections} active title={false} paragraph={{ rows: 2 }}>
                    <Flex start wrap="wrap" style={{ margin: '0 0 12px' }}>
                        {
                            connections && connections.length > 0 &&
                            connections.map(connection => {
                                return <LightContainer padding='5px 0' margin='0 5px 5px 0' height='56px' noBorder='true' >
                                    <Flex style={{ height: '100%' }} start centerHorizontally>
                                        {
                                            (connection.logoUrl || connection.platformName) && //<img style={{ marginRight: 8 }} height='50px' src={connection.logoUrl} />
                                            <Button type="primary" icon={<img style={{ marginRight: 8 }} height='50px' src={connection.logoUrl ? `${connection.logoUrl}` : BankIcon} />} rightIcon={connection.status == "Linked" ? <></> : <ExclamationCircleFilled style={{ color: 'orange' }} />} onClick={() => this.handleQBDetails(connection)} />
                                        }
                                    </Flex>
                                </LightContainer>
                            })
                        }
                        {
                            connections && connections.length == 0 && "No Accounting Software Connections Found."
                        }
                    </Flex>
                </Skeleton>
            </FlexColumn>
        )
    }

    handleQBDetails = (connection) => {
        this.setState({ showQBDetails: true, selectedConnection: connection })
    }

    fetchKnowYourBusiness = () => {
        const { loanApp } = this.props
        var body = {
            "clientBusinessId": loanApp.businessId,
            "applicationId": loanApp.applicationId,
            "rootType": 'KnowYourBusiness'
        }

        console.log("/ops/loans/application/getVerificationResults body", body)
        this.setState({ prescreenResultsLoaded: false })
        apiPOSTReq(`${environment.opsBaseUrl}/ops/loans/application/getVerificationResults`, null, body, (err, resp) => {
            this.setState({ prescreenResultsLoaded: true })
            try {
                const data = resp || {}
                if (data.result) {
                    var businessValidations = data.businessValidations
                    this.setState({
                        knowYourBusiness: businessValidations
                    })
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    handleReportTypeSelection = (val) => {
        this.setState({
            reportType: val,
        })
    }

    generateCodatReport = () => {
        const { loanApp } = this.props.location.state;
        var body = {
            "BusinessId": this.state.selectedConnection.businessId,
            'LoanApplicationId': loanApp.applicationId,
            "companyId": this.state.selectedConnection.companyId,
            'fromDate': moment(this.state.fromDate).format('yyyy-MM-DD'),
            'toDate': moment(this.state.toDate).format('yyyy-MM-DD')
        };

        var { reportType } = this.state;
        var url = `${environment.integrationBaseUrl}/integration/codat/reports/generateProfitAndLossReport`
        if (reportType == "Profit and Loss") {
            url = `${environment.integrationBaseUrl}/integration/codat/reports/generateProfitAndLossReport`;
        }
        else if (reportType == "AR Aging Report") {
            url = `${environment.integrationBaseUrl}/integration/codat/reports/generateARAging`;
        }
        else {
            url = `${environment.integrationBaseUrl}/integration/codat/reports/generateBalanceSheetReport`
        }

        this.setState({ loadingConnections: true });
        console.log("/integration/getConnections body", body, loanApp);
        apiPOSTReq(url, { AionCurrentBiz: loanApp.businessId }, body, (err, resp) => {
            this.setState({ loadingConnections: false });
            try {
                const data = resp || {};
                if (data.result) {
                    this.setState({ showQBDetails: false })
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    render() {
        const { theme } = this.props;
        var { loading, showBankDetails, selectedBank, reconnectBank, accountDetails } = this.state;
        const { fromDate, toDate, statementGenerated, statementLoading, showQBDetails, selectedConnection, month } = this.state;
        const AccountDetailColumns = [
            {
                title: 'Title',
                dataIndex: 'title',
                align: 'left'
            },
            {
                title: 'Account Ending',
                dataIndex: 'mask',
                align: 'left'
            },
            {
                title: 'Available Balance',
                dataIndex: 'availableBalance',
                align: 'left',
                render: (availableBalance, record) => {
                    return (formatter.format(availableBalance ? availableBalance : record.currentBalance))
                },
            }
        ]

        // const dataOptions = ["Profit and Loss", "Balance Sheet", "AR Aging Report"];
        const dataOptions = ["Profit and Loss", "Balance Sheet", "AR Aging Report"];
        const dropdownOptions = dataOptions.map(item => (
            <Option key={item} value={item}>
                {item}
            </Option>
        ));

        const stmtDataOptions = ["CSV", "PDF"];
        const stmtDropdownOptions = stmtDataOptions.map(item => (
            <Option key={item} value={item}>
                {item}
            </Option>
        ));

        return (
            <FlexColumn style={{ marginTop: "20px" }}>
                <Flex style={{ justifyContent: "flex-start", marginBottom: "40px" }}>
                    <CardContainer fullWidth margin="0px 20px 0px 0px" minHeight='450px'>
                        <Skeleton loading={loading} active title={false} paragraph={{ rows: 9 }}>
                            {this.businessData()}
                        </Skeleton>
                    </CardContainer>
                    {/* <div style={{ width: "100px" }}></div> */}
                    <CardContainer minHeight='450px' width='650px'>
                        <Skeleton loading={loading} active title={false} paragraph={{ rows: 9 }}>
                            {this.userData()}
                        </Skeleton>
                    </CardContainer>
                </Flex>
                <Flex style={{ justifyContent: "flex-start", marginBottom: "20px" }}>
                    <CardContainer fullWidth style={{ marginBottom: 20 }}>
                        <Skeleton loading={loading} active title={false} paragraph={{ rows: 9 }}>
                            {this.ownershipData()}
                            {this.debtScheduleData()}
                        </Skeleton>
                    </CardContainer>
                </Flex>
                <Flex style={{ justifyContent: "flex-start", marginBottom: "50px" }}>
                    <CardContainer fullWidth margin="0px 20px 0px 0px">
                        <Skeleton loading={loading} active title={false} paragraph={{ rows: 9 }}>
                            {this.banksConnected()}
                        </Skeleton>
                    </CardContainer>
                    <CardContainer fullWidth>
                        <Skeleton loading={loading} active title={false} paragraph={{ rows: 9 }}>
                            {this.accountingSystemsConnected()}
                        </Skeleton>
                    </CardContainer>
                </Flex>
                <Modal
                    visible={showQBDetails}
                    footer={null}
                    onCancel={() => this.closeQBDetails()}
                    width={600}
                >
                    <Text heading caption margin='12px 12px 0 12px' >Accounting System Details</Text>
                    <Flex style={{ height: '100%' }} between centerHorizontally>
                        <Flex start gap='12px' centerHorizontally>
                            <img height='50px' src={selectedConnection && selectedConnection.logoUrl} />
                            {
                                selectedConnection && <Tag size='14px' height='35px' color='#318F2F' background='#F5F9F5' alert={selectedConnection.status !== 'Linked'}>{selectedConnection.status === 'Linked' ? 'Connected' : 'Disconnected'}</Tag>
                            }
                        </Flex>
                    </Flex>
                    <Flex start wrap="wrap" style={{ margin: '12px 12px 0px 12px' }}>
                        <Text heading caption margin='12px 12px 12px 0'>Generate Reports</Text>
                    </Flex>
                    <Flex start wrap="wrap" style={{ margin: '12px 12px 0px 12px' }}>
                        <FlexColumn left gap='24px' margin='12px 12px 12px 0'>
                            <LabeledInput
                                label="Report Type"
                                labelcolor={theme.colors.secondary3}
                                id="statementType"
                                key="statementType"
                                type="select"
                                placeholder="Select"
                                className="no-left-padding"
                                value={this.state.reportType}
                                onChange={this.handleReportTypeSelection}
                            // style={{ width: "150px", fontSize: "0.95rem", fontWeight: 500 }}
                            >
                                {dropdownOptions}
                            </LabeledInput>
                            <LabeledInput
                                label="Date Range"
                                labelcolor={theme.colors.secondary3}
                                id="dateRange"
                                key="dateRange"
                                type="range-picker"
                                value={[moment(fromDate), moment(toDate)]}
                                disabledDate={(current) => current > moment()}
                                format="MM/DD/YYYY"
                                onChange={this.handleDateRange}
                            />
                        </FlexColumn>
                        <Text background="#F5F6FE" margin='12px 12px 12px 0' borderColor="2px solid var(--solid-primary-blue-20, #D7DCFB);">Once submitted please check the Documents Tab for the statement. The generation of statement might take some time depending upon the period selected.</Text>
                        <Button style={{ alignSelf: 'left', margin: '20px 0' }} margin='12px 12px 12px 0' solid loading={statementLoading} onClick={this.generateCodatReport} text="Generate Report" />
                    </Flex>
                </Modal>
                <Modal
                    visible={showBankDetails}
                    footer={null}
                    onCancel={() => this.closeBankDetails()}
                    width={600}
                >
                    <div style={{ textAlign: 'left' }}>
                        <Text heading caption margin='12px 12px 0 12px' >Bank Details</Text>
                        {
                            <FlexColumn style={{ margin: '24px 0' }}>
                                <div style={{ textAlign: 'left', margin: '0 12px 12px 12px' }}>
                                    <Text>
                                        <img style={{ marginRight: 8 }} height='35px' src={(selectedBank && selectedBank.logoUrl) ? selectedBank.logoUrl : BankIcon} />
                                        {selectedBank && selectedBank.bankName}
                                    </Text>
                                </div>
                                <Flex start wrap="wrap" style={{ margin: '12px 12px 0px 12px' }}>
                                    <Info label='Status' value={reconnectBank ? <Badge status="error" text="DISCONNECTED" /> : <Badge status="success" text="CONNECTED" />} />
                                </Flex>
                                <Flex start wrap="wrap" style={{ margin: '12px 12px 0px 12px' }}>
                                    <Text heading caption margin='12px 12px 12px 0'>Account Details</Text>
                                    {
                                        accountDetails && accountDetails.length > 0 &&
                                        <Table
                                            id="AccountDetailsTable"
                                            tableLayout='fixed'
                                            columns={AccountDetailColumns}
                                            dataSource={accountDetails}
                                            pagination={false}
                                            style={{ margin: '12px 0 12px 0' }}
                                        />
                                    }
                                </Flex>
                            </FlexColumn>
                        }
                        {
                            !reconnectBank && <FlexColumn style={{ margin: '24px 0' }}>
                                <Flex start wrap="wrap" style={{ margin: '12px 12px 0px 12px' }}>
                                    <Text heading caption margin='12px 12px 12px 0'>Generate Bank Statement</Text>
                                </Flex>
                                <Flex start wrap="wrap" style={{ margin: '12px 12px 0px 12px' }}>
                                    <LabeledInput
                                        label="Report Type"
                                        labelcolor={theme.colors.secondary3}
                                        id="statementType"
                                        key="statementType"
                                        type="select"
                                        placeholder="Select"
                                        className="no-left-padding"
                                        value={this.state.reportType}
                                        onChange={this.handleReportTypeSelection}
                                    // style={{ width: "150px", fontSize: "0.95rem", fontWeight: 500 }}
                                    >
                                        {stmtDropdownOptions}
                                    </LabeledInput>
                                    <FlexColumn left gap='24px' margin='12px 12px 12px 0'>
                                        {
                                            this.state.reportType == "CSV" && <LabeledInput
                                                label="Date Range"
                                                labelcolor={theme.colors.secondary3}
                                                id="dateRange"
                                                key="dateRange"
                                                type="range-picker"
                                                value={[moment(fromDate), moment(toDate)]}
                                                disabledDate={(current) => current > moment()}
                                                format="MM/DD/YYYY"
                                                onChange={this.handleDateRange}
                                            />
                                        }
                                        {
                                            this.state.reportType == "PDF" && <LabeledInput
                                                label="Month"
                                                type="date-picker"
                                                picker="month"
                                                placeholder='MMM'
                                                value={month}
                                                format={'MMM-YYYY'}
                                                disabledDate={(current) => current > moment()}
                                                onChange={(date, dateStr) => this.setState({ month: date })}
                                            />
                                        }
                                    </FlexColumn>
                                    <Text background="#F5F6FE" margin='12px 12px 12px 0' borderColor="2px solid var(--solid-primary-blue-20, #D7DCFB);">Once submitted please check the Documents Tab for the statement. The generation of statement might take some time depending upon the period selected.</Text>
                                    <Button style={{ alignSelf: 'left', margin: '20px 0' }} margin='12px 12px 12px 0' solid loading={statementLoading} onClick={this.handleSubmit} text="Generate" />
                                    {/* <Button style={{ alignSelf: 'left', margin: '20px 10px' }} margin='12px 12px 12px 0' loading={statementLoading} onClick={this.generatePDF} text="Generate PDF" /> */}
                                </Flex>
                                {/* <Modal
                                    visible={statementGenerated}
                                    footer={null}
                                    onCancel={() => {
                                        this.setState({ statementGenerated: false });
                                        this.closeBankDetails();
                                    }}
                                >
                                    <SuccessModal
                                        title='Statement Generated Successfully'
                                        buttonTitle='OKAY'
                                        onConfirm={() => {
                                            this.props.closeModal()
                                            this.setState({ statementGenerated: false })
                                        }}
                                    >
                                    </SuccessModal>
                                </Modal> */}
                            </FlexColumn>
                        }
                    </div>
                </Modal>
            </FlexColumn>
        );
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)));