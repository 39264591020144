import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from "react-router-dom";
import { connect } from 'react-redux'
import moment from 'moment'
import { saveAs } from 'file-saver'
import { withTheme } from 'styled-components'

import { Modal, Select, Table, message, Skeleton } from 'antd'
import { PlaidLink } from "react-plaid-link"
import { Flex, FlexColumn, Container, CardContainer, LightContainer } from '../../Reusable/Container'
import PageHeader from '../../Reusable/PageHeader'
// import AccountsCard from './AccountsCard'
// import RecentTxnCard from './RecentTxnCard'
import Banner from '../../Reusable/Banner'
import { Text, Tag } from '../../Reusable/Text'
import { Image } from '../../Reusable/Image'
import { ErrorAlert } from '../../Reusable/Alert'
import AlertModal from '../../Reusable/AlertModal'
import ConnectionModal from '../../Reusable/ConnectionModal'
import SettingsModal from '../../Pay/Bills/SettingsModal'
import AionLogo from '../../Reusable/Image'
import Chart from '../../Reusable/Chart'
import API from '../API Keys/Index';
import { addDataToStore, BNK_SAVE_DATA, SAVE_DATA, UAM_SAVE_BUSINESS, CODAT_DATA } from '../../../Actions/actions'
import { getBPAccountsWithAccess, getResourcePerm, getInitials, addressObjectToStr, getUserRoles, getUserApps, showCodatConnectionBanner, getFeaturePerm } from '../../../Utils/util'
import { TextButton, Button, ImageButton } from '../../Reusable/Button'
import { apiPOST, apiGET, apiPOSTReq } from '../../../Utils/api'
import { fetchCounterparties } from '../../../Utils/bankingDataManager'
// import DebitCardList from './DebitCardList'
// import AccountList from './AccountList'
import { Divider, LabeledInput } from '../../Reusable/Input'
import Pipe from '../../../Images/pipe.png'
import Link from '../../../Images/link.svg'
import { SettingOutlined } from '@ant-design/icons'
import environment from '../../../environment'

import qbLogo from '../../../Images/quickbooks-icon-sm.jpeg'
import GSyncIcon from '../../../Images/integration/sync-green.png';
import BSyncIcon from '../../../Images/integration/sync-blue.png';
import SettingsIcon from '../../../Images/integration/setting.png';
import BusinessLogo from '../../Reusable/BusinessLogo'
import pipe from '../../../Images/pipe.png';
import qbImage from '../../../Images/quickbooks-brand-preferred-logo.png'
import ModalClose from '../../../Images/modal-close.png'
import ExclamationIcon from '../../../Images/exclamation-icon.png'
import Info from '../../Reusable/Info'
import BankConnect from '../External Bank Connect/Index'
import ASConnection from '../../Reusable/ASConnection'
import RightArrow from '../../../Images/right-arrow.svg'


const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
})

const CompanyProfile = (props) => {
    const [loadingAccountVerificationPDF, setLoadingAccountVerificationPDF] = useState(false)
    const [loadingRecipients, setLoadingRecipients] = useState(true)
    const [loadingBanks, setLoadingBanks] = useState(true)
    const [banks, setBanks] = useState([])
    const [dropdownVisible, setDropdownVisible] = useState(false)
    const [totalRecipients, setTotalRecipients] = useState(0)
    const [multiAccount, setMultiAccount] = useState(false)
    const [showActivity, setShowActivity] = useState(false)
    const [selectedAccount, setSelectedAccount] = useState(0)
    const [showAccountNameModal, setShowAccountNameModal] = useState(false)
    const [relinkToken, setRelinkToken] = useState('')
    const [linkToken, setLinkToken] = useState('')
    const [showAPIKeys, setShowAPIKeys] = useState(false)
    const [connectedPlatform, setConnectedPlatform] = useState(null);
    const [isSyncInprogress, setSyncInprogress] = useState(false);
    const accounts = getBPAccountsWithAccess("Deposits") || []
    const [showIntegration, setShowIntegration] = useState(false);
    const [showExternalBankConnections, setShowExternalBankConnections] = useState(false);
    const [showReconnect, setShowReconnect] = useState(false);
    const [showBankDetails, setShowBankDetails] = useState(false)
    const [accountDetails, setAccountDetails] = useState([])
    const [reconnectBanner, setReconnectBanner] = useState(false)
    const [showConnectAS, setShowConnectAS] = useState(false)
    const [showSettingsModal, setShowSettingsModal] = useState(false)
    const [showSyncSetupComplete, setShowSyncSetupComplete] = useState(false)
    const [qbo, setQbo] = useState(false)

    const history = useHistory()
    const location = useLocation()

    const { store, loading, theme, totalScheduled } = props
    var { Profile, UserInfo, businessSubscriptionPlan, business, Attributes, CurrentSync, connection, referralsData } = store
    var { referralCode } = referralsData || {}
    var { businessProfile } = business || {}
    businessProfile = businessProfile || {}
    var businessInfo = businessProfile.businessInfo || {}
    var { subscriptionPlanName } = store
    businessSubscriptionPlan = businessSubscriptionPlan || {}
    const connectedQBO = connectedPlatform ? connectedPlatform.platformName === 'QUICKBOOKS' : false;
    const qbSetupCompleted = connectedQBO && connectedPlatform.syncSetupCompleted;
    const userRole = getUserRoles(props.store)
    const allowedApps = getUserApps(props.store)
    const isCompanyAdmin = userRole.includes("Company Admin") || userRole.includes("Owner") || userRole.includes("Primary Admin")
    const apiPerm = getResourcePerm("AionAPI.API")
    const currentPlan = (businessSubscriptionPlan || {}).currentPlan || ''
    var { Businesses } = Attributes || {};
    var businessAttributes = Businesses || {};

    const isFeatureEnabled = getFeaturePerm("AionApplications.Onboarding.Codat").view
    const showConnections = isFeatureEnabled && connection && connection.status !== "PendingAuth"
    const showConnectionCard = showCodatConnectionBanner(store)

    useEffect(() => {
        getConnections()

        const urlStr = location.search.replace('?', '')
        const queryParams = new URLSearchParams(urlStr)
        const statusCode = queryParams.get('statuscode')
        const errorMessage = queryParams.get('errormessage')
        const statusText = queryParams.get('statustext')
        // http://{website}/{path}/?statuscode={statusCode}&errormessage={errorMessage}&statusText={statusText}
        // Check if redirected from codat
        if (statusCode) {
            if (statusCode == 200) {
                getConnections(true)
            } else {
                ErrorAlert({ description: errorMessage || statusText || "Sorry we had trouble processing your request. Please try connecting again" })
            }
        } else {
            console.log("BillPayments Inbox NO statusCode")
        }
    }, [])

    useEffect(() => {
        // Api App users only
        if (allowedApps.includes("AionAPI")) setShowAPIKeys(true)

        if (allowedApps.includes("ARCredit") || allowedApps.includes("TermLoans") || allowedApps.includes("ABLCredit")) setShowExternalBankConnections(true)

        if (allowedApps.filter(x => x === "Receivables" || x === "Bookkeeping" || x === "Payables").length > 0) setShowIntegration(true)

        if (businessAttributes.AccountingSWLinked || (CurrentSync && CurrentSync.platformName === 'QUICKBOOKS' && CurrentSync.syncSetupCompleted)) {
            setQbo(true)
        }

        if (!props.aionStore.hasIntegration) {
            setShowIntegration(false)
        } else {
            fetchConnectedSW()
        }
        fetchCounterparties({ pagination: { pageSize: 1000, current: 1 } }, (err, resp) => {
            if (resp && resp.counterpartiesCount) {
                setTotalRecipients(resp.counterpartiesCount)
            }
            props.dispatch(addDataToStore(BNK_SAVE_DATA, resp || {}))
            setLoadingRecipients(false)
        })

        // fetchBankInfo()
        fetchBanks()
        fetchBusinessData()
        poll()
        // setInterval(() => poll(), 10000);
    }, [props.aionStore.syncStatus])

    const poll = () => {
        if (props.aionStore.hasIntegration) {
            const { syncJobId } = props.aionStore;
            const { syncStatus } = props.aionStore;

            if (syncStatus === "InProgress") {
                setSyncInprogress(true);
            } else if (syncStatus === "Completed") {
                setSyncInprogress(false);
            }
            return;
        }
    }

    const fetchBankInfo = () => {
        const headers = {
            businesskey: props.aionStore.BusinessUniqueKey
        }
        setLoadingBanks(true)
        apiGET("/bankinfo", headers, (err, resp) => {
            try {
                const data = resp.data || {}
                console.log("fetchBankInfo", data)
                console.log("fetchBankInfo accounts", accounts)
                setBanks(data.BankInfo)
            } catch (error) {
                console.log("ERROR", error.stack)
            } finally {
                setLoadingBanks(false)
            }
        })
    }

    const fetchBanks = () => {
        const headers = {
            businesskey: props.aionStore.BusinessUniqueKey,
        }

        const body = {
            "BusinessId": props.aionStore.BusinessUniqueKey
        }
        setLoadingBanks(true)
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/plaid/getInstitutionInfo`, headers, body, (err, resp) => {
            try {
                const data = resp;
                var bankInfo = data.externalBBInstitutions

                for (let index = 0; index < bankInfo.length; index++) {
                    if (bankInfo[index].status != "Connected") {
                        setReconnectBanner(true)
                        break;
                    }
                }
                setBanks(data.externalBBInstitutions)
            } catch (error) {
                console.log("ERROR", error.stack)
            } finally {
                setLoadingBanks(false)
            }
        })
    }

    const fetchConnectedSW = () => {
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/getConnectedSW`, {}, {}, (err, resp) => {
            try {
                const data = resp;
                console.log("/integration/getConnectedSW", data)
                if (data.result) {
                    if (data.integration) {
                        setConnectedPlatform(data.integration)
                    } else {
                        setConnectedPlatform(null);
                        fetchIntegrationHistory();
                    }
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR fetchConnectedSW", error.stack, resp);
                ErrorAlert({ description: error.message })
            }
        })
    }

    const fetchIntegrationHistory = () => {
        const body = {
            page: 0,
            size: 1000,
        }
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/getIntegrationHistory`, {}, body, (err, resp) => {
            try {
                const data = resp;
                console.log("/integration/getIntegrationHistory", data)
                if (data.result) {
                    if (data.integrationList && data.integrationList.length > 0) {
                        var prev = data.integrationList[0];
                        setShowReconnect(prev.syncSetupCompleted);
                    }
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getIntegrationHistory", error.stack, resp);
                ErrorAlert({ description: error.message })
            }
        })
    }

    const syncNow = () => {
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/qb/syncQuickBooks`, {}, {}, (err, resp) => {
            try {
                const data = resp;
                console.log("/syncQuickBooks", data);
                if (data.result) {
                    message.success(data.responseMessage);
                    if (data.jobId) {
                        props.dispatch(addDataToStore(SAVE_DATA, { syncJobId: data.jobId }));
                    }
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later");
                }
            } catch (error) {
                console.log("ERRR syncQuickBooks", error.stack, resp);
                ErrorAlert({ description: error.message });
            }
        })
    }

    const plaidOnSuccess = (public_token, metadata) => {
        const headers = {
            businesskey: props.store.BusinessUniqueKey
        }
        var body = {
            data: {
                publicToken: public_token,
                accountId: metadata.account.id,
                accountName: metadata.account.name,
                institution: metadata.institution.name,
                institutionId: metadata.institution.institution_id,
            }
        }

        setLoadingBanks(true)
        apiPOST("/plaidauthenticate", headers, body, (err, resp) => {
            try {
                if (err) throw Error(err);
                const data = resp.data;
                if (data.success) {
                    fetchBankInfo()
                } else {
                    setLoadingBanks(false)
                    ErrorAlert({ description: data.msg });
                }
            } catch (error) {
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    const resolveBankError = () => {
        apiPOST("/resolvebankerror", {}, {}, (err, resp) => {
            console.log("Plaid event logged", err, resp)
            try {
                apiPOSTReq(`${environment.integrationBaseUrl}/integration/plaid/getExternalBankConnection`, {}, {}, (err, resp) => {
                    try {
                        const data = resp || {}
                        if (data.result) {
                            console.log("/getExternalBankConnection", data)
                            props.dispatch(addDataToStore(SAVE_DATA, { bankNameList: data.bankNameList }))
                        }
                    } catch (error) {
                        console.log("/getExternalBankConnection err", error, resp)
                        ErrorAlert({ description: error.message })
                    }
                })

                const data = resp.data
                if (data.success) {
                    fetchBankInfo()
                } else {
                    throw Error(data.msg)
                }
            } catch (error) {
                console.log("ERROR", error.stack)
                ErrorAlert({ description: "Sorry, we had trouble processing your request. Please try again." })
            } finally {
                setLoadingBanks(false)
            }
        })
    }

    const logPlaidEvent = (eventName, metadata) => {
        apiPOST("/logError", {}, { logType: "PLAID_DESKTOP", eventName: eventName, metadata: metadata }, (err, resp) => {
            console.log("Plaid event logged", err, resp)
        })
    }


    const getPlaidAccessToken = (public_token, metadata, includeAuth) => {
        var header = {}
        var body = {
            "redirectUri": window.location.href,
            "plaidTokenData": {
                publicToken: public_token,
                accountId: metadata.account.id,
                accountName: metadata.account.name,
                institution: metadata.institution.name,
                institutionId: metadata.institution.institution_id,
            },
            "loggedInUserId": UserInfo.Email,
            "BusinessId": store.businessId,
            "includeAuth": includeAuth

        }

        console.log("Body", body)
        setLoadingBanks(true)
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/plaid/getAccessToken`, header, body, (err, resp) => {
            try {
                const response = resp || {};
                if (response.result) {
                    fetchBanks()
                } else {
                    ErrorAlert({ description: response.responseMessage });
                    setLoadingBanks(false)
                }
            }
            catch (err) {

            }

        })

    }

    const fetchBusinessData = (options) => {
        options = options || {};
        apiPOSTReq(`${environment.uamBaseUrl}/getBusinessOwnership`, {}, {}, (err, resp) => {
            try {
                if (err) throw Error(err);
                const data = resp || {};
                if (data.result) {
                    // var business = data.business || {};
                    console.log("fetchBusinessData", data)
                    props.dispatch(addDataToStore(UAM_SAVE_BUSINESS, data));
                } else {
                    throw Error(data.responseMessage || data.error);
                }
            } catch (error) {
                console.log("/getBusiness err", error, resp);
            }
        });
    }

    const revokeToken = () => {
        const body = {
            businessId: props.aionStore.BusinessUniqueKey
        }
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/qb/revokeToken`, {}, body, (err, resp) => {
            try {
                if (err) throw Error(err);
                const data = resp || {};
                if (data.result) {
                    fetchConnectedSW();
                    fetchBusinessData();
                } else {
                    throw Error(data.responseMessage || data.error);
                }
            } catch (error) {
                console.log("/revokeToken err", error, resp);
            }
        });
    }

    const getConnections = (showSuccess) => {
        const { store } = props
        var { reconnectingCodat } = store

        apiPOSTReq(`${environment.integrationBaseUrl}/integration/codat/getConnections`, {}, {}, (err, resp) => {
            try {
                const data = resp || {};
                if (data.result) {
                    var connections = data.connections || []
                    console.log("BillPayments Inbox /integration/codat/getConnections data", data)
                    props.dispatch(addDataToStore(CODAT_DATA, { connections: connections, connection: connections.length > 0 && connections[0], company: data.company }))
                    if (showSuccess) {
                        if (connections.length > 0) updateConnection(connections[0])
                        if (!reconnectingCodat) setShowSettingsModal(true)
                    }
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("BillPayments Inbox getConnections error", { description: error.message })
            }
        })
    }

    const updateConnection = (connection) => {
        console.log("AccountingSysSelect updateConnection", connection)
        var body = {
            companyId: connection.companyId,
            connectionId: connection.connectionId
        }
        console.log("AccountingSysSelect updateConnection body", body)
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/codat/updateConnection`, {}, body, (err, resp) => {
            try {
                const data = resp || {};
                if (data.result) {
                    console.log("AccountingSysSelect updateConnection data", data)
                    history.replace('/settings/profile')
                    props.dispatch(addDataToStore(CODAT_DATA, { connection: data.connection, reconnectingCodat: false }))
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                ErrorAlert({ description: error.message })
            }
        })
    }

    const referralInviteAccess = getFeaturePerm("AionApplications.Users.ReferralInvite").view

    return (
        <FlexColumn className='main-top-padding main-left-padding'>
            <PageHeader titleText='Business Profile' />
            <Flex start style={{ margin: '18px 0 24px' }} gap="24px">
                <BusinessLogo />
                <FlexColumn start>
                    <Text weight='500' size='28px' height='40px'>{businessInfo.name}</Text>
                    <Flex start centerHorizontally>
                        <Text style={{ marginTop: 0 }}>{addressObjectToStr(businessInfo.mailingAddress)}</Text>
                        {
                            (businessInfo.urls && businessInfo.urls.length > 0) &&
                            <>
                                <Image margin='0 24px' width='1.5px' height='20px' src={Pipe} />
                                <Image margin='0 8px 0 0' src={Link} />
                                <a href={businessInfo.urls[0]} target='_blank' style={{ textDecoration: 'underline' }}>{businessInfo.urls[0]}</a>
                            </>
                        }
                    </Flex>
                </FlexColumn>
            </Flex>

            <Flex style={{ alignItems: "start", width: '100%' }} center>
                <FlexColumn between style={{ width: '100%' }}>
                    {
                        !isCompanyAdmin &&
                        <Banner
                            icon={<Image src={ExclamationIcon} />}
                            style={{ marginTop: '24px', marginBottom: 24, marginRight: 24 }}
                            color='#000000'
                            background='#F2F2F2'
                            borderColor='#CCCCCC'
                            message='You do not have permissions to view any additional information about your business on this screen. Please contact your Company Admin to perform any administrative tasks such as inviting new users, managing account settings and more.'
                        />
                    }
                    {
                        reconnectBanner && showExternalBankConnections &&
                        <Banner style={{ marginTop: '24px', marginBottom: 24, marginRight: 24 }} message='Please view the "Your Connections" section located on this page to reconnect previously linked bank accounts.' />
                    }

                    {
                        isCompanyAdmin &&
                        <Flex className='no-scrollbar' start style={{ padding: '10px 24px 24px 0', overflowX: 'scroll' }} gap='24px' grow>
                            <div style={{ width: '25%', minWidth: 296 }}>
                                <CardContainer
                                    height={'216px'}
                                    width={'100%'}>
                                    <FlexColumn between style={{ width: '100%', height: '100%', paddingBottom: 8 }}>
                                        <FlexColumn start style={{ width: '100%', height: '100%', paddingBottom: 8 }}>
                                            <Text heading>Users & Roles</Text>
                                            <Text>Invite new users and manage existing users of your organization and their roles.</Text>
                                        </FlexColumn>
                                        <TextButton onClick={() => props.history.push('/settings/user-access')} rightIcon={<Image src={RightArrow} />} text="MANAGE" />
                                    </FlexColumn>
                                </CardContainer>
                            </div>

                            <div style={{ width: '25%', minWidth: 296 }}>
                                <CardContainer
                                    height={'216px'}
                                    width={'100%'}>
                                    <FlexColumn between style={{ width: '100%', height: '100%', paddingBottom: 8 }}>
                                        <FlexColumn start style={{ width: '100%', height: '100%', paddingBottom: 8 }}>
                                            <Text heading>Ownership</Text>
                                            <Text>View your organization ownership.</Text>
                                        </FlexColumn>
                                        <TextButton onClick={() => props.history.push('/settings/profile/company-ownership')} rightIcon={<Image src={RightArrow} />} text="MANAGE" />
                                    </FlexColumn>
                                </CardContainer>
                            </div>

                            <div style={{ width: '25%', minWidth: 296 }}>
                                <CardContainer
                                    height={'216px'}
                                    width={'100%'}>
                                    <FlexColumn between style={{ width: '100%', height: '100%', paddingBottom: 8 }}>
                                        <FlexColumn start style={{ width: '100%', height: '100%', paddingBottom: 8 }}>
                                            <Text heading>Company Cards</Text>
                                            <Text>Issue, view and manage cards.</Text>
                                        </FlexColumn>
                                        <TextButton
                                            onClick={() => props.history.push({
                                                pathname: '/settings/manage-cards',
                                                state: { enableBack: true }
                                            })}
                                            rightIcon={<Image src={RightArrow} />} text="MANAGE" />
                                    </FlexColumn>
                                </CardContainer>
                            </div>

                            <div style={{ width: '25%', minWidth: 296 }}>
                                <FlexColumn start gap='24px' grow height={'216px'}>
                                    <CardContainer width={'100%'} grow>
                                        <Flex>
                                            <FlexColumn start style={{ width: '100%', paddingBottom: 0 }}>
                                                <Text heading>Your Plan</Text>
                                                <FlexColumn between style={{ width: '100%', height: '100%' }}>
                                                    <Text>Started: {moment(businessSubscriptionPlan.subscriptionStartDate).format("MMM D, YYYY")}</Text>
                                                    <Text>Billing: {businessSubscriptionPlan.currentBillingType}</Text>
                                                </FlexColumn>
                                            </FlexColumn>
                                            <Tag height='32px' bordered primary>{currentPlan}</Tag>
                                        </Flex>
                                    </CardContainer>

                                    {
                                        referralInviteAccess && referralCode?.id &&
                                        <CardContainer clickable padding='16px 24px' onClick={() => props.history.push({
                                            pathname: '/settings/referrals',
                                            state: {  }
                                        })}>
                                            <Flex between>
                                                <Text heading>Referrals</Text>
                                                <ImageButton src={RightArrow} />
                                            </Flex>
                                        </CardContainer>
                                    }
                                </FlexColumn>
                            </div>
                        </Flex>
                    }

                    {
                        ((showIntegration || userRole.includes("Banking Admin") || isCompanyAdmin) || showAPIKeys) && !currentPlan.toLowerCase().includes('launch') &&
                        <Container shadow padding='24px' style={{ marginRight: 24, marginBottom: 24 }}>
                            <Flex between centerHorizontally>
                                <FlexColumn start>
                                    {
                                        (showIntegration || userRole.includes("Banking Admin") || isCompanyAdmin) &&
                                        <>
                                            <Text heading margin='0 0 4px'>Chart of Accounts</Text>
                                            <Text>Manage your general ledger accounts</Text>
                                        </>
                                    }
                                </FlexColumn>

                                <TextButton onClick={() => props.history.push('/settings/coa')} rightIcon={<Image src={RightArrow} />} text="MANAGE" />

                            </Flex>
                        </Container>
                    }

                    {
                        showConnectionCard &&
                        <Container shadow padding='24px' style={{ marginRight: 24, marginBottom: 24 }}>
                            <Flex between centerHorizontally>
                                <FlexColumn start left>
                                    <Text heading margin='0 0 4px'>Connect Aion to your accounting system</Text>
                                    <Text>Reduce manual entry with Aion's accounting system integrations. Setup in minutes and save time on repetitive accounting tasks.</Text>

                                    {/* <Button margin='24px 0 0' solid text='CONNECT NOW' onClick={() => setShowConnectAS(true)} /> */}
                                </FlexColumn>
                                <TextButton
                                    onClick={() => setShowConnectAS(true)}
                                    rightIcon={<Image src={RightArrow} />} text="CONNECT" />
                            </Flex>
                        </Container>
                    }

                    {
                        (userRole.includes("Banking Admin") || isCompanyAdmin || showAPIKeys) &&
                        (showAPIKeys || showExternalBankConnections || showIntegration || showConnections) &&
                        <Container shadow padding='24px' style={{ marginRight: 24, marginBottom: 24 }}>
                            <FlexColumn start>
                                {
                                    <>
                                        <Text heading margin='0 0 4px'>Your Connections</Text>
                                        <Text>Connect your external bank accounts, accounting systems and more.</Text>
                                    </>
                                }

                                {
                                    (showExternalBankConnections) &&
                                    <BankConnect store={store} key="bankConnect" />
                                }

                                {
                                    showConnections &&
                                    <FlexColumn style={{ marginTop: 24 }}>
                                        <Text caption style={{ marginBottom: "24px" }}>ACCOUNTING SYSTEMS</Text>
                                        <ASConnection settings history={history} location={location} />
                                    </FlexColumn>
                                }

                                {
                                    showIntegration &&
                                    <FlexColumn style={{ marginTop: 24 }}>
                                        <Text caption style={{ marginBottom: "24px" }}>ACCOUNTING SYSTEMS</Text>
                                        <LightContainer padding='12px 24px' margin='0 24px 12px 0' height='56px'>
                                            <Flex style={{ height: '100%' }} start >
                                                <Flex start style={{ width: '50%' }}>
                                                    {/* <img style={{ marginRight: 8 }} height='24px' src={qbLogo} /> */}
                                                    <img style={{ marginRight: 8 }} height='30px' src={qbImage} />

                                                    {/* <Text lightText noWrap style={{ marginRight: 8, marginTop: 3 }}>QuickBooks Online</Text> */}

                                                    {
                                                        qbSetupCompleted && <Tag height='35px' color='#318F2F' background='#F5F9F5'>{connectedPlatform.connectionStatus}</Tag>
                                                    }
                                                    {/* {
                                                            qbSetupCompleted && <TextButton text='DISCONNECT' onClick={() => revokeToken()}  style={{ marginRight: 8 }} />
                                                        } */}
                                                </Flex>
                                                <Flex end style={{ width: '50%', alignItems: 'center' }}>
                                                    {qbSetupCompleted ?
                                                        <>
                                                            {
                                                                isSyncInprogress ? <><img width='24px' style={{ marginRight: 8 }} height='24px' src={GSyncIcon} />
                                                                    <Text color='#1199FF' lightText noWrap style={{ fontSize: '12px', marginRight: 8 }}> Sync in progress</Text></>
                                                                    :
                                                                    <><Text lightText noWrap style={{ color: '#666666', fontSize: '12px', marginRight: 40 }}>Last synced {connectedPlatform.lastSyncedEndDate ? moment(connectedPlatform.lastSyncedEndDate).format('MMM D YY; h:mm a') : '-'}</Text>
                                                                        <img margin='0 12px' width='1px' height='30px' src={Pipe} style={{ marginRight: 48 }} />
                                                                        <img width='24px' style={{ marginRight: 58, cursor: 'pointer' }} height='24px' src={SettingsIcon} onClick={() => window.location.href = ('/settings/sync')} icon={<SettingOutlined />} />
                                                                        <img width='24px' style={{ marginRight: 8, cursor: 'pointer' }} height='24px' src={BSyncIcon} onClick={syncNow} /></>
                                                            }
                                                        </>
                                                        :
                                                        showReconnect ?
                                                            <Button
                                                                radius='4px'
                                                                width='140px'
                                                                height='2px'
                                                                text='RECONNECT'
                                                                onClick={() => window.location.href = ('/settings/integration/qb-setup')}
                                                            /> :
                                                            <Button
                                                                radius='4px'
                                                                width='140px'
                                                                height='2px'
                                                                text='CONFIGURE'
                                                                onClick={() => window.location.href = ('/settings/integration/qb-setup')}
                                                            />
                                                    }
                                                </Flex>
                                            </Flex>
                                        </LightContainer>
                                    </FlexColumn>
                                }

                                {
                                    showAPIKeys &&
                                    <>
                                        {/* {(showIntegration || userRole.includes("Banking Admin") || isCompanyAdmin) && <Divider margin='10px 0' />} */}
                                        <API />
                                    </>
                                }
                            </FlexColumn>
                        </Container>
                    }
                </FlexColumn>
            </Flex>

            <Modal
                visible={showConnectAS}
                footer={null}
                closable={true}
                maskClosable={true}
                width={697}
                destroyOnClose={true}
                onCancel={() => setShowConnectAS(false)}
                closeIcon={<Image src={ModalClose} />}
            >
                <ConnectionModal onCancel={() => setShowConnectAS(false)} />
            </Modal>

            <Modal
                visible={showSettingsModal}
                footer={null}
                closable={true}
                maskClosable={true}
                width={697}
                destroyOnClose={true}
                onCancel={() => setShowSettingsModal(false)}
                closeIcon={<Image src={ModalClose} />}
            >
                <SettingsModal
                    learn
                    onCancel={() => {
                        setShowSettingsModal(false)
                        setShowSyncSetupComplete(true)
                    }}
                />
            </Modal>

            <AlertModal
                success
                visible={showSyncSetupComplete}
                title='Sync setup complete'
                description={`You're all set! The connection was successful and your sync is in progress.`}
                buttonTitle='OKAY'
                onConfirm={() => setShowSyncSetupComplete(false)}
            />
        </FlexColumn>
    )
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer,
        bankingStore: state.bankingAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(CompanyProfile))