import React from 'react'
import { withTheme } from 'styled-components'

import { Flex, FlexColumn } from './Container'
import { Text } from './Text'
import { Image } from './Image'

// Images
import DeviceImg from '../../Images/device-icon.png'
import AppStoreImg from '../../Images/app-store-icon.png'
import Vector7 from '../../Images/vector-7.png'
import Vector8 from '../../Images/vector-8.png'


const MobileLanding = (props) => {

    return (
        <FlexColumn between fullHeight style={{ maxWidth: '100%', maxHeight: '100%' }}>
            {/* <img className='vector-8-onb' src={Vector8} />
            <img className='vector-7-onb' src={Vector7} /> */}
            <Flex start height='80px' style={{ background: props.theme.body, padding: '24px',  }}>
                <img height='32px' src={props.theme.logo.full} />
            </Flex>
            <FlexColumn center gap='24px'>
                <Image height='48px' width='48px' src={DeviceImg} />
                <Text size='28px' weight={500} height='40px' style={{ textAlign: 'center', padding: '0 10px' }}>{props.desc || 'Use our desktop app for the best experience'}</Text>
                {!props.hideAppStore && 
                <>
                    <Text size='12px' color={props.theme.colors.defaultLightText} style={{ textAlign: 'center' }}>or</Text>
                    <a href='https://apps.apple.com/us/app/aion-business-banking/id1529925509'><Image height='40px' width='120px' src={AppStoreImg} /></a>
                </>}
            </FlexColumn>
            <Text size='12px' color={props.theme.colors.defaultLightText} style={{ textAlign: 'center', alignSelf: 'bottom', marginBottom: '24px' }}>{!props.hideAppStore ? 'Coming soon to Android' : ''}</Text>
        </FlexColumn>
    )
}

export default withTheme(MobileLanding)