import  { Component } from "react"
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { apiGET } from "../../Utils/api"

const canUseDOM = () => {
    if(typeof window === 'undefined' || !window.document || !window.document.createElement ){
        return false
    }
    return true
}

export const ZendeskAPI = (...args) => {
    if (canUseDOM && window.zE) {
      window.zE.apply(null, args)
      console.warn("Zendesk is not initialized!!")
    } else {
      console.warn("Zendesk is not initialized yet")
    }
}

class Zendesk extends Component {

    constructor(props) {
        super(props)
        this.insertScript = this.insertScript.bind(this)
        this.onScriptLoaded = this.onScriptLoaded.bind(this)
    }

    onScriptLoaded() {
        if (typeof this.props.onLoaded === 'function') {
          this.props.onLoaded();
        }
        const { store } = this.props;
        var { UserInfo, Profile } = store;
        const { BusinessInfo } = Profile || {};
        if (window.zE) {
            window.zESettings = {
                webWidget: {
                    authenticate: {
                        chat: {
                            jwtFn: function(callback) { 
                                apiGET(`/zendesk/chat/jwt?first_name=${UserInfo.FirstName}&last_name=${UserInfo.LastName}&email=${UserInfo.Email}`, {}, (err, resp) => {
                                    try {
                                        var data = resp.data || {};
                                        console.log("/zendesk/chat/jwt", data.jwt);
                                        callback(data.jwt);
                                    } catch (error) {
                                        console.log("/zendesk/chat/jwt", error);
                                        callback(null);
                                    }
                                })
                            }
                        },
                        jwtFn: function(callback) { 
                            apiGET(`/zendesk/hc/jwt?first_name=${UserInfo.FirstName}&last_name=${UserInfo.LastName}&email=${UserInfo.Email}`, {}, (err, resp) => {
                                try {
                                    var data = resp.data || {};
                                    console.log("/zendesk/hc/jwt", data.jwt);
                                    callback(data.jwt);
                                } catch (error) {
                                    console.log("/zendesk/hc/jwt", error);
                                    callback(null);
                                }
                            })
                        }
                    }
                }
            }
            window.zE("webWidget", "identify", {
                name: `${UserInfo.FirstName} ${UserInfo.LastName}`,
                email: UserInfo.Email,
                organization: BusinessInfo.Name
            });
            window.zE("webWidget", "prefill", {
                name: {
                    value: `${UserInfo.FirstName} ${UserInfo.LastName}`,
                    readOnly: true // optional
                  },
                  email: {
                    value: UserInfo.Email,
                    readOnly: true // optional
                  },
                  phone: {
                    value: UserInfo.Phone,
                    readOnly: true // optional
                  }
            });
        }
        
    }

    insertScript () {
        const script = document.createElement('script')
        script.async = true
        script.id = 'ze-snippet'
        script.src = `https://static.zdassets.com/ekr/snippet.js?key=dd85b1e5-8df5-4690-8fe5-0e04bcced855`
        script.addEventListener('load', this.onScriptLoaded);
        document.body.appendChild(script);
    }

    componentDidMount() {
        this.insertScript();
        if (!canUseDOM || !window.zE) {
            return
        }
        window.zE('webWidget', 'show');
    }

    componentDidUpdate() {
        if(this.props.openSupportWidget) window.zE('webWidget', 'open');
    }
    
    componentWillUnmount() {
        if (!canUseDOM || !window.zE) {
            return
        }
        window.zE('webWidget', 'hide');
        // delete window.zE
        // delete window.zESettings
    }

    openWidget() {
        window.zE('webWidget', 'show');
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Zendesk)));