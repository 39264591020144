import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import { withTheme } from 'styled-components';

import {
    Layout
} from 'antd'

// Components
import { Button } from '../Reusable/Button';
import { Paragraph, Title } from '../Reusable/Text';
import { FlexColumn } from '../Reusable/Container';
import { LabeledInput } from '../Reusable/Input';
import { ErrorAlert } from '../Reusable/Alert';


class NewPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {loading: false}
    }

    handleChange = (event) => {
        if(event.target.id === "password") {
            this.setState({password: event.target.value});
        }        
    }
    
    handleSubmit = async (event) => {
        if(this.state.password === '') {
            ErrorAlert({description: "Please enter a password."});
            return;
        }
        this.setState({loading: true});
        Auth.completeNewPassword(this.props.user, this.state.password)
            .then(user => {
                this.props.newPasswordComplete();
            })
            .catch(error => {
                this.setState({loading: false});
                console.log('error completeNewPassword', error);
                ErrorAlert({description: error.message});
            });
        event.preventDefault();
    }

    render() {
        return (
            <FlexColumn center style={{height: "80%"}}>
                <div style={{ width: "100%" }}>
                    <Title level={4}>New Password Required</Title>
                    <Paragraph>Please enter a new password for your account.</Paragraph>
                    <FlexColumn onSubmit={this.handleSubmit} className="container" style={{flexDirection:'column'}}>
                        <LabeledInput
                            autoFocus
                            label="Password"
                            id="password" 
                            type="password" 
                            placeholder="Enter New Password" 
                            onChange={this.handleChange} 
                        />
                        <Button permtype="Override" style={{alignSelf: 'center', width: '100%', margin: '20px 0'}} solid loading={this.state.loading} onClick={this.handleSubmit} text='Submit' />
                    </FlexColumn>
                </div>
            </FlexColumn>
        );
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(withTheme(NewPassword));