import React, { useState } from 'react';
import { Text, Tag } from '../../Reusable/Text';
import { LabeledInput } from '../../Reusable/Input';
import { Flex, FlexColumn, Container, Card, CardContainer } from '../../Reusable/Container';


const PaymentInformation = ({ name, email, paymentAmount, errorField, errorMessage, width, setName, setEmail, setAmount }) => {

    return (
        <CardContainer width={width} fullWidth={!width} padding='12px 24px 24px'>
            <FlexColumn gap='24px'>
                <Text heading>Payment information</Text>
                <Flex wrap gap='24px' start>
                    <LabeledInput
                        nomargin
                        label="Name"
                        width={width && "276px"}
                        placeholder="Enter your name"
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                        error={errorField === "name"}
                        errorMessage={errorMessage}
                    />
                    <LabeledInput
                        nomargin
                        label="Email"
                        type="email"
                        width={width && "276px"}
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        placeholder="Email"
                        error={errorField === "email"}
                        errorMessage={errorMessage}
                    />
                    <LabeledInput
                        nomargin
                        label="Payment amount"
                        placeholder="Enter payment amount"
                        width={width && "276px"}
                        prefixText="$"
                        value={paymentAmount}
                        onChange={(event) => setAmount(event.target.value)}
                        error={errorField === "paymentAmount"}
                        errorMessage={errorMessage}
                    />
                </Flex>
            </FlexColumn>
        </CardContainer>
    );
};

export default PaymentInformation;
