import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled, { withTheme } from 'styled-components';

import { FlexColumn, Flex, CardContainer } from '../../../Reusable/Container';
import { Text, Tag } from '../../../Reusable/Text';
import { formatPhoneTextV2, getApplicationDisplayName, getUserRoles } from '../../../../Utils/util';
import ProfileImage from './ProfileImage';
import { Divider, LabeledInput } from '../../../Reusable/Input';
import LinkIcon from '../../../../Images/link-icon.png';
import { TextButton } from '../../../Reusable/Button';
import { Modal } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import SuccessModal from '../../../Reusable/SuccessModal';
import ModalClose from '../../../../Images/modal-close.png'
import ChangePassword from './ChangePassword';
import ViewRole from '../../UserAccess/ViewRole';
import { apiPOSTReq } from '../../../../Utils/api';
import environment from '../../../../environment';
import { ErrorAlert } from '../../../Reusable/Alert';
import PageBackArrow from '../../../../Images/page-back-arrow.png'
import { addressObjectToStr } from '../../../../Utils/util'
import { parsePhoneNumber } from 'libphonenumber-js';
import PageHeader from '../../../Reusable/PageHeader';

const cardWidth = 552;

class Index extends Component {
    state = {
        loading: false,
        dropdownVisible: false
    }

    componentDidMount() {
        this.fetchRoles()
    }

    fetchRoles() {
        apiPOSTReq(`${environment.uamBaseUrl}/uam/getRolesForBusiness`, {}, {}, (err, resp) => {
            try {
                var roles = resp.roles || []
                roles.sort(function (x, y) { return x.roleType == "Custom__Sys" ? 1 : y.roleType == "Custom__Sys" ? -1 : 0 })

                console.log("getRolesForBusiness", JSON.stringify(roles))

                // Revenue and Expenses Admin handling
                var revenueAndAdminRoles = []
                var indexesToRemove = []

                roles.forEach((role, i) => {
                    // Add App names to system roles
                    role.resourcePermissions.forEach(permission => {
                        if (!permission.nameSpace) {
                            permission.nameSpace = getApplicationDisplayName(role.applicationName) // getAppDisplayNameBySystemRole(role.roleName)
                        }
                    })

                    // Revenue and Expenses Admin handling
                    if (role.roleName === "Revenue and Expenses Admin") {
                        revenueAndAdminRoles.push(role)
                        indexesToRemove.push(i)
                    }
                })

                // Revenue and Expenses Admin handling
                if (revenueAndAdminRoles.length > 1) {
                    var revenueAndAdminRole = revenueAndAdminRoles[0]

                    for (var i = 1; i < revenueAndAdminRoles.length; i++) {
                        revenueAndAdminRole.resourcePermissions = revenueAndAdminRole.resourcePermissions.concat(revenueAndAdminRoles[i].resourcePermissions)
                    }

                    while (indexesToRemove.length) {
                        roles.splice(indexesToRemove.pop(), 1)
                    }

                    roles.push(revenueAndAdminRole)
                }

                var systemRoles = roles.filter(function (role) {
                    return role.roleType != "Custom__Sys" && role.roleName != "Staff"
                })

                var customRoles = roles.filter(function (role) {
                    return role.roleType === "Custom__Sys" && role.roleName != "Staff"
                })

                this.setState({ allRoles: roles, rolesLoading: false, systemRoles, customRoles })

                if (err) throw Error(err)
                if (!resp.result) throw Error(resp.responseMessage || resp.error)
            } catch (error) {
                /**
                 * Commented because if accessed during onboarding the user will see an error
                 */
                // ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    getRoleInfo = (roles) => {
        var { systemRoles, customRoles } = this.state
        var roleInfo = []
        var resourcePermissions = {}
        const allAccountResourcePermissions = [];
        roles.forEach(role => {
            let sr = (systemRoles || []).find(item => role == item.roleName)
            let cr = (customRoles || []).find(item => role == item.roleName)
            if(sr) roleInfo.push(sr);
            if(cr) roleInfo.push(cr);
        })

        roleInfo.forEach(item => {
            item.resourcePermissions.forEach(perm => {
                let key = `${perm.nameSpace}_${perm.resourceLabel}_${perm.resourceDescription}`;
                let permissions = perm.permissionCodes;
                if(resourcePermissions[key]) {
                    let concatinatedPermissions = resourcePermissions[key].concat(permissions);
                    let uniquePermisssions = [];
                    //remove duplicate permissions
                    for(let i of concatinatedPermissions) {
                        if(uniquePermisssions.indexOf(i) === -1) {
                            uniquePermisssions.push(i);
                        }
                    }
                    resourcePermissions[key] = uniquePermisssions;
                }
                else {
                    resourcePermissions[key] = (permissions);
                }
            });

            (item.accountResourcePermissions || []).forEach(accountResourcePermission => {
                if (!allAccountResourcePermissions.some(perm => perm.accountId === accountResourcePermission.accountId)) {
                    allAccountResourcePermissions.push(accountResourcePermission);
                }
            });
        })
        var formattedRole = {
            roleName: "My Permissions",
            resourcePermissions: [],
            accountResourcePermissions: allAccountResourcePermissions,
        }
        formattedRole.resourcePermissions = Object.keys(resourcePermissions).map(key => {
            const keyArr = key.split("_")
            return {
                nameSpace: keyArr[0],
                resourceLabel: keyArr[1],
                permissionCodes: resourcePermissions[key],
                resourceDescription: keyArr[2],
            }
        })
        console.log("getRoleInfo", roleInfo, formattedRole)
        return formattedRole
    }

    render() {
        const { aionStore, theme } = this.props
        const { dropdownVisible, showPwdChange, showSuccessModal, showViewRoleModal } = this.state
        var { linkedBusinessList } = aionStore
        var { userInfo } = aionStore
        userInfo = userInfo || {}
        console.log("userInfo", userInfo)
        var roles = getUserRoles(aionStore)
        linkedBusinessList = linkedBusinessList || [];
        const phoneNumber = parsePhoneNumber(userInfo.phone)
        const formattedPhoneNumber = phoneNumber.countryCallingCode == "1" ? `+${phoneNumber.countryCallingCode} ${phoneNumber.formatNational()}` : phoneNumber.formatInternational()
        if(roles.includes("Primary Admin") && roles.includes("Company Admin")) {
            const i = roles.indexOf("Company Admin")
            if (i > -1) {
                roles.splice(i, 1)
            }
        }
        
        // console.log('phoneNumber', phoneNumber.formatNational(), phoneNumber.formatInternational(), phoneNumber)

        return (
            <>
                <FlexColumn className='main-padding' gap="24px">
                    <PageHeader
                        titleText='Profile'
                        back
                    />
                    <Flex between style={{ gap: "40px", width: `${cardWidth*2 + 30}px`, zIndex: 100, marginTop: 24 }}>
                        <ProfileImage />
                        <FlexColumn style={{ gap: "16px", width: "100%" }}>
                            <Flex between>
                                <Text weight='700' size='36px' height='48px'>{userInfo.firstName} {userInfo.lastName}</Text>
                            </Flex>
                            
                            <Flex start style={{ gap: "8px" }}>{roles.map(tag => <Tag color={theme.colors.primary2} background={theme.colors.backgroundPrimary} key={tag}>{tag}</Tag>)}</Flex>
                        </FlexColumn>
                    </Flex>
                    <Flex start style={{ gap: "24px" }}>
                        <CardContainer width="936px" padding="24px 24px 34px 24px">
                            <FlexColumn style={{ gap: "24px" }}>
                                <Text heading>My Details</Text>
                                <Text size="16px" weight="500" uppercase color={theme.colors.secondary6}>Personal Details</Text>
                                <LabeledInput
                                    margin="0px"
                                    type="read-only"
                                    label="Legal Name"
                                    value={`${userInfo.firstName} ${userInfo.lastName}`}
                                />
                                <Divider />
                                <Text size="16px" weight="500" uppercase color={theme.colors.secondary6}>Contact Details</Text>
                                <Flex space style={{ gap: "24px", alignSelf: "stretch" }}>
                                    <LabeledInput
                                        margin="0px"
                                        type="read-only"
                                        label="Phone Number"
                                        value={formattedPhoneNumber}
                                    />
                                    <LabeledInput
                                        margin="0px"
                                        type="read-only"
                                        label="Email"
                                        value={`${userInfo.email}`}
                                    />
                                </Flex>
                            </FlexColumn>
                        </CardContainer>
                        <CardContainer width='296px'>
                            <Text heading>Manage</Text>
                            <FlexColumn start style={{ marginTop: "24px" }}>
                                <Text color={theme.colors.defaultLightText}>Change your password</Text>
                                <TextButton margin='12px 0 20px' onClick={() => this.setState({ showPwdChange: true })} rightIcon={<ArrowRightOutlined />} text="CHANGE PASSWORD" />
                                <Divider />
                                <Text margin='10px 0 0' color={theme.colors.defaultLightText}>View detailed information<br/>of what you can access</Text>
                                <TextButton margin='12px 0 20px' onClick={() => this.setState({ showViewRoleModal: true })} rightIcon={<ArrowRightOutlined />} text="VIEW PERMISSIONS" />
                            </FlexColumn>
                        </CardContainer>
                    </Flex>
                    <Flex between>
                        <Flex start style={{ gap: "24px" }}>
                            <a style={{ fontSize: "14px" }} target="_blank" href="https://www.aionfi.com/legal">Legal</a>
                            <a style={{ fontSize: "14px" }} target="_blank" href="https://www.aionfi.com/platform-services-agreement">Terms & Conditions</a>
                            <a style={{ fontSize: "14px" }} target="_blank" href="https://www.aionfi.com/privacy">Privacy</a>
                        </Flex>
                        {/*<Text size="14px" color={theme.colors.defaultLightText}>© 2022 - Aion Financial Technologies, Inc. All rights reserved.</Text>*/}
                    </Flex>
                    <Text size="14px" color={theme.colors.defaultLightText}>
                       {/* Aion is not a bank. Banking Services provided by Cross River Bank, Member FDIC. Aion Visa Business Debit Card is issued by Cross River Bank pursuant to a license from Visa, Inc. and may be used everywhere Visa Debit Cards are accepted. ATM network fees may apply.
                        <br/>
                        <br/>
                        California financing is made or arranged pursuant to California financing Law License No. 60DBO-70879.
                     */}
                     </Text>
                </FlexColumn>
                <Modal
                    visible={showPwdChange}
                    footer={null}
                    closable={false}
                    width={696}
                    destroyOnClose={true}
                >
                    <ChangePassword 
                        closeModal={() => this.setState({ showPwdChange: false })}
                        onSuccess={() => this.setState({ showPwdChange: false, showSuccessModal: true })}
                    />
                </Modal>
                <Modal
                    visible={showSuccessModal}
                    footer={null}
                    closable={false}
                    width={456}
                    destroyOnClose={true}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <SuccessModal
                        title={`Password updated successfully`}
                        description={`You can use your new password on next login.`}
                        buttonTitle='OKAY'
                        onConfirm={() => this.setState({ showSuccessModal: false })} />
                </Modal>
                <Modal
                    visible={showViewRoleModal}
                    footer={null}
                    onCancel={() => { this.setState({ showViewRoleModal: false, selectedRole: null }) }}
                    width={900}
                    destroyOnClose={true}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                    style={{ top: 40 }}
                >
                    <ViewRole
                        selectedRole={this.getRoleInfo(roles)}
                        closeModal={() => {
                            this.setState({ showViewRoleModal: false, selectedRole: null })
                        }}
                        userRoleNames={roles}
                    />
                </Modal>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer,
        bankingStore: state.bankingAppReducer,
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Index));