import { cloneDeep } from 'lodash';
import update from 'immutability-helper';
import {
	APP_STATE, PERSONAL_INFO, BUSINESS_INFO,
	NEW_CO_OWNER, DELETE_CO_OWNER, CO_OWNER_INFO, SET_CONTROL_PERSON, PPP_INFO,
	DEBT_SCHEDULE, SUPPORTING_DOCS, ADD_SUPPORTING_DOCS, DELETE_SUPPORTING_DOCS,
	NO_DOCUMENT_TOGGLE, DOCUMENT_NOTE, RESET_ONBOARDING_STATE, ONB_SAVE_DATA,
	NEW_SIGNER, DELETE_SIGNER, SIGNER_INFO, ADD_CONTROL_PERSON, REMOTE_SAVE_DATA, REMOTE_SAVE_USER_STATUS,
	SAVE_CO_OWNER, SAVE_CO_OWNER_CACHE, RESET_CO_OWNER_CACHE, SAVE_SIGNER, CO_OWNER_APP_INFO
} from '../Actions/actions'
import { apiPOSTReq } from '../Utils/api';
import environment from '../environment';

const initialState = {
	businessTitles: ["CEO", "CFO", "COO", "Other"],
	ownerInfoCache: {
		// businessTitle: "CEO",
		authorizedSigner: true,
		idFileList: []
	},
	personalInfo: {
		// businessTitle: "CEO",
		authorizedSigner: true,
		controlPerson: null,
		idFileList: [],
		// countryOfCitizenship: 'US'
	},
	businessInfo: {
		initialDepositType: "Debit card",
		einFileList: []
	},
	businessUniqueKey: "",
	coOwnersInfo: [],
	supportingDocuments: {
		taxReturns: [],
		pnl: [],
		bankStatements: [],
		balanceSheet: [],
		personalFinancialStatement: [],
		arAgingSummary: [],
		apAgingReport: []
	},
	appState: {},
	coOwnerId: 0,
	signersInfo: [],
	signerId: 0,
	userStatus: "", // Tracks which step the user is on
	additionalSigners: "Yes",
	allowNRFlow: false
}

function onboardingAppReducer(state, action) {
	if (typeof state === 'undefined') {
		return initialState
	}

	function getIndexOfComment(docType, name) {
		return state.supportingDocuments[docType].findIndex(doc => doc.Name == name && typeof doc.Note !== 'undefined')
	}

	// console.log("Running reducer...", action);
	var newState = cloneDeep(state);
	let { docType, name } = action.data || { docType: null, name: null };

	switch (action.type) {
		case APP_STATE:
			newState.appState = Object.assign(newState.appState, action.data || {});
			return newState

		case PERSONAL_INFO:
			newState.personalInfo = newState.personalInfo || initialState.personalInfo;
			newState.personalInfo = Object.assign(newState.personalInfo, action.data || {});
			console.log("PERSONAL_INFO", newState)
			return newState

		case BUSINESS_INFO:
			newState.businessInfo = newState.businessInfo || {};
			newState.businessInfo = Object.assign(newState.businessInfo, action.data || {});
			return newState
		case CO_OWNER_APP_INFO:
			newState.coOwnersInfo = newState.coOwnersInfo || initialState.coOwnersInfo;
			newState.coOwnersInfo = Object.assign(newState.coOwnersInfo, action.data || {});
			return newState
		case SAVE_CO_OWNER:
			var { ownerInfo } = action.data || {};
			var existingOwnerInd = null;
			newState.coOwnersInfo = (newState.coOwnersInfo || []).map((item, i) => {
				if ((item.email == ownerInfo.email) || (item.ssn == ownerInfo.ssn)) {
					existingOwnerInd = i;
					item = ownerInfo; // replace
				}
				return item
			})
			if (existingOwnerInd == null) {
				newState.coOwnersInfo.push(ownerInfo);
			}
			console.log("SAVE_CO_OWNER", newState.coOwnersInfo, ownerInfo);
			return newState
		case DELETE_CO_OWNER:
			var { ownerInfo, ownerType } = action.data || {};
			// Do not delete if person is BO and CP
			if((ownerType == "controlPerson") && (ownerInfo.ownershipPercentage >= 25)) {
				(newState.coOwnersInfo || []).forEach((item, i) => { 
					if(((item.email == ownerInfo.email) || (item.ssn == ownerInfo.ssn))) {
						item.controlPerson = false
					}
				})
			} else {
				newState.coOwnersInfo = (newState.coOwnersInfo || []).filter((item, i) => { 
					return !((item.email == ownerInfo.email) || (item.ssn == ownerInfo.ssn))
				})
			}
			
			console.log("DELETE_CO_OWNER", newState.coOwnersInfo, ownerInfo);
			return newState
		case SAVE_SIGNER:
			var { ownerInfo } = action.data || {};
			var existingInd = null;
			newState.signersInfo = (newState.signersInfo || []).map((item, i) => {
				if ((item.email == ownerInfo.email) || (item.ssn == ownerInfo.ssn)) {
					existingInd = i;
					item = ownerInfo; // replace
				}
				return item
			})
			if (existingInd == null) {
				newState.signersInfo.push(ownerInfo);
			}
			return newState
		case DELETE_SIGNER:
			var { ownerInfo } = action.data || {};
			newState.signersInfo = (newState.signersInfo || []).filter((item, i) => {
				return !((item.email == ownerInfo.email) || (item.ssn == ownerInfo.ssn))
			})
			return newState
		case SAVE_CO_OWNER_CACHE:
			newState.ownerInfoCache = action.data || {};
			return newState
		case RESET_CO_OWNER_CACHE:
			newState.ownerInfoCache = initialState.ownerInfoCache;
			return newState
		// Prepare the structure for a new coOwner
		case NEW_CO_OWNER:
			var newCoOwnerId

			if (state.coOwnersInfo && state.coOwnersInfo.length > 0) {
				newCoOwnerId = newState.coOwnersInfo.slice(-1)[0].id + 1
			} else {
				newCoOwnerId = 0
			}

			let newCoOwner = {
				citizenOrResident: true,
				// businessTitle: "CEO",
				// businessRole: "Founder",
				id: newCoOwnerId,
				resident: true,
				idType: "Passport",
				countryOfCitizenship: 'US',
			};
			return update(state, {
				coOwnersInfo: { $push: [newCoOwner] },
				// coOwnerId: { $set: state.coOwnerId + 1 },
			})
		case ADD_CONTROL_PERSON:
			var newCoOwnerId

			if (state.coOwnersInfo && state.coOwnersInfo.length > 0) {
				newCoOwnerId = newState.coOwnersInfo.slice(-1)[0].id + 1
			} else {
				newCoOwnerId = 0
			}

			let controlPerson = {
				citizenOrResident: true,
				resident: true,
				// businessTitle: "CEO",
				// businessRole: "Founder",
				id: newCoOwnerId,
				controlPerson: true,
				controllingParty: true,
				idType: "Passport",
				countryOfCitizenship: 'US',
			};
			return update(state, {
				coOwnersInfo: { $push: [controlPerson] },
				coOwnerId: { $set: newCoOwnerId },
			})

		case CO_OWNER_INFO:
			if (action.data && action.data.coOwnerId != null && newState.coOwnersInfo.length > 0 && newState.coOwnersInfo[action.data.coOwnerId]) {
				let coOwner = newState.coOwnersInfo[action.data.coOwnerId]
				coOwner = Object.assign(coOwner, action.data || {})
			}

			return newState

		// Prepare the structure for a new signer
		case NEW_SIGNER:
			var newSignerId

			if (state.signersInfo && state.signersInfo.length > 0) {
				newSignerId = newState.signersInfo.slice(-1)[0].id + 1
			} else {
				newSignerId = 0
			}

			let newSigner = {
				citizenOrResident: true,
				// businessTitle: "CEO",
				id: newSignerId,
				authorizedSigner: true,
				countryOfCitizenship: 'US',
				idType: "Passport",
			};
			return update(state, {
				signersInfo: { $push: [newSigner] },
			})
		case SIGNER_INFO:
			if (action.data && action.data.signerId != null && newState.signersInfo.length > 0 && newState.signersInfo[action.data.signerId]) {
				let signer = newState.signersInfo[action.data.signerId]
				signer = Object.assign(signer, action.data || {})
			}

			return newState

		case SET_CONTROL_PERSON:
			delete newState.personalInfo['controlPerson']
			newState.coOwnersInfo.forEach(owner => {
				delete owner['controlPerson']
			})
			if (action.data.key === 0) {
				newState.personalInfo.controlPerson = true
			} else {
				newState.coOwnersInfo[action.data.key - 1].controlPerson = true
			}
			return newState

		case PPP_INFO:
			newState.pppInfo = newState.pppInfo || {};
			newState.pppInfo = Object.assign(newState.pppInfo, action.data || {});
			return newState

		case DEBT_SCHEDULE:
			newState.debtSchedule = action.data.debtSchedule
			return newState

		// A Supporting document is added
		case ADD_SUPPORTING_DOCS:
			return update(state, {
				supportingDocuments: { [docType]: { $push: [action.data.payload] } }
			})

		// A Supporting document is deleted
		case DELETE_SUPPORTING_DOCS:
			const index = state.supportingDocuments[docType].findIndex(doc => doc.uid === action.data.file.uid);
			return update(state, {
				supportingDocuments: { [docType]: { $splice: [[index, 1]] } }
			})

		// The 'No document' checkbox is toggled
		case NO_DOCUMENT_TOGGLE:
			if (getIndexOfComment(docType, name) == -1)	// no entry for comment yet, create one
				return update(state, {
					supportingDocuments: { [docType]: { $push: [{ Name: name.toString(), Note: '', noDocumentChecked: true }] } }
				})
			else
				return update(state, {
					supportingDocuments: { [docType]: { [getIndexOfComment(docType, name)]: { $toggle: ['noDocumentChecked'] } } }
				})

		// Note is entered for when 'No document' is checked
		case DOCUMENT_NOTE:
			return update(state, {
				supportingDocuments: { [docType]: { [getIndexOfComment(docType, name)]: { Note: { $set: action.data.note } } } }
			})

		case ONB_SAVE_DATA:
			newState = Object.assign(newState, action.data ? action.data : {});
			return newState

		case REMOTE_SAVE_DATA:
			// Update userStatus and save existing state
			newState.userStatus = action.data;
			saveBusiness({
				businessInfo: newState.businessInfo,
				primaryApplicantInfo: newState.personalInfo,
				coOwnerApplicantInfo: newState.coOwnersInfo,
				coOwnerInfo: newState.coOwnersInfo,
				signerInfo: newState.signersInfo,
				userStatus: newState.userStatus
			})
			return newState

		case REMOTE_SAVE_USER_STATUS:
			// Update userStatus and save existing state
			newState.userStatus = action.data;
			saveBusiness({
				userStatus: newState.userStatus
			})
			return newState

		case RESET_ONBOARDING_STATE:
			newState = initialState;
			return newState
		default:
			return state
	}
}

function saveBusiness(body) {
	body = body || {};
	apiPOSTReq(`${environment.uamBaseUrl}/saveBusiness`, {}, body, (err, resp) => {
		console.log("REMOTE_SAVE_DATA /saveBusiness body", body, err, resp);
	});
}

export default onboardingAppReducer