import React from 'react';
import styled from 'styled-components';
import { FlexColumn } from './Container';

// This button will have all the functionality as the antd Button
export const Paragraph = styled.p`
    font-size: ${props => {
        switch (props.level) {
            case 1:
                return '0.9rem'
            case 2:
                return '1.1rem'
            case 3:
                return '1.2rem'
            case 4:
                return '1.3rem'
            case 5:
                return '1.4rem'
            default:
                return props.size || '16px'
        }
    }
    };
    font-weight: ${props => props.bold ? 500 : props.fontWeight};
    color: ${props => props.color || props.theme.text};
    margin: ${props => props.noMargin ? 0 : (props.margin || null)};
`;

export const Title = styled.h1`
    opacity: 0.95;
    font-size: ${props => {
        switch (props.level) {
            case 2:
                return '4.2rem'
            case 3:
                return '3.4rem'
            case 4:
                return '2.6rem'
            case 5:
                return '2.0rem'
            case 6:
                return '1.6rem'
            default:
                return '5rem'
        }
    }
    };
    line-height: ${props => {
        switch (props.level) {
            case 2:
                return '4.7rem'
            case 3:
                return '3.9rem'
            case 4:
                return '3.1rem'
            case 5:
                return '2.5rem'
            case 6:
                return '2.3rem'
            default:
                return '5rem'
        }
    }
    };
    font-weight: ${props => props.bold ? 600 : 400};
    color: ${props => props.color || props.theme.text};
    margin: ${props => props.noMargin ? 0 : (props.margin || null)};
`;

/* --- Refresh --- */

export const Text = styled.span`
    font-family: 'InterDisplay';
    font-size: ${props => props.size ? props.size : (props.heading || props.large) ? '20px' : props.light ? '12px' : '14px'};
    font-weight: ${props => props.weight ? props.weight : ((props.heading || props.caption) ? '500' : '400')};
    line-height: ${props => props.height ? props.height : ((props.heading || props.large) ? '40px' : '24px')};
    text-align: ${props => props.right ? 'right' : (props.center ? 'center' : 'left')};
    vertical-align: ${props => props.center && 'middle'};
    color: ${props => {
        var color = ''
        if (props.primary) {
            color = props.theme.colors.primary
        } else if (props.color) {
            color = props.color
        } else if (props.heading) {
            color = props.theme.colors.heading
        } else if (props.light || props.lightText) {
            color = props.theme.colors.defaultLightText
        } else if (props.caption) {
            color = props.theme.colors.caption
        } else if (props.credit) {
            color = props.theme.colors.creditGreen
        } else {
            color = props.theme.colors.defaultText
        }
        return `${color} !important`
    }};
    text-transform: ${props => props.uppercase && 'uppercase'};
    margin: ${props => props.margin && props.margin};
    width: ${props => props.width && props.width};
    white-space: ${props => props.noWrap && 'nowrap'};
    text-transform: ${props => props.capitalize ? 'capitalize' : props.uppercase && 'uppercase'};
    text-decoration: ${props => props.underline && 'underline'};
    letter-spacing: ${props => props.spacing ? props.spacing : props.spaced && '0.08em'};
    cursor: ${props => props.onClick && 'pointer'};
`

export const Tag = styled.span`
    color: ${props => props.color || '#7384AA'};
    background: ${props => props.background || '#F8F8F8'};
    font-size: ${props => props.size || '16px'};
    font-weight: ${props => props.weight || '400'};
    border-radius: ${props => props.radius || '4px'};
    margin: ${props => props.margin || '0'};
    padding: ${props => props.padding || '4px 8px'};
    height: ${props => props.height || '32px'};
    text-transform: capitalize;
    border: ${props => props.border && `solid 0.5px ${props.color || '#7384AA'}`};
`;

export const PageTitle = styled.span`
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    color: ${props => props.theme.colors.body};
`;

export const SectionTitle = styled.span`
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 40px;
    text-transform: capitalize;
    color: ${props => props.theme.colors.body};
`;

export const SectionDesc = styled.span`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${props => props.theme.colors.body};
`;