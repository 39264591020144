import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
// Components
import { Button } from '../../../Reusable/Button';
import { FlexColumn } from '../../../Reusable/Container';
import { Text } from '../../../Reusable/Text';
import { LabeledInput } from '../../../Reusable/Input';
import { apiPOSTReq } from '../../../../Utils/api';
import environment from '../../../../environment';
import { toCurrency } from '../../../../Utils/util';
import moment from 'moment'

class Index extends Component {
    state = {
        availability: this.props.availability,
        loading: false
    }

    componentDidMount() {
    }

    handleReason = (event) => {
        const target = event.target;
        this.setState({ reason: target.value });
    }

    handleSubmit = async () => {
        this.state.loading = true;
        const { UserInfo } = this.props.store;
        const { businessId, loan, reject } = this.props;

        var username = UserInfo.Email;
        var request = {
            "clientBusinessId": businessId,
            "userName": username,
            "reason": this.state.reason,
            "loanId": loan.Id,
            "reject": reject
        }

        apiPOSTReq(`${environment.opsBaseUrl}/ops/rejectHoldLoan`, null, request, (err, resp) => {
            this.setState({ loading: false })
            try {
                const data = resp;
                if (data.result) {
                    if (err) throw new Error(err);

                    this.props.closeRejection()
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getAccounts", error, err, resp);
            }
        });
    }

    render() {
        const { loading } = this.state;
        const { reject, loan } = this.props;

        return (
            <FlexColumn center style={{ minHeight: "80%" }}>
                <div style={{ width: "600px" }}>
                    <Text heading>{reject ? "Reject" : "Hold"} Loan {loan.Id}</Text>
                    <Text>Please verify below details and provide reason for {reject ? "rejection" : "hold"}</Text>
                    <FlexColumn>
                        <table style={{ border: `1px solid black` }}>
                            <tr style={{ border: `1px solid black`, padding: 3 }}>
                                <th>
                                    Business
                                </th>
                                <th>
                                    Advance Amount
                                </th>
                                <th>
                                    Date
                                </th>
                            </tr>
                            <tr style={{ border: `1px solid black` }}>
                                <td>
                                    {this.props.name}
                                </td>
                                <td>
                                    {`$${toCurrency(loan.AdvanceAmount)}`}
                                </td>
                                <td>
                                    {moment(loan.AdvanceDate).format('MMM DD, YYYY')}
                                </td>
                            </tr>
                        </table>
                        <LabeledInput
                            label="Reason"
                            type="text-area"
                            id="reason"
                            key="reason"
                            placeholder={`Add your reason for ${reject ? "rejection" : "hold"}`}
                            onChange={this.handleReason}
                        />
                        Once you submit this request, the loan will no longer be disbursed.
                        <Button style={{ alignSelf: 'center', width: '100%', margin: '20px 0' }} loading={loading} onClick={this.handleSubmit} text="Submit" />
                    </FlexColumn>
                </div >
            </FlexColumn >
        );
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer,
        receivablesStore: state.receivablesAppReducer,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Index));