import { USERDEFAULTS_SAVE_FLAG } from '../Actions/actions'

/**
 * Use this reducer to store user flags that are not deleted on logout
 */

const initialState = {
}


function userDefaultsReducer(state, action) {
	if (typeof state === 'undefined') {
		return initialState
	}
	// console.log("Running reducer...", action);
	var newState = state;
	switch (action.type) {
		case USERDEFAULTS_SAVE_FLAG:
			newState = Object.assign(newState, action.data ? action.data : {});
			return newState
		default:
			return state
	}
}

export default userDefaultsReducer