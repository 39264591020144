import React from 'react'
import { Select } from 'antd'
import { FlexColumn, Flex } from './Container'
import { Text } from './Text'
import { Image } from './Image'
import { ImageButton } from './Button'
import Individual from '../../Images/individual-recipient.png'
import Business from '../../Images/business-recipient.png'
import { toCurrency } from '../../Utils/util'

import ChevronDown from '../../Images/chevron-down.png'
import ChevronUp from '../../Images/chevron-up.png'
import Edit from '../../Images/edit.png'
import ProgressCheck from '../../Images/progress-check.png'

export const Accordion = (props) => {
    const { title, open, onToggle, onEdit } = props

    return <Flex between height='88px' centerHorizontally>
        <Flex start centerHorizontally gap='8px'>
            <Image src={ProgressCheck} />
            <Text heading>{title}</Text>
        </Flex>

        <Flex end centerHorizontally gap='24px'>
            {
                open ?
                    <>
                        <ImageButton src={Edit} onClick={onEdit} />
                        <ImageButton src={ChevronUp} onClick={onToggle} />
                    </>
                    :
                    <ImageButton src={ChevronDown} onClick={onToggle} />
            }
        </Flex>
    </Flex>
}