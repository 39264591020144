import React, { useState } from 'react'
import { Flex } from './Container'
import { LabeledInput } from './Input'

// Images
import Visibility from "../../Images/visibility-blue.png"
import VisibilityOff from "../../Images/visibility-off.png"

const HiddenInfo = (props) => {
    const [masked, setMasked] = useState(true)
    const { account, width, inputWidth, label, value } = props

    return (
        <Flex style={{ width: width }} between bottom>
            <LabeledInput
                nomargin
                noWidth
                type='read-only'
                width={inputWidth}
                label={label}
                value={masked ? `•••••••••••••••` : value}
                hide
                masked={masked}
                setMasked={setMasked}
                mute
            />

            {/* <img style={{ cursor: 'pointer' }} onClick={() => setMasked(!masked)} width='24px' height='24px' src={masked ? Visibility : VisibilityOff} /> */}
        </Flex>
    )
}

export default HiddenInfo