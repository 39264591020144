import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';

import { uaApiPOST, getUserPermissions } from '../../../Utils/userAccess';
import { apiPOSTReq, apiGET } from '../../../Utils/api';
import environment from '../../../environment';
import { ErrorAlert } from '../../Reusable/Alert';
import moment from 'moment'

import {
    message,
    Tabs,
    Modal,
    Skeleton
} from 'antd';
import { toCurrency } from '../../../Utils/util';
import { Flex, FlexColumn, CardContainer } from '../../Reusable/Container';
import { Text } from '../../Reusable/Text'
import { TextButton } from '../../Reusable/Button';
import PageHeader from '../../Reusable/PageHeader';
import { Image } from '../../Reusable/Image'
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import ModalClose from '../../../Images/modal-close.png'
import ArrowRight from "../../../Images/arrow-right.png"

import AvailableFundsModal from './AvailableFundsModal';
import FreezeLine from './FreezeLine/Index';
import Adjustment from './Adjustment/Index';

import Advances from './Advances/Index';
import Payers from './Payers/Index';
import ARUpload from './ARUpload/Index';
import Inventory from './InventoryUpload/Index';
import LineHistory from './LineHistory/Index';
import Invoices from './Invoices/Index';
import ExternalConnection from './ExternalConnections/Index'
import Payments from './Transactions/AccountTransactions'
import Documents from './Documents/Index'
import Contracts from './Contracts/Index'
import LineSetting from './LineSetting/Index'
import Summary from '../ApplicationDetail/Summary/Index';
import Note from '../ApplicationDetail/Note/Index';
import { LoadingOutlined } from '@ant-design/icons';
import Switch from "../../../Images/switch.png"
import { completeSignin, SAVE_DATA, addDataToStore, UAM_SAVE_PERMISSIONS, UAM_SAVE_BUSINESS, resetStore, RESET_BNK_STATE, SOFT_RESET_MAIN_STATE, RESET_ONBOARDING_STATE, RESET_CREDIT_STATE, RESET_RECEIVABLES_STATE } from '../../../Actions/actions';


const { TabPane } = Tabs

class Index extends Component {
    state = {
        tab: "LineHistory",
        documentsCompKey: "initial-doc-tab-key",
    }

    componentDidMount() {
        this.fetchBusinessData();
    }

    fetchBusinessData = () => {
        const { clientDetail } = this.props.location.state;
        var body = {
            "clientBusinessId": clientDetail.businessId
        };

        this.setState({ loading: true });
        apiPOSTReq(`${environment.uamBaseUrl}/business/admin/getBusinessData`, null, body, (err, resp) => {
            try {
                const data = resp || {};
                if (data.result) {
                    this.setState({
                        business: data.business
                    });

                    this.fetchLoanApplication();
                    this.fetchBusinessAttributes(clientDetail.businessId);
                } else {
                    this.setState({ loading: false });
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                this.setState({ loading: false });
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    fetchBusinessAttributes = (businessId) => {
        const { clientDetail } = this.props.location.state;
        var body = {
            "clientBusinessId": clientDetail.businessId
        };

        this.setState({ attributesLoading: true });
        apiPOSTReq(`${environment.opsBaseUrl}/ops/fetchBusinessAttributes`, null, body, (err, resp) => {
            try {
                const data = resp || {};
                if (data.businessAttributes) {
                    var businessAttributes = data.businessAttributes;
                    var productType = businessAttributes.filter(
                        item => {
                            return (item.name == "ProductType" && moment(item.effectiveEndDate).toDate() >= moment().toDate())
                        }
                    )
                    if (productType.length > 0) {
                        if (productType[0].additionalParameterReference != null) {
                            if (productType[0].additionalParameterReference.parameters != null) {
                                productType[0].additionalParameterReference.parameters.forEach(param => {
                                    if (param.name == "CreditType") {
                                        var type = 'arfinancing';
                                        if (param.value.includes("ARCredit")) {
                                            type = 'arfinancing';
                                        }
                                        else if (param.value.includes("ABLCredit")) {
                                            type = 'assetbasedloan';
                                        }

                                        this.setState({
                                            creditType: type
                                        });
                                    }
                                });
                            }
                        }

                        this.setState({
                            productType: productType[0].value,
                            tab: productType[0].value == "GNPL" && this.state.tab == "LineHistory" ? "Advances" : "LineHistory"
                        });
                    }

                    var annualizedInterest = businessAttributes.filter(
                        item => {
                            return (item.name == "AnnualizedInterest" && moment(item.effectiveEndDate).toDate() >= moment().toDate())
                        }
                    )

                    if (annualizedInterest.length > 0) {
                        this.setState({
                            isFromBusinessAttribute: false
                        });

                        const { business } = this.state;
                        if (annualizedInterest[0].additionalParameterReference != null) {
                            if (annualizedInterest[0].additionalParameterReference.parameters != null) {
                                annualizedInterest[0].additionalParameterReference.parameters.forEach(param => {
                                    if (param.name == "DailyInterestRate") {
                                        // business.arAdvanceInfo.fees = (((parseFloat(param.value) + 1)^365) - 1) * 100;
                                        business.arAdvanceInfo.fees = parseFloat(param.value) * 100;
                                        this.setState({
                                            business: business,
                                            isFromBusinessAttribute: true
                                        });
                                    }
                                });
                            }
                        }
                    }

                    if (this.state.productType == "RLOC") {
                        this.fetchARData();
                    }
                    else {
                        this.setState({ loading: false });
                    }
                } else {
                    this.setState({ loading: false });
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                this.setState({ loading: false });
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    // Fetch data for AR
    fetchARData = () => {
        const { clientDetail } = this.props.location.state;
        const { creditType } = this.state;
        const headers = {
            businesskey: clientDetail.businessId
        }

        apiGET(`/${creditType}/funds/view`, headers, (err, resp) => {
            try {
                this.setState({ loading: false });
                if (err) throw Error(err);
                const data = resp.data;
                console.log("/arfinancing/funds/view", data)
                if (data.success) {
                    this.setState({ availableFunds: data, overAdvanced: data.OverAdvanced });
                } else {
                    throw Error("We had some trouble. Please try again later.")
                }

                this.setState({ loading: false, attributesLoading: false });
            } catch (error) {
                alert(error.message);
            }
        })
    }

    fetchLoanApplication = () => {
        const { clientDetail } = this.props.location.state;
        var body = {
            BusinessId: clientDetail.businessId
        }
        console.log("loanApplication body", body);
        this.setState({ validateLoading: true });
        apiPOSTReq(`${environment.opsBaseUrl}/ops/loans/application/getLoanApplicationByBusinessId`, null, body, (err, resp) => {
            this.setState({ validateLoading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    console.log(data.loanApplication);
                    if (data.loanApplication) {
                        this.setState({ loanApp: data.loanApplication });
                    }
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    showCreditLimitHistory = () => {
        this.setState({ showCreditLimitHistory: true })
    }

    showViewDetails = () => {
        this.setState({ showDetails: true })
    }

    closeViewDetails = () => {
        this.setState({ showDetails: false })
    }

    handleFreezeline = () => {
        this.setState({ showFreezeLine: true })
    }

    handleCreditLimit = () => {
        this.setState({ showEditCreditLimit: true })
    }

    closeFreezeline = (isFrozen) => {
        var { clientDetail } = this.props.location.state;
        clientDetail.frozen = isFrozen;
        this.setState({ showFreezeLine: false })
        this.fetchBusinessData();
    }

    handleAdjustment = () => {
        this.setState({ showAdjustment: true })
    }

    closeAdjustment = () => {
        this.setState({ showAdjustment: false })
        this.fetchBusinessData();
    }

    closeLineSetting = () => {
        this.setState({ showEditCreditLimit: false })
        this.fetchBusinessData();
    }

    onTabChange = tab => {
        this.setState({ tab });
    };

    switchToBusiness(record) {
        // console.log("switchToBusiness", record);
        this.setState({ switchLoading: true, businessLoading: record });
        uaApiPOST("/aion/system/switchBusiness", { body: { businessId: record.businessId } }, (err, resp) => {
            try {
                var data = resp || {};
                if (err) throw Error(err);
                if (data.result) {
                    this.clearCache();
                    this.getAttributes();
                }
                else throw Error(data.error || data.responseMessage);
            } catch (error) {
                console.log("switchBusiness Err", error, err, resp);
                ErrorAlert({ description: error.message })
            }
        });
    }

    clearCache = () => {
        this.props.dispatch(resetStore(RESET_BNK_STATE))
        this.props.dispatch(resetStore(SOFT_RESET_MAIN_STATE))
        this.props.dispatch(resetStore(RESET_ONBOARDING_STATE))
        this.props.dispatch(resetStore(RESET_CREDIT_STATE))
        this.props.dispatch(resetStore(RESET_RECEIVABLES_STATE))
    }

    getAttributes() {
        // Check session and fetch attributes
        apiGET("/attributes", null, (err, resp) => {
            try {
                const data = resp.data;
                if (data.success) {
                    this.props.dispatch(completeSignin(data));
                    // Fetch Profile
                    this.fetchBusinessApps();
                } else {
                    throw Error("Could not fetch attributes.")
                }
            } catch (error) {
                console.log("/attributes ERR", error, err, resp)
            }
        })
    }

    fetchBusinessApps = () => {
        apiPOSTReq(`${environment.uamBaseUrl}/aion/system/getAvailableApplications`, {}, {}, (err, resp) => {
            try {
                if (err) throw Error(err);
                const data = resp || {};
                if (data.result) {
                    console.log("/aion/system/getAvailableApplications", data);
                    this.props.dispatch(addDataToStore(UAM_SAVE_PERMISSIONS, { BizAppNames: data.applicationNames || [] }));
                    this.fetchProfile();
                } else {
                    throw Error(data.responseMessage || data.error);
                }
            } catch (error) {
                console.log("/aion/system/getAvailableApplications err", error, resp);
                ErrorAlert({ description: error.message });
            }
        });
    }

    fetchProfile() {
        apiGET("/profile", null, (err, resp) => {
            try {
                const pData = resp.data || {};
                // this.setState({loading: false})
                if (pData.success) {
                    this.props.dispatch(addDataToStore(SAVE_DATA, { Profile: pData.profileInfo }));
                    var { BusinessInfo } = pData.profileInfo || {};
                    this.fetchPermissions()
                    this.fetchIntegration()
                    this.fetchSwitchBusinessData()
                    message.success(`Successfully switched to ${BusinessInfo.Name}`)
                    setTimeout(() => { window.location.reload() }, 1000);
                } else {
                    throw Error("Could not fetch profile.")
                }
            } catch (error) {
                console.log("ERRR", error.stack)
            }
        });
    }

    fetchPermissions = () => {
        // Fetch user permissions and adapt the layout based on the user's access
        getUserPermissions(null, (err, resp) => {
            try {
                if (err) throw Error(err)
                if (!resp.data.authToken) throw Error(resp.data.responseMessage)
                this.props.dispatch(addDataToStore(UAM_SAVE_PERMISSIONS, { UAM: resp.data }))
            } catch (error) {
                ErrorAlert({ description: error.message })
            }
        })
    }

    fetchIntegration = () => {
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/hasIntgration`, {}, {}, (err, resp) => {
            console.log("clear fetchIntegration", resp)
            try {
                if (err) throw Error(err)
                const data = resp || {}
                if (data.result) {
                    this.props.dispatch(addDataToStore(SAVE_DATA, { hasIntegration: data.hasIntegration || false }))
                    if (!data.hasIntegration) {
                        return
                    }

                    apiPOSTReq(`${environment.integrationBaseUrl}/integration/getCurrentSyncSW`, {}, {}, (err, resp) => {
                        try {
                            if (err) throw Error(err)
                            const data = resp || {}
                            if (data.result) {
                                this.props.dispatch(addDataToStore(SAVE_DATA, { CurrentSync: data.integration }))
                                this.props.dispatch(addDataToStore(SAVE_DATA, { hideNotification: data.hideNotification }))
                            } else {
                                throw Error(data.responseMessage || data.error)
                            }
                        } catch (error) {
                            console.log("/getCurrentSyncSW err", error, resp)
                        }
                    })
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/getCurrentSyncSW err", error, resp)
            }
        })
    }

    fetchSwitchBusinessData = (options) => {
        options = options || {};
        apiPOSTReq(`${environment.uamBaseUrl}/getBusiness`, {}, {}, (err, resp) => {
            try {
                if (err) throw Error(err);
                const data = resp || {};
                if (data.result) {
                    this.props.dispatch(addDataToStore(UAM_SAVE_BUSINESS, data));
                } else {
                    throw Error(data.responseMessage || data.error);
                }
            } catch (error) {
                console.log("/getBusiness err", error, resp);
            }
        });
    }

    onChangeTabSummary = (selectedTab, openVerification) => {
        this.setState({ openVerification: openVerification });
    }

    render() {
        const { theme } = this.props;
        const { clientDetail } = this.props.location.state;
        var { business, loading, productType, creditType, isFromBusinessAttribute, availableFunds, showDetails, tab, documentsCompKey, loanApp, switchLoading, businessLoading } = this.state;
        var { businessProfile, arAdvanceInfo, advanceInfo } = business || {};
        var { advanceLimit, fees, creditTaken, creditLeft } = arAdvanceInfo || {};
        advanceInfo = advanceInfo || {};
        var gnplCreditTaken = advanceInfo.creditTaken;
        var gnplCreditLeft = advanceInfo.creditLeft;
        const { AvailableFunds } = availableFunds || {};

        return (
            <FlexColumn className='main-padding'>
                {
                    switchLoading &&
                    <Flex start centerHorizontally gap='8px' style={{ margin: '20px 0' }}>
                        <LoadingOutlined style={{ color: theme.colors.secondary3, fontSize: "1.5rem" }} />
                        <Text color={theme.colors.primary2}>
                            Switching to {businessLoading.businessName.toUpperCase()}...
                        </Text>
                    </Flex>

                }
                {
                    !switchLoading && <Flex between fullWidth style={{ marginBottom: 48 }} gap='24px' stretch>
                        <PageHeader
                            back
                            backText='Control Center'
                            titleText={clientDetail.businessName.toUpperCase() + (clientDetail.referralCode ? " | Referral Code: " + clientDetail.referralCode : "")}
                        />
                        <FlexColumn style={{ marginTop: 0, width: "95%" }} grow>
                            <Skeleton loading={loading} active title={false} paragraph={{ rows: 6 }}>
                                <Flex start fullWidth gap='24px'>
                                    {
                                        productType == "RLOC" && <CardContainer style={{ height: 150, width: '33.3%' }} fullWidth center>
                                            <FlexColumn start gap='10px'>
                                                <Text weight='400' caption>Credit Limit</Text>
                                                <Text size='32px' height='40px'>${advanceLimit ? toCurrency(advanceLimit) : toCurrency(0)}</Text>
                                                {/*<Flex start fullWidth><a style={{ marginBottom: 10 }} onClick={this.showCreditLimitHistory}>
                                                <EyeOutlined style={{ marginRight: 3 }} />View History</a>
                                </Flex>*/}
                                            </FlexColumn>
                                        </CardContainer>
                                    }
                                    {
                                        productType == "GNPL" && <CardContainer style={{ height: 150, width: '33.3%' }} fullWidth center>
                                            <FlexColumn start gap='10px'>
                                                <Text weight='400' caption>Outstanding Balance</Text>
                                                <Text size='32px' height='40px'>${gnplCreditTaken ? toCurrency(gnplCreditTaken) : toCurrency(0)}</Text>
                                            </FlexColumn>
                                        </CardContainer>
                                    }
                                    {
                                        productType == "GNPL" && <CardContainer style={{ height: 150, width: '33.3%' }} fullWidth center>
                                            <FlexColumn start gap='10px'>
                                                <Text weight='400' caption>Available Credit</Text>
                                                <Text size='32px' height='40px'>${gnplCreditLeft ? toCurrency(gnplCreditLeft) : toCurrency(0)}</Text>
                                            </FlexColumn>
                                        </CardContainer>
                                    }
                                    {
                                        productType == "RLOC" && <CardContainer style={{ height: 150, width: '33.3%' }} fullWidth center>
                                            <FlexColumn start gap='10px'>
                                                <Text weight='400' caption>Daily Interest Rate</Text>
                                                <Text size='32px' height='40px'>{`${isFromBusinessAttribute ? parseFloat((fees)).toFixed(4) : parseFloat((fees / 30).toFixed(4))}%`}</Text>
                                            </FlexColumn>
                                        </CardContainer>
                                    }
                                    {
                                        productType == "RLOC" && <CardContainer style={{ height: 150, width: '33.3%' }} fullWidth center>
                                            <FlexColumn start gap='10px'>
                                                <Text weight='400' caption>Outstanding Balance</Text>
                                                <Text size='32px' height='40px'>{creditTaken ? `$${toCurrency(creditTaken)}` : `$${toCurrency(0)}`}</Text>
                                            </FlexColumn>
                                        </CardContainer>
                                    }
                                    {
                                        productType == "RLOC" && <CardContainer style={{ height: 150, width: '33.3%' }} fullWidth center>
                                            <FlexColumn start gap='10px'>
                                                <Text weight='400' caption>Availability</Text>
                                                <Text size='32px' height='40px'>{`$${toCurrency(AvailableFunds)}`}</Text>
                                                <TextButton uppercase rightIcon={<Image src={ArrowRight} />} onClick={this.showViewDetails} text="VIEW DETAILS" />
                                            </FlexColumn>
                                        </CardContainer>
                                    }
                                    <CardContainer style={{ height: 150, width: '33.3%' }} fullWidth center>
                                        <FlexColumn start gap={productType == "RLOC" ? '0px' : '5px'}>
                                            <Text weight='400' caption>Quick Actions</Text>
                                            <Flex start fullWidth>
                                                <TextButton onClick={this.handleFreezeline} rightIcon={<EditOutlined />} text="Edit Line Status" />
                                            </Flex>
                                            <Flex start fullWidth>
                                                <TextButton onClick={this.handleCreditLimit} rightIcon={<EditOutlined />} text="Edit Credit Limit" />
                                            </Flex>
                                            {
                                                productType == "RLOC" && <Flex start fullWidth>
                                                    <TextButton onClick={this.handleAdjustment} rightIcon={<PlusOutlined />} text="Add Adjustment" />
                                                </Flex>
                                            }
                                            <Flex start fullWidth>
                                                <TextButton text='Switch to this Business' onClick={(event) => {
                                                    this.switchToBusiness(clientDetail);
                                                    event.stopPropagation();
                                                }
                                                } rightIcon={<Image src={Switch} />} />
                                            </Flex>
                                        </FlexColumn>
                                    </CardContainer>
                                </Flex>
                            </Skeleton>
                            <FlexColumn>
                                <Flex start style={{ width: '100%', marginTop: 20 }}>
                                    <Tabs size="large" style={{ width: '100%' }} activeKey={tab} onChange={this.onTabChange} >
                                        {
                                            productType != "GNPL" &&
                                            <TabPane tab="Line History" key="LineHistory" />
                                        }
                                        <TabPane tab="Advances" key="Advances" />
                                        <TabPane tab="Bank Accounts" key="Payments" />
                                        {
                                            productType != "GNPL" &&
                                            <TabPane tab="Payers" key="Payers" />
                                        }
                                        {
                                            productType != "GNPL" && creditType == "assetbasedloan" &&
                                            <TabPane tab="AR Upload" key="ARUpload" />
                                        }
                                        {
                                            productType != "GNPL" && creditType == "assetbasedloan" &&
                                            <TabPane tab="Inventory Update" key="Inventory" />
                                        }
                                        <TabPane tab="Invoices" key="Invoices" />
                                        <TabPane tab="External Bank Connections" key="External" />
                                        {
                                            productType != "GNPL" && <TabPane tab="Collateral Reports" key="CollateralReports" />
                                        }
                                        <TabPane tab="Documents" key="Documents" />
                                        {loanApp && <TabPane tab="Contracts" key="Contracts" />}
                                        <TabPane tab="Internal Notes" key="Notes" />
                                        {loanApp && <TabPane tab="Profile" key="Profile" />}
                                    </Tabs>
                                </Flex>
                                {
                                    (tab == "Profile") &&
                                    <Summary loanApp={loanApp} changeTabClick={this.onChangeTabSummary} />
                                }
                                {
                                    (tab == "LineHistory") &&
                                    <LineHistory businessId={clientDetail.businessId}></LineHistory>
                                }
                                {
                                    (tab == "Advances") &&
                                    <Advances businessId={clientDetail.businessId} name={clientDetail.businessName} productType={productType} />
                                }
                                {
                                    (tab == "Payments") &&
                                    <Payments business={business} />
                                }
                                {
                                    (tab == "Payers") &&
                                    // <CardContainer fullWidth margin='24px 0 32px'>
                                    <Payers businessId={clientDetail.businessId} />
                                    // </CardContainer>
                                }
                                {
                                    (tab == "ARUpload") &&
                                    <ARUpload businessId={clientDetail.businessId} creditType={creditType} />
                                }
                                {
                                    (tab == "Inventory") &&
                                    <Inventory businessId={clientDetail.businessId} creditType={creditType} />
                                }
                                {
                                    (tab == "Invoices") &&
                                    <CardContainer fullWidth margin='24px 0 32px'>
                                        <Invoices businessId={clientDetail.businessId} />
                                    </CardContainer>
                                }
                                {
                                    (tab == "External") &&
                                    // <CardContainer fullWidth margin='24px 0 32px'>
                                    <ExternalConnection businessId={clientDetail.businessId} />
                                    // </CardContainer>
                                }
                                {
                                    (tab == "CollateralReports") &&
                                    // <CardContainer fullWidth margin='24px 0 32px'>
                                    <Documents businessId={clientDetail.businessId} type={"CollateralReports"} />
                                    // </CardContainer>
                                }
                                {
                                    (tab == "Documents") &&
                                    <Documents businessId={clientDetail.businessId} type={"Documents"} />
                                }
                                {
                                    (tab == "Contracts") &&
                                    <CardContainer fullWidth margin='24px 0 32px'>
                                        <Contracts businessId={clientDetail.businessId} productType={productType} loanApp={loanApp} />
                                    </CardContainer>
                                }
                                {
                                    (tab == "Notes") &&
                                    <CardContainer fullWidth margin='24px 0 32px'>
                                        <Note key={documentsCompKey} loanApp={loanApp} />
                                    </CardContainer>
                                }
                            </FlexColumn>
                        </FlexColumn>
                    </Flex >
                }
                <Modal
                    visible={showDetails}
                    footer={false}
                    style={{ top: 20 }}
                    destroyOnClose={true}
                    width={550}
                    closable={true}
                    onCancel={() => { this.closeViewDetails() }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    {
                        <AvailableFundsModal businessId={clientDetail.businessId} productType={creditType} availableFunds={availableFunds} closeAvailableFunds={this.closeViewDetails} />
                    }
                </Modal>
                <Modal
                    visible={this.state.showAdjustment}
                    footer={null}
                    closable={true}
                    destroyOnClose={true}
                    width={700}
                    style={{ top: 20 }}
                    onCancel={() => { this.setState({ showAdjustment: false }) }}
                >
                    <Adjustment businessId={clientDetail.businessId} businessAttributes={this.state.businessAttributes} customerAttributes={this.state.customerAttributes} arAdvanceInfo={arAdvanceInfo} closeAdjustment={this.closeAdjustment} store={this.props.store}></Adjustment>
                </Modal>
                <Modal
                    visible={this.state.showFreezeLine}
                    footer={null}
                    closable={true}
                    destroyOnClose={true}
                    width={700}
                    style={{ top: 20 }}
                    onCancel={() => { this.setState({ showFreezeLine: false }) }}
                >
                    <FreezeLine businessId={clientDetail.businessId} closeFreezeline={this.closeFreezeline} store={this.props.store} isWatch={false} isCollection={false} isFrozen={clientDetail.frozen}></FreezeLine>
                </Modal>
                <Modal
                    visible={this.state.showEditCreditLimit}
                    footer={null}
                    closable={true}
                    destroyOnClose={true}
                    width={700}
                    style={{ top: 20 }}
                    onCancel={() => { this.setState({ showEditCreditLimit: false }) }}
                >
                    <LineSetting businessId={clientDetail.businessId} arAdvanceInfo={productType == "RLOC" ? arAdvanceInfo : advanceInfo} closeLineSetting={this.closeLineSetting} store={this.props.store}></LineSetting>
                </Modal>
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)));