import React, { useEffect } from 'react';
import { withTheme } from 'styled-components';
import { Skeleton } from 'antd';

import { Tag, Text } from './Text';
import { FlexColumn, Flex } from './Container';
import PageBackArrow from '../../Images/page-back-arrow.png'
import ModalClose from '../../Images/x.svg'
import { connect } from 'react-redux';
import { UPDATE_PG_TITLE, addDataToStore } from '../../Actions/actions';

const PageHeader = (props) => {
    const { loading, title, titleText, titleTag, ctaContent, subtitle, subtitleColor, back, onBack, close, marginTop, 
        marginBottom, noMargin, marginLeft, ctaSubContent, marginRight, titleComponent, onClose, store, fullpage } = props;
    
    const { pageTitleText } = store
    
    useEffect(() => {
        if(!fullpage) props.dispatch(addDataToStore(UPDATE_PG_TITLE, { pageTitleText: titleText, navProps: { back, onBack } }))
    }, [typeof(titleText) == 'string' ? titleText : null])
    
    // Non fullpage PAGE HEADER is handled by TopNav component
    if(!fullpage) return null
    return (
        <FlexColumn {...props} style={{ marginLeft: marginLeft ? marginLeft : noMargin ? 0 : 0, marginRight: marginRight ? marginRight : noMargin ? 0 : 0, marginTop: marginTop ? marginTop : noMargin ? 0 : 12, marginBottom: marginBottom ? marginBottom : noMargin ? 0 : 24 }}>
            <Skeleton loading={loading} active title={true} paragraph={{ rows: 1, width: 100 }}>
                <Flex between centerHorizontally>
                    <FlexColumn>
                        {
                            back &&
                            <Flex start centerHorizontally style={{ zIndex: 2, marginBottom: 8, cursor: 'pointer' }} onClick={() => {
                                if (onBack) {
                                    onBack()
                                } else {
                                    window.history.back()
                                }
                            }}>
                                <img height='20px' width='20px' src={PageBackArrow} />
                                <Text size='12px' color='#1199FF' margin='2px 0 0 5px'>Back</Text>
                            </Flex>
                        }
                        {
                            subtitle &&
                            <Text style={{ marginBottom: 4 }} color={subtitleColor && subtitleColor}>{subtitle}</Text>
                        }

                        <Flex start centerHorizontally gap='8px'>
                            <Text weight='500' id='title' size='28px' height='40px'>{title || titleText}</Text>
                            {
                                titleTag &&
                                <Tag bordered color={titleTag.color} background={titleTag.background}>{titleTag.text}</Tag>
                            }
                            {
                                titleComponent &&
                                <Flex start centerHorizontally style={{ marginLeft: 16 }}>{titleComponent}</Flex>
                            }
                        </Flex>
                        <Flex start centerHorizontally gap='8px'>
                            {ctaSubContent || null}
                        </Flex>
                    </FlexColumn>
                    <Flex end centerHorizontally style={{ marginTop: (ctaContent && subtitle) ? 24 : 0 }}>
                        {ctaContent || null}
                        {close ? <img style={{ cursor: 'pointer' }} width='14px' height='14px' src={ModalClose} onClick={() => onClose ? onClose() : window.history.back()} /> : null}
                    </Flex>
                </Flex>
            </Skeleton>
        </FlexColumn>
    )
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(PageHeader));