import React, { Component } from 'react';
import { connect } from 'react-redux';
import { saveAs } from 'file-saver'
import styled, { withTheme } from 'styled-components';
import { message, Table, Upload } from 'antd';
import { Flex, FlexColumn, } from '../../../Reusable/Refresh/Container';
import Bowser from "bowser"

import FlowViewComponent from '../../../Reusable/Refresh/FlowViewComponent';
import { Text } from '../../../Reusable/Refresh/Text';
import { Image } from '../../../Reusable/Image';
import { TextButton } from '../../../Reusable/Refresh/Button';
import { LabeledInput } from '../../../Reusable/Refresh/Input';
import environment from '../../../../environment';
import { apiPOSTReq, getPreSignedS3Url } from '../../../../Utils/api';
import { ErrorAlert } from '../../../Reusable/Alert';
import { CloseOutlined, FilePdfOutlined } from '@ant-design/icons';
import DownloadIcon from '../../../../Images/download.png';

// Actions
import { addDataToStore, REMOTE_SAVE_DATA } from '../../../../Actions/actions';
import { OwnserIndividualCard } from '../../../Reusable/Refresh/Card';



class OwnershiptInformation extends Component {

    state = {
        submitLoading: false,
        creditOnboarding: (this.props.loanApplication || {}).applicationId,
        selectedOwner: {},
        ownerDocs: (this.props.proposalTerms.creditLimit > 100000 ? [
            { name: "License" },
            { name: "PFS" },
            { name: "2021 Tax Returns" },
            { name: "2022 Tax Returns" }
        ] :
            [
                { name: "License" }
            ]),
        beneficialOwners: []
    }

    componentDidMount() {
        const { store } = this.props;
        //this.fetchBusinessData();
        this.setState({ beneficialOwners: this.fetchOwners() })
        this.fetchDocuments();
        this.checkBrowser()
    }
    fetchOwners = () => {
        var { store, isCreditPlus, onboardingStore } = this.props;
        var { business } = store;
        var businessProfile = isCreditPlus ? onboardingStore.businessProfile : (business || {}).businessProfile || {};
        var coAppInfo = businessProfile.coOwnerApplicantInfo || [];
        var beneficialOwners = [];
        if (businessProfile.primaryApplicantInfo != undefined) {
            if (Number(businessProfile.primaryApplicantInfo.ownershipPercentage) >= 25) {
                beneficialOwners.push(businessProfile.primaryApplicantInfo)
            }
        }
        if (isCreditPlus) {
            coAppInfo = this.props.onboardingStore.coOwnersInfo
        }
        if (coAppInfo.length > 0) {
            var filteredcoOwners = coAppInfo.filter(owner => Number(owner.ownershipPercentage) >= 25)

            if (filteredcoOwners.length > 0) {
                filteredcoOwners.map(owner => beneficialOwners.push(owner));
            }
        }
        return beneficialOwners;
    }
    fetchBusinessData = () => {
        var body = {
            "clientBusinessId": this.props.store.BusinessUniqueKey
        }

        console.log("/business/admin/getBusinessData body", body)
        this.setState({ loading: true })
        apiPOSTReq(`${environment.uamBaseUrl}/business/admin/getBusinessData`, {}, body, (err, resp) => {
            this.setState({ loading: false })
            try {
                const data = resp || {}
                if (data.result) {
                    this.setState({
                        business: data.business
                    })
                    var { business } = this.state;
                    var businessProfile = (business || {}).businessProfile || {};
                    var coOwnerApplicantInfo = businessProfile.coOwnerApplicantInfo || [];
                    var beneficialOwners = [];
                    if (businessProfile.primaryApplicantInfo != undefined) {
                        coOwnerApplicantInfo.push(businessProfile.primaryApplicantInfo);
                    }
                    if (coOwnerApplicantInfo.length > 0) {
                        beneficialOwners = coOwnerApplicantInfo.filter(owner => Number(owner.ownershipPercentage) >= 25)
                    }
                    this.setState({ beneficialOwners: beneficialOwners });
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp)
                // ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    setErrorField = (id, msg) => this.setState({ errorField: id, errorMessage: msg });

    validateFlow = () => {
        var { flowView } = this.props;
        let notUploaded = [];
        var upload = this.state.ownerDocs.find(doc => {
            let owners = this.state.beneficialOwners || [];

            owners.map(coOwner => {
                if (!this.getDocument(doc.name + "-" + coOwner.email).fileName) {
                    notUploaded.push(true);
                }
            })
        });
        if (notUploaded.includes(true)) {
            ErrorAlert({ description: "Please upload all documents" });
            return;
        }
        //this.props.dispatch(addDataToStore(REMOTE_SAVE_DATA, flowView.FlowStep));
        this.props.loadNext();
    }
    fetchDocuments = () => {
        const { loanApplication } = this.state;
        var body = {
            "sortDirection": "DESC"
        };
        if (loanApplication) {
            body["clientBusinessId"] = loanApplication.businessId;
            body["applicationId"] = loanApplication.applicationId;
            body["viewBy"] = "FILTER_APPLICATION"
        }
        console.log("/docs/getActiveDocuments body", body, loanApplication);
        this.setState({ loading: true });
        apiPOSTReq(`${environment.uamBaseUrl}/docs/getActiveDocuments`, {}, body, (err, resp) => {
            console.log("/docs/getActiveDocuments resp", resp);
            this.setState({ loading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    this.setState({
                        businessDocuments: data.businessDocuments
                    });
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    handleDocSubmit = (uploadItem) => {
        var { loadingItem } = uploadItem || {};
        var { selectedOwner } = this.state;
        var { loanApplication } = this.props;
        var businessDocument = {
            "category": "Owner Financials",
            "name": uploadItem.name + "-" + selectedOwner.email,
            "documentUrl": uploadItem.savedFile || {}
        };
        if (loanApplication) businessDocument.applicationId = loanApplication.applicationId;
        if (uploadItem.effectivePeriod) businessDocument.effectivePeriod = uploadItem.effectivePeriod
        var body = {
            "businessDocument": businessDocument
        }
        console.log("handleSubmit DOC/SAVE uploadItem body", uploadItem, body);
        this.setState({ submitLoading: true, loadingItem: loadingItem });
        apiPOSTReq(`${environment.uamBaseUrl}/docs/save`, {}, body, (err, resp) => {
            this.setState({ submitLoading: false, loadingItem: null });
            try {
                const data = resp || {};
                if (data.result) {
                    message.success(`File saved successfully.`, 0.75);
                    this.fetchDocuments();
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/docs/save err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    uploadProps = (doc, selectedOwner, ownerDocName) => {
        return {
            name: `business-docs`,
            action: `${environment.apiBaseUrl}/file/upload`,
            headers: {
                businesskey: this.props.store.BusinessUniqueKey,
                jwt: this.props.store.jwt
            },
            beforeUpload: (doc) => {
                const isPDF = doc.type === 'application/pdf';
                const isXLSX = doc.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                const isJPEG = doc.type === 'image/jpeg';
                const isPNG = doc.type === 'image/png';
                if ((ownerDocName === "License") && !isJPEG && !isPDF && !isPNG) {
                    message.error(`${ownerDocName} is not a pdf/png/jpeg file`);
                    return isPDF || isJPEG || isPNG || Upload.LIST_IGNORE;
                } else if ((ownerDocName != "License") && !isXLSX && !isPDF) {
                    message.error(`${ownerDocName} is not a pdf/xlsx file`);
                    return isPDF || isXLSX || Upload.LIST_IGNORE;
                }
            },
            onChange: (info) => {
                var { fileList } = info;
                var savedFile = {};
                fileList.forEach(file => {
                    const { status, response, name, url } = file;
                    if (status === "done") {
                        savedFile = {
                            fileName: name,
                            uri: (response.UploadedUrls || [])[0],
                        }
                        if (doc.type) savedFile.type = doc.type;
                        // message.success(`${info.file.name} file uploaded successfully.`, 0.75);
                        doc.savedFile = savedFile;
                        this.handleDocSubmit(doc);
                    } else if (status === 'error') {
                        message.error(`${info.file.name} file upload failed.`);
                    }
                });
                if (((Object.keys(savedFile)).length) !== 0) {
                    doc.savedFile = savedFile;
                }
                this.setState({ [doc.name]: doc });
            },
            onClick: () => this.setState({ selectedOwner: selectedOwner }),
            onRemove: (file) => {
                this.handleDeleteDoc(doc);
            }
        }
    };
    checkBrowser = () => {
        const browser = Bowser.getParser(window.navigator.userAgent)
        const browserName = browser.getBrowserName()
        if (!(browserName.toLowerCase().includes("chrome") || browserName.toLowerCase().includes("safari") || browserName.toLowerCase().includes("edge"))) {
            this.setState({ unsupported: true })
        }
    }

    getDocument = (name, options) => {
        options = options || {};
        var { businessDocuments } = this.state;
        var savedDoc = this.state[name] || {};
        var filteredDoc = (businessDocuments || []).find(item => (item.name == name));
        if (filteredDoc != undefined) {
            // Replace with remotely stored data
            if (options.businessDocument) savedDoc = filteredDoc;
            else savedDoc = filteredDoc.documentUrl || {};
        }
        return savedDoc;
    }
    handleDeleteDoc = (uploadItem) => {
        var { loanApplication } = this.props;
        var businessDocument = {
            "category": "Owner Financials",
            "name": uploadItem.name
        };

        if (loanApplication) businessDocument.applicationId = loanApplication.applicationId;
        var body = {
            "businessDocument": businessDocument,
            "documentId": uploadItem.documentId
        }

        console.log("handleSubmit DOC/delete uploadItem body", body);
        this.setState({ submitLoading: true });
        apiPOSTReq(`${environment.uamBaseUrl}/docs/delete`, {}, body, (err, resp) => {
            this.setState({ submitLoading: false, loadingItem: null });
            try {
                const data = resp || {};
                if (data.result) {
                    message.success(`File Deleted successfully.`, 0.75);
                    this.fetchDocuments();
                    let doc = this.state[uploadItem.name] || {};
                    doc.savedFile = {};
                    this.setState({ [doc.name]: doc });
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/docs/deleteUpload err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    getColumns = () => {
        const { theme, reviewView } = this.props;
        let { ownerDocs, unsupported } = this.state;
        var columns = [];
        columns = [
            {
                title: 'Individual',
                render: (item) => <div >
                    <OwnserIndividualCard width="100" padding='12px 0' ownerInfos={item} theme={theme} symbolprefix="%" onClick={() => this.setState({ showOwnerModal: true, selectedOwner: item })} />
                </div>,
                width: 200
            }
        ];
        let ownerDocColumns = [];
        ownerDocs.map(doc => {
            ownerDocColumns.push({
                title: doc.name,
                width: unsupported ? 250 : 320,
                render: (item) => <div >
                    {reviewView ? <><TextButton preLine size="14px" style={{ textAlign: "left", padding: "8px 8px" }} type="link" icon={<FilePdfOutlined />}
                        text={this.getDocument(doc.name + "-" + item.email).fileName}></TextButton>
                    </> : <>
                        {this.getDocument(doc.name + "-" + item.email).fileName ?
                            <></>
                            : <LabeledInput
                                {...this.uploadProps(doc, item, doc.name)}
                                type='upload'
                            />
                        }
                        {this.getDocument(doc.name + "-" + item.email).fileName ?
                            <><TextButton preLine style={{ textAlign: "left", padding: "8px 8px" }} type="link" icon={<FilePdfOutlined />}
                                rightIcon={<CloseOutlined onClick={() => this.handleDeleteDoc(this.getDocument(doc.name + "-" + item.email, { businessDocument: true }))} />}
                                text={this.getDocument(doc.name + "-" + item.email).fileName}></TextButton>
                            </> : <></>
                        }</>


                    }
                </div>
            }
            )

        });
        ownerDocColumns.map(col => columns.push(col));

        return columns;

    }

    downloadStatement(uri, name) {
        return new Promise((resolve, reject) => {
            getPreSignedS3Url({ url: uri }, (err, preSignedUrl) => {
                if (!err) {
                    saveAs(preSignedUrl, name);
                } else {
                    reject(JSON.stringify(err))
                }
            })
        })
    }
    render() {
        var { flowView, currentViewIndex, loadPrevView, loadNext, flowSteps, onboardingStore, theme, loanApplication, applicationStep, businessInfo, reviewView, reviewEditMode, store } = this.props
        flowSteps = flowSteps || []
        flowView = flowView || {}
        var { beneficialOwners } = this.state;
        var childViews = (
            <FlexColumn start style={{ marginTop: "0px", minHeight: 600 }}>
                <Flex start fullWidth gap='24px'>
                    <FlexColumn style={{ width: "100%", margin: "12px 0 24px" }}>
                        <Flex noMargin row between>
                            <FlexColumn style={{ width: `75%`, flexDirection: "column" }} >
                                <Text margin="0px 0px 4px 0px" spacing="0.08em" weight='500' color='#7384AA' >OWNER INFORMATION</Text>
                                <Text size="16px" width="75%">Provide a driver’s license {this.props.proposalTerms.creditLimit > 100000 ? 'PFS and tax returns' : null} for the below individuals</Text>
                            </FlexColumn>
                            {this.props.proposalTerms.creditLimit > 100000 ?
                                <TextButton style={{ textAlign: "left", padding: "8px 8px" }} type="link" icon={<img width='24px' height='24px' src={DownloadIcon} />}
                                    onClick={() => this.downloadStatement("https://s3-us-west-1.amazonaws.com/fpb-business-documents/templates/PersonalFinancialStatement_AION_fillable.pdf")} text={"PFS Template"}></TextButton>
                                : null
                            }
                        </Flex>
                    </FlexColumn>
                </Flex>
                <Flex start fullWidth gap='24px'>

                    <Table
                        id="todo-table"
                        key="todo-table"
                        className='borderless-table'
                        tableLayout='auto'
                        columns={this.getColumns()}
                        dataSource={beneficialOwners || []}
                        rowKey='objectId'
                        pagination={false}
                        scroll={{ x: '75%' }}
                    />
                </Flex>

            </FlexColumn>

        )

        if (reviewView) {
            return (
                <FlexColumn start style={{ marginTop: "0px", minHeight: '100%' }}>
                    <Flex start fullWidth style={{ width: "-webkit-fill-available" }}>
                        <FlexColumn style={{ width: "100%", margin: "12px 0 24px" }}>
                            <Flex noMargin row between>
                                <FlexColumn style={{ width: `75%`, flexDirection: "column" }} >
                                    <Text margin="0px 0px 4px 0px" spacing="0.08em" weight='500' color='#7384AA' >OWNER INFORMATION</Text>
                                </FlexColumn>
                            </Flex>
                        </FlexColumn>
                    </Flex>
                    <Flex start fullWidth style={{ width: "-webkit-fill-available" }}>
                        <Table
                            id="owner-financials-table"
                            className="row-pointer borderless-table"
                            tableLayout='auto'
                            columns={this.getColumns()}
                            dataSource={beneficialOwners}
                            rowKey='id'
                            style={{ minHeight: "100px" }}
                            pagination={false}
                            scroll={{ x: '100%' }}
                        />
                    </Flex>

                </FlexColumn>
            )
        }
        return (
            <FlowViewComponent
                flowView={flowView}
                currentViewIndex={currentViewIndex}
                loadPrev={loadPrevView}
                childViews={childViews}
                submitLoading={this.state.submitLoading}
                width={'100%'}
                back={currentViewIndex > 0}
                padding='0 64px'
                {...this.props}
                loadNext={this.validateFlow}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer,
        creditStore: state.creditAppReducer,
        onboardingStore: state.onboardingAppReducer,

    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(OwnershiptInformation));