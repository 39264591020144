import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'

import { message, Select } from 'antd'

// Components
import { Button } from '../../Reusable/Button'
import { Text } from '../../Reusable/Text'
import { FlexColumn, Flex } from '../../Reusable/Container'
import { LabeledInput } from '../../Reusable/Input'
import { ErrorAlert } from '../../Reusable/Alert'
import environment from '../../../environment'
import { FromAccountOption } from '../../Reusable/Option'
import { apiPOSTReq } from '../../../Utils/api'
import { addressObjectToStr, toCurrency, getBPAccountsWithAccess } from '../../../Utils/util'
import AionIcon from '../../../Images/aion-bank-icon.png'

const { Option } = Select

class AddPaymentMethod extends Component {

    constructor(props) {
        super(props)

        var fromAccount

        if (this.props.pm && this.props.pm.accountId) {
            const Accounts = getBPAccountsWithAccess(null, { resourceStr: "Payables.Vendors", permType: "manage", getActiveAccounts: true })
            fromAccount = Accounts.find(account => account.accountId === this.props.pm.accountId)
        }

        this.state = {
            pmId: this.props.pm ? this.props.pm.id : null,
            paymentMethod: this.props.pm ? this.props.pm.type : "ACH",
            accountType: this.props.pm ? this.props.pm.bankDetail.accountType.ca : "Checking",
            accountNumber: this.props.pm ? this.props.pm.bankDetail.accountNumber : "",
            routingNumber: this.props.pm ? this.props.pm.bankDetail.routingNumber : "",
            fromAccount: this.props.pm && fromAccount,
            checkAddress: this.props.pm ? this.props.pm.bankDetail.bankAddress : null,
            createcontractorLoading: false,
            contractor: props.contractor,
            loading: false,
        }
    }

    saveInput = (id, dataToSave) => {
        this.setState({ [id]: dataToSave })
    }

    handleAccountSelect = (value, option) => {
        const Accounts = getBPAccountsWithAccess(null, { resourceStr: "Payables.Vendors", permType: "manage", getActiveAccounts: true })
        var fromAccount = Accounts.find(account => account.accountId === value)
        this.setState({ fromAccount })
    }

    handleTextChange = (event) => {
        const target = event.target
        this.saveInput(target.id || target.name, target.value)
    }

    getCheckLocation = (location, formattedAddress) => {
        console.log("getCheckLocation", location)

        if (!location)
            this.setState({
                checkAddress: {
                    // $set: {
                    line1: null,
                    line2: null,
                    city: null,
                    countrySubDivisionCode: null,
                    state: null,
                    postalCode: null
                    // }
                }
            })
        else
            this.setState({
                checkAddress: {
                    // $set: {
                    line1: location.line1,
                    line2: location.line2,
                    city: location.city,
                    countrySubDivisionCode: location.countrySubDivisionCode,
                    state: location.state,
                    postalCode: location.postalCode
                    // }
                }
            })
    }

    saveACHPayment = (successMessage) => {
        const { contractor, fromAccount } = this.state

        var body = {
            "pmId": this.state.pmId,
            "contractorId": contractor.id,
            "accountId": fromAccount.accountId,
            "type": contractor.type,
            "bankDetail": {
                "accountName": contractor.contractorName || "",
                "accountNumber": this.state.accountNumber || "",
                "routingNumber": this.state.routingNumber || "",
                "accountType": (this.state.accountType || "").toLowerCase()
            },
        }

        apiPOSTReq(`${environment.payBaseUrl}/payables/contractors/addTransferDelivery`, {}, body, (err, resp) => {
            this.setState({ createcontractorLoading: false })
            try {
                if (err) throw Error(err)
                const data = resp || {}
                if (data.result) {
                    message.success(successMessage)
                    body.type = "ACH"
                    contractor.deliveryMode = "ACH"
                    this.props.onSave(contractor, body)
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/createCounterparty err", error, resp, body)
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
            this.setState({ loading: false })
        })
    }

    saveCheckPayment = (successMessage) => {
        var { contractor, checkAddress, fromAccount } = this.state
        var { line1, line2, city, state, postalCode, countrySubDivisionCode } = checkAddress

        var body = {
            "pmId": this.state.pmId,
            "contractorId": contractor.id,
            "accountId": fromAccount.accountId,
            "bankDetail": {
                "bankAddress": {
                    "line1": line1,
                    "line2": line2,
                    "city": city,
                    "state": countrySubDivisionCode,
                    "postalCode": postalCode
                }
            },
        }

        console.log("/addCheckDelivery body", JSON.stringify(body))

        apiPOSTReq(`${environment.payBaseUrl}/payables/contractors/addCheckDelivery`, {}, body, (err, resp) => {
            console.log("/addCheckDelivery err, resp", err, resp)
            this.setState({ createcontractorLoading: false })
            try {
                const data = resp || {}
                if (data.result) {
                    message.success(successMessage)
                    body.type = "Check"
                    contractor.deliveryMode = "Check"
                    this.props.onSave(contractor, body)
                } else {
                    throw Error(data.error || data.responseMessage)
                }
            } catch (error) {
                console.log("/addCheckDelivery err", error)
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
            this.setState({ loading: false })
        })
    }

    savePayment = () => {
        var { paymentMethod, accountNumber, routingNumber, checkAddress, fromAccount } = this.state

        if (!fromAccount) {
            ErrorAlert({ description: "Please select an account." })
            return
        }

        if (paymentMethod === "ACH") {
            if (!accountNumber) {
                ErrorAlert({ description: "Please enter an account number." })
                return
            }

            if (accountNumber && (isNaN(accountNumber) || accountNumber.length > 17)) {
                ErrorAlert({ description: "Please enter a valid account number. It can have a maximum of 17 characters." })
                return
            }

            if (!routingNumber) {
                ErrorAlert({ description: "Please enter a routing number." })
                return
            }

            if (routingNumber && (isNaN(routingNumber) || routingNumber.length != 9)) {
                ErrorAlert({ description: "Please enter a valid 9-digit routing number." })
                return
            }
        } else if (paymentMethod === "Check") {
            if (!checkAddress) {
                ErrorAlert({ description: "Check address is required. Please enter a valid address." })
                return
            }

            var { line1, city, state, postalCode } = checkAddress
            if (!line1 || !city || !state || !postalCode) {
                ErrorAlert({ description: "Check address is required. Please enter a valid address. 2" })
                return
            }
        }

        this.setState({ loading: true })

        if (paymentMethod === "ACH") {
            this.saveACHPayment("Payment added successfully!")
        } else if (paymentMethod === "Check") {
            this.saveCheckPayment("Payment added successfully!")
        }
    }

    render() {
        const { paymentMethod, accountType, checkAddress, fromAccount, loading } = this.state
        const Accounts = getBPAccountsWithAccess(null, { resourceStr: "Payables.Vendors", permType: "manage", getActiveAccounts: true })

        var accountOptions = []
        var filteredAccounts = Accounts.filter(item => { return (item.accountType !== 'ACCOUNTS_RECEIVABLE') }) || []
        filteredAccounts.forEach(account => {
            accountOptions.push(
                <Option key={account.accountId} value={account.accountId} style={{ backgroundColor: "transparent" }}>
                    <FromAccountOption account={account} />
                </Option>
            )
        })


        return (
            <FlexColumn style={{ width: "100%" }} between>
                <FlexColumn style={{ height: "100%", justifyContent: "flex-start" }}>
                    <Text size='20px'>Add payment method</Text>

                    <LabeledInput
                        label="Bank Account"
                        showAction={'focus'}
                        type="select"
                        key="fromAccount"
                        className="no-left-padding"
                        instruction="We will use this account for your payments to this contractor"
                        placeholder="From account"
                        onChange={this.handleAccountSelect}
                        value={fromAccount && <><img width='16px' height='16px' src={AionIcon} style={{ marginRight: 8, marginBottom: 2 }} /><Text>{`${(fromAccount.nickName || `Business ${fromAccount.accountSubType}`)} • ${fromAccount.mask}`}</Text></>}
                    >
                        {accountOptions}
                    </LabeledInput>

                    <Text margin="10px 0 0 0">Contractor Payment Account Details</Text>

                    <LabeledInput
                        label="Payment Method"
                        type='switch'
                        switchNames={['ACH', 'Check']}
                        onChange={(value) => this.saveInput("paymentMethod", value)}
                        value={paymentMethod} />

                    {
                        paymentMethod === "ACH" &&
                        <>
                            <LabeledInput
                                autoFocus
                                label="Account Number"
                                name="accountNumber"
                                key="accountNumber"
                                value={this.state.accountNumber}
                                placeholder="xxxxxxxx"
                                onChange={this.handleTextChange}
                                maxLength={17}
                                instruction="Maximum of 17 characters"
                            />
                            <LabeledInput
                                autoFocus
                                label="Routing Number"
                                name="routingNumber"
                                key="routingNumber"
                                value={this.state.routingNumber}
                                placeholder="052001633"
                                onChange={this.handleTextChange}
                                maxLength={9}
                                instruction="9 digit routing number"
                            />

                            <LabeledInput
                                label="Account Type"
                                type='switch'
                                switchNames={['Checking', 'Savings']}
                                onChange={(value) => this.saveInput("accountType", value)}
                                value={accountType} />
                        </>
                    }

                    {
                        paymentMethod === "Check" &&
                        <LabeledInput
                            id="checkAddress"
                            key="checkAddress"
                            label="Check Address"
                            type="location"
                            getLocation={this.getCheckLocation}
                            Line2
                            address={checkAddress}
                            value={checkAddress && checkAddress.line1 ?
                                { line1: addressObjectToStr(checkAddress), line2: checkAddress.line2 } : null
                            }
                            placeholder="Address"
                            instruction="We'll mail a check to this address free of cost"
                        />
                    }
                </FlexColumn>
                <Flex start style={{ width: "100%", marginTop: 24 }}>
                    <Button permtype="Payables.Contractors" solid loading={loading} onClick={() => this.savePayment()} text='Submit' />
                </Flex>
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        bkStore: state.bookkeepingAppReducer,
        aionStore: state.aionAppReducer,
        bankingStore: state.bankingAppReducer,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(AddPaymentMethod))