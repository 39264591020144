import React from 'react';
import { Divider, theme } from 'antd';
import { FlexColumn, Flex, LightContainer } from '../Container';
import { RefreshContainer } from './Container';
import { Text, Tag } from '../Text';
import { addressObjectToStr, toCurrency } from "../../../Utils/util";
import moment from 'moment';
import { useState } from "react";
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { TextButton } from '../../Reusable/Refresh/Button';
import Check from '../../../Images/check-green.png';
import { Image } from '../../Reusable/Image';
import infoRed from '../../../Images/info-red.png'
import edit from '../../../Images/edit.png'
import hexToRgba from 'hex-to-rgba';

const titleMaxLen = 25

export const OwnerCard = (props) => {
    const ownerInfo = props.ownerInfos;
    var businessTitle = `${(ownerInfo.businessTitle || "").trim().substr(0, titleMaxLen)}${(ownerInfo.businessTitle || "").trim().length > titleMaxLen ? "..." : ""}`
    return <LightContainer {...props} padding='12px 24px' style={{ maxWidth: '380px', cursor: "pointer", margin: "10px 20px 10px 0" }}>
        <Flex between style={{ width: "100%" }}>
            <FlexColumn gap='4px'>
                <Flex start noMargin>
                    <Text weight={600} size='13px' height='14px' color={hexToRgba(props.theme.colors.primary2, 0.7)} title={ownerInfo.businessTitle}> {businessTitle} </Text>
                </Flex>
                <Flex start fullWidth centerHorizontally gap='8px'>
                    <Text weight={500} style={{ overflowWrap: 'break-word' }}> {ownerInfo.firstName} {ownerInfo.lastName}</Text>
                    {!props.hideEdit && <img style={{ cursor: 'pointer' }} height={15} width={15} src={edit} />}
                </Flex>
                <Flex start style={{ gap: "10px" }}>
                    <Text lightText size='14px' style={{ overflowWrap: 'break-word' }}>{ownerInfo.email}</Text>
                </Flex>
            </FlexColumn>
            {
                !props.hidePercentage &&
                <Text size='12px' weight={600} color={hexToRgba(props.theme.colors.primary2, 1.0)}>{ownerInfo.ownershipPercentage}%</Text>
            }
        </Flex>
    </LightContainer>
}

export const MissingOwnerInfoCard = (props) => {
    const ownerInfo = props.ownerInfos;
    var businessTitle = `${(ownerInfo.businessTitle || "").trim().substr(0, titleMaxLen)}${(ownerInfo.businessTitle || "").trim().length > titleMaxLen ? "..." : ""}`
    return <RefreshContainer {...props} padding='12px 24px' style={{ width: '370px', cursor: "pointer", margin: "10px 20px 10px 0" }}>
        <Flex between style={{ width: "100%" }}>
            <Flex start noMargin>
                <Image margin='12px 12px 12px 0px' width='48px' height='48px' src={infoRed} />
            </Flex>
            <FlexColumn>
                <Flex start noMargin>
                    <Text weight="400" size="12px" title={ownerInfo.businessTitle}> {businessTitle} </Text>
                </Flex>
                <Flex start noMargin>
                    <Text> {ownerInfo.firstName} {ownerInfo.lastName}</Text>
                </Flex>
                <Flex start style={{ gap: "10px" }}>
                    <Text weight="400">{ownerInfo.email}</Text>
                </Flex>
            </FlexColumn>
            {
                !props.hidePercentage &&
                <Tag
                    background='#FBFBFB'
                    color='#666666'
                    size='14px'
                    height='32px'
                >
                    {ownerInfo.ownershipPercentage}%
                </Tag>
            }
        </Flex>
    </RefreshContainer>
}

export const SubsidirayCard = (props) => {
    const subsidirayInfo = props.subsidirayInfo;
    return <RefreshContainer {...props} padding='12px 24px' style={{ width: '288px', height: '90px', cursor: "pointer", margin: "10px 20px 10px 0" }}>
        <Flex between style={{ width: "100%" }}>
            <FlexColumn>
                <Tag
                    size='12px'
                    color={subsidirayInfo.type === 'Parent' ? '#1199FF' : '#666666'}
                    background={'white'}
                    height='32px'>
                    {subsidirayInfo.type}
                </Tag>
                <Flex between centerHorizontally style={{ width: "100%", gap: "8px" }}>
                    <Tag
                        size='12px'
                        color={'#666666'}
                        background={'#FBFBFB'}
                        radius='21px'
                        height='32px'>
                        {subsidirayInfo.name ? subsidirayInfo.name.match(/\b(\w)/g).join('') : ''}
                    </Tag>
                    <Text weight="400" style={{ float: "left", fontSize: "16px" }}> {subsidirayInfo.name}</Text>
                </Flex>
            </FlexColumn>
        </Flex>
    </RefreshContainer>
}

export const OwnserIndividualCard = (props) => {
    const ownerInfo = props.ownerInfos;
    var businessTitle = `${(ownerInfo.businessTitle || "").trim().substr(0, titleMaxLen)}${(ownerInfo.businessTitle || "").trim().length > titleMaxLen ? "..." : ""}`
    return <Flex between padding='12px 24px' style={{ width: props.width ? props.width : '288px', cursor: "pointer", margin: "10px 20px 10px 0" }}>
        <FlexColumn>
            <Flex start noMargin>
                <Text > {ownerInfo.firstName} {ownerInfo.lastName}</Text>
            </Flex>
            <Flex start noMargin>
                <Text weight="400" size="12px" title={ownerInfo.businessTitle}> {businessTitle}  </Text>
            </Flex>
            <Flex start noMargin>
                <Text weight="400" size="12px"> {ownerInfo.email}</Text>
            </Flex></FlexColumn>
    </Flex>
}

export const OwnserSummaryCard = (props) => {
    const [viewSSN, setViewSSN] = useState(false);

    const ownerInfo = props.ownerInfos;
    var businessTitle = `${(ownerInfo.businessTitle || "").trim().substr(0, titleMaxLen)}${(ownerInfo.businessTitle || "").trim().length > titleMaxLen ? "..." : ""}`
    let ssn = ownerInfo.ssn;
    return <Flex between padding='12px 24px' style={{ width: '248px', cursor: "pointer", margin: "10px 20px 10px 0" }} {...props}>
        <FlexColumn>
            <Flex start noMargin>
                <Text > {ownerInfo.firstName} {ownerInfo.lastName}</Text>
            </Flex>
            <Flex start noMargin>
                <Text weight="400" size="12px" title={ownerInfo.businessTitle}> {businessTitle} | {ownerInfo.email}</Text>
            </Flex>
            <Flex start noMargin>
                <Text>{ownerInfo.dob ? moment(ownerInfo.dob).format('MMM DD, YYYY') : ""} | {ownerInfo.phone ? ownerInfo.phone : "NA"} </Text>
            </Flex>
            <Flex start onClick={(e) => e.stopPropagation()} >SSN : {ssn ? viewSSN ? ssn : ssn.replace(/.(?=.{4})/g, 'x') : ''} &nbsp;&nbsp; <TextButton icon={viewSSN ? <EyeInvisibleOutlined /> : <EyeOutlined />} onClick={() => setViewSSN(!viewSSN)} /></Flex>
            <Text weight="400">{addressObjectToStr(ownerInfo.address)}</Text>
        </FlexColumn>
    </Flex>
}

export const OfferCard = (props) => {
    const offer = props.offer;
    return <>
        <RefreshContainer {...props} padding='12px 24px' style={{ width: '300px', cursor: "pointer", margin: "10px 20px 10px 0" }}>
            <Flex between style={{ width: "100%" }}>
                <FlexColumn>
                    {
                        offer.selected &&
                        <>
                            <Flex end noMargin>
                                <Image src={Check} />
                            </Flex>
                        </>
                    }
                    {
                        offer.productType == "GNPL" &&
                        <>
                            <Flex start noMargin>
                                <Text>Payment Plan: {offer.paymentPlan} </Text>
                            </Flex>
                            <Flex start noMargin>
                                <Text>Repayment Terms: {offer.repaymentTerms}</Text>
                            </Flex>
                        </>
                    }
                    {
                        offer.productType == "RLOC" &&
                        <>
                            <Flex start noMargin>
                                <Text>Advance Factor / Rate: % {offer.advanceFactor} </Text>
                            </Flex>
                            <Flex start noMargin>
                                <Text >Daily Interest Rate: % {offer.dailyInterest} </Text>
                            </Flex>
                        </>
                    }
                    {
                        offer.productType == "GNPL" &&
                        <Flex start noMargin>
                            <Text >Advance Fee: % {offer.advanceFee} </Text>
                        </Flex>
                    }
                    <Flex start noMargin>
                        <Text >Monthly Platform Fee: $ {toCurrency(offer.productType == 'GNPL' ? offer.gnplPlatformFee : offer.rlocPlatformFee)} </Text>
                    </Flex>
                    <Flex start noMargin>
                        <Text >Monthly Committment Fee: $ {toCurrency(offer.commitmentFee)} </Text>
                    </Flex>
                    <Flex start noMargin>
                        <Text >Other Fees: $ {toCurrency(offer.otherFee)} </Text>
                    </Flex>
                </FlexColumn>
            </Flex>
        </RefreshContainer>
    </>
}