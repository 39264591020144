import React, { useState } from 'react'
import { message } from 'antd'
import moment from 'moment'

import { Text } from '../../Reusable/Text'
import { Image } from '../../Reusable/Image'
import { FlexColumn } from '../../Reusable/Container'
import { Button } from '../../Reusable/Refresh/Button'
import { LabeledInput } from '../../Reusable/Refresh/Input'
import environment from '../../../environment'
import { apiPOSTReq } from '../../../Utils/api'
import { ErrorAlert } from '../../Reusable/Alert'
import Deactivate from '../../../Images/deactivate.png'
import { TextButton } from '../../Reusable/Button'

const DeactivateTransferMethod = (props) => {
    const [loading, setLoading] = useState(false)

    const { transferMethod, onDeactivated, onCancel } = props
    // const { nameOnAccount } = recipient

    const deactivate = () => {
        var body = {
            paymentMethodId: transferMethod.id
        }
        setLoading(true)
        console.log("/deactivateTransferDelivery body", body)
        apiPOSTReq(`${environment.bbBaseUrl}/bb/deactivateTransferDelivery`, {}, body, (err, resp) => {
            setLoading(false)
            try {
                if (err) throw Error(err)
                const data = resp || {}
                if (data.result) {
                    console.log("/deactivateTransferDelivery resp", resp)
                    message.success(`Transfer method deactivated!`)
                    onDeactivated()
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/deactivateTransferDelivery err", resp, error)
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    return (
        <FlexColumn center >
            <Image margin='24px 0 12px' width='48px' height='48px' src={Deactivate} />
            <Text size='28px' height='40px' weight='500' style={{ marginBottom: 24, marginTop: 8 }}>Deactivate Transfer Method?</Text>
            <Text center margin='0 12px 24px'>Are you sure you want to deactivate<br />this transfer method?</Text>
            <Button
                solid
                text='YES, DEACTIVATE'
                onClick={() => deactivate()}
                loading={loading} />
            <TextButton onClick={onCancel} margin='24px 0 24px' text='CANCEL' />
        </FlexColumn>
    )
}

export default DeactivateTransferMethod