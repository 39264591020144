import React, { useState } from 'react'
import { message } from 'antd'
import moment from 'moment'

import { Text } from '../../../Reusable/Text'
import { Image } from '../../../Reusable/Image'
import { FlexColumn } from '../../../Reusable/Container'
import Edit from '../../../../Images/edit.png'
import { TextButton, Button } from '../../../Reusable/Button'

const VoidInvoice = (props) => {
    const [loading, setLoading] = useState(false)
    const { onCancel, oldDocNumber, newDocNumber } = props

    return (
        <FlexColumn center >
            <Image margin='24px 0 12px' width='36px' height='36px' src={Edit} />
            <Text size='28px' height='40px' weight='500' style={{ marginBottom: 24, marginTop: 8 }}>Revise Invoice # {oldDocNumber}?</Text>
            <Text center margin='0 12px 36px' width='360px'>Revising {oldDocNumber} will cause it to be voided, however, we’ll create a duplicate invoice {newDocNumber} that looks just like this one. Once an invoice is voided, it cannot be undone. Please confirm or go back to the original invoice. </Text>
            <Button
                solid
                text='REVISE'
                onClick={onCancel}
                loading={loading} />
            <TextButton onClick={() => window.history.back()} margin='24px 0 24px' text='GO BACK' />
        </FlexColumn>
    )
}

export default VoidInvoice