import { apiGET, apiPOSTReq } from './api';
import environment from '../environment';
import moment from 'moment'

export const fetchCounterparties = (options, callback) => {
    const viewBy = options.viewBy;
    var body = {
        viewBy: viewBy,
        sortDirection: "DESC"
    }
    if (options.pagination) {
        body.size = options.pagination.pageSize;
        body.page = options.pagination.current - 1;
    }
    if (options.searchStr) {
        body.searchStr = options.searchStr;
        body.viewBy = 'SEARCH';
    }
    if (options.active === true) body.active = true
    if (options.active === false) body.active = false

    console.log('fetchCounterparties body:', body)

    apiPOSTReq(`${environment.bbBaseUrl}/bb/getCounterparties`, {}, body, (err, resp) => {
        try {
            const data = resp || {};
            if (data.result) {

                console.log('fetchCounterparties resp:', resp)

                var items = data.counterpartiesList || [];
                var dataToStore = {
                    ACHRecipients: [],
                    FundingSources: [],
                    WireRecipients: []
                }
                if (viewBy === "ACH") {
                    dataToStore = {
                        ACHRecipients: [],
                        FundingSources: [],
                        WireRecipients: []
                    }
                } else if (viewBy === "WIRE") {
                    dataToStore = {
                        ACHRecipients: [],
                        FundingSources: [],
                        WireRecipients: []
                    }
                }
                items.forEach(item => {
                    if (item.ach) {
                        if (item.fundingSource) dataToStore.FundingSources.push(item);
                        else dataToStore.ACHRecipients.push(item);
                    };
                    if (item.wire) dataToStore.WireRecipients.push(item);
                });
                console.log("items", dataToStore);
                dataToStore.AllRecipients = items
                callback(null, {
                    ...dataToStore,
                    ...data
                });
            } else {
                throw Error("Could not fetch listCounterParty.");
            }
        } catch (error) {
            console.log("ERRR bb/getCounterparties", error, err, resp);
            callback("Error");
        }
    });
}

// export const fetchACH = (callback) => {
//     apiGET("/banking/radius/ach", null, (err, resp) => {
//         try {
//             if (err) throw Error(err);
//             var data = resp.data;
//             var items = data.ACH || [];
//             var dataToStore = {
//                 FSDeposits: [],
//                 ACHPayments: [],
//                 ACHDailyUsage: data.UsageToday,
//                 ACHFees: data.Fees
//             }
//             items.forEach(item => {
//                 if (item.Direction === "debit") dataToStore.FSDeposits.push(item);
//                 else dataToStore.ACHPayments.push(item);
//             });
//             callback(null, dataToStore);
//         } catch (error) {
//             console.log("Error fetching /ach", error.stack);
//             callback(error.stack);
//         }
//     });
// }

// export const fetchWirePayments = (callback) => {
//     apiGET("/banking/radius/wire", null, (err, resp) => {
//         try {
//             if (err) throw Error(err);
//             var data = resp.data;
//             var items = data.Wire || [];
//             var dataToStore = {
//                 WirePayments: items,
//                 WireDailyUsage: data.UsageToday,
//                 WireFees: data.Fees
//             }
//             callback(null, dataToStore);
//         } catch (error) {
//             console.log("Error fetching /ach", error.stack);
//             callback(error.stack);
//         }
//     });
// }

// export const fetchCheckDeposits = (callback) => {
//     apiGET("/banking/radius/check_deposit", null, (err, resp) => {
//         try {
//             if (err) throw Error(err);
//             var data = resp.data;
//             var items = data.CheckDeposits || [];
//             var dataToStore = {
//                 CheckDeposits: items,
//                 CheckDailyUsage: data.UsageToday
//             }
//             callback(null, dataToStore);
//         } catch (error) {
//             console.log("Error fetching /check_deposit", error.stack);
//             callback(error.stack);
//         }
//     });
// }

// export const fetchBookTransfers = (callback) => {
//     apiGET("/banking/radius/booktransfer/internal/transfers", null, (err, resp) => {
//         try {
//             console.log("booktransfer", err, resp)
//             if (err) throw Error(err);
//             var data = resp.data;
//             var items = data.Transfers || [];
//             var dataToStore = {
//                 BookTransfers: items
//             }
//             callback(null, dataToStore);
//         } catch (error) {
//             callback(error.stack);
//         }
//     });
// }

export const fetchAvailableStatements = (callback) => {
    apiGET("/banking/radius/statement/list", null, (err, resp) => {
        try {
            if (err) throw Error(err);
            var data = resp.data;
            var items = data.AvailableStatements || [];
            var dataToStore = {
                AvailableStatements: items
            }
            callback(null, dataToStore);
        } catch (error) {
            console.log("Error fetching /statement/list", error.stack);
            callback(error.stack);
        }
    });
}

// Bill Pay
export const fetchBPCounterparties = (callback) => {
    apiPOSTReq(`${environment.bbBaseUrl}/bp/listCounterparties`, {}, {}, (err, resp) => {
        try {
            const data = resp || {};
            console.log("BPRecipients /listCounterParty", data)
            if (data.result) {
                callback(null, { BPRecipients: data.counterPartyList })
            } else {
                throw Error("Could not fetch listCounterParty.")
            }
        } catch (error) {
            console.log("ERRR listCounterParty", error, resp);
            callback("Error fetching recipients")
        }
    })
}


/*
    Model
    {
        objectId: "",
        type: "",
        amount: "",
        createdAt: "",
        approvalInstanceId: "",
        description: "",
        referenceId: "", // Check
        toAccountId: "", // Book
        fromAccountId: "", // Book
        counterpartyName: "", // ACH / Wire
    }
*/

export const normalizeAndSortHistory = (data) => {
    const { achTransfersList, wireTransfersList, bookTransfersList, checkDepositList, transactionsList, cardMemoList, instantTransfersList } = data
    const normalizedData = []
    var creditSum = 0
    var debitSum = 0

    if (achTransfersList) {
        achTransfersList.forEach(item => {
            normalizedData.push({
                id: item.id,
                accountId: item.accountId,
                objectId: item.objectId,
                type: 'ACH',
                amount: item.amount,
                createdAt: item.createdAt,
                updatedAt: item.updatedAt,
                releasedAt: item.releasedAt,
                canceledAt: item.canceledAt,
                processedAt: item.processedAt,
                completedAt: item.completedAt,
                postedAt: item.postedAt,
                rejectedAt: item.rejectedAt,
                lastModifiedAt: item.lastModifiedAt,
                effectiveDate: item.effectiveDate,
                status: item.status,
                statusMessage: item.statusMessage,
                approvalInstanceId: item.approvalInstanceId,
                description: item.description,
                userNote: item.userNote,
                referenceId: item.referenceId,
                recipient: item.counterpartyName,
                additionalDetails: item.additionalDetails,
                transactionType: (() => {
                    if((item.direction == "Inbound" && item.transactionType == 'Push') || (item.direction == "Outbound" && item.transactionType == 'Pull')) {
                        return 'credit'
                    } else {
                        return 'debit'
                    }
                })(),
                ogTransactionType: item.transactionType,
                direction: item.direction,
                transactionCode: item.transactionCode,
            })
            if ((item.transactionType || "").toLowerCase() === 'pull') creditSum += Math.abs(item.amount)
            else debitSum += Math.abs(item.amount)
        })
    }

    if (wireTransfersList) {
        wireTransfersList.forEach(item => {
            normalizedData.push({
                id: item.id,
                accountId: item.accountId,
                objectId: item.objectId,
                type: 'Wire',
                amount: item.amount,
                createdAt: item.createdAt,
                updatedAt: item.updatedAt,
                releasedAt: item.releasedAt,
                canceledAt: item.canceledAt,
                processedAt: item.processedAt,
                completedAt: item.completedAt,
                postedAt: item.postedAt,
                rejectedAt: item.rejectedAt,
                lastModifiedAt: item.lastModifiedAt,
                effectiveDate: item.effectiveDate,
                status: item.status,
                statusMessage: item.statusMessage,
                approvalInstanceId: item.approvalInstanceId,
                description: item.description,
                userNote: item.userNote,
                referenceId: item.imad,
                recipient: item.counterpartyName,
                additionalDetails: item.additionalDetails,
                transactionType: 'debit',
                transactionCode: item.transactionCode,
            })
            debitSum += Math.abs(item.amount)
        })
    }

    if (bookTransfersList) {
        bookTransfersList.forEach(item => {
            normalizedData.push({
                accountId: item.accountId,
                objectId: item.objectId,
                type: 'Internal',
                amount: item.amount,
                createdAt: item.createdAt,
                updatedAt: item.updatedAt,
                releasedAt: item.releasedAt,
                canceledAt: item.canceledAt,
                processedAt: item.processedAt,
                completedAt: item.completedAt,
                postedAt: item.postedAt,
                rejectedAt: item.rejectedAt,
                lastModifiedAt: item.lastModifiedAt,
                effectiveDate: item.effectiveDate,
                status: item.status,
                statusMessage: item.statusMessage,
                description: item.description,
                referenceId: item.referenceId,
                toAccountId: item.toAccountId,
                fromAccountId: item.fromAccountId,
                additionalDetails: item.additionalDetails,
                userNote: item.userNote,
                transactionType: item.transactionType && (item.transactionType === 'Push' ? 'debit' : item.transactionType === 'Pull' ? 'credit' : item.transactionType.toLowerCase()),
                transactionCode: item.transactionCode,
                subRail: item.subRail,
            })
            if ((item.transactionType || "").toLowerCase() === 'pull') creditSum += Math.abs(item.amount)
            else debitSum += Math.abs(item.amount)
        })
    }

    if (instantTransfersList) {
        instantTransfersList.forEach(item => {
            normalizedData.push({
                accountId: item.accountId,
                objectId: item.id,
                type: 'Instant',
                amount: item.amount,
                createdAt: item.createdAt,
                updatedAt: item.updatedAt,
                releasedAt: item.releasedAt,
                canceledAt: item.canceledAt,
                processedAt: item.processedAt,
                completedAt: item.completedAt,
                postedAt: item.postedAt,
                rejectedAt: item.rejectedAt,
                lastModifiedAt: item.lastModifiedAt,
                effectiveDate: item.effectiveDate,
                status: item.status,
                statusMessage: item.statusMessage,
                description: item.transactionType === 'Push' ? (item.senderDescription || '--') : (item.receiverDescription || '--'),
                referenceId: item.referenceId,
                toAccountId: item.toAccountId,
                fromAccountId: item.fromAccountId,
                additionalDetails: item.additionalDetails,
                userNote: item.userNote,
                transactionType: item.transactionType && (item.transactionType === 'Push' ? 'debit' : item.transactionType === 'Pull' ? 'credit' : item.transactionType.toLowerCase()),
                transactionCode: item.transactionCode,
                subRail: item.subRail,
                approvalInstanceId: item.approvalInstanceId,
            })
            if ((item.transactionType || "").toLowerCase() === 'pull') creditSum += Math.abs(item.amount)
            else debitSum += Math.abs(item.amount)
        })
    }

    if (checkDepositList) {
        checkDepositList.forEach(item => {
            normalizedData.push({
                accountId: item.accountId,
                objectId: item.objectId,
                type: 'Check',
                amount: item.amount,
                createdAt: item.createdAt,
                updatedAt: item.updatedAt,
                releasedAt: item.releasedAt,
                canceledAt: item.canceledAt,
                processedAt: item.processedAt,
                completedAt: item.completedAt,
                postedAt: item.postedAt,
                rejectedAt: item.rejectedAt,
                lastModifiedAt: item.lastModifiedAt,
                effectiveDate: item.effectiveDate,
                status: item.status,
                statusMessage: item.statusMessage,
                description: item.description,
                referenceId: item.referenceId,
                recipient: item.counterpartyName,
                additionalDetails: item.additionalDetails,
                transactionType: 'credit',
                transactionCode: item.transactionCode,
            })
            creditSum += Math.abs(item.amount)
        })
    }

    if (transactionsList) {
        transactionsList.forEach(item => {
            var type = item.rail
            var status = item.providerStatus
            var objectId = item.objectId
            // console.log('getObjectDetail transactionsList', item.rail, item);
            switch (item.rail) {
                case 'Ach':
                    type = 'ACH'
                    objectId = item.achId
                    break
                case 'Wires':
                    type = 'Wire'
                    objectId = item.wireId
                    break
                case 'Checks':
                    type = 'Check'
                    objectId = item.checkId
                    break
                case 'Internal':
                    type = (item.subRail === 'Instant-Internal') ? 'Internal' : 'Internal';
                    objectId = (item.subRail === 'Instant-Internal') ? (item.instantTransferId || item.transactionId) : item.transactionId;
                    break
                case 'Rtp':
                    type = 'Instant';
                    objectId = item.instantTransferId || item.transactionId
                    break
                case 'XPay':
                    type = 'XPay'
                    objectId = item.objectId
                    break
                case 'CardManagement':
                    type = 'Card'
                    objectId = item.transactionId
                    break
                default:
                    objectId = item.objectId
                    break
            }

            if (status === 'Pending') status = 'To be posted'

            normalizedData.push({
                id: item.id,
                accountId: item.accountId,
                objectId: objectId,
                type: type,
                subRail: item.subRail,
                amount: Math.abs(item.amount),
                createdAt: item.createdAt,
                updatedAt: item.updatedAt,
                releasedAt: item.releasedAt,
                canceledAt: item.canceledAt,
                processedAt: item.processedAt,
                completedAt: item.completedAt,
                postedAt: item.postedAt,
                rejectedAt: item.rejectedAt,
                lastModifiedAt: item.lastModifiedAt,
                effectiveDate: item.effectiveDate,
                status: status,
                statusMessage: item.statusMessage,
                description: item.displayDescription,
                referenceId: item.referenceId,
                recipient: item.counterpartyName,
                traceNumber: item.traceNumber,
                displayDescription: item.displayDescription,
                transaction: true,
                additionalDetails: item.additionalDetails,
                userNote: item.userNote,
                transactionType: item.amount > 0 ? 'credit' : 'debit',
                transactionId: item.transactionId,
                transactionCode: item.transactionCode,
                merchantName: item.merchantName,
                merchantLocation: item.merchantLocation,
                merchantCategory: item.merchantCategory,
            })

            if (item.amount > 0) creditSum += Math.abs(item.amount)
            else debitSum += Math.abs(item.amount)
        })
    }

    if (cardMemoList) {
        cardMemoList.forEach(item => {
            normalizedData.push({
                id: item.id,
                accountId: item.accountId,
                objectId: item.memoPostId,
                type: 'CardMemo',
                amount: item.amount,
                createdAt: item.createdAt,
                updatedAt: item.updatedAt,
                releasedAt: item.releasedAt,
                canceledAt: item.canceledAt,
                processedAt: item.processedAt,
                completedAt: item.completedAt,
                postedAt: item.postedAt,
                rejectedAt: item.rejectedAt,
                lastModifiedAt: item.lastModifiedAt,
                effectiveDate: item.effectiveDate,
                status: 'Pending',
                statusMessage: item.statusMessage,
                description: item.description,
                referenceId: item.referenceId,
                recipient: item.counterpartyName,
                additionalDetails: item.additionalDetails,
                transactionType: item.transactionType.toLowerCase(),
                transactionCode: item.transactionCode,
                traceNumber: item.traceNumber,
                merchantName: item.merchantName,
                merchantLocation: item.merchantLocation,
                merchantCategory: item.merchantCategory,
                retrievalReferenceNumber: item.retrievalReferenceNumber,
                panLastFour: item.panLastFour,
            })

            if ((item.transactionType || "").toLowerCase() === 'credit') creditSum += Math.abs(item.amount)
            else debitSum += Math.abs(item.amount)
        })
    }

    // normalizedData.sort((a, b) => { return moment(a.lastModifiedAt || a.updatedAt || a.createdAt).unix() - moment(b.lastModifiedAt || b.updatedAt || b.createdAt).unix() })
    normalizedData.sort((a, b) => { return moment.utc(a.lastModifiedAt || a.updatedAt || a.createdAt).diff(moment.utc(b.lastModifiedAt || b.updatedAt || b.createdAt)) })
    normalizedData.reverse()

    // console.log('sorted normalizedData', normalizedData)

    return {
        normalizedData: normalizedData,
        pendingSums: {
            creditSum: creditSum,
            debitSum: debitSum
        }
    }
}

// History
export const fetchBBHistory = (options, callback) => {
    var body = {
        viewBy: options.viewBy,
        sortDirection: "DESC"
    };
    console.log("fetchBBHistory", options)
    if (options.pagination) {
        body.size = options.pagination.pageSize;
        body.page = options.pagination.current - 1;
    }
    if (options.searchStr) {
        body.searchStr = options.searchStr
    } else {
        body.searchStr = "";
    }
    if (options.accountId) {
        body.accountId = options.accountId
    }
    if (options.recipientId) {
        body.recipientId = options.recipientId
    }
    if (options.paymentStatus) {
        body.paymentStatus = options.paymentStatus
    }

    console.log("fetchBBHistory /getHistory body", body)
    apiPOSTReq(`${environment.bbBaseUrl}/bb/getHistory`, {}, body, (err, resp) => {
        try {
            const data = resp || {};
            console.log("fetchBBHistory /getHistory resp", data)
            if (data.result) {
                var achTransfersList = data.achTransfersList || [];
                var achPayments = achTransfersList.filter(item => item.direction == "credit");
                var achFundingSource = achTransfersList.filter(item => item.direction == "debit");
                callback(null, {
                    ACHPayments: achPayments || [],
                    FSDeposits: achFundingSource || [],
                    WirePayments: data.wireTransfersList || [],
                    BookTransfers: data.bookTransfersList || [],
                    BillPayments: data.bpPaymentList || [],
                    CheckDeposits: data.checkDepositList || [],
                    ...data
                })
            } else {
                throw Error("Could not fetch listCounterParty.")
            }
        } catch (error) {
            console.log("ERRR listCounterParty", error, resp);
            callback("Error")
        }
    })
}

export const fetchBBObject = (options, callback) => {
    var body = {
        viewBy: options.viewBy,
        ObjectId: options.filterItemId
    };
    console.log("getObjectDetail", body)
    apiPOSTReq(`${environment.bbBaseUrl}/bb/getObjectDetail`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
        try {
            const data = resp || {};
            console.log("/getObjectDetail", data);
            if (data.result) {
                var achPayments = data.achTransferObj ? (data.achTransferObj.direction == "credit" ? data.achTransferObj : null) : null;
                var achFundingSource = data.achTransferObj ? (data.achTransferObj.direction == "debit" ? data.achTransferObj : null) : null;
                console.log("/getObjectDetail achTransferObj", achPayments);
                callback(null, {
                    ACHPayments: achPayments ? [achPayments] : [],
                    FSDeposits: achFundingSource ? [achFundingSource] : [],
                    WirePayments: data.wireTransferObj ? [data.wireTransferObj] : [],
                    BookTransfers: data.bookTransferObj ? [data.bookTransferObj] : [],
                    BillPayments: data.bpPaymentObj ? [data.bpPaymentObj] : [],
                    CheckDeposits: data.checkDepositObj ? [data.checkDepositObj] : [],
                    ...data
                })
            } else {
                throw Error("Could not fetch listCounterParty.")
            }
        } catch (error) {
            console.log("ERRR listCounterParty", error, resp);
            callback("Error")
        }
    })
}

// Transactions
export const fetchBBTransactions = (options, callback) => {
    var body = {
        sortDirection: "DESC"
    };
    var header = {};
    Object.assign(body, options);
    if (options.pagination) {
        body.size = options.pagination.pageSize;
        body.page = options.pagination.current - 1;
    }
    if (options.searchStr) {
        body.searchStr = options.searchStr;
        body.viewBy = options.viewBy ? options.viewBy : 'SEARCH';
    }
    if (options.providerStatus) {
        body.providerStatus = options.providerStatus;
    } else {
        options.providerStatus = "Posted"
    }
    if (options.bankProvider) {
        header["bankProvider"] = options.bankProvider;
    }
    if (options.BusinessId) {
        header["AionCurrentBiz"] = options.BusinessId;
    }
    if (options.paymentStatus) {
        body.paymentStatus = options.paymentStatus
    }

    console.log("fetchBBTransactions body", header, body);
    apiPOSTReq(`${environment.bbBaseUrl}/bb/getTransactions`, header, body, (err, resp) => {
        try {
            console.log("fetchBBTransactions resp", resp);
            const data = resp || {};
            if (data.result) {
                var transactionsList = data.transactionsList || [];
                callback(null, {
                    Transactions: transactionsList || [],
                    ...data
                })
            } else {
                throw Error(data.error || data.responseMessage || "Could not fetch transactions.")
            }
        } catch (error) {
            console.log("ERRR getTransactions", error, resp);
            callback(error.message);
        }
    })
}

// Fetch ACH
export const fetchBBACH = (options, callback) => {
    var body = {
        sortDirection: "DESC",
        sortFieldName: "CreatedAt",
        // "filterType": "INCOMING_TXNS",
    };
    var header = {};
    Object.assign(body, options);
    if (options.pagination) {
        body.size = options.pagination.pageSize;
        body.page = options.pagination.current - 1;
    }
    if (options.searchStr) {
        body.searchStr = options.searchStr;
        body.viewBy = options.viewBy ? options.viewBy : 'SEARCH';
    }
    if (options.filterType) {
        body.filterType = options.filterType;
    }
    // if (options.providerStatus) {
    //     body.providerStatus = options.providerStatus;
    // } else {
    //     options.providerStatus = "Posted"
    // }
    if (options.bankProvider) {
        header["bankProvider"] = options.bankProvider;
    }
    if (options.BusinessId) {
        header["AionCurrentBiz"] = options.BusinessId;
    }
    if (options.paymentStatus) {
        body.paymentStatus = options.paymentStatus
    } else {
        body.paymentStatus = ['pending_approval',
            'pending', 'created', 'processing',
            'Pending', 'Created', 'Processing',
            'sent', 'complete', 'completed',
            'Sent', 'Complete', 'Completed',
            "Hold", "hold", 
            'canceled', 'error', 'Rejected', 'denied_approval',
        ]
    }

    console.log("fetchBBACH body", header, body);
    apiPOSTReq(`${environment.bbBaseUrl}/bb/getACHTransfers`, header, body, (err, resp) => {
        try {
            console.log("fetchBBACH resp", resp);
            const data = resp || {};
            if (data.result) {
                var transactionsList = data.achTransfersList || [];
                callback(null, {
                    Transactions: transactionsList || [],
                    ...data
                })
            } else {
                throw Error(data.error || data.responseMessage || "Could not fetch transactions.")
            }
        } catch (error) {
            console.log("ERRR fetchBBACH", error, resp);
            callback(error.message);
        }
    })
}

// Fetch Wires
export const fetchBBWires = (options, callback) => {
    var body = {
        sortDirection: "DESC",
        sortFieldName: "CreatedAt",
    };
    var header = {};
    Object.assign(body, options);
    if (options.pagination) {
        body.size = options.pagination.pageSize;
        body.page = options.pagination.current - 1;
    }
    if (options.searchStr) {
        body.searchStr = options.searchStr;
        body.viewBy = options.viewBy ? options.viewBy : 'SEARCH';
    }
    // if (options.providerStatus) {
    //     body.providerStatus = options.providerStatus;
    // } else {
    //     options.providerStatus = "Posted"
    // }
    if (options.bankProvider) {
        header["bankProvider"] = options.bankProvider;
    }
    if (options.BusinessId) {
        header["AionCurrentBiz"] = options.BusinessId;
    }
    if (options.paymentStatus) {
        body.paymentStatus = options.paymentStatus
    } else {
        body.paymentStatus = ['pending_approval',
            'pending', 'created', 'processing',
            'Pending', 'Created', 'Processing',
            'sent', 'complete', 'completed',
            'Sent', 'Complete', 'Completed',
            "Hold", "hold",
            'canceled', 'error', 'Rejected', 'denied_approval',
        ]
    }

    console.log("fetchBBWires body", header, body);
    apiPOSTReq(`${environment.bbBaseUrl}/bb/getWireTransfers`, header, body, (err, resp) => {
        try {
            console.log("fetchBBWires resp", resp);
            const data = resp || {};
            if (data.result) {
                var transactionsList = data.wireTransfersList || [];
                callback(null, {
                    Transactions: transactionsList || [],
                    ...data
                })
            } else {
                throw Error(data.error || data.responseMessage || "Could not fetch transactions.")
            }
        } catch (error) {
            console.log("ERRR fetchBBWires", error, resp);
            callback(error.message);
        }
    })
}

// Fetch Checks
export const fetchChecks = (options, callback) => {
    var body = {
        sortDirection: "DESC",
        sortFieldName: "CreatedAt",
    };
    var header = {};
    Object.assign(body, options);
    if (options.pagination) {
        body.size = options.pagination.pageSize;
        body.page = options.pagination.current - 1;
    }
    if (options.searchStr) {
        body.searchStr = options.searchStr;
        body.viewBy = options.viewBy ? options.viewBy : 'SEARCH';
    }
    // if (options.providerStatus) {
    //     body.providerStatus = options.providerStatus;
    // } else {
    //     options.providerStatus = "Posted"
    // }
    if (options.bankProvider) {
        header["bankProvider"] = options.bankProvider;
    }
    if (options.BusinessId) {
        header["AionCurrentBiz"] = options.BusinessId;
    }

    if (options.paymentStatus) {
        body.paymentStatus = options.paymentStatus
    } else {
        body.paymentStatus = ['pending_approval',
            'pending', 'created', 'processing',
            'Pending', 'Created', 'Processing',
            'sent', 'complete', 'completed',
            'Sent', 'Complete', 'Completed',
            "Hold", "hold",
            'canceled', 'error', 'Rejected', 'denied_approval',
        ]
    }

    console.log("getCheckDeposits body", header, body);
    apiPOSTReq(`${environment.bbBaseUrl}/bb/getCheckDeposits`, header, body, (err, resp) => {
        try {
            console.log("getCheckDeposits resp", resp);
            const data = resp || {};
            if (data.result) {
                var transactionsList = data.checkDepositList || [];
                callback(null, {
                    Transactions: transactionsList || [],
                    ...data
                })
            } else {
                throw Error(data.error || data.responseMessage || "Could not fetch transactions.")
            }
        } catch (error) {
            console.log("ERRR getCheckDeposits", error, resp);
            callback(error.message);
        }
    })
}

// Fetch Internal
export const fetchBBInternal = (options, callback) => {
    var body = {
        sortDirection: "DESC",
        sortFieldName: "CreatedAt",
    };
    var header = {};
    Object.assign(body, options);
    if (options.pagination) {
        body.size = options.pagination.pageSize;
        body.page = options.pagination.current - 1;
    }
    if (options.searchStr) {
        body.searchStr = options.searchStr;
        body.viewBy = options.viewBy ? options.viewBy : 'SEARCH';
    }
    // if (options.providerStatus) {
    //     body.providerStatus = options.providerStatus;
    // } else {
    //     options.providerStatus = "Posted"
    // }
    if (options.bankProvider) {
        header["bankProvider"] = options.bankProvider;
    }
    if (options.BusinessId) {
        header["AionCurrentBiz"] = options.BusinessId;
    }
    body.paymentStatus = ['pending', 'created', 'processing',
        'Pending', 'Created', 'Processing',
        'sent', 'complete', 'completed',
        'Sent', 'Complete', 'Completed',
        "Hold", "hold"
    ]

    console.log("fetchBBInternal body", header, body);
    apiPOSTReq(`${environment.bbBaseUrl}/bb/getBookTransfers`, header, body, (err, resp) => {
        try {
            console.log("fetchBBInternal resp", resp);
            const data = resp || {};
            if (data.result) {
                var transactionsList = data.bookTransfersList || [];
                callback(null, {
                    Transactions: transactionsList || [],
                    ...data
                })
            } else {
                throw Error(data.error || data.responseMessage || "Could not fetch transactions.")
            }
        } catch (error) {
            console.log("ERRR fetchBBInternal", error, resp);
            callback(error.message);
        }
    })
}

// Fetch Instant
export const fetchBBInstant = (options, callback) => {
    var body = {
        sortDirection: "DESC",
        sortFieldName: "CreatedAt",
    };
    var header = {};
    Object.assign(body, options);
    if (options.pagination) {
        body.size = options.pagination.pageSize;
        body.page = options.pagination.current - 1;
    }
    if (options.searchStr) {
        body.searchStr = options.searchStr;
        body.viewBy = options.viewBy ? options.viewBy : 'SEARCH';
    }
    // if (options.providerStatus) {
    //     body.providerStatus = options.providerStatus;
    // } else {
    //     options.providerStatus = "Posted"
    // }
    if (options.bankProvider) {
        header["bankProvider"] = options.bankProvider;
    }
    if (options.BusinessId) {
        header["AionCurrentBiz"] = options.BusinessId;
    }
    if (options.paymentStatus) {
        body.paymentStatus = options.paymentStatus
    }
    if (options.paymentStatus) {
        body.paymentStatus = options.paymentStatus
    } else {
        body.paymentStatus = ['pending_approval',
            'pending', 'created', 'processing',
            'Pending', 'Created', 'Processing',
            'sent', 'complete', 'completed',
            'Sent', 'Complete', 'Completed',
            "Hold", "hold",
            'canceled', 'error', 'Rejected', 'denied_approval',
        ]
    }
    
    console.log("fetchBBInstant body", header, body);
    apiPOSTReq(`${environment.bbBaseUrl}/bb/getInstantTransfers`, header, body, (err, resp) => {
        try {
            console.log("fetchBBInstant resp", resp);
            const data = resp || {};
            if (data.result) {
                var transactionsList = data.instantTransfersList || [];
                callback(null, {
                    Transactions: transactionsList || [],
                    ...data
                })
            } else {
                throw Error(data.error || data.responseMessage || "Could not fetch transactions.")
            }
        } catch (error) {
            console.log("ERRR fetchBBInstant", error, resp);
            callback(error.message);
        }
    })
}