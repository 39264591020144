import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { Modal, Select, Menu, Dropdown } from 'antd';
import { PlusOutlined, CheckOutlined, MoreOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from 'moment'

import { Text } from '../../Reusable/Text';
import { Flex, FlexColumn, Container } from '../../Reusable/Container';
import { ErrorAlert } from '../../Reusable/Alert';
import ModalClose from '../../../Images/modal-close.png';

// Actions
import { LabeledInput, StyledLabel } from '../../Reusable/Input';
import { Button, TextButton, Capsule } from '../../Reusable/Button';
import { formatPhoneTextV2, getAddressObj, countryCodeMapUSFirst, formatSSN, formatOwnershipPercentage, isEmailValid } from '../../../Utils/util';
import { addDataToStore, REMOTE_SAVE_DATA, SAVE_CO_OWNER, ONB_SAVE_DATA } from '../../../Actions/actions';
import { capitalize } from 'lodash';

import { Image } from '../../Reusable/Image'
import Percentage from '../../../Images/Percentage.png'

const { Option } = Select;
export const dateFormatList = ['MM/DD/YYYY', 'MM/DD/YY', 'MM-DD-YYYY', 'MM-DD-YY']

class AddOwnerModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rowItem: this.props.rowItem,
            ownerInfo: this.props.ownerInfo,
            coOwnersInfo: this.props.coOwnersInfo,
            newOwner: this.props.newOwner
        }
    }

    handleType = (selectedType) => {
        var selectedItem = this.state.rowItem || {}
        var selectedRow = Object.assign({}, selectedItem)

        selectedRow["type"] = selectedType
        this.setState({ rowItem: selectedRow, selectedType })
        this.setRowItem(selectedRow);
    }

    saveUserInput = (id, value) => {
        var dataToSave = this.state.ownerInfo || {};
        switch (id) {
            case "phone":
                value = value.replace(/[^\d]/g, '');
                if (value.length > 10) value = value.substring(0, 10);
                dataToSave[id] = `+1${value}`;
                break;
            case "ownershipPercentage":
                if (value == "" || parseFloat(value) <= 100) {
                    dataToSave[id] = formatOwnershipPercentage(value >= 0 ? value.toString() : '');
                }
                break;
            default:
                dataToSave[id] = value;
                break;
        }
        // this.props.dispatch(addDataToStore(BUSINESS_INFO, dataToSave));
        this.setState({ ownerInfo: dataToSave });
    }

    handleOnChange = (event) => {
        const target = event.target;
        var value = target.value;
        if (target.id == "firstName" || target.id == "lastName") value = capitalize(value);
        if ((target.id || target.name) == 'ownershipPercentage') {
            if (!isNaN(target.value)) {
                if ((target.value > 100)) {
                    this.saveUserInput(target.id || target.name, target.value.slice(0, 2));
                } else {
                    this.saveUserInput(target.id || target.name, target.value);
                    this.setState({ errorField: null, errorMessage: null });
                }
            }
            else {
                this.setState({ errorField: 'ownershipPercentage', errorMessage: 'Please enter a valid ownership percentage' });
                this.saveUserInput(target.id || target.name, target.value);
            }
        }
        else {
            this.saveUserInput(target.id || target.name, value)
        }
    }


    getLocation(location, key) {
        let addrObj = getAddressObj(location);
        addrObj.city = addrObj.city ? addrObj.city.substring(0, 18) : '';
        this.saveUserInput(key, addrObj);
    }

    setErrorField = (id, msg) => this.setState({ errorField: id, errorMessage: msg });

    validateFlow = () => {
        var { ownerInfo, newOwner, coOwnersInfo } = this.state;
        var { firstName, lastName, phone, email, address, ownershipPercentage, businessTitle, citizenOrResident, countryOfCitizenship, dob, ssn } = ownerInfo || {};
        const foreignUser = (countryOfCitizenship != 'US') && (citizenOrResident == false)

        if (typeof (citizenOrResident) !== 'boolean') {
            this.setErrorField("citizenOrResident", "Select an option")
            return false;
        }
        if (!countryOfCitizenship) {
            this.setErrorField("countryOfCitizenship", "Select a country of citizenship")
            return false;
        }
        if (!firstName) {
            this.setErrorField("firstName", "Please enter a first name.");
            return;
        }
        if (!lastName) {
            this.setErrorField("lastName", "Please enter a last name.");
            return;
        }
        if (!phone || phone == "+1") {
            this.setErrorField("phone", "Please enter a valid phone.");
            return;
        }
        if (!email) {
            this.setErrorField("email", "Please enter a valid email.");
            return;
        } else {
            if (!isEmailValid(email)) {
                this.setState({ errorField: "email", errorMessage: "Enter a valid email" })
                return
            }
            switch (newOwner) {
                case true:
                    var existingCoOwners = coOwnersInfo.filter(owner => owner.email == email);
                    if (existingCoOwners.length > 0) {
                        this.setErrorField("email", "Email already exist.");
                        return;
                    }
                case false:
                    var existingCoOwners = coOwnersInfo.filter(owner => owner.email == email);
                    if (existingCoOwners.length > 1) {
                        this.setErrorField("email", "Email already exist.");
                        return;
                    }
            }
        }
        if (!ownershipPercentage) {
            this.setErrorField("ownershipPercentage", "Please enter a valid ownership percentage.");
            return;
        }
        else {
            if (isNaN(ownershipPercentage)) {
                this.setErrorField("ownershipPercentage", "Please enter a valid ownership percentage.");
                return;
            }
        }

        if (parseFloat(ownershipPercentage) < 25) {
            this.setErrorField("ownershipPercentage", "Ownership percentage should be greater than or equal to 25.");
            return;
        }

        if (!businessTitle) {
            this.setErrorField("title", "Please select a valid title.");
            return;
        }
        if (!dob) {
            this.setErrorField("dob", "Select a date")
            return false;
        }
        if (foreignUser && (!ssn || (ssn.length < 9) || (ssn.length > 20))) {
            this.setErrorField("ssn", "Enter a valid Tax ID between 9 and 20 characters")
            return false;
        } else if (!foreignUser && (!ssn || ((ssn || "").replace(/[^\d]/g, '').length !== 9))) {
            this.setErrorField("ssn", "Enter a valid 9 digit SSN")
            return false;
        }
        if (!address || !address.line1) {
            this.setErrorField("address", "Please enter a valid address.");
            return;
        }
        ownerInfo.isContractOwner = false;
        this.setState({ ownerInfo: ownerInfo });
        // Save to the DB
        ownerInfo.ownershipPercentage = parseFloat(ownerInfo.ownershipPercentage);
        console.log("Saving owner", { ownerInfo: ownerInfo })
        this.props.dispatch(addDataToStore(SAVE_CO_OWNER, { ownerInfo: ownerInfo }));
        if (newOwner) {
            coOwnersInfo.push(ownerInfo);
        }
        this.props.dispatch(addDataToStore(REMOTE_SAVE_DATA, "Apply.Basic"));
        this.props.onSubmit(coOwnersInfo);
    }

    deleteOwner = () => {
        var { ownerInfo } = this.state;
        this.props.onDelete(...ownerInfo);
    }

    handleDateChange = (id, dateStr) => {
        if (dateStr) {
            if (id == "dob") {
                this.saveUserInput(id, moment.utc(dateStr).startOf('day').toDate())
            } else {
                this.saveUserInput(id, moment.utc(dateStr).startOf('day').format("YYYY-MM-DD"))
            }
        } else {
            this.saveUserInput(id, null)
        }
        this.validateExpiryDate();
    }

    disabledDOBDate = (current) => {
        // Can not select days after today and today and weekends
        return current > moment().subtract('18', "years")
    }

    validateExpiryDate = () => {
        var { identification } = this.state.ownerInfo
        const { expDate } = identification || {}

        if (!expDate || !moment(expDate).isAfter(moment())) {
            this.setErrorField("expDate", "Their ID may have expired or been incorrectly filled. Update this field or reupload a valid document.")
            return false;
        } else {
            this.setErrorField({ "": "" });
        }
    }

    render() {
        var { theme } = this.props
        var { ownerInfo, errorField, errorMessage } = this.state
        ownerInfo = ownerInfo || {};
        var { citizenOrResident, countryOfCitizenship, ownershipPercentage } = ownerInfo

        const foreignUser = (countryOfCitizenship != 'US') && (citizenOrResident == false)
        const countryItems = []
        Object.values(countryCodeMapUSFirst).forEach(item => {
            countryItems.push(
                <Option key={item.code} value={item.code}>
                    {item.name}
                </Option>
            )
        })

        return (
            <FlexColumn style={{ width: "100%" }}>
                <Text size='20px' weight="500">{this.props.newOwner ? "Add Owner" : "Update Owner"}</Text>
                <Text size='16px'>Please add an individual that owns 25% or more of your business</Text>
                <Flex between style={{ width: "100%", marginTop: 24 }}>
                    <div style={{ width: "100%" }}>
                        <Flex gap="24px">
                            <LabeledInput
                                margin='0px'
                                label='Are they a resident of the U.S.?'
                                type='switch'
                                switchNames={['Yes', 'No']}
                                onChange={(value) => this.saveUserInput("citizenOrResident", (value == 'Yes'))}
                                value={(typeof (ownerInfo.citizenOrResident) == 'boolean') ? (ownerInfo.citizenOrResident ? 'Yes' : 'No') : null}
                                error={errorField == "citizenOrResident"}
                                errorMessage={errorMessage}
                            />
                            <LabeledInput
                                margin='0px'
                                labelcolor={this.props.theme.colors.secondary3}
                                label="Which country are they a citizen of?"
                                type="select"
                                key='Citizenship'
                                id="Citizenship"
                                placeholder="Select country of citizenship"
                                optionFilterProp="children"
                                onChange={value => { this.saveUserInput("countryOfCitizenship", value) }}
                                value={ownerInfo.countryOfCitizenship}
                                error={errorField == "countryOfCitizenship"}
                                errorMessage={errorMessage}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().startsWith(input.toLowerCase())
                                }
                            >
                                {countryItems}
                            </LabeledInput>
                        </Flex>

                        <Flex between style={{ width: "100%" }}>
                            <div style={{ width: "50%", marginRight: 12 }}>
                                <LabeledInput
                                    autoFocus
                                    label="First Name"
                                    labelcolor={theme.colors.secondary3}
                                    id="firstName"
                                    placeholder="Enter first name"
                                    value={ownerInfo.firstName}
                                    onChange={this.handleOnChange}
                                    error={errorField == "firstName"}
                                    errorMessage={errorMessage}
                                />
                            </div>
                            <div style={{ width: "50%", marginLeft: 12 }}>
                                <LabeledInput
                                    label="Last Name"
                                    labelcolor={theme.colors.secondary3}
                                    id="lastName"
                                    placeholder="Enter last name"
                                    value={ownerInfo.lastName}
                                    onChange={this.handleOnChange}
                                    error={errorField == "lastName"}
                                    errorMessage={errorMessage}
                                />
                            </div>
                        </Flex>

                        <Flex between style={{ width: "100%" }}>
                            <div style={{ width: "50%", marginRight: 12 }}>
                                <LabeledInput
                                    label="Phone Number"
                                    labelcolor={theme.colors.secondary3}
                                    id="phone"
                                    type="text"
                                    placeholder="(000) 000 0000"
                                    value={formatPhoneTextV2(ownerInfo.phone)}
                                    onChange={this.handleOnChange}
                                    error={errorField == "phone"}
                                    errorMessage={errorMessage}
                                />
                            </div>
                            <div style={{ width: "50%", marginLeft: 12 }}>
                                <LabeledInput
                                    label="Email Address"
                                    id="email"
                                    type="email"
                                    placeholder="email@mail.com"
                                    value={ownerInfo.email}
                                    onChange={this.handleOnChange}
                                    error={errorField == "email"}
                                    errorMessage={errorMessage}
                                />
                            </div>
                        </Flex>

                        <Flex between style={{ width: "100%" }}>
                            <div style={{ width: "50%", marginRight: 12 }}>
                                <LabeledInput
                                    label="Ownership Percentage"
                                    id="ownershipPercentage"
                                    symbolsuffix="%"
                                    placeholder="Enter Percentage"
                                    value={formatOwnershipPercentage(ownershipPercentage ? ownershipPercentage.toString() : '')}
                                    onChange={this.handleOnChange}
                                    error={errorField == "ownershipPercentage"}
                                    errorMessage={errorMessage}
                                    maxLength={6}
                                    suffixFontSzie="20px"
                                />
                            </div>
                            <div style={{ width: "50%", marginLeft: 12 }}>
                                <LabeledInput
                                    label="Title"
                                    id="businessTitle"
                                    type="select"
                                    placeholder="Select title"
                                    value={ownerInfo.businessTitle}
                                    onChange={(value) => this.saveUserInput("businessTitle", value)}
                                    error={errorField == "title"}
                                    errorMessage={errorMessage}
                                >
                                    {["CEO", "CFO", "COO", "Other"].map(item => (
                                        <Option key={item} id="title" name="Title" value={item}>
                                            {item}
                                        </Option>
                                    )
                                    )}
                                </LabeledInput>
                            </div>
                        </Flex>

                        <Flex gap="24px">
                            <LabeledInput
                                labelcolor={this.props.theme.colors.secondary3}
                                label="Date of Birth"
                                type="date-picker"
                                defaultPickerValue={moment().subtract(18, "years")}
                                disabledDate={this.disabledDOBDate}
                                key={"DOB"}
                                id="dob"
                                onChange={(date, dateStr) => this.handleDateChange("dob", dateStr)}
                                format={dateFormatList}
                                value={ownerInfo.dob && moment.utc(ownerInfo.dob)}
                                placeholder="Select"
                                error={errorField == "dob"}
                                errorMessage={errorMessage}
                                style={{ height: "30px" }}
                            />
                            <LabeledInput
                                autoFocus
                                onKeyDown={this.handleOnKeyDown}
                                labelcolor={this.props.theme.colors.secondary3}
                                label={foreignUser ? `Personal Tax ID Number` : "Social Security Number"}
                                id="ssn"
                                type="mask"
                                placeholder="123-44-5550"
                                onChange={this.handleOnChange}
                                value={ownerInfo.ssn && (foreignUser ? ownerInfo.ssn : formatSSN(ownerInfo.ssn))}
                                error={errorField == "ssn"}
                                errorMessage={errorMessage}
                                maxLength={foreignUser ? 36 : 11}
                            />
                        </Flex>

                        <LabeledInput
                            type="location"
                            id={"address"}
                            value={ownerInfo.address}
                            label="Address"
                            getLocation={(location, formattedAddress) => this.getLocation(location, 'address')}
                            nextLine={true}
                            country
                            divider={false}
                            fullWidth
                            error={errorField == "address"}
                            errorMessage={errorMessage}
                        />

                        <Flex between style={{ marginTop: 40 }}>
                            {<Button weight='500' onClick={() => this.validateFlow()} text={this.props.newOwner ? "CONFIRM" : "SAVE CHANGES"} solid radius='4px' />}
                            {this.props.newOwner ? null : <TextButton onClick={() => this.props.onDelete(ownerInfo.email)} icon={<DeleteOutlined style={{ color: "red" }} />} />}
                        </Flex>
                    </div>
                </Flex>
            </FlexColumn>
        );
    }
}


function mapStateToProps(state) {
    return {
        store: state.aionAppReducer,
        creditStore: state.creditAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(AddOwnerModal)));