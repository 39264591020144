import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';

import { Skeleton, Modal } from 'antd';
import { FlexColumn } from '../../Reusable/Container';
import { ErrorAlert } from '../../Reusable/Alert';
import { Text } from '../../Reusable/Text';
import { TextButton } from '../../Reusable/Button';
import { uaApiPOST } from '../../../Utils/userAccess';
import ModalClose from '../../../Images/modal-close.png'
import ArrowRight from '../../../Images/arrow-right.png'
import Key from './Key';
import WhitelistedIPs from './WhitelistedIPs'
import Banner from '../../Reusable/Banner';

class Index extends Component {
    state = {
        visible: false,
        generateNewKeyLoading: false,
        copyStatus: "Copy text",
        showWhitelistedIPs: false,
    };

    componentDidMount() {
        this.fetchKeys(true);
    }

    // API Keys
    fetchKeys = (load) => {
        this.setState({ loading: load });
        uaApiPOST('/api/mgmt/getAPIKeyRegistryByBusiness', null, (err, resp) => {
            console.log('getAPIKeyRegistryByBusiness: ', resp)
            this.setState({ loading: false });
            try {
                if (err) throw Error(err);
                if (resp.error || resp.responseMessage) throw Error(resp.error || resp.responseMessage);
                this.setState({ ...resp });
            } catch (error) {
                console.log("getAPIKeyRegistryByBusiness error", error, resp);
                ErrorAlert({ description: error.message });
            }
        })
    }

    render() {
        const { theme } = this.props;
        var { loading, aPIKeyRegistry, loadingSaveIP, showWhitelistedIPs } = this.state;
        var { apikey, whiteListIP } = aPIKeyRegistry || {};
        whiteListIP = whiteListIP || [];
        console.log("aPIKeyRegistry", aPIKeyRegistry);
        const iconSize = "18px";
        return (
            <FlexColumn start style={{ marginTop: 20 }}>
                <Text caption>API</Text>
                <Skeleton loading={loading} active title={true} paragraph={{ rows: 5 }}>
                    <Key />
                </Skeleton>

                {/* <TextButton margin='24px 0' loading={loadingSaveIP} onClick={() => this.setState({ showWhitelistedIPs: !showWhitelistedIPs })} text={'Whitelisted IP Addressess'.toUpperCase()} rightIcon={<img height='24px' width='24px' src={ArrowRight} />} /> */}

                <Modal
                    visible={showWhitelistedIPs}
                    footer={null}
                    closable={true}
                    width={620}
                    destroyOnClose={true}
                    onCancel={() => {
                        this.setState({ showWhitelistedIPs: false })
                    }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <WhitelistedIPs whitelistedIPs={whiteListIP} onSubmit={() => {
                        this.fetchKeys(false)
                        this.setState({ showWhitelistedIPs: false })
                    }} />
                </Modal>
            </FlexColumn>
        );
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer,
        bankingStore: state.bankingAppReducer,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Index));