import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import { Flex, FlexColumn } from './Container'
import { Button, TextButton } from './Button'

const FlowFooter = (props) => {
    const { buttonTitle, solid, onClick, steps, step, backClick, loading, disabled, cancel, onCancel, secondaryCTA, secondaryCTATitle, onClickSecondaryCTA, secondaryCTALoading, start } = props

    return (
        <Flex start={start} end={!start} centerHorizontally fullWidth gap='24px' style={{ height: 88, background: '#FBFBFB', borderRadius: '0px 0px 8px 8px', padding: 24 }}>
            <Button solid={solid} disabled={disabled} loading={loading} background={!solid && 'transparent'} onClick={onClick} text={buttonTitle} />
            {
                cancel &&
                <TextButton text='CANCEL' onClick={onCancel} />
            }
            {
                secondaryCTA &&
                <TextButton text={secondaryCTATitle} onClick={onClickSecondaryCTA} loading={secondaryCTALoading} />
            }
        </Flex>
    )
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(FlowFooter)))