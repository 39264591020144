import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import { Tabs, Modal, Space, Select, Switch, message, Alert, Dropdown, Menu } from 'antd'
import OAuthClient from 'intuit-oauth'
import queryString from 'query-string'

import moment from 'moment'
import _, { cloneDeep } from 'lodash'
import { Flex, FlexColumn, CardContainer } from '../../Reusable/Container'
import PageHeader from '../../Reusable/PageHeader'
import { Button, TextButton, Chip, ImageButton } from '../../Reusable/Button'
import ConnectionModal from '../../Reusable/ConnectionModal'
import { Image } from '../../Reusable/Image'
import QBOInvoices from './QBOInvoices'
import NewInvoice from './New Invoice/Index'
import InvoiceTable from './InvoiceTable'
import SearchAndFilter from './SearchAndFilter'
import { apiGET, apiPOSTReq, apiPOST } from '../../../Utils/api'
import { getFeaturePerm, getUserRoles, showCodatConnectionBanner } from '../../../Utils/util'
import environment from '../../../environment'
import { ErrorAlert } from '../../Reusable/Alert'
import AlertModal from '../../Reusable/AlertModal'
import { LabeledInput } from '../../Reusable/Input'
import { Text } from '../../Reusable/Text'
import Banner from '../../Reusable/Banner'
import BoldBanner from '../../Reusable/BoldBanner'
// import qbButton from '../../../Images/C2QB_auth.png'
import qbButton from '../../../Images/C2QB_green_btn_tall_hover.svg'
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons'
import ModalClose from '../../../Images/modal-close.png'
import Lightning from '../../../Images/lightning-dark.svg';
import ArrowRight from "../../../Images/arrow-right.png"
import Void from './Void'
import Info from '../../../Images/info.png';

// Actions
import { CREDIT_SAVE_DATA, addDataToStore, RECEIVABLES_SAVE_DATA, SAVE_DATA, CODAT_DATA } from '../../../Actions/actions'
import { getUserApps } from '../../../Utils/util'
import { saveAs } from 'file-saver'

const { TabPane } = Tabs
const { Option } = Select

var dFormat = "YYYY-MM-DD"

class Index extends Component {

    state = {
        loading: true,
        selectedTab: "Aion",
        pagination: {
            current: 1,
            pageSize: 10,
            showSizeChanger: false,
        },
        sorter: {},
        sortDirection: "DESC",
        showClear: false,
        selectedRowsPageDict: {},
        selectedRowKeyPageDict: {},
        filterStatus: this.props.location.state?.tab || 'draft',
    }

    componentDidMount() {
        this.fetchCOA()
        this.fetchBorrowerCustomers()
        const userInfo = this.props.aionStore?.userInfo;
        const businessInfo = this.props.aionStore?.business?.businessProfile?.businessInfo;
        this.setState({ ccAddr: userInfo.email, reminderTeamName: businessInfo?.name })
        this.fetchInvoices({ ...this.state })
        const userApps = getUserApps(this.props.aionStore)
        if (userApps && userApps.includes("ABLCredit")) {
            this.setState({ ABLUser: true })
        } else {
            this.setState({ ABLUser: false })
        }
        this.fetchBusinessApps()
        const value = queryString.parse(this.props.location.search)
        const state = value.state
        const url = window.location.href

        if (url.includes('reconnect_qb')) {
            this.setState({
                accountingSoftware: true
            })
        }

        if (state === 'qb-invoices') {
            // this.props.history.replace('/receivables/invoices/add?reconnect_qb=true')
            this.props.history.replace('/receivables/invoices')
            this.setState({
                accountingSoftware: true,
                invoiceRefreshLoading: true
            })

            if (value.code && value.realmId) {
                const headers = {
                    businesskey: this.props.aionStore.BusinessUniqueKey
                }

                apiGET(`/financing/oauth/token?url=${url}`, headers, (err, resp) => {
                    try {
                        const token = resp.data.Response
                        if (resp.data.success) {

                            const data = {
                                accessToken: token.access_token,
                                accessTokenExpiresIn: token.expires_in,
                                accessTokenSecret: "",
                                companyId: value.realmId,
                                accountingSoftware: "QuickBooksOnline",
                                dateCreated: Date.now(),
                                refreshToken: token.refresh_token,
                                refreshTokenExpiresIn: token.x_refresh_token_expires_in,
                                oauthVersion: "2.0",
                                refreshTokens: true
                            }

                            apiPOST('accountingsoftwareinfo', headers, data, (err, resp) => {
                                console.log("accountingsoftwareinfo resp: " + JSON.stringify(resp))
                                if (!err) {
                                    try {
                                        const data = resp.data

                                        if (data.success) {
                                            this.setState({ qbExpired: false, selectedTab: "AccountingSW" })
                                            message.success('QuickBooks reconnected successfully!')
                                            this.refreshInvoices() // refreshInvoices() will call loadInvoicesForApprovedCustomers()
                                        } else {
                                            message.error('Sorry, we had trouble reconnecting your QuickBooks. Please try again.')
                                            throw Error("Could not complete submission.")
                                        }
                                    } catch (error) {
                                        console.error("The error message is :" + JSON.stringify(error))
                                        message.error('Sorry, we had trouble reconnecting your QuickBooks. Please try again.')
                                    }
                                } else {
                                    console.error("The error message is :" + JSON.stringify(err))
                                    message.error('Sorry, we had trouble reconnecting your QuickBooks. Please try again.')
                                }
                                this.setState({ invoiceRefreshLoading: false })
                            })
                        } else {
                            console.error("financing/oauth/token", token)
                            message.error('Sorry, we had trouble reconnecting your QuickBooks. Please try again.')
                            this.setState({ invoiceRefreshLoading: false })
                            throw Error("Could not fetch attributes.")
                        }
                    } catch (error) {
                        console.error("ERROR", error.stack)
                        message.error('Sorry, we had trouble reconnecting your QuickBooks. Please try again.')
                        this.setState({ invoiceRefreshLoading: false })
                    }
                })
            }
        }

        this.getConnections()

        const urlStr = this.props.location.search.replace('?', '')
        const queryParams = new URLSearchParams(urlStr)
        const statusCode = queryParams.get('statuscode')
        const errorMessage = queryParams.get('errormessage')
        const statusText = queryParams.get('statustext')
        // http://{website}/{path}/?statuscode={statusCode}&errormessage={errorMessage}&statusText={statusText}
        // Check if redirected from codat
        if (statusCode) {
            console.log("BillPayments Inbox statusCode", statusCode)
            if (statusCode == 200) {
                this.fetchCOA({ coaPagination: this.state.coaPagination })
                this.getConnections(true)
            } else {
                ErrorAlert({ description: errorMessage || statusText || "Sorry we had trouble processing your request. Please try connecting again" })
            }
        } else {
            console.log("BillPayments Inbox NO statusCode")
        }
    }

    getConnections = (showSuccess) => {
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/codat/getConnections`, {}, {}, (err, resp) => {
            try {
                const data = resp || {};
                if (data.result) {
                    var connections = data.connections || []
                    console.log("BillPayments Inbox /integration/codat/getConnections data", data)
                    this.props.dispatch(addDataToStore(CODAT_DATA, { connections: connections, connection: connections.length > 0 && connections[0], company: data.company }))
                    if (showSuccess) {
                        if(connections.length > 0) this.updateConnection(connections[0])
                        this.setState({ showSyncSetupComplete: true })
                    }
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("BillPayments Inbox getConnections error", { description: error.message })
            }
        })
    }

    updateConnection = (connection) => {
        console.log("BillPayments Inbox updateConnection", connection)
        var body = {
            companyId: connection.companyId,
            connectionId: connection.connectionId
        }
        console.log("BillPayments Inbox updateConnection body", body)
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/codat/updateConnection`, {}, body, (err, resp) => {
            try {
                const data = resp || {};
                if (data.result) {
                    console.log("BillPayments Inbox updateConnection data", data)
                    this.props.dispatch(addDataToStore(CODAT_DATA, { connection: data.connection }))
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                ErrorAlert({ description: error.message })
            }
        })
    }

    fetchBorrowerCustomers = () => {
        var body = {
            page: 0,
            size: 10000,
        }
        apiPOSTReq(`${environment.iveBaseUrl}/ive/bc/getActiveCustomers`, {}, body, (err, resp) => {
            try {
                const data = resp
                console.log("/getActiveCustomers", data)
                if (data.result) {
                    this.setState({ activeCustomers: data.customers })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getActiveCustomers", error.stack, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    fetchBusinessApps = () => {
        apiPOSTReq(`${environment.uamBaseUrl}/aion/system/getAvailableApplications`, {}, {}, (err, resp) => {
            try {
                if (err) throw Error(err)
                const data = resp || {}
                if (data.result) {
                    console.log("/aion/system/getAvailableApplications", data)
                    var { applicationNames } = data || {}
                    if ((applicationNames || []).includes("ABLCredit")) {
                        this.setState({ ABLUser: true })
                    } else {
                        this.setState({ ABLUser: false })
                    }
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/aion/system/getAvailableApplications err", error, resp)
            }
        })
    }

    handleTabChange = (key) => {
        this.setState({
            selectedTab: key,
            pagination: {
                current: 1,
                pageSize: 10,
                showSizeChanger: false,
            },
        })
        if (key === 'Aion') {
            this.fetchInvoices({ pagination: this.state.pagination })
        }
    }

    handleStatusTabChange = (key) => {
        const { pagination } = this.state

        this.setState({
            filterStatus: key, pagination: {
                current: 1,
                pageSize: 10,
                showSizeChanger: false,
            }
        }, () => {
            this.fetchInvoices({ pagination, ...this.state })

            this.props.history.replace({
                pathname: '/receivables/invoices',
                state: { tab: key }
            })
        })
    }

    handleSearchChange = (e) => {
        this.setState({ [e.target.id]: e.target.value })

        const pagination = {
            current: 1,
            pageSize: 10
        }
        this.fetchInvoices({
            searchTerm: e.target.value,
            pagination,
        })
    }

    showSelectedInvoice = (selectedInvoice) => {
        var v2BetaEnabled = getFeaturePerm("Receivables.Invoices.Receivables v2 Beta")

        if (v2BetaEnabled.manage) {
            this.props.history.push({
                pathname: '/receivables/invoices/create',
                state: { invoice: selectedInvoice }
            })
        } else {
            this.setState({ selectedInvoice: selectedInvoice, showInvoiceModal: true })
            if (selectedInvoice) {
                this.props.dispatch(addDataToStore(RECEIVABLES_SAVE_DATA, { editedInvoice: cloneDeep(selectedInvoice) }))
            } else {
                this.props.dispatch(addDataToStore(RECEIVABLES_SAVE_DATA, { editedInvoice: null }))
            }
        }
    }

    shouldShowQBTab = () => {
        const { Attributes, CurrentSync } = this.props.aionStore
        var { Businesses } = Attributes || {}
        var businessAttributes = Businesses || {}
        let shouldShowQBTab = false

        shouldShowQBTab = businessAttributes.AccountingSWLinked
        if (this.props.aionStore.hasIntegration) {
            if (CurrentSync && CurrentSync.syncSetupCompleted) {
                // has live integration
                shouldShowQBTab = false
            }
        }

        return shouldShowQBTab
    }

    fetchInvoices = (options) => {
        var { sortDirection, sortFieldname, filterStatus } = this.state
        var { pagination, fromDate, toDate, filterType, sorter, searchTerm, filterInvoiceFromDate, filterInvoiceToDate, filterDueFromDate, filterDueToDate, filterFromAmount, filterToAmount, filterCustomerName, amountError } = options
        filterType = filterType || []
        sorter = sorter || {}

        const v2BetaEnabled = getFeaturePerm("Receivables.Invoices.Receivables v2 Beta")

        const shouldShowQBTab = this.shouldShowQBTab()

        let source = shouldShowQBTab ? "AION|ARUPLOAD" : "AION|ARUPLOAD|QUICKBOOKS|CODAT"

        var body = {
            "size": pagination.pageSize,
            "page": pagination.current - 1,
            "source": source,
            "viewBy": "FILTER_SOURCE",
            "sortDirection": sortDirection
        }

        if (filterInvoiceFromDate) {
            body.viewBy = "FILTER_INVOICE_DATE"
            body.invoiceFromDate = filterInvoiceFromDate
            body.invoiceToDate = filterInvoiceToDate
        }
        if (filterDueFromDate) {
            body.viewBy = "FILTER_DUE_DATE"
            body.dueFromDate = filterDueFromDate
            body.dueToDate = filterDueToDate
        }

        if ((filterFromAmount || filterToAmount) && !amountError) {
            body.viewBy = "FILTER_AMOUNT"
            body.fromAmount = filterFromAmount
            body.toAmount = filterToAmount
        }

        if (filterCustomerName) {
            body.viewBy = "FILTER_CUSTOMER"
            body.customerName = filterCustomerName
        }

        if (v2BetaEnabled.manage && filterStatus) {
            body.viewBy = "FILTER_STATUS"
            body.invoiceStatus = filterStatus.toUpperCase()
        }

        if (sorter.field) {
            if (sorter.field === sortFieldname) {
                body.sortDirection = (sortDirection === "DESC") ? "ASC" : "DESC"
            } else {
                body.sortDirection = "ASC"
            }
            body.sortFieldname = sorter.field
        } else {
            if (sortFieldname) {
                body.sortFieldname = sortFieldname
            }
            body.sortDirection = sortDirection
        }

        if (searchTerm && searchTerm != "") {
            body.searchStr = searchTerm
        }

        if (filterType.length > 0) {
            var emailConstants = ["Delivered", "Opened", "Clicked"]
            var invStatus = filterType.filter(item => !emailConstants.includes(item)).join("|")
            var emailStatus = filterType.filter(item => emailConstants.includes(item)).join("|")

            // Check if filtering by date
            if (filterType.find(item => emailConstants.includes(item))) {
                body.viewBy = (body.viewBy == "FILTER_TXN_DATE") ? "FILTER_EMAIL_STATUS_TXN_DATE" : "FILTER_EMAIL_STATUS"
            } else {
                body.viewBy = (body.viewBy == "FILTER_TXN_DATE") ? "FILTER_STATUS_TXN_DATE" : "FILTER_STATUS"
            }
            body.status = invStatus
            body.emailStatus = emailStatus
        }

        this.setState({ ...options, loading: true })

        console.log('getInvoices body', body)

        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/getInvoices`, null, body, (err, resp) => {
            try {
                const data = resp || {}
                console.log("/getInvoices", data)
                if (data.result) {
                    var invoices = this.state.pagination.current > 1 ? this.state.invoices.concat(data.invoices) : data.invoices
                    var moreInvoices = invoices.length == data.count ? false : true
                    this.setState({
                        invoices: invoices,
                        loading: false,
                        pagination: {
                            ...pagination,
                            total: data.count
                        },
                        // sorter: _.isEqual(sorter, {}) ? this.state.sorter : sorter,
                        sortDirection: body.sortDirection,
                        sortFieldname: body.sortFieldname,
                        showClear: searchTerm ? true : false,
                        moreToLoad: moreInvoices
                    })

                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getInvoices", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    fetchCOA = () => {
        const headers = {
            AionCurrentBiz: this.props.aionStore.BusinessUniqueKey,
            AionAuth: this.props.aionStore.UAM.encryptedAuthHeader
        }

        const body = {
            "BusinessId": this.props.aionStore.BusinessUniqueKey,
            "size": 1000,
            "page": 0,
            "ActiveRecords": true,
            "sortFieldName": "Code",
            "sortDirection": "ASC"
        }

        apiPOSTReq(`${environment.bbBaseUrl}/bk/getCategories`, headers, body, (err, resp) => {
            try {
                const data = resp
                console.log("/bk/getCategories", data)
                if (data.result) {
                    this.setState({ categories: (data.systemCOAList || []).concat(data.customCOAList) })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getCategories", error.stack, resp)
                // ErrorAlert({ description: error.message })
            }
        })
    }

    fetchQBInvoices = (options) => {
        var { sortDirection, sortFieldname } = this.state
        var { pagination, fromDate, toDate, filterType, sorter, searchTerm } = options
        filterType = filterType || []
        sorter = sorter || {}

        if (!fromDate) fromDate = this.state.fromDate
        if (!toDate) toDate = this.state.toDate

        var body = {
            "size": pagination.pageSize,
            "page": pagination.current - 1,
            "source": "QUICKBOOKS",
            "viewBy": "FILTER_SOURCE",
            // "sortDirection": sortDirection
        }

        if (sorter.field) {
            if (sorter.field === sortFieldname) {
                body.sortDirection = (sortDirection === "DESC") ? "ASC" : "DESC"
            } else {
                body.sortDirection = "ASC"
            }
            body.sortFieldname = sorter.field
        } else {
            if (sortFieldname) {
                body.sortFieldname = sortFieldname
            }
            body.sortDirection = sortDirection
        }

        if (fromDate) {
            body.viewBy = "FILTER_TXN_DATE"//"FILTER_CREATE_DATE"
            body.fromDate = fromDate
            body.toDate = toDate
        }

        if (searchTerm && searchTerm != "") {
            body.searchStr = searchTerm
        }

        if (filterType.length > 0) {
            var emailConstants = ["Delivered", "Opened", "Clicked"]
            var invStatus = filterType.filter(item => !emailConstants.includes(item)).join("|")
            var emailStatus = filterType.filter(item => emailConstants.includes(item)).join("|")

            // Check if filtering by date
            if (filterType.find(item => emailConstants.includes(item))) {
                body.viewBy = (body.viewBy == "FILTER_TXN_DATE") ? "FILTER_BY_EMAIL_STATUS_TXN_DATE" : "FILTER_EMAIL_STATUS"
            } else {
                body.viewBy = (body.viewBy == "FILTER_TXN_DATE") ? "FILTER_BY_STATUS_TXN_DATE" : "FILTER_STATUS"
            }
            body.status = invStatus
            body.emailStatus = emailStatus
        }

        this.setState({ loading: true, fromDate, toDate })

        console.log('getInvoices body', body)
        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/getInvoices`, null, body, (err, resp) => {
            try {
                const data = resp || {}
                // console.log("/getInvoices", data)
                if (data.result) {

                    console.log("invoice/getInvoices", JSON.stringify(data))

                    this.setState({
                        ...options,
                        qbInvoices: data.invoices,
                        loading: false,
                        pagination: {
                            ...pagination,
                            total: data.count
                        },
                        // sorter: _.isEqual(sorter, {}) ? this.state.sorter : sorter,
                        sortDirection: body.sortDirection,
                        sortFieldname: body.sortFieldname,
                        showClear: searchTerm ? true : false,
                        moreToLoad: true
                    })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getInvoices", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    refreshInvoices = () => {
        this.handleRefreshButtonLoading(true)

        const headers = {
            businesskey: this.props.aionStore.BusinessUniqueKey
        }

        apiGET("/refreshinvoices", headers, (err, resp) => {
            this.handleRefreshButtonLoading(false)
            this.fetchInvoices({ pagination: this.state.pagination })
            try {
                const data = (resp || {}).data || {}
                console.log("/refreshinvoices resp", resp)
                if (data.success) {
                    this.props.dispatch(addDataToStore(CREDIT_SAVE_DATA, data))
                } else {
                    if (data.msg = "Tokens Expired") {
                        this.setState({ qbModalVisible: true, qbExpired: true })
                    }
                }
            } catch (error) {
                console.log("ERRR", error, err, resp)
            }
            this.fetchQBInvoices({ pagination: this.state.pagination })
        })
    }

    onAddInvoiceViaQB = () => {
        if (this.props.qbExpired) {
            this.props.reconnectQB()
        } else {
            this.props.history.push('/receivables/invoices/add', { accountingSoftware: true })
        }
    }

    handlePaymentReminder = () => {
        var { selectedRows, ccAddr, reminderTeamName } = this.state
        const userInfo = this.props.aionStore?.userInfo;
        var invoices = selectedRows || []
        this.setState({ loadingPaymentReminder: true })
        var body = {
            invoiceIdList: invoices.map(item => item.invoiceId),
            modifiedByUserName: `${userInfo.firstName} ${userInfo.lastName}`,
        }
        if (ccAddr) body["ccAddr"] = ccAddr
        if (reminderTeamName) body["reminderTeamName"] = reminderTeamName
        console.log("/ive/invoice/sendPaymentReminder", body)
        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/sendPaymentReminder`, {}, body, (err, resp) => {
            this.setState({ loadingPaymentReminder: false })
            try {
                const data = resp
                console.log("POST success /ive/invoice/sendPaymentReminder", data)
                if (data.result) {
                    this.setState({ showPaymentReminderModal: false, enablePaymentReminder: false, selectedRows: [], selectedRowKeys: [] }) // reset
                    message.success(`Payment reminder was sent`)
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("Err /ive/invoice/sendPaymentReminder", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    getSelectedRows = (selectedRowsPageDict) => {
        let selectedRows = []
        Object.values(selectedRowsPageDict).forEach(x => {
            selectedRows = selectedRows.concat(x)
        })
        return selectedRows
    }

    getSelectedRowKeys = (selectedRowKeyPageDict) => {
        let selectedRowKeys = []
        Object.values(selectedRowKeyPageDict).forEach(x => {
            selectedRowKeys = selectedRowKeys.concat(x)
        })
        return selectedRowKeys
    }

    handleRowSelection = (selectedRowKeys, selectedRows) => {
        let selectedRowsPageDict = this.state.selectedRowsPageDict
        let selectedRowKeyPageDict = this.state.selectedRowKeyPageDict

        selectedRowsPageDict[this.state.pagination.current] = selectedRows
        selectedRowKeyPageDict[this.state.pagination.current] = selectedRowKeys
        let newSelectedRows = this.getSelectedRows(selectedRowsPageDict)
        let newSelectedRowKeys = this.getSelectedRowKeys(selectedRowKeyPageDict)

        this.setState({ selectedRows: newSelectedRows, selectedRowKeys: newSelectedRowKeys })
    }

    handleNewInvoice = () => {
        var v2BetaEnabled = getFeaturePerm("Receivables.Invoices.Receivables v2 Beta")

        if (v2BetaEnabled.manage) {
            this.props.history.push('/receivables/invoices/create')
        } else {
            this.setState({ loadingNewInvoice: true })

            apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/generateTemporaryDocNumber`, {}, {}, (err, resp) => {
                this.setState({ loadingNewInvoice: false })
                try {
                    const data = resp
                    console.log("POST success /ive/invoice/generateTemporaryDocNumber", data)
                    if (data.result) {
                        this.setState({ showInvoiceModal: true, selectedInvoice: data })
                    } else {
                        throw Error(data.responseMessage || data.error)
                    }
                } catch (error) {
                    console.log("Err /ive/invoice/generateTemporaryDocNumber", error, err, resp)
                    ErrorAlert({ description: error.message })
                }
            })
        }
    }

    handleRefreshButtonLoading = (loading) => {
        this.setState({ invoiceRefreshLoading: loading })
    }

    reconnectQuickBooks() {
        this.setState({ qbModalVisible: false })
        var oauthClient = new OAuthClient(environment.qboOAuth)

        const authUri = oauthClient.authorizeUri({
            scope: [
                OAuthClient.scopes.Accounting,
                OAuthClient.scopes.OpenId,
                OAuthClient.scopes.Profile,
                OAuthClient.scopes.Email,
                OAuthClient.scopes.Phone,
                OAuthClient.scopes.Address
            ],
            state: 'qb-invoices'
        })

        window.location.href = authUri
    }

    loadMoreInvoices = () => {
        var updatedState = {
            dateType: "Custom",
            loading: true,
            pagination: {
                current: this.state.pagination.current + 1,
                pageSize: 10
            }
        }
        this.fetchInvoices({ ...this.state, ...updatedState })
    }

    voidInvoice = (invoice) => {
        const userInfo = this.props.aionStore?.userInfo;
        this.setState({ voidLoading: true })

        var body = {
            invoiceId: invoice.invoiceId,
            modifiedByUserName: `${userInfo.firstName} ${userInfo.lastName}`,
        }

        console.log("Invoices invoice/voidInvoice body", invoice)

        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/voidInvoice`, null, body, (err, resp) => {
            try {
                const data = resp || {}

                console.log("Invoices invoice/voidInvoice", data)

                this.setState({ showVoidModal: false })
                this.fetchInvoices({ ...this.state })
                message.success('Invoice voided!')
            } catch (error) {
                console.log("ERRR voidInvoice", error, err, resp)
                ErrorAlert({ description: error.message })
            } finally {
                this.setState({ voidLoading: false })
            }
        })
    }

    render() {
        const { selectedTab, loading, invoices, voidLoading, filterStatus, invoiceToVoid, showSyncSetupComplete,
            enablePaymentReminder, selectedRows, selectedRowKeys, loadingPaymentReminder, ccAddr, reminderTeamName, moreToLoad, showVoidModal,
            sorter, invoiceRefreshLoading, ABLUser, activeCustomers, showConnectAS, showDismissConnectWarning } = this.state
        const { theme, aionStore } = this.props

        var dropdownOptions = []
        var dateOptions = ["Year to Date", "Current Month", "Previous Month", "Last Year", "Custom"]
        var statusFilterOptions = ["New", "Scheduled", "Pending", "Sent", "Canceled", "Delivered", "Opened", "Clicked"]
        dropdownOptions = dateOptions.map(item => (
            <Option key={item} value={item}>
                {item}
            </Option>
        ))

        let ARUser = false
        const userApps = getUserApps(this.props.aionStore)
        if (userApps && userApps.includes("ARCredit")) {
            ARUser = true
        }
        console.log("getAvailableApplications ABLUser", ABLUser)
        const shouldShowQBTab = this.shouldShowQBTab()

        const v2BetaEnabled = getFeaturePerm("Receivables.Invoices.Receivables v2 Beta")

        const showConnectionBanner = showCodatConnectionBanner(aionStore)

        return (
            <>
                <FlexColumn className='main-padding'>
                    <PageHeader
                        titleText="Receivables"
                        subtitle="Receivables"
                    />

                    {
                        showConnectionBanner &&
                        <BoldBanner
                            icon={<Image src={Lightning} />}
                            style={{ marginTop: "0", marginBottom: 24, width: "100%" }}
                            message="Sync your invoices, customers and more to your accounting system. Skip all the manual work"
                            cta={<TextButton underline color={theme.colors.primary2} text={'Get started now'} onClick={() => {
                                this.setState({ showConnectAS: true })
                                // this.props.history.push("/settings/sync")
                            }} />}
                            onClose={() => {
                                this.setState({ showDismissConnectWarning: true })
                                this.props.dispatch(addDataToStore(SAVE_DATA, { connectASBannerDismissed: true }))
                            }}
                        />
                    }

                    {
                        !v2BetaEnabled.manage && shouldShowQBTab &&
                        <Tabs size="large" defaultActiveKey={selectedTab} onChange={this.handleTabChange}>
                            <TabPane tab="Aion Invoices" key="Aion" />
                            <TabPane tab="QuickBooks Invoices" key="AccountingSW" />
                        </Tabs>
                    }

                    {
                        selectedTab === "Aion" &&
                        <>
                            <FlexColumn style={{ marginBottom: 12 }}>
                                {
                                    v2BetaEnabled.manage &&
                                    <Flex between centerHorizontally style={{}}>
                                        <Flex start style={{ width: '100%' }}>
                                            <Tabs size="large" style={{ width: '100%' }} defaultActiveKey={filterStatus} onClick={() => { }} onChange={this.handleStatusTabChange} >
                                                <TabPane tab="Draft" key="draft"></TabPane>
                                                <TabPane tab="Unpaid" key="unpaid"></TabPane>
                                                <TabPane tab="Paid" key="paid"></TabPane>
                                                <TabPane tab="Voided" key="voided"></TabPane>
                                            </Tabs>
                                        </Flex>
                                    </Flex>
                                }
                            </FlexColumn>

                            <InvoiceTable
                                showSelectedInvoice={this.showSelectedInvoice}
                                loading={loading}
                                pagination={false}
                                onLoadMore={() => this.loadMoreInvoices()}
                                moreToLoad={moreToLoad}
                                sorter={sorter}
                                fetchInvoices={this.fetchInvoices}
                                invoices={invoices}
                                handleRowSelection={this.handleRowSelection}
                                showRowSelection={enablePaymentReminder}
                                selectedRowKeys={selectedRowKeys}
                                ablUser={ABLUser}
                                onVoid={(invoiceToVoid) => this.setState({ showVoidModal: true, invoiceToVoid })}
                                showCustomer
                                ctaContent={
                                    (ABLUser === false) &&
                                    <Flex start fullWidth gap='24px'>
                                        <SearchAndFilter
                                            invoices={invoices}
                                            customers={activeCustomers}
                                            fetchInvoices={this.fetchInvoices}
                                            handleSearchChange={this.handleSearchChange}
                                            onEnablePaymentReminder={() => this.setState({ enablePaymentReminder: !this.state.enablePaymentReminder })}
                                            showPaymentReminderModal={() => this.setState({ showPaymentReminderModal: true })}
                                            enablePaymentReminder={enablePaymentReminder}
                                            selectedRows={selectedRows}
                                            filterStatus={filterStatus}
                                        />
                                        {
                                            (selectedTab !== "Aion") ? [
                                                <FlexColumn center style={{}}>
                                                    <Flex>
                                                        {ARUser && <Button loading={invoiceRefreshLoading} permtype="Receivables.Invoices" key="QBO" onClick={this.refreshInvoices} text='Refresh from QuickBooks' />}
                                                        {ARUser && <Button margin='0 0 0 24px' permtype="Receivables.Invoices" solid key="submit" onClick={this.onAddInvoiceViaQB} text='Submit Invoices' />}
                                                    </Flex>
                                                    {
                                                        invoiceRefreshLoading &&
                                                        <Text color={theme.colors.systemGray}>Pulling your invoices, customers & products from QuickBooks...</Text>
                                                    }
                                                </FlexColumn>
                                            ]
                                                :
                                                <Button solid text="ADD INVOICE" icon={<PlusOutlined style={{ color: 'white' }} />} permtype="Receivables.Invoices" loading={this.state.loadingNewInvoice} onClick={this.handleNewInvoice} />
                                        }
                                    </Flex>
                                }
                            />
                        </>
                    }
                    {
                        selectedTab === "AccountingSW" &&
                        <QBOInvoices
                            handleRefreshButtonLoading={this.handleRefreshButtonLoading}
                            showSelectedInvoice={this.showSelectedInvoice}
                            fetchQBInvoices={this.fetchQBInvoices}
                            qbInvoices={this.state.qbInvoices || []}
                            pagination={this.state.pagination}
                            fromDate={this.state.fromDate}
                            toDate={this.state.toDate}
                            dateType={this.state.dateType}
                        />
                    }
                </FlexColumn>
                <Modal
                    visible={this.state.showInvoiceModal}
                    footer={null}
                    closable={true}
                    maskClosable={true}
                    width={960}
                    destroyOnClose={true}
                    // className="scroll-overflow-95"
                    // style={{ top: 20 }}
                    onCancel={() => { this.setState({ showInvoiceModal: false }) }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <NewInvoice
                        invoice={this.state.selectedInvoice}
                        refreshInvoices={() => {
                            // this.setState({ showInvoiceModal: false })
                            this.fetchInvoices({ pagination: this.state.pagination })
                        }}
                        close={() => this.setState({ showInvoiceModal: false })}
                        categories={this.state.categories}
                    />
                </Modal>
                <Modal
                    visible={this.state.showPaymentReminderModal}
                    footer={null}
                    closable={true}
                    width={500}
                    destroyOnClose={true}
                    style={{ top: 20 }}
                    onCancel={() => { this.setState({ showPaymentReminderModal: false }) }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <FlexColumn>
                        <Text size='20px'>Payment reminder</Text>
                        <Text color={theme.colors.systemGray} >You have selected <b>{(selectedRows || []).length} invoices</b>. A reminder will be sent for these.</Text>
                        <LabeledInput
                            label="Email CC"
                            labelcolor={theme.colors.secondary3}
                            id="ccAddr"
                            key="ccAddr"
                            placeholder="Enter an email address"
                            value={ccAddr}
                            onChange={(e) => this.setState({ [e.target.id]: e.target.value })}
                            instruction="This email will receive a copy of the payment reminder"
                        />
                        <LabeledInput
                            label="From Name"
                            labelcolor={theme.colors.secondary3}
                            id="reminderTeamName"
                            key="reminderTeamName"
                            placeholder="Enter a name"
                            value={reminderTeamName}
                            onChange={(e) => this.setState({ [e.target.id]: e.target.value })}
                            instruction="This name will show as the from name on the email"
                        />
                        <Button solid width='150px' permtype="Override" margin='20px 0 0' loading={loadingPaymentReminder} onClick={this.handlePaymentReminder} text="Send Reminder" />
                    </FlexColumn>
                </Modal>
                <Modal
                    visible={this.state.qbModalVisible}
                    title="Reconnect QuickBooks"
                    okText="Save"
                    onCancel={() => (this.setState({ qbModalVisible: false }))}
                    confirmLoading={this.state.confirmLoading}
                    destroyOnClose={true}
                    width={700}
                    footer={null}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <div className="flex-column-center" style={{ textAlign: 'center', paddingBottom: '20pt' }}>
                        <Text strong={true} style={{ fontSize: '12pt', marginBottom: '12pt' }}>We need you to reconnect your QuickBooks account<br />before you can refresh your invoices</Text>
                        <Banner title="QuickBooks Master admin or Company admin rights required" message="Make sure you have the right permissions. You will need master admin or company rights to connect to QuickBooks" />
                        <br />
                        <Text>You will be redirected to QuickBooks to provide authorization to Aion</Text>
                        <Text style={{ margin: '32pt 0 12pt 0' }}>Tap on the button below to<br />reconnect you QuickBooks account</Text>
                        <img style={{ height: '40pt' }} src={qbButton} onClick={() => { this.reconnectQuickBooks() }} />
                        <br />
                        <Alert message="We do not store or have access to your QuickBooks credentials" type="info" />
                    </div>
                </Modal>

                <Modal
                    visible={showVoidModal}
                    footer={null}
                    closable={false}
                    width={456}
                    destroyOnClose={true}
                    onCancel={() => {
                        this.setState({ showVoidModal: false })
                    }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <Void
                        loading={voidLoading}
                        invoice={invoiceToVoid}
                        onCancel={() => this.setState({ showVoidModal: false })}
                        onVoid={(invoiceToVoid) => {
                            this.voidInvoice(invoiceToVoid)
                        }}
                    />
                </Modal>

                <Modal
                    visible={showConnectAS}
                    footer={null}
                    closable={true}
                    maskClosable={true}
                    width={697}
                    destroyOnClose={true}
                    onCancel={() => this.setState({ showConnectAS: false })}
                    closeIcon={<Image src={ModalClose} />}
                >
                    <ConnectionModal onCancel={() => this.setState({ showConnectAS: false })} />
                </Modal>

                <AlertModal
                    imgSrc={Info}
                    visible={showDismissConnectWarning}
                    title='Accessing your Connections'
                    description={<Text>You can go to Business Profile {'>'} Accounting Systems anytime to connect Aion to your accounting system.</Text>}
                    buttonTitle='OKAY'
                    onConfirm={() => this.setState({ showDismissConnectWarning: false })}
                />

                <AlertModal
                    success
                    visible={showSyncSetupComplete}
                    title='Sync setup complete'
                    description={`You're all set! The connection was successful and your sync is in progress.`}
                    buttonTitle='OKAY'
                    onConfirm={() => this.setState({ showSyncSetupComplete: false })}
                />
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)))
