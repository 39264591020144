import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';
import {
    Dropdown,
    Table,
    Menu,
    Button,
    message,
    Tabs,
    Tooltip
} from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons'

// Components
import moment from 'moment';
import { saveAs } from 'file-saver'
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { apiPOSTReq, getPreSignedS3Url } from '../../../../Utils/api';
import environment from '../../../../environment';
import { Paragraph, Tag } from '../../../Reusable/Text';
import { ErrorAlert } from '../../../Reusable/Alert';
import { SignedLink } from '../../../Reusable/Link';
import { LabeledInput } from '../../../Reusable/Input'
import { Image } from '../../../Reusable/Image'
import { TextButton } from '../../../Reusable/Button';

import { DownOutlined } from '@ant-design/icons';
import { Flex, FlexColumn, CardContainer } from '../../../Reusable/Container';
import AdditionalDocuments from './AdditionalDocuments';
import AdditionalDocs from './AdditionalDocs';
import Search from '../../../../Images/search.png'
import Download from "../../../../Images/download.png"
import MessageIcon from '../../../../Images/message.png';
import { Text } from '../../../Reusable/Refresh/Text'

class Index extends Component {

    state = {
        pagination: {
            current: 1,
            pageSize: 100
        },
        businessDocuments: [],
        selectedTab: 'All',
        approvedRequests: []

    };

    componentDidMount() {
        this.fetchData({ pagination: this.state.pagination });
        this.fetchApprovedRequests({ pagination: this.state.pagination })
    }

    fetchData = (options) => {
        const { loanApp } = this.props;
        const { pagination } = options || {};
        var body = {
            "size": pagination.pageSize,
            "page": pagination.current - 1,
            "sortDirection": "DESC"
        };
        if (loanApp) {
            body["clientBusinessId"] = loanApp.businessId;
            body["applicationId"] = loanApp.applicationId;
            body["viewBy"] = "FILTER_APPLICATION_ID"
        }
        console.log("/docs/getActiveDocuments body", body);
        this.setState({ loading: true });
        apiPOSTReq(`${environment.uamBaseUrl}/docs/getActiveDocuments`, {}, body, (err, resp) => {
            console.log("/docs/getActiveDocuments resp", resp);
            this.setState({ loading: false });
            try {
                const data = resp || {};
                if (data.result) {

                    var businessDocuments = data.businessDocuments.filter(doc => doc.category != "Owner Financials");
                    this.setState({
                        businessDocuments: businessDocuments,
                        pagination: {
                            ...pagination,
                            total: data.count,
                            pageable: data.pageable
                        }
                    });

                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    handleMenuClick = (status, item) => {
        console.log("handleMenuClick", status, item);
        this.setState({ loading: true });
        var businessDocument = {
            ...item
        };
        businessDocument["status"] = status;
        var body = {
            "businessDocument": businessDocument
        }
        this.setState({ loading: true });
        apiPOSTReq(`${environment.uamBaseUrl}/docs/save`, {}, body, (err, resp) => {
            this.fetchData({ pagination: this.state.pagination });
            this.setState({ loading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    message.success(`Status updated successfully.`, 0.75);
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/docs/save err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    getColumns = () => {
        const statusMenu = (item) => (
            <Menu onClick={(e) => this.handleMenuClick(e.key, item)}>
                <Menu.Item key="In Review">
                    In Review
                </Menu.Item>
                <Menu.Item key="Validated">
                    Validated
                </Menu.Item>
                <Menu.Item key="Additional Info Requested">
                    Additional Info Requested
                </Menu.Item>
            </Menu>
        )
        return [
            // {
            //     title: 'Id',
            //     width: 100,
            //     dataIndex: 'documentId'
            // },
            {
                title: 'Date',
                width: 100,
                dataIndex: 'auditData',
                render: item => moment(item.createTime).format('MM/DD/YY')
            },
            {
                title: 'Name',
                width: 400,
                render: (item) => <Paragraph size="0.95rem" bold noMargin>{item.name}
                    {
                        item.errorMessage &&
                        <Tooltip title={item.errorMessage}>
                            <ExclamationCircleFilled style={{ color: 'orange' }} />
                        </Tooltip>
                    }
                    {
                        item.subType && item.subType == "BusinessFinancials" && <Tag primary>{
                            item.source == 'System' ? 'QuickBooks' : 'Manual Upload'
                        }</Tag>
                    }
                </Paragraph>
            },
            {
                title: 'Period',
                width: 100,
                render: (record) => record.effectiveStartDate ? `${moment(record.effectiveStartDate).format('MM/DD/YY')} to ${moment(record.effectiveEndDate).format('MM/DD/YY')}` : "--"
            },
            {
                title: 'Category',
                width: 180,
                dataIndex: 'category'
            },
            // {
            //     title: 'Closing Date',
            //     width: 130,
            //     dataIndex: 'closingDate'
            // },
            // {
            //     title: 'Status',
            //     dataIndex: 'status',
            //     width: 100,
            //     render: (status, item) => (
            //         <Dropdown overlay={statusMenu(item)}>
            //             <Button>
            //                 {status || "Select"} <DownOutlined />
            //             </Button>
            //         </Dropdown>
            //     )
            // },
            {
                title: '',
                dataIndex: 'documentUrl',
                width: 120,
                render: (documentUrl, item) => (documentUrl || {}).uri ?
                    <TextButton margin='0 24px' onClick={() => this.downloadDoc(item)} text="Download" />
                    : `Note: ${(item.namedValues || []).map(item => item.value).join(", ")}`
            }
        ]
    }

    handleTableChange = (pagination, filters, sorter) => {
        this.fetchData({ pagination: pagination });
    }

    onChangeTab = (selectedTab) => {
        this.setState({ selectedTab: selectedTab });
    }

    // Download All docs
    downloadAll = (docs) => {
        const zip = new JSZip();
        let count = 0;
        const zipFilename = "BusinessDocuments.zip";
        docs.forEach(async function (doc, index) {
            if (doc.documentUrl.uri) {
                const urlArr = doc.documentUrl.uri.split('.');
                var fileExtension = urlArr[urlArr.length - 1];
                var filename = (doc.name).includes(fileExtension) ? doc.name : doc.name + "." + fileExtension;

                try {
                    getPreSignedS3Url({ url: doc.documentUrl.uri }, async (err, preSignedUrl) => {
                        try {
                            const file = await JSZipUtils.getBinaryContent(preSignedUrl)
                            zip.file(filename, file, { binary: true });
                            count++;
                            if (count === docs.length) {
                                zip.generateAsync({ type: 'blob' }).then(function (content) {
                                    saveAs(content, zipFilename);

                                });
                            }
                        }
                        catch (err) {
                            console.log(err);
                        }
                    })
                } catch (err) {
                    console.log(err);
                }
            } else {
                count++;
            }

        });
    }

    // Download Individual Doc
    downloadDoc = (item) => {
        const urlArr = item.documentUrl.uri.split('.');
        var fileExtension = urlArr[urlArr.length - 1];
        var filename = (item.name).includes(fileExtension) ? item.name : item.name + "." + fileExtension;
        getPreSignedS3Url({ url: item.documentUrl.uri }, async (err, preSignedUrl) => {
            try {
                // prevents opening file in new tab
                let blob = await fetch(preSignedUrl).then((r) => r.blob());
                saveAs(blob, filename);

            } catch (err) {
                console.log(err);
            }
        })


    }

    fetchApprovedRequests = (options) => {
        const { loanApp } = this.props;
        const { pagination } = options || {};
        var body = {
            "size": pagination.pageSize,
            "page": pagination.current - 1,
            "sortDirection": "DESC"
        };
        if (loanApp) {
            body["BusinessId"] = loanApp.businessId;
            body["applicationId"] = loanApp.applicationId;
            body["uploadCategory"] = "Custom Upload"
        }

        console.log("/lsm/getApprovedLoanRequest body", body);
        this.setState({ loading: true });
        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/getApprovedLoanRequest`, null, body, (err, resp) => {
            console.log("/lsm/getApprovedLoanRequest resp", resp);
            this.setState({ loading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    this.setState({ approvedRequests: data.requests });

                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/getApprovedLoanRequest err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    render() {
        const { loanApp } = this.props;
        const { pagination, loading, selectedTab } = this.state;
        let { businessDocuments, approvedRequests } = this.state || [];
        let allDocs = [];
        var customDocs = businessDocuments.filter(doc => doc.category === "Custom Upload") || [];
        let nonCustomDocs = businessDocuments.filter(doc => doc.category != "Custom Upload") || [];
        let approvedCustomDocs = [];
        if (businessDocuments.length > 0 && approvedRequests.length > 0) {
            this.state.approvedRequests.map(request => {
                customDocs.map(doc => {
                    if (request.requestId === doc.requestId) {
                        approvedCustomDocs.push(doc);
                    }
                })
            })
            allDocs = nonCustomDocs.concat(approvedCustomDocs);
        } else if (businessDocuments.length > 0 && approvedRequests.length == 0) {
            allDocs = nonCustomDocs;
        }
        else {
            allDocs = businessDocuments;
        }
        allDocs = allDocs.filter(doc => doc.documentUrl.uri != null) || [];

        return (
            <CardContainer fullWidth>
                <Tabs
                    defaultActiveKey="1"
                    onChange={this.onChangeTab}
                >
                    <Tabs.TabPane tab="All" key="All">
                    </Tabs.TabPane>
                    {/*<Tabs.TabPane tab="Active Request" key="Active Request">
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="All Requests" key="All Requests">
        </Tabs.TabPane> */}
                    <Tabs.TabPane tab="Additional Documents" key="AdditionalDocuments">
                    </Tabs.TabPane>
                </Tabs>
                {
                    (selectedTab == 'All') && <>

                        <Flex style={{ marginBottom: 24, marginTop: "-20px" }}>
                            <Flex start width="80%">{/*
                            <div style={{ width: 400, marginRight: 12 }}>
                                    <LabeledInput
                                        nomargin
                                        id="searchTerm"
                                        key="searchTerm"
                                        placeholder="Search by Documents"
                                        prefixIcon={<Image src={Search} />}
                                        value={""}
                                        onChange={() => { }}
                                    />
                                </div> */}


                            </Flex>
                            <Flex end width="50%">
                                <TextButton margin='0 24px' onClick={() => { this.downloadAll(allDocs) }} rightIcon={<Image src={Download} />} text="Download All" />

                            </Flex>

                        </Flex>

                        <Table
                            id="inbox-table"
                            tableLayout='auto'
                            columns={this.getColumns()}
                            dataSource={allDocs}
                            rowKey='id'
                            pagination={pagination}
                            onChange={this.handleTableChange}
                            style={{ minHeight: "500px" }}
                            loading={loading}
                            scroll={{ x: '100%' }}
                        />
                    </>
                }
                {
                    (selectedTab == 'Active Request') && <AdditionalDocuments loanApp={loanApp} refreshInfoRequestedTag={this.props.changeInfoRequestedTag}></AdditionalDocuments>
                }
                {
                    (selectedTab == 'All Requests') && <AdditionalDocuments loanApp={loanApp} allRequests={true}></AdditionalDocuments>
                }
                {
                    (selectedTab == 'AdditionalDocuments') && <AdditionalDocs loanApp={loanApp} refreshInfoRequestedTag={this.props.changeInfoRequestedTag}></AdditionalDocs>
                }
            </CardContainer>
        );
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)));