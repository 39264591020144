import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled, { withTheme } from 'styled-components'
import { message, Modal, Upload, Divider } from 'antd'

import _ from 'lodash'
import { Text } from './Text'
import { Button, TextButton } from './Button'
import { LabeledInput } from './Input'
import environment from '../../environment'
import { Image } from './Image';
import { Flex, FlexColumn } from './Container'
import Result from './Result'
import { ErrorAlert } from './Alert'
import ModalClose from '../../Images/modal-close.png'
import Error from '../../Images/exclamation-red.svg'
import UploadImage from '../../Images/upload.svg'
import UploadMuted from '../../Images/upload-muted.svg'
import UploadWhite from '../../Images/upload-white.svg'
import Download from '../../Images/download.png'
import AionIcon from '../../Images/aion-bank-icon.png'
import { apiPOST } from '../../Utils/api'
import { toCurrency, getBPAccountsWithAccess, getFeatureLimit, capitalizeFirstLetter } from '../../Utils/util'

const { Dragger } = Upload

export const StyledDragger = styled(Dragger)`
    .ant-upload.ant-upload-drag {
        background: #F8F8F9 !important;
        border: 1px dashed #7384AA !important;
        border-radius: 8px !important;
    }
`;

class UploadBatchModal extends Component {

    state = {
        step: 'upload',
    }

    uploadBatch = () => {
        const { rail } = this.props
        const { formData } = this.state

        const headers = {
            AionCurrentBiz: this.props.aionStore.BusinessUniqueKey,
            AionAuth: this.props.aionStore.UAM.encryptedAuthHeader,
        }

        this.setState({ submitLoading: true })

        console.log(`bb/batchpayment/${rail === 'wire' ? 'uploadWire' : 'uploadACH'} formData`, formData)

        apiPOST(`${environment.bbBaseUrl}/bb/batchpayment/${rail === 'wire' ? 'uploadWire' : 'uploadACH'}`, headers, formData, (err, resp) => {
            this.setState({ submitLoading: false })
            try {
                if (err) throw Error(err)
                console.log(`bb/batchpayment/${rail === 'wire' ? 'uploadWire' : 'uploadACH'} response resp`, resp)
                const data = resp?.data || {}
                if (data.result) {
                    console.log(`bb/batchpayment/${rail === 'wire' ? 'uploadWire' : 'uploadACH'} job id`, data.uploadJobId)
                    message.success("Batch uploaded successfully!")
                    this.setState({ step: 'upload' })
                    this.props.submitComplete(data.uploadJobId)
                } else {
                    console.log(`bb/batchpayment/${rail === 'wire' ? 'uploadWire' : 'uploadACH'} response`, data)
                    if (data.uploadBatchPaymentInfo?.errorRows?.length > 0 || data.uploadBatchPaymentInfo?.valueErrors?.length > 0) {
                        this.setState({
                            showErrorModal: true,
                            fileList: [],
                            responseErrorMessage: data.responseMessage,
                            uploadBatchPaymentInfo: data.uploadBatchPaymentInfo,
                            loading: false,
                        })
                    } else {
                        ErrorAlert({ description: data.responseMessage || "Sorry, we had trouble processing your request. Please try again." })
                    }
                }
            } catch (error) {
                console.log("/createACH err", error.stack)
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    render() {
        const { step, responseErrorMessage, uploadBatchPaymentInfo, loading, submitLoading } = this.state
        const { aionStore, onCancel, onConfirm, onSecondaryCTAClick, visible, submitComplete, buttonTitle, rail } = this.props

        const { UserInfo } = aionStore
        var certifiedBy = `${UserInfo.FirstName} ${UserInfo.LastName}`

        var uploadProps = {
            accept: ".csv",
            beforeUpload: file => {
                console.log('before upload', file)
                const fileCopy = new File([file], file.name, { type: file.type });

                const formData = new FormData();
                formData.append('File', fileCopy);
                formData.append('CertifiedBy', certifiedBy);
                formData.append('Notes', null);

                this.setState({ formData })
                return
            },
            multiple: false,
            action: `${environment.bbBaseUrl}/bb/batchpayment/${rail === 'wire' ? 'uploadWire' : 'uploadACH'}`,
            data: (file) => {
                return {
                    File: file,
                    CertifiedBy: certifiedBy,
                    Notes: null,
                    ValidateOnly: step === 'upload',
                }
            },
            headers: {
                AionCurrentBiz: this.props.aionStore.BusinessUniqueKey,
                AionAuth: this.props.aionStore.UAM.encryptedAuthHeader,
            },
            multiple: false,
            onChange: (info) => {
                var { file } = info

                this.setState({ fileList: [file], loading: true })
                const { status, response } = file

                console.log('status', status, response)
                if (status === "done") {
                    console.log(`bb/batchpayment/${rail === 'wire' ? 'uploadWire' : 'uploadACH'} done`, response)

                    if (response.result) {
                        if (response.uploadBatchPaymentInfo.newCustomers && response.uploadBatchPaymentInfo.newCustomers.length > 0) {
                            this.setState({
                                showErrorModal: true,
                                fileList: [],
                                responseErrorMessage: null,
                                uploadBatchPaymentInfo: response.uploadBatchPaymentInfo,
                                loading: false,
                            })
                        } else {
                            console.log(`bb/batchpayment/${rail === 'wire' ? 'uploadWire' : 'uploadACH'} job id`, response.uploadJobId)
                            // message.success("Batch uploaded successfully!")
                            // this.props.submitComplete(response.uploadJobId)
                            this.setState({
                                loading: false,
                                step: 'confirm',
                                uploadBatchPaymentInfo: response.uploadBatchPaymentInfo,
                            })
                        }
                    } else {
                        console.log(`bb/batchpayment/${rail === 'wire' ? 'uploadWire' : 'uploadACH'} response`, response)
                        if (response.uploadBatchPaymentInfo?.errorRows?.length > 0 || response.uploadBatchPaymentInfo?.valueErrors?.length > 0) {
                            this.setState({
                                showErrorModal: true,
                                fileList: [],
                                responseErrorMessage: response.responseMessage,
                                uploadBatchPaymentInfo: response.uploadBatchPaymentInfo,
                                loading: false,
                            })
                        } else {
                            this.setState({
                                fileList: [],
                                loading: false,
                            })
                            ErrorAlert({ description: response.responseMessage || "Sorry, we had trouble processing your request. Please try again." })
                        }
                    }
                } else if (status === "error") {
                    console.log(`bb/batchpayment/${rail === 'wire' ? 'uploadWire' : 'uploadACH'} file`, file)
                    this.setState({ showErrorModal: true, fileList: [], loading: false })
                }
            }
        }

        var uploadTitle = "Upload Error"
        var errorData
        var errorMsg
        var onSubmit

        let showHeaderErrorTable = uploadBatchPaymentInfo && uploadBatchPaymentInfo.headerErrors && uploadBatchPaymentInfo.headerErrors.length > 0
        let errors = []
        // let errorMessage = this.stat>"?e.errorMessage
        if (uploadBatchPaymentInfo && uploadBatchPaymentInfo.headerErrors && uploadBatchPaymentInfo.headerErrors.length > 0) {
            errors = uploadBatchPaymentInfo.headerErrors.map((x, i) => {
                return { ...x, key: i }
            })
            // errorMessage = "The file is missing required column headers"
        } else if (uploadBatchPaymentInfo && uploadBatchPaymentInfo.valueErrors && uploadBatchPaymentInfo.valueErrors.length > 0) {
            errors = uploadBatchPaymentInfo.valueErrors.map((x, i) => {
                return { ...x, key: i }
            })
        }
        var errorMessage = "The file you uploaded contains the below errors. Please correct the errors and retry."

        let errorNum = 0
        if (uploadBatchPaymentInfo && uploadBatchPaymentInfo.recordsErrorCount) {
            errorNum = uploadBatchPaymentInfo.recordsErrorCount
        }

        let successNum = 0
        if (uploadBatchPaymentInfo && uploadBatchPaymentInfo.recordsTotalCount && uploadBatchPaymentInfo.recordsErrorCount) {
            successNum = uploadBatchPaymentInfo.recordsTotalCount - uploadBatchPaymentInfo.recordsErrorCount
        }

        var Accounts = getBPAccountsWithAccess("Payments", { permType: "manage", getActiveAccounts: true })

        const fees = uploadBatchPaymentInfo?.acctFeeMap && Object.values(uploadBatchPaymentInfo?.acctFeeMap).length > 0

        var total = Object.values(uploadBatchPaymentInfo?.acctAmountMap || {}).reduce((acc, value) => acc + value, 0)
        if (fees) total = total + Object.values(uploadBatchPaymentInfo?.acctFeeMap || {}).reduce((acc, value) => acc + value, 0)

        return (
            <>
                <Modal
                    visible={visible}
                    footer={null}
                    closable={true}
                    maskClosable={false}
                    width={750}
                    destroyOnClose={true}
                    closeIcon={<Image src={ModalClose} />}
                    className='drawer-modal'
                    onCancel={() => {
                        if (!submitLoading && !loading) {
                            this.setState({ step: 'upload' })
                            onCancel()
                        }
                    }}
                    wrapClassName="drawer-modal"
                >
                    <FlexColumn start left gap='24px'>
                        {(() => {
                            switch (step) {
                                case 'upload':
                                    return <>
                                        <FlexColumn start left>
                                            <Text heading>Initiate a{rail === 'ACH' ? 'n' : ''} {rail} batch</Text>
                                            <FlexColumn start left gap='12px'>
                                                <Text>Upload a file to initiate multiple {rail} transfers.</Text>
                                                <Text>Download the below template and fill the appropriate transfer details. Example data row on the template will provide details on formatting specifications.</Text>

                                                <FlexColumn start left gap='6px'>
                                                    <Text margin='12px 0 0'>Batch Transfer Guidelines</Text>
                                                    <Text>• No recipients will be created to initiate these transfers</Text>
                                                    <Text>• No approval controls will be applied on these transfers</Text>
                                                    <Text>• File must be in CSV format and UTF-8 encoded</Text>
                                                    <Text>• File must contain no more than {parseFloat(getFeatureLimit("BusinessBanking.Payments.BatchUploadLimit")) || 100} transactions</Text>
                                                    <Text>• Columns must have the exact headers specified but they do not have to be in any particular order.</Text>
                                                    <Text margin='0 0 0 12px'>The column headers are used to find the information.</Text>
                                                </FlexColumn>
                                            </FlexColumn>
                                        </FlexColumn>

                                        {/* <Text>Columns must have the exact headers specified but they do not have to be in any particular order. The column headers are used to find the information.</Text> */}

                                        <Flex fullWidth between>
                                            <FlexColumn start centerVertically style={{ width: 180, height: 180 }} gap='12px'>
                                                <Upload {...uploadProps} showUploadList={false} fileList={this.state.fileList}>
                                                    <Button
                                                        solid
                                                        text={buttonTitle || 'Upload CSV'}
                                                        onClick={onConfirm}
                                                        loading={loading}
                                                        icon={<Image src={UploadWhite} />}
                                                        style={{ width: 220 }}
                                                    />
                                                </Upload>

                                                <Flex style={{ width: 220 }} center>
                                                    <TextButton
                                                        text={<a href={rail === 'wire' ? "/Aion_Wire_Batch_Template.csv" : "/Aion_ACH_Batch_Template.csv"} download={rail === 'wire' ? "/Aion_Wire_Batch_Template.csv" : "/Aion_ACH_Batch_Template.csv"}>Download CSV Template</a>}
                                                        icon={<Image src={Download} />}
                                                    />
                                                </Flex>

                                            </FlexColumn>

                                            <Flex end fullWidth>
                                                <StyledDragger {...uploadProps} showUploadList={false}>
                                                    <FlexColumn center gap='8px' style={{ width: 450, height: 180 }}>
                                                        <Image src={UploadMuted} />
                                                        <Text>Or drop file here...</Text>
                                                    </FlexColumn>
                                                </StyledDragger>
                                            </Flex>
                                        </Flex>
                                    </>
                                case 'confirm':
                                    return <>
                                        <FlexColumn start left>
                                            <FlexColumn start>
                                                <Text heading>{capitalizeFirstLetter(rail)} batch upload</Text>
                                                <Text>Review the information below before confirming your batch transfer</Text>
                                            </FlexColumn>

                                            <Flex start fullWidth style={{ marginTop: 24, marginBottom: 12 }}>
                                                <Text caption spaced weight='500'>{'Transfer Summary'.toUpperCase()}</Text>
                                            </Flex>
                                            <FlexColumn start gap='12px' style={{ marginBottom: 12 }}>
                                                <Flex start centerHorizontally style={{ width: 500, marginTop: 8 }} gap='24px'>
                                                    <Text weight='500'>Total Transfers</Text>
                                                    <Text right lightText>{uploadBatchPaymentInfo?.recordsTotalCount}</Text>

                                                    {/* {
                                                        fees &&
                                                        <>
                                                            <Text weight='500'>Total Fees</Text>
                                                            <Text right lightText>${toCurrency(Object.values(uploadBatchPaymentInfo?.acctFeeMap).reduce((acc, value) => acc + value, 0))}</Text>
                                                        </>
                                                    } */}

                                                    <Text weight='500'>Total Amount</Text>
                                                    <Text right primary>{`$${toCurrency(total)}`}</Text>
                                                </Flex>

                                                {/* <Divider style={{ margin: '8px 0' }} /> */}

                                                <Flex start fullWidth style={{ marginTop: 12, marginBottom: 12 }}>
                                                    <Text caption spaced weight='500'>{'Transfer Details'.toUpperCase()}</Text>
                                                </Flex>

                                                <Flex start centerHorizontally style={{ width: 600 }}>
                                                    <Text width='250px' weight='500'>Account</Text>
                                                    <Text width='100px' right weight='500'>Amount</Text>
                                                    {
                                                        fees &&
                                                        <>
                                                            <Text width='100px' right weight='500'>Fees</Text>
                                                            <Text width='150px' right weight='500'>Total</Text>
                                                        </>
                                                    }
                                                </Flex>

                                                {
                                                    uploadBatchPaymentInfo?.acctAmountMap &&
                                                    Object.entries(uploadBatchPaymentInfo.acctAmountMap).map(([key, value]) => {
                                                        // Split the key to get the account number (assuming the key format is consistent)
                                                        const [accountNumber] = key.split(':');
                                                        const account = Accounts.find(acc => acc.accountNumber === accountNumber)

                                                        return (
                                                            <Flex start centerHorizontally style={{ width: 600 }}>
                                                                <FlexColumn start fullWidth style={{ width: 250 }}>
                                                                    <Text width='250px'><img width='16px' height='16px' src={AionIcon} style={{ marginRight: 8, marginBottom: 2 }} />{`${(account.nickName || `Business ${account.accountSubType}`)} • ${account.mask}`}</Text>
                                                                    <Text size='14px' lightText style={{ marginTop: 4 }}>${toCurrency(account.availableBalance)}</Text>
                                                                </FlexColumn>
                                                                <Text width='100px' right lightText>{`$${toCurrency(value)}`}</Text>
                                                                {
                                                                    fees &&
                                                                    <Text width='100px' right lightText>{`$${toCurrency(uploadBatchPaymentInfo?.acctFeeMap[key])}`}</Text>
                                                                }
                                                                {
                                                                    fees &&
                                                                    <Text width='150px' primary right lightText>{`$${toCurrency((value + uploadBatchPaymentInfo?.acctFeeMap[key]))}`}</Text>
                                                                    // :
                                                                    // <Text width='150px' primary right lightText>{`$${toCurrency(value)}`}</Text>
                                                                }
                                                            </Flex>
                                                        );
                                                    })
                                                }
                                            </FlexColumn>

                                            <Flex start gap='12px' style={{ marginTop: 24 }}>
                                                <Button
                                                    text='CANCEL'
                                                    onClick={() => {
                                                        if (!submitLoading && !loading) {
                                                            this.setState({ step: 'upload' })
                                                            onCancel()
                                                        }
                                                    }} />
                                                <Button solid loading={submitLoading} onClick={this.uploadBatch} text='CONFIRM' />
                                            </Flex>
                                        </FlexColumn>
                                    </>
                                default:
                                    return null
                            }
                        })()}
                    </FlexColumn >
                </Modal>

                <Modal
                    visible={this.state.showErrorModal}
                    footer={null}
                    closable={true}
                    width={750}
                    style={{ top: 80 }}
                    destroyOnClose={true}
                    onCancel={() => {
                        this.setState({ showErrorModal: false, responseErrorMessage: null, uploadBatchPaymentInfo: null })
                    }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <Result
                        uploadTypeTitle={uploadTitle}
                        showHeaderErrorTable={showHeaderErrorTable}
                        errors={errors}
                        errorNum={errorNum}
                        successNum={successNum}
                        errorMessage={errorMessage}
                        responseErrorMessage={responseErrorMessage}
                        errorColumnTitle="Name"
                        errorData={errorData}
                        errorMsg={errorMsg}
                        ctaText="ACH Batch Upload"
                        onSubmit={onSubmit}
                        ignoreNameColumn={true}
                    />
                </Modal>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(UploadBatchModal)))