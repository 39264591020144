import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Select, Steps, message, Skeleton } from 'antd'
import { withTheme } from 'styled-components'

// Components
import AddNewCustomer from './AddNewCustomer'
import PageHeader from "../../Reusable/PageHeader"
import { FlexColumn } from '../../Reusable/Container'
import { ErrorAlert } from '../../Reusable/Alert'

// Util
import { apiPOSTReq } from '../../../Utils/api'
import { defaultPaymentTerms } from '../../../Utils/util'
import environment from '../../../environment'

// Actions
import { addDataToStore, RECEIVABLES_SAVE_DATA } from '../../../Actions/actions'

const { Step } = Steps
const { Option } = Select

class NewCustomerSetup extends Component {

    constructor(props) {
        super(props)

        this.state = {
            currentStep: 0,
            // customer: {},
            paymentTermsItems: defaultPaymentTerms,
            completedCustomers: {},
            customersChecked: false,
        }
    }

    componentDidMount() {
        this.fetchCustomers()

        if ((this.props.location.state || {}).newCustomers) {
            var newCustomers = this.props.location.state.newCustomers || {}
            this.setState({ newCustomers, uploadId: this.props.location.state.uploadId })

            var searchStr = ""

            newCustomers.forEach((newCustomer, i) => {
                searchStr += `^${newCustomer}$`
                if (i < newCustomers.length - 1) searchStr += '|'
            })

            const body = {
                "BusinessId": this.props.aionStore.BusinessUniqueKey,
                searchStr: searchStr,
            }

            apiPOSTReq(`${environment.iveBaseUrl}/ive/bc/getActiveCustomers`, null, body, (err, resp) => {
                try {
                    const data = resp
                    console.log("/getActiveCustomers", JSON.stringify(data))
                    if (data.result) {

                        var completedCustomers = {}
                        var currentStep = 0

                        data.customers.forEach(customer => {
                            completedCustomers[customer.displayName] = true
                        })

                        for (var i = 0; i < newCustomers.length; i++) {
                            if (completedCustomers[newCustomers[i]] !== true) {
                                currentStep = i
                                break
                            }
                        }

                        this.setState({
                            // activeCustomers: data.customers,
                            completedCustomers,
                            currentStep,
                            currentCustomer: newCustomers[currentStep],
                            customersChecked: true,
                        })
                    } else {
                        throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                    }
                } catch (error) {
                    console.log("ERRR getActiveCustomers", error, err, resp)
                    ErrorAlert({ description: error.message })
                }
            })
        }
    }

    fetchCustomers() {
        const body = {
            "BusinessId": this.props.aionStore.BusinessUniqueKey,
        }

        apiPOSTReq(`${environment.iveBaseUrl}/ive/bc/getActiveCustomers`, null, body, (err, resp) => {
            try {
                const data = resp
                console.log("/getActiveCustomers", JSON.stringify(data))
                if (data.result) {
                    this.props.dispatch(addDataToStore(RECEIVABLES_SAVE_DATA, { ActiveCustomers: data.customers }))
                    this.setState({
                        customers: data.customers,
                        customersLoaded: true,
                    })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getActiveCustomers", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    nextStep = (customer, callback) => {
        const { currentStep, newCustomers, completedCustomers } = this.state

        var done = newCustomers ? (currentStep === newCustomers.length - 1) : false

        const body = {
            Customer: customer
        }

        console.log("nextStep customer:", JSON.stringify(body))

        apiPOSTReq(`${environment.iveBaseUrl}/ive/bc/save`, null, body, (err, resp) => {
            try {
                const data = resp
                if (data.result) {
                    this.fetchCustomers()

                    completedCustomers[customer.displayName] = true

                    if (done) {
                        // message.success(`${customer.displayName} saved. New customer setup complete!`)
                        // this.props.history.replace('/credit/abl-credit/ar-aging')

                        this.resubmitUpload(customer, callback)
                    } else {
                        message.success(`${customer.displayName} saved.`)

                        var nextStep = currentStep + 1

                        console.log("currentCustomer: ", newCustomers[nextStep])

                        this.setState({
                            currentStep: nextStep,
                            currentCustomer: newCustomers[nextStep],
                            completedCustomers
                        })
                        callback()
                    }
                } else {
                    callback()
                    console.log("ive/bc/save ERRR: ", data.responseMessage)
                    message.error("Could not Save. " + data.responseMessage)
                }
            } catch (error) {
                callback()
                console.log("ive/bc/save ERRR: ", error.stack, err, resp)
                message.error("Could not Save.")
            }
        })
    }

    resubmitUpload = (customer, callback) => {
        console.log("ar/aging/resubmitUpload: ", this.state.uploadId)
        apiPOSTReq(`${environment.iveBaseUrl}/ar/aging/resubmitUpload`, null, { uploadId: this.state.uploadId }, (err, resp) => {
            try {
                const data = resp
                if (data.result) {
                    console.log("ar/aging/resubmitUpload ", JSON.stringify(data))
                    message.success(`${customer.displayName} saved. New customer setup complete!`)
                    this.props.history.replace('/credit/abl-credit/ar-aging')
                } else {
                    console.log("ar/aging/resubmitUpload ERRR: ", data.responseMessage)
                    message.error("Could not Save. " + data.responseMessage)
                }
            } catch (error) {
                console.log("ar/aging/resubmitUpload ERRR: ", error.stack, err, resp)
                message.error("Could not Save.")
            } finally {
                callback()
            }
        })
    }

    getDescription = (customer) => {
        const { newCustomers, currentStep, completedCustomers } = this.state

        if(completedCustomers[customer] === true) return "Completed"
        
        if(newCustomers[currentStep] === customer) return "In Progress"

        return "Not Started"
    }

    render() {
        const { theme } = this.props
        const { currentStep, newCustomers, currentCustomer, completedCustomers, customersChecked } = this.state
        // var { companyName, displayName, webSite, firstName, lastName, notes, fullyQualifiedName, billingAddress, shippingAddress, phone, paymentTerms, parentCustomerName, billWithParent } = customer

        var { ActiveCustomers } = this.props.receivablesStore
        ActiveCustomers = ActiveCustomers || []

        var done = newCustomers ? (currentStep === newCustomers.length - 1) : false

        return (
            <FlexColumn style={{ margin: '0 40px' }}>
                <PageHeader
                    titleText="New Customer Setup"
                    desc="Complete setting up your new customers"
                />

                <div style={{ display: "flex" }}>
                    <Skeleton loading={!customersChecked} active title={false} paragraph={{ rows: 9 }}>
                        <div style={{ width: 250, display: "flex", flexDirection: "column", marginTop: 20, marginRight: 30 }}>
                            {
                                newCustomers &&
                                <Steps direction="vertical" current={currentStep}>
                                    {
                                        newCustomers.map(customer => {
                                            return <Step title={customer} description={this.getDescription(customer)} />
                                        })
                                    }
                                </Steps>
                            }
                        </div>

                        {
                            currentCustomer &&
                            <AddNewCustomer key={currentCustomer} onNext={(customer, callback) => this.nextStep(customer, callback)} customerName={currentCustomer} done={done} />
                        }
                    </Skeleton>
                </div>
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.creditAppReducer,
        aionStore: state.aionAppReducer,
        receivablesStore: state.receivablesAppReducer,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(NewCustomerSetup)))