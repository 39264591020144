import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'

import { Affix, Layout } from 'antd'

import { LeftOutlined } from '@ant-design/icons'

// Components
import { FlexColumn, Container } from '../../../Reusable/Container'
import PageHeader from '../../../Reusable/PageHeader'
import EditVendor from './EditVendor'

const { Content } = Layout

class Edit extends Component {

    constructor(props) {
        super(props)
        this.state = {
            accountingSW: '',
        }
    }

    componentDidMount() {
        console.log("componentDidMount", this.props.location.state)
        var { newVendor } = this.props.location.state
        var vendor = this.props.location.state.vendor || {}
        this.setState({ vendor: vendor, newVendor })
    }

    render() {
        const { theme } = this.props
        const { vendor, newVendor } = this.state

        return (
            <FlexColumn className='main-padding' style={{ width: '100%' }} start>
                {
                    vendor &&
                    <>
                        <PageHeader
                            back
                            backText='Vendors'
                            titleText={newVendor ? 'New Vendor' : `${vendor.vendorName}`} />
                        {/* <Container shadow style={{ marginBottom: 48 }} padding='12px 24px 24px'> */}
                            <EditVendor
                                key={vendor.id}
                                editvendor={vendor}
                                vendor={vendor}
                                submitComplete={() => window.history.back()}
                                showSaveToQuickBook={this.state.accountingSW === 'QuickBooks'}
                                newVendor={newVendor}
                            />
                        {/* </Container> */}
                    </>
                }
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        bkStore: state.bookkeepingAppReducer,
        store: state.aionAppReducer,
        bankingStore: state.bankingAppReducer,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Edit))