import React from 'react'
import styled from 'styled-components'
import { Empty, Layout } from 'antd'
import moment from 'moment'
import { CloseOutlined } from '@ant-design/icons'
import { Paragraph } from './Text'
import { TextButton } from './Button'
import AbstImg from '../../Images/rocket-abstract.png'
import { containerDimensions } from '../../Styles/theme'
import hexToRgba from 'hex-to-rgba'

const { Sider, Footer } = Layout

export const Flex = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: ${props => {
        if (props.centerVertically || props.centerV || props.center) {
            return 'center';
        } else if (props.between) {
            return 'space-between';
        } else if (props.start) {
            return 'flex-start';
        } else if (props.end) {
            return 'flex-end';
        } else if (props.centerVertically || props.center) {
            return 'center';
        } else {
            return 'space-around';
        }
    }};
    align-items: ${props => {
        if (props.centerHorizontally || props.centerH || props.center) {
            return 'center';
        } else if (props.bottom) {
            return 'flex-end';
        } else if (props.stretch) {
            return 'stretch';
        } else {
            return 'flex-start';
        }
    }};
    flex-wrap: ${props => props.wrap && 'wrap'};
    width: ${props => props.fullWidth ? '100%' : props.width && props.width};
    height: ${props => props.fullHeight && '100%'};
    gap: ${props => props.gap && props.gap};
    flex-grow: ${props => props.grow && 1};
`;

export const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: ${props => {
        if (props.centerVertically || props.center) {
            return 'center';
        } else if (props.start) {
            return 'flex-start';
        } else if (props.between) {
            return 'space-between';
        } else if (props.end) {
            return 'flex-end';
        } else {
            return 'space-around';
        }
    }};
    align-items: ${props => {
        if (props.centerHorizontally || props.center) {
            return 'center';
        } else if (props.right) {
            return 'flex-end';
        } else if (props.left) {
            return 'flex-start';
        } else if (props.stretch) {
            return 'stretch';
        } else {
            return null;
        }
    }};
    width: ${props => props.fullWidth && '100%'};
    height: ${props => props.fullHeight ? '100%' : props.height && props.height };
    gap: ${props => props.gap && props.gap};
    flex-grow: ${props => props.grow && 1};
`;

export const SideBar = () => {
    const StyledBar = styled(Sider)`
        background: ${props => props.theme.colors.secondary2};
        background: linear-gradient(to bottom, #4C33D2, #763CDA);
    `;
    return (<StyledBar width={containerDimensions.authPage.sideBar} />);
}

export const GradientSideBar = styled(Sider)`
    background: ${props => props.theme.colors.secondary2};
    background: linear-gradient(to bottom, #4C33D2, #763CDA);
`;

export const AbstractSideBar = (props) => {
    const StyledBar = styled(Sider)`
        background: ${props => props.theme.colors.secondary2};
        background: linear-gradient(to bottom, #4C33D2, #763CDA);
    `;

    return (
        <StyledBar {...props}>
            <img src={AbstImg} style={{ width: "600px", margin: '50% 0 0 -20%', opacity: 0.8 }} alt="Aion Logo" />
        </StyledBar>
    );
}

export const AppFooter = () => {
    return (
        <Footer style={{ textAlign: "center", background: "transparent" }}>©{moment().format('YYYY')} Aion Financial Technologies, Inc.</Footer>
    );
}

// CARDS
export const CardHeader = (props) => {
    const Header = styled(Flex)`
        background-color: ${props => props.theme.colors.secondary5};
        height: 45px;
        padding: 0 15px;
        border-radius: ${containerDimensions.cards.borderRadius};
        justify-content: space-between;
    `;
    return (
        <Header center>
            <Flex center>
                {props.img ? <img height="35" width="35" src={props.img} style={{ marginRight: "10px" }} /> : null}
                <Paragraph level={3} style={{ fontWeight: 500 }} noMargin>{props.title}</Paragraph>
            </Flex>
            {props.buttonTitle ? <TextButton onClick={props.onClick}>{props.buttonTitle}</TextButton> : null}
            {/* {props.subtitle ? <span style={{ fontWeight: 400, fontSize: 16 }} noMargin>{props.subtitle}</span> : null} */}
        </Header>
    );
}

export const Card = styled.div`
    margin: 5px 0px;
    padding: 5px 15px;
    border-radius: ${containerDimensions.cards.borderRadius};
    border: 0px solid ${props => props.theme.colors.systemGray5};
`;

export const InputContainer = styled.div`
    margin: ${props => props.margin || '20px 0 40px 0'};
    padding: ${props => props.padding || '20px 20px'};
    border-radius: 15px;
    background-color: ${props =>  props.bgColor || props.theme.colors.systemGray6}; 
`;

export const EmptyContainer = (props) => (
    <Empty
        style={props.style || {}}
        imageStyle={{
            height: 60,
        }}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={
            <span>
                {props.desc || `Nothing found`}
            </span>
        }
    >
    </Empty>
);

export const Container = styled.div`
    padding: ${props => props.padding ? props.padding : '24px'};
    margin: ${props => props.margin && props.margin};
    background: ${props => props.color ? props.color : '#FFFFFF'};
    border: ${props => props.border && props.border};
    border-radius: ${props => props.radius ? props.radius : '8px'};
    box-shadow: ${props => props.shadow && '0px 4px 32px rgba(111, 111, 111, 0.08)'};
    width: ${props => props.fullWidth && '100%'};
`

export const GradientContainer = styled.div`
    position:relative;
    padding: ${props => props.padding ? props.padding : '24px'};
    ::before {
        content:"";
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        border-radius:16px;
        border: 1px solid transparent;
        background: linear-gradient(90deg, #CBCED6 0%, rgba(203, 206, 214, 0) 124.48%) border-box;
        -webkit-mask:
            linear-gradient(#fff 0 0) padding-box, 
            linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
            mask-composite: exclude;
    }
`

export const SearchContainer = styled.div`
    background: #F4F5F7;
    border-radius: 8px;
`;

export const Capsule = (props) => {
    const Capsule = styled.div`
        height: ${props => props.height ? props.height : '32px'};
        border: 1px solid #1199FF;
        border-radius: 40px;
        padding: 4px 16px;
        background-color: ${props => props.selected === true ? '#1199FF' : 'white'};
        cursor: pointer;
        margin: ${props => props.margin && props.margin};
        display: flex;
        align-items: center;
        justify-content: center;
    `

    const CapsuleText = styled.span`
        font-family: 'InterDisplay';
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: ${props => props.selected === true ? 'white' : '#1199FF'};
    `

    return (
        <Capsule {...props}>
            <CapsuleText {...props}>{props.text}</CapsuleText>
            {
                props.closable &&
                <CloseOutlined onClick={() => props.onDelete(props.index)} style={{ color: '#1199FF', fontSize: 14, marginLeft: 10, cursor: 'pointer' }} />
            }
        </Capsule>
    );
}

export const LineItemsHeader = styled.div`
    height: 56px;
    width: 100%;
    left: 0px;
    top: 0px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 16px 12px;
    background: #FBFBFB;
    display: flex;
    justify-content: space-between;
    /* border-bottom: 1px solid var(--solid-black-20, #CCC); */
    gap: 16px;
    margin-bottom: 8px;
`

export const LineItemRow = styled.div`
    min-height: 56px;
    width: 100%;
    left: 0px;
    top: 0px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 12px;
    gap: 16px;
`

export const LightContainer = styled.div`
    padding: ${props => props.padding ? props.padding : '24px'};
    border: ${props => !props.noBorder && `1px solid ${props.borderColor ? props.borderColor : hexToRgba(props.theme.colors.primary2, 0.1)}`};
    border-radius: ${props => props.radius ? props.radius : '8px'};
    width: ${props => props.fullWidth ? '100%' : props.width && props.width};
    margin: ${props => props.margin && props.margin};
    height: ${props => props.fullHeight ? '100%' : props.height && props.height};
    cursor: ${props => props.clickable && 'pointer'};
    background: ${props => props.background ? props.background : 'transparent'} !important;
    box-shadow: ${props => props.shadow && '0px 4px 32px rgba(111, 111, 111, 0.08)'};
`;

export const CardContainer = styled.div`
    padding: ${props => props.padding ? props.padding : '24px'};
    box-shadow: ${props => !props.noShadow && '0px 4px 8px rgba(102, 102, 102, 0.08)'};
    border-radius: ${props => props.radius ? props.radius : '8px'};
    margin: ${props => props.margin && props.margin};
    border-right: ${props => props.borderRight && `4px solid rgba(3, 3, 66, 0.7)`};
    width: ${props => props.fullWidth ? '100%' : props.width && props.width};
    min-width: ${props => props.minWidth && props.minWidth};
    height: ${props => props.fullHeight ? '100%' : props.height && props.height};
    min-height: ${props => props.minHeight && props.minHeight};
    cursor: ${props => (props.clickable || props.onClick) && 'pointer'};
    background: ${props => props.background ? props.background : '#FFFFFF'};
    flex-grow: ${props => props.grow && 1};
    border: ${props => props.border};
`;

export const BrandedContainer = styled.div`
    width: 516px;
    height: ${props => props.height && props.height};
    min-height: 592px;
    max-height: 816px;
    /* Gradient/Blue */
    background: radial-gradient(83.75% 38.54% at 16.25% 86.88%, #374FEA 0%, #1A8AFA 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    /* Elevation/Medium */
    box-shadow: 0px 4px 8px rgba(102, 102, 102, 0.16);
    border-radius: 8px;
`;

export const InfoCard = styled.div`
    margin: ${props => props.margin && props.margin};
    padding: ${props => props.padding ? props.padding : '12px 16px'};
    border-radius: ${props => props.radius ? props.radius : '8px'};
    background: ${props => props.color ? props.color : props.theme.colors.backgroundPrimary};
    color: ${props => props.color ? props.color : props.theme.colors.defaultLightText};
`;