import React, { Component } from 'react';
import moment from 'moment';
import { withTheme } from 'styled-components';
import { ConfigProvider, Empty, Skeleton, Table, Tooltip, Popover } from 'antd';


import { FlexColumn } from '../../Reusable/Container';
import { Text, Tag } from '../../Reusable/Text';
import TableView from '../../Reusable/TableView';
import { Flex } from '../../Reusable/Container';
import { Image } from '../../Reusable/Image'
import Info from '../../../Images/info.png'
import { InfoCircleOutlined } from '@ant-design/icons'

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});


const customizeRenderEmpty = () => (
    <Empty
        imageStyle={{
            height: 60,
        }}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={
            <span>
                No Advances
            </span>
        }>
    </Empty>
)

const getColumns = (props) => {
    const { theme } = props
    if (props.ifFeesAreToBeDrawn) {
        return [
            {
                title: 'Requested Date',
                dataIndex: 'AdvanceDate',
                render: date => (moment(date).format('ll')),
                width: "15%",
                align: 'left',
            },
            {
                title: 'Draw ID',
                dataIndex: 'LoanId',
                width: "15%",
                align: 'center',
            },
            {
                title: 'Draw Amount',
                dataIndex: 'AdvanceAmount',
                render: amount => formatter.format(amount),
                width: "15%",
                align: 'center',
            },
            {
                title: () => {
                    return <Flex center fullWidth gap='8px' centerHorizontally>
                        Fees <Tooltip overlayInnerStyle={{ minWidth: 300 }} overlayStyle={{ minWidth: 400 }} overlayClassName='roles-tooltip' placement="right" arrowPointAtCenter={false} title={<FlexColumn>
                            <Text color='white' size='14px' noWrap>Draw fees</Text>
                        </FlexColumn>}>
                            <FlexColumn>
                                <Image src={Info} />
                            </FlexColumn>
                        </Tooltip>
                    </Flex>
                },
                dataIndex: 'Fees',
                render: fees => fees == null ? formatter.format(0) : formatter.format(fees),
                width: "15%",
                align: 'center',
            },
            {
                title: 'Status',
                render: item => <Tag color={item.LoanStatus == "Initiated" ? theme.colors.primary : theme.colors.greenDeep} background={item.LoanStatus == "Initiated" ? theme.colors.backgroundPrimary : theme.colors.greenPale}>{item.LoanStatus == "Initiated" ? "Processing ".toUpperCase() : item.LoanStatus.toUpperCase()}</Tag>,
                width: "15%",
                align: 'center',
            },
            {
                title: 'Note',
                dataIndex: 'AdvanceNotes',
                width: "40%",
                align: 'left',
            },
        ]
    }
    else {
        return [
            {
                title: 'Requested Date',
                dataIndex: 'AdvanceDate',
                render: date => (moment(date).format('ll')),
                width: "15%",
                align: 'left',
            },
            {
                title: 'Advance ID',
                dataIndex: 'LoanId',
                width: "15%",
                align: 'center',
            },
            {
                title: 'Amount',
                dataIndex: 'AdvanceAmount',
                render: amount => formatter.format(amount),
                width: "15%",
                align: 'center',
            },
            {
                title: 'Status',
                render: item => <Tag color={item.LoanStatus == "Initiated" ? theme.colors.primary : theme.colors.greenDeep} background={item.LoanStatus == "Initiated" ? theme.colors.backgroundPrimary : theme.colors.greenPale}>{item.LoanStatus == "Initiated" ? "Processing ".toUpperCase() : item.LoanStatus.toUpperCase()}</Tag>,
                width: "15%",
                align: 'center',
            },
            {
                title: 'Note',
                dataIndex: 'AdvanceNotes',
                width: "40%",
                align: 'left',
            },
        ]
    }
}

function AdvancesTable(props) {
    var items = props.data || [];
    items.sort(function (a, b) {
        return moment(b.AdvanceDate).toDate() - moment(a.AdvanceDate).toDate();
    });
    return (
        <FlexColumn>
            {/* <Text size='20px' margin='0 0 24px 0'>History</Text> */}
            <ConfigProvider renderEmpty={customizeRenderEmpty}>
                <div className="config-provider">
                    <Skeleton loading={!props.advancesLoaded} active title={false} paragraph={{ rows: 4 }}>
                        <TableView
                            id='advances-table'
                            titleText='Advances history'
                            rowKey='Id'
                            tableLayout='auto'
                            columns={getColumns(props)}
                            dataSource={items}
                            onRow={(record) => props.allowRowClick ? ({
                                onClick: () => {
                                    props.selectRow(record);
                                },
                            }) : {}}
                            ctaContent={props.ctaContent}
                        />
                    </Skeleton>
                </div>
            </ConfigProvider>
        </FlexColumn>
    )
}

export default withTheme(AdvancesTable);