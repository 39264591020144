import React from 'react';
import styled from "styled-components";

const Divider = (props) => {
    
    const StyledDivider = styled.div`
        border: 1px solid #F2F2F2;
        flex: none;
        order: 2;
        align-self: stretch;
        flex-grow: 0;
        margin: ${ props => props.margin || "24px 0px" };
    `;

    return <StyledDivider />
}

export default Divider;