import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { message, Alert } from 'antd'
import { Auth } from 'aws-amplify'
import styled, { withTheme } from 'styled-components'

import {
    Layout
} from 'antd'

// Util
import { apiGET, apiPOST, apiPOSTReq, doSignout, getJwt } from '../../Utils/api'
import { isMobile, isTablet, getUserApps, getResourcePerm, camelCaseToPascalCase } from '../../Utils/util'
import { containerDimensions } from '../../Styles/theme'
import Bowser from "bowser"
import environment from '../../environment'

// Actions
import { completeSignin, addDataToStore, SAVE_DATA, UAM_SAVE_PERMISSIONS, WINDOW_CLOSE_TIMESTAMP, USERDEFAULTS_SAVE_FLAG, ONB_SAVE_DATA, UAM_SAVE_BUSINESS, UAM_SAVE_USERINFO } from '../../Actions/actions';

// Components
import { Button } from '../Reusable/Refresh/Button';
import { Divider } from '../Reusable/Divider';
import { Image } from '../Reusable/Image';
import { Flex, FlexColumn, SideBar, GradientSideBar } from '../Reusable/Refresh/Container';
import { LabeledInput } from '../Reusable/Input';
import { StyledExtLink } from '../Reusable/Link';
import AionLogo from '../../Images/aion-shadow.png'
import AionText from '../../Images/aion-logo-text.png'
import { PageTitle, Text } from '../Reusable/Refresh/Text';
import { BrandedContainer } from '../Reusable/Container';

import AionOverlayImg from '../../Images/aion-wing-overlay.png';
import CoinStackImg from '../../Images/signup-img.png';
import { ErrorAlert } from '../Reusable/Alert'

const AionOverLay = styled.div`
    position: absolute;
    mix-blend-mode: overlay;
    opacity: 0.16;
`;

const ConfirmRegistration = (props) => {
    const [loading, setLoading] = useState(false);
    const [code, setCode] = useState('');
    const [errorField, setErrorField] = useState();
    const [errorMessage, setErrorMessage] = useState("");
    const [fromSignIn, setFromSignIn] = useState(false);

    const location = useLocation()
    const history = useHistory()

    useEffect(() => {
        console.log('location.state.username', location)
        if (location.state && location.state.fromSignIn) {
            setFromSignIn(true)
            sendConfirmationCode()
        }
    }, []);

    const handleChange = (event) => {
        if (event.target.id === "code") {
            setCode(event.target.value)
        }
    }

    const handleSubmit = async (event) => {
        if (code === '') {
            setErrorField("code");
            setErrorMessage("Please enter a code.");
            return;
        }

        setLoading(true)
        const username = (location.state && location.state.fromSignIn) ? location.state.username : props.store.Username
        try {
            await Auth.confirmSignUp(username, code)
            
            if (location.state && location.state.password) {
                const user = await Auth.signIn(username, location.state.password)
                saveUserData({ username: username, cognitoUserInfo: { username: user.username, attributes: user.attributes } }, (err, resp) => {})
                // getAttributes()
            } else {
                message.success('Sign up complete! Sign in with your credentials.')
                props.history.push('/')
            }
        } catch (error) {
            setLoading(false)
            console.log('error confirming sign up', error)

            if(error.message == "User cannot be confirmed. Current status is CONFIRMED") {
                const user = await Auth.signIn(username, location.state.password)
                saveUserData({ username: username, cognitoUserInfo: { username: user.username, attributes: user.attributes } }, (err, resp) => {})
            } else {
                message.error(`Error confirming sign up. ${error.message}`)
            }
        }
    }

    const saveUserData = (options, callback) => {
        options = options || {};
        var { cognitoUserInfo, username } = options

        var cognitoAttr = cognitoUserInfo.attributes || {}
        var {
            given_name,
            family_name,
            email,
            phone_number,
        } = cognitoAttr
        
        const browser = Bowser.parse(window.navigator.userAgent)
        var browserInfo = browser
        browserInfo["AppVersion"] = environment.appVersion
        browserInfo["OS"] = browserInfo["Os"] || {}
        if (browserInfo["Os"]) delete browserInfo["Os"]

        console.log("saveUserData", options, browserInfo)

        var userData = {
            userInfo: {
                firstName: given_name,
                lastName: family_name,
                email: email,
                phone: phone_number
            },
            attributes: {
                referralCode: cognitoAttr["custom:referralCode"],
                onboardingType: cognitoAttr["custom:onboardingType"],
                subscriptionPlanName: cognitoAttr["custom:subscriptionPlanName"],
                subscriptionBilling: cognitoAttr["custom:subscriptionBilling"],
            },
            browserInfo: browserInfo
        }
        getJwt((err, jwt) => {
            this.props.dispatch(addDataToStore(UAM_SAVE_PERMISSIONS, { jwt: jwt }))
        })
        apiPOSTReq(`${environment.uamBaseUrl}/saveUserInfo`, {}, { userId: username, registeredUser: userData }, (err, resp) => {
            try {
                if (err) throw Error(err);
                const data = resp || {};
                if (data.result) {
                    var registeredUser = data.registeredUser || {}
                    var userInfo = registeredUser.userInfo || {}

                    props.dispatch(addDataToStore(UAM_SAVE_USERINFO, registeredUser));
                    // We use camelCase in some places and PascalCase in other places
                    props.dispatch(addDataToStore(SAVE_DATA, { UserInfo: camelCaseToPascalCase(Object.assign({}, userInfo)), userInfo: userInfo }))

                    props.dispatch(addDataToStore(SAVE_DATA, { 
                        "TwoFAValidated": true, 
                        OnboardingType: cognitoAttr["custom:onboardingType"], 
                        subscriptionPlanName: cognitoAttr["custom:subscriptionPlanName"], 
                        subscriptionBilling: cognitoAttr["custom:subscriptionBilling"], 
                        referralCode: cognitoAttr["custom:referralCode"] 
                    }))

                    if (cognitoAttr["custom:onboardingType"] == "credit") {
                        props.history.push('/apply/credit')
                    } else {
                        props.history.push('/apply')
                    }

                    callback(null, true);
                } else {
                    throw Error(data.responseMessage || data.error);
                }
            } catch (error) {
                console.log("/getUserInfo err", error, resp);
                setLoading(false)
                ErrorAlert({ description: error.message })
                callback(error.message);
            }
        });
    }

    const sendConfirmationCode = async () => {
        try {
            const username = (location.state && location.state.fromSignIn) ? location.state.username : props.store.Username
            await Auth.resendSignUp(username)
        } catch (error) {
            message.error(`Error sending code: ${error.message}`)
        }
    }

    const resendConfirmationCode = async () => {
        try {
            const username = (location.state && location.state.fromSignIn) ? location.state.username : props.store.Username
            await Auth.resendSignUp(username)
            message.success('Code resent successfully')
        } catch (error) {
            message.error(`Error resending code: ${error.message}`)
        }
    }

    const handleOnKeyDown = (event) => { if (event.key === "Enter") handleSubmit() }

    const getInputContent = () => {
        return (
            <FlexColumn style={{ marginTop: isMobile || isTablet ? "10px" : "0px", width: 'inherit' }}>
                <FlexColumn style={{ flex: 3 }}>
                    <PageTitle level={5} noMargin style={{ marginBottom: '15px' }}>Confirmation Code</PageTitle>
                    <Text >A confirmation code has been sent to {props.store.Phone ? props.store.Phone : 'the phone number you used to sign up'}. This code expires in 5 minutes.</Text>
                    {/* <Text >A confirmation code has been sent to {props.store.Username}. This code expires in 5 minutes.</Text> */}
                    <div className="container" style={{ flexDirection: 'column', marginTop: '24px' }}>
                        <LabeledInput
                            autoFocus
                            label="Confirmation Code"
                            id="code"
                            key="signup.code"
                            type="code"
                            placeholder="Enter Code"
                            onChange={handleChange}
                            onKeyDown={handleOnKeyDown}
                            error={errorField == "code"}
                            errorMessage={errorMessage}
                        />
                    </div>
                </FlexColumn>
            </FlexColumn>
        )
    }

    const getCTA = () => {
        return (
            <FlexColumn left>
                <Button solid loading={loading} permtype="Override" style={{ margin: '10px 0' }} onClick={handleSubmit} text={'Confirm'.toUpperCase()} />
                <Text style={{ textAlign: "center", margin: "20px 0" }}>Didn't Receive a Code? <StyledExtLink onClick={resendConfirmationCode} >Resend Code</StyledExtLink></Text>
            </FlexColumn>
        )
    };

    const loadApp = () => {
        Auth.currentUserInfo()
            .then(cognitoUserInfo => {
                console.log('cognitoUserInfo', cognitoUserInfo)
                var cognitoAttr = cognitoUserInfo.attributes || {}

                if (cognitoAttr["custom:onboardingType"] == "credit") {
                    history.push('/apply/credit')
                } else {
                    history.push('/apply')
                }
            })
            .catch(err => {
                console.log("getJwt Err", err)
            })
    }

    return (
        <Flex style={{ background: "transparent", paddingTop: '120px', justifyContent: 'center', gap: '132px' }}>
            <Flex start style={{ margin: '12px 24px 12px', position: "absolute", top: "12px", left: "75px" }} gap='4px'>
                <Image src={AionLogo} height='32px' width='32px' />
                <Image src={AionText} height='24px' width='51px' />
            </Flex>
            {
                isMobile || isTablet ?
                    <div>
                        <Alert style={{ position: 'sticky', zIndex: 2, top: 0 }} message="For the best experience, please proceed with your application on your desktop or laptop." banner closable={true} />
                        <FlexColumn center style={{ padding: "30px 35px 10px 35px" }}>
                            <FlexColumn left style={{ gap: '24px', width: 'inherit' }}>
                                <AionLogo />
                                {getInputContent()}
                            </FlexColumn>
                            {getCTA()}
                        </FlexColumn>
                        <Flex>
                            <AionLogo />
                        </Flex>
                    </div>
                    :
                    <>
                        <FlexColumn between left style={{ width: "408px", height: "784px", gap: '24px' }}>
                            <FlexColumn left style={{ gap: '24px', width: 'inherit' }}>
                                {getInputContent()}
                            </FlexColumn>
                            {getCTA()}
                        </FlexColumn>
                        <FlexColumn between left style={{ width: "516px", height: "784px" }}>
                            <BrandedContainer height='784px'>
                                <AionOverLay>
                                    <img src={AionOverlayImg} />
                                </AionOverLay>
                                <FlexColumn center style={{ padding: '24px 80px', height: '100%' }}>
                                    <img src={CoinStackImg} height='330px' />
                                    <Divider style={{ background: props.theme.body }} />
                                    <Text center color={props.theme.body}>
                                        One platform to manage all your business finances and operations from banking, paying your employees and vendors, getting paid early on your invoices, managing spends to operating with real time insights
                                    </Text>
                                </FlexColumn>
                            </BrandedContainer>
                        </FlexColumn>
                    </>
            }
        </Flex>
    )
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(ConfirmRegistration))