import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { Space, Select, message } from 'antd';

import moment from 'moment';
import _ from 'lodash';
import { FlexColumn } from '../../../Reusable/Container';

import InvoiceTable from './InvoiceTable';
import { apiPOSTReq } from '../../../../Utils/api';
import environment from '../../../../environment';
import { ErrorAlert } from '../../../Reusable/Alert';
import { LabeledInput } from '../../../Reusable/Input';
import { Button } from '../../../Reusable/Button';

const { Option } = Select;
var dFormat = "YYYY-MM-DD";

class Index extends Component {
    state = {
        loading: true,
        pagination: {
            current: 1,
            pageSize: 100,
            showSizeChanger: false,
        },
        sorter: {},
        sortDirection: "DESC",
        showClear: false,
        selectedRowsPageDict: {},
        selectedRowKeyPageDict: {},
        reviewerNote: '',
    };

    componentDidMount() {
        this.fetchCOA()
        var { Profile, UserInfo } = this.props.aionStore;
        var { BusinessInfo } = Profile || {};
        BusinessInfo = BusinessInfo || {};
        this.setState({ ccAddr: UserInfo.Email, reminderTeamName: BusinessInfo.Name });
        this.handleDateTypeSelection("Year to Date");
    }

    handleOnChange = (event) => {
        const target = event.target;
        this.setState({ [target.id]: target.value })
    }

    showSelectedInvoice = (selectedInvoice) => {
        this.setState({ selectedInvoice: selectedInvoice, showInvoiceModal: true })
    }

    fetchInvoices = (options) => {
        var { sortDirection, sortFieldname } = this.state
        var { pagination, fromDate, toDate, filterType, sorter, searchTerm } = options;
        filterType = filterType || [];
        sorter = sorter || {}

        if (!fromDate) fromDate = this.state.fromDate;
        if (!toDate) toDate = this.state.toDate;

        var body = {
            "size": pagination.pageSize,
            "page": pagination.current - 1,
            "source": "AION|ARUPLOAD|QUICKBOOKS|CODAT",
            "viewBy": "FILTER_SOURCE"
        }

        if (sorter.field) {
            if (sorter.field === sortFieldname) {
                body.sortDirection = (sortDirection === "DESC") ? "ASC" : "DESC"
            } else {
                body.sortDirection = "ASC"
            }
            body.sortFieldname = sorter.field
        } else {
            if (sortFieldname) {
                body.sortFieldname = sortFieldname
            }
            body.sortDirection = sortDirection
        }

        if (fromDate) {
            body.viewBy = "FILTER_CREATE_DATE"//"FILTER_TXN_DATE"
            body.fromDate = fromDate;
            body.toDate = toDate
        }

        if (searchTerm && searchTerm != "") {
            body.searchStr = searchTerm
        }

        if (filterType.length > 0) {
            var emailConstants = ["Delivered", "Opened", "Clicked"];
            var invStatus = filterType.filter(item => !emailConstants.includes(item)).join("|");
            var emailStatus = filterType.filter(item => emailConstants.includes(item)).join("|");

            // Check if filtering by date
            if (filterType.find(item => emailConstants.includes(item))) {
                body.viewBy = (body.viewBy == "FILTER_CREATE_DATE") ? "FILTER_EMAIL_STATUS_CREATE_DATE" : "FILTER_EMAIL_STATUS";
            } else {
                body.viewBy = (body.viewBy == "FILTER_CREATE_DATE") ? "FILTER_STATUS_CREATE_DATE" : "FILTER_STATUS"
            };
            body.status = invStatus;
            body.emailStatus = emailStatus;
        }

        this.setState({ loading: true });

        const headers = {
            AionCurrentBiz: this.props.businessId,
            AionAuth: this.props.aionStore.UAM.encryptedAuthHeader
        }

        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/getInvoices`, headers, body, (err, resp) => {
            try {
                const data = resp || {};
                // console.log("/getInvoices", body, data)
                if (data.result) {
                    this.setState({
                        ...options,
                        invoices: data.invoices,
                        loading: false,
                        pagination: {
                            ...pagination,
                            total: data.count
                        },
                        // sorter: _.isEqual(sorter, {}) ? this.state.sorter : sorter,
                        sortDirection: body.sortDirection,
                        sortFieldname: body.sortFieldname,
                        showClear: searchTerm ? true : false,
                    })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getInvoices", error, err, resp);
                ErrorAlert({ description: error.message })
            }
        })
    }

    fetchCOA = () => {
        const headers = {
            AionCurrentBiz: this.props.businessId,
            AionAuth: this.props.aionStore.UAM.encryptedAuthHeader
        }

        const body = {
            "BusinessId": this.props.businessId,
            "size": 1000,
            "page": 0,
            "ActiveRecords": true,
            "sortFieldName": "Code",
            "sortDirection": "ASC"
        }

        apiPOSTReq(`${environment.bbBaseUrl}/bk/getCategories`, headers, body, (err, resp) => {
            try {
                const data = resp;
                console.log("/bk/getCategories", data)
                if (data.result) {
                    this.setState({ categories: (data.systemCOAList || []).concat(data.customCOAList) })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getCategories", error.stack, resp);
                ErrorAlert({ description: error.message })
            }
        })
    }

    handleDateTypeSelection = (val) => {
        var fromDate, toDate = null;
        switch (val) {
            case "Year to Date":
                fromDate = moment().startOf("year").format(dFormat);
                toDate = moment().add(1, "day").format(dFormat);
                break;
            case "Last Year":
                fromDate = moment().subtract(1, "year").startOf("year").format(dFormat);
                toDate = moment().subtract(1, "year").endOf("year").format(dFormat);
                break;
            case "Current Month":
                fromDate = moment().startOf("month").format(dFormat);
                toDate = moment().format(dFormat);
                break;
            case "Previous Month":
                const prevMonth = moment().subtract(1, "month");
                fromDate = prevMonth.startOf("month").format(dFormat);
                toDate = prevMonth.endOf("month").format(dFormat);
                break;
            default:
                fromDate = this.state.fromDate;
                toDate = this.state.toDate;
                break;
        }
        var updatedState = { fromDate: fromDate, toDate: toDate, dateType: val, loading: true };
        this.fetchInvoices({ ...this.state, ...updatedState });
    }

    handleDateRange = (dates) => {
        var updatedState = {
            fromDate: dates[0].format(dFormat),
            toDate: dates[1].format(dFormat),
            dateType: "Custom",
            loading: true
        };
        this.fetchInvoices({ ...this.state, ...updatedState });
    }

    handleFilterSelection = (val) => {
        var updatedState = { filterType: val };
        console.log("handleFilterSelection", updatedState)
        this.fetchInvoices({ ...this.state, ...updatedState });
    }

    getSelectedRows = (selectedRowsPageDict) => {
        let selectedRows = []
        Object.values(selectedRowsPageDict).forEach(x => {
            selectedRows = selectedRows.concat(x)
        })
        return selectedRows
    }

    getSelectedRowKeys = (selectedRowKeyPageDict) => {
        let selectedRowKeys = []
        Object.values(selectedRowKeyPageDict).forEach(x => {
            selectedRowKeys = selectedRowKeys.concat(x)
        })
        return selectedRowKeys
    }

    handleRowSelection = (selectedRowKeys, selectedRows) => {
        let selectedRowsPageDict = this.state.selectedRowsPageDict;
        let selectedRowKeyPageDict = this.state.selectedRowKeyPageDict;

        selectedRowsPageDict[this.state.pagination.current] = selectedRows;
        selectedRowKeyPageDict[this.state.pagination.current] = selectedRowKeys;
        let newSelectedRows = this.getSelectedRows(selectedRowsPageDict);
        let newSelectedRowKeys = this.getSelectedRowKeys(selectedRowKeyPageDict);

        this.setState({ selectedRows: newSelectedRows, selectedRowKeys: newSelectedRowKeys })
    }

    handleProcessInvoice = (invoicePaymentProcessMethod) => {
        if (!this.state.reviewerNote) {
            ErrorAlert({ description: "Please update reviewer notes before proceeding." });
            return;
        }

        const { UserInfo } = this.props.aionStore;
        const body = {
            invoiceDocNumberList: this.state.selectedRows.map(x => x.docNumber),
            invoicePaymentProcessMethod: invoicePaymentProcessMethod,
            reviewerNote: this.state.reviewerNote,
            "reviewer": `${UserInfo.FirstName} ${UserInfo.LastName} (${UserInfo.Email})`,
        }
        console.log('/ive/invoice/processPayment', body)
        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/processPayment`, { AionCurrentBiz: this.props.businessId }, body, (err, resp) => {
            try {
                const data = resp || {};
                if (data.result) {
                    message.success(`Invoice ${invoicePaymentProcessMethod}ed`);
                    this.fetchInvoices({ ...this.state })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getInvoices", error, err, resp);
                ErrorAlert({ description: error.message })
            }
        })
    }

    render() {
        const { loading, pagination, invoices, dateType, fromDate, toDate, filterType, enablePaymentReminder, selectedRows, selectedRowKeys, loadingPaymentReminder, ccAddr, reminderTeamName, sorter, searchTerm, invoiceRefreshLoading } = this.state;
        const { theme } = this.props;

        var dropdownOptions = [];
        var dateOptions = ["Year to Date", "Current Month", "Previous Month", "Last Year", "Custom"];
        var statusFilterOptions = ["New", "Scheduled", "Pending", "Sent", "Canceled", "Delivered", "Opened", "Clicked"];
        dropdownOptions = dateOptions.map(item => (
            <Option key={item} value={item}>
                {item}
            </Option>
        ));

        return (
            <>
                <FlexColumn style={{ margin: '0 40px' }}>
                    <FlexColumn>
                        <Space size="large">
                            <LabeledInput
                                style={{ width: "500px", fontSize: "14px" }}
                                label="Search"
                                labelcolor={theme.colors.secondary3}
                                id="searchTerm"
                                key="searchTerm"
                                type="search"
                                placeholder="Search by Customers, Invoices or PO numbers"
                                className="no-left-padding"
                                allowClear
                                enterButton="Search"
                                noAsterisk
                                optional
                                value={searchTerm}
                                onChange={this.handleOnChange}
                                onSearch={(value, event) => {
                                    if (value && value.length > 0) {
                                        this.fetchInvoices({ searchTerm: value, pagination: this.state.pagination })
                                    } else {
                                        this.fetchInvoices({ searchTerm: "", pagination: this.state.pagination });
                                    }
                                }}
                            />
                            <LabeledInput
                                label="Date"
                                labelcolor={theme.colors.secondary3}
                                id="statementType"
                                key="statementType"
                                type="select"
                                placeholder="Select"
                                className="no-left-padding"
                                value={dateType}
                                onChange={this.handleDateTypeSelection}
                                style={{ width: "150px", fontSize: "0.95rem", fontWeight: 500 }}
                                noAsterisk
                                optional
                            >
                                {dropdownOptions}
                            </LabeledInput>
                            <LabeledInput
                                label="Date Range"
                                labelcolor={theme.colors.secondary3}
                                id="dateRange"
                                key="dateRange"
                                type="range-picker"
                                value={[moment(fromDate), moment(toDate)]}
                                format="MM/DD/YYYY"
                                onChange={this.handleDateRange}
                                style={{ width: "250px", fontSize: "1.1rem" }}
                                noAsterisk
                                optional
                            />
                            <LabeledInput
                                label="Filter Status"
                                labelcolor={theme.colors.secondary3}
                                id="filter"
                                key="filter"
                                type="select"
                                placeholder="Select"
                                className="no-left-padding"
                                mode="multiple"
                                allowClear
                                value={filterType}
                                onChange={this.handleFilterSelection}
                                style={{ width: "150px" }}
                                noAsterisk
                                optional
                            >
                                {statusFilterOptions.map(item => (
                                    <Option key={item} value={item}>
                                        {item}
                                    </Option>
                                ))}
                            </LabeledInput>
                        </Space>

                        <FlexColumn>
                            {((selectedRows || []).length > 0) &&
                                <div>
                                    <LabeledInput
                                        label="Reviewer Notes"
                                        id="reviewerNote"
                                        type="text-area"
                                        placeholder="Enter reason for confirmation/rejection"
                                        onChange={(e) => this.setState({ reviewerNote: e.target.value })}
                                    />
                                    <div style={{ display: 'flex', marginTop: '20px' }}>
                                        <Button
                                            permtype="Override"
                                            onClick={() => this.handleProcessInvoice("Confirm")}
                                            style={{ height: 45, width: "250px", margin: "0 10px 10px 0" }}
                                            text='Confirm invoices' />

                                        <Button
                                            permtype="Override"
                                            onClick={() => this.handleProcessInvoice("Reject")}
                                            style={{ height: 45, width: "250px", margin: "0 0 10px 0" }}
                                            text='Reject invoices' />
                                    </div>
                                </div>
                            }
                        </FlexColumn>
                    </FlexColumn>
                    <InvoiceTable
                        showSelectedInvoice={this.showSelectedInvoice}
                        loading={loading}
                        pagination={pagination}
                        sorter={sorter}
                        fetchInvoices={this.fetchInvoices}
                        invoices={invoices}
                        handleRowSelection={this.handleRowSelection}
                        showRowSelection={true}
                        selectedRowKeys={selectedRowKeys}
                        hideEditColumn={true}
                    />
                </FlexColumn>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)));