import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { apiPOSTReq } from '../../../Utils/api';
import { getBPAccountsWithAccess } from '../../../Utils/util';
import environment from '../../../environment';
import CompanyProfile from './CompanyProfile';
import moment from 'moment';

// Actions
import { addDataToStore, BNK_SAVE_DATA, USERDEFAULTS_SAVE_FLAG } from '../../../Actions/actions';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            showOpeningDepositModal: false,
        }
    }

    componentDidMount() {
        this.fetchData();
        // this.backgroundDataDetch();
        this.fetchScheduledPayments();
        
        const Accounts = getBPAccountsWithAccess("Transactions");

        console.log("AccountsAccountsAccounts", Accounts)
    }

    render() {
        return (
            <>
                <CompanyProfile {...this.props} {...this.state} />
            </>
        );
    }

    getBalance = () => {
        const Accounts = getBPAccountsWithAccess("Transactions");

        if ((Accounts || []).length > 0) {
            return Accounts[0].availableBalance
        } else {
            return 0
        }
    }

    fetchData = () => {
        this.setState({ loading: true });

        apiPOSTReq(`${environment.bbBaseUrl}/bb/getAccounts`, { "BankProvider": environment.bankProvider.crb }, null, (err, resp) => {
            try {
                console.log("/bb/getAccounts", err, resp);
                const data = resp;
                if (data.result) {
                    if (err) throw new Error(err);
                    var accounts = data.bbaccounts;

                    console.log("/bb/getAccounts", accounts);

                    var zeroBalance = true
                    var crb = false

                    accounts.forEach(account => {
                        if (account.bankProvider === "CrossRiverBank") {
                            crb = true
                        }
                        if (account.currentBalance != 0) {
                            zeroBalance = false
                        }
                    })

                    if (crb && zeroBalance) {
                        this.setState({ showOpeningDepositModal: true })
                    }

                    this.props.dispatch(addDataToStore(BNK_SAVE_DATA, { BBAccounts: accounts }));
                    // Update side menu
                    // this.props.updateSideMenu();
                    // this.getDailyBalances();
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getAccounts", error, err, resp);
                const fd = this.fetchData;
                // ErrorAlert({ description: error.message });
            }
        })
    }

    getDailyBalances = () => {
        var body = {
            fromDate: moment().subtract(91, "days").format("YYYY-MM-DD"),
            toDate: moment().subtract(1, "days").format("YYYY-MM-DD")
        }

        apiPOSTReq(`${environment.bbBaseUrl}/bb/dailyBalances`, {}, body, (err, resp) => {
            this.setState({ loading: false });
            try {
                const data = resp || {};
                console.log("getDailyBalances", data)
                // console.log("/bb/dailyBalances", JSON.stringify(data))
                if (data.result) {
                    this.props.dispatch(addDataToStore(BNK_SAVE_DATA, { DailyBalancesList: data.dailyBalancesList || [] }));
                    this.setState({ loading: false })
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/getDailyBalances err", error, resp);
                // ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    fetchScheduledPayments = () => {
        // fetchBBHistory({ viewBy: "ALL" }, (err, resp) => {
        //     console.log("fetchScheduledPayments fetchBBHistory", err, resp)
        //     this.setState({ outboundACH: ((resp || {}).achTransfersCount || 0) > 0 })
        // });

        // apiGET("/banking/radius/payments/scheduled", {}, (err, resp) => {
        //     this.setState({ submitLoading: false });
        //     try {
        //         const data = resp.data || {};
        //         console.log("fetchACH", data)

        //         // console.log("/banking/radius/payments/scheduled", JSON.stringify(data))

        //         if (data.success) {
        //             this.setState({
        //                 totalScheduled: data.totalScheduled,
        //                 totalWireScheduled: data.totalWireScheduled,
        //                 totalACHScheduled: data.totalACHScheduled,
        //                 scheduledACHTransfers: data.scheduledACHTransfers,
        //                 scheduledWireTransfers: data.scheduledWireTransfers,
        //                 outboundACH: (data.scheduledACHTransfers || []).filter(ach => ach.Direction === 'Outbound').length > 0
        //             });
        //         } else {
        //             throw Error(data.msg || "Sorry we had trouble processing your request, please try again.")
        //         }
        //     } catch (error) {
        //         ErrorAlert({ description: error.message });
        //     }
        // })
    }

    // fetchTransactions() {
    //     const headers = {
    //         businesskey: this.props.aionStore.BusinessUniqueKey,
    //         customerid: ''
    //     }

    //     apiGET("/financing/transactions", headers, (err, resp) => {
    //         try {
    //             const data = resp.data;
    //             console.log("fetchACH transactions", data)
    //             if (data.success) {
    //                 var transactions = data.Response.Transactions.sort((a, b) => new Date(b.Date) - new Date(a.Date))

    //                 this.setState({
    //                     transactions: transactions,
    //                     outstandingBalance: data.Response.OutstandingBalance,
    //                     interestAsOfToday: data.Response.InterestAsOfToday,
    //                     loadingTransactions: false
    //                 })
    //             } else {
    //                 throw Error("Could not fetch transactions.")
    //             }
    //         } catch (error) {
    //             console.log("ERRR", error.stack)
    //         }
    //     })
    // }
}

function mapStateToProps(state) {
    return {
        store: state.bankingAppReducer,
        aionStore: state.aionAppReducer,
        userDefaults: state.userDefaultsReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Home)));