import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import { Dropdown, Menu } from 'antd'

import { Divider } from '../../Reusable/Input'

import { Text } from '../../Reusable/Text'
import { Image } from '../../Reusable/Image'
import { Flex, FlexColumn, CardContainer } from '../../Reusable/Container'
import { TextButton, ImageButton } from '../../Reusable/Button'
import { getBPAccountsWithAccess, getFeaturePerm } from '../../../Utils/util'
import ArrowRight from "../../../Images/arrow-right.png"
import ArrowRightWhite from "../../../Images/arrow-white-right.png"
import ModalClose from '../../../Images/close-white.png'
import WarningBlue from '../../../Images/warning-blue.png'

const TransactCard = (props) => {
    const [dropdownVisible, setDropdownVisible] = useState(false)
    const [selected, setSelected] = useState(false)

    const { theme, onAddFunds, height, noDescription, transitional, moveMoneyDisabled } = props

    var Accounts = getBPAccountsWithAccess("Transactions", { getActiveAccounts: true })
    const instantPaymentsEnabled = getFeaturePerm("BusinessBanking.Payments.Instant Transfer")
    Accounts = Accounts || []

    return (
        <div style={{ width: !noDescription && 296, marginLeft: noDescription ? 0 : 24 }}>
            {
                transitional ?
                    <>
                        <CardContainer
                            padding='0'
                            height={height || '388px'}
                            width={noDescription ? '260px' : '296px'}>

                            <FlexColumn start gap='8px' style={{ padding: 24, width: '100%', height: '100%' }}>
                                <Flex start gap='8px' margin='0 0 8px' centerHorizontally>
                                    <Image src={WarningBlue} />
                                    <Text heading>Transitional Account</Text>
                                </Flex>

                                <Text size='16px'> Please work with your customers to start paying into your newly opened Aion Accounts Receivable bank account on the platform! Starting in April, we will be reducing support for payments coming into your transitional account. Please reach out to our customer success team in case of any questions.</Text>
                            </FlexColumn>
                        </CardContainer>
                    </>
                    :
                    <CardContainer
                        padding='0'
                        height={height || '388px'}
                        width={noDescription ? '260px' : '296px'}>
                        <FlexColumn start className={selected ? 'transact-back-selected' : noDescription ? 'transact-back-sm' : 'transact-back'} style={{ padding: 24, width: '100%', height: '100%' }}>
                            {
                                selected ?
                                    <FlexColumn>
                                        <Flex fullWidth between>
                                            <Text margin='0 0 8px' heading color='white'>Move Money</Text>
                                            <ImageButton onClick={() => setSelected(false)} src={ModalClose} />
                                        </Flex>
                                        <Text margin='5px 0 0' color='white'>Transfer funds to a recipient you’ve added</Text>
                                        {
                                            instantPaymentsEnabled.manage &&
                                            <TextButton margin={noDescription ? '0 0 0px' : '10px 0 -5px'} color='white' onClick={() => props.history.push({
                                                pathname: '/banking/instant-transfers/transfer-to-recipient',
                                                state: { rail: 'Instant' }
                                            })} rightIcon={<Image src={ArrowRightWhite} />} text="Instant Transfer" uppercase />
                                        }
                                        <TextButton margin={noDescription ? '0 0 20px' : '20px 0 16px'} color='white' onClick={() => props.history.push({
                                            pathname: '/banking/ach-transfers/transfer-to-recipient',
                                            state: { rail: 'ACH' }
                                        })} rightIcon={<Image src={ArrowRightWhite} />} text="ACH Transfer" uppercase />
                                        <TextButton margin={noDescription ? '0 0 20px' : '0px 0 16px'} color='white' onClick={() => props.history.push({
                                            pathname: '/banking/wire-transfers/transfer-to-recipient',
                                            state: { rail: 'Wire' }
                                        })} rightIcon={<Image src={ArrowRightWhite} />} text="Wire Transfer" uppercase />
                                        <Divider margin='8px 0 12px' color='#8795F2' />
                                        {
                                            Accounts.length > 1 && <>
                                                <Text margin='5px 0 0' color='white'>Transfer funds within your own Aion accounts</Text>
                                                <TextButton margin={noDescription ? '0 0 20px' : '12px 0 16px'} color='white' onClick={() => props.history.push('/banking/internal-transfer')} rightIcon={<Image src={ArrowRightWhite} />} text="Internal Transfer" uppercase />
                                            </>
                                        }
                                    </FlexColumn>
                                    :
                                    <>
                                        {
                                            !noDescription ? <>
                                                <Text margin='0 0 8px' heading>Transact</Text>
                                                <Text color={theme.colors.defaultLightText}>Send funds externally or transfer between accounts</Text>
                                                {
                                                    // Accounts.length > 1 ?
                                                    <TextButton margin={noDescription ? '0 0 20px' : '12px 0 16px'} onClick={() => setSelected(true)} rightIcon={<Image src={ArrowRight} />} text="MOVE MONEY" />
                                                    // :
                                                    // <TextButton margin={noDescription ? '0 0 20px' : '12px 0 16px'} onClick={() => props.history.push('/banking/transfer-to-recipient')} rightIcon={<Image src={ArrowRight} />} text="MOVE MONEY" />
                                                }
                                            </>
                                                :
                                                <Text margin='0 0 8px' heading>Transact</Text>
                                        }
                                        {
                                            noDescription && <> {Accounts.length > 1 ?
                                                <Dropdown
                                                    trigger={['click']}
                                                    overlay={<Menu style={{ padding: 24, borderRadius: 8 }}>
                                                        <FlexColumn gap='18px'>
                                                            <TextButton rightIcon={<Image src={ArrowRight} />} onClick={() => {
                                                                setDropdownVisible(false)
                                                                props.history.push('/banking/transfer-to-recipient')
                                                            }} text="Transfer to a recipient" />
                                                            <TextButton rightIcon={<Image src={ArrowRight} />} onClick={() => {
                                                                setDropdownVisible(false)
                                                                props.history.push('/banking/internal-transfer')
                                                            }} text="Internal transfer" />
                                                        </FlexColumn>
                                                    </Menu>}
                                                    onVisibleChange={flag => setDropdownVisible(flag)}
                                                    visible={dropdownVisible}
                                                >
                                                    <TextButton margin={noDescription ? '0 0 20px' : '12px 0 16px'} onClick={() => setDropdownVisible(!dropdownVisible)} rightIcon={<Image src={ArrowRight} />} text="MOVE MONEY" />
                                                </Dropdown>
                                                :
                                                <TextButton margin={noDescription ? '0 0 20px' : '12px 0 16px'} onClick={() => props.history.push('/banking/transfer-to-recipient')} rightIcon={<Image src={ArrowRight} />} text="MOVE MONEY" />
                                            }
                                            </>
                                        }
                                        {
                                            !noDescription && <FlexColumn fullWidth>
                                                <Divider margin='0 0 8px' />
                                                <Text margin='5px 0 0' color={theme.colors.defaultLightText}>Deposit details to add or receive funds into your account</Text>
                                            </FlexColumn>
                                        }
                                        <TextButton margin={noDescription ? '0 0 20px' : '12px 0 16px'} onClick={() => onAddFunds()} rightIcon={<Image src={ArrowRight} />} text="DEPOSIT DETAILS" />
                                        {
                                            !noDescription && <FlexColumn fullWidth>
                                                <Divider margin='0 0 8px' />
                                                <Text margin='5px 0 0' color={theme.colors.defaultLightText}>Add or manage recipients</Text>
                                            </FlexColumn>
                                        }
                                        <TextButton margin={noDescription ? '0 0 20px' : '12px 0 20px'} onClick={() => props.history.push({ pathname: '/banking/recipients', state: { back: true } })} rightIcon={<Image src={ArrowRight} />} text="MANAGE RECIPIENTS" />
                                    </>
                            }
                        </FlexColumn>
                    </CardContainer>
            }
        </div>
    )
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer,
        bankingStore: state.bankingAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(TransactCard)))