import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { saveAs } from 'file-saver';
import moment from 'moment';
import {
    message,
    Divider,
    Input,
    List,
    Button,
    Tabs
} from 'antd';
 
// Components
import { FlexColumn, Flex, CardContainer, LightContainer } from '../../Reusable/Container';
import { Text } from '../../Reusable/Text';
import PageHeader from '../../Reusable/PageHeader';
import { apiGET, apiGETDoc, apiGETDocUrl, apiPOSTReq } from '../../../Utils/api';
import environment from '../../../environment';
import { uaApiPOST, getUserPermissions } from '../../../Utils/userAccess';
import { completeSignin, SAVE_DATA, addDataToStore, UAM_SAVE_PERMISSIONS, UAM_SAVE_BUSINESS, resetStore, RESET_BNK_STATE, SOFT_RESET_MAIN_STATE, RESET_ONBOARDING_STATE, RESET_CREDIT_STATE, RESET_RECEIVABLES_STATE } from '../../../Actions/actions';
import { ErrorAlert } from '../../Reusable/Alert';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import Download from '../../../Images/download.png'
import { isEmailValid } from '../../../Utils/util';
import { ImageButton, TextButton } from '../../Reusable/Button';

const { TabPane } = Tabs;


class Index extends Component {

    state = {};

    onSearch = (value) => {
        console.log("onSearch", value)
        this.searchBusiness(value);
    };

    handleTextChange = (event) => {
        const target = event.target;
        console.log("handleTextChange", target.value);
        if(target.value.length > 2) this.searchBusiness(target.value);
    };

    searchBusiness = (searchStr) => {
        this.setState({ searchLoading: true });
        var body = { searchStr: `*${searchStr}*` }
        if(isEmailValid(searchStr)) body = { userEmail: `*${searchStr}*` }
        console.log("body", body);
        apiPOSTReq(`${environment.uamBaseUrl}/aion/system/searchBusiness`, {}, body, (err, resp) => {
            this.setState({ searchLoading: false });
            try {
                if(err) throw Error(err);
                const data = resp || {};
                if (data.result) {
                    console.log("/searchBusiness", data);
                    this.setState({ ...data })
                } else {
                    throw Error(data.responseMessage || data.error);
                }
            } catch (error) {
                console.log("/searchBusiness err", error, resp);
            }
        });
    }

    getAttributes() {
        // Check session and fetch attributes
        apiGET("/attributes", null, (err, resp) => {
            try {
                const data = resp.data;
                if(data.success) {
                    this.props.dispatch(completeSignin(data));
                    // Fetch Profile
                    this.fetchBusinessApps();
                } else {
                    throw Error("Could not fetch attributes.")
                }
            } catch (error) {
                console.log("/attributes ERR", error, err, resp)
                // ErrorAlert({description: "Sorry, we had trouble logging you in, please try again."});
                // this.setState({loading: false});
            }
        })
    }

    clearCache = () => {
        this.props.dispatch(resetStore(RESET_BNK_STATE))
        this.props.dispatch(resetStore(SOFT_RESET_MAIN_STATE))
        this.props.dispatch(resetStore(RESET_ONBOARDING_STATE))
        this.props.dispatch(resetStore(RESET_CREDIT_STATE))
        this.props.dispatch(resetStore(RESET_RECEIVABLES_STATE))
    }

    fetchBusinessApps = () => {
        apiPOSTReq(`${environment.uamBaseUrl}/aion/system/getAvailableApplications`, {}, {}, (err, resp) => {
            try {
                if(err) throw Error(err);
                const data = resp || {};
                if (data.result) {
                    console.log("/aion/system/getAvailableApplications", data);
                    this.props.dispatch(addDataToStore(UAM_SAVE_PERMISSIONS, { BizAppNames: data.applicationNames || [] }));
                    this.fetchProfile();
                } else {
                    throw Error(data.responseMessage || data.error);
                }
            } catch (error) {
                console.log("/aion/system/getAvailableApplications err", error, resp);
                ErrorAlert({ description: error.message });
            }
        });
    }

    fetchProfile() {
        apiGET("/profile", null, (err, resp) => {
            try {
                const pData = resp.data || {};
                // this.setState({loading: false})
                if(pData.success) {
                    this.props.dispatch(addDataToStore(SAVE_DATA, { Profile: pData.profileInfo }));
                    var { BusinessInfo } = pData.profileInfo || {};
                    this.fetchPermissions()
                    this.fetchIntegration()
                    this.fetchBusinessData()
                    this.getReferralCode()
                    message.success(`Successfully switched to ${BusinessInfo.Name}`)
                    setTimeout(() => { window.location.reload() }, 1000);
                } else {
                    throw Error("Could not fetch profile.")
                }
            } catch (error) {
                console.log("ERRR", error.stack)
            }
        });
    }

    fetchBusinessData = (options) => {
        options = options || {};
        apiPOSTReq(`${environment.uamBaseUrl}/getBusiness`, {}, {}, (err, resp) => {
            try {
                if (err) throw Error(err);
                const data = resp || {};
                if (data.result) {
                    // var business = data.business || {};
                    this.props.dispatch(addDataToStore(UAM_SAVE_BUSINESS, data));
                } else {
                    throw Error(data.responseMessage || data.error);
                }
            } catch (error) {
                console.log("/getBusiness err", error, resp);
            }
        });
    }


    fetchIntegration = () => {
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/hasIntgration`, {}, {}, (err, resp) => {
            console.log("clear fetchIntegration", resp)
            try {
                if (err) throw Error(err)
                const data = resp || {}
                if (data.result) {
                    this.props.dispatch(addDataToStore(SAVE_DATA, { hasIntegration: data.hasIntegration || false }))
                    if (!data.hasIntegration) {
                        return
                    }

                    apiPOSTReq(`${environment.integrationBaseUrl}/integration/getCurrentSyncSW`, {}, {}, (err, resp) => {
                        try {
                            if (err) throw Error(err)
                            const data = resp || {}
                            if (data.result) {
                                this.props.dispatch(addDataToStore(SAVE_DATA, { CurrentSync: data.integration }))
                                this.props.dispatch(addDataToStore(SAVE_DATA, { hideNotification: data.hideNotification }))
                            } else {
                                throw Error(data.responseMessage || data.error)
                            }
                        } catch (error) {
                            console.log("/getCurrentSyncSW err", error, resp)
                        }
                    })
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/getCurrentSyncSW err", error, resp)
            }
        })
    }

    fetchPermissions = () => {
        // Fetch user permissions and adapt the layout based on the user's access
        getUserPermissions(null, (err, resp) => {
            try {
                if (err) throw Error(err)
                if (!resp.data.authToken) throw Error(resp.data.responseMessage)
                this.props.dispatch(addDataToStore(UAM_SAVE_PERMISSIONS, { UAM: resp.data }))
                // message.success(`Successfully switched!`)
                // fetchBankAccounts()
            } catch (error) {
                ErrorAlert({ description: error.message })
                // this.props.history.push('/home')
                // setLoading(false)
            }
        })
    }

    switchToBusiness(record) {
        // console.log("switchToBusiness", record);
        this.setState({ switchLoading: true, businessLoading: record });
        uaApiPOST("/aion/system/switchBusiness", { body: { businessId: record.id } }, (err, resp) => {
            try {
                var data = resp || {};
                if(err) throw Error(err);
                if(data.result) {
                    this.clearCache();
                    this.getAttributes();
                }
                else throw Error(data.error || data.responseMessage);
            } catch (error) {
                console.log("switchBusiness Err", error, err, resp);
                ErrorAlert({ description: error.message })
            }
        });
    }

    downloadCSVReport = () => {
        this.setState({ loadingCSV: true });
        apiGETDocUrl(`${environment.uamBaseUrl}/aion/system/export/csv/subscribedApps`, {}, {}, (err, resp) => {
            this.setState({ loadingCSV: false });
            const data = (resp || {}).data;
            try {
                if (err) throw Error("We had trouble downloading your statement. Please try again.");
                const blob = new Blob([data], {
                    type: 'application/vnd.ms-excel',
                });
                const fileURL = URL.createObjectURL(blob);
                saveAs(fileURL, `Client Summary Report ${moment().format('ll')}.csv`);
            } catch (error) {
                ErrorAlert({ description: error.message });
            }
        })
    }

    downloadReferralCSVReport = () => {
        this.setState({ loadingRefCSV: true });
        apiGETDocUrl(`${environment.uamBaseUrl}/aion/system/getReferralCodeCSV`, { 'aionAuth': environment.apiKey, 'Content-Type': 'application/octet-stream' }, {}, (err, resp) => {
            this.setState({ loadingRefCSV: false });
            const data = (resp || {}).data;
            try {
                if (err) throw Error("We had trouble downloading the file. Please try again.");
                const blob = new Blob([data], {
                    type: 'text/csvcharset=utf-8',
                });
                const fileURL = URL.createObjectURL(blob);
                saveAs(fileURL, `Referral Code Report ${moment().format('ll')}.csv`);
            } catch (error) {
                ErrorAlert({ description: error.message });
            }
        })
    }

    getReferralCode = () => {
        apiPOSTReq(`${environment.uamBaseUrl}/aion/system/getReferralCode`, {}, {}, (err, resp) => {
            try {
                const data = resp || {};
                if (data.result) {
                    console.log("/aion/system/getReferralCode data", data)
                    this.props.dispatch(addDataToStore(SAVE_DATA, { referralsData: { referralCode: data.referralCode, inviteCount: data.inviteCount, referralAmount: data.referralAmount }}))
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("getReferralCode error", { description: error.message })
            }
        })
    }

    render() {
        const { theme } = this.props;
        const { businessList, searchLoading, switchLoading, businessLoading, loadingCSV, loadingRefCSV } = this.state;
        return (
            <FlexColumn style={{ margin: '0 40px' }}>
                <PageHeader 
                    titleText="Business Search"
                    desc="Search for a business by name and switch to that business"
                />
                <Tabs size="large" defaultActiveKey="search">
                    <TabPane tab="Search" key="search">
                        <FlexColumn style={{ margin: '20px 0', width: "700px" }}>
                            <Input.Search 
                                placeholder="Enter a business name" 
                                enterButton="Search" 
                                size="large" 
                                allowClear 
                                onSearch={this.onSearch} 
                                onChange={this.handleTextChange}
                                loading={searchLoading}
                            />
                            {
                                switchLoading &&
                                <Flex start centerHorizontally gap='8px' style={{ margin: '20px 0' }}>
                                    <LoadingOutlined style={{ color: theme.colors.secondary3, fontSize: "1.5rem" }} />
                                    <Text color={theme.colors.primary2}>
                                        Switching to {businessLoading.businessProfile.businessInfo.name}...
                                    </Text>
                                </Flex>
                                
                            }
                            <List 
                                style={{ marginTop: "30px" }}
                                dataSource={businessList || []}
                                renderItem={item => {
                                    // const 
                                    return (
                                        <>
                                            <Flex centerHorizontally between>
                                                <Text>{item.businessProfile.businessInfo.name}</Text>
                                                <Button type="link" onClick={() => this.switchToBusiness(item)}>Switch business</Button>
                                            </Flex>
                                            <Divider style={{ margin: "10px 0" }} />
                                        </>
                                    )
                                }}
                            />
                        </FlexColumn>
                    </TabPane>
                    <TabPane tab="Reports" key="reports">
                        <CardContainer margin='12px 0 24px' fullWidth>
                            <Flex fullWidth start gap='8px'>
                                <LightContainer width='284px' padding='12px 24px' margin='0 24px 12px 0'>
                                    <Flex between fullWidth centerHorizontally>
                                        <Text>Client List by Referral Code</Text>
                                        <Flex centerHorizontally>
                                            <TextButton text='' loading={loadingRefCSV} rightIcon={<img style={{ cursor: 'pointer' }} width='24px' height='24px' src={Download} />} onClick={() => this.downloadReferralCSVReport()} />
                                        </Flex>
                                    </Flex>
                                </LightContainer>
                                <LightContainer width='284px' padding='12px 24px' margin='0 24px 12px 0'>
                                    <Flex between fullWidth centerHorizontally>
                                        <Text>Client Summary Report</Text>
                                        <Flex centerHorizontally>
                                            <TextButton text='' loading={loadingCSV} rightIcon={<img style={{ cursor: 'pointer' }} width='24px' height='24px' src={Download} />} onClick={() => this.downloadCSVReport()} />
                                        </Flex>
                                    </Flex>
                                </LightContainer>
                            </Flex>
                        </CardContainer>
                    </TabPane>
                </Tabs>
                
            </FlexColumn>
        );
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)));