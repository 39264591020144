import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'
import styled from 'styled-components'
import { saveAs } from 'file-saver'
import { Table } from 'antd'
import { Text } from './Text'
import { Image } from './Image'
import { Flex, FlexColumn } from './Container'
import environment from '../../environment'
import { CheckCircleFilled, DownloadOutlined, WarningFilled, WarningOutlined } from '@ant-design/icons'
import { apiPOSTReq } from '../../Utils/api'
import { ErrorAlert } from './Alert'
import { Button, TextButton } from '../Reusable/Button'

export const CardButton = styled.div`
    margin-top: 18px;
    background-color: rgb(255, 255, 255);
    border-radius: 0.5rem;
    width: 450px;
    margin-bottom: 1.5rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    opacity: 1;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    padding: 1.8rem;
    transition-property: box-shadow;
    transition-duration: 300ms;
    &:hover {
        cursor: pointer;
        box-shadow: 0 3px 15px rgb(0 0 0 / 0.4);
    };
`

export const CardButtonText = styled.span`
    font-size: 18px;
    font-weight: 400;
`

class Result extends Component {

    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            file: null,
            fileName: '',
            showErrorModal: false,
            fileList: [],
        }
    }

    handleDownloadResult = () => {
        const { downloadVendorUploadJobId } = this.props.aionStore
        if (!downloadVendorUploadJobId) {
            ErrorAlert({ description: "Sorry, we had trouble processing your request. Please try again." })
            return
        }

        apiPOSTReq(`${environment.payBaseUrl}/payables/vendors/getUploadStatusAsCsv`, {}, { uploadJobId: downloadVendorUploadJobId }, (err, resp) => {
            try {
                const data = resp || {}
                if (data) {
                    const blob = new Blob([data.uploadVendorInfoStr], {
                        type: 'application/vnd.ms-excel',
                    })
                    const fileURL = URL.createObjectURL(blob)
                    saveAs(fileURL, `error.csv`)
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/counterparty err", error, resp)
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    render() {
        const { theme, ctaText } = this.props

        const headerErrorsColumns = [
            {
                title: 'Header Name',
                dataIndex: 'colName',
                key: 'colName',
                render: (item, record) => item
            },
        ]

        const columns = [
            {
                title: 'Row No.',
                dataIndex: 'row',
                key: 'row',
                width: 100,
                render: (item) => item
            }
        ]
        if(!this.props.ignoreNameColumn) {
            columns.push({
                title: 'Name',
                dataIndex: 'rowKey',
                key: 'rowKey',
                render: (item) => item
            })
        }
        if (!this.props.afterInitialUpload) {
            columns.push({
                title: 'Error Type',
                dataIndex: 'errorMessage',
                key: 'errorMessage',
                render: (item) => item
            })
        }

        const errorColumns = [
            {
                title: this.props.errorColumnTitle || "",
                dataIndex: 'colName',
                key: 'colName',
                render: (item, record) => item
            },
        ]

        let showHeaderErrorTable = this.props.showHeaderErrorTable
        let errors = this.props.errors

        let errorNum = this.props.errorNum
        let successNum = this.props.successNum

        return (
            <>
                <FlexColumn start style={{ minHeight: "80%", margin: 0 }}>
                    <Flex style={{ justifyContent: 'space-between', alignItems: 'flex-end' }}>
                        <Text heading>{this.props.uploadTypeTitle}</Text>
                        {this.props.afterInitialUpload && <TextButton style={{ color: theme.colors.secondary1 }} onClick={this.handleDownloadResult} icon={<DownloadOutlined />} text='Download results' />}
                    </Flex>
                    <div style={{ display: 'flex', marginBottom: 14, alignItems: 'center' }}>
                        {showHeaderErrorTable && <WarningOutlined style={{ fontSize: 28, marginRight: 12, marginBottom: '20px' }} />}
                        <div>
                            <Text>{this.props.responseErrorMessage && <>{this.props.responseErrorMessage} <br /></>}{this.props.errorMessage}</Text>
                        </div>
                    </div>

                    {!showHeaderErrorTable && <div style={{ fontSize: '20px', fontWeight: 600, marginBottom: '30px' }}>
                        {
                            this.props.successMsg && this.props.afterInitialUpload && <div style={{ display: 'flex', alignItems: 'center', color: theme.colors.secondary1, marginBottom: '15px' }}>
                                <CheckCircleFilled style={{ marginRight: '15px', fontSize: '34px' }} />
                                <div>{successNum} {this.props.successMsg}</div>
                            </div>
                        }
                        <div style={{ color: theme.colors.warning, display: 'flex', alignItems: 'center' }}>
                            <WarningFilled style={{ marginRight: 12, fontSize: 22 }} /><span style={{ marginTop: 2 }}>{errorNum} {this.props.errorMsg || "Errors"}</span>
                        </div>
                    </div>}

                    {
                        this.props.errorData ?
                            <Table
                                dataSource={this.props.errorData}
                                columns={errorColumns}
                                size="small"
                            />
                            :
                            <Table
                                dataSource={errors}
                                columns={showHeaderErrorTable ? headerErrorsColumns : columns}
                                size="small"
                            />
                    }

                    {
                        this.props.onSubmit &&
                        <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", marginTop: 20 }}>
                            <Button permtype="Override" solid onClick={() => this.props.onSubmit()} text={ctaText ? ctaText : "Done"} />
                        </div>
                    }
                </FlexColumn>

            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        bkStore: state.bookkeepingAppReducer,
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Result))