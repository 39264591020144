import React, { useState, useEffect } from 'react'
import { withTheme } from 'styled-components';
import moment from 'moment'
import { Modal, Select, Skeleton, Popover } from 'antd'
import {
    VictoryLegend,
    VictoryPie,
    VictoryTooltip,
} from "victory";

import { getTextWidth, toCurrency } from '../../Utils/util';
import { Flex, FlexColumn, CardContainer } from './Container';
import { Text, Tag } from './Text';
import { LabeledInput } from './Input'
import { BasicOption } from './Option'
import { Image } from './Image'
import { apiPOSTReq, apiGETDocUrl } from '../../Utils/api'
import environment from '../../environment'


// Images
import Clock from "../../Images/clock.png"


const { Option } = Select


const getMaxTextWidth = (data) => {
    if (!Array.isArray(data)) return 0;  // Return a default value if data isn't available or isn't an array

    let canvas = document.createElement("canvas");
    let context = canvas.getContext("2d");
    context.font = "14px InterDisplay";  // Font used in your components
    return Math.max(...data.map(item => context.measureText(`${item.label} $${toCurrency(item.spend)}`).width)) + 54;
}

const CustomLabel = (props) => {
    const { x, y, datum } = props;

    const leftText = datum.name;   // or some other property
    const rightText = `$${toCurrency(datum.value)}`;

    // Use Canvas to measure the width of the texts for accurate positioning
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = '14px InterDisplay';  // The font size and family you're using in VictoryLegend
    const leftTextWidth = context.measureText(leftText).width;
    const rightTextWidth = context.measureText(rightText).width;
    const padding = 10;  // Space between the two texts

    // Compute the positions
    const leftTextPosition = x;
    const rightTextPosition = leftTextPosition + leftTextWidth + padding;

    return (
        <text y={y} dy={5}>
            <tspan x={leftTextPosition} textAnchor="start">{leftText}</tspan>
            <tspan x={rightTextPosition} textAnchor="start">{rightText}</tspan>
        </text>
    );
};

const CustomTooltip = ({ x, y, datum, totalSpend, active }) => {
    if (!active) return null; // Don't render anything if not active

    const percentage = ((datum.spend / totalSpend) * 100).toFixed(2);
    const width = 30 + Math.max(getTextWidth(datum.label), getTextWidth(`$${toCurrency(datum.spend)} | ${percentage}%`));

    return (
        <g>
            <rect x={x} y={y} width={width} height={60} fill="url(#gradient)" rx="4" ry="4" />
            <text x={x + 10} y={y + 22} fill="white">{datum.label}:</text>
            <text x={x + 10} y={y + 45} fill="white">${toCurrency(datum.spend)} | {percentage}%</text>
        </g>
    );
};


const SpendByCategory = ({ last4, initialActivityDuration = "Current Month", ...props }) => {
    const [spendDuration, setSpendDuration] = useState(initialActivityDuration);
    const [currentMonthData, setCurrentMonthData] = useState(true)
    const [lastMonthData, setLastMonthData] = useState(true)
    const [yearToDateData, setYearToDateData] = useState(true)
    const [data, setData] = useState(props.currentMonthCategorySpend[last4])

    // If data is not available, provide a default value (an empty array in this case)
    const validData = Array.isArray(data) ? processSpendData(data) : [];

    const maxTextWidth = getMaxTextWidth(validData);
    const pieWidth = 160;  // Given in your code
    const padding = 20;  // Additional space
    const totalWidth = maxTextWidth + pieWidth + padding;

    const legendData = validData.map(item => ({
        name: item.label,
        value: item.spend,
    }));

    const totalSpend = validData.reduce((acc, item) => acc + item.spend, 0);

    useEffect(() => {
        switch (spendDuration) {
            case "Current Month":
                setData(props.currentMonthCategorySpend[last4])
                break
            case "Previous Month":
                setData(props.lastMonthCategorySpend[last4])
                break
            case "Year To Date":
                setData(props.yearToDateCategorySpend[last4])
                break
            default:
                setData(props.currentMonthCategorySpend[last4])
                break
        }
    }, [last4])

    useEffect(() => {
        switch (spendDuration) {
            case "Current Month":
                setData(props.currentMonthCategorySpend[last4])
                break
            case "Previous Month":
                setData(props.lastMonthCategorySpend[last4])
                break
            case "Year To Date":
                setData(props.yearToDateCategorySpend[last4])
                break
            default:
                setData(props.currentMonthCategorySpend[last4])
                break
        }
    }, [spendDuration])

    function processSpendData(data) {
        // Sort by spend in descending order
        const sortedData = [...data].sort((a, b) => b.spend - a.spend);

        // If there are more than 4 items, combine the rest as 'Other'
        if (sortedData.length > 5) {
            const otherSpend = sortedData.slice(4).reduce((acc, item) => acc + item.spend, 0);
            return [
                ...sortedData.slice(0, 4),
                { label: 'Other', spend: otherSpend }
            ];
        }
        return sortedData;
    }

    return (
        <Flex style={{ padding: '24px 24px 24px 0', height: '300px', width: '100%', minWidth: `${totalWidth + 24}px` }} {...props}>
            <FlexColumn start gap='0' fullWidth center>
                <Flex between centerHorizontally style={{ paddingLeft: 8, minWidth: 400 }} fullWidth>
                    <Text heading>Spend By Category</Text>
                    <div style={{ width: 191 }}>
                        <LabeledInput
                            nomargin
                            // prefixIcon={<img width='18px' height='18px' src={CalendarIcon} />}
                            type='select'
                            defaultValue={initialActivityDuration}
                            value={<Text>{spendDuration}</Text>}
                            onChange={(value) => setSpendDuration(value)}>
                            <Option value='Current Month' label='Current Month' style={{ backgroundColor: "transparent" }}>
                                <BasicOption value='Current Month' />
                            </Option>
                            <Option value='Previous Month' label='Previous Month' style={{ backgroundColor: "transparent" }}>
                                <BasicOption value='Previous Month' />
                            </Option>
                            <Option value='Year To Date' label='Year To Date' style={{ backgroundColor: "transparent" }}>
                                <BasicOption value='Year To Date' />
                            </Option>
                        </LabeledInput>
                    </div>
                </Flex>

                {
                    !data ?
                        <FlexColumn center style={{ height: 170, gap: 0, paddingTop: 28 }}>
                            <Text heading>No transactions</Text>
                            <Text width='400px' center>No card spend in the {spendDuration.toLowerCase()}</Text>
                        </FlexColumn>
                        :
                        <>
                            {
                                validData.length > 0 &&
                                <svg width={totalWidth} height={295}>
                                    <defs>
                                        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                                            <stop offset="0%" stop-color="#000000" />
                                            <stop offset="100%" stop-color="#333333" />
                                        </linearGradient>
                                    </defs>

                                    <VictoryLegend
                                        x={20 + pieWidth + padding / 2}
                                        y={38} // Adjust the positioning
                                        standalone={false}
                                        width={maxTextWidth}
                                        colorScale={["#1199FF", "#8795F2", "#83BC82", "#FF6B6B", "#FFD166"]}
                                        orientation="vertical"
                                        data={legendData}
                                        style={{ labels: { fontSize: 14, fontFamily: 'InterDisplay' } }}
                                        labelComponent={<CustomLabel />}
                                        rowGutter={{ top: 0, bottom: 5 }}
                                    />
                                    <VictoryPie
                                        origin={{ x: 100, y: 124 }}
                                        standalone={false}
                                        colorScale={["#1199FF", "#8795F2", "#83BC82", "#FF6B6B", "#FFD166"]}
                                        padding={0}
                                        padAngle={2}
                                        cornerRadius={5}
                                        innerRadius={70}
                                        height={pieWidth}
                                        width={pieWidth}
                                        data={validData}
                                        labels={({ datum }) => `$${datum.spend}`} // Displays the spend amount on tooltip.
                                        labelComponent={
                                            <CustomTooltip
                                                totalSpend={totalSpend}
                                                cornerRadius={2}
                                                orientation="right"
                                            />
                                        }
                                        x="label"
                                        y="spend"
                                        events={[
                                            {
                                                target: "data",
                                                eventHandlers: {
                                                    onMouseOver: (event, props) => {
                                                        return [
                                                            {
                                                                target: "data",
                                                                eventKey: props.index,
                                                                mutation: (props) => {
                                                                    return {
                                                                        radius: props.radius + 10, // increase radius by 10 (you can adjust this value)
                                                                    };
                                                                }
                                                            },
                                                            {
                                                                target: "labels",
                                                                eventKey: props.index,
                                                                mutation: (props) => {
                                                                    return { active: true };
                                                                }
                                                            }
                                                        ];
                                                    },
                                                    onMouseOut: (event, props) => {
                                                        return [
                                                            {
                                                                target: "data",
                                                                eventKey: props.index,
                                                                mutation: () => {
                                                                    return {};
                                                                }
                                                            },
                                                            {
                                                                target: "labels",
                                                                eventKey: props.index,
                                                                mutation: () => {
                                                                    return { active: false };
                                                                }
                                                            }
                                                        ];
                                                    }
                                                }
                                            }
                                        ]}
                                    />
                                    <text x="100" y="124" textAnchor="middle" dominantBaseline="middle" style={{ fontSize: 20, fontWeight: 'bold' }}>
                                        {`$${toCurrency(totalSpend)}`}
                                    </text>
                                </svg>
                            }
                        </>
                }
            </FlexColumn>
        </Flex>
    );
};

export default withTheme(SpendByCategory);
