import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import { Tabs, Table, Modal, Space, Tooltip, Input, Tag, Skeleton } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

// Components
import PageHeader from '../../Reusable/PageHeader';
import { FlexColumn, Flex, Container } from '../../Reusable/Container';
import environment from '../../../environment';
import { apiPOSTReq } from '../../../Utils/api';
import moment from 'moment';
import { ErrorAlert } from '../../Reusable/Alert';
import { Button } from '../../Reusable/Button';
import ReviewForm from '../Review/ReviewForm';
import { Text } from '../../Reusable/Text';
import { SignedLink } from '../../Reusable/Link';
import { toCurrency } from '../../../Utils/util';


const WOStatusTag = (options) => {
    let statusMsg;
    let statusColor = "blue";
    switch (options.status) {
        case "VALIDATED":
            statusColor = "green";
            statusMsg = "Approved"
            break
        case "REJECTED":
            statusColor = "red";
            statusMsg = "Rejected"
            break
        default:
            statusMsg = "Verifying"
            break
    }
    return (<Tag color={statusColor}>{statusMsg}</Tag>);
}

class Index extends Component {

    state = {
        loading: true,
        pagination: {
            current: 1,
            pageSize: 100
        },
        pos: []
    };

    componentDidMount() {
        this.fetchData({ pagination: this.state.pagination });
    }

    fetchData = (options) => {
        const { pagination } = options || {};
        var body = {
            "size": pagination.pageSize,
            "page": pagination.current - 1,
            "sortDirection": "DESC"
        };

        this.setState({ loading: true });
        console.log("getActivePOs body", body);
        apiPOSTReq(`${environment.opsBaseUrl}/ops/po/getPOs`, null, body, (err, resp) => {
            // apiPOSTReq(`${environment.opsBaseUrl}/ops/po/getPOs`, {}, body, (err, resp) => {
            try {
                this.setState({ loading: false, searchLoading: false });
                const data = resp || {};
                console.log("/ive/po/getActivePOs", err, data);
                if (data.result) {
                    this.setState({ pos: data.pos || [], pagination: { ...pagination, total: data.count } })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /ive/po/getActivePOs", error, err, resp);
                ErrorAlert({ description: error.message });
            }
        })
    }

    getColumns = () => {
        const { theme } = this.props;
        return [
            {
                title: 'Document',
                dataIndex: 'ponumber',
                render: (ponumber, item) => <SignedLink uri={item.documentUrl.uri} fileName={`#${ponumber}`} />,
                width: "150px"
            },
            {
                title: 'PO Number',
                dataIndex: 'ponumber',
                width: "150px"
            },
            {
                title: 'PO Value',
                dataIndex: 'totalValue',
                width: "150px",
                render: item => (item && "$" + toCurrency(item, 0, 2)),
            },
            {
                title: 'Customer Name',
                dataIndex: 'customerName',
                width: "200px"
            },
            {
                title: 'MSA',
                dataIndex: 'msaname',
                width: "150px",
            },
            {
                title: 'MSA Expiration Date',
                dataIndex: 'msaValidThrough',
                width: "120px",
                render: msaValidThrough => msaValidThrough && (moment(msaValidThrough).format('ll')),
            },
            {
                title: 'Created On',
                dataIndex: 'metaData',
                width: "120px",
                render: item => (moment(item.createTime).format('ll')),
            },
            {
                title: 'Expiration Date',
                dataIndex: 'dueDate',
                width: "120px",
                render: dueDate => (moment(dueDate).format('ll')),
            },
            {
                title: 'Status',
                dataIndex: 'verificationStatus',
                render: verificationStatus => (<WOStatusTag status={verificationStatus} />)
            }
        ]
    }

    handleTableChange = (pagination, filters, sorter) => {
        this.fetchData({ pagination: pagination });
    }

    handleRowSelection = (selectedRowKeys, selectedRows) => {
        console.log("handleRowSelection", selectedRows, selectedRowKeys)
        this.setState({ selectedRows: selectedRows, selectedRowKeys: selectedRowKeys })
    }
    getApprovalStatus = (verificationStatus) => {
        let approvalStatus = "";
        if (verificationStatus === 'VALIDATED') {
            approvalStatus = "Approved";
        } else if (verificationStatus === 'REJECTED') {
            approvalStatus = "Rejected";
        } else {
            approvalStatus = "Verifying";
        }
        return approvalStatus;
    }
    getRowDetails = (props) => {
        var { verificationStatus, reviewer, reviewedDate, reviewerNote } = props;
        const { theme } = this.props;
        const dateFormat = "MM/DD/YY";
        const approvalStatus = this.getApprovalStatus(verificationStatus);

        return (
            (approvalStatus != 'Verifying') ? <Skeleton loading={this.state.loadingTrackLog} active title={false} paragraph={{ rows: 3 }}>
                <Flex style={{ padding: "20px", background: theme.body, justifyContent: "space-around", alignItems: 'flex-start' }}>
                    <FlexColumn style={{ width: '40%' }}>
                        <div style={{ fontWeight: 'bold', fontSize: '16px', marginBottom: '10px' }}>Purchase Order approvals</div>
                        <div>
                            <div style={{ color: theme.colors.secondary4 }}>{approvalStatus} by:</div>
                            <div>{reviewer}</div>
                        </div>
                        <div style={{ marginTop: '10px' }}>
                            <div style={{ color: theme.colors.secondary4 }}>{approvalStatus} on:</div>
                            <div>{moment(reviewedDate).format(dateFormat)}</div>
                        </div>
                    </FlexColumn>
                    <FlexColumn>
                        <div style={{ fontWeight: 'bold', fontSize: '16px', marginBottom: '10px' }}>Reviewer note</div>
                        <div>{reviewerNote}</div>
                    </FlexColumn>
                </Flex>
            </Skeleton> : <></>
        )
    }

    render() {
        const { theme, hidetitle } = this.props;
        const { loading, pos, pagination, selectedRows, selectedRowKeys, showUpdateModal } = this.state;

        return (
            <>
                <FlexColumn style={{ margin: hidetitle ? 0 : `0 40px` }}>
                    {!hidetitle &&
                        <PageHeader
                            titleText="Purchase Orders"
                            desc=""
                        />}

                    <FlexColumn>
                        {((selectedRows || []).length > 0) &&
                            <Button
                                permtype="Override"
                                onClick={() => this.setState({ showUpdateModal: true })}
                                style={{ height: 45, width: "250px", margin: "0 10px 10px 0" }}
                                text='Update POs' />
                        }
                    </FlexColumn>
                    <Container shadow style={{ margin: '24px 0' }}>
                        <Table
                            id="inbox-table"
                            tableLayout='auto'
                            columns={this.getColumns()}
                            dataSource={pos}
                            rowKey='id'
                            pagination={pagination}
                            onChange={this.handleTableChange}
                            style={{ minHeight: "500px" }}
                            scroll={{ y: '700px', x: '100%' }}
                            loading={loading}
                            rowSelection={{
                                type: "checkbox",
                                onChange: this.handleRowSelection,
                                selectedRowKeys: selectedRowKeys,
                            }}
                            expandable={!this.props.disableExpandable && {
                                expandedRowRender: record => this.getRowDetails(record),
                                expandRowByClick: true,
                                expandIconColumnIndex: -1,
                                columnWidth: "2px",
                                indentSize: "20px",
                                expandIcon: ({ expanded, onExpand, record }) =>
                                    !expanded ? (
                                        <DownOutlined onClick={e => onExpand(record, e)} />
                                    ) : (
                                        <UpOutlined onClick={e => onExpand(record, e)} />
                                    )
                            }}
                        />
                    </Container>
                </FlexColumn>
                <Modal
                    visible={showUpdateModal}
                    footer={null}
                    closable={true}
                    width={700}
                    style={{ top: 20 }}
                    destroyOnClose={true}
                    onCancel={() => this.setState({ showUpdateModal: false })}
                >
                    <ReviewForm
                        itemType="po"
                        ids={selectedRowKeys}
                        title="Reviewer details"
                        desc={<Text><span style={{ fontWeight: 500 }}>{(selectedRows || []).map(item => item.ponumber).join(", ")}</span> Please add your details to approve/reject POs </Text>}
                        onSuccess={() => {
                            this.setState({ showUpdateModal: false, selectedRowKeys: null, selectedRows: null })
                            this.fetchData({ pagination: this.state.pagination })
                        }}
                    />
                </Modal>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        bkStore: state.bookkeepingAppReducer,
        aionStore: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Index));