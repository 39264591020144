import React, { useState, useEffect } from 'react';
import { withRouter, useLocation, useHistory } from 'react-router-dom'
import { connect, useSelector, useDispatch } from 'react-redux';
import { withTheme } from 'styled-components'
import { Modal, Dropdown, Menu, Tabs } from 'antd'
import { getUserRoles, getFeaturePerm, getResourcePerm, getBPAccountsWithAccess } from '../../../Utils/util'
import { fetchBBHistory, fetchBBTransactions, normalizeAndSortHistory, fetchBBACH } from '../../../Utils/bankingDataManager'
import { Flex, FlexColumn, CardContainer } from '../../Reusable/Container'
import { addDataToStore, BNK_SAVE_DATA } from '../../../Actions/actions'
import PageHeader from '../../Reusable/PageHeader'
import { ErrorAlert } from '../../Reusable/Alert'
import { Text, Tag } from '../../Reusable/Text'
import { Image } from '../../Reusable/Image'
import { Button, TextButton } from '../../Reusable/Button'
import UploadBatchModal from '../../Reusable/UploadBatchModal'
import CompanyCards from './../../Settings/Company Profile/CompanyCards'
import ACHActivity from './ACHActivity'
import WireActivity from './WireActivity'
import CheckActivity from './CheckActivity'
import InternalActivity from './InternalActivity'
import InstantActivity from './InstantActivity'
import Info from '../../../Images/info.png'
import ArrowRight from "../../../Images/arrow-right.png"
import ModalClose from '../../../Images/modal-close.png'
import ACHBatchUpload from './ACHBatchUpload'


const { TabPane } = Tabs

const pageSize = 50;

const Index = (props) => {
    const [achDropdownVisible, setAchDropdownVisible] = useState(false);
    const [wireDropdownVisible, setWireDropdownVisible] = useState(false);
    const [showBatchUploadModal, setShowBatchUploadModal] = useState(false)
    const [showWireBatchUploadModal, setWireShowBatchUploadModal] = useState(false)
    const [postedTransfers, setPostedTransfers] = useState([])
    const [loadingPosted, setLoadingPosted] = useState(true)
    const [currentOptions, setCurrentOptions] = useState({})
    const [moreToLoad, setMoreToLoad] = useState(false)
    // const [tab, setTab] = useState('ACH')

    const aionStore = useSelector(state => state.aionAppReducer);
    const bankingStore = useSelector(state => state.bankingAppReducer); // if needed
    const dispatch = useDispatch();
    const history = useHistory()
    const location = useLocation()

    const tab = location.state?.tab ? location.state?.tab : 'ACH'

    // ComponentDidMount equivalent
    useEffect(() => {
        // Your componentDidMount logic here
    }, []); // Empty dependency array means this runs once on mount

    const userRole = getUserRoles(aionStore)
    const isAdmin = userRole.includes("Company Admin") || userRole.includes("Owner") || userRole.includes("Primary Admin") || userRole.includes("Card Admin")
    const view = (props.location.state && props.location.state.view === true)
    const card = (props.location.state && props.location.state.card)
    const viewAll = (props.location.state && props.location.state.all === true)
    const cardsPerm = getResourcePerm("BusinessCards.Cards", { menuPerm: true })
    const filteredAccounts = getBPAccountsWithAccess("Deposits", { permType: "manage", getActiveAccounts: true });
    var instantPaymentsEnabled = getFeaturePerm("BusinessBanking.Payments.Instant Transfer")

    const manage = isAdmin || cardsPerm.manage

    const manageAccounts = getBPAccountsWithAccess("Payments", { permType: "manage", getActiveAccounts: true })

    const { UAM } = aionStore
    const isAionCustomerSupportUser = UAM.aionCustomerSupportUser
    const batchUploadEnabled = getFeaturePerm("BusinessBanking.Payments.ACHBatchUpload").manage

    const fetchTransactions = (options) => {
        var { fromDate, toDate, searchStr, fromAmt, toAmt, railList, viewBy, filterType, filterValue, paymentStatus } = options

        var fetchOptions = {
            pagination: options.pagination, // || pagination
        }

        console.log('fetchTransactions fetchOptions1:', fetchOptions)

        if (searchStr && searchStr != '') fetchOptions.searchStr = searchStr
        if (fromDate) fetchOptions.fromDate = fromDate
        if (toDate) fetchOptions.toDate = toDate
        if (fromAmt) fetchOptions.fromAmt = fromAmt
        if (toAmt) fetchOptions.toAmt = toAmt
        if (railList) fetchOptions.railList = railList
        if (viewBy && searchStr && searchStr != '') fetchOptions.viewBy = viewBy
        if (filterType) fetchOptions.filterType = filterType
        if (filterValue) fetchOptions.filterValue = filterValue
        if (paymentStatus) fetchOptions.paymentStatus = paymentStatus

        fetchOptions.bankProvider = "CrossRiverBank"

        setLoadingPosted(true)

        setCurrentOptions(fetchOptions)
        fetchBBACH(fetchOptions, (err, resp) => {
            try {
                if (err) throw new Error(err)
                console.log("fetchData fetchBBTransactions fetchOptions", fetchOptions)
                console.log("fetchData fetchBBTransactions resp", resp)

                const newData = fetchOptions.pagination.current > 1 ? postedTransfers.concat(resp.Transactions) : resp.Transactions

                setMoreToLoad(newData.length !== resp.achTransfersCount)
                setPostedTransfers(newData)

                if (!searchStr) {
                    props.dispatch(addDataToStore(BNK_SAVE_DATA, resp || {}))
                }
            } catch (error) {
                ErrorAlert({ description: error.message })
            } finally {
                setLoadingPosted(false)
            }
        })
    }

    const ctaContent = () => {
        switch (tab) {
            case 'ACH':
                return (isAionCustomerSupportUser || batchUploadEnabled) ?
                    <Dropdown
                        trigger={['click']}
                        placement="bottomRight"
                        overlay={<Menu style={{ padding: 24, borderRadius: 8 }}>
                            <FlexColumn gap='18px'>
                                <TextButton rightIcon={<Image src={ArrowRight} />} onClick={() => {
                                    setAchDropdownVisible(false)
                                    props.history.push({
                                        pathname: '/banking/ach-transfers/transfer-to-recipient',
                                        state: { rail: 'ACH' }
                                    })
                                }} text={"ACH Transfer".toLocaleUpperCase()} />
                                <TextButton rightIcon={<Image src={ArrowRight} />} onClick={() => {
                                    setAchDropdownVisible(false)
                                    setShowBatchUploadModal(true)
                                }} text={"Batch Upload".toLocaleUpperCase()} />
                            </FlexColumn>
                        </Menu>}
                        onVisibleChange={flag => setAchDropdownVisible(flag)}
                        visible={achDropdownVisible}
                    >
                        <Button permtype={manageAccounts.length > 0 ? "Override" : "BusinessBanking.Payments"} solid text='TRANSFER' dropdown />
                    </Dropdown>
                    :
                    <Button permtype={manageAccounts.length > 0 ? "Override" : "BusinessBanking.Payments"} solid text='TRANSFER' onClick={() => history.push({
                        pathname: '/banking/ach-transfers/transfer-to-recipient',
                        state: { rail: 'ACH' }
                    })} />
            case 'Wire':
                return (isAionCustomerSupportUser || batchUploadEnabled) ?
                    <Dropdown
                        trigger={['click']}
                        placement="bottomRight"
                        overlay={<Menu style={{ padding: 24, borderRadius: 8 }}>
                            <FlexColumn gap='18px'>
                                <TextButton rightIcon={<Image src={ArrowRight} />} onClick={() => {
                                    setWireDropdownVisible(false)
                                    props.history.push({
                                        pathname: '/banking/ach-transfers/transfer-to-recipient',
                                        state: { rail: 'Wire' }
                                    })
                                }} text={"Wire Transfer".toLocaleUpperCase()} />
                                <TextButton rightIcon={<Image src={ArrowRight} />} onClick={() => {
                                    setWireDropdownVisible(false)
                                    setWireShowBatchUploadModal(true)
                                }} text={"Batch Upload".toLocaleUpperCase()} />
                            </FlexColumn>
                        </Menu>}
                        onVisibleChange={flag => setWireDropdownVisible(flag)}
                        visible={wireDropdownVisible}
                    >
                        <Button permtype={manageAccounts.length > 0 ? "Override" : "BusinessBanking.Payments"} solid text='TRANSFER' dropdown />
                    </Dropdown>
                    :
                    <Button permtype={manageAccounts.length > 0 ? "Override" : "BusinessBanking.Payments"} solid text='TRANSFER' onClick={() => history.push({
                        pathname: '/banking/wire-transfers/transfer-to-recipient',
                        state: { rail: 'Wire' }
                    })} />
            case 'Internal':
                return <Button permtype={manageAccounts.length > 0 ? "Override" : "BusinessBanking.Payments"} solid text='TRANSFER' onClick={() => history.push({
                    pathname: '/banking/internal-transfers/internal-transfer',
                    state: { rail: 'Internal' }
                })} />
            case 'Instant':
                return <Button permtype={manageAccounts.length > 0 ? "Override" : "BusinessBanking.Payments"} solid text='TRANSFER' onClick={() => history.push({
                    pathname: '/banking/instant-transfers/transfer-to-recipient',
                    state: { rail: 'Instant' }
                })} />
            default:
                return null
        }
    }

    return (
        <FlexColumn className='main-padding' style={{ width: '100%', margin: 0 }} start grow>
            <PageHeader
                titleText={'Banking'}
                back={view}
                onBack={() => props.history.replace({
                    pathname: '/cards/all',
                    state: { all: true }
                })}
                subtitle='Banking'
                marginRight='0'
                ctaContent={ctaContent()}
            />

            <Tabs size="large" activeKey={tab} onChange={(key) => history.replace({ pathname: '/banking/transfers', state: { tab: key } })} defaultActiveKey={instantPaymentsEnabled.manage ? 'Instant' : 'ACH'} style={{ margin: '0 24px 0px 0px' }}>
                {
                    instantPaymentsEnabled.manage &&
                    <TabPane tab="Instant" key="Instant">
                        <InstantActivity ctaContent={ctaContent()} />
                    </TabPane>
                }
                <TabPane tab="ACH" key="ACH">
                    <ACHActivity ctaContent={ctaContent()} />
                </TabPane>
                <TabPane tab="Wire" key="Wire">
                    <WireActivity ctaContent={ctaContent()} />
                </TabPane>
                <TabPane tab="Check" key="Check">
                    <CheckActivity ctaContent={ctaContent()} />
                </TabPane>
                {
                    (filteredAccounts.length > 1) &&
                    <TabPane tab="Internal" key="Internal">
                        <InternalActivity ctaContent={ctaContent()} />
                    </TabPane>
                }
            </Tabs>

            {/* <Modal
                visible={showBatchUploadModal}
                footer={null}
                closable={true}
                maskClosable={true}
                width={700}
                destroyOnClose={true}
                onCancel={() => setShowBatchUploadModal(false)}
                closeIcon={<img width='24px' height='24px' src={ModalClose} />}
            >
                <ACHBatchUpload
                    onCancel={() => setShowBatchUploadModal(false)}
                    submitComplete={() => {
                        setShowBatchUploadModal(false)
                        setLoadingPosted(true)
                        setTimeout(() => fetchTransactions({ pagination: { pageSize: pageSize, current: 1 } }), 1000)
                    }}
                />
            </Modal> */}

            <UploadBatchModal
                rail='ACH'
                visible={showBatchUploadModal}
                onCancel={() => setShowBatchUploadModal(false)}
                submitComplete={() => {
                    setShowBatchUploadModal(false)
                    setLoadingPosted(true)
                    setTimeout(() => fetchTransactions({ pagination: { pageSize: 10, current: 1 } }), 1000)
                }}
            />
        </FlexColumn>
    );
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer,
        bankingStore: state.bankingAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)))