export const flowViewsData = () => {
    return [
        {
            "ViewType": "WithAnimation",
            "OnboardingStep": "Intro.Welcome",
            "Title": "Open your Aion checking account",
            "Description": "Fast, friendly and delightful banking.",
            "Progress": 0.1,
            "AnimationName": "generic-bank-card",
            "ButtonTitle": "Start",
            "DefaultUserInput": "NOTREQUIRED",
            "NoInput": true,
            "Step": "Applicant info",
            "AnimationWidth": 300
        },
        /*{
            "ViewType": "WithImage",
            "ImageName": "signup-illustration",
            "OnboardingStep": "Intro.Subscribe",
            "Title": "Unlock the full potential for your business",
            "Description": "All-in-one platform that saves you time and money",
            "Progress": 0.15,
            "ButtonTitle": "Accept",
            "DefaultUserInput": "NOTREQUIRED",
            "NoInput": true,
            "Step": "Applicant info",
            "AnimationWidth": 280
        },
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "Intro.ProductSelection",
            "Title": "What does your business need?",
            "Description": "Select all products your business needs. Loans are subject to approval.",
            "Progress": 0.2,
            "UserInputType": "MultiSwitch",
            "ContentItems": [
                "Business banking",
                "Low interest loans",
                //                "Business banking (waitlist)",
            ],
            "DefaultUserInput": [
                "Business banking",
                "Low interest loans",

            ],
            "ValidationErrorMessage": "Please select at least one product to continue",
            "ButtonTitle": "Continue",
            "Step": "Applicant info"
        },
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "PersonalInfo.MinimumRequirements",
            "Title": "Minimum requirements for loans.",
            "Description": "We evaluate your business revenue and performance data. Please check if you meet our minimum requirements.",
            "ContentItems": [
                "Revenue is more than $250K",
                "Credit score is at least 675",
                "Operating for at least a year",
                "Business accounts are older than a year",
            ],
            "Progress": 0.15,
            "ButtonTitle": "Agree",
            "Step": "Applicant info",
            "DefaultUserInput": "NOTREQUIRED",
        },
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "PersonalInfo.DriverLicenseFront",
            "Title": "Have your driver license in hand.",
            "Description": "We'll scan the front of your license. Camera permissions will be requested next.",
            "Progress": 0.2,
            "AnimationName": "driver-license",
            "ButtonTitle": "Continue",
            "Step": "Applicant info",
            "ValidationErrorMessage": "We need a picture of your driver license to continue.",
        },
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "PersonalInfo.DriverLicense",
            "Title": "Now scan the barcode on your license.",
            "Description": "We'll scan the barcode on the back of your driver license.",
            "Progress": 0.3,
            "AnimationName": "license-scanner",
            "ButtonTitle": "Continue",
            "Step": "Applicant info"
        }*/
        {
            "ViewType": "WithoutImage",
            "ImageName": "signup-illustration",
            "OnboardingStep": "PersonalInfo.PatriotAct",
            "Title": "USA PATRIOT Act Disclosure",
            "ChildDescription": "To help the government fight the funding of terrorism and money laundering activities, federal law requires all financial institutions to obtain, verify, and record information that identifies each person who opens an account.\n\nWe will ask for your name, address, date of birth (for individuals), and other information that will allow us to identify you. We may also ask to see your driver’s license (for individuals) or other identifying documents.\n\nBy starting this application, you acknowledge the above information.",
            "Progress": 0.95,
            "ImageName": "AvataaarsSmile",
            "ButtonTitle": "Continue",
            "Step": "Applicant info",
        },
        {
            "ViewType": "WithAnimation",
            "OnboardingStep": "PersonalInfo.AuthorizedSigner",
            "FieldName": "authorizedSigner",
            "Title": "Company Administrator",
            "Description": "You represent that you have the authority to open bank accounts and conduct business on behalf of the Entity.",
            "Progress": 0.95,
            "AnimationName": "sign",
            "UserInputType": "MultiSwitch",
            "ContentItems": [
                "I am an Authorized Signer for the Business",
            ],
            "ValidationErrorMessage": "You have to be an Authorized Signer to continue",
            "ImageName": "AvataaarsSmile",
            "ButtonTitle": "Acknowledge and Continue",
            "Step": "Applicant info",
        },
        {
            "ViewType": "WithAnimation",
            "OnboardingStep": "PersonalInfo.Citizenship",
            "Title": "What is your country of citizenship?",
            "Description": "We need this to gather relevant information in further steps.",
            "Progress": 0.75,
            "AnimationName": "globe",
            "ButtonTitle": "Continue",
            "ContentItems": [
                "Yes",
                "No"
            ],
            "DefaultUserInput": "Yes",
            "Step": "Applicant info",
            "ValidationErrorMessage": "At this time, we only support applicants that reside in the US."
        },
        {
            "ViewType": "WithAnimation",
            "OnboardingStep": "PersonalInfo.Resident",
            "Title": "Do you reside in the US?",
            "Description": "We need this information to obtain appropriate identification documents.",
            "Progress": 0.75,
            "AnimationName": "globe",
            "ButtonTitle": "Continue",
            "ContentItems": [
                "Yes",
                "No"
            ],
            "DefaultUserInput": "Yes",
            "Step": "Applicant info",
            "ValidationErrorMessage": "At this time, we only support applicants that reside in the US."
        },
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "PersonalInfo.SSN",
            "Title": "Social security number",
            "Description": "We need this to confirm your identity.",
            "Progress": 0.8,
            "ValidationErrorMessage": "Invalid SSN. Please enter a 9 digit SSN.",
            "ButtonTitle": "Continue",
            "Step": "Applicant info"
        },
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "PersonalInfo.IDV",
            // "Title": "Scan your license",
            "Description": "",
            "Progress": 0.7,
            "ImageName": "AvataaarsHello",
            "ButtonTitle": "Continue",
            "Step": "Applicant info"
        },
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "PersonalInfo.Edit",
            "Title": "Hi there, fill your details below.",
            "Description": "Details should be as it appears on a legal document.",
            "Progress": 0.7,
            "ImageName": "AvataaarsHello",
            "ButtonTitle": "Continue",
            "Step": "Applicant info"
        },
        {
            "ViewType": "WithAnimation",
            "OnboardingStep": "PersonalInfo.BusinessTitle",
            "Title": ", what’s your title?",
            "Description": "This will help us to understand your role.",
            "Progress": 0.85,
            "AnimationName": "name-card",
            "ButtonTitle": "Continue",
            "ContentItems": [
                // "Owner",
                "CEO",
                "CFO",
                "COO",
                "Other"
            ],
            "OtherInput": true,
            "DefaultUserInput": "CEO",
            "Step": "Applicant info"
        },
        // {
        //     "ViewType": "WithAnimation",
        //     "OnboardingStep": "PersonalInfo.BusinessRole",
        //     "Title": "What best describes your role?",
        //     "Description": "Select one role.",
        //     "Progress": 0.9,
        //     "AnimationName": "name-card",
        //     "ButtonTitle": "Continue",
        //     "ContentItems": [
        //         "Founder",
        //         "Officer",
        //         "Investor",
        //         "Partner"
        //     ],
        //     "DefaultUserInput": "Founder",
        //     "Step": "Applicant info"
        // },
        // {
        //     "ViewType": "WithoutImage",
        //     "OnboardingStep": "PersonalInfo.Id",
        //     "Title": "Government issued identification",
        //     "Description": "We need this information to validate your identity.",
        //     "Progress": 0.9,
        //     "AnimationName": "driver-license-scan",
        //     "ButtonTitle": "Continue",
        //     "ContentItems": [
        //         "Passport",
        //         "Drivers License",
        //         // "Social Security Card",
        //     ],
        //     "Step": "Applicant info",
        //     "ValidationErrorMessage": "Please upload your Government Issued Id",
        // },
        // {
        //     "ViewType": "WithAnimation",
        //     "OnboardingStep": "PersonalInfo.DriversLicenseFront",
        //     "Title": "Upload the front of your Driver's License",
        //     "Description": "We need this information to validate your identity.",
        //     "Progress": 0.9,
        //     "AnimationName": "driver-license-scan",
        //     "ButtonTitle": "Continue",
        //     "Step": "Applicant info",
        //     "ValidationErrorMessage": "Please upload the front of your Driver's License",
        // },
        // {
        //     "ViewType": "WithAnimation",
        //     "OnboardingStep": "PersonalInfo.DriversLicenseBack",
        //     "Title": "Upload the back of your Driver's License",
        //     "Description": "We need this information to validate your identity.",
        //     "Progress": 0.9,
        //     "AnimationName": "driver-license-scan",
        //     "ButtonTitle": "Continue",
        //     "Step": "Applicant info",
        //     "ValidationErrorMessage": "Please upload the back of your Driver's License",
        // },
        {
            "ViewType": "WithAnimation",
            "OnboardingStep": "PersonalInfo.Complete",
            "Title": ", well done! Step 1 complete 🎉",
            "Description": "For the next steps, have your EIN / Tax ID document and details about beneficial owners, controlling party and authorized signers.",
            "Progress": 1.0,
            "AnimationName": "clap",
            "ButtonTitle": "Continue",
            "DefaultUserInput": "NOTREQUIRED",
            "Step": "Applicant info",
            "AnimationWidth": 300
        },
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "BusinessInfo.IncorpType",
            "Title": "What is your business type?",
            "Description": "Make a selection from the dropdown.",
            "Progress": 0.05,
            "ButtonTitle": "Continue",
            "Step": "Business info"
        },
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "BusinessInfo.OC",
            "Title": "Find your business using the search box",
            "Description": "You'll be searching through a public database of US businesses.",
            "Progress": 0.15,
            "ImageName": "AvataaarsSunglasses",
            "ButtonTitle": "Continue",
            "Step": "Business info"
        },
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "BusinessInfo.Edit",
            "Title": "🙌",
            "Description": ", verify the information for ",
            "Progress": 0.5,
            "ImageName": "AvataaarsSmile",
            "ButtonTitle": "Continue",
            "Step": "Business info"
        },
        {
            "ViewType": "WithAnimation",
            "OnboardingStep": "BusinessInfo.MailingAddress",
            "Title": "Mailing address for ",
            "Description": "Where do you get your business mail.",
            "Progress": 0.63,
            "AnimationName": "post-box",
            "ButtonTitle": "Continue",
            "Step": "Business info",
            "ValidationErrorMessage": "Provide a mailing address"
        },
        {
            "ViewType": "WithAnimation",
            "OnboardingStep": "BusinessInfo.Industry",
            "Title": "What kind of business is ",
            "Description": "Select the industry your business operates in.",
            "Progress": 0.7,
            "AnimationName": "name-card",
            "ButtonTitle": "Continue",
            "Step": "Business info",
            "ValidationErrorMessage": "Please drill-down to select a more specific industry"
        },
        // {
        //     "ViewType": "WithoutImage",
        //     "OnboardingStep": "BusinessInfo.IncorpLetter",
        //     "Title": "Upload company formation document",
        //     "Description": "We need the document you were issued when your company was formed in the US.",
        //     "Progress": 0.9,
        //     "ButtonTitle": "Continue",
        //     "Step": "Business info",
        //     "ValidationErrorMessage": "Please upload your formation document",
        // },
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "BusinessInfo.EIN",
            "Title": "Upload EIN verification letter",
            "Description": "We'll accept a 147c letter or Form CP 575 IRS documents.",
            "Progress": 0.9,
            "ButtonTitle": "Continue",
            "Step": "Business info",
            "ValidationErrorMessage": "Please enter your EIN number",
        },
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "BusinessInfo.IndustryConsent",
            "FieldName": "industryConsent",
            "Title": "Confirmation",
            "Description": "Please confirm that this business is not involved or associated in any of the following activities or practices.\n\n1. The sale/distribution of marijuana, hemp, or CBD\n2. Internet gambling\n3. A money service business\n4. The owner of a private ATM\n5. Securities dealers",
            // \n6. Gov.-owned Lotteries\n7. Gov.-licensed Horse/dog Racing\n8. Betting/track/casino/lotto\n9. Gambling-Horse, Dog Racing, State Lottery\n\nIf your business is involved in any of these activities, we may not be able to onboard you.",
            "Progress": 0.71,
            "UserInputType": "MultiSwitch",
            "ContentItems": [
                "I confirm",
            ],
            "ButtonTitle": "Continue",
            "Step": "Business info",
            "ValidationErrorMessage": "Please confirm to continue"
        },
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "BusinessInfo.Description",
            "Title": "Tell us a little bit more about your business.",
            // "Description": "Enter a brief description.",
            "Progress": 0.75,
            "AnimationName": "edit",
            "ButtonTitle": "Continue",
            "Step": "Business info",
            "ValidationErrorMessage": "Please add a description of at least 60 characters",
        },
        // {
        //     "ViewType": "WithoutImage",
        //     "OnboardingStep": "BusinessInfo.AnnualRevenue",
        //     "Title": " annual revenue?",
        //     "Description": "Enter an amount.",
        //     "Progress": 0.72,
        //     "ButtonTitle": "Continue",
        //     "Step": "Business info",
        //     "ValidationErrorMessage": "Please enter the annual revenue"
        // },
        // {
        //     "ViewType": "WithoutImage",
        //     "OnboardingStep": "BusinessInfo.EmployeeCount",
        //     "Title": "How many employees does ",
        //     "Description": "Enter a number.",
        //     "Progress": 0.73,
        //     "ButtonTitle": "Continue",
        //     "Step": "Business info",
        //     "ValidationErrorMessage": "Please enter the number of employees"
        // },
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "BusinessInfo.AccountActivity",
            "Title": "Tell us about your anticipated account activity.",
            "Description": "Provide a description and an estimate of monthly incoming and outgoing transaction dollar volume.",
            "Progress": 0.75,
            "AnimationName": "edit",
            "ButtonTitle": "Continue",
            "Step": "Business info",
            "ValidationErrorMessage": "Please add a description of at least 60 characters",
        },
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "BusinessInfo.DBA",
            "Title": "Does your business operate under a different name?",
            "Description": "Enter DBA or trade name",
            "Progress": 0.8,
            "AnimationName": "name-card",
            "ButtonTitle": "Continue",
            "Step": "Business info",
            "ContentItems": [
                "We do not use a different name"
            ],
            "ValidationErrorMessage": "Please enter a name",
        },
        {
            "ViewType": "WithAnimation",
            "AnimationName": "browser-click",
            "OnboardingStep": "BusinessInfo.OnlinePresence",
            "Title": "Website address for your business.",
            "Description": "Enter website",
            "Progress": 0.95,
            "ButtonTitle": "Continue",
            "Step": "Business info",
            "ValidationErrorMessage": "Please enter a valid website",
        },
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "BusinessInfo.Phone",
            "Title": " phone number?",
            "Description": "Enter your business phone number.",
            "Progress": 0.97,
            "ButtonTitle": "Continue",
            "Step": "Business info",
            "ValidationErrorMessage": "Please enter a 10 digit phone number",
        },
        {
            "ViewType": "WithAnimation",
            "LoopAnimation": true,
            "OnboardingStep": "BusinessInfo.Status",
            "Title": "Almost there, ",
            "Description": "We need some more information about your beneficial owners and other authorized signers and we're done.",
            "Progress": 0.99,
            "AnimationName": "rocket-takeoff-2",
            "ButtonTitle": "Continue",
            "Step": "Business info",
            "DefaultUserInput": "NOTREQUIRED",
        },
        // {
        //     "ViewType": "WithoutImage",
        //     "OnboardingStep": "BusinessInfo.Ownership",
        //     "Title": ", who owns ",
        //     "Description": "Anyone with at least 25% ownership is considered a owner.",
        //     "Progress": 0.99,
        //     "UserInputType": "MultiSwitch",
        //     "ContentItems": [
        //         "I'm the only owner"
        //     ],
        //     "ButtonTitle": "Continue",
        //     "Step": "Business info",
        //     "DefaultUserInput": false
        // },
        // {
        //     "ViewType": "WithoutImage",
        //     "OnboardingStep": "BusinessInfo.OwnershipPercentage",
        //     "Title": "What percentage of the business do you own?",
        //     "Description": "Enter a percentage.",
        //     "Progress": 0.99,
        //     "ButtonTitle": "Continue",
        //     "Step": "Business info",
        //     "ValidationErrorMessage": "Please enter a valid percentage between 1 and 100"
        // }
    ]
}

export const coOwnerFlowViewsData = () => {
    return [
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "OwnerInfo.OwnershipPercentage",
            "Title": "What percentage of the business do you own?",
            "Description": "Enter a percentage.",
            "Progress": 0.99,
            "ButtonTitle": "Continue",
            "Step": "Business info",
            "ValidationErrorMessage": "Please enter a valid percentage between 0 and 100"
        },
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "OwnerInfo.AllOwners",
            "Title": "Tell us about all individuals who own 25% or more of your business.",
            "Description": "",
            "Progress": 0.99,
            "ImageName": "AvataaarsHello",
            "ButtonTitle": "Continue",
            "Step": "Co-applicant info"
        },
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "OwnerInfo.Edit",
            "Title": "",
            "Description": "Details should be as it appears on a legal document.",
            "Progress": 0.7,
            "ImageName": "AvataaarsHello",
            "ButtonTitle": "Continue",
            "Step": "Co-applicant info"
        },
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "OwnerInfo.Phone",
            "Title": " phone number?",
            "Description": "In case we need to get hold of them.",
            "Progress": 0.99,
            "ButtonTitle": "Continue",
            "Step": "Co-applicant info",
            "ValidationErrorMessage": "Please enter a valid phone number",
        },
        {
            "ViewType": "WithAnimation",
            "OnboardingStep": "OwnerInfo.Citizenship",
            "Title": " country of citizenship?",
            "Description": "We need this to gather relevant information in further steps.",
            "Progress": 0.75,
            "AnimationName": "globe",
            "ButtonTitle": "Continue",
            "ContentItems": [
                "Yes",
                "No"
            ],
            "DefaultUserInput": "Yes",
            "Step": "Applicant info",
            "ValidationErrorMessage": "At this time, we only support beneficial owners that reside in the US."
        },
        {
            "ViewType": "WithAnimation",
            "OnboardingStep": "OwnerInfo.Resident",
            "Title": " reside in the US?",
            "Description": "We need this information to obtain appropriate identification documents.",
            "Progress": 0.75,
            "AnimationName": "globe",
            "ButtonTitle": "Continue",
            "ContentItems": [
                "Yes",
                "No"
            ],
            "DefaultUserInput": "Yes",
            "Step": "Applicant info",
            "ValidationErrorMessage": "At this time, we only support owners that reside in the US."
        },
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "OwnerInfo.Id",
            "Title": "Government issued identification",
            "Description": "We need this information to validate their identity.",
            "Progress": 0.9,
            "AnimationName": "driver-license-scan",
            "ButtonTitle": "Continue",
            "ContentItems": [
                "Passport",
                "Drivers License",
                // "Social Security Card",
            ],
            "Step": "Applicant info",
            "ValidationErrorMessage": "Please upload the id for this owner",
        },
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "OwnerInfo.SSN",
            "Title": "social security number",
            "Description": "We need this to confirm their identity.",
            "Progress": 0.8,
            "ValidationErrorMessage": "Invalid SSN. Please enter a 9 digit SSN.",
            "ButtonTitle": "Continue",
            "Step": "Co-applicant info"
        },
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "OwnerInfo.Email",
            "Title": " email",
            "Description": "We need an email on file for every owner.",
            "Progress": 0.99,
            "ButtonTitle": "Continue",
            "Step": "Co-applicant info",
            "ValidationErrorMessage": "Please enter a valid email address",
        },
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "OwnerInfo.BusinessTitle",
            "Title": "Tell us about ",
            "Description": "What is their job title.",
            "Progress": 0.99,
            "ContentItems": [
                // "Owner",
                "CEO",
                "CFO",
                "COO",
                "Other"
            ],
            "OtherInput": true,
            "DefaultUserInput": "CEO",
            "ButtonTitle": "Continue",
            "Step": "Co-applicant info"
        },
        // {
        //     "ViewType": "WithAnimation",
        //     "OnboardingStep": "OwnerInfo.BusinessRole",
        //     "Title": "What best describes their role?",
        //     "Description": "Select one role.",
        //     "Progress": 0.9,
        //     "AnimationName": "name-card",
        //     "ButtonTitle": "Continue",
        //     "ContentItems": [
        //         "Founder",
        //         "Officer",
        //         "Investor",
        //         "Partner"
        //     ],
        //     "DefaultUserInput": "Founder",
        //     "Step": "Co-applicant info"
        // },
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "OwnerInfo.AddOwner",
            "Title": "Alright, beneficial owner info is complete 🙌",
            "Description": "All individuals who own 25% or more of your business (or is a controlling party) are listed below.",
            "Progress": 0.99,
            "ImageName": "AvataaarsSmile",
            "BackButton": false,
            "ButtonTitle": "Continue",
            "Step": "Co-applicant info",
            "DefaultUserInput": "NOTREQUIRED"
        },
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "OwnerInfo.ControlPerson",
            "Title": "Select the controlling party for your business",
            "Description": "An individual with significant responsibility for managing or directing the entity, including, an executive officer or senior manager (e.g., Chief Executive Officer, Chief Financial Officer, Chief Operating Officer, Managing Member, General Partner, President, Executive Vice President, Treasurer); or Any other individual who regularly performs similar functions.",
            "Progress": 0.95,
            "UserInputType": "MultiSwitch",
            "ContentItems": [
                " is a Control Person for this account",
            ],
            "ValidationErrorMessage": "Please select make a selection",
            "ImageName": "AvataaarsSmile",
            "ButtonTitle": "Continue",
            "DefaultUserInput": false,
            "Step": "Co-applicant info",
        },
    ]
}

export const signersFlowViewsData = () => {
    return [
        {
            "ViewType": "WithAnimation",
            "OnboardingStep": "Signers.AuthorizedSigner",
            "Title": "Are you an authorized signer?",
            // "Description": "We will need to get their information too.",
            "Progress": 0.99,
            "AnimationName": "sign",
            "ButtonTitle": "Continue",
            "BackButton": false,
            "ContentItems": [
                "Yes",
                "No"
            ],
            "DefaultUserInput": "Yes",
            "Step": "Co-applicant info",
        },
        {
            "ViewType": "WithAnimation",
            "OnboardingStep": "Signers.AddSigner",
            "Title": "Are there any additional signers?",
            "Description": "We will need to get their information too.",
            "Progress": 0.99,
            "AnimationName": "sign",
            "ButtonTitle": "Continue",
            // "BackButton": false,
            "ContentItems": [
                "Yes",
                "No"
            ],
            "DefaultUserInput": "Yes",
            "Step": "Co-applicant info",
        },
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "Signers.AllSigners",
            "Title": "Tell us who your authorized signers are.",
            "Description": "",
            "Progress": 0.99,
            "ImageName": "AvataaarsHello",
            "ButtonTitle": "Continue",
            "Step": "Co-applicant info",
            "ContentItems": [
                // "Owner",
                "CEO",
                "CFO",
                "COO",
                "Other"
            ],
            "OtherInput": true,
            "DefaultUserInput": "CEO",
        },
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "Signers.Edit",
            "Title": "",
            "Description": "Details should be as it appears on a legal document.",
            "Progress": 0.7,
            "ImageName": "AvataaarsHello",
            "ButtonTitle": "Continue",
            "Step": "Co-applicant info"
        },
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "Signers.Email",
            "Title": " email",
            "Description": "We need an email on file for every authorized signer.",
            "Progress": 0.99,
            "ButtonTitle": "Continue",
            "Step": "Co-applicant info",
            "ValidationErrorMessage": "Please enter a valid email address",
        },
        {
            "ViewType": "WithAnimation",
            "OnboardingStep": "Signers.Citizenship",
            "Title": " country of citizenship?",
            "Description": "We need this to gather relevant information in further steps.",
            "Progress": 0.75,
            "AnimationName": "globe",
            "ButtonTitle": "Continue",
            "ContentItems": [
                "Yes",
                "No"
            ],
            "DefaultUserInput": "Yes",
            "Step": "Applicant info",
            "ValidationErrorMessage": "At this time, we only support authorized signers that reside in the US."
        },
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "Signers.SSN",
            "Title": "social security number",
            "Description": "We need this to confirm their identity.",
            "Progress": 0.8,
            "ValidationErrorMessage": "Invalid SSN. Please enter a 9 digit SSN.",
            "ButtonTitle": "Continue",
            "Step": "Co-applicant info"
        },
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "Signers.Phone",
            "Title": " phone number?",
            "Description": "In case we need to get hold of them.",
            "Progress": 0.99,
            "ButtonTitle": "Continue",
            "Step": "Co-applicant info",
            "ValidationErrorMessage": "Please enter a valid phone number",
        },
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "Signers.Id",
            "Title": "Government issued identification",
            "Description": "We need this information to validate their identity.",
            "Progress": 0.9,
            "AnimationName": "driver-license-scan",
            "ButtonTitle": "Continue",
            "ContentItems": [
                "Passport",
                "Drivers License",
                // "Social Security Card",
            ],
            "Step": "Applicant info",
            "ValidationErrorMessage": "Please upload the id for this signer",
        },
        // {
        //     "ViewType": "WithAnimation",
        //     "OnboardingStep": "Signers.DriversLicenseFront",
        //     "Title": "Upload the front of your Driver's License",
        //     "Description": "We need this information to validate their identity.",
        //     "Progress": 0.9,
        //     "AnimationName": "driver-license-scan",
        //     "ButtonTitle": "Continue",
        //     "Step": "Applicant info",
        //     "ValidationErrorMessage": "Please upload the front of your Driver's License",
        // },
        // {
        //     "ViewType": "WithAnimation",
        //     "OnboardingStep": "Signers.DriversLicenseBack",
        //     "Title": "Upload the back of your Driver's License",
        //     "Description": "We need this information to validate their identity.",
        //     "Progress": 0.9,
        //     "AnimationName": "driver-license-scan",
        //     "ButtonTitle": "Continue",
        //     "Step": "Applicant info",
        //     "ValidationErrorMessage": "Please upload the back of your Driver's License",
        // },
    ]
}

export const submitSingleOwnerApplicationFlowViewsData = () => {
    return [
        // {
        //     "ViewType": "WithoutImage",
        //     "OnboardingStep": "PersonalInfo.ControlPerson",
        //     "FieldName": "controlPerson",
        //     "Title": "Control Person",
        //     "Description": "An individual with significant responsibility for managing the legal entity noted, such as: An executive officer or senior manager (e.g. Chief Executive Officer, Chief Financial Officer, Chief Operating Officer, Managing Member, General Partner, President, Vice President, Treasurer) or Any individual who regularly performs similar functions.",
        //     "Progress": 0.95,
        //     "UserInputType": "MultiSwitch",
        //     "ContentItems": [
        //         "I am the Control Person for this account",
        //     ],
        //     "ImageName": "AvataaarsSmile",
        //     "ValidationErrorMessage": "You have to be the Control Person to continue",
        //     "ButtonTitle": "Submit",
        //     "BackButton": false,
        //     "DefaultUserInput": false,
        //     "Step": "Applicant info",
        // },
        {
            "ViewType": "WithAnimation",
            "AnimationName": "name-card",
            "OnboardingStep": "SubmitInfo.MultipleAccounts",
            "Title": "Multiple accounts consent",
            "Description": "If needed, we'll open multiple accounts for your business. By continuing, you agree that the information you have entered applies to all accounts.",
            "Progress": 1,
            "ButtonTitle": "Continue",
            "Step": "Confirmation",
            "DefaultUserInput": "NOTREQUIRED"
        },
        {
            "ViewType": "WithAnimation",
            "AnimationName": "sign",
            "OnboardingStep": "SubmitInfo.OwnershipCertification",
            "Title": "Beneficial ownership certification",
            "Subtitle": "The information contained in this Certification is sought pursuant to Section 1020.230 of the Title 31 of the United States Code of Federal Regulations (31 CFR 1020.230).",
            "Description": "I certify to the best of my knowledge, that the information provided regarding the business and beneficial ownership is complete and correct.",
            "Progress": 1,
            "ButtonTitle": "Continue",
            "Step": "Confirmation",
            "DefaultUserInput": "yes"
        },
        {
            //     "ViewType": "WithoutImage",
            //     "OnboardingStep": "SubmitInfo.InitalDeposit",
            //     "Title": "Minimum opening deposit",
            //     "Description": "We require a minimum opening deposit",
            //     "Progress": 0.1,
            //     "AnimationName": "debit-cards-3",
            //     "ButtonTitle": "Continue",
            //     "DefaultUserInput": "Bank transfer",
            //     "NoInput": true,
            //     "ContentItems": ["Bank transfer", "Debit card"],
            //     "Step": "Applicant info",
            //     "AnimationWidth": 300,
            //     "ValidationErrorMessage": "All fields are required."
            // }, {
            "ViewType": "WithoutImage",
            "OnboardingStep": "SubmitInfo.Submit",
            "Title": "That’s it, ",
            "Description": "Your application is ready to submit.\n\nWe’ll need to verify the information you’ve provided by running a background check.\n\nThis is a standard process for opening any bank account and your credit score will not be affected.\n\nIf your business is involved in any of these activities, we may not be able to bank with you.\n\n1. The sale/distribution of marijuana\n2. Internet gambling\n3. A money service business\n4. The owner of a private ATM",
            "Progress": 1.0,
            "ImageName": "AvataaarsSmile",
            "ButtonTitle": "Submit",
            "Step": "Business info",
            "DefaultUserInput": "NOTREQUIRED"
        },
        {
            "ViewType": "WithAnimation",
            "OnboardingStep": "SubmitInfo.Submitted",
            "AnimationName": "application-complete",
            "Title": "Nicely done, ",
            "Description": "Your application was received and is currently under review.\n\nWe'll notify you as soon as we're done processing.",
            "Progress": 1.0,
            "ButtonTitle": "Done",
            "BackButton": false,
            "Step": "Business info",
            "DefaultUserInput": "NOTREQUIRED"
        }
    ]
}

export const submitMultiOwnerApplicationFlowViewsData = () => {
    return [
        {
            "ViewType": "WithAnimation",
            "AnimationName": "name-card",
            "OnboardingStep": "SubmitInfo.MultipleAccounts",
            "Title": "Multiple accounts consent",
            "Description": "If needed, we'll open multiple accounts for your business. By continuing, you agree that the information you have entered applies to all accounts.",
            "Progress": 1,
            "ButtonTitle": "Continue",
            "Step": "Confirmation",
            "DefaultUserInput": "NOTREQUIRED"
        },
        {
            "ViewType": "WithAnimation",
            "AnimationName": "sign",
            "OnboardingStep": "SubmitInfo.OwnershipCertification",
            "Title": "Beneficial ownership certification",
            "Subtitle": "The information contained in this Certification is sought pursuant to Section 1020.230 of the Title 31 of the United States Code of Federal Regulations (31 CFR 1020.230).",
            "Description": "I certify to the best of my knowledge, that the information provided regarding the business and beneficial ownership is complete and correct.",
            "Progress": 1,
            "ButtonTitle": "Continue",
            "Step": "Confirmation",
            "DefaultUserInput": "yes"
        },
        // {
        //     "ViewType": "WithoutImage",
        //     "OnboardingStep": "SubmitInfo.InitalDeposit",
        //     "Title": "Minimum opening deposit",
        //     "Description": "We require a minimum opening deposit",
        //     "Progress": 0.1,
        //     "AnimationName": "debit-cards-3",
        //     "ButtonTitle": "Continue",
        //     "DefaultUserInput": "Bank transfer",
        //     "NoInput": true,
        //     "ContentItems": ["Bank transfer", "Debit card"],
        //     "Step": "Applicant info",
        //     "AnimationWidth": 300,
        //     "ValidationErrorMessage": "All fields are required."
        // },
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "SubmitInfo.Submit",
            "Title": "That’s it, ",
            "Description": "Your application is ready to submit.\n\nWe’ll need to verify the information you’ve provided by running a background check.\n\nThis is a standard process for opening any bank account and your credit score will not be affected.\n\nIf your business is involved in any of these activities, we may not be able to bank you\n\n1. The sale/distribution of marijuana\n2. Internet gambling\n3. A money service business\n4. The owner of a private ATM",
            "Progress": 1.0,
            "ImageName": "AvataaarsSmile",
            "ButtonTitle": "Submit",
            "Step": "Business info",
            "DefaultUserInput": "NOTREQUIRED"
        },
        {
            "ViewType": "WithAnimation",
            "OnboardingStep": "SubmitInfo.Submitted",
            "AnimationName": "application-complete",
            "Title": "Nicely done, ",
            "Description": "Your application was received and is currently under review.\n\nWe'll notify you as soon as we're done processing.",
            "Progress": 1.0,
            "ButtonTitle": "Done",
            "BackButton": false,
            "Step": "Business info",
            "DefaultUserInput": "NOTREQUIRED"
        }
    ]
}

export const pppApplication = () => {
    return [
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "PPP.MonthlyPayroll",
            "Title": "What’s your average monthly payroll?",
            "Description": "Please use your average monthly payroll for 2019, excluding costs over $100,000 on an annualized basis for each employee.",
            "Progress": 0.1,
            "ImageName": "AvataaarsHello",
            "ButtonTitle": "Continue",
            "Step": "PPP info"
        },
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "PPP.Employees",
            "Title": "How many employees did you have as of Feb 15, 2020?",
            "Description": "This number includes all employees on your payroll.",
            "Progress": 0.1,
            "ImageName": "AvataaarsHello",
            "ButtonTitle": "Continue",
            "Step": "PPP info"
        },
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "PPP.LoanRequest",
            "Title": "What's your loan request?",
            "Description": "This should be equal of under (Average monthly payroll x 2.5 + EIDL, net of Advance [if applicable])",
            "Progress": 0.1,
            "ImageName": "AvataaarsHello",
            "ButtonTitle": "Continue",
            "Step": "PPP info"
        },
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "PPP.LoanPurpose",
            "Title": "What’s the purpose of this loan?",
            "Description": "Please select all that apply.",
            "Progress": 0.1,
            "ImageName": "AvataaarsHello",
            "ContentItems": ["Payroll", "Lease / Mortgage Interest", "Utilities", "Other"],
            "ButtonTitle": "Continue",
            "Step": "PPP info"
        },
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "PPP.Questions",
            "Title": "PPP Questions",
            "Description": "Please answer all questions.",
            "Progress": 0.1,
            "ImageName": "AvataaarsHello",
            "ContentItems": [
                {
                    Id: 1,
                    Question: "Is the Applicant or any owner of the Applicant presently suspended, debarred, proposed for debarment, declared ineligible, voluntarily excluded from participation in this transaction by any Federal department or agency, or presently involved in any bankruptcy?"
                },
                {
                    Id: 2,
                    Question: "Has the Applicant, any owner of the Applicant, or any business owned or controlled by any of them, ever obtained a direct or guaranteed loan from SBA or any other Federal agency that is currently delinquent or has defaulted in the last 7 years and caused a loss to the government?"
                },
                {
                    Id: 3,
                    Question: "Is the Applicant or any owner of the Applicant an owner of any other business, or have common management with, any other business? If yes, list all such businesses and describe the relationship below.",
                    YesExplanation: true,
                    Placeholder: "Describe relationship"
                },
                {
                    Id: 4,
                    Question: "Has the Applicant received an SBA Economic Injury Disaster Loan between January 31, 2020 and April 3, 2020? If yes, provide details below",
                    YesExplanation: true,
                    Placeholder: "Describe loan details"
                },
                {
                    Id: 5,
                    Question: "Is the Applicant (if an individual) or any individual owning 20% or more of the equity of the Applicant subject to an indictment, criminal information, arraignment, or other means by which formal criminal charges are brought in any jurisdiction, or presently incarcerated, or on probation or parole?"
                },
                {
                    Id: 6,
                    Question: "Within the last 5 years, for any felony, has the Applicant (if an individual) or any owner of the Applicant 1) been convicted; 2) pleaded guilty; 3) pleaded nolo contendere; 4) been placed on pretrial diversion; or 5) been placed on any form of parole or probation (including probation before judgment)?"
                },
                {
                    Id: 7,
                    Question: "Is the United States the principal place of residence for all employees of the Applicant included in the Applicant’s payroll calculation above?"
                },
                {
                    Id: 8,
                    Question: "Is the Applicant a franchise that is listed in the SBA’s Franchise Directory?"
                }
            ],
            "ButtonTitle": "Continue",
            "Step": "PPP info"
        },
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "PPP.Certification",
            "Title": "CERTIFICATIONS AND AUTHORIZATIONS",
            "Description": "I certify that:",
            "Progress": 0.1,
            "ImageName": "AvataaarsHello",
            "ContentItems": [
                "I have read the statements included in this form, including the Statements Required by Law and Executive Orders, and I understand them.",
                "The Applicant is eligible to receive a loan under the rules in effect at the time this application is submitted that have been issued by the Small Business Administration (SBA) implementing the Paycheck Protection Program under Division A, Title I of the Coronavirus Aid, Relief, and Economic Security Act (CARES Act) (the Paycheck Protection Program Rule).",
                "The Applicant (1) is an independent contractor, eligible self-employed individual, or sole proprietor or (2) employs no more than the greater of 500 or employees or, if applicable, the size standard in number of employees established by the SBA in 13 C.F.R. 121.201 for the Applicant’s industry.",
                "I will comply, whenever applicable, with the civil rights and other limitations in this form.",
                "All SBA loan proceeds will be used only for business-related purposes as specified in the loan application and consistent with the Paycheck Protection Program Rule.",
                "To the extent feasible, I will purchase only American-made equipment and products.",
                "The Applicant is not engaged in any activity that is illegal under federal, state or local law.",
                "Any loan received by the Applicant under Section 7(b)(2) of the Small Business Act between January 31, 2020 and April 3, 2020 was for a purpose other than paying payroll costs and other allowable uses loans under the Paycheck Protection Program Rule."
            ],
            "ValidationErrorMessage": "To submit your application, you need to agree to the certifications and authorizations.",
            "ButtonTitle": "Submit",
            "Step": "PPP info"
        }
    ]
}

export const incorpTypeShortArr = () => {
    return [
        "LLC",
        "C-Corp",
        "S-Corp",
        "Non-Profit",
        "Investment/Hedge/Private Equity Fund",
        "Trust",
        // "Special Purpose Vehicle/Special Purpose Entity", 
        // "Private Investment Company/Private Holding Company", 
        "Partnership",
        "Joint Venture",
    ] // "Sole Proprietorship" disabled for now
}

export const incorpTypeDocumentMap = () => {
    return {
        "LLC": [
            { name: "Filed Certificate of Formation/Articles of Organization, issued by Secretary of State" },
            { name: "Business Certificate/Business License (if professional LLC)" },
            { name: "Certificate of Authority files with state(s) the company is operating in (if incorporated outside of state in which operating)" },
            { name: "Operating Agreement, signed by members" },
        ],
        "C-Corp": [
            { name: "Certificate of Incorporation, issued by Secretary of State and filing receipt" },
            { name: "Trade/Assumed Name Certificate (if existing DBA), must be filed with the Secretary of State" },
            { name: "Certificate of Good Standing" },
        ],
        "S-Corp": [
            { name: "Certificate of Incorporation, issued by Secretary of State and filing receipt" },
            { name: "Trade/Assumed Name Certificate (if existing DBA), must be filed with the Secretary of State" },
            { name: "Certificate of Good Standing" },
        ],
        "Non-Profit": [
            { name: "Filed Certificate of Incorporation/Articles of Incorporation, if incorporated this must be issued be the Secretary of State" },
            { name: "Filed Trade Name Certificate, issued by Secretary of State" },
            { name: "Tax ID Number/501(c)(3) notification" },
        ],
        "Investment/Hedge/Private Equity Fund": [
            { name: "Filed business documents (e.g., LLC, Corporation, Limited Partnership) and respective agreements (e.g. Operating Agreement or Partnership Agreement)" },
            { name: "Offering Documents" },
        ],
        "Trust": [
            { name: "Trust Agreement, this should include the appointed trustee and must contain completed notary section" },
        ],
        // "Special Purpose Vehicle/Special Purpose Entity": [], 
        // "Private Investment Company/Private Holding Company": [], 
        "Partnership": [
            { name: "Filed Business/Trade Name Certificate, this must be issued and certified by the applicable county" },
            { name: "Filed Limited Partnership Certificate, issued by Secretary of State" },
            { name: "Certified Copy of Partnership Agreement" },
        ],
        "Joint Venture": [
            { name: "Filed business documents (e.g., LLC, Corporation, Limited Partnership) and respective agreements (e.g. Operating Agreement or Partnership Agreement)" },
            { name: "Assumed Name Certificate, which must include filing stamp/receipt" },
            { name: "Joint Venture Agreement" },
        ],
    }
}

export const naicsIndustries = () => {
    return [
        "Agriculture, Forestry, Fishing and Hunting",
        "Mining, Quarrying, and Oil and Gas Extraction",
        "Utilities",
        "Construction",
        "Manufacturing",
        "Wholesale Trade",
        "Retail Trade",
        "Transportation and Warehousing",
        "Information",
        "Finance and Insurance",
        "Real Estate and Rental and Leasing",
        "Professional, Scientific, and Technical Services",
        "Management of Companies and Enterprises",
        "Administrative and Support and Waste Management and Remediation Services",
        "Educational Services",
        "Health Care and Social Assistance",
        "Arts, Entertainment, and Recreation",
        "Accommodation and Food Services",
        "Other Services (except Public Administration)",
        "Public Administration"
    ]
}

export const naicsIndustriesMap = () => {
    return {
        "Agriculture, Forestry, Fishing and Hunting": "11",
        "Mining, Quarrying, and Oil and Gas Extraction": "21",
        "Utilities": "22",
        "Construction": "23",
        "Manufacturing": "31-33",
        "Wholesale Trade": "41",
        "Retail Trade": "44-45",
        "Transportation and Warehousing": "48-49",
        "Information": "51",
        "Finance and Insurance": "52",
        "Real Estate and Rental and Leasing": "53",
        "Professional, Scientific, and Technical Services": "54",
        "Management of Companies and Enterprises": "55",
        "Administrative and Support and Waste Management and Remediation Services": "56",
        "Educational Services": "61",
        "Health Care and Social Assistance": "62",
        "Arts, Entertainment, and Recreation": "71",
        "Accommodation and Food Services": "72",
        "Other Services (except Public Administration)": "81",
        "Public Administration": "91"
    }
}

export const invoiceFinancingApplication = () => {
    return [
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "InvoiceFinancing.DebtSchedule",
            "Title": "Debt Schedule",
            "Description": 'Tell us about your outstanding debt with other lenders. Any debt that you currently have needs to be disclosed. Start by clicking "Add Debt".',
            "Progress": 0.1,
            "ImageName": "AvataaarsHello",
            "ButtonTitle": "Continue",
            "DefaultUserInput": "NOTREQUIRED",
            "BackButton": false,
            "Step": "Invoice financing info"
        },
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "InvoiceFinancing.TaxReturns",
            "Title": "Tax returns",
            "Description": "Please provide your business tax returns for the last 3 years",
            "Progress": 0.1,
            "ImageName": "AvataaarsHello",
            "ButtonTitle": "Continue",
            "Step": "Invoice financing info"
        },
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "InvoiceFinancing.ProfitAndLoss",
            "Title": "PNL/Income Statement",
            "Description": "Please upload profit and loss/income statements for the last 2 years",
            "Progress": 0.1,
            "ImageName": "AvataaarsHello",
            "ButtonTitle": "Continue",
            "Step": "Invoice financing info"
        },
        // {
        //     "ViewType": "WithoutImage",
        //     "OnboardingStep": "InvoiceFinancing.BankStatements",
        //     "Title": "Bank Statements for the last 3 months",
        //     "Description": "We need these statements so that we can setup the appropriate limits for your bank account with us.",
        //     "Progress": 0.1,
        //     "ImageName": "AvataaarsHello",
        //     "ButtonTitle": "Continue",
        //     "Step": "Invoice financing info"
        // },
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "InvoiceFinancing.PFStatement",
            "Title": "Personal financial statement",
            "Description": "Please download the attached template, fill it up and then upload the completed document.",
            "Progress": 0.1,
            "ImageName": "AvataaarsHello",
            "ButtonTitle": "Continue",
            "Step": "Invoice financing info"
        },
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "InvoiceFinancing.OtherDocuments",
            "Title": "Other Financial Documents",
            "Description": "Please upload the documents below. We need this information for assessing your business",
            "Progress": 0.1,
            "ImageName": "AvataaarsHello",
            "ButtonTitle": "Continue",
            "Step": "Invoice financing info"
        }
        // {
        //     "ViewType": "WithoutImage",
        //     "OnboardingStep": "InvoiceFinancing.Confirmation",
        //     "Title": "Submit Application",
        //     "Description": "",
        //     "Progress": 0.1,
        //     "ImageName": "AvataaarsHello",
        //     "ButtonTitle": "Submit",
        //     "Step": "Invoice financing info"
        // }
    ]
}

export const coOwnerFlowViewsDataOld = () => {
    return [
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "BusinessInfo.OwnerChecklist",
            "Title": ", have information about your owners handy",
            "Description": "You can add up to 4 signers. We need a total of 76% ownership to submit the application.",
            "ContentItems": [
                "Full legal name",
                "Date of birth",
                "Address",
                "Percentage ownership",
                "Job role and Title",
                "Email address",
                "Phone number",
                "Social security number"
            ],
            "Progress": 0.99,
            "ButtonTitle": "Continue",
            "BackButton": false,
            "Step": "Business info",
            "DefaultUserInput": "NOTREQUIRED",
        },
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "OwnerInfo.Name",
            "Title": ", what’s the name of your business partner?",
            "Description": "Enter their full legal name.",
            "Progress": 0.99,
            "ImageName": "AvataaarsHello",
            "ButtonTitle": "Continue",
            "BackButton": false,
            "Step": "Co-applicant info"
        },
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "OwnerInfo.Edit",
            "Title": "",
            "Description": "Details should be as it appears on a legal document.",
            "Progress": 0.7,
            "ImageName": "AvataaarsHello",
            "ButtonTitle": "Continue",
            "Step": "Co-applicant info"
        },
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "OwnerInfo.BusinessTitle",
            "Title": "Tell us about ",
            "Description": "What is their job title.",
            "Progress": 0.99,
            "ContentItems": [
                // "Owner",
                "CEO",
                "CFO",
                "COO",
                "Other"
            ],
            "OtherInput": true,
            "DefaultUserInput": "CEO",
            "ButtonTitle": "Continue",
            "Step": "Co-applicant info"
        },
        {
            "ViewType": "WithAnimation",
            "OnboardingStep": "OwnerInfo.BusinessRole",
            "Title": "What best describes their role?",
            "Description": "Select one role.",
            "Progress": 0.9,
            "AnimationName": "name-card",
            "ButtonTitle": "Continue",
            "ContentItems": [
                "Founder",
                "Officer",
                "Investor",
                "Partner"
            ],
            "DefaultUserInput": "Founder",
            "Step": "Co-applicant info"
        },
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "OwnerInfo.Email",
            "Title": " email",
            "Description": "We need an email on file for every owner.",
            "Progress": 0.99,
            "ButtonTitle": "Continue",
            "Step": "Co-applicant info",
            "ValidationErrorMessage": "Please enter a valid email address",
        },
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "OwnerInfo.Phone",
            "Title": " phone number?",
            "Description": "In case we need to get hold of them.",
            "Progress": 0.99,
            "ButtonTitle": "Continue",
            "Step": "Co-applicant info",
            "ValidationErrorMessage": "Please enter a valid phone number",
        },
        {
            "ViewType": "WithAnimation",
            "OnboardingStep": "OwnerInfo.Citizenship",
            "Title": " country of citizenship?",
            "Description": "We need this to gather relevant information in further steps.",
            "Progress": 0.99,
            "AnimationName": "globe",
            "ButtonTitle": "Continue",
            "ContentItems": [
                "Yes",
                "No"
            ],
            "DefaultUserInput": "Yes",
            "Step": "Co-applicant info",
            "ValidationErrorMessage": "At this time, we only support beneficial owners that reside in the US."
        },
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "OwnerInfo.SSN",
            "Title": "social security number",
            "Description": "We need this to confirm their identity.",
            "Progress": 0.8,
            "ValidationErrorMessage": "Invalid SSN. Please enter a 9 digit SSN.",
            "ButtonTitle": "Continue",
            "Step": "Co-applicant info"
        },
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "OwnerInfo.OwnershipPercentage",
            "Title": "What percentage of the business does ",
            "Description": "Enter a percentage.",
            "Progress": 0.99,
            "ButtonTitle": "Continue",
            "Step": "Co-applicant info",
        },
        {
            "ViewType": "WithoutImage",
            "OnboardingStep": "SubmitInfo.AddOwner",
            "Title": " info is complete 🙌",
            "Description": "Add any other owner that is missing.",
            "Progress": 0.99,
            "ImageName": "AvataaarsSmile",
            "ButtonTitle": "Continue",
            "BackButton": false,
            "Step": "Co-applicant info",
            "DefaultUserInput": "NOTREQUIRED"
        }
    ]
}