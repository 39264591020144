import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

// Components
import AdvancesTable from './AdvancesTable'
import LimitsBalances from '../LimitsBalances'

import PageHeader from "../../Reusable/PageHeader"
import { Flex, FlexColumn, Container } from '../../Reusable/Container'
import { Button } from '../../Reusable/Button'
import styled from 'styled-components'
import { WarningFilled } from '@ant-design/icons'
import Banner from '../../Reusable/Banner'
import Clock from '../../../Images/clock.png';
import BoldBanner from '../../Reusable/BoldBanner'
import { Image } from '../../Reusable/Image'
// Util
import { apiGET, apiPOSTReq } from '../../../Utils/api'
import environment from '../../../environment'

// Actions
import { CREDIT_SAVE_DATA, addDataToStore } from '../../../Actions/actions'
import moment from 'moment'

export const DescItem = styled.div`
    display: flex;
    justify-content: flex-end;
`

export const DescLabel = styled.span`
    fontWeight: 500;
    fontSize: 16;
`

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
})

class AdvancesIndex extends Component {

    constructor(props) {
        super(props)
        this.state = {
            advancesLoaded: false,
            lineFreeze: false,
            ovarAdvnaced: false,
            ifFeesAreToBeDrawn: false, showBanner: false,
            pagination: {
                current: 1,
                pageSize: 100,
            },
        }
    }

    componentDidMount() {
        this.fetchBusinessData()
        // this.fetchAdvances()
        // this.fetchARData()
        this.fetchARAdvanceData()
        this.fetchData({ fromDate: moment().startOf('year').format('YYYY-MM-DD'), toDate: moment().format('YYYY-MM-DD') })
    }

    fetchBusinessData = () => {
        var body = {
            "clientBusinessId": this.props.aionStore.BusinessUniqueKey
        }

        console.log("/business/admin/getBusinessData body", body)
        this.setState({ loading: true })
        apiPOSTReq(`${environment.uamBaseUrl}/business/admin/getBusinessData`, null, body, (err, resp) => {
            this.setState({ loading: false })
            try {
                const data = resp || {}
                if (data.result) {
                    this.setState({
                        business: data.business
                    })

                    this.fetchBusinessAttributes(this.props.aionStore.BusinessUniqueKey)
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp)
                // ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    fetchBusinessAttributes = (businessId) => {
        var body = {
            "clientBusinessId": businessId
        }

        this.setState({ attributesLoading: true })
        apiPOSTReq(`${environment.opsBaseUrl}/ops/fetchBusinessAttributes`, null, body, (err, resp) => {
            this.setState({ attributesLoading: false })
            try {
                const data = resp || {}
                if (data.businessAttributes) {
                    var businessAttributes = data.businessAttributes
                    var freezeCreditLine = businessAttributes.filter(
                        item => {
                            return (item.name == "FreezeCreditLine" && moment(item.effectiveEndDate).toDate() >= moment().toDate())
                        }
                    )

                    if (freezeCreditLine.length > 0) {
                        var { lineFreeze } = this.state
                        lineFreeze = freezeCreditLine[0].value == "true" ? true : false
                        this.setState({
                            lineFreeze: lineFreeze
                        })
                    }
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                // ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    fetchAdvances() {
        const headers = {
            businesskey: this.props.aionStore.BusinessUniqueKey
        }

        apiGET("/financing/advances/view", headers, (err, resp) => {
            try {
                const data = resp.data
                console.log("/financing/advances/view", JSON.stringify(data))
                this.props.dispatch(addDataToStore(CREDIT_SAVE_DATA, data))
                var initiatedLoans = data.ARAdvances.filter(x => x.LoanStatus == "Initiated");
                this.setState({ advancesLoaded: true, showBanner: (initiatedLoans || []).length > 0 })
            } catch (error) {
                console.log("ERRR", error.stack)
            }
        })
    }

    fetchARAdvanceData = () => {
        const headers = {
            businesskey: this.props.aionStore.BusinessUniqueKey
        }

        apiGET("/arfinancing/funds/view", headers, (err, resp) => {
            try {
                const data = resp.data
                console.log("/arfinancing/funds/view", JSON.stringify(data))
                var ovarAdvnaced = data.OverAdvanced
                var ifFeesAreToBeDrawn = data.DrawFeesToBorrower;
                this.setState({ fundsLoaded: true, AvailableFunds: data, ovarAdvnaced, ifFeesAreToBeDrawn: ifFeesAreToBeDrawn })
                this.fetchAdvances();
            } catch (error) {
                console.log("ERRR", error.stack)
            }
        })
    }

    fetchData(options) {
        let { fromDate, toDate, pagination } = options;

        let fetchOptions = {
            sortFieldDirection: 'DESC',
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            size: this.state.pagination.pageSize,
            page: this.state.pagination.current - 1,
            download: false,
        }
        if (pagination) {
            fetchOptions.size = pagination.pageSize;
            fetchOptions.page = pagination.current - 1;
        }

        if (fromDate) {
            fetchOptions.fromDate = fromDate;
            fetchOptions.toDate = toDate;
        }
        this.setState({ loadingReport: true })

        if (!pagination) {
            pagination = this.state.pagination;
        }
        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/getDailyReport`, {}, fetchOptions, (err, resp) => {
            try {
                const data = resp || {};
                if (data) {
                    this.setState({
                        ...options,
                        loadingReport: false,
                        dailyReportItemList: data.dailyReportItemList,
                        pagination: {
                            ...pagination,
                            total: data.count,
                        }
                    })
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/counterparty err", error, resp);
                // ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    render() {
        var { fundsLoaded, AvailableFunds, lineFreeze, ovarAdvnaced, showBanner, dailyReportItemList } = this.state
        const { UAM } = this.props.aionStore;
        var amount = ''
        if (AvailableFunds && AvailableFunds.OverAdvanced === true) {
            amount = AvailableFunds.OverAdvancedAmount.toString()
            amount = amount.replace('$', '')
            amount = formatter.format(amount)
        }
        var dailyReport = null;
        dailyReportItemList = dailyReportItemList || [];
        var outstandingInterest = null;
        if (dailyReportItemList.length > 0) {
            dailyReport = dailyReportItemList[0];
            outstandingInterest = dailyReport.outstandingInterestToDate;
        }

        return (
            <FlexColumn className='main-padding'>
                <PageHeader
                    titleText="Credit"
                    subtitle="Revolving Line of Credit"
                    ctaContent={
                        <div><Button solid disabled={lineFreeze || ovarAdvnaced || UAM.aionCustomerSupportUser || UAM.aionUnderWriterUser} loading={!fundsLoaded} permtype="ARCredit.Advances" onClick={() => this.props.history.push('/credit/ar-credit/advances/request')} text='REQUEST FUNDS' /></div>
                    }
                />
                {
                    showBanner && <BoldBanner
                        icon={<Image src={Clock} />}
                        style={{ marginTop: "0", marginBottom: 24, width: "100%" }}
                        message="Funds will be available in 1 to 2 business days after the funding request is processed. Requests submitted after 6pm ET are processed the next business day."
                        onClose={() => {
                            this.setState({ showBanner: false })
                        }}
                    />
                }
                {
                    AvailableFunds && AvailableFunds.OverAdvanced === true &&
                    <Banner
                        style={{ marginBottom: 10 }}
                        title={`Overadvanced Amount: ${amount}`}
                        message={`Please note that you are over-advanced on your line of credit. The line may be reactivated once you have provided additional collateral or payments on the outstanding balance are received.`}
                    />
                }
                {/* Limits & Balances */}
                <Flex style={{ alignItems: "start" }}>
                    <FlexColumn style={{ width: "100%" }}>
                        {this.props.store.ARAdvanceInfo ? <LimitsBalances advancesLoaded={fundsLoaded} data={this.props.store.ARAdvanceInfo} availableFunds={AvailableFunds || {}} dailyReport={dailyReport} type="Advances" /> : null}
                    </FlexColumn>
                </Flex>
                <AdvancesTable
                    ifFeesAreToBeDrawn={this.state.ifFeesAreToBeDrawn}
                    advancesLoaded={this.state.advancesLoaded}
                    data={this.props.store.ARAdvances}
                    ctaContent={
                        <div><Button solid disabled={lineFreeze || ovarAdvnaced || UAM.aionCustomerSupportUser || UAM.aionUnderWriterUser} loading={!fundsLoaded} permtype="ARCredit.Advances" onClick={() => this.props.history.push('/credit/ar-credit/advances/request')} text='REQUEST FUNDS' /></div>
                    }
                />
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.creditAppReducer,
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdvancesIndex))