import React from 'react'
import styled, { keyframes, useTheme } from 'styled-components'
import { getResourcePerm } from '../../Utils/util'
import { Text } from './Text'
import { Popconfirm } from 'antd'
import { DownOutlined, PlusOutlined, LoadingOutlined, CloseOutlined } from '@ant-design/icons';
import ChipClose from '../../Images/close-primary.svg';
import Close from '../../Images/close.svg';
import Add from '../../Images/add.png';
import { Flex } from './Container'
import SVGIcon from './SVGIcon'
import hexToRgba from 'hex-to-rgba'

const useStyle = 1;

const Icon = styled.span`
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    margin-right: ${props => !props.right && '8px'};
    margin-left: ${props => props.right && '8px'};
    color: ${props => props.disabled ? '#737387' : (props.color ? props.color : props.theme.button.primary)};
    cursor: ${props => props.noClick ? 'default' : props.disabled ? 'not-allowed' : 'pointer'};
`;

const StyledTextButton = styled.span`
    padding: ${props => props.padding ? props.padding : '0px'};
    font-family: 'InterDisplay';
    font-size: ${props => props.size ? props.size : '14px'};
    font-style: normal;
    font-weight: ${props => props.weight ? props.weight : '600'};
    line-height: ${props => props.height ? props.height : '24px'};
    letter-spacing: 0em;
    text-align: left; 
    width: ${props => props.width && props.width};
    color: ${props => props.mute ? props.theme.button.primary2 : props.disabled ? '#CCCCCC' : (props.color ? props.color : props.theme.button.primary)};
    text-transform: ${props => props.uppercase && 'uppercase'};
    cursor: ${props => (!props.noClick && !props.disabled) && 'pointer'};
    text-decoration-line: ${props => props.underline && 'underline'};
    overflow-wrap: break-word;
    white-space: ${props => props.preLine ? 'pre-line' : 'nowrap'};
    word-break: ${props => props.breakWord ? "break-all" : "auto-phrase"}
`;

export const TextButton = (props) => {

    return <Flex {...props} start centerHorizontally onClick={() => { }} style={{ margin: props.margin, cursor: props.noClick ? 'default' : props.disabled ? 'not-allowed' : 'pointer' }}>
        {
            props.loading &&
            <Icon noClick={props.noClick} disabled={props.disabled}><LoadingOutlined style={{ color: props.color }} /></Icon>
        }
        {
            !props.loading && props.icon &&
            <Icon onClick={props.onClick} noClick={props.noClick} disabled={props.disabled} color={props.color}>{props.icon}</Icon>
        }
        {
            props.leftIcon &&
            <Icon onClick={props.onClick} left disabled={props.disabled} color={props.color}>{props.leftIcon}</Icon>
        }
        {
            /**
              * Re: onClick={() => {}}
              * span also has an onClick event which might cause a double click, this will ensure double clicks do not occur 
             */
        }
        <StyledTextButton {...props} onClick={(!props.loading && !props.disabled) && props.onClick}>{props.text && props.text}</StyledTextButton>
        {
            props.rightIcon &&
            <Icon onClick={props.onClick} noClick={props.noClick} right disabled={props.disabled} color={props.color}>{props.rightIcon}</Icon>
        }
        {
            props.onDelete &&
            <Popconfirm title={props.deleteConfirmMessage} onConfirm={props.onDelete}>
                <ImageButton src={ChipClose} margin='0 8px' />
            </Popconfirm>
        }
        {
            props.dropdown &&
            <DownOutlined style={{ marginLeft: 6, fontSize: 18, fontWeight: 500, color: props.solid ? 'white' : '#1199FF' }} />
        }
    </Flex>
}


const StyledButton = styled.span`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: ${props => props.padding ? props.padding : '16px 24px'};
    width: ${props => props.width && props.width};
    height: ${props => props.height ? props.height : '40px'};
    background: ${props => props.background ? props.background : (props.disabled ? '#F2F2F2' : (props.solid ? props.theme.button[`primaryBg${useStyle}`] : 'white'))};
    /* box-shadow: ${props => (!props.disabled && props.solid) && '0px 4px 32px rgba(55, 79, 234, 0.24)'}; */
    border: ${props => (!props.noBorder) && `1px solid ${props.disabled ? '#F2F2F2' : props.color ? props.color : props.theme.button[`primaryBdr${useStyle}`]}`};
    border-radius: ${props => props.radius ? props.radius : '8px'};
    margin: ${props => props.margin && props.margin};
    cursor: ${props => props.noClick ? 'default' : props.disabled ? 'not-allowed' : 'pointer'};
    white-space: nowrap;
    box-shadow: ${props => props.shadow && '0px 4px 8px rgba(102, 102, 102, 0.08)'};
`;

export const Button = (props) => {
    const perm = getResourcePerm(props.permtype || "", { menuPerm: true });
    const theme = useTheme();
    const txtColor = theme.button[`primaryTxt${useStyle}`];

    /* console.log('theme', theme) */

    return (!props.permtype || (props.permtype && perm.manage || perm.approve)) ? <StyledButton {...props} onClick={(!props.loading && !props.disabled) && props.onClick}>
        <TextButton
            loading={props.loading}
            text={props.text}
            icon={props.icon}
            rightIcon={props.rightIcon}
            color={props.disabled ? '#CCCCCC' : (props.solid ? txtColor : props.color)}
            weight={props.weight || 600}
            size={'14px'}
            noClick={props.noClick}
            disabled={props.disabled} />
        {
            props.dropdown &&
            <DownOutlined style={{ marginLeft: 6, fontSize: 18, fontWeight: 500, color: props.solid ? txtColor : '#1199FF' }} />
        }
        {/* {
            props.loading &&
            <LoadingOutlined style={{ marginLeft: 10, fontSize: 16, color: props.solid ? 'white' : '#1199FF' }} />
        } */}
    </StyledButton> : null
}

export const Capsule = (props) => {
    const Capsule = styled.div`
        height: ${props => props.height ? props.height : '32px'};
        border: 1px solid #1199FF;
        border-radius: 40px;
        padding: 4px 16px;
        background-color: ${props => props.background ? props.background : props.selected === true ? '#1199FF' : 'white'};
        cursor: ${props => (props.onClick == null) ? "unset" : "pointer"};
        margin: ${props => props.margin && props.margin};
        display: flex;
        align-items: center;
        justify-content: center;
    `

    const CapsuleText = styled.span`
        font-family: 'InterDisplay';
        font-size: ${props => props.fontSize ? props.fontSize : '16px'};
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: ${props => props.selected === true ? 'white' : '#1199FF'};
    `

    return (
        <Capsule {...props}>
            <CapsuleText {...props}>{props.text}</CapsuleText>
            {
                props.closable &&
                <CloseOutlined onClick={() => props.onDelete(props.index)} style={{ color: '#1199FF', fontSize: 14, marginLeft: 10, cursor: 'pointer' }} />
            }
        </Capsule>
    );
}

export const FilterButton = (props) => {
    const FilterButton = styled.span`
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 24px;
        background: ${props => props.selected ? '#1199FF' : 'transparent'};
        border: 1px solid #1199FF;
        border-radius: 40px;
        color: ${props => props.selected ? 'white' : '#1199FF'};
        font-family: 'InterDisplay';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        padding: 16px 16px;
        margin-right: 15px;
        margin-bottom: 15px;
        cursor: pointer;
    `;

    return <FilterButton selected={props.selected} onClick={props.onClick} >
        {props.text}
        <PlusOutlined style={{ color: props.selected ? 'white' : '#1199FF', fontSize: 14, marginLeft: 13, cursor: 'pointer' }} />
    </FilterButton>
}

export const Chip = (props) => {
    const Chip = styled.div`
        height: ${props => props.height ? props.height : '40px'};
        border: ${props => props.border || `${(props.selected || props.primary) ? '1.5px' : '1px'} solid ${(props.selected || props.primary) ? '#1199FF' : '#7384AA'}`};
        border-radius: 4px;
        padding: ${props => props.padding ? props.padding : '4px 8px'};
        background: ${props => props.background ? props.background : 'white'};
        cursor: ${props => (props.onClick == null) ? "unset" : "pointer"};
        margin: ${props => props.margin && props.margin};
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: ${props => props.noWrap && 'nowrap'};
        gap: 8px;
    `

    return (
        <Chip {...props}>
            <Text center primary={props.primary} weight={props.selected && '500'} color={(props.selected || props.primary) ? '#1199FF' : '#7384AA'} size={props.fontSize ? props.fontSize : '12px'}>{props.text}</Text>
            {
                props.closable && !props.add &&
                <img height='12px' width='12px' style={{ cursor: 'pointer' }} src={ChipClose} onClick={(i) => props.onDelete(i)} />
            }

            {
                props.add && !props.selected &&
                <img height='12px' width='12px' style={{ cursor: 'pointer' }} src={Add} onClick={(i) => props.onAdd(i)} />
            }

            {
                props.add && props.selected &&
                <img height='12px' width='12px' style={{ cursor: 'pointer' }} src={ChipClose} onClick={(i) => props.onDelete(i)} />
            }
        </Chip>
    );
}

const IconImage = styled.img`
    width: ${props => props.width || props.theme.icon.width};
    height: ${props => props.height || props.theme.icon.height};
`;

const clickAnimation = keyframes`
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(0.9);
    }
    100% {
        transform: scale(1);
    }
`;

// Use this component to pass a 'lucide-react' icon

const LucideIconWrapper = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: ${props => props.onClick && 'pointer'};
    transition: background-color 0.3s ease;
    border-radius: 50%;
    padding: 8px;
    margin: ${props => props.margin};
    z-index: ${props => props.zIndex};

    &:hover {
        background-color: ${props => hexToRgba(props.theme.colors.primary10, 0.2)};
    }

    &:active {
        animation: ${clickAnimation} 0.3s ease;
    }
`;

const StyledIcon = styled.span`
    color: ${props => props.color || hexToRgba(props.theme.colors.secondary, 0.8)};
    transition: color 0.3s ease;

    svg {
        width: ${props => props.size}px;
        height: ${props => props.size}px;
        stroke-width: ${props => props.strokeWidth};
    }
`;

const LucideIcon = ({ icon: Icon, ...props }) => {
    return (
        <LucideIconWrapper onClick={props.onClick} margin={props.margin} zIndex={props.zIndex} title={props.title}>
            <StyledIcon as={Icon} {...props} />
        </LucideIconWrapper>
            
    );
};

export default LucideIcon;

const IconWrapper = styled.div`
    display: inline-block;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 50%;
    padding: 5px;
    margin: ${props => props.margin};
    zIndex: ${props => props.zIndex};
    transform: ${props => props.transform};

    &:hover {
        background-color: ${props => hexToRgba(props.theme.colors.primary10, 0.2)};
    }

    &:active {
        animation: ${clickAnimation} 0.3s ease;
    }
`;

export const ImageButton = (props) => {

    return <IconWrapper title={props.title} >
        {
            props.svg ?
                <SVGIcon
                    {...props}
                />
                :
                <IconImage
                    src={props.src}
                    {...props}
                    style={{ transform: props.transform }}
                />
        }
    </IconWrapper>
}