import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled, { withTheme } from 'styled-components'
import { CardContainer } from '../Reusable/CardContainer'
import { Flex, FlexColumn } from '../../Reusable/Container'
import environment from '../../../environment'

import AppIcon from '../../../Images/product-menu/payables-menu-icon-active.png'
import { apiPOSTReq } from '../../../Utils/api'
import CardHeader from '../Reusable/CardHeader'
import { TextButton } from '../../Reusable/Button'
import { Image } from '../../Reusable/Image'
import ArrowRight from "../../../Images/arrow-right.png"
import { Tag, Text } from '../../Reusable/Text'
import { getDateInLocalTZ, toCurrency } from '../../../Utils/util'
import TableView from '../../Reusable/TableView'
import hexToRgba from 'hex-to-rgba'
import { InitialCircle } from '../../Reusable/InitialCircle'
import { ErrorAlert } from '../../Reusable/Alert'
import { ScrollableTableView } from '../Reusable/ScrollableTableView'

class BillsInbox extends Component {
    state = {
        loading: true,
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        var body = {
            size: 50,
            page: 0,
            sortDirection: "DESC",
            viewBy: "FILTER_STATUS",
            processingError: false,
            archived: false,
            "converted": false
        }

        this.setState({ loading: true })
        apiPOSTReq(`${environment.payBaseUrl}/payables/bills/getBillInboxItems`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            try {
                const data = resp || {}
                if (data.result) {
                    this.setState({
                        inboxItems: data.billInboxItems || []
                    })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /payables/bills/getBillInboxItems", error, err, resp)
            } finally {
                this.setState({ loading: false })
            }
        })
    }

    columns = [
        {
            title: 'From',
            dataIndex: 'from',
            key: 'from',
            render: (from, inboxItem) => {
                var split = (from || "").split("<")
                return (
                    <Flex start centerHorizontally gap='8px'>
                        <InitialCircle name={inboxItem.vendorName || split[0]} size={30} useFirstWordOnly={true} />
                        <Text color={this.props.theme.colors.primary2}>{inboxItem.vendorName || split[0]}</Text>
                        {
                            !inboxItem.parsedInvoiceId && !inboxItem.processingError &&
                            <Tag title='Please wait for a few minutes while we process your bill'>Processing</Tag>
                        }
                    </Flex>
                )
            }
        },
        {
            title: 'Date',
            dataIndex: 'createdDate',
            key: 'date',
            render: createdDate => <span style={{ color: hexToRgba(this.props.theme.colors.primary2, 0.5) }}>{getDateInLocalTZ(createdDate, "MMM D, h:mm A")}</span>
        },
        {
            title: 'Amount',
            dataIndex: 'invoiceAmount',
            key: 'amount',
            render: invoiceAmount => <span style={{ fontWeight: 600 }}>{`$${toCurrency(invoiceAmount)}`}</span>,
            align: 'right'
        }

    ]

    handleBillDetail = (inboxItem) => {
        this.props.history.push({
            pathname: '/payables/inbox/detail',
            state: { inboxItem: inboxItem }
        })
    }
    
    render() {
        const { inboxItems, loading } = this.state

        return (
            <CardContainer>
                <FlexColumn style={{ width: this.props.theme.dashboardCard.large }}>
                    <CardHeader 
                        imgSrc={AppIcon}
                        titleText='Bills' 
                        desc='Inbox'
                        path='payables/bills'
                        secondaryContent={
                            <FlexColumn centerVertically>
                                {<TextButton onClick={() => this.props.history.push('payables/bills')} rightIcon={<Image src={ArrowRight} />} text="VIEW BILLS" />}
                            </FlexColumn>
                        }
                    />
                    <ScrollableTableView
                        id="inbox"
                        columns={this.columns}
                        dataSource={inboxItems}
                        rowKey='id'
                        loading={loading}
                        borderView
                        hideHeader
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event => {
                                    record.parsedInvoiceId ?
                                        this.handleBillDetail(record)
                                        :
                                        ErrorAlert({ title: "Processing", description: "This bill is being processed by our AI Engine, check back shortly." })
                                }, // click row
                            }
                        }}
                    />
                </FlexColumn>
                
            </CardContainer>
            
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(BillsInbox)))