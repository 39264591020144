import hexToRgba from 'hex-to-rgba';
import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { getDateInLocalTZ, toCurrency } from '../../../Utils/util';
import { Text } from '../../Reusable/Text';
import moment from 'moment';
import { withTheme } from 'styled-components';

/*
const data = [
  { name: 'Spend via virtual', value: 651.0 },
  { name: 'Spend via physical', value: 123.7 },
  { name: 'Sales Spend', value: 234.76 }
];
*/

const CustomizedLabel = ({ percent, value }) => {
    return `${(percent * 100).toFixed(0)}%`;
};

const CardSpendDonutChart = ({ data, theme }) => {
    const COLORS = theme.colors.chart;
    return (
        <div className="w-full max-w-2xl mx-auto" style={{ position: 'relative' }}>
            <div style={{ position: 'absolute' }}>
                <Text heading margin={'40px'}>{getDateInLocalTZ(moment(), 'MMMM YYYY')}</Text>
            </div>
            <ResponsiveContainer width={250} height={250} >
                <PieChart>
                    <Pie
                        data={data}
                        cx="50%"
                        cy="50%"
                        innerRadius={40}
                        outerRadius={50}
                        fill="#8884d8"
                        dataKey="value"
                        // label={CustomizedLabel}
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={hexToRgba(COLORS[index % COLORS.length], 0.9)} />
                        ))}
                    </Pie>
                    <Tooltip formatter={(value) => `$${toCurrency(value)}`} />
                    {
                        data.length <= 5 &&
                        <Legend 
                            // layout="vertical"
                            // verticalAlign="middle"
                        />
                    }
                </PieChart>
            </ResponsiveContainer>
        </div>
)
};

export default withTheme(CardSpendDonutChart);