import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';

import { message } from 'antd';

// Components
import { TextButton } from '../../../Reusable/Button';
import { FlexColumn } from '../../../Reusable/Container';
import { ErrorAlert } from '../../../Reusable/Alert';
import PageHeader from '../../../Reusable/PageHeader';
import environment from '../../../../environment';
import { apiPOSTReq } from '../../../../Utils/api';
import BillDetail from '../BillDetail';


class EditBill extends Component {

    updateBill = (bill, vendor, vendorType) => {
        // const { bill, vendor, fileList, flowSteps, inboxItem } = this.state;

        if (!vendor.vendorName) {
            ErrorAlert({ description: "Please select a vendor for this bill." });
            return;
        }
        if (!bill.amount) {
            ErrorAlert({ description: "Please enter an amount for this bill." });
            return;
        }
        if (!bill.invoiceNumber) {
            ErrorAlert({ description: "Please enter a bill no. for this bill." });
            return;
        }
        if (!bill.dueDate) {
            ErrorAlert({ description: "Please select a due date for this bill." });
            return;
        }
        if (!vendor.id) {
            this.setState({ addVendor: true })
            return
        }

        bill.vendorId = vendor.id
        bill.vendorName = vendor.vendorName

        const body = {
            Bill: bill
        }

        console.log("/payables/bills/save body", JSON.stringify(body))

        apiPOSTReq(`${environment.payBaseUrl}/payables/bills/save`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            this.setState({ saveLoading: false, payLoading: false })
            try {
                const data = resp;
                console.log("/payables/bills/save", JSON.stringify(data))
                if (data.result) {
                    message.success('Bill updated successfully!');
                    window.history.back()
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /payables/bills/save", error, err, resp);
                ErrorAlert({ description: error.message })
            }
        })
    }

    render() {
        var { bill } = this.props.location.state || {}
        return (
            <FlexColumn className='main-padding' fullWidth start grow>
                <PageHeader
                    back
                    titleText="Edit bill"
                />

                <BillDetail
                    key="editBillDetail"
                    buttonText={"Update"}
                    bill={bill}
                    onSubmit={this.updateBill}
                    edit
                />
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        bkStore: state.bookkeepingAppReducer,
        store: state.aionAppReducer,
        bankingStore: state.bankingAppReducer,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(EditBill))