import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'

import {
    Table,
    Space,
    Tooltip,
    Modal,
    message,
    Popconfirm
} from 'antd'
import { Tag } from '../../../Reusable/Text'
import environment from '../../../../environment'
import { apiPOSTReq } from '../../../../Utils/api'
import { FlexColumn } from '../../../Reusable/Container'
import PageHeader from '../../../Reusable/PageHeader'
import { Button } from '../../../Reusable/Button'
import AddRestriction from './AddRestriction'
import { ErrorAlert } from '../../../Reusable/Alert'
import TableView from '../../../Reusable/TableView'

class Index extends Component {
    state = {
        restrictionInfoList: [],
        account: this.props.history.location.state.account || {},
        deleteLoading: false
    }

    componentDidMount() {
        this.fetchRestrictions()
    }

    fetchRestrictions = () => {
        const { account } = this.state
        var body = {
            accountNumber: account.accountNumber
        }
        apiPOSTReq(`${environment.bpBaseUrl}/bp/payments/getRestrictions`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            try {
                console.log("/bp/payments/getRestrictions", err, resp)
                const data = resp
                if (data.result) {
                    if (err) throw new Error(err)
                    var restrictionInfoList = data.restrictionInfoList || []
                    this.setState({ restrictionInfoList: restrictionInfoList })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getRestrictions", error, err, resp)
            }
        })
    }

    deleteRestriction = (restriction) => {
        const { account } = this.state
        var body = {
            restrictionId: restriction.restrictionId,
            accountNumber: account.accountNumber
        }
        this.setState({ deleteLoading: true })
        apiPOSTReq(`${environment.bpBaseUrl}/bp/payments/deleteRestriction`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            try {
                this.setState({ deleteLoading: false })
                console.log("/bp/payments/deleteRestriction", err, resp)
                const data = resp
                if (data.result) {
                    message.success(`Restriction successfully deleted`)
                    this.fetchRestrictions()
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR deleteRestriction", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    doesRestrictionExist = (restriction) => {
        var { restrictionInfoList } = this.state

        const rExists = (restrictionInfoList || []).find(item => ((item.rail == restriction.rail) && (item.transactionType == restriction.transactionType)))
        return rExists
    }

    render() {
        const { account, restrictionInfoList, loading } = this.state
        console.log("restr", this.state)
        const columns = [
            {
                title: 'Rail',
                dataIndex: 'rail',
                key: 'rail'

            },
            {
                title: 'Type',
                dataIndex: 'transactionType',
                key: 'transactionType'
            },
            {
                title: 'Restriction Type',
                dataIndex: 'restrictionType',
                key: 'restrictionType'
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status'

            },
            {
                title: 'Notes',
                dataIndex: 'notes',
                key: 'notes'
            },
            {
                title: 'Action',
                key: 'action',
                render: (item) => (
                    item.restrictionType == "Partner" ?
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={() => this.deleteRestriction(item)}
                        okText="Confirm"
                        okButtonProps={{ loading: this.state.deleteLoading }}
                        cancelText="Cancel"
                    >
                        <a>Delete</a>
                    </Popconfirm>
                    :
                    "N/A"
                )
            }
        ]

        return (
            <>
                <FlexColumn className='main-padding'>

                    <PageHeader
                        titleText={`Account Restrictions - ${account.mask}`}
                        back
                        
                    />
                    <TableView
                        id="restriction-table"
                        titleText='Restrictions'
                        columns={columns}
                        dataSource={restrictionInfoList}
                        rowKey='accountId'
                        loading={loading}
                        pagination={false}
                        ctaContent={<Button solid disabled={false} style={{ height: "45px" }} onClick={() => this.setState({ showAddRestrictionModal: true })} text='Add Restriction' />}
                    />
                </FlexColumn>
                <Modal
                    visible={this.state.showAddRestrictionModal}
                    footer={null}
                    closable={true}
                    width={700}
                    style={{ top: 20 }}
                    destroyOnClose={true}
                    onCancel={() => { this.setState({ showAddRestrictionModal: false }) }}
                >
                    <AddRestriction
                        account={account}
                        doesRestrictionExist={this.doesRestrictionExist}
                        submitComplete={
                            () => {
                                this.setState({ showAddRestrictionModal: false })
                                this.fetchRestrictions()
                            }
                        }
                    />
                </Modal>
            </>
        )
    }

}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer,
        bankingStore: state.bankingAppReducer,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)))