import React, { Component } from 'react'
import styled, { withTheme } from 'styled-components'
import { withRouter } from 'react-router-dom'

import { FlexColumn, Flex } from './Container'
import AionLogo, { Image } from './Image'
import { Text } from './Text'
import { Badge, Dropdown, Menu, Modal } from 'antd'
import { copyToClipboard, getFeaturePerm, toCurrency } from '../../Utils/util'
import ProfileImage from '../Settings/Profile/UserProfile/ProfileImage'
import LucideIcon, { Button, ImageButton, TextButton } from './Button'
import SendToModal from './SendToModal'
import AlertModal from './AlertModal'

import AlertIcon from "../../Images/alert.png"
import ArrowRight from "../../Images/arrow-right.png"
import Logout from "../../Images/logout-blue.png"
import ArrowLeft from "../../Images/arrow-left.svg"
import AionLogoImg from "../../Images/aion.svg"
import { Header } from 'antd/lib/layout/layout'
import { capitalize } from 'lodash'
import { connect } from 'react-redux'
import SVGIcon from './SVGIcon'
import Copy from "../../Images/copy.svg"
import Dollar from '../../Images/referrals_dollar.svg'
import Arrow from '../../Images/right-arrow.svg'
import ModalClose from '../../Images/modal-close.png'
import PayerMsg from '../../Images/payer-message-sent.svg'
import { LayoutDashboard } from 'lucide-react'

import { apiPOSTReq } from '../../Utils/api'
import environment from '../../environment'
import { addDataToStore, SAVE_DATA } from '../../Actions/actions'

const NavContainer = styled.div`
    width: 100%;
    height: 62px;

    background: ${props => props.hideBg ? 'transparent' : props.theme.body};
    box-shadow: ;${props => props.hideBg ? 'none' : '0px 0px 2px rgba(0, 0, 0, 0.1)'};
    border-radius: 8px;
`

class TopNav extends Component {

    state = {

    }

    componentDidMount() {
        this.fetchSubcriptionPlan()
    }

    fetchSubcriptionPlan = () => {
        apiPOSTReq(`${environment.uamBaseUrl}/aion/system/getSubscriptionInfo`, {}, {}, (err, resp) => {
            try {
                if (err) throw Error(err)
                const data = resp || {}
                if (data.result) {
                    // console.log("/aion/system/getSubscriptionInfo", data)
                    this.props.dispatch(addDataToStore(SAVE_DATA, { businessSubscriptionPlan: data.businessSubscriptionPlan || {} }))
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/aion/system/getSubscriptionInfo err", error, resp)
            }
        })
    }

    getReferralCode = () => {
        this.setState({ loadingReferralCode: true })
        console.log("/aion/system/getReferralCode body")
        apiPOSTReq(`${environment.uamBaseUrl}/aion/system/getReferralCode`, null, {}, (err, resp) => {
            try {
                const data = resp || {};
                if (data.result) {
                    console.log("/aion/system/getReferralCode data", data)
                    this.setState( { referralsData: { referralCode: data.referralCode, inviteCount: data.inviteCount, referralAmount: data.referralAmount } })
                    this.setState({ loadingReferralCode: false })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("getReferralCode error", { description: error.message })
            }
        })
    }

    render() {
        var { theme, location, notification, notificationText, store, onboardingMode, content, showDashIcon, miniNav } = this.props
        var { showSendToModal, showThankYouModal, referralDropdownVisible, referralsData, loadingReferralCode } = this.state
        var { navProps, pageTitleText } = store
        var { referralCode } = referralsData || {}
        var { back, onBack } = navProps || {}
        var { pathname } = this.props.location
        var pathArr = pathname.split('/')

        var titleText = ''
        titleText = store.pageTitleText || titleText
        if (pageTitleText != '') {
            titleText = pageTitleText
        } else if (pathArr.length > 0) {
            titleText = onboardingMode ? '' : capitalize(pathArr[pathArr.length - 1])
        }

        const referralInviteAccess = getFeaturePerm("AionApplications.Users.ReferralInvite").view

        return (
            <>
                {
                    miniNav
                    ?
                    <Flex between centerHorizontally gap='8px'>
                        <AionLogo logoHeight={30} margin='0' />
                        <Flex centerHorizontally  gap='12px'>
                            <Dropdown
                                placement='bottomRight'
                                overlay={<Menu style={{ padding: 24, borderRadius: 8 }}>
                                    <Flex center style={{ width: (notificationText) ? 320 : 220 }}>
                                        <Text>{(notificationText) ? notificationText : 'No Notifications'}</Text>
                                    </Flex>
                                </Menu>}
                                onVisibleChange={flag => this.setState({ notification: false })}
                            >
                                <Flex style={{ zIndex: 99, cursor: 'pointer' }}>
                                    <ImageButton src={AlertIcon} />
                                    {
                                        notification &&
                                        <Badge style={{ marginLeft: -10, marginTop: -4 }} count={1} size="small" />
                                    }
                                </Flex>
                            </Dropdown>
                            <Dropdown
                                placement='bottomRight'
                                align={{ overflow: { adjustX: true, adjustY: true } }}
                                overlay={<Menu style={{ padding: 24, borderRadius: 8 }}>
                                    <FlexColumn start gap='12px'>
                                        <TextButton onClick={() => this.props.history.push("/settings/user-profile")} rightIcon={<Image src={ArrowRight} />} text={"View Profile".toUpperCase()} />
                                        <TextButton onClick={() => this.props.signout()} rightIcon={<Image src={Logout} />} text={"Logout".toUpperCase()} />
                                    </FlexColumn>
                                </Menu>}
                            >
                                <Flex style={{ zIndex: 99, cursor: 'pointer', marginLeft: '5px' }}>
                                    <ProfileImage
                                        size={24}
                                        hideupload="true"
                                    />
                                </Flex>
                            </Dropdown>
                        </Flex>
                    </Flex>
                    :
                    <NavContainer hideBg={onboardingMode}>
                        <Flex between centerHorizontally style={{ width: '100%', height: '100%', padding: '0 24px' }} gap='16px'>
                            <Flex centerHorizontally gap='4px'>
                                {
                                    back &&
                                    <ImageButton
                                        title='Back'
                                        svg
                                        src={ArrowLeft}
                                        strokeWidth='2px'
                                        onClick={() => {
                                            if (onBack) {
                                                onBack()
                                            } else {
                                                this.props.history.goBack()
                                            }
                                        }}
                                    />
                                }

                                {
                                    content ?
                                        content
                                        :
                                        <Text weight={600} size='20px' color={theme.colors.primary2}>{titleText}</Text>
                                }
                            </Flex>

                            <Flex centerHorizontally gap='20px' style={{ padding: 12 }}>
                                {
                                    referralInviteAccess && 
                                    <Dropdown
                                        trigger='click'
                                        visible={referralDropdownVisible && !loadingReferralCode}
                                        onVisibleChange={flag => this.setState({ referralDropdownVisible: flag })}
                                        placement='bottomRight'
                                        overlay={<Menu style={{ padding: 0, borderRadius: 8 }}>
                                            <FlexColumn center style={{ minWidth: 366 }}>
                                                <FlexColumn center style={{ padding: '16px 24px' }}>
                                                    <Text heading>Refer and earn rewards</Text>
                                                    <Text center lightText>Invite other businesses to Aion and get rewarded!<br />Once your referral has opened their account and<br />completed their first 10 transactions, we will<br />deposit a ${referralCode?.amount} bonus in your account and another<br />${referralCode?.amount} bonus to your referral's account.<br /><br />Questions? Contact our customer success team.</Text>
                                                    <Button text='Email invite' solid margin='16px 0' onClick={() => this.setState({ showSendToModal: true, referralDropdownVisible: false })} />

                                                    <Flex center gap='8px'>
                                                        <Text>Your referral code:</Text>
                                                        <Text size='20px' heading>{referralCode?.id}</Text>
                                                        {
                                                            referralCode?.referralUrl &&
                                                            <ImageButton src={Copy} onClick={() => copyToClipboard(referralCode?.referralUrl)} />
                                                        }
                                                    </Flex>
                                                </FlexColumn>

                                                <Flex fullWidth between centerHorizontally gap='16px' style={{ marginTop: 0, background: '#F3FAFF', padding: '16px 24px', cursor: 'pointer' }}
                                                    onClick={() => {
                                                        this.setState({ showSendToModal: false, referralDropdownVisible: false })
                                                        this.props.history.push({
                                                            pathname: '/settings/referrals',
                                                            state: {}
                                                        })
                                                    }}>
                                                    <Text primary size='16px'>View your referrals</Text>

                                                    <Image src={Arrow} />
                                                </Flex>
                                            </FlexColumn>
                                        </Menu>}
                                    >
                                        <div style={{ borderRadius: 24, background: 'var(--Solid-Primary-Blue-0, #F3FAFF)', padding: '4px 8px' }}>
                                            <TextButton loading={loadingReferralCode} onClick={() => this.getReferralCode()}  icon={<Image src={AionLogoImg} />} text='Refer us now!' underline weight='400' />
                                        </div>
                                    </Dropdown>
                                }
                                {
                                    showDashIcon &&
                                    <LucideIcon
                                        icon={LayoutDashboard}
                                        size={24} 
                                        strokeWidth='1.8px'
                                        onClick={() => { this.props.history.push('/dashboard') }}
                                        title="Dashboard"
                                    />
                                }
                                
                                <Dropdown
                                    placement='bottomRight'
                                    overlay={<Menu style={{ padding: 24, borderRadius: 8 }}>
                                        <Flex center style={{ width: (notificationText) ? 320 : 220 }}>
                                            <Text>{(notificationText) ? notificationText : 'No Notifications'}</Text>
                                        </Flex>
                                    </Menu>}
                                    onVisibleChange={flag => this.setState({ notification: false })}
                                >
                                    <Flex style={{ zIndex: 99, cursor: 'pointer' }}>
                                        <ImageButton src={AlertIcon} />
                                        {
                                            notification &&
                                            <Badge style={{ marginLeft: -10, marginTop: -4 }} count={1} size="small" />
                                        }
                                    </Flex>
                                </Dropdown>
                                <Dropdown
                                    placement='bottomRight'
                                    align={{ overflow: { adjustX: true, adjustY: true } }}
                                    overlay={<Menu style={{ padding: 24, borderRadius: 8 }}>
                                        <FlexColumn start gap='12px'>
                                            <TextButton onClick={() => this.props.history.push("/settings/user-profile")} rightIcon={<Image src={ArrowRight} />} text={"View Profile".toUpperCase()} />
                                            <TextButton onClick={() => this.props.signout()} rightIcon={<Image src={Logout} />} text={"Logout".toUpperCase()} />
                                        </FlexColumn>
                                    </Menu>}
                                >
                                    <Flex style={{ zIndex: 99, cursor: 'pointer', marginLeft: '5px' }}>
                                        <ProfileImage
                                            size={24}
                                            hideupload="true"
                                        />
                                    </Flex>
                                </Dropdown>
                            </Flex>
                        </Flex>
                    </NavContainer>
                }
                

                <Modal
                    visible={showSendToModal}
                    footer={null}
                    closable={true}
                    maskClosable={true}
                    width={616}
                    destroyOnClose={true}
                    onCancel={() => this.setState({ showSendToModal: false })}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <SendToModal onCancel={() => this.setState({ showSendToModal: false })} />
                </Modal>

                <AlertModal
                    visible={showThankYouModal}
                    title='Thank you for your referral'
                    description={`An invitation/s has been sent to sign up for a new Aion account.`}
                    buttonTitle='Okay'
                    onConfirm={() => this.setState({ showThankYouModal: false })}
                    imgSrc={PayerMsg}
                    imgHeight='80px'
                    imgWidth='80px'
                />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    }
}

export default connect(mapStateToProps)(withTheme(withRouter(TopNav)))