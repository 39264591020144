import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import { Select, Modal, Radio } from 'antd'
import moment from 'moment'
import { Document, Page, pdfjs } from 'react-pdf'
import { saveAs } from 'file-saver'
import styled from 'styled-components'

import { FlowViewContainer } from '../../Reusable/FlowViewContainer'
import FlowViewComponent from '../../Reusable/FlowViewComponent'
import { ErrorAlert } from '../../Reusable/Alert'
import { LabeledInput } from '../../Reusable/Input'
import { Paragraph } from '../../Reusable/Text'

import { apiPOST, apiPOSTReq } from '../../../Utils/api'
import environment from '../../../environment'
import { InputContainer } from '../../Reusable/Container'
import ModalClose from '../../../Images/modal-close.png'

const { Option } = Select
const TextButton = styled.span`
        font-family: 'InterDisplay';
        font-size: 16px;
        font-style: normal;
        font-weight: ${props => props.underline ? 400 : 500};
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left; 
        color: ${props => props.disabled ? '#CCCCCC' : (props.color ? props.color : '#1199FF')};
        text-transform: ${props => props.uppercase && 'uppercase'};
        text-decoration: ${props => props.underline && 'underline'};
        cursor: ${props => props.noClick ? 'default' : props.disabled ? 'not-allowed' : 'pointer'};
        white-space: nowrap;
    `;
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const flowViewsData = [{
    "ViewType": "WithoutImage",
    "FlowStep": "Statement.Start",
    "Title": "Monthly account statements",
    "Description": "Make a selection to view and download the statement for any month.",
    "Progress": 1.0,
    "ButtonTitle": "Continue",
    "ContentItems": ["PDF", "CSV"],
    "Step": "",
    "ValidationErrorMessage": "Statement month is required to continue."
}]

class Index extends Component {

    constructor(props) {
        super(props)
        this.state = {
            docType: "PDF",
            currentViewIndex: 0,
            flowViews: flowViewsData,
            submitLoading: false,
            stepProgress: 0,
            numPages: 1,
            hideStatementDownload: false
        }
    }

    onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
        this.setState({ hideStatementDownload: false, numPages: nextNumPages })
    }

    onDocumentLoadFailure = () => {
        this.setState({ hideStatementDownload: true });
    }

    render() {
        const { stmtFile, selectedStmt, numPages, docType } = this.state
        const title = "AR Credit Statement"
        const fileType = docType.toLowerCase()

        return (
            <>
                <FlowViewContainer modal={this.props.modal} title={title} contentViews={this.getContentViews()} hidesidebar={true} />
                <Modal
                    visible={this.state.showAccountStmtModal}
                    footer={null}
                    onCancel={() => { this.setState({ showAccountStmtModal: false, hideStatementDownload: false }) }}
                    width={880}
                    style={{ top: 20 }}
                    destroyOnClose={true}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    {this.state.hideStatementDownload ?
                        <Paragraph> Statement not available!</Paragraph>
                        :
                        <>
                            <TextButton onClick={() => { saveAs(stmtFile, `AR-statement-${moment(selectedStmt).format('MMMM YYYY')}.${fileType}`) }} >Download Statement</TextButton>
                            <Document
                                file={stmtFile}
                                onLoadSuccess={this.onDocumentLoadSuccess}
                                onLoadError={this.onDocumentLoadFailure}
                            >
                                {
                                    Array.from(
                                        new Array(numPages),
                                        (el, index) => (
                                            <Page
                                                key={`page_${index + 1}`}
                                                pageNumber={index + 1}
                                            />
                                        ),
                                    )
                                }
                            </Document>
                        </>
                    }
                </Modal>
            </>
        )
    }

    // This will provide the view for each flowView
    getContentViews = () => {
        const { flowViews, currentViewIndex, docType } = this.state
        const { theme, bankingStore, availableStatements } = this.props
        // const { AvailableStatements } = bankingStore
        const flowView = flowViews[currentViewIndex]
        var childViews = (<div></div>) // Initialize
        // Make any customizations here
        switch (flowView.FlowStep) {
            case "Statement.Start":
                var options = []
                const currentMonth = moment().format("MMMM YYYY");
                (availableStatements || []).forEach(item => {
                    if (currentMonth === item.name) return
                    options.push(
                        <Option key={item.value} id="selectedStmt" name={item.name} value={item.value} style={{ backgroundColor: "transparent" }}>
                            {item.name}
                        </Option>
                    )
                })
                var radioItems = []
                flowView.ContentItems.forEach(item => {
                    radioItems.push(<Radio.Button key={item} value={item}>{item}</Radio.Button>)
                })

                childViews = (
                    <InputContainer>
                        <LabeledInput
                            label="Statement date"
                            autoFocus
                            showAction={'focus'}
                            type="select"
                            showSearch
                            style={{ width: "100%" }}
                            size="large"
                            key="selectedStmt"
                            className="no-left-padding"
                            placeholder={moment().subtract(1, 'month').format("MMMM YYYY")}
                            optionFilterProp="children"
                            onChange={this.handleSelect}
                        >
                            {options}
                        </LabeledInput>
                    </InputContainer>
                )
                break
            default:
                break
        }

        return <FlowViewComponent
            flowView={flowView}
            currentViewIndex={currentViewIndex}
            loadPrev={this.loadPrevView}
            loadNext={this.loadNext}
            saveUserInput={this.saveUserInput}
            childViews={childViews}
            submitLoading={this.state.submitLoading}
        />
    }

    handleOnChange = (event) => {
        const target = event.target
        this.saveUserInput(target.id || target.name, target.value)
    }

    handleSelect = (value, option) => {
        this.saveUserInput(option.id || option.name, value)
    }

    // Validate input and go to next view if it's good
    loadNext = () => {
        var stateToSave = this.state
        const { businessId } = this.props
        const { flowViews, currentViewIndex } = this.state
        const flowView = flowViews[currentViewIndex]
        const validation = this.validateFlow()
        if (validation.validated) {
            // Save the data and load next
            switch (flowView.FlowStep) {
                case "Statement.Start":
                    const selectedStmt = flowView.UserInput
                    stateToSave.submitLoading = true

                    const body = {
                        fromDate: moment(selectedStmt, 'YYYY-MM').startOf('month').format('YYYY-MM-DD'),
                        toDate: moment(selectedStmt, 'YYYY-MM').endOf('month').format('YYYY-MM-DD'),
                        businessId
                    }

                    apiPOSTReq(`${environment.lsmBaseUrl}/lsm/getARMonthlyStatement`, null, body, (err, resp) => {
                        try {
                            if (err || !resp) throw Error("Please try again.")
                            const data = this.base64ToArrayBuffer(resp.stmtFile)
                            const blob = new Blob([data], {
                                type: 'application/pdf',
                            })
                            const fileURL = URL.createObjectURL(blob)
                            this.setState({ showAccountStmtModal: true, selectedStmt, stmtFile: fileURL, selectedStmt: selectedStmt, submitLoading: false })
                        } catch (error) {
                            ErrorAlert({ description: error.message })
                        }
                    })

                    return
                default:
                    break
            }
            this.loadNextView()
        } else {
            ErrorAlert({ description: validation.message })
        }
    }

    base64ToArrayBuffer = (base64) => {
        var binaryString = window.atob(base64)
        var binaryLen = binaryString.length
        var bytes = new Uint8Array(binaryLen)
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i)
            bytes[i] = ascii
        }
        return bytes
    }

    saveUserInput = (id, dataToSave) => {
        var { flowViews, currentViewIndex } = this.state
        var flowView = flowViews[currentViewIndex]
        switch (flowView.FlowStep) {
            case "Statement.Start":
                if (id !== "docType") flowView.UserInput = dataToSave
            default:
                break
        }
        this.setState({ flowViews: flowViews, [id]: dataToSave })
    }

    validateFlow = () => {
        var { flowViews, currentViewIndex } = this.state
        var flowView = flowViews[currentViewIndex]
        if (!flowView.UserInput) {
            // Check if default exists
            if (flowView.DefaultUserInput) flowView.UserInput = flowView.DefaultUserInput
        }
        var validation = {
            validated: false,
            message: flowView.ValidationErrorMessage ?? "Please provide an input to continue"
        }
        switch (flowView.FlowStep) {
            default:
                if (flowView.UserInput || flowView.DefaultUserInput) validation.validated = true
                break
        }
        this.setState({ flowViews: flowViews })
        return validation
    }

    loadPrevView = () => {
        let { flowViews, currentViewIndex } = this.state
        if (currentViewIndex !== 0) {
            this.setState({
                currentViewIndex: currentViewIndex - 1,
                flowViews: flowViews,
                flowView: flowViews[currentViewIndex - 1],
                showError: false, errorMsg: ""      // dismiss any validatin error, if any, for the current step
            })
        }
    }

    loadNextView = () => {
        var flowViews = this.state.flowViews
        var currentViewIndex = this.state.currentViewIndex + 1
        if (currentViewIndex < this.state.flowViews.length) {
            this.setState({
                currentViewIndex: currentViewIndex,
                flowView: flowViews[currentViewIndex]
            })
        }
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer,
        bankingStore: state.bankingAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)))