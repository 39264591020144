import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import { Skeleton, Modal, Badge, Table, Select } from 'antd';
import { FlexColumn, Flex, LightContainer } from '../../../Reusable/Container';
import { Button } from '../../../Reusable/Button';
import { Paragraph, Text } from '../../../Reusable/Text';
import { LabeledInput } from '../../../Reusable/Input';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { apiPOSTReq } from '../../../../Utils/api';
import environment from '../../../../environment';
import moment from 'moment';
import BankIcon from '../../../../Images/bank-generic.png';
import Info from '../../../Reusable/Info';
import { ErrorAlert } from '../../../Reusable/Alert';
import SuccessModal from '../../../Reusable/SuccessModal';
import BankStatements from '../BankStatements/Index'

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const { Option } = Select;

class Index extends Component {
    state = {
        loading: true,
        showModal: false,
        selectedBank: {},
        fromDate: moment().startOf('month'),
        toDate: moment(),
        month: moment().startOf('month')
    }

    componentDidMount() {
        this.fetchBanks();
    }

    fetchBanks = () => {
        const headers = {

        }

        var body = {
            BusinessId: this.props.businessId
        }

        this.setState({ loadingBanks: true })
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/plaid/getInstitutionInfo`, headers, body, (err, resp) => {
            try {
                const data = resp;
                console.log("fetchBankInfo", data.externalBBInstitutions)
                var bankInfo = data.externalBBInstitutions
                for (let index = 0; index < bankInfo.length; index++) {
                    if (bankInfo[index].status != "Connected") {
                        this.setState({ reconnectBanner: true })
                    }
                }
                this.setState({ banks: bankInfo })
            } catch (error) {
                console.log("ERROR", error.stack)
            } finally {
                this.setState({ loadingBanks: false })
            }
        })
    }

    handleBankDetails = (bankInfo) => {
        this.setState({ loadingButton: bankInfo.itemId })
        var body = {};
        const headers = {
        }

        var postUrl = `${environment.integrationBaseUrl}/integration/plaid/getAccountDetails`;
        if (bankInfo.integrationProvider == "Plaid") {
            body = {
                "itemId": bankInfo.itemId,
                "redirectUri": window.location.href,
                "connectionStatus": bankInfo.status
            }

            postUrl = `${environment.integrationBaseUrl}/integration/plaid/getAccountDetails`;
        }
        else {
            postUrl = `${environment.integrationBaseUrl}/integration/yodlee/getAccountDetails`;
            const body = {
                "providerAccountId": bankInfo.providerAccountId,
            }
        }

        apiPOSTReq(postUrl, headers, body, (err, resp) => {
            try {
                const response = resp || {};
                if (response.result) {
                    this.setState({
                        accountDetails: response.accountsList, showBankDetails: true,
                        reconnectBank: false, itemId: bankInfo.itemId, loadingButton: null,
                        selectedBank: bankInfo
                    })
                    if (bankInfo.status != "Connected") {
                        this.setState({ reconnectBank: true })
                    }
                } else {
                    ErrorAlert({ description: response.responseMessage });
                }
            }
            catch (err) {

            }

        })
    }

    handleSubmit = () => {
        if (this.state.reportType == 'CSV') {
            this.setState({ statementLoading: true })
            const { selectedBank } = this.state;
            const { loanApp } = this.props;
            var header = {
                'AionCurrentBiz': this.props.businessId,
                'BusinessId': this.props.businessId
            }

            console.log(selectedBank);
            var request = {}
            var postUrl = '';
            if (selectedBank.integrationProvider == "Plaid") {
                request = {
                    'fromDate': moment(this.state.fromDate).format('MM/DD/YYYY'),
                    'toDate': moment(this.state.toDate).format('MM/DD/YYYY'),
                    'startDate': moment(this.state.fromDate).toDate(),
                    'endDate': moment(this.state.toDate).toDate(),
                    'institutionId': selectedBank.InstitutionId,
                    'BusinessId': this.props.businessId,
                    'itemId': selectedBank.itemId
                }
                postUrl = `${environment.integrationBaseUrl}/integration/plaid/generateBankStatement`;
            }
            else {
                request = {
                    'fromDate': this.state.fromDate,
                    'toDate': this.state.toDate,
                    'startDate': moment(this.state.fromDate).toDate(),
                    'endDate': moment(this.state.toDate).toDate(),
                    'BusinessId': this.props.businessId,
                    'providerAccountId': selectedBank.providerAccountId
                }
                postUrl = `${environment.integrationBaseUrl}/integration/yodlee/generateBankStatement`;
            }
            apiPOSTReq(postUrl, header, request, (err, resp) => {
                this.setState({ statementLoading: false })
                try {
                    const data = resp;
                    if (data.result) {
                        this.setState({ statementGenerated: true })
                    } else {
                        ErrorAlert({ description: (data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later") })
                    }
                } catch (error) {
                    console.log("ERRR getAccounts", error, err, resp);
                }
            });
        }
        else{
            this.generatePDF();
        }
    }

    generatePDF = () => {
        this.setState({ statementLoading: true })
        const { selectedBank } = this.state;
        var header = {
            'AionCurrentBiz': this.props.businessId,
            'BusinessId': this.props.businessId
        }

        var request = {}
        var postUrl = '';
        if (selectedBank.integrationProvider == "Plaid") {
            request = {
                'StartDate': moment(this.state.month).startOf('month').format('YYYY-MM-DD'),
                'EndDate': moment(this.state.month).endOf('month').format('YYYY-MM-DD'),
                'BusinessId': this.props.businessId,
                'itemId': selectedBank.itemId
            }
            postUrl = `${environment.integrationBaseUrl}/integration/plaid/refreshBankStatements`;
            apiPOSTReq(postUrl, header, request, (err, resp) => {
                this.setState({ statementLoading: false })
                try {
                    const data = resp;
                    if (data.result) {
                        this.setState({ statementGenerated: true })
                    } else {
                        ErrorAlert({ description: (data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later") })
                    }
                } catch (error) {
                    console.log("ERRR getAccounts", error, err, resp);
                }
            });
        }
    }

    closeBankDetails = () => {
        this.setState({
            showBankDetails: false, loadingButton: null, fromDate: moment().startOf('month'),
            toDate: moment()
        })
    }

    closeModal = () => {
        this.setState({
            showModal: false
        });
    }

    handleDateRange = (dates) => {
        const dateFormat = 'MM/DD/YYYY';
        this.setState({
            fromDate: dates[0].format(dateFormat),
            toDate: dates[1].format(dateFormat)
        });
    }

    handleReportTypeSelection = (val) => {
        this.setState({
            reportType: val,
        })
    }

    render() {
        const { theme } = this.props;
        const { loadingBanks, banks, loadingButton, showBankDetails, selectedBank, reconnectBank, accountDetails, fromDate, toDate, statementLoading, statementGenerated, month } = this.state;
        const AccountDetailColumns = [
            {
                title: 'Title',
                dataIndex: 'title',
                align: 'left'
            },
            {
                title: 'Account Ending',
                dataIndex: 'mask',
                align: 'left'
            },
            {
                title: 'Available Balance',
                dataIndex: 'availableBalance',
                align: 'left',
                render: (availableBalance, record) => {
                    return (formatter.format(availableBalance ? availableBalance : record.currentBalance))
                },
            }
        ]

        const stmtDataOptions = ["CSV", "PDF"];
        const stmtDropdownOptions = stmtDataOptions.map(item => (
            <Option key={item} value={item}>
                {item}
            </Option>
        ));

        return (
            <FlexColumn start>
                <Paragraph level={3} bold color={theme.colors.secondary3}>Linked Bank Accounts</Paragraph>
                <Skeleton loading={loadingBanks} active title={false} paragraph={{ rows: 2 }}>
                    <Flex start wrap="wrap" style={{ margin: '0 0 12px' }}>
                        {
                            banks && banks.length > 0 &&
                            banks.map(bank => {
                                return <LightContainer padding='5px 0' margin='0 5px 5px 0' height='56px' noBorder='true'>
                                    <Flex style={{ height: '100%' }} start centerHorizontally>
                                        {
                                            (bank.logoUrl || bank.bankName) &&
                                            <Button type="primary" loading={loadingButton === bank.itemId} icon={<img style={{ marginRight: 8 }} height='24px' src={bank.logoUrl ? `${bank.logoUrl}` : BankIcon} />} text={bank.bankName} rightIcon={bank.status == "Connected" ? <></> : <ExclamationCircleFilled style={{ color: 'orange' }} />} onClick={() => this.handleBankDetails(bank)} />
                                        }
                                    </Flex>
                                </LightContainer>
                            })
                        }
                        {
                            banks && banks.length == 0 && "No Bank connections found."
                        }
                    </Flex>
                    <BankStatements businessId={this.props.businessId} changed={showBankDetails} />
                </Skeleton>
                <Modal
                    visible={showBankDetails}
                    footer={null}
                    closable={false}
                    destroyOnClose={true}
                    onCancel={() => this.closeBankDetails()}
                    width={600}
                >
                    <div style={{ textAlign: 'left' }}>
                        <Text heading caption margin='12px 12px 0 12px' >Bank Details</Text>
                        {
                            <FlexColumn style={{ margin: '24px 0' }}>
                                <div style={{ textAlign: 'left', margin: '0 12px 12px 12px' }}>
                                    <Text>
                                        <img style={{ marginRight: 8 }} height='35px' src={(selectedBank && selectedBank.logoUrl) ? selectedBank.logoUrl : BankIcon} />
                                        {selectedBank && selectedBank.bankName}
                                    </Text>
                                </div>
                                <Flex start wrap="wrap" style={{ margin: '12px 12px 0px 12px' }}>
                                    <Info label='Status' value={reconnectBank ? <Badge status="error" text="DISCONNECTED" /> : <Badge status="success" text="CONNECTED" />} />
                                </Flex>
                                <Flex start wrap="wrap" style={{ margin: '12px 12px 0px 12px' }}>
                                    <Text heading caption margin='12px 12px 12px 0'>Account Details</Text>
                                    {
                                        accountDetails && accountDetails.length > 0 &&
                                        <Table
                                            id="AccountDetailsTable"
                                            tableLayout='fixed'
                                            columns={AccountDetailColumns}
                                            dataSource={accountDetails}
                                            pagination={false}
                                            style={{ margin: '12px 0 12px 0' }}
                                        />
                                    }
                                </Flex>
                            </FlexColumn>
                        }
                        {
                            !reconnectBank && <FlexColumn style={{ margin: '24px 0' }}>
                                <Flex start wrap="wrap" style={{ margin: '12px 12px 0px 12px' }}>
                                    <Text heading caption margin='12px 12px 12px 0'>Generate Bank Statement</Text>
                                </Flex>
                                {/* <Flex start wrap="wrap" style={{ margin: '12px 12px 0px 12px' }}>
                                    <FlexColumn left gap='24px' margin='12px 12px 12px 0'>
                                        <LabeledInput
                                            label="Date Range"
                                            labelcolor={theme.colors.secondary3}
                                            id="dateRange"
                                            key="dateRange"
                                            type="range-picker"
                                            value={[moment(fromDate), moment(toDate)]}
                                            disabledDate={(current) => current > moment()}
                                            format="MM/DD/YYYY"
                                            onChange={this.handleDateRange}
                                        />
                                    </FlexColumn>
                                    <Text background="#F5F6FE" margin='12px 12px 12px 0' borderColor="2px solid var(--solid-primary-blue-20, #D7DCFB);">Once submitted the generation of statement might take some time depending upon the period selected.</Text>
                                    <Button style={{ alignSelf: 'left', margin: '20px 0' }} margin='12px 12px 12px 0' solid loading={statementLoading} onClick={this.handleSubmit} text="Generate CSV" />
                                    <Button style={{ alignSelf: 'left', margin: '20px 10px' }} margin='12px 12px 12px 0' loading={statementLoading} onClick={this.generatePDF} text="Generate PDF" />
                                </Flex> */}
                                <Flex start wrap="wrap" style={{ margin: '12px 12px 0px 12px' }}>
                                    <LabeledInput
                                        label="Report Type"
                                        labelcolor={theme.colors.secondary3}
                                        id="statementType"
                                        key="statementType"
                                        type="select"
                                        placeholder="Select"
                                        className="no-left-padding"
                                        value={this.state.reportType}
                                        onChange={this.handleReportTypeSelection}
                                    // style={{ width: "150px", fontSize: "0.95rem", fontWeight: 500 }}
                                    >
                                        {stmtDropdownOptions}
                                    </LabeledInput>
                                    <FlexColumn left gap='24px' margin='12px 12px 12px 0'>
                                        {
                                            this.state.reportType == "CSV" && <LabeledInput
                                                label="Date Range"
                                                labelcolor={theme.colors.secondary3}
                                                id="dateRange"
                                                key="dateRange"
                                                type="range-picker"
                                                value={[moment(fromDate), moment(toDate)]}
                                                disabledDate={(current) => current > moment()}
                                                format="MM/DD/YYYY"
                                                onChange={this.handleDateRange}
                                            />
                                        }
                                        {
                                            this.state.reportType == "PDF" && <LabeledInput
                                                label="Month"
                                                type="date-picker"
                                                picker="month"
                                                placeholder='MMM'
                                                value={month}
                                                format={'MMM-YYYY'}
                                                disabledDate={(current) => current > moment()}
                                                onChange={(date, dateStr) => this.setState({ month: date })}
                                            />
                                        }
                                    </FlexColumn>
                                    <Text background="#F5F6FE" margin='12px 12px 12px 0' borderColor="2px solid var(--solid-primary-blue-20, #D7DCFB);">Once submitted please check the Documents Tab for the statement. The generation of statement might take some time depending upon the period selected.</Text>
                                    <Button style={{ alignSelf: 'left', margin: '20px 0' }} margin='12px 12px 12px 0' solid loading={statementLoading} onClick={this.handleSubmit} text="Generate" />
                                    {/* <Button style={{ alignSelf: 'left', margin: '20px 10px' }} margin='12px 12px 12px 0' loading={statementLoading} onClick={this.generatePDF} text="Generate PDF" /> */}
                                </Flex>
                                <Modal
                                    visible={statementGenerated}
                                    footer={null}
                                    onCancel={() => {
                                        this.setState({ statementGenerated: false });
                                        this.closeBankDetails();
                                    }}
                                >
                                    <SuccessModal
                                        title='Statement Generated Successfully'
                                        buttonTitle='OKAY'
                                        onConfirm={() => {
                                            this.closeModal();
                                            this.setState({ statementGenerated: false });
                                        }}
                                    >
                                    </SuccessModal>
                                </Modal>
                            </FlexColumn>
                        }
                    </div>
                </Modal>
            </FlexColumn>
        );
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer,
        bankingStore: state.bankingAppReducer,
        creditStore: state.creditAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Index));