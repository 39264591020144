import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled, { withTheme } from 'styled-components'
import { addDataToStore, REMOTE_SAVE_DATA, REMOTE_SAVE_USER_STATUS } from '../../../Actions/actions'
import { Button } from '../../Reusable/Button'

import { Flex, FlexColumn, CardContainer, LightContainer } from '../../Reusable/Container'
import { Divider } from '../../Reusable/Divider'
import { Image } from '../../Reusable/Image'
import FlowViewComponent from '../../Reusable/Refresh/FlowViewComponent'
import { Text } from '../../Reusable/Text'
import ChkListIcon from '../../../Images/checklist-icon.png'
import ChkIcon from '../../../Images/check-secondary-icon.png'
import Check from '../../../Images/check.png';
import STF from '../../../Images/stc.png'
import RLOC from '../../../Images/rloc.png'


class YourCreditApplication extends Component {

    state = {}

    render() {
        var { flowView, isCreditPlus } = this.props

        return (
            <FlexColumn start left fullHeight style={{ margin: '24px 64px', paddingTop: 24, minHeight: "600px" }}>
                <FlexColumn center fullWidth style={{ padding: '0 0 24px 0' }}>
                    <Text heading size='28px'>Grow your business with Aion</Text>
                    <Text width='408px' center>Capital to cover your cash flow gaps and scale your business, without diluting your ownership</Text>
                    <Button solid text="LET'S GET STARTED" margin='24px 0' onClick={() => {
                        if (isCreditPlus) {
                            this.props.dispatch(addDataToStore(REMOTE_SAVE_USER_STATUS, flowView.FlowStep))
                        }
                        else {
                            this.props.dispatch(addDataToStore(REMOTE_SAVE_DATA, flowView.FlowStep))
                        }
                        this.props.loadNext()
                    }} />
                </FlexColumn>
                <CardContainer fullHeight fullWidth>
                    <LightContainer fullHeight fullWidth>
                        <Flex fullHeight gap="48px" fullWidth>
                            <FlexColumn style={{ width: '50%' }}>
                                <Flex start centerHorizontally gap='8px'>
                                    <Image src={STF} height='48px' width='48px' />
                                    <Text spacing size='20px'>Short term financing</Text>
                                </Flex>
                                <Text margin='24px 0 8px' color='#1199FF' weight='500'>BEST FOR</Text>
                                <Text>Short term working capital needs such as purchasing inventory or fulfilling<br />orders for a new customer or distributor</Text>
                                <Text margin='24px 0 16px' caption>HIGHLIGHTS</Text>
                                <FlexColumn start left gap='16px'>
                                    <Flex start gap='8px'>
                                        <Image src={Check} />
                                        <Text>Up to $250K*</Text>
                                    </Flex>
                                    <Flex start gap='8px'>
                                        <Image src={Check} />
                                        <Text>Pay weekly or monthly with terms up to 6 months</Text>
                                    </Flex>
                                    <Flex start gap='8px'>
                                        <Image src={Check} />
                                        <Text>No prepayment penalty</Text>
                                    </Flex>
                                </FlexColumn>
                                <Text margin='24px 0 16px' caption>ELIGIBILITY REQUIREMENTS</Text>
                                <FlexColumn start left gap='16px'>
                                    <Flex start gap='8px'>
                                        <Image src={Check} />
                                        <Text>Annual revenue greater than $200K</Text>
                                    </Flex>
                                    <Flex start gap='8px'>
                                        <Image src={Check} />
                                        <Text>Operating for more than 1 year </Text>
                                    </Flex>
                                    <Flex start gap='8px'>
                                        <Image src={Check} />
                                        <Text>1 year or more of B2B invoicing history</Text>
                                    </Flex>
                                </FlexColumn>
                                <LightContainer background='#FBFBFB' style={{ width: '564px', marginTop: '32px' }}>
                                    <FlexColumn>
                                        <Text margin='0 0 8px' caption>HOW IT WORKS?</Text>
                                        <Text>Each advance plus the financing fee will be repaid installments that we debit from your Aion bank account. Once your advance is paid in full, you are eligible to take another up to the approved limit.</Text>
                                    </FlexColumn>
                                </LightContainer>
                            </FlexColumn>
                            <FlexColumn style={{ width: '50%' }}>
                                <Flex start centerHorizontally gap='8px'>
                                    <Image src={RLOC} height='48px' width='48px' />
                                    <Text spacing size='20px'>Revolving line of credit</Text>
                                </Flex>
                                <Text margin='24px 0 8px' color='#1199FF' weight='500'>BEST FOR</Text>
                                <Text>Ongoing operating expenses, payroll, bill payments, unexpected business<br />expenses or emergency funds</Text>
                                <Text margin='24px 0 16px' caption>HIGHLIGHTS</Text>
                                <FlexColumn start left gap='16px'>
                                    <Flex start gap='8px'>
                                        <Image src={Check} />
                                        <Text>Up to $5 million*</Text>
                                    </Flex>
                                    <Flex start gap='8px'>
                                        <Image src={Check} />
                                        <Text>Only pay for the funds you use</Text>
                                    </Flex>
                                    <Flex start gap='8px'>
                                        <Image src={Check} />
                                        <Text>Unlock funds while waiting for your customer payments</Text>
                                    </Flex>
                                </FlexColumn>
                                <Text margin='24px 0 16px' caption>ELIGIBILITY REQUIREMENTS</Text>
                                <FlexColumn start left gap='16px'>
                                    <Flex start gap='8px'>
                                        <Image src={Check} />
                                        <Text>Annual revenue greater than $1 million</Text>
                                    </Flex>
                                    <Flex start gap='8px'>
                                        <Image src={Check} />
                                        <Text>Operating for more than 1 year</Text>
                                    </Flex>
                                    <Flex start gap='8px'>
                                        <Image src={Check} />
                                        <Text>1 year or more of B2B invoicing history</Text>
                                    </Flex>
                                </FlexColumn>
                                <LightContainer background='#FBFBFB' style={{ width: '564px', marginTop: '32px' }}>
                                    <FlexColumn>
                                        <Text margin='0 0 8px' caption>HOW IT WORKS?</Text>
                                        <Text>Use funds when you need it. Your customer payments flow through an Aion business banking account in your name and are automatically applied towards your outstanding principal and interest.</Text>
                                    </FlexColumn>
                                </LightContainer>
                            </FlexColumn>
                        </Flex>
                    </LightContainer>
                </CardContainer>
            </FlexColumn>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapDispatchToProps)(withTheme(YourCreditApplication))