import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import { toAbsCurrency } from '../../../Utils/util'
import { Flex } from '../../Reusable/Container'
import { Text } from '../../Reusable/Text'
import { InitialCircle } from '../../Reusable/InitialCircle'
import AgingBarChart from './AgingBarChart'
import { Bell } from 'lucide-react'
import { apiPOSTReq } from '../../../Utils/api'
import environment from '../../../environment'
import { ScrollableTableView } from './ScrollableTableView'
import FormattedIcon from './FormattedIcon'

class ARAgingCustomer extends Component {
    state = {
        customers: this.props.arAgingReport?.arAgingByCustomer
    }

    sendReminder = (customerName, customerId, totalARBalance) => {
        const { store } = this.props

        const { business } = store
        const { businessProfile } = business || {}
        const { businessInfo } = businessProfile || {}

        const subject = encodeURIComponent(`Reminder: Outstanding Balance`)
        const mailbody = encodeURIComponent(
            `Dear ${customerName},\n\n` +
            `This is a friendly reminder that you have an outstanding balance of $${toAbsCurrency(totalARBalance)}.\n\n` +
            `Please arrange for payment at your earliest convenience.\n\n` +
            `If you have any questions, please don't hesitate to contact us.\n\n` +
            `Best regards,\n${businessInfo.name}`
        )
        var email = ''

        const body = {
            customerId
        }

        this.setState({ loadingCustomer: customerId })
        apiPOSTReq(`${environment.iveBaseUrl}/ive/bc/getCustomer`, {}, body, (err, resp) => {
            try {
                this.setState({ loadingCustomer: false })
                const data = resp
                if (data.result) {
                    var selectedCustomer = data.customer

                    if (selectedCustomer) {
                        email = selectedCustomer.invoiceRecipientEmail?.length > 0 ? selectedCustomer.invoiceRecipientEmail[0] : ''
                    }
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getActiveCustomers", error.stack, resp)
            }
            window.location.href = `mailto:${email}?subject=${subject}&body=${mailbody}`;
        })
    }

    columns = [
        {
            title: 'Name',
            dataIndex: 'customerName',
            key: 'customerName',
            render: (name, record) => (
                <Flex start centerHorizontally gap='8px' wrap>
                    <InitialCircle title={name} name={name} size={30} useFirstWordOnly={true} />
                    <Text title={name}>{name.length > 18 ? `${name.substring(0, 16)}..` : name}</Text>
                    <FormattedIcon 
                        icon={Bell}
                        size={13} 
                        onClick={() => this.sendReminder(record.customerName, record.customerId, record.totalARBalance)}
                        title="Send reminder email"
                    />
                </Flex>
            )
        },
        {
            title: 'Aging',
            dataIndex: 'arAging',
            key: 'arAging',
            align: 'right',
            render: (arAging, record) => <AgingBarChart {...record} />,
            width: '90px'
        },
        {
            title: 'AR',
            dataIndex: 'totalARBalance',
            key: 'totalARBalance',
            align: 'right',
            render: (totalARBalance) => <span style={{ fontWeight: 600 }}>${toAbsCurrency(totalARBalance)}</span>,
            width: '150px'
        }
    ]

    render() {
        const { customers } = this.state

        return (
            <ScrollableTableView
                id="ar-customer"
                columns={this.columns}
                dataSource={customers}
                rowKey='customerId'
                loading={false}
                borderView
                hideHeader
                scroll={{ y: 275 }} // Enable vertical scrolling
                pagination={false}
            />
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(ARAgingCustomer)))