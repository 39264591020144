import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import { Table, Menu, message, Tabs, Modal } from 'antd'

import _ from 'lodash'
import { Text, Tag } from '../../Reusable/Text'
import { Flex, FlexColumn, CardContainer, LightContainer } from '../../Reusable/Container'
import PageHeader from '../../Reusable/PageHeader'
import { Button, TextButton, ImageButton } from '../../Reusable/Button'
import { PlusOutlined } from '@ant-design/icons'
import { apiPOSTReq } from '../../../Utils/api'
import { fetchBBHistory, normalizeAndSortHistory } from '../../../Utils/bankingDataManager'
import { getFeaturePerm, renderBankIcon } from '../../../Utils/util'
import { LabeledInput } from '../../Reusable/Input'
import environment from '../../../environment'
import { ErrorAlert } from '../../Reusable/Alert'
import { ApprovalJourney } from '../../Reusable/Approval'
import PendingTable from '../Reusable/PendingTable'
import AddTransferModal from './AddTransferModal'
import Deactivate from './Deactivate'
import DeactivateTransferMethod from './DeactivateTransferMethod'
import Activate from './Activate'

import Edit from '../../../Images/edit.svg'
import Email from '../../../Images/email.svg'
import AionIcon from '../../../Images/aion-bank-icon.png'
import BofAIcon from '../../../Images/bofa-icon.png'
import ChaseIcon from '../../../Images/chase-icon.png'
import ModalClose from '../../../Images/modal-close.png'
import Delete from '../../../Images/delete-red-icon.png'
import ChevronDown from "../../../Images/chevron-down.png"
import ChevronUp from "../../../Images/chevron-up.png"
import Individual from '../../../Images/individual-recipient.png'
import Business from '../../../Images/business-recipient.png'

// Actions
import { addressObjectToMultiLineStr, getUserApps } from '../../../Utils/util'
import TableView from '../../Reusable/TableView'

const { TabPane } = Tabs

class CustomerDetails extends Component {

    constructor(props) {
        super(props)

        var ABLUser = false

        const userApps = getUserApps(this.props.aionStore)

        if (userApps && userApps.includes("ABLCredit")) {
            ABLUser = true
        }

        this.state = {
            msasLoading: true,
            showMSAModal: false,
            pagination: {
                current: 1,
                pageSize: 100,
                showSizeChanger: false,
            },
            sorter: {},
            ABLUser: ABLUser,
            dashboardLoading: true,
            loading: false,
            selectedTab: 'details',
            transferMethods: [],
        }
    }

    componentDidMount() {
        if (this.props.location.state && this.props.location.state.recipient) {
            const recipient = this.props.location.state.recipient
            this.setState({ recipient })

            console.log('recipient recipient', recipient)

            this.getPending(recipient.id)
            this.getPaymentMethods(recipient.objectId)

            if (recipient.status === 'pending_approval') {
                this.getApprovalRuleInstance(recipient.approvalInstanceId)
            }
        }
    }

    getPending = (recipientId) => {
        this.setState({ loadingPending: true })
        fetchBBHistory({ viewBy: "CP_PAYMENTS", recipientId }, (err, resp) => {
            if (resp) {
                console.log("fetchBBHistory recipient details: ", { viewBy: "CP_PAYMENTS", recipientId }, resp)
                this.setState({ loadingPending: false, pending: normalizeAndSortHistory(resp).normalizedData })
            } else {
                this.setState({ loadingPending: false })
            }
        })
    }

    getPaymentMethods = (id) => {
        this.setState({ paymentMethodsLoading: true })
        console.log("bb/getPaymentMethods id", id)
        apiPOSTReq(`${environment.bbBaseUrl}/bb/getPaymentMethods`, null, { objectId: id }, (err, resp) => {
            try {
                const data = resp

                if (data.result) {
                    if (err) throw new Error(err)
                    console.log("bb/getPaymentMethods", data.paymentMethods)
                    this.setState({ transferMethods: data.paymentMethods })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getAccounts", error, err, resp)
            } finally {
                this.setState({ paymentMethodsLoading: false })
            }
        })
    }

    getApprovalRuleInstance = (approvalInstanceId) => {
        apiPOSTReq(`${environment.bbBaseUrl}/bb/approvals/getApprovalInstance `, { "BankProvider": environment.bankProvider.crb }, { instanceId: approvalInstanceId, }, (err, resp) => {
            try {
                const data = resp;
                console.log("/bb/approvals/getApprovalInstance ", data)
                if (data.result && data.approvalRuleInstance) {
                    this.setState({ approvalRuleInstance: data.approvalRuleInstance })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /bb/approvals/getApprovalInstance ", error, err, resp);
                ErrorAlert({ description: error.message })
            }
        })
    }

    getBankIcon = (bank) => {
        if (bank === "Bank of America") {
            return BofAIcon
        } else if (bank === "Chase") {
            return ChaseIcon
        } else {
            return AionIcon
        }
    }

    getRowDetails = (record, props) => {
        var { bankDetail, recipient, remittanceInfo } = record
        bankDetail = bankDetail || {}

        const { accountNumber, routingNumber, accountType, checkDeliveryType, addressOnAccount } = bankDetail

        return (
            <LightContainer fullWidth>
                <Flex start fullWidth wrap gap='12px'>
                    {
                        accountNumber &&
                        <LabeledInput
                            label='Account Number'
                            nomargin
                            type='read-only'
                            value={accountNumber}
                            width='248px'
                        />
                    }

                    {
                        routingNumber &&
                        <LabeledInput
                            label='Routing Number'
                            nomargin
                            type='read-only'
                            value={routingNumber}
                            width='248px'
                        />
                    }

                    {
                        accountType &&
                        <LabeledInput
                            label='Account Type'
                            nomargin
                            type='read-only'
                            value={accountType}
                            width='248px'
                        />
                    }

                    {
                        checkDeliveryType &&
                        <LabeledInput
                            label='Delivery Type'
                            nomargin
                            type='read-only'
                            value={checkDeliveryType == 'PostalMail' ? 'Postal Mail' : checkDeliveryType}
                            width='248px'
                        />
                    }

                    {
                        checkDeliveryType && addressOnAccount &&
                        <LabeledInput
                            label='Delivery Address'
                            nomargin
                            type='read-only'
                            value={addressObjectToMultiLineStr(addressOnAccount)}
                            width='248px'
                        />
                    }

                    {
                        (checkDeliveryType == 'Email') &&
                        <LabeledInput
                            label='Delivery Address'
                            nomargin
                            type='read-only'
                            value={recipient.email}
                            width='248px'
                        />
                    }
                    {
                        remittanceInfo &&
                        <LabeledInput type='read-only' label="Remittance Information" value={remittanceInfo} width='568px' />
                    }
                </Flex>
            </LightContainer>
        )
    }

    render() {
        var { recipient, showTransferModal, selectedTab, approvalRuleInstance, showDeactivateModal, showDeactivateTMModal, showActivateModal, transferMethods, pending, loadingPending, paymentMethodsLoading, selectedRowId, deactivateTm, editTm } = this.state
        recipient = recipient || {}
        var { theme } = this.props
        const { nameOnAccount, nickName } = this.props.location.state.recipient
        var instantPaymentsEnabled = getFeaturePerm("BusinessBanking.Payments.Instant Transfer")
        var columns = [
            {
                title: 'Name',
                dataIndex: 'nickName',
                key: 'nickName',
                render: (nickName, transferMethod) => <Flex start gap='8px'>
                    {renderBankIcon(transferMethod.bankDetail, '24px')}
                    <Text>{nickName}</Text>
                    {
                        (!transferMethod.active) &&
                        <Tag alert>Inactive</Tag>
                    }
                </Flex>
            },
            
            {
                dataIndex: 'type',
                key: 'type',
                align: 'right',
                width: '100px',
                render: (type, transferMethod) => <Flex end gap='8px'>
                    <Tag bordered primary>{type}</Tag>
                    {
                        transferMethod.bankDetail.accountNumber &&
                        <Tag>{transferMethod.bankDetail.accountNumber && transferMethod.bankDetail.accountNumber.substring(transferMethod.bankDetail.accountNumber.length - 4, transferMethod.bankDetail.accountNumber.length)}</Tag>
                    }
                </Flex>
            },
            {
                dataIndex: 'action',
                key: 'action',
                align: 'left',
                width: '50px',
                onHover: true,
                render: (type, transferMethod) => <Flex end centerHorizontally gap='8px'>
                        {
                            (transferMethod.active) &&
                            <>
                                <ImageButton src={Edit} onClick={(e) => {
                                    this.setState({ showTransferModal: true, editTm: transferMethod, moreDropdownVisible: false })
                                    e.stopPropagation()
                                }} />
                                <ImageButton src={Delete} color='#E52D2D' onClick={(e) => {
                                    this.setState({ showDeactivateTMModal: true, deactivateTm: transferMethod, moreDropdownVisible: false })
                                    e.stopPropagation()
                                }} />
                            </>
                        }
                    </Flex>
            },
        ]
        return (
            <FlexColumn className='main-padding' style={{ width: '100%', marginBottom: '48px' }} start>
                <PageHeader
                    titleText={recipient.nickName ? `${recipient.nickName} (${nameOnAccount})` : nameOnAccount}
                    titleTag={recipient.active === true ?
                        { text: 'Active', color: '#318F2F', background: '#F5F9F5' }
                        :
                        { text: 'Inactive' }
                    }
                    back
                    marginBottom='24px'
                />

                <Tabs size="large">
                    <TabPane tab="Details" key="Details">
                        <FlexColumn start gap='24px' style={{ marginTop: 12 }}>
                            <CardContainer fullWidth>
                                <FlexColumn gap='24px'>
                                    <Flex between centerHorizontally gap='24px'>
                                        <Text margin='0' weight={500} size='18px' color={theme.colors.primary2}>Recipient</Text>
                                        {
                                            recipient.active ?
                                            <Flex between centerHorizontally gap='4px'>
                                                <ImageButton
                                                    src={Edit}
                                                    onClick={() => {
                                                        this.props.history.push({
                                                            pathname: '/banking/recipients/edit',
                                                            state: { recipient }
                                                        })
                                                    }} 
                                                />
                                                <ImageButton src={Delete} onClick={() => this.setState({ showDeactivateModal: true })} />
                                            </Flex>
                                            :
                                            <TextButton color='#318F2F' text="ACTIVATE" onClick={() => this.setState({ showActivateModal: true })} />
                                        }
                                        
                                    </Flex>
                                    

                                    <Flex start wrap gap='24px'>
                                        <LabeledInput nomargin label='Name' type='read-only' value={recipient.nameOnAccount || "--"} width='432px' />
                                        <LabeledInput nomargin label='Nickname' type='read-only' value={recipient.nickName || "--"} width='432px' />
                                        <LabeledInput nomargin label='Type' type='read-only' value={recipient.type || "--"} width='432px' />
                                        <LabeledInput nomargin label='Email' type='read-only' value={recipient.email || "--"} width='432px' />
                                    </Flex>
                                </FlexColumn>
                            </CardContainer>

                            <TableView
                                id="transfer-methods"
                                titleText="Bank details"
                                descText={`Bank details for this recipient. You can add multiple transfer methods ${`(i.e. ${instantPaymentsEnabled.manage ? 'Instant, ' : ''}ACH, Wire)`} for each of your recipients`}
                                className='borderless-table'
                                tableLayout='auto'
                                columns={columns}
                                dataSource={transferMethods.sort((a, b) => b.active - a.active)}
                                rowKey='id'
                                pagination={false}
                                expand
                                expandedRowKey={selectedRowId}
                                getRowDetails={(record) => this.getRowDetails({ ...record, recipient: recipient }, this.props)} 
                                onRow={
                                    (record, rowIndex) => {
                                        return {
                                            onClick: e => {
                                                if(record.id == selectedRowId) {
                                                    this.setState({ selectedRowId: null })
                                                } else {
                                                    this.setState({ selectedRowId: record.id })
                                                }
                                            }, // click row
                                        }
                                    }
                                }
                                ctaContent={
                                    recipient.active &&
                                    <Button solid uppercase loading={paymentMethodsLoading} onClick={() => this.setState({ showTransferModal: true })} text={'Add Transfer Method'.toUpperCase()} />
                                }
                            />
                        </FlexColumn>
                    </TabPane>

                    {
                        <TabPane tab="Activity" key="Activity"  style={{ marginTop: 12 }}>
                            <FlexColumn start gap='24px'>
                                <PendingTable
                                    title={`Transfers`}
                                    subtitle={`Transfer activity to ${nameOnAccount}`}
                                    loadingPending={loadingPending}
                                    pendingTransfers={pending}
                                    collapsible={false} 
                                    onCancelled={() => {
                                        this.getPending(this.state.recipient?.id)
                                    }}
                                    ctaContent={(recipient.active === true && recipient.status !== "pending_approval") &&
                                        <Button permtype="BusinessBanking.Transactions" solid text='TRANSFER' onClick={() => this.props.history.push({
                                            pathname: '/banking/transfer-to-recipient',
                                            state: { recipient }
                                        })} />
                                    }
                                />
                            </FlexColumn>
                        </TabPane>
                    }

                    
                </Tabs>

                <Modal
                    visible={showDeactivateModal}
                    footer={null}
                    closable={false}
                    width={456}
                    destroyOnClose={true}
                    onCancel={() => {
                        this.setState({ showDeactivateModal: false })
                    }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <Deactivate
                        recipient={recipient}
                        onCancel={() => this.setState({ showDeactivateModal: false })}
                        onDeactivated={() => {
                            this.setState({ showDeactivateModal: false })
                            window.history.back()
                        }} 
                    />
                </Modal>
                
                <Modal
                    visible={showDeactivateTMModal}
                    footer={null}
                    closable={false}
                    width={456}
                    destroyOnClose={true}
                    onCancel={() => {
                        this.setState({ showDeactivateTMModal: false })
                    }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <DeactivateTransferMethod
                        transferMethod={deactivateTm}
                        onCancel={() => this.setState({ showDeactivateTMModal: false })}
                        onDeactivated={() => {
                            this.setState({ showDeactivateTMModal: false, loading: true })
                            this.getPaymentMethods(recipient.objectId)
                        }} />
                </Modal>

                <Modal
                    visible={showActivateModal}
                    footer={null}
                    closable={false}
                    width={456}
                    destroyOnClose={true}
                    onCancel={() => {
                        this.setState({ showActivateModal: false })
                    }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <Activate
                        recipient={recipient}
                        onCancel={() => this.setState({ showActivateModal: false })}
                        onActivated={() => {
                            this.setState({ showActivateModal: false })
                            window.history.back()
                        }}
                    />
                </Modal>

                <Modal
                    visible={showTransferModal}
                    width={616}
                    closable={true}
                    maskClosable={true}
                    style={{ borderRadius: '8px !important' }}
                    footer={null}
                    destroyOnClose={true}
                    onCancel={() => this.setState({ showTransferModal: false, transferType: null, editTm: null })}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <AddTransferModal
                        addTransfer={true}
                        transferMethodRequests={transferMethods}
                        transferMethod={editTm}
                        recipientId={recipient.objectId}
                        recipientEmail={recipient.email}
                        onConfirm={(body, transferMethod) => {
                            this.setState({ showTransferModal: false, transferType: null, editTm: null }, () => this.getPaymentMethods(recipient.objectId))
                        }}
                        onDiscard={() => this.setState({ showTransferModal: false, transferType: null, editTm: null })}
                        visible={showTransferModal}
                        msas={[]}
                    />
                </Modal>
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(CustomerDetails)))