import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled, { withTheme } from 'styled-components'
import { Layout, Affix, Modal, Badge } from 'antd'
import queryString from 'query-string'

import { Flex, FlexColumn } from '../Reusable/Container'
import { Text } from '../Reusable/Text'
import { TextButton } from '../Reusable/Button'
import { Image } from '../Reusable/Image'
import AionLogo from '../Reusable/Image'

// Images
import SetupTabIcon from "../../Images/setup-tab.png"
import SupportConsoleTabIcon from "../../Images/support-icon/light.png"
import ApprovalsActiveIcon from "../../Images/product-menu/approvals-menu-icon-active.png"
import ApprovalsInactiveIcon from "../../Images/product-menu/approvals-menu-icon.png"
import BankingActiveIcon from "../../Images/product-menu/bank-menu-icon-active.png"
import BankingInactiveIcon from "../../Images/product-menu/bank-menu-icon.png"
import ContractorsActiveIcon from "../../Images/product-menu/contractors-menu-icon-active.png"
import ContractorsInactiveIcon from "../../Images/product-menu/contractors-menu-icon.png"
import PayablesActiveIcon from "../../Images/product-menu/payables-menu-icon-active.png"
import PayablesInactiveIcon from "../../Images/product-menu/payables-menu-icon.png"
import ReceivablesActiveIcon from "../../Images/product-menu/receivables-menu-icon-active.png"
import ReceivablesInactiveIcon from "../../Images/product-menu/receivables-menu-icon.png"
import ApplyActiveIcon from "../../Images/checklist/checklist-icon-active.png"
import ApplyInactiveIcon from "../../Images/checklist/checklist-icon.png"
import RLOCActiveIcon from "../../Images/rloc-active.png"
import RLOCInactiveIcon from "../../Images/rloc-inactive.png"
import STCActiveIcon from "../../Images/stc-active.png"
import STCInactiveIcon from "../../Images/stc-inactive.png"
import CardsActiveIcon from "../../Images/product-menu/CardsActive.png"
import CardsInactiveIcon from "../../Images/product-menu/CardsInactive.png"
import AionText from "../../Images/aion-logo-text.png"
import Refresh from "../../Images/integration/sync-48.png"
import DividerActive from "../../Images/product-menu/nav-divider-active.png"
import Divider from "../../Images/product-menu/nav-divider.png"
import Lock from "../../Images/lock.svg"
import LockSelected from "../../Images/lock-selected.svg"
import ChevronUp from "../../Images/chevron-up.png"
import ChevronDown from "../../Images/chevron-down.png"
import ArrowRight from "../../Images/arrow-right.png"

import { capitalizeWords, getUserApps, removeArrItem, getResourcePerm, getInitials, getUserRoles, isSmallScreen, camelCaseToPascalCase } from '../../Utils/util'
import { apiPOSTReq, doSignout } from '../../Utils/api'
import { getNewMenuData } from '../../Utils/menuUtil'
import { StyledExtLink } from '../Reusable/Link'
import SwitchBusiness from '../Reusable/SwitchBusiness'
import BusinessLogo from '../Reusable/BusinessLogo'
import { addDataToStore, SAVE_DATA, ONB_SAVE_DATA, UAM_SAVE_BUSINESS } from '../../Actions/actions';

import Capital from "../../Images/capital-plus.png"
import CreditPlus from '../OnboardingFlowCoordinator/CreditOnboarding/Prescreen/CreditPlus'
import { cloneDeep } from 'lodash'
import environment from '../../environment'

const { Sider } = Layout

const Menu = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 40px;
    user-select: none;
    -webkit-user-select: none;
    &.selected {
    };
`

const MenuItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: 'InterDisplay';
    font-style: normal;
    font-weight: 400;
    width: 100%;
    height: 40px;
    font-size: 14px;
    line-height: 24px;
    margin-top: 0;
    color: #7384AA;
    cursor: pointer;
    white-space: nowrap;
    padding: 12px 15px 12px 36px;
    user-select: none;
    -webkit-user-select: none;
    &.selected {
        color: ${props => props.theme.button.navSelected};
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        background: #F6F6F6;
        border-radius: 8px;
    };
`

const SubMenu = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 0;
    width: 100%;
    user-select: none;
    -webkit-user-select: none;
    &.selected {
        background: #F8F8F8;
    };
`

const SubTitle = styled.span`
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    font-family: 'InterDisplay';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    height: 54px;
    text-transform: uppercase;
    color: black;
`

const SubMenuItem = styled.span`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: 'InterDisplay';
    font-style: normal;
    font-weight: 400;
    width: 100%;
    height: 40px;
    font-size: 16px;
    line-height: 24px;
    padding-left: 24px;
    padding-right: 15px;
    color: #7384AA;
    cursor: pointer;
    white-space: nowrap;
    margin-left: 0;
    user-select: none;
    -webkit-user-select: none;
    &.selected {
        color: ${props => props.theme.button.primary2};
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        background: #F6F6F6;
        border-radius: 8px;
    };
`

const SubMenuTitleItem = styled.span`
    margin: 2px 30px 0 65px;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 0.9rem;
    color: ${props => props.colorScheme && props.colorScheme.itemColor || "#9CA6AA"};
    background: ${props => props.colorScheme && props.colorScheme.itemBackground || "white"};
`

class NavMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hideSetting: false,
            showARUploadResult: false,
            showInitExternalBankModal: false,
            // collapsed: true,
            // selected: "banking",
            pinned: true,
            collapsedSubmenus: [],
            showCreditOnboarding: props.openCreditPlus
        }
        this.intervalTmr = null
    }

    componentDidMount() {
        let selected = window.location.pathname.substring(0, window.location.pathname.indexOf("/", 1)).replace('/', '')
        this.setState({ selected })

        // This is mainly utilized on switch business
        this.fetchBusinessData()
    }

    // A menu item is clicked save it to state and load the associated link
    menuItemClicked(item) {
        this.props.history.push(item.path)
    }

    isSubMenuSelected(subMenu) {
        return subMenu.some(item => item.path === this.props.location.pathname)
    }

    tabClicked(product) {
        let { selected } = this.state
        const cardsPerm = getResourcePerm("BusinessCards.Cards", { menuPerm: true })

        if (product === 'apply') {
            this.props.history.push('/apply')
        } else if (product === 'cards' && !cardsPerm.manage) {
            this.props.history.push('/cards/my-cards')
        } else {
            this.setState({ selected: product === selected ? null : product })
        }
        // this.setState({ selected: this.state.selected === product ? null : product })    collapse on click
    }

    signout = () => {
        doSignout(this.props.history, this.props.dispatch)
    }

    onPinnedChange = (pinned) => {
        this.setState({ pinned })
        this.props.onPinnedChange(pinned)
        this.props.dispatch(addDataToStore(SAVE_DATA, { Pinned: pinned }));
    }

    getCustomMenuItemComponent = (item) => {
        if (item.key == "switchBusiness") {
            return (
                <Flex gap="8px">
                    <Image src={item.icon} />
                    <StyledExtLink style={{ textAlign: "center", fontSize: "16px", lineHeight: "24px", textDecoration: "underline" }} onClick={() => this.setState({ showSwitchBizModal: true })}>Switch Business</StyledExtLink>
                </Flex>
            )
        }
    }

    closeCreditPlus = () => {
        this.setState({ showCreditOnboarding: false });
    }

    fetchBusinessData(options, callback) {
        options = options || {};
        apiPOSTReq(`${environment.uamBaseUrl}/getBusiness`, {}, {}, (err, resp) => {
            try {
                if (err) throw Error(err);
                const data = resp || {};
                if (data.result) {
                    this.props.dispatch(addDataToStore(UAM_SAVE_BUSINESS, data))
                    this.props.dispatch(addDataToStore(SAVE_DATA, { Profile: camelCaseToPascalCase(cloneDeep(data.businessProfile)) }))
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/getBusiness err", error, resp)
            }
        });
    }

    render() {
        let { location, theme, bankingStore, userDefaults, aionStore, approvalNotificationCount, showCreditPlus } = this.props
        var { Profile, UserInfo, linkedBusinessList, business } = aionStore
        var { businessInfo } = business.businessProfile || {}
        businessInfo = businessInfo || {}
        linkedBusinessList = linkedBusinessList || []

        const { collapsed, selected, pinned } = this.state
        var Attributes = aionStore.Attributes || {}
        const Applications = bankingStore.Applications || []
        const bbAttributes = Attributes.BankingAttributes || {}
        const menuData = getNewMenuData(this.props)

        const paymentsPerm = getResourcePerm("BusinessBanking.Payments")
        const recipientsPerm = getResourcePerm("BusinessBanking.Recipients")
        const payablesPerm = getResourcePerm("Payables.Recipients")
        const advPerm = getResourcePerm("ARCredit.Advances")
        const invAdvPerm = getResourcePerm("ARCredit.Inventory Advances")
        const billPaymentsPerm = getResourcePerm("Payables.Bill Payments")
        const contractorPaymentsPerm = getResourcePerm("Payables.Contractor Payments")
        const userMgmtPerm = getResourcePerm("UserMgmt.UserMgmt")
        const params = queryString.parse(this.props.location.search)
        const allowedApps = getUserApps(aionStore)
        const userRoleNames = getUserRoles(aionStore);
        const { UAM, businessSubscriptionPlan, OnboardingType } = aionStore
        const { currentPlan } = businessSubscriptionPlan || {}
        var showCredit = false

        var tabsToDisplay = ["settings"]
        let { pathname } = location
        let tabName = pathname.split("/").length > 1 ? pathname.split("/")[1] : ''

        if (bbAttributes.ApplicationApproved) {
            if (allowedApps.includes("BusinessBanking")) tabsToDisplay.push("banking")
        }
        if (allowedApps.includes("BusinessCards")) tabsToDisplay.push("cards")
        if (allowedApps.includes("Receivables")) tabsToDisplay.push("receivables")
        if (allowedApps.includes("ARCredit")) tabsToDisplay.push("ar_credit")
        if (allowedApps.includes("ABLCredit")) tabsToDisplay.push("abl_credit")
        if (allowedApps.includes("Payables")) tabsToDisplay.push("payables")
        if (allowedApps.includes("Payables")) tabsToDisplay.push("contractor_payroll")
        // if (allowedApps.includes("Bookkeeping")) tabsToDisplay.push("bookkeeping")
        if (allowedApps.includes("TermLoans")) tabsToDisplay.push("term_loans")
        if (paymentsPerm.approve || recipientsPerm.approve || billPaymentsPerm.approve || contractorPaymentsPerm.approve) tabsToDisplay.push("approvals")
        showCredit = ["Essentials", "Enterprise", "Growth"].includes(currentPlan) // Show credit onboarding for only these plans
        if (!bbAttributes.ApplicationApproved) tabsToDisplay.splice(0, 0, "apply")
        const creditOnboardingApproved = allowedApps.includes("ARCredit") || allowedApps.includes("ABLCredit") || allowedApps.includes("TermLoans")
        const onboardingApproved = bbAttributes.ApplicationApproved || creditOnboardingApproved
        // Show apply credit tab if AR Credit or Term Loans do not exist
        // Commented out for now as we only want to allow this by CreditAppEnabled
        // if (!(tabsToDisplay.includes("term_loans") || tabsToDisplay.includes("ar_credit") || tabsToDisplay.includes("abl_credit")) && showCredit && onboardingApproved && !UAM.aionUnderWriterUser) tabsToDisplay.splice(0, 0, "apply_credit")

        if (UAM.aionCustomerSupportUser) tabsToDisplay.push("support")
        if (UAM.aionUnderWriterUser) tabsToDisplay.push("underwriting")

        const cardsPerm = getResourcePerm("BusinessCards.Cards", { menuPerm: true })

        var sigStyle = { maxWidth: "100vw", top: 0 }
        if (isSmallScreen) sigStyle["zoom"] = "1.16"

        const TabItem = (options) => {
            const { collapsed, selected, pinned, collapsedSubmenus } = this.state
            const { icon, inactiveIcon, data, label, name } = options
            let pathTab = options.path.split("/").length > 1 ? options.path.split("/")[1] : ''
            // if (options.name == "settings") console.log("TabItem", data)
            let active = (tabName == pathTab) || (pathTab == '/credit' && params.state == 'qb-credit-eligibility') ? "selected" : ""
            if (!tabsToDisplay.includes(options.name) || (options.name !== 'apply' && (data || []).length == 0)) return null

            const items = data ? data.map((item, i) => {
                var { subtitle } = item
                var itemSelected = location.pathname.includes(item.path)

                return (
                    subtitle || item.subMenu ?
                        <SubMenu className={itemSelected ? "selected" : ""} key={`menu-${i}`}>
                            <MenuItem
                                key={item.key}
                                className={itemSelected ? "selected" : ""}
                                onClick={() => {
                                    if (collapsedSubmenus.includes(subtitle)) {
                                        this.setState({ collapsedSubmenus: collapsedSubmenus.filter(item => item !== subtitle) })
                                    } else {
                                        collapsedSubmenus.push(subtitle)
                                        this.setState({ collapsedSubmenus })
                                    }
                                }}
                                colorScheme={data.colorScheme}>
                                <Flex style={{ width: '100%' }} centerHorizontally between>
                                    <Flex start centerHorizontally>
                                        {/* <img src={itemSelected ? DividerActive : Divider} width='1.5px' height='22px' style={{ marginRight: "21.25px" }} /> */}
                                        {subtitle}
                                        {
                                            (approvalNotificationCount || 0) > 0 && item.title === 'Pending' &&
                                            <Badge style={{ marginLeft: 0, marginTop: 0, marginLeft: 8 }} count={approvalNotificationCount} />
                                        }
                                    </Flex>
                                    {
                                        !collapsedSubmenus.includes(subtitle) ?
                                            <Image src={ChevronUp} />
                                            :
                                            <Image src={ChevronDown} />
                                    }
                                </Flex>
                            </MenuItem>
                            {
                                !collapsedSubmenus.includes(subtitle) && // this.isSubMenuOpen(item.items) &&
                                <SubMenu key={`${subtitle}-submenu`}>
                                    {
                                        item.items.map(subItem => {
                                            var subItemSelected = location.pathname.includes(subItem.path)

                                            return (
                                                <SubMenuItem
                                                    key={subItem.key}
                                                    onClick={this.menuItemClicked.bind(this, subItem)}
                                                    className={subItemSelected ? "selected" : ""}
                                                    colorScheme={data.colorScheme}>
                                                    <Flex fullWidth centerHorizontally between style={{ marginLeft: 27.25 }}>
                                                        <Flex start centerHorizontally>
                                                            <img src={itemSelected ? DividerActive : Divider} width='1.5px' height='22px' style={{ marginRight: "45.25px" }} />
                                                            {subItem.title}
                                                        </Flex>
                                                        {
                                                            // subItemSelected &&
                                                            // <Image src={ArrowRight} />
                                                        }
                                                    </Flex>
                                                </SubMenuItem>
                                            )
                                        })
                                    }
                                </SubMenu>
                            }
                        </SubMenu>
                        // <SubMenu key={`menu-${i}`}>
                        //     {
                        //         item.subtitle && item.subtitle !== " " &&
                        //         <SubMenuTitleItem
                        //             key={item.subtitle}
                        //             className={itemSelected ? "selected" : ""}
                        //             colorScheme={data.colorScheme}
                        //         >
                        //             <Flex centerHorizontally style={{ justifyContent: "space-between" }}>
                        //                 <MenuItem>{item.subtitle}</MenuItem>
                        //             </Flex>
                        //         </SubMenuTitleItem>
                        //     }
                        //     {
                        //         item.open === true && // this.isSubMenuOpen(item.items) &&
                        //         <SubMenu key={`${item.subtitle}-submenu`}>
                        //             {
                        //                 item.items.map(subItem => {
                        //                     var subItemSelected = location.pathname.includes(subItem.path)

                        //                     return (
                        //                         <SubMenuItem
                        //                             key={subItem.key}
                        //                             onClick={this.menuItemClicked.bind(this, subItem)}
                        //                             className={subItemSelected ? "selected" : ""}
                        //                             colorScheme={data.colorScheme}>
                        //                             <Flex style={{ width: '75%' }} centerHorizontally between>
                        //                                 {subItem.title}
                        //                                 {
                        //                                     subItemSelected &&
                        //                                     <Image src={ArrowRight} />
                        //                                 }
                        //                             </Flex>
                        //                         </SubMenuItem>
                        //                     )
                        //                 })
                        //             }
                        //         </SubMenu>
                        //     }
                        // </SubMenu>
                        :
                        <Menu className={itemSelected ? "selected" : ""} key={`menu-${i}`}>
                            <MenuItem
                                key={item.key}
                                className={itemSelected ? "selected" : ""}
                                onClick={this.menuItemClicked.bind(this, item)}
                                colorScheme={data.colorScheme}>
                                {
                                    item.type == "custom" ?
                                        this.getCustomMenuItemComponent(item)
                                        :
                                        <Flex style={{ width: '100%' }} centerHorizontally between>
                                            <Flex start centerHorizontally>
                                                {/* <img src={itemSelected ? DividerActive : Divider} width='1.5px' height='22px' style={{ marginRight: "21.25px" }} /> */}
                                                {item.title}
                                                {
                                                    (approvalNotificationCount || 0) > 0 && item.title === 'Pending' &&
                                                    <Badge style={{ marginLeft: 0, marginTop: 0, marginLeft: 8 }} count={approvalNotificationCount} />
                                                }
                                            </Flex>
                                            {
                                                // itemSelected &&
                                                // <Image src={ArrowRight} />
                                            }
                                        </Flex>
                                }
                            </MenuItem>
                        </Menu>
                )
            }) : []

            return (!pinned && collapsed ?
                <Flex
                    onClick={() => this.tabClicked(label)} // options.disabled ? {} : this.tabClicked(label)}
                    // onMouseOver={() => {
                    //     if (collapsed) {
                    //         this.setState({ collapsed: false })
                    //     }
                    // }}
                    style={{ width: 88, minHeight: 56, paddingLeft: 12 }}
                    start
                    centerHorizontally>
                    {
                        active ?
                            <Flex center className='nav-menu-item-active' onMouseOver={() => {
                                if (collapsed) {
                                    this.setState({ collapsed: false, selected: label })
                                }
                            }}>
                                {active ? icon : inactiveIcon}
                            </Flex>
                            :
                            <Flex center className='nav-menu-item' onMouseOver={() => {
                                if (collapsed) {
                                    this.setState({ collapsed: false, selected: label })
                                }
                            }}>
                                {active ? icon : inactiveIcon}
                            </Flex>
                    }
                </Flex>
                :
                <FlexColumn start className={(selected === label && name !== 'settings') ? 'nav-menu-container-selected' : 'nav-menu-container'}>
                    <Flex
                        onClick={() => name !== 'settings' && this.tabClicked(label)}
                        style={{ width: '100%', minHeight: 56, padding: '12px 12px 12px 0', cursor: name !== 'settings' && 'pointer' }}
                        between
                        centerHorizontally
                    >
                        {
                            selected === label ?
                                <Flex start centerHorizontally>
                                    <div width='24px'>
                                        {active ? icon : inactiveIcon}
                                    </div>
                                    <Text color={active ? '#333' : '#333'} weight={active && '600'} margin='0 0 0 12px' noSelect>{capitalizeWords(options.label === 'payables' ? 'bill payments' : options.label)}</Text>
                                </Flex>
                                :
                                <Flex start centerHorizontally>
                                    <div width='24px'>
                                        {active ? icon : inactiveIcon}
                                    </div>
                                    <Text size='14px' color={active ? '#333' : '#333'} weight={active && '600'} margin='0 0 0 12px' noSelect>{capitalizeWords(options.label === 'payables' ? 'bill payments' : options.label)}</Text>
                                    {
                                        (approvalNotificationCount || 0) > 0 && options.label.toLowerCase() === 'approvals' &&
                                        <Badge style={{ marginLeft: 0, marginTop: 0, marginLeft: 8 }} count={approvalNotificationCount} />
                                    }
                                </Flex>
                        }
                        {
                            ['apply'].includes(options.label) || (['cards'].includes(options.label) && !cardsPerm.manage) &&
                            <Image src={ArrowRight} />
                        }

                        {
                            (name !== 'settings' && (options.label !== 'cards' || cardsPerm.manage) && (data || []).length > 0) &&
                            <>
                                {
                                    selected === label ?
                                        <Image src={ChevronUp} />
                                        :
                                        <Image src={ChevronDown} />
                                }
                            </>
                        }
                    </Flex>
                    {
                        (((options.label !== 'cards' || cardsPerm.manage) && // Handle Cards -> Your cards only case
                            selected === label) || (name === 'settings')) && items
                    }
                </FlexColumn>
            )
        }

        return (
            <> <Affix offsetTop={0} offsetBottom={0} style={{ top: 0, bottom: 0, position: "absolute" }}>
                <div
                    className={pinned ? 'nav-menu-pinned' : collapsed ? 'nav-menu-collapsed' : 'nav-menu'}
                    collapsed={!pinned && collapsed}
                    collapsedWidth="88px"
                    style={{ height: '100%' }}
                    width={300}
                    trigger={<img height={32} width={32} src={Refresh} />}
                    onMouseLeave={() => {
                        if (!collapsed && !pinned) {
                            this.setState({ collapsed: true, selected: null })
                        }
                    }}
                >
                    <div style={{ position: "sticky", top: 0, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', paddingBottom: 12, overflowY: 'auto' }}>
                        {/* <FlexColumn start style={{ height: '100%' }} > */}
                        <Flex between style={{ padding: '0 16px 4px 10px', width: '100%' }} onMouseOver={() => {
                            if (collapsed) {
                                this.setState({ collapsed: false })
                            }
                        }}>
                            <Flex start style={{ margin: '24px 28px 12px 16px' }}>
                                <Image src={this.props.theme.logo.symbol} />
                                {
                                    (pinned || !collapsed) &&
                                    <img style={{ marginLeft: 8 }} height={17} width={36} src={AionText} />
                                }
                            </Flex>
                            {
                                (pinned || !collapsed) &&
                                <img style={{ cursor: 'pointer', margin: 20 }} onClick={() => this.onPinnedChange(!pinned)} height={20} width={20} src={pinned ? LockSelected : Lock} />
                            }
                        </Flex>
                        {(!bbAttributes.ApplicationApproved && bbAttributes.ForceCRBAccountOpening) && creditOnboardingApproved && <TabItem data={menuData.menuItems.apply} label="apply for banking" name="apply for banking" path="/apply" icon={<Image src={ApplyActiveIcon} />} inactiveIcon={<Image src={ApplyInactiveIcon} />} />}
                        {(OnboardingType == "credit") && !creditOnboardingApproved && <TabItem data={menuData.menuItems.apply} label="apply" name="apply" path="/apply/credit" icon={<Image src={ApplyActiveIcon} />} inactiveIcon={<Image src={ApplyInactiveIcon} />} />}
                        <TabItem data={menuData.menuItems.banking}/*disabled={AccountLocked || AccountClosed}*/ label="banking" name="banking" path="/banking/home" icon={<Image src={BankingActiveIcon} />} inactiveIcon={<Image src={BankingInactiveIcon} />} />
                        <TabItem data={menuData.menuItems.cards}/*disabled={AccountLocked || AccountClosed}*/ label="cards" name="cards" path="/cards" icon={<Image src={CardsActiveIcon} />} inactiveIcon={<Image src={CardsInactiveIcon} />} />
                        {onboardingApproved &&
                            <>
                                <TabItem data={menuData.menuItems.receivables} label="receivables" name="receivables" path="/receivables/invoices" icon={<Image src={ReceivablesActiveIcon} />} inactiveIcon={<Image src={ReceivablesInactiveIcon} />} />
                                <TabItem data={menuData.menuItems.payables} label="payables" name="payables" path="/payables/inbox" icon={<Image src={PayablesActiveIcon} />} inactiveIcon={<Image src={PayablesInactiveIcon} />} />
                                {/* <TabItem data={menuData.menuItems.contractorPayroll} label="contractor payroll" name="contractor_payroll" path="/contractor-payroll/contractors" icon={<Image src={ContractorsActiveIcon} />} inactiveIcon={<Image src={ContractorsInactiveIcon} />} /> */}
                            </>}

                        {
                            !(advPerm.view || advPerm.manage) && (invAdvPerm.view || invAdvPerm.manage) ?
                                <TabItem data={menuData.menuItems.arCredit} label="credit" name="ar_credit" path="/credit/inventory-loc/advances" icon={<Image src={RLOCActiveIcon} />} inactiveIcon={<Image src={RLOCInactiveIcon} />} />
                                :
                                (advPerm.view || advPerm.manage) && <TabItem data={menuData.menuItems.arCredit} label="credit" name="ar_credit" path="/credit/ar-credit/advances" icon={<Image src={RLOCActiveIcon} />} inactiveIcon={<Image src={RLOCInactiveIcon} />} />
                        }
                        <TabItem data={menuData.menuItems.ablCredit} label="credit" name="abl_credit" path="/credit/abl-credit/advances" icon={<Image src={RLOCActiveIcon} />} inactiveIcon={<Image src={RLOCInactiveIcon} />} />
                        {/* <TabItem label="bookkeeping" name="bookkeeping" path="/bookkeeping/overview" icon={BKTabIcon} /> */}
                        <TabItem label="credit" name="apply_credit" path="/credit/apply" icon={<Image src={RLOCActiveIcon} />} inactiveIcon={<Image src={RLOCInactiveIcon} />} />
                        <TabItem data={menuData.menuItems.termLoans} label="term loans" name="term_loans" path="/credit/term-loans/advances" icon={<Image src={STCActiveIcon} />} inactiveIcon={<Image src={STCInactiveIcon} />} />
                        <TabItem data={menuData.menuItems.approve} label="approvals" name="approvals" path="/approvals"
                            icon={<Flex>
                                <Image src={ApprovalsActiveIcon} />
                                {
                                    (approvalNotificationCount || 0) > 0 && collapsed &&
                                    <Flex start style={{ margin: `-14px 0 12px 28px`, position: "absolute" }}>
                                        <Badge style={{ marginLeft: 0, marginTop: 0 }} count={approvalNotificationCount} />
                                    </Flex>
                                }
                            </Flex>}
                            inactiveIcon={<Flex>
                                <Image src={ApprovalsInactiveIcon} />
                                {
                                    (approvalNotificationCount || 0) > 0 && collapsed &&
                                    <Flex start style={{ margin: `-14px 0 12px 28px`, position: "absolute" }}>
                                        <Badge style={{ marginLeft: 0, marginTop: 0 }} count={approvalNotificationCount} />
                                    </Flex>
                                }
                            </Flex>} />
                        <TabItem data={menuData.menuItems.support} label="aion support" name="support" path="/support/search" icon={<Image src={SupportConsoleTabIcon} />} inactiveIcon={<Image src={SupportConsoleTabIcon} />} />
                        <TabItem data={menuData.menuItems.aionCreditMenuItems} label="aion credit" name="underwriting" path="/underwriting/applications/pending" icon={<Image src={SupportConsoleTabIcon} />} inactiveIcon={<Image src={SupportConsoleTabIcon} />} />
                        {
                            (!allowedApps.includes("TermLoans") && userRoleNames.includes("Primary Admin") && showCreditPlus == "true") && <FlexColumn style={{ width: '280px', boxShadow: '0px 4px 8px rgba(102, 102, 102, 0.08)', borderRadius: '4px', margin: '12px 20px 12px' }}>
                                <Flex style={{ width: '100%', padding: '12px 12px 12px 16px' }} centerHorizontally between>
                                    <FlexColumn start>
                                        <Text style={{ fontSize: "16px" }} weight={'400'} margin='0 0 0 12px'>
                                            {this.props.creditPlusTitle}
                                        </Text>
                                        <TextButton style={{ fontSize: "16px" }} underline type="link" margin='10px 0 0 12px' rightIcon={<Image src={ArrowRight} />} text={this.props.creditPlusSubTitle} onClick={() => this.setState({ showCreditOnboarding: true })} />
                                    </FlexColumn>
                                    <FlexColumn style={{ width: '48px' }}>
                                        <Image style={{ width: '48px', height: '48px' }} src={Capital} />
                                    </FlexColumn>
                                </Flex>
                            </FlexColumn>
                        }
                        {/* </FlexColumn> */}

                        <FlexColumn end grow style={{ paddingBottom: 16 }}>
                            <TabItem
                                label={businessInfo.name || ""}
                                // label={`${BusinessInfo.Name} || "" ${BusinessInfo.Name}  ${BusinessInfo.Name} ${BusinessInfo.Name}   ${BusinessInfo.Name} ${BusinessInfo.Name}`}
                                // label={`${(BusinessInfo.Name || "").trim().substr(0, 18)}${(BusinessInfo.Name || "").trim().length > 18 ? "..." : ""}`}
                                name="settings"
                                path="/settings/profile"
                                data={menuData.menuItems.settings}
                                icon={
                                    <BusinessLogo
                                        size={24}
                                        hideupload="true"
                                    />
                                }
                                inactiveIcon={
                                    <BusinessLogo
                                        size={24}
                                        hideupload="true"
                                        inactiveIcon
                                    />
                                }
                            />
                        </FlexColumn>
                    </div>
                </div>
            </Affix>

                <Modal
                    visible={this.state.showSwitchBizModal}
                    footer={null}
                    header="Select a business to switch"
                    closable={true}
                    width={500}
                    style={{ top: 20 }}
                    destroyOnClose={true}
                    onCancel={() => { this.setState({ showSwitchBizModal: false }) }}
                >
                    <SwitchBusiness 
                        linkedBusinessList={linkedBusinessList} 
                        dispatch={this.props.dispatch} 
                        aionStore={aionStore} 
                        history={this.props.history} 
                        switchComplete={ () => {
                            this.setState({ showSwitchBizModal: false })
                            window.location.reload()
                        } }
                    />
                </Modal>

                <Modal
                    visible={this.state.showCreditOnboarding}
                    footer={null}
                    header="Select a business to switch"
                    closable={true}
                    width={"100vw"}
                    height={"100vh"}
                    style={sigStyle}
                    destroyOnClose={true}
                    onCancel={() => {
                        this.setState({ showCreditOnboarding: false });
                        this.props.dispatch(addDataToStore(ONB_SAVE_DATA, { isCreditPlus: false }));
                    }}
                >
                    <CreditPlus creditEligibilityApplied={false} closeCreditPlus={this.closeCreditPlus} />
                </Modal>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        bankingStore: state.bankingAppReducer,
        aionStore: state.aionAppReducer,
        userDefaults: state.userDefaultsReducer,
        bkStore: state.bookeepingAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(NavMenu)))