import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { DeleteOutlined } from '@ant-design/icons'
import {
    Table,
    Space,
    Popconfirm,
    message
} from 'antd'
import {
    EditOutlined
} from '@ant-design/icons'

// Util
import environment from '../../../../environment'
import { apiPOSTReq } from '../../../../Utils/api'
import { getResourcePerm } from '../../../../Utils/util'
import PageHeader from "../../../Reusable/PageHeader"
import { Flex, FlexColumn, Container } from '../../../Reusable/Container'

// Images
import { ErrorAlert } from '../../../Reusable/Alert'
import { Button, } from '../../../Reusable/Button'
import Modal from 'antd/lib/modal/Modal'
// import NewPayGroup from '../New Pay Group/NewPayGroup'
import { withTheme } from 'styled-components'
import { PlusOutlined } from '@ant-design/icons'
import ModalClose from '../../../../Images/modal-close.png'

class Index extends Component {
    ref = null
    loadingAnimation = null
    state = {
        loading: true,
        templates: [],
        contractors: [],
        pagination: {
            pageSize: 10
        },
        selectedContractors: [],
    }

    componentDidMount() {
        this.fetchContractors()
        this.fetchData()
    }

    componentDidUpdate(prevProps) {
        let { location } = this.props
        var state = location.state ? location.state : {}
        if (state.reloadData) {
            this.props.location.state = {}
            this.fetchData()
        }
    }

    fetchContractors = () => {
        apiPOSTReq(`${environment.payBaseUrl}/payables/contractors/getContractors`, {}, {}, (err, resp) => {
            try {
                const data = resp
                console.log("/payables/contractors/getContractors", JSON.stringify(data))
                if (data.result) {
                    this.setState({ contractors: data.contractors || [] })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /payables/contractors/getContractors", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    fetchData() {
        apiPOSTReq(`${environment.payBaseUrl}/payables/contractors/listPaymentTemplates`, {}, {}, (err, resp) => {
            try {
                const data = resp
                if (data.contractorPaymentTemplateNames) {
                    this.setState({ templates: data.contractorPaymentTemplateNames || [] })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /payables/contractors/listPaymentTemplates", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })

    }

    deletePayGroup = (name) => {
        const contractorPaymentTemplate = {
            "name": name
        }

        this.setState({ deleteLoading: true })
        apiPOSTReq(`${environment.payBaseUrl}/payables/contractors/deletePaymentTemplate`, {}, { contractorPaymentTemplate }, (err, resp) => {
            try {
                this.setState({ deleteLoading: false })
                const data = resp
                console.log("/payables/contractors/deletePaymentTemplate", data)
                if (data.result) {
                    message.success(`Successfully deleted!`)
                    this.fetchData()
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR deletecontractor", error, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    selectRow = (record) => {
        this.fetchPayGroupDetail(record)
        // this.setState({ showNewPayGroupModal: true })

        // this.props.history.push({
        //     pathname: '/payables/paygroups/edit-paygroup',
        //     state: { payGroup: record }
        // })
    }

    fetchPayGroupDetail = (name) => {
        const contractorPaymentTemplate = {
            name,
        }

        apiPOSTReq(`${environment.payBaseUrl}/payables/contractors/getPaymentTemplate`, {}, { contractorPaymentTemplate }, (err, resp) => {
            try {
                const data = resp
                console.log('/payables/contractors/getPaymentTemplate', JSON.stringify(data.contractorPaymentTemplate))
                if (data.contractorPaymentTemplate) {
                    // this.setState({
                    //     templateDetail: data.contractorPaymentTemplate || [],
                    //     selectedContractors: data.contractorPaymentTemplate ? data.contractorPaymentTemplate.items : [],
                    //     payGroupName: data.contractorPaymentTemplate.name,
                    //     templateId: data.contractorPaymentTemplate.id,
                    // })

                    this.props.history.push({
                        pathname: '/contractor-payroll/paygroups/edit-paygroup',
                        state: {
                            templateDetail: data.contractorPaymentTemplate || [],
                            selectedContractors: data.contractorPaymentTemplate ? data.contractorPaymentTemplate.items : [],
                            payGroupName: data.contractorPaymentTemplate.name,
                            templateId: data.contractorPaymentTemplate.id,
                        }
                    })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /payables/contractors/getPaymentTemplate", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    render() {
        var { pagination, contractors, selectedContractor } = this.state
        var contractors = contractors || []

        var columns = [
            {
                title: 'Name',
                dataIndex: '',
                key: 'name',
                render: (name, item) => item
            },
        ]

        const contractorsPerm = getResourcePerm("Payables.Contractors")

        if (contractorsPerm.manage) {
            columns.push(
                {
                    title: 'Action',
                    key: 'action',
                    align: 'end',
                    render: (text, record) => (
                        <Space size="middle">
                            <a onClick={() => this.selectRow(record)}><EditOutlined style={{ fontSize: 18 }} /></a>
                            <Popconfirm
                                title="Are you sure you want to delete this contractor?"
                                onConfirm={() => this.deletePayGroup(record)}
                                okText="Confirm"
                                okButtonProps={{ loading: this.state.deleteLoading }}
                                cancelText="Cancel"
                            >
                                <a permtype="Override"><DeleteOutlined style={{ fontSize: 18 }} /></a>
                            </Popconfirm>
                        </Space>
                    ),
                }
            )
        }

        return (
            <FlexColumn className='main-padding'>
                <PageHeader
                    titleText="Pay Groups"
                    subtitle="Contractor Payroll"
                    ctaContent={
                        // <Button permtype="Payables.Contractor Payments" style={{ height: 45 }} onClick={() => this.setState({ showNewPayGroupModal: true, selectedContractor: null, payGroupName: '', selectedContractors: [], templateId: null })} text='New Pay Group' />
                        <Button icon={<PlusOutlined style={{ color: 'white' }} />} permtype="Payables.Contractor Payments" solid onClick={() => this.props.history.push('/contractor-payroll/paygroups/new-paygroup')} text='ADD PAY GROUP' />
                    }
                />
                {/* <Flex between style={{ alignItems: "start" }}> */}
                <Container shadow>
                    <Table
                        id="contractor-table"
                        className="row-pointer"
                        tableLayout='auto'
                        columns={columns}
                        dataSource={this.state.templates}
                        rowKey='id'
                        pagination={pagination}
                        loading={!this.state.loading}
                        style={{ flex: 1 }}
                        scroll={{ y: '700px', x: '100%' }}
                    />
                </Container>

                <Modal
                    visible={this.state.showNewPayGroupModal}
                    footer={null}
                    closable={true}
                    width={1000}
                    style={{ top: 20 }}
                    destroyOnClose={true}
                    onCancel={() => {
                        this.setState({ showNewPayGroupModal: false, selectedContractor: null })
                        this.fetchData()
                    }}
                    className="scroll-overflow-95"
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    {/* <NewPayGroup
                            key={selectedContractor ? selectedContractor.id : moment().format("HH:ss")}
                            editcontractor={selectedContractor}
                            contractors={this.state.contractors}
                            selectedContractors={this.state.selectedContractors.map(x => {
                                return {
                                    ...x,
                                    id: x.contractorId,
                                    firstName: x.name.split(' ')[0],
                                    lastName: x.name.split(' ')[1],

                                }
                            })}
                            templateId={this.state.templateId}
                            payGroupName={this.state.payGroupName}
                            submitComplete={
                                () => {
                                    this.setState({ showNewPayGroupModal: false, selectedContractor: null, payGroupName: '', selectedContractors: [], contractors: [] })
                                    this.fetchContractors()
                                    this.fetchData()
                                }
                            }
                        /> */}
                </Modal>
                {/* </Flex> */}

            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)))