import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'
import styled from 'styled-components'
import moment from 'moment-business-days'

import { message, Table, Input, InputNumber } from 'antd'

// Components
import { ConfirmStep } from '../PayFlow'
import { Tag } from '../../../Reusable/Text'
import { Button } from '../../../Reusable/Refresh/Button'
import PageHeader from "../../../Reusable/PageHeader"
import { Flex, FlexColumn, Container } from '../../../Reusable/Container'
import { LabeledInput, } from '../../../Reusable/Input'
import { ErrorAlert } from '../../../Reusable/Alert'
import Banner from '../../../Reusable/Banner'
import environment from '../../../../environment'
import { apiPOSTReq } from '../../../../Utils/api'
import { CheckCircleFilled } from '@ant-design/icons'

const TotalAmountText = styled.span`
    font-size: 18px
    font-weight: 400
`

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
})

class NewPayGroup extends Component {
    constructor(props) {
        super(props)

        this.state = {
            pagination: {
                pageSize: 10,
            },
            selectedRowKeys: this.props.selectedContractors ? this.props.selectedContractors.map(x => x.id) : [],
            selectedContractors: this.props.selectedContractors || [],
            selectedPayGroupRowKeys: [],
            selectedTemplate: [],
            flowStep: "selectContractors",
            saveTemplate: false,
            payGroupName: this.props.payGroupName || '',
            usingTemplate: false,
        }

        // Update moment to include holidays
        const { aionStore } = this.props
        var { publicHolidays } = aionStore

        moment.updateLocale('us', {
            holidays: publicHolidays,
            holidayFormat: 'YYYY-MM-DD'
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedRowKeys !== prevProps.selectedRowKeys
            || this.props.selectedContractors !== prevProps.selectedContractors
            || this.props.payGroupName !== prevProps.payGroupName) {
            this.setState({
                selectedRowKeys: this.props.selectedContractors ? this.props.selectedContractors.map(x => x.id) : [],
                selectedContractors: this.props.selectedContractors || [],
                payGroupName: this.props.payGroupName || '',
            })
        }
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        let selectedContractors = this.state.selectedContractors

        let selected = selectedRows.map(x => {
            if (selectedContractors.filter(y => y.id === x.id).length !== 0) {
                return selectedContractors.filter(y => y.id === x.id)[0]
            } else {
                return x
            }
        })

        this.setState({ selectedRowKeys, selectedContractors: selected, selectedPayGroupRowKeys: [] })
    }

    continue = () => {
        var { selectedContractors, selectedPayGroupRowKeys } = this.state

        if (selectedContractors.length === 0 && selectedPayGroupRowKeys.length === 0) {
            ErrorAlert({ description: "Please select at least one contractor." })
            return
        }

        this.setState({ flowStep: "confirm" })
    }

    save = () => {
        var { selectedContractors } = this.state

        for (var i = 0; i < selectedContractors.length; i++) {
            if ((selectedContractors[i].wages || 0) + (selectedContractors[i].reimbursementAmount || 0) === 0) {
                ErrorAlert({ description: "Please enter either a wage or reimbursement amount for each of the contractors you wish to pay." })
                return
            }
        }

        const payGroupItems = []

        selectedContractors.forEach(contractor => {
            payGroupItems.push({
                "contractorId": contractor.id,
                "name": `${contractor.firstName} ${contractor.lastName}`,
                "wages": contractor.wages || 0,
                "reimbursementAmount": contractor.reimbursementAmount || 0,
                "memo": contractor.memo,
                "createdAt": new Date(),
                "lastUpdatedAt": new Date(),
            })
        })

        let templateBody
        if (this.props.templateId) {
            templateBody = {
                contractorPaymentTemplate: {
                    "name": this.state.payGroupName,
                    "items": payGroupItems,
                    "id": this.props.templateId,
                }
            }
        } else {
            templateBody = {
                contractorPaymentTemplate: {
                    "name": this.state.payGroupName,
                    "items": payGroupItems,
                }
            }
        }

        apiPOSTReq(`${environment.payBaseUrl}/payables/contractors/savePaymentTemplate`, {}, templateBody, (err, resp) => {
            try {
                const data = resp
                if (data.result) {
                    message.success(`Template successfully saved!`)
                    // this.props.submitComplete()
                    window.history.back()
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /payables/savePaymentTemplate", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }


    getTotal = () => {
        var total = 0

        if (!this.state.usingTemplate) {
            this.state.selectedContractors.forEach(contractor => {
                total = total + (contractor.wages || 0) + (contractor.reimbursementAmount || 0)
            })
        } else {
            if (this.state.selectedTemplate) {
                this.state.selectedTemplate.forEach(contractor => {
                    total = total + (contractor.wages || 0) + (contractor.reimbursementAmount || 0)
                })
            }
        }

        return formatter.format(total)
    }

    handleTableChange = (pagination, filters, sorter) => {
        this.setState({ pagination })
    }

    render() {
        const { contractors, theme } = this.props
        const { pagination, selectedRowKeys, selectedContractors, flowStep } = this.state

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            getCheckboxProps: (record) => ({
                disabled: !record.deliveryMode,
            }),
        }

        const columns = [
            {
                title: 'Name',
                dataIndex: 'firstName',
                render: (name, contractor) => `${contractor.firstName} ${contractor.lastName}`,
            },
            {
                title: 'Tax ID',
                dataIndex: 'taxId',
            },
            {
                title: 'Payment Method',
                dataIndex: 'deliveryMode',
                render: deliveryMode => deliveryMode ? <Tag style={{ margin: "5px 0" }} tagpreset={theme.colors.secondary4}>{deliveryMode}<CheckCircleFilled /></Tag> : <Tag style={{ margin: "5px 0" }} tagpreset={theme.colors.secondary4}>None</Tag>
            },
            {
                title: 'Email',
                dataIndex: 'primaryEmail',
            },
        ]

        const entryColumns = [
            {
                title: 'Name',
                dataIndex: 'name',
                render: (name, contractor) => this.state.usingTemplate ? name : `${contractor.firstName} ${contractor.lastName}`,
            },
            {
                title: 'Wages',
                width: 120,
                render: (name, contractor) => <InputNumber min={0} value={contractor.wages} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} onChange={(value) => { contractor.wages = value; this.setState(selectedContractors) }} type="text" />,
            },
            {
                title: 'Reimbursements',
                width: 140,
                render: (name, contractor) => <InputNumber min={0} value={contractor.reimbursementAmount} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} onChange={(value) => { contractor.reimbursementAmount = value; this.setState(selectedContractors) }} type="text" />,
            },
            {
                title: 'Total Amount',
                align: 'center',
                render: (name, contractor) => <TotalAmountText>{formatter.format((contractor.wages || 0) + (contractor.reimbursementAmount || 0))}</TotalAmountText>,
            },
            {
                title: 'Memo',
                render: (name, contractor) => <Input maxLength={80} value={contractor.memo} onChange={(event) => { contractor.memo = event.target.value; this.setState(selectedContractors) }} type="text" />,
            },
        ]

        var content = (<div></div>)

        var contractorWithoutDeliveryMode = false

        contractors.forEach(c => {
            if (!c.deliveryMode) contractorWithoutDeliveryMode = true
        })

        switch (flowStep) {
            case "selectContractors":
                content = <>
                    <FlexColumn className='main-padding'>
                        <PageHeader
                            back
                            backText='Pay groups'
                            titleText="New contractor paygroup"
                        />

                        {/* <Title level={4}>Select contractors to add to group</Title>
                        <Paragraph level={3} style={{ color: theme.colors.systemGray }}>You can choose how much to pay them next</Paragraph> */}


                        <Container shadow style={{ marginBottom: 48 }}>
                            <LabeledInput
                                label="Pay Group Name"
                                labelcolor={theme.colors.secondary3}
                                id="payGroupName"
                                key="payGroupName"
                                value={this.state.payGroupName}
                                placeholder="Enter a name for the group"
                                onChange={(e) => this.setState({ [e.target.id]: e.target.value })}
                            />

                            {contractorWithoutDeliveryMode && <Banner style={{ marginTop: 24 }} title={`Default payment method not set`} message="At least one of your contractors does not have a default payment method set. You must have a default payment method set for each contractor before you can pay them." />}
                            <Table
                                rowKey="id"
                                id="contractors-table"
                                tableLayout='auto'
                                columns={columns}
                                dataSource={contractors}
                                pagination={pagination}
                                style={{ marginTop: 24, width: "100%" }}
                                rowSelection={rowSelection}
                                onChange={this.handleTableChange}
                                scroll={{ y: '700px', x: '100%' }}
                            />


                            <Flex start style={{ marginTop: 24 }}>
                                <Button
                                    permtype="Payables.Vendors"
                                    solid onClick={!(!this.state.payGroupName || !this.state.selectedContractors || this.state.selectedContractors.length === 0) && this.continue}
                                    tooltip={!this.state.payGroupName || !this.state.selectedContractors || this.state.selectedContractors.length === 0 ? "To continue please add a Pay Group Name and select at least one contractor" : ""}
                                    disabled={!this.state.payGroupName || !this.state.selectedContractors || this.state.selectedContractors.length === 0} text='Continue' />
                            </Flex>
                        </Container>
                    </FlexColumn>
                </>

                break
            case "confirm":
                content =
                    <FlexColumn className='main-padding'>
                        <ConfirmStep
                            theme={theme}
                            selectedContractors={selectedContractors}
                            entryColumns={entryColumns}
                            getTotal={this.getTotal}
                            confirm={this.save}
                            confirmText={"Save"}
                            onClick={() => this.setState({ flowStep: "selectContractors" })}
                        />
                    </FlexColumn>
                break

            default:
                break
        }

        return (
            <div style={{ display: "flex", flexDirection: "column", minHeight: "85vh", marginTop: 20 }}>
                <FlexColumn style={{ minHeight: "85vh", justifyContent: "space-between" }}>
                    {content}
                </FlexColumn>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        bkStore: state.bookkeepingAppReducer,
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(NewPayGroup))