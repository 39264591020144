
module.exports = {

    flowViewsData: [
        {
            "FlowStep": "Apply.Splash",
            "Title": "Banking with Aion",
            "ButtonTitle": "NEXT",
            "DefaultUserInput": "NOTREQUIRED",
            "Step": "Splash Page",
            "Shadow": true,
        },
        {
            "FlowStep": "Apply.Consent",
            "Title": "Banking with Aion",
            "ButtonTitle": "NEXT",
            "DefaultUserInput": "NOTREQUIRED",
            "Step": "Business Profile",
            "Shadow": true,
        },
        {
            "FlowStep": "Apply.PersonalInfo",
            "Title": "Banking with Aion",
            "ButtonTitle": "NEXT",
            "DefaultUserInput": "NOTREQUIRED",
            "Step": "Business Profile",
            "Shadow": true,
        },
        {
            "FlowStep": "Apply.Lookup",
            "Title": "Banking with Aion",
            "ButtonTitle": "NEXT",
            "DefaultUserInput": "NOTREQUIRED",
            "Step": "Business Profile",
            "Shadow": true,
        },
        {
            "FlowStep": "Apply.BusinessInfo",
            "Title": "Banking with Aion",
            "ButtonTitle": "NEXT",
            "DefaultUserInput": "NOTREQUIRED",
            "NoInput": true,
            "Step": "Business Profile",
            "Shadow": true,
        },
        {
            "FlowStep": "Apply.TxnActivity",
            "Title": "Banking with Aion",
            "ButtonTitle": "NEXT",
            "DefaultUserInput": "NOTREQUIRED",
            "NoInput": true,
            "Step": "Business Profile",
            "Shadow": true,
        },
        {
            "FlowStep": "Apply.Ownership",
            "Title": "Banking with Aion",
            "ButtonTitle": "NEXT",
            "DefaultUserInput": "NOTREQUIRED",
            "NoInput": true,
            "Step": "Business Profile",
            "Shadow": true,
        },
        {
            "FlowStep": "Apply.ControlPerson",
            "Title": "Banking with Aion",
            "ButtonTitle": "NEXT",
            "DefaultUserInput": "NOTREQUIRED",
            "NoInput": true,
            "Step": "Business Profile",
            "Shadow": true,
        },
        // {
        //     "FlowStep": "Apply.AuthorizedSigner",
        //     "Title": "Banking with Aion",
        //     "ButtonTitle": "NEXT",
        //     "DefaultUserInput": "NOTREQUIRED",
        //     "NoInput": true,
        //     "Step": "Business Profile",
        //     "Shadow": true,
        // },
        {
            "FlowStep": "Apply.Review",
            "Title": "Banking with Aion",
            "ButtonTitle": "Acknowledge & submit",
            "DefaultUserInput": "NOTREQUIRED",
            "NoInput": true,
            "Step": "Business Profile",
        },
        {
            "FlowStep": "Apply.BankSubmitted",
            "Title": "Applying for financing with Aion",
            "Description": "Welcome to your one-stop shop for Aion Capital. Complete outstanding tasks, keep track of your application status, and chat with the Aion team throughout the process.",
            "ButtonTitle": "Done",
            "DefaultUserInput": "NOTREQUIRED",
            "HideButton": true,
            "BackButton": false
        }
    ]
}


