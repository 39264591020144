import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';

import { Upload, message, Avatar } from 'antd';
import { CameraOutlined } from '@ant-design/icons';
import environment from '../../environment';
import { apiPOSTReq } from '../../Utils/api';
import { ErrorAlert } from './Alert';
import { addDataToStore, UPDATE_LOGO } from '../../Actions/actions';
import { getInitials, getUserRoles } from '../../Utils/util';

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

class BusinessLogo extends Component {
    state = {
        loading: false,
    };

    componentDidMount() {
        var { business } = this.props.aionStore
        var { businessInfo } = business.businessProfile || {}
        businessInfo = businessInfo || {}
        this.setState({ imageUrl: businessInfo.logoUrl })
    }

    componentDidUpdate(prevProps, prevState) {
        // Update on image change
        var { business } = this.props.aionStore
        var { businessInfo } = business.businessProfile || {}
        businessInfo = businessInfo || {}
        const newLogoUrl = businessInfo.logoUrl
        const prevLogo = ((prevProps.aionStore.business.businessProfile || {}).businessInfo || {}).logoUrl
        if (prevLogo !== newLogoUrl) {
            this.setState({ imageUrl: newLogoUrl })
        }
    }

    // Save logo URL
    saveLogoUrl = () => {
        apiPOSTReq(`${environment.uamBaseUrl}/user/admin/updateLogo`, null, { logoUrl: this.state.imageUrl }, (err, resp) => {
            try {
                const data = resp || {};
                console.log("/updateLogo", this.state.imageUrl, data)
                if (data.result) {
                    // Update business profile
                    this.props.dispatch(addDataToStore(UPDATE_LOGO, this.state.imageUrl));
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR updateLogo", error, err, resp)
                ErrorAlert({description: error.message})
            }
        })
    }

    render() {
        var { business } = this.props.aionStore;
        var { businessInfo } = business.businessProfile || {};
        businessInfo = businessInfo || {};
        const { imageUrl } = this.state;
        var { size, hideupload, inactiveIcon, theme, borderRadius, border } = this.props;
        const userRole = getUserRoles(this.props.aionStore);
        const isCompanyAdmin = userRole.includes("Company Admin") || userRole.includes("Owner") || userRole.includes("Primary Admin");
        if(!isCompanyAdmin) hideupload = true;
        const logoSize = size || 64;
        var uploadProps = {
            action: `${environment.apiBaseUrl}/file/upload`,
            headers: {
                businesskey: this.props.aionStore.BusinessUniqueKey,
                type: "logo",
                jwt: this.props.aionStore.jwt
            },
            onChange: (info) => {
                var { fileList } = info;
                fileList.forEach(file => {
                    const { status, response, name, url } = file;
                    console.log("fileList response", response);
                    if(status === "done") {
                        this.setState({
                            imageUrl: (response.UploadedUrls || [])[0]
                        });
                        this.saveLogoUrl();
                    }
                });
			}
        };
        var imgStyle = { display: "block", margin: "0 auto", height: logoSize, width: logoSize, objectFit: "contain", borderRadius: borderRadius || "5px", border: border }
        return (
            <>
                {
                    imageUrl ? 
                    (
                        <div style={{position: "relative", height: logoSize, width: logoSize}}>
                            <img style={imgStyle} src={imageUrl} />
                            {!hideupload && 
                            <Upload
                                name="avatar"
                                showUploadList={false}
                                beforeUpload={beforeUpload}
                                {...uploadProps}
                            >
                                <CameraOutlined style={{ position: 'relative', cursor: "pointer", bottom: logoSize/3, left: logoSize/2+10, fontSize: "16px", background: theme.body, padding: "5px", borderRadius: "50%" }} className="shadow hover-icon" />
                            </Upload>}
                        </div>                            
                    )
                    :
                    (
                        hideupload ?
                        <Avatar size={logoSize} style={{alignSelf: 'center', backgroundColor: this.props.theme.body, fontSize: 126, fontWeight: 600, color: inactiveIcon ? theme.colors.defaultLightText : theme.colors.primary, userSelect: 'none', WebkitUserSelect: 'none' }}>{businessInfo.name ? getInitials(businessInfo.name) : ""}</Avatar>
                        :
                        <div style={{ position: "relative" }}>
                            <Avatar size={logoSize} style={{ backgroundColor: theme.body, fontSize: logoSize/2, color: theme.colors.defaultLightText, userSelect: 'none', WebkitUserSelect: 'none' }}>{businessInfo.name ? businessInfo.name[0] : ""}</Avatar>
                            <Upload
                                name="av"
                                showUploadList={false}
                                beforeUpload={beforeUpload}
                                {...uploadProps}
                            >
                                <CameraOutlined style={{ position: 'relative', cursor: "pointer", bottom: -20, left: -15, fontSize: "16px", background: this.props.theme.body, padding: "5px", borderRadius: "50%" }} className="shadow hover-icon" />
                            </Upload>
                        </div>
                    )
                    
                }
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(BusinessLogo));