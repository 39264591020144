import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import { Select, Modal } from 'antd'

import { Text, Tag } from '../../Reusable/Text'
import { Divider } from '../../Reusable/Divider'
import { Flex, FlexColumn, CardContainer } from '../../Reusable/Container'
import PageHeader from '../../Reusable/PageHeader'
import { LabeledInput } from '../../Reusable/Input'
import { ErrorAlert } from '../../Reusable/Alert'
import { Button, TextButton, ImageButton } from '../../Reusable/Button'
import SuccessModal from '../../Reusable/SuccessModal'
import { ApprovalGraph } from '../../Reusable/Approval'
import FlowFooter from '../../Reusable/FlowFooter'
import ProgressSteps from '../../Reusable/ProgressSteps'
import { FromAccountOption, PaymentMethodOption } from '../../Reusable/Option'
import AionIcon from '../../../Images/aion-bank-icon.png'
import ChevronDown from '../../../Images/chevron-down.png'
import ChevronUp from '../../../Images/chevron-up.png'
import Edit from '../../../Images/edit.png'

import { ArrowLeftOutlined, CheckOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { addDataToStore, SAVE_DATA } from '../../../Actions/actions'
import { toCurrency, getBPAccountsWithAccess, toNumberStr } from '../../../Utils/util'
import { apiPOSTReq } from '../../../Utils/api'
import environment from '../../../environment'
import ModalClose from '../../../Images/modal-close.png'

const { Option } = Select

class Index extends Component {

    constructor(props) {
        super(props)

        var fromAccount

        if (props.location.state?.fromAccount && props.location.state.fromAccount.accountType !== 'ACCOUNTS_RECEIVABLE') {
            fromAccount = props.location.state && props.location.state.fromAccount
        }

        this.state = {
            loading: true,
            pagination: {
                current: 1,
                pageSize: 1000,
                hideOnSinglePage: true
            },
            recipients: [],
            showTransferModal: false,
            transferMethods: {},
            showResult: false,
            step: 0,
            steps: ['Transfer Details', 'Review'],
            confirmDisabled: true,
            sendEmail: false,
            fromAccount: fromAccount,
        }

        this.rail = this.props.location.state && this.props.location.state.rail

        this.nameRef = React.createRef()
        this.bankRef = React.createRef()
        this.accountNumberRef = React.createRef()
        this.routingNumberRef = React.createRef()
    }

    componentDidMount() {
        const { theme, bankingStore } = this.props
        const { AllRecipients } = bankingStore
        console.log("fetchCounterparties: AllRecipients", AllRecipients)
        this.setState({ loading: false, recipients: AllRecipients })
    }

    viewRecipient = (recipient) => {
        this.props.history.push({
            pathname: '/banking/recipients/details',
            state: { recipient }
        })
    }

    createRecipient = () => {
        const { recipientName, recipientEmail, type, transferMethods } = this.state
        const { store } = this.props
        const { UserInfo } = store || {}

        var body = {
            counterparty: {
                "type": type,
                "nameOnAccount": recipientName || "",
                "email": recipientEmail[0] || "",
                "notificationEmails": [],
                "contextIdentifier": recipientEmail[0] || "",
                "initiatedBy": `${UserInfo.FirstName} ${UserInfo.LastName}`,
            },
            "BankProvider": environment.bankProvider.crb,
            "ruleType": "Recipient"
        }

        if (transferMethods.ACH) {
            body.counterparty.ach = {
                "accountNumber": transferMethods.ACH.accountNumber || "",
                "routingNumber": transferMethods.ACH.routingNumber || "",
                "accountType": (transferMethods.ACH.accountType || "").toLowerCase(),
            }
        }

        if (transferMethods.Wire) {
            body.counterparty.wire = {
                "accountNumber": transferMethods.Wire.accountNumber || "",
                "routingNumber": transferMethods.Wire.routingNumber || "",
                "addressOnAccount": this.formatAddr(transferMethods.Wire.addressOnAccount),
                "bankName": transferMethods.Wire.bankName,
                "bankAddress": this.formatAddr(transferMethods.Wire.bankAddress),
                "accountType": (transferMethods.Wire.accountType || "").toLowerCase(),
            }
        }

        console.log("createRecipient: ", body)

        this.setState({ submitLoading: true })

        apiPOSTReq(`${environment.bbBaseUrl}/bb/createCounterparty`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            this.setState({ submitLoading: false })
            try {
                // if (err) throw Error(err)
                const data = resp || {}
                if (data.result) {
                    console.log("bb/createCounterparty", data)
                    // window.history.back()
                    this.setState({ showResult: true })
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/createCounterparty err", error, resp, body)
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    formatAddr = (address) => {
        return {
            line1: address.line1,
            line2: address.line2 || null,
            city: address.city,
            countrySubDivisionCode: address.countrySubDivisionCode,
            state: address.countrySubDivisionCode,
            postalCode: address.postalCode
        }
    }

    handleSelectAccount = (value, option) => {
        var Accounts = getBPAccountsWithAccess("Payments", { permType: "manage", getActiveAccounts: true })
        var account = Accounts.find(a => a.accountId === value)
        this.setState({ [option.id]: account }, this.isConfirmEnabled)
    }

    saveUserInput = (id, dataToSave) => {
        this.setState({ [id]: dataToSave }, this.isConfirmEnabled)
    }

    selectRecipient = (value, option) => {
        const { recipients } = this.state
        const recipient = recipients.find(r => r.objectId === value)
        console.log("selectRecipient: ", recipient)
        this.setState({ recipient }, this.isConfirmEnabled)
    }

    getTransferMethods = () => {
        const { recipient } = this.state
        const transferMethods = []

        if (recipient && recipient.ach) {
            recipient.ach.type = "ACH"
            transferMethods.push(recipient.ach)
        }

        if (recipient && recipient.wire) {
            recipient.wire.type = "Wire"
            transferMethods.push(recipient.wire)
        }

        if (recipient && recipient.check) {
            recipient.check.type = "Check"
            transferMethods.push(recipient.check)
        }

        console.log("selectRecipient: getTransferMethods", transferMethods)

        return transferMethods
    }

    isConfirmEnabled = () => {
        const { step, fromAccount, toAccount, transferAmount, purpose, recipient, selectedRows } = this.state

        switch (step) {
            case 0:
                this.setState({ confirmDisabled: !fromAccount || !toAccount || !transferAmount || !purpose })
                break
            default:
                // this.setState({ confirmDisabled: true })
                break
        }
    }

    next = () => {
        const { step, fromAccount, recipient, selectedRows } = this.state

        switch (step) {
            case 0:
                this.setState({ step: 1 })
                break
            case 1:
                this.confirmPayment()
                break
            default:
                break
        }
    }

    confirmPayment = () => {
        const { step, fromAccount, toAccount, transferMethod, transferAmount, sendEmail, achDesc, purpose, remittanceInfo } = this.state
        const { store } = this.props
        const { UserInfo } = store || {}

        const paymentAmt = toNumberStr(transferAmount)

        var body = {
            "fromAccountId": fromAccount.accountId || "",
            "toAccountId": toAccount.accountId || "",
            "FromAccountNumber": fromAccount.accountNum,
            "ToAccountNumber": toAccount.accountNum,
            "amount": toNumberStr(transferAmount),
            "amountStr": toNumberStr(transferAmount),
            "operationType": "user",
            "userNote": purpose || null,
            "purpose": purpose || null,
            "remittanceInfo": remittanceInfo
        }

        this.setState({ submitLoading: true })
        console.log("/XferInternal", body)
        apiPOSTReq(`${environment.bbBaseUrl}/bb/xfer`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            try {
                this.setState({ submitLoading: false })
                console.log(`${environment.bpBaseUrl}/bp/payments/XferInternal`, err, resp)
                const data = resp
                if (data.result) {
                    console.log("/XferInternal data ", data)
                    this.setState({ showResult: true })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR XferInternal", error, err, resp)
                const fd = this.fetchData
                ErrorAlert({ description: error.message })
            }
        })
    }

    getAccountOptions = (id) => {
        const { fromAccount, toAccount } = this.state
        var Accounts = getBPAccountsWithAccess("Payments", { permType: "manage", getActiveAccounts: true }) || []
        
        if(id === 'fromAccount' && toAccount) Accounts = Accounts.filter(acc => acc.accountId !== toAccount.accountId)
        if(id === 'toAccount' && fromAccount) Accounts = Accounts.filter(acc => acc.accountId !== fromAccount.accountId)

        var filteredAccounts = Accounts
        if(id === 'fromAccount') filteredAccounts = Accounts.filter(item => { return (!['ACCOUNTS_RECEIVABLE', 'Transitional'].includes(item.accountType)) }) || []
        if(id === 'toAccount') filteredAccounts = Accounts.filter(item => { return (!['Transitional'].includes(item.accountType)) }) || []

        var options = []
        filteredAccounts.forEach(account => {
            options.push(
                <Option key={account.accountId} id={id} value={account.accountId} style={{ backgroundColor: "transparent" }}>
                    <FromAccountOption account={account} />
                </Option>
            )
        })
        return options
    }

    getDisabled = () => {
        const { step, transferAmount, fromAccount, toAccount, purpose } = this.state

        switch (step) {
            case 0:
                return !fromAccount || !toAccount || !transferAmount || !purpose
            default:
                return false
        }
    }

    getButtonTitle = () => {
        return this.state.step === this.state.steps.length - 1 ? 'SUBMIT' : 'NEXT'
    }

    render() {
        const { note, step, steps, fromAccount, toAccount, transferAmount, transferType, recipients, recipient, showResult, submitLoading, viewDetails, recipientRule, remittanceInfo, purpose } = this.state
        const { theme, bankingStore } = this.props
        const { ACHRecipients, WireRecipients } = bankingStore

        var { invoiceNumberError, emailError } = this.state

        var fromOptions = this.getAccountOptions("fromAccount")
        var toOptions = this.getAccountOptions("toAccount")

        var disabled = this.getDisabled()
        var buttonTitle = this.getButtonTitle()

        return (
            <FlexColumn className='main-padding' style={{ width: '100%', height: '100%' }} start>
                <PageHeader
                    back
                    titleText="Internal Transfer"
                />

                <ProgressSteps key={`${step}ProgressSteps`} step={step} steps={steps} onClick={(step) => this.setState({ step })} />

                {
                    steps && step < steps.length - 1 ?
                        <CardContainer padding='0' margin='24px 0 24px' style={{ width: '100%', height: '100%' }} shadow>
                            <FlexColumn between fullHeight>
                                <FlexColumn start style={{ padding: 24, height: '100%' }}>
                                    {
                                        steps[step] === 'Transfer Details' &&
                                        <>
                                            <FlexColumn start>
                                                <Flex start gap='24px'>
                                                    <LabeledInput
                                                        inputRef={this.invoiceNumberInput}
                                                        label="From Account"
                                                        type="select"
                                                        id="fromAccount"
                                                        key="fromAccount"
                                                        placeholder="Select account"
                                                        width='576px'
                                                        value={fromAccount && <><img width='16px' height='16px' src={AionIcon} style={{ marginRight: 8, marginBottom: 2 }} /><Text color='#7384AA'>{`${(fromAccount.nickName || `Business ${fromAccount.accountSubType}`)} • ${fromAccount.mask}`}</Text></>}
                                                        autoFocus
                                                        onChange={this.handleSelectAccount}
                                                        error={invoiceNumberError}
                                                        errorMessage="From account is required"
                                                    >
                                                        {fromOptions}
                                                    </LabeledInput>

                                                    <LabeledInput
                                                        inputRef={this.invoiceNumberInput}
                                                        label="To Account"
                                                        type="select"
                                                        id="toAccount"
                                                        key="toAccount"
                                                        placeholder="Select account"
                                                        width='576px'
                                                        value={toAccount && <><img width='16px' height='16px' src={AionIcon} style={{ marginRight: 8, marginBottom: 2 }} /><Text color='#7384AA'>{`${(toAccount.nickName || `Business ${toAccount.accountSubType}`)} • ${toAccount.mask}`}</Text></>}
                                                        onChange={this.handleSelectAccount}
                                                        error={invoiceNumberError}
                                                        errorMessage="From account is required"
                                                    >
                                                        {toOptions}
                                                    </LabeledInput>
                                                </Flex>

                                                <Flex start gap='24px'>
                                                    <LabeledInput
                                                        inputRef={this.accountNumberInput}
                                                        type="number"
                                                        label="Transfer Amount"
                                                        id="transferAmount"
                                                        key="transferAmount"
                                                        className="no-left-padding"
                                                        placeholder="Enter transfer amount"
                                                        width='576px'
                                                        min={0}
                                                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        value={transferAmount}
                                                        onChange={(value) => {
                                                            this.setState({ transferAmount: value }, this.isConfirmEnabled)
                                                        }}
                                                    // error={accountNumberError}
                                                    // errorMessage="Account number is required"
                                                    // value={accountNumber}
                                                    />

                                                    <LabeledInput
                                                        inputRef={this.accountNumberInput}
                                                        label="Purpose"
                                                        id="purpose"
                                                        key="purpose"
                                                        maxLength={50}
                                                        className="no-left-padding"
                                                        placeholder="Enter a description"
                                                        width='576px'
                                                        value={purpose}
                                                        onChange={this.handleOnChange}
                                                        instruction={purpose ? `${purpose.length}/50` : '0/50'}
                                                    />
                                                </Flex>

                                                {/* <Flex start gap='24px'>
                                                    <LabeledInput
                                                        label="Remittance Information"
                                                        id="remittanceInfo"
                                                        key="remittanceInfo"
                                                        maxLength={140}
                                                        className="no-left-padding"
                                                        placeholder="Enter a description"
                                                        width='576px'
                                                        value={remittanceInfo}
                                                        onChange={this.handleOnChange}
                                                        instruction={remittanceInfo ? `${remittanceInfo.length}/140` : '0/140'}
                                                        optional
                                                    />
                                                </Flex> */}
                                                
                                            </FlexColumn>
                                        </>
                                    }

                                    {
                                        step === 1 &&
                                        <FlexColumn>
                                            <FlexColumn start>
                                                <Flex between>
                                                    <CardContainer shadow padding='24px' style={{ width: 280, height: 112 }}>
                                                        <Flex start>
                                                            <div style={{ width: 8, height: 64, background: '#F4F5F7', borderRadius: 8, marginRight: 40 }} />
                                                            <FlexColumn left>
                                                                <Text uppercase color='#737387' weight={500}>FROM</Text>
                                                                <Tag style={{ marginTop: 8 }}>{<img style={{ marginRight: 8 }} width={16} height={16} src={AionIcon} />} {fromAccount.mask}</Tag>
                                                            </FlexColumn>
                                                        </Flex>
                                                    </CardContainer>
                                                    <FlexColumn center>
                                                        <Text size='20px' color='#1199FF'>{`$ ${toCurrency(transferAmount)}`}</Text>
                                                        <ArrowRightOutlined style={{ color: '#1199FF', marginTop: 12, fontSize: 16 }} />
                                                    </FlexColumn>
                                                    <CardContainer shadow padding='24px' style={{ width: 280, height: 112 }}>
                                                        <Flex end>
                                                            <FlexColumn right>
                                                                <Text uppercase color='#737387' weight={500}>TO</Text>
                                                                <Tag style={{ marginTop: 8 }}>{<img style={{ marginRight: 8 }} width={16} height={16} src={AionIcon} />} {toAccount.mask}</Tag>
                                                            </FlexColumn>
                                                            <div style={{ width: 8, height: 64, background: '#F4F5F7', borderRadius: 8, marginLeft: 40 }} />
                                                        </Flex>
                                                    </CardContainer>
                                                </Flex>
                                            </FlexColumn>
                                        </FlexColumn>
                                    }
                                </FlexColumn>

                                <FlowFooter
                                    disabled={disabled}
                                    loading={submitLoading}
                                    back={false}
                                    backClick={this.handleBack}
                                    buttonTitle={buttonTitle}
                                    onClick={this.next} />
                            </FlexColumn>
                        </CardContainer>
                        :
                        <CardContainer margin='24px 0 24px' fullWidth>
                            <FlexColumn>
                                <Text heading>Transfer</Text>
                                <Text size='28px' height='40px'>{`$ ${toCurrency(transferAmount)}`}</Text>
                                <Text size='20px' height='40px'>{`to Account • ${toAccount.mask}?`}</Text>

                                <Flex between style={{ marginTop: 24 }} centerHorizontally>
                                    <Flex start gap='12px'>
                                        <Button onClick={() => window.history.back()} text='CANCEL' />
                                        <Button solid loading={submitLoading} onClick={this.next} text='CONFIRM' />
                                    </Flex>

                                    {
                                        !viewDetails &&
                                        <TextButton text='VIEW DETAILS' onClick={() => this.setState({ viewDetails: true })} icon={<img width='24px' height='24px' src={ChevronDown} />} />
                                    }
                                </Flex>

                                {
                                    viewDetails &&
                                    <FlexColumn start>
                                        {
                                            recipientRule && <>
                                                <Divider />
                                                <Text caption spaced weight='500'>{'Approvals'.toUpperCase()}</Text>
                                                <ApprovalGraph rule={recipientRule} />
                                            </>
                                        }
                                        <Divider />
                                        <Flex between fullWidth>
                                            <Text caption spaced weight='500'>{'Transfer Details'.toUpperCase()}</Text>
                                            <ImageButton src={Edit} onClick={() => this.setState({ step: 0 })} />
                                        </Flex>
                                        <Flex start wrap gap='24px'>
                                            <LabeledInput
                                                type='read-only'
                                                label="Transfer Amount"
                                                value={`$${toCurrency(transferAmount)}`}
                                                width='282px'
                                            />
                                            <LabeledInput
                                                type='read-only'
                                                label="From Account"
                                                value={`${fromAccount.productType} • ${fromAccount.mask}`}
                                                width='282px'
                                                capitalize
                                            />
                                            <LabeledInput
                                                type='read-only'
                                                label="To Account"
                                                value={`${toAccount.productType} • ${toAccount.mask}`}
                                                width='282px'
                                                capitalize
                                            />
                                            
                                            {
                                                purpose &&
                                                <LabeledInput type='read-only' label="Purpose" value={purpose} width='282px' />
                                            }

                                            {
                                                remittanceInfo &&
                                                <LabeledInput type='read-only' label="Remittance Information" value={remittanceInfo} width='568px' />
                                            }
                                        </Flex>

                                        <TextButton text='HIDE DETAILS' onClick={() => this.setState({ viewDetails: false })} icon={<img width='24px' height='24px' src={ChevronUp} />} />
                                    </FlexColumn>
                                }
                            </FlexColumn>
                        </CardContainer>
                }

                <Modal
                    visible={showResult}
                    footer={null}
                    closable={false}
                    width={456}
                    destroyOnClose={true}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <SuccessModal
                        title='Transfer Initiated'
                        description={`Your transfer of $${toCurrency(transferAmount)} has been initiated`}
                        buttonTitle='OKAY'
                        onConfirm={() => window.history.back()} />
                </Modal>
            </FlexColumn>
        )
    }

    handleOnChange = (event) => {
        const target = event.target
        this.saveUserInput(target.id || target.name, target.value)
    }

    twoFAValidated = () => {
        this.props.dispatch(addDataToStore(SAVE_DATA, { "TwoFAForTransfersValidated": true }))
        this.loadNext()
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer,
        bankingStore: state.bankingAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)))