import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

// Components
import { toCurrency } from '../../Utils/util'

import { FlexColumn } from '../Reusable/Container'
import { Text } from '../Reusable/Text'
import { Descriptions, Popover } from 'antd'
import styled from 'styled-components'
import { InfoCircleOutlined } from '@ant-design/icons'


export const DescItem = styled.div`
    display: flex;
    justify-content: flex-end;
`

export const DescLabel = styled.span`
    fontWeight: 500;
    fontSize: 16;
`

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
})

class AvailableFunds extends Component {

    render() {
        var { AvailableFunds } = this.props
        var amount = ''
        if (AvailableFunds && AvailableFunds.OverAdvanced === true) {
            amount = AvailableFunds.OverAdvancedAmount ? AvailableFunds.OverAdvancedAmount.toString() : "0";
            amount = amount.replace('$', '');
            amount = formatter.format(amount);
        }

        return (
            <FlexColumn>
                <Text size='20px'>Available Funds</Text>
                <Descriptions
                    bordered
                    column={1}
                    style={{ width: '100%', marginTop: 24 }}
                >
                    <Descriptions.Item label="Gross AR" labelStyle={{ fontWeight: 500, fontSize: 14 }} contentStyle={{ fontSize: 14 }}>
                        <DescItem>{AvailableFunds && !isNaN(AvailableFunds.GrossAR) ? `$${toCurrency(AvailableFunds.GrossAR)}` : "--"}</DescItem>
                    </Descriptions.Item>
                    <Descriptions.Item label="- Ineligible Invoices" labelStyle={{ fontSize: 14 }} contentStyle={{ fontSize: 14 }}>
                        <DescItem>{AvailableFunds && !isNaN(AvailableFunds.IneligibleInvoices) ? `$${toCurrency(AvailableFunds.IneligibleInvoices)}` : "--"}</DescItem>
                    </Descriptions.Item>
                    <Descriptions.Item label="Eligible AR" labelStyle={{ fontWeight: 500, fontSize: 14 }} contentStyle={{ fontSize: 14 }}>
                        <DescItem>{AvailableFunds && !isNaN(AvailableFunds.EligibleAR) ? `$${toCurrency(AvailableFunds.EligibleAR)}` : "--"}</DescItem>
                    </Descriptions.Item>
                    <Descriptions.Item label={
                        <Popover content={(
                            <FlexColumn>
                                <span>Certain payers may have customized advance rates</span>
                            </FlexColumn>
                        )}>
                            <div style={{ display: "flex" }}><span>* Standard AR Advance Rate</span><InfoCircleOutlined style={{ marginLeft: 4, color: 'rgba(0,0,0,.45)' }} /></div>
                        </Popover>
                    } labelStyle={{ fontWeight: 500, fontSize: 14 }} contentStyle={{ fontSize: 14 }}>
                        <DescItem>{AvailableFunds && !isNaN(AvailableFunds.ARAdvanceRate) ? `${AvailableFunds.ARAdvanceRate * 100}%` : "--"}</DescItem>
                    </Descriptions.Item>
                    <Descriptions.Item label={
                        <Popover content={(
                            <FlexColumn>
                                <span><b>Calculation:</b> Sum of the gross advanceable amounts for each of your collateral</span>
                            </FlexColumn>
                        )}>
                            <div style={{ display: "flex" }}><span>Gross Advanceable AR</span><InfoCircleOutlined style={{ marginLeft: 4, color: 'rgba(0,0,0,.45)' }} /></div>
                        </Popover>
                    } labelStyle={{ fontWeight: 500, fontSize: 14 }} contentStyle={{ fontSize: 14 }}>
                        <DescItem>{AvailableFunds && !isNaN(AvailableFunds.GrossAdvanceableAR) ? `$${toCurrency(AvailableFunds.GrossAdvanceableAR)}` : "--"}</DescItem>
                    </Descriptions.Item>
                    <Descriptions.Item label="- Adjustments" labelStyle={{ fontSize: 14 }} contentStyle={{ fontSize: 14 }}>
                        <DescItem>{AvailableFunds && !isNaN(AvailableFunds.Adjustments) ? `$${toCurrency(AvailableFunds.Adjustments)}` : "--"}</DescItem>
                    </Descriptions.Item>
                    <Descriptions.Item
                        label={
                            <Popover content={(
                                <FlexColumn>
                                    <span><b>Calculation:</b> Gross advanceable collateral minus any adjustments</span>
                                </FlexColumn>
                            )}>
                                <div style={{ display: "flex" }}>
                                    <DescLabel>Net Advanceable AR</DescLabel>
                                    <InfoCircleOutlined style={{ marginLeft: 4, color: 'rgba(0,0,0,.45)' }} />
                                </div>
                            </Popover>
                        }
                        labelStyle={{ fontWeight: 500, fontSize: 14 }} contentStyle={{ fontSize: 14 }}>
                        <DescItem>{AvailableFunds && !isNaN(AvailableFunds.NetAdvanceableAssets) ? `$${toCurrency(AvailableFunds.NetAdvanceableAssets)}` : "--"}</DescItem>
                    </Descriptions.Item>
                    <Descriptions.Item label="- Outstanding Principal" labelStyle={{ fontSize: 14 }} contentStyle={{ fontSize: 14 }}>
                        <DescItem>{AvailableFunds && !isNaN(AvailableFunds.BorrowerAdvanceTaken) ? `$${toCurrency(AvailableFunds.BorrowerAdvanceTaken)}` : "--"}</DescItem>
                    </Descriptions.Item>
                    <Descriptions.Item label=
                        {
                            <Popover content={(
                                <FlexColumn>
                                    <span><b>Calculation:</b> Net advanceable collateral or credit limit,</span>
                                    <span>whichever is lesser, minus the outstanding principal</span>
                                </FlexColumn>
                            )}>
                                <div style={{ display: "flex" }}>
                                    <DescLabel>Available Funds</DescLabel>
                                    <InfoCircleOutlined style={{ marginLeft: 4, color: 'rgba(0,0,0,.45)' }} />
                                </div>
                            </Popover>
                        }
                        labelStyle={{ fontWeight: 500, fontSize: 14 }} contentStyle={{ fontSize: 14 }}>
                        <DescItem>{AvailableFunds && !isNaN(AvailableFunds.AvailableFunds) ? `$${toCurrency(AvailableFunds.AvailableFunds)}` : "--"}</DescItem>
                    </Descriptions.Item>
                </Descriptions>
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.creditAppReducer,
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AvailableFunds))