import { Modal, Select } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import { addDataToStore, BUSINESS_INFO, PERSONAL_INFO, REMOTE_SAVE_DATA, SAVE_CO_OWNER } from '../../../Actions/actions';
import { ErrorAlert } from '../../Reusable/Alert';
import { TextButton } from '../../Reusable/Button';
import { Flex } from '../../Reusable/Container';
import { Divider } from '../../Reusable/Divider';

import { FlexColumn } from '../../Reusable/Container';
import FlowViewComponent from '../../Reusable/Refresh/FlowViewComponent';
import OwnerProfile from './OwnerProfile';
import { LabeledInput } from '../../Reusable/Input';
import { Text } from '../../Reusable/Refresh/Text';
import ModalClose from '../../../Images/modal-close.png'
import { OwnerCard } from '../../Reusable/Refresh/Card';
import { getTotalOwnershipPercentage } from '../../../Utils/util';
import Image from '../../Reusable/Image';
import ChevronDown from '../../../Images/chevron-down-nopad.png'

class AuthSignerInfo extends Component {

    state = {}

    handleSelect = (selectedOwners) => {
        console.log("authorizedCoOwners selectedOwners", selectedOwners)
        var { onboardingStore } = this.props
        onboardingStore.coOwnersInfo.forEach(owner => {
            owner.authorizedSigner = (selectedOwners.includes(owner.email) || selectedOwners.includes(`${owner.firstName} ${owner.lastName} (${owner.email})`))
            this.props.dispatch(addDataToStore(SAVE_CO_OWNER, { ownerInfo: owner }))
        })
    }

    setErrorField = (id, msg) => this.setState({ errorField: id, errorMessage: msg })

    validateFlow = () => {
        var { flowView } = this.props

        this.props.dispatch(addDataToStore(REMOTE_SAVE_DATA, flowView.FlowStep))
        this.props.loadNext()
    }

    render() {
        var { flowView, currentViewIndex, loadPrevView, onboardingStore, theme, reviewView } = this.props
        var { errorField, errorMessage, selectedOwner, showOwnerModal } = this.state
        var { firstName, lastName, businessTitle, controlPerson } = onboardingStore.personalInfo
        var allCoOwners = onboardingStore.coOwnersInfo
        var authorizedCoOwners = allCoOwners.filter(owner => owner.authorizedSigner)
        var signers = onboardingStore.signersInfo
        var combinedSigners = [onboardingStore.personalInfo].concat((authorizedCoOwners || []).concat(signers || []))
        console.log("authorizedCoOwners", authorizedCoOwners, onboardingStore.personalInfo, allCoOwners)
        var childViews = (
            <FlexColumn start style={{ marginTop: "0px", height: '100%' }}>
                <Text style={{ maxWidth: '650px' }}>We need information about authorized signers on this account. Authorized signers on business bank accounts are persons who are legally permitted to transact or spend from that account.</Text>
                <Flex start style={{ width: "100%", marginTop: "24px" }}>
                    <div style={{ width: "25%", marginRight: 12 }}>
                        <LabeledInput
                            type="read-only"
                            label="Name"
                            value={`${firstName} ${lastName}`}
                        />
                    </div>
                    <div style={{ width: "25%", marginRight: 12, marginLeft: 12 }}>
                        <LabeledInput
                            type="read-only"
                            label="Title"
                            value={businessTitle}
                        />
                    </div>
                </Flex>
                <FlexColumn start left>
                    <Divider />
                    {controlPerson ? "" :
                        <>
                            <Text uppercase spacing='0.08em' weight='500' color={theme.colors.secondary6}>ADD OTHER AUTHORIZED SIGNERS</Text>
                            <FlexColumn style={{ width: "50%" }}>
                                <LabeledInput
                                    label="Select from previously provided individuals"
                                    type="select"
                                    mode="multiple"
                                    key="authSigner"
                                    showArrow={true}
                                    placeholder="Select"
                                    onChange={items => this.handleSelect(items)}
                                    value={authorizedCoOwners.map(owner => `${owner.firstName} ${owner.lastName} (${owner.email})`)}
                                    id="authSigner"
                                    error={errorField == "authSigner"}
                                    errorMessage={errorMessage}
                                    noHeight
                                >
                                    {
                                        allCoOwners.map(item => {
                                            return (
                                                <Select.Option disabled={(item.email == onboardingStore.personalInfo.email)} key={item.email} value={item.email} title={`${item.firstName} ${item.lastName} (${item.email})`} label={`${item.firstName} ${item.lastName} (${item.email})`}>
                                                    {`${item.firstName} ${item.lastName} (${item.email})`}
                                                </Select.Option>
                                            )
                                        })
                                    }
                                </LabeledInput>
                            </FlexColumn>
                        </>
                    }

                    <Flex start style={{ marginTop: '16px' }}>
                        <TextButton text={'Add Authorized Signer'.toUpperCase()} onClick={() => this.setState({ showOwnerModal: true })} />
                    </Flex>
                    <Flex wrap start style={{ marginTop: '16px' }}>
                        {
                            signers.map((item, j) => {
                                return <OwnerCard padding='12px 0' hidePercentage={true} ownerInfos={item} theme={theme} key={j} onClick={() => this.setState({ showOwnerModal: true, selectedOwner: item })} />
                            })
                        }
                    </Flex>
                </FlexColumn>
            </FlexColumn>
        );
        if (reviewView) {
            return (
                <Flex start fullWidth left>
                    {
                        (combinedSigners.length == 0) &&
                        <Text>None added</Text>
                    }
                    {
                        combinedSigners.map((item, j) => {
                            return <OwnerCard padding='12px 0' hidePercentage={true} ownerInfos={item} theme={theme} key={j} onClick={() => this.setState({ showOwnerModal: true, selectedOwner: item })} />
                        })
                    }
                </Flex>
            )
        }
        return (
            <>
                <FlowViewComponent
                    flowView={flowView}
                    currentViewIndex={currentViewIndex}
                    loadPrev={loadPrevView}
                    childViews={childViews}
                    submitLoading={this.state.submitLoading}
                    width={'100%'}
                    back={currentViewIndex > 0}
                    padding='0 64px'
                    {...this.props}
                    loadNext={this.validateFlow}
                />
                <Modal
                    visible={showOwnerModal}
                    footer={null}
                    closable={true}
                    width={700}
                    destroyOnClose={true}
                    onCancel={() => {
                        this.setState({ showOwnerModal: false, selectedOwner: null })
                    }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                    style={{ top: '20px' }}
                >
                    <OwnerProfile
                        ownerType="signer" // coOwner, controlPerson, signer
                        selectedOwner={selectedOwner}
                        flowStep={"Apply.ControlPerson"} // Set remote save to prev step
                        onSubmit={(data) => {
                            this.setState({ showOwnerModal: false, selectedOwner: null })
                        }}
                    />
                </Modal>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        onboardingStore: state.onboardingAppReducer,
        aionStore: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(AuthSignerInfo));