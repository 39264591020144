import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import { Table, Skeleton } from 'antd'
import moment from 'moment'
import Dimensions from 'react-dimensions'

import { Flex, FlexColumn } from '../../Reusable/Container'
import { Text, Tag } from '../../Reusable/Text'
import { renderBankIcon } from '../../../Utils/util'

// Images
import Individual from '../../../Images/individual-recipient.png'
import Business from '../../../Images/business-recipient.png'
import TableView from '../../Reusable/TableView';

const dFormat = "MMM Do, YYYY (z)"
const etTimeZone = "America/New_York"

const RecipientTable = (props) => {
    const history = useHistory();
    const { recipients, pagination, loading, showHeader, paymentMethodRecipientMap, showRowSelection, responsive, view, onViewTransfer } = props

    useEffect(() => {
        // console.log('RecipientsTable: ', recipients)
    }, [])

    function viewRecipient(recipient) {
        history.push({
            pathname: '/banking/recipients/details',
            state: { recipient }
        })
    }

    var columns = [
        {
            title: 'Name',
            dataIndex: 'nameOnAccount',
            key: 'nameOnAccount',
            width: 275,
            render: (item, record) => <FlexColumn start gap='8px'>
                <Flex start gap='12px' centerHorizontally>
                    {
                        record.type === 'Individual' ?
                            <img src={Individual} width='24px' height='24px' />
                            :
                            <img src={Business} width='24px' height='24px' />
                    }
                    {
                        view ?
                            
                            <Flex centerHorizontally gap='8px'>
                                <a style={{ textDecoration: "underline", color: "#1199FF", marginLeft: 12 }}><Text primary>{item}</Text></a>
                                {record.sourceApplication == "Payables" ? <Tag title='Created in bill payment'>Vendor</Tag> : null}
                            </Flex>
                            :
                            <Flex centerHorizontally gap='8px'>
                                <Text>{item}</Text>
                                {record.sourceApplication == "Payables" ? <Tag title='Created in bill payment'>Vendor</Tag> : null}
                            </Flex>
                            
                    }
                </Flex>
            </FlexColumn>
        },
        {
            title: 'Nickname',
            dataIndex: 'nickName',
            key: 'nickName',
            width: 275,
        },
        {
            title: 'Bank Details',
            dataIndex: 'nameOnAccount',
            key: 'nameOnAccount',
            render: (item, recipient) => <FlexColumn start gap='8px'>
                {
                    paymentMethodRecipientMap &&
                    <Flex start wrap gap='8px'>
                        {
                            (paymentMethodRecipientMap[recipient.objectId] || []).map(paymentMethod => {
                                return <Tag onClick={() => onViewTransfer(paymentMethod, recipient)}>{renderBankIcon(paymentMethod.bankDetail)}{paymentMethod.nickName} • {paymentMethod.bankDetail.accountNumber ? paymentMethod.bankDetail.accountNumber.slice(-4) : ""}</Tag>
                            })
                        }
                    </Flex>
                }
            </FlexColumn>
        },
        {
            title: 'Last Transfer',
            dataIndex: 'lastTransferDate',
            key: 'lastTransferDate',
            align: 'right',
            width: 205,
            render: date => <>
                {
                    date ?
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Text color='#444444' margin='0 0 0 8px'>{moment.utc(date).tz(etTimeZone).format(dFormat)}</Text>
                        </div>
                        :
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Text color='#444444' margin='0 0 0 8px'>--</Text>
                        </div>
                }
            </>
        },
    ]

    return (<TableView
        scroll={responsive && { y: props.containerHeight }}
        id="recipient-table"
        titleText='Recipients'
        tableLayout='auto'
        columns={columns}
        dataSource={recipients}
        rowKey='objectId'
        pagination={pagination}
        showHeader={showHeader}
        style={{ minHeight: "600px", height: '100%' }}
        // onChange={this.handleTableChange}
        loading={loading}
        rowSelection={showRowSelection && {
            type: "checkbox",
            onChange: props.handleRowSelection,
            selectedRowKeys: props.selectedRowKeys
        }}
        ctaContent={props.ctaContent}
        onRow={ 
            (record, rowIndex) => {
                return {
                    onClick: event => {
                        viewRecipient(record)
                    }, // click row
                }
            }
        }
    />
    )
}


export default Dimensions({ elementResize: true })(RecipientTable)