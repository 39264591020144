/*
 * action types
 */

export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const CONFIRM_PHONE = 'CONFIRM_PHONE'
export const SIGNIN = 'SIGNIN'
export const SIGNOUT = 'SIGNOUT'
export const ADD_CUSTOMERS = 'ADD_CUSTOMERS'
export const SAVE_DATA = 'SAVE_DATA'
export const TEMPORARY_FORM_DATA = 'TEMPORARY_FORM_DATA'
export const SAVE_INVOICES = 'SAVE_INVOICES'
export const SAVE_TRANSACTIONS = 'SAVE_TRANSACTIONS'
export const SAVE_ADVANCES = 'SAVE_ADVANCES'

export const APP_STATE = 'APP_STATE'
export const PERSONAL_INFO = 'PERSONAL_INFO'
export const BUSINESS_INFO = 'BUSINESS_INFO'
export const NEW_CO_OWNER = 'NEW_CO_OWNER'
export const DELETE_CO_OWNER = 'DELETE_CO_OWNER'
export const CO_OWNER_INFO = 'CO_OWNER_INFO'
export const CO_OWNER_APP_INFO = 'CO_OWNER_APP_INFO'
export const SIGNERS_INFO = 'SIGNERS_INFO'
export const SET_CONTROL_PERSON = 'SET_CONTROL_PERSON'
export const PPP_INFO = 'PPP_INFO'
export const DEBT_SCHEDULE = 'DEBT_SCHEDULE'
export const SUPPORTING_DOCS = 'SUPPORTING_DOCS'
export const ADD_SUPPORTING_DOCS = 'ADD_SUPPORTING_DOCS'
export const DELETE_SUPPORTING_DOCS = 'DELETE_SUPPORTING_DOCS'
export const NO_DOCUMENT_TOGGLE = 'NO_DOCUMENT_TOGGLE'
export const DOCUMENT_NOTE = 'DOCUMENT_NOTE'
export const RESET_BNK_STATE = 'RESET_BNK_STATE'
export const RESET_ONBOARDING_STATE = 'RESET_ONBOARDING_STATE'
export const RESET_MAIN_STATE = 'RESET_MAIN_STATE'
export const SOFT_RESET_MAIN_STATE = 'SOFT_RESET_MAIN_STATE'
export const RESET_CREDIT_STATE = 'RESET_CREDIT_STATE'
export const ONB_SAVE_DATA = 'ONB_SAVE_DATA'
export const UPDATE_LOGO = 'UPDATE_LOGO'
export const UPDATE_PROFILE_IMG = 'UPDATE_PROFILE_IMG'
export const NEW_SIGNER = 'NEW_SIGNER'
export const DELETE_SIGNER = 'DELETE_SIGNER'
export const SIGNER_INFO = 'SIGNER_INFO'
export const ADD_CONTROL_PERSON = 'ADD_CONTROL_PERSON'
export const REMOTE_SAVE_DATA = 'REMOTE_SAVE_DATA'
export const REMOTE_SAVE_USER_STATUS = 'REMOTE_SAVE_USER_STATUS'
export const UAM_SAVE_BUSINESS = 'UAM_SAVE_BUSINESS'
export const UAM_SAVE_USERINFO = 'UAM_SAVE_USERINFO'
export const SAVE_CO_OWNER = 'SAVE_CO_OWNER'
export const SAVE_SIGNER = 'NEW_SIGNER'
export const SAVE_CO_OWNER_CACHE = 'SAVE_CO_OWNER_CACHE'
export const RESET_CO_OWNER_CACHE = 'RESET_CO_OWNER_CACHE'
export const NOTIFICATIONS = 'NOTIFICATIONS'
export const UPDATE_PG_TITLE = 'UPDATE_PG_TITLE'
export const CODAT_DATA = 'CODAT_DATA'

// Banking Actions
export const BNK_SAVE_DATA = 'BNK_SAVE_DATA'

// Bookkeeping Actions
export const BOOKKEEPING_SAVE_DATA = 'BOOKKEEPING_SAVE_DATA'
export const RESET_BOOKKEEPING_DATA = 'RESET_BOOKKEEPING_DATA'

// Credit Actions
export const CREDIT_SAVE_DATA = 'CREDIT_SAVE_DATA'

// Receivables Actions
export const RECEIVABLES_SAVE_DATA = 'RECEIVABLES_SAVE_DATA'
export const RESET_RECEIVABLES_STATE = 'RESET_RECEIVABLES_STATE'

// User Defaults Actions
export const USERDEFAULTS_SAVE_FLAG = 'USERDEFAULTS_SAVE_FLAG'

// User Access Management
export const UAM_SAVE_PERMISSIONS = 'UAM_SAVE_PERMISSIONS'

// Session Timeout
export const WINDOW_CLOSE_TIMESTAMP = 'WINDOW_CLOSE_TIMESTAMP'

// Error Modal
export const ERROR_MODAL = 'ERROR_MODAL'

/*
 * action creators
 */

export function addUsername(username, phone, firstName, lastName) {
	return({
		username: username,
		phone: phone,
		firstName: firstName,
		lastName: lastName,
		type: SIGNUP_SUCCESS
	})
}

export function confirmUsername() {
	return({
		type: CONFIRM_PHONE
	})
}

export function completeSignin(data) {
	return({
		type: SIGNIN,
		data: data
	})
}

export function handleErrorModal(data) {
	return({
		type: ERROR_MODAL,
		data: data
	})
}

export function resetStore(type) {
	return({
		type: type
	})
}

export function addDataToStore(type, data) {
	return({
		type: type,
		data: data
	})
}