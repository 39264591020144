import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import update from 'immutability-helper';
import moment from 'moment';
import {
    message, Select, Popconfirm, Divider, Switch, Space, Tooltip, Popover, Tabs, Table
} from 'antd';
import { DeleteOutlined, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';

// Components
import { Button } from '../../Reusable/Button';
import { Text, Tag } from '../../Reusable/Text';
import { Flex, FlexColumn, InputContainer } from '../../Reusable/Container';
import { LabeledInput } from '../../Reusable/Input';
import { ErrorAlert } from '../../Reusable/Alert';
import AddModalMSA from './AddModalMSA';
import AddModalWO from './AddModalWO';

import environment from '../../../environment';
import { apiPOSTReq } from '../../../Utils/api';
import UploadFiles from '../../Reusable/UploadFiles';
import { isEmailValid, addressObjectToStr, formatPhoneText, capitalizeWords, getNameObj, getValidFullName, defaultPaymentTerms, isValidUrl } from '../../../Utils/util';
import { RECEIVABLES_SAVE_DATA, addDataToStore } from '../../../Actions/actions';
import PageHeader from '../../Reusable/PageHeader';
const { Option } = Select;
const { TabPane } = Tabs;

const WOStatusTag = (options) => {
    let statusMsg;
    let statusColor = "blue";
    switch (options.status) {
        case "VALIDATED":
            statusColor = "green";
            statusMsg = "Approved"
            break
        case "REJECTED":
            statusColor = "red";
            statusMsg = "Rejected"
            break
        default:
            statusMsg = "Verifying"
            break
    }
    return (<Tag color={statusColor}>{statusMsg}</Tag>);
}

class NewCustomer extends Component {
    state = {
        customer: this.props.customer || { primaryEmailAddress: { address: null }, invoiceRecipientEmail: [] },
        loading: false,
        paymentTermsItems: defaultPaymentTerms
    }

    componentDidMount() {
        console.log("NewCustomer componentDidMount", this.state.customer);
        if ((this.state.customer.invoiceRecipientEmail || []).length == 0) this.handleAddEmail();
        if (!this.state.customer.customerId) {
            this.generateTempCustomerId();
        }
        this.fetchCustomers({
            pagination: {
                current: 1,
                pageSize: 500
            }
        });
        this.getMSAForCustomer();
        this.getWOsForCustomer();
    }

    generateTempCustomerId = () => {
        apiPOSTReq(`${environment.iveBaseUrl}/ive/bc/generateTemporaryCustomerId`, {}, {}, (err, resp) => {
            try {
                const data = resp;
                if (data.result) {
                    this.setState({ customer: update(this.state.customer, { customerId: { $set: data.customerId } }) })
                } else {
                    throw Error(data.responseMessage || data.error);
                }
            } catch (error) {
                //console.log("generateTemporaryCustomerId", error.stack, err, resp)
            }
        })
    }

    getMSAForCustomer = () => {
        const body = {
            "BusinessId": this.props.aionStore.BusinessUniqueKey,
            "CustomerId": this.state.customer.customerId,
        }
        console.log("getMSAForCustomer", body, this.state.customer);
        apiPOSTReq(`${environment.iveBaseUrl}/ive/msa/getMSAForCustomer`, this.iveHeaders, body, (err, resp) => {
            try {
                const data = resp;
                if (data.result) {
                    this.setState({
                        msaObj: data.msaObj
                    })
                } else {
                    throw Error("Could not getActiveMSAs.")
                }
            } catch (error) {
                //console.log("ERRR", error.stack)
                //message.error(`Error adding MSA `);
            }
        })
    }

    getWOsForCustomer = () => {
        const body = {
            "BusinessId": this.props.aionStore.BusinessUniqueKey,
            "CustomerId": this.state.customer.customerId,
        }
        apiPOSTReq(`${environment.iveBaseUrl}/ive/po/getPOsForCustomer`, this.iveHeaders, body, (err, resp) => {
            try {
                const data = resp;
                if (data.result) {
                    //console.log("POSs", data)
                    this.setState({
                        workOrders: data.pos
                    })
                } else {
                    throw Error("Could not fetch work orders.")
                }
            } catch (error) {
                //console.log("ERRR", error.stack, err, resp)
                // message.error(`Could not fetch work orders.`);
            }
        })
    }

    fetchCustomers(options) {
        const { pagination } = options;
        // Fetch customer list
        const headers = {
            AionCurrentBiz: this.props.aionStore.BusinessUniqueKey,
            AionAuth: this.props.aionStore.UAM.encryptedAuthHeader
        }
        const body = {
            "BusinessId": this.props.aionStore.BusinessUniqueKey,
            "size": pagination.pageSize,
            "page": pagination.current - 1
        }
        apiPOSTReq(`${environment.iveBaseUrl}/ive/bc/getActiveCustomers`, headers, body, (err, resp) => {
            try {
                const data = resp;
                // //console.log("/getActiveCustomers", data)
                if (data.result) {
                    this.props.dispatch(addDataToStore(RECEIVABLES_SAVE_DATA, { ActiveCustomers: data.customers }))
                    this.setState({
                        customersLoaded: true,
                        pagination: {
                            ...pagination,
                            total: data.count
                        }
                    })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                //console.log("ERRR getActiveCustomers", error, resp);
            }
        })
    }

    // Save
    handleSubmit = () => {
        const customer = this.state.customer;
        var { phone, primaryEmailAddress, invoiceRecipientEmail, displayName, paymentTerms, billingAddress, shippingAddress, hasParent, parentCustomerId, webSite } = customer;
        billingAddress = billingAddress || {};
        shippingAddress = shippingAddress || {};
        phone = phone || "";
        primaryEmailAddress = primaryEmailAddress || {};
        const otherEmails = invoiceRecipientEmail || [];
        // We are checking for display name here as in QBO display name is the required field
        if (!displayName) {
            ErrorAlert({ description: `Please enter a company name.` });
            return;
        }
        // if(hasParent && !parentCustomerId) {
        //     ErrorAlert({description: `Please select a parent company name.`});
        //     return;
        // }
        // if(phone.length !== 12) {
        //     ErrorAlert({description: "Please enter a valid 10 digit US phone number."});
        //     return;
        // };
        if (!isEmailValid(primaryEmailAddress.address)) {
            ErrorAlert({ description: "Please enter a valid contact email address." });
            return;
        };

        var otherEmailsValidated = true;
        var invalidEmail = "";
        otherEmails.map(item => {
            if (!isEmailValid(item || "")) {
                otherEmailsValidated = false
                invalidEmail = item;
            };
        })
        if (!otherEmailsValidated) {
            if (invalidEmail == null) ErrorAlert({ description: "Please enter a valid invoice recipient email." });
            else ErrorAlert({ description: `${invalidEmail} is an invalid invoice recipient email, please update it.` });
            return;
        };

        if (!paymentTerms) {
            ErrorAlert({ description: `Please select payment terms.` });
            return;
        }
        if (!billingAddress.line1) {
            ErrorAlert({ description: `Please add a billing address` });
            return;
        }
        if (!shippingAddress.line1) {
            ErrorAlert({ description: `Please add a shipping address` });
            return;
        }
        if (webSite) {
            var tempWebSiteUrl = "http://" + webSite.replace("http://", "")
            if (!isValidUrl(tempWebSiteUrl)) {
                ErrorAlert({ description: `Please enter a valid website url.` });
                return;
            } else {
                customer.webSite = tempWebSiteUrl
            }
        }
        this.setState({ loading: true });
        const body = {
            Customer: customer,
        }

        apiPOSTReq(`${environment.iveBaseUrl}/ive/bc/save`, this.iveHeaders, body, (err, resp) => {
            this.setState({ loading: false });
            try {
                const data = resp;
                console.log("newCustomer", JSON.stringify(resp));
                if (data.result) {
                    console.log("/ive/bc/save", JSON.stringify(data));
                    message.success(`${displayName} created!`);
                    if (data.responseMessage != null && data.responseMessage != "Success") {
                        ErrorAlert({ description: data.responseMessage });
                    }
                    this.props.submitComplete();
                } else {
                    throw Error(data.responseMessage || data.error);
                }
            } catch (error) {
                //console.log("ERRR", error.stack, err, resp)
                ErrorAlert({ description: error.message });
            }
        })
    }

    handlePrimaryEmailChange = (event) => {
        this.setState({
            customer: update(this.state.customer, { primaryEmailAddress: { address: { $set: event.target.value } } })
        })
    }

    handleEmailChange = (event) => {
        this.setState({
            customer: update(this.state.customer, { invoiceRecipientEmail: { [event.target.getAttribute('index')]: { $set: event.target.value } } })
        })
    }

    handlePhoneChange = (event) => {
        this.setState({
            customer: update(this.state.customer, { phone: { $set: (event.target.value) } })
        })
    }

    handleAddEmail = () => {
        this.setState({
            customer: update(this.state.customer, this.state.customer.invoiceRecipientEmail ? { invoiceRecipientEmail: { $push: [null] } } : { invoiceRecipientEmail: { $set: [] } })
        });
    }

    handleDeleteEmail = (event) => {
        this.setState({
            customer: update(this.state.customer, { invoiceRecipientEmail: { $splice: [[[event.currentTarget.getAttribute('index')], 1]] } })
        });
    }

    handlePaymentSelection = (value) => {
        this.setState({
            customer: update(this.state.customer, { paymentTerms: { $set: value } })
        });
    }

    handleParentCustomerSelect = (value, option) => {
        this.setState({
            customer: update(this.state.customer, { parentCustomerId: { $set: value }, parentCustomerName: { $set: option.name } })
        });
    }

    getLocation = (location, formattedAddress) => {
        console.log("NEW LOCATION RECEIVED", location);
        if (!location)
            this.setState({
                customer: update(this.state.customer, {
                    billingAddress: {
                        $set: {
                            line1: null,
                            line2: null,
                            city: null,
                            countrySubDivisionCode: null,
                            postalCode: null
                        }
                    }
                })
            });
        else
            this.setState({
                customer: update(this.state.customer, {
                    billingAddress: {
                        $set: {
                            line1: location.line1,
                            line2: location.line2,
                            city: location.city,
                            countrySubDivisionCode: location.countrySubDivisionCode,
                            postalCode: location.postalCode
                        }
                    }
                })
            });
    }

    getShippingLocation = (location, formattedAddress) => {
        if (!location)
            this.setState({
                customer: update(this.state.customer, {
                    shippingAddress: {
                        $set: {
                            line1: null,
                            line2: null,
                            city: null,
                            countrySubDivisionCode: null,
                            postalCode: null
                        }
                    }
                })
            });
        else
            this.setState({
                customer: update(this.state.customer, {
                    shippingAddress: {
                        $set: {
                            line1: location.line1,
                            line2: location.line2,
                            city: location.city,
                            countrySubDivisionCode: location.countrySubDivisionCode,
                            postalCode: location.postalCode
                        }
                    }
                })
            });
    }

    handleTextChange = (event) => {
        var val;
        switch (event.target.id) {
            case "name":
                var name = capitalizeWords(event.target.value);
                var nameObj = getNameObj(getValidFullName(name));
                val = { firstName: { $set: nameObj.FirstName }, lastName: { $set: nameObj.LastName } }
                break;
            case "companyName":
                var captitalizedName = { $set: (event.target.value) };
                val = { [event.target.id]: captitalizedName, displayName: captitalizedName, printOnCheckName: captitalizedName };
                break;
            case "primaryEmailAddress":
                val = { [event.target.id]: { address: { $set: event.target.value } } };
                break;
            case "phone":
                val = { phone: { $set: formatPhoneText(event.target.value) } };
                break;
            case "paymentTermsCustom":
                val = { paymentTermsCustom: { $set: `Net ${event.target.value}` } };
                break;
            default:
                val = { [event.target.id]: { $set: event.target.value } };
                break;
        }
        console.log("handleTextChange update", this.state.customer, val);
        this.setState({
            customer: update(this.state.customer, val)
        });
    }

    handleSelection = (value) => {
        this.setState({
            customer: update(this.state.customer, { paymentTerms: { $set: value } })
        });
    }

    handleShipAddrSwitch = (checked) => {
        this.setState({ makeShipAddrAsBillAddr: checked });
        var stateToUpdate = {
            makeShipAddrAsBillAddr: checked
        };
        if (checked) stateToUpdate.customer = update(this.state.customer, { "shippingAddress": { $set: this.state.customer.billingAddress } });
        this.setState(stateToUpdate);
    }

    handleFileUpload = (attachments) => {
        this.setState({
            customer: update(this.state.customer, { attachments: { $set: attachments } })
        });
    }

    handleCustomPaymentTerm = () => {
        var { paymentTermsItems, customer } = this.state;
        //console.log("paymentTermsCustom", customer.paymentTermsCustom)
        if (customer.paymentTermsCustom) this.setState({ paymentTermsItems: [...paymentTermsItems, customer.paymentTermsCustom] })
    }

    /* MSA */

    handleShowMSAModal = () => {
        this.setState({ showAddModalMSA: true });
    }

    handleMSAAdded = () => {
        this.getMSAForCustomer();
        this.setState({ showAddModalMSA: false });
    }

    handleDeleteMSA = () => {
        const body = {
            "BusinessId": this.props.aionStore.BusinessUniqueKey,
            "CustomerId": this.state.customer.customerId,
        }
        apiPOSTReq(`${environment.iveBaseUrl}/ive/msa/delete`, this.iveHeaders, body, (err, resp) => {
            try {
                const data = resp;
                if (data.result) {
                    this.getMSAForCustomer();
                } else {
                    throw Error("Could not delete MSA.")
                }
            } catch (error) {
                //console.log("ERRR", error.stack)
                message.error("Could not delete MSA.");
            }
        })
    }

    handleCancelMSA = () => {
        this.setState({ showAddModalMSA: false });
    }

    /* Work Order */

    handleShowWOModal = () => {
        this.setState({ showAddModalWO: true });
    }

    handleWOAdded = () => {
        this.getWOsForCustomer();
        this.setState({ showAddModalWO: false });
    }

    handleDeleteWO = (PONumber) => {
        const body = {
            "BusinessId": this.props.aionStore.BusinessUniqueKey,
            "CustomerId": this.state.customer.customerId,
            "PONumber": PONumber
        }
        //console.log("handleDeleteWO", body)
        apiPOSTReq(`${environment.iveBaseUrl}/ive/po/delete`, this.iveHeaders, body, (err, resp) => {
            try {
                const data = resp;
                if (data.result) {
                    this.getWOsForCustomer();
                } else {
                    throw Error("Could not delete Work Order.")
                }
            } catch (error) {
                //console.log("ERRR", error, err, resp.error)
                message.error("Could not delete Work Order.");
            }
        })
    }

    handleCancelWO = () => {
        this.setState({ showAddModalWO: false });
    }

    onCustomerMarkInactive = (customer) => {
        const headers = {
            AionCurrentBiz: this.props.aionStore.BusinessUniqueKey,
            AionAuth: this.props.aionStore.UAM.encryptedAuthHeader // This will be replaced with the token received from the access mgmt service
        }
        const body = {
            "BusinessId": this.props.aionStore.BusinessUniqueKey,
            "CustomerId": customer.customerId
        }
        apiPOSTReq(`${environment.iveBaseUrl}/ive/bc/deactivate`, headers, body, (err, resp) => {
            try {
                const data = resp;
                if (data.result) {
                    message.success(`${customer.displayName} was marked as inactive.`);
                    this.props.history.push('/receivables/customers');
                }
            } catch (error) {
                message.error(`Error marking inactive`);
                //console.log("ERRR", error, err, resp)
            }
        })
    }

    render() {
        var { customer, makeShipAddrAsBillAddr, paymentTermsItems } = this.state;
        var { theme, receivablesStore, aionStore } = this.props

        var { ActiveCustomers } = receivablesStore;
        ActiveCustomers = ActiveCustomers || [];
        var { companyName, displayName, webSite, firstName, lastName, notes, attachments, billingAddress, shippingAddress, phone, paymentTerms, customerId, areligible, hasParent, parentCustomerId, parentCustomerName, billWithParent } = customer;
        shippingAddress = shippingAddress || {};
        displayName = displayName || companyName;
        hasParent = true;//hasParent || parentCustomerId;
        var name;
        if (firstName) name = `${firstName} ${lastName}`;
        const labelStyle = { width: "200pt" };
        const locationLabelStyle = { width: "320pt" };
        //console.log("customer state", customer);

        var woTableColumns = [
            {
                title: 'Document',
                dataIndex: 'ponumber',
                key: 'ponumber',
                render: ponumber => `#${ponumber}`
            },
            {
                title: 'Date',
                dataIndex: 'metaData',
                key: 'Date',
                render: item => (moment(item.createTime).format('ll')),
            },
            {
                title: 'Action',
                key: 'action',
                width: 150,
                render: (text, item) => (
                    <Space size="middle">
                        {/* <a target="_blank" href={item.documentUrl.uri}><FileTextOutlined style={{ fontSize: 18 }} /></a> */}
                        <Popconfirm title="Are you sure you want to delete this Work Order?" onConfirm={() => this.handleDeleteWO(item.ponumber)}>
                            <a><DeleteOutlined style={{ cursor: 'pointer', fontSize: 18 }} /></a>
                        </Popconfirm>
                    </Space>
                ),
            }
        ];
        if (areligible) woTableColumns.splice(2, 0, {
            title: 'Status',
            dataIndex: 'verificationStatus',
            key: 'verificationStatus',
            render: verificationStatus => (<WOStatusTag status={verificationStatus} />)
        });

        return (
            <>
                <FlexColumn center style={{ minHeight: "80%" }}>
                    <div style={{ width: "95%" }}>
                        <PageHeader
                            titleText={(displayName && displayName !== '') ? displayName : "New Customer"}
                            ctaContent={<Button permtype="Override" solid loading={this.state.loading} onClick={this.handleSubmit} text='SAVE' />}
                        />

                        <FlexColumn>
                            <Flex between>
                                <div style={{ width: hasParent ? "320pt" : "100%" }}>
                                    <LabeledInput
                                        style={{ width: "100%" }}
                                        autoFocus
                                        label='Name*'
                                        id="companyName"
                                        key="companyName"
                                        placeholder="Acme Inc."
                                        value={displayName}
                                        onChange={this.handleTextChange}
                                    />
                                </div>
                                {
                                    hasParent &&
                                    <div style={{ width: "320pt" }}>
                                        <LabeledInput
                                            customlabelcomponent={
                                                <Flex between centerHorizontally style={{ width: '100%', marginBottom: 8 }}>
                                                    <Text color='#7384AA' size='14px' weight={400}>Parent Company</Text>
                                                    <Flex start centerHorizontally gap='8px'>
                                                        <Text color='#7384AA' size='14px' weight={400}>Bill with parent?</Text>
                                                        <Switch size="small" defaultChecked={this.state.customer.billWithParent} onChange={(checked) => this.setState({ customer: update(customer, { billWithParent: { $set: checked } }) })} />
                                                    </Flex>
                                                </Flex>
                                            }
                                            type="select"
                                            className="no-left-padding"
                                            showSearch
                                            key="parentCustomer"
                                            placeholder="Select parent company"
                                            optionFilterProp="children"
                                            value={parentCustomerName}
                                            onChange={this.handleParentCustomerSelect}
                                            filterOption={(input, option) => {
                                                return option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }}
                                        >
                                            {ActiveCustomers.map(item => <Option key={item.customerId} id="selectedParentCustomer" name={item.displayName || item.companyName} value={item.customerId} style={{ backgroundColor: "transparent" }}>{item.displayName || item.companyName}</Option>)}
                                        </LabeledInput>
                                    </div>
                                }
                            </Flex>
                            <Flex between>
                                <div style={labelStyle}>
                                    <LabeledInput
                                        label="Contact Name*"
                                        labelcolor={theme.colors.secondary3}
                                        id="name"
                                        key="name"
                                        placeholder="Tony Adams"
                                        defaultValue={name}
                                        onChange={this.handleTextChange}
                                    />
                                </div>
                                <div style={labelStyle}>
                                    <LabeledInput
                                        label="Contact Email*"
                                        labelcolor={theme.colors.secondary3}
                                        type="email"
                                        id="primaryEmailAddress"
                                        key="primaryEmailAddress"
                                        value={customer.primaryEmailAddress && customer.primaryEmailAddress.address}
                                        onChange={this.handleTextChange}
                                        placeholder="Email"
                                    />
                                </div>
                                <div style={labelStyle}>
                                    <LabeledInput
                                        type="phone"
                                        label="Phone*"
                                        labelcolor={theme.colors.secondary3}
                                        value={phone}
                                        maxLength={12}
                                        onChange={this.handlePhoneChange}
                                        placeholder="Phone"
                                    />
                                </div>

                            </Flex>
                            <Flex between>
                                <div style={{ margin: "15px 0 0", ...labelStyle }}>
                                    <Tooltip title="Email of the recipient(s) that will receive invoices submitted for this customer">
                                        <Space>
                                            <Text color='#7384AA' size='14px' weight={400}>Invoice Recipient Email *</Text>
                                            <InfoCircleOutlined />
                                        </Space>
                                    </Tooltip>
                                    <div >
                                        {
                                            customer.invoiceRecipientEmail && customer.invoiceRecipientEmail.map((email, index) => {
                                                return (
                                                    <div key={index} style={{ margin: "-15px 0 0" }}>
                                                        <Space size="small">
                                                            <div style={labelStyle}>
                                                                <LabeledInput

                                                                    index={index}
                                                                    type="email"
                                                                    value={email}
                                                                    onChange={this.handleEmailChange}
                                                                    placeholder="Email"
                                                                    id="invoiceEmailAddress"
                                                                    key={"invoiceEmailAddress" + index}
                                                                />
                                                            </div>

                                                            <span index={index} onClick={this.handleDeleteEmail}>
                                                                <DeleteOutlined style={{ cursor: 'pointer' }} />
                                                            </span>
                                                        </Space>
                                                    </div>
                                                )
                                            })
                                        }
                                        <Tag onClick={this.handleAddEmail} tagpreset={theme.colors.primary}>Add email</Tag>
                                    </div>
                                </div>

                                <div style={labelStyle}>
                                    <LabeledInput
                                        label="Payment Terms*"
                                        labelcolor={theme.colors.secondary3}
                                        id="paymentTerms"
                                        key="paymentTerms"
                                        type="select"
                                        className="no-left-padding"
                                        placeholder="Select"
                                        value={paymentTerms}
                                        onChange={this.handleSelection}
                                        // style={labelStyle}
                                        dropdownRender={menu => (
                                            <>
                                                {menu}
                                                <Divider style={{ margin: "0px 0" }} />
                                                <Flex center style={{ padding: "0 15px" }}>
                                                    <LabeledInput
                                                        label="Create custom terms"
                                                        labelcolor={theme.colors.secondary3}
                                                        prefix="Net"
                                                        // type="number"
                                                        id="paymentTermsCustom"
                                                        key="paymentTermsCustom"
                                                        onChange={this.handleTextChange}
                                                        placeholder="40"
                                                        onKeyDown={(event) => { if (event.key === "Enter") this.handleCustomPaymentTerm() }}
                                                    />
                                                    <a
                                                        style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                        onClick={this.handleCustomPaymentTerm}
                                                    >
                                                        <PlusOutlined /> Add
                                                    </a>
                                                </Flex>
                                            </>
                                        )}
                                    >
                                        {paymentTermsItems.map(item => <Option key={item} value={item}>{item}</Option>)}
                                    </LabeledInput>
                                </div>
                                <div style={labelStyle}>
                                    <LabeledInput
                                        // prefix="https://"
                                        label="Website"
                                        labelcolor={theme.colors.secondary3}
                                        id="webSite"
                                        key="webSite"
                                        placeholder="acme.com"
                                        defaultValue={webSite}
                                        onChange={this.handleTextChange}
                                    />
                                </div>
                            </Flex>

                            <Flex between>
                                <div style={locationLabelStyle}>
                                    <LabeledInput
                                        id="billingAddress"
                                        key="billingAddress"
                                        label="Billing Address*"
                                        labelcolor={theme.colors.secondary3}
                                        type="location"
                                        getLocation={this.getLocation}
                                        Line2
                                        address={billingAddress}
                                        value={billingAddress && billingAddress.line1 ?
                                            { line1: addressObjectToStr(billingAddress), line2: billingAddress.line2 } : null
                                        }
                                        placeholder="Address"
                                    />
                                </div>
                                <div style={{ ...locationLabelStyle, margin: "15px 0" }}>
                                    <Flex between centerHorizontally style={{ width: '100%', marginBottom: 8 }}>
                                        <Text color='#7384AA' size='14px' weight={400}>Shipping Address</Text>
                                        <Flex start centerHorizontally gap='8px'>
                                            <Text color='#7384AA' size='14px' weight={400}>Same as billing?</Text>
                                            <Switch size="small" defaultChecked={makeShipAddrAsBillAddr} onChange={this.handleShipAddrSwitch} />
                                        </Flex>
                                    </Flex>
                                    <LabeledInput
                                        id="shippingAddress"
                                        key={"shippingAddress" + makeShipAddrAsBillAddr}
                                        nomargin="nomargin"
                                        type="location"
                                        getLocation={this.getShippingLocation}
                                        Line2
                                        address={shippingAddress}
                                        value={shippingAddress && shippingAddress.line1 ?
                                            { line1: addressObjectToStr(shippingAddress), line2: shippingAddress.line2 } : null
                                        }
                                        placeholder="Address"
                                    />
                                </div>
                            </Flex>

                            <Tabs defaultActiveKey="0">
                                <TabPane tab="Notes" key="0">
                                    <LabeledInput
                                        type="text-area"
                                        id="notes"
                                        key="notes"
                                        placeholder="Add any notes"
                                        defaultValue={notes}
                                        onChange={this.handleTextChange}
                                    />
                                </TabPane>
                                <TabPane tab="Master Service Agreement" key="1">
                                    {/* Master Service Agreement */}
                                    <div>
                                        <Text color={theme.colors.systemGray}>
                                            A master service agreement is a contract entered into by you and your customer
                                            <span style={{ color: theme.colors.secondary3 }}><Popover placement='left' title="MSA or Master Service Agreement" content="A master service agreement is a contract entered into by you and your customer. This agreement details the expectations and the terms to fulfil the contract."> <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)', fontSize: 17 }} /></Popover></span>
                                        </Text>

                                        <Text color={theme.colors.systemGray2}>{(!this.state.msaObj) ? "No MSA added." : null}</Text>
                                        {this.state.msaObj &&
                                            <div>
                                                <Space size="small">
                                                    <span style={{ color: this.props.theme.colors.secondary3, fontSize: 17 }}>{this.state.msaObj.documentUrl.fileName}</span>
                                                    <Popconfirm placement='left' title="Are you sure you want to delete this MSA?" onConfirm={() => this.handleDeleteMSA()}>
                                                        <DeleteOutlined style={{ cursor: 'pointer' }} />
                                                    </Popconfirm>
                                                </Space>
                                            </div>
                                        }
                                        {!this.state.msaObj &&
                                            <Tooltip placement="left" visible={false/*!this.invInfoCompleted*/} title="You will be able to upload an MSA after saving invoicing info above.">
                                                <Button disabled={false/*!this.invInfoCompleted*/} onClick={this.handleShowMSAModal} solid style={{ marginTop: 16 }} text='Add MSA' />
                                            </Tooltip>
                                        }
                                    </div>
                                </TabPane>
                                <TabPane tab="Purchase Orders" key="2">
                                    {/* Work Order */}
                                    <div>
                                        <Text color={theme.colors.systemGray}>
                                            This document could be a <b>work order</b> or a <b>purchase order</b> or a <b>statement of work</b>
                                            <span style={{ color: theme.colors.secondary3 }}>
                                                <Popover
                                                    title="Purchase Orders"
                                                    content={
                                                        <Text>
                                                            This document could be a <b>work order</b> or a <b>purchase order</b> or a <b>statement of work</b>.<br /><br />
                                                            A <b>work order</b> is usually a task or a job for a customer. <br />
                                                            <b>Statement of work</b> usually defines project-specific activities, deliverables and timelines for a vendor providing services to the customer.<br />
                                                            A <b>purchase order</b> is a commercial document and first official offer issued by a buyer to a seller indicating types, quantities, and agreed prices for products or services.
                                                        </Text>
                                                    }
                                                > <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)', fontSize: 17 }} />
                                                </Popover>
                                            </span>
                                        </Text>
                                        {/* <Divider style={{ margin: "10px 0 10px" }} /> */}
                                        {
                                            ((this.state.workOrders || []).length == 0) ?
                                                <Text color={theme.colors.systemGray2}>No work orders added.</Text>
                                                :
                                                <Table
                                                    id="wo-table"
                                                    bordered={false}
                                                    size="middle"
                                                    columns={woTableColumns}
                                                    dataSource={this.state.workOrders || []}
                                                    rowKey='ponumber'
                                                    scroll={{ y: '700px', x: '100%' }}
                                                />
                                        }
                                        {/* {
                                            this.state.workOrders && this.state.workOrders.map((wo, index) => {
                                                return (
                                                    <div key={wo.PONumber} style={{ margin: "8px 0" }}>
                                                        <Space size="small">
                                                            <span style={{ color: this.props.theme.colors.secondary3, fontSize: 17 }}>#{wo.ponumber}</span>
                                                            <span style={{ color: this.props.theme.colors.secondary3, fontSize: 17 }}>{wo.documentUrl.fileName}</span>
                                                            <Popconfirm title="Are you sure you want to delete this Work Order?" onConfirm={() => this.handleDeleteWO(wo.ponumber)}>
                                                                <DeleteOutlined style={{cursor: 'pointer'}} />
                                                            </Popconfirm>
                                                        </Space>
                                                    </div>
                                                )
                                            })
                                        } */}
                                        <Tooltip placement="left" visible={false/*!this.invInfoCompleted*/} title="You will be able to upload work orders after saving invoicing info above.">
                                            <Button disabled={false/*!this.invInfoCompleted*/} onClick={this.handleShowWOModal} solid style={{ marginTop: 16 }} text='Add Work Order' />
                                        </Tooltip>

                                    </div>
                                </TabPane>
                                <TabPane tab="Other documents" key="3">
                                    <Text color={theme.colors.systemGray} style={{ marginBottom: "10px" }}>Add any other files associated with this customer</Text>
                                    <UploadFiles
                                        uploadFileType="receivables"
                                        fileList={attachments}
                                        onUpload={this.handleFileUpload}
                                        hasUnsignedFiles={true}
                                    />
                                </TabPane>
                            </Tabs>
                        </FlexColumn>
                    </div>
                </FlexColumn>

                <AddModalMSA
                    visible={this.state.showAddModalMSA}
                    onAdd={this.handleMSAAdded}
                    onCancel={this.handleCancelMSA}
                    aionStore={this.props.aionStore}
                    store={this.props.store}
                    customerId={customerId}
                />

                <AddModalWO
                    visible={this.state.showAddModalWO}
                    onAdd={this.handleWOAdded}
                    onCancel={this.handleCancelWO}
                    aionStore={this.props.aionStore}
                    store={this.props.store}
                    customerId={customerId}
                    customer={customer}
                />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        bkStore: state.bookkeepingAppReducer,
        aionStore: state.aionAppReducer,
        receivablesStore: state.receivablesAppReducer,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(NewCustomer));