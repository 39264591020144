import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';

import { Skeleton, message } from 'antd';
import { Paragraph, } from '../../Reusable/Text';
import { FlexColumn, } from '../../Reusable/Container';
import { ErrorAlert } from '../../Reusable/Alert';

import environment from '../../../environment';
import { apiPOSTReq } from '../../../Utils/api';
import EditableTable from './EditableTable';

class COATable extends Component {
    state = {
        pagination: {
            current: 1,
            pageSize: 1000,
        },
        pairPagination: {
            current: 1,
            pageSize: 1000,
        },
        accountCOA: {},
        customCOA: [],

    };

    componentDidMount() {
        this.fetchCOA({ pagination: this.state.pagination });

    }

    fetchCOA = ({ pagination }) => {
        const headers = {
            AionCurrentBiz: this.props.aionStore.BusinessUniqueKey,
            AionAuth: this.props.aionStore.UAM.encryptedAuthHeader
        }

        const body = {
            "BusinessId": this.props.aionStore.BusinessUniqueKey,
            "size": pagination.pageSize,
            "page": pagination.current - 1,
            "activeRecords": true,
            "sortFieldName": "Code",
            "sortDirection": "ASC",
            "sources": ["QUICKBOOKS"],
        }
        this.setState({ loading: true, });

        apiPOSTReq(`${environment.bbBaseUrl}/bk/getCategories`, headers, body, (err, resp) => {
            try {
                const data = resp;
                console.log('/bk/getCategories', data)
                if (data.result) {
                    this.setState({
                        loading: false,
                        customCOA: data.customCOAList ? data.customCOAList.map(x => {
                            return {
                                ...x, key: x.id,
                            }
                        }) : [],
                    })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getActiveCustomers", error.stack, resp);
                ErrorAlert({ description: error.message })
            }
        })
    }

    handleSaveRow = (row, newData) => {
        const headers = {
            AionCurrentBiz: this.props.aionStore.BusinessUniqueKey,
            AionAuth: this.props.aionStore.UAM.encryptedAuthHeader
        }

        const body = {
            "BusinessId": this.props.aionStore.BusinessUniqueKey,
            "CustomCOA": row,
        }

        console.log("coaObj", row);
        apiPOSTReq(`${environment.bbBaseUrl}/bk/saveCategory`, headers, body, (err, resp) => {
            try {
                this.setState({ loading: false });
                const data = resp;
                console.log("/bk/saveCategory", data)
                if (data.result) {
                    message.success(`${row.categoryName} successfully updated`);
                    this.setState({ customCOA: newData })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR saveCategory", error.stack, resp);
                ErrorAlert({ description: error.message })
            }
        })

    }

    render() {
        const { theme } = this.props;

        var columns = [
            {
                title: 'Number',
                dataIndex: 'code',
                key: 'code',
                width: "130px",
                editable: true,
            },
            {
                title: "Name",
                dataIndex: 'categoryName',
                key: 'categoryName',
                textWrap: 'word-break',
                render: (categoryName, record) => (
                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                        <FlexColumn>
                            <Paragraph noMargin bold style={{ fontSize: "0.95rem" }}>{record.categoryName} </Paragraph>
                            {(record.description || "").length > 0 ? <Paragraph noMargin color={theme.colors.systemGray} style={{ fontSize: "0.9rem" }}>{record.description} </Paragraph> : null}
                        </FlexColumn>
                    </div>
                )
            },
            {
                title: 'Type',
                dataIndex: 'coatype',
                key: 'coatype',
                render: (item) => item === "COGS" ? "Cost of Goods Sold" : item
            },
        ];

        return (
            <>
                <Paragraph fontWeight={600} level={4} >Organize your accounting by updating your account numbers below <span style={{ fontWeight: 'normal' }}>(Optional)</span></Paragraph>
                <Skeleton loading={this.state.loading} active title={false} paragraph={{ rows: 4 }} className="skeleton-padding">
                    <EditableTable
                        columns={columns}
                        dataSource={this.state.customCOA}
                        rowKey='id'
                        pagination={this.state.pagination}
                        onChange={this.handleTableChange}
                        saveRow={this.handleSaveRow}
                    />
                </Skeleton>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer,
        bankingStore: state.bankingAppReducer,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(COATable));