import React from 'react'

import { Text } from '../../Reusable/Text'
import { Flex, FlexColumn, CardContainer } from '../../Reusable/Container'

// Images
import Download from '../../../Images/download.png'
import { LoadingOutlined } from '@ant-design/icons'

const DocumentDownloadCard = (props) => {
    const { width, title, description, onClick, loading, margin } = props

    return (
        <CardContainer minWidth='616px' width={width} height='148px' margin={margin ? margin : '0 12px 24px 0'}>
            <FlexColumn fullWidth>
                <Flex between fullWidth>
                    <Text heading>{title}</Text>
                    {
                        loading ?
                        <LoadingOutlined style={{ color: '#1199FF' }} />
                        :
                        <img style={{ cursor: 'pointer' }} onClick={onClick} width='24px' height='24px' src={Download} />
                    }
                </Flex>
                <Text>{description}</Text>
            </FlexColumn>
        </CardContainer>
    )
}

export default DocumentDownloadCard