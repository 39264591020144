import React, { useState } from 'react'
import { Switch, message, Modal, Dropdown, Menu, Tooltip } from 'antd'
import moment from 'moment'
import { withTheme } from 'styled-components'

import { Tag, Text } from '../../Reusable/Text'
import { Image } from '../../Reusable/Image'
import { ErrorAlert } from '../../Reusable/Alert'
import { Flex, FlexColumn } from '../../Reusable/Container'
import { apiPOSTReq } from '../../../Utils/api'

// Images
import CardIcon from '../../../Images/aion-card-icon.png'
import { TextButton, ImageButton } from '../../Reusable/Button'
import environment from '../../../environment'
import { LoadingOutlined } from '@ant-design/icons'
import Lock from '../../../Images/lock-selected.svg'
import Unlock from "../../../Images/lock-primary.svg"
import ValidateCard from '../Debit Card/ValidateCard'
import LockBlack from '../../../Images/lock-icon.png'
import ModalClose from '../../../Images/modal-close.png'
import LockLighter from "../../../Images/LockLighter.png"
import ArrowRight from "../../../Images/arrow-right.png"
import More from "../../../Images/more-circle.png"


const DebitCard = (props) => {
    const [loading, setLoading] = useState(false)
    // const [debitCard, setDebitCard] = useState(props.debitCard)
    const [showValidateCardModal, setShowValidateCardModal] = useState(false)

    const { onViewCard, debitCard, setDebitCard, setPin, view, activateCard } = props

    const suspendCard = () => {
        setLoading(true)

        apiPOSTReq(`${environment.bbBaseUrl}/bb/card/suspendCard`, { "BankProvider": environment.bankProvider.crb }, { bbPersonCard: debitCard }, (err, resp) => {
            setLoading(true)
            try {
                const data = resp || {}
                if (data.result) {
                    console.log('suspendCard', data)
                    debitCard.status = 'Suspended'
                    setDebitCard(debitCard)
                    // onUpdateCard(debitCard)
                    message.success('Card locked!')
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/suspendCard err", error, resp)
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            } finally {
                setLoading(false)
            }
        })
    }

    const unsuspendCard = () => {
        setLoading(true)

        apiPOSTReq(`${environment.bbBaseUrl}/bb/card/unsuspendCard`, { "BankProvider": environment.bankProvider.crb }, { bbPersonCard: debitCard }, (err, resp) => {
            setLoading(true)
            try {
                const data = resp || {}
                if (data.result) {
                    console.log('suspendCard', data)
                    debitCard.status = 'Active'
                    setDebitCard(debitCard)
                    // onUpdateCard(debitCard)
                    message.success('Card unlocked!')
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/unsuspendCard err", error, resp)
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            } finally {
                setLoading(false)
            }
        })
    }

    return (
        <FlexColumn between className='debit-card-back' style={{ padding: '18px 22px' }}>
            <Flex fullWidth end>
                {
                    !view && !loading && debitCard.status &&
                    <Dropdown
                        placement='bottomLeft'
                        trigger={['click']}
                        overlay={<Menu style={{ padding: 24, borderRadius: 8 }}>
                            <FlexColumn gap='16px'>
                                {
                                    debitCard.status !== 'Unactivated' &&
                                    <TextButton text='VIEW DETAILS' rightIcon={<Image src={ArrowRight} />} onClick={() => onViewCard()} />
                                }
                                {
                                    debitCard.status !== 'Unactivated' && debitCard.status !== 'Closed' &&
                                    <TextButton text={debitCard.isPinSet === false ? "SET PIN" : "CHANGE PIN"} rightIcon={<Image src={ArrowRight} />} onClick={() => setPin()} />
                                }
                                {
                                    debitCard.status !== 'Unactivated' && debitCard.status === 'Active' &&
                                    <TextButton text='LOCK' rightIcon={<Image src={Lock} />} onClick={() => suspendCard()} />
                                }
                                {
                                    debitCard.status !== 'Unactivated' && debitCard.status === 'Suspended' &&
                                    <TextButton text='UNLOCK' rightIcon={<Image src={Unlock} />} onClick={() => unsuspendCard()} />
                                }
                                {
                                    debitCard.status === 'Unactivated' &&
                                    <TextButton text='ACTIVATE NOW' onClick={() => activateCard()} />
                                }
                            </FlexColumn>
                        </Menu>}
                    // onVisibleChange={flag => this.setState({ moreDropdownVisible: flag })}
                    // visible={moreDropdownVisible}
                    >
                        <Tooltip title={debitCard.status === 'Unactivated' ? 'Activate' : 'Card Info, Lock/Unlock, Update PIN'} key="card-more" defaultVisible={true} color={props.theme.colors.primary} placement='right' >
                            <ImageButton src={More} />
                        </Tooltip>
                    </Dropdown>
                }

                {
                    view &&
                    <Text color='white' margin='2px 0'>{debitCard.firstName} {debitCard.lastName}</Text>
                }

                {
                    loading &&
                    <FlexColumn center style={{ width: 24, height: 24 }}>
                        <LoadingOutlined style={{ color: 'white' }} />
                    </FlexColumn>
                }
            </Flex>
            {
                <FlexColumn start>
                    <Text size='20px' spacing='8px' color='white'>•••• {(debitCard.status !== 'Unactivated') ? debitCard.last4 : '••••'}</Text>
                </FlexColumn>
            }

            <Flex start gap='8px'>
                <Tag background='#333333' color='#CCCCCC' height='32px'>{["VirualPan", "VirtualPan"].includes(debitCard.paymentInstrument) ? 'Virtual' : 'Physical'}</Tag>
                {
                    debitCard && (() => {
                        switch (debitCard.status) {
                            case 'Closed':
                                return <Tag background='#333333' color='#CCCCCC' height='32px'>Closed</Tag>
                            case 'Suspended':
                                return <Tag background='#333333' color='#CCCCCC' height='32px'><Image margin='0 4px 0 0' src={LockLighter} />Locked</Tag>
                            case 'Unactivated':
                                switch (debitCard.orderStatus) {
                                    case 'OrderPending':
                                        return <Tag background='#333333' color='#CCCCCC' height='32px'>Order Pending</Tag>
                                    case 'Completed':
                                        return null //<Tag background='#333333' color='#CCCCCC'>**/**</Tag>
                                    default:
                                        return null // <Tag background='#333333' color='#CCCCCC'>{moment(debitCard.expirationDate).format("MM/YY")}</Tag>
                                }
                            default:
                                return null // <Tag background='#333333' color='#CCCCCC'>{moment(debitCard.expirationDate).format("MM/YY")}</Tag>
                        }
                    })()
                }
            </Flex>
        </FlexColumn>
    )
}

export default withTheme(DebitCard)