import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'

import { Select } from 'antd'
import { FlexColumn, Flex } from '../../Reusable/Container'
import { Text } from '../../Reusable/Text'
import { Image } from '../../Reusable/Image'
import { LabeledInput } from '../../Reusable/Input'
import { Button, TextButton } from '../../Reusable/Button'
import { ErrorAlert } from '../../Reusable/Alert'
import { apiPOSTReq, apiPOSTReqPromise } from '../../../Utils/api'
import { getFeaturePerm, checkAccessForRoles } from '../../../Utils/util'
import { uaApiPOST } from '../../../Utils/userAccess'
import environment from '../../../environment'
import { DeleteOutlined } from '@ant-design/icons'
import Asterisk from '../../../Images/asterisk.png'

const { Option } = Select

class SaveApprovalRule extends Component {

    constructor(props) {
        super(props);

        const { approvalRule, registeredUsers, vendors } = props;

        let initialState = {
            stepCount: 1,
            level1Type: "ANY",
            level2Type: "ANY",
            level3Type: "ANY",
            level1Approvers: [],
            level2Approvers: [],
            level3Approvers: [],
            remainingApprovers: [],
            vendorNames: [],
            vendors,
            allVendors: vendors,
            pagination: {
                current: 1,
                pageSize: 100,
            },
        };

        if (approvalRule) {
            console.log("SaveApprovalRule approvalRule", approvalRule, registeredUsers);
            const { levels, name, condition } = approvalRule;

            initialState = {
                ...initialState,
                name,
                stepCount: levels.length,
            };

            if (condition) condition.expressions.forEach(({ lhsValue, rhsValue }) => {
                if (lhsValue === "Payment.Amount") initialState.amount = rhsValue;
                if (lhsValue === "Vendor.VendorName") initialState.vendorNames = rhsValue.split("|");
            });

            levels.forEach((level, index) => {
                if (!level) return;

                const levelKey = `level${index + 1}`;
                initialState[`${levelKey}Type`] = level.type;

                const approvers = level.performers.map(performer =>
                    registeredUsers.find(user => user.userInfo.email === performer.userName)?.objectId
                ).filter(Boolean); // Remove any undefined values (e.g., if find returns undefined)

                initialState[`${levelKey}Approvers`] = approvers;
            });
        }

        this.state = initialState;
    }

    handleOnChange = (event) => {
        const target = event.target
        this.setState({ [target.id]: target.value })
    }

    handleSelect = (value, option) => {
        this.setState({ [option.id]: value })
    }

    handleSearch = value => {
        if (value) {
            this.onSearch(value)
        } else {
            this.setState({ vendors: this.state.allVendors })
        }
    }

    onSearch = (value) => {
        var { pagination, vendors, allVendors } = this.state

        if (!value) { //this.state.vendorSearch) {
            return this.fetchData({ pagination: this.state.pagination })
        }

        const body = {
            "size": pagination.pageSize,
            "page": pagination.current - 1
        }

        body.searchStr = value // this.state.vendorSearch

        // this.setState({ loading: true })
        apiPOSTReq(`${environment.payBaseUrl}/payables/vendors/lookupVendors`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            this.setState({ loading: false })
            try {
                const data = resp
                console.log("/lookupVendors", JSON.stringify(data))
                if (data.result) {

                    data.vendors.forEach(vendor => {
                        if (allVendors.filter(v => v.objectId === vendor.objectId).length === 0) {
                            allVendors.push(vendor)
                        }
                    })

                    this.setState({
                        vendors: data.vendors,
                        allVendors: allVendors,
                        pagination: {
                            ...pagination,
                            total: data.count
                        },
                    })

                    console.log("lookupVendors", JSON.stringify(this.state.allVendors))
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getContractors", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    getInstruction = (userIds) => {
        const { registeredUsers, type } = this.props
        const users = []
        const newApprovers = []

        const approvalType = type === "vendor" ? "Payable" : "Contractor"

        userIds.forEach(userId => users.push(registeredUsers.find(user => user.objectId === userId)))

        console.log('SaveApprovalRule allApprovers getInstruction', users)

        users.forEach(user => {
            if (approvalType === 'Payable' && !user.payableAccess.approve) newApprovers.push(user.name)
            if (approvalType === 'Contractor' && !user.contractorAccess.approve) newApprovers.push(user.name)
        })

        return newApprovers.length === 0 ? null : `The Bill Payment Approver role will be added for ${newApprovers.join(', ')}`
    }

    getUpdateUserPromise = (user) => {
        const { roleTags } = user

        roleTags.push('Bill Payment Approver')

        var body = {
            RoleNames: roleTags,
            UserId: user.userInfo.email,
        }

        console.log("IssueCardUpdatingUserupdateUser", body)

        return new Promise((resolve, reject) => {
            uaApiPOST("/user/admin/updateUserRoles", { body: body }, (err, resp) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(resp);
                }
            })
        })
    }

    handleSaveApprovalSetting = () => {
        const { name, amount, stepCount, level1Approvers, level2Approvers, level3Approvers, level1Type, level2Type, level3Type, vendorNames } = this.state
        const { registeredUsers, approvalRule, type } = this.props
        const allApproverUsers = []

        const approvalType = type === "vendor" ? "Payable" : "Contractor"

        level1Approvers.concat(level2Approvers).concat(level3Approvers).forEach(userId => allApproverUsers.push(registeredUsers.find(user => user.objectId === userId)))

        if (!name) {
            ErrorAlert({ description: "Please enter a name for this approval policy." })
            return
        } else if (!amount && !vendorNames) {
            ErrorAlert({ description: "Please enter a payment amount or select a vendor for this approval policy." })
            return
        } else {
            if (level1Approvers.length === 0) {
                ErrorAlert({ description: "Please select at least one 1st level approver." })
                return
            }

            if (stepCount > 1 && level2Approvers.length === 0) {
                ErrorAlert({ description: "Please select at least one 2nd level approver or remove the 2nd level." })
                return
            }

            if (stepCount > 2 && level3Approvers.length === 0) {
                ErrorAlert({ description: "Please select at least one 3rd level approver or remove 3rd the level." })
                return
            }

            var request = {
                "ApprovalRule": {
                    "Name": name,
                    "Type": this.props.type === "vendor" ? "Payable" : "Contractor",
                },
                "Action": {
                    "Name": "Create"
                }
            }

            if (approvalRule) {
                request.ApprovalRule.approvalRuleId = approvalRule.approvalRuleId
            }

            var expressions = []

            if (amount) {
                expressions.push({
                    "LhsType": "OBJECT",
                    "LhsValue": "Payment.Amount",
                    "LhsValueType": "DOUBLE",
                    "RelationalOperator": "GE",
                    "RhsType": "CONSTANT",
                    "RhsValue": amount,
                    "RhsValueType": "DOUBLE"
                })
            }

            if (vendorNames && vendorNames.length > 0) {
                expressions.push({
                    "LhsType": "OBJECT",
                    "LhsValue": "Vendor.VendorName",
                    "LhsValueType": "STRING",
                    "RelationalOperator": "CONTAINS",
                    "RhsType": "CONSTANT",
                    "RhsValue": vendorNames.join('|'),
                    "RhsValueType": "LIST"
                })
            }

            request.ApprovalRule.Condition = {
                "Type": "AND",
                "Expressions": expressions
            }

            var levels = []

            var level1Performers = []

            level1Approvers.forEach(approver => {
                var user = registeredUsers.find(rUser => rUser.objectId === approver)

                if (user) {
                    level1Performers.push({
                        UserName: user.userInfo.email,
                        FirstName: user.userInfo.firstName,
                        LastName: user.userInfo.lastName
                    })
                }
            })

            levels.push({
                Type: level1Type,
                RANK: 1,
                Performers: level1Performers
            })

            if (stepCount > 1) {
                var level2Performers = []

                level2Approvers.forEach(approver => {
                    var user = registeredUsers.find(rUser => rUser.objectId === approver)

                    if (user) {
                        level2Performers.push({
                            UserName: user.userInfo.email,
                            FirstName: user.userInfo.firstName,
                            LastName: user.userInfo.lastName
                        })
                    }
                })

                levels.push({
                    Type: level2Type,
                    RANK: 2,
                    Performers: level2Performers
                })
            }

            if (stepCount > 2) {
                var level3Performers = []

                level3Approvers.forEach(approver => {
                    var user = registeredUsers.find(rUser => rUser.objectId === approver)

                    if (user) {
                        level3Performers.push({
                            UserName: user.userInfo.email,
                            FirstName: user.userInfo.firstName,
                            LastName: user.userInfo.lastName
                        })
                    }
                })

                levels.push({
                    Type: level3Type,
                    RANK: 3,
                    Performers: level3Performers
                })
            }

            request.ApprovalRule.Levels = levels

            console.log("Approvals /bb/approvals/saveRule", JSON.stringify(request))
            this.setState({ loadingSaveApprovalSetting: true })

            const saveRulePromise = apiPOSTReqPromise(`${environment.bbBaseUrl}/bb/approvals/saveRule`, { "BankProvider": environment.bankProvider.crb }, request)
                .then(data => {
                    if (data.result) {
                        console.log("CustomerDetails getMSAForCustomer data", data);
                    } else {
                        throw Error("Error saving approval rule");
                    }
                })
                .catch(error => {
                    console.log("ERRR", error.stack);
                    ErrorAlert({ description: error.message })
                });

            const updateUserRolePromises = []

            allApproverUsers.forEach(user => {
                if ((approvalType === 'Payable' && !user.payableAccess.approve) || (approvalType === 'Contractor' && !user.contractorAccess.approve)) {
                    updateUserRolePromises.push(this.getUpdateUserPromise(user))
                }
            })

            Promise.all([saveRulePromise].concat(updateUserRolePromises))
                .then(() => {
                    this.setState({ loadingSaveApprovalSetting: false });
                    this.props.handleSaveApprovalSetting();
                });
        }
    }

    render() {
        const { stepCount, amount, loadingSaveApprovalSetting, name, approverDropdown1Open, approverDropdown2Open,
            approverDropdown3Open, vendorDropdownOpen, level1Approvers, level2Approvers, level3Approvers, vendorNames, vendors } = this.state

        const { registeredUsers, theme, allRoles } = this.props

        var selectProps = {
            type: "select",
            mode: "multiple",
            optionLabelProp: "label",
            placeholder: "Select approvers",
        }

        var level1Options = (registeredUsers || []).filter(o => !level2Approvers.concat(level3Approvers).find(o2 => { return o.objectId === o2 }))
        var level2Options = (registeredUsers || []).filter(o => !level1Approvers.concat(level3Approvers).find(o2 => { return o.objectId === o2 }))
        var level3Options = (registeredUsers || []).filter(o => !level1Approvers.concat(level2Approvers).find(o2 => { return o.objectId === o2 }))

        const allApprovers = (level1Approvers || []).concat((level2Approvers || []), (level3Approvers || [])) || []
        const remainingApprovers = (registeredUsers || []).filter(o => !allApprovers.find(o2 => { return o2 === o.objectId }))

        registeredUsers.forEach(approver => {
            approver.payableAccess = checkAccessForRoles(approver.roleTags, "Bill Payments", allRoles)
            approver.contractorAccess = checkAccessForRoles(approver.roleTags, "Contractor Payments", allRoles)
        })

        return (
            <FlexColumn start fullWidth>
                <Text heading>{this.props.approvalRule ? `Edit control: ${this.props.approvalRule.name}` : "New Control"}</Text>

                <FlexColumn start style={{ marginTop: 24 }} gap='24px'>
                    {
                        !this.props.approvalRule &&
                        <LabeledInput
                            nomargin
                            label="Name"
                            labelcolor={theme.colors.secondary3}
                            id="name"
                            key="name"
                            value={name}
                            placeholder="Enter a name"
                            onChange={(e) => this.setState({ [e.target.id]: e.target.value })}
                            instruction="Enter a name for your new approval policy"
                        />
                    }

                    <LabeledInput
                        nomargin
                        label='Minimum Payment Amount'
                        placeholder="1000"
                        id="amount"
                        key="amount"
                        prefix="$"
                        value={this.state.amount}
                        onChange={this.handleOnChange}
                        optional
                    />

                    <LabeledInput
                        label='Vendors'
                        nomargin
                        type="remote-search"
                        mode="multiple"
                        className="no-left-padding"
                        open={vendorDropdownOpen}
                        dropdownClassName="no-left-padding"
                        value={vendorNames && vendorNames}
                        placeholder="Select vendors"
                        onChange={vendorNames => this.setState({ vendorNames })}
                        onSearch={this.handleSearch}
                        onDropdownVisibleChange={() => this.setState({ vendorDropdownOpen: undefined })}
                        onSelect={() => {
                            this.setState({ vendors: this.state.allVendors })
                        }}
                        dropdownRender={menu => (
                            <div>
                                {menu}
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <TextButton margin='4px 18px 8px 0' onClick={() => this.setState({ vendorDropdownOpen: false })} text='CLOSE' />
                                </div>
                            </div>
                        )}
                        optional
                    >
                        {
                            vendors.map((item, i) => (
                                <Select.Option id={item.objectId} key={item.objectId} name={i} value={`${item.vendorName}`}>
                                    {`${item.vendorName}`}
                                </Select.Option>
                            ))
                        }
                    </LabeledInput>

                    <FlexColumn start>
                        <Flex start bottom style={{ marginBottom: 8 }}>
                            <Text light>Require approval from</Text>
                            <Select
                                id="levelType"
                                label='Require approval from'
                                bordered={false}
                                size="small"
                                defaultValue="One"
                                style={{ width: 65, marginLeft: 5, marginRight: 5, backgroundColor: '#E6E2F2', borderRadius: '5px', fontSize: '0.7rem', paddingLeft: 5 }}
                                onChange={(value) => this.setState({ level1Type: value })}
                            >
                                <Option id="any1" value="ANY">One</Option>
                                <Option id="all1" value="ALL">All</Option>
                            </Select>
                            <Text light>of the following:</Text>
                            <Image width='5px' height='5px' margin='0 0 15px 4px' src={Asterisk} />
                        </Flex>

                        <LabeledInput
                            nomargin
                            {...selectProps}
                            value={level1Approvers}
                            open={approverDropdown1Open}
                            onChange={level1Approvers => this.setState({ level1Approvers })}
                            onDropdownVisibleChange={() => this.setState({ approverDropdown1Open: undefined })}
                            dropdownRender={menu => (
                                <div>
                                    {menu}
                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <TextButton margin='4px 18px 8px 0' onClick={() => this.setState({ approverDropdown1Open: false })} text='CLOSE' />
                                    </div>
                                </div>
                            )}
                            instruction={this.getInstruction(level1Approvers)}
                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                        >
                            {
                                level1Options.map((item, i) => (
                                    <Select.Option id={item.objectId} key={item.objectId} name={i} value={item.objectId} label={`${item.userInfo.firstName} ${item.userInfo.lastName}`}>
                                        <Flex between>
                                            <span>{`${item.userInfo.firstName} ${item.userInfo.lastName}`}</span>
                                            <span>{`${item.userInfo.email}`}</span>
                                        </Flex>
                                    </Select.Option>
                                ))
                            }
                        </LabeledInput>
                    </FlexColumn>


                    {
                        stepCount > 1 &&
                        <FlexColumn start>
                            <Flex start bottom style={{ marginBottom: 8 }}>
                                <Text light>And then require 2nd level approval from</Text>
                                <Select
                                    id="levelType"
                                    bordered={false}
                                    size="small"
                                    defaultValue="One"
                                    style={{ width: 65, marginLeft: 5, marginRight: 5, backgroundColor: '#E6E2F2', borderRadius: '5px', fontSize: '0.7rem', paddingLeft: 5 }}
                                    onChange={(value) => this.setState({ level2Type: value })}>
                                    <Option id="any1" value="ANY">One</Option>
                                    <Option id="all1" value="ALL">All</Option>
                                </Select>
                                <Text light>of the following:</Text>
                                <Image width='5px' height='5px' margin='0 0 15px 4px' src={Asterisk} />
                            </Flex>

                            <div style={{ display: 'flex', width: "100%", alignItems: 'center' }}>
                                <LabeledInput
                                    nomargin
                                    {...selectProps}
                                    value={level2Approvers}
                                    open={approverDropdown2Open}
                                    onChange={level2Approvers => this.setState({ level2Approvers })}
                                    onDropdownVisibleChange={() => this.setState({ approverDropdown2Open: undefined })}
                                    dropdownRender={menu => (
                                        <div>
                                            {menu}
                                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <TextButton margin='4px 18px 8px 0' onClick={() => this.setState({ approverDropdown2Open: false })} text='CLOSE' />
                                            </div>
                                        </div>
                                    )}
                                    instruction={this.getInstruction(level2Approvers)}
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                >
                                    {
                                        level2Options.map((item, i) => (
                                            <Select.Option id={item.objectId} key={item.objectId} name={i} value={item.objectId} label={`${item.userInfo.firstName} ${item.userInfo.lastName}`}>
                                                <Flex between>
                                                    <span>{`${item.userInfo.firstName} ${item.userInfo.lastName}`}</span>
                                                    <span>{`${item.userInfo.email}`}</span>
                                                </Flex>
                                            </Select.Option>
                                        ))
                                    }
                                </LabeledInput>

                                <a style={{ marginLeft: 12 }} onClick={
                                    () => {
                                        this.setState({ stepCount: stepCount - 1, level2Approvers: [] })
                                    }
                                }><DeleteOutlined style={{ cursor: 'pointer', fontSize: 18 }} /></a>
                            </div>
                        </FlexColumn>
                    }

                    {
                        stepCount > 2 &&
                        <FlexColumn start>
                            <Flex start bottom style={{ marginBottom: 8 }}>
                                <Text light>And then require 3rd level approval from</Text>
                                <Select
                                    id="levelType"
                                    bordered={false}
                                    size="small"
                                    defaultValue="One"
                                    style={{ width: 65, marginLeft: 5, marginRight: 5, backgroundColor: '#E6E2F2', borderRadius: '5px', fontSize: '0.7rem' }}
                                    onChange={(value) => this.setState({ level3Type: value })}>
                                    <Option id="any1" value="ANY">One</Option>
                                    <Option id="all1" value="ALL">All</Option>
                                </Select>
                                <Text light>of the following:</Text>
                                <Image width='5px' height='5px' margin='0 0 15px 4px' src={Asterisk} />
                            </Flex>

                            <div style={{ display: 'flex', width: "100%", alignItems: 'center' }}>
                                <LabeledInput
                                    nomargin
                                    {...selectProps}
                                    value={level3Approvers}
                                    open={approverDropdown3Open}
                                    onChange={level3Approvers => this.setState({ level3Approvers })}
                                    onDropdownVisibleChange={() => this.setState({ approverDropdown3Open: undefined })}
                                    dropdownRender={menu => (
                                        <div>
                                            {menu}
                                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <TextButton margin='4px 18px 8px 0' onClick={() => this.setState({ approverDropdown3Open: false })} text='CLOSE' />
                                            </div>
                                        </div>
                                    )}
                                    instruction={this.getInstruction(level3Approvers)}
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                >
                                    {
                                        level3Options.map((item, i) => (
                                            <Select.Option id={item.objectId} key={item.objectId} name={i} value={item.objectId} label={`${item.userInfo.firstName} ${item.userInfo.lastName}`}>
                                                <Flex between>
                                                    <span>{`${item.userInfo.firstName} ${item.userInfo.lastName}`}</span>
                                                    <span>{`${item.userInfo.email}`}</span>
                                                </Flex>
                                            </Select.Option>
                                        ))
                                    }
                                </LabeledInput>

                                <a style={{ marginLeft: 12 }} onClick={
                                    () => {
                                        this.setState({ stepCount: stepCount - 1, level3Approvers: [] })
                                    }
                                }><DeleteOutlined style={{ cursor: 'pointer', fontSize: 18 }} /></a>
                            </div>
                        </FlexColumn>
                    }

                    {
                        remainingApprovers.length > 0 && stepCount < 3 &&
                        <TextButton margin='12px 0 0' onClick={() => this.setState({ stepCount: stepCount + 1 })} text='Add Approval Level' />
                    }
                </FlexColumn>
                <Flex start>
                    <Button permtype="Override" margin='48px 0 0' solid loading={loadingSaveApprovalSetting} onClick={this.handleSaveApprovalSetting} text="SAVE" />
                </Flex>
            </FlexColumn >
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer,
        bankingStore: state.bankingAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(SaveApprovalRule)))