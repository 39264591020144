import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'
import styled from 'styled-components'
import moment from 'moment-business-days'

import { message, Modal, Checkbox } from 'antd'

// Components
import { Text } from '../../Reusable/Text'
import { FlexColumn, Flex } from '../../Reusable/Container'
import { LabeledInput } from '../../Reusable/Input'
import { Button } from '../../Reusable/Refresh/Button'
import { ErrorAlert } from '../../Reusable/Alert'
import environment from '../../../environment'
import Result from '../../Reusable/Result'
import { dateFormatList } from '../../../Utils/util'
import ModalClose from '../../../Images/modal-close.png'

// Util
import { apiPOSTReq } from '../../../Utils/api'


const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
})

export const CardButton = styled.div`
    margin-top: 18px;
    background-color: rgb(255, 255, 255);
    border-radius: 0.5rem;
    width: 450px;
    margin-bottom: 14px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    opacity: 1;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.1);
    padding: 1.8rem;
    transition-property: box-shadow;
    transition-duration: 300ms;
    &.selected {
        cursor: pointer;
        box-shadow: 0 3px 15px rgb(0 0 0 / 0.2);
    };
    &.selected:hover {
        cursor: pointer;
        box-shadow: 0 3px 15px rgb(0 0 0 / 0.4);
    };
`

class UpdateInventory extends Component {

    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            file: null,
            fileName: '',
            showErrorModal: false,
            fileList: [],
            submitLoading: false,
        }
    }

    onSubmit = () => {
        const { aionStore } = this.props
        const { inventoryDate, grossInventory, ineligibleInventory } = this.state

        if(this.state.submitLoading) {
            return;
        }

        if (!inventoryDate) {
            ErrorAlert({ description: `Inventory as of date is a mandatory field.` })
            return
        }

        if (!grossInventory) {
            ErrorAlert({ description: `Gross Inventory is a mandatory field.` })
            return
        }

        if (isNaN(grossInventory)) {
            ErrorAlert({ description: `Gross Inventory must be a valid number.` })
            return
        }

        if (!ineligibleInventory) {
            ErrorAlert({ description: `Ineligible Inventory is a mandatory field.` })
            return
        }

        if (isNaN(ineligibleInventory)) {
            ErrorAlert({ description: `Ineligible Inventory must be a valid number.` })
            return
        }

        if (parseFloat(ineligibleInventory) > parseFloat(grossInventory)) {
            ErrorAlert({ description: `Ineligible Inventory cannot be greater than Gross Inventory.` })
            return
        }

        var body = {
            inventoryLoc: true,
            inventoryAsOf: inventoryDate,
            grossInventory: grossInventory,
            ineligibleInventory: ineligibleInventory,
            eligibleInventory: grossInventory - ineligibleInventory,
        }

        const { UserInfo } = aionStore
        var certifiedBy = `${UserInfo.FirstName} ${UserInfo.LastName}`

        this.setState({ submitLoading: true })

        apiPOSTReq(`${environment.iveBaseUrl}/inventory/uploadInventoryLoc?CertifiedBy=${certifiedBy}&Notes=`, {}, body, (err, resp) => {
            try {
                const data = resp
                console.log("/inventory/uploadInventoryLoc", JSON.stringify(data))
                console.log("/inventory/uploadInventoryLoc err", JSON.stringify(err))
                if (data.result) {
                    message.success('Inventory update sent for review.')
                    this.setState({ inventoryInfos: data.inventoryInfos, inventoryLoaded: true, submitLoading: false })
                    this.props.submitComplete()
                } else {
                    this.setState({ submitLoading: false })
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /inventory/getInventoryHistory", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    disabledDate = (current) => {
        // Can not select days before today and today and weekends / holidays
        return current > moment()
    }

    render() {
        const { certifyChecked, responseErrorMessage, inventoryDate, grossInventory, ineligibleInventory } = this.state
        const { theme, aionStore } = this.props
        const darkMode = (theme.type === "dark")


        let showHeaderErrorTable = this.state.uploadInventoryInfo && this.state.uploadInventoryInfo.headerErrors && this.state.uploadInventoryInfo.headerErrors.length > 0
        let errors = []
        // let errorMessage = this.stat>"?e.errorMessage
        if (this.state.uploadInventoryInfo && this.state.uploadInventoryInfo.headerErrors && this.state.uploadInventoryInfo.headerErrors.length > 0) {
            errors = this.state.uploadInventoryInfo.headerErrors.map((x, i) => {
                return { ...x, key: i }
            })
            // errorMessage = "The file is missing required column headers"
        } else if (this.state.uploadInventoryInfo && this.state.uploadInventoryInfo.valueErrors && this.state.uploadInventoryInfo.valueErrors.length > 0) {
            errors = this.state.uploadInventoryInfo.valueErrors.map((x, i) => {
                return { ...x, key: i }
            })
        }
        let errorMessage = "The file you uploaded contains the below errors. Please correct the errors and retry."

        let errorNum = 0
        if (this.state.uploadInventoryInfo && this.state.uploadInventoryInfo.recordsErrorCount) {
            errorNum = this.state.uploadInventoryInfo.recordsErrorCount
        }
        let successNum = 0
        if (this.state.uploadInventoryInfo && this.state.uploadInventoryInfo.recordsTotalCount && this.state.uploadInventoryInfo.recordsErrorCount) {
            successNum = this.state.uploadInventoryInfo.recordsTotalCount - this.state.uploadInventoryInfo.recordsErrorCount
        }

        return (
            <>
                <FlexColumn>
                    <Text heading>Update Inventory</Text>

                    <LabeledInput
                        label="Inventory as of"
                        labelcolor={theme.colors.secondary3}
                        id="inventoryDate"
                        key="inventoryDate"
                        type="date-picker"
                        format={dateFormatList}
                        defaultValue={inventoryDate && moment(inventoryDate)}
                        placeholder={moment().format("MM-DD-YYYY")}
                        onChange={(date, dateStr) => {
                            if (date) {
                                date = new Date(date).toISOString()
                                date = date.split('T')[0]
                            }
                            this.setState({ inventoryDate: date })
                        }}
                        onKeyDown={this.handleOnKeyDown}
                        disabledDate={this.disabledDate}
                    />

                    <LabeledInput
                        label="Gross Inventory"
                        labelcolor={theme.colors.secondary3}
                        id="grossInventory"
                        key="grossInventory"
                        placeholder="Enter an amount"
                        prefix="$"
                        value={grossInventory}
                        onChange={(event) => {
                            this.setState({ [event.target.id]: event.target.value })
                        }}
                    />

                    <LabeledInput
                        label="Ineligible Inventory"
                        labelcolor={theme.colors.secondary3}
                        id="ineligibleInventory"
                        key="ineligibleInventory"
                        placeholder="Enter an amount"
                        prefix="$"
                        value={ineligibleInventory}
                        onChange={(event) => {
                            this.setState({ [event.target.id]: event.target.value })
                        }}
                    />


                    <LabeledInput key="grossEligibleInventory" type='read-only' label="Gross Eligible Inventory" value={<Text color={theme.colors.creditGreen} weight='500'>{(grossInventory && ineligibleInventory) ? formatter.format(grossInventory - ineligibleInventory) : 0}</Text>} />

                    <Checkbox style={{ width: 450, marginTop: 24, marginBottom: 50 }} onChange={(e) => this.setState({ certifyChecked: e.target.checked })}>Check here to certify that the information provided above is true and correct as of the date hereof</Checkbox>

                    <Flex start>
                        <Button disabled={!certifyChecked} permtype="Override" solid onClick={() => certifyChecked && this.onSubmit()} text='Submit' />
                    </Flex>
                </FlexColumn>
                <Modal
                    visible={this.state.showErrorModal}
                    footer={null}
                    closable={true}
                    width={750}
                    style={{ top: 80 }}
                    destroyOnClose={true}
                    onCancel={() => {
                        this.setState({ showErrorModal: false, responseErrorMessage: null, uploadInventoryInfo: null })
                    }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <Result
                        uploadTypeTitle="Upload Error"
                        showHeaderErrorTable={showHeaderErrorTable}
                        errors={errors}
                        errorNum={errorNum}
                        successNum={successNum}
                        errorMessage={errorMessage}
                        responseErrorMessage={responseErrorMessage}
                    />
                </Modal>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(UpdateInventory))