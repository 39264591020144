import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Layout, Checkbox, Select, message, Radio } from 'antd'
import { withTheme } from 'styled-components'
import { PlaidLink } from "react-plaid-link"

import { Flex, FlexColumn } from '../../Reusable/Container'
import { Text } from '../../Reusable/Text';
import { Button } from '../../Reusable/Refresh/Button'
import { ErrorAlert } from '../../Reusable/Alert'
import { LabeledInput } from '../../Reusable/Input'

// Actions
import { addDataToStore, completeSignin, USERDEFAULTS_SAVE_FLAG, BNK_SAVE_DATA, UAM_SAVE_PERMISSIONS, SAVE_DATA } from '../../../Actions/actions'
// Utils
import { apiGET, apiPOST, apiPOSTReq } from '../../../Utils/api'
import { getUserPermissions } from '../../../Utils/userAccess'
import { isTablet, toCurrency, getBPAccountsWithAccess } from '../../../Utils/util'
import environment from '../../../environment'
import Missing from "../../../Images/missing.png"
import ModalClose from "../../../Images/modal-close.png"

const { Option } = Select
const { Content } = Layout

const MinimumDepositModal = (props) => {
    const [loading, setLoading] = useState(false)
    const [showDebitAuthFlow, setShowDebitAuthFlow] = useState(false)
    const [relinkToken, setRelinkToken] = useState("")
    const [bankInfoList, setBankInfoList] = useState([])
    const [bankInfo, setBankInfo] = useState({})
    const [initialDepositAmt, setInitialDepositAmt] = useState()
    const [authorizedTransfer, setAuthorizedTransfer] = useState(false)
    const [checkingAccount, setCheckingAccount] = useState()
    const [checkingAccounts, setCheckingAccounts] = useState()
    const [manualRoutingNumber, setManualRoutingNumber] = useState(false)
    const [manualAccountNumber, setManualAccountNumber] = useState(false)
    const [flowStep, setFlowStep] = useState('deposit')
    const [counterParty, setCounterParty] = useState()
    const [later, setLater] = useState(true)
    const [type, setType] = useState("Business")
    const [linkToken, setLinkToken] = useState("")
    const [accountNumberError, setAccountNumberError] = useState(false)
    const [routingNumberError, setRoutingNumberError] = useState(false)
    const [accountNumberErrorMessage, setAccountNumberErrorMessage] = useState("Couldn’t retrieve your details from Plaid, please enter it manually")
    const [routingNumberErrorMessage, setRoutingNumberErrorMessage] = useState("Couldn’t retrieve your details from Plaid, please enter it manually")
    const [edited, setEdited] = useState(false)
    const [accountUnavailable, setAccountUnavailable] = useState(false)

    const { store, theme, aionStore } = props;
    var { Applications } = store;
    var { UserInfo } = aionStore;
    Applications = Applications || [{}];
    UserInfo = UserInfo || [{}];

    useEffect(() => {
        // Update the document title using the browser API
        fetchAccounts()
        fetchData()
        getPlaidLinkToken();
        console.log("ApplicationStatus useEffect")
    }, [])


    useEffect(() => {
        console.log("useEffectsetCheckingAccounts", checkingAccount)
        if (checkingAccount) {
            console.log("useEffectsetCheckingAccounts", checkingAccount)
            if (!checkingAccount.routingNumber) {
                setAccountNumberError(true)
                setManualRoutingNumber(true)
            }
            else setManualRoutingNumber(false)
            if (!checkingAccount.accountNumber) {
                setRoutingNumberError(true)
                setManualAccountNumber(true)
            }
            else setManualAccountNumber(false)
        }
    }, [checkingAccount])

    const fetchData = () => {
        setLoading(true)
        apiGET("/banking/radius/dashboard", null, (err, resp) => {
            try {
                if (err) throw new Error(err)
                props.dispatch(addDataToStore(BNK_SAVE_DATA, resp.data))
                apiGET("/attributes", null, (err, resp) => {
                    try {
                        const data = resp.data
                        if (data.success) {
                            props.dispatch(completeSignin(data))
                            setLoading(false)
                        } else {
                            throw Error("Could not fetch attributes.")
                        }
                    } catch (error) {
                        console.log("/attr ERR", error.stack)
                        setLoading(false)
                    }
                })
            } catch (error) {
                setLoading(false)
                const fd = fetchData
                ErrorAlert({ description: error.message, okText: "Retry", onOk() { fd() } })
            }
        })
    }

    const getPlaidLinkToken = () => {
        var header = {}
        var body = {
            "redirectUri": window.location.href,
            "BusinessId": props.store.businessId,
        }

        apiPOSTReq(`${environment.integrationBaseUrl}/integration/plaid/getLinkToken`, header, body, (err, resp) => {
            try {
                const response = resp || {};
                if (response.result) {
                    setLinkToken(response.linkToken);
                } else {
                    ErrorAlert({ description: response.responseMessage });
                }
            }
            catch (err) {
            }
        })
    }

    const fetchAccounts = () => {
        apiPOSTReq(`${environment.bbBaseUrl}/bb/getAccounts`, { "BankProvider": environment.bankProvider.crb }, null, (err, resp) => {
            try {
                console.log("/bb/getAccounts", err, resp)
                const data = resp
                if (data.result) {
                    if (err) throw new Error(err)
                    var accounts = data.bbaccounts
                    props.dispatch(addDataToStore(BNK_SAVE_DATA, { BBAccounts: accounts }))
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getAccounts", error, err, resp)
                // const fd = fetchAccounts
                // ErrorAlert({ description: error.message, okText: "Retry", onOk() { fd() } })
            }
        })
    }

    const fetchBankInfo = () => {
        const headers = {
            businesskey: props.aionStore.BusinessUniqueKey
        }
        setLoading(true)
        apiGET("/bankinfo?includeNumbers=true", headers, (err, resp) => {
            setLoading(false)
            try {
                const data = resp.data || {}

                if (data && data.BankInfo && data.BankInfo.length > 0) {
                    setBankInfoList(data.BankInfo || [])

                    var checkingAccounts = []

                    data.BankInfo.forEach(bankInfo => {
                        bankInfo.Accounts.forEach(account => {
                            if (account.AccountSubType === "checking") {
                                account.bankName = bankInfo.Institution
                                account.bankLogo = bankInfo.InstitutionLogo
                                checkingAccounts.push(account)
                            }
                        })
                    })

                    setCheckingAccounts(checkingAccounts)

                    if (!checkingAccounts || checkingAccounts.length === 0) {
                        ErrorAlert({ description: "You must connect a checking account to continue." })
                    } else if (checkingAccounts.length > 1) {
                        setCheckingAccount(checkingAccounts[0])
                        setFlowStep('selectCheckingAccount')
                    } else {
                        setCheckingAccount(checkingAccounts[0])
                        setFlowStep('auth')
                    }
                }
                // Check if any banks require a relogin
                var relinkRequired = (data.BankInfo || []).find(bankInfo => (bankInfo.Status == "Login required"))
                if (relinkRequired) getPlaidRelinkToken()
            } catch (error) {
                console.log("fetchBankInfo ERROR: ", error.stack)
            }
        })
    }

    const fetchBanks = (bankInfo) => {
        if (bankInfo.integrationProvider == "Plaid") {
            const headers = {}

            const body = {
                "BusinessId": props.store.businessId,
                "itemId": bankInfo.itemId
            }
            apiPOSTReq(`${environment.integrationBaseUrl}/integration/plaid/getAccountInfo`, headers, body, (err, resp) => {
                try {
                    const data = resp;

                    setBankInfoList(bankInfo)

                    if (data && data.accountsList && data.accountsList.length > 0) {
                        var checkingAccounts = []
                        data.accountsList.forEach(account => {

                            if (account.accountSubType === "checking") {
                                account.bankName = bankInfo.bankName
                                account.bankLogo = bankInfo.logoUrl
                                checkingAccounts.push(account)
                            }
                        })

                        console.log("Checking account", checkingAccounts)
                        setCheckingAccounts(checkingAccounts)


                        if (!checkingAccounts || checkingAccounts.length === 0) {
                            ErrorAlert({ description: "You must connect a checking account to continue." })
                        } else if (checkingAccounts.length > 1) {
                            setCheckingAccount(checkingAccounts[0])
                            setFlowStep('selectCheckingAccount')
                        } else {
                            setCheckingAccount(checkingAccounts[0])
                            setFlowStep('auth')
                        }
                        setLoading(false)
                    }
                    else {
                        message.error("Cannot retrieve account information from Plaid")
                        setLoading(false)
                        setManualAccountNumber(true)
                        setManualRoutingNumber(true)
                        setAccountNumberError(true)
                        setRoutingNumberError(true)
                        setFlowStep('auth')
                        // ErrorAlert({ description: "Cannot retrieve account information, please try again" })
                    }

                } catch (error) {
                    setLoading(false)
                    console.log("ERROR", error.stack)
                }
            })
        }


    }


    const getPlaidAccessToken = (public_token, metadata, includeAuth, bankAccessToken) => {
        var header = {}
        var body = {
            "redirectUri": window.location.href,
            "plaidTokenData": {
                publicToken: public_token,
                accountId: metadata.account.id,
                accountName: metadata.account.name,
                institution: metadata.institution.name,
                institutionId: metadata.institution.institution_id,
            },
            "loggedInUserId": UserInfo.Email,
            "BusinessId": store.businessId,
            "includeAuth": includeAuth

        }

        setLoading(true)
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/plaid/getAccessToken`, header, body, (err, resp) => {
            try {
                const response = resp || {};
                if (response.result) {
                    fetchBanks(response.bankInfo)
                } else {
                    if (!(response.msg || "").includes("You have already linked this account. Please link a different account.")) {
                        ErrorAlert({ description: response.responseMessage })
                    }
                }
            }
            catch (err) {

            }

        })

    }

    const plaidOnSuccess = (public_token, metadata) => {
        const headers = {
            businesskey: props.aionStore.BusinessUniqueKey
        }
        var body = {
            data: {
                publicToken: public_token,
                accountId: metadata.account.id,
                accountName: metadata.account.name,
                institution: metadata.institution.name,
                institutionId: metadata.institution.institution_id,
            }
        }

        setLoading(true)
        apiPOST("/plaidauthenticate", headers, body, (err, resp) => {
            try {
                if (err) throw Error(err)
                const data = resp.data
                setLoading(false)
                if (data.success) {
                    fetchBankInfo()
                } else {
                    if (!(data.msg || "").includes("You have already linked this account. Please link a different account.")) {
                        ErrorAlert({ description: data.msg })
                    } else {
                        fetchBankInfo()
                    }
                }
            } catch (error) {
                console.log("plaidauthenticate error: ", error)
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    const logPlaidEvent = (eventName, metadata) => {
        apiPOST("/logError", {}, { logType: "PLAID_DESKTOP", eventName: eventName, metadata: metadata }, (err, resp) => {
            console.log("Plaid event logged", err, resp)
        })
    }

    const getPlaidRelinkToken = () => {
        const headers = {
            businesskey: props.aionStore.BusinessUniqueKey
        }
        setLoading(true)
        apiGET("/plaidcreatepublictoken", headers, (err, resp) => {
            setLoading(false)
            try {
                const data = resp.data
                if (data.success) {
                    setRelinkToken(data.PublicToken || "")
                } else {
                    throw Error(data.msg)
                }
            } catch (error) {
                console.log("ERROR", error.stack)
                // ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    const createCounterParty = () => {
        if ((manualAccountNumber || manualRoutingNumber) || edited) {
            var error = false
            if (!checkingAccount.accountNumber || isNaN(checkingAccount.accountNumber)) {
                setAccountNumberError(true)
                setAccountNumberErrorMessage("A valid account number is required")
                if (!error) error = true
            } else {
                setAccountNumberError(false)
            }

            if (!checkingAccount.routingNumber || isNaN(checkingAccount.routingNumber) || checkingAccount.routingNumber.length != 9) {
                setRoutingNumberError(true)
                setRoutingNumberErrorMessage("A valid routing number is required")
                if (!error) error = true
            } else {
                setRoutingNumberError(false)
            }

            if (error) return
        }

        setLoading(true)

        var body = {
            counterparty: {
                "type": type,
                "nameOnAccount": `${UserInfo.FirstName} ${UserInfo.LastName}`,
                "email": UserInfo.Email,
                "ach": {
                    "accountNumber": checkingAccount.accountNumber,
                    "routingNumber": checkingAccount.routingNumber,
                    "accountType": "checking",
                },
                "contextIdentifier": `${UserInfo.FirstName} ${UserInfo.LastName}`,
                "initiatedBy": `${UserInfo.FirstName} ${UserInfo.LastName}`,
            },
            "BankProvider": environment.bankProvider.crb,
            "fundingSource": true,
            "ruleType": "Recipient"
        }

        apiPOSTReq(`${environment.bbBaseUrl}/bb/createCounterparty`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            try {
                const data = resp || {}
                if (data.result) {
                    setCounterParty(data.counterpartiesObj)
                    createACHDeposit(data.counterpartiesObj)
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/createCounterparty err", error, resp, body)
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    const createACHDeposit = (counterParty) => {
        var secCode = ""
        if (counterParty.type === "Business") {
            secCode = "ccd"
        } else if (counterParty.type === "Individual") {
            secCode = "ppd"
        }

        const bpAccounts = getBPAccountsWithAccess("Deposits", { permType: "manage", getActiveAccounts: true })
        var bpAccount = bpAccounts && bpAccounts.length > 0 ? bpAccounts[0] : {}

        var body = {
            achTransferObj: {
                "accountId": bpAccount.accountId || "",
                "accountNumber": bpAccount.accountNumber || "",
                "amount": 50,
                "counterpartyName": `${UserInfo.FirstName} ${UserInfo.LastName}`,
                "counterpartyId": counterParty.id || "",
                "counterpartyType": counterParty.type || "",
                "direction": "debit",
                "secCode": secCode,
                "email": UserInfo.Email,
                "sendEmail": true,
                "userNote": "Initial deposit",
                "description": "Initial deposit",
                "contextIdentifier": `Initial deposit`,
                "initiatedBy": `${UserInfo.FirstName} ${UserInfo.LastName}`,
                "initiatedByUserName": UserInfo.Email,
                "status": "pending",
                "statusMessage": "Transfer has been queued for processing.",
                "transactionType": "Pull",
            },
            "ruleType": "ACH"
        }

        console.log("/createACH body", body)

        apiPOSTReq(`${environment.bbBaseUrl}/bb/createACH`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            setLoading(false)
            try {
                if (err) throw Error(err)
                const data = resp || {}
                console.log("/createACH data", data)
                if (data.result) {
                    // Set Initial Deposit flag
                    props.dispatch(addDataToStore(USERDEFAULTS_SAVE_FLAG, { BBInitialDepositMade: true }))
                    getStarted()
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/createACH err", error.stack)
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    const handleTextChange = (event) => {
        if (!edited) setEdited(true)
        if (event && event.target) {
            const id = event.target.id
            const value = event.target.value
            setCheckingAccount(oldState => ({ ...oldState, [id]: value }))
        }
    }

    const getDebitAuthFlowContent = () => {
        switch (flowStep) {
            case 'deposit':
                return <FlexColumn start style={{ width: "450px" }}>
                    <Text heading>Activate your account</Text>
                    <Text color={theme.colors.systemGray}>Initiate a $50 deposit to get started with your new bank account!</Text>
                    <LabeledInput
                        type='read-only'
                        label='Deposit amount'
                        id="Amt"
                        value='$50.00'
                    />
                    <div style={{ color: theme.colors.caption }}>Select your Bank Account</div>
                    <Flex centerHorizontally between style={{ margin: '8px 0 24px' }}>
                        <PlaidLink
                            {...environment.plaid}
                            token={linkToken}
                            selectAccount="false"
                            clientName="Aion"
                            isWebview="true"
                            apiVersion="v2"
                            onSuccess={(public_token, metadata) => getPlaidAccessToken(public_token, metadata, true, null)}
                            onExit={() => setLater(true)}
                            style={{ background: 'transparent', padding: 0, fontSize: 18, cursor: 'pointer', height: 40 }}
                            onEvent={logPlaidEvent}
                        >
                            <Button width='100%' height='100%' loading={loading} solid text='Connect' />
                        </PlaidLink>
                        {/* <UserAddOutlined style={{fontSize: 22, color: '#333333'}} /> */}
                    </Flex>
                    <span><i>Remember, this step is <b>required</b> in order to keep your bank account open.</i></span>

                    {/* {
                        later &&
                        <Flex center><a style={{ alignSelf: "center", marginTop: 12 }} onClick={() => props.onCancel()}>Do this later</a></Flex>
                    } */}
                </FlexColumn>

            case 'selectCheckingAccount':
                var items = []
                checkingAccounts.forEach((account, i) => {
                    items.push(
                        <Option key={account.AccountId} value={i}>
                            <Flex start centerHorizontally>{account.bankLogo ? <img width='18px' height='18px' src={`data:image/jpeg;base64,${account.bankLogo}`} /> : account.bankName}<span style={{ marginLeft: 8 }}>{account.AccountName} • {account.Mask}</span></Flex>
                        </Option>
                    )
                })

                return <FlexColumn start style={{ width: "450px" }}>
                    <Text heading>Select checking account</Text>
                    <Text color={theme.colors.systemGray}>Which checking account would you like to use?</Text>
                    <LabeledInput
                        labelcolor={props.theme.colors.secondary3}
                        label="Checking Account"
                        type="select"
                        key="checkingAccount"
                        id="checkingAccount"
                        placeholder="Select checking account"
                        className="no-left-padding"
                        onChange={value => setCheckingAccount(checkingAccounts[value])}
                        value={checkingAccount && <Flex start centerHorizontally>{checkingAccount.bankLogo ? <img width='18px' height='18px' src={`data:image/jpeg;base64,${checkingAccount.bankLogo}`} /> : checkingAccount.bankName}<span style={{ marginLeft: 8 }}>{checkingAccount.AccountName} • {checkingAccount.Mask}</span></Flex>}
                    >
                        {items}
                    </LabeledInput>
                    <Button permtype="Override" solid onClick={() => setFlowStep('auth')} text='Continue' />
                </FlexColumn>
            case 'auth':
                return <FlexColumn start style={{ width: "450px" }}>
                    <Text heading>Bank transfer authorization</Text>
                    <LabeledInput
                        autoFocus
                        labelcolor={props.theme.colors.secondary3}
                        label={`Depository Institution Name`}
                        key={"institution"}
                        value={checkingAccount ? checkingAccount.bankName : ""}
                        id="institution"
                        onChange={handleTextChange}

                    />
                    <LabeledInput
                        autoFocus
                        labelcolor={props.theme.colors.secondary3}
                        label={`Account number`}
                        id="accountNumber"
                        value={checkingAccount ? checkingAccount.accountNumber : ""}
                        maxLength={17}
                        placeholder="Enter Account Number"
                        disabled={!manualAccountNumber && !edited}
                        onChange={handleTextChange}
                        error={accountNumberError}
                        errorMessage={accountNumberErrorMessage}
                    />
                    <LabeledInput
                        labelcolor={props.theme.colors.secondary3}
                        label={`Routing number`}
                        id="routingNumber"
                        value={checkingAccount ? checkingAccount.routingNumber : ""}
                        maxLength={9}
                        placeholder="Enter 9 digit Routing Number"
                        disabled={!manualRoutingNumber && !edited}
                        onChange={handleTextChange}
                        error={routingNumberError}
                        errorMessage={routingNumberErrorMessage}
                    />
                    <LabeledInput
                        autoFocus
                        label="Type"
                        labelcolor={theme.colors.secondary3}
                        id="type"
                        key="type"
                        value={type}
                        type="radio"
                        divider={false}
                        onChange={(e) => setType(e.target.value)}
                    >
                        <Radio value="Business">Business</Radio>
                        <Radio value="Individual">Individual</Radio>
                    </LabeledInput>
                    <Text margin='0 0 24px 0'>I (we) agree that this ACH transaction I (we) authorize will comply with all applicable laws.</Text>
                    <Checkbox value={authorizedTransfer} onChange={(e) => setAuthorizedTransfer(e.target.checked)}>Please check this box to acknowledge the above statements and authorize the transfer</Checkbox>
                    <Button loading={loading} disabled={authorizedTransfer !== true} permtype="Override" style={{ alignSelf: 'center', width: '100%', marginTop: 70 }} solid onClick={() => authorizedTransfer && createCounterParty()} text='Continue' />
                </FlexColumn>
            default: return <></>
        }
    }

    const getStarted = () => {
        message.success('Initial deposit successful!')
        props.onCancel()
        // // Show Getting Started
        // FAQModal({
        //     type: "getting-started", theme: theme, onOk() {
        //         props.dispatch(addDataToStore(USERDEFAULTS_SAVE_FLAG, { BBShowGettingStarted: false }))
        //         // window.location.reload()
        //         // window.location.href = '/banking/home'
        //         props.history.push('/banking/home')
        //     }
        // })
    }

    return (
        <>
            {getDebitAuthFlowContent()}
        </>
    )
}

function mapStateToProps(state) {
    return {
        store: state.bankingAppReducer,
        aionStore: state.aionAppReducer,
        userDefaults: state.userDefaultsReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(MinimumDepositModal)))