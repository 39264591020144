import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components';

// Components
import Pending from './Pending';
import History from './History';
import { FlexColumn } from '../Reusable/Container';


class Index extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        let { location, history } = this.props;
        
        const title = "Payables (Coming Soon)";
        return (
            <FlexColumn start style={{ padding: 0, flexGrow: 1, height: '100%' }}>
                <Switch location={location}>
                    <Route exact path="/approvals" component={Pending} />
                    <Route exact
                        path="/approvals/pending"
                        render={(props) => (
                            <Pending {...props} {...this.props} />
                        )}
                    />
                    <Route exact path="/approvals/history" component={History} />
                </Switch>
            </FlexColumn>
        );
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)));