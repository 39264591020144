import React, { useState } from 'react'
import { message } from 'antd'
import moment from 'moment'

import { Text } from '../../Reusable/Refresh/Text'
import { FlexColumn } from '../../Reusable/Container'
import { Button } from '../../Reusable/Refresh/Button'
import { LabeledInput } from '../../Reusable/Refresh/Input'
import environment from '../../../environment'
import { apiPOSTReq } from '../../../Utils/api'
import { uaApiPOST } from '../../../Utils/userAccess'
import { ErrorAlert } from '../../Reusable/Alert'

const WhitelistedIPs = (props) => {
    const [whitelistedIPs, setWhitelistedIPs] = useState(props.whitelistedIPs || [])
    const [loading, setLoading] = useState(false)

    const handleSaveIP = () => {
        setLoading(true)
        var body = {
            "APIKeyRegistry": {
                "WhiteListIP": whitelistedIPs
            }
        }
        uaApiPOST('/api/mgmt/saveAPIKeyRegistry', { body: body }, (err, resp) => {
            setLoading(false)
            try {
                if (err) throw Error(err)
                if (resp.error || resp.responseMessage) throw Error(resp.error || resp.responseMessage)
                props.onSubmit()
            } catch (error) {
                console.log("saveAPIKeyRegistry error", error, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    return (
        <FlexColumn left>
            <Text heading>Whitelisted IP Addresses</Text>
            <Text style={{ marginBottom: 24, marginTop: 8 }}>IP addressess that are whitelisted can access your data on Aion. Add only IP addressess that you trust.</Text>
            <LabeledInput
                id='whitelistedIPs'
                key='whitelistedIPs'
                placeholder='Add your IP Addressess here'
                value={whitelistedIPs}
                // mode="multiple"
                mode="tags"
                // style={{ width: '100%' }}
                // onChange={handleChange}
                tokenSeparators={[' ']}
                type='select'
                onChange={(ips) => setWhitelistedIPs(ips)}
            />
            <Button
                solid
                margin='36px 0 0'
                radius='4px'
                width='140px'
                text='CONFIRM'
                onClick={() => handleSaveIP()}
                loading={loading} />
        </FlexColumn>
    )
}

export default WhitelistedIPs