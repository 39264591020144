import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components';
import { Select } from 'antd';
import environment from '../../../../environment'
import { apiPOSTReq } from '../../../../Utils/api'
import { getBPAccountsWithAccess } from '../../../../Utils/util'

import { Flex, FlexColumn } from '../../../Reusable/Container';
import { Text } from '../../../Reusable/Text';
import { LabeledInput } from '../../../Reusable/Input';
import { ErrorAlert } from '../../../Reusable/Alert'
import { upperFirst } from 'lodash';

const { Option } = Select;

class Index extends Component {

    state = {
        paymentMethodType: "ACH",
        accountNumber: null,
        routingNumber: null,
        accountType: "Checking",
        fromAccount: {},
        paymentMethod: {},
        accounts: [],
        pm: {}
    }

    componentDidMount () {
        const Accounts = getBPAccountsWithAccess(null, { resourceStr: "Payables.Vendors", permType: "manage", getActiveAccounts: true })
        var filteredAccounts = Accounts.filter(item => { return (item.accountType !== 'ACCOUNTS_RECEIVABLE') }) || []
        console.log("componentDidMount vendor", this.props.vendor)
        this.setState({
            accounts: filteredAccounts,
            fromAccount: filteredAccounts.length > 0 ? filteredAccounts[0] : {}
        })
    }

    saveVendorInput = (key, value) => {
        var { vendor } = this.props
        vendor[key] = value
        // this.setState({ vendor })
        this.props.handleVendorChange(vendor)
    }

    handleVendorTextChange = (event) => {
        var { vendor } = this.props
        vendor[event.target.id] = event.target.value
        if(this.props.handleVendorChange) {
            this.props.handleVendorChange(vendor)
        }
    }

    handlePMTextChange = (event) => {
        var { pm } = this.props
        pm[event.target.id] = event.target.value
        if(this.props.handlePMChange) {
            this.props.handlePMChange(pm)
        }
    }

    addTransfer = (body) => {
        return new Promise((resolve, reject) => {
            const { vendor } = this.state
            body.vendorId = vendor.id

            apiPOSTReq(`${environment.payBaseUrl}/payables/vendors/addTransferDelivery`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
                try {
                    const data = resp || {};
                    if (data.result) {
                        resolve(data);  // Resolve the promise with data if successful
                    } else {
                        throw Error(data.responseMessage || data.error);
                    }
                } catch (error) {
                    reject(error);  // Reject the promise with error if something goes wrong
                }
            });
        });
    }

    render() {
        var { payMode, vendor, errorField, pm } = this.props

        vendor = vendor || { type: "Business", billRecipientEmail: [] }
        var { id, vendorName } = vendor

        var paymentMethod = "ACH"
        var { id, vendorName } = vendor
        var { accountNumber, routingNumber } = pm || {}

        return (
            <FlexColumn start fullHeight style={{ marginBottom: 0 }}>
                <FlexColumn start gap="24px">
                    {
                        !payMode &&
                        <Flex left gap='24px'>
                            <LabeledInput
                                autoFocus
                                label="Vendor Name"
                                nomargin
                                id="vendorName"
                                key="vendorName"
                                placeholder="Enter vendor name"
                                value={vendorName}
                                onChange={this.handleVendorTextChange}
                                error={errorField === "vendor"}
                                errorMessage={"Add a vendor name"}
                            />
                            <LabeledInput
                                label="Vendor Type"
                                type='switch'
                                nomargin
                                switchNames={['Business', 'Individual']}
                                onChange={(value) => this.saveVendorInput("type", value)}
                                value={vendor.type} />
                        </Flex>
                    }
                    
                    {
                        payMode && paymentMethod === "ACH" &&
                        <Flex start between gap='24px'>
                            <LabeledInput
                                label="Vendor's account number"
                                id="accountNumber"
                                key="accountNumber"
                                nomargin
                                value={accountNumber}
                                placeholder="Enter up to 17 digits"
                                onChange={this.handlePMTextChange}
                                maxLength={17}
                                error={errorField === "accountNumber"}
                                errorMessage="A valid account number is required"
                            />
                            <LabeledInput
                                label="Vendor's routing number"
                                id="routingNumber"
                                key="routingNumber"
                                nomargin
                                value={routingNumber}
                                placeholder="Enter up to 9 digits"
                                onChange={this.handlePMTextChange}
                                maxLength={9}
                                error={errorField === "routingNumber"}
                                errorMessage="A valid routing number is required"
                            />
                        </Flex>
                    }
                </FlexColumn>
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.receivablesAppReducer,
        aionStore: state.aionAppReducer,
        receivablesStore: state.receivablesAppReducer,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)))