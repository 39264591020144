import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled, { withTheme } from 'styled-components'
import { CardContainer } from '../Reusable/CardContainer'
import { Flex, FlexColumn } from '../../Reusable/Container'
import environment from '../../../environment'

import AppIcon from '../../../Images/product-menu/approvals-menu-icon-active.png'
import { apiPOSTReq } from '../../../Utils/api'
import CardHeader from '../Reusable/CardHeader'
import { TextButton } from '../../Reusable/Button'
import { Image } from '../../Reusable/Image'
import ArrowRight from "../../../Images/arrow-right.png"
import { Tag, Text } from '../../Reusable/Text'
import { getDateInLocalTZ } from '../../../Utils/util'
import TableView from '../../Reusable/TableView'
import hexToRgba from 'hex-to-rgba'
import moment from 'moment'
import { ScrollableTableView } from '../Reusable/ScrollableTableView'

class Approvals extends Component {
    state = {
        loading: true,
        todoApprovals: []
    }

    componentDidMount() {
        this.fetchInbox()
    }

    fetchInbox = () => {
        const { UserInfo } = this.props.store

        const body = { "approverUserId": UserInfo.Email, "sortDirection": "DESC", size: 5, page: 0 }

        this.setState({ loading: true })
        apiPOSTReq(`${environment.bbBaseUrl}/bb/approvals/getInboxItems`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            try {
                this.setState({ loading: false })
                const data = resp || {}

                if (data.result) {
                    if (err) throw new Error(err)
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
                this.fetchPayablesInbox(data.pendingItemList || [])
            } catch (error) {
                console.log("ERRR getAccounts", error, err, resp)
            }
        })
    }

    fetchPayablesInbox = (bankingItems) => {
        const { UserInfo } = this.props.store

        const body = { "approverUserId": UserInfo.Email, "sortDirection": "DESC", size: 5, page: 0 }

        apiPOSTReq(`${environment.payBaseUrl}/payables/approvals/getInboxItems`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            try {
                this.setState({ loading: false })
                const data = resp || {}

                if (data.result) {
                    if (err) throw new Error(err)
                    this.setState({ todoPayApprovals: (bankingItems || []).concat(data.pendingItemList) })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getAccounts", error, err, resp)
            }
        })
    }

    columns = [
        {
            title: 'Description',
            dataIndex: 'contextIdentifier',
            key: 'contextIdentifier',
            render: (contextIdentifier, item) => {
                return (
                    <span>{contextIdentifier}</span>
                )
            },
        },
        {
            title: 'Date',
            dataIndex: 'assignedTime',
            key: 'assignedTime',
            render: date => {
                return <span style={{ color: hexToRgba(this.props.theme.colors.primary2, 0.5) }}>{getDateInLocalTZ(date)}</span>
            },
        },
        {
            title: 'Requested By',
            dataIndex: 'initiatedBy',
            align: 'right',
            key: 'initiatedBy',
            render: (initiatedBy, item) => {
                var source = item.sourceApplication
                var otTag = item.objectType
                if (item.objectType === "Counterparty") otTag = "New Recipient"
                return <FlexColumn start centerHorizontally gap='4px'><span style={{ fontWeight: 600 }}>{otTag}</span><Tag>{source === 'Payables' ? 'Bill Payment' : 'Banking'}</Tag></FlexColumn>
            }
        }
    ]
    
    render() {
        var { todoPayApprovals, loading } = this.state

        todoPayApprovals = (todoPayApprovals || []).sort((a, b) => (moment(b.assignedTime).toDate() - moment(a.assignedTime).toDate()))
        return (
            <CardContainer small>
                <FlexColumn>
                    <CardHeader 
                        imgSrc={AppIcon}
                        titleText='Approvals' 
                        desc='Pending approvals'
                        path='approvals/pending'
                        secondaryContent={
                            <FlexColumn centerVertically>
                                {<TextButton onClick={() => this.props.history.push('approvals/pending')} rightIcon={<Image src={ArrowRight} />} text="VIEW PENDING" />}
                            </FlexColumn>
                        }
                    />
                    <ScrollableTableView
                        id="approvals"
                        columns={this.columns}
                        dataSource={todoPayApprovals}
                        rowKey='objectId'
                        loading={loading}
                        borderView
                        hideHeader
                        scroll={{ y: 275 }} // Enable vertical scrolling
                        pagination={false}
                    />
                </FlexColumn>
                
            </CardContainer>
            
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Approvals)))