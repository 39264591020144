import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'

import { message, Modal, Tabs, Dropdown, Menu, Select } from 'antd'
import { Tag, Text } from '../../Reusable/Text'
import { Button, TextButton, ImageButton } from '../../Reusable/Button'
import { Flex, FlexColumn } from '../../Reusable/Container'
import { ErrorAlert } from '../../Reusable/Alert'
import { toCurrency } from '../../../Utils/util'
import { LabeledInput } from '../../Reusable/Input'
import { apiPOSTReq, apiGETDocUrl, getPreSignedS3Url } from '../../../Utils/api'
import { addDataToStore, BNK_SAVE_DATA } from '../../../Actions/actions'
import environment from '../../../environment'

// Images
import CardIcon from '../../../Images/aion-card-icon.png'
import Edit from "../../../Images/edit.png"
import hexToRgba from 'hex-to-rgba'

const AccountCard = (props) => {
    const [nickName, setNickname] = useState()
    const [editingNickname, setEditingNickname] = useState(false)
    const [editLoading, setEditLoading] = useState(false)

    const { account, selected, accounts } = props

    // var nickName = (account.nickName || `Business ${account.accountSubType}`).trim()

    // if (nickName.length > 20) nickName = `${nickName.substr(0, 20).trim()}...`

    useEffect(() => {
        setNickname((account.nickName || `Business ${account.accountSubType}`).trim())
    }, [])

    useEffect(() => {
        setNickname((account.nickName || `Business ${account.accountSubType}`).trim())
    }, [account])

    const updateNickname = () => {
        var body = {
            AccountNumber: account.accountNumber,
            AccountNickName: nickName
        }

        setEditLoading(true)
        apiPOSTReq(`${environment.bbBaseUrl}/bb/updateAccountNickName`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            setEditLoading(false)
            try {
                const data = resp || {}
                if (data.result) {
                    account.nickName = nickName
                    setEditingNickname(false)
                    console.log("bb/updateAccountNickName", data)
                    message.success('Account name set')
                    props.dispatch(addDataToStore(BNK_SAVE_DATA, { BBAccounts: accounts }))
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/updateAccountNickName err", error, resp, body)
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }
    const nickNameMaxLen = 30
    var accountNickName = `${(account.nickName || "").trim().substr(0, nickNameMaxLen)}${(account.nickName || "").trim().length > nickNameMaxLen ? "..." : ""}`
    var aactFontSize = '16px';
    return (
        <Flex between centerHorizontally fullWidth className={selected ? 'account-card-selected' : 'account-card'} style={{ height: 70, minWidth: 292, padding: '0 20px' }} gap='8px' onClick={() => {
            setEditLoading(false)
            props.onClick()
        }}>
            
            {
                (editingNickname && selected) ?
                    <Flex start centerHorizontally gap='8px'>
                        <LabeledInput
                            nomargin
                            value={nickName}
                            minHeight='28px'
                            width='140px'
                            maxLength={15}
                            onChange={(event) => {
                                setNickname(event.target.value)
                            }}
                        />
                        <TextButton
                            weight='400'
                            text='Save'
                            disabled={!nickName || nickName === ''}
                            loading={editLoading}
                            onClick={() => {
                                if (nickName && nickName.length > 0) {
                                    setEditLoading(true)
                                    updateNickname()
                                }
                            }} />
                        <TextButton
                            weight='400'
                            text='Cancel'
                            disabled={editLoading}
                            onClick={() => {
                                if (!editLoading) {
                                    setEditingNickname(false)
                                    setNickname(account.nickName)
                                }
                            }} />
                    </Flex>
                    :
                    <Flex start centerHorizontally gap='8px'>
                        <Text size={aactFontSize} capitalize primary={false} weight={selected ? 600 : 400} color={selected ? hexToRgba(props.theme.colors.primary2, 0.8) : hexToRgba(props.theme.colors.primary2, 0.7)} title={account.nickName} >{accountNickName}</Text>
                        {/* {
                            selected &&
                            <ImageButton src={Edit} onClick={() => {
                                setEditingNickname(true)
                            }} />
                        } */}
                    </Flex>
            }
            <Text
                capitalize primary={false} weight={selected ? 500 : 400}
                color={selected ? hexToRgba(props.theme.colors.primary2, 0.9) : hexToRgba(props.theme.colors.primary2, 0.5)}
            >{`$${toCurrency(account.availableBalance)}`}</Text>
        </Flex>
    )
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer,
        bankingStore: state.bankingAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(AccountCard)))