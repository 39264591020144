import hexToRgba from 'hex-to-rgba';
import React from 'react';
import { useTheme } from 'styled-components';
import _, { random } from 'underscore';

const colors = [
  '#1E90FF', '#32CD32', '#FF69B4', '#FFA500', '#9370DB',
  '#20B2AA', '#FF6347', '#4169E1', '#DA70D6', '#3CB371'
];

export const InitialCircle = ({ name, size = 40, useFirstWordOnly = false, colorInd }) => {
    const theme = useTheme()
    const colors = theme.colors.chart
    const initials = useFirstWordOnly
        ? name.charAt(0).toUpperCase()
        : name
            .split(' ')
            .map(word => word[0])
            .join('')
            .toUpperCase()
            .slice(0, 2);

    const colorIndex = !_.isNumber(colorInd) ? random(colors.length-1) : colorInd;
    const backgroundColor = hexToRgba(colors[colorIndex], 0.3);

    return (
        <div
            style={{
                width: size,
                height: size,
                borderRadius: '50%',
                backgroundColor,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: hexToRgba(theme.colors.secondary, 0.7),
                fontWeight: 'bold',
                fontSize: `${size * 0.4}px`,
            }}
        >
            {initials}
        </div>
    );
};