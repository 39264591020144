import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { Steps } from 'antd';
import { Flex, FlexColumn } from '../../Reusable/Container';
import { Button, TextButton } from './Button';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';

// Images
import StepIcon from '../../../Images/step.png';
import StepDone from '../../../Images/step-done.png';
import StepInProgress from '../../../Images/step-in-progress.png';
import Back from '../../../Images/back.png';
import BackDisabled from '../../../Images/back-disabled.png';

const { Step } = Steps;

const FlowFooter = (props) => {
    const { buttonTitle, back, onClick, steps, step, backClick, loading } = props

    return (
        <Flex between centerHorizontally fullWidth style={{ height: 88, background: '#FBFBFB', borderRadius: '0px 0px 8px 8px', padding: 24 }}>
            <Flex start style={{ width: 132 }}>
                {
                    back ?
                    <img width='30px' height='30px' src={Back} onClick={backClick} style={{ cursor: 'pointer' }} />
                    :
                    <img width='30px' height='30px' src={BackDisabled} />
                }
            </Flex>

            <Steps progressDot current={step} style={{ width: 400 }}>
                {
                    steps &&
                    steps.map((stepTitle, index) => {
                        var icon
                        if(index === step) {
                            icon = <img width='16px' height='16px' src={StepInProgress} />
                        } else if(index < step) {
                            icon = <img width='8px' height='8px' src={StepDone} />
                        } else {
                            icon = <img width='8px' height='8px' src={StepIcon} />
                        }

                        return <Step title={stepTitle} />
                    })
                }
            </Steps>

            <Button loading={loading} background='transparent' height='40px' radius='4px' onClick={onClick} text={buttonTitle} />
        </Flex>
    )
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(FlowFooter)));