import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Router, Route, Switch, Redirect } from 'react-router-dom';
import { getUserRoles, getUserApps, showCreditTab, removeArrItem } from './Utils/util';

const ProtectedRoute = ({ component: Component, aionStore, path, ...rest }) => {

    // The most of the access logic below is from Home/Index.js
    var { switchBizInProgress } = aionStore;
    var Attributes = aionStore.Attributes || {};
    const bbAttributes = Attributes.BankingAttributes || {};
    const businessAttributes = Attributes.Businesses || {};
    const showCredit = showCreditTab(aionStore);
    const allowedApps = getUserApps(aionStore);
    const { location } = rest;
    const { pathname } = location || {};
    var authorizedRoutes = ["settings", "apply", "callback", "paynow", "user-invite", "contract-sign", "credit-contract-sign", "additional-document", "customer-payment"];
    const { UAM } = aionStore;
    const twoFAValidated = ((Object.keys(businessAttributes).length > 0) && bbAttributes.ApplicationApproved) ? aionStore.TwoFAValidated : true; // Skip for New Business
    if (bbAttributes.ApplicationApproved) {
        if (allowedApps.includes("BusinessBanking")) {
            authorizedRoutes.push("banking")
            authorizedRoutes.push("dashboard")
        }
    }
    if (allowedApps.includes("BusinessCards")) authorizedRoutes.push("cards")
    if (allowedApps.includes("Receivables")) authorizedRoutes.push("receivables")
    if (allowedApps.includes("ARCredit") || allowedApps.includes("TermLoans") || allowedApps.includes("ABLCredit") || pathname.includes("/credit/apply")) authorizedRoutes.push("credit")
    if (allowedApps.includes("Payables")) {
        authorizedRoutes.push("payables")
        authorizedRoutes.push("contractor-payroll")
    }
    if (allowedApps.includes("Bookkeeping")) authorizedRoutes.push("bookkeeping")
    if (allowedApps.includes("BusinessBanking") || allowedApps.includes("Payables")) authorizedRoutes.push("approvals")
    if (UAM.aionCustomerSupportUser) authorizedRoutes.push("support")
    if (UAM.aionUnderWriterUser) authorizedRoutes.push("underwriting")
    // if(!businessAttributes.ARAgreementSigned) removeArrItem(authorizedRoutes, "receivables");
    // console.log("ProtectedRoute path", switchBizInProgress, twoFAValidated, path, allowedApps, authorizedRoutes, authorizedRoutes.includes(path.substr(1)))
    // console.log("ProtectedRoute redirect? ", !( switchBizInProgress || (twoFAValidated && authorizedRoutes.includes(path.substr(1))) ))
    return (
        <Route
            {...rest}
            path={path}
            render={routeProps =>
                ( switchBizInProgress || (twoFAValidated && authorizedRoutes.includes(path.substr(1))) ) ? (
                    <Component {...routeProps} />
                ) : (
                    <Redirect
                        to={{ pathname: "/" }}
                    />
                )
            }
        />
    );
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer
    };
}

export default withRouter(connect(mapStateToProps)(ProtectedRoute));