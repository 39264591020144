import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'
import moment from 'moment';
import { Divider } from 'antd';
import { FlexColumn, Flex, LightContainer } from '../../Reusable/Container'
import { ErrorAlert } from '../../Reusable/Alert'
import { Text, Title } from '../../Reusable/Text'
import { uaApiPOST } from '../../../Utils/userAccess'
import { copyToClipboard, getResourcePerm } from '../../../Utils/util'
import { LabeledInput } from '../../Reusable/Input'
import { TextButton } from '../../Reusable/Button'
import Banner from '../../Reusable/Banner';

// Images
import NewKey from '../../../Images/new-key.png'
import Copy from '../../../Images/copy.png'
import Delete from '../../../Images/delete-icon.png'

class Key extends Component {
    state = {
        visible: false,
        generateNewKeyLoading: false,
    }

    componentDidMount() {
        this.fetchKeys()
    }

    fetchKeys = () => {
        this.setState({ loading: true })
        uaApiPOST('/api/mgmt/getAPIKeyRegistryByBusiness', null, (err, resp) => {
            this.setState({ loading: false })
            try {
                if (err) throw Error(err)
                if (resp.error || resp.responseMessage) throw Error(resp.error || resp.responseMessage)
                this.setState({ ...resp })
            } catch (error) {
                console.log("getAPIKeyRegistryByBusiness error", error, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    togglePWVisibility = () => {
        console.log("togglePWVisibility", this.state.visible)
        this.setState({ visible: !this.state.visible })
    }

    copyKey = () => {
        var { aPIKeyRegistry } = this.state
        aPIKeyRegistry = aPIKeyRegistry || {}
        copyToClipboard(aPIKeyRegistry.apikey)
    }

    generateNewKey = () => {
        this.setState({ generateNewKeyLoading: true })
        uaApiPOST('/api/mgmt/generateAPIKey', null, (err, resp) => {
            this.setState({ generateNewKeyLoading: false })
            try {
                if (err) throw Error(err)
                if (resp.error || resp.responseMessage) throw Error(resp.error || resp.responseMessage)
                this.setState({ ...resp })
                console.log("generateAPIKey error", err, resp)
            } catch (error) {
                console.log("getAPIKeyRegistryByBusiness error", error, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    deleteKey = () => {
        this.setState({ loading: true })
        uaApiPOST('/api/mgmt/deleteAPIKeyRegistry', null, (err, resp) => {
            this.setState({ loading: false })
            try {
                if (err) throw Error(err)
                if (resp.error || resp.responseMessage) throw Error(resp.error || resp.responseMessage)
                this.setState({ ...resp })
            } catch (error) {
                console.log("deleteAPIKeyRegistry error", error, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    createNewKey = (t) => {
        this.setState({ loading: true })
        var body = {
            "APIKeyRegistry": {}
        }
        console.log("create new key", t)
        uaApiPOST('/api/mgmt/saveAPIKeyRegistry', { body: body }, (err, resp) => {
            this.setState({ loading: false })
            try {
                if (err) throw Error(err)
                if (resp.error || resp.responseMessage) throw Error(resp.error || resp.responseMessage)
                this.setState({ ...resp })
            } catch (error) {
                console.log("saveAPIKeyRegistry error", error, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }



    render() {
        var { aPIKeyRegistry, generateNewKeyLoading, loading } = this.state
        var { keyExpiryDate, apikey, whiteListIP } = aPIKeyRegistry || {}
        whiteListIP = whiteListIP || []
        const apiPerm = getResourcePerm("AionAPI.API")
        return (
            <>
                <FlexColumn start>
                    {
                        apikey ?
                            <>
                                <Text heading>{this.props.title || "Keys"}</Text>
                                <Banner style={{ maxWidth: "900px", margin: "15px 0 15px" }} title="Please note" message="Never share your secret keys in plaintext with anyone. If a secret key is compromised, please generate a new key." />   
                                <LightContainer padding='24px 24px 12px'>
                                    <Flex between fullWidth centerHorizontally>
                                        <FlexColumn style={{ width: 330 }}>
                                            <LabeledInput
                                                label='API KEY'
                                                bordered={false}
                                                value={apikey}
                                                readOnly="readonly"
                                                size={'large'}
                                                type='read-only-password'
                                            />
                                            <Text lightText>Valid till {moment(keyExpiryDate).format('ll')}</Text>
                                        </FlexColumn>
                                        <Flex start margin='12px 12px 0'>
                                            <img width='24px' height='24px' src={Copy} onClick={this.copyKey} style={{ cursor: 'pointer' }} />
                                            {
                                                apiPerm.manage &&
                                                <>
                                                    <TextButton permtype="AionAPI.API" margin='0 24px' loading={generateNewKeyLoading} onClick={this.generateNewKey} text='GENERATE NEW KEY' rightIcon={<img width='24px' height='24px' src={NewKey} />} />
                                                    <img width='24px' height='24px' src={Delete} onClick={this.deleteKey} style={{ cursor: 'pointer' }} />
                                                </>
                                            }
                                        </Flex>
                                    </Flex>
                                </LightContainer>
                            </>
                            
                            :
                            (
                                apiPerm.manage &&
                                <Flex start style={{margin : '12px 0'}}>
                                    <TextButton permtype="AionAPI.API" key='generate key' loading={loading} onClick={() => this.createNewKey()} text='GENERATE KEY' rightIcon={<img width='24px' height='24px' src={NewKey} />} />
                                </Flex>
                            )
                    }  
                </FlexColumn>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer,
        bankingStore: state.bankingAppReducer,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Key))