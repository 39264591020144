import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'
import styled from 'styled-components'
import moment from 'moment-business-days'
import ordinal from 'ordinal'

import { Timeline, Spin } from 'antd'

import {
    ClockCircleTwoTone, CheckCircleTwoTone, MinusCircleTwoTone, MailTwoTone
} from '@ant-design/icons'

// Components
import { Button } from '../../Reusable/Button'
import { Text } from '../../Reusable/Text'
import { FlexColumn, Flex } from '../../Reusable/Container'
import { LabeledInput } from '../../Reusable/Input'
import { ErrorAlert } from '../../Reusable/Alert'
import environment from '../../../environment'
import { apiPOSTReq, apiGET } from '../../../Utils/api'
import { addressObjectToStr, dateFormatList } from '../../../Utils/util'
import { toCurrency, isBeforeCutOff, getFeatureLimit, getBBAccountsWithAccess, getBPAccountsWithAccess } from '../../../Utils/util'

export const CardButton = styled.div`
    margin-top: 18px;
    background-color: rgb(255, 255, 255);
    border-radius: 0.5rem;
    width: 400px;
    height: 200px;
    margin-bottom: 1.5rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    opacity: 1;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    padding: 1.8rem;
    transition-property: box-shadow;
    transition-duration: 300ms;
    &:hover {
        cursor: pointer;
        box-shadow: 0 3px 15px rgb(0 0 0 / 0.4);
    };
`

export const CardButtonText = styled.span`
    font-size: 18px;
    font-weight: 400;
`
const TimelineText = styled.span`
    margin-bottom: 5px ;
    font-size: 18px;
`

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    payBillLoading: false,
})


class RescheduleBill extends Component {

    state = {
        bill: this.props.bill || { billRecipientEmail: [], line: [] },
        vendor: this.props.vendor || {},
        scheduleDate: moment(this.props.bill.scheduledDateStr, "YYYY-MM-DD").isBefore(moment().businessAdd(1)) ? moment().businessAdd(1) : moment(this.props.bill.scheduledDateStr, "YYYY-MM-DD"),
        payOnOption: "dueDate",
        // paymentDate: moment(props.bill.dueDate).isBefore(moment()) ? moment().businessAdd(1) : moment(props.bill.dueDate).subtract(1, 'd'),
    }

    componentDidMount() {
        var { bill } = this.state

        const { store } = this.props
        var { publicHolidays } = store
        // Update moment to include holidays
        moment.updateLocale('us', {
            holidays: publicHolidays,
            holidayFormat: 'YYYY-MM-DD'
        })


        this.evaluatePaymentApproval()
        this.fetchAccount()
        this.getVendor(bill.vendorId)
    }

    getVendor = (id) => {
        apiPOSTReq(`${environment.payBaseUrl}/payables/vendors/getVendor`, {}, { vendorId: id }, (err, resp) => {
            try {
                const data = resp
                if (data.result && data.vendor) {
                    this.setState({ vendor: data.vendor, selectedVendor: data.vendor })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /payables/vendors/getVendor", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    handleDateChange = (e) => {
        if (e.target.value === "payNow") {
            var paymentDate = moment()

            if (!isBeforeCutOff()) paymentDate = paymentDate.businessAdd(1)

            this.setState({ payOnOption: e.target.value, paymentDate })
        } else {
            this.setState({ payOnOption: e.target.value, paymentDate: moment().businessAdd(1) })
        }
    }

    disabledDate = (current) => {
        // Can not select days before today and today and weekends / holidays
        return !current.isBusinessDay() || current < moment().add(1, 'd').startOf('day')
    }

    saveInput = (id, dataToSave) => {
        this.setState({ [id]: dataToSave })
    }

    evaluatePaymentApproval = () => {
        apiPOSTReq(`${environment.payBaseUrl}/payables/evaluatePaymentApproval`, { "BankProvider": environment.bankProvider.crb }, { billId: this.props.bill.id }, (err, resp) => {
            this.setState({ messageLoading: false })
            try {
                const data = resp
                console.log(`${environment.payBaseUrl}/payables/evaluatePaymentApproval`, JSON.stringify(data))
                if (data.result) {
                    this.setState({ approvalRequired: data.approvalRequired, approvalRule: data.approvalRule })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
                // this.setState({ payLoading: false })
            } catch (error) {
                console.log("ERRR /payables/evaluatePaymentApproval", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    getApprovalText = () => {
        const { approvalRule } = this.state
        const { levels } = approvalRule

        var approvalText

        if (levels.length > 1) {
            approvalRule.levels.forEach((level, i) => {
                approvalText = approvalText + `${(i > 0) && ', followed by'} <b>${ordinal(i + 1)}</b> level approval from ${this.getApproversText(level.performers, level.type)}`
            })
        } else {
            approvalText = `This payment requires approval from ${this.getApproversText(levels[0].performers, levels[0].type)}`
        }

        return <Text>{approvalText}</Text>
    }

    getApproversText = (approvers, approvalBy) => {
        if (approvers.length == 1) {
            return `${approvers[0].firstName} ${approvers[0].lastName}`
        } else {
            return <>
                {approvers.map(a => `${a.firstName} ${a.lastName}`).slice(0, approvers.length - 1).join(", ")}<b>{approvalBy === "ANY" ? " or " : " and "}</b>{`${approvers[approvers.length - 1].firstName} ${approvers[approvers.length - 1].lastName}`}
            </>
        }
    }

    fetchAccount = () => {
        let accounts = getBPAccountsWithAccess(null, { resourceStr: "Payables.Bills", permType: "manage", getActiveAccounts: true })
        apiGET("/banking/radius/payments/scheduled", {}, (err, resp) => {
            try {
                const data = resp.data || {}
                if (data.success) {
                    let account = accounts[0]
                    let scheduled = data.scheduledByAccounts[account.accountId]

                    if (this.props.vendor && this.props.vendor.fromAccountId) {
                        account = accounts.filter(x => x.accountId === this.props.vendor.fromAccountId)
                        if (account.length > 0) {
                            account = account[0]
                            scheduled = data.scheduledByAccounts[account.accountId]
                        }
                    }

                    this.setState({
                        totalScheduled: data.totalScheduled,
                        scheduledByAccounts: data.scheduledByAccounts,
                        account,
                        scheduled,
                    })
                } else {
                    throw Error(data.msg || "Sorry we had trouble processing your request, please try again.")
                }
            } catch (error) {
                ErrorAlert({ description: error.message })
            }
        })
    }

    reschedule = () => {
        this.setState({ payBillLoading: true })
        const { bill } = this.props
        const { scheduleDate } = this.state

        var body = {
            "billId": bill.id,
            "scheduleDate": scheduleDate.format("YYYY-MM-DD"),
        }

        apiPOSTReq(`${environment.payBaseUrl}/payables/editBillSchedule`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            try {
                const data = resp
                console.log(`${environment.payBaseUrl}/payables/editBillSchedule`, JSON.stringify(data))
                if (data.result) {
                    this.props.onPay()
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
                this.setState({ payLoading: false })
            } catch (error) {
                console.log("ERRR /payables/editBillSchedule", error, err, resp)
                ErrorAlert({ description: error.message })
            }
            this.setState({ payLoading: false })
        })
    }

    render() {
        const { theme, bankingStore, bill, vendorPaymentAccount } = this.props
        const { payOnOption, paymentDate, messageLoading, approvalRequired, approvalRule, payBillLoading, scheduleDate, vendor } = this.state
        var Accounts = getBPAccountsWithAccess(null, { resourceStr: "Payables.Bills", permType: "manage", getActiveAccounts: true })

        var { invoiceNumber, amount, dueDate, notes, category } = bill
        const { id, vendorName, primaryEmail, primaryPhone, taxId, address, type, deliveryMode } = vendor

        let businessDailyLimits = bankingStore.BusinessDailyLimits || {}
        var achDailyLimit = businessDailyLimits.ACH || parseFloat(getFeatureLimit("BusinessBanking.Payments.Daily ACH Limit")) || 5000

        // const userPaymentAccount = (Accounts || []).find(account => account.accountId === vendorPaymentAccount.accountId)

        return (
            <FlexColumn start>
                <Flex between>
                    <FlexColumn start style={{ width: "50%", marginRight: 12 }}>
                        <Text size='20px' margin="10px 0 0 0">When do you want to reschedule your bill payment to?</Text>

                        <LabeledInput type='read-only' label="Payment due on" value={moment(dueDate).format("ll")} />

                        <LabeledInput
                            label="Reschedule date"
                            key="scheduleDate"
                            id="scheduleDate"
                            type="date-picker"
                            format={dateFormatList}
                            value={scheduleDate && moment(scheduleDate)}
                            disabledDate={this.disabledDate}
                            placeholder={moment(scheduleDate).format("MM-DD-YYYY")}
                            onChange={(date, dateStr) => this.saveInput("scheduleDate", date)}
                            onKeyDown={this.handleOnKeyDown}
                        />

                        {
                            scheduleDate &&
                            <>
                                {
                                    messageLoading ?
                                        <Flex style={{ marginTop: 100 }} center>
                                            <Spin />
                                        </Flex>
                                        :
                                        <div style={{ width: "100%", paddingTop: 12, paddingLeft: 5 }}>
                                            {
                                                deliveryMode === "ACH" && (
                                                    // approvalRequired ?
                                                    //     <Timeline>
                                                    //         <Timeline.Item dot={<ExclamationCircleTwoTone style={{ fontSize: '16px' }} twoToneColor="#f81d22" />}>
                                                    //             <TimelineText>This payment requires approval</TimelineText>
                                                    //         </Timeline.Item>
                                                    //         <Timeline.Item dot={<QuestionCircleTwoTone style={{ fontSize: '16px' }} />}>
                                                    //             <TimelineText>
                                                    //                 {
                                                    //                     approvalRule && approvalRule.levels.length > 1 ?
                                                    //                         approvalRule.levels.map((level, i) => {
                                                    //                             return <>{(i > 0) && ', followed by'} <b>{ordinal(i + 1)}</b> level approval {i == 0 && 'is required '}from {this.getApproversText(level.performers, level.type)}</>
                                                    //                         })
                                                    //                         :
                                                    //                         <>This payment requires approval from {this.getApproversText(approvalRule.levels[0].performers, approvalRule.levels[0].type)}</>
                                                    //                 }
                                                    //             </TimelineText>
                                                    //         </Timeline.Item>
                                                    //         <Timeline.Item dot={<CheckCircleTwoTone style={{ fontSize: '16px' }} twoToneColor="#52c41a" />} color="green">
                                                    //             <TimelineText>Once approved your payment will be initiated and will take 1-3 days to reach <b>{vendorName}</b></TimelineText>
                                                    //         </Timeline.Item>
                                                    //     </Timeline>
                                                    //     :
                                                    <Timeline>
                                                        <Timeline.Item dot={<ClockCircleTwoTone style={{ fontSize: '16px' }} />}>
                                                            <TimelineText><Text>Payment will be inititiated on <b>{scheduleDate.format("ll")}</b>{(payOnOption === "payNow" && !isBeforeCutOff()) && " as you are past the daily ACH cutoff time"}</Text></TimelineText>
                                                        </Timeline.Item>
                                                        <Timeline.Item dot={<MinusCircleTwoTone style={{ fontSize: '16px' }} twoToneColor="#eb2f96" />}>
                                                            <TimelineText><Text><b>{formatter.format(amount)}</b> will be deducted on <b>{scheduleDate.businessAdd(payOnOption === "payNow" && !isBeforeCutOff() ? -1 : 0).format("ll")}</b></Text></TimelineText>
                                                        </Timeline.Item>
                                                        <Timeline.Item dot={<CheckCircleTwoTone style={{ fontSize: '16px' }} twoToneColor="#52c41a" />} color="green">
                                                            <TimelineText><Text>Estimated to reach <b>{vendorName}</b> between <b>{scheduleDate.businessAdd(1).format("ll")}</b> and <b>{scheduleDate.businessAdd(3).format("ll")}</b> as per federal and receiving bank payment settlement timelines</Text></TimelineText>
                                                        </Timeline.Item>
                                                    </Timeline>
                                                )
                                            }

                                            {
                                                deliveryMode === "Check" && (
                                                    // approvalRequired ?
                                                    //     <Timeline>
                                                    //         <Timeline.Item dot={<ExclamationCircleTwoTone style={{ fontSize: '16px' }} twoToneColor="#f81d22" />}>
                                                    //             <TimelineText>This payment requires approval</TimelineText>
                                                    //         </Timeline.Item>
                                                    //         <Timeline.Item dot={<QuestionCircleTwoTone style={{ fontSize: '16px' }} />}>
                                                    //             <TimelineText>
                                                    //                 {
                                                    //                     approvalRule && approvalRule.levels.length > 1 ?
                                                    //                         approvalRule.levels.map((level, i) => {
                                                    //                             return <>{(i > 0) && ', followed by'} <b>{ordinal(i + 1)}</b> level approval {i == 0 && 'is required '}from {this.getApproversText(level.performers, level.type)}</>
                                                    //                         })
                                                    //                         :
                                                    //                         <>This payment requires approval from {this.getApproversText(approvalRule.levels[0].performers, approvalRule.levels[0].type)}</>
                                                    //                 }
                                                    //             </TimelineText>
                                                    //         </Timeline.Item>
                                                    //         <Timeline.Item dot={<CheckCircleTwoTone style={{ fontSize: '16px' }} twoToneColor="#52c41a" />} color="green">
                                                    //             <TimelineText>Once approved your payment will be initiated and will take 1-3 days to reach <b>{vendorName}</b></TimelineText>
                                                    //         </Timeline.Item>
                                                    //     </Timeline>
                                                    //     :
                                                    <Timeline>
                                                        <Timeline.Item dot={<MailTwoTone style={{ fontSize: '16px' }} />}><TimelineText><Text>A check will be mailed to <b>{vendorName}</b> on <b>{moment(scheduleDate).businessAdd(1).format("ll")}</b></Text></TimelineText></Timeline.Item>
                                                        <Timeline.Item dot={<CheckCircleTwoTone style={{ fontSize: '16px' }} twoToneColor="#52c41a" />}><TimelineText><Text>Check estimated to reach <b>{vendorName}</b> around <b>{moment(scheduleDate).businessAdd(7).format("ll")}</b></Text></TimelineText></Timeline.Item>
                                                    </Timeline>
                                                )
                                            }

                                            {
                                                (deliveryMode === "ACH" && payOnOption === "payNow" && isBeforeCutOff()) &&
                                                <FlexColumn>
                                                    <TimelineText><Text>The daily cut-off time for ACH payments is 6:00 PM ET. Payments initiated after this time are processed the next business day.</Text></TimelineText>
                                                </FlexColumn>
                                            }

                                            {
                                                (deliveryMode === "ACH" && payOnOption === "payNow" && !isBeforeCutOff()) &&
                                                <FlexColumn>
                                                    <TimelineText><Text>The daily cut-off time for ACH payments is 6:00 PM ET. Payments initiated after this time are processed the next business day.</Text></TimelineText>
                                                </FlexColumn>
                                            }

                                            {
                                                deliveryMode === "ACH" &&
                                                <Text size='20px'>
                                                    Daily ACH limit: <b style={{ color: theme.colors.creditGreen }}>${toCurrency(achDailyLimit)}</b><br />
                                                </Text>
                                            }
                                        </div>
                                }
                            </>
                        }
                    </FlexColumn>

                    <FlexColumn style={{ width: "45%", justifyContent: "flex-start" }}>
                        {/* <Text size='20px'>You're about to pay <span style={{ color: theme.colors.secondary1 }}>${toCurrency(bill.amount)}</span></Text>

                        <Text size='20px'>
                            Daily ACH limit: <b style={{ color: theme.colors.secondary2 }}>${toCurrency(achDailyLimit)}</b><br />
                        </Text> */}
                        <Text size='20px' margin="10px 0 12px 0">Please verify the information below before hitting Reschedule</Text>
                        {/* {
                            this.state.account &&
                            <AccountInfoCard
                                theme={theme}
                                mask={this.state.account.Mask}
                                availableBalance={`$${toCurrency(this.state.account.AvailableBalance)}`}
                                scheduled={`$${toCurrency(this.state.scheduled)}`}
                                fromAccount={userPaymentAccount}
                            />
                        } */}

                        <LabeledInput type='read-only' key="0" label="Payment to" value={vendor.vendorName} />
                        {/* {
                            userPaymentAccount &&
                            <LabeledInput type='read-only' key="1" label="From account" value={<>{(userPaymentAccount.nickName || `Business ${userPaymentAccount.accountSubType}`).toUpperCase()} - <span className="MICR-font">****{userPaymentAccount.mask}</span></>} />
                        } */}
                        {
                            (vendorPaymentAccount && vendorPaymentAccount.bankDetail && vendorPaymentAccount.bankDetail.accountNumber) &&
                            <LabeledInput type='read-only' key="4" label="Vendor payment details" value={<>{(vendorPaymentAccount.type).toUpperCase()} - <span className="MICR-font">****{vendorPaymentAccount.bankDetail.accountNumber.substring(vendorPaymentAccount.bankDetail.accountNumber.length - 4)}</span></>} />
                        }
                        {
                            (vendorPaymentAccount && vendorPaymentAccount.type === "Check" && vendorPaymentAccount.bankDetail && vendorPaymentAccount.bankDetail.bankAddress) &&
                            <LabeledInput type='read-only' key="4" label="Vendor payment details" value={<>Check payment <br />Address: {addressObjectToStr(vendorPaymentAccount.bankDetail.bankAddress)}</>} />
                        }
                        {
                            // deliveryMode === "ACH" ?
                                // <LabeledInput type='read-only' key="3" label="Payment will be initiated on" value={moment(paymentDate).format("ll")} />
                                // :
                                // <LabeledInput type='read-only' key="6" label="Check will be issued and mailed on" value={moment(paymentDate).businessAdd(1).format("ll")} />
                        }
                        <LabeledInput type='read-only' key="5" label="Transaction fee" value={"$0"} />
                    </FlexColumn>
                </Flex>
                <Flex style={{ width: "100%", marginTop: 15, marginBottom: 30 }} end>
                    <Button permtype="Payables.Bills" solid loading={payBillLoading} onClick={() => this.reschedule()} text="Reschedule" />
                </Flex>
            </FlexColumn>
        )

    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer,
        bankingStore: state.bankingAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(RescheduleBill))