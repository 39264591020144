import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';
import moment from 'moment';
import _ from 'lodash';

import {
    Descriptions,
    message,
    Popconfirm,
    Skeleton,
    Space,
    Table,
    Timeline,
    Tooltip,
    Typography,
    Dropdown,
    Menu,
    Drawer,
} from 'antd';

// Util
import { TextButton, ImageButton } from '../../Reusable/Button';
import { Flex, FlexColumn } from '../../Reusable/Container';
import { Image } from '../../Reusable/Image'
import { apiPOSTReq, getPreSignedS3Url } from '../../../Utils/api';
import environment from '../../../environment';
import { getResourcePerm, getUserApps, toCurrency, getFeaturePerm } from '../../../Utils/util';
import { capitalize } from 'lodash';
import { Text, Tag } from '../../Reusable/Text';
import { DeleteOutlined, DownloadOutlined, DownOutlined, EditOutlined, UpOutlined } from '@ant-design/icons';
import { ErrorAlert } from '../../Reusable/Alert';
import AlertModal from '../../Reusable/AlertModal'
import PaymentDrawer from './PaymentDrawer'
import HistoryDrawer from './HistoryDrawer'

import QB_ICON from '../../../Images/qb-logo-stacked.svg';
import { saveAs } from 'file-saver';
import { SignedLink } from '../../Reusable/Link';
import ChevronDown from "../../../Images/chevron-down.svg"
import More from "../../../Images/more.png"
import Edit from "../../../Images/edit.png"
import Send from '../../../Images/send.svg'
import Add from '../../../Images/add.svg'
import VoidButton from '../../../Images/void.svg'
import Download from '../../../Images/download.png'
import Delete from '../../../Images/delete.svg'
import ModalClose from '../../../Images/modal-close.png'
import History from "../../../Images/history.svg"
import RecordPayment from '../../../Images/record-payment.svg'
import TableView from '../../Reusable/TableView';
import hexToRgba from 'hex-to-rgba';


const CreditStatusTag = (options) => {
    let statusMsg;
    let statusColor;
    let { invoice, theme } = options;
    switch (invoice.creditStatus) {
        case "REQUESTED":
            statusColor = theme.colors.secondary3;
            statusMsg = "Sent for Verification";
            break
        case "INREVIEW":
            statusColor = theme.colors.secondary1;
            statusMsg = "In Review";
            break;
        case "VALIDATED":
        case "ELIGIBLE_FOR_ADVANCE":
            statusColor = theme.colors.primary;
            statusMsg = "";
            break;
        case "REJECTED":
            statusColor = "red";
            statusMsg = "Rejected";
            break;
        case "INELIGIBLE_FOR_ADVANCE":
            statusColor = "red";
            statusMsg = "Ineligible for Advance";
            break;
        case "ADVANCED":
            statusColor = theme.colors.primary;
            statusMsg = "Advanced";
            break;
        default:
            break
    }
    if ((invoice.CreditStatus != "ADVANCED") && (moment(invoice.dueDate).diff(moment()) < 0)) {
        statusColor = theme.colors.secondary7;
        statusMsg = "Expired";
    }
    return statusMsg ? <Tooltip title="Credit status"><Tag tagpreset={statusColor}>{statusMsg.toUpperCase()}</Tag></Tooltip> : null
}

class InvoiceTable extends Component {
    state = {
        invoiceTrackLogs: []
    };

    getVerificationStatusContent(item) {
        return (
            <div>
                <Typography.Text>{item.systemMessage}</Typography.Text>
                {item.customerNote ?
                    <div>
                        <br />
                        <Typography.Text mark>customer note:</Typography.Text><Typography.Text mark> {item.customerNote}</Typography.Text>
                    </div>
                    : null}
            </div>
        )
    }

    componentDidMount() {
        const { businessId } = this.props.aionStore;
        this.getTrackingInfos(businessId);
    }

    selectRow = (record) => {
        // this.props.showSelectedInvoice(record);
        this.props.history.push({
            pathname: '/receivables/invoices/view',
            state: { invoice: record, balance: record.balance }
        })
    }

    handleTableChange = (pagination, filters, sorter) => {
        this.props.fetchInvoices({ pagination: pagination, sorter: sorter });
    }

    getTrackingInfos = (businessId) => {
        const body = {
            "businessId": businessId
        }
        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/getTrackLogs`, null, body, (err, resp) => {
            try {
                const data = resp || {};
                // console.log("/getTrackLog", data)
                if (data.result) {
                    this.setState({
                        invoiceTrackLogs: data.invoiceTrackLogs
                    })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getTrackLog", error, err, resp);
            }
        })
    }

    handleScheduleCancel = (invoice) => {
        const { invoiceId, scheduleId, docNumber } = invoice;
        this.setState({ loadingScheduleCancel: true });
        const body = {
            invoiceId: invoiceId,
            scheduleId: scheduleId
        }
        console.log("/ive/invoice/scheduleInvoice", body)
        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/cancelSchedule`, {}, body, (err, resp) => {
            this.setState({ loadingScheduleCancel: false });
            try {
                const data = resp;
                console.log("POST success /ive/invoice/cancelSchedule", data);
                if (data.result) {
                    message.success(`Invoice ${docNumber} was canceled`);
                } else {
                    throw Error(data.responseMessage || data.error);
                }
            } catch (error) {
                console.log("ive/invoice/cancelSchedule", error, err, resp)
                ErrorAlert({ description: error.message });
            }
        })
    }

    getTimeLineItems = (invoice, dateFormat) => {
        var { invoiceStatus, balance, emailStatus, metaData, txnDate, emailSentDate } = invoice;
        var { invoiceTrackLogs } = this.state;
        var emailEvents = [];
        var eventDates = [];
        invoiceTrackLogs.forEach(log => {
            var id = log.invoiceId;
            if (id == invoice.invoiceId) {
                emailEvents = log.events;
                eventDates = log.eventDates;
            }
        })
        var timelineItems = [{ name: "Created", date: moment(metaData.createTime).format(dateFormat) }];
        // if((invoiceStatus || "").toUpperCase() !== "NEW") timelineItems.push({name: invoiceStatus, date: null});
        // else if((invoiceStatus || "").toUpperCase() == "NEW") timelineItems.push("Not Sent");
        switch ((invoiceStatus || "").toUpperCase()) {
            case "NEW":
                timelineItems.push({ name: "Not Sent", date: null });
                break;
            case "SCHEDULED":
                timelineItems.push({ name: "Scheduled", date: moment(txnDate).format(dateFormat) });
                break;
            case "PENDING":
                timelineItems.push({ name: "Pending", date: null });
                break;
            case "SENT":
                timelineItems.push({ name: "Sent", date: moment(emailSentDate).format(dateFormat) });
                break;
            case "CANCELED":
                timelineItems.push({ name: "Canceled", date: null });
                break;
        }
        if (emailEvents) {
            // timelineItems = timelineItems.concat(emailEvents);
            emailEvents.forEach((event, i) => {
                var existingEvent = timelineItems.find(item => item.name == event);
                if (existingEvent) existingEvent.date = moment(eventDates[i]).format(dateFormat); // Update date to latest
                else timelineItems.push({ name: event, date: moment(eventDates[i]).format(dateFormat) })
            })
        } else if (emailStatus) {
            timelineItems.push({ name: emailStatus, date: null });
        }
        if (balance == 0) timelineItems.push({ name: "Paid", date: null });

        timelineItems = timelineItems.map(item => {
            item.name = capitalize(item.name);
            return item;
        });
        return timelineItems;
    }

    getRowDetails = (invoice) => {
        var { invoiceStatus, balance, emailStatus, terms, memo, privateNote, metaData, scheduleId, systemMessage, customerNote, txnDate, emailSentDate } = invoice;
        const { theme } = this.props;
        const dateFormat = "MM/DD/YY";

        var timelineItems = this.getTimeLineItems(invoice, dateFormat);
        var invoiceDoc = (invoice.attachments || []).find(item => item.type == "InvoiceDoc");

        var attachments = (invoice.attachments || []).filter(item => item.type !== "InvoiceDoc")

        return (
            <Skeleton loading={this.state.loadingTrackLog} active title={false} paragraph={{ rows: 3 }}>
                <Flex style={{ padding: "20px", background: theme.body, justifyContent: "space-around" }}>
                    <FlexColumn start>
                        <Text margin='12px 0 15px 0' color='#000000' bold>Invoice details</Text>
                        <Descriptions bordered size="small">
                            <Descriptions.Item label="Created On" contentStyle={{ minWidth: 100, background: '#FFFFFF' }}>{moment(metaData.createTime).format(dateFormat)}</Descriptions.Item>
                            {terms && <Descriptions.Item label="Terms" contentStyle={{ background: '#FFFFFF' }}>{terms}</Descriptions.Item>}
                            <Descriptions.Item label="Balance" contentStyle={{ background: '#FFFFFF' }}><span style={{ whiteSpace: 'nowrap' }}>${toCurrency(balance)}</span></Descriptions.Item>
                            <Descriptions.Item label="Payment Status" contentStyle={{ minWidth: 100, background: '#FFFFFF' }}>{(invoiceStatus == 'PARTIALLYPAID' ? "Partially Paid" : (invoiceStatus == 'PAID' ? "Paid" : "Unpaid"))}</Descriptions.Item>
                            {memo && <Descriptions.Item label="Memo" contentStyle={{ minWidth: 200, background: '#FFFFFF' }}>{memo}</Descriptions.Item>}
                            {privateNote && <Descriptions.Item label="Private Note" contentStyle={{ minWidth: 200, background: '#FFFFFF' }}>{privateNote}</Descriptions.Item>}
                            {systemMessage && <Descriptions.Item contentStyle={{ background: '#FFFFFF' }} label="System Message">{systemMessage}</Descriptions.Item>}
                            {customerNote && <Descriptions.Item contentStyle={{ background: '#FFFFFF' }} label="Customer Note">{customerNote}</Descriptions.Item>}
                            {invoiceDoc && <Descriptions.Item contentStyle={{ background: '#FFFFFF' }} label="Invoice Document"><SignedLink {...invoiceDoc} /></Descriptions.Item>}
                            {attachments && <Descriptions.Item contentStyle={{ background: '#FFFFFF' }} label="Attachments">
                                {
                                    attachments.map((attachment, index) => <>{index > 0 ? ", " : ""}<a style={{ whiteSpace: 'nowrap' }} onClick={() => this.downloadAttachment(attachment)}>{attachment.fileName}</a></>)
                                }
                            </Descriptions.Item>}
                        </Descriptions>
                        {(["Scheduled"].includes(invoiceStatus.toLowerCase())) && scheduleId && <TextButton onClick={() => this.handleScheduleCancel(invoice)} loading={this.state.loadingScheduleCancel} style={{ marginTop: "10px" }} text='Cancel invoice schedule' />}
                    </FlexColumn>
                    <FlexColumn start>
                        <Text margin='12px 0 15px 40px' color='#000000' bold>Activity log</Text>
                        <Timeline style={{ marginTop: "10px", width: 220, minWidth: 220 }} mode="left">
                            {timelineItems.map((item, i) => <Timeline.Item key={i} label={item.date}>{item.name}</Timeline.Item>)}
                        </Timeline>
                    </FlexColumn>
                </Flex>
            </Skeleton>
        )
    }

    handleRowSelection = (selectedRowKeys, selectedRows) => {
        this.props.handleRowSelection(selectedRowKeys, selectedRows);
    }

    deleteInvoice = (options, pagination) => {
        const { invoiceId, docNumber } = options;
        const body = {
            "InvoiceId": invoiceId
        }
        this.setState({ deleteLoading: true });
        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/delete`, {}, body, (err, resp) => {
            try {
                this.setState({ deleteLoading: false, showDeleteModal: false });
                const data = resp;
                console.log("/ive/invoice/delete", data)
                if (data.result) {
                    message.success(`Invoice No. ${docNumber} deleted!`);
                    this.props.fetchInvoices({ pagination: pagination });
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR /ive/product/delete", error.stack, resp);
                ErrorAlert({ description: error.message })
            }
        })
    }

    canEdit = (record) => {
        if (getResourcePerm("Support.Console").aionSystemUser) {
            return false;
        }

        if (!record.invoiceStatus && !record.emailStatus) return true;
        return ['NEW', 'DRAFT'].includes(record.invoiceStatus);
    }

    downloadAttachment = (attachment) => {
        if (attachment) {
            getPreSignedS3Url({ url: attachment.uri }, (err, preSignedUrl) => {
                saveAs(preSignedUrl, attachment.fileName)
            });
        } else {
            ErrorAlert({ description: "No document found for this attachment" });
        }
    }

    getInvoiceLogs = (invoice) => {
        var body = {
            docNumber: invoice.docNumber,
        }

        console.log("Invoices invoice/getInvoiceLogs body", body)

        this.setState({ invoiceLogLoading: true })

        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/getInvoiceLogs`, null, body, (err, resp) => {
            try {
                const data = resp || {}

                console.log("Invoices invoice/getInvoiceLogs", data)

                this.setState({ invoiceLog: data.invoiceLog, invoiceToView: invoice, invoiceLogVisible: true })
            } catch (error) {
                console.log("ERRR getInvoiceLogs", error, err, resp)
                ErrorAlert({ description: error.message })
            } finally {
                this.setState({ invoiceLogLoading: false })
            }
        })
    }

    render() {
        const { theme, aionStore, loading, pagination, invoices, handleRowSelection, showRowSelection, onLoadMore, moreToLoad, onVoid, showCustomer, showStatus } = this.props;
        const { invoiceToDelete, deleteLoading, showDeleteModal, showAddPayment, invoiceToPay, invoiceToView, invoiceLog, invoiceLogVisible, invoiceLogLoading } = this.state;
        const attributes = aionStore.Attributes || {};
        const businessAttributes = attributes.Businesses || {};
        const dateFormat = "MM/DD/YY";
        const userApps = getUserApps(this.props.aionStore);
        const { CurrentSync } = this.props.aionStore;
        let qbo = false;
        if (CurrentSync && CurrentSync.platformName === 'QUICKBOOKS' && CurrentSync.syncSetupCompleted && userApps && userApps.includes("Receivables")) {
            qbo = true;
        }

        const v2BetaEnabled = getFeaturePerm("Receivables.Invoices.Receivables v2 Beta")

        console.log("this.props.ablUser: ", this.props.ablUser)

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });

        const StatusTag = (options) => {
            let { invoice } = options;
            var { invoiceStatus, emailStatus, cred } = invoice;
            var showCreditStatus;
            const attributes = aionStore.Attributes || {};
            const businessAttributes = attributes.Businesses || {};
            if (invoiceStatus !== 'VOIDED' && businessAttributes.ARAgreementSigned && invoice.creditStatus) {
                showCreditStatus = <CreditStatusTag theme={theme} invoice={invoice} />
            }
            // for these 3 invoice status don't show email status
            let shouldShowEmailStatus = invoiceStatus !== 'VOIDED' && invoiceStatus !== 'NEW' && invoiceStatus !== 'PARTIALLYPAID' && invoiceStatus !== 'Sent Via Accounting Software' && invoiceStatus !== 'Scheduled Via Accounting Software';
            var timeLineItems = this.getTimeLineItems(invoice, dateFormat);
            if (invoiceStatus.toLowerCase() == 'partiallypaid') {
                invoiceStatus = 'Partially Paid';
            }
            const timeLineStatus = timeLineItems.pop().name.toUpperCase()
            return (
                <Flex end={v2BetaEnabled.manage} start={!v2BetaEnabled.manage} gap='8px'>
                    {invoiceStatus && <Tooltip title="Invoice status"><Tag>{invoiceStatus.toUpperCase()}</Tag></Tooltip>}
                    {shouldShowEmailStatus && emailStatus && !['created', 'paid'].includes(timeLineStatus.toLowerCase()) && <Tooltip title="Email status"><Tag primary>{timeLineStatus}</Tag></Tooltip>}
                    {showCreditStatus}
                </Flex>
            )
        }

        var columns = [
            {
                title: 'Number',
                dataIndex: 'docNumber',
                key: 'docNumber',
                width: "200px",
                align: 'left',
                sorter: true,
                showSorterTooltip: false,
                render: (docNumber, record) => {
                    return v2BetaEnabled.manage ? <a style={{ textDecoration: "underline" }}>{docNumber}</a> :
                        <Text>{docNumber}</Text>
                },
            },
        ]

        if (showCustomer) {
            columns.push(
                {
                    title: 'Customer',
                    dataIndex: 'customerName',
                    key: 'Customer',
                    sorter: true,
                    showSorterTooltip: false,
                }
            )
        }

        if (v2BetaEnabled.manage) {
            columns = columns.concat([
                {
                    title: 'Balance',
                    dataIndex: 'balance',
                    key: 'balance',
                    render: (balance, record) => { return <Flex start className='cell-content'><Text weight='500'>{formatter.format(balance)}<span className='hover-content' style={{ color: hexToRgba(theme.colors.primary, 0.7) }}> / <b title='Total'>{formatter.format(record.totalAmt)}</b></span></Text></Flex> },
                    align: 'left',
                    sorter: true,
                    showSorterTooltip: false,
                    // width: "300px",
                },
            ])
        }

        // columns.push({
        //     title: '',
        //     dataIndex: 'totalAmt',
        //     key: 'totalAmt',
        //     align: 'left',
        //     // sorter: true,
        //     // showSorterTooltip: false,
        //     onHover: true,
        //     render: (amount, record) => <Text weight='500'>{`Amount: ${formatter.format(amount)}`}</Text>,
        //     width: "200px",
        // })

        columns.push({
            title: 'Invoice Date',
            dataIndex: 'txnDate',
            key: 'txnDate',
            align: 'right',
            render: (date, invoice) => {
                if (date) {
                    date = new Date(date).toISOString();
                    date = date.split("T")[0];
                }
                return (moment(date).format('ll'))
            },
            sorter: true, //(a, b) => moment(a.txnDate).unix() - moment(b.txnDate).unix(),
            // sortOrder: sortedInfo.columnKey === 'txnDate' && sortedInfo.order,
            showSorterTooltip: false,
            // width: "140px",
        })

        columns.push({
            title: 'Due Date',
            dataIndex: 'dueDate',
            key: 'dueDate',
            align: 'right',
            render: (date, invoice) => {
                if (date) {
                    date = new Date(date).toISOString();
                    date = date.split("T")[0];
                }
                return <Flex end centerHorizontally gap='12px' className='cell-content'>

                    {
                        v2BetaEnabled.manage && invoice.invoiceStatus.toLowerCase() !== 'voided' && invoice.invoiceStatus.toLowerCase() !== 'paid' && moment().isAfter(moment(date)) &&
                        <Tag alert>Overdue</Tag>
                    }
                    <Text>{moment(date).format('ll')}</Text>
                </Flex>
            },
            // width: "210px",
            sorter: true,
            showSorterTooltip: false,
        })

        if (v2BetaEnabled.manage) {
            if (showStatus) {
                columns.push({
                    title: 'Status',
                    dataIndex: 'invoiceStatus',
                    key: 'status',
                    render: (status, invoice) => <Tag>{status.toLowerCase()}</Tag>,
                    // width: "120px",
                    align: 'right',
                    sorter: true,
                    showSorterTooltip: false,
                })
            }
        } else {
            columns = columns.concat([
                {
                    title: 'Purchase Order',
                    dataIndex: 'ponumber',
                    key: 'ponumber',
                    align: 'right',
                    sorter: true,
                    width: "200px",
                    showSorterTooltip: false,
                },
                {
                    title: 'Status',
                    dataIndex: 'invoiceStatus',
                    key: 'status',
                    render: (status, invoice) => <StatusTag invoice={invoice} />,
                    // width: "120px",
                    sorter: true,
                    showSorterTooltip: false,
                },
            ])
        }

        if (this.props.ablUser === false && !this.props.hideEditColumn) {
            columns.push({
                title: '',
                key: 'action',
                align: 'center',
                width: "40px",
                onHover: true,
                render: (text, record) => {
                    const status = (record.invoiceStatus || '').toLowerCase()
                    const invoiceDoc = (record.attachments || []).find(item => item.type == "InvoiceDoc");

                    return v2BetaEnabled.manage ?
                        <>
                            <Dropdown
                                placement='bottomRight'
                                trigger={['click']}
                                key={`${record.docNumber}-Dropdown`}
                                overlay={<Menu style={{ padding: 24, borderRadius: 8, zIndex: 1001 }} key={`${record.docNumber}-Menu`} onClick={(event) => {
                                    console.log("stopPropagation", event)
                                    // stops the click event from bubbling up to the parent component 
                                    event.stopPropagation()
                                }}>
                                    <FlexColumn gap='24px'>
                                        {
                                            ['new', 'draft'].includes(status) &&
                                            <TextButton text='EDIT' rightIcon={<Image src={Edit} />} onClick={(event) => {
                                                event.stopPropagation(); // Stop event propagation here as well
                                                console.log('InvoiceTable ive/invoice/getInvoiceEmailBodyText', record)
                                                this.props.history.push({
                                                    pathname: '/receivables/invoices/create',
                                                    state: { invoice: record }
                                                })
                                            }} />
                                        }

                                        {
                                            ['sent', 'partiallypaid'].includes(status) &&
                                            <TextButton text='RESEND' rightIcon={<Image src={Send} />} onClick={(event) => {
                                                event.stopPropagation(); // Stop event propagation here as well
                                                this.props.history.push({
                                                    pathname: '/receivables/invoices/resend',
                                                    state: { invoice: record }
                                                })
                                            }} />
                                        }

                                        {
                                            (invoiceDoc?.uri && ['sent via accounting software'].includes(status)) &&
                                            <TextButton text='RESEND' rightIcon={<Image src={Send} />} onClick={(event) => {
                                                event.stopPropagation(); // Stop event propagation here as well
                                                this.props.history.push({
                                                    pathname: '/receivables/invoices/resend',
                                                    state: { invoice: record }
                                                })
                                            }} />
                                        }

                                        {
                                            ['sent', 'sent via accounting software'].includes(status) &&
                                            <TextButton text='REVISE' rightIcon={<Image src={Edit} />} onClick={(event) => {
                                                event.stopPropagation(); // Stop event propagation here as well
                                                this.props.history.push({
                                                    pathname: '/receivables/invoices/revise',
                                                    state: { invoice: record }
                                                })
                                            }} />
                                        }

                                        {
                                            ['sent', 'partiallypaid', 'sent via accounting software'].includes(status) &&
                                            <TextButton text='RECORD A PAYMENT' rightIcon={<Image src={RecordPayment} />} onClick={(event) => {
                                                event.stopPropagation(); // Stop event propagation here as well
                                                this.props.history.push({
                                                    pathname: '/receivables/invoice-payments',
                                                    state: { invoice: record, customer: { customerId: record.customerId, displayName: record.customerName }, balance: record.balance }
                                                })
                                            }} />
                                        }

                                        {
                                            ['sent', 'sent via accounting software'].includes(status) &&
                                            <TextButton text='VOID' rightIcon={<Image src={VoidButton} />} onClick={(event) => {
                                                event.stopPropagation(); // Stop event propagation here as well
                                                onVoid(record)
                                            }} />
                                        }

                                        {
                                            (record?.attachments || []).find(item => item.type == "InvoiceDoc") &&
                                            <TextButton text='DOWNLOAD PDF' rightIcon={<Image src={Download} />} onClick={(event) => {
                                                event.stopPropagation(); // Stop event propagation here as well
                                                var invoiceDoc = (record.attachments || []).find(item => item.type == "InvoiceDoc") || {}
                                                getPreSignedS3Url({ url: invoiceDoc.uri }, (err, preSignedUrl) => {
                                                    saveAs(preSignedUrl, `Invoice ${record.docNumber}`)
                                                })
                                            }} />
                                        }

                                        {
                                            ['new', 'draft'].includes(status) &&
                                            <TextButton text='DELETE' rightIcon={<Image src={Delete} />} onClick={(event) => {
                                                event.stopPropagation(); // Stop event propagation here as well
                                                this.setState({ showDeleteModal: true, invoiceToDelete: record })
                                            }} />
                                        }

                                        {
                                            !['new', 'draft'].includes(status) &&
                                            <TextButton text='VIEW HISTORY' rightIcon={<Image src={History} />} onClick={(event) => {
                                                event.stopPropagation(); // Stop event propagation here as well
                                                this.getInvoiceLogs(record)
                                            }} loading={invoiceLogLoading} />
                                        }
                                    </FlexColumn>
                                </Menu>}
                            // onVisibleChange={flag => this.setState({ moreDropdownVisible: flag })}
                            // visible={moreDropdownVisible}
                            >
                                <ImageButton
                                    src={More}
                                    onClick={(event) => {
                                        event.stopPropagation(); // Stop event propagation here as well
                                    }}
                                />
                            </Dropdown>
                        </>
                        :
                        <Space size="middle">
                            {this.canEdit(record) && <a onClick={() => this.props.showSelectedInvoice(record)}><EditOutlined style={{ fontSize: 18 }} /></a>}
                            {this.canEdit(record) &&
                                <Popconfirm
                                    title={<>
                                        <div>Are you sure you want to delete this invoice?</div>
                                        {qbo && record && record.source === 'QUICKBOOKS' && <div>This will also delete the invoice on QuickBooks Online</div>}
                                    </>}
                                    onConfirm={() => this.deleteInvoice(record, pagination)}
                                    okText="Confirm"
                                    placement="left"
                                    okButtonProps={{ loading: this.state.deleteLoading }}
                                    cancelText="Cancel"
                                >
                                    <a permtype="Receivables.Invoices"><DeleteOutlined style={{ fontSize: 18 }} /></a>
                                </Popconfirm>
                            }
                        </Space>
                },
            })
        }

        return (
            <>
                <TableView
                    id="invoice-table"
                    // className="row-pointer borderless-table"
                    tableLayout='auto'
                    columns={columns}
                    dataSource={invoices}
                    titleText="Invoices"
                    // scroll={{ y: '700px', x: '100%' }}
                    rowKey='invoiceId'
                    pagination={false}
                    style={{ minHeight: 500, minWidth: 922 }}
                    onChange={this.handleTableChange}
                    loading={loading}
                    ctaContent={this.props.ctaContent}
                    expandable={(!this.props.disableExpandable && !v2BetaEnabled.manage) && {
                        expandedRowRender: record => this.getRowDetails(record),
                        expandRowByClick: true,
                        expandIconColumnIndex: -1, //columns.length: to show icon at the last column -1: to hide column
                        columnWidth: "2px",
                        indentSize: "20px",
                        expandIcon: ({ expanded, onExpand, record }) =>
                            !expanded ? (
                                <DownOutlined onClick={e => onExpand(record, e)} />
                            ) : (
                                <UpOutlined onClick={e => onExpand(record, e)} />
                            )
                    }}
                    rowSelection={showRowSelection && {
                        type: "checkbox",
                        onChange: this.props.handleRowSelection,
                        selectedRowKeys: this.props.selectedRowKeys,
                        getCheckboxProps: (invoice) => ({
                            disabled: ['NEW', 'DRAFT'].includes(invoice.invoiceStatus) || invoice.balance === 0,
                        })
                    }}
                    onRow={(record) => (v2BetaEnabled.manage && {
                        onClick: () => {
                            this.selectRow(record);
                        },
                    })}
                />

                {
                    moreToLoad &&
                    <Flex fullWidth centerVerticall style={{ marginTop: "10px" }}><TextButton text='LOAD 10 MORE' loading={loading} onClick={onLoadMore} rightIcon={<img style={{ cursor: 'pointer' }} width='24px' height='24px' src={ChevronDown} />} /></Flex>
                }


                <AlertModal
                    del
                    visible={showDeleteModal}
                    loading={deleteLoading}
                    title={`Are you sure you want to delete invoice ${invoiceToDelete?.docNumber}?`}
                    description={qbo && invoiceToDelete?.source === 'QUICKBOOKS' && 'This will also delete the invoice on QuickBooks Online'}
                    buttonTitle='DELETE'
                    cancelButtonText='CANCEL'
                    onConfirm={() => this.deleteInvoice(invoiceToDelete, pagination)}
                    onCancel={() => this.setState({ showDeleteModal: false })}
                />

                <HistoryDrawer
                    invoice={invoiceToView}
                    onClose={() => this.setState({ invoiceLogVisible: false, invoiceToView: null, invoiceLog: null })}
                    visible={invoiceLogVisible}
                    invoiceLog={invoiceLog} />

                <Drawer
                    visible={showAddPayment}
                    placement="right"
                    closable={true}
                    onClose={() => this.setState({ showAddPayment: false })}
                    maskClosable={false}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                    width='444px'
                    destroyOnClose={true}
                >
                    <PaymentDrawer
                        invoiceId={invoiceToPay?.invoiceId}
                        docNumber={invoiceToPay?.docNumber}
                        balance={invoiceToPay?.balance}
                        onSave={() => {
                            this.setState({ showAddPayment: false })
                            this.props.fetchInvoices({ pagination: pagination });
                        }}
                    />
                </Drawer>
            </>
        )
    }

}

function mapStateToProps(state) {
    return {
        store: state.creditAppReducer,
        aionStore: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(InvoiceTable)));