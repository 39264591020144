import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { Flex, FlexColumn } from './Container';
import { Affix, Spin } from 'antd';
import { Summary } from './Summary'
import ActionSpinner from '../../Images/action-spinner.png';
import { LoadingOutlined } from '@ant-design/icons';

const ActionMenu = (props) => {
    const { theme, summary, mainContent, noPadding, steps, step, loading, noBackground } = props

    var stepsContent = []

    if (steps && steps > 0) {
        // var style = { marginLeft: 16 }

        for (var i = 0; i < steps; i++) {
            if (step === i) {
                stepsContent.push(loading ? <div style={{}} class="spinner" /> : <img className='step-dot-current ' src={ActionSpinner} width='16px' height='16px' />)
            } else if(step > i) {
                stepsContent.push(<div class="step-dot-previous" />)
            } else {
                stepsContent.push(<div class="step-dot" />)
            }
        }
    }

    return (
        <Affix offsetTop={0}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: 306, background: !noBackground && 'white', padding: noPadding ? "0 0 0 0" : "80px 48px 0px 48px", height: '100vh', boxShadow: !noBackground && '0px 4px 32px rgba(111, 111, 111, 0.08)' }}>
                <FlexColumn style={{ width: '100%' }}>
                    <FlexColumn right style={{ margin: noPadding && "80px 48px 0px", height: 40 }}>
                        {props.topContent && props.topContent}
                    </FlexColumn>
                    <div style={{ marginTop: 55 }}>
                        {
                            summary &&
                            <Summary theme={theme} title={summary.title} data={summary.data} />
                        }
                        {mainContent && mainContent}
                    </div>
                </FlexColumn>
                <FlexColumn style={{ width: '100%', paddingBottom: 48 }} centerHorizontally>
                    {props.content}
                    {
                        steps && steps > 0 &&
                        <FlexColumn center style={{ paddingTop: 40 }}>
                            <Flex between center style={{ background: 'transparent' }}>
                                {/* <div class="spinner" />
                                <img src={ActionSpinner} width='16px' height='16px' />
                                <img src={ActionSpinner} width='16px' height='16px' /> */}
                                {stepsContent}
                            </Flex>
                        </FlexColumn>
                    }
                </FlexColumn>
            </div>
        </Affix>
    )
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(ActionMenu)));