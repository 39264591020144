import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import styled, { withTheme } from 'styled-components'
import Lottie from 'react-lottie'
import { Alert, Layout, message, Modal } from 'antd'

// Util
import { apiGET, apiPOST, apiPOSTNoAuth, apiPOSTReq, clearLocalStorage, doSignout, getJwt } from '../../Utils/api'
import { getUserPermissions } from '../../Utils/userAccess'
import { camelCaseToPascalCase, defaultLottieOptions, getUserApps, getResourcePerm } from '../../Utils/util'
import Bowser from "bowser"

// Actions
import { completeSignin, addDataToStore, SAVE_DATA, UAM_SAVE_PERMISSIONS, CODAT_DATA, USERDEFAULTS_SAVE_FLAG, ONB_SAVE_DATA, UAM_SAVE_BUSINESS, UAM_SAVE_USERINFO } from '../../Actions/actions';

// Components
import { Button, TextButton } from '../Reusable/Button'
import { Text } from '../Reusable/Text'
import { Flex, FlexColumn, SideBar, GradientSideBar } from '../Reusable/Container'
import { Divider, LabeledInput } from '../Reusable/Input'
import { StyledExtLink, StyledLink } from '../Reusable/Link'
import TwoFactor from '../TwoFactor/Index'
import NewPassword from './NewPassword'
import ReferralCode from './ReferralCode'
import AionLogo from '../Reusable/Image'
import { ErrorAlert } from '../Reusable/Alert'
import environment from '../../environment'
import ModalClose from '../../Images/modal-close.png'
import _ from 'underscore'
import MobileLanding from '../Reusable/MobileLanding'

class SignIn extends Component {
    refArr = []
    constructor(props) {
        super(props)

        this.state = {
            showRequired: { username: false, password: false },
            formData: {},
            loading: false,
            isMobile: window.matchMedia("only screen and (max-width: 760px)").matches,
            isTablet: window.matchMedia("only screen and (max-width: 1100px)").matches,
            step: 0,
            delivery: "text", //text or email
            codeInd: 0,
            password: environment.showAutofill ? "Aion@123" : null,
            fetchPermissionsLoading: false
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        _.times(6, (n) => {
            var r = React.createRef()
            this.refArr.push(r)
        })
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize)
        // this.getAttributes()
        this.checkBrowser()
        this.checkForNewUserReference()
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize)
    }

    handleResize = () => {
        if (typeof (window)) {
            this.setState({
                isMobile: window.matchMedia("only screen and (max-width: 760px)").matches,
                isTablet: window.matchMedia("only screen and (max-width: 1100px)").matches,
            })
        }
    }

    checkForNewUserReference = () => {
        const refCode = new URLSearchParams(this.props.location.search).get('newUserReference')
        console.log("checkForNewUserReference", refCode)
        if (refCode) {
            this.setState({ loading: true })
            apiPOSTNoAuth(`${environment.uamBaseUrl}/getAuthFromLinkReference`, {}, { passwordLinkReference: refCode }, (err, resp) => {
                try {
                    if (err) throw Error(err)
                    const data = (resp || {}).data || {}
                    if (data.result) {
                        const firstTimePasswordLinkInfo = data.firstTimePasswordLinkInfo || {}
                        console.log("firstTimePasswordLinkInfo", firstTimePasswordLinkInfo)
                        this.setState({ username: firstTimePasswordLinkInfo.userName, password: firstTimePasswordLinkInfo.generatedPassword })
                        this.cognitoSignIn()
                    } else {
                        const responseMessage = data.responseMessage || ""
                        if (responseMessage.includes("expired") || responseMessage.includes("invalid")) {
                            const firstTimePasswordLinkInfo = data.firstTimePasswordLinkInfo || {}
                            this.resendNewUserLink(firstTimePasswordLinkInfo.userName)
                        }
                        throw Error(data.responseMessage || data.error)
                    }
                } catch (error) {
                    console.log("/checkForNewUserReference error", error, resp)
                    ErrorAlert({ description: error.message })
                }
            })
        }
    }

    resendNewUserLink = (username) => {
        apiPOSTNoAuth(`${environment.uamBaseUrl}/refreshLinkReference`, {}, { userId: username }, (err, resp) => {
            try {
                if (err) throw Error(err)
                const data = (resp || {}).data || {}
                if (data.result) {
                    console.log("New link sent!")
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/checkForNewUserReference error", error, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    checkBrowser = () => {
        const browser = Bowser.getParser(window.navigator.userAgent)
        const browserName = browser.getBrowserName()
        if (!(browserName.toLowerCase().includes("chrome") || browserName.toLowerCase().includes("safari") || browserName.toLowerCase().includes("edge"))) {
            this.setState({ unsupported: true })
        }
    }

    getAppConfig() {
        // Check session and fetch attributes
        apiGET("/appConfig", null, (err, resp) => {
            try {
                const data = resp.data || {}
                console.log("appConfig", data)
                if (data.success) {
                    this.props.dispatch(completeSignin(data))
                } else {
                    throw Error("Could not fetch appConfig.")
                }
            } catch (error) {
                console.log("/appConfig ERR", error, err, resp)
            }
        })
    }

    fetchUserData = (options, callback) => {
        options = options || {}
        var { cognitoUserInfo } = this.props.store
        cognitoUserInfo = cognitoUserInfo || {}
        var username = cognitoUserInfo.username
        this.setState({ loading: true })
        getJwt((err, jwt) => {
            this.props.dispatch(addDataToStore(UAM_SAVE_PERMISSIONS, { jwt: jwt }))
        })
        apiPOSTReq(`${environment.uamBaseUrl}/getUserInfo`, {}, { userId: username }, (err, resp) => {
            try {
                if (err) throw Error(err)
                const data = resp || {}
                if (data.result) {
                    var registeredUser = data.registeredUser || {}
                    var userInfo = registeredUser.userInfo || {}
                    this.props.dispatch(addDataToStore(UAM_SAVE_USERINFO, registeredUser))

                    

                    // We use camelCase in some places and PascalCase in other places
                    this.props.dispatch(addDataToStore(SAVE_DATA, { UserInfo: camelCaseToPascalCase(Object.assign({}, userInfo)), userInfo: userInfo }))
                    var currentBusiness = registeredUser.currentBusiness
                    // Update browser info
                    this.saveBrowserInfo()
                    this.getAppConfig() // background call

                    const { store } = this.props
                    const { TwoFAValidated } = store
                    if(currentBusiness) {
                        this.fetchBusinessData(null, (err, resp) => {})
                        if (!TwoFAValidated) {
                            this.begin2FA()
                            return
                        } else {

                            // this.props.validationComplete(registeredUser)

                            this.twoFAValidated()
                        }
                    } else {
                        throw Error("Unable to log you in")
                    }

                    callback(null, true)
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                this.setState({ loading: false })
                callback(error.message)
                ErrorAlert({ description: error.message })
            }
        })
    }

    fetchBusinessData = (options, callback) => {
        options = options || {}
        var { store, businessSubscriptionPlan } = this.props
        const cognitoUserInfo = store.cognitoUserInfo || {}
        var cognitoAttr = cognitoUserInfo.attributes || {}
        var { currentPlan } = businessSubscriptionPlan || {}
        console.log("fetchBusinessData", cognitoUserInfo, cognitoAttr)
        apiPOSTReq(`${environment.uamBaseUrl}/getBusiness`, {}, {}, (err, resp) => {
            try {
                if (err) throw Error(err)
                const data = resp || {}
                if (data.result) {
                    var business = data.business || {}
                    var businessBanking = business.businessBanking || {}

                    const bankingAttributes = camelCaseToPascalCase(businessBanking.attributes || {});

                    if (bankingAttributes.ApplicationSubmitted || bankingAttributes.ForceCRBAccountOpening || currentPlan) {
                        var onboarding = bankingAttributes.ForceCRBAccountOpening || false
                        if(onboarding) this.saveOnboardingData(cognitoUserInfo, business, false)
                        this.fetchPermissions()
                    } else {
                        throw Error("Unable to log you in")
                    }
                    
                    callback(null, true)
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/getBusiness err", error, resp)
                callback(error.message)
            }
        });
    }

    fetchPermissions = () => {
        // Fetch user permissions and adapt the layout based on the user's access
        this.setState({ fetchPermissionsLoading: true })
        getUserPermissions(null, (err, resp) => {
            try {
                console.log("fetchPermissions resp.data", resp.data)
                // this.setState({ loading: true })
                if (err) throw Error(err)
                if (!resp.data.authToken) throw Error(resp.data.responseMessage)
                this.props.dispatch(addDataToStore(UAM_SAVE_PERMISSIONS, { UAM: resp.data }))
                this.setState({ fetchPermissionsLoading: false })
                getJwt((err, jwt) => {
                    this.props.dispatch(addDataToStore(UAM_SAVE_PERMISSIONS, { jwt: jwt }))
                })
            } catch (error) {
                ErrorAlert({ description: error.message })
                // this.props.history.push('/home')
                // this.setState({ loading: false })
            }
        })
    }

    handleChange = (event) => {
        this.setState({ [event.target.id]: event.target.value })
        event.preventDefault()
    }

    handle2FAChange = (event) => {
        event.preventDefault()
        const nextInputId = parseInt(event.target.id) + 1
        var newState = Object.assign({ ...this.state }, { ["code_"+event.target.id]: event.target.value, codeInd: nextInputId }) 
        this.setState(newState)
        if(this.refArr[nextInputId] && (event.target.value != "")) this.refArr[nextInputId].current.focus()
        if(nextInputId == this.refArr.length) this.verifyCode(newState)
    }

    handle2FABackspace = (event) => {
        event.preventDefault()
        const prevInputId = parseInt(event.target.id) - 1
        var newState = Object.assign({ ...this.state }, { ["code_"+prevInputId]: event.target.value })
        this.setState(newState)
        if(this.refArr[prevInputId] && (event.target.value == "")) {
            this.refArr[prevInputId].current.focus()
            return
        }        
        
    }

    handlePaste = (event) => {
        console.log("handlePaste", event.clipboardData.getData('Text'));
        const pastedTxt = event.clipboardData.getData('Text')
        // Reset code
        var updatedCode = {}
        _.times(6, (n) => {
            updatedCode["code_"+n] = pastedTxt[n] || ""
        })
        this.setState(updatedCode)
        if(updatedCode["code_5"]) this.verifyCode(Object.assign({ ...this.state }, updatedCode) )
    }

    setError = (field, msg) => this.setState({ errorField: field, errorMessage: msg })

    handleSubmit = async () => {
        const { username, password } = this.state;
        if (!username) {
            this.setError("username", "Please enter a valid username.");
            return;
        }
        if (!password) {
            this.setError("password", "Please enter a valid password.");
            return;
        }
        this.setState({ loading: true })
        this.cognitoSignIn()   
    }

    cognitoSignIn = async () => {
        const username = this.state.username
        const password = this.state.password
        try {
            const user = await Auth.signIn(username, password)
            // console.log('Cognito user', user)
            this.props.dispatch(addDataToStore(SAVE_DATA, { cognitoUserInfo: { username: user.username, attributes: user.attributes } }))
            this.setState({ cognitoUserInfo: { username: user.username, attributes: user.attributes } })
            if (user.challengeName === "NEW_PASSWORD_REQUIRED") this.setState({ showNewPasswordModal: true, cognitoUser: { username: user.username, attributes: user.attributes } })
            else this.fetchUserData(null, (err, resp) => {})
        } catch (error) {
            console.log('error signing in', error)
            if(error.code === "UserNotConfirmedException") {
                this.props.history.push({
                    pathname: '/signup/confirm',
                    state: { fromSignIn: true, username, password }
                })
            } else {
                alert(error.message)
            }
            this.setState({ loading: false })
        }
    }

    saveBrowserInfo = () => {
        const browser = Bowser.parse(window.navigator.userAgent)
        var browserInfo = camelCaseToPascalCase(browser)
        browserInfo["AppVersion"] = environment.appVersion
        browserInfo["OS"] = browserInfo["Os"] || {}
        if (browserInfo["Os"]) delete browserInfo["Os"]
        apiPOST("/browserinfo", {}, browserInfo, (err, resp) => {
            console.log("browserinfo saved", err, resp)
        })
    }

    begin2FA = () => {
        this.sendCode()
    }

    sendCode = (options) => {
        options = options || {}
        var { delivery } = this.state
        var { resend } = options
        if(options.delivery) delivery = options.delivery
        if (delivery === "email") delivery = "mail"

        if(resend) {
            // Reset code
            var updatedCode = {}
            _.times(6, (n) => {
                updatedCode["code_"+n] = null
            })
            this.setState(updatedCode)
        }
        apiGET(`/mfa/generate?delivery=${delivery.toUpperCase()}`, null, (err, resp) => {
            try {
                if (err) throw Error(err)
                var data = resp.data
                console.log('twoFactorData', data)
                if (!data.success) throw Error("")
                if (resend) message.success("Successfully sent")
                this.setState({ loading: false, twoFactorData: data, step: 1, delivery: delivery })
            } catch (error) {
                console.log("2FA.SendCode", error, err, resp)
                ErrorAlert({ description: "Sorry we had trouble processing your request. Please try again." })
                this.setState({ loading: false })
            }
        })
    }

    verifyCode = (state) => {
        state = state || {}
        var code = ""
        _.times(6, (n) => {
            code = code + state["code_"+n]
        })
        if(this.state.loading) return // To avoid multiple clicks
        if (!code) {
            // this.setError("code", "Please enter a valid code.");
            ErrorAlert({ description: "Please enter a valid code." })
            return;
        }
        var endpoint = "/mfa/verify"
        if (this.props.type) endpoint = `${endpoint}?type=${this.props.type}`
        let body = {
            "token": code
        }
        this.setState({ loading: true })
        console.log("verifyCode", endpoint, body)
        apiPOST(endpoint, null, body, (err, resp) => {
            try {
                if (err) throw Error(err)
                var data = resp.data
                if (!data.success) throw Error("")
                if (data.TokenValidated) {
                    this.twoFAValidated()
                } else {
                    this.setState({ loading: false })
                    ErrorAlert({ description: "Invalid verification code. Please enter the correct code or request a new one." })
                }
            } catch (error) {
                this.setState({ loading: false })
                ErrorAlert({ description: "Sorry we had trouble processing your request. Please try again." })
            }
        })
    }

    twoFAValidated = () => {
        this.props.dispatch(addDataToStore(SAVE_DATA, { "TwoFAValidated": true }))
        this.props.validationComplete()
    }

    next = () =>  this.setState({step: this.state.step + 1})
    back = () => this.setState({step: this.state.step - 1})

    getInputContent = () => {
        const { theme } = this.props;
        const { isTablet, twoFactorData, step, errorField, errorMessage, codeInd, delivery } = this.state
        return (
            <FlexColumn fullWidth>
                {
                    (step == 0) && 
                    <FlexColumn gap="24px">
                        <Text size="28px" weight="500" height="">Sign In</Text>
                        <FlexColumn left>
                            <LabeledInput
                                autoFocus={!isTablet}
                                label="Email"
                                id="username"
                                key="username"
                                type="email"
                                placeholder="Enter your email registered with Aion"
                                value={this.state.username}
                                onChange={this.handleChange}
                                error={errorField == "username"}
                                errorMessage={errorMessage}
                                noAsterisk
                                autocomplete
                            />
                            <LabeledInput
                                label="Password"
                                id="password"
                                type="password"
                                key="password"
                                placeholder="Enter your password"
                                value={this.state.password}
                                onChange={this.handleChange}
                                error={errorField == "password"}
                                errorMessage={errorMessage}
                                onKeyDown={(event) => { if (event.key === "Enter") this.handleSubmit() }}
                                noAsterisk
                            />
                            <a target="_blank" href="/forgot-password">Forgot Password?</a>
                        </FlexColumn>
                    </FlexColumn>
                }
                {
                    (step == 1) &&
                    <FlexColumn gap="24px">
                        <Text size="28px" weight="500" height="">Sign In</Text>
                        <Text >{(twoFactorData || {}).Message || (twoFactorData || {}).msg}</Text>
                        <FlexColumn left gap="24px">
                            <Flex gap="8px">
                                {
                                    _.times(6, (n) => {
                                        return (
                                            <LabeledInput
                                                autoFocus={codeInd == n}
                                                inputRef={this.refArr[n]}
                                                id={n}
                                                key={n}
                                                type="code"
                                                // onFocus={e => e.target.select()}
                                                placeholder="_"
                                                onChange={this.handle2FAChange}
                                                onPaste={this.handlePaste}
                                                maxLength={1}
                                                error={errorField == `code_${n}`}
                                                errorMessage={errorMessage}
                                                value={this.state[`code_${n}`]}
                                                onKeyDown={(event) => { if(event.key == "Backspace" && event.target.value == "") this.handle2FABackspace(event) }}
                                                style={{ fontSize: "20px", weight: 400, lineHeight: "32px", textAlign: "center" }}
                                                width="60px"
                                                height="48px"
                                            />
                                        )
                                    })
                                }
                            </Flex>
                            <StyledExtLink key="StyledExtLink" style={{ textAlign: "center", fontSize: "16px", lineHeight: "24px", textDecoration: "underline" }} onClick={() => this.sendCode({ resend: true })}>Resend code</StyledExtLink>
                            <StyledExtLink 
                                key="StyledExtLink" style={{ textAlign: "center", fontSize: "16px", lineHeight: "24px", textDecoration: "underline" }} 
                                onClick={() => {
                                    this.sendCode({ resend: true, delivery: (delivery == "text") ? "email" : "text" })
                                }}
                            >
                                Send my code via {(delivery == "text") ? "email" : "text"}
                            </StyledExtLink>
                        </FlexColumn>
                    </FlexColumn>
                }
            </FlexColumn>
        )
    }

    getCTA = () => {
        const { step, loading } = this.state
        return (
            <FlexColumn>
                {
                    (step == 0) &&
                    <FlexColumn left gap="24px">
                        <Button solid permtype="Override" loading={loading} onClick={() => this.handleSubmit()} text={'Sign In'.toUpperCase()} />
                        {/* <Text>Don't have an Aion account yet? <StyledExtLink href="https://aionfi.com/pricing" style={{ textDecoration: "underline" }}>Get Started</StyledExtLink></Text> */}
                    </FlexColumn>
                }
                {
                    (step == 1) &&
                    <FlexColumn>
                        <Flex center style={{ gap: '24px' }}>
                            <Button permtype="Override" solid loading={loading} onClick={() => this.verifyCode()} text={'Confirm'.toUpperCase()} />
                            <TextButton permtype="Override" onClick={() => this.back()} text={'Cancel'.toUpperCase()} />
                        </Flex>
                    </FlexColumn>
                }
            </FlexColumn>
        )
    }

    render() {
        const { theme } = this.props
        const { isMobile, isTablet, twoFactorData } = this.state

        return (
            <>
                <Flex>
                    {
                        isMobile || isTablet ?
                            <Flex gap='132px'>
                                <FlexColumn start left gap='48px' style={{ width: "350px", alignSelf: "stretch" }}>
                                    <FlexColumn left style={{ gap: '24px', width: 'inherit' }}>
                                        <AionLogo symbol margin='0px' />
                                        {this.getInputContent()}
                                    </FlexColumn>
                                    {this.getCTA()}
                                </FlexColumn>
                            </Flex>
                            :
                            <Flex gap='132px'>
                                <FlexColumn centerVertically left gap='48px' style={{ width: 400, alignSelf: "stretch" }}>
                                    <FlexColumn left style={{ gap: '24px', width: 'inherit' }}>
                                        <AionLogo symbol margin='0px' />
                                        {this.getInputContent()}
                                    </FlexColumn>
                                    {this.getCTA()}
                                </FlexColumn>
                            </Flex>
                    }
                </Flex>
                <Modal
                    visible={this.state.show2FAModal}
                    footer={null}
                    closable={true}
                    width={500}
                    // style={{ top: 20 }}
                    destroyOnClose={true}
                    onCancel={() => { this.setState({ show2FAModal: false }) }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <TwoFactor validationComplete={this.twoFAValidated} />
                </Modal>
                <Modal
                    visible={this.state.showNewPasswordModal}
                    footer={null}
                    closable={true}
                    width={500}
                    style={{ top: 20 }}
                    destroyOnClose={true}
                    onCancel={() => { this.setState({ showNewPasswordModal: false }) }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <NewPassword
                        user={this.state.cognitoUser}
                        newPasswordComplete={
                            () => {
                                this.setState({ showNewPasswordModal: false })
                                this.begin2FA()
                            }
                        }
                    />
                </Modal>
                {/* Referral Code */}
                <Modal
                    visible={this.state.showReferralModal}
                    footer={null}
                    closable={true}
                    width={500}
                    style={{ top: 20 }}
                    destroyOnClose={true}
                    onCancel={() => { this.setState({ showReferralModal: false }) }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <ReferralCode
                        showSignup={
                            (code) => {
                                this.setState({ showReferralModal: false })
                                this.props.history.push(`/create-aion-account?referral=${code}`)
                            }
                        }
                    />
                </Modal>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(SignIn)))