import React, { useState } from 'react'
import { message } from 'antd'
import moment from 'moment'

import { Text } from '../../Reusable/Text'
import { Flex, FlexColumn } from '../../Reusable/Container'
import { Button } from '../../Reusable/Button'
import { LabeledInput } from '../../Reusable/Input'
import environment from '../../../environment'
import { apiPOSTReq } from '../../../Utils/api'
import { getRoleOptions } from '../../../Utils/util'
import { ErrorAlert } from '../../Reusable/Alert'
import { uaApiPOST } from '../../../Utils/userAccess'

const UpdateUser = (props) => {
    const [last4Error, setLast4Error] = useState(false)
    const [expDateError, setExpDateError] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [selectedRoles, setSelectedRoles] = useState(props.user.roleTags)
    const [firstName, setFirstName] = useState()
    const [lastName, setLastName] = useState()
    const [email, setEmail] = useState()
    const [phone, setPhone] = useState()

    const { user, allRoles, onUpdateRoles } = props

    const handleSelectRole = (role) => {
        setSelectedRoles(role)
    }

    const updateUser = () => {
        const { user } = props

        if(!selectedRoles || selectedRoles.length === 0) {
            ErrorAlert({ description: 'You must select at least one role' })
            return
        }

        var body = {
            RoleNames: selectedRoles,
            UserId: user.Email,
        }
        setSubmitLoading(true)
        console.log("UAM.Submit", JSON.stringify(body))
        uaApiPOST("/user/admin/updateUserRoles", { body: body }, (err, resp) => {
            try {
                console.log("UAM.Submit", JSON.stringify(resp))
                setSubmitLoading(false)
                if (err) throw Error(err)
                if (!resp.result) throw Error(resp.responseMessage || resp.error)
                message.success('User roles updated')
                onUpdateRoles()
            } catch (error) {
                ErrorAlert({ description: error.message })
            }
        })
    }

    return (
        <FlexColumn left>
            <Text heading>Update User Roles</Text>
            <Text style={{ marginBottom: 8, marginTop: 8 }}>Select the role/s for this user</Text>
            <LabeledInput
                id='role'
                key='role'
                label='Roles'
                type='select'
                mode="multiple"
                placeholder='Select role/s'
                value={selectedRoles}
                maxLength={4}
                onChange={handleSelectRole}
            >
                {getRoleOptions(allRoles, [])}
            </LabeledInput>

            <Button
                solid
                margin='24px 0 0'
                radius='4px'
                text='SAVE CHANGES'
                onClick={() => updateUser()}
                loading={submitLoading} />
        </FlexColumn>
    )
}

export default UpdateUser