import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import { Select, Modal, Radio } from 'antd'
import moment from 'moment'
import { Document, Page, pdfjs } from 'react-pdf'
import { saveAs } from 'file-saver'

import { FlowViewContainer } from '../../Reusable/FlowViewContainer'
import FlowViewComponent from '../../Reusable/FlowViewComponent'
import { ErrorAlert } from '../../Reusable/Alert'
import { LabeledInput } from '../../Reusable/Input'
import { TextButton } from '../../Reusable/Button'

import { apiGET, apiGETDoc, apiGETDocUrl, apiPOSTReq } from '../../../Utils/api'
import { FlexColumn } from '../../Reusable/Container'
import { Paragraph } from '../../Reusable/Text'
import environment from '../../../environment'
import ModalClose from '../../../Images/modal-close.png'

const { Option } = Select

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const flowViewsData = [{
    "ViewType": "WithoutImage",
    "FlowStep": "Statement.Start",
    "Title": "Monthly account statements",
    "Description": "Make a selection to view and download the statement for any month.",
    "Progress": 1.0,
    "ButtonTitle": "Continue",
    "ContentItems": ["PDF"/*, "CSV"*/],
    "Step": "",
    "ValidationErrorMessage": "Statement month is required to continue."
}]

class Index extends Component {

    state = {
        docType: "PDF",
        currentViewIndex: 0,
        flowViews: flowViewsData,
        submitLoading: false,
        stepProgress: 0,
        numPages: 1,
        useAionStatements: true,
        hideStatementDownload: false
    }

    componentDidMount() {
        if (!this.state.useAionStatements) {
            this.fetchAvailableStatements()
        }
    }

    fetchAvailableStatements = () => {
        var { account, store } = this.props
        var { accountNumber } = account || {}
        const { BusinessUniqueKey } = store
        var body = {
            "BusinessId": BusinessUniqueKey,
            "AccountNum": accountNumber
        }
        console.log("getStatements body", body)
        this.setState({ loading: true })
        apiPOSTReq(`${environment.bpBaseUrl}/bp/customer/mgmt/getStatements`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            try {
                this.setState({ loading: false })
                console.log("getStatements resp", resp)
                const data = resp || {}
                if (data.result) {
                    var statementList = data.statementList || []
                    this.setState({
                        statementList: statementList || [],
                    })
                } else {
                    throw Error(data.error || data.responseMessage || "Could not fetch statements.")
                }
            } catch (error) {
                console.log("ERRR getStatements", error, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
        this.setState({ numPages: nextNumPages, hideStatementDownload: false })
    }

    onDocumentLoadFailure = () => {
        this.setState({hideStatementDownload: true});
    }

    render() {
        const { stmtFile, selectedStmt, numPages, docType, selectedStmtObj, useAionStatements } = this.state
        const title = "Account Statement"
        const fileType = docType.toLowerCase()
        const fileName = useAionStatements ? `${this.props.accountname} ${moment(selectedStmt).format('MMMM YYYY')}.${fileType}` : `${this.props.accountname} - ${selectedStmtObj.description}.${fileType}`
        return (
            <>
                <FlowViewContainer modal={this.props.modal} title={title} contentViews={this.getContentViews()} hidesidebar={true} />
                <Modal
                    visible={this.state.showAccountStmtModal}
                    footer={null}
                    onCancel={() => { this.setState({ showAccountStmtModal: false, hideStatementDownload: false }) }}
                    width={700}
                    style={{ top: 20 }}
                    destroyOnClose={true}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    { this.state.hideStatementDownload ? 
                            <Paragraph> Statement not available!</Paragraph>
                        :
                        <>
                            <TextButton onClick={() => { saveAs(stmtFile, fileName) }}>Download Statement</TextButton>
                            <Document
                            file={stmtFile}
                            onLoadSuccess={this.onDocumentLoadSuccess}
                            onLoadError={this.onDocumentLoadFailure}
                            >
                            {
                                Array.from(
                                    new Array(numPages),
                                    (el, index) => (
                                        <Page
                                            key={`page_${index + 1}`}
                                            pageNumber={index + 1}
                                        />
                                    ),
                                )
                            }
                            </Document>
                        </>
                    }
                    
                </Modal>
            </>
        )
    }

    // This will provide the view for each flowView
    getContentViews = () => {
        const { flowViews, currentViewIndex, docType, statementList, loading, useAionStatements } = this.state
        const { theme, bankingStore } = this.props
        const { AvailableStatements } = bankingStore
        const flowView = flowViews[currentViewIndex]
        var childViews = (<div></div>) // Initialize
        // Make any customizations here
        switch (flowView.FlowStep) {
            case "Statement.Start":
                var options = []
                const currentMonth = moment().format("MMMM YYYY")
                if (useAionStatements) {
                    (AvailableStatements || []).forEach(item => {
                        if (currentMonth === item.Name) return
                        options.push(
                            <Option key={item.Value} id="selectedStmt" name={item.Name} value={item.Value} style={{ backgroundColor: "transparent" }}>
                                {item.Name}
                            </Option>
                        )
                    })
                } else {
                    (statementList || []).forEach(item => {
                        options.push(
                            <Option key={item.statementId} id="selectedStmt" name={item.description} value={item.statementId} style={{ backgroundColor: "transparent" }}>
                                {item.description}
                            </Option>
                        )
                    })
                }

                var radioItems = []
                flowView.ContentItems.forEach(item => {
                    radioItems.push(<Radio.Button key={item} value={item}>{item}</Radio.Button>)
                })

                childViews = (
                    <>
                        <LabeledInput
                            label="Statement date"
                            autoFocus
                            showAction={'focus'}
                            type="select"
                            showSearch
                            style={{ width: "100%" }}
                            size="large"
                            key="selectedStmt"
                            className="no-left-padding"
                            placeholder={"Select"}
                            optionFilterProp="children"
                            onChange={this.handleSelect}
                        >
                            {options}
                        </LabeledInput>
                        <FlexColumn style={{ marginTop: 20 }}>
                            <Paragraph style={{ fontSize: "0.9rem", color: theme.colors.systemGray }}>Statement type</Paragraph>
                            <Radio.Group key="docType" name="docType" onChange={this.handleOnChange} defaultValue={docType} buttonStyle="solid" size="middle">
                                {radioItems}
                            </Radio.Group>
                        </FlexColumn>
                    </>
                )
                break
            default:
                break
        }

        return <FlowViewComponent
            flowView={flowView}
            currentViewIndex={currentViewIndex}
            loadPrev={this.loadPrevView}
            loadNext={this.loadNext}
            saveUserInput={this.saveUserInput}
            childViews={childViews}
            submitLoading={this.state.submitLoading}
        />
    }

    handleOnChange = (event) => {
        const target = event.target
        console.log("handleOnChange", target.id || target.name, target.value)
        this.saveUserInput(target.id || target.name, target.value)
    }

    handleSelect = (value, option) => {
        console.log("handleSelect", option.id || option.name, value)
        this.saveUserInput(option.id || option.name, value)
    }

    // Validate input and go to next view if it's good
    loadNext = () => {
        var stateToSave = this.state
        const { account, accountid, accountname, store } = this.props
        const { flowViews, currentViewIndex, statementList, useAionStatements } = this.state
        var { accountNumber } = account || {}
        var { BusinessUniqueKey, UAM } = store
        const flowView = flowViews[currentViewIndex]
        const validation = this.validateFlow()
        if (validation.validated) {
            // Save the data and load next
            switch (flowView.FlowStep) {
                case "Statement.Start":
                    const selectedStmt = flowView.UserInput
                    const docType = this.state.docType.toLowerCase()
                    stateToSave.submitLoading = true
                    if (useAionStatements) {
                        console.log("apiGETDoc", `/banking/radius/account/statement?accountId=${accountid}&date=${selectedStmt}&type=${docType}`)
                        apiGETDoc(`/banking/radius/account/statement?accountId=${accountid}&date=${selectedStmt}&type=${docType}`, null, (err, resp) => {
                            try {
                                if (err) throw Error("Please try again.")
                                const blob = new Blob([resp.data], {
                                    type: (docType === "csv") ? 'text/csvcharset=utf-8' : 'application/pdf',
                                })
                                const fileURL = URL.createObjectURL(blob)
                                const showAccountStmtModal = (docType !== "csv")
                                if (docType === "csv") saveAs(fileURL, `${accountname} ${moment(selectedStmt).format('MMMM YYYY')}.${docType}`)
                                this.setState({ showAccountStmtModal: showAccountStmtModal, selectedStmt, stmtFile: fileURL, selectedStmt: selectedStmt, submitLoading: false })
                            } catch (error) {
                                ErrorAlert({ description: error.message })
                            }
                        })
                    } else {
                        // Get statement document
                        var body = {
                            "BusinessId": BusinessUniqueKey,
                            "AccountNum": accountNumber,
                            "StatementId": selectedStmt
                        }
                        console.log("getStatementDocuments body", body)
                        this.setState({ loading: true })
                        apiPOSTReq(`${environment.bpBaseUrl}/bp/customer/mgmt/getStatementDocuments`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
                            try {
                                this.setState({ loading: false })
                                console.log("getStatementDocuments resp", resp)
                                const data = resp || {}
                                if (data.result) {
                                    var statementDocumentList = data.statementDocumentList || [{}]
                                    var statementDocument = statementDocumentList[0] || {}
                                    this.setState({
                                        statementDocument: statementDocument
                                    })
                                    downloadDoc(statementDocument)
                                } else {
                                    throw Error(data.error || data.responseMessage || "Could not fetch statements.")
                                }
                            } catch (error) {
                                console.log("ERRR getStatements", error, resp)
                                ErrorAlert({ description: error.message })
                            }
                        })
                    }

                    var t = this
                    function downloadDoc(statementDocument) {
                        apiGETDocUrl(`${environment.bpBaseUrl}/bp/customer/mgmt/exportStatement?AccountNumber=${accountNumber}&StatementId=${selectedStmt}&DocumentId=${statementDocument.statementDocumentId}`, { "BankProvider": environment.bankProvider.crb }, {}, (err, resp) => {
                            console.log("apiGETDoc err, resp", err, resp)
                            try {
                                if (err) throw Error("Please try again.")
                                const blob = new Blob([resp.data], {
                                    type: (docType === "csv") ? 'text/csvcharset=utf-8' : 'application/pdf',
                                })
                                const fileURL = URL.createObjectURL(blob)
                                const showAccountStmtModal = (docType !== "csv")
                                var selectedStmtObj = (statementList || []).find(item => item.statementId == selectedStmt) || {}
                                if (docType === "csv") saveAs(fileURL, `${accountname} ${selectedStmtObj.description}.${docType}`)
                                t.setState({ showAccountStmtModal: showAccountStmtModal, selectedStmt, selectedStmtObj: selectedStmtObj, stmtFile: fileURL, selectedStmt: selectedStmt, submitLoading: false })
                            } catch (error) {
                                ErrorAlert({ description: error.message })
                            }
                        })
                    }

                    return
                default:
                    break
            }
            this.loadNextView()
        } else {
            ErrorAlert({ description: validation.message })
        }
    }

    saveUserInput = (id, dataToSave) => {
        var { flowViews, currentViewIndex } = this.state
        var flowView = flowViews[currentViewIndex]
        switch (flowView.FlowStep) {
            case "Statement.Start":
                if (id !== "docType") flowView.UserInput = dataToSave
            default:
                break
        }
        this.setState({ flowViews: flowViews, [id]: dataToSave })
        // this.loadNext()
    }

    validateFlow = () => {
        var { flowViews, currentViewIndex } = this.state
        var flowView = flowViews[currentViewIndex]
        if (!flowView.UserInput) {
            // Check if default exists
            if (flowView.DefaultUserInput) flowView.UserInput = flowView.DefaultUserInput
        }
        var validation = {
            validated: false,
            message: flowView.ValidationErrorMessage ?? "Please provide an input to continue"
        }
        switch (flowView.FlowStep) {
            default:
                if (flowView.UserInput || flowView.DefaultUserInput) validation.validated = true
                break
        }
        this.setState({ flowViews: flowViews })
        return validation
    }

    loadPrevView = () => {
        let { flowViews, currentViewIndex } = this.state
        if (currentViewIndex !== 0) {
            this.setState({
                currentViewIndex: currentViewIndex - 1,
                flowViews: flowViews,
                flowView: flowViews[currentViewIndex - 1],
                showError: false, errorMsg: ""      // dismiss any validatin error, if any, for the current step
            })
        }
    }

    loadNextView = () => {
        var flowViews = this.state.flowViews
        var currentViewIndex = this.state.currentViewIndex + 1
        if (currentViewIndex < this.state.flowViews.length) {
            this.setState({
                currentViewIndex: currentViewIndex,
                flowView: flowViews[currentViewIndex]
            })
        }
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer,
        bankingStore: state.bankingAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)))