import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';

import { Skeleton, Divider, message, Card } from 'antd';
import { FlexColumn, Flex, Container } from '../../Reusable/Container';
import PageHeader from '../../Reusable/PageHeader';
import { ErrorAlert } from '../../Reusable/Alert';
import { apiPOSTReq } from '../../../Utils/api';
import environment from '../../../environment';

import { ReloadOutlined, WifiOutlined, LinkOutlined, DisconnectOutlined } from '@ant-design/icons';
import qbLogo from '../../../Images/intuit-qb.png'
import qbImage from '../../../Images/quickbooks-brand-preferred-logo.png'
import connectedIcon from '../../../Images/integration/icons8-online-24.png'
import OAuthClient from 'intuit-oauth';
import { addDataToStore, SAVE_DATA } from '../../../Actions/actions';
import { Button } from '../../Reusable/Refresh/Button';
import { Title } from '../../Reusable/Text';
import Key from '../API Keys/Key';

const ThirdPartyCard = (props) => {
    return <Card
        style={{ width: 350, marginLeft: props.noMargin ? '0' : '20px' }}
        bodyStyle={{ padding: '15px' }}
        title={
            <FlexColumn style={{ justifyContent: 'center' }}>
                <img alt={props.alt} src={props.logo} width='80%' style={{ marginLeft: "10%" }} />
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', fontSize: '18px' }}>QuickBooks Online </div>
                <div style={{ fontWeight: 400, fontSize: '12px', textAlign: 'center' }}>Automate your accounting by syncing your data with <br />QuickBooks Online </div>

                <Button permtype="Override" noShadow='none' style={{ marginTop: '20px' }} solid icon={props.actionIcon} onClick={props.action} text={props.actionText} />
            </FlexColumn>
        }
    >
        <Flex style={{ justifyContent: 'center', }}>
            <div>Status: </div>
            <div style={{ marginLeft: '10px', color: props.connected ? props.theme.colors.secondary1 : props.theme.colors.systemGray }}>{props.connectionIcon || <WifiOutlined />} {props.connectionStatus}</div>
        </Flex>
    </Card>
}

class Index extends Component {
    state = {
        setupSyncPlatform: '',
        connectedPlatform: null,
    };

    componentDidMount() {
        this.fetchConnectedSW();
    }

    fetchConnectedSW = () => {
        if (!this.props.aionStore.hasIntegration) {
            return;
        }
        this.setState({ loading: true });
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/getConnectedSW`, {}, {}, (err, resp) => {
            try {
                this.setState({ loading: false });
                const data = resp;
                console.log("/integration/getConnectedSW", data)
                if (data.result) {
                    if (data.integration) {
                        this.setState({
                            connectedPlatform: data.integration,
                        })
                    } else {
                        this.setState({ connectedPlatform: null })
                        this.props.dispatch(addDataToStore(SAVE_DATA, { CurrentSync: null }));
                    }
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR fetchConnectedSW", error.stack, resp);
                ErrorAlert({ description: error.message })
            }
        })
    }

    connectQuickBooks = () => {
        this.setState({ qbModalVisible: false })

        var oauthClient = new OAuthClient(environment.qboOAuth);
        const authUri = oauthClient.authorizeUri({
            scope: [
                OAuthClient.scopes.Accounting,
                OAuthClient.scopes.OpenId,
                OAuthClient.scopes.Profile,
                OAuthClient.scopes.Email,
                OAuthClient.scopes.Phone,
                OAuthClient.scopes.Address
            ],
            state: 'qb-integration'
        });

        window.location.href = authUri
    }

    syncQBO = () => {
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/qb/syncQuickBooks`, {}, {}, (err, resp) => {
            try {
                this.setState({ loading: false });
                const data = resp;
                console.log("/syncQuickBooks", data)
                if (data.result) {
                    message.success(data.responseMessage);
                    if (data.jobId) {
                        this.props.dispatch(addDataToStore(SAVE_DATA, { syncJobId: data.jobId }));
                    }
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR syncQuickBooks", error.stack, resp);
                ErrorAlert({ description: error.message })
            }
        })
    }

    render() {
        const { theme } = this.props;
        var { loading } = this.state;

        const connectedQBO = this.state.connectedPlatform ? this.state.connectedPlatform.platformName === 'QUICKBOOKS' : false;
        const qbSetupCompleted = connectedQBO && this.state.connectedPlatform.syncSetupCompleted;

        return (
            <>
                <FlexColumn className='main-padding'>
                    {this.props.aionStore.hasIntegration &&
                        <Skeleton loading={loading} active title={true} paragraph={{ rows: 5 }}>
                            <PageHeader
                                titleText={"Integrations"}
                                desc={""}
                            />
                            <Container shadow style={{ marginBottom: 48 }}>
                                <Title noMargin level={6}>Sync</Title>
                                <Divider style={{ margin: '15px 0' }} />
                                <Flex style={{ justifyContent: 'left', marginBottom: '30px' }}>
                                    <ThirdPartyCard
                                        theme={theme}
                                        alt="quickbooks"
                                        actionText={qbSetupCompleted ? "Sync now" : "Set up sync"}
                                        actionIcon={qbSetupCompleted ? <ReloadOutlined /> : <LinkOutlined />}
                                        action={qbSetupCompleted ? this.syncQBO : () => window.location.href = ('/settings/integration/qb-setup')}
                                        logo={qbImage}
                                        connected={connectedQBO}
                                        connectionIcon={connectedQBO ? <img alt="connected" src={connectedIcon} height={20} /> : <DisconnectOutlined />}
                                        connectionStatus={connectedQBO ? 'Connected' : 'Not Connected'}
                                        noMargin
                                    />
                                </Flex>
                                {!this.props.hideKey && <Key title="API Keys" />}
                            </Container>
                        </Skeleton>}
                </FlexColumn>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer,
        bankingStore: state.bankingAppReducer,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Index));