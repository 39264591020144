import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    Modal
} from 'antd';

// Components
// import AdvancesTable from './AdvancesTable';
import AdvancesTable from '../../../Credit/Advances/AdvancesTable'
import AdvanceDetail from '../../../Credit/Term Loans/AdvanceDetail'
import RejectLoan from './RejectLoan';
import { FlexColumn } from '../../../Reusable/Container';
import ModalClose from '../../../../Images/modal-close.png'
import { ErrorAlert } from '../../../Reusable/Alert'
// Util
import environment from '../../../../environment'
import { apiGET, getPreSignedS3Url, apiPOSTReq } from '../../../../Utils/api';
import { saveAs } from 'file-saver'
// Actions
import { CREDIT_SAVE_DATA, addDataToStore } from '../../../../Actions/actions';

class AdvancesIndex extends Component {

    constructor(props) {
        super(props);
        this.state = {
            advancesLoaded: false
        };
    }

    componentDidMount() {
        var { productType } = this.props;
        if (productType == "RLOC") {
            this.fetchAdvances();
            this.fetchARData();
        }
        else {
            this.fetchAdvancesGNPL();
        }
    }

    fetchAdvances() {
        const headers = {
            businesskey: this.props.businessId
        }

        apiGET("/financing/advances/view", headers, (err, resp) => {
            try {
                const data = resp.data
                console.log("/financing/advances/view", JSON.stringify(data))
                this.props.dispatch(addDataToStore(CREDIT_SAVE_DATA, data))
                this.setState({ advancesLoaded: true })
            } catch (error) {
                console.log("ERRR", error.stack)
            }
        })
    }

    fetchAdvancesGNPL() {
        const headers = {
            businesskey: this.props.businessId
        }
        this.setState({ loading: true })
        apiGET("/gnpl/funding/draws", headers, (err, resp) => {
            // this.setState({ loading: false })
            try {
                const data = resp.data
                console.log("gnpl/funding/draws", data)
                var ifFeesAreToBeDrawn = data.AdvanceInfo.ifFeesAreToBeDrawn;
                this.setState({ ...data, ifFeesAreToBeDrawn: ifFeesAreToBeDrawn })
                if (data.Advances.length == 0) {
                    this.setState({ isZeroAdvances: true })
                }

                this.fetchBusinessAttributes(this.props.aionStore.BusinessUniqueKey)
            } catch (error) {
                console.log("ERRR", error.stack)
                // ErrorAlert({ description: "Sorry we had trouble processing your request, please try again." })
            }
        })
    }

    // Fetch data for AR
    fetchARData = () => {
        const headers = {
            businesskey: this.props.businessId
        }
        apiGET("/financing/funds/view", headers, (err, resp) => {
            try {
                if (err) throw Error(err);
                const data = resp.data;
                console.log("/financing/funds/view", JSON.stringify(data))
                if (data.success) {
                    this.setState({ advancesLoaded: true, AvailableFunds: data });
                } else {
                    throw Error("We had some trouble. Please try again later.")
                }
            } catch (error) {
                alert(error.message);
            }
        })
    }

    showRejectLoan = (loan) => {
        this.setState({
            showRejectLoan: true,
            loan: loan
        })
    }
    closeRejection = () => {
        this.setState({
            showRejectLoan: false,
            showHoldLoan: false,
        });
    }


    showHoldLoan = (loan) => {
        this.setState({
            showHoldLoan: true,
            loan: loan
        })
    }

    showAdvance = (advance) => {
        console.log("showAdvance", advance)
        this.setState({ selectedAdvance: advance, showAdvanceModal: true })
    }

    fetchAndDownload = (item) => {
        var header = {
            AionCurrentBiz: this.props.businessId
        }

        var body = {
            "BusinessId": this.props.businessId,
            "noteId": item.NoteId
        }

        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/contract/downloadAdvancesContract`, header, body, (err, resp) => {
            try {
                const response = resp || {}
                if (response.result) {
                    const contractDocumentUrl = response.contractDocumentUrl
                    this.setState({ contractDocumentUrl: contractDocumentUrl }, () => this.downloadContract())

                }
                else {
                    ErrorAlert({ description: "Your loan agreement is currently unavailable. Please check back in a few minutes. If you are still unable to view the agreement, please reach out to your customer service contact to obtain this document." })
                }
            }
            catch (error) {
                console.log("ERRR downloading document", error, err, resp);
            }
        })

    }

    downloadContract() {
        return new Promise((resolve, reject) => {
            getPreSignedS3Url({ url: this.state.contractDocumentUrl }, (err, preSignedUrl) => {
                if (!err) {
                    saveAs(preSignedUrl, "AionContract");
                } else {
                    reject(JSON.stringify(err))
                }
            })
        })
    }

    render() {
        var { productType } = this.props;
        const Advances = this.state.Advances || [];
        return (
            <FlexColumn>
                {
                    productType == "RLOC" && <FlexColumn>
                        {/* <AdvancesTable advancesLoaded={this.state.advancesLoaded} showHoldLoan={this.showHoldLoan} showRejectLoan={this.showRejectLoan} data={this.props.store.ARAdvances} /> */}
                        <AdvancesTable
                            ifFeesAreToBeDrawn={this.state.ifFeesAreToBeDrawn}
                            advancesLoaded={this.state.advancesLoaded}
                            data={this.props.store.ARAdvances}
                        />
                    </FlexColumn>
                }
                {
                    productType != "RLOC" && <FlexColumn>
                        <AdvancesTable
                            type="short-term"
                            ifFeesAreToBeDrawn={this.state.ifFeesAreToBeDrawn}
                            advancesLoaded={this.state.loading}
                            data={this.state.Advances}
                            allowRowClick={true}
                            selectRow={(record) => this.showAdvance(record)}
                            downloadContract={(record) => this.fetchAndDownload(record)}
                        />
                    </FlexColumn>
                }
                <Modal
                    visible={this.state.showAdvanceModal}
                    footer={null}
                    closable={true}
                    width={800}
                    style={{ top: 20 }}
                    destroyOnClose={true}
                    onCancel={() => this.setState({ showAdvanceModal: false })}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <AdvanceDetail advance={this.state.selectedAdvance} businessId={this.props.businessId} downloadContract={(record) => this.fetchAndDownload(record)} />
                </Modal>
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.creditAppReducer,
        aionStore: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdvancesIndex));