import React, { useState } from 'react'
import { message } from 'antd'
import moment from 'moment'

import { Text } from '../../Reusable/Text'
import { Image } from '../../Reusable/Image'
import { FlexColumn } from '../../Reusable/Container'
import { Button } from '../../Reusable/Refresh/Button'
import { LabeledInput } from '../../Reusable/Refresh/Input'
import environment from '../../../environment'
import { apiPOSTReq } from '../../../Utils/api'
import { ErrorAlert } from '../../Reusable/Alert'
import Activate from '../../../Images/activate.png'
import { TextButton } from '../../Reusable/Button'

const ActivateCustomer = (props) => {
    const [loading, setLoading] = useState(false)

    const { customer, onActivated, onCancel } = props
    const { displayName } = customer

    const onCustomerMarkActive = (customer) => {
        setLoading(true)
        // const headers = {
        //     AionCurrentBiz: props.aionStore.BusinessUniqueKey,
        //     AionAuth: props.aionStore.UAM.encryptedAuthHeader // This will be replaced with the token received from the access mgmt service
        // }
        const body = {
            // "BusinessId": props.aionStore.BusinessUniqueKey,
            "CustomerId": customer.customerId
        }
        apiPOSTReq(`${environment.iveBaseUrl}/ive/bc/makeCustomerActive`, {}, body, (err, resp) => {
            try {
                const data = resp;
                if (data.result) {
                    message.success(`${customer.displayName} activated!`);
                    onActivated()
                } else {
                    setLoading(false)
                    ErrorAlert({ description: `Failed to activate this customer. ${data.responseMessage}` })
                }
            } catch (error) {
                setLoading(false)
                console.log("ERRR", error, err, resp)
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    return (
        <FlexColumn center >
            <Image margin='24px 0 12px' width='48px' height='48px' src={Activate} />
            <Text size='28px' height='40px' weight='500' style={{ marginBottom: 24, marginTop: 8 }}>Activate Customer?</Text>
            <Text center margin='0 12px 24px'>Activate {customer.displayName} to be able to create invoices and make edits to them.</Text>
            <Button
                solid
                text='YES, ACTIVATE'
                onClick={() => onCustomerMarkActive(customer)}
                loading={loading} />
            <TextButton onClick={onCancel} margin='24px 0 24px' text='CANCEL' />
        </FlexColumn>
    )
}

export default ActivateCustomer