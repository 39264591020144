import React from 'react';
import { Space, Avatar } from 'antd';

import { withTheme } from 'styled-components';
import { FlexColumn, Flex, InputContainer } from '../Reusable/Container';
import { Text, Tag } from '../Reusable/Text';

const ListItem = (props) => {
    const { theme, emoji, title, tag, tags, description, descriptionComponent, secondaryLabel, ctaButton, avatar } = props;
    return (
        <InputContainer style={{ margin: "20px" }}>
            <Flex centerVertically style={{ justifyContent: "start", alignItems: "center", width: "100%"  }}>
                {emoji ? <div style={{ margin: "5px 10px", backgroundColor: '#FFFFFF', padding: "8px 14px", borderRadius: "15px" }}>
                    <Text heading>{emoji}</Text>
                </div> : null}
                <FlexColumn style={{ alignItems: "start", width: "100%" }}>
                    {tag ? <Tag color="blue">{tag}</Tag> : null}
                    {tags ? <Space>{tags.map(t => <Tag key={t} tagpreset={theme.colors.primary}>{t}</Tag>)}</Space> : null}
                    <Flex center style={{ justifyContent: "space-between", width: "100%" }}>
                        <Flex center style={{ marginTop: "8px" }}>
                            {
                                avatar &&
                                <Avatar style={{ backgroundColor: "#2CD1D6", verticalAlign: 'middle', marginRight: 8 }} size="large" gap={1}>
                                    {(title && title != "") && title.charAt(0)}
                                </Avatar>
                            }
                            <Text level={4} bold noMargin>{title}</Text>
                        </Flex>
                        <Text weight='500' color={theme.colors.secondary6} noMargin style={{ marginLeft: "10px" }}>{secondaryLabel}</Text>
                    </Flex>
                    {descriptionComponent ? descriptionComponent : <Text color={theme.colors.systemGray} noMargin>{description}</Text>}
                    {ctaButton || null}
                </FlexColumn>
            </Flex>
        </InputContainer>
    )
}

export default withTheme(ListItem);