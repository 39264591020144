import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'
import { saveAs } from 'file-saver'
import moment from 'moment'
import { Select, Table, Upload, message, Tooltip } from 'antd'

import { Flex, FlexColumn } from '../../Reusable/Container'
import { LabeledInput } from '../../Reusable/Input'
import { Text, Tag } from '../../Reusable/Refresh/Text'

import { ErrorAlert } from '../../Reusable/Alert'
import { TextButton } from '../../Reusable/Button'
import { apiPOSTReq, getPreSignedS3Url } from '../../../Utils/api'
import environment from '../../../environment'
import Info from '../../../Images/info.png'
import Download from '../../../Images/download.png'
import { Image } from '../../Reusable/Image';
import MessageIcon from '../../../Images/message.png';

import { CloseOutlined, FilePdfOutlined, DeleteOutlined, ExclamationCircleFilled } from '@ant-design/icons';

const { Option } = Select
export const dateFormatList = ['MM/DD/YYYY', 'MM/DD/YY', 'MM-DD-YYYY', 'MM-DD-YY']
const etTimeZone = "America/New_York";

class AdditionalDocumentSubmitted extends Component {

    state = {
        uploadList: [],
        pagination: {
            current: 1,
            pageSize: 100
        },
    }

    componentDidMount() {
        this.fetchLoanApplicationRequest();
        this.fetchDocuments();
        this.fetchApprovedRequests()
    }

    getColumns = () => {
        return [
            {
                title: "Submitted Date",
                width: 150,
                render: (doc) => {
                    return (
                        <Text size="0.95rem" style={{marginBottom: "12px"}} >{moment(doc.auditData.lastUpdatedTime).format('MM/DD/YY')}
                        </Text>
                    )
                }
            },
            {
                title: 'File Name',
                width: 500,
                render: (doc) => {
                    return (
                        <Text size="0.95rem"  style={{marginBottom: "12px"}}>{doc.documentUrl.fileName}
                                    </Text>
                               
                    )
                }
            },
            {
                title: 'Notes',
                width: 100,
                render: (doc) => {
                    return (
                        <FlexColumn start style={{ width: '10%',marginBottom: "12px" }}>
                        {
                            doc.notes != null ?
                                <Tooltip overlayInnerStyle={{ width: 330, minWidth: 330 }} overlayStyle={{ width: 350, minWidth: 350 }} overlayClassName='roles-tooltip' placement="left" arrowPointAtCenter={false} title={<FlexColumn>
                                    <Text color='white' size='14px' wrap>{doc.notes}</Text>
                                </FlexColumn>}>
                                    <FlexColumn>
                                        <Image src={MessageIcon} />
                                    </FlexColumn>
                                </Tooltip> : null
                        }
                    </FlexColumn>
                    )
                }
            },
            {
                title: '',
                width: 100,
                render: (doc) => {
                    return (
                        <TextButton margin='0' loading={""} onClick={() => {
                            this.downloadDoc(doc);
                        }} rightIcon={<Image src={Download} />} />
 
                    )
                }
            }

        ]

    }

    downloadDoc = (item) => {
        var filename = item.documentUrl.fileName;
        getPreSignedS3Url({ url: item.documentUrl.uri }, async (err, preSignedUrl) => {
            try {
                // prevents opening file in new tab
                let blob = await fetch(preSignedUrl).then((r) => r.blob());
                saveAs(blob, filename);

            } catch (err) {
                console.log(err);
            }
        })


    }

    fetchApprovedRequests = () => {
        const { loanApplication } = this.props;
        const { pagination } = this.state || {};
        var body = {
            "size": pagination.pageSize,
            "page": pagination.current - 1,
            "sortDirection": "DESC"
        };
        if (loanApplication) {
            body["BusinessId"] = loanApplication.businessId;
            body["applicationId"] = loanApplication.applicationId;
            body["uploadCategory"] = "Custom Upload"
        }

        console.log("/lsm/getApprovedLoanRequest body", body);
        this.setState({ loading: true });
        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/getApprovedLoanRequest`, null, body, (err, resp) => {
            console.log("/lsm/getApprovedLoanRequest resp", resp);
            this.setState({ loading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    this.setState({ approvedRequests: data.requests });

                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/getApprovedLoanRequest err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    fetchDocuments = () => {
        const { loanApplication } = this.props;
        var body = {
            "sortDirection": "DESC"
        };
        if (loanApplication) {
            body["applicationId"] = loanApplication.applicationId;
            body["viewBy"] = "FILTER_APPLICATION_ID"
        }
        console.log("/docs/getActiveDocuments body", body, loanApplication);
        this.setState({ qbConnectLoading: true });
        apiPOSTReq(`${environment.uamBaseUrl}/docs/getActiveDocuments`, {}, body, (err, resp) => {
            console.log("/docs/getActiveDocuments resp", resp);
            this.setState({ qbConnectLoading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    this.setState({ businessDocuments: data.businessDocuments });
                    
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    fetchLoanApplicationRequest = () => {
        // Fetch document list
        const { loanApplication } = this.props;
        var body = {
            "businessId": loanApplication.businessId,
            "applicationId": loanApplication.applicationId,
            "uploadCategory" : "PreScreen"
            // "sortDirection": "ASC"
        };
        this.setState({ loanAppLoading: true });
        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/getActiveLoanRequest`, {}, body, (err, resp) => {
            console.log("/getApplications resp body", resp, body);
            try {
                const data = resp || {};
                if (data.result) {
                    var loanApplicationRequests = data.requests || [];
                    this.setState({ loanApplicationRequests: loanApplicationRequests })
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/getApplications err", error, resp);
                // ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            } finally {
                this.setState({ loanAppLoading: false });
            }
        })
    }

    render() {
        var { loanApplicationRequests, businessDocuments } = this.state;
        let additionalDocs = [];
        if ((businessDocuments || []).length > 0 && (this.state.approvedRequests || []).length > 0) {
           // additionalDocs = businessDocuments.filter(doc => doc.category === "Custom Upload")
            this.state.approvedRequests.map(request => {
                businessDocuments.map(doc => {
                        if(request.requestId === doc.requestId) {
                            additionalDocs.push(doc);
                        }
                    })
            })
        }
        var childViews = (
            <FlexColumn >
                
                    <Table
                        id="todo-table"
                        key="todo-table"
                        className='borderless-table'
                        tableLayout='auto'
                        columns={this.getColumns()}
                        dataSource={additionalDocs || []}
                        rowKey='objectId'
                        pagination={false}
                        scroll={{ x: '75%' }}
                    />
            </FlexColumn>
        )
        return (
            <>
                {childViews}

            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        onboardingStore: state.onboardingAppReducer,
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(AdditionalDocumentSubmitted))