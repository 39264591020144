import React, { Component } from 'react'
import Dimensions from 'react-dimensions'
import AccountCard from './AccountCard'
import { Flex } from '../../Reusable/Container'

class AccountCardList extends Component {

    constructor(props) {
        super(props)

        const refs = props.accounts.map(() => React.createRef())

        console.log('Ref:', refs)

        this.state = {
            refs
        }
    }

    componentDidMount() {
        const { selectedAccount, accounts } = this.props
        this.scrollToAccount(accounts.length > selectedAccount + 1 ? selectedAccount + 1 : selectedAccount)
    }

    scrollToAccount = (index) => {
        const ref = this.state.refs[index];
        if (ref && ref.current) {
            ref.current.scrollIntoView({
                behavior: 'auto', 
                block: 'nearest'
            });
        }
    };

    render() {
        const { accounts, selectedAccount, onSelectAccount } = this.props
        const { refs } = this.state

        return (
            <Flex start gap='24px' className='no-scrollbar' centerHorizontally style={{ width: this.props.containerWidth, overflow: 'scroll', padding: '0px 0 16px', paddingInlineStart: '36px', paddingInlineEnd: '24px' }}>
                {accounts.map((account, i) => <div ref={refs[i]}>
                    <AccountCard key={`account-${i}`} selected={i === selectedAccount} onClick={() => onSelectAccount(i)} account={account} accounts={accounts} />
                </div>)}
            </Flex>
        )
    }
}

export default Dimensions()(AccountCardList)