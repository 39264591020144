import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import moment from 'moment';

import { Skeleton, Divider, Table, Collapse, Switch, message, Tooltip, Popconfirm, Tabs } from 'antd';
import { LeftOutlined, WarningFilled, CheckCircleFilled, InfoCircleOutlined } from '@ant-design/icons';

import { FlexColumn, Flex, CardContainer, LightContainer } from './Container';
import { ErrorAlert } from './Alert';
import { Image } from './Image';
import { Text, Tag } from './Text';
import { Button, TextButton } from './Button';
import AlertModal from './AlertModal'

import environment from '../../environment';
import { apiPOSTReq } from '../../Utils/api';
import { addDataToStore, CODAT_DATA } from '../../Actions/actions'
import BSyncIcon from '../../Images/sync.svg';
import Pipe from '../../Images/pipe.png'
import { LoadingOutlined } from '@ant-design/icons'
import SettingsIcon from '../../Images/settings.svg';
import Disconnect from '../../Images/disconnect.svg'


class ASConnection extends Component {
    state = {
        currentViewIndex: 0,
        historyPagination: {
            current: 1,
            pageSize: 50
        },
        errorPagination: {
            current: 1,
            pageSize: 80
        },
        qbErrors: [],
        integrationHistory: [],
        showAdvancedSetting: false,
        syncQB: false,
        isSyncInprogress: false,
        connectedPlatform: {},
        syncNow: false,
        showReconnect: true,
        selectedTab: 'Connections',
        pollInterval: null,
    };

    componentDidMount() {
        const { aionStore } = this.props;
        var { connection } = aionStore
        const { status } = connection || {}

        if (status === 'Linked') {
            this.getSyncProgress()
            this.startPolling();
        }
    }

    componentWillUnmount() {
        this.stopPolling();
    }

    startPolling = () => {
        const interval = setInterval(this.getSyncProgress, 5000); // Poll every 5 seconds
        this.setState({ pollInterval: interval });

        setTimeout(() => {
            this.setState({ pollInterval: null })
        }, 90000)
    };

    stopPolling = () => {
        clearInterval(this.state.pollInterval);
        this.setState({ pollInterval: null });
    };

    syncCodat = () => {
        var { pollInterval } = this.state;
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/codat/refreshAll`, {}, {}, (err, resp) => {
            try {
                this.setState({ loading: false });
                const data = resp;
                console.log("/integration/codat/refreshAll", data);
                if (data.result) {
                    message.success("Sync started...");
                    this.setState({ isSyncInprogress: true })
                    if (!pollInterval) {
                        setTimeout(() => {
                            this.startPolling();
                        }, 10000)
                    }
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later");
                }
            } catch (error) {
                console.log("ERRR integration/codat/refreshAll", error.stack, resp);
                ErrorAlert({ description: error.message });
            }
        })
    }

    getSyncProgress = () => {
        var { pollInterval } = this.state;
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/codat/getSyncProgress`, {}, {}, (err, resp) => {
            try {
                this.setState({ loading: false });
                const data = resp;
                console.log("/integration/codat/refreshAll getSyncProgress", data);
                if (data.result) {
                    const isInProgress = data.syncJobList.some(job =>
                        job.status === "InProgress" && !['InvoiceDetail', 'CustomerGrading'].includes(job?.progress?.entityName)
                    );

                    if (!isInProgress) {
                        this.updateLatestEndTime(data.syncJobList);
                        this.stopPolling();
                    }
                    this.setState({ isSyncInprogress: isInProgress })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later");
                }
            } catch (error) {
                this.stopPolling();
                console.log("ERRR integration/codat/refreshAll", error.stack, resp);
                // ErrorAlert({ description: error.message });
            }
        })
    }

    beginReconnect = () => {
        const { aionStore } = this.props;
        var { connection } = aionStore
        var { location } = this.props
        var { linkUrl } = connection || {}
        var { pathname } = location

        this.setState({ loading: true })
        this.props.dispatch(addDataToStore(CODAT_DATA, { reconnectingCodat: true }))

        if (connection) {
            var redirectUri = `${linkUrl}?website=${environment.uiBaseUrl}&path=${pathname}`
            window.location.href = redirectUri
        }
    }

    disconnect = () => {
        const { aionStore } = this.props;
        var { connection } = aionStore
        const { platformName } = connection || {}
        this.setState({ disconnectLoading: true });
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/codat/unlinkConnection`, {}, {}, (err, resp) => {
            try {
                this.setState({ loading: false });
                const data = resp;
                console.log("ASConnection unlinkConnection /integration/codat/unlinkConnection", data);
                if (data.result) {
                    message.success(`${platformName || 'Accounting Software'} disconnected`);
                    this.getConnections()
                    // message.success("Sync started...");
                    // this.setState({ isSyncInprogress: true })
                    // if (!pollInterval) {
                    //     setTimeout(() => {
                    //         this.startPolling();
                    //     }, 10000)
                    // }
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later");
                }
            } catch (error) {
                console.log("ASConnection unlinkConnection error", error.stack, resp);
                ErrorAlert({ description: error.message });
            } finally {
                this.setState({ disconnectLoading: false });
            }
        })
    }

    getConnections = () => {
        apiPOSTReq(`${environment.integrationBaseUrl}/integration/codat/getConnections`, {}, {}, (err, resp) => {
            try {
                const data = resp || {};
                if (data.result) {
                    var connections = data.connections || []
                    console.log("ASConnection unlinkConnection getConnections /integration/codat/getConnections data", data)
                    this.props.dispatch(addDataToStore(CODAT_DATA, { connections: connections, connection: connections.length > 0 && connections[0], company: data.company }))
                    this.setState({ disconnectLoading: false, showDisconnect: false });
                    window.history.back()
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ASConnection unlinkConnection getConnections error", { description: error.message })
            } finally {
                this.setState({ disconnectLoading: false });
            }
        })
    }

    updateLatestEndTime = (jobs) => {
        // Filter out jobs without a valid taskEndTime
        const validEndTimes = jobs
            .filter(job => job.taskEndTime && moment(job.taskEndTime).isValid())
            .map(job => moment(job.taskEndTime));

        // Find the latest time
        if (validEndTimes.length > 0) {
            const latestEndTime = moment.max(validEndTimes);
            this.setState({ latestEndTime: latestEndTime.format('YYYY-MM-DD HH:mm:ss') });
        }
    };

    componentWillUnmount() {
        clearInterval(this.timer);
        this.timer = null;
    }

    render() {
        const { settings, aionStore, disconnect } = this.props;
        var { connection } = aionStore
        var { loading, selectedTab, isSyncInprogress, connectedPlatform, syncNow, showReconnect, disabled, submitLoading, latestEndTime, showDisconnect, disconnectLoading } = this.state;
        const { platformName, logoUrl, status, lastRefreshed } = connection || {}

        const setupCompleted = status === 'Linked'

        return (
            <>
                <CardContainer padding='12px 24px' margin='0 0 24px 0' height='56px'>
                    <Flex style={{ height: '100%' }} between centerHorizontally>
                        <Flex start gap='12px' centerHorizontally>
                            <img height='50px' src={logoUrl} />

                            {
                                connection && <Tag size='14px' height='35px' color='#318F2F' background='#F5F9F5' alert={status !== 'Linked'}>{status === 'Linked' ? 'Connected' : 'Disconnected'}</Tag>
                            }
                            {/* {
                                                            qbSetupCompleted && <TextButton text='DISCONNECT' onClick={() => revokeToken()}  style={{ marginRight: 8 }} />
                                                        } */}
                        </Flex>
                        <Flex end centerHorizontally gap='24px'>
                            {setupCompleted ?
                                <>
                                    {
                                        isSyncInprogress ?
                                            <>
                                                {settings && <TextButton text='SETTINGS' style={{ marginRight: 0 }} rightIcon={<Image src={SettingsIcon} />} onClick={() => this.props.history.push('/settings/accounting-system-sync')} />}
                                                <Flex start centerHorizontally>
                                                    <LoadingOutlined style={{ color: '#1199FF' }} />
                                                    <Text color='#1199FF' lightText noWrap style={{ marginLeft: 12 }}> Sync in progress</Text>
                                                </Flex>
                                            </>
                                            :
                                            <>
                                                <Text lightText noWrap style={{ color: '#666666', fontSize: '12px', marginRight: 0 }}>Last synced {lastRefreshed ? moment(lastRefreshed).format('ll h:mm a') : '-'}</Text>
                                                {(settings || status === 'Linked') && <img margin='0' width='1px' height='30px' src={Pipe} style={{ marginRight: 0 }} />}
                                                {settings && <TextButton text='SETTINGS' style={{ marginRight: 0 }} rightIcon={<Image src={SettingsIcon} />} onClick={() => this.props.history.push('/settings/accounting-system-sync')} />}
                                                {status === 'Linked' && <TextButton text="SYNC" rightIcon={<Image src={BSyncIcon} />} onClick={this.syncCodat} />}
                                            </>
                                    }
                                </>
                                :
                                showReconnect ?
                                    <Button
                                        radius='4px'
                                        width='140px'
                                        height='2px'
                                        text='RECONNECT'
                                        onClick={this.beginReconnect}
                                    /> :
                                    <Button
                                        radius='4px'
                                        width='140px'
                                        height='2px'
                                        text='CONFIGURE'
                                        onClick={() => window.location.href = ('/settings/integration/qb-setup')}
                                    />
                            }

                            {/* {
                                (disconnect && setupCompleted) &&
                                <TextButton text='DISCONNECT' onClick={() => this.setState({ showDisconnect: true })} rightIcon={<Image src={Disconnect} />} color={"#e82c2c"} />
                            } */}
                        </Flex>
                    </Flex>
                </CardContainer>

                <AlertModal
                    imgSrc={Disconnect}
                    visible={showDisconnect}
                    title='Are you sure you want to disconnect?'
                    description={`Real time syncing with your accounting system will be disabled.`}
                    buttonTitle='DISCONNECT'
                    onConfirm={this.disconnect}
                    onCancel={() => this.setState({ showDisconnect: false })}
                    loading={disconnectLoading}
                />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer,
        bankingStore: state.bankingAppReducer,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(ASConnection));