import React from 'react'
import { Table, Popover } from 'antd'
import { withTheme } from 'styled-components'
import moment from 'moment'
import { InfoCircleOutlined } from '@ant-design/icons'

import { Flex, FlexColumn } from '../../Reusable/Container'
import TableView from '../../Reusable/TableView'

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
})

const ReportTable = (props) => {
    const handleTableChange = (pagination, filters, sorter) => {
        props.fetchReport({ pagination, sorter, })
    }

    const getRowDetails = (record, props) => {
        const { theme } = props

        const columns = [
            { title: 'Date', dataIndex: 'date', key: 'date', width: 120, render: item => <span style={{ whiteSpace: "nowrap" }}>{moment(item).format("MMM D, YYYY")}</span> },
            { title: 'Description', dataIndex: 'name', key: 'name' },
            { title: 'Amount', dataIndex: 'amount', key: 'amount', width: 120, render: (item) => (formatter.format(item)) },
        ]

        return <Flex style={{ width: "100%", padding: "20px", background: theme.body, justifyContent: "space-between", alignItems: 'flex-start' }}>
            <FlexColumn style={{ width: "100%" }}>
                <div style={{ fontWeight: 'bold', fontSize: '16px', marginBottom: '10px' }}>Receipts Received:</div>
                <Table
                    style={{ background: "white" }}
                    columns={columns}
                    className="plain-table"
                    dataSource={record.payments}
                    rowKey="TransactionId"
                    pagination={false}
                    size="small" />
            </FlexColumn>
        </Flex>
    }
    
    const columns = [
        {
            title: 'Date',
            dataIndex: 'reconciliationDate',
            key: 'reconciliationDate',
            render: item => moment(item).format("MMM D, YYYY"),
        },
        {
            title: (
                <Popover content="Total loan volume deposited by date.">
                    <span>
                        Loans <span style={{ whiteSpace: "nowrap" }}>Deposited <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} /></span>
                    </span>
                </Popover>
            ),
            dataIndex: 'loanDeposited',
            key: 'loanDeposited',
            render: (item) => (formatter.format(item)),
        },
        {
            title: (
                <Popover content="Payments applied to the line.">
                    <span>
                        Receipts <span style={{ whiteSpace: "nowrap" }}>Applied <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} /></span>
                    </span>
                </Popover>
            ),
            dataIndex: 'paymentsReconciled',
            key: 'paymentsReconciled',
            render: (item) => (formatter.format(item)),
        },
        {
            title: (
                <Popover content="Payments applied against principal loan balance.">
                    <span>
                        Principal <span style={{ whiteSpace: "nowrap" }}>Applied <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} /></span>
                    </span>
                </Popover>
            ),
            dataIndex: 'principalApplied',
            key: 'principalApplied',
            render: (item) => (formatter.format(item)),
        },
        {
            title: (
                <Popover content="Interest applied for today on application of payments.">
                    <span>
                        Interest <span style={{ whiteSpace: "nowrap" }}>Applied <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} /></span>
                    </span>
                </Popover>
            ),
            dataIndex: 'interestApplied',
            key: 'interestApplied',
            render: (item) => (formatter.format(item)),
        },
        {
            title: (
                <Popover content="Any refunds post-application of payments.">
                    <span style={{ whiteSpace: "nowrap" }}>Refunds <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} /></span>
                </Popover>
            ),
            dataIndex: 'refund',
            key: 'refund',
            render: (item) => (formatter.format(item)),
        },
        {
            title: (
                <Popover content="Outstanding interest post-application of payments.">
                    <span>
                        Outstanding <span style={{ whiteSpace: "nowrap" }}>Interest <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} /></span>
                    </span>
                </Popover>
            ),
            dataIndex: 'totalOutstandingInterestToDate',
            key: 'totalOutstandingInterestToDate',
            render: (item) => (formatter.format(item)),
        },
        {
            title: (
                <Popover content="Outstanding loan balance post-application of payments.">
                    <span>
                        Outstanding Loan <span style={{ whiteSpace: "nowrap" }}>Balance <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} /></span>
                    </span>
                </Popover>
            ),
            dataIndex: 'outstandingLoanBalance',
            key: 'outstandingLoanBalance',
            render: (item) => (formatter.format(item)),
        },
        {
            title: (
                <Popover content="Daily interest on ending outstanding balance.">
                    <span>
                        Daily <span style={{ whiteSpace: "nowrap" }}>Interest <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} /></span>
                    </span>
                </Popover>
            ),
            dataIndex: 'dailyInterest',
            key: 'dailyInterest',
            render: (item) => (formatter.format(item)),
        },
    ]

    let count = 0
    const data = props.reports.map(x => {
        return {
            key: count++, ...x,
        }
    })

    return (
        <>
            <TableView
                id="report-table"
                titleText="Transactions"
                rowKey="id"
                dataSource={data}
                columns={columns}
                tableLayout='auto'
                pagination={props.pagination}
                onChange={handleTableChange}
                expandable={{
                    expandedRowRender: record => getRowDetails(record, props),
                    expandRowByClick: true,
                    expandIconColumnIndex: -1,
                    columnWidth: "2px",
                    indentSize: "20px",
                    rowExpandable: record => record.payments && record.payments.length > 0
                }}
                ctaContent={props.ctaContent}
            />
        </>
    )
}

export default withTheme(ReportTable)