import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'styled-components'
import { Modal, Badge, Table, Space, Tabs } from 'antd'

import { Flex, FlexColumn, CardContainer, LightContainer } from '../Reusable/Container'
import PageHeader from '../Reusable/PageHeader'
import { LabeledInput } from '../Reusable/Input'
import { Button, TextButton } from '../Reusable/Button'
import HiddenInfo from '../Reusable/HiddenInfo'
import ViewBill from '../Pay/Bills/ViewBill'
import PDFDocument from '../Reusable/PDFDocument';
import { Image } from '../Reusable/Image'
import { ErrorAlert } from '../Reusable/Alert'
import { Text, Tag } from '../Reusable/Text'
import environment from '../../environment'
import moment from 'moment'
import { addressObjectToStr, toCurrency, getResourcePerm, renderBankIcon, addressObjectToMultiLineStr, getBPAccountsWithAccess, getDateInLocalTZ } from '../../Utils/util'
import { apiPOSTReq, getPreSignedS3Url } from '../../Utils/api'
import ModalClose from '../../Images/modal-close.png'
import ChevronDown from "../../Images/chevron-down.svg"
import ChevronUp from "../../Images/chevron-up.png"
import TableView from '../Reusable/TableView'

const { TabPane } = Tabs

class Index extends Component {

    constructor(props) {
        super(props)

        const paymentsPerm = getResourcePerm("BusinessBanking.Payments")
        const recipientsPerm = getResourcePerm("BusinessBanking.Recipients")

        this.state = {
            name: 'New Approval Setting',
            amount: 2000,
            paymentType: "ACH",
            rule: "greater than",
            approvers: {
                0: [],
                1: [],
                2: []
            },
            approvalSettings: [],
            approvalBy: "Any",
            stepCount: 1,
            showEditSettingModal: false,
            levelTypes: {
                0: "ANY",
                1: "ANY",
                2: "ANY"
            },
            registeredUsers: [],
            selectedTab: "todo",
            loading: true,
            pageSize: 10,
            page: 0,
            payPage: 0,
            todoApprovals: [],
            todoPayApprovals: [],
            tab: (paymentsPerm.approve || recipientsPerm.approve) ? 'Banking' : 'Bill Payments',
            coaPagination: {
                current: 1,
                pageSize: 200
            },
            bankingNotificationCount: 0,
            billsNotificationCount: 0
        }
    }

    componentDidMount() {
        this.fetchInbox()
        this.fetchPayablesInbox()
        this.getInboxCount()
        this.getPayablesInboxCount()
        this.fetchCOA({ coaPagination: this.state.coaPagination })
    }

    fetchInbox = () => {
        const { UserInfo } = this.props.store
        const { pageSize, page, todoApprovals } = this.state

        const body = { "approverUserId": UserInfo.Email, "sortDirection": "DESC", size: pageSize, page: page }

        // console.log("pending approvals bb/getInboxItems body", body)

        this.setState({ loading: true })
        apiPOSTReq(`${environment.bbBaseUrl}/bb/approvals/getInboxItems`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            try {
                this.setState({ loading: false })
                const data = resp

                console.log("pending approvals bb/getInboxItems", data)

                if (data.result) {
                    if (err) throw new Error(err)

                    // if (data.pendingItemList && this.props.onApprovalAction) {
                    //     console.log("onApprovalAction", data)
                    //     this.props.onApprovalAction(data.pendingItemList.length)
                    // }
                    this.setState({ todoApprovals: todoApprovals.concat(data.pendingItemList), loading: false, moreToLoad: data.pageable })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getAccounts", error, err, resp)
            }
        })
    }

    fetchPayablesInbox = () => {
        const { UserInfo } = this.props.store
        const { pageSize, payPage, todoPayApprovals } = this.state

        const body = { "approverUserId": UserInfo.Email, "sortDirection": "DESC", size: pageSize, page: payPage }

        this.setState({ loadingPay: true })
        apiPOSTReq(`${environment.payBaseUrl}/payables/approvals/getInboxItems`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            try {
                this.setState({ loading: false })
                const data = resp

                console.log("Approvals /payables/getInboxItems", data)

                if (data.result) {
                    if (err) throw new Error(err)

                    // if (data.pendingItemList && this.props.onApprovalAction) {
                    //     console.log("onApprovalAction", data)
                    //     this.props.onApprovalAction(data.pendingItemList.length)
                    // }
                    this.setState({ todoPayApprovals: todoPayApprovals.concat(data.pendingItemList), loadingPay: false, morePayToLoad: data.pageable })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getAccounts", error, err, resp)
            }
        })
    }

    getInboxCount = () => {
        const { UserInfo } = this.props.store
        var username = UserInfo.Email
        apiPOSTReq(`${environment.bbBaseUrl}/bb/approvals/getInboxItems`, { "BankProvider": environment.bankProvider.crb }, { "approverUserId": username }, (err, resp) => {
            try {
                const data = resp
                if (data.result) {
                    if (err) throw new Error(err)
                    if (data.count > 0) this.setState({ bankingNotificationCount: data.count })
                    else this.setState({ bankingNotificationCount: null })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERROR bb/approvals/getInboxItems", error, err, resp)
            }
        })
    }

    getPayablesInboxCount = () => {
        const { bankingNotificationCount } = this.state
        const { UserInfo } = this.props.store
        var username = UserInfo.Email
        apiPOSTReq(`${environment.payBaseUrl}/payables/approvals/getInboxItems`, { "BankProvider": environment.bankProvider.crb }, { "approverUserId": username }, (err, resp) => {
            try {
                const data = resp
                if (data.result) {
                    if (err) throw new Error(err)
                    if (data.count > 0) {
                        this.setState({ billsNotificationCount: data.count, tab: (bankingNotificationCount == 0) ? 'Bill Payments' : 'Banking' })
                    }
                    else this.setState({ billsNotificationCount: null })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERROR payables/approvals/getInboxItems", error, err, resp)
            }
        })
    }

    fetchCOA = (options) => {
        const { coaPagination } = options
        const headers = {
            AionCurrentBiz: this.props.store.BusinessUniqueKey,
            AionAuth: this.props.store.UAM.encryptedAuthHeader
        }
        const body = {
            "BusinessId": this.props.store.BusinessUniqueKey,
            "size": coaPagination.pageSize,
            "page": 0,
            "ActiveRecords": true,
            "sortFieldName": "Code",
            "sortDirection": "ASC"
        }
        this.setState({ newProductLoading: true })
        apiPOSTReq(`${environment.bbBaseUrl}/bk/getCategories`, headers, body, (err, resp) => {
            try {
                const data = resp
                console.log("/bk/getCategories", data)
                if (data.result) {
                    // this.props.dispatch(addDataToStore(BOOKKEEPING_SAVE_DATA, { COA: (data.systemCOAList || []).concat(data.customCOAList) }))
                    this.setState({
                        coaPagination: {
                            ...coaPagination,
                            total: data.count
                        },
                        categories: (data.systemCOAList || []).concat(data.customCOAList),
                    })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getActiveCustomers", error.stack, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    handleOnChange = (event) => {
        const target = event.target
        this.setState({ [target.id]: target.value })
    }

    handleSelect = (value, option) => {
        this.setState({ [option.id]: value })
    }

    handleSelectLevelType = (value, option) => {
        const { levelTypes } = this.state
        levelTypes[option.id] = value
        this.setState({ levelTypes })
    }

    handleMultiSelect = (value, option) => {
        var { approvers, registeredUsers } = this.state

        var selectedUser

        registeredUsers.map(r => {
            if (r.id === option[option.length - 1].value) {
                selectedUser = r
            }
        })

        if (selectedUser) {
            approvers[option[option.length - 1].id].push(selectedUser)

            this.setState({ approvers })
        }
    }

    handleReject = (selectedApproval) => {
        // if (!this.state.reason) {
        //     this.setState({ reasonError: true })
        // } else {
        //     this.setState({ reasonError: false, loadingReject: true })
        const { UserInfo } = this.props.store
        var username = UserInfo.Email
        var request = {
            "approverUserId": username,
            "InstanceId": selectedApproval.ruleInstanceId,
            "objectId": selectedApproval.objectId,
            "objectType": selectedApproval.objectType,
            "UserAction": "Rejected",
            "reason": this.state.reason,
        }
        var rejectUrl = `${environment.bbBaseUrl}/bb/approvals/reject`
        if (selectedApproval.sourceApplication == "Payables") {
            rejectUrl = `${environment.payBaseUrl}/payables/approvals/reject`
        }
        console.log("handleReject", rejectUrl, request, selectedApproval)
        apiPOSTReq(rejectUrl, { "BankProvider": environment.bankProvider.crb }, request, (err, resp) => {
            this.setState({ loadingReject: false })
            try {
                const data = resp
                console.log("Approvals /approvals/reject", data, rejectUrl, request)
                if (data.result) {
                    this.props.onApprovalAction()

                    this.setState({ todoApprovals: [], todoPayApprovals: [], showApprovalModal: false, reason: null }, () => {
                        this.fetchPayablesInbox()
                        this.fetchInbox()
                        this.getInboxCount()
                        this.getPayablesInboxCount()
                    })
                    if (err) throw new Error(err)
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getAccounts", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
        // }
    }

    handleApprove = (selectedApproval) => {
        // if (!this.state.reason) {
        //     this.setState({ reasonError: true })
        // } else {
        this.setState({ reasonError: false, loadingApprove: true })
        const { UserInfo } = this.props.store
        var username = UserInfo.Email
        var request = {
            "approverUserId": username,
            "InstanceId": selectedApproval.ruleInstanceId,
            "objectId": selectedApproval.objectId,
            "objectType": selectedApproval.objectType,
            "UserAction": "Approved",
            "reason": this.state.reason,
        }
        var approveUrl = `${environment.bbBaseUrl}/bb/approvals/approve`
        if (selectedApproval.sourceApplication == "Payables") {
            approveUrl = `${environment.payBaseUrl}/payables/approvals/approve`
        }
        console.log("handleApprove", approveUrl, request, selectedApproval, { "BankProvider": environment.bankProvider.crb })
        apiPOSTReq(approveUrl, { "BankProvider": environment.bankProvider.crb }, request, (err, resp) => {
            this.setState({ loadingApprove: false })
            try {
                const data = resp

                console.log("Approvals /approvals/approve", data, approveUrl, request)

                if (data.result) {
                    this.props.onApprovalAction()

                    this.setState({ todoApprovals: [], todoPayApprovals: [], showApprovalModal: false, reason: null }, () => {
                        this.fetchPayablesInbox()
                        this.fetchInbox()
                        this.getInboxCount()
                        this.getPayablesInboxCount()
                    })
                    if (err) throw new Error(err)
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getAccounts", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
        // }
    }


    getApproversText = (approvers, approvalBy) => {
        if (approvers.length == 1) {
            return `${approvers[0].firstName} ${approvers[0].lastName}`
        } else {
            return <>
                {approvers.map(a => `${a.firstName} ${a.lastName}`).slice(0, approvers.length - 1).join(", ")}<b>{approvalBy === "ANY" ? " or " : " and "}</b>{`${approvers[approvers.length - 1].firstName} ${approvers[approvers.length - 1].lastName}`}
            </>
        }
    }

    getPaymentTypeText = (paymentType) => {
        switch (paymentType) {
            case 'ACH':
                return <><b>{paymentType}</b> transfers</>
            case 'Wire', 'Check', 'Bill Pay':
                return <><b>{paymentType}</b> transfers</>
            default:
                return <><b>Any</b> transfer</>
        }
    }

    getApproverNames = (level, levelCount) => {
        const { UserInfo } = this.props.store
        var username = UserInfo.Email

        var items = []
        level.performerInstances.map(a => {
            if (a.userName != username) {
                items.push(<Tag color={this.props.theme.colors.secondary3} style={{ marginRight: 10 }}>{`${a.firstName} ${a.lastName}`}</Tag>)
            } else {
                items.push(<Tag color={this.props.theme.colors.secondary3} style={{ marginRight: 10 }}>You</Tag>)
            }
        })

        return items
    }

    onTabChange = tab => {
        console.log("Approvals /payables/getInboxItems", tab)
        this.setState({ tab })
    }


    getObjectDetail = (body, selectedApproval, callback) => {
        apiPOSTReq(`${environment.bbBaseUrl}/bb/getObjectDetail`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            // console.log('Pending bb/getObjectDetail: ', resp, selectedApproval)
            // console.log("Pending /bb/getObjectDetail body record getObjectDetail", resp, selectedApproval)
            try {
                var data = resp || {}
                if (err) throw Error(err)
                if (!resp.result) throw Error(resp.responseMessage || resp.error)

                var approvalType = (selectedApproval || {}).objectType
                if (approvalType === 'Counterparty') {
                    this.getPaymentMethods(data, selectedApproval, data.counterpartiesObj)
                } else if (approvalType === 'ACH') {
                    if (data?.achTransferObj?.billId) {
                        this.getBill(data?.achTransferObj?.billId, data, selectedApproval)
                    } else {
                        var body = { viewBy: "COUNTERPARTIES", objectId: data?.achTransferObj?.counterpartyId, "fetchByPrimaryKey": false }
                        this.getCounterpartyDetail(body, selectedApproval, data)
                    }
                    this.setState({ achTransferObj: data?.achTransferObj })
                } else if (approvalType === 'Wire') {
                    var body = { viewBy: "COUNTERPARTIES", objectId: data?.wireTransferObj?.counterpartyId, "fetchByPrimaryKey": false }
                    this.getCounterpartyDetail(body, selectedApproval, data)
                } else {
                    this.setState({ showApprovalModal: true, objectDetail: data, selectedApproval })
                }
            } catch (error) {
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    getBill = (id, objectDetail, selectedApproval) => {
        apiPOSTReq(`${environment.payBaseUrl}/payables/bills/getBill`, { "BankProvider": environment.bankProvider.crb }, { billId: id }, (err, resp) => {
            try {
                const data = resp
                console.log("Pending /bb/getObjectDetail body record data.bill id", id)
                if (data.result && data.bill) {
                    console.log("Pending /bb/getObjectDetail body record data.bill", data.bill)
                    this.setState({ bill: data.bill, showApprovalModal: true, objectDetail, selectedApproval })

                    if ((data.bill || {}).previewDocumentUrls && data.bill.previewDocumentUrls.length > 0) {
                        getPreSignedS3Url({ url: data.bill.previewDocumentUrls[0].uri }, (err, preSignedUrl) => {
                            if (!err) {
                                this.setState({ billUrl: preSignedUrl })
                            }
                        })
                    }
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                // console.log("Pending /bb/getObjectDetail body record data.bill id", error, err, resp)
                // console.log("ERRR /payables/bills/getBill", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    getCounterpartyDetail = (body, selectedApproval, objectDetail) => {
        console.log("Pending bb/getObjectDetail:getCounterpartyDetail body", body)
        apiPOSTReq(`${environment.bbBaseUrl}/bb/getObjectDetail`, { "BankProvider": environment.bankProvider.crb }, body, (err, resp) => {
            console.log('Pending bb/getObjectDetail:getCounterpartyDetail ', resp)
            try {
                var data = resp || {}
                if (err) throw Error(err)
                if (!resp.result) throw Error(resp.responseMessage || resp.error)

                this.getPaymentMethods(objectDetail, selectedApproval, data.counterpartiesObj)
            } catch (error) {
                console.log("ERRR getAccounts", error, err, resp)
                this.setState({ showApprovalModal: true, objectDetail, selectedApproval })
            }
        })
    }

    getPaymentMethods = (objectDetail, selectedApproval, counterpartiesObj) => {
        console.log("bb/getPaymentMethods id", counterpartiesObj.objectId)
        apiPOSTReq(`${environment.bbBaseUrl}/bb/getPaymentMethods`, null, { objectId: counterpartiesObj.objectId }, (err, resp) => {
            try {
                const data = resp

                if (data.result) {
                    if (err) throw new Error(err)
                    console.log("Pending bb/getPaymentMethods", data.paymentMethods)
                    var approvalType = (selectedApproval || {}).objectType

                    if (approvalType === 'Counterparty') this.setState({ transferMethods: data.paymentMethods, recipient: counterpartiesObj })
                    if (approvalType === 'ACH') {
                        this.setState({ transferMethod: data.paymentMethods.find(paymentMethod => paymentMethod.id === objectDetail?.achTransferObj?.paymentMethodId), recipient: counterpartiesObj })
                    }
                    if (approvalType === 'Wire') {
                        this.setState({ transferMethod: data.paymentMethods.find(paymentMethod => paymentMethod.id === objectDetail?.wireTransferObj?.paymentMethodId), recipient: counterpartiesObj })
                    }
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getAccounts", error, err, resp)
            } finally {
                this.setState({ showApprovalModal: true, objectDetail, selectedApproval })
            }
        })
    }

    getRowDetails = (record, props) => {
        var { bankDetail, recipient } = record
        bankDetail = bankDetail || {}

        const { accountNumber, routingNumber, accountType, checkDeliveryType, addressOnAccount } = bankDetail

        return (
            <LightContainer fullWidth>
                <Flex start fullWidth wrap gap='12px'>
                    {
                        accountNumber &&
                        <LabeledInput
                            label='Account Number'
                            nomargin
                            type='read-only'
                            value={accountNumber}
                            width='160px'
                        />
                    }

                    {
                        routingNumber &&
                        <LabeledInput
                            label='Routing Number'
                            nomargin
                            type='read-only'
                            value={routingNumber}
                            width='160px'
                        />
                    }

                    {
                        accountType &&
                        <LabeledInput
                            label='Account Type'
                            nomargin
                            type='read-only'
                            value={accountType}
                            width='160px'
                        />
                    }

                    {
                        checkDeliveryType &&
                        <LabeledInput
                            label='Delivery Type'
                            nomargin
                            type='read-only'
                            value={checkDeliveryType == 'PostalMail' ? 'Postal Mail' : checkDeliveryType}
                            width='150px'
                        />
                    }

                    {
                        checkDeliveryType && addressOnAccount &&
                        <LabeledInput
                            label='Delivery Address'
                            nomargin
                            type='read-only'
                            value={addressObjectToMultiLineStr(addressOnAccount)}
                            width='150px'
                        />
                    }

                    {
                        (checkDeliveryType == 'Email') &&
                        <LabeledInput
                            label='Delivery Address'
                            nomargin
                            type='read-only'
                            value={recipient.email}
                            width='150px'
                        />
                    }
                </Flex>
            </LightContainer>
        )
    }

    render() {
        const { moreToLoad, morePayToLoad, selectedApproval, page, payPage, loadingReject, loadingApprove, reason, loading, transferMethods, recipient, bill, categories,
            tab, todoApprovals, todoPayApprovals, showApprovalModal, objectDetail, reasonError, bankingNotificationCount, billsNotificationCount, transferMethod, billUrl } = this.state
        const { theme } = this.props
        var { counterpartiesObj, achTransferObj, wireTransferObj, bpPaymentObj, instantTransferObj } = objectDetail || {}
        const { sourceApplication } = selectedApproval || {}

        // console.log("Pending achTransferObj", achTransferObj, counterpartiesObj, wireTransferObj, bpPaymentObj, objectDetail)
        console.log("Pending achTransferObj", achTransferObj)

        var Accounts = getBPAccountsWithAccess("Payments", { permType: "manage", getActiveAccounts: true })
        var fromAccount

        if (achTransferObj) fromAccount = Accounts.find(a => a.accountNumber === achTransferObj.accountNumber)
        if (wireTransferObj) fromAccount = Accounts.find(a => a.accountNumber === wireTransferObj.accountNumber)

        const paymentsPerm = getResourcePerm("BusinessBanking.Payments")
        const recipientsPerm = getResourcePerm("BusinessBanking.Recipients")
        const billPaymentsPerm = getResourcePerm("Payables.Bill Payments")
        const contractorPaymentsPerm = getResourcePerm("Payables.Contractor Payments")

        const todoColumns = [
            {
                title: 'Date',
                dataIndex: 'assignedTime',
                key: 'assignedTime',
                render: date => {
                    return <Text noWrap>{moment(date).format('MMM Do, YYYY')}</Text>
                },
            },
            {
                title: 'Description',
                dataIndex: 'contextIdentifier',
                key: 'contextIdentifier',
                render: (contextIdentifier, item) => {
                    console.log("contextIdentifier objectDetail", contextIdentifier, item)
                    var tag = item.objectType

                    if (item.objectType === "Counterparty") tag = "New Recipient"
                    if (item.objectType === "BillPay") tag = "Check"
                    if (item.objectType === "InstantXfer") tag = "Instant"
                    return (
                        <Space><b>{contextIdentifier}</b><Tag tagpreset={theme.colors.secondary4}>{tag}</Tag></Space>
                    )
                },
            },
            {
                title: 'Requested By',
                dataIndex: 'initiatedBy',
                key: 'initiatedBy'
            },
            {
                // title: 'Action',
                key: 'action',
                render: (text, record) => {
                    var viewBy = record.objectType || ""
                    if (viewBy === "Counterparty") viewBy = "COUNTERPARTIES"
                    if (viewBy === "Wire") viewBy = "WIRE_TFER"
                    if (viewBy === "BillPay") viewBy = "BILL_PAY"
                    if (viewBy === "InstantXfer") viewBy = "INSTANT_TFER"
                    return (
                        <Space size="middle" style={{ width: "200px", justifyContent: "flex-end" }}>
                            <TextButton style={{ padding: 0 }} onClick={() => {
                                var body = { viewBy: viewBy, "objectId": record.objectId, "fetchByPrimaryKey": true }

                                console.log("Pending /bb/getObjectDetail body record", body, record)
                                this.setState({ billUrl: null })
                                this.getObjectDetail(body, record)
                            }} text='Review' />
                        </Space>
                    )
                },
            }
        ]

        console.log("Pending perm", paymentsPerm.approve, recipientsPerm.approve, billPaymentsPerm.approve, contractorPaymentsPerm.approve)
        const { nameOnAccount, email, type, transferMethodACH, transferMethodWire, transferMethodCheck, nickName } = recipient || {}

        var approvalType = (selectedApproval || {}).objectType
        if (approvalType === 'Counterparty') approvalType = "New Recipient"
        if (approvalType === 'InstantXfer') approvalType = "Instant"

        var columns = [
            {
                dataIndex: 'nickName',
                key: 'nickName',
                render: (nickName, transferMethod) => <Flex start gap='8px'>
                    {renderBankIcon(transferMethod.bankDetail, '24px')}
                    <Text>{nickName}</Text>
                </Flex>
            },
            {
                dataIndex: 'type',
                key: 'type',
                align: 'right',
                render: (type, transferMethod) => <Flex end gap='8px'>
                    <Tag bordered primary>{type}</Tag>
                    <Tag>{transferMethod.bankDetail.accountNumber && transferMethod.bankDetail.accountNumber.substring(transferMethod.bankDetail.accountNumber.length - 4, transferMethod.bankDetail.accountNumber.length)}</Tag>
                    {!transferMethod.active && <Tag alert>Inactive</Tag>}
                </Flex>
            },
        ]

        return (
            <>
                <FlexColumn className='main-padding'>
                    <PageHeader
                        subtitle="Approvals"
                        titleText="Approvals"
                        desc="Approve or reject any pending items"
                    />
                    {/* <CardContainer minHeight='700px' style={{ marginBottom: 48 }} padding='12px 24px 24px 24px' grow> */}
                        {
                            ((paymentsPerm.approve || recipientsPerm.approve) && (billPaymentsPerm.approve || contractorPaymentsPerm.approve)) ?
                                <>
                                    <Tabs size="large" onChange={this.onTabChange} activeKey={tab}>
                                        <TabPane
                                            tab={<Flex start centerHorizontally gap='8px'>
                                                <Text primary weight={tab === 'Banking' ? '500px' : '400px'}>Banking</Text>
                                                {
                                                    (bankingNotificationCount || 0) > 0 &&
                                                    <Badge style={{ backgroundColor: this.props.theme.colors.primary }} count={bankingNotificationCount} />
                                                }
                                            </Flex>}
                                            key="Banking"
                                        >
                                            <TableView
                                                id="pending-table"
                                                key="pending-table"
                                                // className='borderless-table'
                                                titleText='Pending'
                                                tableLayout='auto'
                                                columns={todoColumns}
                                                dataSource={todoApprovals || []}
                                                rowKey='objectId'
                                                loading={loading}
                                                pagination={false}
                                                onChange={(pagination, filters, sorter) => {
                                                    this.setState({ inboxPagination: pagination })
                                                }}
                                            />
                                        </TabPane>
                                        <TabPane
                                            tab={<Flex start centerHorizontally gap='8px'>
                                                <Text primary weight={tab === 'Bill Payments' ? '500px' : '400px'}>Bill Payments</Text>
                                                {
                                                    (billsNotificationCount || 0) > 0 &&
                                                    <Badge style={{ backgroundColor: this.props.theme.colors.primary }} count={billsNotificationCount} />
                                                }
                                            </Flex>}
                                            key="Bill Payments"
                                        >
                                            <TableView
                                                id="todo-pay-table"
                                                key="todo-pay-table"
                                                className='borderless-table'
                                                titleText='Pending'
                                                tableLayout='auto'
                                                columns={todoColumns}
                                                dataSource={todoPayApprovals || []}
                                                rowKey='objectId'
                                                loading={loading}
                                                pagination={false}
                                                onChange={(pagination, filters, sorter) => {
                                                    this.setState({ inboxPagination: pagination })
                                                }}
                                            />
                                        </TabPane>
                                    </Tabs>
                                </>
                                :
                                <TableView
                                    id="todo-table"
                                    key="todo-table"
                                    className='borderless-table'
                                    tableLayout='auto'
                                    columns={todoColumns}
                                    dataSource={(paymentsPerm.approve || recipientsPerm.approve) ? todoApprovals : todoPayApprovals}
                                    rowKey='objectId'
                                    style={{ marginTop: 12 }}
                                    loading={loading}
                                    onChange={(pagination, filters, sorter) => {
                                        this.setState({ inboxPagination: pagination })
                                    }}
                                />
                        }

                        {
                            ((tab === 'Bill Payments' && morePayToLoad === true) || (tab === 'Banking' && moreToLoad === true)) &&
                            <Flex style={{ marginTop: 24 }} fullWidth centerVertically><TextButton text='LOAD 10 MORE' loading={loading} onClick={() => {
                                if (tab === 'Bill Payments') {
                                    this.setState({ payPage: payPage + 1 }, () => this.fetchPayablesInbox())
                                } else {
                                    this.setState({ page: page + 1 }, () => this.fetchInbox())
                                }
                            }} rightIcon={<img style={{ cursor: 'pointer' }} width='24px' height='24px' src={ChevronDown} />} /></Flex>
                        }
                    {/* </CardContainer> */}
                </FlexColumn>

                <Modal
                    visible={showApprovalModal}
                    footer={null}
                    closable={true}
                    width={bill ? (billUrl ? 1250 : 700) : 670}
                    destroyOnClose={true}
                    onCancel={() => {
                        this.setState({ showApprovalModal: false })
                    }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <Flex between gap='24px'>

                        {
                            (bill && billUrl) &&
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                <div style={{ height: "auto", border: 'none', marginBottom: 10, marginTop: 15 }}>
                                    <div style={{ width: 711 }}>
                                        <PDFDocument url={billUrl} fileName={bill.invoiceNumber} download height={600} width={663} />
                                    </div>
                                </div>
                            </div>
                        }

                        {
                            selectedApproval &&
                            <FlexColumn style={{ margin: '0 10px' }}>
                                <Text heading>{`${sourceApplication === 'Payables' ? 'Bill Payments - ' : ''}${approvalType}${['ACH', 'Wire'].includes(approvalType) ? ' Transfer' : ''}`} Approval</Text>

                                {
                                    (achTransferObj) &&
                                    <FlexColumn start>
                                        <Text caption spaced weight='500' margin='24px 0 0'>{sourceApplication === 'Payables' ? 'BILL DETAILS' : 'TRANSFER DETAILS'}</Text>
                                        <Flex start fullWidth wrap gap='12px'>
                                            {/* <Text>{selectedApproval.contextIdentifier}</Text> */}
                                            <LabeledInput type='read-only' key="initiatedBy" label="Created by" value={selectedApproval.initiatedBy} width='160px' />
                                            <LabeledInput type='read-only' key="amount" label="Amount" color={theme.colors.creditGreen} value={<b>{`$${toCurrency(achTransferObj.amount)}`}</b>} width='160px' />
                                            {fromAccount && <LabeledInput type='read-only' key="From" label="From Account" value={`${fromAccount.productType} • ${fromAccount.mask}`} width='160px' />}
                                            {sourceApplication !== 'Payables' && !achTransferObj.purpose && achTransferObj.description && <LabeledInput type='read-only' key="Description" label="Description" value={achTransferObj.description} width='160px' />}
                                            {/* {achTransferObj.userNote && <LabeledInput type='read-only' key="userNote" label="Note" value={achTransferObj.userNote} withdivider />} */}
                                            {achTransferObj.purpose && <LabeledInput type='read-only' key="purpose" label="Purpose" value={achTransferObj.purpose} width='160px' />}
                                            {achTransferObj.remittanceInfo && <LabeledInput type='read-only' key="remittanceInfo" label="Remittance Information" value={achTransferObj.remittanceInfo} width='160px' />}
                                            {!achTransferObj?.scheduledDate && (bill?.status == 'Pending Approval') && <LabeledInput type='read-only' label="Payment Date" value={"Initiated on approval"} width='160px' />}
                                            {achTransferObj?.scheduledDate && achTransferObj.createdAt && <LabeledInput type='read-only' label="Approval Submitted Date" value={getDateInLocalTZ(achTransferObj.createdAt)} width='160px' />}
                                            {achTransferObj?.scheduledDate && <LabeledInput key='scheduledDateStr' type='read-only' label="Payment Scheduled" value={getDateInLocalTZ(achTransferObj?.scheduledDate)} width='160px' />}
                                            {bill?.dueDate && <LabeledInput key="13" type='read-only' label="Due Date" value={getDateInLocalTZ(bill?.dueDate)} width='160px' />}
                                        </Flex>
                                        <Text caption spaced weight='500' margin='24px 0 0'>{sourceApplication === 'Payables' ? 'VENDOR DETAILS' : 'RECIPIENT DETAILS'}</Text>
                                        <Flex start fullWidth wrap gap='12px'>
                                            {achTransferObj.counterpartyName && <LabeledInput type='read-only' key="To" label="Name" value={achTransferObj.counterpartyName} width='160px' />}
                                            {recipient?.nickName && <LabeledInput type='read-only' key="To" label="Nickname" value={recipient?.nickName} width='160px' />}
                                            {achTransferObj.email && <LabeledInput type='read-only' key="Email" label="Email" value={achTransferObj.email} width='160px' />}
                                            {transferMethod?.bankDetail?.accountType && <LabeledInput type='read-only' key="Account Type" label="Account Type" value={transferMethod?.bankDetail?.accountType} width='160px' />}
                                            {transferMethod?.bankDetail?.accountNumber && <div style={{ width: 160, margin: '15px 0' }}><HiddenInfo type='read-only-password' key="Account Number" label="Account Number" value={transferMethod?.bankDetail?.accountNumber} /></div>}
                                            {transferMethod?.bankDetail?.routingNumber && <LabeledInput type='read-only' key="Routing Number" label="Routing Number" value={transferMethod?.bankDetail?.routingNumber} width='160px' />}
                                        </Flex>
                                        <div style={{ width: '100%', height: 1, background: '#E3E6EE', margin: '4px 0' }}></div>
                                    </FlexColumn>
                                }
                                {
                                    (wireTransferObj) &&
                                    <FlexColumn start>
                                        <Text caption spaced weight='500' margin='24px 0 0'>{sourceApplication === 'Payables' ? 'BILL DETAILS' : 'TRANSFER DETAILS'}</Text>
                                        <Flex start fullWidth wrap gap='12px'>
                                            {/* <Text>{selectedApproval.contextIdentifier}</Text> */}
                                            <LabeledInput type='read-only' key="initiatedBy" label="Created by" value={selectedApproval.initiatedBy} width='160px' />
                                            <LabeledInput type='read-only' key="amount" label="Amount" color={theme.colors.creditGreen} value={<b>{`$${toCurrency(wireTransferObj.amount)}`}</b>} width='160px' />
                                            {fromAccount && <LabeledInput type='read-only' key="From" label="From Account" value={`${fromAccount.productType} • ${fromAccount.mask}`} width='160px' />}
                                            {sourceApplication !== 'Payables' && !wireTransferObj.purpose && wireTransferObj.instructions && <LabeledInput type='read-only' key="Instructions" label="Instructions" value={wireTransferObj.instructions} width='160px' />}
                                            {wireTransferObj.purpose && <LabeledInput type='read-only' key="purpose" label="Purpose" value={wireTransferObj.purpose} width='160px' />}
                                            {wireTransferObj.remittanceInfo && <LabeledInput type='read-only' key="remittanceInfo" label="Remittance Information" value={wireTransferObj.remittanceInfo} width='160px' />}
                                        </Flex>
                                        <Text caption spaced weight='500' margin='24px 0 0'>{sourceApplication === 'Payables' ? 'VENDOR DETAILS' : 'RECIPIENT DETAILS'}</Text>
                                        <Flex start fullWidth wrap gap='12px'>
                                            {wireTransferObj.counterpartyName && <LabeledInput type='read-only' key="To" label="Name" value={wireTransferObj.counterpartyName} width='160px' />}
                                            {recipient?.nickName && <LabeledInput type='read-only' key="To" label="Nickname" value={recipient?.nickName} width='160px' />}
                                            {wireTransferObj.email && <LabeledInput type='read-only' key="Email" label="Email" value={wireTransferObj.email} width='160px' />}
                                            {transferMethod?.bankDetail?.accountType && <LabeledInput type='read-only' key="Account Type" label="Account Type" value={transferMethod?.bankDetail?.accountType} width='160px' />}
                                            {transferMethod?.bankDetail?.accountNumber && <div style={{ width: 160, margin: '15px 0' }}><HiddenInfo type='read-only-password' key="Account Number" label="Account Number" value={transferMethod?.bankDetail?.accountNumber} /></div>}
                                            {transferMethod?.bankDetail?.routingNumber && <LabeledInput type='read-only' key="Routing Number" label="Routing Number" value={transferMethod?.bankDetail?.routingNumber} width='160px' />}
                                            {transferMethod?.bankDetail?.bankName && <LabeledInput type='read-only' key="bankName" label="Bank Name" value={transferMethod?.bankDetail?.bankName} width='160px' />}
                                            {transferMethod?.bankDetail?.bankAddress && <LabeledInput type='read-only' key="bankAddress" label="Bank Address" value={addressObjectToStr(transferMethod?.bankDetail?.bankAddress)} width='160px' />}
                                            {/* {wire && <LabeledInput type='read-only-password' key="accountNumber" label="Account Number" value={wire.accountNumber} width='160px' />}
                                        {wire && <LabeledInput type='read-only' key="routingNumber" label="Routing Number" value={wire.routingNumber} width='160px' />}
                                        {wire && <LabeledInput type='read-only' key="bankName" label="Bank Name" value={wire.bankName} width='160px' />}
                                        {wire && <LabeledInput type='read-only' key="bankAddress" label="Bank Address" value={addressObjectToStr(wire.bankAddress)} width='160px' />} */}
                                        </Flex>
                                        <div style={{ width: '100%', height: 1, background: '#E3E6EE', margin: '4px 0' }}></div>
                                    </FlexColumn>
                                }
                                {
                                    bpPaymentObj &&
                                    <Flex start fullWidth wrap gap='12px'>
                                        {/* <Text>{selectedApproval.contextIdentifier}</Text> */}
                                        <LabeledInput type='read-only' key="initiatedBy" label="Created by" value={selectedApproval.initiatedBy} width='160px' />
                                        <LabeledInput type='read-only' key="amount" label="Amount" color={theme.colors.creditGreen} value={<b>{`$${toCurrency(bpPaymentObj.amount)}`}</b>} width='160px' />
                                        {bpPaymentObj.billPayCounterpartyName && <LabeledInput type='read-only' key="To" label="Payment To" value={bpPaymentObj.billPayCounterpartyName} width='160px' />}
                                        {bpPaymentObj.email && <LabeledInput type='read-only' key="Email" label="Email" value={bpPaymentObj.email} width='160px' />}
                                        {bpPaymentObj.instructions && <LabeledInput type='read-only' key="Instructions" label="Instructions" value={bpPaymentObj.instructions} width='160px' />}
                                    </Flex>
                                }
                                {
                                    instantTransferObj &&
                                    <Flex start fullWidth wrap gap='12px'>
                                        {/* <Text>{selectedApproval.contextIdentifier}</Text> */}
                                        <LabeledInput type='read-only' key="initiatedBy" label="Created by" value={selectedApproval.initiatedBy} width='160px' />
                                        <LabeledInput type='read-only' key="amount" label="Amount" color={theme.colors.creditGreen} value={<b>{`$${toCurrency(instantTransferObj.amount)}`}</b>} width='160px' />
                                        {instantTransferObj.receiverName && <LabeledInput type='read-only' key="To" label="Payment To" value={instantTransferObj.receiverName} width='160px' />}
                                        {instantTransferObj.email && <LabeledInput type='read-only' key="Email" label="Email" value={bpPaymentObj.email} width='160px' />}
                                        {instantTransferObj.purpose && <LabeledInput type='read-only' key="purpose" label="Purpose" value={instantTransferObj.purpose} width='160px' />}
                                        {instantTransferObj.remittanceInfo && <LabeledInput type='read-only' key="remittanceInfo" label="Remittance Information" value={instantTransferObj.remittanceInfo} width='160px' />}
                                    </Flex>
                                }

                                {
                                    (counterpartiesObj && transferMethods && recipient) &&
                                    <>
                                        <Flex start centerHorizontally gap='8px' style={{ marginTop: 10 }}>
                                            <Text caption spaced weight='500' margin='0 0 0'>{'Recipient Details'.toUpperCase()}</Text>
                                            {/* <Tag bordered primary>{type}</Tag> */}
                                        </Flex>
                                        <Flex start fullWidth wrap gap='24px'>
                                            {type && <div><LabeledInput type='read-only' key="Type" label="Type" value={type} width='160px' /></div>}
                                            {(nameOnAccount) && <div><LabeledInput type='read-only' key="To" label="Name" value={nameOnAccount} width='160px' /></div>}
                                            {(nickName) && <div><LabeledInput type='read-only' key="To" label="Nickname" value={nickName} width='160px' /></div>}
                                            {email && <div><LabeledInput type='read-only' key="Email" label="Email" value={email} /></div>}
                                        </Flex>
                                        <Text color={'#7384AA'} size='14px' weight={400} margin='8px 0 12px'>Bank Details</Text>
                                        <Table
                                            id="transfer-methods"
                                            className='borderless-table'
                                            tableLayout='auto'
                                            columns={columns}
                                            dataSource={transferMethods.sort((a, b) => b.active - a.active)}
                                            rowKey='id'
                                            pagination={false}
                                            showHeader={false}
                                            expandable={{
                                                expandedRowRender: record => this.getRowDetails({ ...record, recipient: recipient }, this.props),
                                                expandRowByClick: false,
                                                expandIconColumnIndex: 4,
                                                expandIcon: ({ expanded, onExpand, record }) => {
                                                    if (record.dateRow) {
                                                        return null
                                                    } else {
                                                        return !expanded ? (
                                                            <img style={{ cursor: 'pointer' }} width='24px' height='24px' src={ChevronDown} onClick={e => {
                                                                // this.getRowDetail(record)
                                                                onExpand(record, e)
                                                            }} />
                                                        ) : (
                                                            <img style={{ cursor: 'pointer' }} width='24px' height='24px' src={ChevronUp} onClick={e => onExpand(record, e)} />
                                                        )
                                                    }
                                                },
                                                defaultExpandAllRows: true,
                                            }} />
                                    </>
                                }

                                <LabeledInput
                                    label="Reason"
                                    value={reason}
                                    // placeholder='Adding a reason is optional if you are approving the item'
                                    optional
                                    onChange={(e) => this.setState({ reason: e.target.value })}
                                    error={reasonError}
                                    errorMessage="Please enter a reason for rejecting this approval" />

                                <Flex start style={{ marginTop: 24 }} centerHorizontally>
                                    <Button permtype="BusinessBanking.Payments" margin='0 24px 0 0' solid loading={loadingApprove} onClick={() => this.handleApprove(selectedApproval)} text="Approve" />
                                    <TextButton permtype="BusinessBanking.Payments" loading={loadingReject} onClick={() => this.handleReject(selectedApproval)} text="Reject" />
                                </Flex>
                            </FlexColumn>
                        }
                    </Flex>
                </Modal >
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer,
        bankingStore: state.bankingAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)))