import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'

import { Flex, FlexColumn } from '../../../Reusable/Container'
import { Text } from '../../../Reusable/Refresh/Text'
import { Image } from '../../../Reusable/Image'
import RejectedIcon from '../../../../Images/rejected.png'
import { CardContainer } from '../../../Reusable/Container'
import Quote from '../../../../Images/quote.png'

import BankIcon from '../../../../Images/bank-icon/Banking.png';
import Capital from "../../../../Images/capital-plus.png"
import ThumbsUp from '../../../../Images/thumbs-up.png';
import Clock from '../../../../Images/Clock-grad.png';
import Pager from '../../../Reusable/Pager'
import { Button } from '../../../Reusable/Refresh/Button';
import { Skeleton, Space } from 'antd';
import { apiPOSTReq } from '../../../../Utils/api'
import { addDataToStore, ONB_SAVE_DATA, UAM_SAVE_BUSINESS, REMOTE_SAVE_DATA, SAVE_DATA } from '../../../../Actions/actions'
import { camelCaseToPascalCase } from '../../../../Utils/util'
import environment from '../../../../environment'
import HelloSign from "hellosign-embedded";
import { Modal } from 'antd';
import { ErrorAlert } from '../../../Reusable/Alert'

import ArrowRight from '../../../../Images/arrow-white-right.png'

class CreditAppStatus extends Component {

    state = {
        page: 0,
        loanApplication: this.props.loanApplication || {},
        signatureStatus: false,
        gnplLow: 0,
        gnplHigh: 10001,
        preSignedContract: false
    }

    componentDidMount() {
        this.setState({ loading: true });
        if (this.props.isCreditPlus) {
            setTimeout(() => {
                // Fetch on refresh page
                this.fetchContract();
                this.fetchBusinessAttributes();
            }, 2000);
        }
        else {
            // Fetch on refresh page
            this.fetchContract();
            this.fetchBusinessAttributes();
        }
    }

    fetchBusinessAttributes = () => {
        var body = {
            "clientBusinessId": ''
        };

        this.setState({ attributesLoading: true });
        // apiPOSTReq(`${environment.opsBaseUrl}/ops/fetchBusinessAttributes`, {}, body, (err, resp) => {
        apiPOSTReq(`${environment.opsBaseUrl}/ops/fetchBusinessAttributes`, null, body, (err, resp) => {
            try {
                if (err) throw Error(err);
                const data = resp || {};
                if (data.result) {
                    var lineSetup = data.businessAttributes.filter(x => x.name == "GNPLEnableBankingFlow");
                    if (lineSetup.length > 0) {
                        var attribute = lineSetup[0];
                        var additionalParameterReference = attribute.additionalParameterReference;
                        var parameters = additionalParameterReference.parameters;
                        var low = parseInt(parameters[0].value);
                        var high = parseInt(parameters[1].value);
                        this.setState({ gnplLow: low, gnplHigh: high })
                    }
                } else {
                    throw Error(data.responseMessage || data.error);
                }
            } catch (error) {
                console.log("/generateDebtSchedule err", error, resp);
            }
        });
    }

    closePreview = () => {
        this.setState({ contractSigned: false });
    };

    fetchContract = () => {
        this.setState({ loading: true })
        if (this.props.location == null) {
            const header = {
                AionCurrentBiz: this.props.loanApplication.businessId
            }
            apiPOSTReq(`${environment.lsmBaseUrl}/lsm/contract/getContract`, header, { "ApplicationId": this.props.loanApplication.applicationId }, (err, resp) => {
                try {
                    const response = resp || {};
                    if (response.result) {

                        this.setState({ loading: false })
                        const { contracts } = response
                        const { promisor } = contracts
                        this.setState({ contractToBeSent: false })
                        this.setState({ contracts: contracts, isPrimaryApplicant: contracts.primaryApplicant, promisor: promisor })

                        const signatureStatus = contracts.promisorSignatureStatus
                        if (signatureStatus == "Completed") {
                            this.setState({ signatureStatus: true })
                        }

                        this.fetchBusinessData()
                    }
                    else {
                        this.setState({ loading: false })
                        this.setState({ contractToBeSent: true })
                    }
                }
                catch (error) {
                    this.setState({ loading: false })
                    console.log("ERRR fetching contract", error, err, resp);
                }

            })
        }
        else {
            const urlStr = this.props.location.search.replace('?', '')
            const ref = (new URLSearchParams(urlStr).get('ref') || '')

            apiPOSTReq(`${environment.lsmBaseUrl}/lsm/contract/getContract`, {}, { referenceNumber: ref }, (err, resp) => {
                try {
                    const response = resp || {};
                    if (response.result) {
                        this.setState({ loading: false })
                        const { contracts } = response
                        this.setState({ contracts: contracts, isPrimaryApplicant: true, loanApplication: { underwritingStatus: "approved" } })

                        const signatureStatus = contracts.promisorSignatureStatus
                        if (signatureStatus == "Completed") {
                            this.setState({ signatureStatus: true })
                        }
                    }
                }
                catch (error) {
                    this.setState({ loading: false })
                    console.log("ERRR fetching contract", error, err, resp);
                }

            })
        }


    }

    fetchBusinessData = () => {
        apiPOSTReq(`${environment.uamBaseUrl}/getBusinessOwnership`, {}, {}, (err, resp) => {
            try {
                if (err) throw Error(err);
                const data = resp || {};
                if (data.result) {
                    var business = data.business || {}
                    this.props.dispatch(addDataToStore(UAM_SAVE_BUSINESS, data));
                    this.props.dispatch(addDataToStore(ONB_SAVE_DATA, { userStatus: business.userStatus })); // track userStatus
                } else {
                    throw Error(data.responseMessage || data.error);
                }
            } catch (error) {
                console.log("/getBusiness err", error, resp);
            }
        });
    }

    letsGetStartedCreditPlus = () => {
        var { loanApplication, store } = this.props
        var { business, businessSubscriptionPlan } = store || {}
        var businessProfile = business.businessProfile || {};
        var body = {
            businessId: loanApplication.businessId,
            applicationId: loanApplication.applicationId,
            "applicationName": "TermLoans"
        }
        this.setState({ isBankAppLoading: true })
        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/lineSetup`, {}, body, (err, resp) => {
            try {
                if (err) throw Error(err);
                const data = resp || {};
                this.props.dispatch(addDataToStore(SAVE_DATA, { loanApplication: loanApplication })); // track userStatus
                this.props.dispatch(addDataToStore(ONB_SAVE_DATA, { loanApplication: loanApplication })); // track userStatus
                this.props.dispatch(addDataToStore(ONB_SAVE_DATA, {
                    businessUniqueKey: business.Id,
                    personalInfo: businessProfile.primaryApplicantInfo || {},
                    coOwnersInfo: businessProfile.coOwnerApplicantInfo || [],
                    signersInfo: businessProfile.signerInfo || [],
                    businessInfo: businessProfile.businessInfo,
                    contractOwnerApplicantInfo: businessProfile.contractOwnerApplicantInfo,
                    ...business
                }));
                if (resp.result) {
                    apiPOSTReq(`${environment.lsmBaseUrl}/lsm/activateCreditLine`, {}, body, (err, resp) => {
                        try {
                            if (err) throw Error(err);
                            const data = resp || {};
                            if (resp.result) {
                                businessSubscriptionPlan = businessSubscriptionPlan || {}
                                const currentPlan = (businessSubscriptionPlan || {}).currentPlan || '';
                                this.fetchBusinessData();
                                if (currentPlan == 'Launch') {
                                    loanApplication.productType == 'RLOC' ? this.changePlan("Growth") : this.changePlan("Essentials");
                                }
                                else if (currentPlan == 'Essentials') {
                                    if (loanApplication.productType == 'RLOC') {
                                        this.changePlan("Growth");
                                    }
                                    else {
                                        this.addApplicationAccess();
                                    }
                                }
                                else {
                                    this.addApplicationAccess();
                                }
                            } else {
                                this.setState({ isEnableAccessLoading: false })
                                ErrorAlert({ description: "Sorry, we had trouble processing your request. Please try again." });
                            }
                        } catch (error) {
                            this.setState({ isEnableAccessLoading: false })
                            ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
                            console.log("/getBusiness err", error, resp);
                        }
                    });
                }
            } catch (error) {
                console.log("/getBusiness err", error, resp);
            }
        });
    }

    addApplicationAccess = () => {
        var { loanApplication } = this.props
        var body = {
            businessId: loanApplication.businessId,
            applicationId: loanApplication.applicationId,
            applicationName: "TermLoans"
        }
        apiPOSTReq(`${environment.uamBaseUrl}/aion/system/addApplicationAccess`, {}, body, (err, resp) => {
            this.setState({ isEnableAccessLoading: false });
            try {
                if (err) throw Error(err);
                const data = resp || {};
                if (data.result) {
                    this.props.closeCreditPlus();
                } else {
                    this.setState({ isEnableAccessLoading: false })
                    ErrorAlert({ description: "Sorry, we had trouble processing your request. Please try again." });
                }
            } catch (error) {
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
                this.setState({ isEnableAccessLoading: false })
                console.log("/getBusiness err", error, resp);
            }
        });
    }

    changePlan = (planName) => {
        var { loanApplication } = this.props
        var body = {
            businessId: loanApplication.businessId,
            subscriptionPlanName: planName,
            userEmail: loanApplication.emailAddress,
        }

        apiPOSTReq(`${environment.uamBaseUrl}/business/admin/changeSubscription`, {}, body, (err, response) => {
            try {
                if (err) throw Error(err);
                const data = response || {};
                if (data.result) {
                    this.addApplicationAccess();
                }
                else {
                    this.setState({ isEnableAccessLoading: false })
                    ErrorAlert({ description: "Sorry, we had trouble processing your request. Please try again." });
                }
            }
            catch (error) {
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
                console.log("/getBusiness err", error, response);
            }
        });
    }

    onStart = () => {
        var { loanApplication, store } = this.props
        var { business } = store || {}
        var businessProfile = business.businessProfile || {};
        var body = {
            businessId: loanApplication.businessId,
            applicationId: loanApplication.applicationId
        }
        this.setState({ isBankAppLoading: true })
        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/lineSetup`, {}, body, (err, resp) => {

            try {
                if (err) throw Error(err);
                const data = resp || {};
                this.props.dispatch(addDataToStore(SAVE_DATA, { loanApplication: loanApplication })); // track userStatus
                this.props.dispatch(addDataToStore(ONB_SAVE_DATA, { loanApplication: loanApplication })); // track userStatus
                this.props.dispatch(addDataToStore(ONB_SAVE_DATA, {
                    businessUniqueKey: business.Id,
                    personalInfo: businessProfile.primaryApplicantInfo || {},
                    coOwnersInfo: businessProfile.coOwnerApplicantInfo || [],
                    signersInfo: businessProfile.signerInfo || [],
                    businessInfo: businessProfile.businessInfo,
                    contractOwnerApplicantInfo: businessProfile.contractOwnerApplicantInfo,
                    ...business
                }));
                if (resp.result == true) {
                    apiPOSTReq(`${environment.lsmBaseUrl}/lsm/enableApplicationPage`, {}, body, (err, res) => {

                        try {
                            if (err) throw Error(err);
                            const data = res || {};
                            if (res.result == true) {
                                window.location.href = "/apply";
                            } else if (res.result == false) {
                                ErrorAlert({ description: "Sorry, we had trouble processing your request. Please try again." });
                                this.setState({ isBankAppLoading: false })
                                console.log("/getBusiness err", err, resp);
                            }

                            // track userStatus
                        } catch (error) {
                            ErrorAlert({ description: "Sorry, we had trouble processing your request. Please try again." });
                            this.setState({ isBankAppLoading: false })
                            console.log("/getBusiness err", error, resp);
                        }
                    });
                } else if (resp.result == false) {
                    this.setState({ isBankAppLoading: false })

                    ErrorAlert({ description: "Sorry, we had trouble processing your request. Please try again." });
                    console.log("/getBusiness err", err, resp);
                }

                // window.location.href="/apply/credit/banking"
                // track userStatus
            } catch (error) {
                console.log("/getBusiness err", error, resp);
            }
        });

    }

    openSignature = () => {
        this.setState({ isSignLoading: true })
        const contracts = this.state.contracts
        const header = {
            AionCurrentBiz: contracts.businessId
        }

        const body = {
            "BusinessId": contracts.businessId,
            "contracts": contracts
        }
        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/contract/getClientSignature`, header, body, (err, resp) => {
            try {
                const response = resp || {};
                console.log("response", response)
                if (response.result) {
                    const embeddedUrl = response.helloSignEmbeddedUrl
                    this.setState({ embeddedUrl: embeddedUrl })

                    const client = new HelloSign({
                        clientId: `${environment.hellosignClientKey}`
                    });

                    client.open(this.state.embeddedUrl, {
                        testMode: environment.isSandbox
                    });

                    client.on('sign', () => {
                        console.log('The document has been signed!');
                        if (contracts.contractSigner == "Pre-Signed") {
                            const headers = {
                                AionCurrentBiz: contracts.businessId
                            }

                            const body = {
                                "BusinessId": contracts.businessId,
                                "contracts": contracts
                            }

                            apiPOSTReq(`${environment.lsmBaseUrl}/lsm/contract/makeActiveContract`, headers, body, (err, resp) => {
                                try {
                                    const response = resp || {}
                                    if (response.result) {
                                        this.setState({ preSignedContract: true, signatureStatus: true, isSignLoading: false })
                                    }
                                    else {
                                        this.setState({ signatureStatus: true, isSignLoading: false })
                                    }
                                }
                                catch (error) {
                                    this.setState({ signatureStatus: true, isSignLoading: false })
                                    console.log("ERRR sending email", error, err, resp);
                                }
                            })
                        }
                        else {
                            this.setState({ signatureStatus: true, isSignLoading: false })
                        }
                    });
                }
                else {
                    this.setState({ isSignLoading: false })
                    if (contracts.contractSignatureStatus == "Cancelled") {
                        ErrorAlert({ description: "This contract is no longer valid" })
                    } else {
                        this.setState({ isSignLoading: false })
                        ErrorAlert({ description: "You have already signed the contract." })
                    }
                }
            }
            catch (error) {
                console.log("ERRR loading contract", error, err, resp);
            }

        })
    }

    render() {
        var { creditStore, theme, proposalTerms, isCreditPlus } = this.props
        var { page, isSignLoading, isPrimaryApplicant, loanApplication, signatureStatus, loading, promisor, contractToBeSent, isBankAppLoading, contracts, gnplHigh, gnplLow } = this.state
        var status = (loanApplication.underwritingStatus || '').toLowerCase()
        var contractStatus = (loanApplication.contracting || '').toLowerCase();
        var icon = <></>
        var title = ''
        var desc = <></>
        var cta = null
        var marginTop = "";
        promisor = promisor || {}
        contracts = contracts || {}
        var amount = contracts.commitmentAmount || ''

        const formattedAmount = amount.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
        });

        const fullName = (promisor.promisorMiddleName ? `${promisor.promisorFirstName} ${promisor.promisorMiddleName} ${promisor.promisorFirstName}` : `${promisor.promisorFirstName} ${promisor.promisorLastName}`) || '';


        if (contractStatus != "contract signed" && this.state.preSignedContract != true && signatureStatus == true) {
            icon = Clock
            title = 'We are wrapping up the agreement signatures'
            desc = <>
                <div style={{ width: '80px', height: '1px', background: 'radial-gradient(83.75% 38.54% at 16.25% 86.88%, #1199FF 0%, #1A8AFA 100%)' }} />
                <Text weight="400" size="16px" style={{ textAlign: 'center' }}>You are all set. The Aion team is signing your agreement. You will receive an email to get started on the next steps.</Text>
            </>
            marginTop = "220px"
        }
        else if (isCreditPlus && (contractStatus == "contract signed" || this.state.preSignedContract) && Object.keys(contracts).length > 0 && (gnplLow < contracts.commitmentAmount && contracts.commitmentAmount < gnplHigh) && proposalTerms.productType == "GNPL") {
            icon = Capital
            title = 'Congratulations!'
            desc = <>
                <Text weight="400" size="20px" spacing="0.8px" style={{ maxWidth: '320px', textAlign: 'center', lineHeight: "32px" }}>You’re all set. Start now by clicking the button below.</Text>
                <div style={{ width: '80px', height: '1px', background: 'radial-gradient(83.75% 38.54% at 16.25% 86.88%, #1199FF 0%, #1A8AFA 100%)' }} />

                <Text weight="400" size="16px" style={{ textAlign: 'center' }}>If we have any questions we’ll reach out to you at <a>{loanApplication.emailAddress}</a>.
                </Text>
            </>
            var cta = <Button loading={isBankAppLoading} solid text='LET’S GET STARTED' onClick={() => {
                this.fetchBusinessData();
                this.letsGetStartedCreditPlus()
            }} rightIcon={<Image src={ArrowRight} />} />
            marginTop = "220px"
        }
        else if ((contractStatus == "contract signed" || this.state.preSignedContract) && Object.keys(contracts).length > 0 && (gnplLow < contracts.commitmentAmount && contracts.commitmentAmount < gnplHigh) && proposalTerms.productType == "GNPL") {
            icon = BankIcon
            title = 'Open a bank account to get funded'
            desc = <>
                <Text weight="400" size="20px" spacing="0.8px" style={{ maxWidth: '320px', textAlign: 'center', lineHeight: "32px" }}>Easy-to-use commercial grade digital banking. Faster money movement, better service.</Text>
                <div style={{ width: '80px', height: '1px', background: 'radial-gradient(83.75% 38.54% at 16.25% 86.88%, #1199FF 0%, #1A8AFA 100%)' }} />

                <Text weight="400" size="16px" style={{ textAlign: 'center' }}>If we have any questions we’ll reach out to you at <a>{loanApplication.emailAddress}</a>.
                </Text>
            </>
            var cta = <Button loading={isBankAppLoading} solid text='LET’S GET STARTED' onClick={() => {
                this.fetchBusinessData();
                this.onStart()
            }} rightIcon={<Image src={ArrowRight} />} />
            marginTop = "220px"
        } else {
            switch (status) {
                case "auto approved":
                case "approved":
                    if (contractToBeSent == true) {
                        icon = ThumbsUp
                        title = 'Congratulations!'
                        desc = <>
                            <div style={{ width: '80px', height: '1px', background: 'radial-gradient(83.75% 38.54% at 16.25% 86.88%, #1199FF 0%, #1A8AFA 100%)' }} />
                            <Text weight="400" size="16px" style={{ textAlign: 'center', maxWidth: '250px' }}>You have been approved. We are currently finalizing your agreement. Please reach out to us at <a href="mailto:contact@aionfi.com">contact@aionfi.com</a> in case of any questions.</Text>
                        </>
                        marginTop = "220px"
                    } else {
                        icon = ThumbsUp
                        title = 'Congratulations!'
                        desc = <>
                            <Text weight="400" size="15px" spacing="0.04em" style={{ maxWidth: '320px', textAlign: 'center' }}>You have been approved. Manage your business finances automagically with Aion.</Text>
                            <Text weight="400" size="30px" spacing="0.04em" style={{ maxWidth: '320px', textAlign: 'center', fontWeight: 'bold' }}>{formattedAmount}</Text>
                            <div style={{ width: '80px', height: '1px', background: 'radial-gradient(83.75% 38.54% at 16.25% 86.88%, #1199FF 0%, #1A8AFA 100%)' }} />

                            {!isPrimaryApplicant ? <Text weight="400" size="16px" style={{ textAlign: 'center' }}>Your agreement has been sent to {fullName} ({promisor.promisorEmail}) for their signature.</Text>

                                :
                                <div style={{ textAlign: 'center', marginBottom: "20px" }}>
                                    <Text weight="400" size="10px" style={{ textAlign: 'center', marginBottom: "100px" }}>Please read the agreement for more information.</Text>
                                    <div style={{ marginTop: "20px" }}>
                                        <Space size="middle" style={{ justifyContent: "center" }}>
                                            <Button
                                                solid
                                                primary
                                                loading={isSignLoading}
                                                // style={{ marginLeft: '30pt', float: 'right' }}
                                                onClick={() => { this.openSignature() }}
                                                text='REVIEW AND SIGN AGREEMENT'
                                            />
                                        </Space>
                                    </div>
                                </div>
                            }
                        </>
                    }

                    marginTop = "220px"
                    break
                case "rejected":
                    icon = RejectedIcon
                    title = 'Application declined'
                    desc = <Text size="16px" style={{ maxWidth: '575px', textAlign: 'center' }}>We have carefully assessed your information and, at this time, we are<br />unable to approve your request for financing.</Text>
                    marginTop = "30%"
                    break
                case "under review":
                    window.location.href = "/apply/credit";
                    break;
                default:
                    break
            }
        }

        return (
            <FlexColumn className={((status === 'approved') || (status === 'auto approved') || (contractStatus === "contract signed"))
                && 'application-approved-congrats-back'} start fullWidth fullHeight style={{ position: 'relative' }}>
                <Skeleton loading={loading} active title={false} paragraph={{ rows: 3 }}>
                    <FlexColumn start centerHorizontally>
                        <div style={{ width: '624px', zIndex: 5, }}>
                            <FlexColumn center gap='24px' style={{ marginTop: marginTop }}>
                                <img height='48px' width='48px' src={icon} />
                                <Text weight="500" size='28px' height='40px' style={{ textAlign: 'center', maxWidth: '400px' }}>{title}</Text>
                                {desc}
                                {cta}
                            </FlexColumn>
                        </div>
                    </FlexColumn>
                    {
                        (status === 'approved' || status === 'auto approved') &&
                        <FlexColumn start centerHorizontally style={{ marginBottom: "96px" }}>
                            <CardContainer style={{ width: '624px', marginTop: 150, marginBottom: 24, zIndex: 5, }}>
                                {(() => {
                                    switch (page) {
                                        case 0:
                                            return <Text>As a business owner, time is of the essence. Aion allows me to manage my business finances on the go. And when I need assistance, the customer service team is prompt and helpful in resolving any issues.</Text>
                                        case 1:
                                            return <Text>In the early days of Purely Elizabeth, we needed to keep up with increasing demand. Aion provided flexible, non-dilutive funding that grew with our business and helped us scale.</Text>
                                        case 2:
                                            return <Text>Aion is the banking partner we trust for competitive rates, transparency to our clients, real-time support, and efficient flow of funding.                                       </Text>
                                        default:
                                            return null
                                    }
                                })()}

                                <Flex between style={{ marginTop: 12, width: '100%' }}>
                                    <Flex start>
                                        {/* <img width='48px' height='48px' src={Profile1} /> */}
                                        <FlexColumn between style={{ height: 48 }}>
                                            {(() => {
                                                switch (page) {
                                                    case 0:
                                                        return <>
                                                            <Text color='#1199FF'>Robert Sims</Text>
                                                            <Text size='12px' height='20px' color='#666666'>CEO, Hoosier Inc.</Text>
                                                        </>
                                                    case 1:
                                                        return <>
                                                            <Text color='#1199FF'>Mark Freeburg</Text>
                                                            <Text size='12px' height='20px' color='#666666'>CFO, Purely Elizabeth</Text>
                                                        </>
                                                    case 2:
                                                        return <>
                                                            <Text color='#1199FF'>Drew Marheine</Text>
                                                            <Text size='12px' height='20px' color='#666666'>Owner, iDeploy LLC</Text>
                                                        </>
                                                    default:
                                                        return null
                                                }
                                            })()}
                                        </FlexColumn>
                                    </Flex>

                                    <img width='48px' height='48px' src={Quote} />
                                </Flex>
                            </CardContainer>
                            <Pager
                                pages={3}
                                page={page}
                                next={() => { }}
                                previous={() => { }}
                                noArrows
                                dotClickable
                                onClick={(i) => this.setState({ "page": i })}
                            />
                        </FlexColumn>
                    }
                </Skeleton>
            </FlexColumn>
        );
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer,
        creditStore: state.creditAppReducer,
        onboardingStore: state.onboardingAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(CreditAppStatus));