import React, { useState } from 'react'
import { message } from 'antd'
import moment from 'moment'

import { Text } from '../../Reusable/Text'
import { Image } from '../../Reusable/Image'
import { FlexColumn } from '../../Reusable/Container'
import { Button } from '../../Reusable/Refresh/Button'
import { LabeledInput } from '../../Reusable/Refresh/Input'
import environment from '../../../environment'
import { apiPOSTReq } from '../../../Utils/api'
import { ErrorAlert } from '../../Reusable/Alert'
import Activate from '../../../Images/activate.png'
import { TextButton } from '../../Reusable/Button'

const ValidateCard = (props) => {
    const [loading, setLoading] = useState(false)

    const { recipient, onActivated, onCancel } = props
    const { nameOnAccount, nickName } = recipient

    const deactivate = () => {
        var body = {
            ObjectId: recipient.objectId
        }
        setLoading(true)
        console.log("/activateCounterparty body", body)
        apiPOSTReq(`${environment.bbBaseUrl}/bb/activateCounterparty`, {}, body, (err, resp) => {
            setLoading(false)
            try {
                if (err) throw Error(err)
                const data = resp || {}
                if (data.result) {
                    console.log("/activateCounterparty resp", resp)
                    message.success(`${nickName || nameOnAccount} activated!`)
                    onActivated()
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/deactivateCounterparty err", resp, error)
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." })
            }
        })
    }

    return (
        <FlexColumn center >
            <Image margin='24px 0 12px' width='48px' height='48px' src={Activate} />
            <Text size='28px' height='40px' weight='500' style={{ marginBottom: 24, marginTop: 8 }}>Activate Recipient?</Text>
            <Text center margin='0 12px 24px'>Activate the recipient to be able to send payments too and make edits to them</Text>
            <Button
                solid
                text='YES, ACTIVATE'
                onClick={() => deactivate()}
                loading={loading} />
            <TextButton onClick={onCancel} margin='24px 0 24px' text='CANCEL' />
        </FlexColumn>
    )
}

export default ValidateCard