import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import {
    ConfigProvider,
    Empty,
    Skeleton,
    Table,
    Input,
    Button,
    Modal,
    Menu,
    Dropdown,
    Space
} from 'antd';

import PageHeader from "../../Reusable/PageHeader";
import { Flex, FlexColumn, Container } from '../../Reusable/Container';
import { ErrorAlert } from '../../Reusable/Alert';
import ReconcileModal from "./ReconcileModal";
import { TextButton } from '../../Reusable/Button';
import { BankAccountTag } from '../../Reusable/Tag';
import { saveAs } from 'file-saver';

// Util
import environment from '../../../environment';
import { apiGET, apiPOSTReq, getPreSignedS3Url } from '../../../Utils/api';
import { getBBAccountName, getBPAccountsWithAccess, toCurrency, capitalizeWords } from '../../../Utils/util';
import { LabeledInput, StyledInput, StyledDivider, GradientDivider } from '../../Reusable/Input';
import { RECEIVABLES_SAVE_DATA, addDataToStore } from '../../../Actions/actions';
import { Text, Tag } from '../../Reusable/Text';
import { DownOutlined, ArrowRightOutlined, CheckCircleOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Divider } from 'rc-menu';
import { withTheme } from 'styled-components';

import MatchInvoices from '../../../Images/match-invoices.png';
import ModalClose from '../../../Images/modal-close.png'

const { Search } = Input;

class Index extends Component {
    state = {
        loading: true,
        customize: true,
        customersLoaded: false,
        transactionsLoaded: false,
        transactions: [],
        showReconcileModal: false,
        modalWidth: 700,
        selectedTab: "Payments",
        reconciledPaymentPagination: {
            current: 1,
            pageSize: 100,
        },
        pagination: {
            current: 1,
            pageSize: 100,
        },
        searchPagination: {
            current: 1,
            pageSize: 500,
        },
        expandedRowKeys: [],
    };

    componentDidMount() {
        var accounts = getBPAccountsWithAccess("Transactions");
        console.log("accounts", accounts)
        var accountId = (accounts[0] || {}).AccountId;
        this.setState({ accounts: accounts, accountId: accountId });
        // this.txnSearch({ accountId: accountId, pagination: this.state.pagination });
        this.fetchCustomers({
            pagination: {
                current: 1,
                pageSize: 500
            }
        });

        this.getAllMatchedPayments()
    }

    customizeRenderEmpty = () => (
        <Empty
            imageStyle={{
                height: 60,
            }}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
                <span>
                    No payments found
                </span>
            }
        />
    )

    getAllMatchedPayments() {
        apiPOSTReq(`${environment.iveBaseUrl}/ive/invoice/getAllMatchedPayments`, {}, {}, (err, resp) => {
            try {
                const data = resp || {}
                console.log("/ive/invoice/getAllMatchedPayments", data)
                if (data.result) {
                    this.setState({ loading: false, matchedPayments: data.matchedPayments })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                this.setState({ loading: false })
                console.log("ERRR getAllMatchedPayments", error, resp);
                ErrorAlert({ description: error.message })
            }
        })
    }

    fetchCustomers(options) {
        const { pagination } = options

        const body = {
            "BusinessId": this.props.aionStore.BusinessUniqueKey,
            "size": pagination.pageSize,
            "page": pagination.current - 1
        }

        apiPOSTReq(`${environment.iveBaseUrl}/ive/bc/getActiveCustomers`, {}, body, (err, resp) => {
            try {
                const data = resp;
                // //console.log("/getActiveCustomers", data)
                if (data.result) {
                    this.props.dispatch(addDataToStore(RECEIVABLES_SAVE_DATA, { ActiveCustomers: data.customers }))
                    this.setState({
                        customersLoaded: true,
                        customerPagination: {
                            ...pagination,
                            total: data.count
                        },
                        customers: data.customers,
                    })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                //console.log("ERRR getActiveCustomers", error, resp);
            }
        })
    }

    handleTabChange = (selectedTab) => {
        const { accountId } = this.state;
        this.setState({ selectedTab: selectedTab });
        if (selectedTab == "Payments") this.txnSearch({ accountId: accountId, pagination: this.state.pagination });
        else if (selectedTab == "Reconciled") this.fetchReconciledTransactions({ accountId: accountId, pagination: this.state.reconciledPaymentPagination });
    }

    handleMatchPayment = (qbo, payment) => {
        this.props.history.push({
            pathname: '/receivables/invoice-payments/match-invoice',
            state: { qbo, payment }
        })
    }

    switchAccount = (accountId) => {
        this.setState({ accountId: accountId });
        this.txnSearch({ accountId: accountId, pagination: this.state.pagination });
    }

    txnSearch = (options) => {
        let { accountId, pagination, searchTerm } = options;
        const body = {
            "size": pagination.pageSize,
            "page": pagination.current - 1,
            accountId,
        }

        if (searchTerm && parseFloat(searchTerm)) {
            pagination = this.state.searchPagination;
            body.size = pagination.pageSize;
            body.page = pagination.current - 1;
            body.searchAmt = parseFloat(searchTerm)
        } else if (searchTerm) {
            pagination = this.state.searchPagination;
            body.size = pagination.pageSize;
            body.page = pagination.current - 1;
            body.searchStr = searchTerm
        }
        console.log("/bb/getIncomingTransactions", body);
        this.setState({ loading: true });
        apiPOSTReq(`${environment.bbBaseUrl}/bb/getIncomingTransactions`, {}, body, (err, resp) => {
            this.setState({ loading: false });
            try {
                const data = resp;
                console.log("/bb/getIncomingTransactions transactionsList", data)
                if (data.result) {
                    this.setState({
                        transactions: data.transactionsList,
                        transactionsLoaded: true,
                        pagination: {
                            ...pagination,
                            total: data.transactionsCount
                        }
                    })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getContractors", error, err, resp);
                ErrorAlert({ description: error.message })
            }
        })
    }

    handleTableChange = (pagination) => {
        if (this.state.selectedTab == "Payments") this.txnSearch({ accountId: this.state.accountId, pagination });
        else if (this.state.selectedTab == "Reconciled") this.fetchReconciledTransactions({ accountId: this.state.accountId, pagination });
    }

    onSearchChange = (e) => {
        this.setState({ txnSearch: e.target.value });

        const pagination = {
            current: 1,
            pageSize: 100
        }
        this.txnSearch({ accountId: this.state.accountId, pagination, searchTerm: e.target.value });
    }

    downloadInvoice = (invoice) => {
        var invoiceDoc = (invoice.attachments || []).find(item => item.type == "InvoiceDoc")
        if (invoiceDoc) {
            getPreSignedS3Url({ url: invoiceDoc.uri }, (err, preSignedUrl) => {
                invoiceDoc.preSignedUrl = preSignedUrl || "";
                saveAs(invoiceDoc.preSignedUrl, `Invoice ${invoice.docNumber}`)
            });
        } else {
            ErrorAlert({ description: "No document found for this invoice" });
        }
    }

    render() {
        const { customize, transactions, transactionsLoaded, customersLoaded, showReconcileModal, modalWidth, paymentDate, totalPaymentAmount, accountId, selectedTab, txnRecord, reconciledTransactions, matchedPayments, pagination, loading, expandedRowKeys } = this.state;
        const { theme } = this.props;

        const AccountDropdownMenu = () => {
            var accounts = this.state.accounts || [];
            var menuItems = [];
            accounts.forEach(account => {
                menuItems.push(
                    <Menu.Item key={account.AccountId}>
                        <a onClick={() => this.switchAccount(account.AccountId)}>
                            {getBBAccountName(account)}
                        </a>
                    </Menu.Item>
                )
            })

            const menu = <Menu style={{ maxWidth: "250px" }} selectedKeys={[accountId]}>{menuItems}</Menu>;
            return (
                <Flex style={{ justifyContent: "start", alignItems: "center", marginTop: 5 }}>
                    <Dropdown overlay={menu}>
                        <a onClick={e => e.preventDefault()}>
                            <Text color={theme.colors.secondary3}>{getBBAccountName(accounts.find(item => item.AccountId == accountId))} <DownOutlined /></Text>
                        </a>
                    </Dropdown>
                </Flex>
            );
        }

        var columns = [
            {
                title: 'Date',
                dataIndex: 'date',
                key: 'date',
                render: date => <Text color="#7384AA">{(moment(date, "YYYY-MM-DD").format('MMM Do, YYYY'))}</Text>,
                width: '120pt'
            },
            {
                title: 'Description',
                dataIndex: 'customerName',
                key: 'customerName',
                render: (customerName, payment) => <Text color="#444444">{customerName ? customerName : payment.description}</Text>,
                width: '30%'
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
                key: 'amount',
                render: amt => <Text weight="600" color="#3EAD3C">$ {toCurrency(amt)}</Text>,
                align: 'right'
            },
            // {
            //     title: 'Type',
            //     dataIndex: 'transactionType',
            //     key: 'transactionType',
            //     render: transactionType => <Tag textcolor="#7384AA" background="#F8F8F8" height="32px" weight={400} textsize="16px">{transactionType}</Tag>,
            //     align: 'left'
            // },
            {
                title: 'Account',
                dataIndex: 'mask',
                key: 'mask',
                render: (mask, payment) => {
                    return <Space wrap><BankAccountTag bank='aion' type={capitalizeWords(payment.accountType)} mask={payment.mask} /></Space>
                },
                align: 'left'
            },
            {
                title: '',
                key: 'action',
                align: 'center',
                render: (text, record) => (
                    <Flex center>
                        <CheckCircleOutlined style={{ fontSize: "20px", color: "#3EAD3C" }} />
                        <a style={{ height: "16px" }} onClick={() => {
                            if (!expandedRowKeys.includes(record.transactionId)) {
                                this.setState({ expandedRowKeys: [record.transactionId] })
                            } else {
                                this.setState({ expandedRowKeys: [] })
                            }
                        }}>
                            <DownOutlined style={{ fontSize: "16px", marginLeft: 24 }} />
                        </a>
                    </Flex>
                ),
            }
        ]

        return (
            <FlexColumn className='main-padding'>
                <PageHeader
                    titleText="Matched Payments"
                    subtitle="Invoice Payments"
                    desc="Payments matched with their respective invoices."
                />

                <Container shadow>
                    <ConfigProvider renderEmpty={customize && this.customizeRenderEmpty}>
                        <div className="config-provider">
                            <Skeleton rowKey="Id" loading={loading} active title={false} paragraph={{ rows: 4 }}>
                                <Text heading>All Matched Payments</Text>
                                <Search
                                    placeholder="Search Payments"
                                    size="large" allowClear
                                    onSearch={() => this.txnSearch({ accountId: this.state.accountId, pagination: this.state.pagination, searchTerm: this.state.txnSearch })}
                                    onChange={this.onSearchChange}
                                    style={{ marginBottom: '20px' }}
                                />
                                {
                                    transactions && transactions.length > 0 &&
                                    <Text color='#737387'>{`${transactions.length} Payments`}</Text>
                                }
                                <Table
                                    id="invoice-payments-table"
                                    tableLayout='auto'
                                    rowKey={'transactionId'}
                                    columns={columns}
                                    dataSource={matchedPayments}
                                    pagination={pagination}
                                    style={{ minHeight: "500px" }}
                                    onChange={this.handleTableChange}
                                    expandable={{
                                        expandedRowRender: payment => {
                                            return <FlexColumn style={{ paddingLeft: 32, paddingRight: 12 }}>
                                                {/* <Paragraph margin="0" labelSize="16px" labelWeight="500" color={this.props.theme.colors.systemGray}>INCOMING PAYMENT DETAILS</Paragraph>
                                            <Flex start> */}
                                                {/* <div>
                                                    <LabeledInput
                                                        type="read-only"
                                                        label="TRANSACTION NUMBER"
                                                        value={1234567890}
                                                        divider={false}
                                                    />
                                                </div> */}
                                                {/* <div>
                                                    <LabeledInput
                                                        type="read-only"
                                                        label="BANK DESCRIPTION"
                                                        value="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
                                                        divider={false}
                                                    />
                                                </div> */}
                                                {/* </Flex> */}
                                                <Text weight="500" color={this.props.theme.colors.systemGray}>MATCH DETAILS</Text>
                                                <div>
                                                    <LabeledInput
                                                        type="read-only"
                                                        label="MATCHED AMOUNT"
                                                        value={<Flex style={{ marginTop: 4 }} start>{`$ ${toCurrency(payment.amount)} (`}<span style={{ color: '#1199FF' }}>{payment.matched}%</span>{`)`}</Flex>}
                                                        divider={false}
                                                        fontSize="14px"
                                                    />
                                                </div>
                                                <div>
                                                    <LabeledInput
                                                        type="read-only"
                                                        label="MATCHED INVOICES"
                                                        value={payment.invoices ? <Flex style={{ marginTop: 8 }} start>{
                                                            payment.invoices.map(invoice => {
                                                                return <Tag
                                                                    padding='4px 8px'
                                                                    margin="0 8px 0 0"
                                                                    height="32px"
                                                                    weight={400}
                                                                    textsize="16px"
                                                                    background="#F8F8F8"
                                                                    tagpreset="#1199FF"
                                                                    onClick={() => this.downloadInvoice(invoice)}><FilePdfOutlined />{invoice.docNumber}</Tag>
                                                            })}
                                                        </Flex> : <Flex style={{ marginTop: 4 }} start>NONE</Flex>
                                                        }
                                                        divider={false}
                                                        nomargin
                                                        fontSize="14px"
                                                    />
                                                </div>
                                            </FlexColumn>
                                        },
                                        expandedRowKeys: expandedRowKeys,
                                        expandIconColumnIndex: -1, //columns.length: to show icon at the last column -1: to hide column
                                        columnWidth: "2px",
                                        indentSize: "20px",
                                    }}
                                />
                            </Skeleton>
                        </div>
                    </ConfigProvider>
                </Container>

                <Modal
                    visible={showReconcileModal}
                    footer={null}
                    closable={true}
                    width={modalWidth}
                    style={{ top: 20 }}
                    destroyOnClose={true}
                    onCancel={() => { this.setState({ showReconcileModal: false }) }}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <ReconcileModal
                        txnRecord={txnRecord}
                        totalPaymentAmount={totalPaymentAmount}
                        customers={this.state.customers}
                        paymentDate={paymentDate}
                        submitComplete={() => {
                            this.setState({ showReconcileModal: false });
                        }}
                        onWidthChange={(width) => this.setState({ modalWidth: width })}
                    />
                </Modal>
            </FlexColumn>
        );
    }
}

function mapStateToProps(state) {
    return {
        store: state.creditAppReducer,
        aionStore: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)));