import React, { Component } from 'react'
import { withTheme } from 'styled-components'
import moment from 'moment'

import { toAbsCurrency } from '../../../Utils/util'
import TableView from '../../Reusable/TableView'
import environment from '../../../environment'

class BillPaymentScheduledTable extends Component {

    render() {

        var { payments } = this.props

        var columns = [
            {
                title: 'Date',
                dataIndex: 'date',
                render: date => date
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
                render: amt => `$${toAbsCurrency(amt, 2)}`
            },
            {
                title: 'Status',
                dataIndex: 'status',
                render: status => {
                    if(status == "pending_approval") return "Pending Approval"
                    else return status
                },
                width: '220px'
            }
        ]
        
        return (
            <TableView
                id='bill-payments-table'
                tableLayout='auto'
                columns={columns}
                dataSource={payments}
                rowKey='paymentId'
                titleText={this.props.titleText || 'In Progress'}
                descText={this.props.descText || 'These payments are scheduled or pending approval'}
                borderView={this.props.borderView}
            />
        )
    }
}

export default withTheme(BillPaymentScheduledTable)