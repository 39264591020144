import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import styled, { withTheme } from 'styled-components';
import { Helmet } from "react-helmet";
import environment from '../../environment';
import _, { random } from 'underscore';

import { Space, Divider, Select, Modal } from 'antd'

// Util
import { apiGET } from '../../Utils/api';
import { getBase64DecodedStr, getBase64EncodedStr, isMobile, toCurrency, isTablet, formatPhoneTextV2, isSmallScreen } from '../../Utils/util';
import { getValidFullName, getNameObj2, isEmailValid, capitalizeWords } from '../../Utils/util';

// Actions
import { addDataToStore, addUsername, SAVE_DATA } from '../../Actions/actions';

// Components
import { Button, TextButton } from '../Reusable/Button';
import { Image } from '../Reusable/Image';
import Pager from '../Reusable/Pager'
import { Paragraph, Title, PageTitle, Text } from '../Reusable/Refresh/Text';
import { BrandedContainer } from '../Reusable/Container';
import { Flex, FlexColumn, SideBar, GradientSideBar, InputContainer, LightContainer, CardContainer } from '../Reusable/Container';
import { ErrorLabel, LabeledInput } from '../Reusable/Input';
import { StyledExtLink } from '../Reusable/Link';
import { ErrorAlert } from '../Reusable/Alert';
import { CheckCircleTwoTone, StarFilled } from '@ant-design/icons';
import QuoteIcon from '../../Images/icons8-quote-left-250.svg';
import UserImg from '../../Images/gnpl.png';
import AionOverlayImg from '../../Images/aion-wing-overlay.png';
import CoinStackImg from '../../Images/signup-img.png';
import Check from '../../Images/check-white.png';
import Quote from '../../Images/quote.png';
import MobileLanding from '../Reusable/MobileLanding';
import ModalClose from '../../Images/modal-close.png'
import AionLogo from '../../Images/aion-shadow.png'
import AionText from '../../Images/aion-logo-text.png'

const { Option } = Select;

const pricingMap = {
    "launch": {
        monthly: 0,
        yearly: 0
    },
    "launch+": {
        monthly: 0,
        yearly: 0
    },
    "essentials": {
        monthly: 0,
        yearly: 39
    },
    "growth": {
        monthly: 49,
        yearly: 99
    },
    "enterprise": {
        monthly: 399,
        yearly: 299
    }
}

const AionOverLay = styled.div`
    position: absolute;
    mix-blend-mode: overlay;
    opacity: 0.16;
`;


const Register = (props) => {
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState("");
    const [phone, setPhone] = useState();
    const [phoneCode, setPhoneCode] = useState({
        "name": "United States",
        "flag": "🇺🇸",
        "code": "US",
        "dial_code": "+1"
    });
    const [captchaVal, setCaptchaVal] = useState(null);
    const [spCheck, setSPCheck] = useState(true);
    const [referralCode, setReferralCode] = useState("")//useState(new URLSearchParams(props.location.search).get('referral'))
    const [plan, setPlan] = useState("Essentials")//useState((new URLSearchParams(props.location.search).get('plan') || "Launch").toLowerCase())
    const [billing, setBilling] = useState("monthly");
    const [onboardingType, setOnboardingType] = useState("banking");
    const [step, setStep] = useState(0);
    const [errorField, setErrorField] = useState();
    const [errorMessage, setErrorMessage] = useState("");
    const [showPwdOverlay, setShowPwdOverlay] = useState(false);
    const [showPhoneConfirmation, setShowPhoneConfirmation] = useState(false);
    const [page, setPage] = useState(0);
    const [refCodeValid, setRefCodeValid] = useState(false);
    const [refData, setRefData] = useState({})

    const defaultReferralCode = referralCode;

    useEffect(() => {
        var urlStr = props.location.search.replace('?', '');
        //Hubspot GNPL
        var pt = (new URLSearchParams(urlStr).get('pt') || '');
        var uReferral = new URLSearchParams(urlStr).get('referral') || '';
        var pfEmail = new URLSearchParams(urlStr).get('prefill_Email');
        console.log('pt', pt);
        if (pt != '') {
            urlStr = pt;
        }
        var urlParams = getBase64DecodedStr(urlStr);
        var dPlan = capitalizeWords(new URLSearchParams(urlParams).get('plan') || '') //.toLowerCase();
        var dReferral = new URLSearchParams(urlParams).get('referral');
        var dBilling = new URLSearchParams(urlParams).get('billing');
        var dType = new URLSearchParams(urlParams).get('type');
        // Give preference to encoded referral code over the plain text referral code
        const refCode = dReferral || uReferral;

        console.log("URL PARAMS decoder and encoder", dPlan, dType, pfEmail);

        if (!pricingMap[dPlan.toLowerCase()]) {
            ErrorAlert({ description: "Authorization required to access this page, please reach out to contact@aionfi.com", title: "Authorization Required", onOk: () => { window.location.href = "/" } });
        }
        setPlan(dPlan);
        setOnboardingType(dType);
        setReferralCode(refCode);
        if(pfEmail) setEmail(pfEmail);
        if (dBilling) setBilling(dBilling);

        if (refCode) {
            checkReferralCode(refCode, validCode => {
                if (validCode) {
                    setRefCodeValid(true)
                }
            })
        }

        // Set this class on page load to handle iframe issues on small screens
        document.body.classList.add('g-recaptcha-custom')

        // Remove class on leaving component
        return () => {
            document.body.classList.remove('g-recaptcha-custom')
        };
    }, [props.location.search]);

    const handleChange = (event) => {
        if (event.target.id == errorField) setErrorField(""); // reset
        switch (event.target.id) {
            case "email":
                setEmail(event.target.value.toLowerCase())
                break;
            case "password":
                setPassword(event.target.value)
                break;
            case "phone":
                setPhone((phoneCode.dial_code == '+1') ? formatPhoneTextV2(event.target.value) : event.target.value)
                break;
            case "firstName":
                setFirstName(capitalizeWords(event.target.value))
                break;
            case "lastName":
                setLastName(capitalizeWords(event.target.value))
                break;
            case "referralcode":
                setReferralCode(event.target.value)
                break;
            default:
                break;
        }
    }

    const handleSelect = (value, option) => {
        console.log("handleSelect", value, option)
        setPlan(value)
    }

    const checkReferralCode = (code, callback) => {
        var refCode = code || referralCode
        if (refCode === '') {
            setLoading(false)
            ErrorAlert({ description: "Please enter a referral code." });
            return;
        }
        apiGET(`/validate/referralcode?code=${refCode}`, {}, (error, resp) => {
            try {
                const data = resp.data;
                if (error || !data.Active) throw Error("Invalid code");
                setRefData(data);
                callback(true);
            } catch (error) {
                setLoading(false)
                ErrorAlert({ description: "We could not recognize this referral code, please enter a valid code. You can also skip this and proceed without a code." });
                console.log("Error", error);
                callback(false);
            }
        })
    }

    const setManualReviewRequired = (msg) => {
        console.log("setManualReviewRequired", msg)
        props.dispatch(addDataToStore(SAVE_DATA, { manualReviewRequired: true, manualReviewRequiredMsg: msg }));
    }

    const getPasswordValidations = () => {
        var validationArr = [];
        if ((password.length >= 8) && (password.length < 25)) validationArr.push(true)
        else validationArr.push(false)
        const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        validationArr.push(specialChars.test(password));
        validationArr.push(/^(?=.*[A-Z])/.test(password));
        validationArr.push(/^(?=.*[a-z])/.test(password));
        validationArr.push(/^(?=.*\d)/.test(password));
        return validationArr;
    }

    const autofill = () => {
        const fNames = ["Adam", "Alex", "Aaron", "Ben", "Carl", "Dan", "David", "Edward", "Fred", "Frank", "George", "Hal", "Hank", "Ike", "John", "Jack", "Joe", "Larry", "Monte", "Matthew", "Mark", "Nathan", "Otto", "Paul", "Peter", "Roger", "Roger", "Steve", "Thomas", "Tim", "Ty", "Victor", "Walter"];
        const lNames = ["Anderson", "Ashwoon", "Aikin", "Bateman", "Bongard", "Bowers", "Boyd", "Cannon", "Cast", "Deitz", "Dewalt", "Ebner", "Frick", "Hancock", "Haworth", "Hesch", "Hoffman", "Kassing", "Knutson", "Lawless", "Lawicki", "Mccord", "McCormack", "Miller", "Myers", "Nugent", "Ortiz", "Orwig", "Ory", "Paiser", "Pak", "Pettigrew", "Quinn", "Quizoz", "Ramachandran", "Resnick", "Sagar", "Schickowski", "Schiebel", "Sellon", "Severson", "Shaffer", "Solberg", "Soloman", "Sonderling", "Soukup", "Soulis", "Stahl", "Sweeney", "Tandy", "Trebil", "Trusela", "Trussel", "Turco", "Uddin", "Uflan", "Ulrich", "Upson", "Vader", "Vail", "Valente", "Van Zandt", "Vanderpoel", "Ventotla", "Vogal", "Wagle", "Wagner", "Wakefield", "Weinstein", "Weiss", "Woo", "Yang", "Yates", "Yocum", "Zeaser", "Zeller", "Ziegler", "Bauer", "Baxster", "Casal", "Cataldi", "Caswell", "Celedon", "Chambers", "Chapman", "Christensen", "Darnell", "Davidson", "Davis", "DeLorenzo", "Dinkins", "Doran", "Dugelman", "Dugan", "Duffman", "Eastman", "Ferro", "Ferry", "Fletcher", "Fietzer", "Hylan", "Hydinger", "Illingsworth", "Ingram", "Irwin", "Jagtap", "Jenson", "Johnson", "Johnsen", "Jones", "Jurgenson", "Kalleg", "Kaskel", "Keller", "Leisinger", "LePage", "Lewis", "Linde", "Lulloff", "Maki", "Martin", "McGinnis", "Mills", "Moody", "Moore", "Napier", "Nelson", "Norquist", "Nuttle", "Olson", "Ostrander", "Reamer", "Reardon", "Reyes", "Rice", "Ripka", "Roberts", "Rogers", "Root", "Sandstrom", "Sawyer", "Schlicht", "Schmitt", "Schwager", "Schutz", "Schuster", "Tapia", "Thompson", "Tiernan", "Tisler"];
        setFirstName(fNames[random(fNames.length - 1)]);
        setLastName(lNames[random(lNames.length - 1)]);
        setPhone("8474699966");
        const email = `engr${random(1000000, 9999999)}@mg.aionfi.com`;
        setEmail(email);
        window.navigator.clipboard.writeText(email);
        setPassword("Aion@123");
        setSPCheck(true);
        setCaptchaVal(1);
    }

    const handleSubmit = (onboardingType) => {
        if (onboardingType === 'credit') {
            if (!firstName) {
                setErrorField("firstName");
                setErrorMessage("Please enter a valid first name.");
                return;
            }
            if (!lastName) {
                setErrorField("lastName");
                setErrorMessage("Please enter a valid last name.");
                return;
            }
            if (!phone || (phone.replace(/[^\d]/g, '').length < 6 || phone.replace(/[^\d]/g, '').length > 10)) {
                setErrorField("phone");
                setErrorMessage("Please enter a valid mobile number.");
                return;
            }
            if (!isEmailValid(email)) {
                setErrorField("email");
                setErrorMessage("Please enter a valid business email.");
                return;
            }
            if (!spCheck) {
                setErrorField("sp");
                setErrorMessage("We do not support sole proprietors, check this box to continue.");
                return;
            }
            if (!captchaVal) {
                setErrorField("captcha");
                setErrorMessage("We are unable to verify your request. Please check the \"I'm not a robot\" box.");
                return;
            }
        }

        if (getPasswordValidations().includes(false)) {
            setErrorField("password")
            setErrorMessage("Please enter a valid password");
            return;
        }
        // TODO: Remove when referral code is not mandatory
        // if (!referralCode) {
        //     ErrorAlert({ description: "Please enter a referral code." });
        //     return;
        // }

        // const email = email
        const phone_number = `${phoneCode.dial_code}${(phone.replace(/[^\d]/g, ''))}`
        console.log("phone_number", phone_number)
        setLoading(true)

        if (referralCode) {
            checkReferralCode(referralCode, validCode => {
                if (validCode) {
                    setShowPhoneConfirmation(true)
                }
            })
        } else {
            apiGET(`/validate/email?email=${email}`, null, (err, resp) => {
                try {
                    const data = resp.data;
                    if (data.ValidBusinessEmail) {
                        setShowPhoneConfirmation(true)
                    } else {
                        setManualReviewRequired("Tried to sign up with an invalid email address.")
                        throw Error("An email with your business domain is required (gmail, yahoo etc. are not accepted)")
                    }
                } catch (error) {
                    setLoading(false)
                    ErrorAlert({ description: error.message });
                    console.log("ERRR", error.stack);
                }
            });
        }
    }

    const createAccount = async () => {
        const phone_number = `${phoneCode.dial_code}${(phone.replace(/[^\d]/g, ''))}`
        console.log("phone_number", phone_number)

        // const confirmation = window.confirm(`Confirm your phone number.\nWe'll be sending a confirmation code to ${phone_number}.\nIf this number is correct, click on OK.`);
        // if (!confirmation) {
        //     setLoading(false)
        //     return;
        // }

        const given_name = firstName;
        const family_name = lastName;
        // const password = password;
        const rc = referralCode;

        try {
            var signUpAttributes = {
                given_name,
                family_name,
                email,
                phone_number,
            }
            if (rc) {
                signUpAttributes["custom:referralCode"] = rc;
            }
            signUpAttributes["custom:subscriptionPlanName"] = capitalizeWords(plan);
            signUpAttributes["custom:subscriptionBilling"] = billing;
            signUpAttributes["custom:onboardingType"] = onboardingType;
            const { user } = await Auth.signUp({
                username: email,
                password,
                attributes: signUpAttributes
            });
            props.dispatch(addUsername(email, phone_number, given_name, family_name));
            props.dispatch(addDataToStore(SAVE_DATA, { subscriptionPlanName: plan, subscriptionBilling: billing, onboardingType: onboardingType }));
            // Call Signup service

            //Hubspot Tracking Code Start
            if (plan.toLowerCase() === "growth") {
                var _hsq = window._hsq = window._hsq || [];
                _hsq.push(["identify", {
                    email: email,
                    firstname: given_name,
                    lastname: family_name,
                    phone: phone_number,
                    plan_name: 'Growth',
                    capital_status: 'Account Created'
                }]);
                _hsq.push(['setPath', '/create-aion-account?pt=cGxhbj1ncm93dGgmdHlwZT1jcmVkaXQ']);
                _hsq.push(['setPath', '/create-aion-account?cGxhbj1ncm93dGgmdHlwZT1jcmVkaXQ=']);
                _hsq.push(['setPath', '/create-aion-account?cGxhbj1ncm93dGgmdHlwZT1jcmVkaXQ=&utm_medium=email&_hsmi=2&_hsenc=p2ANqtz-9Q6ELoAwMHB8PQLtRpY6eOP319rIWfE815FaeSzyUudFRu1w_GflpolobqRuzL-aakpUD4eEqwLAvysipEBgPJiSl7fg&utm_content=2&utm_source=hs_email']);
                _hsq.push(['trackPageView']);

            }

            if (plan.toLowerCase() === "launch") {
                console.log(1);
                var _hsq = window._hsq = window._hsq || [];
                _hsq.push(["identify", {
                    email: email,
                    firstname: given_name,
                    lastname: family_name,
                    phone: phone_number,
                    plan_name: 'Launch',
                    banking_status: 'Onboarding'
                }]);
                _hsq.push(['setPath', '/create-aion-account?pt=cGxhbj1sYXVuY2gmdHlwZT1iYW5raW5n']);
                _hsq.push(['trackPageView']);

            }

            if (plan.toLowerCase() === "essentials") {
                console.log(2);
                var _hsq = window._hsq = window._hsq || [];
                _hsq.push(["identify", {
                    email: email,
                    firstname: given_name,
                    lastname: family_name,
                    phone: phone_number,
                    plan_name: 'Essentials',
                    banking_status: 'Onboarding'
                }]);
                _hsq.push(['setPath', '/create-aion-account?pt=cGxhbj1lc3NlbnRpYWxzJnR5cGU9YmFua2luZw==']);
                _hsq.push(['trackPageView']);

            }
            //Hubspot Tracking Code End

            props.history.push({
                pathname: '/signup/confirm',
                state: { password }
            })
        } catch (error) {
            console.log('error signing up:', error);
            setLoading(false)
            ErrorAlert({ description: error.message });
        }
    }

    const handleRecaptcha = (val) => {
        setCaptchaVal(val);
    }
    const handleRecaptchaExpiry = (val) => {
        setCaptchaVal(null);
    }
    const handleRecaptchaError = (val) => {
        setCaptchaVal(null);
    }
    window.handleRecaptcha = handleRecaptcha;
    window.handleRecaptchaExpiry = handleRecaptchaExpiry;
    window.handleRecaptchaError = handleRecaptchaError;

    const handleOnKeyDown = (event) => { if (event.key === "Enter") { (step == 0) ? next() : handleSubmit() } }

    const getTitle = () => {
        const { theme } = props;
        const gnplItems = ["Unlock the cash you need to grow", "Receive a proposal within 24 hours", "Applying doesn't impact credit score"];
        return (
            (onboardingType != "credit") ?
                <>
                    <Title level={3} style={{ color: "#FFFFFF" }}>Welcome to Aion</Title>
                    <Paragraph style={{ color: "#FFFFFF" }}>One platform to manage all your finances and operations — banking, paying employees and vendors, getting paid early on your invoices, managing spend and operating with real-time insights.</Paragraph>
                </>
                :
                isMobile ?
                    <FlexColumn>
                        <FlexColumn style={{ paddingLeft: "30px" }}>
                            <Title level={6} style={{ color: "#FFFFFF" }}>Aion</Title>
                            <div style={{ marginBottom: "1 0px" }}>
                                {gnplItems.map(item =>
                                    <Space style={{ marginTop: "5px", marginBottom: "5px" }} align="center">
                                        <CheckCircleTwoTone style={{ fontSize: "1.0rem" }} />
                                        <Paragraph size="1.0rem" noMargin style={{ color: "#FFFFFF" }}>
                                            {item}
                                        </Paragraph>
                                    </Space>
                                )}
                            </div>
                        </FlexColumn>
                    </FlexColumn>
                    :
                    isTablet ?
                        <FlexColumn>
                            <FlexColumn>
                                <Title level={3} style={{ color: "#FFFFFF" }}>Aion</Title>
                                <div style={{ marginBottom: "1 0px" }}>
                                    {gnplItems.map(item =>
                                        <Space key={item} style={{ marginTop: "10px", marginBottom: "10px" }} align="center">
                                            <CheckCircleTwoTone style={{ fontSize: "1.5rem" }} />
                                            <Paragraph size="1.5rem" noMargin style={{ color: "#FFFFFF" }}>
                                                {item}
                                            </Paragraph>
                                        </Space>
                                    )}
                                </div>
                            </FlexColumn>
                        </FlexColumn>
                        :
                        <FlexColumn>
                            <FlexColumn>
                                <Title level={3} style={{ color: "#FFFFFF" }}>Grow now, pay later</Title>
                                <div style={{ paddingLeft: "30px", marginBottom: "60px" }}>
                                    {gnplItems.map(item =>
                                        <Space key={item} style={{ marginTop: "10px", marginBottom: "15px" }} align="center">
                                            <CheckCircleTwoTone style={{ fontSize: "1.5rem" }} />
                                            <Paragraph size="1.5rem" noMargin style={{ color: "#FFFFFF" }}>
                                                {item}
                                            </Paragraph>
                                        </Space>
                                    )}
                                </div>
                                <Flex style={{ marginLeft: "-100px" }}>
                                    <img src={QuoteIcon} height="120px" style={{ marginRight: -150, zIndex: 10 }} />
                                    <InputContainer style={{ width: "450px", marginTop: "45px" }}>
                                        <FlexColumn style={{ padding: "5px 20px", textAlign: "justify" }}>
                                            <Paragraph size="1.2rem" noMargin style={{ fontWeight: 600 }}>AION GAVE US PEACE OF MIND</Paragraph>
                                            <Paragraph size="1.2rem" noMargin >that that they would be there for us when money was tight and we were right on the edge. Now we’re growing faster than ever.</Paragraph>
                                            <Space style={{ marginTop: "10px" }}>{_.times(5, i => <StarFilled key={i} style={{ color: theme.colors.secondary2, fontSize: "1.2rem" }} />)}</Space>
                                        </FlexColumn>
                                        <Space style={{ paddingLeft: "20px" }}>
                                            <img src={UserImg} height="66px" style={{ borderRadius: "33px" }} />
                                            <FlexColumn>
                                                <Paragraph noMargin style={{ fontSize: "0.75rem", color: "rgba(0,0,0,0.9)" }}>
                                                    ROB IMESON<br />
                                                    FOUNDER/CEO<br />
                                                    SPLASH WINES, INC
                                                </Paragraph>
                                            </FlexColumn>
                                        </Space>
                                    </InputContainer>
                                </Flex>
                            </FlexColumn>
                        </FlexColumn>
        )
    }

    const handleRadioOnChange = (event) => {
        const target = event.target;
        setBilling(target.value)
    }

    const handleCodeChange = (code) => {
        setPhoneCode(code)
    }

    const getInputContent = () => {
        var title = "Create your Aion account"
        var desc = "Get started with your application by creating an Aion account"

        if (onboardingType == "banking" && plan.toLowerCase() == "launch") {
            title = "Apply for banking in minutes"
            desc = "Modern online banking with physical and virtual cards."
        }
        if (plan.toLowerCase() != "launch") {
            title = "Modern finance platform"
            desc = "Banking, cards, invoicing, bill payments, financing - all in one place."
        }
        if (onboardingType == "credit") {
            title = "Apply for financing in minutes"
            desc = "Unlock working capital. Grow your business."
        }
        return (
            <FlexColumn style={{ marginTop: isMobile || isTablet ? "10px" : "0px", width: '433px' }}>
                {
                    (step == 0) &&
                    <FlexColumn start gap='18px'>
                        <FlexColumn start style={{ marginBottom: 8 }}>
                            <PageTitle level={5} noMargin style={{ marginBottom: 0 }}>{title}</PageTitle>
                            <Text color={props.theme.colors.caption}>{desc}</Text>

                            {/* <PageTitle level={5} noMargin style={{ marginBottom: 0 }}>Apply for financing</PageTitle>
                            <Text color={props.theme.colors.caption}>Unlock working capital. Grow your business.</Text> */}

                            {/* <PageTitle level={5} noMargin style={{ marginBottom: 0 }}>Apply for banking</PageTitle>
                            <Text color={props.theme.colors.caption}>Modern online banking with physical and virtual cards.</Text> */}

                            {/* <PageTitle level={5} noMargin style={{ marginBottom: 0 }}>Modern finance platform</PageTitle>
                            <Text color={props.theme.colors.caption}>Banking, cards, invoicing, bill payments, financing - all in one place.</Text> */}
                        </FlexColumn>
                        <Flex style={{ gap: '24px', alignItems: "start" }}>
                            <LabeledInput
                                margin='0px'
                                autoFocus
                                label="First Name"
                                id="firstName"
                                type="firstName"
                                value={firstName}
                                placeholder="John"
                                onChange={handleChange}
                                onKeyDown={handleOnKeyDown}
                                error={errorField == "firstName"}
                                errorMessage={errorMessage}
                                autocomplete
                            />
                            <LabeledInput
                                margin='0px'
                                label="Last Name"
                                id="lastName"
                                type="lastName"
                                value={lastName}
                                onKeyDown={handleOnKeyDown}
                                placeholder="Smith Jr."
                                onChange={handleChange}
                                error={errorField == "lastName"}
                                errorMessage={errorMessage}
                                autocomplete
                            />
                        </Flex>
                        {
                            refCodeValid ?
                                <LabeledInput
                                    margin='0px'
                                    label="Mobile Number"
                                    id="phone"
                                    type="phone-cc"
                                    placeholder="(000) 000 0000"
                                    onChange={handleChange}
                                    onKeyDown={handleOnKeyDown}
                                    onCodeChange={(code) => handleCodeChange(code)}
                                    value={phone}
                                    phoneCode={phoneCode}
                                    error={errorField == "phone"}
                                    errorMessage={errorMessage}
                                    autocomplete
                                />
                                :
                                <LabeledInput
                                    margin='0px'
                                    label="Mobile Number"
                                    id="phone"
                                    placeholder="(000) 000 0000"
                                    onChange={handleChange}
                                    onKeyDown={handleOnKeyDown}
                                    value={phone}
                                    symbolprefix={<Text size='18px' style={{ marginRight: '8px' }}>{phoneCode.flag}</Text>}
                                    error={errorField == "phone"}
                                    errorMessage={errorMessage}
                                    autocomplete
                                />
                        }
                        <LabeledInput
                            margin='0px'
                            label="Business Email"
                            id="email"
                            type="email"
                            placeholder="Eg: john@acme.com"
                            onChange={handleChange}
                            value={email}
                            error={errorField == "email"}
                            errorMessage={errorMessage}
                            autocomplete
                        />

                        <div style={{ background: 'transparent' }}>
                            <LabeledInput
                                margin='0px'
                                label="Enter a Password"
                                id="password"
                                type="new-password"
                                placeholder="Enter a password for your account"
                                onChange={handleChange}
                                onKeyDown={handleOnKeyDown}
                                error={errorField == "password"}
                                errorMessage={errorMessage}
                                defaultValue={password}
                                onFocus={() => setShowPwdOverlay(true)}
                                onBlur={() => setShowPwdOverlay(false)}
                            />
                            {showPwdOverlay && pwdOverlay()}
                        </div>

                        {
                            !(refData.IsPartnerCode && refData.PartnerLogo) &&
                            <LabeledInput
                                margin='0px'
                                value={referralCode}
                                // disabled={defaultReferralCode}
                                label="Referral Code"
                                id="referralcode"
                                onKeyDown={handleOnKeyDown}
                                placeholder="Enter the code if you have one (Eg: 3ABSN20)"
                                onChange={handleChange}
                                style={{ color: referralCode ? "rgba(0,0,0,0.7)" : null }}
                                error={errorField == "referralcode"}
                                errorMessage={errorMessage}
                                optional
                            />
                        }
                        {
                            refData.PartnerLogo &&
                            <Flex centerHorizontally start gap='8px' style={{ margin: '8px 0' }}>
                                <Text>In partnership with</Text>
                                <img src={refData.PartnerLogo} height={`${refData.LogoHeight || 20}px`} />
                            </Flex>
                        }
                        {/* <FlexColumn>
                            <Flex start gap='8px' centerHorizontally>
                                <Flex gap='4px' >
                                    <Text noMargin color={props.theme.colors.greySecondary} size='14px'>I'm not a Sole Proprietor</Text>
                                    <Image width='5px' height='5px' margin='4px 0 0' src={Asterisk} />
                                </Flex>
                                <Switch checked={spCheck} size='small' onChange={(checked) => setSPCheck(checked)} />
                            </Flex>
                            {(errorField == 'sp') && <ErrorLabel>{errorMessage}</ErrorLabel>}
                        </FlexColumn> */}
                        <FlexColumn>
                            <div
                                className="g-recaptcha"
                                data-sitekey="6Ld-biEcAAAAAET3P4cgpAsYRTiNemQhufZxeZzZ"
                                data-callback="handleRecaptcha"
                                data-expired-callback="handleRecaptchaExpiry"
                                data-error-callback="handleRecaptchaError"
                                key={step}
                            // style={{ transform: "scale(1.2)", transformOrigin: "0 0" }}
                            >
                            </div>
                            {(errorField == 'captcha') && <ErrorLabel>{errorMessage}</ErrorLabel>}
                        </FlexColumn>

                    </FlexColumn>
                }
                {/* {
                    (step == 1) &&
                    <FlexColumn style={{ flex: 3 }}>
                        <PageTitle level={5} noMargin style={{ marginBottom: '15px' }}>Hi {firstName}</PageTitle>
                        <Text color={props.theme.colors.caption}>Set your password here and click on next to proceed</Text>
                        <LabeledInput
                            label="Mobile Number"
                            id="phone"
                            type="read-only"
                            value={`${phoneCode.dial_code} ${phone}`}
                        />
                        <LabeledInput
                            label="Business Email"
                            id="email"
                            type="read-only"
                            value={email}
                        />
                        <div style={{ background: 'transparent' }}>
                            <LabeledInput
                                label="Enter a Password"
                                id="password"
                                type="new-password"
                                placeholder="Enter a password for your account"
                                onChange={handleChange}
                                onKeyDown={handleOnKeyDown}
                                error={errorField == "password"}
                                errorMessage={errorMessage}
                                defaultValue={password}
                                style={{ width: '100%' }}
                                margin="12px 0 8px"
                                onFocus={() => setShowPwdOverlay(true)}
                                onBlur={() => setShowPwdOverlay(false)}
                            />
                            {showPwdOverlay && pwdOverlay()}
                        </div>
                    </FlexColumn>
                } */}
                {
                    (step == 1) &&
                    <FlexColumn style={{ flex: 3 }}>
                        <PageTitle level={5} noMargin style={{ marginBottom: '15px' }}>Hi {firstName}</PageTitle>
                        <Text color={props.theme.colors.caption}>Confirm your plan below and proceed to verify your phone number.</Text>
                        <LabeledInput
                            label="Plan"
                            id="phone"
                            type="select"
                            value={plan}
                            onChange={handleSelect}
                        >
                            {["Essentials", "Growth"].map(item => (
                                <Option key={item} id="plan" name="plan" value={item}>
                                    {item}
                                </Option>
                            )
                            )}
                        </LabeledInput>

                        <LightContainer margin='24px 0 0'>
                            <FlexColumn>
                                <Text caption spaced>PRICING</Text>
                                <Flex between fullWidth style={{ marginTop: 24 }}>
                                    <Text color={props.theme.colors.caption}>Plan</Text>
                                    <FlexColumn right>
                                        <Text size='20px'>${toCurrency(pricingMap[plan.toLowerCase()].monthly)}</Text>
                                        <Text size='12px'>/ month</Text>
                                    </FlexColumn>
                                </Flex>

                                <Text margin='24px 0 0' size='14px' color={props.theme.colors.caption}>{plan === 'Essentials' ? "No monthly subscription fees apply." : "Monthly subscription charges will be deducted from your account."}</Text>
                            </FlexColumn>
                        </LightContainer>
                    </FlexColumn>
                }
            </FlexColumn>
        )
    }

    const pwdOverlay = () => {
        const StyledOverlay = styled.div`
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 16px;

            position: absolute;
            width: 408px;
            height: 162px;

            background: conic-gradient(from 156.95deg at 92.52% 82.42%, #000000 0deg, #333333 360deg);
            border-radius: 4px;
            z-index: 10;
        `;
        const StyledUL = styled.ul`
            font-weight: 400;
            font-size: 16px;
            color: #FFFFFF;
            padding: 0 16px;
            list-style: none;
        `;
        const StyledSpan = styled.span`
            display: inline-block;
            width: 15px;
        `;
        const bullet = <StyledSpan>•</StyledSpan>
        const checkMark = <StyledSpan>✓</StyledSpan>
        const validations = getPasswordValidations();
        return (
            <StyledOverlay>
                <StyledUL className='checked-bullet'>
                    <li>{validations[0] ? checkMark : bullet} 8 to 25 characters</li>
                    <li>{validations[1] ? checkMark : bullet} At least 1 special character (@,#,$,%)</li>
                    <li>{validations[2] ? checkMark : bullet} At least 1 character from A-Z</li>
                    <li>{validations[3] ? checkMark : bullet} At least 1 character from a-z</li>
                    <li>{validations[4] ? checkMark : bullet} At least 1 character from 0-9</li>
                </StyledUL>
            </StyledOverlay>
        )
    }

    const getCTA = () => {
        return (
            <FlexColumn>
                {
                    (step == 0) &&
                    <FlexColumn>
                        {
                            onboardingType === 'credit' &&
                            <Paragraph style={{ textAlign: "left", margin: "20px 0", fontSize: "0.9rem", opacity: 0.8 }}>By clicking next you agree to our <StyledExtLink target="_blank" href="https://www.aionfi.com/platform-services-agreement">Platform Services Agreement</StyledExtLink>, <StyledExtLink target="_blank" href="http://aionfi.com/esign-agreement">E-SIGN Agreement</StyledExtLink>, <StyledExtLink target="_blank" href="https://www.aionfi.com/legal">Terms of Use</StyledExtLink> and <StyledExtLink target="_blank" href="https://www.aionfi.com/privacy">Privacy Policy</StyledExtLink></Paragraph>
                        }
                        {
                            onboardingType === 'banking' &&
                            <Text color={props.theme.colors.caption} style={{ textAlign: "left", margin: "15px 0" }}>Note: We currently do not support sole proprietors</Text>
                        }
                        <FlexColumn left>
                            {environment.showAutofill && <TextButton onClick={() => autofill()} text="Autofill"></TextButton>}
                            <Flex start centerHorizontally gap='24px'>
                                {
                                    (onboardingType !== 'banking' || plan === 'Launch') ?
                                        <Button permtype="Override" solid loading={loading} style={{ margin: '10px 0' }} onClick={() => handleSubmit(onboardingType)} text={'Next'.toUpperCase()} />
                                        :
                                        <Button permtype="Override" solid onClick={() => next()} text={'Next'.toUpperCase()} />
                                }

                                <Text>Already started? <StyledExtLink href="https://app.aionfi.com" style={{ textDecoration: "underline" }}>Log back in</StyledExtLink></Text>
                            </Flex>
                        </FlexColumn>
                    </FlexColumn>
                }
                {/* {
                    (step == 1) &&
                    <FlexColumn>
                        {
                            onboardingType === 'credit' &&
                            <Paragraph style={{ textAlign: "left", margin: "20px 0", fontSize: "0.9rem", opacity: 0.8 }}>By clicking next you agree to our <StyledExtLink target="_blank" href="https://www.aionfi.com/platform-services-agreement">Platform Services Agreement</StyledExtLink>,<br /> <StyledExtLink target="_blank" href="http://aionfi.com/esign-agreement">E-SIGN Agreement</StyledExtLink>, <StyledExtLink target="_blank" href="https://www.aionfi.com/legal">Terms of Use</StyledExtLink> and <StyledExtLink target="_blank" href="https://www.aionfi.com/privacy">Privacy Policy</StyledExtLink></Paragraph>
                        }
                        <Flex start style={{ gap: '24px' }} centerHorizontally>
                            <TextButton permtype="Override" onClick={() => back()} text={'Back'.toUpperCase()} />
                            {
                                onboardingType === 'banking' ?
                                    <Button permtype="Override" solid onClick={() => setStep(2)} text={'Next'.toUpperCase()} />
                                    :
                                    <Button permtype="Override" solid loading={loading} style={{ margin: '10px 0' }} onClick={handleSubmit} text={'Next'.toUpperCase()} />
                            }
                        </Flex>
                    </FlexColumn>
                } */}

                {
                    (step == 1) &&
                    <FlexColumn left>
                        {
                            onboardingType === 'banking' &&
                            <Paragraph style={{ textAlign: "left", margin: "20px 0", fontSize: "0.9rem", opacity: 0.8 }}>By clicking confirm you agree to our <StyledExtLink target="_blank" href="https://www.aionfi.com/platform-services-agreement">Platform Services Agreement</StyledExtLink>, <StyledExtLink target="_blank" href="http://aionfi.com/esign-agreement">E-SIGN Agreement</StyledExtLink>, <StyledExtLink target="_blank" href="https://www.aionfi.com/legal">Terms of Use</StyledExtLink> and <StyledExtLink target="_blank" href="https://www.aionfi.com/privacy">Privacy Policy</StyledExtLink></Paragraph>
                        }
                        <Flex start style={{ gap: '24px' }} centerHorizontally>
                            <TextButton permtype="Override" onClick={() => back()} text={'Back'.toUpperCase()} />
                            <Button bordered permtype="Override" solid loading={loading} style={{ margin: '10px 0' }} onClick={handleSubmit} text={'Confirm'.toUpperCase()} />
                        </Flex>
                    </FlexColumn>
                }
            </FlexColumn>
        )
    }

    const next = () => {
        if (!firstName) {
            setErrorField("firstName");
            setErrorMessage("Please enter a valid first name.");
            return;
        }
        if (!lastName) {
            setErrorField("lastName");
            setErrorMessage("Please enter a valid last name.");
            return;
        }
        if (!phone || (phone.replace(/[^\d]/g, '').length < 6 || phone.replace(/[^\d]/g, '').length > 10)) {
            setErrorField("phone");
            setErrorMessage("Please enter a valid mobile number.");
            return;
        }
        if (!isEmailValid(email)) {
            setErrorField("email");
            setErrorMessage("Please enter a valid business email.");
            return;
        }
        if (!spCheck) {
            setErrorField("sp");
            setErrorMessage("We do not support sole proprietors, check this box to continue.");
            return;
        }
        if (!captchaVal) {
            setErrorField("captcha");
            setErrorMessage("We are unable to verify your request. Please check the \"I'm not a robot\" box.");
            return;
        }
        if (getPasswordValidations().includes(false)) {
            setErrorField("password")
            setErrorMessage("Please enter a valid password");
            return;
        }
        setStep(step + 1);
    };
    const back = () => { setStep(step - 1) };

    function createScript() {
        if (plan.toLowerCase() === "growth" || plan.toLowerCase() === "launch" || plan.toLowerCase() === "essentials") {
            const script = document.createElement("script");
            script.src = environment.hubspotTrackingScript;
            script.id = "hs-script-loader"
            script.async = true;
            var scriptExists = false;
            var scripts = document.getElementsByTagName('script');
            for (var i = scripts.length; i--;) {
                if (scripts[i].src == script.src) {
                    scriptExists = true;
                    break;
                }
            }

            if (!scriptExists) {
                document.body.appendChild(script);
            }
        }
    }

    // For now show mobile landing page for phone users
    if (isMobile) return <MobileLanding
        hideAppStore={true}
        desc='Please sign up using your desktop device as we currently do not support mobile device sign-ups'
    />

    return (
        <Flex style={{ paddingTop: '80px', justifyContent: 'center', gap: '132px' }}>
            <Flex start style={{ margin: '12px 24px 12px', position: "absolute", top: "12px", left: "75px" }} gap='4px'>
                <Image src={AionLogo} height='32px' width='32px' />
                <Image src={AionText} height='24px' width='51px' />
            </Flex>
            <Helmet>
                <script src="https://www.google.com/recaptcha/enterprise.js" async defer></script>
            </Helmet>
            {
                isMobile || isTablet ?
                    <div>
                        <GradientSideBar width={"100%"}>
                            <FlexColumn center>
                                {
                                    isMobile ?
                                        <div style={{ padding: '30px 10px' }}>
                                            {getTitle()}
                                        </div>
                                        :
                                        <div style={{ padding: '30px 70px' }}>
                                            {getTitle()}
                                        </div>
                                }
                            </FlexColumn>
                        </GradientSideBar>
                        <FlexColumn center style={{ padding: "30px 25px 10px 25px" }}>
                            {getInputContent()}
                        </FlexColumn>
                        <Flex>
                            <Image src={AionLogo} />
                        </Flex>
                    </div>
                    :
                    <>
                        <FlexColumn between left style={{ width: "408px", height: "816px", gap: '24px' }}>
                            <FlexColumn left style={{ gap: '12px', width: 'inherit' }}>
                                {/* <Image src={AionLogo} /> */}
                                {getInputContent()}
                            </FlexColumn>
                            {getCTA()}
                        </FlexColumn>
                        <FlexColumn between left style={{ width: "516px", height: "816px" }}>
                            <BrandedContainer height={step === 1 ? '657px' : '816px'}>
                                <AionOverLay>
                                    <img src={AionOverlayImg} />
                                </AionOverLay>
                                {
                                    step === 1 ?
                                        <FlexColumn style={{ padding: '80px' }}>
                                            {(() => {
                                                switch (plan) {
                                                    // case 'Launch':
                                                    //     return <>
                                                    //         <Text heading color='white'>{plan}</Text>
                                                    //         <Text margin='8px 0 16px' size='20px' color='white'>Convenient online banking for every business</Text>
                                                    //         <Flex start fullWidth centerHorizontally style={{ marginBottom: 16 }}>
                                                    //             <Text size='12px' color='white' noWrap>Your plan includes</Text>
                                                    //             <div style={{ height: 1, width: '100%', background: 'white', marginLeft: 24 }} />
                                                    //         </Flex>
                                                    //     <FlexColumn start left gap='16px'>
                                                    //         <Flex start gap='8px'>
                                                    //             <Image src={Check} />
                                                    //             <Text color='white'>Business checking account</Text>
                                                    //         </Flex>
                                                    //         <Flex start gap='8px'>
                                                    //             <Image src={Check} />
                                                    //             <Text color='white'>Debit card</Text>
                                                    //         </Flex>
                                                    //         <Flex start gap='8px'>
                                                    //             <Image src={Check} />
                                                    //             <Text color='white'>Banking feeds to QuickBooks Online</Text>
                                                    //         </Flex>
                                                    //         <Flex start gap='8px'>
                                                    //             <Image src={Check} />
                                                    //             <Text color='white'>MFA-secured and FDIC-insured</Text>
                                                    //         </Flex>
                                                    //     </FlexColumn>
                                                    // </>
                                                    case 'Essentials':
                                                        return <>
                                                            <Text heading color='white'>{plan}</Text>
                                                            <Text margin='8px 0 16px' size='20px' color='white'>One platform to manage all your business finances</Text>
                                                            <Flex start fullWidth centerHorizontally style={{ marginBottom: 16 }}>
                                                                <Text size='12px' color='white' noWrap>Your plan includes</Text>
                                                                <div style={{ height: 1, width: '100%', background: 'white', marginLeft: 24 }} />
                                                            </Flex>
                                                            <FlexColumn start left gap='16px'>
                                                                <Flex start gap='8px'>
                                                                    <Image src={Check} />
                                                                    <Text color='white'>Checking account, ACH and Wire Transfers</Text>
                                                                </Flex>
                                                                <Flex start gap='8px'>
                                                                    <Image src={Check} />
                                                                    <Text color='white'>Physical and Virtual Cards</Text>
                                                                </Flex>
                                                                <Flex start gap='8px'>
                                                                    <Image src={Check} />
                                                                    <Text color='white'>Bill Payments, Scheduling, and Tracking</Text>
                                                                </Flex>
                                                                <Flex start gap='8px'>
                                                                    <Image src={Check} />
                                                                    <Text color='white'>Invoicing, Customization and Reconciliation</Text>
                                                                </Flex>
                                                                <Flex start gap='8px'>
                                                                    <Image src={Check} />
                                                                    <Text color='white'>Banking Feeds to QuickBooks Online</Text>
                                                                </Flex>
                                                            </FlexColumn>
                                                        </>
                                                    case 'Growth':
                                                        return <>
                                                            <Text heading color='white'>{plan}</Text>
                                                            <Text margin='8px 0 16px' size='20px' color='white'>Finance operations & automations that scale with your growing business</Text>
                                                            <Flex start fullWidth centerHorizontally style={{ marginBottom: 16 }}>
                                                                <Text size='12px' color='white' noWrap>Everything in the Essentials plan plus</Text>
                                                                <div style={{ height: 1, width: '100%', background: 'white', marginLeft: 24 }} />
                                                            </Flex>
                                                            <FlexColumn start left gap='16px'>
                                                                <Flex start gap='8px'>
                                                                    <Image src={Check} />
                                                                    <Text color='white'>Custom Permissions</Text>
                                                                </Flex>
                                                                <Flex start gap='8px'>
                                                                    <Image src={Check} />
                                                                    <Text color='white'>Approval Workflow Automations</Text>
                                                                </Flex>
                                                                <Flex start gap='8px'>
                                                                    <Image src={Check} />
                                                                    <Text color='white'>Short-term Working Capital</Text>
                                                                </Flex>
                                                                <Flex start gap='8px'>
                                                                    <Image src={Check} />
                                                                    <Text color='white'>Automatic QuickBooks Sync</Text>
                                                                </Flex>
                                                                <Flex start gap='8px'>
                                                                    <Image src={Check} />
                                                                    <Text color='white'>Accounting Systems Integrations</Text>
                                                                </Flex>
                                                            </FlexColumn>
                                                        </>
                                                    default:
                                                        return null
                                                }
                                            })()}

                                            <FlexColumn start centerHorizontally>
                                                <CardContainer style={{ width: '468px', marginTop: 140, marginBottom: 24, zIndex: 5, }}>
                                                    {(() => {
                                                        switch (page) {
                                                            case 0:
                                                                return <Text>With Aion, I can finally perform all financial functions for my business in one comprehensive place. Aion’s intuitive app saves me a bunch of time, and as a business owner, that means a bunch of money, too.</Text>
                                                            case 1:
                                                                return <Text>Aion is the banking partner we trust for competitive rates, transparency to our clients, real-time support, and efficient flow of funding.</Text>
                                                            case 2:
                                                                return <Text>The Aion app provides a feature-rich solution for managing my customers and billing. Business financing is at the click of a button, and the integration with QuickBooks ensures instant updates to close the books.</Text>
                                                            default:
                                                                return null
                                                        }
                                                    })()}

                                                    <Flex between style={{ marginTop: 12, width: '100%' }}>
                                                        <Flex start>
                                                            {/* <img width='48px' height='48px' src={Profile1} /> */}
                                                            <FlexColumn between style={{ height: 48, marginLeft: 12 }}>
                                                                {(() => {
                                                                    switch (page) {
                                                                        case 0:
                                                                            return <>
                                                                                <Text color='#1199FF'>Christopher V. Basso</Text>
                                                                                <Text size='12px' height='20px' color='#666666'>President, Quench Design</Text>
                                                                            </>
                                                                        case 1:
                                                                            return <>
                                                                                <Text color='#1199FF'>Drew Marheine</Text>
                                                                                <Text size='12px' height='20px' color='#666666'>Owner, iDeploy LLC</Text>
                                                                            </>
                                                                        case 2:
                                                                            return <>
                                                                                <Text color='#1199FF'>Rajeshwar Mitra</Text>
                                                                                <Text size='12px' height='20px' color='#666666'>CEO, GreyMatter Technologies</Text>
                                                                            </>
                                                                        default:
                                                                            return null
                                                                    }
                                                                })()}
                                                            </FlexColumn>
                                                        </Flex>

                                                        <img width='48px' height='48px' src={Quote} />
                                                    </Flex>
                                                </CardContainer>
                                                <Pager
                                                    pages={3}
                                                    page={page}
                                                    next={() => { }}
                                                    previous={() => { }}
                                                    noArrows
                                                    dotClickable
                                                    onClick={(i) => setPage(i)}
                                                />
                                            </FlexColumn>
                                        </FlexColumn>
                                        :
                                        <FlexColumn center style={{ padding: '24px 80px', height: '100%' }}>
                                            <img src={CoinStackImg} height='330px' />
                                            <Divider style={{ background: props.theme.body }} />
                                            <Text center color={props.theme.body}>
                                                One platform to manage all your business finances and operations from banking, paying your employees and vendors, getting paid early on your invoices, managing spends to operating with real time insights
                                            </Text>
                                        </FlexColumn>
                                }
                            </BrandedContainer>

                            <Modal
                                visible={showPhoneConfirmation}
                                width={520}
                                closable={true}
                                maskClosable={true}
                                style={{ borderRadius: '8px !important' }}
                                footer={null}
                                destroyOnClose={true}
                                onCancel={() => {
                                    setLoading(false)
                                    setShowPhoneConfirmation(false)
                                }}
                                closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                            >
                                <FlexColumn between>
                                    <FlexColumn start>
                                        <Text margin='0 0 24px' size='24px' weight={500} color='#444444'>Verify Phone Number</Text>
                                        {/* <Text>{`We'll be sending a confirmation code to ${email}.\nClick confirm to proceed.`}</Text> */}
                                        <Text>We'll be sending a confirmation code to <span style={{ fontWeight: 600 }}>{phone ? `${phoneCode.dial_code} ${phone}` : ''}</span>.<br />Click confirm to proceed.</Text>
                                    </FlexColumn>

                                    <Flex centerHorizontally style={{ marginTop: 40 }} start gap='24px'>
                                        <Button primary solid onClick={() => createAccount()} text="CONFIRM" />
                                        <TextButton text='CANCEL' onClick={() => {
                                            setLoading(false)
                                            setShowPhoneConfirmation(false)
                                        }} />
                                    </Flex>
                                </FlexColumn>
                            </Modal>
                        </FlexColumn>
                    </>
            }
            {createScript()}
        </Flex>
    );
    // }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Register)));