import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
// Components
import { Button } from '../../../Reusable/Button';
import { Flex, FlexColumn } from '../../../Reusable/Container';
import { Text } from '../../../Reusable/Text';
import { LabeledInput } from '../../../Reusable/Input';
import { apiPOSTReq } from '../../../../Utils/api';
import environment from '../../../../environment';
import { ErrorAlert } from '../../../Reusable/Alert';
import { Select } from 'antd';
const { Option } = Select;

class Index extends Component {
    state = {
        availability: this.props.availability,
        loading: false,
        reason: '',
        status: null
    }

    componentDidMount() {
    }

    handleReason = (event) => {
        const target = event.target;
        this.setState({ reason: target.value });
    }

    handleSelect = (value, option) => {
        this.setState({ [option.id]: value });
    }

    handleSubmit = async () => {
        const { UserInfo } = this.props.store;
        var { businessId, isFrozen } = this.props;

        var username = UserInfo.Email;

        if (this.state.status && this.state.reason != null && this.state.reason != '') {
            this.setState({ loading: true });
            var request = {
                "clientBusinessId": businessId,
                "creditOpsParams": {
                    "businessAttributeParams": [
                        {
                            "name": "FreezeCreditLine",
                            "type": "FreezeCreditLine",
                            "value": this.state.status == "Freeze" ? true : false
                        },
                        {
                            "name": "Watch",
                            "type": "Watch",
                            "value": this.state.status == "Watch" ? true : false
                        },
                        {
                            "name": "Collection",
                            "type": "Collection",
                            "value": this.state.status == "Collection" ? true : false
                        }
                    ]
                },
                "userName": username,
                "reason": this.state.reason
            }
            apiPOSTReq(`${environment.opsBaseUrl}/ops/setBusinessInfo`, null, request, (err, resp) => {
                this.setState({ loading: false })
                try {
                    const data = resp;
                    if (data.result) {
                        if (err) throw new Error(err);
                        this.state.status == "Freeze" ? isFrozen = true : isFrozen = false;
                        this.props.closeFreezeline(isFrozen)
                    } else {
                        throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                    }
                } catch (error) {
                    console.log("ERRR setBusinessInfo", error, err, resp);
                }
            });
        }
        else {
            if (!this.state.status) {
                this.setState({ errorField: "creditLimit", errorMessage: "Please select a valid status." })
                return
            }
            if (!this.state.reason || this.state.reason == "") {
                this.setState({ errorField: "reason", errorMessage: "Please enter a valid reason." })
                return
            }
        }
    }

    render() {
        const { reason, loading, status, errorField, errorMessage } = this.state;
        const { businessId, isFrozen, isWatch, isCollection } = this.props;
        var lineFreeze = isFrozen;
        const statuses = [
            isFrozen ? "Reactivate" : "Freeze",
            isWatch ? "Reactivate" : "Watch",
            isCollection ? "Reactivate" : "Collection"
        ]

        return (
            <FlexColumn center style={{ minHeight: "80%" }}>
                <div style={{ width: "600px" }}>
                    {
                        <Text heading>Credit Line Status</Text>
                    }
                    <Flex start>
                        <LabeledInput
                            label="Line Status"
                            type="select"
                            value={status}
                            placeholder="Select a Status"
                            onChange={this.handleSelect}
                            margin='10px 0 10px'
                            error={errorField == "creditLimit"}
                            errorMessage={errorMessage}
                        >
                            {
                                statuses.map(
                                    status => {
                                        return <Option id="status" value={status}>{status}</Option>
                                    }
                                )
                            }
                        </LabeledInput>
                    </Flex>
                    {/* <Paragraph>Add reason for {lineFreeze ? "reactivating" : "freezing"} the line and submit your request</Paragraph> */}
                    <Flex start>
                        <LabeledInput
                            label="Reason"
                            type="text-area"
                            id="reason"
                            key="reason"
                            placeholder={`Add your reason`}
                            onChange={this.handleReason}
                            error={errorField == "reason"}
                            errorMessage={errorMessage}
                        />
                    </Flex>
                    <Flex start>
                        <Button style={{ alignSelf: 'start', margin: '20px 0' }} solid size="large" loading={loading} onClick={this.handleSubmit} text="Save" />
                    </Flex>
                </div>
            </FlexColumn>
        );
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer,
        receivablesStore: state.receivablesAppReducer,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Index));