import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { DeleteOutlined } from '@ant-design/icons'
import { Table, Space, Popconfirm, message } from 'antd'
import {
    CheckCircleFilled,
    EditOutlined,
    PlusOutlined,
} from '@ant-design/icons'
import { LabeledInput } from '../../Reusable/Input'

// Util
import environment from '../../../environment'
import { apiPOSTReq } from '../../../Utils/api'
import { getResourcePerm } from '../../../Utils/util'
import PageHeader from "../../Reusable/PageHeader"
import { Flex, FlexColumn, Container } from '../../Reusable/Container'
import { Image } from '../../Reusable/Image'

// Images
import { ErrorAlert } from '../../Reusable/Alert'
import { Tag } from '../../Reusable/Text'
import { Button } from '../../Reusable/Button'
import Modal from 'antd/lib/modal/Modal'
import NewContractor from './NewContractor'
import Import from '../Vendors/Import'
import { withTheme } from 'styled-components'
import { addDataToStore, SAVE_DATA } from '../../../Actions/actions'
import ModalClose from '../../../Images/modal-close.png'
import Search from '../../../Images/search.png'


class Index extends Component {
    ref = null
    loadingAnimation = null
    state = {
        loading: false,
        pagination: {
            current: 1,
            pageSize: 50
        },
        searchPagination: {
            current: 1,
            pageSize: 50
        },
    }

    componentDidMount() {
        let { Profile } = this.props.aionStore
        const accountingSW = Profile.AccountingSW
        this.setState({ accountingSW })

        this.fetchData({ pagination: this.state.pagination })
    }

    componentDidUpdate(prevProps) {
        let { location } = this.props
        var state = location.state ? location.state : {}
        if (state.reloadData) {
            this.props.location.state = {}
            this.fetchData({ pagination: this.state.pagination })
        }
    }

    fetchData(options) {
        const { pagination } = options
        const body = {
            "size": pagination.pageSize,
            "page": pagination.current - 1
        }
        this.setState({ loading: true })
        apiPOSTReq(`${environment.payBaseUrl}/payables/contractors/getContractors`, {}, body, (err, resp) => {
            this.setState({ loading: false })
            try {
                const data = resp
                console.log("/getContractors", data)
                if (data.result) {
                    this.setState({
                        ...data,
                        pagination: {
                            ...pagination,
                            total: data.count
                        }
                    })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getContractors", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    deleteContractor = (contractor) => {
        const body = {
            "contractorId": contractor.id
        }
        this.setState({ deleteLoading: true })
        apiPOSTReq(`${environment.payBaseUrl}/payables/contractors/delete`, {}, body, (err, resp) => {
            try {
                this.setState({ deleteLoading: false })
                const data = resp
                console.log("/payables/contractors/delete", data)
                if (data.result) {
                    message.success(`Successfully deleted!`)
                    this.fetchData({ pagination: this.state.pagination })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR deletecontractor", error, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    selectRow = (record) => {
        // this.setState({ selectedContractor: record, showNewContractorModal: true })

        this.props.history.push({
            pathname: '/contractor-payroll/contractors/edit-contractor',
            state: { contractor: record }
        })
    }

    setRowClassName = (record) => {
        var { selectedContractor } = this.state
        selectedContractor = selectedContractor || {}
        console.log("setRowClassName", record, selectedContractor, record.id === selectedContractor.id ? 'selected-row' : '')
        return record.id === selectedContractor.id ? 'selected-row' : ''
    }

    handleTableChange = (pagination, filters, sorter) => {
        this.fetchData({ pagination: pagination })
    }

    handleImportContractor = () => {
        this.setState({ bill: null, selectedContractor: null, category: null, showImportModal: true, modalWidth: 700 })
    }

    onSearch = ({ contractorSearch }) => {
        if (!contractorSearch) {
            return this.fetchData({ pagination: this.state.pagination })
        }
        let pagination = this.state.searchPagination
        const body = {
            "size": pagination.pageSize,
            "page": pagination.current - 1
        }

        if (contractorSearch && contractorSearch.includes("@")) {
            body.searchEmail = contractorSearch
        } else {
            body.searchStr = contractorSearch
        }
        this.setState({ loading: true })
        apiPOSTReq(`${environment.payBaseUrl}/payables/contractors/lookupContractors`, {}, body, (err, resp) => {
            this.setState({ loading: false })
            try {
                const data = resp
                console.log("/lookupContractors", data)
                if (data.result) {
                    this.setState({
                        ...data,
                        pagination: {
                            ...pagination,
                            total: data.count
                        }
                    })
                } else {
                    throw Error(data.error || data.responseMessage || "Sorry we had trouble processing your request. Please try again later")
                }
            } catch (error) {
                console.log("ERRR getContractors", error, err, resp)
                ErrorAlert({ description: error.message })
            }
        })
    }

    onSearchChange = (e) => {
        this.setState({ contractorSearch: e.target.value })
        this.setState({
            pagination: {
                current: 1,
                pageSize: 50
            }
        })
        this.onSearch({ contractorSearch: e.target.value })
    }

    render() {
        var { theme } = this.props
        var { pagination, contractors, selectedContractor } = this.state
        var contractors = contractors || []

        var columns = [
            {
                title: 'Name',
                dataIndex: 'firstName',
                render: (name, contractor) => `${contractor.firstName} ${contractor.lastName}`,
            },
            {
                title: 'Email',
                dataIndex: 'primaryEmail',
                key: 'primaryEmail'
            },
            {
                title: 'Payment method',
                dataIndex: 'deliveryMode',
                key: 'deliveryMode',
                render: deliveryMode => deliveryMode ? <Tag style={{ margin: "5px 0" }} primary>{deliveryMode} <CheckCircleFilled style={{ marginLeft: 4 }} /></Tag> : <Tag style={{ margin: "5px 0" }} primary>None</Tag>
            },
        ]

        const contractorsPerm = getResourcePerm("Payables.Contractors")

        if (contractorsPerm.manage) {
            columns.push(
                {
                    title: '',
                    key: 'action',
                    align: 'center',
                    render: (text, record) => (
                        <Space size="middle">
                            <a onClick={() => this.selectRow(record)}><EditOutlined style={{ fontSize: 18 }} /></a>
                            <Popconfirm
                                title="Are you sure you want to delete this contractor?"
                                onConfirm={() => this.deleteContractor(record)}
                                okText="Confirm"
                                okButtonProps={{ loading: this.state.deleteLoading }}
                                cancelText="Cancel"
                            >
                                <a permtype="Override"><DeleteOutlined style={{ fontSize: 18 }} /></a>
                            </Popconfirm>
                        </Space>
                    ),
                }
            )
        }

        const { contractorUploadJobId } = this.props.aionStore

        return (
            <FlexColumn className='main-padding'>
                <PageHeader
                    titleText="Contractors"
                    subtitle="Contractor Payroll"
                    ctaContent={
                        <div style={{ display: 'flex', alignContent: 'flex-start' }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Button permtype="Payables.Contractors" icon={<PlusOutlined style={{ color: 'white' }} />} solid onClick={() => this.props.history.push('/contractor-payroll/contractors/new-contractor')} text='ADD CONTRACTOR' />
                                {/* <Button type='link' style={{ color: theme.colors.secondary1 }} onClick={this.handleImportContractor} disabled={contractorUploadJobId}><UploadOutlined /> Bulk Import</Button> */}
                            </div>
                        </div>
                    }
                />

                <Container shadow style={{ marginBottom: 48 }}>
                    <LabeledInput
                        id="searchTerm"
                        key="searchTerm"
                        placeholder="Search contractors"
                        prefixIcon={<Image width='12px' height='12px' src={Search} />}
                        onChange={this.onSearchChange}
                        margin='0 0 24px'
                    />
                    {/* <Search
                        placeholder="Search"
                        size="large" 
                        allowClear
                        onSearch={this.onSearch}
                        onChange={this.onSearchChange}
                        style={{ marginBottom: '20px' }}
                    /> */}
                    <Flex between style={{ alignItems: "start" }}>
                        <Table
                            id="contractor-table"
                            className='borderless-table'
                            tableLayout='auto'
                            columns={columns}
                            dataSource={contractors}
                            rowKey='id'
                            pagination={pagination}
                            onChange={this.handleTableChange}
                            loading={this.state.loading}
                            style={{ minHeight: "500px" }}
                            scroll={{ y: '700px', x: '100%' }}
                        />
                    </Flex>
                </Container>

                <Modal
                    visible={this.state.showNewContractorModal}
                    footer={null}
                    closable={true}
                    width={1000}
                    style={{ top: 20 }}
                    destroyOnClose={true}
                    onCancel={() => {
                        this.setState({ showNewContractorModal: false, selectedContractor: null })
                        this.fetchData({ pagination: this.state.pagination })
                    }}
                    className="scroll-overflow-95"
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <NewContractor
                        key={selectedContractor ? selectedContractor.id : moment().format("HH:ss")}
                        editcontractor={selectedContractor}
                        contractor={this.state.selectedContractor}
                        submitComplete={
                            () => {
                                this.setState({ showNewContractorModal: false, selectedContractor: null })
                                this.fetchData({ pagination: this.state.pagination })
                            }
                        }
                    />
                </Modal>

                <Modal
                    visible={this.state.showImportModal}
                    footer={null}
                    closable={true}
                    maskClosable={true}
                    width={this.state.modalWidth}
                    style={{ top: 20 }}
                    destroyOnClose={true}
                    onCancel={() => this.setState({ showImportModal: false, })}
                    closeIcon={<img width='24px' height='24px' src={ModalClose} />}
                >
                    <Import
                        downloadLink="/Contractor_template.csv"
                        downloadName="Contractor_template.csv"
                        actionUrl={`${environment.payBaseUrl}/payables/contractors/uploadContractors`}
                        uploadTypeTitle="Contractor"
                        uploadType="contractor"
                        submitComplete={
                            (contractorUploadJobId) => {
                                if (contractorUploadJobId) {
                                    this.props.dispatch(addDataToStore(SAVE_DATA, { contractorUploadJobId: contractorUploadJobId }))
                                }
                                this.setState({ showImportModal: false })
                            }
                        }
                    />
                </Modal>
            </FlexColumn>
        )
    }
}

function mapStateToProps(state) {
    return {
        aionStore: state.aionAppReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Index)))