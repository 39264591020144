import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';
import {
    Dropdown,
    Table,
    Menu,
    Button,
    message,
    Tabs,
    Tooltip
} from 'antd';

// Components
import moment from 'moment';
import { saveAs } from 'file-saver'
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { apiPOSTReq, getPreSignedS3Url } from '../../../../Utils/api';
import environment from '../../../../environment';
import { Paragraph, Tag } from '../../../Reusable/Text';
import { ErrorAlert } from '../../../Reusable/Alert';
import { SignedLink } from '../../../Reusable/Link';
import { LabeledInput } from '../../../Reusable/Input'
import { Image } from '../../../Reusable/Image'
import { TextButton } from '../../../Reusable/Button';

import { DownOutlined } from '@ant-design/icons';
import { Flex, FlexColumn, CardContainer } from '../../../Reusable/Container';
import AdditionalDocuments from './AdditionalDocuments';
import Search from '../../../../Images/search.png'
import Download from "../../../../Images/download.png"
import MessageIcon from '../../../../Images/message.png';
import { Text } from '../../../Reusable/Refresh/Text'

class AddtionalDocs extends Component {

    state = {
        pagination: {
            current: 1,
            pageSize: 100
        },
        businessDocuments: []
    };

    componentDidMount() {
        this.fetchData({ pagination: this.state.pagination });
        this.fetchApprovedRequests({ pagination: this.state.pagination })
        this.fetchActiveRequests({ pagination: this.state.pagination })
    }

    fetchData = (options) => {
        const { loanApp } = this.props;
        const { pagination } = options || {};
        var body = {
            "size": pagination.pageSize,
            "page": pagination.current - 1,
            "sortDirection": "DESC"
        };
        if (loanApp) {
            body["clientBusinessId"] = loanApp.businessId;
            body["applicationId"] = loanApp.applicationId;
            body["viewBy"] = "FILTER_APPLICATION_ID"
        }
        console.log("/docs/getActiveDocuments body", body);
        this.setState({ loading: true });
        apiPOSTReq(`${environment.uamBaseUrl}/docs/getActiveDocuments`, {}, body, (err, resp) => {
            console.log("/docs/getActiveDocuments resp", resp);
            this.setState({ loading: false });
            try {
                const data = resp || {};
                if (data.result) {

                    var businessDocuments = data.businessDocuments.filter(doc => doc.category != "Owner Financials");
                    this.setState({
                        businessDocuments: businessDocuments,
                        pagination: {
                            ...pagination,
                            total: data.count,
                            pageable: data.pageable
                        }
                    });

                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    fetchApprovedRequests = (options) => {
        const { loanApp } = this.props;
        const { pagination } = options || {};
        var body = {
            "size": pagination.pageSize,
            "page": pagination.current - 1,
            "sortDirection": "DESC"
        };
        if (loanApp) {
            body["BusinessId"] = loanApp.businessId;
            body["applicationId"] = loanApp.applicationId;
            body["uploadCategory"] = "Custom Upload"
        }

        console.log("/lsm/getApprovedLoanRequest body", body);
        this.setState({ loading: true });
        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/getApprovedLoanRequest`, null, body, (err, resp) => {
            console.log("/lsm/getApprovedLoanRequest resp", resp);
            this.setState({ loading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    this.setState({ requests: data.requests });

                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                console.log("/getApprovedLoanRequest err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    fetchActiveRequests = (options) => {
        const { loanApp } = this.props;
        const { pagination } = options || {};
        var body = {
            "size": pagination.pageSize,
            "page": pagination.current - 1,
            "sortDirection": "DESC"
        };
        if (loanApp) {
            body["BusinessId"] = loanApp.businessId;
            body["applicationId"] = loanApp.applicationId;
            body["uploadCategory"] = "Custom Upload"
        }

        console.log("/lsm/getActiveLoanRequest body", body);
        this.setState({ loading: true });
        apiPOSTReq(`${environment.lsmBaseUrl}/lsm/getActiveLoanRequest`, null, body, (err, resp) => {
            console.log("/lsm/getActiveLoanRequest resp", resp);
            this.setState({ loading: false });
            try {
                const data = resp || {};
                if (data.result) {
                    if(data.requests.length == 0) {
                      this.props.refreshInfoRequestedTag(false)
                    } else {
                     this.props.refreshInfoRequestedTag(true)
                    }
                } else {
                    throw Error(data.responseMessage || data.error)
                }
            } catch (error) {
                //console.log("/getPersonCards err", error, resp);
                ErrorAlert({ description: error.message || "Sorry, we had trouble processing your request. Please try again." });
            }
        });
    }

    getColumnsRequest = (item) => {
        return [
            {
                title: 'ID',
                width: 100,
                render: (item) => <Paragraph size="0.95rem"  noMargin>{item.requestId}
                </Paragraph>
            },
            
            {
                title: 'Submitted Date',
                width: 120,
                render: (request) => {
                    return (
                        <Paragraph size="0.95rem" style={{marginBottom: "12px"}} >{moment(request.lastUpdatedAt).format('MM/DD/YY')}
                                    </Paragraph>
                    )
                }
            },
            {
                title: 'File Name',
                width: 300,
                render: (request) => {
                    var filDocs = this.state.businessDocuments.filter(doc => doc.requestId == request.requestId)
                    return (
                        <>
                            {filDocs.map(doc => {
                                return (
                                    <Paragraph size="0.95rem"  style={{marginBottom: "12px"}}>{doc.documentUrl.fileName}
                                    </Paragraph>
                                )
                            })}
                        </>
                    )
                }
            },
            
            {
                title: 'Notes',
                width: 120,
                render: (request) => {
                    var filDocs = this.state.businessDocuments.filter(doc => doc.requestId == request.requestId)
                    return (
                        <>
                            {filDocs.map(doc => {
                                return (
                                    <FlexColumn start style={{ width: '10%',marginBottom: "12px" }}>
                                    {
                                        doc.notes != null ?
                                            <Tooltip overlayInnerStyle={{ width: 330, minWidth: 330 }} overlayStyle={{ width: 350, minWidth: 350 }} overlayClassName='roles-tooltip' placement="left" arrowPointAtCenter={false} title={<FlexColumn>
                                                <Text color='white' size='14px' wrap>{doc.notes}</Text>
                                            </FlexColumn>}>
                                                <FlexColumn>
                                                    <Image src={MessageIcon} />
                                                </FlexColumn>
                                            </Tooltip> : null
                                    }
                                </FlexColumn>
                                )
                            })}
                        </>
                    )
                }
            },
            {
                title: 'Category',
                width: 120,
                dataIndex: 'uploadCategory'
            },
            {
                title: '',
                width: 100,
                render: (request) => {
                    var filDocs = this.state.businessDocuments.filter(doc => doc.requestId == request.requestId) || []

                    return (
                        <TextButton margin='0' loading={""} onClick={() => {
                            this.download(request);
                        }} rightIcon={<Image src={Download} />} text={"Download"} />

                    )
                }
            }
        ]
    }

    handleTableChange = (pagination, filters, sorter) => {
        this.fetchData({ pagination: pagination });
    }

    download = (request) => {
        const { businessDocuments } = this.state;
        let requestId = request.requestId;
        let name = request.documentType;
        var documents = businessDocuments.filter(x => x.requestId == requestId);
        if (documents.length == 1) {
            let document = documents[0] || {};
            this.setState({ loadingDownload: true })
            this.downloadStatement(document);
        } else if (documents.length > 1) {
            this.downloadAll(documents);
        }
    }

    downloadStatement(item) {
        var filename = item.documentUrl.fileName;
        getPreSignedS3Url({ url: item.documentUrl.uri }, async (err, preSignedUrl) => {
            try {
                // prevents opening file in new tab
                let blob = await fetch(preSignedUrl).then((r) => r.blob());
                saveAs(blob, filename);

            } catch (err) {
                console.log(err);
            }
        })
    }

    // Download All docs
    downloadAll = (docs) => {
        const zip = new JSZip();
        let count = 0;
        const zipFilename = "AdditionalDocuments.zip";
        docs.forEach(async function (doc, index) {
            var filename = doc.documentUrl.fileName;
            try {
                getPreSignedS3Url({ url: doc.documentUrl.uri }, async (err, preSignedUrl) => {
                    try {
                        const file = await JSZipUtils.getBinaryContent(preSignedUrl)
                        zip.file(filename, file, { binary: true });
                        count++;
                        if (count === docs.length) {
                            zip.generateAsync({ type: 'blob' }).then(function (content) {
                                saveAs(content, zipFilename);

                            });
                        }
                    }
                    catch (err) {
                        console.log(err);
                    }
                })
            } catch (err) {
                console.log(err);
            }
        });
    }



    render() {
        const { loanApp } = this.props;
        const { requests, pagination, loading, businessDocuments } = this.state;
        let additionalDocs = [];
        if (businessDocuments.length > 0) {
            additionalDocs = businessDocuments.filter(doc => doc.category === "Custom Upload")
        }
        return (
            <>
                <Table
                    id="inbox-table"
                    tableLayout='auto'
                    columns={this.getColumnsRequest()}
                    dataSource={requests || []}
                    rowKey='id'
                    pagination={pagination}
                    // onChange={this.handleTableChange}
                    style={{ minHeight: "500px" }}
                    loading={loading}
                    scroll={{ x: '100%' }}
                />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        store: state.aionAppReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(AddtionalDocs)));