import React from 'react';
import { Layout } from 'antd';

import { FlexColumn, AbstractSideBar, SideBar } from './Container';
import PageHeader from './PageHeader';
import { containerDimensions } from '../../Styles/theme';

const { Content } = Layout;

export const FlowViewContainer = (props) => {
    const { contentViews, title, modal, hidesidebar } = props;
    return (
        <FlexColumn fullHeight start style={{ minHeight: '100%', minWidth: '100%', background: "transparent" }}>
            {/* <SideBar /> */}
            <FlexColumn start fullHeight style={{ padding: props.padding ? props.padding : "0 40px" }}>
                {(title && !modal) ? <PageHeader {...props} titleText={title} /> : null}
                <FlexColumn height={props.height} style={{ width: "100%" }} center>
                    {contentViews}
                </FlexColumn>
            </FlexColumn>
            {/* { (!modal || !hidesidebar) ? <AbstractSideBar 
                width={containerDimensions.authPage.abstractSidebarWidth}
            /> : null} */}
        </FlexColumn>
    );
}